<template>
  <page title="Справочник РЭС">
    <v-table-new
      v-model="transformedOekDistrictDepartments"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник РЭС"
      @edit="onEdit"
    />
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createDepartment"
      @update="updateDepartment"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import generateXml from 'lib/storage/connection-application/data';

export default {
  name: 'CabinetOekDistrictDepartments',
  components: {
    AddDirectoryFieldModal,
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      oekDistrictDepartments: [],
      selectedValue: null,
    };
  },
  computed: {
    isAccessUpdate() {
      return this.isPlatformWorker;
    },
    actionBlockItems() {
      if (!this.isAccessUpdate) return [];
      return [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              width: '95%',
            },
            filter: {},
          },
          ...editHeaders,
        ],
      };
    },
    transformedOekDistrictDepartments() {
      if (!this.oekDistrictDepartments?.length) {
        return [];
      }

      return this.oekDistrictDepartments.map((it) => ({
        ...it,
        actionButtons: {
          deletable: false,
          updatable: true,
        },
      }));
    },
    fieldsTemplate() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
      ];
    },
    fields() {
      if (!this.selectedValue) return this.fieldsTemplate;
      return this.fieldsTemplate.map((field) => field.value = this.selectedValue[field.id]);
    },
    ...mapGetters('user', ['isPlatformWorker']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.oekDistrictDepartments = await this.supplierDepartments({ inn: '7720522853' });
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    createDepartment() {
      this.update(generateXml.ResChangesXML({ create: { name: this.modalValue.name } }));
    },
    updateDepartment() {
      this.update(generateXml.ResChangesXML({ update: { id: this.modalValue.id, name: this.modalValue.name } }));
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    async update(xml) {
      this.loading = true;
      this.error = '';
      try {
        await this.documentUpload({ xml: xml });
        this.closeModal();
        await this.loadData();
      } catch (error) {
        this.setNotification({ message: HTTPError.network(error).message });
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.modalValue = {};
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
    },
    ...mapActions('dataLists', ['supplierDepartments']),
    ...mapActions('user', ['setNotification']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
