export function inArray(validValues) {
  return (value) => validValues.includes(value);
}

export function isErrorConvert(errorArray, indexPoint, indexItem) {
  if (errorArray === undefined || errorArray === null) {
    return { error: false };
  }
  if (errorArray[indexPoint] === undefined || errorArray[indexPoint] === null) {
    return { error: false };
  }
  if (errorArray[indexPoint][indexItem] === undefined || errorArray[indexPoint][indexItem] === null) {
    return { error: false };
  }

  return errorArray[indexPoint][indexItem];
}

export function inConstants(objectWithConstants) {
  return inArray(Object.values(objectWithConstants));
}
