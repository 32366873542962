const SET_LOADING = (state, value) => {
  state.loading = value;
};

const SET_ADRESKA_DATA = (state, data) => {
  state.adreska = data;
};

const SET_OBJECTS_DATA = (state, data) => {
  state.objects = data;
};

export default {
  SET_LOADING,
  SET_ADRESKA_DATA,
  SET_OBJECTS_DATA,
};
