<template>
  <div :class="$style.chat">
    <div v-if="!loading" ref="messenger" :class="$style.chatListContainer">
      <ul :class="$style.chatList">
        <li
            v-for="(msg, idx) in messages"
            :key="idx"
            :class="{
                [$style.client]: msg.senderId === getUser.id || msg.type === 'faq',
                [$style.bot]:  msg.senderId !== getUser.id && msg.type !== 'faq',
              }"
        >
          <p v-if="msg.senderName" :class="$style.time">
            {{ msg.senderName }}:
          </p>
          <p style="white-space: pre-line" :class="{[$style.faq]:  msg.type === 'faq'}">
            <span v-if="msg.type === 'answer'" v-html="msg.content" />
            <span v-else @click="faqAnswer(msg)">
                  {{ msg.content }}
                </span>
          </p>
          <p v-if="msg.attachments?.length > 0" :class="$style.attachmentsTitle">
            Прикрепленные файлы:
          </p>
          <p
              v-for="(attachment, index) in msg.attachments"
              :key="index"
              :class="$style.file"
              @click="downloadAttachment(attachment, msg.roomId)"
          >
                <span>
                  {{ attachment.fileName }}
                </span>
          </p>
          <p v-if="msg.creationTime" :class="$style.time">
            {{ formatTime(msg.creationTime) }}
          </p>
        </li>
      </ul>
    </div>
    <div v-if="!loading" :class="$style.inputMessage">
      <c-uploading
          v-model="attachments"
          is-clip-view
          role="file"
          :class="$style.clip"
      />
      <textarea
          v-model="message"
          :class="$style.inputValue"
          :disabled="isWaitingSubmit"
          placeholder="Напишите сообщение..."
          @keydown.enter.exact.prevent="send"
          @keydown.enter.shift.exact.prevent="message += '\n'"
      />

      <span ref="sendMessage" :class="$style.send" @click="send">
            <img alt="Отправить сообщение" src="@/assets/icons/basic/send.svg">
          </span>
    </div>
    <div v-if="attachments?.length > 0" :class="$style.attachments">
      <div>
        Прикрепленные файлы:
      </div>
      <div :class="$style.attachmentItems">
        <p v-for="(attachment, index) in attachments" :key="index" :class="$style.attachmentItem">
          <a :href="fileUrl(attachment)" target="_blank" :download="attachment.name">
            {{ spoilerName(attachment.name) }}
          </a>
          <img alt="удалить" src="~@/assets/icons/file/delete.svg" @click="deleteAttachment(attachment)">
        </p>
      </div>
    </div>
    <v-circle-loader v-if="loading || isWaitingSubmit"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatting from 'lib/utils/formatting';
import { VCircleLoader } from '@/components';
import { convertDbTimeFormatHhMm } from 'lib/utils/date';
import CUploading from 'atoms/common/CUploading';
import { saveFile } from 'lib/utils/files';

export default {
  name: 'ChatMessenger',
  components: {
    VCircleLoader,
    CUploading,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    roomId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: '',
      answers: [],
      documentsUpload: [],
      isWaitingSubmit: false,
      attachments: [],
    };
  },
  computed: {
    ...mapGetters('user', ['getUser']),
    ...mapGetters('dataLists', ['chatFaqMessages']),
  },
  watch: {
    messages: {
      immediate: true,
      handler() {
        console.log('watch messages');

        this.scrollMessages();
      },
    },
  },
  methods: {
    async send() {
      if ((!this.message.trim() && (this.attachments?.length === 0 || !this.attachments)) || this.isWaitingSubmit) {
        return;
      }

      this.isWaitingSubmit = true;

      const msg = this.message;

      try {
        const { message } = await this.sendMessage({ roomId: this.roomId, content: msg });

        if (this.attachments?.length > 0) {
          await this.sendAttachment({ files: this.attachments, roomId: message.roomId, messageId: message.id });
          this.attachments = [];
        }

        this.message = '';

        this.$emit('submitted');
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    scrollMessages() {
      if (this.$refs.messenger) {
        this.$nextTick(() => {
          this.$refs.messenger.scrollTop = this.$refs.messenger.scrollHeight;
        });
      }
    },
    formatTime(time) {
      return convertDbTimeFormatHhMm(time);
    },
    formatMessage(message) {
      return formatting.formattedString(message, '', '');
    },
    faqAnswer(msg) {
      this.answers.push({
        content: `${msg.content}?`,
        senderId: this.getUser.id,
      });
      this.answers.push({
        content: msg.answer.replaceAll('\n', '\n\n'),
        type: 'answer',
      });

      this.scrollMessages();
    },
    deleteAttachment(attachment) {
      this.attachments = this.attachments
          .filter((scan) => scan.source !== attachment.source);
    },
    fileUrl(file) {
      return `data:${file.type};base64,${file.code}`;
    },
    async downloadAttachment(attachment, roomId) {
      this.isWaitingSubmit = true;

      try {
        const { data } = await this.downloadFile({ roomId: roomId, fileId: attachment.id });

        saveFile(data, attachment);
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    spoilerName(name) {
      if (name.length > 32) {
        return `${name.substr(0, 30)}...`;
      }

      return name;
    },
    ...mapActions('chatbot', ['sendMessage', 'sendAttachment', 'downloadFile']),
  },
};
</script>

<style lang="sass">
.right-enter-active,
.right-leave-active
  transition: transform .3s

.right-enter,
.right-leave-to
  transform: translateX(100%)
</style>

<style lang="sass" module>
.chatContainer
  padding: 8px
  position: absolute
  right: 10px
  top: 10px
  min-width: 415px
  min-height: 600px
  background-color: #F5F6F6
  z-index: 1008
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25)

.iconClose
  width: 18px
  height: 18px
  display: block
  position: absolute
  top: 14px
  right: 14px
  background-image: url('~icons/basic/close.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer

.chat
  position: relative
  display: flex
  flex-direction: column
  width: 400px
  height: 520px
  background-color: white
  padding: 8px
  border-radius: 4px
  margin-left: auto
  margin-right: auto
  justify-content: space-between

.chatListContainer
  overflow-y: auto
  overflow-x: hidden
  height: 100%

  &::-webkit-scrollbar-button
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar-thumb
    -webkit-border-radius: 0
    border-radius: 10px
    background-color: #d1d1d1

  &::-webkit-scrollbar-thumb:hover
    background-color: #a19f9f

  &::-webkit-resizer
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar
    width: 7px


.chatList
  display: flex
  flex-direction: column
  padding: 0 8px

  .uploadingButton

  p
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 16px

    a
      color: #71757A
      text-decoration: underline


  .client
    margin-bottom: 15px

    p
      text-align: right
      word-break: break-word

  .bot
    margin-bottom: 15px

    p
      text-align: left
      word-break: break-word
      color: #71757A

    p.note
      p
        color: #979CA2

  .faq
    border-radius: 24px
    background-color: #F5F5F5
    cursor: pointer
    padding: 8px
    margin-bottom: 8px


.time
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 14px
  margin-top: 10px
  color: #838383

.inputMessage
  border-top: 2px solid #F2F2F2
  display: flex
  align-items: stretch
  padding: 16px 0 0 0
  background-color: white

.inputValue
  width: 100%
  max-height: 40px
  padding: 8px
  border: none
  margin-right: 2px
  outline: none
  resize: none

  &::placeholder
    color: #838383
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 21px

  &::-webkit-scrollbar
    width: 0

.send
  align-items: center
  margin-bottom: 0
  width: 28px
  height: 24px
  cursor: pointer
  transform: translateY(6px)

.clip
  cursor: pointer

.attachments
  display: flex
  align-items: center
  gap: 8px

.attachmentItems
  display: flex
  flex-direction: column
  gap: 4px

.attachmentItem
  display: flex
  align-items: center
  gap: 4px

  img
    cursor: pointer

.file
  color: #1f5b9b
  cursor: pointer
  text-decoration: underline

.attachmentsTitle
  margin-top: 4px
  color: gray !important
</style>
