const checkContentPatch = (checkContentPatchVariable) => {
  if (!checkContentPatchVariable) {
    return '/content';
  }

  return `${checkContentPatchVariable}/content`;
};

const actualEnvContentPatch = checkContentPatch(process.env.VUE_APP_CONTENT_HTTP);

export default actualEnvContentPatch;
