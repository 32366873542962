const getDocument = (state) => state.document;
const getKind = (state) => state.kind;
const getStatusTitle = (state) => state.statusTitle;
const getStatus = (state) => state.status;
const getIdxDoc = (state) => state.idxDoc;
const getIdxStat = (state) => state.idxStat;

export default {
  getDocument,
  getKind,
  getStatusTitle,
  getStatus,
  getIdxDoc,
  getIdxStat,
};
