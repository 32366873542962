<template>
  <div v-custom-click-outside="closeSelect">
    <div :class="{[$style['select']]: true, [$style['select-error']]: error}"
         :style="`padding: ${selectPadding}; --top-px:${top}`"
         @click="openSelect">
      <p :class="{[$style['select__placeholder']]: true, [$style['select__placeholder__error']]: error}">{{ value ? transformValue(value, transform) : placeholder }}</p>
      <div v-if="visible"
           :class="{[$style['select__option']]: true,[$style['select__option__left']]: left, [$style['select__option__right']]: right}"
           :style="`width: ${optionWidth}; max-height: ${optionMaxHeight};`"
      >
        <p v-for="(option, id) in options" :key="id" :class="$style['select__option__text']" @mousedown="setSelect(option)">
          <span>{{ option.name }}</span>
        </p>
      </div>
    </div>
    <p v-if="error" class="errorText">Поле обязательно для заполнения</p>
  </div>
</template>

<script>
export default {
  name: 'VSelectNew',
  props: {
    options: { type: Array, required: true },
    value: { type: String, default: '' },
    error: { type: Boolean, default: false },
    transform: { type: Object, required: true },
    optionWidth: { type: String, default: '790px' },
    optionMaxHeight: { type: String, default: '200px' },
    selectPadding: { type: String, default: '10px 28px 10px 8px;' },
    top: { type: String, default: '10px' },
    placeholder: { type: String, default: '' },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    transformValue(value, transform) {
      return value.length > transform.length
        ? `${value.slice(0, transform.slice)}...`
        : `${value}`;
    },
    openSelect() {
      if (this.visible) {
        this.closeSelect();
        return;
      }
      this.visible = true;
    },
    setSelect(option) {
      this.$emit('select', option);
      this.closeSelect();
    },
    closeSelect() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" module>
.select {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #DEE2E6;
  cursor: pointer;
  z-index: 2;

  &__placeholder {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757A;

    &__error {
      color: red;
    }
  }

  &__option {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 16px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 10;

    &__left {
      left: 0;
    }

    &__right {
      right: 0;
    }

    &__text {
      color: #4C4E51;
      padding: 4px 16px 0 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      &:last-child {
        padding-bottom: 4px;
      }

      &:hover {
        background-color: #2F82DF;
        color: #FFFFFF;

        &:first-child {
          border-radius: 9px 0 0 0;
        }

        &:last-child {
          border-radius: 0 0 0 9px;
        }
      }
    }
  }

  &:after {
    content: url('~icons/basic/arrow_down_gray16x10.svg');
    position: absolute;
    right: 10px;
    top: var(--top-px);
    pointer-events: none;
    box-sizing: border-box;
  }
}

.select-error {
  border: 1px solid red !important;
}
</style>
