<template>
  <page title="Доступные профили">
    <v-table-new
      v-model="accountsList"
      :template="template"
      :loading="loading"
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProfilesList',
  components: { Page, VTableNew },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: 'Тип профиля',
            key: 'profileType',
            sort: {},
            filter: {},
          },
          {
            label: 'Название',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'Должность',
            key: 'role',
            sort: {},
            filter: {},
          },
          {
            label: 'Перейти',
            key: 'followToProfile',
            type: 'button',
            customCheck: (data) => data.isCurrent,
          },
        ],
      };
    },
    accountsList() {
      if (!this.getUser || !this.getUser.authorizations) {
        return [];
      }
      return this.getUser.authorizations
        .map((item) => ({
          ...item,
          isCurrent: item.account?.id === this.getAccount?.id,
          profileType: this.getProfileType(item.account?.profile.__typename),
          role: item.role?.name,
          name: item.account?.profile.__typename === 'CompanyProfile' ? item.account?.profile?.name : this.getFio(this.getUser.fullName),
          followToProfile: 'Перейти',
        }))
        .sort((first, second) => (second.isCurrent && !first.isCurrent ? 1 : -1));
    },
    ...mapGetters('user', ['getUser', 'getAccount']),
  },
  methods: {
    getProfileType(type) {
      if (type === 'PlatformWorkerProfile') {
        return 'Администратор платформы';
      }

      if (type === 'CompanyProfile') {
        return 'Юридическое лицо';
      }

      return 'Физическое лицо';
    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'followToProfile': {
          return this.changeAccount(cell.row);
        }
      }
    },
    async changeAccount(item) {
      if (item.account?.id === this.getAccount?.id) return;
      try {
        this.loading = true;
        await this.userSwapAccount({ accountId: item.account?.id });
        await this.$router.push('/cabinet');
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getFio(fullName) {
      if (fullName && fullName.name) {
        let patronymic = '';
        if (fullName.patronymic) {
          patronymic = `${fullName.patronymic[0]}. `;
        }
        return `${fullName.name[0]}. ${patronymic}${fullName.surname}`;
      }
      return undefined;
    },
    ...mapActions('user', ['userSwapAccount']),
  },
};
</script>

<style scoped lang="scss">

</style>
