<template>
  <div :class="$style.container">


    <div>
      <div v-if="additionalDocument && additionalDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.additionalDocumentTitle">
          Дополнительная информация переданная по заявке:
        </div>
        <p v-html="comments"></p>
      </div>
      <div v-if="additionalDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in additionalDocument.files" :key="index" style="display: flex; justify-content: space-between">
          <a :href="fileUrl(file)" :role="'additionalDocumentFile[' + index + '].name'" :download="file.name" :class="$style.reviewFileName">{{ file.name }}</a>
          <a :href="fileUrl(file)" :role="'additionalDocumentFile[' + index + '].download'" :download="file.name" :class="$style.reviewFileDownload">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div v-if="retailerOfferDocument && retailerOfferDocument.files" :class="$style.reviewDocument">
        <div :class="$style.retailerOfferDocumentTitle">
          {{ actionProps.agreementTitle }}
        </div>
      </div>
      <div v-if="retailerOfferDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in retailerOfferDocument.files" :key="index" style="display: flex; justify-content: space-between">
          <a :href="fileUrl(file)" :role="'retailerOfferDocumentFile[' + index + '].name'" :download="file.name" :class="$style.reviewFileName">{{ file.name }}</a>
          <a :href="fileUrl(file)" :role="'retailerOfferDocumentFile[' + index + '].download'" :download="file.name" :class="$style.reviewFileDownload">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>

        <div v-if="retailerOfferDocument.comments" :class="$style.commentsTitle">Сопроводительный текст:</div>
        <p v-if="retailerOfferDocument.comments" :class="$style.retailerOfferComments" v-html="retailerOfferComments"></p>
      </div>


    </div>

    <div :class="$style.actionContent">
      <submit-task-details title="OK" role='close' @submit="onClose" />
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';

export default {
  name: 'RetailerOfferSubmitted',
  components: {
    SubmitTaskDetails,
  },
  props: {
    documentId: String,
    additionalDocument: Object,
    retailerOfferDocument: Object,
    actionProps: Object,
  },
  computed: {
    comments() {
      return formatting.formattedString(this.additionalDocument.comments, '«', '»');
    },
    retailerOfferComments() {
      return formatting.formattedString(this.retailerOfferDocument.comments, '«', '»');
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
  },
};
</script>

<style lang="sass" module>
  @import 'styles/components/task-details';

  .title
    padding-bottom: 16px
    +base-title

  .retailerOfferDocumentTitle
    +base-title

  .additionalDocumentTitle
    padding-top: 40px
    padding-bottom: 16px
    +base-title

  .commentsTitle
    margin-top: 40px
    margin-bottom: 24px
    +base-title

  .retailerOfferComments p
    margin-bottom: 8px

  .statusGroup, .submitNote, .offerGroup, .blockContent
    width: 100%
    max-width: 560px
    margin: 0 auto

  .reviewDocument
    padding-bottom: 24px

  .reviewDocument, .reviewDocumentFiles, .accompanyingText
    width: 100%
    max-width: 560px
    margin: 0 auto

  .reviewDocumentFiles
    margin-bottom: 40px

  .reviewDocument p
    margin-top: 8px
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px
    color: #000

  .reviewDocumentFiles p a
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 16px
    color: #000
    text-decoration: underline

  .blockContent
    margin-bottom: 40px

  .statusGroup
    margin-top: 40px

  .submitNote
    margin-top: 40px
    margin-bottom: 40px
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
    color: #0E0F0F

  .accompanyingText
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
    color: #000

  .correctionTitle
    padding-bottom: 12px
    +base-title

  p.subTitle
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px
    +medium-spacing

  .actionContent
    width: 560px
    margin: 0 auto
</style>
