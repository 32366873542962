export function isAccess(permissions, accountKind) {
  const blockAccess = {
    agreements: false,
    'acts-implementation-technological-connection': false,
    tp: false,
    'contract-implementation-technological-connection': false,
    losses: false,
    service: false,
    disagreements: false,
    'acts-installation-metering-devices': false,
    'accounting-points': false,
    'integral-acts': false,
    'eighteen-shape': false,
    'useful-vacation-certificate': false,
    'useful-vacation-departments-and-consumers': false,
    balances: false,
    attorney: false,
    'access-rights': false,
    employees: false,
    'reading-sheets': false,
    measurements: false,

    // ODK
    // Учёт складских остатков
    'process/0/1': false,
    // Ведение реестров
    'process/1/1': false,
    'process/1/2': false,
    'process/1/3': false,
    'process/1/4': false,
    'process/1/5': false,
    'process/1/6': false,
    'process/1/7': false,
    // Ведение АХП
    'process/2/1': false,
    // Ведение программы оформления
    'process/3/1': false,
    'process/3/2': false,
    'process/3/3': false,
    // Ведение договоров
    'process/4/1': false,
    'process/4/2': false,
    // Проекты накладных
    'process/5/1': false,
    // Выдача со склада
    'process/6/1': false,
    // Монтаж
    'process/7/1': false,
    // Приёмка и контроль монтажа
    'process/8/1': false,
    'process/8/2': false,
    'process/8/1-2': false,
    'process/8/3': false,
    'process/8/4': false,
    // Назначение на подключение/смену точки
    'process/9/1': false,
    'process/9/2': false,
    'process/9/1-2': false,
    // Подключение/смена точки
    'process/10/1': false,
    'process/10/2': false,
    // Осмотры
    'process/11/1': false,
    'process/11/2': false,
    'process/11/3': false,
    'process/11/4': false,
    'process/11/3-4': false,
    'process/11/5': false,
    'process/11/6': false,
    'process/11/7': false,
    'process/11/5-7': false,
    // Ремонт
    'process/12/1': false,
    // Демонтаж
    'process/13/1': false,
    // Приёмка и контроль демонтажа
    'process/14/1': false,
    'process/14/2': false,
    'process/14/1-2': false,
    'process/14/3': false,
    'process/14/4': false,
    // Приёмка на склад
    'process/15/1': false,
    'process/15/2': false,
    // Дефектовка
    'process/16/1': false,
    'process/16/2': false,
    'process/16/3': false,
    'process/16/4': false,

    // Витрина "Программа оформления"
    adreska: false,
    // Витрина "Ведение договоров"
    'initial-control-inspection': false,
    'initial-control-inspection/contracts': false,
    'initial-control-inspection/specification': false,
    // Витрина "Складские операции"
    'warehouse-operations': false,
    'warehouse-operations/elements': false,
    'warehouse-operations/axp': false,
    'warehouse-operations/to-extradite': false,
    'warehouse-operations/to-acceptance': false,
    'warehouse-operations/contractor-debt': false,
    'warehouse-operations/consignments': false,
    'warehouse-operations/plan-consignments': false,
    // Витрина "Эксплуатация"
    exploitation: false,
    'exploitation/card': false,
    'exploitation/exploitation': false,
    'exploitation/installation': false,
    'exploitation/dismantling': false,
    'exploitation/installation-control': false,
    'exploitation/dismantling-control': false,
    'exploitation/connection': false,
    // Витрина "Работа с дефектами"
    'handling-defects': false,
    'handling-defects/assignment': false,
    'handling-defects/tasks': false,
    'handling-defects/defects': false,
    // Отчёты ОДК
    'odk-reports': false,
    'odk-reports/acceptance': false,
    'odk-reports/extradite': false,
    'odk-reports/installation-districts': false,
    'odk-reports/installation-contractors': false,
    'odk-reports/dismantling': false,
    'odk-reports/defects-contractors': false,
    'odk-reports/defects-districts': false,
    'odk-reports/defects-contractors-districts': false,
    'odk-reports/lighting': false,
    'odk-reports/defects-adreska': false,
    'odk-reports/control': false,
    // НСИ ОДК
    contractors: false,
    'odk-accounting-warehouses': false,
    'odk-accounting-axp': false,
    'odk-accounting-holidays': false,
    'odk-accounting-events': false,
    'odk-accounting-element-types': false,
    'odk-accounting-decor-types': false,
    'odk-accounting-defects': false,
    'responsible-by-contractor': false,
    'administrative-districts': false,
    'odk-tags': false,
    // Редактирование накладной
    'consignment-edit': false,
  };
  const enableOdkNsi = (blockAccess) => {
    blockAccess.contractors = true;
    blockAccess['odk-accounting-warehouses'] = true;
    blockAccess['odk-accounting-axp'] = true;
    blockAccess['odk-accounting-holidays'] = true;
    blockAccess['odk-accounting-events'] = true;
    blockAccess['odk-accounting-element-types'] = true;
    blockAccess['odk-accounting-decor-types'] = true;
    blockAccess['odk-accounting-defects'] = true;
    blockAccess['responsible-by-contractor'] = true;
    blockAccess['administrative-districts'] = true;
    blockAccess['odk-tags'] = true;
  };
  if (accountKind === 'LEGAL_CONSUMER' || accountKind === 'INDIVIDUAL_CONSUMER') {
    blockAccess.tp = true;
  }

  if (!permissions || permissions.length === 0) {
    return blockAccess;
  }
  permissions.forEach((permission) => {
    const permissionForCheck = permission.code.split('-')[0].trim();
    switch (permissionForCheck) {
      case 'SVET_A1':
      case 'SVET_A2': {
        blockAccess.agreements = true;
        break;
      }
      case 'SVET_A3':
      case 'SVET_A4': {
        blockAccess['acts-implementation-technological-connection'] = true;
        blockAccess.tp = true;
        blockAccess['contract-implementation-technological-connection'] = true;
        break;
      }
      case 'SVET_A5': {
        blockAccess.losses = true;
        blockAccess.service = true;
        break;
      }
      case 'SVET_A6': {
        blockAccess.disagreements = true;
        break;
      }
      case 'SVET_A7': {
        blockAccess['acts-installation-metering-devices'] = true;
        break;
      }
      case 'SVET_A9': {
        blockAccess['accounting-points'] = true;
        break;
      }
      case 'SVET_A10': {
        blockAccess['reading-sheets'] = true;
        blockAccess.measurements = true;
        break;
      }
      case 'SVET_A11': {
        blockAccess['integral-acts'] = true;
        break;
      }
      case 'SVET_A12': {
        blockAccess['eighteen-shape'] = true;
        blockAccess['useful-vacation-certificate'] = true;
        blockAccess['useful-vacation-departments-and-consumers'] = true;
        break;
      }
      case 'SVET_A13': {
        blockAccess.balances = true;
        break;
      }
      case 'SVET_B1': {
        blockAccess['access-rights'] = true;
        blockAccess.employees = true;
        break;
      }
      case 'SVET_B2': {
        blockAccess.attorney = true;
        break;
      }
    }
    if (accountKind === 'BOILER') {
      switch (permission.code) {
        case 'SVET_D0-A': {
          blockAccess['process/0/1'] = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          blockAccess['initial-control-inspection/specification'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-A': {
          blockAccess['process/1/1'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-B': {
          blockAccess['process/1/2'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-C': {
          blockAccess['process/1/3'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-D': {
          blockAccess['process/1/4'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-E': {
          blockAccess['process/1/5'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D1-F': {
          blockAccess['process/1/6'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D2-A': {
          blockAccess['process/2/1'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D3-A': {
          blockAccess['process/3/1'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D3-B': {
          blockAccess['process/3/2'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D3-C': {
          blockAccess['process/3/3'] = true;
          enableOdkNsi(blockAccess);
          blockAccess.adreska = true;
          break;
        }
        case 'SVET_D4-A': {
          blockAccess['process/4/1'] = true;
          blockAccess.adreska = true;
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D4-B': {
          blockAccess['process/4/2'] = true;
          blockAccess.adreska = true;
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          blockAccess['initial-control-inspection/specification'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D5-A': {
          blockAccess['process/5/1'] = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['warehouse-operations/to-extradite'] = true;
          blockAccess['warehouse-operations/to-acceptance'] = true;
          blockAccess['warehouse-operations/contractor-debt'] = true;
          blockAccess['warehouse-operations/consignments'] = true;
          blockAccess['warehouse-operations/plan-consignments'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          blockAccess.adreska = true;
          break;
        }
        case 'SVET_D6-A': {
          blockAccess['process/6/1'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['warehouse-operations/to-extradite'] = true;
          blockAccess['warehouse-operations/contractor-debt'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D7-A': {
          blockAccess['process/7/1'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D8-A': {
          blockAccess['process/8/1'] = true;
          blockAccess['process/8/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          blockAccess['exploitation/installation-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D8-B': {
          blockAccess['process/8/2'] = true;
          blockAccess['process/8/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          blockAccess['exploitation/installation-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D8-C': {
          blockAccess['process/8/3'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          blockAccess['exploitation/installation-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D8-D': {
          blockAccess['process/8/4'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D9-A': {
          blockAccess['process/9/1'] = true;
          blockAccess['process/9/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/connection'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D9-B': {
          blockAccess['process/9/2'] = true;
          blockAccess['process/9/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/connection'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D10-A': {
          blockAccess['process/10/1'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/connection'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D10-B': {
          blockAccess['process/10/2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-A': {
          blockAccess['process/11/1'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-B': {
          blockAccess['process/11/2'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-C': {
          blockAccess['process/11/3'] = true;
          blockAccess['process/11/3-4'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/assignment'] = true;
          blockAccess['handling-defects/tasks'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-D': {
          blockAccess['process/11/4'] = true;
          blockAccess['process/11/3-4'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/assignment'] = true;
          blockAccess['handling-defects/tasks'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-E': {
          blockAccess['process/11/5'] = true;
          blockAccess['process/11/5-7'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-F': {
          blockAccess['process/11/6'] = true;
          blockAccess['process/11/5-7'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/tasks'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D11-G': {
          blockAccess['process/11/7'] = true;
          blockAccess['process/11/5-7'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/exploitation'] = true;
          blockAccess['exploitation/card'] = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D12-A': {
          blockAccess['process/12/1'] = true;
          blockAccess.adreska = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D13-A': {
          blockAccess['process/13/1'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D14-A': {
          blockAccess['process/14/1'] = true;
          blockAccess['process/14/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          blockAccess['exploitation/dismantling-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D14-B': {
          blockAccess['process/14/2'] = true;
          blockAccess['process/14/1-2'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          blockAccess['exploitation/dismantling-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D14-C': {
          blockAccess['process/14/3'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          blockAccess['exploitation/dismantling-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D14-D': {
          blockAccess['process/14/4'] = true;
          blockAccess.adreska = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D15-A': {
          blockAccess['process/15/1'] = true;
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          blockAccess['initial-control-inspection/specification'] = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['warehouse-operations/to-acceptance'] = true;
          blockAccess['warehouse-operations/contractor-debt'] = true;
          blockAccess['consignment-edit'] = true;
          blockAccess.adreska = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D15-B': {
          blockAccess['process/15/2'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D16-A': {
          blockAccess['process/16/1'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D16-B': {
          blockAccess['process/16/2'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D16-C': {
          blockAccess['process/16/3'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D16-D': {
          blockAccess['process/16/4'] = true;
          blockAccess.adreska = true;
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          blockAccess['consignment-edit'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-A': {
          blockAccess.adreska = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-B': {
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-C': {
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/specification'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-D': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/elements'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-E': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/axp'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-F': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/to-extradite'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-G': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/to-acceptance'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-H': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/contractor-debt'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-I': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/consignments'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-J': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/plan-consignments'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-K': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/exploitation'] = true;
          blockAccess['exploitation/card'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-L': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-M': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-N': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-O': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling-control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-P': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/connection'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-Q': {
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/assignment'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-R': {
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/tasks'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D17-S': {
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-A': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/extradite'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-B': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/acceptance'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-C': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/installation-districts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-D': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/installation-contractors'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-E': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-F': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/defects-contractors'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-G': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/defects-districts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-H': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/defects-contractors-districts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-I': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/lighting'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-J': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/defects-adreska'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D19-K': {
          blockAccess['odk-reports'] = true;
          blockAccess['odk-reports/control'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D20-A': {
          enableOdkNsi(blockAccess);
          break;
        }
      }
      // ODK permission
    } else if (accountKind === 'COUNTERPARTY') {
      switch (permission.code) {
        case 'SVET_D7-A': {
          blockAccess.adreska = true;
          blockAccess['process/7/1'] = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D10-A': {
          blockAccess.adreska = true;
          blockAccess['process/10/1'] = true;
          blockAccess.exploitation = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D10-B': {
          blockAccess.adreska = true;
          blockAccess['process/10/2'] = true;
          blockAccess.exploitation = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D12-A': {
          blockAccess.adreska = true;
          blockAccess['process/12/1'] = true;
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D13-A': {
          blockAccess.adreska = true;
          blockAccess['process/13/1'] = true;
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-A': {
          blockAccess.adreska = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-B': {
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/contracts'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-C': {
          blockAccess['initial-control-inspection'] = true;
          blockAccess['initial-control-inspection/specification'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-D': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/contractor-debt'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-E': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/to-extradite'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-F': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/to-acceptance'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-G': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/consignments'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-H': {
          blockAccess['warehouse-operations'] = true;
          blockAccess['warehouse-operations/plan-consignments'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-I': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/exploitation'] = true;
          blockAccess['exploitation/card'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-J': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/installation'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-K': {
          blockAccess.exploitation = true;
          blockAccess['exploitation/dismantling'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D18-L': {
          blockAccess['handling-defects'] = true;
          blockAccess['handling-defects/defects'] = true;
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D20-A': {
          enableOdkNsi(blockAccess);
          break;
        }
      }
    } else {
      switch (permission.code) {
        case 'SVET_D20-A': {
          enableOdkNsi(blockAccess);
          break;
        }
        case 'SVET_D21-A': {
          enableOdkNsi(blockAccess);
          blockAccess['process/1/7'] = true;
          break;
        }
      }
    }
  });

  return blockAccess;
}

export function filterForAccess(item, blockAccess) {
  return blockAccess && (blockAccess[item] === undefined || blockAccess[item]);
}

export function isAccessUpdateNsiOdk(blockAccess) {
  return blockAccess && blockAccess['process/nsi-odk/update'];
}

export function isAccessDefectAssignment(blockAccess) {
  return blockAccess && blockAccess['process/11/7'];
}

export function isAccessInspectionOdk(blockAccess) {
  return blockAccess && blockAccess['process/11/5-7'];
}

export function isAccessRegularInspectionOdk(blockAccess) {
  return blockAccess && blockAccess['process/11/6'];
}
