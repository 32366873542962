<template>
  <div>
    <p v-if="checkOwner" style="margin-bottom: 16px; color: #71757A">
      * актуальные данные по работникам являются ответственностью компании
    </p>
    <v-table-new
      v-model="transformedData"
      :template="template"
      excel-report-file-name="Сотрудники компании. Сотрудники"
      :loading="isLoading"
      @on-cell-click="cellClickHandler"
    />

    <!-- delete user modal begin -->
    <dialog-template v-if="displayDeleteDialog" v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Вы уверены, что хотите удалить сотрудника
        <strong v-if="userForDelete.fullName">
          {{ userForDelete.fullName.surname }} {{ userForDelete.fullName.name }}
          <span v-if="userForDelete.fullName.patronymic && userForDelete.fullName.patronymic !== 'undefined'">
              {{ userForDelete.fullName.patronymic }}
            </span>
        </strong>
        <strong v-else>
            <span>
              {{ userForDelete.email }}
            </span>
        </strong>
        ?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="ДА" @submit="deleteUserFromCompanyHandler(userForDelete.id)"/>
        <submit-task-details :gray="true" title="НЕТ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>
    <!-- delete user modal end -->

    <!-- delete user modal begin -->
    <dialog-template v-if="displayDeleteUserInvitationDialog" v-model="displayDeleteUserInvitationDialog" center>
      <p :class="$style.deleteText">
        Вы уверены, что хотите отменить приглашение сотрудника
        <span>{{ userForDelete.email }}</span>
        ?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="ДА" @submit="deleteUserInvitationHandler(userForDelete.email)"/>
        <submit-task-details :gray="true" title="НЕТ" @submit="displayDeleteUserInvitationDialog = false"/>
      </div>
    </dialog-template>
    <!-- delete user modal end -->

    <!-- update role begin -->
    <v-modal :visible="isModalVisible">
      <div :class="$style.modalContent">
        <h1>Смена должности</h1>
        <p :class="$style['modalContent__info']">Данные сотрудника: <span>{{ userName }}</span></p>
        <div :class="$style['modalContent__input-group']">
          <div :class="$style['modalContent__input-group__input']">
            <p :class="$style['modalContent__input-group__input__title']">Подразделение</p>
            <v-search-select
                v-model="selectedSubdivision"
                :options="subdivisions"
                placeholder="Подразделение"
            />
          </div>
          <div :class="$style['modalContent__input-group__input']">
            <p :class="$style['modalContent__input-group__input__title']">Должность</p>
            <v-search-select
                v-model="selectedRoleId"
                :options="roles"
                placeholder="Должность"
            />
          </div>
        </div>
        <v-divider/>
        <div :class="$style['modalContent__btn-group']">
          <v-button :disabled="disabled" style="margin-right: 16px"
                    @click="saveUserRole">
            Сохранить
          </v-button>
          <v-button variant="outlined" @click="cancel">Отменить</v-button>
          <horizontal-progress-bar v-if="isWaitingRoleSubmit"/>
        </div>
      </div>
    </v-modal>
    <!-- update role end -->

  </div>
</template>

<script>
import { VModal, VSearchSelect, VTableNew } from 'components';
import DialogTemplate from 'templates/DialogTemplate.vue';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VButton from 'components/VButton/VButton.vue';
import VDivider from 'components/VDivider/VDivider.vue';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

export default {
  name: 'EmployeeContentBlock',
  components: {
    HorizontalProgressBar,
    VDivider,
    VButton,
    VTableNew,
    DialogTemplate,
    SubmitTaskDetails,
    VModal,
    VSearchSelect,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayDeleteDialog: false,
      displayDeleteUserInvitationDialog: false,
      userForDelete: null,
      isModalVisible: false,
      isModalCheck: false,
      user: null,
      isUserOwner: null,
      isCurrentUser: null,
      selectedSubdivision: '',
      selectedCheckRoleId: '',
      selectedCheckSubdivision: '',
      selectedRoleId: '',
      xml: '',
      isWaitingRoleSubmit: false,
    };
  },
  computed: {
    template() {
      const headers = [
        {
          label: '№ П/П',
          key: 'index',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          type: 'index',
        },
        {
          label: 'Внешний ID',
          key: 'externalId',
          thStyle: {
            maxWidth: '100px',
            minWidth: '100px',
          },
          sort: {},
          filter: {},
        },
        {
          label: 'Почта',
          key: 'email',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
        },
        {
          label: 'Фамилия',
          key: 'surname',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          filter: {},
        },
        {
          label: 'Имя',
          key: 'name',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
          filter: {},
        },
        {
          label: 'Отчество',
          key: 'patronymic',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
          filter: {},
        },
        {
          label: 'Подразделение',
          key: 'subdivision',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
          filter: {
            type: 'select',
            values: this.subdivisionNames.map((it) => ({
              value: it,
              label: it,
            })),
          },
        },
        {
          label: 'Должность',
          key: 'roleTransform',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          type: 'roleModal',
          sort: {},
          filter: {
            type: 'select', values: this.getRoles.map((item) => ({ label: item.name, value: item.name })),
          },
        },
        {
          label: 'Наличие функций (куратор)',
          key: 'curator',
          thStyle: {
            minWidth: '100px',
          },
          sort: {},
        },
        {
          label: 'Действует на основании',
          key: 'transformPowerOfAttorney',
          thStyle: {
            minWidth: '100px',
          },
          type: 'select_powerOfAttorney',
        },
        {
          label: 'Статус сотрудника',
          key: 'statusName',
          thStyle: {
            minWidth: '100px',
          },
        },
      ];

      if (!this.isPlatformWorkerForCompany) {
        headers.push({
          key: 'crossDelete',
          type: 'crossDelete',
          checkCrossDelete: true,
        });
      }

      headers.push({
        key: 'followLink',
        type: 'followLink',
        checkOnlyOwnerIsNotOwner: true,
      });

      return {
        headers: headers,
      };
    },
    transformedData() {
      if (!this.getEmployees || !this.getNotAcceptedInvitations) return [];

      const employeesAndNotAccepted = [
        ...this.getEmployees,
        ...this.getNotAcceptedInvitations.map((item) => {
          const role = this.getRoles.find((role) => role.id === item.roleId);

          return {
            user: {
              email: item.email,
              id: item.id,
            },
            role: {
              name: role?.name,
              id: role?.id,
              subdivision: role?.subdivision,
            },
            status: 'INVITED',
          };
        }),
      ];

      return employeesAndNotAccepted.map((it) => ({
        ...it,
        name: it.user.fullName?.name,
        surname: it.user.fullName?.surname,
        patronymic: it.user.fullName?.patronymic,
        roleTransform: it.role?.name ?? 'Не назначена',
        transformPowerOfAttorney: it.role?.name === 'Генеральный директор' ? 'Устав' : it.transformPowerOfAttorney,
        email: it.user.email,
        subdivision: it.role?.subdivision?.name ?? 'Без подразделения',
        // eslint-disable-next-line no-nested-ternary
        statusName: it.role?.name === 'Генеральный директор' ? '' : (it.status === 'INVITED' ? 'Приглашён' : 'Действует'),
      })).sort((first, second) => {
        if (first.role?.name === 'Генеральный директор') {
          return -1;
        }
        if (second.role?.name === 'Генеральный директор') {
          return 1;
        }
        if (first.user.fullName && second.user.fullName) {
          const firstName = first.user.fullName;
          const secondName = second.user.fullName;

          const compareSurname = firstName.surname.localeCompare(secondName.surname);

          if (compareSurname === 0) {
            return firstName.name.localeCompare(secondName.name);
          }

          return compareSurname;
        } else if (first.user.fullName && !second.user.fullName) {
          return -1;
        } else if (!first.user.fullName && second.user.fullName) {
          return 1;
        }
        return first.user.email.localeCompare(second.user.email);

      });
    },
    userName() {
      if (!this.user) {
        return '';
      }
      if (!this.user.fullName)
        return this.user.email;
      const patronymic =
        this.user?.fullName?.patronymic
          ? this.user?.fullName?.patronymic
          : '';
      return `${this.user?.fullName?.name} ${this.user?.fullName?.surname} ${patronymic}`;
    },
    roles() {
      return this.getRoles
          .filter((it) => !it.isCompanyOwner && ((!it.subdivision && this.selectedSubdivision === 'Без подразделения') || (it.subdivision?.id === this.selectedSubdivision)))
          .map((item) => ({ ...item, label: item.name, value: item.id }));
    },
    subdivisions() {
      const subdivisions = [];
      this.getRoles.filter((it) => !it.isCompanyOwner && it.subdivision).forEach((role) => {
        if (!subdivisions.find((subdivision) => subdivision.id === role.subdivision.id)) {
          subdivisions.push({ name: role.subdivision.name, id: role.subdivision.id, label: role.subdivision.name, value: role.subdivision.id });
        }
      });
      return [{ name: 'Без подразделения', label: 'Без подразделения', value: 'Без подразделения' }, ...subdivisions];
    },
    disabled() {
      return this.isWaitingRoleSubmit ||
        this.isUserOwner ||
        this.isCurrentUser ||
        (this.selectedRoleId === this.selectedCheckRoleId && this.selectedSubdivision === this.selectedCheckSubdivision) ||
        !this.selectedRoleId;
    },
    checkOwner() {
      return this.getAuthorization.authorization.role.isCompanyOwner;
    },
    subdivisionNames() {
      return [...new Set(this.getRoles.map((it) => it.subdivision?.name ?? 'Без подразделения'))];
    },
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.company-employees';
    },
    ...mapGetters('employees', ['getEmployees', 'getRoles', 'getNotAcceptedInvitations']),
    ...mapGetters('user', ['getAccount', 'getUser', 'getAuthorization', 'isPlatformWorker']),
  },
  watch: {
    selectedRole(val) {
      this.selectedRoleId = val?.value ?? '';
    },
    selectedSubdivision(val) {
      if (this.selectedCheckSubdivision !== val) {
        this.selectedRoleId = '';
      }
    },
  },
  methods: {
    cellClickHandler(cell) {
      console.log(cell);
      switch (cell.key) {
        case 'crossDelete': {
          this.userForDelete = cell.row.user;

          if (cell.row.status === 'INVITED') {
            this.displayDeleteUserInvitationDialog = true;
            break;
          }
          this.displayDeleteDialog = true;
          break;
        }
        case 'roleTransform': {
          this.user = cell.row.user;
          this.isUserOwner = cell.row.role.isCompanyOwner;
          this.selectedRoleId = cell.row.role.id;
          this.selectedSubdivision = cell.row.role.subdivision?.id ?? 'Без подразделения';
          this.selectedCheckSubdivision = cell.row.role.subdivision?.id ?? 'Без подразделения';
          this.selectedCheckRoleId = cell.row.role.id;
          this.isCurrentUser = this.getUser.id === cell.row.user.id;
          this.isModalVisible = true;
          break;
        }
        case 'followLink': {
          this.$router.push({
            name: 'cabinet.employee-details',
            params: {
              id: cell.row.user.id,
              accountId: cell.row.account?.id,
            },
          });
          break;
        }
      }
    },
    async saveUserRole() {
      this.isWaitingRoleSubmit = true;

      this.xml = generateXml.PositionAppointmentOrder(this.getAccount.id, this.getUser.id, {
        userId: this.user.id,
        roleId: this.selectedRoleId,
      });

      try {
        await this.documentUpload({
          xml: this.xml,
        });
        this.isModalCheck = true;
        this.modalClose();
        this.$emit('load');
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.setNotification({ message: errorData.message });
      } finally {
        this.isWaitingRoleSubmit = false;
      }
    },
    cancel() {
      this.isModalCheck = true;
      this.modalClose();
    },
    modalClose() {
      if (this.isModalVisible && !this.isModalCheck) {
        this.isModalCheck = true;
        return;
      }
      this.isModalVisible = false;
      this.isModalCheck = false;
    },
    async deleteUserFromCompanyHandler(userId) {
      this.displayDeleteDialog = false;

      this.xml = generateXml.RemoveUserFromOrganisationApplication(this.getAccount.id, this.getUser.id, userId, new Date());
      console.log('XML', this.xml);
      try {
        await this.documentUpload({
          xml: this.xml,
        });
        this.SET_EMPLOYEES(this.getEmployees.filter((item) => item.user.id !== userId));
      } catch (error) {
        console.log(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      }
    },
    async deleteUserInvitationHandler(email) {
      this.displayDeleteUserInvitationDialog = false;

      this.xml = generateXml.RevokeUserInvitationApplication(email, new Date());

      try {
        await this.documentUpload({
          xml: this.xml,
        });
        await this.loadInvitationsFromServer();
      } catch (error) {
        console.log(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      }
    },
    ...mapMutations('employees', ['SET_EMPLOYEES', 'SET_ROLES']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('employees', ['loadInvitationsFromServer']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" module>
.deleteText {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #4C4E51;
  }

  &__info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4C4E51;

    span {
      font-weight: bold;
    }
  }

  &__input-group {
    display: flex;
    gap: 16px;

    &__input {
      &__title {
        margin-bottom: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #71757A;
      }

      &__select {
        position: relative;
        display: inline-block;
        width: 100%;

        &:after {
          content: url('~icons/basic/arrow_down_gray16x10.svg');
          position: absolute;
          right: 10px;
          top: 14px;
          pointer-events: none;
          box-sizing: border-box;
        }

        select {
          min-width: 240px;
          max-width: 240px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 28px 10px 8px;
          border: 1px solid #DEE2E6;
          appearance: none;
          cursor: pointer;
          outline: none;

          color: #000000;

          &:disabled {
            color: #000000;
          }
        }
      }
    }
  }

  &__btn-group {
    text-align: center;
  }
}
</style>
