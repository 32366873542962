<template>
  <v-table-new
    v-model="transformedLeftOverWarehouseElements"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="leftOverWarehouseElements.pageOut"
    :template="template"
    excel-report-file-name="Складские остатки. Элементы АХП по складам"
    server-side-sorting
    table-name="leftOverWarehouseElements"
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'WarehouseElementsLeftoverTable',
  components: { VTableNew },
  data() {
    return {
      leftOverWarehouseElements: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    template() {
      const headers = [];
      headers.push(
        {
          label: '№ П/П',
          key: 'index',
          type: 'index',
          thStyle: {
            whiteSpace: 'nowrap',
          },
        },
        {
          label: 'АХП',
          key: 'axpName',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
          filter: { type: 'axpOdk' },
        },
        {
          label: 'Название элемента',
          key: 'elementName',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
          filter: {},
        },
        {
          label: 'Всего элементов',
          key: 'elementTotal',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
        },
      );
      const warehouseSet = new Set();
      this.transformedLeftOverWarehouseElements.forEach((item) => {
        (item.warehousesWithQuantity || []).forEach((it) => {
          warehouseSet.add(it.warehouseName);
        });
      });
      warehouseSet.forEach((warehouseName) => {
        headers.push({
          label: `${warehouseName}`,
          key: `warehouse_${warehouseName}`,
          thStyle: {
            whiteSpace: 'nowrap',
          },
        });
      });

      return { headers };
    },
    transformedLeftOverWarehouseElements() {
      if (!this.leftOverWarehouseElements || !this.leftOverWarehouseElements.data) {
        return [];
      }
      return this.leftOverWarehouseElements.data.map(this.transformed);
    },
  },
  methods: {
    transformed(item, index) {
      const warehousesWithQuantity = item.warehousesWithQuantity?.reduce((acc, it) => {
        const key = `warehouse_${it.warehouseName}`;
        acc[key] = it.quantity;
        return acc;
      }, {});
      return {
        ...item,
        index: index + 1,
        axpName: item.axpName,
        elementName: item.elementName,
        elementTotal: item.elementTotal,
        ...warehousesWithQuantity,
      };
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const response = await fetchData('axp-element-by-warehouses', 1, this.leftOverWarehouseElements.pageOut.totalElements, sortField, sort, filters);

        return response.data?.map(this.transformed);
      } catch (error) {
        console.log('eftOverWarehouseElements', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = this.filter;
        const { field: sortField, type: sort  } = this.sort;
        const { page, size } = this.pagination;
        const response = await fetchData('axp-element-by-warehouses', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: response.page,
          totalElements: response.total,
          totalPages: response.last_page,
        };
        this.leftOverWarehouseElements = response;
        this.leftOverWarehouseElements.pageOut = this.pagination;
      } catch (error) {
        console.log('eftOverWarehouseElements', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
