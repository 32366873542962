const eighteenFormExcelPattern = {
  companyName: { colIndex: 1, rules: 'required|maxLength1024', title: 'Наименование сетевой организации', col: 2 },
  PESName: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование ПЭС', col: 3 },
  RESNameFirst: { colIndex: 3, rules: 'maxLength1024', title: 'Наименование РЭС (Моск. Обл.)', col: 4 },
  RESNameSecond: { colIndex: 4, rules: 'maxLength1024', title: '№ РЭС (Москва)', col: 5 },
  supplyPointName: {
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 6 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 7 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 8 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 9 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 10 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 11 },
  },
};

export const eighteenFormExcelCompanyPatternV1 = {
  rowNumberStart: 6,
  ...eighteenFormExcelPattern,
  ...{
    ikts: { colIndex: 11, rules: 'maxLength255', title: 'ИКТС', col: 12 },
    consumerName: { colIndex: 12, rules: 'required|maxLength1024', title: 'Наименование потребителя', col: 13 },
    subscriberNumber: { colIndex: 13, rules: 'required|maxLength1024', title: 'Номер абонента', col: 14 },
    location: { colIndex: 14, rules: 'maxLength1024', title: 'Населенный пункт', col: 15 },
    accountingPointAddress: { colIndex: 15, rules: 'required|maxLength1024', title: 'Адрес точки учёта', col: 16 },
    meter: {
      type: { colIndex: 18, rules: 'maxLength1024', title: 'Марка счётчика', col: 19 },
      serial: { colIndex: 16, rules: 'maxLength1024', title: '№ счётчика', col: 17 },
    },
    pointKind: {
      colIndex: 17,
      rules: 'required|allowedValues',
      listName: 'typeAccountingListLong',
      allowedValues: ['Р', 'К', 'Т', 'расчетный', 'расчётный', 'транзитный', 'контрольный', 'Расчётный', 'Расчетный', 'Транзитный', 'Контрольный'],
      title: 'Тип счётчика',
      col: 18,
    },
    tariffCategory1: {
      colIndex: 19,
      rules: 'allowedValues',
      listName: 'tariffCategoryTypeListNumber',
      allowedValues: ['По умолчанию', '1', '2', '3', '4', '5', '6'],
      title: 'ЦК',
      col: 20,
    },
    previousReading: { colIndex: 20, rules: 'number', title: 'Показания счётчика. Предыдущее', col: 21 },
    currentReading: { colIndex: 21, rules: 'number', title: 'Показания счётчика. Текущее', col: 22 },
    accountingRatio: { colIndex: 22, rules: 'number', title: 'Расчётный коэффициент', col: 23 },
    meterConsumption: { colIndex: 23, rules: 'number', title: 'Расход электроэнергии по счётчику, кВт.ч', col: 24 },
    lossFactor: {
      const: { colIndex: 24, rules: 'number', title: 'Потери, кВт.ч', col: 25 },
    },
    accountingMethods: {
      constAdditional: {
        colIndex: 25,
        rules: 'maxLength1024',
        title: 'Расчётный способ учёта электроэнергии, кВт.ч. Пост., Доп.',
        col: 26,
      },
      stat: { colIndex: 26, rules: 'number', title: 'Расчётный способ учёта электроэнергии, кВт.ч. Стат.', col: 27 },
      power: {
        colIndex: 27,
        rules: 'number',
        title: 'Расчётный способ учёта электроэнергии, кВт.ч. по Мощн., по Токовой нагр.',
        col: 28,
      },
      unaccounted: { colIndex: 28, rules: 'number', title: 'Расчётный способ учёта электроэнергии, кВт.ч. НУ', col: 29 },
    },
    totalConsumption: { colIndex: 29, rules: 'required|number', title: 'Общий расход, кВт.ч', col: 30 },
    voltage: {
      colIndex: 30,
      rules: 'required|allowedValues',
      listName: 'voltageClassList',
      allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
      title: 'Уровень напряжения экономический (по тарифу)',
      col: 31,
    },
    act: { colIndex: 31, rules: 'maxLength1024', title: '№ и дата акта', col: 32 },
    subscriberState: {
      colIndex: 32,
      rules: 'allowedValues',
      listName: 'subscriberStateList',
      allowedValues: ['Н', 'К', 'Р', 'С', 'Действующий', 'действующий'],
      title: 'Состояние абонента (Н, К, Р, С, Действующий)',
      col: 33,
    },
    meterState: {
      colIndex: 33,
      rules: 'allowedValues',
      listName: 'meterStateList',
      allowedValues: ['Н', 'К', 'Р', 'Л'],
      title: 'Состояние приборов учёта (Н, Л, Р, К)',
      col: 34,
    },
    appliedTariff: {
      colIndex: 34,
      rules: 'allowedValues',
      listName: 'appliedTariffList',
      allowedValues: ['Н', 'Д', 'П', 'П/П', 'К'],
      title: 'Признак применяемого тарифа (Д, Н, П, П/П, К)',
      col: 35,
    },
    tariffCategory2: {
      colIndex: 35,
      rules: 'allowedValues',
      listName: 'tariffCategoryTypeListNumber',
      allowedValues: ['По умолчанию', '1', '2', '3', '4', '5', '6'],
      title: 'Ценовая категория',
      col: 36,
    },
    statisticsGroup: { colIndex: 36, rules: 'maxLength1024', title: 'Группа статистики', col: 37 },
    tariffLink: { colIndex: 37, rules: 'maxLength1024', title: 'Ссылка на тариф', col: 38 },
    region: { colIndex: 38, rules: 'maxLength1024', title: 'Регион', col: 39 },
    remark: { colIndex: 39, rules: 'maxLength1024', title: 'Примечание', col: 40 },
    maxPowerObject: { colIndex: 40, rules: 'maxLength1024', title: 'Диапазон макс. мощности по объекту', col: 41 },
    curator: { colIndex: 41, rules: 'maxLength1024', title: 'Куратор', col: 42 },
    odpu: { colIndex: 42, rules: 'maxLength1024', title: 'Признак ОДПУ', col: 43 },
    flowCalculationAlgorithm: { colIndex: 43, rules: 'maxLength1024', title: 'Алгоритм расчёта расхода', col: 44 },
    schemeNumber: { colIndex: 44, rules: 'maxLength1024', title: '№ схемы', col: 45 },
    department: { colIndex: 45, rules: 'maxLength1024', title: 'Отделение', col: 46 },
    notation: { colIndex: 46, rules: 'maxLength1024', title: 'Примечание', col: 47 },
  },
};

export const eighteenFormExcelCompanyPatternV2 = {
  rowNumberStart: 3,
  ...eighteenFormExcelPattern,
  ...{
    headIkts: { colIndex: 11, rules: 'maxLength255', title: 'ИКТС головной ТУ', col: 12 },
    ikts: { colIndex: 12, rules: 'required|maxLength255', title: 'ИКТС ТУ', col: 13 },
    consumerName: { colIndex: 13, rules: 'required|maxLength1024', title: 'Наименование потребителя', col: 14 },
    legalAddress: {
      location: { colIndex: 14, rules: 'maxLength1024', title: 'Населённый пункт', col: 15 },
      street: { colIndex: 15, rules: 'maxLength1024', title: 'Улица', col: 16 },
      house: { colIndex: 16, rules: 'maxLength1024', title: 'Дом/корп./стр/офис', col: 17 },
    },
    contract: {
      number: { colIndex: 17, rules: 'required|maxLength1024', title: 'Номер договора', col: 18 },
      date: { colIndex: 18, rules: 'required|date', title: 'Дата заключения договора', col: 19 },
      terminateDate: { colIndex: 19, rules: 'date', title: 'Дом/корп./стр/офис', col: 20 },
    },
    subscriberNumber: { colIndex: 20, rules: 'required|maxLength1024', title: 'Номер объекта', col: 21 },
    physicalAddress: {
      location: { colIndex: 21, rules: 'maxLength1024', title: 'Населённый пункт', col: 22 },
      street: { colIndex: 22, rules: 'maxLength1024', title: 'Улица', col: 23 },
      house: { colIndex: 23, rules: 'maxLength1024', title: 'Дом/корп./стр/офис', col: 24 },
    },
    usagePointAddress: {
      location: { colIndex: 24, rules: 'required|maxLength1024', title: 'Населённый пункт', col: 25 },
      street: { colIndex: 25, rules: 'maxLength1024', title: 'Улица', col: 26 },
      house: { colIndex: 26, rules: 'maxLength1024', title: 'Дом/корп./стр/офис', col: 27 },
    },
    meter: {
      meterSerial: { colIndex: 27, rules: 'required|maxLength1024', title: '№ счетчика', col: 28 },
      pointKind: {
        colIndex: 28,
        rules: 'required|allowedValues',
        listName: 'typeAccountingListLong',
        allowedValues: ['Р', 'К', 'Т', 'расчетный', 'расчётный', 'транзитный', 'контрольный', 'Расчётный', 'Расчетный', 'Транзитный', 'Контрольный'],
        title: 'Тип счётчика',
        col: 29,
      },
      type: { colIndex: 29, rules: 'maxLength1024', title: 'Марка счётчика', col: 30 },
      digitCapacity: { colIndex: 30, rules: 'required|number', title: 'Значность', col: 31 },
      accountingRatio: { colIndex: 44, rules: 'required|number', title: 'Расчётный коэффициент', col: 45 },
      meterInstalledDate: { colIndex: 31, rules: 'date', title: 'Дата установки', col: 32 },
      meterReplacedDate: { colIndex: 32, rules: 'date', title: 'Дата замены', col: 33 },
    },
    readingsZoneS: {
      previousReading: { colIndex: 33, rules: 'number', title: 'Предыдущее (сутки)', col: 34 },
      currentReading: { colIndex: 34, rules: 'required|number', title: 'Текущее (сутки)', col: 35 },
    },
    readingsZoneD: {
      previousReading: { colIndex: 35, rules: 'number', title: 'Предыдущее (день)', col: 36 },
      currentReading: { colIndex: 36, rules: 'required|number', title: 'Текущее (день)', col: 37 },
    },
    readingsZoneN: {
      previousReading: { colIndex: 37, rules: 'number', title: 'Предыдущее (ночь)', col: 38 },
      currentReading: { colIndex: 38, rules: 'required|number', title: 'Текущее (ночь)', col: 39 },
    },
    readingsZoneP: {
      previousReading: { colIndex: 39, rules: 'number', title: 'Предыдущее (пик)', col: 40 },
      currentReading: { colIndex: 40, rules: 'required|number', title: 'Текущее (пик)', col: 41 },
    },
    readingsZonePP: {
      previousReading: { colIndex: 41, rules: 'number', title: 'Предыдущее (п/пик)', col: 42 },
      currentReading: { colIndex: 42, rules: 'required|number', title: 'Текущее (п/пик)', col: 43 },
    },
    readingSource: { colIndex: 43, rules: 'maxLength1024', title: 'Источник показаний', col: 44 },
    meterConsumption: { colIndex: 45, rules: 'number', title: 'Расход электроэнергии по счетчику, кВт.ч', col: 46 },
    meterLoss: {
      kWth: { colIndex: 46, rules: 'number', title: 'Потери в ПУ Квт.ч', col: 47 },
      percent: { colIndex: 47, rules: 'number', title: 'Потери в ПУ %', col: 48 },
    },
    lineLoss: {
      kWth: { colIndex: 48, rules: 'number', title: 'Потери в линии Квт.ч', col: 49 },
      percent: { colIndex: 49, rules: 'number', title: 'Потери в линии %', col: 50 },
    },
    transformerLoss: {
      kWth: { colIndex: 50, rules: 'number', title: 'Потери в трансформаторе Квт.ч', col: 51 },
      percent: { colIndex: 51, rules: 'number', title: 'Потери в трансформаторе %', col: 52 },
    },
    accountingMethods: {
      constAdditional: { colIndex: 52, rules: 'maxLength1024', title: 'Пост., Доп.', col: 53 },
      correction: { colIndex: 53, rules: 'number', title: 'Перерасчет/корректировка', col: 54 },
      stat: { colIndex: 54, rules: 'number', title: ' Стат.', col: 55 },
      power: { colIndex: 55, rules: 'number', title: 'по Мощн., по Токовой нагр.', col: 56 },
      unaccounted: { colIndex: 56, rules: 'number', title: 'НУ', col: 57 },
    },
    totalConsumption: { colIndex: 57, rules: 'required|number', title: 'Общий расход, кВт.ч', col: 58 },
    tariffCategory: {
      colIndex: 58,
      rules: 'allowedValues',
      listName: 'tariffCategoryTypeListNumber',
      allowedValues: ['По умолчанию', '1', '2', '3', '4', '5', '6'],
      title: 'Ценовая категория',
      col: 59,
    },
    tariffVoltage: {
      colIndex: 59,
      rules: 'required|allowedValues',
      listName: 'voltageClassList',
      allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
      title: 'Уровень напряжения /Тарифный',
      col: 60,
    },
    voltageLevel: { colIndex: 60, rules: 'number', title: 'Уровень напряжения /Физический', col: 61 },
    act: { colIndex: 61, rules: 'maxLength1024', title: '№ и дата акта', col: 62 },
    correctionStructure: {
      colIndex: 62,
      rules: 'maxLength1024',
      title: 'Структура перерасчета (корректировки)',
      col: 63,
    },
    recalculationReason: { colIndex: 63, rules: 'maxLength1024', title: 'Причина перерасчета', col: 64 },
    subscriberState: {
      colIndex: 64,
      rules: 'allowedValues',
      listName: 'subscriberStateList',
      allowedValues: ['Н', 'К', 'Р', 'С', 'Действующий', 'действующий'],
      title: 'Состояние абонента (Н, К, Р, С, Действующий)',
      col: 65,
    },
    meterState: {
      colIndex: 65,
      rules: 'allowedValues',
      listName: 'meterStateList',
      allowedValues: ['Н', 'К', 'Р', 'Л'],
      title: 'Состояние прибора учёта (Н, Л, Р, К)',
      col: 66,
    },
    externalId: { colIndex: 66, rules: 'maxLength1024', title: 'Код АИСКУЭ', col: 67 },
    consumerCategory: {
      colIndex: 67,
      rules: 'allowedValues',
      listName: 'consumerCategoryList',
      allowedValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      title: 'Категория потребителя',
      col: 68,
    },
    appliedTariff: { colIndex: 68, rules: 'number', title: 'Код АИСКУЭ', col: 69 },
    remark: { colIndex: 69, rules: 'maxLength1024', title: 'Примечание', col: 70 },
  },
};
