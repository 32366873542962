<template>
  <div class="department-selector">
    <span class="department-selector__title">
      Отделение/Филиал
    </span>
    <select
      v-model="selectedDepartment.id"
      :disabled="!editable"
      class="department-selector__select"
      @change="changeHandler(selectedDepartment.id)"
    >
      <option
        v-for="(department, index) in departments"
        :key="index"
        :value="department.id"
      >
        {{ department.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    inn: {
      type: String,
      required: true,
    },
    initDepartment: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDepartment: {
        id: null,
      },
      departments: [],
    };
  },
  watch: {
    inn: {
      immediate: true,
      async handler() {
        await this.update();
      },
    },
    selectedDepartment: {
      handler(val) {
        this.$emit('select', { ...val });
      },
    },
  },
  methods: {
    ...mapActions('dataLists', ['supplierDepartments']),
    async update() {
      try {
        this.departments = [...await this.supplierDepartments({ inn: this.inn })];
      } catch (e) {
        console.log('can`t get departments', e);
        this.departments = [];
      }
      this.departments.push({ id: null, name: 'Без отделения' });
      if (this.initDepartment && this.inn === this.initDepartment.inn) {
        this.changeHandler(this.initDepartment.id);
        return;
      }
      this.changeHandler(null);
    },
    changeHandler(companyId) {
      this.selectedDepartment = { ...this.departments.find((employee) => employee.id === companyId), inn: this.inn };
    },
  },
};
</script>

<style lang="scss" scoped>
.department-selector {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
  }

  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;

    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
      opacity: 1;
    }

    &_error {
      border-color: #EB5757;
    }
  }
}
</style>
