<template>
  <div class="simple-indicator">
    <div class="simple-indicator__wrp">
      <div :style="`background:${color}`" class="simple-indicator__wrp__indicator"/>
      <span class="simple-indicator__wrp__text">
      {{ label }}
    </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSimpleIndicator',
  props: {
    color: {
      type: String,
      default: '#FF7400',
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-indicator {

  &__wrp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px;
    border: 1px solid #C1C4C7;
    background: white;
    padding: 8px;
    min-width: 164px;
    max-height: 26px;

    &__indicator {
      border-radius: 100%;
      background: #FFF;
      width: 8px;
      margin-right: 8px;
      height: 8px;
      justify-content: center;
      align-items: center;
    }

    &__text {
      white-space: nowrap;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #263238;
    }
  }

}
</style>
