<template>
  <page title="Показания точек учёта">
    <data-tab-selector
      :data-types-list="tabs"
      class="cabinet-consumers-card__select"
      tab-style="table"
      @selectTab="selectTabHandler"
    />

    <receiving-measurements v-if="selectedTab === 'RECEIVING_POINT'" :addition-kind="selectedTab" />
    <delivery-measurements v-else-if="selectedTab === 'DELIVERY_POINT'" :addition-kind="selectedTab" />
    <transmission-measurements v-else-if="selectedTab === 'TRANSFER_POINT'" :addition-kind="selectedTab" />
    <technical-measurements v-else-if="selectedTab === 'TECHNICAL_POINT'" :addition-kind="selectedTab" />
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import DeliveryMeasurements from './components/ContentBlock/DeliveryMeasurements';
import ReceivingMeasurements from './components/ContentBlock/ReceivingMeasurements';
import TechnicalMeasurements from './components/ContentBlock/TechnicalMeasurements';
import TransmissionMeasurements from './components/ContentBlock/TransmissionMeasurements';

export default {
  name: 'Measurements',
  components: {
    TechnicalMeasurements,
    TransmissionMeasurements,
    ReceivingMeasurements,
    DeliveryMeasurements,
    DataTabSelector,
    Page,
  },
  data() {
    return {
      selectedTab: 'RECEIVING_POINT',
      tabs: [
        {
          id: 1,
          title: 'Приём',
          value: 'RECEIVING_POINT',
          isActive: true,
        },
        {
          id: 2,
          title: 'Поставка',
          value: 'DELIVERY_POINT',
          isActive: false,
        },
        {
          id: 3,
          title: 'Передача',
          value: 'TRANSFER_POINT',
          isActive: false,
        },
        {
          id: 4,
          title: 'Техническая',
          value: 'TECHNICAL_POINT',
          isActive: false,
        },
      ],
    };
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
