<template>
  <dialog-template v-model="isModalOpen" center collapse @close="onClosePowerOfAttorney()">
    <v-dialog-header
      subtitle=""
      title="Загрузка доверенности"
    />
    <div :class="$style['button-power-of-attorney']">
      <p>Выдается</p>
      <select v-model="selectedUser" :class="$style.select">
        <option :value="{}" disabled selected>Выберите сотрудника</option>
        <option v-for="(it, index) in validUsers" :key="index" :value="it">{{ userName(it) }}</option>
      </select>
      <c-date-picker
        v-model="grantedAt"
        format="DD.MM.YYYY"
        label="Дата выдачи доверенности"
        type="day"
      />
      <p v-if="!grantedAtEmpty&&!grantedAtBeforeNow" :class="$style['error-text']">Дата выдачи должна быть меньше или
        равна текущей дате</p>
      <c-date-picker
        v-model="startsFrom"
        format="DD.MM.YYYY"
        label="Дата вступления в силу доверенности"
        style="margin-top: 8px"
        type="day"
      />
      <p v-if="!startsFromEmpty&&!grantedAtEmpty&&!startsFromAfterGrantedAt" :class="$style['error-text']">Дата начала
        действия должна быть больше или равна дате выдачи</p>
      <c-date-picker
        v-model="expiresAt"
        format="DD.MM.YYYY"
        label="Дата истечения доверенности"
        style="margin-top: 8px"
        type="day"
      />
      <p v-if="!expiresAtAfterNow&&!expiresAtEmpty" :class="$style['error-text']">Дата истечения должна быть больше чем
        текущая дата</p>
      <p v-if="!startsFromEmpty&&!expiresAtEmpty&&!expiresAtAfterStartsFrom" :class="$style['error-text']">Дата
        истечения должна быть больше чем дата вступления в силу действия</p>
      <c-uploading
        v-model="powerOfAttorney"
        :class="$style.filledDocuments"
      />
      <div v-if="powerOfAttorney && powerOfAttorney[0]" :class="$style.attachment">
        <a :class="$style.filePowerOfAttorney" :download="powerOfAttorney.name"
           :href="fileUrl(powerOfAttorney[0])">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40" />
          <span>{{ powerOfAttorney[0].name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" title="удалить" @click="deletePowerOfAttorney()">
          <icon-delete />
        </a>
      </div>
    </div>
    <horizontal-progress-bar v-if="isPowerOfAttorneySubmit" />
    <submit-task-details
      :disabled="disabled"
      title="Сохранить"
      @submit="onSubmitPowerOfAttorney(grantedAt, startsFrom, expiresAt)"
    />
  </dialog-template>
</template>

<script>
import { VDialogHeader } from '@/components';
import CDatePicker from 'atoms/common/inputs/CDatePicker';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';

export default {
  name: 'FormAttorney',
  components: {
    VDialogHeader,
    CDatePicker,
    DialogTemplate,
    SubmitTaskDetails,
    HorizontalProgressBar,
    CUploading: () => import('atoms/common/CUploading.vue'),
    IconDelete: () => import('atoms/icons/action/Delete'),
  },
  data() {
    return {
      isModalOpen: true,
      powerOfAttorney: [],
      expiresAt: '',
      startsFrom: '',
      grantedAt: '',
      isPowerOfAttorneySubmit: false,
      selectedUser: {},
    };
  },
  computed: {
    validUsers() {
      return this.getEmployees.filter((it) => it.user.fullName && it.user.id !== this.getUser.id);
    },
    grantedAtBeforeNow() {
      return !this.grantedAtEmpty && dayjs(this.grantedAt, 'DD.MM.YYYY', true).diff(dayjs().startOf('day')) <= 0;
    },
    startsFromAfterGrantedAt() {
      return !this.startsFromEmpty && !this.grantedAtEmpty && dayjs(this.grantedAt, 'DD.MM.YYYY', true).diff(dayjs(this.startsFrom, 'DD.MM.YYYY', true)) <= 0;
    },
    expiresAtAfterNow() {
      return !this.expiresAtEmpty && dayjs().startOf('day').diff(dayjs(this.expiresAt, 'DD.MM.YYYY', true)) < 0;
    },
    expiresAtAfterStartsFrom() {
      return !this.expiresAtEmpty && !this.startsFromEmpty && dayjs(this.startsFrom, 'DD.MM.YYYY', true).diff(dayjs(this.expiresAt, 'DD.MM.YYYY', true)) < 0;
    },
    expiresAtEmpty() {
      return !this.expiresAt.length > 0;
    },
    startsFromEmpty() {
      return !this.startsFrom.length > 0;
    },
    grantedAtEmpty() {
      return !this.grantedAt.length > 0;
    },
    disabled() {
      return !this.powerOfAttorney || !this.powerOfAttorney[0] || Object.keys(this.selectedUser).length === 0 ||
        this.expiresAtEmpty || this.startsFromEmpty || this.grantedAtEmpty || !this.startsFromAfterGrantedAt || !this.expiresAtAfterStartsFrom || !this.expiresAtAfterNow || !this.grantedAtBeforeNow;
    },
    ...mapGetters('employees', ['getEmployees']),
    ...mapGetters('attorney', ['getPowerOfAttorneys']),
    ...mapGetters('user', ['getUser']),
  },
  methods: {
    async onSubmitPowerOfAttorney(grantedAt, startsFrom, expiresAt) {
      this.isPowerOfAttorneySubmit = true;
      console.log('log', this.powerOfAttorney, this.isPowerOfAttorneySubmit);

      const xmlPowerOfAttorney = generateXml.addPowerOfAttorneyToUser(this.selectedUser, this.powerOfAttorney[0], grantedAt, startsFrom, expiresAt);

      try {
        await this.documentUpload({ xml: xmlPowerOfAttorney });
        await this.$emit('load');
        this.$emit('close');
        this.selectedUser = {};
        this.expiresAt = '';
        this.powerOfAttorney = [];
      } catch (e) {
        console.log(e);
      } finally {
        this.isPowerOfAttorneySubmit = false;
      }
    },
    onClosePowerOfAttorney() {
      this.selectedUser = {};
      this.expiresAt = '';
      this.powerOfAttorney = [];
      this.$emit('close');
    },
    deletePowerOfAttorney() {
      this.powerOfAttorney = [];
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    userName(employee) {
      return `${employee.user.fullName.surname} ${employee.user.fullName.name} ${employee.user.fullName.patronymic ? employee.user.fullName.patronymic : ''}, ${employee.user.email}`;
    },
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" module>
.button-power-of-attorney {
  padding: 8px;
}

.error-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 12px;
  color: red;
}

.attachment {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0E0F0F;
  display: flex;
  justify-content: space-between;
}

.filePowerOfAttorney {
  display: flex;
  align-items: center;
  margin-top: 12px;

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }

  color: #0E0F0F
}

.downloadIcon {
  color: #4C4E51;
  display: flex;
  align-items: center;
}

.select {
  height: 40px;
  width: 100%;
  font-size: 18px;
  padding-left: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
  border: 1px solid #141412;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid #2f82df;
  }
}
</style>
