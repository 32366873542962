<template>
  <draggable v-if="isModalVisible" class="draggable-modal">
    <template slot="header">
      <div class="draggable-modal__header">
        <button @click="changeModalBodyVisible">
          <!-- hide/show modal body button -->
        </button>
        <button
          class="btn-close"
          @click="close">
        </button>
      </div>
    </template>
    <template slot="body">
      <modal
        v-if="true"
        :is-btn-close-visible="false"
        :is-backdrop="false"
        :modal-wrapper-style="{
          position: 'relative',
        }"
        :header-style="{
          height: '0',
          minHeight: '0',
        }"
        :body-style="{
          padding: '0',
        }"
      >
        <template slot="body">
          <div v-show="modalBodyVisible">
            <slot name="modalBody">
            </slot>
          </div>
        </template>
      </modal>
    </template>
  </draggable>
</template>

<script>
import {
  Modal,
  Draggable,
} from '@/components';

export default {
  components: {
    Modal,
    Draggable,
  },
  // TODO: add props by buisness task
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalBodyVisible: true,
    };
  },
  methods: {
    changeModalBodyVisible() {
      this.modalBodyVisible = !this.modalBodyVisible;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-modal {
  box-shadow:
    rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 48px;

  z-index: 1051;
  background-color: #FFFFFF;

  &__header {
    min-height: 36px;
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 18px;
    height: 18px;
    z-index: 1051;
    background-image: url('~icons/modal/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
    background-color: #FFFFFF;
  }
}
</style>
