import { convertDbFormat } from 'lib/utils/date';

const getContract = (state) => state;

const getContractTemplates = (state) => {
  console.log('getContractTemplates', state.contractSides, state);
  if (!state.contractSides) {
    return [];
  }

  return state.contractSides.map((contractSide) => ({
    sides: contractSide.sides,
    xmlContent: contractSide.xmlContent,
    label: contractSide.name,
    value: contractSide.mnemonicType,
  }));
};

const getContractSideCompanyValue = (state) => (sideName) => {
  if (!state.selectedTemplate) {
    return [];
  }

  return state.selectedTemplate.sides.find((it) => it.contractSide === sideName).credentials.map((contractSide) => ({
    label: contractSide.name,
    value: contractSide.inn,
  }));
};

const getContractSideCompanyBankRequisites = (state) => (sideName) => {
  if (!state.selectedTemplate) {
    return [];
  }

  let bankRequisites = [];

  state.selectedTemplate.sides.find((it) => it.contractSide === sideName).credentials.forEach((contractSide) => {
    bankRequisites = [
        ...bankRequisites,
        ...contractSide.bankRequisites.map((item) => ({
          inn: contractSide.inn,
          ...item,
        })),
    ];
  });

  const countItems = {};

  for (const item of bankRequisites) {
    countItems[item.bankName] = countItems[item.bankName] ? countItems[item.bankName] + 1 : 1;
    if (countItems[item.bankName] > 1) item.bankName = `${item.bankName} ${countItems[item.bankName]}`;
  }

  return bankRequisites;
};

const getErrors = (state) => {
  if (state.errors) {
    return state.errors;
  }
};

const getPatternName = (state) => {
  if (state.type === 'contract') return state?.selectedTemplate?.name ?? 'Договор об оказании услуг по передаче электрической энергии (мощности)';
  return `${state?.selectedTemplate?.name ?? 'Дополнительное соглашение'} к договору ${state.sourceContractNumber ?? '__'} от ${convertDbFormat(state.sourceContractDate) ?? '__'}`;
};

const getEditableSides = (state) => state.editableSides;

const getRejectedContracts = (state) => state.rejectedContracts;

export default {
  getRejectedContracts,
  getContract,
  getContractTemplates,
  getErrors,
  getEditableSides,
  getContractSideCompanyValue,
  getContractSideCompanyBankRequisites,
  getPatternName,
};
