var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('modal',{staticClass:"tags-modal",attrs:{"body-style":{
        padding: '24px 0',
        overflowY:'initial'
      },"header-style":{
        display: 'flex',
        justifyContent: 'center',
      },"is-backdrop":true,"is-btn-close-visible":false,"modal-style":{
        borderRadius: '16px',
        padding: '24px',
      }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("Тэги ОДК")])]},proxy:true},{key:"body",fn:function(){return [_c('div',_vm._l((_vm.tags),function(tag,idx){return _c('div',{key:idx},[_c('div',{staticClass:"tags-modal__field-wrp"},[_c('div',{staticClass:"tags-modal__field"},[_c('tag-autocomplete',{attrs:{"fetch-data-function":_vm.fetchTags,"fetch-default-data-function":_vm.fetchTags,"items":_vm.tags[idx].names,"search":_vm.tags[idx].name,"is-required":""},on:{"update:items":function($event){return _vm.$set(_vm.tags[idx], "names", $event)},"update:search":function($event){return _vm.$set(_vm.tags[idx], "name", $event)}},model:{value:(_vm.tags[idx].id),callback:function ($$v) {_vm.$set(_vm.tags[idx], "id", $$v)},expression:"tags[idx].id"}})],1),_c('button',{staticClass:"tags-modal__btn",on:{"click":function($event){return _vm.remove(idx)}}},[_c('cross-delete')],1)])])}),0),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-button',{staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.canBeAdded},on:{"click":_vm.add}},[_c('span',[_vm._v("Добавить")])])],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.loading)?_c('horizontal-progress-bar',{staticClass:"tags-modal__progress-bar"}):_vm._e(),(_vm.error?.length > 0)?_c('div',{staticClass:"errorText"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-button',{attrs:{"variant":"outlined"},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-button',{attrs:{"disabled":_vm.loading},on:{"click":_vm.save}},[_c('span',[_vm._v("Сохранить")])])],1)]},proxy:true}],null,false,3869481968)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }