<template>
  <img
    v-if="isShow"
    :class="{
      [$style.clearable]: true,
    }"
    alt="#"
    src="~@/assets/icons/close.svg"
    v-bind="$attrs"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'VClearableInput',
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    cinput: {
      type: Boolean,
      default: false,
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.clearable {
  cursor: pointer;
}
</style>
