<template>
  <div class="company-hierarchy">
    <div class="company-hierarchy__title">
      <div>
        <h1>Иерархия компании</h1>
      </div>
      <div class="company-hierarchy__title__button">
        <template v-if="selectedTab === 'hierarchy'">
          <select-button
              :disabled="!supplierInn && isPlatformWorkerForCompany"
              :display-icon="false"
              :items="[{
                id: 1,
                title: 'Добавить подразделение',
                method: addDepartment,
              }]"
          />
          <select-button
              :disabled="!supplierInn && isPlatformWorkerForCompany"
              :display-icon="false"
              :items="[{
                id: 1,
                title: 'Добавить должность',
                method: addRole,
              }]"
          />
        </template>

        <v-search-select
            v-if="isPlatformWorkerForCompany"
            v-model="selectedSupplierInn"
            :options="companies"
            style="min-width: 400px"
            placeholder="Выберите компанию"
        />
      </div>
    </div>
    <v-divider style="margin-bottom: 16px"/>
    <data-tab-selector
        :data-types-list="tabs"
        :disabled="!supplierInn && isPlatformWorkerForCompany"
        class="company-hierarchy__tabs"
        tab-style="table"
        @selectTab="selectTabHandler"
    />

    <template v-if="selectedTab === 'subdivision'">
      <v-table-new
          v-model="transformedSubdivision"
          :action-block-items="supplierInn ? subdivisionActionBlockItems : undefined"
          :loading="loading"
          :template="templateSubdivision"
          excel-report-file-name="Иерархия компании. Подразделения"
          @delete="(cell) => { unselect(); selectRole(cell.row.id); deleteDialog = true; }"
          @edit="(cell) => roleClick(cell.row.id, 'bottom')"
      />
    </template>

    <template v-else-if="selectedTab === 'roles'">
      <v-table-new
          v-model="transformedRoles"
          :action-block-items="rolesActionBlockItems"
          :loading="loading"
          :template="templateRoles"
          excel-report-file-name="Иерархия компании. Должности"
          @delete="(cell) => { unselect(); selectRole(cell.row.id); deleteDialog = true; }"
          @edit="(cell) => roleClick(cell.row.id, 'top')"
      />
    </template>

    <div class="company-hierarchy__content">
      <div class="company-hierarchy__content-chart">
        <template v-if="selectedTab === 'hierarchy'">
          <tree-chart
            :get-parent-id="getParentId"
            :grouping="{ in: 'Подразделение', out: 'Без отдела', propName: 'subdivision' }"
            :ignore-node="ignoreRole"
            :is-grouping="true"
            :list="roleList"
            :top-node="gd"
            :unselect="!selectedTarget"
            :zoom="zoom"
            class="company-hierarchy__content-chart-data"
            node-view="SubdivisionNode"
            @click="roleClick"
          />

          <div class="company-hierarchy__content-chart__zoom">
            <div class="company-hierarchy__content-chart__zoom-plus" @click="zoomIn">
              <img src="@/assets/icons/basic/plus_circle.svg">
            </div>
            <div class="company-hierarchy__content-chart__zoom-minus" @click="zoomOut">
              <img src="@/assets/icons/basic/minus_circle.svg">
            </div>
          </div>
        </template>
      </div>

      <div class="company-hierarchy__content-control">
        <modal
          v-if="selectedTarget === 'bottom'"
          :body-style="{
            padding: '0',
          }"
          :header-style="{
            minHeight: '0',
          }"
          :is-backdrop="true"
          :is-btn-close-visible="false"
          :modal-style="{
            borderRadius: '20px 20px 0 0',
            padding: '0',
            width: '400px',
          }"
          height="740px"
          width="400px"
        >
          <template #header>
            <div class="company-hierarchy__content-control__title">
              <settings/>
              <span>Карточка подразделения</span>
              <img class="company-hierarchy__content-control__title-arrow"
                   src="@/assets/icons/arrows/arrow-down-white.svg" @click="unselect">
            </div>
          </template>
          <template #body>
            <div class="company-hierarchy__content-control-department">
              <v-form @submit="onSubmitDepartment">
                <div class="company-hierarchy__content-control-department__info">
                  <v-input
                    v-model="selectedRole.subdivisionName"
                    :rules-messages="{required: 'Обязательно для заполнения'}"
                    clearable
                    rules="required"
                    title="Название *"
                    :disabled="isWaitingSubmit"
                  />

                  <v-input
                    v-model="selectedRole.name"
                    :rules-messages="{required: 'Обязательно для заполнения'}"
                    clearable
                    rules="required"
                    :disabled="isWaitingSubmit"
                    title="Наименование должности руководителя *"
                  />

                  <v-input
                    v-model="selectedRole.phoneNumber"
                    :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                    :rules-messages="{regex: 'Неправильно введён номер телефона'}"
                    clearable
                    rules="regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
                    title="Номер телефона"
                    :disabled="isWaitingSubmit"
                  />

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <v-select
                    v-model="selectedRole.parentRoleId"
                    :options="ownerRolesList"
                    rules="required"
                    title="Подчинение *"
                    :disabled="isWaitingSubmit"
                  />

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <v-select
                    v-model="selectedRole.districtDepartmentId"
                    :options="districtDepartmentsList"
                    style="margin-top: 24px"
                    title="Районное подразделение"
                    :disabled="isWaitingSubmit"
                  />

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <v-select
                    v-model="selectedRole.shift"
                    :options="shiftList"
                    style="margin-top: 24px"
                    title="Смена"
                    :disabled="isWaitingSubmit"
                  />

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <div class="company-hierarchy__content-control-department__info-children">
                    <span>Состав должностей</span>

                    <select-button
                      :display-icon="false"
                      :items="[{
                        title: 'Добавить должность',
                        method: addChildRole,
                      }]"
                        :disabled="isWaitingSubmit"
                        class="company-hierarchy__content-control-department__info-children__button"
                        height="32"
                    />

                    <div v-if="selectedRole.childrenList" :key="updateChildrenList"
                         class="company-hierarchy__content-control-department__info-children__list">
                      <div v-for="(child, index) in selectedRole.childrenList.filter(role => !role.isSubdivisionOwner)"
                           :key="index"
                           class="company-hierarchy__content-control-department__info-children__item"
                           @click="updateRole(child, index)">
                        <span>{{ child.name }}</span>
                        <div class="company-hierarchy__content-control-department__info-children__item__controls">
                          <info-gray/>
                          <img height="12" src="@/assets/icons/close.svg" @click="(e) => deleteRole(e, index)"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="isWaitingSubmit && !deleteDialog">
                    <horizontal-progress-bar />
                  </div>

                  <v-divider v-else class="company-hierarchy__content-control-department__info-divider"/>

                  <div class="company-hierarchy__content-control-department__info-buttons">
                    <v-button
                      :disabled="!selectedRole.id || isWaitingSubmit"
                      class="company-hierarchy__content-control-department__info-buttons__delete"
                      type="button"
                      variant="outlined-red"
                      @click="deleteDialog = true"
                    >
                      <delete fill="#EB5757"/>
                      <span>Удалить подразделение</span>
                    </v-button>
                    <v-button :disabled="isWaitingSubmit || !isChanged"
                              class="company-hierarchy__content-control-department__info-buttons__next"
                              type="submit">
                      <span v-if="selectedRole.id">Сохранить</span>
                      <span v-else>Добавить</span>
                      <arrow-right/>
                    </v-button>
                  </div>

                </div>
              </v-form>
            </div>
          </template>
        </modal>
        <modal
          v-if="selectedTarget === 'top'"
          :body-style="{
            padding: '0',
          }"
          :header-style="{
            minHeight: '0',
          }"
          :is-backdrop="true"
          :is-btn-close-visible="false"
          :modal-style="{
            borderRadius: '20px 20px 0 0',
            padding: '0',
            width: '400px',
          }"
          height="740px"
          width="400px"
        >
          <template #header>
            <div class="company-hierarchy__content-control__title">
              <info-gray/>
              <span>Карточка должности</span>
              <img class="company-hierarchy__content-control__title-arrow"
                   src="@/assets/icons/arrows/arrow-down-white.svg" @click="unselect">
            </div>
          </template>
          <template #body>
            <div class="company-hierarchy__content-control-department">
              <v-form @submit="onSubmitRole">
                <div class="company-hierarchy__content-control-department__info">
                  <v-input
                    v-model="selectedRole.name"
                    :disabled="isDisabledRole"
                    :rules-messages="{required: 'Обязательно для заполнения'}"
                    clearable
                    rules="required"
                    title="Должность *"
                  />

                  <div v-if="selectedRole.isSubdivisionOwner"
                       class="company-hierarchy__content-control-department__info-label company-hierarchy__content-control-department__info-label--disabled">
                    <input checked="checked" disabled="disabled" type="checkbox"/>
                    Является руководителем
                  </div>

                  <v-select
                    v-model="selectedRole.subdivisionId"
                    :disabled="selectedRole.withoutSubdivision || isDisabledRole || selectedRole.isSubdivisionOwner"
                    :options="subdivisionList"
                    :rules="!selectedRole.withoutSubdivision ? 'required' : ''"
                    title="Подразделение *"
                    @input="changeSubdivision"
                  />

                  <div class="company-hierarchy__content-control-department__info-label">
                    <input v-model="selectedRole.withoutSubdivision"
                           :disabled="selectedRole.isCompanyOwner || selectedRole.isSubdivisionOwner"
                           type="checkbox"
                           @input="changeWithoutSubdivision"/>
                    Должность без подразделения
                  </div>

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <v-select
                    v-model="selectedRole.parentRoleId"
                    :disabled="isDisabledRole"
                    :options="ownerRolesList"
                    title="Кому подчиняется *"
                    rules="required"
                    @input="changeParent"
                  />

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <div class="company-hierarchy__content-control-department__info-children">
                    <div class="company-hierarchy__content-control-department__info-children__title">
                      <span>Полномочия</span><img height="24" src="@/assets/icons/help/question_double_circle.svg"
                                                  @click="addPermission"/>
                    </div>

                    <select-button
                      v-if="!isDisabledRole"
                      :display-icon="false"
                      :items="[{
                        title: 'Добавить права',
                        method: addPermission,
                      }]"
                      class="company-hierarchy__content-control-department__info-children__button"
                      height="32"
                    />

                    <div v-if="selectedRole.permissions?.length > 0"
                         :key="updateChildrenList"
                         :style="spoilerPermissions ? {maxHeight: '80px'} : {}"
                         class="company-hierarchy__content-control-department__info-children__list-permissions"
                    >
                      <div v-for="(permission, index) in selectedRole.permissions" :key="index"
                           class="company-hierarchy__content-control-department__info-children__item">
                        <span>{{ permission.code }}</span>
                        <div v-if="!isDisabledRole"
                             class="company-hierarchy__content-control-department__info-children__item__controls">
                          <img height="12" src="@/assets/icons/close.svg" @click="deletePermission(index)"/>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="selectedRole.permissions?.length > 0"
                      class="company-hierarchy__content-control-department__info-children__spoiler"
                      @click="spoilerPermissions = !spoilerPermissions"
                    >
                      <img v-if="spoilerPermissions" height="12" src="@/assets/icons/arrows/arrow-down.svg"/>
                      <img v-else height="12" src="@/assets/icons/arrows/arrow-up.svg"/>
                    </div>
                  </div>

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <div class="company-hierarchy__content-control-department__info-children">
                    <div class="company-hierarchy__content-control-department__info-children__title">
                      <span>Доступные пункты меню</span><img height="24"
                                                             src="@/assets/icons/help/question_double_circle.svg"
                                                             @click="addPermission"/>
                    </div>

                    <div v-if="selectedRole.permissions?.length > 0" :key="updateChildrenList">
                      <div v-for="(route, index) in getAllowedRoutesByPermissions(selectedRole.permissions)"
                           :key="index" class="company-hierarchy__content-control-department__info-children__menu">
                        {{ route }}
                      </div>
                    </div>
                  </div>

                  <v-divider class="company-hierarchy__content-control-department__info-divider"/>

                  <div v-if="isWaitingSubmit && !deleteDialog">
                    <horizontal-progress-bar/>
                  </div>


                  <div v-if="!isDisabledRole" class="company-hierarchy__content-control-department__info-buttons">
                    <v-button
                      :disabled="!selectedRole.id || isWaitingSubmit"
                      class="company-hierarchy__content-control-department__info-buttons__delete"
                      type="button"
                      variant="outlined-red"
                      @click="deleteDialog = true"
                    >
                      <delete fill="#EB5757"/>
                      <span>Удалить должность</span>
                    </v-button>
                    <v-button :disabled="isWaitingSubmit"
                              class="company-hierarchy__content-control-department__info-buttons__next"
                              type="submit">
                      <span v-if="selectedRole.id">Сохранить</span>
                      <span v-else>Добавить</span>
                      <arrow-right/>
                    </v-button>
                  </div>
                </div>
              </v-form>
            </div>
          </template>
        </modal>
      </div>
    </div>

    <permissions
      v-if="displayPermissionsDialog"
      :blocks="blocks"
      :is-editable-role-name="isEditableRoleName"
      :role="updatePermissionsRole"
      @close="closePermissions"
      @save="updatePermissions"
    />
    <modal
      v-if="deleteDialog"
      :body-style="{
          padding: '24px 0',
        }"
      :header-style="{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '18px',
        }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
          borderRadius: '16px',
          padding: '24px 18px',
          textAlign: 'center',
          fontSize: '16px',
        }"
    >
      <template #header>
        <span v-if="selectedTarget === 'bottom'">Удаление подразделения</span>
        <span v-else>Удаление должности</span>
      </template>
      <template #body>
        <span
          v-if="selectedTarget === 'bottom'">Будут удалены все должности, входящие в подразделение {{
            selectedRole.subdivisionName
          }}. Удалить?</span>
        <span v-else-if="selectedRole.isSubdivisionOwner">Будут удалена руководящая должность и все должности, входящие в подразделение {{
            selectedRole.subdivisionName
          }}. Удалить?</span>
        <span v-else>Удалить должность {{ selectedRole.name }}?</span>

        <horizontal-progress-bar v-if="isWaitingSubmit" style="margin-top: 8px"/>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-between">
          <v-button :disabled="isWaitingSubmit" type="button" variant="outlined" @click="deleteDialog = false">
            <span>Отмена</span>
          </v-button>
          <v-button :disabled="isWaitingSubmit" type="button" variant="outlined-red" @click="onDelete">
            <span>Удалить</span>
          </v-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  DataTabSelector,
  Modal,
  SelectButton,
  TreeChart,
  VButton,
  VDivider,
  VForm,
  VInput,
  VSelect,
  VTableNew,
} from 'components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Settings from 'atoms/icons/basic/Settings.vue';
import InfoGray from 'atoms/icons/basic/InfoGray.vue';
import Delete from 'atoms/icons/action/Delete.vue';
import ArrowRight from 'atoms/icons/arrows/ArrowRight.vue';
import Permissions from './components/Permissions.vue';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { getAllVueRoutes } from 'utils/common';
import { isAccess } from 'lib/utils/permissions';
import { isEqual } from 'lodash';
import formatting from 'lib/utils/formatting';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import { ACCOUNT_PROFILE_TYPE } from 'views/private/UserManagement/AccountProfileType';

export default {
  name: 'CompanyHierarchy',
  components: {
    TreeChart,
    VDivider,
    SelectButton,
    Settings,
    VInput,
    VSelect,
    InfoGray,
    VButton,
    Delete,
    ArrowRight,
    Permissions,
    VForm,
    HorizontalProgressBar,
    Modal,
    DataTabSelector,
    VTableNew,
    VSearchSelect,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      selectedTarget: '',
      selectedRole: {},
      selectedRoleOld: {},
      updateChildrenList: 1,
      updatePermissionsRole: {},
      displayPermissionsDialog: false,
      isEditableRoleName: false,
      possiblePermissions: [{ code: '', description: '' }],
      blocks: [{ label: '', value: '' }],
      roleList: [],
      districtDepartmentsList: [],
      isWaitingSubmit: false,
      loading: false,
      errorText: '',
      spoilerPermissions: true,
      deleteDialog: false,
      zoom: 1,
      selectedTab: 'subdivision',
      tabs: [
        {
          id: 1,
          title: 'Подразделения',
          value: 'subdivision',
          isActive: true,
        },
        {
          id: 2,
          title: 'Должности',
          value: 'roles',
          isActive: false,
        },
        {
          id: 3,
          title: 'Иерархия',
          value: 'hierarchy',
          isActive: false,
        },
      ],
      selectedSupplierInn: '',
      selectedAccountId: '',
      companies: [],
    };
  },
  computed: {
    gd() {
      return this.roleList.filter((role) => role.isCompanyOwner)[0] ?? {};
    },
    ownerRolesList() {
      return this.roleList.filter((role) => (!role.subdivision || role.isSubdivisionOwner) && role.id !== this.selectedRole.id).map((role) => ({
        label: role.name,
        value: role.id,
      }));
    },
    subdivisionList() {
      return this.roleList.filter((role) => role.subdivision && role.isSubdivisionOwner).map((role) => ({
        label: role.subdivision.name,
        value: role.subdivision.id,
      }));
    },
    isDisabledRole() {
      return this.selectedRole.isCompanyOwner === true;
    },
    isChanged() {
      return !isEqual(this.selectedRole, this.selectedRoleOld);
    },
    transformedSubdivision() {
      return this.roleList.filter((role) => role.subdivision && role.isSubdivisionOwner).map((item) => ({
        ...item,
        name: item.subdivision.name,
        supervisor: item.name,
        parentRoleName: item.parentRole?.name,
        districtDepartmentId: item.subdivision.districtDepartment?.id,
        phoneNumber: item.phoneNumber ? formatting.phoneFormat(item.phoneNumber).formattedPhone : undefined,
        shift: item.subdivision.shift,
      }));
    },
    templateSubdivision() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'Должность руководителя',
            key: 'supervisor',
            sort: {},
            filter: {},
          },
          {
            label: 'Кому подчиняется',
            key: 'parentRoleName',
            sort: {},
            filter: {},
          },
          {
            key: 'editDeleteButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
    transformedRoles() {
      return this.roleList.map((item) => ({
        ...item,
        subdivisionName: item.subdivision?.name,
        parentRoleName: item.parentRole?.name,
        subdivisionOwner: item.isSubdivisionOwner ? 'Да' : 'Нет',
        permissions: item.permissions.map((permission) => ({ value: permission.code, tooltip: permission.description })),
        menuItems: this.getAllowedRoutesByPermissions(item.permissions).map((route) => ({ value: route })),
      }));
    },
    templateRoles() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'Подразделение',
            key: 'subdivisionName',
            sort: {},
            filter: {},
          },
          {
            label: 'Руководитель подразделения',
            key: 'subdivisionOwner',
            sort: {},
            filter: {},
          },
          {
            label: 'Полномочия',
            key: 'permissions',
            type: 'list',
            spoilerCount: 4,
            filter: {},
          },
          {
            label: 'Доступные пункты меню',
            key: 'menuItems',
            type: 'list',
            spoilerCount: 4,
            thStyle: {
              width: '480px',
            },
            filter: {},
          },
          {
            key: 'editDeleteButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
            customCheck: (item) => item.isCompanyOwner,
          },
        ],
      };
    },
    subdivisionActionBlockItems() {
      return [
        {
          id: 1,
          title: 'Добавить подразделение',
          method: () => this.addDepartment(),
        },
      ];
    },
    rolesActionBlockItems() {
      return [
        {
          id: 1,
          title: 'Добавить должность',
          method: () => this.addRole(),
        },
      ];
    },
    supplierInn() {
      if (this.isPlatformWorkerForCompany) {
        return this.selectedSupplierInn;
      }

      return this.getAccount.profile.inn;
    },
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.support-company-hierarchy';
    },
    ...mapGetters('user', ['getAccount', 'getUser', 'isPlatformWorker']),
    ...mapGetters('dataLists', ['shiftList']),
  },
  watch: {
    selectedSupplierInn(val) {
      this.selectedAccountId = this.companies.find((company) => company.value === val)?.accountId;

      this.loadData();
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    async loadCompanies() {
      const accounts = await this.accountsByFilter({ filter: { profileType: ACCOUNT_PROFILE_TYPE.COMPANY } });

      this.companies = accounts.map((account) => ({
        value: account.profile.inn,
        label: account.profile.name,
        accountId: account.id,
      }));
    },
    async loadData() {
      try {
        this.loading = true;

        if (!this.supplierInn && this.isPlatformWorkerForCompany) {
          await this.loadCompanies();

          this.roleList = [];
          return;
        }

        this.districtDepartmentsList = !this.supplierInn ? [] : (await this.supplierDepartments({ inn: this.supplierInn })).map((it) => ({
          value: it.id,
          label: it.name,
        }));

        this.roleList = await this.getCompanyRoles(this.isPlatformWorkerForCompany ? { filter: { accountId: this.selectedAccountId } } : undefined);

        this.possiblePermissions = await this.getPossiblePermissions();

        this.blocks = this.possiblePermissions.map((block) => ({
          label: `${block.code}: ${block.description}`,
          value: block.code,
        }));
        this.possiblePermissions = this.possiblePermissions.flatMap((permissionBlock) => permissionBlock.permissions).filter((it) => it);
      } catch (error) {
        console.log('CompanyHierarchy load data error: ', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    addDepartment() {
      this.selectedRole = {};
      this.selectedTarget = 'bottom';
      this.selectedRole.shift = this.shiftList[0].value;
    },
    addRole() {
      this.selectedRole = {};
      this.selectedTarget = 'top';
    },
    addChildRole() {
      this.updatePermissionsRole = {};

      this.updatePermissionsRole.newPermissions = this.possiblePermissions.map((permission) => ({
        ...permission,
        value: this.updatePermissionsRole?.permissions?.some((rolePermission) => rolePermission.code === permission.code) ?? false,
      }));
      this.displayPermissionsDialog = true;
    },
    addPermission() {
      this.updatePermissionsRole = JSON.parse(JSON.stringify(this.selectedRole));

      this.updatePermissionsRole.newPermissions = this.possiblePermissions.map((permission) => ({
        ...permission,
        value: this.updatePermissionsRole?.permissions?.some((rolePermission) => rolePermission.code === permission.code) ?? false,
      }));
      this.displayPermissionsDialog = true;
    },
    selectRole(id) {
      const role = this.roleList.filter((role) => role.id === id)[0];
      this.selectedRole = {
        ...JSON.parse(JSON.stringify(role)),
        parentRoleId: role.parentRole?.id,
        subdivisionId: role.subdivision?.id ?? '',
        subdivisionName: role.subdivision?.name ?? '',
        districtDepartmentId: this.districtDepartmentsList.find((it) => it.value === role.subdivision?.districtDepartment?.id)?.value,
        phoneNumber: role.subdivision?.phoneNumber ? formatting.phoneFormat(role.subdivision?.phoneNumber).formattedPhone : '',
        withoutSubdivision: !role.subdivision,
        shift: role.subdivision?.shift ?? 'DAY',
        childrenList: this.roleList.filter((role) => this.getParentId(role) === id),
      };

      this.selectedRoleOld = JSON.parse(JSON.stringify(this.selectedRole));
    },
    roleClick(id, target) {
      if (id === this.selectedRole?.id && target === this.selectedTarget) {
        this.unselect();
        return;
      }

      this.unselect();

      this.selectedTarget = target;

      this.selectRole(id);
    },
    updateRole(role, index) {
      this.updatePermissionsRole = JSON.parse(JSON.stringify(role));
      this.updatePermissionsRole.index = index;

      this.updatePermissionsRole.newPermissions = this.possiblePermissions.map((permission) => ({
        ...permission,
        value: this.updatePermissionsRole?.permissions?.some((rolePermission) => rolePermission.code === permission.code) ?? false,
      }));
      this.displayPermissionsDialog = true;
    },
    deleteRole(e, index) {
      this.selectedRole.childrenList.splice(index, 1);
      this.updateChildrenList++;
      e.stopPropagation();
    },
    ignoreRole(role) {
      return role.subdivision && !role.isSubdivisionOwner;
    },
    getParentId(role) {
      return role.parentRole?.id;
    },
    unselect() {
      this.selectedRole = {};
      this.selectedTarget = '';
      this.deleteDialog = false;
      this.spoilerPermissions = true;
    },
    closePermissions() {
      this.updatePermissionsRole = {};
      this.displayPermissionsDialog = false;
    },
    updatePermissions(role) {
      if (this.selectedTarget === 'bottom') {
        if (role.index !== undefined) {
          this.selectedRole.childrenList[role.index] = {
            ...role,
            permissions: role.newPermissions.filter((permission) => permission.value),
          };
        } else {
          if (!this.selectedRole.childrenList) {
            this.selectedRole.childrenList = [];
          }
          this.selectedRole.childrenList.push({
            ...role,
            permissions: role.newPermissions.filter((permission) => permission.value),
          });
        }
      }

      if (this.selectedTarget === 'top') {
        this.selectedRole = { ...role, permissions: role.newPermissions.filter((permission) => permission.value) };
      }

      this.displayPermissionsDialog = false;
    },
    async onSubmitDepartment() {
      this.isWaitingSubmit = true;

      let data;

      if (!this.selectedRole.id) {
        // Добавление подразделения
        data = {
          createSubdivision: {
            ...this.selectedRole,
            phoneNumber: this.selectedRole.phoneNumber ? formatting.unformatPhone(this.selectedRole.phoneNumber) : null,
          },
        };

        // Добавление должностей в подразделение
        const newRoles = this.selectedRole.childrenList?.filter((role) => !role.id);
        if (newRoles && newRoles.length > 0) {
          data.rolesToCreate = [];

          newRoles.forEach((role) => {
            data.rolesToCreate.push(
              {
                ...role,
                parentRoleId: this.selectedRole.id,
                subdivisionId: this.selectedRole.subdivisionId,
              },
            );
          });
        }
      } else {
        data = {};
        // Обновление подразделения
        if (this.selectedRole.subdivisionName !== this.selectedRoleOld.subdivisionName || this.selectedRole.shift !== this.selectedRoleOld.shift || this.selectedRole.districtDepartmentId !== this.selectedRoleOld.districtDepartmentId || this.selectedRole.phoneNumber !== this.selectedRoleOld.phoneNumber) {
          data.changeSubdivision = {
            subdivisionId: this.selectedRole.subdivisionId,
            subdivisionName: this.selectedRole.subdivisionName,
            districtDepartmentId: this.selectedRole.districtDepartmentId,
            shift: this.selectedRole.shift,
            phoneNumber: this.selectedRole.phoneNumber ? formatting.unformatPhone(this.selectedRole.phoneNumber) : null,
          };
        }

        // Обновление должности руководителя
        if (this.selectedRole.name !== this.selectedRoleOld.name || this.selectedRole.parentRoleId !== this.selectedRoleOld.parentRoleId) {
          data.roleToUpdate = [{
            id: this.selectedRole.id,
            name: this.selectedRole.name,
            parentRoleId: this.selectedRole.parentRoleId,
            subdivisionId: this.selectedRole.subdivisionId,
          }];
        }

        // Добавление должностей в подразделение
        const newRoles = this.selectedRole.childrenList?.filter((role) => !role.id);
        if (newRoles && newRoles.length > 0) {
          data.rolesToCreate = [];

          newRoles.forEach((role) => {
            data.rolesToCreate.push(
              {
                ...role,
                parentRoleId: this.selectedRole.id,
                subdivisionId: this.selectedRole.subdivisionId,
              },
            );
          });
        }

        // Удаление должностей из подразделения
        const deleteRoles = this.selectedRoleOld.childrenList.filter((roleOld) => !this.selectedRole.childrenList.find((role) => role.id === roleOld.id));
        if (deleteRoles.length > 0) {
          data.roleToDeleteFromSubdivision = [];

          deleteRoles.forEach((role) => {
            data.roleToDeleteFromSubdivision.push(
              {
                id: role.id,
              },
            );
          });
        }

        // Обновляем должности в подразделение
        const updateRoles = this.selectedRole.childrenList.filter(
          (role) => {
            if (!role.id) {
              return false;
            }

            const roleOld = this.selectedRoleOld.childrenList.find((roleOld) => roleOld.id === role.id);

            if (role.name !== roleOld.name) {
              return true;
            }

            if (role.permissions.length !== roleOld.permissions.length ||
              role.permissions.some((permission) => !roleOld.permissions.find((permissionOld) => permissionOld.code === permission.code))
            ) {
              return true;
            }

            return false;
          },
        );
        if (updateRoles.length > 0) {

          if (!data.roleToUpdate) {
            data.roleToUpdate = [];
          }

          data.roleToUpdate.push(...updateRoles);
        }


      }

      const xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.isPlatformWorkerForCompany ? this.selectedAccountId : this.getAccount.id, this.getUser.id, data);

      try {
        await this.documentUpload({
          xml: xmlAddUsersToOrganisationApplication,
        });

        await this.loadData();
        this.unselect();
      } catch (error) {
        this.errorText = HTTPError.network(error).message;
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${this.errorText}`,
        });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async onSubmitRole() {
      this.isWaitingSubmit = true;

      let data;

      if (!this.selectedRole.id) {
        data = {
          rolesToCreate: [this.selectedRole],
        };
      } else {
        data = {
          roleToUpdate: [this.selectedRole],
        };
      }

      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.isPlatformWorkerForCompany ? this.selectedAccountId : this.getAccount.id, this.getUser.id, data);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });

        await this.loadData();
        this.unselect();
      } catch (error) {
        this.errorText = HTTPError.network(error).message;
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${this.errorText}`,
        });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    deletePermission(index) {
      this.selectedRole.permissions.splice(index, 1);
      this.updateChildrenList++;
    },
    changeSubdivision() {
      const subdivisionOwner = this.roleList.find((role) => role.subdivision?.id === this.selectedRole.subdivisionId && role.isSubdivisionOwner);
      this.selectedRole.parentRoleId = subdivisionOwner.id;
    },
    changeWithoutSubdivision() {
      if (!this.selectedRole.withoutSubdivision) {
        this.selectedRole.subdivisionId = undefined;
      }
    },
    changeParent() {
      if (this.selectedRole.isSubdivisionOwner) {
        return;
      }

      const parent = this.roleList.find((role) => role.id === this.selectedRole.parentRoleId);

      if (parent.subdivision?.id) {
        this.selectedRole.subdivisionId = parent.subdivision.id;
        this.selectedRole.withoutSubdivision = false;
        return;
      }

      this.selectedRole.subdivisionId = undefined;
      this.selectedRole.withoutSubdivision = true;
    },
    getAllowedRoutesByPermissions(permissions) {
      const allRoutes = getAllVueRoutes(this.$router.options.routes);
      const routesByPermission = isAccess(permissions, this.getAccount?.kind);
      const allowedRoutes = [];

      for (const prop in routesByPermission) {
        // eslint-disable-next-line no-prototype-builtins
        if (routesByPermission.hasOwnProperty(prop) && routesByPermission[prop]) {
          const allowedRoute = allRoutes.find((route) => route.path === prop);
          if (allowedRoute?.meta?.title?.length) allowedRoutes.push(allowedRoute.meta.title);
        }
      }

      return allowedRoutes;
    },
    async onDelete() {
      this.isWaitingSubmit = true;

      let data;

      if (this.selectedTarget === 'bottom') {
        data = {
          deleteSubdivision: this.selectedRole,
        };
      } else {
        data = {
          roleToDelete: [this.selectedRole],
        };
      }

      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.isPlatformWorkerForCompany ? this.selectedAccountId : this.getAccount.id, this.getUser.id, data);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });

        await this.loadData();
        this.unselect();
      } catch (error) {
        this.errorText = HTTPError.network(error).message;
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${this.errorText}`,
        });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    zoomIn() {
      this.zoom += 0.05;
    },
    zoomOut() {
      this.zoom -= 0.05;
    },
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    ...mapActions('user', ['getCompanyRoles', 'getPossiblePermissions', 'setNotification', 'accountsByFilter']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('dataLists', ['supplierDepartments']),
  },
};

</script>

<style lang="scss" scoped>

.company-hierarchy {
  overflow-x: hidden;

  &__subdivision {
    &__content-control-button {
      margin-top: 24px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 16px;
    font-family: 'Roboto Condensed', sans-serif;

    h1 {
      position: relative;
      color: #C1C4C7;
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
    }

    span {
      font-size: 18px;
      line-height: 20px;
    }

    &__button {
      display: flex;
      gap: 12px;
      margin-right: 24px;

      @media (max-width: 860px) {
        flex-direction: column;
        gap: 8px;
        justify-items: end;
      }
    }
  }

  &__content {
    height: 100%;
    display: flex;

    &-chart {
      position: relative;
      width: 100%;
      height: 100%;

      &-divider-top {
        margin-top: 24px;
      }

      &-data {
        padding-top: 55px;
      }

      &__zoom {
        position: absolute;
        top: 42px;
        right: 24px;
        display: flex;
        gap: 1px;
        z-index: 5;

        &-plus, &-minus {
          width: 40px;
          height: 40px;
          padding: 10px;
          border: 1px;
          background-color: #F5F6F6;
          cursor: pointer;
        }

        &-plus {
          border-radius: 24px 0 0 24px;

          &--inactive {
            cursor: default;
            background-color: #fff7f7;
          }
        }

        &-minus {
          border-radius: 0 24px 24px 0;
        }
      }
    }

    &-control {
      padding: 30px 0 0 30px;

      &-button {
        margin-bottom: 16px;
      }

      &-divider {
        height: 1px;
        width: 400px;
        margin-top: -6px;
        margin-bottom: 17px;
      }

      &__title {
        height: 40px;
        position: relative;
        background-color: #2F82DF;
        display: flex;
        gap: 18px;
        color: #FFFFFF;
        align-items: center;
        border-radius: 20px 20px 0 0;
        padding-left: 26px;

        svg {
          fill: #FFFFFF;
        }

        &-arrow {
          position: absolute;
          right: 24px;
          cursor: pointer;
        }
      }

      &-department {
        width: 400px;
        height: auto;
        border: 1px solid #2F82DF;

        &__info {
          padding: 24px 16px 21px 16px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          height: 700px;
          overflow-y: scroll;
          overflow-x: hidden;

          &-divider {
            height: 1px;
          }

          &-children {
            display: flex;
            flex-direction: column;
            gap: 16px;
            position: relative;

            &__button {
              height: 32px;
            }

            &__list, &__list-permissions {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
            }

            &__list-permissions {
              border: 1px solid #DEE2E6;
              border-radius: 16px;
              padding: 8px 10px;
              overflow: hidden;
            }

            &__spoiler {
              width: 40px;
              height: 16px;
              border: 1px solid #DEE2E6;
              border-radius: 24px;
              background-color: #FFFFFF;
              position: absolute;
              bottom: -8px;
              left: 162px;
              padding: 0 14px;
              cursor: pointer;
            }

            &__item {
              background-color: #E9EBED;
              border-radius: 16px;
              padding: 5px 12px;

              display: flex;
              gap: 8px;
              justify-content: space-between;
              cursor: pointer;

              &__controls {
                display: flex;
                gap: 8px;
                align-items: center;

                svg {
                  height: 14px;
                  fill: #C1C4C7;
                }
              }
            }

            &__title {
              display: flex;
              align-items: center;
              gap: 8px;

              img {
                cursor: pointer;
              }
            }

            &__menu {
              border: 1px solid #DEE2E6;
              border-radius: 16px;
              padding: 8px 10px;
              margin-bottom: 8px;

              div {
                border-radius: 16px;
                background-color: #E9EBED;
                padding: 5px 12px 5px 8px;
              }
            }
          }

          &-buttons {
            display: flex;
            justify-content: space-between;
            font-size: 14px;

            &__next, &__delete {
              display: flex;
              align-items: center;
              cursor: pointer;
              gap: 8px;
            }

            &__delete {
              padding: 6px 16px 6px 8px;

              svg {
                height: 18px;
                fill: #EB5757;
              }
            }

            &__next {
              padding: 6px 16px 6px 16px;

              svg {
                height: 12px;
                fill: #FFFFFF;
              }
            }
          }

          &-label {
            width: 100%;
            height: 40px;
            background-color: #F5F6F6;
            border-radius: 24px;
            padding: 18px;
            display: flex;
            align-items: center;
            gap: 5px;

            &--disabled {
              color: #4C4E51;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
</style>
