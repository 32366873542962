<template>
  <div>
    <table-with-measurements-common-logic :kind-list="['DELIVERY_POINT']"/>
  </div>
</template>

<script>
import TableWithMeasurementsCommonLogic from '../MeasurementsCommon/TableWithMeasurementsCommonLogic.vue';

export default {
  name: 'CabinetMeasurementsFinally',
  components: {
    TableWithMeasurementsCommonLogic,
  },
};
</script>
