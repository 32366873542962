<template>
  <div :class="$style.containerInner">
    <div :class="$style.status">
      <task-status
        :current-index="data.statusIndex"
        :list="data.statusList"
        :sub-title="data.statusSubTitle"
        :title="data.statusTitle"
        :user-id="data.userId"
      />
    </div>

    <div :class="$style.documentContainer">
      <v-file-attachment v-if="data.isDisplayApplicationDocument"
                         :document-id="data.documentId"
                         href="/"
                         label="Заявка на технологическое присоединение"/>
    </div>

    <div>
      <summary-application
        v-if="applicationData.consumer"
        :admission-certificate-id="admissionCertificateId"
        :connection-application="applicationData"
        :connection-certificate-id="connectionCertificateId"
        :document-id="data.documentId"
        :expansion="summaryProps.expansion"
        :init-spoiler="summaryProps.initSpoiler"
        :init-spoiler-offer="summaryProps.initSpoilerOffer"
        :offer-document="offerDocumentSummary"
        :offer-document-title="offerDocumentTitle"
        :only-microgenerator="data.onlyMicrogenerator"
        :retail-offer-document-id="retailerOfferDocument.id"
        :show-map="summaryProps.showMap"
        :technical-conditions-act-document-id="technicalConditionsActDocumentId"
        :utility-offer-document-id="utilityOfferDocumentId"
        title="Детали заявки"
      />
    </div>

    <div v-if="isLoading" :class="$style.loader">
      <v-circle-loader/>
    </div>

    <component
      :is="data.actionComponent"
      v-if="data.actionComponent"
      :account-id="accountId"
      :action-props="data.actionProps"
      :additional-document="additionalDocument"
      :admission-certificate-id="admissionCertificateId"
      :application-data="applicationData"
      :application-id="applicationId"
      :document-id="data.documentId"
      :grid-company-name="gridCompanyName"
      :invoice-id="data.invoiceId"
      :is-display="isDisplayActionData"
      :offer-document="offerDocument"
      :only-microgenerator="data.onlyMicrogenerator"
      :retail-company-name="retailCompanyName"
      :retail-review-document="retailReviewDocument"
      :retailer-offer-document="retailerOfferDocument"
      :review-document="reviewDocument"
      :role-id="data.roleId"
      :subtitle="data.subtitle"
      :technical-conditions-act-document-id="technicalConditionsActDocumentId"
      :user-id="data.userId"
      :workflow-id="data.workflowId"
      style="flex-grow: 3"
      @close="onClose"
    />

    <div v-else style="flex-grow: 3">&nbsp;</div>

    <submit-task-details v-if="!data.actionComponent" :class="$style.buttonNoAction" role="close" @submit="onClose"/>

    <submit-task-details v-else-if="!isDisplayActionData" :class="$style.buttonNoAction" :disabled="isLoading"
                         :title="data.displayActionTitle" role="displayAction" @submit="isDisplayActionData = true"/>
  </div>
</template>

<script>
// import { formatDateToCustomDateFormat } from 'lib/utils/date';

import DialogTemplate from 'templates/DialogTemplate';

import ScrollingHeader from 'molecules/ScrollingHeader';
import TaskStatus from 'molecules/TaskStatus';
import SummaryApplication from 'views/private/ConnectionApplication/steps/Summary';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import ApprovedApplication from 'views/private/ConnectionApplication/Task/actions/ApprovedApplication';
import CorrectionApplication from 'views/private/ConnectionApplication/Task/actions/CorrectionApplication';
import CorrectionApplicationMicrogenerator
  from 'views/private/ConnectionApplication/Task/actions/CorrectionApplicationMicrogenerator';
import SelectTariff from 'views/private/ConnectionApplication/Task/actions/SelectTariff';
import ApprovedOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedOffer';
import ApprovedTechnicalConditions
  from 'views/private/ConnectionApplication/Task/actions/ApprovedTechnicalConditionsAct';
import AdditionalInfo from 'views/private/ConnectionApplication/Task/actions/AdditionalInfo';
import SendRetailerOffer from 'views/private/ConnectionApplication/Task/actions/SendRetailerOffer';
import RetailerOfferSubmitted from 'views/private/ConnectionApplication/Task/actions/RetailerOfferSubmitted';
import ApprovedRetailerOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedRetailerOffer';
import SendConnectionCertificate from 'views/private/ConnectionApplication/Task/actions/SendConnectionCertificate';
import SendTechnicalConditionsAct from 'views/private/ConnectionApplication/Task/actions/SendTechnicalConditionsAct';
import SendAdmissionCertificate from 'views/private/ConnectionApplication/Task/actions/SendAdmissionCertificate';
import SignAdmissionCertificate from 'views/private/ConnectionApplication/Task/actions/SignAdmissionCertificate';
import SendUsagePointLossesDocument
  from 'views/private/ConnectionApplication/Task/actions/SendUsagePointLossesDocument.vue';
import Cancelled from 'views/private/ConnectionApplication/Task/actions/Cancelled';
import ExecutionAgreement from 'views/private/ConnectionApplication/Task/actions/ExecutionAgreement';
import VDialogHeader from 'components/VDialog/VDialogHeader';
import VFileAttachment from 'components/VFileAttachment/VFileAttachment';
import VCircleLoader from 'components/VCircleLoader/VCircleLoader';

export default {
  name: 'Details',
  components: {
    VFileAttachment,
    VDialogHeader,
    DialogTemplate,
    ScrollingHeader,
    SubmitTaskDetails,
    SummaryApplication,
    TaskStatus,
    ApprovedApplication,
    CorrectionApplication,
    CorrectionApplicationMicrogenerator,
    SelectTariff,
    ApprovedOffer,
    ApprovedTechnicalConditions,
    AdditionalInfo,
    SendRetailerOffer,
    RetailerOfferSubmitted,
    ApprovedRetailerOffer,
    SendConnectionCertificate,
    SendTechnicalConditionsAct,
    SendAdmissionCertificate,
    SignAdmissionCertificate,
    SendUsagePointLossesDocument,
    Cancelled,
    VCircleLoader,
    ExecutionAgreement,
  },
  props: {
    value: Boolean,
    data: Object,
    summaryProps: {
      type: Object,
      default: () => ({
        expansion: true,
        showMap: true,
        initSpoiler: false,
      }),
    },
    applicationId: String,
    accountId: String,
    applicationData: Object,
    retailCompanyName: String,
    gridCompanyName: String,
    reviewDocument: Object,
    offerDocument: Object,
    connectionCertificateId: String,
    technicalConditionsActDocumentId: String,
    utilityOfferDocumentId: String,
    admissionCertificateId: String,
    retailReviewDocument: Object,
    additionalDocument: Object,
    retailerOfferDocument: Object,
    offerDocumentTitle: String,
    isDisplayAction: {
      type: Boolean,
      default: true,
    },
    isDisplayApplicationDocument: {
      type: Boolean,
      default: false,
    },
    hideOfferInSummary: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isDisplayActionData = this.isDisplayAction;
  },
  data() {
    return {
      visible: false,
      spoiler: {
        summary: true,
        documents: false,
        status: false,
        tariff: false,
        upload: false,
      },
      preparedData: {},
      isDisplayActionData: true,
    };
  },
  computed: {
    offerDocumentSummary() {
      if (this.hideOfferInSummary) {
        return undefined;
      }
      return this.offerDocument;
    },
    scrollStyle() {
      const height = document.documentElement.clientHeight;

      return {
        height: `${height - 100}px`,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
        if (!val) {
          this.isDisplayActionData = this.isDisplayAction;
        }
      },
    },
  },
  methods: {
    onClose() {
      this.visible = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.documentContainer {
  margin-left: 70px;
  margin-bottom: 40px;
}

.container {
  padding-top: 20px;
  overflow-y: auto;
}

.containerInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.status {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 0 40px;
}

.errorText + .small-text {
  margin: 4px;
  color: red;
}

.buttonNoAction {
  padding-top: 24px;
  margin-top: auto;
}

.loader {
  position: relative;
  height: 50px;
  margin-bottom: 32px;
}
</style>
