<template>
  <tr :style="generalRowStyle" :class="{
    ['general-row']: true,
    ['hide-divider']: !dividerShow,
  }">
    <!-- table cell(td) begin -->
    <v-table-td v-for="(header, index) in filteredHeaders"
                :key="header.label"
                :data="row"
                :header="header"
                :index="index"
                :next-row-data="nextRow"
                :is-nested="isNested"
                :is-nested-show="isNestedShow"
                @delete="onCellDeleteHandler"
                @edit="onCellEditHandler"
                @on-cell-click="onCellClickHandler"
                @on-cell-input="onCellInputHandler"
                @on-spoiler-button-click="onSpoilerButtonClickHandler"
    />
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { VTableTd } from '../VTableTd';

export default {
  name: 'VTableTr',
  components: {
    VTableTd,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
      required: true,
    },
    nextRow: {
      type: Object,
      default: undefined,
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    isNestedShow: {
      type: Boolean,
      default: true,
    },
    dividerShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    generalRowStyle() {
      if (this.browserProperties.isFirefox) {
        return { height: '100%' };
      }
      return {};
    },
    filteredHeaders() {
      return this.headers.filter((item) => item.type !==  'externalFilter');
    },
    ...mapGetters('environment', ['browserProperties']),
  },
  methods: {
    onCellDeleteHandler($event) {
      this.$emit('delete', $event);
    },
    onCellEditHandler($event) {
      this.$emit('edit', $event);
    },
    onCellClickHandler($event) {
      this.$emit('on-cell-click', $event);
    },
    onCellInputHandler($event) {
      this.$emit('on-cell-input', $event);
    },
    onSpoilerButtonClickHandler() {
      this.$emit('on-spoiler-button-click', this.row.groupId);
    },
  },
};
</script>

<style lang="scss" scoped>
.general-row {
  position: relative;
  border-bottom: 1px solid #cccccc;
  padding: 3px 10px;

  &:hover {
    background-color: #dee2e6;
    cursor: pointer;
  }
}

.hide-divider {
  border-bottom: none;

  &:hover {
    background-color: inherit;
  }

  &:last-child {
    border-bottom: 1px solid #cccccc;
  }
}
</style>
