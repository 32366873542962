<template>
  <div class="exploitation-history-content">
    <p v-if="isLoading" class="text">Загрузка данных...</p>
    <div v-else-if="!isLoading && getAdreska" class="inputs-wrapper">
      <div class="row">
        <div class="col col--1of2">
          <v-input
            :value="planExtraditionStartDate"
            title="Плановая дата начала выдачи"
            disabled
            readonly
          />
        </div>
        <div class="col col--1of2">
          <v-input
            :value="planExtraditionEndDate"
            title="Плановая дата окончания выдачи"
            disabled
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col col--1of2">
          <v-input
            :value="planInstallationStartDate"
            title="Плановая дата начала монтажа"
            disabled
            readonly
          />
        </div>
        <div class="col col--1of2">
          <v-input
            :value="planInstallationEndDate"
            title="Плановая дата окончания монтажа"
            disabled
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col col--1of2">
          <v-input
            :value="planEnableStartDate"
            title="Плановая дата начала включения"
            disabled
            readonly
          />
        </div>
        <div class="col col--1of2">
          <v-input
            :value="planEnableEndDate"
            title="Плановая дата окончания включения"
            disabled
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col col--1of2">
          <v-input
            :value="planDismantlingStartDate"
            title="Плановая дата начала демонтажа"
            disabled
            readonly
          />
        </div>
        <div class="col col--1of2">
          <v-input
            :value="planDismantlingEndDate"
            title="Плановая дата окончания демонтажа"
            disabled
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col col--1of2">
          <v-input
            :value="planAcceptanceStartDate"
            title="Плановая дата начала приёмки на склад"
            disabled
            readonly
          />
        </div>
        <div class="col col--1of2">
          <v-input
            :value="planAcceptanceEndDate"
            title="Плановая дата окончания приёмки на склад"
            disabled
            readonly
          />
        </div>
      </div>

      <v-app class="vuetify-styles" data-app>
        <AdreskaHistory :adreska-id="getAdreska.id"></AdreskaHistory>
      </v-app>
    </div>
    <p v-else class="text">Нет данных</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import { VInput } from 'components';

export default {
  name: 'ExploitationHistory',
  components: { VInput, AdreskaHistory },
  created() {
    void this.loadData();
  },
  computed: {
    ...mapGetters('exploitation', [
      'isLoading',
      'getAdreska',
      'getAdreskaEvent',
    ]),
    planInstallationStartDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planInstallationStartDate,
      );
    },
    planInstallationEndDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planInstallationEndDate,
      );
    },
    planExtraditionStartDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planExtraditionStartDate,
      );
    },
    planExtraditionEndDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planExtraditionEndDate,
      );
    },
    planDismantlingStartDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planDismantlingStartDate,
      );
    },
    planDismantlingEndDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planDismantlingEndDate,
      );
    },
    planAcceptanceStartDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planAcceptanceStartDate,
      );
    },
    planAcceptanceEndDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planAcceptanceEndDate,
      );
    },
    planEnableStartDate() {
      return formatDateFromOdkTimestamp(
        this.getAdreskaEvent.planEnableStartDate,
      );
    },
    planEnableEndDate() {
      return formatDateFromOdkTimestamp(this.getAdreskaEvent.planEnableEndDate);
    },
  },
  methods: {
    ...mapActions('exploitation', ['getAdreskaData']),
    loadData() {
      void this.getAdreskaData(this.$route.query.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/grid.scss';

.exploitation-history-content {
  padding: 16px 0;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(29, 41, 57, 1);
}

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#app .vuetify-styles {
  width: 100%;
}

.vuetify-styles .v-application--wrap {
  min-height: auto;
}
</style>
