<template>
  <div :class="$style.container">
    <div ref="navigationBar" :style="{...stickyPosition, top: '-16px' }">
      <navigation-bar :class="$style.navigationBar"
                      :current-step="usefulVacationDocument.currentStep"
                      :home="task === undefined ? '18 формы' : 'Задачи'"
                      :steps="steps"
                      @open="openStep" />

      <v-divider :class="$style.dividerNavigation" />
    </div>

    <div ref="eighteenthFormsContent" :class="$style.content" role="detailsContent">
      <component
          :is="steps[usefulVacationDocument.currentStep].component"
          :key="componentKey"
          ref="details"
          :component-key="componentKey"
          :disable="disable"
          :step-number="steps[usefulVacationDocument.currentStep].stepNumber"
          :task="task"
          :useful-vacation-document="usefulVacationDocument"
          @close="close"
          @complete="complete"
          @next="next"
          @preValidations="preValidations"
      />

      <v-divider :class="$style.divider" />
    </div>

    <div :class="$style.contentFooter" :style="{...stickyPosition, bottom: '16px' }">
      <div style="display: flex">
        <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
          <chevron-left />
          <span>Назад</span>
        </v-button>
        <v-button :class="$style.delete" type="button" variant="outlined-red" @click="displayDeleteDialog = true">
          <delete />
          <span>Удалить черновик</span>
        </v-button>
      </div>
      <v-button v-show="displayNext" :class="$style.next" type="button" @click="next">
        <span>Далее</span>
        <arrow-right />
      </v-button>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить черновик?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft" />
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
      </div>
    </dialog-template>
  </div>
</template>
<script>
import ArrowRight from 'atoms/icons/arrows/ArrowRight';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import Delete from 'atoms/icons/action/Delete.vue';

import Additions from './steps/EighteenthFormsAdditions';
import EighteenthForms from './steps/EighteenthForms';
import DownloadReport from './steps/DownloadReport';
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';
import SignatoriesStep from './steps/SignatoriesStep';

import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import localforage from 'localforage';
import throttle from 'lib/utils/throttle';
import { scrollIntoView } from 'lib/utils/DOM';
import { mapGetters, mapMutations, mapState } from 'vuex';
import validate from 'lib/utils/validationRules';
import { VButton, VDivider } from '@/components';
import { check } from 'lib/utils/validation/additionValidation.js';

function isNotEmptyItem(item) {
  return validate.isNotEmptyItem(item);
}

function compare(a, b) {
  if (a.row < b.row) {
    return -1;
  }
  if (a.row > b.row) {
    return 1;
  }
  if (a.row === b.row) {
    if (a.col < b.col) {
      return -1;
    }
    if (a.col > b.col) {
      return 1;
    }
  }
  return 0;
}

export default {
  name: 'EighteenFormUpload',
  components: {
    VButton,
    Delete,
    ChevronLeft,
    ArrowRight,
    Additions,
    EighteenthForms,
    DownloadReport,
    NavigationBar,
    DialogTemplate,
    SubmitTaskDetails,
    SignatoriesStep,
    VDivider,
  },
  props: {
    task: Object,
    index: Number,
    isComments: {
      type: Boolean,
      default: () => false,
    },
  },
  async created() {
    try {
      const account = this.account.profile;
      this.usefulVacationDocument.isRetailer = this.account.kind === 'RETAILER' || this.account.kind === 'SUPPLIER_OF_LAST_RESORT';
      if (!this.usefulVacationDocument.isRetailer)
        this.usefulVacationDocument.UtilityInfo = {
          name: account.name,
          inn: account.inn,
        };
      const usefulVacationDocument = await localforage.getItem('eighteenFormDocument');

      if (usefulVacationDocument === null) {
        return;
      }

      const object = JSON.parse(usefulVacationDocument);

      if (object.version === undefined || object.version < this.usefulVacationDocument.version) {
        return;
      }

      this.usefulVacationDocument = object;
      this.usefulVacationDocument.isRetailer = this.account.kind === 'RETAILER' || this.account.kind === 'SUPPLIER_OF_LAST_RESORT';
      if (!this.usefulVacationDocument.isRetailer)
        this.usefulVacationDocument.UtilityInfo = {
          name: account.name,
          inn: account.inn,
        };
    } catch (error) {
      console.log('error getData usefulVacationDocument', error);
    }
    window.addEventListener('resize', this.setElementsWidth);
  },
  mounted() {
    this.setElementsWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      usefulVacationDocument: {
        attachments: [],
        version: 5,
        currentStep: 0,
        attachments_error: { error: false },
        Retailer: {
          id: '',
          name: '',
        },
        Retailer_error: { error: false },
        UtilityInfo: {
          name: '',
          inn: '',
        },
        Utility_error: { error: false },
        eighteenthForms: {},
        AccountingPeriod: '',
        AccountingPeriod_error: { error: false },
        ConsumerType: '',
        ConsumerType_error: { error: false },
        isDisplayDocumentScan: false,
        documentsScan: [],
        documentsScan_error: { error: false },
        signatory_error: { error: false },
        signatory: {
          userId: '',
          role: {
            id: '',
            name: '',
            nameGenitive: '',
          },
          accountId: '',
          reason: {
            name: '',
            nameGenitive: '',
            number: null,
            date: null,
          },
          fullName: {},
          fullNameGenitive: {},
          roleName: {},
          roleNameGenitive: {},
        },
        isRetailer: false,
      },
      steps: [
        {
          component: 'Additions',
          stepNumber: {
            value: 1,
            from: 4,
            title: () => 'Загрузка данных',
          },
          isComplete: true,
          isDisplayEdit: false,
          validations: this.validAdditions,
        },
        {
          component: 'EighteenthForms',
          stepNumber: {
            value: 2,
            from: 4,
            title: () => 'Проверка приложений',
          },
          isDisplayEdit: true,
          isComplete: false,
          preValidations: this.eighteenthFormsVacation,
        },
        {
          component: 'SignatoriesStep',
          stepNumber: {
            value: 3,
            from: 4,
            title: () => 'Подписывающие лица',
          },
          isDisplayEdit: false,
          isComplete: true,
          validations: this.validSignatures,
        },
        {
          component: 'DownloadReport',
          stepNumber: {
            value: 4,
            from: 4,
            title: () => 'Сформированные XML файлы',
          },
          isDisplayEdit: false,
          isComplete: false,
        },
      ],
      componentKey: 1,
      disable: false,
      displayDeleteDialog: false,
      footerWidth: '0',
    };
  },
  computed: {
    displayBack() {
      return this.usefulVacationDocument.currentStep > 0;
    },
    displayNext() {
      return this.usefulVacationDocument.currentStep >= 0 && this.steps[this.usefulVacationDocument.currentStep].isComplete;
    },
    stickyPosition() {
      return {
        position: 'sticky',
        backgroundColor: '#ffffff',
        paddingLeft: '16px',
        zIndex: '100',
        marginLeft: '-16px',
        paddingTop: '16px',
        marginTop: '-16px',
        marginBottom: '-8px',
        paddingBottom: '8px',
      };
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'reliabilityClassList',
      'voltageClassList',
      'subscriberStateList',
      'meterStateList',
      'appliedTariffList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'typeAccountingListLong',
    ]),
    ...mapState('user', ['account']),
  },
  watch: {
    usefulVacationDocument: {
      deep: true,
      immediate: false,
      handler: 'saveDataIntermediate',
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    validAdditions() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      isValid = this.usefulVacationDocument.attachments.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'filledAttachment';
        this.usefulVacationDocument.attachments_error = { error: true };
      } else {
        this.usefulVacationDocument.attachments_error = { error: false };
      }

      isValid = this.usefulVacationDocument.attachment1File === null || this.usefulVacationDocument.AccountingPeriod.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'AccountingPeriod';
        this.usefulVacationDocument.AccountingPeriod_error = { error: true };
      } else {
        this.usefulVacationDocument.AccountingPeriod_error = { error: false };
      }

      isValid = this.usefulVacationDocument.documentsScan.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'DocumentsScan';
        this.usefulVacationDocument.documentsScan_error = { error: true };
      } else {
        this.usefulVacationDocument.documentsScan_error = { error: false };
      }

      isValid = this.usefulVacationDocument.ConsumerType === undefined || this.usefulVacationDocument.ConsumerType.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'ConsumerType';
        this.usefulVacationDocument.ConsumerType_error = { error: true };
      } else {
        this.usefulVacationDocument.ConsumerType_error = { error: false };
      }

      isValid = this.usefulVacationDocument.Retailer.name.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Retailer';
        this.usefulVacationDocument.Retailer_error = { error: true };
      } else {
        this.usefulVacationDocument.Retailer_error = { error: false };
      }

      isValid = this.usefulVacationDocument.UtilityInfo.name.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Utility';
        this.usefulVacationDocument.Utility_error = { error: true };
      } else {
        this.usefulVacationDocument.Utility_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validSignatures() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      isValid = this.usefulVacationDocument.signatory.userId && this.usefulVacationDocument.signatory.userId.length > 0;

      if (!isValid) {
        result = true;
        errorRole = 'signatory';
        this.usefulVacationDocument.signatory_error = { error: true };
      } else {
        this.usefulVacationDocument.signatory_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validUsefulVacation() {
      const errorRole = '';
      const result = false;

      return { isError: result, firstError: errorRole };
    },
    eighteenthFormsVacation() {
      const errorRole = '';
      let result = false;

      let titlesHead = [];
      if (this.usefulVacationDocument.attachments.length > 0) {
        titlesHead = this.usefulVacationDocument.attachments[0].titlesHead;
      }

      let row = 8;
      if (this.usefulVacationDocument.ConsumerType === 'Individual') {
        row = 2;
      }

      const validateHandler = (attachmentError, rule, items, indexAttachment, indexItem, valuesList) => {
        const errorTexts = {
          required: 'Поле обязательно для заполнения',
          requiredNumber: 'Обязательное поле. Корректный формат: число',
          formatNumber: 'Некорректный формат. Корректный формат: число',
          requiredKtNumber: 'Обязательное поле. Корректный формат: два числа через слэш. Например: 100/50',
          requiredInn: 'Обязательное поле. Корректный формат: ИНН',
          formatDate: 'Некорректный формат даты. Корректный формат: ДД.ММ.ГГГГ',
        };

        let validResult = true;
        let errorText = '';

        switch (rule) {
          case 'required': {
            validResult = isNotEmptyItem(items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredLabelList': {
            validResult = isNotEmptyItem(items[indexItem]) && isValidLabelFromList(valuesList, items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredLabel2List': {
            validResult = isNotEmptyItem(items[indexItem]) && isValidLabel2FromList(valuesList, items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && !isNaN(Number(items[indexItem]));
            errorText = errorTexts.requiredNumber;
            break;
          }
          case 'formatNumber': {
            validResult = !isNotEmptyItem(items[indexItem]) || !isNaN(Number(items[indexItem]));
            errorText = errorTexts.formatNumber;
            break;
          }
          case 'requiredKtNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && items[indexItem].match(/^\d+\/\d+$/g) !== null;
            errorText = errorTexts.requiredKtNumber;
            break;
          }
          case 'requiredInn': {
            const errorInn = { message: '' };
            validResult = isNotEmptyItem(items[indexItem]) && validate.validateInn(items[indexItem], errorInn);
            errorText = errorTexts.requiredInn;
            break;
          }
          case 'formatDate': {
            const date = new Date(items[indexItem]);
            validResult = isNotEmptyItem(items[indexItem]) || (date instanceof Date && !isNaN(date)) ||
              items[indexItem] === 'Н'; // TODO: удалить после приведения данных от СК Энергия
            errorText = errorTexts.formatDate;
            break;
          }
        }

        if (!validResult) {
          result = true;

          attachmentError.push({
            row: indexAttachment + row,
            col: Number(titlesHead[indexItem].col),
            colTitle: titlesHead[indexItem].title,
            text: errorText,
          });
        }

      };

      function isValidLabelFromList(list, value) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].label === value) {
            return true;
          }
        }
        return false;
      }

      function isValidLabel2FromList(list, value) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].label2 === value) {
            return true;
          }
        }
        return false;
      }

      if (this.usefulVacationDocument.ConsumerType === 'Company') {
        const attachments = [];
        attachments.push(...this.usefulVacationDocument.attachments);

        let isValid = true;
        let errorsCount = 0;

        attachments.forEach((attachment) => {
          attachment.attachmentError = [];
          attachment.errors = attachment.errors.filter((error) => {
            const resultValidation = check(error.value, error.template, error.parsed, error.line, attachment.value[error.rowIndex]);

            if (resultValidation.isNotValid) {
              error.text = resultValidation.errorText;
              error.incorrectValue = error.value;
              isValid = false;
            }

            return resultValidation.isNotValid;
          });

          attachment.attachmentError = attachment.errors;
          attachment.attachmentError.sort(compare);
          errorsCount += attachment.errors.length;
        });

        if (!isValid) {
          result = true;
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: `Общее количество ошибок: ${errorsCount}`,
          });
        }
      } else if (this.usefulVacationDocument.ConsumerType === 'Individual') {
        this.usefulVacationDocument.attachments.forEach((it) => {
          it.attachmentError = [];
          it.attachment.forEach((attachment, index) => {
            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              1,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              10,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              11,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              12,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              13,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              19,
            );

            validateHandler(
              it.attachmentError,
              'formatNumber',
              attachment,
              index,
              20,
            );

            validateHandler(
              it.attachmentError,
              'formatNumber',
              attachment,
              index,
              24,
            );

            validateHandler(
              it.attachmentError,
              'formatNumber',
              attachment,
              index,
              25,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              34,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              35,
            );

            validateHandler(
              it.attachmentError,
              'required',
              attachment,
              index,
              36,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              39,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              40,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              41,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              42,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              43,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              44,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              45,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              46,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              47,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              48,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              49,
            );

            validateHandler(
              it.attachmentError,
              'requiredLabelList',
              attachment,
              index,
              50,
              this.voltageClassList,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              54,
            );

            validateHandler(
              it.attachmentError,
              'requiredNumber',
              attachment,
              index,
              57,
            );

            validateHandler(
              it.attachmentError,
              'formatNumber',
              attachment,
              index,
              60,
            );

            validateHandler(
              it.attachmentError,
              'formatNumber',
              attachment,
              index,
              61,
            );
          });

          it.attachmentError.sort(compare);
        });
      }

      return { isError: result, firstError: errorRole };
    },
    saveDataIntermediate: throttle(function() {
      this.saveData();
    }, 500),
    async saveData() {
      try {
        await localforage.setItem('eighteenFormDocument', JSON.stringify(this.usefulVacationDocument));
      } catch (error) {
        console.log('error usefulVacationDocument.save, data:', this.usefulVacationDocument);
      }
    },
    async clearData() {
      try {
        await localforage.removeItem('eighteenFormDocument');
      } catch (error) {
        console.log('error usefulVacationDocument.clear, data:', this.usefulVacationDocument);
      }
    },
    scrollToTop() {
      const scrollButton = document.querySelector('.scroll-button');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      scrollButton.dispatchEvent(clickEvent);
    },
    close() {
      if (this.task === undefined) {
        this.$router.push('/cabinet/eighteen-shape');
        return;
      }
      this.$emit('close');
    },
    back() {
      if (this.usefulVacationDocument.currentStep === 0) {
        this.close();
        return;
      }

      this.usefulVacationDocument.currentStep -= 1;
      this.scrollToTop();
    },
    next() {
      this.scrollToTop();
      if (this.steps[this.usefulVacationDocument.currentStep].validations) {
        const result = this.steps[this.usefulVacationDocument.currentStep].validations();

        if (result.isError) {
          this.componentKey += 1;
          this.$nextTick(() => {
            console.log('result.firstError', result.firstError);
            scrollIntoView(document.querySelector(`[role=${result.firstError}]`));
          });

          return;
        }
      }

      this.usefulVacationDocument.currentStep += 1;

      if (this.steps[this.usefulVacationDocument.currentStep].preValidations) {
        const result = this.steps[this.usefulVacationDocument.currentStep].preValidations();

        this.steps[this.usefulVacationDocument.currentStep].isComplete = !result.isError;

        if (result.isError) {
          this.componentKey += 1;
        }
      }

      document.querySelector('[role=detailsContent]').scrollTop = 0;
    },
    complete(isComplete) {
      this.steps[this.usefulVacationDocument.currentStep].isComplete = isComplete;

      if (isComplete && (
        (this.usefulVacationDocument.currentStep === 3)
      )) {
        this.clearData();
      }
    },
    changePoint() {
      this.disable = false;
    },
    savePoint() {
      this.disable = true;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.usefulVacationDocument.currentStep + 1) {
        return;
      }

      this.usefulVacationDocument.currentStep = value - 1;
    },
    preValidations() {
      const result = this.steps[this.usefulVacationDocument.currentStep].preValidations();

      this.steps[this.usefulVacationDocument.currentStep].isComplete = !result.isError;

      return result;
    },
    deleteDraft() {
      this.clearData();
      this.close();
    },
    setElementsWidth() {
      this.footerWidth = `${this.$refs.eighteenthFormsContent.offsetWidth}px`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #fff;

  .dividerNavigation {
    margin-right: 60px;
    width: auto;
    margin-top: 8px;
  }

  .navigationBar {
    position: sticky;
    left: 146px;
    width: 848px
  }
}

.loader {
  position: relative;
  height: 50px;

  div:first-child {
    top: 50px;
  }
}

.content {
  padding-bottom: 60px;
  flex-grow: 2;
  position: sticky;
  left: 146px;
  width: 848px;

  .divider {
    width: 864px;
    margin-left: -8px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.contentFooter {
  height: 48px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 100;
  width: 100%;
}

.back, .next, .delete, .save, .submit {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;

  svg {
    fill: #FFFFFF;
  }
}

.delete {
  margin-left: 16px;

  svg {
    path {
      fill: #EB5757
    }
  }

  span {
    margin-left: 7px;
  }
}

.back {
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.next {
  span {
    margin-right: 13px;
  }
}

.save {
  margin-right: 16px;

  span {
    margin-left: 7px;
  }
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.uploadingDraft {
  z-index: 100;
}
</style>
