<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="title">
              <span class="text-h5">Демонтаж</span>
              <span v-if="isExpired" class="errorText">
                {{subtitleError}}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <contractor-autocomplete
              v-if="isBoiler"
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-required
              title="Подрядчик"
            ></contractor-autocomplete>

            <events-autocomplete
              ref="event"
              v-model="eventId"
              :find-ids="eventsFindIds"
              :is-disabled="!contractorId"
              :items.sync="eventItems"
              :search.sync="eventName"
              is-required
              title="Мероприятие"
              url-default-data="/events/contractor/{0}/top20"
              url-search="/events/contractor/{0}/find?name={1}"
            />

            <autocomplete-find-id
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              :is-disabled="!contractorId||!eventId"
              :items.sync="adreskaItems"
              :search.sync="adreskaAddress"
              is-required
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
              url-default-data="/adreska/contractor/{0}/event/{1}/top20?statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS"
              url-search="/adreska/contractor/{0}/event/{1}/find?name={2}&statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS"
            ></autocomplete-find-id>

            <div v-if="adreska">
              <adreska-history :adreska-id="adreska.id" subselect="DISMANTLING_CONTROL" title="Информация о демонтаже"/>

              <text-field
                v-model="adreska.axp.odk.name"
                is-disabled
                title="ОДК"
              ></text-field>

              <yandex-map
                ref="customMap"
                :collection-axp="adreskaFactCollection"
                @tapPlacemark="onTapPlacemark"
              />

              <text-field
                ref="contract"
                v-model="countOdk"
                class="mt-4"
                is-disabled
                title="Количество демонтируемых комплектов ОДК"
              ></text-field>
            </div>

            <v-row>
              <v-col>
                <div>
                  <v-label>Комментарий к демонтажу*</v-label>
                </div>
                <v-textarea
                  v-model="commentDismantling"
                  dense
                  name="input-7-4"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <file-input
              ref="photos"
              v-model="photos"
              :loading="isLoadingPhotos"
              class="mt-2"
              is-required
              title="Выберите фото"
            ></file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>

</template>

<script>
import { BASE_URL } from '@/constants/api';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
// import { D5_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { formatDateFromOdkTimestamp, formatOdkTimestampToDateObject, getDateAtStartOfDay } from 'lib/utils/date';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import { fetchAdreskaById } from '@/api/rest/odk/api';

export default {
  components: {
    AdreskaHistory,
    TextField, YandexMap,
    ContractorAutocomplete,
    AutocompleteFindId,
    FileInput,
    EventsAutocomplete,
  },

  async created() {
    if (!this.isBoiler) this.contractorId = this.getAccount.supplier.id;
    await this.initAdreska();
  },

  data: () => ({
    progressValue: 0,

    adreska: null,
    contractorId: null,
    contractorName: '',
    contractors: [],
    adreskaId: null,
    adreskaAddress: '',
    adreskaItems: [],
    eventItems: [],
    adreskaFactId: null,
    eventId: null,
    eventName: '',
    events: [],
    commentDismantling: null,
    photos: [],
    isLoadingPhotos: false,
    adreskaFactCollection: [],
    loaderDialog: false,
  }),

  computed: {
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisabledDdreska = !this.adreska;
      const isDisabledComment = !this.commentDismantling?.length;
      const isDisabledPhoto = !this.photos.length;

      return (
        isDisabledContractor ||
        isDisabledDdreska ||
        isDisabledComment ||
        isDisabledPhoto
      );
    },
    countOdk() {
      return this.adreskaFactCollection.filter((it) => it.isSelected).length;
    },
    isExpired() {
      if (!this.adreska) return false;
      // После приёмки процесс не доступен => если зашли в процесс <=> Приёмка не осуществлялась
      return getDateAtStartOfDay(Date.now()) > formatOdkTimestampToDateObject(this.adreska.event.planDismantlingEndDate);
    },
    subtitleError() {
      return 'Просрочена плановая дата окончания демонтажа';
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'isBoiler', 'getAccount']),
  },

  watch: {
    adreskaId() {
      if (!this.adreskaId) {
        this.adreska = null;
        return;
      }
      this.fetchAdreska();
      this.fetchAdreskaFactCollection();
    },
  },

  methods: {
    async initAdreska() {
      if (!this.$route.params.initAdreskaId) return;
      const adreska = await fetchAdreskaById(this.$route.params.initAdreskaId);
      if (!adreska) {
        await this.setNotification({ message: `Не найдена программа оформления с ID ${this.$route.params.initAdreskaId}` });
        this.onTapBack();
      }
      if (!this.isBoiler && adreska.contractorId !== this.getAccount.supplier.id)  {
        await this.setNotification({ message: 'Вы не можете демонтировать по данному адресу' });
        this.onTapBack();
      }

      this.eventItems = [{
        id: String(adreska.event.id),
        name: adreska.event.name,
      }];
      this.eventId = String(adreska.event.id);
      this.eventName = adreska.event.name;


      this.contractors = [{
        id: adreska.contractorId,
        name: adreska.contractorName,
      }];
      this.contractorName = adreska.contractorName;
      this.contractorId = adreska.contractorId;


      console.log('set', this.eventItems, this.eventName, this.eventId);
      this.adreskaItems = [{
        id: adreska.id,
        name: adreska.name,
      }];
      this.adreskaAddress = adreska.name;
      this.adreskaId = adreska.id;
    },
    async fetchAdreska() {
      const response = await fetch(
        `${BASE_URL}/adreska/${this.adreskaId}`,
      );
      if (response.status === 200) {
        this.adreska = await response.json();
      } else {
        this.adreska = null;
      }
    },
    async fetchAdreskaFactCollection() {
      const response = await fetch(
        `${BASE_URL}/adreskafact?adreskaId=${this.adreskaId}`,
      );
      const data = await response.json();
      this.adreskaFactCollection = data.map((el) => {
        let color;
        let status;
        if (el.statusDismantlingCheck == 1) {
          color = 'red';
          status = 'С замечаниями';
        } else if (el.statusDismantlingCheck == 2) {
          color = 'green';
          status = 'Без замечаний';
        } else if (el.statusDismantling == 0) {
          color = 'violet';
          status = 'Демонтаж не проводился';
        } else {
          color = 'blue';
          status = 'Контроль не проводился';
        }
        return {
          id: el.id,
          locationLatitude: el.locationLatitude,
          locationLongitude: el.locationLongitude,
          statusDismantling: el.statusDismantling,
          isSelected: false,
          countOdk: el.countOdk,
          statusDismantlingAcceptance: el.statusDismantlingAcceptance,
          color: color,
          balloonContent:
            {
              title: el.axp.odk.name,
              buttonLabel: el.statusDismantling <= 0 ? 'Выбрать' : null,
              props:
                [
                  {
                    label: 'ID',
                    value: el.id,
                  },
                  {
                    label: 'АХП',
                    value: el.axp.name,
                  },
                  {
                    label: 'Тип декора',
                    value: el.decorType?.name,
                  },
                  {
                    label: 'Статус',
                    value: status,
                  },
                  {
                    label: 'Дата монтажа',
                    value: formatDateFromOdkTimestamp(el.createDate),
                  },
                ],
            },
        };
      });
    },
    onTapPlacemark(id) {
      const fact = this.adreskaFactCollection.find((it) => it.id === id && it.statusDismantling <= 0);
      fact.isSelected = !fact.isSelected;
      fact.balloonContent.buttonLabel = fact.isSelected ? 'Отменить' : 'Выбрать';
      this.adreskaFactCollection = this.adreskaFactCollection.map((it) => (it.id === id
        ? fact
        : it));
    },
    async onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        adreskaId: this.adreskaId,
        adreskaFactId: this.adreskaFactCollection.filter((it) => it.isSelected).map((it) => it.id),
        comment: this.commentDismantling,
        photos: mainPhotos,
      };

      axios
        .post(`${BASE_URL}/dismantling-by-contractor`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.progressValue = 0;

      this.contractorId = null;
      this.contractorName = '';
      this.contractors = [];

      this.adreskaId = null;
      this.adreskaAddress = '';
      this.adreskaItems = [];
      this.eventItems = [];
      this.adreskaFactId = null;
      this.commentDismantling = null;

      this.eventId = null;
      this.eventName = '';
      this.events = [];

      this.photos = [];
      this.isLoadingPhotos = false;

      this.loaderDialog = false;
    },
    ...
      mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
</style>

