<template>
  <div
    v-custom-click-outside="clickOutside"
    :class="$style['search-select-container']"
    :title="title"
    @click="setShowMenu"
  >
    <v-input v-model="internalValue"
             :clearable="!disabled"
             :disabled="disabled"
             :err="err"
             :rules="required ? 'required' : ''"
             :rules-messages="{required: 'Обязательно для заполнения'}"
             :vid="vid"
             err-text="Обязательно для заполнения"
             :validation-mode="validationMode"
             v-bind="$props"/>
    <v-menu
      v-if="showMenu"
      :options="internalOptions"
      :value="internalValue"
      :max-height="maxHeight"
      @click="setSelectedOption"
      @scrolledToEnd="scrolledToEnd"
    />
  </div>
</template>

<script>
import { VInput, VMenu } from '@/components';
import { debounce } from 'lodash';
import { BASE_URL } from '@/constants/api';

export default {
  name: 'VSearchSelect',
  components: {
    VInput,
    VMenu,
  },
  props: {
    required: { type: Boolean, default: false },
    options: { type: Array, required: false },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: false },
    value: { type: String, default: '' },
    title: { type: String, required: false },
    vid: { type: String, default: '' },
    emitFilter: { type: Boolean, default: false },
    err: { type: Boolean, default: false },
    maxHeight: { type: String, required: false },
    urlSearch: {
      type: String,
      default: null,
    },
    urlDefaultData: {
      type: String,
      default: null,
    },
    fetchDataFunction: {
      type: Function,
      default: null,
    },
    fetchDefaultDataFunction: {
      type: Function,
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      required: false,
      default: null,
    },
    findIds: {
      type: Array,
      required: false,
      validator(value) {
        return value.every((item) => typeof item.queryIndex === 'number');
      },
    },
    addElementItem: {
      type: Boolean,
      default: false,
    },
    notResetSearch: {
      type: Boolean,
      default: false,
    },
    newItem: {
      type: Object,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      showMenu: false,
      internalValue: this.value?.toString(),
      internalOptions: this.options ?? [],
      selectedOption: this.value?.toString(),
      defaultOptions: [],
      isNewItem: false,
      reFetchData: debounce(function() {
        this.fetchData();
      }, 500),
    };
  },
  computed: {
    optionsMap() {
      if (this.internalOptions) {
        return this.internalOptions.reduce((acc, cur) => {
          acc[cur.value] = cur.label;
          return acc;
        }, {});
      }

      return {};
    },
    isSearch() {
      return this.internalValue?.length > 0 && (!this.selectedOption || this.selectedOption.length === 0);
    },
    isFetching() {
      return this.urlSearch || this.fetchDataFunction || this.urlDefaultData || this.fetchDefaultDataFunction;
    },
    emptyValue() {
      return this.options ? '' : null;
    },
    validationMode() {
      if (this.required && this.addElementItem) {
        return 'aggressive';
      }

      return 'lazy';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSelectedOption(this.value);
      },
    },
    options() {
      this.internalOptions = this.options;

      if (this.value) {
        this.setSelectedOption(this.value);
      }
    },
    internalValue() {
      if (this.internalValue === undefined || this.internalValue === null) {
        return;
      }
      if (this.internalValue && this.internalValue !== this.internalValue.toString().trim()) {
        this.internalValue = this.internalValue.trim();
        return;
      }
      if (this.internalValue?.length === 0) {
        this.selectedOption = this.emptyValue;
        this.$emit('input', this.emptyValue, this.emptyValue);
      }

      if (this.notResetSearch) {
        this.selectedOption = '';
        this.$emit('update:search', this.internalValue);
      }

      if (this.urlSearch || this.fetchDataFunction) {
        this.fetchData();
      } else {
        this.internalOptions = (this.options ?? this.defaultOptions)?.filter((it) => it.label.toLowerCase().includes(this.internalValue.toString().trim().toLowerCase()));
      }

      if (this.emitFilter) {
        this.changeFilter();
      }
    },
    findIds: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
    internalOptions(val) {
      this.$emit('options', val);

      if (this.internalOptions.length === 0) {
        this.internalOptions.push({ label: 'Отсутствуют данные', value: 'missing-data' });
      }
      if (this.addElementItem && !val.find((item) => item.value === 'add-element')) {
        this.internalOptions.push({ label: 'Добавить элемент', value: 'add-element', isAction: true });
      }
    },
    async dependency() {
      this.reFetchData();
    },
    async newItem(val) {
      this.isNewItem = true;
      this.internalValue = val?.name;
      await this.fetchData();
      this.setSelectedOption(val?.id);
    },
    search: {
      immediate: true,
      handler(val) {
        if (val === undefined) {
          return;
        }

        this.internalValue = val;
      },
    },
  },
  methods: {
    setShowMenu() {
      if (this.disabled) return;
      this.showMenu = true;
    },
    setSelectedOption(pValue) {
      const value = pValue?.toString();

      if (value === 'add-element') {
        this.$emit('addElement');
        return;
      }

      if (value === 'missing-data') {
        return;
      }

      this.selectedOption = value ?? '';

      if (!this.internalOptions || this.internalOptions.length === 0) {
        return;
      }

      if (this.notResetSearch) {
        this.internalValue = this.optionsMap[value] ?? value;

        if (this.optionsMap[value]) {
          this.showMenu = false;
        }
      } else {
        this.internalValue = this.optionsMap[value] ?? '';
        this.showMenu = false;
      }

      this.$emit('input', value, this.internalValue);
    },
    clickOutside() {
      this.showMenu = false;
      if (this.notResetSearch) {
        return;
      }
      // TODO: фикс непонятного вызова clickOutside при инициализации, при котором this.optionsMap === {} (пример, редактирование элемент АХП)
      if (Object.keys(this.optionsMap).length === 0) {
        return;
      }

      if (this.selectedOption?.length > 0) {
        this.internalValue = this.optionsMap[this.selectedOption];
      } else {
        this.internalValue = '';
      }
    },
    changeFilter: debounce(function() {
      this.$emit('changeFilter', this.internalValue?.toString()?.trim());
    }, 500),
    scrolledToEnd() {
      this.$emit('scrolledToEnd');
    },
    async fetchDataFunctionSearch(value) {
      if (this.isSearch && this.fetchDataFunction) {
        return await this.fetchDataFunction(value, this.findIds);
      }

      return await this.fetchDefaultDataFunction(this.findIds);
    },
    async fetchData() {
      if (this.isNewItem && !this.internalValue) {
        return;
      }

      if (!this.isFetching) {
        return;
      }

      let data = [];

      if (this.fetchDataFunction || this.fetchDefaultDataFunction) {
        data = await this.fetchDataFunctionSearch(this.internalValue?.toString()?.trim());
      } else {
        const response = await fetch(this.getURL());
        data = await response.json();
      }

      this.internalOptions.splice(0, this.internalOptions.length);
      data.forEach((element) => {
        this.internalOptions.push({ ...element, value: element.id.toString(), label: element.name });
      });

      if (!this.urlSearch && !this.fetchDefaultDataFunction) {
        this.defaultOptions = [...this.internalOptions];
      }

      if (this.selectedOption?.toString()?.length > 0) {
        if (this.notResetSearch) {
          this.internalValue = this.optionsMap[this.selectedOption] ?? this.internalValue;
        } else {
          this.internalValue = this.optionsMap[this.selectedOption];
        }

        this.$emit('input', this.selectedOption, this.internalValue);
      }
    },
    prepareQueryParameters() {
      const queryParameters = [];

      if (this.findIds) {
        [...this.findIds]
            .sort((first, second) => (first.queryIndex > second.queryIndex ? 1 : -1))
            .forEach((findId) => queryParameters.push(findId.value ?? -1));
      }

      if (this.isSearch) {
        queryParameters.push(this.internalValue);
      }

      return queryParameters;
    },
    getURL() {
      const url = this.isSearch && this.urlSearch ? this.urlSearch : this.urlDefaultData;

      return BASE_URL + url.format(this.prepareQueryParameters());
    },
  },
};
</script>

<style lang="scss" module>
.search-select-container {
  position: relative;
}
</style>
