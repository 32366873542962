<template>
  <div>
    <div class="cabinet-company-details">
      <h1 v-if="!$route.params.inn" class="cabinet-company-details__title" style="margin-left: 8px;">
        Реквизиты {{ companyNamingTitle }}
      </h1>
      <div
        v-else
        class="company-card-header"
      >
        <button
          class="btn-back"
          @click="back"
        >
          Назад
        </button>
        <navigation-bar
          :current-step="currentStep"
          :steps="steps"
          style="padding-top: 8px"
          @open="openStep"
        />
      </div>
      <v-divider style="margin: 12px 40px 32px 0;"/>
      <div v-if="profile" class="page-form" style="margin: 32px 4px 4px 56px">
        <div class="page-form__form">
          <div class="row">
            <h1 class="col col--1of1 mb-24 page-form__title">
              {{ profile.name }}
            </h1>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div
            v-if="isScreenWidthLess1000px"
            class="row"
          >
            <div class="col col--1of1 space-between align-items">
              <required-label/>
            </div>
          </div>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              {{ companyDataTitle }}
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.name"
                :disabled="true"
                title="Наименование организации (полное) *"
              />
            </div>
          </div>

          <div v-if="!isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.departmentName"
                :disabled="true"
                title="Название филиала"
              />
            </div>
          </div>

          <div v-if="!isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.shortName"
                :disabled="true"
                title="Наименование организации (краткое) *"
              />
            </div>
          </div>

          <div v-if="!isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.fullEnglishName"
                :disabled="true"
                title="Наименование организации на английском языке (полное) *"
              />
            </div>
          </div>

          <div v-if="!isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.shorEnglishName"
                :disabled="true"
                title="Наименование организации на английском языке (краткое) *"
              />
            </div>
          </div>
          <div v-if="!isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="profile.inn"
                :disabled="true"
                title="ИНН *"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="profile.kpp"
                :disabled="true"
                title="КПП *"
              />
            </div>
          </div>

          <div v-if="isIndividualEnterpreneur" class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="profile.inn"
                :disabled="true"
                title="ИНН *"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="profile.ogrnip"
                :disabled="true"
                title="ОГРНИП *"
              />
            </div>
          </div>

          <div v-else class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="profile.ogrn"
                :disabled="true"
                title="ОГРН *"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="profile.okpo"
                :disabled="true"
                title="ОКПО *"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              Виды деятельности
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                v-model="profile.okved"
                :disabled="true"
                title="Основной ОКВЭД *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div v-for="(okved, idx) in profile.additionalOkved" :key="idx" class="col col--1of3">
              <v-input
                v-model="profile.additionalOkved[idx]"
                :disabled="true"
                title="Дополнительные ОКВЭД"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              Адрес {{ companyNamingTitle }}
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--3of3">
              <v-address-search-select
                v-model="profile.legalAddress"
                :disabled="true"
                label="Юридический адрес *"
              />
            </div>
            <!--            <div class="col col&#45;&#45;1of3">-->
            <!--              <v-input-->
            <!--                :disabled="true"-->
            <!--                title="Почтовый индекс"-->
            <!--              />-->
            <!--            </div>-->
          </div>

          <div class="row mb-24">
            <div class="col col--3of3">
              <v-address-search-select
                v-model="profile.postAddress"
                :disabled="true"
                label="Фактический адрес *"
              />
            </div>
            <!--            <div class="col col&#45;&#45;1of3">-->
            <!--              <v-input-->
            <!--                :disabled="true"-->
            <!--                title="Почтовый индекс"-->
            <!--              />-->
            <!--            </div>-->
          </div>

          <v-divider v-if="ownerFullname" class="mb-24 mt-24 page-form__divider"/>

          <div v-if="ownerFullname" class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              ФИО Генерального директора
            </h2>
          </div>

          <div v-if="ownerFullname" class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="ownerFullname.surname"
                :disabled="true"
                title="Фамилия *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="ownerFullname.name"
                :disabled="true"
                title="Имя *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="ownerFullname.patronymic"
                :disabled="true"
                title="Отчество"
              />
            </div>
          </div>

          <v-divider v-if="chiefAcountant" class="mb-24 mt-24 page-form__divider"/>

          <div v-if="chiefAcountant" class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              ФИО Главного бухгалтера
            </h2>
          </div>

          <div v-if="chiefAcountant" class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="chiefAcountant.surname"
                :disabled="true"
                title="Фамилия *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="chiefAcountant.name"
                :disabled="true"
                title="Имя *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="chiefAcountant.patronymic"
                :disabled="true"
                title="Отчество"
              />
            </div>
          </div>

          <div v-if="chiefAcountant?.attorneyNumber" class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="chiefAcountant.attorneyNumber"
                :disabled="true"
                title="№ доверенности *"
              />
            </div>
            <div class="col col--1of3">
              <v-date-picker
                v-model="chiefAcountant.attorneyGrantedAt"
                :disabled="true"
                title="Дата выдачи *"
              />
            </div>
            <div class="col col--1of3">
              <v-date-picker
                v-model="chiefAcountant.attorneyExpiresAt"
                :disabled="true"
                title="Действует до *"
              />
            </div>
          </div>

          <v-divider class="mb-24 page-form__divider"/>

          <bank-requisites-block v-model="profile.bankRequisites" :errors="errors" :is-edit-mode-on="isEditModeOn"/>

          <v-divider class="mb-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1  page-form__sub-title">
              Контактные данные
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="profile.phoneNumber"
                :clearable="isEditModeOn"
                :disabled="!isEditModeOn"
                :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                title="Телефон"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="profile.email"
                :clearable="isEditModeOn"
                :disabled="!isEditModeOn"
                title="E-mail"
              />
            </div>
          </div>
          <div v-if="documents.length" class="row mb-24">
            <div class="col col--1of1">
              <documents-list :documents="documents"/>
            </div>
          </div>

          <horizontal-progress-bar v-if="isWaitingSubmit"/>

          <v-divider v-if="canEdit" class="mb-24 page-form__divider"/>

          <div v-if="canEdit" class="row mb-44">
            <div v-if="!$route.params.inn || isPlatformWorker"
                 class="col col--1of1 page-form__changes-buttons"
            >
              <div class="col col--1of1 page-form__changes-buttons">
                <v-button v-if="!isIndividualEnterpreneur"
                          :disabled="isWaitingSubmit || isEditModeOn"
                          variant="outlined-colored"
                          @click="onUpdateByFns"
                >
                  Актуализировать данные компании из ЕГРЮЛ
                </v-button>
                <v-button
                  v-if="!isEditModeOn && getAuthorization.authorization.role.isCompanyOwner && !isPlatformWorker"
                  :disabled="isEditModeOn"
                  class="button delete"
                  variant="outlined-red"
                  @click="openDeleteModal"
                >
                  <delete fill="#EB5757"/>
                  {{ deleteCompanyButtonTitle }}
                </v-button>

                <template v-if="isEditModeOn">
                  <v-button
                      :disabled="isWaitingSubmit || !isEditModeOn"
                      class="button"
                      variant="outlined-red"
                      @click="revertChange"
                  >
                    <cancel/>
                    Отменить изменения
                  </v-button>
                  <v-button
                      :disabled="isWaitingSubmit || !isEditModeOn"
                      class="button"
                      variant="outlined-colored"
                      @click="onSave"
                  >
                    <save/>
                    Сохранить
                  </v-button>
                </template>
                <v-button
                    v-if="!isEditModeOn"
                    :disabled="isEditModeOn"
                    class="button"
                    variant="outlined-colored"
                    @click="changeEditMode"
                >
                  <edit-without-circle/>
                  Редактировать
                </v-button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isScreenWidthLess1000px"
          :style="{
          marginLeft: '20px',
          maxWidth: '300px',
        }"
          class="page-form__aside"
        >
          <div
            class="row page-form__required"
          >
            <div class="col col--1of1">
              <required-label/>
            </div>
          </div>
        </div>
        <div class="cabinet-company-details__history-block">
          <history v-if="profileHistory && profileHistory.length > 0" :can-be-collapsed="false" :history="profileHistory"
                   :title="companyHistoryTitle"/>
          <history v-if="bankRequisitesHistory && bankRequisitesHistory.length > 0" :can-be-collapsed="false" :history="bankRequisitesHistory"
                   title="История изменения банковских реквизитов"/>
        </div>
      </div>
    </div>

    <modal
      v-if="isDeleteModalVisible"
      :body-style="{
      padding: '24px 0',
    }"
      :header-style="{
      display: 'flex',
      justifyContent: 'center',
    }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
      borderRadius: '16px',
      padding: '50px 100px',
    }"
      class="delete-user-data-modal">
      <template #header>
        <span class="delete-user-data-modal__title">Подтвердите подачу заявки удаление профиля {{
            companyNamingTitle
          }}</span>
      </template>
      <template #body>
        <span>Подать заявку на удаление профиля {{ companyNamingTitle }}, данные будут архивированы.</span>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-around;">
          <v-button
            type="button"
            @click="onDelete"
          >
            Подтвердить
          </v-button>
          <v-button
            type="button"
            variant="outlined"
            @click="closeDeleteModal"
          >
            Отменить
          </v-button>
        </div>
      </template>
    </modal>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { convertDbDateTimeFormat, convertDbFormat } from 'lib/utils/date';
import HTTPError from 'lib/utils/errors';
import validate from 'lib/utils/validationRules';
import { checkField } from 'utils/common';
import { insertAt } from 'utils/insert';
// components
import {
  DocumentsList,
  History,
  Modal,
  RequiredLabel,
  VAddressSearchSelect,
  VButton,
  VDivider,
  VInput,
} from 'components';
import BankRequisitesBlock from 'views/private/CabinetCompanyDetails/BankRequisitesBlock.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

// icons
import EditWithoutCircle from 'atoms/icons/action/EditWithoutCircle.vue';
import Save from 'atoms/icons/action/Save.vue';
import Cancel from 'atoms/icons/action/Cancel.vue';
import Delete from 'atoms/icons/action/Delete.vue';

import is from 'vue2-datepicker/locale/es/is';
import generateXml from 'lib/storage/connection-application/data';
import VDatePicker from 'components/VDatePicker/VDatePicker';
import NavigationBar from 'components/NavigationBar/NavigationBar';

export default {
  name: 'CabinetCompanyDetails',
  components: {
    VDatePicker,
    NavigationBar,
    Delete,
    Modal,
    Cancel,
    Save,
    EditWithoutCircle,
    HorizontalProgressBar,
    History,
    BankRequisitesBlock,
    VInput,
    VDivider,
    DocumentsList,
    VButton,
    VAddressSearchSelect,
    RequiredLabel,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        {
          stepNumber: {
            value: 1,
            from: 2,
            title: () => `Справочник ${this.companyType}`,
          },
        },
        {
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Реквизиты компаний',
          },
        },
      ],
      isDeleteModalVisible: false,
      employees: null,
      profile: undefined,
      oldProfile: undefined,
      submitError: '',
      isWaitingSubmit: false,
      profileHistory: [],
      bankRequisitesHistory: [],
      errors: {
        bankName: [],
        bik: [],
        settlementAccount: [],
        correspondentAccount: [],
      },
      componentKey: 1,
      isEditModeOn: false,
      oldProfileForRevert: null,
      documents: [],
    };
  },
  computed: {
    companyDataTitle() {
      if (this.isIndividualEnterpreneur) {
        return 'Данные об индивидуальном предпринимателе';
      }
      return 'Данные о компании';
    },
    companyHistoryTitle() {
      if (this.isIndividualEnterpreneur) {
        return 'История изменения данных об индивидуальном предпринимателе';
      }
      return 'История изменения данных о компании';
    },
    companyHistoryMessage() {
      if (this.isIndividualEnterpreneur) {
        return 'Данные индивидуального предпринимателя обновлены';
      }
      return 'Данные компании обновлены по информации из ФНС';
    },
    deleteCompanyButtonTitle() {
      if (this.isIndividualEnterpreneur) {
        return 'Удалить индивидуального предпринимателя';
      }
      return 'Удалить компанию';
    },
    companyNamingTitle() {
      if (this.isIndividualEnterpreneur) {
        return 'индивидуального предпринимателя';
      }
      return 'компании';
    },
    companyType() {
      const pathSegments = this.$route.path.split('/');
      const utilities = pathSegments[2];
      if (utilities === 'utilities') {
        return 'сетевых компаний';
      } else if (utilities === 'retailers') {
        return 'сбытовых компаний';
      } else if (utilities === 'contractors') {
        return 'контрагентов';
      }
      return ''; // Возврат пустой строки для других случаев

    },
    is() {
      return is;
    },
    canEdit() {
      return this.getAuthorization.authorization.role.permissions.some((it) => it.code === 'SVET_B3-W') || this.isPlatformWorker;
    },
    isScreenWidthLess1000px() {
      return window.matchMedia('(max-width: 1000px)').matches;
    },
    ownerFullname() {
      if (!this.employees || this.employees.length === 0) {
        return null;
      }

      const employee = this.employees.find((employee) => employee?.role?.isCompanyOwner === true);

      if (!employee) {
        return null;
      }

      return {
        name: checkField(employee?.user?.fullName?.name),
        surname: checkField(employee?.user?.fullName?.surname),
        patronymic: checkField(employee?.user?.fullName?.patronymic),
      };
    },
    chiefAcountant() {
      if (!this.employees || this.employees.length === 0) {
        return null;
      }

      const employee = this.employees.find((employee) => employee?.role?.isChiefAccountant === true);

      if (!employee) {
        return null;
      }
      return {
        name: checkField(employee?.user?.fullName?.name),
        surname: checkField(employee?.user?.fullName?.surname),
        patronymic: checkField(employee?.user?.fullName?.patronymic),
        attorneyNumber: checkField(employee?.powerOfAttorney[0]?.number),
        attorneyGrantedAt: convertDbFormat(employee?.powerOfAttorney[0]?.grantedAt),
        attorneyExpiresAt: convertDbFormat(employee?.powerOfAttorney[0]?.expiresAt),
      };
    },
    ...mapState('user', ['account', 'getUser']),
    ...mapGetters('user', ['getAuthorization', 'getAccount', 'isIndividualEnterpreneur', 'isPlatformWorker']),
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
      this.$router.go(-1);
    },
    back() {
      this.$router.go(-1);
    },
    openDeleteModal() {
      if (this.isWaitingSubmit || this.isEditModeOn) {
        return;
      }
      this.isDeleteModalVisible = true;
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    async onDelete() {
      this.isWaitingSubmit = true;
      this.closeDeleteModal();
      const xml = generateXml.RequestDeleteCompany(this.getAccount.id);
      try {
        await this.documentUpload({ xml: xml });
        this.vueShowNotification('Запрос отправлен', '<p>Ваш запрос на удаление профиля компании на Платформе отправлен</p>');
        await this.$router.push('/');
      } catch (e) {
        console.log(e);
        const errorMessage = e.message.split(': ')[2];
        this.vueShowNotification(
          '',
          `<p style="color: red">${errorMessage}</p>`,
        );
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    revertChange() {
      console.log('forRevert', this.oldProfileForRevert);
      this.profile = JSON.parse(JSON.stringify(this.oldProfileForRevert));
      console.log(this.profile);
      this.isEditModeOn = false;
    },
    changeEditMode() {
      this.isEditModeOn = !this.isEditModeOn;
      console.log(this.profile);
    },
    async loadData() {
      if (this.$route.query.id) {
        const account = await this.getAccountAction({ id: this.$route.query.id });

        this.profile = account.profile;
        this.profile.postAddress = this.swapNullToString(this.profile.postAddress);
        this.profile.legalAddress = this.swapNullToString(this.profile.legalAddress);

        this.oldProfile = JSON.parse(JSON.stringify(this.profile, (key, value) => (value === null ? '' : value)));
        this.oldProfileForRevert = JSON.parse(JSON.stringify(this.profile));
        this.bankRequisitesHistory = [{
          message: 'Банковские реквизиты обновлены.',
          datetime: this.profile?.bankRequisitesUpdatedAt,
        }];
      } else {
        const user = await this.userAuthorizations();
        const { authorizations } = await this.getUserFunction();
        this.employees = authorizations;
        this.profile = user.authorizations.filter((item) => item.account.id === this.account.id)[0].account.profile;
        this.profile.postAddress = this.swapNullToString(this.profile.postAddress);
        this.profile.legalAddress = this.swapNullToString(this.profile.legalAddress);
        this.oldProfile = JSON.parse(JSON.stringify(this.profile, (key, value) => (value === null ? '' : value)));
        this.oldProfileForRevert = JSON.parse(JSON.stringify(this.profile));
        this.profile.phoneNumber = this.initConvertPhoneNumber(this.profile.phoneNumber);
      }

      this.profileHistory = this.profile.history?.map((it) => ({
        message: this.companyHistoryMessage,
        datetime: it.updatedAt,
      })) ?? [];
      this.bankRequisitesHistory = this.profile.bankRequisitesHistory?.map((it) => ({
        message: `Банковские реквизиты обновлены. ${it.updatedBy.fullName.surname} ${it.updatedBy.fullName.name} ${it.updatedBy.fullName.patronymic ?? ''}`,
        datetime: it.updatedAt,
      })) ?? [];
    },
    swapNullToString(value) {
      if (value === null) {
        return '';
      }
      return value;
    },
    convertDbDateTimeFormat(value) {
      return convertDbDateTimeFormat(value);
    },
    async onUpdateByFns() {
      this.isWaitingSubmit = true;
      this.clearErrors();
      this.submitError = '';
      try {
        await this.updateCompanyProfileByFns({ companyAccountId: this.$route.query.id });
        this.oldProfile = JSON.parse(JSON.stringify(this.profile));
        await this.loadData();
      } catch (error) {
        if (error.message.includes('не найдена в ЕГРЮЛ')) {
          this.submitError = error.message.split(':')[2];
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: `${this.submitError}`,
          });
          return;
        }
        const errorData = HTTPError.network(error);
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${errorData.message}`,
        });
        this.submitError = errorData.message;
      } finally {
        this.isEditModeOn = false;
        this.isWaitingSubmit = false;
      }
    },
    async onSave() {
      this.isWaitingSubmit = true;
      this.clearErrors();

      if (!this.validation()) {
        this.isWaitingSubmit = false;
        this.componentKey += 1;
        return;
      }

      this.isWaitingSubmit = true;
      this.submitError = '';

      const xml = generateXml.GenerateRequestUpdateCompanyProfileXML(
        {
          bankRequisites: this.profile.bankRequisites,
          contactInformation: {
            email: this.checkValueIsEmpty(this.profile.email),
            phoneNumber: this.checkValueIsEmpty(this.unformatPhonePrefix(this.profile.phoneNumber)),
          },
          accountId: this.$route.query.id ? this.$route.query.id : this.getAccount.id,
        },
      );

      try {
        console.log(this.profile);
        await this.documentUpload({ xml: xml });
        this.oldProfile = JSON.parse(JSON.stringify(this.profile));
        await this.loadData();
        await this.setUser();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
      } finally {
        this.isEditModeOn = false;
        this.isWaitingSubmit = false;
      }
    },
    validation() {
      const errorText = { message: '' };
      let isValid = true;

      this.profile.bankRequisites.forEach((item, idx) => {
        this.errors.bankName.push({});
        this.errors.bik.push({});
        this.errors.settlementAccount.push({});
        this.errors.correspondentAccount.push({});

        if (item.bankName !== this.oldProfile.bankRequisites[idx]?.bankName) {
          this.errors.bankName[idx].error = !validate.required(item.bankName);
          this.errors.bankName[idx].text = 'Обязательно для заполнения';

          if (this.errors.bankName[idx].error) {
            isValid = false;
          }
        }

        if (item.bik.length <= 0 && item.settlementAccount.length <= 0 && item.correspondentAccount.length <= 0 && item.bankName.length <= 0) return;
        if (item.bik !== this.oldProfile.bankRequisites[idx]?.bik) {
          this.errors.bik[idx].error = !validate.validateBik(item.bik, errorText);
          this.errors.bik[idx].text = errorText.message;

          if (this.errors.bik[idx].error) {
            isValid = false;
          }
        }

        if (item.settlementAccount !== this.oldProfile.bankRequisites[idx]?.settlementAccount) {
          this.errors.settlementAccount[idx].error = !validate.validateCompanyAccount(item.settlementAccount, errorText);
          this.errors.settlementAccount[idx].text = errorText.message;

          if (this.errors.settlementAccount[idx].error) {
            isValid = false;
          }
        }

        if (item.correspondentAccount !== this.oldProfile.bankRequisites[idx]?.correspondentAccount) {
          this.errors.correspondentAccount[idx].error = !validate.validateCompanyAccount(item.correspondentAccount, errorText);
          this.errors.correspondentAccount[idx].text = errorText.message;

          if (this.errors.correspondentAccount[idx].error) {
            isValid = false;
          }
        }
      });
      return isValid;
    },
    clearErrors() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key].error = false;
      });
      this.componentKey += 1;
    },
    unformatPhonePrefix(phone) {
      return phone.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
    },
    checkValueIsEmpty(value) {
      if (value) {
        return value;
      }
      return null;
    },
    // TODO Метод костыль появился, потому что не получилось найти причину неправильной (неотформатированной) инициализации при прокидывании в компонент с маской телефона
    initConvertPhoneNumber(phoneNumber) {
      if (!phoneNumber || phoneNumber.length !== 12 || phoneNumber[0] !== '+') {
        return null;
      }
      const phoneChars = [
        {
          symbol: ' ',
          position: 2,
        },
        {
          symbol: '(',
          position: 3,
        },
        {
          symbol: ')',
          position: 7,
        },
        {
          symbol: ' ',
          position: 8,
        },
        {
          symbol: '-',
          position: 12,
        },
        {
          symbol: '-',
          position: 15,
        }];
      phoneChars.forEach((char) => {
        phoneNumber = insertAt(phoneNumber, char.symbol, char.position);
      });
      return phoneNumber.trim();
    },
    ...mapActions('user', ['setUser', 'userAuthorizations', 'updateCompanyProfileByFns', 'getUserFunction', 'getAccountAction']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";
@import "@/assets/scss/commonCardStyles";

.company-card-header {
  display: flex;
  margin: 0 0 8px 12px;

  .navigator {
    margin: unset;
  }

  .btn-back {
    width: 86px;
    height: 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #677379;
    padding-left: 12px;
    margin-right: 24px;
    background-image: url('~icons/navigation/arrow_left.svg');
    background-repeat: no-repeat;
    background-position: center left 4px;
    background-color: #FFFFFF;
    border: 1px solid #C1C4C7;
    border-radius: 24px;

    &:hover {
      background-color: #F5F6F6;
    }
  }
}

.cabinet-company-details {
  &__bank-title {
    display: inline-block;
    font-size: 14px;
    color: #71757a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }

  &__title {
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #C1C4C7;
  }

  &__error-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    margin-top: 12px;
    color: red;
  }

  &__history-block {
    display: flex;
    gap: 48px;
    flex-direction: column;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 11px;
}

.page-form {
  &__changes-buttons {
    display: flex;
    gap: 16px;

    @media (max-width: 860px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  @media (max-width: 860px) {
    flex-direction: column;
    gap: 4px;
  }

  &__required {
    margin-top: 102px;

    @media (max-width: 860px) {
      margin-top: 0;
    }
  }
}
</style>
