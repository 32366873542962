<template>
  <div>
    <content-block :balances="internalBalances" />
  </div>
</template>

<script>
import ContentBlock from './ContentBlock';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import formatting from 'lib/utils/formatting';

const initialFilters = {
  billingPeriod: '',
  paymentVolumeFrom: '',
  paymentVolumeTo: '',
  totalLossFrom: '',
  totalLossTo: '',
};

export default {
  name: 'Balances',
  components: {
    ContentBlock,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      balances: [],
      internalBalances: [],
      filters: this.initialFilters(),
      isWaitingSubmit: false,
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      balanceIndicators: undefined,
      totalLoss: '',
      displayBalanceDialog: false,
    };
  },
  computed: {
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.balanceIndicators) {
        return {
          transmissionContract: unknown,
          usefulVacation: unknown,
          integralActs: {
            ...unknown,
            text: 'Интегральные акты сформированы',
          },
        };
      }

      const result = {};

      result.transmissionContract = this.balanceIndicators.isTransmissionContractSigned ? check : error;
      result.usefulVacation = this.balanceIndicators.isUsefulVacationExists ? check : error;
      result.isPreviousBalanceExists = this.balanceIndicators.isPreviousBalanceExists;

      const isIntegralActsText = this.balanceIndicators.isIntegralActsExists ? 'Интегральные акты подписаны' : 'Интегральные акты сформированы';

      if (this.balanceIndicators.isIntegralActsExists && this.balanceIndicators.isIntegralActsSigned) {
        result.integralActs = {
          ...check,
          text: isIntegralActsText,
        };
      } else {
        result.integralActs = {
          ...error,
          text: isIntegralActsText,
        };
      }

      return result;
    },
    isFiltersActive() {
      return this.filters.billingPeriod?.length > 0 ||
        this.filters.paymentVolumeFrom?.length > 0 ||
        this.filters.paymentVolumeTo?.length > 0 ||
        this.filters.totalLossFrom?.length > 0 ||
        this.filters.totalLossTo?.length > 0;
    },
  },
  watch: {
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.balanceIndicators = undefined;
          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          this.balanceIndicators = await this.loadBalanceIndicators({ year: date.year(), month: date.month() + 1 });
        }
      },
    },
  },
  filters: {
    totalLossFilter(value) {
      value = value.split(' ').join('').replace(/^0+/, '');
      return formatting.formatByThousands(value);
    },
  },
  methods: {
    updateTotalLoss(e) {
      const value = e.target.value.split(' ').join('').replace(/^0+/, '');

      if (value.includes(' ')) {
        return this.$refs.totalLoss.value = formatting.formatByThousands(this.totalLoss);
      }

      if (value === '' || value === '-') {
        return;
      }

      if (this.validateByAllowedChars(value)) {
        return this.totalLoss = value;
      }

      return this.$refs.totalLoss.value = formatting.formatByThousands(this.totalLoss);
    },
    validateByAllowedChars(str) {
      const allowedChars = ['-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const totalLossArr = str.split('');

      for (let i = 0; i < totalLossArr.length; i++) {
        const char = totalLossArr[i];

        if (!allowedChars.includes(char)) {
          return false;
        }

        if (char === '-' && i !== 0) {
          return false;
        }
      }

      return true;
    },
    onSearchClient() {
      const paymentVolumeFromNumber = Number(this.filters.paymentVolumeFrom.replaceAll(' ', ''));
      const paymentVolumeToNumber = Number(this.filters.paymentVolumeTo.replaceAll(' ', ''));
      const totalLossFromNumber = Number(this.filters.totalLossFrom.replaceAll(' ', ''));
      const totalLossToNumber = Number(this.filters.totalLossTo.replaceAll(' ', ''));

      this.internalBalances = this.balances
        .filter((balance) => (this.filters.billingPeriod.length > 0
            ?            (
              Number(balance.accountingPeriod.year) === dayjs(this.filters.billingPeriod, 'MMMM | YYYY').year() &&
              Number(balance.accountingPeriod.month) === dayjs(this.filters.billingPeriod, 'MMMM | YYYY').month() + 1
            )
            : true))
        .filter((balance) => (this.filters.paymentVolumeFrom.length > 0 || this.filters.paymentVolumeTo.length > 0
            ?            (
              (this.filters.paymentVolumeFrom.length === 0 || Number(balance.paymentVolume) >= paymentVolumeFromNumber) &&
              (this.filters.paymentVolumeTo.length === 0 || Number(balance.paymentVolume) <= paymentVolumeToNumber)
            )
            : true))
        .filter((balance) => (this.filters.totalLossFrom.length > 0 || this.filters.totalLossTo.length > 0
            ?            (
              (this.filters.totalLossFrom.length === 0 || Number(balance.totalLoss) >= totalLossFromNumber) &&
              (this.filters.totalLossTo.length === 0 || Number(balance.totalLoss) <= totalLossToNumber)
            )
            : true));
    },
    async onSearchServer() {
      try {
        const filter = {
          accountingPeriod:
            this.filters.billingPeriod.length > 0
              ? {
                year: dayjs(this.filters.billingPeriod, 'MMMM | YYYY').year(),
                month: dayjs(this.filters.billingPeriod, 'MMMM | YYYY').month() + 1,
              }
              : null,
          paymentVolume:
            this.filters.paymentVolumeFrom.length > 0 || this.filters.paymentVolumeTo.length > 0
              ? {
                from: this.filters.paymentVolumeFrom,
                to: this.filters.paymentVolumeTo,
              }
              : null,
          totalLoss:
            this.filters.totalLossFrom.length > 0 || this.filters.totalLossTo.length > 0
              ? {
                from: this.filters.totalLossFrom,
                to: this.filters.totalLossTo,
              }
              : null,
        };
        this.internalBalances = await this.loadBalances({
          filter: filter,
        });
      } catch (e) {
        console.log(e);
      }
    },
    onClear() {
      this.filters = { ...initialFilters };
      this.internalBalances = this.balances;
    },
    async loadData() {
      try {
        this.balances = await this.loadBalances({});
        this.internalBalances = this.balances;

        if (this.isFiltersActive) {
          this.onSearchClient();
        }
      } catch (error) {
        console.log('load balances', error);
      }
    },
    async generateBalanceClick() {
      this.displayBalanceDialog = false;

      if (!this.balanceIndicators) {
        return;
      }

      if (!this.balanceIndicators.isUsefulVacationExists ||
        !this.balanceIndicators.isIntegralActsExists ||
        !this.balanceIndicators.isIntegralActsSigned) {

        let errorMessage = '';

        if (!this.balanceIndicators.isUsefulVacationExists) {
          errorMessage += 'Справки о полезном отпуске не загружены. Прошу загрузить полезный отпуск.<br>';
        }
        if (!this.balanceIndicators.isIntegralActsExists) {
          errorMessage += 'АПОПЭЭ и АСППУ акты не сформированы. Прошу сформировать акты.<br>';
        } else if (!this.balanceIndicators.isIntegralActsSigned) {
          errorMessage += 'АПОПЭЭ и АСППУ акты не подписаны. Прошу подписать акты.';
        }

        this.setNotification({ message: errorMessage });
        return;
      }

      this.isWaitingSubmit = true;
      const date = dayjs(this.currentDate, 'MMMM | YYYY');

      let totalLossForSend = null;

      if (!this.indicators.isPreviousBalanceExists) {
        totalLossForSend = Number(this.totalLoss);
      }

      try {
        await this.generateBalance({
          year: date.year(),
          month: date.month() + 1,
          totalLoss: totalLossForSend,
        });
      } catch (e) {
        this.setNotification({ message: 'При формировании баланса' });
        this.isWaitingSubmit = false;
        return;
      }

      const countBalances = this.balances.length;

      await this.loadData();

      if (this.balances.length === countBalances) {
        setTimeout(async () => {
          await this.loadData();
          this.isWaitingSubmit = false;
        }, 1000);

        return;
      }
      this.isWaitingSubmit = false;
    },
    initialFilters() {
      return this.getTableFilterByName()(this.$route.name, initialFilters);
    },
    ...mapActions('cabinet', ['loadBalances', 'loadBalanceIndicators']),
    ...mapActions('document', ['generateBalance']),
    ...mapActions('user', ['setNotification']),
    ...mapGetters('user', ['getTableFilterByName']),
  },
};
</script>

<style lang="scss" module>
.button {
  margin: 24px 0;

  div {
    margin-right: 8px;
  }
}

.generate {
  display: flex;

  input {
    border: 1px solid #C1C4C7;
    border-radius: 24px 0 0 24px;
  }

  button {
    border-radius: 0 24px 24px 0;
  }
}

.submitBlock {
  display: flex;

  div {
    width: 50%;
  }
}

.balanceDialog {
  padding: 40px;
}

.balanceInput {
  width: 100%;
  height: 40px;
  border: 1px solid #dee2e6;
  padding: 0 15px;

  &:focus {
    outline: none;
    border: 1px solid #2f82df;
  }

  &:hover {
    border: 1px solid #2f82df;
  }
}
</style>
