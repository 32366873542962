import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  document: null,
  kind: null,
  statusTitle: null,
  status: null,
  idxDoc: 0,
  idxStat: 0,
  change: JSON.parse(localStorage.getItem('change')),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
