<template>
  <div v-if="checkPermissionForLoadAttorney" :class="$style.container">
    <div :class="$style['container__add-attorney']">
      <div :class="$style['container__block-select-attorney']">
        <img src="~@/assets/icons/basic/document-select.svg"/>
        <select v-model="typeFormAttorney" :class="$style['container__select-attorney']">
          <option value="form">Сформировать МЧД</option>
          <option value="xml">Загрузить XML МЧД</option>
          <option value="upload">Загрузить доверенность</option>
        </select>
      </div>

      <img :class="$style['container__form-attorney']" alt="Перейти" src="~@/assets/icons/basic/select-choice.svg"
           @click="formAttorney"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ActionBlock',
  props: {
    homeTitle: {
      type: String,
      default: 'Доверенности',
    },
    homeUrl: {
      type: String,
      default: '/cabinet/attorney',
    },
  },
  data() {
    return {
      visible: false,
      typeFormAttorney: 'form',
    };
  },
  computed: {
    checkPermissionForLoadAttorney() {
      return this.getPermissions.find((it) => it.code === 'SVET_B2-W');
    },
    ...mapGetters('attorney', ['getFormAttorney']),
    ...mapGetters('user', ['getPermissions']),
  },
  methods: {
    formAttorney() {
      let formAttorney = this.getFormAttorney;

      if (Object.keys(formAttorney).length !== 0) {
        delete formAttorney.steps;
        delete formAttorney.currentStep;

        if (this.typeFormAttorney === 'form') {
          delete formAttorney.issueDate;
          delete formAttorney.powerOfAttorneyScan;
        }
      }

      if (this.typeFormAttorney !== formAttorney.typeFormAttorney) {
        formAttorney = {};
      }

      this.updateFormAttorney(
        {
          ...formAttorney,
          typeFormAttorney: this.typeFormAttorney,
          homeTitle: this.homeTitle,
          homeUrl: this.homeUrl,
        },
      );

      if (this.typeFormAttorney === 'xml') {
        this.$router.push('/cabinet/upload-attorney-xml');
        return;
      }
      this.$router.push('/cabinet/form-attorney');
    },
    ...mapActions('attorney', ['updateFormAttorney']),
  },
};
</script>

<style lang="scss" module>
.container {
  &__add-attorney {
    display: flex;
    gap: 4px;
  }

  &__block-select-attorney {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border: 1px solid #2F82DF;
    border-radius: 24px 2px 2px 24px;
    background-color: inherit;

    img {
      height: 16px;
    }
  }

  &__select-attorney {
    width: 160px;
    border: 0;
    background: inherit;
  }

  &__form-attorney {
    cursor: pointer;
  }
}

</style>
