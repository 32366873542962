<template>
  <div class="search-input">
    <div
    class="search-input__input"
    >
      <v-input
        v-model="searchText"
        :placeholder="placeholder"
      />
    </div>

      <button class="search-input__search-button" @click="search">
        <img src="@/assets/icons/search/loupe.svg"/>
      </button>

      <div class="search-input__clear-button" @click="clear">
        <img src="@/assets/icons/search/cross.svg"/>
      </div>
  </div>
</template>

<script>
import { VInput } from '../VInput';

export default {
  components: {
    VInput,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchText: '',
    };
  },
  methods: {
    clear() {
      this.searchText = '';
      this.$emit('clear');
    },
    search() {
      this.$emit('search', this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  display: flex;

  &__input {
    width: 100%;
    max-width: 400px;
    margin-right: 20px;
  }

  &__search-button {
    cursor: pointer;
    padding: 11px;
    background-color: #2F82DF;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    border-radius: 24px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__search-button:hover {
    background-color: #C1C4C7;
  }

  &__clear-button {
    cursor: pointer;
    padding: 12px;
    border: 1px solid #2F82DF;
    width: 40px;
    height: 40px;
    border-radius: 24px;

    img {
      width: 14px;
      height: 14px;
    }
  }

  &__clear-button:hover {
    background-color: #ea8080;
    border: 1px solid #ea8080;
  }
}
</style>
