<template>
  <div
    :class="{
      [$style['pagination-button']]: true,
      [$style['pagination-button__value']]: size !== -1,
      [$style['pagination-button--selected']]: selected,
    }"
    @click="onClick"
  >
    {{ sizeOrDor }}
  </div>
</template>

<script>
export default {
  name: 'VTablePaginationButton',
  props: {
    size: { type: Number, required: true },
    selected: { type: Boolean, default: false },
  },
  computed: {
    sizeOrDor() {
      return this.size !== -1 ? this.size : '...';
    },
  },
  methods: {
    onClick() {
      if (this.size !== -1) {
        this.$emit('input', this.size);
      }
    },
  },
};
</script>

<style lang="scss" module>
.pagination-button {
  &__value {
    cursor: pointer;

    &:hover {
      background-color: #dee2e6;
    }
  }

  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #4c4e51;
  border-radius: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &--selected {
    background: #979ca2;
    color: #ffffff;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
