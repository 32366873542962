<template>
  <div :class="$style.container">
    <input
      :id="inputId"
      type="checkbox"
      :class="{
        [$style.checkbox]: true,
        [$style.invalid]: error
      }"
      :checked="$props.value"
      :role="role"
      :style="inputStyle"
      :disabled="disabled"
      @change="handleInput"
    />
    <label :for="inputId" :class="$style.label" :style="labelStyle">{{ $props.label }}</label>
  </div>
</template>

<script>
import { generateRandomString } from 'lib/utils/string';

export default {
  name: 'CCheckbox',
  props: {
    value: Boolean,
    label: {
      type: String,
      default: () => '',
    },
    error: {
      type: Object,
      default: () => ({ error: false }),
    },
    role: {
      type: String,
      default: '',
    },
    inputStyle: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: false,
    };
  },
  computed: {
    inputId() {
      return generateRandomString();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internalValue = val;
      },
    },
    internalValue: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleInput($event) {
      this.internalValue = $event.target.checked;
      this.$emit('change');
    },
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    align-items: center

  .checkbox
    width: 26px
    height: 26px
    color: #000
    cursor: pointer

    &::placeholder
      color: #000

  .label
    +base-text
    margin-left: 16px
    cursor: pointer

  .invalid
    color: red
</style>
