<template>
  <div :class="$style.container">
    <div
      :style="{
          position: 'absolute',
          top: '0',
          backgroundColor: '#ffffff',
          height: '40px',
          padding: '10px',
          zIndex: '100',
          width: navBarWidth,
          marginLeft: '-16px',
        }"
    >
      <navigation-bar
        :current-step="connectionApplication.currentStep"
        :steps="steps"
        :style="{
          margin: '0',
        }"
        home="Заявка на подключение объекта"
        @open="openStep"
      />
    </div>

    <div ref="connectionApplicationContent" :class="$style.content" role="detailsContent">
      <div :class="$style.title">
        Заявка на подключение объекта
        <p v-if="connectionApplication.currentStep !== 0" :class="$style.subTitle">
          {{ type }}
        </p>
      </div>

      <component
        :is="steps[connectionApplication.currentStep].component"
        :key="componentKey"
        ref="details"
        :component-key="componentKey"
        :connection-application="connectionApplication"
        :errors="errors"
        :is-additional-owner="isAdditionalOwner"
        :is-legal="isLegal"
        :min-steps="8"
        :only-microgenerator="onlyMicrogenerator"
        :source-document-id="sourceDocumentId"
        :step-number="steps[connectionApplication.currentStep].stepNumber"
        @changeType="changeType"
        @close="close"
        @next="next"
      />
    </div>

    <div :class="$style.contentFooter" :style="{
      position: 'absolute',
      bottom: '0',
      width: footerWidth,
    }">
      <div v-show="displayBack" :class="$style.back" @click="back">
        <arrow-left/>
        <span>НАЗАД</span>
      </div>
      <div :class="$style.delete" @click="displayDeleteDialog = true">
        <span>УДАЛИТЬ ЧЕРНОВИК</span>
      </div>
      <div :class="$style.center">
      </div>
      <div v-show="displayNext" :class="$style.next" @click="next">
        <span>ДАЛЕЕ</span>
        <arrow-right/>
      </div>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить черновик?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft"/>
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>
  </div>
</template>

<script>
import ArrowLeft from 'atoms/icons/arrows/ArrowLeft';
import ArrowRight from 'atoms/icons/arrows/ArrowRight';

import NavigationBar from 'components/NavigationBar/NavigationBar.vue';

import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import localforage from 'localforage';
import throttle from 'lib/utils/throttle';
import PersonalData from './steps/PersonalData';
import ObjectData from './steps/ObjectData';
import SelectType from './steps/SelectType';
import Submit from './steps/Submit';
import validate from 'lib/utils/validationRules';
import { scrollIntoView } from 'lib/utils/DOM';
import { EVENT_BUS } from 'root/eventBus';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import formattingData from 'lib/utils/formatting';
import { maxBy } from 'lodash';

export default {
  name: 'ConnectionApplication',
  components: {
    ArrowLeft,
    ArrowRight,
    NavigationBar,
    DialogTemplate,
    SubmitTaskDetails,
    PersonalData,
    ObjectData,
    SelectType,
    Submit,
  },
  async created() {
    try {
      if (this.$route.params.onlyMicrogenerator) {
        this.onlyMicrogenerator = true;
      }

      if (this.$route.params.connectionApplication) {
        this.connectionApplication = {
          currentStep: 0,
          version: this.connectionApplication.version,
          ...this.$route.params.connectionApplication,
        };
        this.sourceDocumentId = this.$route.params.sourceDocumentId;

        return;
      }

      const connectionApplication = await localforage.getItem(this.storeName);

      if (connectionApplication === null) {
        return;
      }

      const object = JSON.parse(connectionApplication);

      if (object.version === undefined || object.version < this.connectionApplication.version) {
        return;
      }
      this.connectionApplication = object;
    } catch (error) {
      console.log(`error getData ${this.storeName}`, error);
    }
    window.addEventListener('resize', this.setElementsWidth);
  },
  mounted() {
    this.setElementsWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      navBarWidth: '0',
      footerWidth: '0',
      connectionApplication: {
        version: 7,
        currentStep: 0,
        type: '',
        connectionApplicationWithContractType: {
          label: '',
          value: '',
        },
        consumer: {
          familyName: '',
          firstName: '',
          patronymic: '',
          documentType: {},
          otherDocumentType: '',
          passportNumber: '',
          documentSeries: '',
          subdivisionCode: '',
          issueDate: '',
          issuer: '',
          insuranceNumber: '',
          phone: '',
          phoneCode: '',
          additionalPhone: '',
          additionalPhoneCode: '',
          email: '',
          emailCode: '',
          address: '',
          postcodeAddress: '',
          registrationAddress: '',
          postcodeRegistrationAddress: '',
          additionalOwner: false,
          company: {
            name: '',
            address: '',
            ogrn: '',
            inn: '',
          },
        },
        additionalOwner: {
          ownerGroups: [],
        },
        reasonToConnection: {
          label: '',
          value: '',
        },
        objectLocation: {
          objectAddress: '',
          additionalInformation: '',
          landCadastralNumber: '',
          buildingCadastralNumber: '',
          latitude: 55.753239,
          longitude: 37.62177,
          objectType: {},
          otherObjectType: '',
          objectArea: '',
        },
        confirmationDocument: {
          documentTitle: {
            label: '',
            value: '',
          },
          documentNumber: '',
          documentIssueDate: '',
          documentName: '',
        },
        connectionCharacteristics: {
          currentPreset: '',
          energyConsumerCount: {
            label: '',
            value: '',
          },
          maximumPowerVoltage: [],
          maxPowerConnection: '',
          phaseVoltage: '',
          currentVoltage: '',
          phaseVoltageForSelect: {
            label: '',
            value: '',
          },
          previousMaxPower: '0',
          previousPhaseVoltage: '0',
          summaryMaxPower: '0',
          summaryPhaseVoltage: '0',
          reliabilityClass: {
            label: '',
            value: '',
          },
          workload: {},
          tariffCategoryType: {},
          tariffZoneType: {},
          typeAccounting: {},
          readyDate: '',
          supplierOfLastResort: {
            label: '',
            value: '',
          },
          retailAgreementNumber: '',
          retailAgreementNumberDate: '',
          readyConnectionDate: [{
            stage: '',
            designDate: '',
            readyDate: '',
            maxPower: '',
            reliabilityClass: {},
            designDateError: false,
            readyDateError: false,
          }],
        },
        microgenerator: {
          items: [{
            MicrogeneratorLocation: '',
            MaxGeneratePower: '',
            PowerTransmittedToNetwork: '',
            Characteristics: '',
            DocumentScans: [],
          }],
          ReadyDate: '',
        },
        gridCompany: {
          // gridCompanyName: { value: 'АО "ОЭК"', inn: '7720522853' },
          gridCompanyName: {},
          otherGridCompany: '',
        },
        documentScans: {
          passportScans: [],
          powerOfAttorneyScans: [],
          confirmationDocumentScan: [],
          objectShemeScan: [],
          otherScans: [],
        },
        additionalInfo: {
          comments: '',
          additionalDocuments: [],
        },
      },
      steps: [
        {
          component: 'SelectType',
          stepNumber: {
            value: 1,
            from: 4,
            title: () => 'Тип технологического присоединения',
          },
        },
        {
          component: 'PersonalData',
          stepNumber: {
            value: 2,
            from: 4,
            title: () => 'Данные заявителя',
          },
          validations: this.validPersonalData,
        },
        {
          component: 'ObjectData',
          stepNumber: {
            value: 3,
            from: 4,
            title: () => 'Информация по точке учёта',
          },
          validations: this.validObjectData,
        },
        {
          component: 'Submit',
          stepNumber: {
            value: 4,
            from: 4,
            title: () => 'Формирование заявки',
          },
        },
      ],
      componentKey: 1,
      displayDeleteDialog: false,
      sourceDocumentId: undefined,
      errors: {
        consumer: {},
        additionalOwner: [],
        objectLocation: {},
        documentScans: {},
        connectionCharacteristics: {
          maxPowerConnection: [],
          currentVoltage: [],

        },
        confirmationDocument: {},
        gridCompany: {},
        reasonToConnection: {},
      },
      onlyMicrogenerator: false,
    };
  },
  computed: {
    isLegal() {
      return this.getAccount !== null && this.getAccount.kind !== 'INDIVIDUAL_CONSUMER';
    },
    displayBack() {
      return this.connectionApplication.currentStep > 0;
    },
    displayNext() {
      return this.connectionApplication.currentStep < 3 && this.connectionApplication.currentStep !== 0;
    },
    isAdditionalOwner() {
      return this.connectionApplication.additionalOwner?.ownerGroups?.length > 0;
    },
    storeName() {
      return 'ConnectionApplication';
    },
    type() {
      if (!this.connectionApplication.type) {
        return '';
      }

      return this.connectionApplicationTypeList.filter(
        (item) => item.value === this.connectionApplication.type,
      )[0]?.label ?? '';
    },
    ...mapGetters('dataLists', [
      'connectionApplicationTypeList',
    ]),
    ...mapGetters('user', ['getAccount', 'getUser']),
  },
  watch: {
    connectionApplication: {
      deep: true,
      immediate: false,
      handler: 'saveDataIntermediate',
    },
  },
  methods: {
    setElementsWidth() {
      this.navBarWidth = `${this.$refs.connectionApplicationContent.offsetWidth + 16}px`;
      this.footerWidth = `${this.$refs.connectionApplicationContent.offsetWidth}px`;
    },
    saveDataIntermediate: throttle(function() {
      this.saveData();
    }, 500),
    async saveData() {
      try {
        await localforage.setItem(this.storeName, JSON.stringify(this.connectionApplication));
      } catch (error) {
        console.log(`error ${this.storeName}.save, data:`, this.connectionApplication);
      }
    },
    async clearData() {
      try {
        await localforage.removeItem(this.storeName);
      } catch (error) {
        console.log(`error ${this.storeName}.clear, data:`, this.connectionApplication);
      }
    },
    scrollToTop() {
      const scrollButton = document.querySelector('.scroll-button');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      scrollButton.dispatchEvent(clickEvent);
    },
    close() {
      this.$router.push('/cabinet/objects');
    },
    back() {
      if (this.connectionApplication.currentStep === 0) {
        return;
      }
      this.connectionApplication.currentStep -= 1;
      this.scrollToTop();
    },
    next() {
      this.scrollToTop();
      if (this.steps[this.connectionApplication.currentStep].validations) {
        const firstError = this.steps[this.connectionApplication.currentStep].validations();

        if (firstError !== undefined) {
          this.componentKey += 1;
          this.$nextTick(() => {
            console.log('result.firstError', firstError);
            scrollIntoView(document.querySelector(`[role=${firstError}]`));
          });

          return;
        }
      }

      this.connectionApplication.currentStep += 1;

      document.querySelector('[role=detailsContent]').scrollTop = 0;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
        return;
      }
      if (value >= this.connectionApplication.currentStep + 1) {
        return;
      }

      this.connectionApplication.currentStep = value - 1;
    },
    deleteDraft() {
      this.clearData();
      this.close();
    },
    validPersonalData() {
      let firstError = undefined, error = undefined;

      firstError = this.validateAdditionalOwner(this.connectionApplication.additionalOwner);

      error = this.validateConsumer(this.connectionApplication.consumer);

      if (error !== undefined) {
        firstError = error;
      }

      return firstError;
    },
    validateAdditionalOwner(additionalOwner) {
      const errorsList = [];
      let firstError = undefined;

      if (!additionalOwner.ownerGroups) {
        this.errors.additionalOwner = errorsList;

        return firstError;
      }

      Object.keys(additionalOwner.ownerGroups).reverse().forEach((index) => {
        const owner = additionalOwner.ownerGroups[index];
        const errors = {};

        if (validate.isEmptyString(owner.address)) {
          errors.address = { error: true };
          firstError = `address_${index}`;
        } else {
          errors.address = { error: false };
        }

        if (validate.isEmptyString(owner.registrationAddress)) {
          errors.registrationAddress = { error: true };
          firstError = `registrationAddress_${index}`;
        } else {
          errors.registrationAddress = { error: false };
        }

        if (validate.isPhone(owner.additionalPhone) && owner.additionalPhoneCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
          errors.additionalPhoneCode = { error: true };
          firstError = `additionalPhoneCode_${index}`;
        } else {
          errors.additionalPhoneCode = { error: false };
        }

        if (validate.isPhone(owner.phone) && owner.phoneCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
          errors.phoneCode = { error: true };
          firstError = `phoneCode_${index}`;
        } else {
          errors.phoneCode = { error: false };
        }

        if (!validate.isPhone(owner.phone)) {
          errors.phone = { error: true };
          firstError = `phone_${index}`;
        } else {
          errors.phone = { error: false };
        }

        if (this.isInvalidInsuranceNumber(owner.insuranceNumber)) {
          errors.insuranceNumber = { error: true };
          firstError = `insuranceNumber_${index}`;
        } else {
          errors.insuranceNumber = { error: false };
        }

        if (owner.documentType.value !== 'OTHER' && owner.documentType.value !== 'BIRTH_CERTIFICATE' &&
          validate.isEmptyString(owner.subdivisionCode)) {
          errors.subdivisionCode = { error: true };
          firstError = `subdivisionCode_${index}`;
        } else {
          errors.subdivisionCode = { error: false };
        }

        if (validate.isEmptyString(owner.issuer)) {
          errors.issuer = { error: true };
          firstError = `issuer_${index}`;
        } else {
          errors.issuer = { error: false };
        }

        if (!validate.dateInPast(owner.issueDate)) {
          errors.issueDate = { error: true };
          firstError = `issueDate_${index}`;
        } else {
          errors.issueDate = { error: false };
        }

        if (validate.isEmptyString(owner.passportNumber)) {
          errors.passportNumber = { error: true };
          firstError = `passportNumber_${index}`;
        } else {
          errors.passportNumber = { error: false };
        }

        if (validate.isEmptyString(owner.documentSeries)) {
          errors.documentSeries = { error: true };
          firstError = `documentSeries_${index}`;
        } else {
          errors.documentSeries = { error: false };
        }

        if (owner.documentType.value === 'OTHER' && validate.isEmptyString(owner.otherDocumentType)) {
          errors.otherDocumentType = { error: true };
          firstError = `otherDocumentType_${index}`;
        } else {
          errors.otherDocumentType = { error: false };
        }

        if (validate.isEmptyString(owner.documentType)) {
          errors.documentType = { error: true };
          firstError = `documentType_${index}`;
        } else {
          errors.documentType = { error: false };
        }

        if (validate.isEmptyString(owner.firstName)) {
          errors.firstName = { error: true };
          firstError = `firstName_${index}`;
        } else {
          errors.firstName = { error: false };
        }

        if (validate.isEmptyString(owner.familyName)) {
          errors.familyName = { error: true };
          firstError = `familyName_${index}`;
        } else {
          errors.familyName = { error: false };
        }

        errorsList.push(errors);
      });

      this.errors.additionalOwner = errorsList.reverse();

      return firstError;
    },
    validateConsumer(consumer) {
      const errors = {};
      let firstError = undefined;
      if (!this.isLegal) {
        if (validate.isEmptyString(consumer.address)) {
          errors.address = { error: true };
          firstError = 'address';
        } else {
          errors.address = { error: false };
        }
        if (validate.isEmptyString(consumer.registrationAddress)) {
          errors.registrationAddress = { error: true };
          firstError = 'registrationAddress';
        } else {
          errors.registrationAddress = { error: false };
        }
      }
      if (consumer.documentType.value !== 'OTHER' && consumer.documentType.value !== 'BIRTH_CERTIFICATE' &&
        (validate.isEmptyString(consumer.subdivisionCode) || !/^\d{1}\d{2}-\d{3}$/.test(consumer.subdivisionCode))) {
        errors.subdivisionCode = { error: true };
        firstError = 'subdivisionCode';
      } else {
        errors.subdivisionCode = { error: false };
      }

      if (validate.isEmptyString(consumer.issuer)) {
        errors.issuer = { error: true };
        firstError = 'issuer';
      } else {
        errors.issuer = { error: false };
      }

      if (!validate.dateInPast(consumer.issueDate)) {
        errors.issueDate = { error: true };
        firstError = 'issueDate';
      } else {
        errors.issueDate = { error: false };
      }

      if (validate.isEmptyString(consumer.passportNumber) || !/^\d{6}$/.test(consumer.passportNumber)) {
        errors.passportNumber = { error: true };
        firstError = 'passportNumber';
      } else {
        errors.passportNumber = { error: false };
      }

      if (validate.isEmptyString(consumer.documentSeries) || !/^\d{4}$/.test(consumer.documentSeries)) {
        errors.documentSeries = { error: true };
        firstError = 'documentSeries';
      } else {
        errors.documentSeries = { error: false };
      }

      if (consumer.documentType.value === 'OTHER' && validate.isEmptyString(consumer.otherDocumentType)) {
        errors.otherDocumentType = { error: true };
        firstError = 'otherDocumentType';
      } else {
        errors.otherDocumentType = { error: false };
      }

      if (validate.isEmptyObject(consumer.documentType)) {
        errors.documentType = { error: true };
        firstError = 'documentType';
      } else {
        errors.documentType = { error: false };
      }

      if (this.getUser.email !== consumer.email && validate.isEmail(consumer.email) &&
        consumer.emailCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
        errors.emailCode = { error: true };
        firstError = 'emailCode';
      } else {
        errors.emailCode = { error: false };
      }

      if (!validate.isEmail(consumer.email)) {
        errors.email = { error: true };
        firstError = 'email';
      } else {
        errors.email = { error: false };
      }

      if (this.getUser.additionalPhone !== formattingData.unformatPhonePrefix(consumer.additionalPhone) &&
        validate.isPhone(consumer.additionalPhone) && consumer.additionalPhoneCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
        errors.additionalPhoneCode = { error: true };
        firstError = 'additionalPhoneCode';
      } else {
        errors.additionalPhoneCode = { error: false };
      }

      if (this.getUser.phoneNumber !== formattingData.unformatPhonePrefix(consumer.phone) &&
        validate.isPhone(consumer.phone) && consumer.phoneCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
        errors.phoneCode = { error: true };
        firstError = 'phoneCode';
      } else {
        errors.phoneCode = { error: false };
      }

      if (!validate.isPhone(consumer.phone)) {
        errors.phone = { error: true };
        firstError = 'phone';
      } else {
        errors.phone = { error: false };
      }

      if (this.isInvalidInsuranceNumber(consumer.insuranceNumber)) {
        errors.insuranceNumber = { error: true };
        firstError = 'insuranceNumber';
      } else {
        errors.insuranceNumber = { error: false };
      }

      if (validate.isEmptyString(consumer.firstName)) {
        errors.firstName = { error: true };
        firstError = 'firstName';
      } else {
        errors.firstName = { error: false };
      }

      if (validate.isEmptyString(consumer.familyName)) {
        errors.familyName = { error: true };
        firstError = 'familyName';
      } else {
        errors.familyName = { error: false };
      }

      this.errors.consumer = errors;

      return firstError;
    },
    validObjectData() {
      let firstError = undefined, error = undefined;

      firstError = this.validateDocumentScans(this.connectionApplication.documentScans);

      error = this.validateMicrogenerator(this.connectionApplication.microgenerator);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.validateGridCompany(this.connectionApplication.gridCompany);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.validateConnectionCharacteristics(this.connectionApplication.connectionCharacteristics);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.validateMaximumPower(this.connectionApplication.connectionCharacteristics);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.validateConfirmationDocument(this.connectionApplication.confirmationDocument);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.validateObjectLocation(this.connectionApplication.objectLocation);

      if (error !== undefined) {
        firstError = error;
      }

      error = this.reasonToConnection(this.connectionApplication.reasonToConnection);

      if (error !== undefined) {
        firstError = error;
      }

      return firstError;
    },
    validateDocumentScans(documentScans) {
      const errors = {};
      let firstError = undefined;

      if (this.isAdditionalOwner && validate.isEmptyArray(documentScans.powerOfAttorneyScans)) {
        errors.powerOfAttorneyScans = { error: true };
        firstError = 'powerOfAttorneyScans';
      } else {
        errors.powerOfAttorneyScans = { error: false };
      }
      if (validate.isEmptyArray(documentScans.confirmationDocumentScan)) {
        errors.confirmationDocumentScan = { error: true };
        firstError = 'confirmationDocumentScan';
      } else {
        errors.confirmationDocumentScan = { error: false };
      }
      if (validate.isEmptyArray(documentScans.passportScans)) {
        errors.passportScans = { error: true };
        firstError = 'passportScans';
      } else {
        errors.passportScans = { error: false };
      }
      if (validate.isEmptyArray(documentScans.objectShemeScan)) {
        errors.objectShemeScan = { error: true };
        firstError = 'objectShemeScan';
      } else {
        errors.objectShemeScan = { error: false };
      }

      this.errors.documentScans = errors;

      return firstError;
    },
    validateMicrogenerator(microgenerator) {
      if (!this.onlyMicrogenerator) {
        return undefined;
      }

      let isError = microgenerator.items.some(
        (item) => validate.isEmptyString(item.MicrogeneratorLocation) ||
          validate.isEmptyString(item.MaxGeneratePower) ||
          validate.isEmptyString(item.PowerTransmittedToNetwork) ||
          validate.isEmptyString(item.Characteristics) ||
          validate.isEmptyArray(item.DocumentScans),
      );

      if (validate.isEmptyString(microgenerator.ReadyDate) || validate.dateInFuture(microgenerator.ReadyDate)) {
        isError = true;
        // TODO remove EVENT_BUS
        EVENT_BUS.$emit('VALIDATE', 'ReadyDate');
      }

      if (isError) {
        // TODO remove EVENT_BUS
        EVENT_BUS.$emit('VALIDATE', 'items');
        return 'items';
      }

      return undefined;
    },
    validateConnectionCharacteristics(connectionCharacteristics) {
      const errors = {};
      let firstError = undefined;

      const readyConnectionDate = [...connectionCharacteristics.readyConnectionDate];

      if (this.connectionApplication.reasonToConnection.value === 'POWER_INCREASE' &&
        validate.isEmptyString(connectionCharacteristics.retailAgreementNumber)) {
        errors.retailAgreementNumber = { error: true };
        firstError = 'retailAgreementNumber';
      } else {
        errors.retailAgreementNumber = { error: false };
      }

      if (this.connectionApplication.reasonToConnection.value === 'POWER_INCREASE' &&
        validate.isEmptyString(connectionCharacteristics.retailAgreementNumberDate)) {
        errors.retailAgreementNumberDate = { error: true };
        firstError = 'retailAgreementNumberDate';
      } else {
        errors.retailAgreementNumberDate = { error: false };
      }

      if (validate.isEmptyObject(connectionCharacteristics.tariffCategoryType)) {
        errors.tariffCategoryType = { error: true };
        firstError = 'tariffCategoryType';
      } else {
        errors.tariffCategoryType = { error: false };
      }

      if (validate.isEmptyObject(connectionCharacteristics.tariffZoneType) && connectionCharacteristics.tariffCategoryType?.value === 'TARIFF_CATEGORY_2') {
        errors.tariffZoneType = { error: true };
        firstError = 'tariffZoneType';
      } else {
        errors.tariffZoneType = { error: false };
      }

      if (this.isLegal && validate.isEmptyObject(connectionCharacteristics.workload)) {
        errors.workload = { error: true };
        firstError = 'workload';
      } else {
        errors.workload = { error: false };
      }

      errors.currentVoltage = [];
      errors.maxPowerConnection = [];
      connectionCharacteristics.maximumPowerVoltage.forEach((item, index) => {

        if (validate.isEmpty(item.currentVoltage.value)) {
          errors.currentVoltage[index] = { error: true };
          firstError = `currentVoltage${index}`;
        } else {
          errors.currentVoltage[index] = { error: false };
        }
        if (validate.isUndefined(item.maxPowerConnection) || validate.isEmpty(item.maxPowerConnection) || Number(item.maxPowerConnection) <= 0) {
          errors.maxPowerConnection[index] = { error: true };
          firstError = `maxPowerConnection${index}`;
        } else {
          errors.maxPowerConnection[index] = { error: false };
        }
      });
      const maxPowerValidation = (summaryMaxPower) => {
        switch (this.connectionApplication.type) {
          case 'INDIVIDUALS_UP_TO_15':
            return summaryMaxPower < 16;
          case 'LEGAL_ENTITIES_UP_TO_150':
            return summaryMaxPower < 151;
          case 'LEGAL_ENTITIES_OVER_150':
            return summaryMaxPower > 150;
          default:
            return true;
        }
      };

      if (!maxPowerValidation(connectionCharacteristics.maximumPowerVoltage.reduce((a, b) => a + Number(b.summaryMaxPower), 0))) {
        errors.summaryMaxPowerConnection = { error: true };
        firstError = 'summaryMaxPowerConnection';
      } else {
        errors.summaryMaxPowerConnection = { error: false };
      }

      if (validate.isEmpty(connectionCharacteristics.supplierOfLastResort?.value)) {
        errors.supplierOfLastResort = { error: true };
        firstError = 'supplierOfLastResort';
      } else {
        errors.supplierOfLastResort = { error: false };
      }

      if (validate.isEmpty(this.connectionApplication.connectionApplicationWithContractType?.value)) {
        errors.connectionApplicationWithContractType = { error: true };
        firstError = 'connectionApplicationWithContractType';
      } else {
        errors.connectionApplicationWithContractType = { error: false };
      }

      errors.maxPowerStage = false;

      if (readyConnectionDate.length > 1 || (readyConnectionDate.length === 1 && readyConnectionDate[0].maxPower.length > 0)) {
        // При поэтапном максимальная мощность должна совпадать с мощностью введённой последним этапом
        const maxReadyDateObj = maxBy(readyConnectionDate, (obj) => dayjs(obj.readyDate, 'YYYY-MM').unix());

        const maximumPowerVoltage = connectionCharacteristics.maximumPowerVoltage.reduce((a, b) => a + Number(b.summaryMaxPower), 0);

        if (maxReadyDateObj && maximumPowerVoltage && maxReadyDateObj?.maxPower !== String(maximumPowerVoltage)) {
          errors.maxPowerStage = true;
          firstError = 'maxPowerStage';
        }
      }

      readyConnectionDate.reverse().forEach((readyItem, index) => {
        if (validate.isEmptyString(readyItem.designDate)) {
          readyItem.designDateError = true;
          firstError = `designDate_${connectionCharacteristics.readyConnectionDate.length - 1 - index}`;
        } else {
          readyItem.designDateError = false;
        }
        if (validate.isEmptyString(readyItem.readyDate)) {
          readyItem.readyDateError = true;
          readyItem.readyDateErrorText = 'Поле обязательно для заполнения';
          firstError = `readyDate_${connectionCharacteristics.readyConnectionDate.length - 1 - index}`;
        } else if (!validate.isEmptyString(readyItem.designDate) &&
          dayjs(readyItem.readyDate, 'YYYY-MM').diff(dayjs(readyItem.designDate, 'YYYY-MM')) < 0) {
          readyItem.readyDateError = true;
          readyItem.readyDateErrorText = 'Не ранее планируемого срока проектирования';
          firstError = `readyDate_${connectionCharacteristics.readyConnectionDate.length - 1 - index}`;
        } else {
          readyItem.readyDateError = false;
        }
      });

      if ((this.connectionApplication.type !== 'INDIVIDUALS_UP_TO_15' && validate.isEmpty(connectionCharacteristics.energyConsumerCount.label)) &&
        this.connectionApplication.type !== 'LEGAL_ENTITIES_UP_TO_150') {
        errors.energyConsumerCount = { error: true };
        firstError = 'energyConsumerCount';
      } else {
        errors.energyConsumerCount = { error: false };
      }

      this.errors.connectionCharacteristics = errors;

      return firstError;
    },
    validateMaximumPower(connectionCharacteristics) {
      const errors = {};

      let firstError = undefined;

      errors.currentVoltage = [];
      errors.maxPowerConnection = [];
      connectionCharacteristics.maximumPowerVoltage.forEach((item, index) => {

        if (validate.isEmpty(item.currentVoltage.value)) {
          errors.currentVoltage[index] = { error: true };
          firstError = `currentVoltage${index}`;
        } else {
          errors.currentVoltage[index] = { error: false };
        }

        if (validate.isUndefined(item.maxPowerConnection) || validate.isEmpty(item.maxPowerConnection) || Number(item.maxPowerConnection) <= 0) {
          errors.maxPowerConnection[index] = { error: true };
          firstError = `maxPowerConnection${index}`;
        } else {
          errors.maxPowerConnection[index] = { error: false };
        }
      });
      const maxPowerValidation = (summaryMaxPower) => {
        switch (this.connectionApplication.type) {
          case 'INDIVIDUALS_UP_TO_15':
            return summaryMaxPower < 16;
          case 'LEGAL_ENTITIES_UP_TO_150':
            return summaryMaxPower < 151;
          case 'LEGAL_ENTITIES_OVER_150':
            return summaryMaxPower > 150;
          default:
            return true;
        }
      };

      if (!maxPowerValidation(connectionCharacteristics.maximumPowerVoltage.reduce((a, b) => a + Number(b.summaryMaxPower), 0))) {
        errors.summaryMaxPowerConnection = { error: true };
        firstError = 'summaryMaxPowerConnection';
      } else {
        errors.summaryMaxPowerConnection = { error: false };
      }

      this.errors.connectionCharacteristics = { ...this.errors.connectionCharacteristics, ...errors };

      return firstError;
    },
    validateConfirmationDocument(confirmationDocument) {
      const errors = {};
      let firstError = undefined;

      if (confirmationDocument.documentTitle.value !== 'Выписка из ЕГРН' && validate.isEmptyString(confirmationDocument.documentNumber)) {
        errors.documentNumber = { error: true };
        firstError = 'documentNumber';
      } else {
        errors.documentNumber = { error: false };
      }

      if (confirmationDocument.documentTitle.value !== 'Выписка из ЕГРН' && !validate.dateInPast(confirmationDocument.documentIssueDate)) {
        errors.documentIssueDate = { error: true };
        firstError = 'documentIssueDate';
      } else {
        errors.documentIssueDate = { error: false };
      }

      if (validate.isEmpty(confirmationDocument.documentTitle.value)) {
        errors.documentTitle = { error: true };
        firstError = 'documentTitle';
      } else {
        errors.documentTitle = { error: false };

        if (confirmationDocument.documentTitle.label === 'Иное основание...') {
          if (validate.isEmptyString(confirmationDocument.documentName)) {
            errors.documentName = { error: true };
            firstError = 'documentName';
          } else {
            errors.documentName = { error: false };
          }
        }
      }

      this.errors.confirmationDocument = errors;

      return firstError;
    },
    reasonToConnection(reasonToConnection) {
      let firstError = undefined;
      const errors = {};

      const showReasonError = ['INDIVIDUALS_UP_TO_15', 'INDIVIDUALS_OVER_15', 'LEGAL_ENTITIES_UP_TO_150', 'LEGAL_ENTITIES_OVER_150', 'MICROGENERATION'].includes(this.connectionApplication.type);

      if (validate.isEmptyString(reasonToConnection.value) && showReasonError) {
        firstError = 'reasonToConnection';
        errors.reasonToConnection = { error: true };
      } else {
        errors.reasonToConnection = { error: false };
      }
      this.errors.reasonToConnection = errors;

      return firstError;
    },
    validateObjectLocation(objectLocation) {
      let firstError = undefined;
      const errors = {};

      if (!validate.isEmptyString(objectLocation.landCadastralNumber) && this.isInvalidCadastralNumber(objectLocation.landCadastralNumber)) {
        errors.landCadastralNumber = { error: true };
        firstError = 'landCadastralNumber';
      } else {
        errors.landCadastralNumber = { error: false };
      }

      if (!validate.isEmptyString(objectLocation.buildingCadastralNumber) && this.isInvalidCadastralNumber(objectLocation.buildingCadastralNumber)) {
        errors.buildingCadastralNumber = { error: true };
        firstError = 'buildingCadastralNumber';
      } else {
        errors.buildingCadastralNumber = { error: false };
      }

      if (validate.isEmptyString(objectLocation.objectAddress)) {
        firstError = 'objectAddress';
        errors.objectAddress = { error: true };
      } else {
        errors.objectAddress = { error: false };
      }

      if (validate.isEmptyObject(objectLocation.objectType)) {
        errors.objectType = { error: true };
        firstError = 'objectType';
      } else {
        errors.objectType = { error: false };
      }

      if (objectLocation.objectType.value === 'Нежилое помещение' && validate.isEmptyString(objectLocation.objectArea)) {
        errors.objectArea = { error: true };
        firstError = 'objectArea';
      } else {
        errors.objectArea = { error: false };
      }

      this.errors.objectLocation = errors;

      return firstError;
    },
    validateGridCompany(gridCompany) {
      let firstError = undefined;
      const errors = {};

      if (validate.isEmptyObject(gridCompany.gridCompanyName)) {
        errors.gridCompanyName = { error: true };
        firstError = 'gridCompanyName';
      } else {
        errors.gridCompanyName = { error: false };
      }

      if (gridCompany.gridCompanyName.label === 'Иное' && validate.isEmptyString(gridCompany.otherGridCompany)) {
        errors.otherGridCompany = { error: true };
        firstError = 'otherGridCompany';
      } else {
        errors.otherGridCompany = { error: false };
      }

      this.errors.gridCompany = errors;

      return firstError;
    },
    changeType(type) {
      this.connectionApplication.type = type;
      this.onlyMicrogenerator = type.value === 'MICROGENERATION';
    },
    isInvalidInsuranceNumber(value) {
      if (validate.isEmptyString(value)) {
        return true;
      }

      const clearedValue = value.replace(/\D/g, '');
      return clearedValue.length !== 11;
    },

    isInvalidCadastralNumber(value) {
      return !validate.landCadastralNumber(value);
    },
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2
  padding-top: 32px
  padding-bottom: 32px

  .title
    +questionnaries-container
    +step-spacing

    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 32px
    line-height: 36px
    color: #000

    .subTitle
      margin: 16px 0 0 0
      font-family: Roboto
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 20px
      color: #71757a

.contentFooter
  height: 48px
  display: flex

.back, .next, .delete
  +button-text
  color: #FFFFFF
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  background-color: #2F82DF
  padding: 18px

  svg
    fill: #FFFFFF

.center
  flex-grow: 2

.delete
  background-color: red
  margin-left: 16px

.back
  margin-left: 16px

  span
    margin-left: 13px

.next
  margin-right: 36px

  span
    margin-right: 13px

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 64px
  width: 300px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF

.submitBlock
  display: flex
  margin-top: 64px

  div
    width: 50%

.deleteText
  +base-title
  margin-top: 64px
  margin-bottom: 16px
  width: 100%
  text-align: center
</style>
