<template>
  <div>
    <select-button :items="value" is-select />
  </div>
</template>

<script>
import SelectButton from 'components/SelectButton/SelectButton.vue';

export default {
  name: 'VTableCellSelectButton',
  components: { SelectButton },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>

</style>
