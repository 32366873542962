import { render, staticRenderFns } from "./VTooltip.vue?vue&type=template&id=2c267e77&scoped=true"
import script from "./VTooltip.vue?vue&type=script&lang=js"
export * from "./VTooltip.vue?vue&type=script&lang=js"
import style0 from "./VTooltip.vue?vue&type=style&index=0&id=2c267e77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c267e77",
  null
  
)

export default component.exports