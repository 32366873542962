const energyConsumerCountList = (state) => state.energyConsumerCountList;
const objectTypeList = (state) => state.objectTypeList;
const phaseVoltageList = (state) => state.phaseVoltageList;
const reliabilityClassList = (state) => state.reliabilityClassList;
const reliabilityClassShortList = (state) => state.reliabilityClassShortList;
const workloadList = (state) => state.workloadList;
const reliabilityClassMiddleList = (state) => state.reliabilityClassMiddleList;
const supplierOfLastResortList = (state) => state.supplierOfLastResortList;
const gridCompanyList = (state) => state.gridCompanyList;
const currentUtilityCompany = (state) => state.currentUtilityCompany;
const confirmationDocumentTitleList = (state) => state.confirmationDocumentTitleList;
const voltageLevelList = (state) => state.voltageLevelList;

const typeAccountingList = (state) => state.typeAccountingList;
const typeAccountingLabelList = (state) => state.typeAccountingLabelList;
const typeAccountingListLong = (state) => state.typeAccountingListLong;
const ownerAccountingFacilitiesList = (state) => state.ownerAccountingFacilitiesList;
const voltageClassList = (state) => state.voltageClassList;
const voltageClassListRu = (state) => state.voltageClassListRu;
const subscriberStateList = (state) => state.subscriberStateList;
const meterStateList = (state) => state.meterStateList;
const appliedTariffList = (state) => state.appliedTariffList;
const pointsKindList = (state) => state.pointsKindList;
const measurementsReadingKindList = (state) => state.measurementsReadingKindList;
const tariffCategoryTypeList = (state) => state.tariffCategoryTypeList;
const tariffCategoryTypeListNumber = (state) => state.tariffCategoryTypeListNumber;
const tariffCategoryTypeShortList = (state) => state.tariffCategoryTypeShortList;

const consumerCategoryList = (state) => state.consumerCategoryList;
const tariffZoneTypeList = (state) => state.tariffZoneTypeList;
const accountingMethodList = (state) => state.accountingMethodList;
const relatedOrganizationList = (state) => state.relatedOrganizationList;
const documentTypeList = (state) => state.documentTypeList;
const pointsKindFullList = (state) => state.pointsKindFullList;
const measurementsReadingKindFullList = (state) => state.measurementsReadingKindFullList;
const connectionApplicationTypeList = (state) => state.connectionApplicationTypeList;
const accountKindList = (state) => state.accountKindList;
const paymentTariffsList = (state) => state.paymentTariffsList;
const meterTariffTypeList = (state) => state.meterTariffTypeList;
const paymentKindList = (state) => state.paymentKindList;
const sealPlaceList = (state) => state.sealPlaceList;
const fixtureStageList = (state) => state.fixtureStageList;
const connectionApplicationWithContractTypeList = (state) => state.connectionApplicationWithContractTypeList;
const reasonToConnectionList = (state) => state.reasonToConnectionList;
const odkDefectStatus = (state) => state.odkDefectStatus;
const shiftList = (state) => state.shiftList;
const regionList = (state) => state.regionList;
const reliabilityClassListWithShortenedNames = (state) => state.reliabilityClassListWithShortenedNames;
const typeAccountingMethodList = (state) => state.typeAccountingMethodList;
const substationKindList = (state) => state.substationKindList;
const tariffOrderList = (state) => state.tariffOrderList;
const chatFaqMessages = (state) => state.chatFaqMessages;

export default {
  energyConsumerCountList,
  objectTypeList,
  phaseVoltageList,
  reliabilityClassList,
  reliabilityClassShortList,
  reliabilityClassMiddleList,
  workloadList,
  supplierOfLastResortList,
  gridCompanyList,
  confirmationDocumentTitleList,
  voltageLevelList,
  subscriberStateList,
  meterStateList,
  appliedTariffList,
  pointsKindList,
  currentUtilityCompany,

  typeAccountingList,
  typeAccountingLabelList,
  ownerAccountingFacilitiesList,
  voltageClassList,
  voltageClassListRu,
  measurementsReadingKindList,
  tariffCategoryTypeList,
  tariffCategoryTypeListNumber,
  tariffCategoryTypeShortList,
  consumerCategoryList,
  tariffZoneTypeList,
  accountingMethodList,
  relatedOrganizationList,
  typeAccountingListLong,
  documentTypeList,
  pointsKindFullList,
  measurementsReadingKindFullList,
  connectionApplicationTypeList,
  accountKindList,
  typeAccountingMethodList,
  paymentTariffsList,
  paymentKindList,
  meterTariffTypeList,
  sealPlaceList,
  fixtureStageList,
  reasonToConnectionList,
  connectionApplicationWithContractTypeList,
  reliabilityClassListWithShortenedNames,

  odkDefectStatus,
  shiftList,
  substationKindList,
  regionList,
  tariffOrderList,
  chatFaqMessages,
};
