<template>
  <modal v-if="isVisibleSimpleSignatureModal" :header-style="{padding: '16px 16px 16px 24px'}"
         class="simple-signature-modal" @close="closeHandler">
    <template #header>
      <h1 class="simple-signature-modal__header">Введите одноразовый пароль для подтверждения подписания документа</h1>
    </template>
    <template #body>
      <one-time-password-input :is-error="isError" :is-waiting="isWaiting" @code-input="codeEntered"
                               @code-request="requestCode"/>
      <div
        v-if="isError"
        class="simple-signature-modal__error-text"
        role="errorText"
      >
        {{ errorMessage }}
      </div>
    </template>
    <template #footer>
      <v-button
        :class="{['simple-signature-modal__button_disabled']: isButtonDisabled}"
        :disabled="isButtonDisabled"
        class="simple-signature-modal__button"
        variant="filled--no-radius"
        @click="signDocument"
      >
        Подписать документ
      </v-button>
      <horizontal-progress-bar v-if="isWaiting"/>
    </template>
  </modal>
</template>

<script>
import VButton from '@/components/VButton/VButton';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { mapActions } from 'vuex';
import Modal from 'components/Modal/Modal';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput';

export default {
  name: 'SimpleSignatureModal',
  components: {
    OneTimePasswordInput,
    Modal,
    HorizontalProgressBar,
    VButton,
  },
  props: {
    isVisibleSimpleSignatureModal: {
      type: Boolean,
      default: false,
    },
    xmlDocument: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
      isWaiting: false,
      isError: false,
      errorMessage: '',
    };
  },
  computed: {
    isButtonDisabled() {
      return !this.isCodeCompleted || this.isWaiting;
    },
    isCodeCompleted() {
      return this.code.length === 4;
    },
  },
  methods: {
    closeHandler() {
      this.clearError();
      this.$emit('close');
    },
    codeEntered(code) {
      this.code = code;
    },
    async requestCode() {
      this.isWaiting = true;
      console.log('Code requested');
      try {
        await this.requestSignatureCode();
      } catch (e) {
        this.processSubmittingError(e);
      } finally {
        this.isWaiting = false;
      }
    },
    processSubmittingError(error) {
      this.isError = true;
      this.errorMessage = error.message.split(': ')[2];
    },
    clearError() {
      this.isError = false;
      this.errorMessage = '';
    },
    async signDocument() {
      this.isWaiting = true;
      try {
        const signedXml = await this.signDocumentWithSimpleSignature({ xml: this.xmlDocument, code: this.code });
        this.$emit('document-signed', signedXml);
        this.clearError();
      } catch (e) {
        this.processSubmittingError(e);
      } finally {
        this.isWaiting = false;
      }
    },
    ...mapActions('document', ['requestSignatureCode', 'signDocumentWithSimpleSignature']),
  },
};
</script>

<style lang="scss" scoped>
.simple-signature-modal {
  &__header {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: #000;
  }

  &__button {
    width: 100%;
    max-width: 100%;
    padding: 16px;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 21px;
    border-radius: 0;
    line-height: 24px;
    height: 64px;
    background-color: #2F82DF;
    color: #FFF;
    text-transform: uppercase;

    &_disabled {
      background-color: #71757A;
    }
  }

  &__error-text {
    margin-top: 12px;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    line-height: 18px;
    color: #EB5757;
  }
}
</style>
