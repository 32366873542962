<template>
  <div style="display: flex; flex-direction: column; gap: 28px; width: 100%">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div>
        <img v-if="header.sort && sortState && sortState.key === header.key && sortState.direction === 1"
             alt="сортировать" class="sort-icon sort-icon--active sort-icon--direction"
             src="@/assets/icons/basic/sort-direction.svg" @click="callSortColumn" />

        <img v-else-if="header.sort && sortState && sortState.key === header.key && sortState.direction !== 1"
             alt="сортировать" class="sort-icon sort-icon--active"
             src="@/assets/icons/basic/sort-active.svg"
             @click="callSortColumn" />

        <img v-else-if="header.sort" alt="сортировать" class="sort-icon"
             src="@/assets/icons/basic/sort.svg" @click="callSortColumn" />
        <span>{{ header.label }}</span>
      </div>
      <edit-mode-button title="Выбрать всё" select-all-border-gray :is-true="selectAllIsTrue" @click="selectAll" />
    </div>
    <v-input
      v-model="filterValues"
      search-style
      clearable
      @input="(val) => $emit('filter', { field: header, value: val})"
    />
  </div>
</template>

<script>
import EditModeButton from '../../../../EditModeButton/EditModeButton.vue';
import VInput from '../../../../VInput/VInput.vue';

export default {
  name: 'VTableThFilterAndCheckbox',
  components: { EditModeButton, VInput },
  props: {
    header: {
      type: Object,
      required: true,
    },
    sortState: {
      type: Object,
      required: false,
    },
    selectAllIsTrue: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      filterValues: '',
    };
  },
  methods: {
    callSortColumn() {
      this.$emit('sort', this.header);
    },
    selectAll(check) {
      this.$emit('select-all', check);
    },
  },
};
</script>

<style scoped lang="scss">
.sort-icon {
  margin-top: 2px;
  margin-right: 8px;
  cursor: pointer;

  &--direction {
    transform: scale(1, -1);
  }
}
</style>
