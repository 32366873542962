import { checkSuccessfullyPdfOpen } from 'utils/notification/notification';
import unicodeToWin1251 from 'lib/utils/windows-1251.js';

export function showPdf(pdf) {
  const pdfView = URL.createObjectURL(pdf);
  checkSuccessfullyPdfOpen(pdfView);
}

export function downloadPdf(pdf, name) {
  const url = URL.createObjectURL(pdf);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.pdf`);
  document.body.appendChild(link);
  link.click();
}

export function downloadXml(xml, name) {
  const blob = new Blob([xml], { type: 'application/xml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.xml`);
  document.body.appendChild(link);
  link.click();
}

export function downloadXmlWin1251(xml, name) {
  const blob = new Blob([unicodeToWin1251(xml)], { type: 'application/xml;charset=windows-1251' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.xml`);
  document.body.appendChild(link);
  link.click();
}


export function b64toBlob(b64Data, contentType) {
  const sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


export function downloadSiganture(signature, name) {
  console.log('sig', signature);
  const blob = b64toBlob(signature, 'application/octet-stream');
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.sig`);
  document.body.appendChild(link);
  link.click();
}
