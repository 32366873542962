<template>
  <a :href="href" :rel="relation" :target="target" :download="name" @click="$emit('click')">
    <slot />
  </a>
</template>

<script>
import { inArray } from 'lib/component/validators';

export default {
  name: 'CommonLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    relation: {
      type: String,
      default: () => 'noreferrer noopener',
    },
    target: {
      type: String,
      validator: inArray(['_blank', '_self', '_parent', '_top']),
      default: () => '_blank',
    },
    name: {
      type: String,
      default: () => '',
    },
  },
};
</script>
