export function generateRandomString() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function escape(string) {
  const element = document.createElement('span');
  const text = document.createTextNode(string);
  element.appendChild(text);

  return element.innerHTML;
}

export function escapeXml(string) {
  return string.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');

}

export function unescape(string) {
  const element = document.createElement('span');
  element.innerHTML = string;

  return element.innerText;
}

export function numberConvert(value) {
  return value.replace(',', '.');
}
