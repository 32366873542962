<template>
  <page title="Справочник индивидуальных тарифов на услуги по передаче электрической энергии для взаиморасчётов между сетевыми организациями">
    <v-row v-if="getUserType === 'Platform'" :class="$style.button">
      <v-col :grid="{ xs: 3 }">
        <v-button @click="upload">
          Загрузить приказ по тарифам
        </v-button>
      </v-col>
    </v-row>
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />

    <v-table-new
      v-model="transformedUtilityIndividualTariffs"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник индивидуальных тарифов на услуги по передаче электрической энергии для взаиморасчётов между сетевыми организациями"
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ModalProgressBar, Page, VButton, VCol, VRow, VTableNew } from '@/components';
import { formatPeriod } from 'lib/utils/date';

const initialFilters = {
  year: '',
  inn: '',
};

export default {
  name: 'UtilityIndividualTariff',
  components: {
    VTableNew,
    VButton,
    VRow,
    VCol,
    ModalProgressBar,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      utilityIndividualTariffs: [],
      loading: false,
      displayDeleteDialog: false,
      deleteItem: {},
      isWaiting: false,
      submitError: '',
      isModalVisible: false,
      isProcessComplete: false,
      filters: this.initialFilters(),
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование смежной сетевой организации',
            key: 'utility.name',
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'ИНН',
            key: 'utility.inn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Дата начала действия тарифа',
            key: 'startDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Дата конца действия тарифа',
            key: 'endDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Ставка на содержание электрических сетей, (руб / (МВт * мес))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForTheMaintenanceOfElectricalNetworks_NoZero',
          },
          {
            label: 'Ставка на оплату технологического расхода, (руб / (МВт * ч))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForPaymentOfTechnologicalLosses_NoZero',
          },
          {
            label: 'Одноставочный тариф, (руб / (кВт * ч))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'singleRateElectricityTariff_NoZero',
          },
          {
            label: 'Реквизиты приказа. Год',
            key: 'tariffsOrder.year',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: { type: 'date', format: 'YYYY', selectType: 'year' },
          },
          {
            label: 'Реквизиты приказа. Дата составления документа',
            key: 'tariffsOrder.docDate',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Номер документа',
            key: 'tariffsOrder.orderNumber',
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Название приказа',
            key: 'tariffsOrder.orderName',
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Кто издал приказ',
            key: 'tariffsOrder.orderPublisher',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    transformedUtilityIndividualTariffs() {
      if (!this.utilityIndividualTariffs || this.utilityIndividualTariffs.length === 0) {
        return [];
      }

      return this.utilityIndividualTariffs.map((it) => ({
        ...it,
        rateForTheMaintenanceOfElectricalNetworks_NoZero: parseFloat(it.rateForTheMaintenanceOfElectricalNetworks)?.toString(),
        rateForPaymentOfTechnologicalLosses_NoZero: parseFloat(it.rateForPaymentOfTechnologicalLosses)?.toString(),
        singleRateElectricityTariff_NoZero: parseFloat(it.singleRateElectricityTariff)?.toString(),
        documentId: it.tariffsOrder?.documentId,
        document: { documentId: it.tariffsOrder?.documentId, name: 'Справочник индивидуальных тарифов' },
      })).filter((item) => (!this.filters.year || item.tariffsOrder.year === this.filters.year) &&
        (!this.filters.inn || (item.utility?.inn && item.utility.inn.indexOf(this.filters.inn) !== -1)));
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.utilityIndividualTariffs = await this.loadUtilityIndividualTariff();
      } catch (error) {
        console.log('loadUtilityIndividualTariff', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    upload() {
      this.$router.push('/cabinet/individual-tariffs/upload');
    },
    formatPeriod(month, year) {
      return formatPeriod(month, year);
    },
    initialFilters() {
      return this.getTableFilterByName()(this.$route.name, initialFilters);
    },
    ...mapActions('document', ['loadUtilityIndividualTariff']),
    ...mapActions('user', ['setNotification']),
    ...mapGetters('user', ['getTableFilterByName']),
  },
};
</script>

<style lang="sass" module>
@import "src/assets/sass/components/tables"

.filter
  margin: 24px 0

</style>
