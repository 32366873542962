<template>
  <div :class="$style.container">
    <div>
      <div :class="$style.statusGroup">
        <p :class="$style.title">
          Отметьте статус заявки
        </p>
        <div :class="$style.radioGroup" style="margin-left: 0">
          <label :class="$style.labelRadio">
            <input
              :checked="applicationStatus === 1"
              :class="$style.control"
              name="applicationStatus"
              role="applicationStatus.completed"
              type="radio"
              @click="applicationStatusClick(1)"

            />
            <div :class="$style.contentRadioGroup">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupCheck"></div>
                <h3 :class="$style.titleRadioGroup">Выполнена</h3>
              </div>
            </div>
          </label>
          <label :class="$style.labelRadio">
            <input
              :checked="applicationStatus === 2"
              :class="$style.control"
              name="applicationStatus"
              role="applicationStatus.cancelled"
              type="radio"
              @click="applicationStatusClick(2)"
            />
            <div :class="$style.contentRadioGroup" style="margin-top: -16px">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupCheck"></div>
                <h3 :class="$style.titleRadioGroup">Аннулирована</h3>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div v-if="applicationStatus === 2" :class="$style.correctionGroup">
        <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

        <div :class="$style.blockContent">
          <p>Укажите причину аннулирования заявки *</p>
          <c-textarea
            v-model="cancelledComments"
            role="canceledComments"
          />
        </div>

        <div :class="$style.blockContent">
          <p :class="$style.subtitle">
            Приложите необходимые документы
          </p>

          <div>
            <div v-if="cancelledDocs.length">
              <file-list-preview
                :list="cancelledDocs"
                action
                @delete="handleRemoveCancelledDocs"
              />
            </div>

            <c-uploading
              v-model="cancelledDocs"
              role="cancelledDocs"
            />
          </div>
        </div>

      </div>

      <div v-if="applicationStatus === 1" :class="$style.correctionGroup">
        <div>
          <p :class="$style.titleAct">Акт об осуществлении технологического присоединения</p>
          <div>
            <p :class="$style.titleNumber">Номер документа (DID): <span>{{ ConnectionCertificate.Number }}</span></p>
          </div>

          <div>
            <p :class="$style.titleField">Наименование сетевой организации:
              <span>{{ ConnectionCertificate.GridCompanyName }}</span></p>
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="ConnectionCertificate.GridCompanyEmployee"
              label="Представитель сетевой организации"
              role="GridCompanyEmployee"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="ConnectionCertificate.GridCompanyDocument"
              label="Устав, доверенность, иной документ представителя сетевой организации"
              role="GridCompanyDocument"
            />
          </div>

          <div>
            <p :class="$style.titleField">Фамилия, имя, отчество или наименование потребителя или его представителя:
              <span>{{ ConnectionCertificate.Customer }}</span></p>
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="ConnectionCertificate.CustomerRepresentative"
              label="Ф.И.О. лица – представителя заявителя"
              role="CustomerRepresentative"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="ConnectionCertificate.CustomerDocument"
              label="Устав, доверенность, иной документ заявителя"
              role="CustomerDocument"
            />
          </div>

          <p :class="$style.titleBlock">1. Договор об осуществлении технологического присоединения</p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="ConnectionCertificate.ConnectionAgreement.Number"
              label="Номер"
              role="ConnectionAgreement.Number"
            />
            <c-date-picker
              v-model="ConnectionCertificate.ConnectionAgreement.Date"
              label="Дата"
              role="ConnectionAgreement.Date"
            />
          </div>

          <div>
            <p :class="$style.titleField">Сумма: <span>{{
                ConnectionCertificate.ConnectionAgreement.AmountFormatting
              }}</span></p>
          </div>

          <p :class="$style.titleBlock">Мероприятия по технологическому присоединению выполнены в соответствии с
            техническими условиями</p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="ConnectionCertificate.TechnicalSpecification.Number"
              label="Номер"
              role="TechnicalSpecification.Number"
            />
            <c-date-picker
              v-model="ConnectionCertificate.TechnicalSpecification.Date"
              label="Дата"
              role="TechnicalSpecification.Date"
            />
          </div>

          <p :class="$style.titleBlock">Объекты электроэнергетики (энергопринимающие устройства)</p>
          <div>
            <p :class="$style.titleField">Адрес: <span>{{ ConnectionCertificate.ConnectionAddress }}</span></p>
          </div>

          <p :class="$style.titleBlock">Акт о выполнении технических условий</p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="ConnectionCertificate.TechnicalCertificate.Number"
              label="Номер"
              role="TechnicalCertificate.Number"
            />
            <c-date-picker
              v-model="ConnectionCertificate.TechnicalCertificate.Date"
              label="Дата"
              role="TechnicalCertificate.Date"
            />
          </div>

          <p :class="$style.titleBlock">Характеристики присоединения</p>

          <p :class="$style.titleField">Максимальная мощность:
            <span>{{ ConnectionCertificate.ConnectionCharacteristics.NewMaxPower }} кВт</span></p>
          <p :class="$style.titleField">Категория надежности:
            <span>III</span></p>

          <p v-if="onlyMicrogenerator" :class="$style.titleField">Максимальная мощность объектов микрогенерации (кВт)
            (всего): <span>{{ ConnectionCertificate.ConnectionCharacteristics.MicrogenerationMaxPower }}</span></p>

          <p :class="$style.titleBlock">2. Перечень точек присоединения</p>

          <div v-for="(point, index) in ConnectionCertificate.ConnectionPoints.ConnectionPoint"
               :key="'ConnectionPoint_' + index" :class="$style.addBlock">
            <p :class="$style.titleField">{{ index + 1 }}.
              <c-input
                v-model="point.Number"
                :role="'ConnectionPoints[' + index + '].Number'"
                label="Номер"
              />
            </p>

            <div :class="$style.grid2Column">
              <c-input
                v-model="point.PowerSupplyCenter"
                :role="'ConnectionPoints[' + index + '].PowerSupplyCenter'"
                label="Источник питания"
              />
              <c-input
                v-model="point.Description"
                :role="'ConnectionPoints[' + index + '].Description'"
                label="Описание точки присоединения"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-input
                v-model="point.VoltageLevel"
                :role="'ConnectionPoints[' + index + '].VoltageLevel'"
                label="Уровень напряжения (В)"
              />
              <c-input
                v-model="point.MaxPower"
                :role="'ConnectionPoints[' + index + '].MaxPower'"
                label="Максимальная мощность (кВт)"
              />
            </div>

            <div :class="$style.grid2Column">
              <c-input
                v-model="point.MicrogenerationMaxPower"
                :role="'ConnectionPoints[' + index + '].MicrogenerationMaxPower'"
                label="Максимальная мощность объектов микрогенерации   (кВт)"
              />
              <c-input
                v-model="point.TotalTransformerPowerRating"
                :role="'ConnectionPoints[' + index + '].TotalTransformerPowerRating'"
                label="Величина номинальной мощности присоединенных трансформаторов (кВА)"
              />
            </div>

            <div :class="$style.gridFluid">
              <c-input
                v-model="point.ReactivePowerFactor"
                :role="'ConnectionPoints[' + index + '].ReactivePowerFactor'"
                label="Предельное значение коэффициента реактивной мощности (tg φ)"
              />
            </div>
          </div>

          <div :class="$style.gridFluid" style="display: none">
            <button :class="$style.addAction" role="ConnectionPoints.add" @click="addConnectionPoint">+ ещё точка
              присоединения
            </button>
          </div>

          <p :class="$style.titleBlock">Границы балансовой принадлежности объектов электроэнергетики
            (энергопринимающих устройств) и эксплуатационной ответственности сторон:</p>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.BalanceAffiliationBoundary"
              label="Описание границ балансовой принадлежности объектов электроэнергетики (энергопринимающих устройств)"
              role="BalanceAffiliationBoundary"
            />
          </div>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.OperationalResponsibilityBoundary"
              label="Описание границ эксплуатационной ответственности сторон"
              role="OperationalResponsibilityBoundary"
            />
          </div>

          <p :class="$style.titleBlock">3. У сторон на границе балансовой принадлежности объектов
            электроэнергетики (энергопринимающих устройств) находятся следующие
            технологически соединенные элементы электрической сети:</p>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.GridCompanyEquipment"
              label="Наименование электроустановки (оборудования) сетевой организации"
              role="GridCompanyEquipment"
            />
          </div>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.CustomerEquipment"
              label="Наименование электроустановки (оборудования) заявителя"
              role="CustomerEquipment"
            />
          </div>

          <p :class="$style.titleBlock">У сторон в эксплуатационной ответственности находятся следующие
            технологически соединенные элементы электрической сети:</p>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.GridCompanyResponsibleEquipment"
              label="Наименование электроустановки (оборудования), находящейся в эксплуатации сетевой организации"
              role="GridCompanyResponsibleEquipment"
            />
          </div>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.CustomerResponsibleEquipment"
              label="Наименование электроустановки (оборудования), находящейся в эксплуатации заявителя"
              role="CustomerResponsibleEquipment"
            />
          </div>

          <p :class="$style.titleBlock">4. Устройства защиты, релейной защиты, противоаварийной и режимной
            автоматики:</p>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.ProtectiveDevice"
              role="ProtectiveDevice"
            />
          </div>

          <p :class="$style.titleBlock">5. Автономный резервный источник питания (установки, тип, мощность и др.):</p>

          <div :class="$style.gridFluid">
            <c-input
              v-model="ConnectionCertificate.OffGridPowerSupply"
              role="OffGridPowerSupply"
            />
          </div>

          <p :class="$style.titleBlock">
            6. Прочие сведения (в том числе сведения об опосредованно присоединенных потребителях, наименование, адрес,
            максимальная мощность, категория надежности, уровень напряжения, сведения о расчетах потерь электрической
            энергии в электрической сети потребителя электрической энергии и др.)
          </p>
          <c-textarea
            v-model="ConnectionCertificate.SpecialNotes"
            role="SpecialNotes"
          />

          <p :class="$style.titleBlock">
            7. Схематично границы балансовой принадлежности объектов электроэнергетики (энергопринимающих устройств) и
            эксплуатационной ответственности сторон указаны в приведенной ниже однолинейной схеме присоединения
            энергопринимающих устройств
          </p>

          <div :class="$style.blockContent">
            <p :class="$style.subtitle">
              Приложите скан схемы
            </p>

            <div>
              <div v-if="ConnectionCertificate.BoundaryScheme.length">
                <file-list-preview
                  :list="ConnectionCertificate.BoundaryScheme"
                  action
                  @delete="handleRemoveBoundaryScheme"
                />
              </div>

              <c-uploading
                v-model="ConnectionCertificate.BoundaryScheme"
                role="BoundaryScheme"
              />
            </div>
          </div>

          <p :class="$style.titleBlock">
            8. Прочее
          </p>
          <c-textarea
            v-model="ConnectionCertificate.Comments"
            role="Comments"
          />

          <p :class="$style.titleBlock">
            8. Подписывающие лица
          </p>
          <div v-for="(Signer, index) in ConnectionCertificate.Signers.Signer"
               :key="'ConnectionCertificate.Signer_' + index" :class="$style.addBlock">
            <div :class="$style.gridFluid">
              <c-input
                v-model="Signer.Name"
                :label="index + 1 + '. Наименование'"
                :role="'Signers[' + index + '].Name'"
              />
            </div>

            <div :class="$style.grid2Column">
              <c-input
                v-model="Signer.FamilyName"
                :role="'Signers[' + index + '].familyName'"
                label="Фамилия"
              />
              <c-input
                v-model="Signer.FirstName"
                :role="'Signers[' + index + '].firstName'"
                label="Имя"
              />
            </div>

            <div :class="$style.gridFluid">
              <c-input
                v-model="Signer.Patronymic"
                :role="'Signers[' + index + '].patronymic'"
                label="Отчество"
              />
            </div>
          </div>

          <div :class="$style.gridFluid">
            <button :class="$style.addAction" role="ConnectionCertificate.Signer.add" @click="addSigner">+ ещё
              подписывающее лицо
            </button>
          </div>

        </div>
        <div>
          <p :class="$style.titleAct">Акт допуска в эксплуатацию прибора учета электрической энергии</p>
          <div>
            <p :class="$style.titleNumber">Номер документа (DID):
              <span>{{ AdmissionCertificate.AdmissionCertificateNumber }}</span></p>
          </div>

          <div>
            <p :class="$style.titleField">Наименование сетевой организации:
              <span>{{ AdmissionCertificate.GridCompanyName }}</span></p>
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.GridCompanyEmployee"
              label="Представитель сетевой организации"
              role="AdmissionCertificate.GridCompanyEmployee"
            />
          </div>

          <div>
            <p :class="$style.titleField">Фамилия, имя, отчество или наименование потребителя или его представителя:
              <span>{{ AdmissionCertificate.Customer }}</span></p>
          </div>

          <div>
            <p :class="$style.titleField">Наименование субъекта розничного рынка, с которым у заявителя заключен договор
              энергоснабжения: <span>{{ AdmissionCertificate.RetailCompanyName }}</span></p>
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.RetailCompanyEmployee"
              label="Фамилия, имя, отчество, должность представителя субъекта розничного рынка, с которым у заявителя заключен договор энергоснабжения"
              role="RetailCompanyEmployee"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="ContractConsumerMes.ContractNumber"
              :error="contractNumberError"
              :validation="$validate.isNotEmptyString"
              label="Номер договора энергоснабжения*"
              role="ContractConsumerMes.ContractNumber"
            />
          </div>

          <div :class="$style.grid2Column">
            <div>
              <p :class="$style.textHelper">
                <span>Тариф</span>
              </p>
              <c-select
                v-model="AdmissionCertificate.TariffCategory"
                :options="tariffCategoryList"
                role="TariffCategory"
              />
            </div>
          </div>

          <div :class="$style.blockContent">
            <c-date-picker
              v-model="AdmissionCertificate.VerificationDate"
              label="Дата и время проведения проверки"
              role="VerificationDate"
            />
          </div>

          <p :class="$style.titleBlock">
            1. Сведения о точке поставки
          </p>

          <div>
            <p :class="$style.titleField">Адрес: <span>{{ AdmissionCertificate.UsagePoint.Address }}</span></p>
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.Name"
              :error="usagePointName"
              :validation="$validate.isNotEmptyString"
              label="Наименование*"
              role="AdmissionCertificate.UsagePoint.Name"
            />
          </div>

          <div :class="$style.blockContent">

            <c-input
              v-model="AdmissionCertificate.UsagePoint.MainSubstation"
              :error="mainSubstationError"
              :validation="$validate.isNotEmptyString"
              label="Центр питания (наименование, уровень напряжения, номер)"
              role="AdmissionCertificate.UsagePoint.MainSubstation"
            />

          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.Feeder10"
              label="Фидер 10 (6) кВ (наименование, номер)"
              role="AdmissionCertificate.UsagePoint.Feeder10"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.TransformerSubstation"
              label="ТП (КТП) (наименование, номер)"
              role="AdmissionCertificate.UsagePoint.TransformerSubstation"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.Feeder04"
              label="Фидер 0,4 кВ (наименование, номер)"
              role="AdmissionCertificate.UsagePoint.Feeder04"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.Pylon04"
              label="Опора 0,4 кВ (номер)"
              role="AdmissionCertificate.UsagePoint.Pylon04"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.RoomType"
              label="Характеристика помещения (жилое или нежилое)"
              role="AdmissionCertificate.UsagePoint.RoomType"
            />
          </div>

          <div :class="$style.blockContent">
            <c-input
              v-model="AdmissionCertificate.UsagePoint.Switch"
              label="Коммутационный аппарат до прибора учета (номинальный ток, А)"
              role="AdmissionCertificate.UsagePoint.Switch"
            />
          </div>

          <p :class="$style.titleBlock">
            2. Характеристики и показания прибора учета
          </p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.Meter.Location"
              label="Место установки"
              role="AdmissionCertificate.Meter.Location"
            />
            <c-input
              v-model="AdmissionCertificate.Meter.Owner"
              label="Балансовая принадлежность"
              role="AdmissionCertificate.Meter.Owner"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.Meter.Type"
              label="Тип"
              role="AdmissionCertificate.Meter.Type"
            />
            <c-input
              v-model="AdmissionCertificate.Meter.Serial"
              :error="meterNumberError"
              :validation="$validate.isNotEmptyString"
              label="Заводской номер"
              role="AdmissionCertificate.Meter.Serial"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.Meter.AccuracyClass"
              label="Класс точности"
              role="AdmissionCertificate.Meter.AccuracyClass"
            />
            <c-input
              v-model="AdmissionCertificate.Meter.RatedCurrent"
              label="Номинальный ток, А"
              role="AdmissionCertificate.Meter.RatedCurrent"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.Meter.NominalVoltage"
              label="Номинальное напряжение, В"
              role="AdmissionCertificate.Meter.NominalVoltage"
            />
            <c-input
              v-model="AdmissionCertificate.Meter.IntegerDigits"
              label="Разрядность (до запятой)"
              role="AdmissionCertificate.Meter.IntegerDigits"
              type="Number"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.Meter.FractionDigits"
              label="Разрядность (после запятой)"
              role="AdmissionCertificate.Meter.FractionDigits"
              type="Number"
            />
            <c-input
              v-model="AdmissionCertificate.Meter.ProductionYear"
              label="Год выпуска"
              role="AdmissionCertificate.Meter.ProductionYear"
              type="Number"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-date-picker
              v-model="AdmissionCertificate.Meter.CalibrationDate"
              label="Дата поверки"
              role="AdmissionCertificate.CalibrationDate"
            />
            <c-date-picker
              v-model="AdmissionCertificate.Meter.NextCalibrationDate"
              label="Дата следующей поверки"
              role="AdmissionCertificate.NextCalibrationDate"
            />
          </div>

          <p :class="$style.titleBlock">
            Показания электрической энергии
          </p>

          <div v-for="(reading, index) in AdmissionCertificate.Readings.Value" :key="'Readings_' + index"
               :class="$style.addBlock">
            <div :class="$style.grid2Column">
              <c-date-picker
                v-model="reading.time"
                :label="index + 1 + '. Дата и время'"
                :role="'reading[' + index + '].time'"
              />
              <c-input
                v-model="reading.tariff"
                :role="'reading[' + index + '].tariff'"
                label="Номер тарифа"
                type="number"
              />
            </div>

            <div :class="$style.grid2Column">
              <c-input
                v-model="reading.ActiveInput"
                :role="'reading[' + index + '].ActiveInput'"
                label="Активная (прием)"
              />
              <c-input
                v-model="reading.ActiveOutput"
                :role="'reading[' + index + '].ActiveOutput'"
                label="Активная (отдача)"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-input
                v-model="reading.ReactiveInput"
                :role="'reading[' + index + '].ReactiveInput'"
                label="Реактивная (прием)"
              />
              <c-input
                v-model="reading.ReactiveOutput"
                :role="'reading[' + index + '].ReactiveOutput'"
                label="Реактивная (отдача)"
              />

            </div>
          </div>

          <div :class="$style.gridFluid">
            <button :class="$style.addAction" role="AdmissionCertificate.Readings.add" @click="addReading">+ ещё
              показания
            </button>
          </div>

          <p :class="$style.titleBlock">
            3. Характеристики измерительных трансформаторов тока
          </p>
          <div :class="$style.gridFluid">
            <c-input
              v-model="AdmissionCertificate.CurrentTransformer.Placement"
              label="Место установки"
              role="AdmissionCertificate.CurrentTransformer.Placement"
            />
          </div>

          <div v-for="(Phase, index) in AdmissionCertificate.CurrentTransformer.Phase"
               :key="'CurrentTransformer.Phase_' + index">
            <p :class="$style.titleBlock"><span>Фаза {{ Phase.name }}</span></p>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.Type"
                :role="'Phase[' + index + '].Type'"
                label="Тип"
              />
              <c-input
                v-model="Phase.Serial"
                :role="'Phase[' + index + '].Serial'"
                label="Заводской номер"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.TransformationRatio"
                :role="'Phase[' + index + '].TransformationRatio'"
                label="Коэффициент трансформации"
              />
              <c-input
                v-model="Phase.AccuracyClass"
                :role="'Phase[' + index + '].AccuracyClass'"
                label="Класс точности"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-date-picker
                v-model="Phase.CalibrationDate"
                :role="'Phase[' + index + '].CalibrationDate'"
                label="Дата поверки"
              />
              <c-date-picker
                v-model="Phase.NextCalibrationDate"
                :role="'Phase[' + index + '].NextCalibrationDate'"
                label="Дата следующей поверки"
              />
            </div>
          </div>

          <p :class="$style.titleBlock">
            4. Характеристики измерительных трансформаторов напряжения
          </p>
          <div :class="$style.gridFluid">
            <c-input
              v-model="AdmissionCertificate.PotentialTransformer.Placement"
              label="Место установки"
              role="AdmissionCertificate.CurrentTransformer.Placement"
            />
          </div>

          <div v-for="(Phase, index) in AdmissionCertificate.PotentialTransformer.Phase"
               :key="'PotentialTransformer.Phase_' + index">
            <p :class="$style.titleBlock"><span>Фаза {{ Phase.name }}</span></p>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.Type"
                :role="'Phase[' + index + '].Type'"
                label="Тип"
              />
              <c-input
                v-model="Phase.Serial"
                :role="'Phase[' + index + '].Serial'"
                label="Заводской номер"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.TransformationRatio"
                :role="'Phase[' + index + '].TransformationRatio'"
                label="Коэффициент трансформации"
              />
              <c-input
                v-model="Phase.AccuracyClass"
                :role="'Phase[' + index + '].AccuracyClass'"
                label="Класс точности"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-date-picker
                v-model="Phase.CalibrationDate"
                :role="'Phase[' + index + '].CalibrationDate'"
                label="Дата поверки"
              />
              <c-date-picker
                v-model="Phase.NextCalibrationDate"
                :role="'Phase[' + index + '].NextCalibrationDate'"
                label="Дата следующей поверки"
              />
            </div>
          </div>

          <p :class="$style.titleBlock">
            5. Информация о знаках визуального контроля (пломбах)
          </p>

          <div :class="$style.grid3Column">
            <div><span>Место установки пломбы</span></div>
            <div><span>Номер пломбы</span></div>
            <div><span>Наименование организации, установившей пломбу</span></div>
          </div>
          <div v-for="(Seal, index) in AdmissionCertificate.Seals.Seal" :key="'Seal_'+index" :class="$style.addBlock">
            <div :class="$style.grid3Column">
              <c-input
                v-model="Seal.Placement"
                :role="'Seal[' + index + '].Placement'"
              />
              <c-input
                v-model="Seal.Number"
                :role="'Seal[' + index + '].Number'"
              />
              <c-input
                v-model="Seal.Organization"
                :role="'Seal[' + index + '].Organization'"
              />
            </div>
          </div>

          <div :class="$style.gridFluid">
            <button :class="$style.addAction" role="AdmissionCertificate.Seal.add" @click="addSeal">+ ещё пломба
            </button>
          </div>

          <p :class="$style.titleBlock">
            6. Сведения об оборудовании дистанционного сбора данных
          </p>

          <p :class="$style.titleBlock">
            Устройство сбора и передачи данных
          </p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Location"
              label="Место установки"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Location"
            />
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
              label="Балансовая принадлежность"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Type"
              label="Тип"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Type"
            />
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Serial"
              label="Заводской номер"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Serial"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-date-picker
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate"
              label="Дата поверки"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate"
            />
            <c-date-picker
              v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.NextCalibrationDate"
              label="Дата следующей поверки"
              role="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.NextCalibrationDate"
            />
          </div>

          <p :class="$style.titleBlock">
            Коммуникационное оборудование
          </p>

          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Location"
              label="Место установки"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.Location"
            />
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
              label="Балансовая принадлежность"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Type"
              label="Тип"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.Type"
            />
            <c-input
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Serial"
              label="Заводской номер"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.Serial"
            />
          </div>
          <div :class="$style.grid2Column">
            <c-date-picker
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate"
              label="Дата поверки"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate"
            />
            <c-date-picker
              v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.NextCalibrationDate"
              label="Дата следующей поверки"
              role="AdmissionCertificate.DataAcquisitionEquipment.Switch.NextCalibrationDate"
            />
          </div>

          <p :class="$style.titleBlock">
            7. Результаты измерений
          </p>

          <div v-for="(Phase, index) in AdmissionCertificate.PhaseMeasurements.Phase"
               :key="'PhaseMeasurements.Phase_' + index">
            <p :class="$style.titleBlock"><span>Фаза {{ Phase.name }}</span></p>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.PrimaryCircuitCurrent"
                :role="'Phase[' + index + '].PrimaryCircuitCurrent'"
                label="Сила тока в первичной цепи"
              />
              <c-input
                v-model="Phase.MeasuringCircuitCurrent"
                :role="'Phase[' + index + '].MeasuringCircuitCurrent'"
                label="Сила тока в измерительных цепях"
              />
            </div>
            <div :class="$style.grid2Column">
              <c-input
                v-model="Phase.PhaseVoltage"
                :role="'Phase[' + index + '].PhaseVoltage'"
                label="Фазное напряжение"
              />
              <c-input
                v-model="Phase.PhaseShift"
                :role="'Phase[' + index + '].PhaseShift'"
                label="Угол фазового сдвига"
              />
            </div>
          </div>

          <p :class="$style.titleBlock">
            8. Характеристики использованного оборудования
          </p>

          <div :class="$style.blockContent">
            <c-textarea
              v-model="AdmissionCertificate.UsedEquipment.Name"
              role="AdmissionCertificate.UsedEquipment.Name"
            />
          </div>

          <p :class="$style.titleBlock">
            9. Прочее
          </p>

          <div :class="$style.blockContent">
            <c-textarea
              v-model="AdmissionCertificate.Comments"
              role="AdmissionCertificate.Comments"
            />
          </div>

          <p :class="$style.titleBlock">
            10. Заключение. Решение о допуске (недопуске) прибора учета в эксплуатацию (в случае недопуска указать
            причины)
          </p>

          <div :class="$style.blockContent">
            <c-textarea
              v-model="AdmissionCertificate.Resolution"
              role="AdmissionCertificate.Resolution"
            />
          </div>

          <p :class="$style.titleBlock">
            11. Подписывающие лица
          </p>
          <div v-for="(Signer, index) in AdmissionCertificate.Signers.Signer"
               :key="'AdmissionCertificate.Signer_'+index" :class="$style.addBlock">
            <div :class="$style.gridFluid">
              <c-input
                v-model="Signer.Name"
                :label="index + 1 + '. Наименование'"
                :role="'Signers[' + index + '].Name'"
              />
            </div>

            <div :class="$style.grid2Column">
              <c-input
                v-model="Signer.FamilyName"
                :role="'Signers[' + index + '].familyName'"
                label="Фамилия"
              />
              <c-input
                v-model="Signer.FirstName"
                :role="'Signers[' + index + '].firstName'"
                label="Имя"
              />
            </div>

            <div :class="$style.gridFluid">
              <c-input
                v-model="Signer.Patronymic"
                :role="'Signers[' + index + '].patronymic'"
                label="Отчество"
              />
            </div>
          </div>

          <div :class="$style.gridFluid">
            <button :class="$style.addAction" role="AdmissionCertificate.Signer.add" @click="addAdmissionSigner">+ ещё
              подписывающее лицо
            </button>
          </div>
        </div>
      </div>

    </div>

    <div :class="$style.actionContent">
      <p v-if="isValid" :class="$style.submitNote">
        Нажимая кнопку «Подписать и отправить», вы направляете документы потребителю и подписываете их простой
        электронной подписью.
      </p>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <submit-task-details :disabled="isWaitingSubmit || !isValid" title="Подписать и отправить" @submit="onSubmit"/>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { scrollIntoView } from 'lib/utils/DOM';
import { getFileUrl } from 'lib/utils/files';
import { getCurrentDate } from 'lib/utils/date';

export default {
  name: 'CloseApplication',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    FileListPreview,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    CInput: () => import('atoms/common/inputs/CInput'),
    CDatePicker: () => import('atoms/common/inputs/CDatePicker'),
    CSelect: () => import('atoms/common/CSelect'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    applicationData: Object,
    offerDocument: Object,
    applicationId: String,
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitError: '',
      meterNumberError: { error: false },
      mainSubstationError: { error: false },
      usagePointName: { error: false },
      contractNumberError: { error: false },
      isWaitingSubmit: false,
      applicationStatus: undefined,
      cancelledComments: '',
      cancelledDocs: [],
      AdmissionCertificate: {
        Date: '',
        AdmissionCertificateNumber: '',
        GridCompanyName: '',
        GridCompanyEmployee: '',
        Customer: '',
        RetailCompanyName: '',
        RetailCompanyEmployee: '',
        RetailAgreementNumber: '',
        TariffCategory: '',
        VerificationDate: '',
        UsagePoint: {
          Address: '',
          Name: '',
          MainSubstation: '',
          Feeder10: '',
          TransformerSubstation: '',
          Feeder04: '',
          Pylon04: '',
          RoomType: '',
          Switch: '',
        },
        Meter: {
          Location: '',
          Owner: '',
          Type: '',
          Serial: '',
          AccuracyClass: '',
          RatedCurrent: '',
          NominalVoltage: '',
          IntegerDigits: '',
          FractionDigits: '',
          ProductionYear: '',
          CalibrationDate: '',
          NextCalibrationDate: '',
        },
        Readings: {
          Value: [
            {
              time: '',
              tariff: '',
              ActiveInput: '',
              ActiveOutput: '',
              ReactiveInput: '',
              ReactiveOutput: '',
            },
          ],
        },
        CurrentTransformer: {
          Placement: '',
          Phase: [
            {
              name: 'A',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
            {
              name: 'B',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
            {
              name: 'C',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
          ],
        },
        PotentialTransformer: {
          Placement: '',
          Phase: [
            {
              name: 'A',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
            {
              name: 'B',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
            {
              name: 'C',
              Type: '',
              Serial: '',
              TransformationRatio: '',
              AccuracyClass: '',
              CalibrationDate: '',
              NextCalibrationDate: '',
            },
          ],
        },
        Seals: {
          Seal: [
            {
              Placement: '',
              Number: '',
              Organization: '',
            },
          ],
        },
        DataAcquisitionEquipment: {
          RemoteTerminalUnit: {
            Location: '',
            Owner: '',
            Type: '',
            Serial: '',
            CalibrationDate: '',
            NextCalibrationDate: '',
          },
          Switch: {
            Location: '',
            Owner: '',
            Type: '',
            Serial: '',
            CalibrationDate: '',
            NextCalibrationDate: '',
          },
        },
        PhaseMeasurements: {
          Phase: [
            {
              name: 'A',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
            {
              name: 'B',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
            {
              name: 'C',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
          ],
        },
        UsedEquipment: {
          Name: '',
        },
        Comments: '',
        Resolution: '',
        Signers: {
          Signer: [
            {
              Name: '',
              FamilyName: '',
              FirstName: '',
              Patronymic: '',
            },
          ],
        },
      },
      ConnectionCertificate: {
        Date: '',
        Number: '',
        GridCompanyName: '',
        GridCompanyEmployee: '',
        GridCompanyDocument: '',
        Customer: '',
        CustomerRepresentative: '',
        CustomerDocument: '',
        ConnectionAgreement: {
          Number: '',
          Date: '',
          Amount: '',
          AmountFormatting: '',
        },
        TechnicalSpecification: {
          Number: '',
          Date: '',
        },
        TechnicalCertificate: {
          Number: '',
          Date: '',
        },
        ConnectionAddress: '',
        ConnectionCharacteristics: {
          MaxPower: '0',
          NewMaxPower: '',
          ReliabilityClass: '',
          MicrogenerationMaxPower: '',
        },
        ConnectionPoints: {
          ConnectionPoint: [
            {
              Number: '',
              PowerSupplyCenter: '',
              Description: '',
              VoltageLevel: '',
              MaxPower: '',
              MicrogenerationMaxPower: '',
              TotalTransformerPowerRating: '',
              ReliabilityClass: '',
              ReactivePowerFactor: '',
            },
          ],
        },
        BalanceAffiliationBoundary: '',
        OperationalResponsibilityBoundary: '',
        GridCompanyEquipment: '',
        CustomerEquipment: '',
        GridCompanyResponsibleEquipment: '',
        CustomerResponsibleEquipment: '',
        ProtectiveDevice: '',
        OffGridPowerSupply: '',
        SpecialNotes: '',
        BoundaryScheme: [],
        Comments: '',
        Signers: {
          Signer: [
            {
              Name: '',
              FamilyName: '',
              FirstName: '',
              Patronymic: '',
            },
          ],
        },
      },
      ContractConsumerMes: {
        SourceApplicationDocumentId: '',
        ContractNumber: '',
        ContractDate: '',
        ReliabilityClass: '',
        UsagePointAddress: '',
        VoltageLevel: '',
        AccountingRatio: '',
        ObjectNumber: '',
        MeterSerial: '',
      },
    };
  },
  computed: {
    isValid() {
      return (this.applicationStatus === 2 && this.cancelledComments.length > 0) ||
        (this.applicationStatus === 1);
    },
    reviewAttachedFiles() {
      return this.cancelledDocs;
    },
    reviewComments() {
      return this.cancelledComments;
    },
    tariffCategoryList() {
      return [
        { label: 'По умолчанию', value: 'По умолчанию' },
        { label: '1 ценовая категория', value: '1 ценовая категория' },
        { label: '2 ценовая категория', value: '2 ценовая категория' },
        { label: '3 ценовая категория', value: '3 ценовая категория' },
        { label: '4 ценовая категория', value: '4 ценовая категория' },
        { label: '5 ценовая категория', value: '5 ценовая категория' },
        { label: '6 ценовая категория', value: '6 ценовая категория' },
      ];
    },
    ...mapGetters('user', ['getUserType']),
  },
  watch: {
    offerDocument: {
      immediate: true,
      handler() {
        if (this.applicationData.gridCompany) {
          const currentDate = getCurrentDate('YYYY-MM-DD');
          const customer = `${this.applicationData.consumer.familyName} ${this.applicationData.consumer.firstName}  ${this.applicationData.consumer.patronymic}`;

          this.AdmissionCertificate.Date = currentDate;
          this.AdmissionCertificate.AdmissionCertificateNumber = this.documentId;
          this.AdmissionCertificate.GridCompanyName = this.applicationData.gridCompany.gridCompanyName.label;
          this.AdmissionCertificate.Customer = customer;
          this.AdmissionCertificate.RetailCompanyName = this.applicationData.connectionCharacteristics.supplierOfLastResort.label;
          this.AdmissionCertificate.RetailAgreementNumber = this.applicationData.connectionCharacteristics.retailAgreementNumber;
          this.AdmissionCertificate.TariffCategory = { label: 'По умолчанию', value: 'По умолчанию' };
          this.AdmissionCertificate.UsagePoint.Address = this.applicationData.objectLocation.objectAddress;

          this.setContractConsumerMesData();

          this.ConnectionCertificate.Date = currentDate;
          this.ConnectionCertificate.Number = this.documentId;
          this.ConnectionCertificate.GridCompanyName = this.applicationData.gridCompany.gridCompanyName.label;
          this.ConnectionCertificate.Customer = customer;
          this.ConnectionCertificate.ConnectionAgreement.Amount = this.offerDocument.unformattedPrice;
          this.ConnectionCertificate.ConnectionAgreement.AmountFormatting = this.offerDocument.price;
          this.ConnectionCertificate.ConnectionAddress = this.applicationData.objectLocation.objectAddress;
          this.ConnectionCertificate.ConnectionCharacteristics.NewMaxPower = this.applicationData.connectionCharacteristics.maxPowerConnection;
          if (this.applicationData.connectionCharacteristics.reliabilityClass && this.applicationData.connectionCharacteristics.reliabilityClass.value) {
            this.ConnectionCertificate.ConnectionCharacteristics.ReliabilityClass = this.applicationData.connectionCharacteristics.reliabilityClass.label;
          }
          if (this.onlyMicrogenerator) {
            let sum = 0;
            this.applicationData.microgenerator.items.forEach((item) => {
              sum += item.MaxGeneratePowerDecimal;
            });

            this.ConnectionCertificate.ConnectionCharacteristics.MicrogenerationMaxPower = String(sum);
          }
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      if (this.applicationStatus === 2) {
        const documentReviewXml = connectionApplicationData.documentReviewXml(
          this.documentId,
          this.actionProps.reviewType,
          this.reviewComments,
          this.reviewAttachedFiles,
          [],
        );

        try {
          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            this.isWaitingSubmit = false;
            // TODO возможно, нужно ждать изменения состояния
            this.$nextTick(() => {
              this.vueShowNotification(
                'ЗАЯВКА АННУЛИРОВАНА',
                '',
              );
            });
            this.onClose();
          }, 2000);

        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
      } else {
        try {
          if (!this.onlyMicrogenerator) {

            let errorRole = '';
            if (this.AdmissionCertificate.UsagePoint.Name.length === 0) {
              this.usagePointName = { error: true };
              errorRole = 'AdmissionCertificate.UsagePoint.Name';
              this.submitError = '';
              this.isWaitingSubmit = false;
            }
            if (this.AdmissionCertificate.Meter.Serial.length === 0) {
              this.meterNumberError = { error: true };
              errorRole = 'AdmissionCertificate.Meter.Serial';
              this.submitError = '';
              this.isWaitingSubmit = false;
            }
            if (this.AdmissionCertificate.UsagePoint.MainSubstation.length === 0) {
              this.mainSubstationError = { error: true };
              errorRole = 'AdmissionCertificate.UsagePoint.MainSubstation';
              this.submitError = '';
              this.isWaitingSubmit = false;
            }
            if (this.ContractConsumerMes.ContractNumber.length === 0) {
              this.contractNumberError = { error: true };
              errorRole = 'ContractConsumerMes.ContractNumber';
              this.submitError = '';
              this.isWaitingSubmit = false;
            }
            if (errorRole.length !== 0) {
              scrollIntoView(
                document.querySelector(`[role="${errorRole}"]`),
              );
              return;
            }
          }

          const ConnectionCertificateXml = connectionApplicationData.generateConnectionCertificateXml(this.ConnectionCertificate, this.onlyMicrogenerator);
          await this.documentUpload({ xml: ConnectionCertificateXml });

          if (this.AdmissionCertificate.Meter.Serial.length > 0 && this.AdmissionCertificate.UsagePoint.MainSubstation.length > 0) {
            const AdmissionCertificateXml = connectionApplicationData.generateAdmissionCertificateXml(this.AdmissionCertificate);
            await this.documentUpload({ xml: AdmissionCertificateXml });
          }

          const ContractConsumerMes = connectionApplicationData.contractConsumerMesXml(this.ContractConsumerMes, this.applicationData);
          await this.documentUpload({ xml: ContractConsumerMes });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            this.isWaitingSubmit = false;
            // TODO возможно, нужно ждать изменения состояния
            this.$nextTick(() => {
              this.vueShowNotification(
                'ЗАЯВКА ВЫПОЛНЕНА',
                '',
              );
            });
            this.onClose();
          }, 2000);
        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
      }
    },
    onClose() {
      this.applicationStatus = undefined;
      this.$emit('close');
    },
    applicationStatusClick(val) {
      this.applicationStatus = val;
    },
    handleRemoveCancelledDocs(file) {
      this.cancelledDocs = this.cancelledDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveBoundaryScheme(file) {
      this.ConnectionCertificate.BoundaryScheme = this.ConnectionCertificate.BoundaryScheme
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    setContractConsumerMesData() {
      this.ContractConsumerMes.SourceApplicationDocumentId = this.documentId;
      this.ContractConsumerMes.ContractDate = getCurrentDate('YYYY-MM-DD');
      this.ContractConsumerMes.UsagePointAddress = this.applicationData.objectLocation.objectAddress;
      if (this.applicationData.connectionCharacteristics.reliabilityClass && this.applicationData.connectionCharacteristics.reliabilityClass.value) {
        this.ContractConsumerMes.ReliabilityClass = this.applicationData.connectionCharacteristics.reliabilityClass.value;
      }
      this.ContractConsumerMes.MeterSerial = this.AdmissionCertificate.Meter.Serial;
      this.ContractConsumerMes.ObjectNumber = ''; // TODO Что является номером объекта и откуда его брать?
    },
    addConnectionPoint() {
      this.ConnectionCertificate.ConnectionPoints.ConnectionPoint.push(
        {
          Number: '',
          PowerSupplyCenter: '',
          Description: '',
          VoltageLevel: '',
          MaxPower: '',
          MicrogenerationMaxPower: '',
          TotalTransformerPowerRating: '',
          ReactivePowerFactor: '',
        },
      );
    },
    addSigner() {
      this.ConnectionCertificate.Signers.Signer.push(
        {
          Name: '',
          FamilyName: '',
          FirstName: '',
          Patronymic: '',
        },
      );
    },
    addAdmissionSigner() {
      this.AdmissionCertificate.Signers.Signer.push(
        {
          Name: '',
          FamilyName: '',
          FirstName: '',
          Patronymic: '',
        },
      );
    },
    addReading() {
      this.AdmissionCertificate.Readings.Value.push(
        {
          time: '',
          tariff: '',
          ActiveInput: '',
          ActiveOutput: '',
          ReactiveInput: '',
          ReactiveOutput: '',
        },
      );
    },
    addSeal() {
      this.AdmissionCertificate.Seals.Seal.push(
        {
          Placement: '',
          Number: '',
          Organization: '',
        },
      );
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.content
  margin-top: 130px
  padding: 0 72px 34px 72px

.statuses
  width: 220px
  position: relative
  left: 50%
  margin-left: -110px

.title
  padding-bottom: 120px
  +base-title

.titleBlock
  padding-top: 40px
  padding-bottom: 40px
  +base-title

.titleAct
  padding-bottom: 40px
  +big-title

.titleNumber
  padding-bottom: 40px
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 18px
  line-height: 24px
  color: #000

.titleNumber span
  font-size: 24px
  line-height: 32px

.titleField
  padding-bottom: 40px
  font-family: Roboto
  font-weight: 400
  font-size: 16px
  line-height: 22px
  color: #000

.titleField span
  font-family: 'Roboto Condensed'
  font-size: 21px
  line-height: 30px

.arrow
  padding-left: 16px
  padding-bottom: 4px

.closeButton
  width: 100%
  height: 56px
  +base-button-text
  color: #ffffff
  background: #3894FB

  &:disabled
    background: var(--color-background-gray-150)

.radioGroup
  margin-left: -66px
  margin-top: 28px

.radioGroupTariff
  display: flex
  flex-direction: column
  align-items: start
  margin-left: 84px
  margin-top: 18px

.labelRadio
  cursor: pointer
  height: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

.statusGroup, .submitNote
  width: 100%
  max-width: 560px
  margin: 0 auto
  display: flex

.correctionGroup, .accompanyingText
  width: 100%
  max-width: 560px
  margin: 0 auto

.accompanyingText
  margin-top: 40px
  margin-bottom: 40px

.correctionTitle
  padding-bottom: 12px
  +base-title

.subTitle
  +base-subtitle
  +medium-spacing

.mediumSpacing
  +medium-spacing

.blockContent
  &:last-child
    margin-bottom: 40px

  &:not(:last-child)
    +base-spacing

.gridFluid
  +grid-fluid

.subtitle
  +base-subtitle
  +medium-spacing

  span
    padding-right: 8px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 8px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.reviewDocumentFiles p a, .reviewDocumentFiles p span
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocumentFiles p span.replace
  color: #4C4E51
  white-space: nowrap
  cursor: pointer

.reviewDocumentFiles a span
  text-decoration: underline

.reviewDocumentFiles p
  &:not(:last-child)
    margin-bottom: 24px

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #000

.grid2Column
  +grid-2-column

.grid3Column
  +grid-3-column

.grid4Column
  +grid-4-column

.gridFluid
  +grid-fluid

button.addAction
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 24px
  text-decoration: underline
  background: transparent
  color: #141412
  cursor: pointer
  margin-bottom: 24px

.addBlock
  margin-bottom: 24px

.textHelper
  +base-text
  color: #141412
  display: flex

.actionContent
  width: 560px
  margin: 0 auto
</style>
