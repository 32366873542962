<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">
      <span>{{ stepNumber.title() }}</span>
    </p>

    <p :class="$style.stepSubTitle">Справка о полезном отпуске</p>

    <div :class="$style.attachment">
      <a :class="$style.fileAttachment"
         download="UsefulVacationDocument.xml"
         :href="getXmlBlobUsefulVacationDocument()">
        <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
        <span>UsefulVacationDocument.xml</span>
      </a>
    </div>

    <template v-if="usefulVacationDocument.documentsScan.length > 0">
      <p :class="$style.stepSubTitle">Подтверждающий документ</p>

      <div v-for="(file, index) in usefulVacationDocument.documentsScan" :key="index" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="file.name" :href="fileUrl(file)">
          <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
          <span>{{ file.name }}</span>
        </a>
      </div>
    </template>

    <div :class="$style.submit">
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit" title='Загрузить' @submit="onSubmit" />
    </div>

  </div>
</template>

<script>

import { getFileUrl } from 'lib/utils/files';
import { mapActions, mapGetters } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import { XmlMd5 } from 'lib/utils/md5';

export default {
  name: 'DownloadReport',
  components: {
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
  },
  data() {
    return {
      isWaitingSubmit: false,
      xmlUsefulVacationDocument: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dataLists', [
      'voltageClassList',
      'typeAccountingList',
      'typeAccountingListLong',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'tariffCategoryTypeList',
    ]),
  },
  methods: {
    async onSubmit() {
      this.isWaitingSubmit = true;

      console.log('xml UsefulVacationDocument', this.xmlUsefulVacationDocument);

      try {
        let result = undefined;

        if (this.usefulVacationDocument.documentsScan.length === 0) {
          result = await this.documentUpload({ xml: this.xmlUsefulVacationDocument });
        } else {
          const xmlScan = generateXml.DocumentScan(
            this.usefulVacationDocument.documentsScan,
            XmlMd5(this.xmlUsefulVacationDocument),
            'UsefulVacationDocument',
            [this.usefulVacationDocument.currentSignatory],
          );

          result = await this.submitManyDocuments({ xmls: [this.xmlUsefulVacationDocument, xmlScan] });
        }

        if (!result) {
          this.isWaitingSubmit = false;
          return;
        }

        setTimeout(() => {
          // TODO возможно, нужно ждать изменения состояния
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$emit('complete', true);
          this.$emit('close');
        }, 2000);

      } catch (error) {
        const errorMessage = error.message.split(': ')[2];
        this.setNotification({ message: errorMessage });
        this.isWaitingSubmit = false;
      }
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    getXmlBlobUsefulVacationDocument() {
      this.xmlUsefulVacationDocument = generateXml.UsefulVacationDocument(
        this.usefulVacationDocument,
        this.voltageClassList,
      );
      const blob = new Blob([this.xmlUsefulVacationDocument], { type: 'text/xml' });
      return  window.URL.createObjectURL(blob);
    },
    ...mapActions('cabinet', ['addTestTask', 'clearTestTask', 'setWorkflows']),
    ...mapActions('document', ['documentUpload', 'submitManyDocuments']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 848px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 24px auto 0px

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51

  .stepSubTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 18px
    white-space: nowrap
    color: #4C4E51

.submit
  margin-top: 44px

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.errorText
  +small-text
  margin: 4px
  color: red

</style>
