<template>
  <validation-provider
    v-slot="{ errors }"
    :custom-messages="rulesMessages"
    :name="name"
    :rules="rules"
    :vid="vid"
    mode="lazy"
  >
    <div class="date-picker-container">
      <v-label
        :is-error="(errors.length > 0 || err) && !disabled"
        :is-need-mouseover-tooltip="isNeedMouseoverTooltip"
        :is-value="isText"
        :label="labelByRequired"
        :title="titleByRequired"
      >
        <div :class="{['mt-8']: isMarginTopNeeded,}">
          <date-picker
            :default-value="startDate"
            :disabled="disabled"
            :disabled-date="disabledAfterToday"
            :format="format"
            :input-class="{
            ['vmx-input']: true,
            ['error-date']: (errors.length > 0 || err) && !disabled,
          }"
            :placeholder="placeholder"
            :range="range"
            :type="type"
            :value="internalValue"
            lang="ru"
            prefix-class="vmx"
            value-type="format"
            @clear="handleClear"
            @input="onValueChange($event)"
          />
        </div>
      </v-label>
      <span
        v-if="errText !== '' && err"
        class="errorText"
      >
        {{ errText }}
      </span>
      <p v-if="errors.length > 0 && !disabled" class="errorText">{{ errors[0] }}</p>
    </div>
  </validation-provider>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'VDatePicker',
  components: {
    DatePicker,
    ValidationProvider,
    VLabel: () => import('@/components/Common/VLabel/VLabel'),
  },
  props: {
    isDisabledFutureDates: { type: Boolean, default: false },
    label: { type: String, default: '' },
    type: { type: String, default: 'date' },
    format: { type: String, default: 'DD.MM.YYYY' },
    value: { type: [String, Array], default: '' },
    rules: { type: String, default: '' },
    rulesMessages: {
      type: Object, default: () => {
      },
    },
    mode: { type: String, default: 'passive' },
    vid: { type: String, default: '' },
    startDate: {
      type: Date, default: () => new Date(),
    },
    range: { type: Boolean, default: false },
    err: { type: Boolean, default: false },
    errText: { type: String, default: '' },
    title: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    isNeedMouseoverTooltip: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: false },
    maxDay: { type: Number, default: undefined },
  },
  data() {
    return {
      internalValue: this.range ? [] : '',
    };
  },
  computed: {
    isMarginTopNeeded() {
      if (this.title.length !== 0 || this.label.length !== 0) {
        return true;
      }
      return false;
    },
    name() {
      if (!this.label) return this.title;
      return this.label;
    },
    isText() {
      if (!this.internalValue) return false;
      return this.internalValue && this.internalValue.length > 0;
    },
    labelByRequired() {
      if (this.required && this.label && !this.disabled) {
        return `${this.label} *`;
      }
      return this.label;
    },
    titleByRequired() {
      if (this.required && this.title && !this.disabled) {
        return `${this.title} *`;
      }
      return this.title;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internalValue = val;
      },
    },
  },
  methods: {
    disabledAfterToday(date) {
      if (this.isDisabledFutureDates) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date > today;
      }

      if (this.maxDay) {
        return date.getDate() > this.maxDay;
      }

      return false;
    },
    onValueChange(value) {
      this.$emit('input', value);
    },
    handleClear() {
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="sass">
$namespace: 'vmx'

@import '~vue2-datepicker/scss/index.scss'

.date-picker-container
  position: relative


.vmx-datepicker-popup
  z-index: 10040

.vmx-datepicker
  width: 100%
  position: relative


.vmx-input
  border: 1px solid #dee2e6 !important
  height: 40px !important
  color: #000000 !important
  padding: 0 15px !important
  border-radius: 0 !important
  box-shadow: none !important

  &:focus
    outline: none !important
    border: 1px solid #2f82df !important

  &:hover
    border: 1px solid #2f82df !important

  &:disabled
    color: #000000 !important

.error-date
  border-color: red

.mt-8
  margin-top: 8px !important

.vmx-time-column .vmx-time-list
  list-style-type: none

  .vmx-time-item
    text-align: center
</style>
