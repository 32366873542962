<template>
  <validation-observer ref="observer" v-slot="{ invalid }" style="height: 100%" tag="div" v-bind="$attrs">
    <form :class="{['disabled-validate-form']: invalid}" style="height: 100%" @submit.prevent="onSubmit(invalid)">
      <slot></slot>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'VForm',
  components: { ValidationObserver },
  props: {
    actionOnError: {
      type: Function,
      required: false,
    },
  },
  methods: {
    async onSubmit() {
      const observer = this.$refs.observer;
      const isValid = await observer.validate();

      if (!isValid) {
        if (this.actionOnError) {
          await this.actionOnError();
        }

        for (const key of Object.keys(observer.fields)) {
          if (observer.fields[key].invalid) {
            this.$emit('error', key, observer.refs[key].$el);
            observer.refs[key].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
          }
        }
      } else {
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" module></style>
