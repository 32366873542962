<template>
  <div :class="$style.container">
    <div>
      <div v-if="reviewDocument && reviewDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.commentsTitle">
          Потребителю были направлены замечания:
        </div>
        <p v-html="comments"></p>
      </div>
      <div v-if="reviewDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in reviewDocument.files" :key="index"
           style="display: flex; justify-content: space-between">
          <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
             :role="'reviewDocumentFile[' + index + '].name'">{{ file.name }}</a>
          <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
             :role="'reviewDocumentFile[' + index + '].download'">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div v-if="retailReviewDocument && retailReviewDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.commentsTitle">
          Была запрошена дополнительная информация для заключения {{ actionProps.agreementTitle }}:
        </div>
        <p v-html="retailReviewComments"></p>
      </div>
      <div v-if="retailReviewDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in retailReviewDocument.files" :key="index"
           style="display: flex; justify-content: space-between">
          <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
             :role="'retailReviewDocument[' + index + '].name'">{{ file.name }}</a>
          <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
             :role="'retailReviewDocument[' + index + '].download'">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div v-if="additionalDocument && additionalDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.additionalDocumentTitle">
          Дополнительная информация переданная потребителем:
        </div>
        <p v-html="additionalComments"></p>
      </div>
      <div v-if="additionalDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in additionalDocument.files" :key="index"
           style="display: flex; justify-content: space-between">
          <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocument[' + index + '].name'">{{ file.name }}</a>
          <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocument[' + index + '].download'">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div :class="$style.statusGroup">
        <p :class="$style.title">
          Отметьте статус заявки
        </p>
        <div :class="$style.radioGroup">
          <label :class="$style.labelRadio">
            <input
              :checked="statusGroup === 1"
              :class="$style.control"
              name="taskStatus"
              role="radio.approve"
              type="radio"
              @click="statusGroupClick(1)"

            />
            <div :class="$style.contentRadioGroup">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupCheck"></div>
                <h3 :class="$style.titleRadioGroup">Одобрена</h3>
              </div>
            </div>
          </label>
          <label :class="$style.labelRadio">
            <input
              :checked="statusGroup === 2"
              :class="$style.control"
              name="taskStatus"
              role="radio.reject"
              type="radio"
              @click="statusGroupClick(2)"
            />
            <div :class="$style.contentRadioGroup" style="margin-top: -16px">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupError"></div>
                <h3 :class="$style.titleRadioGroup">{{ actionProps.rejectButtonTitle }}</h3>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div v-if="statusGroup === 2" :class="$style.correctionGroup">
        <p :class="$style.correctionTitle">
          {{ actionProps.title }}
        </p>
        <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

        <div :class="$style.blockContent">
          <p>{{ actionProps.commentTitle }}*</p>
          <c-textarea
            v-model="correctionComments"
            role="correctionComments"
          />
        </div>

        <div :class="$style.blockContent">
          <p :class="$style.subtitle">
            Приложите необходимые документы
          </p>

          <div>
            <div v-if="correctionDocs.length">
              <file-list-preview
                :list="correctionDocs"
                action
                @delete="handleRemoveCorrectionDocs"
              />
            </div>

            <c-uploading
              v-model="correctionDocs"
              accept=""
              accept-title="или перетащите его в эту область."
              role="correctionDocs"
              style="margin-top: 16px"
            />
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.actionContent">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit || !isValid" :title="submitTitle" @submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';

export default {
  name: 'ApprovedApplication',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    FileListPreview,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    reviewDocument: Object,
    retailReviewDocument: Object,
    additionalDocument: Object,
  },
  data() {
    return {
      submitError: '',
      isWaitingSubmit: false,
      statusGroup: undefined,
      correctionComments: '',
      correctionDocs: [],
    };
  },
  computed: {
    isValid() {
      return (this.statusGroup === 2 && this.correctionComments && this.correctionComments !== '') || this.statusGroup === 1;
    },
    reviewType() {
      if (this.statusGroup === 1) {
        return this.actionProps.approvedReviewType;
      }
      if (this.statusGroup === 2) {
        return this.actionProps.rejectedReviewType;
      }
      // TODO handle this error (I don't know when it is possible)
      return '';
    },
    reviewComments() {
      if (this.statusGroup === 2) {
        return this.correctionComments;
      }
      return '';
    },
    reviewAttachedFiles() {
      if (this.statusGroup === 2) {
        return this.correctionDocs;
      }
      return [];
    },
    submitTitle() {
      if (this.statusGroup === 2) {
        return this.actionProps.submitTitle;
      }
      return 'ОДОБРИТЬ';
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '«', '»');
    },
    retailReviewComments() {
      return formatting.formattedString(this.retailReviewDocument.comments, '«', '»');
    },
    additionalComments() {
      return formatting.formattedString(this.additionalDocument.comments, '«', '»');
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    statusGroupClick(val) {
      this.tariff = undefined;
      this.statusGroup = val;
    },
    handleRemoveCorrectionDocs(file) {
      this.correctionDocs = this.correctionDocs
        .filter((scan) => scan.source !== file.source);
    },
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      const documentReviewXml = connectionApplicationData.documentReviewXml(
        this.documentId,
        this.reviewType,
        this.reviewComments,
        this.reviewAttachedFiles,
        {},
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO (возможно, нужно ждать изменения состояния, иначе задача на рассмотрение останется)
          const statusGroup = this.statusGroup;
          this.$nextTick(() => {
            this.showNotification(statusGroup);
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    showNotification(statusGroup) {
      if (statusGroup === 2) {
        this.vueShowNotification(
          this.actionProps.rejectedMessage,
          '',
        );
      } else {
        this.vueShowNotification(
          'ЗАЯВКА ОДОБРЕНА',
          `<p>${this.actionProps.approvedMessage}</p>`,
        );
      }
    },
    onClose() {
      this.$emit('close');
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.content
  margin-top: 130px
  padding: 0 72px 34px 72px

.statuses
  width: 220px
  position: relative
  left: 50%
  margin-left: -110px

.title
  padding-bottom: 120px
  +base-title

.additionalDocumentTitle
  padding-top: 40px
  padding-bottom: 16px
  +base-title

.arrow
  padding-left: 16px
  padding-bottom: 4px

.closeButton
  width: 100%
  height: 56px
  +base-button-text
  color: #ffffff
  background: #3894FB

  &:disabled
    background: var(--color-background-gray-150)

.radioGroup
  margin-left: -66px
  margin-top: 28px

.radioGroupTariff
  display: flex
  flex-direction: column
  align-items: start
  margin-left: 84px
  margin-top: 18px

.labelRadio
  cursor: pointer
  height: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center


.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

    & .buttonRadioGroupError
      background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.statusGroup
  width: 100%
  max-width: 560px
  margin: 0 auto
  display: flex

.statusGroup
  margin-top: 40px

.correctionGroup
  width: 100%
  max-width: 560px
  margin: 0 auto

.correctionTitle
  padding-bottom: 12px
  +base-title

.subTitle
  +base-subtitle
  +medium-spacing

.mediumSpacing
  +medium-spacing

.blockContent
  &:last-child
    margin-bottom: 40px

  &:not(:last-child)
    +base-spacing

.uploadContainer
  +questionnaries-container
  +step-spacing

.gridFluid
  +grid-fluid

.noteContainer
  +base-spacing

  p
    +small-text

  a
    +base-link

.subtitle
  +base-subtitle
  +medium-spacing

  span
    padding-right: 8px

.description
  +small-text
  +medium-spacing
  padding-left: 24px

  a
    +base-link

.previewContainer
  display: flex
  align-items: center
  padding: 0 24px
  +medium-spacing

.previewLink
  display: flex
  align-items: center

.previewImage
  width: 80px
  height: 80px

  img
    width: 100%

.previewName
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 18px
  line-height: 24px
  color: #0E0F0F
  text-decoration: underline
  margin: 0 8px 0 16px

.previewReset
  width: 24px
  height: 24px
  background-image: url('~icons/basic/trash.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer

.reviewDocument
  padding-bottom: 24px

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 8px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #000

.actionContent
  width: 560px
  margin: 0 auto
</style>
