<template>
  <div>
    <summary-connection-application
      :connection-application="connectionApplication"
      :connection-application-xml="xmlConnectionApplicationDocument"
      :only-microgenerator="onlyMicrogenerator"
      :document-id="sourceDocumentId"
    />

    <div :class="$style.stepContainer">
      <div>
        <h3>Данные из заявки на технологическое присоединение будут перенесены в профиль и обновят текущие данные
          профиля.</h3>
        <p style="margin: 16px 0">
      <span>
        <input v-model="agreedWithCondition" type="checkbox" @change="onAgreedChanged"/>
      </span>
          &nbsp;
          <span style="color: red">*</span> Я даю
          <a
            :href="policyLink"
            target="_blank"
          >
            согласие на обработку моих персональных данных </a> и ознакомлен(а) с

          <a href="/policy-new/personal-data-processing-policy.pdf" target="_blank">
            политикой обработки персональных данных
          </a>
        </p>
      </div>
      <p v-if="connectionApplication.gridCompany.gridCompanyName?.onPlatform === false" class="errorText">
        Выбранная вами сетевая компания не является участником проекта «Мой свет». Будет сформирована заявка для
        самостоятельного предоставления в {{ connectionApplication.gridCompany.gridCompanyName.label }}.
      </p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <button :class="$style.button" :disabled="isWaitingSubmit || !agreedWithCondition" role="submit" type="submit"
              @click="submitNotification">
        {{
         'ОТПРАВИТЬ ЗАЯВКУ'
        }}
      </button>
    </div>
    <personal-data-agreement-dialog
      :open-modal="privacyPolicyModalOpened"
      :user="{
        fullName: {
          name: connectionApplication.consumer.firstName,
          surname: connectionApplication.consumer.familyName,
          patronymic: connectionApplication.consumer.patronymic,
        }
      }"
      :user-type="policyUserType"
      @close-modal="privacyPolicyModalOpened = false"
    />
  </div>
</template>

<script>
import SummaryConnectionApplication from './Summary';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import connectionApplicationData from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import { getCurrentDate } from 'lib/utils/date';
import PersonalDataAgreementDialog from 'components/PersonalDataAgreement/PersonalDataAgreementDialog.vue';

const COUNT_REQUEST_INTERVAL = 3000;

export default {
  name: 'Submit',
  components: {
    PersonalDataAgreementDialog,
    SummaryConnectionApplication,
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    isAdditionalOwner: {
      type: Boolean,
      default: false,
    },
    sourceDocumentId: {
      type: String,
      default: () => undefined,
    },
  },
  created() {
    this.xmlConnectionApplicationDocument = this.createXmlConnectionApplicationDocument();
  },
  data() {
    return {
      submitError: '',
      agreedWithCondition: false,
      intervalId: null,
      isWaitingSubmit: false,
      isNotificationModal: false,
      privacyPolicyModalOpened: false,
      xmlConnectionApplicationDocument: '',
    };
  },
  computed: {
    isUserAgreedWithCondition() {
      return this.agreedWithCondition;
    },
    policyLink() {
      switch (this.getAuthorizationType) {
        case 'GENERAL_DIRECTOR':
          return '/policy-new/general-director-policy.pdf';
        case 'INDIVIDUAL':
          return '/policy-new/individual-policy.pdf';
        case 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return '/policy-new/without-rights-policy.pdf';
        case 'EMPLOYEE_WITH_SIGNING_RIGHTS':
          return '/policy-new/with-rights-policy.pdf';
        case 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS':
          return '/policy-new/counterparty-with-rights-policy.pdf';
        case 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return '/policy-new/counterparty-without-rights-policy.pdf';
        default:
          return '/policy-new/user-policy.pdf';
      }
    },
    policyUserType() {
      if (this.connectionApplication.consumer.company?.inn?.length) return 'EMPLOYEE_WITH_SIGNING_RIGHTS';
      return 'INDIVIDUAL';
    },
    hasStoppedInterval() {
      // TODO revert when server authorizations is fixed
      // return this.$validate.isNotNull(this.getUser.authorizations);
      const account = this.getAccount?.profile;
      console.log('hasStoppedInterval this.getAccount', account);
      return this.$validate.isNotNull(account) && this.$validate.isNotUndefined(account);
    },
    ...mapGetters('user', ['getAccount', 'getAuthorization', 'getAuthorizationType']),
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    onAgreedChanged() {
      if (this.agreedWithCondition) this.privacyPolicyModalOpened = true;
    },
    closeNotification() {
      this.isNotificationModal = false;
    },
    async submitNotification() {
      if (!this.isUserAgreedWithCondition) return;
      this.isNotificationModal = false;
      await this.handleSubmit();
    },
    async handleSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      try {
        await this.documentUpload({ xml: this.xmlConnectionApplicationDocument });
        let gridCompany = this.connectionApplication.gridCompany.gridCompanyName.value;

        if (this.connectionApplication.gridCompany.gridCompanyName.label === 'Иное') {
          gridCompany = this.connectionApplication.gridCompany.otherGridCompany;
        }
        this.authorizationsRequestByInterval(gridCompany);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${this.submitError}`,
        });
        this.isWaitingSubmit = false;
      }
    },
    createXmlConnectionApplicationDocument() {
      const privacyPolicy = {
        agreedWithPersonalDataAgreement: localStorage.getItem('agreedWithPersonalDataAgreement'),
        agreedWithUserAgreement: localStorage.getItem('agreedWithUserAgreement'),
      };
      return connectionApplicationData.generateXml(this.connectionApplication, getCurrentDate('YYYY-MM-DD'), this.sourceDocumentId, this.onlyMicrogenerator, privacyPolicy);
    },
    authorizationsRequestByInterval(companyName) {
      this.intervalId = setInterval(async () => {
        if (this.hasStoppedInterval) {
          this.clearSuppliers();
          connectionApplicationData.clearData();

          clearInterval(this.intervalId);

          this.isWaitingSubmit = false;
          this.$emit('close');
          this.$router.push('/cabinet/objects');

          await this.setUser();
          if (this.sourceDocumentId) {
            this.$nextTick(() => this.vueShowNotification(
              'ИЗМЕНЕНИЯ СОХРАНЕНЫ',
              `<p>Внесённые изменения сохранены и отправлены в сетевую компанию. Сотрудник ${companyName} ещё раз всё проверит и сообщит вам решение по заявке в течение трёх рабочих дней</p>`,
            ));
          } else {
            if (this.connectionApplication.gridCompany.gridCompanyName.onPlatform) {
              this.$nextTick(() => this.vueShowNotification('ЗАЯВКА УСПЕШНО СОЗДАНА', `<p>и отправлена в <a href="#">${companyName}</a> на рассмотрение. В разделе <a href="/cabinet/tasks">Заявки</a> вашего личного кабинета вы сможете следить за статусом выполнения работ.</p><p>Мы пришлём вам на телефон или электронную почту, указанную при регистрации, сообщение c результатами рассмотрения и необходимые документы в течение трёх рабочих дней.</p>`));
            } else {
              this.$nextTick(() => this.vueShowNotification(
                'ЗАЯВКА УСПЕШНО СФОРМИРОВАНА',
                `<p>В разделе <a href="/cabinet/documents">Документы</a> вашего личного кабинета вы сможете распечатать заявление для предоставления в <a href="#">${companyName}</a>.</p>`,
              ));
            }
          }
        } else {
          await this.setUser();
        }
      }, COUNT_REQUEST_INTERVAL);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setUser']),
    ...mapActions('dataLists', ['clearSuppliers']),
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.button
  width: 100%
  padding: 16px
  position: relative
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 24px
  color: #FFF
  background-color: #2F82DF

.button:disabled
  background-color: #ACB0B5
</style>
