<template>
  <page title="Список НСИ">
    <div class="guide-list">
      <v-table-new
        v-model="transformedDirectories"
        :action-block-items="actionBlockItems"
        :loading="loading"
        :on-filter-change="onFilterChange"
        :on-load-all-values="loadAllFiltered"
        :on-pagination-change="onPaginationChange"
        :page-out="directoriesPage.pageOut"
        :template="template"
        excel-report-file-name="Список НСИ"
        @delete="onDelete"
        @edit="onEdit"
        @on-cell-click="cellClickHandler"
      />
      <modal
        v-if="isDisplayAddDialog"
        :body-style="{
        padding: '24px 0',
      }"
        :header-style="{
        display: 'flex',
        justifyContent: 'center',
      }"
        :is-backdrop="true"
        :is-btn-close-visible="false"
        :modal-style="{
        borderRadius: '16px',
        padding: '24px',
      }"
        class="delete-modal"
      >
        <template #header>
          <span>{{ directoryId.length === 0 ? 'Создание' : 'Редактирование' }} справочника</span>
        </template>
        <template #body>
          <div>
            <v-input
              v-model="directoryName"
              rules="required"
              title="Название*"
            />

            <div :style="isErrorDesc ? 'color: red' : ''" class="guide-list__new-directory-title">Описание*</div>
            <textarea
              v-model="directoryDesc"
              :style="isErrorDesc ? 'border: 1px solid red' : 'border: 1px solid #DEE2E6'"
              class="guide-list__new-directory-desc"
              rows="5"
              @focusout="directoryDesc.length === 0 ? isErrorDesc = true : isErrorDesc = false"
            />
            <span v-show="isErrorDesc" class="errorText">Поле Описание обязательно для заполнения</span>

            <div class="guide-list__fields-title">Список полей*</div>

            <div class="guide-list__fields">
              <draggable :list="fields" handle=".handle" tag="ul" @end="setOrderField">
                <div
                  v-for="(field, index) in fields"
                  :key="index"
                  class="guide-list__field"
                  style="margin-bottom: 10px;"
                >

                  <div class="guide-list__field-handle handle">
                    <img src="@/assets/icons/move.svg">
                  </div>

                  <v-input v-model="field.name" class="guide-list__field-name"/>
                  <v-select
                    v-model="field.type"
                    :options="typesField"
                    height="40"
                    new-style
                    placeholder="Тип поля"
                    style="width: 135px"
                  />
                  <img alt="удалить" class="guide-list__field-delete" src="@/assets/icons/user/delete-member.svg"
                       @click="deleteField(index)">
                </div>

                <v-button
                  class="guide-list__fields-add"
                  variant="outlined"
                  @click="addField"
                >
                  + Добавить поле
                </v-button>
              </draggable>
            </div>

          </div>
        </template>
        <template #footer>
          <horizontal-progress-bar v-if="isWaitingSubmit" class="guide-list__progress-bar"/>
          <div v-if="submitError.length > 0" class="errorText">
            {{ submitError }}
          </div>
          <div style="display: flex; justify-content: space-around;">
            <v-button
              variant="outlined"
              @click="isDisplayAddDialog = false"
            >
              Отменить
            </v-button>
            <v-button
              :disabled="!isValidAddNew"
              @click="onAddDirectory"
            >
              {{ directoryId.length === 0 ? 'Создать' : 'Сохранить' }}
            </v-button>
          </div>
        </template>
      </modal>
      <modal
        v-if="isDisplayDeleteDialog"
        :header-style="{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '18px',
        }"
        :is-backdrop="true"
        :is-btn-close-visible="false"
        :modal-style="{
          borderRadius: '16px',
          padding: '24px',
        }"
        class="delete-modal">
        <template #header>
          <span>Удалить справочник "{{ deleteDirectoryName }}"?</span>
        </template>
        <template #footer>
          <horizontal-progress-bar v-if="isWaitingSubmit" class="guide-list__progress-bar"/>
          <div v-if="submitError.length > 0" class="errorText">
            {{ submitError }}
          </div>
          <div style="display: flex; justify-content: space-around;">
            <v-button
              variant="outlined"
              @click="isDisplayDeleteDialog = false"
            >
              Отменить
            </v-button>
            <v-button
              variant="outlined-red"
              @click="onDeleteDirectory"
            >
              Удалить
            </v-button>
          </div>
        </template>
      </modal>
    </div>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import { Modal, Page, VButton, VInput, VSelect, VTableNew } from '@/components';
import { convertDictionaryKind, getDictionaryKindValueLabelList } from 'lib/utils/formatXmlType';
import { generateDeleteDirectory, generateSaveDirectory } from 'views/private/Directories/xml';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  components: {
    VTableNew,
    Modal,
    VButton,
    VInput,
    HorizontalProgressBar,
    VSelect,
    Page,
    draggable,
  },
  data() {
    return {
      loading: false,
      actionBlockItems: [
        {
          id: 1,
          title: 'Создать справочник',
          method: this.addDirectory,
        },
      ],
      isDisplayAddDialog: false,
      directoryName: '',
      directoryDesc: '',
      directoryId: '',
      deleteDirectoryName: '',
      deleteDirectoryId: '',
      isDisplayDeleteDialog: false,
      fields: [],
      isErrorDesc: false,
      directoryRoutes: {
        'Справочник сетевых компаний': 'utilities',
        'Справочник сбытовых компаний': 'retailers',
        'Справочник контрагентов': 'contractors',
        'Справочник индивидуальных тарифов': 'individual-tariffs',
        'Справочник марок приборов учета': 'guide-pu',
        'Справочник шаблонов документов': 'document-templates',
        'Справочник полномочий': 'permissions-registry',
        'Справочник моделей трансформаторов тока': 'guide-tt',
        'Справочник моделей трансформаторов напряжения': 'guide-tn',
        'Реестр ИСУ': 'guide-isu',
        'Справочник подстанций': 'substations',
      },
      directoriesPage: {},
      pagination: initialPagination,
      filter: {},
      isWaitingSubmit: false,
      submitError: '',
      typesField: [
        {
          label: 'Текстовый',
          value: 'TEXT',
        },
        {
          label: 'Числовой',
          value: 'NUMBER',
        },
        {
          label: 'Дата',
          value: 'DATE',
        },
        {
          label: 'Логический',
          value: 'BOOLEAN',
        },
      ],
    };
  },
  computed: {
    directories() {
      return this.directoriesPage.list ?? [];
    },
    transformedDirectories() {
      if (this.directories.length === 0) return [];

      return this.directories.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Наименование справочника',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'Описание справочника',
            key: 'description',
            sort: {},
            filter: {},
          },
          {
            label: 'Тип справочника',
            key: 'kind',
            sort: {},
            filter: { type: 'select', values: getDictionaryKindValueLabelList() },
          },
          {
            key: 'actionButton',
            type: 'editDeleteButtons',
            customCheck: (item) => item.kindKey !== 'LOCAL_CLASSIFIER',
          },
        ],
      };
    },
    isValidAddNew() {
      return this.directoryName.length > 0 && this.directoryDesc.length > 0 && this.fields.filter((item) => item.name.length === 0 || !item.type).length === 0;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.directoriesPage = await this.getGuideList({
          filter: this.filter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    addDirectory() {
      this.directoryName = '';
      this.directoryDesc = '';
      this.directoryId = '';
      this.fields = [];
      this.isDisplayAddDialog = true;
      this.isErrorDesc = false;
    },
    async onAddDirectory() {
      this.isWaitingSubmit = true;
      this.submitError = '';

      const xml = generateSaveDirectory({
        id: this.directoryId,
        name: this.directoryName,
        desc: this.directoryDesc,
        fields: this.fields,
      });

      try {
        await this.documentUpload({ xml: xml });

        this.isDisplayAddDialog = false;
        this.loadData();

        this.$store.state.cabinet.menuKeyUpdate++;
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async onDeleteDirectory() {
      this.isWaitingSubmit = true;
      this.submitError = '';

      const xml = generateDeleteDirectory(this.deleteDirectoryId);

      try {
        await this.documentUpload({ xml: xml });

        this.isDisplayDeleteDialog = false;
        this.loadData();

        this.$store.state.cabinet.menuKeyUpdate++;
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    cellClickHandler(cell) {
      if (cell.row.kindKey === 'LOCAL_CLASSIFIER') {
        this.$router.push({
          name: 'cabinet.directory',
          params: {
            id: cell.row.id,
            name: cell.row.name,
            fields: cell.row.fields,
          },
        });
      }
    },
    onEdit(cell) {
      this.directoryName = cell.row.name;
      this.directoryDesc = cell.row.description;
      this.directoryId = cell.row.id;
      this.fields = cell.row.fields?.map((it) => ({ ...it, type: it.type })) ?? [];
      const sortedFiledsByOrder = this.fields.sort((a, b) => a.order - b.order);
      this.fields = [...sortedFiledsByOrder];
      this.isDisplayAddDialog = true;
      this.isErrorDesc = false;
    },
    onDelete(cell) {
      this.deleteDirectoryName = cell.row.name;
      this.deleteDirectoryId = cell.row.id;
      this.isDisplayDeleteDialog = true;
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        ...it,
        id: it.id,
        name: it.name,
        description: it.description,
        kind: convertDictionaryKind(it.kind),
        kindKey: it.kind,
      };
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const directories = await this.getGuideList({
          filter: this.filter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.directoriesPage.pageOut.totalElements,
          },
        });

        if (!directories?.list) {
          return [];
        }

        return directories.list.map(this.transformed);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    setOrderField() {
      this.fields.forEach((field, i) => {
        field.order = i;
      });
    },
    addField() {
      this.fields.push({
        name: '',
        order: this.fields.length,
      });
    },
    deleteField(index) {
      this.fields.splice(index, 1);
    },
    ...mapActions('dataLists', ['getGuideList']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
.guide-list {
  margin-right: 10px;

  &__new-directory-title {
    font-size: 14px;
    color: #71757a;
    margin-top: 24px;
    margin-bottom: 6px;
  }

  &__new-directory-desc {
    width: 100%;
  }

  &__fields-title {
    font-size: 14px;
    color: #71757a;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 400px;
    //overflow-y: scroll;

    &::-webkit-scrollbar-button {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 0;
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a19f9f;
    }

    &::-webkit-resizer {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &-add {
      width: 160px;
      margin: 0 auto;
    }
  }

  &__field {
    display: flex;
    gap: 12px;
    align-items: flex-end;

    &-name, &-default {
      flex-grow: 3;
    }

    &-delete {
      cursor: pointer;
      margin-bottom: 8px;
      margin-right: 12px;
    }

    &-handle {
      cursor: pointer;
      margin-bottom: 4px;
    }
  }

  &__progress-bar {
    margin-bottom: 12px;
  }
}
</style>
