<template>
  <cabinet-tp is-tpo/>
</template>

<script>
import CabinetTp from 'views/private/CabinetTp';


export default {
  name: 'CabinetTpo',
  components: {
    CabinetTp,
  },
};
</script>

<style lang="scss" scoped>

.container {
  width: 100%;
  padding-right: 20px;
}
</style>
