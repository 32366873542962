<template>
  <div class="container">
    <navigation-bar
      :current-step="currentStep"
      :home="vueRouterNavigationbarHome.label"
      :steps="steps"
      @open="openStep"
    />

    <div class="content">
      <component
        :is="steps[currentStep].component"
        v-model="readings"
        :xml-type="xmlType"
        @on-submit-successful="onSubmitSuccessfulHandler"
        @kind="onKindUpdate"
      />
    </div>

    <div class="contentFooter">
      <v-button v-show="displayBack" class="back" type="button" variant="outlined-colored" @click="back">
        <chevron-left />
        <span>Назад</span>
      </v-button>
      <v-button class="delete" type="button" variant="outlined-red" @click="displayDeleteDialog = true">
        <delete />
        <span>Удалить черновик</span>
      </v-button>
      <v-button v-show="displayNext" class="next" type="button" @click="next">
        <span>Далее</span>
        <arrow-right />
      </v-button>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p class="deleteText">
        Удалить черновик?
      </p>
      <div class="submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft" />
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
      </div>
    </dialog-template>

    <dialog-template v-model="isErrorsModalShow" center>
      <div class="table-wrp">
        <table class="table">
          <thead>
          <th>№</th>
          <th>Поле</th>
          <th>Значение</th>
          <th>Ошибка</th>
          </thead>
          <tr v-for="(error, i) in errors" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ error.humanReadableName }}</td>
            <td>{{ error.value }}</td>
            <td>{{ error.errorText }}</td>
          </tr>
        </table>
      </div>
      <div>
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="isErrorsModalShow = false" />
      </div>
    </dialog-template>

  </div>
</template>

<script>
// components
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';
import Form from './steps/Form.vue';
import XML from './steps/XML.vue';
import { VButton } from 'components';

// icons
import ArrowRight from 'atoms/icons/arrows/ArrowRight';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import Delete from 'atoms/icons/action/Delete.vue';

// dialog (modal)
import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

// utils methods
import { getCurrentDate } from 'lib/utils/date';


export default {
  components: {
    VButton,
    NavigationBar,
    Form,
    XML,
    // icons
    ArrowRight,
    Delete,
    ChevronLeft,
    // modal
    DialogTemplate,
    SubmitTaskDetails,
  },
  props: {
    xmlType: {
      type: String,
      required: true,
      validator: (value) => value === 'input_transit' || value === 'consumer',
    },
    vueRouterNavigationbarHome: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (!this.$route.params.measurementKind) return;
    if (this.$route.params.measurementKind === 'transmission') {
      this.readings.measurementKind.value = 'TRANSFER';
    } else {
      this.readings.measurementKind.value = this.$route.params.measurementKind.toUpperCase();
    }
  },
  data() {
    return {
      steps: [
        {
          component: 'Form',
          stepNumber: {
            value: 1,
            from: 2,
            title: () => 'Ручной ввод показаний',
          },
        },
        {
          component: 'XML',
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Сформированные XML файлы',
          },
        },
      ],
      readings: {
        factoryNumber: {
          humanReadableName: 'Заводской номер',
          errorText: 'Обязательное поле.',
          value: null,
          validationMethod: (x) => x !== null && x !== '',
        },
        T1: {
          humanReadableName: 'Показание T1',
          errorText: 'Обязательное поле. Формат ввода: число.',
          value: null,
          validationMethod: (x) => x !== null && x !== '',
        },
        verificationDate: {
          humanReadableName: 'Дата поверки',
          errorText: 'Обязательное поле. Формат ввода: дата.',
          value: '',
          validationMethod: (x) => x !== '',
        },
        currentDate: {
          humanReadableName: 'Текущая дата',
          errorText: 'Формат ввода: дата.',
          value: getCurrentDate('YYYY-MM-DD'),
          // eslint-disable-next-line no-unused-vars
          validationMethod: (x) => true,
        },
        measurementKind: {
          humanReadableName: 'Тип точки',
          value: this.$route.params.measurementKind === 'transmission'
            ? 'TRANSFER'
            : this.$route.params.measurementKind,
          errorText: 'Обязательное поле. Выберите тип точки из выпадающего списка.',
          validationMethod: (x) => x !== '' || undefined,
        },
      },
      currentStep: 0,
      displayDeleteDialog: false,
      errors: [],
      isErrorsModalShow: false,
    };
  },
  computed: {
    displayBack() {
      return this.currentStep > 0;
    },
    displayNext() {
      return this.currentStep < this.steps.length - 1;
    },
    displayDelete() {
      return this.currentStep === 0;
    },
  },
  methods: {
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
    },
    close() {
      if (this.$route.path.indexOf(this.vueRouterNavigationbarHome.pathFrom) !== -1 && this.$route.params.measurementKind) {
        this.$router.push('/cabinet/reading-sheets');
        return;
      }
      this.$router.push('/cabinet/transport-meter-input-transit');
      this.$emit('close');
    },
    next() {
      // TODO: refactor, nested condition - not good
      if (this.currentStep === 0) {
        const validation = this.validationReadings(this.readings);

        if (validation.isValid) {
          this.currentStep += 1;
          return;
        }

        this.errors = validation.errors;
        this.isErrorsModalShow = true;

        return;
      }

      this.currentStep += 1;
    },
    onKindUpdate(value) {
      this.readings.measurementKind.value = value;
    },
    onSubmitSuccessfulHandler() {
      this.close();
    },
    back() {
      this.currentStep -= 1;
    },
    deleteDraft() {
      this.close();
    },
    validationReadings(readings) {
      const errors = [];
      const readingsArray = [];

      // TODO: refactor this, excess "for in" loop
      for (const key in readings) {
        if (readings.hasOwnProperty.call(readings, key)) {
          readingsArray.push(readings[key]);
        }
      }

      readingsArray.forEach((reading) => {
        const isValid = reading.validationMethod(reading.value);

        if (!isValid) {
          errors.push(reading);
        }
      });

      return {
        isValid: errors.length === 0 && readingsArray.length !== 0,
        errors,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .content {
    height: 80vh;
    flex-grow: 2;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .contentFooter {
    height: 48px;
    display: flex;
    justify-content: space-between;

    .delete {
      fill: #EB5757;
    }
  }

  .back, .next, .delete {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 19px;

    svg {
      fill: #FFFFFF;
    }
  }

  .back {
    background: #ffffff;

    svg {
      fill: #2F82DF;
    }
  }

  .next {
    svg {
      margin-left: 13px;
    }
  }

  .delete {
    svg {
      margin-right: 7px;
      fill: #EB5757;
    }
  }
}

// scope для dialog-template (не внтури .container)
.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.table {
  width: 100%;
  text-align: left;
  font-size: 16px;
  border-collapse: collapse;

  td, th {
    border: 1px solid;
    padding: 5px;
  }

  &-wrp {
    padding: 35px;
    width: 100%;
  }
}
</style>
