<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">
      <span>{{ stepNumber.title() }}</span>
    </p>

    <p :class="$style.smallTitle">Сформированный XML файл</p>

    <div :class="$style.attachment">
      <a :class="{[$style.fileAttachment]:true, [$style.disabled]: xml.length === 0}" download="TariffsOrder.xml"
         :href="xmlBlob">
        <img alt="" height="80" src="~@/assets/icons/file/attachment.svg" width="80" />
        <span>TariffsOrder.xml</span>
      </a><br>
      <horizontal-progress-bar v-if="xml.length === 0" />
    </div>

    <div :class="$style.submit">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit || xml.length === 0" title="Загрузить" @submit="onSubmit" />
    </div>
  </div>
</template>

<script>

import { getFileUrl } from 'lib/utils/files';
import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';
import { generateUtilityIndividualTariffs } from './xml.js';

export default {
  name: 'DownloadReport',
  components: {
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
  },
  props: {
    stepNumber: Object,
    utilityIndividualTariff: Object,
  },
  created() {
    this.getXmlBlob();
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
      xml: '',
      xmlBlob: undefined,
    };
  },
  methods: {
    async onSubmit() {
      this.isWaitingSubmit = true;

      try {
        const result = await this.documentUpload({ xml: this.xml });

        if (!result) {
          this.isWaitingSubmit = false;
          return;
        }
        this.$emit('deleteDraft');

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.isWaitingSubmit = false;
        this.submitError = errorData.message;
        console.log('submit error: ', error);
      }
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    async getXmlBlob() {
      try {
        this.xml = generateUtilityIndividualTariffs(
          this.utilityIndividualTariff,
        );
      } catch (e) {
        console.log('Error generate utilityIndividualTariff', e);
        return;
      }

      const blob = new Blob([this.xml], { type: 'text/xml' });

      this.xmlBlob = window.URL.createObjectURL(blob);
    },
    ...mapActions('cabinet', ['addTestTask', 'clearTestTask', 'setWorkflows']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

.submit
  margin-top: 77px

.attachment
  +base-subtitle

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.errorText
  +small-text
  margin: 4px
  color: red

.disabled
  pointer-events: none
  cursor: default
  color: #979CA2

.waitNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

</style>
