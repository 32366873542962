<template>
  <div v-if="spoiler === 0">
    <div
      v-for="option in value"
      :key="option.key ?? option.value"
      class="cell-list-option" :title="option.tooltip ?? ''">{{ option.value }}
    </div>
  </div>
  <div v-else>
    <div
      v-for="(option, index) in value"
      :key="option.key ?? option.value" class="cell-list-option">
      <span v-if="index < spoiler" :title="option.tooltip ?? ''">{{ option.value }}</span>
      <span v-if="index === spoiler && !spoilerOpen" @click="spoilerOpen = true">...</span>
      <span v-if="index > spoiler && spoilerOpen" :title="option.tooltip ?? ''">{{ option.value }}</span>
    </div>
    <div v-if="spoilerOpen" class="cell-list-option-hide" @click="spoilerOpen = false">
      Скрыть
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: { type: Array,   default: () => [] },
    spoiler: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spoilerOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.cell-list-option {
  margin-bottom: 6px;
  line-height: 14px;
}
.cell-list-option-hide {
  color: #2F82DF;
  font-size: 14px;
}
</style>
