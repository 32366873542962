<template>
  <div>
    <v-button
        variant="filled"
        :disabled="disabled"
        @click="selectFile"
    >
      {{ label }}
    </v-button>
    <input
        ref="upload"
        style="display: none"
        type="file"
        :accept="accept"
        @change="handleInput($event)"
    >
  </div>
</template>
<script>
import VButton from 'components/VButton/VButton';

export default {
  name: 'SelectFileButtonDialog',
  components: {
    VButton,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Добавить из Excel',
    },
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  methods: {
    selectFile() {
      this.$refs.upload.click();
    },
    async handleInput(event) {
      const files = [...event.target.files];

      if (!files.length) {
        return;
      }

      const filesData = await this.getFiles(files);
      this.$emit('input', filesData);

      if (event.target.files) {
        event.target.value = null;
      }
    },
    getFile(file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = (error) => reject(error);
        reader.onload = () => resolve(reader.result.replace(RegExp('data:.*;base64,'), ''));
        reader.readAsDataURL(file);
      });
    },

    getFiles(files) {
      return Promise.all(files.map(async (file) => ({
        name: file.name,
        source: URL.createObjectURL(file),
        code: await this.getFile(file),
        type: file.type,
      })));
    },
  },
};

</script>

<style scoped>

</style>
