<template>
  <v-text-field
    v-if="simple"
    ref="name"
    v-model="name"
    v-mask="mask"
    :append-icon="name ? 'mdi-close' : ''"
    :disabled="isDisabled"
    :error="!isNameValid"
    :prepend-inner-icon="prependInnerIcon"
    :readonly="readonly"
    :rules="[rules.time, rules.requiredForNull, rules.onlyNumbers, rules.nonZero, rules.onlyIntegerNumbers, rules.maxValue, rules.minValue, rules.phoneNumber]"
    dense
    outlined
    required
    @focusout="validate(name)"
    @input="onChangeName"
    @click:append="onClearName"
  ></v-text-field>
  <v-row v-else>
    <v-col>
      <div>
        <v-label
          :class="{
            'error-label': !isNameValid,
          }"
        >{{ internalTitle }}
        </v-label
        >
      </div>
      <v-text-field
        ref="name"
        v-model="name"
        v-mask="mask"
        :append-icon="name && !isDisabled ? 'mdi-close' : ''"
        :disabled="isDisabled"
        :error="!isNameValid"
        :prepend-inner-icon="prependInnerIcon"
        :readonly="readonly"
        :rules="[rules.time, rules.requiredForNull, rules.onlyNumbers, rules.nonZero,rules.onlyIntegerNumbers, rules.maxValue, rules.minValue, rules.phoneNumber, ...customRules]"
        dense
        outlined
        required
        @focusout="validate(name)"
        @input="onChangeName"
        @click:append="onClearName"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>

export default {
  model: {
    prop: 'text',
    event: 'change',
  },
  props: {
    title: {
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isOnlyNumbers: {
      type: Boolean,
      default: false,
    },
    isOnlyIntegerNumbers: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    isTime: {
      type: Boolean,
      default: false,
    },
    text: {
      default: '',
    },
    nonZero: {
      type: Boolean,
      default: false,
    },
    max: {
      default: null,
    },
    min: {
      default: null,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    prependInnerIcon: {
      required: false,
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNameValid: true,

      rules: {
        nonZero: (value) => {
          if (this.nonZero) {
            return value !== '0' || 'Нельзя вводить 0';
          }
          return true;
        },
        requiredForNull: (value) => (this.isRequired
          ? !!value || 'Поле обязательное'
          : true),
        onlyNumbers: (value) => {
          const pattern = /^\d+(\.\d+)?$/;
          if (this.isOnlyNumbers) {
            return pattern.test(value) || 'Можно вводить только цифры и .';
          }
          return true;

        },
        onlyIntegerNumbers: (value) => {
          const pattern = /^\d*$/;
          if (this.isOnlyIntegerNumbers) {
            return pattern.test(value) || 'Можно вводить только цифры';
          }
          return true;

        },
        phoneNumber: (value) => {
          const pattern = /^(\+7) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
          if (this.isPhoneNumber) {
            return pattern.test(value) || 'Номер телефона должен содержать 10 цифр';
          }
          return true;

        },
        time: (value) => {
          const pattern = /^(0[0-9]|[1-9][0-9])$/;
          if (this.isTime) {
            return (
              pattern.test(value) || 'Можно вводить от 00'
            );
          }
          return true;

        },
        maxValue: (value) => {
          if (this.max != null) {
            return value <= this.max || `Нельзя вводить больше ${this.max}`;
          }
          return true;
        },
        minValue: (value) => {
          if (this.min != null) {
            return value >= this.min || `Нельзя вводить меньше ${this.min}`;
          }
          return true;
        },

      },
    };
  },

  computed: {
    mask() {
      if (this.isPhoneNumber) return '+7 (###) ###-##-##';
      return undefined;
    },
    internalTitle() {
      if (!this.title) return;
      if (this.isRequired) return `${this.title}*`;
      return this.title;
    },
    name: {
      get() {
        if (this.isDate) {
          return this.formattedDate(this.text);
        }
        return this.text;

      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
  watch: {
    name() {
      this.validate(this.name);
    },
  },
  methods: {
    onClearName() {
      this.name = null;
      this.changeNameValid(false);
    },
    onChangeName(newVal) {
      this.name = newVal;
    },

    validate(newVal) {
      let result = true;
      for (const rulesKey in this.rules) {
        result = result && this.rules[rulesKey](newVal) === true;
      }
      this.changeNameValid(result);
    },

    changeNameValid(val) {
      this.isNameValid = val;
    },

    resetValidation() {
      this.isNameValid = true;
      this.$refs.name.resetValidation();
    },

    formattedDate(unixTimestamp) {
      const newDate = new Date(unixTimestamp * 1000);
      let date = newDate.getDate();
      date = date < 10 ? `0${date}` : date;
      let month = newDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      const newDateText = `${newDate.getFullYear()}-${month}-${date}`;
      if (newDateText == '1970-01-01') {
        return unixTimestamp;
      }
      return newDateText;

    },
  },
};
</script>
