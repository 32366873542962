<template>
  <v-form @submit="next">
    <div :class="$style.container">
      <div
        ref="navigationBar"
        :style="{
            position: 'sticky',
            top: '-16px',
            backgroundColor: '#ffffff',
            paddingLeft: '16px',
            zIndex: '100',
            width: navBarWidth,
            marginLeft: '-16px',
            paddingTop: '16px',
            marginTop: '-16px',
            marginBottom: '-8px',
            paddingBottom: '8px',
            display: 'flex',
            gap: '24px',
          }"
      >
        <navigation-bar
          :current-step="currentStep"
          home="Платёжные поручения"
          :steps="steps"
          @open="openStep"
        />
      </div>

      <div ref="content" :class="$style.content">
        <component
          :is="steps[currentStep].component"
          :step-number="steps[currentStep].stepNumber"
          :height-navigation-bar="heightNavigationBar"
          @close="close"
          @complete="complete"
          @next="next"
        />
      </div>

      <div
        :class="$style.contentFooter"
        :style="{ position: 'absolute', bottom: '0', width: footerWidth, }"
      >
        <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
          <chevron-left />
          <span>Назад</span>
        </v-button>
        <div :class="$style.center">&nbsp;</div>
        <v-button :class="$style.next" type="submit" :disabled="loading">
          <span v-show="displayNext">Далее</span>
          <span v-show="!displayNext">Создать</span>
          <arrow-right />
        </v-button>
      </div>

      <v-circle-loader v-if="loading" />
    </div>
  </v-form>
</template>

<script>
import GeneratePaymentOrder from './steps/GeneratePaymentOrder.vue';

import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ArrowRight from 'atoms/icons/arrows/ArrowRight.vue';
import { NavigationBar, VButton, VCircleLoader, VForm } from 'components';
import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'IndexGeneratePaymentOrder',
  components: {
    VCircleLoader,
    NavigationBar,
    VButton,
    VForm,
    ChevronLeft,
    ArrowRight,
    GeneratePaymentOrder,
  },
  async created() {
    if (!this.steps[this.currentStep]) {
      this.currentStep = 0;
    }
    window.addEventListener('resize', this.setElementsWidth);
  },
  mounted() {
    this.setElementsWidth();
  },
  updated() {
    this.heightNavigationBar = this.$refs.navigationBar.clientHeight;
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          component: 'GeneratePaymentOrder',
          isComplete: false,
          stepNumber: {
            value: 1,
            from: 1,
            title: () => 'Создание платёжного поручения',
          },
        },
      ],
      footerWidth: '0',
      navBarWidth: '0',
      heightNavigationBar: 24,
      loading: false,
    };
  },
  computed: {
    displayNext() {
      return this.currentStep < this.steps.length - 1;
    },
  },
  methods: {
    setElementsWidth() {
      this.navBarWidth = `${this.$refs.content.offsetWidth + 16}px`;
      this.footerWidth = `${this.$refs.content.offsetWidth}px`;
    },
    close() {
      this.$router.go(-1);
    },
    openStep(value) {
      if (value === -1) {
        this.close();
        return;
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
    },
    back() {
      if (this.currentStep === 0) {
        return this.$router.go(-1);
      }
      this.currentStep -= 1;
    },
    async next() {
      if (!this.displayNext) {
        this.loading = true;
        try {
          await this.generatePaymentOrderForServiceOrLossAct();
          return this.$router.push('/cabinet/payment-orders');
        } catch (error) {
          console.log('generate payment order', error);
          const errorData = HTTPError.network(error);
          return this.setNotification({ message: errorData.message });
        } finally {
          this.loading = false;
        }
      }
      this.currentStep += 1;
    },
    complete(isComplete) {
      this.steps[this.currentStep].isComplete = isComplete;
    },
    ...mapActions('paymentOrders', ['generatePaymentOrderForServiceOrLossAct']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.loader {
  position: relative;
  height: 50px;

  div:first-child {
    top: 50px;
  }
}

.content {
  padding-bottom: 60px;
  flex-grow: 2;
  margin: 16px 20px 20px 100px;
}

.contentFooter {
  height: 48px;
  display: flex;
  background-color: #ffffff;
  z-index: 100;
}

.back, .next, .delete, .save {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;

  svg {
    fill: #FFFFFF;
  }
}

.delete {
  margin-left: 16px;

  svg {
    path {
      fill: #EB5757
    }
  }

  span {
    margin-left: 7px;
  }
}

.center {
  flex-grow: 2;
}

.back {
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.next {
  margin-right: 36px;

  span {
    margin-right: 13px;
  }
}

.save {
  margin-right: 16px;

  span {
    margin-left: 7px;
  }
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.uploadingDraft {
  z-index: 100;
}
</style>
