<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="back">{{ params.fromTitle }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ toTitle }}</span>
    </p>

    <div :class="$style.content">
      <approve-document
        :id="$route.params.id"
        :completed-by-workflow="params.completed"
        :kind="params.to"
        :rejected-by-workflow="params.rejected"
        @back="back"
      />
    </div>

    <div :class="$style.contentFooter">
      <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import ApproveDocument from 'components/ApproveDocument/ApproveDocument';
import { VButton } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';

export default {
  name: 'IndexApproveDocument',
  components: {
    ChevronLeft,
    VButton,
    ApproveDocument,
  },
  created() {
    this.load();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === this.prevRoute?.path) {
      localStorage.removeItem('approve_document');
    }
    next();
  },
  data() {
    return {
      params: {},
      prevRoute: undefined,
    };
  },
  computed: {
    toTitle() {
      if (this.params.title) {
        return this.params.title;
      }

      switch (this.params.to) {
        case 'documentSigning': {
          return 'ПОДПИСАНИЕ ЗАГРУЖЕННОГО ДОКУМЕНТА';
        }
        case 'documentAgreement': {
          return 'СОГЛАСОВАНИЕ ЗАГРУЖЕННОГО ДОКУМЕНТА';
        }
        case 'documentUpdateInfo': {
          return 'ВВОД ИНФОРМАЦИИ О ДОКУМЕНТЕ';
        }
        default: {
          return '';
        }
      }
    },
  },
  methods: {
    load() {
      if (!localStorage.getItem('approve_document')) {
        localStorage.setItem('approve_document', JSON.stringify(this.$route.params));
        this.params = JSON.parse(localStorage.getItem('approve_document'));
      } else {
        this.params = JSON.parse(localStorage.getItem('approve_document'));
      }
    },
    back() {
      localStorage.removeItem('approve_document');
      if (this.$route.params.goBackOnComplete) {
        this.$router.back();
        return;
      }
      this.$router.push(`/cabinet/${this.params.from}`);
    },
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2
  padding-bottom: 50px

.navigationBar
  width: 100%
  background-color: #FFFFFF
  z-index: 1006
  // more than modal z-index
  +title-small-uppercase
  margin: 8px 16px 0 0
  padding-left: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px

.contentFooter
  position: absolute
  bottom: 0
  width: 100%
  background-color: #ffffff
  height: 48px
  display: flex
  z-index: 1000

.back
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  padding: 19px

  svg
    fill: #FFFFFF

.center
  flex-grow: 2

.back
  background: #ffffff

  svg
    path
      fill: #2F82DF
</style>
