import { setDraft } from '@/services/draft';
import { initState } from './index';
import Vue from 'vue';

// этот метод не мутация, а хелпер для установки значения черновиков
const setContractDraft = async (state) => {
  state.updated = true;
  await setContractDraftWithoutUpdate({ ...state });
};

const setContractDraftWithoutUpdate = async (state) => {
  // TODO костыль - в какой-то момент может прилететь пустой state, тогда ломается страница
  if (!state.action) {
    return;
  }

  if (!state.accountId) {
    const accountData = localStorage.getItem('account');

    if (accountData === null) {
      console.log('Error get account from store');
      return;
    }

    state.accountId = JSON.parse(accountData).id;
  }

  await setDraft(state.accountId, { ...state }, 'contractNew');
};

const SET_CONTRACT_MODE = (state, { action, type, contractType }) => {
  state.action = action;
  state.type = type;
  state.contractType = contractType;
};

const SET_CREATED_DATE = async (state, { date }) => {
  state.createdDate = date;
  await setContractDraftWithoutUpdate(state);
};

const SET_CONTRACT_SIDES = async (state, { sides }) => {
  state.contractSides = sides;
  await setContractDraftWithoutUpdate(state);
};

const ADD_REJECTED_CONTRACT = async (state, contract) => {
  const hasDuplicate = state.rejectedContracts.some((item) => item.contractId === contract.contractId && item.draftId === contract.draftId);

  if (!hasDuplicate) {
    state.rejectedContracts.push(contract);
    await setContractDraftWithoutUpdate(state);
  }
};



const SET_SELECTED_TEMPLATE = async (state, { selectedTemplate }) => {
  state.selectedTemplate = selectedTemplate;
  selectedTemplate.sides.forEach((templateSide) => {
    state.editableSides[templateSide.contractSide].company = {
      ...state.editableSides[templateSide.contractSide].company,
      ...templateSide.credentials[0],
    };
  });
  await setContractDraft(state);
};

const SET_SOURCE_CONTRACT_ID = async (state, { sourceContractId }) => {
  state.sourceContractId = sourceContractId;
  await setContractDraft(state);
};

const SET_SOURCE_CONTRACT_NUMBER = async (state, { sourceContractNumber }) => {
  state.sourceContractNumber = sourceContractNumber;
  await setContractDraft(state);
};

const SET_ADDITIONAL_AGREEMENT_TYPE = async (state, { additionalAgreementType }) => {
  state.additionalAgreementType = additionalAgreementType;
  await setContractDraft(state);
};

const SET_SOURCE_CONTRACT_DATE = async (state, { sourceContractDate }) => {
  state.sourceContractDate = sourceContractDate;
  await setContractDraft(state);
};

const SET_USER_EMPLOYEES = async (state, { userEmployees }) => {
  state.userEmployees = userEmployees;
  await setContractDraftWithoutUpdate(state);
};

const ADD_USER_EMPLOYEES = async (state, { userEmployees }) => {
  if (!state.userEmployees.find((stateMember) => stateMember.id === userEmployees.id)) {
    state.userEmployees.push(userEmployees);
    await setContractDraft(state);
  }
};

const REMOVE_USER_EMPLOYEES = async (state, { userEmployees }) => {
  state.userEmployees = state.userEmployees.filter((stateUser) => stateUser.id !== userEmployees.id);
  await setContractDraft(state);
};

const ADD_TO_USER_EMPLOYEES_CONTRACT_MEMBERS = async (state, { userEmployeesContractMember }) => {
  if (!state.userEmployeesContractMembers.find((stateMember) => stateMember.id === userEmployeesContractMember.id)) {
    state.userEmployeesContractMembers.push(userEmployeesContractMember);
    await setContractDraftWithoutUpdate(state);
  }
};

const SET_USER_EMPLOYEES_CONTRACT_MEMBERS = async (state, { userEmployeesContractMembers }) => {
  state.userEmployeesContractMembers = userEmployeesContractMembers;
  await setContractDraft(state);
};

const REMOVE_EMPLOYEES_CONTRACT_MEMBERS = async (state, { userEmployeesContractMember }) => {
  state.userEmployeesContractMembers = state.userEmployeesContractMembers.filter((stateMember) => stateMember.id !== userEmployeesContractMember.id);
  await setContractDraft(state);
};

const SET_SCAN_CONTRACT = async (state, { scanContract }) => {
  state.scanContract = scanContract;
  await setContractDraft(state);
};

const SET_ERRORS = (state, { errors }) => {
  state.errors = errors;
};

const RESET_STATE = (state) => {
  Object.assign(state, initState());
};

const SET_SIGNATORIES_SIDE = async (state, { signatoryEmployee, sideName }) => {
  state.editableSides[sideName].signatory = signatoryEmployee;
  await setContractDraft(state);
};

const SET_ACT_VERIFICATION_SIGNATORIES_SIDE = async (state, { signatoryEmployee, sideName }) => {
  state.editableSides[sideName].signatoryVerificationAct = signatoryEmployee;
  await setContractDraft(state);
};


const SET_COMPANY_SIDE = async (state, { selectedCompany, sideName }) => {
  state.editableSides[sideName].company = state.selectedTemplate.sides.find((it) => it.contractSide === sideName).credentials.find((it) => it.inn === selectedCompany);
  await setContractDraft(state);
};

const SET_BANK_REQUISITES_SIDE = async (state, { selectedBank, sideName }) => {
  Vue.set(state.editableSides[sideName].company, 'selectedBankRequisites', selectedBank);
  await setContractDraft(state);
};

const SET_BANK_NAME = async (state, { bankName, sideName }) => {
  state.editableSides[sideName].company.selectedBankRequisites.bankName = bankName;
  await setContractDraft(state);
};

const SET_SETTLEMENT_ACCOUNT = async (state, { settlementAccount, sideName }) => {
  state.editableSides[sideName].company.selectedBankRequisites.settlementAccount = settlementAccount;
  await setContractDraft(state);
};

const SET_CORRESPONDENT_ACCOUNT = async (state, { correspondentAccount, sideName }) => {
  state.editableSides[sideName].company.selectedBankRequisites.correspondentAccount = correspondentAccount;
  await setContractDraft(state);
};

const SET_BIK = async (state, { bik, sideName }) => {
  state.editableSides[sideName].company.selectedBankRequisites.bik = bik;
  await setContractDraft(state);
};

const SET_COMPANY_NAME = async (state, { name, sideName }) => {
  state.editableSides[sideName].company.name = name;
  await setContractDraft(state);
};
const SET_COMPANY_SHORT_NAME = async (state, { name, sideName }) => {
  state.editableSides[sideName].company.shortName = name;
  await setContractDraft(state);
};

const SET_COMPANY_KPP = async (state, { kpp, sideName }) => {
  state.editableSides[sideName].company.kpp = kpp;
  await setContractDraft(state);
};

const SET_COMPANY_OGRN = async (state, { ogrn, sideName }) => {
  state.editableSides[sideName].company.ogrn = ogrn;
  await setContractDraft(state);
};

const SET_COMPANY_OKPO = async (state, { okpo, sideName }) => {
  state.editableSides[sideName].company.okpo = okpo;
  await setContractDraft(state);
};

const SET_COMPANY_OKTMO = async (state, { oktmo, sideName }) => {
  state.editableSides[sideName].company.oktmo = oktmo;
  await setContractDraft(state);
};

const SET_COMPANY_LEGAL_ADDRESS = async (state, { legalAddress, sideName }) => {
  state.editableSides[sideName].company.legalAddress = legalAddress;
  await setContractDraft(state);
};

const SET_COMPANY_POST_ADDRESS = async (state, { postAddress, sideName }) => {
  state.editableSides[sideName].company.postAddress = postAddress;
  await setContractDraft(state);
};

const SET_CONTRACT_NUMBER = async (state, { number }) => {
  state.contractNumber = number;
  await setContractDraft(state);
};

const SET_CONCLUSION_DATE = async (state, { date }) => {
  state.conclusionDate = date;
  await setContractDraft(state);
};

const SET_EFFECTIVE_DATE = async (state, { date }) => {
  state.effectiveDate = date;
  await setContractDraft(state);
};

const SET_EXPIRES_DATE = async (state, { date }) => {
  state.expiresDate = date;
  await setContractDraft(state);
};

const SET_PROLONGATION = async (state, { prolongation }) => {
  state.prolongation = prolongation;
  await setContractDraft(state);
};

const SET_REGION = async (state, { region }) => {
  state.region = region;
  await setContractDraft(state);
};

const SET_IGK = async (state, { igk }) => {
  state.igk = igk;
  await setContractDraft(state);
};


const SET_PAYMENT_DATES = async (state, { dates }) => {
  state.paymentDates = dates;
  await setContractDraft(state);
};

function deleteAttachment(state, attachment) {
  state.attachments = state.attachments.filter((it) => !(
    it.name === attachment.name && it.kind === attachment.kind && it.departmentData?.id === attachment.departmentData?.id
  ));
}

const SET_ATTACHMENTS = async (state, { attachment }) => {
  deleteAttachment(state, attachment);

  state.attachments.push(attachment);
  await setContractDraft(state);
};

const SET_EDITED_SELECTED_TEMPLATE_XML = async (state, { xml }) => {
  state.editedSelectedTemplateXml = xml;
  await setContractDraft(state);
};

const SET_FINALLY_CONTRACT_XML = async (state, { xml }) => {
  state.finallyContractXml = xml;
  await setContractDraft(state);
};


const SET_CONTRACT_LAST_CHANGE = (state, { contractLastChange }) => {
  state.contractLastChange = contractLastChange;
};

const DELETE_ATTACHMENT = async (state, { attachment }) => {
  deleteAttachment(state, attachment);
  await setContractDraft(state);
};

const SET_ATTACHMENTS_LIST = async (state, { attachments }) => {
  state.attachments = attachments;
  await setContractDraft(state);
};

const SET_CONTRACT = (state, { contract }) => {
  Object.assign(state, contract);
};

const SET_CURRENT_STEP = async (state, { currentStep }) => {
  state.currentStep = currentStep;
  await setContractDraft(state);
};

const SET_DRAFT_ID = async (state, { draftId }) => {
  state.draftId = draftId;
  await setContractDraftWithoutUpdate(state);
};

const SET_UPDATED = async (state, { updated }) => {
  state.updated = updated;
};

const SET_EDIT_MODE = async (state, { editMode }) => {
  state.editMode = editMode;
};

const SET_USAGE_POINTS = async (state, { usagePoints }) => {
  state.usagePoints = usagePoints;
  await setContractDraft(state);
};


export default {
  ADD_REJECTED_CONTRACT,
  SET_CONTRACT_MODE,
  SET_CREATED_DATE,
  SET_CONTRACT_SIDES,
  SET_USER_EMPLOYEES,
  ADD_USER_EMPLOYEES,
  REMOVE_USER_EMPLOYEES,
  ADD_TO_USER_EMPLOYEES_CONTRACT_MEMBERS,
  REMOVE_EMPLOYEES_CONTRACT_MEMBERS,
  SET_SELECTED_TEMPLATE,
  SET_SCAN_CONTRACT,
  SET_USER_EMPLOYEES_CONTRACT_MEMBERS,
  SET_ERRORS,
  RESET_STATE,
  SET_SIGNATORIES_SIDE,
  SET_CONTRACT_NUMBER,
  SET_CONCLUSION_DATE,
  SET_EFFECTIVE_DATE,
  SET_EXPIRES_DATE,
  SET_PROLONGATION,
  SET_REGION,
  SET_IGK,
  SET_PAYMENT_DATES,
  SET_COMPANY_SIDE,
  SET_ACT_VERIFICATION_SIGNATORIES_SIDE,
  SET_ATTACHMENTS,
  DELETE_ATTACHMENT,
  SET_SOURCE_CONTRACT_ID,
  SET_SOURCE_CONTRACT_NUMBER,
  SET_ADDITIONAL_AGREEMENT_TYPE,
  SET_SOURCE_CONTRACT_DATE,
  SET_ATTACHMENTS_LIST,
  SET_CONTRACT,
  SET_CURRENT_STEP,
  SET_BANK_REQUISITES_SIDE,
  SET_BANK_NAME,
  SET_SETTLEMENT_ACCOUNT,
  SET_CORRESPONDENT_ACCOUNT,
  SET_BIK,
  SET_COMPANY_NAME,
  SET_COMPANY_SHORT_NAME,
  SET_COMPANY_KPP,
  SET_COMPANY_OGRN,
  SET_COMPANY_OKPO,
  SET_COMPANY_OKTMO,
  SET_COMPANY_LEGAL_ADDRESS,
  SET_COMPANY_POST_ADDRESS,
  SET_DRAFT_ID,
  SET_UPDATED,
  SET_EDIT_MODE,
  SET_EDITED_SELECTED_TEMPLATE_XML,
  SET_CONTRACT_LAST_CHANGE,
  SET_FINALLY_CONTRACT_XML,
  SET_USAGE_POINTS,
};
