<template>
  <div class="pagination" :style="customStyle">
    <div class="pagination__sizes">
      <v-table-pagination-button
        v-for="paginationSize in paginationSizes"
        :key="paginationSize"
        v-model="pagination.size"
        :size="paginationSize"
        :selected="paginationSize === pagination.size"
      />
    </div>
    <div class="pagination__pages">
      <div class="pagination__pages-text">
        Страница
        <span v-if="totalPages === 0">0 из 0</span>
        <span v-else>{{ pagination.page }} из {{ totalPages }}</span>
         (Всего элементов: {{ totalElements }})
      </div>
      <v-table-pagination-button
        v-for="(paginationPagesButton, index) in paginationPagesButtons"
        :key="paginationPagesButton + index"
        v-model="pagination.page"
        :size="paginationPagesButton"
        :selected="paginationPagesButton === pagination.page"
      />
    </div>
  </div>
</template>

<script>
import { VTablePaginationButton } from './VTablePaginationButton';
import { mapGetters, mapMutations } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'VTablePagination',
  components: { VTablePaginationButton },
  props: {
    tableName: { type: String, required: false },
    totalElements: { type: Number, required: true },
    customStyle: { type: String, default: '' },
  },
  data() {
    return {
      pagination: this.initPagination(),
    };
  },
  computed: {
    paginationSizes() {
      return [10, 20, 30, 50, 100];
    },
    totalPages() {
      return Math.ceil(
        this.totalElements / this.pagination.size,
      );
    },
    paginationPages() {
      return [...Array(this.totalPages).keys()].map(
        (it) => it + 1,
      );
    },
    paginationPagesButtons() {
      const currentPage = this.pagination.page;
      return [...Array(this.totalPages).keys()]
        .map((it) => it + 1)
        .filter(
          (it) => it === 1 ||
            it === this.totalPages ||
            currentPage === it ||
            currentPage - 1 === it ||
            currentPage + 1 === it ||
            (currentPage - 2 === 2 && it === 2) ||
            (currentPage + 2 === this.totalPages - 2 &&
              it === this.totalPages - 2) ||
            (currentPage < 6 && it < 6 && currentPage !== 5) ||
            (currentPage > this.totalPages - 5 &&
              it > this.totalPages - 5 &&
              currentPage !== this.totalPages - 4),
        )
        .flatMap((it, index, arr) => {
          if (index === arr.length - 1) return [it];
          return it + 1 === arr[index + 1] ? [it] : [it, -1];
        });
    },
    pageTableName() {
      if (this.tableName) {
        return this.tableName;
      }

      return this.$route.name;
    },
  },
  watch: {
    'pagination.size'(newSize, oldSize) {
      if (newSize !== oldSize) {
        if (this.totalPages !== 0 && (this.pagination.page > this.totalPages || this.pagination.page === 0)) {
          this.pagination.page = this.totalPages;
        }

        this.$emit('change', { ...this.pagination });

        this.SAVE_TABLES({ name: this.pageTableName, table: this.pagination });
      }
    },
    'pagination.page'(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$emit('change', { ...this.pagination });

        this.SAVE_TABLES({ name: this.pageTableName, table: this.pagination });
      }
    },
    totalPages: {
      immediate: true,
      handler(val) {
        if (val !== 0 && val < this.pagination.page) {
          this.pagination.page = val;
        }
      },
    },
  },
  methods: {
    initPagination() {
      const tables = this.getTables();

      if (tables) {
        let tableName = this.tableName;

        if (!tableName) {
          tableName = this.$route.name;
        }

        if (tables[tableName]) {
          this.$emit('change', tables[tableName]);

          return tables[tableName];
        }
      }

      this.$emit('change', initialPagination);

      return { ...initialPagination };
    },
    ...mapGetters('user', ['getTables']),
    ...mapMutations('user', ['SAVE_TABLES']),
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  @media (max-width: 860px) {
    flex-direction: column;
    gap: 8px;
  }

  &__sizes {
    display: flex;

    & > div {
      margin-right: 10px;
    }
  }

  &__pages {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 10px;
    }

    &-text {
      color: #677379;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
