<template>
  <div>
    <div class="container">
      <HelpNav />
      <div class="content-container">
        <div class="content">
          <div class="content-text">
            <p class="title">Техническая поддержка</p>
            <div class="question">
              <p class="standart-text">
                Наши специалисты находятся на связи 24 часа и обязательно помогут вам.
                <br>
                <br>
                Рекомендуем, однако, перед тем как обращаться в службу поддержки, ознакомиться со списком решения
                известных проблем. Возможно, в таком случае, вы сможете справиться самостоятельно.
              </p>
            </div>
            <div class="question">
              <p class="second-title" style="text-transform: uppercase;">Введите адрес электронной почты для обратной
                связи</p>
              <div style="margin-top: 16px;">
                <c-input
                  v-model="email"
                  :error="emailError"
                  :error-text="errorText"
                  :validation="validate"
                  placeholder="pochta@mail.ru"
                  role="email"
                  type="text"
                />
              </div>
            </div>
            <!-- /.question -->
            <div class="question">
              <p class="second-title" style="text-transform: uppercase;">Опишите проблему, с которой вы столкнулись</p>
              <div style="margin-top: 16px;">
                <c-textarea
                  v-model="message"
                  :error="messageError"
                  :error-text="errorMessageText"
                  :validation="validate"
                  role="message"
                  type="text"
                />
              </div>
            </div>
            <!-- /.question -->
            <button :disabled="isWaitingSubmit" class="button" @click="sendMessage">Отправить заявку</button>
            <horizontal-progress-bar v-if="isWaitingSubmit" />
            <p v-if="sendError" class="error-text">{{ sendError }}</p>
            <div class="question">
              <p class="standart-text">Если вы не можете воспользоваться электронной почтой, свяжитесь со службой
                технической поддержки по телефону <a class="number" href="tel://+74956579095">+7 (495) 657 90 95</a></p>
            </div>
          </div>
          <!-- /.content-text -->
        </div>
        <!-- /.content -->
      </div>
      <!-- /.content-container -->
    </div>
    <!-- /.container -->
  </div>
</template>

<script>
import HelpNav from 'views/public/help/components/HelpNav.vue';
import CInput from 'atoms/common/inputs/CInput.vue';
import validate from 'lib/utils/validationRules.js';
import CTextarea from 'atoms/common/inputs/CTextarea.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    HelpNav,
    CInput,
    CTextarea,
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  data() {
    return {
      email: '',
      message: '',
      emailError: { error: false },
      messageError: { error: false },
      errorText: 'Поле обязательно для заполнения и должно содержать email для получения ответа',
      errorMessageText: 'Поле обязательно для заполнения',
      isWaitingSubmit: false,
      sendError: '',
      validate: validate.isNotEmpty,
    };
  },
  methods: {
    async sendMessage() {
      if (!validate.isEmail(this.email)) {
        this.emailError = { error: true };
        return;
      }
      if (!validate.isNotEmpty(this.message)) {
        this.messageError = { error: true };
        return;
      }
      this.isWaitingSubmit = true;
      this.sendError = '';
      const isSend = await this.sendEmailToSupport({ from: this.email, message: this.message });
      this.isWaitingSubmit = false;

      if (!isSend) {
        this.sendError = 'Сервер не отвечает, ведутся работы.';
        return;
      }

      this.vueShowNotification(
        'ЗАЯВКА УСПЕШНО ОТПРАВЛЕНА',
        '<p>Наши специалисты свяжутся с вами в ближайшее время.</p>',
      );

      this.validate = undefined;
      this.email = '';
      this.message = '';
      setTimeout(() => {
        this.validate = validate.isNotEmpty;
      }, 200);
    },
    ...mapActions('user', ['sendEmailToSupport']),
  },
};
</script>

<style scoped>
.container {
  font-family: 'Roboto Condensed', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  overflow-x: auto;
}

/* Content */

.content-container {
  display: flex;
  margin-left: 530px;
}

.bg-content {
  width: 491px;
  height: 325px;
}

.content {
  margin-left: 80px;
  margin-top: 40px;
}

.content-text {
  margin-top: 40px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}

.question {
  margin-top: 24px;
}

.second-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #0E0F0F;
}

.standart-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #0E0F0F;
  max-width: 465px;
}

.link {
  color: #0E0F0F;
}

.number {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: black;
}

.input1 {
  width: 489px;
  height: 64px;
  padding: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  display: flex;
}

.error {
  border-color: #EB5757;
}

.error-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 147.9%;
  margin-top: 16px;
  color: #EB5757;
}

.message {
  max-width: 489px;
  min-width: 489px;
  min-height: 110px;
  width: 489px;
  height: 110px;
  padding: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #0E0F0F;
}

.button {
  margin-top: 24px;
  width: 100%;
  height: 56px;
  border: none;
  background-color: #2F82DF;
  text-transform: uppercase;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
}

input::placeholder {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  color: #C1C4C7;
}

:focus::-webkit-input-placeholder {
  color: transparent
}

:focus::-moz-placeholder {
  color: transparent
}

:focus:-moz-placeholder {
  color: transparent
}

:focus:-ms-input-placeholder {
  color: transparent
}
</style>
