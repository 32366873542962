<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
        <span>Заявленная категория надежности</span>
      </h2>
    </div>

    <div :class="$style.grid2Column">
      <div>
        <p :class="$style.textHelper">
          <span>Категория надёжности</span>
          <tooltip-helper offset="6px" placement="top">
            <div :class="$style.tooltip" style="width: 320px;">
              <p>Для всех бытовых потребителей категория надёжности — это третья категория. Если не знаете, какую
                выбирать, выбирайте третью. Чем выше категория надёжности, тем больше стоит технологическое
                присоединение.</p>
            </div>
          </tooltip-helper>
        </p>
        <c-select
          v-model="connectionApplication.connectionCharacteristics.reliabilityClass"
          :error="errors.reliabilityClass"
          :options="reliabilityClassList"
          role='reliabilityClass'
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StepCounter from '../components/StepCounter';

export default {
  name: 'ReliabilityClass',
  components: {
    CSelect: () => import('atoms/common/CSelect'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    StepCounter,
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (!this.reliabilityClassList.some((it) => it.value === this.connectionApplication.connectionCharacteristics.reliabilityClass.value)) {
      this.connectionApplication.connectionCharacteristics.reliabilityClass = this.reliabilityClassList[0];
    }
  },
  computed: {
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    reliabilityClassList() {
      switch (this.connectionApplication.type) {
        case 'LEGAL_ENTITIES_UP_TO_150':
          return this.reliabilityClassMiddleList;
        default:
          return this.reliabilityClassShortList;
      }
    },
    ...mapGetters('dataLists', ['reliabilityClassShortList', 'reliabilityClassMiddleList']),
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.titleContainer
  +base-spacing

.title
  display: flex

.textHelper
  +base-text
  color: #141412
  display: flex

  span
    margin-right: 10px

.noteContainer
  +base-spacing

  p
    +small-spacing
    +small-text

  a
    +base-link

.grid2Column
  +grid-2-column

.gridFluid
  +grid-fluid

.textDescription
  p
    +base-text

  a
    +base-link

.dateLabel
  margin-bottom: 8px
  font-size: 16px
  line-height: 24px
</style>
