import gql from 'graphql-tag';

export const userType = gql`
  fragment User_type on User {
    id
    snils
    fullName {
      name
      surname
      patronymic
    }
    fullNameGenitiveCase {
      name
      surname
      patronymic
    }
    email
    phoneNumber
    telegramAccount
    agreedWithUserAgreement
    agreedWithPersonalDataAgreement
  }
`;

export const profileType = gql`
  fragment Profile_type on Profile {
    __typename
    ... on CompanyProfile {
      id
      name
      inn
      kpp
      ogrn
      oktmo
      okpo
      bankRequisites {
        bik
        settlementAccount
        bankName
        correspondentAccount
      }
      okved
      additionalOkved
      legalAddress
      postAddress
      shortName
      fullEnglishName
      shortEnglishName
      departmentName
      email
      phoneNumber
      history {
        id
        updatedAt
      }
      bankRequisitesHistory {
        id
        updatedAt
        updatedBy {
          fullName {
            name
            surname
            patronymic
          }
        }
      }
    }
    ... on IndividualProfile {
      person {
        name
        surname
        patronymic
      }
      identityDocumentInfo {
        documentType
        series
        number
        issueDate
        issuerName
        issuerCode
        otherName
      }
      snils
      registrationAddress
      liveAddress
      phoneNumber
      email
      inn
      fullName {
        name
        surname
        patronymic
      }
      fullNameGenitiveCase {
        name
        surname
        patronymic
      }
    }
    ... on IndividualEnterpreneurProfile {
      id
      name
      inn
      ogrnip
      oktmo
      okpo
      bankRequisites {
        bik
        settlementAccount
        bankName
        correspondentAccount
      }
      okved
      additionalOkved
      legalAddress
      postAddress
      email
      phoneNumber
      history {
        id
        updatedAt
      }
      bankRequisitesHistory {
        id
        updatedAt
        updatedBy {
          fullName {
            name
            surname
            patronymic
          }
        }
      }
    }
    ... on PlatformWorkerProfile {
      type
      name
      role
    }
  }
`;

export const roleType = gql`
  fragment Role_type on UserRole {
    id
    permissions {
      code
      description
      usingOnAttorney
    }
    name
    isCompanyOwner
    isSubdivisionOwner
    isChiefAccountant
    subdivision {
      id
      name
      description
      districtDepartment {
        id
      }
      shift
      phoneNumber
    }
    parentRole {
      id
      name
    }
    children {
      id
    }
  }
`;
