import 'babel-polyfill';
import 'whatwg-fetch';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'lib/utils/validation';
import VueDraggableResizable from 'vue-draggable-resizable';
import PortalVue from 'portal-vue';
import './plugins/axios';
import vuetify from '@/plugins/vuetify';
import 'dayjs/locale/ru';
import './assets/sass/main.sass';
import './vuetify-styles.less';
import registerPlugins from 'lib/plugins';
import { createProvider } from './api/graphql/vue-apollo';
import VClickOutside from './directives/VClickOutside';

import Chart from 'chart.js';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { onShownNotification } from '@/utils/notification/notification';
import './assets/MainStyles.css';
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);

const utc = require('dayjs/plugin/utc');

dayjs.extend(customParseFormat);

Chart.plugins.unregister(ChartJsPluginDataLabels);

registerPlugins(Vue);

dayjs.locale('ru');
dayjs.extend(utc);

Vue.config.productionTip = false;

Vue.directive('custom-click-outside', VClickOutside);

Vue.prototype.vueShowNotification = onShownNotification;
Vue.use(PortalVue);
Vue.component('VueDraggableResizable', VueDraggableResizable);

if (!String.prototype.format) {
  String.prototype.format = function() {
    const args = arguments[0];
    return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] != 'undefined'
      ? args[number]
      : match));
  };
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
