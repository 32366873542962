<template>
  <page class="substations" title="Справочник подстанций">
    <v-table-new
      v-model="transformedSubstations"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник подстанций"
      @delete="displayDeleteDialog"
      @edit="displayEditDialog"
    />
    <modal v-if="isShowDeleteDialog"
           :is-btn-close-visible="false"
           :modal-style="{
              borderRadius: '15px',
           }"
           @close="isShowDeleteDialog = false"
    >
      <template #header>
        <div class="substations__header">Удалить подстанцию?</div>
      </template>
      <template #body>
        <div class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Полное наименование:</div>
          <div class="col col--2of3">{{ itemForUpdate.fullName }}</div>
        </div>
        <div class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Номер:</div>
          <div class="col col--2of3">{{ itemForUpdate.number }}</div>
        </div>
        <div class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Краткое наименование:</div>
          <div class="col col--2of3">{{ itemForUpdate.shortName }}</div>
        </div>
        <div v-if="itemForUpdate.workingVoltageUpper" class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Входящее напряжение, кВ:</div>
          <div class="col col--2of3">{{ itemForUpdate.workingVoltageUpper }}</div>
        </div>
        <div v-if="itemForUpdate.address" class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Адрес:</div>
          <div class="col col--2of3">{{ itemForUpdate.address }}</div>
        </div>
        <div v-if="itemForUpdate.substationKindLabel" class="row mb-24 substations__delete__info">
          <div class="col col--1of3 substations__delete__info__title">Тип:</div>
          <div class="col col--2of3">{{ itemForUpdate.substationKindLabel }}</div>
        </div>

        <horizontal-progress-bar v-if="isWaitingSubmit" class="substations__delete__progress"/>

        <div class="substations__delete__actions">
          <v-button variant="outlined" @click="isShowDeleteDialog = false">Отмена</v-button>
          <v-button :disabled="isWaitingSubmit" variant="outlined-red" @click="deleteSubstation">Удалить</v-button>
        </div>
      </template>
    </modal>
    <modal v-if="isShowAddEditDialog"
           :body-style="{
             minHeight: '420px',
           }"
           :is-btn-close-visible="false"
           :modal-style="{
              borderRadius: '15px',
           }"
           @close="isShowAddEditDialog = false"
    >
      <template #header>
        <div class="substations__header">
          <span v-if="itemForUpdate.isNew">Добавление</span>
          <span v-else>Редактирование</span> подстанции
        </div>
      </template>
      <template #body>
        <v-form @submit="submitSubstation">
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-select
                v-model="itemForUpdate.substationKind"
                :options="substationKindList"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                label="Тип"
                required
                rules="required"
              />
            </div>
          </div>
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-address-search-select
                v-model="itemForUpdate.address"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                label="Адрес"
                required
                rules="required"
              />
            </div>
          </div>
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-input
                v-model="itemForUpdate.fullName"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                required
                rules="required"
                title="Полное наименование"
              />
            </div>
          </div>
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-input
                v-model="itemForUpdate.number"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                required
                rules="required"
                title="Номер"
              />
            </div>
          </div>
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-input
                v-model="itemForUpdate.shortName"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                required
                rules="required"
                title="Краткое наименование"
              />
            </div>
          </div>
          <div class="row mb-24 substations__delete__info">
            <div class="col col--1of1">
              <v-input
                v-model="itemForUpdate.workingVoltageUpper"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                decimal
                required
                rules="required|greaterZero"
                title="Входящее напряжение, кВ"
              />
            </div>
          </div>
          <horizontal-progress-bar v-if="isWaitingSubmit" class="substations__delete__progress"/>
          <div class="substations__delete__actions">
            <v-button variant="outlined" @click="isShowAddEditDialog = false">Отмена</v-button>
            <v-button :disabled="isWaitingSubmit" type="submit" variant="filled">
              <span v-if="itemForUpdate.isNew">Отправить</span>
              <span v-else>Сохранить</span>
            </v-button>
          </div>
        </v-form>
      </template>
    </modal>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Page, VAddressSearchSelect, VButton, VForm, VInput, VSelect, VTableNew } from 'components';
import Modal from 'components/Modal/Modal';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import {
  generateAddSubstationXml,
  generateDeleteSubstationXml,
  generateUpdateSubstationXml,
} from 'views/private/Substations/xml';

export default {
  name: 'CabinetSubstationsScreen',
  components: {
    VTableNew,
    Page,
    Modal,
    VButton,
    HorizontalProgressBar,
    VInput,
    VSelect,
    VAddressSearchSelect,
    VForm,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      isShowDeleteDialog: false,
      isShowAddEditDialog: false,
      isWaitingSubmit: false,
      substationsList: [],
      itemForUpdate: {},
    };
  },
  computed: {
    template() {
      const headers = [
        {
          key: 'id',
          type: 'hide',
        },
        {
          label: '№',
          key: 'index',
          type: 'index',
          thStyle: {
            width: '20px',
          },
        },
        {
          label: 'Наименование',
          key: 'fullName',
          sort: {},
          filter: {},
        },
        {
          label: 'Номер',
          key: 'number',
          sort: {},
          filter: {},
        },
        {
          label: 'Сокращенное наименование',
          key: 'shortName',
          sort: {},
          filter: {},
        },
        {
          label: 'Входящее напряжение, кВ',
          key: 'workingVoltageUpper',
          type: 'number',
          sort: {},
          filter: {},
        },
        {
          label: 'Адрес',
          key: 'address',
          sort: {},
          filter: {},
        },
        {
          label: 'Тип',
          key: 'substationKindLabel',
          sort: {},
          filter: { type: 'select', values: this.substationKindList },
        },
      ];

      if (this.isPlatformWorker) {
        headers.push(
          {
            key: 'editDeleteButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        );
      }

      return { headers: headers };
    },
    transformedSubstations() {
      return this.substationsList.map((substation) => ({
        ...substation,
        substationKindLabel: this.formatSubstationKind(substation.substationKind),
      }));
    },
    actionBlockItems() {
      if (this.isPlatformWorker) {
        return [
          {
            title: 'Добавить подстанцию',
            method: this.displayAddDialog,
          },
        ];
      }

      return undefined;
    },
    ...mapGetters('user', ['isPlatformWorker']),
    ...mapGetters('dataLists', ['substationKindList']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.substationsList = await this.substations();
      } catch (error) {
        this.setNotification({ message: error.message });
      } finally {
        this.loading = false;
      }
    },
    formatSubstationKind(kind) {
      return this.substationKindList.find((item) => item.value === kind)?.label;
    },
    displayEditDialog(cell) {
      this.itemForUpdate = cell.row;
      this.itemForUpdate.numberOld = cell.row.number;
      this.isShowAddEditDialog = true;
    },
    displayAddDialog() {
      this.itemForUpdate = {};
      this.itemForUpdate.isNew = true;
      this.isShowAddEditDialog = true;
    },
    displayDeleteDialog(cell) {
      this.itemForUpdate = cell.row;
      this.isShowDeleteDialog = true;
    },
    async deleteSubstation() {
      this.isWaitingSubmit = true;

      try {
        const xml = generateDeleteSubstationXml(this.itemForUpdate.number);

        await this.documentUpload({ xml: xml });

        this.isShowDeleteDialog = false;
        await this.loadData();
      } catch (error) {
        console.log('error deleteSubstation: ', error);
        this.setNotification({ message: error.message });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async submitSubstation() {
      this.isWaitingSubmit = true;

      this.itemForUpdate.number = this.itemForUpdate.number.trim();
      this.itemForUpdate.shortName = this.itemForUpdate.shortName.trim();
      this.itemForUpdate.fullName = this.itemForUpdate.fullName.trim();
      this.itemForUpdate.address = this.itemForUpdate.address.trim();
      this.itemForUpdate.workingVoltageUpper = this.itemForUpdate.workingVoltageUpper.replaceAll(' ', '');

      try {
        const xml = this.itemForUpdate.isNew
          ? generateAddSubstationXml(this.itemForUpdate)
          : generateUpdateSubstationXml(this.itemForUpdate);

        await this.documentUpload({ xml: xml });

        this.isShowAddEditDialog = false;

        this.setNotification({
          message: this.itemForUpdate.isNew ? 'Подстанция добавлена' : 'Подстанция изменена',
          type: 'success',
        });

        await this.loadData();
      } catch (error) {
        console.log('error submitSubstation: ', error);
        this.setNotification({ message: error.message });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('border', ['substations']),
    ...mapActions('user', ['setNotification']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";

.substations {
  &__header {
    font-size: 24px;
    width: 100%;
    text-align: center;
    padding-top: 12px;
  }

  &__delete {
    &__info {
      font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      &__title {
        font-weight: 600;
        font-size: 16px;
        color: gray;
      }
    }

    &__progress {
      width: 60%;
      margin: 0 auto;
    }

    &__actions {
      padding: 12px 24px 12px 24px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
