<template>
  <div :class="$style.container">
    <div
      v-if="visibleImg"
      :class="$style.image"
      :style="`backgroundImage: url(${fileUrl})`"
      :role="role + '.preview'"
    ></div>
    <div :class="$style.link" >
      <common-link :href="fileUrl" target="_blank" :name="file.name" :role="role + '.link'">
        <span :class="$style.title">{{ file.name }}</span>
      </common-link>
      <div v-if="$slots.hasOwnProperty('action')" :class="$style.action" :role="role + '.delete'">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonLink from 'atoms/common/CommonLink';

export default {
  name: 'FilePreview',
  components: {
    CommonLink,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      default: '',
    },
    visibleImg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fileUrl() {
      return `data:${this.file.type};base64,${this.file.code}`;
    },
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    align-items: center
    flex-wrap: wrap
    margin-left: 24px
    margin-top: 14px

  .link
    display: flex
    margin-left: 0

    a
      &:hover
        text-decoration: none

  .image
    width: 80px
    height: 80px
    background-color: #F5F6F6
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    margin-right: 16px

  .title
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 25px
    color: #0E0F0F
    text-decoration: underline

    &:hover
      text-decoration: none

  .action
    margin-left: 18px
</style>
