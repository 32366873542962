<template>
  <div
    ref="draggableContainer"
    class="draggable-container"
    :style="{
      top: top + 'px',
      left: left + 'px',
    }"
  >
    <div @mousedown="dragMouseDown">
      <slot name="header">
      </slot>
    </div>
    <slot name="body">
    </slot>
    <slot name="footer">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Draggable',
  props: {
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      positions: {
        clientX: null,
        clientY: null,

        movementX: 0,
        movementY: 0,
      },
    };
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault();

      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();

      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;

      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
      this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable {
  &-container {
    position: absolute;
    z-index: 10;
  }
}
</style>
