<template>
  <div class="page-form">
    <div class="page-form__form">
      <template v-if="isLegalEntity">
        <div class="row mb-24">
          <div class="col col--1of3">
            <v-input
              v-model="internalCompany.name"
              :disabled="!isPlatformWorker"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              title="Наименование"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              v-model="internalCompany.inn"
              :disabled="!isPlatformWorker"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              title="ИНН"
            />
          </div>
          <div v-if="!isIpInfo" class="col col--1of3">
            <v-input
              v-model="kpp"
              :clearable="editable"
              :disabled="!editable"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required|kpp"
              title="КПП"
            />
          </div>
          <div v-else class="col col--1of3">
            <v-address-search-select
              v-model="internalCompany.address"
              :disabled="!isPlatformWorker"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              :title="addresFieldTitle"
            />
          </div>
        </div>
        <div v-if="!isIpInfo" class="row mb-24">
          <div class="col col--1of3">
            <v-address-search-select
              v-model="internalCompany.address"
              :disabled="!isPlatformWorker"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              :title="addresFieldTitle"
            />
          </div>
          <div v-if="isDisplayOgrn" class="col col--1of3">
            <v-input
              v-model="internalCompany.ogrn"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              title="ОГРН"
            />
          </div>
        </div>
        <v-divider class="page-form__form-divider"/>
      </template>

      <template v-if="type === 'GENERAL_DIRECTOR'">
        <div class="row mb-24">
          <div class="col col--1of2">
            <v-input
              v-model="internalCompany.name"
              disabled
              title="Название компании"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-model="internalCompany.roleName"
              disabled
              title="Должность"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--1of2">
            <v-input
              v-model="internalUser.additionalEmail"
              :clearable="editable"
              :disabled="!editable"
              :rules-messages="{email: 'Неправильно введён адрес электронной почты'}"
              rules="email"
              title="Доп. электронная почта"
            />
            <one-time-password-input
              v-if="editable"
              :is-waiting="isSendCode.additionalEmail"
              :is-disabled="isDisableSendAdditionalEmailCode"
              input-style="height: 40px;fontSize: 18px"
              is-display-send-button
              :required="!isDisableSendAdditionalEmailCode"
              style="margin-top: 4px"
              vid="additionalEmailCode"
              @code-input="(code) => codeEntered(code, 'additionalEmailCode')"
              @code-request="codeRequest('additionalEmail')"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-model="internalUser.additionalPhoneNumber"
              :clearable="editable"
              :disabled="!editable"
              :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
              :rules-messages="{regex: 'Неправильно введён номер телефона'}"
              rules="regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
              title="Доп. телефон"
            />
            <one-time-password-input
              v-if="editable"
              :is-waiting="isSendCode.additionalPhoneNumber"
              :is-disabled="isDisableSendAdditionalPhoneNumberCode"
              input-style="height: 40px;fontSize: 18px"
              is-display-send-button
              :required="!isDisableSendAdditionalPhoneNumberCode"
              style="margin-top: 4px"
              vid="additionalPhoneNumberCode"
              @code-input="(code) => codeEntered(code, 'additionalPhoneNumberCode')"
              @code-request="codeRequest('additionalPhoneNumber')"
            />
          </div>
        </div>

        <v-divider class="page-form__form-divider"/>
      </template>

      <div class="page-form__form-header">
        <span class="page-form__form-header__title">{{ titleUser }}</span>
        <span v-if="editable" class="page-form__form-header__required-note">* поля обязательные для заполнения</span>
      </div>
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="internalUser.secondName"
            :clearable="isEditablePersonData"
            :disabled="!isEditablePersonData"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required"
            title="Фамилия"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalUser.firstName"
            :clearable="isEditablePersonData"
            :disabled="!isEditablePersonData"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required"
            title="Имя"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalUser.patronymic"
            :clearable="isEditablePersonData"
            :disabled="!isEditablePersonData"
            title="Отчество"
          />
        </div>
      </div>
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="internalUser.insuranceNumber"
            :clearable="isSnilsEditingAvailable"
            :disabled="!isSnilsEditingAvailable"
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required|snils"
            title="СНИЛС"
          />
        </div>
        <div v-if="type!=='registration'" class="col col--1of3">
          <v-input
            v-model="internalUser.telegram"
            :clearable="isEditablePersonData"
            :disabled="!isEditablePersonData"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            placeholder="@"
            rules="telegramAccount"
            title="Аккаунт Telegram"
          />
        </div>
        <div v-if="internalUser.telegram?.length" class="col col--1of3">
          <p class="page-form__form__telegram-label">Для начала приёма уведомлений в Telegram необходимо написать боту
            <a :href="telegramBotInfo.url" target="_blank">{{ telegramBotInfo.account }}</a></p>
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of2 page-form__form__place-below">
          <v-input
            v-model="internalUser.phone"
            :clearable="editable"
            :disabled="!editable || withoutPassword"
            :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
            :rules-messages="{required: 'Обязательно для заполнения', regex: 'Неправильно введён номер телефона'}"
            required
            rules="required|regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
            title="Телефон"
          />
          <one-time-password-input
            v-if="editable && !withoutPassword"
            :is-waiting="isSendCode.phone"
            :is-disabled="isDisableSendPhoneCode"
            input-style="height: 40px;fontSize: 18px"
            is-display-send-button
            :required="!isDisableSendPhoneCode"
            style="margin-top: 4px"
            vid="phoneCode"
            @code-input="(code) => codeEntered(code, 'phoneCode')"
            @code-request="codeRequest('phone')"
          />
        </div>
        <div class="col col--1of2">
          <v-input
            v-model="internalUser.email"
            :clearable="editable"
            :disabled="!editable || withoutPassword"
            :rules-messages="{required: 'Обязательно для заполнения', email: 'Неправильно введён адрес электронной почты'}"
            required
            rules="required|email"
            title="Электронная почта"
            note="В соответствии с ФЗ 406 от 31.07.2023 просим использовать для авторизации электронные почтовые ящики в зоне .ru или .рф"
          />
          <one-time-password-input
            v-if="editable &&  !withoutPassword"
            :is-waiting="isSendCode.email"
            :is-disabled="isDisableSendEmailCode"
            input-style="height: 40px;fontSize: 18px"
            is-display-send-button
            :required="!isDisableSendEmailCode"
            style="margin-top: 4px"
            vid="emailCode"
            @code-input="(code) => codeEntered(code, 'emailCode')"
            @code-request="codeRequest('email')"
          />
        </div>
      </div>

      <v-divider class="page-form__form-divider"/>

      <!--Сотрудник, Сотрудник с правом подписи, Сотрудник ЮЛ потребителя-->
      <template v-if="userType.type === 'EMPLOYEE'">
        <div class="page-form__form-header">
          <span class="page-form__form-header__title">Данные сотрудника</span>
        </div>

        <div class="row mb-24">
          <div class="col col--1of2">
            <v-input
              v-model="internalCompany.name"
              disabled
              title="Название компании"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-if="!isUpdateRole"
              v-model="internalCompany.roleName"
              disabled
              title="Должность"
            />
          </div>
        </div>

        <div v-if="isUpdateRole" class="row mb-24">
          <div class="col col--1of2">
            <v-search-select
                v-model="internalUser.subdivision"
                :options="subdivisions"
                required
                title="Подразделение"
            />
          </div>
          <div class="col col--1of2">
            <v-search-select
                v-model="internalUser.role"
                :options="roles"
                required
                title="Должность"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--1of2">
            <v-input
              v-model="internalUser.additionalEmail"
              :clearable="editable"
              :disabled="!editable"
              :rules-messages="{email: 'Неправильно введён адрес электронной почты'}"
              rules="email"
              title="Доп. электронная почта"
            />
            <one-time-password-input
              v-if="editable"
              :is-waiting="isSendCode.additionalEmail"
              :is-disabled="isDisableSendAdditionalEmailCode"
              input-style="height: 40px;fontSize: 18px"
              is-display-send-button
              :required="!isDisableSendAdditionalEmailCode"
              style="margin-top: 4px"
              vid="additionalEmailCode"
              @code-input="(code) => codeEntered(code, 'additionalEmailCode')"
              @code-request="codeRequest('additionalEmail')"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-model="internalUser.additionalPhoneNumber"
              :clearable="editable"
              :disabled="!editable"
              :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
              :rules-messages="{regex: 'Неправильно введён номер телефона'}"
              rules="regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
              title="Доп. телефон"
            />
            <one-time-password-input
              v-if="editable"
              :is-waiting="isSendCode.additionalPhoneNumber"
              :is-disabled="isDisableSendAdditionalPhoneNumberCode"
              input-style="height: 40px;fontSize: 18px"
              is-display-send-button
              :required="!isDisableSendAdditionalPhoneNumberCode"
              style="margin-top: 4px"
              vid="additionalPhoneNumberCode"
              @code-input="(code) => codeEntered(code, 'additionalPhoneNumberCode')"
              @code-request="codeRequest('additionalPhoneNumber')"
            />
          </div>
        </div>
      </template>

      <template v-if="type && type !== 'registration'">
        <div class="page-form__form-header">
          <span class="page-form__form-header__title">{{ userType.title }}</span>
          <span v-if="editable && userType.type === 'EMPLOYEE'"
                class="page-form__form-header__required-note page-form__form-header__required-note--grey">
            Блок "Данные сотрудника с правом подписи" заполняется только при необходимости формирования работнику машиночитаемой доверенности
          </span>
          <span v-if="editable && userType.type === 'CONSUMER'"
                class="page-form__form-header__required-note page-form__form-header__required-note--grey">
            Блок "Данные потребителя" заполняется при заполнении заявки на технологическое присоединение или заключении договора энергоснабжения
          </span>
          <span v-if="editable && userType.type === 'GENERAL_DIRECTOR'"
                class="page-form__form-header__required-note page-form__form-header__required-note--grey">
            Блок "Данные для МЧД" заполняется только при необходимости формирования машиночитаемой доверенности
          </span>
        </div>

        <div class="row mb-24">
          <div class="col col--1of3">
            <v-select
              v-model="internalUser.identityDocumentInfo.documentType"
              :disabled="!editable"
              :options="documentTypeList"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              title="Тип удостоверения личности"
            />
            <v-input
              v-if="internalUser.identityDocumentInfo.documentType === 'OTHER'"
              v-model="internalUser.identityDocumentInfo.otherName"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              :disabled="!editable"
              title="Иное:"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              v-model="internalUser.identityDocumentInfo.series"
              :clearable="editable"
              :disabled="!editable"
              :mask="internalUser.identityDocumentInfo.documentType === 'PASSPORT_RF' ? [/\d/, /\d/, /\d/, /\d/] : undefined"
              :required="requiredForType.length > 0"
              :rules="requiredForType + (internalUser.identityDocumentInfo.documentType === 'PASSPORT_RF' ? '|digits:4' : '')"
              :rules-messages="{required: 'Обязательно для заполнения', digits: 'Данные серии паспорта введены неправильно'}"
              title="Серия"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              v-model="internalUser.identityDocumentInfo.number"
              :clearable="editable"
              :disabled="!editable"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
              :required="requiredForType.length > 0"
              :rules="requiredForType + '|digits:6'"
              :rules-messages="{required: 'Обязательно для заполнения', digits: 'Данные номера паспорта введены неправильно'}"
              title="Номер"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--2of3">
            <v-input
              v-model="internalUser.identityDocumentInfo.issuerName"
              :clearable="editable"
              :disabled="!editable"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              title="Кем выдан"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              v-model="internalUser.inn"
              :clearable="editable"
              :disabled="!editable"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
              :required="type !== 'INDIVIDUAL' && requiredForType.length > 0"
              :rules="(type === 'INDIVIDUAL' ? '' : requiredForType) + '|digits:12|inn'"
              :rules-messages="{digits: 'Данные ИНН введены неправильно'}"
              title="ИНН"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--1of3">
            <v-date-picker
              v-model="internalUser.identityDocumentInfo.issueDate"
              :clearable="editable"
              :disabled="!editable"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              :start-date="startDate"
              title="Дата выдачи"
            />
          </div>
          <div v-if="subdivisionCodeShow" class="col col--1of3">
            <v-input
              v-model="internalUser.identityDocumentInfo.issuerCode"
              :clearable="editable"
              :disabled="!editable"
              :mask="[/\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/]"
              :required="requiredForType.length > 0"
              :rules="requiredForType + '|min:7|max:7'"
              :rules-messages="{required: 'Обязательно для заполнения', digits: 'Код подразделения введен неправильно'}"
              title="Код подразделения"
            />
          </div>
          <div class="col col--1of3">
            <v-date-picker
              v-model="internalUser.birthDate"
              :clearable="editable"
              :disabled="!editable"
              :required="type !== 'INDIVIDUAL' && requiredForType.length > 0"
              :rules="birthValidationRule"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              :start-date="birthStartDate"
              title="Дата рождения"
            />
          </div>
        </div>

        <div v-if="userType.type === 'EMPLOYEE' || userType.type === 'GENERAL_DIRECTOR'" class="row mb-24">
          <div class="col col--2of3">
            <v-address-search-select
              v-model="internalUser.birthPlace"
              :clearable="editable"
              :disabled="!editable"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              title="Место рождения"
            />
          </div>
        </div>

        <template v-else>
          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.ogrnip"
                :clearable="editable"
                :disabled="!editable"
                title="ОГРНИП"
              />
            </div>
            <div class="col col--1of3">
              <v-address-search-select
                v-model="internalUser.birthPlace"
                :clearable="editable"
                :disabled="!editable"
                title="Место рождения"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.citizenship"
                :clearable="editable"
                :disabled="!editable"
                title="Гражданство"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.additionalEmail"
                :clearable="editable"
                :disabled="!editable"
                :rules-messages="{email: 'Неправильно введён адрес электронной почты'}"
                rules="email"
                title="Доп. электронная почта"
              />
              <one-time-password-input
                v-if="editable"
                :is-waiting="isSendCode.additionalEmail"
                :is-disabled="isDisableSendAdditionalEmailCode"
                input-style="height: 40px;fontSize: 18px"
                is-display-send-button
                :required="!isDisableSendAdditionalEmailCode"
                style="margin-top: 4px"
                vid="additionalEmailCode"
                @code-input="(code) => codeEntered(code, 'additionalEmailCode')"
                @code-request="codeRequest('additionalEmail')"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.additionalPhoneNumber"
                :clearable="editable"
                :disabled="!editable"
                :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                :rules-messages="{regex: 'Неправильно введён номер телефона'}"
                rules="regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
                title="Доп. телефон"
              />
              <one-time-password-input
                v-if="editable"
                :is-waiting="isSendCode.additionalPhoneNumber"
                :is-disabled="isDisableSendAdditionalPhoneNumberCode"
                input-style="height: 40px;fontSize: 18px"
                is-display-send-button
                :required="!isDisableSendAdditionalPhoneNumberCode"
                style="margin-top: 4px"
                vid="additionalPhoneNumberCode"
                @code-input="(code) => codeEntered(code, 'additionalPhoneNumberCode')"
                @code-request="codeRequest('additionalPhoneNumber')"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--2of3">
              <v-address-search-select
                v-model="internalUser.registrationAddress"
                :clearable="editable"
                :disabled="!editable"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                required
                rules="required"
                title="Адрес регистрации"
              />
            </div>
          </div>
        </template>

        <div class="row mb-24">
          <div class="col col--2of3">
            <v-address-search-select
              v-model="internalUser.address"
              :clearable="editable"
              :disabled="!editable"
              :required="requiredForType.length > 0"
              :rules="requiredForType"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              title="Место жительства"
            />
          </div>
        </div>

        <template v-if="userType.type === 'CONSUMER'">
          <div class="row">
            <h2 class="col col--1of1 mb-24 page-form__form-sub-title">
              Банковские реквизиты
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.bankRequisites.settlementAccount"
                :clearable="editable"
                :disabled="!editable"
                :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
                :required="requiredByIndividual.length > 0"
                :rules="requiredByIndividual + '|rs'"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                title="Расчетный счёт"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.bankRequisites.correspondentAccount"
                :clearable="editable"
                :disabled="!editable"
                :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
                :required="requiredByIndividual.length > 0"
                :rules="requiredByIndividual + '|ks'"
                title="Корреспондентский счёт"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-address-search-select
                v-model="internalUser.bankRequisites.bankName"
                :bik="internalUser.bankRequisites.bik"
                :clearable="editable"
                :disabled="!editable"
                :required="requiredByIndividual.length > 0"
                :rules="requiredByIndividual"
                bank-api
                title="Банк"
                @keyup="setBankInfo"
              />
            </div>
          </div>
        </template>
      </template>

      <template v-if="(type === 'registration' && !withoutPassword) || isUpdateRole">
        <v-divider v-if="isUpdateRole" class="page-form__form-divider"/>

        <div class="row mb-24">
          <div class="col col--2of3">
            <v-input
              v-model="internalUser.password"
              :clearable="editable"
              :disabled="!editable"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required|password"
              title="Пароль"
              type="password"
            />
          </div>
          <div class="col col--1of3">
            <ul class="page-form__form-ul-note">
              <li v-for="(error, index) in passwordErrors" :key="index">{{ error }}</li>
              <li v-if="passwordErrors.length > 0 && passwordSymbolsError">Пароль не может содержать русские буквы</li>
            </ul>
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--2of3">
            <v-input
              v-model="internalUser.confirmPassword"
              :clearable="editable"
              :disabled="!editable"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              title="Подтверждение пароля"
              type="password"
            />
          </div>
          <div class="col col--1of3">
            <ul v-if="internalUser.confirmPassword && internalUser.password !== internalUser.confirmPassword"
                class="page-form__form-ul-note"
            >
              <li>Пароли не совпадают</li>
            </ul>
          </div>
        </div>

        <v-divider class="page-form__form-divider"/>

      </template>
    </div>
  </div>
</template>

<script>
import { VAddressSearchSelect, VDatePicker, VDivider, VInput, VSelect, VSearchSelect } from 'components';
import { merge } from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput.vue';
import formatting from 'views/public/login/mixins/formatting';
import HTTPError from 'lib/utils/errors';
import formattingData from 'lib/utils/formatting';

export default {
  mixins: [formatting],
  components: {
    VDivider,
    VInput,
    VSelect,
    VDatePicker,
    VAddressSearchSelect,
    OneTimePasswordInput,
    VSearchSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    oldData: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    company: {
      type: Object,
      required: false,
    },
    isLegalEntity: {
      type: Boolean,
      default: false,
    },
    isIpInfo: {
      type: Boolean,
      default: false,
    },
    isCap: {
      type: Boolean,
      default: false,
    },
    withoutPassword: {
      type: Boolean,
      default: false,
    },
    isUpdateRole: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.type === 'registration' && this.internalCompany?.person) {
      if (this.isLegalEntity) {
        this.emptyUser.firstName = this.internalCompany.person.firstName;
        this.emptyUser.secondName = this.internalCompany.person.familyName;
        this.emptyUser.patronymic = this.internalCompany.person.patronymic;
        this.emptyUser.insuranceNumber = this.internalCompany.person.snils;
      } else {
        this.emptyUser.insuranceNumber = this.internalCompany.person.snils;
      }
    }
    this.loadData();
  },
  data() {
    return {
      internalUser: {},
      internalCompany: { ...this.company },
      identityDocumentTypeList: [
        { label: 'Паспорт гражданина РФ', value: 'PASSPORT_RF' },
      ],
      emptyUser: {
        firstName: '',
        secondName: '',
        patronymic: '',
        insuranceNumber: '',
        telegram: '',
        phone: '',
        phoneCode: '',
        email: '',
        emailCode: '',
        password: '',
        confirmPassword: '',

        additionalPhoneNumber: '',
        additionalPhoneNumberCode: '',
        additionalEmail: '',
        additionalEmailCode: '',

        birthDate: '',
        birthPlace: '',
        address: '',
        citizenship: '',

        identityDocumentInfo: {
          documentType: '',
          series: '',
          number: '',
          issueDate: '',
          issuerName: '',
          issuerCode: '',
          otherName: '',
        },

        bankRequisites: {
          bankName: '',
          bik: '',
          settlementAccount: '',
          correspondentAccount: '',
        },

        inn: '',
        registrationAddress: '',
        correspondentAccount: '',
        settlementAccount: '',
        bik: '',
        bankName: '',

        ogrnip: '',

        subdivision: '',
        role: '',
      },
      kpp: this.company?.kpp ?? '',
      passwordSymbolsError: false,
      telegramBotInfo: {
        account: '',
        url: '',
      },
      isSendCode: {},
    };
  },
  computed: {
    subdivisionCodeShow() {
      return this.internalUser.identityDocumentInfo.documentType === 'PASSPORT_RF';
    },
    addresFieldTitle() {
      if (this.isIpInfo) return 'Адрес';
      return 'Адрес юридического лица';
    },
    birthStartMoment() {
      return moment().subtract(18, 'years');
    },
    birthStartDate() {
      return this.birthStartMoment.toDate();
    },
    birthValidationRule() {
      const dateFormat = 'DD.MM.YYYY';
      const required = this.type === 'INDIVIDUAL' ? '' : this.requiredForType;
      return `${required}|dateAfter:${dateFormat},${this.birthStartMoment.format(dateFormat)}`;
    },
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    isEditablePersonData() {
      return this.editable && (this.type !== 'registration' || !this.isLegalEntity || this.isPlatformWorker);
    },
    isSnilsEditingAvailable() {
      return (this.type === 'registration' && !this.isCap) || this.isUpdateRole;
    },
    titleUser() {
      if (this.type === 'registration') {
        return this.isLegalEntity ? 'Данные исполнительного органа (генерального директора)' : 'Данные пользователя';
      }
      return this.userType.type === 'EMPLOYEE' ? 'Личные данные' : 'Ваши данные';
    },
    passwordErrors() {
      const result = [];

      if (this.internalUser.password.length < 12) {
        result.push('Пароль не менее 12 символов');
      }
      if (!this.internalUser.password.match(/[A-Z]/)) {
        result.push('Пароль должен включать как минимум одну заглавную букву');
      }
      if (!this.internalUser.password.match(/[a-z]/)) {
        result.push('Пароль должен включать как минимум одну строчную букву');
      }
      if (!this.internalUser.password.match(/[0-9]/)) {
        result.push('Пароль должен включать как минимум одну цифру');
      }
      if (!this.internalUser.password.match(/[!@#$%^&]/)) {
        result.push('Пароль должен включать как минимум один специальный символ (например: !, @, $)');
      }

      return result;
    },
    userType() {
      /* Сотрудник, Сотрудник с правом подписи, Сотрудник ЮЛ потребителя*/
      if (this.type === 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS' ||
        this.type === 'EMPLOYEE_WITH_SIGNING_RIGHTS' ||
        this.type === 'CONSUMER_EMPLOYEE_WITH_SIMPLE_SIGNATURE' ||
        this.type === 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS' || this.type === 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS') {

        return { type: 'EMPLOYEE', title: 'Данные сотрудника с правом подписи' };
      }
      /* ФЛ/ИП, представитель ФЛ/ИП, Представитель ЮЛ*/
      if (this.type === 'INDIVIDUAL' ||
        this.type === 'REPRESENTATIVE_OF_INDIVIDUAL' ||
        this.type === 'REPRESENTATIVE_OF_LEGAL_WITH_SIMPLE_SIGNATURE') {

        return { type: 'CONSUMER', title: 'Данные потребителя' };
      }

      if (this.type === 'GENERAL_DIRECTOR') {

        return { type: 'GENERAL_DIRECTOR', title: 'Данные для МЧД' };
      }

      return '';
    },
    requiredForType() {
      if (this.userType.type === 'CONSUMER' ||
        this.type === 'EMPLOYEE_WITH_SIGNING_RIGHTS' ||
        this.type === 'CONSUMER_EMPLOYEE_WITH_SIMPLE_SIGNATURE' ||
        this.type === 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS') {

        return 'required';
      }
      if ((this.type === 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS' ||
          this.type === 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS' || this.type === 'GENERAL_DIRECTOR') &&
        (
          this.internalUser.identityDocumentInfo.series?.length > 0 ||
          this.internalUser.identityDocumentInfo.number?.length > 0 ||
          this.internalUser.identityDocumentInfo.issuerName?.length > 0 ||
          this.internalUser.inn?.length > 0 ||
          this.internalUser.identityDocumentInfo.issueDate?.length > 0 ||
          this.internalUser.identityDocumentInfo.issuerCode?.length > 0 ||
          this.internalUser.birthPlace?.length > 0 ||
          this.internalUser.address?.length > 0
        )) {

        return 'required';
      }
      return '';
    },
    requiredByIndividual() {
      if (this.type !== 'INDIVIDUAL') {
        return 'required';
      }
      if (this.internalUser.bankRequisites?.settlementAccount.length > 0 ||
        this.internalUser.bankRequisites?.correspondentAccount.length > 0 ||
        this.internalUser.bankRequisites?.bik.length > 0 ||
        this.internalUser.bankRequisites?.bankName.length > 0) {
        return 'required';
      }
      return '';
    },
    isDisableSendPhoneCode() {
      const isOldNumberSameNewFormattedPhoneAndIsValidPhone = this.oldData?.phone === formattingData.unformatPhonePrefix(this.internalUser.phone) || !this.isPhone(this.internalUser.phone);
      const isOldNumberSameNewPhone = this.oldData?.phone === this.internalUser.phone;

      const result = isOldNumberSameNewFormattedPhoneAndIsValidPhone || isOldNumberSameNewPhone;
      return result;
    },
    isDisableSendEmailCode() {
      return this.oldData?.email === this.internalUser.email || !this.isEmail(this.internalUser.email);
    },
    isDisableSendAdditionalPhoneNumberCode() {
      const isOldNumberSameNewFormattedPhone = this.oldData?.additionalPhoneNumber === formattingData.unformatPhonePrefix(this.internalUser.additionalPhoneNumber);
      const isValidPhone = !this.isPhone(this.internalUser.additionalPhoneNumber);
      const isOldNumberSameNewPhone = this.oldData?.additionalPhoneNumber === this.internalUser.additionalPhoneNumber;

      const result = isOldNumberSameNewFormattedPhone || isValidPhone || isOldNumberSameNewPhone;
      return result;
    },
    isDisableSendAdditionalEmailCode() {
      return this.oldData?.additionalEmail === this.internalUser.additionalEmail || !this.isEmail(this.internalUser.additionalEmail);
    },
    isDisplayOgrn() {
      return this.isPlatformWorker && this.type === 'registration' && this.isLegalEntity;
    },
    subdivisions() {
      const subdivisions = [];
      this.getRoles.filter((it) => !it.isCompanyOwner && it.subdivision).forEach((role) => {
        if (!subdivisions.find((subdivision) => subdivision.id === role.subdivision.id)) {
          subdivisions.push({ name: role.subdivision.name, id: role.subdivision.id, label: role.subdivision.name, value: role.subdivision.id });
        }
      });
      return [{ name: 'Без подразделения', label: 'Без подразделения', value: 'Без подразделения' }, ...subdivisions];
    },
    roles() {
      return this.getRoles
          .filter((it) => !it.isCompanyOwner && ((!it.subdivision && this.internalUser.subdivision === 'Без подразделения') || (it.subdivision?.id === this.internalUser.subdivision)))
          .map((item) => ({ ...item, label: item.name, value: item.id }));
    },
    ...mapGetters('dataLists', ['documentTypeList']),
    ...mapGetters('user', ['isPlatformWorker']),
    ...mapGetters('employees', ['getRoles']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.internalUser = merge(this.emptyUser, val);
      },
    },
    internalUser: {
      deep: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
    internalCompany: {
      deep: true,
      handler(val) {
        this.$emit('updateCompany', val);
      },
    },
    'internalUser.password'(val) {
      if (val.match(/[а-яА-ЯЁё]/)) {
        setTimeout(() => {
          this.internalUser.password = val.replaceAll(/[а-яА-ЯЁё]/g, '');
        }, 10);
        this.passwordSymbolsError = true;
      }
    },
    kpp(val) {
      this.$emit('changeKpp', val);
    },
    editable(val) {
      if (val) {
        this.$emit('saveForRevert', this.internalUser);
      }
    },
    company: {
      immediate: true,
      handler(val) {
        if (!this.internalCompany.name) {
          this.internalCompany = { ...val };
        }
      },
    },
  },
  methods: {
    loadData() {
      if (this.type !== 'registration') {
        this.getTelegramBotInfo().then((res) => this.telegramBotInfo = res).catch(() => console.log('failed to load telegram bot creds'));
      }
    },
    setBankInfo(value) {
      this.internalUser.bankRequisites.bik = value.bik;
    },
    codeEntered(code, field) {
      this.internalUser[field] = code;
    },
    async codeRequest(contact) {
      const isPhone = this.isPhone(this.internalUser[contact]);

      if (!isPhone && !this.isEmail(this.internalUser[contact])) {
        return;
      }

      try {
        this.$set(this.isSendCode, contact, true);
        await this.sendUsernameVerificationCode({ username: isPhone ? formattingData.unformatPhonePrefix(this.internalUser[contact]) : this.internalUser[contact] });
      } catch (e) {
        console.log(e);
        const errorData = HTTPError.network(e);

        this.setNotification({ message: errorData.message });
      } finally {
        this.$set(this.isSendCode, contact, false);
      }
    },
    unformatPhonePrefix(phone) {
      return formattingData.unformatPhonePrefix(phone);
    },
    ...mapActions('cabinet', ['getTelegramBotInfo']),
    ...mapActions('user', ['sendUsernameVerificationCode', 'setNotification']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.page-form__form {
  &-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }

    &__required-note {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #71757A;
      position: absolute;
      top: 0;
      right: -290px;

      &--grey {
        right: -360px;
        width: 320px;
        padding: 16px;
        border-radius: 24px;
        background-color: #F5F6F6;
      }

      @media (max-width: 1400px) {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  &-divider {
    margin-bottom: 24px;
    height: 1px
  }

  &-ul-note {
    color: #EB5757;
    list-style-type: disc;
    margin-top: 24px;
    margin-left: 68px;

    li {
      margin-bottom: 8px;
    }

  }

  &-sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &__telegram-label {
    font-weight: 400;
    font-size: 14px;
    color: #141412;
    width: 100%;
  }

  &__place-below {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
