import { render, staticRenderFns } from "./CreatePlanConsignment.vue?vue&type=template&id=2cf89b98&scoped=true"
import script from "./CreatePlanConsignment.vue?vue&type=script&lang=js"
export * from "./CreatePlanConsignment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf89b98",
  null
  
)

export default component.exports