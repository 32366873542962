<template>
  <div>
    <pdf-download/>
  </div>
</template>

<script>
import PdfDownload from 'atoms/icons/action/PdfDownload.vue';
export default {
  name: 'VTableCellPdfDownload',
  components: { PdfDownload },
};
</script>

<style scoped>

</style>
