import { publicClient } from 'lib/utils/axios';

export const submitDocument = (data) => publicClient.post('/submitDocument', data, {
  headers: { 'Content-Type': 'text/plain' },
});

export const submitSignedDocument = (data) => publicClient.post('/submitSignedDocument', data, {
  headers: { 'Content-Type': 'text/plain' },
});

export const getUserData = () => publicClient.get('/token');

export const getGqlUrl = () => publicClient.get('/gqlUrl');

export default {
  submitDocument,
  getUserData,
  getGqlUrl,
  submitSignedDocument,
};
