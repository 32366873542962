var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isModalVisible)?_c('draggable',{staticClass:"draggable-modal"},[_c('template',{slot:"header"},[_c('div',{staticClass:"draggable-modal__header"},[_c('button',{on:{"click":_vm.changeModalBodyVisible}}),_c('button',{staticClass:"btn-close",on:{"click":_vm.close}})])]),_c('template',{slot:"body"},[(true)?_c('modal',{attrs:{"is-btn-close-visible":false,"is-backdrop":false,"modal-wrapper-style":{
        position: 'relative',
      },"header-style":{
        height: '0',
        minHeight: '0',
      },"body-style":{
        padding: '0',
      }}},[_c('template',{slot:"body"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modalBodyVisible),expression:"modalBodyVisible"}]},[_vm._t("modalBody")],2)])],2):_vm._e()],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }