<template>
  <div class="header">
    <h1>{{ title }}, {{ uuid }}</h1>
    <div v-for="(item, index) in statusForMap" :key="index" class="header__status">
      <img v-if="item.type === 'qualityIndicators'" :style="item.style"
           src="~@/assets/icons/status/quality-indicator.svg"/>
      <img v-else-if="item.type === 'readingsTransmitted'" :style="item.style"
           src="~@/assets/icons/status/readings-transmitted.svg"/>
      <img v-else-if="item.type === 'sealStatus'" :style="item.style" src="~@/assets/icons/status/seal-status.svg"/>
      <div v-else :style="item.style" class="header__icon-is-ok"></div>
      &nbsp;{{ item.label }}
    </div>
    <div v-if="labelRequired" class="header__label-required">
      * поля, обязательные для заполнения
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeterHeader',
  props: {
    title: {
      type: String,
      default: '-',
    },
    uuid: {
      type: String,
      default: '-',
    },
    status: {
      type: Array,
      default: () => [],
    },
    labelRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusForMap() {
      return this.status.map((item) => ({
          type: item.type,
          label: this.getStatusMap(item.type)[item.value].label,
          style: { background: this.getStatusMap(item.type)[item.value].color },
        }));
    },
  },
  methods: {
    getStatusMap(type) {
      switch (type) {
        case 'isOk': {
          return {
            NEW: { label: 'Новая', color: '#FF7400' },
            SETTLED: { label: 'Урегулирована', color: '#4CCB40' },
            UNSETTLED: { label: 'Не урегулирована', color: '#EB5757' },
            ARCHIVED: { label: 'В архиве', color: '#EB5757' },
            IN_APPLICATION: { label: 'В заявке на ТП', color: '#EB5757' },
            CONNECTED: { label: 'Прошла ТП', color: '#4CCB40' },
            EXCLUDED: { label: 'Удалена', color: '#DFE1E6' },
          };
        }
        case 'qualityIndicators': {
          return {
            OK: { label: 'Поверен', fill: '#4CCB40' },
            NOT_TRANSFERRED: { label: '', fill: '#FF7400' },
            PROBLEM: { label: 'Не поверен', fill: '#EB5757' },
            WITHDRAWN: { label: 'Выведен', fill: '#EB5757' },
          };
        }
        case 'readingsTransmitted': {
          return {
            OK: { label: 'Есть связь', fill: '#4CCB40' },
            NOT_TRANSFERRED: { label: '', fill: '#FF7400' },
            PROBLEM: { label: 'Нет связи', fill: '#EB5757' },
          };
        }
        case 'sealStatus': {
          return {
            OK: { label: 'Пломбы целы', fill: '#4CCB40' },
            NOT_TRANSFERRED: { label: '', fill: '#FF7400' },
            PROBLEM: { label: 'Пломбы сорваны', fill: '#EB5757' },
          };
        }
        default: {
          return {
            OK: { label: 'Ok', color: '#4CCB40' },
            NOT_TRANSFERRED: { label: 'Нет данных', color: '#FF7400' },
            PROBLEM: { label: 'Проблемы', color: '#EB5757' },
          };
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  h1 {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    margin-right: 48px;
    margin-bottom: 6px;
  }

  &__status {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #C1C4C7;
    border-radius: 16px;
    padding: 3px 14px 3px 14px;
    margin-right: 16px;
  }

  &__icon-is-ok {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 24px;
  }

  &__label-required {
    position: absolute;
    top: 84px;
    right: -298px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757A;

    @media (max-width: 1400px) {
      position: relative;
      top: 0;
      right: 0;
      width: 848px;
      margin-top: 16px;
    }
  }

}
</style>
