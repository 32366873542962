<template>
  <div class="employee-select-or-input">
    <!-- TODO: нулевой сотрдник (гендир) должен отображаться по умолчанию -->
    <div v-if="internalMode === 'select'">
      <employee-selector
        :editable="editable"
        :employees="employees.getEmployees()"
        :init-employee="selectedEmployee"
        @select="selectHandler"
      />
      <signing-basis-selector
        :editable="editable"
        :init-reason-id="initEmployee?.reason?.id"
        :input-mode-type="selectedEmployee.role.isCompanyOwner ? 'charter' : 'powerOfAttorney'"
        :power-of-attorney-list="powerOfAttorneyList"
        @select="selectReasonHandler"
      />
    </div>

    <div
      v-if="internalMode === 'input'"
    >
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.surname"
            :disabled="!editable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"
            title="Фамилия *"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.name"
            :disabled="!editable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"
            title="Имя *"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.patronymic"
            :disabled="!editable"
            title="Отчество"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-input
            v-model="selectedEmployee.role.name"
            :disabled="!editable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"
            title="Должность подписывающего лица *"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div
          class="col col--1of1">
          <select
            v-model="selectedEmployee.reason.reasonDocument"
            :disabled="!editable"
            class="employee-select-or-input__select"
          >
            <option
              v-for="(reason, index) in reasons"
              :key="index"
              :value="reason"
            >
              {{ reason }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-if="selectedEmployee.reason.reasonDocument === 'Доверенность'"
        class="row mb-24"
      >
        <div class="col col--1of2">
          <v-input
            v-model="selectedEmployee.reason.number"
            :disabled="!editable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"
            title="Номер документа *"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            v-model="selectedEmployee.reason.date"
            :disabled="!editable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"

            title="Дата документа *"
          />
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { EmployeeSelector, VDatePicker, VInput } from '@/components';

import { Employees } from '@/services/employees';
import SigningBasisSelector from 'components/DocumentSideSelectOrInput/SigningBasisSelector.vue';

export default {
  components: {
    VDatePicker,
    VInput,
    EmployeeSelector,
    SigningBasisSelector,
  },
  props: {
    inn: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    permission: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      validator: (mode) => ['select', 'input', 'auto'].includes(mode),
      default: 'auto',
    },
    error: {
      type: Object,
      default: () => ({
        select: false,
        fullName: {
          name: false,
          surname: false,
          patronymic: false,
        },
        role: false,
        reason: {
          reasonDocument: false,
          number: false,
          date: false,
        },
      }),
    },
    initEmployee: {
      type: Object,
      required: false,
    },
    isCompanyMode: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.employees = new Employees(this.isCompanyMode);
    await this.updateValue();
  },
  data() {
    return {
      employees: null,
      // internalMode: select или input
      internalMode: null,
      reasons: ['Устав', 'Доверенность'],
      selectedEmployee: {
        id: null,
        userId: null,
        accountId: null,
        fullName: {
          name: '',
          surname: '',
          patronymic: '',
        },
        position: '',
        role: {
          id: null,
          name: '',
          isCompanyOwner: null,
        },
        reason: {
          id: null,
          reasonDocument: 'Устав',
          number: '',
          date: '',
        },
        powerOfAttorney: null,
      },
    };
  },
  computed: {
    powerOfAttorneyList() {
      return [...this.selectedEmployee.powerOfAttorney];
    },
  },
  watch: {
    selectedEmployee: {
      deep: true,
      immediate: true,
      handler(updatedSelectedEmployee) {
        if (this.inn) {
          updatedSelectedEmployee.inn = this.inn;
        }
        this.$emit('employeeSelectOrInput', { ...updatedSelectedEmployee });
      },
    },
    inn: {
      async handler(current, previous) {
        if (current === previous) return;
        if (this.inn !== this?.initEmployee?.inn) {
          this.setEmptyEmployee();
        }
        await this.updateValue();
      },
    },
  },
  methods: {
    setEmptyEmployee() {
      this.selectedEmployee = {
        id: null,
        userId: null,
        accountId: null,
        fullName: {
          name: '',
          surname: '',
          patronymic: '',
        },
        position: '',
        role: {
          id: null,
          name: '',
          isCompanyOwner: null,
        },
        reason: {
          id: null,
          reasonDocument: 'Устав',
          number: '',
          date: '',
        },
        powerOfAttorney: null,
      };
      this.internalMode = 'input';
    },
    async updateValue() {
      if (this.inn) {
        try {
          await this.fetchSupplierEmployeesByInnAndPermission(this.inn, this.permission);
        } catch (e) {
          console.log('Error');
          this.setEmptyEmployee();
        }
      }
      this.setMode();
      this.emitSupplierOnPlatform();

      if (this.internalMode === 'select') {
        let employee;
        let powerOfAttorney;
        if (this.initEmployee?.userId) {
          employee = { ...this.employees.getEmployees().find((employee) => employee.userId === this.initEmployee.userId) };
        }
        if (!employee) {
          employee = { ...this.employees.getEmployees()[0] };
        }
        if (this.initEmployee?.reason?.id) {
          powerOfAttorney = { ...employee.powerOfAttorney.find((poa) => poa.id === this.initEmployee.reason.id) };
        }
        if (!powerOfAttorney && employee.powerOfAttorney?.length > 0) {
          powerOfAttorney = { ...employee.powerOfAttorney[0] };
        }
        if (powerOfAttorney) {
          employee.reason = {
            id: powerOfAttorney.id,
            reasonDocument: 'Доверенность',
            date: powerOfAttorney.grantedAt,
            number: powerOfAttorney.number,
          };
        } else {
          employee.reason = {
            reasonDocument: 'Устав',
            id: null,
            date: null,
            number: null,
          };
        }
        this.selectedEmployee = { ...employee };
        return;
      }
      if (this.initEmployee) {
        this.selectedEmployee = { ...this.initEmployee };
      }
    },
    emitSupplierOnPlatform() {
      this.$emit('companyOnPlatform', this.employees.getSupplierOnPlatform());
    },
    async fetchSupplierEmployeesByInnAndPermission(inn, permission) {
      try {
        await this.employees.fetchSupplierEmployeesByInnAndPermission(inn, permission);
      } catch (error) {
        console.log(error);
        this.internalMode = 'input';
      }
    },
    setMode() {
      if (this.mode !== 'auto') {
        this.internalMode = this.mode;
        return;
      }

      if (this.employees.getSupplierOnPlatform() && this.employees.getEmployees().length !== 0) {
        this.internalMode = 'select';
        return;
      }
      this.internalMode = 'input';
    },
    selectHandler(selectedEmployee) {
      selectedEmployee.reason = { ...this.selectedEmployee.reason };
      this.selectedEmployee = { ...selectedEmployee };
    },
    selectReasonHandler(reason) {
      this.selectedEmployee.reason = { ...reason };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";

.employee-select-or-input {
  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;
    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
    }

    &_error {
      border-color: #EB5757;
    }
  }
}
</style>
