const TITLE_TARIFFS_CHANGING = {
  Customer: {
    text: 'Смена тарифа на электроэнергию',
    subTitle: '',
  },
  Retailer: {
    text: 'Рассмотрите Заявление о смене тарифа',
    subTitle: '',
  },
};

const TITLE = {
  tariffChangingApplication: TITLE_TARIFFS_CHANGING,
};

const STATUS_LIST_TARIFFS_CHANGING = {
  Customer: [
    {
      // id: 0,
      title: 'на рассмотрении',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Отклонено',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Принято',
      subTitle: '',
    },
  ],
  Retailer: [
    {
      // id: 0,
      title: 'начать выполнение',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Завершить задачу',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Отклонено',
      subTitle: '',
    },
    {
      // index: 3,
      title: 'Принято',
      subTitle: '',
    },
  ],
};

const STATUS_LIST = {
  tariffChangingApplication: STATUS_LIST_TARIFFS_CHANGING,
};

const TARIFFS_CHANGING = {
  SUBMITTED: {
    Customer: {
      statusIndex: 0,
      isActive: false,
      isDisplayAction: false,
    },
    Retailer: {
      statusIndex: 0,
      isActive: true,
      isDisplayAction: true,
    },
  },
  AT_WORK: {
    Customer: {
      statusIndex: 0,
      isActive: false,
      isDisplayAction: false,
    },
    Retailer: {
      statusIndex: 1,
      isActive: true,
      isDisplayAction: true,
      startedTask: true,
    },
  },
  REJECTED: {
    Customer: {
      statusIndex: 1,
      isActive: false,
      isDisplayAction: false,
      isApproved: false,
    },
    Retailer: {
      statusIndex: 2,
      isActive: false,
      isDisplayAction: false,
      isApproved: false,
    },
  },
  APPROVED: {
    Customer: {
      statusIndex: 2,
      isActive: false,
      isDisplayAction: false,
      isApproved: true,
    },
    Retailer: {
      statusIndex: 3,
      isActive: false,
      isDisplayAction: false,
      isApproved: true,
    },
  },

};

const PROCESS_DATA = {
  tariffChangingApplication: TARIFFS_CHANGING,
};

const userType = {
  Customer: 'Customer',
    Retailer: 'Retailer',
    SUPPLIER_OF_LAST_RESORT: 'Retailer',
};

export function initTariffsChangingTaskData(task) {
  const title = TITLE[task.workflowType][userType[task.userType]];
  const statusList = STATUS_LIST[task.workflowType][userType[task.userType]];

  const stageData = PROCESS_DATA[task.workflowType];

  const data = {
    ...stageData[task.stage][userType[task.userType]],
    title: `${title.text}`,
    region: task.region,
    year: task.year,
    statusList: statusList,
    documentId: task.documentId,
    workflowId: task.workflowId,
    expiresAt: task.expiresAt,
    userType: userType[task.userType],
  };

  return {
    title: title,
    status: statusList[data.statusIndex],
    data: data,
  };
}
