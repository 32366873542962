<template>
  <modal
    :body-style="{
      padding: '24px 0',
    }"
    :header-style="{
      display: 'flex',
      justifyContent: 'center',
    }"
    :is-btn-close-visible="false"
    :modal-style="{
      borderRadius: '16px',
      padding: '50px 100px',
    }"
    class="added-members-modal"
  >
    <template #header>
      <span class="added-members-modal__title">Лист согласования</span>
    </template>
    <template #body>
      <div v-if="mode === 'addMembers'" style="display: flex; flex-direction: column;">
        <span class="added-members-modal__sub-title">
          Добавить сотрудника:
        </span>
        <span class="added-members-modal__position-fullname" style="margin-bottom: 8px;">
          Должность и ФИО
        </span>
        <v-select
          :options="userEmployees"
          @input="addToContractMembers"
        />
        <v-divider style="margin: 24px 0;"/>
        <span class="added-members-modal__sub-title">Добавленные сотрудники:</span>
        <div style="display: flex; flex-direction: column;">
          <div v-for="(employee, index) in [...selectedEmployees]" :key="index"
               style="margin-bottom: 16px;">
            <span class="added-members-modal__position">
              {{ employee.position }}
            </span>
            <span class="added-members-modal__fullname">
            {{ employee.name }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="mode === 'editMember'" style="display: flex; flex-direction: column;">
        <span class="added-members-modal__sub-title">
          Заменить сотрудника {{ `${currentMember.value} (${currentMember.label.replace(': ', '')})` }}
        </span>
        <span class="added-members-modal__position-fullname" style="margin-bottom: 8px;">
          Должность и ФИО
        </span>
        <v-select
          :options="userEmployees"
          @input="replacingCurrentMemberToSelected"
        />
      </div>
    </template>
    <template #footer>
      <div style="display: flex; justify-content: space-around;">
        <v-button
          @click="save"
        >
          Сохранить
        </v-button>
        <v-button
          variant="outlined"
          @click="cancel"
        >
          Отменить
        </v-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal, VButton, VDivider, VSelect } from 'components';
import { mapActions, mapGetters } from 'vuex';

import { getSurnameAndInitials } from 'utils/user';
import { checkField } from 'utils/common';

export default {
  components: {
    Modal,
    VDivider,
    VSelect,
    VButton,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: (mode) => ['addMembers', 'editMember'].includes(mode),
    },
    currentMember: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      memberForChange: null,
      membersForAdd: [],
    };
  },
  computed: {
    userEmployees() {
      if (!this.getContract.userEmployees) return [];
      return this.getContract.userEmployees.map((employee) => ({
        ...employee,
        user: employee?.user,
        label: `${checkField(employee?.role?.name)}: ${getSurnameAndInitials(employee?.user?.fullName)}`,
        value: employee.id,
      })).filter((employee) => ![...this.selectedEmployees].some((selected) => selected.id === employee.id));
    },
    selectedEmployees() {
      if (!this.getContract?.userEmployeesContractMembers) return [];
      return [...this.getContract.userEmployeesContractMembers, ...this.membersForAdd].map((employee) => ({
        id: employee.id,
        user: employee?.user,
        position: `${checkField(employee?.role?.name)}: `,
        name: getSurnameAndInitials(employee?.user?.fullName),
        label: `${checkField(employee?.role?.name)}: ${getSurnameAndInitials(employee?.user?.fullName)}`,
        value: employee.id,
      }));

    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
    ...mapGetters('user', ['getUser']),
  },
  methods: {
    save() {
      if (this.mode === 'editMember') {
        this.saveChangedMember();
      }
      if (this.mode === 'addMembers') {
        this.saveAddedMember();
      }
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.membersForAdd = [];
      this.memberForChange = null;
      this.$emit('close');
    },
    addToContractMembers(memberId) {
      const userEmployeesContractMember = this.getContract.userEmployees.find((employee) => employee.id === memberId);
      userEmployeesContractMember.isInitiator = false;
      this.membersForAdd.push(userEmployeesContractMember);
    },
    saveChangedMember() {
      if (!this.memberForChange) return;
      const membersWithoutMemberExcess = this.getContract.userEmployeesContractMembers.filter((employee) => employee.id !== this.currentMember.id);

      membersWithoutMemberExcess.push(this.memberForChange);

      if (!this.getContract.userEmployeesContractMembers.find((stateMember) => stateMember.id === this.memberForChange.id)) {
        this.setUserRemoveEmployeesContractMembers({ userEmployeesContractMembers: membersWithoutMemberExcess });
      }
    },
    saveAddedMember() {
      this.membersForAdd.forEach((employee) => {
        this.pushToUserEmployeesContractMembers({ userEmployeesContractMember: employee });
      });
    },
    replacingCurrentMemberToSelected(selectedMemberValue) {
      this.memberForChange = this.getContract.userEmployees.find((employee) => employee.id === selectedMemberValue);
    },
    ...mapActions('electricityTransmissionContract', [
      'removeUserEmployees',
      'pushToUserEmployeesContractMembers',
      'setUserRemoveEmployeesContractMembers',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.added-members-modal {
  &__sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 24px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  &__position-fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #71757A;
  }

  &__position {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
