<template>
  <div class="form-contract-info">
    <div class="row mb-24">
      <p class="col col--1of1 form-contract-info__title">Параметры {{ contractType }}</p>
    </div>

    <div v-if="isOldContract" class="row mb-24">
      <div class="col col--1of2">
        <v-input
          v-model="contractNumber"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          :title="`Номер ${contractType} *`"
          clearable
          rules="required"
        />
      </div>
      <div class="col col--1of2">
        <v-date-picker
          v-model="conclusionDate"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          :title="`Дата заключения ${contractType} *`"
          rules="required"
        />
      </div>
    </div>

    <div class="row mb-24">
      <div class="col col--1of3">
        <v-date-picker
          v-model="effectiveDate"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          :title="`Дата вступления в силу ${contractType} *`"
          rules="required"
        />
      </div>
      <div class="col col--1of3">
        <v-date-picker
          v-model="expiresDate"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          :title="`Дата истечения ${contractType} *`"
          rules="required"
        />
      </div>
      <div class="col col--1of3" style="display: flex; gap: 8px;">
        <input
          v-model="prolongation"
          type="checkbox"
        />
        <label class="checkBoxText">Автоматическая пролонгация {{contractType}}</label>
      </div>
    </div>

    <div class="row mb-24">
      <div class="col col--1of1">
        <span class="region-title">Место заключения {{contractType}} *</span>
        <v-input
          v-model="region"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          clearable
          rules="required"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col--1of1">
        <v-input
          v-model="igk"
          :title="`Идентификатор государственного контракта`"
          clearable
          rules="numeric|min:20|max:25"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VInput from 'components/VInput/VInput.vue';
import VDatePicker from 'components/VDatePicker/VDatePicker.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ContractInfo',
  components: { VDatePicker, VInput },
  computed: {
    contractNumber: {
      get() {
        return this.getContract.contractNumber;
      },
      set(value) {
        this.setContractNumber({ number: value });
      },
    },
    conclusionDate: {
      get() {
        return this.getContract.conclusionDate;
      },
      set(value) {
        this.setConclusionDate({ date: value });
      },
    },
    effectiveDate: {
      get() {
        return this.getContract.effectiveDate;
      },
      set(value) {
        this.setEffectiveDate({ date: value });
      },
    },
    expiresDate: {
      get() {
        return this.getContract.expiresDate;
      },
      set(value) {
        this.setExpiresDate({ date: value });
      },
    },
    prolongation: {
      get() {
        return this.getContract.prolongation;
      },
      set(value) {
        this.setProlongation({ prolongation: value });
      },
    },
    region: {
      get() {
        return this.getContract.region;
      },
      set(value) {
        this.setRegion({ region: value });
      },
    },
    igk: {
      get() {
        return this.getContract.igk;
      },
      set(value) {
        this.setIgk({ igk: value });
      },
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договора';
      }
        return 'доп.соглашения';

    },
    isOldContract() {
      return this.getContract.action === 'upload';
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
  methods: {
    ...mapActions(
'electricityTransmissionContract',
      [
        'setContractNumber',
        'setConclusionDate',
        'setEffectiveDate',
        'setExpiresDate',
        'setProlongation',
        'setRegion',
        'setIgk',
      ],
),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";

.form-contract-info {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;
  }
  .region-title {
    font-size: 14px;
    color: #71757a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }
  .checkBoxText {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
    margin: auto 0;
  }
}
</style>
