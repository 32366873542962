<template>
  <div :class="$style.container">
    <div @click="onShownTaskDetails">
      <div :class="$style.content">

        <div :class="$style.moreIcon">
          <more-vert-icon />
        </div>

        <div :class="$style.title" :role="'document[' + index + '].title'">
          {{ document.title }}
        </div>

        <div :class="$style.subtitle">
          <p v-if="document.subtitle" :role="'document[' + index + '].subtitle'">{{ formatSubtitle(document.subtitle) }}</p>
        </div>

<!--        <div :class="$style.subtitle">-->
<!--          <p v-if="document.counterparties" :role="'document[' + index + '].counterparties'">{{ document.counterparties }}</p>-->
<!--        </div>-->

        <div :class="$style.subtitle">
          <p v-if="document.formattedDate" :role="'document[' + index + '].date'">от {{ formatDate(document.formattedDate) }} г.</p>
        </div>

        <div :class="$style.expairedDate" >
          <p :role="'document[' + index + '].status'">{{ getStatus(document.status) }}</p>
        </div>
        <horizontal-progress-bar v-if="isWaitingDownload" />
      </div>
    </div>

    <template v-if="document.__typename === 'ElectricityTariffsDocumentView'">
      <TariffsCard v-model="visible" :title="document.title" :tariff-id="document.electricityTariffsOrderId" @tariff-changing="tariffChanging" />
      <tariffs-changing v-model="displayTariffsChanging" :service-location="tariffsChangingServiceLocation" />
    </template>
  </div>
</template>

<script>
import MoreVertIcon from 'atoms/icons/basic/MoreVert.vue';
import TariffsCard from 'organisms/tariffs/TariffsCard';
import { mapActions } from 'vuex';
import { getDocument } from 'lib/utils/downloadDocuments';
import { showPdf } from 'lib/utils/pdf';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';

export default {
  name: 'Card',
  components: {
    MoreVertIcon,
    TariffsCard,
    TariffsChanging: () => import('organisms/tariffs/TariffsChanging.vue'),
    HorizontalProgressBar,
  },
  props: {
    document: Object,
    index: Number,
  },
  data() {
    return {
      dataUrl: undefined,
      isLoading: true,
      visible: false,
      displayTariffsChanging: false,
      tariffsChangingServiceLocation: {},
      isWaitingDownload: false,
    };
  },
  methods: {
    async onShownTaskDetails() {
      if (this.document.__typename === 'ElectricityTariffsDocumentView') {
        this.visible = true;
      } else {
        if (!this.document.documentId) {
          return;
        }
        this.isWaitingDownload = true;
        try {
          const document = await getDocument(this.document.documentId, this.showWarning);
          showPdf(document);
        } catch (e) {
          alert('Ошибка загрузки файла');
        } finally {
          this.isWaitingDownload = false;
        }
      }
    },
    formatSubtitle(text) {
      text = text.split('от')[0];

      return text;
    },
    formatDate(date) {
      if (date === '') {
        return '';
      }
        date = date.replace(/-/gi, '.').split('.').reverse()
.join('.');


      return date;
    },
    getStatus(statusId) {
      switch (statusId) {
        case 'ACTING': {
          return 'ДЕЙСТВУЮЩИЙ';
        }
        case 'COMPLETED': {
          return 'ЗАКЛЮЧЕНА';
        }
        case 'FORMED': {
          return 'СФОРМИРОВАН';
        }
        case 'CANCELLED': {
          return 'АННУЛИРОВАН';
        }
        case 'DRAFT': {
          return 'СОЗДАНА';
        }
      }
      return statusId;
    },
    async tariffChanging(serviceLocation) {
      this.tariffsChangingServiceLocation = serviceLocation;
      this.displayTariffsChanging = true;
    },
    showWarning() {
      this.vueShowNotification(
        'Предупреждение',
        '<p>Формирование документа выполняется в фоновом режиме и может занимать до 15 минут. Просим подождать.</p>',
      );
    },
    ...mapActions('document', ['getDocumentByUrl']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 452px
    position: relative
    background-color: #fff
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    margin-bottom: 16px
    margin-right: 16px

    &:hover
      cursor: pointer

  .content
    display: flex
    flex-direction: column
    align-items: space-between
    padding: 16px 32px 16px 16px

  .moreIcon
    position: absolute
    top: 8px
    right: 8px

  .title
    margin: 0 0 8px
    font-family: 'Roboto Condensed'
    font-weight: 400
    @media screen and (min-width: 800px)
      font-size: 20px
    @media screen and (min-width: 1100px)
      font-size: 24px
    line-height: 32px
    color: #0E0F0F
    letter-spacing: 0.01em
    text-transform: uppercase

  .subtitle
    margin: 0 0 8px
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 24px
    color: #0E0F0F
    letter-spacing: 0.01em

  .description
    margin: 0 0 8px

    p
      font-family: Roboto
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: #000

    small
      font-family: Roboto
      font-weight: 400
      font-size: 12px
      line-height: 18px
      color: #000

  .process,
  .expairedDate,
  .process p
    text-align: right
    text-transform: uppercase
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 24px

  .process p
    color: #2F82DF

  .expairedDate p
    color: #54B687
</style>
