<template>
  <div :style="customStyle" class="cell">
    <div v-if="type === 'status'">
      <v-table-cell-status :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'customStatus'">
      <v-table-cell-custom-status :color="dataColor" :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'delete' && !customCheck" @click.prevent="clickByCellHandler">
      <v-table-cell-delete/>
    </div>
    <div v-else-if="type === 'crossDelete'" @click.prevent="clickByCellHandler">
      <v-table-cell-cross-delete v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'view'" @click.prevent="clickByCellHandler">
      <v-table-cell-view-eye v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'xmlDownload'" @click.prevent="clickByCellHandler">
      <v-table-cell-xml-download v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'pdfDownload'" @click.prevent="clickByCellHandler">
      <v-table-cell-pdf-download v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'fileDownload'" @click.prevent="clickByCellHandler">
      <v-table-cell-file-download v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'followLink'" @click.prevent="clickByCellHandler">
      <v-table-cell-follow-link v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'list'" @click.prevent="clickByCellHandler">
      <v-table-cell-list v-if="currentCellValue !== '-'" :spoiler="spoilerCount" :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'clip'" @click.prevent="clickByCellHandler">
      <v-table-cell-clip :custom-check="customCheck" :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'select_powerOfAttorney'"
         @click.prevent="clickByCellHandler">
      <v-table-cell-select-power-of-attorney v-if="Array.isArray(currentCellValue)" :value="currentCellValue"/>
      <span v-else>{{ currentCellValue }}</span>
    </div>
    <div v-else-if="type === 'roleModal'" @click.prevent="clickByCellHandler">
      <v-table-cell-role-modal :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'edit'" @click.prevent="clickByCellHandler">
      <v-table-cell-edit v-if="!customCheck"/>
    </div>
    <div v-else-if="type === 'input'" @click.prevent="clickByCellHandler">
      <v-table-cell-input v-if="!customCheck" v-model="currentCellValue" :validation-messages="validationMessages"
                          :validation-rules="validationRules"/>
    </div>
    <div v-else-if="type === 'button'" @click.prevent="clickByCellHandler">
      <v-table-cell-button v-if="!customCheck" :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'selectButton'">
      <v-table-cell-select-button v-if="!customCheck" :value="currentCellValue"/>
    </div>
    <div v-else-if="type === 'checkbox'">
      <v-table-cell-checkbox v-if="!customCheck" v-model="currentCellValue"/>
    </div>
    <div v-else-if="type === 'select'">
      <v-table-cell-select v-if="!customCheck" v-model="currentCellValue" :options="options"
                           :validation-messages="validationMessages"
                           :validation-rules="validationRules"/>
    </div>
    <div v-else-if="type === 'filterAndCheckbox' && customCheck">
      <v-table-cell-usage-points-check-box v-model="currentCellValue" :data="data" :is-disabled="isDisabled"
                                           @select="clickByCellHandler"/>
    </div>
    <div v-else-if="type === 'filterAndCheckbox'">
      <v-table-cell-borders-checkbox :data="data" :is-disabled="isDisabled" @select="clickByCellHandler"/>
    </div>
    <v-table-cell-edit-and-delete
      v-else-if="type === 'actionButton'"
      :is-initiator="customCheck"
      @delete="clickCellDelete"
      @edit="clickCellEdit"
    />
    <div v-else-if="type === 'editDeleteButtons'">
      <v-table-cell-edit-delete-buttons
        v-if="!customCheck"
        :type="type"
        :value="currentCellValue!=='-'?currentCellValue:undefined"
        @delete="clickCellDelete"
        @edit="clickCellEdit"
      />
    </div>
    <div v-else-if="type === 'spoiler' && currentCellValue.length > 32" @click.prevent="clickByCellHandler">
      {{ currentCellValue.substr(0, 32) }}
      <span v-if="spoiler" @click="spoiler = !spoiler">
        ...
        <b class="cell__spoiler">(показать всё)</b>
      </span>
      <span v-else>
        {{ currentCellValue.substr(32, currentCellValue.length) }}
        <b class="cell__spoiler" @click="spoiler = !spoiler">(скрыть)</b>
      </span>
    </div>
    <div v-else-if="type === 'spoilerWithoutButton' && currentCellValue.length > 32"
         @click.prevent="clickByCellHandler">
      {{ currentCellValue.substr(0, 32) }}<span v-if="spoiler">...</span>
    </div>
    <div v-else-if="type === 'documentDownload'">
      <document-downloader v-if="!customCheck" :document="currentCellValue"/>
    </div>
    <div v-else-if="type === 'spoilerButton'">
      <v-table-cell-spoiler-button
        v-if="!isNested && !customCheck"
        :is-nested-show="isNestedShow"
        @click-by-spoiler-button="clickSpoilerButtonHandler"
      />
      <span v-else>
      </span>
    </div>
    <div v-else-if="type === 'preview'">
      <v-table-cell-preview v-if="!customCheck" :value="currentCellValue" @image-click="clickByCellHandler"/>
    </div>
    <div v-else-if="type==='number'">
      {{ formattedNumberCellValue }}
    </div>
    <div
      v-else
    >
      {{ cellValue }}
    </div>
  </div>
</template>

<script>
import { VTableCellStatus } from './VTableCellStatus';
import { VTableCellCustomStatus } from './VTableCellCustomStatus';
import { VTableCellDelete } from './VTableCellDelete';
import { VTableCellViewEye } from './VTableCellViewEye';
import { VTableCellList } from './VTableCellList';
import { VTableCellClip } from './VTableCellClip';
import { VTableCellPdfDownload } from './VTableCellPdfDownload';
import { VTableCellXmlDownload } from './VTableCellXmlDownload';
import { VTableCellFollowLink } from './VTableCellFollowLink';
import { VTableCellCrossDelete } from './VTableCellCrossDelete';
import { VTableCellSelectPowerOfAttorney } from './VTableCellSelectPowerOfAttorney';
import { VTableCellRoleModal } from './VTableCellRoleModal';
import { VTableCellFileDownload } from './VTableCellFileDownload';
import { VTableCellEdit } from './VTableCellEdit';
import DocumentDownloader from '@/components/DocumentDownloader/DocumentDownloader';
import { VTableCellInput } from './VTableCellInput';
import { VTableCellCheckbox } from './VTableCellCheckbox';
import { VTableCellSelect } from './VTableCellSelect';
import { VTableCellSpoilerButton } from './VTableCellSpoilerButton';
import VTableCellEditAndDelete
  from 'components/VTableNew/common/VTableCell/VTableCellEditAndDelete/VTableCellEditAndDelete.vue';
import VTableCellEditDeleteButtons
  from 'components/VTableNew/common/VTableCell/VTableCellEditDeleteButtons/VTableCellEditDeleteButtons.vue';
import VTableCellButton from 'components/VTableNew/common/VTableCell/VTableCellButton/VTableCellButton.vue';
import VTableCellUsagePointsCheckBox
  from 'components/VTableNew/common/VTableCell/VTableCellUsagePointsCheckBox/VTableCellUsagePointsCheckBox.vue';
import VTableCellBordersCheckbox
  from 'components/VTableNew/common/VTableCell/VTableCellBordersCheckBox/VTableCellBordersCheckBox';
import VTableCellPreview from 'components/VTableNew/common/VTableCell/VTableCellPreview/VTableCellPreview.vue';
import formatting from 'lib/utils/formatting';
import VTableCellSelectButton
  from 'components/VTableNew/common/VTableCell/VTableCellSelectButton/VTableCellSelectButton.vue';

export default {
  components: {
    VTableCellSelectButton,
    VTableCellPreview,
    VTableCellBordersCheckbox,
    VTableCellUsagePointsCheckBox,
    VTableCellButton,
    VTableCellEditAndDelete,
    VTableCellInput,
    VTableCellEdit,
    VTableCellRoleModal,
    VTableCellSelectPowerOfAttorney,
    VTableCellCrossDelete,
    VTableCellFollowLink,
    VTableCellXmlDownload,
    VTableCellPdfDownload,
    VTableCellStatus,
    VTableCellDelete,
    VTableCellViewEye,
    VTableCellList,
    VTableCellClip,
    VTableCellFileDownload,
    DocumentDownloader,
    VTableCellCustomStatus,
    VTableCellCheckbox,
    VTableCellSelect,
    VTableCellSpoilerButton,
    VTableCellEditDeleteButtons,
  },
  props: {
    cellValue: {
      type: [Number, String, Array, Object, Boolean],
      // TODO: add default or required
    },
    data: {
      type: Object,
      // TODO: add default or required
    },
    type: {
      type: String,
      // TODO: add default or required
    },
    customCheck: {
      type: Boolean,
      default: false,
    },
    spoilerCount: {
      type: Number,
      default: 0,
    },
    dataColor: {
      type: String,
      required: false,
    },
    rowColor: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    validationRules: {
      type: String,
      required: false,
    },
    validationMessages: {
      type: Object,
      required: false,
    },
    isNested: {
      type: Boolean,
      default: false,
    },
    isNestedShow: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.type === 'checkbox') console.log(this.cellValue);
    this.currentCellValue = this.cellValue;
  },
  data() {
    return {
      currentCellValue: null,
      disabled: true,
      spoiler: true,
    };
  },
  computed: {
    formattedNumberCellValue() {
      return formatting.numberFormattedIfNumber(this.cellValue);
    },
    customStyle() {
      if (!this.dataColor) {
        return '';
      }

      return `
        background-color: ${this.rowColor.replace(')', ', 0.1)')};
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 0;
      `;
    },
  },
  watch: {
    cellValue() {
      if (this.type === 'checkbox' && (this.cellValue === '' || this.cellValue === '-')) {
        this.currentCellValue = false;
        return;
      }
      this.currentCellValue = this.cellValue;
    },
    currentCellValue() {
      this.$emit('on-cell-input', this.currentCellValue);
    },
  },
  methods: {

    clickSpoilerButtonHandler() {
      this.$emit('on-spoiler-button-click');
    },
    clickByCellHandler() {
      this.$emit('on-cell-click', this.currentCellValue);
    },
    clickCellDelete() {
      this.$emit('delete', this.currentCellValue);
    },
    clickCellEdit() {
      this.$emit('edit', this.currentCellValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  &__input {
    background: none;
    height: 100%;
    width: 100%;
    border: none;
  }

  &__spoiler {
    color: #2F82DF;
    text-decoration: underline;
  }
}

.table__td--row-color:first-child {
  .cell {
    border-left: solid 4px rgb(118, 170, 229);
    border-radius: 4px 0px 0px 4px;
    margin-left: -4px;

    div {
      padding-left: 16px;
    }
  }
}
</style>
