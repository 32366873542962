<template>
  <v-table-new
    v-model="transformedData"
    :template="template"
    excel-report-file-name="Доверенности"
    @on-cell-click="cellClickHandler"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { convertDbFormat } from 'lib/utils/date';
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import { convertToLabelValueList } from '@/utils/list';

export default {
  components: {
    VTableNew,
  },
  props: {
    powerOfAttorneys: {
      type: Array,
      required: true,
    },
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Статус',
            key: 'status',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {
              type: 'select',
              values: convertToLabelValueList(['Сформирована', 'Отменена', 'Отклонена', 'Просрочена', 'Действует', 'Удалена']),
            },
          },
          {
            label: 'Тип доверенности',
            key: 'kind',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {
              type: 'select',
              values: convertToLabelValueList(['Действующая доверенность', 'МЧД', 'Доверенность, сформированная во внешней системе']),
            },
          },
          {
            label: 'Название доверенности',
            key: 'title',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Кому выдана',
            key: 'to',
            thStyle: {
              minWidth: '80px',
            },
            sort: {},
            filter: { type: 'select', values: convertToLabelValueList(this.getEmployeesNames()) },
          },
          {
            label: 'Дата начала действия',
            key: 'startsFrom',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Дата истечения',
            key: 'expiresAt',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Полномочия',
            key: 'permissions',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            type: 'list',
            spoilerCount: 3,
            sort: {},
          },
          {
            label: 'Кем выдана',
            thStyle: {
              minWidth: '80px',
            },
            key: 'from',
            sort: {},
          },
          {
            label: 'Дата выдачи',
            key: 'grantedAt',
            thStyle: {
              minWidth: '80px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedData() {
      return this.powerOfAttorneys.map((it) => {
        const status = this.getStatus(it);

        return {
          ...it,
          from: this.userNameToString(it.initiatorUser?.fullName),
          to: this.userNameToString(it.grantedToUser?.fullName),
          grantedAt: convertDbFormat(it.grantedAt),
          startsFrom: convertDbFormat(it.startsFrom),
          expiresAt: convertDbFormat(it.expiresAt),
          permissions: it.permissions.map((item) => ({ value: item.code, tooltip: item.description })),
          document: { documentId: it.documentId, name: it.title },
          status: status.title,
          tdStyle: {
            status: status.style,
          },
          kind: this.getKind(it.kind),
          statusDb: it.status,
        };
      });
    },
    ...mapGetters('user', ['getUser']),
  },
  methods: {
    getEmployeesNames() {
      return this.getEmployees().filter((item) => !item.role?.isCompanyOwner).map((item) => this.userNameToString(item.user.fullName));
    },
    async cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          if (cell.row.statusDb === 'REJECTED' && this.getUser.id === cell.row.initiatorUser.id) {
            return await this.$router.push({
              name: 'cabinet.approve-document',
              params: {
                id: cell.row.id,
                from: 'attorney',
                fromTitle: 'ДОВЕРЕННОСТИ',
                to: 'documentReload',
                rejected: true,
                completed: true,
                title: `Исправьте ${cell.row.title}`,
              },
            });
          }
          return this.link(cell.row);
        }
      }
    },
    link(task) {
      let workflowType;
      switch (task.status) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      console.log(workflowType);
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: task.id,
          from: 'attorney',
          fromTitle: 'Доверенности',
          to: workflowType,
        },
      });
    },
    userNameToString(fullName) {
      if (!fullName) {
        return '';
      }
      return `${fullName.surname} ${fullName.name}${fullName.patronymic ? (` ${fullName.patronymic}`) : ''}`;
    },
    getStatus(attorney) {
      if (attorney.status === 'APPROVED_BY_INITIATOR') {
        return {
          title: 'Сформирована',
          style: 'color: #DFE1E5',
        };
      }

      if (attorney.status === 'NULLIFIED') {
        return {
          title: 'Отменена',
          style: 'color: #EB5757',
        };
      }

      if (attorney.status === 'REJECTED') {
        return {
          title: 'Отклонена',
          style: 'color: #EB5757',
        };
      }

      if (attorney.status === 'DELETED') {
        return {
          title: 'Удалена',
          style: 'color: #EB5757',
        };
      }

      if (dayjs().startOf('day').diff(dayjs(attorney.expiresAt, 'YYYY-MM-DDTHH:mm:ss[Z]', true)) > 0) {
        return {
          title: 'Просрочена',
          style: 'color: #EB5757',
        };
      }

      return {
        title: 'Действует',
        style: 'color: #4CCB40',
      };
    },
    getKind(kind) {
      if (kind === 'SIMPLE') {
        return 'Действующая доверенность';
      }
      if (kind === 'EXTERNAL') {
        return 'Доверенность, сформированная во внешней системе';
      }
      return 'МЧД';
    },
    ...mapActions('dataLists', ['convertToLabelValueList']),
    ...mapActions('document', ['loadDocument']),
    ...mapGetters('employees', ['getEmployees']),
    ...mapGetters('user', ['getAccount']),
  },
};
</script>
