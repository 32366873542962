import actions from './actions';
import getters from './getters';
import mutations from './mutations';

function initUser() {
  const userData = localStorage.getItem('user');

  if (userData === null) {
    return {};
  }

  return JSON.parse(userData);
}

let accountId;

function initAccount() {
  const accountData = localStorage.getItem('account');

  if (accountData === null) {
    return null;
  }

  const account = JSON.parse(accountData);
  accountId = account?.id;

  return account;
}

function initAuthorization() {
  const authorizationData = localStorage.getItem('authorization');

  if (authorizationData === null) {
    return null;
  }

  return JSON.parse(authorizationData);
}

function initWasShowMicrogenerationHelp() {
  const accountData = localStorage.getItem('wsmHelp');

  return accountData !== null;
}

function initSettings() {
  const settings = localStorage.getItem(`settings${accountId}`);

  if (settings === null) {
    return {};
  }

  return JSON.parse(settings);
}

const state = {
  data: initUser(),
  account: initAccount(),
  authorization: initAuthorization(),
  error: {},
  notification: {
    notificationType: 'error',
    notificationText: null,
  },
  isShownNotification: false,
  token: localStorage.getItem('apollo-token'),
  tokenStatus: localStorage.getItem('token-status'),
  tokenUsername: localStorage.getItem('token-username'),
  wasShowMicrogenerationHelp: initWasShowMicrogenerationHelp(),
  settings: initSettings(),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
