export const UploadAdreskaPattern = {
  rowNumberStart: 2,
  contractor: {
    name: {
      colIndex: 1,
      col: 1,
      rules: 'required',
      title: 'Подрядчик',
    },
    inn: {
      colIndex: 2,
      col: 2,
      rules: 'required|inn',
      title: 'ИНН',
    },
  },
  odkName: {
    colIndex: 3,
    col: 3,
    rules: 'required',
    title: 'Наименование ОДК',
  },
  axpName: {
    colIndex: 4,
    col: 4,
    title: 'Наименование по договору',
  },
  administrativeDistrict: {
    colIndex: 5,
    col: 5,
    rules: 'required',
    title: 'Административный округ',
  },
  responsibleDistrictDepartment: {
    colIndex: 6,
    col: 6,
    rules: 'required',
    title: 'Ответственный РЭС',
  },
  name: {
    colIndex: 7,
    col: 7,
    rules: 'required',
    title: 'Программа оформления',
  },
  address: {
    colIndex: 8,
    col: 8,
    rules: 'required',
    title: 'Адрес',
  },
  backupAddress: {
    colIndex: 9,
    col: 9,
    title: 'Резервный адрес',
  },
  quantityPlan: {
    colIndex: 10,
    col: 10,
    rules: 'required|positiveInteger|greaterZero',
    title: 'Кол-во (шт.) План',
  },
  workModePlan: {
    colIndex: 11,
    col: 11,
    rules: 'required|allowedValues',
    title: 'СВЕТОВАЯ ДА/НЕТ',
    allowedValues: ['ДА', 'НЕТ'],
  },
  comment: {
    colIndex: 12,
    col: 12,
    rules: 'maxLength255',
    title: 'Примечание',
  },
};
