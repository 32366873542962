import { apolloClient } from '@/api/graphql';
import { COMPANY_WITH_EMPLOYEES_BY_INN, INVITATIONS, SUPPLIERS_WITH_EMPLOYEES_BY_INN } from '@/api/graphql/queries';

async function getSupplierEmployeesByInnAndPermissions({ rootState, commit }, payload) {
  try {
    const {
      data: { UTILITY, RETAILER },
    } = await apolloClient.query({
      query: SUPPLIERS_WITH_EMPLOYEES_BY_INN,
      variables: {
        filter: {
          inn: payload.inn,
          kind: 'UTILITY',
        },
        filter2: {
          inn: payload.inn,
          kind: 'RETAILER',
        },
        authorizationsFilter: {
          permissions: payload.permissions,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return { UTILITY: UTILITY, RETAILER: RETAILER };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loaded UTILITY, RETAILER by inn error', error);
    throw error;
  }
}

async function getCompanyEmployeesByInnAndPermissions({ rootState, commit }, payload) {
  try {
    const {
      data: { companyProfile },
    } = await apolloClient.query({
      query: COMPANY_WITH_EMPLOYEES_BY_INN,
      variables: {
        inn: payload.inn,
        authorizationsFilter: {
          permissions: payload.permissions,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return companyProfile;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loaded companyProfile by inn error', error);
    throw error;
  }
}


async function loadInvitationsFromServer({ rootState, commit }, payload) {
  try {
    const { data: { invitations } } = await apolloClient.query({
      query: INVITATIONS,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('loadInvitationsFromServer errors', error.graphQLErrors);
      },
    });

    commit('SET_INVITATIONS', invitations);
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadInvitationsFromServer::catch', error);
    throw error;
  }
}

export default {
  loadInvitationsFromServer,
  getSupplierEmployeesByInnAndPermissions,
  getCompanyEmployeesByInnAndPermissions,
};
