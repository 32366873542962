<template>
  <div>
    <view-eye/>
  </div>
</template>

<script>
import ViewEye from 'atoms/icons/action/ViewEye.vue';
export default {
  name: 'VTableCellViewEye',
  components: { ViewEye },
};
</script>
