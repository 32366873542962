<template>
  <div>
    <div class="cabinet-company-details">
      <div class="page-form">
        <div class="page-form__form">
          <div class="row">
            <h1 class="col col--1of1 mb-24 page-form__title">
              Карточка потребителя ЮЛ
            </h1>
          </div>
          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              Данные о компании
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Наименование организации (полное) *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Название филиала"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Наименование организации (краткое) *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Наименование организации на английском языке (полное) *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Наименование организации на английском языке (краткое) *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                :disabled="true"
                title="ИНН *"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                :disabled="true"
                title="КПП *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                :disabled="true"
                title="ОГРН *"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                :disabled="true"
                title="ОКПО *"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              Виды деятельности
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <v-input
                :disabled="true"
                title="Основной ОКВЭД *"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              Адрес компании
            </h2>
          </div>

          <div class="row mb-24 mt-24">
            <div class="col col--3of3">
              <v-address-search-select
                :disabled="true"
                label="Юридический адрес *"
              />
            </div>
          </div>

          <div class="row mb-24">
            <div class="col col--3of3">
              <v-address-search-select
                :disabled="true"
                label="Фактический адрес *"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              ФИО Генерального директора
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Фамилия *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Имя *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Отчество"
              />
            </div>
          </div>

          <v-divider class="mb-24 mt-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              ФИО Главного бухгалтера
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Фамилия *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Имя *"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                title="Отчество"
              />
            </div>
          </div>

          <v-divider class="mb-24 page-form__divider"/>

          <div class="row mb-24">
            <h2 class="col col--1of1  page-form__sub-title">
              Контактные данные
            </h2>
          </div>

          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                title="Телефон"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                title="Дополнительный телефон"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                :disabled="true"
                clearable
                title="E-mail"
              />
            </div>
          </div>

          <div v-if="documents.length" class="row mb-24">
            <div class="col col--1of1">
              <documents-list :documents="documents"/>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { DocumentsList, VAddressSearchSelect, VDivider, VInput } from 'components';

export default {
  name: 'CompanyDetailsForm',
  components: {
    VInput,
    VDivider,
    DocumentsList,
    VAddressSearchSelect,
  },
  data() {
    return {
      documents: [],
    };
  },
  computed: {
    isScreenWidthLess1000px() {
      return window.matchMedia('(max-width: 1000px)').matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";
@import "@/assets/scss/commonCardStyles";

.cabinet-company-details {
  &__bank-title {
    display: inline-block;
    font-size: 14px;
    color: #71757a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }

  &__title {
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #C1C4C7;
  }

  &__error-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    margin-top: 12px;
    color: red;
  }

  &__history-block {
    display: flex;
    gap: 48px;
    flex-direction: column;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 11px;
}
</style>
