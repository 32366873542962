<template>
  <div :class="$style.grid">
    <PaymentCard
      v-for="(itm, idx) in $store.state.info"
      :key="idx"
      :item="itm"
    />
  </div>
</template>

<script>
import PaymentCard from 'organisms/payment/PaymentCard';
import { mapActions } from 'vuex';

export default {
  name: 'CabinetPay',
  components: {
    PaymentCard,
  },
  created() {
    this.loadInfo();
  },
  methods: {
    async loadInfo() {
      try {
        // TODO заменить на локальную переменную при релизе
        this.$store.state.info = await this.paymentInfo();
      } catch (error) {
        console.log(error.message);
      }
    },
    ...mapActions('payment', ['paymentInfo']),
  },
};
</script>

<style lang="scss" module>
.grid {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
</style>
