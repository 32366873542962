<template>
  <div :class="$style.container">
    <navigation-bar :current-step="currentStep"
                    :home="task === undefined ? 'Показания' : 'Задачи'"
                    :steps="steps"
                    @open="openStep" />

    <div :class="$style.content" role="detailsContent">
      <component
        :is="steps[currentStep].component"
        :key="componentKey"
        ref="details"
        :attachment="attachment"
        :component-key="componentKey"
        :disable="disable"
        :step-number="steps[currentStep].stepNumber"
        :task="task"
        :utility-individual-tariff="utilityIndividualTariff"
        @clearAttachment="clearAttachment"
        @close="close"
        @deleteDraft="deleteDraft"
        @next="next"
        @parseAttachment="parseAttachment"
        @preValidations="preValidations"
        @updateOrderTariff="updateOrderTariff"
      />
    </div>

    <div :class="$style.contentFooter">
      <div v-show="displayBack" :class="$style.back" @click="back">
        <arrow-left />
        <span>НАЗАД</span>
      </div>
      <div :class="$style.delete" @click="displayDeleteDialog = true">
        <span>УДАЛИТЬ ЧЕРНОВИК</span>
      </div>
      <div :class="$style.center">&nbsp;</div>
      <div v-show="displayNext" :class="$style.next" @click="next">
        <span>ДАЛЕЕ</span>
        <arrow-right />
      </div>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить черновик?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft" />
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
      </div>
    </dialog-template>

  </div>
</template>

<script>
import ArrowLeft from 'atoms/icons/arrows/ArrowLeft.vue';
import ArrowRight from 'atoms/icons/arrows/ArrowRight.vue';

import UtilityIndividualTariff
  from 'views/private/UtilityIndividualTariff/UtilityIndividualTariffUpload/steps/UtilityIndividualTariff.vue';
import Validation from 'views/private/UtilityIndividualTariff/UtilityIndividualTariffUpload/steps/Validation.vue';
import Upload from 'views/private/UtilityIndividualTariff/UtilityIndividualTariffUpload/steps/Upload.vue';
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';

import DialogTemplate from 'templates/DialogTemplate.vue';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';

import localforage from 'localforage';
import throttle from 'lib/utils/throttle.js';
import { mapGetters, mapState } from 'vuex';
import { excelValidateWithWorker } from '@/utils/excel/index.js';
import { nanoid } from 'nanoid';
import { scrollIntoView } from 'lib/utils/DOM';
import validate from 'lib/utils/validationRules';
import dayjs from 'dayjs';
import { normalizeDiacritics } from '@/utils/string/normalize';

function isNotEmptyItem(item) {
  return validate.isNotEmptyItem(item);
}

export default {
  name: 'UtilityIndividualTariffUpload',
  components: {
    ArrowLeft,
    ArrowRight,
    UtilityIndividualTariff,
    Validation,
    Upload,
    NavigationBar,
    DialogTemplate,
    SubmitTaskDetails,
  },
  props: {
    task: Object,
    index: Number,
    isComments: {
      type: Boolean,
      default: () => false,
    },
  },
  async created() {
    try {
      const utilityIndividualTariff = await localforage.getItem(this.storeName);

      if (utilityIndividualTariff === null) {
        return;
      }

      const object = JSON.parse(utilityIndividualTariff);

      if (object.version === undefined || object.version < this.utilityIndividualTariff.version) {
        return;
      }

      this.utilityIndividualTariff = object;
      this.componentKey += 1;
    } catch (error) {
      console.log('error getData utilityIndividualTariff', error);
    }
  },
  data() {
    return {
      utilityIndividualTariff: {
        version: 1,
        orderTariff: {
          year: '',
          year_error: { error: false },
          orderRequisites: {
            date: '',
            date_error: { error: false },
            orderNumber: '',
            orderNumber_error: { error: false },
            orderName: '',
            orderName_error: { error: false },
            orderPublisher: '',
            orderPublisher_error: { error: false },
          },
          orderBody: '',
          orderBody_error: { error: false },
        },
        companyTariff: [],
      },
      steps: [
        {
          component: 'UtilityIndividualTariff',
          stepNumber: {
            value: 1,
            from: 3,
            title: () => 'Внесение данных приказа по тарифам',
          },
          isComplete: true,
          validations: this.validUtilityIndividualTariff,
        },
        {
          component: 'Validation',
          stepNumber: {
            value: 2,
            from: 3,
            title: () => 'Проверка',
          },
          isComplete: false,
          preValidations: this.validationsAttachment,
        },
        {
          component: 'Upload',
          stepNumber: {
            value: 3,
            from: 3,
            title: () => 'Загрузка приказа по тарифам',
          },
          isComplete: false,
        },
      ],
      currentStep: 0,
      storeName: 'UtilityIndividualTariff',
      componentKey: 1,
      disable: false,
      displayDeleteDialog: false,
      attachment: {
        name: 'Приказ по индивидуальным тарифам',
        file: undefined,
        error: { error: false },
        errors: [],
      },
      validateExcelConfig: {
        rowNumberStart: 6,
        columns: [
          {
            columnNumber: 3,
            rule: {
              type: 'Date?',
            },
          },
          {
            columnNumber: 4,
            rule: {
              type: 'Date?',
            },
          },
          {
            columnNumber: 5,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 6,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 7,
            rule: {
              type: 'Number',
            },
          },
        ],
      },
    };
  },
  computed: {
    displayBack() {
      return this.currentStep > 0;
    },
    displayNext() {
      return this.currentStep >= 0 && this.steps[this.currentStep].isComplete;
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'reliabilityClassList',
      'voltageClassList',
      'pointsKindList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'accountingMethodList',
    ]),
    ...mapState('user', ['account']),
  },
  watch: {
    utilityIndividualTariff: {
      deep: true,
      immediate: false,
      handler: 'saveDataIntermediate',
    },
  },
  methods: {
    saveDataIntermediate: throttle(function() {
      this.saveData();
    }, 500),
    async saveData() {
      try {
        await localforage.setItem(this.storeName, JSON.stringify(this.utilityIndividualTariff));
      } catch (error) {
        console.log('error UtilityIndividualTariff.save, data:', this.utilityIndividualTariff);
      }
    },
    async clearData() {
      try {
        await localforage.removeItem(this.storeName);
      } catch (error) {
        console.log('error UtilityIndividualTariff.clear');
      }
    },
    close() {
      this.$router.push('/cabinet/individual-tariffs');
    },
    back() {
      if (this.currentStep === 0) {
        return;
      }

      this.currentStep -= 1;
    },
    validUtilityIndividualTariff() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      isValid = this.attachment.file !== undefined;
      if (!isValid) {
        result = true;
        errorRole = 'CompanyTariff';
        this.attachment.error = { error: true };
      } else {
        this.attachment.error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.orderBody.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'orderBody';
        this.utilityIndividualTariff.orderTariff.orderBody_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.orderBody_error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.orderRequisites.orderPublisher.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'orderPublisher';
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderPublisher_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderPublisher_error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.orderRequisites.orderName.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'orderName';
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderName_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderName_error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.orderRequisites.orderNumber.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'orderNumber';
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderNumber_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.orderRequisites.orderNumber_error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.orderRequisites.date.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'date';
        this.utilityIndividualTariff.orderTariff.orderRequisites.date_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.orderRequisites.date_error = { error: false };
      }

      isValid = this.utilityIndividualTariff.orderTariff.year.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'year';
        this.utilityIndividualTariff.orderTariff.year_error = { error: true };
      } else {
        this.utilityIndividualTariff.orderTariff.year_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validationsAttachment() {
      this.attachment.errors = [];

      const errorRole = '';
      let result = false;

      const validateHandler = (rule, items, indexAttachment, indexItem, colNumber, colTitle) => {
        const errorTexts = {
          required: 'Поле обязательно для заполнения',
          requiredNumber: 'Обязательное поле. Корректный формат: число',
          formatNumber: 'Некорректный формат. Корректный формат: число',
          requiredKtNumber: 'Обязательное поле. Корректный формат: два числа через слэш. Например: 100/50',
          requiredInn: 'Обязательное поле. Корректный формат: ИНН',
          maxString: 'Максимальная длина строки 255 символов',
          maxText: 'Максимальная длина строки 1024 символов',
          greaterCurrent: (date) => `Дата (${date}) не может быть меньше, чем текущая дата`,
          lessCurrent: (date) => `Дата (${date}) не может быть больше, чем текущая дата`,
        };
        let validResult = true;
        let errorText = '';

        switch (rule) {
          case 'required': {
            validResult = isNotEmptyItem(items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && !isNaN(Number(items[indexItem]));
            errorText = errorTexts.requiredNumber;
            break;
          }
          case 'formatNumber': {
            validResult = !isNotEmptyItem(items[indexItem]) || !isNaN(Number(items[indexItem]));
            errorText = errorTexts.formatNumber;
            break;
          }
          case 'requiredKtNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && items[indexItem].match(/^\d+\/\d+$/g) !== null;
            errorText = errorTexts.requiredKtNumber;
            break;
          }
          case 'requiredInn': {
            const errorInn = { message: '' };
            validResult = isNotEmptyItem(items[indexItem]) && validate.validateInn(items[indexItem], errorInn);
            errorText = errorTexts.requiredInn;
            break;
          }
          case 'requiredString': {
            validResult = isNotEmptyItem(items[indexItem]);

            if (validResult && items[indexItem].length > 255) {
              validResult = false;
              errorText = errorTexts.maxString;
              break;
            }

            errorText = errorTexts.required;
            break;
          }
          case 'string': {
            validResult = !isNotEmptyItem(items[indexItem]) || items[indexItem].length <= 255;
            errorText = errorTexts.maxString;
            break;
          }
          case 'requiredText': {
            validResult = isNotEmptyItem(items[indexItem]);

            if (validResult && items[indexItem].length > 1024) {
              validResult = false;
              errorText = errorTexts.maxText;
              break;
            }

            errorText = errorTexts.required;
            break;
          }
          case 'text': {
            validResult = !isNotEmptyItem(items[indexItem]) || items[indexItem].length <= 1024;
            errorText = errorTexts.maxText;
            break;
          }
          case 'requiredGreaterCurrent': {
            validResult = isNotEmptyItem(items[indexItem]);

            const date = dayjs(items[indexItem], 'DD.MM.YYYY', true);

            validResult = date.isValid();

            if (validResult) {
              validResult = dayjs().startOf('day').diff(date, 'day') <= 0;
              errorText = errorTexts.greaterCurrent(items[indexItem]);
            } else {
              errorText = errorTexts.required;
            }

            break;
          }
        }

        if (!validResult) {
          result = true;

          this.attachment.errors.push({
            row: indexAttachment + 8,
            col: colNumber,
            colTitle: colTitle,
            text: errorText,
          });
        }
      };

      this.utilityIndividualTariff.companyTariff.forEach((attachment, index) => {

        validateHandler(
          'required',
          attachment,
          index,
          7,
          8,
          'Одноставочный тариф',
        );

        validateHandler(
          'required',
          attachment,
          index,
          6,
          7,
          'Двухставочный тариф. Ставка на оплату технологического расхода',
        );

        validateHandler(
          'required',
          attachment,
          index,
          5,
          6,
          'Двухставочный тариф. Ставка на содержание электрических сетей',
        );

        validateHandler(
          'required',
          attachment,
          index,
          4,
          5,
          'Дата конца действия тарифа',
        );

        validateHandler(
          'required',
          attachment,
          index,
          3,
          4,
          'Дата начала действия тарифа',
        );

        validateHandler(
          'requiredInn',
          attachment,
          index,
          2,
          3,
          'ИНН',
        );

        validateHandler(
          'text',
          attachment,
          index,
          1,
          2,
          'Наименование смежной сетевой организации',
        );
      });

      return { isError: result, firstError: errorRole };
    },
    preValidations() {
      if (!this.steps[this.currentStep].preValidations) {
        return;
      }

      const result = this.steps[this.currentStep].preValidations();
      this.steps[this.currentStep].isComplete = !result.isError;
    },
    async parseAttachment(file) {
      this.utilityIndividualTariff.companyTariff = [];

      const taskId = nanoid();

      const displayError = (incorrectFile) => {
        this.vueShowNotification(
          '',
          `<p><b>Имя файла:</b></p><p>${incorrectFile}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${this.attachment.name}</p>`,
        );
      };

      if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment.name)) === -1) {
        displayError(file.name);
        return;
      }

      this.attachment.file = file;
      this.attachment.error = { error: false };
      try {
        const res = await excelValidateWithWorker(
          file.code,
          this.validateExcelConfig,
          taskId,
        );

        if (res.taskId === taskId) {
          const tableArray = res.data.slice(5, res.data.length);

          tableArray.forEach((item, index) => {
            if (!isNotEmptyItem(item[1]) && !isNotEmptyItem(item[2]) &&
              /* isNotEmptyItem(item[3]) && isNotEmptyItem(item[4]) &&
              isNotEmptyItem(item[5]) && isNotEmptyItem(item[6]) && isNotEmptyItem(item[7]) &&*/
              tableArray[index - 1] && isNotEmptyItem(tableArray[index - 1][1]) &&
              tableArray[index - 1] && isNotEmptyItem(tableArray[index - 1][2])
            ) {
              item[1] = tableArray[index - 1][1];
              item[2] = tableArray[index - 1][2];
            }
          });

          this.utilityIndividualTariff.companyTariff = tableArray.length === 0 ? [[]] : tableArray;
          this.preValidations();

          await this.saveData();
        } else {
          displayError(file.name);
        }
      } catch (error) {
        console.log('error XLSX.read', error);
        displayError(file.name);
      }
    },
    updateOrderTariff(orderTariff) {
      this.utilityIndividualTariff.orderTariff = orderTariff;
    },
    next() {
      if (this.steps[this.currentStep].validations) {
        const result = this.steps[this.currentStep].validations();

        if (result.isError) {
          this.componentKey += 1;
          this.$nextTick(() => {
            console.log('result.firstError', result.firstError);
            scrollIntoView(document.querySelector(`[role=${result.firstError}]`));
          });

          return;
        }
      }

      this.currentStep += 1;

      if (this.steps[this.currentStep].preValidations) {
        const result = this.steps[this.currentStep].preValidations();

        this.steps[this.currentStep].isComplete = !result.isError;

        if (result.isError) {
          this.componentKey += 1;
        }
      }

      document.querySelector('[role=detailsContent]').scrollTop = 0;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
    },
    clearAttachment() {
      this.attachment.file = undefined;
      this.attachment.error = { error: false };
      this.attachment.errors = [];
      this.utilityIndividualTariff.companyTariff = [];
    },
    deleteDraft() {
      this.clearData();
      this.clearAttachment();
      this.close();
    },
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2
  overflow-y: auto
  overflow-x: hidden

.contentFooter
  height: 48px
  display: flex

.back, .next, .delete
  +button-text
  color: #FFFFFF
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  background-color: #2F82DF
  padding: 18px

  svg
    fill: #FFFFFF

.delete
  background-color: red
  margin-left: 16px

.deleteText
  +base-title
  margin-top: 64px
  margin-bottom: 16px
  width: 100%
  text-align: center

.submitBlock
  display: flex
  margin-top: 64px

  div
    width: 50%

.center
  flex-grow: 2

.back
  margin-left: 16px

  span
    margin-left: 13px

.next
  margin-right: 36px

  span
    margin-right: 13px

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 64px
  width: 200px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF
</style>
