<template>
  <div class="table-filter-wrapper">
    <div v-if="actionBlockItems.length > 0"
         style="display: flex; justify-content: space-between; flex-wrap: wrap; gap: 16px;">
      <select-button
        :items="actionBlockItems"
        class="select-button"
      />
      <v-button
        v-show="showFiltersBlock && isFiltered"
        :class="{'open': showFilters, 'closed': !showFilters}"
        class="filter-button"
        variant="outlined"
        @click="showFilters = !showFilters"
      >
        {{ showFilters ? 'Свернуть фильтр' : 'Показать фильтр' }}
        <v-table-tab-arrow/>
      </v-button>
    </div>
    <v-button
      v-else
      v-show="showFiltersBlock && isFiltered"
      :class="{'open': showFilters, 'closed': !showFilters}"
      class="filter-button"
      variant="outlined"
      @click="showFilters = !showFilters"
    >
      {{ showFilters ? 'Свернуть фильтр' : 'Показать фильтр' }}
      <v-table-tab-arrow/>
    </v-button>
    <div v-show="showFilters && isFiltered && showFiltersBlock" class="table-filter-container">
      <div class="table-filter-container__items">
        <div v-for="(field, index) in filterFields" :key="index + filterFieldsKey"
             :class="{['table-filter-container__items-field']: true, ['table-filter-container__items-field--period']: field.filter.type === 'period'}"
        >
          <v-select
            v-if="field.filter.type === 'select' || field.filter.type === 'selectNotStrict'"
            v-model="filterValues[field.key]"
            :options="field.filter.values"
            :placeholder="field.filter.label ?? field.label"
            clearable
            height="40"
            new-style
            @input="(val) => $emit('change', field, val)"
          />

          <edit-mode-button
            v-else-if="field.filter.type === 'checkbox'"
            :is-true="filterValues[field.key]"
            :title="field.filter.label ?? field.label"
            @click="(val) => {
              $set(filterValues, field.key, val)
              $emit('change', field, val)
            }"
          />

          <v-date-picker
            v-else-if="field.filter.type === 'date'"
            v-model="filterValues[field.key]"
            :format="field.filter.format ?? 'DD.MM.YYYY'"
            :placeholder="field.filter.label ?? field.label"
            :type="field.filter.selectType ?? 'date'"
            clearable
            @input="(val) => $emit('change', field, val)"
          />

          <v-date-picker
            v-else-if="field.filter.type === 'range'"
            v-model="filterValues[field.key]"
            :format="field.filter.format ?? 'DD.MM.YYYY'"
            :placeholder="field.filter.label ?? field.label"
            :type="field.filter.selectType ?? 'date'"
            clearable
            range
            @input="(val) => $emit('change', field, val)"
          />

          <template v-else-if="field.filter.type === 'period'">
            <v-date-picker
              v-model="filterValues[field.key]"
              :placeholder="(field.filter.label ?? field.label) + ' от'"
              class="table-filter-container__items-field-date-picker"
              clearable
              @input="(val) => $emit('change', field, val)"
            />
            <v-date-picker
              v-model="filterValuesEnd[field.key]"
              :placeholder="(field.filter.label ?? field.label) + ' до'"
              class="table-filter-container__items-field-date-picker"
              clearable
              @input="(val) => $emit('change', field, val)"
            />
          </template>

          <events-autocomplete
              v-else-if="field.filter.type === 'eventsOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <axp-autocomplete
              v-else-if="field.filter.type === 'axpOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <v-search-select
              v-else-if="field.filter.type === 'odkOdk'"
              :placeholder="field.label"
              url-search="/odk/find?name={0}"
              url-default-data="/odk"
              :search="initFilterValues[field.key]"
              not-reset-search
              @update:search="(value) => updateSearch(value, field)"
          />

          <contractor-autocomplete
              v-else-if="field.filter.type === 'contractorOdk'"
              :selected="initFilterValues[field.key]"
              :search="filterValues[field.key]"
              :placeholder="field.label"
              is-vsearch
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <administrative-districts-autocomplete
              v-else-if="field.filter.type === 'administrativeDistrictOdk'"
              :selected="initFilterValues[field.key]"
              :search="filterValues[field.key]"
              :placeholder="field.label"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <oek-district-departments-autocomplete
              v-else-if="field.filter.type === 'responsibleDistrictDepartmentOdk'"
              :selected="initFilterValues[field.key]"
              :search="filterValues[field.key]"
              :placeholder="field.label"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <v-search-select
              v-else-if="field.filter.type === 'vSearchSelect'"
              :placeholder="field.label"
              :options="field.filter.values"
              :search="initFilterValues[field.key]"
              not-reset-search
              @input="(value, label)=>updateSearch(label, field)"
              @update:search="(value)=>updateSearch(value, field)"
          />

          <v-search-select
              v-else-if="field.filter.type === 'vSearchSelectValue'"
              :value="initFilterValues[field.key]"
              :search="initFilterValuesEnd[field.key]"
              :placeholder="field.label"
              :options="field.filter.values"
              @input="(value, label)=> {updateSearch(value, field); filterValuesEnd[field.key] = label;}"
              @update:search="(value)=>updateSearch(value, field)"
          />

          <v-search-select
              v-else-if="field.filter.type === 'vSearchSelectValueUrl'"
              :placeholder="field.label"
              :url="field.filter.url"
              :url-default-data="field.filter.urlDefault"
              :search="initFilterValues[field.key]"
              not-reset-search
              @input="(value)=>updateSearch(value, field)"
              @update:search="(value)=>updateSearch(value, field)"
          />

          <tag-autocomplete
              v-else-if="field.filter.type === 'tagOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <warehouses-autocomplete
              v-else-if="field.filter.type === 'warehousesOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <decor-type-autocomplete
              v-else-if="field.filter.type === 'decorTypeOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <elements-axp-autocomplete
              v-else-if="field.filter.type === 'elementsAxpOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              url-search="/elements/find?name={0}"
              url-default-data="/elements"
              @update:search="(val) => updateSearch(val, field)"
          />

          <axp-element-types-autocomplete
              v-else-if="field.filter.type === 'axpElementTypesOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <holidays-autocomplete
              v-else-if="field.filter.type === 'holidaysOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <defect-autocomplete
              v-else-if="field.filter.type === 'defectOdk'"
              :selected="initFilterValues[field.key]"
              :placeholder="field.label"
              :search="filterValues[field.key]"
              :add-element-item="false"
              margin-bottom=""
              not-reset-search
              @update:search="(val) => updateSearch(val, field)"
          />

          <v-input
            v-else
            v-model="filterValues[field.key]"
            :placeholder="field.filter.label ?? field.label"
            clearable
            @input="(val) => $emit('change', field, val)"
          />
        </div>
      </div>

      <div class="table-filter-container__controls">
        <v-button variant="filled" class="table-filter-container__controls-item" @click="$emit('search')">Найти</v-button>
        <v-button variant="outlined" class="table-filter-container__controls-item" @click="clear">Очистить</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton, VDatePicker, VInput, VSelect } from 'components';
import SelectButton from 'components/SelectButton/SelectButton.vue';
import VTableTabArrow from 'components/VTableNew/common/VTableTabs/VTableTabArrow.vue';
import EditModeButton from 'components/EditModeButton/EditModeButton.vue';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import AdministrativeDistrictsAutocomplete from 'components/Processes/components/AdministrativeDistrictsAutocomplete.vue';
import OekDistrictDepartmentsAutocomplete from 'components/Processes/components/OekDistrictDepartmentsAutocomplete.vue';
import TagAutocomplete from 'components/Processes/components/TagAutocomplete.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';
import DecorTypeAutocomplete from 'components/Processes/components/DecorTypeAutocomplete.vue';
import ElementsAxpAutocomplete from 'components/Processes/components/ElementsAxpAutocomplete.vue';
import AxpElementTypesAutocomplete from 'components/Processes/components/AxpElementTypesAutocomplete.vue';
import HolidaysAutocomplete from 'components/Processes/components/HolidaysAutocomplete.vue';
import DefectAutocomplete from 'components/Processes/components/DefectAutocomplete.vue';

export default {
  name: 'VTableFilter',
  components: {
    EditModeButton,
    SelectButton,
    VInput,
    VButton,
    VTableTabArrow,
    VDatePicker,
    VSelect,
    EventsAutocomplete,
    AxpAutocomplete,
    VSearchSelect,
    ContractorAutocomplete,
    AdministrativeDistrictsAutocomplete,
    OekDistrictDepartmentsAutocomplete,
    TagAutocomplete,
    WarehousesAutocomplete,
    DecorTypeAutocomplete,
    ElementsAxpAutocomplete,
    AxpElementTypesAutocomplete,
    HolidaysAutocomplete,
    DefectAutocomplete,
  },
  props: {
    showFiltersBlock: { type: Boolean, default: true },
    filterFields: { type: Array, default: () => [] },
    filterFieldsKey: { type: Number, default: 1 },
    isFiltered: { type: Boolean, default: false },
    filterValues: {
      type: Object, default: () => {
      },
    },
    filterValuesEnd: {
      type: Object, default: () => {
      },
    },
    actionBlockItems: { type: Array, default: () => [] },
  },
  data() {
    return {
      showFilters: true,
      initFilterValues: JSON.parse(JSON.stringify(this.filterValues)),
      initFilterValuesEnd: JSON.parse(JSON.stringify(this.filterValuesEnd)),
    };
  },
  methods: {
    updateSearch(val, field) {
      this.$set(this.filterValues, field.key, val);
      this.$emit('change', field, val);
    },
    clear() {
      this.$emit('clear');
      this.initFilterValues = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.table-filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .select-button {
    margin-bottom: 16px;
  }

  .filter-button {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #4C4E51;
    align-self: end;
    margin-bottom: 16px;

    svg {
      margin-left: 8px;
      fill: #71757a;
      transform: rotate(90deg);
    }
  }

  .filter-button.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    svg {
      transform: rotate(270deg);
    }
  }
}

.table-filter-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;

  @media (max-width: 860px){
    display: block;
    padding: 0 0 36px 0;
  }

  @media (max-width: 1427px) {
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__items {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: start;

    @media (max-width: 860px){
      width: 100%;
      gap: 2px;
    }

    @media (max-width: 1427px) {
      width: 100%;
    }

    &-field {
      width: 30%;

      @media (max-width: 860px){
        width: 100%;
      }

      &--period {
        width: 60%;
        display: flex;
        gap: 4px;

        @media (max-width: 860px){
          width: 100%;
        }
      }

      &-date-picker {
        width: 49%;

        @media (max-width: 860px){
          width: 100%;
        }
      }
    }
  }

  &__controls {
    height: 18px;
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: start;

    @media (max-width: 860px) {
      justify-content: space-between;
    }

    @media (max-width: 1427px) {
      margin-top: 10px;
      width: 100%;
    }

    &-item {
      @media (max-width: 860px) {
        width: 47%;
      }
    }
  }
}
</style>
