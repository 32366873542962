<template>
  <page title="Зоны присутствия">
    <select-button
      v-if="!isAvailableToSupport"
      :items="actionBlockItems"
    />
    <v-table-new
      v-model="computedPresenceZones"
      :loading="loading"
      :template="template"
      @delete="onDelete"
      @edit="onEdit"
    />

    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createZone"
      @delete="deleteZone"
      @update="updateZone"/>
  </page>
</template>

<script>
import { Page, SelectButton, VTableNew } from 'components';
import HTTPError from 'lib/utils/errors';
import { mapActions, mapGetters } from 'vuex';
import { addPresenceZones, deletePresenceZones, editPresenceZones } from 'views/private/PresenceZones/xml';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  components: {
    AddDirectoryFieldModal,
    VTableNew,
    Page,
    SelectButton,
  },
  name: 'PresenceZones',
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      presenceZones: [],
      selectFromAllSuppliers: false,
      allSuppliers: [],
      actionBlockItems: [
        {
          id: 1,
          title: 'Добавить зону присутствия',
          method: this.addRecord,
        },
      ],
      pagination: initialPagination,
      filter: {},
      zoneId: '',
      editRecordId: '',
      deleteRecordId: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount']),
    uniquePresenceZones: {
      get() {
        const uniqueSupplierNames = new Set(this.computedPresenceZones.map((it) => it.supplierName));
        return Array.from(uniqueSupplierNames).map((name) => ({
          value: name,
          label: name,
        }));
      },
    },
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'supplierName',
          name: 'Сетевая компания',
          options: this.suppliersList,
          required: true,
          disabled: this.isDisabled,
        },
        {
          type: AddDirectoryFieldModalFieldType.ADDRESS,
          id: 'presenceAddress',
          name: 'Адрес',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'equipmentDescription',
          name: 'Описание',
          required: true,
          customValidator: (value) => value.length < 1024,
        },
      ];

    },
    isDisabled() {
      return this.modalAction !== AddDirectoryFieldModalActions.CREATE;
    },
    computedPresenceZones() {
      return this.presenceZones.map((zone) => ({
        equipmentDescription: zone.equipmentDescription,
        presenceAddress: zone.presenceAddress,
        supplierName: zone.supplier.name,
        supplierInn: zone.supplier.inn,
        id: zone.id,
      }));
    },
    isAvailableToSupport() {
      return this.getAccount.kind !== 'PLATFORM';
    },
    presenceZonesListOptions() {
      return this.computedPresenceZones.map((zone) => (
        {
          label: zone.supplierName,
          value: zone.supplierName,
          id: zone.id,
        }
      ));
    },
    suppliersList() {
      return this.allSuppliers.map((it) => (
        {
          label: it.name,
          value: it.name,
        }
      ));
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '80px',
            },
          },
          {
            label: 'Сетевая компания',
            key: 'supplierName',
            sort: {},
            filter: {
              type: 'select',
              values: this.uniquePresenceZones,
            },
          },
          {
            label: 'Адрес',
            key: 'presenceAddress',
            sort: {},
            filter: {},
          },
          {
            label: 'Описание',
            key: 'equipmentDescription',
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
            customCheck: () => this.isAvailableToSupport,
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions('document', ['documentUpload']),
    ...mapActions('dataLists', ['getSuppliersForPresenceZones', 'supplierPresenceZonesByFilter']),
    ...mapActions('user', ['setNotification']),
    transformed(it) {
      return {
        ...it,
      };
    },
    async doAction(action) {
      this.loading = true;
      try {
        await action();
        this.closeModal();
        await this.loadData();
      } catch (error) {
        console.log('catch');
        this.setNotification({ message: HTTPError.network(error).message });
      } finally {
        this.loading = false;
      }
    },

    async loadData() {
      try {
        this.loading = true;
        const presenceZones = await this.supplierPresenceZonesByFilter({
          filter: this.filter,
        });
        this.presenceZones = presenceZones;
        this.allSuppliers = await this.getSuppliersForPresenceZones();
        return presenceZones;
      } catch (error) {
        console.log('supplierPresenceZonesByFilter:error', error);
      } finally {
        this.loading = false;
      }
    },
    async createZone() {
      this.selectFromAllSuppliers = true;
      const inn = this.allSuppliers.find((it) => it.name === this.modalValue.supplierName).inn;
      const xml = addPresenceZones(inn, this.modalValue.presenceAddress, this.modalValue.equipmentDescription);
      await this.doAction(async () => await this.documentUpload({ xml: xml }));
      this.selectFromAllSuppliers = false;
    },
    async updateZone() {
      const inn = this.allSuppliers.find((it) => it.name === this.modalValue.supplierName).inn;
      const xml = editPresenceZones(this.modalValue.id, this.modalValue.presenceAddress, this.modalValue.equipmentDescription, inn);
      await this.doAction(async () => await this.documentUpload({ xml: xml }));
    },
    async deleteZone() {
      const xml = deletePresenceZones(this.modalValue.id);
      await this.doAction(async () => await this.documentUpload({ xml: xml }));
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    async addRecord() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
      this.selectFromAllSuppliers = true;
    },
  },
};
</script>
