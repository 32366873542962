var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('div',{class:{
        [_vm.$style.progress]: true,
        [_vm.$style.progress92]: _vm.list.length < 7,
        [_vm.$style.progress96]: _vm.list.length >= 7,
      }}),_c('div',{class:_vm.$style.list},_vm._l((_vm.list),function(item,idx){return _c('p',{key:idx,class:{
        [_vm.$style.status]: true,
        [_vm.$style.current]: idx === _vm.currentIndex,
        [_vm.$style.disabled]: idx < _vm.currentIndex,
      }},[(idx === _vm.currentIndex)?_c('img',{class:{ [_vm.$style.point]: true, [_vm.$style.current]: true },attrs:{"src":require("@/assets/icons/basic/step_current_ellipse.svg"),"alt":""}}):_c('img',{class:_vm.$style.point,attrs:{"src":require("@/assets/icons/basic/step_ellipse.svg"),"alt":""}}),(_vm.title && idx === _vm.currentIndex)?_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.title.title)+" "+_vm._s(_vm.title.subTitle))]):_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(item.title)+" "+_vm._s(item.subTitle))]),(idx === _vm.currentIndex && _vm.subTitle)?_c('span',{class:_vm.$style.date},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }