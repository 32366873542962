export const workModeList = ['НО', 'АХП', '24 часа', 'не световая'];
export const consignmentTypesMap = {
  BUY_CONSTRUCTION: 'Приёмка по договору',
  ACCEPTANCE_TO_WAREHOUSE: 'Приёмка',
  EXTRADITE_FROM_WAREHOUSE: 'Выдача',
  RECALCULATION: 'Пересчёт',
  DEFECTOSCOPE: 'Дефектовка',
  TO_REPAIR: 'Выдача в ремонт',
  FROM_REPAIR: 'Возврат из ремонта',
  DECOMISSION: 'Списание',
  ACCOUNTING_BALANCES: 'Учёт складских остатков',
};

export const consignmentTypesReversedMap = {
  'Приёмка по договору': 'BUY_CONSTRUCTION',
  Приёмка: 'ACCEPTANCE_TO_WAREHOUSE',
  Выдача: 'EXTRADITE_FROM_WAREHOUSE',
  Пересчёт: 'RECALCULATION',
  Дефектовка: 'DEFECTOSCOPE',
  'Выдача в ремонт': 'TO_REPAIR',
  'Возврат из ремонта': 'FROM_REPAIR',
  Списание: 'DECOMISSION',
  'Учёт складских остатков': 'ACCOUNTING_BALANCES',
};

export const consignmentTypesConstant = {
  BUY_CONSTRUCTION: 'BUY_CONSTRUCTION',
  ACCEPTANCE_TO_WAREHOUSE: 'ACCEPTANCE_TO_WAREHOUSE',
  EXTRADITE_FROM_WAREHOUSE: 'EXTRADITE_FROM_WAREHOUSE',
  RECALCULATION: 'RECALCULATION',
  DEFECTOSCOPE: 'DEFECTOSCOPE',
  TO_REPAIR: 'TO_REPAIR',
  FROM_REPAIR: 'FROM_REPAIR',
  DECOMISSION: 'DECOMISSION',
  ACCOUNTING_BALANCES: 'ACCOUNTING_BALANCES',
};

export const consignmentTypesValueList = [
  { name: 'Приёмка по договору', id: 'BUY_CONSTRUCTION', label: 'Приёмка по договору', value: 'BUY_CONSTRUCTION' },
  { name: 'Приёмка', id: 'ACCEPTANCE_TO_WAREHOUSE', label: 'Приёмка', value: 'ACCEPTANCE_TO_WAREHOUSE' },
  { name: 'Выдача', id: 'EXTRADITE_FROM_WAREHOUSE', label: 'Выдача', value: 'EXTRADITE_FROM_WAREHOUSE' },
  { name: 'Пересчёт', id: 'RECALCULATION', label: 'Пересчёт', value: 'RECALCULATION' },
  { name: 'Дефектовка', id: 'DEFECTOSCOPE', label: 'Дефектовка', value: 'DEFECTOSCOPE' },
  { name: 'Выдача в ремонт', id: 'TO_REPAIR', label: 'Выдача в ремонт', value: 'TO_REPAIR' },
  { name: 'Возврат из ремонта', id: 'FROM_REPAIR', label: 'Возврат из ремонта', value: 'FROM_REPAIR' },
  { name: 'Списание', id: 'DECOMISSION', label: 'Списание', value: 'DECOMISSION' },
  {
    name: 'Учёт складских остатков',
    id: 'ACCOUNTING_BALANCES',
    label: 'Учёт складских остатков',
    value: 'ACCOUNTING_BALANCES',
  },
];

export const regularInspectionTaskType = {
  INSPECTION: 'INSPECTION',
  INSTALLATION_CONTROL: 'INSTALLATION_CONTROL',
  DISMANTLING_CONTROL: 'DISMANTLING_CONTROL',
  ADRESKA_CONNECTION: 'ADRESKA_CONNECTION',
};

export const adreskaStatusEnum = {
  MOUNTING_IN_PROCESS: 'MOUNTING_IN_PROCESS',
  MOUNTED: 'MOUNTED',
  IN_EXPLOITATION: 'IN_EXPLOITATION',
  DISMANTLING_IN_PROCESS: 'DISMANTLING_IN_PROCESS',
  DISMANTLED: 'DISMANTLED',
  NOT_STARTED: 'NOT_STARTED',
};


export const adreskaStatusMap = {
  MOUNTING_IN_PROCESS: 'В процессе монтажа',
  MOUNTED: 'Смонтировано',
  IN_EXPLOITATION: 'В эксплуатации',
  DISMANTLING_IN_PROCESS: 'В процессе демонтажа',
  DISMANTLED: 'Демонтировано',
  NOT_STARTED: 'Монтаж не начался',
};

export const adreskaStatusList = [
  { value: 'MOUNTING_IN_PROCESS', label: 'В процессе монтажа' },
  { value: 'MOUNTED', label: 'Смонтировано' },
  { value: 'IN_EXPLOITATION', label: 'В эксплуатации' },
  { value: 'DISMANTLING_IN_PROCESS', label: 'В процессе демонтажа' },
  { value: 'DISMANTLED', label: 'Демонтировано' },
  { value: 'NOT_STARTED', label: 'Монтаж не начался' },
];

export const accountingBalancesStatusEnum = {
  AT_WAREHOUSE: 'AT_WAREHOUSE',
  IN_REPAIR: 'IN_REPAIR',
  DEFECTIVE: 'DEFECTIVE',
  AT_CONTRACTOR: 'AT_CONTRACTOR',
  DECOMISSIONED: 'DECOMISSIONED',
};


export const accountingBalancesStatusMap = {
  AT_WAREHOUSE: 'На складе',
  IN_REPAIR: 'В ремонте',
  DEFECTIVE: 'Дефектные',
  AT_CONTRACTOR: 'У подрядчика',
  DECOMISSIONED: 'Списано',
};

export const accountingBalancesStatusMapReversed = {
  'на складе': 'AT_WAREHOUSE',
  'в ремонте': 'IN_REPAIR',
  дефектные: 'DEFECTIVE',
  'у подрядчика': 'AT_CONTRACTOR',
  списано: 'DECOMISSIONED',
};

export const consignmentUrlByType = {
  EXTRADITE_FROM_WAREHOUSE: 'extradite-construction',
  BUY_CONSTRUCTION: 'acceptance-to-warehouse',
  ACCEPTANCE_TO_WAREHOUSE: 'acceptance-to-warehouse',
  RECALCULATION: 'recalculation',
  DEFECTOSCOPE: 'defectoscope-construction',
  DECOMISSION: 'decomission',
  TO_REPAIR: 'repair-construction',
  FROM_REPAIR: 'from-repair-construction',
};

