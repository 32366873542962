<template>
  <div :class="$style.container">
    <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
        <span>Сроки проектирования и поэтапного введения в эксплуатацию объекта (в том числе по этапам и очередям), планируемое поэтапное распределение максимальной мощности:</span>
      </h2>
    </div>


    <template>
      <div v-for="(readyItem, index) in connectionCharacteristics.readyConnectionDate" :key="index">
        <hr v-if="index > 0">
        <div :class="$style.grid2Column">
          <div>
            <p :class="$style.textHelper">
              <span>Этап (очередь) строительства</span>
              <tooltip-helper offset="6px" placement="top">
                <div :class="$style.tooltip" style="width: 320px;">
                  При поэтапном технологическом присоединении максимальная мощность в заявлении должна совпадать с мощностью, введённой последним этапом
                </div>
                </tooltip-helper>
            </p>
            <c-input
              v-model="readyItem.stage"
            />
          </div>
          <div>
            <i v-if="connectionCharacteristics.readyConnectionDate?.length > 1" :class="$style.deleteBtn"
               @click="removeReadyDate(index)"><img alt="удалить" src="~@/assets/icons/basic/delete.svg"
                                                    style="width: 18px; height: 18px"></i>
          </div>
        </div>
        <div :class="$style.grid2Column">
          <div>
            <p :class="$style.textHelper" style="margin-bottom: 4px">
              <span><br/>Планируемый срок проектирования энергопринимающего устройства *</span>
            </p>
            <v-date-picker
              v-model="readyItem.designDate"
              format="YYYY-MM"
              :err="readyItem.designDateError"
              :role="'designDate_' + index"
              err-text="Поле обязательно для заполнения"
              type="month"
            />
          </div>
          <div>
            <p :class="$style.textHelper" style="margin-bottom: 4px">
              <span>Планируемый срок введения энергопринимающего устройства в эксплуатацию *</span>
            </p>
            <v-date-picker
              v-model="readyItem.readyDate"
              format="YYYY-MM"
              :err="readyItem.readyDateError"
              :role="'readyDate_' + index"
              :err-text="readyItem.readyDateErrorText"
              type="month"
            />
          </div>
        </div>
        <div :class="$style.grid2Column">
          <div>
            <p :class="$style.textHelper">
              <span>Максимальная мощность энергопринимающего устройства (кВт)</span>
            </p>
            <c-input
              v-model="readyItem.maxPower"
              type="number"
            />
          </div>
        </div>
      </div>
      <div v-if="errors.maxPowerStage" role="maxPowerStage">
        <p class="errorText">При поэтапном технологическом присоединении максимальная мощность в заявлении должна совпадать с мощностью, введённой последним этапом</p>
      </div>

      <div :class="$style.addButtons">
        <button @click="addReadyDate">+ ещё один этап (очередь) строительства</button>
      </div>

    </template>
  </div>
  </div>
</template>

<script>
import { EVENT_BUS } from 'root/eventBus';
import { mapGetters } from 'vuex';
import { VDatePicker } from 'components';
import StepCounter from '../components/StepCounter';

const INITIAL_DATA = {
  currentPreset: '',
  presets: [
    {
      type: 'home',
      title: 'Большой дом',
      description: [
        '1 точка присоединения',
        'Мощность до 15 кВт',
        '3-фазное подключение',
        'III категория надёжности',
      ],
      value: 'home',
      checked: false,
    },
    {
      type: 'village',
      title: 'Дача',
      description: [
        '1 точка присоединения',
        'Мощность до 9 кВт',
        'Однофазное подключение',
        'III категория надёжности',
      ],
      value: 'village',
      checked: false,
    },
    {
      type: 'custom',
      title: 'Свой вариант',
      description: [
        'Настройте свои параметры подключения',
      ],
      value: 'custom',
      checked: false,
    },
  ],
};

export default {
  name: 'ReadyConnectionDate',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    VDatePicker,
    StepCounter,
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
  },
  props: {
    connectionApplicationType: {
      type: String,
      required: true,
    },
    connectionCharacteristics: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    isLegal: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    EVENT_BUS.$on('CHECKED_PRESET', (checkedValue) => {
      this.connectionCharacteristics.presets.forEach((preset) => preset.checked = false);
      this.connectionCharacteristics.presets.find((preset) => preset.value === checkedValue).checked = true;
    });
    if (!this.connectionCharacteristics.tariffZoneType?.value) {
      this.connectionCharacteristics.tariffZoneType = this.tariffZoneTypeList[0];
    }
  },
  data() {
    return {
      tariffCategoryDesc: {
        1: 'Ценовая категория 1 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), учёт которых осуществляется в целом за расчётный период',
        2: 'Ценовая категория 2 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), учёт которых осуществляется по зонам суток расчётного периода',
        3: 'Ценовая категория 3 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), в отношении которых осуществляется почасовой учёт, но не осуществляется почасовое планирование, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в одноставочном выражении',
        4: 'Ценовая категория 4 - Тариф двухставочный - для объёмов покупки электрической энергии (мощности), в отношении которых осуществляется почасовой учёт, но не осуществляется почасовое планирование, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в двухставочном выражении',
        5: 'Ценовая категория 5 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), в отношении которых за расчётный период осуществляются почасовое планирование и учёт, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в одноставочном выражении',
        6: 'Ценовая категория 6 - Тариф двухставочный - для объёмов покупки электрической энергии (мощности), в отношении которых за расчётный период осуществляются почасовое планирование и учёт, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в двухставочном выражении.',
      },
    };
  },
  computed: {
    sortedSuppliersOptions() {
      const options = [...this.supplierOfLastResortList];

      options.sort((a, b) => {
        if (a.label === 'АО "Мосэнергосбыт"') {
          return -1;
        } else if (b.label === 'АО "Мосэнергосбыт"') {
          return 1;
        }
          return 0;
      });

      return options;
    },
    isInvalid() {
      return this.errors.currentPreset?.error && this.$validate.isEmptyString(this.connectionCharacteristics.currentPreset);
    },
    ...mapGetters('dataLists', [
      'energyConsumerCountList',
      'phaseVoltageList',
      'reliabilityClassShortList',
      'supplierOfLastResortList',
      'workloadList',
      'tariffCategoryTypeShortList',
      'tariffZoneTypeList',
      'typeAccountingLabelList',
    ]),
  },
  watch: {
    connectionCharacteristics: {
      deep: true,
      immediate: true,
      handler: 'updateItems',
    },
    'connectionCharacteristics.tariffCategoryType'() {
      console.log('this.connectionCharacteristics.tariffCategoryType', this.connectionCharacteristics.tariffCategoryType);
      switch (this.connectionCharacteristics.tariffCategoryType.value) {
        case 'TARIFF_CATEGORY_1': {
          this.connectionCharacteristics.typeAccounting = { label: 'Месячный', value: 'MONTHLY' };
          break;
        }
        case 'TARIFF_CATEGORY_2': {
          this.connectionCharacteristics.typeAccounting = { label: 'Зонный', value: 'ZONE' };
          break;
        }
        default: {
          this.connectionCharacteristics.typeAccounting = { label: 'Почасовой', value: 'HOURLY' };
        }
      }
    },
  },
  methods: {
    updateItems(val) {
      if (!val.presets) {
        this.connectionCharacteristics.presets = [...INITIAL_DATA.presets];
      }
      if (!val.currentPreset) {
        this.connectionCharacteristics.currentPreset = '';
      }
    },

    addReadyDate() {
      this.connectionCharacteristics.readyConnectionDate.push(
        {
          stage: '',
          designDate: '',
          readyDate: '',
          maxPower: '',
          reliabilityClass: { label: 'III категория', value: 'III' },
          designDateError: false,
          readyDateError: false,
        },
      );
    },
    removeReadyDate(index) {
      this.connectionCharacteristics.readyConnectionDate.splice(index, 1);
    },
  },
};
</script>

<style lang="sass" module>
.container
  max-width: 100%

  .stepContainer
    +questionnaries-container
    +step-spacing

    hr
      margin: 16px 0 16px 0

  .titleContainer
    +base-spacing

  .title
    display: flex

  .presetItem_1
    margin-left: 12px

  .presetItem_0:hover ~ .descriptions_home,
  .presetItem_1:hover ~ .descriptions_village,
  .presetItem_2:hover ~ .descriptions_custom
    @media all and (-ms-high-Contrast: none), (-ms-high-Contrast: active)
      margin-top: 16px
    display: grid
    display: -ms-grid

    &:nth-child(4)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(5)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(6)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5


  .descriptions_current
    @media all and (-ms-high-Contrast: none), (-ms-high-Contrast: active)
      margin-top: 16px
    display: grid !important
    display: -ms-grid !important

    &:nth-child(4)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(5)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(6)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

  .descriptions_home, .descriptions_village, .descriptions_custom, .descriptions_hide
    display: none
    grid-column-start: 1
    grid-column-end: 4

  .grid3Column
    +grid-3-column

  .grid2Column
    +grid-2-column

    &:nth-child(2)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 3

  .gridFluid
    +grid-fluid
    -ms-grid-column: 1
    -ms-grid-column-span: 3

  .blockContent
    +base-spacing

  .smallText
    +small-text

    p
      +small-text

  .textDescription
    h4
      +base-text
      margin-bottom: 8px
      color: #141412

    p
      +base-text
      font-size: 12px
      line-height: 18px
      color: #141412

    a
      +base-link

  .inputNote
    +input-note-text
    margin-top: 8px

  .textHelper
    +base-text
    color: #141412
    display: flex

    span
      margin-right: 10px

  .tooltip
    h3
      margin-bottom: 8px
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 18px
      line-height: 22px
      color: #000

    ul
      margin-left: 15px
      list-style: disc

    li,
    p
      font-family: Roboto
      font-weight: 400
      font-size: 12px
      line-height: 18px
      color: #000

    li
      margin-bottom: 8px

    a
      +base-link

  .invalid
    border: 1px solid red

  .noteContainer
    p
      +small-text

    a
      +base-link

  .deleteBtn
    width: 18px
    height: 18px
    margin-top: 43px
    max-width: 18px
    max-height: 18px
    cursor: pointer

  .addButtons
    display: flex
    justify-content: center
    margin-top: 16px

    button
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 21px
      line-height: 24px
      text-decoration: underline
      background: transparent
      color: #141412
      cursor: pointer

  .disclaimerGroup
    position: relative

    .disclaimer
      position: absolute
      top: 0
      width: 260px
      right: -304px

  @media (max-width: 1480px)
    .disclaimerGroup
      .disclaimer
        position: relative
        width: 100%
        right: 0
</style>
