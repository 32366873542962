var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal__wrp",class:{
    'modal__wrp_backdrop': _vm.isBackdrop,
  },style:(_vm.modalWrapperStyle)},[_c('div',{staticClass:"modal",class:{
      'modal_box-shadow': _vm.isModalBoxShadow,
    },style:(_vm.innerModalStyle)},[_c('header',{staticClass:"modal-header",style:(_vm.headerStyle)},[_vm._t("header"),(_vm.isBtnCloseVisible)?_c('button',{staticClass:"btn-close",attrs:{"aria-label":"Close modal","type":"button"},on:{"click":_vm.close}}):_vm._e()],2),_c('section',{class:{
        ['modal-body']: true,
        ['modal-body--scrollY']: _vm.isScrollY,
      },style:(_vm.bodyStyle)},[_vm._t("body")],2),_c('footer',{staticClass:"modal-footer",style:(_vm.footerStyle)},[_vm._t("footer")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }