<template>
  <page
    title="Ставка тарифа на услуги по передаче электрической энергии на содержание объектов электросетевого хозяйства">
    <v-table-new
      v-model="transformedTariffRate"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="tariffRatePage.pageOut"
      :template="template"
      :style-table="{minHeight: '450px'}"
      excel-report-file-name="Ставка тарифа на услуги по передаче электрической энергии на содержание объектов электросетевого хозяйства"
    />
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
  </page>
</template>

<script>
import { ModalProgressBar, Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import formatting from 'lib/utils/formatting';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'CostsStandardLosses',
  components: {
    VTableNew,
    Page,
    ModalProgressBar,
  },
  data() {
    return {
      tariffRatePage: {},
      loading: false,
      pagination: initialPagination,
      isModalVisible: false,
      isProcessComplete: false,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Дата начала действия тарифа',
            key: 'startDate',
            thStyle: {
              minWidth: '80px',
            },
            filter: { type: 'date' },
            sort: {},
          },
          {
            label: 'Дата конца действия тарифа',
            key: 'endDate',
            thStyle: {
              minWidth: '80px',
            },
            filter: { type: 'date' },
            sort: {},
          },
          {
            label: 'Ставка тарифа на услуги по передаче электрической энергии на содержание объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, для субъектов Российской Федерации, за исключением субъектов Российской Федерации, входящих в перечень субъектов Российской Федерации, на территории которых устанавливаются дифференцированные тарифы на услуги по передаче электрической энергии по единой (национальной) электрической сети, предусмотренный приложением №2 к Основам ценообразования в области регулируемых цен (тарифов) в электроэнергетике, утвержденным постановлением правительства Российской Федерации от 29 декабря 2011 года №1178 "О ценообразовании в области регулируемых цен (тарифов) в электроэнергетике", руб/(МВт*мес)',
            type: 'number',
            key: 'tariffRateForNonListedRegions',
            sort: {
              type: 'number',
            },
            tooltip: {
              maxLength: 100,
            },
          },
          {
            label: 'Ставка тарифа на услуги по передаче электрической энергии на содержание объектов электросетевого хозяйства, входящих в единую национальную (общероссийскую) электрическую сеть, для субъектов Российской Федерации, входящих в перечень субъектов Российской Федерации, на территории которых устанавливаются дифференцированные тарифы на услуги по передаче электрической энергии по единой (национальной) электрической сети, предусмотренный приложением №2 к Основам ценообразования в области регулируемых цен (тарифов) в электроэнергетике, утвержденным постановлением правительства Российской Федерации от 29 декабря 2011 года №1178 "О ценообразовании в области регулируемых цен (тарифов) в электроэнергетике", руб/(МВт*мес)',
            type: 'number',
            key: 'tariffRateForDifferentiatedRegions',
            sort: {
              type: 'number',
            },
            tooltip: {
              maxLength: 100,
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffRate() {
      return this.tariffRatePage.list ?? [];
    },
    transformedTariffRate() {
      return this.tariffRate.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffRatePage = await this.tariffRateForElectricityTransmissionMaintenanceByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffRateForElectricityTransmissionMaintenanceByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffRate = await this.tariffRateForElectricityTransmissionMaintenanceByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffRatePage.pageOut.totalElements,
          },
        });

        if (!tariffRate?.list) {
          return [];
        }

        return tariffRate.list.map(this.transformed);
      } catch (e) {
        console.log('tariffRateForElectricityTransmissionMaintenanceByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        id: it.id,
        startDate: it.startDate,
        endDate: it.endDate,
        tariffRateForNonListedRegions: formatting.numberFormatted(it.tariffRateForNonListedRegions),
        tariffRateForDifferentiatedRegions: formatting.numberFormatted(it.tariffRateForDifferentiatedRegions),
        tariffsOrderId: it.tariffsOrder?.documentId,
        document: { documentId: it.tariffsOrder?.documentId, name: 'TariffsOrderTariffRatesCostsStandardLosses' },
      };
    },
    ...mapActions('dataLists', ['tariffRateForElectricityTransmissionMaintenanceByPaged']),
    ...mapActions('document', ['loadDocument']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
