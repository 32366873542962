<template>
  <div class="table-cell-clip">
    <div class="table-cell-clip__clip">{{ value }}</div>
    <clip v-if="customCheck" class="table-cell-clip__clip__icon" />
  </div>
</template>

<script>
import Clip from 'atoms/icons/basic/Clip';

export default {
  name: 'VTableCellClip',
  components: { Clip },
  props: {
    value: { type: String, default: '' },
    customCheck: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.table-cell-clip {
  display: flex;

  &__clip {
    padding: 10px;

    &__icon {
      position: absolute;
      right: 0;
      bottom: -21px;
      z-index: 1;
    }
  }
}
</style>
