<template>
  <div class="added-members">
    <span class="added-members__title">Лист согласования:</span>

    <v-select
      :options="approvalSheets"
      :value="approvalSheets[0].value"
      disabled
      title="Выберите"
    />

    <v-table-new
      v-model="transformedMembers"
      :pagination-show="false"
      :template="membersTemplate"
      @delete="removeMember"
      @edit="openEditMemberModal"
      @on-th-click="onThClickHandler"
    />

    <added-members-modal
      v-if="isShowAddMemberModal"
      :members="members"
      mode="addMembers"
      @close="isShowAddMemberModal = false"
    />

    <added-members-modal
      v-if="isEditMemberModal"
      :current-member="currentMember"
      :members="members"
      mode="editMember"
      @close="isEditMemberModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddedMembersModal from './AddedMembersModal.vue';

import { getSurnameAndInitials } from 'utils/user';
import { checkField } from 'utils/common';
import VSelect from 'components/VSelect/VSelect.vue';
import VTableNew from 'components/VTableNew/VTable.vue';

export default {
  components: {
    VTableNew,
    VSelect,
    AddedMembersModal,
  },
  data() {
    return {
      approvalSheets: [
        {
          label: 'Лист согласования договоров',
          value: 'CONTRACT_APPROVAL_SHEET',
        },
      ],
      isShowAddMemberModal: false,
      isEditMemberModal: false,
      currentMember: null,
    };
  },
  computed: {
    membersTemplate() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'П/П',
            key: 'memberIndex',
            thStyle: {
              borderTop: borderStyle,
              borderLeft: borderStyle,
              padding: '16px',
            },
            tdStyle: {
              borderLeft: borderStyle,
              padding: '16px',
            },
          },
          {
            label: 'Подразделение',
            key: 'subDivision',
            thStyle: {
              borderTop: borderStyle,
              padding: '16px',
            },
            tdStyle: {
              borderTop: borderStyle,
              padding: '16px',
            },
          },
          {
            label: 'Должность',
            key: 'roleName',
            type: 'spoiler',
            thStyle: {
              borderTop: borderStyle,
              padding: '16px',
            },
            tdStyle: {
              maxWidth: '280px',
              borderTop: borderStyle,
              padding: '16px',
            },
          },
          {
            label: 'ФИО',
            key: 'fio',
            thStyle: {
              borderTop: borderStyle,
              padding: '16px',
            },
            tdStyle: {
              borderTop: borderStyle,
              padding: '16px',
            },
          },
          {
            key: 'actionButton',
            type: 'actionButton',
            colspan: '2',
            customCheck: (data) => data.isInitiator,
            thStyle: {
              width: '120px',
              borderTop: borderStyle,
              borderRight: borderStyle,
              padding: '16px',
            },
            tdStyle: {
              borderTop: borderStyle,
              borderRight: borderStyle,
              padding: '16px',
            },
          },
        ],
      };
    },
    transformedMembers() {
      if (this.members.length === 0) return [];

      return this.members.map((it, index) => ({
        ...it,
        memberIndex: this.checkIndexField(it, index),
        roleName: it.label,
        fio: it.value,
      }));
    },
    members() {
      const members = [];
      if (this.getContract?.userEmployeesContractMembers) {
        this.getContract.userEmployeesContractMembers.forEach((employee) => {
          members.push({
            id: employee.id,
            label: checkField(employee?.role?.name),
            value: getSurnameAndInitials(employee.user.fullName),
            isInitiator: employee.isInitiator,
            user: employee.user,
            role: employee.role,
            powerOfAttorney: employee.powerOfAttorney,
          });
        });
      }
      return members;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
  methods: {
    onThClickHandler(th) {
      switch (th.key) {
        case 'actionButton': {
          this.isShowAddMemberModal = true;
          break;
        }
      }
    },
    checkIndexField(it, index) {
      if (it.isInitiator) return 'Инициатор';
      return index;
    },
    removeMember(cell) {
      this.addUserEmployees({ userEmployees: cell.row });
      this.removeEmployeesContractMembers({ userEmployeesContractMember: cell.row });
    },
    openEditMemberModal(cell) {
      this.currentMember = { ...cell.row };
      this.isEditMemberModal = true;
    },
    ...mapActions('electricityTransmissionContract', ['removeEmployeesContractMembers', 'addUserEmployees']),
  },
};
</script>

<style lang="scss" scoped>
.added-members {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;
  }
}
</style>
