<template>
  <div class="form-wrp">
    <div>
      <div class="form">
        <form-header
          :column1="headerData.column1"
          :column2="headerData.column2"
          :title="headerData.title"
          :type="headerData.type"
        />
        <v-divider style="margin: 24px 0;" />
        <added-members />
        <div v-if="getContract.action === 'upload'">
          <v-divider style="margin: 24px 0;" />
          <span class="form__upload-title" :style="{'color': getErrors.scanContract.isError ? 'red': ''}">
            Загрузка документа
          </span>
          <c-uploading
            v-model="scanContract"
            :error="{error: getErrors.scanContract.isError}"
          />
          <div v-if="scanContract.length !== 0">
            <file-list-preview
              :list="scanContract"
              action
              @delete="removeScanContractHandler"
            />
          </div>
        </div>
        <v-divider style="margin: 24px 0;" />
        <v-select
          v-if="getContract.type === 'contract'"
          v-model="selectedTemplateValue"
          :err="getErrors.selectedTemplate.isError"
          :options="getContractTemplates"
          label="Выберите шаблон договора"
        />
        <div v-if="getContract.type === 'additionalAgreement'" class="select-wrp" style="margin-bottom: 8px">
          <select v-model="sourceContractId"
                  :class="{'select': true, 'select-error': getErrors.sourceContractId.isError}"
                  :disabled="isLoading"
                  style="margin-bottom: 0"
                  @change="onChangeContract($event.target.value)">
            <option disabled selected value="">Выберите сущеcтвующий исходный договор</option>
            <option v-for="(contractElement, index) in existingContractsForSelect" :key="index"
                    :value="contractElement.id">
              {{
                '№' + contractElement.contractInfo.number + ' от ' + convertDbDateFormat(contractElement.contractInfo.date)
              }}
            </option>
          </select>
          <horizontal-progress-bar v-if="isLoading" />
          <p v-if="getErrors.sourceContractId.isError" class="errorText">Поле обязательно к заполнению</p>
        </div>
        <div v-if="getContract.type === 'additionalAgreement'" class="select-wrp">
          <select v-model="additionalAgreementType"
                  :class="{'select': true, 'select-error': getErrors.additionalAgreementType.isError}"
                  :disabled="isLoading"
                  @change="onChangeAdditionalAgreementType()">
            <option disabled selected value="">Выберите тип дополнительного соглашения</option>
            <option v-for="(type, index) in additionalAgreementTypeList" :key="index"
                    :value="type.value">
              {{ type.label }}
            </option>
          </select>
          <horizontal-progress-bar v-if="isLoading" />
          <p v-if="getErrors.sourceContractId.isError" class="errorText">Поле обязательно к заполнению</p>
        </div>
        <contract-sides-selector
          v-if="selectedTemplateValue"
        />
      </div>
    </div>

    <div class="form__tooltips">
      <input-tooltip
        :text="tooltipText1"
        style="width: 350px;"
      />
      <input-tooltip
        v-if="getContract.action === 'upload'"
        :text="tooltipText2"
        style="width: 350px; margin-top: 192px;"
      />
      <input-tooltip
        v-if="getContract.action === 'create'"
        :text="tooltipText3"
        style="width: 350px; margin-top: 166px;"
      />
      <input-tooltip
        v-if="getContract.action === 'create' && !isBankRequisitesExists"
        :text="tooltipText4"
        style="width: 350px; margin-top: 166px;"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { InputTooltip, VDivider, VSelect } from 'components';
import CUploading from 'atoms/common/CUploading.vue';
import FileListPreview from 'molecules/upload/form/FileListPreview.vue';
import FormHeader from './FormHeader.vue';
import AddedMembers from './AddedMembers.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { convertDbFormat } from 'lib/utils/date';
import ContractSidesSelector
  from 'views/private/UploadDocument/steps/ContractRequisites/components/ContractSidesSelector';
import HTTPError from 'lib/utils/errors';
import { removeDraft } from 'services/draft';

export default {
  components: {
    ContractSidesSelector,
    HorizontalProgressBar,
    FormHeader,
    AddedMembers,
    VDivider,
    VSelect,
    CUploading,
    FileListPreview,
    InputTooltip,
  },
  async created() {
    this.isLoading = true;

    if (!this.getContract.action) {
      await this.initFromDraft({ draft: undefined });
    } else {
      await this.getContractSides();
    }

    if (this.getContract.type === 'additionalAgreement') {
      await this.loadSourceInfoFromContracts();
    }

    this.isLoading = false;
  },
  data() {
    return {
      tooltipText1: 'Для произведения взаиморасчётов необходимо загрузить на Платформу договор об оказании услуг по передаче электрической энергии (мощности), регулирующий ваши взаимоотношения с контрагентами.',
      isLoading: false,
      existingContracts: [],
      isWaitingSubmit: false,
      submitError: '',
    };
  },
  computed: {
    sides() {
      return this.getContract.selectedTemplate.sides;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
    isBankRequisitesExists() {
      return this.getAuthorization.authorization.account.profile.bankRequisites.length > 0;
    },
    tooltipText2() {
      return `Будет сформирован Акт верификации загрузки на Платформу действующего ${this.contractType}, а сам ${this.contractType2} появится на Платформе в виде цифровой копии реального документа заверенной цифровой подписью.`;
    },
    contractType() {
      let contractType = '';

      if (this.getContract?.type === 'contract') {
        contractType = 'договора';
      } else if (this.getContract?.type === 'additionalAgreement') {
        contractType = 'доп.соглашения';
      }
      return contractType;
    },
    contractType2() {
      let contractType2 = '';

      if (this.getContract?.type === 'contract') {
        contractType2 = 'сам договор';
      } else if (this.getContract?.type === 'additionalAgreement') {
        contractType2 = 'само доп.соглашение';
      }
      return contractType2;
    },
    tooltipText3() {
      return `Создание электронного ${this.contractType} возможно только в случае присутствия всех сторон и наличии информации об их банковских реквизитах на Платформе.`;
    },
    tooltipText4() {
      return `Создание электронного ${this.contractType} возможно только в случае наличия информации о Ваших банковских реквизитах на платформе`;
    },
    scanContract: {
      get() {
        return this.getContract.scanContract;
      },
      set(updatedScanContract) {
        if (updatedScanContract.length === 1) {
          this.setScanContract({ scanContract: updatedScanContract });
        }
      },
    },
    selectedTemplateValue: {
      get() {
        if (this.getContract?.selectedTemplate?.mnemonicType) {
          return this.getContract.selectedTemplate.mnemonicType;
        }
        return '';
      },
      set(value) {
        this.setSelectedTemplateByValue({ value });
        this.setAttachmentsList({ attachments: [] });
      },
    },
    sourceContractId: {
      get() {
        if (this.getContract?.sourceContractId) {
          return this.getContract.sourceContractId;
        }
        return '';
      },
      set(sourceContractId) {
        this.setSourceContractId({ sourceContractId });
      },
    },
    additionalAgreementType: {
      get() {
        if (this.getContract?.additionalAgreementType) {
          return this.getContract.additionalAgreementType;
        }
        return '';
      },
      set(additionalAgreementType) {
        this.setAdditionalAgreementType({ additionalAgreementType });
      },
    },
    // TODO: унифицировать условия
    getTitleAndType() {
      const patternName = this.getPatternName;
      if (this.getContract.type === 'contract' && this.getContract.action === 'create') {
        return {
          title: patternName,
          type: 'Электронный',
        };
      }
      if (this.getContract.type === 'contract' && this.getContract.action === 'upload') {
        return {
          title: patternName,
          type: 'Действующий',
        };
      }
      if (this.getContract.type === 'additionalAgreement' && this.getContract.action === 'create') {
        return {
          title: patternName,
          type: 'Электронный',
        };
      }
      if (this.getContract.type === 'additionalAgreement' && this.getContract.action === 'upload') {
        return {
          title: patternName,
          type: 'Действующий',
        };
      }
      return {
        title: '',
        type: '',
      };
    },
    headerColumns() {
      if (this.getContract.action === 'create') {
        return {
          column1: [
            {
              label: 'Дата создания:',
              value: this.getContract.createdDate,
            },
          ],
          column2: [
            {
              label: 'Инициатор:',
              value: this.surnameAndInitials,
            },
          ],
        };
      }
      if (this.getContract.action === 'upload') {
        return {
          column1: [
            {
              label: 'Дата создания:',
              value: this.getContract.createdDate,
            },
          ],
          column2: [
            {
              label: 'Загружает:',
              value: this.surnameAndInitials,
            },
          ],
        };
      }
      return [];
    },
    headerData() {
      return {
        title: this.getTitleAndType.title,
        type: this.getTitleAndType.type,
        column1: this.headerColumns.column1,
        column2: this.headerColumns.column2,
      };
    },
    existingContractsForSelect() {
      return this.existingContracts.filter((contract) => !contract.contractInfo.sourceContractNumber);
    },
    additionalAgreementTypeList() {
      const list = [
        { label: 'Дополнительное соглашение по реквизитам', value: 'ADDITIONAL_REQUISITES' },
        { label: 'Дополнительное соглашение по приложениям', value: 'ADDITIONAL_ATTACHMENTS' },
        { label: 'Дополнительное соглашение по тексту', value: 'ADDITIONAL_TEXT' },
        { label: 'Дополнительное соглашение по новым потребителям и точкам учёта', value: 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS' },
      ];

      return this.getContract.action === 'upload' ? list.slice(0, 3) : list;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getContractTemplates', 'getErrors', 'getPatternName']),
    ...mapGetters('user', ['surnameAndInitials', 'getAuthorization']),
  },
  methods: {
    async loadSourceInfoFromContracts() {
      try {
        this.isLoading = true;
        this.existingContracts = await this.loadBasicTransmissionContracts({

          status: ['DRAFT', 'WAITING_UPDATE_INFO', 'APPROVED_BY_INITIATOR', 'SIGNED_BY_INITIATOR', 'APPROVED', 'SIGNED', 'WAITING_VERIFICATION_ACT'],
          onlyAllSidesOnPlatform: this.getContract.action === 'create' && !this.$route.params.additionalAgreementType,
          onlyWithNumberUpdated: true,
        });

        if (this.$route.params.contractId) {
          const selectedContract = this.existingContracts.filter((contract) => !contract.contractInfo.sourceContractNumber && contract.id === this.$route.params.contractId);

          this.sourceContractId = selectedContract[0]?.id ?? '';
        }

        if (this.$route.params.additionalAgreementType) {
          this.additionalAgreementType = this.$route.params.additionalAgreementType;
          this.setSelectedTemplate();
        }
      } catch (e) {
        console.error('An error occurred while loading transmission contracts', e);
      } finally {
        this.isLoading = false;
      }
    },
    onChangeContract(selectedValue) {
      const value = this.existingContracts.find((el) => el.id === selectedValue);
      if (!value) return;
      this.setSourceContractNumber({ sourceContractNumber: value.contractInfo.number });
      this.setSourceContractDate({ sourceContractDate: value.contractInfo.date });
      this.setSelectedTemplate(value);
    },
    onChangeAdditionalAgreementType() {
      this.setSelectedTemplate();
    },
    convertDbDateFormat(value) {
      return convertDbFormat(value);
    },
    removeScanContractHandler() {
      this.setScanContract({ scanContract: [] });
    },
    async setSelectedTemplate(selectedContractValue) {
      if (!this.sourceContractId || !this.additionalAgreementType) {
        return;
      }

      const selectedContract = selectedContractValue ?? this.existingContracts.find((el) => el.id === this.sourceContractId);

      // if (this.additionalAgreementType === 'ADDITIONAL_REQUISITES') {
      const additionalRequisitesSideIndex = this.getContract.contractSides
          .findIndex(
              (contractSide) => (
                  (contractSide.mnemonicType === this.additionalAgreementType) ||
                  (contractSide.mnemonicType === 'ADDITIONAL_ATTACHMENTS' && this.additionalAgreementType === 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS')
              ),
          );

      if (additionalRequisitesSideIndex === -1) throw new Error('Не удалось contractSides type === ADDITIONAL_REQUISITES');

      const additionalRequisitesContractSides = this.getContract.contractSides[additionalRequisitesSideIndex];

      if (additionalRequisitesContractSides.sides.length === 0) {

        if (this.$route.params.fromTask) {
          this.existingContracts = [selectedContract];
        }

        const additionalRequisitesSides = [];

        const allOnPlatform = true;

        for (const side of selectedContract.sides) {
          additionalRequisitesSides.push({
            contractSide: side.sideName,
            credentials: [side.companyInfo],
          });
        }

        if (this.$route.params.fromTask) {
          this.setContractMode({ action: allOnPlatform ? 'create' : 'upload', type: this.getContract.type, contractType: this.getContract.contractType });
        }

        additionalRequisitesContractSides.sides = additionalRequisitesSides;

        let contractType = '';
        if (selectedContract.kindBySides === 'THREE_SIDE') {
          contractType = 'MES_BOILER_TSO';
        } else if (selectedContract.kindBySides === 'THREE_SIDE_RMR') {
          contractType = 'OEK_MES_RMR';
        } else {
          contractType = 'BOILER_UTILITY';
        }
        additionalRequisitesContractSides.contractType = contractType;

        const updatedContractSides = [...this.getContract.contractSides];
        updatedContractSides[additionalRequisitesSideIndex] = additionalRequisitesContractSides;

        this.setContractSides({ sides: updatedContractSides });
      }

      this.setSelectedTemplateByValue({ value: this.additionalAgreementType });
      // return;
      // }

      /*      if (!selectedContract) return;

            if (selectedContract.kindBySides === 'THREE_SIDE') {
              this.setSelectedTemplateByValue({value: 'MES_BOILER_TSO'});
            } else if (selectedContract.kindBySides === 'THREE_SIDE_RMR') {
              this.setSelectedTemplateByValue({value: 'OEK_MES_RMR'});
            } else {
              this.setSelectedTemplateByValue({value: 'BOILER_UTILITY'});
            }*/
    },
    async generateAdditions() {
      this.isWaitingSubmit = true;

      try {
        await this.generateAdditionsForNewUsagePointsAndConsumers({ sourceContractId: this.sourceContractId });

        this.vueShowNotification(
            '',
            '<p>Дополнительное соглашение по новым потребителям и точкам учёта сформировано.</p>',
        );

        setTimeout(async () => {
          if (this.getContract.draftId) {
            this.deleteDraft({ id: this.getContract.draftId });
            await this.resetState();
          }

          this.setUpdated({ updated: false });
          removeDraft(`${this.getContract.accountId}contractNew`);

          this.$router.push({
            name: this.$route.name.replace('.upload', ''),
          });
        }, 1000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;

      } finally {
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('electricityTransmissionContract', [
      'getContractSides',
      'setSelectedTemplateByValue',
      'setSourceContractId',
      'setSourceContractNumber',
      'setSourceContractDate',
      'setScanContract',
      'setAttachmentsList',
      'setAdditionalAgreementType',
      'setContractSides',
      'setContractMode',
      'generateAdditionsForNewUsagePointsAndConsumers',
      'resetState',
      'setUpdated',
      'initFromDraft',
    ]),
    ...mapActions('document', ['loadBasicTransmissionContracts', 'deleteDraft']),
    ...mapActions('cabinet', ['getSuppliersByFilter']),
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 850px;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  &-wrp {
    display: flex;
    flex-direction: row;
  }

  &__upload-title {
    color: #71757A;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__tooltips {
    margin-left: 20px;
  }

  &__tooltip {
    // max-width: 100px;
  }

  .select-wrp {
    position: relative;
    display: inline-block;
    width: 100%;

    &:after {
      content: url('~icons/arrows/arrow-down.svg');
      position: absolute;
      right: 8px;
      top: 8px;
      pointer-events: none;
      box-sizing: border-box;
    }
  }

  .select {
    width: 100%;
    height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
    border-color: #C1C4C7;
    appearance: none;
    outline: none;
    padding-left: 12px;
  }

  .select-error {
    border-color: red;
  }

  &__submit-button {
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
