<template>
  <div :class="$style.content">
    <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

    <p :class="$style.stepSubTitle">Сведения по полезному отпуску электроэнергии
      {{ usefulVacationDocument.UtilityInfo.name }} </p>

    <div>
      <table :class="$style.details">
        <thead>
        <tr>
          <td rowspan="2">№ п/п</td>
          <td role="unitType" rowspan="2">
            Расход электроэнергии потребленной потребителями точки поставки, которых расположены в г. Москве<br>
            <span>({{ formatUnitType(usefulVacationDocument.unitType) }})</span>
          </td>
        </tr>
        </thead>

        <tbody>
        <tr :class="$style.header">
          <td colspan="2">Полезный отпуск {{ usefulVacationDocument.UtilityInfo.name }}</td>
        </tr>
        <tr :class="$style.header">
          <td colspan="2">По уровням напряжения</td>
        </tr>
        <tr>
          <td>ВН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_1_vn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_1.vn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_1.vn"
                :error="usefulVacation.value_1.vn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_1.vn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 1</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_1_sn1'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_1.sn1) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_1.sn1"
                :error="usefulVacation.value_1.sn1_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_1.sn1_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 2</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_1_sn2'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_1.sn2) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_1.sn2"
                :error="usefulVacation.value_1.sn2_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_1.sn2_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>НН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_1_nn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_1.nn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_1.nn"
                :error="usefulVacation.value_1.nn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_1.nn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_1_total'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_1.total) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_1.total"
                :error="usefulVacation.value_1.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_1.total_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr :class="$style.header">
          <td colspan="2">В том числе по 4-ой, 6-ой ценовым категориям</td>
        </tr>
        <tr>
          <td>ВН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_2_6_vn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_2_6.vn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_2_6.vn"
                :error="usefulVacation.value_2_6.vn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_2_6.vn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 1</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_2_6_sn1'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_2_6.sn1) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_2_6.sn1"
                :error="usefulVacation.value_2_6.sn1_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_2_6.sn1_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 2</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_2_6_sn2'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_2_6.sn2) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_2_6.sn2"
                :error="usefulVacation.value_2_6.sn2_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_2_6.sn2_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>НН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_2_6_nn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_2_6.nn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_2_6.nn"
                :error="usefulVacation.value_2_6.nn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_2_6.nn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'value_2_6_total'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.value_2_6.total) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.value_2_6.total"
                :error="usefulVacation.value_2_6.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.value_2_6.total_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-for="(item, index) in usefulVacation.departments" :key="'departments_' + index">
        <tr :class="$style.header">
          <td colspan="2">В том числе потребителям, обслуживаемым в {{ item.name }}</td>
        </tr>
        <tr>
          <td>ВН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'department_vn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.vn) }}</span>
            <c-input
                v-else
                v-model="item.vn"
                :error="item.vn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.vn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 1</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'departments_sn1_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.sn1) }}</span>
            <c-input
                v-else
                v-model="item.sn1"
                :error="item.sn1_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.sn1_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 2</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'departments_sn2_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.sn2) }}</span>
            <c-input
                v-else
                v-model="item.sn2"
                :error="item.sn2_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.sn2_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>НН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'departments_nn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.nn) }}</span>
            <c-input
                v-else
                v-model="item.nn"
                :error="item.nn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.nn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'departments_total_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.total) }}</span>
            <c-input
                v-else
                v-model="item.total"
                :error="item.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.total_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-for="(valuesItem, valuesIndex) in usefulVacation.voltageClassValues" :key="valuesIndex">
        <tr :class="$style.header">
          <td colspan="2" role="powerUnitType">
            <span v-if="valuesItem.title === 'Мощность'">{{ `${valuesItem.title}, ${formatPowerUnitType(usefulVacationDocument.powerUnitType)}` }}</span>
            <span v-else>{{ valuesItem.title }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">ВН</td>
        </tr>
        <tr v-for="(item, index) in valuesItem.values.vn" :key="'vn_' + index">
          <td>{{ item.name }}</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}"
              :role="'voltageClass_' + valuesIndex + '_vn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.value) }}</span>
            <c-input
                v-else
                v-model="item.value"
                :error="item.value_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.value_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>

        <tr>
          <td colspan="2">СН 1</td>
        </tr>
        <tr v-for="(item, index) in valuesItem.values.sn1" :key="'sn1_' + index">
          <td>{{ item.name }}</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}"
              :role="'voltageClass_' + valuesIndex + '_sn1_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.value) }}</span>
            <c-input
                v-else
                v-model="item.value"
                :error="item.value_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.value_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>

        <tr>
          <td colspan="2">СН 2</td>
        </tr>
        <tr v-for="(item, index) in valuesItem.values.sn2" :key="'sn2_' + index">
          <td>{{ item.name }}</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}"
              :role="'voltageClass_' + valuesIndex + '_sn2_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.value) }}</span>
            <c-input
                v-else
                v-model="item.value"
                :error="item.value_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.value_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>

        <tr>
          <td colspan="2">НН</td>
        </tr>
        <tr v-for="(item, index) in valuesItem.values.nn" :key="'nn_' + index">
          <td>{{ item.name }}</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}"
              :role="'voltageClass_' + valuesIndex + '_nn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.value) }}</span>
            <c-input
                v-else
                v-model="item.value"
                :error="item.value_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.value_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}"
              :role="'voltageClass_' + valuesIndex + '_total'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(valuesItem.values.total) }}</span>
            <c-input
                v-else
                v-model="valuesItem.values.total"
                :error="valuesItem.values.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && valuesItem.values.total_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>

        </tbody>
        <tbody>
        <tr :class="$style.header">
          <td colspan="2">в т.ч. собственное потребление</td>
        </tr>
        <tr>
          <td>ВН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'ownConsumption_vn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.ownConsumption.vn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.ownConsumption.vn"
                :error="usefulVacation.ownConsumption.vn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.ownConsumption.vn_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 1</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'ownConsumption_sn1'">
              <span v-if="disable" :class="$style.textInputTd">{{
                  formatting(usefulVacation.ownConsumption.sn1)
                }}</span>
            <c-input
                v-else
                v-model="usefulVacation.ownConsumption.sn1"
                :error="usefulVacation.ownConsumption.sn1_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.ownConsumption.sn1_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 2</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'ownConsumption_sn2'">
              <span v-if="disable" :class="$style.textInputTd">{{
                  formatting(usefulVacation.ownConsumption.sn2)
                }}</span>
            <c-input
                v-else
                v-model="usefulVacation.ownConsumption.sn2"
                :error="usefulVacation.ownConsumption.sn2_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.ownConsumption.sn2_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>НН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'ownConsumption_nn'">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(usefulVacation.ownConsumption.nn) }}</span>
            <c-input
                v-else
                v-model="usefulVacation.ownConsumption.nn"
                :error="usefulVacation.ownConsumption.nn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.ownConsumption.nn_error.error" :class="$style.errorText">{{
                errorText
              }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'ownConsumption_total'">
              <span v-if="disable" :class="$style.textInputTd">{{
                  formatting(usefulVacation.ownConsumption.total)
                }}</span>
            <c-input
                v-else
                v-model="usefulVacation.ownConsumption.total"
                :error="usefulVacation.ownConsumption.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && usefulVacation.ownConsumption.total_error.error" :class="$style.errorText">
              {{ errorText }}</p>
          </td>
        </tr>
        </tbody>
        <tbody v-for="(item, index) in usefulVacation.transit" :key="'transit_' + index">
        <tr :class="$style.header">
          <td colspan="2">Транзит в</td>
        </tr>
        <tr>
          <td>ВН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'transit_vn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.vn) }}</span>
            <c-input
                v-else
                v-model="item.vn"
                :error="item.vn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.vn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 1</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'transit_sn1_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.sn1) }}</span>
            <c-input
                v-else
                v-model="item.sn1"
                :error="item.sn1_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.sn1_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>СН 2</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'transit_sn2_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.sn2) }}</span>
            <c-input
                v-else
                v-model="item.sn2"
                :error="item.sn2_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.sn2_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>НН</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'transit_nn_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.nn) }}</span>
            <c-input
                v-else
                v-model="item.nn"
                :error="item.nn_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.nn_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        <tr>
          <td>ВСЕГО</td>
          <td :class="{[$style.endGroup]: true, [$style.textTableTd]: disable}" :role="'transit_total_' + index">
            <span v-if="disable" :class="$style.textInputTd">{{ formatting(item.total) }}</span>
            <c-input
                v-else
                v-model="item.total"
                :error="item.total_error"
                :native-disabled="disable"
            />
            <p v-if="disable && item.total_error.error" :class="$style.errorText">{{ errorText }}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isError" style="margin-bottom: 32px">
      <p :class="$style.stepTitle">[{{ isError }}] Загрузите исправленный документ XLSX на Платформу</p>

      <c-uploading
          v-model="filledDocuments"
          :class="$style.filledDocuments"
          :error="usefulVacationDocument.attachments_error"
          accept=".xlsx, .xls"
          accept-title="XLSX, XLS или перетащите его в эту область."
          role="filledAttachments"
      />


      <div v-if="usefulVacationDocument.attachment2File" :class="$style.attachment">
        <a :class="$style.fileAttachment"
           :download="usefulVacationDocument.attachment2File.name"
           :href="fileUrl(usefulVacationDocument.attachment2File)">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40" />
          <span>{{ usefulVacationDocument.attachment2File.name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc2">
          <icon-delete />
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files';
import { isErrorConvert } from 'lib/component/validators';

export default {
  name: 'UsefulVacation',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    IconDelete: () => import('atoms/icons/action/Delete'),
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
    disable: {
      type: Boolean,
      default: true,
    },
    isError: Boolean,
  },
  data() {
    return {
      errorText: 'Поле обязательно для заполнения',
      usefulVacation: this.usefulVacationDocument.attachment2,
      filledDocuments: [],
    };
  },
  computed: {
    isValid() {
      return true;
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'voltageClassList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
    ]),
  },
  watch: {
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit('complete', val);
      },
    },
    filledDocuments(val) {
      if (val.length === 0) {
        return;
      }

      this.$emit('reload', val);
      this.filledDocuments = [];
    },
    usefulVacationDocument(val) {
      console.log('usefulVacationDocument', val);
      this.usefulVacation = val.attachment2;
    },
  },
  methods: {
    deletePoint(index) {
      this.contractData.attachment1.splice(index, 1);
    },
    copyPoint(index) {
      this.contractData.attachment1.push(
        JSON.parse(JSON.stringify(this.contractData.attachment1[index])),
      );

      this.scrollToEnd();
    },
    scrollToEnd() {
      setTimeout(() => {
        document.querySelector('[role=detailsContent]').scrollTo(0, document.body.scrollHeight);
      }, 200);
    },
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    isErrorConvert(errorArray, indexPoint, indexItem) {
      return isErrorConvert(errorArray, indexPoint, indexItem);
    },
    formatUnitType(value) {
      switch (value) {
        case 'MWth': return 'тыс. кВт.ч';
        case 'kWth': return 'кВт.ч';
      }
    },
    formatPowerUnitType(value) {
      switch (value) {
        case 'MWt': return 'МВт';
        case 'kWt': return 'кВт';
      }
    },
    formatting(value) {
      if (!value || value.length === 0) {
        return '';
      }
      return Intl.NumberFormat('ru-RU', {
        style: 'decimal',
      }).format(value);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deleteDoc2() {
      this.usefulVacationDocument.attachment2 = [];
      this.usefulVacationDocument.attachment2File = null;
    },
  },
};
</script>

<style lang="sass" module>
table.details
  width: 100%

  thead
    text-align: center

    td
      +table-text-small
      color: #0F1010
      padding-left: 8px
      padding-right: 24px
      padding-top: 12px
      padding-bottom: 12px
      vertical-align: middle
      border: 1px solid #71757A

    td.firstTitle
      text-align: left
      vertical-align: bottom

    td.noborder
      border: none

  tbody
    td
      border: 1px solid #71757A
      vertical-align: middle
      padding-left: 8px
      padding-right: 24px
      padding-top: 12px
      padding-bottom: 12px
      width: 50%

      p
        margin: 4px

      label
        display: none

      input
        border: 0

        &:disabled
          color: #71757A

      //select
      //  width: 100%
      //  height: 46px
      //  font-size: 18px
      //  padding-left: 12px
      //  border: 0
      //  outline: none
      //
      //  &:disabled
      //    color: #71757A
      //    opacity: 1

      img
        width: 24px
        height: 24px
        margin-top: 12px
        cursor: pointer

      img.close
        width: 18px
        height: 18px
        margin-left: 8px
        margin-right: 24px

    td.dateField
      input
        width: 140px

      input:disabled
        background-color: #ffffff

    td.field100
      input
        width: 100px

      input:disabled
        background-color: #ffffff

    td.copy
      padding-left: 10px
      border-right: none

    td.delete, td.copy
      border: none

    .footer
      td
        border: none
        height: 22px

  thead, tbody
    tr.header
      td
        font-size: 16px
        background-color: #e5e5e5

  .endGroup
    text-align: center

.content
  width: 848px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 24px auto 0px

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51

  .stepSubTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 18px
    white-space: nowrap
    color: #4C4E51


.form
  position: absolute
  width: 100%
  margin-bottom: 64px
  padding: 0 32px 0 0

  div
    overflow-x: auto

    &::-webkit-scrollbar-button
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 0
      border-radius: 10px
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb:hover
      background-color: #a19f9f

    &::-webkit-resizer
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar
      width: 10px
      height: 10px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment, .fileArchive
  +base-subtitle

.attachment
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.downloadIcon
  display: flex
  align-items: center

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

  color: #4C4E51

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 48px
  margin-top: 32px
  margin-bottom: 48px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF

.space
  opacity: 0

.errorText
  +small-text
  color: red

.textInputTd
  +input-text
  font-family: 'Roboto Condensed'
  color: gray

.textTableTd
  padding: 4px 16px

.tableTd
  td
    min-width: 200px
</style>
