import { apolloClient } from '@/api/graphql';
import { VUE_APP_DADATA_PATH, VUE_APP_DADATA_TOKEN } from '@/constants/env';
import {
  ALL_SUPPLIERS,
  ALL_SUPPLIERS_FOR_PRESENCE_ZONES,
  DIRECTORY_RECORDS,
  ELECTRICITY_BOILER_TARIFFS, ELECTRICITY_BOILER_TARIFFS_CATEGORY,
  EXPENSES_PAYMENT_STANDARD_LOSSES,
  GET_ALL_ISU,
  GET_CURRENT_TRANSFORMER_MODELS,
  GET_DATA_ACQUISITION_EQUIPMENT_MODEL,
  GET_DATA_ACQUISITION_EQUIPMENT_SWITCH_MODEL,
  GET_GUIDE_LIST,
  GET_METER_MODELS,
  GET_SUPPLIER_BY_ID,
  GET_SUPPLIER_BY_INN_AND_OGRN,
  GET_SUPPLIERS,
  GET_VOLTAGE_TRANSFORMER_MODELS,
  MAIN_SUBSTATIONS,
  MAIN_SUBSTATIONS_FOR_SELECTOR,
  SUPPLIER_DEPARTMETS,
  SUPPLIER_PRESENCE_ZONES_BY_FILTER,
  SUPPLIERS_BY_FILTER,
  TARIFF_INFRASTRUCTURE_PAYMENTS,
  TARIFF_LOSS_NORMATIVES,
  TARIFF_PEAK_LOAD_HOURS_PAGED,
  TARIFF_RATE_FOR_ELECTRICITY_TRANSMISSION_MAINTENANCE,
  TARIFFS_ORDER_BY_FILTER,
  UNIFIED_ENERGY_TARIFFS,
  UTILITY_LOSS_TARIFFS,
  MAIN_SUBSTATIONS_FROM_NUMBERS,
} from '../../../api/graphql/queries.js';
import axios from 'axios';

async function setSuppliers({ commit, rootState }, payload) {
  try {
    console.log('setSuppliers payload', payload);
    const filter = {
      filter: {
        kind: 'UTILITY',
      },
    };
    const filter2 = {
      filter2: {
        kind: 'RETAILER',
      },
    };
    const filter3 = {
      filter3: {
        kind: 'ODK',
      },
    };
    if (payload && payload.latitude) {
      filter.filter.location = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
      filter2.filter2.location = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
      filter3.filter3.location = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
      filter3.filter3.location = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
    }
    const {
      data: { UTILITY, RETAILER, ODK },
    } = await apolloClient.query({
      query: GET_SUPPLIERS,
      variables: {
        ...filter,
        ...filter2,
        ...filter3,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('loaded UTILITY', UTILITY);
    console.log('loaded RETAILER', RETAILER);
    console.log('loaded ODK', ODK);

    commit('SET_UTILITY', UTILITY);
    commit('SET_RETAILER', RETAILER);

    return { UTILITY: UTILITY, RETAILER: RETAILER, ODK: ODK };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load suppliers::catch', error);
    throw error;
  }
}

async function getSupplierById({ rootState }, payload) {
  try {
    console.log('getSupplierById payload', payload);
    const {
      data: { supplier },
    } = await apolloClient.query({
      query: GET_SUPPLIER_BY_ID,
      variables: {
        id: payload.id,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return supplier;
  } catch (error) {
    console.log('getSupplierById::catch', error);
    throw error;
  }
}


async function setSupplierByInnAndOgrn({ commit, rootState }, payload) {
  try {
    console.log('setSupplierByInnAndOgrn payload', payload);
    const {
      data: { UTILITY, RETAILER, ODK },
    } = await apolloClient.query({
      query: GET_SUPPLIER_BY_INN_AND_OGRN,
      variables: {
        filter: {
          inn: payload.inn,
          ogrn: payload.ogrn,
          kind: 'UTILITY',
        },
        filter2: {
          inn: payload.inn,
          ogrn: payload.ogrn,
          kind: 'RETAILER',
        },
        filter3: {
          inn: payload.inn,
          ogrn: payload.ogrn,
          kind: 'ODK',
        },
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('loaded UTILITY, RETAILER, ODK', UTILITY, RETAILER, ODK);

    commit('SET_CURRENT_UTILITY', { UTILITY: UTILITY, RETAILER: RETAILER, ODK: ODK });

    return { UTILITY: UTILITY, RETAILER: RETAILER, ODK: ODK };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load supplier by inn::catch', error);
    throw error;
  }
}

async function clearSuppliers({ commit }) {
  commit('CLEAR_SUPPLIERS');
}

async function mesDepartments({ rootState, commit }) {
  try {
    const {
      data: { supplierDepartments },
    } = await apolloClient.query({
      query: SUPPLIER_DEPARTMETS,
      variables: { supplierInn: '7736520080' },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return supplierDepartments;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load mes departments by inn::catch', error);
    throw error;
  }
}

async function supplierDepartments({ rootState, commit }, payload) {
  try {
    const {
      data: { supplierDepartments },
    } = await apolloClient.query({
      query: SUPPLIER_DEPARTMETS,
      variables: { supplierInn: payload.inn },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return supplierDepartments;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load mes departments by inn::catch', error);
    throw error;
  }
}
async function supplierPresenceZonesByFilter({ rootState }, payload) {
  try {
    const {
      data: { supplierPresenceZonesByFilter },
    } = await apolloClient.query({
      query: SUPPLIER_PRESENCE_ZONES_BY_FILTER,
      variables: { ...payload },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return supplierPresenceZonesByFilter;
  } catch (error) {
    console.log('load supplierPresenceZones::catch', error);
    throw error;
  }
}



async function getSuppliers({ rootState, commit }) {
  try {
    const {
      data: { suppliers },
    } = await apolloClient.query({
      query: ALL_SUPPLIERS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return suppliers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load mes suppliers by inn::catch', error);
    throw error;
  }
}

async function getSuppliersForPresenceZones({ rootState }) {
  try {
    const {
      data: { suppliers },
    } = await apolloClient.query({
      query: ALL_SUPPLIERS_FOR_PRESENCE_ZONES,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return suppliers;
  } catch (error) {
    console.log('load mes suppliers by inn::catch', error);
    throw error;
  }
}

async function getMeterModels({ rootState, commit }) {
  try {
    const {
      data: { meterModels },
    } = await apolloClient.query({
      query: GET_METER_MODELS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer${rootState.user.token}`,
        },
      },
    });
    return meterModels;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load mes measuring devices by inn::catch', error);
  }
}

async function getCurrentTransformerModels({ rootState, commit }) {
  try {
    const {
      data: { currentTransformerModel },
    } = await apolloClient.query({
      query: GET_CURRENT_TRANSFORMER_MODELS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer${rootState.user.token}`,
        },
      },
    });
    return currentTransformerModel;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getCurrentTransformerModels::catch', error);
  }
}

async function getVoltageTransformerModels({ rootState, commit }) {
  try {
    const {
      data: { voltageTransformerModel },
    } = await apolloClient.query({
      query: GET_VOLTAGE_TRANSFORMER_MODELS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer${rootState.user.token}`,
        },
      },
    });
    return voltageTransformerModel;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getVoltageTransformerModels::catch', error);
  }
}

async function getDataAcquisitionEquipmentModel({ rootState, commit }) {
  try {
    const {
      data: { dataAcquisitionEquipmentModel },
    } = await apolloClient.query({
      query: GET_DATA_ACQUISITION_EQUIPMENT_MODEL,
      context: {
        headers: {
          ['X-Authorization']: `Bearer${rootState.user.token}`,
        },
      },
    });
    return dataAcquisitionEquipmentModel;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getDataAcquisitionEquipmentModel::catch', error);
  }
}

async function getDataAcquisitionEquipmentSwitchModel({ rootState, commit }) {
  try {
    const {
      data: { dataAcquisitionEquipmentSwitchModel },
    } = await apolloClient.query({
      query: GET_DATA_ACQUISITION_EQUIPMENT_SWITCH_MODEL,
      context: {
        headers: {
          ['X-Authorization']: `Bearer${rootState.user.token}`,
        },
      },
    });
    return dataAcquisitionEquipmentSwitchModel;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getDataAcquisitionEquipmentSwitchModel::catch', error);
  }
}

async function loadLegalCompanyByInnFromFns(_, inn, type = 'LEGAL') {
  try {
    const { data } = await axios.post(
      `${VUE_APP_DADATA_PATH}/rs/findById/party`,
      {
        query: inn,
        type: type,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${VUE_APP_DADATA_TOKEN}`,
        },
      },
    );
    const internalOptions = data.suggestions.map((it) => ({
      name: it.value,
      kpp: it.data.kpp,
    }));
    console.log('loadLegalCompanyByInnFromFns', internalOptions);
    return internalOptions;
  } catch (error) {
    console.log('loadLegalCompanyByInnFromFns::catch', error);
    throw error;
  }
}

async function getGuideList({ rootState, commit }, payload) {
  try {
    const {
      data: { directories },
    } = await apolloClient.query({
      query: GET_GUIDE_LIST,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return directories;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load guide list::catch', error);
    throw error;
  }
}

async function getDirectoryRecords(store, payload) {
  try {
    const {
      data: { directory },
    } = await apolloClient.query({
      query: DIRECTORY_RECORDS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });
    return directory;
  } catch (error) {
    console.log('load directoryRecords::catch', error);
    throw error;
  }
}

async function getMainSubstationsList({ rootState, commit }) {
  try {
    const {
      data: { substations },
    } = await apolloClient.query({
      query: MAIN_SUBSTATIONS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return substations;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getMainSubstationsList::catch', error);
    throw error;
  }
}

async function getMainSubstationsListFromNumbers({ rootState, commit }, payload) {
  try {
    const {
      data: { existingSubstations },
    } = await apolloClient.query({
      query: MAIN_SUBSTATIONS_FROM_NUMBERS,
      variables: { ...payload },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return existingSubstations;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getMainSubstationsList::catch', error);
    throw error;
  }
}

async function getMainSubstationsListWithLines({ rootState, commit }) {
  try {
    const {
      data: { substations },
    } = await apolloClient.query({
      query: MAIN_SUBSTATIONS_FOR_SELECTOR,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return substations;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getMainSubstationsListWithLines::catch', error);
    throw error;
  }
}

async function getUtilityByFilter({ rootState, commit }, payload) {
  const filter = {
    filter: {
      kind: 'UTILITY',
      ...payload,
    },
  };

  try {
    const { data: { suppliers } } = await apolloClient.query({
      query: SUPPLIERS_BY_FILTER,
      fetchPolicy: 'no-cache',
      variables: { ...filter },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return suppliers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getUtilityByFilter::catch', error);
    throw error;
  }
}

async function getAllIsu({ rootState, commit }) {
  try {
    const {
      data: { allIsu },
    } = await apolloClient.query({
      query: GET_ALL_ISU,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return allIsu;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getAllIsu::catch', error);
    throw error;
  }
}

async function tariffRateForElectricityTransmissionMaintenanceByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: TARIFF_RATE_FOR_ELECTRICITY_TRANSMISSION_MAINTENANCE,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.tariffRateForElectricityTransmissionMaintenancePaged };
  } catch (error) {
    console.log('tariffRateForElectricityTransmissionMaintenanceByPaged::catch', error);
    throw error;
  }
}

async function tariffPeakLoadHoursPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: TARIFF_PEAK_LOAD_HOURS_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.tariffPeakLoadHoursPaged };
  } catch (error) {
    console.log('tariffPeakLoadHoursPaged::catch', error);
    throw error;
  }
}

async function tariffRateForElectricityTransmissionExpensesByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: EXPENSES_PAYMENT_STANDARD_LOSSES,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.tariffRateForElectricityTransmissionExpensesPaged };
  } catch (error) {
    console.log('tariffRateForElectricityTransmissionExpensesByPaged::catch', error);
    throw error;
  }
}

async function unifiedEnergyTariffsByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: UNIFIED_ENERGY_TARIFFS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.unifiedEnergyTariffsPaged };
  } catch (error) {
    console.log('unifiedEnergyTariffsByPaged::catch', error);
    throw error;
  }
}

async function tariffLossNormativesByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: TARIFF_LOSS_NORMATIVES,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.tariffLossNormativesPaged };
  } catch (error) {
    console.log('tariffLossNormativesByPaged::catch', error);
    throw error;
  }
}

async function tariffOrdersByFilterPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: TARIFFS_ORDER_BY_FILTER,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.allTariffsOrdersByFilterPaged };
  } catch (error) {
    console.log('allTariffsOrdersByFilterPaged::catch', error);
    throw error;
  }
}

async function tariffInfrastructurePaymentsByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: TARIFF_INFRASTRUCTURE_PAYMENTS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.tariffInfrastructurePaymentsPaged };
  } catch (error) {
    console.log('tariffInfrastructurePaymentsByPaged::catch', error);
    throw error;
  }
}

async function utilityLossTariffsByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: UTILITY_LOSS_TARIFFS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.utilityLossTariffsPaged };
  } catch (error) {
    console.log('utilityLossTariffsPaged::catch', error);
    throw error;
  }
}

async function electricityBoilerTariffsByPaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: ELECTRICITY_BOILER_TARIFFS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.allElectricityBoilerTariffPaged };
  } catch (error) {
    console.log('electricityBoilerTariffsByPaged::catch', error);
    throw error;
  }
}

async function boilerConsumerTypePaged(store, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: ELECTRICITY_BOILER_TARIFFS_CATEGORY,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return { ...data.boilerConsumerTypePaged };
  } catch (error) {
    console.log('boilerConsumerTypePaged::catch', error);
    throw error;
  }
}



export default {
  getSuppliers,
  setSuppliers,
  clearSuppliers,
  setSupplierByInnAndOgrn,
  mesDepartments,
  loadLegalCompanyByInnFromFns,
  getGuideList,
  getDirectoryRecords,
  getMeterModels,
  supplierDepartments,
  getCurrentTransformerModels,
  getMainSubstationsList,
  supplierPresenceZonesByFilter,
  getSuppliersForPresenceZones,
  getMainSubstationsListWithLines,
  getVoltageTransformerModels,
  getDataAcquisitionEquipmentSwitchModel,
  getDataAcquisitionEquipmentModel,
  getUtilityByFilter,
  getAllIsu,
  getSupplierById,
  tariffRateForElectricityTransmissionMaintenanceByPaged,
  tariffPeakLoadHoursPaged,
  tariffRateForElectricityTransmissionExpensesByPaged,
  unifiedEnergyTariffsByPaged,
  tariffLossNormativesByPaged,
  tariffInfrastructurePaymentsByPaged,
  utilityLossTariffsByPaged,
  electricityBoilerTariffsByPaged,
  tariffOrdersByFilterPaged,
  boilerConsumerTypePaged,
  getMainSubstationsListFromNumbers,
};
