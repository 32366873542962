<template>
  <div :class="$style.container">

    <div :class="$style.companyInfo">
      <img height="20" src="~@/assets/icons/basic/checked.svg" width="20" />
      <div>
        <p :class="$style.capTitle">Номер подтвержден</p>
        <p :class="$style.capInfo">Выберите роль для входа</p>
      </div>
    </div>

    <v-select v-model="selectedAccount" :options="accountsList" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VSelect from 'components/VSelect/VSelect.vue';

export default {
  name: 'AccountSelection',
  components: {
    VSelect,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isOpenUserMenu: false,
      selectedAccount: {},
    };
  },
  computed: {
    role() {
      return this.getAuthorization?.authorization?.role?.name;
    },
    userFio() {
      return this.getFio(this.getUser.fullName);
    },
    accountsList() {
      if (!this.getUser || !this.getUser.authorizations) {
        return [];
      }
      return this.getUser.authorizations
          .filter((item) => item.account)
          .map((item) => {
            const companyName = this.getCompanyName(item.account?.profile?.name);
            const userFio = this.getFio(this.getUser.fullName);
            const label = companyName.name ? `Представитель или работник компании (${companyName.name}, ${item?.role?.name}, ${userFio}` : `Физическое лицо (${userFio})`;

            return {
              value: item.account.id,
              label: label,
            };
          });
    },
    ...mapGetters('user', ['getUser', 'getUserType', 'getAuthorization']),
  },
  watch: {
    selectedAccount(accountId) {
      this.changeAccount(accountId);
    },
  },
  methods: {
    async changeAccount(accountId) {
      await this.confirmAccount({ accountId: accountId });

      this.keyIndex ++;

      this.$router.push('/cabinet/tasks');
    },
    openUserMenu() {
      this.isOpenUserMenu = !this.isOpenUserMenu;
    },
    hideUserMenu() {
      this.isOpenUserMenu = false;
    },
    getFio(fullName) {
      if (fullName && fullName.name) {
        let patronymic = '';
        if (fullName.patronymic) {
          patronymic = `${fullName.patronymic[0]}. `;
        }
        return `${fullName.name[0]}. ${patronymic}${fullName.surname}`;
      }
      return undefined;
    },
    getCompanyName(name) {
      if (!name) {
        return { name: undefined };
      }

      if (name.length >= 48) {
        return {
          name: `${name.substr(0, 48)}...`,
          fullName: name,
        };
      }

      return { name: name };
    },
    logout() {
      this.logOut();
      this.CLEAR_WORKFLOWS();
    },
    ...mapMutations('user', ['SAVE_ACCOUNT', 'SET_TOKEN', 'SAVE_USER']),
    ...mapActions('user', ['confirmAccount', 'setUser', 'logOut']),
    ...mapActions('cabinet', ['setWorkflows', 'clearTasks']),
    ...mapMutations('cabinet', ['CLEAR_WORKFLOWS']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

  .label
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 19px
    text-transform: uppercase
    color: #4C4E51
    margin-top: 33px
    margin-bottom: 38px

.companyInfo
  display: flex
  padding: 16px 0 8px
  border: 1px solid #54B687
  box-sizing: border-box
  margin-bottom: 38px
  margin-top: 82px

.capTitle
  +title-small-uppercase
  margin-bottom: 8px

.capInfo
  +small-text
  margin-bottom: 4px

.companyInfo
  img
    width: 20px
    margin-left: 36px
    margin-right: 18px

.content
  height: 155px
  width: 264px
  position: relative
  font-family: 'Roboto', sans-serif
  font-style: normal
  font-weight: 800
  font-size: 16px
  line-height: 19px
  display: flex
  align-items: center
  border-radius: 8px 8px 0 0
  border: 1px solid #C1C4C7
  padding: 6px
  margin: 0 auto

  .selector
    width: 48px
    height: 100%
    text-align: center
    background-color: #E9EBED
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px

  .exitIcon
    padding: 7px 6px 7px 0
    margin-left: 22px
    margin-right: 22px

  .iconChevron
    cursor: pointer

  .iconChevron:hover
    background-color: #DFE1E6
    border-radius: 55px

  .iconChevronUp
    transform: scale(1, -1)

  span
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 19px
    text-transform: uppercase
    color: #4C4E51
    white-space: nowrap

  .titleUser
    margin-left: 19px

  .companyName
    font-weight: 600

  .userFio
    font-weight: 600

.title
  +main-menu-text

.subtitle
  +main-menu-small-text

.tooltip
  width: 264px
  max-height: 485px
  overflow-y: auto
  overflow-x: hidden
  font-family: 'Roboto', sans-serif
  font-style: normal
  font-weight: 800
  font-size: 16px
  line-height: 19px
  border: 1px solid #C1C4C7
  border-top: 0
  border-radius: 0 0 8px 8px
  margin: 0 auto
  padding-bottom: 9px

  &::-webkit-scrollbar-button
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar-thumb
    -webkit-border-radius: 0
    border-radius: 10px
    background-color: #d1d1d1

  &::-webkit-scrollbar-thumb:hover
    background-color: #a19f9f

  &::-webkit-resizer
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar
    width: 7px

  .tooltipItem
    min-height: 155px
    width: 264px
    position: relative
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: 800
    font-size: 16px
    line-height: 19px
    display: flex
    align-items: center
    justify-content: center
    margin-top: 9px
    cursor: pointer

    .titleRole
      font-family: Roboto Condensed
      font-size: 16px
      font-weight: 600
      text-transform: uppercase


  .tooltipItem:hover
    background-color: #DFE1E6

.accountInfo
  width: 100%
  text-align: center

</style>
