
const TITLE_INDIVIDUAL_CUSTOMER = {
  Customer: {
    text: 'Подключение объекта',
    subTitle: 'к электросети',
  },
  Supplier: {
    text: 'Технологическое присоединение',
    subTitle: '',
  },
  Retailer: {
    text: 'Заключение договора энергоснабжения',
    subTitle: '',
  },
  RetailerDkp: {
    text: 'Заключение договора купли-продажи',
    subTitle: '',
  },
  Boiler: {
    text: 'Технологическое присоединение',
    subTitle: '',
  },
};

const TITLE_MICROGENERATION = {
  Customer: {
    text: 'Подключение микрогенератора',
    subTitle: '',
  },
  Supplier: {
    text: 'Технологическое присоединение',
    subTitle: 'объекта микрогенерации',
  },
  Retailer: {
    text: 'Заключение договора купли-продажи',
    subTitle: 'электроэнергии с объектом микрогенерации',
  },
};

const TITLE = {
  individualCustomerConnection: TITLE_INDIVIDUAL_CUSTOMER,
  individualCustomerConnectionRetail: TITLE_INDIVIDUAL_CUSTOMER,
  microgenerationConnectionUtility: TITLE_MICROGENERATION,
  microgenerationConnectionRetail: TITLE_MICROGENERATION,
};

const userType = {
  Customer: 'Customer',
  LEGAL_CONSUMER: 'Customer',
  Retailer: 'Retailer',
  SUPPLIER_OF_LAST_RESORT: 'Retailer',
  Supplier: 'Supplier',
  BOILER: 'Boiler',
  Boiler: 'Boiler',
  COUNTERPARTY: 'Supplier',
};

const STATUS_LIST_INDIVIDUAL_CUSTOMER = {
  Customer: [
    {
      // id: 0,
      title: 'Заявка подана',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'На рассмотрении',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Заявка одобрена,',
      subTitle: 'подготовка документов',
    },
    {
      // index: 3,
      title: 'Оплатите счёт',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Примите условия договора',
      subTitle: 'и выполните технические условия',
    },
    {
      // index: 5,
      title: 'Исполнение договора',
      subTitle: 'технологического присоединения',
    },
    {
      // index: 6,
      title: 'Формирование акта выполнения технических условий',
      subTitle: '',
    },
    {
      // index: 7,
      title: 'Подпишите акт выполнения технических условий',
      subTitle: '',
    },
    {
      // index: 8,
      title: 'Работы по подключению',
      subTitle: '',
    },
    {
      // index: 9,
      title: 'Подпишите акт допуска прибора учёта',
      subTitle: '',
    },
    {
      // index: 10,
      title: 'Работы по подключению',
      subTitle: '',
    },
    {
      // index: 11,
      title: 'Выполнена',
      subTitle: '',
    },
  ],
  Supplier: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: 'с техническими условиями',
    },
    {
      // index: 2,
      title: 'Ожидает оплаты и',
      subTitle: 'принятия условий',
    },
    {
      // index: 3,
      title: 'Исполнение договора',
      subTitle: 'технологического присоединения',
    },
    {
      // index: 4,
      title: 'Загрузка акта выполнения технических условий',
      subTitle: '',
    },
    {
      // index: 5,
      title: 'Акт выполнения технических условий ожидает принятия',
      subTitle: '',
    },
    {
      // index: 6,
      title: 'На рассмотрении',
      subTitle: 'у энергосбытовой компании',
    },
    {
      // index: 7,
      title: 'Формирование АДПУ',
      subTitle: '',
    },
    {
      // index: 8,
      title: 'Подписание АДПУ',
      subTitle: '',
    },
    {
      // index: 9,
      title: 'Формирование АТП',
      subTitle: '',
    },
    {
      // index: 10,
      title: 'Выполнена',
      subTitle: '',
    },
  ],
  Boiler: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: 'с техническими условиями',
    },
    {
      // index: 2,
      title: 'Ожидает оплаты и',
      subTitle: 'принятия условий',
    },
    {
      // index: 3,
      title: 'Исполнение договора',
      subTitle: 'технологического присоединения',
    },
    {
      // index: 4,
      title: 'Загрузка акта выполнения технических условий',
      subTitle: '',
    },
    {
      // index: 5,
      title: 'Акт выполнения технических условий ожидает принятия',
      subTitle: '',
    },
    {
      // index: 6,
      title: 'На рассмотрении',
      subTitle: 'у энергосбытовой компании',
    },
    {
      // index: 7,
      title: 'Формирование АДПУ',
      subTitle: '',
    },
    {
      // index: 8,
      title: 'Подписание АДПУ',
      subTitle: '',
    },
    {
      // index: 9,
      title: 'Формирование АТП',
      subTitle: '',
    },
    {
      // index: 10,
      title: 'Выполнена',
      subTitle: '',
    },
  ],
  Retailer: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Ожидает принятия',
      subTitle: 'условий договора',
    },
    {
      // index: 3,
      title: 'Закрытие заявки',
      subTitle: '',
    },
  ],
};
const STATUS_LIST_INDIVIDUAL_CUSTOMER_RETAIL = {
  Customer: [
    {
      // index: 0,
      title: 'На рассмотрении',
      subTitle: 'у энергосбытовой компании',
    },
    {
      // index: 1,
      title: 'Заявка одобрена,',
      subTitle: 'подготовка договора',
    },
    {
      // index: 2,
      title: 'Заключите',
      subTitle: 'договор',
    },
    {
      // index: 3,
      title: 'Выполнение заявки',
      subTitle: '',
    },
  ],
  Retailer: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Ожидает принятия',
      subTitle: 'условий договора',
    },
    {
      // index: 3,
      title: 'Закрытие заявки',
      subTitle: '',
    },
  ],
};

const STATUS_LIST_MICROGENERATION_UTILITY = {
  Customer: [
    {
      // id: 0,
      title: 'Заявка подана',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'На рассмотрении',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Заявка одобрена,',
      subTitle: 'подготовка документов',
    },
    {
      // index: 3,
      title: 'Оплатите счёт',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Примите условия договора',
      subTitle: 'и выполните технические условия',
    },
    {
      // index: 5,
      title: 'Работы по подключению',
      subTitle: '',
    },
    {
      // index: 6,
      title: 'Выполнение заявки',
      subTitle: '',
    },
  ],
  Supplier: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: 'с техническими условиями',
    },
    {
      // index: 2,
      title: 'Ожидает оплаты и',
      subTitle: 'принятия условий',
    },
    {
      // index: 3,
      title: 'Работы по присоединению',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Закрытие заявки',
      subTitle: '',
    },
  ],
  Retailer: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Ожидает принятия',
      subTitle: 'условий договора',
    },
    {
      // index: 3,
      title: 'Работы по присоединению',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Закрытие заявки',
      subTitle: '',
    },
  ],
};
const STATUS_LIST_MICROGENERATION_RETAIL = {
  Customer: [
    {
      // id: 0,
      title: 'Заявка передана',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'На рассмотрении',
      subTitle: 'энергосбытовой компанией',
    },
    {
      // index: 2,
      title: 'Заявка одобрена,',
      subTitle: 'подготовка документов',
    },
    {
      // index: 3,
      title: 'Примите условия договора',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Договор с энергосбытовой компанией подписан, ',
      subTitle: 'работы по присоединению',
    },
    {
      // index: 5,
      title: 'Выполнение заявки',
      subTitle: '',
    },
  ],
  Retailer: [
    {
      // index: 0,
      title: 'Рассмотрите заявку',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Подготовьте договор',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Ожидает принятия',
      subTitle: 'условий договора',
    },
    {
      // index: 3,
      title: 'Договор подписан, ',
      subTitle: 'работы по присоединению',
    },
    {
      // index: 4,
      title: 'Закрытие заявки',
      subTitle: '',
    },
  ],
};

const STATUS_LIST = {
  individualCustomerConnection: STATUS_LIST_INDIVIDUAL_CUSTOMER,
  individualCustomerConnectionRetail: STATUS_LIST_INDIVIDUAL_CUSTOMER_RETAIL,
  microgenerationConnectionUtility: STATUS_LIST_MICROGENERATION_UTILITY,
  microgenerationConnectionRetail: STATUS_LIST_MICROGENERATION_RETAIL,
};

const INDIVIDUAL_CUSTOMER_CONNECTION = {
  NOT_ON_PLATFORM: {
    Customer: {
      title: 'Заявка сформирована',
      statusIndex: 0,
      statusTitle: { title: 'Заявка сформирована' },
      statusSubTitle: 'Выбранная вами сетевая компания не является участником проекта «Мой свет». Необходимо лично предоставить заявку в компанию.',
      isActive: false,
      isDisplayDate: false,
      isDisplayApplicationDocument: true,
    },
    Supplier: {
      title: 'Заявка сформирована',
      statusIndex: 0,
      statusTitle: { title: 'Заявка сформирована' },
      statusSubTitle: 'Заявка сформирована и будет представлена лично.',
      isActive: false,
      isDisplayDate: false,
      isDisplayApplicationDocument: false,
    },
    Boiler: {
      title: 'Заявка сформирована',
      statusIndex: 0,
      statusTitle: { title: 'Заявка сформирована' },
      statusSubTitle: 'Заявка сформирована и будет представлена лично.',
      isActive: false,
      isDisplayDate: false,
      isDisplayApplicationDocument: false,
    },
  },
  SUBMITTED: {
    Customer: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
      actionProps: {
        title: 'Отправка заявки на корректировку',
        commentTitle: 'Укажите, что потребитель должен откорректировать в заявке',
        rejectButtonTitle: 'Требуется корректировка',
        approvedReviewType: 'connection-application-utility-company-review-approved',
        rejectedReviewType: 'connection-application-utility-company-review-rejected',
        approvedMessage: 'Подготовьте договор с техническими условиями, срок 20 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ОТКОРРЕКТИРОВАТЬ ЗАЯВКУ',
        submitTitle: 'ОТПРАВИТЬ НА КОРРЕКТИРОВКУ',
      },
    },
    Boiler: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
      actionProps: {
        title: 'Отправка заявки на корректировку',
        commentTitle: 'Укажите, что потребитель должен откорректировать в заявке',
        rejectButtonTitle: 'Требуется корректировка',
        approvedReviewType: 'connection-application-utility-company-review-approved',
        rejectedReviewType: 'connection-application-utility-company-review-rejected',
        approvedMessage: 'Подготовьте договор с техническими условиями, срок 20 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ОТКОРРЕКТИРОВАТЬ ЗАЯВКУ',
        submitTitle: 'ОТПРАВИТЬ НА КОРРЕКТИРОВКУ',
      },
    },
  },
  CORRECTION: {
    Customer: {
      statusIndex: 1,
      statusTitle: { title: 'Устраните замечания' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'CorrectionApplication',
      isDisplayAction: false,
      displayActionTitle: 'РЕДАКТИРОВАТЬ ДАННЫЕ',
      summaryProps: {
        expansion: true,
        showMap: false,
        initSpoiler: false,
      },
    },
    Supplier: {
      statusIndex: 0,
      statusTitle: { title: 'На корректировке' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 0,
      statusTitle: { title: 'На корректировке' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: true,
    },
  },
  APPROVED: {
    Customer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      actionProps: {
        reviewType: 'connection-application-utility-company-documents-submitted',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
    Boiler: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      actionProps: {
        reviewType: 'connection-application-utility-company-documents-submitted',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
  },
  CONNECTION_DOCUMENTS_SUBMITTED_WITH_INVOICE: {
    Customer: {
      statusIndex: 3,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: true,
        approvedReviewType: 'connection-application-utility-company-documents-approved',
        rejectedReviewType: 'connection-application-utility-company-documents-rejected',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
    Boiler: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_DOCUMENTS_SUBMITTED_AND_PAID: {
    Customer: {
      statusIndex: 4,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: false,
        approvedReviewType: 'connection-application-utility-company-documents-approved',
        rejectedReviewType: 'connection-application-utility-company-documents-rejected',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
    Boiler: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_DOCUMENTS_REJECTED: {
    Customer: {
      statusIndex: 3,
      statusTitle: { title: 'Предложения по изменениям отправлены,', subTitle: 'подготовка документов' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
    },
    Supplier: {
      statusIndex: 1,
      statusTitle: { title: 'Замечания по условиям договора' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      hideOfferInSummary: true,
      actionProps: {
        reviewType: 'connection-application-utility-company-documents-loaded-after-remarks',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
    Boiler: {
      statusIndex: 1,
      statusTitle: { title: 'Замечания по условиям договора' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      hideOfferInSummary: true,
      actionProps: {
        reviewType: 'connection-application-utility-company-documents-loaded-after-remarks',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
  },
  CONNECTION_INVOICE_CREATED: {
    Customer: {
      statusIndex: 4,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: true,
        approvedReviewType: 'connection-application-utility-company-documents-approved',
        rejectedReviewType: 'connection-application-utility-company-documents-rejected',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
    Boiler: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_INVOICE_PAID: {
    Customer: {
      statusIndex: 5,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 3,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendTechnicalConditionsAct',
    },
    Boiler: {
      statusIndex: 3,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendTechnicalConditionsAct',
    },
    Retailer: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: false,
      isDisplayDate: true,
    },
  },
  CONNECTION_DOCUMENTS_EXECUTION_COMPLETED: {
    Customer: {
      statusIndex: 6,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 4,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendTechnicalConditionsAct',
    },
    Boiler: {
      statusIndex: 4,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendTechnicalConditionsAct',
    },
  },
  WAITING_FOR_APPROVING: {
    Customer: {
      statusIndex: 0,
      isActive: false,
      isDisplayDate: false,
    },
    Retailer: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
      actionProps: {
        title: 'Запрос дополнительной информации',
        commentTitle: 'Укажите, какая дополнительная информация по заявке требуется',
        rejectButtonTitle: 'Требуется уточнение',
        approvedReviewType: 'connection-application-retail-company-additions-approved',
        rejectedReviewType: 'connection-application-retail-company-additions-remarks',
        approvedMessage: 'Подготовьте договор, срок 30 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ПРИСЛАТЬ УТОЧНЕНИЯ ПО ЗАЯВКЕ',
        submitTitle: 'ОТПРАВИТЬ ЗАПРОС',
      },
    },
  },
  TECHNICAL_CONDITIONS_ACT_SUBMITTED: {
    Customer: {
      statusIndex: 7,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'ApprovedTechnicalConditions',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 5,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
    Boiler: {
      statusIndex: 5,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },

  },
  TECHNICAL_CONDITIONS_ACT_APPROVED: {
    Customer: {
      statusIndex: 8,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 6,
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'SendConnectionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
    Boiler: {
      statusIndex: 6,
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'SendConnectionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
  },
  CONNECTION_CERTIFICATE_REQUIRED: {
    Customer: {
      statusIndex: 10,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 9,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendConnectionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
    Boiler: {
      statusIndex: 9,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendConnectionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
  },
  ADMISSION_CERTIFICATE_REQUIRED: {
    Customer: {
      statusIndex: 8,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 7,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendAdmissionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
    Boiler: {
      statusIndex: 7,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendAdmissionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
  },
  ADMISSION_CERTIFICATE_SUBMITTED: {
    Customer: {
      statusIndex: 9,
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'SignAdmissionCertificate',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
    Supplier: {
      statusIndex: 8,
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 8,
      isActive: false,
      isDisplayDate: true,
    },
  },
  USAGE_POINT_LOSSES_DOCUMENT_REQUIRED: {
    Customer: {
      statusIndex: 7,
      statusSubTitle: 'с энергосбытовой компанией',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 7,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendUsagePointLossesDocument',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
    Boiler: {
      statusIndex: 7,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendUsagePointLossesDocument',
      actionProps: {
        reviewType: 'connection-application-cancelled',
      },
    },
  },
  CANCELLED: {
    Customer: {
      statusIndex: 11,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
    Supplier: {
      statusIndex: 5,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
    Boiler: {
      statusIndex: 5,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
    Retailer: {
      statusIndex: 4,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
  },
  CONNECTED: {
    Customer: {
      statusIndex: 11,
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
    Supplier: {
      statusIndex: 10,
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 10,
      isActive: false,
      isDisplayDate: true,
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_REJECTED: {
    Customer: {
      statusIndex: 6,
      statusTitle: { title: 'Предоставьте дополнительную информацию' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'AdditionalInfo',
      actionProps: {
        reviewType: 'connection-application-retail-company-additions-submitted-after-remarks',
        agreementTitle: 'договора',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
    Supplier: {
      statusIndex: 9,
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 9,
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 0,
      statusTitle: { title: 'На уточнении' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: true,
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_SUBMITTED: {
    Customer: {
      statusIndex: 8,
      statusSubTitle: 'с энергосбытовой компанией',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 8,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 9,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        agreementTitle: 'договора',
      },
      actionProps: {
        title: 'Запрос дополнительной информации',
        commentTitle: 'Укажите, какая дополнительная информация по заявке требуется',
        rejectButtonTitle: 'Требуется уточнение',
        approvedReviewType: 'connection-application-retail-company-additions-approved',
        rejectedReviewType: 'connection-application-retail-company-additions-remarks',
        approvedMessage: 'Подготовьте договор, срок 30 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ПРИСЛАТЬ УТОЧНЕНИЯ ПО ЗАЯВКЕ',
        submitTitle: 'ОТПРАВИТЬ ЗАПРОС',
      },
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_APPROVED: {
    Customer: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 30 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Retailer: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 30 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendRetailerOffer',
      actionProps: {
        reviewType: 'connection-application-retail-company-offer-submitted',
        agreementTitle: 'Договор',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_SUBMITTED: {
    Customer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'ApprovedRetailerOffer',
      actionProps: {
        approvedReviewType: 'connection-application-retail-company-offer-approved',
        rejectedReviewType: 'connection-application-retail-company-offer-remarks',
        agreementTitle: 'ДОГОВОР',
      },
    },
    Retailer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_REJECTED: {
    Customer: {
      statusIndex: 6,
      statusTitle: { title: 'Предложения по изменениям отправлены,', subTitle: 'подготовка договора' },
      statusSubTitle: 'Срок: 30 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 9,
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 9,
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 1,
      statusTitle: { title: 'Замечания', subTitle: 'по условиям договора' },
      statusSubTitle: 'Срок: 30 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendRetailerOffer',
      actionProps: {
        reviewType: 'connection-application-retail-company-offer-submitted-after-remarks',
        agreementTitle: 'Договор',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_APPROVED: {
    Customer: {
      statusIndex: 11,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
    Supplier: {
      statusIndex: 9,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
    },
    Boiler: {
      statusIndex: 9,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 3,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор',
      },
    },
  },
  CONNECTION_DOCUMENTS_APPROVED: {
    Customer: {
      statusIndex: 5,
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 3,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ExecutionAgreement',
      actionProps: {
        isDisabled: true,
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Boiler: {
      statusIndex: 3,
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ExecutionAgreement',
      actionProps: {
        isDisabled: true,
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
  },
};

const MICROGENERATION_CONNECTION = {
  NOT_ON_PLATFORM: {
    Customer: {
      title: 'Заявка сформирована',
      statusIndex: 0,
      statusTitle: { title: 'Заявка сформирована' },
      statusSubTitle: 'Выбранная вами сетевая компания не является участником проекта «Мой свет». Необходимо лично предоставить заявку в компанию.',
      isActive: false,
      isDisplayDate: false,
      isDisplayApplicationDocument: true,
    },
    Supplier: {
      title: 'Заявка сформирована',
      statusIndex: 0,
      statusTitle: { title: 'Заявка сформирована' },
      statusSubTitle: 'Заявка сформирована и будет представлена лично.',
      isActive: false,
      isDisplayDate: false,
      isDisplayApplicationDocument: false,
    },
  },
  SUBMITTED: {
    Customer: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        agreementTitle: 'договора купли-продажи',
      },
      actionProps: {
        title: 'Отправка заявки на корректировку',
        commentTitle: 'Укажите, что потребитель должен откорректировать в заявке',
        rejectButtonTitle: 'Требуется корректировка',
        approvedReviewType: 'microgeneration-utility-company-review-approved',
        rejectedReviewType: 'microgeneration-utility-company-review-rejected',
        approvedMessage: 'Подготовьте договор с техническими условиями, срок 20 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ОТКОРРЕКТИРОВАТЬ ЗАЯВКУ',
        submitTitle: 'ОТПРАВИТЬ НА КОРРЕКТИРОВКУ',
      },
    },
  },
  CORRECTION: {
    Customer: {
      statusIndex: 1,
      statusTitle: { title: 'Устраните замечания' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'CorrectionApplicationMicrogenerator',
      isDisplayAction: false,
      displayActionTitle: 'РЕДАКТИРОВАТЬ ДАННЫЕ',
      summaryProps: {
        expansion: true,
        showMap: false,
        initSpoiler: false,
      },
    },
    Supplier: {
      statusIndex: 0,
      statusTitle: { title: 'На корректировке' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: true,
    },
  },
  APPROVED: {
    Customer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      actionProps: {
        reviewType: 'microgeneration-utility-company-offer-submitted',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
  },
  CONNECTION_DOCUMENTS_SUBMITTED: {
    Customer: {
      statusIndex: 3,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: false,
        approvedReviewType: 'microgeneration-utility-company-offer-approved',
        rejectedReviewType: 'microgeneration-utility-company-offer-rejected',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
    Boiler: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_INVOICE_CREATED: {
    Customer: {
      statusIndex: 5,
      isActive: true,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: true,
        approvedReviewType: 'microgeneration-utility-company-offer-approved',
        rejectedReviewType: 'microgeneration-utility-company-offer-rejected',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_DOCUMENTS_REJECTED: {
    Customer: {
      statusIndex: 3,
      statusTitle: { title: 'Предложения по изменениям отправлены,', subTitle: 'подготовка документов' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: false,
      offerDocumentTitle: 'Документы для согласования',
    },
    Supplier: {
      statusIndex: 1,
      statusTitle: { title: 'Замечания по условиям договора' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SelectTariff',
      actionProps: {
        reviewType: 'microgeneration-utility-company-offer-submitted-after-remarks',
      },
      hideOfferInSummary: true,
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
  },
  CONNECTION_DOCUMENTS_APPROVED: {
    Customer: {
      statusIndex: 4,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'ApprovedOffer',
      actionProps: {
        isDisabled: true,
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
        initSpoilerOffer: true,
      },
    },
    Supplier: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 5 рабочих дней',
      isActive: false,
      isDisplayDate: true,
      offerDocumentTitle: 'Документы отправленные на согласование',
    },
  },
  CONNECTION_CERTIFICATE_SUBMITTED: {
    Customer: {
      statusIndex: 6,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Supplier: {
      statusIndex: 4,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_REJECTED: {
    Customer: {
      statusIndex: 1,
      statusTitle: { title: 'Предоставьте дополнительную информацию' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'AdditionalInfo',
      actionProps: {
        reviewType: 'microgeneration-application-retail-company-additions-submitted-after-remarks',
        agreementTitle: 'договора купли-продажи',
      },
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
    },
    Supplier: {
      statusIndex: 5,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 0,
      statusTitle: { title: 'На уточнении' },
      statusSubTitle: 'Срок: 20 рабочих дней',
      isActive: false,
      isDisplayDate: true,
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_SUBMITTED: {
    Customer: {
      statusIndex: 1,
      /* statusSubTitle: 'с энергосбытовой компанией',*/
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 3,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 0,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'ApprovedApplication',
      summaryProps: {
        expansion: true,
        showMap: true,
        initSpoiler: false,
      },
      actionProps: {
        title: 'Запрос дополнительной информации',
        commentTitle: 'Укажите, какая дополнительная информация по заявке требуется',
        rejectButtonTitle: 'Требуется уточнение',
        approvedReviewType: 'microgeneration-application-retail-company-additions-approved',
        rejectedReviewType: 'microgeneration-application-retail-company-additions-remarks',
        approvedMessage: 'Подготовьте договор купли-продажи, срок 7 рабочих дней',
        rejectedMessage: 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ПРИСЛАТЬ УТОЧНЕНИЯ ПО ЗАЯВКЕ',
        submitTitle: 'ОТПРАВИТЬ ЗАПРОС',
      },
    },
  },
  ADDITIONAL_DOCUMENTS_FOR_RETAIL_APPROVED: {
    Customer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 7 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 3,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 1,
      statusSubTitle: 'Срок: 7 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendRetailerOffer',
      actionProps: {
        reviewType: 'microgeneration-application-retail-company-offer-submitted',
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_SUBMITTED: {
    Customer: {
      statusIndex: 3,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: true,
      isDisplayDate: false,
      actionComponent: 'ApprovedRetailerOffer',
      actionProps: {
        approvedReviewType: 'microgeneration-application-retail-company-offer-approved',
        rejectedReviewType: 'microgeneration-application-retail-company-offer-remarks',
        agreementTitle: 'ДОГОВОР КУПЛИ-ПРОДАЖИ',
      },
    },
    Supplier: {
      statusIndex: 3,
      /* statusSubTitle: 'Срок: 20 рабочих дней',*/
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 2,
      statusSubTitle: 'Срок: 3 рабочих дня',
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_REJECTED: {
    Customer: {
      statusIndex: 2,
      statusTitle: { title: 'Предложения по изменениям отправлены,', subTitle: 'подготовка договора купли-продажи' },
      statusSubTitle: 'Срок: 7 рабочих дней',
      isActive: false,
      isDisplayDate: false,
    },
    Supplier: {
      statusIndex: 5,
      isActive: false,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 1,
      statusTitle: { title: 'Замечания', subTitle: 'по условиям договора' },
      statusSubTitle: 'Срок: 7 рабочих дней',
      isActive: true,
      isDisplayDate: true,
      actionComponent: 'SendRetailerOffer',
      actionProps: {
        reviewType: 'microgeneration-application-retail-company-offer-submitted-after-remarks',
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
  RETAIL_OFFER_DOCUMENTS_APPROVED: {
    Customer: {
      statusIndex: 4,
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Supplier: {
      statusIndex: 5,
      isActive: true,
      isDisplayDate: true,
    },
    Retailer: {
      statusIndex: 3,
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
  CANCELLED: {
    Customer: {
      statusIndex: 10,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Supplier: {
      statusIndex: 5,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Retailer: {
      statusIndex: 4,
      statusTitle: { title: 'Аннулирована' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'Cancelled',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
};

const MICROGENERATION_CONNECTION_UTILITY = {
  ...MICROGENERATION_CONNECTION,
  CONNECTED: {
    Customer: {
      statusIndex: 6,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Supplier: {
      statusIndex: 5,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
    },
  },
};

const MICROGENERATION_CONNECTION_RETAIL = {
  ...MICROGENERATION_CONNECTION,
  CONNECTED: {
    Customer: {
      statusIndex: 5,
      statusTitle: { title: 'Договор подписан' },
      isActive: false,
      isDisplayDate: false,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
    Retailer: {
      statusIndex: 4,
      statusTitle: { title: 'Выполнена' },
      isActive: false,
      isDisplayDate: true,
      actionComponent: 'RetailerOfferSubmitted',
      actionProps: {
        agreementTitle: 'Договор купли-продажи',
      },
    },
  },
};

const PROCESS_DATA = {
  individualCustomerConnection: INDIVIDUAL_CUSTOMER_CONNECTION,
  individualCustomerConnectionRetail: INDIVIDUAL_CUSTOMER_CONNECTION,
  microgenerationConnectionUtility: MICROGENERATION_CONNECTION_UTILITY,
  microgenerationConnectionRetail: MICROGENERATION_CONNECTION_RETAIL,
};

export function initConnectionApplicationTaskData(task, targetAccountId = null) {
  const uType = userType[task.userType];
  const userTypeContractType = (task.workflowType === 'individualCustomerConnection' || task.workflowType === 'individualCustomerConnectionRetail') && uType === 'Retailer' &&
  task.contractType === 'ELECTRICITY_PURCHASE_CONTRACT' ? 'RetailerDkp' : uType;
  const initiatorUserType = (task.accountId === targetAccountId ? userType.Customer : uType);
  if (task.accountId === targetAccountId) {
    task.isInitiator = true;
  }
  const title = TITLE[task.workflowType][userTypeContractType];
  const stageData = PROCESS_DATA[task.workflowType];
  const onlyMicrogenerator = task.workflowType === 'microgenerationConnectionUtility' || task.workflowType === 'microgenerationConnectionRetail';
  const data = {
    ...stageData[task.stage][initiatorUserType],
    subtitle: `Создана: ${task.date}`,
    isInitiator: task.accountId === targetAccountId,
    statusList: STATUS_LIST[task.workflowType][(initiatorUserType === 'Customer' ? userType.Customer : uType)],
    ...task,
    onlyMicrogenerator: onlyMicrogenerator,
  };
  const currentStatus = data.statusTitle ? data.statusTitle : data.statusList[data.statusIndex];
  data.title = `${title.text} ${title.subTitle}`;

  return {
    data: data,
    status: currentStatus,
    title: title,
  };
}
