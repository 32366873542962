import * as XLSX from 'xlsx-js-style';

const worker = new Worker(new URL('./excelWorker.js', import.meta.url));
const parseWorker = new Worker(new URL('./excelParsingWorker.js', import.meta.url));

export const excelValidateWithWorker = (...args) => new Promise((resolve, reject) => {
  worker.onmessage = (event) => {
    resolve(event.data);
  };

  worker.onerror = (e) => {
    console.error(`Error: Line ${e.lineno} in ${e.filename}: ${e.message}`);
    reject(e);
  };

  worker.postMessage(args);
});

export const excelParseWithWorker = (...args) => new Promise((resolve, reject) => {
  parseWorker.onmessage = (event) => {
    resolve(event.data);
  };

  parseWorker.onerror = (e) => {
    console.error(`Error: Line ${e.lineno} in ${e.filename}: ${e.message}`);
    reject(e);
  };

  parseWorker.postMessage(args);
});

export const exportToExcel = (title, dataHeaders, headerLabel, dataList, valueKey, options) => {
  const headers = [];
  const data = [];
  const dataExcel = [];
  let rowDataIndex = 0;

  if (options.infoData) {
    options.infoData.forEach((item) => {
      dataExcel.push(item);
      rowDataIndex++;
    });
    dataExcel.push(['']);
    rowDataIndex++;
  }

  dataHeaders.forEach((item) => {
    headers.push(item[headerLabel]);
  });

  dataList?.forEach((item) => {
    const row = [];

    dataHeaders.forEach((header) => {
      row.push(valueByKey(header[valueKey], item, header.type));
    });

    data.push(row);
  });

  const workbook = XLSX.utils.book_new();

  const merges = [{ s: { r: rowDataIndex, c: 0 }, e: { r: rowDataIndex, c: headers.length - 1 } }];

  dataExcel.push([title]);

  let rowCount = data.length + 2;

  if (options?.topHeaders) {
    const topHeadersLabel = [];
    let index = 0;
    options.topHeaders.forEach((item) => {
      topHeadersLabel.push(item[headerLabel]);

      if (item.merge) {
        merges.push({ s: { r: 1 + rowDataIndex, c: index }, e: { r: 1 + rowDataIndex, c: index + item.merge } });
        index += item.merge;
        for (let i = 0; i < item.merge; i++) {
          topHeadersLabel.push(item[headerLabel]);
        }
      }

      index += 1;
    });

    dataExcel.push(topHeadersLabel);
    rowCount += 1;
  }

  dataExcel.push(headers);
  dataExcel.push(...data);

  let totalRowCount = 0;

  if (options?.total) {
    if (options.total.title) {
      totalRowCount++;

      dataExcel.push(['']);

      dataExcel.push([options.total.title]);
      merges.push({
        s: { r: rowCount + rowDataIndex + 1, c: 0 },
        e: { r: rowCount + rowDataIndex + 1, c: headers.length - 1 },
      });
    }

    if (options.total.isDisplayHeader) {
      dataExcel.push(headers);
      totalRowCount++;
    }

    dataExcel.push(options.total.row);
    totalRowCount = totalRowCount + 2;
  }

  if (options?.footer) {
    let footerIndex = 0;

    dataExcel.push(['']);
    merges.push({
      s: { r: totalRowCount + rowCount + rowDataIndex, c: 0 },
      e: { r: totalRowCount + rowCount + rowDataIndex, c: headers.length - 1 },
    });

    options.footer.forEach((footerItem) => {
      footerIndex++;

      const topHeadersLabel = [];
      let index = 0;
      footerItem.forEach((item) => {
        topHeadersLabel.push(item[headerLabel]);

        if (item.merge) {
          merges.push({
            s: { r: footerIndex + totalRowCount + rowCount + rowDataIndex, c: index },
            e: { r: footerIndex + totalRowCount + rowCount + rowDataIndex, c: index + item.merge },
          });
          index += item.merge;
          topHeadersLabel.push(item[headerLabel]);
        }

        index += 1;
      });
      dataExcel.push(topHeadersLabel);
    });
  }

  const worksheet = XLSX.utils.aoa_to_sheet(dataExcel);

  worksheet['!merges'] = merges;

  const styles = {
    mainHeader: {
      font: {
        bold: true,
        name: 'Times New Roman', // Font name
        sz: 16, // Font size
      },
      border: {
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
      alignment: {
        // Center align the text horizontally and vertically
        horizontal: 'center',
        vertical: 'center',
      },
    },
    header: {
      font: {
        bold: true,
        name: 'Times New Roman', // Font name
        sz: 14, // Font size
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
    },
    allCell: {
      font: {
        name: 'Times New Roman', // Font name
        sz: 14, // Font size
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
      wrapText: true,
    },
  };

  if (options?.alignment === 'center') {
    styles.allCell.alignment = {
      horizontal: 'center',
      vertical: 'center',
      wrapText: '1',
    };
    styles.header.alignment = {
      horizontal: 'center',
      vertical: 'center',
      wrapText: '1',
    };
  }

  const cellAddressHeader = XLSX.utils.encode_cell({
    r: rowDataIndex,
    c: 0,
  });
  getOrCreateCell(cellAddressHeader, styles.mainHeader);

  const lastColName = colNameFromNumber(headers.length - 1);

  const headerRange = XLSX.utils.decode_range(`A${2 + rowDataIndex}:${lastColName}${2 + rowDataIndex}`);
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const cellAddress = XLSX.utils.encode_cell({
      r: headerRange.s.r,
      c: col,
    });

    getOrCreateCell(cellAddress, styles.header);
  }

  // Apply styles to the numeric cells
  const numberCellRange = XLSX.utils.decode_range(
    `A${3 + rowDataIndex}:${lastColName}${dataExcel.length}`,
  );
  for (let row = numberCellRange.s.r; row <= numberCellRange.e.r; row++) {
    for (let col = numberCellRange.s.c; col <= numberCellRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });

      getOrCreateCell(cellAddress, styles.allCell);
    }
  }

  if (options?.total?.title) {
    const cellAddressHeader = XLSX.utils.encode_cell({
      r: rowCount + 1 + rowDataIndex,
      c: 0,
    });
    getOrCreateCell(cellAddressHeader, styles.mainHeader);
  }

  if (options?.total) {
    let rowTotalHeaders = rowCount + 1 + rowDataIndex;

    if (options.total.title) {
      rowTotalHeaders += 1;
    }

    if (options.total.isDisplayHeader) {
      rowTotalHeaders += 1;
    }

    const headerRange = XLSX.utils.decode_range(`A${rowTotalHeaders}:${lastColName}${rowTotalHeaders}`);
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({
        r: headerRange.s.r,
        c: col,
      });
      getOrCreateCell(cellAddress, styles.header);
    }
  }

  const colWidths = [];

  for (let row = 0; row < dataExcel.length; row++) {
    for (let col = 0; col < dataExcel[row].length; col++) {
      let maxWidth = -1;
      const cellValue = dataExcel[row][col] ? dataExcel[row][col].toString() : '';
      const cellWidth = (cellValue.length + (2 * cellValue.length / 3)) * (options?.widthRate ?? 1);
      maxWidth = Math.max(maxWidth, cellWidth);
      if (options?.maxWidth && maxWidth > options.maxWidth) {
        maxWidth = options.maxWidth;
      }
      if (!colWidths[col]) {
        colWidths.push({ wch: maxWidth });
      } else if (maxWidth > colWidths[col]?.wch) {
        colWidths[col] = { wch: maxWidth };
      }
    }
  }
  worksheet['!cols'] = colWidths;

  if (options?.rowHeights) {
    worksheet['!rows'] = options.rowHeights;
  }

  let sheetName = options?.sheetName ?? title;
  if (sheetName.length > 31) {
    sheetName = `${title.substring(0, 28)}...`;
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${title}.xlsx`);
  return XLSX.write(workbook, { type: 'buffer' });

  function getOrCreateCell(cellAddress, style) {
    if (!worksheet[cellAddress]) {
      worksheet[cellAddress] = { t: 's' };
    }
    if (!worksheet[cellAddress].s) {
      worksheet[cellAddress].s = {};
    }
    Object.assign(worksheet[cellAddress].s, style);
  }

  function valueByKey(key, data, type) {
    let value = options?.emptyValue ?? '';

    const keys = key.split('.');

    if (keys.length > 1) {
      value = keys.reduce((acc, cur) => {
        if (acc[cur]) {
          return acc[cur];
        }
        return options?.emptyValue ?? '';
      }, data);
    } else if (data[key] === 0 || (data[key] && data[key].length !== 0)) {
      value = data[key];
    }

    if (type === 'list' && value instanceof Array) {
      return value.map((it) => it.value).join(', ');
    }

    return value;
  }

  function colNameFromNumber(number) {
    const codeA = 'a'.charCodeAt(0);
    const codeZ = 'z'.charCodeAt(0);
    const length = codeZ - codeA + 1;

    let result = '';
    let n = number;
    while (n >= 0) {
      result = String.fromCharCode((n % length) + codeA) + result;
      n = Math.floor(n / length) - 1;
    }

    return result.toUpperCase();
  }
};
