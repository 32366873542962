<template>
  <div class="form-wrp">
    <div class="form">
      <div class="form-header">
        <h1 class="form-header__title">
          {{ contractPatternName }}
        </h1>
        <p class="form-header__subtitle">Введите параметры {{ contractType }}</p>
      </div>
      <v-divider style="margin: 24px 0; height: 0;" />
      <contract-info />
      <v-divider style="margin: 24px 0; height: 0;" />
      <contract-sides />

      <template v-if="additionalAgreementType !== 'ADDITIONAL_REQUISITES'">
        <v-divider style="margin: 24px 0; height: 0;" />
        <contract-paid-for />
        <v-divider style="margin: 24px 0; height: 0;" />
      </template>
    </div>

    <div class="form__tooltips">
      <p class="form__tooltips__text" style="margin-bottom: 24px">* поля обязательные для заполнения</p>
      <input-tooltip
        :text="tooltipText"
        style="width: 320px;"
      />
      <input-tooltip
        :text="tooltipText"
        style="width: 320px; margin-top: 285px;"
      />
      <input-tooltip
        :style="getContract.selectedTemplate.sides.length > 2 ? {'width': '320px', 'margin-top': '545px'} : {'width': '320px', 'margin-top': '510px'}"
        :text="tooltipText"
      />
      <input-tooltip
        v-if="getContract.selectedTemplate.sides.length > 2"
        :text="tooltipText"
        style="width: 320px; margin-top: 550px;"
      />
    </div>
  </div>
</template>

<script>
import { InputTooltip, VDivider } from 'components';
import ContractInfo from 'views/private/UploadDocument/steps/ContractRequisites/ContractInfo.vue';
import ContractSides from 'views/private/UploadDocument/steps/ContractRequisites/ContractSides.vue';
import ContractPaidFor from 'views/private/UploadDocument/steps/ContractRequisites/ContractPaidFor.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'IndexElectricityTransmissionContract',
  components: {
    ContractPaidFor,
    ContractSides,
    ContractInfo,
    VDivider,
    InputTooltip,
  },
  computed: {
    contractPatternName() {
      return this.getPatternName;
    },
    tooltipText() {
      return this.boldString('Важно: Введите точные данные, указанные в документе, даже если эти данные содержат устаревшую или ошибочную информацию.', 'Важно');
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договора';
      }
        return 'доп.соглашения';

    },
    additionalAgreementType: {
      get() {
        if (this.getContract?.additionalAgreementType) {
          return this.getContract.additionalAgreementType;
        }
        return '';
      },
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getPatternName']),
  },
  methods: {
    boldString(str, substr) {
      const strRegExp = new RegExp(substr, 'g');
      return str.replace(strRegExp, `<b>${substr}</b>`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-wrp {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 850px;
    }

    &__subtitle {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4C4E51;
    }
  }

  &__tooltips {
    margin-top: 128px;

    &__text {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #71757A;
    }
  }
}
</style>
