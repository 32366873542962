<template>
  <login-template>
    <div v-if="showIndividual" :class="$style.overlay"/>
    <div :class="$style.container">
      <template v-if="step===0">
        <certificate-loader @error="() => error = true" @loaded="(data)=>certificates=data"/>
        <certificate-selector v-if="!error && certificates.length" v-model="selectedCertificate"
                              :certificates="certificates"/>
        <submit-task-details :disabled="!selectedCertificate" title="ПРОВЕРИТЬ КЭП"
                             @submit="verifyCap"/>
      </template>
      <template v-if="step===1">
        <certificate-validator :certificate="selectedCertificate" :custom-validations="capValidations"
                               @error="onCertificateValidationError" @parsed="onCertificateParsed"
                               @validated="onCertificateValidated"/>
        <div
          v-if="requestCodeError"
          :class="$style.errorText"
          role="errorText"
        >
          {{ requestCodeErrorText }}
        </div>
        <p :class="$style.backLink" @click="back">ИСПОЛЬЗОВАТЬ ДРУГУЮ КЭП</p>
        <p :class="$style.backLink" @click="home">ИСПОЛЬЗОВАТЬ ДРУГОЙ МЕТОД АВТОРИЗАЦИИ</p>
      </template>
      <template v-if="step===2">
        <div>
          <label>ПАРОЛЬ ОТПРАВЛЕН НА</label>
          <c-input
            :value="maskedSendTo"
            disabled
          />
        </div>
        <div :class="$style.code">
          <label>ВВЕДИТЕ ОДНОРАЗОВЫЙ ПАРОЛЬ ИЗ {{ showEnterCodeMessage }}</label>
          <one-time-password-input :is-error="isCodeError" :is-waiting="isSendingCode" @code-input="codeEntered" @code-request="codeRequest"/>
          <horizontal-progress-bar v-if="isSendingCode"/>
          <div
            v-if="isCodeError"
            :class="$style.errorText"
            role="errorText"
          >
            {{ codeErrorMessage }}
          </div>
        </div>
      </template>
    </div>
    <div :class="$style.navigate">
      <v-button :class="$style.back" type="button" variant="outlined-gray-3" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <v-button v-if="buttonText" :class="$style.reg" :disabled="error" type="button" @click="buttonAction">
        <span>{{ buttonText }}</span>
        <chevron-right/>
      </v-button>
    </div>
    <div :class="$style.help">
      <p v-if="infoText?.length">{{ infoText }}</p>
      <router-link :class="$style.helpLink" to="/help-individual">
        <img :class="$style.helpicon" alt="icon: help" height="24px" src="@/assets/icons/help/help.svg" width="24px">&nbsp;ПОДДЕРЖКА
      </router-link>
    </div>
    <cookie-notification :visible="cookieVisible" @close="closeCookie"/>
    <dialog-template
      v-model="showIndividual"
      :top="8"
      height100
      hide-close-button
      style-type="roundedStyle"
      @close="closeIndividual"
    >
      <individual-connection-app
        :certificate="selectedCertificate"
        :company="company"
        :is-legal-entity="isLegalEntity"
        :is-cap="isCap"
        @close="closeIndividual"
      />
    </dialog-template>
  </login-template>
</template>

<script>
import LoginTemplate from 'templates/LoginTemplate';

import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { mapActions, mapGetters } from 'vuex';

// import HTTPError from 'lib/utils/errors';
import IndividualConnectionApp from 'organisms/registration/individual-connection';
import DialogTemplate from 'templates/DialogTemplate';
import CookieNotification from 'components/CookieNotification/CookieNotification';
import { CertificateLoader, VButton } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ChevronRight from 'atoms/icons/navigation/ChevronRight';
import CertificateSelector from 'components/ecp/CertificateSelector/CertificateSelector.vue';
import CertificateValidator from 'components/ecp/CertificateValidator/CertificateValidator.vue';
import formatting from 'views/public/login/mixins/formatting';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import HTTPError from 'lib/utils/errors';
import CInput from 'atoms/common/inputs/CInput.vue';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput.vue';
import { clearLocalStorage } from 'utils/localStorage';

export default {
  name: 'ConsumerType',
  mixins: [formatting],
  components: {
    OneTimePasswordInput,
    CInput,
    HorizontalProgressBar,
    CertificateValidator,
    CertificateSelector,
    ChevronRight,
    CookieNotification,
    DialogTemplate,
    IndividualConnectionApp,
    LoginTemplate,
    SubmitTaskDetails,
    VButton,
    ChevronLeft,
    CertificateLoader,
  },
  created() {
    this.sendTo = this.getUser?.email;
    this.step = this.getUser?.email ? 2 : 0;
  },
  data() {
    return {
      userType: undefined,
      company: {},
      certificates: [],
      selectedCertificate: null,
      error: false,
      legalEntityType: undefined,
      showIndividual: false,
      showEmployee: false,
      cookieVisible: !JSON.parse(localStorage.getItem('agreedCookie')),
      step: 0,
      authorizationString: null,
      capValidationError: false,
      requestCodeError: false,
      requestCodeErrorText: '',
      sendTo: '',
      code: '',
      isSendingCode: false,
      isCodeError: false,
      codeErrorMessage: '',
    };
  },
  computed: {
    maskedSendTo() {
      return this.maskedContact(this.sendTo);
    },
    isShownCodeErrorMessage() {
      const { status } = this.confirmStatus;

      return status && status !== 0;
    },
    isPersonEntity() {
      return this.userType === 'person';
    },
    isLegalEntity() {
      return this.userType === 'legal';
    },
    isCap() {
      return this.legalEntityType === 'cap';
    },
    isWithoutCap() {
      return this.legalEntityType === 'without_cap';
    },
    capValidations() {
      return [{
        validator: this.isRegistered,
        errorText: 'Пользователь с данным СНИЛС не зарегистрирован',
      }];
    },
    showEnterCodeMessage() {
      if (this.isEmail(this.sendTo)) {
        return 'ПИСЬМА';
      }
      return 'СМС';

    },
    infoText() {
      if (this.step === 1) return '* Для авторизации с помощью электронной подписи потребуется подписать случайно сгенерированную строку.';
      if (this.step === 2) return '* Для авторизации с помощью электронной подписи потребуется подписать введённый код.';
      return null;
    },
    buttonText() {
      if (this.step === 1 && this.capValidationError) return 'Зарегистрироваться';
      if ((this.step === 1 && !this.capValidationError) || this.step === 2) return 'Подписать и войти';
      return null;
    },
    ...mapGetters('user', ['getUser']),
  },
  watch: {
    selectedCertificate() {
      if (this.selectedCertificate && this.certificates.length === 1) {
        this.verifyCap();
      }
    },
  },
  methods: {
    buttonAction() {
      if (this.step === 1 && this.capValidationError) this.startRegistration();
      if (this.step === 1 && !this.capValidationError) this.startAuth();
      if (this.step === 2) this.sendCode();
    },
    codeEntered(code) {
      this.code = code;
    },
    async sendCode() {
      this.codeErrorMessage = '';
      this.isSendingCode = true;
      this.isCodeError = false;
      try {
        const res = await this.confirmUser({ code: this.code });
        if (res.message?.length) {
          this.isCodeError = true;
          this.codeErrorMessage = res.message;
        } else {
          await this.$router.push('/cabinet');
        }
      } catch (e) {
        this.codeErrorMessage = HTTPError.network(e).message;
        this.isCodeError = true;
      } finally {
        this.isSendingCode = false;
      }
    },
    closeCookie() {
      this.cookieVisible = false;
    },
    setUserType(type) {
      if (this.showIndividual) {
        this.showIndividual = false;
      }
      this.userType = type;
      if (this.userType === 'person') {
        this.showIndividual = true;
      } else if (this.userType === 'legal') {
        this.setLegalEntityType('cap');
      }
    },
    closeIndividual() {
      this.userType = undefined;
      this.showIndividual = false;
    },
    openLegalEntity() {
      this.setUserType('legal');
      this.showIndividual = true;
    },
    openIndividual() {
      this.setUserType('person');
      this.showIndividual = true;
    },
    setLegalEntityType(type) {
      if (this.showEmployee)
        this.showEmployee = false;
      this.legalEntityType = type;

      if (type === 'without_cap') {
        this.showEmployee = true;
      }
    },
    formatDate(date) {
      date = date.split('T');
      date = date[0].replace(/-/gi, '.').split('.').reverse()
        .join('.');

      return date;
    },
    async isRegistered(certificate) {
      clearLocalStorage();
      const snils = certificate.find((item) => item.title === 'СНИЛС').description;
      try {
        this.authorizationString = await this.requestCapAuth({ snils: snils });
        if (!this.authorizationString) return false;
      } catch (e) {
        console.log('return false');
        return false;
      }
      return true;
    },
    verifyCap() {
      this.step = 1;
    },
    back() {
      if (this.step === 0) this.home();
      else this.step--;
    },
    home() {
      this.$router.back();
    },
    async startAuth() {
      this.requestCodeError = false;
      this.requestCodeErrorText = '';
      try {
        const signedCode = await this.signDocument({
          xml: this.authorizationString,
          certificate: this.selectedCertificate,
        });
        this.sendTo = await this.requestCapCode({ signedString: signedCode });
        this.step++;
      } catch (e) {
        this.requestCodeError = true;
        this.requestCodeErrorText = HTTPError.network(e).message;
      }
    },
    onCertificateValidationError() {
      this.capValidationError = true;
    },
    onCertificateValidated() {
      this.capValidationError = false;
    },
    onCertificateParsed(company) {
      this.company = company;
    },
    startRegistration() {
      if (this.company.isLegal) {
        this.openLegalEntity();
      } else {
        this.openIndividual();
      }
    },
    codeRequest() {
      this.userSendAuthenticationCode();
    },
    ...mapActions('user', ['requestCapAuth', 'requestCapCode', 'userSendAuthenticationCode', 'confirmUser']),
    ...mapActions('dataLists', ['setSuppliers']),
    ...mapActions('document', ['signDocument']),
  },
};
</script>

<style lang="sass" module>
.help
  height: 24px
  display: flex
  justify-content: flex-end
  align-items: flex-end

  p
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: #4C4E51

.helpLink
  display: flex
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 19px
  text-transform: uppercase
  color: #C1C4C7
  align-items: center

.helpLink:hover
  color: #4F4F4F

.helpicon
  max-width: 24px
  max-height: 24px
  display: block

.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.backLink
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: #71757A
  text-align: center
  text-decoration: underline
  cursor: pointer
  margin-bottom: 16px

.back, .reg
  width: max-content
  height: 46px
  display: flex
  align-items: center
  cursor: pointer
  padding: 10px 30px

  svg
    fill: #4C4E51

.reg
  svg
    fill: #FFF

.overlay
  background-color: #666666
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  opacity: 0.5

.navigate
  width: 100%
  display: flex
  justify-content: space-between

.code
  display: flex
  flex-direction: column
  margin-top: 28px
  align-items: center

label
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: #4F4F4F

.errorText
  +small-text
  margin-bottom: 32px
  color: red
</style>
