<template>
  <div>
    <edit />
  </div>
</template>

<script>
import Edit from 'atoms/icons/action/Edit.vue';

export default {
  name: 'VTableCellEdit',
  components: { Edit },
};
</script>

<style scoped>

</style>
