<template>
  <div :style="customStyle">
    <v-search-select
        :value="selectedItem"
        :disabled="isDisabled"
        :required="isRequired"
        :title="title"
        :fetch-default-data-function="fetchDefaultDataFunction"
        :fetch-data-function="fetchDefaultDataFunction"
        :placeholder="placeholder"
        :not-reset-search="notResetSearch"
        :search.sync="searchItem"
        @input="selectItem"
    />
  </div>
</template>

<script>
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import { mapActions } from 'vuex';

export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  components: {
    VSearchSelect,
  },
  props: {
    title: {
      default: '',
    },
    selected: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    items: {
      default: () => [],
    },
    findIds: {
      type: Array,
      required: false,
      validator(value) {
        return value.every((item) => typeof item.queryIndex === 'number');
      },
    },
    marginBottom: {
      type: String,
      default: '24',
    },
    placeholder: {
      type: String,
      default: '',
    },
    notResetSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:search'],
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        console.log('!selectedItem', val);
        this.$emit('change', val);
      },
    },
    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    customStyle() {
      return `marginBottom: ${this.marginBottom}px`;
    },
  },
  methods: {
    selectItem(value, label) {
      console.log('!selectItem', value);
      console.log('!selectItem label', label);
      this.selectedItem = value;
      this.$emit('update:search', label);
    },
    async fetchDefaultDataFunction() {
      try {
        this.loading = true;
        return await this.supplierDepartments({ inn: '7720522853' });
      } catch (error) {
        console.log(`error supplierDepartments: ${error}`);
        return [];
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('dataLists', ['supplierDepartments']),
  },
};
</script>
