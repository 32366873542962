<template>
  <div :class="$style.container">
    <v-circle-loader v-if="!isLoaded"/>
    <div v-else :class="$style.content">
      <p :class="$style.title">Детали задачи</p>

      <div :class="$style.timeGroup">
        <p>Дата создания документа:</p>
        <p>{{ convertDbDateTimeFormat(document.createdAt) }}</p>
      </div>

      <div :class="$style.timeGroup">
        <p>Дата обновления документа:</p>
        <p>{{ convertDbDateTimeFormat(document.updatedAt) }}</p>
      </div>

      <p :class="$style.description">Наименование Объекта проверки</p>
      <p :class="$style.stepDate">{{ document.title }}</p>

      <div v-if="history">
        <p :class="$style.description">История</p>
        <document-history
          :history="history"
        />
      </div>

      <div v-if="documentId">
        <v-file-attachment
          :document-id="documentId"
          :label="label"
        />
      </div>

      <div v-if="!isDeleted && (isRejected || (document.status === 'NULLIFIED' && document.rejectedReason))" style="margin-top: 24px">
        <p v-if="isRejected && !isNullified" :class="$style.description">Отклонён</p>
        <p v-if="isNullified" :class="$style.description">{{ `${afterNullifiedName}` }}</p>
        <p v-for="(rejected, index) in document.sides" :key="'rejected' + index"
           :class="$style.stepDate">
          {{ rejected.status === 'REJECTED' || rejected.status === 'NULLIFIED' ? rejected.name : '' }}
        </p>
        <p v-if="spoilerText" :class="$style.stepDate">
          {{ `Комментарий: ${spoilerText}` }}
          <span v-if="document.rejectedReason?.length > 32" :class="$style.spoiler" @click="spoiler = !spoiler">
            {{ spoiler ? '(показать всё)' : '(скрыть)' }}
        </span>
        </p>
      </div>

      <div v-if="powerOfAttorneyList.length > 0">
        <div :class="$style.content">
          <p :class="$style.title">Доверенности подписывающего лица</p>
        </div>
        <div v-for="(doc, idx) in powerOfAttorneyList" :key="idx">
          <v-file-attachment
            :document-id="doc.documentId"
            :label="doc.title"
          />
          <template v-if="isDisplayPowerAttorney && doc.id === powerAttorneyIdx">
            <div v-if="powerAttorneyPdfUrlView" :class="$style.viewPdf">
              <iframe :src="powerAttorneyPdfUrlView"></iframe>
            </div>
            <div v-else :class="$style.waitingPdf">
              <span>Загрузка...</span>
              <horizontal-progress-bar/>
            </div>
          </template>
        </div>
      </div>

      <template v-if="isValidForSubmitting">
        <div v-if="!isRejected && !completed" :class="$style.statusGroup">
          <div :class="$style.radioGroup">
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 1"
                :class="$style.control"
                name="taskStatus"
                role="radio.approve"
                type="radio"
                @click="statusGroupClick(1)"
              />
              <div :class="$style.contentRadioGroup">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupCheck"></div>
                  <h3 :class="$style.titleRadioGroup">Нет замечаний</h3>
                </div>
              </div>
            </label>
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 2"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(2)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">Имеются замечания</h3>
                </div>
              </div>
            </label>
            <label v-if="isDisagreementsEnabled" :class="$style.labelRadio">
              <input
                :checked="statusGroup === 3"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(3)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">Разногласия</h3>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div v-if="canBeNullified" :class="$style.statusGroup">
          <div :class="$style.radioGroup">
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 3"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(4)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">{{ `${nullifyName}` }}</h3>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div v-if="statusGroup === 2 || statusGroup === 4" :class="$style.correctionGroup">
          <p :class="$style.correctionTitle">
            Комментарий
          </p>
          <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

          <div :class="$style.blockContent">
            <c-textarea
              v-model="comments"
              role="correctionComments"
            />
          </div>
        </div>

        <div v-if="statusGroup === 3 && document.xmlDocumentType === 'ElectricityBalance'"
             :class="$style.correctionGroup">
          <p :class="$style.correctionTitle">
            Величина услуги при разногласиях
          </p>
          <div :class="$style.blockContent">
            Услуга по одноставке, кВт.ч
            <c-input
              v-model="paymentVolumeOnePartElectricity"
              role="correctionPowerService"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Услуга по двухставке, кВт.ч
            <c-input
              v-model="paymentVolumeTwoPartElectricity"
              role="correctionPowerService"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Услуга по мощности, МВт
            <c-input
              v-model="paymentVolumeTwoPartPower"
              role="paymentVolumepaymentVolumeTwoPartPower"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            <c-checkbox
              v-model="accountingTransit"
              label="Учитывать транзит в услуге"
              role="additionalOwner"
            />
          </div>
          <div v-if="accountingTransit" :class="$style.blockContent">
            Услуга транзит, кВт.ч
            <c-input
              v-model="totalTransit"
              role="paymentVolumeTransit"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Общая величина услуги, кВт.ч
            <c-input
              v-model="paymentVolume"
              :disabled="true"
              role="paymentVolumeTotal"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Скорректированный полезный отпуск, кВт.ч
            <c-input
              v-model="totalUsefulVacation"
              :disabled="true"
              role="totalUsefulVacation"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Величина потерь, кВт.ч
            <c-input
              v-model="totalLoss"
              :disabled="true"
              role="totalLoss"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Величина потерь, %
            <c-input
              v-model="totalLossPercent"
              :disabled="true"
              role="totalLoss"
              type="number"
            />
          </div>
        </div>
      </template>


      <simple-signature-modal
        :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
        :xml-document="actXml"
        @close="isVisibleSimpleSignatureModal=false"
        @document-signed="onDocumentSignedBySimple"/>

      <div v-if="kind === 'documentUpdateInfo' && document.xmlDocumentType === 'Contract' && !isRejected && !isNullified && !isDeleted"
           :class="$style.correctionGroup">
        <div :class="$style.blockContent">
          Номер документа
          <c-input
            v-model="documentNumber"
            role="documentNumber"
          />
        </div>
        <div :class="$style.blockContent">
          Дата заключения документа
          <c-date-picker
            v-model="documentDate"
            role="documentDate"
          />
        </div>
      </div>

      <div v-if="kind === 'documentReload' && isRejected && !isNullified && !isDeleted">
        <div v-if="actVerification" :class="$style.content">

          <p>Загрузите новые сканы для акта верификации</p>

          <c-uploading v-model="documentsScan"/>

          <div v-if="documentsScan.length">
            <file-list-preview :list="documentsScan" action
                               @delete="handleRemoveDocumentsScan"/>
          </div>
          <submit-task-details
            v-if="!onlyReading"
            :class="{[$style.submit]: true}"
            :disabled="isNotValid || isWaitingSubmit || documentsScan.length === 0 || waitingXml"
            :title="'Перезагрузить сканы'"
            @submit="reloadDocumentScansHandler"
          />
        </div>
        <submit-task-details
          v-if="!onlyReading"
          :class="{[$style.submit]: true}"
          :disabled="isNotValid || isWaitingSubmit || waitingXml"
          :title="'Удалить документ'"
          @submit="deleteDocumentHandler"
        />
        <div v-if="reloadDocumentAvailable">
          <submit-task-details
            v-if="!onlyReading"
            :class="{[$style.submit]: true}"
            :disabled="isNotValid || isWaitingSubmit || waitingXml"
            title="Исправить замечания"
            @submit="reloadDocumentHandler"
          />
        </div>
      </div>
      <div v-else>
        <submit-task-details
          v-if="!onlyReading"
          :class="{[$style.submit]: true, [$style.rejected]: isRejected || isNullified || isDeleted}"
          :disabled="isNotValid || isWaitingSubmit || isRejected || isNullified || (!statusGroup && kind !== 'documentUpdateInfo') || onlyReading || waitingXml"
          :title="actionButton"
          @submit="submit"
        />
      </div>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { convertDbDateTimeFormat, formatDateToXmlDateFormat } from 'lib/utils/date';
import { SimpleSignatureModal, VFileAttachment, VCircleLoader } from 'components';
import axios from 'axios';
import generateXml from 'lib/storage/connection-application/data';
import { completedByStatus } from 'lib/utils/formatXmlType';
import DocumentHistory from 'components/ApproveDocument/DocumentHistory';
import CDatePicker from 'atoms/common/inputs/CDatePicker.vue';
import { addNumberAndDateToContract } from 'components/ApproveDocument/updateDocumentInfo';
import { setDraft } from '@/services/draft';

export default {
  name: 'ApproveDocument',
  components: {
    CDatePicker,
    DocumentHistory,
    VFileAttachment,
    SimpleSignatureModal,
    VCircleLoader,
    CInput: () => import('atoms/common/inputs/CInput'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CCheckbox: () => import('atoms/common/CCheckbox'),
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    FileListPreview: () => import('molecules/upload/form/FileListPreview.vue'),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    rejectedByWorkflow: {
      type: Boolean,
      required: false,
    },
    completedByWorkflow: {
      type: Boolean,
      required: false,
    },
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      document: {},
      contractKind: null,
      isAdditional: null,
      actVerification: undefined,
      currentSideStatus: '',
      spoiler: true,
      isWaitingSubmit: false,
      submitError: '',
      statusGroup: undefined,
      comments: '',
      actXml: undefined,
      actSignature: undefined,
      waitingXml: false,
      completed: false,
      history: [],
      documentId: undefined,
      label: '',
      documentsScan: [],

      balance: undefined,
      accountingTransit: false,
      paymentVolumeOnePartElectricity: 0,
      paymentVolumeTwoPartElectricity: 0,
      paymentVolumeTwoPartPower: 0,
      totalTransit: 0,
      paymentVolume: 0,
      totalUsefulVacation: 0,
      totalLoss: 0,
      totalLossPercent: 0,

      // Доверенности
      waitingPowerAttorney: false,
      isDisplayPowerAttorney: false,
      powerAttorneyPdfUrlView: undefined,
      powerOfAttorneyListBase: [],
      powerAttorneyIdx: null,
      waitingDocXml: false,

      // Подписание ПЭП
      isVisibleSimpleSignatureModal: false,
      signedDocument: '',

      // Обновление договора
      documentNumber: '',
      documentDate: '',

      isLoaded: false,
      draftId: undefined,
    };
  },
  computed: {
    spoilerText() {
      if (!this.document.rejectedReason)
        return;
      return this.spoiler && this.document.rejectedReason.length > 32
        ? `${this.document.rejectedReason.split(' ').slice(0, 32).join(' ')}...`
        : this.document.rejectedReason;
    },
    isRejected() {
      return this.document.status === 'REJECTED' || this.rejectedByWorkflow;
    },
    isDeleted() {
      return this.document.status === 'DELETED';
    },
    isNullified() {
      return this.document.status === 'NULLIFIED';
    },
    nullifyName() {
      if (this.document.xmlDocumentType === 'PowerOfAttorney' || this.document.xmlDocumentType === 'Доверенность') {
        return 'Отменить';
      }
      return 'Аннулировать';
    },
    afterNullifiedName() {
      if (this.document.xmlDocumentType === 'PowerOfAttorney' || this.document.xmlDocumentType === 'Доверенность') {
        return 'Отменена';
      }
      return 'Аннулирован';
    },
    reloadDocumentAvailable() {
      const acceptedXmlTypes = ['Contract', 'EighteenthForms', 'UsefulVacation', 'PowerOfAttorneyDig', 'PowerOfAttorney', 'Доверенность'];
      return acceptedXmlTypes.includes(this.document.xmlDocumentType);
    },
    isNotValid() {
      if (this.statusGroup === 3) return false;
      return ((this.currentSideStatus === 'SIGNED') && !this.canBeNullified) ||
        ((this.currentSideStatus === 'APPROVED' || this.currentSideStatus === 'WAITING_UPDATE_INFO') && this.kind === 'documentAgreement') ||
        (this.kind === 'documentUpdateInfo' && !this.isValidUpdate) ||
        (this.statusGroup === 2 && this.comments.length < 3) ||
        (this.statusGroup === 3 && !this.isValidDisagreement) ||
        (this.statusGroup === 4 && this.comments.length < 3);
    },
    actionButton() {
      if (this.isDeleted) {
        return 'УДАЛЕНО';
      }
      if (this.isNullified) {
        return this.afterNullifiedName;
      }
      if (this.isRejected && !this.isNullified) {
        return 'ОТКЛОНЕНО';
      }
      if (this.completed && this.kind === 'documentReload') {
        return 'ИСПРАВЛЕН';
      }
      if (this.statusGroup === 3) {
        return 'СФОРМИРОВАТЬ ПРОТОКОЛ РАЗНОГЛАСИЙ';
      }
      if (this.statusGroup === 4) {
        return this.nullifyName;
      }
      if (this.statusGroup === 2) {
        return 'ОТКЛОНИТЬ';
      }
      if ((this.currentSideStatus === 'APPROVED' || this.currentSideStatus === 'WAITING_UPDATE_INFO') && this.kind === 'documentAgreement') {
        return 'СОГЛАСОВАН';
      }
      if (this.kind === 'documentUpdateInfo') {
        return 'ВНЕСТИ КОРРЕКТИРОВКИ';
      }
      switch (this.currentSideStatus) {
        case 'DRAFT': {
          return `СОГЛАСОВАТЬ ${this.actVerification ? 'АКТ ВЕРИФИКАЦИИ' : 'ДОКУМЕНТ'}`;
        }
        case 'SIGNED': {
          if (this.kind === 'documentSigning') return 'ПОДПИСАН';
          if (this.kind === 'documentAgreement') return 'СОГЛАСОВАН';
          return '';
        }
        default: {
          return `ПОДПИСАТЬ ${this.actVerification ? 'АКТ ВЕРИФИКАЦИИ' : 'ДОКУМЕНТ'}`;
        }
      }
    },
    isValidDisagreement() {
      return this.document.xmlDocumentType === 'ElectricityBalance' && parseFloat(this.paymentVolumeOnePartElectricity) > 0 &&
        parseFloat(this.paymentVolumeTwoPartElectricity) >= 0 &&
        parseFloat(this.paymentVolumeTwoPartPower) >= 0 &&
        parseFloat(this.totalTransit) >= 0 &&
        parseFloat(this.paymentVolume) >= 0 &&
        parseFloat(this.totalUsefulVacation) >= 0;
    },
    isValidUpdate() {
      return this.document.xmlDocumentType !== 'Contract' || (this.documentNumber.length && this.documentDate.length);
    },
    isDisagreementsEnabled() {
      return this.document.initiatorAccountId !== this.getAccount.id && (this.isAdditional || !this.contractKind);
    },
    powerOfAttorneyList() {
      return this.powerOfAttorneyListBase;
    },
    canBeNullified() {
      return this.completed && this.document.initiatorAccountId === this.getAccount.id && this.currentSideStatus === 'SIGNED' && this.document.xmlDocumentType !== 'UpdateUserDataApplication';
    },
    isSimpleSignature() {
      return this.document.sides.find((side) => side.accountId === this.getAccount.id).isSimpleSignature;
    },
    onlyReading() {
      if (!this.document?.sides) return true;
      const document = this.actVerification ?? this.document;
      if (this.canBeNullified) {
        return false;
      }
      // TODO проверка
      return !document.sides.some((side) => side.accountId === this.getAccount.id);
    },
    isValidForSubmitting() {
      return !this.isRejected && (!this.completed || this.canBeNullified) && this.document.status !== 'NULLIFIED' && !this.onlyReading && this.kind !== 'documentUpdateInfo';
    },
    ...mapGetters('user', ['getToken', 'getAccount', 'getUser']),
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
  watch: {
    paymentVolumeOnePartElectricity() {
      this.accountService();
    },
    paymentVolumeTwoPartElectricity() {
      this.accountService();
    },
    totalTransit() {
      this.accountService();
      this.accountService();
    },
    accountingTransit() {
      this.accountService();
      this.accountUseful();
    },
    paymentVolume() {
      this.accountUseful();
    },
    totalUsefulVacation() {
      this.accountLoss();
    },
    totalLoss() {
      this.accountLossPercent();
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    statusGroupClick(val) {
      this.statusGroup = val;
    },
    async loadData() {
      this.isLoaded = false;

      try {
        this.document = await this.getDomainDocument(this.id);
        const contract = await this.loadBasicElectricityTransmissionContractById(this.document.id);
        if (contract) {
          this.contractKind = contract.kindByExisting;
          this.isAdditional = contract.sourceContractId;
          this.draftId = contract.draftId;
        }
        this.currentSideStatus = this.document.sides.find((it) => it.accountId === this.getAccount.id)?.status;
        this.completed = this.completedByStatus(this.currentSideStatus);
        this.history = this.document.documentHistory;
        console.log('Document', this.document);
        console.log('STATUS', this.currentSideStatus);
        if (this.document.actVerification?.id) {
          this.actVerification = await this.getDomainDocument(this.document.actVerification.id);
          this.documentId = this.actVerification.documentId;
          this.label = 'Акт верификации';
          this.powerOfAttorneyListBase = this.actVerification.powerOfAttorney;
        } else {
          this.documentId = this.document.documentId;
          this.label = 'Документ';
          this.powerOfAttorneyListBase = this.document.powerOfAttorney;
        }
        await this.downloadActXml();

        if (this.document.xmlDocumentType === 'ElectricityBalance') {
          await this.preProcessData();
        }
      } catch (error) {
        console.log('loadData', error);
      } finally {
        this.isLoaded = true;
      }
    },
    async downloadActXml() {
      if (!this.actVerification) {
        try {
          this.waitingXml = true;

          const pureData = await this.loadDocument(this.document.documentId);

          this.actXml = pureData.xml;
          this.actSignature = pureData.signature;
          this.waitingXml = false;
        } catch (error) {
          this.waitingXml = false;
        }
      } else {
        try {
          this.waitingXml = true;

          const pureData = await this.loadDocument(this.actVerification.documentId);

          this.actXml = pureData.xml;
          this.actSignature = pureData.signature;
          this.waitingXml = false;
        } catch (error) {
          this.waitingXml = false;
        }
      }
    },

    async preProcessData() {
      try {
        this.balance = await this.loadElectricityBalance({ id: this.id });
      } catch (error) {
        console.log('loadElectricityBalance', error);
      }
      this.accountingTransit = this.balance.accountingTransit === 'true';
      this.paymentVolumeOnePartElectricity = this.balance.paymentVolumeOnePartElectricity;
      this.paymentVolumeTwoPartElectricity = this.balance.paymentVolumeTwoPartElectricity;
      this.paymentVolumeTwoPartPower = this.balance.paymentVolumeTwoPartPower;
      this.totalTransit = this.balance.totalTransit;
      this.paymentVolume = this.balance.paymentVolume;
      this.totalUsefulVacation = this.balance.totalUsefulVacation;
      this.totalLoss = this.balance.totalLoss;
      this.totalLossPercent = this.balance.totalLossPercent;
    },
    accountService() {
      let result = parseFloat(this.paymentVolumeOnePartElectricity) + parseFloat(this.paymentVolumeTwoPartElectricity);
      if (this.accountingTransit) result += parseFloat(this.totalTransit);
      this.paymentVolume = result.toString();
    },
    accountUseful() {
      let result = parseFloat(this.paymentVolume) + parseFloat(this.balance.otherRetails) + parseFloat(this.balance.dkp);
      if (!this.accountingTransit) result += parseFloat(this.totalTransit);
      this.totalUsefulVacation = result.toString();
    },
    accountLoss() {
      this.totalLoss = (parseFloat(this.balance.totalInput) - parseFloat(this.totalUsefulVacation)).toString();
    },
    accountLossPercent() {
      // TODO divide input
      this.totalLossPercent = ((parseFloat(this.totalLoss) / parseFloat(this.balance.totalInput) * 100.0).toFixed(2) ?? 0).toString();
      if (this.totalLossPercent === '-Infinity' || this.totalLossPercent === 'Infinity') this.totalLossPercent = '0';
    },
    async reloadDocumentHandler() {
      switch (this.document.xmlDocumentType) {
        case 'Contract': {
          /* const action = this.contractKind === 'NEW' ? 'create' : 'upload';
          const type = 'contract';
          const contractType = 'ELECTRICITY_TRANSMISSION_CONTRACT';
          await this.setContractMode({ action, type, contractType });
          localStorage.removeItem('approve_document');
          await this.$router.push({
            name: 'cabinet.agreements.upload',
            params: { contractId: this.document.documentId },
          });*/

          try {
            this.isWaitingSubmit = true;

            if (!this.draftId) {
              this.setNotification({ message: 'Не удалось загрузить черновик' });
              return;
            }

            const draft = await this.loadDraftById({ id: this.draftId });

            if (!draft) {
              this.setNotification({ message: 'Не удалось загрузить черновик' });
              return;
            }

            const contractState = JSON.parse(draft.xml);

            contractState.draftId = this.draftId;
            contractState.currentStep = 0;

            await setDraft(contractState.accountId, contractState, `${contractState.accountId}contractNew`);

            await this.initFromDraft({ draft: contractState });

            localStorage.removeItem('approve_document');

            this.$router.push({
              name: 'cabinet.contracts-work-agreements.upload',
            });

          } catch (error) {
            console.log('error reloadDocumentHandler', error);
            this.setNotification({ message: 'Нет связи с сервером' });
          } finally {
            this.isWaitingSubmit = false;
          }

          break;
        }
        case 'EighteenthForms': {
          localStorage.removeItem('approve_document');
          await this.$router.push({
            name: 'cabinet.eighteen-shape.upload',
          });
          break;
        }
        case 'UsefulVacation': {
          localStorage.removeItem('approve_document');
          await this.$router.push({
            name: 'cabinet.useful-vacation-certificate',
          });
          break;
        }
        case 'Доверенность': {
          localStorage.removeItem('approve_document');
          await this.$router.push({
            name: 'cabinet.form-attorney',
            query: { id: this.document.documentId },
          });
          break;
        }
        case 'PowerOfAttorney': {
          localStorage.removeItem('approve_document');
          await this.$router.push({
            name: 'cabinet.form-attorney',
            query: { id: this.document.documentId },
          });
          break;
        }
      }
    },
    async reloadDocumentScansHandler() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      try {
        const xmlScan = generateXml.DocumentScan(
          this.documentsScan,
          this.document.documentHash,
          this.document.xmlDocumentType,
        );
        console.log(xmlScan);
        const result = await this.documentUpload({ xml: xmlScan });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.$emit('back');
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async deleteDocumentHandler() {
      try {
        this.isWaitingSubmit = true;
        this.submitError = '';
        const result = await this.documentCancel({ documentId: this.document.documentId });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.$emit('back');
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    handleRemoveDocumentsScan(file) {
      this.documentsScan = this.documentsScan
        .filter((scan) => scan.source !== file.source);
    },
    onDocumentSignedBySimple(signedDocument) {
      this.signedDocument = signedDocument;
      this.isVisibleSimpleSignatureModal = false;
      this.submitSignedDocument();
    },

    async submitSignedDocument() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      try {
        const result = await this.documentUpload({
          xml: this.signedDocument,
          isCap: false,
          sourceDocumentId: this.documentId,
        });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.$emit('back');
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isWaitingSubmit = false;
      }

    },

    async submit() {
      console.info(`Submitting documents: ${this.kind}`);

      if (this.statusGroup === 3) {
        localStorage.removeItem('approve_document');
        this.$router.push(`/cabinet/formation-protocol-disagreements/${this.document.documentId}`);
      }

      if (this.statusGroup === 4) {
        this.isWaitingSubmit = true;
        this.submitError = '';
        try {
          const reviewXml = generateXml.documentReviewXml(
            this.document.documentId,
            'document-nullify',
            this.comments,
            [],
            {},
          );
          const result = await this.documentUpload({ xml: reviewXml, isCap: true });
          if (result) {
            await this.setWorkflows({ userType: this.getUserType });
            this.$emit('back');
          }
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.isWaitingSubmit = false;
        }
        return;
      }

      if (this.kind === 'documentSigning' && this.statusGroup !== 2) {
        if (this.isSimpleSignature) {
          this.openSignatureModal();
          return;
        }
        this.isWaitingSubmit = true;
        this.submitError = '';
        try {
          const result = await this.documentUpload({
            xml: this.actXml,
            isCap: true,
            sourceDocumentId: this.documentId,
            existingSignature: this.actSignature,
          });
          if (result) {
            await this.setWorkflows({ userType: this.getUserType });
            this.$emit('back');
          }
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.isWaitingSubmit = false;
        }
        return;
      }

      if (this.kind === 'documentUpdateInfo') {
        this.isWaitingSubmit = true;
        this.submitError = '';
        let xmlToSubmit;
        if (this.document.xmlDocumentType === 'Contract') xmlToSubmit = addNumberAndDateToContract(this.actXml, this.documentNumber, formatDateToXmlDateFormat(this.documentDate));
        else xmlToSubmit = this.actXml;
        try {
          const result = await this.documentUpload({
            xml: xmlToSubmit,
            isCap: false,
            sourceDocumentId: this.documentId,
          });
          if (result) {
            await this.setWorkflows({ userType: this.getUserType });
            this.$emit('back');
          }
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.isWaitingSubmit = false;
        }
        return;
      }

      if (this.kind === 'documentAgreement' || this.statusGroup === 2) {
        let reviewType = '';
        let comments = '';
        const params = {};

        if (this.statusGroup === 1) {
          reviewType = 'document-approved';
        } else if (this.statusGroup === 2) {
          reviewType = 'document-rejected';
          comments = this.comments;
        } else if (this.statusGroup === 3) {
          reviewType = 'act-verification-disagreement';
          comments = this.comments;
        }

        try {
          this.isWaitingSubmit = true;

          let documentReviewXml;
          if (this.statusGroup === 3 && this.document.xmlDocumentType === 'ElectricityBalance') {
            console.log('D', this.totalLossPercent);
            documentReviewXml = generateXml.documentReviewXml(
              this.task.documentId,
              'electricity-balance-disagreement',
              this.comments,
              [],
              {
                PaymentVolume: this.paymentVolume,
                TotalLoss: this.totalLoss,
                TotalLossPercent: this.totalLossPercent,
                PaymentVolumeOnePartElectricity: this.paymentVolumeOnePartElectricity,
                PaymentVolumeTwoPartElectricity: this.paymentVolumeTwoPartElectricity,
                PaymentVolumeTwoPartPower: this.paymentVolumeTwoPartPower,
                TotalTransit: this.totalTransit,
                AccountingTransit: this.accountingTransit,
                TotalUsefulVacation: this.totalUsefulVacation,
              },
            );
          } else if (!this.actVerification) {
            documentReviewXml = generateXml.documentReviewXml(
              this.document.documentId,
              reviewType,
              comments,
              [],
              params,
            );
          } else {
            documentReviewXml = generateXml.documentReviewXml(
              this.actVerification.documentId,
              reviewType,
              comments,
              [],
              params,
            );
          }

          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            // TODO возможно, нужно ждать изменения состояния
            this.$emit('back');
          }, 2000);
        } catch (error) {
          this.errorHandler(error);
          this.isWaitingSubmit = false;
        }
      }
    },
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    errorHandler(error) {
      let splitError;
      if (error.name === 'CRYPTOPRO_CERTIFICATES_ERROR' || error.name === 'CRYPTOPRO_ERROR') {
        splitError = error.message;
      } else {
        splitError = error.message.split(': ')[2];
      }
      if (splitError === 'ADDITIONAL_BEFORE_SOURCE_SIGNED') {
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: 'Доп.соглашение не может проходить этапы согласования до тех пор, пока исходный договор не будет подписан всеми сторонами',
        });
      } else {
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${splitError}`,
        });
      }

    },

    // Доверенности
    async powerAttorney(type, name, url, id) {
      this.powerAttorneyIdx = id;
      this.waitingPowerAttorney = true;
      const token = this.getToken;
      axios.get(url, { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log('download error', error.message);
          alert('Ошибка загрузки');
        })
        .finally(() => {
          this.waitingPowerAttorney = false;
          this.powerAttorneyIdx = null;
        });
    },
    async openPowerAttorneyView(url, id) {
      const index = id;
      this.isDisplayPowerAttorney = !this.isDisplayPowerAttorney;
      if (this.powerAttorneyPdfUrlView) {
        if (this.powerAttorneyIdx !== index) {
          this.powerAttorneyPdfUrlView = undefined;
          this.isDisplayPowerAttorney = true;
        } else {
          this.powerAttorneyPdfUrlView = undefined;
          this.isDisplayPowerAttorney = false;
          return;
        }
      }
      this.powerAttorneyIdx = index;

      try {
        const pdfDoc = await this.getDocumentByUrl(url);

        this.powerAttorneyPdfUrlView = URL.createObjectURL(pdfDoc);
      } catch (error) {
        console.log('error documents docPdf', error);
        this.isDisplayPowerAttorney = false;
        this.powerAttorneyIdx = null;
        alert('Ошибка загрузки');
      }
    },

    completedByStatus(data) {
      if ((this.currentSideStatus === 'APPROVED' || this.currentSideStatus === 'WAITING_UPDATE_INFO') && this.kind === 'documentAgreement')
        return true;
      return completedByStatus(data) || this.completedByWorkflow;
    },
    convertDbDateTimeFormat(date) {
      return convertDbDateTimeFormat(date);
    },
    ...mapActions('document', ['loadDocument', 'getDomainDocument', 'documentUpload', 'documentCancel', 'loadBasicElectricityTransmissionContractById']),
    ...mapActions('cabinet', ['loadElectricityBalance', 'setWorkflows']),
    ...mapActions('electricityTransmissionContract', ['initFromDraft', 'setContractMode']),
    ...mapActions('document', ['loadDraftById']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  height: 90%

.viewPdf
  width: 100%
  height: 100%
  margin-top: 16px

  iframe
    min-height: 450px
    height: 100%
    width: 100%

    html
      overflow-y: hidden

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .title
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

  .stepDate
    +table-text-small
    margin-bottom: 34px

    span
      +table-data-small
      margin-left: 116px

  .description, .descriptionTitle
    +card-title-small
    margin-bottom: 32px

    span
      +table-text-small

    span.action, a.action
      +table-text-small
      text-decoration: underline
      margin-right: 8px
      cursor: pointer
      color: #0E0F0F !important

  .descriptionTitle
    margin-top: 32px

.contractType
  margin-top: 32px
  margin-bottom: 39px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px
  cursor: pointer

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center


.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

    & .buttonRadioGroupError
      background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.statusGroup
  width: 100%
  max-width: 560px
  margin: 0 auto
  display: flex

.statusGroup
  margin-top: 40px

.correctionTitle
  padding-bottom: 12px
  +base-title

.mediumSpacing
  +medium-spacing

.blockContent
  &:last-child
    margin-bottom: 40px

  &:not(:last-child)
    +base-spacing

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.correctionGroup, .radioGroup
  margin: 20px auto 40px

.closeAction
  color: red

div.description
  display: inline-block
  margin-bottom: 0 !important

.waiting
  a, span
    cursor: default !important

.waitingPdf
  width: 70px
  margin: 32px auto 0

.submit
  margin-top: 32px

.errorText
  +small-text
  margin: 32px 4px -28px 4px
  color: red

.rejected
  background-color: red !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none

.timeGroup
  display: grid
  grid-template-columns: 1fr 1fr
  margin-bottom: 34px

  p
    +table-text-small

    &:last-child
      +table-data-small
</style>
