<template>
  <div
    class="edit-mode-button"
  >
    <button
      :class="{
        ['edit-mode-button__button']: true,
        ['edit-mode-button__button--select-all']: selectAll,
        ['edit-mode-button__button--select-all-border-gray']: selectAllBorderGray,
        ['edit-mode-button__button--select']: select,
        ['edit-mode-button__active']: isTrue || isChecked,
        ['edit-mode-button__active--select']: (isTrue || isChecked) && select,
        ['edit-mode-button__non-active']: !isChecked && !isTrue,
        ['edit-mode-button__non-active--select-all']: (!isChecked && !isTrue) && selectAll,
      }"
      :style="customStyle"
      :disabled="isDisabled"
      @click.prevent="click"
    >
      <span
        class="edit-mode-button__button-icon"
      >
        <img
          v-if="(isChecked || isTrue) && select"
          alt=""
          class="edit-mode-button__button-icon-img"
          src="@/assets/icons/edit-button/on-select.svg"
        />
        <img
          v-if="(isChecked || isTrue) && !select && !whiteIcon"
          alt=""
          class="edit-mode-button__button-icon-img"
          src="@/assets/icons/edit-button/on.svg"
        />
        <img
            v-if="(isChecked || isTrue) && !select && whiteIcon"
          alt=""
          class="edit-mode-button__button-icon-img"
          src="@/assets/icons/edit-button/on-select-white.svg"
        />
        <img
          v-if="!(isChecked || isTrue)"
          alt=""
          class="edit-mode-button__button-icon-img"
          src="@/assets/icons/edit-button/off.svg"
        />
        <span class="edit-mode-button__button-text">
          {{ title }}
        </span>
      </span>
      <slot />
    </button>
  </div>
</template>

<script>

export default {
  name: 'EditModeButton',
  props: {
    isTrue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Режим редактирования',
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    selectAllBorderGray: {
      type: Boolean,
      default: false,
    },
    whiteIcon: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  computed: {
    customStyle() {
      if (!this.background) {
        return '';
      }

      return `background-color: ${this.background}`;
    },
  },
  watch: {
    isTrue() {
      this.isChecked = this.isTrue;
    },
  },
  methods: {
    click() {
      if (this.isTrue && !this.isChecked) {
        this.isChecked = !this.isTrue;
        this.$emit('click', this.isChecked);
        return;
      }
      this.isChecked = !this.isChecked;
      this.$emit('click', this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-mode-button {
  cursor: pointer;

  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &__button {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 12px 16px;

    display: flex;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;

      &-img {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }
    }

    &--select-all {
      border-radius: 24px;
      border: 1px solid #2F82DF;
      padding: 5px 16px;

      &:hover {
        background: #b8c9e5;
      }
    }

    &--select-all-border-gray {
      border-radius: 24px;
      border: 1px solid #C1C4C7;
      padding: 5px 16px;

      &:hover {
        background: #b8c9e5;
      }
    }

    &--select {
      color: #71757A;
      padding: 13px 20px;
      border-radius: 16px;
      background-color: #F5F6F6;
    }
  }

  &__active {
    background: #2F82DF;
    color: #FFFFFF;

    &--select {
      background: #F5F6F6;
      color: #4C4E51;
    }
  }

  &__non-active {
    background: #F5F6F6;
    color: #4C4E51;

    &--select-all {
      background: #FFFFFF;
    }
  }
}
</style>
