const getIsDisplayDetails = (state) => state.isDisplayDetails;
const getComponentDetails = (state) => state.componentDetails;
const getTaskDetails = (state) => state.taskDetails;
const getPopUpDetails = (state) => state.popUpDetails;
const getPopUpDetailsProps = (state) => state.popUpDetailsProps;
const getIsDisplayPopUpDetails = (state) => state.isDisplayPopUpDetails;
const getLoading = (state) => state.loading;

export default {
  getIsDisplayDetails,
  getComponentDetails,
  getTaskDetails,
  getPopUpDetails,
  getPopUpDetailsProps,
  getIsDisplayPopUpDetails,
  getLoading,
};
