<template>
  <div>
    <IconDelete />
  </div>
</template>

<script>
import IconDelete from 'atoms/icons/action/Delete';

export default {
  components: {
    // TODO: remove this component,
    IconDelete,
  },
};
</script>

<style lang="scss">
</style>
