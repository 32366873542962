<template>
  <div class="signatories-step">
    <v-divider style="margin: 15px 0 20px 0"/>
    <div
      class="signatories-step__content"
      role="SignatoriesStep"
    >
      <h1 class="signatories-step__title">Подписание
        {{ isNewContract ? selectedContractName : `акта верификации ${selectedContractName}` }} </h1>
      <h2 class="signatories-step__sub-title">{{ titleSignatories }}</h2>

      <v-divider style="margin: 15px 0 20px 0"/>

      <div>
        <div v-if="!isNewContract">
          <div
            v-for="(side, index) in onPlatformSides"
            :key="index"
            class="signatories-step__side"
          >
            <div v-if="editableSides[side].company.correlationId?.length>0">
            <span class="signatories-step__side-title__signatory-text">
            Подписывающая сторона:
            </span>
              <span class="signatories-step__side-title__side-name">
            {{ sidesInfo[side] }}
            </span>
              <emploee-select-or-input
                :init-employee="setEmployee(side)"
                :inn="editableSides[side].company.inn"
                :not-emit-input-reason="true"
                mode="select"
                permission="SVET_A1-S"
                @emploeeSelectOrInput="employeeSelectOrInputHandler($event, side)"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(side, index) in onPlatformSides"
            :key="index"
            class="signatories-step__side"
          >
            <div>
            <span class="signatories-step__side-title__signatory-text">
            Подписывающая сторона:
            </span>
              <span class="signatories-step__side-title__side-name">
            {{ sidesInfo[side] }}
            </span>
              <emploee-select-or-input
                :editable="false"
                :init-employee="setEmployee(side)"
                :inn="editableSides[side].company.inn"
                :not-emit-input-reason="true"
                permission="SVET_A1-S"
                @emploeeSelectOrInput="employeeSelectOrInputHandler($event, side)"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 15px 0 20px 0">
        <v-divider/>
        <horizontal-progress-bar v-if="isWaitingSubmit"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { EmploeeSelectOrInput, VDivider } from 'components';
import { checkField } from 'utils/common';
import HTTPError from 'lib/utils/errors';
import { generateElectricityTransmissionContractWithWorker } from 'utils/xml';
import generateXml from 'lib/storage/connection-application/data';
import { XmlMd5 } from 'lib/utils/md5';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { removeDraft } from '@/services/draft';

export default {
  components: {
    HorizontalProgressBar,
    EmploeeSelectOrInput,
    VDivider,
  },
  props: {
    submit: {
      type: Boolean,
      required: true,
    },
  },
  async created() {
    for (const edSideKey in this.editableSides) {
      if (this.editableSides[edSideKey]?.company?.inn) {
        const supplier = await this.getSuppliersByFilter({ inn: this.editableSides[edSideKey].company.inn });
        this.suppliers.push(...supplier);
      }
    }
  },
  data() {
    return {
      // Порядок элементов в массиве соответствует
      // порядку отображения сторон на странице
      suppliers: [],
      sidesInfo: {
        SUPPLIER: 'Заказчик *',
        PERFORMER_FIRST: 'Исполнитель-1 *',
        PERFORMER_SECOND: 'Исполнитель-2 *',
        PERFORMER: 'Исполнитель *',
      },
      xml: null,
      xmlScan: null,
      submitError: '',
      isWaitingSubmit: false,
    };
  },
  computed: {
    onPlatformSides() {
      const suppliersOnPlatform = this.suppliers.filter((supplier) => supplier.onPlatform === true);
      return Object.keys(this.editableSides).filter((sideKey) => suppliersOnPlatform.some((supplier) => supplier.inn === this.editableSides[sideKey].company?.inn));
    },
    contractMembersIds() {
      return this.getContract.userEmployeesContractMembers.map((it) => it.user.id);
    },
    isNewContract() {
      return this.getContract?.action === 'create';
    },
    contractType() {
      if (this.getContract.type === 'contract') return 'договору';
      return 'доп.соглашению';
    },
    titleSignatories() {
      return this.isNewContract ? 'Подписывающие лица' : 'Выберите лица, подписывающие акт верификации';
    },
    selectedContractName() {
      return checkField(this.getContract?.selectedTemplate?.name);
    },
    sides() {
      return this.getContract.selectedTemplate.sides;
    },
    editableSides() {
      return this.getContract.editableSides;
    },
    sourceContractId() {
      return this.getContract.sourceContractId;
    },
    contract() {
      return this.getContract;
    },
    ...mapGetters('user', ['getAccount', 'getUser']),
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getEditableSides', 'getRejectedContracts']),
    ...mapGetters('dataLists', ['typeAccountingList', 'ownerAccountingFacilitiesList', 'voltageClassList', 'measurementsReadingKindList', 'reliabilityClassListWithShortenedNames', 'relatedOrganizationList']),
  },
  watch: {
    isWaitingSubmit(val) {
      this.$emit('isWaitingSubmit', val);
    },
    submit() {
      this.sendContract();
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    ...mapActions('electricityTransmissionContract', ['setActVerificationSignatoriesSide', 'resetState', 'generateAdditionsForNewUsagePointIdsAsXmlText', 'setAttachments']),
    ...mapActions('document', ['submitManyDocuments', 'getDomainDocumentByDocumentId', 'deleteDraft', 'documentCancel']),
    ...mapActions('cabinet', ['getSuppliersByFilter', 'setWorkflows']),
    setEmployee(contractSide) {
      const side = this.editableSides[contractSide];
      const source = this.isNewContract ? side.signatory : side.signatoryVerificationAct;
      return {
        id: source.id,
        fullName: {
          surname: source.fullName.surname,
          name: source.fullName.name,
          patronymic: source.fullName.patronymic,
        },
        reason: {
          id: source.reason.id,
          reasonDocument: source.reason.reasonDocument,
          number: source.reason.number,
          date: source.reason.date,
        },
        role: {
          id: source.role.id,
          name: source.role.name,
        },
        userId: source.userId,
        accountId: source.accountId,
      };
    },
    employeeSelectOrInputHandler(signatoryEmployee, sideName) {
      if (JSON.stringify(this.getEditableSides[sideName].signatoryVerificationAct) !== JSON.stringify(signatoryEmployee)) {
        this.setActVerificationSignatoriesSide({ signatoryEmployee, sideName });
      }
    },
    async formXml() {
      try {
        this.xml = await generateElectricityTransmissionContractWithWorker(
          this.contract,
          this.typeAccountingList,
          this.ownerAccountingFacilitiesList,
          this.voltageClassList,
          this.measurementsReadingKindList,
          this.reliabilityClassListWithShortenedNames,
          this.relatedOrganizationList,
          null,
          this.contract.finallyContractXml,
        );
        this.isWaitingGenerateXml = false;
      } catch (e) {
        console.log('Error generate ElectricityTransmissionContract', e);
        this.isWaitingGenerateXml = false;
        return;
      }

      this.xmlScan = generateXml.DocumentScan(
        this.contract.scanContract,
        XmlMd5(this.xml),
        'Contract',
        this.getScanSides(),
      );
    },
    getScanSides() {
      const sides = [];
      this.contract.selectedTemplate.sides.forEach((contractSide) => {
        const side = this.editableSides[contractSide.contractSide];
        if (side.company.correlationId?.length > 0 && !!this.onPlatformSides.includes(contractSide.contractSide)) {
          sides.push({
            userId: side.signatoryVerificationAct.userId,
            role: {
              id: side.signatoryVerificationAct.role.id,
            },
            accountId: side.signatoryVerificationAct.accountId,
            powerOfAttorneyId: side.signatoryVerificationAct.reason.id,
          });
        }
      });
      return sides;
    },
    async sendContract() {
      if (this.contract.additionalAgreementType === 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS') {
        const errorGenerate = await this.generateAdditions();

        if (errorGenerate.length > 0) {
          return;
        }
      }

      await this.formXml();

      this.isWaitingSubmit = true;
      try {
        const result = await this.submitManyDocuments({ xmls: [this.xml, this.xmlScan] });

        if (!result) {
          this.isWaitingSubmit = false;
          return;
        }
        const contractDocument = result.find((it) => it.hash === `${XmlMd5(this.xml)}`);
        const contractDomainDocument = await this.getDomainDocumentByDocumentId(contractDocument.id);
        let currentEditableSide;
        for (const editableSidesKey in this.editableSides) {
          if (this.editableSides[editableSidesKey].signatoryVerificationAct.accountId === this.getAccount.id) {
            currentEditableSide = this.editableSides[editableSidesKey];
          }
        }
        const approvalSheetResult = await this.submitManyDocuments({ xmls: [generateXml.ApprovalSheet(contractDomainDocument.actVerification?.documentId ?? contractDomainDocument.documentId, this.getAccount.id, contractDomainDocument.actVerification?.documentId ?? 'ActVerification' ?? 'Contract', this.contractMembersIds, currentEditableSide.signatoryVerificationAct.userId, contractDomainDocument.actVerification?.documentId ? null : this.contractMembersIds[this.contractMembersIds.length - 1])] });
        if (!approvalSheetResult) {
          this.isWaitingSubmit = false;
          return;
        }
        if (this.$route.params?.contractId) {
          try {
            await this.documentCancel({ documentId: this.$route.params.contractId });
            await this.setWorkflows({ userType: this.getUserType });
          } catch (error) {
            console.log('error while deleting fixing contract task', error);
          }
        }
        if (this.getRejectedContracts.length) {
          const rejectedContract = this.getRejectedContracts.find((item) => item.draftId === this.getContract.draftId);

          if (rejectedContract.draftId === this.getContract.draftId) {
            try {
              await this.documentCancel({ documentId: rejectedContract.contractId });
              await this.setWorkflows({ userType: this.getUserType });
            } catch (error) {
              console.log('error while deleting fixing contract task', error);
            }
          }
        }
        setTimeout(() => {
          // this.deleteDraft({ id: this.getContract.draftId });
          removeDraft(`${this.getContract.accountId}contractNew`);
          this.resetState();

          this.$router.push({
            name: this.$route.name.replace('.upload', ''),
          });
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.isWaitingSubmit = false;
        const errorMessage = error.message.split(' : ')[1];
        const regexp = new RegExp('.*?:.*?: (.*)');

        if (errorMessage === 'You can\'t bind your additional with rejected source contract') {
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: 'Исходный документ был отклонён. Вы не можете загрузить дополнительное соглашение к отклонённому исходному договору',
          });
        } else if (errorMessage === 'Your inn not included in contract sides') {
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: 'Ошибка при заполнении сторон. ИНН компании от которой создается договор не присутствует не в одной из сторон',
          });
        } else if (errorMessage.startsWith('Питающие центры или генераторы отсутствуют')) {
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: `Ошибка при заполнении столбца №ПЦ. ${error.message.match(regexp)[1]} `,
          });
        } else if (errorMessage === 'Дата очередной поверки не может быть меньше, чем текущая дата') {
          this.submitError = errorMessage;
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: `${errorMessage}`,
          });
        } else {
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: `${errorData.message}`,
          });
          this.submitError = errorData.message;
        }
      }
    },
    async generateAdditions() {
      this.isWaitingSubmit = true;

      try {
        const selectedUsagePointsIds = this.contract.usagePoints.filter((point) => point.selected).map((point) => point.id);
        const xml = await this.generateAdditionsForNewUsagePointIdsAsXmlText({ usagePointIds: selectedUsagePointsIds });

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');
        const additions = xmlDoc.getElementsByTagNameNS('*', 'Additions')[0];

        const additionList = additions.getElementsByTagNameNS('*', 'Addition');

        let additionsStr = '';
        for (const item of additionList) {
          additionsStr += new XMLSerializer().serializeToString(item);
        }

        await this.setAttachments({
          attachment: {
            name: 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS',
            value: additionsStr,
            number: 1,
          },
        });

        return '';
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        return errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signatories-step {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #4C4E51;
  }

  &__error {
    font-family: 'Roboto Condensed', sans-serif;
    color: red;
  }

  &__sub-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4C4E51;
    margin-top: 13px;
    margin-bottom: 24px;
  }

  &__content {
    width: 560px;
    margin: 0 auto 0;
  }

  &__side {
    margin-top: 30px;

    &-title {
      &__signatory-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }

      &__side-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>
