import { apolloClient } from '@/api/graphql/index.js';
import { PAYMENT_ORDERS } from '@/api/graphql/queries.js';
import { GENERATE_PAYMENT_ORDER_FOR_SERVICE_OR_LOSS_ACT } from '@/api/graphql/mutations.js';

async function getPaymentOrders({ rootState, commit }) {
  try {
    const { data: { paymentOrders } } = await apolloClient.query({
      query: PAYMENT_ORDERS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('paymentOrders', paymentOrders);
    return paymentOrders;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error.message);
    throw error;
  }
}


const setActDocumentDomainId = ({ commit }, { actDocumentDomainId }) => {
  commit('SET_ACT_DOCUMENT_DOMAIN_ID', { actDocumentDomainId });
};

const setTotalCost = ({ commit }, { totalCost }) => {
  commit('SET_TOTAL_COST', { totalCost });
};

const setPerformerFirstBankRequisites = ({ commit }, { performerFirstBankRequisites }) => {
  commit('SET_PERFORMER_FIRST_BANK_REQUISITES', { performerFirstBankRequisites });
};

const setPerformerSecondBankRequisites = ({ commit }, { performerSecondBankRequisites }) => {
  commit('SET_PERFORMER_SECOND_BANK_REQUISITES', { performerSecondBankRequisites });
};

const setPaymentKind = ({ commit }, { paymentKind }) => {
  commit('SET_PAYMENT_KIND', { paymentKind });
};

async function generatePaymentOrderForServiceOrLossAct({ rootState, getters }) {
  try {

    const variables = {
      actDocumentDomainId: getters.getPaymentOrders.actDocumentDomainId,
      performerFirstBankRequisites: getters.getPaymentOrders.performerFirstBankRequisites,
      performerSecondBankRequisites: getters.getPaymentOrders.performerSecondBankRequisites,
      totalCost: getters.getPaymentOrders.totalCost,
      paymentType: getters.getPaymentOrders.paymentKind,
    };

    const { data } = await apolloClient.mutate({
      mutation: GENERATE_PAYMENT_ORDER_FOR_SERVICE_OR_LOSS_ACT,
      variables: variables,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log('generate payment order', data);
  } catch (error) {
    console.log('error generate payment order::catch', error);
    throw error;
  }
}

export default {
  getPaymentOrders,
  setActDocumentDomainId,
  setTotalCost,
  setPerformerFirstBankRequisites,
  setPaymentKind,
  setPerformerSecondBankRequisites,
  generatePaymentOrderForServiceOrLossAct,
};
