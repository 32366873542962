<template>
  <page title="Справочник единых (котловых) тарифов на услуги по передаче электрической энергии по сетям города Москвы">
    <v-table-new
      v-model="transformedBoilerTariffs"
      :template="template"
      excel-report-file-name="Справочник котловых тарифов"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';

export default {
  name: 'DirectoryBoilerTariffs',
  components: {
    VTableNew,
    Page,
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование смежной сетевой организации',
            key: 'utility.name',
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'ИНН',
            key: 'utility.inn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Дата начала действия тарифа',
            key: 'startDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Дата конца действия тарифа',
            key: 'endDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Ставка на содержание электрических сетей, (руб / (МВт * мес))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForTheMaintenanceOfElectricalNetworks_NoZero',
          },
          {
            label: 'Ставка на оплату технологического расхода, (руб / (МВт * ч))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForPaymentOfTechnologicalLosses_NoZero',
          },
          {
            label: 'Одноставочный тариф, (руб / (кВт * ч))',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'singleRateElectricityTariff_NoZero',
          },
          {
            label: 'Реквизиты приказа. Год',
            key: 'tariffsOrder.year',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Реквизиты приказа. Дата составления документа',
            key: 'tariffsOrder.docDate',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Номер документа',
            key: 'tariffsOrder.orderNumber',
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Название приказа',
            key: 'tariffsOrder.orderName',
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Реквизиты приказа. Кто издал приказ',
            key: 'tariffsOrder.orderPublisher',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    transformedBoilerTariffs() {
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
