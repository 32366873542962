const state = () => ({
  uploadDocument: {},
});

const getters = {
  uploadDocumentType: (state) => state.uploadDocument.type,
};

const actions = {};

const mutations = {
  setUploadDocumentType: (state, type) => {
    state.uploadDocument = { ...state.uploadDocument, type };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
