<template>
  <div :class="$style.container">
    <div :class="$style.blockContent">
      <v-select
          v-model="whoPerforms"
          :options="whoPerformsOptions"
          rules="required"
          title="Кто исполняет *"
          new-style
          :disabled="isWaitingSubmit"
      />
    </div>

    <div :class="$style.actionContent">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>

      <submit-task-details :disabled="isWaitingSubmit || !isValid" title="СОХРАНИТЬ"
                           @submit="onSubmit"/>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import VSelect from 'components/VSelect/VSelect';

export default {
  name: 'ExecutionAgreement',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    VSelect,
  },
  props: {
    documentId: String,
  },
  data() {
    return {
      submitError: '',
      isWaitingSubmit: false,
      whoPerforms: undefined,
      whoPerformsOptions: [
        {
          value: 'ECONOMIC_METHOD',
          label: 'Хоз. способ',
        },
        {
          value: 'CONTRACTOR',
          label: 'Подрядчик',
        },
      ],
    };
  },
  computed: {
    isValid() {
      return this.whoPerforms;
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async onSubmit() {
      try {
        this.submitError = '';
        this.isWaitingSubmit = true;
        const documentReviewXml = connectionApplicationData.documentReviewXml(
            this.documentId,
            'connection-application-contract-execution-completed',
            '',
            [],
            {
              performer: this.whoPerforms,
            },
        );

        await this.documentUpload({ xml: documentReviewXml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto 40px

.actionContent
  width: 560px
  margin: 0 auto
</style>
