<template>
  <div class="connection-name">
    <h2>Наименование присоединения, границы балансовой принадлежности и участка сети</h2>

    <v-select v-if="internalValue.balanceBoundary"
              v-model="internalValue.balanceBoundary.name"
              :disabled="!isEditing"
              :options="[
        {
          value: internalValue.balanceBoundary.name,
          label: internalValue.balanceBoundary.name,
        }
      ]"
              title="Участок сети"
    />

    <div v-if="internalValue.pointInfo.borderName" class="connection-name__border-name">
      <v-input
        v-model="internalValue.pointInfo.borderName"
        :disabled="!isEditing"
        class="connection-name__border-name__name"
        clearable
        rules="required"
        title="Наименование границы балансовой принадлежности *"
      />

      <div class="connection-name__does-not-pass">
        <div class="connection-name__does-not-pass-label">
          <input type="checkbox"/>
          &nbsp;&nbsp;Граница балансовой принадлежности проходит не по прибору учёта
        </div>
      </div>
    </div>

    <v-input
      v-model="internalValue.externalId"
      :disabled="true"
      clearable
      title="ИКТС"
    />

    <v-input
      v-model="getAdditionKind"
      :disabled="!isEditing"
      clearable
      rules="required"
      title="Тип точки *"
    />

    <v-divider/>

    <h3>Наименование присоединения</h3>

    <div class="connection-name__point-info">
      <v-input
        v-model="internalValue.pointInfo.mainSubstationNumber"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ ПЦ"
      />
      <v-input
        v-model="internalValue.pointInfo.feeder"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ Номер фидера"
      />
    </div>

    <div class="connection-name__point-info">
      <v-input
        v-model="internalValue.pointInfo.distributionSubstation"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ РП"
      />
      <v-input
        v-model="internalValue.pointInfo.line"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ линии"
      />
    </div>

    <div class="connection-name__point-info">
      <v-input
        v-model="internalValue.pointInfo.transformationSubstation"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ ТП"
      />
      <v-input
        v-model="internalValue.pointInfo.other"
        :disabled="!isEditing"
        class="connection-name__border-name-value"
        clearable
        title="№ отходящей линии/ шины"
      />
    </div>

    <div class="connection-name__point-info">
      <v-input
        v-model="internalValue.pointInfo.switchGear"
        :disabled="!isEditing"
        class="connection-name__border-name-value connection-name__border-name-value--one"
        clearable
        title="№ РУ/ВРУ"
      />
    </div>

    <v-divider/>

  </div>
</template>

<script>
import { VDivider, VInput, VSelect } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'ConnectionName',
  components: {
    VInput,
    VSelect,
    VDivider,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        boundariesBalanceSheet: '',
        borderName: '',
        pointInfo: {
          mainSubstationNumber: '',
          feeder: '',
          distributionSubstation: '',
          line: '',
          transformationSubstation: '',
          outgoingLine: '',
          switchGear: '',
        },
      },
      internalValue: {},
    };
  },
  computed: {
    getAdditionKind() {
      switch (this.internalValue.additionKind) {
        case 'RECEIVING_POINT':
          return 'Приём';
        case 'TRANSFER_POINT':
          return 'Передача';
        case 'DELIVERY_POINT':
          return 'Поставка';
        case 'TECHNICAL_POINT':
          return 'Техническая';
        default:
          return '';
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(previous, current) {
        if (JSON.stringify(previous) === JSON.stringify(current)) return;
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },

};

</script>

<style lang="scss" scoped>
.connection-name {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2, h3 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
  }

  h2 {
    margin-bottom: 24px;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
  }

  &__border-name {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    &__name {
      width: 100%;
    }

    img {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__border-name-value {
    width: 786px;
    gap: 24px;


    &--one {
      width: 49%;
    }
  }

  &__point-info {
    display: flex;
    gap: 16px
  }

  &__does-not-pass {
    position: absolute;
    top: 26px;
    right: -442px;

    @media (max-width: 1400px) {
      position: relative;
      top: 0;
      right: 0;
      width: 848px;
    }

  }

  &__does-not-pass-label {
    display: flex;
    align-items: center;
    width: 397px;
    background: #F5F6F6;
    border-radius: 16px;
    padding: 14px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #4C4E51;

  }
}
</style>
