export const platform = {
  groups: [
    {
      title: 'Задачи',
      route: 'tasks',
      iconComponent: 'Tasks',
    },
    // TODO раздел в разработке
    // {
    //   title: 'Восстановить доступ',
    //   route: 'restore-access',
    //   iconComponent: 'Tasks',
    // },
    {
      title: 'Аналитика и отчетность',
      route: 'reports',
      iconComponent: 'Reports',
    },
    {
      title: 'Нормативно-справочная информация',
      iconComponent: 'Contacts',
      items: [
        {
          title: 'Список НСИ',
          route: 'guide-list',
          iconComponent: 'Contacts',
        },
        {
          title: 'Отозванные сертификаты',
          route: 'guide-revoked-certificates',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник моделей трансформаторов тока',
          route: 'guide-tt',
          iconComponent: 'DirectoryTT',
        },
        {
          title: 'Справочник моделей трансформаторов напряжения',
          route: 'guide-tn',
          iconComponent: 'DirectoryTT',
        },
        {
          title: 'Справочник моделей приборов учёта',
          route: 'guide-pu',
          iconComponent: 'Contacts',
        },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник моделей трансформаторов тока и напряжения',
        //   route: 'guide-tt',
        //   iconComponent: 'DirectoryTT',
        // },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник УСПД',
        //   route: 'guide-uspd',
        //   iconComponent: 'DirectoryTT',
        // },
        {
          title: 'Справочник сетевых компаний',
          route: 'utilities',
          iconComponent: 'ContactPage1',
        },
        {
          title: 'Справочник сбытовых компаний',
          route: 'retailers',
          iconComponent: 'ContactPage2',
        },
        {
          title: 'Справочник контрагентов',
          route: 'contractors',
          iconComponent: 'ContactPage2',
        },
        {
          title: 'Справочник полномочий',
          route: 'permissions-registry',
          iconComponent: 'PermissionsRegistry',
        },
        {
          title: 'Реестр ИСУ',
          route: 'guide-isu',
          iconComponent: 'DirectoryTT',
        },
        {
          title: 'Шаблоны документов',
          route: 'document-templates',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник подстанций',
          route: 'substations',
          iconComponent: 'Contacts',
        },
        {
          title: 'Список запрещенных паролей',
          route: 'prohibited-passwords',
          iconComponent: 'Documents',
        },
        {
          title: 'Зоны присутствия',
          route: 'presence-zones',
          iconComponent: 'WarehouseAccountingSystem',
        },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник цен для потребителей',
        //   route: 'guide-consumer-price',
        //   iconComponent: 'IndividualTariffs',
        // },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник потерь ФСК',
        //   route: 'guide-losses-fsk',
        //   iconComponent: 'IndividualTariffs',
        // },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник инфраструктурных платежей',
        //   route: 'guide-infrastructure-payments',
        //   iconComponent: 'IndividualTariffs',
        // },
        // TODO раздел в разработке
        // {
        //   title: 'Справочник подстанций (в том числе генераторы)',
        //   route: 'guide-substations',
        //   iconComponent: 'IndividualTariffs',
        // },
        {
          title: 'Справочник административных округов',
          route: 'administrative-districts',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник РЭС',
          route: 'oek-district-departments',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник категорий потребителей',
          route: 'consumers-category',
          iconComponent: 'IndividualTariffs',
        },
      ],
    },
    {
      title: 'Тарифы',
      iconComponent: 'IndividualTariffs',
      items: [
        {
          title: 'Реестр приказов на тарифы',
          route: 'tariff-order',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Справочник индивидуальных тарифов на услуги по передаче электрической энергии для взаиморасчётов между сетевыми организациями',
          route: 'individual-tariffs',
          iconComponent: 'IndividualTariffs',
        },

        {
          title: 'Ставка тарифа на услуги по передаче электрической энергии на содержание объектов электросетевого хозяйства',
          route: 'costs-standard-losses',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Ставка тарифа на услуги по передаче электрической энергии, используемая для целей определения расходов на оплату нормативных потерь электрической энергии',
          route: 'expenses-payment-standard-losses',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Экономически обоснованные единые (котловые) тарифы на услуги по передаче электрической энергии по сетям города Москвы',
          route: 'unified-energy-tariffs',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Плановые часы пиковой нагрузки',
          route: 'scheduled-peak-hours',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Нормативы потерь электрической энергии при ее передаче по единой национальной (общероссийской) электрической сети, осуществляемой публичным акционерным обществом "Федеральная сетевая компания - Россети"',
          route: 'tariff-loss-normatives',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Нерегулируемые цены на электрическую энергию, поставляемую АО "Мосэнергосбыт"',
          route: 'utility-loss-tariff',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Инфраструктурные платежи',
          route: 'tariff-infrastructure-payments',
          iconComponent: 'IndividualTariffs',
        },
        {
          title: 'Единые (котловые) тарифы на услуги по передаче электрической энергии',
          route: 'electricity-boiler-tariffs',
          iconComponent: 'IndividualTariffs',
        },
      ],
    },
    {
      title: 'Обращения пользователя',
      route: 'user-requests',
      iconComponent: 'HomeWork',
    },
    {
      title: 'Управление организацией',
      iconComponent: 'Partner',
      items: [
        {
          title: 'Сотрудники компании',
          route: 'employees',
          iconComponent: 'Partner',
        },
        {
          title: 'Доверенности',
          route: 'attorney',
          iconComponent: 'Attorney',
        },
        {
          title: 'Иерархия компании',
          route: 'company-hierarchy',
          iconComponent: 'Structure',
        },
      ],
    },
    {
      title: 'Управление пользователями',
      iconComponent: 'Partner',
      items: [
        {
          title: 'Реестр юридических лиц',
          route: 'register-legal-entities',
          iconComponent: 'Partner',
        },
        {
          title: 'Реестр физических лиц',
          route: 'register-individuals',
          iconComponent: 'UserDetails',
        },
        {
          title: 'Иерархия компании',
          route: 'support-company-hierarchy',
          iconComponent: 'Structure',
        },
        {
          title: 'Сотрудники компании',
          route: 'company-employees',
          iconComponent: 'Partner',
        },
      ],
    },
    {
      title: 'Профиль',
      route: 'user-details',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Доступные профили',
      route: 'available-profiles',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Настройки',
      iconComponent: 'MiscellaneousServices',
      items: [
        {
          title: 'Установка универсального транспортного модуля',
          route: 'cabinet-utm-installation',
          iconComponent: 'UtmInstallation',
        },
      ],
    },
  ],
};
