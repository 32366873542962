var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":"modal","order":2}},[(_vm.visible)?_c('vue-draggable-resizable',{key:_vm.key,class:{
      [_vm.$style['modal-height']]: !_vm.height100 && _vm.height !== 50,
      [_vm.$style['modal-height--minimize']]: _vm.height === 50,
      [_vm.$style['modal-height--full']]: _vm.height100 && _vm.height !== 50,
    },staticStyle:{"position":"absolute","top":"0","z-index":"10040"},attrs:{"prevent-deactivation":true,"class-name-active":"my-active-class","parent":true,"w":_vm.dialogWidth,"x":_vm.x,"y":_vm.y,"h":50,"drag-handle":'.dialog-container'}},[(_vm.visible)?_c('div',{class:{
        'c-dialog': true,
        'c-dialog--center': _vm.center,
        'c-dialog--roundedStyle': _vm.styleType === 'roundedStyle',
      }},[_c('transition',{attrs:{"name":"bottom-animate","appear":""}},[_c('div',{staticStyle:{"width":"100%"}},[(!_vm.hideCloseButton)?_c('i',{class:_vm.$style['iconClose'],on:{"click":_vm.onClose}}):_vm._e(),(_vm.collapse)?_c('i',{class:_vm.$style['collapse_icon'],on:{"click":function($event){return _vm.onMinimize()}}}):_vm._e(),_vm._t("default")],2)])],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }