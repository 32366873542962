<template>
  <div class="documents-list">
    <h1 v-if="!disableTitle">Документы:</h1>
    <div v-for="(document, index) in documents" :key="index" class="documents-list__document">
      <img alt="" src="~@/assets/icons/basic/document.svg"/>

      <div class="documents-list__title documents-list__title--name">
        <span>{{ document.name }}</span>
      </div>
      <div v-if="!disableDate" class="documents-list__title documents-list__title--date">
        <span>{{ document.date }}</span>
      </div>

      <document-downloader :document="document"/>
    </div>
  </div>
</template>

<script>
import { DocumentDownloader } from '@/components';

export default {
  name: 'DocumentsList',
  components: {
    DocumentDownloader,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
    disableDate: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>
.documents-list {

  h1 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &__document {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #4C4E51;
    flex-grow: 2;
    margin: 0 24px 0 16px;

    &--name {
      text-align: left;
    }

    &--date {
      min-width: 90px;
      text-align: right;
    }
  }
}
</style>
