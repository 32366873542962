<template>
  <simple-autocomplete-by-function
    ref="autocomplete"
    v-model="selectedItem"
    :dependency="dependency"
    :fetch-data-function="fetchData"
    :fetch-default-data-function="fetchData"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :items.sync="internalItems"
    :search.sync="searchItem"
    :selected="selected"
    :title="title"
  />
</template>

<script>

import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { findAddress } from '@/api/rest/dadata/api';

export default {
  components: { SimpleAutocompleteByFunction },
  name: 'AddressAutocomplete',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalItems: [],
    };
  },
  emits: ['update:search', 'change', 'update:items'],
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
  },
  watch: {
    internalItems() {
      this.$emit('update:items', this.internalItems);
    },
    items: {
      immediate: true,
      handler() {
        this.internalItems = this.items;
      },
    },
  },
  methods: {
    async fetchData(name) {
      if (!name?.length) return [];
      const data = await findAddress(name);
      if (!data?.suggestions?.length) return [];
      return data.suggestions.map((it) => {
        const value = it.data?.postal_code ? `${it.data?.postal_code}, ${it.value}` : it.value;
        return {
          id: value,
          name: value,
        };
      });
    },
    resetValidation() {
      this.$refs.autocomplete.resetValidation();
    },
  },
};
</script>
