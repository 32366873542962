export default {
    methods: {
        isPhone(value) {
            if (!value) {
              return false;
            }
            const phonePattern = /(^\+?[87]\d{10}$)|(^[9]\d{9}$)/;
            const phone = value.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
            return phonePattern.test(phone);
        },
        isEmail(value) {
            const emailPattern = /^\S+@\S+\.\S{2,}$/;
            return emailPattern.test(value.trim());
        },
        phoneFormat(pPhone) {
            const phonePattern = /(^\+?[87]\d{10}$)|(^[9]\d{9}$)/;
            const phone = pPhone.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
            const isPhone = phonePattern.test(phone);
            let formattedPhone = '';

            if (isPhone) {
                let firstNumber = 0;
                if (phone[0] !== '8' && phone[0] !== '7') {
                    firstNumber = phone[0] === '+' ? 1 : -1;
                }

                const part4 = phone.substr(9 + firstNumber, 2);
                const part3 = phone.substr(7 + firstNumber, 2);
                const part2 = phone.substr(4 + firstNumber, 3);
                const part1 = phone.substr(1 + firstNumber, 3);
                formattedPhone = '+7 '.concat('(', part1, ') ', part2, '-', part3, '-', part4);
            }

            return { isPhone: isPhone, formattedPhone: formattedPhone };
        },
        unformatPhone(phone) {
          return phone.replace(RegExp('[+7]?[-]?[(]?[)]?[ ]?', 'g'), '');
        },
        maskedContact(contact, isEmail) {
          if (isEmail || this.isEmail(contact)) {
            const atIndex = contact.indexOf('@');
            const startPart = contact.substring(0, atIndex);
            const endPart = contact.substring(atIndex);
            if (startPart.length <= 4) return `${startPart[0]}******${startPart[startPart.length - 1]}${endPart}`;
            return `${startPart.substring(0, 2)}******${startPart.substring(startPart.length - 2)}${endPart}`;
          }
          return `${contact.substring(0, 2)}******${contact.substring(contact.length - 2)}`;
        },
    },
};
