<template>
  <div class="header">
    <h1>{{ title }}, {{ uuid }}</h1>
    <div v-for="(item, index) in statusForMap" :key="index" class="header__status">
      <div v-if="item.type === 'isOk'" :style="item.style" class="header__icon-is-ok"></div>
      {{ item.label }}
    </div>
    <div v-if="labelRequired" class="header__label-required">
      * поля, обязательные для заполнения
    </div>
  </div>
</template>

<script>
const statusMap = {
  NEW: { label: 'Новая', color: '#FF7400' },
  SETTLED: { label: 'Урегулирована', color: '#4CCB40' },
  UNSETTLED: { label: 'Неурегулирована', color: '#EB5757' },
  ARCHIVED: { label: 'В архиве', color: '#EB5757' },
  IN_APPLICATION: { label: 'В заявке на ТП', color: '#EB5757' },
  CONNECTED: { label: 'Прошла ТП', color: '#4CCB40' },
  WAITING_SUPPLY_CONTRACT: { label: 'Ожидает заключение ДЭС/ДКП', color: '#EB5757' },
  EXCLUDED: { label: 'Удалена', color: '#DFE1E6' },
  ACTIVE: { label: 'Активная', color: '#4CCB40' },
  WAITING_ADDITION_APPROVE: { label: 'Ожидает согласования ДС', color: '#EB5757' },
  DISAGREEMENT: { label: 'Разногласия', color: '#EB5757' },
};

export default {
  name: 'UsagePointHeader',
  props: {
    title: {
      type: String,
      default: '-',
    },
    uuid: {
      type: String,
      default: '-',
    },
    status: {
      type: Array,
      default: () => [],
    },
    labelRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusForMap() {
      return this.status.map((item) => ({
          type: item.type,
          label: statusMap[item.value].label,
          style: { background: statusMap[item.value].color },
        }));
    },
  },
};

</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    margin-right: 48px;
  }

  &__status {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #C1C4C7;
    border-radius: 16px;
    padding: 3px 14px 3px 14px;
    margin-right: 16px;
  }

  &__icon-is-ok {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 24px;
  }

  &__label-required {
    position: absolute;
    top: 172px;
    right: -298px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757A;

    @media (max-width: 1400px) {
      position: relative;
      top: 0;
      right: 0;
      width: 848px;
      margin-top: 16px;
    }
  }

}
</style>
