<template>
  <div class="signatories-step">
    <div v-if="usefulVacationDocument.isDisplayDocumentScan">
      <span class="signatories-step__title">Выберите лицо, подписавшее оригинальный документ</span>
      <emploee-select-or-input :error="inputError" :init-employee="initInput" mode="input"
                               role="signatory"
                               @emploeeSelectOrInput="onSelectedInput"/>
    </div>
    <div>
      <span v-if="usefulVacationDocument.isDisplayDocumentScan" class="signatories-step__title">Выберите лицо, подписывающее акт верификации</span>
      <span v-else class="signatories-step__title">Выберите подписывающее лицо</span>
    </div>
    <emploee-select-or-input :error="selectError" :init-employee="initEmployeeSelected" :inn="signatoryInn" mode="select" permission="SVET_A12-S" role="signatory"
                             @emploeeSelectOrInput="onSelectedCurrent"/>
  </div>
</template>

<script>
import { EmploeeSelectOrInput } from '@/components';
import { mapGetters } from 'vuex';

export default {
  components: {
    EmploeeSelectOrInput,
  },
  props: {
    usefulVacationDocument: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.usefulVacationDocument.inputSignatory) {
      this.initInput = {
        role: {
          name: this.usefulVacationDocument.inputSignatory.role.name,
        },
        reason: {
          reasonDocument: this.usefulVacationDocument.inputSignatory.reason.name,
          number: this.usefulVacationDocument.inputSignatory.reason.number,
          date: this.usefulVacationDocument.inputSignatory.reason.date,
        },
        fullName: {
          name: this.usefulVacationDocument.inputSignatory.fullName.name,
          surname: this.usefulVacationDocument.inputSignatory.fullName.surname,
          patronymic: this.usefulVacationDocument.inputSignatory.fullName.patronymic,
        },
      };
    }
  },
  data() {
    return {
      initInput: null,
    };
  },
  computed: {
    signatoryInn() {
      return this.getAccount.profile.inn;
    },
    retailerInn() {
      return '7736520080';
    },
    ...mapGetters('user', ['getAccount']),
    selectError() {
      return { select: this.usefulVacationDocument.signatory_error.error };
    },
    inputError() {
      return {
        fullName: {
          name: this.usefulVacationDocument.signatory_fullName_name_error.error,
          surname: this.usefulVacationDocument.signatory_fullName_surname_error.error,
          patronymic: this.usefulVacationDocument.signatory_fullName_patronymic_error.error,
        },
        role: this.usefulVacationDocument.signatory_position_error.error,
        reason: {
          reasonDocument: this.usefulVacationDocument.signatory_reason_reasonDocument_error.error,
          number: this.usefulVacationDocument.signatory_reason_number_error.error,
          date: this.usefulVacationDocument.signatory_reason_date_error.error,
        },
      };
    },
    initEmployeeSelected() {
      return {
        userId: this.usefulVacationDocument.currentSignatory.userId,
        reason: {
          id: this.usefulVacationDocument.currentSignatory.powerOfAttorneyId,
        },
      };
    },
  },
  methods: {
    onSelectedCurrent(selected) {
      this.usefulVacationDocument.currentSignatory.userId = selected.userId;
      this.usefulVacationDocument.currentSignatory.role.id = selected.role?.id ?? '';
      this.usefulVacationDocument.currentSignatory.role.name = selected.role?.name ?? '';
      this.usefulVacationDocument.currentSignatory.reason.name = selected.reason?.reasonDocument ?? '';
      this.usefulVacationDocument.currentSignatory.reason.number = selected.reason?.number ?? '';
      this.usefulVacationDocument.currentSignatory.reason.date = selected.reason?.date ?? '';
      this.usefulVacationDocument.currentSignatory.fullName.name = selected.fullName?.name ?? '';
      this.usefulVacationDocument.currentSignatory.fullName.surname = selected.fullName?.surname ?? '';
      this.usefulVacationDocument.currentSignatory.fullName.patronymic = selected.fullName?.patronymic ?? '';
      this.usefulVacationDocument.currentSignatory.fullNameGenitive.name = selected.fullNameGenitive?.name ?? '';
      this.usefulVacationDocument.currentSignatory.fullNameGenitive.surname = selected.fullNameGenitive?.surname ?? '';
      this.usefulVacationDocument.currentSignatory.fullNameGenitive.patronymic = selected.fullNameGenitive?.patronymic ?? '';
      this.usefulVacationDocument.currentSignatory.powerOfAttorneyId = selected.reason?.id;
      this.usefulVacationDocument.currentSignatory.accountId = selected.accountId ?? '';
    },
    onSelectedInput(selected) {
      this.usefulVacationDocument.inputSignatory.userId = selected.userId ?? '';
      this.usefulVacationDocument.inputSignatory.role.id = selected.role?.id ?? '';
      this.usefulVacationDocument.inputSignatory.role.name = selected.role?.name ?? '';
      this.usefulVacationDocument.inputSignatory.reason.name = selected.reason?.reasonDocument ?? '';
      this.usefulVacationDocument.inputSignatory.reason.number = selected.reason?.number ?? '';
      this.usefulVacationDocument.inputSignatory.reason.date = selected.reason?.date ?? '';
      this.usefulVacationDocument.inputSignatory.fullName.name = selected.fullName?.name ?? '';
      this.usefulVacationDocument.inputSignatory.fullName.surname = selected.fullName?.surname ?? '';
      this.usefulVacationDocument.inputSignatory.fullName.patronymic = selected.fullName?.patronymic ?? '';
      this.usefulVacationDocument.inputSignatory.fullNameGenitive.name = selected.fullNameGenitive?.name ?? '';
      this.usefulVacationDocument.inputSignatory.powerOfAttorneyId = selected.reason?.id;
      this.usefulVacationDocument.inputSignatory.fullNameGenitive.surname = selected.fullNameGenitive?.surname ?? '';
      this.usefulVacationDocument.inputSignatory.fullNameGenitive.patronymic = selected.fullNameGenitive?.patronymic ?? '';
      this.usefulVacationDocument.inputSignatory.accountId = selected.accountId;
    },
  },
};
</script>

<style lang="scss" scoped>
.signatories-step {
  width: 848px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px auto 0px;

  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #4C4E51;
  }
}
</style>
