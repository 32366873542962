import { BASE_URL } from '@/constants/api';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import { consignmentTypesMap } from 'components/Processes/list';

export async function fetchConsignment(consignmentId, consignmentType) {
  const response = await fetch(
    `${BASE_URL}/consignment?id=${consignmentId}&type=${consignmentType}`,
  );

  const result = await response.json();

  return  {
    previousConsignmentId: result.info.previousConsignmentId,
    edited: result.info.edited,
    uniqueNumber: result.info.uniqueNumber,
    warehouseName: result.info.warehouseName,
    eventName: result.info.eventName,
    adreskaName: result.info.adreskaName,
    contract: result.info.contract,
    contractorId: result.info.contractorId,
    contractorName: result.info.contractorName,
    date: formatDateFromOdkTimestamp(result.info.date),
    employee: result.info.fullName,
    performer: result.info.performerFullName,
    axpName: result.info.axpName,
    type: consignmentTypesMap[result.info.type],
    originalType: result.info.type,
    acceptanceToWarehouseId: result.info.acceptanceToWarehouseId,
    extraditeConstructionId: result.info.extraditeConstructionId,
    recalculationId: result.info.recalculationId,
    accountingBalancesId: result.info.accountingBalancesId,
    contractorEmployeeName: result.info.contractorEmployeeName,
    elements: result.elements.map((el) => ({
      elementName: el.elementName,
      unitName: el.unitName,
      count: el.countElement,
      defects: el.defects,
      photos: el.imageName.map((it, idx) => ({
        file: 'File',
        name: it,
        previewUrl: `${BASE_URL}/api/get/${el.imageFolder[idx]}/${it}`,
      })).filter((it) => it.name),
    })),
  };
}
