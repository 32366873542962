<template>
  <button
    :class="{[$style.button]: true, [$style.grayButton]: gray, [$style.redButton]: red}"
    :disabled="disabled"
    :role="role"
    type="submit"
    @click="$emit('submit')"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'SubmitTaskDetails',
  props: {
    disabled: Boolean,
    title: {
      type: String,
      default: 'Ok',
    },
    role: {
      type: String,
      default: 'submitTask',
    },
    // TODO: ?? wrong props, form button dont needs props for color
    // button have prop for get disabled state, fix this
    gray: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" module>
.button
  width: 100%
  padding: 16px
  font-family: 'Roboto Condensed', sans-serif
  font-weight: 400
  font-size: 21px
  line-height: 24px
  background-color: #2F82DF
  color: #FFF
  text-transform: uppercase

.grayButton
  background-color: #71757A

.redButton
  background-color: red

.button:disabled
  background-color: #ACB0B5
</style>
