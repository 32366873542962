<template>
  <div :style="customStyle">
    <v-search-select
        :value="selectedItem"
        :disabled="isDisabled"
        :required="isRequired"
        :title="title"
        :add-element-item="addElementItem && filterForAccess('process/3/2', getAuthorization?.blockAccess)"
        :url-search="urlSearch"
        :url-default-data="urlDefaultData"
        :fetch-data-function="fetchDataFunction"
        :fetch-default-data-function="fetchDefaultDataFunction"
        :dependency="dependency"
        :find-ids="findIds"
        :placeholder="placeholder"
        :search.sync="searchItem"
        :not-reset-search="notResetSearch"
        :new-item="newData"
        @input="selectItem"
        @addElement="onCreateElement"
        @options="updateOptions"
    />
    <events-edit
        :modal-action.sync="modalAction"
        :modal-value="modalValue"
        @onAction="onCreate"
    />
  </div>
</template>

<script>
import EventsEdit from 'views/private/OdkAccounting/Events/EventsEdit.vue';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { mapGetters } from 'vuex';

export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  components: {
    EventsEdit,
    VSearchSelect,
  },
  props: {
    title: {
      default: '',
    },
    selected: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    items: {
      default: () => [],
    },
    urlSearch: {
      type: String,
      default: '/events/find?name={0}',
    },
    urlDefaultData: {
      type: String,
      default: '/events/top20',
    },
    fetchDataFunction: {
      type: Function,
      default: null,
    },
    fetchDefaultDataFunction: {
      type: Function,
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      required: false,
      default: null,
    },
    findIds: {
      type: Array,
      required: false,
      validator(value) {
        return value.every((item) => typeof item.queryIndex === 'number');
      },
    },
    marginBottom: {
      type: String,
      default: '24',
    },
    placeholder: {
      type: String,
      default: '',
    },
    addElementItem: {
      type: Boolean,
      default: true,
    },
    notResetSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:search', 'update:items'],
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      newData: {},
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    customStyle() {
      return `marginBottom: ${this.marginBottom}px`;
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    selectItem(value, label) {
      this.selectedItem = value;
      this.$emit('update:search', label);
    },
    onCreateElement() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    filterForAccess: filterForAccess,
    onCreate(data) {
      if (!data?.id || !data?.name) {
        return;
      }

      this.newData = data;
    },
    updateOptions(options) {
      this.$emit('update:items', options);
    },
  },
};
</script>
