export const legalUser = {
  groups: [
    {
      title: 'Объекты',
      route: 'objects',
      iconComponent: 'HomeWork',
    },
    {
      title: 'Заявки',
      route: 'tasks',
      iconComponent: 'Tasks',
    },

    {
      title: 'Технологическое присоединение',
      iconComponent: 'TpGroup',
      items: [
        {
          title: 'Заявки на технологическое присоединение',
          route: 'tpo',
          iconComponent: 'Tp',
        },
        {
          title: 'Договоры об осуществлении технологического присоединения',
          route: 'connection-application-contracts',
          iconComponent: 'AgreementsImplementationTechnologicalConnection',
        },
      ],
    },
    {
      title: 'Энергоснабжение',
      iconComponent: 'FlashAuto',
      items: [
        {
          title: 'Договоры энергоснабжения',
          route: 'electricity-supply-contracts',
          iconComponent: 'LibraryBooks',
        },
      ],
    },
    {
      title: 'Оплата',
      route: 'pay',
      iconComponent: 'Pay',
    },
    // TODO раздел в разработке
    // {
    //   title: 'УСЛУГИ',
    //   route: 'services',
    //   iconComponent: 'Service',
    // },
    {
      title: 'Документы',
      route: 'documents',
      iconComponent: 'Documents',
    },
    {
      title: 'Управление организацией',
      iconComponent: 'Partner',
      items: [
        {
          title: 'Сотрудники компании',
          route: 'employees',
          iconComponent: 'Partner',
        },
        {
          title: 'Доверенности',
          route: 'attorney',
          iconComponent: 'Attorney',
        },
        {
          title: 'Иерархия компании',
          route: 'company-hierarchy',
          iconComponent: 'Structure',
        },
        {
          title: 'Реквизиты компании',
          route: 'company-details',
          iconComponent: 'HomeWork',
        },
      ],
    },
    {
      title: 'Аналитика и отчетность',
      route: 'reports',
      iconComponent: 'Reports',
    },
    {
      title: 'Профиль',
      route: 'user-details',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Доступные профили',
      route: 'available-profiles',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Чаты',
      route: 'user-requests',
      iconComponent: 'Chat',
    },
  ],
};
