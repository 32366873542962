<template>
  <div :class="$style.container">
    <div :class="$style.searchInputWrp">
      <search-input
        placeholder="Введите должность"
        @clear="clearHandler"
        @search="searchHandler"
      />
    </div>
    <div style="display: flex; flex-direction: row;">
      <v-button style="margin-bottom: 32px" @click="isAddRole=true">
        Добавить должность
      </v-button>
    </div>
    <div :class="$style.form">
      <div v-for="(item, index) in rolesListSelected" :key="index"
           :class="{[$style.info]: true, [$style.owner]: isDisabled(item)}">
        <div :class="$style['info-section']">
          <div :class="$style['info-block']">
            <div :class="$style['info-block-text']">
              <p>
                <strong style="word-wrap: break-word; ">{{ item.name }}</strong>
                <button v-if="!item.isCompanyOwner" :class="$style.edit" @click="clickByUpdate(item)">
                  <edit/>
                </button>
                <button
                  v-if="isRoleDeleteButtonShow(item)"
                  :class="$style.crossDelete"
                  @click="clickByDelete(item)"
                >
                  <cross-delete/>
                </button>
                <button
                  :class="$style.collapse"
                  :style="collapsedStyle(item.isShown)"
                  @click="onCollapseClick(item.id)">
                  <chevron-down/>
                </button>
              </p>
            </div>
          </div>
        </div>
        <br/>
        <div v-if="item.isShown">
          <v-select
            v-model="item.selectedBlock"
            :options="blocks"
            label="Функциональный блок"
            style="margin-bottom: 12px"
          />
          <div v-if="getAllowedRoutesByCode(item.selectedBlock).length !== 0"
               :class="$style.allowedRoutesWrp"
          >
            <div :class="$style.allowedRoutes">
              Пункты меню, доступные при выдаче прав из функционального блока
            </div>
            <div v-for="(route, index) in getAllowedRoutesByCode(item.selectedBlock)" :key="index">
              {{ route }}
            </div>
          </div>
          <table :class="$style.details">
            <thead>
            <tr>
              <td>№</td>
              <td>Код</td>
              <td>Описание</td>
              <td>Разрешено</td>
            </tr>
            </thead>
            <tbody :class="$style.tableTd">
            <tr
              v-for="(permission, permissionIndex) in item.newPermissions.filter((permission) => permission.code.includes(`${item.selectedBlock}-`))"
              :key="permissionIndex"
            >
              <td>{{ permissionIndex + 1 }}</td>
              <td>{{ permission.code }}</td>
              <td>{{ permission.description }}</td>
              <td :class="$style.center">
                <input
                  v-model="permission.value"
                  :disabled="isDisabled(item)"
                  type="checkbox"
                  @change="changePermissions(item, index)"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <p
              v-if="submitError[index] && submitError[index].length !== 0"
              class="errorText"
            >
              {{ submitError[index] }}
            </p>
            <div :class="$style.submit">
              <horizontal-progress-bar v-if="isWaitingSubmit[index] === true"/>
              <submit-task-details
                :disabled="isWaitingSubmit[index] === true || isDisplaySubmit[index] !== true"
                title="Сохранить"
                @submit="onSubmit(item, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <dialog-template v-model="isAddRole" collapse height100>
      <v-form @submit="saveRoles">
        <v-dialog-header
          subtitle="Для добавления должности введите название и нажмите кнопку сохранить"
          title="Добавление должностей"
        />
        <div ref="roles" :class="$style.dialogContent">
          <div v-for="(role, idx) in roles" :key="idx">
            <div :class="$style.grid2Column">
              <v-input
                v-model="role.name"
                :vid="idx.toString()"
                clearable
                label="Название"
                rules="required"
              />
              <i :class="$style.deleteBtn" @click="removeRole(idx, role)"
              ><img
                alt="delete"
                src="~@/assets/icons/basic/delete.svg"
                style="width: 18px; height: 18px"
              /></i>
            </div>
          </div>
          <v-button type="button" @click="addEmployee"
          >Добавить ещё должность
          </v-button
          >
        </div>
        <div :class="{[$style.footerError]: errorText, [$style.footer]: !errorText}">
          <p v-if="errorText" class="errorText" style="margin: 0 16px">
            {{ errorText }}
          </p>
          <horizontal-progress-bar v-if="isWaitingRolesSubmit"/>
          <button :class="$style.saveRoles" type="submit">Сохранить</button>
        </div>
      </v-form>
    </dialog-template>

    <!-- update user modal begin -->
    <dialog-template v-model="isUpdateRole" center>
      <v-form @submit="updateRole">
        <v-dialog-header
          subtitle="Для изменения названия должности введите новое название и нажмите кнопку сохранить"
          title="Изменение названия должности"
        />
        <div ref="roles" :class="$style.dialogContent">
          <v-input
            v-model="updateName"
            clearable
            label="Название должности"
            rules="required"
          />
        </div>
        <p v-if="errorText" class="errorText" style="margin: 0 16px">
          {{ errorText }}
        </p>
        <horizontal-progress-bar v-if="isWaitingUpdateRoleSubmit"/>
        <button :class="$style.saveRoles" :disabled="updateDisabled" type="submit">Сохранить</button>
      </v-form>
    </dialog-template>
    <!-- update user modal end -->

    <!-- delete user modal begin -->
    <dialog-template v-if="displayDeleteDialog" v-model="displayDeleteDialog" center>
      <div style="padding: 64px 16px 0 16px">
        <p :class="$style.deleteText">
          Вы уверены, что хотите удалить должность
        </p>
        <p :class="$style.deleteText">
          {{ roleForDelete.name }}?
        </p>
      </div>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="ДА" @submit="deleteRoleFromCompanyHandler(roleForDelete.id)"/>
        <submit-task-details :gray="true" title="НЕТ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>
    <!-- delete user modal end -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { convertDbDateTimeFormat } from 'lib/utils/date';
import { isAccess } from 'lib/utils/permissions';
import { checkField, getAllVueRoutes } from '@/utils/common';
import { SearchInput, VButton, VDialogHeader, VForm, VInput } from '@/components';

import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';

import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import Edit from 'atoms/icons/action/Edit';
import VSelect from 'components/VSelect/VSelect.vue';



export default {
  name: 'CabinetPermissions',
  components: {
    VSelect,
    Edit,
    DialogTemplate,
    SubmitTaskDetails,
    HorizontalProgressBar,
    VDialogHeader,
    VForm,
    VInput,
    VButton,
    SearchInput,
    CrossDelete: () => import('atoms/icons/action/CrossDelete'),
    ChevronDown: () => import('atoms/icons/arrows/ChevronDown'),
  },
  created() {
    this.loadData();
    this.rolesListSelected = this.rolesList;
  },
  data() {
    return {
      roles: [{ name: '' }],
      possiblePermissions: [{ code: '', description: '' }],
      xmlAddUsersToOrganisationApplication: '',
      isWaitingRolesSubmit: false,
      rolesList: [],
      rolesListOld: [],
      searcedRolesList: [],
      rolesListSelected: [],
      isWaitingSubmit: [],
      isDisplaySubmit: [],
      isModalOpen: [],
      isPowerOfAttorneySubmit: false,
      powerOfAttorney: [],
      submitError: [],
      errorText: '',
      isAddRole: false,
      waitingFile: [],
      expiresAt: '',
      displayDeleteDialog: false,
      roleForDelete: null,
      roleToUpdate: null,
      checkName: '',
      isUpdateRole: false,
      updateName: '',
      isWaitingUpdateRoleSubmit: false,
      blocks: [{ label: '', value: '' }],
    };
  },
  computed: {
    updateDisabled() {
      return this.updateName === this.checkName;
    },
    isDisabled() {
      return (item) => item.isCompanyOwner;
    },
    ...mapGetters('dictionary', ['userFunctionalBlock', 'userFunction']),
    ...mapGetters('user', ['getUser', 'getAccount']),
    ...mapGetters('user', ['haveUserPermissionToDeleteUserFromCompany']),
  },
  methods: {
    getAllowedRoutesByCode(code) {
      const allRoutes = getAllVueRoutes(this.$router.options.routes);
      const routesByPermission = isAccess([{ code }], this.getAccount?.kind);
      const allowedRoutes = [];

      for (const prop in routesByPermission) {
        // eslint-disable-next-line no-prototype-builtins
        if (routesByPermission.hasOwnProperty(prop) && routesByPermission[prop]) {
          const allowedRoute = allRoutes.find((route) => route.path === prop);
          allowedRoutes.push(checkField(allowedRoute?.meta?.title));
        }
      }

      return allowedRoutes;
    },
    setRolesListSelected(searcedRolesListNewVal) {
      if (searcedRolesListNewVal.length !== 0) {
        this.rolesListSelected = searcedRolesListNewVal;
      } else {
        this.rolesListSelected = [];
      }
    },
    clearHandler() {
      this.searcedRolesList = [];
      this.rolesListSelected = this.rolesList;
    },
    searchHandler(searchRequset) {
      if (searchRequset === '') {
        this.rolesListSelected = this.rolesList;
        return;
      }

      this.searcedRolesList = [];
      this.rolesList.forEach((rolesListItem) => {
        if (rolesListItem.name.toLowerCase().trim().includes(searchRequset.toLowerCase().trim())) {
          this.searcedRolesList.push(rolesListItem);
        }
      });

      this.setRolesListSelected(this.searcedRolesList);
    },
    collapsedStyle(isShown) {
      if (isShown) return {
        transform: 'rotate(180deg)',
      };
      return {
        transform: 'rotate(0)',
      };
    },
    processServerError(stacktrace) {
      const message = stacktrace.message;
      if (message.includes('IllegalStateException') || message.includes('IllegalArgumentException')) {
        return message.split(':')[2];
      }
        return HTTPError.network(stacktrace).message;

    },
    isRoleDeleteButtonShow(item) {
      // dont show for user with "OWNER" role
      if (item.isCompanyOwner) {
        return false;
      }

      // dont show for user which dont have delete permission
      // TODO: implement for new permissions
      /* if (!this.haveUserPermissionToDeleteUserFromCompany) {
        return false;
      }*/

      return true;
    },

    addEmployee() {
      this.roles.push({ name: '' });

      this.$nextTick(() => {
        this.$refs.roles.scrollTop = this.$refs.roles.scrollHeight;
      });
    },
    removeRole(index, role) {
      if (index > 0) {
        this.roles.splice(index, 1);
      } else if (index === 0) {
        if (this.roles.length > 1) {
          this.roles.splice(0, 1);
        } else {
          role.name = '';
        }
      }
    },
    async loadData() {
      try {
        const roles = await this.getCompanyRoles();
        this.possiblePermissions = await this.getPossiblePermissions();
        this.blocks = this.possiblePermissions.map((block) => ({
              label: `${block.code}: ${block.description}`,
              value: block.code,
            }));
        this.possiblePermissions = this.possiblePermissions.flatMap((permissionBlock) => permissionBlock.permissions).filter((it) => it);

        this.updateRolesList(roles);
      } catch (error) {
        console.log('roles', error);
      }
    },
    updateRolesList(roles) {
      roles.forEach((role) => role.isShown = false);

      roles.forEach((role) => {
        role.newPermissions = this.possiblePermissions.map((permission) => ({
          ...permission,
          value: role.permissions.some((rolePermission) => rolePermission.code === permission.code),
        }));
        role.selectedBlock = this.blocks[0].value;
      });

      roles = roles.filter((role) => !this.rolesList.some((old) => old.id === role.id));

      this.rolesList.push(...roles);
      this.rolesListOld = JSON.parse(JSON.stringify(this.rolesList));
    },
    convertDbDateTimeFormat(value) {
      return convertDbDateTimeFormat(value);
    },
    onCollapseClick(id) {
      console.log(id);
      console.log(this.rolesList);
      this.rolesList.forEach((it) => {
        if (it.id === id) it.isShown = !it.isShown;
      });
      this.rolesList = [...this.rolesList];
    },
    addPermissionToList(permissionCode, isAdded, addedPermissions, removedPermissions) {
      const dictionaryToAdd = isAdded ? addedPermissions : removedPermissions;
      dictionaryToAdd.push(permissionCode);
    },
    async onSubmit(item, index) {
      this.submitError[index] = '';
      this.isWaitingSubmit[index] = true;
      this.isWaitingSubmit.splice(index, 1, true);

      const addedPermissions = [];
      const removedPermissions = [];
      item.newPermissions.forEach((permission, permissionIndex) => {
        if (
          this.comparePermission(permission, index, permissionIndex)
        ) {
          console.log('added');
          this.addPermissionToList(
            permission.code,
            permission.value,
            addedPermissions,
            removedPermissions,
          );
        }
      });
      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.getAccount.id, this.getUser.id, {
          permissionsToAdd: {
            roleId: item.id,
            permissions: addedPermissions,
          },
          permissionsToDelete: {
            roleId: item.id,
            permissions: removedPermissions,
          },
        });
      console.log('XML', this.xmlAddUsersToOrganisationApplication);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });
        await this.setUser();
        this.isDisplaySubmit[index] = false;
        this.rolesListOld = JSON.parse(JSON.stringify(this.rolesList));
      } catch (error) {
        this.submitError[index] = this.processServerError(error);
      }

      this.isWaitingSubmit.splice(index, 1, false);
    },
    comparePermission(permission, index, permissionIndex) {
      return permission.value !==
        this.rolesListOld[index].newPermissions[permissionIndex]
          .value;
    },
    changePermissions(role, index) {
      this.submitError[index] = '';
      role.newPermissions.every((permission, permissionIndex) => {
        if (
          this.comparePermission(permission, index, permissionIndex)
        ) {
          this.isDisplaySubmit[index] = true;
          return false;
        }
          this.isDisplaySubmit[index] = false;
          return true;

      });
    },
    async saveRoles() {
      this.isWaitingRolesSubmit = true;

      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.getAccount.id, this.getUser.id, {
          roleToCreate: this.roles,
        });
      console.log('XML', this.xmlAddUsersToOrganisationApplication);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });
        this.isWaitingRolesSubmit = false;
        this.isAddRole = false;
        // this.roles = [{ name: '' }];
        await this.loadData();
      } catch (error) {
        this.errorText = this.processServerError(error);
        this.isWaitingRolesSubmit = false;
      }
    },
    clickByUpdate(role) {
      this.roleToUpdate = role;
      this.checkName = role.name;
      this.updateName = role.name;
      this.isUpdateRole = true;
    },
    async updateRole() {
      this.isWaitingUpdateRoleSubmit = true;

      this.roleToUpdate.name = this.updateName;

      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.getAccount.id, this.getUser.id, {
          roleToUpdate: [{ id: this.roleToUpdate.id, name: this.roleToUpdate.name }],
        });
      console.log('XML', this.xmlAddUsersToOrganisationApplication);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });
        await this.loadData();
      } catch (error) {
        this.errorText = this.processServerError(error);
      } finally {
        this.roleToUpdate = null;
        this.updateName = '';
        this.checkName = '';
        this.isWaitingUpdateRoleSubmit = false;
        this.isUpdateRole = false;
      }
    },
    clickByDelete(role) {
      this.roleForDelete = role;
      this.displayDeleteDialog = true;
    },
    async deleteRoleFromCompanyHandler(roleId) {
      this.displayDeleteDialog = false;

      this.xmlAddUsersToOrganisationApplication =
        generateXml.OrganisationStructureChangingOrder(this.getAccount.id, this.getUser.id, {
          roleToDelete: [{ id: roleId }],
        });
      console.log('XML', this.xmlAddUsersToOrganisationApplication);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });
        this.rolesList = this.rolesList.filter((item) => item.id !== roleId);
      } catch (error) {
        this.vueShowNotification(
          this.processServerError(error),
          '',
        );
      }
    },
    ...mapActions('user', ['getCompanyRoles', 'updatePermissions', 'deleteUserFromCompany', 'getPossiblePermissions', 'setUser']),
    ...mapActions('cabinet', ['setWorkflows']),
    ...mapActions('document', ['documentUpload', 'getDocumentByUrl']),
  },
};
</script>

<style lang="sass" module>
@import "src/assets/sass/components/tables"

.submit
  width: 300px
  margin-top: 16px

.downloadIcon
  display: flex
  align-items: center

.downloadIcon, .deleteIcon
  color: #4C4E51

.attachment
  +base-subtitle

.attachment
  display: flex
  justify-content: space-between

.filePowerOfAttorney
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.button-power-of-attorney
  padding: 8px

.container
  position: relative

  .searchInputWrp
    margin-bottom: 10px

  .content
    width: 560px
    margin: 0 auto 0
    z-index: 999

    .stepNote
      +base-text
      width: 100%
      padding: 16px
      margin-top: 20px
      margin-bottom: 32px
      background-color: rgba(242, 201, 76, 0.25)


  .form
    position: absolute
    width: 100%
    margin-bottom: 64px
    padding: 0 32px 0 0

    .allowedRoutesWrp
      margin: 10px 0

      .allowedRoutes
        margin-bottom: 5px
        font-weight: bold

    div

      &::-webkit-scrollbar-button
        background-repeat: no-repeat
        width: 10px
        height: 0

      &::-webkit-scrollbar-thumb
        -webkit-border-radius: 0
        border-radius: 10px
        background-color: #d1d1d1

      &::-webkit-scrollbar-thumb:hover
        background-color: #a19f9f

      &::-webkit-resizer
        background-repeat: no-repeat
        width: 10px
        height: 0

      &::-webkit-scrollbar
        width: 10px
        height: 10px

    .info
      margin-bottom: 32px

    .info-block
      display: flex
      flex-wrap: wrap
      align-items: center
      overflow: hidden

    .info-block-text
      margin-right: 5px
      overflow: hidden

      p
        margin-left: 0
        display: flex
        gap: 10px
        align-items: center

      strong
        max-width: 90%

  .button
    +base-button-text
    text-decoration: underline
    text-transform: uppercase
    cursor: pointer
    margin-left: 48px
    margin-top: 32px
    margin-bottom: 48px

    &:hover
      text-decoration: none

  table
    .tableTd
      td
        padding: 4px 16px
        font-family: 'Roboto Condensed'
        color: gray

      td.error
        background-color: red

      td.center
        text-align: center

.saveRoles
  position: relative
  width: 100%
  padding: 16px
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 24px
  background-color: #2F82DF
  color: #FFF
  text-transform: uppercase

  &:disabled
    background-color: #ACB0B5

.grid2Column
  +grid-2-column
  grid-template-columns: 4fr 1fr
  +base-spacing

.dialogContent
  padding: 16px 24px
  overflow: auto
  overflow-x: hidden
  height: 82%

.deleteBtn
  transform: translateY(10px)
  max-width: 18px
  max-height: 18px
  cursor: pointer

.owner
  input
    pointer-events: none

.download
  color: #2F82DF
  cursor: pointer
  text-decoration: underline

.edit
  background: none
  display: flex
  align-items: center
  width: 24px
  height: 24px

.collapse
  background: none
  display: flex
  align-items: center
  width: 24px
  height: 24px


.crossDelete
  background: none
  display: flex
  align-items: center
  width: 24px
  height: 24px

.deleteText
  +base-title
  word-break: break-all
  width: 100%
  text-align: center

.submitBlock
  display: flex
  margin-top: 64px

  div
    width: 50%

.footer
  transform: translateY(20px)

.footerError
  transform: translateY(2px)
</style>
