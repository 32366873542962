<template>
  <div class="table-cell-input">
    <c-checkbox v-model="internalValue"/>
  </div>
</template>

<script>
import CCheckbox from 'atoms/common/CCheckbox.vue';

export default {
  name: 'VTableCellCheckbox',
  components: { CCheckbox },
  props: {
    value: { type: [String, Boolean], default: false },
    customCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    internalValue: {
      handler() {
        this.$emit('input', this.internalValue);
      },
    },
  },
};
</script>

<style lang="scss">
.table-cell-input {
  display: flex;
  justify-content: center;
  z-index: 1000;
}
</style>
