<template>
  <page title="Договоры энергоснабжения">
    <div class="container">
      <content-block :contracts="contracts"
                     :loading="loading"/>
    </div>
  </page>
</template>

<script>
import ContentBlock from './Contracts/ContentBlock.vue';
import { mapActions } from 'vuex';
import Page from 'components/Page/Page.vue';

export default {
  name: 'CabinetElectricitySupplyContracts',
  components: { Page, ContentBlock },
  created() {
    this.loadData();
  },
  data() {
    return {
      contracts: [],
      disagreements: [],
      loading: false,
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.contracts = await this.loadElectricitySupplyContracts();
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['loadElectricitySupplyContracts']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}
</style>
