import axios from 'axios';
import { BASE_BACKEND_URL } from '@/api/axios/constants';

const baseApi = axios.create({
  baseURL: BASE_BACKEND_URL,
});
const AUTH_TOKEN = 'apollo-token';
baseApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['X-Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
);


// Export the api instance
export default baseApi;
