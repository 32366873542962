<template>
  <div
    :class="{
      ['table__tab']: true,
      [`table__tab-${variant}`]: true,
      [`table__tab-${variant}--active`]: isActive,
    }"
  >
    <div
      :class="{
        ['table__tab-text']: true,
        [`table__tab-${variant}-text`]: true,
      }"
    >
      <slot></slot>
    </div>
    <div v-if="variant === 'filled'">
      <v-table-tab-arrow />
    </div>
  </div>
</template>

<script>
import VTableTabArrow from './VTableTabArrow';

export default {
  name: 'VTableTab',
  components: { VTableTabArrow },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    variant: { type: String, default: 'outlined' },
  },
};
</script>

<style lang="scss" scoped>
.table__tab {
  min-height: 36px;
  max-height: 56px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  &-outlined {
    &--active {
      border-bottom: 2px #2f82df solid;
    }
  }

  &-filled {
    background: rgba(47, 130, 223, 0.1);
    border-radius: 2px 0 0 0;

    svg {
      fill: #71757a;
    }

    &-text {
      margin-right: 15px;
    }

    &--active {
      background: #2f82df;
      color: white;

      svg {
        fill: #ffffff;
      }
    }
  }

  &:hover {
    background-color: #dee2e6;
  }

  &:not(:last-child) {
    border-right: 1px solid #C1C4C7;
  }
}
</style>
