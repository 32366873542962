<template>
  <v-spoiler :summary="'Трансформатор ' + title">
    <div class="transformer-info">
      <v-select
        v-model="internalValue.type"
        :disabled="!isEditing"
        :options="options"
        :title="'Марка трансформатора ' + title + ' *'"
        class="transformer-info__item transformer-info__item--select"
      />

      <v-input
        v-model="internalValue.serialNumber"
        :disabled="!isEditing"
        :title="'Номер трансформатора ' + title + ' *'"
        class="transformer-info__item"
        clearable
      />
    </div>

    <div class="transformer-info">
      <v-input
        v-model="internalValue.accuracyClass"
        :disabled="!isEditing"
        :options="options"
        class="transformer-info__item"
        title="Класс точности *"
      />

      <v-input
        v-if="title === 'тока'"
        v-model="internalValue.transformationRatio"
        :disabled="!isEditing"
        :options="options"
        class="transformer-info__item"
        title="КТТ *"
      />

      <v-input
        v-else
        v-model="internalValue.transformationRatio"
        :disabled="!isEditing"
        :options="options"
        class="transformer-info__item"
        title="КТН *"
      />
    </div>

    <div class="transformer-info">
      <v-input
        v-model="internalValue.productionYear"
        :disabled="!isEditing"
        class="transformer-info__item"
        clearable
        title="Год выпуска *"
      />

      <v-input
        v-model="internalValue.calibrationInterval"
        :disabled="!isEditing"
        class="transformer-info__item"
        clearable
        title="Межповерочный интервал *"
      />
    </div>

    <div class="transformer-info">
      <v-input
        v-model="internalValue.calibrationDate"
        :disabled="!isEditing"
        class="transformer-info__item"
        clearable
        title="Дата поверки *"
      />

      <v-input
        v-model="internalValue.nextCalibrationDate"
        :disabled="!isEditing"
        class="transformer-info__item"
        clearable
        title="Дата следующей поверки"
      />
    </div>
  </v-spoiler>
</template>

<script>
import { VInput, VSelect, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'TransformerInfo',
  components: {
    VInput,
    VSelect,
    VSpoiler,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initData: {
        type: '',
        serialNumber: '',
        accuracyClass: '',
        kkt: '',
        productionYear: '',
        calibrationInterval: '',
        calibrationDate: '',
        nextCalibrationDate: '',

        currentTransformer: {
          type: '',
          serialNumber: '',
        },

        potentialTransformer: {
          type: '',
          serialNumber: '',
        },
      },
      internalValue: {},
      options: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);

        if (this.options.length === 0) {
          this.options = [
            {
              value: this.internalValue.type,
              label: this.internalValue.type,
            },
          ];
        }
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.transformer-info {
  display: flex;
  gap: 16px;

  &__item {
    width: 330px;
    margin-top: 24px;

    &--select {
      margin-top: 48px;
    }

    &--100 {
      width: 100%;
      margin-top: 0;
    }
  }

  img {
    margin-top: 24px;
    cursor: pointer;
  }

  img.follow-arrow {
    margin-top: 48px;
  }
}
</style>
