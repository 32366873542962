<template>
  <transition appear name="bottom">
    <div v-if="visible" :class="$style['container']">
      <div :class="$style['container__content']">
        <input v-model="agree" type="checkbox">
        <h3 :class="$style['container__content__title']">
          Согласен на
          <a href="/policy-new/cookie-usage-agreement.pdf" target="_blank">использование файлов cookie.</a>
        </h3>
        <button :disabled="!agree" @click="agreed">ОК</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CookieNotification',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.setCookies();
  },
  data() {
    return {
      agree: false,
    };
  },
  methods: {
    setCookies() {
      if (localStorage.getItem('agreedCookie') === null) {
        localStorage.setItem('agreedCookie', JSON.stringify(false));
      }
    },
    agreed() {
      localStorage.setItem('agreedCookie', JSON.stringify(true));
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.bottom-enter-active,
.bottom-leave-active
  transition: transform .3s

.bottom-enter,
.bottom-leave-to
  transform: translatey(100%)
</style>

<style lang="scss" module>
.container {
  position: fixed;
  right: 0;
  bottom: 40px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  overflow-y: hidden;

  &__content {
    position: relative;
    display: flex;
    gap: 13px;
    background-color: #fff;
    border: 1px solid #C1C4C7;
    border-radius: 24px;
    padding: 10px 24px;

    &__title {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      margin: auto;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    input {
      margin: auto;
    }

    button {
      margin: auto;
      padding: 6px 25px;
      border: 1px solid #2F82DF;
      border-radius: 24px;
      background: #FFFFFF;
      color: #2F82DF;

      &:disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>
