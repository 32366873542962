<template>
  <div class="settings">
    Настройки - в разработке
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.settings {
  width: 100%;
  padding-right: 16px;
}
</style>
