<template>
  <div :class="$style.container">
    <!--    <p :class="$style.navigationBar">-->
    <!--      <span :class="$style.navigationRoot" @click="close">НАСТРОЙКИ</span>-->
    <!--      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>-->
    <!--      <span>Установка универсального транспортного модуля</span>-->
    <!--    </p>-->

    <div :class="$style.content">
      <div :class="$style.contentCenter">
        <TaskDetails
          :date="task.date"
        />
        <System />
        <Install
          :url="task.utmDownloadUrl"
        />
        <!--        <submit-task-details          -->
        <!--          style="margin-bottom: 20px"-->
        <!--          title="Закрыть"-->
        <!--          :disabled="false"-->
        <!--          :gray="false"-->
        <!--          @submit="close"-->
        <!--        />-->
      </div>
    </div>

  </div>
</template>

<script>
import TaskDetails from 'organisms/tasks/utm-installation/action/TaskDetails';
import System from 'organisms/tasks/utm-installation/action/System';
import Install from 'organisms/tasks/utm-installation/action/Install';

export default {
  name: 'Details',
  components: {
    System,
    TaskDetails,
    Install,
  },
  props: {
    task: Object,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2

.contentCenter
  max-width: 560px
  margin: 0 auto

.navigationBar
  width: 100%
  background-color: #FFFFFF
  z-index: 999
  +title-small-uppercase
  margin: 8px 16px 0 0
  padding-left: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px
</style>
