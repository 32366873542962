<template>
  <div
      ref="tooltip"
    class="tooltip"
    :style="style"
    :class="isPositionXY ? [] : [
      tooltipPositionClass,
      tailTargetClassCommonClass,
      tailTargetClass,
    ]"
  >
    {{ content }}
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      validator(direction) {
        return ['left', 'right', 'bottom', 'top'].includes(direction);
      },
      required: true,
    },
    isTailDirectionNeed: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      required: true,
    },
    positionX: {
      type: Number,
      required: false,
    },
    positionY: {
      type: Number,
      required: false,
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    windowInnerWidth: {
      type: Number,
      required: false,
    },
  },
  created() {
    if (this.isTailDirectionNeed) {
      this.tailDirection = this.setTailDirection(this.position);
    }
  },
  mounted() {
    this.tooltipClientWidth = this.$refs.tooltip.clientWidth;
  },
  data() {
    return {
      tailDirection: '',
      tooltipClientWidth: undefined,
    };
  },
  computed: {
    tailTargetClass() {
      if (!this.isTailDirectionNeed) return '';
      return `tooltip__tail_${this.tailDirection}`;
    },
    tailTargetClassCommonClass() {
      if (!this.isTailDirectionNeed) return '';
      return 'tooltip__tail';
    },
    tooltipPositionClass() {
      return `tooltip_${this.position}`;
    },
    isPositionXY() {
      return this.positionX !== undefined && this.positionY !== undefined;
    },
    style() {
      const result = {};

      if (this.isPositionXY) {
        result.top = `${this.positionY - 56}px`;

        let leftPosition = this.positionX;

        if (this.tooltipClientWidth) {
          const delta = this.windowInnerWidth - this.positionX - this.tooltipClientWidth;

          if (delta < 0) {
            leftPosition = this.positionX + delta;
          }
        }
        result.left = `${leftPosition - 48}px`;
      }

      return { ...result, ...this.customStyle };
    },
  },
  methods: {
    setTailDirection(position) {
      switch (position) {
        case 'top':
          return 'bottom';

        case 'bottom':
          return 'top';

        case 'right':
          return 'left';

        case 'left':
          return 'right';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;

  padding: 8px;
  background-color: #979CA2;
  color: #ffffff;
  border-radius: 6px;
  font-size: 14px;
  white-space: normal;
  z-index: 9999;

    &_left {
      transform: translate(-105%, 0);
    }

    &_right {
      transform: translate(105%, 0);
    }

    &_bottom {
      transform: translate(0, 105%);
    }

    &_top {
      transform: translate(0, -105%);
    }

  &__tail {
    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #979CA2 transparent transparent;
    }

    &_left {
      &::after {
        top: 50%;
        right: 100%;
        margin-top: -5px;
        margin-right: -1px;
      }
    }

    &_right {
      &::after {
        top: 50%;
        left: 100%;
        margin-top: -5px;
        margin-left: -2px;
        transform: rotate(180deg);
      }
    }

    &_bottom {
      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        margin-top: -1px;
        transform: rotate(270deg);
      }
    }

    &_top {
      &::after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        margin-bottom: -1px;
        transform: rotate(90deg);
      }
    }
  }
}
</style>
