<template>
  <page title="Справочник моделей приборов учёта">
    <v-table-new
      v-model="transformedMeteringDevices"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник моделей приборов учёта"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from 'components';
import { mapActions } from 'vuex';
import { convertBooleanToText } from 'lib/utils/formatXmlType';

export default {
  name: 'CabinetMeteringDevices',
  components: {
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      meteringDevices: [],
    };
  },
  computed: {
    transformedMeteringDevices() {
      if (this.meteringDevices.length === 0) return [];

      return this.meteringDevices.map((device) => ({
        model: device.attributes.typeDesignation,
        manufacturer: device.manufacturer.manufacturerName,
        date: device.attributes.publicationDate,
        periodicVerificationAvailability: device.calibrationInterval.years.toString(),
        directConnection: convertBooleanToText(device.directConnection),
      }));
    },
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Модель ПУ',
            key: 'model',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата внесения',
            key: 'date',
            thStyle: {
              minWidth: '73px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Изготовитель',
            key: 'manufacturer',
            sort: {},
            filter: {},
          },
          {
            label: 'Межповерочный интервал, лет',
            key: 'periodicVerificationAvailability',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Прямое включение',
            key: 'directConnection',
            sort: {},
            filter: {
              type: 'select',
              values: ['Да', 'Нет'].map((it) => ({
                value: it,
                label: it,
              })),
            },
          },
        ],
      };
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.meteringDevices = await this.getMeterModels();
      } catch (error) {
        console.log('getMeterModels', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('dataLists', ['getMeterModels']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
