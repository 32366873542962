<template>
  <div>
    <div :class="{ 'consumer-data-selector--table-style': tabStyle === 'table' }" class="consumer-data-selector">
      <button
        v-for="(dataItem, index) in dataTypesList"
        :key="index"
        :class="{
          'consumer-data-selector__data_active': dataItem.isActive,
          'consumer-data-selector__data--table-style': tabStyle === 'table',
          'consumer-data-selector__data--table-style_active': tabStyle === 'table' && dataItem.isActive,
          'consumer-data-selector__data--table-style--disabled': disabled,
        }"
        :style="'z-index: ' + (100 - index + (dataItem.isActive ? 100 : 0))"
        class="consumer-data-selector__data"
        @click.prevent="clickHandler(dataItem.id)"
      >
        <span :class="{'consumer-data-selector__data--table-style-title': tabStyle === 'table'}"
              class="consumer-data-selector__data-title">
          {{ dataItem.title }}
        </span>
      </button>
    </div>
    <hr v-if="tabStyle === 'table'" class="consumer-data-selector__hr"/>
  </div>
</template>

<script>
export default {
  props: {
    dataTypesList: {
      type: Array,
      required: true,
    },
    tabStyle: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler(id) {
      if (this.disabled) {
        return;
      }

      this.setActiveTabById(id);

      const selectedTab = this.dataTypesList.find((dataItem) => dataItem.id === id);
      this.$emit('selectTab', { ...selectedTab });
    },
    setActiveTabById(id) {
      const clickedIndex = this.dataTypesList.findIndex(((dataItem) => dataItem.id === id));

      this.dataTypesList.forEach((dataItem, index) => {
        dataItem.isActive = index === clickedIndex;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-data-selector {
  &--table-style {
    display: flex;
    width: 100%;

    @media (max-width: 860px){
      display: block;
    }
  }

  &__hr {
    width: 100%;
    height: 0.5px;
    background-color: #2F82DF;
    border: 0;
    z-index: 100;
    position: relative;
    margin: -1px 0 0;
  }

  &__data {
    display: inline-block;

    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: #C1C4C7;

    outline: none;
    background: transparent;

    @media (max-width: 860px){
      width: 100%;
    }

    &_active {
      color: #4C4E51;
      border-bottom: 2px solid #2F82DF;
    }

    &-title {
      display: flex;
      margin: 0 24px 21px;
    }

    &--table-style:first-child {
      margin-left: 0;
    }

    &--table-style {
      display: block;
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      min-width: 92px;

      background: #F5F6F6;
      border: 0.5px solid #C1C4C7;
      border-bottom: 0;
      border-radius: 15px 15px 0 0;

      margin-left: -10px;
      cursor: pointer;

      @media (max-width: 860px){
        margin-left: 0;
      }

      &_active {
        background: #FFFFFF;
        color: #2F82DF;
        border: 0.5px solid #2F82DF;
        border-bottom: 0.5px solid #FFFFFF;
        font-weight: 500;
        cursor: default;
      }

      &-title {
        margin: 8px 20px 8px 20px;
      }

      &--disabled {
        cursor: default;
      }
    }
  }
}
</style>
