<template>
  <div class="button-dropdown">
    <div class="button-dropdown__button-wrp">
      <button v-if="selectedOption" class="button-dropdown__button" @click="clickHandler">
        {{ selectedOption.label }}
      </button>
    </div>
    <div class="button-dropdown__dropdown-wrp">
      <select v-model="selectedOption" class="button-dropdown__dropdown">
        <option v-for="option in options" :key="option.value" :value="option">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    if (this.options.length === 0) {
      throw 'Empty options array';
    }

    this.selectedOption = this.options[0];
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  methods: {
    clickHandler() {
      this.$emit('on-button-click', this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-dropdown {
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  &__button {
    height: 100%;
    color: #ffffff;
    padding: 10px 10px 10px 25px;
    background-color: #2f82df;
    border-radius: 10px 0 0 10px;
  }

  &__dropdown {
    background-color: #2f82df;
    color: #ffffff;
    border-radius: 0 10px 10px 0;
    border: 0;
    border-left: 1px solid #fff;
    outline: none;
    width: 17px;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
