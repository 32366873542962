<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="back">{{ $route.params.fromTitle }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ $route.params.title }}</span>
    </p>

    <div :class="$style.content">
      <p :class="$style.stepTitle">Детали задачи</p>

      <p v-if="$route.params.meterSerial" :class="$style.stepDate">Номер прибора учёта: {{ $route.params.meterSerial }}</p>
    </div>

    <div :class="$style.content">
      <c-uploading
        v-model="documentsScan"
        label="Загрузите акт осмотра для прибора учёта"
      />

      <div v-if="documentsScan.length">
        <file-list-preview :list="documentsScan" action
                           @delete="handleRemoveDocumentsScan" />
      </div>

      <submit-task-details :class="{[$style.submit]: true}"
                           :disabled="$route.params.completed || !isValid || isWaitingSubmit"
                           title="Загрузить" @submit="submit" />
      <horizontal-progress-bar v-if="isWaitingSubmit" />
    </div>
    <div :class="$style.contentFooter">
      <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left />
        <span>Назад</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';
import generateXml from 'lib/storage/connection-application/data';
import { VButton } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';

export default {
  name: 'InspectionReportUpload',
  components: {
    ChevronLeft,
    VButton,
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    FileListPreview: () => import('molecules/upload/form/FileListPreview.vue'),
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
      documentsScan: [],
    };
  },
  computed: {
    isValid() {
      return this.documentsScan.length > 0;
    },
  },
  methods: {
    async submit() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      try {
        // TODO: upload is not implemented
        const xmlScan = generateXml.DocumentScan(
          this.documentsScan,
          this.$route.params.documentHash,
          this.$route.params.documentXmlType,
        );

        const result = await this.documentUpload({ xml: xmlScan });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.back();
        }
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    handleRemoveDocumentsScan(file) {
      this.documentsScan = this.documentsScan
        .filter((scan) => scan.source !== file.source);
    },
    back() {
      this.$router.push(`/cabinet/${this.$route.params.from}`);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  height: 90%

.content
  width: 560px
  margin: 32px auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

  .stepDate
    +table-text-small
    margin-bottom: 34px

    span
      +table-data-small
      margin-left: 116px

.submit
  margin-top: 32px

.errorText
  +small-text
  margin: 32px 4px -28px 4px
  color: red

.navigationBar
  width: 100%
  background-color: #FFFFFF
  z-index: 1006
  // more than modal z-index
  +title-small-uppercase
  margin: 8px 16px 0 0
  padding-left: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px

.contentFooter
  height: 48px
  display: flex

.back
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  padding: 19px

  svg
    fill: #FFFFFF

.center
  flex-grow: 2

.back
  background: #ffffff

  svg
    path
      fill: #2F82DF
</style>
