import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  suppliers: [],
  objectTypeList: [
    {
      label: 'Объект на земельном участке',
      value: 'Объект на земельном участке',
    },
    { label: 'Нежилое помещение', value: 'Нежилое помещение' },
    { label: 'Жилой дом', value: 'Жилой дом' },
    { label: 'Жилое строение', value: 'Жилое строение' },
    {
      label: 'Комплекс объектов на земельном участке',
      value: 'Комплекс объектов на земельном участке',
    },
    { label: 'Иное:', value: 'Иное' },
  ],
  energyConsumerCountList: [{ label: 'I точка', value: 1 }],
  phaseVoltageList: [
    { label: '220 В (однофазное подключение)', value: 220 },
    { label: '380 В (трёхфазное подключение)', value: 380 },
    { label: '6 кВ (трёхфазное подключение)', value: 6000 },
    { label: '10 кВ (трёхфазное подключение)', value: 10000 },
    { label: '20 кВ (трёхфазное подключение)', value: 20000 },
  ],
  reliabilityClassListWithShortenedNames: [
    { label: 'III', value: 'III' },
    { label: 'II', value: 'II' },
    { label: 'I', value: 'I' },
  ],
  reliabilityClassList: [
    { label: 'III категория', value: 'III' },
    { label: 'II категория', value: 'II' },
    { label: 'I категория', value: 'I' },
  ],
  reliabilityClassMiddleList: [
    { label: 'III категория', value: 'III' },
    { label: 'II категория', value: 'II' },
  ],
  reliabilityClassShortList: [
    { label: 'III категория', value: 'III' },
  ],
  workloadList: [
    { label: 'Бытовая', value: 'HOUSEHOLD' },
    { label: 'Производственная', value: 'PRODUCTION' },
    { label: 'Совмещенная', value: 'COMBINED' },
  ],
  supplierOfLastResortList: [],
  gridCompanyList: [],
  currentUtilityCompany: [],
  confirmationDocumentTitleList: [
    { label: 'Выписка из ЕГРН', value: 'Выписка из ЕГРН' },
    {
      label: 'Свидетельство о праве собственности',
      value: 'Свидетельство о праве собственности',
    },
    {
      label: 'Свидетельство о праве наследства',
      value: 'Свидетельство о праве наследства',
    },
    { label: 'Договор купли-продажи', value: 'Договор купли-продажи' },
    { label: 'Иное основание...', value: 'Иное основание...' },
  ],
  voltageLevelList: [
    { value: 'vn', label: 'Высокое напряжение' },
    { value: 'sn1', label: 'Среднее первое напряжение' },
    { value: 'sn2', label: 'Среднее второе напряжение' },
    { value: 'hh', label: 'Низкий уровень напряжения' },
  ],
  typeAccountingList: [
    { label: 'Р', value: 'accounting', label2: 'Расчётный' },
    { label: 'К', value: 'control', label2: 'Контрольный' },
  ],
  typeAccountingMethodList: [
    { value: 'power', label: 'По мощности' },
    { value: 'const', label: 'По постоянному расходу' },
    { value: 'variable', label: 'По переменному расходу' },
    { value: 'previous', label: 'По факту прошлого периода' },
  ],
  typeAccountingLabelList: [
    { label: 'Месячный', value: 'MONTHLY' },
    { label: 'Зонный', value: 'ZONE' },
    { label: 'Почасовой', value: 'HOURLY' },
  ],
  typeAccountingListLong: [
    { label: 'Р', value: 'accounting', label2: 'Расчётный' },
    { label: 'К', value: 'control', label2: 'Контрольный' },
    { label: 'Т', value: 'transit', label2: 'Транзитный' },
    { label: 'Расчётный', value: 'accounting' },
    { label: 'Контрольный', value: 'control' },
    { label: 'Транзитный', value: 'transit' },
    { label: 'транзитный', value: 'transit' },
    { label: 'контрольный', value: 'control' },
    { label: 'расчётный', value: 'accounting' },
    { label: 'расчетный', value: 'accounting' },
  ],
  ownerAccountingFacilitiesList: [
    { label: 'И', value: 'I' },
    { label: 'З', value: 'S' },
    { label: 'П', value: 'P' },
    { label: 'И1', value: 'I1' },
    { label: 'И2', value: 'I2' },
    { label: 'И-1', value: 'I1' },
    { label: 'И-2', value: 'I2' },
    { label: 'ССО', value: 'SSO' },
    { label: 'ПЭ', value: 'PE' },
    { label: 'ТСО', value: 'ТСО' },
  ],
  voltageClassList: [
    { value: 'vn', label: 'ВН' },
    { value: 'sn1', label: 'СН1' },
    { value: 'sn2', label: 'СН2' },
    { value: 'hh', label: 'НН' },
  ],
  voltageClassListRu: [
    { value: 'BH', label: 'ВН' },
    { value: 'CH1', label: 'СН1' },
    { value: 'CH2', label: 'СН2' },
    { value: 'HH', label: 'НН' },
  ],
  subscriberStateList: [
    { value: 'N', label: 'Н' },
    { value: 'K', label: 'К' },
    { value: 'R', label: 'Р' },
    { value: 'C', label: 'С' },
    { value: 'existing', label: 'Действующий' },
    { value: 'existing', label: 'действующий' },
  ],
  meterStateList: [
    { value: 'N', label: 'Н' },
    { value: 'K', label: 'К' },
    { value: 'R', label: 'Р' },
    { value: 'L', label: 'Л' },
  ],
  appliedTariffList: [
    { value: 'N', label: 'Н' },
    { value: 'D', label: 'Д' },
    { value: 'P', label: 'П' },
    { value: 'PP', label: 'П/П' },
    { value: 'K', label: 'К' },
  ],
  pointsKindList: [
    {
      label: 'Приём',
      value: 'receiving',
    },
    {
      label: 'Поставка',
      value: 'delivery',
    },
    {
      label: 'Передача',
      value: 'transfer',
    },
    {
      label: 'Техническая',
      value: 'technical',
    },
  ],
  measurementsReadingKindList: [
    { value: 'ISU', label: 'А' },
    { value: 'MANUAL', label: 'Р' },
    { value: 'POWER', label: 'По мощности' },
    { value: 'CONST', label: 'По постоянному расходу' },
    { value: 'VARIABLE', label: 'По переменному расходу' },
    { value: 'PREVIOUS', label: 'По факту прошлого периода' },
  ],
  tariffCategoryTypeList: [
    { value: 'TARIFF_CATEGORY_1', label: '1 ценовая категория' },
    { value: 'TARIFF_CATEGORY_2', label: '2 ценовая категория' },
    { value: 'TARIFF_CATEGORY_3', label: '3 ценовая категория' },
    { value: 'TARIFF_CATEGORY_4', label: '4 ценовая категория' },
    { value: 'TARIFF_CATEGORY_5', label: '5 ценовая категория' },
    { value: 'TARIFF_CATEGORY_6', label: '6 ценовая категория' },
  ],
  tariffCategoryTypeListNumber: [
    { value: 'TARIFF_CATEGORY_1', label: 'По умолчанию' },
    { value: 'TARIFF_CATEGORY_1', label: '1' },
    { value: 'TARIFF_CATEGORY_2', label: '2' },
    { value: 'TARIFF_CATEGORY_3', label: '3' },
    { value: 'TARIFF_CATEGORY_4', label: '4' },
    { value: 'TARIFF_CATEGORY_5', label: '5' },
    { value: 'TARIFF_CATEGORY_6', label: '6' },
  ],
  tariffCategoryTypeShortList: [
    { value: 'TARIFF_CATEGORY_1', label: '1 ценовая категория' },
    { value: 'TARIFF_CATEGORY_2', label: '2 ценовая категория' },
    { value: 'TARIFF_CATEGORY_3', label: '3 ценовая категория' },
    { value: 'TARIFF_CATEGORY_4', label: '4 ценовая категория' },
    { value: 'TARIFF_CATEGORY_5', label: '5 ценовая категория' },
    { value: 'TARIFF_CATEGORY_6', label: '6 ценовая категория' },
  ],
  consumerCategoryList: [
    { value: 'category-1', label: '1' },
    { value: 'category-2', label: '2' },
    { value: 'category-3', label: '3' },
    { value: 'category-4', label: '4' },
    { value: 'category-5', label: '5' },
    { value: 'category-6', label: '6' },
    { value: 'category-7', label: '7' },
    { value: 'category-8', label: '8' },
    { value: 'category-9', label: '9' },
    { value: 'category-10', label: '10' },
    { value: 'category-11', label: '11' },
  ],
  tariffZoneTypeList: [
    { value: 'TWO_ZONE', label: 'День/Ночь' },
    { value: 'THREE_ZONE', label: 'Пик/Полупик/Ночь' },
  ],
  accountingMethodList: [
    { value: 'measurements', label: 'По показаниям' },
    { value: 'power', label: 'По мощности' },
    { value: 'const', label: 'По постоянному расходу' },
    { value: 'variable', label: 'По переменному расходу' },
    { value: 'previous', label: 'По факту прошлого периода' },
    { value: 'adjustment', label: 'Корректировка' },
  ],
  relatedOrganizationList: [
    { value: 'RetailElectricityMarket', label: 'Потребитель РРЭ' },
    { value: 'WholesaleElectricityMarket', label: 'Потребитель ОРЭ' },
    { value: 'SupplierOfLastResort', label: 'ГП' },
    { value: 'ESO', label: 'ЭСО' },
    { value: 'Consumer', label: 'ПЭЭ' },
  ],
  documentTypeList: [
    { value: 'PASSPORT_RF', label: 'Паспорт гражданина РФ' },
    { value: 'RESIDENCE_PERMIT_RF', label: 'Вид на жительство' },
    { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
    { value: 'OTHER', label: 'Иное' },
  ],
  connectionApplicationTypeList: [
    {
      value: 'INDIVIDUALS_UP_TO_15',
      label: 'Заявление на технологическое присоединение по одному источнику электроснабжения к электрическим сетям сетевой организации заявителей: физические лица, максимальная мощность до 15 кВт, по III категории надежности или увеличение мощности до 15 кВт (используемых для бытовых и иных нужд, не связанных с осуществлением предпринимательской деятельности)',
    },
    {
      value: 'INDIVIDUALS_OVER_15',
      label: 'Заявление на технологическое присоединение к электрическим сетям сетевой организации заявителей: физические лица, максимальная мощность свыше 15 кВт, II и III категория надежности или увеличение мощности свыше 15 кВт',
    },
    {
      value: 'LEGAL_ENTITIES_UP_TO_150',
      label: 'Заявление на технологическое присоединение к электрическим сетям сетевой организации заявителей: юридические лица (индивидуальные предприниматели), максимальная мощность до 150 кВт, II и III категория надежности или увеличение мощности свыше 150 кВт',
    },
    {
      value: 'LEGAL_ENTITIES_OVER_150',
      label: 'Заявление на технологическое присоединение к электрическим сетям сетевой организации заявителей свыше максимальная мощность свыше 150 кВт или увеличение мощности свыше 150 кВт',
    },
    { value: 'POWER_REDUCTION', label: 'Заявление на уменьшение максимальной мощности' },
    {
      value: 'MICROGENERATION',
      label: ' Заявление на технологическое присоединение объектов микрогенерации или на перевод объектов в статус микрогенерации',
    },
    {
      value: 'DOCUMENTS_RESTORATION',
      label: 'Заявление на восстановление и переоформление документов о технологическом присоединении',
    },
    { value: 'POWER_REDISTRIBUTION', label: 'Заявление на перераспределение мощности' },
    {
      value: 'TEMPORARY_CONNECTION',
      label: 'Заявление на временное технологическое присоединение к электрическим сетям сетевой организации',
    },
  ],
  pointsKindFullList: [
    { value: 'ACCOUNTING', label: 'Расчётный' },
    { value: 'CONTROL', label: 'Контрольный' },
    { value: 'EXPENDABLE', label: 'Расходный' },
    { value: 'PROFITABLE', label: 'Доходный' },
  ],
  measurementsReadingKindFullList: [
    { value: 'MANUAL', label: 'Ручная загрузка' },
    { value: 'ISU', label: 'Автоматическая загрузка (через ИСУ)' },
    { value: 'EIGHTEENTH_FORM', label: '18 формы' },
    { value: 'REPLACEMENT', label: 'Замещающая информация' },
    { value: 'ADJUSTMENT', label: 'Корректировка' },
    { value: 'ACCOUNTING_METHOD', label: 'Расчётный метод' },
    { value: 'NON_ACCOUNTED', label: 'Безучётное потребление' },
    { value: 'OTHER', label: 'Другое' },
  ],
  accountKindList: [
    { value: 'INDIVIDUAL_CONSUMER', label: 'Потребитель физическое лицо', name: 'Потребитель физическое лицо' },
    { value: 'LEGAL_CONSUMER', label: 'Потребитель юридическое лицо', name: 'Потребитель юридическое лицо' },
    { value: 'UTILITY', label: 'Сетевая компания', name: 'Сетевая компания' },
    { value: 'BOILER', label: 'Котлодержатель', name: 'Котлодержатель' },
    { value: 'RETAILER', label: 'Сбытовая компания', name: 'Сбытовая компания' },
    { value: 'SUPPLIER_OF_LAST_RESORT', label: 'Гарантирующий поставщик', name: 'Гарантирующий поставщик' },
    { value: 'PLATFORM', label: 'Техническая поддержка', name: 'Техническая поддержка' },
  ],
  paymentTariffsList: [
    {
      value: 'BOILER',
      label: 'Котловые тарифы',
    },
    {
      value: 'INDIVIDUAL',
      label: 'Индивидуальные тарифы',
    },
    {
      value: 'LOSS',
      label: 'Цена на электроэнергию для компенсации потерь',
    },
  ],
  paymentKindList: [
    {
      value: 'PRE_PAYMENT',
      label: 'Авансовый',
    },
    {
      value: 'MAIN_PAYMENT',
      label: 'Основной',
    },
  ],
  meterTariffTypeList: [
    {
      label: 'T1',
      value: 'Т1',
    },
    {
      label: 'T2',
      value: 'Т2',
    },
    {
      label: 'T3',
      value: 'Т3',
    },
    {
      label: 'T4',
      value: 'Т4',
    },
    {
      label: 'T5',
      value: 'Т5',
    },
    {
      label: 'T6',
      value: 'Т6',
    },
    {
      label: 'T7',
      value: 'Т7',
    },
    {
      label: 'T8',
      value: 'Т8',
    },
    {
      label: 'T9',
      value: 'Т9',
    },
    {
      label: 'T10',
      value: 'Т10',
    },
  ],
  sealPlaceList: [
    {
      label: 'Клеммная крышка ПУ',
      value: 'TERMINAL_COVER',
    },
    {
      label: 'Интерфейс, защитная крышка',
      value: 'PROTECTIVE_COVER',
    },
    {
      label: 'ИКК',
      value: 'IKK',
    },
    {
      label: 'ТТ Фаза A',
      value: 'TT_A',
    },
    {
      label: 'ТТ Фаза B',
      value: 'TT_B',
    },
    {
      label: 'ТТ Фаза C',
      value: 'TT_C',
    },
    {
      label: 'ТН Фаза A',
      value: 'TN_A',
    },
    {
      label: 'ТН Фаза B',
      value: 'TN_B',
    },
    {
      label: 'ТН Фаза C',
      value: 'TN_C',
    },
  ],
  fixtureStageList: [
    {
      label: 'Заявка создана',
      value: 'submitted',
    },
    {
      label: 'Заявка отклонена сетевой',
      value: 'utility_remarks',
    },
    {
      label: 'Заявка исправлена',
      value: 'submitted_after_utility_remarks',
    },
    {
      label: 'Заявка одобрена сетевой',
      value: 'approved_by_utility',
    },
    {
      label: 'Загружен договор тех. приса со стороны сетевой',
      value: 'utility_offer_submitted',
    },
    // {
    //   label: 'Замечания к договору тех. приса',
    //   value: 'utility_offer_remarks',
    // },
    // {
    //   label: 'Договор тех. приса исправлен сетевой',
    //   value: 'utility_offer_submitted_after_remarks',
    // },
    // {
    //   label: 'Договор тех. приса подписан потребителем',
    //   value: 'utility_offer_approved',
    // },
    {
      label: 'Тех. прис оплачен',
      value: 'paid',
    },
    {
      label: 'Акт тех.условий загружен',
      value: 'technical_conditions',
    },
    {
      label: 'Замечания к заявке от сбыта',
      value: 'remarks_by_retail',
    },
    {
      label: 'Загружены исправления для сбыта',
      value: 'submitted_after_retail_remarks',
    },
    {
      label: 'Заявка одобрена сбытом',
      value: 'approved_by_retail',
    },
    {
      label: 'Загружен ДЭС',
      value: 'retail_offer_submitted',
    },
    {
      label: 'Замечания к ДЭС',
      value: 'retail_offer_remarks',
    },
    {
      label: 'Исправлен ДЭС',
      value: 'retail_offer_submitted_after_remarks',
    },
    {
      label: 'ДЭС подписан потребителем',
      value: 'retail_offer_approved',
    },
    {
      label: 'Загружен АДПУ',
      value: 'admission_certificate',
    },

    {
      label: 'АДПУ подписан потребителем (для ЮЛ)',
      value: 'admission_certificate_signed',
    },
    {
      label: 'Загружен АТП',
      value: 'connection_certificate',
    },
  ],
  reasonToConnectionList: [
    {
      label: 'Новое технологическое присоединение',
      value: 'NEW_TECHNOLOGICAL_CONNECTION',
    },
    {
      label: 'Увеличение мощности',
      value: 'POWER_INCREASE',
    },
  ],
  connectionApplicationWithContractTypeList: [
    {
      label: 'Договор энергоснабжения',
      value: 'ELECTRICITY_SUPPLY_CONTRACT',
    },
    {
      label: 'Договор купли-продажи электрической энергии',
      value: 'ELECTRICITY_PURCHASE_CONTRACT',
    },
  ],
  odkDefectStatus: [
    {
      label: 'Создано',
      value: 'ON_INSPECTION',
    },
    {
      label: 'Отправлено',
      value: 'INSPECTED',
    },
    {
      label: 'Выполнено',
      value: 'AFTER_REPAIR',
    },
    {
      label: 'Закрыто',
      value: 'NOT_FOUND',
    },
  ],
  shiftList: [
    { value: 'DAY', label: 'Дневная' },
    { value: 'NIGHT', label: 'Ночная' },
    { value: 'AROUND', label: 'Круглосуточная' },
  ],
  substationKindList: [
    {
      label: 'Питающий центр',
      value: 'MAIN_SUBSTATION',
    },
    {
      label: 'Трансформаторная подстанция',
      value: 'TRANSFORMATION_SUBSTATION',
    },
    {
      label: 'Распределительная подстанция',
      value: 'DISTRIBUTION_SUBSTATION',
    },
    {
      label: 'Иное',
      value: 'OTHER',
    },
  ],
  regionList: [
    { value: 'MSK', label: 'Москва' },
    { value: 'MO', label: 'Московская область' },
  ],
  tariffOrderList: [
    { value: 'INDIVIDUAL_TARIFFS', label: 'Индивидуальные тарифы' },
    { value: 'PEAK_LOAD_HOURS', label: 'Плановые часы пиковой нагрузки' },
    { value: 'UNIFIED_ENERGY_TARIFFS', label: 'Объединенные энергетические тарифы' },
    { value: 'TARIFF_RATE_EXPENSES', label: 'Расходы по тарифной ставке' },
    { value: 'TARIFF_RATE_MAINTENANCE', label: 'Тарифная ставка' },
    { value: 'LOSS_NORMATIVES', label: 'Нормативы потерь электрической энергии' },
    { value: 'INFRASTRUCTURE_PAYMENTS', label: 'Инфраструктурные платежи' },
    { value: 'TARIFF_LOSSES', label: 'Нерегулируемые цены на электрическую энергию' },
    { value: 'BOILER_TARIFFS', label: 'Котловые тарифы' },
  ],

  chatFaqMessages: [
    {
      q: 'Способ подачи заявки (Как подать заявку)',
      a: 'Заявку на технологическое присоединение можно подать:\n' +
        '- на платфоме «Мой свет» svet.uneco.ru\n' +
        '- на официальном сайте АО «ОЭК» <a href="https://itp.uneco.ru" target="_blank">https://itp.uneco.ru</a>\n' +
        '- на официальном портале Мэра и Правительства Москвы <a href="https://www.mos.ru" target="_blank">https://www.mos.ru</a>\n' +
        '- очно в Центрах обслуживания клиентов по адресам: Москва, Раушская набережная, д. 8 (режим работы: понедельник-пятница с 08:00 до 17:00);  г. Троицк, ул. Полковника милиции Курочкина, д. 8  (режим работы: понедельник-пятница с 08:00 до 17:00, обед с 12:00-13:00) <a href="https://itp.uneco.ru" target="_blank">https://uneco.ru/klientam/centry-obsluzhivaniya-klientov</a>\n' +
        '- по почте: 115035, г. Москва, Раушская набережная, д. 8',
    },
    {
      q: 'Где можно получить консультацию по технологическому присоединению',
      a: 'Консультацию по технологическому присоединению можно плучиь: \n' +
        '- по телефону: (495) 657-90-95 \n' +
        '- онлайн-консультант на официальном сайте АО «ОЭК» <a href="https://itp.uneco.ru/_layouts/15/oek.lktp/public/default.aspx?ReturnUrl=%2f_layouts%2f15%2fAuthenticate.aspx%3fSource%3d%252F&Source=%2F" target="_blank">https://itp.uneco.ru</a>\n' +
        '- направить обращение на электронную почту: connection@uneco.ru\n' +
        '- очно в Центрах обслуживания клиентов по адресам: Москва, Раушская набережная, д. 8 (режим работы: понедельник-пятница с 08:00 до 17:00);  г. Троицк, ул. Полковника милиции Курочкина, д. 8  (режим работы: понедельник-пятница с 08:00 до 17:00, обед с 12:00-13:00)\n' +
        '<a href="https://uneco.ru/klientam/centry-obsluzhivaniya-klientov" target="_blank">https://uneco.ru/klientam/centry-obsluzhivaniya-klientov</a>\n',
    },
    {
      q: 'Какие нормативные документы регламентируют порядок технологического присоединения',
      a: '- Федеральный закон от 26.03.2003 № 35-ФЗ «Об электроэнергетике»;\n' +
        '- Правила технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденными Постановлением Правительства Российской Федерации от 27.12.2004 № 861',
    },
    {
      q: 'Перечень документов необходимых для подачи заявки',
      a: 'Заявка с комплектом документов оформляется в соответствии с пунктом 9, 10 Правил технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденными Постановлением Правительства Российской Федерации от 27.12.2004 № 861\n' +
        'С перечнем документов можно ознакомиться на официальном сайте АО «ОЭК»: <a href="https://uneco.ru/klientam/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam" target="_blank">https://uneco.ru/klientam/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam</a>',
    },
    {
      q: 'Как определить в какую электросетевую компанию необходимо подать заявку на технологическое присоединение',
      a: '     Заявитель направляет заявку в сетевую организацию, объекты электросетевого хозяйства которой расположены на наименьшем расстоянии от границ участка заявителя.\n' +
        '     Если на расстоянии менее 300 метров от границ участка заявителя (500 метров для сельской местности) находятся объекты электросетевого хозяйства нескольких сетевых организаций, заявитель вправе направить заявку в любую из них. Эти положения не распространяются на заявителей, имеющих намерение осуществить технологическое присоединение энергопринимающих устройств по индивидуальному проекту.\n' +
        '     Заявитель вправе направить запрос в орган местного самоуправления, на территории которого расположены соответствующие объекты электросетевого хозяйства, с указанием расположения объектов электросетевого хозяйства, принадлежность которых необходимо определить, а орган местного самоуправления обязан предоставить заявителю в течение 15 дней информацию о принадлежности указанных в запросе объектов электросетевого хозяйства.\n' +
        '     Заявки на увеличение ранее присоединенной мощности, переоформление (подтверждение) мощности подаются в электросетевые организации, к объектам электросетевого хозяйства которых присоединены энергопринимающие устройства заявителей.',
    },
    {
      q: 'Как рассчитать стоимость технологического присоединения (ТП)',
      a: '   Размер платы за технологическое присоединение к электрическим сетям электросетевой организации подлежит государственному регулированию, устанавливается Департаментом экономической политики и развития города Москвы на соответствующий год.\n' +
        '   Стоимость услуги рассчитывается в рамках подготовки оферты договора об осуществлении технологического присоединения конкретного объекта, исходя из подготовленных по результатам рассмотрения заявки технических условий.\n' +
        '   Ознакомиться с документами, определяющими порядок расчета стоимости услуги по технологическому присоединению, можно на официальном сайте АО «ОЭК»: <a href="https://uneco.ru/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam/plata-za-tehnologicheskoe-prisoedinenie-k" target="_blank">https://uneco.ru/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam/plata-za-tehnologicheskoe-prisoedinenie-k</a>\n' +
        '   Произвести предварительный расчет стоимости услуги по технологическому присоединению к электросетям АО «ОЭК» можно с помощью Единого калькулятора технологического присоединения в разделе «Портал технологических присоединений»: <a href="https://itp.uneco.ru/_layouts/15/oek.lktp/Calculator.aspx" target="_blank">https://itp.uneco.ru/_layouts/15/oek.lktp/Calculator.aspx</a>',
    },
    {
      q: 'Срок выполнения мероприятий по технологическому присоединению',
      a: 'Срок выполнения мероприятий по технологическому присоединению регламентирован  Правилами технологического присоединения энергопринимающих устройств потребителей электрической энергии, объектов по производству электрической энергии, а также объектов электросетевого хозяйства, принадлежащих сетевым организациям и иным лицам, к электрическим сетям, утвержденными Постановлением Правительства Российской Федерации от 27.12.2004 № 861 (далее - Правила).\n' +
        'Срок выполнения мероприятий указывается:\n' +
        '- в ТУ (для заявителей, указанных в пунктах <span style="color:red">12(1), 13(2) - 13(5) и 14</span> Правил)\n' +
        '- в договоре об осуществлении технологического присоединения (для заявителей, не попадающих под критерии, указанные в пунктах <span style="color:red">12(1), 13(2) - 13(5) и 14</span> Правил)\n',
    },
    {
      q: 'Как подать письменное обращение по вопросу технологического присоединения',
      a: 'Обращение по вопросу технологического присоединения можно направить:\n' +
        '- на платфоме «Мой свет»\n' +
        '- на официальном сайте АО «ОЭК» <a href="https://itp.uneco.ru" target="_blank">https://itp.uneco.ru</a>\n' +
        '- направить обращение на электронную почту: connection@uneco.ru\n' +
        '- очно в Центрах обслуживания клиентов по адресам: Москва, Раушская набережная, д. 8 (режим работы: понедельник-пятница с 08:00 до 17:00);  \n' +
        'г. Троицк, ул. Полковника милиции Курочкина, д. 8  (режим работы: понедельник-пятница с 08:00 до 17:00, обед с 12:00-13:00) <a href="https://uneco.ru/klientam/centry-obsluzhivaniya-klientov" target="_blank">https://uneco.ru/klientam/centry-obsluzhivaniya-klientov</a>\n' +
        '- по почте: 115035, г. Москва, Раушская набережная, д. 8',
    },
    {
      q: 'Основные этапы и сроки рассмотрения заявок на технологическое присоединение к электрическим сетям сетевой организации',
      a: 'С основными этапами и сроками рассмотрения заявок на технологическое присоединение к электрическим сетям сетевой организации можно ознакомиться на официальном сайте АО «ОЭК»:\n' +
        '<a href="https://uneco.ru/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam/osnovnye-etapy-i-sroki-rassmotreniya-zayavo-0" target="_blank">https://uneco.ru/tehnologicheskoe-prisoedinenie-k-elektricheskim-setyam/osnovnye-etapy-i-sroki-rassmotreniya-zayavo-0</a>',
    },
    {
      q: 'Использование и настройка ЭЦП в ЛК (ПЭП, КЭП)',
      a: 'По вопросу работы с использованием ЭЦП (ПЭП, КЭП) вы можете ознакомиться в инструкции на платфоме «Мой свет»',
    },
  ],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
