<template>
  <div class="xml-contract">
    <div class="xml-contract-bar">
      <div class="xml-contract-bar-left">
        <h1 class="xml-contract-bar__title">
          {{ contractPatternName }}
        </h1>
      </div>
      <div class="xml-contract-bar-right">
        <button class="button button--grey"
                @click.prevent="isDisplayCloseAll ? triggerCloseAll = !triggerCloseAll : triggerOpenAll = !triggerOpenAll"
        >
          <span class="button-inner">
            <img v-if="isDisplayCloseAll" alt="" src="@/assets/icons/arrows/arrow-up.svg">
            <img v-else alt="" src="@/assets/icons/arrows/arrow-down.svg">
            <span class="button__text">
              <span v-if="isDisplayCloseAll">Свернуть</span><span v-else>Развернуть</span>&nbsp;всё
            </span>
          </span>
        </button>
      </div>
    </div>
    <div class="xml-contract__table">
      <xml-contract-last-change :close-all="triggerCloseAll" :open-all="triggerOpenAll" :template="template"
                                :xml="finallyXml"
                                @allClosed="allClosed"/>
    </div>

  </div>
</template>

<script>
import { XmlContractLastChange } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import { getSurnameAndInitials } from '@/utils/user';
import { checkField } from '@/utils/common';
import { formHeaderBySides } from 'services/contractXml';

export default {
  components: {
    XmlContractLastChange,
  },
  async created() {
    await this.processTemplate();
  },
  data() {
    return {
      tooltipText: 'Просмотр шаблона',
      isDisplayCloseAll: true,
      triggerCloseAll: true,
      triggerOpenAll: true,
    };
  },
  computed: {
    contractPatternName() {
      return this.getPatternName;
    },
    template() {
      if (this.getContract?.selectedTemplate?.xmlContent) {
        return this.getContract.selectedTemplate.xmlContent;
      }
      return '';
    },
    finallyXml() {
      if (this.getContract?.finallyContractXml) {
        return this.getContract.finallyContractXml;
      }
      return '';
    },
    editedTemplate() {
      if (this.getContract?.editedSelectedTemplateXml) {
        return this.getContract.editedSelectedTemplateXml;
      }
      return '';
    },
    contractSides() {
      return this.getContract?.editableSides ?? {};
    },
    sourceContractInfo() {
      return this.getContract?.sourceContractNumber ? {
        number: this.getContract?.sourceContractNumber,
        date: this.getContract?.sourceContractDate,
      } : null;
    },
    selectedEmployees() {
      const selectedEmployees = [];
      if (this.getContract?.userEmployeesContractMembers) {
        this.getContract.userEmployeesContractMembers.forEach((employee) => {
          selectedEmployees.push({
            position: `${checkField(employee?.role?.name)}: `,
            name: getSurnameAndInitials(employee?.user?.fullName),
          });
        });
      }
      return selectedEmployees;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getPatternName']),
  },
  methods: {
    async processTemplate() {
      const contract = { xml: { ...this.editedTemplate } };
      const sides = this.contractSides;
      for (const sidesKey in sides) {
        if (!sides[sidesKey].signatory.fullName.name?.length) continue;
        sides[sidesKey].signatory.fullNameGenitive = await this.getPersonInCaseAction({
          fio: sides[sidesKey].signatory.fullName,
          inCase: 'Genitive',
        });
        sides[sidesKey].signatory.role.nameGenitive = await this.getWordInCaseAction({
          word: sides[sidesKey].signatory.role.name,
          inCase: 'Genitive',
        });
        sides[sidesKey].signatory.reason.reasonDocumentGenitive = sides[sidesKey].signatory.reason.reasonDocument === 'Устав' ? 'Устава' : 'Доверенности';
      }
      contract.xml.contractHeader = formHeaderBySides(sides, this.sourceContractInfo);
      await this.setFinallyContractXml(contract);
    },
    allClosed(allClosed) {
      this.isDisplayCloseAll = !allClosed;
    },
    ...mapActions('electricityTransmissionContract', ['setFinallyContractXml']),
    ...mapActions('document', ['getPersonInCaseAction', 'getWordInCaseAction']),
  },
};
</script>

<style lang="scss" scoped>
.xml-contract {
  &-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    width: 90%;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 450px;
    }

    & .button {
      border-radius: 24px;
      border: 1px solid #2F82DF;
      background: inherit;
      padding: 0px 20px;
      height: 40px;

      &--grey {
        border: 1px solid #DEE2E6;
      }

      &-inner {
        display: flex;
        gap: 10px;
      }

      &__text {
        display: flex;
        align-self: center;
      }
    }

  }
;

  &-bar-left, &-bar-right {
    display: flex;
    gap: 30px;
    width: 50%;
  }
;

  &-bar-right {
    display: flex;
    justify-content: flex-end;
  }
;

  &__table {
    margin-right: 10px;
  }
;
}
</style>

