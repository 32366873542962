<template>
  <div :class="$style.stepContainer">
    <div :class="$style.spoiler">
      <spoiler-preview v-model="spoiler.summary" :disabled="!expansion">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header :title="title">
            <template v-if="expansion" slot="icon">
              <chevron-up v-if="spoiler.summary"/>
              <chevron-down v-else/>
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.grid2Column">
            <p>Заявитель:</p>
            <p role="summary.fullName"><strong>{{ isLegal ? companyName : fullName }}</strong></p>
          </div>

          <div v-if="isLegal" :class="$style.grid2Column">
            <p>ИНН:</p>
            <p role="summary.companyInn"><strong>{{ companyInn }}</strong></p>
          </div>

          <div v-if="isLegal" :class="$style.grid2Column">
            <p>ОГРН:</p>
            <p role="summary.ogrn"><strong>{{ ogrn }}</strong></p>
          </div>

          <div v-if="isLegal" :class="$style.grid2Column">
            <p>Фактический адрес:</p>
            <p role="summary.companyAddress"><strong>{{ companyAddress }}</strong></p>
          </div>


          <div v-if="isLegal" :class="$style.grid2Column">
            <p>Представитель:</p>
            <p role="summary.fullName"><strong>{{ fullName }}</strong></p>
          </div>

          <div v-if="!isLegal" :class="$style.grid2Column">
            <p>Тип удостоверения личности:</p>
            <div>
              <p><strong v-if="documentType">{{ documentType }}</strong><strong v-else>Паспорт гражданина РФ</strong>
              </p>
            </div>
          </div>

          <div v-if="!isLegal" :class="$style.grid2Column">
            <p>Паспортные данные:</p>
            <div>
              <p role="summary.passportNumber"><strong>серия {{ passportNumber }}</strong></p>
              <p role="summary.passportIssue"><strong>выдан {{ passportIssueDate }} {{ passportIssuer }}</strong></p>
              <p role="summary.subdivisionCode"><strong>код подразделения {{ subdivisionCode }}</strong></p>
            </div>
          </div>

          <div :class="$style.grid2Column">
            <p>СНИЛС:</p>
            <p role="summary.insuranceNumber"><strong>{{ insuranceNumber }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Телефон:</p>
            <p role="summary.phone"><strong>{{ phone }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Электронная почта:</p>
            <p role="summary.email"><strong>{{ email }}</strong></p>
          </div>

          <div v-if="!isLegal" :class="$style.grid2Column">
            <p>Адрес проживания:</p>
            <p role="summary.address"><strong>{{ postcodeAddress }} {{ address }}</strong></p>
          </div>

          <div v-if="!isLegal" :class="$style.grid2Column">
            <p>Адрес регистрации:</p>
            <p role="summary.registrationAddress"><strong>{{ postcodeRegistrationAddress }} {{
                registrationAddress
              }}</strong></p>
          </div>

          <template v-if="form.additionalOwner?.ownerGroups && form.additionalOwner.ownerGroups.length > 0">
            <div v-for="(owner, index) in form.additionalOwner.ownerGroups" :key="index"
                 :class="$style.grid2Column">
              <p v-if="index === 0">Созаявитель/заявитель:</p>
              <p v-else></p>
              <div role="summary.ownerGroups">
                <p :class="$style.gridFluid">
                  <strong :role="'summary.owner_' + index">{{
                      `${notNullString(owner?.familyName)} ${notNullString(owner?.firstName)} ${notNullString(owner?.patronymic)}`
                    }}</strong>
                </p>

                <div :class="$style.grid2Column">
                  <p>Тип удостоверения личности:</p>
                  <div>
                    <p><strong v-if="owner?.documentType?.label">{{ owner?.documentType?.label }}</strong><strong
                      v-else>Паспорт гражданина РФ</strong></p>
                  </div>
                </div>

                <div :class="$style.grid2Column">
                  <p>Паспортные данные:</p>
                  <div>
                    <p><strong>серия {{ owner.documentSeries }} №{{ owner.passportNumber }}</strong></p>
                    <p><strong>выдан {{ owner?.issueDate }} {{ owner?.issuer }}</strong></p>
                    <p><strong>код подразделения {{ owner?.subdivisionCode }}</strong></p>
                  </div>
                </div>

                <div :class="$style.grid2Column">
                  <p>СНИЛС:</p>
                  <p><strong>{{ owner?.insuranceNumber }}</strong></p>
                </div>

                <div :class="$style.grid2Column">
                  <p>Телефон:</p>
                  <p><strong>{{ formattingPhone(owner?.phone) }}</strong><strong
                    v-show="owner?.additionalPhone"><br/>{{ formattingPhone(owner?.additionalPhone) }}</strong></p>
                </div>

                <div :class="$style.grid2Column">
                  <p>Адрес проживания:</p>
                  <p><strong>{{ owner?.postcodeAddress }} {{ owner?.address }}</strong></p>
                </div>

                <div :class="$style.grid2Column">
                  <p>Адрес регистрации:</p>
                  <p><strong>{{ owner?.postcodeRegistrationAddress }} {{ owner?.registrationAddress }}</strong></p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div :class="$style.grid2Column">
              <p>Созаявитель/заявитель:</p>
              <div>
                <p>
                  <strong :role="'summary.owner'">{{ fullName }}</strong>
                </p>
              </div>
            </div>
          </template>

          <div :class="$style.grid2Column">
            <p>Документ, подтверждающий собственность(или право собственности):</p>
            <div>
              <p role="summary.documentTitle"><strong>{{ documentTitle }}</strong></p>
              <p role="summary.documentInfo"><strong>{{ documentNumber }} {{ documentIssueDate }}</strong></p>
            </div>
          </div>

          <div :class="$style.grid2Column">
            <p>В связи с:</p>
            <p v-if="onlyMicrogenerator"><strong>В связи с подключением микрогенераций</strong></p>
            <p v-else><strong>Новым технологическим присоединением</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Объект:</p>
            <p role="summary.objectType">
              <strong v-if="objectType === 'Иное...'">{{ objectOtherType }}</strong>
              <strong v-else>{{ objectType }}</strong>
            </p>
          </div>

          <div :class="$style.grid2Column">
            <p>Адрес объекта:</p>
            <p role="summary.objectAddress"><strong>{{ objectAddress }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Дополнительные сведения о расположении:</p>
            <p role="summary.objectInformation"><strong>{{ objectInformation }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Кадастровый номер земельного участка:</p>
            <p role="summary.landCadastralNumber"><strong>{{ landCadastralNumber }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Кадастровый номер объекта подключения:</p>
            <p role="summary.buildingCadastralNumber"><strong>{{ buildingCadastralNumber }}</strong></p>
          </div>

          <div v-if="displayMicrogenerator">
            <div :class="$style.grid2Column">
              <p>Количество микрогенераторов:</p>
              <p role="summary.countMicrogenerators"><strong>{{ countMicrogenerators }}</strong></p>
            </div>

            <div v-for="(item, idx) in microgeneratorItems" :key="'summary.microgenerator_' + idx">
              <div :class="$style.gridFluid">
                <h2 :class="$style.title">
                  <span>Микрогенератор №{{ idx + 1 }}</span>
                </h2>
              </div>

              <div :class="$style.grid2Column">
                <p>Максимальная генерируемая мощность:</p>
                <p :role="'summary.microgenerator[' + idx + '].MaxGeneratePower'"><strong>{{ item.MaxGeneratePower }}
                  кВт</strong></p>
              </div>

              <div :class="$style.grid2Column">
                <p>Мощность, передаваемая в сеть:</p>
                <p :role="'summary.microgenerator[' + idx + '].PowerTransmittedToNetwork'">
                  <strong>{{ item.PowerTransmittedToNetwork }} кВт</strong></p>
              </div>

              <div :class="$style.grid2Column">
                <p>Паспортные характеристики микрогенератора:</p>
                <p :class="$style.marginBottom" :role="'summary.microgenerator[' + idx + '].Characteristics'"
                   v-html="formattingString(item.Characteristics)"></p>
              </div>

              <div :class="$style.grid2Column">
                <p>Место установки микрогенератора:</p>
                <p :role="'summary.microgenerator[' + idx + '].MicrogeneratorLocation'">
                  <strong>{{ item.MicrogeneratorLocation }}</strong></p>
              </div>

              <div :class="$style.grid2Column">
                <p>Планируемый срок проектирования:</p>
                <p :role="'summary.microgenerator[' + idx + '].microgeneratorsReadyDate'">
                  <strong>{{ microgeneratorsReadyDate }}</strong></p>
              </div>

              <div :class="$style.grid2Column" style="padding-bottom: 24px">
                <p>Планируемый срок подключения:</p>
                <p :role="'summary.microgenerator[' + idx + '].microgeneratorsReadyDate'">
                  <strong>{{ microgeneratorsReadyDate }}</strong></p>
              </div>


            </div>
          </div>
          <!--TODO вернуть energyConsumerCount-->
          <div v-if="!displayMicrogenerator" :class="$style.gridFluid">
            <div v-if="form.type !== 'INDIVIDUALS_UP_TO_15'" :class="$style.grid2Column">
              <p>Количество точек присоединения:</p>
              <p role="summary.energyConsumerCount"><strong>1</strong></p>
            </div>

            <div :class="$style.grid2Column">
              <p>Ценовая категория:</p>
              <p role="summary.tariffCategoryType"><strong>{{ tariffCategoryType }}</strong></p>
            </div>

            <div v-if="tariffCategoryType === '2 ценовая категория'" :class="$style.grid2Column">
              <p>Тарифная зона:</p>
              <p role="summary.tariffZoneType"><strong>{{ tariffZoneType }}</strong></p>
            </div>

            <div :class="$style.grid2Column">
              <p>Тип учёта:</p>
              <p role="summary.typeAccounting"><strong>{{ typeAccounting }}</strong></p>
            </div>
            <template v-if="maximumPowerVoltage.length > 0">
              <div :class="$style.gridFluid">
                <p><strong>Максимальная мощность энергопринимающих устройств и напряжение:</strong></p>
              </div>
              <div v-for="(item, index) in maximumPowerVoltage" :key="`voltage${index}`" :class="$style.grid2Column"
                   style="padding-left: 24px">
                <div>
                  <p><strong>Категория надежности:</strong></p>
                </div>
                <div>
                  <p><strong>{{ item.reliabilityClass.label }}</strong></p>
                </div>
                <div>
                  <p><strong>Максимальная мощность присоединяемых устройств, кВт:</strong></p>
                </div>
                <div>
                  <p><strong>{{ item.maxPowerConnection }}</strong></p>
                </div>
                <div>
                  <p>Максимальная мощность ранее присоединяемых устройств, кВт:</p>
                </div>
                <div>
                  <p><strong>{{ item.previousMaxPower }}</strong></p>
                </div>
                <div>
                  <p>Максимальная мощность присоединенных и ранее присоединяемых устройств, кВт:</p>
                </div>
                <div>
                  <p><strong>{{ item.summaryMaxPower }}</strong></p>
                </div>
                <div>
                  <p>Напряжение присоединяемых устройств, В:</p>
                </div>
                <div>
                  <p><strong>{{ item.currentVoltage.value }}</strong></p>
                </div>
                <div>
                  <p>Напряжение ранее присоединяемых устройств, В:</p>
                </div>
                <div>
                  <p><strong>{{ item.previousPhaseVoltage }}</strong></p>
                </div>
                <div>
                  <p>Напряжение присоединенных и ранее присоединяемых устройств, В:</p>
                </div>
                <div>
                  <p><strong>{{ item.summaryPhaseValue.value }}</strong></p>
                </div>
              </div>
            </template>
            <div :class="$style.grid2Column">
              <p>Категория надежности:</p>
              <p role="summary.reliabilityClass"><strong>{{ reliabilityClass }}</strong></p>
            </div>
            <div v-if="isLegal" :class="$style.grid2Column">
              <p>Характер нагрузки:</p>
              <p role="summary.workload"><strong>{{ workload }}</strong></p>
            </div>
            <template v-if="readyConnectionDate.length > 0">
              <div :class="$style.gridFluid">
                <p><strong>Этапы введения в эксплуатацию:</strong></p>
              </div>
              <div v-for="(item, index) in readyConnectionDate" :key="index" :class="$style.grid2Column"
                   style="padding-left: 24px">
                <div>
                  <p><strong>Этап (очередь) строительства:</strong></p>
                </div>
                <div>
                  <p><strong>{{ item.stage }}</strong></p>
                </div>
                <div>
                  <p>Планируемый срок проектирования энергопринимающего устройства:</p>
                </div>
                <div>
                  <p><strong>{{ item.designDate }}</strong></p>
                </div>
                <div>
                  <p>Планируемый срок введения энергопринимающего устройства в эксплуатацию:</p>
                </div>
                <div>
                  <p><strong>{{ item.readyDate }}</strong></p>
                </div>
                <div>
                  <p>Максимальная мощность энергопринимающего устройства (кВт)</p>
                </div>
                <div>
                  <p><strong>{{ item.maxPower }}</strong></p>
                </div>
                <div>
                  <p>Категория надежности:</p>
                </div>
                <div>
                  <p><strong>III</strong></p>
                </div>
              </div>
            </template>

          </div>

          <div :class="$style.grid2Column">
            <p>Будет заключён:</p>
            <p role="summary.supplierOfLastResortNote"><strong>Договор купли-продажи
              электрической энергии</strong>
            </p>
          </div>

          <div v-if="onlyMicrogenerator" :class="$style.grid2Column">
            <p>Со сбытовой компанией:</p>
            <p role="summary.supplierOfLastResort"><strong>{{ supplierOfLastResort }}</strong></p>
          </div>

          <div v-else :class="$style.grid2Column">
            <p>С гарантирующим поставщиком:</p>
            <p role="summary.supplierOfLastResort"><strong>{{ supplierOfLastResort }}</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Ценовая категория:</p>
            <p><strong>1 ценовая категория, помесячный учёт</strong></p>
          </div>

          <div :class="$style.grid2Column">
            <p>Заявка направляется в организацию, осуществляющую присоединение:</p>
            <div>
              <p role="summary.gridCompany.label"><strong>{{ gridCompany.label }}</strong></p>
              <p role="summary.gridCompany.address"><strong>{{ gridCompany.address }}</strong></p>
              <p role="summary.gridCompany.phone"><strong>Тел: <a
                :href="'tel:' + gridCompany.phone">{{ gridCompany.phone }}</a></strong></p>
              <p role="summary.gridCompany.email"><strong>E-mail: <a
                :href="'mailto:' + gridCompany.email">{{ gridCompany.email }}</a></strong></p>
              <p role="summary.gridCompany.host"><strong><a :href="'https://' + gridCompany.host"
                                                            target='_blank'>{{ gridCompany.host }}</a></strong></p>
            </div>
          </div>

          <div v-if="additionalInfo" :class="$style.grid2Column">
            <p>Дополнительная информация:</p>
            <p><strong>{{ additionalInfo }}</strong></p>
          </div>
          <div v-if="additionalDocuments.length > 0" :class="$style.grid2Column">
            <p>Дополнительные документы:</p>
            <div :class="$style.documentFiles">
              <file-list-preview :list="additionalDocuments" role="summary.additionalDocuments"/>
            </div>
          </div>
        </template>
      </spoiler-preview>
    </div>

    <div :class="$style.spoiler">
      <spoiler-preview v-model="spoiler.upload" :disabled="!expansion">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header title="Документы, загруженные потребителем">
            <template v-if="expansion" slot="icon">
              <chevron-up v-if="spoiler.upload"/>
              <chevron-down v-else/>
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.grid2Column">
            <p><strong>Паспорт гражданина РФ</strong></p>
            <div :class="$style.documentFiles">
              <file-list-preview :list="passportScans" role="summary.passportScans"/>
            </div>
          </div>

          <div v-if="powerOfAttorneyScans.length > 0" :class="$style.grid2Column">
            <p><strong>Доверенность, согласие на подключение собственника или владельцев долевой собственности и согласие на обработку персональных данных</strong></p>
            <div :class="$style.documentFiles">
              <file-list-preview :list="powerOfAttorneyScans" role="summary.powerOfAttorneyScans"/>
            </div>
          </div>

          <div v-if="objectShemeScan.length > 0" :class="$style.grid2Column">
            <p><strong>План расположения энергопринимающих устройств</strong></p>
            <div :class="$style.documentFiles">
              <file-list-preview :list="objectShemeScan" role="summary.objectShemeScan"/>
            </div>
          </div>

          <div :class="$style.grid2Column">
            <p><strong>Документы, подтверждающие право собственности</strong></p>
            <div :class="$style.documentFiles">
              <file-list-preview :list="confirmationDocumentScan" role="summary.confirmationDocumentScan"/>
            </div>
          </div>

          <div v-if="displayMicrogenerator">
            <div v-for="(item, idx) in microgeneratorItems" :key="'summary.microgenerator.docs_' + idx"
                 :class="$style.grid2Column">
              <p><strong>Технические характеристики микрогенератора №{{ idx + 1 }}</strong></p>
              <div :class="$style.documentFiles">
                <file-list-preview :list="item.DocumentScans"
                                   :role="'summary.microgenerator[' + idx + '].DocumentScans'"/>
              </div>
            </div>
          </div>
        </template>
      </spoiler-preview>
    </div>

    <div v-if="offerDocument && offerDocument.files" :class="$style.spoiler">
      <spoiler-preview v-model="spoiler.offerDocument" :disabled="!expansion">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header :title="offerDocumentTitle">
            <template v-if="expansion" slot="icon">
              <chevron-up v-if="spoiler.offerDocument"/>
              <chevron-down v-else/>
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.offerDocumentFiles">
            <p v-for="(file, index) in offerDocument.files" :key="index"
               style="display: flex; justify-content: space-between">
              <a :download="file.name" :href="fileUrl(file)" style="width: 400px; margin-bottom: 16px">{{
                  file.name
                }}</a>
              <a :download="file.name" :href="fileUrl(file)" style="text-decoration: none">
                <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
                скачать</a>
            </p>
          </div>
        </template>
      </spoiler-preview>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { EVENT_BUS } from 'root/eventBus';

import ChevronDown from 'atoms/icons/arrows/ChevronDown';
import ChevronUp from 'atoms/icons/arrows/ChevronUp';

import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';
import FileListPreview from 'molecules/upload/form/FileListPreview';

import connectionApplicationData from 'lib/storage/connection-application/data';
import formatting from 'lib/utils/formatting';

export default {
  name: 'SummaryApplication',
  components: {
    ChevronDown,
    ChevronUp,
    SpoilerPreview,
    SpoilerHeader,
    FileListPreview,
  },
  props: {
    applicationData: {
      type: Object,
      default: () => undefined,
    },
    offerDocument: {
      type: Object,
      default: () => undefined,
    },
    offerDocumentTitle: {
      type: String,
      default: () => 'Согласованные документы',
    },
    title: {
      type: String,
      default: () => 'Проверьте введённую информацию',
    },
    expansion: {
      type: Boolean,
      default: () => false,
    },
    showMap: {
      type: Boolean,
      default: () => false,
    },
    initSpoiler: {
      type: Boolean,
      default: () => true,
    },
    initSpoilerOffer: {
      type: Boolean,
      default: () => false,
    },
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (!this.applicationData) {
      EVENT_BUS.$on('SAVE_INTERMEDIATE', (type, data) => {
        this.form[type] = { ...data };
      });
    }

    this.initializeData();
  },
  data() {
    return {
      spoiler: {
        summary: this.initSpoiler,
        upload: this.initSpoiler,
        offerDocument: this.initSpoiler || this.initSpoilerOffer,
      },
      form: {
        consumer: {},
        additionalOwner: {},
        objectLocation: {},
        confirmationDocument: {},
        connectionCharacteristics: {},
        microgenerator: {},
        gridCompany: {},
        additionalInfo: {},
        documentScans: {},
      },
    };
  },
  computed: {
    fullName() {
      const { consumer } = this.form;
      if (!consumer) {
        return '';
      }
      return `${this.notNullString(consumer?.familyName)} ${this.notNullString(consumer?.firstName)} ${this.notNullString(consumer?.patronymic)}`;
    },
    maximumPowerVoltage() {
      const { connectionCharacteristics } = this.form;
      const isValidated = connectionCharacteristics?.maximumPowerVoltage && connectionCharacteristics?.maximumPowerVoltage.length > 0;

      if (!isValidated) {
        return [];
      }
      console.log(connectionCharacteristics?.maximumPowerVoltage);
      return connectionCharacteristics?.maximumPowerVoltage;
    },
    passportNumber() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.passportNumber);

      if (!isValidated) {
        return '';
      }

      return `${consumer.documentSeries} №${consumer.passportNumber}`;
    },
    documentType() {
      return this.form.consumer?.documentType?.label;
    },
    passportIssueDate() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.issueDate);

      if (!isValidated) {
        return '';
      }

      return consumer?.issueDate;
    },
    subdivisionCode() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.subdivisionCode);

      if (!isValidated) {
        return '';
      }

      return consumer?.subdivisionCode;
    },
    passportIssuer() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.issuer);

      if (!isValidated) {
        return '';
      }

      return consumer?.issuer;
    },
    insuranceNumber() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.insuranceNumber);

      if (!isValidated) {
        return '';
      }

      return consumer?.insuranceNumber;
    },
    phone() {
      const { consumer } = this.form;
      let phoneString = '';

      if (this.$validate.isNotEmptyString(consumer?.phone)) {
        const formattingPhone = formatting.phoneFormat(consumer.phone);

        if (formattingPhone.isPhone) {
          phoneString = formattingPhone.formattedPhone;
        } else {
          phoneString = consumer.phone;
        }
      }

      if (this.$validate.isNotEmptyString(consumer?.additionalPhone)) {
        const formattingPhone = formatting.phoneFormat(consumer.additionalPhone);

        if (formattingPhone.isPhone) {
          phoneString = `${phoneString}, ${formattingPhone.formattedPhone}`;
        } else {
          phoneString = `${phoneString}, ${consumer.additionalPhone}`;
        }
      }

      return phoneString;
    },
    email() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.email);

      if (!isValidated) {
        return '';
      }

      return consumer?.email;
    },
    address() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.address);

      if (!isValidated) {
        return '';
      }

      return consumer?.address;
    },
    postcodeAddress() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.postcodeAddress);

      if (!isValidated) {
        return '';
      }

      return consumer?.postcodeAddress;
    },
    registrationAddress() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.registrationAddress);

      if (!isValidated) {
        return '';
      }

      return consumer?.registrationAddress;
    },
    postcodeRegistrationAddress() {
      const { consumer } = this.form;
      const isValidated = this.$validate.isNotEmptyString(consumer?.postcodeRegistrationAddress);

      if (!isValidated) {
        return '';
      }

      return consumer?.postcodeRegistrationAddress;
    },
    ownerGroups() {
      const { additionalOwner } = this.form;
      const isValidated = this.$validate.isNotEmptyArray(additionalOwner?.ownerGroups);

      if (!isValidated) {
        return [];
      }

      return additionalOwner.ownerGroups.map((group) => `${this.notNullString(group?.familyName)} ${this.notNullString(group?.firstName)} ${this.notNullString(group?.patronymic)}`);
    },
    documentTitle() {
      const { confirmationDocument } = this.form;

      return this.notNullString(confirmationDocument?.documentTitle?.label?.includes('Иное') ? confirmationDocument?.documentName : confirmationDocument?.documentTitle?.label);
    },
    documentNumber() {
      const { confirmationDocument } = this.form;
      const isValidated = this.$validate.isNotEmptyString(confirmationDocument?.documentNumber);

      if (!isValidated) {
        return '';
      }

      return `№${confirmationDocument?.documentNumber}`;
    },
    documentIssueDate() {
      const { confirmationDocument } = this.form;
      const isValidated = this.$validate.isNotEmptyString(confirmationDocument?.documentIssueDate);

      if (!isValidated) {
        return '';
      }

      return `от ${confirmationDocument?.documentIssueDate}`;
    },
    objectType() {
      const { objectLocation } = this.form;

      console.log('objectLocation', objectLocation?.objectType?.label);

      return this.notNullString(objectLocation?.objectType?.label);
    },
    objectOtherType() {
      const { objectLocation } = this.form;

      return this.notNullString(objectLocation?.otherObjectType);
    },
    objectAddress() {
      const { objectLocation } = this.form;
      const isValidated = this.$validate.isNotEmptyString(objectLocation?.objectAddress);

      if (!isValidated) {
        return '';
      }

      return objectLocation?.objectAddress;
    },
    objectInformation() {
      const { objectLocation } = this.form;
      const isValidated = this.$validate.isNotEmptyString(objectLocation?.additionalInformation);

      if (!isValidated) {
        return '';
      }

      return objectLocation?.additionalInformation;
    },
    landCadastralNumber() {
      const { objectLocation } = this.form;
      const isValidated = this.$validate.isNotEmptyString(objectLocation?.landCadastralNumber);

      if (!isValidated) {
        return '';
      }

      return objectLocation?.landCadastralNumber;
    },
    buildingCadastralNumber() {
      const { objectLocation } = this.form;
      const isValidated = this.$validate.isNotEmptyString(objectLocation?.buildingCadastralNumber);

      if (!isValidated) {
        return '';
      }

      return objectLocation?.buildingCadastralNumber;
    },
    energyConsumerCount() {
      const { connectionCharacteristics } = this.form;
      // добавить ли проверку на выбранный пресет?

      return this.notNullString(connectionCharacteristics?.energyConsumerCount?.label);
    },
    maxPowerConnection() {
      const { connectionCharacteristics } = this.form;
      const isValidated = this.$validate.isNotEmptyString(connectionCharacteristics?.maxPowerConnection);
      // добавить ли проверку на выбранный пресет?
      if (!isValidated) {
        return '';
      }

      return connectionCharacteristics?.maxPowerConnection;
    },
    phaseVoltage() {
      const { connectionCharacteristics } = this.form;
      // добавить ли проверку на выбранный пресет?

      return this.notNullString(connectionCharacteristics?.phaseVoltage?.label);
    },
    reliabilityClass() {
      const { connectionCharacteristics } = this.form;
      // добавить ли проверку на выбранный пресет?

      return this.notNullString(connectionCharacteristics?.reliabilityClass?.label);
    },
    tariffCategoryType() {
      const { connectionCharacteristics } = this.form;

      return this.notNullString(connectionCharacteristics?.tariffCategoryType?.label);
    },
    tariffZoneType() {
      const { connectionCharacteristics } = this.form;

      return this.notNullString(connectionCharacteristics?.tariffZoneType?.label);
    },
    typeAccounting() {
      const { connectionCharacteristics } = this.form;

      return this.notNullString(connectionCharacteristics?.typeAccounting?.label);
    },
    objectReadyDate() {
      const { connectionCharacteristics } = this.form;
      const isValidated = this.$validate.isNotEmptyString(connectionCharacteristics?.readyDate);

      if (!isValidated) {
        return '';
      }

      return `${connectionCharacteristics?.readyDate} г.`;
    },
    readyConnectionDate() {
      const { connectionCharacteristics } = this.form;
      const isValidated = connectionCharacteristics?.readyConnectionDate && connectionCharacteristics?.readyConnectionDate.length > 0;

      if (!isValidated) {
        return [];
      }

      return connectionCharacteristics?.readyConnectionDate;
    },
    supplierOfLastResort() {
      const { connectionCharacteristics } = this.form;
      // добавить ли проверку на выбранный пресет?

      return this.notNullString(connectionCharacteristics?.supplierOfLastResort?.label);
    },
    additionalInfo() {
      const { additionalInfo } = this.form;

      return this.notNullString(additionalInfo?.comments);
    },
    gridCompany() {
      const { gridCompany } = this.form;

      console.log('this.form', this.form);
      console.log('this.form.gridCompany', gridCompany);
      console.log('this.form.gridCompany?.otherGridCompany', gridCompany.otherGridCompany);
      if (gridCompany?.gridCompanyName?.label === 'Иное') {
        return {
          label: this.form.gridCompany?.otherGridCompany,
        };
      }

      return {
        ...gridCompany?.gridCompanyName,
      };
    },
    passportScans() {
      const passportScans = this.form?.documentScans?.passportScans;

      if (this.$validate.isUndefined(passportScans) || this.$validate.isEmptyArray(passportScans)) {
        return [];
      }

      return passportScans;
    },
    powerOfAttorneyScans() {
      const powerOfAttorneyScans = this.form?.documentScans?.powerOfAttorneyScans;

      if (this.$validate.isUndefined(powerOfAttorneyScans) || this.$validate.isEmptyArray(powerOfAttorneyScans)) {
        return [];
      }

      return powerOfAttorneyScans;
    },
    confirmationDocumentScan() {
      const confirmationDocumentScan = this.form?.documentScans?.confirmationDocumentScan;

      if (this.$validate.isUndefined(confirmationDocumentScan) || this.$validate.isEmptyArray(confirmationDocumentScan)) {
        return [];
      }

      return confirmationDocumentScan;
    },
    objectShemeScan() {
      const objectShemeScan = this.form?.documentScans?.objectShemeScan;

      if (this.$validate.isUndefined(objectShemeScan) || this.$validate.isEmptyArray(objectShemeScan)) {
        return [];
      }

      return objectShemeScan;
    },
    additionalDocuments() {
      const documents = this.form?.additionalInfo?.additionalDocuments;

      if (this.$validate.isUndefined(documents) || this.$validate.isEmptyArray(documents)) {
        return [];
      }

      return documents;
    },
    latitude() {
      const { objectLocation } = this.form;

      return objectLocation?.latitude;
    },
    longitude() {
      const { objectLocation } = this.form;

      return objectLocation?.longitude;
    },
    displayMicrogenerator() {
      const { microgenerator } = this.form;

      if (microgenerator && microgenerator.items) {
        return true;
      }

      return false;
    },
    countMicrogenerators() {
      const { microgenerator } = this.form;

      if (!microgenerator.items) {
        return 0;
      }
      return microgenerator.items.length;
    },
    microgeneratorItems() {
      const { microgenerator } = this.form;

      return microgenerator.items;
    },
    microgeneratorsReadyDate() {
      const { microgenerator } = this.form;

      const isValidated = this.$validate.isNotEmptyString(microgenerator?.ReadyDate);

      if (!isValidated || !microgenerator) {
        return '';
      }

      return `${microgenerator?.ReadyDate} г.`;
    },
    company() {
      const { consumer } = this.form;
      return consumer.company;
    },
    isLegal() {
      return this.company?.ogrn?.length;
    },
    companyName() {
      return this.company?.name;
    },
    ogrn() {
      return this.company?.ogrn;
    },
    companyInn() {
      return this.company?.inn;
    },
    companyAddress() {
      return this.company?.address;
    },
    ...mapGetters('tgis', ['getZoom']),
  },
  methods: {
    async initializeData() {
      const connectionApplication = await connectionApplicationData.getData(this.applicationData);
      console.log('initializeData', this.applicationId, connectionApplication);

      console.log('this.onlyMicrogenerator', this.onlyMicrogenerator);
      console.log('connectionApplication.microgenerator', this.$validate.isEmptyObject(connectionApplication.microgenerator), connectionApplication.microgenerator);
      if (this.$validate.isNotEmptyObject(connectionApplication) &&
        (this.onlyMicrogenerator || this.$validate.isEmptyObject(connectionApplication.microgenerator))) {
        this.form = { ...connectionApplication };

        console.log('this.form.additionalOwner', this.form.additionalOwner);
      }

    },
    fileUrl(file) {
      return `data:${file.contentType};base64,${file.value}`;
    },
    formattingString(value) {
      return formatting.formattedString(value, '', '');
    },
    notNullString(val) {
      if (this.$validate.isNull(val)) {
        return '';
      }
      return val;
    },
    formattingPhone(phone) {
      if (!phone || phone.length === 0) {
        return '';
      }

      const result = formatting.phoneFormat(phone);

      if (!result.isPhone) {
        return '';
      }

      return result.formattedPhone;
    },
  },
};
</script>

<style lang="sass" module>
.stepContainer
  padding-bottom: 24px

.titleContainer
  +base-spacing

.gridMap
  +grid-fluid

.gridFluid
  +grid-fluid

.grid2Column
  +grid-2-column
  grid-template-columns: 1fr 2fr
  -ms-grid-columns: 1fr 16px 2fr

.gridFluid,
.grid2Column
  +questionnaries-container

  p
    font-weight: 400
    font-size: 14px
    line-height: 18px
    color: #000

  strong
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 16px
    line-height: 22px
    color: #141412

  a
    color: #2F82DF
    text-decoration: underline

.documentGroup
  display: flex
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: 12px

.documentFiles
  flex: none
  margin: 0 0 0 16px

.spoiler
  &:not(:last-child)
    margin-bottom: 12px

.spoilerHeader
  +questionnaries-container

.offerDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.offerDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.marginBottom p
  margin-bottom: 8px
</style>
