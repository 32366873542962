<template>
  <div>
    <action-block
      :mode="mode"
      :company-account-id="companyAccountId"
      :company-name="companyName"
      @changeMode="onModeChanged"
      @load="loadData"
    />
    <content-block :mode="mode" :is-loading="isLoading" @load="loadData"/>
  </div>
</template>

<script>
import ContentBlock from './ContentBlock/index.vue';
import ActionBlock from './ActionBlock';

import dayjs from 'dayjs';
import { mapActions, mapGetters, mapMutations } from 'vuex';

function compare(a, b) {
  if (dayjs(a.expiresAt).diff(dayjs(b.expiresAt)) < 0) {
    return 1;
  }

  return -1;
}

export default {
  name: 'EmployeesIndex',
  components: { ActionBlock, ContentBlock },
  props: {
    companyAccountId: {
      type: String,
      default: undefined,
    },
    companyName: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      usersList: [],
      mode: 'employees',
      isLoading: false,
    };
  },
  computed: {
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.company-employees';
    },
    ...mapGetters('user', ['getAccount', 'isPlatformWorker']),
  },
  watch: {
    companyAccountId() {
      console.log('watch companyAccountId', this.companyAccountId);
      this.loadData();
    },
    isPlatformWorkerForCompany() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;

        let users;
        let roles;

        if (this.isPlatformWorkerForCompany) {
          if (!this.companyAccountId) {
            this.SET_ROLES([]);
            this.SET_EMPLOYEES([]);
            this.SET_INVITATIONS([]);

            return;
          }

          users = await this.getUser({ id: this.companyAccountId });
          roles = await this.getCompanyRoles({ filter: { accountId: this.companyAccountId } });
          await this.loadInvitationsFromServer({ filter: { accountId: this.companyAccountId } });
        } else {
          users = await this.getUser();
          roles = await this.getCompanyRoles();
          await this.loadInvitationsFromServer();
        }

        const result = users.authorizations.map((user) => ({
            ...user,
            transformPowerOfAttorney: this.transformPowerOfAttorney(user.powerOfAttorney),
          }));

        this.SET_ROLES(roles);
        this.SET_EMPLOYEES(result);
      } catch (error) {
        console.log('userPermissions', error);
      } finally {
        this.isLoading = false;
      }
    },
    onModeChanged(mode) {
      console.log(mode);
      this.mode = mode;
    },
    transformPowerOfAttorney(list) {
      if (!list || list.length === 0) {
        return [];
      }

      return list.sort(compare).map((item) => ({
          ...item,
          isArchive: !item.expiresAt ? false : dayjs().startOf('day').diff(dayjs(item.expiresAt), 'day') > 0,
        }));
    },
    ...mapMutations('employees', ['SET_EMPLOYEES', 'SET_ROLES', 'SET_INVITATIONS']),
    ...mapActions('user', ['getUser', 'getCompanyRoles']),
    ...mapActions('employees', ['loadInvitationsFromServer']),
  },
};
</script>

<style lang="scss" module>
</style>
