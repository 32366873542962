<template>
  <add-directory-field-modal
      v-model="internalModalValue"
      :action="internalModalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createWarehouse"
      @delete="deleteWarehouse"
      @update="updateWarehouse"/>
</template>

<script>
import { createWarehouse, deleteWarehouse, updateWarehouse } from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';

export default {
  name: 'WarehousesEdit',
  components: {
    AddDirectoryFieldModal,
  },
  props: {
    modalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalModalValue: {},
      loading: false,
    };
  },
  computed: {
    fields() {
      return [{
        type: AddDirectoryFieldModalFieldType.TEXT,
        id: 'name',
        name: 'Наименование',
        required: true,
      },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.ADDRESS,
          id: 'address',
          name: 'Адрес',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'hide',
          name: 'Скрыт',
          defaultValue: 'false',
          required: true,
        },
      ];
    },
  },
  watch: {
    modalAction(val) {
      this.internalModalAction = val;
    },
    modalValue(val) {
      this.internalModalValue = val;
    },
  },
  methods: {
    closeModal() {
      this.internalModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:modalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalModalValue = {};
      this.$emit('update:modalValue', {});
    },
    deleteWarehouse() {
      this.doAction(() => deleteWarehouse(this.modalValue.id));
    },
    createWarehouse() {
      this.doAction(() => createWarehouse(this.modalValue));
    },
    updateWarehouse() {
      this.doAction(() => updateWarehouse(this.modalValue.id, this.modalValue));
    },
    async doAction(action) {
      this.loading = true;
      try {
        const result = await action();
        this.$emit('onAction', result?.data);
        this.closeModal();
        this.$emit('loadData', this.modalValue);
      } catch (error) {
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>
