<template>
  <div>
    <v-table-new
      v-model="transformedContracts"
      :action-block-items="actionBlockItems"
      :is-nested-mode="true"
      :loading="loading"
      :template="template"
      excel-report-file-name="Договоры оказания услуг по передаче электрической энергии и дополнительные соглашения"
      @on-cell-click="cellClickHandler"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { VTableNew } from '@/components';
import { completedByStatus, formatContractStatus, getContractStatusLabelValueList } from 'lib/utils/formatXmlType';
import { convertDbDateTimeFormat, convertDbFormat } from 'lib/utils/date';
import { checkField } from '@/utils/common';
import { convertToLabelValueList } from '@/utils/list';
import { isArray } from 'lodash';

export default {
  name: 'ContentBlock',
  components: {
    VTableNew,
  },
  props: {
    loading: { type: Boolean, required: true },
    contracts: { type: Array, required: true },
    contractType: { type: String, required: true },
  },
  data() {
    return {
      actionBlockItems: [
        {
          id: 1,
          title: 'Создать электронный договор',
          method: this.goToPageByRoute(
            { action: 'create', type: 'contract' },
          ),
        },
        {
          id: 2,
          title: 'Загрузить действующий договор',
          method: this.goToPageByRoute(
            { action: 'upload', type: 'contract' },
          ),
        },
        {
          id: 3,
          title: 'Создать электронное дополнительное соглашение',
          method: this.goToPageByRoute(
            { action: 'create', type: 'additionalAgreement' },
          ),
        },
        {
          id: 4,
          title: 'Загрузить действующее дополнительное соглашение',
          method: this.goToPageByRoute(
            { action: 'upload', type: 'additionalAgreement' },
          ),
        },
      ],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: '',
            key: 'spoilerButton',
            type: 'spoilerButton',
            thStyle: {
              width: '20px',
            },
            customCheck: (row) => !this.hasNonNullifiedAdditionalContracts(row) && !row.additionalContracts,
          },
          {
            label: 'Договор',
            key: 'kindByExisting',
            filter: { type: 'selectNotStrict', values: convertToLabelValueList(['Договор', 'Доп. соглашение']) },
          },
          {
            label: 'Номер документа',
            key: 'documentNumber',
            thStyle: {
              minWidth: '111px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Дата заключения',
            key: 'formattedDate',
            thStyle: {
              minWidth: '110px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Дата истечения',
            key: 'expirationDate',
            thStyle: {
              minWidth: '110px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Дата с которой действует договор',
            key: 'effectiveDate',
            thStyle: {
              minWidth: '110px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Заказчик',
            key: 'supplierName',
            sort: {},
            filter: {},
          },
          {
            label: 'Исполнитель 1',
            key: 'performerFirstName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Исполнитель 2',
            key: 'performerSecondName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Статус',
            key: 'status',
            type: 'status',
            sort: {},
            filter: { type: 'select', values: getContractStatusLabelValueList() },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
            thStyle: {
              minWidth: '130px',
            },
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedContracts() {
      if (this.contracts.length === 0) {
        return [];
      }

      const nestedContracts = this.makeToNestedContracts(this.contracts);

      nestedContracts.forEach((mainContract) => {
        mainContract.main = this.mapContracts(mainContract.main);

        if (mainContract.nested && mainContract.nested.length !== 0) {
          mainContract.nested = this.mapContracts(mainContract.nested);
        }

      });

      return nestedContracts;
    },
  },
  methods: {
    hasNonNullifiedAdditionalContracts(mainContract) {
      if (isArray(mainContract.additionalContracts)) {
        return mainContract.additionalContracts && mainContract.additionalContracts.some((contract) => contract.status !== 'NULLIFIED');
      }
    },
    mapContracts(contracts) {
      let innerContracts;
      let type;

      if (Array.isArray(contracts)) type = 'Array';
      if (contracts && typeof contracts === 'object' && contracts.constructor === Object) type = 'Object';

      switch (type) {
        case 'Array':
          innerContracts = contracts;
          break;
        case 'Object':
          innerContracts = [contracts];
          break;
        default:
          return;
      }

      const mappedInnerContracts = innerContracts.map((it) => ({
        kindByExisting: this.formatContractTitle({
          kindByExisting: it.kindByExisting,
          sourceContractId: it.sourceContractId,
        }),
        actVerificationDocumentId: it.actVerification?.documentId,
        actOpen: checkField(it.actVerification?.documentId),
        actXmlDownload: checkField(it.actVerification?.documentId),
        actPdfDownload: checkField(it.actVerification?.documentId),
        documentNumber: it.contractInfo.number,
        formattedDate: convertDbFormat(it.contractInfo.date),
        supplierName: it.supplier.name,
        expirationDate: convertDbFormat(it.contractInfo.expirationDate),
        effectiveDate: convertDbFormat(it.contractInfo.effectiveDate),
        performerFirstName: it.performerFirst.name,
        performerSecondName: it.performerSecond.name,
        additionalContracts: this.formatAdditionalContracts(it.additionalContracts),
        status: it.status,
        document: { documentId: it.documentId, name: this.formatContractTitle(it.kindByExisting) },
        act: this.checkEmptyAct(it.actVerification),
        documentId: it.documentId,
        rejectedReason: it.rejectedReason,
        supplierStatus: this.formatContractStatus(it.supplier.status),
        performerFirstStatus: this.formatContractStatus(it.performerFirst.status),
        performerSecondStatus: this.formatContractStatus(it.performerSecond.status),
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        contractId: it.id,
        notFormatStatus: it.status,
        performerFirst: it.performerFirst,
        performerSecond: it.performerSecond,
        supplier: it.supplier,
        initiatorAccountId: it.initiatorAccountId,
        initiatorUserId: it.initiatorUserId,
      }));

      switch (type) {
        case 'Array':
          return mappedInnerContracts;
        case 'Object':
          return mappedInnerContracts[0];
        default:
          return;
      }

    },
    makeToNestedContracts(contracts) {
      const nestedContracts = [];
      const mainContracts = contracts.filter((contract) => !contract.sourceContractId);
      const additionalContracts = contracts.filter((contract) => contract.sourceContractId);

      mainContracts.forEach((mainContract) => {
        const nestedAdditionalContracts = additionalContracts.filter((additionalContract) => mainContract.id === additionalContract.sourceContractId &&
          (mainContract.status !== 'NULLIFIED' ||
            (mainContract.status === 'NULLIFIED' && additionalContract.status === 'NULLIFIED')));

        nestedContracts.push({
          main: mainContract,
          nested: nestedAdditionalContracts,
        });
      });

      const unattachedAdditionalContracts = additionalContracts.filter((additionalContract) => !nestedContracts.some((nestedContract) => nestedContract.main.id === additionalContract.sourceContractId));

      unattachedAdditionalContracts.forEach((additionalContract) => {
        nestedContracts.push({
          main: additionalContract,
          nested: [],
        });
      });

      return nestedContracts;
    },
    checkEmptyAct(act) {
      if (act) {
        return { documentId: act.documentId, name: 'Акт верификации' };
      }
      return null;

    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          return this.link(cell.row);
        }
      }
    },
    link(task) {
      this.$router.push({
        name: 'cabinet.contract',
        params: {
          id: task.contractId,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    formatAdditionalContracts(additionalContracts) {
      if (!additionalContracts) {
        return null;
      }

      let resStr = '';

      additionalContracts.forEach((additional) => {
        resStr += `№${additional.contractInfo.number}, от ${convertDbFormat(additional.contractInfo.date)}; `;
      });

      return resStr;
    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    formatContractTitle(data) {
      const contractTypeTitle = {
        additional: '',
        contract: '',
      };

      if (this.contractType === 'ELECTRICITY_TRANSMISSION_CONTRACT') {
        contractTypeTitle.additional = 'договору по передаче ЭЭ';
        contractTypeTitle.contract = 'Договор по передаче ЭЭ';
      }
      if (this.contractType === 'ELECTRICITY_PURCHASE_CONTRACT') {
        contractTypeTitle.additional = 'договору купли-продажи электрической энергии';
        contractTypeTitle.contract = 'Договор купли-продажи электрической энергии';
      }

      if (data.sourceContractId) {
        return `Доп. соглашение к ${contractTypeTitle.additional}`;
      }

      switch (data.kindByExisting) {
        case 'ADDITIONAL': {
          return `Доп. соглашение к ${contractTypeTitle.additional}`;
        }
        case 'EXISTING': {
          return contractTypeTitle.contract;
        }
        case 'NEW': {
          return contractTypeTitle.contract;
        }
      }
    },
    goToPageByRoute({ action, type }) {
      return async () => {
        await this.resetDraft();

        const contractType = this.contractType;
        this.setContractMode({ action, type, contractType });

        return this.$router.push({
          name: `${this.$route.name}.upload`,
        });
      };
    },
    ...mapActions('document', ['loadDocument']),
    ...mapActions('electricityTransmissionContract', ['setContractMode', 'resetDraft']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
