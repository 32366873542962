<template>
  <div class="table-cell-custom-status">
    <div
        :style="`background-color: ${color ?? '#4CCB40'}`"
        class="table-cell-custom-status__circle"
    ></div>
    <div>{{ value }}</div>
  </div>
</template>

<script>

export default {
  name: 'VTableCellStatus',
  props: {
    value: { type: String, default: '' },
    color: { type: String, default: '#4CCB40' },
  },
};
</script>

<style lang="scss">
.table-cell-custom-status {
  display: flex;
  align-items: center;
  text-transform: capitalize;

  &__circle {
    min-width: 8px;
    min-height: 8px;
    border-radius: 24px;
    margin-right: 8px;
  }
}
</style>
