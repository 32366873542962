<template>
  <div class="spoiler">
    <details :class="w100 ? 'spoiler__details-w100' : ''" :open="isOpen" @click="onClick" @toggle="onToggle">
      <summary :style="customStyle ? customStyle : summaryStyle"><span v-if="preSummary" class="spoiler__pre-summary">{{ preSummary }} </span>{{
          summary
        }}
        <slot name="button"/>
      </summary>
      <div class="spoiler__details">
        <slot/>
      </div>
    </details>
  </div>
</template>

<script>
export default {
  name: 'VSpoiler',
  props: {
    summary: {
      type: String,
      required: true,
    },
    preSummary: {
      type: String,
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    w100: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    summaryStyle() {
      return this.disabled ? 'cursor: default !important; ' : '';
    },
  },
  methods: {
    onClick(e) {
      if (this.disabled) {
        e.preventDefault();
        return false;
      }
    },
    onToggle() {
      this.$emit('toggle');
    },
  },
};

</script>

<style lang="scss" scoped>
.spoiler {
  position: relative;

  summary {
    height: auto;
    padding: 8px 48px 8px 20px;

    display: flex;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #71757A;

    background: #F5F6F6;
    border-radius: 20px 20px 20px 20px;

    cursor: pointer;
  }

  &__pre-summary {
    font-weight: 600;
  }

  summary:first-letter {
    text-transform: uppercase;
  }

  details > summary {
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none
  }

  summary::after {
    content: url('~icons/spoiler/close.svg');
    position: absolute;
    right: 20px;
  }

  details {
    border: 0;
  }

  details[open] {
    border: 1px solid #F5F6F6;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 20px;

    summary {
      border-radius: 20px 20px 0 0;
    }

    summary:after {
      content: url('~icons/spoiler/open.svg');;
      position: absolute;
      right: 20px;
    }
  }

  &__details {
    margin: 20px 56px 24px 56px;
  }

  &__details-w100 {
    width: 100%;
  }
}
</style>
