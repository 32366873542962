import moment from 'moment/moment';

export function validateSnils(snils, error) {
  let result = false;
  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }
  if (!snils.length) {
    error.code = 1;
    error.message = 'СНИЛС пуст';
  } else if (/[^0-9]/.test(snils)) {
    error.code = 2;
    error.message = 'СНИЛС может состоять только из цифр';
  } else if (snils.length !== 11) {
    error.code = 3;
    error.message = 'СНИЛС может состоять только из 11 цифр';
  } else {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true;
    } else {
      error.code = 4;
      error.message = 'Неправильное контрольное число для введенного СНИЛС';
    }
  }
  return result;
}

export function validateInn(inn, error) {
  let result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'ИНН пуст';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 10 или 12 цифр';
  } else {
    const checkDigit = function(inn, coefficients) {
      let n = 0;
      for (const i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10);
    };
    switch (inn.length) {
      case 10: {
        const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      }
      case 12: {
        const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true;
        }
        break;
      }
    }
    if (!result) {
      error.code = 4;
      error.message = 'Неправильное контрольное число для введенного ИНН';
    }
  }
  return result;
}

export function validateDateAfter(date, after, dateFormat, error) {
  let result = false;
  const formattedDate = moment(date, dateFormat);
  const formattedBeforeDate = moment(after, dateFormat);
  if (formattedDate.isAfter(formattedBeforeDate)) {
    error.code = 0;
    error.message = `Дата должна быть не позднее ${after}`;
  } else {
    result = true;
  }
  return result;
}

export function validateBik(bik, error) {
  let result = false;
  if (typeof bik === 'number') {
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    bik = '';
  }
  if (!bik.length) {
    error.code = 1;
    error.message = 'БИК пуст';
  } else if (/[^0-9]/.test(bik)) {
    error.code = 2;
    error.message = 'БИК может состоять только из 9 цифр';
  } else if (bik.length !== 9) {
    error.code = 3;
    error.message = 'БИК может состоять только из 9 цифр';
  } else {
    result = true;
  }
  return result;
}

export function validateKs(ks, error) {
  let result = false;
  if (typeof ks === 'number') {
    ks = ks.toString();
  } else if (typeof ks !== 'string') {
    ks = '';
  }
  if (!ks.length) {
    error.code = 1;
    error.message = 'Корреспондентский счёт пуст';
  } else if (/[^0-9]/.test(ks)) {
    error.code = 2;
    error.message = 'Корреспондентский счёт может состоять только из 20 цифр';
  } else if (ks.length !== 20) {
    error.code = 3;
    error.message = 'Корреспондентский счёт может состоять только из 20 цифр';
  } else {
    result = true;
  }
  return result;
}

export function validateRs(rs, error) {
  let result = false;
  if (typeof rs === 'number') {
    rs = rs.toString();
  } else if (typeof rs !== 'string') {
    rs = '';
  }
  if (!rs.length) {
    error.code = 1;
    error.message = 'Расчётный счёт пуст';
  } else if (/[^0-9]/.test(rs)) {
    error.code = 2;
    error.message = 'Расчётный счёт может состоять только из 20 цифр';
  } else if (rs.length !== 20) {
    error.code = 3;
    error.message = 'Расчётный счёт может состоять только из 20 цифр';
  } else {
    result = true;
  }
  return result;
}

export function validateKpp(kpp, error) {
  if (kpp.length === 9 && !isNaN(parseFloat(kpp)) && !isNaN(kpp - 0)) {
    return true;
  }
  error.message = 'КПП должен состоять из 9 цифр';
  return false;
}

export function validateTelegramAccount(value, error) {
  const regExpTelegramAccount = /^@[A-Za-z\d_]{5,32}$/;
  const isTelegramAccount = typeof value === 'string' &&
    regExpTelegramAccount.test(value) === true;
  if (isTelegramAccount) {
    return true;
  }
  error.message = 'Аккаунт Telegram должен начинаться с @ и содержать от 5 до 32 символов латинского алфавита, цифр или нижнего подчеркивания.';
  return false;
}

export function validatePassword(password, error) {
  if (password.match(/[а-яА-ЯЁё]/)) {
    error.message = 'Пароль не может содержать русские буквы';
    return false;
  }
  if (password.length < 12) {
    error.message = 'Пароль не менее 12 символов';
    return false;
  }
  if (!password.match(/[A-Z]/)) {
    error.message = 'Пароль должен включать как минимум одну заглавную букву';
    return false;
  }
  if (!password.match(/[a-z]/)) {
    error.message = 'Пароль должен включать как минимум одну строчную букву';
    return false;
  }
  if (!password.match(/[0-9]/)) {
    error.message = 'Пароль должен включать как минимум одну цифру';
    return false;
  }
  if (!password.match(/[!@#$%^&]/)) {
    error.message = 'Пароль должен включать как минимум один специальный символ (например: !, @, $)';
    return false;
  }

  return true;
}

export function greaterZero(value, error) {
  if (isNaN(parseFloat(value))) {
    error.message = 'Значение должно быть числом';
    return false;
  }

  if (parseFloat(value) <= 0) {
    error.message = 'Значение должно быть больше нуля';
    return false;
  }

  return true;
}

export function validateOgrn(ogrn, error) {
  let result = false;
  if (typeof ogrn === 'number') {
    ogrn = ogrn.toString();
  } else if (typeof ogrn !== 'string') {
    ogrn = '';
  }
  if (!ogrn.length) {
    error.code = 1;
    error.message = 'ОГРН пуст';
  } else if (/[^0-9]/.test(ogrn)) {
    error.code = 2;
    error.message = 'ОГРН может состоять только из цифр';
  } else if (ogrn.length !== 13) {
    error.code = 3;
    error.message = 'ОГРН может состоять только из 13 цифр';
  } else {
    const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
    if (n13 === parseInt(ogrn[12])) {
      result = true;
    } else {
      error.code = 4;
      error.message = 'Неправильное контрольное число';
    }
  }
  return result;
}

export function validateOkved(value, error) {
  const regExpOkvedCode = /^(\d\d)(\.\d(\d(\.\d{1,2})?)?)?$/;
  const isOkved = typeof value === 'string' && regExpOkvedCode.test(value) === true;
  if (!isOkved) {
    error.message = 'Неверный формат';
    return false;
  }
  return true;
}

export function validateOktmo(value, error) {
  if ((value.length === 8 || value.length === 11) && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'ОКТМО должен состоять из 8 или 11 цифр';
  return false;
}

export function validateOkpo(value, error) {
  if (!value) {
    error.message = 'ОКПО должен состоять из 8 или 10 цифр';
    return false;
  }

  if ((value.length === 8 || value.length === 10) && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'ОКПО должен состоять из 8 или 10 цифр';
  return false;
}
