<template>
  <div>
    <odk-tag-modal v-model="tagsModalState" @updated="fetchAxpInExploitation"/>
    <v-app class="vuetify-styles" data-app>

      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
              <span class="text-h5 align-center">{{ title }}</span>
            </v-card-title>
            <v-card-text>
              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"/>
              <div>
                <yandex-map
                  ref="customMap"
                  :collection-axp="axpItemsFiltered"
                  @tapPlacemark="onTapPlacemark"
                />
              </div>
              <v-data-table
                v-model="selectedAdreskaFact"
                :headers="headersTable"
                :items="axpItemsFiltered"
                :loading="isLoadingAxp"
                :search="search"
                class="elevation-1 mb-4"
                item-key="id"
                return-object
                show-select
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.id)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>

                <template #item.status="{ item }">
                  {{
                    item.status
                  }}
                </template>
              </v-data-table>


              <simple-autocomplete-by-function
                ref="contractor"
                v-model="contractorId"
                :fetch-data-function="contractorByName"
                :fetch-default-data-function="contractorByName"
                :items.sync="contractors"
                :search.sync="contractorName"
                is-required
                title="Подрядчик"
              ></simple-autocomplete-by-function>

              <simple-autocomplete-by-function
                ref="adreska"
                v-model="adreskaId"
                :fetch-data-function="adreskaByName"
                :fetch-default-data-function="adreskaByName"
                :items.sync="adreskas"
                :search.sync="adreskaName"
                is-required
                title="Программа оформления"
              ></simple-autocomplete-by-function>

              <v-card v-if="localRepair != null" class="mb-6">
                <v-card-title>
              <span class="text-h5 align-center"
              >Информация об устранении
              </span>
                </v-card-title>
                <v-expansion-panels multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                    >Устранение замечаний подрядчиком
                    </v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                    >
                      <text-field
                        ref="comment"
                        v-model="localRepair.comment"
                        is-disabled
                        title="Комментарий"
                      ></text-field>
                      <file-input
                        ref="photos"
                        v-model="localRepair.photos"
                        class="mt-2"
                        only-view
                        title="Фото устранения"
                      ></file-input>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>

              <text-field
                ref="countPlan"
                :text="countPlan"
                class="mt-2"
                is-disabled
                title="Количество по плану"
              ></text-field>

              <text-field
                ref="count"
                :text="count"
                class="mt-2"
                is-disabled
                title="Количество к осмотру"
              ></text-field>

              <file-input
                ref="photos"
                v-model="photos"
                class="mt-2"
                title="Фото"
              ></file-input>

              <v-row class="no-margin-top mt-1">
                <v-checkbox
                  v-model="isDefectNotFound"
                  class="no-margin-top"
                  color="success"
                  hide-details
                  label="Дефекты не выявлены"
                ></v-checkbox>
              </v-row>

              <div v-if="!isDefectNotFound">
                <v-row class="mt-7">
                  <v-col>
                    <div>
                      <v-label>Комментарий</v-label>
                    </div>
                    <v-textarea
                      v-model="commentDefect"
                      dense
                      name="input-7-4"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-data-table
                  v-if="adreskaFactIds.length"
                  :headers="headersNewDefects"
                  :items="newDefects"
                >
                  <template #item.actions="{ item }">
                    <v-icon v-if="!item.id" class="error-label" small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template #top>
                    <v-btn
                      color="primary"
                      elevation="0"
                      rounded
                      style="width: 100%"
                      @click="addDefect"
                    >
                      Добавить дефект
                    </v-btn>
                  </template>
                  <template #item.defectId="{ item }">
                    <defect-autocomplete
                      :key="`defectId${item.id}`"
                      v-model="item.defectId"
                      :is-disabled="Boolean(item.id)"
                      :items.sync="item.defects"
                      :search.sync="item.defectName"
                      is-required
                      style="min-width: 100px"
                    ></defect-autocomplete>
                  </template>
                  <template #item.defectDescription="{ item }">
                    <v-textarea
                      :key="`desc${item.id}`"
                      v-model="item.defectDescription"
                      :disabled="Boolean(item.id)"
                      class="mt-8"
                      dense
                      name="input-7-4"
                      outlined
                      style="min-width: 100px"
                    ></v-textarea>
                  </template>
                  <template #item.photos="{ item }">
                    <file-input
                      :key="`photos${item.id}`"
                      v-model="item.photos"
                      :only-view="!!item.id"
                      style="min-width: 100px"
                    ></file-input>
                  </template>
                  <template #item.criticalityId="{item}">
                    <simple-autocomplete
                      :key="`criticality${item.id}`"
                      v-model="item.criticalityId"
                      :is-disabled="Boolean(item.id)"
                      :items.sync="item.criticalityItems"
                      :search.sync="item.criticalityName"
                      is-required
                      style="min-width: 100px"
                      url="/criticality/find?name="
                      url-default-data="/criticality"
                    ></simple-autocomplete>
                  </template>
                  <template #item.isAgain="{item}">
                    <div v-if="!item.isNew" style="justify-content: center; display: flex">
                      <v-checkbox
                        v-model="item.isAgain"
                        class="no-margin-top mb-1"
                        color="success"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #item.warranty="{item}">
                    <div v-if="!item.isNew" style="justify-content: center; display: flex">
                      <v-checkbox
                        v-model="item.warranty"
                        class="no-margin-top mb-1"
                        color="success"
                        :disabled="Boolean(item.id)"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #item.foundBy="{ item }">
                    <text-field
                      :key="`foundBy${item.id}`"
                      v-model="item.foundBy"
                      :is-disabled="
                        Boolean(item.id) ||
                        modeInternal !== inspectionScreenModes.ALL
                      "
                      is-required
                      style="min-width: 100px"
                    ></text-field>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabledSaveDialog"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSend"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog v-model="loaderDialog"
                  class="v-dialog-top" persistent width="250">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + "%" }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapPrint"
              >
                Распечатать aкт осмотра
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTableDelete"
                  class="v-dialog-top" max-width="500px">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center"
            >Вы уверены что хотите удалить?
            </v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="dialogTableDelete = false"
              >Отмена
              </v-btn
              >
              <v-btn
                class="ma-2"
                color="error"
                outlined
                rounded
                @click="onTapDeleteDefect"
              >
                <v-icon class="label-error pr-1">mdi-delete</v-icon>
                Удалить
              </v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import SimpleAutocomplete from '@/components/Processes/components/SimpleAutocomplete.vue';
import TextField from '@/components/Processes/components/TextField.vue';
import FileInput from '@/components/Processes/components/FileInput.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { oswaldRegularString } from '@/assets/fonts/oswald/Oswaldregular';
import { formatDateFromOdkTimestamp, getCurrentDate } from 'lib/utils/date';
import { distinctBy } from 'utils/arrays/arrays';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { inspectionScreenModes } from 'components/Processes/Process11/process5-7/constants';
import { inConstants } from 'lib/component/validators';
import { regularInspectionTaskType } from 'components/Processes/list';
import DefectAutocomplete from '@/components/Processes/components/DefectAutocomplete.vue';

export default {
  components: {
    OdkTagModal,
    SimpleAutocompleteByFunction,
    YandexMap,
    SimpleAutocomplete,
    TextField,
    FileInput,
    DefectAutocomplete,
  },

  name: 'Form1',
  props: {
    mode: {
      type: String,
      default: inspectionScreenModes.NOT_SPECIFIED,
      validator: inConstants(inspectionScreenModes),
    },
  },
  async created() {
    await this.fetchAxpInExploitation();
    if (this.$route.params.initAdreskaFactId) {
      console.log('axp', this.axpItems);
      const adreskaFact = this.axpItems.find((it) => it.adreskaFactId === this.$route.params.initAdreskaFactId);
      if (!adreskaFact) {
        await this.setNotification({ message: 'Данный объект не закреплен за Вами или не находится в эксплуатации' });
        this.onTapBack();
        return;
      }
      await this.onTapPlacemark(adreskaFact.id);
    }
  },

  data() {
    return {
      inspectionScreenModes: inspectionScreenModes,
      tagsFilter: null,

      headersDefects: [
        { text: 'Дефект найден', value: 'description', align: 'center' },
        {
          text: 'Дефект',
          align: 'start',
          value: 'defectName',
        },
        {
          text: 'Комментарий к дефекту',
          align: 'start',
          value: 'defectDescription',
        },
        { text: 'Фото', value: 'photos', align: 'center' },
      ],

      headersNewDefects: [
        { text: 'Дефект', value: 'defectId', align: 'center', justify: 'start', sortable: false },
        {
          text: 'Комментарий к дефекту',
          align: 'center',
          value: 'defectDescription', sortable: false,
        },
        { text: 'Фото', value: 'photos', align: 'center', sortable: false, width: '200px' },
        { text: 'Критичность дефекта', value: 'criticalityId', align: 'center', sortable: false },
        {
          text: 'ФИО выявившего дефект',
          value: 'foundBy',
          align: 'center',
          sortable: false,
        },
        { text: 'ID ОДК', value: 'adreskaFactId', align: 'center', sortable: false },
        {
          text: 'Гарантийный',
          value: 'warranty',
          align: 'center',
          sortable: false,
        },
        { text: 'Выявлен повторно', value: 'isAgain', align: 'center', sortable: false },
        { text: '', align: 'end', value: 'actions', sortable: false },
      ],
      newDefects: [],

      dialogTable: false,
      editId: -1,
      editedItem: {
        defectId: null,
        defectName: null,
        defects: [],
        defectDescription: null,
        photos: [],
        countPhotos: 0,
      },
      tagsModalState: null,
      adreskaId: null,
      adreskaName: null,
      adreskas: [],
      contractorId: null,
      contractorName: null,
      contractors: [],
      inspectionTaskId: null,
      isDefectNotFound: false,
      commentDefect: null,
      isOldDefectNotValid: false,
      localRepair: null,

      photos: [],
      criticalityId: null,
      criticalityName: '',
      criticalityItems: [],
      modalDate: false,
      isDateValid: true,
      dates: [],
      datesForText: [],
      startHour: '00',
      startMinutes: '00',
      endHour: '00',
      endMinutes: '00',

      expanded: [],
      search: null,
      axpItems: [],
      isLoadingAxp: false,
      updateMap: 0,

      departmentId: null,
      departmentName: '',
      departments: [],
      employeeId: null,
      employeeFullName: '',
      employees: [],

      progressValue: 0,
      loaderDialog: false,

      dialogTableDelete: false,
      axpItemForInspectionId: null,
      selectedAdreskaFact: [],
      defectsTypes: [],
    };
  },
  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        { text: 'ID', value: 'adreskaFactId', align: 'center' },
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        {
          text: 'Наименование АХП',
          align: 'start',
          value: 'axpName',
        },
        {
          text: 'Программа оформления',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Подрядчик',
          align: 'start',
          value: 'contractorName',
        },
        { text: 'Критичность', value: 'criticalityName', align: 'center' },
        { text: 'Дата проверки', value: 'dateInspection', align: 'center' },
        { text: 'Статус осмотра', value: 'status', align: 'center' },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    modeInternal() {
      if (this.mode !== this.inspectionScreenModes.NOT_SPECIFIED) return this.mode;
      if (this.canInspectAll) return this.inspectionScreenModes.ALL;
      if (this.canInspectRegular) return this.inspectionScreenModes.REGULAR;
      return this.inspectionScreenModes.NON_REGULAR;
    },
    adreskaFactIds() {
      return this.selectedAdreskaFact.map((it) => it.id);
    },
    canInspectAll() {
      return filterForAccess('process/11/7', this.getAuthorization.blockAccess);
    },
    canInspectRegular() {
      return filterForAccess('process/11/6', this.getAuthorization.blockAccess);
    },
    title() {
      if (this.modeInternal === this.inspectionScreenModes.REGULAR) return 'Регулярный осмотр';
      else if (this.modeInternal === this.inspectionScreenModes.ALL) return 'Внесение дефектов';
      return 'Осмотр';
    },
    isDisabledSaveDialog() {
      const isDisabledInspection =
        this.selectedAdreskaFact.length === 0;
      let isDisableDefects = false;
      if (!this.isDefectNotFound) {
        isDisableDefects = this.newDefects.filter((it) => !it.id || it.isAgain).length === 0 || this.newDefects.some((it) => !it.id && (!it.defectId || !it.photos.length || !it.criticalityId || (!it.defectDescription && this.defectsTypes.find((defectType) => defectType.id == it.defectId)?.mnemonic === 'CUSTOM')));
      }
      return (
        isDisabledInspection ||
        isDisableDefects
      );
    },
    distinctContractors() {
      return distinctBy(this.axpItems.map((it) => ({
        id: it.contractorId,
        name: it.contractorName,
      })), (it) => it);
    },
    distinctAdreskasFilteredByContractor() {
      const distinctAdreskas = distinctBy(this.axpItems, (it) => it.adreskaId).map((it) => ({
        id: it.adreskaId,
        name: it.name,
        contractorId: it.contractorId,
        countPlan: it.countPlan,
      }));
      if (!this.contractorId) return distinctAdreskas;
      return distinctAdreskas.filter((it) => it.contractorId === this.contractorId);
    },
    axpItemsFiltered() {
      let filtered = this.axpItems;
      if (this.adreskaId) filtered = filtered.filter((it) => it.adreskaId === this.adreskaId);
      if (this.contractorId) filtered = filtered.filter((it) => it.contractorId === this.contractorId);
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      return filtered;
    },
    selectedAdreska() {
      return this.distinctAdreskasFilteredByContractor.find((it) => it.id === this.adreskaId);
    },
    countPlan() {
      return this.selectedAdreska?.countPlan;
    },
    count() {
      return this.selectedAdreskaFact.length;
    },
    userFio() {
      const getFullNameByUser = (user) => (user.fullName ? `${user.fullName.surname} ${user.fullName.name} ${user.fullName.patronymic ?? ''}` : `Пользователь ${user.id}`);

      return getFullNameByUser(this.getUser);
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getToken', 'getAuthorization', 'getUser']),
  },

  watch: {
    distinctContractors() {
      if (this.contractorName == null) {
        this.contractorName = '';
      }
    },
    distinctAdreskasFilteredByContractor() {
      if (this.adreskaName == null) {
        this.adreskaName = '';
      }
    },
    async adreskaFactIds() {
      this.onItemSelected();
      await this.fetchDefects();
    },
    adreskaId(newVal, oldVal) {
      if (oldVal !== newVal && oldVal) {
        this.selectedAdreskaFact = [];
      }
      if (this.adreskaId != null) {
        this.contractorId = this.selectedAdreska.contractorId;
        this.$refs.contractor.resetValidation();
      }
    },
  },

  methods: {
    async fetchDefects() {
      const response = await fetch(`${BASE_URL}/adreska-fact/byIds/defects/actual?ids=${this.adreskaFactIds.join(',')}`);
      const data = await response.json();
      this.newDefects = data.map((it) => ({
        criticalityItems: [{
          id: it.criticality.id,
          name: it.criticality.name,
        }],
        criticalityId: it.criticality.id,
        criticalityName: it.criticality.name,

        defectDescription: it.defectDescription,
        adreskaFactId: it.adreskaFact.id,
        defects: [{
          id: String(it.defect.id),
          name: it.defect.name,
        }],
        foundBy: it.foundBy,
        defectId: String(it.defect.id),
        defectName: it.defect.name,
        photos: it.photos.map((it) => ({
          fileId: it.id,
          file: null,
          name: it.name,
          previewUrl:
            `${BASE_URL}/api/get/${it.folder}/${it.name}`,
          new: false,
        })),
        isNew: false,
        id: it.id,
        isAgain: false,
        warranty: it.warranty,
      }));
    },
    openTags(id) {
      this.tagsModalState = id;
    },
    findByName(list, name) {
      if (!list) return [];
      if (!name) return [...list];
      return list.filter((it) => it.name.toLowerCase().includes(name.toLowerCase()));
    },
    contractorByName(name) {
      return this.findByName(this.distinctContractors, name);
    },
    adreskaByName(name) {
      return this.findByName(this.distinctAdreskasFilteredByContractor, name);
    },

    deleteItem(item) {
      this.editId = this.newDefects.indexOf(item);
      this.dialogTableDelete = true;
    },

    onTapDeleteDefect() {
      this.newDefects.splice(this.editId, 1);
      this.editId = -1;
      this.dialogTableDelete = false;
    },

    addDefect() {
      this.newDefects.push({
        isNew: true,
        isAgain: false,
        isWarranty: false,
        foundBy: this.userFio,
        photos: [],
      });
    },

    cancel() {
      this.editId = -1;
    },

    save(dates) {
      this.modalDate = false;
      this.datesForText = dates.sort();
    },

    onTapPlacemark(id) {
      const item = this.axpItemsFiltered.find((it) => it.id === id);
      if (this.selectedAdreskaFact.some((it) => it.id === id)) {
        this.selectedAdreskaFact = this.selectedAdreskaFact.filter((it) => it.id !== id);
        item.balloonContent.buttonLabel = 'Выбрать';
      } else {
        item.balloonContent.buttonLabel = 'Отменить';
        this.selectedAdreskaFact.push(item);
      }
      this.axpItems = [...this.axpItems];
    },

    onItemSelected() {
      if (!this.adreskaId) {
        const id = this.adreskaFactIds[0];
        this.adreskaId = this.axpItemsFiltered.find((it) => it.id === id)?.adreskaId;
      }
      const set = new Set(this.adreskaFactIds);
      this.axpItems = this.axpItems.map((it) => {
        const selected = set.has(it.id);
        return {
          ...it,
          isSelected: selected,
          balloonContent: {
            ...it.balloonContent,
            buttonLabel: selected ? 'Отменить' : 'Выбрать',
          },
        };
      });
    },

    async fetchAxpInExploitation() {
      this.isLoadingAxp = true;
      this.axpItems = [];

      console.log('mode', this.modeInternal);
      switch (this.modeInternal) {
        case this.inspectionScreenModes.REGULAR: {
          await this.fetchAxpInExploitationForRegular();
          break;
        }
        case this.inspectionScreenModes.NON_REGULAR: {
          await this.fetchAxpInExploitationForNonRegular();
          break;
        }
        case this.inspectionScreenModes.ALL: {
          await this.fetchAxpInExploitationForAll();
          break;
        }
      }

      this.updateMap = this.updateMap + 1;
      const defectsResponse = await fetch(`${BASE_URL}/defects`);
      const defectsData = await defectsResponse.json();
      this.defectsTypes = defectsData;
      this.isLoadingAxp = false;
    },


    async fetchAxpInExploitationForRegular() {

      // Получаем объекты
      const response = await fetch(`${BASE_URL}/axp/exploitation/regular-inspection-assigned`, {
        headers: {
          'X-Authorization': `Bearer ${this.getToken}`,
        },
      });
      const data = await response.json();


      // Получаем таски
      const distinctAdreska = distinctBy(data.map((it) => ({ ...it.adreska })), (it) => it.id);
      const adreskaIds = distinctAdreska.map((it) => it.id).join(',');
      const inspectionsResponse = await fetch(
        `${BASE_URL}/adreska/byIds/regular-inspection-tasks/assigned?type=${regularInspectionTaskType.INSPECTION}&ids=${adreskaIds}`,
        { headers: { 'X-Authorization': `Bearer ${this.getToken}` } },
      );
      const inspectionData = await inspectionsResponse.json();

      data.forEach((it) => {
        const inspection = inspectionData[it.adreska.id][0];
        let color;
        let status;
        if (it.statusInspection == 0) {
          color = 'blue';
          status = 'Осмотр не проводился';
        } else if (it.statusInspection == 3) {
          color = 'green';
          status = 'Осмотр завершен без замечаний';
        } else if (it.statusLocalRepair == 2) {
          color = 'orange';
          status = 'После ремонта';
        } else {
          color = 'red';
          status = 'Ожидает ремонта';
        }

        const axp = {
          id: it.id,
          regularInspectionTaskId: inspection.id,
          adreskaFactId: it.id,
          contractorId: it.adreska.contractorId,
          odkName: it.axp.odk.name,
          axpName: it.axp.name,
          address: it.adreska.address,
          contractorName: it.adreska.contractorName,
          adreskaId: it.adreska.id,
          name: it.adreska.name,
          tags: it.adreska.tags.map((it) => it.name).join(', '),
          criticalityName: inspection?.criticality?.name,
          dateInspection: inspection.dates?.map((date) => formatDateFromOdkTimestamp(date))?.join(', ') ?? '',
          color: color,
          locationLatitude: it.locationLatitude,
          locationLongitude: it.locationLongitude,
          statusInspection: it.statusInspection,
          statusLocalRepair: it.statusLocalRepair,
          status: status,
          countPlan: it.adreska.quantityPlan,
          count: it.countOdk,
          isSelected: false,
          balloonContent: {
            title: it.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: it.statusLocalRepair == 2 ? [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Статус осмотра',
                value: status,
              },
            ] : [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Критичность',
                value: inspection?.criticality?.name,
              },
              {
                label: 'Статус осмотра',
                value: status,
              },
            ],
          },
        };
        this.axpItems.push(axp);
      });
    },
    formattedTime(unixTimestamp) {
      const hours =
        Math.trunc(unixTimestamp / 60) > 9
          ? Math.trunc(unixTimestamp / 60)
          : `${Math.trunc(unixTimestamp / 60)}0`;
      const minutes =
        unixTimestamp % 60 > 9
          ? unixTimestamp % 60
          : `${unixTimestamp % 60}0`;
      return `${hours}:${minutes}`;
    },
    async fetchAxpInExploitationForNonRegular() {
      const response = await fetch(`${BASE_URL}/inspection-tasks`, {
        headers: {
          'X-Authorization': `Bearer ${this.getToken}`,
        },
      });
      const data = await response.json();
      this.axpItems = data.map((it) => {
        const assignmentForInspection =
          it.installationAssignmentForInspection[0]?.assignmentForInspection;

        const dateInspection = assignmentForInspection
          ? `${this.formattedDate(assignmentForInspection.startInspectionDate)
          } ~ ${
            this.formattedDate(assignmentForInspection.endInspectionDate)
          } ${
            this.formattedTime(
              this.formattedDate(assignmentForInspection.startInspectionTime),
            )
          } ~ ${
            this.formattedTime(assignmentForInspection.endInspectionTime)}` : '';
        let color = '';
        let status = '';
        if (it.adreskaFact.statusInspection == 0) {
          color = 'blue';
          status = 'Осмотр не проводился';
        } else if (it.statusInspection == 3) {
          color = 'green';
          status = 'Осмотр завершен без замечаний';
        } else if (it.adreskaFact.statusLocalRepair == 2) {
          color = 'orange';
          status = 'После ремонта';
        } else {
          color = 'red';
          status = 'Ожидает ремонта';
        }
        return {
          id: it.adreskaFact.id,
          adreskaFactId: it.adreskaFact.id,
          inspectionTaskId: it.id,
          adreskaId: it.adreskaFact.adreska.id,
          contractorId: it.adreskaFact.adreska.contractorId,
          odkName: it.adreskaFact.axp.odk.name,
          axpName: it.adreskaFact.axp.name,
          name: it.adreskaFact.adreska.name,
          address: it.adreskaFact.adreska.address,
          contractorName: it.adreskaFact.adreska.contractorName,
          status: status,
          criticalityName: assignmentForInspection?.criticality?.name,
          dateInspection: dateInspection,
          color: color,
          countPlan: it.adreskaFact.adreska.quantityPlan,
          locationLatitude: it.adreskaFact.locationLatitude,
          locationLongitude: it.adreskaFact.locationLongitude,
          statusInspection: it.adreskaFact.statusInspection,
          statusLocalRepair: it.adreskaFact.statusLocalRepair,
          count: it.adreskaFact.countOdk,
          tags: it.adreskaFact.adreska.tags.map((it) => it.name).join(', '),
          isSelected: false,
          balloonContent: {
            title: it.adreskaFact.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: it.adreskaFact.statusLocalRepair == 2 ? [
              {
                label: 'ID',
                value: it.adreskaFact.id,
              },
              {
                label: 'АХП',
                value: it.adreskaFact.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.adreskaFact.decorType?.name,
              }, {
                label: 'Статус осмотра',
                value: status,
              },
            ] : [
              {
                label: 'ID',
                value: it.adreskaFact.id,
              },
              {
                label: 'АХП',
                value: it.adreskaFact.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.adreskaFact.decorType?.name,
              },
              {
                label: 'Критичность',
                value: assignmentForInspection?.criticality?.name,
              },
              {
                label: 'Дата проверки',
                value: dateInspection,
              },
              {
                label: 'Статус осмотра',
                value: status,
              },
            ],
          },
        };
      });


    },

    async fetchAxpInExploitationForAll() {
      const response = await fetch(`${BASE_URL}/axp/exploitation`, {
        headers: {
          'X-Authorization': `Bearer ${this.getToken}`,
        },
      });
      const data = await response.json();
      this.axpItems = data.map((it) => {
        let color = '';
        let status = '';
        if (it.statusInspection == 0) {
          color = 'blue';
          status = 'Осмотр не проводился';
        } else if (it.statusInspection == 3) {
          color = 'green';
          status = 'Осмотр завершен без замечаний';
        } else if (it.statusLocalRepair == 2) {
          color = 'orange';
          status = 'После ремонта';
        } else {
          color = 'red';
          status = 'Ожидает ремонта';
        }
        return {
          id: it.id,
          adreskaFactId: it.id,
          adreskaId: it.adreska.id,
          contractorId: it.adreska.contractorId,
          odkName: it.axp.odk.name,
          axpName: it.axp.name,
          name: it.adreska.name,
          address: it.adreska.address,
          contractorName: it.adreska.contractorName,
          status: status,
          color: color,
          locationLatitude: it.locationLatitude,
          locationLongitude: it.locationLongitude,
          statusInspection: it.statusInspection,
          statusLocalRepair: it.statusLocalRepair,
          isSelected: false,
          count: it.countOdk,
          countPlan: it.adreska.quantityPlan,
          tags: it.adreska.tags.map((it) => it.name).join(', '),
          balloonContent: {
            title: it.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: it.statusLocalRepair == 2 ? [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Статус осмотра',
                value: status,
              },
            ] : [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Статус осмотра',
                value: status,
              },
            ],
          },
        };
      });
    },

    formattedDate(unixTimestamp) {
      const newDate = new Date(unixTimestamp * 1000);
      let date = newDate.getDate();
      date = date < 10 ? `0${date}` : date;
      let month = newDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      const newDateText = `${newDate.getFullYear()}-${month}-${date}`;
      if (newDateText == '1970-01-01') {
        return unixTimestamp;
      }
      return newDateText;

    },

    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;
      const defects = [];
      if (!this.isDefectNotFound) {
        this.newDefects.forEach((it) => {
          const defectPhotos = [];
          it.photos.forEach((doc) => {
            defectPhotos.push({
              stringBase64: doc.previewUrl,
              fileName: doc.name,
            });
          });
          defects.push({
            id: it.id,
            defectId: it.defectId,
            description: it.defectDescription,
            photos: defectPhotos,
            criticalityId: it.criticalityId,
            foundBy: this.modeInternal === inspectionScreenModes.ALL ? it.foundBy : null,
            isAgain: it.isAgain,
          });
        });
      }

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });
      const selected = this.selectedAdreskaFact[0];

      const request = {
        regularInspectionTaskId: selected.regularInspectionTaskId,
        inspectionTaskId: selected.inspectionTaskId,
        contractorId: selected.contractorId,
        adreskaFactIds: this.adreskaFactIds,
        adreskaId: selected.adreskaId,
        isDefectNotFound: this.isDefectNotFound,
        commentDefect: this.commentDefect,
        defects: defects,
        photos: mainPhotos,
      };

      axios
        .post(`${BASE_URL}/inspection`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      window.location.reload();
    },
    onTapPrint() {
      // this.generatePdf().save('Акт визуального осмотра.pdf');
      this.generatePdf().autoPrint({ variant: 'non-conform' }).output('pdfobjectnewwindow', { filename: 'Акт визуального осмотра.pdf' });
    },
    generatePdf() {
      const doc = new jsPDF();

      doc.addFileToVFS('Oswaldregular.ttf', oswaldRegularString);
      doc.addFont('Oswaldregular.ttf', 'Oswaldregular', 'normal');
      doc.setFont('Oswaldregular');

      const fontStyle = { font: 'Oswaldregular', fontStyle: 'normal' };

      const indent = 14;
      const lineSpacing = 8;
      const font = {
        base: 9,
        title: 14,
        subtitle: 10,
      };

      const selected = this.selectedAdreskaFact[0];

      const headers = ['N', 'Наименование', 'Выявленные замечания', 'Прим.'];
      const body = [];

      this.newDefects.filter((it) => !it.id || it.isAgain).forEach((it, index) => {
        body.push([index + 1, selected.odkName, it.defectName, it.defectDescription]);
      });

      if (this.commentDefect) {
        body.push(['', '', `Комментарий:\n${this.commentDefect}`, '']);
      }

      let lineNumber = 1;

      function getY() {
        return 10 + (lineNumber * lineSpacing);
      }

      doc.setFontSize(font.title);
      doc.text('АКТ', doc.internal.pageSize.getWidth() / 2, getY(), { align: 'center', ...fontStyle });
      lineNumber++;
      doc.text('ВИЗУАЛЬНОГО ОСМОТРА N _____', doc.internal.pageSize.getWidth() / 2, getY(), { align: 'center', ...fontStyle });

      lineNumber++;
      doc.setFontSize(font.base);
      doc.text('г. Москва', indent, getY(), { align: 'left', ...fontStyle });
      doc.text(getCurrentDate('DD.MM.YYYY'), doc.internal.pageSize.getWidth() - indent, getY(), { align: 'right', ...fontStyle });

      lineNumber += 2;
      doc.setFontSize(font.subtitle);
      doc.text('Комиссией в составе:', indent, getY(), { align: 'left', ...fontStyle });

      lineNumber++;
      doc.line(indent, getY(), doc.internal.pageSize.getWidth() - indent, getY());

      lineNumber++;
      doc.line(indent, getY(), doc.internal.pageSize.getWidth() - indent, getY());

      lineNumber++;
      doc.line(indent, getY(), doc.internal.pageSize.getWidth() - indent, getY());

      lineNumber++;
      doc.line(indent, getY(), doc.internal.pageSize.getWidth() - indent, getY());

      lineNumber++;
      doc.setFontSize(font.subtitle);

      const subTitle1 = 'Произведен осмотр: ';
      doc.text(subTitle1, indent, getY(), { align: 'left', ...fontStyle });

      doc.setFontSize(font.base);
      doc.text(selected.odkName, indent + doc.getTextWidth(subTitle1) + 4, getY(), { align: 'left', ...fontStyle });

      lineNumber++;
      doc.setFontSize(font.subtitle);

      const subTitle2 = 'По адресу: ';
      doc.text(subTitle2, indent, getY(), { align: 'left', ...fontStyle });

      doc.setFontSize(font.base);
      doc.text(selected.address, indent + doc.getTextWidth(subTitle2) + 4, getY(), { align: 'left', ...fontStyle });

      lineNumber++;
      doc.setFontSize(font.subtitle);
      doc.text('На момент осмотра комиссией установлено:', indent, getY(), { align: 'left', ...fontStyle });

      lineNumber += 1;

      const tb = doc.autoTable({
        head: [headers],
        body: body,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          fillColor: '#f37a1f',
        },
        styles: {
          font: 'Oswaldregular',
          fontStyle: 'normal',
        },
        startY: getY(),
      });

      const signLineY = doc.lastAutoTable.finalY + (2 * lineSpacing);
      const signLineX1 = doc.internal.pageSize.getWidth() / 2;
      const signLineX2 = doc.internal.pageSize.getWidth() - indent;

      tb.line(signLineX1, signLineY, signLineX2, signLineY);
      tb.line(signLineX1, signLineY + lineSpacing, signLineX2, signLineY + lineSpacing);
      tb.line(signLineX1, signLineY + (2 * lineSpacing), signLineX2, signLineY + (2 * lineSpacing));
      tb.line(signLineX1, signLineY + (3 * lineSpacing), signLineX2, signLineY + (3 * lineSpacing));

      return doc;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>
