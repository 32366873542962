import { boiler } from './userTypes/boiler';
import { grandSupplier } from './userTypes/grandSupplier';
import { legalUser } from './userTypes/legalUser';
import { platform } from './userTypes/platform';
import { retailer } from './userTypes/retailer';
import { user } from './userTypes/user';
import { newUser } from './userTypes/newUser';
import { supplier } from './userTypes/supplier';
import { counterparty } from './userTypes/counterparty';

export const getItemsByAccountKind = (userType) => {
  if (!userType) {
    return newUser;
  }
  switch (userType) {
    case 'PLATFORM': {
      return platform;
    }
    case 'UTILITY': {
      return supplier;
    }
    case 'RETAILER': {
      return retailer;
    }
    case 'INDIVUDUAL_CONSUMER': {
      return user;
    }
    case 'LEGAL_CONSUMER': {
      return legalUser;
    }
    case 'BOILER': {
      return boiler;
    }
    case 'SUPPLIER_OF_LAST_RESORT': {
      return grandSupplier;
    }
    case 'COUNTERPARTY': {
      return counterparty;
    }
    default: {
      return user;
    }
  }
};
