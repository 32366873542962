<template>
  <svg fill="none" :height="height" viewBox="0 0 16 16" :width="width" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3171_32626)">
      <path
        d="M7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM11.333 8.66683H8.66634V11.3335H7.33301V8.66683H4.66634V7.3335H7.33301V4.66683H8.66634V7.3335H11.333V8.66683Z"
        fill="#2F82DF" />
    </g>
    <defs>
      <clipPath id="clip0_3171_32626">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AddCircle',
  props: {
    height: { type: String, default: '16' },
    width: { type: String, default: '16' },
  },
};
</script>
