<template>
  <div :class="$style.stepContainer">
    <div :class="$style.gridFluid">
      <c-checkbox
        v-if="!isLegal"
        v-model="isAdditionalOwner"
        label="Собственник другой человек, или есть долевая собственность"
        role="additionalOwner"
        @input="changeIsAdditionalOwner()"
      />
    </div>

    <template v-if="additionalOwner.ownerGroups.length > 0">
      <div :class="$style.titleContainer">
        <h2 :class="$style.title">
          <step-counter :step-number="2" :total-steps="totalSteps"/>
          <span>Согласие собственника или владельцев долевой собственности</span>
        </h2>
      </div>

      <div :class="$style.noteContainer">
        <p>Получите <a
          href="https://uneco.ru/rekomenduemye-formy-doverennosti-dlya-sdachi-i-polucheniya-dokumentov-v-ao-oek-0"
          target="_blank">доверенность</a> на подключение от всех собственников или владельцев долевой собственности
          объекта и их <a
            href="https://uneco.ru/formy-soglasiy-na-obrabotku-personalnyh-dannyh-setevoy-organizaciey-i-peredachu-tretey-storone"
            target="_blank">согласие на обработку персональных данных</a>. Отсканируйте или сфотографируйте документы и
          прикрепите к заявке на последнем этапе оформления.</p>
      </div>

      <div>
        <additional-owner-group
          :errors="errors"
          :list="additionalOwner.ownerGroups"
          role="ownerGroups"
          @add-owner="handlerAddingOwner"
        />
      </div>
    </template>
  </div>
</template>

<script>
import throttle from 'lib/utils/throttle';
import StepCounter from '../components/StepCounter';

const OWNER_FIELDS = {
  familyName: '',
  firstName: '',
  patronymic: '',
  documentType: { value: 'PASSPORT_RF', label: 'Паспорт гражданина РФ' },
  otherDocumentType: '',
  passportNumber: '',
  documentSeries: '',
  subdivisionCode: '',
  issueDate: '',
  issuer: '',
  insuranceNumber: '',
  phone: '',
  additionalPhone: '',
  email: '',
  address: '',
  postcodeAddress: '',
  registrationAddress: '',
  postcodeRegistrationAddress: '',
};

export default {
  name: 'AdditionalOwner',
  components: {
    AdditionalOwnerGroup: () => import('views/private/ConnectionApplication/steps/components/AdditionalOwnerGroup.vue'),
    CCheckbox: () => import('atoms/common/CCheckbox'),
    StepCounter,
  },
  props: {
    additionalOwner: {
      type: Object,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    isLegal: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isAdditionalOwner: undefined,
    };
  },
  watch: {
    additionalOwner: {
      immediate: true,
      handler(val) {
        this.isAdditionalOwner = val.ownerGroups.length > 0;
      },
    },
  },
  methods: {
    saveIntermediate: throttle(function(val) {
      this.step.saveIntermediate(val);
    }, 500),
    handlerAddingOwner() {
      this.additionalOwner.ownerGroups.push({ ...OWNER_FIELDS });
    },
    changeIsAdditionalOwner() {
      if (this.isAdditionalOwner === undefined) {
        return;
      }

      if (this.isAdditionalOwner) {
        if (this.additionalOwner.ownerGroups.length === 0) {
          this.additionalOwner.ownerGroups.push({ ...OWNER_FIELDS });
        }
      } else {
        this.additionalOwner.ownerGroups = [];
      }
    },
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.titleContainer
  +base-spacing

.title
  display: flex

.noteContainer
  +base-spacing

  p
    +small-spacing
    +small-text

  a
    +base-link

.grid2Column
  +grid-2-column

.gridFluid
  +grid-fluid
</style>
