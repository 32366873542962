<template>
  <div>
    <single-measurements
      :vue-router-navigationbar-home="{
        label: 'Показания',
        pathFrom: 'reading-sheets/indications-single-upload',
        pathTo: '/cabinet/indications',
      }"
      xml-type="input_transit"
    />
  </div>
</template>

<script>
import { SingleMeasurements } from 'components';

export default {
  components: {
    SingleMeasurements,
  },
};
</script>
