<template>
  <div class="container">
    <div class="form">
      <div>
        <v-table-new
          v-model="transformedMeasurements"
          :loading="loading"
          :on-filter-change="onFilterChange"
          :on-load-all-values="loadAllFiltered"
          :on-pagination-change="onPaginationChange"
          :page-out="pointsPage.pageOut"
          :table-name="'CabinetMeasurements_' + additionKind"
          :template="template"
          :title="activePeriod"
          excel-report-file-name="Показания"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import dayjs from 'dayjs';
import {
  formatAccountingMethod,
  formatAdditionKind,
  formatMeasurementsReadingKind,
  formatPointKind,
  pointKindValueLabelList,
} from 'lib/utils/formatXmlType';
import { mapActions } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'CommonMeasurements',
  components: { VTableNew },
  props: {
    additionKind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentPeriod: dayjs().format('MMMM YYYY'),
      activePeriod: dayjs().format('MMMM YYYY'),
      pointsPage: {},
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    template() {
      const headers = {
        headers: [
          {
            label: 'Период',
            type: 'externalFilter',
            key: 'period',
            filter: {
              type: 'date',
              format: 'MMMM YYYY',
              selectType: 'month',
              emptyValue: `${String(this.currentPeriod)}`,
            },
          },
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: '№ прибора учёта',
            key: 'serialNumber',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Тип прибора учёта',
            key: 'pointKind',
            sort: {},
            filter: { type: 'select', values: pointKindValueLabelList() },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Тип точки',
            key: 'additionKind',
            thStyle: {
              width: '190px',
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Коэффициент трансформации тока',
            key: 'ratedCurrent',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Коэффициент трансформации напряжения',
            key: 'nominalVoltage',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Начальные показания',
            key: 'previousValue',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Конечные показания',
            key: 'currentValue',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Разность показаний, кВт*ч',
            key: 'measurementsDifference',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Расчётный коэффициент',
            key: 'accountingRatio',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Потери переменные, %',
            key: 'variableLoss',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Потери постоянные, кВт*ч',
            key: 'constLoss',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Дополнительные объёмы, кВт*ч',
            key: 'additionalVolume',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Итоговый объём, кВт*ч',
            key: 'totalConsumption',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
        ],
      };

      if (this.additionKind === 'DELIVERY_POINT') {
        const tariffs = [
          {
            label: 'T1 (текущий)',
            key: 'currentT1',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'T2 (текущий)',
            key: 'currentT2',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'T3 (текущий)',
            key: 'currentT3',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'T1 (предыдущий)',
            key: 'previousT1',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'T2 (предыдущий)',
            key: 'previousT2',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'T3 (предыдущий)',
            key: 'previousT3',
            thStyle: {
              minWidth: '100px',
            },
          },
        ];

        headers.headers.push(...tariffs);
      }
      return headers;
    },
    transformedMeasurements() {
      if (this.points.length === 0) {
        return [];
      }
      this.points.forEach((item, index, object) => {
        if (item.meter === null) {
          object.splice(index, 1);
        }
      });

      return this.points.map(this.transformed);
    },
    accountingPeriodsFromSelectYear() {
      const selectYear = this.activePeriod?.substring(this.activePeriod.length - 4, this.activePeriod.length) ?? this.currentPeriod.substring(this.currentPeriod.length - 4, this.currentPeriod.length);

      return [
        `январь ${selectYear}`,
        `февраль ${selectYear}`,
        `март ${selectYear}`,
        `апрель ${selectYear}`,
        `май ${selectYear}`,
        `июнь ${selectYear}`,
        `июль ${selectYear}`,
        `август ${selectYear}`,
        `сентябрь ${selectYear}`,
        `октябрь ${selectYear}`,
        `ноябрь ${selectYear}`,
        `декабрь ${selectYear}`,
      ];
    },
    getAllAccountingPeriods() {
      return this.accountingPeriodsFromSelectYear.map((period, i) => ({
        label: period,
        value: `-${i}-${period.split(' ')[1]}`,
      }));
    },
    points() {
      return this.pointsPage.list ?? [];
    },
    transformFilter() {
      const result = { additionKind: this.additionKind };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        let convertFilterKey = '';

        if (filterKey === 'serialNumber') {
          convertFilterKey = 'meterSerial';
        } else {
          convertFilterKey = filterKey;
        }

        if (filterValue.length !== 0) {
          result[convertFilterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const period = dayjs(this.activePeriod, 'MMMM YYYY');

        this.pointsPage = await this.loadPointsWithMeasurementsByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
          accountingPeriod: {
            year: period.year(),
            month: period.month() + 1,
          },
        });
      } catch (error) {
        console.log('loadPoints', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const points = await this.loadPointsWithMeasurementsByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.pointsPage.pageOut.totalElements,
          },
        });

        if (!points?.list) {
          return [];
        }

        return points.list.map(this.transformed);
      } catch (error) {
        console.log('loadPoints', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    valueFromPeriod() {
      const accountingPeriod = this.getAllAccountingPeriods.find((accountingPeriod) => accountingPeriod.label === this.activePeriod);

      if (accountingPeriod) {
        return accountingPeriod.value;
      }

      return '';
    },
    measurementFieldByPeriod(measurements, period, field) {
      if (!measurements) {
        return null;
      }

      const measurement = measurements.find((measurement) => {
        if (measurement) {
          return measurement.accountingPeriod === period;
        }
      });

      if (measurement) {
        return measurement[field];
      }

      return null;
    },
    accountingMethodByPeriod(measurements, period, field) {
      const fieldByPeriod = this.measurementFieldByPeriod(measurements, period, field);

      if (fieldByPeriod) {
        return formatAccountingMethod(fieldByPeriod);
      }

      return null;
    },
    measurementsReadingKindByPeriod(kind) {
      if (!kind) {
        return null;
      }

      return formatMeasurementsReadingKind(kind);
    },
    formatPointKind(data) {
      return formatPointKind(data);
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      this.$delete(this.filter, 'period');
      this.activePeriod = filter.period ?? this.currentPeriod;
      await this.loadData();
    },
    transformed(it) {
      const measurement = it.measurements?.[0];
      return {
        id: it.id,
        serialNumber: it.meter?.serialNumber,
        pointKind: this.formatPointKind(it.pointKind),
        additionKind: formatAdditionKind(it.additionKind),
        ratedCurrent: it.meter?.ratedCurrent,
        nominalVoltage: it.meter?.nominalVoltage,
        accuracyClass: it.meter?.accuracyClass,
        accountingRatio: measurement?.accountingRatio,
        constLoss: measurement?.constLoss,
        variableLoss: measurement?.variableLoss,
        currentValue: measurement?.currentValue,
        additionalVolume: measurement?.additionalVolume,
        previousValue: measurement?.previousValue,
        totalConsumption: measurement?.totalConsumption,
        measurementsDifference: measurement?.measurementsDifference,
        currentT1: measurement?.currentTariffsMeasurements?.readingT1,
        currentT2: measurement?.currentTariffsMeasurements?.readingT2,
        currentT3: measurement?.currentTariffsMeasurements?.readingT3,
        previousT1: measurement?.previousTariffsMeasurements?.readingT1,
        previousT2: measurement?.previousTariffsMeasurements?.readingT2,
        previousT3: measurement?.previousTariffsMeasurements?.readingT3,
      };
    },
    ...mapActions('point', ['loadPoints', 'loadPointsWithMeasurementsByFilterPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  .button {
    margin: 5px;
  }

  .form {
    padding: 0 16px 0 0;
  }
}
</style>
