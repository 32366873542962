<template>
  <div :class="$style.container">
    <div>
      <div v-if="applicationData.objectLocation" :class="$style.statusGroup">
        <p :class="$style.title">
          Тариф технологического присоединения
        </p>
        <div :class="$style.radioGroup" style="margin-left: -222px ">
          <label :class="$style.labelRadio">
            <input
              :checked="tariff === 1"
              :class="$style.control"
              name="tariff"
              role="tariff.preferential"
              type="radio"
              :disabled="isTechnicalConditions"
              @click="tariffGroupClick(1)"
            />
            <div :class="$style.contentRadioGroup">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupCheck"></div>
                <h3 :class="$style.titleRadioGroup">Льготный</h3>
              </div>
            </div>
          </label>
          <label :class="$style.labelRadio">
            <input
              :checked="tariff === 2"
              :class="$style.control"
              name="tariff"
              role="tariff.notPreferential"
              type="radio"
              :disabled="isTechnicalConditions"
              @click="tariffGroupClick(2)"
            />
            <div :class="$style.contentRadioGroup" style="margin-top: -16px">
              <div :class="$style.titleContainer">
                <div :class="$style.buttonRadioGroupCheck"></div>
                <h3 :class="$style.titleRadioGroup">Без льгот</h3>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div v-if="reviewDocument && reviewDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.commentsTitle">
          Потребитель предложил изменения в документах.
        </div>
        <p v-html="comments"></p>
        <p :class="$style.title">Учтите предложения потребителя и загрузите документы для согласования:</p>
      </div>

      <div v-if="tariff === 1" :class="$style.correctionGroup">
        <div :class="$style.gridFluid">
          <span :class="$style.label">Будет выставлен счёт на сумму: {{ preferentialConnectionPriceFormatted }}</span>
        </div>
      </div>

      <div v-if="tariff === 2" :class="$style.correctionGroup">
        <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

        <div :class="$style.blockContent">
          <p>По какой причине не подпадает в льготную категорию *</p>
          <c-textarea
            v-model="tariffComments"
            :disabled="isTechnicalConditions"
            role="tariffComments"
          />
        </div>

        <div :class="$style.gridFluid">
          <label :class="$style.label" for="invoiceInputId">Будет выставлен счёт на сумму *</label>
          <input
            id="invoiceInputId"
            v-model="invoice.value"
            :class="{
              [$style.currencyField]: true,
              [$style.cInputDisabled]:isTechnicalConditions
            }"
            :disabled="isTechnicalConditions"
            role="invoice"
            @blur="currencyBlur"
            @focus="currencyFocus"
            @input="currencyInput"
          />
        </div>

      </div>

      <div v-if="applicationData.objectLocation" :class="$style.correctionGroup">
        <p :class="$style.correctionTitle">
          Заполните документы
        </p>
        <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

        <div :class="$style.blockContent" style="display: flex; flex-direction: column; gap: 12px">
          <p :class="$style.subtitle">
            <span>1.</span>
            Договор об осуществлении технологического присоединения к электросетям
          </p>
          <v-input v-model="contractNumber" title="Номер договора *" :disabled="isTechnicalConditions"/>
          <v-date-picker v-model="contractDate" style="margin-top: 24px" title="Дата заключения договора *" :disabled="isTechnicalConditions"/>
        </div>

        <div :class="$style.blockContent" style="display: flex; flex-direction: column; gap: 12px">
          <p :class="$style.subtitle">
            <span>2.</span>
            Технические условия*
          </p>
          <v-input :value="`Энергопринимающие устройства ${applicationData.objectLocation.objectType.value}`"
                   disabled
                   title="Наименование энергопринимающих устройств *"/>
          <v-input v-model="applicationData.objectLocation.objectType.value"
                   disabled
                   title="Наименование объекта *"/>
          <v-input v-model="applicationData.objectLocation.objectAddress"
                    disabled
                   title="Адрес объекта *"/>
          <v-input v-model="applicationData.connectionCharacteristics.currentMaxPower"
                   disabled
                   title="Максимальная мощность *"/>
          <v-input v-model="applicationData.connectionCharacteristics.reliabilityClass.value"
                   disabled
                   title="Категория надежности *"/>
          <v-input v-model="applicationData.connectionCharacteristics.phaseVoltage"
                    disabled
                   title="Класс напряжения *"/>
          <v-date-picker v-model="technicalConditions.yearOfCommissioning" format="YYYY" style="margin-top: 24px"
                         title="Год ввода в эксплуатацию *" type="year" is-disabled-future-dates :disabled="isTechnicalConditions"/>
          <p style="margin-top: 12px">Точки присоединения</p>
          <p>1 точка</p>
          <v-input v-model="technicalConditions.pointName" :disabled="isTechnicalConditions" title="Наименование точки присоединения *"/>
          <v-input v-model="applicationData.connectionCharacteristics.currentMaxPower"
                   disabled
                   title="Максимальная мощность *"/>

          <p style="margin-top: 12px">Основной источник питания</p>
          <usage-point-connection-select v-model="technicalConditions.mainSubstationObject" :disabled="isTechnicalConditions"/>

          <c-textarea v-model="technicalConditions.utilityResponsibilities"
                      :disabled="isTechnicalConditions"
                      :label="`Мероприятия, выполняемые ${getAccount.profile.name}`"
                      style="margin-top: 24px"/>
          <c-textarea v-model="technicalConditions.consumerResponsibilities"
                      :disabled="isTechnicalConditions"
                      label="Мероприятия, выполняемые Заявителем"/>
          <c-textarea v-model="technicalConditions.generalRequirements" :disabled="isTechnicalConditions" label="Общие требования"/>
          <div>
          </div>
        </div>
      </div>

      <div v-if="reviewDocument && reviewDocument.comments" :class="$style.accompanyingText">
        <p>Сопроводительный текст<span v-if="!isNew">*</span></p>
        <c-textarea
          v-model="accompanyingText"
          :disabled="isTechnicalConditions"
          role="accompanyingText"
        />
      </div>
    </div>
    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="notSignedDoc"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
    <div :class="$style.actionContent">
      <p v-if="isValid && applicationData.objectLocation" :class="$style.submitNote">
        Нажимая кнопку «Подписать и отправить», вы направляете документы потребителю и подписываете их простой
        электронной подписью.
      </p>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <submit-task-details v-if="applicationData.objectLocation" :disabled="isWaitingSubmit || !isValid" title="Подписать и отправить" @submit="onSubmit"/>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import { SimpleSignatureModal } from 'components';
import VInput from 'components/VInput/VInput.vue';
import VDatePicker from 'components/VDatePicker/VDatePicker.vue';
import UsagePointConnectionSelect from 'components/selectors/UsagePointConnectionSelect/UsagePointConnectionSelect.vue';

export default {
  name: 'SelectTariff',
  components: {
    UsagePointConnectionSelect,
    VDatePicker,
    VInput,
    SimpleSignatureModal,
    SubmitTaskDetails,
    HorizontalProgressBar,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    documentId: String,
    accountId: String,
    actionProps: Object,
    reviewDocument: Object,
    offerDocument: Object,
    applicationData: Object,
    isTechnicalConditions: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.preferentialConnectionPrice = await this.getPreferentialConnectionPrice({ connectionApplicationDocumentId: this.documentId });
  },
  data() {
    return {
      submitError: '',
      isWaitingSubmit: false,
      tariff: undefined,
      tariffComments: '',
      accompanyingText: '',
      invoice: {
        value: '',
        formatted: '',
      },
      agreementTechnologicalConnectionDocs: [],
      technicalConditionsDocs: [],
      isNew: true,
      preferentialConnectionPrice: '',
      notSignedDoc: '',
      isVisibleSimpleSignatureModal: false,
      contractNumber: '',
      contractDate: '',
      technicalConditions: {
        yearOfCommissioning: '',
        pointName: '',
        mainSubstationObject: {
          mainSubstation: {},
        },
        reserveSubstationObject: undefined,
        utilityResponsibilities: '',
        consumerResponsibilities: '',
        generalRequirements: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'getAuthorization']),
    isValid() {
      return ((this.isNew || this.accompanyingText?.length) &&
        (
          this.tariff === 1 ||
          (this.tariff === 2 && this.tariffComments && this.tariffComments !== '' && this.invoice.value && this.invoice.value !== '')
        ) &&
        (this.contractNumber?.length && this.contractDate?.length && this.technicalConditions.yearOfCommissioning?.length && this.technicalConditions.pointName?.length && this.technicalConditions.mainSubstationObject.mainSubstation?.fullName?.length)) || (this.isTechnicalConditions && this.technicalConditions.mainSubstationObject.mainSubstation?.fullName?.length);
    },
    reviewType() {
      return this.actionProps.reviewType;
    },
    reviewComments() {
      if (this.tariff === 2) {
        return this.tariffComments;
      }
      return '';
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '«', '»');
    },
    preferentialConnectionPriceFormatted() {
      if (!this.preferentialConnectionPrice) {
        return '';
      }
      return this.numberFormat(this.preferentialConnectionPrice);
    },
    ...mapGetters('user', ['getUserType']),
  },
  watch: {
    applicationData(val) {
      console.log(val);
    },
    offerDocument: {
      immediate: true,
      handler(val) {
        if (val && val.technicalConditions) {
          this.technicalConditions = val.technicalConditions;
          console.log('techCond', this.technicalConditions);

          this.contractNumber = val.contractNumber;
          this.contractDate = val.contractDate;

          this.isNew = false;
          console.log(val.isPreferential);
          if (val.isPreferential) {
            this.tariff = 1;
          } else {
            this.tariff = 2;
          }
          this.invoice.value = this.numberFormat(String(val.unformattedPrice));
          this.tariffComments = val.comments;
          if (val.accompanyingText) {
            this.accompanyingText = val.accompanyingText;
          }
        }
      },
    },
  },
  methods: {
    async accountPrice() {
      if (this.tariff === 1) {
        return this.preferentialConnectionPrice;
      }
      return this.reverseFormatNumber(this.invoice.value);
    },
    openSignatureModal() {
      console.log('appData', this.applicationData);
      console.log('xml', this.notSignedDoc);
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              this.isTechnicalConditions ? 'АКТ ВЫПОЛНЕНИЯ ТЕХНИЧЕСКИХ УСЛОВИЙ ОТПРАВЛЕН' : 'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ПРИНЯТЬ УСЛОВИЯ И ПРОИЗВЕСТИ ОПЛАТУ',
              '',
            );
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {

      const account = this.getAccount;
      const authorization = this.getAuthorization.authorization;
      const docFunc = this.isTechnicalConditions ? connectionApplicationData.ConnectionApplicationTechnicalConditionsAct : connectionApplicationData.ConnectionApplicationContract;
      this.notSignedDoc = docFunc(
        {
          connectionApplicationDocumentId: this.documentId,
          number: this.contractNumber,
          date: this.contractDate,
          price: await this.accountPrice(),
          isPreferential: this.tariff === 1,
          reason: this.tariffComments,
          accompanyingText: this.accompanyingText,
          technicalConditions: {
            electricityDevicesName: `Энергопринимающие устройства ${this.applicationData.objectLocation.objectType.value}`,
            objectName: this.applicationData.objectLocation.objectType.value,
            objectAddress: this.applicationData.objectLocation.objectAddress,
            point: {
              name: this.technicalConditions.pointName,
              maxPower: this.applicationData.connectionCharacteristics.currentMaxPower,
            },
            maxPower: this.applicationData.connectionCharacteristics.currentMaxPower,
            previousMaxPower: this.applicationData.connectionCharacteristics.previousMaxPower,
            voltageLevel: this.applicationData.connectionCharacteristics.phaseVoltage,
            yearOfCommissioning: this.technicalConditions.yearOfCommissioning,
            cable: this.technicalConditions.cable,
            mainSubstationObject: this.technicalConditions.mainSubstationObject,
            reserveSubstationObject: this.technicalConditions.reserveSubstationObject,
            utilityResponsibilities: this.technicalConditions.utilityResponsibilities,
            consumerResponsibilities: this.technicalConditions.consumerResponsibilities,
            generalRequirements: this.technicalConditions.generalRequirements,
            reliabilityClass: this.applicationData.connectionCharacteristics.reliabilityClass.value,
          },
        },
        {
          inn: account.profile.inn,
          name: account.profile.name,
          accountId: account.id,
          familyName: authorization.user.fullName.surname,
          firstName: authorization.user.fullName.name,
          patronymic: authorization.user.fullName.patronymic ?? '',
          position: authorization.role.name,
        },
        {
          snils: this.applicationData.consumer.insuranceNumber,
          companyInn: this.applicationData.consumer.company.inn,
          companyName: this.applicationData.consumer.company.name,
          name: `${this.applicationData.consumer.familyName} ${this.applicationData.consumer.firstName} ${this.applicationData.consumer.patronymic ?? ''}`,
          familyName: this.applicationData.consumer.familyName,
          firstName: this.applicationData.consumer.firstName,
          patronymic: this.applicationData.consumer.patronymic ?? '',
          documentSeries: this.applicationData.consumer.documentSeries,
          passportNumber: this.applicationData.consumer.passportNumber,
          issuer: this.applicationData.consumer.issuer,
          issueDate: this.applicationData.consumer.issueDate,
          address: this.applicationData.consumer.address,
          accountId: this.accountId,
        },
        this.applicationData.type,
      );
      this.openSignatureModal();
    },
    onClose() {
      this.statusGroup = undefined;
      this.tariff = undefined;
      this.$emit('close');
    },
    statusGroupClick(val) {
      this.tariff = undefined;
      this.statusGroup = val;
    },
    tariffGroupClick(val) {
      if (!this.isNew && this.tariff !== val) {
        this.replace();
      }

      this.tariff = val;
    },
    replace() {
      this.isNew = true;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    // TODO move currency input in component
    reverseFormatNumber(val) {
      return val.replace(new RegExp('[\\s₽]', 'g'), '').replace(new RegExp('[,]', 'g'), '.');
    },
    currencyFocus() {
      if (!this.invoice.value) {
        return;
      }
      this.invoice.value = this.reverseFormatNumber(this.invoice.value);
    },
    numberFormat(value) {
      if (!value) {
        return '';
      }

      return Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
      }).format(value.replace(new RegExp('[,]', 'g'), '.'));
    },
    currencyBlur() {
      if (!this.invoice.value) {
        return;
      }
      this.invoice.value = this.numberFormat(this.invoice.value);
    },
    currencyInput() {
      this.invoice.value = this.invoice.value.replace(new RegExp('[^\\d\\.]', 'gi'), '');
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows', 'getPreferentialConnectionPrice']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.content
  margin-top: 130px
  padding: 0 72px 34px 72px

.statuses
  width: 220px
  position: relative
  left: 50%
  margin-left: -110px

.title
  padding-bottom: 120px
  +base-title

.arrow
  padding-left: 16px
  padding-bottom: 4px

.closeButton
  width: 100%
  height: 56px
  +base-button-text
  color: #ffffff
  background: #3894FB

  &:disabled
    background: var(--color-background-gray-150)

.radioGroup
  margin-left: -66px
  margin-top: 28px

.radioGroupTariff
  display: flex
  flex-direction: column
  align-items: start
  margin-left: 84px
  margin-top: 18px

.labelRadio
  cursor: pointer
  height: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.disabled
  color: gray

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

.cInputDisabled
  color: gray
  border: 0
  pointer-events: none


.control:disabled
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: gray

    p
      color: gray

.statusGroup, .submitNote
  width: 100%
  max-width: 560px
  margin: 0 auto
  display: flex

.correctionGroup, .accompanyingText
  width: 100%
  max-width: 560px
  margin: 0 auto

.accompanyingText
  margin-top: 40px
  margin-bottom: 40px

.correctionTitle
  padding-bottom: 12px
  +base-title

.subTitle
  +base-subtitle
  +medium-spacing

.mediumSpacing
  +medium-spacing

.blockContent
  &:last-child
    margin-bottom: 40px
    gap: 12px

  &:not(:last-child)
    +base-spacing

.gridFluid
  +grid-fluid

.subtitle
  +base-subtitle
  +medium-spacing

  span
    padding-right: 8px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 8px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.reviewDocumentFiles p a, .reviewDocumentFiles p span
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocumentFiles p span.replace
  color: #4C4E51
  white-space: nowrap
  cursor: pointer

.reviewDocumentFiles a span
  text-decoration: underline

.reviewDocumentFiles p
  &:not(:last-child)
    margin-bottom: 24px

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #000

.currencyField
  font-weight: 400
  font-size: 21px
  line-height: 30px
  width: 100%
  font-family: 'Roboto Condensed'
  padding: 4px 16px
  color: #4F4F4F
  background: #FFF
  border: 1px solid #141412

  &::placeholder
    color: #E8E8E8

  &:focus
    outline: none

  &.date
    line-height: 28px

.label
  +label-text
  margin-bottom: 8px
  color: #141412
  width: 100%

.actionContent
  width: 560px
  margin: 0 auto
</style>
