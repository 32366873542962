export const newUser = {
  groups: [
    {
      title: 'ЗАДАЧИ',
      route: 'tasks',
      iconComponent: 'Tasks',
    },
    {
      title: 'ОБЪЕКТЫ',
      route: 'objects',
      iconComponent: 'HomeWork',
    },
    {
      title: 'ПРОФИЛЬ',
      route: 'user-details',
      iconComponent: 'UserDetails',
    },
    {
      title: 'ДОСТУПНЫЕ ПРОФИЛИ',
      route: 'available-profiles',
      iconComponent: 'UserDetails',
    },
  ],
};
