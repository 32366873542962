<template>
  <div>
    <odk-tag-modal v-model="tagsModalState" @updated="fetchAxpInExploitation"/>
    <v-app class="vuetify-styles" data-app>
      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
          <span class="text-h5 align-center"
          >{{ title }}</span
          >
            </v-card-title>
            <v-card-text>
              <v-divider/>
              <v-card-title>
          <span class="text-h6 align-center"
          >Выберите ОДК для назначения или переназначения</span
          >
              </v-card-title>
              <events-autocomplete
                ref="event"
                v-model="eventId"
                :items.sync="events"
                :search.sync="eventName"
                title="Мероприятие"
              />
              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"/>
              <div>
                <yandex-map
                  ref="customMap"
                  :collection-axp="axpItemsFiltered"
                  @tapPlacemark="onTapPlacemark"
                />
              </div>
              <v-data-table
                :expanded.sync="expanded"
                :headers="headersTable"
                :items="adreskaItemsFiltered"
                :loading="isLoadingAxp"
                :search="search"
                class="elevation-1 mb-4 no-expanded-shadow"
                item-key="id"
                show-expand
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.isSelected="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.isSelected"
                      class="no-margin-top mb-1"
                      color="success"
                      hide-details
                      @click="onTapCheckbox(item)"
                    ></v-checkbox>
                  </div>
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex justify-center flex-fill justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.id)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>
                <template #expanded-item="{headers, item}">
                  <td/>
                  <td :colspan="headers.length" class="pa-0 pb-6">
                    <v-data-table
                      :headers="subHeadersTable"
                      :items="item.inspections"
                      :loading="isLoadingAxp"
                      :search="search"
                      disable-pagination
                      hide-default-footer
                      item-key="id"
                      style="width: 100%"
                    >
                      <template #item.delete="{ item }">
                        <v-icon class="error-label" small @click="deleteAssignment(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                      <template #item.isSelected="{ item }">
                        <div class="d-flex justify-center">
                          <v-checkbox
                            v-model="item.isSelected"
                            class="no-margin-top mb-1"
                            color="success"
                            hide-details
                            @click="onTapCheckboxInspection(item)"
                          ></v-checkbox>
                        </div>
                      </template>

                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
              <v-divider/>
              <v-card-title>
                <span class="text-h6 align-center">Заполните информацию для нового назначения</span>
              </v-card-title>
              <v-card-text class="mb-2">Если необходимо удалить существующие назначения, оставьте данные поля пустыми
              </v-card-text>
              <file-input
                ref="photos"
                v-model="photos"
                class="mt-2"
                title="Фото"
              ></file-input>

              <simple-autocomplete
                ref="criticality"
                v-model="criticalityId"
                :items.sync="criticalityItems"
                :search.sync="criticalityName"
                :title="`Критичность ${actionText}`"
                is-required
                url="/criticality/find?name="
                url-default-data="/criticality"
              ></simple-autocomplete>

              <v-row>
                <v-dialog
                  ref="modalDateRange"
                  v-model="modalDateRange"
                  class="v-dialog-top"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-col>
                      <div>
                        <v-label :class="{
                        'error-label': dateRangeError,
                        }">Дата начала и конца {{ actionText }}*
                        </v-label
                        >
                      </div>
                      <v-text-field
                        ref="textFieldDate"
                        v-model="dateRangeText"
                        :error-messages="dateRangeError?'Поле обязательно для заполнения':undefined"
                        dense
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        @focus="validateDateRange"
                        v-on="on"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-card class="v-application">
                    <v-date-picker v-model="rangeDates" :min="currentDate"
                                   range></v-date-picker>
                    <v-card-actions>
                      <v-btn color="primary" text @click="modalDateRange = false">
                        Отмена
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="save(rangeDates)"
                      >
                        Принять
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>

              <v-row
                  v-if="type !== regularInspectionTaskType.ADRESKA_CONNECTION">
                <v-dialog
                  ref="modalDates"
                  v-model="modalDates"
                  :disabled="isDisableDateRangeText"
                  class="v-dialog-top"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-col>
                      <div>
                        <v-label :class="{
                        'error-label': datesError,
                        }">Даты {{ actionText }}*
                        </v-label>
                      </div>
                      <v-text-field
                        ref="textFieldDate"
                        v-model="datesText"
                        :error-messages="datesError?'Поле обязательно для заполнения':undefined"
                        dense
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        @focus="validateDates"
                        v-on="on"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-card class="v-application">
                    <v-date-picker v-model="dates" :max="sortedDatesForRange[1] ?? sortedDatesForRange[0]"
                                   :min="sortedDatesForRange[0]"
                                   multiple></v-date-picker>
                    <v-card-actions>
                      <v-btn color="primary" text @click="modalDates = false">
                        Отмена
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="saveDates(dates)"
                      >
                        Принять
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>

              <template v-if="type === regularInspectionTaskType.ADRESKA_CONNECTION">
                <div>
                  <v-label>Кто подключает</v-label>
                </div>
                <v-autocomplete
                  v-model="whoConnects"
                  :items="Object.values(whoConnectsType)"
                  :append-icon="whoConnects ? 'mdi-close non-rotating-icon' : 'mdi-chevron-down'"
                  item-value="value"
                  item-text="label"
                  outlined
                  required
                  dense
                  style="border: none"
                  @click:append="onClearWhoConnects"
                />
              </template>

              <text-field
                  v-if="type === regularInspectionTaskType.ADRESKA_CONNECTION && whoConnects === whoConnectsType.CONTRACTOR.value"
                  v-model="contractorName"
                  is-disabled
                  title="Подрядчик"
              ></text-field>

              <simple-autocomplete-by-function
                v-if="type !== regularInspectionTaskType.ADRESKA_CONNECTION ||
                  (whoConnects === whoConnectsType.CONTRACTOR.value && contractorId) ||
                  (whoConnects === whoConnectsType.HOUSEHOLD_WAY.value)"
                ref="department"
                v-model="departmentId"
                :dependency="subdivisionsDependency"
                :fetch-data-function="subdivisionsByName"
                :fetch-default-data-function="subdivisionsByName"
                :items.sync="departments"
                :search.sync="departmentName"
                is-required
                title="Департамент"
              ></simple-autocomplete-by-function>

              <v-banner v-if="departmentId && isDisableNoUsers" class="red--text mb-8">В данном департаменте отсутствуют
                сотрудники. Выберите другой департамент
              </v-banner>
              <simple-autocomplete-by-function
                ref="employee"
                v-model="employeeId"
                :dependency="whoConnects"
                :fetch-data-function="rolesForPermissionByName"
                :fetch-default-data-function="rolesForPermissionByName"
                :is-disabled="!departmentId||isDisableNoUsers"
                :items.sync="employees"
                :search.sync="employeeFullName"
                is-required
                title="Сотрудник"
              ></simple-autocomplete-by-function>
              <div>
                <text-field
                  ref="comment"
                  v-model="comment"
                  title="Комментарий"/>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabledSaveDialog"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSend"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog v-model="loaderDialog"
                  class="v-dialog-top" persistent width="250">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + "%" }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
    <v-dialog v-model="dialogTableDelete"
              class="v-dialog-top" max-width="500px">
      <v-card class="v-application">
        <v-card-title class="text-h5 text-center"
        >Вы уверены, что хотите удалить?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            color="primary"
            outlined
            rounded
            @click="dialogTableDelete = false"
          >Отмена
          </v-btn
          >
          <v-btn
            class="ma-2"
            color="error"
            outlined
            rounded
            @click="deleteItem"
          >
            <v-icon class="label-error pr-1">mdi-delete</v-icon>
            Удалить
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import SimpleAutocomplete from '@/components/Processes/components/SimpleAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import FileInput from '@/components/Processes/components/FileInput.vue';
// import { D11_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { getSurnameAndInitials } from 'utils/user';
import { formatDateFromOdkTimestamp, getMomentCurrentDate } from 'lib/utils/date';
import { distinctBy } from 'utils/arrays/arrays';
import { filterForAccess } from 'lib/utils/permissions';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { findCriticalityByName } from '@/api/rest/odk/api';
import { inConstants } from 'lib/component/validators';
import { regularInspectionTaskType } from 'components/Processes/list';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

const DEFAULT_CRITICALITY = 'Низкая';
export default {
  components: {
    TextField,
    OdkTagModal,
    SimpleAutocompleteByFunction,
    SimpleAutocomplete,
    YandexMap,
    FileInput,
    EventsAutocomplete,
  },

  name: 'Form1',
  props: {
    type: {
      type: String,
      validator: inConstants(regularInspectionTaskType),
      default: regularInspectionTaskType.INSPECTION,
    },
  },
  async created() {
    await this.setDefaultCriticality();
    await this.fetchAxpInExploitation();
    this.subdivisions = await this.getSubordinatedSubdivisions();
    const currentSubdivision = this.getAuthorization.authorization.role.subdivision;
    if (this.hasAccessForDepartment && currentSubdivision && !this.subdivisions.some((it) => it.id === currentSubdivision.id)) {
      this.subdivisions.push(currentSubdivision);
    }
    if (this.$route.params.initAdreskaId) {
      if (!this.adreskaItems.get(this.$route.params.initAdreskaId)) {
        this.setNotification({ message: 'Невозможно совершить действие - неверный статус объекта' });
        this.onTapBack();
      }
      this.selectAdreska(this.$route.params.initAdreskaId);
    }
  },

  data: () => ({
    tagsFilter: null,
    dialogTableDelete: false,
    tagsModalState: null,
    photos: [],
    criticalityId: null,
    criticalityName: '',
    criticalityItems: [],
    modalDateRange: false,
    modalDates: false,
    isDateValid: true,
    isDatesValid: true,
    rangeDates: [],
    dates: [],
    sortedDatesForRange: [],
    sortedDates: [],

    expanded: [],
    search: null,
    axpItems: [],
    isLoadingAxp: false,
    updateMap: 0,

    subdivisions: [],
    departmentId: null,
    contractorId: null,
    departmentName: '',
    contractorName: '',
    departments: [],
    contractors: [],
    employeeId: null,
    employeeFullName: '',
    employees: [],

    eventId: null,
    events: [],
    eventName: '',

    progressValue: 0,
    loaderDialog: false,
    adreskaItems: new Map(),
    fetchedRoles: [],
    toDelete: {},
    dateRangeError: false,
    datesError: false,
    comment: '',

    regularInspectionTaskType: regularInspectionTaskType,

    whoConnectsType: {
      HOUSEHOLD_WAY: { value: 'HOUSEHOLD_WAY', label: 'Хоз. способ' },
      CONTRACTOR: { value: 'CONTRACTOR', label: 'Подрядчик' },
    },
    whoConnects: '',
  }),
  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        { text: 'На осмотр', value: 'isSelected', align: 'center', width: '100px' },
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        { text: 'Подрядчик', value: 'contractorName', align: 'center' },
        { text: 'Ответственный РЭС', value: 'responsibleDistrictDepartmentName', align: 'center' },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
        { text: 'Список осмотров', value: 'data-table-expand', width: 150, align: 'center' },
      ];
    },
    permissionsForFilter() {
      switch (this.type) {
        case regularInspectionTaskType.INSTALLATION_CONTROL:
          return ['SVET_D8-A', 'SVET_D8-B', 'SVET_D8-C'];
        case regularInspectionTaskType.DISMANTLING_CONTROL:
          return ['SVET_D14-A', 'SVET_D14-B', 'SVET_D14-C'];
        case regularInspectionTaskType.ADRESKA_CONNECTION:
          return ['SVET_D9-A', 'SVET_D9-B', 'SVET_D10-A'];
        default:
          return ['SVET_D11-C', 'SVET_D11-D', 'SVET_D11-F'];
      }
    },
    title() {
      if (this.hasAccessForAllObjects) {
        switch (this.type) {
          case regularInspectionTaskType.INSTALLATION_CONTROL:
            return 'Назначение объектов на контроль монтажа';
          case regularInspectionTaskType.DISMANTLING_CONTROL:
            return 'Назначение объектов на контроль демонтажа';
          case regularInspectionTaskType.ADRESKA_CONNECTION:
            return 'Назначение объектов на подключение/смену точки подключения';
          default:
            return 'Назначение объектов на регулярный осмотр';
        }
      } else {
        switch (this.type) {
          case regularInspectionTaskType.INSTALLATION_CONTROL:
            return 'Распределение объектов на контроль монтажа';
          case regularInspectionTaskType.DISMANTLING_CONTROL:
            return 'Распределение объектов на контроль демонтажа';
          case regularInspectionTaskType.ADRESKA_CONNECTION:
            return 'Распределение объектов на подключение/смену точки подключения';
          default:
            return 'Распределение объектов на регулярный осмотр';
        }
      }
    },
    actionText() {
      switch (this.type) {
        case regularInspectionTaskType.INSTALLATION_CONTROL:
          return 'контроля';
        case regularInspectionTaskType.DISMANTLING_CONTROL:
          return 'контроля';
        case regularInspectionTaskType.ADRESKA_CONNECTION:
          return 'подключения';
        default:
          return 'осмотра';
      }
    },
    currentDate() {
      return getMomentCurrentDate().format('YYYY-MM-DD');
    },
    axpItemsFiltered() {
      let filtered = [...this.axpItems.values()];
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      if (this.eventId) filtered = filtered.filter((it) => String(it.eventId) === String(this.eventId));
      return filtered;
    },
    adreskaItemsFiltered() {
      let filtered = [...this.adreskaItems.values()];
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      if (this.eventId) filtered = filtered.filter((it) => String(it.eventId) === String(this.eventId));
      return filtered;
    },
    hasAccessForAllObjects() {
      switch (this.type) {
        case regularInspectionTaskType.INSTALLATION_CONTROL:
          return filterForAccess('process/8/1', this.getAuthorization?.blockAccess);
        case regularInspectionTaskType.DISMANTLING_CONTROL:
          return filterForAccess('process/14/1', this.getAuthorization?.blockAccess);
        case regularInspectionTaskType.ADRESKA_CONNECTION:
          return filterForAccess('process/9/1', this.getAuthorization?.blockAccess);
        default:
          return filterForAccess('process/11/3', this.getAuthorization?.blockAccess);
      }
    },
    hasAccessForDepartment() {
      switch (this.type) {
        case regularInspectionTaskType.INSTALLATION_CONTROL:
          return filterForAccess('process/8/2', this.getAuthorization?.blockAccess);
        case regularInspectionTaskType.DISMANTLING_CONTROL:
          return filterForAccess('process/14/2', this.getAuthorization?.blockAccess);
        case regularInspectionTaskType.ADRESKA_CONNECTION:
          return filterForAccess('process/9/2', this.getAuthorization?.blockAccess);
        default:
          return filterForAccess('process/11/4', this.getAuthorization?.blockAccess);
      }
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    isDisableNoUsers() {
      return !this.fetchedRoles.length;
    },
    isDisabledSaveDialog() {
      const isDisabledContractor =
        ![...this.adreskaItems.values()].some(
          (it) => it.isSelected,
        );
      const isDisableCriticality = this.criticalityId == null;
      const isDisableEmployeeId = this.employeeId == null;
      const needToCheckForm = !(isDisableEmployeeId && this.isDisableDateRangeText && this.isDisableDatesText && isDisableEmployeeId && !this.photos?.length && this.departmentId == null) ||
        ![...this.adreskaItems.values()].some(
          (it) => it.inspections.some((internalIt) => internalIt.isSelected),
        );

      const isDisableWhoConnects = this.type === regularInspectionTaskType.ADRESKA_CONNECTION && this.whoConnects?.length === 0;

      return (
        isDisableWhoConnects || (
          needToCheckForm && (
            isDisabledContractor ||
            isDisableCriticality ||
            this.isDisableDateRangeText ||
            this.isDisableDatesText ||
            isDisableEmployeeId
          )
        )
      );
    },
    isDisableDateRangeText() {
      return this.dateRangeText == null;
    },
    isDisableDatesText() {
      return this.type !== regularInspectionTaskType.ADRESKA_CONNECTION && this.datesText == null;
    },

    dateRangeText() {
      if (this.sortedDatesForRange.length > 0) {
        return this.sortedDatesForRange.join(' ~ ');
      }
      return null;

    },
    datesText() {
      if (this.sortedDates.length > 0) {
        return this.sortedDates.join(', ');
      }
      return null;

    },
    subHeadersTable() {
      const headers = [
        { text: 'Заменить', value: 'isSelected', align: 'center', width: '100px' },
        {
          text: 'Подразделение',
          align: 'center',
          value: 'departmentName',
        },
        {
          text: 'Сотрудник',
          align: 'center',
          value: 'employeeName',
        },
        {
          text: 'Смена',
          align: 'start',
          value: 'shift',
        },
        {
          text: 'Период',
          align: 'start',
          value: 'period',
        },
        {
          text: 'Даты',
          align: 'start',
          value: 'dates',
        },
        {
          text: 'Критичность',
          align: 'start',
          value: 'criticality',
        },
        {
          text: 'Удалить',
          align: 'start',
          value: 'delete',
        },
      ];

      if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
        headers.splice(5, 1);
      }

      return headers;
    },
    subdivisionsDependency() {
      if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
        return this.contractorId;
      }

      return this.subdivisions;
    },
    ...mapGetters('user', ['getToken', 'getAccount', 'getAuthorization']),
    ...mapGetters('dictionary', ['shiftDictionary']),
  },

  watch: {
    sortedDatesForRange() {
      this.sortedDates = this.sortedDates.filter((it) => it >= this.sortedDatesForRange[0] && it <= (this.sortedDatesForRange[1] ?? this.sortedDatesForRange[0]));
      this.dates = this.dates.filter((it) => it >= this.sortedDatesForRange[0] && it <= (this.sortedDatesForRange[1] ?? this.sortedDatesForRange[0]));
    },
    async departmentId() {
      this.employeeId = null;
      if (!this.departmentId) return;
      const result = await this.getSubdivisionWithFilteredRolesById({
        id: this.departmentId,
        filter: { permissions: this.permissionsForFilter },
      });
      this.fetchedRoles = this.departmentId ? result.roles.flatMap((role) => role.authorizations.map((auth) => ({
        authorization: auth,
        roleName: role.name,
        isSubdivisionOwner: role.isSubdivisionOwner,
      }))).map((elem) => ({
        id: elem.authorization.user.id,
        authorization: elem.authorization,
        name: `${elem.roleName} ${getSurnameAndInitials(elem.authorization.user.fullName)}`,
        isSubdivisionOwner: elem.isSubdivisionOwner,
      })) : [];
      console.log('fr', this.fetchedRoles);
      this.employeeFullName = '';
      this.employeeId = this.fetchedRoles.find((it) => it.isSubdivisionOwner)?.id;
    },
    whoConnects() {
      this.departmentId = null;
      this.employeeId = null;
    },
  },

  methods: {
    validateDateRange() {
      this.dateRangeError = !this.sortedDatesForRange.length;
    },
    validateDates() {
      this.datesError = !this.sortedDates.length;
    },
    async setDefaultCriticality() {
      if (!this.criticalityId) {
        this.criticalityId = (await findCriticalityByName(DEFAULT_CRITICALITY))[0].id;
      }
    },
    openTags(id) {
      this.tagsModalState = id;
    },
    async subdivisionsByName(name) {
      if (this.hasAccessForAllObjects) {

        if (this.whoConnects === this.whoConnectsType.CONTRACTOR.value) {
          const subdivisions = await this.supplierSubdivisions({
            supplierId: this.contractorId,
            filter: {
              permissions: this.permissionsForFilter,
            },
          });

          return subdivisions.map((it) => ({
            ...it,
            name: `${it.name} (${this.shiftDictionary[it.shift]} смена)`,
          })).filter((it) => it.name.toLowerCase().includes(name?.toLowerCase() ?? ''));
        }

        const subdivisions = await this.getSubdivisions({
          filter: {
            permissions: this.permissionsForFilter,
          },
        });

        return subdivisions.map((it) => ({
          ...it,
          name: `${it.name} (${this.shiftDictionary[it.shift]} смена)`,
        })).filter((it) => it.roles.some((role) => role.authorizations?.length) && it.name.toLowerCase().includes(name?.toLowerCase() ?? ''));
      }
      if (!name?.length) return this.subdivisions;
      return this.subdivisions.filter((it) => it.name.toLowerCase().includes(name.toLowerCase())).map((it) => ({
        ...it,
        name: `${it.name} (${this.shiftDictionary[it.shift]} смена)`,
      })).filter((it) => it.roles.some((role) => role.authorizations?.length));
    },
    // TODO серверная фильтрация
    async rolesForPermissionByName(name) {
      if (!name?.length) return this.fetchedRoles;
      const nameLower = name.toLowerCase();
      return this.fetchedRoles.filter((role) => role.name.toLowerCase().includes(nameLower));
    },
    save(dates) {
      this.modalDateRange = false;
      this.sortedDatesForRange = dates.sort();
    },

    saveDates(dates) {
      this.modalDates = false;
      this.sortedDates = dates.sort();
    },

    onTapPlacemark(id) {
      const adreskaId = this.axpItems.get(id).adreskaId;
      this.selectAdreska(adreskaId);
    },

    selectAdreska(id) {
      const adreska = this.adreskaItems.get(id);
      if (adreska) {
        adreska.isSelected = !adreska.isSelected;
        this.onAdreskaSelected(adreska.id, adreska.isSelected);
      }
    },

    onAdreskaSelected(id, isSelected) {
      this.axpItems.forEach((it) => {
        if (it.adreskaId === id) {
          it.isSelected = isSelected;
          it.balloonContent.buttonLabel = isSelected ? 'Отменить' : 'Выбрать';
        }
      });
      if (!isSelected) {
        this.adreskaItems.get(id).inspections.forEach((it) => it.isSelected = false);
      }

      // TODO подумать как это сделать без этого
      this.axpItems = new Map(this.axpItems);

      let contractorId = null;
      let contractorName = '';
      [...this.axpItems].every((value) => {
        if (value[1].isSelected) {
          contractorId = value[1].contractorId;
          contractorName = value[1].contractorName;
          return false;
        }
        return true;
      });

      this.contractorId = contractorId;
      this.contractorName = contractorName;
    },

    onInspectionSelected(id, adreskaId, isSelected) {
      const adreska = this.adreskaItems.get(adreskaId);
      if (!adreska) return;
      if (isSelected) {
        adreska.isSelected = true;
        this.onAdreskaSelected(adreska.id, true);
      } else if (!adreska.inspections.some((it) => it.isSelected)) {
        adreska.isSelected = false;
        this.onAdreskaSelected(adreska.id, false);
      }
      // TODO подумать как это сделать без этого
      this.adreskaItems = new Map(this.adreskaItems);
    },

    onTapCheckbox(item) {
      // TODO возможно оно работает не слишком очевидно (глубокие ссылки и т.д.)
      this.onAdreskaSelected(item.id, item.isSelected);
    },

    onTapCheckboxInspection(item) {
      // TODO возможно оно работает не слишком очевидно (глубокие ссылки и т.д.)
      this.onInspectionSelected(item.id, item.adreskaId, item.isSelected);
    },

    async fetchAxpInExploitation() {
      this.isLoadingAxp = true;
      let response;
      if (this.hasAccessForAllObjects) {
        switch (this.type) {
          case regularInspectionTaskType.INSTALLATION_CONTROL:
            response = await fetch(`${BASE_URL}/adreska/adreskafact?statuses=null, MOUNTING_IN_PROCESS, MOUNTED`);
            break;
          case regularInspectionTaskType.DISMANTLING_CONTROL:
            response = await fetch(`${BASE_URL}/adreska/adreskafact?statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS`);
            break;
          case regularInspectionTaskType.ADRESKA_CONNECTION:
            response = await fetch(`${BASE_URL}/adreska/adreskafact?statuses=IN_EXPLOITATION, MOUNTING_IN_PROCESS, MOUNTED`);
            break;
          default:
            response = await fetch(`${BASE_URL}/axp/exploitation`);
            break;
        }
      } else {
        switch (this.type) {
          case regularInspectionTaskType.INSTALLATION_CONTROL:
            response = await fetch(`${BASE_URL}/axp/installation/regular-inspection-access?type=${this.type}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } });
            break;
          case regularInspectionTaskType.DISMANTLING_CONTROL:
            response = await fetch(`${BASE_URL}/axp/dismantling/regular-inspection-access?type=${this.type}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } });
            break;
          case regularInspectionTaskType.ADRESKA_CONNECTION:
            response = await fetch(`${BASE_URL}/axp/connection/regular-inspection-access?type=${this.type}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } });
            break;
          default:
            response = await fetch(`${BASE_URL}/axp/exploitation/regular-inspection-access?type=${this.type}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } });
            break;
        }
      }
      const data = await response.json();
      const distinctAdreska = distinctBy(data.map((it) => ({ ...it.adreska })), (it) => it.id);
      const adreskaIds = distinctAdreska.map((it) => it.id).join(',');

      const inspectionsResponse = await fetch(this.tasksUrlByType(adreskaIds), { headers: { 'X-Authorization': `Bearer ${this.getToken}` } });

      const inspectionData = await inspectionsResponse.json();

      this.adreskaItems = new Map(distinctAdreska.map((it) => [it.id, {
          id: it.id,
          inspections: this.formattedInspectionData(inspectionData, it),
          eventId: it.event.id,
          responsibleDistrictDepartmentName: it.responsibleDistrictDepartmentName,
          contractorName: it.contractorName,
          contractorId: it.contractorId,
          odkName: it.axp.odk.name,
          isSelected: false,
          tags: it.tags.map((it) => it.name).join(', '),
        }]));

      this.axpItems = new Map(data.map((it) => {
        let color = '';
        let status = '';
        if (it.statusInspection == 0) {
          color = 'blue';
          status = 'Не назначено на осмотр';
        } else if (it.statusInspection == 1) {
          color = 'green';
          status = 'Ожидает осмотр';
        } else if (it.statusInspection == 2) {
          if (it.statusLocalRepair == 1) {
            color = 'red';
            status = 'Ожидает ремонта';
          } else {
            color = 'yellow';
            status = 'Ремонт завершён';
          }
        } else if (it.statusInspection == 3) {
          color = 'green';
          status = 'Осмотр завершён без замечаний ';
        }
        return [it.id,
          {
            id: it.id,
            odkName: it.adreska.axp.odk.name,
            dateInstallation: it.createDate,
            contractorName: it.adreska.contractorName,
            contractorId: it.adreska.contractorId,
            locationLatitude: it.locationLatitude,
            locationLongitude: it.locationLongitude,
            eventId: it.adreska.event.id,
            color: color,
            responsibleDistrictDepartmentName: it.adreska.responsibleDistrictDepartmentName,
            adreskaId: it.adreska.id,
            tags: it.adreska.tags.map((it) => it.name).join(', '),
            balloonContent: {
              title: it.adreska.axp.odk.name,
              buttonLabel: 'Выбрать',
              props: [
                {
                  label: 'ID',
                  value: it.id,
                },
                {
                  label: 'ID программы оформления',
                  value: it.adreska.id,
                },
                {
                  label: 'АХП',
                  value: it.adreska.axp.name,
                },
                {
                  label: 'Подрядчик',
                  value: it.adreska.contractorName,
                },
                {
                  label: 'Дата монтажа',
                  value: formatDateFromOdkTimestamp(it.createDate),
                },
                {
                  label: 'Ответственный РЭС',
                  value: it.adreska.responsibleDistrictDepartmentName,
                },
                {
                  label: 'Статус',
                  value: status,
                },
              ],
            },
          },
        ];
      }));

      this.isLoadingAxp = false;
    },
    deleteAssignment(item) {
      this.toDelete = { id: [item.id], adreskaId: item.adreskaId };
      this.fetchAxpInExploitation();
      this.dialogTableDelete = true;
    },
    deleteItem() {
      this.dialogTableDelete = false;
      this.loaderDialog = true;
      this.progressValue = 0;
      const request = {
        assignments: [this.toDelete],
        type: this.type,
      };

      axios
        .post(`${BASE_URL}/assignment-for-regular-inspection?type=${this.type}`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
          this.loaderDialog = false;
          this.toDelete = {};
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      let newDate = new Date(this.sortedDatesForRange[0]);
      const startUnixTimestamp = newDate.getTime() / 1000;
      let endUnixTimestamp = 0;
      if (this.sortedDatesForRange.length > 1) {
        newDate = new Date(this.sortedDatesForRange[1]);
        endUnixTimestamp = newDate.getTime() / 1000;
      } else {
        endUnixTimestamp = startUnixTimestamp;
      }

      const dates = this.sortedDates.map((it) => new Date(it).getTime() / 1000);

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const installationItems = [];

      this.adreskaItems.forEach((it) => {
        if (it.isSelected) {
          installationItems.push({
            adreskaId: it.id,
            id: it.inspections.filter((inspection) => inspection.isSelected).map((it) => it.id),
          });
        }
      });

      if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
        const newAssignment = {
          userId: this.employeeId,
          accountId: this.fetchedRoles.find((item) => item.id === this.employeeId)?.authorization?.account.id,
          criticalityId: this.criticalityId,
          departmentId: this.departmentId,
          photos: mainPhotos,
          startConnectionDate: startUnixTimestamp,
          endConnectionDate: endUnixTimestamp,
          comment: this.comment,
        };

        const request = {
          assignments: installationItems,
          newAssignment: newAssignment,
        };

        axios
            .post(`${BASE_URL}/assignment-for-connection`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
            .then(() => {
              this.progressValue = 100;
            })
            .catch((error) => {
              console.error(error);
              this.loaderDialog = false;
              this.setNotification({ message: error.response.data });
            });

        return;
      }

      const newAssigment = {
        userId: this.employeeId,
        accountId: this.getAccount.id,
        criticalityId: this.criticalityId,
        photos: mainPhotos,
        startInspectionDate: startUnixTimestamp,
        endInspectionDate: endUnixTimestamp,
        dates: dates,
        comment: this.comment,
      };
      const request = {
        assignments: installationItems,
        newAssigment: this.employeeId ? newAssigment : null,
        type: this.type,
      };

      axios
        .post(`${BASE_URL}/assignment-for-regular-inspection?type=${this.type}`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },


    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.photos = [];
      this.criticalityId = null;
      this.criticalityName = '';
      this.criticalityItems = [];
      this.modalDateRange = false;
      this.modalDates = false;
      this.isDateValid = true;
      this.rangeDates = [];
      this.dates = [];
      this.sortedDatesForRange = [];
      this.sortedDates = [];
      this.expanded = [];
      this.search = null;
      this.axpItems = [];
      this.isLoadingAxp = false;
      this.updateMap = 0;
      this.departmentId = null;
      this.departmentName = '';
      this.departments = [];
      this.employeeId = null;
      this.employeeFullName = '';
      this.employees = [];
      this.progressValue = 0;
      this.loaderDialog = false;
      this.adreskaItems = new Map();
      this.fetchedRoles = [];
      this.tagsFilter = null;
      this.eventId = null;
      this.events = [];
      this.eventName = '';
      this.comment = null;
      this.fetchAxpInExploitation();
    },
    onClearWhoConnects() {
      this.whoConnects = null;
      this.departmentId = null;
      this.employeeId = null;
    },
    formattedInspectionData(inspectionData, adreska) {
      if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
        return inspectionData?.filter((item) => item.adreska.id === adreska.id)?.map((inspection) => ({
          id: inspection.id,
          adreskaId: adreska.id,
          departmentName: inspection.employee.departmentName,
          employeeName: inspection.employee.fullName,
          period: inspection.startConnectionDate === inspection.endConnectionDate ? formatDateFromOdkTimestamp(inspection.startConnectionDate) : `${formatDateFromOdkTimestamp(inspection.startConnectionDate)}~${formatDateFromOdkTimestamp(inspection.endConnectionDate)}`,
          shift: this.shiftDictionary[inspection.employee.shift],
          isSelected: false,
          criticality: inspection.criticality.name,
          comment: inspection.comment,
        })) ?? [];
      }

      return inspectionData[adreska.id]?.map((inspection) => ({
        id: inspection.id,
        adreskaId: adreska.id,
        departmentName: inspection.employee.departmentName,
        employeeName: inspection.employee.fullName,
        period: inspection.startInspectionDate === inspection.endInspectionDate ? formatDateFromOdkTimestamp(inspection.startInspectionDate) : `${formatDateFromOdkTimestamp(inspection.startInspectionDate)}~${formatDateFromOdkTimestamp(inspection.endInspectionDate)}`,
        dates: inspection.dates?.map((date) => formatDateFromOdkTimestamp(date))?.join(', ') ?? '',
        shift: this.shiftDictionary[inspection.employee.shift],
        isSelected: false,
        criticality: inspection.criticality.name,
        comment: inspection.comment,
      })) ?? [];
    },
  tasksUrlByType(adreskaIds) {
    if (this.hasAccessForAllObjects) {
      if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
        return `${BASE_URL}/connection-tasks/all`;
      }

      return `${BASE_URL}/adreska/byIds/regular-inspection-tasks?ids=${adreskaIds}&type=${this.type}`;
    }

    if (this.type === regularInspectionTaskType.ADRESKA_CONNECTION) {
      return `${BASE_URL}/connection-tasks`;
    }

    return `${BASE_URL}/adreska/byIds/regular-inspection-tasks/with-access?ids=${adreskaIds}&type=${this.type}`;
  },
  ...mapActions('user', [
    'getSubdivisions',
    'supplierSubdivisions',
    'getSubordinatedSubdivisions',
    'getSubdivisionWithFilteredRolesById',
    'setNotification',
  ]),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

<style scoped>
.v-data-table__expanded__content {
  box-shadow: none !important;
}

</style>

