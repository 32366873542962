<template>
  <page title="Отчёты об отпуске и покупке электроэнергии">
    <v-table-new
      v-model="transformedReports"
      :template="template"
      excel-report-file-name="Отчёты об отпуске и покупке электроэнергии"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { getContractStatusList } from 'lib/utils/formatXmlType.js';

export default {
  name: 'ReportElectricitySupplyPurchase',
  components: {
    VTableNew,
    Page,
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Дата формирования',
            key: 'date',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Наименование',
            key: 'title',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            type: 'clip',
            keyClip: 'correlationId',
            sort: {},
            filter: {},
          },
          {
            label: 'Месяц',
            key: 'accountingPeriodMonth',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Год',
            key: 'accountingPeriodYear',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'ТСО 1',
            key: 'tsoOne',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'ТСО 2',
            key: 'tsoTwo',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Статус со стороны ТСО 1',
            key: 'statusOne',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны ТСО 2',
            key: 'statusTwo',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Сформировано автоматически',
            key: 'autoGenerated',
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedReports() {
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
