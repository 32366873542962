<template>
  <dialog-template v-model="visible" collapse @closed="onClose">
      <v-dialog-header
        :title="title"
        subtitle=''
      />
    <div :class="$style.container" :style="scrollStyle">
      <TariffsContent v-show="displayContent" :props="props" :style="scrollStyle2" @tariff-changing="tariffChanging"/>
      <button :class="$style.btn" @click="onClose">Закрыть</button>
      <horizontal-progress-bar v-if="isWaiting" />
    </div>
  </dialog-template>
</template>

<script>
import DialogTemplate from 'templates/DialogTemplate';
import { mapActions } from 'vuex';

export default {
  name: 'TariffsCard',
  components: {
    DialogTemplate,
    VDialogHeader: () => import('@/components/VDialog/VDialogHeader.vue'),
    TariffsContent: () => import('organisms/tariffs/TariffsContent.vue'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    value: Boolean,
    title: String,
    tariffId: String,
  },
  data() {
    return {
      visible: false,
      isWaiting: false,
      displayContent: false,
      objects: [],
      tariffDocument: [],
      tariffsData: {},
      props: {},
    };
  },
  computed: {
    scrollStyle() {
      const height = document.documentElement.clientHeight;

      return {
        height: `${height - 100}px`,
      };
    },
    scrollStyle2() {
      const height = document.documentElement.clientHeight;

      return {
        'min-height': `${height - 178}px`,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      async handler(val) {
        this.$emit('input', val);
        if (val) {
          this.isWaiting = true;
          this.objects = await this.loadUsagePointsIndividual();
          this.tariffsData = await this.tariffsById(this.tariffId);

          const pureDataTariffOrder = await this.loadDocument(this.tariffsData.documentId);
          const preparedTariffOrder = JSON.parse(pureDataTariffOrder.json);

          if (preparedTariffOrder.Documents) {
            this.tariffDocument = preparedTariffOrder.Documents.Document;
          }

          this.props = {
            objects: this.objects,
            tariffsData: this.tariffsData,
            tariffDocument: this.tariffDocument,
          };

          this.isWaiting = false;
          this.displayContent = true;
        }
      },
    },
  },
  methods: {
    onClose() {
      this.visible = false;
    },
    tariffChanging(serviceLocation) {
      this.$emit('tariff-changing', serviceLocation);
      this.onClose();
    },
    ...mapActions('serviceLocations', ['loadUsagePointsIndividual']),
    ...mapActions('document', ['loadDocument', 'tariffsById']),
  },
};
</script>

<style lang="sass" module>
.btn
  width: 100%
  padding: 8px 16px
  background: #71757A
  color: #fff
  text-transform: uppercase
  font-style: normal
  font-weight: normal
  font-size: 21px
  line-height: 30px

.container
  overflow-y: auto
</style>
