<template>
  <Process1/>
</template>

<script>
import Process1 from 'components/Processes/Process13/Process1.vue';

export default {
  name: 'DismantlingByContractor',
  components: { Process1 },
};
</script>

<style scoped>

</style>
