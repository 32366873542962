import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function initFormAttorney() {
  const formAttorney = localStorage.getItem('formAttorney');

  if (!formAttorney) {
    return {
      homeTitle: 'Доверенности',
      homeUrl: '/cabinet/attorney',
    };
  }

  return JSON.parse(formAttorney);
}

const state = {
  powerOfAttorneys: [],
  formAttorney: initFormAttorney(),
  filter: {
    title: '',
    from: '',
    to: '',
    permissions: '',
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
