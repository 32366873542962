<template>
  <div class="document-side-select-or-input">
    <v-spoiler :summary="contractSide.name">
      <div v-if="companySelectorMode==='select'">
        <div class="row mb-24">
          <div class="col col--1of1">
            <company-selector
              :companies="[...companies]"
              :editable="editable&&editableCompany"
              :init-company-inn="company?.inn"
              @select="onCompanySelected"
            />
          </div>
        </div>
        <div v-if="company?.inn && enableDepartment" class="row mb-24">
          <div class="col col--1of1">
            <department-selector :editable="editable&&editableDepartment"
                                 :init-department="initDepartment"
                                 :inn="company?.inn"
                                 @select="onDepartmentSelected"/>
          </div>
        </div>
      </div>
      <div v-if="companySelectorMode==='input'">
        <div class="row mb-24">
          <div class="col col--1of1">
            <v-input
              v-model="company.name"
              :editable="editable&&editableCompany"
              title="Компания"
            />
          </div>
        </div>

        <div v-if="enableDepartment" class="row mb-24">
          <div class="col col--1of1">
            <v-input
              v-if="companySelectorMode==='input'"
              v-model="department.name"
              :editable="editable&&editableDepartment"
              title="Отделение/Филиал"
            />
          </div>
        </div>
      </div>

      <company-info
        v-if="showCompanyRequisites"
        :company-info="company"
        :editable="editable&&companySelectorMode==='input'"
        :only-main-requisites="onlyMainRequisites"
      />

      <div v-if="company.inn && enableSignatory">
        <employee-select-or-input :editable="editable&&editableSignatory" :init-employee="initEmployee"
                                  :inn="company.inn"
                                  :mode="companySelectorMode==='input' ? 'input' : 'auto'"
                                  :permission="permission"
                                  @employeeSelectOrInput="onEmployeeSelected"/>
      </div>
    </v-spoiler>
  </div>
</template>

<script>

import { VSpoiler } from 'components';
import CompanySelector from 'components/DocumentSideSelectOrInput/CompanySelector.vue';
import CompanyInfo from 'components/CompanyInfo/CompanyInfo.vue';
import VInput from 'components/VInput/VInput.vue';
import EmployeeSelectOrInput from 'components/DocumentSideSelectOrInput/EmployeeSelectOrInput.vue';
import DepartmentSelector from 'components/DocumentSideSelectOrInput/DepartmentSelector.vue';

export default {
  components: { DepartmentSelector, EmployeeSelectOrInput, VInput, CompanyInfo, CompanySelector, VSpoiler },
  props: {
    contractSide: {
      type: Object,
      required: true,
    },
    initCompany: {
      type: Object,
      default: null,
    },
    initEmployee: {
      type: Object,
      default: null,
    },
    initDepartment: {
      type: Object,
      default: null,
    },
    companySelectorMode: {
      type: String,
      validator: (mode) => ['select', 'input'].includes(mode),
      default: 'select',
    },
    companies: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: String,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    editableCompany: {
      type: Boolean,
      default: true,
    },
    editableDepartment: {
      type: Boolean,
      default: true,
    },
    editableSignatory: {
      type: Boolean,
      default: true,
    },
    enableDepartment: {
      type: Boolean,
      default: true,
    },
    enableSignatory: {
      type: Boolean,
      default: true,
    },
    onlyMainRequisites: {
      type: Boolean,
      default: false,
    },
    showCompanyRequisites: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
  },
  data() {
    return {
      company: this.emptyCompany(),
      employee: this.emptyEmployee(),
      department: this.emptyDepartment(),
    };
  },
  watch: {
    companies: {
      handler() {
        this.updateCompany();
      },
    },
    initDepartment: {
      handler() {
        this.updateDepartment();
      },
    },
    enableSignatory: {
      handler() {
        if (!this.enableSignatory) this.employee = null;
      },
    },
    company: {
      deep: true,
      handler() {
        this.$emit('company', { ...this.company });
      },
    },
    employee: {
      deep: true,
      handler() {
        this.$emit('employee', { ...this.employee });
      },
    },
    department: {
      deep: true,
      handler() {
        this.$emit('department', { ...this.department });
      },
    },
  },
  methods: {
    updateCompany() {
      // TODO сравнение объектов дает неверный результат, хотя объекты полностью идентичны ???
      if (!this.initCompany || JSON.stringify(this.initCompany) === JSON.stringify(this.company)) return;
      this.company = { ...this.initCompany };
    },
    updateDepartment() {
      // TODO сравнение объектов дает неверный результат, хотя объекты полностью идентичны ???
      if (!this.initDepartment || JSON.stringify(this.initDepartment) === JSON.stringify(this.department)) return;
      this.department = { ...this.initDepartment };
    },
    emptyCompany() {
      return {
        bankName: null,
        bik: null,
        correspondentAccount: null,
        id: null,
        inn: null,
        kpp: null,
        legalAddress: null,
        name: null,
        ogrn: null,
        okpo: null,
        oktmo: null,
        okved: null,
        postAddress: null,
        settlementAccount: null,
      };
    },
    emptyEmployee() {
      return {
        id: null,
        userId: null,
        accountId: null,
        fullName: {
          name: null,
          surname: null,
          patronymic: null,
        },
        position: null,
        role: {
          id: null,
          name: null,
          isCompanyOwner: null,
        },
        reason: {
          id: null,
          reasonDocument: null,
          number: null,
          date: null,
        },
        powerOfAttorney: null,
      };
    },
    emptyDepartment() {
      return {
        id: null,
        name: null,
      };
    },
    onCompanySelected(selectedCompany) {
      this.company = {
        ...selectedCompany,
        bankName: '',
        bik: '',
        correspondentAccount: '',
        settlementAccount: '',
      };
      console.log('company', this.company);
    },
    onDepartmentSelected(selectedDepartment) {
      this.department = JSON.parse(JSON.stringify(selectedDepartment));
    },
    onEmployeeSelected(selectedEmployee) {
      this.employee = JSON.parse(JSON.stringify(selectedEmployee));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";

.document-side-select-or-input {
  &__company-name {
    margin-top: 32px;
    margin-bottom: 24px;
  }
}
</style>
