<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>
    </div>

    <div v-if="orderTariffForm.orderRequisites" :class="$style.content">
      <p :class="$style.stepTitle">Внесение данных приказа по тарифам</p>
      <v-date-picker v-model="orderTariffForm.year" format="YYYY" label="Год"
                     role="year" type="year" />
      <p v-if="orderTariffForm.year_error?.error" class="errorText">
        Поле обязательно для заполнения
      </p>

      <p :class="$style.stepTitle">Реквизиты приказа</p>

      <p :class="$style.stepTitle">Дата составления документа</p>
      <v-date-picker v-model="orderTariffForm.orderRequisites.date" format="DD.MM.YYYY"
                     label="Дата" role="date" />
      <p v-if="orderTariffForm.orderRequisites.date_error?.error" class="errorText">
        Поле обязательно для заполнения
      </p>

      <p :class="$style.stepTitle">Номер документа</p>
      <c-input
        v-model="orderTariffForm.orderRequisites.orderNumber"
        role="orderNumber"
        :error="orderTariffForm.orderRequisites.orderNumber_error"
      />

      <p :class="$style.stepTitle">Название приказа</p>
      <c-input
        v-model="orderTariffForm.orderRequisites.orderName"
        role="orderName"
        :error="orderTariffForm.orderRequisites.orderName_error"
      />

      <p :class="$style.stepTitle">Кто издал приказ</p>
      <c-input
        v-model="orderTariffForm.orderRequisites.orderPublisher"
        role="orderPublisher"
        :error="orderTariffForm.orderRequisites.orderPublisher_error"
      />

      <p :class="$style.stepTitle">Текст приказа</p>
      <c-textarea
        v-model="orderTariffForm.orderBody"
        role="orderBody"
        :error="orderTariffForm.orderBody_error"
      />

      <p :class="$style.smallTitle">
        Для загрузки данных скачайте шаблоны документов в формате MS Excel
      </p>

      <p :class="$style.stepNote">
        <b>Важно:</b> скачайте и заполните шаблоны в точном соответствии с
        названием полей шаблона. Не оставляйте поля пустыми.
      </p>

      <div v-if="attachment?.name" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="attachment.name + '.xlsx'"
           href="/xlsx/utility-individual-tariff/template_adding_order.xlsx">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40" />
          <span>Шаблон {{ attachment.name }}</span>
        </a>
        <a :class="$style.downloadIcon" :download="attachment.name + '.xlsx'"
           href="/xlsx/utility-individual-tariff/template_adding_order.xlsx" title="скачать">
          <file-download />
        </a>
      </div>

      <p :class="$style.stepTitle">
        Загрузите заполненные документы XLSX на Платформу
      </p>

      <c-uploading
        v-model="filledDocuments"
        :class="$style.filledDocuments"
        :error="attachment.error"
        accept=".xlsx, .xls"
        accept-title="XLSX, XLS или перетащите его в эту область."
        role="CompanyTariff"
      />

      <div v-if="attachment?.file?.name" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="attachment.file.name"
           :href="fileUrl(attachment.file)">
          <img alt="" height="40" width="40" src="~@/assets/icons/file/attachment.svg" />
          <span>{{ attachment.file.name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteAttachment()">
          <icon-delete />
        </a>
      </div>


    </div>
  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import { mapState } from 'vuex';
import { VDatePicker } from '@/components';

export default {
  name: 'Additions',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    FileDownload: () => import('atoms/icons/file/FileDownload'),
    IconDelete: () => import('atoms/icons/action/Delete'),
    VDatePicker,
    CInput: () => import('atoms/common/inputs/CInput'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
    componentKey: Number,
    attachment: {
      type: Object,
      required: true,
    },
    utilityIndividualTariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderTariffForm: { ...this.utilityIndividualTariff?.orderTariff },
      filledDocuments: [],
      errorReport: undefined,
      isWaitingSubmit: false,
      isCompleted: false,
    };
  },

  computed: {
    ...mapState('user', ['data']),
  },
  watch: {
    filledDocuments: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          return;
        }

        this.$emit('parseAttachment', val[0]);
        this.filledDocuments = [];
      },
    },
    orderTariffForm: {
      immediate: false,
      deep: true,
      handler(val) {
        this.$emit('updateOrderTariff', val);
      },
    },
  },
  methods: {
    fileUrl(file) {
      return getFileUrl(file);
    },
    deleteAttachment() {
      this.$emit('clearAttachment');
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    margin-bottom: 16px
    border-color: #C1C4C7

.content, .viewWidth
  table
    tr
      th
        width: 1%
        white-space: nowrap

      th.header
        text-align: center

      td, th
        border: 1px solid #0E0F0F

        input
          height: 100%
          width: 100%
          border: 0
          font-size: 16px
          margin: 0

      td
        p
          width: 100%
          text-align: center

      td.error
        input
          border: 1px solid red

        text-align: left

        p
          margin: 0

      th, td.text
        padding: 18px

    thead
      td
        font-weight: bold
        text-align: center

.viewWidth
  width: 100%
  margin-top: 64px

  table
    width: 98%

  p
    text-align: center

  table, p
    margin: 0 auto 32px

.attachment, .fileArchive, .attachmentReport
  +base-subtitle

.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757
</style>
