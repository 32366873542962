<template>
  <div
    :class="{
      [$style['pagination-button']]: true,
      [$style['pagination-button--selected']]: selected,
    }"
    @click="$emit('input', size)"
  >
    {{ size }}
  </div>
</template>

<script>
export default {
  name: 'VPaginationButton',
  props: {
    size: { type: Number, required: true },
    selected: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" module>
.pagination-button {
  width: 30px;
  height: 30px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #4c4e51;
  border-radius: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &:hover {
    background-color: #dee2e6;
  }

  &--selected {
    background: #979ca2;
    color: #ffffff;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
