<template>
  <page :title="pageTitle">
    <data-tab-selector
      :data-types-list="tabs"
      @selectTab="selectTabHandler"
    />
    <axp v-if="selectedTab === 'axp'"/>
    <odk v-if="selectedTab === 'odk'"/>
    <elements-axp v-if="selectedTab === 'elements'"/>
  </page>
</template>

<script>
import { Page } from '@/components';
import DataTabSelector from 'components/DataTabSelector/DataTabSelector.vue';
import Axp from './Axp.vue';
import ElementsAxp from 'views/private/OdkAccounting/Axp/ElementsAxp.vue';
import Odk from 'views/private/OdkAccounting/Axp/Odk.vue';

export default {
  name: 'OdkAccountingAxpIndexPage',
  components: { Odk, ElementsAxp, Axp, DataTabSelector, Page },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'АХП типы',
          value: 'axp',
          isActive: true,
        },
        // {
        //   id: 2,
        //   title: 'ОДК',
        //   value: 'odk',
        //   isActive: false,
        // },
        {
          id: 3,
          title: 'Элементы АХП',
          value: 'elements',
          isActive: false,
        },
      ],
      selectedTab: 'axp',
    };
  },
  computed: {
    pageTitle() {
      if (this.selectedTab === 'odk') return 'ОДК';
      if (this.selectedTab === 'elements') return 'Элементы АХП';
      return 'АХП типы';
    },
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
