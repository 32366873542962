<template>
  <div class="vuetify-styles">
    <v-dialog v-model="visibleDialog"
              class="v-dialog-top" max-width="500px" persistent>
      <template #activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          class="mb-2 flex-grow-1"
          color="primary"
          elevation="0"
          rounded
          v-bind="attrs"
          v-on="on"
        >
          Добавить АХП
        </v-btn>
      </template>
      <v-card
        class="v-application">
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <axp-autocomplete
            ref="axp"
            v-model="editedItem.axpId"
            :items.sync="editedItem.axps"
            :search.sync="editedItem.axpName"
            class="mt-2"
            is-required
            title="АХП"
          />
          <text-field
            ref="count"
            v-model="editedItem.count"
            is-only-integer-numbers
            is-required
            title="Количество"
          />
          <text-field
            ref="price"
            v-model="editedItem.price"
            is-only-numbers
            title="Цена за ед., руб."
          ></text-field>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn color="primary" outlined rounded @click="onTapCloseTableDialog">
            Отмена
          </v-btn>
          <v-spacer/>
          <v-btn
            :disabled="isDisabledSaveDialog"
            color="primary"
            elevation="0"
            rounded
            @click="onTapSaveDialog"
          >
            {{ formButtonTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextField from '@/components/Processes/components/TextField.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';

export default {
  components: {
    AxpAutocomplete,
    TextField,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      default: false,
    },
    edit: {
      default: null,
    },
    handleSuccess: {
      type: Function,
      required: true,
    },
    handleError: {
      type: Function,
      default: () => ({}),
    },
    cancel: {
      type: Function,
      default: () => ({}),
    },
    url: {
      default: null,
    },
    dialogData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      default: true,
    },
    axpId: {
      default: -1,
    },
  },

  data() {
    return {
      editedItem: {
        axpId: null,
        axpName: '',
        count: null,
        axps: [],
        price: null,
      },
    };
  },

  computed: {
    visibleDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    formTitle() {
      return this.edit > -1 ? 'Редактирование' : 'Добавление';
    },

    formButtonTitle() {
      return this.edit > -1 ? 'Сохранить' : 'Добавить';
    },

    isDisabledSaveDialog() {
      const isDisabledElementAxp = this.editedItem.axpId == null;
      const isDisableCount = this.editedItem.count == null || !this.editedItem.count.match('^\\d+$');
      const isDisablePrice = this.editedItem.price != null && isNaN(Number(this.editedItem.price));

      return isDisabledElementAxp || isDisableCount || isDisablePrice;
    },
  },

  watch: {
    edit(newVal) {
      if (newVal > -1) {
        Object.assign(this.editedItem, this.dialogData);
      } else {
        this.defaultValue();
      }
    },
  },

  methods: {
    onTapSaveDialog() {
      this.handleSuccess(this.editedItem);
      this.resetValidation();
    },

    onTapCloseTableDialog() {
      this.visibleDialog = false;
      this.afterClose();
    },

    resetValidation() {
      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));
    },
    afterClose() {
      this.defaultValue();

      this.resetValidation();

      this.cancel();
    },

    defaultValue() {
      this.editedItem.axpId = null;
      this.editedItem.axpName = '';
      this.editedItem.count = null;
      this.editedItem.axps = [];
      this.editedItem.price = null;
    },
  },
};
</script>
