<template>
  <page title="Баланс электрической энергии">
    <template #default>
      <balances />
    </template>
  </page>
</template>

<script>
import { Page } from '@/components';
import Balances from './Balances';

export default {
  name: 'BalancesPage',
  components: { Balances, Page },
  data() {
    return {
      activeTab: 'balances',
    };
  },
  computed: {
    tabOptions: () => [
      { label: 'Баланс общий', value: 'balances' },
      // { label: 'Баланс по участкам сети', value: 'templates' },
    ],
  },
};
</script>

<style lang="scss" module>
.title {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
}
</style>
