<template>
  <div :class="$style.tabs">
    <v-tab
      v-for="option in options"
      :key="option.value"
      :is-active="value === option.value"
      @click="$emit('input', option.value)"
    >
      {{ option.label }}
    </v-tab>
  </div>
</template>

<script>
import VTab from './VTab';

export default {
  name: 'VTabs',
  components: { VTab },
  props: {
    options: { type: Array, required: true },
    value: { type: String, default: '' },
  },
};
</script>

<style lang="scss" module>
.tabs {
  display: flex;
}
</style>
