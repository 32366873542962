export const AddDirectoryFieldModalActions = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  EMPTY: 'EMPTY',
};

export const AddDirectoryFieldModalFieldType = {
  TEXT: 'TEXT',
  ADDRESS: 'ADDRESS',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  BOOLEAN: 'BOOLEAN',
  YEAR: 'YEAR',
  REFERENCE: 'REFERENCE',
  CUSTOM: 'CUSTOM',
};
