<template>
  <v-popover v-bind="$attrs" :container="false">
    <div class="tooltip-target">
      <help-icon v-if="small" class="small"/>
      <help-icon v-else />
    </div>

    <template slot="popover">
      <div class="tooltip-content">
        <slot />

        <div v-close-popover class="tooltip-close">
          <close-icon />
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { VPopover, VClosePopover } from 'v-tooltip';

export default {
  name: 'TooltipHelper',
  components: {
    VPopover,
    HelpIcon: () => import('atoms/icons/basic/Help.vue'),
    CloseIcon: () => import('atoms/icons/basic/Close.vue'),
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'close-popover': VClosePopover,
  },
};
</script>

<style lang="sass" scoped>
  .tooltip-target
    display: flex
    cursor: pointer

    svg
      fill: #ACB0B5

    svg.small
      height: 16px

  .tooltip-content
    padding: 18px 32px 18px 24px
    background-color: #F5F6F6
    border-radius: 5px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2)

    // &:after
    //   content: ''
    //   position: absolute
    //   right: 10%
    //   bottom: -20px
    //   border: 10px solid transparent
    //   border-top: 10px solid #fff

  .tooltip-close
    width: 14px
    height: 14px
    display: flex
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer

    svg
      width: 100%
      height: 100%
      fill: #ACB0B5
</style>
