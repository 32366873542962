var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"click":function($event){return _vm.openSearch(false)}}},[(_vm.showMicrogenerationHelp)?_c('div',{class:_vm.$style.microgeneration},[_c('span',{class:_vm.$style.text,on:{"click":function($event){return _vm.onSelectMicrogeneration()}}},[_vm._v(" Стань "),_c('img',{class:_vm.$style.iconUnion,attrs:{"alt":"icon: union","height":"23px","src":require("@/assets/images/microgeneration/iconunion.png"),"width":"16px"}}),_vm._v(" микрогенератором. Передавай электроэнергию в сеть и уменьшай сумму счёта. ")]),_c('i',{class:_vm.$style.iconClose,on:{"click":function($event){return _vm.close()}}})]):_vm._e(),_c('div',{class:_vm.$style.container},[_c('dialog-template',{model:{value:(_vm.isShownMicrogenerationHelpData),callback:function ($$v) {_vm.isShownMicrogenerationHelpData=$$v},expression:"isShownMicrogenerationHelpData"}},[_c('Microgeneration',{on:{"close":_vm.close}})],1),_c(_vm.headerComponent,{tag:"component",attrs:{"is-compact":_vm.isCompact,"is-open-search":_vm.isOpenSearch},on:{"mode":_vm.setMode,"openSearch":function($event){return _vm.openSearch(true)}}}),_c('div',{class:{[_vm.$style.content]: !_vm.showMicrogenerationHelp, [_vm.$style.contentHelp]: _vm.showMicrogenerationHelp}},[_c('div',{class:{
          [_vm.$style.leftColumn]: true,
          [_vm.$style.compact]: _vm.isCompact,
          [_vm.$style.full]: !_vm.isCompact,
          [_vm.$style.over]: _vm.isOver,
        },style:(_vm.compactStyle),on:{"click":_vm.scrollTop}},[_c('left-menu',{attrs:{"is-compact":_vm.isCompact}})],1),_c('div',{ref:"rightcolumn",class:_vm.$style.rightColumn,on:{"scroll":_vm.handleScroll,"click":_vm.clickRightColumn}},[(_vm.$store.state.chatbot.isActive)?_c('Chat'):_vm._e(),_c('router-view')],1)]),_c('microgenerator-connection')],1),_c('cookie-notification',{attrs:{"visible":_vm.cookieVisible},on:{"close":_vm.closeCookie}}),_c('scroll-to-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isScrollButtonShow),expression:"isScrollButtonShow"}],attrs:{"get-scroll-target-ref":() => _vm.$refs['rightcolumn']}}),(_vm.isDeleteProfileDialog)?_c('div',{class:_vm.$style.modalBackdrop},[_c('dialog-template',{attrs:{"center":"","hide-close-button":""},model:{value:(_vm.isDeleteProfileDialog),callback:function ($$v) {_vm.isDeleteProfileDialog=$$v},expression:"isDeleteProfileDialog"}},[_c('div',{class:_vm.$style.deleteProfileDialog},[_c('p',[_vm._v(_vm._s(_vm.deleteProfileDialogText))]),(_vm.submitError.length !== 0)?_c('p',{staticClass:"errorText"},[_vm._v(_vm._s(_vm.submitError))]):_vm._e(),_c('v-button',{attrs:{"variant":"filled"},on:{"click":_vm.agreeDeleteProfile}},[_vm._v("Согласен")]),(_vm.isWaitingDocumentUpload)?_c('horizontal-progress-bar'):_vm._e()],1)])],1):_vm._e(),(_vm.isDisplaySendCode)?_c('modal',{attrs:{"width":"592px","header-style":{padding: '16px 16px 16px 24px'},"modal-style":{
        borderRadius: '16px',
        padding: '6px 0 36px 0',
      }},on:{"close":_vm.logOut},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{class:_vm.$style.modalTitle},[_c('img',{attrs:{"src":require("@/assets/icons/user/phone.svg")}}),_c('span',[_vm._v("Ввод кода при смене аккаунта")])]),_c('div',{class:_vm.$style.modalBody},[_c('span',{class:_vm.$style.codeTitle},[_vm._v(_vm._s(_vm.codeTitles.titleContact))]),_c('input',{staticStyle:{"height":"40px","padding-left":"16px","margin-bottom":"16px"},attrs:{"disabled":""},domProps:{"value":_vm.codeTitles.contact}}),_c('span',{class:_vm.$style.codeTitle},[_vm._v(_vm._s(_vm.codeTitles.titleCode))]),_c('one-time-password-input',{attrs:{"is-error":_vm.isCodeError,"is-waiting":_vm.isWaitingCode,"input-style":"height: 40px;font-size: 16px;letter-spacing:8px","retry-button-style":'marginTop: 8px;justifyContent: left'},on:{"code-input":_vm.codeEntered,"code-request":_vm.requestCode}}),(_vm.isWaitingCode)?_c('horizontal-progress-bar'):_vm._e(),(_vm.isCodeError)?_c('div',{staticClass:"errorText",attrs:{"role":"errorText"}},[_vm._v(" "+_vm._s(_vm.codeErrorMessage)+" ")]):_vm._e(),_c('v-button',{staticStyle:{"width":"136px","margin":"24px auto 0 auto"},attrs:{"disabled":_vm.code.length < 4},on:{"click":_vm.sendCode}},[_vm._v("Подтвердить")])],1)]},proxy:true}],null,false,2506547871)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }