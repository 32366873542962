<template>
  <page title="Справочник ответственных сотрудников подрядчиков">
    <v-table-new
      v-model="transformedResponsibleByContractor"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник ответственных сотрудников подрядчиков"
      @delete="onDelete"
      @edit="onEdit"
    />
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createResponsible"
      @delete="deleteResponsible"
      @update="updateResponsible">
      <template
        #responsiblePhone="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
          :options="suppliersOptions"
          :required="slotProps.field.required"
          :rules-messages="{regex: 'Неправильно введён номер телефона'}"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="regex:^\+7 \([1-9]\d{2}\) \d{3}(-\d{2}){2}$"
          type="phoneNumber"
          @input="slotProps.input"
        />
      </template>
    </add-directory-field-modal>
  </page>
</template>

<script>
import { Page, VInput, VTableNew } from '@/components';
import {
  createResponsibleByContractor,
  deleteResponsibleByContractor,
  fetchResponsibleByContractor,
  updateResponsibleByContractor,
} from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import formatting from 'lib/utils/formatting';

export default {
  name: 'CabinetResponsibleByContractorScreen',
  components: {
    AddDirectoryFieldModal,
    VTableNew,
    VInput,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      responsibleByContractor: [],
      suppliersOptions: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'contractorId',
          options: this.suppliersOptions,
          name: 'Подрядчик',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'responsibleName',
          name: 'ФИО ответственного',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'responsiblePhone',
          name: 'Телефон ответственного',
          required: true,
          customValidator: (value) => {
            const pattern = /^(\+7) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
            return pattern.test(value);
          },
        }];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              width: '60px',
            },
          },
          {
            label: 'Наименование подрядчика',
            key: 'contractorName',
            sort: {},
            filter: { type: 'contractorOdk' },
          },
          {
            label: 'ФИО ответственного',
            key: 'responsibleName',
            sort: {},
            filter: {},
          },
          {
            label: 'Телефон ответственного',
            key: 'responsiblePhone',
            sort: {},
            filter: {},
          },
          ...editHeaders,
        ],
      };
    },
    transformedResponsibleByContractor() {
      if (!this.responsibleByContractor?.length) {
        return [];
      }

      return this.responsibleByContractor.map((it) => ({
        ...it,
        responsiblePhone: formatting.phoneFormat(it.responsiblePhone).formattedPhone,
      }));
    },
    isAccessUpdate() {
      return filterForAccess('process/1/6', this.getAuthorization?.blockAccess);
    },
    actionBlockItems() {
      if (!this.isAccessUpdate) return [];
      return [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ];
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.responsibleByContractor = await fetchResponsibleByContractor();
        const suppliers = await this.getSupplierForSelectorByFilter({ kind: 'ODK' });
        this.suppliersOptions = suppliers.map((it) => ({ value: it.id, label: it.name }));
      } catch (error) {
        console.error(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    deleteResponsible() {
      this.doAction(() => deleteResponsibleByContractor(this.modalValue.id));
    },
    createResponsible() {
      this.doAction(() => createResponsibleByContractor(
        this.modalValue.contractorId,
        this.modalValue.responsibleName,
        this.modalValue.responsiblePhone,
        this.getToken,
      ));
    },
    updateResponsible() {
      this.doAction(() => updateResponsibleByContractor(
        this.modalValue.id,
        this.modalValue.contractorId,
        this.modalValue.responsibleName,
        this.modalValue.responsiblePhone,
        this.getToken,
      ));
    },
    async doAction(action) {
      this.loading = true;
      try {
        await action();
        this.closeModal();
        await this.loadData();
      } catch (error) {
        console.log('catch');
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('cabinet', ['getSupplierForSelectorByFilter']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
