<template>
  <portal to="modal" :order="2">
    <vue-draggable-resizable
      v-if="visible"
      :key="key"
      :prevent-deactivation="true"
      class-name-active="my-active-class"
      :parent="true"
      :w="dialogWidth"
      :x="x"
      :y="y"
      :h="50"
      :drag-handle="'.dialog-container'"
      style="
        position: absolute;
        top: 0;
        z-index: 10040;
      "
      :class="{
        [$style['modal-height']]: !height100 && height !== 50,
        [$style['modal-height--minimize']]: height === 50,
        [$style['modal-height--full']]: height100 && height !== 50,
      }"    >
      <div
        v-if="visible"
        :class="{
          'c-dialog': true,
          'c-dialog--center': center,
          'c-dialog--roundedStyle': styleType === 'roundedStyle',
        }"
      >
        <transition name="bottom-animate" appear>
          <div style="width: 100%">
            <i
                v-if="!hideCloseButton"
                :class="$style['iconClose']"
                @click="onClose"
            ></i>
            <i
              v-if="collapse"
              :class="$style['collapse_icon']"
              @click="onMinimize()"
            ></i>
            <slot/>
          </div>
        </transition>
      </div>
    </vue-draggable-resizable>
  </portal>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import VueDraggableResizable from 'vue-draggable-resizable';
import { nanoid } from 'nanoid';

/**
 TODO:
 1. вынести все анимации в отдельный файл
 2. сделать анимации закрытия
 3. проверить другие браузеры
 **/
export default {
  name: 'CDialog',
  components: { VueDraggableResizable },
  props: {
    value: Boolean,
    height100: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      required: false,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.x = ((window.innerWidth - this.dialogWidth) / 2);
    this.y = this.center ? ((window.innerHeight / 2) / 2) : this.top;
  },
  data() {
    return {
      key: nanoid(),
      visible: false,
      height: 'auto',
      x: 0,
      y: 0,
    };
  },
  computed: {
    dialogWidth() {
      return this.styleType === 'roundedStyle' ? 960 : 700;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible(val) {
      if (val) {
        if (this.$route.path.includes('/cabinet/objects')) {
          enableBodyScroll(document.body);
        } else {
          disableBodyScroll(document.body);
        }
      } else {
        enableBodyScroll(document.body);
      }
      this.$emit('input', val);
    },
  },
  methods: {
    onClose() {
      this.visible = false;

      this.$emit('close');
      this.height = 'auto';
    },
    onMinimize() {
      this.height = this.height === 'auto' ? 50 : 'auto';
    },
  },
};
</script>

<style lang="scss" module>
.modal-height {
  height: fit-content !important;
  &--full {
    height: 98% !important;
  }

  &--minimize {
    height: 50px !important;

    button {
      display: none;
    }
  }
}

.collapse_icon {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  z-index: 10040;
  top: 14px;
  right: 40px;
  background-image: url('~icons/modal/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 175%;
  cursor: pointer;
}

.iconClose {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  z-index: 10040;
  top: 14px;
  right: 14px;
  background-image: url('~icons/modal/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  cursor: pointer;
}
</style>

<style lang="sass">
$color-dialog-overlay: rgba(0, 0, 0, 0.2)

.left-animate-enter-active
  transition: transform .5s

.left-animate-enter
  transform: translateX(-100%)

.right-animate-enter-active
  transition: transform .5s

.right-animate-enter
  transform: translateX(100%)

.top-animate-enter-active
  transition: transform .5s

.top-animate-enter
  transform: translateY(-100%)

.bottom-animate-enter-active
  transition: transform .5s

.bottom-animate-enter
  transform: translateY(100%)

.my-active-class
  z-index: 10040 !important

.c-dialog
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px
  height: 100%
  width: 100%
  display: flex
  overflow-y: hidden

  &--roundedStyle
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15)
    border-radius: 24px

.c-dialog-overlay
  position: absolute
  top: 0
  left: 0
</style>
