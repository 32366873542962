<template>
  <inner-page
    :can-history-be-collapsed="false"
    :history="convertedDocumentHistory"
    :history-spacing="0"
    :steps="['Ведомости снятия показаний']"
    history-title="История"
    home="Показания"
    @back="$router.back()"
    @goHome="goHome"
  >
    <div class="row">
      <p class="col col--1of1 page-form__title">
        Ведомости снятия показаний
      </p>
    </div>

    <div class="row mb-16">
      <p class="col col--1of1 page-form__sub-title">
        Сведения о точке:
      </p>
    </div>

    <div class="row mb-16">
      <div class="col col--1of1">
        <v-input
          v-model="pointType"
          :disabled="true"
          title="Тип точки *"
        />
      </div>
    </div>

    <v-divider />

    <div class="row">
      <p class="col col--1of1 page-form__sub-title">
        Представитель сетевой организации:
      </p>
    </div>

    <div class="row">
      <div class="col col--1of3">
        <v-input
          v-model="selectedEmployee.fullName.surname"
          :disabled="true"
          title="Фамилия *"
        />
      </div>
      <div class="col col--1of3">
        <v-input
          v-model="selectedEmployee.fullName.name"
          :disabled="true"
          title="Имя *"
        />
      </div>
      <div class="col col--1of3">
        <v-input
          v-model="selectedEmployee.fullName.patronymic"
          :disabled="true"
          title="Отчество"
        />
      </div>
    </div>

    <v-divider />

    <div class="row">
      <div class="col col--1of1">
        <documents-list
          :documents="[
            {
              name: name,
              documentId: documentId,
            },
          ]"
        />
      </div>
    </div>

    <v-divider />

  </inner-page>
</template>

<script>
import {
  InnerPage,
  VDivider,
  VInput,
  DocumentsList,
} from '@/components';


export default {
  name: 'ReadingSheetsCard',
  components: {
    InnerPage,
    VDivider,
    VInput,
    DocumentsList,
  },
  created() {
    const { pointType, fullName, documentId, name, documentHistory } = this.$route.params;

    if (!pointType || !fullName || !documentId || !name || !documentHistory) {
      return this.goHome();
    }
    this.pointType = pointType;
    this.selectedEmployee.fullName.name = fullName.name;
    this.selectedEmployee.fullName.patronymic = fullName.patronymic;
    this.selectedEmployee.fullName.surname = fullName.surname;
    this.documentId = documentId;
    this.name = name;
    this.convertedDocumentHistory = this.convertDocumentHistory(documentHistory);
  },
  data() {
    return {
      pointType: '',
      selectedEmployee: {
        fullName: {
          name: '',
          surname: '',
        },
      },
      documentId: '',
      convertedDocumentHistory: [],
    };
  },
  methods: {
    goHome() {
      this.$router.push('/cabinet/reading-sheets');
    },
    convertDocumentHistory(documentHistory) {
      return documentHistory.map((it) => ({
        action: it.action,
        datetime: it.datetime,
        documentName: this.name,
        role: it.role,
        account: it.account,
        user: it.user,
        documentHash: it.documentHash,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";
.action-buttons {
  display: flex;

  button {
    height: 40px;
    font-family: Roboto, 'sans-serif';
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }
  }

  .btn-print {
    background-image: url('~icons/basic/print.svg');
    background-repeat: no-repeat;
    background-position: center left 14px;
    padding-left: 48px;
  }

  &__action {
    width: 100%;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 16px;
      }
    }
  }
}
</style>
