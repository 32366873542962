<template>
  <cabinet-employee-data-form/>
</template>

<script>
import CabinetEmployeeDataForm from 'views/private/CabinetUserDetails/CabinetUserCard/CabinetEmployeeDataForm';
export default {
  name: 'CabinetEmployeeProfileScreen',
  components: { CabinetEmployeeDataForm },
};
</script>

<style scoped>

</style>
