<template>
  <div class="privacy-policy" style="padding: 16px; border: 1px solid #C1C4C7; border-radius: 16px ">
    <div ref="scrollContainer" style="max-height: 400px; overflow: auto" @scroll="onScroll">
      <div style="font-size: 18px; white-space: break-spaces;" v-html="agreementText"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalDataAgreementStep',
  props: {
    agreementText: { required: true },
  },
  methods: {
    onScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      if (Math.abs(scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight) < 2) {
        this.$emit('scrolled-to-end');
      }
    },
  },
};
</script>

<style scoped>

</style>
