<template>
  <validation-provider
      ref="validationProvider"
      v-slot="{ errors, validate }"
      :custom-messages="rulesMessages"
      name="Код подтверждения"
      :rules="rules"
      :vid="vid"
      mode="lazy"
      tag="div"
  >
    <div v-if="!isInternalDisplaySendButton || isValidationErrors">
      <masked-input
          id="user-code"
          v-model="code"
          :class="{
        ['one-time-password__code-input_error']: isError
      }"
          :mask="[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]"
          :show-mask="true"
          :style="inputStyle"
          class="one-time-password__code-input"
          role="code"
          @validate="validate"
      />
      <span
          v-if="isValidationErrors && !isDisabled"
          class="errorText">{{ errors[0] }}
    </span>
      <horizontal-progress-bar v-if="isDisplaySendButton && isWaiting"/>
      <div class="one-time-password__retry-wrp" :style="retryButtonStyle">
        <button :disabled="!canRetry || isDisabled" class="one-time-password__retry-button" @click="requestCode">
          <span v-if="!isCodeSendingEnabled" class="one-time-password__retry-text"> выслать повторно через {{ timer }} секунд </span>
          <span v-else class="one-time-password__retry-text">выслать повторно</span>
        </button>
      </div>
    </div>
    <button v-else :disabled="isDisabled" class="one-time-password__send-button" @click="sendCode">Отправить код для подтверждения</button>
  </validation-provider>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import { delay } from '@/utils/delay';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'OneTimePasswordInput',
  components: {
    MaskedInput,
    HorizontalProgressBar,
    ValidationProvider,
  },
  props: {
    isWaiting: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisplaySendButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: '',
    },
    rulesMessages: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: false,
    },
    retryButtonStyle: {
      type: String,
      default: '',
    },
    inputStyle: {
      type: String,
      default: '',
    },
  },
  created() {
    if (!this.isDisplaySendButton) {
      this.requestCode();
    }
  },
  mounted() {
    if (this.isError) {
      this.$refs.validationProvider?.validate();
    }
    this.validationProvider = this.$refs.validationProvider;
  },
  data() {
    return {
      code: '',
      timer: 0,
      isInternalDisplaySendButton: this.isDisplaySendButton,
      validationProvider: {},
    };
  },
  computed: {
    canRetry() {
      return this.isCodeSendingEnabled && !this.isWaiting;
    },
    isCodeSendingEnabled() {
      return this.timer === 0;
    },
    rules() {
      return `${this.required ? 'required|oneTimeCode' : ''}`;
    },
    isValidationErrors() {
      return this.validationProvider?.errors?.length > 0;
    },
  },
  watch: {
    code(newValue) {
      this.isInternalDisplaySendButton = false;
      const formattedCode = newValue.replace(RegExp('[ _]?', 'g'), '');
      this.$emit('code-input', formattedCode);
    },
    async timer(newValue) {
      if (newValue > 0) {
        await delay(1000);
        this.timer--;
      }
    },
    isValidationErrors(newVal) {
      if (this.isDisabled || !this.isInternalDisplaySendButton || !newVal) {
        return;
      }

      this.sendCode();
    },
    isDisplaySendButton(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.isInternalDisplaySendButton = newVal;
        this.sendCode();
      }
    },
    isError(newVal) {
      if (newVal) {
        this.validationProvider.validate();
      }
    },
  },
  methods: {
    requestCode() {
      this.$emit('code-request');
      this.timer = 60;
    },
    sendCode() {
      this.isInternalDisplaySendButton = false;
      this.requestCode();
    },
  },
};
</script>
<style lang="scss" scoped>
.one-time-password {
  &__code-input {
    width: 100%;
    height: 64px;
    padding: 4px 8px;
    text-align: center;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 32px;
    line-height: 64px;
    color: #4F4F4F;
    border: 1px solid #C4C4C4;

    &:focus {
      border: 1px solid black;
      outline: 0;
    }

    &_error {
      color: #EB5757;
      border-color: #EB5757;

      &:focus {
        border-color: #EB5757;
        outline: 0;
      }
    }
  }

  &__retry {
    &-wrp {
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }

    &-text {
      margin-top: 8px;
      text-align: center;
      font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #4C4E51;
      text-transform: uppercase;
    }

    &-button {
      background: inherit;
      border: 0;
      outline: none;
    }
  }

  &__send-button {
    background-color: #2f82df;
    color: #ffffff;
    width: 100%;
    height: 40px;
  }

  &__send-button:disabled {
    background-color: #7e8b91;
  }
}
</style>
