<template>
  <div v-show="isDisplay" :class="$style.container">
    <div>
      <div v-show="isNewDocs && regulatoryDocs.length === 0" :class="$style.blockContent">
        <div :class="$style.title">
          Скачайте регулирующий документ
        </div>
        <p>Скачайте с <a
          href="https://www.mos.ru/depr/documents/tarifnaia-politika/pa-v-sfere-gosudarstvennogo-regulirovaniya-tarifov/"
          target="_blank">сайта Департамента экономической политики и развития города Москвы</a> последний регулирующий
          документ об установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп
          потребителей {{ region }} на {{ year }} год.</p>
      </div>

      <div v-if="reviewDocument.comments" :class="$style.blockContent">
        <spoiler-preview v-model="spoiler.review">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title="Результаты проверки">
              <template slot="icon">
                <chevron-up v-if="spoiler.review" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <p :class="$style.reviewComments" v-html="comments"></p>
          </template>
        </spoiler-preview>
      </div>

      <div :class="$style.blockContent">
        <div :class="$style.title">
          <span v-if="isNewDocs && regulatoryDocs.length === 0">Загрузите регулирующий документ</span>
          <span v-else>Регулирующий документ</span>
        </div>
        <p :class="$style.documentTitle">Приказ Департамента экономической политики и развития города Москвы «Об
          установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп потребителей
          города Москвы»</p>
      </div>

      <div v-if="!isNewDocs && tariffDocument.length > 0" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in tariffDocument" :key="index" style="display: flex; justify-content: space-between">
          <a :download="file.name" :href="fileUrl(file)" :role="'tariffDocument[' + index + '].name'">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;<span>{{ file.name
            }}</span></a>
          <span :class="$style.replace" @click="replace">
            <img alt="" src="~@/assets/icons/basic/replace.svg" />
            <span :role="'tariffDocument[' + index + '].replace'">
                &nbsp;&nbsp;&nbsp;заменить</span>
          </span>

        </p>
      </div>

      <div v-show="isNewDocs" :class="$style.blockContent">
        <div>
          <div v-if="regulatoryDocs.length">
            <file-list-preview
              :list="regulatoryDocs"
              action
              @delete="handleRemoveRegulatoryDocs"
            />
          </div>

          <c-uploading
            v-model="regulatoryDocs"
            accept=".pdf"
            accept-title="PDF или перетащите его в эту область."
            role="regulatoryDocs"
          />
        </div>
      </div>

      <div v-show="regulatoryDocs && regulatoryDocs.length > 0" :class="$style.blockContent">
        <spoiler-preview v-model="spoiler.tariff">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title="Тарифы">
              <template slot="icon">
                <chevron-up v-if="spoiler.tariff" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <tariff-form
              v-model="tariff"
              :description="description"
              :dictionary-category="tariffCategory"
              :dictionary-rate="tariffRate"
              :invalid-values="reviewDocument.params"
              :region="region"
              :tariffs-data="tariffsData"
              :task-document-id="documentId"
              :year="year"
              mode="edit"
            />
          </template>
        </spoiler-preview>

        <p :class="$style.submitNote">
          Тарифы будут направлены на проверку сотруднику платформы. В течение 1 дня вам придёт уведомление о результатах
          проверки.
        </p>
      </div>
    </div>


    <div>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <div :class="$style.buttons">
        <submit-task-details :class="$style.close" :disabled="isWaitingSubmit" title="продолжить позже"
                             @submit="onClose" />
        <submit-task-details :class="$style.submit" :disabled="isWaitingSubmit || !isValid" :title="submitTitle"
                             @submit="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import generateXml from 'lib/storage/connection-application/data';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import TariffForm from 'organisms/tasks/tariffs-update/TariffForm';
import { getCurrentDate } from 'lib/utils/date';
import { scrollIntoView } from 'lib/utils/DOM';

// TODO move to lib
function convertDocumentScan(targetArray, file) {
  targetArray.push({
    code: file.value,
    name: file.name,
    type: file.contentType,
  });
}

export default {
  name: 'UploadTariff',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    FileListPreview,
    SpoilerPreview,
    SpoilerHeader,
    TariffForm,
    ChevronDown,
    ChevronUp,
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    reviewDocument: Object,
    retailCompanyName: String,
    region: String,
    year: String,
    description: String,
    isDisplay: {
      type: Boolean,
      default: true,
    },
    tariffDocument: [],
    tariffsData: {},
    initSpoiler: {
      type: Object,
      default: () => ({
          tariff: true,
        }),
    },
  },
  data() {
    return {
      isChecked: true,
      correctionComments: '',
      submitError: '',
      isWaitingSubmit: false,
      regulatoryDocs: [],
      spoiler: {
        tariff: this.initSpoiler.tariff,
        review: true,
      },
      tariff: [],
      isNewDocs: true,
    };
  },
  computed: {
    isValid() {
      return this.regulatoryDocs.length > 0;
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '', '');
    },
    submitTitle() {
      if (this.isNewDocs || this.spoiler.tariff) {
        return 'Отправить на проверку';
      }
      return 'Исправить ошибки';
    },
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
  },
  watch: {
    tariffDocument: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.isNewDocs = false;
          this.regulatoryDocs = [];
          convertDocumentScan(this.regulatoryDocs, val[0]);
        }
      },
    },
  },
  methods: {
    validate(tariff, firstError) {
      if (tariff.value.length === 0 || tariff.value === '0,00') {
        tariff.error = true;

        if (firstError.length === 0) {
          firstError.push(tariff.id);
        }
      } else {
        tariff.error = false;
      }
    },
    async onSubmit() {
      if (!this.spoiler.tariff) {
        this.spoiler.tariff = true;

        if (this.reviewDocument.firstInvalid) {
          const container = document.querySelector(`[role="${this.reviewDocument.firstInvalid}"]`);
          setTimeout(() => {
            scrollIntoView(container);
          }, 50);

        }
        return;
      }

      const firstError = [];

      this.tariff.forEach((tariff) => {
        if (tariff.rates) {
          this.validate(tariff.rates.rate1.first.t1, firstError);

          this.validate(tariff.rates.rate2.first.t1, firstError);
          this.validate(tariff.rates.rate2.first.t2, firstError);

          this.validate(tariff.rates.rate3.first.t1, firstError);
          this.validate(tariff.rates.rate3.first.t2, firstError);
          this.validate(tariff.rates.rate3.first.t3, firstError);

          this.validate(tariff.rates.rate1.second.t1, firstError);

          this.validate(tariff.rates.rate2.second.t1, firstError);
          this.validate(tariff.rates.rate2.second.t2, firstError);

          this.validate(tariff.rates.rate3.second.t1, firstError);
          this.validate(tariff.rates.rate3.second.t2, firstError);
          this.validate(tariff.rates.rate3.second.t3, firstError);
        }
      });

      if (firstError.length > 0) {
        const container = document.querySelector(`[role="${firstError[0]}"]`);
        scrollIntoView(container);
        return;
      }

      this.submitError = '';
      this.isWaitingSubmit = true;

      const xml = generateXml.electricityTariffOrder(
        this.documentId,
        getCurrentDate('YYYY-MM-DD'),
        this.year,
        'MSK',
        this.tariff,
        this.regulatoryDocs,
      );

      try {
        await this.documentUpload({ xml: xml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              '',
              '<p>Тарифы будут направлены на проверку сотруднику платформы. В течение 1 дня вам придёт уведомление о результатах проверки.</p>',
            );
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    handleRemoveRegulatoryDocs(file) {
      this.regulatoryDocs = this.regulatoryDocs
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    replace() {
      this.isNewDocs = true;
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.container
  a
    display: inline !important

.title
  padding-bottom: 24px
  +base-title

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto 40px

.submitNote
  +table-data-base
  margin-top: 40px
  margin-bottom: 40px

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 16px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.documentTitle
  +card-title-small

.buttons
  display: flex
  position: relative

  .close
    button
      background: #71757A

  div
    width: 50%

    &.disabled
      background: #DFE1E6

.spoilerHeader
  +questionnaries-container

.reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles
  margin-bottom: 40px

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.reviewDocumentFiles p span.replace
  display: flex
  font-family: 'Roboto Condensed'
  color: #4C4E51
  white-space: nowrap
  cursor: pointer
  font-size: 18px
  line-height: 18px

.reviewComments
  p
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px
    color: #EB5757

</style>
