<template>
  <div :class="$style.container" @click="$emit('click')">
    <div :class="$style.icon">
      <slot name="icon" />
    </div>
    <div :class="$style.title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'FileAction',
  props: {
    title: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    align-items: stretch
    cursor: pointer

  .title
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 18px
    color: #4C4E51
    text-transform: lowercase
    margin: 0 0 0 8px

  .icon
    display: flex

    svg
      width: 24px
      height: 24px

      path
        fill: #4C4E51
</style>
