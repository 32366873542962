<template>
  <div>
    <v-table-new
      v-model="transformedData"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="data.pageOut"
      :template="template"
      excel-report-file-name="Складские операции. Накладные"
      server-side-sorting
      table-name="consignment"
      @on-sort="handleSort"
      @on-cell-click="onCellClick"
    />
    <v-app class="vuetify-styles" data-app style="width: 100%">
      <consignment v-model="consignmentOpened" :consignment="consignment" @updated="loadData"/>
      <consignment-accounting-balances v-model="consignmentAccountingBalancesOpened" :consignment="consignment"/>
    </v-app>
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';
import { mapFilters } from 'lib/utils/table-pagination';
import { BASE_URL } from '@/constants/api';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import {
  accountingBalancesStatusMap,
  consignmentTypesMap,
  consignmentTypesValueList,
} from '../../../components/Processes/list';
import { mapGetters } from 'vuex';
import ConsignmentAccountingBalances from 'components/Processes/Consignment/ConsignmentAccountingBalances.vue';
import { fetchConsignment } from './consignment';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'OdkConsignmentTable',
  components: {
    ConsignmentAccountingBalances,
    Consignment,
    VTableNew,
  },
  data() {
    return {
      data: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      filterEnd: {},
      sort: {
        field: null,
        type: null,
      },
      consignmentOpened: false,
      consignmentAccountingBalancesOpened: false,
      consignment: {},
    };
  },
  computed: {
    transformedData() {
      if (!this.data || !this.data.data || !Array.isArray(this.data.data)) {
        return [];
      }
      return this.data.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Номер',
            key: 'uniqueNumber',
            sort: {},
            filter: { },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Тип',
            key: 'type',
            sort: {},
            filter: { type: 'vSearchSelectValue', values: consignmentTypesValueList },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Дата',
            key: 'date',
            sort: {},
            filter: { type: 'period' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Сотрудник совершивший операцию',
            key: 'performerFullName',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Сотрудник, занесший информацию',
            key: 'fullName',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Склад',
            key: 'warehouseName',
            sort: {},
            filter: { type: 'warehousesOdk' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'АХП',
            key: 'axpName',
            sort: {},
            filter: { type: 'axpOdk' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Мероприятие',
            key: 'eventName',
            sort: {},
            filter: { type: 'eventsOdk' },
            thStyle: {
              minWidth: '100px',
            },
          }, {
            label: 'Программа оформления',
            key: 'adreskaName',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          }, {
            label: 'Подрядчик',
            key: 'contractorName',
            sort: {},
            filter: this.contractorFilter,
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Сотрудник подрядчика',
            key: 'contractorEmployeeName',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Договор',
            key: 'contract',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Редактировалось',
            key: 'edited',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            key: 'showAll',
            type: 'checkbox',
            filter: {
              type: 'checkbox',
              label: 'Показать всё',
            },
            thStyle: {
              minWidth: '83px',
            },
            customCheck: () => true,
          },
          {
            key: 'editButton',
            type: 'edit',
            customCheck: (data) => data.typeId === 'ACCOUNTING_BALANCES',
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),
  },
  methods: {
    async transformFilter() {

      const filterMapping = {
        contractorName: {
          name: 'contractorName',
        },
        performerFullName: {
          name: 'performerFullName',
        },
        fullName: {
          name: 'fullName',
        },
        warehouseName: {
          name: 'warehouseName',
        },
        eventName: {
          name: 'eventName',
        },
        adreskaName: {
          name: 'adreskaName',
        },
        type: {
          name: 'type',
        },
        axpName: {
          name: 'axpName',
        },
        uniqueNumber: {
          name: 'uniqueNumber',
        },
        date: {
          name: 'fromDate',
          mapping: formatDateToOdkTimestamp,
        },
      };

      const filterEndMapping = {
        date: {
          name: 'toDate',
          mapping: formatDateToOdkTimestamp,
        },
        contractorName: {
          name: 'contractorId',
        },
        axpName: {
          name: 'axpId',
        },
        warehouseName: {
          name: 'warehouseId',
        },
        eventName: {
          name: 'eventId',
        },
        type: {
          name: 'typeLabel',
        },
      };

      if (!this.filter.showAll) {
        this.filter.showAll = 'false';
      }

      const mappedFilters = await mapFilters(
        filterMapping,
        this.filter,
        filterEndMapping,
        this.filterEnd,
      );
      if (!this.isBoiler) {
        mappedFilters.contractorId = this.getAccount.supplier.id;
      }

      delete mappedFilters.typeLabel;
      return mappedFilters;
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('consignments', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.data = responseData;
        this.data.pageOut = this.pagination;
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('consignments', 1, this.data.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed);
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter, filterEnd) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      this.filterEnd = { ...filterEnd };
      await this.loadData();
    },
    async onCellClick(cell) {
      switch (cell.key) {
        case 'followLink': {
          await this.openConsignment(cell.row.idField, cell.row.typeId);
          break;
        }
        case 'editButton': {
          this.routeToEdit(cell.row);
          break;
        }
      }
    },
    routeToEdit(data) {
      if (data.typeId === 'ACCOUNTING_BALANCES') {
        return;
      }

      return this.$router.push({
        name: 'cabinet.consignment',
        params: { consignmentId: data.id, type: data.typeId },
      });
    },
    async openConsignment(consignmentId, consignmentType) {
      await this.loadConsignment(consignmentId, consignmentType);
      if (consignmentType === 'ACCOUNTING_BALANCES') {
        this.consignmentAccountingBalancesOpened = true;
      } else {
        this.consignmentOpened = true;
      }
    },
    async loadConsignment(consignmentId, consignmentType) {
      this.loading = true;
      try {
        if (consignmentType !== 'ACCOUNTING_BALANCES') {
          this.consignment = await fetchConsignment(consignmentId, consignmentType);
        } else {
          const response = await fetch(
            `${BASE_URL}/accounting-balances/${consignmentId}/elements`,
          );
          const result = await response.json();
          this.consignment = {
            elements: result.map((it) => ({
              axpName: it.element.axp.name,
              elementName: it.element.name,
              statusName: accountingBalancesStatusMap[it.status],
              warehouseName: it.warehouse?.name,
              contractorName: it.contractorName,
              contractName: it.contract?.contract,
              unitName: it.element.unit.name,
              count: it.count,
            })),
          };
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    transformed(it) {
      return {
        ...it,
        idField: it.id,
        typeId: it.type,
        uniqueNumber: it.uniqueNumber,
        type: consignmentTypesMap[it.type],
        date: formatDateFromOdkTimestamp(it.date),
        performerFullName: it.performerFullName,
        fullName: it.fullName,
        warehouseName: it.warehouseName,
        eventName: it.eventName,
        adreskaName: it.adreskaName,
        contractorName: it.contractorName,
        axpName: it.axpName,
        contract: it.contract,
        contractorEmployeeName: it.contractorEmployeeName,
        previousConsignmentId: it.previousConsignmentId,
        edited: it.edited ? 'Да' : '',
        toEdit: true,
      };
    },
  },
};
</script>

<style scoped>

</style>
