const phonePattern = /(^\+?[87]\d{10}$)|(^\d{10}$)/;

function unformatPhone(phone) {
  return phone.replace(/(\+7)|-| |\(|\)/g, '');
}

function unformatPhonePrefix(phone) {
  return phone.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
}

/* function isPhone(value) {

  const phone = value.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
  return phonePattern.test(phone);
}

function isEmail(value) {
  const emailPattern = /^\S+@\S+\.\S{2,}$/;
  return emailPattern.test(value.trim());
}*/

function phoneFormat(pPhone) {
  const phone = pPhone.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), '');
  const isPhone = phonePattern.test(phone);
  let formattedPhone = '';

  if (isPhone) {
    let firstNumber = 0;
    if (phone[0] === '+') firstNumber = 1;
    else if (phone.length === 10) firstNumber = -1;

    const part4 = phone.substr(9 + firstNumber, 2);
    const part3 = phone.substr(7 + firstNumber, 2);
    const part2 = phone.substr(4 + firstNumber, 3);
    const part1 = phone.substr(1 + firstNumber, 3);
    formattedPhone = '+7 '.concat('(', part1, ') ', part2, '-', part3, '-', part4);
  }

  return { isPhone: isPhone, formattedPhone: formattedPhone };
}

function formattedString(str, start, end) {
  return (`<p>${start}${str}${end}</p>`)
    .replace(RegExp('[\\v\\r\\n\\f]', 'g'), '</p><p>')
    .replace(RegExp('<p>\\s*</p>', 'g'), '');
}

function currencyFormatted(value) {
  return Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(value);
}

function numberFormattedIfNumber(value) {
  const formatted = formatting.numberFormatted(value);
  return formatted !== 'не число' ? formatted : value;
}


function numberFormatted(value) {
  return Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    maximumFractionDigits: 20,
  }).format(value);
}

function formatByThousands(str) {
  let res = '';
  const srcStr = str;

  if (srcStr[0] === '-') {
    str = str.slice(1);
  }

  // separate by thousands begin
  const separate = (str) => {
    const arr = str.split('');
    let index = -3;
    while (arr.length + index > 0) {
      arr.splice(index, 0, ' ');
      index -= 4;
    }
    return arr.join('');
  };
  // separate by thousands end

  res = separate(str);

  if (srcStr[0] === '-') {
    return `-${res}`;
  }

  return res;
}

function formatBoundaryName(boundary) {
  return `${boundary.connections.map((connection) => `${connection.mainSubstation.fullName} ${connection.distributionSubstation?.join(' ') ?? ''}`).join(', ')}(${boundary.consumers?.join(', ') ?? ''})`;
}

const formatting = {
  unformatPhone,
  unformatPhonePrefix,
  phoneFormat,
  formatBoundaryName,
  formattedString,
  currencyFormatted,
  numberFormatted,
  formatByThousands,
  numberFormattedIfNumber,
};

export default formatting;
