<template>
  <div :class="$style.newUserAgreements">
    <div :class="$style.noteContainer">

      <p :class="$style.welcomeText">
        Для использования платформы просим согласиться со следующими документами:
      </p>

      <p v-if="displayPrivacyPolicy" :class="$style.downloadPolicy">
            <span>
              <span :class="$style.spanCheckbox">
                <input
                    v-model="privacyPolicy2"
                    type="checkbox"
                    @change="changePrivacyPolicy2"
                />
                </span>&nbsp;
            <span style="color: red">*</span>
            {{ privacyPolicyTargetTitle.person }} {{ privacyPolicyTargetTitle.action }}
            <a @click="openPersonalDataAgreementModal">
              согласие на обработку {{ privacyPolicyTargetTitle.whose }} персональных данных
            </a>
            </span>
        <a v-if="isLegalEntity" href="/policy-new/general-director-policy.pdf" target="_blank"><span
            :class="$style.downloadButton"><file-download/>Скачать</span></a>
        <a v-else href="/policy-new/user-policy.pdf" target="_blank"><span :class="$style.downloadButton"><file-download/>Скачать</span></a>
      </p>

      <p v-if="displayPrivacyPolicy" :class="$style.downloadPolicy">
            <span>
              <span :class="$style.spanCheckbox">
                <input
                    v-model="privacyPolicy1"
                    type="checkbox"
                    @change="changePrivacyPolicy1"
                />
                </span>&nbsp;
            <span style="color: red">*</span>
            {{ privacyPolicyTargetTitle.person }} ознакомлен(а) с
            <a href="/policy-new/personal-data-processing-policy.pdf" target="_blank">
              политикой обработки персональных данных
            </a>
            </span>
        <a href="/policy-new/personal-data-processing-policy.pdf" target="_blank"><span :class="$style.downloadButton"><file-download/>Скачать</span></a>
      </p>

      <p v-if="displayPrivacyPolicy" :class="$style.downloadPolicy">
            <span>
              <span :class="$style.spanCheckbox">
                <input v-model="privacyPolicy3" type="checkbox" @change="changePrivacyPolicy3"/>
              </span>&nbsp;
            <span style="color: red">*</span>
            {{ privacyPolicyTargetTitle.person }} {{ privacyPolicyTargetTitle.action }} согласие на использование
            <a href="/policy-new/user-agreement.pdf" target="_blank">
              пользовательского соглашения
            </a>
              </span>
        <a href="/policy-new/user-agreement.pdf" target="_blank"><span :class="$style.downloadButton"><file-download/>Скачать</span></a>
      </p>

      <p v-if="displayPrivacyPolicy">
              <span :class="$style.spanCheckbox">
                <input v-model="privacyPolicy4" type="checkbox"/>
              </span>&nbsp;
        <span style="color: #FFF">*</span>
        {{ privacyPolicyTargetTitle.person }} {{ privacyPolicyTargetTitle.action }} согласие на получение рекламной рассылки
      </p>
    </div>

    <template v-if="isPlatformWorker">
      <v-divider :class="$style.divider"/>

      <div :class="$style.noteContainer">

        <p :class="$style.welcomeText">
          Загрузите сканы копии следующих документов:
        </p>

        <p>
          1. Согласия заявителя *
        </p>

        <div v-if="applicantsConsent && applicantsConsent.length">
          <file-list-preview
              :list="applicantsConsent"
              action
              role="applicantsConsent"
              @delete="handleRemoveApplicantsConsent"
          />
        </div>

        <c-uploading
            v-model="applicantsConsent"
            :validation="$validate.isNotEmptyArray"
            role="applicantsConsent"
            :error="{error: false}"
        />

        <p :class="$style.marginTop24">
          2. Документы заявителя *
        </p>

        <div v-if="applicantsDocuments && applicantsDocuments.length">
          <file-list-preview
              :list="applicantsDocuments"
              action
              role="applicantsDocuments"
              @delete="handleRemoveApplicantsDocuments"
          />
        </div>

        <c-uploading
            v-model="applicantsDocuments"
            :validation="$validate.isNotEmptyArray"
            role="applicantsDocuments"
            :error="{error: false}"
        />
      </div>
    </template>

    <personal-data-agreement-dialog
        :open-modal="isModalOpen"
        :user="user"
        :user-type="isLegalEntity
            ? 'GENERAL_DIRECTOR'
            : 'USER'"
        @close-modal="isShowPersonalDataModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FileDownload from 'atoms/icons/file/FileDownload.vue';
import PersonalDataAgreementDialog from 'components/PersonalDataAgreement/PersonalDataAgreementDialog';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import CUploading from 'atoms/common/CUploading';

export default {
  name: 'NewUserAgreements',
  components: {
    FileDownload,
    PersonalDataAgreementDialog,
    FileListPreview,
    CUploading,
  },
  props: {
    displayPrivacyPolicy: {
      type: Boolean,
      default: true,
    },
    isLegalEntity: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    isEmployees: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privacyPolicy1: false,
      privacyPolicy2: false,
      privacyPolicy3: false,
      privacyPolicy4: false,
      isShowPersonalDataModal: false,
      applicantsConsent: [],
      applicantsDocuments: [],
    };
  },
  computed: {
    privacyPolicyTargetTitle() {
      if (this.isPlatformWorker) {
        if (this.isEmployees) {
          return { person: 'Сотрудник', action: 'даёт', whose: 'своих' };
        }

        return { person: 'Заявитель', action: 'даёт', whose: 'своих' };
      }

      return { person: 'Я', action: 'даю', whose: 'моих' };
    },
    isModalOpen() {
      return this.isShowPersonalDataModal;
    },
    agreements() {
      return {
        privacyPolicy1: this.privacyPolicy1,
        privacyPolicy2: this.privacyPolicy2,
        privacyPolicy3: this.privacyPolicy3,
        privacyPolicy4: this.privacyPolicy4,
        applicantsConsent: this.applicantsConsent,
        applicantsDocuments: this.applicantsDocuments,
      };
    },
    ...mapGetters('user', ['isPlatformWorker']),
  },
  watch: {
    agreements: {
      deep: true,
      handler(val) {
        console.log('watch updateAgreements', val);
        this.$emit('updateAgreements', val);
      },
    },
  },
  methods: {
    changePrivacyPolicy1() {
      localStorage.setItem('agreedWithPersonalDataAgreement', this.privacyPolicy1);
    },
    changePrivacyPolicy2() {
      if (this.privacyPolicy2) {
        this.openPersonalDataAgreementModal();
      }
    },
    changePrivacyPolicy3() {
      localStorage.setItem('agreedWithUserAgreement', this.privacyPolicy3);
    },
    openPersonalDataAgreementModal() {
      this.isShowPersonalDataModal = true;
    },
    handleRemoveApplicantsConsent(file) {
      this.applicantsConsent = this.applicantsConsent
          .filter((scan) => scan.source !== file.source);
    },
    handleRemoveApplicantsDocuments(file) {
      this.applicantsDocuments = this.applicantsDocuments
          .filter((scan) => scan.source !== file.source);
    },
  },
};

</script>

<style lang="sass" module>
.newUserAgreements
  .divider
    height: 1px
    margin-bottom: 16px

  .marginTop24
    margin-top: 24px

  .noteContainer
    padding-bottom: 20px

    .downloadPolicy
      width: 100%
      display: inline-flex
      justify-content: space-between
      align-items: center

      .downloadButton
        text-align: right
        display: inline-flex
        align-items: center
        color: #ACB0B5

        svg
          height: 18px
          fill: #ACB0B5

    p
      margin-bottom: 12px
      font-family: Roboto Condensed
      font-size: 18px
      font-weight: 400
      line-height: 22px

      a
        +base-link

    margin: 0

    .welcomeText
      font-size: 18px
      font-weight: 600
      line-height: 22px
      margin-bottom: 28px

  .spanCheckbox
    height: 16px
    display: inline-flex
    vertical-align: middle
    align-item: center
</style>
