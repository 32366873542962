<template>
  <v-row>
    <v-col>
      <v-dialog
        ref="modalDate"
        v-model="modalDate"
        class="v-dialog-top"
        persistent
        width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-row>
            <v-col>
              <div>
                <div>
                  <v-label>{{ internalTitle }}</v-label>
                </div>
                <v-text-field
                  ref="dateText"
                  :disabled="disabled"
                  :rules="[rules.requiredForNull]"
                  :value="dateText"
                  dense
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  required
                  v-bind="attrs"
                  v-on="on"

                ></v-text-field>
              </div>
            </v-col>
            <div style="gap: 6px; display: flex; align-items: center; margin-bottom: 8px;">
              <slot class="align-center" name="buttons"/>
            </div>
          </v-row>
        </template>
        <v-date-picker v-model="date" :allowed-dates="allowedDates" required>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel">
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="save"
          >
            Принять
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>


import moment from 'moment';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';

export default {
  model: {
    prop: 'value',
    event: 'save',
  },
  props: {
    label: {
      default: '',
    },
    value: {
      type: Number,
      required: false,
    },
    isRequired: { type: Boolean, default: false },
    allowedDates: {
      required: false,
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modalDate: false,
      internalValue: null,
      rules: {
        requiredForNull: (value) => (this.isRequired ? !!value || 'Поле обязательное' : true),
      },
    };
  },
  computed: {
    internalTitle() {
      if (!this.label) return;
      if (this.isRequired) return `${this.label}*`;
      return this.label;
    },
    dateText() {
      if (this.internalValue !== null && this.internalValue !== undefined) {
        return formatDateFromOdkTimestamp(this.internalValue);
      }
      return '';
    },
    date: {
      get() {
        return this.internalValue !== null && this.internalValue !== undefined ? moment(formatDateFromOdkTimestamp(this.internalValue), 'DD.MM.YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      },
      set(value) {
        this.internalValue = formatDateToOdkTimestamp(moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY'));
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
  },
  methods: {
    resetValidation() {
      this.$refs.dateText.resetValidation();
    },
    save() {
      this.modalDate = false;
      this.$emit('save', this.internalValue);
    },
    cancel() {
      this.modalDate = false;
      this.internalValue = this.value;
    },
  },
};
</script>
