<template>
  <dialog-template v-model="visible" :height100="!isLoading" collapse>
    <v-dialog-header
      :title="title"
      :subtitle="subtitle"
      :is-loading="isLoading"
      :is-min-height="true"
      :close-button-margin="true"
    />
    <div v-if="!isLoading" :class="$style.container">
      <iframe :src="dataUrl"></iframe>
    </div>
  </dialog-template>
</template>

<script>
import DialogTemplate from 'templates/DialogTemplate';
import VDialogHeader from 'components/VDialog/VDialogHeader';

export default {
  name: 'Details',
  components: {
    VDialogHeader,
    DialogTemplate,
  },
  props: {
    value: { type: Boolean, default: false },
    dataUrl: String,
    title: String,
    subtitle: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="sass" module>
.container
  height: 100%

.container iframe
  height: 100%
  width: 100%

.spoilerTitle
  width: 100%
  max-width: 560px
  margin: 0 auto
  padding: 0 0 16px
</style>
