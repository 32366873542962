<template>
  <div class="inner-page">
    <div class="inner-page__header">
      <button
        v-if="displayBack"
        class="btn-back"
        @click="back"
      >
        Назад
      </button>
      <navigation-bar :current-step="currentStep"
                      :home="home"
                      :steps="innerSteps"
                      class="inner-page__navigation"
                      @open="openStep" />
    </div>
    <v-divider />
    <div class="inner-page__content">
      <div class="inner-page__data" :style="{ width: dataWidth }">
        <slot />

        <div class="inner-page__buttons">
          <slot name="buttons" />
        </div>
      </div>

      <div v-if="history.length > 0" class="inner-page__history">
        <history :can-be-collapsed="canHistoryBeCollapsed" :history="history" :spacing="historySpacing"
                 :title="historyTitle" />
      </div>
    </div>

  </div>
</template>

<script>
import VDivider from 'components/VDivider/VDivider';
import History from 'components/History/History';
import NavigationBar from 'components/NavigationBar/NavigationBar';

export default {
  name: 'InnerPage',
  components: {
    VDivider,
    NavigationBar,
    History,
  },
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    home: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    history: {
      type: Array,
      default: () => [],
    },
    historyTitle: {
      type: String,
      required: false,
      default: 'История документа',
    },
    historySpacing: {
      type: Number,
      default: 1,
    },
    canHistoryBeCollapsed: {
      type: Boolean,
      default: true,
    },
    displayBack: {
      type: Boolean,
      default: true,
    },
    dataWidth: {
      type: String,
      default: '848px',
    },
  },
  computed: {
    innerSteps() {
      return this.steps.map((item, index) => ({
          stepNumber: {
            value: index + 1,
            title: () => item.label ?? item,
          },
        }));
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
    openStep(index) {
      if (index === -1) {
        this.$emit('goHome', index);
        return;
      }

      this.$emit('openStep', index);
    },
    cancel() {
      this.$emit('cancel');
    },
    print() {
      this.$emit('print');
    },
    save() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-page {
  margin: 8px 32px 56px 0;

  &__header {
    margin: 0 0 8px 12px;
    display: flex;
  }

  &__content {
    display: flex;
    align-items: self-start;
    margin: 25px 32px 0 116px;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  &__data {
    width: 848px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__history {
    max-width: 450px;
    @media (max-width: 1400px) {
      width: 848px;
    }
  }

  .btn-back {
    width: 86px;
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #677379;
    padding-left: 12px;

    background-image: url('~icons/navigation/arrow_left.svg');
    background-repeat: no-repeat;
    background-position: center left 4px;
    background-color: #FFFFFF;
    border: 1px solid #C1C4C7;
    border-radius: 24px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  &__navigation {
    margin: 0 0 0 26px;
  }
}
</style>
