import { apolloClient } from '@/api/graphql';
import {
  GET_USAGE_POINT,
  GET_USAGE_POINTS_FOR_INDIVIDUAL,
  GET_USAGE_POINTS_FOR_INDIVIDUAL_WITH_MEASUREMENTS,
  METER_CONSUMPTIONS_BY_FILTER,
  POWER_PROFILES_BY_METER_ID,
} from '@/api/graphql/queries';

async function loadUsagePointsIndividual({ rootState, commit }) {
  try {
    const { data: { allUsagePointsForIndividual } } = await apolloClient.query({
      query: GET_USAGE_POINTS_FOR_INDIVIDUAL,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('load Usage Points', allUsagePointsForIndividual);
    return allUsagePointsForIndividual;
  } catch (error) {
    console.log('load Usage Points::catch', error);
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    throw error;
  }
}

async function loadUsagePointsIndividualWithMeasurements({ rootState, commit }, payload) {
  try {
    const { data: { allUsagePointsForIndividual } } = await apolloClient.query({
      query: GET_USAGE_POINTS_FOR_INDIVIDUAL_WITH_MEASUREMENTS,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('load Usage Points', allUsagePointsForIndividual);
    return allUsagePointsForIndividual;
  } catch (error) {
    console.log('load Usage Points::catch', error);
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    throw error;
  }
}

async function loadUsagePoint({ rootState, commit }, id) {
  try {
    const { data: { usagePoint } } = await apolloClient.query({
      query: GET_USAGE_POINT,
      variables: { id: id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('load Usage Point', usagePoint);
    return usagePoint;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('load Usage Point::catch', error);
    throw error;
  }
}

async function meterConsumptionsByFilter({ rootState, commit }, payload) {
  try {
    const { data: { meterConsumptionsByFilter } } = await apolloClient.query({
      query: METER_CONSUMPTIONS_BY_FILTER,
      variables: {
        filter: {
          ...payload,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('load meterConsumptionsByFilter', payload.from, payload.to, meterConsumptionsByFilter);
    return meterConsumptionsByFilter;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('meterConsumptionsByFilter::catch', error);
    throw error;
  }
}

async function powerProfilesByMeterId({ rootState, commit }, meterId) {
  try {
    const { data: { powerProfilesByMeterId } } = await apolloClient.query({
      query: POWER_PROFILES_BY_METER_ID,
      variables: { meterId: meterId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return powerProfilesByMeterId;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('powerProfilesByMeterId::catch', error);
    throw error;
  }
}

export default {
  loadUsagePointsIndividual,
  meterConsumptionsByFilter,
  loadUsagePoint,
  powerProfilesByMeterId,
  loadUsagePointsIndividualWithMeasurements,
};
