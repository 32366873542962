<template>
  <div>
    <create-or-upload-contract-form
      style="margin: 24px 0 20px 100px;"
    />
  </div>
</template>

<script>
import CreateOrUploadContractForm from './CreateOrUploadContractForm/Form.vue';

export default {
  name: 'SelectType',
  components: {
    CreateOrUploadContractForm,
  },
  props: {
    contractData: Object,
  },
};
</script>

<style lang="scss" scoped>
</style>
