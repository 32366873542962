const MINOR_ERROR_TEXT = 'Сервер не отвечает, ведутся работы.';
const FATAL_ERROR_TEXT = 'Мы устраняем неполадки, скоро всё заработает.';

export const CODE = {
  NOT_FOUND: 404,
  PERMISSION_DENIED: 403,
};

export const networkError = (error) => {
  console.error(`${error.name} - ${error.message} ${error.code}`);

  let code = CODE.NOT_FOUND;
  let message = MINOR_ERROR_TEXT;

  if (error.graphQLErrors && error.graphQLErrors[0]?.extensions?.errorType === 'PERMISSION_DENIED') {
    message = 'Доступ запрещён';
    code = CODE.PERMISSION_DENIED;

    return {
      code,
      message,
    };
  }

  if (error.response) {
    switch (error.response.status) {
      case 500: {
        code = 500;
        message = FATAL_ERROR_TEXT;
        break;
      }
    }
  }

  if (error.message.indexOf('You have no permission to do this action') !== -1) {
    message = 'У Вас недостаточно прав для данного действия. Для получения прав обратитесь к ответственному сотруднику';
  } else if (error.message.indexOf('You have already load existing three side contract') !== -1) {
    message = 'Вы уже загрузили существующий трехсторонний контракт';
  }

  const result = error.message.match(/[а-яА-ЯЁё0-9%.,:-]/);
  const resultData = typeof error.response?.data === 'string' && error.response?.data?.match(/[а-яА-ЯЁё0-9%.,:-]/);

  if (result && result.index !== -1) {
    message = error.message.substr(result.index, error.message.length);
  }

  if (resultData && resultData.index !== -1) {
    message = error.response.data.substr(resultData.index, error.response.data.length);
  }

  return {
    code,
    message,
  };
};


export default {
  network: networkError,
  code: CODE,
};
