<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.compact]: isCompact
    }"
    :style="generalRowStyle"
  >
    <div v-for="(group, indexGroup) in items.groups" :key="indexGroup" :class="$style.linkGroup">
      <item-menu
        :block-access="blockAccess"
        :counter="group.route === 'tasks' ? getTasksCounter : 0"
        :icon-component="group.iconComponent"
        :index-group="indexGroup"
        :is-compact="isCompact"
        :is-open="openIndex === indexGroup"
        :items="group.items"
        :route="group.route"
        :title="group.title"
        :params="group.params"
        @openGroup="openGroup"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ItemMenu from './ItemMenu';
import { getItemsByAccountKind } from './configItems';

export default {
  name: 'LeftMenu',
  components: {
    ItemMenu,
  },
  props: {
    isCompact: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.loadDirectories();
  },
  data() {
    return {
      openIndex: '',
      directories: [],
    };
  },
  computed: {
    blockAccess() {
      return this.getAuthorization?.blockAccess ?? { pay: false, documents: false };
    },
    items() {
      const itemsByAccountKind = getItemsByAccountKind(this.getAccount?.kind);
      const nsiGroup = itemsByAccountKind.groups.filter((item) => item.title === 'Нормативно-справочная информация');

      if (!nsiGroup || nsiGroup.length === 0 || !this.directories || this.directories.length === 0) {
        return itemsByAccountKind;
      }

      const notDirectoriesItems = nsiGroup[0].items.filter((item) => item.route !== 'directory');

      nsiGroup[0].items = [...notDirectoriesItems, ...this.directories];

      return itemsByAccountKind;
    },
    generalRowStyle() {
      if (this.browserProperties.isFirefox && this.isCompact) {
        return { width: '77px' };
      }
      return {};
    },
    ...mapGetters('user', ['getAuthorization', 'getAccount']),
    ...mapGetters('cabinet', ['getTasksCounter']),
    ...mapGetters('environment', ['browserProperties']),
  },
  watch: {
    isCompact() {
      const tableContainerScrollable = document.getElementsByClassName('table-container-scrollable');

      if (!tableContainerScrollable || tableContainerScrollable.length === 0) {
        return;
      }

      tableContainerScrollable.forEach((it) => {
        if (this.isCompact) {
          it.style.width = 'calc(100vw - 120px)';
        } else {
          it.style.width = 'calc(100vw - 370px)';
        }
      });
    },
    '$store.state.cabinet.menuKeyUpdate'() {
      this.loadDirectories();
    },
  },
  methods: {
    openGroup(index) {
      this.openIndex = this.openIndex === index ? '' : index;
    },
    async loadDirectories() {
      // TODO: hardcode max custom directories in menu
      const directories = await this.getGuideList({
        filter: { kind: 'LOCAL_CLASSIFIER' },
        pageIn: {
          currentPage: 1,
          pageSize: 50,
        },
      });

      if (!directories?.list) {
        return;
      }

      this.directories = directories.list.map((item) => ({
        title: item.name,
        route: 'directory',
        iconComponent: 'Contacts',
        params: { id: item.id },
      }));
    },
    ...mapActions('dataLists', ['getGuideList']),
  },
};
</script>

<style lang="sass" module>

.container
  min-width: 270px
  width: 270px
  color: #4C4E51
  margin-top: 8px

  a
    &:last-child
      margin-bottom: 0

.compact
  width: 64px
</style>
