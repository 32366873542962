<template>
  <Process/>
</template>

<script>
import Process from 'components/Processes/Assignment/Assignment.vue';

export default {
  name: 'ObjectsForRegularInspection',
  components: { Process },
};
</script>

<style scoped>

</style>
