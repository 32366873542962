<template>
  <utm-installation-details :task="mokeTask" @close="$router.push('settings')"/>
</template>

<script>
import UtmInstallationDetails from 'organisms/tasks/utm-installation/Details';

export default {
  components: {
    UtmInstallationDetails,
  },
  data() {
    return {
      mokeTask: {
        date: '27.01.2023',
        stage: 'SUBMITTED',
        completed: false,
        utmDownloadUrl: 'https://gitlab.energochain.ru/platform/utm/-/jobs/25062/artifacts/download?file_type=archive',
        userType: 'Supplier',
        workflowType: 'utmInstallation',
        workflowId: '198def63-4f29-42d3-822a-98e20515b5fd',
      },
    };
  },
};
</script>
