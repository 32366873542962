<template>
  <div class="input-tooltip" v-html="text" />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-tooltip {
  background-color: #F5F6F6;
  padding: 10px 10px 10px 24px;
  border-radius: 16px;

  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
