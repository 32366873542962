<template>
  <div class="table-cell-input">
    <v-select v-model="internalValue" :options="options" :rules="validationRules"
              :rules-messages="validationMessages"
              class="table-cell-input__input"
              new-style style="width: 100%;"/>
  </div>
</template>

<script>
import VSelect from 'components/VSelect/VSelect.vue';

export default {
  name: 'VTableCellSelect',
  components: { VSelect },
  props: {
    value: { type: String, default: '' },
    options: {
      type: [], default: () => [],
    },
    validationRules: { type: String, default: '' },
    validationMessages: {
      type: Object, default: () => {
      },
    },
    customCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};
</script>

<style lang="scss">
.table-cell-input {
  display: flex;
}
</style>
