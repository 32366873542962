<template>
  <div :class="$style.container">
    <div
      :class="{
        [$style.progress]: true,
        [$style.progress92]: history.length < 7,
        [$style.progress96]: history.length >= 7,
      }"
    />
    <div v-for="(it, index) in history" :key="index" :class="$style.list">
      <img
        v-if="index === history.length - 1"
        :class="{ [$style.point]: true, [$style['point__current']]: true }"
        alt=""
        src="~@/assets/icons/basic/step_current_ellipse.svg"
      />
      <img
        v-else
        :class="$style.point"
        alt=""
        src="~@/assets/icons/basic/step_ellipse.svg"
      />

      <span>{{ `${convertDbDateTimeFormat(it.datetime)}. Документ ${convertAction(it.action)}` }} </span>
      <span v-if="it.role">
        {{
          it.role.name
        }}
      </span>
      <span v-if="it.account?.profile?.name">{{ ` ${it.account.profile.name}` }}</span>
      <span v-if="it.user">
        {{
          it.user?.fullName
            ? getFio(it.user?.fullName)
            : ''
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { convertDbDateTimeFormat } from 'lib/utils/date';
import { convertAction } from 'lib/utils/formatXmlType';

export default {
  name: 'DocumentHistory',
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
  methods: {
    convertDbDateTimeFormat(date) {
      return convertDbDateTimeFormat(date);
    },
    convertAction(action) {
      return convertAction(action);
    },
    getFio(fullName) {
      if (fullName && fullName.name) {
        let patronymic = '';
        if (fullName.patronymic) {
          patronymic = ` ${fullName.patronymic}`;
        }
        return `${fullName.surname} ${fullName.name}${patronymic}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px
}

.list {
  padding: 0 0 0 25px
}

.progress {
  width: 2px;
  position: absolute;
  top: 8px;
  left: 4px;
  background: #C1C4C7;
}

.progress92 {
  height: 92%;
}

.progress96 {
  height: 96%;
}

.point {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 1px;
  margin: 4px 0 0;

  &__current {
    width: 14px;
    height: 14px;
    position: absolute;
    left: -2px;
  }
}
</style>
