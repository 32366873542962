<template>
  <div class="table-cell-status">
    <div>{{ labelByStatus }}</div>
    <div
      :style="`background-color: ${colorByStatus}`"
      class="table-cell-status__circle"
    ></div>
  </div>
</template>

<script>
const statusMap = {
  NEW: { label: 'Новая', color: '#FF7400' },
  SETTLED: { label: 'Урегулирована', color: '#4CCB40' },
  UNSETTLED: { label: 'Неурегулирована', color: '#EB5757' },
  ARCHIVED: { label: 'В архиве', color: '#EB5757' },
  IN_APPLICATION: { label: 'В заявке на ТП', color: '#EB5757' },
  CONNECTED: { label: 'Прошла ТП', color: '#4CCB40' },
  EXCLUDED: { label: 'Удалена', color: '#DFE1E6' },
  ACTIVE: { label: 'Активная', color: '#4CCB40' },
  WAITING_SUPPLY_CONTRACT: { label: 'Ожидает заключение ДЭС', color: '#EB5757' },
  WAITING_ADDITION_APPROVE: { label: 'Ожидает согласования ДС', color: '#EB5757' },

  // Договор
  DRAFT: { label: 'На согласовании', color: '#3CB6EA' },
  APPROVED_BY_INITIATOR: { label: 'Согласован работником инициатора', color: '#4CCB40' },
  SIGNED_BY_INITIATOR: { label: 'Подписан со стороны инициатора', color: '#2F82DF' },
  REJECTED: { label: 'Отклонён', color: '#EB5757' },
  CANCELED: { label: 'Отменён', color: '#EB5757' },
  FORMED: { label: 'Сформирован', color: '#3CB6EA' },
  APPROVED: { label: 'На подписании', color: '#4CCB40' },
  DELETED: { label: 'Удалён', color: '#EB5757' },
  NULLIFIED: { label: 'Аннулирован', color: '#EB5757' },
  SIGNED: { label: 'Подписан', color: '#2F82DF' },
  COMPLETED: { label: 'Завершён', color: '#DFE1E6' },
  COMPANY_NOT_FOUND: { label: 'Компания не имеет аккаунта', color: '#DFE1E6' },
  COMPANY_NOT_ON_PLATFORM: { label: 'Компания не имеет аккаунта', color: '#DFE1E6' },
  WAITING_VERIFICATION_ACT: { label: 'Ожидает формирования акта верификации', color: '#DFE1E6' },
  WAITING_UPDATE_INFO: { label: 'Ожидает ввода информации', color: '#DFE1E6' },
  NOT_SEND: { label: 'Не отправлен', color: '#DFE1E6' },
  DISAGREEMENT: { label: 'Разногласия', color: '#EB5757' },
};

export default {
  name: 'VTableCellStatus',
  props: {
    value: { type: String, default: '' },
  },
  computed: {
    labelByStatus() {
      return statusMap[this.value].label;
    },
    colorByStatus() {
      return statusMap[this.value].color;
    },
  },
};
</script>

<style lang="scss">
.table-cell-status {
  display: flex;
  align-items: center;

  &__circle {
    min-width: 8px;
    min-height: 8px;
    border-radius: 24px;
    margin-left: 8px;
  }
}
</style>
