<template>
  <div :class="{
      [$style.container]: true,
      [$style.minHeight]: isMinHeight
    }">
    <div :class="$style.content">
      <h1>{{ title }}</h1>
      <h1>{{ title2 }}</h1>
      <h2>{{ subtitle }}</h2>
    </div>
    <i :class="$style.iconClose" @click="$emit('close')"></i>
    <horizontal-progress-bar v-if="isLoading" />
  </div>
</template>

<script>
export default {
  name: 'ScrollingHeader',
  components: {
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    title2: {
      type: String,
      required: false,
      default: undefined,
    },
    subtitle: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMinHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" module>
  .container
    position: fixed
    width: 700px
    background-color: #fff
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5)
    z-index: 1004

  .minHeight
    min-height: 118px

  .content
    +questionnaries-container
    padding: 8px 0 16px

    h1
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 32px
      line-height: 36px
      color: #000

    h2
      margin: 16px 0 0 0
      font-family: Roboto
      font-style: normal
      font-weight: normal
      font-size: 16px
      line-height: 20px
      color: #71757A

  .iconClose
    width: 18px
    height: 18px
    display: block
    position: absolute
    top: 14px
    right: 14px
    background-image: url('~icons/basic/close.svg')
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    cursor: pointer
</style>
