<template>
  <div>
    <select-button
      :items="actionBlockItems"
    />
  </div>
</template>

<script>
import { SelectButton } from '@/components';

export default {
  name: 'ActionBlock',
  components: { SelectButton },
  data() {
    return {
      actionBlockItems: [
        {
          id: 1,
          title: 'Создать новый шаблон',
          method: () => {},
        },
      ],
    };
  },
};
</script>
