<template>
  <div ref="scrollContainer" class="menu" :style="customStyle" @scroll="onScroll">
    <div
      v-for="option in options"
      :key="option.value"
      :class="{
        ['menu__item']: true,
        ['menu__item--no-wrap']: noWrapItem,
        ['menu__item--action']: option.isAction,
        ['menu__item--missing-data']: option.value === 'missing-data',
      }"
      @click="(event) => click(event, option)"
    >
      <Highlighter
        :auto-escape="true"
        :search-words="keywords"
        :text-to-highlight="option.label"
        highlight-class-name="highlight"
      />
    </div>
  </div>
</template>

<script>
import Highlighter from 'vue-highlight-words';

export default {
  name: 'VMenu',
  components: {
    Highlighter,
  },
  props: {
    options: { type: Array, required: true },
    value: { type: String, default: '' },
    noWrapItem: { type: Boolean, default: false },
    stopPropagation: { type: Boolean, default: false },
    maxHeight: { type: String, required: false },
  },
  computed: {
    keywords() {
      return this.value?.length > 0 ? this.value.split(' ') : [];
    },
    customStyle() {
      if (this.maxHeight) {
        return `maxHeight: ${this.maxHeight}px`;
      }

      return '';
    },
  },
  methods: {
    click(event, option) {
      if (this.stopPropagation) {
        event.stopPropagation();
      }
      this.$emit('click', option.value);
    },
    onScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      if (Math.abs(scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight) < 2) {
        this.$emit('scrolledToEnd');
      }
    },
  },
};
</script>

<style lang="scss">
.highlight {
  font-weight: 600;
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
.menu {
  background-color: #ffffff;
  width: 100%;
  position: absolute;
  border: 1px solid #dee2e6;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 240px;
  overflow: auto;
  z-index: 999;

  &__item {
    display: flex;
    align-items: center;
    padding: 12px 10px 12px 10px;
    cursor: pointer;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #dee2e6;
    }

    &--no-wrap {
      white-space: nowrap;
    }

    &--action {
      background-color: #2f82df;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        background-color: #2f82df;
      }
    }

    &--missing-data {
      cursor: default;
      font-size: 12px;

      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
</style>
