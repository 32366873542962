import { convertAccountKindToXml } from 'lib/utils/formatXmlType';

const generateAccountKinds = (kinds) => {
  let result = '            <AccountKinds>\n';
  kinds.forEach((kind) => {
    result += `                <AccountKind>${convertAccountKindToXml(kind)}</AccountKind>\n`;
  });
  result += '            </AccountKinds>\n';
  return result;
};

export function generateFunctionalBlockOrder(data) {

  return `${'' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <CreateFunctionalBlock>\n' +
    `            <Code>${data.code}</Code>\n` +
    `            <Description>${data.description}</Description>\n`}${
    generateAccountKinds(data.accountKinds)
    }        </CreateFunctionalBlock>\n` +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}

export function generatePermissionOrder(data) {
  return '' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <CreatePermission>\n' +
    `            <Code>${data.code}</Code>\n` +
    `            <Description>${data.description}</Description>\n` +
    `            <UsingOnAttorney>${data.usingOnAttorney}</UsingOnAttorney>\n` +
    '        </CreatePermission>\n' +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}

export function generateEditFunctionalBlockOrder(data) {

  return `${'' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <EditFunctionalBlock>\n' +
    `            <OldCode>${data.oldCode}</OldCode>\n` +
    `            <Code>${data.code}</Code>\n` +
    `            <Description>${data.description}</Description>\n`}${
    generateAccountKinds(data.accountKinds)
    }        </EditFunctionalBlock>\n` +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}

export function generateEditPermissionOrder(data) {
  return '' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <EditPermission>\n' +
    `            <OldCode>${data.oldCode}</OldCode>\n` +
    `            <Code>${data.code}</Code>\n` +
    `            <Description>${data.description}</Description>\n` +
    `            <UsingOnAttorney>${data.usingOnAttorney}</UsingOnAttorney>\n` +
    '        </EditPermission>\n' +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}


export function generateDeleteFunctionalBlockOrder(data) {

  return '' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <DeleteFunctionalBlock>\n' +
    `            <Code>${data.code}</Code>\n` +
    '        </DeleteFunctionalBlock>\n' +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}

export function generateDeletePermissionOrder(data) {
  return '' +
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PermissionRegistryChanges\n' +
    '        xmlns="http://energochain.ru/XML/documents/permission-registry-changes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/permission-registry-changes/1.0 PermissionRegistryChanges.xsd">\n' +
    '    <PermissionRegistryChangeList>\n' +
    '        <DeletePermission>\n' +
    `            <Code>${data.code}</Code>\n` +
    '        </DeletePermission>\n' +
    '    </PermissionRegistryChangeList>\n' +
    '</PermissionRegistryChanges>\n';
}
