var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"substation-node",style:(_vm.styleNode)},[(!_vm.node.isEmpty)?[_c('div',{class:{
                ['substation-node-top']: true,
                ['substation-node-top--selected']: _vm.selected === 'top'
              },attrs:{"id":'node-top-' + _vm.node.id},on:{"click":function($event){return _vm.clickNode(_vm.node, 'top')}}},[(_vm.node.type === 'usagePoint')?_c('contact-page',{staticClass:"substation-node-top-icon-left"}):_c('substation-icon',{staticClass:"substation-node-top-icon-left"}),_c('span',[_vm._v(_vm._s(_vm.node.name)+" ")]),_c('info-gray',{staticClass:"substation-node-top-icon-right"})],1),_c('div',{class:{
                ['substation-node-bottom']: true,
                ['substation-node-bottom--empty']: !_vm.node.address,
              },attrs:{"id":'node-bottom-' + _vm.node.id},on:{"click":function($event){return _vm.clickNode(_vm.node, 'bottom')}}},[(_vm.node.address)?_c('span',[_vm._v(_vm._s(_vm.node.address))]):_vm._e()])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }