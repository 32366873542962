<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">{{ getPatternName }}</p>
    <p :class="$style.stepTitle">Внесение данных из приложений к {{ contractType }}</p>

    <v-divider/>

    <div style="display: flex; justify-content: space-between; padding: 0 9px">
      <p :class="$style.subTitle" style="margin: auto 0">Шаблоны приложений</p>
      <button :class="{[$style.button]: true, [$style.grey]: true}"
              @click.prevent="isDisplayCloseAll = !isDisplayCloseAll">
          <span :class="$style.inner">
            <img v-show="isDisplayCloseAll" alt="свернуть" src="@/assets/icons/arrows/arrow-up.svg">
            <img v-show="!isDisplayCloseAll" alt="развернуть" src="@/assets/icons/arrows/arrow-down.svg">
            <span :class="$style.text">
              <span v-show="isDisplayCloseAll">Свернуть</span><span v-show="!isDisplayCloseAll">Развернуть</span>&nbsp;всё
            </span>
          </span>
      </button>
    </div>

    <div v-show="isDisplayCloseAll" :class="$style.attachmentsList">
      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 1</span>
          <a :class="$style.fileAttachment" :download="attachment1Name" :href="`${attachmentPath}attachment_1.xlsx`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment1Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSX']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_1.xlsx`, attachment1Name)"
        />
      </div>

      <v-divider/>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 2</span>
          <a :class="$style.fileAttachment" :download="attachment1Name" :href="`${attachmentPath}attachment_2.xlsx`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment2Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSX']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_2.xlsx`, attachment2Name)"
        />
      </div>

      <v-divider/>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 3</span>
          <a :class="$style.fileAttachment" :download="attachment1Name" :href="`${attachmentPath}attachment_3.xlsx`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment3Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSX']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_3.xlsx`, attachment3Name)"
        />
      </div>
    </div>

    <v-divider/>

    <p :class="$style.subTitle" style="padding: 0 9px">Точки поставки, передачи и потребителей ЭСО</p>

    <div style="padding: 0 9px">
      <p
        :class="$style.uploadingTitle"
        :style="{'color': getErrors.filledAttachment1.isError ? 'red': ''}"
      >
        Загрузка Приложений: Приложения 1, 2, 3
      </p>
      <c-uploading
        v-model="filledAttachment1"
        :class="$style.filledDocuments"
        :error="{ error: getErrors.filledAttachment1.isError}"
        accept=".xlsx, .xls"
        accept-title="XLSX, XLS или перетащите его в эту область."
        role="filledAttachment1"
      />
    </div>

    <v-divider/>

    <div v-if="getContract.attachments.length > 0" :class="$style.attachmentsList">
      <div v-for="(attachment, index) in nonDepartmentApplications" :key="index" :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span>{{ formattedFileName(attachment.file.name) }}</span>
          <a :class="$style.fileAttachment" :download="attachment.file.name"
             :href="fileUrl(attachment.file)">
            <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
            <span>{{ attachment.file.name }}</span>
          </a>
        </div>
        <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
           @click="deleteDoc(attachment)"
        >
          <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
        </a>
      </div>
      <dialog-template v-model="visible" center>
        <p :class="$style.searchNote">Загрузка файлов. Подождите немного.</p>
        <div :class="$style.modalContainer">
          <progress-bar
            :fake-time-ms="25"
            :is-complete="true"
            text="Загрузка"
            @is-hundred-percent="downloadFinished"
          />
        </div>
      </dialog-template>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import { EVENT_BUS } from '@/eventBus';
import { excelParseWithWorker } from 'utils/excel';
import { nanoid } from 'nanoid';
import { mapActions, mapGetters } from 'vuex';
import { normalizeDiacritics } from 'utils/string/normalize';
import { oekTso1Pattern, oekTso2Pattern, oekTso3Pattern } from 'views/private/UploadDocument/patterns/EsoTso';
import VDivider from 'components/VDivider/VDivider.vue';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

const attachment1NameWithoutExtension = 'Приложение_1-Реестр документов о технологическом присоединении по точкам приема';
const attachment2NameWithoutExtension = 'Приложение_2-Точки поставки электрической энергии из сети Исполнителя';
const attachment3NameWithoutExtension = 'Приложение_3-Реестр документов о технологическом присоединении по точкам поставки';

export default {
  name: 'EsoTso',
  components: {
    ProgressBar,
    DocumentDownloader,
    VDivider,
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  data() {
    return {
      visible: false,
      filledAttachment1: [],
      isWaitingSubmit: false,
      departmentId: -1,
      departmentList: [],
      attachment1NameWithoutExtension: attachment1NameWithoutExtension,
      attachment1Name: `${attachment1NameWithoutExtension}.xlsx`,
      attachment2NameWithoutExtension: attachment2NameWithoutExtension,
      attachment2Name: `${attachment2NameWithoutExtension}.xlsx`,
      attachment3NameWithoutExtension: attachment3NameWithoutExtension,
      attachment3Name: `${attachment3NameWithoutExtension}.xlsx`,
      isDisplayCloseAll: true,
    };
  },
  computed: {
    attachmentPath() {
      if (this.getContract.action === 'upload') {
        return '/xlsx/contract-patterns/existing/oek-tso/';
      }

      return '/xlsx/contract-patterns/new/oek-tso/';
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договору';
      }
      return 'доп.соглашению';

    },
    nonDepartmentApplications() {
      return this.getContract.attachments.filter((attach) => !attach.departmentData);
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getErrors', 'getPatternName']),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }

        this.isWaitingSubmit = true;
        this.visible = this.isWaitingSubmit;
        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment1NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...oekTso1Pattern },
              taskId,
            );
            await this.setAttachments({
              attachment: {
                name: 'ElectricityUsagePointsConnectionInfoAddition',
                kind: 'receiving',
                value: res.data,
                errors: res.errors,
                file,
                number: 1,
              },
            });
          } else if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment2NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...oekTso2Pattern },
              taskId,
            );
            await this.setAttachments({
              attachment: {
                name: 'ElectricityDeliveryPointsTsoAddition',
                kind: 'transfer',
                value: res.data,
                errors: res.errors,
                file,
                number: 2,
              },
            });
          } else if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment3NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...oekTso3Pattern },
              taskId,
            );
            await this.setAttachments({
              attachment: {
                name: 'ElectricityDeliveryPointsConnectionInfoAddition',
                value: res.data,
                errors: res.errors,
                file,
                number: 3,
              },
            });
          } else {
            incorrectFiles.push(file.name);
          }
        }

        if (incorrectFiles.length > 0) {
          this.visible = false;
          this.onShownNotification(incorrectFiles, `${this.attachment1Name}<br/>${this.attachment11Name}<br/>${this.attachment31Name}`);
        }
        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    downloadFinished() {
      setTimeout(() => {
        this.visible = false;
      }, 150);
    },
    formattedFileName(name) {
      const names = ['Приложение_1', 'Приложение_2', 'Приложение_3'];
      for (let i = 0; i < names.length; i++) {
        if (name.includes(names[i])) return names[i].replace('_', ' ');
      }
      return '';
    },
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    onShownNotification(files, attachments) {
      EVENT_BUS.$emit('NOTIFICATION_LISTENER', {
        data: {
          title: '',
          message: `<p><b>Имя файла:</b></p><p>${files}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${attachments}</p>`,
        },
        type: 'ALERT',
        width: 700,
      });
    },
    deleteDoc(attachment) {
      this.deleteAttachment({ attachment });
    },
    ...mapActions('electricityTransmissionContract', ['setAttachments', 'deleteAttachment']),
    ...mapActions('dataLists', ['mesDepartments']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 800px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 22px
    font-weight: 500
    line-height: 26px
    letter-spacing: 0
    color: #4C4E51

    span
      margin-right: 12px
      color: #979CA2

  .stepHeader
    +base-title
    margin-top: 58px
    padding: 8px
    background-color: #9e9fa2
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .smallTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .subTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 18px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    margin-bottom: 16px
    border-color: #C1C4C7

.uploadingTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 16px
  font-weight: 500
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51

.modalContainer
  padding: 0 40px 40px

.attachmentsList
  display: flex
  flex-direction: column
  gap: 32px
  padding: 0 9px

.attachment, .fileArchive, .attachmentReport
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  text-align: left
  color: #4C4E51

.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachmentWrp
  display: flex
  flex-direction: column
  gap: 16px

.attachmentTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51

.fileAttachment
  display: flex
  align-items: center
  text-decoration: underline
  color: #4C4E51

  img
    width: 40px
    height: 40px
    margin-right: 16px

  span
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 400
    line-height: 22px
    letter-spacing: 0

  &:hover
    text-decoration: none

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

  img
    margin-right: 8px

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757 !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none

.inputField
  +input-text
  font-family: 'Roboto Condensed', sans-serif
  padding: 4px 16px
  color: gray

.button
  border-radius: 24px
  border: 1px solid #DEE2E6
  background: inherit
  padding: 0 20px
  height: 40px

  .inner
    display: flex
    gap: 10px

  .text
    display: flex
    align-self: center
</style>
