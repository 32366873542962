<template>
  <page title="18 формы">
    <modal-progress-bar
        :is-complete="isProcessComplete"
        :is-modal-visible="isModalVisible"
        @close="isModalVisible = false"
    />
    <v-table-new
        v-model="transformedForms"
        :action-block-items="actionBlockItems"
        :loading="loading"
        :template="template"
        excel-report-file-name="18 формы"
        @on-cell-click="cellClickHandler"
    />

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить документ?
      </p>

      <p :class="$style.deleteItem">
        <span>Наименование:</span>
        {{ deleteItem.title }}
      </p>
      <p :class="$style.deleteItem">
        <span>Отделение:</span>
        {{ deleteItem.departmentName }}
      </p>
      <p v-if="deleteItem.accountingPeriodMonth" :class="$style.deleteItem">
        <span>Период: </span>{{ deleteItem.accountingPeriodMonth }} | {{ deleteItem.accountingPeriodYear }}
      </p>
      <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="visible && index === contractIdx"/>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDocument(deleteItem.documentId)"/>
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import DialogTemplate from 'templates/DialogTemplate';

import { ModalProgressBar, Page, VTableNew } from '@/components';
import {
  completedByStatus,
  formatContractStatus,
  formatEighteenthFormsKind,
  formatInfo,
  getContractStatusList,
} from 'lib/utils/formatXmlType';
import { convertDbDateTimeFormat, formatPeriod, getMonthString } from 'lib/utils/date';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'EighteenForms',
  components: {
    VTableNew,
    ModalProgressBar,
    HorizontalProgressBar,
    SubmitTaskDetails,
    DialogTemplate,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isModalVisible: false,
      isProcessComplete: false,
      forms: [],
      loading: false,
      dataUrl: undefined,
      title: '',
      subtitle: '',
      pdfUrl: null,
      visible: false,
      contractIdx: null,
      from: '1900-01-01T00:00:00Z',
      to: '2900-01-01T00:00:00Z',
      displayDeleteDialog: false,
      deleteItem: {},
      isWaiting: false,
      submitError: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'getUser']),
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Потребители',
            key: 'consumer',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'title',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Отделение',
            key: 'departmentName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Месяц',
            key: 'accountingPeriodMonth',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Год',
            key: 'accountingPeriodYear',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Контрагенты',
            key: 'counterparties',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Статус',
            key: 'status',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны Заказчик',
            key: 'supplierStatus',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '150px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: '',
            key: 'deleteControl',
            type: 'delete',
            customCheck: (data) => {
              const status = this.forms.find((it) => it.id === data.id).status;

              return status !== 'DRAFT' && status !== 'WAITING_VERIFICATION_ACT' && status !== 'REJECTED';
            },
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedForms() {
      if (this.forms.length === 0) {
        return [];
      }

      return this.forms.map((it) => ({
        ...it,
        title: it.title,
        departmentName: it.retailerDepartment.name,
        accountingPeriodMonth: getMonthString(it.month),
        accountingPeriodYear: it.year,
        counterparties: this.formatCounterparties(it.counterparties),
        status: this.formatContractStatus(it.status),
        deleteControl: '',
        documentId: it.documentId,
        consumer: formatEighteenthFormsKind(it.kind),
        rejectedReason: it.rejectedReason,
        supplierStatus: this.formatContractStatus(it.sides.find((side) => side.accountId === this.getAccount.id)?.status),
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        document: { documentId: it.documentId, name: it.title },
        actVerificationDocumentId: it.actVerification?.documentId,
        act: this.checkEmptyAct(it.actVerification),
        eighteenFormId: it.id,
      }));

    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Загрузить 18 формы',
          method: goToPageByRoute('eighteen-shape/upload'),
        },
      ];
    },
  },
  methods: {
    checkEmptyAct(act) {
      if (act) {
        return { documentId: act.documentId, name: 'Акт верификации' };
      }
      return null;

    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'deleteControl': {
          return this.deleteDialog(cell.row);
        }
        case 'followLink': {
          return this.link(cell.row);
        }
      }
    },
    link(task) {
      let workflowType;
      let title;
      const side = task.sides.find((it) => it.accountId === this.getAccount.id);
      switch (side?.status) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        case 'REJECTED': {
          if (this.getUser.id !== task.initiatorUserId) {
            workflowType = 'documentSigning';
            break;
          }
          workflowType = 'documentReload';
          title = `Исправьте ${task.title}`;
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: task.eighteenFormId,
          from: 'eighteen-shape',
          fromTitle: '18 ФОРМЫ',
          to: workflowType,
          title: title,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    formatCounterparties(counterparties) {
      if (!counterparties) {
        return null;
      }

      return counterparties.join('; ');
    },
    formatInfo(data) {
      return formatInfo(data);
    },
    formatPeriod(month, year) {
      return formatPeriod(month, year);
    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    formatEighteenthFormsKind(data) {
      return formatEighteenthFormsKind(data);
    },
    async loadData() {
      try {
        this.loading = true;
        this.forms = await this.loadEighteenthForms({});
        console.log(this.forms);
      } catch (error) {
        console.log('filterDocumentViews', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async deleteDocument(documentId) {
      try {
        this.isWaiting = true;
        this.submitError = '';

        await this.documentCancel({ documentId: documentId });

        await this.loadData();

        this.displayDeleteDialog = false;
        this.isWaiting = false;

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaiting = false;
      }
    },
    deleteDialog(item) {
      this.deleteItem = item;
      this.displayDeleteDialog = true;
    },
    ...mapActions('document', ['loadEighteenthForms', 'documentCancel', 'loadDocument']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
.deleteText
  +base-title
  margin-top: 24px
  margin-bottom: 16px
  width: 100%
  text-align: center

.deleteItem
  +base-text
  margin: 16px

  span
    +base-title

.submitBlock
  display: flex

  div
    width: 50%
</style>
