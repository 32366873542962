<template>
  <select-button
    :disabled="isSelectDisabled"
    :items="actionBlockItems"
  />
</template>

<script>
import SelectButton from 'components/SelectButton/SelectButton';

export default {
  name: 'PersonalDataAgreementFooter',
  components: {
    SelectButton,
  },
  props: {
    isSelectDisabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actionBlockItems: [
        {
          id: 1,
          title: 'Подписать согласие ПЭП с помощью E-mail',
          method: () => {
this.$emit('signMethod', 'E-MAIL');
},
        },
        {
          id: 2,
          title: 'Подписать согласие ПЭП с помощью СМС',
          method: () => {
this.$emit('signMethod', 'СМС');
},
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
