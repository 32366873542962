<template>
  <page title="Типовые замечания">
    <v-table-new
      v-model="transformedDefects"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. Типовые замечания"
      @delete="onDelete"
      @edit="onEdit"
    />

    <defect-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData"
    />
  </page>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchDefect } from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import Page from 'components/Page/Page.vue';
import DefectEdit from 'views/private/OdkAccounting/Defects/DefectEdit.vue';

export default {
  name: 'Defects',
  components: {
    Page,
    VTableNew,
    DefectEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      defect: [],
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ] : [];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'defectOdk' },
          },
          {
            label: 'Процессы',
            key: 'processesName',
            type: 'list',
            spoilerCount: 3,
            sort: { type: '' },
            filter: { type: 'vSearchSelectValueUrl', urlDefault: '/defects/processes' },
          },
          ...editHeaders,
        ],
      };
    },
    transformedDefects() {
      if (!this.defect?.length) {
        return [];
      }

      return this.defect;
    },
    isAccessUpdate() {
      return filterForAccess('process/1/4', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.defect = (await fetchDefect()).map((it) => ({
          ...it,
          processesName: it.processes?.map((it) => ({ value: it.name })) ?? [],
          processesId: it.processes?.map((it) => it.id) ?? [],
        }));
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
