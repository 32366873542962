<template>
  <v-dialog
    v-model="internalValue"
    class="v-dialog-top"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="v-application">
      <v-toolbar color="primary" dark>
        <v-btn dark icon @click="onTapCloseDialogReport">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card v-if="consignment" class="v-application">
        <v-row class="ml-2">
          <v-col>
            <v-row>
              <v-col>
                <p class="mb-0">
                  Учёт складских остатков
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div style="display: flex; gap: 24px">
                  <v-btn @click="exportToExcel">
                    Скачать как .XLSX
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/download_action.svg"/>
                  </v-btn>
                  <v-btn @click="exportToPdf">
                    Скачать как .PDF
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/download_action.svg"/>
                  </v-btn>
                  <v-btn @click="printToPdf">
                    Распечатать
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/file_print.svg"/>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headerForTableReport"
          :items="consignment.elements"
        />
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { exportToExcel } from 'utils/excel';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { oswaldRegularString } from '@/assets/fonts/oswald/Oswaldregular';

export default {
  name: 'ConsignmentAccountingBalances',
  props: {
    consignment: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    internalValue: false,
  }),
  computed: {
    headerForTableReport() {
      return [
        {
          text: 'АХП',
          align: 'start',
          sortable: true,
          value: 'axpName',
        },
        {
          text: 'Элемент',
          align: 'start',
          sortable: true,
          value: 'elementName',
        }, {
          text: 'Статус',
          align: 'start',
          sortable: true,
          value: 'statusName',
        },
        {
          text: 'Склад',
          align: 'start',
          sortable: true,
          value: 'warehouseName',
        },
        {
          text: 'Подрядчик',
          align: 'start',
          sortable: true,
          value: 'contractorName',
        },
        {
          text: 'Договор',
          align: 'start',
          sortable: true,
          value: 'contractName',
        },
        {
          text: 'Eдиница измерения',
          align: 'start',
          sortable: true,
          value: 'unitName',
        }, {
          text: 'Количество',
          align: 'start',
          sortable: true,
          value: 'count',
        }];
    },
    ...mapGetters('user', ['getToken', 'isBoiler']),
  },
  watch: {
    value() {
      this.internalValue = this.value;
      this.refresh();
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    onTapCloseDialogReport() {
      this.internalValue = false;
    },
    exportToExcel() {
      const footer = [
        [
          {
            text: 'Подписи АО "ОЭК"',
            merge: 9,
          },
          '',
        ],
      ];
      exportToExcel(
        'Накладная',
        [
          {
            text: 'П/П',
            value: 'index',
          },
          ...this.headerForTableReport,
        ],
        'text',
        this.consignment.elements.map((item, index) => ({ index: index + 1, ...item })),
        'value',
        {
          alignment: 'center',
          maxWidth: 40,
          footer: footer,
        },
      );
    },
    generatePdf() {
      const doc = new jsPDF('l', 'pt');

      doc.addFileToVFS('Oswaldregular.ttf', oswaldRegularString);
      doc.addFont('Oswaldregular.ttf', 'Oswaldregular', 'normal');
      doc.setFont('Oswaldregular');


      const headers = ['П/П'];

      this.headerForTableReport.forEach((item) => {
        headers.push(item.text);
      });

      const body = [];
      this.consignment.elements.forEach((item, index) => {
        body.push([index + 1, ...this.headerForTableReport.map((h) => [item[h.value]])]);
      });

      body.push([{ content: '', colSpan: 9 }]);
      body.push([{
        startY: doc.autoTable.previous.finalY + 15,
        content: 'Подписи АО "ОЭК"',
        colSpan: 9,
        styles: { halign: 'right' },
      }]);

      doc.text('Учёт складских остатков', 360, 15);


      doc.autoTable({
        head: [headers],
        body: body,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          fillColor: '#f37a1f',
        },
        styles: {
          font: 'Oswaldregular',
          fontStyle: 'normal',
        },
        startY: 20,
      });
      return doc;
    },
    exportToPdf() {
      this.generatePdf().save('Накладная.pdf');
    },
    printToPdf() {
      this.generatePdf().autoPrint({ variant: 'non-conform' }).output('pdfobjectnewwindow', { filename: 'Накладная.pdf' });
    },
    ...mapActions('user', ['setNotification']),
  },

};
</script>
