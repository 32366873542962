<template>
  <v-app class="vuetify-styles" data-app>
    <odk-tag-modal v-model="tagsModalState"
                   @updated="fetchDataProcesses($route.params.assignmentForInspectionId)"/>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form3" class="v-application" style="display: block">
          <v-card-title>
          <span class="text-h5 align-center"
          >Распределение на осмотр внутри департамента</span
          >
          </v-card-title>
          <v-card-text>
            <text-field
              ref="tagsFilter"
              v-model="tagsFilter"
              title="Фильтрация по тэгу"/>
            <div>
              <yandex-map
                ref="customMap"
                :collection-axp="axpItemsFiltered"
                @tapPlacemark="onTapPlacemark"
              />
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="axpItemsFiltered"
              :loading="isLoading"
              class="elevation-1"
              item-key="id"
              show-select
            >
              <template #item.addTagButton="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-btn
                    class="ma-2"
                    color="primary"
                    rounded
                    @click="openTags(item.adreskaId)"
                  >
                    Тэги
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <simple-autocomplete-by-function
              ref="employee"
              v-model="employeeId"
              :dependency="fetchedRoles"
              :fetch-data-function="rolesForPermissionByName"
              :fetch-default-data-function="rolesForPermissionByName"
              :items.sync="employees"
              :search.sync="employeeFullName"
              class="mt-4"
              is-required
              title="Сотрудник"
            ></simple-autocomplete-by-function>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog
        v-model="loaderDialog" class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { getSurnameAndInitials } from 'utils/user';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import TextField from 'components/Processes/components/TextField.vue';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import { filterForAccess } from 'lib/utils/permissions';

export default {
  components: {
    OdkTagModal,
    TextField,
    YandexMap,
    SimpleAutocompleteByFunction,
  },

  name: 'Form1',

  created() {
    let params = this.$route.params;
    if (params.task) {
      localStorage.setItem('odk_task', JSON.stringify(this.$route.params));
    } else {
      params = JSON.parse(localStorage.getItem('odk_task'));
    }
    this.taskId = params.task;
    this.fetchDataProcesses(params.assignmentForInspectionId);
    this.fetchRoles(params.departmentId);
  },

  data: () => ({
    taskId: '',

    departmentId: null,
    selected: [],
    items: [],
    employeeId: null,
    employeeFullName: null,
    employees: [],
    isLoading: false,

    progressValue: 0,
    loaderDialog: false,

    fetchedRoles: [],
    tagsFilter: null,
    tagsModalState: null,
  }),

  computed: {
    headers() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        {
          text: 'Замечание',
          value: 'defectName',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Комментарий к замечанию',
          value: 'defectDescription',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Критичность осмотра',
          align: 'start',
          value: 'criticalityName',
        },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    axpItemsFiltered() {
      if (this.tagsFilter) return this.items.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      return this.items;
    },
    isDisabledSaveDialog() {
      const isDisabledSelected = this.selected.length == 0;
      const isDisabledEmloyer = this.employeeId == null;

      return isDisabledSelected || isDisabledEmloyer;
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getToken', 'getAccount']),
  },

  methods: {
    openTags(id) {
      this.tagsModalState = id;
    },
    onTapPlacemark(id) {
      const item = this.items.find((it) => it.id === id);
      if (this.selected.some((it) => it.id === id)) {
        this.selected = this.selected.filter((it) => it.id !== id);
        item.balloonContent.buttonLabel = 'Выбрать';
      } else {
        item.balloonContent.buttonLabel = 'Отменить';
        this.selected.push(item);
      }
      this.items = [...this.items];
    },
    async fetchRoles(departmentId) {
      this.departmentId = departmentId;
      const result = await this.getSubdivisionWithFilteredRolesById({
        id: departmentId,
      });
      this.fetchedRoles = result.roles.flatMap((role) => role.authorizations.map((auth) => ({
        authorization: auth,
        roleName: role.name,
      }))).map((elem) => ({
        id: elem.authorization.user.id,
        name: `${elem.roleName} ${getSurnameAndInitials(elem.authorization.user.fullName)}`,
      }));
    },
    async fetchDataProcesses(assignmentForInspectionId) {
      this.isLoading = true;
      const response = await fetch(
        `${BASE_URL
        }/assignment-for-inspection/not-assigned?assignment-id=${assignmentForInspectionId}`,
      );
      const data = await response.json();
      data.forEach((it) => {
        const item = {
          id: it.id,
          odkName: it.adreskaFact.axp.odk.name,
          axpName: it.adreskaFact.axp.name,
          defectName: it.defect?.defect?.name,
          defectDescription: it.defect?.defectDescription,
          criticalityName: it.assignmentForInspection.criticality.name,
          tags: it.adreskaFact.adreska.tags.map((it) => it.name).join(', '),
          locationLatitude: it.adreskaFact.locationLatitude,
          locationLongitude: it.adreskaFact.locationLongitude,
          adreskaId: it.adreskaFact.adreska.id,
          balloonContent: {
            title: it.adreskaFact.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: [
              {
                label: 'ID',
                value: it.adreskaFact.id,
              },
              {
                label: 'АХП',
                value: it.adreskaFact.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.adreskaFact.decorType?.name,
              },
              {
                label: 'Подрядчик',
                value: it.adreskaFact.adreska.contractorName,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(it.adreskaFact.createDate),
              },
            ],
          },
        };
        this.items.push(item);
      });
      this.isLoading = false;
    },

    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        taskId: this.taskId,
        installationAssignmentForInspection: this.selected,
        employeeUserId: this.employeeId,
        employeeAccountId: this.getAccount.id,
      };

      axios
        .post(`${BASE_URL}/distribution-for-inspection`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    async rolesForPermissionByName(name) {
      if (!name?.length) return this.fetchedRoles;
      const nameLower = name.toLowerCase();
      return this.fetchedRoles.filter((role) => role.name.toLowerCase().includes(nameLower));
    },
    onTapBack() {
      this.$router.back();
    },
    ...mapActions('user', ['getSubdivisionWithFilteredRolesById', 'setNotification']),
  },
};
</script>
