<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps" />
        <span>Документ, подтверждающий право собственности</span>
      </h2>
    </div>

    <div :class="$style.gridFluid">
      <c-select
        v-model="confirmationDocument.documentTitle"
        label="Тип документа *"
        role="documentTitle"
        :options="confirmationDocumentTitleList"
        :error="errors.documentTitle"
      />
    </div>
    <div v-if="confirmationDocument.documentTitle.value==='Иное основание...'" :class="$style.gridFluid">
      <c-input
        v-model="confirmationDocument.documentName"
        label="Наименование документа/основания *"
        role="documentName"
        :error="errors.documentName"
        :validation="$validate.isNotEmptyString"
      />
    </div>
    <div v-if="confirmationDocument.documentTitle.value !== 'Выписка из ЕГРН'" :class="$style.grid2Column">
      <c-input
        v-model="confirmationDocument.documentNumber"
        label="Номер документа *"
        role="documentNumber"
        :error="errors.documentNumber"
        :validation="$validate.isNotEmptyString"
      />
      <div>
        <p :class="$style.dateLabel">
          <span>Выдан *</span>
        </p>
        <v-date-picker
            v-model="confirmationDocument.documentIssueDate"
            role="documentIssueDate"
            :start-date="startDate"
            :err="errors.documentIssueDate?.error"
            err-text="Поле обязательно для заполнения"
            :validation="$validate.isNotEmptyString"
        />
      </div>
    </div>

    <div :class="$style.gridFluid">
      <div :class="$style.textDescription">
        <p>Выписку из ЕГРН можно получить на: <a href="https://rosreestr.gov.ru/wps/portal/p/cc_present/EGRN_1" target="_blank">rosreestr.gov.ru</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StepCounter from '../components/StepCounter';
import { VDatePicker } from 'components';

export default {
  name: 'ConfirmationDocument',
  components: {
    CSelect: () => import('atoms/common/CSelect'),
    CInput: () => import('atoms/common/inputs/CInput'),
    VDatePicker,
    StepCounter,
  },
  props: {
    confirmationDocument: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    ...mapGetters('dataLists', ['confirmationDocumentTitleList']),
  },
};
</script>

<style lang="sass" module>
  .stepContainer
    +questionnaries-container
    +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .noteContainer
    +base-spacing

    p
      +small-spacing
      +small-text

    a
      +base-link

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .textDescription
    p
      +base-text

    a
      +base-link

  .dateLabel
    margin-bottom: 8px
    font-size: 16px
    line-height: 24px
</style>
