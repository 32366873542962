<template>
  <add-directory-field-modal
      v-model="internalModalValue"
      :action="internalModalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createAxpElement"
      @delete="deleteAxpElement"
      @update="updateAxpElements"
      @isNotValid="reValidate">
    <template #axpId="slotProps">
      <axp-autocomplete
          :selected="slotProps.value"
          :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
          :is-required="slotProps.field.required"
          :title="slotProps.field.name"
          @change="slotProps.input"
      />
    </template>
    <template #typeId="slotProps">
      <axp-element-types-autocomplete
          :selected="slotProps.value"
          :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
          :is-required="slotProps.field.required"
          :title="slotProps.field.name"
          @change="slotProps.input"
      />
    </template>
    <template
        #photos="slotProps">
      <div class="vuetify-styles" data-app style="margin: 24px 0 0 0">
        <v-app class="no-min-height" style="display: flex; width: auto; min-height: 0">
          <file-input
              :is-required="slotProps.field.required"
              :only-view="slotProps.disabled"
              :title="slotProps.field.name"
              :value="slotProps.value"
              disable-vertical-padding
              :re-validate="isReValidate"
              @input="slotProps.input"
          />
        </v-app>
      </div>
    </template>
    <template #decorTypeId="slotProps">
      <decor-type-autocomplete
          :selected="slotProps.value"
          :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
          :is-required="slotProps.field.required"
          :title="slotProps.field.name"
          @change="slotProps.input"
      />
    </template>
  </add-directory-field-modal>
</template>

<script>
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  createAxpElement,
  deleteAxpElement,
  updateAxpElements,
} from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import AxpElementTypesAutocomplete from 'components/Processes/components/AxpElementTypesAutocomplete.vue';
import DecorTypeAutocomplete from 'components/Processes/components/DecorTypeAutocomplete.vue';
import FileInput from 'components/Processes/components/FileInput.vue';

export default {
  name: 'ElementsAxpEdit',
  components: {
    AddDirectoryFieldModal,
    AxpAutocomplete,
    AxpElementTypesAutocomplete,
    DecorTypeAutocomplete,
    FileInput,
  },
  props: {
    modalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalModalValue: {},
      loading: false,
      AddDirectoryFieldModalActions: AddDirectoryFieldModalActions,
      isReValidate: false,
    };
  },
  computed: {
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'axpId',
          name: 'АХП',
          url: '/axp',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'unitId',
          name: 'Единицы измерения',
          url: '/units',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.NUMBER,
          id: 'quantity',
          name: 'Количество',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'typeId',
          name: 'Тип',
          url: '/axp-element-types',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'decorTypeId',
          name: 'Тип декора',
          url: '/decor-types',
          required: false,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'required',
          name: 'Обязательный для сборки',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'archived',
          name: 'В архиве',
          required: true,
          defaultValue: 'false',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'photos',
          name: 'Фото',
          required: true,
          defaultValue: [],
        },
      ];
    },
  },
  watch: {
    modalAction(val) {
      this.internalModalAction = val;
    },
    modalValue(val) {
      this.internalModalValue = val;
    },
  },
  methods: {
    closeModal() {
      this.internalModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:modalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalModalValue = {};
      this.$emit('update:modalValue', {});
    },
    deleteAxpElement() {
      this.doAction(() => deleteAxpElement(this.modalValue.id));
    },
    createAxpElement() {
      this.doAction(() => createAxpElement(this.formatBody()));
    },
    updateAxpElements() {
      this.doAction(() => updateAxpElements(this.modalValue.id, this.formatBody()));
    },
    formatBody() {
      return {
        ...this.modalValue,
        photos: this.transformFilesToRequest(this.modalValue.photos),
      };
    },
    async doAction(action) {
      this.loading = true;
      try {
        const result = await action();
        this.$emit('onAction', result?.data);
        this.closeModal();
        this.$emit('loadData', this.modalValue);
      } catch (error) {
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    transformFilesToRequest(photos) {
      return photos.map((doc) => ({
        id: doc.fileId,
        stringBase64: doc.previewUrl,
        fileName: doc.name,
      }));
    },
    reValidate() {
      this.isReValidate = !this.isReValidate;
    },
    ...mapActions('user', ['setNotification']),
  },
};

</script>
