<template>
  <v-form @submit="onSubmit()">
    <div :class="$style.dialogContainer">
      <div :class="$style.dialogContent">
        <h1 :class="$style.title">
          <span>Регистрация пользователя</span>
        </h1>

        <h3 :class="$style.subTitle">
          <span>{{ userTypeTitle }}</span>
        </h3>

        <v-divider :class="$style.divider"/>

        <user-info
          v-model="user"
          :company="company"
          :editable="true"
          :is-legal-entity="isLegalEntity"
          :is-ip-info="isIpRegistration"
          type="registration"
          :is-cap="isCap"
          :without-password="withoutPassword"
          @changeKpp="changeKpp"
          @input="updateUser"
          @updateCompany="updateCompany"
        />

        <new-user-agreements
            :user="user"
            :display-privacy-policy="displayPrivacyPolicy"
            :is-legal-entity="isLegalEntity"
            @updateAgreements="updateAgreements"
        />

        <div v-if="errorText || isWaitingSubmit" :class="$style.waiting">
          <p v-if="errorText" class="errorText">{{ errorText }}</p>
          <horizontal-progress-bar v-if="isWaitingSubmit"/>
        </div>

        <div :class="$style.contentFooter">
          <v-button :class="$style.back" type="button" variant="outlined-gray-3" @click="back">
            <chevron-left/>
            <span>Назад</span>
          </v-button>
          <v-button :class="$style.next + ' submit-button'" :disabled="!privacyPolicy || !isValid" type="submit">
            <span>{{ registerButtonText }}</span>
            <chevron-right/>
          </v-button>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import { VButton, VDivider, VForm } from 'components';
import UserInfo from 'components/UserInfo/UserInfo.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import generateXml from 'lib/storage/connection-application/data';
import { mapActions, mapGetters } from 'vuex';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ChevronRight from 'atoms/icons/navigation/ChevronRight';
import HTTPError from 'lib/utils/errors';
import NewUserAgreements from 'organisms/registration/individual-connection/NewUserAgreements';

export default {
  name: 'IndividualConnectionApp',
  components: {
    ChevronRight,
    HorizontalProgressBar,
    UserInfo,
    VDivider,
    VForm,
    ChevronLeft,
    VButton,
    NewUserAgreements,
  },
  props: {
    displayPrivacyPolicy: {
      type: Boolean,
      default: true,
    },
    isLegalEntity: {
      type: Boolean,
      default: false,
    },
    withoutPassword: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      required: false,
    },
    certificate: {
      type: Object,
      required: false,
    },
    isCap: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.user = {
      email: (!this.isPlatformWorker && this.getUser?.email) ? this.getUser.email : '',
      phone: (!this.isPlatformWorker && this.getUser?.phoneNumber) ? this.maskFormattingPhoneNumber(this.getUser.phoneNumber) : '',
    };
  },
  data() {
    return {
      user: {
        email: (!this.isPlatformWorker && this.getUser?.email) ?? '',
        phone: (!this.isPlatformWorker && this.getUser?.phoneNumber) ? this.maskFormattingPhoneNumber(this.getUser.phoneNumber) : '',
      },
      privacyPolicy1: false,
      privacyPolicy2: false,
      privacyPolicy3: false,
      privacyPolicy4: false,
      isWaitingSubmit: false,
      intervalId: null,
      errorText: '',
      isShowPersonalDataModal: false,
      innerCompany: { ...this.company },
      applicantsConsent: [],
      applicantsDocuments: [],
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'getUser', 'isPlatformWorker']),
    isModalOpen() {
      return this.isShowPersonalDataModal;
    },
    registerButtonText() {
      if (this.isPlatformWorker) {
        return 'Зарегистрировать';
      }
      return this.isLegalEntity ? 'Подписать заявление и зарегистрироваться' : 'Зарегистрироваться';
    },
    privacyPolicy() {
      return this.privacyPolicy1 &&
        this.privacyPolicy3 &&
        this.privacyPolicy2;
    },
    isIpRegistration() {
      if (this.company?.ogrnip) return true;
      return false;
    },
    userTypeTitle() {
      if (this.isIpRegistration) return 'Индивидуальный предприниматель';
      return this.isLegalEntity ? 'Юридическое лицо' : 'Физическое лицо';
    },
    isAuthorized() {
      if (this.isLegalEntity) {
        if (this.user.password && this.user.password.length > 0) {
          return this.$validate.isNotNull(this.getAccount?.profile) && this.$validate.isNotUndefined(this.getAccount?.profile);
        }
        return this.isExistingLegalAccount;
      }
      return this.$validate.isNotNull(this.getUser?.snils) && this.$validate.isNotUndefined(this.getUser?.snils);
    },
    isValid() {
      return this.user.password === this.user.confirmPassword &&
          (
              !this.isPlatformWorker || (this.applicantsConsent.length > 0 && this.applicantsDocuments.length > 0)
          );
    },
    accountsList() {
      if (!this.getUser || !this.getUser.authorizations) {
        return [];
      }
      return this.getUser.authorizations;
    },
    isExistingLegalAccount() {
      return this.accountsList.filter((item) => item.account?.profile?.inn === this.company.inn).length !== 0;
    },
    privacyPolicyTargetTitle() {
      return this.isPlatformWorker ? { person: 'Заявитель', action: 'даёт', whose: 'своих' }
          : { person: 'Я', action: 'даю', whose: 'моих' };
    },
  },
  methods: {
    async onSubmit() {
      this.isWaitingSubmit = true;
      this.errorText = '';

      let xml = '';

      if (this.isIpRegistration) {
        this.user.inn = this.company.inn;
        this.user.ogrnip = this.company.ogrnip;
        this.user.address = this.company.address;

        xml = generateXml.IndividualConnectionToPlatformApplicationIp(
          this.user,
          {
            userAgreement: this.privacyPolicy3,
            personalDataAgreement: this.privacyPolicy1,
            privacyPolicy: this.privacyPolicy2,
            marketing: this.privacyPolicy4,
          },
        );

        delete this.user.inn;
        delete this.user.ogrnip;
      } else {
        xml = this.isLegalEntity
        ? generateXml.legalConnectionToPlatformApplication(
          this.innerCompany,
          'utility',
          this.user,
          {
            agreement: {
              userAgreement: this.privacyPolicy3,
              personalDataAgreement: this.privacyPolicy1,
              privacyPolicy: this.privacyPolicy2,
              marketing: this.privacyPolicy4,
            },
            agreementScans: this.applicantsConsent,
            documentScans: this.applicantsDocuments,
          },
        )
        : generateXml.IndividualConnectionToPlatformApplication(
          this.user,
        {
            agreement: {
              userAgreement: this.privacyPolicy3,
              personalDataAgreement: this.privacyPolicy1,
              privacyPolicy: this.privacyPolicy2,
              marketing: this.privacyPolicy4,
            },
            agreementScan: this.applicantsConsent,
            documentScan: this.applicantsDocuments,
          },
        );
      }

      try {
        let message = '';
        let title = '';
        let route = 'tasks';

        if (this.isLegalEntity) {
          title = `Проверка ФНС пройдена: компания ${this.company.name ?? this.innerCompany.name} существует и зарегистрирована на Платформе`;

          if (this.isPlatformWorker) {
            route = 'register-legal-entities';
          } else {
            message = 'Теперь вы можете приступить к работе на Платформе и начать загрузку топологии.';
          }
        } else {
          if (this.isPlatformWorker) {
            route = 'register-individuals';
            title = 'Физическое лицо успешно зарегистрировано';
          } else {
            message = 'Вам предоставлен доступ к личному кабинету в ознакомительных целях. Если вы являетесь сотрудником компании, попросите ответственного сотрудника делегировать вам полномочия. Если вы частное лицо, заполните заявку на технологическое присоединение или заключите договор энергоснабжения';
            title = 'Поздравляем, Вы зарегистрированы на Платформе';
          }
        }
        let signature;
        if (this.isLegalEntity && !this.isPlatformWorker) signature = await this.signDocument({ xml: xml, certificate: this.certificate });

        if (this.user.password && this.user.password.length > 0) {
          this.isPlatformWorker ? await this.registerUserRequest({ password: this.user.password, xml: xml })
              : await this.registerUser({ password: this.user.password, xml: xml, signature: signature });
        } else {
          await this.documentUpload({ xml: xml, isCap: true, certificate: this.certificate });
        }

        if (this.isPlatformWorker) {
          this.vueShowNotification(title, `<p>${message}</p>`);
          this.$emit('route');
          return;
        }

        this.authorizationsRequestByInterval(title, message, route);
      } catch (error) {
        console.log(error);
        const errorData = HTTPError.network(error);
        this.errorText = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    authorizationsRequestByInterval(title, message, route) {
      this.intervalId = setInterval(async () => {
        if (this.isAuthorized) {
          clearInterval(this.intervalId);
          this.vueShowNotification(title, `<p>${message}</p>`);

          if (this.user.password && this.user.password.length > 0) {
            await this.$router.push(`/cabinet/${route}`);
            return;
          }

          this.$emit('route');
          return;
        }

        await this.setUser();
      }, 2000);
    },
    updateUser(val) {
      this.user = val;

      if (this.isPlatformWorker) {
        this.innerCompany = {
          ...this.innerCompany,
          person: { ...val, snils: val.insuranceNumber, familyName: val.secondName },
        };
      }
    },
    updateCompany(val) {
      this.innerCompany = { ...this.innerCompany, ...val };
    },
    back() {
      this.$emit('close');
    },
    parsePhoneNumber(number) {
      return number.substring(number.length - 10);
    },
    maskFormattingPhoneNumber(number) {
      return `+7 (${number.substring(2, 5)}) ${number.substring(5, 8)}-${number.substring(8, 10)}-${number.substring(10, 12)}`;
    },
    changeKpp(val) {
      this.innerCompany.kpp = val;
    },
    openPersonalDataAgreementModal() {
      this.isShowPersonalDataModal = true;
    },
    handleRemoveApplicantsConsent(file) {
      this.applicantsConsent = this.applicantsConsent
          .filter((scan) => scan.source !== file.source);
    },
    handleRemoveApplicantsDocuments(file) {
      this.applicantsDocuments = this.applicantsDocuments
          .filter((scan) => scan.source !== file.source);
    },
    updateAgreements(agreements) {
      this.privacyPolicy1 = agreements.privacyPolicy1;
      this.privacyPolicy2 = agreements.privacyPolicy2;
      this.privacyPolicy3 = agreements.privacyPolicy3;
      this.privacyPolicy4 = agreements.privacyPolicy4;
      this.applicantsConsent = agreements.applicantsConsent;
      this.applicantsDocuments = agreements.applicantsDocuments;
    },
    ...mapActions('document', ['documentUpload', 'signDocument']),
    ...mapActions('user', ['setUser', 'registerUser', 'registerUserRequest']),
  },
};
</script>

<style lang="sass" module>
.saveButton
  position: relative
  width: 100%
  padding: 16px
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 24px
  background-color: #2F82DF
  color: #FFF
  text-transform: uppercase

.disabledButton
  background-color: gray

.dialogContainer
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

.noteContainer
  padding-bottom: 20px

  .downloadPolicy
    width: 100%
    display: inline-flex
    justify-content: space-between
    align-items: center

    .downloadButton
      text-align: right
      display: inline-flex
      align-items: center
      color: #ACB0B5

      svg
        height: 18px
        fill: #ACB0B5

  p
    margin-bottom: 12px
    font-family: Roboto Condensed
    font-size: 18px
    font-weight: 400
    line-height: 22px

    a
      +base-link

  margin: 0

  .welcomeText
    font-size: 18px
    font-weight: 600
    line-height: 22px
    margin-bottom: 28px

.spanCheckbox
  height: 16px
  display: inline-flex
  vertical-align: middle
  align-item: center

.dialogContent
  height: 100%
  margin-top: 22px
  margin-bottom: 22px

  padding: 16px 56px
  overflow-y: auto
  overflow-x: hidden

  &::-webkit-scrollbar-button
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar-thumb
    -webkit-border-radius: 0
    border-radius: 10px
    background-color: #d1d1d1

  &::-webkit-scrollbar-thumb:hover
    background-color: #a19f9f

  &::-webkit-resizer
    background-repeat: no-repeat
    width: 7px
    height: 0

  &::-webkit-scrollbar
    width: 7px
    height: 7px

  .title
    font-size: 22px
    font-weight: 500
    line-height: 26px
    margin-bottom: 16px

  .subTitle
    font-size: 16px
    font-weight: 500
    line-height: 20px
    margin-bottom: 16px

  .divider
    height: 1px
    margin-bottom: 16px

  .dividerBlock
    height: 1px
    margin-top: 24px
    margin-bottom: 24px


.disabled
  pointer-events: none
  opacity: 0.4

.back
  margin-bottom: 16px
  margin-left: 56px
  display: flex
  align-items: center
  cursor: pointer
  padding: 10px 30px

  svg
    fill: #4C4E51

.next
  margin-bottom: 16px
  margin-right: 56px
  display: flex
  align-items: center
  cursor: pointer
  padding: 10px 30px
  color: #FFFFFF

  svg
    fill: #FFFFFF

.contentFooter
  display: flex
  justify-content: space-between

.waiting
  padding: 0 56px 16px 56px

</style>

<style lang="sass">
.disabled-validate-form
  .submit-button
    cursor: default
    background-color: #7e8b91
</style>
