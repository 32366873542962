<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <span class="text-h5 align-center">Возврат из ремонта</span>
          </v-card-title>
          <v-card-text>
            <simple-autocomplete
              ref="planConsignment"
              v-model="planConsignmentId"
              :items.sync="planConsignments"
              :search.sync="planConsignmentName"
              search-by="uniqueNumber"
              title="Плановая накладная"
              url="/plan-consignment/find?type=FROM_REPAIR&hideArchive=true&uniqueNumber="
              url-default-data="/plan-consignment/find?type=FROM_REPAIR&hideArchive=true"
            ></simple-autocomplete>

            <process102 v-model="data" :plan-consignment="planConsignment"/>

            <v-row>
              <v-col>
                <v-data-table :headers="headersTable" :items="formElements" class="elevation-1 mt-4">
                  <template #no-data>
                  <span :class="{
                    valid: isTableElementsValid,
                    invalid: !isTableElementsValid,
                  }">Отсутствуют данные</span>
                  </template>
                  <template #top>
                    <v-toolbar flat>
                      <edit-dialog-process ref="editDialog" v-model="editDialog" :axp-id="data.axpId"
                                           :cancel="cancelForAxpElements" :dialog-data="editedItem"
                                           :disabled="disabledEditDialog"
                                           :edit="editedIndex" :handle-success="addElement">
                      </edit-dialog-process>
                      <v-dialog v-model="dialogDelete"
                                class="v-dialog-top" max-width="500px">
                        <v-card class="v-application">
                          <v-card-title class="text-h5 text-center">Вы точно хотите удалить этот элемент?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialogDelete = false">Отмена</v-btn>
                            <v-btn class="ma-2" color="error" outlined rounded @click="deleteItemConfirm">Удалить
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template #item.actions="{ item }">
                    <v-icon class="mr-2" small @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <file-input ref="documents" v-model="documents" :loading="isLoadingDocuments"
                        is-required title="Фото документов"></file-input>


            <v-textarea v-model="commentary" label="Комментарий" name="input-7-4"
                        outlined></v-textarea>

            <text-field ref="contractorEmployee" v-model="contractorEmployeeName" :is-required="false"
                        title="Сотрудник подрядчика">
            </text-field>

            <employee-autocomplete
              ref="performer"
              v-model="performerId"
              :items.sync="performerItems"
              :search.sync="performerName"
              class="mt-2"
              is-required
              set-current-by-default
              title="Сотрудник, совершивший операцию"
            ></employee-autocomplete>

            <warehouse-date-picker
              ref="date"
              v-model="date"
              is-required
              label="Дата накладной"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn class="ma-2" color="primary" outlined rounded @click="onTapBack"> Назад</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="isDisabledSendButton" color="primary" elevation="0" rounded @click="onTapSend">
              Отправить
            </v-btn>
          </v-card-actions>

          <v-dialog v-model="loaderDialog"
                    class="v-dialog-top" persistent width="250">
            <v-card class="v-application">
              <v-card-title class="text-h5 text-center green lighten-2">
                Загрузка данных
              </v-card-title>
              <div class="text-center mt-8">
                <div v-if="progressValue < 100">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div v-else>
                  <v-icon :size="70" :width="7" color="green darken-2">
                    mdi-cloud-check-variant
                  </v-icon>
                </div>

                <div class="mt-2 mb-8">
                  {{ progressValue + "%" }}
                </div>
              </div>
              <v-card-actions class="flex-column">
                <v-btn
                  class="ma-2"
                  color="primary"
                  outlined
                  rounded
                  @click="onTapBack"
                >
                  На главную
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  color="primary"
                  outlined
                  rounded
                  @click="onTapViewReport"
                >
                  Показать накладную
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                  Заполнить еще раз
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import EditDialogProcess from './EditDialog.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
import TextField from 'components/Processes/components/TextField.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import WarehouseDatePicker from 'components/Processes/components/WarehouseDatePicker.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import Process102 from 'components/Processes/WarehouseForms/Process16-4.vue';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';
import EmployeeAutocomplete from 'components/Processes/components/EmployeeAutocomplete.vue';

export default {

  components: {
    EmployeeAutocomplete,
    SimpleAutocomplete,
    Process102,
    WarehouseDatePicker,
    Consignment, EditDialogProcess, FileInput, TextField,
  },
  name: 'Form1',
  data: () => ({
    performerId: null,
    performerName: '',
    performerItems: [],
    loaderDialog: false,
    progressValue: 0,
    contractorEmployeeId: null,
    contractorEmployeeName: '',
    contractorEmployees: [],
    commentary: '',
    formElements: [],
    editedIndex: -1,
    editedItem: {},
    isDocumentInputValid: true,
    documents: [],
    countDocuments: null,
    addedDocuments: [],
    allNameAddedDocuments: new Set(),
    isPhotoInputValid: true,
    photos: [],
    countPhotos: null,
    addedPhotos: [],
    allNameAddedPhotos: new Set(),
    isTableElementsValid: false,
    dialogDelete: false,
    isLoadingDocuments: false,
    disabledEditDialog: true,
    editDialog: false,
    dialogForViewReport: false,
    dataReportCustom: {},
    headersTable: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      { text: 'Количество', value: 'realCount' },
      { text: 'Фото', value: 'photos.length' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
    date: null,

    data: {
      warehouseId: null,
      warehouseName: '',
      axpId: null,
      axpName: '',
      contractorId: null,
      contractorName: '',
    },

    planConsignmentId: null,
    planConsignmentName: '',
    planConsignments: [],
  }),
  computed: {
    planConsignment() {
      if (!this.planConsignmentId) return null;
      return this.planConsignments.find((it) => it.id === this.planConsignmentId);
    },
    isDisabledSendButton() {
      const isDisabledDate = this.date == null;
      const isDisabledElements = this.formElements == 0;
      const isDisabledDocuments = !this.documents.length;
      const isDisabledPerformer = this.performerId == null;


      return (
        this.data.isDisabled ||
        isDisabledDate ||
        isDisabledElements ||
        isDisabledDocuments ||
        isDisabledPerformer
      );
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    'data.axpId'(newVal) {
      this.formElements = [];
      this.editedItem = {};
      this.editedIndex = -1;
      this.disabledEditDialog = newVal == null;
    },
  },
  methods: {
    cancelForAxpElements() {
      this.editedIndex = -1;
    },

    addElement(data) {
      const newData = {};
      Object.assign(newData, data);
      if (this.editedIndex > -1) {
        Object.assign(this.formElements[this.editedIndex], newData);
      } else {
        this.formElements.push(newData);
      }
      this.$refs.editDialog.afterClose();
      this.editedIndex = -1;
      this.editDialog = false;
      this.countDisable = this.countDisable + 1;
    },


    deleteItemConfirm() {
      this.formElements.splice(this.editedIndex, 1);
      this.onTapCloseDeleteTableDialog();
      this.editedIndex = -1;
      this.dialogDelete = false;
    },

    onTapSend() {
      const formDataFiles = new FormData();
      this.documents.forEach((f) => {
        formDataFiles.append('files', f.file);
      });

      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.documents.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });


      const request = {
        performerUserId: this.performerId,
        planConsignmentId: this.planConsignmentId,
        warehouseId: this.data.warehouseId,
        axpId: this.data.axpId,
        documents: mainPhotos,
        contractorId: this.data.contractorId,
        commentary: this.commentary,
        contractorEmployee: this.contractorEmployeeName,
        elementsFromRepairConstruction: [],
        createDate: this.date,
      };

      this.formElements.forEach((f) => {
        const formElementPhotos = f.photos == null ? [] : f.photos;
        const photos = [];
        formElementPhotos.forEach((doc) => {
          photos.push({
            stringBase64: doc.previewUrl,
            fileName: doc.name,
          });
        });
        request.elementsFromRepairConstruction.push({
          id: f.elementId,
          count: f.realCount ?? 0,
          photos: photos,
        });
      });

      axios
        .post(`${BASE_URL}/from-repair-construction`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    // Для таблицы

    editItem(item) {
      this.editedIndex = this.formElements.indexOf(item);
      Object.assign(this.editedItem, this.formElements[this.editedIndex]);
      this.editDialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.formElements.indexOf(item);
      this.dialogDelete = true;
    },


    onTapAgain() {
      this.performerId = null;
      this.performerName = '';
      this.performerItems = [];
      this.loaderDialog = false;
      this.contractorEmployeeId = null;
      this.contractorEmployeeName = '';
      this.contractorEmployees = [];
      this.commentary = '';
      this.formElements = [];
      this.editedIndex = -1;
      this.editedItem = {};
      this.isDocumentInputValid = true;
      this.documents = [];
      this.countDocuments = null;
      this.addedDocuments = [];
      this.allNameAddedDocuments = new Set();
      this.isPhotoInputValid = true;
      this.photos = [];
      this.countPhotos = null;
      this.addedPhotos = [];
      this.allNameAddedPhotos = new Set();
      this.isTableElementsValid = false;
      this.dialogDelete = false;
      this.isLoadingDocuments = false;
      this.disabledEditDialog = true;
      this.dialogTable = false;
      this.dialogTableDelete = false;
      this.editDialog = false;
      this.progressValue = 0;
      this.dialogForViewReport = false;
      this.dataReportCustom = {};
      this.date = null;
      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));
    },

    onTapViewReport() {
      const els = this.formElements.filter((element) => element.realCount > 0).map((element) => ({
        performer: this.performerName,
        axpName: this.data.axpName,
        date: formatDateFromOdkTimestamp(this.date),
        contractorName: this.data.contractorName,
        warehouseName: this.data.warehouseName,
        photos: element.photos,
        elementName: element.elementName,
        unitName: element.unitName,
        type: 'Возврат из ремонта',
        count: element.realCount ?? 0,
        employee: this.getUser.fullName,
        contractorEmployeeName: this.contractorEmployeeName,
      }));

      this.dataReportCustom = els[0];
      this.dataReportCustom.elements = els;


      this.dialogForViewReport = true;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>
