<template>
  <div :class="$style.container">

    <div v-custom-click-outside="hide" :class="$style.filterContainer">
      <NavMenu
        :type="type"
        @load="loadData"
        @sort="sort"
        @change="changeViews()"
        @show="showFilter = !showFilter"
      />


      <FilterType
        v-if="showFilter"
        @filter="filterDocuments"
        @load="loadData"
        @close="showFilter = false"
      />
    </div>

    <div v-if="!$store.state.document.change" :class="$style.grid">
      <component
        :is="'DocumentCard'"
        v-for="(doc, idx) in documents"
        :key="`doc_${idx}`"
        :document="doc"
        :index="idx"
      />
    </div>

    <div v-else style="padding-left: 16px; padding-top: 8px; overflow-y: auto; height: 100%;">
      <vertical-card
        :documents="documents"
      />
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DocumentCard from 'organisms/documents/Card';
import VerticalCard from 'organisms/documents/VerticalCard';
import FilterType from 'organisms/documents/FilterType';
import NavMenu from 'organisms/documents/NavMenu';

export default {
  name: 'CabinetDocuments',
  components: {
    FilterType,
    NavMenu,
    VerticalCard,
    DocumentCard,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      documents: [],
      showFilter: false,
      type: 'Все документы',
      direction: 'ASC',
      field: 'date',
      from: '1900-01-01T00:00:00Z',
      to: '2900-01-01T00:00:00Z',
    };
  },
  computed: {
    ...mapState('document', [
      'document',
      'status',
      'statusTitle',
      'kind',
    ]),
  },
  methods: {
    hide() {
      this.showFilter = false;
    },
    async loadData() {
      this.type = 'Все документы';
      try {
        this.documents = await this.loadDocumentViews({ field: this.field, direction: this.direction });
      } catch (error) {
        console.log('loadDocumentViews', error);
      }
    },
    changeViews() {
      this.$store.state.document.change = !this.$store.state.document.change;
      localStorage.setItem('change', JSON.stringify(this.$store.state.document.change));
    },
    sort(data) {
      if (data.field === 'date') {
        this.direction = 'ASC';
      } else {
        this.direction = 'DESC';
      }
      this.field = data.field;
      return this.loadData();
    },
    async filterDocuments(data) {
      if (data.from && data.to) {
        this.type = `с ${this.formatDate(data.from)} по ${this.formatDate(data.to)}`;
      } else {
        if (!this.document && !this.statusTitle) {
          this.type = 'Все документы';
        } else {
          if (!this.document || !this.statusTitle)
            this.type = this.document || this.statusTitle;
          else
            this.type = `${this.document}, ${this.statusTitle}`;
        }
      }
      try {
        this.documents = await this.filterDocumentViews({ kind: this.kind, from: data.from || this.from, to: data.to || this.to });
      } catch (error) {
        console.log('loadDocumentViews', error);
      }
    },
    formatDate(date) {
      date = date.split('T');
      date = date[0].replaceAll('-', '.').split('.').reverse()
.join('.');

      return date;
    },
    ...mapActions('document', ['loadDocumentViews', 'filterDocumentViews']),
  },
};
</script>

<style lang="sass" module>
  .filterContainer
    max-width: 80%

    @media screen and (max-width: 930px)
      max-width: 550px

  .container
    display: flex
    flex-direction: column
    width: 100%
    background-color: #fff

  .grid
    height: 100%
    overflow-y: auto
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    padding-left: 16px
    padding-top: 8px
</style>
