<template>
  <page :title="'Результат поиска: ' + $route.query.search">
    <v-table-new
      v-model="transformed"
      :excel-report-file-name="'Результат поиска: ' + $route.query.search"
      :loading="loading"
      :on-pagination-change="onPaginationChange"
      :page-out="resultPage.pageOut"
      :template="template"
    />
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { convertDbFormat } from 'lib/utils/date';
import { Page, VTableNew } from '@/components';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'SearchResult',
  components: { VTableNew, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      searchResult: [],
      loading: false,
      resultPage: {},
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    transformed() {
      return this.searchResultList.map((it, index) => ({
        ...it,
        index: index,
        document: { documentId: it.documentId, name: it.title },
        created: convertDbFormat(it.createdAt),
      }));
    },
    searchResultList() {
      return this.resultPage.list ?? [];
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Название',
            key: 'title',
            size: 'lg',
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Дата создания',
            key: 'created',
            sort: {},
          },
        ],
      };
    },
  },
  watch: {
    '$route.query.search'() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        this.resultPage = await this.documentsByFilterPaged({
          filter: { title: this.$route.query.search },
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (error) {
        console.log('documentsByFilterPaged', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    convertDbFormat(value) {
      return convertDbFormat(value);
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    ...mapActions('cabinet', ['documentsByFilterPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>
