<template>
  <HelpIndividual/>
</template>

<script>
import HelpIndividual from 'views/public/help/components/HelpIndividual.vue';

export default {
  name: 'Help',
  components: {
    HelpIndividual,
  },
};
</script>

<style>
</style>
