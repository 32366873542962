<template>
  <div class="page-form">

    <div class="page-form__form">
      <div class="row">
        <h1 class="col col--1of1 mb-24 page-form__title">
          Карточка потребителя (Физическое лицо)
        </h1>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <h2 class="col col--1of1 page-form__sub-title">
          Ваши данные
        </h2>
      </div>

      <!-- ФИО -->
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            clearable
            title="Фамилия"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Имя"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Отчество"
            disabled
          />
        </div>
      </div>

      <!-- ФИО в родительном падеже -->
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            clearable
            title="Фамилия (в родительном падеже)"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Имя (в родительном падеже)"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Отчество (в родительном падеже)"
            disabled
          />
        </div>
      </div>

      <!-- Тип удостоверения личности -->
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-select
            :options="[]"
            title="Тип удостоверения личности"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/]"
            clearable
            title="Серия"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            clearable
            title="Номер"
            disabled
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--2of3">
          <v-input
            clearable
            title="Кем выдан"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            clearable
            title="ИНН"
            disabled
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of3">
          <v-date-picker
            format="DD.MM.YYYY"
            title="Дата выдачи"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/]"
            clearable
            title="Код поздразделения"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            clearable
            title="СНИЛС"
            disabled
          />
        </div>
      </div>

      <!-- Почта и телефон -->
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            clearable
            title="Электронная почта"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Телефон"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            title="Дополнительный телефон"
            disabled
          />
        </div>
      </div>

      <!-- Адрес регистрации -->
      <div class="row mb-24">
        <div class="col col--2of3">
          <v-input
            clearable
            title="Адрес регистрации"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Почтовый индекс"
            disabled
          />
        </div>
      </div>

      <!-- Место жительства -->
      <div class="row mb-24">
        <div class="col col--2of3">
          <v-input
            clearable
            title="Место жительства"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            title="Почтовый индекс"
            disabled
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <!-- Банковские реквизиты -->
      <div class="row">
        <h2 class="col col--1of1 mb-24 page-form__sub-title">
          Банковские реквизиты
        </h2>
      </div>

      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
            clearable
            title="Расчетный счет"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
            clearable
            title="Корреспондентский счет"
            disabled
          />
        </div>
        <div class="col col--1of3">
          <v-input
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            clearable
            title="БИК"
            disabled
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-input
            clearable
            title="Банк"
            disabled
          />
        </div>
      </div>

      <!-- Документы -->
      <div v-if="documents.length" class="row mb-24">
        <div class="col col--1of1">
          <documents-list :documents="documents"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DocumentsList,
  VDatePicker,
  VDivider,
  VInput,
  VSelect,
} from '@/components';

export default {
  components: {
    VInput,
    VSelect,
    VDatePicker,
    VDivider,
    DocumentsList,
  },
  data() {
    return {
      documents: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";
</style>
