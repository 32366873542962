<template>
  <div>
    <navigation-bar
      :current-step="1"
      :steps="steps"
      style="margin: 16px 0"
      @open="openStep"

    />
    <div class="page-form">
      <div class="page-form__form">
        <template>
          <div class="page-form__form-header">
            <span class="page-form__form-header__title">Личные данные</span>
          </div>
          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.fullName.surname"
                disabled
                title="Фамилия"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.fullName.name"
                disabled
                title="Имя"
              />
            </div>
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.fullName.patronymic"
                disabled
                title="Отчество"
              />
            </div>
          </div>
          <div class="row mb-24">
            <div class="col col--1of3">
              <v-input
                v-model="internalUser.snils"
                disabled
                title="СНИЛС"
              />
            </div>
            <div v-if="internalUser.phoneNumber" class="col col--1of3">
              <v-input

                v-model="internalUser.phoneNumber"
                disabled
                title="Телефон"
              />
            </div>
            <div v-if="internalUser.email" class="col col--1of3">
              <v-input
                v-model="internalUser.email"
                disabled
                title="Электронная почта"
              />
            </div>
          </div>
        </template>
        <template>
          <div class="page-form__form-header">
            <span class="page-form__form-header__title">Данные сотрудника</span>
          </div>

          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="company.props.companyName"
                disabled
                title="Название компании"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="company.props.roleName"
                disabled
                title="Должность"
              />
            </div>
          </div>

          <div v-if="!completed" class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.additionalEmail"
                title="Доп. электронная почта"
              />
              <one-time-password-input
                  :is-waiting="isSendCode.additionalEmail"
                  :is-disabled="isDisableSendAdditionalEmailCode"
                  input-style="height: 40px;fontSize: 18px"
                  is-display-send-button
                  :is-error="isErrorEmailCode"
                  :required="internalUser.additionalEmail && internalUser.additionalEmail.length > 0"
                  style="margin-top: 4px"
                  vid="additionalEmailCode"
                  @code-input="(code) => codeEntered(code, 'additionalEmailCode')"
                  @code-request="codeRequest('additionalEmail')"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="internalUser.additionalPhone"
                :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                title="Доп. телефон"
              />
              <one-time-password-input
                  :is-waiting="isSendCode.additionalPhone"
                  :is-disabled="isDisableSendAdditionalPhoneCode"
                  input-style="height: 40px;fontSize: 18px"
                  is-display-send-button
                  :is-error="isErrorPhoneCode"
                  :required="internalUser.additionalPhone && internalUser.additionalPhone.length > 0"
                  style="margin-top: 4px"
                  vid="additionalPhoneCode"
                  @code-input="(code) => codeEntered(code, 'additionalPhoneCode')"
                  @code-request="codeRequest('additionalPhone')"
              />
            </div>
          </div>
          <div v-if="!completed" class="row mb-24">
            <div style="display: flex; flex-direction: column;">
              <div class="spanCheckbox" style="display: flex; padding: 0 16px; gap: 8px;">
                <div>
                  <input
                    v-model="agreement"
                    type="checkbox"
                    @change="onAgreedChanged"
                  />
                </div>
                <div>
                  <span style="color: red">*</span>
                  Я даю <a :href="policyLink" target="_blank">
                  согласие на обработку своих персональных данных
                </a> и ознакомлен(а) с
                  <a href="/policy-new/personal-data-processing-policy.pdf" target="_blank">
                    политикой обработки персональных данных
                  </a>.
                </div>&nbsp;
              </div>
            </div>
          </div>
        </template>
        <div style="margin-bottom: 12px">
          <p v-if="errorText" class="errorText">{{ errorText }}</p>
          <horizontal-progress-bar v-if="isWaitingSubmit"/>
        </div>
        <div class="page-form__form-button-row">
          <div style="width: 50%;">
            <v-button style="display: flex; align-items: center" variant="outlined-colored" @click="$router.go(-1)">
              <chevron-left style="fill: #2F82DF"/>
              Назад
            </v-button>
          </div>
          <div style="width: 50%;display: flex;flex-direction: row-reverse">
            <v-button
              :variant="agreement ? 'filled' : 'disabled'"
              @click="createEmployeeProfile"
            >
              {{submitButtonText}}
            </v-button>
          </div>
        </div>
      </div>
    </div>
    <personal-data-agreement-dialog
      :open-modal="privacyPolicyModalOpened"
      :user="{
        fullName: {
          name: internalUser.fullName.name,
          surname: internalUser.fullName.surname,
          patronymic: internalUser.fullName.patronymic,
        }
      }"
      :user-type="policyUserType"
      @close-modal="privacyPolicyModalOpened = false"
    />
  </div>
</template>

<script>
import { NavigationBar, VButton, VInput } from 'components';
import generateXml from 'lib/storage/connection-application/data';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import PersonalDataAgreementDialog from 'components/PersonalDataAgreement/PersonalDataAgreementDialog.vue';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput.vue';
import formatting from 'views/public/login/mixins/formatting';
import formattingData from 'lib/utils/formatting';

export default {
  name: 'CabinetEmployeeDataForm',
  mixins: [formatting],
  components: {
    PersonalDataAgreementDialog,
    HorizontalProgressBar,
    ChevronLeft,
    VInput,
    VButton,
    NavigationBar,
    OneTimePasswordInput,
  },
  async created() {
    this.internalUser = this.getUser;
    this.company = this.getTaskDetails;
    this.accountKind = (await this.getAccountAction({ id: this.company.props.accountId })).kind;
    this.completed = this.company.props.completed;
    this.stage = this.company.props.stage;
    this.rejected = this.company.props.rejected;
    console.log('task details', this.getTaskDetails);
  },
  data() {
    return {
      privacyPolicyModalOpened: false,
      internalUser: {},
      company: {},
      agreement: false,
      completed: false,
      stage: 'WAITING',
      isWaitingSubmit: false,
      errorText: null,
      isSendCode: {},
      accountKind: '',
      steps: [
        {
          component: 'Tasks',
          stepNumber: {
            value: 1,
            from: 2,
            title: () => 'Задачи',
          },
        },
        {
          component: 'EmployeeDataForm',
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Заполнить профиль сотрудника',
          },
        },
      ],
      isErrorEmailCode: false,
      isErrorPhoneCode: false,
    };
  },
  computed: {
    policyUserType() {
      if (this.accountKind === 'COUNTERPARTY') return 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS';
      return 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS';
    },
    policyLink() {
      if (this.accountKind === 'COUNTERPARTY') return '/policy-new/counterparty-without-rights-policy.pdf';
      return '/policy-new/without-rights-policy.pdf';
    },
    isDisableSendAdditionalEmailCode() {
      return !this.internalUser.additionalEmail || !this.isEmail(this.internalUser.additionalEmail);
    },
    isDisableSendAdditionalPhoneCode() {
      return !this.internalUser.additionalPhone || !this.isPhone(this.internalUser.additionalPhone);
    },
    submitButtonText() {
      switch (this.stage) {
        case 'REVIEW':
          return 'Профиль на рассмотрении';
        case 'REJECTED':
          return 'Создать профиль сотрудника';
        case 'APPROVED':
          return 'Профиль создан';
        case 'REVOKED':
          return 'Приглашение отменено';
        default:
          return 'Создать профиль сотрудника';
      }
    },
    ...mapGetters('user', ['getUser']),
    ...mapGetters('tasks', ['getTaskDetails']),
  },
  methods: {
    ...mapActions('user', ['setUser', 'userSwapAccount', 'sendUsernameVerificationCode', 'getAccountAction']),
    ...mapActions('document', ['documentUpload']),
    onAgreedChanged() {
      if (this.agreement) this.privacyPolicyModalOpened = true;
    },
    openStep(value) {
      if (value === 1) {
        this.$router.push('/cabinet/tasks');
      }
    },
    validateCodes() {
      let isValidCode = true;
      if (this.internalUser.additionalEmail &&
          this.internalUser.additionalEmail.length > 0 &&
          this.internalUser.additionalEmailCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
        this.isErrorEmailCode = true;

        isValidCode = false;
      }

      if (this.internalUser.additionalPhone &&
          this.internalUser.additionalPhone.length > 0 &&
          this.internalUser.additionalPhoneCode?.replace(RegExp('[ _]?', 'g'), '').length !== 4) {
        this.isErrorPhoneCode = true;

        isValidCode = false;
      }

      return isValidCode;
    },
    async createEmployeeProfile() {
      if (!this.agreement) return;

      if (!this.validateCodes()) {
        return;
      }

      const xml = generateXml.WorkerConnectionToPlatformApplication(
          this.company.props.accountId,
          this.internalUser,
          this.company.props.roleName,
          this.company.props.companyName,
          true,
      );
      try {
        this.isWaitingSubmit = true;
        this.errorText = null;
        await this.documentUpload({ xml: xml });
        await this.setUser();
        await this.userSwapAccount({ accountId: this.company.props.accountId });
        this.$router.go(-1);
      } catch (e) {
        console.log(e);
        const errorData = HTTPError.network(e);
        this.errorText = errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    codeEntered(code, field) {
      this.internalUser[field] = code;
    },
    async codeRequest(contact) {
      const isPhone = this.isPhone(this.internalUser[contact]);

      if (!isPhone && !this.isEmail(this.internalUser[contact])) {
        return;
      }
      try {
        this.$set(this.isSendCode, contact, true);
        await this.sendUsernameVerificationCode({ username: isPhone ? formattingData.unformatPhonePrefix(this.internalUser[contact]) : this.internalUser[contact] });
      } catch (e) {
        console.log(e);
        const errorData = HTTPError.network(e);

        this.vueShowNotification(
            'Ошибка отправки кода',
            `<p>${errorData.message}</p>`,
        );
      } finally {
        this.$set(this.isSendCode, contact, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.spanCheckbox {
  vertical-align: middle;
  display: flex;
  padding: 0 16px;

  input[type='checkbox'] {
    width: 18px;
    height: 18px;

    margin-right: 8px;
  }

  &TextBlock {
    align-self: center;
  }
}

.page-form__form {
  &-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }

    &__required-note {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #71757A;
      position: absolute;
      top: 0;
      right: -290px;

      &--grey {
        right: -360px;
        width: 320px;
        padding: 16px;
        border-radius: 24px;
        background-color: #F5F6F6;
      }

      @media (max-width: 1400px) {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  &-select-input {
    margin-top: 24px;
  }

  &-divider {
    margin-bottom: 24px;
    height: 1px
  }

  &-ul-note {
    color: #EB5757;
    list-style-type: disc;
    margin-top: 24px;
    margin-left: 68px;

    li {
      margin-bottom: 8px;
    }

  }

  &-sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &-button-row {
    display: flex;
    flex-direction: row;
    height: 48px;
  }

  &-error-text {
    color: red
  }

}
</style>
