<template>
  <div class="company-selector">
    <!-- TODO: нулевой сотрдник (гендир) должен отображаться по умолчанию -->
    <span class="company-selector__title">
      Компания
    </span>
    <select
      v-model="selectedCompany.inn"
      :disabled="!editable"
      class="company-selector__select"
      @change="changeHandler(selectedCompany?.inn)"
    >
      <option
        v-for="(company, index) in companies"
        :key="index"
        :value="company.inn"
      >
        {{ company.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    initCompanyInn: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedCompany: {
        inn: null,
      },
    };
  },
  watch: {
    companies: {
      handler() {
        this.update();
      },
    },
    selectedCompany: {
      handler() {
        this.$emit('select', { ...this.selectedCompany });
      },
    },
  },
  methods: {
    update() {
      if (this.selectedCompany?.inn != null && (this.companies?.length === 0 || !this.companies?.some((it) => it.inn === this.selectedCompany?.inn)))
        this.changeHandler(null);

      if (this.initCompanyInn && this.initCompanyInn !== this.selectedCompany?.inn) {
        this.changeHandler(this.initCompanyInn);
      }
      if (!this.selectedCompany?.inn && this.companies?.length) {
        // TODO костыль, чтобы exception с inn не вылезал для раздела генерации интегральных актов.
        // Если ? делать внутри changeHandler, то интерфейс зависает и ничего не работает
        if (this.companies[0]?.inn)
        this.changeHandler(this.companies[0].inn);
      }
    },
    changeHandler(companyInn) {
      if (this.selectedCompany) {
        this.selectedCompany = { ...this.companies.find((employee) => employee.inn === companyInn) };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-selector {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
  }

  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;

    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
      opacity: 1;
    }

    &_error {
      border-color: #EB5757;
    }
  }
}
</style>
