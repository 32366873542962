<template>
  <div class="bank-requisites-block">
    <div class="row mb-24">
      <h2 class="col col--1of2  page-form__sub-title" style="display: flex; align-items: center;">
        Банковские реквизиты
      </h2>
    </div>

    <div class="bank-requisites-block__requisites">
      <div v-for="(bankRequisites, idx) in value" :key="idx">
        <v-spoiler :summary="`Реквизиты в  ${bankRequisites.bankName?.length > 0 ? bankRequisites.bankName : '...' }`">
          <button v-if="isEditModeOn && value.length>1" slot="button"
                  class="bank-requisites-block__requisites__delete-btn"
                  @click="deleteRequisites(idx)">
            <cross-delete/>
          </button>
          <div class="row mb-24">
            <div class="col col--1of1">
              <v-address-search-select
                v-model="bankRequisites.bankName"
                :clearable="isEditModeOn"
                :disabled="!isEditModeOn"
                :err="errors?.bankName[idx]?.error"
                :err-text="errors?.bankName[idx]?.text"
                bank-api
                title="Банк"
                :bik="bankRequisites.bik"
                @keyup="(val) => setBankInfo(val, idx)"
              />
            </div>
          </div>
          <div class="row mb-24">
            <div class="col col--1of2">
              <v-input
                v-model="bankRequisites.settlementAccount"
                :clearable="isEditModeOn"
                :disabled="!isEditModeOn"
                :err="errors?.settlementAccount[idx]?.error"
                :err-text="errors?.settlementAccount[idx]?.text"
                :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
                title="Расчётный счёт"
              />
            </div>
            <div class="col col--1of2">
              <v-input
                v-model="bankRequisites.correspondentAccount"
                :clearable="isEditModeOn"
                :disabled="!isEditModeOn"
                :err="errors?.correspondentAccount[idx]?.error"
                :err-text="errors?.correspondentAccount[idx]?.text"
                :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
                title="Корреспондентский счёт"
              />
            </div>
          </div>
        </v-spoiler>
      </div>
      <div v-if="isEditModeOn" style="display: flex; justify-content: center">
        <v-button @click="addRequisites"
        >
          Добавить реквизиты
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { VAddressSearchSelect, VButton, VInput, VSpoiler } from 'components';
import CrossDelete from 'atoms/icons/action/CrossDelete.vue';

export default {
  name: 'BankRequisitesBlock',
  components: {
    VButton,
    CrossDelete,
    VSpoiler,
    VInput,
    VAddressSearchSelect,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isEditModeOn: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    setBankInfo(value, idx) {
      this.value[idx].bik = value.bik;
    },
    deleteRequisites(idx) {
      this.internalValue = this.internalValue.splice(idx, 1);
    },
    addRequisites() {
      this.internalValue.push({
        bik: '',
        bankName: '',
        settlementAccount: '',
        correspondentAccount: '',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";
@import "@/assets/scss/commonCardStyles";

.bank-requisites-block {

  &__title {
    text-transform: uppercase;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #C1C4C7;
  }

  &__requisites {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    &__delete-btn {
      margin-left: 16px;
      background: inherit;
    }

    &__bank-title {
      display: inline-block;
      font-size: 14px;
      color: #71757a;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 4px;
    }
  }
}
</style>
