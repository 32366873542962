<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
        <span>Наименование и адрес объекта</span>
      </h2>
    </div>

    <div :class="$style.blockContent">
      <c-select
        v-model="objectLocation.objectType"
        :error="errors.objectType"
        :options="objectTypeList"
        label="Наименование объекта *"
        role="objectType"
      />
      <p :class="$style.inputNote">так же, как и в свидетельстве о собственности</p>

      <c-input
        v-if="objectLocation.objectType.value === 'Иное'"
        v-model="objectLocation.otherObjectType"
        label="Иное:"
      />
    </div>

    <div v-if="objectLocation.objectType.value === 'Нежилое помещение'" :class="$style.blockContent">
      <c-input
          v-model="objectLocation.objectArea"
          :error="errors.objectArea"
          :validation="$validate.isNotEmptyString"
          label="Площадь объекта, кв. м. *"
          role="objectArea"
      />
      <p :class="$style.inputNote">Для нежилого помещения площадь объекта присоединения является обязательной</p>
    </div>

    <div :class="$style.blockContent">
      <p :class="$style.titleAddress" role="objectAddress">Адрес *</p>
      <v-address-search-select
        v-model="objectLocation.objectAddress"
        :rules-messages="{required: 'Обязательно для заполнения.'}"
        cinput
        clearable
        rules="required"
        :validation-mount="errors.objectAddress?.error"
        @keyup="handleKeyupOnObjectAddress"
      />
      <p :class="$style.inputNote">как в документе подтверждающем собственность</p>
    </div>

    <div :class="$style.blockContent">
      <c-input
        v-model="objectLocation.additionalInformation"
        label="Дополнительные сведения о расположении объекта"
        role="additionalInformation"
      />
    </div>

    <div id="boundariesElement" :class="$style.blockContent">
      <p :class="$style.inputTitle">
        <span>Кадастровый номер:</span>
        <tooltip-helper offset="6px" placement="bottom">
          <div :class="$style.tooltip">
            <p>Кадастровый номер участка, к которому планируется подвести электроэнергию. Узнать кадастровый номер
              участка можно в документах о собственности или на сайте <a href="http://pkk.rosreestr.ru"
                                                                         rel="noopener noreferrer"
                                                                         target="_blank">Росреестра</a>.</p>
          </div>
        </tooltip-helper>
      </p>
      <div :class="$style.grid2Column">
        <div>
          <c-input
            v-model="objectLocation.landCadastralNumber"
            :error="errors.landCadastralNumber"
            error-text="Кадастровый номер должен состоять из 13 цифр или 15 цифр"
            :mask="[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/, /\d/, /\d/,]"
            :validation="(val) => $validate.isEmptyString(val) || $validate.landCadastralNumber(val)"
            label="земельного участка"
            placeholder="XX:XX:XXXXXXX:XXXX"
            role="landCadastralNumber"
          />
        </div>
        <div>
          <c-input
            v-model="objectLocation.buildingCadastralNumber"
            :error="errors.buildingCadastralNumber"
            error-text="Кадастровый номер должен состоять из 13 цифр или 15 цифр"
            :mask="[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/, /\d/, /\d/,]"
            :validation="(val) => $validate.isEmptyString(val) || $validate.landCadastralNumber(val)"
            label="объекта подключения"
            placeholder="XX:XX:XXXXXX:XXXX"
            role="buildingCadastralNumber"
          />
        </div>
      </div>
    </div>

    <!--    <div :class="$style.blockContent">-->
    <!--      <p>Если у вас нет кадастрового номера, обведите территорию объекта на карте</p>-->
    <!--    </div>-->

    <!--    <template>-->
    <!--      <t-gis-map-->
    <!--        :center="[this.objectLocation.latitude, this.objectLocation.longitude]"-->
    <!--        :points="[this.objectLocation.latitude, this.objectLocation.longitude]"-->
    <!--        :styles="{ width: '100%', height: '400px' }"-->
    <!--        :zoom="getZoom"-->
    <!--        role="map"-->
    <!--        @zoom="SET_ZOOM"-->
    <!--        @position="handleChangePosition"-->
    <!--      ></t-gis-map>-->
    <!--    </template>-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import throttle from 'lib/utils/throttle';

import { VAddressSearchSelect } from 'components';
import StepCounter from '../components/StepCounter';

const LOCATION_ZOOM = 16;

const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function errorCallback(error) {
  console.log(error);
  // switch(error.code) {
  //   case error.PERMISSION_DENIED:
  //     x.innerHTML = "User denied the request for Geolocation."
  //     break;
  //   case error.POSITION_UNAVAILABLE:
  //     x.innerHTML = "Location information is unavailable."
  //     break;
  //   case error.TIMEOUT:
  //     x.innerHTML = "The request to get user location timed out."
  //     break;
  //   case error.UNKNOWN_ERROR:
  //     x.innerHTML = "An unknown error occurred."
  //     break;
  // }
}

export default {
  name: 'ObjectLocation',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    // TGisMap: () => import('organisms/common/TGisMap'),
    CSelect: () => import('atoms/common/CSelect'),
    VAddressSearchSelect,
    StepCounter,
  },
  props: {
    objectLocation: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  created() {
    // TODO: revert error style after scroll (for not change object address)
    /* EVENT_BUS.$on('VALIDATE', value => {
      this.errors[value] = { error: true };
    });*/

    /* EVENT_BUS.$on('CLEAR_DATA', () => {
      this.step.initData({...INITIAL_DATA});
    });

    this.step.initData({...INITIAL_DATA});
    this.step.setData(this.onlyMicrogenerator);*/
    // this.initializeMap();
  },
  mounted() {
    this.loadObjectPosition(this.objectLocation.objectAddress);
  },
  data() {
    return {
      isLoadedObjectAddress: false,
      otherObjectType: '',
      isInitializeMap: false,
    };
  },
  computed: {
    ...mapGetters('tgis', ['getZoom']),
    ...mapGetters('dataLists', ['objectTypeList', 'gridCompanyList']),
  },
  watch: {
    // objectLocation: {
    //   deep: true,
    //   immediate: true,
    //   handler: 'initialize',
    // },
    errors: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('watch errors', val);
      },
    },
  },
  methods: {
    handleKeyupOnObjectAddress: throttle(function(val) {
      console.log('VALUE', val);
      this.loadObjectPosition(val);
      this.SET_ZOOM(LOCATION_ZOOM);
    }, 1500),

    setPosition({ latitude, longitude }) {
      this.objectLocation.latitude = latitude;
      this.objectLocation.longitude = longitude;
    },

    setCustomerGeolocation() {
      this.isLoadedObjectAddress = false;

      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        this.setPosition({ latitude, longitude });
        this.SET_ZOOM(LOCATION_ZOOM);
      }, errorCallback, GEOLOCATION_OPTIONS);

      this.isLoadedObjectAddress = true;
    },

    async initializeMap() {
      const { objectAddress } = this.objectLocation;

      if (this.$validate.isEmptyString(objectAddress)) {
        this.setCustomerGeolocation();
        return;
      }

      this.loadObjectPosition(objectAddress);
    },

    async loadObjectAddress({ latitude = null, longitude = null }) {
      const coords = {
        latitude: latitude || this.objectLocation.latitude,
        longitude: longitude || this.objectLocation.longitude,
      };

      // this.isLoadedObjectAddress = false;

      try {
        const data = await this.geocodeByLocation(coords);
        console.log(data);

        if (this.$validate.isNull(data)) {
          return;
        }

        this.objectLocation.objectAddress = data.full_name;

        this.setPosition({
          latitude: data.point.lat,
          longitude: data.point.lon,
        });
        this.SET_ZOOM(LOCATION_ZOOM);

        this.setSuppliers({ latitude: data.point.lat, longitude: data.point.lon });
      } catch (error) {
        console.log(error);
      }

      // this.isLoadedObjectAddress = true;
    },

    async loadObjectPosition(address = null) {
      const objectAddress = address || this.objectLocation.objectAddress;

      // this.isLoadedObjectAddress = false;

      try {
        const data = await this.geocodeByAddress(objectAddress);
        console.log(data);

        if (this.$validate.isNull(data)) {
          this.setPosition({
            latitude: '',
            longitude: '',
          });

          // TODO временно, пока не разберемся с геокодинком в IE (на любой адрес показывает неизвестную территорию)
          this.setSuppliers({ latitude: '55.753857', longitude: '37.619153' });

          return;
        }

        this.setPosition({
          latitude: data.latitude,
          longitude: data.longitude,
        });
        this.SET_ZOOM(LOCATION_ZOOM);

        this.setSuppliers({ latitude: data.latitude, longitude: data.longitude });
      } catch (error) {
        console.log(error);
      }

      // this.isLoadedObjectAddress = true;
    },

    handleChangePosition: throttle(function(coords) {
      this.loadObjectAddress(coords);
    }, 500),

    initialize() {
      if (!this.isInitializeMap) {
        this.initializeMap();
        this.isInitializeMap = true;
      }
      if (this.gridCompanyList.length === 0 && this.objectLocation.objectAddress?.length > 0) {
        this.loadObjectPosition(this.objectLocation.objectAddress);
      }
    },
    ...mapActions('tgis', ['geocodeByLocation', 'geocodeByAddress']),
    ...mapActions('dataLists', ['setSuppliers']),
    ...mapMutations('tgis', ['SET_ZOOM']),
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +step-spacing

.titleContainer
  +questionnaries-container
  +base-spacing

.title
  display: flex

.titleAddress
  margin-bottom: 8px

.grid2Column
  +questionnaries-container
  +grid-2-column

.blockContent
  +questionnaries-container
  +base-spacing

.inputNote
  +input-note-text
  margin-top: 8px

.inputTitle
  +label-text
  display: flex
  align-items: center

  span
    margin-right: 10px

.tooltip
  width: 380px

  p
    font-family: Roboto
    font-weight: 400
    font-size: 12px
    line-height: 18px
    color: #000

  a
    +base-link
</style>
