export const esoOek1AdditionPattern = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  consumer: {
    name: {
      colIndex: 11,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 11,
    },
    inn: {
      colIndex: 12,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 12,
    },
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 13,
      rules: 'required',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Номер',
      col: 13,
    },
    date: {
      colIndex: 14,
      rules: 'required|date',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Дата',
      col: 14,
    },
  },
  electricityPurchaseSaleContractInfo: {
    number: {
      colIndex: 15,
      rules: 'required',
      title: 'Сведения о договоре купли-продажи электроэнергии. Номер договора',
      col: 15,
    },
    date: {
      colIndex: 16,
      rules: 'required|date',
      title: 'Сведения о договоре купли-продажи электроэнергии. Дата договора',
      col: 16,
    },
  },
  serviceLocation: {
    colIndex: 17,
    rules: 'required',
    title: 'Адрес местоположения энергопринимающего устройства',
    col: 17,
  },
  meterPlace: { colIndex: 18, rules: 'required', title: 'Место установки прибора учёта', col: 18 },
  voltageLevel: { colIndex: 19, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 19 },
  tariffVoltageLevel: {
    colIndex: 20,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 20,
  },
  meter: {
    rules: 'required',
    id: { colIndex: 21, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. ID ПУ', col: 21 },
    type: { colIndex: 22, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. Тип', col: 22 },
    serial: {
      colIndex: 26,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта',
      col: 26,
    },
    accuracyClass: {
      colIndex: 25,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности',
      col: 25,
    },
    ratedCurrent: { colIndex: 24, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. I ном.', col: 24 },
    nominalVoltage: { colIndex: 23, ruled: 'maxLength255', title: 'Прибор учёта электрической энергии. Uном.', col: 23 },
    calibrationDate: {
      colIndex: 27,
      rules: 'date|lessCurrent',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 27,
    },
    calibrationInterval: {
      colIndex: 28,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет',
      col: 28,
    },
    nextCalibrationDate: {
      colIndex: 29,
      rules: 'date|nextCalibrationDate',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 29,
    },
  },
  currentTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 30, rules: 'maxLength255', title: 'Трансформаторы тока. Тип', col: 30 },
    transformationRatio: { colIndex: 32, rules: 'ktNumber', title: 'Трансформаторы тока. Ктт', col: 32 },
    accuracyClass: { colIndex: 31, rules: 'maxLength255', title: 'Трансформаторы тока. Класс точности', col: 31 },
    calibrationDate: { colIndex: 33, rules: 'date|lessCurrent', title: 'Трансформаторы тока. Дата поверки', col: 33 },
    calibrationInterval: {
      colIndex: 34,
      rules: 'number',
      title: 'Трансформаторы тока. Межповерочный интервал, лет',
      col: 34,
    },
  },
  potentialTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 35, rules: 'maxLength255', title: 'Трансформаторы напряжения. Тип', col: 44 },
    transformationRatio: { colIndex: 37, rules: 'ktNumber', title: 'Трансформаторы напряжения. Ктн', col: 37 },
    accuracyClass: { colIndex: 36, rules: 'maxLength255', title: 'Трансформаторы напряжения. Класс точности', col: 36 },
    calibrationDate: {
      colIndex: 38,
      rules: 'date|lessCurrent',
      title: 'Трансформаторы напряжения. Дата поверки',
      col: 38,
    },
    calibrationInterval: {
      colIndex: 39,
      rules: 'number',
      title: 'Трансформаторы напряжения. Межповерочный интервал, лет',
      col: 39,
    },
  },
  accountingRatio: { colIndex: 40, rules: 'required|number', title: 'Расчётный коэффициент', col: 40 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 42,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 42,
    },
    variable: {
      colIndex: 41,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 41,
    },
  },
  kind: {
    colIndex: 43,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный)',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 43,
  },
  metersOwner: {
    colIndex: 44,
    rules: 'required|allowedValues',
    title: 'Собственник средств учёта (И1/И2 - Исполнитель, ССО - смежная сетевая организация, ПЭ - производитель электрической энергии)',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['И1', 'И2', 'И-1', 'И-2', 'ССО', 'ПЭ'],
    col: 44,
  },
  measurementsReadingKind: {
    colIndex: 45,
    rules: 'required|allowedValues',
    title: 'Способ снятия показаний (А - автоматический ввод через ИСУ, Р - ручной ввод)',
    listName: 'measurementsReadingKindList',
    allowedValues: ['А', 'Р'],
    col: 45,
  },
  remarks: { colIndex: 46 },
  sourceContractInfo: {
    number: {
      colIndex: 47,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 47,
    },
    date: {
      colIndex: 48,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 48,
    },
  },
};

export const esoOek2AdditionPattern = {
  rowNumberStart: 4,
  objectName: { colIndex: 2 },
  supplyPointName: {
    switchgear: { colIndex: 3, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 3 },
    feederCell: { colIndex: 4, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 4 },
    borderName: { colIndex: 5, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 5 },
    mainSubstationNumber: { colIndex: 6, rules: 'required|maxLength1024', title: '№ ПЦ', col: 6 },
    feeder: { colIndex: 7, rules: 'maxLength1024', title: '№ фидера', col: 7 },
    distributionSubstation: { colIndex: 8, rules: 'maxLength1024', title: '№ РП', col: 8 },
    line: { colIndex: 9, rules: 'maxLength1024', title: '№ Линии', col: 9 },
    transformationSubstation: { colIndex: 10, rules: 'maxLength1024', title: '№ ТП', col: 10 },
    other: { colIndex: 11, rules: 'maxLength1024', title: 'иное', col: 11 },
  },
  consumer: {
    name: {
      colIndex: 12,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 12,
    },
    inn: {
      colIndex: 13,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 13,
    },
  },
  connectionAct: {
    number: {
      colIndex: 14,
      rules: 'required|maxLength255',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Номер',
      col: 14,
    },
    date: {
      colIndex: 15,
      rules: 'required|date',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Дата',
      col: 15,
    },
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 16,
      rules: 'required',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Номер',
      col: 16,
    },
    date: {
      colIndex: 17,
      rules: 'required|date',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Дата',
      col: 17,
    },
  },
  electricityPurchaseSaleContractInfo: {
    number: {
      colIndex: 18,
      rules: 'required',
      title: 'Сведения о договоре купли-продажи электроэнергии. Номер договора',
      col: 18,
    },
    date: {
      colIndex: 19,
      rules: 'required|date',
      title: 'Сведения о договоре купли-продажи электроэнергии. Дата договора',
      col: 19,
    },
  },
  serviceLocation: {
    colIndex: 20,
    rules: 'required',
    title: 'Адрес местоположения энергопринимающих устройств',
    col: 20,
  },
  voltageLevel: { colIndex: 21, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 21 },
  tariffVoltageLevel: {
    colIndex: 22,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 22,
  },
  reliabilityClass: {
    colIndex: 23,
    rules: 'required|allowedValues',
    listName: 'reliabilityClassListWithShortenedNames',
    allowedValues: ['I', 'II', 'III'],
    title: 'Категория надёжности электроснабжения',
    col: 23,
  },
  maxPowerForPoints: {
    colIndex: 24,
    rules: 'number',
    title: 'Величина максимальной мощности по каждой точке присоединения/поставки, МВт',
    col: 24,
  },
  declaredMaxPowerForPoints: {
    colIndex: 25,
    rules: 'number',
    title: 'Величина заявленной мощности по каждой точке присоединения, МВт',
    col: 25,
  },
  technicalArmorValue: { colIndex: 26 },
};
