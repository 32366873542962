<template>
  <page title="Добавление сотрудника компании" class="company-employees-add">
    <template #default>
      <v-form @submit="onSubmit">
        <div class="page-form__form">
          <user-info
            v-model="user"
            :company="companyName"
            editable
            is-update-role
            type="EMPLOYEE_WITHOUT_SIGNING_RIGHTS"
          />
          <new-user-agreements
              :user="user"
              is-employees
              @updateAgreements="updateAgreements"
          />

          <div v-if="errorText || isWaitingSubmit" class="row mb-24">
            <p v-if="errorText" class="errorText">{{ errorText }}</p>
            <horizontal-progress-bar v-if="isWaitingSubmit"/>
          </div>

          <div class="row mb-44">
            <div class="col col--1of1 page-form__changes-buttons">
              <v-button
                  class="page-form__changes-buttons__back"
                  type="button"
                  variant="outlined-colored"
                  @click="goBack"
              >
                <chevron-left />
                <span>Назад</span>
              </v-button>
              <v-button class="page-form__changes-buttons__next" type="submit" :disabled="isWaitingSubmit || !isValid">
                <span>Зарегистрировать</span>
                <chevron-right />
              </v-button>
            </div>
          </div>
        </div>
      </v-form>
    </template>
  </page>
</template>

<script>
import { Page, VButton, VForm } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import UserInfo from 'components/UserInfo/UserInfo.vue';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ChevronRight from 'atoms/icons/navigation/ChevronRight.vue';
import NewUserAgreements from 'organisms/registration/individual-connection/NewUserAgreements';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';

export default {
  name: 'CompanyEmployeesAdd',
  components: {
    Page,
    UserInfo,
    VButton,
    ChevronRight,
    ChevronLeft,
    NewUserAgreements,
    HorizontalProgressBar,
    VForm,
  },
  created() {
    if (!this.isPlatformWorker || !this.$route.params?.accountId) {
      this.$router.back();
    }
  },
  data() {
    return {
      companies: [],
      selectedAccountId: undefined,
      user: {
        id: '',
        firstName: '',
        secondName: '',
        patronymic: '',
        firstNameGenitive: '',
        secondNameGenitive: '',
        patronymicGenitive: '',
        passport: {
          series: '',
          number: '',
          issueDate: '',
          issuer: '',
          subdivisionCode: '',
        },
        telegram: '',
        insuranceNumber: '',
        phone: '',
        additionalPhoneNumber: '',
        email: '',
        address: '',
        registrationAddress: '',
        inn: '',
        bankRequisites: {
          bankName: '',
          bik: '',
          settlementAccount: '',
          correspondentAccount: '',
        },
        birthDate: '',
        subdivision: '',
        role: '',
      },
      privacyPolicy1: false,
      privacyPolicy2: false,
      privacyPolicy3: false,
      privacyPolicy4: false,
      isWaitingSubmit: false,
      errorText: '',
      applicantsConsent: [],
      applicantsDocuments: [],
    };
  },
  computed: {
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.company-employees';
    },
    companyName() {
      return {
        name: this.$route.params?.companyName,
      };
    },
    isValid() {
      return this.privacyPolicy1 && this.privacyPolicy2 && this.privacyPolicy3 && this.applicantsConsent.length > 0 &&
          this.applicantsDocuments.length > 0;
    },
    ...mapGetters('user', ['isPlatformWorker']),
  },
  methods: {
    updateAgreements(agreements) {
      this.privacyPolicy1 = agreements.privacyPolicy1;
      this.privacyPolicy2 = agreements.privacyPolicy2;
      this.privacyPolicy3 = agreements.privacyPolicy3;
      this.privacyPolicy4 = agreements.privacyPolicy4;
      this.applicantsConsent = agreements.applicantsConsent;
      this.applicantsDocuments = agreements.applicantsDocuments;
    },
    async onSubmit() {
      this.isWaitingSubmit = true;
      this.errorText = '';

      const xml = generateXml.IndividualConnectionToPlatformApplication(
          this.user,
          {
            agreement: {
              userAgreement: this.privacyPolicy3,
              personalDataAgreement: this.privacyPolicy1,
              privacyPolicy: this.privacyPolicy2,
              marketing: this.privacyPolicy4,
            },
            agreementScan: this.applicantsConsent,
            documentScan: this.applicantsDocuments,
            companyAccountId: this.$route.params?.accountId,
          },
      );

      try {
        await this.registerUserRequest({ password: this.user.password, xml: xml });

        this.setNotification({ message: 'Сотрудник успешно добавлен', type: 'Ok' });
        await this.$router.push('/cabinet/company-employees');
      } catch (error) {
        console.log(error);
        const errorData = HTTPError.network(error);
        this.errorText = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    goBack() {
      this.$router.back();
    },
    ...mapActions('user', ['registerUserRequest', 'setNotification']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.company-employees-add {
  .page-form__changes-buttons {
    &__back, &__next {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 40px;
    }
    &__back {
      svg {
        fill: #2F82DF;
      }
    }
    &__next {
      svg {
        fill: #FFFFFF;
      }
    }
  }
}
</style>
