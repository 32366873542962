<template>
  <div class="form-contract-paid-for">
    <div class="row mb-24">
      <p class="col col--1of1 form-contract-paid-for__title">Авансовые и основной платежи</p>
    </div>

    <v-table-new
      v-model="tableWithOptions"
      hide-overflow
      :pagination-show="false"
      :template="contractInfoTableTemplate"
      hide-spoiler
      @on-cell-click="onCellClickHandler"
      @on-cell-input="onCellInputHandler"
    />
    <div class="row" style="margin-top: 16px">
      <div class="col col--1of3" />
      <div class="col col--1of3">
        <button class="form-contract-paid-for__button" @click.prevent="addRow">
          Добавить платёж
        </button>
      </div>
      <div class="col col--1of3" />
    </div>
  </div>
</template>

<script>
import { VTableNew } from 'components';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ContractPaidFor',
  components: { VTableNew },
  created() {
    if (!this.paymentDates.length) this.addRow();
  },
  computed: {
    canRowsBeDeleted() {
      return this.tableWithOptions.length > 1;
    },
    tableWithOptions() {
      return this.paymentDates.map((it) => ({
        ...it,
        options: {
          tariff: this.paymentTariffsList,
          from: this.contractSideSelector.filter((side) => side.value !== it.to),
          to: this.contractSideSelector.filter((side) => side.value !== it.from),
          kind: this.paymentKindList,
        },
        validationRules: {
          day: 'required|numeric|min_value:1|max_value:28',
          percent: 'required|numeric|min_value:0|max_value:100',
          from: 'required',
          to: 'required',
        },
        validationMessages: {
          day: {
            required: 'Обязательно для заполнения',
            numeric: 'Должно быть числом',
            min_value: 'Должно быть больше или равно 1',
            max_value: 'Должно быть меньше или равно 28',
          },
          percent: {
            required: 'Обязательно для заполнения',
            numeric: 'Должно быть числом',
            min_value: 'Должно быть больше или равно 0',
            max_value: 'Должно быть меньше или равно 100',
          },
          from: {
            required: 'Обязательно для заполнения',
          },
          to: {
            required: 'Обязательно для заполнения',
          },
        },
      }));
    },
    contractInfoTableTemplate() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '25px',
              padding: '6px',
              borderTop: borderStyle,
              borderLeft: borderStyle,
            },
            tdStyle: {
              width: '25px',
              padding: '6px',
              borderLeft: borderStyle,
            },
          },
          {
            label: 'Тариф',
            key: 'tariff',
            type: 'select',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '100px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '125px',
              padding: '6px',
            },
          },
          {
            label: 'От',
            key: 'from',
            type: 'select',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
          },
          {
            label: 'К',
            key: 'to',
            type: 'select',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
          },
          {
            label: 'Вид платежа',
            key: 'kind',
            type: 'select',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '6px',
            },
          },
          {
            label: 'Число месяца платежа',
            key: 'day',
            type: 'input',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '55px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '55px',
              padding: '6px',
            },
          },
          {
            label: 'Включительно',
            key: 'isWithoutDate',
            type: 'checkbox',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '50px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '50px',
              padding: '6px',
            },
          },
          {
            label: 'Процент',
            key: 'percent',
            type: 'input',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '60px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '60px',
              padding: '6px',
            },
          },
          {
            key: 'delete',
            type: 'crossDelete',
            customCheck: () => !this.canRowsBeDeleted,
            thStyle: {
              borderTop: borderStyle,
              borderRight: borderStyle,
              maxWidth: '50px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '50px',
              borderRight: borderStyle,
              padding: '6px',
            },
          },
        ],
      };
    },
    paymentDates: {
      get() {
        return this.getContract.paymentDates;
      },
      set(value) {
        this.setPaymentDates({ dates: value });
      },
    },
    contractSideSelector() {
      return this.getContract.selectedTemplate.sides.map((it) => ({
        value: it.contractSide,
        label: this.getContractSideName(it.contractSide),
      }));
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
    ...mapGetters('dataLists', ['paymentTariffsList', 'paymentKindList']),
  },

  methods: {
    getContractSideName(side) {
      return {
        PERFORMER_FIRST: 'Исполнитель-1',
        PERFORMER_SECOND: 'Исполнитель-2',
        SUPPLIER: 'Заказчик',
        PERFORMER: 'Исполнитель',
      }[side];
    },
    onCellClickHandler(cell) {
      switch (cell.key) {
        case 'delete': {
          this.deleteRow(cell.row.index);
        }
      }
    },
    onCellInputHandler(cell) {
      switch (cell.key) {
        case 'percent':
          this.paymentDates[cell.row.index - 1].percent = cell.value;
          break;
        case 'day':
          this.paymentDates[cell.row.index - 1].day = cell.value;
          break;
        case 'isWithoutDate':
          this.paymentDates[cell.row.index - 1].isWithoutDate = cell.value;
          break;
        case 'tariff':
          this.paymentDates[cell.row.index - 1].tariff = cell.value;
          break;
        case 'from':
          this.paymentDates[cell.row.index - 1].from = cell.value;
          break;
        case 'to':
          this.paymentDates[cell.row.index - 1].to = cell.value;
          break;
        case 'kind':
          this.paymentDates[cell.row.index - 1].kind = cell.value;
          break;
      }
      console.log(this.tableWithOptions);
    },
    addRow() {
      this.paymentDates.push(
        {
          kind: 'PRE_PAYMENT',
          from: '',
          to: '',
          tariff: 'BOILER',
          day: '1',
          percent: '50',
          isWithoutDate: false,
        },
      );
    },
    deleteRow(index) {
      this.paymentDates = this.paymentDates.filter((row, idx) => idx !== index - 1);
    },
    ...mapActions(
'electricityTransmissionContract',
      [
        'setPaymentDates',
      ],
),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";

.form-contract-paid-for {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;
  }


  &__button {
    border-radius: 24px;
    padding: 10px 25px;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

}
</style>
