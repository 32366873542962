<template>
  <v-table-new
    v-model="transformedPoints"
    :excel-report-file-name="'Точки учёта. ' + kindNameFromKey"
    :loading="loading"
    :action-block-items="actionBlockItems"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="pointsPage.pageOut"
    :table-name="'CabinetPoints_' + additionKind"
    :template="template"
    :title="kindNameFromKey"
    @on-cell-click="cellClickHandler"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  formatPointIsDuplicate,
  formatPointKind,
  formatPointTariffCategory,
  getPointStatusLabelValueList,
} from 'lib/utils/formatXmlType';
import { convertDbFormat } from 'lib/utils/date';
import { VTableNew } from '@/components';
import { exportToExcel } from 'utils/excel';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'CabinetPoints',
  components: { VTableNew },
  props: {
    additionKind: {
      type: String,
      required: true,
    },
    exportToExcelAction: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pointsPage: {},
      loading: false,
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    transformedPoints() {
      return this.points.map(this.transformed);
    },
    template() {
      const template = {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: 'Уникальный идентификатор точки',
            key: 'uuid',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
            filter: {},
          },
          {
            label: 'Тип точки',
            key: 'pointKind',
            size: 'sm',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 3,
              minSreenWidthTooltip: 1024,
            },
            sort: {},
            filter: {
              type: 'select',
              values: [{ label: 'Расчётный', value: 'ACCOUNTING' }, { label: 'Контрольный', value: 'CONTROL' }],
            },
          },
          {
            label: 'Статус',
            key: 'pointStatus',
            type: 'status',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'md',
            sort: {},
            filter: { type: 'select', values: getPointStatusLabelValueList() },
          },
          {
            label: 'ИКТС',
            key: 'externalId',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Тип занесения показаний',
            key: 'transformedMeasurementsReadingKind',
            size: 'md',
            tooltip: {
              maxLength: 13,
            },
            sort: {},
          },
          {
            label: 'Собственник средств учёта',
            key: 'transformedOwnerAccountingFacilities',
            size: 'md',
            tooltip: {
              maxLength: 11,
            },
            sort: {},
          },
          {
            label: 'Место установки ПУ',
            key: 'meterPlace',
            size: 'md',
            tooltip: {
              maxLength: 5,
            },
            sort: {},
          },
          {
            label: 'Дубликат',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            key: 'isDuplicate',
            size: 'sm',
            sort: {},
          },
          {
            label: 'АТП/АРБП. Номер акта',
            key: 'delimitationActNumber',
            size: 'md',
            sort: {},
            tooltip: {
              maxLength: 9,
            },
            filter: {},
          },
          {
            label: 'АТП/АРБП. Дата акта',
            key: 'transformedDelimitationActDate',
            size: 'sm',
            tooltip: {
              maxLength: 8,
            },
            sort: { type: 'date' },
          },
          {
            label: 'Расчётный коэффициент',
            key: 'accountingRatio',
            type: 'number',
            size: 'sm',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 9,
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Величина максимальной мощности, МВт',
            key: 'maxPower',
            type: 'number',
            size: 'sm',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 8,
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Потери ЭЭ, если прибор установлен не на границе, %',
            key: 'variableLoss',
            type: 'number',
            size: 'sm',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 9,
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Потери ЭЭ, если прибор установлен не на границе, кВтч',
            key: 'constLoss',
            type: 'number',
            size: 'sm',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 9,
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Центр питания',
            key: 'pointInfo.mainSubstation',
            thStyle: {
              minWidth: '50px',
            },
            tooltip: {
              maxLength: 5,
              minSreenWidthTooltip: 1024,
            },
            size: 'md',
          },
          {
            label: 'Наименование РУ питающего центра',
            key: 'pointInfo.switchGear',
            thStyle: {
              minWidth: '75px',
            },
            tooltip: {
              maxLength: 15,
            },
            size: 'sm',
          },
          {
            label: '№ ячейки (фидера)',
            key: 'pointInfo.feederCell',
            thStyle: {
              minWidth: '50px',
            },
            tooltip: {
              maxLength: 8,
            },
            size: 'sm',
          },
          {
            label: '№ ПЦ',
            key: 'pointInfo.mainSubstationNumber',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: '№ фидера',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            key: 'pointInfo.feeder',
            size: 'sm',
          },
          {
            label: 'Наименование границы субъекта в Точке приема',
            thStyle: {
              minWidth: '100px',
            },
            tooltip: {
              maxLength: 12,
            },
            key: 'pointInfo.borderName',
            size: 'md',
          },
          {
            label: '№ РП',
            key: 'pointInfo.distributionSubstation',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: '№ линии',
            key: 'pointInfo.line',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: '№ ТП',
            key: 'pointInfo.transformationSubstation',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: 'Иное',
            key: 'pointInfo.other',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'lg',
          },
          {
            label: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
            key: 'sourceContractNumber',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 5,
            },
            sort: {},
          },
          {
            label: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
            key: 'sourceContractDate',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 4,
            },
            sort: {},
          },
          {
            label: 'Ценовая категория',
            key: 'tariffCategory',
            thStyle: {
              minWidth: '100px',
            },
            size: 'sm',
            sort: {},
          },
          {
            label: 'Название компании',
            key: 'supplier.name',
            size: 'lg',
            thStyle: {
              minWidth: '50px',
            },
            filter: {},
          },
          {
            label: 'ИНН',
            key: 'supplier.inn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'md',
            filter: {},
          },
          {
            label: 'КПП',
            key: 'supplier.kpp',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'md',
          },
          {
            label: 'ОГРН',
            key: 'supplier.ogrn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'lg',
          },
          {
            label: 'Прибор учёта. Номер',
            key: 'meter.serialNumber',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
            filter: { label: 'Прибор учёта. Номер' },
          },
          {
            label: 'Прибор учёта. Класс точности',
            key: 'meter.accuracyClass',
            thStyle: {
              minWidth: '56px',
            },
            size: 'sm',
          },
          {
            label: 'Прибор учёта. Номинальный ток, A',
            key: 'meter.ratedCurrent',
            type: 'number',
            thStyle: {
              minWidth: '82px',
            },
            size: 'sm',
          },
          {
            label: 'Прибор учёта. Номинальное напряжение, В',
            type: 'number',
            key: 'meter.nominalVoltage',
            thStyle: {
              minWidth: '83px',
            },
            size: 'sm',
          },
          {
            label: 'Прибор учёта. Дата поверки',
            key: 'transformedMeterCalibrationDate',
            size: 'md',
            thStyle: {
              minWidth: '68px',
            },
            sort: {},
          },
          {
            label: 'Прибор учёта. Дата следующей поверки',
            key: 'transformedMeterNextCalibrationDate',
            size: 'md',
            thStyle: {
              minWidth: '111px',
            },
            sort: {},
          },
          {
            label: 'Прибор учёта. Межповерочный интервал, лет',
            type: 'number',
            key: 'meter.calibrationInterval',
            thStyle: {
              minWidth: '95px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор тока. Тип',
            key: 'currentTransformer.type',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'lg',
          },
          {
            label: 'Трансформатор тока. Класс точности',
            key: 'currentTransformer.accuracyClass',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор тока. Ктт',
            key: 'currentTransformer.transformationRatio',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: 'Трансформатор тока. Дата поверки',
            key: 'transformedCalibrationDate',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор тока. Дата очередной поверки',
            key: 'transformedNextCalibrationDate',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор напряжения. Тип',
            key: 'potentialTransformer.type',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'lg',
          },
          {
            label: 'Трансформатор напряжения. Класс точности',
            key: 'potentialTransformer.accuracyClass',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор напряжения. Ктн',
            key: 'potentialTransformer.transformationRatio',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
          },
          {
            label: 'Трансформатор напряжения. Дата поверки',
            key: 'transformedPotentialTransformerCalibrationDate',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Трансформатор напряжения. Дата очередной поверки',
            key: 'transformedPotentialTransformerNextCalibrationDate',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
        ],
      };

      if (this.additionKind === 'DELIVERY_POINT') {
        template.headers.push({
          label: 'Наименование отделения',
          key: 'retailDepartmentName',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
        });
      }

      template.headers.push({
        key: 'followLink',
        type: 'followLink',
      });

      return template;
    },
    points() {
      return this.pointsPage.list ?? [];
    },
    transformFilter() {
      const result = { additionKind: this.additionKind };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        let convertFilterKey = '';

        if (filterKey === 'supplier.name') {
          convertFilterKey = 'supplierName';
        } else if (filterKey === 'supplier.inn') {
          convertFilterKey = 'supplierInn';
        } else if (filterKey === 'meter.serialNumber') {
          convertFilterKey = 'meterSerial';
        } else if (filterKey === 'uuid') {
          convertFilterKey = 'id';
        } else {
          convertFilterKey = filterKey;
        }

        if (filterValue.length !== 0) {
          result[convertFilterKey] = filterValue;
        }
      });

      return result;
    },
    nameFromAdditionKind() {
      if (this.additionKind === 'RECEIVING_POINT') {
        return '. Приём';
      }

      if (this.additionKind === 'DELIVERY_POINT') {
        return '. Поставка';
      }

      if (this.additionKind === 'TRANSFER_POINT') {
        return '. Передача';
      }

      if (this.additionKind === 'TECHNICAL_POINT') {
        return '. Техническая';
      }

      return '';
    },
    kindNameFromKey() {
      if (this.additionKind === 'RECEIVING_POINT') {
        return 'Приём';
      }
      if (this.additionKind === 'DELIVERY_POINT') {
        return 'Поставка';
      }
      if (this.additionKind === 'TRANSFER_POINT') {
        return 'Передача';
      }
      if (this.additionKind === 'TECHNICAL_POINT') {
        return 'Техническая';
      }
      return '';
    },
    getCorrectPath() {
      return this.route === '/cabinet/transport-accounting-points'
        ?  'transport-accounting-points/act-of-technological-connection'
        : 'accounting-points/act-of-technological-connection';
    },
    actionBlockItems() {
      return [{
        id: 1,
        title: 'Сформировать акт технологического присоединения',
        method: goToPageByRoute(`${this.getCorrectPath}`),
      }];
    },
    ...mapGetters('dictionary', [
      'ownerAccountingFacilities',
      'measurementsReadingKind',
    ]),
  },
  watch: {
    async additionKind() {
      await this.loadData();
    },
    async exportToExcelAction() {
      const points = await this.loadAllFiltered();

      exportToExcel(
        `Точки учёта${this.nameFromAdditionKind}`,
        [
          ...this.template.headers.slice(1),
          ...this.template.subGroups[0].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[0].label}. ${item.label}`,
          })),
          ...this.template.subGroups[1].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[1].label}. ${item.label}`,
          })),
          ...this.template.subGroups[2].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[2].label}. ${item.label}`,
          })),
          ...this.template.subGroups[3].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[3].label}. ${item.label}`,
          })),
          ...this.template.subGroups[4].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[4].label}. ${item.label}`,
          })),
          ...this.template.subGroups[5].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[5].label}. ${item.label}`,
          })),
          ...this.template.subGroups[6].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[6].label}. ${item.label}`,
          })),
          ...this.template.subGroups[7].headers.map((item) => ({
            ...item,
            label: `${this.template.subGroups[7].label}. ${item.label}`,
          })),
        ].filter((item) => item.key !== 'followLink'),
        'label',
        points,
        'key',
        {
          alignment: 'center',
          maxWidth: 40,
        },
      );
    },
  },
  methods: {
    formatPointKind(data) {
      return formatPointKind(data);
    },
    formatPointIsDuplicate(data) {
      return formatPointIsDuplicate(data);
    },
    formatPointTariffCategory(data) {
      return formatPointTariffCategory(data);
    },
    async loadData() {
      try {
        this.loading = true;
        this.pointsPage = await this.loadPointsByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (error) {
        console.log('loadPoints', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;

        const result = await this.loadPointsByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.pointsPage.pageOut.totalElements,
          },
        });

        if (!result?.list) {
          return [];
        }

        return result.list.map(this.transformed);
      } catch (error) {
        console.log('loadAllFiltered', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    convertDbFormat(value) {
      return convertDbFormat(value);
    },
    cellClickHandler(cell) {
      if (cell.key === 'followLink') {
        this.$router.push({
          name: 'cabinet.usage-point',
          params: {
            id: cell.row.id,
            usagePoint: this.points[cell.row.index],
          },
        });
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it, index) {
      return {
        ...it,
        uuid: it.id,
        pointKind: this.formatPointKind(it.pointKind),
        retailDepartmentName: it.retailDepartment?.name,
        transformedMetersOwner: this.ownerAccountingFacilities[it.metersOwner],
        transformedMeasurementsReadingKind:
          this.measurementsReadingKind[it.measurementsReadingKind],
        externalId: it.externalId,
        transformedCalibrationDate: it.currentTransformer?.calibrationDate,
        transformedNextCalibrationDate: it.currentTransformer?.nextCalibrationDate,
        transformedPotentialTransformerCalibrationDate: it.potentialTransformer?.calibrationDate,
        transformedPotentialTransformerNextCalibrationDate: it.potentialTransformer?.nextCalibrationDate,
        transformedMeterCalibrationDate: convertDbFormat(
          it.meter?.calibrationDate,
        ),
        transformedMeterNextCalibrationDate: convertDbFormat(
          it.meter?.nextCalibrationDate,
        ),
        transformedOwnerAccountingFacilities:
          this.ownerAccountingFacilities[it.metersOwner],
        transformedDelimitationActDate: convertDbFormat(it.delimitationActDate),
        name: `Название присоединения ${index + 1}`,
        isDuplicate: this.formatPointIsDuplicate(it.isDuplicate),
        tariffCategory: this.formatPointTariffCategory(it.tariffCategory),
      };
    },
    ...mapActions('point', ['loadPoints', 'loadPointsByFilterPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
@import "src/assets/sass/components/tables"
.container
  position: relative

  .stepTitle
    +base-title
    margin: 0 32px 32px 0

    span
      margin-right: 12px
      color: #979CA2

  .content
    width: 560px
    margin: 0 auto 0
    z-index: 999

    .stepNote
      +base-text
      width: 100%
      padding: 16px
      margin-top: 20px
      margin-bottom: 32px
      background-color: rgba(242, 201, 76, 0.25)


  .form
    position: absolute
    width: 100%
    margin-bottom: 64px
    padding: 0 32px 0 0

    div
      overflow-x: auto

      &::-webkit-scrollbar-button
        background-repeat: no-repeat
        width: 10px
        height: 0

      &::-webkit-scrollbar-thumb
        -webkit-border-radius: 0
        border-radius: 10px
        background-color: #d1d1d1

      &::-webkit-scrollbar-thumb:hover
        background-color: #a19f9f

      &::-webkit-resizer
        background-repeat: no-repeat
        width: 10px
        height: 0

      &::-webkit-scrollbar
        width: 10px
        height: 10px

  .radioItem
    position: relative
    padding: 0 2px
    margin: 18px 0 0

    &:last-child
      margin-bottom: 38px

    input
      display: none

    label
      +base-text
      cursor: pointer

  .button
    +base-button-text
    text-decoration: underline
    text-transform: uppercase
    cursor: pointer
    margin-left: 48px
    margin-top: 32px
    margin-bottom: 48px

    &:hover
      text-decoration: none

  table
    .tableTd
      td
        padding: 4px 16px
        font-family: 'Roboto Condensed', sans-serif
        color: gray
</style>
