<template>
  <div class="employee-selector">
    <!-- TODO: нулевой сотрдник (гендир) должен отображаться по умолчанию -->
    <span :style="labelStyle" class="employee-selector__title">
      Подписывающее лицо
    </span>
    <select
      v-model="selectedEmployee.id"
      :disabled="!editable"
      class="employee-selector__select"
      :class="{'employee-selector__select_error': isError }"
      :style="selectStyle"
      @change="changeHandler(selectedEmployee.id)"
    >
      <option
        v-for="(employee, index) in employees"
        :key="index"
        :value="employee.id"
      >
        {{ employee.fullName.surname }}
        {{ employee.fullName.name }}
        {{ employee.fullName.patronymic }}
        {{ employee.role.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    initEmployee: {
      type: [Object, null],
      default: null,
    },
    selectStyle: {
      type: Object,
      default: () => ({}),
    },
    labelStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (this.initEmployee) {
      this.selectedEmployee = { ...this.initEmployee };
      this.$emit('select', this.selectedEmployee);
    }
  },
  data() {
    return {
      selectedEmployee: {
        id: null,
      },
    };
  },
  watch: {
    initEmployee(val) {
      if (val) {
        this.selectedEmployee = { ...val };
      }
    },
  },
  methods: {
    changeHandler(employeeId) {
      const selectEdemployee = this.employees.find((employee) => employee.id === employeeId);
      this.$emit('select', selectEdemployee);
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-selector {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
  }

  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;

    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
    }

    &_error {
      border-color: #EB5757;
    }
  }
}
</style>
