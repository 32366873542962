<template>
  <div>
    <div>
      <consumer
        :consumer="connectionApplication.consumer"
        :errors="errors.consumer"
        :is-legal="isLegal"
        :only-microgenerator="onlyMicrogenerator"
        :total-steps="totalSteps"
      />
    </div>
    <additional-owner
      :additional-owner="connectionApplication.additionalOwner"
      :errors="errors.additionalOwner"
      :is-legal="isLegal"
      :total-steps="totalSteps"
    />
  </div>
</template>

<script>
import Consumer from './groups/Consumer';
import AdditionalOwner from './groups/AdditionalOwner';

export default {
  name: 'PersonalData',
  components: {
    Consumer,
    AdditionalOwner,
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    isLegal: {
      type: Boolean,
      default: false,
    },
    isAdditionalOwner: {
      type: Boolean,
      default: false,
    },
    minSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalSteps() {
      return Number(this.minSteps) + (this.onlyMicrogenerator ? 1 : 0) + (this.isAdditionalOwner ? 1 : 0);
    },
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.titleContainer
  +base-spacing

.title
  display: flex

.titleAddress
  margin-bottom: 8px

.grid2Column
  +grid-2-column

.gridFluid
  +grid-fluid
</style>
