<template>
  <page title="Реестр ИСУ">
    <v-table-new
      v-model="transformedIsu"
      :loading="loading"
      :template="template"
      excel-report-file-name="Реестр ИСУ"
    />
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Page, VTableNew } from '@/components';

export default {
  name: 'CabinetIsu',
  components: {
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      isu: [],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование ИСУ',
            key: 'name',
            thStyle: {
              maxWidth: '200px',
              minWidth: '200px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование протокола ИСУ',
            key: 'isuProtocolName',
            thStyle: {
              minWidth: '90px',
            },
            tdStyle: {
              maxWidth: '80px',
            },
            sort: {},
            filter: {},
          },
        ],
      };
    },
    transformedIsu() {
      if (this.isu.length === 0) {
        return [];
      }

      return this.isu.map((it) => ({
        name: it.name,
        isuProtocolName: it?.isuProtocol?.name,
      }));
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.isu = await this.getAllIsu();
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('dataLists', ['getAllIsu']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
