<template>
  <div>
    <v-modal :visible="functionalBlockVisible" content-width="1024px">
      <div class="modal-content">
        <h1 v-if="initFunctionalBlock">Обновление функционального блока</h1>
        <h1 v-else>{{ getUserType !== 'Platform' ? 'Отправка заявки на добавление функционального блока' : 'Добавление функционального блока'}}</h1>
        <v-input
          v-model="currentBlockCode" class="modal-content__input" label="Код функционального блока"/>
        <p v-if="errors.functionalBlockCodeError.length>0" class="errorText">
          {{ errors.functionalBlockCodeError }}</p>
        <v-input v-model="currentBlockDescription" class="modal-content__input" label="Описание функционального блока"/>
        <p v-if="errors.functionalBlockDescriptionError.length>0" class="errorText">
          {{ errors.functionalBlockDescriptionError }}</p>
        <v-select-new class="modal-content__input"
                      :options="notSelectedAccounts"
                      :transform="{ length: 30, slice: 27 }"
                      option-max-height="400px"
                      option-width="100%"
                      placeholder="Тип аккаунта"
                      right
                      @select="selectAccountKind"
        />
        <p v-if="errors.accountKindError.length>0" class="errorText">{{ errors.accountKindError }}</p>
        <div class="modal-content__selected-accounts-list">
          <div v-for="(item, index) in selectedAccounts" :key="index" class="modal-content____selected-accounts">
            {{ item.name }}
            <img alt="Открыть на карте" src="../../../../assets/icons/basic/cross.svg"
                 @click="deleteAccountKind(item)"/>
          </div>
        </div>
        <v-divider/>
        <p v-if="submitError.length>0" class="errorText">{{ submitError }}</p>
        <horizontal-progress-bar v-if="isLoading"/>
        <div class="modal-content__button-group">
          <v-button :disabled="isLoading" style="margin-right: 16px" @click="submitFunctionalBlock">
            <span v-if="getUserType !== 'Platform'">{{ initFunctionalBlock ? 'Обновить' : 'Отправить заявку' }}</span>
            <span v-else>{{ initFunctionalBlock ? 'Обновить' : 'Добавить' }}</span>
          </v-button>
          <v-button :disabled="isLoading" variant="outlined" @click="modalClose">Отменить</v-button>
        </div>
      </div>
    </v-modal>
    <v-modal :visible="permissionsVisible" content-width="1024px">
      <div class="modal-content">
        <h1 v-if="initPermission">Обновление полномочия</h1>
        <h1 v-else>{{ getUserType !== 'Platform' ? 'Отправка заявки на добавление полномочия' : 'Добавление полномочия'}}</h1>
        <v-select-new
          class="modal-content__input"
          :options="transformedBlocks"
          :transform="{ length: 30, slice: 27 }"
          option-max-height="400px"
          :value="selectedBlock.name"
          option-width="100%"
          placeholder="Функциональный блок"
          right
          @select="selectFunctionalBlock"
        />
        <v-input
          v-model="currentPermissionCode" class="modal-content__input" label="Код полномочия"/>
        <p v-if="errors.permissionCodeError.length>0" class="errorText">{{ errors.permissionCodeError }}</p>
        <v-input v-model="currentPermissionDescription" class="modal-content__input" label="Описание полномочия"/>
        <p v-if="errors.permissionDescriptionError.length>0" class="errorText">
          {{ errors.permissionDescriptionError }}</p>
        <edit-mode-button class="modal-content__input"
                          :is-true="usingOnAttorney"
                          title="Формируется доверенность"
                          @click="usingOnAttorneyHandler"
        />
        <v-divider class="modal-content__input"/>
        <p v-if="submitError.length>0" class="errorText">{{ submitError }}</p>
        <horizontal-progress-bar v-if="isLoading"/>
        <div class="modal-content__button-group">
          <v-button :disabled="isLoading" style="margin-right: 16px" @click="submitPermission">
            <span v-if="getUserType !== 'Platform'">{{ initPermission ? 'Обновить' : 'Отправить заявку' }}</span>
            <span v-else>{{ initPermission ? 'Обновить' : 'Добавить' }}</span>
          </v-button>
          <v-button :disabled="isLoading" variant="outlined" @click="modalClose">Отменить</v-button>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { EditModeButton, VButton, VDivider, VInput, VModal, VSelectNew } from '@/components';
import {
  generateEditFunctionalBlockOrder, generateEditPermissionOrder,
  generateFunctionalBlockOrder,
  generatePermissionOrder,
} from 'views/private/CabinetPermissionsRegistry/PermissionsRegistry/xml';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

export default {
  components: {
    HorizontalProgressBar,
    EditModeButton,
    VSelectNew, VButton,
    VInput, VModal, VDivider,
  },
  props: {
    functionalBlockVisible: {
      type: Boolean,
      default: false,
    },
    permissionsVisible: {
      type: Boolean,
      default: false,
    },
    initFunctionalBlock: {
      type: Object,
      default: null,
    },
    initPermission: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.notSelectedAccounts = this.accountKindList;
    this.setInitPermission();
    this.setInitFunctionalBlock();
  },
  data() {
    return {
      currentPermissionCode: '',
      currentPermissionDescription: '',
      currentBlockCode: '',
      currentBlockDescription: '',
      notSelectedAccounts: [],
      selectedAccounts: [],
      usingOnAttorney: false,
      selectedBlock: '',
      isLoading: false,
      submitError: '',
      errors: {
        functionalBlockCodeError: '',
        functionalBlockDescriptionError: '',
        accountKindError: '',
        permissionCodeError: '',
        permissionDescriptionError: '',
      },
    };
  },
  computed: {
    transformedBlocks() {
      return this.getFunctionalBlocks.map((block) => ({
          ...block,
          value: block.code,
          name: `${block.code}: ${block.description}`,
        }));
    },
    ...mapGetters('dataLists', ['accountKindList']),
    ...mapGetters('permissionsRegistry', ['getFunctionalBlocks', 'getPermissions']),
    ...mapGetters('user', ['getUserType']),
  },
  watch: {
    functionalBlocks: {
      handler() {
        if (!this.getFunctionalBlocks.some((block) => block.code === this.selectedBlock.code)) {
          this.setStandartSelectedBlock();
        }
      },
    },
    permissionsVisible: {
      handler(val) {
        if (val) this.setStandartSelectedBlock();
      },
    },
    initFunctionalBlock: {
      handler() {
        this.setInitFunctionalBlock();
      },
    },
    initPermission: {
      handler() {
        this.setInitPermission();
      },
    },
  },
  methods: {
    setInitFunctionalBlock() {
      if (!this.initFunctionalBlock) return;
      this.currentBlockCode = this.initFunctionalBlock.code;
      this.currentBlockDescription = this.initFunctionalBlock.description;
      this.initFunctionalBlock.accountKind.forEach((it) => this.selectAccountKind(this.notSelectedAccounts.find((ns) => ns.value === it)));
    },
    setInitPermission() {
      if (!this.initPermission) return;
      this.currentPermissionCode = this.initPermission.code;
      this.currentPermissionDescription = this.initPermission.description;
      this.selectedBlock = this.transformedBlocks.find((block) => block.code === this.initPermission.block);
      this.usingOnAttorney = this.initPermission.usingOnAttorney;
    },
    setStandartSelectedBlock() {
      this.selectedBlock = this.transformedBlocks[0];
      this.currentPermissionCode = `${this.selectedBlock.code}-`;
    },
    validateFunctionalCode() {
      if (this.currentBlockCode.length < 6 || this.currentBlockCode.length > 50) {
        this.errors.functionalBlockCodeError = 'Длина поля должна быть не менее 6 и не более 50 символов';
        return false;
      }
      if (!this.currentBlockCode.match('^[^\\W_]+_[^\\W_]\\d+$')) {
        this.errors.functionalBlockCodeError = 'Поле должно быть в формате "Группа_Код функционального блока", где код состоит из одной буквы латинского алфавита и одной или нескольких цифр';
        return false;
      }
      if (this.getFunctionalBlocks.some((block) => block.code === this.currentBlockCode) && !this.initFunctionalBlock) {
        this.errors.functionalBlockCodeError = 'Функциональный блок с таким кодом уже существует';
        return false;
      }
      return true;
    },
    validateFunctionalDescription() {
      if (this.currentBlockDescription.length <= 0) {
        this.errors.functionalBlockDescriptionError = 'Поле обязательно для заполнения';
        return false;
      }
      if (this.currentBlockDescription.length > 255) {
        this.errors.functionalBlockDescriptionError = 'Поле не должно быть 255 символов';
        return false;
      }
      return true;
    },
    validateAccountKinds() {
      if (this.selectedAccounts.length === 0) {
        this.errors.accountKindError = 'Необходимо выбрать хотя бы один тип аккаунта';
        return false;
      }
      return true;
    },
    validatePermissionCode() {
      if (this.currentPermissionCode.length === 0) {
        this.errors.permissionCodeError = 'Поле обязательно для заполнения';
        return false;
      }
      if (this.currentPermissionCode.length < 6) {
        this.errors.permissionCodeError = 'Поле должно содержать не менее 6 символов';
        return false;
      }
      if (!this.currentPermissionCode.match('^[^\\W_]+_[^\\W_]\\d+-[^\\W_]$')) {
        this.errors.permissionCodeError = 'Код должен быть в формате "Группа_Код функционального блока-Буква латинского алфавита"';
        return false;
      }
      if (!this.currentPermissionCode.startsWith(this.selectedBlock.code)) {
        this.errors.permissionCodeError = 'Код полномочия должен начинаться с кода выбранного функционального блока';
        return false;
      }
      if (this.getPermissions.some((permission) => permission.code === this.currentPermissionCode) && !this.initPermission) {
        this.errors.permissionCodeError = 'Полномочие с таким кодом уже существует';
        return false;
      }
      return true;
    },
    validatePermissionDescription() {
      if (this.currentPermissionDescription.length <= 0) {
        this.errors.permissionDescriptionError = 'Поле обязательно для заполнения';
        return false;
      }
      if (this.currentPermissionDescription.length > 255) {
        this.errors.permissionDescriptionError = 'Поле не должно быть длиннее 255 символов';
        return false;
      }
      return true;
    },
    validateFunctionalBlock() {
      const codeValid = this.validateFunctionalCode();
      const descriptionValid = this.validateFunctionalDescription();
      const accountKindsValid = this.validateAccountKinds();
      return codeValid && descriptionValid && accountKindsValid;
    },
    validatePermissionBlock() {
      const codeValid = this.validatePermissionCode();
      const descriptionValid = this.validatePermissionDescription();
      return codeValid && descriptionValid;
    },
    clearErrors() {
      this.submitError = '';
      this.errors = {
        functionalBlockCodeError: '',
        functionalBlockDescriptionError: '',
        accountKindError: '',
        permissionCodeError: '',
        permissionDescriptionError: '',
      };
    },
    async submitFunctionalBlock() {
      this.clearErrors();
      if (!this.validateFunctionalBlock()) return;

      let xml = '';
      let successMessage = '';

      if (this.initFunctionalBlock) {
        xml = generateEditFunctionalBlockOrder({
          oldCode: this.initFunctionalBlock.code,
          code: this.currentBlockCode,
          description: this.currentBlockDescription,
          accountKinds: this.selectedAccounts.map((item) => item.value),
        });

        successMessage = 'Заявка на обновление функционального блока отправлена на рассмотрение в службу поддержки';
      } else {
        xml = generateFunctionalBlockOrder({
          code: this.currentBlockCode,
          description: this.currentBlockDescription,
          accountKinds: this.selectedAccounts.map((item) => item.value),
        });

        successMessage = 'Заявка на добавление функционального блока отправлена на рассмотрение в службу поддержки';
      }
      await this.submitDocument(xml, successMessage);
    },
    async submitPermission() {
      this.clearErrors();
      if (!this.validatePermissionBlock()) return;

      let xml = '';
      let successMessage = '';

      if (this.initPermission) {
        xml = generateEditPermissionOrder({
          oldCode: this.initPermission.code,
          code: this.currentPermissionCode,
          description: this.currentPermissionDescription,
          usingOnAttorney: this.usingOnAttorney,
        });

        successMessage = 'Заявка на обновление полномочия отправлена на рассмотрение в службу поддержки';
      } else {
        xml = generatePermissionOrder({
          code: this.currentPermissionCode,
          description: this.currentPermissionDescription,
          usingOnAttorney: this.usingOnAttorney,
        });

        successMessage = 'Заявка на добавление полномочия отправлена на рассмотрение в службу поддержки';
      }
      await this.submitDocument(xml, successMessage);
    },
    async submitDocument(xml, successMessage) {
      try {
        this.submitError = '';
        this.isLoading = true;
        await this.documentUpload({ xml });

        if (this.getUserType !== 'Platform') {
          this.vueShowNotification(
              '',
              successMessage,
          );
        }

        await this.modalClose(true);
      } catch (e) {
        this.submitError = e.message.split(':')[2];
      } finally {
        this.isLoading = false;
      }
    },
    selectAccountKind(value) {
      this.selectedAccounts.push(value);
      this.notSelectedAccounts = this.notSelectedAccounts.filter((item) => item !== value);
    },
    deleteAccountKind(value) {
      this.notSelectedAccounts.push(value);
      this.selectedAccounts = this.selectedAccounts.filter((item) => item !== value);
    },
    usingOnAttorneyHandler() {
      this.usingOnAttorney = !this.usingOnAttorney;
    },
    async modalClose(success) {
      this.currentBlockDescription = '';
      this.currentPermissionDescription = '';
      this.currentBlockCode = '';
      this.currentPermissionCode = '';
      this.notSelectedAccounts = this.accountKindList;
      this.selectedAccounts = [];
      this.usingOnAttorney = false;
      this.selectedBlock = '';
      this.clearErrors();
      if (success) {
        await this.loadPermissionsRegistry();
      }
      this.$emit('modalClose');
    },
    selectFunctionalBlock(value) {
      this.selectedBlock = value;
      this.currentPermissionCode = `${this.selectedBlock.code}-`;
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('permissionsRegistry', ['loadPermissionsRegistry']),
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  font-family: 'Roboto';
  width: 560px;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #4C4E51;
  }

  &__input {
    margin-top: 24px;
  }

  &__selected-accounts-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin: 24px 0;
    max-width: 848px;
  }

  &____selected-accounts {
    padding: 5px 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4C4E51;

    background: #E9EBED;
    border: 1px solid #E9EBED;
    border-radius: 16px;

    img {
      height: 10px;
      cursor: pointer;
      margin-left: 8px;
    }
  }

  &__button-group {
    margin-top: 24px;
    text-align: center;
  }
}
</style>
