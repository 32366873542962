<template>
  <div :class="$style.container">

    <v-select
      v-model="type"
      :class="$style.contractType"
      :disabled="isLoading"
      :options="availableConnectionApplicationKind"
      role="applicationType"
      title='Выбор типа технологического присоединения *'
    />

    <submit-task-details :class="$style.start" :disabled="!type && !isLoading" title="ЗАПОЛНИТЬ ЗАЯВЛЕНИЕ"
                         @submit="onStart"/>
    <horizontal-progress-bar v-if="isLoading"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { VSelect } from '@/components';

export default {
  name: 'SelectType',
  components: {
    HorizontalProgressBar,
    VSelect,
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.type = this.connectionApplication.type;
  },
  data() {
    return {
      isLoading: false,
      type: this.connectionApplication.type,
    };
  },
  computed: {
    isValid() {
      return this.type && this.connectionApplication.connectionApplicationWithContractType;
    },
    availableConnectionApplicationKind() {
      if (!this.getAccount || this.getAccount.kind === 'INDIVIDUAL_CONSUMER')
        return this.connectionApplicationTypeList.filter((type) => !['LEGAL_ENTITIES_UP_TO_150', 'LEGAL_ENTITIES_OVER_150'].some((it) => it === type.value));
      return this.connectionApplicationTypeList.filter((type) => !['INDIVIDUALS_UP_TO_15', 'INDIVIDUALS_OVER_15'].some((it) => it === type.value));
    },
    ...mapGetters('dataLists', [
      'connectionApplicationTypeList',
      'connectionApplicationWithContractTypeList',
    ]),
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        this.$emit('changeType', val);
      },
    },
    connectionApplication: {
      deep: true,
      handler(val) {
        this.type = val.type;
      },
    },
  },
  methods: {
    onStart() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="sass" module>
.container
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

.contractType
  display: block
  margin-top: 54px
  margin-bottom: 39px
  display: block

</style>
