export const D1_A = 'SVET_D1-A';
export const D1_B = 'SVET_D1-B';

export const D2_A = 'SVET_D2-A';

export const D3_A = 'SVET_D3-A';
export const D3_B = 'SVET_D3-B';

export const D4_A = 'SVET_D4-A';

export const D5_A = 'SVET_D5-A';

export const D6_A = 'SVET_D6-A';

export const D7_A = 'SVET_D7-A';

export const D8_A = 'SVET_D8-A';

export const D9_A = 'SVET_D9-A';

export const D10_A = 'SVET_D10-A';
export const D10_B = 'SVET_D10-B';

export const D11_A = 'SVET_D11-A';
export const D11_B = 'SVET_D11-B';
export const D11_C = 'SVET_D11-C';
export const D11_D = 'SVET_D11-D';
export const D11_E = 'SVET_D11-E';
export const D5_B = 'SVET_D5-B';
export const D5_C = 'SVET_D5-C';
export const D12_A = 'SVET_D12-A';

export const accessTask = {
  PROCESS_4_1: 'SVET_D4-A',
  PROCESS_4_2: 'SVET_D4-B',
  PROCESS_5_2: 'SVET_D5-B',
  PROCESS_5_3: 'SVET_D5-C',
  PROCESS_7: 'SVET_D7-A',
  PROCESS_11_2: 'SVET_D11-B',
};

export function checkAccess(code) {
  console.log(code);
  const accesses = [
    {
      code: 'SVET_D11-B',
      description: 'Возврат из ремонта',
    },
    {
      code: 'SVET_D11-A',
      description: 'Выдача в ремонт',
    },
    {
      code: 'SVET_D9-A',
      description: 'Дефектовка',
    },
    {
      code: 'SVET_D5-A',
      description: 'Контроль монтажа',
    },
    {
      code: 'SVET_D1-A',
      description: 'Учёт остатков (однократный)',
    },
  ];
  const access = accesses.find((it) => it.code === code);
  return access !== undefined;
}


