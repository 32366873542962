const TITLE_TARIFFS_UPDATE = {
  Performer: {
    text: 'Актуализация регулируемых тарифов',
    subTitle: '',
    formDesc: 'Заполните все поля формы, вводя цифры из скачанного нормативного документа. Цены (тарифы) указываются в ₽/кВт ⋅ ч (с учётом НДС)',
  },
  Reviewer: {
    text: 'Проверка новых регулируемых тарифов перед публикацией на Платформе',
    subTitle: '',
    formDesc: 'Проверьте все тарифы на соответствие с прикреплённым нормативным документом. Отметьте тарифы, в которых допущены ошибки, и укажите замечания. Цены (тарифы) указаны в ₽/кВт ⋅ ч (с учётом НДС)',
  },
};

const TITLE = {
  tariffsUpdateTask: TITLE_TARIFFS_UPDATE,
};

const STATUS_LIST_TARIFFS_UPDATE = {
  Performer: [
    {
      // id: 0,
      title: 'начать выполнение',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'Загрузите тарифы',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'На проверке',
      subTitle: '',
    },
    {
      // index: 3,
      title: 'Исправьте ошибки',
      subTitle: '',
    },
    {
      // index: 4,
      title: 'Выполнена',
      subTitle: '',
    },
  ],
  Reviewer: [
    {
      // id: 0,
      title: 'начать выполнение',
      subTitle: '',
    },
    {
      // index: 1,
      title: 'На исправлении',
      subTitle: '',
    },
    {
      // index: 2,
      title: 'Выполнена',
      subTitle: '',
    },
  ],
};

const STATUS_LIST = {
  tariffsUpdateTask: STATUS_LIST_TARIFFS_UPDATE,
};

const TARIFFS_UPDATE = {
  SUBMITTED: {
    Performer: {
      statusIndex: 0,
      isActive: true,
      actionComponent: 'UploadTariff',
      isDisplayAction: false,
      displayActionTitle: 'Начать выполнение',
      startReviewType: 'tariff-update-task-started',
      initSpoiler: {
        details: true,
        tariff: true,
      },
    },
  },
  AT_WORK: {
    Performer: {
      statusIndex: 1,
      isActive: true,
      actionComponent: 'UploadTariff',
      isDisplayAction: true,
      initSpoiler: {
        details: true,
        tariff: true,
      },
    },
  },
  REVIEW: {
    Performer: {
      statusIndex: 2,
      isActive: false,
      isDisplayAction: false,
    },
    Reviewer: {
      statusIndex: 0,
      isActive: true,
      actionComponent: 'ReviewTariff',
      isDisplayAction: true,
      displayActionTitle: 'Начать выполнение',
      initSpoiler: {
        details: true,
        tariff: false,
      },
      // startReviewType: 'tariff-update-task-approved',
    },
  },
  CORRECTION: {
    Performer: {
      statusIndex: 3,
      isActive: true,
      actionComponent: 'UploadTariff',
      isDisplayAction: true,
      initSpoiler: {
        details: false,
        tariff: false,
      },
    },
    Reviewer: {
      statusIndex: 1,
      isActive: false,
      isDisplayAction: false,
    },
  },
  COMPLETED: {
    Performer: {
      statusIndex: 4,
      isActive: false,
      isDisplayAction: false,
    },
    Reviewer: {
      statusIndex: 2,
      isActive: false,
      isDisplayAction: false,
    },
  },
};

const PROCESS_DATA = {
  tariffsUpdateTask: TARIFFS_UPDATE,
};

export function initTariffsUpdateTaskData(task, userId) {
  const userType = task.approverUserId === userId ? 'Reviewer' : 'Performer';

  const title = TITLE[task.workflowType][userType];

  const statusList = STATUS_LIST[task.workflowType][userType];

  const stageData = PROCESS_DATA[task.workflowType];

  const tariffTarget = `${task.region} на ${task.year} год`;
  console.log('userType', userType);
  const data = {
    ...stageData[task.stage][userType],
    title: `${title.text}`,
    region: task.region,
    year: task.year,
    title2: `группы «Население» ${tariffTarget}`,
    caption: `группа «Население» ${tariffTarget}`,
    statusList: statusList,
    documentId: task.documentId,
    invoiceId: task.invoiceId,
    workflowId: task.workflowId,
    applicationId: task.applicationId,
    expiresAt: task.expiresAt,
    // TODO: remove hardcode
    executor: 'Иванов Иван Иванович',
    approver: 'Семенов Семен Семенович',
    userType: userType,
    description: title.formDesc,
  };

  const currentStatus = data.statusTitle ? data.statusTitle : statusList[data.statusIndex];
  return {
    title: title,
    data: data,
    status: currentStatus,
  };
}
