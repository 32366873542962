<template>
  <div class="directory">
    <page :title="directoryName">
      <select-button
        :items="actionBlockItems"
      />
      <v-table-new
        v-model="transformeDirectoryRecords"
        :excel-report-file-name="directoryName"
        :loading="loading"
        :on-filter-change="onFilterChange"
        :on-load-all-values="loadAllFiltered"
        :on-pagination-change="onPaginationChange"
        :page-out="directoryRecordsPage.pageOut"
        :template="template"
        @delete="onDelete"
        @edit="onEdit"
      />
    </page>
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="onAddRecord"
      @delete="onDeleteRecord"
      @update="onAddRecord"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { AddDirectoryFieldModal, SelectButton, VTableNew } from '@/components';
import { generateSaveOrDeleteDirectoryRecord } from 'views/private/Directories/xml';
import HTTPError from 'lib/utils/errors';
import Page from 'components/Page/Page';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  components: {
    AddDirectoryFieldModal,
    VTableNew,
    SelectButton,
    Page,
  },
  created() {
    this.directoryId = this.$route.params.id;
    this.fields = this.$route.params.fields;
    const sortedFiledsByOrder = this.fields.sort((a, b) => a.order - b.order);
    this.fields = [...sortedFiledsByOrder];
    this.directoryName = this.$route.params.name;

    if (!this.directoryId || this.directoryId.length === 0) {
      this.vueShowNotification(
        '',
        'Не найден ID справочника!',
      );

      this.$router.back();
    }
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      actionBlockItems: [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.addRecord,
        },
      ],
      isDisplayAddDialog: false,
      directoryName: '',
      fields: [],
      directoryRecordsPage: {},
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    directoryRecords() {
      return this.directoryRecordsPage.list ?? [];
    },
    transformeDirectoryRecords() {
      if (this.directoryRecords.length === 0) return [];

      return this.directoryRecords.map(this.transformed);
    },
    template() {
      const headers = [];

      headers.push({
        label: '№ П/П',
        key: 'index',
        type: 'index',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      });

      this.fields?.forEach((item) => {
        const config = {
          label: item.name,
          key: item.id,
          filter: {},
          sort: {},
        };
        if (item.type === 'DATE') {
          config.filter = { type: 'date' };
        } else if (item.type === 'BOOLEAN') {
          config.filter = {
            type: 'select',
            values: this.booleanFieldOptions,
          };
        } else if (item.type === 'NUMBER') {
          config.type = 'number';
          config.filter = { type: 'number' };
          config.sort = { type: 'number' };
        }

        headers.push(config);
      });

      headers.push({
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      });

      return {
        headers: headers,
      };
    },
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.directoryId = to.params.id;
        this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const transformedFilters = this.transformFilters(this.filter);

        const directoryRecords = await this.getDirectoryRecords({
          id: this.directoryId,
          filters: transformedFilters,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
          sortedBy: { direction: 'ASC', field: '' },
        });

        this.directoryRecordsPage = directoryRecords.directoryRecords;

        if (!directoryRecords.name) {
          return;
        }

        this.fields = directoryRecords.fields;
        this.directoryName = directoryRecords.name;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    addRecord() {
      this.modalValue = {};
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
    },
    async onAddRecord() {
      this.loading = true;

      try {
        const xml = generateSaveOrDeleteDirectoryRecord({
          directoryId: this.directoryId,
          id: this.modalValue.id,
          fields: this.fields,
          value: this.modalValue,
        }, 'Save');


        await this.documentUpload({ xml: xml });

        this.closeModal();

        await this.loadData();

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.setNotification({
          message: errorData.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async onDeleteRecord() {
      this.loading = true;
      try {
        const xml = generateSaveOrDeleteDirectoryRecord({
          directoryId: this.directoryId,
          id: this.modalValue.id,
        }, 'Delete');

        await this.documentUpload({ xml: xml });

        await this.loadData();

        this.closeModal();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.setNotification({
          message: errorData.message,
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformFilters(filter) {
      const transformedFilters = [];

      for (const key in filter) {
        const query = filter[key];
        if (query !== '') {
          transformedFilters.push({
            query: query,
            fieldId: key,
          });
        }
      }
      return transformedFilters;
    },
    transformed(it) {
      const valuesById = JSON.parse(it.fields);

      const result = {};

      this.fields.forEach((item) => {
        let value = valuesById[item.id];
        if (value === 'true') {
          value = 'Да';
        } else if (value === 'false') {
          value = 'Нет';
        }
        result[item.id] = value;
      });

      result.id = it.id;

      return result;
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const directoryRecords = await this.getDirectoryRecords({
          id: this.directoryId,
          filter: {},
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.directoryRecordsPage.pageOut.totalElements,
          },
          sortedBy: { direction: 'ASC', field: '' },
        });

        if (!directoryRecords?.directoryRecords?.list) {
          return [];
        }

        return directoryRecords.directoryRecords.list.map(this.transformed);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('dataLists', ['getDirectoryRecords']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>
