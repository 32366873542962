<template>
  <v-table-new
    :action-block-items="actionBlockItems"
    :template="template"
    :value="transformedData"
    excel-report-file-name="Справочник полномочий. Полномочия"
    table-name="permissionsRegistry"
    @on-sort-column="onSortColumnHandler"
    @on-cell-click="cellClickHandler"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import VTableNew from 'components/VTableNew/VTable.vue';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { generateDeletePermissionOrder } from 'views/private/CabinetPermissionsRegistry/PermissionsRegistry/xml';

export default {
  components: {
    VTableNew,
  },
  props: {
    isSupport: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKey: '',
      sortDirection: 1,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Код',
            key: 'code',
            thStyle: {
              minWidth: '60px',
            },
            sort: {
              active: this.sortKey === 'code',
              direction: this.sortDirection,
            },
            filter: {},
          },
          {
            label: 'Функциональный блок',
            key: 'functionalBlock',
            thStyle: {
              minWidth: '60px',
            },
            sort: {
              active: this.sortKey === 'functionalBlock',
              direction: this.sortDirection,
            },
            filter: {},
          },
          {
            label: 'Описание',
            key: 'description',
            sort: {
              active: this.sortKey === 'description',
              direction: this.sortDirection,
            },
            filter: {},
          },
          {
            label: 'Используется на платформе',
            key: 'usingOnPlatformText',
            thStyle: {
              minWidth: '150px',
            },
            sort: {
              active: this.sortKey === 'usingOnPlatform',
              direction: this.sortDirection,
            },
          },
          {
            label: 'Используется при формировании доверенности',
            key: 'usingOnAttorneyText',
            thStyle: {
              minWidth: '150px',
            },
            sort: {
              active: this.sortKey === 'usingOnAttorney',
              direction: this.sortDirection,
            },
          },
          {
            label: 'Полномочия присутствуют в справочнике ФНС',
            key: 'usingOnFnsText',
            thStyle: {
              minWidth: '150px',
            },
            sort: {
              active: this.sortKey === 'usingOnAttorney',
              direction: this.sortDirection,
            },
          },
          {
            key: 'crossDelete',
            type: 'crossDelete',
            customCheck: (data) => data.usingOnPlatform || !this.isSupport,
          },
          {
            key: 'edit',
            type: 'edit',
            customCheck: (data) => data.usingOnPlatform || !this.isSupport,
          },
        ],
      };
    },
    transformedData() {
      const transformedData = this.getPermissions.map((permission) => ({
        ...permission,
        functionalBlock: `${permission.block}: ${permission.blockDescription}`,
        usingOnPlatformText: convertBooleanToText(permission.usingOnPlatform),
        usingOnAttorneyText: convertBooleanToText(permission.usingOnAttorney),
        usingOnFnsText: convertBooleanToText(permission.usingOnFns),
      }));
      console.log('data', transformedData);
      if (this.sortKey.length === 0) {
        return transformedData;
      }

      return transformedData.sort((firstElement, secondElement) => this.compare(firstElement, secondElement));
    },
    functionalBlockTitle() {
      return this.isSupport ? 'Добавить функциональный блок' : 'Отправить заявку на добавление функционального блока';
    },
    permissionTilte() {
      return this.isSupport ? 'Добавить полномочие' : 'Отправить заявку на добавление полномочия';
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: this.functionalBlockTitle,
          method: () => {
            this.$emit('functionalBlockModal');
          },
        },
        {
          id: 2,
          title: this.permissionTilte,
          method: () => {
            this.$emit('permissionModal');
          },
        },
      ];
    },
    ...mapGetters('permissionsRegistry', ['getPermissions']),
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    // TODO возможно стоит вынести общий метод
    compare(firstElement, secondElement) {
      if (this.sortKey !== 'code') return this.sortDirection * (`${firstElement[this.sortKey]}`).localeCompare(secondElement[this.sortKey]);
      const comparedLetters = this.sortDirection * (`${firstElement.block.slice(0, 1)}`).localeCompare(secondElement.block.slice(0, 1));
      if (comparedLetters !== 0) return comparedLetters;
      const comparedBlocks = this.sortDirection * (Number(firstElement.block.slice(1)) - Number(secondElement.block.slice(1)));
      if (comparedBlocks !== 0) return comparedBlocks;
      return this.sortDirection * (`${firstElement.code.slice(-1)}`).localeCompare(secondElement.code.slice(-1));
    },
    onSortColumnHandler(key) {
      if (this.sortKey === key) {
        this.sortDirection *= -1;
      } else {
        this.sortDirection = 1;
      }
      this.sortKey = key;
    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'crossDelete': {
          this.submitPermission(cell.row.code);
          break;
        }
        case 'edit': {
          this.$emit('edit', cell.row);
          break;
        }
      }
    },
    async submitPermission(code) {
      try {
        await this.documentUpload({ xml: generateDeletePermissionOrder({ code: code }) });
        await this.loadPermissionsRegistry();
      } catch (e) {
        console.log(e);
      }
    },
    ...mapActions('user', ['getAllPermissions']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('permissionsRegistry', ['loadPermissionsRegistry']),
  },
};
</script>

<style lang="scss" scoped>
</style>
