<template>
  <fragment v-show="isShow" style="width: 100%">
    <tr v-show="isShow">
      <td style="border: 1px solid #DEE2E6; height: 1px;">
        <div v-show="isEditModeOn" style="height: 100%;">

          <div
            style="height: 100%; display: flex; flex-direction: column; justify-content: center;"
          >
            <div style="display: flex; flex-direction: column;">
              <button
                v-if="!point.isNewPoint && !isCreateNewRowInProgress && isEditModeOn"
                :disabled="!isEditModeOn"
                style="background: inherit;"
                @click.prevent="editPoint()"
              >
                <img v-if="point.isEditableNow" src="../../assets/icons/contract/edit-now.svg">
                <img v-else src="../../assets/icons/contract/edit.svg">
              </button>
              <button
                v-else-if="point.isNewPoint && isEditModeOn"
                style="background: inherit;"
                @click.prevent="save"
              >
                <img src="../../assets/icons/contract/save.svg">
              </button>
              <button
                v-if="(!isCreateNewRowInProgress||point.isNewPoint) && isEditModeOn"
                :disabled="!isEditModeOn"
                style="background: inherit;"
                @click.prevent="deletePoint(point.number)"
              >
                <img src="../../assets/icons/contract/delete.svg">
              </button>
            </div>

          </div>
          <div style="position: relative">
            <button
              v-show="!point.isNewPoint && !isCreateNewRowInProgress && isEditModeOn"
              :disabled="isCreateNewRowInProgress || !isEditModeOn"
              style="background: inherit; width: 100%; position: absolute; bottom: -13px;"
              @click.prevent="addPointAfter(point.number)"
            >
              <img src="../../assets/icons/contract/plus.svg">
            </button>
          </div>

        </div>
      </td>
      <td v-show="isShow" :class="{td: isShow}" style="vertical-align: top;">
        <div v-if="point.isNewPoint">
          <select v-model="selectedNewPointNumber" :class="{error: isSelectedNewPointNumberError}">
            <option
              v-for="(option, index) in availableForSelect" :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div v-else>
          {{ currentNumber }}
        </div>
      </td>
      <td class="td" style="white-space: pre-line">
        <div>
          <autosize-textarea
            :key="`${currentNumber}-text-${changes}`"
            v-model="innerText"
            :disabled="!point.isEditableNow"
          />
        </div>
      </td>
    </tr>
    <xml-chapter-view v-for="(row, index) in point.chapters" :key="`${index}-${row.changes}`"
                      :chapter="row"
                      :is-create-new-row-in-progress="isCreateNewRowInProgress" :is-edit-mode-on="isEditModeOn"
                      :is-inner-chapter="true"
                      @chapterChanged="onChapterChanged"
                      @newRowFinished="emitNewRowFinished"
                      @newRowInProgress="emitNewRowInProgress"
                      @pointCreated="onNewPointCreated" @pointDeleted="onPointDeleted"
    />
    <xml-point-view v-for="(row) in point.points" :key="`${currentNumber}.${row.number}`"
                    :available-next-points="[...availableNextPoints,...availableNextPointsForPoint()]"
                    :is-create-new-row-in-progress="isCreateNewRowInProgress" :is-edit-mode-on="isEditModeOn"
                    :is-show="isShow"
                    :point="row"
                    :previous-number="currentNumber" @newRowFinished="emitNewRowFinished"
                    @newRowInProgress="emitNewRowInProgress"
                    @pointChanged="onPointChanged" @pointCreated="onNewPointCreated"
                    @pointDeleted="onPointDeleted"/>

  </fragment>
</template>

<script>

import { Fragment } from 'vue-frag';
import AutosizeTextarea from './AutosizeTextarea.vue';

export default {
  name: 'XmlPointView',
  components: {
    Fragment, AutosizeTextarea,
  },
  props: {
    point: { type: Object, required: true },
    previousNumber: { type: String, required: true },
    isEditModeOn: { type: Boolean, default: false },
    isShow: { type: Boolean, required: true },
    isCreateNewRowInProgress: { type: Boolean, default: false },
    availableNextPoints: { type: Array, default: () => [] },
  },
  beforeCreate: function() {
    this.$options.components.XmlChapterView = require('./XmlChapterView.vue').default;
  },
  data() {
    return {
      selectedNewPointNumber: '',
      isSelectedNewPointNumberError: false,
      innerText: null,
      changes: 0,
    };
  },
  computed: {
    currentNumber() {
      return this.previousNumber ? `${this.previousNumber}.${this.point.number}` : this.point.number;
    },
    availableForSelect() {
      if (this.point.forChangingParentPoint) return [...this.availableNextPoints];
      return [...this.availableNextPoints, `${this.previousNumber}.1`];
    },
  },
  watch: {
    point: {
      immediate: true,
      handler() {
        if (this.point.text !== this.innerText) {
          this.changes++;
          this.innerText = this.point.text;
        }
      },
    },
    innerText: {
      handler() {
        if (this.innerText === this.point.text) return;
        this.$emit('pointChanged', {
          ...this.point,
          text: this.innerText,
        });
      },
    },
  },
  methods: {
    emitNewRowInProgress() {
      this.$emit('newRowInProgress');
    },
    emitNewRowFinished() {
      this.$emit('newRowFinished');
    },
    availableNextPointsForPoint() {
      return [this.previousNumber ? `${this.previousNumber}.${parseInt(this.point.number) + 1}` : `${parseInt(this.point.number) + 1}`];
    },
    onNewPointCreated(number) {
      this.$emit('pointCreated', number);
    },
    onChapterChanged(newChapter) {
      console.log('on chapter changed');
      this.$emit('pointChanged', {
        ...this.point,
        chapters: this.point.chapters.map((chapter) => {
          if (chapter.number === chapter.number) return newChapter;
          return chapter;
        }),
      });
    },
    save() {
      if (!this.selectedNewPointNumber) {
        this.isSelectedNewPointNumberError = true;
        return;
      }
      this.isSelectedNewPointNumberError = false;
      this.emitNewRowFinished();
      this.$emit('pointDeleted', this.point);
      this.onNewPointCreated(this.selectedNewPointNumber);
    },
    deletePoint() {
      if (this.point.points.length || this.point.chapters.length) {
        this.vueShowNotification(
          'Для удаления данного пункта необходимо удалить вложенные подпункты',
          '',
        );
        return;
      }
      if (this.point.isNewPoint) this.emitNewRowFinished();
      this.$emit('pointDeleted', this.point);
    },
    onPointDeleted(deletedPoint) {
      this.$emit('pointChanged', {
        ...this.point,
        points: this.point.points.filter((point) => point.number !== deletedPoint.number).map((point, idx) => ({
          ...point,
          number: `${idx + 1}`,
        })),
      });
    },
    onPointChanged(newPoint) {
      this.$emit('pointChanged', {
        ...this.point,
        points: this.point.points.map((point) => {
          if (point.number === newPoint.number) return newPoint;
          return point;
        }),
      });
    },
    editPoint() {
      this.point.isEditableNow = !this.point.isEditableNow;
    },
    addPointAfter(afterNumber) {
      console.log('insertAfter', afterNumber);
      const newPoints = [...this.point.points];
      newPoints.splice(0, 0, {
        number: '',
        points: [],
        text: '',
        isEditableNow: false,
        isNewPoint: true,
        forChangingParentPoint: this.point.chapters.length > 0,
        chapters: [],
      });
      this.emitNewRowInProgress();
      this.$emit('pointChanged', {
        ...this.point,
        points: newPoints,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.td {
  text-align: left;
  border: 1px solid #DEE2E6;
  padding: 20px;
}

.error {
  background: inherit;
  border-color: red;
}
</style>
