<template>
  <div :class="$style.container">
    <navigation-bar
      :current-step="uploadReadings.currentStep"
      :home="task === undefined ? 'Показания' : 'Задачи'"
      :steps="steps"
      @open="openStep"
    />

    <div :class="$style.content" role="detailsContent">
      <component
        :is="steps[uploadReadings.currentStep].component"
        :key="componentKey"
        ref="details"
        :component-key="componentKey"
        :disable="disable"
        :step-number="steps[uploadReadings.currentStep].stepNumber"
        :task="task"
        :upload-readings="uploadReadings"
        @close="close"
        @complete="complete"
        @next="next"
        @preValidations="preValidations"
      />
    </div>

    <div :class="$style.contentFooter">
      <v-button v-show="displayBack" :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <v-button :class="$style.delete" type="button" variant="outlined-red" @click="displayDeleteDialog = true">
        <delete/>
        <span>Удалить черновик</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
      <v-button v-show="displayNext" :class="$style.next" type="button" @click="next">
        <span>Далее</span>
        <arrow-right/>
      </v-button>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить черновик?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft"/>
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>

  </div>
</template>

<script>
import ArrowRight from 'atoms/icons/arrows/ArrowRight';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import Delete from 'atoms/icons/action/Delete.vue';

import UploadReadings
  from 'views/private/MeasurementsIndications/MeasurementsIndicationsUpload/multiple/steps/UploadReadings';
import Additions from 'views/private/MeasurementsIndications/MeasurementsIndicationsUpload/multiple/steps/Additions';
import MeasurementsIndicationsUploadMultipleDownloadReport from 'views/private/MeasurementsIndications/MeasurementsIndicationsUpload/multiple/steps/MeasurementsIndicationsUploadMultipleDownloadReport';
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';

import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import localforage from 'localforage';
import throttle from 'lib/utils/throttle';
import { scrollIntoView } from 'lib/utils/DOM';
import { mapGetters, mapState } from 'vuex';
import validate from 'lib/utils/validationRules';
import { VButton } from '@/components';

// TODO: move followings functions to methods scope
function isNotEmptyItem(item) {
  return validate.isNotEmptyItem(item);
}

function compare(a, b) {
  if (a.row < b.row) {
    return -1;
  }
  if (a.row > b.row) {
    return 1;
  }
  if (a.row === b.row) {
    if (a.col < b.col) {
      return -1;
    }
    if (a.col > b.col) {
      return 1;
    }
  }
  return 0;
}

export default {
  name: 'MeasurementsIndicationsUpload',
  components: {
    VButton,
    Delete,
    ChevronLeft,
    ArrowRight,
    UploadReadings,
    Additions,
    MeasurementsIndicationsUploadMultipleDownloadReport,
    NavigationBar,
    DialogTemplate,
    SubmitTaskDetails,
  },
  props: {
    task: Object,
    index: Number,
    isComments: {
      type: Boolean,
      default: () => false,
    },
  },
  async created() {
    try {
      const account = this.account.profile;

      this.uploadReadings.UtilityInfo = {
        name: account.name,
        inn: account.inn,
      };

      const uploadReadings = await localforage.getItem(this.storeName);

      if (uploadReadings === null) {
        return;
      }

      const object = JSON.parse(uploadReadings);

      if (object.version === undefined || object.version < this.uploadReadings.version) {
        return;
      }

      this.uploadReadings = object;

      this.cleanUploadReadingsErrors();

      this.uploadReadings.UtilityInfo = {
        name: account.name,
        inn: account.inn,
      };

    } catch (error) {
      console.log('error getData uploadReadings', error);
    }
  },
  data() {
    return {
      uploadReadings: {
        version: 3,
        currentStep: 0,
        attachment1: [],
        attachment1File: null,
        attachment1Error: [],
        attachments_error: { error: false },
        UsagePointKind: '',
        UsagePointKind_error: { error: false },
        Utility: {
          inCase: {
            fio: {
              name: '',
              surname: '',
              patronymic: '',
            },
            position: '',
          },
          Representative: {
            Name: {
              FamilyName: '',
              FamilyName_error: { error: false },
              FirstName: '',
              FirstName_error: { error: false },
              Patronymic: '',
            },
            Position: '',
            Position_error: { error: false },
          },
        },
        Date: '',
        Date_error: { error: false },
        ReportPointsKind: '',
        ReportPointsKind_error: { error: false },
        documentSubmissionErrors: [],
      },
      steps: [
        {
          component: 'UploadReadings',
          stepNumber: {
            value: 1,
            from: 3,
            title: () => 'Ведомости снятия показаний',
          },
          isDisplayEdit: false,
          isComplete: true,
          validations: this.validUploadReadings,
        },
        {
          component: 'Additions',
          stepNumber: {
            value: 2,
            from: 3,
            title: () => 'Реестр показаний приборов учёта',
          },
          isDisplayEdit: true,
          isComplete: false,
          preValidations: this.validAttachment1,
        },
        {
          component: 'MeasurementsIndicationsUploadMultipleDownloadReport',
          stepNumber: {
            value: 3,
            from: 3,
            title: () => 'Сформированные XML файлы',
          },
          isDisplayEdit: false,
          isComplete: false,
        },
      ],

      componentKey: 1,
      disable: false,
      displayDeleteDialog: false,
    };
  },
  computed: {
    displayBack() {
      return this.uploadReadings.currentStep > 0;
    },
    displayNext() {
      return this.uploadReadings.currentStep >= 0 && this.steps[this.uploadReadings.currentStep].isComplete;
    },
    storeName() {
      return 'uploadReadings_indications';
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'reliabilityClassList',
      'voltageClassList',
      'pointsKindList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'accountingMethodList',
    ]),
    ...mapState('user', ['account']),
  },
  watch: {
    uploadReadings: {
      deep: true,
      immediate: false,
      handler: 'saveDataIntermediate',
    },
  },
  methods: {
    cleanUploadReadingsErrors() {
      this.uploadReadings.UsagePointKind_error.error = false;
      this.uploadReadings.Date_error.error = false;

      this.uploadReadings.Utility.Representative.Name.FamilyName_error.error = false;
      this.uploadReadings.Utility.Representative.Name.FirstName_error.error = false;

      this.uploadReadings.Utility.Representative.Position_error.error = false;
      this.uploadReadings.attachments_error = false;
    },
    validUploadReadings() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      isValid = this.uploadReadings.attachment1File !== null;
      if (!isValid) {
        result = true;
        errorRole = 'filledAttachments';
        this.uploadReadings.attachments_error = { error: true };
      } else {
        this.uploadReadings.attachments_error = { error: false };
      }

      isValid = this.uploadReadings.Utility.Representative.Name.FirstName.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Utility_FirstName';
        this.uploadReadings.Utility.Representative.Name.FirstName_error = { error: true };
      } else {
        this.uploadReadings.Utility.Representative.Name.FirstName_error = { error: false };
      }

      isValid = this.uploadReadings.Utility.Representative.Name.FamilyName.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Utility_FamilyName';
        this.uploadReadings.Utility.Representative.Name.FamilyName_error = { error: true };
      } else {
        this.uploadReadings.Utility.Representative.Name.FamilyName_error = { error: false };
      }

      isValid = this.uploadReadings.Utility.Representative.Position.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Utility_Position';
        this.uploadReadings.Utility.Representative.Position_error = { error: true };
      } else {
        this.uploadReadings.Utility.Representative.Position_error = { error: false };
      }

      isValid = this.uploadReadings.Date.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Date_error';
        this.uploadReadings.Date_error = { error: true };
      } else {
        this.uploadReadings.Date_error = { error: false };
      }

      isValid = this.uploadReadings.UsagePointKind.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'UsagePointKind_error';
        this.uploadReadings.UsagePointKind_error = { error: true };
      } else {
        this.uploadReadings.UsagePointKind_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validAttachment1() {
      this.uploadReadings.attachment1Error = [];
      return this.validAdditions1(this.uploadReadings.attachment1Error, this.uploadReadings.attachment1);
    },
    validAdditions1(attachment1Error, attachment1) {
      const errorRole = '';
      let result = false;

      const titlesHead = this.uploadReadings.titlesHead;

      const validateHandler = (rule, items, indexAttachment, indexItem, valuesList, additionalItems) => {
        const errorTexts = {
          required: 'Поле обязательно для заполнения',
          requiredNumber: 'Обязательное поле. Корректный формат: число',
          formatNumber: 'Некорректный формат. Корректный формат: число',
          requiredKtNumber: 'Обязательное поле. Корректный формат: два числа через слэш. Например: 100/50',
          requiredInn: 'Обязательное поле. Корректный формат: ИНН',
          requiredOneOfNumber: `Должно быть заполнено данное поле или одно из полей: ${[...additionalItems ?? []].map((it) => it + 1)}. Корректный формат: число`,
        };

        let validResult = true;
        let errorText = '';

        switch (rule) {
          case 'requiredOneOfNumber': {
            // TODO: refactor this
            const condition1 = isNotEmptyItem(items[indexItem]) && !isNaN(Number(items[indexItem]));

            const condition2 = additionalItems.map((it) => isNotEmptyItem(items[it]) &&
              !isNaN(Number(items[it]) &&
                !isNaN(Number(items[indexItem])))).some((e) => e);

            const condition3 = !isNaN(Number(items[indexItem]));

            validResult = condition1 || (condition2 && condition3);
            errorText = errorTexts.requiredOneOfNumber;
            break;
          }
          case 'required': {
            validResult = isNotEmptyItem(items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredLabelList': {
            validResult = isNotEmptyItem(items[indexItem]) && isValidLabelFromList(valuesList, items[indexItem]);
            errorText = errorTexts.required;
            break;
          }
          case 'requiredNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && !isNaN(Number(items[indexItem]));
            errorText = errorTexts.requiredNumber;
            break;
          }
          case 'formatNumber': {
            validResult = !isNotEmptyItem(items[indexItem]) || !isNaN(Number(items[indexItem]));
            errorText = errorTexts.formatNumber;
            break;
          }
          case 'requiredKtNumber': {
            validResult = isNotEmptyItem(items[indexItem]) && items[indexItem].match(/^\d+\/\d+$/g) !== null;
            errorText = errorTexts.requiredKtNumber;
            break;
          }
          case 'requiredInn': {
            const errorInn = { message: '' };
            validResult = isNotEmptyItem(items[indexItem]) && validate.validateInn(items[indexItem], errorInn);
            errorText = errorTexts.requiredInn;
            break;
          }
        }

        if (!validResult) {
          result = true;

          attachment1Error.push({
            row: indexAttachment + 7,
            col: indexItem + 1,
            colTitle: titlesHead[indexItem],
            text: errorText,
          });
        }
      };
      if (this.uploadReadings.documentSubmissionErrors.length > 0) {
        result = true;
      }

      function isValidLabelFromList(list, value) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].label === value) {
            return true;
          }
        }
        return false;
      }

      if (attachment1.length === 0) {
        attachment1.push([]);
      }

      attachment1.forEach((attachment, index) => {
        validateHandler(
          'requiredLabelList',
          attachment,
          index,
          14,
          this.accountingMethodList,
        );

        validateHandler(
          'requiredLabelList',
          attachment,
          index,
          13,
          this.voltageClassList,
        );

        validateHandler(
          'requiredOneOfNumber',
          attachment,
          index,
          9,
          null,
          [11],
        );

        validateHandler(
          'requiredOneOfNumber',
          attachment,
          index,
          10,
          null,
          [11],
        );
      });

      attachment1Error.sort(compare);

      return { isError: result, firstError: errorRole };
    },
    saveDataIntermediate: throttle(function() {
      this.saveData();
    }, 500),
    async saveData() {
      try {
        await localforage.setItem(this.storeName, JSON.stringify(this.uploadReadings));
      } catch (error) {
        console.log('error uploadReadings.save, data:', this.uploadReadings);
      }
    },
    async clearData() {
      try {
        await localforage.removeItem(this.storeName);
      } catch (error) {
        console.log('error uploadReadings.clear, data:', this.uploadReadings);
      }
    },
    close() {
      if (this.$route.path.indexOf('reading-sheets/indications-multiple-upload') !== -1) {
        this.$router.push('/cabinet/reading-sheets');
        return;
      }
      this.$emit('close');
    },
    back() {
      if (this.uploadReadings.currentStep === 0) {
        return;
      }

      if (this.uploadReadings.currentStep === 2 && this.uploadReadings.attachment1.length === 0) {
        this.uploadReadings.currentStep = 0;
        return;
      }

      this.uploadReadings.currentStep -= 1;
    },
    preValidations() {
      const result = this.steps[this.uploadReadings.currentStep].preValidations();

      this.steps[this.uploadReadings.currentStep].isComplete = !result.isError;

      return result;
    },
    next() {
      if (this.steps[this.uploadReadings.currentStep].validations) {
        const result = this.steps[this.uploadReadings.currentStep].validations();

        if (result.isError) {
          this.componentKey += 1;
          this.$nextTick(() => {
            console.log('result.firstError', result.firstError);
            scrollIntoView(document.querySelector(`[role=${result.firstError}]`));
          });

          return;
        }
      }

      this.uploadReadings.currentStep += 1;

      if (this.steps[this.uploadReadings.currentStep].preValidations) {
        const result = this.steps[this.uploadReadings.currentStep].preValidations();

        this.steps[this.uploadReadings.currentStep].isComplete = !result.isError;

        if (result.isError) {
          this.componentKey += 1;
        }
      }

      document.querySelector('[role=detailsContent]').scrollTop = 0;
    },
    complete(isComplete) {
      this.steps[this.uploadReadings.currentStep].isComplete = isComplete;

      if (isComplete && ((this.uploadReadings.currentStep === 2))) {
        this.clearData();
      }
    },
    changePoint() {
      this.disable = false;
    },
    savePoint() {
      this.disable = true;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
        return;
      }
      if (value >= this.uploadReadings.currentStep + 1) {
        return;
      }

      // TODO: избавиться от декремента шагов по всему коду
      // иначе багует иницициализация из черновиков полей по типу "currentStep"
      this.uploadReadings.currentStep = value - 1;
    },
    deleteDraft() {
      this.clearData();
      this.close();
    },
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2
  overflow-y: auto
  overflow-x: hidden

.contentFooter
  height: 48px
  display: flex

.back, .next, .delete
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  padding: 19px

  svg
    fill: #FFFFFF

.delete
  margin-left: 16px

  svg
    path
      fill: #EB5757

  span
    margin-left: 7px

.deleteText
  +base-title
  margin-top: 64px
  margin-bottom: 16px
  width: 100%
  text-align: center

.submitBlock
  display: flex
  margin-top: 64px

  div
    width: 50%

.center
  flex-grow: 2

.back
  background: #ffffff

  svg
    path
      fill: #2F82DF

.next
  margin-right: 36px

  span
    margin-right: 13px

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 64px
  width: 200px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF
</style>
