<template>
  <div v-if="filteredActionBlockItems.length">
    <select-button
      :items="filteredActionBlockItems"
    />
    <v-divider style="margin: 24px 0; height: 0;"/>
  </div>
</template>

<script>
import SelectButton from 'components/SelectButton/SelectButton.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { mapGetters } from 'vuex';
import { VDivider } from 'components';

export default {
  name: 'ActionBlock',
  components: { VDivider, SelectButton },
  computed: {
    actionBlockItems() {
      return [
        {
          id: 'process/4/1',
          title: 'Внесение данных о договорах купли-продажи движимого имущества',
          method: this.goToPageByRoute('process/4/1'),
        },
        {
          id: 'process/4/2',
          title: 'Внесение спецификации к договорам купли-продажи движимого имущества',
          method: this.goToPageByRoute('process/4/2'),
        },
      ];
    },
    filteredActionBlockItems() {
      return this.actionBlockItems.filter((item) => filterForAccess(item.id, this.getAuthorization?.blockAccess));
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    goToPageByRoute(path) {
      return () => this.$router.push(path);
    },
  },
};
</script>

<style scoped>

</style>
