<template>
  <page
    title="Экономически обоснованные единые (котловые) тарифы на услуги по передаче электрической энергии по сетям города Москвы">
    <v-table-new
      v-model="transformedUnifiedEnergyTariffs"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="unifiedEnergyTariffsPage.pageOut"
      :template="template"
      :width-for-truncated="950"
      excel-report-file-name="Экономически обоснованные единые (котловые) тарифы на услуги по передаче электрической энергии по сетям города Москвы"
    />
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
  </page>
</template>

<script>
import { ModalProgressBar, Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import formatting from 'lib/utils/formatting';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'UnifiedEnergyTariffs',
  components: {
    VTableNew,
    Page,
    ModalProgressBar,
  },
  data() {
    return {
      unifiedEnergyTariffsPage: {},
      loading: false,
      pagination: initialPagination,
      isModalVisible: false,
      isProcessComplete: false,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Год',
            key: 'year',
            thStyle: {
              minWidth: '70px',
            },
            filter: { type: 'date', format: 'YYYY', selectType: 'year' },
            sort: {},
          },
          {
            label: 'Наименование компании',
            key: 'companyName',
            filter: {},
            sort: {},
          },
          {
            label: 'ИНН компании',
            key: 'companyInn',
            filter: {},
            sort: {},
          },
          {
            label: 'НВВ сетевых организаций без учета оплаты потерь, учтенная при утерждении (расчете) единых (котловых) тарифов на услуги по передаче ЭЭ в г. Москве (тыс. руб.)',
            type: 'number',
            key: 'utilityNVVExcludingLossPayment',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Учтенные расхожы сетевых организаций, связанные с осуществлением технологического присоединения к электрическим сетям, не включаемые в плату за технологическое присоединение (тыс. руб.)',
            type: 'number',
            key: 'incurredExpensesForTechnicalConnection',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Величина потерь электрической энергии при ее передаче по электрическим сетям, учтенная при формировании регулируемых цен (тарифов) (млн. кВт*ч)',
            type: 'number',
            key: 'electricityLosses',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    unifiedEnergyTariffs() {
      return this.unifiedEnergyTariffsPage.list ?? [];
    },
    transformedUnifiedEnergyTariffs() {
      return this.unifiedEnergyTariffs.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.unifiedEnergyTariffsPage = await this.unifiedEnergyTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('unifiedEnergyTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const unifiedEnergyTariffs = await this.unifiedEnergyTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.unifiedEnergyTariffsPage.pageOut.totalElements,
          },
        });

        if (!unifiedEnergyTariffs?.list) {
          return [];
        }

        return unifiedEnergyTariffs.list.map(this.transformed);
      } catch (e) {
        console.log('unifiedEnergyTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        year: it.year,
        companyInn: it.companyInn,
        companyName: it.companyName,
        utilityNVVExcludingLossPayment: formatting.numberFormatted(it.utilityNVVExcludingLossPayment),
        incurredExpensesForTechnicalConnection: formatting.numberFormatted(it.incurredExpensesForTechnicalConnection),
        electricityLosses: formatting.numberFormatted(it.electricityLosses),
        document: { documentId: it.tariffsOrder?.documentId, name: 'UnifiedEnergyTariffs' },
      };
    },
    ...mapActions('dataLists', ['unifiedEnergyTariffsByPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
