// eslint-disable-next-line no-useless-escape
const REGEXP_COOKIE_NAME = /([\.$?*|{}\(\)\[\]\\\/\+^])/g;

export function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(REGEXP_COOKIE_NAME, '\\$1')}=([^;]*)`,
  ));

  return matches ? decodeURIComponent(matches[1]) : null;
}

export function setCookie(name, value, options = { path: '/' }) {
  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    const time = d.getTime() + expires;

    d.setTime(time * 1000);
    // expires = options.expires = d;
    options.expires = d;
    expires = options.expires;
  }

  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (const propName in options) {
    const propValue = options[propName];

    updatedCookie += `; ${propName}`;
    if (propValue) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}
