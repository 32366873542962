import router from '@/router/index';

// TODO: dont use STATE MANAGER for call ROUTERS methods only
async function displayDetails({ commit, dispatch, rootState }, task) {
  if (task.props.workflowType === 'odk') {
    const normalizedProcess = task.props.process.toLowerCase().replaceAll('_', '.');
    console.log('norm', normalizedProcess);
    const params = {
      task: task.props.odkId,
    };
    switch (normalizedProcess) {
      case 'process.15.2':
        params.warehouseId = JSON.parse(task.props.additionalData).warehouseId;
        params.axpId = JSON.parse(task.props.additionalData).axpId;
        params.contract = JSON.parse(task.props.additionalData).contract;
        params.eventId = JSON.parse(task.props.additionalData).eventId;
        params.contractorId = JSON.parse(task.props.additionalData).contractorId;
        break;
      case 'process.11.2':
        params.departmentId = JSON.parse(task.props.additionalData).departmentId;
        params.assignmentForInspectionId = JSON.parse(task.props.additionalData).assignmentForInspectionId;
        break;
      case 'process.10.1':
        params.departmentId = JSON.parse(task.props.additionalData).departmentId;
        params.initAdreskaId = JSON.parse(task.props.additionalData).adreskaId;
        params.assignmentForConnectionId = JSON.parse(task.props.additionalData).assignmentForConnectionId;
        break;
      default:
        break;
    }

    return await router.push({
      name: `cabinet.${normalizedProcess}`,
      params: params,
    });
  }

  if (task.props.workflowType === 'documentSigning' || task.props.workflowType === 'documentAgreement' ||
    task.props.workflowType === 'documentUpdateInfo' || task.props.workflowType === 'documentReload') {
    return await router.push({
      name: 'cabinet.approve-document',
      params: {
        id: task.props.domainDocumentId,
        from: 'tasks',
        fromTitle: 'ЗАДАЧИ',
        to: task.props.workflowType,
        rejected: task.props.rejected,
        completed: task.props.completed,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'userProfileDelete') {
    return await router.push({
      name: 'cabinet.user-profile-delete',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'companyDelete') {
    return await router.push({
      name: 'cabinet.company-delete',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'connectionUserBySupplyContractApplication') {
    return await router.push({
      name: 'cabinet.connection-user-by-contract-task',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'updateUserDataApplication') {
    return await router.push({
      name: 'cabinet.update-user-data-application-task',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'permissionRegistryChanges') {
    return await router.push({
      name: 'cabinet.permission-registry-changes-task',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'connectionToPlatform') {
    return await router.push({
      name: 'cabinet.connection-to-platform-task',
      params: {
        ...task.props,
        id: task.props.documentId,
        title: task.title,
      },
    });
  }

  if (task.props.workflowType === 'companyInvite') {
    commit('SET_TASKDETAILS', { ...task });
    await router.push({
      name: 'cabinet.task.workerConnection',
    });
  }


  if (task.props.workflowType === 'individualCustomerConnection' ||
    task.props.workflowType === 'individualCustomerConnectionRetail' ||
    task.props.workflowType === 'microgenerationConnectionUtility' ||
    task.props.workflowType === 'microgenerationConnectionRetail') {

    return await router.push({
      name: 'cabinet.connection-application.task',
      params: {
        task: task.props,
        isShownTaskDetails: true,
      },
    });
  }

  if (task.componentDetails || task.props.componentDetails) {
    if (task.componentDetails === 'NotDetails') {
      return;
    }

    if (task.componentDetails) {
      commit('SET_COMPONENTDETAILS', task.componentDetails);
    } else {
      commit('SET_COMPONENTDETAILS', task.props.componentDetails);
    }

    await router.push(`/cabinet/tasks/${rootState.tasks.componentDetails}`);

    commit('SET_TASKDETAILS', task.props);
    commit('SET_ISDISPLAYDETAILS', true);

    return;
  }

  if (task.props.workflowType === 'documentReload') {
    const contract = await dispatch('document/loadBasicTransmissionContractById', task.props.contractId, { root: true });

    if (task.props.documentType === 'ELECTRICITY_TRANSMISSION_CONTRACT') {
      await dispatch('electricityTransmissionContract/resetDraft', null, { root: true });
      await dispatch('electricityTransmissionContract/setContractMode', {
        action: contract.kindByExisting === 'NEW' ? 'create' : 'upload',
        type: contract.sourceContractId ? 'additionalAgreement' : 'contract',
        contractType: task.props.documentType,
      }, { root: true });

      const params = {
        contractId: contract.sourceContractId ? contract.sourceContractId : task.props.contractId,
        fromTask: true,
      };

      if (contract.sourceContractId) {
        params.contractKindByExisting = 'ADDITIONAL';
      }

      await router.push({
        name: 'cabinet.agreements.upload',
        params: params,
      });
    }
  }

  if (task.props.workflowType === 'tariffsUpdateTask') {
    commit('SET_TASKDETAILS', { ...task.props });
    await router.push('/cabinet/task/tariffs-update');

    return;
  }

  if (task.props.workflowType === 'tariffChangingApplication') {
    commit('SET_TASKDETAILS', { ...task.props });
    await router.push('/cabinet/task/tariffs-changing');

    return;
  }

  if (task.props.workflowType === 'balanceSigning' || task.props.workflowType === 'balanceAgreement') {
    // this.loading = true;

    // TODO get balance by documentId
    const balances = await this.loadBalances({});

    balances.some((item) => {
      if (item.documentId === task.props.documentId) {
        // TODO: переделать (and link method in ContentBlock)
        localStorage.setItem('balance', JSON.stringify(item));
        return true;
      }
      return false;
    });

    // this.loading = false;
    await router.push(`${task.props.link}${task.props.documentId}`);

    return;
  }

  if (task.props.workflowType === 'borderDeviceSigning' || task.props.workflowType === 'borderDeviceAgreement' ||
    task.props.workflowType === 'borderFlowSigning' || task.props.workflowType === 'borderFlowAgreement') {
    // this.loading = true;

    // TODO get integralAct by using loadDocumentView(this.task.documentId)
    const integralActs = await this.filterDocumentViews({
      kind: 'INTEGRAL_ACT', from: '1900-01-01T00:00:00Z', to: '2900-01-01T00:00:00Z',
    });

    integralActs.some((item) => {
      if (item.documentId === task.props.documentId) {
        this.setIntegralAct(item);
        return true;
      }
      return false;
    });

    // this.loading = false;
    await router.push(`${task.props.link}${task.props.documentId}`);
  }

  if (task.props.workflowType === 'documentScanUpload') {
    if (task.props.completed) {
      return;
    }
    await router.push({
      name: 'cabinet.document-scan-upload',
      params: {
        ...task.props,
        from: 'tasks',
        fromTitle: 'ЗАДАЧИ',
      },
    });
  }

  if (task.props.workflowType === 'contractAdditionUpload') {
    await router.push({
      name: 'cabinet.contract-addition-upload',
      params: {
        ...task.props,
        from: 'tasks',
        fromTitle: 'ЗАДАЧИ',
      },
    });
  }

  if (task.props.workflowType === 'connectionCertificateAfterContractValidation') {
    if (task.props.completed) {
      return;
    }
     await router.push({
      name: 'cabinet.document-atp-upload',
      params: {
        ...task.props,
        from: 'tasks',
        fromTitle: 'ЗАДАЧИ',
      },
    });
  }

  if (task.props.workflowType === 'supplyContractInformationAfterContractValidation') {
    if (task.props.completed) {
      return;
    }

    await router.push({
      name: 'cabinet.supply-contract-information-upload',
    });
  }

  if (task.props.workflowType === 'consumerAdditionAfterContractValidation') {
    if (task.props.completed) {
      return;
    }
    await dispatch('electricityTransmissionContract/resetDraft', null, { root: true });
    await dispatch('electricityTransmissionContract/setContractMode', {
      action: 'create',
      type: 'additionalAgreement',
      contractType: 'ELECTRICITY_TRANSMISSION_CONTRACT',
    }, { root: true });
    await dispatch('electricityTransmissionContract/setAdditionalAgreementType', {
      additionalAgreementType: 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS',
    }, { root: true });

    await router.push({
      name: 'cabinet.contracts-work-agreements.upload',
    });
  }

  if (task.props.workflowType === 'inspectionReportAfterContractValidation') {
    if (task.props.completed) {
      return;
    }
    await router.push({
      name: 'cabinet.readingSheets.indicationsSingleUpload',
      params: {
        ...task.props,
        from: 'tasks',
        fromTitle: 'ЗАДАЧИ',
      },
    });
  }
}

async function closeDetailsStore({ commit }) {
  console.log('closeDetails');
  commit('CLEAR_DETAILS');
  await router.push('/cabinet/tasks');
}

async function closeDetailsPopUpDetailsStore({ commit }) {
  console.log('closeDetailsPopUpDetails');
  commit('CLEAR_POPUPDETAILS');
}

export default {
  displayDetails,
  closeDetailsStore,
  closeDetailsPopUpDetailsStore,
};
