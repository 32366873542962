import moment from 'moment';

export function getMomentDate(date, format) {
  return moment(date, format);
}

export function getDateAsISOString(date) {
  return getMomentDate(date).format();
}

export function getDateAsForceISOString(date) {
  return getMomentDate(date).toISOString();
}

export function setLocale(locale) {
  moment.locale(locale);
}

export function getValidDateObject(date) {
  return getMomentDate(date).toDate();
}

export function formatDateToCustomDateFormat(date, format) {
  if (date) {
    return getMomentDate(date).format(format);
  }
  return '';
}

export function formatDateToXmlDateFormat(date) {
  if (!date) {
    return '';
  }
  return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
}

export function formatDateTimeToXmlFormat(date) {
  if (!date) {
    return '';
  }
  return moment(date, 'DD.MM.YYYY hh:mm').format('YYYY-MM-DDThh:mm');
}

export function formatDateToXmlYearMonthFormat(date) {
  if (!date) {
    return '';
  }
  return moment(date, 'DD.MM.YYYY').format('YYYY-MM');
}

export function formatStrDateToRussianDate(dateStr) {
  if (dateStr) {
    // Только формат 16.05.2024
    const [day, month, year] = dateStr.split('.');
    return `«${day}» ${month} ${year} г.`;
  }
  return '';
}


export function getCurrentDate(format) {
  return moment().format(format);
}

export function getUTCCurrentDate(format) {
  return moment().utc().format(format);
}

export function getMomentCurrentDate() {
  return moment();
}

export function addMonthsToDateFormatted(date, monthsCount, format) {
  return getMomentDate(date, format).add(monthsCount, 'month');
}

export function addYearsToDateFormatted(date, yearsCount, format) {
  const newDate = getMomentDate(date, format);
  newDate.add(yearsCount, 'year');
  console.log(newDate);
  console.log(yearsCount);
  return getMomentDate(date, format).add(yearsCount, 'year');
}

export function addMonthsToDate(date, monthsCount) {
  return addMonthsToDateFormatted(date, monthsCount);
}

export function addYearsToDate(date, yearsCount) {
  return addYearsToDateFormatted(date, yearsCount);
}


export function addDaysToCurrentDate(daysCount, format) {
  return moment().add(daysCount, 'day').format(format);
}

export function getTimestamp() {
  return moment().valueOf();
}

export function notNullString(val) {
  if (!val) {
    return '';
  }
  return String(val);
}

export function getNumberForDS(value) {
  if (value.contractExisting === 'ADDITIONAL') {
    return value.electricityTransmissionContract.ContractInfo.SourceContractInfo.Number;
  }
  return '';
}

export function getIdForDS(value) {
  if (value.contractExisting === 'ADDITIONAL') {
    return value.electricityTransmissionContract.ContractInfo.SourceContractInfo.Id;
  }
  return '';
}


export function getDateForDS(value) {
  if (value.contractExisting === 'ADDITIONAL') {
    return value.electricityTransmissionContract.ContractInfo.SourceContractInfo.Date;
  }
  return '';
}

export function convertXlsFormat(date) {
  if (!date || typeof date !== 'string') {
    return '';
  }
  if (date.indexOf('/') !== -1) {
    return moment(date, 'DD/MM/YYYY').format('DD.MM.YYYY');
  }
  if (date.indexOf('.') !== -1 || date.indexOf(',') !== -1) {
    return moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
  }
  if (date.indexOf('-') !== -1) {
    return moment(date, 'DD-MM-YYYY').format('DD.MM.YYYY');
  }
  return '';
}

export function convertXlsFormatStrict(date) {
  const result = convertXlsFormat(date);

  return result.length === 0 ? date : result;
}

export function convertDbFormat(date) {
  if (!date || typeof date !== 'string' || date.indexOf('-') === -1) {
    return '';
  }
  return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
}

export function convertDbTimeFormat(date) {
  if (!date || typeof date !== 'string' || date.indexOf('-') === -1) {
    return '';
  }
  return moment.utc(date, 'YYYY-MM-DDThh:mm:ss').local().format('HH:mm:ss');
}

export function convertDbTimeFormatHhMm(date) {
  if (!date || typeof date !== 'string' || date.indexOf('-') === -1) {
    return '';
  }
  return moment.utc(date, 'YYYY-MM-DDThh:mm:ss').local().format('HH:mm');
}

export function convertDbDateTimeFormat(date) {
  if (!date || typeof date !== 'string' || date.indexOf('-') === -1) {
    return '';
  }
  return moment.utc(date, 'YYYY-MM-DDThh:mm:ss').local().format('DD.MM.YYYY HH:mm:ss');
}

export function convertDateStringToUTC(date) {
  return moment(date, 'DD.MM.YYYY').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}

export function formatPeriod(month, year) {
  return moment(`${month}.${year}`, 'MM.YYYY').format('MM.YYYY');
}

export function getAccountingPeriodMonth(obj, isNotCapitalize) {
  if (!obj) {
    return null;
  }

  const month = moment().locale('ru')
    .year((obj.year ?? 1))
    .month((obj.month ?? 1) - 1)
    .format('MMMM');

  if (isNotCapitalize) {
    return month;
  }

  return month.charAt(0).toUpperCase() + month.substr(1);
}

export function getMonthString(monthNumber, isNotCapitalize) {
  if (!monthNumber) {
    return null;
  }

  const month = moment().locale('ru')
    .year(1)
    .month(monthNumber - 1)
    .format('MMMM');

  if (isNotCapitalize) {
    return month;
  }

  return month.charAt(0).toUpperCase() + month.substr(1);
}

export function getDateFromEpoch(epoch) {
  return moment(epoch).local().format('DD.MM.YYYY');
}

export function compareDbDatetimeFormatDesc(first, second) {
  return moment.utc(first.datetime, 'YYYY-MM-DDThh:mm:ss') - moment.utc(second.datetime, 'YYYY-MM-DDThh:mm:ss');
}

export function getXlsFormat(date) {
  if (!date || typeof date !== 'string') {
    return date;
  }
  if (date.indexOf('/') !== -1) {
    return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }
  if (date.indexOf('.') !== -1 || date.indexOf(',') !== -1) {
    return moment(date, 'DD.MM.YYYY').format('DD/MM/YYYY');
  }
  if (date.indexOf('-') !== -1) {
    return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
  }
  return date;
}

export function formatDateFromOdkTimestamp(timestamp) {
  if (!timestamp) {
    return '';
  }

  return moment.utc(timestamp * 1000).local().format('DD.MM.YYYY');
}

export function formatDateTimeFromOdkTimestamp(timestamp) {
  console.log('ts', timestamp, moment(timestamp * 1000).utc());
  if (!timestamp) {
    return '';
  }

  return moment.utc(timestamp * 1000).local().format('DD.MM.YYYY HH:mm:ss');
}

export function formatOdkTimestampToDateObject(timestamp) {
  return new Date(timestamp * 1000);
}

export function formatDateToOdkTimestamp(date) {
  return moment.utc(date, 'DD.MM.YYYY').valueOf() / 1000;
}

export function getDateAtStartOfDay(date) {
  const start = new Date(date);
  start.setUTCHours(0, 0, 0, 0);
  return start;
}
