<template>
  <div v-if="isClipView" :class="$style.clip" @click="containerClick">
    <clip />
    <input
        v-show="false"
        :id="inputId"
        :accept="accept"
        :role="role + '.input'"
        multiple
        name="files[]"
        type="file"
        @change="handleInput($event)"
    />
  </div>
  <validation-provider
    v-else
    ref="validationProvider"
    v-slot="{ validate, errors }"
    :custom-messages="rulesMessages"
    :rules="rules"
    :vid="vid"
    mode="lazy"
    name=" "
  >
    <p v-if="label.length > 0" :class="{[$style.errorText]: errors.length > 0 || isInvalid }">{{ label }}</p>
    <div
      v-cloak
      :class="{
      [$style.container]: true,
      [$style.invalid]: isInvalid || errors.length > 0
    }"
      @click="containerClick"
      @mousemove="mouseMove"
      @drop.prevent="handleDrop"
      @dragover.prevent
    >
      <div :class="$style.content" :role="role">
        <input
          :id="inputId"
          :accept="accept"
          :role="role + '.input'"
          multiple
          name="files[]"
          type="file"
          @change="handleInput($event) || validate($event)"
        />
        <span :class="$style.label" :for="inputId">
        <span>Выберите файл</span>
        {{ acceptTitle }}
        </span>
      </div>

      <p v-if="isInvalid" :class="$style.errorText">
        {{ errorText }}
      </p>
      <p v-if="errors.length > 0" :class="$style.errorText">
        {{ errors[0] }}
      </p>
    </div>
  </validation-provider>
</template>

<script>
import { generateRandomString } from 'lib/utils/string';
import { ValidationProvider } from 'vee-validate';
import Clip from 'atoms/icons/basic/Clip';

function getFile(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = (error) => reject(error);
    reader.onload = () => resolve(reader.result.replace(RegExp('data:.*;base64,'), ''));
    reader.readAsDataURL(file);
  });
}

function getFiles(files) {
  return Promise.all(files.map(async (file) => ({
    name: file.name,
    source: URL.createObjectURL(file),
    code: await getFile(file),
    type: file.type,
    blob: file,
  })));
}

export default {
  name: 'CUploading',
  components: {
    ValidationProvider,
    Clip,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
      default: () => ({ error: true }),
    },
    errorText: {
      type: String,
      default: 'Поле обязательно для заполнения',
    },
    validation: {
      type: Function,
      default: undefined,
    },
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png, .gif, .pdf',
    },
    acceptTitle: {
      type: String,
      default: 'JPEG, PNG, GIF, PDF или перетащите его в эту область.',
    },
    role: {
      type: String,
      default: '',
    },
    rules: { type: String, default: '' },
    rulesMessages: {
      type: Object, default: () => {
      },
    },
    vid: { type: String, default: '' },
    label: { type: String, default: '' },
    isClipView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previews: [],
      internalError: false,
    };
  },
  computed: {
    inputId() {
      return generateRandomString();
    },
    isInvalid() {
      return this.internalError;
    },
    isAdvancedUpload() {
      const div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.previews = val;
      },
    },
    previews: {
      immediate: false,
      handler(val) {
        this.handleValidate();
        this.$emit('input', val);
      },
    },
    error: {
      immediate: true,
      handler(val) {
        this.internalError = val.error;
      },
    },
  },
  methods: {
    async handleDrop($e) {
      const filesList = $e.dataTransfer.files;
      const files = [...filesList];

      if (!files.length) {
        return;
      }

      this.previews.push(...await getFiles(files));
      this.$emit('handleDrop');
    },
    async handleInput($e) {
      const filesList = $e.target.files || $e.dataTransfer.files;
      const files = [...filesList];

      if (!files.length) {
        return;
      }

      this.previews.push(...await getFiles(files));

      if ($e.target.files) {
        $e.target.value = null;
      }
    },
    handleValidate() {
      if (this.$validate.isUndefined(this.validation)) {
        return;
      }

      const isValid = this.validation(this.value);
      this.internalError = !isValid;
    },
    containerClick() {
      document.querySelector(`[role="${this.role}.input"]`).click();
      this.$emit('click');
    },
    mouseMove() {
      this.$emit('mousemove');
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  padding: 30px 120px
  text-align: center
  border: 1px dashed #979CA2
  cursor: pointer
  margin-top: 8px

  .label
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #0E0F0F

    span
      text-decoration: underline

  input
    display: none

.container:hover
  background: #F5F6F6

.invalid
  border-color: red

.errorText
  margin-top: 8px
  +small-text
  color: red

.clip
  cursor: pointer
</style>
