<template>
  <page title="Эксплуатация">
    <action-block/>
    <data-tab-selector
      v-if="tabs.length > 0"
      :data-types-list="tabs"
      tab-style="table"
      @selectTab="selectTabHandler"
    />


    <v-table-new
      v-if="selectedTab === 'exploitation'"
      v-model="tableData"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFilteredTransformed"
      :on-pagination-change="onPaginationChange"
      :page-out="adreskaSummary.pageOut"
      :template="template"
      excel-report-file-name="Эксплуатация. Список адресов"
      is-display-map-option
      server-side-sorting
      @change-mode="changeTableMode"
      @on-sort="handleSort"
      @on-cell-click="cellClickHandler"
      @tap-placemark="tapPlacemark"
    />

    <installation-constructions-report v-else-if="selectedTab === 'installation'"/>

    <dismantling-constructions-report v-else-if="selectedTab === 'dismantling'"/>

    <regular-inspection-list-table
      v-else-if="selectedTab === 'installation-control'" key="installation-control"
      :type="regularInspectionTaskType.INSTALLATION_CONTROL"
      excel-report-file-name="Эксплуатация. Контроль монтажа"
      table-name="installation-control"/>

    <regular-inspection-list-table
      v-else-if="selectedTab === 'dismantling-control'" key="dismantling-control"
      :type="regularInspectionTaskType.DISMANTLING_CONTROL"
      excel-report-file-name="Эксплуатация. Контроль демонтажа"
      table-name="dismantling-control"/>

    <regular-inspection-list-table
      v-else-if="selectedTab==='connection'" key="adreska-connection"
      :type="regularInspectionTaskType.ADRESKA_CONNECTION"
      excel-report-file-name="Эксплутация. Подключение"
      table-name="adreska-connection"/>

  </page>
</template>

<script>
import { DataTabSelector, Page, VTableNew } from '@/components';
import ActionBlock from './ActionBlock';
import { fetchData } from '@/api/rest/odk/api';
import InstallationConstructionsReport from 'views/private/OdkReports/reports/InstallationConstructionsReport.vue';
import DismantlingConstructionsReport from 'views/private/OdkReports/reports/DismantlingConstructionsReport.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { mapGetters } from 'vuex';
import { BASE_URL } from '@/constants/api';
import formatting from 'lib/utils/formatting';
import {
  adreskaStatusEnum,
  adreskaStatusList,
  adreskaStatusMap,
  regularInspectionTaskType,
} from 'components/Processes/list';
import RegularInspectionListTable from 'views/private/HandlingDefects/RegularInspectionsListTable.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { VTableMode } from 'components/VTableNew/constants';
import { flatMap } from 'lodash';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'Exploitation',
  components: {
    Page,
    ActionBlock,
    VTableNew,
    DataTabSelector,
    InstallationConstructionsReport,
    DismantlingConstructionsReport,
    RegularInspectionListTable,
  },
  created() {
    const tabsByAccountKind = this.isBoiler ? this.boilerTabs : this.counterPartyTabs;
    const blockAccess = this.getAuthorization?.blockAccess;
    this.tabs = tabsByAccountKind.filter((tab) => filterForAccess(`exploitation/${tab.value}`, blockAccess));
    if (this.tabs.length) {
      this.tabs[0].isActive = true;
      this.selectTabHandler(this.tabs[0]);
    }
  },
  data() {
    return {
      tableMode: null,
      regularInspectionTaskType: regularInspectionTaskType,
      adreskaSummary: [],
      mapSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
      boilerTabs: [
        {
          id: 1,
          title: 'Список адресов',
          value: 'exploitation',
          isActive: false,
        },
        {
          id: 2,
          title: 'Свод монтажа',
          value: 'installation',
          isActive: false,
        },
        {
          id: 3,
          title: 'Свод демонтажа',
          value: 'dismantling',
          isActive: false,
        },
        {
          id: 4,
          title: 'Контроль монтажа',
          value: 'installation-control',
          isActive: false,
        },
        {
          id: 5,
          title: 'Контроль демонтажа',
          value: 'dismantling-control',
          isActive: false,
        },
      ],
      counterPartyTabs: [
        {
          id: 1,
          title: 'Список адресов',
          value: 'exploitation',
          isActive: false,
        },
        {
          id: 2,
          title: 'Свод монтажа',
          value: 'installation',
          isActive: false,
        },
        {
          id: 3,
          title: 'Свод демонтажа',
          value: 'dismantling',
          isActive: false,
        },
      ],
      selectedTab: null,
      tableLineActions: [
        {
          id: 'process/7/1',
          title: 'Монтаж',
          name: 'cabinet.process.7.1',
        },
        {
          id: 'process/8/1-2',
          title: 'Назначение объектов на контроль монтажа',
          name: 'cabinet.process.8.1-2',
        },
        {
          id: 'process/8/3',
          title: 'Контроль монтажа',
          name: 'cabinet.process.8.3',
        },
        {
          id: 'process/8/4',
          title: 'Приёмка монтажа',
          name: 'cabinet.process.8.4',
        },
        {
          id: 'process/9/1-2',
          title: 'Назначение объектов на подключение/смену точки подключения',
          name: 'cabinet.process.9.1-2',
        },
        {
          id: 'process/10/2',
          title: 'Переобнарядка',
          name: 'cabinet.process.10.2',
        },
        {
          id: 'process/13/1',
          title: 'Демонтаж',
          name: 'cabinet.process.13.1',
        },
        {
          id: 'process/14/1-2',
          title: 'Назначение объектов на контроль демонтажа',
          name: 'cabinet.process.14.1-2',
        },
        {
          id: 'process/14/3',
          title: 'Контроль демонтажа',
          name: 'cabinet.process.14.3',
        },
        {
          id: 'process/14/4',
          title: 'Приёмка демонтажа',
          name: 'cabinet.process.14.4',
        },
        {
          id: 'process/11/1',
          title: 'Назначение объектов на осмотр',
          name: 'cabinet.process.11.1',
        },
        {
          id: 'process/11/3-4',
          title: 'Назначение объектов на регулярный осмотр',
          name: 'cabinet.process.11.3-4',
        },
      ],
      tabs: [],
    };
  },
  computed: {
    filteredTableLineActions() {
      return this.tableLineActions.filter((it) => filterForAccess(it.id, this.getAuthorization.blockAccess));
    },
    transformedAdreskaSummary() {
      if (!this.adreskaSummary || !this.adreskaSummary.data || !Array.isArray(this.adreskaSummary.data)) {
        return [];
      }

      return this.adreskaSummary.data.map(this.transformed);
    },
    transformedMapSummary() {
      if (!this.mapSummary || !this.mapSummary.data || !Array.isArray(this.mapSummary.data)) {
        return [];
      }

      return flatMap(this.mapSummary.data, this.transformedMap);
    },
    tableData() {
      return this.tableMode === VTableMode.MAP ? this.transformedMapSummary : this.transformedAdreskaSummary;
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Иллюстрация ОДК',
            key: 'preview',
            type: 'preview',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Мероприятие',
            key: 'eventName',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: { type: 'eventsOdk' },
          },
          {
            label: 'Программа оформления',
            key: 'adreskaName',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Адрес площадки эксплуатации',
            key: 'address',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Резервный адрес площадки эксплуатации',
            key: 'backupAddress',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование ОДК',
            key: 'odkName',
            thStyle: {
              minWidth: '105px',
            },
            size: 'lg',
            sort: {},
            filter: { type: 'odkOdk' },
          },
          {
            label: 'Статус',
            key: 'status',
            type: 'customStatus',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
              minWidth: '80px',
            },
            sort: {},
            filter: { type: 'vSearchSelectValue', values: adreskaStatusList },
          },
          {
            label: 'Наименование АХП',
            key: 'axpName',
            thStyle: {
              minWidth: '105px',
            },
            size: 'lg',
            sort: {},
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Количество ОДК по плану',
            type: 'number',
            key: 'quantityPlan',
            thStyle: {
              minWidth: '105px',
            },
            size: 'md',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество ОДК по факту',
            type: 'number',
            key: 'quantityFact',
            thStyle: {
              minWidth: '105px',
            },
            size: 'md',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Точка подключения ',
            key: 'connectionPoint',
            thStyle: {
              minWidth: '95px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Возможность подключения ДГУ или бензогенератора',
            key: 'isConnectedGenerator',
            thStyle: {
              minWidth: '115px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Административный округ г. Москвы установки ОДК',
            key: 'administrativeDistrictName',
            thStyle: {
              minWidth: '130px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Ответственный РЭС',
            key: 'responsibleDistrictDepartmentName',
            thStyle: {
              minWidth: '105px',
            },
            size: 'md',
            sort: {},
            filter: { type: 'responsibleDistrictDepartmentOdk' },
          },
          {
            label: 'Подрядчик',
            key: 'contractorName',
            sort: {},
            filter: this.contractorFilter,
            thStyle: {
              minWidth: '90px',
            },
          },
          {
            label: 'Ответственный подрядчик',
            key: 'responsibleContractor',
            type: 'list',
            spoilerCount: 3,
            thStyle: {
              minWidth: '210px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Мощность',
            key: 'axpPower',
            type: 'number',
            thStyle: {
              minWidth: '80px',
            },
            size: 'md',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Режим работы по плану',
            key: 'workModePlan',
            thStyle: {
              minWidth: '80px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Режим работы по факту',
            key: 'workModeFact',
            thStyle: {
              minWidth: '80px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Планируемая дата монтажа',
            key: 'planInstallationStartDate',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Фактическая дата монтажа',
            key: 'createDate',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Монтаж, %',
            key: 'installationCompletionPercentage',
            thStyle: {
              minWidth: '80px',
            },
            type: 'number',
            size: 'md',
            sort: {
              type: 'number',
            },
          },

          {
            label: 'Смонтировано подрядчиком',
            type: 'number',
            key: 'mountedByContractorCount',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Смонтировано подрядчиком, %',
            type: 'number',
            key: 'installationCompletionPercentage',
            size: 'md',
            thStyle: {
              minWidth: '110px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Монтаж принят',
            type: 'number',
            key: 'mountedAcceptedField',
            size: 'md',
            thStyle: {
              minWidth: '70px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Монтаж принят, %',
            type: 'number',
            key: 'mountedAcceptedPercentField',
            size: 'md',
            thStyle: {
              minWidth: '80px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Горение',
            type: 'number',
            key: 'lightCount',
            size: 'md',
            thStyle: {
              minWidth: '70px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Горение, %',
            type: 'number',
            key: 'lightPercent',
            size: 'md',
            thStyle: {
              minWidth: '80px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Назначено на осмотр',
            type: 'number',
            key: 'waitingInspectionCount',
            size: 'md',
            thStyle: {
              minWidth: '80px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Назначено на осмотр, %',
            type: 'number',
            key: 'waitingInspectionPercent',
            size: 'md',
            thStyle: {
              minWidth: '90px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Дефектных',
            type: 'number',
            key: 'waitingRepairCount',
            size: 'md',
            thStyle: {
              minWidth: '95px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Дефектных, %',
            type: 'number',
            key: 'waitingRepairPercent',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Отремонтировано',
            type: 'number',
            key: 'repairedCount',
            size: 'md',
            thStyle: {
              minWidth: '120px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Отремонтировано, %',
            type: 'number',
            key: 'repairedPercent',
            size: 'md',
            thStyle: {
              minWidth: '130px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Осмотрено успешно',
            type: 'number',
            key: 'inspectedCount',
            size: 'md',
            thStyle: {
              minWidth: '85px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Осмотрено успешно, %',
            type: 'number',
            key: 'inspectedPercent',
            size: 'md',
            thStyle: {
              minWidth: '85px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Демонтировано',
            type: 'number',
            key: 'dismantledCount',
            size: 'md',
            thStyle: {
              minWidth: '110px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Демонтировано, %',
            type: 'number',
            key: 'dismantledPercent',
            size: 'md',
            thStyle: {
              minWidth: '120px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Действие',
            key: 'actionButton',
            size: 'md',
            type: 'selectButton', thStyle: {
              minWidth: '300px',
            },
            customCheck: (item) => !item.actionButton.length,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        axpName: 'axpName',
        odkName: 'odkName',
        contractorName: 'contractorName',
        eventName: 'eventName',
        adreskaName: 'adreskaName',
        address: 'address',
        backupAddress: 'backupAddress',
        status: 'status',
        responsibleDistrictDepartmentName: 'responsibleDistrictDepartmentName',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });

      if (!this.isBoiler) {
        result.contractorId = this.getAccount.supplier.id;
      }
      return result;
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'getAuthorization', 'isBoiler']),
  },

  methods: {
    changeTableMode(mode) {
      const isFirstLoad = !this.tableMode;
      this.tableMode = mode;

      if (!isFirstLoad) {
        this.loadData();
      }
    },
    getStyleStatus(val) {
      switch (val) {
        case 'MOUNTING_IN_PROCESS':
          return '#82CDFF';
        case 'MOUNTED':
          return '#1E98FF';
        case 'IN_EXPLOITATION':
          return '#56DA40';
        case 'DISMANTLING_IN_PROCESS':
          return '#BC35FF';
        case 'DISMANTLED':
          return '#B3B3B3';
        case 'NOT_STARTED':
          return '#B3B3B3';
        default:
          return '#B3B3B3';
      }
    },
    async loadData() {
      if (!this.tableMode) return;

      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('adreska-summary', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.adreskaSummary = responseData;
        this.adreskaSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('adreskaSummary', error);
      } finally {
        this.loading = false;
      }

      if (this.tableMode === VTableMode.MAP) {
        this.mapSummary = await this.loadAllFiltered();
      }
    },
    async loadAllFilteredTransformed() {
      const responseData = await this.loadAllFiltered();

      return responseData.data?.map(this.transformed) ?? [];
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;

        const responseData = await fetchData('adreska-summary', 1, this.adreskaSummary.pageOut.totalElements, sortField, sort, filters);

        return responseData;
      } catch (error) {
        console.log('adreskaSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    async fetchReport() {
      this.loading = true;
      const filters = this.transformFilter;
      const { field: sortField, type: sort } = this.sort;
      const result = await fetchData('adreska-summary', 1, this.pagination.totalElements, sortField, sort, filters);
      this.loading = false;
      return result;
    },
    goToPageByRoute(path, params) {
      return () => this.$router.push({ name: path, params: params });
    },
    transformed(item, index) {
      return {
        ...item,
        index: index + 1,
        adreskaId: item.adreskaId,
        eventName: item.eventName,
        preview: {
          src: `${BASE_URL}/axp/${item.axpId}/preview`,
          alt: 'Изображение ОДК',
          previewExists: item.axpPhotosSize,
          notExistsMessage: 'Отсутствует',
        },
        axpName: item.axpName,
        contractorName: item.contractorName,
        adreskaName: item.adreskaName,
        address: item.address,
        backupAddress: item.backupAddress,
        quantityPlan: item.quantityPlan,
        quantityFact: item.quantityFact,
        status: adreskaStatusMap[item.status],
        statusEnum: item.status,
        installationCompletionAcceptance: item.installationCompletionAcceptance,
        installationCompletionPercentage: item.installationCompletionPercentage,
        workModeFact: item.workModeFact,
        workModePlan: item.workModePlan,
        connectionPoint: item.connectionPoint,
        addressConnectionPoint: item.addressConnectionPoint,
        isConnectedGenerator: convertBooleanToText(item.isConnectedGenerator),
        administrativeDistrictName: item.administrativeDistrictName,
        responsibleDistrictDepartmentName: item.responsibleDistrictDepartmentName,
        responsibleContractor: item.responsibles?.map((responsible, innerIdx) => ({
          key: `${index + 1}_${innerIdx}_responsible`,
          value: `${responsible.name}: ${formatting.phoneFormat(responsible.phone).formattedPhone}`,
        })) ?? [{
          key: `${index + 1}_responsible`,
          value: 'Не назначен',
        }],
        planInstallationStartDate: formatDateFromOdkTimestamp(item.planInstallationStartDate),
        planDismantlingStartDate: formatDateFromOdkTimestamp(item.planDismantlingStartDate),
        createDate: formatDateFromOdkTimestamp(item.createDate),
        mountedByContractorCount: item.mountedByContractorCount,
        mountedByContractorPercent: item.mountedByContractorPercent,
        dismantledCount: item.dismantledCount,
        dismantledPercent: item.dismantledPercent,
        waitingInspectionCount: item.waitingInspectionCount,
        waitingInspectionPercent: item.waitingInspectionPercent,
        waitingRepairCount: item.waitingRepairCount,
        waitingRepairPercent: item.waitingRepairPercent,
        repairedCount: item.repairedCount,
        repairedPercent: item.repairedPercent,
        inspectedCount: item.inspectedCount,
        inspectedPercent: item.inspectedPercent,
        lightCount: item.lightCount,
        lightPercent: item.lightPercent,
        odkName: item.odkName,
        axpPower: String(item.axpPower),
        dataColor: this.getStyleStatus(item.status),
        rowColor: 'rgb(255, 255, 255)',
        actionButton: this.filteredTableLineActions.map((action) => ({
          ...action,
          method: this.goToPageByRoute(action.name, { initAdreskaId: item.adreskaId, status: item.status }),
        })),
      };
    },
    transformedMap(item) {
      return flatMap(item.adreskaFactLocations, (it) => {
        let color;
        switch (item.status) {
          case adreskaStatusEnum.MOUNTING_IN_PROCESS:
            color = 'lightBlue';
            break;
          case adreskaStatusEnum.MOUNTED:
            color = 'blue';
            break;
          case adreskaStatusEnum.IN_EXPLOITATION:
            if (it.withDefect) {
              color = 'red';
            } else {
              color = 'green';
            }
            break;
          case adreskaStatusEnum.DISMANTLING_IN_PROCESS:
            color = 'violet';
            break;
          default:
            color = 'gray';
        }
        return {
          id: it.id,
          adreskaId: item.adreskaId,
          ...it,
          color: color,
          balloonContent: {
            title: item.odkName,
            buttonLabel: 'Перейти в карточку',
            props: [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'Мероприятие',
                value: item.eventName,
              },
              {
                label: 'АХП',
                value: item.axpName,
              },
              {
                label: 'Программа оформления',
                value: item.adreskaName,
              },
              {
                label: 'Адрес эксплуатации',
                value: item.address,
              },
              {
                label: 'Подрядчик',
                value: item.contractorName,
              },
              {
                label: 'Статус',
                value: adreskaStatusMap[item.status],
              },
            ],
          },
        };
      })
        ;
    },
    tapPlacemark(id) {
      const row = this.transformedMapSummary.find((it) => it.id === id);
      this.$router.push({
        name: 'cabinet.exploitation.card',
        query: { id: row.adreskaId },
      });
    },
    cellClickHandler(cell) {
      if (cell.key !== 'followLink') {
        return;
      }

      this.$router.push({
        name: 'cabinet.exploitation.card',
        query: { id: cell.row.adreskaId },
        params: {
          data: cell.row,
        },
      });
    }
    ,
  },
}
;
</script>

<style scoped>
</style>
