<template>
  <div class="wrp">
    <v-form style="width: 100%" @submit="onSave">
      <div class="page-form__form">

        <user-info
            :key="keyReload"
            v-model="user"
            :company="companyAndRoleName"
            :editable="isEditModeOn"
            :type="getAuthorizationType"
            :old-data="oldUserForRevert"
            @saveForRevert="saveForRevert"
        />

        <div v-if="isEditModeOn" class="row mb-24">
          <div class="spanCheckbox">
            <div>
              <input
                  v-model="agreement"
                  type="checkbox"
                  @change="onAgreementChange"
              />
            </div>&nbsp;
            <div class="spanCheckboxTextBlock">
              <span style="color: red">* </span>
              Я даю&nbsp;
              <a :href="policyLink" target="_blank">
                согласие на обработку своих персональных данных
              </a>
              и ознакомлен(а) с&nbsp;
              <a :href="personalDataProcessingPolicyLink" target="_blank">
                политикой обработки персональных данных.
              </a>
            </div>
          </div>
        </div>
        <div v-if="isEditable && isWaitingSubmit" class="row mb-16 plr-16">
          <horizontal-progress-bar/>
        </div>

        <div v-if="isEditable" class="row mb-44">
          <div class="col col--1of1 page-form__changes-buttons">
            <v-button
                :disabled="isWaitingSubmit || isEditModeOn"
                type="button"
                variant="outlined-colored"
                @click="openDeleteModal"
            >
              Отозвать персональные данные
            </v-button>
            <div v-if="isEditModeOn" style="display: flex; gap: 16px">
              <v-button
                  :disabled="!isEditModeOn"
                  class="button"
                  type="button"
                  variant="outlined-red"
                  @click="revertChange"
              >
                <cancel/>
                Отменить изменения
              </v-button>
              <v-button
                  :disabled="isWaitingSubmit || !changed || !isEditModeOn || !agreement"
                  class="button"
                  type="submit"
                  variant="outlined-colored"
              >
                <save/>
                Сохранить
              </v-button>
            </div>
            <v-button
                v-if="!isEditModeOn"
                :disabled="isEditModeOn"
                class="button"
                type="button"
                variant="outlined-colored"
                @click="changeEditMode"
            >
              <edit-without-circle/>
              Редактировать
            </v-button>
          </div>
        </div>
      </div>

      <modal
        v-if="isDeleteModalVisible"
        :body-style="{
          padding: '24px 0',
        }"
        :header-style="{
          display: 'flex',
          justifyContent: 'center',
        }"
        :is-backdrop="true"
        :is-btn-close-visible="false"
        :modal-style="{
          borderRadius: '16px',
          padding: '50px 100px',
        }"
        class="delete-user-data-modal"
      >
        <template #header>
          <span class="delete-user-data-modal__title">Подтвердите отзыв персональных данных</span>
        </template>
        <template #body>
          <span>При отзыве персональных данных также будет удалён Ваш профиль на Платформе.</span>
        </template>
        <template #footer>
          <div style="display: flex; justify-content: space-around;">
            <v-button
                type="button"
                @click="onDelete"
            >
              Подтвердить
            </v-button>
            <v-button
                type="button"
                variant="outlined"
                @click="closeDeleteModal"
            >
              Отменить
            </v-button>
          </div>
        </template>
      </modal>

      <personal-data-agreement-dialog
        :open-modal="isPolicyModalOpen"
        :user="user"
        :user-type="modifiedAuthorizationType"
        @close-modal="isPolicyModalOpen = false"
      />

    </v-form>
    <history v-if="history.length" :can-be-collapsed="false" :history="history"
             title="История"/>
  </div>
</template>

<script>
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import moment from 'moment/moment';
import UserInfo from 'components/UserInfo/UserInfo.vue';
import { isEqual, debounce } from 'lodash';

import { History, Modal, VButton } from '@/components';

import Save from 'atoms/icons/action/Save.vue';
import Cancel from 'atoms/icons/action/Cancel.vue';
import EditWithoutCircle from 'atoms/icons/action/EditWithoutCircle.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import VForm from 'components/VForm/VForm';
import PersonalDataAgreementDialog from 'components/PersonalDataAgreement/PersonalDataAgreementDialog.vue';
import { setDraft, removeDraft, getDraft } from '@/services/draft';

export default {
  components: {
    History,
    PersonalDataAgreementDialog,
    VForm,
    Modal,
    EditWithoutCircle,
    Cancel,
    Save,
    VButton,
    UserInfo,
    HorizontalProgressBar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      required: false,
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isPolicyModalOpen: false,
      isDeleteModalVisible: false,
      documentKind: '',
      documents: [],
      agreement: false,
      personalDataProcessingPolicyLink: '/policy-new/personal-data-processing-policy.pdf',
      user: {
        id: '',
        firstName: '',
        secondName: '',
        patronymic: '',
        firstNameGenitive: '',
        secondNameGenitive: '',
        patronymicGenitive: '',
        passport: {
          series: '',
          number: '',
          issueDate: '',
          issuer: '',
          subdivisionCode: '',
        },
        telegram: '',
        insuranceNumber: '',
        phone: '',
        additionalPhoneNumber: '',
        email: '',
        address: '',
        registrationAddress: '',
        inn: '',
        bankRequisites: {
          bankName: '',
          bik: '',
          settlementAccount: '',
          correspondentAccount: '',
        },
        birthDate: '',
      },
      errors: {
        firstName: { error: false },
        secondName: { error: false },
        patronymic: { error: false },
        firstNameGenitive: { error: false },
        secondNameGenitive: { error: false },
        patronymicGenitive: { error: false },
        series: { error: false },
        number: { error: false },
        issueDate: { error: false },
        issuer: { error: false },
        inn: { error: false },
        subdivisionCode: { error: false },
        insuranceNumber: { error: false },
        phone: { error: false },
        additionalPhoneNumber: { error: false },
        email: { error: false },
        address: { error: false },
        registrationAddress: { error: false },
        bankName: { error: false },
        bik: { error: false },
        settlementAccount: { error: false },
        correspondentAccount: { error: false },
        birthDate: { error: false },
      },
      documentsKind: [
        {
          label: 'Паспорт гражданина РФ',
          value: 'PASSPORT_RF',
        },
        {
          label: 'Вид на жительство',
          value: 'RESIDENCE_PERMIT_RF',
        },
        {
          label: 'Свидетельство о рождении',
          value: 'BIRTH_CERTIFICATE',
        },
        {
          label: 'Иное',
          value: 'OTHER',
        },
      ],
      oldUserForRevert: null,
      isEditModeOn: false,
      isWaitingSubmit: false,
      errorText: '',
      isEditable: !this.userId,
      companyAndRoleName: {},
      keyReload: 1,
    };
  },
  computed: {
    isCompletedSigningRights() {
      return this.user.identityDocumentInfo?.series?.length > 0 || this.user.identityDocumentInfo?.number?.length > 0 || this.user.identityDocumentInfo?.issuer?.length > 0 ||
          this.user.identityDocumentInfo?.subdivisionCode?.length > 0 || this.user.identityDocumentInfo?.issueDate?.length > 0 || this.user.inn?.length > 0 ||
          this.user.birthDate?.length > 0 || this.user.birthPlace?.length > 0 || this.user.address?.length > 0;
    },
    policyLink() {
      switch (this.getAuthorizationType) {
        case 'GENERAL_DIRECTOR':
          return '/policy-new/general-director-policy.pdf';
        case 'INDIVIDUAL':
          return '/policy-new/individual-policy.pdf';
        case 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return this.isCompletedSigningRights ? '/policy-new/with-rights-policy.pdf' : '/policy-new/without-rights-policy.pdf';
        case 'EMPLOYEE_WITH_SIGNING_RIGHTS':
          return '/policy-new/with-rights-policy.pdf';
        case 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return this.isCompletedSigningRights ? '/policy-new/counterparty-with-rights-policy.pdf' : '/policy-new/counterparty-without-rights-policy.pdf';
        case 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS':
          return '/policy-new/counterparty-with-rights-policy.pdf';
        default:
          return '/policy-new/user-policy.pdf';
      }
    },
    modifiedAuthorizationType() {
      switch (this.getAuthorizationType) {
        case 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return this.isCompletedSigningRights ? 'EMPLOYEE_WITH_SIGNING_RIGHTS' : 'EMPLOYEE_WITHOUT_SIGNING_RIGHTS';
        case 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS':
          return this.isCompletedSigningRights ? 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS' : 'COUNTERPARTY_EMPLOYEE_WITHOUT_SIGNING_RIGHTS';
        default:
          return this.getAuthorizationType;
      }
    },
    isScreenWidthLess1000px() {
      return window.matchMedia('(max-width: 1000px)').matches;
    },
    userFullName() {
      const fullName = {
        name: this.checkFullnameField(this.user.firstName),
        surname: this.checkFullnameField(this.user.secondName),
        patronymic: this.checkFullnameField(this.user.patronymic),
      };
      return `${fullName.surname} ${fullName.name} ${fullName.patronymic}`;
    },
    changed() {
      return !isEqual(this.user, this.oldUserForRevert);
    },
    birthStartMoment() {
      return moment().subtract(18, 'years');
    },
    birthStartDate() {
      return this.birthStartMoment.toDate();
    },
    authorization() {
      if (this.userId) {
        if (this.isPlatformWorker) {
          if (this.accountId) {
            return this.user.authorizations?.filter((item) => item.account?.id === this.accountId)[0];
          }
          return this.user.authorizations ? this.user.authorizations[0] : undefined;
        }
        return this.user.authorizations?.filter((item) => item.account?.profile.id === this.getAuthorization.authorization.account.profile.id)[0];
      }
      return this.getAuthorization?.authorization;
    },
    history() {
      return this.authorization?.history?.filter((it) => it.role)?.map((it) => ({
        datetime: it.updatedAt,
        message: `Переведен на должность ${it.role.name}`,
      })) ?? [];
    },
    getAuthorizationType() {
      return this.authorization?.type;
    },
    ...mapGetters('user', ['getUser', 'getUserType', 'getAuthorization', 'getAuthorizationType', 'getAccount', 'isPlatformWorker']),
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
    modifiedAuthorizationType(oldVal, newVal) {
      if (oldVal !== newVal) this.agreement = false;
    },
    user: {
      deep: true,
      handler() {
        if (this.isEditModeOn) {
          this.saveDraft();
        }
      },
    },
    isEditModeOn() {
      if (!this.isEditModeOn) {
        removeDraft(`${this.getAccount.id}${this.userId ? this.userId : this.getUser.id}userProfile`);
      }
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setUser', 'getUserById', 'logOut']),
    ...mapMutations('user', ['LOGOUT_USER']),
    onAgreementChange() {
      if (this.agreement) this.isPolicyModalOpen = true;
    },
    changeEditMode() {
      this.isEditModeOn = !this.isEditModeOn;
    },
    async loadData() {
      if (this.userId) {
        if (!this.getAuthorization.authorization.role.isCompanyOwner) {
          this.$router.go(-1);
          return;
        }

        this.updateData(
            await this.getUserById({ id: this.userId }),
        );
      } else {
        await this.setUser();
        this.updateData(this.getUser);
      }

      await this.loadDraft();

      this.setCompanyAndRoleName();

      this.isEditable = !this.userId;
    },
    updateData(loadedUser) {
      console.log('on update data', loadedUser);
      this.user = loadedUser;
      this.user = {
        ...loadedUser,
        insuranceNumber: loadedUser.snils,
        telegram: loadedUser.telegramAccount,
        firstName: loadedUser.fullName?.name,
        secondName: loadedUser.fullName?.surname,
        patronymic: loadedUser.fullName?.patronymic,
        phone: loadedUser.phoneNumber,
        firstNameGenitive: loadedUser.fullNameGenitiveCase?.name,
        secondNameGenitive: loadedUser.fullNameGenitiveCase?.surname,
        patronymicGenitive: loadedUser.fullNameGenitiveCase?.patronymic,
        additionalPhoneNumber: this.authorization?.phoneNumber,
        additionalEmail: this.authorization?.email,
        inn: this.authorization?.inn,
        ogrnip: this.authorization?.ogrnip,
        birthDate: this.authorization?.birthDate,
        birthPlace: this.authorization?.birthPlace,
        citizenship: this.authorization?.citizenship,
        registrationAddress: this.authorization?.registrationAddress,
        address: this.authorization?.liveAddress,
        identityDocumentInfo: this.authorization?.identityDocumentInfo ?? {},
        bankRequisites: this.authorization?.bankRequisites ?? {},
      };

      this.saveForRevert(this.user);
    },
    async onSave() {
      this.isWaitingSubmit = true;
      this.errorText = '';
      console.log('user', this.user);
      const xml = generateXml.UpdateUserDataApplication(this.user, this.authorization?.account?.id, this.agreement);

      try {
        await this.documentUpload({ xml: xml });
        await this.setUser();
        this.isEditModeOn = false;
      } catch (e) {
        console.log(e);
        const errorData = HTTPError.network(e);
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${errorData.message}`,
        });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async onDelete() {
      this.isWaitingSubmit = true;
      this.closeDeleteModal();
      const xml = generateXml.DeleteUserProfileApplication(this.user);
      try {
        await this.documentUpload({ xml: xml });
        localStorage.setItem(`application_user_delete_profile${this.getUser.id}`, 'true');
        this.vueShowNotification('Запрос отправлен', '<p>Ваш запрос на отзыв персональных данных и удаление профиля на Платформе отправлен</p>');
        await this.$router.push('/');
      } catch (e) {
        console.log(e);
        if (HTTPError.network(e).code === HTTPError.code.PERMISSION_DENIED) {
          console.log(e.graphQLErrors[0].extensions.errorType);
          this.LOGOUT_USER();
          this.SET_NOTIFICATION({
            notificationType: 'error',
            notificationText: 'Ваша сессия устарела',
          });
          await this.$router.push('/login');
          return;
        }
        const errorMessage = e.message.split(': ')[2];
        this.vueShowNotification(
            '',
            `<p style="color: red">${errorMessage}</p>`,
        );
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    revertChange() {
      this.user = JSON.parse(JSON.stringify(this.oldUserForRevert));
      this.agreement = false;
      this.isEditModeOn = false;
      this.keyReload++;
    },
    checkOwner() {
      return this.getAuthorization?.authorization.role.isCompanyOwner;
    },
    parsePhoneNumber(number) {
      return number.substring(number.length - 10);
    },
    clearErrors() {
      Object.keys(this.errors).forEach((key) => {
        this.errors[key].error = false;
      });
    },
    checkFullnameField(field) {
      if (field) {
        return field;
      }

      return '';
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    openDeleteModal() {
      if (this.isWaitingSubmit || this.isEditModeOn) {
        return;
      }
      this.isDeleteModalVisible = true;
    },
    setCompanyAndRoleName() {
      this.companyAndRoleName = {
        name: this.checkFullnameField(this.authorization?.account?.profile?.name),
        roleName: this.checkFullnameField(this.authorization?.role?.name),
      };
    },
    saveForRevert(oldUser) {
      this.oldUserForRevert = JSON.parse(JSON.stringify(oldUser));
    },
    saveDraft: debounce(function() {
      setDraft(this.getAccount.id, this.user, `${this.userId ? this.userId : this.getUser.id}userProfile`);
    }, 500),
    async loadDraft() {
      if (!this.getAccount) {
        return false;
      }

      const userId = this.userId ? this.userId : this.getUser.id;

      const draft = await getDraft(`${this.getAccount.id}${userId}userProfile`);

      if (!draft) {
        return false;
      }

      this.user = draft;
      this.isEditModeOn = true;

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.wrp {
  display: flex;
  flex-direction: row;

  @media (max-width: 860px) {
    flex-direction: column;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 11px;
}

.spanCheckbox {
  vertical-align: middle;
  display: flex;
  padding: 0 16px;

  input[type='checkbox'] {
    width: 18px;
    height: 18px;

    margin-right: 8px;
  }

  &TextBlock {
    align-self: center;
  }
}

.delete-user-data-modal {
  &__sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 24px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  &__position-fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #71757A;
  }

  &__position {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

}

</style>

