<template>
  <modal
    :body-style="{
      padding: '24px 0',
    }"
    :is-backdrop="true"
    :is-btn-close-visible="false"
    :modal-style="{
      borderRadius: '16px',
      padding: '50px 100px',
    }"
  >
    <template #header>
      <h1 class="connection-user-by-contract-modal__title">Подключиться по договору энергоснабжения</h1>
    </template>
    <template #body>
      <v-form
        class="connection-user-by-contract-modal__form"
        @submit="sendConnectionUserBySupplyContractApplication"
      >
        <div>
          <v-input
            v-model="connectionUserBySupplyContractApplication.number"
            rules="required"
            title="Номер ДЭС *"
          />
          <v-date-picker
            v-model="connectionUserBySupplyContractApplication.date"
            class="connection-user-by-contract-modal__form-date-picker-error-margin"
            rules="required"
            style="display: block; margin-top: 30px;"
            title="Дата заключения договора *"
          />
        </div>
        <div style="display: flex; justify-content: space-around; margin-top: 15px;">
          <v-button
            type="button"
            variant="outlined-red"
            @click="hideContractModal"
          >
            Отменить
          </v-button>
          <v-button
            type="submit"
            variant="outlined"
          >
            Отправить
          </v-button>
        </div>
      </v-form>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Modal, VButton, VDatePicker, VForm, VInput } from '@/components';
import { checkField } from '@/utils/common';
import HTTPError from 'lib/utils/errors';
import { formatDateToXmlDateFormat } from 'lib/utils/date';
import generateXml from 'lib/storage/connection-application/data';

export default {
  components: {
    VForm,
    VInput,
    Modal,
    VButton,
    VDatePicker,
  },
  data() {
    return {
      connectionUserBySupplyContractApplication: {
        number: '',
        date: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getUserType', 'getUser']),
  },
  methods: {
    hideContractModal() {
      this.$emit('close');
    },
    async sendConnectionUserBySupplyContractApplication() {
      const fullName = `${this.getUser.fullName.surname} ${this.getUser.fullName.name} ${checkField(this.getUser.fullName?.patronymic)}`;

      const xml = generateXml.GenerateConnectionUserBySupplyContractApplicationXML({
        contractNumber: this.connectionUserBySupplyContractApplication.number,
        contractDate: formatDateToXmlDateFormat(this.connectionUserBySupplyContractApplication.date),
        fullName: fullName,
        snils: this.getUser.snils,
        userId: this.getUser.id,
      });

      try {
        await this.documentUpload({ xml });
        this.$emit('close');
        this.setNotification({ type: 'success', message: 'Заявка отправлена на рассмотрение' });
      } catch (error) {
        const { message } = HTTPError.network(error);
        this.$emit('close');
        this.setNotification({ message: message });
      }
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss">
.connection-user-by-contract-modal {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  &__form {
    text-align: left;


    &-date-picker-error-margin {
      div.date-picker-container {
        p.errorText {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
