<template>
  <div class="container">
    <div class="technological-connection-act-header">
      <button
        class="btn-back"
        @click="back"
      >
        Назад
      </button>
      <navigation-bar
        class="navigator"
        :current-step="currentStep"
        :steps="steps"
        @open="openStep"
      />
    </div>
    <v-divider class="divider"/>

    <send-connection-certificate :hide-status="true"/>
  </div>
</template>

<script>
import SendConnectionCertificate from 'views/private/ConnectionApplication/Task/actions/SendConnectionCertificate';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import VDivider from 'components/VDivider/VDivider';

export default {
  components: {
    VDivider,
    NavigationBar,
    SendConnectionCertificate,
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        {
          stepNumber: {
            value: 1,
            from: 2,
            title: () => `${this.fromTitle}`,
          },
        },
        {
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Акт об осуществлении технологического присоединения',
          },
        },
      ],
    };
  },
  computed: {
    fromTitle() {
      return this.$route.fullPath === '/cabinet/acts-implementation-technological-connection/act-of-technological-connection'
        ? 'Акты об осуществлении технологического присоединения'
        : 'Реестр точек учёта';
    },
  },
  methods: {
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
      this.$router.go(-1);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 8px 32px 56px 0;
  width: 848px;
  padding-bottom: 44px;
  padding-left: 0;
  .divider {
    margin-bottom: 18px;
  }
  .technological-connection-act-header {
    display: flex;
    margin: 0 0 8px 12px;
    .navigator {
      margin: unset;
    }

    .btn-back {
      width: 86px;
      height: 24px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #677379;
      padding-left: 12px;
      margin-right: 24px;
      background-image: url('~icons/navigation/arrow_left.svg');
      background-repeat: no-repeat;
      background-position: center left 4px;
      background-color: #FFFFFF;
      border: 1px solid #C1C4C7;
      border-radius: 24px;

      &:hover {
        background-color: #F5F6F6;
      }
    }
  }

}
</style>
