<template>
  <div :class="$style.container">
    <div role="detailsHeader"></div>
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="close">ЗАДАЧИ</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>Отчёт о снятии показаний ПУ в автоматическом режиме</span>
      <span v-if="steps[currentStep].stepNumber">&nbsp;{{ steps[currentStep].stepNumber.value }}/{{ steps[currentStep].stepNumber.from }}</span>
    </p>

    <div :class="$style.content">
      <component
        :is="steps[currentStep].component"
        :task="task"
        :step-number="steps[currentStep].stepNumber"
        @close="close"
        @complete="complete"
        @next="next"
      />
    </div>

    <div :class="$style.contentFooter">
      <div v-show="displayBack" :class="$style.back" @click="back">
        <arrow-left />
        <span>НАЗАД</span>
      </div>
      <div :class="$style.center">&nbsp;</div>
      <div v-show="displayNext" :class="$style.next" @click="next">
        <span>ДАЛЬШЕ</span>
        <arrow-right />
      </div>
    </div>

  </div>
</template>

<script>
import MeterReport from 'organisms/tasks/reports/meter-report/MeterReport';
import ArrowLeft from 'atoms/icons/arrows/ArrowLeft';
import ArrowRight from 'atoms/icons/arrows/ArrowRight';
import { scrollIntoView } from 'lib/utils/DOM';

export default {
  name: 'Details',
  components: {
    MeterReport,
    ArrowLeft,
    ArrowRight,
  },
  props: {
    task: Object,
    index: Number,
  },
  data() {
    return {
      steps: [
        {
          component: 'MeterReport',
          isComplete: false,
        },
      ],
      currentStep: 0,
    };
  },
  computed: {
    displayBack() {
      return this.currentStep > 1;
    },
    displayNext() {
      return this.currentStep > 0 && this.steps[this.currentStep].isComplete;
    },

  },
  methods: {
    close() {
      this.$emit('close');
    },
    back() {
      if (this.currentStep === 0) {
        return;
      }
      this.currentStep -= 1;
    },
    next() {
      this.currentStep += 1;

      scrollIntoView(
        document.querySelector('[role=detailsHeader]'),
      );
    },
    complete(isComplete) {
      this.steps[this.currentStep].isComplete = isComplete;
    },
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    background-color: #fff

  .content
    flex-grow: 2
    overflow-y: auto

  .navigationBar
    width: 100%
    background-color: #FFFFFF
    z-index: 999
    +title-small-uppercase
    margin: 8px 16px 0 0
    padding-left: 16px

    .navigationRoot, .navigationSeparator
      color: #979CA2
      cursor: pointer

    .navigationSeparator
      font-size: 24px
      line-height: 24px

  .contentFooter
    height: 48px
    display: flex

  .back, .next
    +button-text
    color: #71757A
    margin-bottom: 16px
    display: flex
    align-items: center
    cursor: pointer

    svg
      fill: #71757A

  .center
    flex-grow: 2

  .back
    margin-left: 16px

    span
      margin-left: 13px

  .next
    margin-right: 36px

    span
      margin-right: 13px
</style>
