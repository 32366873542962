<template>
  <page title="Инфраструктурные платежи">
    <v-table-new
      v-model="transformedTariffInfrastructurePayments"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="tariffInfrastructurePaymentsPage.pageOut"
      :template="template"
      excel-report-file-name="Инфраструктурные платежи"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'TariffInfrastructurePayments',
  components: {
    VTableNew,
    Page,
  },
  data() {
    return {
      tariffInfrastructurePaymentsPage: {},
      filter: {},
      loading: false,
      pagination: initialPagination,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Название приказа',
            key: 'tariffOrderName',
            sort: {},
            filter: {},
          },
          {
            label: 'Год',
            key: 'year',
            sort: { type: 'date' },
            filter: { type: 'date', format: 'YYYY', selectType: 'year' },
          },
          {
            label: 'Тариф на услуги коммерческого оператора, оказываемые АО "АТС"',
            type: 'number',
            key: 'complexServicesTariffRate',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Тариф на услуги по оперативно-диспетчерскому управлению, оказываемые АО "СО ЕЭС"',
            type: 'number',
            key: 'commercialServicesTariffRate',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Тариф за комплексную услугу, оказываемую АО "ЦФР"',
            type: 'number',
            key: 'operationalManagementTariffRate',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffInfrastructurePayments() {
      return this.tariffInfrastructurePaymentsPage.list ?? [];
    },
    transformedTariffInfrastructurePayments() {
      return this.tariffInfrastructurePayments.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffInfrastructurePaymentsPage = await this.tariffInfrastructurePaymentsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffInfrastructurePaymentsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffInfrastructurePayments = await this.tariffInfrastructurePaymentsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffInfrastructurePaymentsPage.pageOut.totalElements,
          },
        });

        if (!tariffInfrastructurePayments?.list) {
          return [];
        }

        return tariffInfrastructurePayments.list.map(this.transformed);
      } catch (e) {
        console.log('tariffInfrastructurePaymentsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    transformed(it) {
      return {
        year: it.tariffsOrder?.year,
        tariffOrderName: it.tariffsOrder?.orderName,
        complexServicesTariffRate: it.complexServicesTariffRate,
        commercialServicesTariffRate: it.commercialServicesTariffRate,
        operationalManagementTariffRate: it.operationalManagementTariffRate,
        document: { documentId: it.tariffsOrder?.documentId, name: 'Инфраструктурные платежи' },
      };
    },
    ...mapActions('dataLists', ['tariffInfrastructurePaymentsByPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>

</style>
