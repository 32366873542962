<template>
  <v-table-new
    v-model="transformedOrders"
    :action-block-items="actionBlockItems"
    :loading="loading"
    :template="template"
    excel-report-file-name="Эксплуатация. Платежные поручения"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { getContractStatusLabelValueList } from 'lib/utils/formatXmlType';
import formatting from 'lib/utils/formatting.js';
import { convertDbFormat } from 'lib/utils/date.js';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'PaymentOrdersContentBlock',
  components: { VTableNew },
  props: {
    loading: { type: Boolean, required: true },
    paymentOrders: { type: Array, required: true },
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
            },
          },
          {
            label: 'Наименование документа',
            key: 'name',
            thStyle: {
              maxWidth: '200px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Дата документа',
            key: 'createdAt',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Сумма платежа, руб',
            type: 'number',
            key: 'totalCost',
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Статус',
            key: 'status',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusLabelValueList() },
          },
          {
            label: 'Номер договора',
            key: 'contractNumber',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата договора',
            key: 'contractDate',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Плательщик',
            key: 'payer',
            sort: {},
            filter: {},
          },
          {
            label: 'Получатель',
            key: 'payee',
            sort: {},
            filter: {},
          },
          {
            label: 'Ссылки на скачивание документа',
            key: 'documentId',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Сформировано автоматически',
            key: 'autoGenerated',
            sort: {},
          },
        ],
      };
    },
    transformedOrders() {
      if (this.paymentOrders.length === 0) return [];

      return this.paymentOrders.map((it) => ({
        name: it.title,
        createdAt: convertDbFormat(it.createdAt),
        totalCost: formatting.numberFormatted(it.totalCost),
        status: this.formatPaymentOrderStatus(it.status),
        contractNumber: it.reasonDocument?.contractNumber,
        contractDate: convertDbFormat(it.reasonDocument?.contractDate),
        payer: it.payer.name,
        payee: it.payee.name,
        documentId: { documentId: it.documentId, name: it.title },
        autoGenerated: it.autoGenerated ? 'Сформировано автоматически' : '',
      }));
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Создать платёжное поручение',
          method: goToPageByRoute('generate-payment-order'),
        },
      ];
    },
  },
  methods: {
    formatPaymentOrderStatus(value) {
      // TODO временно пока нет интеграции с банком
      switch (value) {
        case 'DRAFT': {
          return 'Не оплачен';
        }
        default: {
          return '-';
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
