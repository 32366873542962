<template>
  <v-form class="page-form" @submit="onSubmit" @error="onError">
    <div>
      <div v-if="!hideStatus">
        <p class="page-form__title">
          Выбрать статус заявки
        </p>
        <div class="page-form__controlDiv">
          <label class="page-form__controlDiv-label">
            <div class="control-contentRadioGroup">
              <div class="control-titleContainer">
                <div :class="getCheckboxClass(applicationStatus === 1)"></div>
                <h3 class="control-titleRadioGroup">Выполнена</h3>
              </div>
            </div>
            <input
                :checked="applicationStatus === 1"
                class="control"
                name="applicationStatus"
                type="radio"
                :disabled="isLoading"
                @click="applicationStatusClick(1)"
            />
          </label>
          <label class="page-form__controlDiv-label">
            <div class="control-contentRadioGroup">
              <div class="control-titleContainer">
                <div :class="getCheckboxClass(applicationStatus === 2)"></div>
                <h3 class="control-titleRadioGroup">Аннулирована</h3>
              </div>
            </div>
            <input
                :checked="applicationStatus === 2"
                class="control"
                name="applicationStatus"
                type="radio"
                :disabled="isLoading"
                @click="applicationStatusClick(2)"
            />
          </label>
        </div>

        <v-divider class="mt-24 mb-24" />

      </div>

      <div v-if="applicationStatus === 2">
        <p>* обязательно заполните это поле</p>

        <div class="row mb-16">
          <p class="col col--1of1 page-form__sub-title">Указать причину аннулирования заявки *</p>
        </div>
        <div class="row mb-16">
          <c-textarea v-model="cancelledComments"
                      class="col col--1of1"
          />
        </div>

        <div>
          <div class="row mb-16">
            <p class="col col--1of1 page-form__sub-title">
              Приложить необходимые документы
            </p>
          </div>

          <div v-if="cancelledDocs.length" class="row mb-16">
            <file-list-preview
              :list="cancelledDocs"
              action
              class="col col--1of1"
              @delete="handleRemoveCancelledDocs"
            />
          </div>

          <div class="row mb-16">
            <c-uploading
              v-model="cancelledDocs"
              class="col col--1of1"
            />
          </div>
        </div>

      </div>

      <div v-if="applicationStatus === 1">
        <div>
          <div class="row mb-24">
            <p class="col col--1of1 page-form__sub-title">Акт об осуществлении технологического присоединения</p>
          </div>

          <div class="row mb-24">
            <v-input v-model="ConnectionCertificate.Number" class="col col--1of1" disabled
                     title="Номер документа (DID) *"/>
          </div>
          <div class="row mb-24">
            <v-input v-model="ConnectionCertificate.Utility.Name" class="col col--1of1" disabled
                     title="Наименование сетевой организации *"/>
          </div>

          <div class="row mb-24">
            <v-input v-model="ConnectionCertificate.Utility.RepresentativeFullName" class="col col--1of1" disabled
                     title="Представитель сетевой организации *"/>
          </div>

          <div class="row">
            <signing-basis-selector :input-mode-type="basisSelectorMode" :power-of-attorney-list="attorneys"
                                    class="col col--1of1"
                                    @select="(val) => ConnectionCertificate.Utility.CompanyDocument = val"/>
          </div>

          <div class="row mb-24">
            <v-input v-model="ConnectionCertificate.Retailer.Name" class="col col--1of1" disabled
                     title="Наименование энергосбытовой организации *"/>
          </div>

          <div class="row mb-24">
            <div class="col col--1of1">
              <emploee-select-or-input
                  mode="select"
                  :inn="applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn"
                  :not-emit-input-reason="true"
                  permission="SVET_A3-S"
                  @emploeeSelectOrInput="selectEmploee"
              />
            </div>
          </div>

          <div class="row mb-24">
            <v-input v-model="ConnectionCertificate.Consumer.Name" class="col col--1of1" disabled
                     title="ФИО или наименование потребителя или его представителя *"/>
          </div>
          <!--TODO вернуть -->
          <!--          <div :class="$style.blockContent">-->
          <!--            <c-input-->
          <!--              v-model="ConnectionCertificate.CustomerRepresentative"-->
          <!--              label="Ф.И.О. лица – представителя заявителя"-->
          <!--              role="CustomerRepresentative"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div :class="$style.blockContent">-->
          <!--            <c-input-->
          <!--              v-model="ConnectionCertificate.CustomerDocument"-->
          <!--              label="Устав, доверенность, иной документ заявителя"-->
          <!--              role="CustomerDocument"-->
          <!--            />-->
          <!--          </div>-->

          <div v-if="applicationStatus" class="row mb-24">
            <v-divider/>
          </div>

          <v-spoiler class="row mb-24" summary="1 | Договор об осуществлении технологического присоединения"
                     :is-open="isOpenSpoiler.spoiler1" w100 @toggle="toggleSpoiler('spoiler1')">
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionAgreement.Number"
                       class="col col--1of2"
                       disabled
                       title="Номер *"
              />
              <v-date-picker v-model="ConnectionCertificate.ConnectionAgreement.Date"
                             class="col col--1of2"
                             disabled
                             title="Дата *"
              />
            </div>

            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionAgreement.AmountFormatting" class="col col--1of1" disabled
                       title="Сумма *"/>
            </div>

            <div class="row mb-24"><p class="col col--1of1 page-form__sub-title">Мероприятия по технологическому
              присоединению выполнены в
              соответствии с
              техническими условиями</p>
            </div>

            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.TechnicalSpecification.Number"
                       class="col col--1of2"
                       disabled
                       title="Номер *"
              />
              <v-date-picker v-model="ConnectionCertificate.TechnicalSpecification.Date"
                             class="col col--1of2"
                             disabled
                             title="Дата *"
              />
            </div>

            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">Объекты электроэнергетики (энергопринимающие устройства)</p>
            </div>
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionAddress" class="col col--1of1" disabled
                       title="Адрес *"/>
            </div>

            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">Акт о выполнении технических условий</p>

            </div>
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.TechnicalCertificate.Number"
                       class="col col--1of2"
                       disabled
                       rules="required"
                       vid="ConnectionCertificate.TechnicalCertificate.Number"
                       title="Номер *"
              />
              <v-date-picker v-model="ConnectionCertificate.TechnicalCertificate.Date"
                             class="col col--1of2"
                             disabled
                             rules="required"
                             vid="ConnectionCertificate.TechnicalCertificate.Date"
                             title="Дата *"
              />
            </div>

            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">Характеристики присоединения</p>
            </div>
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionCharacteristics.NewMaxPower" class="col col--1of1"
                       disabled
                       title="Максимальная мощность, кВт *"/>
            </div>
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionCharacteristics.ReliabilityClass" class="col col--1of1"
                       disabled
                       title="Категория надежности *"/>
            </div>
            <div v-if="onlyMicrogenerator" class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.ConnectionCharacteristics.MicrogenerationMaxPower" class="col col--1of1"
                  disabled
                  title="Максимальная мощность объектов микрогенерации, кВт *"/>
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="2 | Перечень точек присоединения" :is-open="isOpenSpoiler.spoiler2" w100
                     @toggle="toggleSpoiler('spoiler2')">
            <div v-for="(point, index) in ConnectionCertificate.ConnectionPoints.ConnectionPoint"
                 :key="'ConnectionPoint_' + index">
              <div class="row mb-24">
                <v-input
                    :value="`${index + 1}`"
                    class="col col--1of1"
                    disabled
                    title="Номер *"
                />
              </div>
              <div class="row mb-24">
                <usage-point-connection-select v-if="point.PowerSupplyCenter"
                                               v-model="point.PowerSupplyCenter"
                                               class="col col--1of1" disabled/>
              </div>
              <div class="row mb-24">
                <v-input
                    v-model="point.Description"
                    class="col col--1of1"
                    disabled
                    title="Описание точки присоединения *"
                />
              </div>
              <div class="row mb-24">
                <v-input
                    :value="point.VoltageLevel"
                    class="col col--1of2"
                    disabled
                    title="Уровень напряжения, В *"
                />
                <v-input
                    v-model="point.MaxPower"
                    class="col col--1of2"
                    disabled
                    title="Максимальная мощность, кВт *"
                />
              </div>

              <div v-if="onlyMicrogenerator" class="row mb-24">
                <v-input
                    v-model="point.MicrogenerationMaxPower"
                    class="col col--1of2"
                    title="Максимальная мощность объектов микрогенерации, кВт *"
                />
                <v-input
                    v-model="point.TotalTransformerPowerRating"
                    class="col col--1of2"
                    title="Величина номинальной мощности присоединенных трансформаторов, кВА *"
                />
              </div>

              <div class="row mb-24">
                <v-input
                    v-model="point.ReactivePowerFactor"
                    class="col col--1of1"
                    rules="required"
                    :vid="'point.ReactivePowerFactor_' + index"
                    title="Предельное значение коэффициента реактивной мощности (tg φ) *"
                />
              </div>
            </div>

            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">Границы балансовой принадлежности объектов электроэнергетики
                (энергопринимающих устройств) и эксплуатационной ответственности сторон:</p>
            </div>

            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.BalanceAffiliationBoundary"
                  class="col col--1of1"
                  rules="required"
                  vid="ConnectionCertificate.BalanceAffiliationBoundary"
                  title="Описание границ балансовой принадлежности объектов электроэнергетики (энергопринимающих устройств) *"
              />
            </div>

            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.OperationalResponsibilityBoundary"
                  class="col col--1of1"
                  rules="required"
                  vid="ConnectionCertificate.OperationalResponsibilityBoundary"
                  title="Описание границ эксплуатационной ответственности сторон *"
              />
            </div>
          </v-spoiler>


          <v-spoiler class="row mb-24" summary="3 | У сторон на границе балансовой принадлежности объектов
              электроэнергетики (энергопринимающих устройств) находятся следующие
              технологически соединенные элементы электрической сети" :is-open="false" w100>
            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.GridCompanyEquipment"
                  class="col col--1of1"
                  title="Наименование электроустановки (оборудования) сетевой организации"
              />
            </div>

            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.CustomerEquipment"
                  class="col col--1of1"
                  title="Наименование электроустановки (оборудования) заявителя"
              />
            </div>

            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">У сторон в эксплуатационной ответственности находятся
                следующие
                технологически соединенные элементы электрической сети:</p>
            </div>

            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.GridCompanyResponsibleEquipment"
                  class="col col--1of1"
                  title="Наименование электроустановки (оборудования), находящейся в эксплуатации сетевой организации"
              />
            </div>

            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.CustomerResponsibleEquipment"
                  class="col col--1of1"
                  title="Наименование электроустановки (оборудования), находящейся в эксплуатации заявителя"
              />
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="4 | Устройства защиты, релейной защиты, противоаварийной и режимной
              автоматики" :is-open="false" w100>
            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.ProtectiveDevice"
                  class="col col--1of1"
              />
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="5 | Автономный резервный источник питания (установки, тип,
                мощность и др.)" :is-open="false" w100>
            <div class="row mb-24">
              <v-input
                  v-model="ConnectionCertificate.OffGridPowerSupply"
                  class="col col--1of1"
              />
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="6 | Прочие сведения" :is-open="false" w100>
            <div class="row mb-24">
              <c-textarea
                  v-model="ConnectionCertificate.SpecialNotes"
                  class="col col--1of1"
              />
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="7 | Схематично границы балансовой принадлежности"
                     :is-open="isOpenSpoiler.spoiler7" w100 @toggle="toggleSpoiler('spoiler7')">
            <div class="row mb-24">
              <p class="col col--1of1 page-form__sub-title">
                Приложите скан схемы *
              </p>
            </div>

            <div v-if="ConnectionCertificate.BoundaryScheme.length" class="row mb-24">
              <file-list-preview :list="ConnectionCertificate.BoundaryScheme"
                                 action
                                 class="col col--1of1"
                                 @delete="handleRemoveBoundaryScheme"
              />
            </div>

            <div class="row mb-24">
              <v-input v-show="false" v-model="ConnectionCertificate.BoundaryScheme"
                       vid="ConnectionCertificate.BoundaryScheme"
                       type="hidden"
                       rules="required"/>
              <c-uploading v-model="ConnectionCertificate.BoundaryScheme"
                           :error="{error: false}"
                           :rules="ConnectionCertificate.BoundaryScheme.length === 0 ? 'required' : ''"
                           class="col col--1of1"
              />
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="8 | Потери"
                     :is-open="isOpenSpoiler.spoiler8" w100 @toggle="toggleSpoiler('spoiler8')">
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionCharacteristics.ConstLoss"
                       vid="ConnectionCertificate.ConnectionCharacteristics.ConstLoss"
                       title="Потери постоянные, кВтч"
                       required
                       clearable
                       decimal
                       rules="required"/>
              <br/>
            </div>
            <div class="row mb-24">
              <v-input v-model="ConnectionCertificate.ConnectionCharacteristics.VariableLoss"
                       vid="ConnectionCertificate.ConnectionCharacteristics.VariableLoss"
                       title="Потери переменные, %"
                       required
                       clearable
                       decimal
                       rules="required"/>
            </div>
          </v-spoiler>

          <v-spoiler class="row mb-24" summary="9 | Прочее" :is-open="false" w100>
            <div class="row mb-24">
              <c-textarea v-model="ConnectionCertificate.Comments"
                          class="col col--1of1"
              />
            </div>
          </v-spoiler>
        </div>
      </div>
    </div>

    <horizontal-progress-bar v-if="isWaitingSubmit"/>
    <div v-if="applicationStatus" class="row mb-24">
      <v-divider/>
    </div>

    <div class="row mb-24 page-form__action">
      <div v-if="isValid && applicationStatus !== 2" class="page-form__action-note">
        Нажимая кнопку «Подписать и отправить», вы направляете документы потребителю и подписываете их простой
        электронной подписью.
      </div>
      <p v-if="submitError.length !== 0">{{ submitError }}</p>
      <div class="page-form__action-button">
        <v-button :disabled="isWaitingSubmit || !isValid || !isLoadedData" type="submit">{{submitButtonTitle}}</v-button>
      </div>
    </div>

    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="notSignedDoc"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
  </v-form>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { scrollIntoView } from 'lib/utils/DOM';
import { getFileUrl } from 'lib/utils/files';
import { getCurrentDate } from 'lib/utils/date';
import { SimpleSignatureModal, VInput, EmploeeSelectOrInput } from 'components';
import SigningBasisSelector from 'components/DocumentSideSelectOrInput/SigningBasisSelector.vue';
import VDatePicker from 'components/VDatePicker/VDatePicker.vue';
import UsagePointConnectionSelect from 'components/selectors/UsagePointConnectionSelect/UsagePointConnectionSelect.vue';
import VButton from 'components/VButton/VButton.vue';
import VForm from 'components/VForm/VForm.vue';
import VDivider from 'components/VDivider/VDivider.vue';
import VSpoiler from '@/components/VSpoiler/VSpoiler';

export default {
  name: 'SendConnectionCertificate',
  components: {
    VDivider,
    VForm,
    VButton,
    UsagePointConnectionSelect,
    VDatePicker,
    SigningBasisSelector,
    SimpleSignatureModal,
    // SubmitTaskDetails,
    HorizontalProgressBar,
    FileListPreview,
    VInput,
    VSpoiler,
    EmploeeSelectOrInput,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  props: {
    documentId: String,
    accountId: String,
    hideStatus: {
      type: Boolean,
      default: false,
    },
    actionProps: Object,
    applicationData: Object,
    offerDocument: Object,
    applicationId: String,
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.hideStatus) {
      this.applicationStatus = 1;
    }
  },
  data() {
    return {
      submitError: '',
      meterNumberError: { error: false },
      mainSubstationError: { error: false },
      usagePointName: { error: false },
      contractNumberError: { error: false },
      isWaitingSubmit: false,
      applicationStatus: undefined,
      cancelledComments: '',
      cancelledDocs: [],
      ConnectionCertificate: {
        Date: '',
        Number: '',
        Consumer: {
          Name: '',
          Inn: '',
          Snils: '',
          AccountId: '',
        },
        Utility: {
          Name: '',
          Inn: '',
          AccountId: '',
          RepresentativeFullName: '',
          RepresentativeName: '',
          representativeSurname: '',
          representativePatronymic: '',
          representativePosition: '',
          CompanyDocument: {},
        },
        Retailer: {
          Name: '',
          Inn: '',
          AccountId: '',
          representativeName: '',
          representativeSurname: '',
          representativePatronymic: '',
          representativePosition: '',
          CompanyDocument: {},
        },
        ConnectionAgreement: {
          Number: '',
          Date: '',
          Amount: '',
          AmountFormatting: '',
        },
        TechnicalSpecification: {
          Number: '',
          Date: '',
        },
        TechnicalCertificate: {
          Number: '',
          Date: '',
        },
        ConnectionAddress: '',
        ConnectionCharacteristics: {
          MaxPower: '0',
          NewMaxPower: '',
          ReliabilityClass: '',
          MicrogenerationMaxPower: '',
          ConstLoss: '',
          VariableLoss: '',
        },
        ConnectionPoints: {
          ConnectionPoint: [
            {
              Number: '',
              PowerSupplyCenter: '',
              Description: '',
              VoltageLevel: '',
              MaxPower: '',
              MicrogenerationMaxPower: '',
              TotalTransformerPowerRating: '',
              ReliabilityClass: '',
              ReactivePowerFactor: '',
            },
          ],
        },
        BalanceAffiliationBoundary: '',
        OperationalResponsibilityBoundary: '',
        GridCompanyEquipment: '',
        CustomerEquipment: '',
        GridCompanyResponsibleEquipment: '',
        CustomerResponsibleEquipment: '',
        ProtectiveDevice: '',
        OffGridPowerSupply: '',
        SpecialNotes: '',
        BoundaryScheme: [],
        Comments: '',
        Signers: {
          Signer: [
            {
              Name: '',
              FamilyName: '',
              FirstName: '',
              Patronymic: '',
            },
          ],
        },
      },
      notSignedDoc: '',
      isVisibleSimpleSignatureModal: false,
      isOpenSpoiler: {
        spoiler1: false,
        spoiler2: false,
        spoiler7: false,
        spoiler8: false,
      },
      fieldsRulesSpoiler: {
        'ConnectionCertificate.TechnicalCertificate.Number': 'spoiler1',
        'ConnectionCertificate.TechnicalCertificate.Date': 'spoiler1',
        'point.ReactivePowerFactor': 'spoiler2',
        'ConnectionCertificate.BalanceAffiliationBoundary': 'spoiler2',
        'ConnectionCertificate.OperationalResponsibilityBoundary': 'spoiler2',
        'ConnectionCertificate.BoundaryScheme': 'spoiler7',
        'ConnectionCertificate.ConnectionCharacteristics.ConstLoss': 'spoiler8',
        'ConnectionCertificate.ConnectionCharacteristics.VariableLoss': 'spoiler8',
      },
      isError: false,
    };
  },
  computed: {
    isValid() {
      return (this.applicationStatus === 2 && this.cancelledComments.length > 0) ||
        (this.applicationStatus === 1);
    },
    isLoadedData() {
      return this.offerDocument?.technicalConditions;
    },
    reviewAttachedFiles() {
      return this.cancelledDocs;
    },
    reviewComments() {
      return this.cancelledComments;
    },
    basisSelectorMode() {
      return this.getAuthorization.authorization.role.isCompanyOwner ? 'charter' : 'powerOfAttorney';
    },
    attorneys() {
      return this.getAuthorization.authorization.powerOfAttorney ?? [];
    },
    submitButtonTitle() {
      if (this.applicationStatus === 2) {
        return 'Аннулировать';
      }
      return 'Подписать и отправить';
    },
    ...mapGetters('user', ['getUserType', 'getUser', 'getAccount', 'getAuthorization']),
  },
  watch: {
    offerDocument: {
      immediate: true,
      handler() {
        this.updateData();
      },
    },
  },
  methods: {
    async initSides() {
      const account = this.getAccount;
      const authorization = this.getAuthorization.authorization;

      let retailerData = [];
      if (this.applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn) {
        retailerData = await this.getSuppliersByFilter({ inn: this.applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn });
      }

      this.ConnectionCertificate.Consumer.Name = this.applicationData?.consumer?.company?.name ?? `${this.applicationData?.consumer?.familyName ?? ''} ${this.applicationData?.consumer?.firstName ?? ''} ${this.applicationData?.consumer?.patronymic ?? ''}`;
      this.ConnectionCertificate.Consumer.Snils = this.applicationData?.consumer?.insuranceNumber;
      this.ConnectionCertificate.Consumer.Inn = this.applicationData?.consumer?.company?.inn;
      this.ConnectionCertificate.Consumer.AccountId = this.accountId;

      this.ConnectionCertificate.Retailer.Name = retailerData[0]?.name;
      this.ConnectionCertificate.Retailer.Inn = retailerData[0]?.inn;
      this.ConnectionCertificate.Retailer.AccountId = retailerData[0]?.account.id;

      this.ConnectionCertificate.Utility.Name = account.profile.name;
      this.ConnectionCertificate.Utility.Inn = account.profile.inn;
      this.ConnectionCertificate.Utility.AccountId = account.id;
      this.ConnectionCertificate.Utility.RepresentativeFullName = `${authorization.user.fullName.surname} ${authorization.user.fullName.name} ${authorization.user.fullName.patronymic ?? ''}`;
      this.ConnectionCertificate.Utility.RepresentativeName = authorization.user.fullName.name;
      this.ConnectionCertificate.Utility.representativeSurname = authorization.user.fullName.surname;
      this.ConnectionCertificate.Utility.representativePatronymic = authorization.user.fullName.patronymic;
      this.ConnectionCertificate.Utility.representativePosition = authorization.role?.name ?? '';
    },
    getCheckboxClass(checked) {
      return checked ? 'control-buttonRadioGroupCheck-checked' : 'control-buttonRadioGroupCheck-unchecked';
    },
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              'АТП ОТПРАВЛЕН',
              '',
            );
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      if (this.applicationStatus === 2) {
        this.submitError = '';
        this.isWaitingSubmit = true;
        const documentReviewXml = connectionApplicationData.documentReviewXml(
          this.documentId,
          this.actionProps.reviewType,
          this.reviewComments,
          this.reviewAttachedFiles,
          [],
        );

        try {
          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            this.isWaitingSubmit = false;
            // TODO возможно, нужно ждать изменения состояния
            this.$nextTick(() => {
              this.vueShowNotification(
                'ЗАЯВКА АННУЛИРОВАНА',
                '',
              );
            });
            this.onClose();
          }, 2000);

        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
      } else {
        if (!this.onlyMicrogenerator) {

          const errorRole = '';
          if (errorRole.length !== 0) {
            scrollIntoView(
              document.querySelector(`[role="${errorRole}"]`),
            );
            return;
          }
        }
        this.notSignedDoc = connectionApplicationData.generateConnectionCertificateXml(
          this.ConnectionCertificate,
          this.onlyMicrogenerator,
        );
        this.openSignatureModal();
      }
    },
    onError(vid, element) {
      console.log('vid, element', vid, element);
      const vidWithoutIndex = vid.indexOf('_') === -1 ? vid : vid.substr(0, vid.indexOf('_'));

      if (!this.isOpenSpoiler[this.fieldsRulesSpoiler[vidWithoutIndex]]) {
        this.isError = true;
        this.isOpenSpoiler[this.fieldsRulesSpoiler[vidWithoutIndex]] = true;
      }

      this.$nextTick(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
    toggleSpoiler(val) {
      if (this.isError) {
        this.isError = false;
        return;
      }

      this.isOpenSpoiler[val] = !this.isOpenSpoiler[val];
    },
    onClose() {
      this.applicationStatus = undefined;
      this.$emit('close');
    },
    applicationStatusClick(val) {
      this.isOpenSpoiler.spoiler1 = false;
      this.isOpenSpoiler.spoiler2 = false;
      this.isOpenSpoiler.spoiler7 = false;
      this.isOpenSpoiler.spoiler8 = false;
      this.applicationStatus = val;
      this.updateData();
    },
    handleRemoveCancelledDocs(file) {
      this.cancelledDocs = this.cancelledDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveBoundaryScheme(file) {
      this.ConnectionCertificate.BoundaryScheme = this.ConnectionCertificate.BoundaryScheme
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    addConnectionPoint() {
      this.ConnectionCertificate.ConnectionPoints.ConnectionPoint.push(
        {
          Number: '',
          PowerSupplyCenter: '',
          Description: '',
          VoltageLevel: '',
          MaxPower: '',
          MicrogenerationMaxPower: '',
          TotalTransformerPowerRating: '',
          ReactivePowerFactor: '',
        },
      );
    },
    addSigner() {
      this.ConnectionCertificate.Signers.Signer.push(
        {
          Name: '',
          FamilyName: '',
          FirstName: '',
          Patronymic: '',
        },
      );
    },
    updateData() {
      this.initSides();
      if (this.applicationData.gridCompany) {
        const currentDate = getCurrentDate('YYYY-MM-DD');
        this.ConnectionCertificate.Date = currentDate;
        this.ConnectionCertificate.Number = this.documentId;

        this.ConnectionCertificate.ConnectionAgreement.Number = this.offerDocument.contractNumber;
        this.ConnectionCertificate.ConnectionAgreement.Date = this.offerDocument.contractDate;
        this.ConnectionCertificate.ConnectionAgreement.Amount = this.offerDocument.unformattedPrice;
        this.ConnectionCertificate.ConnectionAgreement.AmountFormatting = this.offerDocument.price;

        this.ConnectionCertificate.TechnicalSpecification.Number = this.offerDocument.contractNumber;
        this.ConnectionCertificate.TechnicalSpecification.Date = this.offerDocument.contractDate;

        this.ConnectionCertificate.TechnicalCertificate.Number = this.offerDocument.contractNumber;
        this.ConnectionCertificate.TechnicalCertificate.Date = this.offerDocument.contractDate;

        console.log('appData', this.applicationData);

        this.ConnectionCertificate.ConnectionAddress = this.applicationData.objectLocation.objectAddress;
        this.ConnectionCertificate.ConnectionCharacteristics.NewMaxPower = this.applicationData.connectionCharacteristics.currentMaxPower;
        if (this.applicationData.connectionCharacteristics.reliabilityClass && this.applicationData.connectionCharacteristics.reliabilityClass.value) {
          this.ConnectionCertificate.ConnectionCharacteristics.ReliabilityClass = this.applicationData.connectionCharacteristics.reliabilityClass.value;
        }

        this.ConnectionCertificate.ConnectionPoints.ConnectionPoint = [
          {
            Number: '1',
            PowerSupplyCenter: this.offerDocument.technicalConditions.mainSubstationObject,
            Description: this.offerDocument.technicalConditions.pointName,
            VoltageLevel: this.applicationData.connectionCharacteristics.phaseVoltage,
            MaxPower: this.offerDocument.technicalConditions.maxPower,
            MicrogenerationMaxPower: '',
            TotalTransformerPowerRating: '',
            ReliabilityClass: '',
            ReactivePowerFactor: '',
          },
        ];

        if (this.onlyMicrogenerator) {
          let sum = 0;
          this.applicationData.microgenerator.items.forEach((item) => {
            sum += item.MaxGeneratePowerDecimal;
          });

          this.ConnectionCertificate.ConnectionCharacteristics.MicrogenerationMaxPower = String(sum);
        }
      }
    },
    selectEmploee(val) {
      this.ConnectionCertificate.Retailer.representativeName = val.fullName.name;
      this.ConnectionCertificate.Retailer.representativeSurname = val.fullName.surname;
      this.ConnectionCertificate.Retailer.representativePatronymic = val.fullName.patronymic;
      this.ConnectionCertificate.Retailer.representativePosition = val.role.name;
      this.ConnectionCertificate.Retailer.CompanyDocument = val.reason;
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows', 'getSuppliersByFilter']),
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.control {
  opacity: 0;
  pointer-events: none;

  &-titleContainer {
    display: flex;
    align-items: center;
  }

  &-titleRadioGroup {
    font-family: 'Roboto Condensed';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 8px;
  }

  .contentRadioGroup {
    display: flex;
    flex-direction: column;
    color: #141412;

    p {
      font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #C1C4C7;
    }
  }

  &-buttonRadioGroupCheck-unchecked {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center;
  }

  &-buttonRadioGroupCheck-checked {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center;
  }
}


.page-form {
  display: flex;
  flex-direction: column;
  align-self: center;

  &__title {
    font-family: Roboto;
    color: #4C4E51;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &-sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &__status {
    align-items: center;
  }

  &__controlDiv {
    border-left: 2px solid #DEE2E6;
    margin-left: 6px;
    padding-left: 14px;

    &-label {
      display: flex;
    }

    &-label:first-child {
      margin-bottom: 10px;
    }
  }

  &__spoiler {
    details {
      width: 100% !important;
    }
  }

  &__action {
    position: relative;

    &-note {
      width: 320px;
      position: absolute;
      right: -364px;
      border-radius: 16px;
      background: #F5F6F6;
      color: #4C4E51;
      padding: 12px 12px 6px 24px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &-button {
      position: absolute;
      right: 0;
    }
  }
}
</style>
