<template>
  <v-app class="vuetify-styles" data-app>

    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="title">
              <span class="text-h5">Приёмка демонтажа</span>
              <span v-if="isExpired" class="errorText">
                {{subtitleError}}
              </span>
            </div>
          </v-card-title>
          <v-card-text>

            <v-row class="mb-8">
              <v-col>
                <div id="maps">
                  <p>Выберите принятые ОДК*</p>
                  <yandex-map
                    ref="customMap"
                    :collection-axp="adreskaFactCollection"
                    @tapPlacemark="onTapPlacemark"
                  />
                </div>
              </v-col>
            </v-row>

            <contractor-autocomplete
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-required
              title="Подрядчик"
              url="/contractors/find?name="
              url-default-data="/contractors/top20"
            ></contractor-autocomplete>

            <events-autocomplete
              ref="event"
              v-model="eventId"
              :find-ids="eventsFindIds"
              :is-disabled="!contractorId"
              :items.sync="events"
              :search.sync="eventName"
              is-required
              title="Мероприятие"
              url-default-data="/events/contractor/{0}/top20"
              url-search="/events/contractor/{0}/find?name={1}"
            />

            <autocomplete-find-id
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              :is-disabled="!contractorId||!eventId"
              :items.sync="adreskaItems"
              :search.sync="adreskaAddress"
              is-required
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
              url-default-data="/adreska/contractor/{0}/event/{1}/top20?statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS"
              url-search="/adreska/contractor/{0}/event/{1}/find?name={2}&statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS"
            ></autocomplete-find-id>

            <div v-if="adreska">

              <adreska-history :adreska-id="adreska.id" subselect="DISMANTLING_CONTROL" title="Информация о демонтаже"/>
              <text-field
                v-model="adreska.event.name"
                is-disabled
                title="Мероприятие"
              ></text-field>

              <text-field
                v-model="adreska.axp.odk.name"
                is-disabled
                title="ОДК"
              ></text-field>

              <text-field
                ref="administrativeDistrict"
                v-model="adreska.administrativeDistrict.name"
                is-disabled
                title="Административный округ г. Москвы установки ОДК"
              ></text-field>

              <text-field
                ref="responsibleRES"
                v-model="adreska.responsibleDistrictDepartmentName"
                is-disabled
                title="Ответственный РЭС "
              ></text-field>

              <text-field
                ref="quantityPlan"
                v-model="adreska.quantityPlan"
                is-disabled
                title="Количество по плану"
              ></text-field>

              <text-field
                ref="workModePlan"
                v-model="adreska.workModePlan"
                is-disabled
                title="Режим работы ОДК по плану"
              ></text-field>

              <text-field
                ref="planDismantlingStartDate"
                :text="formatDateFromOdkTimestamp(adreska.event.planDismantlingStartDate)"
                is-disabled
                title="Планируемая дата начала демонтажа"
              ></text-field>
            </div>

            <div v-if="adreska">
              <text-field
                ref="countOdk"
                v-model="countOdk"
                is-disabled
                is-required
                title="Количество демонтированных ОДК"
              ></text-field>

              <text-field
                v-if="adreska"
                ref="installationCompletionPercentage"
                :text="installationPercentage"
                is-disabled
                title="% выполнения демонтажа"
              ></text-field>
            </div>

            <file-input
              v-if="adreska"
              ref="photos"
              v-model="photos"
              class="mt-2"
              title="Фото"
            ></file-input>

            <div v-if="installationPercentage!==100">
              <v-divider/>
              <v-card-text class="text-h6">
                Выберите программу оформления для переноса не демонтированных ОДК
              </v-card-text>
              <contractor-autocomplete
                ref="contractorNew"
                v-model="contractorNewId"
                :items.sync="contractorsNew"
                :search.sync="contractorNewName"
                is-required
                title="Подрядчик"
                url="/contractors/find?name="
                url-default-data="/contractors/top20"
              ></contractor-autocomplete>

              <events-autocomplete
                ref="eventNew"
                v-model="eventNewId"
                :find-ids="eventsNewIdFindIds"
                :is-disabled="!contractorNewId"
                :items.sync="eventNewItems"
                :search.sync="eventNewName"
                is-required
                title="Мероприятие"
                url-default-data="/events/contractor/{0}/top20"
                url-search="/events/contractor/{0}/find?name={1}"
              />

              <autocomplete-find-id
                ref="adreskaNew"
                v-model="adreskaNewId"
                :find-id="contractorNewId"
                :find-id1="eventNewId"
                :find-id2="adreska?.axp?.id"
                :is-disabled="!contractorNewId || !adreska?.axp?.id || !eventNewId"
                :items.sync="adreskaNewItems"
                :search.sync="adreskaNewAddress"
                is-required
                is-required-find-id
                is-required-find-id1
                is-required-find-id2
                title="Программа оформления"
                url-default-data="/adreska/contractor/{0}/event/{1}/axp/{2}/top20?statuses=MOUNTING_IN_PROCESS, MOUNTED, null"
                url-search="/adreska/contractor/{0}/event/{1}/axp/{2}/find?name={3}&statuses=MOUNTING_IN_PROCESS, MOUNTED, null"
              ></autocomplete-find-id>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog
        v-model="loaderDialog"
        class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import TextField from 'components/Processes/components/TextField.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import { formatDateFromOdkTimestamp, formatOdkTimestampToDateObject, getDateAtStartOfDay } from 'lib/utils/date';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  components: {
    FileInput,
    AdreskaHistory,
    ContractorAutocomplete,
    YandexMap,
    AutocompleteFindId,
    TextField,
    EventsAutocomplete,
  },

  async created() {
    await this.fetchAdreskaFactCollection();
    if (this.$route.params.initAdreskaId) {
      let adreskaFact = this.adreskaFactCollection.filter((it) => it.adreskaId === this.$route.params.initAdreskaId && it.statusDismantling == 1);
      if (!adreskaFact?.length) adreskaFact = this.adreskaFactCollection.filter((it) => it.adreskaId === this.$route.params.initAdreskaId);
      if (!adreskaFact?.length) {
        await this.setNotification({ message: 'Не найден объект с переданным ID' });
        this.onTapBack();
      }
      const first = adreskaFact[0];
      this.contractors = [{
        id: first.contractorId,
        name: first.contractorName,
      }];
      this.contractorId = first.contractorId;
      this.contractorName = first.contractorName;


      this.events = [{
        id: String(first.eventId),
        name: first.eventName,
      }];
      this.eventId = String(first.eventId);
      this.eventName = first.eventName;


      this.adreskaItems = [{
        id: first.adreskaId,
        name: first.adreskaName,
      }];
      this.adreskaId = first.adreskaId;
      this.adreskaName = first.adreskaName;

    }
  },

  data: () => ({
    progressValue: 0,
    firstlySelected: null,
    updateMap: 0,


    contractorId: null,
    adreskaId: null,
    adreskaFactId: null,
    eventId: null,


    contractors: [],
    adreskaItems: [],
    events: [],

    adreska: null,

    contractorName: '',
    adreskaAddress: '',
    eventName: '',

    adreskaFactCollection: [],

    contractorNewId: null,
    adreskaNewId: null,
    eventNewId: null,

    contractorNewName: '',
    adreskaNewAddress: '',
    eventNewName: '',

    adreskaNewItems: [],
    contractorsNew: [],
    eventNewItems: [],

    loaderDialog: false,

    photos: [],
  }),

  computed: {
    countOdk() {
      return this.acceptedAdreskaFact.length;
    },
    totalCountOdk() {
      return this.adreskaFactCollection.length;
    },
    acceptedAdreskaFact() {
      console.log('acc', this.adreskaFactCollection);
      return this.adreskaFactCollection.filter((it) => it.isSelected);
    },
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisabledAdreska = !this.adreskaId;
      const isDisabledCountOdk = this.installationPercentage !== 100 && !this.adreskaNewId;

      return (
        isDisabledContractor ||
        isDisabledAdreska ||
        isDisabledCountOdk
      );
    },
    installationPercentage() {
      return this.countOdk / this.totalCountOdk * 100;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorId,
        },
      ];
    },
    eventsNewIdFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorNewId,
        },
      ];
    },
    isExpired() {
      if (!this.adreska) return false;
      // После приёмки процесс не доступен => если зашли в процесс <=> Приёмка не осуществлялась
      return getDateAtStartOfDay(Date.now()) > formatOdkTimestampToDateObject(this.adreska.event.planDismantlingEndDate);
    },
    subtitleError() {
      if (this.adreskaFactCollection.every((element) => element?.statusDismantling == 0) && this.adreska?.event?.planDismantlingStartDate)  {
       return 'Просрочена плановая дата начала демонтажа';
      }
      return 'Просрочена плановая дата окончания демонтажа';
    },
    ...mapGetters('user', ['getToken']),
  },

  watch: {
    async adreskaId(newVal) {
      this.adreskaFactId = null;
      if (newVal) {
        await this.fetchAdreska();
        await this.fetchAdreskaFactCollection();
        this.firstlySelected = null;
      } else {
        this.adreska = null;
        this.clearSelected();
        await this.fetchAdreskaFactCollection();
      }
    },

    adreskaFactId() {
      this.fetchAdreskaFactCollection();
    },
  },

  methods: {
    formatDateFromOdkTimestamp,

    async onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        adreskaId: this.adreskaId,
        newAdreskaId: this.adreskaNewId,
        acceptedAdreskaFactIds: this.acceptedAdreskaFact.map((it) => it.id),
        photos: this.photos.map((doc) => ({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        })),
      };

      axios
        .post(`${BASE_URL}/dismantling-acceptance`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    clearSelected() {
      this.adreskaFactCollection = this.adreskaFactCollection.map((it) => ({
        ...it,
        isSelected: false,
      }));
    },
    onTapPlacemark(id) {
      const fact = this.adreskaFactCollection.find((it) => it.id === id);
      fact.isSelected = !fact.isSelected;
      fact.balloonContent.buttonLabel = fact.isSelected ? 'Отменить' : 'Выбрать';
      this.adreskaFactCollection = this.adreskaFactCollection.map((it) => (it.id === id
        ? fact
        : it));
      if (!this.adreskaId) {

        this.contractors = [{
          id: fact.contractorId,
          name: fact.contractorName,
        }];
        this.contractorId = fact.contractorId;
        this.contractorName = fact.contractorName;


        this.events = [{
          id: String(fact.eventId),
          name: fact.eventName,
        }];
        this.eventId = String(fact.eventId);
        this.eventName = fact.eventName;


        this.adreskaItems = [{
          id: fact.adreskaId,
          name: fact.adreskaName,
        }];
        this.adreskaId = fact.adreskaId;
        this.adreskaName = fact.adreskaName;

        this.adreskaAddress = fact.adreskaName;
        // TODO костыль для того чтобы проставить true после перезагрузки, подумать как покрасивее
        this.firstlySelected = fact.id;
      }
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.progressValue = 0;
      this.item = {};
      this.updateMap = 0;
      this.contractorId = null;
      this.contractorName = '';
      this.contractors = [];
      this.eventId = null;
      this.eventName = '';
      this.events = [];
      this.adreskaId = null;
      this.adreska = null;
      this.adreskaAddress = '';
      this.adreskaItems = [];
      this.adreskaFactId = null;
      this.adreskaFactCollection = [];
      this.loaderDialog = false;
      this.firstlySelected = null;
    },
    async fetchAdreska() {
      const response = await fetch(
        `${BASE_URL}/adreska/${this.adreskaId}`,
      );
      const adreskaSummaryResponse = await fetch(
        `${BASE_URL}/adreska-summary-entity?adreskaId=${this.adreskaId}`,
      );
      if (response.status === 200 && adreskaSummaryResponse.status === 200) {
        this.adreska = await response.json();
      } else {
        this.adreska = null;
      }
    },
    async fetchAdreskaFactCollection() {
      let response = null;
      if (this.adreskaId) {
        response = await fetch(
          `${BASE_URL}/adreskafact?adreskaId=${this.adreskaId}`,
        );
      } else {
        response = await fetch(
          `${BASE_URL}/adreska/adreskafact?statuses=IN_EXPLOITATION, DISMANTLING_IN_PROCESS`,
        );
      }
      const data = await response.json();
      this.adreskaFactCollection = data.map((el) => {
        let color;
        let status;
        if (el.statusDismantlingCheck == 1) {
          color = 'red';
          status = 'С замечаниями';
        } else if (el.statusDismantlingCheck == 2) {
          color = 'green';
          status = 'Без замечаний';
        } else if (el.statusDismantling == 0) {
          color = 'violet';
          status = 'Демонтаж не проводился';
        } else {
          color = 'blue';
          status = 'Контроль не проводился';
        }
        return {
          id: el.id,
          locationLatitude: el.locationLatitude,
          locationLongitude: el.locationLongitude,
          statusAssemblyAcceptance: el.statusAssemblyAcceptance,
          adreskaId: el.adreska.id,
          adreskaName: el.adreska.name,
          eventId: el.adreska.event.id,
          eventName: el.adreska.event.name,
          contractorId: el.adreska.contractorId,
          contractorName: el.adreska.contractorName,
          isSelected: el.id === this.firstlySelected,
          event: {
            planDismantlingStartDate: el.adreska.event.planDismantlingStartDate,
          },
          statusDismantling: el.statusDismantling,
          countOdk: el.countOdk,
          color: color,
          balloonContent: {
            title: el.axp.odk.name,
            buttonLabel: el.id === this.firstlySelected ? 'Отменить' : 'Выбрать',
            props: [
              {
                label: 'ID',
                value: el.id,
              },
              {
                label: 'АХП',
                value: el.axp.name,
              },
              {
                label: 'Тип декора',
                value: el.decorType?.name,
              },
              {
                label: 'Подрядчик',
                value: el.adreska.contractorName,
              },
              {
                label: 'Статус',
                value: status,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(el.createDate),
              },
            ],
          },
        };
      });
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>
<style scoped>
.title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
</style>

