<template>
  <v-spoiler class="voltage-level" summary="Уровень напряжения">
    <div class="voltage-level__item">
      <v-input
        v-model="internalValue.actualVoltageLevel"
        :disabled="!isEditing"
        clearable
        title="Уровень (класс) напряжения фактический, кВ"
      />
    </div>
    <div class="voltage-level__item">
      <v-select
        v-model="internalValue.voltageLevel"
        :disabled="!isEditing"
        :options="voltageOptions"
        title="Уровень (класс) напряжения используемый для применения тарифов (цен) (ВН, СН1, СН2, НН)"
      />
    </div>
  </v-spoiler>
</template>

<script>
import { VInput, VSelect, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'VoltageLevel',
  components: {
    VSelect,
    VSpoiler,
    VInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        voltage: {
          actual: '',
          tariff: '',
        },
      },
      internalValue: {},
      options: [],
      voltageOptions: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);

        if (this.options.length === 0) {
          this.voltageOptions = [
            {
              value: this.internalValue.voltageLevel,
              label: this.internalValue.voltageLevel,
            },
          ];
          this.options = [
            {
              value: this.internalValue.boundariesBalanceSheet,
              label: this.internalValue.boundariesBalanceSheet,
            },
          ];
        }
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.voltage-level {

  &__item {
    width: 100%;
    margin-top: 48px;
  }
}
</style>
