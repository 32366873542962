<template>
  <modal
      v-if="visibleDialog"
      :body-style="{
            padding: '24px 0',
            overflowY:'initial'
          }"
      :header-style="{
            display: 'flex',
            justifyContent: 'center',
          }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
            borderRadius: '16px',
            padding: '24px',
          }"
      class="accounting-balances-from-excel"
  >
    <template #header>
      <span class="accounting-balances-from-excel__title">Добавить из Excel</span>
    </template>
    <template #body>
      <div class="accounting-balances-from-excel__attachment">
        <div class="accounting-balances-from-excel__file-attachment-wrp">
          <a :href="`/xlsx/accounting-balances/accounting-balances.xlsx`"
             class="accounting-balances-from-excel__file-attachment"
             download="Программа оформления шаблон.xlsx">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span class="accounting-balances-from-excel__attachment-title">Учёт остатков шаблон.xlsx</span>
          </a>
        </div>
        <document-downloader
            :is-open-eye-show="false"
            :types="['XLSX']"
            @download="xlsDownload(`/xlsx/accounting-balances/accounting-balances.xlsx`, 'Учёт остатков шаблон.xlsx')"
        />
      </div>
      <horizontal-progress-bar v-if="isParsingExcel" class="accounting-balances-from-excel__progress-bar"/>
      <div class="accounting-balances-from-excel__buttons">
        <v-button
            variant="outlined"
            @click="onClose"
        >
          Отменить
        </v-button>
        <select-file-button-dialog
            v-model="addFromExcel"
            :disabled="isParsingExcel"
            :label="errors.length > 0 ? 'Заменить из Excel' : undefined"
            accept=".xls,.xlsx"
            class="ml-4"
        />
      </div>

      <div v-if="errors.length > 0">
        <div class="accounting-balances-from-excel__error__title">
          <span>Найдено {{ errors.length }} ошибок. Перезагрузите файл или исправьте данные:</span>
        </div>
        <template v-if="!isParsingExcel">
          <div v-for="(error, indexError) in errorsSort" :key="indexError" class="accounting-balances-from-excel__error__item">
          <div class="accounting-balances-from-excel__error__text">Строка {{ error.index + 4 }}. {{ error.text }}</div>
          <div>
            <axp-autocomplete
                v-if="error.type === 'axpName'"
                v-model="items[error.index].axpId"
                margin-bottom=""
                title="АХП тип"
                @update:search="(val) => items[error.index].element.axpName = val"
            />
            <elements-axp-autocomplete
                v-else-if="error.type === 'elementName'"
                v-model="error.elementId"
                :find-ids="[
                      {
                        queryIndex: 1,
                        value: items[error.index]?.axpId,
                      }
                  ]"
                margin-bottom=""
                title="Наименование элемента"
                @update:search="(val) => items[error.index].element.elementName = val"
            />
            <v-search-select
                v-else-if="error.type === 'status'"
                :options="mapReversedToLabelValueList"
                title="Статус"
                @input="(val, label) => items[error.index].element.status = label"
            />
            <warehouses-autocomplete
                v-else-if="error.type === 'warehouseName'"
                v-model="error.warehouseId"
                margin-bottom=""
                title="Склад"
                @update:search="(val) => items[error.index].element.warehouseName = val"
            />
            <v-input
                v-else-if="error.type === 'contractorInn'"
                v-model="items[error.index].element.contractorInn"
                :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                rules="digits:10|inn"
                title="ИНН"
            />
            <v-input
                v-else-if="error.type === 'count'"
                v-model="items[error.index].element.count"
                title="Количество"
                type="number"
            />
            <div v-else-if="error.type === 'contractId'" style="display: flex; gap: 4px">
              <v-input
                  v-model="items[error.index].element.contract.number"
                  style="width: 100%"
                  title="Номер договора"
              />
              <v-date-picker
                  :value="getCorrectDateFormat(items[error.index].element.contract.date)"
                  label="Дата договора"
                  style="width: 100%"
                  @input="(val) => items[error.index].element.contract.date = val"
              />
            </div>
            <v-input
                v-else
                v-model="items[error.index].element[error.type]"
                :title="error.title"
            />
          </div>
        </div>
        </template>
      </div>
    </template>
    <template v-if="errors.length > 0" #footer>
      <div class="accounting-balances-from-excel__error__save">
        <v-button
            :disabled="isParsingExcel"
            variant="filled"
            @click="saveUpdate"
        >
          Сохранить
        </v-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SelectFileButtonDialog from 'components/Processes/components/SelectFileButtonDialog.vue';
import { nanoid } from 'nanoid';
import { excelParseWithWorker } from 'utils/excel';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';
import moment from 'moment/moment';
import { AccountingBalancesPattern } from 'components/Processes/patterns/AccountingBalancesPattern';
import { accountingBalancesStatusEnum, accountingBalancesStatusMapReversed } from 'components/Processes/list';
import { VInput, VSearchSelect, VDatePicker, VButton } from 'components';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import ElementsAxpAutocomplete from 'components/Processes/components/ElementsAxpAutocomplete.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';
import Modal from 'components/Modal/Modal';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { check } from 'lib/utils/validation/additionValidation';

export default {
  components: {
    DocumentDownloader,
    SelectFileButtonDialog,
    VInput,
    AxpAutocomplete,
    ElementsAxpAutocomplete,
    VSearchSelect,
    WarehousesAutocomplete,
    VDatePicker,
    Modal,
    HorizontalProgressBar,
    VButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    handleSuccess: {
      type: Function,
      default: () => ({}),
    },
    handleError: {
      type: Function,
      default: () => ({}),
    },
  },
  async created() {
    try {
      this.districtDepartments = await this.supplierDepartments({ inn: '7720522853' });
    } catch (e) {
      this.handleError('Ошибка загрузки списка РЭС. Обновите страницу и попробуйте ещё раз.');
    }
  },
  data() {
    return {
      isParsingExcel: false,
      addFromExcel: undefined,
      eventIdSearch: '',
      districtDepartments: [],
      items: [],
      errors: [],
      parseValidate: [],
      accountingBalancesStatusMapReversed: accountingBalancesStatusMapReversed,
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    mapReversedToLabelValueList() {
      return Object.keys(accountingBalancesStatusMapReversed).map((key) => ({
        label: key,
        value: accountingBalancesStatusMapReversed[key],
      }));
    },
    errorsSort() {
      return [...this.errors].sort((first, second) => {
        if (first.type === 'elementName' && second.type === 'axpName') {
          return 1;
        }
        if (first.type === 'axpName' && second.type === 'elementName') {
          return -1;
        }
        return 0;
      });
    },
    ...mapGetters('user', ['getToken']),
  },
  watch: {
    async addFromExcel(val) {
      if (val && val.length > 0) {
        this.isParsingExcel = true;

        const taskId = nanoid();
        const res = await excelParseWithWorker(
          val[0].code,
          { ...AccountingBalancesPattern },
          taskId,
        );

        this.errors = [];
        this.parseValidate = [];

        if (res.errors.length) {
          // this.handleError(res.errors.slice(0, 5).map((it) => `${it.title}. ${it.text}`).join(''));

          res.errors.forEach((error) => {
            this.errors.push({
              text: `"${error.value}" - ${error.text}`,
              index: error.row - 3,
              type: error.keys[0],
            });

            this.parseValidate.push({
              keys: error.keys,
              line: error.line,
              parsed: error.parsed,
              title: error.title,
              template: error.template,
            });
          });
        }

        if (res.data.length === 0) {
          this.handleError('Пустой файл');
          this.isParsingExcel = false;
          return;
        }

        await this.validateData(res.data, false);

        if (this.errors.length > 0) {
          this.errors.sort((first, second) => (first.index > second.index ? 1 : -1));
          this.isParsingExcel = false;
          return;
        }

        this.postAccountingBalances();
      }
    },
  },

  methods: {
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    getCorrectDateFormat(date) {
      return moment(date, 'DD/MM/YYYY').format('DD.MM.YYYY');
    },
    onClose() {
      this.items = [];
      this.errors = [];
      this.visibleDialog = false;
    },
    getEqualWithoutCase(data, word, findByFunction) {
      if (!data?.length || !word) return null;
      const equal = data.find((it) => findByFunction(it) === word);
      data.forEach((it) => {
        console.log('test', findByFunction(it), word, findByFunction(it) === word);
      });
      if (equal) return equal;
      const equalWithoutCase = data.find((it) => findByFunction(it).toLowerCase() === word.toLowerCase());
      if (equalWithoutCase) return equalWithoutCase;
      return null;
    },
    async getAxpByNames(name, odkName) {
      const response = await fetch(`${BASE_URL}/axp/find?name=${name}&odkName=${odkName}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getWarehouseByName(name) {
      const response = await fetch(`${BASE_URL}/warehouses/find?name=${name}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getElementByAxpAndName(id, name) {
      const response = await fetch(`${BASE_URL}/axp/${id}/elements/find?name=${name}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getContractsByName(name) {
      const response = await fetch(`${BASE_URL}/contract-debt/find?name=${name}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getSuppliers(inn) {
      return this.getSupplierForSelectorByFilterNotErrorNotification(inn ? { inn: inn, kind: 'ODK' } : { kind: 'ODK' });
    },
    async validateData(dataList, isParseValidate) {
      this.items = [];

      for (let i = 0; i < dataList.length; i++) {
        const element = dataList[i];
        const item = {};

        if (isParseValidate) {
          this.parseValidate.forEach((validate) => {
            let value = undefined;
            validate.keys.forEach((key) => {
              if (value) {
                value = value[key];
              } else {
                value = element[key];
              }
            });
            const resultValidation = check(value, validate.template, validate.parsed, validate.line);

            if (resultValidation.isNotValid) {
              this.errors.push({
                text: `"${value}" - ${resultValidation.errorText}`,
                index: i,
                type: validate.keys[0],
                title: validate.title,
              });
            }
          });
        }

        // АХП
        let axp = undefined;
        if (element.axpName.length > 0) {
          axp = await this.getAxpByNames(element.axpName);
          if (!axp || axp.length === 0) {
            this.errors.push({
              text: `Не найден АХП - "${element.axpName}"`,
              index: i,
              type: 'axpName',
            });
          } else {
            item.axpId = axp.id;
          }
        }

        if (item.axpId && element.elementName.length > 0) {
          // Элемент
          const axpElement = await this.getElementByAxpAndName(axp.id, element.elementName);
          if (!axpElement || axpElement.length === 0) {
            this.errors.push({
              text: `Не найден элемент АХП (для "${element.axpName}") - "${element.elementName}"`,
              index: i,
              type: 'elementName',
            });
          } else {
            item.elementId = axpElement.id;
          }
        }

        // Статус
        let status = undefined;

        if (element.status.length > 0) {
          status = accountingBalancesStatusMapReversed[element.status.toLowerCase()];
          if (!status) {
            this.errors.push({
              text: `Не найден статус - "${element.status}"`,
              index: i,
              type: 'status',
            });
          } else {
            item.status = status;
          }
        }

        // Склад
        if (element.warehouseName) {
          const warehouse = await this.getWarehouseByName(element.warehouseName);
          if (!warehouse || warehouse.length === 0) {
            this.errors.push({
              text: `Не найден склад - "${element.warehouseName}"`,
              index: i,
              type: 'warehouseName',
            });
          } else {
            item.warehouseId = warehouse.id;
          }
        } else {
          if (status === accountingBalancesStatusEnum.AT_WAREHOUSE || status === accountingBalancesStatusEnum.IN_REPAIR || status === accountingBalancesStatusEnum.DECOMISSIONED || status === accountingBalancesStatusEnum.DEFECTIVE) {
            this.errors.push({
              text: `Для статуса "${element.status}" обязательно указание склада`,
              index: i,
              type: 'warehouseName',
            });
          }
        }

        // Подрядчик
        if (element.contractorInn) {
          try {
            const contractors = await this.getSuppliers(element.contractorInn);

            if (!contractors || contractors.length === 0) {
              this.errors.push({
                text: `Не найден подрядчик по ИНН - ИНН: "${element.contractorInn}"`,
                index: i,
                type: 'contractorInn',
              });
            } else {
              item.contractorId = contractors[0].id;
            }
          } catch (e) {
            console.log('get getSuppliers error:', e);
          }
        } else {
          if (status === accountingBalancesStatusEnum.IN_REPAIR || status === accountingBalancesStatusEnum.AT_CONTRACTOR) {
            this.errors.push({
              text: `Для статуса "${element.status}" обязательно указание подрядчика`,
              index: i,
              type: 'contractorInn',
            });
          }
        }

        item.count = element.count;

        if (element.contract.number && element.contract.date) {
          const contract = await this.getContractsByName(`№${element.contract.number} от ${this.getCorrectDateFormat(element.contract.date)} г.`);

          if (!contract || contract.length === 0) {
            this.errors.push({
              text: `Не найден договор: №${element.contract.number} от ${this.getCorrectDateFormat(element.contract.date)} г.`,
              index: i,
              type: 'contractId',
            });
          } else {
            item.contractId = contract.id;
          }
        }

        item.element = element;
        this.items.push(item);
      }
    },
    postAccountingBalances() {
      axios
          .post(`${BASE_URL}/accounting-balances`, JSON.stringify({ elements: this.items }), {
            headers: {
              'X-Authorization': `Bearer ${this.getToken}`,
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.onClose();
            this.handleSuccess();
          })
          .catch((error) => {
            console.error(error);
            this.handleError(error.response.data);
          })
          .finally(() => {
            this.isParsingExcel = false;
          });
    },
    async saveUpdate() {
      this.isParsingExcel = true;
      this.errors = [];

      await this.validateData(this.items.map((item) => item.element), true);

      if (this.errors.length > 0) {
        this.errors.sort((first, second) => (first.index > second.index ? 1 : -1));
        this.isParsingExcel = false;
        return;
      }

      this.postAccountingBalances();
    },
    ...mapActions('cabinet', ['getSupplierForSelectorByFilterNotErrorNotification']),
    ...mapActions('dataLists', ['supplierDepartments']),
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
}

.accounting-balances-from-excel {
  &__attachment {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #4C4E51;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__attachment-title, &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #4C4E51;
  }

  &__title {
    font-size: 24px;
  }

  &__file-attachment-wrp {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__file-attachment {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #4C4E51;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    span {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
    }

    &:hover {
      text-decoration: none
    }
  }

  &__error {
    &__text, &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #4C4E51;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &__item {
      margin-bottom: 24px;
    }

    &__save {
      display: flex;
      justify-content: end;
    }
  }

  &__buttons {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  &__progress-bar {
    margin-bottom: 16px;
  }
}

</style>
