<template>
  <div class="contract-side-select">
    <v-select
      v-model="company"
      :options="companies"
      :title="getTitleByContractSide"
      is-show-input-img
    />
  </div>
</template>

<script>
import VSelect from 'components/VSelect/VSelect';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ContractSideSelect',
  components: { VSelect },
  props: {
    side: { type: Object, required: true },
  },
  computed: {
    company: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.inn;
      },
      set(selectedCompany) {
        this.setCompanySide({ selectedCompany, sideName: this.side.contractSide });
      },
    },
    getTitleByContractSide() {
      if (this.side.contractSide === 'SUPPLIER') {
        return 'Заказчик';
      } else if (this.side.contractSide === 'PERFORMER_FIRST') {
        return 'Исполнитель 1';
      } else if (this.side.contractSide === 'PERFORMER_SECOND') {
        return 'Исполнитель 2';
      } else if (this.side.contractSide === 'PERFORMER') {
        return 'Исполнитель';
      }
      return '';
    },
    companies() {
      return this.getContractSideCompanyValue(this.side.contractSide);
    },
    ...mapGetters('electricityTransmissionContract', [
      'getContractSideCompanyValue',
      'getEditableSides',
    ]),
  },
  methods: {
    ...mapActions('electricityTransmissionContract', [
      'setCompanySide',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.contract-side-select {
  width: 272px;
  padding-right: 16px;
}
</style>
