<template>
  <div>
    <div class="egrul-validator" :style="borderStyle">
    <img v-if="error" alt="Ошибка" height="20" src="@/assets/icons/basic/error.svg" width="20"/>
    <img v-else alt="Успешно" height="20" src="@/assets/icons/basic/checked.svg" width="20"/>
    <div>
      <p class="egrul-validator__title">{{ title }}</p>
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    inn: {
      type: String,
      required: true,
    },
  },
  created() {
    this.validateByinFnsByInn();
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    title() {
      const successText = 'Проверка ЕГРЮЛ пройдена.\nКомпания существует';
      const errorText = 'Проверка ЕГРЮЛ не пройдена.\nКомпания не существует';
      return this.error ? errorText : successText;
    },
    borderStyle() {
      return this.error ? { border: '1px solid red' } : { border: '1px solid #54B687' };
    },
    ipOrCompany() {
      if (this.inn.toString().length === 12) {
        return 'INDIVIDUAL';
      }
      return 'LEGAL';
    },
  },
  methods: {
    async validateByinFnsByInn() {
      const companies = await this.loadLegalCompanyByInnFromFns(this.inn, this.ipOrCompany);
      if (companies.length === 0) {
        this.error = true;
        this.$emit('error', this.error);
      } else {
        this.error = false;
      }
    },
    ...mapActions('dataLists', ['loadLegalCompanyByInnFromFns']),
  },
};
</script>

<style lang="scss" scoped>
.egrul-validator {
  display: flex;
  padding: 16px 0 8px;
  box-sizing: border-box;
  margin-bottom: 38px;

  img {
    width: 20px;
    margin-left: 36px;
    margin-right: 18px;
  }

  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #0E0F0F;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__field {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #141412;
    margin-bottom: 4px;
  }
}
</style>
