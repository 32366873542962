<template>
  <div :class="twoColumnStyle ? 'usage-point-connection--two-column' : 'usage-point-connection'" :style="spacingStyle">
    <v-search-select v-model="mainSubstationNumber" :disabled="disabled" :options="mainSubstations" class="select-item"
                     title="ПЦ *"/>
    <v-search-select v-if="distributionSubstationsText.length" v-model="distributionSubstationNumber"
                     :disabled="disabled"
                     :options="distributionSubstationsText" class="select-item" title="РП"/>
    <div v-if="rpMultiSelect && internalValue.distributionSubstations?.length > 0"
         class="usage-point-connection__rp-multi-select">
      <div class="usage-point-connection__rp-multi-select__title">РП:</div>
      <div v-for="(substation, index) in internalValue.distributionSubstations" :key="index"
           class="usage-point-connection__rp-multi-select__tp">
        {{ substation.fullName }}&nbsp;&nbsp;<img class="usage-point-connection__rp-multi-select__delete"
                                                  src="~@/assets/icons/basic/cross.svg"
                                                  @click="unselectSubstation(index)"/>
      </div>
    </div>
    <v-search-select v-if="!onlyTp && transformationSubstations.length" v-model="transformationSubstationNumber"
                     :disabled="disabled"
                     :options="transformationSubstationsText" class="select-item" title="ТП"/>
    <v-select v-if="!onlyTp" v-model="internalValue.consumerLine" :disabled="disabled" :options="lines"
              class="select-item"
              title="Линия *"/>
  </div>
</template>

<script>
import { VSearchSelect, VSelect } from '@/components';
import { mapActions } from 'vuex';

export default {
  name: 'UsagePointConnectionSelect',
  components: {
    VSelect,
    VSearchSelect,
  },
  props: {
    value: { type: Object, required: true },
    spacing: { type: String, default: '12px' },
    disabled: { type: Boolean, default: false },
    twoColumnStyle: { type: Boolean, default: false },
    onlyTp: { type: Boolean, default: false },
    rpMultiSelect: { type: Boolean, default: false },
    excludeSubstations: { type: Array, required: false },
  },
  async created() {
    this.substations = await this.getMainSubstationsListWithLines();
    this.internalValue = { ...this.value };
    if (!this.internalValue.consumerLine) this.internalValue.consumerLine = this.lines[0].value;
  },
  data() {
    return {
      substations: [],
      distributionSubstations: [],
      transformationSubstations: [],
      internalValue: {},
      lines: [
        { label: '0,4 кВт', value: '0,4 кВт' },
        { label: '10 кВт', value: '10 кВт' },
      ],
      lineName: '',
      mainSubstationNumber: '',
      distributionSubstationNumber: '',
      transformationSubstationNumber: '',
    };
  },
  computed: {
    mainSubstations() {
      const substationToSelectList = this.mapSubstationToSelectList(this.substations);

      if (!this.excludeSubstations || this.excludeSubstations.length === 0) {
        return substationToSelectList;
      }

      return substationToSelectList.filter((item) => !this.excludeSubstations.includes(item.value));
    },
    distributionSubstationsText() {
      const distributionSubstations = this.rpMultiSelect
        ? this.distributionSubstations.filter((item) => !this.internalValue.distributionSubstations?.find((selectedItem) => selectedItem.fullName === item.fullName))
        : this.distributionSubstations;

      return this.mapSubstationToSelectList(distributionSubstations);
    },
    transformationSubstationsText() {
      return this.mapSubstationToSelectList(this.transformationSubstations);
    },
    spacingStyle() {
      return {
        gap: this.twoColumnStyle ? '0' : this.spacing,
      };
    },
  },
  watch: {
    mainSubstationNumber() {
      console.log('mainSubstationNumberUpdated');
      if (!this.substations.length) return;
      this.updateMainSubstation();
    },
    distributionSubstationNumber() {
      if (!this.substations.length) return;
      this.updateDistributionSubstation();
    },
    transformationSubstationNumber() {
      if (!this.substations.length) return;
      this.updateTransformationSubstation();
    },
    substations() {
      console.log('on substations loaded');
      this.initValue();
    },
    value() {
      this.internalValue = this.value;
      this.initValue();
    },
    internalValue() {
      console.log('internalValue');
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    initValue() {
      console.log('init value', this.internalValue.mainSubstation?.fullName, this.substations.length);
      if (!this.internalValue.mainSubstation?.fullName && this.substations.length) {
        console.log('inner');
        if (this.internalValue.mainSubstation?.number) this.mainSubstationNumber = this.internalValue.mainSubstation.number;
        if (this.internalValue.distributionSubstation?.number) this.distributionSubstationNumber = this.internalValue.distributionSubstation.number;
        if (this.internalValue.transformationSubstation?.number) this.transformationSubstationNumber = this.internalValue.transformationSubstation.number;
      }
    },
    updateMainSubstation() {
      console.log('update ms');
      if (!this.mainSubstationNumber?.length) {
        this.clearMainSubstation();
        return;
      }
      if (this.internalValue.mainSubstation?.number !== this.mainSubstationNumber) this.clearDistributionSubstation();
      this.internalValue =
        {
          ...this.internalValue,
          mainSubstation: this.getSubstationByNumber(this.substations, this.mainSubstationNumber),
        };
      if (this.internalValue.mainSubstation && this.internalValue.mainSubstation?.lines) {
        this.distributionSubstations = this.getSubstationListFromLines(this.internalValue.mainSubstation?.lines);
      }
    },
    updateDistributionSubstation() {
      if (!this.distributionSubstationNumber?.length) {
        this.clearDistributionSubstation();
        return;
      }
      if (this.internalValue.distributionSubstation?.number !== this.distributionSubstationNumber) this.clearTransformationSubstation();

      const internalValue = {
        ...this.internalValue,
        distributionSubstation: this.getSubstationByNumber(this.distributionSubstations, this.distributionSubstationNumber),
        feeder: this.getLineNameBySubstationNumber(this.internalValue.mainSubstation.lines, this.distributionSubstationNumber),
      };

      if (this.rpMultiSelect) {
        const substations = internalValue.distributionSubstations ?? [];
        substations.push(
          this.getSubstationByNumber(this.distributionSubstations, this.distributionSubstationNumber),
        );

        internalValue.distributionSubstations = substations;
        this.distributionSubstationNumber = '';
      }

      this.internalValue = internalValue;

      this.transformationSubstations = this.getSubstationListFromLines(this.internalValue.distributionSubstation.lines);
    },
    updateTransformationSubstation() {
      if (!this.transformationSubstations?.length) {
        this.clearTransformationSubstation();
        return;
      }
      this.internalValue =
        {
          ...this.internalValue,
          line: this.getLineNameBySubstationNumber(this.internalValue.distributionSubstation.lines, this.transformationSubstationNumber),
          transformationSubstation: this.getSubstationByNumber(this.transformationSubstations, this.transformationSubstationNumber),
        };

    },
    clearTransformationSubstation() {
      this.transformationSubstationNumber = '';
      this.internalValue =
        {
          ...this.internalValue,
          line: undefined,
          transformationSubstation: undefined,
        };
    },
    clearDistributionSubstation() {
      this.clearTransformationSubstation();
      this.distributionSubstationNumber = '';
      this.transformationSubstations = [];
      this.internalValue =
        {
          ...this.internalValue,
          distributionSubstation: undefined,
          feeder: undefined,
        };
    },
    clearMainSubstation() {
      this.clearDistributionSubstation();
      this.internalValue =
        {
          ...this.internalValue,
          mainSubstation: undefined,
          distributionSubstations: undefined,
        };
      this.mainSubstationNumber = '';
      this.distributionSubstations = [];
    },
    getSubstationNameWithLine(substation) {
      if (substation.lineName) return `${substation.lineName}, ${substation.fullName}`;
      return substation.fullName;
    },
    getSubstationByNumber(substations, number) {
      return substations.find((it) => it.number === number);
    },
    getLineNameBySubstationNumber(lines, number) {
      return lines.find((it) => it.secondSubstation.number === number).name;
    },
    getSubstationListFromLines(lines) {
      if (!lines) return [];
      return lines.map(
        (it) => ({
            ...it.secondSubstation,
            lineName: it.name,
          }
        ),
      );
    },
    mapSubstationToSelectList(substations) {
      return substations.map(
        (it) => ({
          value: it.number,
          label: this.getSubstationNameWithLine(it),
        }),
      );
    },
    unselectSubstation(index) {
      this.internalValue.distributionSubstations.splice(index, 1);
    },
    ...mapActions('dataLists', ['getMainSubstationsListWithLines']),
  },
};
</script>

<style lang="scss" scoped>
.usage-point-connection {
  display: flex;
  flex-direction: column;

  &__rp-multi-select {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;

    &__title {
      margin-right: 12px;
    }

    &__tp {
      border: 1px solid #2f82df;
      border-radius: 15px;
      padding: 4px 8px;
    }

    &__delete {
      cursor: pointer;
    }
  }
}

.usage-point-connection--two-column {
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
  flex-wrap: wrap;

  .select-item {
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
</style>
