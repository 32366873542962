<template>
  <div class="cabinet-loss-acts-generate">
    <navigation-bar :current-step="0"
                    :steps="steps"
                    home="Акты приёма-передачи электрической энергии"
                    is-display-back
                    @open="goBack" />
    <v-divider />

    <div class="cabinet-loss-acts-generate__content">
      <h1 class="cabinet-loss-acts-generate__content__header">Формирование акта</h1>
      <h3 class="cabinet-loss-acts-generate__content__subtitle">За расчётный период</h3>

      <v-divider class="cabinet-loss-acts-generate__content__divider" />

      <div class="cabinet-loss-acts-generate__content_period">
        <div class="cabinet-loss-acts-generate__content__title">
          Выбрать период:
        </div>
        <v-date-picker
            v-model="currentDate"
            format="MMMM | YYYY"
            type="month"
        />
      </div>

      <v-divider class="cabinet-loss-acts-generate__content__divider" />

      <v-simple-indicator class="cabinet-loss-acts-generate__content__indicator" :color="indicators.balance.color" label="Баланс подписан" />

      <v-divider class="cabinet-loss-acts-generate__content__divider" />

      <v-circle-loader v-show="isLoading" />

      <template v-if="actIndicators?.creationByBalance">
        <h2 class="cabinet-loss-acts-generate__content__title">Параметры акта</h2>

        <table class="cabinet-loss-acts-generate__content__table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Показатель</th>
              <th>Потери, кВт*ч</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input v-model="settledVolume"
                       class="cabinet-loss-acts-generate__content__checkbox"
                       type="checkbox"
                />
              </td>
              <td>
                Урегулированные объемы
              </td>
              <td>
                {{ balanceVolumes.settledVolume }}
              </td>
            </tr>
            <tr>
              <td>
                <input v-model="unsettledVolume"
                       class="cabinet-loss-acts-generate__content__checkbox"
                       type="checkbox"
                />
              </td>
              <td>
                Не урегулированные объемы
              </td>
              <td>
                {{ balanceVolumes.unsettledVolume }}
              </td>
            </tr>
            <tr>
              <td>
                <input v-model="inProcessVolume"
                       class="cabinet-loss-acts-generate__content__checkbox"
                       type="checkbox"
                />
              </td>
              <td>
                В процессе урегулирования
              </td>
              <td>
                {{ balanceVolumes.inProcessVolume }}
              </td>
            </tr>
            <tr>
              <td>
                <input v-model="newVolume"
                       class="cabinet-loss-acts-generate__content__checkbox"
                       type="checkbox"
                />
              </td>
              <td>
                Новые
              </td>
              <td>
                {{ balanceVolumes.newVolume }}
              </td>
            </tr>
            <tr>
              <td>
                <input v-model="disagreementVolume"
                       class="cabinet-loss-acts-generate__content__checkbox"
                       type="checkbox"
                />
              </td>
              <td>
                Имеют разногласия
              </td>
              <td>
                {{ balanceVolumes.disagreementVolume }}
              </td>
            </tr>

          </tbody>
        </table>

        <v-divider class="cabinet-loss-acts-generate__content__divider" />

        <h2 class="cabinet-loss-acts-generate__content__title">Стороны</h2>

        <div class="cabinet-loss-acts-generate__content__sides">
          <v-select
              v-model="supplierInn"
              :options="supplierList"
              title="Заказчик"
              is-show-input-img
              class="cabinet-loss-acts-generate__content__sides__select"
          />
          <v-select
              v-model="performerSecondInn"
              :options="performerSecondList"
              title="Исполнитель"
              is-show-input-img
              class="cabinet-loss-acts-generate__content__sides__select"
          />
        </div>

        <v-divider class="cabinet-loss-acts-generate__content__divider" />

        <h2 class="cabinet-loss-acts-generate__content__title">Подписывающие лица</h2>

        <v-row v-if="supplierInn" class="cabinet-loss-acts-generate__signatories">
          <v-col :grid="{ xs: 2.5 }" class="cabinet-loss-acts-generate__signatories__col-wrapper">
            <p class="cabinet-loss-acts-generate__content__subtitle">Подписывающее лицо
              {{ supplierName }}*</p>
            <emploee-select-or-input :inn="supplierInn" permission="SVET_A5-S"
                                     @emploeeSelectOrInput="updateSupplierSignatory"/>
          </v-col>
        </v-row>
        <v-row v-if="performerSecondInn" class="cabinet-loss-acts-generate__signatories">
          <v-col :grid="{ xs: 2.5 }" class="cabinet-loss-acts-generate__signatories__col-wrapper">
            <p class="cabinet-loss-acts-generate__content__subtitle">Подписывающее лицо
              {{ performerSecondName }}*</p>
            <emploee-select-or-input :inn="performerSecondInn" permission="SVET_A5-S"
                                     @emploeeSelectOrInput="updatePerformerSecondSignatory"/>
          </v-col>
        </v-row>

        <v-divider class="cabinet-loss-acts-generate__content__divider" />
        <horizontal-progress-bar v-if="isWaitingSubmit" style="margin-bottom: 12px"/>
      </template>

      <v-row class="generate-integral-act__buttons">
        <v-col>
          <v-button style="color: #0E0F0F; background: #FFFFFF; border: 1px solid #C1C4C7;"
                    @click="goBack">
            Отменить
          </v-button>

        </v-col>
        <v-col style="display:flex; justify-content: right">
          <div>
            <v-button
              :disabled="notValid"
              style="border: 1px solid;"
              @click="generateActClick"
            >
              Сформировать акт
            </v-button>
          </div>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import {
  EmploeeSelectOrInput,
  NavigationBar,
  VButton,
  VCircleLoader,
  VCol,
  VDatePicker,
  VDivider,
  VRow,
  VSelect,
} from '@/components';
import dayjs from 'dayjs';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { mapActions } from 'vuex';
import { getDateAsForceISOString } from 'lib/utils/date';
import VSimpleIndicator from 'components/VSimpleIndicator/VSimpleIndicator.vue';
import formatting from 'lib/utils/formatting.js';

export default {
  components: {
    EmploeeSelectOrInput,
    VDatePicker,
    VButton,
    VRow,
    VCol,
    HorizontalProgressBar,
    NavigationBar,
    VDivider,
    VSimpleIndicator,
    VCircleLoader,
    VSelect,
  },
  data() {
    return {
      isWaitingSubmit: false,
      actIndicators: undefined,
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      isLoading: false,
      supplierSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleNameGenitive: {},
      },
      performerSecondSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
      steps: [
        {
          stepNumber: {
            value: 1,
            title: () => 'Формирование акта',
          },
        },
      ],
      newVolume: false,
      settledVolume: false,
      unsettledVolume: false,
      inProcessVolume: false,
      disagreementVolume: false,
      performerSecondInn: '',
      supplierInn: '',
    };
  },
  computed: {
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.actIndicators) {
        return {
          balance: unknown,
        };
      }

      const result = {};

      result.balance = this.actIndicators.isBalanceSigned ? check : error;

      return result;
    },
    notValid() {
      const notValidSupplier = !this.checkRepresentativeInput(this.supplierSignatory);
      const notValidPerformerSecond = !this.checkRepresentativeInput(this.performerSecondSignatory);
      const other = this.isWaitingSubmit || this.actIndicators === undefined || !this.indicators.balance;
      return notValidSupplier || notValidPerformerSecond || other;
    },
    balanceVolumes() {
      return {
        newVolume: this.numberFormatted(this.actIndicators?.lossesVolumes?.newVolume),
        settledVolume: this.numberFormatted(this.actIndicators?.lossesVolumes?.settledVolume),
        unsettledVolume: this.numberFormatted(this.actIndicators?.lossesVolumes?.unsettledVolume),
        inProcessVolume: this.numberFormatted(this.actIndicators?.lossesVolumes?.inProcessVolume),
        disagreementVolume: this.numberFormatted(this.actIndicators?.lossesVolumes?.disagreementVolume),
      };
    },
    supplierList() {
      if (!this.actIndicators?.creationByBalance?.supplier) {
        return [];
      }

      return [{
        label: this.actIndicators.creationByBalance.supplier.name,
        value: this.actIndicators.creationByBalance.supplier.inn,
      }];
    },
    performerSecondList() {
      if (!this.actIndicators?.creationByBalance?.performerSecond) {
        return [];
      }

      return [{
        label: this.actIndicators.creationByBalance.performerSecond.name,
        value: this.actIndicators.creationByBalance.performerSecond.inn,
      }];
    },
    supplierName() {
      return this.supplierList.filter((item) => item.value === this.supplierInn)[0]?.label ?? '';
    },
    performerSecondName() {
      return this.performerSecondList.filter((item) => item.value === this.performerSecondInn)[0]?.label ?? '';
    },
  },
  watch: {
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.isLoading = true;

          this.actIndicators = undefined;
          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          this.actIndicators = await this.loadServiceAndLossActIndicators({ year: date.year(), month: date.month() + 1 });

          this.isLoading = false;
        }
      },
    },
    supplierList(val) {
      if (val && val.length > 0) {
        this.supplierInn = val[0].value;
      }
    },
    performerSecondList(val) {
      if (val && val.length > 0) {
        this.performerSecondInn = val[0].value;
      }
    },
  },
  methods: {
    ...mapActions('cabinet', ['loadServiceAndLossActIndicators']),
    checkRepresentativeInput(signatory) {
      if (signatory.userId) {
        return signatory.reason.name === 'Устав' || signatory.powerOfAttorneyId;
      }
      return signatory.fullName?.name?.length > 0 &&
        signatory.fullName?.surname?.length > 0 &&
        signatory.role.name?.length > 0 &&
        signatory.reason.name?.length > 0 &&
        (signatory.reason.name === 'Устав' || (signatory.reason.date && signatory.reason.number));
    },
    updateSignatory(signatory, selected) {
      signatory.userId = selected.userId;
      signatory.role = {
        id: selected.role?.id,
        name: selected.role?.name,
      };
      signatory.reason = {
        name: selected.reason?.reasonDocument,
        number: selected.reason?.number,
        date: selected.reason?.date,
      };
      signatory.fullName = {
        name: selected.fullName?.name,
        surname: selected.fullName?.surname,
        patronymic: selected.fullName?.patronymic,
      };
      signatory.fullNameGenitive = {
        name: selected.fullNameGenitive?.name,
        surname: selected.fullNameGenitive?.surname,
        patronymic: selected.fullNameGenitive?.patronymic,
      };
      signatory.powerOfAttorneyId = selected.reason?.id;
      signatory.accountId = selected.accountId;
    },
    updateSupplierSignatory(selected) {
      this.updateSignatory(this.supplierSignatory, selected);
    },
    updatePerformerSecondSignatory(selected) {
      this.updateSignatory(this.performerSecondSignatory, selected);
    },
    createRepresentativeInput(signatory) {
      return signatory.userId ? {
        digital: {
          userId: signatory.userId,
          roleId: signatory.role.id,
          powerOfAttorneyId: signatory.powerOfAttorneyId,
        },
      } : {
        simple: {
          person: {
            name: signatory.fullName.name,
            surname: signatory.fullName.surname,
            patronymic: signatory.fullName.patronymic,
          },
          positionName: signatory.role.name,
          reasonDocument: {
            reasonDocumentName: signatory.reason.name,
            date: signatory.reason.date ? getDateAsForceISOString(signatory.reason.date) : null,
            number: signatory.reason.number,
          },
        },
      };
    },
    goBack() {
      this.$router.push('/cabinet/losses');
    },
    async generateActClick() {

      if (!this.actIndicators) {
        return;
      }

      if (!this.actIndicators.isBalanceSigned) {

        const errorMessage = 'Баланс не подписаны. Прошу подписать баланс.';

        this.setNotification({ message: errorMessage });
        return;
      }

      this.isWaitingSubmit = true;
      const date = dayjs(this.currentDate, 'MMMM | YYYY');

      try {
        await this.generateLossAct({
          year: date.year(),
          month: date.month() + 1,
          supplierRepresentative: this.createRepresentativeInput(this.supplierSignatory),
          performerSecondRepresentative: this.createRepresentativeInput(this.performerSecondSignatory),
          balanceId: this.actIndicators.creationByBalance.id,
          volumesByPointStatus: this.getVolumesByPointStatus(),
        });
        this.goBack();
      } catch (e) {
        this.setNotification({ message: 'При формировании акта приёма-передачи электрической энергии' });
        this.isWaitingSubmit = false;
        return;
      }

      this.isWaitingSubmit = false;
    },
    numberFormatted(val) {
      if (!val) {
        return '';
      }
      return formatting.numberFormatted(val);
    },
    getVolumesByPointStatus() {
      const result = [];

      if (this.newVolume) {
        result.push('NEW');
      }
      if (this.settledVolume) {
        result.push('SETTLED');
      }
      if (this.unsettledVolume) {
        result.push('UNSETTLED');
      }
      if (this.inProcessVolume) {
        result.push('IN_PROCESS');
      }
      if (this.disagreementVolume) {
        result.push('DISAGREEMENT');
      }

      return result;
    },
    ...mapActions('document', ['generateLossAct']),
    ...mapActions('user', ['setNotification']),
  },

};
</script>

<style lang="scss" scoped>
.cabinet-loss-acts-generate {
  &__content {
    padding: 24px 24px 24px 140px;
    width: 1032px;

    &__divider {
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &__header {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &_period {
      display: flex;
      gap: 16px;
    }

    &__checkbox {
      margin-left: 16px;
      margin-right: 90px;
    }

    &__table {
      width: 100%;
      border: 1px solid #D9D9D9;

      th {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #677379;
      }

      tr {
        height: 48px;
        border-bottom: 1px solid #D9D9D9;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #263238;
      }
    }

    &__sides {
      margin-top: 40px;
      display: flex;
      gap: 16px;

      &__select {
        width: 50%;
      }
    }
  }

  &__signatories {
    display: flex;
    padding: 8px;

    &__col-wrapper {
      width: 100%;
    }

  }

  &__buttons {
    margin-top: 10px;
    display: flex;
    width: 600px;
  }
}
</style>
