const SET_PERMISSIONS = (state, permissions) => {
  state.permissions = permissions;
};

const SET_FUNCTIONAL_BLOCKS = (state, functionalBlocks) => {
  state.functionalBlocks = functionalBlocks;
};


export default {
  SET_PERMISSIONS,
  SET_FUNCTIONAL_BLOCKS,
};
