<template>
  <div class="disagreements">
    <div class="disagreements__header">
      <button
        class="btn-back"
        @click="back"
      >
        Назад
      </button>
      <navigation-bar
        :current-step="0"
        :steps="disagreementsSteps"
        class="disagreements__navigation"
        home="Договоры"
        @open="openStep"
      />
    </div>
    <div class="disagreements__body">
      <v-table-new
        v-model="points"
        :template="template"
        :pagination-show="true"
        :divider-show="false"
        :show-filters-block="false"
        @on-cell-click="cellClickHandler"
        @select-all="allBordersWithSubjectSelected"
      />
      <horizontal-progress-bar v-if="isLoading"/>
      <div style="margin-top: 16px">
        <v-button :disabled="isLoading" @click="submit">
          Сформировать протокол разногласий
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';
import { VButton } from 'components';
import { mapActions, mapGetters } from 'vuex';
import VTableNew from 'components/VTableNew/VTable.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

export default {
  components: {
    HorizontalProgressBar,
    VTableNew,
    VButton,
    NavigationBar,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isLoading: false,
      points: [],
      disagreementsSteps: [
        {
          stepNumber: {
            value: 1,
            from: 1,
            title: () => 'Формирование протокола разногласий',
          },
        },
      ],
    };
  },
  computed: {
    template() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'Список точек учёта:',
            key: 'pointValue',
            type: 'filterAndCheckbox',
            customCheck: true,
            sort: {},
            filter: {},
            thStyle: {
              width: '50%',
              borderTop: borderStyle,
              borderRight: borderStyle,
              borderLeft: borderStyle,
              padding: '12px 16px',
            },
            tdStyle: {
              borderRight: borderStyle,
              borderLeft: borderStyle,
            },
          },
        ],
      };
    },
    ...mapGetters('user', ['getAccount']),
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        const pureData = await this.loadDocument(this.$route.params.documentId);
        const parsedData = JSON.parse(pureData.json);
        const supplyPoints = parsedData.Additions?.Addition?.flatMap((it) => it.ElectricitySupplyPointAddition?.ElectricitySupplyPointsByConsumerKind?.flatMap((byOrg) => (
          byOrg.ElectricityUsagePoint)).map((point) => ({
          pointValue: point?.SupplyPointName?.MainSubstation,
          point: {
            id: point.Id,
            mainSubstation: point?.SupplyPointName?.MainSubstation,
            selected: true,
          },
        })) ?? []) ?? [];
        const usagePoints =
          parsedData.Additions?.Addition?.flatMap((it) => it.ElectricityUsagePointsAddition?.ElectricityUsagePointsByOrganisation?.flatMap((byOrg) => (
            byOrg.ElectricityUsagePoint))?.map((point) => ({
            pointValue: point?.SupplyPointName?.MainSubstation,
            point: {
              id: point.Id,
              mainSubstation: point?.SupplyPointName?.MainSubstation,
              selected: true,
            },
          })) ?? []) ?? [];
        const newPoints = (await this.allNewUsagePoints({ inn: parsedData.ContractRequisites.ContractSides.DocumentSide.find((it) => it.ExecutorName === 'PerformerSecond').INN })).map((it) => ({
          pointValue: it?.pointInfo?.mainSubstation,
          point: {
            id: it.id,
            mainSubstation: it?.pointInfo?.mainSubstation,
            pointStatus: it.pointStatus,
            tariffCategory: it.tariffCategory,
            selected: it.selected,
          },
        }));
        this.points = [...supplyPoints, ...usagePoints, ...newPoints];
      } finally {
        this.isLoading = false;
      }
    },
    openStep(value) {
      if (value === -1) {
        this.$router.push('/cabinet/agreements');
      }
    },
    async submit() {
      console.log('submit');
      try {
        this.isLoading = true;
        await this.generateDisagreementsForContract({
          contractDocumentId: this.$route.params.documentId,
          usagePointIds: this.points.filter((it) => it.point.selected).map((it) => it.point.id),
        });
        this.$router.go(-2);
      } finally {
        this.isLoading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    cellClickHandler(value) {
      const pointIndex = this.points.findIndex((it) => it.point.id === value.row.point.id);
      // console.log('pindex', value.row.point.id, this.points);
      this.$set(this.points[pointIndex], 'selected', !this.points[pointIndex].selected);
    },
    allBordersWithSubjectSelected(val) {
      this.points = this.points.map((item) => ({ ...item, point: { ...item.point, selected: val } }));
    },
    ...mapActions('electricityTransmissionContract', ['generateDisagreementsForContract']),
    ...mapActions('document', ['loadDocument']),
    ...mapActions('point', ['allNewUsagePoints']),
  },
};
</script>

<style lang="scss" scoped>
.disagreements {
  &__header {
    display: flex;
  }

  &__body {
    padding: 24px
  }

  .btn-back {
    width: 86px;
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #677379;
    padding-left: 12px;

    background-image: url('~icons/navigation/arrow_left.svg');
    background-repeat: no-repeat;
    background-position: center left 4px;
    background-color: #FFFFFF;
    border: 1px solid #C1C4C7;
    border-radius: 24px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  &__navigation {
    margin: 0 0 0 26px;
  }
}
</style>
