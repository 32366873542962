import { BASE_URL } from '@/constants/api';
import axios from 'axios';

export async function fetchData(url, page, size, sortField, sort, filters) {
  const params = {
    page: page, size: size, ...filters,
  };
  if (sortField) {
    params.sortField = sortField;
    params.sort = sort;
  }
  const apiUrl = `${BASE_URL}/${url}?${new URLSearchParams(params).toString()}`;
  const response = await fetch(apiUrl);

  return await response.json();
}

export async function fetchAdministrativeDistricts() {
  return await (await fetch(`${BASE_URL}/administrative-district`)).json();
}

export async function findCriticalityByName(name) {
  return await (await fetch(`${BASE_URL}/criticality/find?name=${name}`)).json();
}

export async function deleteAdministrativeDistrict(id) {
  return await axios.delete(`${BASE_URL}/administrative-district/${id}`);
}

export async function createAdministrativeDistrict(name, fullName) {
  return await axios.post(`${BASE_URL}/administrative-district`, {
    name: name,
    fullName: fullName,
  });
}

export async function updateAdministrativeDistrict(id, name, fullName) {
  return await axios.put(`${BASE_URL}/administrative-district/${id}?name=${name}&fullName=${fullName}`);
}

export async function fetchOdkTags() {
  return await (await fetch(`${BASE_URL}/tag`)).json();
}

export async function deleteOdkTags(id) {
  return await axios.delete(`${BASE_URL}/tag/${id}`);
}

export async function createOdkTags(name) {
  return await axios.post(`${BASE_URL}/tag`, {
    name: name,
  });
}

export async function updateOdkTags(id, name) {
  return await axios.put(`${BASE_URL}/tag/${id}?name=${name}`);
}

export async function fetchAdreskaTags(id) {
  return (await axios.get(`${BASE_URL}/adreska/${id}/tags`)).data;
}

export async function saveAdreskaTags(id, tags) {
  return await axios.post(`${BASE_URL}/adreska/${id}/tags`, tags);
}

export async function findOdkTags(name) {
  return await (await fetch(`${BASE_URL}/tag/find?name=${name ?? ''}`)).json();
}

export async function fetchDefectsProcesses() {
  return (await axios.get(`${BASE_URL}/defects/processes`)).data;
}

export async function setPlanConsignmentArchive(id, archive) {
  return await axios.patch(`${BASE_URL}/plan-consignment/${id}?archive=${archive}`);
}

export async function fetchResponsibleByContractor() {
  return await (await fetch(`${BASE_URL}/responsible-by-contractor`)).json();
}

export async function deleteResponsibleByContractor(id) {
  return await axios.delete(`${BASE_URL}/responsible-by-contractor/${id}`);
}

export async function createResponsibleByContractor(contractorId, responsibleName, responsiblePhone, token) {
  return await axios.post(`${BASE_URL}/responsible-by-contractor`, {
    contractorId, responsibleName, responsiblePhone,
  }, { headers: { 'X-Authorization': `Bearer ${token}` } });
}

export async function updateResponsibleByContractor(id, contractorId, responsibleName, responsiblePhone, token) {
  return await axios.put(`${BASE_URL}/responsible-by-contractor/${id}?contractorId=${contractorId}&responsibleName=${responsibleName}&responsiblePhone=${responsiblePhone}`, null, { headers: { 'X-Authorization': `Bearer ${token}` } });
}

// HOLIDAYS

export async function fetchHolidays() {
  return await (await fetch(`${BASE_URL}/holiday`)).json();
}

export async function deleteHoliday(id) {
  return await axios.delete(`${BASE_URL}/holiday/${id}`);
}

export async function createHoliday(name, externalId) {
  return await axios.post(`${BASE_URL}/holiday`, {
    name: name,
    externalId: externalId,
  });
}

export async function updateHoliday(id, name, externalId) {
  return await axios.post(`${BASE_URL}/holiday/${id}`, {
    name: name,
    externalId: externalId,
  });
}

// EVENTS

export async function fetchEventById(id) {
  return await (await fetch(`${BASE_URL}/events/${id}`)).json();
}


export async function fetchEvents() {
  return await (await fetch(`${BASE_URL}/events`)).json();
}

export async function deleteEvent(id) {
  return await axios.delete(`${BASE_URL}/events/${id}`);
}

export async function createEvent(body) {
  return await axios.post(`${BASE_URL}/events`, body);
}

export async function cloneEvent(id, body) {
  return await axios.post(`${BASE_URL}/events/${id}/clone`, body);
}

export async function updateEvent(id, body) {
  return await axios.post(`${BASE_URL}/events/${id}`, body);
}

// AXP
export async function fetchAxpById(id) {
  return await (await fetch(`${BASE_URL}/axp/${id}`)).json();
}
export async function fetchAxp() {
  return await (await fetch(`${BASE_URL}/axp`)).json();
}

export async function deleteAxp(id) {
  return await axios.delete(`${BASE_URL}/axp/${id}`);
}

export async function createAxp(body) {
  return await axios.post(`${BASE_URL}/axp`, body);
}

export async function updateAxp(id, body) {
  // console.log('up', { ...body });
  return await axios.post(`${BASE_URL}/axp/${id}`, body);
}

// ODK

export async function fetchOdk() {
  return await (await fetch(`${BASE_URL}/odk`)).json();
}

export async function deleteOdk(id) {
  return await axios.delete(`${BASE_URL}/odk/${id}`);
}

export async function createOdk(body) {
  return await axios.post(`${BASE_URL}/odk`, body);
}


export async function updateOdk(id, body) {
  return await axios.post(`${BASE_URL}/odk/${id}`, body);
}

// AXP ELEMENTS
export async function fetchAxpElementsByAxpId(axpId) {
  return await (await fetch(`${BASE_URL}/axp/${axpId}/elements`)).json();
}

export async function fetchAxpElements() {
  return await (await fetch(`${BASE_URL}/elements`)).json();
}

export async function fetchAxpElementsById(id) {
  return await (await fetch(`${BASE_URL}/element/${id}`)).json();
}

export async function deleteAxpElement(id) {
  return await axios.delete(`${BASE_URL}/elements/${id}`);
}

export async function createAxpElement(body) {
  return await axios.post(`${BASE_URL}/elements`, body);
}


export async function updateAxpElements(id, body) {
  return await axios.post(`${BASE_URL}/elements/${id}`, body);
}

// ELEMENT TYPE
export async function fetchElementTypes() {
  return await (await fetch(`${BASE_URL}/axp-element-types`)).json();
}

export async function deleteElementType(id) {
  return await axios.delete(`${BASE_URL}/axp-element-types/${id}`);
}

export async function createElementType(data) {
  const body = {
    externalId: data.externalId,
    name: data.name,
    assemblyPercentage: data.installationCompletionPercentage,
  };

  return await axios.post(`${BASE_URL}/axp-element-types`, body);
}

export async function updateElementType(id, data) {
  const body = {
    externalId: data.externalId,
    name: data.name,
    assemblyPercentage: parseInt(data.installationCompletionPercentage),
  };

  return await axios.post(`${BASE_URL}/axp-element-types/${id}`, body);
}

export async function getElementTypesByAxpId(axpId) {
  return await axios.get(`${BASE_URL}/axp-element-types/axp/${axpId}`);
}

export async function getElementTypesByAxpIdWithDecors(axpId, elementIds, params = {}) {
  const requestParams = {
    elementIds: elementIds,
  };

  if (Object.hasOwnProperty.call(params, 'decorTypeId')) {
    requestParams.decorTypeId = params.decorTypeId;
  }
  return await axios.get(`${BASE_URL}/axp/${axpId}/elements/default-and-decor-not-archived`, {
    params: requestParams,
  });
}

// DECOR TYPE
export async function fetchDecorTypes() {
  return await (await fetch(`${BASE_URL}/decor-types`)).json();
}

export async function deleteDecorType(id) {
  return await axios.delete(`${BASE_URL}/decor-types/${id}`);
}

export async function createDecorType(body) {
  return await axios.post(`${BASE_URL}/decor-types`, body);
}


export async function updateDecorType(id, body) {
  return await axios.post(`${BASE_URL}/decor-types/${id}`, body);
}


// UNITS
export async function fetchUnits() {
  return await (await fetch(`${BASE_URL}/units`)).json();
}

// ADRESKA
export async function fetchAdreskaById(id) {
  return await (await fetch(`${BASE_URL}/adreska/${id}`)).json();
}

export async function fetchAdreska() {
  return await (await fetch(`${BASE_URL}/adreska`)).json();
}

export async function deleteAdreska(id) {
  return await axios.delete(`${BASE_URL}/adreska/${id}`);
}

export async function getAdreska(id) {
  return await axios.get(`${BASE_URL}/adreska/${id}`);
}

export async function createAdreska(body, token) {
  return await axios.post(`${BASE_URL}/adreska`, body, { headers: { 'X-Authorization': `Bearer ${token}` } });
}


export async function updateAdreska(id, body, token) {
  return await axios.post(`${BASE_URL}/adreska/${id}`, body, { headers: { 'X-Authorization': `Bearer ${token}` } });
}

// WAREHOUSES

export async function fetchWarehouse() {
  return await (await fetch(`${BASE_URL}/warehouses`)).json();
}

export async function fetchWarehouseById(id) {
  return await (await fetch(`${BASE_URL}/warehouses/${id}`)).json();
}

export async function deleteWarehouse(id) {
  return await axios.delete(`${BASE_URL}/warehouses/${id}`);
}

export async function createWarehouse(body) {
  return await axios.post(`${BASE_URL}/warehouses`, body);
}


export async function updateWarehouse(id, body) {
  return await axios.post(`${BASE_URL}/warehouses/${id}`, body);
}

// DEFECTS

export async function fetchDefect() {
  return await (await fetch(`${BASE_URL}/defects`)).json();
}

export async function fetchDefectById(id) {
  return await (await fetch(`${BASE_URL}/defects/${id}`)).json();
}


export async function deleteDefect(id) {
  return await axios.delete(`${BASE_URL}/defects/${id}`);
}

export async function createDefect(body) {
  return await axios.post(`${BASE_URL}/defects`, body);
}


export async function updateDefect(id, body) {
  return await axios.post(`${BASE_URL}/defects/${id}`, body);
}

// CONTRACT
export async function fetchContractDebtById(id) {
  return await (await fetch(`${BASE_URL}/contract-debt?contractId=${id}`)).json();
}

// OBJECTS
export async function getAdreskaFact(adreskaId) {
  return await axios.get(`${BASE_URL}/adreskafact?adreskaId=${adreskaId}`);
}
