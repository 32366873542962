<template>
  <div>
    <file-download/>
  </div>
</template>

<script>
import FileDownload from 'atoms/icons/action/FileDownload.vue';
export default {
  name: 'VTableCellFileDownload',
  components: { FileDownload },
};
</script>

<style scoped>

</style>
