import gql from 'graphql-tag';
import { profileType, roleType, userType } from './types';

export const LOGGED_IN_USER = gql`
  query LOGGED_IN_USER($id: String) {
    user(id: $id) {
      ...User_type
      authorizations {
        history {
          updatedAt
          role {
            name
          }
        }
        type
        ogrnip
        bankRequisites {
          bankName
          correspondentAccount
          bik
          settlementAccount
        }
        birthDate
        phoneNumber
        type
        email
        inn
        citizenship
        identityDocumentInfo {
          number
          documentType
          issueDate
          issuerCode
          issuerName
          series
          otherName
        }
        registrationAddress
        liveAddress
        birthPlace
        powerOfAttorney(specificSelection: VISIBLE_FOR_USER) {
          id
          title
          documentId
          startsFrom
          expiresAt
          grantedAt
          kind
          initiatorUser {
            id
            fullName {
              name
              surname
              patronymic
            }
          }
          grantedToUser {
            id
            fullName {
              name
              surname
              patronymic
            }
          }
          permissions {
            code
            description
          }
          status
        }
        id
        externalId
        role {
          ...Role_type
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
        }
        user {
          ...User_type
        }
      }
    }
  }
  ${userType}
  ${profileType}
  ${roleType}
`;

export const USER_BY_ID = gql`
  query USER_BY_ID($id: String) {
    user(id: $id) {
      id
      fullName {
        name
        surname
        patronymic
      }
      fullNameGenitiveCase {
        name
        surname
        patronymic
      }
      email
      phoneNumber
      snils
      agreedWithUserAgreement
      agreedWithPersonalDataAgreement
      authorizations {
        history {
          updatedAt
          role {
            name
          }
        }
        type
        ogrnip
        bankRequisites {
          bankName
          correspondentAccount
          bik
          settlementAccount
        }
        birthDate
        phoneNumber
        type
        email
        inn
        citizenship
        identityDocumentInfo {
          number
          documentType
          issueDate
          issuerCode
          issuerName
          series
          otherName
        }
        registrationAddress
        liveAddress
        birthPlace
        powerOfAttorney {
          id
          title
          documentId
          startsFrom
          expiresAt
          grantedAt
          kind
          initiatorUser {
            id
            fullName {
              name
              surname
              patronymic
            }
          }
          grantedToUser {
            id
            fullName {
              name
              surname
              patronymic
            }
          }
          permissions {
            code
            description
            usingOnAttorney
          }
          status
        }
        id
        externalId
        role {
          ...Role_type
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
        }
        user {
          ...User_type
        }
      }
    }
  }
  ${userType}
  ${profileType}
  ${roleType}
`;

export const GET_WORKFLOWS = gql`
  query GET_WORKFLOWS {
    workflows {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;

export const GET_WORKFLOWS_BY_CORRELATION_ID = gql`
  query GET_WORKFLOWS_BY_CORRELATION_ID($correlationId: String) {
    workflowsByCorrelationId(correlationId: $correlationId) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTRACT_PATTERNS = gql`
  query GET_CONTRACT_PATTERNS($filter: ContractPatternFilter) {
    contractPatterns(filter: $filter) {
      name
      xmlContent
      sides {
        contractSide
        credentials{
          correlationId
          name
          inn
          kpp
          ogrn
          oktmo
          okpo
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          okved
          additionalOkved
          oktmo
          okpo
          okved
          legalAddress
          postAddress
          shortName
          sideName
          updatedAt
          bankRequisitesUpdatedAt
        }
      }
      type
      mnemonicType
      createdAt
      updatedAt
    }
  }
`;

export const GET_DOCUMENT = gql`
  query GET_DOCUMENT($id: String!) {
    document(id: $id) {
      id
      xml
      json
      title
      signature
    }
  }
`;

export const GET_SUPPLIER_BY_ID = gql`
  query GET_SUPPLIER_BY_ID(
    $id: String!
  ) {
    supplier(id: $id) {
      id
      name
      inn
      kpp
      address
      phoneNumber
      email
      website
      onPlatform
    }
  }
`;

export const GET_SUPPLIER_BY_INN_AND_OGRN = gql`
  fragment SupplierFragment on Supplier {
    name
    shortName
    inn
    ogrn
    kpp
    address
    phoneNumber
    email
    website
    regions
    onPlatform
    account {
      id
    }
    bankRequisites {
      bik
      settlementAccount
      bankName
      correspondentAccount
    }
    oktmo
    okpo
    okved
    postAddress
    departmentName
    area
    district
    generalDirectorFullName
    generalDirectorFullNameGenitive
  }
  query GET_SUPPLIER_BY_INN_AND_OGRN(
    $filter: SupplierFilter!
    $filter2: SupplierFilter!
    $filter3: SupplierFilter!
  ) {
    UTILITY: suppliers(filter: $filter) {
      ...SupplierFragment
      ... on UtilitySupplier {
        boiler
        boilerCompany {
          name
        }
      }
    }
    RETAILER: suppliers(filter: $filter2) {
      ...SupplierFragment
      ... on RetailSupplier {
        supplierOfLastResort
        available
        regions
      }
    }
    ODK: suppliers(filter: $filter3) {
      ...SupplierFragment
    }
  }
`;

export const GET_SUPPLIERS = gql`
  fragment SupplierFragment on Supplier {
    id
    externalId
    name
    ogrn
    shortName
    inn
    kpp
    address
    phoneNumber
    email
    website
    regions
    onPlatform
    account {
      id
    }
    bankRequisites {
      bik
      settlementAccount
      bankName
      correspondentAccount
    }
    oktmo
    okpo
    okved
    postAddress
    departmentName
    area
    district
    generalDirectorFullName
    generalDirectorFullNameGenitive
  }
  query GET_SUPPLIERS($filter: SupplierFilter!, $filter2: SupplierFilter!, $filter3: SupplierFilter!) {
    UTILITY: suppliers(filter: $filter) {
      ... SupplierFragment
      ... on UtilitySupplier {
        boiler
        ogrn
        normativeLoss {
          value
          percentValue
        }
        #              TODO: fix display tariff in directory suppliers after individualTariff -> UtilityIndividualTariff
        individualTariff {
          startDate
          endDate
          singleRateElectricityTariff
          rateForTheMaintenanceOfElectricalNetworks
          rateForPaymentOfTechnologicalLosses
          tariffsOrder {
            documentId
            year
            docDate
            orderNumber
            orderName
            orderPublisher
          }
        }
        presenceZones {
          id
          presenceAddress
          equipmentDescription
        }
      }
    }
    RETAILER: suppliers(filter: $filter2) {
      ...SupplierFragment
      ... on RetailSupplier {
        supplierOfLastResort
        lossTariff {
          lossTariff
        }
      }
    }
    ODK: suppliers(filter: $filter3) {
      ...SupplierFragment
    }
  }
`;

export const GET_USAGE_POINTS_FOR_INDIVIDUAL = gql`
  query GET_USAGE_POINTS_FOR_INDIVIDUAL {
    allUsagePointsForIndividual {
      id
      documentId
      admissionCertificateId
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        id
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
        qualityIndicators
        readingsTransmitted
        sealStatus
        ownerCompany
        productionYear
        location
        address
        microgeneratorStatus
        type
      }
      sourceContractNumber
      sourceContractDate
      individualAccountId
      pointStatus
      consumer{
        consumerName
        objectType
        businessAccount {
          balance
          id
        }
        contractNumber
        serviceLocation
        region
        category
        tariff
        tariffZone
        firstHalfYearTariff {
          T1
          T2
          T3
        }
        secondHalfYearTariff {
          T1
          T2
          T3
        }
      }
      objectAddress
      objectType
      tariffCategory
    }
  }
`;

export const GET_USAGE_POINTS_FOR_INDIVIDUAL_WITH_MEASUREMENTS = gql`
  query GET_USAGE_POINTS_FOR_INDIVIDUAL_WITH_MEASUREMENTS($accountingPeriod: AccountingPeriodInput!) {
    allUsagePointsForIndividual {
      id
      documentId
      admissionCertificateId
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        id
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
        qualityIndicators
        readingsTransmitted
        sealStatus
        ownerCompany
        productionYear
        location
        address
        microgeneratorStatus
        type
      }
      sourceContractNumber
      sourceContractDate
      individualAccountId
      pointStatus
      consumer{
        consumerName
        objectType
        businessAccount {
          balance
          id
        }
        contractNumber
        serviceLocation
        region
        category
        tariff
        tariffZone
        firstHalfYearTariff {
          T1
          T2
          T3
        }
        secondHalfYearTariff {
          T1
          T2
          T3
        }
      }
      objectAddress
      objectType
      tariffCategory
      measurements(accountingPeriod: $accountingPeriod) {
        usagePointId
        currentValue
        previousValue
        totalConsumption
        additionalVolume
        accountingPeriod {
          year
          month
        }
        accountingMethod
        measurementsDifference
        accountingRatio
        variableLoss
        constLoss
        currentTariffsMeasurements {
          readingT1
          readingT2
          readingT3
        }
        previousTariffsMeasurements {
          readingT1
          readingT2
          readingT3
        }
      }
    }
  }
`;

export const GET_USAGE_POINT = gql`
  query GET_USAGE_POINT($id: String!) {
    usagePoint(id: $id) {
      id
      documentId
      admissionCertificateId
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
        qualityIndicators
        readingsTransmitted
        sealStatus
        ownerCompany
        productionYear
        location
        address
        microgeneratorStatus
      }
      sourceContractNumber
      sourceContractDate
      individualAccountId
      consumer{
        consumerName
        objectType
        contractNumber
        serviceLocation
        region
        category
        tariff
        firstHalfYearTariff {
          T1
          T2
          T3
        }
        secondHalfYearTariff {
          T1
          T2
          T3
        }
      }
    }
  }
`;

export const GET_DOCUMENT_VIEW = gql`
  query GET_DOCUMENT_VIEW($id: String) {
    documentView(id: $id) {
      id
      title
      subtitle
      info
      status
      documentNumber
      formattedDate
      documentId
      counterparties {
        accountId
        companyInn
        departmentId
        departmentName
        documentViewStatus
        name
      }
      date
      formattedDate
      documentKind
      __typename
      ... on PdfDocumentView {
        id
        namespace
        downloadPdfUrl
        showPdfUrl
        attachments {
          filename
          contentType
          size
          downloadLink
        }
        powerOfAttorney {
          id
          documentId
          startsFrom
          expiresAt
        }
      }
      ... on ElectricityTariffsDocumentView {
        id
        electricityTariffsOrderId
      }
      ... on TwoSideDocumentView {
        namespace
        accountingPeriod {
          year
          month
        }
        statusByCurrentCompany
        currentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        adjacentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT_VIEW_BY_DOCUMENT_ID = gql`
  query GET_DOCUMENT_VIEW_BY_DOCUMENT_ID($documentId: String) {
    documentViewByDocumentId(documentId: $documentId) {
      id
      title
      subtitle
      info
      status
      documentNumber
      formattedDate
      documentId
      counterparties {
        accountId
        companyInn
        departmentId
        departmentName
        documentViewStatus
        name
      }
      date
      formattedDate
      documentKind
      __typename
      ... on PdfDocumentView {
        id
        namespace
        downloadPdfUrl
        showPdfUrl
        attachments {
          filename
          contentType
          size
          downloadLink
        }
        powerOfAttorney {
          id
          documentId
          startsFrom
          expiresAt
        }
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
      ... on ElectricityTariffsDocumentView {
        id
        electricityTariffsOrderId
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
      ... on TwoSideDocumentView {
        namespace
        accountingPeriod {
          year
          month
        }
        statusByCurrentCompany
        currentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        adjacentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
    }
  }
`;

export const GET_DOCUMENT_VIEWS = gql`
  query GET_DOCUMENT_VIEWS($sort: DocumentViewSort) {
    documentViews(sort: $sort) {
      id
      title
      subtitle
      info
      status
      documentNumber
      formattedDate
      documentId
      correlationId
      associatedId
      counterparties {
        accountId
        companyInn
        departmentId
        departmentName
        documentViewStatus
        name
      }
      date
      formattedDate
      documentKind
      __typename
      ... on PdfDocumentView {
        id
        namespace
        downloadPdfUrl
        showPdfUrl
        attachments {
          filename
          contentType
          size
          downloadLink
        }
        powerOfAttorney {
          id
          documentId
          startsFrom
          expiresAt
        }
      }
      ... on ElectricityTariffsDocumentView {
        id
        electricityTariffsOrderId
      }
    }
  }
`;

export const GET_DOCUMENT_VIEWS_FILTER = gql`
  query GET_DOCUMENT_VIEWS(
    $filter: DocumentViewFilter
    $sort: DocumentViewSort
  ) {
    documentViews(filter: $filter, sort: $sort) {
      id
      title
      subtitle
      info
      status
      documentNumber
      formattedDate
      initiatorAccountId
      documentId
      correlationId
      associatedId
      counterparties {
        name
        documentViewStatus
        departmentName
        departmentId
        companyInn
        accountId
      }
      date
      formattedDate
      documentKind
      accountingPeriod {
        year
        month
      }
      departmentName
      __typename
      ... on PdfDocumentView {
        id
        namespace
        downloadPdfUrl
        showPdfUrl
        attachments {
          filename
          contentType
          size
          downloadLink
        }
        powerOfAttorney {
          id
          documentId
          startsFrom
          expiresAt
        }
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
      ... on ElectricityTariffsDocumentView {
        id
        electricityTariffsOrderId
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
      ... on TwoSideDocumentView {
        namespace
        accountingPeriod {
          year
          month
        }
        statusByCurrentCompany
        currentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        adjacentCompany {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        rejectedReason
        supplierStatus
        performerFirstStatus
        performerSecondStatus
        statusDateChangedAt
      }
    }
  }
`;

export const GET_TRANSMISSION_CONTRACTS = gql`
  query GET_ALL_TRANSMISSION_CONTRACTS($filter: TransmissionContractFilter){
    transmissionContracts(filter: $filter) {
      id
      sourceContractId
      documentId
      kindBySides
      documentView {
        id
        title
        subtitle
        info
        departmentName
        status
        documentNumber
        documentId
        counterparties {
          accountId
          companyInn
          departmentId
          departmentName
          documentViewStatus
          name
        }
        date
        accountingPeriod {
          year
          month
        }
        formattedDate
        documentKind
        statusByCurrentCompany
        __typename
        ... on PdfDocumentView {
          id
          namespace
          downloadPdfUrl
          showPdfUrl
          attachments {
            filename
            contentType
            size
            downloadLink
          }
          powerOfAttorney {
            id
            documentId
            startsFrom
            expiresAt
          }
        }
      }
      contractInfo {
        number
        date
        sourceContractNumber
        sourceContractDate
      }
      actVerificationDocumentId
      actVerificationDLCDocumentId
      region
      initiatorAccountId
      initiatorUserId
      supplierInn
      performerFirstInn
      performerSecondInn
      supplierAccountId
      performerFirstAccountId
      performerSecondAccountId
      supplierName
      performerFirstName
      performerSecondName
      sidesInn
      sidesAccountIds
      agreementsIds
      signersIds
      kindBySides
      kindByExisting
      contractStatus
      additionalContracts {
        documentView {
          documentNumber
          formattedDate
        }
      }
      rejectedReason
      supplierStatus
      performerFirstStatus
      performerSecondStatus
      statusDateChangedAt
    }
  }
`;

export const GET_ELECTRICITY_TRANSMISSION_CONTRACTS_BY_TYPE = gql`
  query GET_ELECTRICITY_TRANSMISSION_CONTRACTS_BY_TYPE($contractType: ContractTypeEnum) {
    electricityTransmissionContractsByType(contractType: $contractType) {
      isDisagreementProtocol
      sourceContractId
      kindBySides
      actVerification {
        documentId
      }
      status
      documentId
      additionalContracts {
        documentId
        status
        actVerification {
          documentId
        }
        contractInfo {
          number
          date
          region
          sourceContractDate
          sourceContractNumber
          effectiveDate
          expirationDate
        }
        id
        kindByExisting
        kindBySides
        supplier {
          userId
          accountId
          approvalListId
          inn
          name
          status
        }
        performerFirst {
          userId
          accountId
          approvalListId
          inn
          name
          status
        }
        performerSecond {
          userId
          accountId
          approvalListId
          inn
          name
          status
        }
      }
      contractInfo {
        number
        date
        effectiveDate
        expirationDate
        region
        sourceContractDate
        sourceContractNumber
      }
      id
      kindByExisting
      kindBySides
      supplier {
        userId
        accountId
        approvalListId
        inn
        name
        status
      }
      performerFirst {
        userId
        accountId
        approvalListId
        inn
        name
        status
      }
      performerSecond {
        userId
        accountId
        approvalListId
        inn
        name
        status
      }
      rejectedReason
      sourceContractId
      updatedAt
      documentId
      initiatorAccountId
      initiatorUserId
    }
  }
`;

export const ADMISSION_CERTIFICATES = gql`
  query ADMISSION_CERTIFICATES {
    admissionCertificates {
      status
      documentId
      id
      title
      number
      date
      rejectedReason
      updatedAt
      documentId
      sides {
        userId
        accountId
        approvalListId
        inn
        name
        status
        sideName
      }
    }
  }
`;

export const CONNECTION_CERTIFICATES = gql`
  query CONNECTION_CERTIFICATES {
    connectionCertificates {
      status
      documentId
      id
      title
      number
      date
      rejectedReason
      updatedAt
      documentId
      sides {
        userId
        accountId
        approvalListId
        inn
        name
        status
        sideName
      }
    }
  }
`;

export const CONNECTION_APPLICATION_CONTRACTS = gql`
  query CONNECTION_APPLICATION_CONTRACTS {
    connectionApplicationContracts {
      status
      documentId
      id
      title
      number
      date
      rejectedReason
      updatedAt
      documentId
      sides {
        userId
        accountId
        approvalListId
        inn
        name
        status
        sideName
      }
    }
  }
`;

export const ELECTRICITY_SUPPLY_CONTRACTS = gql`
  query ELECTRICITY_SUPPLY_CONTRACTS {
    electricitySupplyContracts {
      status
      documentId
      id
      title
      number
      date
      rejectedReason
      updatedAt
      documentId
      sides {
        userId
        accountId
        approvalListId
        inn
        name
        status
        sideName
      }
    }
  }
`;

export const GET_USEFUL_VACATIONS = gql`
  query GET_USEFUL_VACATIONS {
    usefulVacations {
      title
      id
      year
      month
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sides {
        status
        name
        inn
        userId
        approvalListId
        accountId
      }
      utility {
        inn
        name
      }
      retailer {
        inn
        name
      }
      retailerDepartment {
        name
        id
        departmentKind
        supplierId
        supplierInn
      }
      totalConsumption
      initiatorAccountId
      initiatorUserId
    }
  }
`;

export const GET_ELECTRICITY_BALANCES = gql`
  query GET_ELECTRICITY_BALANCES {
    electricityBalances {
      title
      id
      company {
        inn
        name
      }
      accountingPeriod {
        year
        month
      }
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      supplier {
        status
        name
        inn
        userId
        approvalListId
        accountId
      }
      performerFirst {
        status
        name
        inn
        userId
        approvalListId
        accountId
      }
      performerSecond {
        status
        name
        inn
        userId
        approvalListId
        accountId
      }
      transmissionContract {
        contractInfo {
          number
          date
        }
      }
      paymentVolume
      totalLoss
      initiatorAccountId
      initiatorUserId
      autoGenerated
    }
  }
`;

export const GET_ELECTRICITY_BALANCE_BY_ID = gql`
  query GET_ELECTRICITY_BALANCE_BY_ID($id: String!) {
    electricityBalance(id: $id) {
      title
      id
      company {
        inn
        name
      }
      accountingPeriod {
        year
        month
      }
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      supplier {
        status
        name
        userId
        inn
        approvalListId
        accountId
      }
      performerFirst {
        status
        name
        userId
        inn
        approvalListId
        accountId
      }
      performerSecond {
        status
        name
        userId
        inn
        approvalListId
        accountId
      }
      paymentVolume
      totalLoss
      totalLoss
      totalLossPercent
      totalPaymentLoss
      totalPaymentLossPercent
      totalLossFromPreviousPeriod
      totalLossToNextPeriod
      paymentVolume
      paymentVolumeOnePartElectricity
      paymentVolumeTwoPartElectricity
      paymentVolumeTwoPartPower
      totalTransit
      accountingTransit
      totalUsefulVacation
      totalInput
      dkp
      otherRetails
    }
  }
`;

export const GET_EIGHTEENTH_FORMS = gql`
  query GET_EIGHTEENTH_FORMS {
    eighteenthForms {
      title
      id
      year
      month
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sides {
        status
        name
        inn
        approvalListId
        accountId
        userId
      }
      utility {
        inn
        name
      }
      retailer {
        inn
        name
      }
      retailerDepartment {
        name
        id
        departmentKind
        supplierId
        supplierInn
      }
      kind
      initiatorAccountId
      initiatorUserId
    }
  }
`;

export const GET_BASIC_TRANSMISSION_CONTRACTS = gql`
  query GET_BASIC_TRANSMISSION_CONTRACTS($filter: TransmissionContractFilter){
    transmissionContracts(filter: $filter) {
      id
      kindBySides
      contractInfo {
        number
        date
        sourceContractNumber
        sourceContractDate
      }
      sides {
        companyInfo {
          correlationId
          name
          inn
          kpp
          ogrn
          oktmo
          okpo
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          okved
          additionalOkved
          oktmo
          okpo
          okved
          legalAddress
          postAddress
          shortName
          sideName
          updatedAt
          bankRequisitesUpdatedAt
        }
        sideName
        name
        inn
        accountId
      }
    }
  }
`;

export const GET_BASIC_TRANSMISSION_CONTRACT_BY_ID = gql`
  query GET_BASIC_TRANSMISSION_CONTRACT_BY_ID($id: String){
    transmissionContract(id: $id) {
      id
      kindBySides
      kindByExisting
      rejectedReason
      documentId
      sourceContractId
    }
  }
`;

export const GET_BASIC_ELECTRICITY_TRANSMISSION_CONTRACT_BY_ID = gql`
  query GET_BASIC_ELECTRICITY_TRANSMISSION_CONTRACT_BY_ID($id: String){
    electricityTransmissionContract(id: $id) {
      id
      kindBySides
      kindByExisting
      rejectedReason
      documentId
      sourceContractId
      draftId
    }
  }
`;

export const GET_SOURCE_CONTRACT_PARTIES = gql`
  query GET_CONTRACT_PARTIES($contractId: String){
    contractParties(contractId: $contractId) {
      supplier{
        name
        inn
        kpp
        ogrn
        bik
        settlementAccount
        bankName
        correspondentAccount
        oktmo
        okpo
        okved
        phoneNumber
        legalAddress
        postAddress
        representativeName
        representativeSurname
        representativePatronymic
        representativePosition
        reasonDocumentName
        reasonDocumentNumber
        reasonDocumentDate
      }
      performerFirst{
        name
        inn
        kpp
        ogrn
        bik
        settlementAccount
        bankName
        correspondentAccount
        oktmo
        okpo
        okved
        phoneNumber
        legalAddress
        postAddress
        representativeName
        representativeSurname
        representativePatronymic
        representativePosition
        reasonDocumentName
        reasonDocumentNumber
        reasonDocumentDate
      }
      performerSecond{
        name
        inn
        kpp
        ogrn
        bik
        settlementAccount
        bankName
        correspondentAccount
        oktmo
        okpo
        okved
        phoneNumber
        legalAddress
        postAddress
        representativeName
        representativeSurname
        representativePatronymic
        representativePosition
        reasonDocumentName
        reasonDocumentNumber
        reasonDocumentDate
      }
    }
  }
`;

export const GET_DOMAIN_DOCUMENT_BY_ID = gql`
  query GET_DOMAIN_DOCUMENT_BY_ID($id: String!){
    domainDocument(id: $id) {
      createdAt
      title
      id
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sides {
        status
        name
        inn
        approvalListId
        accountId
        userId
        isSimpleSignature
      }
      powerOfAttorney {
        id
        documentId
        expiresAt
        startsFrom
      }
      xmlDocumentType
      documentHash
      initiatorAccountId
      initiatorUserId
      documentHistory {
        domainDocumentId
        documentHash
        datetime
        action
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
        }
        account {
          id
          profile {
            ...Profile_type
          }
        }
        role {
          ...Role_type
        }
      }
    }
  }
  ${profileType}
  ${roleType}
`;

export const GET_DOMAIN_DOCUMENT_BY_DOCUMENT_ID = gql`
  query GET_DOMAIN_DOCUMENT_BY_DOCUMENT_ID($documentId: String!){
    domainDocumentByDocumentId(documentId: $documentId) {
      createdAt
      title
      id
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sides {
        status
        name
        inn
        approvalListId
        accountId
        userId
        isSimpleSignature
      }
      powerOfAttorney {
        id
        documentId
        expiresAt
        startsFrom
      }
      xmlDocumentType
      documentHash
      initiatorAccountId
      initiatorUserId
      documentHistory {
        documentHash
        domainDocumentId
        datetime
        action
        user {
          ...User_type
        }
        account {
          id
          profile {
            ...Profile_type
          }
        }
        role {
          ...Role_type
        }
      }
    }
  }
  ${userType}
  ${profileType}
  ${roleType}
`;

export const GET_INTEGRAL_ACTS = gql`
  query GET_INTEGRAL_ACTS{
    integralActs {
      createdAt
      borderId
      title
      id
      accountingPeriod {
        year
        month
      }
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sideSecond {
        status
        name
        userId
        inn
        approvalListId
        accountId
      }
      sideThird {
        status
        userId
        name
        inn
        approvalListId
        accountId
      }
      inputConsumption
      outputConsumption
      saldoConsumption
      selfNeedsConsumption
      transitConsumption
      kind
      borderDeviceActId
      initiatorAccountId
      initiatorUserId
      autoGenerated
    }
  }
`;

export const GET_INTEGRAL_ACT_FILE = gql`
  query GET_INTEGRAL_ACT_FILE($documentId: String!) {
    saveUrl(documentId: $documentId)
  }
`;

export const GET_PAYMENT_INFO = gql`
  query GET_PAYMENT_INFO {
    invoices {
      id
      name
      amount
      status
      payeeInn
      payeeName
      invoiceNumber
      invoiceDate
      paymentDate
      invoiceDocumentId
      __typename
      ... on MesElectricityInvoice {
        meterSerial
        personalAccount
      }
    }
  }
`;

export const METER_CONSUMPTIONS_BY_FILTER = gql`
  query METER_CONSUMPTIONS_BY_FILTER($filter: MeterConsumptionFilter) {
    meterConsumptionsByFilter(filter: $filter) {
      id
      accountingPeriod {
        year
        month
      }
      integralConsumption
      intervalConsumption
      t1Consumption
      t2Consumption
      t3Consumption
    }
  }
`;

export const TARIFFS_BY_ID = gql`
  query TARIFFS_BY_ID($id: String) {
    tariffsById(id: $id) {
      documentId
      year
      approved
      actual
      startUsingDate
      endUsingDate
      firstHalfYearTariffs {
        consumerCategory
        singleRate {
          T1
          T2
          T3
        }
        doubleRate {
          T1
          T2
          T3
        }
        multiRate {
          T1
          T2
          T3
        }
      }
      secondHalfYearTariffs {
        consumerCategory
        singleRate {
          T1
          T2
          T3
        }
        doubleRate {
          T1
          T2
          T3
        }
        multiRate {
          T1
          T2
          T3
        }
      }
    }
  }
`;

export const ACTUAL_TARIFFS_BY_REGION = gql`
  query ACTUAL_TARIFFS_BY_REGION($region: Region) {
    actualTariffsByRegion(region: $region) {
      documentId
      year
      approved
      actual
      startUsingDate
      firstHalfYearTariffs {
        consumerCategory
        singleRate {
          T1
          T2
          T3
        }
        doubleRate {
          T1
          T2
          T3
        }
        multiRate {
          T1
          T2
          T3
        }
      }
      secondHalfYearTariffs {
        consumerCategory
        singleRate {
          T1
          T2
          T3
        }
        doubleRate {
          T1
          T2
          T3
        }
        multiRate {
          T1
          T2
          T3
        }
      }
    }
  }
`;

export const ALL_USAGE_POINTS = gql`
  query ALL_USAGE_POINTS {
    allUsagePoints {
      id
      externalId
      additionKind
      pointInfo {
        mainSubstation
        switchGear
        feeder
        distributionSubstation
        line
        transformationSubstation
        other
        mainSubstationNumber
        borderName
        connectionName
        feederCell
      }
      delimitationActNumber
      delimitationActDate
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        id
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
      }
      currentTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      potentialTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      history {
        id
        revisionId
        updatedAt
        updatedByDocumentId
        updatedByDocument {
          id
          hash
          title
        }
      }
      accountingRatio
      pointKind
      pointStatus
      metersOwner
      maxPower
      measurementsReadingKind
      sourceContractNumber
      sourceContractDate
      isDuplicate
      tariffCategory
    }
  }
`;

export const ALL_USAGE_POINTS_BY_FILTER_PAGED = gql`
  query ALL_USAGE_POINTS_BY_FILTER_PAGED($filter: UsagePointFilter!, $pageIn: PageIn!) {
    allUsagePointsByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        externalId
        additionKind
        retailDepartment {
          id
          name
        }
        pointInfo {
          mainSubstation
          switchGear
          feeder
          distributionSubstation
          line
          transformationSubstation
          other
          mainSubstationNumber
          borderName
          connectionName
          feederCell
        }
        delimitationActNumber
        delimitationActDate
        supplier {
          name
          inn
          kpp
          ogrn
        }
        meterPlace
        meter {
          id
          contractDocumentId
          serialNumber
          accuracyClass
          ratedCurrent
          nominalVoltage
          calibrationDate
          nextCalibrationDate
          calibrationInterval
        }
        currentTransformer {
          type
          transformationRatio
          accuracyClass
          calibrationDate
          nextCalibrationDate
        }
        potentialTransformer {
          type
          transformationRatio
          accuracyClass
          calibrationDate
          nextCalibrationDate
        }
        history {
          id
          revisionId
          updatedAt
          updatedByDocumentId
          updatedByDocument {
            id
            hash
            title
          }
        }
        accountingRatio
        pointKind
        pointStatus
        variableLoss
        constLoss
        metersOwner
        maxPower
        measurementsReadingKind
        sourceContractNumber
        sourceContractDate
        isDuplicate
        tariffCategory
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const USAGE_POINT_BY_ID_AND_REVISION = gql`
  query USAGE_POINT_BY_ID_AND_REVISION($id: String!, $revision: Int) {
    usagePoint(id: $id, revision: $revision) {
      id
      externalId
      retailDepartment {
        id
        name
      }
      pointInfo {
        mainSubstation
        switchGear
        feeder
        distributionSubstation
        line
        transformationSubstation
        other
        mainSubstationNumber
        borderName
        connectionName
        feederCell
      }
      delimitationActNumber
      delimitationActDate
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        id
        type
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
      }
      currentTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      potentialTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      consumer {
        consumerName
        objectType
        reliabilityClass
      }
      retail {
        name
      }
      balanceBoundary {
        id
        name
      }
      history {
        id
        revisionId
        updatedAt
        updatedByDocumentId
        updatedByDocument {
          id
          hash
          title
        }
      }
      accountingRatio
      pointKind
      variableLoss
      constLoss
      voltageLevel
      actualVoltageLevel
      pointStatus
      metersOwner
      maxPower
      measurementsReadingKind
      objectAddress
      objectType
      sourceContractNumber
      sourceContractDate
      isDuplicate
      tariffCategory
      additionKind
    }
  }
`;

export const HISTORY = gql`
  query HISTORY {
    history {
      createdAt
      message
      kind
    }
  }
`;

export const BALANCE_DOCUMENTS_CREATION_SETTINGS = gql`
  query balanceDocumentsSettings {
    balanceDocumentsSettings {
      borderActsParams {
        isGenerationEnabled
        dayOfMonth
      }
      balanceParams {
        isGenerationEnabled
        dayOfMonth
      }
      serviceActParams {
        isGenerationEnabled
        dayOfMonth
      }
      lossActParams {
        isGenerationEnabled
        dayOfMonth
      }
      paymentOrderParams {
        isGenerationEnabled
        dayOfMonth
      }
    }
  }
`;

export const MEASUREMENTS_STATEMENTS = gql`
  query MEASUREMENTS($kind: UsagePointKind!) {
    measurementsStatements(kind: $kind) {
      documentId
      createdAt
      initiatorUser {
        id
        fullName {
          name
          surname
          patronymic
        }
      }
      initiatorAccount {
        id
        profile {
          __typename
        }
      }
      rejectedReason
      sides {
        status
      }
      documentHistory {
        domainDocumentId
        datetime
        action
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
        }
        account {
          id
          profile {
            __typename
            ... on CompanyProfile {
              name
              id
            }
            ... on IndividualEnterpreneurProfile {
              name
              id
            }
          }
        }
        role {
          id
          name
        }
        documentHash
      }
      title
      updatedAt
    }
  }
`;

export const POINTS_WITH_MEASUREMENTS = gql`
  query POINTS_WITH_MEASUREMENTS($kind: [UsagePointKind]) {
    usagePointsByKind(kind: $kind) {
      id
      measurementsReadingKind
      pointKind
      additionKind
      meter {
        serialNumber
      }
      measurements {
        usagePointId
        currentValue
        previousValue
        consumption
        additionalVolume
        accountingPeriod
        accountingMethod
      }
    }
  }
`;

export const BALANCES = gql`
  query BALANCES($filter: BalanceFilter) {
    balances(filter: $filter) {
      id
      documentId
      companyInn
      year
      month
      sides
      signedBy
      approvedBy
      rejectedBy
      approvedByCurrentAccount
      signedByCurrentAccount
      rejectedByCurrentAccount
      status
      sidesNames
      signedByNames
      approvedByNames
      rejectedByNames
      paymentVolume
      totalLoss
      totalLossPercent
      totalTransit
      totalUsefulVacation
      accountingTransit
      paymentVolumeOnePartElectricity
      paymentVolumeTwoPartElectricity
      paymentVolumeTwoPartPower
      totalInput
      otherRetails
      dkp
      rejectedReason
      supplierStatus
      performerFirstStatus
      performerSecondStatus
      statusDateChangedAt
    }
  }
`;

export const LOSS_ACTS = gql`
  query LOSS_ACTS {
    lossActs {
      id
      documentId
      year
      month
      rejectedReason
      status
      sides {
        name
        inn
        sideName
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      supplier {
        inn
        status
        name
        accountId
        userId
        approvalListId
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      performerSecond {
        inn
        status
        name
        accountId
        userId
        approvalListId
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      updatedAt
      title
      invoiceDocumentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      totalCost
      initiatorAccountId
      initiatorUserId
      contractDate
      contractNumber
      autoGenerated
    }
  }
`;

export const SERVICE_ACTS = gql`
  query SERVICE_ACTS {
    serviceActs {
      id
      documentId
      year
      month
      kind
      rejectedReason
      status
      sides {
        name
        inn
        sideName
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      performerFirst {
        inn
        status
        name
        accountId
        userId
        approvalListId
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      performerSecond {
        approvalListId
        accountId
        name
        userId
        status
        inn
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
      }
      updatedAt
      title
      invoiceDocumentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      totalCost
      initiatorAccountId
      initiatorUserId
      contractDate
      contractNumber
      autoGenerated
    }
  }
`;

export const PAYMENT_ORDERS = gql`
  query PAYMENT_ORDERS {
    paymentOrders {
      id
      createdAt
      documentId
      status
      title

      payer {
        name
      }
      payee {
        name
      }
      reasonDocument {
        ... on ServiceAct {
          contractNumber
          contractDate
        }
        ... on LossAct {
          contractNumber
          contractDate
        }
      }
      totalCost
      autoGenerated
    }
  }
`;

export const CONTRACT_PAYMENTS = gql`
  query CONTRACT_PAYMENTS {
    contractPayments {
      id
      from {
        name
        inn
        ogrn
        kpp
      }
      to {
        name
        inn
        ogrn
        kpp
      }
      day
      percent
      withoutDate
      paymentType
      tariffType
    }
  }
`;

export const SAVE_URL = gql`
  query SAVE_URL($documentId: String!) {
    saveUrl(documentId: $documentId)
  }
`;

export const SHOW_URL = gql`
  query SHOW_URL($documentId: String!) {
    showUrl(documentId: $documentId)
  }
`;

export const USER_PERMISSIONS = gql`
  query LOGGED_IN_USER($id: String) {
    account(id: $id) {
      id
      authorizations {
        type
        ogrnip
        bankRequisites {
          bankName
          correspondentAccount
          bik
          settlementAccount
        }
        birthDate
        phoneNumber
        type
        email
        inn
        citizenship
        identityDocumentInfo {
          number
          documentType
          issueDate
          issuerCode
          issuerName
          series
          otherName
        }
        registrationAddress
        liveAddress
        birthPlace
        id
        externalId
        role {
          ...Role_type
        }
        user {
          ...User_type
        }
        account {
          id
          profile {
            __typename
            ... on CompanyProfile {
              id
            }
            ... on IndividualEnterpreneurProfile {
              id
            }
          }
        }
        powerOfAttorney {
          title
          documentId
          startsFrom
          expiresAt
          grantedAt
          number
        }
      }
    }
  }
  ${userType}
  ${roleType}
`;

export const ACCOUNT = gql`
  query GET_ACCOUNT($id: String) {
    account(id: $id) {
      id
      kind
      supplier {
        __typename
      }
      profile {
        __typename
        ... on CompanyProfile {
          id
          name
          inn
          kpp
          ogrn
          okpo
          oktmo
          okved
          additionalOkved
          bankRequisites {
            bankName
            bik
            settlementAccount
            correspondentAccount
          }
          legalAddress
          postAddress
          shortName
          fullEnglishName
          shortEnglishName
          departmentName
          history {
            id
            updatedAt
            revisionId
          }
          bankRequisitesHistory {
            id
            updatedAt
            revisionId
            updatedBy {
              fullName {
                name:
                surname
                patronymic
              }
            }
          }
        }
        ... on IndividualEnterpreneurProfile {
          id
          name
          inn
          okpo
          oktmo
          okved
          additionalOkved
          bankRequisites {
            bankName
            bik
            settlementAccount
            correspondentAccount
          }
          legalAddress
          postAddress
          history {
            id
            updatedAt
            revisionId
          }
          bankRequisitesHistory {
            id
            updatedAt
            revisionId
            updatedBy {
              fullName {
                name:
                surname
                patronymic
              }
            }
          }
        }
        ... on IndividualProfile {
          person {
            name
            surname
            patronymic
          }
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          snils
          inn
          registrationAddress
          liveAddress
          phoneNumber
          email
        }
        ... on PlatformWorkerProfile {
          type
          name
          role
        }
      }
      supplier {
        id
        kind
      }
    }
  }
`;

export const POWER_OF_ATTORNEYS = gql`
  query POWER_OF_ATTORNEYS {
    powerOfAttorneys {
      id
      title
      documentId
      startsFrom
      expiresAt
      grantedAt
      kind
      initiatorUser {
        ...User_type
      }
      grantedToUser {
        ...User_type
      }
      initiatorAccount {
        id
        authorizations {
          type
          ogrnip
          id
          bankRequisites {
            bankName
            correspondentAccount
            bik
            settlementAccount
          }
          birthDate
          phoneNumber
          type
          email
          inn
          citizenship
          identityDocumentInfo {
            number
            documentType
            issueDate
            issuerCode
            issuerName
            series
            otherName
          }
          registrationAddress
          liveAddress
          birthPlace
          externalId
          role {
            ...Role_type
          }
          user {
            ...User_type
          }
          account {
            id
          }
          powerOfAttorney {
            documentId
            startsFrom
            expiresAt
          }
        }
      }
      permissions {
        code
        description
        usingOnAttorney
      }
      status
    }
  }
  ${userType}
  ${roleType}
`;

export const SUPPLIER_DEPARTMETS = gql`
  query SUPPLIER_DEPARTMETS($supplierInn: String!) {
    supplierDepartments(supplierInn: $supplierInn) {
      id
      externalId
      name
    }
  }
`;
export const SUPPLIER_PRESENCE_ZONES_BY_FILTER = gql`
  query SUPPLIER_PRESENCE_ZONES_BY_FILTER($filter: SupplierPresenceZoneFilter!) {
    supplierPresenceZonesByFilter(filter: $filter) {
      id
      supplier {
        id
        kind
        name
        inn
      }
      presenceAddress
      equipmentDescription
    }

  }
`;

export const ALL_SUPPLIERS = gql`
  query {
    suppliers(filter: { kind: UTILITY }) {
      name
      inn
    }
  }
`;

export const ALL_SUPPLIERS_FOR_PRESENCE_ZONES = gql`
  query {
    suppliers(filter: { kind: UTILITY }) {
      name
      inn
    }
  }
`;

export const GET_METER_MODELS = gql`
  query METER_MODELS {
    meterModels {
      id
      attributes {
        typeDesignation
        publicationDate
      }
      manufacturer {
        manufacturerName
        country
      }
      calibrationInterval {
        years
        months
      }
      accuracyClass
      nominalCurrent
      nominalVoltage
      digitsCapacity {
        afterComma
        beforeComma
      }
      directConnection
    }
  }
`;

export const GET_CURRENT_TRANSFORMER_MODELS = gql`
  query GET_CURRENT_TRANSFORMER_MODELS {
    currentTransformerModel {
      id
      grsiNumber
      title
      power
      ratedVoltage
      ratedNetworkFrequency
      ratedCurrentPrimary
      ratedCurrentSecondary
      transformationRatio
      accuracyClass
      calibrationInterval
    }
  }
`;

export const GET_VOLTAGE_TRANSFORMER_MODELS = gql`
  query GET_VOLTAGE_TRANSFORMER_MODELS {
    voltageTransformerModel {
      id
      grsiNumber
      title
      power
      inputVoltage
      outputVoltage
      transformationRatio
      accuracyClass
      calibrationInterval
    }
  }
`;

export const GET_DATA_ACQUISITION_EQUIPMENT_MODEL = gql`
  query GET_DATA_ACQUISITION_EQUIPMENT_MODEL {
    dataAcquisitionEquipmentModel {
      id
      title
      calibrationInterval
    }
  }
`;

export const GET_DATA_ACQUISITION_EQUIPMENT_SWITCH_MODEL = gql`
  query GET_DATA_ACQUISITION_EQUIPMENT_SWITCH_MODEL {
    dataAcquisitionEquipmentSwitchModel {
      id
      title
      calibrationInterval
    }
  }
`;

export const USER_AUTHORIZATIONS = gql`
  query USER_AUTHORIZATIONS($id: String) {
    user(id: $id) {
      agreedWithUserAgreement
      agreedWithPersonalDataAgreement
      authorizations {
        type
        ogrnip
        id
        bankRequisites {
          bankName
          correspondentAccount
          bik
          settlementAccount
        }
        birthDate
        phoneNumber
        type
        email
        inn
        citizenship
        identityDocumentInfo {
          number
          documentType
          issueDate
          issuerCode
          issuerName
          series
          otherName
        }
        registrationAddress
        liveAddress
        birthPlace
        externalId
        role {
          ...Role_type
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
        }
      }
    }
  }
  ${profileType}
  ${roleType}
`;

export const COMPANY_ROLES = gql`
  query COMPANY_ROLES($filter: RolesFilter) {
    roles(filter: $filter) {
      ...Role_type
    }
  }
  ${roleType}
`;

export const BALANCE_INDICATORS = gql`
  query BALANCE_INDICATORS($year: Int!, $month: Int!) {
    balanceIndicators(year: $year, month: $month) {
      isUsefulVacationExists
      isTransmissionContractSigned
      isIntegralActsSigned
      isIntegralActsExists
      isPreviousBalanceExists
      creationByTransmissionContract {
        id
        supplier {
          inn
          name
        }
        performerFirst {
          inn
          name
        }
        performerSecond {
          inn
          name
        }
      }
    }
  }
`;

export const MEASUREMENTS_INDICATORS = gql`
  query MEASUREMENTS_INDICATORS($year: Int!, $month: Int!) {
    measurementsIndicators(year: $year, month: $month) {
      isMeasurementsExists,
      isTransmissionContractSigned,
      creationByTransmissionContract {
        id,
        supplier {
          inn
          name
        }
        performerFirst {
          inn
          name
        }
        performerSecond {
          inn
          name
        }
        initiatorAccountId
      }
    }
  }
`;

export const CONSUMERS = gql`
  query CONSUMERS {
    consumers {
      id
      inn
      snils
      consumerName
      serviceLocation
      contractNumber
      contractDate
      objectNumber
      startOfSupplyDate
      delimitationActNumber
      delimitationActDate
      connectionActNumber
      connectionActDate
      reliabilityClass
      maxPower
      emergencyArmorValue
      technicalArmorValue
      remark
      state
      transmissionContractId
      utilityAccountId
      usefulVacations {
        title
        id
        year
        month
        documentId
        actVerification {
          documentId
          id
          verifyingDocumentId
        }
        status
        rejectedReason
        updatedAt
        sides {
          status
          name
          inn
          approvalListId
          accountId
        }
        utility {
          inn
          name
        }
        retailer {
          inn
          name
        }
        retailerDepartment {
          name
          id
          departmentKind
          supplierId
          supplierInn
        }
        totalConsumption
      }
    }
  }
`;
export const CONSUMERS_BY_FILTER_PAGED = gql`
  query CONSUMERS_BY_FILTER_PAGED($filter: ConsumerFilter!, $pageIn: PageIn!) {
    consumersByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        businessAccount {
          balance
        }
        id
        inn
        snils
        consumerName
        retailDepartment {
          id
          name
        }
        serviceLocation
        contractNumber
        contractDate
        objectNumber
        startOfSupplyDate
        delimitationActNumber
        delimitationActDate
        connectionActNumber
        connectionActDate
        reliabilityClass
        maxPower
        emergencyArmorValue
        technicalArmorValue
        remark
        state
        transmissionContractId
        utilityAccountId
        usefulVacations {
          title
          id
          year
          month
          documentId
          actVerification {
            documentId
            id
            verifyingDocumentId
          }
          status
          rejectedReason
          updatedAt
          sides {
            status
            name
            inn
            approvalListId
            accountId
          }
          utility {
            inn
            name
          }
          retailer {
            inn
            name
          }
          retailerDepartment {
            name
            id
            departmentKind
            supplierId
            supplierInn
          }
          totalConsumption
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const INTEGRAL_ACT_PATTERNS = gql`
  query INTEGRAL_ACT_PATTERNS {
    integralActPatterns {
      id
      name
      balanceRule
      balanceSign
      borderName
      boundaries {
        consumers
        connections {
          mainSubstation {
            fullName
          }
          distributionSubstation
        }
      }
      external
      firstCompanyDepartmentId
      secondCompanyDepartmentId
      firstCompany {
        name
        inn
      }
      secondCompany {
        name
        inn
      }
      startsFrom
      expiresAt
    }
  }
`;

export const SUBSTATIONS = gql`
  query SUBSTATIONS {
    substations {
      substationKind
      fullName
      number
      shortName
      workingVoltageUpper
      workingVoltageLower
      workingVoltageLowerAdditional
      address
      lines {
        name
        secondSubstation {
          fullName
          number
          shortName
          workingVoltageUpper
          workingVoltageLower
          workingVoltageLowerAdditional
          address
          lines {
            name
          }
        }
      }
      usagePoints {
        id
        pointInfo {
          line
        }
        powerSupplyObject {
          id
          consumer {
            consumerName
          }
          objectAddress
        }
      }
    }
  }
`;

export const GET_USAGE_POINTS_BY_BALANCE_BOUNDARY_IDS = gql`
  query GET_USAGE_POINTS_BY_BALANCE_BOUNDARY_IDS($balanceBoundaryIds: [String!]!, $departmentIds: [String!]) {
    usagePointsByBalanceBoundaryIdsAndDepartmentIds(balanceBoundaryIds: $balanceBoundaryIds, departmentIds: $departmentIds) {
      id,
      pointInfo {
        mainSubstation
        switchGear
        feeder
        distributionSubstation
        line
        transformationSubstation
        other
        borderName
        connectionName
        mainSubstationNumber
        feederCell
      }
      tariffCategory
      pointStatus
      meter {
        serialNumber
      }
      additionKind
      consumer {
        consumerName
      }
    }
  }
`;

export const BALANCE_BOUNDARIES = gql`
  query BALANCE_BOUNDARIES($filter: BalanceBoundariesFilterInput) {
    balanceBoundaries(filter: $filter) {
      id
      consumers
      connections {
        mainSubstation {
          fullName
          number
        }
        distributionSubstation
      }
      firstCompany {
        name
        inn
        id
        postAddress
        okved
        bankRequisites {
          bik
          settlementAccount
          bankName
          correspondentAccount
        }
        oktmo
        okpo
        ogrn
        kpp
        address
        area
        available
        district
        email
        kind
        onPlatform
        phoneNumber
      }
      secondCompany {
        name
        inn
        id
        postAddress
        bankRequisites {
          bik
          settlementAccount
          bankName
          correspondentAccount
        }
        okved
        oktmo
        okpo
        ogrn
        kpp

        address
        area
        available
        district
        email
        kind
        onPlatform
        phoneNumber
      }
    }
  }
`;

export const DEPARTMENTS_BY_BALANCE_BOUNDARY_IDS = gql`
  query DEPARTMENTS_BY_BALANCE_BOUNDARY_IDS($balanceBoundaryIds: [String!]!) {
    departmentsByBalanceBoundaryIds(balanceBoundaryIds: $balanceBoundaryIds) {
      id
      name
      supplierId
      supplierInn
    }
  }
`;

export const PREFERENTIAL_CONNECTION_PRICE = gql`
  query connectionPreferentialPrice($connectionApplicationDocumentId: String) {
    connectionPreferentialPrice(connectionApplicationDocumentId: $connectionApplicationDocumentId)
  }
`;

export const POSSIBLE_PERMISSIONS = gql`
  query possiblePermissions {
    possiblePermissions {
      description
      code
      accountKind
      permissions {
        code
        description
        usingOnAttorney
        usingOnPlatform
      }
    }}
`;

export const ALL_PERMISSIONS = gql`
  query allPermissions {
    permissions {
      description
      code
      accountKind
      permissions {
        code
        description
        usingOnAttorney
        usingOnPlatform
        usingOnFns
      }
    }}
`;

export const INVITATIONS = gql`
  query invitations($filter: InvitationFilter) {
    invitations(filter: $filter) {
      id
      email
      accountId
      roleId
      status
    }
  }
`;
export const ACT_INDICATORS = gql`
  query ACT_INDICATORS($year: Int!, $month: Int!) {
    integralActIndicators(year: $year, month: $month) {
      isTransmissionContractSigned
      isMeasurementsInputTransitExists
    }
  }
`;

export const SERVICE_AND_LOSS_ACT_INDICATORS = gql`
  query SERVICE_AND_LOSS_ACT_INDICATORS($year: Int!, $month: Int!) {
    serviceAndLossActIndicators(year: $year, month: $month) {
      creationByBalance {
        id
        supplier {
          inn
          name
        }
        performerFirst {
          inn
          name
        }
        performerSecond {
          inn
          name
        }
      }
      isBalanceSigned
      onePartVolumes {
        newVolume
        settledVolume
        unsettledVolume
        inProcessVolume
        disagreementVolume
      }
      twoPartVolumes {
        newVolume
        settledVolume
        unsettledVolume
        inProcessVolume
        disagreementVolume
      }
      twoPartPower {
        newVolume
        settledVolume
        unsettledVolume
        inProcessVolume
        disagreementVolume
      }
      lossesVolumes {
        newVolume
        settledVolume
        unsettledVolume
        inProcessVolume
        disagreementVolume
      }
    }
  }
`;

export const IS_NEED_VERIFICATION_ACT = gql`
  query isNeedVerificationAct($isExisting: Boolean!, $sidesInns: [String], $accountingPeriod: AccountingPeriodInput, $isEighteenthFormsOrUsefulVacation: Boolean) {
    isNeedVerificationAct(isExisting: $isExisting, sidesInns: $sidesInns, accountingPeriod: $accountingPeriod, isEighteenthFormsOrUsefulVacation: $isEighteenthFormsOrUsefulVacation)
  }
`;

export const IS_TRANSMISSION_CONTRACT_SIGNED = gql`
  query isTransmissionContractSigned($accountingPeriod: AccountingPeriodInput!, $isByInitiator: Boolean!) {
    isTransmissionContractSigned(accountingPeriod: $accountingPeriod, isByInitiator: $isByInitiator)
  }
`;

export const UTILITY_INDIVIDUAL_TARIFF = gql`
  query UTILITY_INDIVIDUAL_TARIFF {
    utilityIndividualTariffAll {
      utility {
        inn
        name
      }
      startDate
      endDate
      singleRateElectricityTariff
      rateForTheMaintenanceOfElectricalNetworks
      rateForPaymentOfTechnologicalLosses
      tariffsOrder {
        documentId
        year
        docDate
        orderNumber
        orderName
        orderPublisher
      }
    }
  }
`;

export const SUPPLIERS_WITH_EMPLOYEES_BY_INN = gql`
  query GET_SUPPLIER_BY_INN_AND_OGRN(
    $filter: SupplierFilter!
    $filter2: SupplierFilter!
    $authorizationsFilter: UserAuthorizationForAccountFilter!
  ) {
    UTILITY: suppliers(filter: $filter) {
      id
      name
      inn
      onPlatform
      account {
        id
        authorizations(filter: $authorizationsFilter) {
          id
          bankRequisites {
            bankName
            correspondentAccount
            bik
            settlementAccount
          }
          birthDate
          phoneNumber
          type
          email
          inn
          phoneNumber
          citizenship
          identityDocumentInfo {
            number
            documentType
            issueDate
            issuerCode
            issuerName
            series
            otherName
          }
          registrationAddress
          liveAddress
          birthPlace
          externalId
          role {
            ...Role_type
          }
          account {
            id
          }
          powerOfAttorney(specificSelection: ONLY_ACTIVE) {
            id
            number
            title
            grantedAt
            permissions {
              code
              usingOnAttorney
            }
          }
          user {
            ...User_type
          }
        }
      }
    }
    RETAILER: suppliers(filter: $filter2) {
      id
      name
      inn
      onPlatform
      account {
        id
        authorizations(filter: $authorizationsFilter) {
          id
          externalId
          bankRequisites {
            bankName
            correspondentAccount
            bik
            settlementAccount
          }
          birthDate
          phoneNumber
          type
          email
          inn
          phoneNumber
          citizenship
          identityDocumentInfo {
            number
            documentType
            issueDate
            issuerCode
            issuerName
            series
            otherName
          }
          registrationAddress
          liveAddress
          birthPlace
          role {
            ...Role_type
          }
          account {
            id
          }
          powerOfAttorney(specificSelection: ONLY_ACTIVE) {
            id
            number
            title
            grantedAt
            permissions {
              code
              usingOnAttorney
            }
          }
          user {
            ...User_type
          }
        }
      }
    }
  }
  ${userType}
  ${roleType}
`;

export const COMPANY_WITH_EMPLOYEES_BY_INN = gql`
  query COMPANY_WITH_EMPLOYEES_BY_INN(
    $inn: String
    $authorizationsFilter: UserAuthorizationForAccountFilter!
  ) {
    companyProfile(inn: $inn) {
      id
      name
      inn
      account {
        id
        authorizations(filter: $authorizationsFilter) {
          id
          role {
            ...Role_type
          }
          account {
            id
          }
          powerOfAttorney(specificSelection: ONLY_ACTIVE) {
            id
            number
            title
            grantedAt
            permissions {
              code
              usingOnAttorney
            }
          }
          user {
            ...User_type
          }
        }
      }
    }
  }
  ${userType}
  ${roleType}
`;

// все комнаты конкретного пользователя
export const GET_ALL_ROOMS_FOR_USER = gql`
  query GET_AlL_ROOMS_FOR_USER {
    roomsForUserId {
      id
      title
      kind
    }
  }
`;

// получение комнаты по id
export const GET_ROOM = gql`
  query GET_ROOM($roomId: String!) {
    room(roomId: $roomId) {
      ... on RoomOperationSuccess {
        room {
          id
          title
        }
      }
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

// список сообщений в комнате
export const GET_MESSAGES = gql`
  query GET_MESSAGES($roomId: String!) {
    messages(roomId: $roomId) {
      # id сообщения
      id
      # id отправителя
      senderId
      # id комнаты
      roomId
      # текст сообщения пользователя
      content
      # время отправки сообщения
      creationTime
      attachments {
        id
        fileName
        fileType
        fileSize
      }
    }
  }
`;

export const METER = gql`
  query METER($id: String!, $revision: Int) {
    meter(id: $id, revision: $revision) {
      contractDocumentId
      accountId
      serialNumber
      accuracyClass
      ratedCurrent
      nominalVoltage
      calibrationDate
      nextCalibrationDate
      calibrationInterval
      externalId
      qualityIndicators
      readingsTransmitted
      sealStatus
      ownerCompany
      productionYear
      location
      address
      type
      microgeneratorStatus
      replacementStatus
      history {
        id
        updatedAt
        revisionId
        updatedByDocumentId
        updatedByDocument {
          id
          hash
          title
        }
      }
      usagePoint {
        id
        pointInfo {
          mainSubstation
          switchGear
          feeder
          distributionSubstation
          line
          transformationSubstation
          other
          mainSubstationNumber
          borderName
          connectionName
          feederCell
        }
        delimitationActNumber
        delimitationActDate
        supplier {
          name
          inn
          kpp
          ogrn
        }
        meterPlace
        meter {
          id
          type
          contractDocumentId
          serialNumber
          accuracyClass
          ratedCurrent
          nominalVoltage
          calibrationDate
          nextCalibrationDate
          calibrationInterval
        }
        currentTransformer {
          type
          transformationRatio
          accuracyClass
          calibrationDate
          nextCalibrationDate
          calibrationInterval
        }
        potentialTransformer {
          type
          transformationRatio
          accuracyClass
          calibrationDate
          nextCalibrationDate
          calibrationInterval
        }
        consumer {
          consumerName
          objectType
          reliabilityClass
        }
        history {
          id
          revisionId
          updatedAt
          updatedByDocumentId
          updatedByDocument {
            id
            hash
            title
          }
        }
        accountingRatio
        pointKind
        variableLoss
        constLoss
        voltageLevel
        pointStatus
        metersOwner
        maxPower
        measurementsReadingKind
        objectAddress
        objectType
        sourceContractNumber
        sourceContractDate
        isDuplicate
        tariffCategory
        additionKind
      }
    }
  }`;

export const ALL_METER = gql`
  query ALL_METER {
    allMeters {
      id
      contractDocumentId
      accountId
      serialNumber
      accuracyClass
      ratedCurrent
      nominalVoltage
      calibrationDate
      nextCalibrationDate
      calibrationInterval
      externalId
      qualityIndicators
      readingsTransmitted
      sealStatus
      ownerCompany
      productionYear
      location
      address
      type
      microgeneratorStatus
    }
  }`;

export const ALL_METERS_BY_FILTER_PAGED = gql`
  query ALL_METERS_BY_FILTER_PAGED($filter: MeterFilter!, $pageIn: PageIn!) {
    allMetersByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        contractDocumentId
        accountId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
        externalId
        qualityIndicators
        readingsTransmitted
        sealStatus
        ownerCompany
        productionYear
        location
        address
        type
        microgeneratorStatus
        replacementStatus
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const GET_TRANSMISSION_CONTRACT_BY_ID = gql`
  query GET_TRANSMISSION_CONTRACT_BY_ID($id: String!){
    transmissionContract(id: $id) {
      sourceContractId
      createdAt
      title
      id
      contractType
      contractInfo {
        number
        region
        date
        effectiveDate
        expirationDate
        prolongation
      }
      documentId
      additionalContracts {
        id
        sourceContractId
        contractInfo {
          number
          date
        }
        documentHistory {
          documentHash
          domainDocumentId
          datetime
          action
          user {
            ...User_type
          }
          account {
            id
            profile {
              ...Profile_type
            }
          }
          role {
            ...Role_type
          }
        }
      }
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sides {
        status
        name
        inn
        approvalListId
        accountId
        userId
        isSimpleSignature
        sideName
        companyInfo {
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          correlationId
          inn
          kpp
          legalAddress
          postAddress
          name
          ogrn
          okpo
          oktmo
          okved
          postAddress
        }
        kpp
        ogrn
        oktmo
        okpo
        okved
        address
        postAddress
        bankRequisites {
          bik
          settlementAccount
          bankName
          correspondentAccount
        }
      }
      powerOfAttorney {
        id
        documentId
        expiresAt
        startsFrom
      }
      xmlDocumentType
      documentHash
      initiatorAccountId
      initiatorUserId
      documentHistory {
        documentHash
        domainDocumentId
        datetime
        action
        user {
          ...User_type
        }
        account {
          id
          profile {
            ...Profile_type
          }
        }
        role {
          ...Role_type
        }
      }
      payments {
        id
        day
        from {
          name
        }
        to {
          name
        }
        paymentType
        tariffType
        withoutDate
        percent
      }
    }
  }
  ${userType}
  ${profileType}
  ${roleType}
`;
export const GET_PROHIBITED_PASSWORD_PAGE = gql`
  query GET_PROHIBITED_PASSWORD_PAGE($filter: ProhibitedPasswordFilter!, $sortedBy: ProhibitedPasswordSorting!, $pageIn: PageIn!) {
    prohibitedPasswordsByFilterPagedSorted(filter: $filter, sortedBy: $sortedBy, pageIn: $pageIn) {
      list {
        id
        value
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;
export const GET_GUIDE_LIST = gql`
  query GET_GUIDE_LIST($filter: DirectoryFilter!, $pageIn: PageIn!) {
    directories(filter: $filter, pageIn: $pageIn, sortedBy: {direction: ASC, field: NAME}) {
      list {
        id
        name
        description
        createdAt
        updatedAt
        kind
        fields {
          id
          directoryId
          name
          order
          type
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const DIRECTORY_RECORDS = gql`
  query DIRECTORY_RECORDS($id: String!, $filters: [DirectoryRecordFilter!], $sortedBy: DirectoryRecordSorting!, $pageIn: PageIn!) {
    directory(id: $id){
      id
      createdAt
      updatedAt
      kind
      name
      description
      fields {
        id
        directoryId
        order
        type
        name
      }
      directoryRecords(filters: $filters, pageIn: $pageIn, sortedBy: $sortedBy) {
        list {
          id
          directoryId
          fields
        }
        pageOut {
          totalPages
          currentPage
          pageSize
          totalElements
        }
      }
    }
  }`;

export const GET_BORDER_DEVICE_ACTS_FOR_ACCOUNTING = gql`
  query GET_BORDER_DEVICE_ACTS_FOR_ACCOUNTING($accountingPeriod: AccountingPeriodInput) {
    borderDeviceActsForAccounting(accountingPeriod: $accountingPeriod) {
      createdAt
      borderId
      title
      id
      accountingPeriod {
        year
        month
      }
      documentId
      actVerification {
        documentId
        id
        verifyingDocumentId
      }
      status
      rejectedReason
      updatedAt
      sideSecond {
        status
        name
        userId
        inn
        approvalListId
        accountId
        departmentId
        companyInfo {
          correlationId
          name
          inn
          kpp
          ogrn
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          oktmo
          okpo
          okved
          legalAddress
          postAddress
        }
      }
      sideThird {
        status
        userId
        name
        inn
        approvalListId
        accountId
        departmentId
        companyInfo {
          correlationId
          name
          inn
          kpp
          ogrn
          bankRequisites {
            bik
            settlementAccount
            bankName
            correspondentAccount
          }
          oktmo
          okpo
          okved
          legalAddress
          postAddress
        }
      }
      inputConsumption
      outputConsumption
      saldoConsumption
      selfNeedsConsumption
      transitConsumption
      kind
      borderDeviceActId
      balanceBoundaries {
        id
        consumers
        connections {
          distributionSubstation
          mainSubstation {
            number
            fullName
          }
        }
        firstCompany {
          id
          name
        }
        secondCompany {
          id
          name
        }
      }
    }
  }
`;

export const ALL_USAGE_POINTS_MEASUREMENTS_BY_FILTER_PAGED = gql`
  query ALL_USAGE_POINTS_MEASUREMENTS_BY_FILTER_PAGED($filter: UsagePointFilter!, $pageIn: PageIn!, $accountingPeriod: AccountingPeriodInput!) {
    allUsagePointsByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        measurementsReadingKind
        pointKind
        additionKind
        meter {
          serialNumber
        }
        measurements(accountingPeriod: $accountingPeriod) {
          usagePointId
          currentValue
          previousValue
          totalConsumption
          additionalVolume
          accountingPeriod {
            year
            month
          }
          accountingMethod
          measurementsDifference
          accountingRatio
          variableLoss
          constLoss
          currentTariffsMeasurements {
            readingT1
            readingT2
            readingT3
          }
          previousTariffsMeasurements {
            readingT1
            readingT2
            readingT3
          }
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const GET_SUPPLIERS_BY_FILTER = gql`
  query GET_SUPPLIERS($filterUtility: SupplierFilter!, $filterRetailer: SupplierFilter!, $filterOdk: SupplierFilter!) {
    UTILITY: suppliers(filter: $filterUtility) {
      account {
        id
        profile {
          ...Profile_type
        }
      }
      name
      shortName
      inn
      kpp
      ogrn
      address
      phoneNumber
      email
      website
      regions
      onPlatform
      bankRequisites {
        bik
        settlementAccount
        bankName
        correspondentAccount
      }
      oktmo
      okpo
      okved
      additionalOkveds
      postAddress
      ... on UtilitySupplier {
        ogrn
        bankRequisitesUpdatedAt
        normativeLoss {
          value
          percentValue
        }
      }
    }
    RETAILER: suppliers(filter: $filterRetailer) {
      account {
        id
      }
      name
      shortName
      inn
      kpp
      ogrn
      address
      phoneNumber
      email
      website
      regions
      onPlatform
      bankRequisites {
        bik
        settlementAccount
        bankName
        correspondentAccount
      }
      oktmo
      okpo
      okved
      additionalOkveds
      postAddress
      ... on RetailSupplier {
        ogrn
        bankRequisitesUpdatedAt
      }
    }
    ODK: suppliers(filter: $filterOdk) {
      account {
        id
      }
      name
      shortName
      inn
      kpp
      ogrn
      address
      phoneNumber
      email
      website
      regions
      onPlatform
      bankRequisites {
        bik
        settlementAccount
        bankName
        correspondentAccount
      }
      oktmo
      okpo
      okved
      additionalOkveds
      postAddress
      ... on RetailSupplier {
        ogrn
        bankRequisitesUpdatedAt
      }
    }
  }
  ${profileType}
`;

export const GET_UTILITY_FOR_SELECTOR = gql`
  query GET_SUPPLIERS_FOR_SELECTOR {
    suppliers(filter: {kind: UTILITY}) {
      account {
        id
      }
      name
      __typename
    }
  }
`;

export const GET_SUPPLIER_FOR_SELECTOR_BY_FILTER = gql`
  query GET_SUPPLIER_FOR_SELECTOR_BY_FILTER($filter: SupplierFilter!) {
    suppliers(filter: $filter) {
      id
      externalId
      name
      inn
      __typename
    }
  }
`;

export const GET_RETAILER_FOR_SELECTOR = gql`
  query GET_SUPPLIERS_FOR_SELECTOR {
    suppliers(filter: {kind: RETAILER}) {
      account {
        id
      }
      name
      __typename
    }
  }
`;

export const DRAFTS_BY_FILTER = gql`
  query DRAFTS_BY_FILTER($draftFilter: DraftFilter){
    draftsByFilter(draftFilter: $draftFilter) {
      id
      documentType
      createdAt
      updatedAt
      title
    }
  }
`;

export const DRAFT_BY_ID = gql`
  query DRAFT_BY_ID($id: String){
    draft(id: $id) {
      id
      xml
      accountId
      userId
      documentType
      createdAt
      updatedAt
    }
  }
`;

export const TELEGRAM_BOT_INFO = gql`
  query TELEGRAM_BOT_INFO{
    telegramBotInfo {
      account
      url
    }
  }
`;

export const USER_PROFILE_DELETE_APPLICATIONS = gql`
  query USER_PROFILE_DELETE_APPLICATIONS {
    userProfileDeleteApplications {
      id
      number
      documentId
      agreedDecisionDocumentId
      rejectedDecisionDocumentId
      status
      agreedTime
      createdAt
    }
  }
`;

export const MAIN_SUBSTATIONS = gql`
  query MAIN_SUBSTATIONS {
    substations(filter: {kind: MAIN_SUBSTATION}) {
      number
      fullName
    }
  }`;

export const MAIN_SUBSTATIONS_FOR_SELECTOR = gql`
  query MAIN_SUBSTATIONS_FOR_SELECTOR {
    substations(filter: {kind: MAIN_SUBSTATION}) {
      number
      fullName
      lines {
        name
        secondSubstation {
          number
          fullName
          lines {
            name
            secondSubstation {
              number
              fullName
            }
          }
        }
      }
    }
  }`;

export const REVOKED_CERTIFICATES = gql`
  query REVOKED_CERTIFICATES {
    certificates {
      id
      name
      link
      updatedAt
    }
  }
`;

export const ALL_ACCOUNTS = gql`
  query allAccounts {
    allAccounts {
      id
      profile {
        ...on CompanyProfile {
          id
          inn
          name
        }
        ...on IndividualEnterpreneurProfile {
          id
          inn
          name
        }
      }
      authorizations {
        externalId
        role {
          isCompanyOwner
        }
        user {
          id
          email
          phoneNumber
          snils
        }
      }
    }
  }
`;

export const ACCOUNTS_BY_FILTER = gql`
  query accountsByFilter($filter: AccountFilter) {
    accountsByFilter(filter: $filter) {
      id
      profile {
        ...on CompanyProfile {
          id
          inn
          name
          kpp
          ogrn
        }
        ...on PlatformWorkerProfile {
          id
          name
        }
        ...on IndividualEnterpreneurProfile {
          id
          inn
          name
        }
        ...on IndividualProfile {
          id
          fullName {
            name
            surname
            patronymic
          }
          snils
        }
      }
      authorizations {
        externalId
        role {
          isCompanyOwner
        }
        user {
          id
          email
          phoneNumber
          snils
          fullName {
            name
            surname
            patronymic
          }
        }
      }
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers {
    allUsers {
      id
      email
      phoneNumber
      snils
      authorizations {
        externalId
        phoneNumber
        email
        account {
          profile {
            ...on CompanyProfile {
              name
            }
            ...on IndividualEnterpreneurProfile {
              name
            }
            ...on IndividualProfile {
              snils
            }
            ...on PlatformWorkerProfile {
              id
            }
          }
        }
      }
    }
  }
`;

export const POWER_PROFILES_BY_METER_ID = gql`
  query powerProfilesByMeterId($meterId: String) {
    powerProfilesByMeterId(meterId: $meterId) {
      document {
        title
        documentId
        xmlDocumentType
      }
    }
  }
`;
export const SUBDIVISIONS_BY_FILTER = gql`
  query subdivisionByFilter($filter: SubdivisionsFilter){
    subdivisions(filter: $filter) {
      id
      name
      shift
      roles {
        name
        authorizations {
          id
          externalId
        }
      }
    }
  }`;

export const SUPPLIER_SUBDIVISIONS_BY_FILTER = gql`
  query supplierSubdivisionsByFilter($supplierId: String!, $filter: SubdivisionsFilter){
    supplierSubdivisions(supplierId: $supplierId, filter: $filter) {
      id
      name
      shift
      roles {
        name
        authorizations {
          id
          externalId
        }
      }
    }
  }`;

export const SUBORDINATED_SUBDIVISIONS = gql`
  query SUBORDINATED_SUBDIVISIONS {
    authorization {
      id
      role {
        id
        subordinatedSubdivisionLinear {
          id
          name
          shift
          roles {
            name
            authorizations {
              id
              externalId
            }
          }
        }
      }
    }
  }`;

export const SUBDIVISION_WITH_FILTERED_ROLES_BY_ID = gql`
  query subdivisionWithFilteredRolesById($id: String!, $filter: RolesFilter){
    subdivision(id: $id) {
      id
      name
      roles(filter: $filter) {
        isSubdivisionOwner
        name
        authorizations {
          id
          externalId
          user {
            id
            fullName {
              name
              surname
              patronymic
            }
          }
          account {
            id
          }
        }
      }
    }
  }`;

export const SUPPLIERS_BY_FILTER = gql`
  query suppliersByFilter($filter: SupplierFilter!){
    suppliers(filter: $filter) {
      id
      name
      inn
    }
  }`;

export const GET_ALL_ISU = gql`
  query allIsu {
    allIsu {
      id
      name
      isuProtocol {
        id
        name
      }
    }
  }
`;

export const DOCUMENTS_BY_FILTER_PAGED = gql`
  query documentsByFilterPaged($filter: DomainDocumentFilter!, $pageIn: PageIn!) {
    documentsByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        documentId
        title
        createdAt
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const ALL_NEW_USAGE_POINTS = gql`
  query allNewUsagePoints($inn: String!) {
    allNewUsagePoints(inn: $inn) {
      id
      externalId
      additionKind
      pointInfo {
        mainSubstation
        switchGear
        feeder
        distributionSubstation
        line
        transformationSubstation
        other
        mainSubstationNumber
        borderName
        connectionName
        feederCell
      }
      delimitationActNumber
      delimitationActDate
      supplier {
        name
        inn
        kpp
        ogrn
      }
      meterPlace
      meter {
        id
        contractDocumentId
        serialNumber
        accuracyClass
        ratedCurrent
        nominalVoltage
        calibrationDate
        nextCalibrationDate
        calibrationInterval
      }
      currentTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      potentialTransformer {
        type
        transformationRatio
        accuracyClass
        calibrationDate
        nextCalibrationDate
      }
      history {
        id
        revisionId
        updatedAt
        updatedByDocumentId
        updatedByDocument {
          id
          hash
          title
        }
      }
      accountingRatio
      pointKind
      pointStatus
      metersOwner
      maxPower
      measurementsReadingKind
      sourceContractNumber
      sourceContractDate
      isDuplicate
      tariffCategory
    }
  }`;

export const TARIFF_RATE_FOR_ELECTRICITY_TRANSMISSION_MAINTENANCE = gql`
  query TARIFF_RATE_FOR_ELECTRICITY_TRANSMISSION_MAINTENANCE($pageIn: PageIn!, $filter: TariffRateMaintenanceFilter!) {
    tariffRateForElectricityTransmissionMaintenancePaged(pageIn: $pageIn, filter: $filter) {
      list {
        id
        startDate
        endDate
        tariffRateForNonListedRegions
        tariffRateForDifferentiatedRegions
        tariffsOrder {
          documentId
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const TARIFF_PEAK_LOAD_HOURS_PAGED = gql`
  query TARIFF_PEAK_LOAD_HOURS_PAGED($pageIn: PageIn!, $filter: PeakLoadFilter!){
    tariffPeakLoadHoursPaged(pageIn: $pageIn, filter: $filter) {
      list {
        id
        accountingPeriod {
          month
          year
        }
        interval {
          end
          start
        }
        tariffsOrder {
          title
          id
          documentId
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const EXPENSES_PAYMENT_STANDARD_LOSSES = gql`
  query EXPENSES_PAYMENT_STANDARD_LOSSES($pageIn: PageIn!, $filter: TariffRateExpensesFilter!) {
    tariffRateForElectricityTransmissionExpensesPaged(pageIn: $pageIn, filter: $filter) {
      list {
        id
        accountingPeriod {
          year
          month
        }
        electricityCostForTransmissionTariffCalculation
        electricityLossVolumeInNationalGrid
        tariffRateForNationalGridLosses
        atsEnergo
        tariffsOrder {
          documentId
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const UNIFIED_ENERGY_TARIFFS = gql`
  query UNIFIED_ENERGY_TARIFFS($pageIn: PageIn!, $filter: UnifiedEnergyTariffsFilter!) {
    unifiedEnergyTariffsPaged(pageIn: $pageIn, filter: $filter) {
      list {
        id
        year
        companyInn,
        companyName,
        utilityNVVExcludingLossPayment
        incurredExpensesForTechnicalConnection
        electricityLosses
        tariffsOrder {
          documentId
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const TARIFF_LOSS_NORMATIVES = gql`
  query TARIFF_LOSS_NORMATIVES($pageIn: PageIn!, $filter: TariffOrdersFilter) {
    tariffLossNormativesPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        lossVolume220
        volume220
        lossVolume330
        volume330
        tariffsOrder {
          documentId
          year
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const TARIFF_INFRASTRUCTURE_PAYMENTS = gql`
  query TARIFF_INFRASTRUCTURE_PAYMENTS($filter: TariffOrdersFilter, $pageIn: PageIn!){
    tariffInfrastructurePaymentsPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        tariffsOrder {
          id
          orderName
          kind
          documentId
          year
        }
        complexServicesTariffRate
        commercialServicesTariffRate
        operationalManagementTariffRate
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const UTILITY_LOSS_TARIFFS = gql`
  query UTILITY_LOSS_TARIFFS($filter: UtilityLossTariffsFilter, $pageIn: PageIn!){
    utilityLossTariffsPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        tariffsOrder {
          id
          documentId
          orderName
          year
        }
        lossTariff
        extraLossTariff
        averageElectricityPart
        retailerPart
        infrastructurePart
        extraAverageElectricityPart
        extraRetailerPart
        extraInfrastructurePart
        year
        month
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }
`;

export const ELECTRICITY_BOILER_TARIFFS = gql`
  query ELECTRICITY_BOILER_TARIFFS($filter: ElectricityBoilerTariffFilter, $pageIn: PageIn!) {
    allElectricityBoilerTariffPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        singleRateElectricityTariff
        rateForTheMaintenanceOfElectricalNetworks
        rateForPaymentOfTechnologicalLosses
        voltageLevel
        boilerConsumerType {
          id
          description
        }
        startDate
        endDate
        publicationDate
        createdAt
        tariffsOrder {
          documentId
        }
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const ELECTRICITY_BOILER_TARIFFS_CATEGORY = gql`
  query ELECTRICITY_BOILER_TARIFFS_CATEGORY($filter: BoilerConsumerTypeFilter!, $pageIn: PageIn!) {
    boilerConsumerTypePaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        description
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const TARIFFS_ORDER_BY_FILTER = gql`
  query TARIFFS_ORDER_BY_FILTER($filter: TariffOrdersFilter, $pageIn: PageIn!) {
    allTariffsOrdersByFilterPaged(filter: $filter, pageIn: $pageIn) {
      list {
        id
        orderName
        kind
        documentId
        docDate
        title
      }
      pageOut {
        totalPages
        currentPage
        pageSize
        totalElements
      }
    }
  }`;

export const REPORTS = gql`
  query REPORTS {
    reports {
      id
      title
      createdAt
    }
  }`;

export const MAIN_SUBSTATIONS_FROM_NUMBERS = gql`
  query MAIN_SUBSTATIONS_FROM_NUMBERS($numbers: [String!]!) {
    existingSubstations(numbers: $numbers) {
      number
    }
  }`;
