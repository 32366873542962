<template>
  <simple-autocomplete-by-function
    ref="autocomplete"
    v-model="selectedItem"
    :dependency="dependency"
    :fetch-data-function="getFilteredEmployees"
    :fetch-default-data-function="getFilteredEmployees"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :items.sync="internalItems"
    :search.sync="searchItem"
    :selected="selected"
    :title="title"
  />
</template>

<script>

import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { mapActions, mapGetters } from 'vuex';
import { fullNameToString } from 'utils/user';

export default {
  components: { SimpleAutocompleteByFunction },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    selected: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    setCurrentByDefault: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      default: null,
      required: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    let data;
    try {
      data = await this.getAccountEmployees();
    } catch (e) {
      console.error('Failed to fetch employees');
      data = [];
    }
    this.employees = data.authorizations.map((it) => ({
      id: it.user.id,
      name: this.authorizationToName(it),
    }));
    if (this.setCurrentByDefault) {
      this.internalItems = [{
        id: this.getAuthorization.authorization.user.id,
        name: this.authorizationToName(this.getAuthorization.authorization),
      }];
      this.searchItem = this.authorizationToName(this.getAuthorization.authorization);
      this.selectedItem = this.getAuthorization.authorization.user.id;
    }
  },
  data() {
    return {
      internalItems: [],
      employees: [],
    };
  },
  emits: ['update:search', 'change', 'update:items'],
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    ...mapGetters('user', ['getUser', 'getAuthorization']),
  },
  watch: {
    internalItems() {
      this.$emit('update:items', this.internalItems);
    },
    items: {
      immediate: true,
      handler() {
        this.internalItems = this.items;
      },
    },
  },

  methods: {

    ...mapActions('user', ['getAccountEmployees']),
    authorizationToName(authorization) {
      return `${authorization.role.name} ${fullNameToString(authorization.user.fullName)}`;
    },
    async getFilteredEmployees(name) {
      if (!name) return this.employees.slice(0, 20);
      return this.employees.filter((it) => it.name.toUpperCase().includes(name.toUpperCase())).slice(0, 20);
    },

    resetValidation() {
      this.$refs.autocomplete.resetValidation();
    },
  },
};
</script>
