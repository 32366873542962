import { render, staticRenderFns } from "./ConsumerInfo.vue?vue&type=template&id=a840cb24&scoped=true"
import script from "./ConsumerInfo.vue?vue&type=script&lang=js"
export * from "./ConsumerInfo.vue?vue&type=script&lang=js"
import style0 from "./ConsumerInfo.vue?vue&type=style&index=0&id=a840cb24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a840cb24",
  null
  
)

export default component.exports