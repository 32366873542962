<template>
  <div class="form-header">
    <div class="form-header__title-wrp">
      <h1
        v-if="title !== ''"
        class="form-header__title">
        {{ title }}
      </h1>

      <div
        v-if="type !== ''"
        class="form-header__title-type"
      >
        {{ type }}
      </div>
    </div>

    <div class="form-header__columns">
      <div
        v-if="column1.length !== 0"
        class="column"
      >
        <span v-for="(columnItem, i) in column1"
          :key="i"
          class="column__item"
        >
          <span class="column__item-label">{{ columnItem.label }}</span>&nbsp;
          <span class="column__item-value">{{ columnItem.value }}</span>
        </span>
      </div>
      <div
        v-if="column2.length !== 0"
        class="column"
      >
        <span v-for="(columnItem, i) in column2"
          :key="i"
          class="column__item"
        >
          <span class="column__item-label">{{ columnItem.label }}</span>&nbsp;
          <span class="column__item-value">{{ columnItem.value }}</span>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    column1: {
      type: Array,
      default: () => [],
    },
    column2: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.form-header {
  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;

    max-width: 475px;

    &-wrp {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &-type {
      display: flex;
      align-self: center;
      padding: 8px 25px;
      border: 1px solid #C1C4C7;
      border-radius: 16px;

      color: #263238;
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;

    .column {
      width: 50%;
      display: flex;
      flex-direction: column;

      &__item {
        margin-bottom: 20px;
        font-size: 16px;

        &-value {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
