import axios from 'axios';
import store from 'store';
import actualEnvContentPatch from '@/utils/env';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


async function getDocumentStatusByUrl(url) {
  const token = store.getters['user/getToken'];

  const doc = await axios.request({
    url: url, method: 'POST', responseType: 'json', headers: {
      ['X-Authorization']: `Bearer ${token}`,
    },
  });

  return doc.data;
}

async function getDocumentByUrl(url) {
  const token = store.getters['user/getToken'];

  const doc = await axios.request({
    url: url, method: 'get', responseType: 'blob', headers: {
      ['X-Authorization']: `Bearer ${token}`,
    },
  });

  return doc.data;
}

async function getDocumentStatus(documentId) {
  let result;
  try {
    result = await getDocumentStatusByUrl(
      `${actualEnvContentPatch}/v1/document/${documentId}/submit/pdf`,
    );
  } catch (e) {
    console.log('getDocumentStatus::error', e.message);
  }
  return result;
}

async function pollDocument(documentId, waitingFunction) {
  let status = await getDocumentStatus(documentId);
  if (status === 'WAITING' && waitingFunction) {
    waitingFunction();
  }
  while (status === 'WAITING') {
    await sleep(1000);
    status = await getDocumentStatus(documentId);
  }
  if (status === 'COMPLETE') {
    try {
      return await getDocumentByUrl(
        `${actualEnvContentPatch}/v1/document/${documentId}/download`,
      );
    } catch (e) {
      console.log('getDocumentByUrl::error', e.message);
    }
  }
}


export async function getDocument(documentId, waitingFunction) {
  return new Promise(((resolve, reject) => {
    pollDocument(documentId, waitingFunction).then((document) => {
      if (!document) {
        reject(new Error('Ошибка загрузки документа'));
      }
      resolve(document);
    }).catch(() => {
      reject(new Error('Ошибка загрузки документа'));
    });
  }));
}
