<template>
  <page title="Шаблоны документов">
    <action-block style="margin-bottom: 24px" />
    <data-tab-selector
      :data-types-list="tabs"
      style="margin-bottom: 24px"
      tab-style="table"
      @selectTab="selectTabHandler"
    />
    <contract-templates v-if="selectedTab === 'contracts'" class="document-templates__block" />
  </page>
</template>

<script>
import ActionBlock from './ActionBlock';
import ContractTemplates from './ContractTemplates';
import { DataTabSelector, Page } from '@/components';

export default {
  name: 'CabinetContracts',
  components: {
    ContractTemplates,
    ActionBlock,
    DataTabSelector,
    Page,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'Шаблоны договоров',
          value: 'contracts',
          isActive: true,
        },
        {
          id: 2,
          title: 'Шаблоны балансов',
          value: 'balances',
          isActive: false,
        },
        {
          id: 3,
          title: 'Шаблоны актов',
          value: 'acts',
          isActive: false,
        },
      ],
      selectedTab: 'contracts',
    };
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
