<template>
  <th
    v-if="header.type!=='hide'"
    :colspan="header.colspan"
    :style="header.thStyle"
    class="table__header"
  >
    <div
      :style="labelStyle"
      :class="{['table__label']: true, ['table__label--not-index']: header.type !== 'index'}"
      @mouseout="$emit('labelMouseout', header)"
      @mouseover="(event) => $emit('labelMouseover', header, event, isMatchMinScreenWidthTooltip)"
    >
      <div v-if="header.type !== 'filterAndCheckbox'">
        <img v-if="header.sort && sortState && sortState.key === header.key && sortState.direction === 1"
             alt="сортировать" class="table__sort-icon table__sort-icon--active table__sort-icon--direction"
             src="@/assets/icons/basic/sort-direction.svg" @click="callSortColumn"/>

        <img v-else-if="header.sort && sortState && sortState.key === header.key && sortState.direction === -1"
             alt="сортировать" class="table__sort-icon table__sort-icon--active"
             src="@/assets/icons/basic/sort-active.svg"
             @click="callSortColumn"/>

        <img v-else-if="header.sort" alt="сортировать" class="table__sort-icon"
             src="@/assets/icons/basic/sort.svg" @click="callSortColumn"/>
      </div>
      <v-table-th-added-button v-if="header.type === 'actionButton'" @click="clickByThHandler"/>
      <v-table-th-filter-and-checkbox
        v-else-if="header.type === 'filterAndCheckbox'"
        :header="header"
        :select-all-is-true="selectAllIsTrue"
        :sort-state="sortState"
        @filter="(val) => $emit('filter', val)"
        @sort="callSortColumn"
        @select-all="(val) => $emit('select-all', val)"
      />
      <span v-else-if="header.label" :style="headerSpanStyle(header.label)" @click="callSortColumn">
        <span v-if="header.tooltip">
          {{ truncatedLabel }}
        </span>
        <span v-else-if="isUnconditionalWidthForTooltip">
          {{ defaultTruncatedLabel }}
        </span>
        <span v-else>
          {{ header.label }}
        </span>
      </span>

    </div>
  </th>
</template>

<script>
import VTableThAddedButton from './VTableThAddedButton/VTableThAddedButton.vue';
import { truncateString } from '@/utils/string/truncate';
import VTableThFilterAndCheckbox from './VTableThFilterAndCheckbox/VTableThFilterAndCheckbox.vue';

export default {
  name: 'VTableTh',
  components: {
    VTableThFilterAndCheckbox,
    VTableThAddedButton,
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    sortState: {
      type: Object,
      required: false,
    },
    windowInnerWidth: {
      type: Number,
      required: true,
    },
    selectAllIsTrue: {
      type: Boolean,
      required: false,
    },
    widthForTruncated: {
      type: Number,
      default: 700,
    },
  },
  data() {
    return {
      isHeaderTooltipShow: false,
      labelStyle: {},
    };
  },
  computed: {
    headerTooltipPosition() {
      if (this.header?.tooltip?.position) return this.header.tooltip.position;
      return 'bottom';
    },
    truncatedLabel() {
      if (this.isUnconditionalWidthForTooltip) {
        return this.defaultTruncatedLabel;
      }

      if (this.header?.tooltip?.maxLength && this.isMatchMinScreenWidthTooltip) {
        return truncateString(this.header.label, this.header.tooltip.maxLength);
      }
      return this.header.label;
    },
    defaultTruncatedLabel() {
      if (!this.header.label) {
        return '';
      }

      let index = this.header.label.indexOf(' ');

      if (index === -1) {
        return this.header.label;
      }

      if (index < 2) {
        const index2 = this.header.label.indexOf(' ', index + 1);

        if (index2 === -1) {
          return this.header.label;
        }

        index = index + index2 - 1;
      }

      return `${this.header.label.substr(0, index)}...`;
    },
    isMatchMinScreenWidthTooltip() {
      if (!this.header?.tooltip?.minSreenWidthTooltip) {
        this.changeLabelStyle({});
        return true;
      }

      if (this.windowInnerWidth < this.header.tooltip.minSreenWidthTooltip) {
        this.changeLabelStyle({ whiteSpace: 'nowrap' });
        return true;
      }
      this.changeLabelStyle({});
      return false;
    },
    isUnconditionalWidthForTooltip() {
      return this.windowInnerWidth < this.widthForTruncated;
    },
  },
  methods: {
    changeLabelStyle(styles) {
      this.labelStyle = { ...styles };
    },
    callSortColumn() {
      if (!this.header.sort) return;
      this.$emit('on-sort-column', this.header);
    },
    clickByThHandler() {
      this.$emit('on-th-click', this.header);
    },
    headerSpanStyle(text) {
      if (text.length > 5) {
        return 'minWidth: 40px';
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__header {
    text-align: left;
    color: #677379;
    border-bottom: 1px solid #cccccc;

    font-family: 'Roboto Condensed', sans-serif;
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  &__label {
    display: flex;
    align-items: start;
    gap: 4px;
    cursor: pointer;

    &--not-index {
      min-width: 4em;
    }
  }

  &__sort-icon {
    margin-top: 2px;
    cursor: pointer;

    &--direction {
      transform: scale(1, -1);
    }
  }
}
</style>
