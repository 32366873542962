<template>
  <div :class="$style.container">
    {{ transformValue }}
  </div>
</template>

<script>
export default {
  name: 'VTableCellRoleModal',
  props: {
    value: { type: String, required: true },
  },
  computed: {
    transformValue() {
      return this.value.length > 20
        ? `${this.value.slice(0, 17)}...`
        : `${this.value}`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: inline-block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #2F82DF;
  border-radius: 24px;
  padding: 4px 16px;
  cursor: pointer;
}
</style>
