export function generateDeleteBoundariesXml(borderId) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationInfoLists\n' +
    '        xmlns="http://energochain.ru/XML/documents/update-balance-boundary/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/update-balance-boundary/1.0 UpdateBalanceBoundary.xsd">\n' +
    '    <RevokeBalanceBoundary>\n' +
    `        <BalanceBoundaryId>${borderId}</BalanceBoundaryId>\n` +
    '    </RevokeBalanceBoundary>\n' +
    '</UpdateSubstationInfoLists>'
  );
}

function generateBoundaries(tsoInn, boundaries) {
  let connections = '';

  boundaries.forEach((border) => {
    let distributions = '';

    border.distributionSubstations?.forEach((distribution) => {
      if (distribution.value) {
        if (distributions.length === 0) {
          distributions = '                <DistributionSubstationList>\n';
        }
        distributions += `                    <DistributionSubstationName>${distribution.value}</DistributionSubstationName>\n`;
      }
    });

    if (distributions.length !== 0) {
      distributions += '                </DistributionSubstationList>\n';
    }

    connections += `${'            <Connection>\n' +
    `                <MainSubstationNumber>${border.mainSubstationNumber}</MainSubstationNumber>\n`}${
      distributions
    }            </Connection>\n`;
  });

  return `${`        <SecondCompanyInn>${tsoInn}</SecondCompanyInn>\n` +
    '        <Connections>\n'}${
      connections
    }        </Connections>\n`;
}

export function generateAddBalanceBoundaryXml(tsoInn, boundaries) {
  const boundariesXml = generateBoundaries(tsoInn, boundaries);

  return `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationInfoLists\n' +
    '        xmlns="http://energochain.ru/XML/documents/update-balance-boundary/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/update-balance-boundary/1.0 UpdateBalanceBoundary.xsd">\n' +
    '    <AddBalanceBoundary>\n'}${
    boundariesXml
    }    </AddBalanceBoundary>\n` +
    '</UpdateSubstationInfoLists>';
}

export function generateUpdateBalanceBoundaryXml(boundaryId, tsoInn, boundaries) {
  const boundariesXml = generateBoundaries(tsoInn, boundaries).replaceAll('\n', '\n    ');

  return `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationInfoLists\n' +
    '        xmlns="http://energochain.ru/XML/documents/update-balance-boundary/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/update-balance-boundary/1.0 UpdateBalanceBoundary.xsd">\n' +
    '    <UpdateBalanceBoundary>\n' +
    `        <ID>${boundaryId}</ID>\n` +
    '        <BalanceBoundary>\n    '}${
    boundariesXml
    }    </BalanceBoundary>\n` +
    '    </UpdateBalanceBoundary>\n' +
    '</UpdateSubstationInfoLists>';
}
