import axios from 'axios';

export const publicClient = axios.create({
  baseURL: '',
});

publicClient.interceptors.response.use(
  (response) => response,
  (error) => new Promise(() => {
 throw error;
}),
);
