import { apolloClient } from '@/api/graphql';
import { HISTORY, BALANCE_DOCUMENTS_CREATION_SETTINGS } from '@/api/graphql/queries';
import { UPDATE_BALANCE_DOCUMENTS_SETTINGS } from '@/api/graphql/mutations';

async function loadHistory({ rootState, commit }) {
  try {
    const {
      data: { history },
    } = await apolloClient.query({
      query: HISTORY, fetchPolicy: 'no-cache', context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadHistory', history);
    return history;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadHistory::catch', error);
    throw error;
  }
}

async function balanceDocumentsSettings({ rootState }) {
  try {
    const {
      data: { balanceDocumentsSettings },
    } = await apolloClient.query({
      query: BALANCE_DOCUMENTS_CREATION_SETTINGS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return balanceDocumentsSettings;
  } catch (error) {
    console.log('balanceDocumentsSettings::catch', error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function updateBalanceDocumentsSettings({ commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: UPDATE_BALANCE_DOCUMENTS_SETTINGS,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('updateBalanceDocumentsSettings::catch', error);
    throw error;
  }
}

export default {
  loadHistory,
  balanceDocumentsSettings,
  updateBalanceDocumentsSettings,
};
