<template>
  <common-measurements :addition-kind="additionKind" />
</template>

<script>
import CommonMeasurements  from './CommonMeasurements';

export default {
  name: 'TechnicalMeasurements',
  components: {
    CommonMeasurements,
  },
  props: {
    additionKind: {
      type: String,
      required: true,
    },
  },
};
</script>

