<template>
    <select-tariff
      :document-id="documentId"
      :account-id="accountId"
      :application-data="applicationData"
      :offer-document="offerDocument"
      :action-props="actionProps"
      :review-document="reviewDocument"
      is-technical-conditions
      @close="onClose"
    />
</template>

<script>

import SelectTariff from 'views/private/ConnectionApplication/Task/actions/SelectTariff';
export default {
  name: 'SendTechnicalConditionsAct',
  components: { SelectTariff },
  props: {
    documentId: String,
    accountId: String,
    actionProps: Object,
    reviewDocument: Object,
    offerDocument: Object,
    applicationData: Object,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" module/>
