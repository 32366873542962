<template>
  <div class="object-info">
    <h2>Объект энергоснабжения:</h2>

    <!--    <div class="object-info__object">-->
    <!--      <v-input-->
    <!--        v-model="displayObjectType"-->
    <!--        :disabled="!isEditing"-->
    <!--        class="object-info__item"-->
    <!--        clearable-->
    <!--        rules="required"-->
    <!--        title="Тип объекта *"-->
    <!--      />-->
    <!--    </div>-->

    <div class="object-info__object">
      <v-input
        v-model="internalValue.objectAddress"
        :disabled="!isEditing"
        class="object-info__object__address"
        clearable
        rules="required"
        title="Адрес объекта энергоснабжения (адрес поставки) *"
      />
    </div>

    <v-divider/>

  </div>
</template>

<script>
import { VDivider, VInput } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'Object',
  components: {
    VInput,
    VDivider,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        objectType: '',
        objectAddress: '',
        objectPostCode: '',
      },
      internalValue: {},
    };
  },
  computed: {
    displayObjectType() {
      return this.internalValue.objectType || 'Неизвестно';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.object-info {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
    margin-bottom: 0;
  }

  &__object {
    display: flex;
    gap: 24px;

    img {
      margin-top: 20px;
      cursor: pointer;
    }

    &__address {
      width: 100%;
    }
  }

  &__item {
    width: 266px;

    &--500 {
      width: 500px;
    }
  }
}
</style>
