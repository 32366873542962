<template>
  <div :class="$style.menu">
    <div :class="$style.filterGroup">
      <i :class="[$style.filter, $style.doc]" @click="$emit('show')"/>
      <p style="cursor: pointer; text-transform: uppercase" @click="$emit('show')">{{ type }}</p>
      <div :class="$style.select">
        <select v-model="selected" :class="$style.typeSelect" @change="sortDocuments">
          <option value="date">По дате</option>
          <option value="documentKind">По типу документа</option>
        </select>
      </div>
    </div>
    <i :class="[!$store.state.document.change ? $style.iconBurger : $style.iconCard]" @click="changeCard" />
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    type: String,
  },
  data() {
    return {
      selected: 'date',
      field: '',
    };
  },
  methods: {
    changeCard() {
      this.$emit('change');
    },
    sortDocuments() {
      this.$emit('sort', { field: this.selected, direction: '' });
    },
  },
};
</script>

<style lang="sass" module>
.menu
  display: flex
  justify-content: space-between
  padding: 16px 32px

  p
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 24px
    margin-right: 28px
    text-transform: uppercase

    &:last-child
      margin-right: 0

.filterGroup
  display: flex
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 24px

  p
    margin-right: 22px
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 24px

    &:last-child
      margin-right: 0

.select
  position: relative
  display: inline-block

  &:after
    content: url('~icons/basic/filter-date.svg')
    position: absolute
    left: 0
    top: -2px
    pointer-events: none
    box-sizing: border-box
    cursor: pointer

.typeSelect
  width: 100%
  padding: 0 28px
  text-transform: uppercase
  cursor: pointer
  outline: none
  appearance: none
  border: none

.iconBurger
  width: 16px
  height: 16px
  background-image: url('~icons/basic/burger.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer
  transform: translateY(5px)

.iconCard
  width: 16px
  height: 16px
  background-image: url('~icons/basic/card-menu.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer
  transform: translateY(5px)

.filter
  margin-right: 7px
  transform: translateY(5px)
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer

.doc
  width: 16px
  height: 16px
  background-image: url('~icons/basic/filter-document.svg')

.number
  width: 12px
  height: 14px
  background-image: url('~icons/basic/filter-number.svg')
</style>
