<template>
  <div v-if="isLoading" :class="$style.loader">
    <v-circle-loader />
  </div>
  <v-form v-else :action-on-error="saveContractDraft" @submit="next">
    <v-circle-loader v-show="isUploadingDraft" :class="$style.uploadingDraft" />
    <div :class="$style.container">
      <div
          ref="navigationBar"
          :style="{
            position: 'sticky',
            top: '-16px',
            backgroundColor: '#ffffff',
            paddingLeft: '16px',
            zIndex: '100',
            width: navBarWidth,
            marginLeft: '-16px',
            paddingTop: '16px',
            marginTop: '-16px',
            marginBottom: '-8px',
            paddingBottom: '8px',
          }"
      >
        <navigation-bar
          :current-step="currentStep"
          :home="$route.params.contractId ? 'Задачи' : 'Договоры...'"
          :steps="steps"
          @open="openStep"
        />
      </div>


      <div v-if="steps[currentStep]" ref="contractContent" :class="$style.content">
        <component
          :is="steps[currentStep].component"
          :key="componentKey"
          ref="details"
          :component-key="componentKey"
          :step-number="steps[currentStep].stepNumber"
          :height-navigation-bar="heightNavigationBar"
          :submit="submit"
          :custom-action="customAction"
          @close="close"
          @complete="complete"
          @next="next"
          @isWaitingSubmit="updateWaitingSubmit"
        />
      </div>

      <div
        :class="$style.contentFooter"
        :style="{ position: 'absolute', bottom: '0', width: footerWidth, }"
      >
        <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
          <chevron-left />
          <span>Назад</span>
        </v-button>
        <v-button :class="$style.delete" :disabled="!draftId" type="button" variant="outlined-red"
                  @click="displayDeleteDialog = true">
          <delete />
          <span>Удалить черновик</span>
        </v-button>
        <div :class="$style.center">&nbsp;</div>
        <v-button :class="$style.save" :disabled="!isUpdated" type="button" variant="outlined-colored"
                  @click="saveContractDraft">
          <save />
          <span>Сохранить черновик</span>
        </v-button>
        <v-button v-if="steps[currentStep].needEditable" :class="$style.save" type="button" variant="outlined-colored"
                  @click="changeEditMode">
          <img alt="" src="@/assets/icons/user/edit-member-without-round.svg">
          <div class="button__text">
            {{ isEditModeOn ? 'Завершить редактирование' : 'Редактировать' }}
          </div>
        </v-button>
        <v-button v-show="steps[currentStep]?.isDisplaySubmit" :class="$style.submit" :disabled="!canBeSubmittedByRequisites || isWaitingSubmit" type="button" @click="onSubmit">
          <span>Отправить</span>
        </v-button>
        <v-button v-show="displayNext" :class="$style.next" :disabled="!canBeSubmittedByRequisites || isWaitingSubmit" type="submit">
          <span>Далее</span>
          <arrow-right />
        </v-button>
      </div>

      <dialog-template v-model="displayDeleteDialog" center>
        <p :class="$style.deleteText">
          Удалить черновик?
        </p>
        <div :class="$style.submitBlock">
          <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteContractDraft" />
          <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
        </div>
      </dialog-template>
    </div>
  </v-form>
</template>

<script>
import { NavigationBar, VButton, VCircleLoader, VForm } from 'components';

import SelectType from 'views/private/UploadDocument/steps/SelectType.vue';
import SelectPoints from 'views/private/UploadDocument/steps/CreateOrUploadContractForm/SelectPoints.vue';
import ContractView from 'views/private/UploadDocument/steps/ContractView.vue';
import ContractViewPre from 'views/private/UploadDocument/steps/ContractViewPre.vue';
import UploadAdditions from 'views/private/UploadDocument/steps/UploadAdditions/UploadAdditions.vue';
import ElectricityTransmissionContract from 'views/private/UploadDocument/steps/ElectricityTransmissionContract.vue';
import SignatoriesStep from 'views/private/UploadDocument/steps/SignatoriesStep.vue';
import ContractApplicationCheck from 'views/private/UploadDocument/steps/ContractApplicationCheck/index.vue';

import Delete from 'atoms/icons/action/Delete.vue';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ArrowRight from 'atoms/icons/arrows/ArrowRight.vue';
import Save from 'atoms/icons/action/Save.vue';

import DialogTemplate from 'templates/DialogTemplate.vue';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';

import { scrollIntoView } from 'lib/utils/DOM';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { removeDraft } from 'services/draft';
import { check } from 'lib/utils/validation/additionValidation';

export default {
  name: 'Details',
  components: {
    Save,
    VCircleLoader,
    ChevronLeft,
    Delete,
    VButton,
    NavigationBar,
    SelectType,
    ContractView,
    ElectricityTransmissionContract,
    SignatoriesStep,
    ArrowRight,
    DialogTemplate,
    SubmitTaskDetails,
    VForm,
    UploadAdditions,
    ContractApplicationCheck,
    ContractViewPre,
    SelectPoints,
  },
  async created() {
    if (!this.steps[this.currentStep]) {
      this.currentStep = 0;
    }
    window.addEventListener('resize', this.setElementsWidth);
    this.isLoading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isUpdated) {
      next();
      return;
    }

    const answer = window.confirm('Вы хотите уйти? У вас есть несохранённые изменения!');
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  mounted() {
    this.setElementsWidth();
  },
  updated() {
    this.heightNavigationBar = this.$refs.navigationBar.clientHeight;
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      navBarWidth: '0',
      footerWidth: '0',
      isLoading: false,
      isUploadingDraft: false,
      stepsNew: [
        {
          component: 'SelectType',
          isComplete: false,
          stepNumber: {
            value: 1,
            from: 7,
            title: () => `Создание карточки ${this.contractType}`,
          },
        },
        {
          component: 'ElectricityTransmissionContract',
          isComplete: false,
          stepNumber: {
            value: 2,
            from: 7,
            title: () => `Параметры ${this.contractType}`,
          },
        },
        {
          component: 'ContractView',
          isComplete: false,
          needEditable: true,
          stepNumber: {
            value: 3,
            from: 7,
            title: () => `Типовая форма ${this.contractType}`,
          },
        },
        {
          component: 'UploadAdditions',
          isComplete: false,
          stepNumber: {
            value: 4,
            from: 7,
            title: () => 'Загрузка приложений',
          },
          validations: this.validationsUploadAdditions,
        },
        {
          component: 'ContractApplicationCheck',
          isComplete: false,
          stepNumber: {
            value: 5,
            from: 7,
            title: () => 'Проверка приложений',
          },
          validations: this.validationsApplication,
        },
        {
          component: 'ContractViewPre',
          isComplete: false,
          stepNumber: {
            value: 6,
            from: 7,
            title: () => 'Предварительный просмотр',
          },
        },
        {
          component: 'SignatoriesStep',
          stepNumber: {
            value: 7,
            from: 7,
            title: () => (this.getContract.action === 'create' ? 'Подписывающие лица' : 'Выбор лиц, подписывающих акт верификации'),
          },
          isComplete: false,
          isDisplaySubmit: true,
        },
      ],
      componentKey: 1,
      displayDeleteDialog: false,
      heightNavigationBar: 24,
      submit: false,
      customAction: false,
      isWaitingSubmit: false,
    };
  },
  computed: {
    canBeSubmittedByRequisites() {
      return this.getContract.action === 'upload' || this.getAuthorization.authorization.account.profile.bankRequisites.length > 0;
    },
    contractType() {
      if (this.getContract.type === 'contract') return 'договора';
      return 'доп.соглашения';
    },
    displayNext() {
      return this.currentStep < this.steps.length - 1;
    },
    steps() {
      if (!this.stepsNew) {
        return [];
      }
      if (this.getContract.additionalAgreementType === 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS') {
        return [
          ...this.stepsNew.slice(0, 3),
          {
            component: 'SelectPoints',
            isComplete: false,
            stepNumber: {
              value: 4,
              from: 6,
              title: () => 'Выбор точек',
            },
            validations: this.validationsUploadAdditions,
          },
          ...this.stepsNew.slice(5, 7).map((step) => ({ ...step, stepNumber: { ...step.stepNumber, value: step.stepNumber.value - 1, from: step.stepNumber.from - 1 } })),
        ];
      }

      return this.stepsNew;
    },
    currentStep: {
      get() {
        return this.getContract.currentStep;
      },
      set(value) {
        this.setCurrentStep({ currentStep: value });
        this.saveContractDraft();
      },
    },
    draftId: {
      get() {
        if (this.getContract?.draftId) {
          return this.getContract.draftId;
        }
        return undefined;
      },
      set(draftId) {
        this.setDraftId({ draftId });
      },
    },
    isUpdated: {
      get() {
        if (this.getContract?.updated) {
          return this.getContract.updated;
        }
        return false;
      },
      set(updated) {
        this.setUpdated({ updated });
      },
    },
    isEditModeOn: {
      get() {
        if (this.getContract?.editMode) {
          return this.getContract.editMode;
        }
        return false;
      },
      set(editMode) {
        this.setEditMode({ editMode });
      },
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
    ...mapGetters('user', ['getAuthorization']),
  },
  watch: {
    currentStep(val) {
      if (val !== 0 && !this.steps[val]) {
        this.currentStep = 0;
      }
    },
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    setElementsWidth() {
      this.navBarWidth = `${this.$refs.contractContent.offsetWidth + 16}px`;
      this.footerWidth = `${this.$refs.contractContent.offsetWidth}px`;
    },
    validationsApplication() {
      if (this.getContract.attachments.every((attachment) => (!attachment.errors || attachment.errors.length === 0))) {
        return true;
      }

      const attachments = [];
      attachments.push(...this.getContract.attachments);

      let isValid = true;
      let errorsCount = 0;

      attachments.forEach((attachment) => {

        attachment.errors = attachment.errors.filter((error) => {
          const resultValidation = check(error.value, error.template, error.parsed, error.line, attachment.value[error.rowIndex]);

          if (resultValidation.isNotValid) {
            error.text = resultValidation.errorText;
            error.incorrectValue = error.value;
            isValid = false;
          }

          return resultValidation.isNotValid;
        });

        errorsCount += attachment.errors.length;
      });

      this.setAttachmentsList({ attachments: attachments });

      if (isValid) {
        return true;
      }

      this.componentKey += 1;

      this.SET_NOTIFICATION({
        notificationType: 'error',
        notificationText: `Общее количество ошибок: ${errorsCount}`,
      });
      return false;
    },
    validationMesOekRmr() {
      let isValid = true;
      const errors = this.getContract.errors;
      let lastRole = '';
      if (!this.getContract.attachments.find((item) => item.name === 'ElectricityUsagePointsAddition' && item.kind === 'receiving') &&
        !this.getContract.attachments.find((item) => item.name === 'ElectricityUsagePointsAddition' && item.kind === 'transfer')) {
        errors.filledAttachment1 = {
          isError: true,
        };

        lastRole = 'filledAttachment1';

        isValid = false;
      } else {
        errors.filledAttachment1 = {
          isError: false,
        };
      }
      this.setErrors({ errors: errors });

      if (!isValid) {
        scrollIntoView(document.querySelector(`[role=${lastRole}]`));
      }

      return isValid;
    },
    validationsMesBoilerTso() {
      let isValid = true;
      const errors = this.getContract.errors;
      let lastRole = '';

      if (this.getContract.selectedTemplate.mnemonicType === 'MES_BOILER_TSO') {
        // Приложение 1
        if (this.getContract.attachments.filter((item) => item.name === 'ElectricityUsagePointsAddition' && item.kind === 'receiving').length === 0) {
          errors.filledAttachment1 = {
            isError: true,
          };

          lastRole = 'filledAttachment1';

          isValid = false;
        } else {
          errors.filledAttachment1 = {
            isError: false,
          };
        }

        const departments = this.getContract.attachments
          .filter((item) => item.departmentData);

        // Наличие отделений
        if (departments.length > 0) {
          // Отделения без приложений
          const invalidDepartments = departments
            .filter((item) => !item.file)
            .map((item) => item.departmentData.id);

          // Отделения не со всеми приложениями (Приложение 2 и Приложение 3)
          const incompleteDepartments = departments
            .map((item) => item.departmentData.id)
            .filter(
              (departmentId, index, array) => array.filter((depId) => depId === departmentId).length < 2,
            );

          if (invalidDepartments.length > 0 || incompleteDepartments.length > 0) {
            let role = '';
            invalidDepartments.push(...incompleteDepartments);
            invalidDepartments.forEach((departmentId) => {
                role += `${departmentId}, `;
                lastRole = `filledDocuments_${departmentId}`;
              });

            errors.filledDocuments = {
              isError: true,
              role: role,
            };

            isValid = false;
          } else {
            errors.filledDocuments = {
              isError: false,
            };
          }
        }
      }

      this.setErrors({ errors: errors });

      if (!isValid) {
        scrollIntoView(document.querySelector(`[role=${lastRole}]`));
      }

      return isValid;
    },
    validationsUploadAdditions() {
      if (this.getContract.type === 'additionalAgreement') return true;

      if (this.getContract.selectedTemplate.mnemonicType === 'MES_BOILER_TSO') {
        return this.validationsMesBoilerTso();
      }
      if (this.getContract.selectedTemplate.mnemonicType === 'MES_RMR_OEK') {
        return this.validationMesOekRmr();
      }
      if (this.getContract.selectedTemplate.mnemonicType === 'RETAIL_RETAIL_PURCHASE') {
        if (this.getContract.attachments.length !== 2 || this.getContract.attachments.some((attachment) => (!attachment.value || attachment.value.length === 0))) {
          const errors = this.getContract.errors;

          errors.filledAttachment1 = {
            isError: true,
          };

          this.setErrors({ errors: errors });

          scrollIntoView(document.querySelector('[role=filledAttachment1]'));

          return false;
        }

        return true;
      }

      const errors = this.getContract.errors;
      let isValid = true;

      if (this.getContract.attachments.length === 0 ||
        this.getContract.attachments.some((attachment) => (!attachment.value || attachment.value.length === 0))) {

        errors.filledAttachment1 = {
          isError: true,
        };

        isValid = false;
      } else {
        errors.filledAttachment1 = {
          isError: false,
        };
      }

      this.setErrors({ errors: errors });

      if (!isValid) {
        scrollIntoView(document.querySelector('[role=filledAttachment1]'));
      }

      return isValid;
    },
    scrollToTop() {
      const scrollButton = document.querySelector('.scroll-button');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      scrollButton.dispatchEvent(clickEvent);
    },
    close() {
      this.$router.go(-1);
    },
    back() {
      if (this.currentStep === 0) {
        return this.$router.go(-1);
      }
      this.currentStep -= 1;
      this.scrollToTop();
    },
    async next() {
      this.scrollToTop();
      if (this.validationNext()) {
        this.currentStep += 1;
      }
      this.disableEditMode();
    },
    validationNext() {
      const contract = this.getContract;
      const errors = this.getContract.errors;

      let isValid = true;

      if (contract.type === 'contract' && !contract.selectedTemplate) {
        errors.selectedTemplate = {
          isError: true,
        };
        this.setErrors({ errors: errors });
        isValid = false;
      } else {
        errors.selectedTemplate = {
          isError: false,
        };
      }

      if (contract.type === 'additionalAgreement' && !contract.sourceContractId) {
        errors.sourceContractId = {
          isError: true,
        };
        this.setErrors({ errors: errors });
        isValid = false;
      } else {
        errors.sourceContractId = {
          isError: false,
        };
      }

      if (!contract.type || (contract.type === 'additionalAgreement' && !contract.additionalAgreementType)) {
        errors.additionalAgreementType = {
          isError: true,
        };
        this.setErrors({ errors: errors });
        isValid = false;
      } else {
        errors.additionalAgreementType = {
          isError: false,
        };
      }

      if (contract.scanContract.length === 0 && contract.action === 'upload') {
        errors.scanContract = {
          isError: true,
        };
        this.setErrors({ errors: errors });
        isValid = false;
      }

      if (this.steps[this.currentStep].validations) {
        return this.steps[this.currentStep].validations();
      }

      return isValid;
    },
    complete(isComplete) {
      this.steps[this.currentStep].isComplete = isComplete;

      this.componentKey += 1;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
        return;
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
    },
    deleteFromElectricityTransmissionContract() {
      removeDraft(`${this.getContract.accountId}contractNew`);
      this.$store.dispatch('electricityTransmissionContract/resetState');
    },
    deleteContractDraft() {
      this.deleteDraft({ id: this.draftId });
      this.deleteFromElectricityTransmissionContract();
      this.close();
    },
    getDraftTitle() {
      let title = '';
      if (this.getContract.type === 'contract' && this.getContract.action === 'create') {
        title = 'Создание электронного договора';
      }
      if (this.getContract.type === 'contract' && this.getContract.action === 'upload') {
        title = 'Загрузка действующего договора';
      }
      if (this.getContract.type === 'additionalAgreement' && this.getContract.action === 'create') {
        title = 'Создание электронного дополнительного соглашения';
      }
      if (this.getContract.type === 'additionalAgreement' && this.getContract.action === 'upload') {
        title = 'Загрузка действующего дополнительного соглашения';
      }

      let sides = '';
      this.getContract.selectedTemplate?.sides?.forEach((side, index) => {
        if (index !== 0) {
          sides += ' - ';
        }
        sides += side.credentials[0].name;
      });

      return `${title} ${sides}`;
    },
    changeEditMode() {
      this.isEditModeOn = !this.isEditModeOn;
    },
    disableEditMode() {
      this.isEditModeOn = false;
    },
    async saveContractDraft() {
      try {
        this.isUploadingDraft = true;

        this.isUpdated = false;

        const payload = {
          xml: JSON.stringify(this.getContract),
          documentType: this.getContract.type + this.getContract.action + this.getContract.contractType,
          title: this.getDraftTitle(),
        };

        if (this.draftId) {
          payload.id = this.draftId;
        }
        if (this.draftId && this.$route.params.contractId) {
          await this.ADD_REJECTED_CONTRACT({ contractId: this.$route.params.contractId, draftId: this.draftId });
          payload.xml = JSON.stringify(this.getContract);
        }
        this.draftId = await this.saveDraft(payload);
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Не удалось сохранить черновик' });
        this.isUpdated = true;
      } finally {
        this.isUploadingDraft = false;
      }
    },
    onSubmit() {
      this.submit = !this.submit;
    },
    onAction() {
      this.customAction = !this.customAction;
    },
    updateWaitingSubmit(val) {
      this.isWaitingSubmit = val;
    },
    ...mapActions('electricityTransmissionContract', ['setAttachmentsList', 'setErrors', 'setCurrentStep', 'setDraftId', 'setUpdated', 'setEditMode']),
    ...mapActions('document', ['saveDraft', 'deleteDraft']),
    ...mapActions('user', ['setNotification']),
    ...mapMutations('electricityTransmissionContract', ['ADD_REJECTED_CONTRACT']),
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.loader {
  position: relative;
  height: 50px;

  div:first-child {
    top: 50px;
  }
}

.content {
  padding-top: 8px;
  padding-bottom: 60px;
  flex-grow: 2;
}

.contentFooter {
  height: 48px;
  display: flex;
  background-color: #ffffff;
  z-index: 100;
}

.back, .next, .delete, .save, .submit {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;

  svg {
    fill: #FFFFFF;
  }
}

.delete {
  margin-left: 16px;

  svg {
    path {
      fill: #EB5757
    }
  }

  span {
    margin-left: 7px;
  }
}

.center {
  flex-grow: 2;
}

.back {
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.next, .submit {
  margin-right: 36px;
}

.next {
  span {
    margin-right: 13px;
  }
}

.save {
  margin-right: 16px;

  span {
    margin-left: 7px;
  }
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.uploadingDraft {
  z-index: 100;
}
</style>
