<template>
  <div>
    <div class="form-wrp" role="form-wrp-top" style="margin: 24px 0 20px 100px;">
      <div class="form">
        <div class="form-header">
          <h1 class="form-header__title">
            {{ contractPatternName }}
          </h1>
          <p class="form-header__subtitle">{{ title }}</p>
        </div>

        <v-divider style="margin: 24px 0; height: 0;"/>

        <p v-if="getContract.attachments.length === 0" class="form-header__title" style="text-align: center">Приложения
          не были
          загружены</p>

        <div v-for="(application, index) in nonDepartmentApplications" :key="'nond_' + index" class="application-error">
          <div class="application-error__header">
            <div>{{ application.file.name }}</div>
            <div v-if="application.errors && application.errors.length > 0" class="application-error__status">
              <div class="application-error__circle application-error__circle--error"></div>
              <div>Найдено ошибок | {{ application.errors.length }}</div>
            </div>
            <div v-else class="application-error__status">
              <div class="application-error__circle application-error__circle--ok"></div>
              <div>Ошибок не найдено</div>
            </div>
          </div>

          <error-item v-for="(error, indexError) in paginatorList(application.errors, `application_${index}`)"
                      :key="error.row + '_' + error.col + indexError"
                      :error="error"
                      :substations="mapMainSubstationToSelectList"
                      :all-substations="mapAllSubstationToSelectList"
                      @change="changeValue($event, indexError, application, `application_${index}`)"
                      @check="checkError"
          />

          <v-table-pagination v-if="application.errors.length > 10"
                              :table-name="`${getContract.action}_application_${index}`"
                              :total-elements="application.errors.length"
                              @change="(pagination) => paginationChange(pagination, `application_${index}`)"
          />

          <v-divider style="margin: 24px 0; height: 0;"/>

        </div>

        <div v-for="(application, index) in departments" :key="index" class="application-error">
          <div class="application-error__header application-error__header--mb">
            <div>Наименование отделения {{ getContract.editableSides.SUPPLIER.company.name }}</div>
            <div>Отделение: {{ application.departmentData?.name }}</div>
          </div>
          <div class="application-error__header">
            <div>{{ application.file?.name }}</div>
            <div v-if="application.errors && application.errors.length > 0" class="application-error__status">
              <div class="application-error__circle application-error__circle--error"></div>
              <div>Найдено ошибок | {{ application.errors.length }}</div>
            </div>
            <div v-else class="application-error__status">
              <div class="application-error__circle application-error__circle--ok"></div>
              <div>Ошибок не найдено</div>
            </div>
          </div>

          <error-item v-for="(error, indexError) in paginatorList(application.errors, `department_${index}`)"
                      :key="error.row + '_' + error.col + indexError"
                      :error="error"
                      :substations="mapMainSubstationToSelectList"
                      :all-substations="mapAllSubstationToSelectList"
                      @change="changeValue($event, indexError, application, `department_${index}`)"
                      @check="checkError"
          />

          <v-table-pagination v-if="application.errors.length > 10"
                              :table-name="`${getContract.action}_application_department_${index}`"
                              :total-elements="application.errors.length"
                              @change="(pagination) => paginationChange(pagination, `department_${index}`)"
          />

          <v-divider style="margin: 24px 0; height: 0;"/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VDivider } from 'components';
import { VTablePagination } from 'components/VTableNew/common';
import ErrorItem from './ErrorItem.vue';
import { mapActions, mapGetters } from 'vuex';

function compareAttachments(a, b) {
  if (a.number < b.number) {
    return -1;
  }
  return 1;
}

function compareDepartment(a, b) {
  if (a.departmentData.name < b.departmentData.name) {
    return -1;
  }
  if (a.departmentData.name > b.departmentData.name) {
    return 1;
  }
  if (a.departmentData.name === b.departmentData.name) {
    if (a.number < b.number) {
      return -1;
    }
    return 1;
  }
}

export default {
  name: 'ContractApplicationCheck',
  components: {
    VDivider,
    ErrorItem,
    VTablePagination,
  },
  props: {
    title: {
      type: String,
      default: 'Проверка приложений',
    },
  },
  async created() {
    this.mainSubstations = await this.getMainSubstationsListWithLines();
    this.allSubstations = await this.substations();
  },
  data() {
    return {
      currentPage: {},
      pageSize: {},
      mainSubstations: [],
      allSubstations: [],
    };
  },
  computed: {
    contractPatternName() {
      return this.getPatternName;
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договора';
      }
      return 'доп.соглашения';

    },
    tooltipText() {
      return this.boldString('Важно: Введите точные данные, указанные в документе, даже если эти данные содержат устаревшую или ошибочную информацию.', 'Важно');
    },
    nonDepartmentApplications() {
      return this.getContract.attachments.filter((attach) => !attach.departmentData).sort(compareAttachments);
    },
    departments() {
      return this.getContract.attachments.filter((attach) => attach.departmentData).sort(compareDepartment);
    },
    paginatorList() {
      return (list, id) => {
        const page = this.currentPage[id] ?? 1;
        const pageSize = this.pageSize[id] ?? 10;

        return list.slice((page - 1) * pageSize, page * pageSize);
      };
    },
    mapMainSubstationToSelectList() {
      return this.mainSubstations.map(
          (it) => ({
            value: it.number,
            label: it.number,
          }),
      );
    },
    mapAllSubstationToSelectList() {
      return this.allSubstations.map(
          (it) => ({
            value: it.number,
            label: it.number,
          }),
      );
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getPatternName']),
  },
  methods: {
    paginationChange(pagination, id) {
      this.$set(this.pageSize, id, pagination.size);
      this.$set(this.currentPage, id, pagination.page);
    },
    boldString(str, substr) {
      const strRegExp = new RegExp(substr, 'g');
      return str.replace(strRegExp, `<b>${substr}</b>`);
    },
    checkError() {
      this.$emit('next');
    },
    changeValue(error, errorIndex, updatedApplication, id) {
      let updatedRow = updatedApplication.value[error.rowIndex];

      error.keys.forEach((key, index) => {
        if (index === error.keys.length - 1) {
          updatedRow[key] = error.value;
        } else {
          updatedRow = updatedRow[key];
        }
      });

      const page = this.currentPage[id] ?? 1;
      const pageSize = this.pageSize[id] ?? 10;

      updatedApplication.errors[((page - 1) * pageSize) + errorIndex].value = error.value;

      this.setAttachments({ attachment: updatedApplication });
    },
    ...mapActions('dataLists', ['getMainSubstationsListWithLines']),
    ...mapActions('border', ['substations']),
    ...mapActions('electricityTransmissionContract', ['setAttachments']),
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 848px;

  &-wrp {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
    }

    &__subtitle {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4C4E51;
    }
  }

  .application-error {
    &__header {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;

      &--mb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: 12px;
        font-weight: 600;
      }
    }

    &__status {
      display: flex;
      width: fit-content;
      border: 1px solid #C1C4C7;
      border-radius: 16px;
      padding: 3px 15px 3px 15px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 6px;
    }

    &__circle {
      width: 8px;
      height: 8px;
      margin-top: 6px;
      margin-right: 8px;
      border-radius: 24px;

      &--error {
        background-color: #EB5757;
      }

      &--ok {
        background-color: #4CCB40;
      }
    }
  }
}
</style>
