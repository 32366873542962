const agentHas = (keyword) => navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;

const browserProperties = {
  isFirefox: agentHas('Firefox') || agentHas('FxiOS') || agentHas('Focus'),
  isEdge: agentHas('Edg'),
  isIE: !!document.documentMode,
  isOpera: agentHas('OPR'),
  isVivaldi: agentHas('Vivaldi'),
  isChrome: agentHas('CriOS') || agentHas('Chrome') || !!window.chrome,
  isSafari: (!!window.ApplePaySetupFeature || !!window.safari) &&
    agentHas('Safari') && !agentHas('Chrome') && !agentHas('CriOS'),
};

export default browserProperties;
