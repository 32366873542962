import { formatDateToXmlDateFormat } from 'lib/utils/date';

export function generateUtilityIndividualTariffs(data) {
  const orderDate = data.orderTariff.orderRequisites.date
    ? `<Date>${formatDateToXmlDateFormat(data.orderTariff.orderRequisites.date)}</Date>` : '';

  let companyTariff = '';

  const utilityIndividualTariffs = (item) => `        <Tariffs startDate="${formatDateToXmlDateFormat(item[3])}" endDate="${formatDateToXmlDateFormat(item[4])}">\n` +
      `            <SingleRateElectricityTariff unit="rubkWth">${item[7]}</SingleRateElectricityTariff>\n` +
      `            <RateForTheMaintenanceOfElectricalNetworks unit="rubMWtm">${item[5]}</RateForTheMaintenanceOfElectricalNetworks>\n` +
      `            <RateForPaymentOfTechnologicalLosses unit="rubMWth">${item[6]}</RateForPaymentOfTechnologicalLosses>\n` +
      '        </Tariffs>\n';

  for (let i = 0; i < data.companyTariff.length; i++) {
    if (i === 0) {
      companyTariff = '<CompanyTariff>\n';
    } else {
      companyTariff += '    <CompanyTariff>\n';
    }

    companyTariff += `      <CompanyInn>${data.companyTariff[i][2]}</CompanyInn>\n`;
    companyTariff += `      <CompanyName>${data.companyTariff[i][1]}</CompanyName>\n`;
    companyTariff += utilityIndividualTariffs(data.companyTariff[i]);

    if (data.companyTariff[i + 1] && data.companyTariff[i][2] === data.companyTariff[i + 1][2]) {
      i++;
      companyTariff += utilityIndividualTariffs(data.companyTariff[i]);
    }

    companyTariff += '    </CompanyTariff>\n';
  }

  return '<?xml version="1.0" encoding="UTF-8"?>\n' +
    '<TariffsOrder xmlns="http://energochain.ru/XML/documents/tariffs-order/1.0"\n' +
    '                          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                          xsi:schemaLocation="http://energochain.ru/XML/documents/tariffs-order/1.0 TariffsOrder.xsd">\n' +
    `    <Year>${data.orderTariff.year}</Year>\n` +
    '    <OrderRequisites>\n' +
    `        ${orderDate}\n` +
    `        <OrderNumber>${data.orderTariff.orderRequisites.orderNumber}</OrderNumber>\n` +
    `        <OrderName>${data.orderTariff.orderRequisites.orderName}</OrderName>\n` +
    `        <OrderPublisher>${data.orderTariff.orderRequisites.orderPublisher}</OrderPublisher>\n` +
    '    </OrderRequisites>\n' +
    `    <OrderBody>${data.orderTariff.orderBody}</OrderBody>\n` +
    '    <UtilityIndividualTariffs>\n' +
    '        <MeasuringUnits>\n' +
    '            <SingleRateElectricityTariffUnits>rubkWth</SingleRateElectricityTariffUnits>\n' +
    '            <RateForTheMaintenanceOfElectricalNetworksUnits>rubMWtm</RateForTheMaintenanceOfElectricalNetworksUnits>\n' +
    '            <RateForPaymentOfTechnologicalLossesUnits>rubMWth</RateForPaymentOfTechnologicalLossesUnits>\n' +
    '       </MeasuringUnits>\n' +
    '\n' +
    `    ${companyTariff}\n` +
    '    </UtilityIndividualTariffs>\n' +
    '</TariffsOrder>\n';

}
