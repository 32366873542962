<template>
  <page title="Единые (котловые) тарифы на услуги по передаче электрической энергии">
    <v-table-new
      v-model="transformedElectricityBoilerTariffs"
      :loading="loading"
      :template="template"
      :on-pagination-change="onPaginationChange"
      :on-filter-change="onFilterChange"
      :page-out="electricityBoilerTariffsPage.pageOut"
      :on-load-all-values="loadAllFiltered"
      excel-report-file-name="Единые (котловые) тарифы на услуги по передаче электрической энергии"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import { formatDateToCustomDateFormat } from 'lib/utils/date.js';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'ElectricityBoilerTariffs',
  components: {
    VTableNew,
    Page,
  },
  data() {
    return {
      electricityBoilerTariffsPage: {},
      pagination: initialPagination,
      filter: {},
      loading: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Категория потребителей',
            key: 'boilerConsumerTypeName',
            sort: {},
            filter: {},
          },
          {
            label: 'Уровень напряжения',
            key: 'voltageLevel',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: { type: 'select', values: this.voltageClassListRu },
          },
          {
            label: 'Дата начала действия тарифа',
            key: 'startDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Дата конца действия тарифа',
            key: 'endDate',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Ставка на содержание электрических сетей, (руб / (МВт * мес))',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForTheMaintenanceOfElectricalNetworks',
          },
          {
            label: 'Ставка на оплату технологического расхода, (руб / (МВт * ч))',
            thStyle: {
              minWidth: '100px',
            },
            key: 'rateForPaymentOfTechnologicalLosses',
          },
          {
            label: 'Одноставочный тариф, (руб / (кВт * ч))',
            thStyle: {
              minWidth: '100px',
            },
            key: 'singleRateElectricityTariff',
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    electricityBoilerTariffs() {
      return this.electricityBoilerTariffsPage.list ?? [];
    },
    transformedElectricityBoilerTariffs() {
      return this.electricityBoilerTariffs.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
    ...mapGetters('dataLists', ['voltageClassListRu']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.electricityBoilerTariffsPage = await this.electricityBoilerTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('electricityBoilerTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const electricityBoilerTariffs = await this.electricityBoilerTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.electricityBoilerTariffsPage.pageOut.totalElements,
          },
        });

        if (!electricityBoilerTariffs?.list) {
          return [];
        }

        return electricityBoilerTariffs.list.map(this.transformed);
      } catch (e) {
        console.log('electricityBoilerTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        boilerConsumerTypeName: it.boilerConsumerType.description,
        voltageLevel: it.voltageLevel,
        startDate: formatDateToCustomDateFormat(it.startDate, 'DD.MM.YYYY'),
        endDate: formatDateToCustomDateFormat(it.endDate, 'DD.MM.YYYY'),
        rateForTheMaintenanceOfElectricalNetworks: parseFloat(it.rateForTheMaintenanceOfElectricalNetworks) ?? '',
        rateForPaymentOfTechnologicalLosses: parseFloat(it.rateForPaymentOfTechnologicalLosses) ?? '',
        singleRateElectricityTariff: parseFloat(it.singleRateElectricityTariff) ?? '',
        document: { documentId: it.tariffsOrder?.documentId, name: 'ElectricityBoilerTariffs' },
      };
    },
    ...mapActions('dataLists', ['electricityBoilerTariffsByPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped lang="scss">

</style>
