<template>
  <v-spoiler :summary="transformContractSide(side.contractSide)">
    <div class="row">
      <div class="col col--1of mt-24">
        <v-input
          v-model="companyShortName"
          :disabled="contractAction"
          title="Краткое наименование компании"
        />
      </div>
      <div class="col col--1of mt-24">
        <v-input
          v-model="companyName"
          :disabled="contractAction"
          title="Полное наименование компании"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="side.credentials[0].inn"
          :disabled="true"
          title="ИНН"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyKpp"
          :disabled="contractAction"
          title="КПП"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyOgrn"
          :disabled="contractAction"
          title="ОГРН"

        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyOkpo"
          :disabled="contractAction"
          title="ОКПО"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyOktmo"
          :disabled="contractAction"
          title="ОКТМО"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyLegalAddress"
          :disabled="contractAction"
          title="Адрес юридический"
        />
      </div>
      <div class="col col--1of2 col col--1of mt-24">
        <v-input
          v-model="companyPostAddress"
          :disabled="contractAction"
          title="Адрес почтовый"
        />
      </div>
    </div>

    <v-divider style="margin: 24px 0; height: 0;"/>

    <div class="row mb-16">
      <div class="col col--1of1 space-between">
        <p class="title" style="margin: auto 0">Банковские реквизиты *</p>
        <v-select
          v-if="contractAction"
          v-model="bank"
          :options="bankRequisites"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          :vid="`${idx}_Выбрать реквизиты`"
          new-style
          placeholder="Выбрать реквизиты"
          rules="required"
          style="min-width: 235px; max-width: 500px"
        />
      </div>
    </div>

    <div v-if="bank || !contractAction">
      <div>
        <div class="row mb-24">
          <div class="col col--1of1">
            <v-address-search-select
              v-model="bankName"
              :bik="bik"
              :disabled="contractAction"
              :vid="`${idx}_Банк`"
              bank-api
              clearable
              required
              rules="required"
              title="Банк"
              @keyup="setBikInfo"
            />
          </div>
        </div>

        <div class="row">
          <div class="col col--1of2">
            <v-input
              v-model="settlementAccount"
              :disabled="contractAction"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`${idx}_Расчётный счёт`"
              required
              rules="required|rs"
              title="Расчётный счёт"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-model="correspondentAccount"
              :disabled="contractAction"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`${idx}_Корреспондентский счёт`"
              required
              rules="required|ks"
              title="Корреспондентский счёт"
            />
          </div>
        </div>
      </div>
    </div>

    <v-divider style="margin: 24px 0; height: 0;"/>

    <div class="row mb-24">
      <div class="col col--1of1">
        <p class="title">Данные о подписывающем лице</p>
      </div>
    </div>

    <div class="row">
      <div class="col col--1of1">
        <emploee-select-or-input
          v-if="company?.length > 0"
          :idx="idx"
          :init-employee="setEmployee()"
          :inn="company"
          :mode="selectorType"
          :not-emit-input-reason="true"
          permission="SVET_A1-S"
          @emploeeSelectOrInput="selectSideSignatoryEmployeeHandler($event)"
        />
      </div>
    </div>

    <v-divider v-if="contractAction" style="margin: 8px 0 24px 0; height: 0;"/>

    <div v-if="contractAction" class="row">
      <div class="col col--1of1">
        <p class="updated-at">Реквизиты компании на {{ convertDbDateTimeFormat(companyRequisitesUpdatedAt) }}</p>
      </div>
    </div>
  </v-spoiler>
</template>

<script>
import { EmploeeSelectOrInput, VAddressSearchSelect, VDivider, VInput, VSelect, VSpoiler } from 'components';
import { mapActions, mapGetters } from 'vuex';
import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  name: 'ContractSide',
  components: { VAddressSearchSelect, VDivider, VInput, VSelect, EmploeeSelectOrInput, VSpoiler },
  props: {
    side: { type: Object, required: true },
    idx: { type: Number, required: true },
  },
  mounted() {
    if (!this.contractAction) {
      this.setEmployee();
      this.setBankRequisites();
    }
  },
  computed: {
    selectorType() {
      if (this.getContract.action === 'upload') return 'input';
      return 'auto';
    },
    contractAction() {
      return this.getContract.action !== 'upload';
    },
    companies() {
      return this.getContractSideCompanyValue(this.side.contractSide);
    },
    bankRequisites() {
      return this.getContractSideCompanyBankRequisites(this.side.contractSide)
        .filter((bankRequisites) => bankRequisites.inn === this.company)
        .map((bankRequisites) => ({
          ...bankRequisites,
          label: bankRequisites.bankName,
          value: bankRequisites.settlementAccount,
        }));
    },
    companyName: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.name;
      },
      set(value) {
        this.setCompanyName({ name: value, sideName: this.side.contractSide });
      },
    },
    companyShortName: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.shortName;
      },
      set(value) {
        this.setCompanyShortName({ name: value, sideName: this.side.contractSide });
      },
    },
    companyKpp: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.kpp;
      },
      set(value) {
        this.setCompanyKpp({ kpp: value, sideName: this.side.contractSide });
      },
    },
    companyOgrn: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.ogrn;
      },
      set(value) {
        this.setCompanyOgrn({ ogrn: value, sideName: this.side.contractSide });
      },
    },
    companyOkpo: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.okpo;
      },
      set(value) {
        this.setCompanyOkpo({ okpo: value, sideName: this.side.contractSide });
      },
    },
    companyOktmo: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.oktmo;
      },
      set(value) {
        this.setCompanyOktmo({ oktmo: value, sideName: this.side.contractSide });
      },
    },
    companyLegalAddress: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.legalAddress;
      },
      set(value) {
        this.setCompanyLegalAddress({ legalAddress: value, sideName: this.side.contractSide });
      },
    },
    companyPostAddress: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.postAddress;
      },
      set(value) {
        this.setCompanyPostAddress({ postAddress: value, sideName: this.side.contractSide });
      },
    },
    bank: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.selectedBankRequisites?.settlementAccount;
      },
      set(selectedBank) {
        const bank = this.bankRequisites.find((it) => it.settlementAccount === selectedBank);

        this.setBankRequisitesSide({ selectedBank: bank, sideName: this.side.contractSide });
      },
    },
    bankName: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.selectedBankRequisites?.bankName;
      },
      set(value) {
        this.setBankName({ bankName: value, sideName: this.side.contractSide });
      },
    },
    settlementAccount: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.selectedBankRequisites?.settlementAccount;
      },
      set(value) {
        this.setSettlementAccount({ settlementAccount: value, sideName: this.side.contractSide });
      },
    },
    correspondentAccount: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.selectedBankRequisites?.correspondentAccount;
      },
      set(value) {
        this.setCorrespondentAccount({ correspondentAccount: value, sideName: this.side.contractSide });
      },
    },
    bik: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.selectedBankRequisites?.bik;
      },
      set(value) {
        this.setBik({ bik: value, sideName: this.side.contractSide });
      },
    },
    company: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.inn;
      },
      set(selectedCompany) {
        this.setCompanySide({ selectedCompany, sideName: this.side.contractSide });
      },
    },
    companyRequisitesUpdatedAt: {
      get() {
        return this.getEditableSides[this.side.contractSide].company.bankRequisitesUpdatedAt;
      },
    },
    selectSideSignatoryEmployee: {
      get() {
        return this.getEditableSides[this.side.contractSide];
      },
      set(signatoryEmployee) {
        this.setSignatoriesSide({ signatoryEmployee, sideName: this.side.contractSide });
      },
    },
    ...mapGetters('electricityTransmissionContract', [
      'getEditableSides',
      'getContractSideCompanyValue',
      'getContractSideCompanyBankRequisites',
      'getContract',
      'getErrors',
    ]),
  },
  methods: {
    convertDbDateTimeFormat(value) {
      return convertDbDateTimeFormat(value);
    },
    setBankRequisites() {
      if (!this.getEditableSides[this.side.contractSide].company.bankRequisites[0]) return;
      this.getEditableSides[this.side.contractSide].company.selectedBankRequisites = this.getEditableSides[this.side.contractSide].company.bankRequisites[0];
    },
    setBikInfo(value) {
      this.setBik({ bik: value.bik, sideName: this.side.contractSide });
    },
    setEmployee() {
      return {
        id: this.getEditableSides[this.side.contractSide].signatory.id,
        fullName: {
          surname: this.getEditableSides[this.side.contractSide].signatory.fullName.surname,
          name: this.getEditableSides[this.side.contractSide].signatory.fullName.name,
          patronymic: this.getEditableSides[this.side.contractSide].signatory.fullName.patronymic,
        },
        reason: {
          id: this.getEditableSides[this.side.contractSide].signatory.reason.id,
          reasonDocument: this.getEditableSides[this.side.contractSide].signatory.reason.reasonDocument ?? 'Устав',
          number: this.getEditableSides[this.side.contractSide].signatory.reason.number,
          date: this.getEditableSides[this.side.contractSide].signatory.reason.date,
        },
        role: {
          id: this.getEditableSides[this.side.contractSide].signatory.role.id,
          name: this.getEditableSides[this.side.contractSide].signatory.role.name,
        },
        userId: this.getEditableSides[this.side.contractSide].signatory.userId,
        accountId: this.getEditableSides[this.side.contractSide].signatory.accountId,
      };
    },
    selectSideSignatoryEmployeeHandler(signatoryEmployee) {
      if (JSON.stringify(this.selectSideSignatoryEmployee.signatory) !== JSON.stringify(signatoryEmployee)) {
        this.selectSideSignatoryEmployee = signatoryEmployee;
      }
    },
    transformContractSide(side) {
      switch (side) {
        case 'SUPPLIER': {
          return 'Заказчик';
        }
        case 'PERFORMER': {
          return 'Исполнитель';
        }
        case 'PERFORMER_FIRST': {
          return 'Исполнитель-1';
        }
        case 'PERFORMER_SECOND': {
          return 'Исполнитель-2';
        }
      }
    },
    ...mapActions('electricityTransmissionContract', [
      'setSignatoriesSide',
      'setCompanySide',
      'setBankRequisitesSide',
      'setBankName',
      'setSettlementAccount',
      'setCorrespondentAccount',
      'setBik',
      'setCompanyName',
      'setCompanyShortName',
      'setCompanyKpp',
      'setCompanyOgrn',
      'setCompanyOkpo',
      'setCompanyOktmo',
      'setCompanyLegalAddress',
      'setCompanyPostAddress',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";

.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4C4E51;
}

.updated-at {
  color: #71757a;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
</style>
