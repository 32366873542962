<template>
  <div class="container">
    <contract-side
      v-for="(side, idx) in sides" :key="idx"
      :idx="idx"
      :side="side"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContractSide from 'views/private/UploadDocument/steps/ContractRequisites/components/ContractSide.vue';

export default {
  name: 'ContractSides',
  components: { ContractSide },
  computed: {
    sides() {
      return this.getContract.selectedTemplate.sides;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 850px;
}
</style>
