<template>
  <div>
    <v-table-new
      v-model="transformedLocalRepairSummary"
      :loading="loading"
      :template="template"

      excel-report-file-name="Фото ОДК"

      @on-cell-click="cellClickHandler"
    />
    <v-app class="vuetify-styles" data-app>
      <v-dialog v-model="isVisibleCarousel" class="v-dialog-top"
                data-app>
        <div class="v-application" data-app>
          <v-carousel v-model="carouselItem" class="files-carousel" height="auto"
          >
            <v-carousel-item v-for="(item, index) in transformedLocalRepairSummary" :key="index">
              <div class="align-center justify-center d-flex">
                <img v-if="!loading" :src="item.preview.src" class="exploitationPhotos__image"/>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { mapGetters } from 'vuex';
import { formatDateTimeFromOdkTimestamp } from 'lib/utils/date';
import { BASE_URL } from '@/constants/api';
import axios from 'axios';
import { flatMap } from 'lodash';
import { fetchData } from '@/api/rest/odk/api';

export default {
  name: 'ExploitationPhotos',
  components: { VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      localRepairSummary: [],
      loading: false,
      isVisibleCarousel: false,
      carouselItem: 0,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
      adreskaId: null,
      adreska: {},
    };
  },
  computed: {
    transformedLocalRepairSummary() {
      if (!this.localRepairSummary ||
        !Array.isArray(this.localRepairSummary)) {
        return [];
      }

      const transformed = flatMap(this.localRepairSummary, this.transformed);
      if (this.adreska.axpId) {
        transformed.splice(0, 0, {
          preview: {
            src: `${BASE_URL}/axp/${this.adreska.axpId}/preview`,
            alt: 'Эталонное фото',
            previewExists: true,
            notExistsMessage: 'Отсутствует',
            disableDefaultClick: true,
          },
          process: 'Эталонное фото',
          date: '',
        });
      }
      return transformed;
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
          },
          {
            label: 'Фото',
            key: 'preview',
            type: 'preview',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Процесс',
            key: 'process',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата',
            key: 'date',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Сотрудник',
            key: 'fullName',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        'defect.status': 'status',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });

      result.adreskaId = this.adreskaId;

      return result;
    },
    ...mapGetters('dictionary', ['odkProcessDictionary']),
  },
  methods: {
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'preview': {
          this.isVisibleCarousel = true;
          this.carouselItem = cell.row.index - 1;
        }
      }
    },
    async loadData() {
      this.adreskaId = this.$route.query.id;
      if (!this.adreskaId) {
        return;
      }

      try {
        this.loading = true;
        const responseData = await axios.get(`${BASE_URL}/adreska-history?adreskaId=${this.adreskaId}`);
        this.localRepairSummary = responseData.data;

        const adreskaData = await fetchData('adreska-summary', 1, 10, undefined, undefined, { adreskaId: this.adreskaId });
        this.adreska = adreskaData?.data[0];
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    transformed(item) {
      return item.data.photos?.map((it) => ({
        preview: {
          src: `${BASE_URL}/api/get/${it.folder}/${it.name}`,
          alt: 'Фото ОДК',
          previewExists: true,
          notExistsMessage: 'Отсутствует',
          disableDefaultClick: true,
        },
        process: this.odkProcessDictionary[item.type],
        date: formatDateTimeFromOdkTimestamp(item.data.createDate),
        fullName: item.data.fullName,
      })) ?? [];
    },
  },
};
</script>

<style scoped>
.exploitationPhotos__image {
  min-height: 10vh;
  max-height: 75vh;
  max-width: 100vw;
}
</style>
