<template>
  <page title="Отозванные сертификаты">
    <div class="revoked-certificates">
      <v-table-new
        v-model="transformedRevokedCertificates"
        :action-block-items="actionBlockItems"
        :loading="loading"
        :template="template"
        excel-report-file-name="Отозванные сертификаты"
        @on-cell-click="cellClickHandler"
      />

      <modal
        v-if="isAddModalShow"
        :body-style="{
        padding: '24px 0',
      }"
        :header-style="{
        display: 'flex',
        justifyContent: 'center',
      }"
        :is-backdrop="true"
        :is-btn-close-visible="false"
        :modal-style="{
        borderRadius: '16px',
        padding: '50px 100px',
      }"
        @close="closeModal"
      >
        <template #header>
          <span class="revoked-certificates__modal__title">Добавление списка</span>
        </template>
        <template #body>
          <v-form @submit="submitHandler">
            <div class="revoked-certificates__list-name">
              <v-input
                v-model="listNameForAdded"
                title="Название списка"
              />
            </div>

            <v-input
              v-model="listLinkForAdded"
              :rules-messages="{revokedCertificatesUrl: 'Поле должно содержать ссылку на .crl файл'}"
              rules="revokedCertificatesUrl"
              title="Ссылка на список"
            />

            <div style="display: flex; justify-content: space-around; margin-top: 20px;">
              <v-button
                type="submit"
              >
                Добавить список
              </v-button>
              <v-button
                type="button"
                variant="outlined"
                @click="closeModal"
              >
                Отменить
              </v-button>
            </div>
          </v-form>

        </template>
      </modal>

    </div>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Modal, VButton, VForm, VInput, VTableNew } from '@/components';

import generateXml from '@/lib/storage/connection-application/data.js';
import { convertDbDateTimeFormat } from 'lib/utils/date';
import Page from 'components/Page/Page.vue';

export default {
  components: {
    Page,
    VTableNew,
    VButton,
    Modal,
    VInput,
    VForm,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      isAddModalShow: false,
      listNameForAdded: '',
      listLinkForAdded: '',
      revokedCertificates: [],
      actionBlockItems: [
        {
          id: 1,
          title: 'Добавить',
          method: this.addButtonClickHandler,
        },
      ],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Название списка',
            key: 'name',
          },
          {
            label: 'Ссылка на список',
            key: 'link',
          },
          {
            label: 'Дата обновления',
            key: 'updatedAt',
          },
          {
            label: '',
            key: 'delete',
            type: 'delete',
          },
        ],
      };
    },
    transformedRevokedCertificates() {
      if (this.revokedCertificates.length === 0) return [];

      return this.revokedCertificates.map((it) => ({
        id: it.id,
        name: it.name,
        link: it.link,
        updatedAt: convertDbDateTimeFormat(it.updatedAt),
      }));
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async loadData() {
      try {
        this.revokedCertificates = [];
        this.loading = true;
        this.revokedCertificates = await this.getRevokedCertificates();
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    addButtonClickHandler() {
      this.listNameForAdded = '';
      this.listLinkForAdded = '';
      this.isAddModalShow = true;
    },
    async submitHandler() {
      const revokedCertificatesListItem = {
        name: this.listNameForAdded,
        link: this.listLinkForAdded,
      };
      const xml = generateXml.GenerateRevokedCertificatesXML([revokedCertificatesListItem], 'add');

      try {
        await this.documentUpload({ xml: xml });
        await this.loadData();
        this.closeModal();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteRevokedCertificate(id) {
      const xml = generateXml.GenerateRevokedCertificatesXML([{ id: id }], 'revoke');

      try {
        await this.documentUpload({ xml: xml });
        await this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    closeModal() {
      this.listNameForAdded = '';
      this.listLinkForAdded = '';
      this.isAddModalShow = false;
    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'delete': {
          this.deleteRevokedCertificate(cell.row.id);
          break;
        }
      }
    },
    ...mapActions('cabinet', ['getRevokedCertificates']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
.revoked-certificates {

  &__add {
    margin-bottom: 5px;
  }

  &__list-name {
    margin-bottom: 40px;
  }

  &__modal {
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }
  }
}
</style>
