const getEmployees = (state) => state.employees;
const getRoles = (state) => state.roles;
const getAcceptedInvitations = (state) => state.acceptedInvitations;
const getNotAcceptedInvitations = (state) => state.notAcceptedInvitations;

export default {
  getEmployees,
  getRoles,
  getAcceptedInvitations,
  getNotAcceptedInvitations,
};
