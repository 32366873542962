<template>
  <modal
      v-if="visibleDialog"
      :body-style="{
            padding: '24px 0',
            overflowY:'initial',
          }"
      :header-style="{
            display: 'flex',
            justifyContent: 'center',
          }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
            borderRadius: '16px',
            padding: '24px',
            minHeight: '400px',
          }"
      class="add-adreska-from-excel"
  >
    <template #header>
      <span class="add-adreska-from-excel__title">Добавить из Excel</span>
    </template>
    <template #body>
      <events-autocomplete
          ref="event"
          v-model="eventIdForExcel"
          :is-disabled="isParsingExcel"
          :search.sync="eventIdSearch"
          is-required
          title="Выберите праздник"
      />
      <div class="add-adreska-from-excel__attachment">
        <div class="add-adreska-from-excel__file-attachment-wrp">
          <a :href="`/xlsx/adreska/adreska.xlsx`" class="add-adreska-from-excel__file-attachment"
             download="Программа оформления шаблон.xlsx">
            <img alt="" height="40" src="../../../assets/icons/file/template.svg" width="40"/>
            <span class="add-adreska-from-excel__attachment-title">Программа оформления шаблон.xlsx</span>
          </a>
        </div>
        <document-downloader
            :is-open-eye-show="false"
            :types="['XLSX']"
            @download="xlsDownload(`/xlsx/adreska/adreska.xlsx`, 'Программа оформления шаблон.xlsx')"
        />
      </div>
      <horizontal-progress-bar v-if="isParsingExcel" class="add-adreska-from-excel__progress-bar"/>
      <div class="add-adreska-from-excel__buttons">
        <v-button
            variant="outlined"
            @click="onClose"
        >
          Отменить
        </v-button>
        <select-file-button-dialog
            v-model="addFromExcel"
            :disabled="isParsingExcel || !eventIdForExcel"
            :label="errors.length > 0 ? 'Заменить из Excel' : undefined"
            accept=".xls,.xlsx"
            class="ml-4"
        />
      </div>

      <div v-if="errors.length > 0">
        <div class="add-adreska-from-excel__error__title">
          <span>Найдено {{ errors.length }} ошибок. Перезагрузите файл или исправьте данные:</span>
        </div>
        <template v-if="!isParsingExcel">
          <div v-for="(error, indexError) in errors" :key="indexError" class="add-adreska-from-excel__error__item">
            <div class="add-adreska-from-excel__error__text">Строка {{ error.index + 4 }}. {{ error.text }}</div>
            <div>
              <template v-if="error.type === 'odkName'">
                <v-input
                    v-model="items[error.index].element.odkName"
                    title="Наименование ОДК"
                    style="width: 100%; margin-bottom: 24px"
                />
                <axp-autocomplete
                    title="АХП тип"
                    margin-bottom=""
                    @update:search="(val) => items[error.index].element.axpName = val"
                />
              </template>
              <administrative-districts-autocomplete
                  v-else-if="error.type === 'administrativeDistrict'"
                  title="Административный округ"
                  margin-bottom=""
                  @update:search="(val) => items[error.index].element.administrativeDistrict = val"
              />
              <oek-district-departments-autocomplete
                  v-else-if="error.type === 'responsibleDistrictDepartment'"
                  title="Ответственный РЭС"
                  margin-bottom=""
                  @update:search="(val) => items[error.index].element.responsibleDistrictDepartment = val"
              />
              <v-address-search-select
                  v-else-if="error.type === 'address'"
                  title="Адрес"
                  @update:search="(val) => items[error.index].element.address = val"
                  @input="(val) => items[error.index].element.address = val"
              />
              <v-address-search-select
                  v-else-if="error.type === 'backupAddress'"
                  title="Резервный адрес"
                  @update:search="(val) => items[error.index].element.backupAddress = val"
                  @input="(val) => items[error.index].element.address = val"
              />
              <div v-else-if="error.type === 'contractor'" style="display: flex; gap: 4px">
                <v-input
                    v-model="items[error.index].element.contractor.name"
                    title="Подрядчик"
                    style="width: 100%"
                />
                <v-input
                    v-model="items[error.index].element.contractor.inn"
                    :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                    rules="digits:10|inn"
                    title="ИНН"
                    style="width: 100%"
                />
              </div>
              <v-input
                  v-else
                  v-model="items[error.index].element[error.type]"
                  :title="error.title"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-if="errors.length > 0" #footer>
      <div class="add-adreska-from-excel__error__save">
        <v-button
            variant="filled"
            :disabled="isParsingExcel"
            @click="saveUpdate"
        >
          Сохранить
        </v-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { ADRESKA_LIST, BASE_URL } from '@/constants/api';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SelectFileButtonDialog from 'components/Processes/components/SelectFileButtonDialog.vue';
import { nanoid } from 'nanoid';
import { excelParseWithWorker } from 'utils/excel';
import { UploadAdreskaPattern } from 'components/Processes/patterns/UploadAdreskaPattern';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';
import { findAddress } from '@/api/rest/dadata/api';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import AdministrativeDistrictsAutocomplete from 'components/Processes/components/AdministrativeDistrictsAutocomplete.vue';
import OekDistrictDepartmentsAutocomplete from 'components/Processes/components/OekDistrictDepartmentsAutocomplete.vue';
import Modal from 'components/Modal/Modal';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { VButton, VAddressSearchSelect, VInput } from 'components';
import { check } from 'lib/utils/validation/additionValidation';

export default {
  components: {
    DocumentDownloader,
    SelectFileButtonDialog,
    EventsAutocomplete,
    Modal,
    HorizontalProgressBar,
    VButton,
    AxpAutocomplete,
    AdministrativeDistrictsAutocomplete,
    OekDistrictDepartmentsAutocomplete,
    VAddressSearchSelect,
    VInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    handleSuccess: {
      type: Function,
      default: () => ({}),
    },
    handleError: {
      type: Function,
      default: () => ({}),
    },
  },
  async created() {
    try {
      this.districtDepartments = await this.supplierDepartments({ inn: '7720522853' });
    } catch (e) {
      this.handleError('Ошибка загрузки списка РЭС. Обновите страницу и попробуйте ещё раз.');
    }
  },
  data() {
    return {
      isParsingExcel: false,
      eventIdForExcel: null,
      addFromExcel: undefined,
      eventIdSearch: '',
      districtDepartments: [],
      items: [],
      errors: [],
      parseValidate: [],
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    ...mapGetters('user', ['getToken']),
  },
  watch: {
    async addFromExcel(val) {
      if (val && val.length > 0) {
        this.isParsingExcel = true;

        const taskId = nanoid();
        const res = await excelParseWithWorker(
          val[0].code,
          { ...UploadAdreskaPattern },
          taskId,
        );

        this.errors = [];
        this.parseValidate = [];

        if (res.errors.length) {
          // this.handleError(res.errors.slice(0, 5).map((it) => `${it.title}. ${it.text}`).join(''));
          res.errors.forEach((error) => {
            this.errors.push({
              text: `"${error.value}" - ${error.text}`,
              index: error.row - 3,
              type: this.getKeysString(error.keys),
              title: error.title,
            });

            this.parseValidate.push({
              keys: error.keys,
              line: error.line,
              parsed: error.parsed,
              title: error.title,
              template: error.template,
            });
          });
        }

        if (res.data.length === 0) {
          this.handleError('Пустой файл');
          this.isParsingExcel = false;
          return;
        }

        await this.validateData(res.data, false);

        if (this.errors.length > 0) {
          this.errors.sort((first, second) => (first.index > second.index ? 1 : -1));
          this.isParsingExcel = false;
          return;
        }

        this.postAccountingBalances();
      }
    },
  },

  methods: {
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    getEqualWithoutCase(data, word, findByFunction) {
      if (!data?.length || !word) return null;
      const equal = data.find((it) => findByFunction(it) === word);
      if (equal) return equal;
      const equalWithoutCase = data.find((it) => findByFunction(it).toLowerCase() === word.toLowerCase());
      if (equalWithoutCase) return equalWithoutCase;
      return null;
    },
    onClose() {
      this.items = [];
      this.errors = [];
      this.eventIdForExcel = null;
      this.visibleDialog = false;
    },
    async getAxpByNames(name, odkName) {
      const response = await fetch(`${BASE_URL}/axp/find?name=${name}&odkName=${odkName}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getAdministrativeDistrictByName(name) {
      const response = await fetch(`${BASE_URL}/administrative-district/find?name=${name}`);
      const data = await response.json();
      return this.getEqualWithoutCase(data, name, (it) => it.name);
    },
    async getAddressByFias(name) {
      const response = await findAddress(name);
      return response?.suggestions[0];
    },
    async getResponsibleDistrictDepartmentByName(name) {
      const includes = this.districtDepartments.filter((it) => it.name.toLowerCase().includes(name.toLowerCase()));
      return this.getEqualWithoutCase(includes, name, (it) => it.name);

    },
    async getSuppliers(inn) {
      return this.getSupplierForSelectorByFilterNotErrorNotification(inn ? { inn: inn, kind: 'ODK' } : { kind: 'ODK' });
    },
    getKeysString(keys) {
      if (keys.length === 1) {
        return keys[0];
      }

      return `${keys[0]}_${keys[1]}`;
    },
    async validateData(dataList, isParseValidate) {
      this.items = [];

      if (isParseValidate) {
        this.errors = [];
      }

      for (let i = 0; i < dataList.length; i++) {
        const element = dataList[i];
        const item = {};

        if (isParseValidate) {
          this.parseValidate.forEach((validate) => {
            let value = undefined;
            validate.keys.forEach((key) => {
              if (value) {
                value = value[key];
              } else {
                value = element[key];
              }
            });
            const resultValidation = check(value, validate.template, validate.parsed, validate.line);

            if (resultValidation.isNotValid) {
              this.errors.push({
                text: `"${value}" - ${resultValidation.errorText}`,
                index: i,
                type: this.getKeysString(validate.keys),
                title: validate.title,
              });
            }
          });
        }

        // Праздник
        item.eventId = this.eventIdForExcel;

        // ОДК
        if (element.odkName.length > 0) {
          const axp = await this.getAxpByNames(element.axpName);
          if (!axp || axp.length === 0 || element.odkName.replaceAll(' ', '').toLowerCase() !== axp.odk.name.replaceAll(' ', '').toLowerCase()) {
            this.errors.push({
              text: `Не найден АХП - "${element.axpName}" - ${element.odkName}`,
              index: i,
              type: 'odkName',
            });
          } else {
            item.axpId = axp.id;
          }
        }

        if (element.administrativeDistrict.length > 0) {
          const administrativeDistrict = await this.getAdministrativeDistrictByName(element.administrativeDistrict);
          if (!administrativeDistrict || administrativeDistrict.length === 0) {
            this.errors.push({
              text: `Не найден административный округ с названием - "${element.administrativeDistrict}"`,
              index: i,
              type: 'administrativeDistrict',
            });
          } else {
            item.administrativeDistrictId = administrativeDistrict.id;
          }
        }

        if (element.responsibleDistrictDepartment.length > 0) {
          const responsibleDistrictDepartment = await this.getResponsibleDistrictDepartmentByName(element.responsibleDistrictDepartment);
          if (!responsibleDistrictDepartment) {
            this.errors.push({
              text: `Не найден административный округ с названием - "${element.responsibleDistrictDepartment}"`,
              index: i,
              type: 'responsibleDistrictDepartment',
            });
          } else {
            item.responsibleDistrictDepartmentId = responsibleDistrictDepartment.id;
          }
        }

        if (element.address.length > 0) {
          const address = await this.getAddressByFias(element.address);
          if (!address) {
            this.errors.push({
              text: `Не найден адрес - "${element.address}"`,
              index: i,
              type: 'address',
            });
          } else {
            item.address = address?.data?.postal_code ? `${address?.data?.postal_code}, ${address?.value}` : address?.value;
            item.locationLatitude = address?.data?.geo_lat ?? '';
            item.locationLongitude = address?.data?.geo_lon ?? '';
          }
        }

        if (item.backupAddress?.length) {
          const backupAddress = await this.getAddressByFias(element.backupAddress);
          if (!backupAddress?.length) {
            this.errors.push({
              text: `Не найден адрес - "${element.backupAddress}"`,
              index: i,
              type: 'backupAddress',
            });
          } else {
            item.backupAddress = backupAddress;
          }
        }
        // Подрядчик
        try {
          if (element.contractor.inn.length > 0) {
            const contractors = await this.getSuppliers(element.contractor.inn);

            if (!contractors || contractors.length === 0) {
              this.errors.push({
                text: `Не найден подрядчик по ИНН - ИНН: "${element.contractor.inn}", Name: "${element.contractor.name}"`,
                index: i,
                type: 'contractor',
              });
            } else {
              item.contractorId = contractors[0].id;
              item.name = element.name;
              item.quantityPlan = element.quantityPlan;
              item.workModePlan = element.workModePlan === 'ДА' ? '24 часа' : 'не световая';
              item.administrativeDistrict = element.administrativeDistrict;
              item.comment = element.comment;
            }
          }
        } catch (e) {
          console.log('get getSuppliers error:', e);
        }

        item.element = element;
        this.items.push(item);
      }
    },
    async saveUpdate() {
      this.isParsingExcel = true;

      this.items.forEach((item) => {
        Object.keys(item.element)
            .filter((key) => key.indexOf('_') !== -1 && this.errors.some((error) => error.type === key))
            .forEach((multiKey) => {
              const value = item.element[multiKey];
              const keys = multiKey.split('_');

              let elementObj = undefined;
              keys.forEach((keyItem, index) => {
                if (keys.length === index + 1) {
                  elementObj[keyItem] = value;
                } else if (elementObj) {
                  elementObj = elementObj[keyItem];
                } else {
                  elementObj = item.element[keyItem];
                }
              });
            });
      });

      this.errors = [];

      await this.validateData(this.items.map((item) => item.element), true);

      if (this.errors.length > 0) {
        this.errors.sort((first, second) => (first.index > second.index ? 1 : -1));
        this.isParsingExcel = false;
        return;
      }

      this.postAccountingBalances();
    },
    postAccountingBalances() {
      const items = this.items.some((item) => item.element) ? this.items.map((item) => ({
        ...item,
        ...item.element,
      })) : this.items;

      axios
          .post(BASE_URL + ADRESKA_LIST, JSON.stringify(items), {
            headers: {
              'X-Authorization': `Bearer ${this.getToken}`,
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            this.onClose();
            this.handleSuccess();
          })
          .catch((error) => {
            console.error(error);
            this.handleError(error.response.data);
          })
          .finally(() => {
            this.isParsingExcel = false;
          });
    },
    ...mapActions('cabinet', ['getSupplierForSelectorByFilterNotErrorNotification']),
    ...mapActions('dataLists', ['supplierDepartments']),
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
}

.add-adreska-from-excel {
  &__attachment {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #4C4E51;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__attachment-title, &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #4C4E51;
  }

  &__title {
    font-size: 24px;
  }

  &__file-attachment-wrp {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__file-attachment {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #4C4E51;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    span {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0;
    }

    &:hover {
      text-decoration: none
    }
  }

  &__error {
    &__text, &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #4C4E51;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &__item {
      margin-bottom: 24px;
    }

    &__save {
      display: flex;
      justify-content: end;
    }
  }

  &__buttons {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  &__progress-bar {
    margin-bottom: 16px;
  }
}

</style>
