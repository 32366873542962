<template>
  <page title="Договоры оказания услуг по передаче электрической энергии и дополнительные соглашения">
    <data-tab-selector
      :data-types-list="tabs"
      tab-style="table"
      class="cabinet-consumers-card__select"
      @selectTab="selectTabHandler"
    />
    <content-block v-if="selectedTab === 'contract'" :contract-type="contractType"
                   :contracts="filteredContracts"
                   :loading="loading"/>
    <content-block v-else-if="selectedTab === 'disagreements'" :contract-type="contractType"
                   :contracts="disagreementProtocols"
                   :loading="loading"/>
    <!--    <disagreements-block v-else-if="selectedTab === 'disagreements'" :documents="disagreements" :loading="loading" />-->
    <contract-drafts v-else-if="selectedTab === 'drafts'" :contract-type="contractType" />
    <content-block v-if="selectedTab === 'nullified'" :contracts="nullifiedContracts" :loading="loading" :contract-type="contractType" />
  </page>
</template>

<script>
import ContentBlock from './Contracts/ContentBlock.vue';
import ContractDrafts from './Contracts/ContractDrafts.vue';
import { mapActions } from 'vuex';
import { DataTabSelector, Page } from '@/components';

export default {
  name: 'CabinetContracts',
  components: { ContentBlock, DataTabSelector, ContractDrafts, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      contracts: [],
      disagreements: [],
      loading: false,
      tabs: [
        {
          id: 1,
          title: 'Договоры и дополнительные соглашения',
          value: 'contract',
          isActive: true,
        },
        {
          id: 2,
          title: 'Протоколы разногласий',
          value: 'disagreements',
          isActive: false,
        },
        {
          id: 3,
          title: 'Черновики',
          value: 'drafts',
          isActive: false,
        },
        {
          id: 4,
          title: 'Архив',
          value: 'nullified',
          isActive: false,
        },
      ],
      selectedTab: 'contract',
    };
  },
  computed: {
    filteredContracts() {
      return this.contracts.filter((contract) => contract.status !== 'NULLIFIED' && !contract.isDisagreementProtocol);
    },
    nullifiedContracts() {
      return this.contracts.filter((contract) => contract.status === 'NULLIFIED');
    },
    disagreementProtocols() {
      return this.contracts.filter((contract) => contract.isDisagreementProtocol);
    },
    contractType() {
      if (this.$route.name === 'cabinet.agreements' || this.$route.name === 'cabinet.contracts-work-agreements') {
        return 'ELECTRICITY_TRANSMISSION_CONTRACT';
      }
      if (this.$route.name === 'cabinet.electricity-purchase-contracts') {
        return 'ELECTRICITY_PURCHASE_CONTRACT';
      }

      console.log('Error contract type, route.name = ', this.$route.name);
      return 'OTHER';
    },
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.contracts = await this.loadElectricityTransmissionContractsByType({ contractType: this.contractType });
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    ...mapActions('document', ['loadElectricityTransmissionContractsByType']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
