var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tooltipShow && _vm.isCompact)?_c('hover-tooltip',{attrs:{"text":_vm.title,"top":_vm.distanceToTop}}):_vm._e(),_c('div',{ref:"itemMenuRef",class:{
      [_vm.$style.active]: _vm.route ? _vm.active : false,
      [_vm.$style.container]: true,
      [_vm.$style.open]: _vm.isOpen && _vm.items,
      [_vm.$style.isGroup]: _vm.items,
      [_vm.$style.under]: _vm.isUnder && !_vm.active,
      [_vm.$style.underActive]: _vm.isUnder && _vm.active,
      [_vm.$style.hide]: _vm.isHide,
      [_vm.$style.isCompact]: _vm.isCompact,
    },on:{"mouseleave":function($event){return _vm.hideTooltip(_vm.title)},"mouseover":function($event){return _vm.showTooltip(_vm.title)}}},[_c('router-link',{class:_vm.$style.menuLink,attrs:{"to":_vm.route ? { name: `cabinet.${_vm.route}`, params: _vm.params } : '#'}},[_c('div',{class:{[_vm.$style.compact]: _vm.isCompact },style:(_vm.customStyle),on:{"click":_vm.openGroup}},[_c(_vm.iconComponent,{tag:"component"}),_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.counter > 0)?_c('p',{class:{
                [_vm.$style.counter]: !_vm.isCompact,
                [_vm.$style.compactCounter]: _vm.isCompact,
              }},[_vm._v(" "+_vm._s(_vm.transformCounter)+" ")]):_vm._e()],1)]),(_vm.items)?_c('img',{class:{[_vm.$style.iconChevron]: true, [_vm.$style.iconChevronUp]: _vm.isOpen},attrs:{"alt":"","src":require("@/assets/icons/arrows/arrow-down-black.svg")},on:{"click":_vm.openGroup}}):_vm._e()],1),(_vm.items && _vm.isOpen)?_vm._l((_vm.items),function(item,indexItem){return _c('item-menu',{key:indexItem,attrs:{"block-access":_vm.blockAccess,"counter":_vm.counter,"icon-component":item.iconComponent,"index-group":indexItem,"is-compact":_vm.isCompact,"is-under":true,"items":item.items,"route":item.route,"title":item.title,"params":item.params},on:{"openGroup":_vm.openSubGroup}})}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }