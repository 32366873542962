<template>
  <div style="margin-top: 24px">
    <v-table-new
      v-model="transformedOdks"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. ОДК"
      @delete="onDelete"
      @edit="onEdit"
    />
    <odk-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData" />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchOdk } from '@/api/rest/odk/api';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import OdkEdit from 'views/private/OdkAccounting/Axp/OdkEdit';

export default {
  name: 'Odk',
  components: {
    OdkEdit,
    VTableNew,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      odk: [],
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить ОДК',
          method: this.onCreate,
        },
      ] : [];
    },
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
      ];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          ...editHeaders,
        ],
      };
    },
    transformedOdks() {
      if (!this.odk?.length) {
        return [];
      }

      return this.odk;
    },
    isAccessUpdate() {
      return filterForAccess('process/2/1', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.odk = await fetchOdk();
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
