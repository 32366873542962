<template>
  <page title="Чаты">
    <v-table-new
      v-model="transformedData"
      :loading="loading"
      :template="template"
      excel-report-file-name="Чаты"
      hide-spoiler
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from 'components';
import { mapActions } from 'vuex';

export default {
  name: 'CabinetUserRequests',
  components: { Page, VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      rooms: [],
      loading: false,
      observer: null,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '80px',
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Название',
            key: 'title',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            key: 'followLink',
            type: 'followLink',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
    transformedData() {
      if (this.rooms.length === 0)
        return [];

      return this.rooms.map((it) => ({
        ...it,
        title: it.title,
      }));
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        this.rooms = await this.showAllRoomsForUser();
        await this.subscribeRooms();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async subscribeRooms() {
      this.observer = await this.receiveRooms();
      this.observer.subscribe({
        rooms: this.rooms,
        next(response) {
          console.log('response', response.data);
          const room = {
            ...response.data.receiveRooms,
          };
          this.rooms.unshift(room);
        },
        error(error) {
          console.error('error', error);
        },
      });
    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          this.$router.push({
            name: 'cabinet.user-requests.messenger',
            params: {
              id: cell.row.id,
              title: cell.row.title,
              fromTitle: 'Обращение пользователя',
            },
          });
        }
      }
    },
    ...mapActions('chatbot', ['showAllRoomsForUser', 'showRoom', 'receiveRooms']),
  },
};
</script>
