<template>
  <label :class="$style.label">
    <div
      v-if="isTooltipShow && isNeedMouseoverTooltip"
      :class="$style.label__tooltip"
    >
      {{ title }}{{ label }}
    </div>
    <div
      :class="{
        [$style['label__text']]: true,
        [$style['label__text--error']]: isError,
        [$style['label__text--up']]: isValue,
        [$style['label__text--title']]: title.length > 0,
      }"
      @mouseleave="isTooltipShow = false"
      @mouseover="isTooltipShow = true"
    >
      {{ title }}{{ label }}
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'VLabel',
  props: {
    label: { type: String, required: false },
    isValue: { type: Boolean, required: true },
    select: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
    title: { type: String, default: '' },
    isNeedMouseoverTooltip: { type: Boolean, default: false },
  },
  data() {
    return {
      isTooltipShow: false,
    };
  },
};
</script>

<style lang="scss" module>
.label {
  z-index: 99;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &__tooltip {
    position: absolute;
    top: 0;
    text-decoration: none;
    background-color: #f5f1f1;
    box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
    font-size: 12px;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 1000;
  }

  &__text {
    z-index: 1;
    color: #71757a;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: text;
    transition: all 0.1s linear;

    &--up {
      width: auto;
      max-width: 100%;
      background-color: white;

      &:focus {
        color: #2f82df;
      }
    }

    &--error {
      color: red;
    }

    &--title {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:focus-within > .label__text--up {
    color: #2f82df;
  }
}
</style>
