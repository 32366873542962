<template>
  <inner-page :current-step="formAttorney.currentStep"
              :home="formAttorney.homeTitle"
              :steps="formAttorney.steps"
              class="form-attorney-scan"
              @back="goHome"
              @goHome="goHome"
              @openStep="openStep"
  >
    <h1 class="form-attorney-scan__title">Загрузить XML МЧД</h1>
    <v-divider/>

    <div>
      <span :style="{'color': isError ? 'red': ''}">Загрузка документа *</span>
      <c-uploading
        v-model="formAttorney.powerOfAttorneyXml"
        :error="{error: isError}"
        :error-text="documentError"
        accept=".xml"
        accept-title="XML или перетащите его в эту область"
        class="form-attorney-scan__uploading"
        role="xml"
      />

      <div v-if="formAttorney.powerOfAttorneyXml && formAttorney.powerOfAttorneyXml[0]"
           class="form-attorney-scan__attachment">
        <a :download="formAttorney.powerOfAttorneyXml[0].name" :href="fileUrl(formAttorney.powerOfAttorneyXml[0])"
           class="form-attorney-scan__attachment-item">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40"/>
          <span>{{ formAttorney.powerOfAttorneyXml[0].name }}</span>
        </a>
        <a class="form-attorney-scan__attachment-delete" href="#" title="удалить" @click="deletePowerOfAttorneyXml()">
          <icon-delete/>
        </a>
      </div>
    </div>

    <div>
      <span>Загрузка подписи *</span>
      <c-uploading
        v-model="formAttorney.signatureFile"
        :error="{error: false}"
        accept=".sig"
        accept-title="SIG или перетащите его в эту область"
        class="form-attorney-scan__uploading"
        role="sig"
      />

      <div v-if="formAttorney.signatureFile && formAttorney.signatureFile[0]"
           class="form-attorney-scan__attachment">
        <a :download="formAttorney.signatureFile[0].name" :href="fileUrl(formAttorney.signatureFile[0])"
           class="form-attorney-scan__attachment-item">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40"/>
          <span>{{ formAttorney.signatureFile[0].name }}</span>
        </a>
        <a class="form-attorney-scan__attachment-delete" href="#" title="удалить" @click="deleteSignatureFile()">
          <icon-delete/>
        </a>
      </div>
    </div>

    <v-divider/>

    <div v-if="submitError.length > 0" class="form-attorney-scan__error-text errorText">
      {{ submitError }}
    </div>

    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="back">
          Назад
        </button>
        <button class="btn-delete-draft" @click="displayDeleteDialog = true">
          УДАЛИТЬ ЧЕРНОВИК
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button
          :disabled="!formAttorney.powerOfAttorneyXml[0] || !formAttorney.signatureFile[0] || isError || isWaitingSubmit"
          class="btn-save"
          @click="next">
          Далее
        </button>
      </div>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <div class="delete-dialog">
        <p class="delete-dialog__title">
          Удалить черновик?
        </p>
        <div class="delete-dialog__action">
          <button class="btn-delete-draft" @click="deleteDraft">
            УДАЛИТЬ
          </button>
          <button class="btn-cancel" @click="displayDeleteDialog = false">
            ЗАКРЫТЬ
          </button>
        </div>
      </div>
    </dialog-template>
  </inner-page>
</template>

<script>
import { InnerPage, VDivider } from '@/components';
import IconDelete from 'atoms/icons/action/Delete';
import CUploading from 'atoms/common/CUploading';
import { getFileUrl } from 'lib/utils/files';
import { mapActions, mapGetters } from 'vuex';
import DialogTemplate from 'templates/DialogTemplate';
import { XMLParser } from 'fast-xml-parser';
import dayjs from 'dayjs';
import { Buffer } from 'buffer';

export default {
  name: 'UploadAttorneyScan',
  components: {
    InnerPage,
    VDivider,
    IconDelete,
    CUploading,
    DialogTemplate,
  },
  async created() {
    await this.loadData();
    console.log(this.getFormAttorney);
  },
  data() {
    return {
      formAttorney: {
        powerOfAttorneyXml: [],
        signatureFile: [],
        issueDate: '',
        steps: [
          { label: 'Загрузить XML МЧД', url: '/cabinet/upload-attorney-xml' },
          { label: 'Сформировать МЧД', url: '/cabinet/form-attorney' },
          { label: 'Загрузить', url: '/cabinet/form-attorney/summary' },
        ],
        currentStep: 0,
      },
      submitError: '',
      isWaitingSubmit: false,
      displayDeleteDialog: false,
    };
  },
  computed: {
    documentError() {
      if (!this.formAttorney.powerOfAttorneyXml[0]) return '';
      if (this.formAttorney.powerOfAttorneyXml[0].type !== 'text/xml') return 'Неверный формат файла';
      if (!this.xmlDocument.includes('ON_EMCHD_1_928_00_01_01_01')) return 'Неверный формат XML';
      if (!this.objectFromXml) return 'Неверный формат XML';
      if (!this.objectFromXml.Довер.СвУпПред.Пред.СведФизЛ.attributes.СНИЛС) return 'СНИЛС сотрудника не указан';
      return '';
    },
    isError() {
      return this.documentError.length > 0;
    },
    xmlDocument() {
      if (!this.formAttorney.powerOfAttorneyXml[0]) return '';
      return Buffer.from(this.formAttorney.powerOfAttorneyXml[0].code, 'base64').toString('utf-8');
    },
    objectFromXml() {
      const alwaysArray = [
        'Доверенность.Документ.Довер.СвПолн.МашПолн',
      ];

      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: '',
        attributesGroupName: 'attributes',
        parseTagValue: true,
        removeNSPrefix: true,
        alwaysArray: alwaysArray,
        // eslint-disable-next-line no-unused-vars
        isArray: (name, jpath, isLeafNode, isAttribute) => {
          if (alwaysArray.indexOf(jpath) !== -1) return true;
        },
      };

      const parser = new XMLParser(options);
      return parser.parse(this.xmlDocument)?.Доверенность?.Документ;
    },
    ...mapGetters('attorney', ['getFormAttorney']),
  },
  watch: {
    formAttorney: {
      deep: true,
      handler(val) {
        this.updateFormAttorney(
          {
            ...this.getFormAttorney,
            ...val,
          },
        );
      },
    },
    'formAttorney.powerOfAttorneyXml': {
      handler() {
        if (this.formAttorney.powerOfAttorneyXml?.length > 1) {
          this.formAttorney.powerOfAttorneyXml = [this.formAttorney.powerOfAttorneyXml[this.formAttorney.powerOfAttorneyXml.length - 1]];
        }
      },
    },
    'formAttorney.signatureFile': {
      handler() {
        if (this.formAttorney.signatureFile?.length > 1) {
          this.formAttorney.signatureFile = [this.formAttorney.signatureFile[this.formAttorney.signatureFile.length - 1]];
        }
      },
    },
  },
  methods: {
    goHome() {
      this.$router.push(this.formAttorney.homeUrl);
    },
    deleteDraft() {
      this.updateFormAttorney({
        homeTitle: this.getFormAttorney.homeTitle,
        homeUrl: this.getFormAttorney.homeUrl,
      });
      this.goHome();
    },
    openStep(index) {
      const isBeforeAtCurrent = this.getFormAttorney.currentStep > index - 1;
      const isUrlExist = this.getFormAttorney.steps[index - 1]?.url;

      if (isBeforeAtCurrent && isUrlExist) {
        this.updateFormAttorney(
          {
            ...this.getFormAttorney,
            currentStep: index - 1,
          },
        );
        this.$router.push(this.getFormAttorney.steps[index - 1].url);
      }
    },
    back() {
      if (!this.getFormAttorney.steps[this.getFormAttorney.currentStep - 1]?.url) {
        this.goHome();
        return;
      }

      this.openStep(this.getFormAttorney.currentStep);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deletePowerOfAttorneyXml() {
      this.formAttorney.powerOfAttorneyXml = [];
    },
    deleteSignatureFile() {
      this.formAttorney.signatureFile = [];
    },
    loadData() {
      this.formAttorney = {
        ...this.formAttorney,
        ...this.getFormAttorney,
      };
    },
    async next() {
      const selectedRights = this.objectFromXml.Довер.СвПолн.МашПолн.map((item) => ({
        label: item.attributes.НаимПолн,
        description: item.attributes.НаимПолн,
        value: item.attributes.КодПолн,
      }));
      const fioXml = this.objectFromXml.Довер.СвУпПред.Пред.СведФизЛ.СведФЛ.ФИО.attributes;
      this.updateFormAttorney(
        {
          ...this.getFormAttorney,
          steps: this.formAttorney.steps,
          currentStep: this.formAttorney.currentStep + 1,
          startDate: dayjs(this.objectFromXml.Довер.СвДов.attributes.ДатаВыдДовер, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          endDate: dayjs(this.objectFromXml.Довер.СвДов.attributes.СрокДейст, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          selectedRights: selectedRights,
          numberAttorney: String(this.objectFromXml.Довер.СвДов.attributes.ВнНомДовер),
          personFIO: [fioXml.Фамилия, fioXml.Имя, fioXml.Отчество].filter(Boolean).join(' '),
          snils: this.objectFromXml.Довер.СвУпПред.Пред.СведФизЛ.attributes.СНИЛС.replace(/[^0-9]/g, ''),
          externalXml: this.xmlDocument,
          externalSignature: this.formAttorney.signatureFile[0].code ?? '',
        },
      );

      this.$router.push('/cabinet/form-attorney');
    },
    ...mapActions('attorney', ['updateFormAttorney']),
    ...mapActions('document', ['documentUpload']),
  },
};

</script>

<style lang="scss" scoped>
.form-attorney-scan {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4C4E51;

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
  }

  &__date {
    width: 272px;
    margin-top: 24px;
  }

  &__attachment {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #0E0F0F;
    display: flex;
    justify-content: space-between;
  }

  &__error-text {
    width: 100%;
    text-align: center;
  }

  &__attachment-item {
    display: flex;
    align-items: center;
    margin-top: 12px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    color: #0E0F0F
  }

  &__attachment-delete {
    color: #4C4E51;
    display: flex;
    align-items: center;
  }

  .action-buttons {
    display: flex;

    button {
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      border-radius: 24px;
      color: #0E0F0F;
      background: #FFFFFF;
      padding: 6px 25px 6px 25px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    .btn-save {
      color: #FFFFFF;
      background: #2F82DF;

      &:hover {
        background: #1f5b9b;
      }

      &:disabled {
        background: #717d8f;
      }
    }

    .btn-delete-draft {
      background-color: red;
      margin-left: 16px;
      color: #FFFFFF;

      &:hover {
        background-color: #831c1c;
      }
    }

    &__action {
      width: 100%;

      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;

        button {
          margin-right: 16px;
        }
      }
    }
  }
}

.delete-dialog {
  &__action {
    display: flex;
    margin-top: 64px;

    button {
      width: 50%;
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      color: #FFFFFF;
      background: #71757A;
      padding: 6px 25px 6px 25px;

      &:hover {
        background: #525459;
      }
    }

    .btn-delete-draft {
      background-color: red;

      &:hover {
        background-color: #831c1c;
      }
    }
  }

  &__title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    margin-top: 64px;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

}
</style>
