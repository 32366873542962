<template>
    <div>
        <div class="container">
            <HelpNav />
            <div class="content-container">
                <div class="content">
                    <img src="../../../../assets/images/help/bg-content-2.png" alt="rectangle" class="bg-content">
                    <div class="content-text">
                        <p class="title">Миссия сервиса</p>
                        <div class="question">
                            <p class="second-title">Сократить споры между контрагентами об объёме оказанных услуг.</p>
                        </div>
                        <div class="question">
                            <p class="second-title">Сделать прозрачными взаиморасчёты, увеличить доверие между сетевыми компаниями, сбытовыми компаниями и потребителями.</p>
                        </div>
                        <div class="question">
                            <p class="second-title">Выявить источники потерь и снизить их.</p>
                        </div>
                        <div class="question">
                            <p class="second-title">Создать условия для подключения микрогенераторов.</p>
                        </div>
                    </div>
                    <!-- /.content-text -->
                </div>
                <!-- /.content -->
            </div>
            <!-- /.content-container -->
        </div>
        <!-- /.container -->
    </div>
</template>

<script>
import HelpNav from 'views/public/help/components/HelpNav.vue';

export default {
    components: {
        HelpNav,
    },
};
</script>

<style scoped>
    .container{
        font-family: 'Roboto Condensed', sans-serif;
        max-width: 1200px;
        margin:0 auto;
        display: flex;
        overflow-x: auto;
    }

    /* Content */

    .content-container{
        display: flex;
        margin-left: 530px;
    }

    .bg-content{
        width: 491px;
        height: 325px;
    }

    .content{
        margin-left: 80px;
        margin-top: 40px;
    }

    .content-text{
        margin-top: 40px;
    }

    .title{
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.005em;
        text-transform: uppercase;
    }

    .question{
        margin-top: 24px;
    }

    .second-title{
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #0E0F0F;
    }

    .standart-text{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #0E0F0F;
        max-width: 465px;
    }

    .link{
        color: #0E0F0F;
    }
</style>
