<template>
  <Process/>
</template>

<script>
import Process from '../../../components/Processes/Process15/Process1.vue';

export default {
  name: 'AcceptanceToWarehouse',
  components: { Process },
};
</script>

<style scoped>

</style>
