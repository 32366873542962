<template>
  <v-card class="mb-6">
    <v-card-title>
      <span class="text-h5 align-center">{{ title }}</span>
    </v-card-title>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="(item, i) in processItems" :key="i">
        <v-expansion-panel-header v-if="item.type == 'PROCESS_7_1'"
        >
        {{ panelHeader('Монтаж',item) }}
        </v-expansion-panel-header
        >
        <v-expansion-panel-content v-if="item.type == 'PROCESS_7_1'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата монтажа"
          ></custom-date-picker>
          <text-field
            ref="axp"
            v-model="item.data.contractorName"
            is-disabled
            title="Подрядчик"
          ></text-field>
          <text-field
            ref="axp"
            v-model="item.data.adreska.event.name"
            is-disabled
            title="Праздник"
          ></text-field>
          <text-field
            ref="axp"
            v-model="item.data.adreska.name"
            is-disabled
            title="Программа оформления"
          ></text-field>
          <text-field
            ref="axp"
            v-model="item.data.adreska.address"
            is-disabled
            title="Адрес"
          ></text-field>
          <text-field
            ref="odk"
            v-model="item.data.adreska.axp.odk.name"
            is-disabled
            title="ОДК"
          ></text-field>
          <text-field
            ref="axpPlan"
            v-model="item.data.adreska.axp.name"
            is-disabled
            title="АХП по плану"
          ></text-field>
          <text-field
            ref="axp"
            v-model="item.data.axp.name"
            is-disabled
            title="АХП"
          ></text-field>
          <text-field
            ref="decorType"
            :text="item.data.decorType?.name ?? ''"
            is-disabled
            title="Тип декора"
          ></text-field>
          <text-field
            ref="axpCount"
            v-model="item.data.constructions.length"
            is-disabled
            title="Количество ОДК"
          ></text-field>
          <v-row class="mt-6">
            <v-col>
              <div>
                <v-label>Комментарий к месту установки</v-label>
              </div>
              <v-textarea
                v-model="item.data.commentPlace"
                dense
                disabled
                name="input-7-4"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <div>
                <v-label>Комментарий к монтажу</v-label>
              </div>
              <v-textarea
                v-model="item.data.commentInstallation"
                dense
                disabled
                name="input-7-4"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото монтажа"
          ></file-input>
          <yandex-map :id="`mount-${i}`" :collection-axp="item.data.constructions"/>
        </v-expansion-panel-content>
        <v-expansion-panel-header v-if="item.type == 'PROCESS_8_4'"
        >
        {{ panelHeader('Приёмка монтажа',item) }}
        </v-expansion-panel-header
        >
        <v-expansion-panel-content v-if="item.type == 'PROCESS_8_4'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата приёмки монтажа"
          ></custom-date-picker>

          <text-field
            ref="workModeFact"
            v-model="item.data.workModeFact"
            is-disabled
            title="Режим работы ОДК по факту"
          ></text-field>

          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото"
          ></file-input>
        </v-expansion-panel-content>
        <v-expansion-panel-header v-if="item.type == 'PROCESS_10_1'"
        >
        {{ panelHeader('Подключение/Смена точки подключения',item) }}
        </v-expansion-panel-header
        >
        <v-expansion-panel-content v-if="item.type == 'PROCESS_10_1'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата"
          ></custom-date-picker>
          <text-field
            ref="connectionPoint"
            v-model="item.data.connectionPoint"
            is-disabled
            title="Точка подключения"
          ></text-field>

          <text-field
            ref="addressConnectionPoint"
            v-model="item.data.addressConnectionPoint"
            is-disabled
            title="Адрес установки автоматического выключателя, коммутирующего ОДК"
          ></text-field>

          <text-field
            ref="addressConnectionPoint"
            v-model="item.data.switchBoard"
            is-disabled
            title="ТМ ВРШ"
          ></text-field>

          <text-field
            ref="isConnectedGenerator"
            :text="convertBooleanToText(item.data.isConnectedGenerator)"
            is-disabled
            title="Возможность подключения ДГУ или бензогенератора"
          ></text-field>

          <text-field
            ref="isConnectedElectricity"
            :text="convertBooleanToText(item.data.isConnectedElectricity)"
            is-disabled
            title="Подключено к электричеству"
          ></text-field>
          <text-field
            ref="isConnectedElectricity"
            :text="convertBooleanToText(item.data.readyForInput)"
            is-disabled
            title="Готово к вводу/включению?"
          ></text-field>

          <text-field
            ref="isConnectedElectricity"
            :text="item.data.workModeFact"
            is-disabled
            title="Режим работы по факту"
          ></text-field>
        </v-expansion-panel-content>
        <v-expansion-panel-header v-if="item.type == 'PROCESS_10_2'"
        >
        {{ panelHeader('Переобнарядка',item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.type == 'PROCESS_10_2'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата"
          ></custom-date-picker>
          <text-field
            ref="contractor"
            v-model="item.data.adreska.contractorName"
            is-disabled
            title="Подрядчик"
          ></text-field>
          <text-field
            ref="event"
            v-model="item.data.adreska.event.name"
            is-disabled
            title="Праздник"
          ></text-field>
          <text-field
            ref="adreska"
            v-model="item.data.adreska.name"
            is-disabled
            title="Программа оформления"
          ></text-field>
          <text-field
            ref="address"
            v-model="item.data.adreska.address"
            is-disabled
            title="Адрес"
          ></text-field>
          <text-field
            ref="odk"
            v-model="item.data.adreska.axp.odk.name"
            is-disabled
            title="ОДК"
          ></text-field>
          <text-field
            ref="decorType"
            :text="item.data.newDecorType?.name"
            is-disabled
            title="Тип декора"
          ></text-field>
          <text-field
            ref="axpCount"
            v-model="item.data.adreskaFact.length"
            is-disabled
            title="Количество"
          ></text-field>
          <v-row class="mt-6">
            <v-col>
              <div>
                <v-label>Комментарий</v-label>
              </div>
              <v-textarea
                v-model="item.data.comment"
                dense
                disabled
                name="input-7-4"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото демонтажа"
          ></file-input>
          <yandex-map :id="`re-equipment-${i}`"
                      :collection-axp="transformAdreskaFactsForInstallationControl(item.data.adreskaFact)"/>
        </v-expansion-panel-content>
        <v-expansion-panel-header v-if="item.type == 'PROCESS_8_3'"
        >
        {{ panelHeader('Контроль монтажа',item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.type == 'PROCESS_8_3'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата контроля"
          ></custom-date-picker>
          <text-field
            ref="remarks"
            :text="item.data.isRemark ? 'Есть' : 'Отсутствуют'"
            is-disabled
            title="Наличие замечаний"
          ></text-field>
          <custom-date-picker
            v-if="item.data.isRemark"
            ref="date"
            v-model="item.data.date"
            disabled
            label="Дата устранения"
          ></custom-date-picker>
          <text-field
            ref="comment"
            v-model="item.data.comment"
            is-disabled
            title="Комментарий"
          ></text-field>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото"
          ></file-input>
          <yandex-map :id="`mount-control-${i}`"
                      :collection-axp="transformAdreskaFactsForInstallationControl(item.data.adreskaFact)"/>
        </v-expansion-panel-content>

        <v-expansion-panel-header v-if="item.type == 'PROCESS_13_1'"
        >
        {{ panelHeader('Демонтаж',item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.type == 'PROCESS_13_1'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата демонтажа"
          ></custom-date-picker>
          <text-field
            ref="contractor"
            v-model="item.data.adreska.contractorName"
            is-disabled
            title="Подрядчик"
          ></text-field>
          <text-field
            ref="event"
            v-model="item.data.adreska.event.name"
            is-disabled
            title="Праздник"
          ></text-field>
          <text-field
            ref="adreska"
            v-model="item.data.adreska.name"
            is-disabled
            title="Программа оформления"
          ></text-field>
          <text-field
            ref="address"
            v-model="item.data.adreska.address"
            is-disabled
            title="Адрес"
          ></text-field>
          <text-field
            ref="odk"
            v-model="item.data.adreska.axp.odk.name"
            is-disabled
            title="ОДК"
          ></text-field>
          <text-field
            ref="odk"
            v-model="item.data.adreska.axp.name"
            is-disabled
            title="АХП"
          ></text-field>
          <text-field
            ref="axpCount"
            v-model="item.data.adreskaFact.length"
            is-disabled
            title="Количество"
          ></text-field>
          <v-row class="mt-6">
            <v-col>
              <div>
                <v-label>Комментарий к демонтажу</v-label>
              </div>
              <v-textarea
                v-model="item.data.comment"
                dense
                disabled
                name="input-7-4"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото демонтажа"
          ></file-input>
          <yandex-map :id="`dismantling-${i}`"
                      :collection-axp="transformAdreskaFactsForInstallationControl(item.data.adreskaFact)"/>
        </v-expansion-panel-content>

        <v-expansion-panel-header v-if="item.type == 'PROCESS_14_3'"
        >
        {{ panelHeader('Контроль демонтажа',item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.type == 'PROCESS_14_3'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата контроля"
          ></custom-date-picker>
          <text-field
            ref="remarks"
            :text="item.data.isRemark ? 'Есть' : 'Отсутствуют'"
            is-disabled
            title="Наличие замечаний"
          ></text-field>
          <custom-date-picker
            v-if="item.data.isRemark"
            ref="date"
            v-model="item.data.date"
            disabled
            label="Дата устранения"
          ></custom-date-picker>
          <text-field
            ref="comment"
            v-model="item.data.comment"
            is-disabled
            title="Комментарий"
          ></text-field>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото"
          ></file-input>
          <yandex-map :id="`dismantling-control-${i}`"
                      :collection-axp="transformAdreskaFactsForInstallationControl(item.data.adreskaFact)"/>
        </v-expansion-panel-content>
        <v-expansion-panel-header v-if="item.type == 'PROCESS_14_4'"
        >
        {{ panelHeader('Приёмка демонтажа',item) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.type == 'PROCESS_14_4'">
          <custom-date-picker
            ref="date"
            v-model="item.data.createDate"
            disabled
            label="Дата приёмки демонтажа"
          ></custom-date-picker>
          <text-field
            ref="contractor"
            v-model="item.data.adreska.contractorName"
            is-disabled
            title="Подрядчик"
          ></text-field>
          <text-field
            ref="event"
            v-model="item.data.adreska.event.name"
            is-disabled
            title="Праздник"
          ></text-field>
          <text-field
            ref="adreska"
            v-model="item.data.adreska.name"
            is-disabled
            title="Программа оформления"
          ></text-field>
          <text-field
            ref="remarks"
            :text="item.data.dismantled.length"
            is-disabled
            title="Количество демонтировано"
          ></text-field>
          <text-field
            ref="remarks"
            :text="item.data.notDismantled.length"
            is-disabled
            title="Количество не демонтировано"
          ></text-field>
          <file-input
            ref="photos"
            v-model="item.data.photos"
            class="mt-2"
            only-view
            title="Фото"
          ></file-input>
          <v-card-text class="text-h6">
            Программа оформления, в которую перенесены не демонтированные объекты
          </v-card-text>
          <text-field
            v-if="item.data.newAdreska"
            ref="newAdreska"
            v-model="item.data.newAdreska.name"
            is-disabled
            title="Программа оформления"
          ></text-field>
          <text-field
            v-if="item.data.newAdreska"
            ref="newEvent"
            v-model="item.data.newAdreska.event.name"
            is-disabled
            title="Праздник"
          ></text-field>
          <text-field
            v-if="item.data.newAdreska"
            ref="newContractor"
            v-model="item.data.newAdreska.contractorName"
            is-disabled
            title="Подрядчик"
          ></text-field>
          <yandex-map :id="`dismantling-control-${i}`"
                      :collection-axp="transformAdreskaFactsForDismantlingControl(item.data.dismantled, item.data.notDismantled)"/>
        </v-expansion-panel-content>
      </v-expansion-panel>


    </v-expansion-panels>
  </v-card>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import TextField from 'components/Processes/components/TextField.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
import formatting from 'lib/utils/formatting';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import CustomDatePicker from 'components/Processes/components/CustomDatePicker.vue';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';

export default {
  components: { CustomDatePicker, YandexMap, FileInput, TextField },
  props: {
    adreskaId: {
      required: true,
    },
    subselect: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: 'История по адресной программе',
    },
  },
  data() {
    return {
      processItems: [],
    };
  },
  computed: {
    formatting() {
      return formatting;
    },
  },
  model: {
    prop: 'selected',
    event: 'change',
  },

  watch: {
    adreskaId: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },


  methods: {
    convertBooleanToText,
    async fetchData() {
      let url = `${BASE_URL}/adreska-history?adreskaId=${this.adreskaId}`;
      if (this.subselect) {
        url += `&subselect=${this.subselect}`;
      }
      const response = await fetch(
        url,
      );

      const responseData = await response.json();
      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i].data.photos) {
          responseData[i].data.photos = responseData[i].data.photos.map((it) => ({
            fileId: it.id,
            file: null,
            name: it.name ?? '',
            previewUrl:
              `${BASE_URL}/api/get/${it.folder}/${it.name}`,
            new: false,
          }));
        }
      }
      this.processItems = responseData;
      this.$emit('data', this.processItems);
    },
    transformAdreskaFactsForInstallationControl(list) {
      return list.map((it) => this.transformAdreskaFactForMaps(it));
    },
    transformAdreskaFactsForDismantlingControl(dismantled, notDismantled) {
      const transformedDismantled = dismantled.map((it) => this.transformAdreskaFactForMaps(it, () => 'green', () => 'Демонтировано'));
      const transformedNotDismantled = notDismantled.map((it) => this.transformAdreskaFactForMaps(it, () => 'orange', () => 'Перенесено'));
      return [...transformedDismantled, ...transformedNotDismantled];
    },
    transformAdreskaFactForMaps(adreskaFact, colorFunction, statusFunction) {
      const statusPropList = statusFunction ? [
        {
          label: 'Статус',
          value: statusFunction(adreskaFact),
        }] : [];
      return {
        ...adreskaFact,
        color: colorFunction ? colorFunction(adreskaFact) : undefined,
        balloonContent: {
          title: adreskaFact.axp?.odk?.name ?? 'Нет данных',
          buttonLabel: 'Выбрать',
          props: [
            {
              label: 'ID',
              value: adreskaFact.id,
            },
            {
              label: 'АХП',
              value: adreskaFact.axp?.name,
            },
            {
              label: 'Тип декора',
              value: adreskaFact.decorType?.name,
            },
            ...statusPropList,
            {
              label: 'Дата монтажа',
              value: formatDateFromOdkTimestamp(adreskaFact.createDate),
            },
          ],
        },
      };
    },
    panelHeader(name, item) {
      return  `${name} ${formatDateFromOdkTimestamp(item.data.createDate)}, Исполнитель: ${item.data.fullName}`;
    },
  },
};
</script>
