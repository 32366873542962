<template>
  <div class="xml-contract">
    <div :style="xmlContractBarStyle" class="xml-contract-bar">
      <div class="xml-contract-bar-left">
        <h1 class="xml-contract-bar__title">
          {{ contractPatternName }}
          <br />
        </h1>
        <input-tooltip :text="tooltipText" style="display: flex; align-self: center; padding: 11px 24px" />
      </div>
      <div class="xml-contract-bar-right">
        <button class="button button--grey"
                @click.prevent="isDisplayCloseAll ? triggerCloseAll = !triggerCloseAll : triggerOpenAll = !triggerOpenAll">
          <span class="button-inner">
            <img v-if="isDisplayCloseAll" alt="" src="@/assets/icons/arrows/arrow-up.svg">
            <img v-else alt="" src="@/assets/icons/arrows/arrow-down.svg">
            <span class="button__text">
              <span v-if="isDisplayCloseAll">Свернуть</span><span v-else>Развернуть</span>&nbsp;всё
            </span>
          </span>
        </button>
      </div>
    </div>
    <div class="xml-contract__table">
      <xml-contract-view :close-all="triggerCloseAll" :is-edit-mode-on="isEditModeOn" :open-all="triggerOpenAll"
                         :xml="xmlForView" @allClosed="allClosed" />
    </div>

  </div>
</template>

<script>
import { InputTooltip, XmlContractView } from '@/components';
import { mapGetters } from 'vuex';
import { getSurnameAndInitials } from '@/utils/user';
import { checkField } from '@/utils/common';
import { xmlFromHeaderAndBody } from 'services/contractXml';

export default {
  components: {
    XmlContractView,
    InputTooltip,
  },
  props: {
    heightNavigationBar: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {
      tooltipText: 'Просмотр шаблона',
      isDisplayCloseAll: true,
      triggerCloseAll: true,
      triggerOpenAll: true,
    };
  },
  computed: {
    contractPatternName() {
      return this.getPatternName;
    },
    isEditModeOn: {
      get() {
        if (this.getContract?.editMode) {
          return this.getContract.editMode;
        }
        return false;
      },
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договора';
      }
        return 'доп.соглашения';

    },
    xmlForView() {
      if (this.getContract?.editedSelectedTemplateXml) {
        return xmlFromHeaderAndBody(this.getContract?.editedSelectedTemplateXml);
      }
      if (this.getContract?.selectedTemplate?.xmlContent) return this.getContract.selectedTemplate.xmlContent;
      return '';
    },
    selectedEmployees() {
      const selectedEmployees = [];
      if (this.getContract?.userEmployeesContractMembers) {
        this.getContract.userEmployeesContractMembers.forEach((employee) => {
          selectedEmployees.push({
            position: `${checkField(employee?.role?.name)}: `,
            name: getSurnameAndInitials(employee?.user?.fullName),
          });
        });
      }
      return selectedEmployees;
    },
    xmlContractBarStyle() {
      return { top: `${this.heightNavigationBar - 16}px` };
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getPatternName']),
  },
  methods: {
    allClosed(allClosed) {
      this.isDisplayCloseAll = !allClosed;
    },
  },
};
</script>

<style lang="scss" scoped>
.xml-contract {
  &-bar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;

    margin-bottom: 30px;
    padding: 5px 20px 5px 20px;
    width: 100%;

    position: sticky;
    top: 20px;
    background-color: #ffffff;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 450px;
    }

    & .button {
      border-radius: 24px;
      border: 1px solid #2F82DF;
      background: inherit;
      padding: 0 20px;
      height: 40px;

      &--grey {
        border: 1px solid #DEE2E6;
      }

      &-inner {
        display: flex;
        gap: 10px;
      }

      &__text {
        display: flex;
        align-self: center;
      }
    }

  }
;

  &-bar-left, &-bar-right {
    display: flex;
    gap: 30px;
    width: 50%;
  }
;

  &-bar-right {
    display: flex;
    justify-content: flex-end;
  }
;

  &__table {
    margin-right: 10px;
  }
;
}
</style>

