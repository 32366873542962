<template>
  <div class="substation-node" :style="styleNode">
    <template v-if="!node.isEmpty">
      <div
          :id="'node-top-' + node.id"
          :class="{
                  ['substation-node-top']: true,
                  ['substation-node-top--selected']: selected === 'top'
                }"
          @click="clickNode(node, 'top')"
      >
        <contact-page v-if="node.type === 'usagePoint'" class="substation-node-top-icon-left"/>
        <substation-icon v-else class="substation-node-top-icon-left"/>
        <span>{{ node.name }}&nbsp;</span>
        <info-gray class="substation-node-top-icon-right"/>
      </div>
      <div
          :id="'node-bottom-' + node.id"
          :class="{
                  ['substation-node-bottom']: true,
                  ['substation-node-bottom--empty']: !node.address,
                }"
          @click="clickNode(node, 'bottom')"
      >
        <span v-if="node.address">{{ node.address }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import InfoGray from 'atoms/icons/basic/InfoGray.vue';
import SubstationIcon from 'atoms/icons/point/Substation.vue';
import ContactPage from 'atoms/icons/user/ContactPage.vue';

export default {
  name: 'SubstationNode',
  components: {
    SubstationIcon,
    InfoGray,
    ContactPage,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    styleNode: {
      type: Object,
      required: true,
    },
    grouping: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickNode(node, target) {
      this.$emit('clickNode', node, target);
    },
  },
};

</script>

<style lang="scss" scoped>

.substation-node {
  position: relative;

  &-top {
    border: 1px solid #2F82DF;
    padding: 12px 40px 12px 50px;
    border-radius: 24px;
    background-color: #FFFFFF;
    position: relative;
    z-index: 2;
    color: #4C4E51;
    cursor: pointer;

    &-icon-right {
      position: absolute;
      top: 9px;
      right: 18px;
    }

    &-icon-left {
      position: absolute;
      top: 7px;
      left: 18px;
    }

    svg {
      fill: #C1C4C7;
    }

    &--selected, &:hover {
      background-color: #2F82DF;
      color: #FFFFFF;
      cursor: default;

      svg {
        fill: #FFFFFF;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-bottom {
    min-height: 80px;
    padding: 12px 38px 12px 24px;
    border-radius: 24px;
    background-color: #F5F6F6;
    margin-top: -40px;
    position: relative;
    z-index: 1;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: #262729;

    span {
      padding-top: 40px;
    }

    &--empty {
      min-height: 40px;
    }
  }
}

</style>
