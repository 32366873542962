<template>
  <div>
    <p v-if="checkOwner" style="margin-bottom: 16px; color: #71757A">
      * актуальные данные по работникам являются ответственностью компании
    </p>
    <v-table-new
      v-model="transformedData"
      :template="template"
      excel-report-file-name="Сотрудники компании. Приглашения"
      :loading="isLoading"
      @on-cell-click="cellClickHandler"
    />

    <!-- delete user modal begin -->
    <dialog-template v-if="displayDeleteDialog" v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Вы уверены, что хотите отменить приглашение сотрудника
        <span>{{ userForDelete.email }}</span>
        ?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="ДА" @submit="deleteUserFromCompanyHandler(userForDelete.email)"/>
        <submit-task-details :gray="true" title="НЕТ" @submit="displayDeleteDialog = false"/>
      </div>
    </dialog-template>
    <!-- delete user modal end -->
  </div>
</template>

<script>
import { VTableNew } from 'components';
import DialogTemplate from 'templates/DialogTemplate.vue';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';

export default {
  name: 'InvitationsContentBlock',
  components: {
    VTableNew,
    DialogTemplate,
    SubmitTaskDetails,
  },
  props: {
    mode: { type: String, default: 'accepted' },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayDeleteDialog: false,
      userForDelete: null,
      isModalVisible: false,
      isModalCheck: false,
      user: null,
      isUserOwner: null,
      isCurrentUser: null,
      selectedSubDivision: '',
      selectedRole: '',
      selectedCheckRole: '',
      selectedRoleId: '',
      xml: '',
      isWaitingRoleSubmit: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Почта',
            key: 'email',
            filter: {},
            sort: {},
          },
          {
            label: 'Подразделение',
            key: 'subdivision',
            sort: {},
          },
          {
            label: 'Должность',
            key: 'roleTransform',
            type: 'roleModal',
            sort: {},
            filter: {
              type: 'select', values: this.getRoles.map((item) => ({ label: item.name, value: item.name })),
            },
          },
          {
            key: 'crossDelete',
            type: 'crossDelete',
            customCheck: this.mode === 'accepted',
          },
        ],
      };
    },
    transformedData() {
      if (this.mode === 'accepted' && !this.getAcceptedInvitations) return [];
      if (this.mode === 'notAccepted' && !this.getNotAcceptedInvitations) return [];

      let invitations;
      if (this.mode === 'accepted') invitations = this.getAcceptedInvitations;
      else invitations = this.getNotAcceptedInvitations;

      return invitations.map((it) => {
        const role = this.getRoles.find((role) => role.id === it.roleId);

        return {
          ...it,
          roleTransform: role?.name,
          subdivision: role?.subdivision?.name ?? 'Без подразделения',
          email: it.email,
        };
      }).sort((first, second) => first.email.localeCompare(second.email));
    },
    userName() {
      if (!this.user) {
        return '';
      }
      if (!this.user.fullName)
        return this.user.email;
      const patronymic =
        this.user?.fullName?.patronymic
          ? this.user?.fullName?.patronymic
          : '';
      return `${this.user?.fullName?.name} ${this.user?.fullName?.surname} ${patronymic}`;
    },
    roles() {
      return this.getRoles.filter((it) => !it.isCompanyOwner);
    },
    disabled() {
      return this.isWaitingRoleSubmit || this.isUserOwner || this.isCurrentUser || this.selectedRole === this.selectedCheckRole;
    },
    checkOwner() {
      return this.getAuthorization.authorization.role.isCompanyOwner;
    },
    ...mapGetters('employees', ['getEmployees', 'getRoles', 'getAcceptedInvitations', 'getNotAcceptedInvitations']),
    ...mapGetters('user', ['getAccount', 'getUser', 'getAuthorization']),
  },
  methods: {
    cellClickHandler(cell) {
      console.log(cell);
      switch (cell.key) {
        case 'crossDelete': {
          this.userForDelete = cell.row;
          this.displayDeleteDialog = true;
          break;
        }
      }
    },
    modalClose() {
      if (this.isModalVisible && !this.isModalCheck) {
        this.isModalCheck = true;
        return;
      }
      this.isModalVisible = false;
      this.isModalCheck = false;
    },
    async deleteUserFromCompanyHandler(email) {
      this.displayDeleteDialog = false;

      this.xml = generateXml.RevokeUserInvitationApplication(email, new Date());
      console.log('XML', this.xml);
      try {
        await this.documentUpload({
          xml: this.xml,
        });
        await this.loadInvitationsFromServer();
      } catch (error) {
        console.log(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      }
    },
    ...mapMutations('employees', ['SET_EMPLOYEES', 'SET_ROLES']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('employees', ['loadInvitationsFromServer']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" module>
.deleteText {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #4C4E51;
  }

  &__info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4C4E51;

    span {
      font-weight: bold;
    }
  }

  &__input-group {
    display: flex;
    gap: 16px;

    &__input {
      &__title {
        margin-bottom: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #71757A;
      }

      &__select {
        position: relative;
        display: inline-block;
        width: 100%;

        &:after {
          content: url('~icons/basic/arrow_down_gray16x10.svg');
          position: absolute;
          right: 10px;
          top: 14px;
          pointer-events: none;
          box-sizing: border-box;
        }

        select {
          min-width: 240px;
          max-width: 240px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 28px 10px 8px;
          border: 1px solid #DEE2E6;
          appearance: none;
          cursor: pointer;
          outline: none;

          color: #000000;

          &:disabled {
            color: #000000;
          }
        }
      }
    }
  }

  &__btn-group {
    text-align: center;
  }
}
</style>
