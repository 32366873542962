<template>
  <div class="tasks-filter">
    <div class="tasks-filter-block">
      <v-input v-model="filter.name" :placeholder="getSearchPlaceholderByUserType"
               class="tasks-filter-block__search-input"/>

      <div class="tasks-filter-block__search-button" @click="search(false)">
        <img src="~@/assets/icons/search/loupe.svg"/>
      </div>

      <div class="tasks-filter-block__clear-button" @click="clear">
        <img src="~@/assets/icons/search/cross.svg"/>
      </div>

      <v-divider class="tasks-filter-block__divider" direction="vertical"/>

      <template v-if="!isIndividualConsumer">
        <edit-mode-button
          :is-true="filter.onlyOn"
          :title="onlyOnOptions.filter(item => item.value === filter.onlyOnSelected)[0].label"
          background="rgba(255, 173, 128, 0.2)"
          class="tasks-filter-block__select-only-on"
          select
          @click="onlyOnChange"
        >
          <img class="tasks-filter-block__cogwheel" src="~@/assets/icons/edit-button/cogwheel.svg"
               @click="changeOnlyOnSelected"/>
          <v-menu v-if="showOnlyOnMenu" :no-wrap-item="true" :options="onlyOnOptions" :stop-propagation="true"
                  @click="setSelectedOnlyOnOption"/>
        </edit-mode-button>

        <v-divider class="tasks-filter-block__divider" direction="vertical"/>
      </template>

      <div class="tasks-filter-block__view-mode">
        <img src="~@/assets/icons/basic/view-mode.svg"/>
      </div>

      <v-divider class="tasks-filter-block__divider" direction="vertical"/>

      <div class="tasks-filter-block__archive">
        <span>Архив</span>
        <img src="~@/assets/icons/navigation/chevron_right.svg"/>
      </div>
    </div>

    <v-divider class="tasks-filter__divider"/>

    <div style="display: flex;flex-wrap: wrap">
      <div class="tasks-filter-block">
        <div
          class="tasks-filter-block__counter tasks-filter-block__counter--in-work tasks-filter-block__counter--margin-right">
          <span><b>{{ countInWork }}</b>&nbsp;|&nbsp;В работе</span>
        </div>
        <div
          class="tasks-filter-block__counter tasks-filter-block__counter--new tasks-filter-block__counter--margin-right">
          <span><b>{{ countNew }}</b>&nbsp;|&nbsp;Новые</span>
        </div>
        <div class="tasks-filter-block__counter tasks-filter-block__counter--completed">
          <span><b>{{ countCompleted }}</b>&nbsp;|&nbsp;Выполненные</span>
        </div>

        <v-divider
          class="tasks-filter-block__divider"
          direction="vertical"
        />

        <!--        <template v-if="!isIndividualConsumer">-->
        <!--          <div class="tasks-filter-block__counter tasks-filter-block__counter&#45;&#45;new tasks-filter-block__counter&#45;&#45;active">-->
        <!--            <img src="~@/assets/icons/basic/plus.svg" /><span>Создать задачу</span>-->
        <!--          </div>-->

        <!--          <v-divider direction="vertical" class="tasks-filter-block__divider"/>-->
        <!--        </template>-->


      </div>

      <div
        :class="{['tasks-filter-block__group-by']: true, ['tasks-filter-block__group-by--is-individual-consumer']: isIndividualConsumer}">

        <v-select
          v-model="filter.groupBy"
          :options="groupLabelValueList"
          class="tasks-filter-block__group-by-select-group"
        >
          <img class="tasks-filter-block__group-by-select-group-icon" src="~@/assets/icons/select/group-by.svg"/>
        </v-select>

        <div
          :class="{
            ['tasks-filter-block__group-by-groups']: true,
            ['tasks-filter-block__group-by-groups--is-individual-consumer']: isIndividualConsumer
          }"
        >
          <edit-mode-button
            v-for="(group, index) in groupList[filter.groupBy]" :key="index"
            :is-true="filter.displayGroups[group.id] ?? true"
            :title="group.title"
            class="tasks-filter-block__group-by-groups-group-button"
            select-all
            white-icon
            @click="(isChecked) => clickGroup(isChecked, group.id)"
          />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { EditModeButton, VDivider, VInput, VMenu, VSelect } from '@/components';
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';

const initialFilters = {
  name: '',
  sideSelected: '1',
  onlyOn: false,
  onlyOnSelected: 'Исправить',
  groupBy: 'type-document',
  prevGroupBy: 'type-document',
  displayGroups: {},
};

export default {
  name: 'FilterBlock',
  components: {
    VSelect,
    VDivider,
    VInput,
    EditModeButton,
    VMenu,
  },
  props: {
    countInWork: {
      type: Number,
      default: 0,
    },
    countNew: {
      type: Number,
      default: 0,
    },
    countCompleted: {
      type: Number,
      default: 0,
    },
    isIndividualConsumer: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initFilter();
  },
  data() {
    return {
      showOnlyOnMenu: false,
      onlyOnOptions: [
        { label: 'Показывать только на исправление', value: 'Исправить' },
        { label: 'Показывать только на согласование', value: 'Согласовать' },
        { label: 'Показывать только на подписание', value: 'Подписать' },
      ],
      groupLabelValueList: [
        {
          label: 'Группировка по типу документа', value: 'type-document',
        },
        {
          label: 'Группировка по статусу', value: 'status',
        },
      ],
      groupList: this.initGroupList(),
      filter: initialFilters,
      filterSettingName: 'tasks_page_filters',
      isInitFilter: false,
    };
  },
  computed: {
    getSearchPlaceholderByUserType() {
      if (this.isIndividualConsumer) {
        return 'Найти заявку по названию или ключевому слову';
      }

      return 'Найти задачу по названию или ключевому слову';
    },
  },
  watch: {
    'filter.groupBy': {
      immediate: true,
      handler(val) {
        this.$emit('groupList', this.groupList[val], this.filter.displayGroups);

        if (this.isInitFilter) {
          this.saveFilters();
        }
      },
    },
    groupList: {
      immediate: true,
      handler(val) {
        this.$emit('groupList', val[this.filter.groupBy], this.filter.displayGroups);
      },
    },
  },
  methods: {
    clear() {
      this.filter.sideSelected = '1';
      this.filter.name = '';

      this.$emit('filter', this.filter.sideSelected, this.filter.name);

      this.saveFilters();
    },
    search(isInit) {
      this.filter.name = this.filter.name.trim().replace(/\s\s+/g, ' ');

      this.$emit('filter', this.filter.sideSelected, this.filter.name);

      if (!isInit) {
        this.saveFilters();
      }
    },
    clickGroup(isChecked, groupId) {
      Vue.set(this.filter.displayGroups, groupId, isChecked);
      this.$emit('groupList', this.groupList[this.filter.groupBy], this.filter.displayGroups);

      this.saveFilters();
    },
    onlyOnChange(isChecked) {
      if (isChecked !== this.filter.onlyOn) {
        this.filter.onlyOn = isChecked;

        if (isChecked) {
          this.filter.prevGroupBy = this.filter.groupBy;
          this.filter.groupBy = 'status';
        } else {
          this.filter.groupBy = this.filter.prevGroupBy;
        }
      }

      this.groupList.status.forEach((item) => {
        Vue.set(this.filter.displayGroups, item.id, !isChecked ? true : item.title === this.filter.onlyOnSelected);
      });

      this.$emit('isOnlyOnChange', isChecked);

      this.saveFilters();
    },
    setSelectedOnlyOnOption(value) {
      this.filter.onlyOnSelected = value;
      this.showOnlyOnMenu = false;
      this.onlyOnChange(this.filter.onlyOn);
    },
    changeOnlyOnSelected(event) {
      event.stopPropagation();
      this.showOnlyOnMenu = true;
    },
    initGroupList() {
      if (this.isIndividualConsumer) {
        return {
          'type-document': [
            {
              title: 'Акты', display: true, id: 'type-document_2',
              filter: (item) => ['INTEGRAL_ACT', 'LOSS_ACT', 'SERVICE_ACT'].includes(item.dataDocumentType),
            },
            {
              title: 'Заявка на технологическое присоединение', display: true, id: 'type-document_3',
              filter: (item) => ['individualCustomerConnection', 'individualCustomerConnectionRetail', 'microgenerationConnectionUtility',
                'microgenerationConnectionRetail'].includes(item.workflowType),
            },
          ],
          status: [
            {
              title: 'На рассмотрении', display: true, id: 'status_1',
              filter: (item) => item.status.toUpperCase().indexOf('На рассмотрении'.toUpperCase()) !== -1,
            },
            {
              title: 'Оплатите счёт', display: true, id: 'status_2',
              filter: (item) => item.status.toUpperCase().indexOf('Оплатите'.toUpperCase()) !== -1,
            },
            {
              title: 'Заключение договора ДЭС/ДКП', display: true, id: 'status_3',
              filter: (item) => item.status.toUpperCase().indexOf('Заключите'.toUpperCase()) !== -1,
            },
            {
              title: 'Выполнение', display: true, id: 'status_4',
              filter: (item) => item.status.toUpperCase().indexOf('Выполнение'.toUpperCase()) !== -1,
            },
          ],
        };
      }

      return {
        'type-document': [
          {
            title: 'Договоры', display: true, id: 'type-document_1',
            filter: (item) => item.workflowType === 'contractAdditionUpload' || item.dataDocumentType === 'ELECTRICITY_TRANSMISSION_CONTRACT' ||
                item.workflowType === 'consumerAdditionAfterContractValidation' || item.workflowType === 'supplyContractInformationAfterContractValidation',
          },
          {
            title: 'Акты', display: true, id: 'type-document_2',
            filter: (item) => ['INTEGRAL_ACT', 'LOSS_ACT', 'SERVICE_ACT'].includes(item.dataDocumentType) ||
                item.workflowType === 'connectionCertificateAfterContractValidation' || item.workflowType === 'inspectionReportAfterContractValidation',
          },
          {
            title: 'Заявка на технологическое присоединение', display: true, id: 'type-document_3',
            filter: (item) => ['individualCustomerConnection', 'individualCustomerConnectionRetail', 'microgenerationConnectionUtility',
              'microgenerationConnectionRetail'].includes(item.workflowType),
          },
          {
            title: 'Балансы', display: true, id: 'type-document_4',
            filter: (item) => item.dataDocumentType === 'ELECTRICITY_BALANCE',
          },
          {
            title: 'Учёт ОДК', display: true, id: 'type-document_5',
            filter: (item) => item.workflowType === 'odk',
          },
        ],
        status: [
          {
            title: 'Подписать', display: true, id: 'status_1',
            filter: (item) => item.status.toUpperCase() === 'Подписать'.toUpperCase(),
          },
          {
            title: 'Согласовать', display: true, id: 'status_2',
            filter: (item) => item.status.toUpperCase() === 'Согласовать'.toUpperCase(),
          },
          {
            title: 'Исправить', display: true, id: 'status_3',
            filter: (item) => item.status.toUpperCase() === 'Исправить'.toUpperCase(),
          },
          {
            title: 'Загрузить', display: true, id: 'status_4',
            filter: (item) => item.status.toUpperCase() === 'Загрузить'.toUpperCase(),
          },
          {
            title: 'Выполнено', display: true, id: 'status_5',
            filter: (item) => item.status.toUpperCase() === 'Подписан'.toUpperCase() ||
              item.status.toUpperCase() === 'Согласован'.toUpperCase() ||
              item.status.toUpperCase() === 'Загружено'.toUpperCase() ||
              item.status.toUpperCase() === 'Обновлено'.toUpperCase() ||
              item.status.toUpperCase() === 'Выполнено'.toUpperCase(),
          },
          {
            title: 'Подготовить', display: true, id: 'status_6',
            filter: (item) => item.status.toUpperCase().indexOf('Подготовьте'.toUpperCase()) !== -1,
          },
        ],
      };
    },
    initFilter() {
      this.filter = this.getTableFilterByName()(this.filterSettingName, initialFilters);

      if (this.filter.name !== initialFilters.name) {
        this.search(true);
      }

      if (this.filter.groupBy !== initialFilters.groupBy || Object.keys(this.filter.displayGroups).some((group) => this.filter.displayGroups[group] === false)) {
        this.$emit('groupList', this.groupList[this.filter.groupBy], this.filter.displayGroups);
      }

      if (this.filter.onlyOn) {
        this.$emit('isOnlyOnChange', true);
      }

      this.isInitFilter = true;
    },
    saveFilters() {
      this.SAVE_TABLE_FILTERS({
        name: this.filterSettingName,
        filters: this.filter,
      });
    },
    ...mapMutations('user', ['SAVE_TABLE_FILTERS']),
    ...mapGetters('user', ['getTableFilterByName']),
  },
};
</script>

<style lang="scss" scoped>
.tasks-filter {
  margin-bottom: 38px;

  &__divider {
    margin-top: 24px;
    margin-bottom: 28px;
  }
}

.tasks-filter-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__search-button, &__clear-button, &__view-mode {
    border-radius: 24px;
    width: 38px;
    height: 38px;
    cursor: pointer;
    margin-top: 8px;
  }

  &__search-button {
    padding: 10px;
    background-color: #2F82DF;
    margin-right: 14px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__search-button:hover {
    background-color: #C1C4C7;
  }

  &__clear-button {
    padding: 11px;
    border: 1px solid #2F82DF;

    img {
      width: 14px;
      height: 14px;
    }
  }

  &__clear-button:hover {
    background-color: #ea8080;
    border: 1px solid #ea8080;
  }

  &__view-mode {
    padding: 6px;
    border: 1px solid #C1C4C7;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__view-mode:hover {
    background-color: #C1C4C7;
  }

  &__select-side, &__search-input {
    width: 420px;
    margin-top: 8px;
  }

  &__search-input {
    margin-right: 24px;
  }

  &__divider {
    width: 1px;
    height: 32px;
    margin: 8px 27px 0 27px;
  }

  &__select-only-on {
    margin-top: 8px;
    position: relative;
  }

  &__cogwheel {
    margin-left: 7px;
  }

  &__archive {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 88px;
    margin-top: 8px;

    background: #F5F6F6;
    border-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
  }

  &__archive:hover {
    background: #dee2e6;
  }

  &__counter {
    display: flex;
    align-items: center;

    border-radius: 16px;
    padding: 13px 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 8px;

    img {
      margin-right: 16px;
    }

    &--in-work {
      background: #2F82DF;
    }

    &--new {
      background: #13CE5E;
    }

    &--completed {
      background: #C1C4C7;
    }

    &--active {
      cursor: pointer;
    }

    &--active:hover {
      background: #C1C4C7;
    }

    &--margin-right {
      margin-right: 16px;
    }
  }


  &__group-by {
    max-width: 1090px;
    display: flex;
    align-items: center;

    &-select-group {
      width: 280px;
      margin-right: 28px;
      margin-top: 8px;

      &-icon {
        margin-right: 10px;
      }
    }

    &--is-individual-consumer {
      max-width: 1150px;
    }

    &-groups {
      max-width: 810px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-group-button {
        height: 40px;
        margin-right: 16px;
        margin-top: 8px;
      }

      &--is-individual-consumer {
        max-width: 870px;
      }
    }
  }


}
</style>
