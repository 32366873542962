import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  employees: [],
  internalEmployees: [],
  roles: [],
  serverInvitations: [],
  notAcceptedInvitations: [],
  acceptedInvitations: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
