import store from '@/store/index.js';
import { checkField } from '@/utils/common';

export class Employees {
  constructor(isCompanyMode) {
    this.employees = [];
    this.isSupplierOnPlatform = false;
    this.isCompanyMode = isCompanyMode;
  }

  setEmployees(employees) {
    this.employees = employees;
  }

  setSupplierOnPlatform(isSupplierOnPlatform) {
    this.isSupplierOnPlatform = isSupplierOnPlatform;
  }

  getEmployees() {
    return this.employees;
  }

  getSupplierOnPlatform() {
    return this.isSupplierOnPlatform;
  }

  async fetchSupplierEmployeesByInnAndPermission(inn, permission) {
    // TODO: remove vuex call fetch after create api layer
    let supplier;
    try {
      if (this.isCompanyMode) {
        supplier = await store.dispatch('employees/getCompanyEmployeesByInnAndPermissions', {
          inn: inn,
          permissions: permission ? [permission] : [],
        });
      } else {

        const { UTILITY, RETAILER } = await store.dispatch('employees/getSupplierEmployeesByInnAndPermissions', {
          inn: inn,
          permissions: permission ? [permission] : [],
        });
        [supplier] = [...UTILITY, ...RETAILER];
      }
      if (!supplier.account?.authorizations) {
        this.setEmployees([]);
        throw new Error('Не удалось получить сотрудников c сервера');
      }
      if (!supplier.onPlatform && !this.isCompanyMode) {
        return;
      }
      this.setSupplierOnPlatform(supplier.onPlatform || this.isCompanyMode);

      const formatedEmployees = this.formatAuthorizationsEmployees(supplier.account.authorizations, permission);
      this.setEmployees(formatedEmployees);

    } catch (e) {
      this.setEmployees([]);
      throw new Error('Не удалось получить сотрудников c сервера');
    }
  }

  // TODO: make or note private method
  formatAuthorizationsEmployees(authorizations, permission) {
    // TODO: добавить метод, который перемещает гендира на нулевый индекс в массиве сотрудников

    const checkEmployeePowerOfAttorney = (employee) => {
      if (employee.powerOfAttorney.length === 0 && !employee?.role?.isCompanyOwner) {
        return false;
      }
      return true;
    };

    const checkFullName = (employee) => {
      const isNameEmpty = employee.fullName.name === '';
      const isSurnameEmpty = employee.fullName.surname === '';
      const isPatronymicEmpty = employee.fullName.patronymic === '';

      if (isNameEmpty && isSurnameEmpty && isPatronymicEmpty) {
        return false;
      }

      return true;
    };

    const filterPowerOfAttorneys = (employee) => {
      if (permission) {
        return employee.powerOfAttorney.filter((poa) => poa.permissions.some((poaPermission) => poaPermission.code === permission));
      }
      return employee.powerOfAttorney;
    };

    const formatedEmployees = [];

    authorizations.forEach((employee) => {
      const formatedEmployee = {
        id: employee.id,
        accountId: employee?.account?.id,
        userId: employee?.user?.id,
        position: checkField(employee?.role?.name),
        fullName: {
          name: checkField(employee?.user?.fullName?.name),
          patronymic: checkField(employee?.user?.fullName?.patronymic),
          surname: checkField(employee?.user?.fullName?.surname),
        },
        fullNameGenitiveCase: {
          name: checkField(employee?.user?.fullNameGenitiveCase?.name),
          patronymic: checkField(employee?.user?.fullNameGenitiveCase?.patronymic),
          surname: checkField(employee?.user?.fullNameGenitiveCase?.surname),
        },
        role: {
          id: employee?.role?.id,
          name: employee?.role?.name,
          isCompanyOwner: employee?.role?.isCompanyOwner,
        },
        powerOfAttorney: filterPowerOfAttorneys(employee),
        permissions: employee.role.permissions,
      };

      if (checkFullName(formatedEmployee) && checkEmployeePowerOfAttorney(formatedEmployee)) {
        formatedEmployees.push((formatedEmployee));
      }

    });

    return [...formatedEmployees.filter((it) => it.role?.isCompanyOwner), ...formatedEmployees.filter((it) => !it.role?.isCompanyOwner)];
  }

}
