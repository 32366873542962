<template>
  <div v-if="axpPhotos.length">
    <div v-if="mode===axpPhotosCarouselMode.IMAGE">
      <div class="mb-2">
        <v-label>Эталонные фото ОДК</v-label>
      </div>
      <v-carousel class="files-carousel mb-2" height="auto">
        <v-carousel-item v-for="(item, index) in axpPhotos" :key="index">
          <div class="align-center justify-center d-flex">
            <img :src="item.previewUrl" alt="Эталонное фото ОДК" class="files-carousel__image"/>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div v-else>
      <file-input
        ref="photos"
        v-model="axpPhotos"
        :display-images="mode===axpPhotosCarouselMode.FILE_INPUT_WITH_PREVIEW"
        class="mt-2"
        only-view
        title="Эталонные фото ОДК"
      />
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import FileInput from 'components/Processes/components/FileInput.vue';
import { axpPhotosCarouselMode } from 'components/Processes/components/AxpPhotosCarousel/constants';
import { inConstants } from 'lib/component/validators';


export default {
  name: 'AxpPhotosCarousel',
  components: { FileInput },
  props: {
    axpId: {
      type: Number,
      required: false,
    },
    mode: {
      type: String,
      default: axpPhotosCarouselMode.FILE_INPUT_WITH_PREVIEW,
      validator: inConstants(axpPhotosCarouselMode),
    },
  },
  data() {
    return {
      axpPhotos: [],
    };
  },
  computed: {
    axpPhotosCarouselMode() {
      return axpPhotosCarouselMode;
    },
  },
  watch: {
    axpId: {
      immediate: true,
      handler() {
        this.fetchPhotos();
      },
    },
  },
  methods: {
    fetchPhotos() {
      console.log('ff', this.axpId);
      if (!this.axpId) return;
      fetch(`${BASE_URL}/axp/${this.axpId}`).then((response) => response.json()).then((data) => {
        this.axpPhotos = data.photos.map((it) => ({
          fileId: it.id,
          file: null,
          name: it.name,
          previewUrl:
            `${BASE_URL}/api/get/${it.folder}/${it.name}`,
          new: false,
        }));
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
