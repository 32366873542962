<template>
  <v-spoiler summary="Тип учёта">
    <div class="type-accounting">
      <v-select
        v-model="internalValue.pointKind"
        :disabled="!isEditing"
        :options="pointsKindFullList"
        class="type-accounting__item"
        title="Тип учёта *"
      />

      <v-select
        v-model="internalValue.measurementsReadingKind"
        :disabled="!isEditing"
        :options="measurementsReadingKindFullList"
        class="type-accounting__item"
        title="Тип ввода показаний *"
      />
    </div>
    <div class="type-accounting__info">
      <div class="type-accounting__info-kind"><b>Тип учёта</b><br/>Активные (А): 0-расчётный; 7-транзитный;
        8-контрольный.<br/>Реактивные (Р): 5-основной; 6-транзитный.
      </div>
      <div class="type-accounting__info-kind"><b>Тип ввода показаний:</b><br/>А — Автоматически.<br/>Р — Ручной.</div>
    </div>
  </v-spoiler>
</template>

<script>
import { VSelect, VSpoiler } from '@/components';
import { mapGetters } from 'vuex';
import { merge } from 'lodash';

export default {
  name: 'TypeAccounting',
  components: {
    VSelect,
    VSpoiler,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        pointKind: '',
        measurementsReadingKind: '',
      },
      internalValue: {},
      options: [],
    };
  },
  computed: {
    ...mapGetters('dataLists', [
      'pointsKindFullList',
      'measurementsReadingKindFullList',
    ]),
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.type-accounting {
  display: flex;
  gap: 16px;
  margin-top: 48px;

  &__item {
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    top: 0;
    right: -442px;

    @media (max-width: 1400px) {
      flex-direction: row;
      position: relative;
      top: 0;
      right: 0;
      margin-left: -16px;
      width: 848px;
      margin-top: 16px;
      gap: 8px;
    }
  }

  &__info-kind {
    width: 380px;
    padding: 24px 24px;
    background: #F5F6F6;
    border-radius: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0E0F0F;
  }
}
</style>
