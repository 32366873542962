<template>
  <page title="Полезный отпуск по отделениям и потребителям">
    <div class="container">
      <div class="container__temporary-selector">
        <div class="generate">
          <v-date-picker
            v-model="currentDate"
            format="MMMM | YYYY"
            type="month"
          />
          <v-select-new
            :options="consumerNameAndContractNumberList"
            :transform="{ length: 30, slice: 27 }"
            :value="selectedConsumer"
            left
            placeholder="Потребители"
            select-padding="9px 28px 9px 8px;"
            @select="selectConsumer"
          />
          <v-button
            :disabled="currentDate === '' || !consumerId"
            class="generate__button"
            @click="generate"
          >
            Сформировать полезный отпуск по потребителям
          </v-button>
        </div>
      </div>
      <modal-progress-bar
        :is-complete="isProcessComplete"
        :is-modal-visible="isModalVisible"
        @close="isModalVisible = false"
      />
      <v-table-new
        v-model="transformedUsefulVacationDepartmentsAndConsumers"
        :loading="loading"
        :template="template"
        excel-report-file-name="Полезный отпуск по отделениям и потребителям"
      />
    </div>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { ModalProgressBar, Page, VButton, VDatePicker, VSelectNew, VTableNew } from '@/components';
import { formatPeriod } from 'lib/utils/date.js';

import dayjs from 'dayjs';
import formatting from 'lib/utils/formatting';

export default {
  name: 'UsefulVacationDepartmentsAndConsumers',
  components: {
    VSelectNew,
    VTableNew,
    VButton,
    VDatePicker,
    ModalProgressBar,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      consumers: [],
      internalConsumers: [],
      selectedConsumer: '',
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      consumerId: null,
      isModalVisible: false,
      isProcessComplete: false,
      consumerName: '',
      contractNumber: '',
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование потребителя',
            key: 'consumerName',
            thStyle: {
              maxWidth: '200px',
              minWidth: '200px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Номер договора',
            key: 'contractNumber',
            thStyle: {
              maxWidth: '85px',
              minWidth: '85px',
            },
            tdStyle: {
              maxWidth: '85px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Период',
            key: 'period',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Общий расход, кВт * ч',
            key: 'totalConsumption',
            type: 'number',
            thStyle: {
              maxWidth: '85px',
              minWidth: '85px',
            },
            tdStyle: {
              maxWidth: '85px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Общий расход по 4 и 6 ценовой категории, кВт * ч',
            type: 'number',
            key: 'totalConsumption4And6PriceCategories',
            thStyle: {
              maxWidth: '80px',
              minWidth: '80px',
            },
            tdStyle: {
              maxWidth: '80px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Расход мощности по 4 и 6 ценовой категории, МВт',
            type: 'number',
            key: 'powerСonsumption4And6PriceCategories',
            thStyle: {
              maxWidth: '80px',
              minWidth: '80px',
            },
            tdStyle: {
              maxWidth: '80px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
        ],
      };
    },
    transformedUsefulVacationDepartmentsAndConsumers() {
      if (this.consumers.length === 0) {
        return [];
      }

      const usefulVacationDepartmentsAndConsumers = [];

      this.consumers.forEach((it) => {
        if (it.usefulVacations) {
          it.usefulVacations.forEach((usefulVacation) => {
            const usefulVacationForPush = {
              id: usefulVacation.documnetId,
              consumerName: it.consumerName,
              contractNumber: it.contractNumber,
              usefulVacationTitle: usefulVacation.title,
              period: formatPeriod(usefulVacation.month, usefulVacation.year),
              totalConsumption: formatting.numberFormatted(usefulVacation.totalConsumption),
              open: true,
              xmlDownload: true,
              pdfDownload: true,
              document: { documentId: it.documentId, name: usefulVacation.title },
            };
            usefulVacationDepartmentsAndConsumers.push(usefulVacationForPush);
          });
        } else {
          usefulVacationDepartmentsAndConsumers.push({
            id: it.id,
            consumerName: it.consumerName,
            contractNumber: it.contractNumber,
          });
        }
      });

      return usefulVacationDepartmentsAndConsumers;
    },
    consumerNameAndContractNumberList() {
      return this.consumers.map((consumer) => ({
        id: consumer.id,
        consumerName: consumer.consumerName,
        contractNumber: consumer.contractNumber,
        name: `${consumer.consumerName} ${consumer.contractNumber}`,
      }));
    },
  },
  methods: {
    filterConsumers(payload) {
      this.consumers = this.internalConsumers
        .filter((consumer) => (payload.consumerName.length > 0
          ? consumer.consumerName.toUpperCase().match(payload.consumerName.trim().toUpperCase())
          : true))
        .filter((consumer) => (payload.contractNumber.length > 0
          ? consumer.contractNumber.toUpperCase().match(payload.contractNumber.trim().toUpperCase())
          : true));
    },
    search() {
      const payload = {
        consumerName: this.consumerName,
        contractNumber: this.contractNumber,
      };
      this.filterConsumers(payload);
    },
    clear() {
      this.consumerName = '';
      this.contractNumber = '';
      this.search();
    },
    selectConsumer(option) {
      this.selectedConsumer = option.name;
      this.consumerId = option.id;
    },
    async generate() {
      const dateObj = dayjs(this.currentDate, 'MMMM | YYYY');

      try {
        await this.generateUsefulVacation({
          consumerId: this.consumerId,
          month: dateObj.month() + 1,
          year: dateObj.year(),
        });
        await this.loadData();
      } catch (error) {
        console.log(error);
        const splitError = error.message.split(': ')[2];
        this.setNotification({ message: splitError });
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.consumers = await this.loadConsumers();
        this.internalConsumers = this.consumers;
      } catch (e) {
        console.log('loadConsumers', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['generateUsefulVacation', 'loadDocument']),
    ...mapActions('consumer', ['loadConsumers']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  // TODO: fix this element style
  &__temporary-selector {
    .generate {
      display: flex;
      gap: 5px;

      input {
        border: 1px solid #C1C4C7;
        border-radius: 24px 0 0 24px;
        width: 100%;
      }

      div {
        width: 290px;
      }

      select {
        width: 290px;
        font-size: 18px;
        border-color: #C1C4C7;
      }

      &__button {
        padding: 5px 15px;
        border-radius: 0 24px 24px 0;
        min-width: 345px;
      }

      @media (max-width: 860px){
        flex-direction: column;
        gap: 4px;

        input {
          border-radius: 0;
        }

        &__button {
          width: 100%;
          border-radius: 0;
        }

        select, div {
          width: 100%;
        }
      }
    }
  }

  &__filter {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 16px 0;

    .input-group {
      display: flex;
      gap: 5px;

      span {
        width: 290px;
      }
    }

    .btn-group {
      display: flex;
      gap: 5px;
    }
  }
}
</style>
