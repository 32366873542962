<template>
  <div v-if="visible" :class="$style.modalContainer">
    <div v-custom-click-outside="checkClickOutside" :class="$style['modalContainer__modal']"
         :style="`width: ${contentWidth}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VModal',
  props: {
    visible: { type: Boolean, default: false },
    contentWidth: { type: String, default: 'auto' },
  },
  methods: {
    checkClickOutside() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10070;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  &__modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;;
    padding: 50px 100px;
    border-radius: 16px;
  }
}
</style>
