<template>
  <v-form :class="$style.container" @submit="onSubmit">
      <div :class="$style.blockContent">
        <div class="page-form__form">
          <v-input v-model="UsagePointLossesDocument.ConstLoss"
                   title="Потери постоянные, кВтч"
                   required
                   clearable
                   decimal
                   rules="required"/>
          <br/>
          <v-input v-model="UsagePointLossesDocument.VariableLoss"
                   title="Потери переменные, %"
                   required
                   clearable
                   decimal
                   rules="required|max_value:100"/>
        </div>
      </div>
    <div :class="$style.actionContent">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <v-button type="submit" :disabled="isWaitingSubmit" style="width: 100%">ОТПРАВИТЬ ИНФОРМАЦИЮ</v-button>
    </div>
  </v-form>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { VInput, VForm, VButton } from 'components';

export default {
  name: 'SendUsagePointLossesDocument',
  components: {
    VInput,
    HorizontalProgressBar,
    VForm,
    VButton,
  },
  props: {
    documentId: String,
    actionProps: Object,
  },
  data() {
    return {
      submitError: '',
      isWaitingSubmit: false,
      UsagePointLossesDocument: {
        ConnectionApplicationDocumentId: '',
        ConstLoss: '',
        VariableLoss: '',
      },
    };
  },
  computed: {
    isValid() {
      return (this.UsagePointLossesDocument.VariableLoss && this.UsagePointLossesDocument.VariableLoss >= 0 &&
        this.UsagePointLossesDocument.VariableLoss <= 100 && this.UsagePointLossesDocument.ConstLoss);
    },
  },
  methods: {
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;
      this.UsagePointLossesDocument.ConnectionApplicationDocumentId = this.documentId;
      this.UsagePointLossesDocument.ConstLoss = this.UsagePointLossesDocument.ConstLoss.replaceAll(' ', '');
      const xml = connectionApplicationData.usagePointLossesDocument(this.UsagePointLossesDocument);
      try {
        await this.documentUpload({ xml: xml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$nextTick(() => {
            this.showNotification();
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    showNotification() {
      this.vueShowNotification(
        'ИНФОРМАЦИЯ ПО ПОТЕРЯМ ОТПРАВЛЕНА',
      );
    },
    onClose() {
      this.$emit('close');
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.title
  padding-bottom: 16px
  +base-title

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.blockContent
  margin-bottom: 40px

.submitNote
  margin-top: 40px
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

p.subTitle
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  +medium-spacing

.actionContent
  width: 560px
  margin: 0 auto
</style>
