<template>
  <svg fill="none" :height="height" viewBox="0 0 26 26" :width="width" xmlns="http://www.w3.org/2000/svg">
    <rect :fill="fill" height="24" rx="12" width="24" x="1" y="1"/>
    <g clip-path="url(#clip0_2567_35074)">
      <path
        d="M9.66602 8.91967L13.7371 12.9997L9.66602 17.0797L10.9193 18.333L16.2527 12.9997L10.9193 7.66634L9.66602 8.91967Z"
        fill="#2F82DF"/>
    </g>
    <rect height="24" rx="12" :stroke="stroke" width="24" x="1" y="1"/>
    <defs>
      <clipPath id="clip0_2567_35074">
        <rect fill="white" height="16" transform="matrix(0 -1 1 0 5 21)" width="16"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightCircle',
  props: {
    stroke: { type: String, default: '#C1C4C7' },
    fill: { type: String, default: 'white' },
    height: { type: String, default: '26' },
    width: { type: String, default: '26' },
  },
};
</script>
