import validate from 'lib/utils/validationRules';
import {
  formatDateTimeToXmlFormat,
  formatDateToCustomDateFormat,
  formatDateToXmlDateFormat,
  notNullString,
} from 'lib/utils/date';
import formatting from 'lib/utils/formatting';
import localforage from 'localforage';
import dayjs from 'dayjs';
import store from 'store';
import {
  getCompanyInfo,
  getCompletedTag,
  getContractLifeCycleStatus,
  getContractType,
  getIdentityDocType,
  getValueByLabel,
} from 'utils/xml/tools';
import { TEXT_ELECTRICITY_PURCHASE_CONTRACT_BODY } from '@/mock';
import { maxBy } from 'lodash';

function serializeToString(xml) {
  return (
    new XMLSerializer()
      .serializeToString(xml)
      // TODO fix IE adding NS in attributes
      .replace(RegExp('xmlns:ns\\d="" ', 'gi'), '')
      .replace(RegExp('NS\\d+:', 'gi'), '')
  );
}

function createElement(namespace, name, value) {
  const element = document.createElementNS(namespace, name);
  element.appendChild(document.createTextNode(value));
  return element;
}

function addChildNodes(namespace, parent, nameValueList) {
  let isNotEmptyNodes = false;

  nameValueList.forEach((entry) => {
    if (entry[1] && String(entry[1]).length > 0) {
      isNotEmptyNodes = true;
      parent.appendChild(createElement(namespace, entry[0], entry[1]));
    }
  });

  return isNotEmptyNodes;
}

function addChildNode(namespace, parent, name, value) {
  if (value && String(value).length > 0) {
    parent.appendChild(createElement(namespace, name, value));
  }
}

async function getData(data) {
  if (data) {
    return data;
  }

  try {
    const connectionApplication = await localforage.getItem(
      'ConnectionApplication',
    );
    return JSON.parse(connectionApplication);
  } catch (error) {
    console.log('error connectionApplication.getData');
  }
}

async function saveData(type, val) {
  try {
    let connectionApplication = await localforage.getItem(
      'ConnectionApplication',
    );

    if (validate.isNull(connectionApplication)) {
      connectionApplication = {};
    } else {
      connectionApplication = JSON.parse(connectionApplication);
    }

    connectionApplication[type] = val;

    await save(connectionApplication);
  } catch (error) {
    console.log('error ConnectionApplication.saveData. type, val', type, val);
  }
}

async function save(connectionApplication) {
  try {
    await localforage.setItem(
      'ConnectionApplication',
      JSON.stringify(connectionApplication),
    );
  } catch (error) {
    console.log(
      'error ConnectionApplication.save, data:',
      connectionApplication,
    );
  }
}

async function clearData() {
  try {
    await localforage.removeItem('ConnectionApplication');
  } catch (error) {
    console.log('error ConnectionApplication.clearData');
  }
}

// TODO replace vueComponent for access lists
function dataMapper(data, vueComponent) {
  function getIdentityDocument(Consumer) {
    let documentType = '';
    let document = {};

    if (Consumer.IdentityDocument) {
      if (Consumer.IdentityDocument.Passport) {

        documentType = 'PASSPORT_RF';
        document = Consumer.IdentityDocument.Passport;

      } else if (Consumer.IdentityDocument.ResidencePermit) {

        documentType = 'RESIDENCE_PERMIT_RF';
        document = Consumer.IdentityDocument.ResidencePermit;

      } else if (Consumer.IdentityDocument.BirthCertificate) {

        documentType = 'BIRTH_CERTIFICATE';
        document = Consumer.IdentityDocument.BirthCertificate;

      } else if (Consumer.IdentityDocument.Other) {

        documentType = 'OTHER';
        document = Consumer.IdentityDocument.Other;

      }
    }
    // TODO document.IssueDate прилетал undefined - потратил кучу времени чтобы найти. Этот сайдэффект сохраняет с сегодняшней датой выдачи паспорта у customer любую заявку на тп.
    const issueDate = document.IssueDate ? formatDateToCustomDateFormat(document.IssueDate, 'DD.MM.YYYY') : '';

    const identityDocument = {
      issueDate: issueDate,
      documentIssueDate: issueDate,
      issuer: document.Issuer,
      passportNumber: document.Number,
      documentSeries: document.Series,
      subdivisionCode: document.SubdivisionCode,
      documentType: vueComponent.documentTypeList.filter((item) => item.value === documentType)[0] ?? '',
      DocumentScans: document.DocumentScans,
    };

    if (document.SubdivisionCode && document.SubdivisionCode.length > 0) {
      identityDocument.subdivisionCode = document.SubdivisionCode;
    }

    if (document.Name && document.Name.length > 0) {
      identityDocument.otherDocumentType = document.Name;
    }

    return identityDocument;
  }

  const gridCompany = {
    gridCompanyName: vueComponent.gridCompanyList.reduce((acc, item) => {
      if (item.inn === data.GridCompanyInn) {
        acc = item;
        return acc;
      }
      return acc;
    }, {}),
  };

  let additionalOwner = {
    ownerGroups: [],
  };

  if (data.AdditionalOwners) {
    const ownerGroups = [];
    data.AdditionalOwners.Owner.forEach((item) => {
      ownerGroups.push({
        familyName: item.FamilyName,
        firstName: item.FirstName,
        patronymic: item.Patronymic,
        additionalPhone: item.AdditionalPhone,
        address: item.Address,
        insuranceNumber: item.SNILS,
        phone: item.Phone,
        postcodeAddress: item.Postcode,
        postcodeRegistrationAddress: item.RegistrationPostcode,
        registrationAddress: item.RegistrationAddress,
        ...getIdentityDocument(item),
      });
    });
    additionalOwner = {
      ownerGroups: ownerGroups,
    };
  }

  const additionalInfo = {
    comments: data.AdditionalInfo,
  };
  const connectionCharacteristics = {
    energyConsumerCount: vueComponent.energyConsumerCountList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.EnergyConsumerCount) {
          acc = item;
          return acc;
        }
        return acc;
      },
      {},
    ),

    maximumPowerVoltage: data.ConnectionCharacteristics.MaxPowerVoltageCharacteristics.map((it) => ({
      maxPowerConnection: String(it.MaxPowerVoltageCurrent.MaxPower),
      previousMaxPower: String(it.MaxPowerVoltagePrevious.MaxPower),
      summaryMaxPower: String(it.MaxPowerVoltageSummary.MaxPower),
      currentVoltage: vueComponent.phaseVoltageList.find((summary) => summary.value === Number(it.MaxPowerVoltageCurrent.Voltage)),
      previousPhaseVoltage: String(it.MaxPowerVoltagePrevious.Voltage),
      summaryPhaseValue: vueComponent.phaseVoltageList.find((summary) => summary.value === Number(it.MaxPowerVoltageSummary.Voltage)),
      reliabilityClass: vueComponent.reliabilityClassList.find((reliability) => reliability.value === it.reliabilityClass),
    })),
    reliabilityClass: vueComponent.reliabilityClassList.find((reliability) => reliability.value === data.ConnectionCharacteristics.ReliabilityClass),
    currentMaxPower: String(data.ConnectionCharacteristics.MaxPowerVoltageCharacteristics.reduce((a, b) => a + Number(b.MaxPowerVoltageSummary.MaxPower) ?? 0, 0)),
    previousMaxPower: String(data.ConnectionCharacteristics.MaxPowerVoltageCharacteristics.reduce((a, b) => a + Number(b.MaxPowerVoltagePrevious.MaxPower) ?? 0, 0)),
    phaseVoltage: String(maxBy(data.ConnectionCharacteristics.MaxPowerVoltageCharacteristics, (characteristics) => characteristics.MaxPowerVoltageSummary.Voltage).MaxPowerVoltageSummary.Voltage),
    retailAgreementNumber: notNullString(
      data.SupplierOfLastResort.RetailAgreementNumber,
    ),
    retailAgreementNumberDate: formatDateToCustomDateFormat(
      data.SupplierOfLastResort.RetailAgreementNumberDate,
      'DD.MM.YYYY',
    ),
    workload: vueComponent.workloadList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.WorkLoad) {
          acc = item;
          return acc;
        }
        return acc;
      },
      undefined,
    ),
    supplierOfLastResort: vueComponent.supplierOfLastResortList.reduce(
      (acc, item) => {
        if (item.inn === data.SupplierOfLastResort.INN) {
          acc = item;
          return acc;
        }
        return acc;
      },
      {},
    ),
    tariffCategoryType: vueComponent.tariffCategoryTypeShortList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.TariffCategory) {
          acc = item;
          return acc;
        }
        return acc;
      },
      undefined,
    ),
    tariffZoneType: vueComponent.tariffZoneTypeList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.TariffZone) {
          acc = item;
          return acc;
        }
        return acc;
      },
      undefined,
    ),
    typeAccounting: vueComponent.typeAccountingLabelList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.AccountingKind) {
          acc = item;
          return acc;
        }
        return acc;
      },
      undefined,
    ),
    phaseVoltageForSelect: vueComponent.phaseVoltageList.reduce(
      (acc, item) => {
        if (item.value === data.ConnectionCharacteristics.PhaseVoltage) {
          acc = item;
          return acc;
        }
        return acc;
      },
      undefined,
    ),
    currentPreset: 'custom',
  };

  if (
    data.ConnectionCharacteristics.ReadyConnectionDate &&
    data.ConnectionCharacteristics.ReadyConnectionDate.length > 0
  ) {
    const readyConnectionDate = [];

    data.ConnectionCharacteristics.ReadyConnectionDate.forEach((itemReady) => {
      const reliabilityClass = vueComponent.reliabilityClassList.reduce(
        (acc, item) => {
          if (item.value === itemReady.ReliabilityClass) {
            acc = item;
            return acc;
          }
          return acc;
        },
        {},
      );

      readyConnectionDate.push({
        stage: itemReady.Stage,
        designDate: formatDateToCustomDateFormat(
          itemReady.DesignDate,
          'YYYY-MM',
        ),
        readyDate: formatDateToCustomDateFormat(itemReady.ReadyDate, 'YYYY-MM'),
        maxPower: itemReady.MaxPower,
        reliabilityClass: reliabilityClass,
      });
    });

    connectionCharacteristics.readyConnectionDate = readyConnectionDate;
  }

  const consumerData = data.Consumer ?? data.LegalConsumer;
  const consumerIdentityDocument = getIdentityDocument(consumerData);
  const consumer = {
    additionalOwner: additionalOwner?.ownerGroups?.length > 0,
    additionalPhone: consumerData.AdditionalPhone,
    address: consumerData.Address,
    email: consumerData.Email,
    familyName: consumerData.FamilyName,
    firstName: consumerData.FirstName,
    insuranceNumber: consumerData.SNILS,
    patronymic: consumerData.Patronymic,
    phone: consumerData.Phone,
    postcodeAddress: consumerData.Postcode,
    postcodeRegistrationAddress: consumerData.RegistrationPostcode,
    registrationAddress: consumerData.RegistrationAddress,
    snils: consumerData.SNILS,
    company: {
      name: consumerData.CompanyName,
      address: consumerData.CompanyAddress,
      ogrn: consumerData.OGRN,
      ogrnip: consumerData.OGRNIP,
      inn: consumerData.CompanyInn,
    },
    ...consumerIdentityDocument,
  };

  const confirmationDocument = {
    documentIssueDate: formatDateToCustomDateFormat(
      data.ConfirmationDocument.IssueDate,
      'DD.MM.YYYY',
    ),
    documentNumber: data.ConfirmationDocument.Number,
    documentTitle: vueComponent.confirmationDocumentTitleList.reduce(
      (acc, item) => {
        if (
          item.value === (vueComponent.confirmationDocumentTitleList.map((item) => item.value).includes(data.ConfirmationDocument.Title)
            ? data.ConfirmationDocument.Title
            : 'Иное основание...')
        ) {
          acc = item;
          return acc;
        }
        return acc;
      },
      {},
    ),
    documentName: vueComponent.confirmationDocumentTitleList.includes(
      data.ConfirmationDocument.Title,
    )
      ? ''
      : data.ConfirmationDocument.Title,
  };

  let latitude = '';
  let longitude = '';
  if (
    data.ObjectLocation.Contur?.Point &&
    data.ObjectLocation.Contur?.Point[0]
  ) {
    latitude = data.ObjectLocation.Contur.Point[0].Latitude;
    longitude = data.ObjectLocation.Contur.Point[0].Longitude;
  }
  const objectLocation = {
    additionalInformation: data.ObjectLocation.AdditionalInformation,
    buildingCadastralNumber: data.ObjectLocation.BuildingCadastralNumber,
    landCadastralNumber: data.ObjectLocation.LandCadastralNumber,
    latitude: latitude,
    longitude: longitude,
    objectAddress: data.ObjectLocation.Address,
    objectType: vueComponent.objectTypeList.reduce((acc, item) => {
      if (item.value === data.ConnectionCharacteristics.ObjectType.Type) {
        acc = item;
        return acc;
      }
      return acc;
    }, {}),
    otherObjectType: data.ConnectionCharacteristics.ObjectType.CustomType,
    objectArea: data.ConnectionCharacteristics.ObjectArea,
  };

  function convertDocumentScan(targetArray, documentScans) {
    function pushToTarget(docScan) {
      if (docScan.File) {
        docScan.File.forEach((item) => {
          targetArray.push({
            code: item.value,
            name: item.name,
            type: item.contentType,
          });
        });
      }
    }

    if (documentScans) {
      pushToTarget(documentScans);
    }
  }

  const passportScans = [];

  convertDocumentScan(passportScans, consumerIdentityDocument.DocumentScans);

  const confirmationDocumentScan = [];
  convertDocumentScan(
    confirmationDocumentScan,
    data.ConfirmationDocument.DocumentScans,
  );

  const objectShemeScan = [];
  convertDocumentScan(objectShemeScan, data.ObjectLocation.DocumentScans);

  const powerOfAttorneyScans = [];
  if (data.AdditionalOwners) {
    convertDocumentScan(
      powerOfAttorneyScans,
      data.AdditionalOwners.DocumentScans,
    );
  }

  const otherScans = [];
  convertDocumentScan(otherScans, data.DocumentScans);

  const documentScans = {
    passportScans: passportScans,
    confirmationDocumentScan: confirmationDocumentScan,
    objectShemeScan: objectShemeScan,
    powerOfAttorneyScans: powerOfAttorneyScans,
    otherScans: otherScans,
  };

  let microgenerator = {};

  if (data.Microgenerator && data.Microgenerator.length > 0) {
    const items = [];
    data.Microgenerator.forEach((item) => {
      const DocumentScans = [];
      convertDocumentScan(
        DocumentScans,
        item.MicrogeneratorPassport.DocumentScans,
      );

      items.push({
        MicrogeneratorLocation: item.MicrogeneratorLocation,
        MaxGeneratePower: String(item.MaxGeneratePower),
        MaxGeneratePowerDecimal: item.MaxGeneratePower,
        PowerTransmittedToNetwork: String(item.PowerTransmittedToNetwork),
        Characteristics: item.MicrogeneratorPassport.Characteristics,
        DocumentScans: DocumentScans,
      });
    });

    microgenerator = {
      items: items,
      maxPowerConnection: connectionCharacteristics.maxPowerConnection,
      supplierOfLastResort: connectionCharacteristics.supplierOfLastResort,
      gridCompany: gridCompany,
    };

    if (data.ConnectionCharacteristics.ReadyConnectionDate[0].ReadyDate) {
      microgenerator.ReadyDate = formatDateToCustomDateFormat(
        data.ConnectionCharacteristics.ReadyConnectionDate[0].ReadyDate,
        'DD.MM.YYYY',
      );
    }
  }
  const result = {
    gridCompany: gridCompany,
    additionalOwner: additionalOwner,
    additionalInfo: additionalInfo,
    connectionCharacteristics: connectionCharacteristics,
    consumer: consumer,
    confirmationDocument: confirmationDocument,
    objectLocation: objectLocation,
    documentScans: documentScans,
    microgenerator: microgenerator,
    reasonToConnection: vueComponent.reasonToConnectionList.filter(
      (item) => item.value === data.ReasonToConnection,
    )[0] ?? '',
    type: data.ConnectionApplicationType,
    connectionApplicationWithContractType: vueComponent.connectionApplicationWithContractTypeList.filter(
      (item) => item.value === data.SupplierOfLastResort.ConnectionApplicationWithContractType,
    )[0] ?? '',
  };

  if (data.ServiceLocationId) {
    result.serviceLocationId = data.ServiceLocationId;
  }

  return result;
}

function createDocumentScansElement(namespace, tagName, source) {
  return createAttachmentScansElement(namespace, tagName, source, 'File');
}

function createAttachmentScansElement(
  namespace,
  tagName,
  source,
  AttachmentTagName,
) {
  const scan = document.createElementNS(namespace, tagName);
  source.forEach((docScan) => {
    const file = document.createElementNS(namespace, AttachmentTagName);

    file.setAttribute('xmime:contentType', docScan.type);
    file.setAttribute('name', docScan.name);
    file.appendChild(document.createTextNode(docScan.code));

    scan.appendChild(file);
  });
  return scan;
}

function generateAdmissionCertificateXml(data, isCompanyConsumer) {
  const namespace =
    'http://energochain.ru/XML/documents/admission-certificate/1.1';

  const AdmissionCertificate = document.createElementNS(
    namespace,
    'AdmissionCertificate',
  );

  const nodesAdmissionCertificate = [
    ['Number', data.AdmissionCertificateNumber],
    ['RetailAgreementNumber', data.RetailAgreementNumber],
    ['Date', data.Date],
    ['ConnectionApplicationDocumentId', data.AdmissionCertificateNumber],
  ];

  addChildNodes(namespace, AdmissionCertificate, nodesAdmissionCertificate);

  const consumerNodes = [
    ['Name', data.Consumer.Name],
    ['Inn', data.Consumer.Inn],
    ['AccountId', data.Consumer.AccountId],
  ];

  const Consumer = document.createElementNS(
    namespace,
    'Consumer',
  );

  addChildNodes(namespace, Consumer, consumerNodes);


  const RepresentativeConsumer = document.createElementNS(
    namespace,
    'Representative',
  );

  if (isCompanyConsumer) {
    RepresentativeConsumer.appendChild(
      createElement(
        namespace,
        'UserId',
        data.Consumer.representativeUserId,
      ),
    );

    RepresentativeConsumer.appendChild(
      createElement(
        namespace,
        'Position',
        data.Consumer.representativePosition,
      ),
    );

    RepresentativeConsumer.appendChild(
      createElement(
        namespace,
        'RoleId',
        data.Consumer.representativePositionId,
      ),
    );

    const RepresentativeConsumerName = document.createElementNS(
      namespace,
      'Name',
    );
    const RepresentativeConsumerNameNodes = [
      ['FamilyName', data.Consumer.representativeSurname],
      ['FirstName', data.Consumer.representativeName],
      ['Patronymic', data.Consumer.representativePatronymic],
    ];

    addChildNodes(namespace, RepresentativeConsumerName, RepresentativeConsumerNameNodes);

    const RepresentativeConsumerReason = document.createElementNS(
      namespace,
      'Reason',
    );

    RepresentativeConsumerReason.appendChild(
      createElement(
        namespace,
        'ReasonDocument',
        data.Consumer.CompanyDocument.reasonDocument,
      ),
    );

    RepresentativeConsumer.appendChild(RepresentativeConsumerName);
    RepresentativeConsumer.appendChild(RepresentativeConsumerReason);
  }
  Consumer.appendChild(RepresentativeConsumer);
  AdmissionCertificate.appendChild(Consumer);

  const utilityNodes = [
    ['Name', data.Utility.Name],
    ['Inn', data.Utility.Inn],
    ['AccountId', data.Utility.AccountId],
  ];
  const Utility = document.createElementNS(
    namespace,
    'Utility',
  );
  addChildNodes(namespace, Utility, utilityNodes);

  const Representative = document.createElementNS(
    namespace,
    'Representative',
  );

  Representative.appendChild(
    createElement(
      namespace,
      'UserId',
      data.Utility.representativeUserId,
    ),
  );

  Representative.appendChild(
    createElement(
      namespace,
      'Position',
      data.Utility.representativePosition,
    ),
  );

  Representative.appendChild(
    createElement(
      namespace,
      'RoleId',
      data.Utility.representativePositionId,
    ),
  );


  const RepresentativeName = document.createElementNS(
    namespace,
    'Name',
  );
  const RepresentativeNameNodes = [
    ['FamilyName', data.Utility.representativeSurname],
    ['FirstName', data.Utility.RepresentativeName],
    ['Patronymic', data.Utility.representativePatronymic],
  ];

  addChildNodes(namespace, RepresentativeName, RepresentativeNameNodes);

  const RepresentativeReason = document.createElementNS(
    namespace,
    'Reason',
  );

  RepresentativeReason.appendChild(
    createElement(
      namespace,
      'ReasonDocument',
      data.Utility.CompanyDocument.reasonDocument,
    ),
  );

  Representative.appendChild(RepresentativeName);
  Representative.appendChild(RepresentativeReason);
  Utility.appendChild(Representative);

  AdmissionCertificate.appendChild(Utility);

  const retailerNodes = [
    ['Name', data.Retailer.Name],
    ['Inn', data.Retailer.Inn],
    ['AccountId', data.Retailer.AccountId],
  ];
  const Retailer = document.createElementNS(
    namespace,
    'Retailer',
  );
  addChildNodes(namespace, Retailer, retailerNodes);

  const RepresentativeRetailer = document.createElementNS(
    namespace,
    'Representative',
  );

  RepresentativeRetailer.appendChild(
    createElement(
      namespace,
      'Position',
      data.Retailer.representativePosition,
    ),
  );

  const RepresentativeRetailerName = document.createElementNS(
    namespace,
    'Name',
  );
  const RepresentativeRetailerNameNodes = [
    ['FamilyName', data.Retailer.representativeSurname],
    ['FirstName', data.Retailer.representativeName],
    ['Patronymic', data.Retailer.representativePatronymic],
  ];

  addChildNodes(namespace, RepresentativeRetailerName, RepresentativeRetailerNameNodes);

  const RepresentativeRetailerReason = document.createElementNS(
    namespace,
    'Reason',
  );

  RepresentativeRetailerReason.appendChild(
    createElement(
      namespace,
      'ReasonDocument',
      data.Retailer.CompanyDocument.reasonDocument,
    ),
  );

  RepresentativeRetailer.appendChild(RepresentativeRetailerName);
  RepresentativeRetailer.appendChild(RepresentativeRetailerReason);
  Retailer.appendChild(RepresentativeRetailer);

  AdmissionCertificate.appendChild(Retailer);

  if (data.TariffCategory.value) {
    AdmissionCertificate.appendChild(
      createElement(namespace, 'TariffCategory', data.TariffCategory.value),
    );
  }

  addChildNode(
    namespace,
    AdmissionCertificate,
    'VerificationDate',
    formatDateTimeToXmlFormat(data.VerificationDate),
  );

  // UsagePoint
  const UsagePoint = document.createElementNS(namespace, 'UsagePoint');

  const PowerSupplyCenter = document.createElementNS(namespace, 'PowerSupplyCenter');

  const nodesUsagePointConnection = [
    ['MainSubstation', data.UsagePoint.PowerSupplyCenter.mainSubstation?.fullName],
    ['MainSubstationNumber', data.UsagePoint.PowerSupplyCenter.mainSubstation?.number],
    ['Feeder', data.UsagePoint.PowerSupplyCenter.feeder],
    ['DistributionSubstation', data.UsagePoint.PowerSupplyCenter.distributionSubstation?.number],
    ['Line', data.UsagePoint.PowerSupplyCenter.line],
    ['TransformationSubstation', data.UsagePoint.PowerSupplyCenter.transformationSubstation?.number],
    ['ConsumerLine', data.UsagePoint.PowerSupplyCenter.consumerLine],
  ];

  addChildNodes('http://energochain.ru/XML/documents/topology-common/1.0', PowerSupplyCenter, nodesUsagePointConnection);

  UsagePoint.appendChild(PowerSupplyCenter);

  const nodesUsagePoint = [
    ['Address', data.UsagePoint.Address],
    ['Name', data.UsagePoint.Name],
  ];

  addChildNodes(namespace, UsagePoint, nodesUsagePoint);

  const ObjectType = document.createElementNS(namespace, 'ObjectType');

  ObjectType.appendChild(
    createElement(
      namespace,
      'Type',
      data.UsagePoint.ObjectType,
    ),
  );
  UsagePoint.appendChild(ObjectType);

  AdmissionCertificate.appendChild(UsagePoint);

  function addMeterAndReadings(admissionCertificate, meterData, meterTagName, dataReadings) {
    // Meter
    const Meter = document.createElementNS(namespace, meterTagName);

    const nodesMeter = [
      ['tc:Id', meterData.Id],
      ['tc:Location', meterData.Location],
      ['tc:Owner', meterData.Owner],
      ['tc:Type', meterData.Type],
      ['tc:Serial', meterData.Serial],
      ['tc:Number', meterData.Serial],
      ['tc:AccuracyClass', meterData.AccuracyClass],
      ['tc:RatedCurrent', meterData.RatedCurrent],
      ['tc:NominalVoltage', meterData.NominalVoltage],
      ['tc:IntegerDigits', meterData.IntegerDigits],
      ['tc:FractionDigits', meterData.FractionDigits],
      ['tc:ProductionYear', meterData.ProductionYear],
      ['tc:CalibrationInterval', meterData.CalibrationInterval],
      ['tc:CalibrationDate', formatDateToXmlDateFormat(meterData.CalibrationDate)],
      [
        'tc:NextCalibrationDate',
        formatDateToXmlDateFormat(meterData.NextCalibrationDate),
      ],
    ];
    addChildNodes(namespace, Meter, nodesMeter);

    // Readings
    if (dataReadings.Value?.length > 0) {
      const Readings = document.createElementNS(namespace, 'tc:Readings');

      let isNotEmptyReadings = false;

      dataReadings.Value.forEach((value) => {
        const Value = document.createElementNS(namespace, 'mc:Reading');

        const nodesValue = [
          ['mc:Period', dayjs(value.time, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')],
          ['mc:ReadingT1', value.ReadingT1?.replace(',', '.')],
          ['mc:ReadingT2', value.ReadingT2?.replace(',', '.')],
          ['mc:ReadingT3', value.ReadingT3?.replace(',', '.')],
        ];

        if (addChildNodes(namespace, Value, nodesValue)) {
          Readings.appendChild(Value);

          isNotEmptyReadings = true;
        }
      });

      if (isNotEmptyReadings) {
        Meter.appendChild(Readings);
      }
    }

    admissionCertificate.appendChild(Meter);
  }

  if (!data.InstallableMeter.Serial || data.InstallableMeter.Serial.length === 0) {
    addMeterAndReadings(AdmissionCertificate, data.Meter, 'Meter', data.Readings);
  } else {
    const meterSwap = document.createElementNS(namespace, 'MeterSwap');

    const nodesMeterSwap = [
      ['City', data.City],
      ['Address', data.Address],
      ['Place', data.Place],
    ];
    addChildNodes(namespace, meterSwap, nodesMeterSwap);

    addMeterAndReadings(meterSwap, data.Meter, 'ReplaceableMeter', data.Readings);
    addMeterAndReadings(meterSwap, data.InstallableMeter, 'InstallableMeter', data.InstallableReadings);
    AdmissionCertificate.appendChild(meterSwap);
  }

  if (data.CurrentTransformer.Placement?.length > 0) {
    // CurrentTransformer
    const CurrentTransformer = document.createElementNS(
      namespace,
      'CurrentTransformer',
    );

    const currentTransformerNodes = [
      ['Placement', data.CurrentTransformer.Placement],
      ['Type', data.CurrentTransformer.Type],
      ['Serial', data.CurrentTransformer.Serial],
      ['TransformationRatio', data.CurrentTransformer.TransformationRatio],
      ['AccuracyClass', data.CurrentTransformer.AccuracyClass],
      ['CalibrationInterval', data.CurrentTransformer.CalibrationInterval],
      ['CalibrationDate', formatDateToXmlDateFormat(data.CurrentTransformer.CalibrationDate)],
      [
        'NextCalibrationDate',
        formatDateToXmlDateFormat(data.CurrentTransformer.NextCalibrationDate),
      ],
    ];

    addChildNodes(
      namespace,
      CurrentTransformer,
      currentTransformerNodes,
    );

    data.CurrentTransformer.Phase.forEach((phase) => {
      const Phase = document.createElementNS(namespace, 'Phase');

      const nodesPhase = [
        ['Serial', phase.Serial],
      ];

      if (addChildNodes(namespace, Phase, nodesPhase)) {
        Phase.setAttribute('name', phase.name);

        CurrentTransformer.appendChild(Phase);
      }
    });

    AdmissionCertificate.appendChild(CurrentTransformer);
  }

  if (data.PotentialTransformer.Placement?.length > 0) {
    // PotentialTransformer
    const PotentialTransformer = document.createElementNS(
      namespace,
      'PotentialTransformer',
    );

    const potentialTransformerNodes = [
      ['Placement', data.PotentialTransformer.Placement],
      ['Type', data.PotentialTransformer.Type],
      ['Serial', data.PotentialTransformer.Serial],
      ['TransformationRatio', data.PotentialTransformer.TransformationRatio],
      ['AccuracyClass', data.PotentialTransformer.AccuracyClass],
      ['CalibrationInterval', data.PotentialTransformer.CalibrationInterval],
      ['CalibrationDate', formatDateToXmlDateFormat(data.PotentialTransformer.CalibrationDate)],
      [
        'NextCalibrationDate',
        formatDateToXmlDateFormat(data.PotentialTransformer.NextCalibrationDate),
      ],
    ];

    addChildNodes(
      namespace,
      PotentialTransformer,
      potentialTransformerNodes,
    );


    data.PotentialTransformer.Phase.forEach((phase) => {
      const Phase = document.createElementNS(namespace, 'Phase');

      const nodesPhase = [
        ['Serial', phase.Serial],
      ];

      if (addChildNodes(namespace, Phase, nodesPhase)) {
        Phase.setAttribute('name', phase.name);

        PotentialTransformer.appendChild(Phase);
      }
    });

    AdmissionCertificate.appendChild(PotentialTransformer);
  }

  // Seals
  if (data.Seals.Seal?.length > 0) {
    const Seals = document.createElementNS(namespace, 'Seals');

    let isNotEmptySeals = false;

    data.Seals.Seal.forEach((seal) => {
      const Seal = document.createElementNS(namespace, 'Seal');

      const nodesSeal = [
        ['Placement', seal.Placement],
        ['Number', seal.Number],
        ['OrganizationInn', seal.Organization],
      ];

      if (addChildNodes(namespace, Seal, nodesSeal)) {
        Seals.appendChild(Seal);
        isNotEmptySeals = true;
      }
    });

    if (isNotEmptySeals) {
      AdmissionCertificate.appendChild(Seals);
    }
  }

  if (data.DataAcquisitionEquipment.RemoteTerminalUnit.Location?.length > 0) {
    // DataAcquisitionEquipment
    const DataAcquisitionEquipment = document.createElementNS(
      namespace,
      'DataAcquisitionEquipment',
    );

    const RemoteTerminalUnit = document.createElementNS(
      namespace,
      'RemoteTerminalUnit',
    );

    const nodesRemoteTerminalUnit = [
      ['Location', data.DataAcquisitionEquipment.RemoteTerminalUnit.Location],
      ['Owner', data.DataAcquisitionEquipment.RemoteTerminalUnit.Owner],
      ['Type', data.DataAcquisitionEquipment.RemoteTerminalUnit.Type],
      ['Serial', data.DataAcquisitionEquipment.RemoteTerminalUnit.Serial],
      [
        'CalibrationDate',
        formatDateToXmlDateFormat(
          data.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate,
        ),
      ],
      [
        'NextCalibrationDate',
        formatDateToXmlDateFormat(
          data.DataAcquisitionEquipment.RemoteTerminalUnit.NextCalibrationDate,
        ),
      ],
    ];

    if (addChildNodes(namespace, RemoteTerminalUnit, nodesRemoteTerminalUnit)) {
      DataAcquisitionEquipment.appendChild(RemoteTerminalUnit);
    }

    // Switch
    const Switch = document.createElementNS(namespace, 'Switch');

    const nodesSwitch = [
      ['Location', data.DataAcquisitionEquipment.Switch.Location],
      ['Owner', data.DataAcquisitionEquipment.Switch.Owner],
      ['Type', data.DataAcquisitionEquipment.Switch.Type],
      ['Serial', data.DataAcquisitionEquipment.Switch.Serial],
      [
        'CalibrationDate',
        formatDateToXmlDateFormat(
          data.DataAcquisitionEquipment.Switch.CalibrationDate,
        ),
      ],
      [
        'NextCalibrationDate',
        formatDateToXmlDateFormat(
          data.DataAcquisitionEquipment.Switch.NextCalibrationDate,
        ),
      ],
    ];

    if (addChildNodes(namespace, Switch, nodesSwitch)) {
      DataAcquisitionEquipment.appendChild(Switch);
    }

    if (data.DataAcquisitionEquipment?.Isu) {
      // Isu
      const Isu = document.createElementNS(
        namespace,
        'Isu',
      );

      const nodesIsu = [
        ['Name', data.DataAcquisitionEquipment.Isu.Name],
        ['IsuProtocolName', data.DataAcquisitionEquipment.Isu.IsuProtocolName],
      ];

      if (addChildNodes(namespace, Isu, nodesIsu)) {
        DataAcquisitionEquipment.appendChild(Isu);
      }
    }

    AdmissionCertificate.appendChild(DataAcquisitionEquipment);
  }

  // PhaseMeasurements
  if (data.PhaseMeasurements.Phase?.length > 0) {
    const PhaseMeasurements = document.createElementNS(
      namespace,
      'PhaseMeasurements',
    );

    let isNotEmptyPhaseMeasurements = false;

    data.PhaseMeasurements.Phase.forEach((phase) => {
      const Phase = document.createElementNS(namespace, 'Phase');

      const nodesPhase = [
        ['PrimaryCircuitCurrent', phase.PrimaryCircuitCurrent],
        ['MeasuringCircuitCurrent', phase.MeasuringCircuitCurrent],
        ['PhaseVoltage', phase.PhaseVoltage],
        ['PhaseShift', phase.PhaseShift],
      ];

      if (addChildNodes(namespace, Phase, nodesPhase)) {
        Phase.setAttribute('name', phase.name);

        PhaseMeasurements.appendChild(Phase);
        isNotEmptyPhaseMeasurements = true;
      }
    });

    if (isNotEmptyPhaseMeasurements) {
      AdmissionCertificate.appendChild(PhaseMeasurements);
    }
  }

  // UsedEquipment
  if (data.UsedEquipment.Name && data.UsedEquipment.Name.length > 0) {
    const UsedEquipment = document.createElementNS(namespace, 'UsedEquipment');

    addChildNode(namespace, UsedEquipment, 'Name', data.UsedEquipment.Name);

    AdmissionCertificate.appendChild(UsedEquipment);
  }

  // Comments
  addChildNode(namespace, AdmissionCertificate, 'Comments', data.Comments);

  // Resolution
  addChildNode(namespace, AdmissionCertificate, 'Resolution', data.Resolution);

  // Signers
  if (data.Signers.Signer?.length > 0) {
    const Signers = document.createElementNS(namespace, 'Signers');

    let isNotEmptySigners = false;

    data.Signers.Signer.forEach((signer) => {
      const Signer = document.createElementNS(namespace, 'Signer');

      const nodesSigner = [
        ['Name', signer.Name],
        ['FamilyName', signer.FamilyName],
        ['FirstName', signer.FirstName],
        ['Patronymic', signer.Patronymic],
      ];

      if (addChildNodes(namespace, Signer, nodesSigner)) {
        Signers.appendChild(Signer);
        isNotEmptySigners = true;
      }
    });

    if (isNotEmptySigners) {
      AdmissionCertificate.appendChild(Signers);
    }
  }

  AdmissionCertificate.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );
  AdmissionCertificate.setAttribute(
    'xmlns:xmime',
    'http://www.w3.org/2005/05/xmlmime',
  );
  AdmissionCertificate.setAttribute(
    'xsi:schemaLocation',
    'http://energochain.ru/XML/documents/admission-certificate/1.1 AdmissionCertificate.xsd',
  );

  console.log('AdmissionCertificate xmlDoc', AdmissionCertificate);

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n\n' +
    '<?xml-stylesheet type="text/xsl" href="AdmissionCertificate.xsl" ?>\n' +
    '<AdmissionCertificate\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"\n' +
    'xmlns:mc="http://energochain.ru/XML/documents/measurements-common/1.0"\n' +
    'xmlns="http://energochain.ru/XML/documents/admission-certificate/1.1"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/admission-certificate/1.1 AdmissionCertificate.xsd">\n'}${
      AdmissionCertificate.innerHTML
    }\n</AdmissionCertificate>`
  );
}

function generateConnectionCertificateXml(data, onlyMicrogenerator) {
  const namespace =
    'http://energochain.ru/XML/documents/connection-certificate/1.1';

  const ConnectionCertificate = document.createElementNS(
    namespace,
    'ConnectionCertificate',
  );

  let nodesConnectionCertificate = [
    ['Number', data.Number],
    ['Date', data.Date],
    ['ConnectionApplicationDocumentId', data.Number],
  ];

  addChildNodes(namespace, ConnectionCertificate, nodesConnectionCertificate);

  const consumerNodes = [
    ['Name', data.Consumer.Name],
    ['AccountId', data.Consumer.AccountId],
  ];
  const Consumer = document.createElementNS(
    namespace,
    'Consumer',
  );
  addChildNodes(namespace, Consumer, consumerNodes);
  ConnectionCertificate.appendChild(Consumer);

  const utilityNodes = [
    ['Name', data.Utility.Name],
    ['Inn', data.Utility.Inn],
    ['AccountId', data.Utility.AccountId],
  ];
  const Utility = document.createElementNS(
    namespace,
    'Utility',
  );
  addChildNodes(namespace, Utility, utilityNodes);

  const Representative = document.createElementNS(
    namespace,
    'Representative',
  );

  Representative.appendChild(
    createElement(
      namespace,
      'Position',
      data.Utility.representativePosition,
    ),
  );

  const RepresentativeName = document.createElementNS(
    namespace,
    'Name',
  );
  const RepresentativeNameNodes = [
    ['FamilyName', data.Utility.representativeSurname],
    ['FirstName', data.Utility.RepresentativeName],
    ['Patronymic', data.Utility.representativePatronymic],
  ];

  addChildNodes(namespace, RepresentativeName, RepresentativeNameNodes);

  const RepresentativeReason = document.createElementNS(
    namespace,
    'Reason',
  );

  RepresentativeReason.appendChild(
    createElement(
      namespace,
      'ReasonDocument',
      data.Utility.CompanyDocument.reasonDocument,
    ),
  );

  Representative.appendChild(RepresentativeName);
  Representative.appendChild(RepresentativeReason);
  Utility.appendChild(Representative);

  ConnectionCertificate.appendChild(Utility);

  const retailerNodes = [
    ['Name', data.Retailer.Name],
    ['Inn', data.Retailer.Inn],
    ['AccountId', data.Retailer.AccountId],
  ];
  const Retailer = document.createElementNS(
    namespace,
    'Retailer',
  );
  addChildNodes(namespace, Retailer, retailerNodes);

  const RepresentativeRetailer = document.createElementNS(
    namespace,
    'Representative',
  );

  RepresentativeRetailer.appendChild(
    createElement(
      namespace,
      'Position',
      data.Retailer.representativePosition,
    ),
  );

  const RepresentativeRetailerName = document.createElementNS(
    namespace,
    'Name',
  );
  const RepresentativeRetailerNameNodes = [
    ['FamilyName', data.Retailer.representativeSurname],
    ['FirstName', data.Retailer.representativeName],
    ['Patronymic', data.Retailer.representativePatronymic],
  ];

  addChildNodes(namespace, RepresentativeRetailerName, RepresentativeRetailerNameNodes);

  const RepresentativeRetailerReason = document.createElementNS(
    namespace,
    'Reason',
  );

  RepresentativeRetailerReason.appendChild(
    createElement(
      namespace,
      'ReasonDocument',
      data.Retailer.CompanyDocument.reasonDocument,
    ),
  );

  RepresentativeRetailer.appendChild(RepresentativeRetailerName);
  RepresentativeRetailer.appendChild(RepresentativeRetailerReason);
  Retailer.appendChild(RepresentativeRetailer);

  ConnectionCertificate.appendChild(Retailer);

  const ConnectionAgreement = document.createElementNS(
    namespace,
    'ConnectionAgreement',
  );

  const nodesConnectionAgreement = [
    ['Date', formatDateToXmlDateFormat(data.ConnectionAgreement.Date)],
    ['Number', data.ConnectionAgreement.Number],
    ['Amount', data.ConnectionAgreement.Amount],
  ];

  addChildNodes(namespace, ConnectionAgreement, nodesConnectionAgreement);

  ConnectionCertificate.appendChild(ConnectionAgreement);

  addChildNode(
    namespace,
    ConnectionCertificate,
    'ConnectionAddress',
    data.ConnectionAddress,
  );

  const TechnicalSpecification = document.createElementNS(
    namespace,
    'TechnicalSpecification',
  );

  const nodesTechnicalSpecification = [
    ['Date', formatDateToXmlDateFormat(data.TechnicalSpecification.Date)],
    ['Number', data.TechnicalSpecification.Number],
  ];

  if (
    addChildNodes(
      namespace,
      TechnicalSpecification,
      nodesTechnicalSpecification,
    )
  ) {
    ConnectionCertificate.appendChild(TechnicalSpecification);
  }

  const TechnicalCertificate = document.createElementNS(
    namespace,
    'TechnicalCertificate',
  );

  const nodesTechnicalCertificate = [
    ['Date', formatDateToXmlDateFormat(data.TechnicalSpecification.Date)],
    ['Number', data.TechnicalSpecification.Number],
  ];

  if (
    addChildNodes(namespace, TechnicalCertificate, nodesTechnicalCertificate)
  ) {
    ConnectionCertificate.appendChild(TechnicalCertificate);
  }

  const ConnectionCharacteristics = document.createElementNS(
    namespace,
    'ConnectionCharacteristics',
  );

  const nodesConnectionCharacteristics = [
    ['MaxPower', data.ConnectionCharacteristics.MaxPower],
    ['NewMaxPower', data.ConnectionCharacteristics.NewMaxPower],
    ['ReliabilityClass', data.ConnectionCharacteristics.ReliabilityClass],
    ['ConstLoss', data.ConnectionCharacteristics.ConstLoss.replaceAll(' ', '')],
    ['VariableLoss', data.ConnectionCharacteristics.VariableLoss],
  ];

  if (onlyMicrogenerator) {
    ConnectionCharacteristics.appendChild(
      createElement(
        namespace,
        'MicrogenerationMaxPower',
        data.ConnectionCharacteristics.MicrogenerationMaxPower,
      ),
    );
  }

  addChildNodes(
    namespace,
    ConnectionCharacteristics,
    nodesConnectionCharacteristics,
  );

  ConnectionCertificate.appendChild(ConnectionCharacteristics);

  // ConnectionPoints
  if (data.ConnectionPoints.ConnectionPoint.length > 0) {
    const ConnectionPoints = document.createElementNS(
      namespace,
      'ConnectionPoints',
    );

    let isNotEmptyConnectionPoints = false;

    data.ConnectionPoints.ConnectionPoint.forEach((point) => {
      const ConnectionPoint = document.createElementNS(
        namespace,
        'ConnectionPoint',
      );

      const PowerSupplyCenter = document.createElementNS(namespace, 'PowerSupplyCenter');

      const nodesUsagePointConnection = [
        ['MainSubstation', point.PowerSupplyCenter.mainSubstation.fullName],
        ['MainSubstationNumber', point.PowerSupplyCenter.mainSubstation.number],
        ['Feeder', point.PowerSupplyCenter.feeder],
        ['DistributionSubstation', point.PowerSupplyCenter.distributionSubstation?.number],
        ['Line', point.PowerSupplyCenter.line],
        ['TransformationSubstation', point.PowerSupplyCenter.transformationSubstation?.number],
        ['ConsumerLine', point.PowerSupplyCenter.consumerLine],
      ];

      addChildNodes('http://energochain.ru/XML/documents/topology-common/1.0', PowerSupplyCenter, nodesUsagePointConnection);

      ConnectionPoint.appendChild(PowerSupplyCenter);

      const nodesConnectionPoint = [
        ['Number', point.Number],
        ['Description', point.Description],
        ['VoltageLevel', point.VoltageLevel],
        ['MaxPower', point.MaxPower],
        ['TotalTransformerPowerRating', point.TotalTransformerPowerRating],
        ['ReactivePowerFactor', point.ReactivePowerFactor],
      ];

      if (onlyMicrogenerator) {
        nodesConnectionPoint.push([
          'MicrogenerationMaxPower',
          point.MicrogenerationMaxPower,
        ]);
      }

      if (addChildNodes(namespace, ConnectionPoint, nodesConnectionPoint)) {
        ConnectionPoints.appendChild(ConnectionPoint);
        isNotEmptyConnectionPoints = true;
      }
    });

    if (isNotEmptyConnectionPoints) {
      ConnectionCertificate.appendChild(ConnectionPoints);
    }
  }

  nodesConnectionCertificate = [
    ['BalanceAffiliationBoundary', data.BalanceAffiliationBoundary],
    [
      'OperationalResponsibilityBoundary',
      data.OperationalResponsibilityBoundary,
    ],
    ['GridCompanyEquipment', data.GridCompanyEquipment],
    ['CustomerEquipment', data.CustomerEquipment],
    ['GridCompanyResponsibleEquipment', data.GridCompanyResponsibleEquipment],
    ['CustomerResponsibleEquipment', data.CustomerResponsibleEquipment],
    ['ProtectiveDevice', data.ProtectiveDevice],
    ['OffGridPowerSupply', data.OffGridPowerSupply],
    ['SpecialNotes', data.SpecialNotes],
  ];

  addChildNodes(namespace, ConnectionCertificate, nodesConnectionCertificate);

  if (data.BoundaryScheme && data.BoundaryScheme.length > 0) {
    ConnectionCertificate.appendChild(
      createDocumentScansElement(
        namespace,
        'BoundaryScheme',
        data.BoundaryScheme,
      ),
    );
  }

  addChildNode(namespace, ConnectionCertificate, 'Comments', data.Comments);

  // Signers
  if (data.Signers.Signer.length > 0) {
    const Signers = document.createElementNS(namespace, 'Signers');

    let isNotEmptySigners = false;

    data.Signers.Signer.forEach((signer) => {
      const Signer = document.createElementNS(namespace, 'Signer');

      const nodesSigner = [
        ['Name', signer.Name],
        ['FamilyName', signer.FamilyName],
        ['FirstName', signer.FirstName],
        ['Patronymic', signer.Patronymic],
      ];

      if (addChildNodes(namespace, Signer, nodesSigner)) {
        Signers.appendChild(Signer);
        isNotEmptySigners = true;
      }
    });

    if (isNotEmptySigners) {
      ConnectionCertificate.appendChild(Signers);
    }
  }

  if (onlyMicrogenerator) {
    ConnectionCertificate.appendChild(
      createElement(namespace, 'Microgeneration', 'true'),
    );
  }

  ConnectionCertificate.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );
  ConnectionCertificate.setAttribute(
    'xmlns:xmime',
    'http://www.w3.org/2005/05/xmlmime',
  );
  ConnectionCertificate.setAttribute(
    'xsi:schemaLocation',
    'http://energochain.ru/XML/documents/connection-certificate/1.1 ConnectionCertificate.xsd',
  );

  console.log('ConnectionCertificate xmlDoc', ConnectionCertificate);

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ConnectionCertificate.xsl" ?>\n'}${
      serializeToString(ConnectionCertificate)}`
  );
}

function generateXml(
  data,
  date,
  sourceDocumentId,
  onlyMicrogenerator,
  privacyPolicy,
) {
  const namespace =
    'http://energochain.ru/XML/documents/connection-application/1.0';

  const ConnectionApplication = document.createElementNS(
    namespace,
    'ConnectionApplication',
  );

  if (privacyPolicy) {
    const UserAgreements = document.createElementNS(
      namespace,
      'UserAgreements',
    );
    addChildNodes(namespace, UserAgreements, [
      ['AgreedWithUserAgreement', privacyPolicy.agreedWithUserAgreement],
      [
        'AgreedWithPersonalDataAgreement',
        privacyPolicy.agreedWithPersonalDataAgreement,
      ],
    ]);
    ConnectionApplication.appendChild(UserAgreements);
  }

  if (sourceDocumentId !== undefined) {
    // Date
    ConnectionApplication.appendChild(
      createElement(namespace, 'SourceDocumentId', sourceDocumentId),
    );
  }

  // Date
  ConnectionApplication.appendChild(createElement(namespace, 'Date', date));

  function getIdentityDocument(consumer, passportScans) {
    let documentType = '';

    switch (consumer.documentType.value) {
      case 'PASSPORT_RF': {
        documentType = 'Passport';
        break;
      }
      case 'RESIDENCE_PERMIT_RF': {
        documentType = 'ResidencePermit';
        break;
      }
      case 'BIRTH_CERTIFICATE': {
        documentType = 'BirthCertificate';
        break;
      }
      case 'OTHER': {
        documentType = 'Other';
        break;
      }
    }

    const Passport = document.createElementNS(namespace, documentType);

    const nodesPassport = [
      ['Series', consumer.documentSeries],
      ['Number', consumer.passportNumber],
      ['Issuer', consumer.issuer],
      ['IssueDate', formatDateToXmlDateFormat(consumer.issueDate)],
    ];

    if (consumer.subdivisionCode && consumer.subdivisionCode.length > 0) {
      nodesPassport.push(['SubdivisionCode', consumer.subdivisionCode]);
    }

    if (consumer.otherDocumentType && consumer.otherDocumentType.length > 0) {
      nodesPassport.push(['Name', consumer.otherDocumentType]);
    }

    addChildNodes(namespace, Passport, nodesPassport);

    if (passportScans) {
      Passport.appendChild(
        createDocumentScansElement(
          namespace,
          'DocumentScans',
          data.documentScans.passportScans,
        ),
      );
    }

    return Passport;
  }

  if (data.consumer.company?.ogrn?.length || data.consumer.company?.ogrnip?.length) {
    const Consumer = document.createElementNS(namespace, 'LegalConsumer');
    let additionalPhone = '';
    if (data.consumer.additionalPhone) {
      additionalPhone = formatting.unformatPhonePrefix(
        data.consumer.additionalPhone,
      );
    }
    const nodesConsumer = [
      ['FamilyName', data.consumer.familyName],
      ['FirstName', data.consumer.firstName],
      ['Patronymic', data.consumer.patronymic],
      ['Phone', formatting.unformatPhonePrefix(data.consumer.phone)],
      ['PhoneVerificationCode', data.consumer.phoneCode],
      ['AdditionalPhone', additionalPhone],
      ['AdditionalPhoneVerificationCode', data.consumer.additionalPhoneCode],
      ['SNILS', data.consumer.insuranceNumber.replace(RegExp('[-]?[ ]?', 'g'), '')],
      ['Email', data.consumer.email],
      ['EmailVerificationCode', data.consumer.emailCode],
      ['CompanyName', data.consumer.company.name],
      ['CompanyAddress', data.consumer.company.address],
      ['CompanyInn', data.consumer.company.inn],
      data.consumer.company.ogrn ? ['OGRN', data.consumer.company.ogrn] : ['OGRNIP', data.consumer.company.ogrnip],
    ];

    console.log(nodesConsumer);
    addChildNodes(namespace, Consumer, nodesConsumer);
    ConnectionApplication.appendChild(Consumer);

    const passportScans = data.documentScans.passportScans;
    if (passportScans.length) {
      const identityDocument = getIdentityDocument(data.consumer, passportScans);
      if (identityDocument) {
        const IdentityDocument = document.createElementNS(namespace, 'IdentityDocument');
        IdentityDocument.appendChild(identityDocument);
        Consumer.appendChild(IdentityDocument);
      }
    }
  } else {
    // Consumer
    const Consumer = document.createElementNS(namespace, 'Consumer');

    let nodesConsumer = [
      ['FamilyName', data.consumer.familyName],
      ['FirstName', data.consumer.firstName],
      ['Patronymic', data.consumer.patronymic],
    ];

    addChildNodes(namespace, Consumer, nodesConsumer);


    const passportScans = data.documentScans.passportScans;
    if (passportScans.length) {
      const identityDocument = getIdentityDocument(data.consumer, passportScans);
      if (identityDocument) {
        const IdentityDocument = document.createElementNS(namespace, 'IdentityDocument');
        IdentityDocument.appendChild(identityDocument);
        Consumer.appendChild(IdentityDocument);
      }
    }


    let additionalPhone = '';
    if (data.consumer.additionalPhone) {
      additionalPhone = formatting.unformatPhonePrefix(
        data.consumer.additionalPhone,
      );
    }

    nodesConsumer = [
      ['Phone', formatting.unformatPhonePrefix(data.consumer.phone)],
      ['PhoneVerificationCode', data.consumer.phoneCode],
      ['AdditionalPhone', additionalPhone],
      ['AdditionalPhoneVerificationCode', data.consumer.additionalPhoneCode],
      [
        'SNILS',
        data.consumer.insuranceNumber.replace(RegExp('[-]?[ ]?', 'g'), ''),
      ],
      ['Email', data.consumer.email],
      ['EmailVerificationCode', data.consumer.emailCode],
      ['RegistrationAddress', data.consumer.registrationAddress],
      ['RegistrationPostcode', data.consumer.postcodeRegistrationAddress],
      ['Address', data.consumer.address],
      ['Postcode', data.consumer.postcodeAddress],
    ];
    addChildNodes(namespace, Consumer, nodesConsumer);

    ConnectionApplication.appendChild(Consumer);
  }
  // AdditionalOwner

  if (
    data.additionalOwner &&
    data.additionalOwner.ownerGroups &&
    data.additionalOwner.ownerGroups.length > 0 &&
    data.additionalOwner.ownerGroups[0].familyName !== ''
  ) {
    const AdditionalOwners = document.createElementNS(
      namespace,
      'AdditionalOwners',
    );
    data.additionalOwner.ownerGroups.forEach((owner) => {
      const AdditionalOwner = document.createElementNS(namespace, 'Owner');

      let nodes = [
        ['FamilyName', owner.familyName],
        ['FirstName', owner.firstName],
        ['Patronymic', owner.patronymic],
      ];

      addChildNodes(namespace, AdditionalOwner, nodes);

      const IdentityDocument = document.createElementNS(namespace, 'IdentityDocument');

      IdentityDocument.appendChild(getIdentityDocument(owner));

      AdditionalOwner.appendChild(IdentityDocument);

      let additionalPhone = '';
      if (owner.additionalPhone) {
        additionalPhone = formatting.unformatPhonePrefix(owner.additionalPhone);
      }

      nodes = [
        ['Phone', formatting.unformatPhonePrefix(owner.phone)],
        ['PhoneVerificationCode', owner.phoneCode],
        ['AdditionalPhone', additionalPhone],
        ['AdditionalPhoneVerificationCode', owner.additionalPhoneCode],
        ['SNILS', owner.insuranceNumber.replace(RegExp('[-]?[ ]?', 'g'), '')],
        ['RegistrationAddress', owner.registrationAddress],
        ['RegistrationPostcode', owner.postcodeRegistrationAddress],
        ['Address', owner.address],
        ['Postcode', owner.postcodeAddress],
      ];
      addChildNodes(namespace, AdditionalOwner, nodes);

      AdditionalOwners.appendChild(AdditionalOwner);
    });

    if (data.documentScans.powerOfAttorneyScans) {
      AdditionalOwners.appendChild(
        createDocumentScansElement(
          namespace,
          'DocumentScans',
          data.documentScans.powerOfAttorneyScans,
        ),
      );
    }

    ConnectionApplication.appendChild(AdditionalOwners);
  }

  // ReasonToConnection
  if (data.reasonToConnection?.value === 'POWER_INCREASE' || data.reasonToConnection?.value === 'NEW_TECHNOLOGICAL_CONNECTION') {
    ConnectionApplication.appendChild(
      createElement(namespace, 'ReasonToConnection', data.reasonToConnection?.value),
    );
  } else {
    ConnectionApplication.appendChild(
      createElement(namespace, 'ReasonToConnection', data.type),
    );
  }

  // ObjectLocation
  const ObjectLocation = document.createElementNS(namespace, 'ObjectLocation');
  const nodesObjectLocation = [
    ['Address', data.objectLocation.objectAddress],
    ['AdditionalInformation', data.objectLocation.additionalInformation],
    ['LandCadastralNumber', data.objectLocation.landCadastralNumber],
    ['BuildingCadastralNumber', data.objectLocation.buildingCadastralNumber],
  ];
  addChildNodes(namespace, ObjectLocation, nodesObjectLocation);

  if (data.objectLocation.latitude && data.objectLocation.longitude) {
    const Contur = document.createElementNS(namespace, 'Contur');
    const Point = document.createElementNS(namespace, 'Point');
    const nodesPoint = [
      ['Latitude', data.objectLocation.latitude],
      ['Longitude', data.objectLocation.longitude],
    ];
    addChildNodes(namespace, Point, nodesPoint);
    Contur.appendChild(Point);

    ObjectLocation.appendChild(Contur);
  }

  if (data.documentScans.objectShemeScan) {
    ObjectLocation.appendChild(
      createDocumentScansElement(
        namespace,
        'DocumentScans',
        data.documentScans.objectShemeScan,
      ),
    );
  }

  ConnectionApplication.appendChild(ObjectLocation);

  // ConfirmationDocument
  const ConfirmationDocument = document.createElementNS(
    namespace,
    'ConfirmationDocument',
  );
  const nodesConfirmationDocument = [
    [
      'Title',
      data.confirmationDocument.documentTitle.label.includes('Иное')
        ? data.confirmationDocument.documentName
        : data.confirmationDocument.documentTitle.value,
    ],
  ];
  addChildNodes(namespace, ConfirmationDocument, nodesConfirmationDocument);

  if (data.confirmationDocument.documentTitle.value !== 'Выписка из ЕГРН') {
    ConfirmationDocument.appendChild(
      createElement(namespace, 'Number', data.confirmationDocument.documentNumber),
    );
    ConfirmationDocument.appendChild(
      createElement(namespace, 'IssueDate', formatDateToXmlDateFormat(data.confirmationDocument.documentIssueDate)),
    );
  }

  if (data.documentScans.confirmationDocumentScan) {
    ConfirmationDocument.appendChild(
      createDocumentScansElement(
        namespace,
        'DocumentScans',
        data.documentScans.confirmationDocumentScan,
      ),
    );
  }

  ConnectionApplication.appendChild(ConfirmationDocument);

  // ConnectionCharacteristics
  const ConnectionCharacteristics = document.createElementNS(
    namespace,
    'ConnectionCharacteristics',
  );
  const ObjectType = document.createElementNS(namespace, 'ObjectType');
  ObjectType.appendChild(
    createElement(namespace, 'Type', data.objectLocation.objectType.value),
  );

  if (data.objectLocation.objectType.value === 'Иное') {
    ObjectType.appendChild(
      createElement(namespace, 'CustomType', data.objectLocation.otherObjectType),
    );
  }
  ConnectionCharacteristics.appendChild(ObjectType);

  if (data.objectLocation.objectType.value === 'Нежилое помещение') {
    ConnectionCharacteristics.appendChild(
      createElement(namespace, 'ObjectArea', data.objectLocation.objectArea),
    );
  }
  ConnectionCharacteristics.appendChild(
    createElement(namespace, 'ReliabilityClass', data.connectionCharacteristics.reliabilityClass.value),
  );
  data.connectionCharacteristics.maximumPowerVoltage.forEach((it) => {
    const MaxPowerVoltageCharacteristics = document.createElementNS(namespace, 'MaxPowerVoltageCharacteristics');
    MaxPowerVoltageCharacteristics.setAttribute('reliabilityClass', it.reliabilityClass.value);
    const MaxPowerVoltageSummary = document.createElementNS(namespace, 'MaxPowerVoltageSummary');
    const nodesSummary = [
      ['MaxPower', it.summaryMaxPower],
      ['Voltage', it.summaryPhaseValue.value],
    ];
    addChildNodes(namespace, MaxPowerVoltageSummary, nodesSummary);
    MaxPowerVoltageCharacteristics.appendChild(MaxPowerVoltageSummary);

    const MaxPowerVoltageCurrent = document.createElementNS(namespace, 'MaxPowerVoltageCurrent');
    const nodesCurrent = [
      ['MaxPower', it.maxPowerConnection],
      ['Voltage', it.currentVoltage.value],
    ];
    addChildNodes(namespace, MaxPowerVoltageCurrent, nodesCurrent);
    MaxPowerVoltageCharacteristics.appendChild(MaxPowerVoltageCurrent);

    const MaxPowerVoltagePrevious = document.createElementNS(namespace, 'MaxPowerVoltagePrevious');
    const nodesPrevious = [
      ['MaxPower', it.previousMaxPower],
      ['Voltage', it.previousPhaseVoltage],
    ];
    addChildNodes(namespace, MaxPowerVoltagePrevious, nodesPrevious);
    MaxPowerVoltageCharacteristics.appendChild(MaxPowerVoltagePrevious);
    ConnectionCharacteristics.appendChild(MaxPowerVoltageCharacteristics);
  });

  const nodesConnectionCharacteristics = [];

  if (data.connectionCharacteristics.tariffCategoryType) {
    nodesConnectionCharacteristics.push(['TariffCategory', data.connectionCharacteristics.tariffCategoryType.value]);
  }

  if (data.connectionCharacteristics.workload) {
    nodesConnectionCharacteristics.push(['WorkLoad', data.connectionCharacteristics.workload.value]);
  }

  if (data.connectionCharacteristics.tariffZoneType && data.connectionCharacteristics.tariffCategoryType.value === 'TARIFF_CATEGORY_2') {
    nodesConnectionCharacteristics.push(['TariffZone', data.connectionCharacteristics.tariffZoneType.value]);
  }

  if (data.connectionCharacteristics.typeAccounting) {
    nodesConnectionCharacteristics.push(['AccountingKind', data.connectionCharacteristics.typeAccounting.value]);
  }

  addChildNodes(
    namespace,
    ConnectionCharacteristics,
    nodesConnectionCharacteristics,
  );

  let energyConsumerCount;

  if (data.type === 'LEGAL_ENTITIES_UP_TO_150' || !data.connectionCharacteristics.energyConsumerCount) {
    energyConsumerCount = '1';
  } else if (data.type !== 'INDIVIDUALS_UP_TO_15' && data.type !== 'LEGAL_ENTITIES_UP_TO_150') {
    energyConsumerCount = data.connectionCharacteristics.energyConsumerCount.value;
  }

  if (energyConsumerCount) {
    ConnectionCharacteristics.appendChild(
      createElement(
        namespace,
        'EnergyConsumerCount',
        energyConsumerCount,
      ),
    );
  }

  if (
    data.connectionCharacteristics.readyConnectionDate &&
    data.connectionCharacteristics.readyConnectionDate.length > 0
  ) {
    data.connectionCharacteristics.readyConnectionDate.forEach((item) => {
      const ReadyConnectionDate = document.createElementNS(
        namespace,
        'ReadyConnectionDate',
      );

      const nodesReadyConnectionDate = [
        ['Stage', item.stage],
        ['DesignDate', item.designDate],
        ['ReadyDate', item.readyDate],
        ['MaxPower', item.maxPower],
        ['ReliabilityClass', 'III'],
      ];

      addChildNodes(namespace, ReadyConnectionDate, nodesReadyConnectionDate);

      ConnectionCharacteristics.appendChild(ReadyConnectionDate);
    });
  }

  ConnectionApplication.appendChild(ConnectionCharacteristics);

  // SupplierOfLastResort
  const SupplierOfLastResort = document.createElementNS(
    namespace,
    'SupplierOfLastResort',
  );
  const retailAgreementDate = formatDateToXmlDateFormat(data.connectionCharacteristics.retailAgreementNumberDate);
  const nodesSupplierOfLastResort = [
    ['Title', data.connectionCharacteristics.supplierOfLastResort.value],
    ['INN', data.connectionCharacteristics.supplierOfLastResort.inn],
    ['RetailAgreementNumber', data.connectionCharacteristics.retailAgreementNumber],
    ['RetailAgreementNumberDate', retailAgreementDate],
    ['ConnectionApplicationWithContractType', data.connectionApplicationWithContractType.value],
  ];
  addChildNodes(namespace, SupplierOfLastResort, nodesSupplierOfLastResort);

  ConnectionApplication.appendChild(SupplierOfLastResort);

  // GridCompanyName
  let gridCompany = data.gridCompany.gridCompanyName.value;

  if (data.gridCompany.gridCompanyName.label === 'Иное') {
    gridCompany = data.gridCompany.otherGridCompany;
  }

  ConnectionApplication.appendChild(
    createElement(namespace, 'GridCompanyName', gridCompany),
  );
  // GridCompanyInn
  ConnectionApplication.appendChild(
    createElement(
      namespace,
      'GridCompanyInn',
      data.gridCompany.gridCompanyName.inn,
    ),
  );

  // AdditionalInfo
  if (data.additionalInfo && data.additionalInfo.comments) {
    ConnectionApplication.appendChild(
      createElement(namespace, 'AdditionalInfo', data.additionalInfo.comments),
    );
  }

  // Microgenerator
  if (onlyMicrogenerator) {
    data.microgenerator.items.forEach((item) => {
      const Microgenerator = document.createElementNS(
        namespace,
        'Microgenerator',
      );

      const nodesMicrogenerator = [
        ['MaxGeneratePower', item.MaxGeneratePower],
        ['PowerTransmittedToNetwork', item.PowerTransmittedToNetwork],
      ];
      addChildNodes(namespace, Microgenerator, nodesMicrogenerator);

      const MicrogeneratorPassport = document.createElementNS(
        namespace,
        'MicrogeneratorPassport',
      );
      MicrogeneratorPassport.appendChild(
        createElement(namespace, 'Characteristics', item.Characteristics),
      );

      if (item.DocumentScans && item.DocumentScans.length > 0) {
        MicrogeneratorPassport.appendChild(
          createDocumentScansElement(
            namespace,
            'DocumentScans',
            item.DocumentScans,
          ),
        );
      }

      Microgenerator.appendChild(MicrogeneratorPassport);

      Microgenerator.appendChild(
        createElement(
          namespace,
          'MicrogeneratorLocation',
          item.MicrogeneratorLocation,
        ),
      );

      ConnectionApplication.appendChild(Microgenerator);
    });
  }

  ConnectionApplication.appendChild(
    createElement(namespace, 'ConnectionApplicationType', data.type),
  );

  if (data.documentScans.otherScans) {
    ConnectionApplication.appendChild(
      createDocumentScansElement(
        namespace,
        'DocumentScans',
        data.documentScans.otherScans,
      ),
    );
  }

  // ConnectionApplication.appendChild(
  //   createElement(namespace, 'ConnectionApplicationWithContractType', data.connectionApplicationWithContractType),
  // );

  ConnectionApplication.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );
  ConnectionApplication.setAttribute(
    'xmlns:xmime',
    'http://www.w3.org/2005/05/xmlmime',
  );
  ConnectionApplication.setAttribute(
    'xsi:schemaLocation',
    'http://energochain.ru/XML/documents/connection-application/1.0 ConnectionApplication.xsd',
  );

  console.log('xmlDoc', ConnectionApplication);

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ConnectionApplication.xsl" ?>\n'}${
      serializeToString(ConnectionApplication)}`
  );
}

function documentReviewXml(documentId, type, comments, files, params) {
  const namespace = 'http://energochain.ru/XML/documents/document-review/1.0';

  const documentReview = document.createElementNS(namespace, 'DocumentReview');

  // Workflow
  documentReview.appendChild(
    createElement(namespace, 'DocumentId', documentId),
  );

  // Task
  documentReview.appendChild(createElement(namespace, 'Type', type));

  // Params
  if (params && Object.keys(params).length > 0) {
    const paramsElement = createElement(namespace, 'Params', '');

    Object.keys(params).forEach((param) => {
      const paramElement = createElement(namespace, 'Param', params[param]);
      paramElement.setAttribute('name', param);
      paramsElement.appendChild(paramElement);
    });

    documentReview.appendChild(paramsElement);
  }

  // Comments
  if (comments && comments.length > 0) {
    addChildNode(namespace, documentReview, 'Comments', comments);
  }

  if (files && files.length > 0) {
    documentReview.appendChild(
      createDocumentScansElement(namespace, 'Files', files),
    );
    documentReview.setAttribute(
      'xmlns:xmime',
      'http://www.w3.org/2005/05/xmlmime',
    );
  }

  console.log('documentReview xml', documentReview);

  documentReview.setAttribute(
    'xmlns:xsi',
    'http://www.w3.org/2001/XMLSchema-instance',
  );
  documentReview.setAttribute(
    'xsi:schemaLocation',
    'http://energochain.ru/XML/documents/document-review/1.0 DocumentReview.xsd',
  );

  return (
    `<?xml version="1.0" encoding="utf-8" ?>\n${
      serializeToString(documentReview)}`
  );
}

function usagePointLossesDocument(data) {
  return (
    '<?xml version="1.0" encoding="utf-8"?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UsagePointLossesDocument.xsl"?>\n' +
    '<UsagePointLossesDocument xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                          xmlns="http://energochain.ru/XML/documents/usage-point-losses-document/1.0"\n' +
    '                          xsi:schemaLocation="http://energochain.ru/XML/documents/usage-point-losses-document/1.0 UsagePointLossesDocument.xsd">\n' +
    `    <ConnectionApplicationDocumentId>${data.ConnectionApplicationDocumentId}</ConnectionApplicationDocumentId>\n` +
    `    <Date>${dayjs().utc().format('YYYY-MM-DD')}</Date>\n` +
    `    <ConstLoss>${data.ConstLoss.replaceAll(' ', '')}</ConstLoss>\n` +
    `    <VariableLoss>${data.VariableLoss}</VariableLoss>\n` +
    '</UsagePointLossesDocument>'
  );
}

function contractConsumerMesXml(data, retailer, consumer, accompanyingText) {
  console.log('data', data);
  let consumerCreds = '';
  if (consumer.companyInn?.length) {
    consumerCreds = (
      `       <Name>${consumer.companyName}</Name>\n` +
      `       <LegalInn>${consumer.companyInn}</LegalInn>\n`
    );
  } else {
    consumerCreds = (
      `       <Name>${consumer.name}</Name>\n` +
      `       <Snils>${consumer.snils}</Snils>\n`
    );
  }
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<ContractConsumerMES\n' +
    '        xmlns="http://energochain.ru/XML/documents/contract-consumer-mes/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/contract-consumer-mes/1.0 ContractConsumerMES.xsd">\n' +
    `    <ConnectionApplicationDocumentId>${data.SourceApplicationDocumentId}</ConnectionApplicationDocumentId>\n` +
    '    <ContractInfo>\n' +
    `        ${getCompletedTag('ExternalId', data.ExternalId)}` +
    `        <Number>${data.ContractNumber}</Number>\n` +
    `        <Date>${formatDateToXmlDateFormat(data.ContractDate)}</Date>\n` +
    '    </ContractInfo>\n' +
    '    <Retailer>\n' +
    `       <AccountId>${retailer.accountId}</AccountId>\n` +
    `       <Name>${retailer.name}</Name>\n` +
    `       <LegalInn>${retailer.inn}</LegalInn>\n` +
    `       <DepartmentName>${data.Department.name}</DepartmentName>\n` +
    `       <DepartmentId>${data.Department.id}</DepartmentId>\n` +
    '    </Retailer>\n' +
    '    <Consumer>\n' +
    `       <AccountId>${consumer.accountId}</AccountId>\n`}${
      consumerCreds
    }    </Consumer>\n` +
    `    ${getCompletedTag('AccompanyingText', accompanyingText)}` +
    `    <UsagePointAddress>${data.UsagePointAddress}</UsagePointAddress>\n` +
    `    <ConsumerTariffCategory>${data.TariffCategory.value}</ConsumerTariffCategory>\n` +
    `    ${getCompletedTag('ConsumerTariffZone', data.TariffZone?.value)}` +
    `    ${getCompletedTag('ReliabilityClass', data.ReliabilityClass)}` +
    `    <ObjectNumber>${data.ObjectNumber}</ObjectNumber>\n` +
    '    <Meter>\n' +
    `        <Serial>${data.MeterSerial}</Serial>\n` +
    '    </Meter>\n' +
    '</ContractConsumerMES>'
  );
}

function contractConsumerElectricityPurchaseXml(data, retailer, consumer, signatory, contractHeader) {
  // eslint-disable-next-line
  let passportData = '';
  if (consumer.passport !== undefined)
    passportData =
      ('                <IdentityDoc xmlns="http://energochain.ru/XML/documents/documents-common/1.0">\n' +
        '                    <Passport>\n' +
        `${getCompletedTag('Series', consumer.passport.series, '                        ')}` +
        `${getCompletedTag('Number', consumer.passport.number, '                        ')}` +
        `${getCompletedTag('IssueDate', consumer.passport.issueDate, '                        ')}` +
        `${getCompletedTag('Issuer', consumer.passport.issuer, '                        ')}` +
        `${getCompletedTag('SubdivisionCode', consumer.passport.subdivisionCode, '                        ')}` +
        '                    </Passport>\n' +
        '                </IdentityDoc>\n');

  let representative = '      <Representative>\n';
  if (signatory.fullName != null) representative +=
    '          <Name case="Nominative">\n' +
    `              <FamilyName>${signatory.fullName.surname}</FamilyName>\n` +
    `              <FirstName>${signatory.fullName.name}</FirstName>\n` +
    `              <Patronymic>${signatory.fullName.patronymic}</Patronymic>\n` +
    '          </Name>\n';
  if (signatory.position != null) representative +=
    `          <Position case="Nominative">${signatory.position}</Position>\n`;
  if (signatory.role?.id)  representative +=
    `          <RoleId>${signatory.role?.id}</RoleId>\n`;
  if (signatory.roleId)  representative +=
    `          <RoleId>${signatory.roleId}</RoleId>\n`;
  if (signatory.reason) representative +=
    '          <Reason>\n' +
    `             ${getCompletedTag('ID', signatory.reason.id)}` +
    `             <ReasonDocument case="Nominative">${signatory.reason.reasonDocument}</ReasonDocument>\n` +
    `             ${getCompletedTag('Number', signatory.reason.number)}` +
    `             ${getCompletedTag('Date', formatDateToXmlDateFormat(signatory.reason.date))}` +
    '            </Reason>\n';
  representative +=
    `        ${getCompletedTag('UserId', signatory.userId)}` +
    '    </Representative>\n';

  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="../Contract.xsl" ?>\n' +
    '<Contract\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/contract/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/contract/1.0 ../Contract.xsd">\n' +
    '\n' +
    '    <ContractRequisites>\n' +
    `        ${getCompletedTag('ExternalId', data.ExternalId)}` +
    '        <ContractName>купли-продажи электрической энергии(мощности)</ContractName>\n' +
    `        <ContractNumber>${data.ContractNumber}</ContractNumber>\n` +
    '        <ContractType>ELECTRICITY_PURCHASE_CONTRACT</ContractType>\n' +
    '        <ContractPlace>г. Москва</ContractPlace>\n' +
    `        <ContractDate>${formatDateToXmlDateFormat(data.ContractDate)}</ContractDate>\n` +
    `        <ContractStartDate>${formatDateToXmlDateFormat(data.ContractStartDate)}</ContractStartDate>\n` +
    `        <ContractEndDate>${formatDateToXmlDateFormat(data.ContractEndDate)}</ContractEndDate>\n` +
    '        <ContractSides>\n' +
    '            <DocumentSide xmlns="http://energochain.ru/XML/documents/company-common/1.0">\n' +
    `                <OrganisationName>${consumer.companyName?.length > 0 ? consumer.companyName : consumer.name}</OrganisationName>\n` +
    '                <ExecutorName>Supplier</ExecutorName>\n' +
    '                <AccountKind>Consumer</AccountKind>\n' +
    `${getCompletedTag('INN', consumer.inn, '                ')}` +
    `${getCompletedTag('SNILS', consumer.snils, '                ')}` +
    `${getCompletedTag('LegalAddress', consumer.legalAddress, '                ')}` +
    `${getCompletedTag('PostAddress', consumer.postAddress, '                ')}` +
    `${getCompletedTag('KPP', consumer.kpp, '                ')}` +
    `${getCompletedTag('OGRN', consumer.ogrn, '                ')}` +
    `${getCompletedTag('CorrespondentAccount', consumer.bankRequisites?.correspondentAccount, '                ')}` +
    `${getCompletedTag('SettlementAccount', consumer.bankRequisites?.settlementAccount, '                ')}` +
    `${getCompletedTag('BIK', consumer.bankRequisites?.bik, '                ')}` +
    `${getCompletedTag('BankName', consumer.bankRequisites?.bankName, '                ')}` +
    `${getCompletedTag('OKTMO', consumer.oktmo, '                ')}` +
    `${getCompletedTag('OKVED', consumer.okved, '                ')}` +
    `${getCompletedTag('OKPO', consumer.okpo, '                ')}` +
    `${getCompletedTag('PhoneNumber', consumer.phoneNumber, '                ')}` +
    `${passportData}` +
    `                <AccountId>${consumer.accountId}</AccountId>\n${
    representative
    }            </DocumentSide>\n` +
    '            <DocumentSide xmlns="http://energochain.ru/XML/documents/company-common/1.0">\n' +
    `                <OrganisationName>${retailer.name}</OrganisationName>\n` +
    '                <ExecutorName>PerformerFirst</ExecutorName>\n' +
    `                <INN>${retailer.inn}</INN>\n` +
    `${getCompletedTag('LegalAddress', retailer.legalAddress, '                ')}` +
    `${getCompletedTag('PostAddress', retailer.postAddress, '                ')}` +
    `${getCompletedTag('KPP', retailer.kpp, '                ')}` +
    `${getCompletedTag('OGRN', retailer.ogrn, '                ')}` +
    `${getCompletedTag('CorrespondentAccount', retailer.bankRequisites?.correspondentAccount, '                ')}` +
    `${getCompletedTag('SettlementAccount', retailer.bankRequisites?.settlementAccount, '                ')}` +
    `${getCompletedTag('BIK', retailer.bankRequisites?.bik, '                ')}` +
    `${getCompletedTag('BankName', retailer.bankRequisites?.bankName, '                ')}` +
    `${getCompletedTag('OKTMO', retailer.oktmo, '                ')}` +
    `${getCompletedTag('OKVED', retailer.okved, '                ')}` +
    `${getCompletedTag('OKPO', retailer.okpo, '                ')}` +
    `${getCompletedTag('PhoneNumber', retailer.phoneNumber, '                ')}` +
    `                <AccountId>${retailer.accountId}</AccountId>\n` +
    `                <DepartmentName>${data.Department.name}</DepartmentName>\n` +
    `                <DepartmentId>${data.Department.id}</DepartmentId>\n` +
    '                <Representative>\n' +
    `                    <RoleId>${retailer.roleId}</RoleId>\n` +
    `                    <UserId>${retailer.userId}</UserId>\n` +
    '                </Representative>\n' +
    '            </DocumentSide>\n' +
    '        </ContractSides>\n' +
    '    </ContractRequisites>\n' +
    `    ${getCompletedTag('ContractHeader', contractHeader, '    ')}` +
    `    ${TEXT_ELECTRICITY_PURCHASE_CONTRACT_BODY}` +
    '    <ContractImportantConditions>\n' +
    '        <ElectricityPurchaseContractImportantConditions>\n' +
    `            <ConnectionApplicationDocumentId>${data.SourceApplicationDocumentId}</ConnectionApplicationDocumentId>\n` +
    `            <ConsumerTariffCategory>${data.TariffCategory.value}</ConsumerTariffCategory>\n` +
    `            ${getCompletedTag('ConsumerTariffZone', data.TariffZone?.value)}` +
    '        </ElectricityPurchaseContractImportantConditions>\n' +
    '    </ContractImportantConditions>\n' +
    '    <Additions>\n' +
    '    </Additions>\n' +
    '</Contract>';
}

function testTariffsUpdatingTaskXml(userId, date, expiresAt) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="PlatformTask.xsl" ?>\n' +
    '<PlatformTask\n' +
    '        xmlns="http://energochain.ru/XML/documents/platform-task/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/platform-task/1.0 PlatformTask.xsd">\n' +
    `    <Date>${date}</Date>\n` +
    `    <ExpiresAt>${expiresAt}</ExpiresAt>\n` +
    '    <Employee>\n' +
    '        <Name>Иванов Иван Иванович</Name>\n' +
    '        <AccountId>c2ab1a2d-64c3-450a-8e2f-be0c3193900c</AccountId>\n' +
    '        <UserId>ae040fdf-8099-42b9-b260-efa58a7ff72e</UserId>\n' +
    '        <Role>Performer</Role>\n' +
    '    </Employee>\n' +
    '    <Employee>\n' +
    '        <Name>Иванов Иван Иванович</Name>\n' +
    '        <AccountId>c2ab1a2d-64c3-450a-8e2f-be0c3193900c</AccountId>\n' +
    '        <UserId>2492b05c-81ce-4c6d-99c6-7a4c5839ea46</UserId>\n' +
    '        <Role>Reviewer</Role>\n' +
    '    </Employee>\n' +
    '    <TaskType>tariffs-updating</TaskType>\n' +
    '    <UpdatingTariffsTaskInfo>\n' +
    '        <Year>2022</Year>\n' +
    '        <HalfYear>1</HalfYear>\n' +
    '        <Region>MSK</Region>\n' +
    '        <RegulatoryDocumentName>Приказ Департамента экономической политики и развития города Москвы «Об установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп потребителей города Москвы»</RegulatoryDocumentName>\n' +
    '        <TariffListSource sourceName="Департамент экономической политики и развития города Москвы">https://www.mos.ru/depr/documents/tarifnaia-politika/pa-v-sfere-gosudarstvennogo-regulirovaniya-tarifov/</TariffListSource>\n' +
    '        <TariffListSource sourceName="АО «Мосэнергосбыт»">https://www.mosenergosbyt.ru/individuals/tariffs-n-payments/tariffs-msk/polnaya-versiya-tarifov.php</TariffListSource>\n' +
    '    </UpdatingTariffsTaskInfo>\n' +
    '</PlatformTask>'
  );
}

function electricityTariffOrder(
  taskDocumentId,
  date,
  year,
  region,
  tariffList,
  regulatoryDocs,
) {
  // TODO move currency input in component
  function reverseFormatNumber(val) {
    return val
      .replace(new RegExp('[\\s₽]', 'g'), '')
      .replace(new RegExp('[,]', 'g'), '.');
  }

  let halfYearElectricityTariffs1 = '';
  let halfYearElectricityTariffs2 = '';

  tariffList.forEach((tariff) => {
    if (!tariff.rates) {
      return;
    }

    const half1 =
      `        <ConsumerCategoryElectricityTariffs consumerCategory = "${tariff.xmlCategory}">\n` +
      '            <RateElectricityTariffs rate="rate1">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate1.first.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '            <RateElectricityTariffs rate="rate2">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate2.first.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t2">${reverseFormatNumber(
        tariff.rates.rate2.first.t2.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '            <RateElectricityTariffs rate="rate3">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate3.first.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t2">${reverseFormatNumber(
        tariff.rates.rate3.first.t2.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t3">${reverseFormatNumber(
        tariff.rates.rate3.first.t3.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '        </ConsumerCategoryElectricityTariffs>';

    halfYearElectricityTariffs1 += `\n ${half1}`;

    const half2 =
      `        <ConsumerCategoryElectricityTariffs consumerCategory = "${tariff.xmlCategory}">\n` +
      '            <RateElectricityTariffs rate="rate1">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate1.second.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '            <RateElectricityTariffs rate="rate2">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate2.second.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t2">${reverseFormatNumber(
        tariff.rates.rate2.second.t2.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '            <RateElectricityTariffs rate="rate3">\n' +
      `                <DayZoneElectricityTariff dayZone="t1">${reverseFormatNumber(
        tariff.rates.rate3.second.t1.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t2">${reverseFormatNumber(
        tariff.rates.rate3.second.t2.value,
      )}</DayZoneElectricityTariff>\n` +
      `                <DayZoneElectricityTariff dayZone="t3">${reverseFormatNumber(
        tariff.rates.rate3.second.t3.value,
      )}</DayZoneElectricityTariff>\n` +
      '            </RateElectricityTariffs>\n' +
      '        </ConsumerCategoryElectricityTariffs>';

    halfYearElectricityTariffs2 += `\n ${half2}`;
  });

  // const namespace = 'http://energochain.ru/XML/documents/electricity-tariff-order/1.0';

  const regulatoryDocsStr = serializeToString(
    createAttachmentScansElement('', 'Documents', regulatoryDocs, 'dc:Document'),
  );

  // TODO validate set start using date
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="TariffOrder.xsl" ?>\n' +
    '<ElectricityTariffOrder\n' +
    '        xmlns="http://energochain.ru/XML/documents/electricity-tariff-order/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:dc="http://energochain.ru/XML/documents/documents-common/1.0"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-tariff-order/1.0 ElectricityTariffOrder.xsd">\n' +
    `    <TaskDocumentId>${taskDocumentId}</TaskDocumentId>\n` +
    `    <Date>${date}</Date>\n` +
    `    <StartUsingDate>${year}-01-01</StartUsingDate>\n` +
    `    <Year>${year}</Year>\n` +
    `    <Region>${region}</Region>\n` +
    `    ${regulatoryDocsStr}\n` +
    '    <HalfYearElectricityTariffs halfYear="1">\n' +
    `    ${halfYearElectricityTariffs1}\n` +
    '    </HalfYearElectricityTariffs>\n' +
    '    <HalfYearElectricityTariffs halfYear="2">\n' +
    `    ${halfYearElectricityTariffs2}\n` +
    '    </HalfYearElectricityTariffs>\n' +
    '</ElectricityTariffOrder>'
  );
}

function measurementsXml(data) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="Measurements.xsl" ?>\n' +
    '<Measurements\n' +
    '        xmlns="http://energochain.ru/XML/documents/measurements/1.0"\n' +
    '        xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"\n' +
    '        xmlns:mc="http://energochain.ru/XML/documents/measurements-common/1.0">\n' +
    '    <Meter>\n' +
    `        <tc:Id>${data.id}</tc:Id>\n` +
    `        <tc:Type>${data.type}</tc:Type>\n` +
    `        <tc:Serial>${data.serialNumber}</tc:Serial>\n` +
    '        <tc:Readings>\n' +
    '            <mc:Reading>\n' +
    `                <mc:Period>${data.dateTime}</mc:Period>\n` +
    `                <mc:ReadingT1>${data.value1}</mc:ReadingT1>\n` +
    `${getCompletedTag('mc:ReadingT2', data.value2, '                ')}` +
    `${getCompletedTag('mc:ReadingT3', data.value3, '                ')}` +
    '            </mc:Reading>\n' +
    '        </tc:Readings>\n' +
    '    </Meter>\n' +
    '</Measurements>'
  );
}

// TODO move to submodule/remove test
function electricityPaymentXml({
                                 meterNumber,
                                 tariff,
                                 serviceLocation,
                                 owner,
                                 address,
                                 number,
                                 secondnumber,
                               }) {
  let previousReadings = '';
  let summary = 0;

  switch (tariff) {
    case 'SINGLE_RATE': {
      const summary1 = 100 * serviceLocation.consumer.firstHalfYearTariff.T1;
      summary = summary1;

      previousReadings =
        '    <PreviousReadings>\n' +
        '        <Rate>Однотарифный учёт</Rate>\n' +
        '        <Readings>48912</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>162</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T1}</RatePrice>\n` +
        `        <Summary>${summary1.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n';
      break;
    }
    case 'DOUBLE_RATE': {
      const summary1 = 100 * serviceLocation.consumer.firstHalfYearTariff.T1;
      const summary2 = 62 * serviceLocation.consumer.firstHalfYearTariff.T2;

      summary = summary1 + summary2;
      previousReadings =
        '    <PreviousReadings>\n' +
        '        <Rate>T1 (день)</Rate>\n' +
        '        <Readings>38912</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>100</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T1}</RatePrice>\n` +
        `        <Summary>${summary1.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n' +
        '    <PreviousReadings>\n' +
        '        <Rate>T2 (ночь)</Rate>\n' +
        '        <Readings>10000</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>62</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T2}</RatePrice>\n` +
        `        <Summary>${summary2.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n';
      break;
    }
    case 'MULTI_RATE': {
      const summary1 = 100 * serviceLocation.consumer.firstHalfYearTariff.T1;
      const summary2 = 10 * serviceLocation.consumer.firstHalfYearTariff.T2;
      const summary3 = 52 * serviceLocation.consumer.firstHalfYearTariff.T3;

      summary = summary1 + summary2 + summary3;

      previousReadings =
        '    <PreviousReadings>\n' +
        '        <Rate>T1 (пик)</Rate>\n' +
        '        <Readings>15000</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>100</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T1}</RatePrice>\n` +
        `        <Summary>${summary1.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n' +
        '    <PreviousReadings>\n' +
        '        <Rate>T2 (ночь)</Rate>\n' +
        '        <Readings>5000</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>10</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T2}</RatePrice>\n` +
        `        <Summary>${summary2.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n' +
        '    <PreviousReadings>\n' +
        '        <Rate>T3 (п/пик)</Rate>\n' +
        '        <Readings>28912</Readings>\n' +
        '        <Date>17.02.2022</Date>\n' +
        '        <LastReadings>\n' +
        '           <Readings></Readings>\n' +
        '           <Date></Date>\n' +
        '        </LastReadings>\n' +
        '        <Consumption>52</Consumption>\n' +
        '        <Losses></Losses>\n' +
        `        <RatePrice>${serviceLocation.consumer.firstHalfYearTariff.T3}</RatePrice>\n` +
        `        <Summary>${summary3.toFixed(2)}</Summary>\n` +
        '    </PreviousReadings>\n';
      break;
    }
  }

  const totalSummary = (2879.89 + 10.87 + summary).toFixed(2);
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityPayment.xsl" ?>\n' +
    '<ElectricityPayment xmlns="http://energochain.ru/XML/documents/electricity-payment/1.0" \n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-payment/1.0 ElectricityPayment.xsd"\n' +
    'xmlns:xmime="http://www.w3.org/2005/05/xmlmime">\n' +
    '\n' +
    '<InvoiceNumber>№'}${
      number
    }-${
      secondnumber
    }</InvoiceNumber>\n` +
    '\n' +
    '<Organisation>\n' +
    '    <Name>АО "Мосэнергосбыт"</Name>\n' +
    '    <Adress>117312, г.Москва, ул.Вавилова, д.9</Adress>\n' +
    '    <Site>www.mosenergosbyt.ru</Site>\n' +
    '    <Number>8(499) 550-9-550</Number>\n' +
    '    <Fax>+7 (499) 550-9-550</Fax>\n' +
    '    <Email>info@mosenergosbyt.ru</Email>\n' +
    '</Organisation>\n' +
    '\n' +
    '<PaymentReceiver>\n' +
    '    <Name>АО "Мосэнергосбыт"</Name>\n' +
    '    <INN>7736520080</INN>\n' +
    '    <rs>4070281072810738360027199</rs>\n' +
    '    <BankName>Московский банк ПАО Сбербанк России</BankName>\n' +
    '    <BIC>044525225</BIC>\n' +
    '    <ks>30101810400000000225</ks>\n' +
    '</PaymentReceiver>\n' +
    '\n' +
    '<User>\n' +
    '    <ls>73356-188-27</ls>\n' +
    `    <MeterNumber>${
      meterNumber
    }</MeterNumber>\n` +
    `    <Name>${
      owner
    }</Name>\n` +
    `    <Adress>${
      address
    }</Adress>\n` +
    '    <RoomCount>1</RoomCount>\n' +
    '    <UsersCount>1</UsersCount>\n' +
    '    <Number>50OB008976</Number>\n' +
    '</User>\n' +
    '\n' +
    '<InvoiceDetail>\n' +
    '    <MeterInformation>\n' +
    `        <Number>${
      meterNumber
    }</Number>\n` +
    '        <TransformationRatio>5</TransformationRatio>\n' +
    '    </MeterInformation>\n' +
    `    <CalcType>C</CalcType>\n${
      previousReadings
    }    <Period>2022-02</Period>\n` +
    '</InvoiceDetail>\n' +
    '\n' +
    '<Total>\n' +
    '    <StartPeriod>2879,89</StartPeriod>\n' +
    '    <Paid>0,00</Paid>\n' +
    `        <Accrued>${summary}</Accrued>\n` +
    '    <InterestAccrued>10,87</InterestAccrued>\n' +
    `    <Summary>${totalSummary}</Summary>\n` +
    '</Total>\n' +
    '\n' +
    '</ElectricityPayment>\n'
  );
}

function mesTransaction(amount, number, date) {
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="MesTransaction.xsl" ?>\n' +
    '<MesTransaction\n' +
    '  xmlns="http://energochain.ru/XML/documents/mes_transaction/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/mes_transaction/1.0 MesTransaction.xsd">\n' +
    '  <Date>'}${
      date
    }</Date>\n` +
    `  <TransactionDate>${
      date
    }T20:11:00+03:00</TransactionDate>\n` +
    `  <InvoiceNumber>${
      number
    }</InvoiceNumber>\n` +
    `  <Amount>${
      amount
    }</Amount>\n` +
    '</MesTransaction>'
  );
}

function changingTariffApplication(
  date,
  serviceLocation,
  newCategory,
  newTariff,
  documents,
) {
  const currentCategoryXml = serviceLocation.tariffCategory
    ?.replace('TARIFF_', '')
    .replace('_', '-')
    .toLowerCase() ?? '';

  const newCategoryXml = newCategory.replace('_', '-').toLowerCase();

  const rateXml = {
    SINGLE_RATE: 'rate1',
    DOUBLE_RATE: 'rate2',
    MULTI_RATE: 'rate3',
  };

  const documentsXml = serializeToString(
    createAttachmentScansElement('', 'Documents', documents, 'Document'),
  );

  const fio = serviceLocation.consumer.consumerName.split(' ');

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ChangingTariffApplication.xsl" ?>\n' +
    '<ChangingTariffApplication \n' +
    '  xmlns="http://energochain.ru/XML/documents/changing-tariff-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/changing-tariff-application/1.0 ChangingTariffApplication.xsd">\n' +
    `  <Date>${date}</Date>\n` +
    `  <ServiceLocationId>${serviceLocation.id}</ServiceLocationId>\n` +
    '  <ConsumerName>\n' +
    `    <FamilyName>${fio[0]}</FamilyName>\n` +
    `    <FirstName>${fio[1]}</FirstName>\n` +
    `    ${getCompletedTag('Patronymic', fio[2])}\n` +
    '  </ConsumerName>\n' +
    `  <BusinessAccount>${serviceLocation.consumer.contractNumber}</BusinessAccount>\n` +
    `  <ObjectLocation>${serviceLocation.objectAddress}</ObjectLocation>\n` +
    `  <CurrentConsumerCategory>${currentCategoryXml}</CurrentConsumerCategory>\n` +
    `  <CurrentTariff>${
      rateXml[serviceLocation.consumer.tariff]
    }</CurrentTariff>\n` +
    `  <NewConsumerCategory>${newCategoryXml}</NewConsumerCategory>\n` +
    `  <NewTariff>${rateXml[newTariff]}</NewTariff>\n`}${
      documentsXml
    }</ChangingTariffApplication>\n`
  );
}

function legalConnectionToPlatformApplication(
  company,
  kind,
  user,
  agreementData,
) {
  const { agreement, agreementScans, documentScans } = agreementData;

  let agreementScansStr = '';
  let documentScansStr = '';

  if (agreementScans && agreementScans?.length > 0) {
    agreementScansStr = serializeToString(
      createAttachmentScansElement('', 'AgreementScans', agreementScans, 'File'),
    );

    documentScansStr = serializeToString(
      createAttachmentScansElement('', 'DocumentScans', documentScans, 'File'),
    );
  }

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="LegalPlatformConnectionApplication.xsl" ?>\n' +
    '<LegalConnectionToPlatformApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/legal-connection-to-platform-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/legal-connection-to-platform-application/1.0 LegalConnectionToPlatformApplication.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    '    <Person>\n' +
    '       <FIO>\n' +
    `           <FamilyName>${company.person.familyName}</FamilyName>\n` +
    `           <FirstName>${company.person.firstName}</FirstName>\n`}${
      company.person.patronymic
        ? `           <Patronymic>${company.person.patronymic}</Patronymic>\n`
        : ''
    }       </FIO>\n` +
    `       <Snils>${company.person.snils}</Snils>\n` +
    `        ${getCompletedTag('Phone', formatting.unformatPhonePrefix(user.phone))}\n` +
    `        ${getCompletedTag('PhoneVerificationCode', user.phoneCode)}\n` +
    `        ${getCompletedTag('Email', user.email)}\n` +
    `        ${getCompletedTag('EmailVerificationCode', user.emailCode)}\n` +
    '    </Person>\n' +
    '    <CompanyInfo>\n' +
    `        <Name>${company.name}</Name>\n` +
    `        <INN>${company.inn}</INN>\n` +
    `        ${getCompletedTag('OGRN', company.ogrn)}\n` +
    `        ${getCompletedTag('KPP', company.kpp)}\n` +
    `        <LegalAddress>${company.address}</LegalAddress>\n` +
    '    </CompanyInfo>\n' +
    `    <Kind>${kind}</Kind>\n` +
    '    <Agreements>\n' +
    `      <AgreedWithUserAgreement>${agreement.userAgreement}</AgreedWithUserAgreement>\n` +
    `      <AgreedWithPersonalDataAgreement>${agreement.personalDataAgreement}</AgreedWithPersonalDataAgreement>\n` +
    `      <AgreedWithPrivacyPolicy>${agreement.privacyPolicy}</AgreedWithPrivacyPolicy>\n` +
    `      <AgreedWithMarketing>${agreement.marketing}</AgreedWithMarketing>\n` +
    `${agreementScansStr}` +
    '    </Agreements>\n' +
    `${documentScansStr}` +
    '</LegalConnectionToPlatformApplication>'
  );
}

function ElectricityTransmissionContractTwo(
  data,
  voltageClassList,
  typeAccountingList,
  ownerAccountingFacilitiesList,
  measurementsReadingKindList,
  contractExisting,
  sourceContractId,
) {
  let ElectricityEnergyPoint = '';
  data.attachment1.forEach((point) => {
    ElectricityEnergyPoint +=
      '            <ElectricityEnergyPoint>\n' +
      `                <SupplierName>${point[1]}</SupplierName>\n` +
      `                <Address>${point[2]}</Address>\n` +
      '                <Contract>\n' +
      `                    <Number>${point[3]}</Number>\n` +
      `                    <Date>${point[4]}</Date>\n` +
      '                </Contract>\n' +
      '                <SupplyPointName>\n' +
      `                    <MainSubstation>${point[5]}</MainSubstation>\n` +
      `                    <Feeder>${point[6]}</Feeder>\n` +
      `                    <DistributionSubstation>${point[7]}</DistributionSubstation>\n` +
      `                    <Line>${point[8]}</Line>\n` +
      `                    <TransformationSubstation>${point[9]}</TransformationSubstation>\n` +
      `                    <Other>${point[10]}</Other>\n` +
      '                </SupplyPointName>\n' +
      '                <Voltage>\n' +
      `                    <Actual>${point[11]}</Actual>\n` +
      `                    <Tariff>${getValueByLabel(
        point[12],
        voltageClassList,
      )}</Tariff>\n` +
      '                </Voltage>\n' +
      '                <Meter>\n' +
      `                    <Type>${point[14]}, ${point[15]}, ${point[16]}</Type>\n` +
      `                    ${getCompletedTag('Id', point[13])}` +
      `                    <AccuracyClass>${point[17]}</AccuracyClass>\n` +
      `                    <Number>${point[18]}</Number>\n` +
      `                    <CalibrationDate>${formatDateToXmlDateFormat(
        point[19],
      )}</CalibrationDate>\n` +
      `                    <NextCalibrationDate>${formatDateToXmlDateFormat(
        point[20],
      )}</NextCalibrationDate>\n` +
      `                    <CalibrationInterval>${point[21]}</CalibrationInterval>\n` +
      '                </Meter>\n' +
      '                <CurrentTransformer>\n' +
      `                    <Type>${point[22]}, ${point[23]}</Type>\n` +
      `                    <TransformationRatio>${point[24]}</TransformationRatio>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        point[25],
      )}</Date>\n` +
      `                    <CalibrationInterval>${point[26]}</CalibrationInterval>\n` +
      '                </CurrentTransformer>\n' +
      '                <VoltageTransformer>\n' +
      `                    <Type>${point[27]}, ${point[28]}</Type>\n` +
      `                    <TransformationRatio>${point[29]}</TransformationRatio>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        point[30],
      )}</Date>\n` +
      `                    <CalibrationInterval>${point[31]}</CalibrationInterval>\n` +
      '                </VoltageTransformer>\n' +
      `                <EstimatedCoefficient>${point[32]}</EstimatedCoefficient>\n` +
      '                <ElectricityLosses>\n' +
      `                    <Percent>${point[33]}</Percent>\n` +
      `                    <kWh>${point[34]}</kWh>\n` +
      '                </ElectricityLosses>\n' +
      `                <AccountingType>${getValueByLabel(
        point[35],
        typeAccountingList,
      )}</AccountingType>\n` +
      `                <AccountOwner>${getValueByLabel(
        point[36],
        ownerAccountingFacilitiesList,
      )}</AccountOwner>\n` +
      `                <MeasurementsReadingKind>${getValueByLabel(
        point[37],
        measurementsReadingKindList,
      )}</MeasurementsReadingKind>\n` +
      '            </ElectricityEnergyPoint>\n';
  });

  let EssentialTermsContract = '';

  data.attachment2.forEach((contract) => {
    EssentialTermsContract +=
      '            <EssentialTermsContract>\n' +
      `                <ObjectName>${contract[1]}</ObjectName>\n` +
      `                <ConnectionName>${contract[2]}</ConnectionName>\n` +
      `                <ConsumerName>${contract[3]}</ConsumerName>\n` +
      `                <Address>${contract[4]}</Address>\n` +
      '                <ContractPurchaseElectricity>\n' +
      `                    <Number>${contract[5]}</Number>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        contract[6],
      )}</Date>\n` +
      '                </ContractPurchaseElectricity>\n' +
      '                <Voltage>\n' +
      `                    <Actual>${contract[7]}</Actual>\n` +
      `                    <Tariff>${getValueByLabel(
        contract[8],
        voltageClassList,
      )}</Tariff>\n` +
      '                </Voltage>\n' +
      '                <ConnectionDocument>\n' +
      `                    <Number>${contract[9]}</Number>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        contract[10],
      )}</Date>\n` +
      '                </ConnectionDocument>\n' +
      `                <ReliabilityСategory>${contract[11]}</ReliabilityСategory>\n` +
      `                <MaximumPowerConnectionPoint>${contract[12]}</MaximumPowerConnectionPoint>\n` +
      `                <DeclaredPowerConnectionPoint>${contract[13]}</DeclaredPowerConnectionPoint>\n` +
      `                <TechnologicalEmergencyArmor>${contract[14]}</TechnologicalEmergencyArmor>\n` +
      '            </EssentialTermsContract>\n';
  });
  return (
    `${'<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityTransmissionContractTwoNew.xsl" ?>\n' +
    '<ElectricityTransmissionContractTwoNew xmlns="http://energochain.ru/XML/documents/electricity-transmission-contract-two-new/1.0"\n' +
    '                                       xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                                       xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-transmission-contract-two-new/1.0 ElectricityTransmissionContractTwoNew.xsd">\n'}${
      sourceContractId ? `<ContractId>${sourceContractId}</ContractId>` : ''
    }    <KindByExisting>${contractExisting}</KindByExisting>\n` +
    '    <ContractInfo>\n' +
    `        <NameCreator>${data.contractCard.ContractInfo.NameCreator}</NameCreator>\n` +
    '        <FullContractName>\n' +
    `            <Type>${getContractType(data)}</Type>\n` +
    '            <ContractSubject>оказание услуг</ContractSubject>\n' +
    '            <ContractRequisites>\n' +
    `                <Number>${data.contractCard.ContractInfo.Number}</Number>\n` +
    `                <Date>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.Date,
    )}</Date>\n` +
    `                <Place>${data.contractCard.ContractInfo.Place}</Place>\n` +
    '            </ContractRequisites>\n' +
    '        </FullContractName>\n' +
    `        <EffectiveDate>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.EffectiveDate,
    )}</EffectiveDate>\n` +
    `        <ExpirationDate>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.ExpirationDate,
    )}</ExpirationDate>\n` +
    '        <SourceContractInfo>\n' +
    `            <Number>${data.contractCard.ContractInfo.SourceContractInfo.Number}</Number>\n` +
    `            <Date>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.SourceContractInfo.Date,
    )}</Date>\n` +
    `            <Place>${data.contractCard.ContractInfo.SourceContractInfo.Place}</Place>\n` +
    '        </SourceContractInfo>\n' +
    `        <ContractLifeCycleStatus>${getContractLifeCycleStatus(
      data,
    )}</ContractLifeCycleStatus>\n` +
    '    </ContractInfo>\n' +
    '    <Supplier>\n' +
    `       ${getCompanyInfo(data.contractCard.Supplier)}\n` +
    '    </Supplier>\n' +
    '\n' +
    '    <Performer>\n' +
    `       ${getCompanyInfo(data.contractCard.Performer)}\n` +
    '    </Performer>\n' +
    '\n' +
    '    <Additions>\n' +
    `        <UsageEnergyPointList>\n${
      ElectricityEnergyPoint
    }        </UsageEnergyPointList>\n` +
    `        <UsageEssentialTermsContract>\n${
      EssentialTermsContract
    }        </UsageEssentialTermsContract>\n` +
    '    </Additions>\n' +
    '\n' +
    '</ElectricityTransmissionContractTwoNew>'
  );
}

function ElectricityTransmissionContractTwoTso(
  data,
  voltageClassList,
  typeAccountingList,
  measurementsReadingKindList,
  contractExisting,
  sourceContractId,
) {
  let TechnologicalConnectionReceptionPoint = '';

  data.attachment1.forEach((point) => {
    TechnologicalConnectionReceptionPoint +=
      '<TechnologicalConnectionReceptionPoint>\n' +
      `                <ReceptionPointName>${point[1]}</ReceptionPointName>\n` +
      `                <DocumentNumber>${point[2]}</DocumentNumber>\n` +
      `                <DocumentDate>${formatDateToXmlDateFormat(
        point[3],
      )}</DocumentDate>\n` +
      `                <SubjectName>${point[4]}</SubjectName>\n` +
      '            </TechnologicalConnectionReceptionPoint>\n';
  });

  let TechnologicalConnectionSupplyPoint = '';

  data.attachment3.forEach((point) => {
    TechnologicalConnectionSupplyPoint +=
      '<TechnologicalConnectionSupplyPoint>\n' +
      `                <SupplyPointName>${point[1]}</SupplyPointName>\n` +
      `                <DocumentNumber>${point[2]}</DocumentNumber>\n` +
      `                <DocumentDate>${formatDateToXmlDateFormat(
        point[3],
      )}</DocumentDate>\n` +
      `                <ConsumerName>${point[4]}</ConsumerName>\n` +
      '            </TechnologicalConnectionSupplyPoint>\n';
  });

  let SupplyPoint = '';

  data.attachment2.forEach((point) => {
    SupplyPoint +=
      '<SupplyPoint>\n' +
      `                <ConsumerNameSSO>${point[1]}</ConsumerNameSSO>\n` +
      '                <EnergySupplyContract>\n' +
      `                    <Number>${point[2]}</Number>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        point[3],
      )}</Date>\n` +
      '                </EnergySupplyContract>\n' +
      '                <SupplyPointName>\n' +
      `                    <MainSubstation>${point[4]}</MainSubstation>\n` +
      `                    <Feeder>${point[5]}</Feeder>\n` +
      `                    <DistributionSubstation>${point[6]}</DistributionSubstation>\n` +
      `                    <Line>${point[7]}</Line>\n` +
      `                    <TransformationSubstation>${point[8]}</TransformationSubstation>\n` +
      `                    <Other>${point[9]}</Other>\n` +
      '                </SupplyPointName>\n' +
      `                <MaxPower>${point[10]}</MaxPower>\n` +
      `                <MeterPlace>${point[11]}</MeterPlace>\n` +
      '                <Meter>\n' +
      `                    <Type>${point[13]}, ${point[14]}, ${point[15]}</Type>\n` +
      `                    ${getCompletedTag('Id', point[12])}` +
      `                    <AccuracyClass>${point[16]}</AccuracyClass>\n` +
      `                    <Number>${point[17]}</Number>\n` +
      `                    <CalibrationDate>${formatDateToXmlDateFormat(
        point[18],
      )}</CalibrationDate>\n` +
      `                    <NextCalibrationDate>${formatDateToXmlDateFormat(
        point[19],
      )}</NextCalibrationDate>\n` +
      `                    <CalibrationInterval>${point[20]}</CalibrationInterval>\n` +
      '                </Meter>\n' +
      '                <CurrentTransformer>\n' +
      `                    <Type>${point[21]}, ${point[22]}</Type>\n` +
      `                    <TransformationRatio>${point[23]}</TransformationRatio>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        point[24],
      )}</Date>\n` +
      `                    <CalibrationInterval>${point[25]}</CalibrationInterval>\n` +
      '                </CurrentTransformer>\n' +
      '                <VoltageTransformer>\n' +
      `                    <Type>${point[26]}, ${point[27]}</Type>\n` +
      `                    <TransformationRatio>${point[28]}</TransformationRatio>\n` +
      `                    <Date>${formatDateToXmlDateFormat(
        point[29],
      )}</Date>\n` +
      `                    <CalibrationInterval>${point[30]}</CalibrationInterval>\n` +
      '                </VoltageTransformer>\n' +
      `                <EstimatedCoefficient>${point[31]}</EstimatedCoefficient>\n` +
      '                <ElectricityLosses>\n' +
      `                    <Percent>${point[32]}</Percent>\n` +
      `                    <kWh>${point[33]}</kWh>\n` +
      '                </ElectricityLosses>\n' +
      `                <AccountingType>${getValueByLabel(
        point[24],
        typeAccountingList,
      )}</AccountingType>\n` +
      `                <BalanceAccountingMeans>${point[35]}</BalanceAccountingMeans>\n` +
      `                <MeasurementsReadingKind>${getValueByLabel(
        point[36],
        measurementsReadingKindList,
      )}</MeasurementsReadingKind>\n` +
      '            </SupplyPoint>\n';
  });

  return (
    `${'<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityTransmissionContractTwoTSO.xsl" ?>\n' +
    '<ElectricityTransmissionContractTwoTSO xmlns="http://energochain.ru/XML/documents/electricity-transmission-contract-two-tso/1.0"\n' +
    '                                       xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                                       xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-transmission-contract-two-tso/1.0 ElectricityTransmissionContractTwoTSO.xsd">\n' +
    `    <KindByExisting>${contractExisting}</KindByExisting>\n`}${
      sourceContractId ? `<ContractId>${sourceContractId}</ContractId>` : ''
    }    <ContractInfo>\n` +
    `        <NameCreator>${data.contractCard.ContractInfo.NameCreator}</NameCreator>\n` +
    '        <FullContractName>\n' +
    `            <Type>${getContractType(data)}</Type>\n` +
    '            <ContractSubject>оказание услуг</ContractSubject>\n' +
    '            <ContractRequisites>\n' +
    `                <Number>${data.contractCard.ContractInfo.Number}</Number>\n` +
    `                <Date>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.Date,
    )}</Date>\n` +
    `                <Place>${data.contractCard.ContractInfo.Place}</Place>\n` +
    '            </ContractRequisites>\n' +
    '        </FullContractName>\n' +
    `        <EffectiveDate>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.EffectiveDate,
    )}</EffectiveDate>\n` +
    `        <ExpirationDate>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.ExpirationDate,
    )}</ExpirationDate>\n` +
    '        <SourceContractInfo>\n' +
    `            <Number>${data.contractCard.ContractInfo.SourceContractInfo.Number}</Number>\n` +
    `            <Date>${formatDateToXmlDateFormat(
      data.contractCard.ContractInfo.SourceContractInfo.Date,
    )}</Date>\n` +
    `            <Place>${data.contractCard.ContractInfo.SourceContractInfo.Place}</Place>\n` +
    '        </SourceContractInfo>\n' +
    `        <ContractLifeCycleStatus>${getContractLifeCycleStatus(
      data,
    )}</ContractLifeCycleStatus>\n` +
    '    </ContractInfo>\n' +
    '    <Supplier>\n' +
    `       ${getCompanyInfo(data.contractCard.Supplier)}\n` +
    '    </Supplier>\n' +
    '\n' +
    '    <Performer>\n' +
    `       ${getCompanyInfo(data.contractCard.Performer)}\n` +
    '    </Performer>\n' +
    '\n' +
    '    <Additions>\n' +
    '        <TechnologicalConnectionReceptionPointList>\n' +
    `            ${TechnologicalConnectionReceptionPoint}\n` +
    '        </TechnologicalConnectionReceptionPointList>\n' +
    '        <TechnologicalConnectionSupplyPointList>\n' +
    `            ${TechnologicalConnectionSupplyPoint}\n` +
    '        </TechnologicalConnectionSupplyPointList>\n' +
    '        <SupplyPointList>\n' +
    `            ${SupplyPoint}\n` +
    '        </SupplyPointList>\n' +
    '    </Additions>\n' +
    '\n' +
    '</ElectricityTransmissionContractTwoTSO>'
  );
}

function UsefulVacationDocument(data) {
  let departments = '';

  data.attachment2.departments.forEach((item) => {
    departments +=
      `      <Component kind="department" departmentName="${item.name.replaceAll(
        '"',
        '',
      )}">\n` +
      `        <Aggregate kind="ВН">${item.vn}</Aggregate>\n` +
      `        <Aggregate kind="СН1">${item.sn1}</Aggregate>\n` +
      `        <Aggregate kind="СН2">${item.sn2}</Aggregate>\n` +
      `        <Aggregate kind="НН">${item.nn}</Aggregate>\n` +
      `        <Summary>${item.total}</Summary>\n` +
      '      </Component>\n';
  });

  function getAggregate(values) {
    let result = '';

    result += '      <Aggregate kind="ВН">\n';

    let sum = 0;
    values.vn.forEach((item) => {
      result += `        <Company companyName="${item.name.replaceAll(
        '"',
        '',
      )}">${item.value}</Company>\n`;
      sum += Number(item.value);
    });

    result += `        <Sum>${sum}</Sum>\n`;
    result += '      </Aggregate>\n';

    result += '      <Aggregate kind="СН1">\n';

    sum = 0;
    values.sn1.forEach((item) => {
      result += `        <Company companyName="${item.name.replaceAll(
        '"',
        '',
      )}">${item.value}</Company>\n`;
      sum += Number(item.value);
    });

    result += `        <Sum>${sum}</Sum>\n`;
    result += '      </Aggregate>\n';

    result += '      <Aggregate kind="СН2">\n';

    sum = 0;
    values.sn2.forEach((item) => {
      result += `        <Company companyName="${item.name.replaceAll(
        '"',
        '',
      )}">${item.value}</Company>\n`;
      sum += Number(item.value);
    });

    result += `        <Sum>${sum}</Sum>\n`;
    result += '      </Aggregate>\n';

    result += '      <Aggregate kind="НН">\n';

    sum = 0;
    values.nn.forEach((item) => {
      result += `        <Company companyName="${item.name.replaceAll(
        '"',
        '',
      )}">${item.value}</Company>\n`;
      sum += Number(item.value);
    });

    result += `        <Sum>${sum}</Sum>\n`;
    result += '      </Aggregate>\n';

    return result;
  }


  function getVolumeAggregate(volumeName, data, unit) {
    return (
      `     <${volumeName}>\n` +
      `         <Settled unit="${unit}" xmlns="http://energochain.ru/XML/documents/balance-documents-common/1.0">\n` +
      `            <Aggregate kind="ВН">${data.vn}</Aggregate>\n` +
      `            <Aggregate kind="СН1">${data.sn1}</Aggregate>\n` +
      `            <Aggregate kind="СН2">${data.sn2}</Aggregate>\n` +
      `            <Aggregate kind="НН">${data.nn}</Aggregate>\n` +
      `            <Summary>${data.total}</Summary>\n` +
      '         </Settled>\n' +
      `         <Unsettled unit="${unit}" xmlns="http://energochain.ru/XML/documents/balance-documents-common/1.0">\n` +
      '            <Aggregate kind="ВН">0</Aggregate>\n' +
      '            <Aggregate kind="СН1">0</Aggregate>\n' +
      '            <Aggregate kind="СН2">0</Aggregate>\n' +
      '            <Aggregate kind="НН">0</Aggregate>\n' +
      '            <Summary>0</Summary>\n' +
      '         </Unsettled>\n' +
      `         <New unit="${unit}" xmlns="http://energochain.ru/XML/documents/balance-documents-common/1.0">\n` +
      '            <Aggregate kind="ВН">0</Aggregate>\n' +
      '            <Aggregate kind="СН1">0</Aggregate>\n' +
      '            <Aggregate kind="СН2">0</Aggregate>\n' +
      '            <Aggregate kind="НН">0</Aggregate>\n' +
      '            <Summary>0</Summary>\n' +
      '         </New>\n' +
      `         <InProcess unit="${unit}" xmlns="http://energochain.ru/XML/documents/balance-documents-common/1.0">\n` +
      '            <Aggregate kind="ВН">0</Aggregate>\n' +
      '            <Aggregate kind="СН1">0</Aggregate>\n' +
      '            <Aggregate kind="СН2">0</Aggregate>\n' +
      '            <Aggregate kind="НН">0</Aggregate>\n' +
      '            <Summary>0</Summary>\n' +
      '         </InProcess>\n' +
      `         <Disagreements unit="${unit}" xmlns="http://energochain.ru/XML/documents/balance-documents-common/1.0">\n` +
      '            <Aggregate kind="ВН">0</Aggregate>\n' +
      '            <Aggregate kind="СН1">0</Aggregate>\n' +
      '            <Aggregate kind="СН2">0</Aggregate>\n' +
      '            <Aggregate kind="НН">0</Aggregate>\n' +
      '            <Summary>0</Summary>\n' +
      '         </Disagreements>\n' +
      `     </${volumeName}>\n`
    );
  }

  const dkp = getAggregate(data.attachment2.voltageClassValuesDKP.values);
  const powerSummary = getAggregate(data.attachment2.powerSummary.values);
  const powerFourSix = getAggregate(data.attachment2.powerFourSix.values);
  const signatory = data.isDisplayDocumentScan ? data.inputSignatory : data.currentSignatory;

  let transits = '';
  data.attachment2.transit.forEach((item) => {
    transits +=
      `      <Component supplierName="${item.name.replaceAll('"', '')}">\n` +
      `        <Aggregate kind="ВН">${item.vn}</Aggregate>\n` +
      `        <Aggregate kind="СН1">${item.sn1}</Aggregate>\n` +
      `        <Aggregate kind="СН2">v${item.sn2}</Aggregate>\n` +
      `        <Aggregate kind="НН">${item.nn}</Aggregate>\n` +
      `        <Summary>${item.total}</Summary>\n` +
      '      </Component>\n';
  });
  return (
    '<?xml version="1.0" encoding="utf-8"?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UsefulVacationDocument.xsl"?>\n' +
    '<UsefulVacationDocument xmlns="http://energochain.ru/XML/documents/useful-vacation-document/1.0"\n' +
    '                        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                        xsi:schemaLocation="http://energochain.ru/XML/documents/useful-vacation-document/1.0 UsefulVacationDocument.xsd">\n' +
    '\n' +
    `  <YearMonth>${data.AccountingPeriod}</YearMonth>\n` +
    '\n' +
    '  <UtilityInfo>\n' +
    `    <Name>${data.UtilityInfo.name}</Name>\n` +
    `    <INN>${data.UtilityInfo.inn}</INN>\n` +
    '  </UtilityInfo>\n' +
    '\n' +
    '  <RetailerInfo>\n' +
    '    <Name>АО "Мосэнергосбыт"</Name>\n' +
    '    <INN>7736520080</INN>\n' +
    `    <DepartmentName>${data.Retailer.name}</DepartmentName>\n` +
    `    <DepartmentId>${data.Retailer.id}</DepartmentId>\n` +
    '    <Representative>\n' +
    '        <Name case="Nominative">\n' +
    `            <FamilyName>${signatory.fullName.surname}</FamilyName>\n` +
    `            <FirstName>${signatory.fullName.name}</FirstName>\n` +
    `            <Patronymic>${signatory.fullName.patronymic}</Patronymic>\n` +
    '        </Name>\n' +
    '        <Name case="Genitive">\n' +
    `            <FamilyName>${signatory.fullNameGenitive.surname}</FamilyName>\n` +
    `            <FirstName>${signatory.fullNameGenitive.name}</FirstName>\n` +
    `            <Patronymic>${signatory.fullNameGenitive.patronymic}</Patronymic>\n` +
    '        </Name>\n' +
    `        <Position case="Nominative">${signatory.role.name}</Position>\n` +
    `        <Position case="Genitive">${signatory.role.nameGenitive}</Position>\n` +
    `${getCompletedTag('UserId', signatory.userId, '        ')}` +
    `${getCompletedTag('RoleId', signatory.role.id, '        ')}` +
    '        <Reason>\n' +
    `          <ReasonDocument case="Nominative">${signatory.reason.name}</ReasonDocument>\n` +
    `          <ReasonDocument case="Genitive">${signatory.reason.nameGenitive}</ReasonDocument>\n` +
    `         ${getCompletedTag('Number', signatory.reason.number)}\n` +
    `         ${getCompletedTag('Date', formatDateToXmlDateFormat(signatory.reason.date))}\n` +
    `         ${getCompletedTag('Id', signatory.powerOfAttorneyId)}\n` +
    '        </Reason>\n' +
    '    </Representative>\n' +
    '  </RetailerInfo>\n' +
    '\n' +
    '  <Units>\n' +
    `    <Electricity>${data.unitType}</Electricity>\n` +
    `    <Power>${data.powerUnitType}</Power>\n` +
    '  </Units>\n' +
    '\n' +
    '  <VolumesByTariffCategories>\n' +
    `      ${getVolumeAggregate('VolumesByOneTwoTariffCategory', data.attachment2.value_1, data.unitType)}\n` +
    `      ${getVolumeAggregate('VolumesByThreeSixTariffCategory', data.attachment2.value_2_6, data.unitType)}\n` +
    `      ${getVolumeAggregate('PowerByThreeSixTariffCategory', data.attachment2.powerFourSixVolumes, data.powerUnitType)}\n` +
    '  </VolumesByTariffCategories>\n' +
    '\n' +
    '  <UsefulVacation>\n' +
    '    <Useful>\n' +
    '      <Component kind="summary">\n' +
    `        <Aggregate kind="ВН">${data.attachment2.value_1.vn}</Aggregate>\n` +
    `        <Aggregate kind="СН1">${data.attachment2.value_1.sn1}</Aggregate>\n` +
    `        <Aggregate kind="СН2">${data.attachment2.value_1.sn2}</Aggregate>\n` +
    `        <Aggregate kind="НН">${data.attachment2.value_1.nn}</Aggregate>\n` +
    `        <Summary>${data.attachment2.value_1.total}</Summary>\n` +
    '      </Component>\n' +
    '      <Component kind="fourSix">\n' +
    `        <Aggregate kind="ВН">${data.attachment2.value_2_6.vn}</Aggregate>\n` +
    `        <Aggregate kind="СН1">${data.attachment2.value_2_6.sn1}</Aggregate>\n` +
    `        <Aggregate kind="СН2">${data.attachment2.value_2_6.sn2}</Aggregate>\n` +
    `        <Aggregate kind="НН">${data.attachment2.value_2_6.nn}</Aggregate>\n` +
    `        <Summary>${data.attachment2.value_2_6.total}</Summary>\n` +
    '      </Component>\n' +
    `      ${departments}\n` +
    '    </Useful>\n' +
    '    <Power>\n' +
    '      <ComponentPower kind="summary">\n' +
    `        ${powerSummary}\n` +
    `        <Summary>${data.attachment2.powerSummary.values.total}</Summary>\n` +
    '      </ComponentPower>\n' +
    '      <ComponentPower kind="fourSix">\n' +
    `        ${powerFourSix}\n` +
    `        <Summary>${data.attachment2.powerFourSix.values.total}</Summary>\n` +
    '      </ComponentPower>\n' +
    '    </Power>\n' +
    '    <DKP>\n' +
    `      ${dkp}\n` +
    `      <Summary>${data.attachment2.voltageClassValuesDKP.values.total}</Summary>\n` +
    '    </DKP>\n' +
    '    <OwnNeeds>\n' +
    `      <Aggregate kind="ВН">${data.attachment2.ownConsumption.vn}</Aggregate>\n` +
    `      <Aggregate kind="СН1">${data.attachment2.ownConsumption.sn1}</Aggregate>\n` +
    `      <Aggregate kind="СН2">${data.attachment2.ownConsumption.sn2}</Aggregate>\n` +
    `      <Aggregate kind="НН">${data.attachment2.ownConsumption.nn}</Aggregate>\n` +
    `      <Summary>${data.attachment2.ownConsumption.total}</Summary>\n` +
    '    </OwnNeeds>\n' +
    '    <Transit>\n' +
    `      ${transits}\n` +
    '    </Transit>\n' +
    '  </UsefulVacation>\n' +
    '</UsefulVacationDocument>'
  );
}

function EighteenthFormsIndividual(
  data,
  voltageClassList,
  typeAccountingList,
  tariffCategoryTypeList,
  AccountingPeriod,
  typeAccountingListLong,
  UtilityInfo,
  Retailer,
  signatory,
) {
  let UsagePoints = '';

  data.forEach((point) => {
    UsagePoints +=
      '        <efc:UsagePoint>\n' +
      `            <efc:CompanyName>${point[1]}</efc:CompanyName>\n` +
      `            <efc:NMELSETI_M>${point[2]}</efc:NMELSETI_M>\n` +
      `            <efc:NNELSETI_M>${point[3]}</efc:NNELSETI_M>\n` +
      '            <efc:SupplyPointName>\n' +
      `                <efc:MainSubstation>${point[4]}</efc:MainSubstation>\n` +
      `                <efc:Feeder>${point[5]}</efc:Feeder>\n` +
      `                <efc:DistributionSubstation>${point[6]}</efc:DistributionSubstation>\n` +
      `                <efc:Line>${point[7]}</efc:Line>\n` +
      `                <efc:TransformationSubstation>${point[8]}</efc:TransformationSubstation>\n` +
      `                <efc:Other>${point[9]}</efc:Other>\n` +
      '            </efc:SupplyPointName>\n' +
      `            <efc:IKTS>${point[10]}</efc:IKTS>\n` +
      `            <efc:SubscriberName>${point[11]}</efc:SubscriberName>\n` +
      `            <efc:SubscriberInn>${point[12]}</efc:SubscriberInn>\n` +
      `            <efc:Location>${point[13]}</efc:Location>\n` +
      `            <efc:Street>${point[14]}</efc:Street>\n` +
      `            <efc:House>${point[15]}</efc:House>\n` +
      `            <efc:Flat>${point[16]}</efc:Flat>\n` +
      '            <efc:Meter>\n' +
      `                <tc:Type>${point[17]}</tc:Type>\n` +
      `                <tc:Serial>${point[18]}</tc:Serial>\n` +
      '            </efc:Meter>\n' +
      `            <efc:CHZN>${point[19]}</efc:CHZN>\n` +
      `            <efc:AccountingRatio>${point[20]}</efc:AccountingRatio>\n` +
      `            <efc:MeterInstalledDate>${point[21]}</efc:MeterInstalledDate>\n` +
      `            <efc:MeterReplacedDate>${point[22]}</efc:MeterReplacedDate>\n` +
      `            <efc:ExternalId>${point[23]}</efc:ExternalId>\n` +
      '            <efc:MeterReading zone="S">\n' +
      `                <mc:PreviousReading>${point[24]}</mc:PreviousReading>\n` +
      `                <mc:CurrentReading>${point[25]}</mc:CurrentReading>\n` +
      '            </efc:MeterReading>\n' +
      `            <efc:PKVID>${point[34]}</efc:PKVID>\n` +
      `            <efc:PreviousReadingDate>${point[35]}</efc:PreviousReadingDate>\n` +
      `            <efc:CurrentReadingDate>${point[36]}</efc:CurrentReadingDate>\n` +
      `            <efc:PTPUPRC>${point[37]}</efc:PTPUPRC>\n` +
      `            <efc:PTPU>${point[38]}</efc:PTPU>\n` +
      `            <efc:POTERI_PRO>${point[39]}</efc:POTERI_PRO>\n` +
      `            <efc:POTERI_RAS>${point[40]}</efc:POTERI_RAS>\n` +
      `            <efc:NPOTERI_PR>${point[41]}</efc:NPOTERI_PR>\n` +
      `            <efc:NPOTERI_RA>${point[42]}</efc:NPOTERI_RA>\n` +
      `            <efc:HPOTERI_PR>${point[43]}</efc:HPOTERI_PR>\n` +
      `            <efc:HPOTERI_RA>${point[44]}</efc:HPOTERI_RA>\n` +
      `            <efc:TotalConsumtion>${point[45]}</efc:TotalConsumtion>\n` +
      `            <efc:RASHOD_AKT>${point[46]}</efc:RASHOD_AKT>\n` +
      `            <efc:RASHOD_ODN>${point[47]}</efc:RASHOD_ODN>\n` +
      `            <efc:RASHOD_STA>${point[48]}</efc:RASHOD_STA>\n` +
      `            <efc:RASHOD_NOR>${point[49]}</efc:RASHOD_NOR>\n` +
      `            <efc:Voltage>${getValueByLabel(
        point[50],
        voltageClassList,
      )}</efc:Voltage>\n` +
      `            <efc:STAT_ABON>${point[51]}</efc:STAT_ABON>\n` +
      `            <efc:STAT_SHETC>${point[52]}</efc:STAT_SHETC>\n` +
      `            <efc:PU_ZAM>${point[53]}</efc:PU_ZAM>\n` +
      `            <efc:KD_CONS>${point[54]}</efc:KD_CONS>\n` +
      `            <efc:Remark>${point[55]}</efc:Remark>\n` +
      `            <efc:IKTU>${point[56]}</efc:IKTU>\n` +
      `            <efc:UCHET>${point[57]}</efc:UCHET>\n` +
      `            <efc:HouseType>${point[58]}</efc:HouseType>\n` +
      `            <efc:StoveType>${point[59]}</efc:StoveType>\n` +
      `            <efc:CNT_ROOM>${point[60]}</efc:CNT_ROOM>\n` +
      `            <efc:CNT_FML>${point[61]}</efc:CNT_FML>\n` +
      `            <efc:NM_SCHEMA>${point[62]}</efc:NM_SCHEMA>\n` +
      `            <efc:NM_DOG>${point[63]}</efc:NM_DOG>\n` +
      `            <efc:KD_OBJ>${point[64]}</efc:KD_OBJ>\n` +
      '        </efc:UsagePoint>\n';
  });

  let xml = '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="EighteenthFormsIndividuals.xsl" ?>\n' +
    '<EighteenthFormsIndividuals xmlns="http://energochain.ru/XML/documents/eighteenth-forms-individuals/1.0"\n' +
    '                 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '                 xmlns:efc="http://energochain.ru/XML/documents/eighteenth-forms-common/1.0"\n' +
    '                 xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"\n' +
    '                 xmlns:mc="http://energochain.ru/XML/documents/measurements-common/1.0"\n' +
    '                 xmlns:cc="http://energochain.ru/XML/documents/company-common/1.0"\n' +
    '                 xsi:schemaLocation="http://energochain.ru/XML/documents/eighteenth-forms-individuals/1.0 EighteenthFormsIndividuals.xsd">\n' +
    `    <AccountingPeriod>${AccountingPeriod}</AccountingPeriod>\n` +
    '    <UtilityInfo>\n' +
    `      <cc:Name>${UtilityInfo.name}</cc:Name>\n` +
    `      <cc:INN>${UtilityInfo.inn}</cc:INN>\n` +
    '    </UtilityInfo>\n' +
    '    <RetailerInfo>\n' +
    '      <cc:Name>АО "Мосэнергосбыт"</cc:Name>\n' +
    '      <cc:INN>7736520080</cc:INN>\n' +
    `      <cc:DepartmentName>${Retailer}</cc:DepartmentName>\n`;
  if (signatory) xml = `${xml
    }        <cc:Representative>\n` +
    '            <cc:Name case="Nominative">\n' +
    `                <cc:FamilyName>${signatory.fullName.surname}</cc:FamilyName>\n` +
    `                <cc:FirstName>${signatory.fullName.name}</cc:FirstName>\n` +
    `                <cc:Patronymic>${signatory.fullName.patronymic}</cc:Patronymic>\n` +
    '            </cc:Name>\n' +
    '            <cc:Name case="Genitive">\n' +
    `                <cc:FamilyName>${signatory.fullNameGenitive.surname}</cc:FamilyName>\n` +
    `                <cc:FirstName>${signatory.fullNameGenitive.name}</cc:FirstName>\n` +
    `                <cc:Patronymic>${signatory.fullNameGenitive.patronymic}</cc:Patronymic>\n` +
    '            </cc:Name>\n' +
    `            <cc:Position case="Nominative">${signatory.role.name}</cc:Position>\n` +
    `            <cc:Position case="Genitive">${signatory.role.nameGenitive}</cc:Position>\n` +
    `            <cc:UserId>${signatory.userId}</cc:UserId>\n` +
    `            <cc:RoleId>${signatory.role.id}</cc:RoleId>\n` +
    '            <cc:Reason>\n' +
    `              <cc:ReasonDocument case="Nominative">${signatory.reason.name}</cc:ReasonDocument>\n` +
    `              <cc:ReasonDocument case="Genitive">${signatory.reason.nameGenitive}</cc:ReasonDocument>\n` +
    `             ${getCompletedTag('cc:Number', signatory.reason.number)}\n` +
    `             ${getCompletedTag('cc:Date', formatDateToXmlDateFormat(signatory.reason.date))}\n` +
    '            </cc:Reason>\n' +
    '        </cc:Representative>\n';

  xml = `${xml}    </RetailerInfo>\n` +
    `    <UsagePointsList>\n${
      UsagePoints
    }    </UsagePointsList>\n` +
    '</EighteenthFormsIndividuals>';
  return xml;
}

function agreeIntegralAct(documentId, type) {
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<DocumentReview\n' +
    '  xmlns="http://energochain.ru/XML/documents/document-review/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/document-review/1.0 DocumentReview.xsd">\n' +
    '  <DocumentId>'}${
      documentId
    }</DocumentId>\n` +
    `  <Type>${
      type
    }</Type>\n` +
    '  <Comments>Корректировка, неисправность счётчика</Comments>\n' +
    '</DocumentReview>'
  );
}

function ElectricityPurchaseReportIndications(
  data,
  Date,
  voltageClassList,
  accountingMethodList,
) {

  const Utility =
    '    <Utility>\n' +
    '        <Representative>\n' +
    '            <Name case="Nominative">\n' +
    `                <FamilyName>${data.Utility.inCase.fio.surname}</FamilyName>\n` +
    `                <FirstName>${data.Utility.inCase.fio.name}</FirstName>\n` +
    `                <Patronymic>${data.Utility.inCase.fio.patronymic}</Patronymic>\n` +
    '            </Name>\n' +
    `            <Position case="Nominative">${data.Utility.inCase.position}</Position>\n` +
    '        </Representative>\n' +
    '    </Utility>\n';

  const Retailer =
    data.Retailer?.inCase.fio.name.length > 0
      ? `    <Retailer>
        <Representative>
          <Name case="Genitive">
            <FamilyName>${data.Retailer.inCase.fio.surname}</FamilyName>
            <FirstName>${data.Retailer.inCase.fio.name}</FirstName>
            <Patronymic>${data.Retailer.inCase.fio.patronymic}</Patronymic>
          </Name>
          <Position case="Genitive">${data.Retailer.inCase.position}</Position>
        </Representative>
    </Retailer>\n`
      : '';

  const MeasuringComplex =
    '    <MeasuringComplex>\n' +
    // `        <PlaceInstallation>${data.MeasuringComplex.PlaceInstallation}</PlaceInstallation>\n` +
    '    </MeasuringComplex>\n';

  let Points = '';

  data.attachment1.forEach((point) => {
    Points +=
      '        <Point>\n' +
      `            <RelatedUtilityCompany>${point[1]}</RelatedUtilityCompany>\n` +
      `            <PointKind>${data.UsagePointKind}</PointKind>\n` +
      '            <SupplyPointName>\n' +
      `                <MainSubstation>${point[2]}</MainSubstation>\n` +
      `                <Feeder>${point[3]}</Feeder>\n` +
      `                <DistributionSubstation>${point[4]}</DistributionSubstation>\n` +
      `                <Line>${point[5]}</Line>\n` +
      `                <TransformationSubstation>${point[6]}</TransformationSubstation>\n` +
      `                <Other>${point[7]}</Other>\n` +
      '            </SupplyPointName>\n' +
      `            <MeterSerial>${point[8]}</MeterSerial>\n` +
      `            <PreviousReading>${point[9]}</PreviousReading>\n` +
      `            <CurrentReading>${point[10]}</CurrentReading>\n` +
      `            <AdditionalVolume>${point[11]}</AdditionalVolume>\n` +
      `            <MaxPower>${point[12]}</MaxPower>\n` +
      `            <VoltageLevel>${getValueByLabel(
        point[13],
        voltageClassList,
      )}</VoltageLevel>\n` +
      `            <AccountingMethod>${getValueByLabel(
        point[14],
        accountingMethodList,
      )}</AccountingMethod>\n` +
      `            <Remark>${point[15]}</Remark>\n` +
      '        </Point>\n';
  });

  return (
    `${'<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityPurchaseReport.xsl" ?>\n' +
    '<ElectricityPurchaseReportNew\n' +
    '        xmlns="http://energochain.ru/XML/documents/electricity-purchase-report/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-purchase-report/1.0 ElectricityPurchaseReportNew.xsd">\n' +
    `    <CreatedAt>${dayjs().utc().format('YYYY-MM-DD')}</CreatedAt>\n` +
    `    <YearMonth>${Date}</YearMonth>\n`}${
      Utility
    }${Retailer
    }${MeasuringComplex
    }    <Report>\n${
      Points
    }    </Report>\n` +
    '</ElectricityPurchaseReportNew>'
  );
}

function ElectricityPurchaseReportFinally(
  data,
  Date,
  voltageClassList,
  accountingMethodList,
) {

  const Utility =
    '    <Utility>\n' +
    '        <Representative>\n' +
    '            <Name case="Nominative">\n' +
    `                <FamilyName>${data.Utility.inCase.fio.surname}</FamilyName>\n` +
    `                <FirstName>${data.Utility.inCase.fio.name}</FirstName>\n` +
    `                <Patronymic>${data.Utility.inCase.fio.patronymic}</Patronymic>\n` +
    '            </Name>\n' +
    `            <Position case="Nominative">${data.Utility.inCase.position}</Position>\n` +
    '        </Representative>\n' +
    '    </Utility>\n';

  const Retailer =
    data.Retailer?.inCase.fio.name.length > 0
      ? `    <Retailer>
        <Representative>
          <Name case="Genitive">
            <FamilyName>${data.Retailer.inCase.fio.surname}</FamilyName>
            <FirstName>${data.Retailer.inCase.fio.name}</FirstName>
            <Patronymic>${data.Retailer.inCase.fio.patronymic}</Patronymic>
          </Name>
          <Position case="Genitive">${data.Retailer.inCase.position}</Position>
        </Representative>
    </Retailer>\n`
      : '';

  const MeasuringComplex =
    '    <MeasuringComplex>\n' +
    `        <PlaceInstallation>${data.MeasuringComplex.PlaceInstallation}</PlaceInstallation>\n` +
    '    </MeasuringComplex>\n';

  const Consumer =
    `
      <Consumer>
        <Name>${data.Consumer.Name}</Name>
        <Representative>
            <Name case="Genitive">
                <FamilyName>${data.Consumer.Representative.Name.FamilyName}</FamilyName>
                <FirstName>${data.Consumer.Representative.Name.FirstName}</FirstName>
                <Patronymic>${data.Consumer.Representative.Name.Patronymic}</Patronymic>
            </Name>
            <Position case="Genitive">${data.Consumer.Representative.Position}</Position>
        </Representative>
        <ObjectName>${data.Consumer.ObjectName}</ObjectName>
        <Address>${data.Consumer.Address}</Address>
      </Consumer>
    `;

  let Points = '';

  data.attachment1.forEach((point) => {
    Points +=
      '        <Point>\n' +
      `            <RelatedUtilityCompany>${point[1]}</RelatedUtilityCompany>\n` +
      '            <PointKind>delivery</PointKind>\n' +
      '            <SupplyPointName>\n' +
      `                <MainSubstation>${point[2]}</MainSubstation>\n` +
      `                <Feeder>${point[3]}</Feeder>\n` +
      `                <DistributionSubstation>${point[4]}</DistributionSubstation>\n` +
      `                <Line>${point[5]}</Line>\n` +
      `                <TransformationSubstation>${point[6]}</TransformationSubstation>\n` +
      `                <Other>${point[7]}</Other>\n` +
      '            </SupplyPointName>\n' +
      `            <MeterSerial>${point[8]}</MeterSerial>\n` +
      `            <PreviousReading>${point[9]}</PreviousReading>\n` +
      `            <CurrentReading>${point[10]}</CurrentReading>\n` +
      `            <AdditionalVolume>${point[11]}</AdditionalVolume>\n` +
      `            <MaxPower>${point[12]}</MaxPower>\n` +
      `            <VoltageLevel>${getValueByLabel(
        point[13],
        voltageClassList,
      )}</VoltageLevel>\n` +
      `            <AccountingMethod>${getValueByLabel(
        point[14],
        accountingMethodList,
      )}</AccountingMethod>\n` +
      `            <Remark>${point[15]}</Remark>\n` +
      '        </Point>\n';
  });

  return (
    `${'<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityPurchaseReport.xsl" ?>\n' +
    '<ElectricityPurchaseReportNew\n' +
    '        xmlns="http://energochain.ru/XML/documents/electricity-purchase-report/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-purchase-report/1.0 ElectricityPurchaseReportNew.xsd">\n' +
    `    <CreatedAt>${dayjs().utc().format('YYYY-MM-DD')}</CreatedAt>\n` +
    `    <YearMonth>${Date}</YearMonth>\n`}${
      Utility
    }${Retailer
    }${Consumer
    }${MeasuringComplex
    }    <Report>\n${
      Points
    }    </Report>\n` +
    '</ElectricityPurchaseReportNew>'
  );
}

function AddUsersToOrganisationApplication(data, date) {
  let User = '';

  data.forEach((item) => (User +=
    '      <User>\n' +
    `          <Email>${item.email}</Email>\n` +
    `          <Role>${item.role}</Role>\n` +
    '      </User>\n'));

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="LegalEntityConnectionApplication.xsl" ?>\n' +
    '<UpdateUsersInOrganisationApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/add-users-to-organisation-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/add-users-to-organisation-application/1.0 AddUsersToOrganisationApplication.xsd">\n' +
    `  <Date>${formatDateToXmlDateFormat(date)}</Date>\n` +
    '  <UsersChangelist>\n' +
    '     <UserInvites>\n'}${
      User
    }     </UserInvites>\n` +
    '  </UsersChangelist>\n' +
    '</UpdateUsersInOrganisationApplication>'
  );
}

function RevokeUserInvitationApplication(email, date) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="LegalEntityConnectionApplication.xsl" ?>\n' +
    '<UpdateUsersInOrganisationApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/add-users-to-organisation-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/add-users-to-organisation-application/1.0 AddUsersToOrganisationApplication.xsd">\n' +
    `  <Date>${formatDateToXmlDateFormat(date)}</Date>\n` +
    '  <UsersChangelist>\n' +
    '     <UserRevocations>\n' +
    '      <User>\n' +
    `          <Email>${email}</Email>\n` +
    '      </User>\n' +
    '     </UserRevocations>\n' +
    '  </UsersChangelist>\n' +
    '</UpdateUsersInOrganisationApplication>'
  );
}

function OrganisationStructureChangingOrder(accountId, userId, data) {

  let xml = '<?xml version="1.0" encoding="UTF-8" ?>\n ' +
    '<OrganisationStructureChangingOrder ' +
    ' xmlns="http://energochain.ru/XML/documents/organisation-structure-changing-order/1.0" ' +
    ' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/organisation-structure-changing-order/1.0 OrganizationStructureChangingOrder.xsd"> \n' +
    `  <OrganisationAccountId>${accountId}</OrganisationAccountId> \n` +
    `  <UserId>${userId}</UserId> \n` +
    '  <OrganisationStructureChangeList> \n';

  if (data.rolesToCreate && !data.createSubdivision) {
    data.rolesToCreate.forEach((role) => {
      xml += '    <CreateRole> \n' +
        `        <Name>${role.name}</Name> \n`;

      if (role.permissions?.length > 0) {
        xml += '        <Permissions> \n';

        role.permissions.forEach((permission) => {
          xml += `                <Permission>${permission.code}</Permission>\n`;
        });

        xml += '        </Permissions> \n';
      } else {
        xml += '        <Permissions/> \n';
      }

      xml += `        ${getCompletedTag('ParentRoleId', role.parentRoleId)}` +
        `        ${getCompletedTag('SubdivisionId', role.subdivisionId)}` +
        '    </CreateRole> \n';
    });
  }

  if (data.roleToUpdate) {
    data.roleToUpdate.forEach((role) => {
      xml +=
        '    <ChangeRole> \n' +
        `        <RoleId>${role.id}</RoleId> \n` +
        `        <Name>${role.name}</Name> \n` +
        `        ${getCompletedTag('ParentRoleId', role.parentRoleId)}` +
        `        ${getCompletedTag('SubdivisionId', role.subdivisionId)}`;

      if (role.permissions?.length > 0) {
        xml += '        <Permissions> \n';

        role.permissions.forEach((permission) => {
          xml += `            <Permission>${permission.code}</Permission>\n`;
        });

        xml += '        </Permissions> \n';
      }

      xml += '    </ChangeRole> \n';
    });
  }

  if (data.roleToDelete) {
    data.roleToDelete.forEach((role) => {
      xml +=
        '    <DeleteRole> \n' +
        `      <RoleId>${role.id}</RoleId> \n` +
        '    </DeleteRole> \n';
    });
  }

  if (data.roleToDeleteFromSubdivision) {
    data.roleToDeleteFromSubdivision.forEach((role) => {
      xml +=
        '    <ChangeRole> \n' +
        `      <RoleId>${role.id}</RoleId> \n` +
        '      <SubdivisionId></SubdivisionId> \n' +
        '    </ChangeRole> \n';
    });
  }

  if (data.permissionsToAdd?.permissions) {
    xml += '    <AddPermissions> \n' +
      `      <RoleId>${data.permissionsToAdd.roleId}</RoleId> \n` +
      '      <Permissions> \n';
    data.permissionsToAdd.permissions.forEach((permission) => {
      xml += `        <Permission>${permission}</Permission>\n`;
    });
    xml += '      </Permissions> \n' +
      '    </AddPermissions> \n';
  }

  if (data.permissionsToDelete?.permissions) {
    xml += '    <DeletePermissions> \n' +
      `      <RoleId>${data.permissionsToDelete.roleId}</RoleId> \n` +
      '      <Permissions> \n';
    data.permissionsToDelete.permissions.forEach((permission) => {
      xml += `        <Permission>${permission}</Permission>\n`;
    });
    xml += '      </Permissions> \n' +
      '    </DeletePermissions> \n';
  }

  if (data.createSubdivision) {
    xml += '    <CreateSubdivision>\n' +
      `        <Name>${data.createSubdivision.subdivisionName}</Name>\n` +
      `        ${getCompletedTag('PhoneNumber', data.createSubdivision.phoneNumber)}` +
      `        ${getCompletedTag('DistrictDepartmentId', data.createSubdivision.districtDepartmentId)}` +
      `            <Description>департамент ${data.createSubdivision.subdivisionName}</Description>\n` +
      '        <OwnerRole>\n' +
      `            <Name>${data.createSubdivision.name}</Name>\n` +
      `            <ParentRoleId>${data.createSubdivision.parentRoleId}</ParentRoleId>\n` +
      '        </OwnerRole>\n';

    if (data.rolesToCreate?.length > 0) {
      xml += '        <Roles>\n';

      data.rolesToCreate.forEach((role) => {
        xml += '            <Role>\n' +
          `                <Name>${role.name}</Name>\n`;

        if (role.permissions?.length > 0) {
          xml += '                <Permissions>\n';

          role.permissions.forEach((permission) => {
            xml += `                    <Permission>${permission.code}</Permission>\n`;
          });

          xml += '                </Permissions>\n';
        } else {
          xml += '        <Permissions/> \n';
        }

        xml += '            </Role>\n';
      });

      xml += '        </Roles>\n';
    }
    xml += `    <SubdivisionShift>${data.createSubdivision.shift}</SubdivisionShift>\n`;

    xml += '    </CreateSubdivision>\n';
  }

  if (data.changeSubdivision) {
    xml +=
      '    <ChangeSubdivision> \n' +
      `      <SubdivisionId>${data.changeSubdivision.subdivisionId}</SubdivisionId> \n` +
      `        ${getCompletedTag('PhoneNumber', data.changeSubdivision.phoneNumber)}` +
      `        ${getCompletedTag('DistrictDepartmentId', data.changeSubdivision.districtDepartmentId)}` +
      `      <Name>${data.changeSubdivision.subdivisionName}</Name> \n` +
      `      <SubdivisionShift>${data.changeSubdivision.shift}</SubdivisionShift> \n` +
      '    </ChangeSubdivision> \n';
  }

  if (data.deleteSubdivision) {
    xml +=
      '    <DeleteSubdivision> \n' +
      `      <SubdivisionId>${data.deleteSubdivision.subdivisionId}</SubdivisionId> \n` +
      '    </DeleteSubdivision> \n';
  }

  return (`${xml
    }  </OrganisationStructureChangeList> \n` +
    '</OrganisationStructureChangingOrder> '
  );
}

function addPowerOfAttorneyToUser(user, data, grantedAt, startsFrom, expiresAt) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="PowerOfAttorney.xsl" ?>\n' +
    '<PowerOfAttorney\n' +
    '  xmlns="http://energochain.ru/XML/documents/power-of-attorney/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/power-of-attorney/1.0 PowerOfAttorney.xsd">\n' +
    `  <Date>${dayjs().utc().format()}</Date>\n` +
    `  <GrantedAt>${formatDateToXmlDateFormat(grantedAt)}</GrantedAt>\n` +
    `  <StartsFrom>${formatDateToXmlDateFormat(startsFrom)}</StartsFrom>\n` +
    `  <ExpiresAt>${formatDateToXmlDateFormat(expiresAt)}</ExpiresAt>\n` +
    `  <AccountId>${user.account.id}</AccountId>\n` +
    `  <UserId>${user.user.id}</UserId>\n` +
    '<Permissions/>\n' +
    `  <File xmime:contentType="${data.type}" name="${data.name}">${data.code}</File>\n` +
    '</PowerOfAttorney>'
  );
}

function UserAgreements({
                          agreedWithUserAgreement,
                          agreedWithPersonalDataAgreement,
                        }) {
  return `<?xml version="1.0" encoding="utf-8"?>
    <?xml-stylesheet type="text/xsl" href="UsefulVacationDocument.xsl"?>
    <UserAgreements xmlns="http://energochain.ru/XML/documents/user-agreements/1.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://energochain.ru/XML/documents/user-agreements/1.0
    UserAgreements.xsd">
      <AgreedWithUserAgreement>${agreedWithUserAgreement}</AgreedWithUserAgreement>
      <AgreedWithPersonalDataAgreement>${agreedWithPersonalDataAgreement}</AgreedWithPersonalDataAgreement>
    </UserAgreements>`;
}

function IndividualConnectionToPlatformApplication(
  user,
  agreementData,
) {
  const { agreement, agreementScans, documentScans, companyAccountId } = agreementData;

  let agreementScansStr = '';
  let documentScansStr = '';

  if (agreementScans && agreementScans?.length > 0) {
    agreementScansStr = serializeToString(
      createAttachmentScansElement('', 'AgreementScans', agreementScans, 'File'),
    );

    documentScansStr = serializeToString(
      createAttachmentScansElement('', 'DocumentScans', documentScans, 'File'),
    );
  }

  let connectionToCompanyBySupportBlock = '';

  if (user.role && companyAccountId) {
    connectionToCompanyBySupportBlock = `    <ConnectionToCompanyBySupportBlock>
            <CompanyAccountId>${companyAccountId}</CompanyAccountId>
            <RoleId>${user.role}</RoleId>
    </ConnectionToCompanyBySupportBlock>\n`;
  }

  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="LegalPlatformConnectionApplication.xsl" ?>\n' +
    '<IndividualConnectionToPlatformApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/individual-connection-to-platform-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/individual-connection-to-platform-application/1.0 IndividualConnectionToPlatformApplication.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    '    <Person>\n' +
    '        <FIO>\n' +
    `            <FamilyName>${user.secondName}</FamilyName>\n` +
    `            <FirstName>${user.firstName}</FirstName>\n` +
    `             ${getCompletedTag('Patronymic', user.patronymic)}\n` +
    '        </FIO>\n' +
    `        <Snils>${user.insuranceNumber}</Snils>\n`}${
      getCompletedTag('INN', user.inn)
    }        ${getCompletedTag('Phone', formatting.unformatPhonePrefix(user.phone))}\n` +
    `        ${getCompletedTag('PhoneVerificationCode', user.phoneCode)}\n` +
    `        ${getCompletedTag('Email', user.email)}\n` +
    `        ${getCompletedTag('EmailVerificationCode', user.emailCode)}\n` +
    '    </Person>\n' +
    `${connectionToCompanyBySupportBlock}` +
    '    <Agreements>\n' +
    `      <AgreedWithUserAgreement>${agreement.userAgreement}</AgreedWithUserAgreement>\n` +
    `      <AgreedWithPersonalDataAgreement>${agreement.personalDataAgreement}</AgreedWithPersonalDataAgreement>\n` +
    `      <AgreedWithPrivacyPolicy>${agreement.privacyPolicy}</AgreedWithPrivacyPolicy>\n` +
    `      <AgreedWithMarketing>${agreement.marketing}</AgreedWithMarketing>\n` +
    `${agreementScansStr}` +
    '    </Agreements>\n' +
    `${documentScansStr}` +
    '</IndividualConnectionToPlatformApplication>'
  );
}

function IndividualConnectionToPlatformApplicationIp(
  user,
  agreement,
) {
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="LegalPlatformConnectionApplication.xsl" ?>\n' +
    '<IndividualConnectionToPlatformApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/individual-connection-to-platform-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/individual-connection-to-platform-application/1.0 IndividualConnectionToPlatformApplication.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    '    <Person>\n' +
    '        <FIO>\n' +
    `            <FamilyName>${user.secondName}</FamilyName>\n` +
    `            <FirstName>${user.firstName}</FirstName>\n` +
    `             ${getCompletedTag('Patronymic', user.patronymic)}\n` +
    '        </FIO>\n' +
    `        <Snils>${user.insuranceNumber}</Snils>\n`}
             ${getCompletedTag('Phone', formatting.unformatPhonePrefix(user.phone))}\n` +
    `        ${getCompletedTag('PhoneVerificationCode', user.phoneCode)}` +
    `        ${getCompletedTag('Email', user.email)}` +
    `        ${getCompletedTag('EmailVerificationCode', user.emailCode)}` +
    `        ${getCompletedTag('INN', user.inn)}` +
    `        ${getCompletedTag('OGRNIP', user.ogrnip)}` +
    `        ${getCompletedTag('RegistrationAddress', user.address)}` +
    '    </Person>\n' +
    '    <Agreements>\n' +
    `      <AgreedWithUserAgreement>${agreement.userAgreement}</AgreedWithUserAgreement>\n` +
    `      <AgreedWithPersonalDataAgreement>${agreement.personalDataAgreement}</AgreedWithPersonalDataAgreement>\n` +
    `      <AgreedWithPrivacyPolicy>${agreement.privacyPolicy}</AgreedWithPrivacyPolicy>\n` +
    `      <AgreedWithMarketing>${agreement.marketing}</AgreedWithMarketing>\n` +
    '    </Agreements>\n' +
    '</IndividualConnectionToPlatformApplication>'
  );
}

function UpdateUserDataApplication(
  user,
  accountId,
  agreement,
) {
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UpdateUserDataApplication.xsl" ?>\n' +
    '<UpdateUserDataApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/update-user-data-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/update-user-data-application/1.0 UpdateUserDataApplication.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    `    <UserId>${user.id}</UserId>\n` +
    `    ${getCompletedTag('AccountId', accountId)}\n` +
    '    <Person>\n' +
    '        <FIO>\n' +
    `            <FamilyName>${user.secondName}</FamilyName>\n` +
    `            <FirstName>${user.firstName}</FirstName>\n` +
    `             ${getCompletedTag('Patronymic', user.patronymic)}\n` +
    '        </FIO>\n'}${
      user.secondNameGenitive && user.secondNameGenitive.length > 0
        ? '        <FIO case="Genitive">\n' +
        `            <FamilyName>${user.secondName}</FamilyName>\n` +
        `            <FirstName>${user.firstName}</FirstName>\n` +
        `             ${getCompletedTag('Patronymic', user.patronymic)}\n` +
        '        </FIO>\n' : ''
    }        <Snils>${user.insuranceNumber}</Snils>\n` +
    `        ${getCompletedTag('INN', user.inn)}` +
    `        ${getCompletedTag('OGRNIP', user.ogrnip)}` +
    `        ${getIdentityDocType(user.identityDocumentInfo, '        ')}` +
    `        ${getCompletedTag('Phone', user.phone?.length > 0 ? formatting.unformatPhonePrefix(user.phone) : undefined)}\n` +
    `        ${getCompletedTag('PhoneVerificationCode', user.phoneCode)}\n` +
    `        ${getCompletedTag('AdditionalPhone', user.additionalPhoneNumber?.length > 0 ? formatting.unformatPhonePrefix(user.additionalPhoneNumber) : undefined)}\n` +
    `        ${getCompletedTag('AdditionalPhoneVerificationCode', user.additionalPhoneNumberCode)}\n` +
    `        ${getCompletedTag('TelegramAccount', user.telegram)}` +
    `        ${getCompletedTag('Email', user.email)}\n` +
    `        ${getCompletedTag('EmailVerificationCode', user.emailCode)}\n` +
    `        ${getCompletedTag('AdditionalEmail', user.additionalEmail)}\n` +
    `        ${getCompletedTag('AdditionalEmailVerificationCode', user.additionalEmailCode)}\n` +
    `        ${getCompletedTag('RegistrationAddress', user.registrationAddress)}\n` +
    `        ${getCompletedTag('ResidenceAddress', user.address)}\n` +
    `        ${getCompletedTag('BirthPlace', user.birthPlace)}\n` +
    `     <BirthDate>${formatDateToXmlDateFormat(user.birthDate)}</BirthDate>` +
    `        ${getCompletedTag('Citizenship', user.citizenship)}\n${
      user.bankRequisites.bik.length > 0 ? '        <BankRequisites>\n' +
        `    <BIK>${user.bankRequisites.bik}</BIK>\n` +
        `    <SettlementAccount>${user.bankRequisites.settlementAccount}</SettlementAccount>\n` +
        `    <CorrespondentAccount>${user.bankRequisites.correspondentAccount}</CorrespondentAccount>\n` +
        `    <BankName>${user.bankRequisites.bankName}</BankName>\n` +
        '        </BankRequisites>\n' : ''
    }    </Person>` +
    `    <Agreement>${agreement}</Agreement>` +
    '</UpdateUserDataApplication>'
  );
}

function DeleteUserProfileApplication(
  user,
) {
  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UpdateUserDataApplication.xsl" ?>\n' +
    '<UserProfileDeleteApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/user-profile-delete-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/user-profile-delete-application/1.0 UserProfileDeleteApplication.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    `    <UserId>${user.id}</UserId>\n` +
    '</UserProfileDeleteApplication>'
  );
}

function RequestDeleteCompany(companyAccountId) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<RequestDeleteCompany\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/request-delete-company/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/request-delete-company/1.0 RequestDeleteCompany.xsd">\n' +
    `    <CompanyAccountId>${companyAccountId}</CompanyAccountId>\n` +
    '</RequestDeleteCompany>'
  );
}

function WorkerConnectionToPlatformApplication(accountId, user, roleName, companyName, agreedWithPersonalDataAgreement) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="WorkerConnectionToPlatformApplication.xsl" ?>\n' +
    '<WorkerConnectionToPlatformApplication\n' +
    '  xmlns="http://energochain.ru/XML/documents/worker-connection-to-platform-application/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/worker-connection-to-platform-application/1.0 WorkerConnectionToPlatformApplication.xsd">\n' +
    `    ${getCompletedTag('Date', dayjs().utc().format())}` +
    `    ${getCompletedTag('UserId', store.state.user.data.id)}` +
    '    <Person>\n' +
    '        <FIO>\n' +
    `            ${getCompletedTag('FamilyName', user.fullName.surname)}` +
    `            ${getCompletedTag('FirstName', user.fullName.name)}` +
    `            ${getCompletedTag('Patronymic', user.fullName.patronymic)}` +
    '        </FIO>\n' +
    `        ${getCompletedTag('Snils', user.snils)}` +
    `        ${getCompletedTag('Phone', user.phoneNumber)}` +
    `        ${getCompletedTag('Email', user.email)}` +
    '    </Person>\n' +
    `    ${getCompletedTag('CompanyAccountId', accountId)}` +
    `    ${getCompletedTag('CompanyName', companyName)}` +
    `    ${getCompletedTag('RoleName', roleName)}` +
    `    ${getCompletedTag('WorkEmail', user.additionalEmail)}` +
    `    ${getCompletedTag('WorkEmailVerificationCode', user.additionalEmailCode)}\n` +
    `    ${getCompletedTag('WorkPhone', user.additionalPhone ? formatting.unformatPhonePrefix(user.additionalPhone) : '')}` +
    `    ${getCompletedTag('WorkPhoneVerificationCode', user.additionalPhoneCode)}\n` +
    `    <AgreedWithPersonalDataAgreement>${agreedWithPersonalDataAgreement}</AgreedWithPersonalDataAgreement>\n` +
    '</WorkerConnectionToPlatformApplication>'
  );
}

function DocumentScan(documentsScan, documentHash, documentType, signatories) {
  let xml =
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<DocumentScan\n' +
    '        xmlns="http://energochain.ru/XML/documents/document-scan/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/document-scan/1.0 DocumentScan.xsd">\n' +
    `        <DocumentHash>${documentHash}</DocumentHash>` +
    `        <DocumentType>${documentType}</DocumentType>` +
    `        ${serializeToString(
      createAttachmentScansElement('', 'DocumentScans', documentsScan, 'File'),
    )}\n`;
  if (signatories && signatories.length > 0) {
    xml += '        <ActVerificationSignatory>\n';
    signatories.forEach((signatory) => {
      xml += '          <ActVerificationSignatory>\n' +
        `            <UserId>${signatory.userId}</UserId>\n` +
        `            <AccountId>${signatory.accountId}</AccountId>\n` +
        `            <RoleId>${signatory.role.id}</RoleId>\n` +
        `${getCompletedTag('PowerOfAttorneyId', signatory.powerOfAttorneyId, '    ')}` +
        '          </ActVerificationSignatory>\n';
    });
    xml += '          </ActVerificationSignatory>\n';
  }
  xml += '</DocumentScan>';
  return xml;
}

function ApprovalSheet(documentId, accountId, documentType, userIds, signerUserId, userIdForDocumentInfo) {
  let xml =
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<ApprovalSheet\n' +
    '         xmlns="http://energochain.ru/XML/documents/approval-sheet/1.0"\n' +
    '         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '         xsi:schemaLocation="http://energochain.ru/XML/documents/approval-sheet/1.0 ApprovalSheet.xsd">\n' +
    `         <DocumentId>${documentId}</DocumentId>` +
    `         <AccountId>${accountId}</AccountId>` +
    `         <DocumentType>${documentType}</DocumentType>` +
    '         <UserIds>';
  userIds.forEach((userId) => {
    xml +=
      `         <UserId>${userId}</UserId>`;
  });
  xml +=
    '         </UserIds>' +
    `         <Signer>${signerUserId}</Signer>` +
    `         ${getCompletedTag('UserIdForDocumentInfo', userIdForDocumentInfo)}` +
    '         <ApprovalResults></ApprovalResults>' +
    '</ApprovalSheet>';
  return xml;
}

function uploadSingleReadings(reading, type, currentTime) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ElectricityVerificationAct.xsl"?>\n' +
    '\n' +
    '<ElectricityVerificationAct xmlns="http://energochain.ru/XML/documents/electricity-verification-act/1.0" \n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://energochain.ru/XML/documents/electricity-verification-act/1.0 ElectricityVerificationAct.xsd"\n' +
    'xmlns:xmime="http://www.w3.org/2005/05/xmlmime">\n' +
    '\n' +
    '    <GridCompanyName>Акционерное общество «Объединенная энергетическая компания»</GridCompanyName>\n' +
    '    <ConsumerName>\n' +
    '        <Name>ООО "1"</Name>\n' +
    '        <Supervisor>Руководитель: главный энергетик Иванов И.И.</Supervisor>\n' +
    '        <Number>Тел.: +7 97777777777777</Number>\n' +
    '        <Adress>Адресс объекта: г. Москва, СЗАО, ул., около вл. 7-9</Adress>\n' +
    '    </ConsumerName>\n' +
    `    <Date>${reading.currentDate.value}</Date>\n` +
    `    <Time>${currentTime}</Time>\n` +
    '    <PlaceInstallation>ВРУ</PlaceInstallation>\n' +
    '    <Inspection>внеплановая</Inspection>\n' +
    '    <Representative>\n' +
    '        <Organization>АО «ОЭК»  </Organization>\n' +
    '        <Position>инспектором по учету СУЭЭ  </Position>\n' +
    '        <FirstName>П. </FirstName>\n' +
    '        <LastName>П. </LastName>\n' +
    '        <SecondName>Петровым </SecondName>\n' +
    '    </Representative>\n' +
    '    <Subscriber>\n' +
    '        <Organization></Organization>\n' +
    '        <Position>главного энергетика </Position>\n' +
    '        <FirstName>И. </FirstName>\n' +
    '        <LastName>И. </LastName>\n' +
    '        <SecondName>Иванова</SecondName>\n' +
    '    </Subscriber>\n' +
    '    <RepresentativeHelpers>\n' +
    '        <Organization></Organization>\n' +
    '        <Position></Position>\n' +
    '        <FirstName></FirstName>\n' +
    '        <LastName></LastName>\n' +
    '        <SecondName></SecondName>\n' +
    '    </RepresentativeHelpers>\n' +
    '\n' +
    '    <Flows1>\n' +
    '            <Type>Меркурий 234 ARTM-03 PBR.R</Type>\n' +
    `            <FactoryNumber>${reading.factoryNumber.value}</FactoryNumber>\n` +
    `            <MeasurementKind>${reading.measurementKind.value.toUpperCase()}</MeasurementKind>\n` +
    `            <IndicationT1>${reading.T1.value}</IndicationT1>\n` +
    '            <IndicationT2></IndicationT2>\n' +
    '            <IndicationT3></IndicationT3>\n' +
    '            <AccuracyClass>0.5s</AccuracyClass>\n' +
    '            <ConnectionDiagram>завода-изготовителя</ConnectionDiagram>\n' +
    '            <ManufacturingDate>2021</ManufacturingDate>\n' +
    `            <VerificationDate>${reading.verificationDate.value}</VerificationDate>\n` +
    '            <PlaceInstallation>ВРУ</PlaceInstallation>\n' +
    '            <SealNumber>АО "ОЭК"0145810</SealNumber>\n' +
    '            <ExpirationDate>2037</ExpirationDate>\n' +
    '    </Flows1>\n' +
    '\n' +
    '    <FlowsGroup>\n' +
    '        <Flows2 kind="current">\n' +
    '            <Flow>\n' +
    '                <Type>ТТИ 40</Type>\n' +
    '                <TransformationRatio>400/5</TransformationRatio>\n' +
    '                <AccuracyClass>0.5s</AccuracyClass>\n' +
    '                <Number>F23238</Number>\n' +
    '                <SealNumber>АО "ОЭК"0145835</SealNumber>\n' +
    '                <LastCheck>2021</LastCheck>\n' +
    '                <ExpirationDate>2026</ExpirationDate>\n' +
    '            </Flow>\n' +
    '            <Flow>\n' +
    '                <Type>ТТИ 40</Type>\n' +
    '                <TransformationRatio>400/5</TransformationRatio>\n' +
    '                <AccuracyClass>0.5s</AccuracyClass>\n' +
    '                <Number>F23240</Number>\n' +
    '                <SealNumber>АО "ОЭК"0145820</SealNumber>\n' +
    '                <LastCheck>2021</LastCheck>\n' +
    '                <ExpirationDate>2026</ExpirationDate>\n' +
    '            </Flow>\n' +
    '            <Flow>\n' +
    '                <Type>ТТИ 40</Type>\n' +
    '                <TransformationRatio>400/5</TransformationRatio>\n' +
    '                <AccuracyClass>0.5s</AccuracyClass>\n' +
    '                <Number>C50467</Number>\n' +
    '                <SealNumber>АО "ОЭК"0145812</SealNumber>\n' +
    '                <LastCheck>2021</LastCheck>\n' +
    '                <ExpirationDate>2026</ExpirationDate>\n' +
    '            </Flow>\n' +
    '        </Flows2>\n' +
    '        <Flows2 kind="voltage">\n' +
    '            <Flow>\n' +
    '                <Type></Type>\n' +
    '                <TransformationRatio></TransformationRatio>\n' +
    '                <AccuracyClass></AccuracyClass>\n' +
    '                <Number></Number>\n' +
    '                <SealNumber>АО "ОЭК</SealNumber>\n' +
    '                <LastCheck></LastCheck>\n' +
    '                <ExpirationDate></ExpirationDate>\n' +
    '            </Flow>\n' +
    '            <Flow>\n' +
    '                <Type></Type>\n' +
    '                <TransformationRatio></TransformationRatio>\n' +
    '                <AccuracyClass></AccuracyClass>\n' +
    '                <Number></Number>\n' +
    '                <SealNumber>АО "ОЭК</SealNumber>\n' +
    '                <LastCheck></LastCheck>\n' +
    '                <ExpirationDate></ExpirationDate>\n' +
    '            </Flow>\n' +
    '            <Flow>\n' +
    '                <Type></Type>\n' +
    '                <TransformationRatio></TransformationRatio>\n' +
    '                <AccuracyClass></AccuracyClass>\n' +
    '                <Number></Number>\n' +
    '                <SealNumber>АО "ОЭК</SealNumber>\n' +
    '                <LastCheck></LastCheck>\n' +
    '                <ExpirationDate></ExpirationDate>\n' +
    '            </Flow>\n' +
    '        </Flows2>\n' +
    '    </FlowsGroup>\n' +
    '\n' +
    '    <TestBox>\n' +
    '        <Availability>да</Availability>\n' +
    '        <SealNumber>АО "ОЭК"0145819</SealNumber>\n' +
    '    </TestBox>\n' +
    '\n' +
    '    <CheckMeter>\n' +
    '        <AmperageA>—</AmperageA>\n' +
    '        <AmperageB>—</AmperageB>\n' +
    '        <AmperageC>—</AmperageC>\n' +
    '        <AmperageAA>0,11</AmperageAA>\n' +
    '        <AmperageBB>0,09</AmperageBB>\n' +
    '        <AmperageCC>0,11</AmperageCC>\n' +
    '        <VoltageA>0,22</VoltageA>\n' +
    '        <VoltageB>0,22</VoltageB>\n' +
    '        <VoltageC>0,22</VoltageC>\n' +
    '        <VoltageAB>0,22</VoltageAB>\n' +
    '        <VoltageBC>0,22</VoltageBC>\n' +
    '        <VoltageAC>0,22</VoltageAC>\n' +
    '        <CosA>0,97</CosA>\n' +
    '        <CosB>0,95</CosB>\n' +
    '        <CosC>0,99</CosC>\n' +
    '        <Comments>Схема подключения прибора учета соответствует / не соответствует схеме подключения завода изготовителя</Comments>\n' +
    '    </CheckMeter>\n' +
    '\n' +
    '    <FixationSeal>\n' +
    '        <SealNumber1></SealNumber1>\n' +
    '        <SealNumber2></SealNumber2>\n' +
    '        <SealNumber3></SealNumber3>\n' +
    '        <PlaceInstallation1></PlaceInstallation1>\n' +
    '        <PlaceInstallation2></PlaceInstallation2>\n' +
    '        <PlaceInstallation3></PlaceInstallation3>\n' +
    '        <Organization1></Organization1>\n' +
    '        <Organization2></Organization2>\n' +
    '        <Organization3></Organization3>\n' +
    '    </FixationSeal>\n' +
    '\n' +
    '    <Equipment>\n' +
    '        <Computer></Computer>\n' +
    '        <Configurator></Configurator>\n' +
    '        <ClampMeter>Токовые клещи № А29490; </ClampMeter>\n' +
    '        <Software>№ исп 1912  Дата сл исп 11.09.2022; ПО Меркурий</Software>\n' +
    '    </Equipment>\n' +
    '\n' +
    '    <Comments>9.1 Истек МПИ: Прибор учета: ДА/НЕТ 9.2 Истек МПИ ТТ: ДА/НЕТ 9.3 Выявлено/не выявлено безучетное потребление: Нет</Comments>\n' +
    '    <Сonclusion>Прибор учета пригоден/не пригоден для осуществления расчетов за электроэнергию</Сonclusion>\n' +
    '\n' +
    '</ElectricityVerificationAct>\n'
  );
}

function PositionAppointmentOrder(accountId, publisherUserId, data) {
  return '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PositionAppointmentOrder\n' +
    '        xmlns="http://energochain.ru/XML/documents/position-appointment-order/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/position-appointment-order/1.0 PositionAppointmentOrder.xsd">\n' +
    `    <OrganisationAccountId>${accountId}</OrganisationAccountId>\n` +
    `    <PublisherUserId>${publisherUserId}</PublisherUserId>\n` +
    `    <UserId>${data.userId}</UserId>\n` +
    `    <RoleId>${data.roleId}</RoleId>\n` +
    '</PositionAppointmentOrder>\n';
}

function RemoveUserFromOrganisationApplication(accountId, publisherUserId, deletingUserId, date) {
  return '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<RemoveUserFromOrganisationApplication\n' +
    '        xmlns="http://energochain.ru/XML/documents/remove-user-from-organisation-application/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/remove-user-from-organisation-application/1.0 RemoveUserFromOrganisationApplication.xsd">\n' +
    `  <Date>${formatDateToXmlDateFormat(date)}</Date>\n` +
    `    <AccountId>${accountId}</AccountId>\n` +
    `    <UserId>${publisherUserId}</UserId>\n` +
    `    <DeletingUserId>${deletingUserId}</DeletingUserId>\n` +
    '</RemoveUserFromOrganisationApplication>\n';
}

function DeleteCompany(accountId) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<DeleteCompany \n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" \n' +
    'xmlns="http://energochain.ru/XML/documents/delete-company/1.0" \n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/delete-company/1.0 DeleteCompany.xsd"> \n' +
    `    <CompanyAccountId>${accountId}</CompanyAccountId> \n` +
    '</DeleteCompany>'
  );
}

function UserProfileDeleteApplicationAgreedDecision(params) {
  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UpdateUserDataApplicationAgreedDecision.xsl" ?>\n' +
    '<UserProfileDeleteApplicationAgreedDecision\n' +
    '        xmlns="http://energochain.ru/XML/documents/user-profile-delete-application-agreed-decision/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/user-profile-delete-application-agreed-decision/1.0 UserProfileDeleteApplicationAgreedDecision.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    `    <UserId>${params.initiatorUserId}</UserId>\n` +
    `    <ApplicationId>${params.documentId}</ApplicationId>\n` +
    `    <ApplicationName>${params.title}</ApplicationName>\n` +
    '</UserProfileDeleteApplicationAgreedDecision>';
}

function UserProfileDeleteApplicationRejectedDecision(params, reason) {
  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="UpdateUserDataApplicationRejectedDecision.xsl" ?>\n' +
    '<UserProfileDeleteApplicationRejectedDecision\n' +
    '        xmlns="http://energochain.ru/XML/documents/user-profile-delete-application-rejected-decision/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/user-profile-delete-application-rejected-decision/1.0 UserProfileDeleteApplicationRejectedDecision.xsd">\n' +
    `    <Date>${dayjs().utc().format()}</Date>\n` +
    `    <UserId>${params.initiatorUserId}</UserId>\n` +
    `    <ApplicationId>${params.documentId}</ApplicationId>\n` +
    `    <ApplicationName>${params.title}</ApplicationName>\n` +
    `    <Reason>${reason}</Reason>\n` +
    '</UserProfileDeleteApplicationRejectedDecision>';
}

function ConnectionApplicationContract(offer, utility, consumer, connectionApplicationKind) {
  let consumerCreds = '';
  if (consumer.companyInn?.length) {
    consumerCreds = (`<INN>${consumer.companyInn}</INN>\n`);
  } else {
    consumerCreds = (`<Snils>${consumer.snils}</Snils>\n`);
  }
  return (
    `${'<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ConnectionApplicationContract.xsl" ?>\n' +
    '<ConnectionApplicationContract\n' +
    'xmlns:ss="http://energochain.ru/XML/documents/simple-signature/1.0"\n' +
    'xmlns:cc="http://energochain.ru/XML/documents/company-common/1.0"\n' +
    'xmlns:c="http://energochain.ru/XML/documents/contract/1.0"\n' +
    'xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns="http://energochain.ru/XML/documents/connection-application-contract/1.0"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/connection-application-contract/1.0 ConnectionApplicationContract.xsd">\n' +
    `<ConnectionApplicationDocumentId>${offer.connectionApplicationDocumentId}</ConnectionApplicationDocumentId>\n` +
    `<ContractNumber>${offer.number}</ContractNumber>\n` +
    `<ContractDate>${formatDateToXmlDateFormat(offer.date)}</ContractDate>\n` +
    '<ContractPlace>Москва</ContractPlace>\n' +
    `<Amount>${offer.price}</Amount>\n` +
    `<Preferential>${offer.isPreferential}</Preferential>\n` +
    `${getCompletedTag('NotPreferentialReason', offer.reason)}` +
    '   <TechnicalСonditions> \n' +
    `     <TechnicalСonditionsDate>${formatDateToXmlDateFormat(offer.date)}</TechnicalСonditionsDate>\n` +
    '     <TechnicalСonditionsPlace>Москва</TechnicalСonditionsPlace>\n' +
    `     <ElectricityDevicesName>${offer.technicalConditions.electricityDevicesName}</ElectricityDevicesName>\n` +
    `     <ObjectName>${offer.technicalConditions.objectName}</ObjectName>\n` +
    `     <ObjectAddress>${offer.technicalConditions.objectAddress}</ObjectAddress> \n` +
    `     <MaxPower>${offer.technicalConditions.maxPower}</MaxPower> \n` +
    `     <PreviousMaxPower>${offer.technicalConditions.previousMaxPower}</PreviousMaxPower> \n` +
    `     <ReliabilityClass>${offer.technicalConditions.reliabilityClass}</ReliabilityClass>\n` +
    `     <VoltageLevel>${offer.technicalConditions.voltageLevel}</VoltageLevel> \n` +
    `     <YearOfCommissioning>${offer.technicalConditions.yearOfCommissioning}</YearOfCommissioning> \n` +
    '     <Points> \n' +
    '       <Point> \n' +
    `         <Name>${offer.technicalConditions.point.name}</Name> \n` +
    `         <MaxPower>${offer.technicalConditions.point.maxPower}</MaxPower> \n` +
    '       </Point> \n' +
    '     </Points> \n' +
    '     <MainSubstation xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"> \n' +
    `       <tc:MainSubstation>${offer.technicalConditions.mainSubstationObject.mainSubstation.fullName}</tc:MainSubstation> \n` +
    `       <tc:MainSubstationNumber>${offer.technicalConditions.mainSubstationObject.mainSubstation.number}</tc:MainSubstationNumber> \n` +
    `       ${getCompletedTag('tc:Feeder', offer.technicalConditions.mainSubstationObject.feeder)}` +
    `       ${getCompletedTag('tc:DistributionSubstation', offer.technicalConditions.mainSubstationObject.distributionSubstation?.number)}` +
    `       ${getCompletedTag('tc:Line', offer.technicalConditions.mainSubstationObject.line)}` +
    `       ${getCompletedTag('tc:TransformationSubstation', offer.technicalConditions.mainSubstationObject.transformationSubstation?.number)}` +
    `       ${getCompletedTag('tc:ConsumerLine', offer.technicalConditions.mainSubstationObject.consumerLine)}` +
    '     </MainSubstation> \n'
    }${
      offer.technicalConditions.reserveSubstationObject
        ? '     <MainSubstation xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"> \n' +
        `       <tc:MainSubstation>${offer.technicalConditions.reserveSubstationObject.mainSubstation.fullName}</tc:MainSubstation> \n` +
        `       <tc:MainSubstationNumber>${offer.technicalConditions.reserveSubstationObject.mainSubstation.number}</tc:MainSubstationNumber> \n` +
        `       ${getCompletedTag('tc:Feeder', offer.technicalConditions.reserveSubstationObject.feeder)}` +
        `       ${getCompletedTag('tc:DistributionSubstation', offer.technicalConditions.reserveSubstationObject.distributionSubstation?.number)}` +
        `       ${getCompletedTag('tc:Line', offer.technicalConditions.reserveSubstationObject.line)}` +
        `       ${getCompletedTag('tc:TransformationSubstation', offer.technicalConditions.reserveSubstationObject.transformationSubstation?.number)}` +
        `       ${getCompletedTag('tc:ConsumerLine', offer.technicalConditions.reserveSubstationObject.consumerLine)}` +
        '     </MainSubstation> \n' : ''
    }     ${getCompletedTag('UtilityResponsibilities', offer.technicalConditions.utilityResponsibilities)}` +
    `     ${getCompletedTag('ConsumerResponsibilities', offer.technicalConditions.consumerResponsibilities)}` +
    `     ${getCompletedTag('ConditionsPeriod', '3')}` +
    `     ${getCompletedTag('GeneralRequirements', offer.technicalConditions.generalRequirements)}` +
    '   </TechnicalСonditions> \n' +
    `<ConnectionApplicationKind>${connectionApplicationKind}</ConnectionApplicationKind>\n` +
    `${getCompletedTag('AccompanyingText', offer.accompanyingText)}` +
    '<ContractInfo>\n' +
    '        <ContractHeader>\n' +
    '            <c:Text>\n' +
    '                <c:Paragraph>\n' +
    `                    ${utility.name}, именуемое в дальнейшем\n` +
    '                    сетевой организацией, с одной стороны, и\n' +
    `                    ${consumer.name},\n` +
    '                    именуемый(ая) в дальнейшем заявителем, с другой стороны, вместе именуемые Сторонами,\n' +
    '                    заключили настоящий договор о нижеследующем.\n' +
    '                </c:Paragraph>\n' +
    '            </c:Text>\n' +
    '        </ContractHeader>\n' +
    '\n' +
    '        <ContractBody>\n' +
    '            <c:Chapter number="I" title="Предмет договора">\n' +
    '                <c:Point number="1">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            По настоящему договору сетевая организация принимает на себя обязательства по осуществлению\n' +
    '                            технологического присоединения энергопринимающих устройств заявителя (далее —\n' +
    '                            технологическое\n' +
    `                            присоединение) ${offer.technicalConditions.electricityDevicesName} в том числе по обеспечению готовности объектов\n` +
    '                            электросетевого хозяйства (включая их проектирование, строительство, реконструкцию) к\n' +
    '                            присоединению энергопринимающих устройств, урегулированию отношений с третьими лицами в\n' +
    '                            случае\n' +
    '                            необходимости строительства (модернизации) такими лицами принадлежащих им объектов\n' +
    '                            электросетевого хозяйства (энергопринимающих устройств, объектов электроэнергетики), с\n' +
    '                            учетом\n' +
    '                            следующих характеристик:\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    `                            - максимальная мощность присоединяемых энергопринимающих устройств ${offer.technicalConditions.maxPower} (кВт);\n` +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - категория надежности III;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - класс напряжения электрических сетей, к которым осуществляется технологическое\n' +
    '                            присоединение\n' +
    `                            ${offer.technicalConditions.voltageLevel} (В);\n` +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - ранее присоединенная в точке присоединения, указанной в пункте 3 настоящего договора,\n' +
    `                            мощность ${offer.technicalConditions.previousMaxPower} кВт (подлежит указанию, если энергопринимающее устройство заявителя ранее\n` +
    '                            в\n' +
    '                            надлежащем порядке было технологически присоединено и заявитель имеет документы,\n' +
    '                            подтверждающие\n' +
    '                            указанное технологическое присоединение и наличие ранее присоединенной в данной точке\n' +
    '                            присоединения мощности).\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            Заявитель обязуется оплатить расходы на технологическое присоединение в соответствии с\n' +
    '                            условиями\n' +
    '                            настоящего договора.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="2">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Технологическое присоединение необходимо для электроснабжения\n' +
    `                            ${offer.technicalConditions.objectAddress},\n` +
    '                            расположенных/которые будут располагаться (нужное подчеркнуть)\n' +
    `                            ${offer.technicalConditions.objectAddress}.\n` +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="3">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Точка(и) присоединения указана(ы) в технических условиях для присоединения к\n' +
    '                            электрическим сетям (далее - технические условия) и располагается(ются) на расстоянии\n' +
    '                            _________\n' +
    '                            м (точки присоединения не могут располагаться далее 25 метров от границы участка, на котором\n' +
    '                            располагаются (будут располагаться) присоединяемые объекты заявителя) от границы участка\n' +
    '                            заявителя, на котором располагаются/будут располагаться (нужное подчеркнуть) присоединяемые\n' +
    '                            объекты заявителя.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="4">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Технические условия являются неотъемлемой частью настоящего договора и приведены в\n' +
    '                            приложении к настоящему договору. Срок действия технических условий составляет 3\n' +
    '                            год(а)\n' +
    '                            (срок действия технических условий не может составлять менее 2 лет и более 5 лет) со дня\n' +
    '                            заключения настоящего договора.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="5">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Срок выполнения мероприятий по технологическому присоединению составляет\n' +
    '                            _____________\n' +
    '                            (срок осуществления мероприятий по технологическому присоединению не может превышать 6\n' +
    '                            месяцев в\n' +
    '                            случае технологического присоединения к электрическим сетям классом напряжения до 20 кВ\n' +
    '                            включительно, если расстояние от существующих электрических сетей необходимого класса\n' +
    '                            напряжения\n' +
    '                            до границ участка заявителя, на котором расположены присоединяемые энергопринимающие\n' +
    '                            устройства,\n' +
    '                            составляет не более 300 метров в городах и поселках городского типа и не более 500 метров в\n' +
    '                            сельской местности. В иных случаях срок осуществления мероприятий по технологическому\n' +
    '                            присоединению не может превышать 1 год, если более короткие сроки не предусмотрены\n' +
    '                            соответствующей инвестиционной программой или соглашением Сторон) со дня заключения\n' +
    '                            настоящего\n' +
    '                            договора.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="II" title="Обязанности Сторон">\n' +
    '                <c:Point number="6">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Сетевая организация обязуется:\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - надлежащим образом исполнить обязательства по настоящему договору, в том числе по\n' +
    '                            выполнению\n' +
    '                            возложенных на сетевую организацию мероприятий по технологическому присоединению (включая\n' +
    '                            урегулирование отношений с иными лицами) до границ участка, на котором расположены\n' +
    '                            присоединяемые энергопринимающие устройства заявителя, указанные в технических условиях;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - в течение ____ рабочих дней со дня уведомления заявителем сетевой организации о выполнении\n' +
    '                            им\n' +
    '                            технических условий осуществить проверку выполнения технических условий заявителем, провести\n' +
    '                            с\n' +
    '                            участием заявителя осмотр (обследование) присоединяемых энергопринимающих устройств\n' +
    '                            заявителя;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>\n' +
    '                            - не позднее ________ рабочих дней со дня проведения осмотра (обследования), указанного в\n' +
    '                            абзаце\n' +
    '                            третьем настоящего пункта, с соблюдением срока, установленного пунктом 5 настоящего\n' +
    '                            договора,\n' +
    '                            осуществить фактическое присоединение энергопринимающих устройств заявителя к электрическим\n' +
    '                            сетям, фактический прием (подачу) напряжения и мощности, составить при участии заявителя акт\n' +
    '                            разграничения балансовой принадлежности электрических сетей, акт разграничения\n' +
    '                            эксплуатационной\n' +
    '                            ответственности, акт об осуществлении технологического присоединения и направить их\n' +
    '                            заявителю.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="7">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Сетевая организация при невыполнении заявителем технических условий в согласованный срок и\n' +
    '                            наличии на дату окончания срока их действия технической возможности технологического\n' +
    '                            присоединения вправе по обращению заявителя продлить срок действия технических условий. При\n' +
    '                            этом\n' +
    '                            дополнительная плата не взимается.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="8">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Заявитель обязуется:\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- надлежащим образом исполнить обязательства по настоящему договору, в том числе по\n' +
    '                            выполнению возложенных на заявителя мероприятий по технологическому присоединению в пределах\n' +
    '                            границ участка, на котором расположены присоединяемые энергопринимающие устройства\n' +
    '                            заявителя,\n' +
    '                            указанные в технических условиях;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- после выполнения мероприятий по технологическому присоединению в пределах границ\n' +
    '                            участка заявителя, предусмотренных техническими условиями, уведомить сетевую организацию о\n' +
    '                            выполнении технических условий;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- принять участие в осмотре (обследовании) присоединяемых энергопринимающих\n' +
    '                            устройств\n' +
    '                            сетевой организацией;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- после осуществления сетевой организацией фактического присоединения\n' +
    '                            энергопринимающих\n' +
    '                            устройств заявителя к электрическим сетям, фактического приема (подачи) напряжения и\n' +
    '                            мощности\n' +
    '                            подписать акт разграничения балансовой принадлежности электрических сетей, акт разграничения\n' +
    '                            эксплуатационной ответственности, акт об осуществлении технологического присоединения либо\n' +
    '                            представить мотивированный отказ от подписания в течение ______ рабочих дней со дня\n' +
    '                            получения\n' +
    '                            указанных актов от сетевой организации;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- надлежащим образом исполнять указанные в разделе III настоящего договора\n' +
    '                            обязательства\n' +
    '                            по оплате расходов на технологическое присоединение;\n' +
    '                        </c:Paragraph>\n' +
    '                        <c:Paragraph>- уведомить сетевую организацию о направлении заявок в иные сетевые организации при\n' +
    '                            технологическом присоединении энергопринимающих устройств, в отношении которых применяется\n' +
    '                            категория надежности электроснабжения, предусматривающая использование двух и более\n' +
    '                            источников\n' +
    '                            электроснабжения.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="9">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Заявитель вправе при невыполнении им технических условий в согласованный срок и наличии на\n' +
    '                            дату\n' +
    '                            окончания срока их действия технической возможности технологического присоединения\n' +
    '                            обратиться в\n' +
    '                            сетевую организацию с просьбой о продлении срока действия технических условий.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="III" title="Плата за технологическое присоединение и порядок расчетов">\n' +
    '                <c:Point number="10">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Размер платы за технологическое присоединение определяется в соответствии с решением\n' +
    '                            ____________________________________________________________________\n' +
    `                            от ${formatDateToXmlDateFormat(offer.date)} № ${offer.number} и составляет ${Math.floor(offer.price)} руб. ${offer.price * 100 % 100} коп. (размер платы\n` +
    '                            за\n' +
    '                            технологическое присоединение энергопринимающих устройств максимальной мощностью, не\n' +
    '                            превышающей\n' +
    '                            15 кВт включительно (с учетом ранее присоединенной в данной точке присоединения мощности),\n' +
    '                            устанавливается органом исполнительной власти в области государственного регулирования\n' +
    '                            тарифов\n' +
    '                            исходя из стоимости мероприятий по технологическому присоединению в размере не более 550\n' +
    '                            рублей\n' +
    '                            при условии, что расстояние от границ участка заявителя до объектов электросетевого\n' +
    '                            хозяйства\n' +
    '                            необходимого заявителю класса напряжения сетевой организации, в которую подана заявка,\n' +
    '                            составляет не более 300 метров в городах и поселках городского типа и не более 500 метров в\n' +
    '                            сельской местности).\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="11">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Внесение платы за технологическое присоединение осуществляется заявителем в следующем\n' +
    '                            порядке:\n' +
    '                            __________________________________________________________.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="12">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Датой исполнения обязательства заявителя по оплате расходов на технологическое присоединение\n' +
    '                            считается дата внесения денежных средств в кассу или на расчетный счет сетевой организации.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="IV"\n' +
    '                       title="Разграничение балансовой принадлежности электрических сетей и эксплуатационной ответственности Сторон">\n' +
    '                <c:Point number="13">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Заявитель несет балансовую и эксплуатационную ответственность в границах своего участка,\n' +
    '                            сетевая\n' +
    '                            организация - до границ участка заявителя (такой порядок разграничения балансовой и\n' +
    '                            эксплуатационной ответственности устанавливается, если иное не определено соглашением между\n' +
    '                            сетевой организацией и заявителем, заключенным на основании его обращения в сетевую\n' +
    '                            организацию).\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="V"\n' +
    '                       title="Условия изменения, расторжения договора и ответственность Сторон">\n' +
    '                <c:Point number="14">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Настоящий договор может быть изменен по письменному соглашению Сторон или в\n' +
    '                            судебном\n' +
    '                            порядке.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="15">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Договор может быть расторгнут по требованию одной из Сторон по основаниям,\n' +
    '                            предусмотренным Гражданским кодексом Российской Федерации.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="16">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Заявитель вправе при нарушении сетевой организацией указанных в настоящем договоре\n' +
    '                            сроков\n' +
    '                            технологического присоединения в одностороннем порядке расторгнуть настоящий договор.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="17">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>В случае нарушения одной из Сторон сроков исполнения своих обязательств по\n' +
    '                            настоящему\n' +
    '                            договору такая Сторона в течение 10 рабочих дней со дня наступления просрочки уплачивает\n' +
    '                            другой\n' +
    '                            Стороне неустойку, рассчитанную как произведение 0,014 ставки рефинансирования Центрального\n' +
    '                            банка Российской Федерации, установленной на дату заключения настоящего договора, и общего\n' +
    '                            размера платы за технологическое присоединение по настоящему договору за каждый день\n' +
    '                            просрочки.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="18">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>За неисполнение или ненадлежащее исполнение обязательств по настоящему договору\n' +
    '                            Стороны\n' +
    '                            несут ответственность в соответствии с законодательством Российской Федерации.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="19">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>Стороны освобождаются от ответственности за частичное или полное неисполнение\n' +
    '                            обязательств по настоящему договору, если оно явилось следствием обстоятельств непреодолимой\n' +
    '                            силы, возникших после подписания Сторонами настоящего договора и оказывающих\n' +
    '                            непосредственное\n' +
    '                            воздействие на выполнение Сторонами обязательств по настоящему договору.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="VI" title="Порядок разрешения споров">\n' +
    '                <c:Point number="20">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Споры, которые могут возникнуть при исполнении, изменении, расторжении настоящего договора,\n' +
    '                            Стороны разрешают в соответствии с законодательством Российской Федерации.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '            <c:Chapter number="VII" title="Заключительные положения">\n' +
    '                <c:Point number="21">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Настоящий договор считается заключенным с даты поступления подписанного заявителем\n' +
    '                            экземпляра настоящего договора в сетевую организацию.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '                <c:Point number="22">\n' +
    '                    <c:Text>\n' +
    '                        <c:Paragraph>\n' +
    '                            Настоящий договор составлен и подписан в двух экземплярах, по одному для каждой из Сторон.\n' +
    '                        </c:Paragraph>\n' +
    '                    </c:Text>\n' +
    '                </c:Point>\n' +
    '            </c:Chapter>\n' +
    '        </ContractBody>\n' +
    '\n' +
    '        <ContractImportantConditions>\n' +
    '            <c:ElectricityTransmissionContractImportantConditions>\n' +
    '                <c:Payments/>\n' +
    '            </c:ElectricityTransmissionContractImportantConditions>\n' +
    '        </ContractImportantConditions>\n' +
    '    </ContractInfo>' +
    '<Requisites>\n' +
    '        <Supplier>\n' +
    `            <cc:OrganisationName>${utility.name}</cc:OrganisationName>\n` +
    '            <cc:ExecutorName>Supplier</cc:ExecutorName>\n' +
    `            <cc:INN>${utility.inn}</cc:INN>\n` +
    `            <cc:AccountId>${utility.accountId}</cc:AccountId>\n` +
    '            <cc:Representative>\n' +
    '                <cc:Name>\n' +
    `                    <cc:FamilyName>${utility.familyName}</cc:FamilyName>\n` +
    `                    <cc:FirstName>${utility.firstName}</cc:FirstName>\n` +
    `                    <cc:Patronymic>${utility.patronymic}</cc:Patronymic>\n` +
    '                </cc:Name>\n' +
    `                <cc:Position>${utility.position}</cc:Position>\n` +
    '            </cc:Representative>\n' +
    '        </Supplier>\n' +
    '        <Applicant>\n' +
    `            <AccountId>${consumer.accountId}</AccountId>\n` +
    `            <FamilyName>${consumer.familyName}</FamilyName>\n` +
    `            <FirstName>${consumer.firstName}</FirstName>\n` +
    `            <Patronymic>${consumer.patronymic}</Patronymic>\n` +
    '            <IdentityDocument>\n' +
    '                <Passport>\n' +
    `                    <Series>${consumer.documentSeries}</Series>\n` +
    `                    <Number>${consumer.passportNumber}</Number>\n` +
    `                    <Issuer>${consumer.issuer}</Issuer>\n` +
    `                    <IssueDate>${formatDateToXmlDateFormat(consumer.issueDate)}</IssueDate>\n` +
    '                </Passport>\n' +
    '            </IdentityDocument>\n' +
    `            ${consumerCreds}\n` +
    `            <ResidenceAddress>${consumer.address}</ResidenceAddress>\n` +
    '        </Applicant>\n' +
    '    </Requisites>\n' +
    '</ConnectionApplicationContract>'
  );
}

function ConnectionApplicationTechnicalConditionsAct(offer, utility, consumer) {
  let consumerCreds = '';
  if (consumer.companyInn?.length) {
    consumerCreds = (
      `<Name>${consumer.companyName}</Name>\n` +
      `<LegalInn>${consumer.companyInn}</LegalInn>\n`);
  } else {
    consumerCreds =
      (`<Name>${consumer.name}</Name>\n` +
        `<Snils>${consumer.snils}</Snils>\n`);
  }

  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ActTechnicalConditions.xsl" ?>\n' +
    '<ActTechnicalConditions\n' +
    'xmlns:ss="http://energochain.ru/XML/documents/simple-signature/1.0"\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns="http://energochain.ru/XML/documents/act-technical-conditions/1.0"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/act-technical-conditions/1.0 ActTechnicalConditions.xsd">\n' +
    `<DocumentNumber>${offer.number}</DocumentNumber>\n` +
    `<DocumentDate>${formatDateToXmlDateFormat(offer.date)}</DocumentDate>\n` +
    `<ConnectionApplicationDocumentId>${offer.connectionApplicationDocumentId}</ConnectionApplicationDocumentId>\n` +
    '<Utility>\n' +
    `<AccountId>${utility.accountId}</AccountId>\n` +
    `<Name>${utility.name}</Name>\n` +
    `<LegalInn>${utility.inn}</LegalInn>\n` +
    '</Utility>\n' +
    '<Consumer>\n' +
    `<AccountId>${consumer.accountId}</AccountId>\n` +
    `${consumerCreds}` +
    '</Consumer>\n' +
    '   <TechnicalСonditions> \n' +
    `     <TechnicalСonditionsDate>${formatDateToXmlDateFormat(offer.date)}</TechnicalСonditionsDate>\n` +
    '     <TechnicalСonditionsPlace>Москва</TechnicalСonditionsPlace>\n' +
    `     <ElectricityDevicesName>${offer.technicalConditions.electricityDevicesName}</ElectricityDevicesName>\n` +
    `     <ObjectName>${offer.technicalConditions.objectName}</ObjectName>\n` +
    `     <ObjectAddress>${offer.technicalConditions.objectAddress}</ObjectAddress> \n` +
    `     <MaxPower>${offer.technicalConditions.maxPower}</MaxPower> \n` +
    `     <ReliabilityClass>${offer.technicalConditions.reliabilityClass}</ReliabilityClass>\n` +
    `     <VoltageLevel>${offer.technicalConditions.voltageLevel}</VoltageLevel> \n` +
    `     <YearOfCommissioning>${offer.technicalConditions.yearOfCommissioning}</YearOfCommissioning> \n` +
    `     <PreviousMaxPower>${offer.technicalConditions.previousMaxPower}</PreviousMaxPower> \n` +
    '     <Points> \n' +
    '       <Point> \n' +
    `         <Name>${offer.technicalConditions.point.name}</Name> \n` +
    `         <MaxPower>${offer.technicalConditions.point.maxPower}</MaxPower> \n` +
    '       </Point> \n' +
    '     </Points> \n' +
    '     <MainSubstation xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"> \n' +
    `       <tc:MainSubstation>${offer.technicalConditions.mainSubstationObject.mainSubstation.fullName}</tc:MainSubstation> \n` +
    `       <tc:MainSubstationNumber>${offer.technicalConditions.mainSubstationObject.mainSubstation.number}</tc:MainSubstationNumber> \n` +
    `       ${getCompletedTag('tc:Feeder', offer.technicalConditions.mainSubstationObject.feeder)}` +
    `       ${getCompletedTag('tc:DistributionSubstation', offer.technicalConditions.mainSubstationObject.distributionSubstation?.number)}` +
    `       ${getCompletedTag('tc:Line', offer.technicalConditions.mainSubstationObject.line)}` +
    `       ${getCompletedTag('tc:TransformationSubstation', offer.technicalConditions.mainSubstationObject.transformationSubstation?.number)}` +
    `       ${getCompletedTag('tc:ConsumerLine', offer.technicalConditions.mainSubstationObject.consumerLine)}` +
    '     </MainSubstation> \n' +
    `${
      offer.technicalConditions.reserveSubstationObject
        ? '     <MainSubstation xmlns:tc="http://energochain.ru/XML/documents/topology-common/1.0"> \n' +
        `       <tc:MainSubstation>${offer.technicalConditions.reserveSubstationObject.mainSubstation.fullName}</tc:MainSubstation> \n` +
        `       <tc:MainSubstationNumber>${offer.technicalConditions.reserveSubstationObject.mainSubstation.number}</tc:MainSubstationNumber> \n` +
        `       ${getCompletedTag('tc:Feeder', offer.technicalConditions.reserveSubstationObject.feeder)}` +
        `       ${getCompletedTag('tc:DistributionSubstation', offer.technicalConditions.reserveSubstationObject.distributionSubstation?.number)}` +
        `       ${getCompletedTag('tc:Line', offer.technicalConditions.reserveSubstationObject.line)}` +
        `       ${getCompletedTag('tc:TransformationSubstation', offer.technicalConditions.reserveSubstationObject.transformationSubstation?.number)}` +
        `       ${getCompletedTag('tc:ConsumerLine', offer.technicalConditions.reserveSubstationObject.consumerLine)}` +
        '     </MainSubstation> \n' : ''
    }` +
    `     ${getCompletedTag('UtilityResponsibilities', offer.technicalConditions.utilityResponsibilities)}` +
    `     ${getCompletedTag('ConsumerResponsibilities', offer.technicalConditions.consumerResponsibilities)}` +
    `     ${getCompletedTag('ConditionsPeriod', '3')}` +
    `     ${getCompletedTag('GeneralRequirements', offer.technicalConditions.generalRequirements)}` +
    '   </TechnicalСonditions> \n' +
    '</ActTechnicalConditions>'
  );
}

function GenerateRevokedCertificatesXML(certificates, action) {
  const getRevokedXML = (revokedCertificates) => {
    let revokedListXML = '';
    revokedCertificates.forEach((certificate) => {
      revokedListXML += `
            <CertificateList>
               <CertUuid>${certificate.id}</CertUuid>
           </CertificateList>`;
    });
    revokedListXML = `
        <RevokeCertificateLists>
          ${revokedListXML}
        </RevokeCertificateLists>`;
    return revokedListXML;
  };

  const getAddXML = (addedCertificates) => {
    let addListXML = '';
    addedCertificates.forEach((certificate) => {
      addListXML += `
            <CertificateList>
               <Name>${certificate.name}</Name>
               <CertLink>${certificate.link}</CertLink>
           </CertificateList>`;
    });
    addListXML = `
        <AddCertificateLists>
          ${addListXML}
        </AddCertificateLists>`;
    return addListXML;
  };

  let actionXml = '';
  if (action === 'add') {
    actionXml = getAddXML(certificates);
  } else if (action === 'revoke') {
    actionXml = getRevokedXML(certificates);
  }

  const resultXml = '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateCertificateLists\n' +
    'xmlns="http://energochain.ru/XML/documents/update-certificate-list/1.0"\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/update-certificate-list/1.0\n' +
    'UpdateCertificateList.xsd">\n' +
    `${actionXml}` +
    '</UpdateCertificateLists>\n';

  return resultXml;
}

function generatePowerProfileXML({ meterId, excelData, profileInfo }) {
  const now = new Date();
  const offset = 3 * 60 * 60000;
  const adjustedDate = new Date(now.getTime() + offset);
  const dateInUTCPlus3 = adjustedDate.toISOString().slice(0, 10);
  let xml = `<?xml version="1.0" encoding="utf-8" ?>
  <?xml-stylesheet type="text/xsl" href="../xsl/PowerProfile.xsl" ?>
  <PowerProfile
    xmlns="http://energochain.ru/XML/documents/power-profile/1.0"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://energochain.ru/XML/documents/power-profile/1.0 ../xsd/PowerProfile.xsd">
    <Date>${dateInUTCPlus3}</Date>
    <MeterNumber>${meterId}</MeterNumber>
    <Ktr>${profileInfo.ktr}</Ktr>
    <PreviousActiveInput>${profileInfo.previousActiveInput}</PreviousActiveInput>
    <CurrentActiveInput>${profileInfo.currentActiveInput}</CurrentActiveInput>
    <Difference>${profileInfo.difference}</Difference>
    <TotalEnergy>${profileInfo.totalEnergy}</TotalEnergy>
    <Readings>
  `;
  for (const row of excelData) {
    const { date } = row;
    for (let i = 1; i <= 24; i++) {
      const hourValue = row[`hour${i}`];
      const [day, month, year] = date.split('.');
      const dateAndHourObj = new Date(year, month - 1, day, i - 1);
      const dateAndHourIso = dateAndHourObj.toISOString();
      xml += `
        <Reading>
          <Period xmlns="http://energochain.ru/XML/documents/measurements-common/1.0">${dateAndHourIso}</Period>
          <ActiveInput xmlns="http://energochain.ru/XML/documents/measurements-common/1.0">${hourValue}</ActiveInput>
          <ActiveOutput xmlns="http://energochain.ru/XML/documents/measurements-common/1.0"></ActiveOutput>
          <ReactiveInput xmlns="http://energochain.ru/XML/documents/measurements-common/1.0"></ReactiveInput>
          <ReactiveOutput xmlns="http://energochain.ru/XML/documents/measurements-common/1.0"></ReactiveOutput>
        </Reading>
      `;
    }
  }
  xml += `
    </Readings>
  </PowerProfile>`;
  return xml;
}

// запрос с версией  без <AccountId></AccountId>
// т.к. его нет до прохождение тех. приса или подключения по договору
function GenerateConnectionUserBySupplyContractApplicationXML({ contractNumber, contractDate, fullName, snils, userId }) {
  const resultXml = '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ConnectionUserByContractConsumerMESApplication.xsl" ?>\n' +
    '<ConnectionUserBySupplyContractApplication\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns="http://energochain.ru/XML/documents/connection-user-by-supply-contract-application/1.0"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/connection-user-by-supply-contract-application/1.0 ConnectionUserBySupplyContractApplication.xsd">\n' +
    '  <ContractInfo>\n' +
    `    <Number>${contractNumber}</Number>\n` +
    `    <Date>${contractDate}</Date>\n` +
    '  </ContractInfo>\n' +
    '<Consumer>\n' +
    `<UserId>${userId}</UserId>\n` +
    `<Name>${fullName}</Name>\n` +
    `<Snils>${snils}</Snils>\n` +
    '</Consumer>\n' +
    '</ConnectionUserBySupplyContractApplication>\n';
  return resultXml;
}

function GenerateRequestUpdateCompanyProfileXML({ bankRequisites, contactInformation, accountId }) {
  const createBankRequisitesXml = (bankRequisites) => {
    let resultXml = '';
    bankRequisites.forEach((requisitesItem) => {
      resultXml += '\n' +
        '  <Requisites>\n' +
        `      <cc:BankName>${requisitesItem.bankName}</cc:BankName>\n` +
        `      <cc:BIK>${requisitesItem.bik}</cc:BIK>\n` +
        `      <cc:SettlementAccount>${requisitesItem.settlementAccount}</cc:SettlementAccount>\n` +
        `      <cc:CorrespondentAccount>${requisitesItem.correspondentAccount}</cc:CorrespondentAccount>\n` +
        '  </Requisites>\n';
    });
    return resultXml;
  };

  const createContactInformationXml = (contactInformation) => {
    let resultXml = '';

    if (contactInformation.email) {
      resultXml += `  <cc:Email>${contactInformation.email}</cc:Email>\n`;
    }

    if (contactInformation.phoneNumber) {
      resultXml += `  <cc:PhoneNumber>${contactInformation.phoneNumber}</cc:PhoneNumber>\n`;
    }
    return resultXml;
  };

  const bankRequisitesXml = createBankRequisitesXml(bankRequisites);
  const contactInformationXml = createContactInformationXml(contactInformation);

  const resultXml = '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="../xsl/RequestUpdateCompanyProfile.xsl" ?>\n' +
    '<RequestUpdateCompanyProfile\n' +
    'xmlns="http://energochain.ru/XML/documents/company-profile/1.0"\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns:cc="http://energochain.ru/XML/documents/company-common/1.0"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/company-profile/1.0 ../xsd/RequestUpdateCompanyProfile.xsd">\n' +
    `<CompanyAccountId>${accountId}</CompanyAccountId>\n` +
    `${bankRequisitesXml}\n` +
    '  <ContactInformation>\n' +
    `      ${contactInformationXml}\n` +
    '  </ContactInformation>\n' +
    '</RequestUpdateCompanyProfile>\n';
  return resultXml;
}

function ResChangesXML({ create, update }) {
  let resultXml = ('<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="ResChanges.xsl" ?>\n' +
    '<ResChanges\n' +
    'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    'xmlns="http://energochain.ru/XML/documents/res-changes/1.0"\n' +
    'xsi:schemaLocation="http://energochain.ru/XML/documents/res-changes/1.0 ResChanges.xsd">\n' +
    '  <ResChangesList>\n');
  if (create) {
    resultXml += (
      '   <CreateRes>\n' +
      '     <Res>\n' +
      `       <Name>${create.name}</Name>\n` +
      '     </Res>\n' +
      '   </CreateRes>\n'
    );
  }
  if (update) {
    resultXml += (
      `   <ChangeRes id="${update.id}">\n` +
      '     <Res>\n' +
      `       <Name>${update.name}</Name>\n` +
      '     </Res>\n' +
      '   </ChangeRes>\n'
    );
  }
  resultXml += (
    '  </ResChangesList>\n' +
    '</ResChanges>'
  );
  return resultXml;
}

const connectionApplicationData = {
  uploadSingleReadings,
  generatePowerProfileXML,
  getData,
  saveData,
  save,
  dataMapper,
  generateXml,
  documentReviewXml,
  clearData,
  contractConsumerMesXml,
  contractConsumerElectricityPurchaseXml,
  usagePointLossesDocument,
  generateConnectionCertificateXml,
  generateAdmissionCertificateXml,
  measurementsXml,
  testTariffsUpdatingTaskXml,
  electricityTariffOrder,
  electricityPaymentXml,
  mesTransaction,
  changingTariffApplication,
  legalConnectionToPlatformApplication,
  ElectricityTransmissionContractTwo,
  ElectricityTransmissionContractTwoTso,
  ElectricityPurchaseReportIndications,
  ElectricityPurchaseReportFinally,
  UsefulVacationDocument,
  EighteenthFormsIndividual,
  agreeIntegralAct,
  AddUsersToOrganisationApplication,
  addPowerOfAttorneyToUser,
  UserAgreements,
  IndividualConnectionToPlatformApplication,
  IndividualConnectionToPlatformApplicationIp,
  UpdateUserDataApplication,
  DeleteUserProfileApplication,
  WorkerConnectionToPlatformApplication,
  DocumentScan,
  OrganisationStructureChangingOrder,
  PositionAppointmentOrder,
  RemoveUserFromOrganisationApplication,
  ApprovalSheet,
  RevokeUserInvitationApplication,
  DeleteCompany,
  UserProfileDeleteApplicationAgreedDecision,
  UserProfileDeleteApplicationRejectedDecision,
  ConnectionApplicationContract,
  ConnectionApplicationTechnicalConditionsAct,
  RequestDeleteCompany,
  GenerateRevokedCertificatesXML,
  GenerateConnectionUserBySupplyContractApplicationXML,
  GenerateRequestUpdateCompanyProfileXML,
  ResChangesXML,
};

export default connectionApplicationData;
