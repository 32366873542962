<template>
  <v-app class="vuetify-styles v-application" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Внесение спецификации к договорам купли-продажи движимого имущества</div>
          </v-card-title>
          <v-card-text>
            <simple-autocomplete
              ref="contract"
              v-model="contractId"
              :items.sync="contracts"
              :search.sync="contractName"
              is-required
              title="Договор"
              url="/bought-constructions?name="
              url-default-data="/bought-constructions"
            ></simple-autocomplete>

            <v-radio-group v-model="deliveryContract" column>
              <v-radio label="Договор на поставку АХП" value="AXP"></v-radio>
              <v-radio
                label="Договор на поставку элементов АХП"
                value="ELEMENT_AXP"
              ></v-radio>
            </v-radio-group>

            <div v-if="deliveryContract === `AXP`">
              <v-col>
                <div class="v-application">
                  <v-data-table
                    :headers="axpHeadersTable"
                    :items="formAxps"
                    class="elevation-1 mb-4"
                    style="width: 100%"
                  >
                    <template #no-data>
                      <span>Отсутствуют данные</span>
                    </template>
                    <template #top>
                      <v-toolbar flat>
                        <edit-dialog-process-axp
                          ref="editDialog"
                          v-model="editDialog"
                          :cancel="cancelForAxpElements"
                          :dialog-data="editedItem"
                          :disabled="disabledEditDialog"
                          :edit="editedIndex"
                          :handle-success="addAxp"
                        ></edit-dialog-process-axp>
                        <v-dialog
                          v-model="dialogTableDelete" class="v-dialog-top" max-width="500px">
                          <v-card class="v-application">
                            <v-card-title class="text-h5 text-center"
                            >Вы уверены что хотите удалить?
                            </v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                rounded
                                @click="dialogTableDelete = false"
                              >Отмена
                              </v-btn
                              >
                              <v-btn
                                class="ma-2"
                                color="error"
                                outlined
                                rounded
                                @click="onTapDeleteItemConfirmTableDialog('axp')"
                              >
                                <v-icon class="label-error pr-1"
                                >mdi-delete
                                </v-icon
                                >
                                Удалить
                              </v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template #item.actions="{ item }">
                      <v-icon class="mr-2" small @click="editItem(item, 'axp')">
                        mdi-pencil
                      </v-icon>
                      <v-icon class="error-label" small @click="deleteItem(item, 'axp')">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </div>
            <v-row v-if="deliveryContract === `ELEMENT_AXP`">
              <v-col>
                <div class="v-application">
                  <v-data-table
                    :headers="elementsHeadersTable"
                    :items="formElements"
                    class="elevation-1 mb-4"
                    style="width: 100%"
                  >
                    <template #no-data>
                      <span>Отсутствуют данные</span>
                    </template>
                    <template #top>
                      <v-toolbar flat>
                        <edit-dialog-process
                          ref="editDialog"
                          v-model="editDialog"
                          :cancel="cancelForAxpElements"
                          :dialog-data="editedItem"
                          :disabled="disabledEditDialog"
                          :edit="editedIndex"
                          :handle-success="addElement"
                        ></edit-dialog-process>
                        <v-dialog
                          v-model="dialogTableDelete" class="v-dialog-top" max-width="500px">
                          <v-card class="v-application">
                            <v-card-title class="text-h5 text-center"
                            >Вы уверены что хотите удалить?
                            </v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                rounded
                                @click="dialogTableDelete = false"
                              >Отмена
                              </v-btn
                              >
                              <v-btn
                                class="ma-2"
                                color="error"
                                outlined
                                rounded
                                @click="onTapDeleteItemConfirmTableDialog('element')"
                              >
                                <v-icon class="label-error pr-1"
                                >mdi-delete
                                </v-icon
                                >
                                Удалить
                              </v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template #item.actions="{ item }">
                      <v-icon class="mr-2" small @click="editItem(item, 'element')">
                        mdi-pencil
                      </v-icon>
                      <v-icon class="error-label" small @click="deleteItem(item, 'element')">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import SimpleAutocomplete from '@/components/Processes/components/SimpleAutocomplete.vue';
import EditDialogProcess from './dialogs/EditDialogProcess42.vue';
import { checkAccess, D1_A } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import EditDialogProcessAxp from 'components/Processes/Process4/dialogs/EditDialogProcess42Axp.vue';

export default {

  components: {
    EditDialogProcessAxp,
    SimpleAutocomplete,
    EditDialogProcess,
  },

  created() {
    if (!checkAccess(D1_A)) {
      this.onTapBack();
    }
  },
  data: () => ({
    progressValue: 0,

    contractId: null,
    contractName: '',
    contracts: [],

    deliveryContract: '',
    count: null,
    formElements: [],
    formAxps: [],
    axpHeadersTable: [
      {
        text: 'АХП',
        align: 'start',
        value: 'axpName',
      },
      { text: 'Количество', value: 'count' },
      { text: 'Цена за ед., руб.', value: 'price' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
    elementsHeadersTable: [
      {
        text: 'АХП',
        align: 'start',
        value: 'axpName',
      },
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      { text: 'Количество', value: 'count' },
      { text: 'Цена за ед., руб.', value: 'price' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],

    editDialog: false,
    editedItem: {},
    editedIndex: -1,

    dialogTableDelete: false,
    loaderDialog: false,

    disabledEditDialog: false,
  }),

  computed: {
    isDisabledSaveDialog() {
      const isDisabledContract = this.contractId == null;
      let isDisable = false;
      isDisable = !this.deliveryContract?.length;
      if (this.deliveryContract === 'AXP') {
        isDisable = !this.formAxps?.length;
      }
      if (this.deliveryContract === 'ELEMENT_AXP') {
        isDisable = !this.formElements?.length;
      }

      return (
        isDisabledContract ||
        isDisable
      );
    },
    ...mapGetters('user', ['getToken']),
  },
  watch: {
    contractId() {
      this.fetchContractData();
    },
  },
  methods: {
    async fetchContractData() {
      this.deliveryContract = '';
      this.formElements = [];
      this.formAxps = [];
      if (!this.contractId) return;
      const response = await fetch(`${BASE_URL}/bought-construction/${this.contractId}/specification`);
      const data = await response.json();
      if (!data) return;
      this.deliveryContract = data.type;
      this.formAxps = data.axps.map((axp) => ({
        axpId: axp.axp.id,
        axpName: axp.axp.name,
        count: axp.count,
        price: axp.price,
      }));
      this.formElements = data.elements.map((element) => ({
        axpId: element.element.axp.id,
        axpName: element.element.axp.name,
        elementId: element.element.id,
        elementName: element.element.name,
        count: element.countElement,
        price: element.price,
      }));
    },
    addElement(data) {
      const newData = {};
      Object.assign(newData, data);
      if (this.editedIndex > -1) {
        Object.assign(this.formElements[this.editedIndex], newData);
      } else {
        this.formElements.push(newData);
      }
      this.$refs.editDialog.afterClose();
      this.editedIndex = -1;
      this.editDialog = false;
    },

    addAxp(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.formAxps[this.editedIndex], { ...data });
      } else {
        this.formAxps.push({ ...data });
      }
      this.$refs.editDialog.afterClose();
      this.editedIndex = -1;
      this.editDialog = false;
    },

    cancelForAxpElements() {
      this.editedIndex = -1;
    },

    onTapCloseDeleteTableDialog() {
      this.dialogTableDelete = false;
    },

    arrayByType(type) {
      return type === 'axp' ? this.formAxps : this.formElements;
    },

    onTapDeleteItemConfirmTableDialog(type) {
      const arrayToDelete = this.arrayByType(type);
      arrayToDelete.splice(this.editedIndex, 1);
      this.onTapCloseDeleteTableDialog();
      this.editedIndex = -1;
    },

    editItem(item, type) {
      const arrayToEdit = this.arrayByType(type);
      this.editedIndex = arrayToEdit.indexOf(item);
      Object.assign(this.editedItem, arrayToEdit[this.editedIndex]);
      this.editDialog = true;
    },

    deleteItem(item, type) {
      this.editedIndex = this.arrayByType(type).indexOf(item);
      this.dialogTableDelete = true;
    },

    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        contractId: this.contractId,
        deliveryContract: this.deliveryContract,
        axpId: this.deliveryContract === 'AXP' ? this.formAxps.map((it) => ({
          id: it.axpId,
          count: it.count,
          price: it.price,
        })) : [...new Set(this.formElements.map((it) => it.axpId))].map((it) => ({ id: it, elements: [] })),
      };

      if (this.deliveryContract === 'ELEMENT_AXP') {
        request.axpId.forEach((axp) => {
          axp.elements = this.formElements.filter((el) => el.axpId === axp.id).map((el) => ({
            id: el.elementId,
            count: el.count,
            price: el.price,
          }));
        });
      }

      axios
        .post(`${BASE_URL}/bought-construction-specification`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.contractId = null;
      this.contractName = '';
      this.contracts = [];
      this.deliveryContract = '';

      this.formElements = [];
      this.formAxps = [];
      this.editDialog = false;
      this.editedItem = {};
      this.editedIndex = -1;

      this.dialogTableDelete = false;
      this.loaderDialog = false;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

