<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>
    </div>

    <template v-if="uploadReadings.attachment1Error.length > 0">
      <div :class="$style.content">
        <div>
          <p style="color: red; margin-bottom: 32px">Найдено ошибок: {{ uploadReadings.attachment1Error.length }}</p>
          <table :class="$style.details">
            <thead>
            <tr>
              <td>Строка</td>
              <td>Столбец</td>
              <td>Описание</td>
            </tr>
            </thead>
            <tbody :class="$style.tableTd">
            <tr v-for="(error, index) in uploadReadings.attachment1Error" :key="index">
              <td>{{ error.row }}</td>
              <td>{{ error.col }}<br />{{ error.colTitle }}</td>
              <td>{{ error.text }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p :class="$style.stepTitle">Загрузите исправленный документ XLSX на Платформу </p>

          <c-uploading
            v-model="filledAttachment1"
            accept=".xlsx, .xls"
            accept-title="XLSX, XLS или перетащите его в эту область."
            role="filledAttachment1"
          />

          <div v-if="uploadReadings.attachment1File" :class="$style.attachment" style="margin-bottom: 32px">
            <a :class="$style.fileAttachment" :download="uploadReadings.attachment1File.name"
               :href="fileUrl(uploadReadings.attachment1File)">
              <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40" />
              <span>{{ uploadReadings.attachment1File.name }}</span>
            </a>
            <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc1">
              <icon-delete />
            </a>
          </div>
        </div>
      </div>

    </template>
    <template v-else-if="uploadReadings.documentSubmissionErrors.length > 0">
      <div :class="$style.content">
        <div>
          <p style="color: red; margin-bottom: 32px">Найдено ошибок: {{ uploadReadings.documentSubmissionErrors.length }}</p>
          <table :class="$style.details">
            <thead>
            <tr>
              <td>Описание ошибки</td>
            </tr>
            </thead>
            <tbody :class="$style.tableTd">
            <tr v-for="(error, index) in uploadReadings.documentSubmissionErrors" :key="index">
              <td>{{ error }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p :class="$style.stepTitle">Загрузите исправленный документ XLSX на Платформу </p>

          <c-uploading
            v-model="filledAttachment1"
            accept=".xlsx, .xls"
            accept-title="XLSX, XLS или перетащите его в эту область."
            role="filledAttachment1"
          />

          <div v-if="uploadReadings.attachment1File" :class="$style.attachment" style="margin-bottom: 32px">
            <a :class="$style.fileAttachment" :download="uploadReadings.attachment1File.name"
               :href="fileUrl(uploadReadings.attachment1File)">
              <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40" />
              <span>{{ uploadReadings.attachment1File.name }}</span>
            </a>
            <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc1">
              <icon-delete />
            </a>
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <div :class="$style.content">
        <p :class="$style.stepTitle" style="color: green">Ошибок не найдено</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFileUrl } from 'lib/utils/files.js';
import { isErrorConvert } from 'lib/component/validators.js';
import { read, utils } from 'xlsx';
import { normalizeDiacritics } from '@/utils/string/normalize';

const attachment1NameWithoutExtensionFinally = 'Реестр показаний приборов учёта (Конечные потребители)';

export default {
  name: 'Additions',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    IconDelete: () => import('atoms/icons/action/Delete.vue'),
  },
  props: {
    stepNumber: Object,
    uploadReadings: Object,
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filledAttachment1: [],
      attachment1NameWithoutExtensionFinally: attachment1NameWithoutExtensionFinally,
      attachment1NameFinally: `${attachment1NameWithoutExtensionFinally}.xlsx`,
    };
  },
  computed: {
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'voltageClassList',
      'pointsKindList',
      'accountingMethodList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
    ]),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.isWaitingSubmit = true;

        const incorrectFiles = [];

        try {
          val.forEach((file) => {
            const excel = read(file.code, {
              type: 'base64',
              cellText: false,
              cellDates: true,
            });

            const tableArray = utils.sheet_to_json(excel.Sheets[excel.SheetNames[0]], {
              header: 1,
              defval: '',
              raw: false,
              blankrows: false,
              dateNF: 'DD/MM/YYYY',
            });

            if (tableArray.length === 0) {
              return [];
            }

            if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment1NameWithoutExtensionFinally)) !== -1) {
              this.uploadReadings.attachment1 = tableArray.slice(6, tableArray.length);
              this.uploadReadings.attachment1File = file;

              this.$emit('preValidations');
            } else {
              incorrectFiles.push(file.name);
            }

          });

        } catch (e) {
          console.log('error XLSX.read', e);
        }

        if (incorrectFiles.length > 0) {
          this.vueShowNotification(
            '',
            `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Не соответсвуют шаблону:</b></p><p>${this.attachment1NameFinally}</p>`,
);
        } else {
          this.uploadReadings.documentSubmissionErrors = [];
          this.$emit('preValidations');
        }

        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    changePoint() {
      this.disable = false;
    },
    savePoint() {
      this.disable = true;
    },
    deletePoint(index) {
      this.uploadReadings.attachment1.splice(index, 1);
    },
    copyPoint(index) {
      this.uploadReadings.attachment1.push(
        JSON.parse(JSON.stringify(this.uploadReadings.attachment1[index])),
      );

      this.scrollToEnd();
    },
    scrollToEnd() {
      setTimeout(() => {
        document.querySelector('[role=detailsContent]').scrollTo(0, document.body.scrollHeight);
      }, 200);
    },
    isErrorConvert(errorArray, indexPoint, indexItem) {
      return isErrorConvert(errorArray, indexPoint, indexItem);
    },
    deleteDoc1() {
      this.uploadReadings.attachment1 = [];
      this.uploadReadings.attachment1File = null;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.stepTitle
  +base-title
  margin-top: 32px
  margin-bottom: 32px

  span
    margin-right: 12px
    color: #979CA2

.smallTitle
  +card-title-small
  margin-top: 32px
  margin-bottom: 4px

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)


.form
  position: absolute
  width: 100%
  margin-bottom: 64px
  padding: 0 32px 0 0

  div
    overflow-x: auto

    &::-webkit-scrollbar-button
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 0
      border-radius: 10px
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb:hover
      background-color: #a19f9f

    &::-webkit-resizer
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar
      width: 10px
      height: 10px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment, .fileArchive
  +base-subtitle

.attachment
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.downloadIcon
  display: flex
  align-items: center

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

  color: #4C4E51

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 48px
  margin-top: 32px
  margin-bottom: 48px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF

.space
  opacity: 0

.test
  +small-text
  color: red

.textInputTd
  +input-text
  font-family: 'Roboto Condensed'
  color: gray

.textTableTd
  padding: 0 16px

.endGroup
  min-width: 200px

  textarea
    padding: 8px
    border: none

    &::-webkit-scrollbar
      width: 0
      height: 0

table.details
  width: 100%

  thead
    text-align: center

    td
      +table-text-small
      color: #0F1010
      padding-left: 8px
      padding-right: 24px
      padding-top: 12px
      padding-bottom: 12px
      vertical-align: middle
      border: 1px solid #71757A

    td.firstTitle
      text-align: left
      vertical-align: bottom

    td.noborder
      border: none

  tbody
    td
      border: 1px solid #71757A
      vertical-align: middle

      p
        margin: 4px

      label
        display: none

      input
        border: 0

        &:disabled
          color: #71757A

      select
        width: 100%
        height: 46px
        font-size: 18px
        padding-left: 12px
        border: 0
        outline: none

        &:disabled
          color: #71757A
          opacity: 1

      img
        width: 24px
        height: 24px
        margin-top: 12px
        cursor: pointer

      img.close
        width: 18px
        height: 18px
        margin-left: 8px
        margin-right: 24px

    td.dateField
      input
        width: 140px

      input:disabled
        background-color: #ffffff

    td.field100
      input
        width: 100px

      input:disabled
        background-color: #ffffff

    td.copy
      padding-left: 10px
      border-right: none

    td.delete, td.copy
      border: none

    .footer
      td
        border: none
        height: 22px

  thead
    tr.header
      td
        font-size: 16px
        background-color: #e5e5e5

  tbody > tr > th:first-child,
  tbody > tr > td:first-child
    width: 48px
    height: 48px
    background-color: white
    min-width: 60px

  td
    padding: 8px
    text-align: center
    margin: 0
</style>
