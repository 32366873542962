<template>
  <page title="Тэги ОДК">
    <v-table-new
      v-model="transformedOdkTags"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Тэги ОДК"
      @delete="onDelete"
      @edit="onEdit"
    />

    <tag-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { fetchOdkTags } from '@/api/rest/odk/api';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import { mapActions, mapGetters } from 'vuex';
import { filterForAccess } from 'lib/utils/permissions';
import TagEdit from 'views/private/OdkAccounting/Tags/TagEdit.vue';

export default {
  name: 'CabintOdkTagsScreen',
  components: {
    VTableNew,
    Page,
    TagEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      odkTags: [],
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ] : [];
    },
    fields() {
      return [{
        type: AddDirectoryFieldModalFieldType.TEXT,
        id: 'name',
        name: 'Тэг',
        required: true,
      }];
    },
    template() {
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              width: '5%',
            },
          },
          {
            label: 'Тэг',
            key: 'name',
            sort: {},
            thStyle: {
              width: '95%',
            },
            filter: { type: 'tagOdk' },
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
    isAccessUpdate() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    transformedOdkTags() {
      if (!this.odkTags?.length) {
        return [];
      }

      return this.odkTags;
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.odkTags = await fetchOdkTags();
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
