export const counterparty = {
  groups: [
    {
      title: 'Объекты',
      route: 'objects',
      iconComponent: 'HomeWork',
    },
    {
      title: 'Задачи',
      route: 'tasks',
      iconComponent: 'Tasks',
    },
    {
      title: 'Учёт объёмно-декоративных конструкций',
      iconComponent: 'LightningDevice',
      items: [
        {
          title: 'Программа оформления',
          route: 'adreska',
          iconComponent: 'HomeWork',
        },
        {
          title: 'Ведение договоров',
          route: 'initial-control-inspection',
          iconComponent: 'ControlInspection',
        },
        {
          title: 'Складские операции',
          route: 'warehouse-operations',
          iconComponent: 'Subtract',
        },
        {
          title: 'Эксплуатация',
          route: 'exploitation',
          iconComponent: 'Exploitation',
        },
        {
          title: 'Работа с дефектами',
          route: 'handling-defects',
          iconComponent: 'Defects',
        },
      ],
    },
    {
      title: 'Технологическое присоединение',
      iconComponent: 'TpGroup',
      items: [
        {
          title: 'Заявки на технологическое присоединение',
          route: 'tp',
          iconComponent: 'Tp',
        },
        {
          title: 'Договоры об осуществлении технологического присоединения',
          route: 'connection-application-contracts',
          iconComponent: 'AgreementsImplementationTechnologicalConnection',
        },
      ],
    },
    {
      title: 'Энергоснабжение',
      iconComponent: 'FlashAuto',
      items: [
        {
          title: 'Договоры энергоснабжения',
          route: 'electricity-supply-contracts',
          iconComponent: 'LibraryBooks',
        },
      ],
    },
    {
      title: 'Оплата',
      route: 'pay',
      iconComponent: 'Pay',
    },
    // TODO раздел в разработке
    // {
    //   title: 'УСЛУГИ',
    //   route: 'services',
    //   iconComponent: 'Service',
    // },
    {
      title: 'Документы',
      route: 'documents',
      iconComponent: 'Documents',
    },
    {
      title: 'Управление организацией',
      iconComponent: 'Partner',
      items: [
        {
          title: 'Сотрудники компании',
          route: 'employees',
          iconComponent: 'Partner',
        },
        {
          title: 'Доверенности',
          route: 'attorney',
          iconComponent: 'Attorney',
        },
        {
          title: 'Иерархия компании',
          route: 'company-hierarchy',
          iconComponent: 'Structure',
        },
        {
          title: 'Реквизиты компании',
          route: 'company-details',
          iconComponent: 'HomeWork',
        },
      ],
    },
    {
      title: 'Аналитика и отчетность',
      route: 'reports',
      iconComponent: 'Reports',
    },
    {
      title: 'Нормативно-справочная информация',
      iconComponent: 'Contacts',
      items: [
        {
          title: 'Список НСИ',
          route: 'guide-list',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник контрагентов',
          route: 'contractors',
          iconComponent: 'ContactPage2',
        },
        {
          title: 'Справочник полномочий',
          route: 'permissions-registry',
          iconComponent: 'PermissionsRegistry',
        },
        {
          title: 'Справочник административных округов',
          route: 'administrative-districts',
          iconComponent: 'Contacts',
        },
        {
          title: 'Зоны присутствия',
          route: 'presence-zones',
          iconComponent: 'WarehouseAccountingSystem',
        },
        {
          title: 'Справочник РЭС',
          route: 'oek-district-departments',
          iconComponent: 'Contacts',
        },
        {
          title: 'Склады',
          route: 'odk-accounting-warehouses',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'АХП',
          route: 'odk-accounting-axp',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Праздники',
          route: 'odk-accounting-holidays',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Мероприятия',
          route: 'odk-accounting-events',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Типы элементов АХП',
          route: 'odk-accounting-element-types',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Типы декора',
          route: 'odk-accounting-decor-types',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Типовые замечания',
          route: 'odk-accounting-defects',
          iconComponent: 'OdkAccounting',
        },
        {
          title: 'Тэги ОДК',
          route: 'odk-tags',
          iconComponent: 'Contacts',
        },
        {
          title: 'Справочник ответственных сотрудников подрядчиков',
          route: 'responsible-by-contractor',
          iconComponent: 'Contacts',
        },
      ],
    },
    {
      title: 'Профиль',
      route: 'user-details',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Доступные профили',
      route: 'available-profiles',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Чаты',
      route: 'user-requests',
      iconComponent: 'Chat',
    },
  ],
};
