<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Пересчёт</div>
          </v-card-title>
          <v-card-text>
            <process7 v-model="data" disabled-main-info/>
            <text-field
              ref="contractorEmployeeName"
              v-model="contractorEmployeeName"
              title="Сотрудник подрядчика"
            ></text-field>
            <file-input
              ref="photos"
              v-model="photos"
              class="mt-2"
              is-required
              title="Фото"
            ></file-input>
            <employee-autocomplete
              ref="performer"
              v-model="performerId"
              :items.sync="performerItems"
              :search.sync="performerName"
              class="mt-2"
              is-required
              set-current-by-default
              title="Сотрудник, совершивший операцию"
            ></employee-autocomplete>

            <warehouse-date-picker
              ref="date"
              v-model="date"
              is-required
              label="Дата накладной"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import FileInput from 'components/Processes/components/FileInput.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import WarehouseDatePicker from 'components/Processes/components/WarehouseDatePicker.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import Process7 from 'components/Processes/WarehouseForms/Process15-2.vue';
import { fetchWarehouseById, fetchAxpById, fetchContractDebtById, fetchEventById } from '@/api/rest/odk/api';
import TextField from 'components/Processes/components/TextField.vue';
import EmployeeAutocomplete from 'components/Processes/components/EmployeeAutocomplete.vue';

export default {
  components: {
    EmployeeAutocomplete,
    TextField,
    Process7,
    WarehouseDatePicker,
    Consignment,
    FileInput,
  },

  async created() {
    let params = this.$route.params;
    if (params.task) {
      localStorage.setItem('odk_task', JSON.stringify(this.$route.params));
    } else {
      params = JSON.parse(localStorage.getItem('odk_task'));
    }
    const warehouse = params.warehouseId != -1 ? await fetchWarehouseById(params.warehouseId) : null;
    const axp = params.axpId != -1 ? await fetchAxpById(params.axpId) : null;
    const contract = params.contract != -1 ? (await fetchContractDebtById(params.contract))[0]?.boughtConstructions : null;
    const event = params.eventId != -1 ? await fetchEventById(params.eventId) : null;
    const contractor = params.contractorId != -1 ? await this.getSupplierById({ id: params.contractorId }) : null;
    this.data = {
      ...this.data,
      isByContract: params.contract != -1,
      warehouseId: warehouse?.id ? String(warehouse.id) : null,
      warehouseName: warehouse?.name ?? '',
      warehouses: warehouse ? [{
        id: String(warehouse.id),
        name: warehouse.name,
      }] : [],
      axpId: axp?.id ? String(axp.id) : null,
      axpName: axp?.name ?? '',
      axpItems: axp ? [{
        id: String(axp.id),
        name: axp.name,
      }] : [],
      contractId: contract?.id,
      contractName: contract?.contract ?? '',
      contracts: contract ? [{
        id: contract.id,
        name: contract.contract,
      }] : [],
      eventId: event?.id,
      eventName: event?.name ?? '',
      eventItems: event ? [{
        id: event.id,
        name: event.name,
      }] : [],
      contractorId: contractor?.id,
      contractorName: contractor?.name ?? '',
      contractors: contractor ? [{
        id: contractor.id,
        name: contractor.name,
      }] : [],
      taskId: params.task,
      searchParams: { ...params },
      formElements: [],
      toEdit: params?.toEdit ?? false,
    };
    this.taskId = params.task;
  },
  data: () => ({
    progressValue: 0,

    warehouseId: -1,
    axpId: -1,
    contractorId: '',
    eventId: null,
    contract: null,


    performerId: null,
    performerName: '',
    performerItems: [],

    taskId: -1,

    data: {
      formElements: [],

      eventId: null,
      warehouseId: null,
      contractId: null,
      contractorId: null,
      axpId: null,
      isByContract: null,

      warehouseName: '',
      contractorName: '',
      contractName: '',
      axpName: '',
      eventName: '',

      isDisabled: true,
    },

    taskData: {
      axpName: '',
      contractorName: '',
      eventName: null,
      contract: null,
      warehouseName: '',
    },

    formElements: [],

    photos: [],


    isLoadingElements: false,

    loaderDialog: false,

    dialogForViewReport: false,
    dataReportCustom: {},

    date: null,

    contractorEmployeeName: null,
  }),

  computed: {
    isDisabledSaveDialog() {
      const isDisabledDate = this.date == null;
      const isDisabledPerformer = this.performerId == null;
      const isDisabledPhotos = !this.photos.length;

      return this.data.isDisabled || isDisabledDate || isDisabledPhotos || isDisabledPerformer;
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },

  methods: {
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        performerUserId: this.performerId,
        taskId: this.taskId,
        createDate: this.date,
        axpId: this.data.axpId,
        warehouseId: this.data.warehouseId,
        contractorId: this.data.contractorId,
        contractorEmployeeName: this.contractorEmployeeName,
        elements: [],
        photos: mainPhotos,
      };

      if (JSON.parse(localStorage.getItem('odk_task').previousConsignmentId)) request.previousConsignmentId =  this.$route.params.previousConsignmentId;

      this.data.eventId !== -1 && this.data.eventId ? request.eventId = this.data.eventId : false;
      this.data.contractId !== -1 && this.data.contractId ? request.contract = this.data.contractId : false;
      this.data.formElements.forEach((f) => {
        request.elements.push({
          id: f.elementId,
          count: f.realCount ?? 0,
        });
      });

      axios
        .post(`${BASE_URL}/recalculation`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },
    onTapViewReport() {
      const els = this.data.formElements.filter((element) => Number(element.realCount) > 0).map((element) => ({
        axpName: this.data.axpName,
        elementName: element.elementName,
        elementQuantity: element.elementQuantity,
        date: formatDateFromOdkTimestamp(this.date),
        type: 'Пересчёт',
        unitName: element.unitName,
        count: element.realCount ?? 0,
        performer: this.performerName,
        photos: this.photos,
        contractorName: this.data.contractorName,
        eventName: this.data.eventName,
        warehouseName: this.data.warehouseName,
        employee: this.getUser.fullName,
        contractorEmployeeName: this.contractorEmployeeName,
      }));
      this.dataReportCustom = els[0];
      this.dataReportCustom.elements = els;
      this.dialogForViewReport = true;
    },
    ...mapActions('user', ['setNotification']),
    ...mapActions('dataLists', ['getSupplierById']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

