import browserProperties from '@/utils/browser';
import getters from './getters';

const state = {
  browserProperties,
};

export default {
  namespaced: true,
  state,
  getters,
};
