<template>
  <v-search-select
    v-if="isVsearch"
    ref="autocomplete"
    v-model="selectedItem"
    :placeholder="placeholder"
    :fetch-data-function="getSuppliers"
    :fetch-default-data-function="getSuppliers"
    :dependency="dependency"
    :disabled="isDisabled"
    :required="isRequired"
    :items.sync="internalItems"
    :title="title"
    :search.sync="searchItem"
    :not-reset-search="notResetSearch"
    @input="selectItem"
  />
  <simple-autocomplete-by-function
    v-else
    ref="autocomplete"
    v-model="selectedItem"
    :dependency="dependency"
    :fetch-data-function="getSuppliers"
    :fetch-default-data-function="getSuppliers"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :items.sync="internalItems"
    :search.sync="searchItem"
    :selected="selected"
    :title="title"
  />
</template>

<script>

import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { mapActions } from 'vuex';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';

export default {
  components: { SimpleAutocompleteByFunction, VSearchSelect },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    selected: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      default: null,
      required: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isVsearch: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    notResetSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalItems: [],
    };
  },
  emits: ['update:search', 'change', 'update:items'],
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
  },
  watch: {
    internalItems() {
      this.$emit('update:items', this.internalItems);
    },
    items: {
      immediate: true,
      handler() {
        this.internalItems = this.items;
      },
    },
  },

  methods: {

    ...mapActions('cabinet', ['getSupplierForSelectorByFilter']),

    async getSuppliers(name) {
      return this.getSupplierForSelectorByFilter(name ? { name: name, kind: 'ODK' } : { kind: 'ODK' });
    },

    resetValidation() {
      this.$refs.autocomplete.resetValidation();
    },
    selectItem(value, label) {
      this.selectedItem = value;
      this.$emit('update:search', label);
    },
  },
};
</script>
