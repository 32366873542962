<template>
  <div>
    <v-circle-loader v-if="isLoading" />
    <div v-else :class="$style.container">
      <view-changing
        v-if="task.userType === 'Customer'"
        v-model="isShownTaskDetails"
        :data="data"
        :tariff-application="tariffApplication"
        :review-document="reviewDocument"
        :tariffs-data="tariffsData"
        :tariff-document="tariffDocument"
        :create-date="createDate"
        @retry="tariffsChanging"
      />
      <review-changing
        v-if="task.userType !== 'Customer'"
        v-model="isShownTaskDetails"
        :data="data"
        :tariff-application="tariffApplication"
        :review-document="reviewDocument"
        :tariffs-data="tariffsData"
        :tariff-document="tariffDocument"
        :create-date="createDate"
      />
      <tariffs-changing v-model="displayTariffsChanging" :service-location="tariffsChangingServiceLocation" />
  </div>
  </div>
</template>

<script>
// import MoreVertIcon from 'atoms/icons/basic/MoreVert';
import ViewChanging from 'organisms/tasks/tariffs-changing/action/ViewChanging';
import ReviewChanging from 'organisms/tasks/tariffs-changing/action/ReviewChanging';
import { mapActions, mapGetters } from 'vuex';
import { formatDateToCustomDateFormat } from 'lib/utils/date';
import { initTariffsChangingTaskData } from 'organisms/tasks/tariffs-changing/initData';
import router from '@/router';
import VCircleLoader from 'components/VCircleLoader/VCircleLoader';

export default {
  name: 'Card',
  components: {
    // MoreVertIcon,
    ViewChanging,
    ReviewChanging,
    TariffsChanging: () => import('organisms/tariffs/TariffsChanging.vue'),
    VCircleLoader,
  },
  async created() {
    this.task = this.getTaskDetails;

    if (!this.task?.workflowType) {
      router.push('/cabinet/tasks/');

      console.error('task loading error');
      return;
    }

    this.isLoading = true;

    await this.loadData();

    const initData = initTariffsChangingTaskData(this.task);
    this.title = initData.title;
    this.currentStatus = initData.status;
    this.data = initData.data;

    this.isLoading = false;
  },
  data() {
    return {
      data: {},
      tariffApplication: {},
      title: '',
      currentStatus: {},
      isShownTaskDetails: true,
      reviewDocument: {},
      tariffDocument: [],
      tariffsData: {},
      isLoading: true,
      createDate: '',
      displayTariffsChanging: false,
      tariffsChangingServiceLocation: {},
      task: {},
    };
  },
  computed: {
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
    ...mapGetters('tasks', ['getTaskDetails']),
  },
  watch: {
    async isShownTaskDetails(newVal) {
      this.isLoading = true;
      if (newVal) {
        await this.loadData();
        this.isLoading = false;
      } else {
        await router.push('/cabinet/tasks');
      }
    },
  },
  methods: {
    onShownTaskDetails() {
      this.isShownTaskDetails = true;
    },
    async loadData() {
      if (this.task.documentId) {
        const pureDataChangingTariffApplication = await this.loadDocument(this.task.documentId);
        const prepareChangingTariffApplication = JSON.parse(pureDataChangingTariffApplication.json);

        this.createDate = formatDateToCustomDateFormat(prepareChangingTariffApplication.Date, 'DD.MM.YYYY');

        const rateXml = {
          rate1: 'SINGLE_RATE',
          rate2: 'DOUBLE_RATE',
          rate3: 'MULTI_RATE',
        };

        this.tariffApplication = {
          ...prepareChangingTariffApplication,
          currentConsumerCategory: prepareChangingTariffApplication.CurrentConsumerCategory.replace('-', '_').toUpperCase(),
          newConsumerCategory: prepareChangingTariffApplication.NewConsumerCategory.replace('-', '_').toUpperCase(),
          currentTariff: rateXml[prepareChangingTariffApplication.CurrentTariff],
          newTariff: rateXml[prepareChangingTariffApplication.NewTariff],
        };

        if (prepareChangingTariffApplication.Documents && prepareChangingTariffApplication.Documents.Document) {
          this.tariffDocument = prepareChangingTariffApplication.Documents.Document;
        }
      }

      // TODO add region in workflow
      this.tariffsData = await this.actualTariffsByRegion('MSK');

      if (this.task.reviewDocumentId) {
        const pureDataReviewDoc = await this.loadDocument(this.task.reviewDocumentId);
        const preparedDataReviewDoc = JSON.parse(pureDataReviewDoc.json);

        this.reviewDocument = {
          comments: preparedDataReviewDoc.Comments,
        };
      }
    },
    async tariffsChanging() {
      this.tariffsChangingServiceLocation = await this.loadUsagePoint(this.task.serviceLocationId);
      this.displayTariffsChanging = true;
    },
    ...mapActions('document', ['loadDocument', 'actualTariffsByRegion', 'tariffsById']),
    ...mapActions('serviceLocations', ['loadUsagePoint']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 452px
    position: relative
    background-color: #fff
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    margin-bottom: 16px
    margin-right: 16px

    &:hover
      cursor: pointer

  .content
    display: flex
    flex-direction: column
    align-items: space-between
    padding: 16px 32px 16px 16px

  .moreIcon
    position: absolute
    top: 8px
    right: 8px

  .title
    +card-task-title
    margin: 0 0 8px
    @media screen and (min-width: 800px)
      font-size: 20px
    @media screen and (min-width: 1100px)
      font-size: 24px

  .subtitle
    margin: 0 0 8px
    p
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 18px
      line-height: 24px
      color: #0E0F0F
      letter-spacing: 0.01em

  .caption
    margin: 0
    p
      +card-task-caption


  .process,
  .expairedDate,
  .process
    align-self: flex-end
    p
      +card-task-status
      text-align: right

  .process p
    color: #2F82DF

  .expairedDate p
    color: #ACB0B5

  .footer
    margin-top: 8px

  .footerExpires
    display: flex
    justify-content: space-between
    margin-top: 8px
</style>
