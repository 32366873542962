<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps" />
        <span>Гарантирующий поставщик с которым заключен (планируется заключать) договор</span>
      </h2>
    </div>

    <div v-if="supplierOfLastResortList.length > 0" :class="$style.gridFluid">
      <div>
        <p :class="$style.textHelper">
          <span>Тип договора со сбытовой организацией *</span>
        </p>
        <c-select
          v-model="connectionApplication.connectionApplicationWithContractType"
          :options="connectionApplicationWithContractTypeList"
          style="margin-bottom: 10px"
          role="connectionApplicationWithContractType"
          :error="errors.connectionApplicationWithContractType"
        />
        <p :class="$style.textHelper">
          <span>Гарантирующий поставщик электроэнергии или энергосбытовая компания *</span>
          <tooltip-helper offset="6px" placement="left">
        <div :class="$style.tooltip" style="width: 320px;">
          <p>Компания, которой вы будете оплачивать электроэнергию по договору электроснабжения.</p>
        </div>
        </tooltip-helper>
        </p>
        <v-search-select
            v-model="supplierOfLastResortName"
            :options="sortedSuppliersOptions"
            :error="errors.supplierOfLastResort"
            role="supplierOfLastResort"
            :err="errors.supplierOfLastResort?.error"
        />
        <p
            v-if="connectionCharacteristics.supplierOfLastResort && !connectionCharacteristics.supplierOfLastResort.available"
            :class="$style.inputNote">Этот гарантирующий поставщик может отказаться заключить с вами договор
          энергоснабжения. Возможно, вы находитесь вне границ его зоны деятельности.</p>
      </div>
    </div>

    <div :class="$style.grid2Column">
      <c-input
        v-model="connectionCharacteristics.retailAgreementNumber"
        :label="'Номер договора ' + requiredLabelFor"
        role="retailAgreementNumber"
        :error="errors.retailAgreementNumber"
      />
      <div>
        <p :class="$style.textHelper" style="margin-bottom: 11px">
          <span>Дата договора {{ requiredLabelFor }}</span>
        </p>
        <v-date-picker
            v-model="connectionCharacteristics.retailAgreementNumberDate"
            :err="errors.retailAgreementNumberDate?.error"
            role="retailAgreementNumberDate"
            err-text="Поле обязательно для заполнения"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StepCounter from '../components/StepCounter';
import { VDatePicker } from 'components';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect';

export default {
  name: 'SupplierOfLastResort',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    CSelect: () => import('atoms/common/CSelect'),
    StepCounter,
    VDatePicker,
    VSearchSelect,
  },
  props: {
    connectionCharacteristics: {
      type: Object,
      required: true,
    },
    connectionApplication: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.supplierOfLastResortName = this.connectionCharacteristics.supplierOfLastResort?.label ?? '';
  },
  data() {
    return {
      summaryMaxPower: '0',
      summaryPhaseVoltage: '0',
      supplierOfLastResortName: '',
    };
  },
  computed: {
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    sortedSuppliersOptions() {
      const options = [...this.supplierOfLastResortList];

      options.sort((a, b) => {
        if (a.label === 'АО "Мосэнергосбыт"') {
          return -1;
        } else if (b.label === 'АО "Мосэнергосбыт"') {
          return 1;
        }
        return 0;
      });

      return options;
    },
    requiredLabelFor() {
      return this.connectionApplication.reasonToConnection.value === 'POWER_INCREASE' ? '*' : '(при наличии)';
    },
    ...mapGetters('dataLists', [
      'supplierOfLastResortList',
      'connectionApplicationTypeList',
      'connectionApplicationWithContractTypeList',
    ]),
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    calcSummaryMaxPower: {
      immediate: true,
      handler(val) {
        this.summaryMaxPower = val === 0 ? '' : val;
      },
    },
    summaryPhaseVoltage: {
      immediate: true,
      handler(val) {
        this.summaryPhaseVoltage = val === 0 ? '' : val;
      },
    },
    supplierOfLastResortName(val) {
      if (this.sortedSuppliersOptions.length === 0) {
        return;
      }
      this.connectionCharacteristics.supplierOfLastResort = this.sortedSuppliersOptions.filter((company) => company.label === val)[0] ?? {};
    },
  },
};
</script>

<style lang="sass" module>
  .stepContainer
    +questionnaries-container
    +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .textHelper
    +base-text
    color: #141412
    display: flex
    span
      margin-right: 10px


  .noteContainer
    +base-spacing

    p
      +small-spacing
      +small-text

    a
      +base-link

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .textDescription
    p
      +base-text

    a
      +base-link

  .dateLabel
    margin-bottom: 8px
    font-size: 16px
    line-height: 24px
</style>
