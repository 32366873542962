<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps" />
        <span>Характеристики микрогенератора</span>
      </h2>
    </div>

    <div :class="$style.noteContainer">
      Если условия подключения микрогенератора удовлетворяют условиям <a href="#" target="_blank">п.13 постановления Правительства РФ от 02.04.2021 г. №299</a>, стоимость подключения составит 550&nbsp;₽. В противном случае сетевая организация предложит стоимость работ.
    </div>

    <div v-for="(item, idx) in microgenerator.items" :key="idx" role="items">
      <h2 :class="$style.title">
        <span>Микрогенератор №{{ idx + 1 }}</span>
      </h2>

      <div :class="$style.gridFluid">
        <c-input
          v-model="item.MicrogeneratorLocation"
          label="Место установки микрогенератора *"
          :error="emptyErrors(item.MicrogeneratorLocation)"
          :validation="$validate.isNotEmptyString"
        />
      </div>

      <div :class="$style.grid2Column">
        <c-input
          v-model="item.MaxGeneratePower"
          label="Максимальная генерируемая мощность *"
          placeholder="0 кВт"
          :role="'Microgenerator[' + idx + '].MaxGeneratePower'"
          type="number"
          :error="emptyErrors(item.MaxGeneratePower)"
          :validation="$validate.isNotEmptyString"
        />
        <c-input
          v-model="item.PowerTransmittedToNetwork"
          label="Мощность, которую планируете передавать в сеть *"
          placeholder="0 кВт"
          :role="'Microgenerator[' + idx + '].PowerTransmittedToNetwork'"
          type="number"
          :error="emptySummaryErrors(item.PowerTransmittedToNetwork)"
          :error-text="summaryErrorText"
          :local-error="true"
          :validation="$validate.isNotEmptyString"
        />
      </div>
      <div :class="{[$style.grid2Column]: true, [$style.topStick]: true}">
        <div></div>
        <div :class="$style.summaryLimit">
          <span v-if="summaryError.error" :class="$style.localErrorText">{{ summaryErrorNote }}</span>
          <span v-else>в сумме не может превышать {{ microgenerator.maxPowerConnection }} кВт – максимальную мощность существующего подключения</span>
        </div>
      </div>

      <div :class="$style.gridFluid">
        <p>Технические характеристики *</p>
        <c-textarea
          v-model="item.Characteristics"
          :role="'Microgenerator[' + idx + '].Characteristics'"
          :error="emptyErrors(item.Characteristics)"
          :validation="$validate.isNotEmptyString"
        />
        <span :class="$style.textNote">
          Сотрудник сетевой компании сможет быстрее дать ответ по заявке, если вы подробно опишете характеристики устройств или приложите фото технического паспорта.
        </span>
      </div>

      <div :class="{[$style.gridFluid]: true, [$style.paddingBottom]: true}">
        <p :class="$style.description">
          Сфотографируйте или отсканируйте страницы с техническими характеристиками устройств
        </p>
        <div>
          <div v-if="item.DocumentScans && item.DocumentScans.length">
            <file-list-preview
              :list="item.DocumentScans"
              action
              :role="'Microgenerator[' + idx + '].DocumentScans'"
              @delete="(file) => { handleRemoveDocumentScans(idx, file) }"
            />
          </div>

          <c-uploading
            v-model="item.DocumentScans"
            :role="'Microgenerator[' + idx + '].DocumentScans'"
            :error="errors.DocumentScans"
          />
        </div>
      </div>
    </div>

    <div :class="{[$style.actionLabel]: true, [$style.paddingBottom]: true}">
      <button role="Microgenerator.add" @click="handleAction">+ ещё один микрогенератор</button>
    </div>

    <div :class="{[$style.grid2Column]: true, [$style.localErrorText]: summaryError.error}">
      <div :class="{[$style.summaryTitle]: true, [$style.localErrorText]: summaryError.error}">
        <span>Суммарная мощность всех подключаемых микрогенераторов</span><br/>
      </div>
      <div :class="{[$style.summaryValue]: true, [$style.localErrorText]: summaryError.error}">
        <span>{{ summaryValue }} кВт</span>
      </div>
    </div>
    <div :class="{[$style.gridFluid]: true, [$style.summaryLimit]: true, [$style.topStick]: true}">
      <span v-if="summaryError.error" :class="$style.localErrorText">{{ summaryErrorNote }}</span>
      <span v-else>не может превышать {{ microgenerator.maxPowerConnection }} кВт</span>
    </div>

    <div :class="$style.grid2Column">
      <c-date-picker
        v-model="microgenerator.ReadyDate"
        :error="errors.ReadyDate"
        error-text="Дата не может быть раньше текущей"
        label="Когда объекты будут готовы к подключению"
        role='ReadyDate'
      />
    </div>

    <div v-if="microgenerator.supplierOfLastResort" :class="{[$style.gridFluid]: true, [$style.paddingTop]: true}">
      <span :class="$style.summaryLimit">Договор на куплю-продажу электроэнергии будет заключён со сбытовой компанией {{ microgenerator.supplierOfLastResort.label }}.  </span>
    </div>

    <div v-if="microgenerator.gridCompany" :class="{[$style.gridFluid]: true, [$style.paddingTop]: true, [$style.summaryLimit]: true}">
      <p>Работы выполнит {{ microgenerator.gridCompany.gridCompanyName.label }}</p>
      <p>{{ microgenerator.gridCompany.gridCompanyName.address }}</p>
      <p>Тел: <a :href="'tel:' + microgenerator.gridCompany.gridCompanyName.phone">{{ microgenerator.gridCompany.gridCompanyName.phone }}</a></p>
      <p>E-mail: <a :href="'mailto:' + microgenerator.gridCompany.gridCompanyName.email">{{ microgenerator.gridCompany.gridCompanyName.email }}</a></p>
      <p><a :href="'https://' + microgenerator.gridCompany.gridCompanyName.host" target='_blank'>{{ microgenerator.gridCompany.gridCompanyName.host }}</a></p>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import StepCounter from '../components/StepCounter';
import { EVENT_BUS } from 'root/eventBus';

/* const INITIAL_DATA = {
  MicrogeneratorLocation: '',
  MaxGeneratePower: '',
  PowerTransmittedToNetwork: '',
  Characteristics: '',
  DocumentScans: [],
  DesignDate: '',
  ReadyDate: '',
};*/

export default {
  name: 'Microgenerator',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    CDatePicker: () => import('atoms/common/inputs/CDatePicker'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    FileListPreview,
    StepCounter,
  },
  props: {
    microgenerator: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  created() {
    // TODO remove EVENT_BUS
    EVENT_BUS.$on('VALIDATE', (value) => {
      this.errors[value] = { error: true };
      this.error = { error: true };
    });
  },
  data() {
    return {
      error: { error: false },
      errors: {
        MicrogeneratorLocation: { error: false },
        MaxGeneratePower: { error: false },
        PowerTransmittedToNetwork: { error: false },
        Characteristics: { error: false },
        DocumentScans: { error: false },
        DesignDate: { error: false },
        ReadyDate: { error: false },
      },
    };
  },
  computed: {
    isShownAddressMailIndex() {
      return this.$validate.isNotEmptyString(this.microgenerator.address);
    },
    isShownRegistrationAddressMailIndex() {
      return this.$validate.isNotEmptyString(this.microgenerator.registrationAddress);
    },
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    summaryValue() {
      let summary = 0;
      this.microgenerator.items.forEach((item) => {
        if (item.PowerTransmittedToNetwork) {
          summary += parseFloat(item.PowerTransmittedToNetwork);
        }
      });
      return this.parseFloatToFix(summary);
    },
    summaryError() {
      return {
        error: parseFloat(this.microgenerator.maxPowerConnection) - this.summaryValue < 0,
      };
    },
    summaryErrorText() {
      if (this.summaryError.error) {
        return '';
      }
      return undefined;
    },
    summaryErrorNote() {
      return `Уменьшите суммарную мощность на микрогенераторах до ${this.microgenerator.maxPowerConnection} кВт, чтобы она не превышала мощность существующего подключения`;
    },
  },
  methods: {
    handleRemoveDocumentScans(idx, file) {
      this.microgenerator.items[idx].DocumentScans = this.microgenerator.items[idx].DocumentScans
        .filter((scan) => scan.source !== file.source);
    },
    handleAction() {
      this.microgenerator.items.push({
        MicrogeneratorLocation: '',
        MaxGeneratePower: '',
        PowerTransmittedToNetwork: '',
        Characteristics: '',
        DocumentScans: [],
        DesignDate: '',
        ReadyDate: '',
      });
    },
    emptyErrors(value) {
      return { error: (this.error.error && this.$validate.isEmptyString(value)) };
    },
    emptySummaryErrors(value) {
      return {
        error: (this.error.error && this.$validate.isEmptyString(value)) || (parseFloat(this.microgenerator.maxPowerConnection) - this.summaryValue < 0),
      };
    },
    parseFloatToFix(value) {
      return Math.round(value * 1000) / 1000;
    },
  },
};
</script>

<style lang="sass" module>
  .stepContainer
    +questionnaries-container
    +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .noteContainer
    +base-spacing

    p
      +small-spacing
      +small-text

    a
      +base-link

  .description
    +small-text
    margin-top: 4px

  .actionLabel
    display: flex
    justify-content: center

    button
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 21px
      line-height: 24px
      color: #141412
      cursor: pointer
      text-decoration: underline
      background: transparent

  .paddingBottom
    padding-bottom: 38px

  .paddingTop
    padding-top: 16px

  .summaryTitle
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 21px
    line-height: 28px
    color: #141412


  .summaryLimit, .textNote
    font-family: Roboto
    font-weight: 400
    font-size: 16px
    line-height: 22px
    color: #141412

  .summaryValue
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 36px
    line-height: 52px
    color: #141412
    text-align: right

  .localErrorText
    color: red

  .topStick
    margin-top: -16px

  .textNote
    margin-top: 8px
    color: #C1C4C7

</style>
