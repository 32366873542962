import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const INITIAL_ZOOM = 10;

function initizlizeZoom() {
  const storageData = localStorage.getItem('zoom');

  if (storageData === null) {
    return INITIAL_ZOOM;
  }

  return JSON.parse(storageData);
}

const state = {
  zoom: initizlizeZoom(),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
