export const clearLocalStorage = () => {
  // нельзя удалять application_user_delete_profile, т.к. нужно предусмотреть
  // случай, перелогина (ФЛ -> поддержка -> ФЛ) в рамках одной и той же
  // вкладки браузера
  const userDeleteProfileKey = 'application_user_delete_profile';

  // настройки отображения (значения фильтров и отображения столбцов в таблицах)
  const userSettings = 'settings';

  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach((key) => {
    if (key !== 'agreedCookie' && !key.includes(userDeleteProfileKey) && !key.includes(userSettings)) {
      localStorage.removeItem(key);
    }
  });
};
