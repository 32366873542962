<template>
  <v-row>
    <v-col :style="disableVerticalPadding?{padding: '0 12px'}:{}">
      <div>
        <v-label :class="{
          'error-label': !isPhotoInputValid,
        }">{{ internalTitle }}
        </v-label>
      </div>
      <v-file-input
        ref="input"
        v-model="privateData"
        :clearable="!onlyView "
        :counter="!onlyView"
        :disabled="isMainLoad"
        :error="!isPhotoInputValid"
        :loading="isMainLoad"
        :rules="[rules.leastOnePhoto]"
        dense
        multiple
        outlined
        placeholder="Максимальный объем файлов составляет 15 Мбайт"
        prepend-icon=""
        v-bind="$attrs"
        @focusout="validate"
        v-on="$listeners"
        @click.native="handleClick"
        @click:clear="onTapAllPhotosClear"
      >
        <template #prepend-inner>
          <v-icon v-if="!onlyView" :disabled="isMainLoad" color="primary" @click="openFileFinder"
          >mdi-plus-circle
          </v-icon
          >
        </template>
        <template #selection="{ index, text }">
          <v-chip
            v-if="displayImages"
            :clearable="!onlyView"
            :close="!onlyView"
            :disabled="isMainLoad"
            class="truncate-text"
            label
            small
            style="height: 68px"
            @click="openCarousel(index)"
            @click:close="!onlyView ? deleteChipPhoto(index) : null"
          ><img :src="getPreview(index)" style="height: 64px;" @click="openCarousel(index)">
          </v-chip>
          <v-chip
            v-else
            :clearable="!onlyView"
            :close="!onlyView"
            :disabled="isMainLoad"
            class="truncate-text"
            color="primary"
            label
            small
            @click="openCarousel(index)"
            @click:close="!onlyView ? deleteChipPhoto(index) : null"
          > {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-dialog v-model="isVisibleCarousel" class=" v-dialog-top"
                data-app>
        <div class="v-application" data-app>
          <v-carousel v-model="carouselItem" class="files-carousel" height="auto"
          >
            <v-carousel-item v-for="(item, index) in privateData" :key="index">
              <div class="align-center justify-center d-flex">
                <img :src="item.previewUrl" class="files-carousel__image"/>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-dialog>
      <input
        ref="fileInput"
        multiple
        onclick="this.value=null;"
        style="display: none"
        type="file"
        @change="onChangePhoto"
      />
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
    displayImages: {
      type: Boolean,
      default: false,
    },
    disableVerticalPadding: {
      type: Boolean,
      default: false,
    },
    reValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateLoading: false,
      carouselItem: 0,
      privateData: [],
      isPhotoInputValid: true,
      showDownloadIcon: true,
      isVisibleCarousel: false,
      rules: {
        leastOnePhoto: () => (
          !this.isRequired || Boolean(this.privateData.length) ||
          'Необходимо добавить хотя бы одно фото'
        ),
      },
    };
  },

  computed: {
    isMainLoad() {
      return this.loading || this.privateLoading;
    },
    internalTitle() {
      if (!this.title) return;
      if (this.isRequired) return `${this.title}*`;
      return this.title;
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.privateData = this.value ?? [];
      },
    },
    privateData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.privateData);
      },
    },
    reValidate() {
      this.validate();
    },
  },

  methods: {
    getPreview(index) {
      return this.privateData[index].previewUrl;
    },
    openFileFinder() {
      this.$refs.fileInput.click();
    },

    handleClick(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    openCarousel(idx) {
      this.isVisibleCarousel = true;
      this.carouselItem = idx;
    },

    onTapAllPhotosClear() {
      this.privateData = [];
      this.validate();
    },

    onChangePhoto(event) {
      if (event.type === 'change') {
        const newFiles = event.target.files;
        if (newFiles.length) {
          for (const file of newFiles) {
            const exists = this.privateData.some(
              (f) => (f.file === null ? false : f.file.name === file.name),
            );
            if (!exists) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const previewUrl = e.target.result;
                const data = {
                  fileId: null,
                  file: file,
                  name: file.name,
                  previewUrl: previewUrl,
                  new: true,
                };
                this.privateData = [...this.privateData, data];
                this.isPhotoInputValid = true;
              };

              reader.readAsDataURL(file);
            }
          }
        }
        this.validate();
      }
    },

    deleteChipPhoto(index) {
      this.privateData = this.privateData.filter((it, idx) => idx !== index);
      this.validate();
    },
    validate() {
      this.isPhotoInputValid = !(!this.privateData.length && this.isRequired);
    },
    resetValidation() {
      this.isPhotoInputValid = true;
      this.$refs.input.resetValidation();
      this.defaultValue();
    },

    defaultValue() {
      this.privateData = [];
    },
  },
};
</script>

<style scoped>
.files-carousel__image {
  min-height: 10vh;
  max-height: 75vh;
  max-width: 100vw;
}
</style>
