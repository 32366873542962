<template>
  <div :class="$style.container">
    <div>
      <div v-if="retailReviewDocument && retailReviewDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.commentsTitle">
          {{ retailCompanyName }} запросила дополнительную информацию для заключения {{ actionProps.agreementTitle }}:
          <p v-html="comments"></p>
        </div>

        <div v-if="retailReviewDocument.files" :class="$style.reviewDocumentFiles">
          <p v-for="(file, index) in retailReviewDocument.files" :key="index"
             style="display: flex; justify-content: space-between">
            <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
               :role="'retailReviewFile[' + index + '].name'">{{ file.name }}</a>
            <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
               :role="'retailReviewFile[' + index + '].download'">
              <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
              скачать</a>
          </p>
        </div>
      </div>

      <div :class="$style.blockContent">
        <div :class="$style.title">
          Предоставьте необходимую информацию для заключения {{ actionProps.agreementTitle }}*
        </div>
        <p>Напишите её в этом поле</p>
        <c-textarea
          v-model="correctionComments"
          role="correctionComments"
        />
      </div>

      <div :class="$style.blockContent">
        <p :class="$style.subtitle">
          Приложите необходимые документы
        </p>

        <div>
          <div v-if="correctionDocs.length">
            <file-list-preview
              :list="correctionDocs"
              action
              @delete="handleRemoveCorrectionDocs"
            />
          </div>

          <c-uploading
            v-model="correctionDocs"
            role="correctionDocs"
          />
        </div>
      </div>
    </div>

    <div :class="$style.actionContent">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit || !isValid" title="ОТПРАВИТЬ" @submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';

export default {
  name: 'AdditionalInfo',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    FileListPreview,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    retailReviewDocument: Object,
    retailCompanyName: String,
  },
  data() {
    return {
      isChecked: true,
      correctionComments: '',
      submitError: '',
      isWaitingSubmit: false,
      correctionDocs: [],
    };
  },
  computed: {
    isValid() {
      return this.correctionDocs.length > 0 || this.correctionComments !== '';
    },
    comments() {
      return formatting.formattedString(this.retailReviewDocument.comments, '«', '»');
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      const documentReviewXml = connectionApplicationData.documentReviewXml(
        this.documentId,
        this.actionProps.reviewType,
        this.correctionComments,
        this.correctionDocs,
        {},
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              'ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ ОТПРАВЛЕНА',
              '<p></p>',
            );
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    handleRemoveCorrectionDocs(file) {
      this.correctionDocs = this.correctionDocs
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.title
  padding-top: 40px
  padding-bottom: 24px
  +base-title

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.blockContent
  margin-bottom: 40px

.statusGroup
  margin-top: 40px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.accompanyingText
  margin-bottom: 24px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 16px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.actionContent
  width: 560px
  margin: 0 auto
</style>
