<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="back">{{ 'Задачи' }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ toTitle }}</span>
    </p>

    <div :class="$style.content">
      <permission-registry-changes-task :draft-full-name="draftFullName" :params="params"/>
    </div>

    <div :class="$style.contentFooter">
      <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { VButton } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import PermissionRegistryChangesTask from 'components/PermissionRegistryChangesTask/PermissionRegistryChangesTask.vue';

export default {
  name: 'IndexPermissionRegistryChangesTask',
  components: {
    PermissionRegistryChangesTask,
    ChevronLeft,
    VButton,
  },
  created() {
    this.load();
  },
  beforeMount() {
    document.title = this.params.title;
  },
  beforeUpdate() {
    document.title = this.params.title;
  },
  data() {
    return {
      params: {},
      draftName: 'permission_registry_changes_task',
    };
  },
  computed: {
    toTitle() {
      return this.params.title;
    },
    draftFullName() {
      return `${this.draftName}_${this.$route.params.id}`;
    },
  },
  methods: {
    load() {
      if (!localStorage.getItem(this.draftFullName)) {
        this.params = this.$route.params;
        // TODO replace save localStorage to get task data from workflow by id
        localStorage.setItem(this.draftFullName, JSON.stringify(this.$route.params));
        return;
      }

      const paramsFromStore = JSON.parse(localStorage.getItem(this.draftFullName));
      if (paramsFromStore) {
        this.params = paramsFromStore;
        return;
      }

      console.log(`error load ${this.draftFullName} task params`);
      this.$router.back();
    },
    back() {
      localStorage.removeItem(this.draftFullName);
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.content {
  flex-grow: 2;
  padding-bottom: 50px;
}

.navigationBar {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0E0F0F;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1006;
  margin: 8px 16px 0 0;
  padding-left: 16px;

  .navigationRoot, .navigationSeparator {
    color: #979CA2;
    cursor: pointer;
  }

  .navigationSeparator {
    font-size: 24px;
    line-height: 24px;
  }
}

.contentFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  height: 48px;
  display: flex;
  z-index: 1000;
}

.back {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.center {
  flex-grow: 2;
}
</style>
