<template>
  <div v-custom-click-outside="closeOption" :class="$style['container']" @click="openOption">
    {{ transformValue(value, { length: 13, slice: 12 }) }}
    <div v-if="optionVisible"
         :class="$style['container__option']"
         :style="`width: ${optionWidth};right: ${optionRight}${optionTop ? ';top: ' + optionTop : ''}`"
    >
      <p v-for="(option, idx) in options" :key="idx" @mousedown="setSelectedOption(option)">
        <span>{{ option.name }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VMiniSelect',
  props: {
    options: { type: Array, required: true },
    optionWidth: { type: String, default: '522px' },
    optionRight: { type: String, default: '0' },
    optionTop: { type: String, required: false },
    value: { type: String, default: '' },
    id: { type: Number },
  },
  data() {
    return {
      optionVisible: false,
    };
  },
  methods: {
    openOption() {
      if (this.optionVisible) {
        this.closeOption();
        return;
      }
      this.optionVisible = true;
    },
    closeOption() {
      this.optionVisible = false;
    },
    setSelectedOption(option) {
      this.$emit('select', { option, id: this.id });
      this.closeOption();
    },
    transformValue(value, data) {
      return value.length > data.length
        ? `${value.slice(0, data.slice)}...`
        : `${value}`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  max-width: 120px;
  min-width: 120px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4C4E51;
  padding: 4px 16px 4px 6px;
  border: 1px solid #2F82DF;
  border-radius: 2px;
  cursor: pointer;

  &__option {
    position: absolute;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    margin-top: 7px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 10;

    p {
      color: #4C4E51;
      padding: 4px 16px 0 16px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      &:last-child {
        padding-bottom: 4px;
      }

      &:hover {
        background-color: #2F82DF;
        color: #FFFFFF;

        &:first-child {
          border-radius: 9px 9px 0 0;
        }

        &:last-child {
          border-radius: 0 0 9px 9px;
        }
      }
    }
  }

  &:after {
    content: url('~icons/basic/arrow_down_gray12x8.svg');
    position: absolute;
    right: 4px;
    top: 5px;
    pointer-events: none;
    box-sizing: border-box;
  }
}
</style>
