import localforage from 'localforage';

export async function getDraft(draftName) {
  return await localforage.getItem(draftName);
}

export async function setDraft(accountId, data, draftName) {
  await localforage.setItem(accountId + draftName, data);
}

export async function removeDraft(draftName) {
  await localforage.removeItem(draftName);
}
