import { encodeXmlCharacters } from 'utils/xml/tools';

export function addProhibitedPassword(value) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<ProhibitedPasswordChangingLists\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/prohibited-password/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/prohibited-password/1.0 ProhibitedPassword.xsd">\n' +
    '   <AddProhibitedPasswordChangeList>\n' +
          '<AddPassword>\n' +
            `<Value>${encodeXmlCharacters(value)}</Value>\n` +
          '</AddPassword>\n' +
        '</AddProhibitedPasswordChangeList>\n' +
    '</ProhibitedPasswordChangingLists>'
  );
}

export function deleteProhibitedPassword(passwordId) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<ProhibitedPasswordChangingLists\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/prohibited-password/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/prohibited-password/1.0 ProhibitedPassword.xsd">\n' +
    '   <DeleteProhibitedPasswordChangeList>\n' +
          '<DeletePassword>\n' +
            `<PasswordId>${passwordId}</PasswordId>\n` +
          '</DeletePassword>\n' +
        '</DeleteProhibitedPasswordChangeList>\n' +
    '</ProhibitedPasswordChangingLists>'
  );
}

export function updateProhibitedPassword(passwordId, value) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<ProhibitedPasswordChangingLists\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/prohibited-password/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/prohibited-password/1.0 ProhibitedPassword.xsd">\n' +
    '   <UpdateProhibitedPasswordChangeList>\n' +
          '<UpdatePassword>\n' +
            `<PasswordId>${passwordId}</PasswordId>\n` +
            `<Value>${encodeXmlCharacters(value)}</Value>\n` +
        '</UpdatePassword>\n' +
      '</UpdateProhibitedPasswordChangeList>\n' +
    '</ProhibitedPasswordChangingLists>'
  );
}
