<template>
  <div :class="$style.container">
    <div :class="$style.stepContainer">
      <div :class="$style.titleContainer">
        <h2 :class="$style.title">
          <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
          <span>Ценовая категория, условия почасового планирования потребления ЭЭ</span>
        </h2>
      </div>

      <div :class="{[$style.grid2Column]: true}">
        <div
          v-if="connectionApplicationType !== 'LEGAL_ENTITIES_UP_TO_150' && connectionApplicationType !== 'INDIVIDUALS_UP_TO_15' "
          :class="$style.disclaimerGroup"
        >
          <p :class="$style.textHelper">
            <span>Количество точек присоединения *</span>
            <tooltip-helper offset="6px" placement="top">
              <div :class="$style.tooltip" style="width: 320px;">
                <p>Количество кабельных вводов на участок или объект, например, в дом.</p>
              </div>
            </tooltip-helper>
          </p>
          <c-select
            v-model="connectionCharacteristics.energyConsumerCount"
            :error="errors.energyConsumerCount"
            :options="energyConsumerCountList"
            role="energyConsumerCount"
          />
        </div>
      </div>


      <div :class="$style.grid2Column">
        <div>
          <p :class="$style.textHelper">
            <span>Ценовая категория *</span>
            <tooltip-helper offset="6px" placement="top">
              <div :class="$style.tooltip" style="width: 320px;">
                <ul>
                  <li>{{ tariffCategoryDesc[1] }}</li>
                  <li>{{ tariffCategoryDesc[2] }}</li>
                  <li>{{ tariffCategoryDesc[3] }}</li>
                  <li>{{ tariffCategoryDesc[4] }}</li>
                  <li>{{ tariffCategoryDesc[5] }}</li>
                  <li>{{ tariffCategoryDesc[6] }}</li>
                </ul>
              </div>
            </tooltip-helper>
          </p>
          <c-select
            v-model="connectionCharacteristics.tariffCategoryType"
            :error="errors.tariffCategoryType"
            :options="tariffCategoryTypeShortList"
            role="tariffCategoryType"
          />
        </div>
        <div :class="$style.noteContainer">
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '1 ценовая категория'">
            {{ tariffCategoryDesc[1] }}</p>
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '2 ценовая категория'">
            {{ tariffCategoryDesc[2] }}</p>
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '3 ценовая категория'">
            {{ tariffCategoryDesc[3] }}</p>
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '4 ценовая категория'">
            {{ tariffCategoryDesc[4] }}</p>
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '5 ценовая категория'">
            {{ tariffCategoryDesc[5] }}</p>
          <p v-if="connectionCharacteristics.tariffCategoryType?.label === '6 ценовая категория'">
            {{ tariffCategoryDesc[6] }}</p>
        </div>
      </div>

      <div v-if="connectionCharacteristics.tariffCategoryType?.value === 'TARIFF_CATEGORY_2'" :class="$style.grid2Column">
        <div>
          <p :class="$style.textHelper">
            <span>Тарифная зона *</span>
          </p>
          <c-select
            v-model="connectionCharacteristics.tariffZoneType"
            :error="errors.tariffZoneType"
            :options="tariffZoneTypeList"
            role="tariffZoneType"
          />
        </div>
      </div>

      <div v-if="connectionCharacteristics.typeAccounting.label" :class="$style.grid2Column">
        <div>
          <p :class="$style.textHelper">
            <span>Тип учёта *</span>
          </p>
          <c-input
            v-model="connectionCharacteristics.typeAccounting.label"
            :error="errors.typeAccounting"
            role="typeAccounting"
            :native-disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StepCounter from '../components/StepCounter';

const INITIAL_DATA = {
  currentPreset: '',
  presets: [
    {
      type: 'home',
      title: 'Большой дом',
      description: [
        '1 точка присоединения',
        'Мощность до 15 кВт',
        '3-фазное подключение',
        'III категория надёжности',
      ],
      value: 'home',
      checked: false,
    },
    {
      type: 'village',
      title: 'Дача',
      description: [
        '1 точка присоединения',
        'Мощность до 9 кВт',
        'Однофазное подключение',
        'III категория надёжности',
      ],
      value: 'village',
      checked: false,
    },
    {
      type: 'custom',
      title: 'Свой вариант',
      description: [
        'Настройте свои параметры подключения',
      ],
      value: 'custom',
      checked: false,
    },
  ],
};

export default {
  name: 'ConnectionCharacteristics',
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    CSelect: () => import('atoms/common/CSelect'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    StepCounter,
  },
  props: {
    connectionApplicationType: {
      type: String,
      required: true,
    },
    connectionCharacteristics: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    isLegal: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    if (!this.connectionCharacteristics.tariffZoneType?.value) {
      this.connectionCharacteristics.tariffZoneType = this.tariffZoneTypeList[0];
    }

    this.connectionCharacteristics.readyConnectionDate[0].reliabilityClass = {
       label: 'III категория', value: 'III',
    };
  },
  data() {
    return {
      tariffCategoryDesc: {
        1: 'Ценовая категория 1 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), учёт которых осуществляется в целом за расчётный период',
        2: 'Ценовая категория 2 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), учёт которых осуществляется по зонам суток расчётного периода',
        3: 'Ценовая категория 3 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), в отношении которых осуществляется почасовой учёт, но не осуществляется почасовое планирование, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в одноставочном выражении',
        4: 'Ценовая категория 4 - Тариф двухставочный - для объёмов покупки электрической энергии (мощности), в отношении которых осуществляется почасовой учёт, но не осуществляется почасовое планирование, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в двухставочном выражении',
        5: 'Ценовая категория 5 - Тариф одноставочный - для объёмов покупки электрической энергии (мощности), в отношении которых за расчётный период осуществляются почасовое планирование и учёт, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в одноставочном выражении',
        6: 'Ценовая категория 6 - Тариф двухставочный - для объёмов покупки электрической энергии (мощности), в отношении которых за расчётный период осуществляются почасовое планирование и учёт, а стоимость услуг по передаче электрической энергии определяется по тарифу на услуги по передаче электрической энергии в двухставочном выражении.',
      },
    };
  },
  computed: {
    sortedSuppliersOptions() {
      const options = [...this.supplierOfLastResortList];

      options.sort((a, b) => {
        if (a.label === 'АО "Мосэнергосбыт"') {
          return -1;
        } else if (b.label === 'АО "Мосэнергосбыт"') {
          return 1;
        }
          return 0;
      });

      return options;
    },
    ...mapGetters('dataLists', [
      'energyConsumerCountList',
      'phaseVoltageList',
      'reliabilityClassShortList',
      'supplierOfLastResortList',
      'workloadList',
      'tariffCategoryTypeShortList',
      'tariffZoneTypeList',
      'typeAccountingLabelList',
    ]),
  },
  watch: {
    connectionCharacteristics: {
      deep: true,
      immediate: true,
      handler: 'updateItems',
    },
    'connectionCharacteristics.tariffCategoryType'() {
      switch (this.connectionCharacteristics.tariffCategoryType.value) {
        case 'TARIFF_CATEGORY_1': {
          this.connectionCharacteristics.typeAccounting = { label: 'Месячный', value: 'MONTHLY' };
          break;
        }
        case 'TARIFF_CATEGORY_2': {
          this.connectionCharacteristics.typeAccounting = { label: 'Зонный', value: 'ZONE' };
          break;
        }
        default: {
          this.connectionCharacteristics.typeAccounting = { label: 'Почасовой', value: 'HOURLY' };
        }
      }
    },
  },
  methods: {
    updateItems(val) {
      if (!val.presets) {
        this.connectionCharacteristics.presets = [...INITIAL_DATA.presets];
      }
      if (!val.currentPreset) {
        this.connectionCharacteristics.currentPreset = '';
      }
    },
    maxPowerConnectionValidate(val) {
      switch (this.connectionApplicationType) {
        case 'LEGAL_ENTITIES_OVER_15':
          return val > 15 && val <= 100;
        case  'LEGAL_ENTITIES_WITHIN_100_670':
          return val > 100 && val < 670;
        case 'LEGAL_ENTITIES_NOT_LESS_670':
          return val >= 670;
        default:
          return val > 0 && val <= 15;
      }
    },

    addReadyDate() {
      this.connectionCharacteristics.readyConnectionDate.push(
        {
          stage: '',
          designDate: '',
          readyDate: '',
          maxPower: '',
          reliabilityClass: { label: 'III категория', value: 'III' },
          designDateError: false,
          readyDateError: false,
        },
      );
    },
    removeReadyDate(index) {
      this.connectionCharacteristics.readyConnectionDate.splice(index, 1);
    },
  },
};
</script>

<style lang="sass" module>
.container
  max-width: 100%

  .stepContainer
    +questionnaries-container
    +step-spacing

    hr
      margin: 16px 0 16px 0

  .titleContainer
    +base-spacing

  .title
    display: flex

  .descriptions_current
    @media all and (-ms-high-Contrast: none), (-ms-high-Contrast: active)
      margin-top: 16px
    display: grid !important
    display: -ms-grid !important

    &:nth-child(4)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(5)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

    &:nth-child(6)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 5

  .descriptions_home, .descriptions_village, .descriptions_custom, .descriptions_hide
    display: none
    grid-column-start: 1
    grid-column-end: 4

  .grid3Column
    +grid-3-column

  .grid2Column
    +grid-2-column

    &:nth-child(2)
      -ms-grid-row: 3
      -ms-grid-column: 1
      -ms-grid-column-span: 3

  .gridFluid
    +grid-fluid
    -ms-grid-column: 1
    -ms-grid-column-span: 3

  .blockContent
    +base-spacing

  .smallText
    +small-text

    p
      +small-text

  .textDescription
    h4
      +base-text
      margin-bottom: 8px
      color: #141412

    p
      +base-text
      font-size: 12px
      line-height: 18px
      color: #141412

    a
      +base-link

  .inputNote
    +input-note-text
    margin-top: 8px

  .textHelper
    +base-text
    color: #141412
    display: flex

    span
      margin-right: 10px

  .tooltip
    h3
      margin-bottom: 8px
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 18px
      line-height: 22px
      color: #000

    ul
      margin-left: 15px
      list-style: disc

    li,
    p
      font-family: Roboto
      font-weight: 400
      font-size: 12px
      line-height: 18px
      color: #000

    li
      margin-bottom: 8px

    a
      +base-link

  .invalid
    border: 1px solid red

  .noteContainer
    p
      +small-text

    a
      +base-link

  .deleteBtn
    width: 18px
    height: 18px
    margin-top: 43px
    max-width: 18px
    max-height: 18px
    cursor: pointer

  .addButtons
    display: flex
    justify-content: center
    margin-top: 16px

    button
      font-family: 'Roboto Condensed'
      font-weight: 400
      font-size: 21px
      line-height: 24px
      text-decoration: underline
      background: transparent
      color: #141412
      cursor: pointer

  .disclaimerGroup
    position: relative

    .disclaimer
      position: absolute
      top: 0
      width: 260px
      right: -304px

  @media (max-width: 1480px)
    .disclaimerGroup
      .disclaimer
        position: relative
        width: 100%
        right: 0
</style>
