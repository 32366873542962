<template>
  <v-dialog
    v-model="internalValue"
    class="v-dialog-top"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="v-application">
      <v-toolbar color="primary" dark>
        <v-btn dark icon @click="onTapCloseDialogReport">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card v-if="consignment" class="v-application">
        <v-row class="ml-2">
          <v-col>
            <v-row v-if="consignment.axpName">
              <v-col>
                <p class="mb-0">
                  АХП Тип:
                </p>
              </v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.axpName }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.contractorName">
              <v-col><p class="mb-0">
                Подрядчик:
              </p>
              </v-col>
              <v-col>
                <p class="mb-0">
                  {{
                    consignment.contractorName
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.contractorEmployeeName">
              <v-col><p class="mb-0">
                Сотрудник подрядчика:
              </p>
              </v-col>
              <v-col>
                <p class="mb-0">
                  {{
                    consignment.contractorEmployeeName
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.eventName">
              <v-col><p class="mb-0">Мероприятие:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.eventName }}
                </p>
              </v-col>
            </v-row>
            <v-row v-else-if="isAbleForEdition && editMode && !consignment.accountingBalancesId">
              <v-col><p class="mb-0">Мероприятие:</p></v-col>
              <v-col>
                <events-autocomplete
                  ref="event"
                  v-model="eventId"
                  :items.sync="events"
                  :search.sync="eventName"
                  title="Выберите мероприятие"
                />
              </v-col>
            </v-row>
            <v-row v-if="consignment.type">
              <v-col><p class="mb-0">Тип движения:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.type }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.warehouseName">
              <v-col><p class="mb-0">Склад:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.warehouseName }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.performer">
              <v-col><p class="mb-0">Сотрудник:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.performer }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.employee">
              <v-col><p class="mb-0">Сотрудник, внёсший информацию:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ employeeName }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="consignment.date">
              <v-col><p class="mb-0">Дата:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.date }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="isAbleForEdition">
              <v-col>
                <v-btn v-if="!editMode" @click="enableEditMode">Редактировать</v-btn>
                <v-row v-else>
                  <v-col>
                    <div style="display: flex; gap: 24px">
                      <v-btn @click="cancel">Отменить</v-btn>
                      <v-btn @click="save">Сохранить</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div style="display: flex; gap: 24px">
                  <v-btn @click="exportToExcel">
                    Скачать как .XLSX
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/download_action.svg"/>
                  </v-btn>
                  <v-btn @click="exportToPdf">
                    Скачать как .PDF
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/download_action.svg"/>
                  </v-btn>
                  <v-btn @click="printToPdf">
                    Распечатать
                    <img alt="Скачать как .XLS" src="~@/assets/icons/file/file_print.svg"/>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer/>
          <v-spacer/>
          <v-col>
            <v-row v-if="consignment.adreskaName">
              <v-col><p class="mb-0">Адрес установки:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.adreskaName }}
                </p>
              </v-col>
            </v-row>
            <v-row
              v-if="consignment.contract">
              <v-col><p class="mb-0">Договор:</p></v-col>
              <v-col>
                <p class="mb-0">
                  {{ consignment.contract }}
                </p>
              </v-col>
            </v-row>
            <v-row
              v-else-if="editMode && isAbleForEdition && (consignment.recalculationId || consignment.acceptanceToWarehouseId || consignment.accountingBalancesId)">
              <v-col><p class="mb-0">Договор:</p></v-col>
              <v-col>
                <autocomplete-find-id
                  v-if="consignment.contractorId" ref="contract" v-model="contractId"
                  :find-id="consignment.contractorId"
                  :items.sync="contracts"
                  :search.sync="contractName"
                  is-required-find-id
                  title="Выберите договор"
                  url-default-data="/contract-debt/contractor/{0}/top20"
                  url-search="/contract-debt/contractor/{0}/find?name={1}"></autocomplete-find-id>
                <simple-autocomplete
                  v-else ref="contract" v-model="contractId"
                  :items.sync="contracts"
                  :search.sync="contractName"
                  title="Выберите договор"
                  url="/contract-debt/find?name="
                  url-default-data="/contract-debt/top20"></simple-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer/>
          <v-spacer/>
        </v-row>
        <v-data-table
          :headers="headerForTableReport"
          :items="consignment.elements"
        >
          <template #item.photos="{ item }">
            <div class="d-flex align-center mt-6">
              <file-input
                ref="reportReferencePhotos"
                v-model="item.photos"
                only-view
              ></file-input>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
    <v-dialog
      v-model="loaderDialog"
      class="v-dialog-top" persistent width="250">
      <v-card class="v-application">
        <v-card-title class="text-h5 text-center green lighten-2">
          Загрузка данных
        </v-card-title>
        <div class="text-center mt-8">
          <div v-if="progressValue < 100">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-icon :size="70" :width="7" color="green darken-2">
              mdi-cloud-check-variant
            </v-icon>
          </div>

          <div class="mt-2 mb-8">
            {{ progressValue + "%" }}
          </div>
        </div>
        <v-card-actions class="flex-column">
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" rounded @click="onTapClose">
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>

import FileInput from 'components/Processes/components/FileInput.vue';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';
import axios from 'axios';
import { BASE_URL } from '@/constants/api';
import { mapActions, mapGetters } from 'vuex';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import { exportToExcel } from 'utils/excel';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { oswaldRegularString } from '@/assets/fonts/oswald/Oswaldregular';
import { filterForAccess } from 'lib/utils/permissions';
import { formatStrDateToRussianDate } from 'lib/utils/date';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  name: 'Consignment',
  components: {
    AutocompleteFindId,
    SimpleAutocomplete,
    FileInput,
    EventsAutocomplete,
  },
  props: {
    consignment: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    internalValue: false,
    editMode: false,
    eventId: null,
    events: [],
    eventName: '',
    loaderDialog: false,
    progressValue: 0,
    contractId: null,
    contracts: [],
    contractName: '',
  }),
  computed: {
    isDefectoscope() {
      return this.consignment.originalType === 'DEFECTOSCOPE';
    },
    consignmentMembers() {
      const reverseTypes = ['EXTRADITE_FROM_WAREHOUSE', 'DEFECTOSCOPE', 'TO_REPAIR'];
      return reverseTypes.includes(this.consignment.originalType) ? {
        from: 'АО "ОЭК"',
        to: `${this.consignment.contractorName}`,
      } : { from: `${this.consignment.contractorName}`, to: 'АО "ОЭК"' };
    },
    consignmentTitle() {
      const consignmentTypeTitle =  (this.consignment.recalculationId || this.isDefectoscope) ? 'Акт приёмки и проверки технического состояния' : 'Накладная';
      return `${consignmentTypeTitle} № ${this.consignment.uniqueNumber ?? ''}  ${formatStrDateToRussianDate(this.consignment.date)}`;
    },
    isAbleForEdition() {
      if (!this.isBoiler) return false;
      if (!filterForAccess('consignment-edit'), this.getAuthorization?.blockAccess) return false;
      return (((this.consignment.acceptanceToWarehouseId || this.consignment.extraditeConstructionId || this.consignment.recalculationId) && !this.consignment.eventName) ||
        ((this.consignment.acceptanceToWarehouseId || this.consignment.recalculationId || this.consignment.accountingBalancesId) && !this.consignment.contract)) && !this.plan;
    },
    headerForTableReport() {
      const planHeaders = !this.consignment.plan ? [{
        text: 'Фото',
        align: 'start',
        sortable: true,
        value: 'photos',
      }] : [
        {
          text: 'Количество по факту',
          align: 'start',
          sortable: true,
          value: 'factCount',
        },
      ];
      const defectsHeader = this.isDefectoscope && !this.consignment.plan ? [
        {
        text: 'Дефекты',
        align: 'start',
        sortable: true,
        value: 'defects',
        },
      ] : [];
      return [
        {
          text: 'Элемент',
          align: 'start',
          sortable: true,
          value: 'elementName',
        }, {
          text: 'Eдиница измерения',
          align: 'start',
          sortable: true,
          value: 'unitName',
        }, {
          text: 'Количество',
          align: 'start',
          sortable: true,
          value: 'count',
        }, ...planHeaders, ...defectsHeader];
    },
    consignmentAdreskaName() {
      return this.consignment.adreskaName;
    },
    employeeName() {
      if (this.consignment.employee?.name) {
        return `${this.consignment.employee.surname} ${this.consignment.employee.name} ${this.consignment.employee.patronymic ?? ''} `;
      }

      return this.consignment.employee;
    },
    ...mapGetters('user', ['getToken', 'isBoiler', 'getAuthorization']),
  },
  watch: {
    value() {
      this.internalValue = this.value;
      this.refresh();
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    cancel() {
      this.editMode = false;
    },
    save() {
      this.loaderDialog = true;
      this.progressValue = 0;

      let baseRequest = null;
      let assignUrl = null;

      if (this.consignment.acceptanceToWarehouseId) {
        baseRequest = {
          acceptanceToWarehouseId: this.consignment.acceptanceToWarehouseId,
          previousConsignmentId: this.consignment.previousConsignmentId,
        };
        assignUrl = 'acceptance-to-warehouse-assign';
      } else if (this.consignment.extraditeConstructionId) {
        baseRequest = {
          extraditeConstructionId: this.consignment.extraditeConstructionId,
          previousConsignmentId: this.consignment.previousConsignmentId,
        };
        assignUrl = 'extradite-construction-assign';
      } else if (this.consignment.recalculationId) {
        baseRequest = {
          recalculationId: this.consignment.recalculationId,
          previousConsignmentId: this.consignment.previousConsignmentId,
        };
        assignUrl = 'recalculation-assign';
      } else if (this.consignment.accountingBalancesId) {
        baseRequest = {
          accountingBalancesId: this.consignment.accountingBalancesId,
          previousConsignmentId: this.consignment.previousConsignmentId,
        };
        assignUrl = 'accounting-balances-assign';
      } else {
        this.loaderDialog = false;
        this.setNotification({ message: 'Невозможно заменить ID для данной накладной' });
        console.error('NOT FOUND ID');
        return;
      }
      if (this.contractId != null) {
        axios
          .post(`${BASE_URL}/${assignUrl}-contract`, {
            ...baseRequest,
            contractId: this.contractId,
          }, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
          .then(() => {
            this.progressValue = this.eventId != null ? this.progressValue + 50 : 100;
            this.contractId = null;
            this.consignment.contract = this.contractName;
            this.$emit('updated');
            if (this.consignment.accountingBalancesId) {
              this.onTapClose();
              this.onTapCloseDialogReport();
            }
          })
          .catch((error) => {
            console.error(error);
            this.setNotification({ message: error.response.data });
          });
      }
      if (this.eventId != null) {
        axios
          .post(`${BASE_URL}/${assignUrl}-event`, {
            ...baseRequest,
            eventId: this.eventId,
          }, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
          .then(() => {
            this.progressValue = this.contractId != null ? this.progressValue + 50 : 100;
            this.eventId = null;
            this.consignment.eventName = this.eventName;
            this.$emit('updated');
          })
          .catch((error) => {
            console.error(error);
            this.setNotification({ message: error.response.data });
          });
      }
      this.loaderDialog = false;
    },
    refresh() {
      this.editMode = false;
      this.eventId = null;
      this.events = [];
      this.eventName = '';
      this.contractId = null;
      this.contracts = [];
      this.contractName = '';
      this.loaderDialog = false;
      this.progressValue = 0;
    },
    onTapClose() {
      this.refresh();
      this.$emit('updated');
    },
    enableEditMode() {
      this.editMode = true;
    },
    onTapCloseDialogReport() {
      this.internalValue = false;
    },
    exportToExcel() {
      const infoData = [];
      if (this.consignment.contractorName) {
        infoData.push([`От кого: ${this.consignmentMembers.from}`, `Кому: ${this.consignmentMembers.to}`]);
      }
      if (this.consignment.warehouseName) {
        infoData.push(['Склад:', this.consignment.warehouseName]);
      }
      if (this.consignment.axpName) {
        infoData.push(['Наименование объекта:', this.consignment.axpName]);
      }
      const footerForPlan = [];
      const headerForPlan  = [];
      const bigEmptyString = '      ';  // TODO ужасный костыль, переписать. В идеале нужно указывать через {minWidth: n} для каждой ячейки
      if (this.consignment.plan) {
        for (let i = 0; i < 7; i++) {
          footerForPlan.push({ text: bigEmptyString });
          headerForPlan.push({ text: bigEmptyString, value: `data${i}` });
        }
      }
      const footer = [
        [
          {
            text: 'Сдал: ',
          },
          { text: '' },
          { text: 'Принял: ' },
        ],
        [
          {
            text: '(подпись, расшифровка подписи)',
          },
          { text: '' },
          { text: '(подпись, расшифровка подписи)' },
        ],
      ];
      exportToExcel(
        this.consignmentTitle,
        [
          {
            text: 'П/П',
            value: 'index',
          },
          ...this.headerForTableReport.filter((item) => item.value !== 'photos'),
          ...headerForPlan,
        ],
        'text',
        this.consignment.elements.map((item, index) => ({
            index: index + 1,
            ...item,
            defects: this.isDefectoscope ? item.defects?.join(', ') : null,
          })),
        'value',
        {
          alignment: 'center',
          maxWidth: 40,
          infoData: infoData,
          footer: footer,
          sheetName: 'Накладная',
        },
      );
    },
    generatePdf() {
      const orientation = this.consignment.plan ? 'l' : 'p';
      const doc = new jsPDF(orientation);
      doc.addFileToVFS('Oswaldregular.ttf', oswaldRegularString);
      doc.addFont('Oswaldregular.ttf', 'Oswaldregular', 'normal');
      doc.setFont('Oswaldregular');
      const header = (data) => {
        doc.setFontSize(12);
        doc.setTextColor(40);
        doc.text(this.consignmentTitle, doc.internal.pageSize.getWidth() / 2, data.settings.margin.top - 2, { align: 'center' });
      };
      const heads = [];
      if (this.consignment.contractorName) {
        heads.push([{ content: `От кого: ${this.consignmentMembers.from}`, styles: { halign: 'left' } }, `Кому: ${this.consignmentMembers.to}`]);
      }
      if (this.consignment.warehouseName) {
        heads.push([{ content: 'Склад:', styles: { halign: 'right' } }, this.consignment.warehouseName]);
      }
      if (this.consignment.axpName) {
        heads.push([{ content: 'Наименование объекта:', styles: { halign: 'right' } }, this.consignment.axpName]);
      }

      doc.autoTable({
        body: heads,
        theme: 'grid',
        styles: {
          font: 'Oswaldregular',
          fontStyle: 'normal',
          overflow: 'linebreak',
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto', minCellWidth: 100 },
        },
        tableWidth: 'auto',
        beforePageContent: header,
      });

      const headers = ['П/П'];

      this.headerForTableReport.filter((item) => item.value !== 'photos').forEach((item) => {
        headers.push(item.text);
      });

      const body = [];
      const date = { content: '', styles: { minCellWidth: 12, fillColor: null, cellPadding: 0.5, lineWidth: 0.1 } };
      const emptys = [];

      if (this.consignment.plan) {
        for (let i = 0; i < 6; i++) {
          headers.push(date);
          emptys.push('');
        }
        this.consignment.elements.forEach((item, index) => {
          body.push([index + 1, item.elementName, item.unitName, item.count, item.factCount, ...emptys]);
        });
      } else {
        this.consignment.elements.forEach((item, index) => {
          const defects = this.isDefectoscope ? item.defects?.join(', ') : null;
          body.push([index + 1, item.elementName, item.unitName, item.count, defects, ...emptys]);
        });
      }

      doc.autoTable({
        head: [headers],
        body: body,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          fillColor: '#f37a1f',
        },
        styles: {
          font: 'Oswaldregular',
          fontStyle: 'normal',
        },
      });

      const signatures = [];

      signatures.push([
        {
        content: 'Сдал: ',
        styles: { halign: 'left', valign: 'middle', cellPadding: { top: 5, bottom: 5, left: 1 } },
        },
        {
          content: '', styles: { minCellWidth: 30 },
        },
        {
          content: 'Принял: ',
          styles: { halign: 'left', valign: 'middle', cellPadding: { top: 5, bottom: 5, left: 1 } },
        },
      ]);
      signatures.push([
        {
          content: '(подпись, расшифровка подписи)',
          styles: { halign: 'center', valign: 'middle', fontSize: 8 },
        },
        {
          content: '', styles: { minCellWidth: 30 },
        },
        {
          content: '(подпись, расшифровка подписи)',
          styles: { halign: 'center', valign: 'middle', fontSize: 8 },
        },
      ]);

      doc.autoTable({
        body: signatures,
        theme: 'grid',
        styles: {
          font: 'Oswaldregular',
          fontStyle: 'normal',
        },
        didDrawCell: (data) => {
          doc.setDrawColor(0);
          doc.setLineWidth(0.3);
          const y = (data.cell.y + (data.cell.height / 2)) + 1;
          const x1 = data.cell.x + (data.cell.width / 4);
          const x2 = data.cell.x + (3 * data.cell.width / 4);
          if (data.row.index === 0 && data.column.index === 0) {
            doc.line(x1 - 5, y, x2, y);
          }
          if (data.row.index === 0 && data.column.index === 2) {
            doc.line(x1 - 2, y, x2, y);
          }
        },
      });
      return doc;
    },
    exportToPdf() {
      this.generatePdf().save(`${this.consignmentTitle}.pdf`);
    },
    printToPdf() {
      this.generatePdf().autoPrint({ variant: 'non-conform' }).output('pdfobjectnewwindow', { filename: 'Накладная.pdf' });
    },
    ...mapActions('user', ['setNotification']),
  },

};
</script>
