<template>
  <div>
    <odk-tag-modal v-model="tagsModalState" @updated="fetchAxpInExploitation"/>
    <v-app class="vuetify-styles" data-app>
      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
              <span class="text-h5 align-center">Ремонт на объекте</span>
            </v-card-title>
            <v-card-text>
              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"/>
              <div>
                <yandex-map
                  ref="customMap"
                  :collection-axp="axpItemsFiltered"
                  @tapPlacemark="onTapPlacemark"
                />
              </div>
              <v-data-table
                v-model="selectedAdreskaFact"
                :headers="headersTable"
                :items="axpItemsFiltered"
                :loading="isLoadingAxp"
                class="elevation-1 mb-4"
                item-key="id"
                return-object
                show-select
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.adreskaId)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>
                <template #item.statusLocalRepair="{ item }">
                  {{
                    item.statusLocalRepair == -1
                      ? "Новый"
                      : "После устранения дефекта"
                  }}
                </template>
              </v-data-table>

              <simple-autocomplete-by-function
                v-if="isBoiler"
                ref="contractor"
                v-model="contractorId"
                :fetch-data-function="contractorByName"
                :fetch-default-data-function="contractorByName"
                :items.sync="contractors"
                :search.sync="contractorName"
                is-required
                title="Подрядчик"
              ></simple-autocomplete-by-function>

              <simple-autocomplete-by-function
                ref="adreska"
                v-model="adreskaId"
                :fetch-data-function="adreskaByName"
                :fetch-default-data-function="adreskaByName"
                :items.sync="adreskas"
                :search.sync="adreskaName"
                is-required
                title="Программа оформления"
              ></simple-autocomplete-by-function>

              <v-data-table
                v-if="adreskaFactIds.length"
                v-model="selectedDefects"
                :headers="defectHeadersTable"
                :items="defectItems"
                :loading="isLoadingDefects"
                class="elevation-1 mb-4"
                item-key="id"
                show-select
              >
                <template #no-data>
                  <span>Дефекты отсутствуют</span>
                </template>
                <template #item.photos="{ item }">
                  <div class="d-flex align-center mt-6">
                    <file-input
                      ref="referencePhotos"
                      v-model="item.photos"
                      only-view
                    ></file-input>
                  </div>
                </template>
              </v-data-table>

              <v-row class="mt-7">
                <v-col>
                  <div>
                    <v-label>Причины неисправности*</v-label>
                  </div>
                  <v-textarea
                    v-model="reason"
                    dense
                    name="input-7-4"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row class="mt-7">
                <v-col>
                  <div>
                    <v-label>Выполненные работы*</v-label>
                  </div>
                  <v-textarea
                    v-model="works"
                    dense
                    name="input-7-4"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row class="mt-7">
                <v-col>
                  <div>
                    <v-label>Комментарий</v-label>
                  </div>
                  <v-textarea
                    v-model="commentDefect"
                    dense
                    name="input-7-4"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <file-input
                ref="photos"
                v-model="photos"
                class="mt-2"
                is-required
                title="Фото"
              ></file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabledSaveDialog"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSend"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog v-model="loaderDialog"
                  class="v-dialog-top" persistent width="250">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + "%" }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import FileInput from 'components/Processes/components/FileInput.vue';
// import { D4_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import TextField from 'components/Processes/components/TextField.vue';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { distinctBy } from 'utils/arrays/arrays';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { convertBooleanToText } from 'lib/utils/formatXmlType';

export default {
  components: {
    OdkTagModal,
    SimpleAutocompleteByFunction,
    TextField,
    YandexMap,
    FileInput,
  },

  name: 'Form1',

  created() {
    if (!this.isBoiler) {
      this.contractorId = this.getAccount.supplier.id;
    }
    this.fetchAxpInExploitation();
  },

  data() {
    return {
      selectedDefects: [],
      selectedAdreskaFact: [],
      defectHeadersTable: [
        {
          text: 'Дефект',
          align: 'start',
          value: 'defectName',
        },
        {
          text: 'Комментарий к дефекту',
          align: 'start',
          value: 'defectDescription',
        },
        { text: 'Фото', value: 'photos', align: 'center' },
        { text: 'Критичность', value: 'criticalityName', align: 'center' },
        {
            text: 'Гарантийный',
            value: 'warranty',
            align: 'center',
          },
        { text: 'Дата обнаружения', value: 'date', align: 'center' },
      ],

      newDefects: [],

      contractors: [],
      adreskas: [],
      contractorName: null,
      adreskaName: null,
      adreskaId: null,
      contractorId: null,

      commentDefect: null,
      reason: null,
      works: null,
      photos: [],

      axpItems: [],
      isLoadingAxp: false,

      progressValue: 0,
      loaderDialog: false,

      defectItems: [],
      isLoadingDefects: false,

      tagsFilter: null,
      tagsModalState: null,
    };
  },
  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        {
          text: 'Наименование АХП',
          align: 'start',
          value: 'axpName',
        },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    selectedAdreska() {
      return this.distinctAdreskasFilteredByContractor.find((it) => it.id === this.adreskaId);
    },
    isDisabledSaveDialog() {
      const isDisabledInspection =
        this.selectedDefects.length == 0;
      const isDisabledReason = !this.reason?.length;
      const isDisabledWorks = !this.works?.length;
      const isDefectPhotos = this.photos.length == 0;

      return isDisabledInspection || isDefectPhotos || isDisabledReason || isDisabledWorks;
    },
    adreskaFactIds() {
      return this.selectedAdreskaFact.map((it) => it.id);
    },
    axpItemsFiltered() {
      let filtered = this.axpItems;
      if (this.adreskaId) filtered = filtered.filter((it) => it.adreskaId === this.adreskaId);
      if (this.contractorId) filtered = filtered.filter((it) => it.contractorId === this.contractorId);
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      return filtered;
    },
    distinctContractors() {
      return distinctBy(this.axpItems.map((it) => ({
        id: it.contractorId,
        name: it.contractorName,
      })), (it) => it);
    },
    distinctAdreskasFilteredByContractor() {
      const distinctAdreskas = distinctBy(this.axpItems, (it) => it.adreskaId).map((it) => ({
        id: it.adreskaId,
        name: it.name,
        contractorId: it.contractorId,
        countPlan: it.countPlan,
      }));
      if (!this.contractorId) return distinctAdreskas;
      return distinctAdreskas.filter((it) => it.contractorId === this.contractorId);
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getToken', 'isBoiler', 'getAccount']),
  },

  watch: {
    async adreskaFactIds() {
      this.onItemSelected();
      await this.fetchDefects();
    },
    adreskaId(newVal, oldVal) {
      if (oldVal !== newVal && oldVal) {
        this.selectedAdreskaFact = [];
      }
      if (this.adreskaId != null) {
        this.contractorId = this.selectedAdreska.contractorId;
        this.$refs.adreska.resetValidation();
        this.$refs.contractor.resetValidation();
      }
    },
    selectedAdreskaFact(newVal, oldVal) {
      if (oldVal && oldVal.length > newVal.length) {
        const set = new Set(this.adreskaFactIds);
        this.selectedDefects = this.selectedDefects.filter((it) => set.has(it.adreskaFactId));
      }
    },
  },

  methods: {
    openTags(id) {
      this.tagsModalState = String(id);
    },
    findByName(list, name) {
      if (!list) return [];
      if (!name) return [...list];
      return list.filter((it) => it.name.toLowerCase().includes(name.toLowerCase()));
    },
    contractorByName(name) {
      return this.findByName(this.distinctContractors, name);
    },
    adreskaByName(name) {
      return this.findByName(this.distinctAdreskasFilteredByContractor, name);
    },
    onTapPlacemark(id) {
      const item = this.axpItemsFiltered.find((it) => it.id === id);
      if (this.selectedAdreskaFact.some((it) => it.id === id)) {
        this.selectedAdreskaFact = this.selectedAdreskaFact.filter((it) => it.id !== id);
        item.balloonContent.buttonLabel = 'Выбрать';
      } else {
        item.balloonContent.buttonLabel = 'Отменить';
        this.selectedAdreskaFact.push(item);
      }
      this.axpItems = [...this.axpItems];
    },

    onItemSelected() {
      if (!this.adreskaId) {
        const id = this.adreskaFactIds[0];
        this.adreskaId = this.axpItemsFiltered.find((it) => it.id === id).adreskaId;
      }
      const set = new Set(this.adreskaFactIds);
      this.axpItems = this.axpItems.map((it) => {
        const selected = set.has(it.id);
        return {
          ...it,
          isSelected: selected,
          balloonContent: {
            ...it.balloonContent,
            buttonLabel: selected ? 'Отменить' : 'Выбрать',
          },
        };
      });
    },

    async fetchAxpInExploitation() {
      this.isLoadingAxp = true;
      const response = await fetch(`${BASE_URL}/axp/defective`);
      const data = await response.json();
      this.axpItems = data.map((it) => ({
        id: it.id,
        contractorId: it.adreska.contractorId,
        contractorName: it.adreska.contractorName,
        odkName: it.axp.odk.name,
        axpName: it.axp.name,
        adreskaId: it.adreska.id,
        name: it.adreska.name,
        locationLatitude: it.locationLatitude,
        locationLongitude: it.locationLongitude,
        statusInspection: it.statusInspection,
        tags: it.adreska.tags.map((it) => it.name).join(', '),
        isSelected: false,
        balloonContent: {
          title: it.axp.odk.name,
          buttonLabel: 'Выбрать',
          props: [
            {
              label: 'ID',
              value: it.id,
            },
            {
              label: 'АХП',
              value: it.axp.name,
            },
            {
              label: 'Тип декора',
              value: it.decorType?.name,
            },
            {
              label: 'Подрядчик',
              value: it.adreska.contractorName,
            },
            {
              label: 'Дата монтажа',
              value: formatDateFromOdkTimestamp(it.createDate),
            },
          ],
        },
      }));
      this.isLoadingAxp = false;
    },

    async fetchDefects() {
      this.isLoadingDefects = true;
      const response = await fetch(`${BASE_URL}/adreska-fact/byIds/defects/onRepair?ids=${this.adreskaFactIds.join(',')}`);
      const data = await response.json();
      this.defectItems = data.map((it) => ({
        id: it.id,
        defectName: it.defect.name,
        defectDescription: it.defectDescription,
        criticalityName: it.criticality.name,
        date: formatDateFromOdkTimestamp(it.createDate),
        photos: this.makePhotos(it.photos),
        isSelected: false,
        adreskaFactId: it.adreskaFact.id,
        warranty: convertBooleanToText(it.warranty),
      }));
      this.isLoadingDefects = false;
    },
    makePhotos(photos) {
      const newPhotos = [];
      photos.forEach((it) => {
        newPhotos.push({
          fileId: it.id,
          file: null,
          name: it.name,
          previewUrl: `${BASE_URL}/api/get/${it.folder}/${it.name}`,
          new: false,
        });
      });
      return newPhotos;
    },

    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        adreskaId: this.adreskaId,
        adreskaFactIds: this.adreskaFactIds,
        defectComment: this.commentDefect,
        reason: this.reason,
        works: this.works,
        photos: mainPhotos,
        defects: this.selectedDefects.map((it) => it.id),
      };

      axios
        .post(`${BASE_URL}/local-repair`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      window.location.reload();
    },
    ...mapActions('user', ['setNotification']),
  }
  ,
}
;
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>
