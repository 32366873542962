<template>
  <div class="tasks">
    <v-circle-loader v-if="tasksCount === 0 && loading"/>
    <div v-else-if="tasksCount !== 0 || loading">
      <component
        :is="getComponentDetails"
        v-if="getIsDisplayDetails"
        :is-shown-task-details="getIsDisplayDetails"
        :task="getTaskDetails"
        @close="closeDetails"
      />
      <!-- TODO: bad condintion rendering:
        (v-if="getIsDisplayDetails" <page v-else>) -->
      <page v-else>
        <filter-block
          :count-completed="tasksCount === 0 ? 0 : tasksCount - getTasksCounter"
          :count-in-work="getTasksCounter"
          :is-individual-consumer="getUserType === 'Customer'"
          @filter="changeFilter"
          @groupList="changeGroupList"
          @isOnlyOnChange="updateOnlyOnChange"
        />
        <v-table-new
          v-for="(group, index) in groupedTasks" :key="index"
          v-model="group.tasks"
          :excel-report-file-name="group.title"
          :loading="loading"
          :table-name="'tasks_group_' + group.id"
          :template="template"
          :title="group.title"
          class="tasks__spoiler"
          @on-cell-click="cellClickHandler"
        />
      </page>
    </div>

    <div v-else class="tasks__without-tasks">
      <div v-if="isOnboardingForSuppliers" class="tasks__without-tasks-inner">
        <p>Здесь пока ничего нет. Чтобы приступить к работе,</p>
        <p>
          занесите
          <span
            @click="linkToAgreements('/cabinet/agreements/upload', { action: 'upload', type: 'contract', contractType: 'ELECTRICITY_TRANSMISSION_CONTRACT' })"
          >
            действующий договор
          </span>
          на передачу электроэнергии
        </p>
      </div>
      <div v-if="!getUserType || getUserType === 'Customer'" class="tasks__without-tasks-inner">
        <p>
          Оформите
          <span @click="linkToConnectionApplication">заявку на технологическое присоединение</span>
          <br/>или
          <span @click="showContractModal">добавьте договор энергоснабжения</span>
          <connection-user-by-contract-modal
            v-if="isContractModalShow"
            @close="hideContractModal"
          />
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import HeaderCabinet from 'organisms/HeaderCabinet';

import ConnectionApplicationCard from 'views/private/ConnectionApplication/Task';
import TariffsChangingCard from 'organisms/tasks/tariffs-changing/Card';

import UtmInstallationDetails from 'organisms/tasks/utm-installation/Details';

import UploadDocumentDetails from 'views/private/UploadDocument/index.vue';

import TransmissionContractSigningDetails from 'organisms/tasks/approve-uploaded-document/Details';
import TransmissionContractReloadCard from 'organisms/tasks/reload-rejected-document/Card';

import ViewDownloadReport from 'organisms/tasks/checking-uploaded-document/steps/ViewDownloadReport';

import PersonalAccount from 'organisms/PersonalAccount';

import MeterReportDetails from 'organisms/tasks/reports/meter-report/Details';

import IntegralActApproveCard from 'views/private/IntegralActsPage/ApproveTask/Card';

import { ConnectionUserByContractModal, Page, VTableNew } from '@/components';
import { initConnectionApplicationTaskData } from 'views/private/ConnectionApplication/Task/initData';
import { initTariffsUpdateTaskData } from 'organisms/tasks/tariffs-update/initData';
import { initTariffsChangingTaskData } from 'organisms/tasks/tariffs-changing/initData';
import { initUploadDocumentTaskData } from 'views/private/UploadDocument/initData';

// TODO: fix this flow for navigation
import route from '@/router';

import VButton from 'components/VButton/VButton';
import VSpoilerLeft from '@/components/VSpoilerLeft/VSpoilerLeft';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import FilterBlock from './FilterBlock';
import { convertDbDateTimeFormat } from 'lib/utils/date';
import { partition } from 'lodash';


export default {
  name: 'CabinetTasks',
  components: {
    VSpoilerLeft,
    VButton,
    HeaderCabinet,
    PersonalAccount,
    ConnectionApplicationCard,
    TariffsChangingCard,
    TransmissionContractReloadCard,
    TransmissionContractSigningDetails,
    UploadDocumentDetails,
    ViewDownloadReport,
    UtmInstallationDetails,
    MeterReportDetails,
    IntegralActApproveCard,
    VTableNew,
    Page,
    FilterBlock,
    ConnectionUserByContractModal,
    VCircleLoader,
  },
  beforeRouteLeave(to, from, next) {
    next();
    if (from.params.id !== undefined) {
      this.CLEAR_DETAILS();
    }
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isContractModalShow: false,
      tasks: [],
      countAttempts: 0,
      isDisplayDetails: false,
      componentDetails: '',
      taskDetails: {},
      loading: false,
      observer: null,
      groupList: [],
      displayGroups: {},
      filterTitle: '',
      isOnlyOnChange: false,
    };
  },
  computed: {
    isOnboardingForSuppliers() {
      return this.getUserType === 'SUPPLIER_OF_LAST_RESORT' || this.getUserType === 'RETAILER' || this.getUserType === 'Retailer' ||
        this.getUserType === 'Supplier' || this.getUserType === 'Boiler';
    },
    isShownPersonalAccount() {
      return this.getUserType === 'Customer';
    },
    // TODO: понять точно нужно вычисляемон свойство на 300 строк
    // если да - поделить/переписать, если нет - удалить
    getNotEmptyTasks() {
      const tasks = this.getTasks(this.getUserType);
      const accountId = this.getAccount?.id;
      if (!tasks) {
        return [];
      }

      function getTaskData(task, getRowColor, user) {
        const taskTitle = task.props.title;
        if (task.props.workflowType === 'individualCustomerConnection' ||
          task.props.workflowType === 'individualCustomerConnectionRetail' ||
          task.props.workflowType === 'microgenerationConnectionUtility' ||
          task.props.workflowType === 'microgenerationConnectionRetail') {
          const initData = initConnectionApplicationTaskData(task.props, accountId);
          let title = initData.data.title;

          if (initData.isDisplayDate) {
            title += `. \n${task.props.date}`;
          }

          title += `\n по адресу: ${task.props.address}`;

          let tdStatusStyle;

          if (initData.data.isActive) {
            tdStatusStyle = '#2F82DF';
          } else {
            tdStatusStyle = '#ACB0B5';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: `${initData.status?.title ?? ''} ${initData.status?.subTitle ? initData.status.subTitle : ''}`,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'tariffsUpdateTask') {
          const initData = initTariffsUpdateTaskData(task.props, user.id);

          let tdStatusStyle;

          if (initData.data.isActive) {
            tdStatusStyle = '#2F82DF';
          } else {
            tdStatusStyle = '#ACB0B5';
          }

          return {
            title: `${initData.title.text} ${initData.title.subTitle}. ${initData.data.caption}. Выполнить до ${task.props.expiresAt}`,
            status: `${initData.status.title} ${initData.status.subTitle ? initData.status.subTitle : ''}`,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'tariffChangingApplication') {
          const initData = initTariffsChangingTaskData(task.props);

          let title = `${initData.title.text} ${initData.title.subTitle}.`;

          if (task.props.userType === 'Customer') {
            title += ` ${task.props.expiresAt} г.`;
            title += ` Объект: ${task.props.serviceLocationName}.`;
            title += ` Адрес: ${task.props.address}.`;
            title += ` Лицевой счёт: №${task.props.businessAccount}.`;
          } else {
            title += ` Лицевой счёт: №${task.props.businessAccount}.`;
            title += ` Выполнить до ${task.props.expiresAt} г.`;
          }

          let tdStatusStyle;

          if (initData.data.isActive) {
            tdStatusStyle = '#2F82DF';
          } else {
            tdStatusStyle = '#ACB0B5';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: `${initData.status.title} ${initData.status.subTitle ? initData.status.subTitle : ''}`,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'transmissionContract' || task.props.workflowType === 'transmissionContractThreeBoiler') {
          const initData = initUploadDocumentTaskData(task.props);

          let title = initData.title;

          if (task.props.createDate) {
            title += `. от ${task.props.createDate} г.`;
          }

          let tdStatusStyle;

          if (task.props.rejected) {
            tdStatusStyle = 'color: #EB5757';
          } else if (task.props.completed) {
            tdStatusStyle = '#ACB0B5';
          } else {
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: initData.status,
            componentDetails: task.componentDetails ? task.componentDetails : initData.componentDetails,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'userProfileDelete') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'companyDelete') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'connectionUserBySupplyContractApplication') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'updateUserDataApplication') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'documentSigning' || task.props.workflowType === 'documentAgreement') {
          let status;
          let tdStatusStyle;

          if (task.props.rejected) {
            status = 'ОТКЛОНЕНО';
            tdStatusStyle = '#EB5757';
          } else if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: taskTitle ? taskTitle : `${task.props.cardTitle} от ${task.props.actDate}`,
            status: status,
            componentDetails: task.componentDetails,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'utmInstallation') {
          let initData;
          let tdStatusStyle;

          if (task.props.stage === 'SUBMITTED') {
            initData = {
              isActive: true,
              status: 'НАЧАТЬ ВЫПОЛНЕНИЕ',
              title: 'Установка универсального транспортного модуля',
              componentDetails: 'UtmInstallationDetails',
            };
            tdStatusStyle = '#2F82DF';
          } else {
            initData = {
              isActive: false,
              status: 'ВЫПОЛНЕНО',
              title: 'Установка универсального транспортного модуля',
              componentDetails: 'UtmInstallationDetails',
            };
            tdStatusStyle = '#ACB0B5';
          }

          return {
            title: taskTitle ? taskTitle : initData.title,
            status: initData.status,
            componentDetails: initData.componentDetails,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'meterReport') {
          let title = 'Отчёт о снятии показаний ПУ в автоматическом режиме';

          if (task.props.createDate) {
            title += `. от ${task.props.createDate} г.`;
          }

          let tdStatusStyle;

          if (task.props.completed) {
            tdStatusStyle = '#ACB0B5';
          } else {
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: task.props.completed ? 'ВЫПОЛНЕНО' : 'ПОДПИСАТЬ',
            componentDetails: task.componentDetails,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'balanceSigning' || task.props.workflowType === 'balanceAgreement') {
          let title = task.props.cardTitle;

          if (task.props.actDate) {
            title += `. от ${task.props.actDate} г.`;
          }

          let status;
          let tdStatusStyle;

          if (task.props.rejected) {
            status = 'ОТКЛОНЁН';
            tdStatusStyle = '#EB5757';
          } else if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'borderDeviceSigning' || task.props.workflowType === 'borderDeviceAgreement' ||
          task.props.workflowType === 'borderFlowSigning' || task.props.workflowType === 'borderFlowAgreement') {

          let title = task.props.cardTitle;

          if (task.props.actDate) {
            title += `. от ${task.props.actDate} г.`;
          }

          let status;
          let tdStatusStyle;

          if (task.props.rejected) {
            status = 'ОТКЛОНЁН';
            tdStatusStyle = '#EB5757';
          } else if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: taskTitle ? taskTitle : title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'documentReload') {

          let title = task.props.title;

          if (task.props.actDate && task.props.actNumber) {
            title += ` №${task.props.actNumber} от ${task.props.actDate} г.`;
          }

          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'documentReload') {

          let title = task.props.title;

          if (task.props.actDate && task.props.actNumber) {
            title += ` №${task.props.actNumber} от ${task.props.actDate} г.`;
          }

          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'documentScanUpload') {

          const title = task.props.title;

          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'documentUpdateInfo') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;
          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }
          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'permissionRegistryChanges') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;
          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'connectionToPlatform') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;
          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'odk') {
          const title = task.props.title;
          let status;
          let tdStatusStyle;
          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        if (task.props.workflowType === 'contractAdditionUpload') {

          const title = task.props.title;

          let status;
          let tdStatusStyle;

          if (task.props.completed) {
            status = task.props.completedTitle;
            tdStatusStyle = '#ACB0B5';
          } else {
            status = task.props.actionTitle;
            tdStatusStyle = '#2F82DF';
          }

          return {
            title: title,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }
        if (task.props.workflowType === 'companyInvite') {

          let tdStatusStyle;
          let status;
          switch (task.props.stage) {
            case 'APPROVED':
              status = task.props.completedTitle;
              tdStatusStyle = '#ACB0B5';
              break;
            case 'REJECTED':
              status = 'Отклонен';
              tdStatusStyle = '#EB5757';
              break;
            case 'REVOKED':
              status = 'Отказано';
              tdStatusStyle = '#EB5757';
              break;
            case 'REVIEW':
              status = 'На рассмотрении';
              tdStatusStyle = '#ACB0B5';
              break;
            default:
              status = task.props.actionTitle;
              tdStatusStyle = '#2F82DF';
          }

          return {
            title: task.props.cardTitle,
            status: status,
            props: task.props,
            dataColor: tdStatusStyle,
            rowColor: getRowColor(task),
          };
        }

        const title = task.props.cardTitle ? task.props.cardTitle : task.props.workflowType;

        let status = task.props.completed;

        if (task.props.completed && task.props.completedTitle) {
          status = task.props.completedTitle;
        } else if (!task.props.completed && task.props.actionTitle) {
          status = task.props.actionTitle;
        }

        return {
          title: taskTitle ? taskTitle : title,
          status: status,
          componentDetails: task.componentDetails,
          props: task.props,
        };
      }

      const tasksList = tasks.map((task) => {
        const taskData = getTaskData(task, this.getRowColor, this.getUser);
        return {
          ...taskData,
          performer: `${this.userFio}, ${this.companyName}`,
          sender: task.props.sender,
          workflowType: task.props.workflowType,
          dataDocumentType: task.props.dataDocumentType,
          createdAt: convertDbDateTimeFormat(task.props.createdAt),
          updatedAt: convertDbDateTimeFormat(task.props.updatedAt),
        };
      });

      if (this.filterTitle.length === 0) {
        return tasksList;
      }
      return tasksList.filter((task) => task.title.toUpperCase().indexOf(this.filterTitle.toUpperCase()) !== -1);
    },
    template() {
      let titleLabel;

      if (this.isShownPersonalAccount) {
        titleLabel = 'Заявка';
      } else {
        titleLabel = 'Задача';
      }

      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
            },
          },
          {
            label: titleLabel,
            key: 'title',
            thStyle: {
              width: '600px',
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Статус',
            key: 'status',
            type: 'customStatus',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Дата постановки задачи',
            key: 'createdAt',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Дата обновления задачи',
            key: 'updatedAt',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Срок исполнения',
            key: 'deadline',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1000,
            },
          },
          {
            label: 'Исполнитель',
            key: 'performer',
            thStyle: {
              width: '200px',
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Отправитель',
            key: 'sender',
            thStyle: {
              width: '100px',
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    companyName() {
      return this.getCompanyName(this.getAccount?.profile?.name);
    },
    userFio() {
      return this.getFio(this.getUser.fullName);
    },
    groupedTasks() {
      if (this.getNotEmptyTasks.length === 0 || this.groupList.length === 0) {
        return [];
      }

      const result = [];

      const outOfGroups = this.groupList.reduce((acc, cur) => {
        const [filtered, notFiltered] = partition(acc, cur.filter);

        if ((this.displayGroups[cur.id] === true || this.displayGroups[cur.id] === undefined) && filtered.length > 0) {
          result.push({
            id: cur.id,
            title: cur.title,
            tasks: filtered,
          });
        }

        return notFiltered;
      }, this.getNotEmptyTasks);

      if (!this.isOnlyOnChange && outOfGroups && outOfGroups.length > 0) {
        result.push({
          id: 'other',
          title: 'Остальные',
          tasks: outOfGroups,
        });
      }

      return result;
    },
    tasksCount() {
      return this.getTasks(this.getUserType).length;
    },
    ...mapGetters('user', ['getUserType', 'getUser', 'getAccount']),
    ...mapGetters('cabinet', ['getTasks', 'getTasksCounter']),
    ...mapGetters('dataLists', [
      'energyConsumerCountList',
      'objectTypeList',
      'phaseVoltageList',
      'reliabilityClassList',
      'supplierOfLastResortList',
      'workloadList',
      'gridCompanyList',
      'confirmationDocumentTitleList',
    ]),
    ...mapGetters(
      'tasks',
      [
        'getIsDisplayDetails',
        'getComponentDetails',
        'getTaskDetails',
      ],
    ),
  },
  methods: {
    showContractModal() {
      this.isContractModalShow = true;
    },
    hideContractModal() {
      this.isContractModalShow = false;
    },
    linkToConnectionApplication() {
      route.push('/cabinet/connection-application');
    },
    async linkToAgreements(path, { action, type, contractType }) {
      await this.setContractMode({ action, type, contractType });
      this.$router.push(path);
    },
    async loadData() {
      this.loading = true;
      try {
        await this.setWorkflows({ userType: this.getUserType });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async cellClickHandler(cell) {
      return await this.displayDetails(cell.row);
    },
    async closeDetails() {
      await this.closeDetailsStore();
      await this.loadData();
    },
    getCompanyName(name) {
      if (!name) {
        return '';
      }

      if (name.length >= 48) {
        return `${name.substr(0, 48)}...`;
      }

      return name;
    },
    getFio(fullName) {
      if (fullName && fullName.name) {
        let patronymic = '';
        if (fullName.patronymic) {
          patronymic = `${fullName.patronymic[0]}. `;
        }
        return `${fullName.name[0]}. ${patronymic}${fullName.surname}`;
      }
      return undefined;
    },
    changeGroupList(groupList, displayGroups) {
      this.groupList = groupList;
      this.displayGroups = displayGroups;
    },
    changeFilter(selectedSide, title) {
      this.filterTitle = title;
    },
    updateOnlyOnChange(isOnlyOnChange) {
      this.isOnlyOnChange = isOnlyOnChange;
    },
    getRowColor(task) {
      if (task.props.completed) {
        return 'rgb(193, 196, 199)';
      }

      // Пока не реализованно
      if (task.props.isNew) {
        return 'rgb(113, 242, 167)';
      }

      return 'rgb(118, 170, 229)';
    },
    ...mapActions('document', ['loadDocument']),
    ...mapActions('cabinet', ['loadBalances', 'setWorkflows']),
    ...mapActions('document', ['filterDocumentViews']),
    ...mapActions('tasks', ['displayDetails', 'closeDetailsStore']),
    ...mapMutations('tasks', ['CLEAR_DETAILS']),
    ...mapActions('electricityTransmissionContract', ['setContractMode']),
  },
};
</script>

<style lang="scss" scoped>
.tasks {
  &__without-tasks {
    margin: 20% auto;

    p {
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #4C4E51;
      text-align: center;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }

  &__spoiler {
    margin-bottom: 24px;
  }
}
</style>
