<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">00.1 Учёт складских остатков</div>
          </v-card-title>
          <v-card-text>
            <warehouses-autocomplete
                ref="warehouse"
                v-model="warehouseId"
                :items.sync="warehouses"
                :search.sync="warehouseName"
                is-required
                title="Выберите склад"
            />

            <axp-autocomplete
              ref="axp"
              v-model="axpId"
              :items.sync="axpItems"
              :search.sync="axpName"
              is-required
              title="Выберите АХП"
            />
            <axp-photos-carousel v-if="axpId" :axp-id="axpId"/>
            <text-field
              ref="axpQuantity"
              v-model="countAxp"
              is-only-numbers
              is-required
              title="Введите количество комплектов АХП"
            ></text-field>

            <v-row class="pr-4 mb-2">
              <v-col/>
              <v-col/>
              <v-col>
                <v-row>
                  <v-spacer/>
                  <v-btn @click="accountTotal">Рассчитать</v-btn>
                  <v-spacer/>
                  <v-btn @click="clearTotal">Очистить форму</v-btn>
                </v-row>
              </v-col>
            </v-row>

            <div class="v-application">
              <v-data-table
                :headers="headersTable"
                :items="formElements"
                :loading="isLoadingElements"
                class="elevation-1 mb-4"
                style="width: 100%"
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.countElement="{ item }">
                  <div class="d-flex align-center mt-6">
                    <text-field
                      ref="countElement"
                      v-model="item.countElement"
                      is-only-numbers
                      is-required
                      simple
                    ></text-field>
                  </div>
                </template>
              </v-data-table>
            </div>
            <file-input
              ref="photos"
              v-model="photos"
              class="mt-2"
              is-required
              title="Выберите фото"
            ></file-input>

            <file-input
              ref="documents"
              v-model="documents"
              class="mt-2"
              is-required
              title="Выберите документы"
            ></file-input>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import TextField from '@/components/Processes/components/TextField.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import { getSurnameAndInitials } from 'utils/user';
import FileInput from 'components/Processes/components/FileInput.vue';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';

export default {
  components: {
    AxpPhotosCarousel,
    FileInput,
    Consignment,
    AxpAutocomplete,
    TextField,
    WarehousesAutocomplete,
  },

  created() {
  },

  data: () => ({
    progressValue: 0,

    warehouseId: null,
    warehouseName: '',
    warehouses: [],

    axpId: null,
    axpName: '',
    axpItems: [],
    countAxp: '',

    formElements: [],

    photos: [],
    documents: [],

    isLoadingElements: false,

    loaderDialog: false,

    dialogForViewReport: false,
    dataReportCustom: {},
  }),

  computed: {
    headersTable() {
      return [
        {
          text: 'Элемент',
          align: 'start',
          value: 'elementName',
        },
        {
          text: 'Количество в одном АХП',
          value: 'elementQuantity',
          align: 'center',
        },
        {
          text: `Количество на ${this.countAxp} АХП`,
          value: 'needCount',
          align: 'center',
        },
        {
          text: 'Количество на складе',
          value: 'countElement',
          align: 'center',
        },
      ];
    },
    isDisabledSaveDialog() {
      const isDisabledWarehouse = this.warehouseId == null;
      const isDisableAxp = this.axpId == null;
      const isDisableCountAxp = !this.countAxp?.length || isNaN(Number(this.countAxp));
      const isDisablePhoto = !this.photos?.length;
      const isDisableDocuments = !this.documents?.length;

      const isDisableCountElements = this.formElements.some((it) => it.countElement?.length === 0 || isNaN(Number(it.countElement)) || Number(it.countElement) < 0) ||
        this.formElements.every((it) => Number(it) === 0);


      return (
        isDisabledWarehouse ||
        isDisableAxp ||
        isDisableCountAxp ||
        isDisableCountElements ||
        isDisablePhoto ||
        isDisableDocuments
      );
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },

  watch: {
    axpId(newVal) {
      this.formElements = [];
      this.countAxp = '';
      if (newVal != null) {
        this.fetchElements(newVal);
      }
    },
    countAxp(newVal) {
      if (newVal != null && !!Number(newVal)) {
        this.formElements.forEach((el) => {
          el.needCount = newVal * el.elementQuantity;
        });
      } else {
        this.formElements.forEach((el) => {
          el.needCount = 0;
        });
      }
    },
  },

  methods: {
    ...mapActions('user', ['setNotification']),
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const mainDocuments = [];
      this.documents.forEach((doc) => {
        mainDocuments.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        warehouseId: this.warehouseId,
        axpId: this.axpId,
        countAxp: this.countAxp,
        elements: this.formElements.map((el) => ({
          id: el.elementId,
          count: el.countElement,
        })),
        photos: mainPhotos,
        documents: mainDocuments,
      };

      axios
        .post(`${BASE_URL}/accounting-balances`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {

      this.progressValue = 0;
      this.warehouseId = null;
      this.warehouseName = '';
      this.warehouses = [];
      this.axpId = null;
      this.axpName = '';
      this.axpItems = [];
      this.countAxp = '';
      this.formElements = [];
      this.photos = [];
      this.documents = [];
      this.loaderDialog = false;
      this.dialogForViewReport = false;
      this.dataReportCustom = {};

      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));

      this.isLoadingElements = false;
    },

    async fetchElements() {
      this.isLoadingElements = true;

      const response = await fetch(
        `${BASE_URL}/axp/${this.axpId}/elements`,
      );
      const data = await response.json();
      this.formElements = data.map((el) => ({
        elementId: el.id,
        elementName: el.name,
        unitName: el.unitName,
        elementQuantity: el.quantity,
        countElement: '',
      }));
      this.isLoadingElements = false;
      await this.fetchElementsDept();
    },

    onTapViewReport() {
      const currentDate = new Date();
      const formattedDate =
        `${currentDate.getDate()
        }/${
          currentDate.getMonth() + 1
        }/${
          currentDate.getFullYear()}`;
      const els = this.formElements.map((element) => ({
        axpName: this.axpName,
        date: formattedDate,
        type: 'Учёт складских остатков',
        warehouseName: this.warehouseName,
        photos: this.photos,
        elementName: element.elementName,
        unitName: element.unitName,
        count: element.countElement,
        employee: getSurnameAndInitials(this.getUser.fullName),
      }));

      this.dataReportCustom = els[0];
      this.dataReportCustom.elements = els;

      this.dialogForViewReport = true;
    },
    accountTotal() {
      this.formElements = this.formElements.map((el) => ({
        ...el,
        countElement:
          String(el.needCount),
      }));
    },
    clearTotal() {
      this.formElements = this.formElements.map((el) => ({
        ...el,
        countElement: '',
      }));
    },
    onTapCloseDialogReport() {
      this.dataReportCustom = {};
      this.dialogForViewReport = false;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

