import Vue from 'vue';
import VueRouter from 'vue-router';

import store from 'store';
import routes from './routes';
import HTTPError from 'lib/utils/errors';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/cabinet' },
    ...routes,
    { path: '*', redirect: '/' },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authenticated = !!store.state.user.token && (store.state.user.tokenStatus === 'FULL' || store.state.user.tokenStatus === 'SWAP');
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut);
  const isPublic = to.matched.some((record) => record.meta.public);

  document.title = to.meta.title;

  if (authenticated) {
    router.app.$store.dispatch('cabinet/setWorkflows', { userType: router.app.$store.getters['user/getUserType'] })
      .catch((e) => {
        if (HTTPError.network(e).code === HTTPError.code.PERMISSION_DENIED) {
          Vue.prototype.vueShowNotification('Сессия устарела или аккаунт был удален', '<p>Ваша сессия устарела, пожалуйста, войдите повторно.</p>');
          router.app.$store.dispatch('user/logOut');
          return;
        }
        console.log('error on load workflows', e);
      });
    next();
  }


  if (!isPublic && !authenticated) {
    if (!process.env.VUE_APP_IS_UTM) {
      return next({ path: '/login' });
    }
    return next({ path: '/check-auth' });

  }

  // если авторизован, но не отправил анкету показывать страницу consumer-type
  // после отправки анкеты пользватель подтверждает свой тип
  // TODO revert when server authorizations is fixed
  /* if (
    authenticated &&
    !authorizations &&
    to.path !== '/consumer-type' &&
    to.path.indexOf('/help-') === -1
  ) {
    return next({ path: '/consumer-type' });
  }*/

  if (authenticated && onlyLoggedOut && to.path.indexOf('/help-') === -1) {
    return next('/');
  }

  const blockAccess = store.state.user.authorization?.blockAccess;

  const path = to.path.replace('/cabinet/', '');
  if (
    blockAccess &&
    blockAccess[path] !== undefined &&
    blockAccess[path] === false
  ) {
    return next('/');
  }

  next();
});

export default router;
