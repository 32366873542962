<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="back">{{ $route.params.fromTitle }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ $route.params.title }}</span>
    </p>

    <div :class="$style.content">
      <p :class="$style.stepTitle">Детали задачи</p>
      <p :class="$style.stepDate">Дата создания документа: {{ documentCreatedAt }}</p>
      <v-file-attachment
        :document-id="$route.params.documentId"
        :label="$route.params.documentName"
      />
    </div>

    <div :class="$style.content">
      <submit-task-details
        v-if="!$route.params.completed"
        :class="{[$style.submit]: true}"
        :disabled="isWaitingSubmit"
        :title="'Сформировать дополнительное соглашение'"
        @submit="handleAdditionLoad"
      />
      <submit-task-details
        :class="{[$style.submit]: true}"
        :disabled="isWaitingSubmit||$route.params.completed"
        :title="completedTitle"
        @submit="closeTask"
      />
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
    </div>
    <div :class="$style.contentFooter">
      <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';
import generateXml from 'lib/storage/connection-application/data';
import VFileAttachment from 'components/VFileAttachment/VFileAttachment.vue';
import { VButton } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import dayjs from 'dayjs';

export default {
  name: 'ContractAdditionUpload',
  components: {
    ChevronLeft,
    VButton,
    VFileAttachment,
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
    };
  },
  computed: {
    completedTitle() {
      if (this.$route.params.completed) {
        return 'Выполнено';
      }
        return 'Не формировать';

    },
    documentCreatedAt() {
      return dayjs(this.$route.params.documentCreatedAt).format('DD.MM.YYYY');
    },
  },
  methods: {
    async closeTask() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      try {
        const documentReviewXml = generateXml.documentReviewXml(
          this.$route.params.documentId,
          'contract-addition-upload-close',
        );
        console.log(documentReviewXml);
        const result = await this.documentUpload({ xml: documentReviewXml });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.back();
        }
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async handleAdditionLoad() {
      this.isWaitingSubmit = true;

      await this.resetDraft();

      // TODO get contractType from task
      const contractType = 'ELECTRICITY_TRANSMISSION_CONTRACT';
      this.setContractMode({ action: 'create', type: 'additionalAgreement', contractType: contractType });

      await this.$router.push({
        name: 'cabinet.agreements.upload',
        params: {
          contractId: this.$route.params.domainDocumentId,
          additionalAgreementType: 'ADDITIONAL_REQUISITES',
          fromTask: true,
          contractKindByExisting: 'ADDITIONAL',
        },
      });

      this.isWaitingSubmit = false;
    },
    back() {
      this.$router.push(`/cabinet/${this.$route.params.from}`);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
    ...mapActions('electricityTransmissionContract', ['setContractMode', 'resetDraft']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  height: 90%

.content
  width: 560px
  margin: 32px auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

  .stepDate
    +table-text-small
    margin-bottom: 34px

    span
      +table-data-small
      margin-left: 116px

.submit
  margin-top: 32px

.errorText
  +small-text
  margin: 32px 4px -28px 4px
  color: red

.navigationBar
  width: 100%
  background-color: #FFFFFF
  z-index: 1006
  // more than modal z-index
  +title-small-uppercase
  margin: 8px 16px 0 0
  padding-left: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px

.contentFooter
  height: 48px
  display: flex

.back
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  padding: 19px

  svg
    fill: #FFFFFF

.center
  flex-grow: 2

.back
  background: #ffffff

  svg
    path
      fill: #2F82DF
</style>
