<template>
  <div :class="$style.page">
    <div v-if="title" :class="$style['page__title']">
      <div :class="$style['page__title__header']">
        <div :class="$style['page__title__header__text']">{{ title }}</div>
        <div>
          <slot name="titleButton" />
        </div>
      </div>
      <v-divider style="margin: 10px 0 0 0;" />
    </div>
    <div v-if="!title" :class="$style['page__title-content']">
      <div :class="$style['page__title-content-title']">
        <slot name="title" />
      </div>
      <div :class="$style['page__title-content-actions']">
        <slot name="actions"></slot>
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import VDivider from 'components/VDivider/VDivider';

export default {
  name: 'Page',
  components: { VDivider },
  props: {
    title: { type: String, default: '' },
  },
};
</script>

<style lang="scss" module>
.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 20px 16px 16px;

  &__title {
    position: relative;

    &__header {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      &__text {
        color: #C1C4C7;
        font-family: 'Roboto Condensed', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }

      @media (max-width: 860px) {
        flex-direction: column;
        gap: 8px;
        justify-items: end;
      }
    }
  }

  &__title-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #4c4e51;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 20px;

    &-title {
      margin-right: 8px;
    }

    &-actions {
      margin-top: 8px;
    }
  }
}
</style>
