const SET_ACT_DOCUMENT_DOMAIN_ID = (state, { actDocumentDomainId }) => {
  state.actDocumentDomainId = actDocumentDomainId;
};

const SET_TOTAL_COST = (state, { totalCost }) => {
  state.totalCost = totalCost;
};

const SET_PERFORMER_FIRST_BANK_REQUISITES = (state, { performerFirstBankRequisites }) => {
  state.performerFirstBankRequisites = performerFirstBankRequisites;
};

const SET_PERFORMER_SECOND_BANK_REQUISITES = (state, { performerSecondBankRequisites }) => {
  state.performerSecondBankRequisites = performerSecondBankRequisites;
};

const SET_PAYMENT_KIND = (state, { paymentKind }) => {
  state.paymentKind = paymentKind;
};

export default {
  SET_ACT_DOCUMENT_DOMAIN_ID,
  SET_TOTAL_COST,
  SET_PERFORMER_FIRST_BANK_REQUISITES,
  SET_PERFORMER_SECOND_BANK_REQUISITES,
  SET_PAYMENT_KIND,
};
