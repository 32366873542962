import router from '@/router/index';

import { apolloClient, apolloClientFiles } from '@/api/graphql';
import {
  ACCOUNT,
  ALL_ACCOUNTS,
  ALL_PERMISSIONS,
  ALL_USERS,
  COMPANY_ROLES,
  LOGGED_IN_USER,
  POSSIBLE_PERMISSIONS,
  SUBDIVISION_WITH_FILTERED_ROLES_BY_ID,
  SUBDIVISIONS_BY_FILTER,
  SUBORDINATED_SUBDIVISIONS,
  SUPPLIER_SUBDIVISIONS_BY_FILTER,
  USER_AUTHORIZATIONS,
  USER_BY_ID,
  USER_PERMISSIONS,
  USER_PROFILE_DELETE_APPLICATIONS,
  ACCOUNTS_BY_FILTER,
} from '@/api/graphql/queries';
import {
  CONFIRM_ACCOUNT,
  CONFIRM_USER,
  DELETE_USER_FROM_COMPANY,
  LOGIN_USER,
  REGISTRATION_USER,
  REQUEST_CAP_AUTH,
  REQUEST_CODE_WITH_CAP,
  RESTORE_PASSWORD,
  SEND_RESTORE_PASSWORD_CODE,
  SEND_SUPPORT,
  SEND_USERNAME_VERIFICATION_CODE,
  UPDATE_COMPANY_PROFILE_BY_FNS,
  UPDATE_PERMISSIONS,
  USER_SEND_AUTHENTICATION_CODE,
  USER_SWAP_ACCOUNT,
} from '@/api/graphql/mutations';
import { getUserData } from '@/api/rest/utm';
import { clearLocalStorage } from '@/utils/localStorage';

function clearUserData({ commit }) {
  clearLocalStorage();
  commit('LOGOUT_USER');
}

async function logOut({ commit }) {
  clearLocalStorage();
  commit('LOGOUT_USER');
  await router.push('/login');
}

async function loginUser({ commit }, payload) {
  try {
    clearLocalStorage();
    commit('LOGOUT_USER');

    const { data: { userAuthenticateWithPassword } } = await apolloClient.mutate({
      mutation: LOGIN_USER,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });

    saveUser(commit, userAuthenticateWithPassword);

    return userAuthenticateWithPassword;
  } catch (error) {
    console.log('loginUser::catch', error);
    throw error;
  }
}

async function userSendAuthenticationCode() {
  try {
    await apolloClient.mutate({
      mutation: USER_SEND_AUTHENTICATION_CODE,
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('loginUser::catch', error);
    throw error;
  }
}

async function requestCapAuth(store, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: REQUEST_CAP_AUTH,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.userGenerateSigString.ecpString;
  } catch (error) {
    console.log('requestCapAuth::catch', error);
    throw error;
  }
}

async function requestCapCode({ commit }, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: REQUEST_CODE_WITH_CAP,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });

    saveUser(commit, data.userAuthenticateWithEcp);

    return data.userAuthenticateWithEcp.user.email;
  } catch (error) {
    console.log('requestCapCode::catch', error);
    throw error;
  }
}

async function userSwapAccount({ commit }, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: USER_SWAP_ACCOUNT,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });

    saveUser(commit, data.userSwapAccount);
  } catch (error) {
    console.log('userSwapAccount::catch', error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function sendUsernameVerificationCode({ commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: SEND_USERNAME_VERIFICATION_CODE,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('sendUsernameVerificationCode::catch', error);
    throw error;
  }
}

async function userSwapAccountToDefault({ getters, commit }) {
  const accountId = getters.getUser.authorizations[0].account.id;
  await userSwapAccount({ commit }, { accountId });
}

function saveUser(commit, data) {
  const { code, message } = data;

  if (code && message) {
    commit('SET_LOGIN_ERROR', { code, message });
    return;
  }

  const token = data.token;
  const tokenStatus = data.tokenStatus;
  const tokenUsername = data.tokenUsername;
  const user = data.user;
  let account = null;

  if (!user.authorizations.some((item) => item.type === 'INDIVIDUAL')) {
    user.authorizations.push({});
  }

  if (data.user.authorizations && data.account) {
    // TODO first authorizations in call confirmAccount ?= first authorizations in user in userAuthenticateWithCode
    data.user.authorizations.some((item) => {
      if (item.account.id === data.account.id) {
        account = item;
        return true;
      }
      return false;
    });
  }

  commit('SET_TOKEN', { token: token, tokenStatus: tokenStatus, tokenUsername: tokenUsername });
  commit('SAVE_USER', user);
  commit('SAVE_ACCOUNT', account);

  commit('RESET_LOGIN_ERROR');
}

async function confirmUser({ commit }, payload) {
  try {
    const { data: { userAuthenticateWithCode } } = await apolloClient.mutate({
      mutation: CONFIRM_USER,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });

    saveUser(commit, userAuthenticateWithCode);

    return userAuthenticateWithCode;
  } catch (error) {
    console.log('confirmUser::catch', error);
    throw error;
  }
}

async function confirmUserWithUtm({ commit }) {
  console.log('confirm with utm');
  try {
    const data = await getUserData();
    const authData = data.data.data.userAuthenticateWithInnAndOgrn;
    saveUser(commit, authData);
  } catch (error) {
    console.log('confirmUserWithUtm::catch', error);
    throw error;
  }
}


async function confirmAccount({ commit }, payload) {
  try {
    console.log('confirmAccount payload', payload);
    const { data: { userAuthenticateWithAccount } } = await apolloClient.mutate({
      mutation: CONFIRM_ACCOUNT,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });

    saveUser(commit, userAuthenticateWithAccount);

  } catch (error) {
    console.log('confirmAccount::catch', error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function registerUserRequest({ commit }, payload) {
  try {
    const variables = {
      ...payload,
      xml: new Blob([payload.xml], { type: 'application/xml' }),
    };

    if (payload.signature?.length) {
      variables.signature = new Blob([payload.signature], { type: 'text/plain' });
    }

    return await apolloClientFiles.mutate({
      mutation: REGISTRATION_USER,
      fetchPolicy: 'no-cache',
      variables: variables,
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('registerUserRequest::catch', error);
    throw error;
  }
}

async function registerUser({ commit }, payload) {
  try {
    clearLocalStorage();
    commit('LOGOUT_USER');

    const { data: { registerUser } } = await registerUserRequest(commit, payload);

    saveUser(commit, registerUser);

  } catch (error) {
    console.log('registerUser::catch', error);
    throw error;
  }
}

async function setUser({ getters, commit, rootState }, payload) {
  const userId = getters.getUser?.id;
  const { data: { user } } = await apolloClient.query({
    query: LOGGED_IN_USER,
    variables: { id: payload?.id || userId },
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });
  const individualAuthorization = user.authorizations.find((item) => item.type === 'INDIVIDUAL');

  if (!individualAuthorization) {
    user.authorizations.push({});
  }
  commit('SAVE_USER', user);
  if (!user.authorizations || user.authorizations.length === 0) {
    return user;
  }

  const account = getters.getAccount;
  if (!account) {
    const accountId = individualAuthorization?.account?.id;
    if (individualAuthorization) await userSwapAccount({ commit }, { accountId: accountId });
  } else {
    console.log('update account');
    user.authorizations.some((item) => {
      if (item.account?.id === account?.id) {
        commit('SAVE_ACCOUNT', item);
        return true;
      }
      return false;
    });
  }
  return user;
}

async function getUserById({ rootState }, payload) {
  const { data: { user } } = await apolloClient.query({
    query: USER_BY_ID,
    variables: { id: payload.id },
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return user;
}

// TODO переименовать надо
async function getUser({ rootState }, payload) {
  const { data: { account } } = await apolloClient.query({
    query: USER_PERMISSIONS,
    variables: { ...payload },
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return account;
}

async function getAccountEmployees({ rootState }) {
  const { data: { account } } = await apolloClient.query({
    query: USER_PERMISSIONS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return account;
}

// getUserFunction так названа из-за наличия такого же геттера
async function getUserFunction({ rootState }) {
  const { data: { account } } = await apolloClient.query({
    query: USER_PERMISSIONS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return account;
}

async function getAccountAction({ rootState }, payload) {
  const { data: { account } } = await apolloClient.query({
    query: ACCOUNT,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return account;
}

async function sendEmailToSupport({ commit }, payload) {
  try {
    console.log('from, message', payload.from, payload.message);
    const { data: { sendEmailToSupport } } = await apolloClient.mutate({
      mutation: SEND_SUPPORT,
      variables: { ...payload },
    });

    console.log('sendEmailToSupport', sendEmailToSupport, commit);
    return true;
  } catch (error) {
    console.log('sendEmailToSupport::catch', error);
    return false;
  }
}

async function updatePermissions(store, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: UPDATE_PERMISSIONS,
      variables: { ...payload },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('updatePermissions errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    console.log('updatePermissions::catch', error);
    throw error;
  }
}

async function userAuthorizations({ getters, rootState }, payload) {
  const userId = getters.getUser?.id;

  try {
    const { data } = await apolloClient.query({
      query: USER_AUTHORIZATIONS,
      variables: { id: payload?.id || userId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return data.user;
  } catch (error) {
    console.log('userAuthorizations::catch', error);
    throw error;
  }
}

async function updateCompanyProfileByFns(store, payload) {
  try {
    const request = {
      mutation: UPDATE_COMPANY_PROFILE_BY_FNS,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('updateCompanyProfileByFns errors', error.graphQLErrors);
      },
    };

    if (payload?.companyAccountId) {
      request.variables = { companyAccountId: payload.companyAccountId };
    }

    const { data } = await apolloClient.mutate(request);
    console.log(data);
  } catch (error) {
    console.log('updateCompanyProfileByFns::catch', error);
    throw error;
  }
}

async function deleteUserFromCompany(store, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: DELETE_USER_FROM_COMPANY,
      variables: {
        deletableUserId: payload,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('deleteUserFromCompany errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    console.log('deleteUserFromCompany::catch', error);
    throw error;
  }
}

async function getCompanyRoles({ rootState }, payload) {
  const { data: { roles } } = await apolloClient.query({
    query: COMPANY_ROLES,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return roles;
}

async function getSubdivisions({ rootState }, payload) {
  const { data: { subdivisions } } = await apolloClient.query({
    query: SUBDIVISIONS_BY_FILTER,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return subdivisions;
}

async function supplierSubdivisions({ rootState }, payload) {
  const { data: { supplierSubdivisions } } = await apolloClient.query({
    query: SUPPLIER_SUBDIVISIONS_BY_FILTER,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return supplierSubdivisions;
}

async function getSubordinatedSubdivisions({ rootState }, payload) {
  const { data: { authorization } } = await apolloClient.query({
    query: SUBORDINATED_SUBDIVISIONS,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return authorization.role.subordinatedSubdivisionLinear;
}

async function getSubdivisionWithFilteredRolesById({ rootState }, payload) {
  const { data: { subdivision } } = await apolloClient.query({
    query: SUBDIVISION_WITH_FILTERED_ROLES_BY_ID,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        ['X-Authorization']: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return subdivision;
}

async function getPossiblePermissions({ rootState }) {
  const { data: { possiblePermissions } } = await apolloClient.query({
    query: POSSIBLE_PERMISSIONS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });

  return possiblePermissions;
}

async function getAllPermissions({ rootState }) {
  const { data: { permissions } } = await apolloClient.query({
    query: ALL_PERMISSIONS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });
  return permissions;
}

async function userProfileDeleteApplications({ rootState }) {
  const { data: { userProfileDeleteApplications } } = await apolloClient.query({
    query: USER_PROFILE_DELETE_APPLICATIONS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });
  return userProfileDeleteApplications;
}

async function getAllAccounts({ rootState }) {
  const { data: { allAccounts } } = await apolloClient.query({
    query: ALL_ACCOUNTS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });
  return allAccounts;
}

async function accountsByFilter({ rootState }, payload) {
  const { data: { accountsByFilter } } = await apolloClient.query({
    query: ACCOUNTS_BY_FILTER,
    fetchPolicy: 'no-cache',
    variables: { ...payload },
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });
  return accountsByFilter;
}

async function getAllUsers({ rootState }) {
  const { data: { allUsers } } = await apolloClient.query({
    query: ALL_USERS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        Authorization: `Bearer ${rootState.user.token}`,
      },
    },
  });
  return allUsers;
}

// eslint-disable-next-line no-unused-vars
async function sendRestorePasswordCode({ commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: SEND_RESTORE_PASSWORD_CODE,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('sendRestorePasswordCode::catch', error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function restorePassword({ commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: RESTORE_PASSWORD,
      variables: { ...payload },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('sendRestorePasswordCode::catch', error);
    throw error;
  }
}

function setNotification({ commit }, error) {
  commit('user/SET_NOTIFICATION', {
    notificationType: error.type ?? 'error',
    notificationText: `${error.message}`,
    isHtml: error.isHtml,
  }, { root: true });
}

export default {
  loginUser,
  confirmUser,
  setUser,
  logOut,
  sendEmailToSupport,
  confirmUserWithUtm,
  getUser,
  getUserFunction,
  updatePermissions,
  userAuthorizations,
  updateCompanyProfileByFns,
  confirmAccount,
  deleteUserFromCompany,
  getCompanyRoles,
  getPossiblePermissions,
  getUserById,
  getAllPermissions,
  registerUser,
  registerUserRequest,
  userProfileDeleteApplications,
  getAllAccounts,
  getAllUsers,
  requestCapAuth,
  requestCapCode,
  userSendAuthenticationCode,
  userSwapAccount,
  clearUserData,
  userSwapAccountToDefault,
  sendRestorePasswordCode,
  restorePassword,
  sendUsernameVerificationCode,
  getAccountAction,
  getSubdivisions,
  supplierSubdivisions,
  getSubdivisionWithFilteredRolesById,
  getAccountEmployees,
  setNotification,
  getSubordinatedSubdivisions,
  accountsByFilter,
};
