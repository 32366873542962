<template>
  <page title="Акты снятия показаний приборов учёта и акты по отпуску и приему электроэнергии по границе балансовой принадлежности">
    <div v-if="activeTab === 'integralActs'" :class="$style.content">
      <integral-acts :is-create-integral-acts-click="reloadActs" @uploaded="uploadedActs" />
    </div>
    <template v-if="activeTab === 'templates'">666</template>
    <dialog-template v-model="isShowError" center>
      <template>
        <p :class="$style.errorTitle">При формировании актов возникла ошибка</p>
        <p :class="$style.errorText" style="margin-bottom: 32px">{{ submitError }}</p>
        <submit-task-details :class="$style.errorsSubmit" title="Закрыть" @submit="isShowError = false" />
      </template>
      <horizontal-progress-bar v-if="isWaitingSubmit && submitError.length === 0" />
    </dialog-template>
  </page>
</template>

<script>
import { Page } from '@/components';
import IntegralActs from './IntegralActs';
import HTTPError from 'lib/utils/errors';
import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { mapActions } from 'vuex';

import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import dayjs from 'dayjs';

export default {
  name: 'IntegralActsPage',
  components: {
    IntegralActs,
    Page,
    HorizontalProgressBar,
    DialogTemplate,
    SubmitTaskDetails,
  },
  created() {
    this.loadSelectBoundariesBalanceSheet();
  },
  data() {
    return {
      activeTab: 'integralActs',
      isWaitingSubmit: false,
      reloadActs: false,
      submitError: '',
      isShowError: false,
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      actIndicators: undefined,
      borders: [],
      borderId: '',
      actsKindList: [
        {
          label: 'АСППУ',
          value: ['BORDER_DEVICE_ACT'],
        },
        {
          label: 'АПОПЭЭ',
          value: ['BORDER_FLOW_ACT'],
        },
        {
          label: 'АСППУ и АПОПЭЭ',
          value: ['BORDER_DEVICE_ACT', 'BORDER_FLOW_ACT'],
        },
      ],
      actsKind: ['BORDER_DEVICE_ACT', 'BORDER_FLOW_ACT'],
    };
  },
  computed: {
    tabOptions: () => [
      { label: 'Интегральные акты', value: 'integralActs' },
      // { label: 'Шаблоны', value: 'templates' },
    ],
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.actIndicators) {
        return {
          transmissionContract: unknown,
          measurements: unknown,
        };
      }

      const result = {};

      result.transmissionContract = this.actIndicators.isTransmissionContractSigned ? check : error;
      result.measurements = this.actIndicators.isMeasurementsInputTransitExists ? check : error;

      return result;
    },
  },
  watch: {
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.balanceIndicators = undefined;
          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          this.actIndicators = await this.loadActIndicators({ year: date.year(), month: date.month() + 1 });
        }
      },
    },
  },
  methods: {
    async loadSelectBoundariesBalanceSheet() {
      try {
        this.borders = await this.loadIntegralActPatterns();
        this.borderId = this.borders[0]?.id;
      } catch (error) {
        console.log('loadIntegralActPatterns', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      }
    },
    async createIntegralActsClick() {
      if (this.indicators.transmissionContract.isError) {

        let errorMessage = '';

        if (this.indicators.transmissionContract.isError) {
          errorMessage += 'Договор не загружен. Прошу загрузить договор.<br>';
        }

        this.setNotification({ message: errorMessage, isHtml: true });
        return;
      }
      this.isWaitingSubmit = true;
      try {
        const dateObj = dayjs(this.currentDate, 'MMMM | YYYY');
        const result = await this.createIntegralActs({
          year: dateObj.year(),
          month: dateObj.month() + 1,
          actsKind: this.actsKind,
          borderId: this.borderId,
        });
        if (result.generateIntegralAct.length > 0) {
          let notFormedActsMessage = '';
          result.generateIntegralAct.forEach((value, index) => {
            let firstDepartmentName = '';
            let secondDepartmentName = '';
            if (value.firstCompanyDepartmentName !== null) {
              firstDepartmentName = `(отделение ${value.firstCompanyDepartmentName})`;
            }
            if (value.secondCompanyDepartmentName !== null) {
              secondDepartmentName = `(отделение ${value.secondCompanyDepartmentName})`;
            }
            notFormedActsMessage += `<p><b>${index + 1}.</b> ${value.firstCompanyName} ${firstDepartmentName} ${value.secondCompanyName} ${secondDepartmentName} за ${value.accountingPeriod} </p>\n`;
          });
          this.vueShowNotification(
            'По причине отсутствия показаний, не удалось сформировать следующие интегральные акты:',
            notFormedActsMessage,
          );
        } else {
          this.vueShowNotification(
            'Интегральные акты были успешно сформированы!',
          );
        }
        this.reloadActs = true;
      } catch (e) {
        this.errorHandler(e);
        console.log(e);
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    uploadedActs() {
      this.reloadActs = false;
    },
    errorHandler(error) {
      if (error.message.split(': ')[2] === 'THREE_SIDE_CONTRACT_NOT_FOUND') {
        this.isShowError = true;
        this.submitError = 'Вы не загрузили трёхсторонний договор';
        return;
      }
      if (error.message.split(': ')[2] === 'CONTRACT_NOT_SIGNED') {
        this.isShowError = true;
        this.submitError = 'Вы не подписали загруженный договор';
        return;
      }
      if (error.message.split(': ')[2] === 'THREE_SIDE_CONTRACT_WRONG_DATES') {
        this.isShowError = true;
        this.submitError = 'Не найден договор, период действия которого включает выбранный расчётный период';
        return;
      }
      if (error.message.split(': ')[2] === 'SUPPLIER_NOT_FOUND') {
        this.isShowError = true;
        this.submitError = 'Ваша компания не найдена в реестре сетевых и сбытовых компаний';
        return;
      }
      const errorData = HTTPError.network(error);
      this.submitError = errorData.message;
    },
    ...mapActions('document', ['createIntegralActs']),
    ...mapActions('cabinet', ['loadActIndicators']),
    ...mapActions('border', ['loadIntegralActPatterns']),
    ...mapActions('user', ['setNotification']),
  },
};

</script>

<style lang="scss" module>
.content {
  margin-top: 24px;
}

.title {
  display: flex;
  align-items: center;
}

.errorTitle {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin: 48px 64px 16px;
}

.errorText {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: red;
  margin-left: 64px;
  margin-right: 64px;
}

.button {
  div {
    margin-right: 8px;
  }
}

.generate {
  display: flex;
  gap: 8px;

  span {
    width: 160px;
    margin-right: -16px;
  }

  input {
    border: 1px solid #C1C4C7;
    border-radius: 24px 0 0 24px;
    width: 100%;
  }

  select {
    width: 290px;
    font-size: 18px;
    border-color: #C1C4C7;
  }

  button {
    border-radius: 0 24px 24px 0;
  }
}

</style>
