export const oekTso1Pattern = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  supplier: {
    name: {
      colIndex: 11,
      rules: 'required|maxLength255',
      title: 'Наименование смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица)',
      col: 11,
    },
    inn: {
      colIndex: 12,
      rules: 'required|inn',
      title: 'ИНН смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица)',
      col: 12,
    },
  },
  connectionAct: {
    number: {
      colIndex: 13,
      rules: 'required|maxLength255',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Номер',
      col: 13,
    },
    date: {
      colIndex: 14,
      rules: 'required|date',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Дата',
      col: 14,
    },
  },
  sourceContractInfo: {
    number: {
      colIndex: 15,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 15,
    },
    date: {
      colIndex: 16,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 16,
    },
  },
};

export const oekTso2Pattern = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  consumer: {
    name: {
      colIndex: 11,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 11,
    },
    inn: {
      colIndex: 12,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 12,
    },
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 13,
      rules: 'required',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора. Номер',
      col: 13,
    },
    date: {
      colIndex: 14,
      rules: 'required',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора. Дата',
      col: 14,
    },
  },
  meterPlace: { colIndex: 15, rules: 'required', title: 'Место установки прибора учёта', col: 15 },
  voltageLevel: { colIndex: 16, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 16 },
  tariffVoltageLevel: {
    colIndex: 17,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 17,
  },
  meter: {
    rules: 'required',
    id: { colIndex: 18, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. ID ПУ', col: 18 },
    type: { colIndex: 19, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. Тип', col: 19 },
    serial: {
      colIndex: 23,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта',
      col: 23,
    },
    accuracyClass: {
      colIndex: 22,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности',
      col: 22,
    },
    ratedCurrent: { colIndex: 21, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. I ном.', col: 21 },
    nominalVoltage: { colIndex: 20, ruled: 'maxLength255', title: 'Прибор учёта электрической энергии. Uном.', col: 20 },
    calibrationDate: {
      colIndex: 24,
      rules: 'date|lessCurrent',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 24,
    },
    calibrationInterval: {
      colIndex: 25,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет',
      col: 25,
    },
    nextCalibrationDate: {
      colIndex: 26,
      rules: 'date|nextCalibrationDate',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 26,
    },
  },
  currentTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 27, rules: 'maxLength255', title: 'Трансформаторы тока. Тип', col: 27 },
    transformationRatio: { colIndex: 29, rules: 'ktNumber', title: 'Трансформаторы тока. Ктт', col: 29 },
    accuracyClass: { colIndex: 28, rules: 'maxLength255', title: 'Трансформаторы тока. Класс точности', col: 28 },
    calibrationDate: { colIndex: 30, rules: 'date|lessCurrent', title: 'Трансформаторы тока. Дата поверки', col: 30 },
    calibrationInterval: {
      colIndex: 31,
      rules: 'number',
      title: 'Трансформаторы тока. Межповерочный интервал, лет',
      col: 31,
    },
  },
  potentialTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 32, rules: 'maxLength255', title: 'Трансформаторы напряжения. Тип', col: 32 },
    transformationRatio: { colIndex: 34, rules: 'ktNumber', title: 'Трансформаторы напряжения. Ктн', col: 34 },
    accuracyClass: { colIndex: 33, rules: 'maxLength255', title: 'Трансформаторы напряжения. Класс точности', col: 33 },
    calibrationDate: {
      colIndex: 35,
      rules: 'date|lessCurrent',
      title: 'Трансформаторы напряжения. Дата поверки',
      col: 35,
    },
    calibrationInterval: {
      colIndex: 36,
      rules: 'number',
      title: 'Трансформаторы напряжения. Межповерочный интервал, лет',
      col: 36,
    },
  },
  accountingRatio: { colIndex: 37, rules: 'required|number', title: 'Расчётный коэффициент', col: 37 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 39,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 39,
    },
    variable: {
      colIndex: 38,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 38,
    },
  },
  kind: {
    colIndex: 40,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный)',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 40,
  },
  maxPower: { colIndex: 41, rules: 'required|number', title: 'Величина максимальной мощности, МВт', col: 41 },
  metersOwner: {
    colIndex: 42,
    rules: 'required|allowedValues',
    title: 'Собственник средств учёта (И1/И2 - Исполнитель, ССО - смежная сетевая организация, ПЭ - производитель электрической энергии)',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['И1', 'И2', 'И-1', 'И-2', 'ССО', 'ПЭ'],
    col: 42,
  },
  sourceContractInfo: {
    number: {
      colIndex: 43,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 43,
    },
    date: {
      colIndex: 44,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 44,
    },
  },
};

export const oekTso3Pattern = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  consumer: {
    name: {
      colIndex: 11,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 11,
    },
    inn: {
      colIndex: 12,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 12,
    },
  },
  connectionAct: {
    number: {
      colIndex: 13,
      rules: 'required|maxLength255',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Номер',
      col: 13,
    },
    date: {
      colIndex: 14,
      rules: 'required|date',
      title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Дата',
      col: 14,
    },
  },
  sourceContractInfo: {
    number: {
      colIndex: 15,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 15,
    },
    date: {
      colIndex: 16,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 16,
    },
  },
};
