<template>
  <div :class="$style.filters">
    <i :class="$style.iconClose" @click="$emit('close')"/>
    <div :class="$style.typeFilter">
      <p :class="$style.small">Вид документа</p>
      <p v-for="(doc, idx) in doType" :key="idx"
         :class="[$store.state.document.idxDoc === idx ? [$style.docTypes, $style.active] : [$style.docTypes]]"
         @click="$store.state.document.idxDoc = idx, documentType(doc, idx)">{{ doc.title }}</p>
    </div>
    <div :class="$style.typeFilter">
      <p :class="$style.small">Когда заключен</p>
      <p>с
        <c-date-picker v-model="fromModel"/>
      </p>
      <p>по
        <c-date-picker v-model="toModel"/>
      </p>
    </div>
    <div :class="$style.typeFilter">
      <p :class="$style.small">Статус</p>
      <p v-for="(status, idx) in statType" :key="idx"
         :class="[$store.state.document.idxStat === idx ? [$style.docTypes, $style.active] : [$style.docTypes]]"
         @click="$store.state.document.idxStat = idx, statusType(status, idx)">{{ status.title }}</p>
    </div>
  </div>
</template>

<script>
import CDatePicker from 'atoms/common/inputs/CDatePicker';
import { mapMutations } from 'vuex';

export default {
  name: 'FilterType',
  components: {
    CDatePicker,
  },
  data() {
    return {
      docType: [
        {
          title: 'все',
          type: 'ALL',
        },
        {
          title: 'акты',
          type: 'ACT',
        },
        {
          title: 'заявки',
          type: 'APPLICATION',
        },
        {
          title: 'договоры',
          type: 'CONTRACT',
        },
        {
          title: 'тарифы',
          type: 'ELECTRICITY_TARIFFS',
        },
        {
          title: 'технические условия',
          type: 'TECHNICAL_CONDITION',
        },
      ],
      statType: [
        {
          title: 'все',
          type: 'ALL',
        },
        {
          title: 'действующие',
          type: 'ACTING',
        },
        {
          title: 'заключенные',
          type: 'COMPLETED',
        },
        {
          title: 'сформированные',
          type: 'FORMED',
        },
        {
          title: 'аннулированные',
          type: 'CANCELED',
        },
        {
          title: 'созданные',
          type: 'DRAFT',
        },
      ],
      fromModel: '',
      toModel: '',
      from: null,
      to: null,
    };
  },
  watch: {
    fromModel: {
      immediate: true,
      handler(val) {
        this.from = val;
        this.filterTime();
      },
    },
    toModel: {
      immediate: true,
      handler(val) {
        this.to = val;
        this.filterTime();
      },
    },
  },
  methods: {
    filterDocuments() {
      this.$emit('filter', {
        from: this.from,
        to: this.to,
      });
    },
    documentType(doc, idx) {
      if (idx === 0) {
        this.SET_DOC(0);
        this.SET_DOCUMENT(null);
        this.SET_KIND(null);
        this.filterDocuments();
      } else {
        this.SET_DOCUMENT(doc.title);
        this.SET_KIND(doc.type);
        this.filterDocuments();
      }
    },
    statusType(status, idx) {
      if (idx === 0) {
        this.SET_STAT(0);
        this.SET_TITLE(null);
        this.SET_STATUS(null);
        this.filterDocuments();
      } else {
        this.SET_TITLE(status.title);
        this.SET_STATUS(status.type);
        this.filterDocuments();
      }
    },
    filterTime() {
      if (!this.fromModel || !this.toModel) {
        if (!this.from && !this.to)
          return this.filterDocuments();
        return;
      }
      this.from = this.formatDate(this.fromModel);
      this.to = this.formatDate(this.toModel);
      this.filterDocuments();
    },
    formatDate(date) {
      date = `${date.replaceAll('.', '-').split('-').reverse()
        .join('-')}T00:00:00Z`;

      return date;
    },
    ...mapMutations('document', [
      'SET_DOCUMENT',
      'SET_STATUS',
      'SET_STAT',
      'SET_DOC',
      'SET_KIND',
      'SET_TITLE',
    ]),
  },
};
</script>

<style lang="sass" module>
.filters
  display: flex
  margin-left: 16px
  position: relative
  padding: 16px 32px
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
  background-color: #fff

.typeFilter
  margin-right: 80px

  &:last-child
    margin-right: 0

.small
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 12px
  margin-bottom: 10px

.docTypes
  padding-left: 8px
  margin-bottom: 7px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 19px
  cursor: pointer

.active
  border-left: 5px solid #2F82DF

.iconClose
  width: 18px
  height: 18px
  display: block
  position: absolute
  top: 14px
  right: 14px
  background-image: url('~icons/basic/close.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer
</style>
