const SET_ISDISPLAYDETAILS = (state, isDisplayDetails) => {
  state.isDisplayDetails = isDisplayDetails;
};

const SET_COMPONENTDETAILS = (state, componentDetails) => {
  state.componentDetails = componentDetails;
};

const SET_TASKDETAILS = (state, taskDetails) => {
  state.taskDetails = taskDetails;
};

const SET_POPUPDETAILS = (state, popUpDetails) => {
  state.popUpDetails = popUpDetails;
};

const SET_POPUPDETAILSPROPS = (state, popUpDetailsProps) => {
  state.popUpDetailsProps = popUpDetailsProps;
};

const SET_ISDISPLAYPOPUPDETAILS = (state, isDisplayPopUpDetails) => {
  state.isDisplayPopUpDetails = isDisplayPopUpDetails;
};

const SET_LOADING = (state, loading) => {
  state.loading = loading;
};

const CLEAR_DETAILS = (state) => {
  state.isDisplayDetails = false;
  state.componentDetails = '';
  state.taskDetails = {};
};

const CLEAR_POPUPDETAILS = (state) => {
  state.isDisplayDetails = false;
  state.popUpDetails = '';
  state.popUpDetailsProps = {};
};

export default {
  SET_ISDISPLAYDETAILS,
  SET_COMPONENTDETAILS,
  SET_TASKDETAILS,
  SET_POPUPDETAILS,
  SET_POPUPDETAILSPROPS,
  SET_ISDISPLAYPOPUPDETAILS,
  SET_LOADING,
  CLEAR_DETAILS,
  CLEAR_POPUPDETAILS,
};
