<template>
  <page title="Реестр приборов учёта">
    <v-table-new
      v-model="transformedMeters"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-pagination-change="onPaginationChange"
      :page-out="metersPage.pageOut"
      :template="template"
      excel-report-file-name="Отчёт. Приборы учёта"
      :on-load-all-values="loadAllFiltered"
      :action-block-items="actionBlockItems"
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { convertDbFormat } from 'lib/utils/date';
import { Page, VTableNew } from '@/components';
import { exportToExcel } from 'utils/excel';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'CabinetMeterInputTransit',
  components: { VTableNew, Page },
  data() {
    return {
      metersPage: {},
      loading: false,
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    transformedMeters() {
      return this.meters.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            type: 'index',
            size: 'sm',
          },
          {
            label: 'Уникальный идентификатор прибора учёта',
            key: 'uuid',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
            filter: {},
          },
          {
            label: 'Модель',
            key: 'type',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'lg',
          },
          {
            label: 'Номер',
            key: 'serialNumber',
            size: 'sm',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Адрес',
            key: 'address',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Класс точности',
            key: 'accuracyClass',
            size: 'md',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 5,
            },
            filter: {},
          },
          {
            label: 'Номинальный ток, А',
            key: 'ratedCurrent',
            type: 'number',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
          },
          {
            label: 'Номинальное напряжение, В',
            key: 'nominalVoltage',
            type: 'number',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
          },
          {
            label: 'Расположение',
            key: 'location',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Организация',
            key: 'ownerCompany',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Год выпуска',
            key: 'productionYear',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 3,
            },
            size: 'md',
          },
          {
            label: 'Межповерочный интервал, лет',
            key: 'calibrationInterval',
            type: 'number',
            thStyle: {
              minWidth: '96px',
            },
            size: 'md',
          },
          {
            label: 'Дата поверки',
            key: 'calibrationDate',
            tooltip: {
              maxLength: 4,
            },
            size: 'md',
          },
          {
            label: 'Дата следующей поверки',
            key: 'nextCalibrationDate',
            tooltip: {
              maxLength: 7,
            },
            size: 'md',
          },
          {
            label: 'Статус',
            key: 'statusText',
            size: 'md',
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    meters() {
      return this.metersPage.list ?? [];
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();
        let convertFilterKey = '';

        if (filterKey === 'uuid') {
          convertFilterKey = 'id';
        }

        if (filterValue.length !== 0) {
          result[convertFilterKey] = filterValue;
        }
      });

      return result;
    },
    getInputTransitPath() {
      return this.route === '/cabinet/transport-meter-input-transit'
        ? 'transport-meter-input-transit/certificate-of-admission-upload'
        : 'meter-input-transit/certificate-of-admission-upload';
    },
    getGenerateReplacingMeterActPath() {
      return 'transport-meter-input-transit/generate-replacing-meter-act';
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать акт проверки прибора учёта электроэнергии инструментальной/визуальной',
          method: goToPageByRoute('reading-sheets/indications-single-upload'),
        },
        {
          id: 2,
          title: 'Сформировать акт допуска в эксплуатацию прибора учета электрической энергии',
          method: goToPageByRoute(`${this.getInputTransitPath}`),
        },
        {
          id: 3,
          title: 'Сформировать акт замены прибора учёта',
          method: goToPageByRoute(`${this.getGenerateReplacingMeterActPath}`),
        },
      ];
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.metersPage = await this.allMetersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (error) {
        console.log('allMetersByFilterPaged', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;

        const meters = await this.allMetersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.metersPage.pageOut.totalElements,
          },
        });

        if (!meters?.list) {
          return [];
        }

        return meters.list.map(this.transformed);
      } catch (error) {
        console.log('loadAllFiltered', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        console.log('finally');
        this.loading = false;
      }
    },
    transformed(it, index) {
      return {
        ...it,
        uuid: it.id,
        index: index,
        productionYear: convertDbFormat(it.productionYear),
        calibrationDate: convertDbFormat(it.calibrationDate),
        nextCalibrationDate: convertDbFormat(it.nextCalibrationDate),
        statusText: this.statusText(it.replacementStatus),
      };
    },
    convertDbFormat(value) {
      return convertDbFormat(value);
    },
    cellClickHandler(cell) {
      if (cell.key === 'followLink') {
        this.$router.push({
          name: 'cabinet.meter',
          params: {
            id: cell.row.id,
            usagePoint: this.meters[cell.row.index],
          },
        });
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    statusText(status) {
      if (status === 'INSTALLED') {
        return 'Поставлен';
      }

      if (status === 'WITHDRAWN') {
        return 'Выведен';
      }

      return '';
    },
    async exportToExcelAction() {
      const meters = await this.loadAllFiltered();

      exportToExcel(
        'Приборы учёта',
        this.template.headers.slice(1).filter((item) => item.label),
        'label',
        meters,
        'key',
      );
    },
    ...mapActions('point', ['allMetersByFilterPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>
