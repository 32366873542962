<template>
  <div>
    <v-table-new
      v-model="transformedLocalRepairSummary"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="localRepairSummary.pageOut"
      :template="template"
      excel-report-file-name="Список дефектов ОДК"
      server-side-sorting
      @on-sort="handleSort"
      @on-cell-click="handleCellClick"
    />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { mapGetters } from 'vuex';
import { formatDateTimeFromOdkTimestamp } from 'lib/utils/date';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { BASE_URL } from '@/constants/api';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};
export default {
  name: 'ExploitationDefects',
  components: { VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      localRepairSummary: [],
      loading: false,
      pagination: initialPagination,
      adreskaId: null,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    transformedLocalRepairSummary() {
      if (!this.localRepairSummary ||
        !this.localRepairSummary.data ||
        !Array.isArray(this.localRepairSummary.data)) {
        return [];
      }

      console.log('trans', this.localRepairSummary);
      return this.localRepairSummary.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
          },
          {
            label: 'ID ОДК',
            key: 'defect.adreskaFact.id',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Фото дефекта',
            key: 'preview',
            type: 'preview',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Выявленные замечания',
            key: 'defect.defect.name',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Комментарий к замечанию',
            key: 'defect.defectDescription',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Гарантийный',
            key: 'defect.warranty',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Статус устранения замечания',
            key: 'defect.status',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
            filter: { type: 'select', values: this.odkDefectStatus },
          },
          {
            label: 'Причины неисправности',
            key: 'defect.reason',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Выполненные работы',
            key: 'defect.works',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'ФИО выявившего дефект',
            key: 'defect.foundBy',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата обнаружения ',
            key: 'defect.createDate',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата устранения ',
            key: 'defect.repairedAt',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        'defect.status': 'status',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });

      result.adreskaId = this.adreskaId;

      return result;
    },
    defaultContractorName() {
      return this.getAccount?.kind === 'COUNTERPARTY' ? this.getAccount.profile?.name : undefined;
    },
    ...mapGetters('user', ['getAccount']),
    ...mapGetters('dataLists', ['odkDefectStatus']),
    ...mapGetters('dictionary', ['odkDefectStatusDictionary']),
  },
  methods: {
    async handleCellClick(cell) {
      switch (cell.key) {
        case 'followLink': {
          await this.$router.push({
            name: 'cabinet.exploitation.defect-card',
            query: { id: cell.row.defect.id },
          });
        }
      }
    },
    async loadData() {
      this.adreskaId = this.$route.query.id;
      if (!this.adreskaId) {
        return;
      }

      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;

        const responseData = await fetchData('local-repair-summary', page, size, sortField, sort, filters);

        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.localRepairSummary = responseData;
        this.localRepairSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('local-repair-summary', 1, this.localRepairSummary.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed) ?? [];
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(item, index) {
      return {
        index: index + 1,
        preview: {
          src: `${BASE_URL}/api/get/${item.defect.photos[0]?.folder}/${item.defect.photos[0]?.name}`,
          alt: 'Фото дефекта',
          previewExists: item.defect.photos[0],
          notExistsMessage: 'Отсутствует',
        },
        defect: {
          id: item.defect.id,
          defect: {
            name: item.defect.defect.name,
          },
          adreskaFact: {
            id: item.defect.adreskaFact.id,
            countOdk: item.defect.adreskaFact.countOdk,
          },
          reason: item.defect.reason,
          works: item.defect.works,
          status: this.odkDefectStatusDictionary[item.defect.status],
          defectDescription: item.defect.defectDescription,
          updatedAt: formatDateTimeFromOdkTimestamp(item.defect.updatedAt),
          foundBy: item.defect.foundBy,
          createDate: formatDateTimeFromOdkTimestamp(item.defect.createDate),
          repairedAt: formatDateTimeFromOdkTimestamp(item.defect.repairedAt),
          warranty: convertBooleanToText(item.defect.warranty),
        },
      };
    },
  },
};
</script>

<style scoped>

</style>
