import { isAccess } from 'lib/utils/permissions';
import Vue from 'vue';

function saveUser(state) {
  localStorage.setItem('user', JSON.stringify(state.data));
}

function saveSettings(state) {
  localStorage.setItem(`settings${state.account?.id}`, JSON.stringify(state.settings));
}

function updateStateSettings(state, accountId) {
  const settings = localStorage.getItem(`settings${accountId}`);

  state.settings = settings ? JSON.parse(settings) : {};
}

export const SET_NOTIFICATION = (state, notification) => {
  state.notification = notification;
  state.isShownNotification = true;
};

export const CLEAR_NOTIFICATION = (state) => {
  state.notification = null;
  state.isShownNotification = false;
};

const SET_TOKEN = (state, data) => {
  state.token = data.token;
  state.tokenStatus = data.tokenStatus;
  state.tokenUsername = data.tokenUsername;
  localStorage.setItem('apollo-token', data.token);
  localStorage.setItem('token-status', data.tokenStatus);
  localStorage.setItem('token-username', data.tokenUsername);
};

const SAVE_USER = (state, user) => {
  state.data = { ...user };
  saveUser(state);
};

const SAVE_ACCOUNT = (state, authorization) => {
  // TODO join account and authorization, revert account with authorization.account

  if (authorization === null) {
    state.account = null;
    state.authorization = null;
    localStorage.removeItem('account');
    localStorage.removeItem('authorization');
    return;
  }

  const account = authorization.account;
  state.account = { ...account };

  const authorizationData = {
    authorization: { ...authorization },
    blockAccess: isAccess(authorization.role?.permissions, account.kind),
  };
  state.authorization = authorizationData;

  updateStateSettings(state, account?.id);

  localStorage.setItem('account', JSON.stringify(account));
  localStorage.setItem('authorization', JSON.stringify(authorizationData));
};

const LOGOUT_USER = (state) => {
  state.token = null;
  state.tokenStatus = null;
  state.tokenUsername = null;
  state.data = null;
  state.account = null;
  state.authorization = null;
};

const SET_LOGIN_ERROR = (state, error) => {
  state.error = { ...error };
};

const RESET_LOGIN_ERROR = (state) => {
  state.error = {};
};

const SET_MIGROGENERATION_HELP = (state) => {
  state.wasShowMicrogenerationHelp = true;
  localStorage.setItem('wsmHelp', 'true');
};

const SAVE_TABLES = (state, data) => {
  if (!state.settings.tables) {
    Vue.set(state.settings, 'tables', {});
  }

  Vue.set(state.settings.tables, data.name, data.table);

  saveSettings(state);
};

const SAVE_TABLE_FILTERS = (state, data) => {
  if (!state.settings.tableFilters) {
    Vue.set(state.settings, 'tableFilters', {});
  }

  Vue.set(state.settings.tableFilters, data.name, data.filters);

  saveSettings(state);
};

const SAVE_TABLE_HIDDEN_COLUMNS = (state, data) => {
  if (!state.settings.tableHiddenColumns) {
    Vue.set(state.settings, 'tableHiddenColumns', {});
  }

  Vue.set(state.settings.tableHiddenColumns, data.name, data.hideColumns);

  saveSettings(state);
};

export default {
  SET_TOKEN,
  SAVE_USER,
  SAVE_ACCOUNT,
  LOGOUT_USER,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  SET_LOGIN_ERROR,
  RESET_LOGIN_ERROR,
  SET_MIGROGENERATION_HELP,
  SAVE_TABLES,
  SAVE_TABLE_FILTERS,
  SAVE_TABLE_HIDDEN_COLUMNS,
};
