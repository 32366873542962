<template>
  <div>
        <div class="container">
            <HelpNav />
            <div class="content-container">
                <div class="content">
                    <img src="../../../../assets/images/help/bg-content.png" alt="rectangle" class="bg-content">
                    <div class="content-text">
                        <p class="title">Ответы на вопросы и типичные ситуации</p>
                        <div class="question">
                            <p class="second-title">Не приходит СМС с одноразовым паролем</p>
                            <p class="standart-text">Включите и выключите телефон или используйте другой способ авторизации.</p>
                        </div>
                        <!-- /.question -->
                        <div class="question">
                            <p class="second-title">Не вижу своих объектов, счётчиков и документов после авторизации</p>
                            <p class="standart-text">Возможно, вы использовали другой способ авторизации. Выйдите и авторизуйтесь под тем логином, который использовали ранее.</p>
                        </div>
                        <!-- /.question -->
                        <div class="question">
                            <p class="standart-text">Не нашли ответ на свой вопрос? Воспользуйтесь <router-link to="/help-technical-support" class="link">формой обратной связи.</router-link></p>
                        </div>
                        <!-- /.question -->
                    </div>
                    <!-- /.content-text -->
                </div>
                <!-- /.content -->
            </div>
            <!-- /.content-container -->
        </div>
        <!-- /.container -->
  </div>
</template>

<script>
import HelpNav from 'views/public/help/components/HelpNav.vue';

export default {
    components: {
        HelpNav,
    },
};
</script>

<style scoped>
    .container{
        font-family: 'Roboto Condensed', sans-serif;
        max-width: 1200px;
        margin:0 auto;
        display: flex;
        overflow-x: auto;
    }

    /* Content */

    .content-container{
        display: flex;
        margin-left: 530px;
    }

    .bg-content{
        width: 491px;
        height: 325px;
    }

    .content{
        margin-left: 80px;
        margin-top: 40px;
    }

    .content-text{
        margin-top: 40px;
    }

    .title{
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.005em;
        text-transform: uppercase;
    }

    .question{
        margin-top: 24px;
    }

    .second-title{
        margin-bottom: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #0E0F0F;
    }

    .standart-text{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #0E0F0F;
        max-width: 465px;
    }

    .link{
        color: #0E0F0F;
        text-decoration: underline;
    }
</style>
