<template>
  <div class="page-form">

    <div v-if="isNewStyle" class="page-form__form">
      <!-- ФИО -->
      <div v-if="withCompanyName" class="row mb-24">
        <div class="col col--2of3">
          <v-input
            v-model="internalCompany.name"
            :clearable="editable"
            :disabled="!editable"
            title="Наименование компании"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.inn"
            :clearable="editable"
            :disabled="!editable"
            title="ИНН"
          />
        </div>
      </div>
      <div v-else class="row mb-24">
        <div class="col col--1of1">
          <v-input
            v-model="internalCompany.inn"
            :clearable="editable"
            :disabled="!editable"
            title="ИНН"
          />
        </div>
      </div>
      <div class="row mb-24">
        <div class="col col--2of3">
          <v-address-search-select
            v-model="internalCompany.legalAddress"
            :clearable="editable"
            :disabled="!editable && !!internalCompany.legalAddress"
            title="Юридический адрес"
          />
        </div>
        <div class="col col--1of3">
          <v-address-search-select
            v-model="internalCompany.postAddress"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.postAddress) || nonEditable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            title="Почтовый адрес"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-divider/>
        </div>
      </div>

      <div v-if="!onlyMainRequisites && companyInfo.bankRequisites && companyInfo.bankRequisites.length"
           class="row mb-16">
        <div class="col col--1of1 space-between">
          <p class="title" style="margin: auto 0">Банковские реквизиты</p>
          <v-select
            v-model="selectedBank"
            :options="bankRequisites"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            new-style
            placeholder="Выбрать реквизиты"
            rules="required"
            style="min-width: 235px; max-width: 500px"
          />
        </div>
      </div>

      <div
        v-if="!onlyMainRequisites && (selectedBank || !(companyInfo.bankRequisites && companyInfo.bankRequisites.length))">
        <div class="row mb-24">
          <div class="col col--1of1">
            <v-address-search-select
              v-model="internalCompany.bankName"
              :bik="internalCompany.bik"
              :clearable="editable"
              :disabled="!editable &&  (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)"
              :vid="`Банк_${vid}`"
              bank-api
              is-hide-bik
              required
              rules="required"
              title="Банк"
              @keyup="setBankInfo"
            />
          </div>
        </div>

        <div v-if="!onlyMainRequisites" class="row mb-24">
          <div class="col col--1of3">
            <v-input
              v-model="internalCompany.settlementAccount"
              :disabled="!editable && (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`Расчётный счёт_${vid}`"
              required
              rules="required|rs"
              title="Расчётный счёт"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              v-model="internalCompany.correspondentAccount"
              :disabled="!editable && (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`Корреспондентский счёт_${vid}`"
              required
              rules="required|ks"
              title="Корреспондентский счёт"
            />
          </div>
          <div class="col col--1of3">
            <v-input
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
              :value="internalCompany.bik"
              :vid="`БИК_${vid}`"
              disabled
              required
              rules="bik"
              title="БИК"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--1of1">
            <v-divider/>
          </div>
        </div>
      </div>

      <div v-if="!onlyMainRequisites" class="row mb-24">
        <div class="col col--1of1">
          <v-input
            v-model="internalCompany.oktmo"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.oktmo) || nonEditable"
            title="ОКТМО"
          />
        </div>
      </div>

      <div v-if="!onlyMainRequisites" class="row mb-24">

        <div class="col col--1of2">
          <v-input
            v-model="internalCompany.kpp"
            :clearable="editable"
            :disabled="!editable"
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required|kpp"
            title="КПП"
          />
        </div>
        <div class="col col--1of2">
          <v-input
            v-model="internalCompany.okpo"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.okpo) || nonEditable"
            title="ОКПО"
          />
        </div>
      </div>

      <div v-else class="row mb-24">

        <div class="col col--1of1">
          <v-input
            v-model="internalCompany.kpp"
            :clearable="editable"
            :disabled="!editable"
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required|kpp"
            title="КПП"
          />
        </div>
      </div>

      <div v-if="!onlyMainRequisites">
        <div class="row mb-24">
          <div class="col col--1of1">
            <v-divider/>
          </div>
        </div>

        <div class="row mb-16">
          <div class="col col--1of1 space-between">
            <p class="title" style="margin: auto 0">Виды деятельности</p>
          </div>
        </div>

        <div class="row mb-24">
          <div class="col col--1of1">
            <v-input
              v-model="internalCompany.okved"
              :clearable="editable"
              :disabled="(!editable && !!companyInfo.okved) || nonEditable"
              title="ОКВЭД"
            />
          </div>
        </div>

        <div class="row mb-24">
          <div v-for="(okved, idx) in internalCompany.additionalOkved" :key="idx" class="col col--1of3">
            <v-input
              v-model="internalCompany.additionalOkved[idx]"
              :disabled="true"
              title="Дополнительные ОКВЭД"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="page-form__form">
      <div v-if="withCompanyName" class="row mb-24">
        <div class="col col--1of1">
          <v-input
            v-model="internalCompany.name"
            :clearable="editable"
            :disabled="!editable"
            title="Наименование компании"
          />
        </div>
      </div>
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.inn"
            :clearable="editable"
            :disabled="!editable"
            title="ИНН"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.kpp"
            :clearable="editable"
            :disabled="!editable"
            :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            required
            rules="required|kpp"
            title="КПП"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.ogrn"
            :clearable="editable"
            :disabled="!editable && !!internalCompany.ogrn"
            title="ОГРН"
          />
        </div>
      </div>
      <div v-if="!onlyMainRequisites" class="row mb-24">
        <div class="col col--1of2">
          <v-address-search-select
            v-model="internalCompany.legalAddress"
            :clearable="editable"
            :disabled="!editable && !!internalCompany.legalAddress"
            title="Юридический адрес"
          />
        </div>
        <div class="col col--1of2">
          <v-address-search-select
            v-model="internalCompany.postAddress"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.postAddress) || nonEditable"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            title="Почтовый адрес"
          />
        </div>
      </div>

      <div v-if="!onlyMainRequisites&&companyInfo.bankRequisites && companyInfo.bankRequisites.length"
           class="row mb-16">
        <div class="col col--1of1 space-between">
          <p class="title" style="margin: auto 0">Банковские реквизиты</p>
          <v-select
            v-model="selectedBank"
            :options="bankRequisites"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            new-style
            placeholder="Выбрать реквизиты"
            rules="required"
            style="min-width: 235px; max-width: 500px"
          />
        </div>
      </div>

      <div
        v-if="!onlyMainRequisites&& (selectedBank || !(companyInfo.bankRequisites && companyInfo.bankRequisites.length))">
        <div class="row mb-24">
          <div class="col col--1of1">
            <v-address-search-select
              v-model="internalCompany.bankName"
              :bik="internalCompany.bik"
              :clearable="editable"
              :disabled="(!editable && (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)) || nonEditable"
              :vid="`Банк_${vid}`"
              bank-api
              required
              rules="required"
              title="Банк"
              @keyup="setBankInfo"
            />
          </div>
        </div>

        <div v-if="!onlyMainRequisites" class="row mb-24">
          <div class="col col--1of2">
            <v-input
              v-model="internalCompany.settlementAccount"
              :disabled="(!editable && (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)) || nonEditable"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`Расчётный счёт_${vid}`"
              required
              rules="required|rs"
              title="Расчётный счёт"
            />
          </div>
          <div class="col col--1of2">
            <v-input
              v-model="internalCompany.correspondentAccount"
              :disabled="(!editable && (!!companyInfo.bankRequisites && !!companyInfo.bankRequisites.length)) || nonEditable"
              :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,  /\d/, /\d/]"
              :vid="`Корреспондентский счёт_${vid}`"
              required
              rules="required|ks"
              title="Корреспондентский счёт"
            />
          </div>
        </div>
      </div>

      <div v-if="!onlyMainRequisites" class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.oktmo"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.oktmo) || nonEditable"
            title="ОКТМО"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.okpo"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.okpo) || nonEditable"
            title="ОКПО"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="internalCompany.okved"
            :clearable="editable"
            :disabled="(!editable && !!companyInfo.okved) || nonEditable"
            title="ОКВЭД"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { VAddressSearchSelect, VInput, VSelect } from 'components';
import { merge } from 'lodash';
import { mapActions } from 'vuex';
import VDivider from '@/components/VDivider/VDivider';

export default {
  components: { VInput, VAddressSearchSelect, VSelect, VDivider },
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    nonEditable: {
      type: Boolean,
      default: false,
    },
    withCompanyName: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: '',
    },
    isNewStyle: {
      type: Boolean,
      default: false,
    },
    onlyMainRequisites: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalCompany: {
        bankName: null,
        bik: null,
        correspondentAccount: null,
        id: null,
        inn: null,
        kpp: null,
        legalAddress: null,
        name: null,
        ogrn: null,
        okpo: null,
        oktmo: null,
        okved: null,
        postAddress: null,
        settlementAccount: null,
      },
      emptyCompany: {
        bankName: '',
        bik: '',
        correspondentAccount: '',
        id: '',
        inn: '',
        kpp: '',
        legalAddress: '',
        name: '',
        ogrn: '',
        okpo: '',
        oktmo: '',
        okved: '',
        postAddress: '',
        settlementAccount: '',
      },
    };
  },
  computed: {
    selectedBank: {
      get() {
        return this.internalCompany.settlementAccount;
      },
      set(value) {
        const bank = this.companyInfo?.bankRequisites.find((bankRequisite) => bankRequisite.settlementAccount === value);
        this.internalCompany.bankName = bank.bankName;
        this.internalCompany.bik = bank.bik;
        this.internalCompany.correspondentAccount = bank.correspondentAccount;
        this.internalCompany.settlementAccount = bank.settlementAccount;
        this.$emit('select-bank', bank);
      },
    },
    bankRequisites() {
      return this.companyInfo?.bankRequisites.map((bankRequisites) => ({
        label: bankRequisites.bankName,
        value: bankRequisites.settlementAccount,
      }));
    },
    bankName() {
      return this.internalCompany.bankName;
    },
    settlementAccount() {
      return this.internalCompany.settlementAccount;
    },
    correspondentAccount() {
      return this.internalCompany.correspondentAccount;
    },
    bik() {
      return this.internalCompany.bik;
    },
  },
  watch: {
    companyInfo: {
      immediate: true,
      handler(previous, current) {
        if (JSON.stringify(previous) === JSON.stringify(current)) return;
        if (this.internalCompany) {
          const companyInformation = {
            ...this.companyInfo,
            ...(this.companyInfo?.bankRequisites && this.companyInfo.bankRequisites.length > 0
              ? this.companyInfo.bankRequisites
              : {}),
            ...(this.companyInfo?.bankRequisites?.bankName ? this.companyInfo.bankRequisites : {}),
          };
          if (!companyInformation.legalAddress) {
            companyInformation.legalAddress = this.companyInfo.address;
          }
          this.internalCompany = merge(this.emptyCompany, companyInformation);
          if (this.companyInfo?.bankRequisites && this.companyInfo.bankRequisites.length > 0) {
            this.selectedBank = this.companyInfo.bankRequisites[0].settlementAccount;
          }
        }
      },
    },
    bankName() {
      if (this.companyInfo.bankRequisites.length > 0) return;
      this.$emit('input', this.internalCompany);
    },
    settlementAccount() {
      if (this.companyInfo.bankRequisites.length > 0) return;
      this.$emit('input', this.internalCompany);
    },
    correspondentAccount() {
      if (this.companyInfo.bankRequisites.length > 0) return;
      this.$emit('input', this.internalCompany);
    },
    bik() {
      if (this.companyInfo.bankRequisites.length > 0) return;
      this.$emit('input', this.internalCompany);
    },
  },
  methods: {
    setBankInfo(value) {
      this.internalCompany.bik = value.bik;
    },
    ...mapActions('cabinet', ['getSuppliersByFilter']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4C4E51;
}
</style>
