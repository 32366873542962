<template>
  <v-form :action-on-error="actionOnError" @submit="onActionRecord">
    <modal
      v-if="!isClosed"
      :body-style="{
            padding: '24px 0',
            overflowY:'initial'
          }"
      :header-style="{
            display: 'flex',
            justifyContent: 'center',
          }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
            borderRadius: '16px',
            padding: '24px',
          }"
      class="directory"
    >
      <template #header>
        <span v-if="isCreateForm">Добавление записи</span>
        <span v-else-if="isUpdateForm">Редактирование записи</span>
        <span v-else-if="isDeleteForm">Удаление записи</span>
      </template>
      <template #body>
        <div class="directory__fields">
          <div
            v-for="(field) in template"
            :key="field.id"
          >
            <div v-if="field.type === 'TEXT'">
              <v-input
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :rules-messages="{required: 'Обязательно для заполнения', max: 'Максимальное количество символов - 1024!'}"
                :title="field.name"
                :rules="field.required ? 'required|max: 1024' : 'max: 1024'"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'NUMBER'">
              <v-input
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :title="field.name"
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                type="number"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'DATE'">
              <v-date-picker
                :key="datePickerReRenderKey"
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                :title="field.name"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'DATE_RANGE'">
              <v-date-picker
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :title="field.name"
                range
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'YEAR'">
              <v-date-picker
                :key="datePickerReRenderKey"
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                :title="field.name"
                format="YYYY"
                type="year"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'BOOLEAN'">
              <v-select
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :options="booleanFieldOptions"
                :required="field.required"
                :title="field.name"
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'REFERENCE'">
              <v-search-select
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :title="field.name"
                :options="field.options"
                :url-default-data="field.url"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'ADDRESS'">
              <v-address-search-select
                v-model="internalValue[field.id]"
                :disabled="isDeleteForm || field.disabled"
                :required="field.required"
                :rules="field.required ? 'required' : ''"
                :rules-messages="{required: 'Обязательно для заполнения'}"
                :title="field.name"
                @input="inputDateHandler"
              />
            </div>
            <div v-else-if="field.type === 'CUSTOM'">
              <slot
                :action="action" :disabled="isDeleteForm || field.disabled" :field="field"
                :input="(val) => setValue(field, val)" :name="field.id"
                :re-render-key="datePickerReRenderKey" :value="internalValue[field.id]"/>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <horizontal-progress-bar v-if="loading" class="directory__progress-bar"/>
        <div v-if="error?.length > 0" class="errorText">
          {{ error }}
        </div>
        <div style="display: flex; justify-content: space-around;">
          <v-button
            variant="outlined"
            @click="close"
          >
            Отменить
          </v-button>
          <v-button
            v-if="!isDeleteForm"
            :disabled="loading"
            :variant="isDeleteForm ? 'outlined-red' : undefined"
            type="submit"
          >
            <span v-if="isCreateForm">Добавить</span>
            <span v-else-if="isUpdateForm">Сохранить</span>
          </v-button>
          <v-button v-else :disabled="loading" variant="outlined-red" @click="onActionRecord">Удалить</v-button>
        </div>
      </template>
    </modal>
  </v-form>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from './consts.js';
import { VButton, VDatePicker, VForm, VInput, VSelect } from 'components';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import VAddressSearchSelect from 'components/VAddressSearchSelect/VAddressSearchSelect';
import Modal from 'components/Modal/Modal';
import { inConstants } from 'lib/component/validators';


export default {
  components: {
    VAddressSearchSelect,
    VSearchSelect,
    VSelect,
    HorizontalProgressBar,
    VButton,
    VForm,
    Modal,
    VDatePicker,
    VInput,
  },
  name: 'AddDirectoryFieldModal',
  props: {
    value: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
      validator: inConstants(AddDirectoryFieldModalActions),
    },
    template: {
      type: Array,
      default: () => [],
      validator(value) {
        const inConstantValidator = inConstants(AddDirectoryFieldModalFieldType);
        return value.every((field) => inConstantValidator(field.type));
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      datePickerReRenderKey: 0,
      booleanFieldOptions: [
        {
          label: 'Да',
          value: 'true',
        },
        {
          label: 'Нет',
          value: 'false',
        },
      ],
      internalValue: {},
    };
  },
  computed: {
    isValid: {
      cache: false,
      get() {
        return this.template.every((it) => {
          const fieldValue = this.value[it.id];
          const isString = typeof fieldValue === 'string' || fieldValue instanceof String;
          const isArray = Array.isArray(fieldValue);
          const isValidByRequired = !it.required || ((isString || isArray) && fieldValue?.length) || ((!isString && !isArray) && fieldValue);
          const isValidByCustom = !it.customValidator || it.customValidator(fieldValue);
          return isValidByCustom && isValidByRequired;
        });
      },
    },
    isCreateForm() {
      return this.action === AddDirectoryFieldModalActions.CREATE;
    },
    isUpdateForm() {
      return this.action === AddDirectoryFieldModalActions.UPDATE;
    },
    isDeleteForm() {
      return this.action === AddDirectoryFieldModalActions.DELETE;
    },
    isClosed() {
      return this.action === AddDirectoryFieldModalActions.EMPTY;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value ?? {};
      },
    },
    internalValue: {
      handler() {
        this.$emit('input', this.internalValue);
      },
      deep: true,
    },
  },
  methods: {
    setValue(field, value) {
      this.internalValue[field.id] = value;
      this.inputDateHandler();
    },
    inputDateHandler() {
      this.datePickerReRenderKey += 1;
    },
    close() {
      this.$emit('close');
    },
    onActionRecord() {
      if (this.loading || (!this.isDeleteForm && !this.isValid)) {

        if (!this.isValid) {
          this.$emit('isNotValid');
        }

        return;
      }
      switch (this.action) {
        case AddDirectoryFieldModalActions.CREATE:
          this.$emit('create');
          break;
        case AddDirectoryFieldModalActions.UPDATE:
          this.$emit('update');
          break;
        case AddDirectoryFieldModalActions.DELETE:
          this.$emit('delete');
          break;
      }
    },
    actionOnError() {
      this.$emit('isNotValid');
    },
  },
};
</script>

<style lang="scss" scoped>
.directory {
  margin-right: 10px;

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 600px;
    overflow-y: scroll;
    padding: 0 10px 0 10px;

    &::-webkit-scrollbar-button {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 0;
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a19f9f;
    }

    &::-webkit-resizer {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
  }

  &__progress-bar {
    margin-bottom: 12px;
  }
}

</style>
