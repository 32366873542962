<template>
  <dialog-template v-model="visible" collapse @closed="onClose">
    <v-dialog-header
      :subtitle="'Заявка от ' + createDate"
      :title="data.title"
    />
    <div :class="$style.content">
      <spoiler-preview v-model="spoiler.details" :class="$style.currentTariff">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header title="Детали задачи">
            <template slot="icon">
              <chevron-up v-if="spoiler.details" />
              <chevron-down v-else />
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.object">
            <p :class="$style.documentTitle">Проверьте заявление и рассмотрите возможность смены тарифа.</p>

            <div :class="$style.expiresAt">
              <span>Срок исполнения:</span>
              <p>{{ data.expiresAt }}</p>
            </div>

            <div :class="$style.prop">
              <span>Дата подачи заявления:</span>
              <p>{{ createDate }}</p>
            </div>

            <div :class="$style.prop">
              <span>Потребитель:</span>
              <p>{{ tariffApplication.ConsumerName.FamilyName }}&nbsp;{{ tariffApplication.ConsumerName.FirstName }}&nbsp;{{ tariffApplication.ConsumerName.Patronymic
                }}</p>
            </div>

            <div :class="$style.prop">
              <span>Лицевой счёт:</span>
              <p>№&nbsp;{{ tariffApplication.BusinessAccount }}</p>
            </div>

            <div :class="$style.prop">
              <span>Адрес:</span>
              <p>{{ tariffApplication.ObjectLocation }}</p>
            </div>

            <div :class="$style.prop">
              <span>Группа населения:</span>
              <p>{{ tariffCategory[tariffApplication.currentConsumerCategory].title }}</p>
            </div>

            <div :class="$style.prop">
              <span>Текущий тариф:</span>
              <p>{{ tariffRate[tariffApplication.currentTariff].title }}</p>
            </div>
          </div>
        </template>
      </spoiler-preview>

      <spoiler-preview v-model="spoiler.newTariffObject" :class="$style.currentTariff" :disabled="true">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header title="Заявленные изменения">
            <template slot="icon">
              <chevron-up v-if="spoiler.tariffObject" />
              <chevron-down v-else />
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.object">
            <div v-if="tariffApplication.newConsumerCategory !== tariffApplication.currentConsumerCategory"
                 :class="$style.newConsumerCategory">
              <span>Смена группы населения:</span>
              <p :class="$style.title">{{ tariffCategory[tariffApplication.newConsumerCategory].title }}</p>
            </div>

            <div v-if="tariffApplication.newTariff !== tariffApplication.currentTariff">
              <span>Смена тарифа:</span>
              <p :class="$style.title">{{ tariffRate[tariffApplication.newTariff].title }}</p>
            </div>
          </div>
        </template>
      </spoiler-preview>

      <div v-if="tariffDocument.length > 0" :class="$style.currentTariff">
        <p :class="$style.documentTitle">Приложенные документы</p>

        <div :class="$style.reviewDocumentFiles">
          <p v-for="(file, index) in tariffDocument" :key="index" style="display: flex; justify-content: space-between">
            <a :download="file.name" :href="fileUrl(file)"
               :role="'tariffDocument[' + index + '].name'"><span>{{ file.name }}</span></a>
            <span :class="$style.replace">
                      <a :download="file.name" :href="fileUrl(file)"><img alt=""
                                                                          src="~@/assets/icons/file/file_download.svg" /></a>
                      <a :download="file.name" :href="fileUrl(file)"><span
                        :role="'tariffDocument[' + index + '].replace'">&nbsp;&nbsp;&nbsp;скачать</span></a>
                  </span>

          </p>
        </div>
      </div>

      <template v-if="data.isDisplayAction && (data.startedTask || startedTask)">
        <div :class="$style.statusGroup">
          <p :class="$style.reviewTitle">
            Отметьте результаты рассмотрения заявления
          </p>
          <div :class="$style.radioGroup">
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 1"
                :class="$style.control"
                name="taskStatus"
                role="radio.approve"
                type="radio"
                @click="statusGroupClick(1)"

              />
              <div :class="$style.contentRadioGroup">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupCheck"></div>
                  <h3 :class="$style.titleRadioGroup">Одобрена</h3>
                </div>
              </div>
            </label>
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 2"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(2)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">Отклонено</h3>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div v-if="statusGroup === 2" :class="$style.correctionGroup">
          <p>Причина мотивированного отказа и возможные способы решения</p>
          <c-textarea
            v-model="comments"
            role="comments"
          />
        </div>

        <div v-if="statusGroup === 1 || statusGroup === 2" :class="$style.currentTariff">
          <p :class="$style.correctionTitle">
            {{ reviewMessage }}
          </p>
        </div>

      </template>
      <template v-else-if="data.isApproved !== undefined">
        <div :class="$style.reviewBlock">
          <p :class="$style.reviewTitle">Решение по заявлению</p>
          <span v-if="data.isApproved === true" :class="$style.reviewLabelApprove">Принято</span>
          <span v-else-if="data.isApproved === false" :class="$style.reviewLabelReject">Отклонено</span>
        </div>

        <div v-if="reviewDocument.comments" :class="$style.reviewBlock">
          <p>Причина мотивированного отказа и возможные способы решения</p>
          <p :class="$style.reviewText" v-html="reviewComments"></p>
        </div>
      </template>
    </div>

    <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
    <horizontal-progress-bar v-if="isWaiting" />
    <template v-if="data.isDisplayAction">
      <submit-task-details v-if="data.startedTask || startedTask" :disabled="isWaiting || !isValid"
                           title="ЗАВЕРШИТЬ ЗАДАЧУ" @submit="onSubmit" />
      <submit-task-details v-else :disabled="isWaiting" title="НАЧАТЬ ВЫПОЛНЕНИЕ" @submit="onStartTask" />
    </template>
    <button v-else :class="$style.btn" @click="onClose">ЗАКРЫТЬ</button>
  </dialog-template>
</template>

<script>
import DialogTemplate from 'templates/DialogTemplate';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';
// import TariffForm from 'organisms/tasks/tariffs-update/TariffForm';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { mapActions, mapGetters } from 'vuex';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'TariffsChanging',
  components: {
    DialogTemplate,
    SpoilerPreview,
    SpoilerHeader,
    // TariffForm,
    SubmitTaskDetails,
    VDialogHeader: () => import('@/components/VDialog/VDialogHeader.vue'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    ChevronDown: () => import('atoms/icons/arrows/ChevronDown'),
    ChevronUp: () => import('atoms/icons/arrows/ChevronUp'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    value: Boolean,
    serviceLocation: Object,
    data: Object,
    reviewDocument: Object,
    tariffApplication: Object,
    tariffsData: Object,
    createDate: String,
    tariffDocument: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {

  },
  data() {
    return {
      visible: false,
      isWaiting: false,
      submitError: '',
      displayContent: false,
      spoiler: {
        tariff: false,
        details: true,
        newTariffObject: true,
      },
      selectedCategory: '',
      selectedRate: '',
      uploadDocs: [],
      additionalUploadDocs: [],
      approveAction: false,
      tariffs: [],
      comments: '',
      statusGroup: undefined,
      startedTask: false,
    };
  },
  computed: {
    firstHalfYearTariffs() {
      return this.tariffsData.firstHalfYearTariffs.reduce((map, object) => {
        map[object.consumerCategory] = {
          SINGLE_RATE: object.singleRate,
          DOUBLE_RATE: object.doubleRate,
          MULTI_RATE: object.multiRate,
        };
        return map;
      }, {});
    },
    secondHalfYearTariffs() {
      return this.tariffsData.secondHalfYearTariffs.reduce((map, object) => {
        map[object.consumerCategory] = {
          SINGLE_RATE: object.singleRate,
          DOUBLE_RATE: object.doubleRate,
          MULTI_RATE: object.multiRate,
        };
        return map;
      }, {});
    },
    // TODO Why is it not cached?
    firstHalfTariff() {
      return (serviceLocation, rate) => this.firstHalfTariffValue(serviceLocation.category, serviceLocation.meter[0].tariff, rate);
    },
    firstHalfTariffValue() {
      return (category, tariff, rate) => {
        if (!category) {
          return '';
        }

        const values = this.firstHalfYearTariffs[category][tariff];

        return formatting.currencyFormatted(values[rate]);
      };
    },
    secondHalfTariff() {
      return (serviceLocation, rate) => this.secondHalfTariffValue(serviceLocation.category, serviceLocation.meter[0].tariff, rate);
    },
    secondHalfTariffValue() {
      return (category, tariff, rate) => {
        if (!category) {
          return '';
        }

        const values = this.secondHalfYearTariffs[category][tariff];

        return formatting.currencyFormatted(values[rate]);
      };
    },
    isValid() {
      return this.statusGroup === 1 || (this.statusGroup === 2 && this.comments.trim().length > 3);
    },
    reviewType() {
      if (this.statusGroup === 1) {
        return 'changing-tariff-application-approved';
      }
      return 'changing-tariff-application-rejected';
    },
    reviewMessage() {
      if (this.statusGroup === 1) {
        return 'Сообщение о принятии заявления и смене тарифа будет направлено потребителю.';
      }
      return 'Сообщение о причинах отказа и способах решения будет направлено потребителю.';
    },
    reviewComments() {
      return formatting.formattedString(this.reviewDocument.comments, '', '');
    },
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
    ...mapGetters('user', ['getAccount', 'getUserType']),
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      async handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onClose() {
      if (this.startedTask) {
        this.setWorkflows({ userType: this.data.userType });
      }
      this.visible = false;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    selectRate(rate) {
      this.selectedRate = rate;
    },
    handleRemoveUploadDocs(file) {
      this.uploadDocs = this.uploadDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveAdditionalUploadDocs(file) {
      this.additionalUploadDocs = this.additionalUploadDocs
        .filter((scan) => scan.source !== file.source);
    },
    async onStartTask() {
      this.submitError = '';
      this.isWaiting = true;

      const documentReviewXml = generateXml.documentReviewXml(
        this.data.documentId,
        'changing-tariff-application-started',
        '',
        [],
        {},
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });
        this.isWaiting = false;
        this.startedTask = true;

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaiting = false;
      }
    },
    async onSubmit() {
      this.submitError = '';
      this.isWaiting = true;

      const documentReviewXml = generateXml.documentReviewXml(
        this.data.documentId,
        this.reviewType,
        this.comments,
        [],
        {},
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaiting = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification('', `${this.reviewMessage}`);
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaiting = false;
      }
    },
    statusGroupClick(val) {
      this.statusGroup = val;
    },
    ...mapActions('document', ['loadDocument', 'actualTariffsByRegion', 'documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
.btn
  width: 100%
  padding: 8px 16px
  background: #71757A
  color: #fff
  text-transform: uppercase
  font-style: normal
  font-weight: normal
  font-size: 21px
  line-height: 30px

.changingNote
  +base-text
  background-color: rgba(242, 201, 76, 0.25)
  padding: 16px
  max-width: 592px
  margin: 120px auto 0

.content
  width: 100%
  margin-top: 116px
  margin-bottom: 24px

  hr
    background-color: #D5D7DA

  input[type='radio']
    position: absolute

.spoilerHeader
  font-size: 16px !important
  line-height: 24px !important
  +questionnaries-container
  margin-bottom: 24px

.spoilerHeader2
  font-size: 16px !important
  line-height: 24px !important


.titleAddress
  +base-card-title
  margin-bottom: 10px

.prop, .expiresAt
  display: flex
  margin-bottom: 8px

  span
    +table-text-small
    line-height: 24px
    flex: 0 0 216px

  p
    +table-data-small

.expiresAt
  margin-bottom: 60px
  margin-top: 40px

.title
  +card-title-small
  margin-top: 4px

.tariffHeader
  margin-bottom: 16px

.tariffValues
  margin-bottom: 4px

.tariffHeader
  display: flex

  .titleTariff
    +small-text
    flex: 0 0 216px

  p
    +table-data-small
    flex: 0 0 80px

.tariffValues
  display: flex

  .titleTariff
    +table-data-small
    flex: 0 0 216px

    span
      +small-text

  p
    +input-text
    flex: 0 0 80px

.selectRate
  .prop
    span
      flex: 0 0 300px

  .tariffHeader
    .titleTariff
      flex: 0 0 300px

  .tariffValues
    .titleTariff
      flex: 0 0 300px

  .tariffHeader
    p
      flex: 0 0 90px

.currentTariff, .selectCategory, .selectRate, .uploadDocuments, .approveBlock, .reviewBlock
  max-width: 560px
  margin: 40px auto 0

.radioGroup
  max-width: 560px
  margin: auto 0 0 142px

.selectCategory
  img
    margin-right: 24px
    margin-left: 8px

.reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.reviewDocumentFiles p span.replace
  display: flex
  font-family: 'Roboto Condensed'
  color: #4C4E51
  white-space: nowrap
  cursor: pointer
  font-size: 18px
  line-height: 18px

  a
    text-decoration: none

.replace
  margin-bottom: 24px

.documentTitle
  +card-title-small
  margin-bottom: 24px

.titleBlock
  +base-title
  margin-bottom: 24px

.selectRate
  .rateBlock
    padding: 16px
    cursor: pointer

  .buttonRadioGroup
    margin-left: 24px

.rateBlock
  .buttonRadioGroup
    position: absolute
    right: 78px

.uploadNote
  +small-text

.titleAdditionalUploadDocs
  +base-text
  margin-top: 24px

.approveBlock
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 40px

  p
    +card-title-small

  input
    cursor: pointer

.reviewTitle
  +base-card-title
  margin-bottom: 24px

.reviewLabel
  +base-card-title
  line-height: 24px
  border: 2px solid #979CA2
  box-sizing: border-box
  border-radius: 4px
  color: #979CA2
  padding-left: 8px
  padding-right: 8px

.reviewBlock
  margin-bottom: 40px

.newConsumerCategory
  margin-bottom: 24px

.radioGroupTariff
  display: flex
  flex-direction: column
  align-items: start
  margin-left: 84px
  margin-top: 18px

.labelRadio
  cursor: pointer
  height: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 16px

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center


.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

    & .buttonRadioGroupError
      background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.statusGroup
  width: 100%
  max-width: 560px
  margin: 0 auto

.statusGroup
  margin-top: 40px

.correctionGroup
  width: 100%
  max-width: 560px
  margin: 0 auto

.correctionTitle
  +base-title
  margin-bottom: 40px

.reviewTitle
  +base-card-title

.reviewLabelProcess, .reviewLabelApprove, .reviewLabelReject
  +base-card-title
  line-height: 24px
  box-sizing: border-box
  border-radius: 4px
  padding-left: 8px
  padding-right: 8px

.reviewLabelProcess
  border: 2px solid #979CA2
  color: #979CA2

.reviewLabelApprove
  border: 2px solid #54B687
  color: #54B687

.reviewLabelReject
  border: 2px solid #EB5757
  color: #EB5757

.reviewText
  +table-data-base
  color: #EB5757

</style>
