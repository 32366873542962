export const AccountingBalancesPattern = {
  rowNumberStart: 2,
  axpName: {
    colIndex: 1,
    col: 1,
    rules: 'required',
    title: 'АХП тип',
  },
  elementName: {
    colIndex: 2,
    col: 2,
    rules: 'required',
    title: 'Наименование элемента',
  },
  status: {
    colIndex: 3,
    col: 3,
    rules: 'required',
    title: 'Статус',
  },
  warehouseName: {
    colIndex: 4,
    col: 4,
    title: 'Наименование склада',
  },
  contractorInn: {
    colIndex: 5,
    col: 5,
    title: 'ИНН подрядчика',
    rules: 'inn',
  },
  count: {
    colIndex: 6,
    col: 6,
    title: 'Количество',
    rules: 'required|number|greaterZero',
  },
  contract: {
    number: {
      colIndex: 7,
      col: 7,
      title: 'Номер договора',
    },
    date: {
      colIndex: 8,
      col: 8,
      title: 'Дата договора',
      rules: 'date',
    },
  },
};
