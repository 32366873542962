<template>
  <page title="История действий на Платформе">
    <v-table-new
      v-model="transformedMessages"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="История действий на Платформе"
    />
  </page>
</template>

<script>

import { mapActions } from 'vuex';
import { Page, VTableNew } from '@/components';
import { convertDbFormat, convertDbTimeFormat } from 'lib/utils/date';

export default {
  name: 'CabinetHistory',
  components: { VTableNew, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      messages: [],
      loading: false,
    };
  },
  computed: {
    transformedMessages() {
      return this.messages.map((it, index) => ({
        ...it,
        index: index,
        createdAtDate: convertDbFormat(it.createdAt),
        createdAtTime: convertDbTimeFormat(it.createdAt),
      }));
    },
    template() {
      return {
        headers: [
          {
            label: 'Дата заключения договора',
            key: 'createdAtDate',
            size: 'md',
            sort: {},
            filter: { type: 'period' },
          },
          {
            label: 'Время',
            key: 'createdAtTime',
            size: 'md',
            sort: {},
          },
          {
            label: 'Событие',
            key: 'message',
            size: 'xl',
            sort: {},
            filter: {},
          },
        ],
      };
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Обновить историю',
          method: this.loadData,
        },
      ];
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.messages = await this.loadHistory();
      } catch (error) {
        console.log('loadHistory', error);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('history', ['loadHistory']),
  },
};
</script>

<style lang="scss" module>
</style>
