<template>
  <div :class="$style.container">
    <div v-for="(file, idx) in list" :key="idx" :class="$style.list">
      <file-preview :visible-img="visibleImg" :file="file" :role="role + '[' + idx + ']'">
        <file-action v-if="action" slot="action" @click="handleDelete(file)">
          <icon-delete v-if="visibleDelete" slot="icon" />
        </file-action>
      </file-preview>
    </div>
  </div>
</template>

<script>
import IconDelete from 'atoms/icons/action/Delete';
import FileAction from 'molecules/file/FileAction';
import FilePreview from 'molecules/upload/form/FilePreview';

export default {
  name: 'FileListPreview',
  components: {
    IconDelete,
    FileAction,
    FilePreview,
  },
  props: {
    action: {
      type: Boolean,
    },
    list: {
      type: Array,
      default: () => [
        {
          name: '',
          source: '',
          preview: '',
        },
      ],
    },
    role: {
      type: String,
      default: '',
    },
    visibleDelete: {
      type: Boolean,
      default: true,
    },
    visibleImg: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleDelete(data) {
      this.$emit('delete', data);
    },
  },
};
</script>

<style lang="sass" module>
  .container

  .list
    &:not(:last-child)
      margin-bottom: 16px
</style>
