<template>
  <div>
    <xml-download/>
  </div>
</template>

<script>
import XmlDownload from 'atoms/icons/action/XmlDownload.vue';
export default {
  name: 'VTableCellXmlDownload',
  components: { XmlDownload },
};
</script>

<style scoped>

</style>
