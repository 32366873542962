export function formatUserFunction(data) {
  switch (data) {
    case 'BALANCE_REVIEW': {
      return 'Обзор баланса';
    }
    case 'ACTIVE_CONTRACT_CREATION': {
      return 'Создание контракта';
    }
    case 'INTEGRAL_ACT_REVIEW': {
      return 'Обзор интегральных актов';
    }
    case 'LOSS_ACT_REVIEW': {
      return 'Обзор актов об оплате потерь';
    }
    case 'MEASUREMENTS_READING_REPORT': {
      return 'Отчёты по показаниям ПУ';
    }
    case 'SERVICE_ACT_REVIEW': {
      return 'Обзор актов об оказании услуг';
    }
    case 'ADD_USERS_TO_ORGANISATION': {
      return 'Добавление сотрудников организации';
    }
  }
}

export function formatEighteenthFormsKind(data) {
  switch (data) {
    case 'LEGAL': {
      return 'Юридические лица';
    }
    case 'INDIVIDUAL': {
      return 'Физические лица';
    }
    default: {
      return '-';
    }
  }
}

export function formatContractStatus(data) {
  return getContractStatusLabelValueList().filter((item) => item.value === data)[0]?.label ?? '-';
}

export function getContractStatusList() {
  return getContractStatusLabelValueList().map((item) => ({ label: item.label, value: item.label }));
}

export function getContractStatusLabelValueList() {
  return [
    {
      value: 'DRAFT',
      label: 'На согласовании',
    },
    {
      value: 'APPROVED_BY_INITIATOR',
      label: 'Согласован работником инициатора',
    },
    {
      value: 'SIGNED_BY_INITIATOR',
      label: 'Подписан со стороны инициатора',
    },
    {
      value: 'REJECTED',
      label: 'Отклонён',
    },
    {
      value: 'CANCELED',
      label: 'Отменён',
    },
    {
      value: 'FORMED',
      label: 'Сформирован',
    },
    {
      value: 'APPROVED',
      label: 'Согласован',
    },
    {
      value: 'DELETED',
      label: 'Удалён',
    },
    {
      value: 'NULLIFIED',
      label: 'Аннулирован',
    },
    {
      value: 'SIGNED',
      label: 'Подписан',
    },
    {
      value: 'COMPLETED',
      label: 'Завершён',
    },
    {
      value: 'COMPANY_NOT_FOUND',
      label: 'Компания не найдена',
    },
    {
      value: 'COMPANY_NOT_ON_PLATFORM',
      label: 'Компания не имеет аккаунта',
    },
    {
      value: 'WAITING_VERIFICATION_ACT',
      label: 'Ожидает формирования акта верификации',
    },
    {
      value: 'WAITING_UPDATE_INFO',
      label: 'Ожидает ввода информации',
    },
    {
      value: 'NOT_SIGNING',
      label: 'Не подписывает',
    },
    {
      value: 'NOT_SEND',
      label: 'Не отправлен',
    },
  ];
}

export function getPointStatusLabelValueList() {
  return [
    { value: 'NEW', label: 'Новая' },
    { value: 'SETTLED', label: 'Урегулирована' },
    { value: 'UNSETTLED', label: 'Неурегулирована' },
    { value: 'IN_APPLICATION', label: 'В заявке на ТП' },
    { value: 'EXCLUDED', label: 'Удалена' },
    { value: 'ARCHIVED', label: 'В архиве' },
    { value: 'WAITING_SUPPLY_CONTRACT', label: 'Ожидает заключение ДЭС' },
    { value: 'WAITING_ADDITION_APPROVE', label: 'Ожидает согласования ДС' },
    { value: 'ACTIVE', label: 'Активная' },
  ];
}

export function formatPointKind(data) {
  switch (data) {
    case 'ACCOUNTING': {
      return 'Расчётный';
    }
    case 'CONTROL': {
      return 'Контрольный';
    }
    case 'EXPENDABLE': {
      return 'Расходный';
    }
    case 'PROFITABLE': {
      return 'Доходный';
    }
  }
}

export function pointKindValueLabelList() {
  return [
    {
      label: 'Расчётный',
      value: 'ACCOUNTING',
    },
    {
      label: 'Контрольный',
      value: 'CONTROL',
    },
    {
      label: 'Расходный',
      value: 'EXPENDABLE',
    },
    {
      label: 'Доходный',
      value: 'PROFITABLE',
    },
  ];
}

export function formatPaymentType(data) {
  switch (data) {
    case 'PRE_PAYMENT': {
      return 'Авансовый';
    }
    case 'MAIN_PAYMENT': {
      return 'Основной';
    }
  }
}

export function formatTariffType(data) {
  switch (data) {
    case 'INDIVIDUAL': {
      return 'Индивидуальный';
    }
    case 'BOILER': {
      return 'Котлодержатель';
    }
    case 'LOSS': {
      return 'Потери';
    }
  }
}

export function formatInfo(data) {
  if (!JSON.parse(data)) {
    return '';
  }

  return data;
}

export function formatRegion(data) {
  let str = '';
  for (let i = 0; i < data.length; i++) {
    switch (data[i]) {
      case 'MSK': {
        return 'Москва';
      }
      case 'MO': {
        return 'Московская область';
      }
    }
    str += `${data[i]} `;
  }

  return str;
}

export function formatPointIsDuplicate(data) {
  if (data === true) {
    return 'Да';
  }
  return '-';
}

export function formatPointTariffCategory(data) {
  switch (data) {
    case 'TARIFF_CATEGORY_1': {
      return '1';
    }
    case 'TARIFF_CATEGORY_2': {
      return '2';
    }
    case 'TARIFF_CATEGORY_3': {
      return '3';
    }
    case 'TARIFF_CATEGORY_4': {
      return '4';
    }
    case 'TARIFF_CATEGORY_5': {
      return '5';
    }
    case 'TARIFF_CATEGORY_6': {
      return '6';
    }
    default: {
      return '-';
    }
  }
}

export function formatMeasurementKind(data) {
  switch (data) {
    case 'ACTIVE_INPUT': {
      return 'Активная (прием)';
    }
    case 'ACTIVE_OUTPUT': {
      return 'Активная (отдача)';
    }
    case 'REACTIVE_INPUT': {
      return 'Реактивная (прием)';
    }
    case 'REACTIVE_OUTPUT': {
      return 'Реактивная (отдача)';
    }
  }
}

export function formatMeasurementsReadingKind(data) {
  switch (data) {
    case 'MANUAL': {
      return 'Ручная загрузка';
    }
    case 'ISU': {
      return 'Автоматическая загрузка (через ИСУ)';
    }
    case 'EIGHTEENTH_FORM': {
      return '18 формы';
    }
    case 'REPLACEMENT': {
      return 'Замещающая информация';
    }
    case 'ADJUSTMENT': {
      return 'Корректировка';
    }
    case 'ACCOUNTING_METHOD': {
      return 'Расчётный метод';
    }
    case 'NON_ACCOUNTED': {
      return 'Безучётное потребление';
    }
    case 'OTHER': {
      return 'Другое';
    }
  }
}

export function formatServiceKind(data) {
  switch (data) {
    case 'TWO_PART': {
      return 'Двухставочный';
    }
    case 'ONE_PART': {
      return 'Одноставочный';
    }
  }
}

export function formatAdditionKind(data) {
  switch (data) {
    case 'DELIVERY_POINT':
      return 'Точка поставки';

    case 'TRANSFER_POINT':
      return 'Точка передачи';

    case 'RECEIVING_POINT':
      return 'Точка приема';

    case 'TECHNICAL_POINT':
      return 'Техническая точка';

    default:
      break;
  }
}

export function formatAccountingMethod(data) {
  switch (data) {
    case 'MANUAL_INPUT': {
      return 'По показаниям';
    }
    case 'ISU': {
      return 'Автоматическая';
    }
    case 'EIGHTEENTH_FORM': {
      return '18 формы';
    }
    case 'REPLACEMENT': {
      return 'Замещающая информация';
    }
    case 'ADJUSTMENT': {
      return 'Корректировка';
    }
    case 'ACCOUNTING_METHOD': {
      return 'Расчётный метод';
    }
    case 'ADDITIONAL_VOLUME': {
      return 'Дополнительный объем';
    }
    case 'NOT_ACCOUNTED_CONSUMPTION': {
      return 'Не учтенное';
    }
    default: {
      return '-';
    }
  }
}

export function completedByStatus(data) {
  switch (data) {
    case 'DELETED': {
      return true;
    }
    case 'SIGNED': {
      return true;
    }
    case 'REJECTED': {
      return true;
    }
    case 'NULLIFIED': {
      return true;
    }
    default: {
      return false;
    }
  }
}

export function convertAction(action) {
  switch (action) {
    case 'CREATED': {
      return 'создан. ';
    }
    case 'SENT_TO': {
      return 'на согласовании. ';
    }
    case 'SENT_TO_SIGN': {
      return 'на подписании. ';
    }
    case 'INFO_UPDATED': {
      return 'обновлён.';
    }
    case 'APPROVED': {
      return 'согласован. ';
    }
    case 'SIGNED': {
      return 'подписан. ';
    }
    case 'DELETED': {
      return 'удалён. ';
    }
    case 'REJECTED': {
      return 'отклонён. ';
    }
    case 'RECREATED': {
      return 'изменен. ';
    }
    case 'NULLIFIED': {
      return 'аннулирован. ';
    }
  }
}

export function convertDictionaryKind(kind) {
  switch (kind) {
    case 'LOCAL_CLASSIFIER': {
      return 'Локальный классификатор';
    }
    case 'BASIC_CHARACTERISTIC': {
      return 'Базовая характеристика';
    }
    case 'GENERAL_DIRECTORY': {
      return 'Общий справочник';
    }
    case 'CENTRALIZED_DIRECTORY': {
      return 'Централизованный справочник';
    }
  }
}

export function getDictionaryKindValueLabelList() {
  return [
    {
      label: 'Локальный классификатор',
      value: 'LOCAL_CLASSIFIER',
    },
    {
      label: 'Базовая характеристика',
      value: 'BASIC_CHARACTERISTIC',
    },
    {
      label: 'Общий справочник',
      value: 'GENERAL_DIRECTORY',
    },
    {
      label: 'Централизованный справочник',
      value: 'CENTRALIZED_DIRECTORY',
    },
  ];
}

export function convertAccountKind(kind) {
  switch (kind) {
    case 'INDIVIDUAL_CONSUMER':
      return 'Потребитель физическое лицо';
    case 'LEGAL_CONSUMER':
      return 'Потребитель юридическое лицо';
    case 'UTILITY':
      return 'Сетевая компания';
    case 'BOILER':
      return 'Котлодержатель';
    case 'RETAILER':
      return 'Сбытовая компания';
    case 'SUPPLIER_OF_LAST_RESORT':
      return 'Гарантирующий поставщик';
    case 'PLATFORM':
      return 'Техническая поддержка';
  }
}

export function convertAccountKindToXml(kind) {
  switch (kind) {
    case 'INDIVIDUAL_CONSUMER':
      return 'IndividualConsumer';
    case 'LEGAL_CONSUMER':
      return 'LegalConsumer';
    case 'UTILITY':
      return 'Utility';
    case 'BOILER':
      return 'Boiler';
    case 'RETAILER':
      return 'Retailer';
    case 'SUPPLIER_OF_LAST_RESORT':
      return 'SupplierOfLastResort';
    case 'PLATFORM':
      return 'Platform';
  }
}

export function convertTariffKind(kind) {
  switch (kind) {
    case 'INDIVIDUAL_TARIFFS':
      return 'Индивидуальные тарифы';
    case 'PEAK_LOAD_HOURS':
      return 'Плановые часы пиковой нагрузки';
    case 'UNIFIED_ENERGY_TARIFFS':
      return 'Объединенные энергетические тарифы';
    case 'TARIFF_RATE_EXPENSES':
      return 'Расходы по тарифной ставке';
    case 'TARIFF_RATE_MAINTENANCE':
      return 'Тарифная ставка';
    case 'LOSS_NORMATIVES':
      return 'Нормативы потерь электрической энергии';
    case 'INFRASTRUCTURE_PAYMENTS':
      return 'Инфраструктурные платежи';
    case 'TARIFF_LOSSES':
      return 'Нерегулируемые цены на электрическую энергию';
    case 'BOILER_TARIFFS':
      return 'Котловые тарифы';
  }
}

export function convertBooleanToText(boolean) {
  return boolean ? 'Да' : 'Нет';
}

export function unconvertBooleanToText(text) {
  return text === 'Да';
}

export function booleanFromText(boolean) {
  if (String(boolean).toLowerCase() === 'true') return true;
  if (String(boolean).toLowerCase() === 'false') return false;
  return null;
}
