<template>
  <div style="padding: 0 24px 24px 0">
    <v-table-new
        v-model="transformedUsagePoints"
        :template="template"
        :pagination-show="true"
        :divider-show="false"
        :show-filters-block="false"
        @on-cell-click="cellClickHandler"
        @select-all="allBordersWithSubjectSelected"
    />
    <div>
      <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
    </div>
  </div>
</template>

<script>
import VTableNew from 'components/VTableNew/VTable.vue';
import { mapActions, mapGetters } from 'vuex';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

export default {
  name: 'SelectPoints',
  components: {
    VTableNew,
    HorizontalProgressBar,
  },
  props: {
    customAction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      usagePoints: [],
      submitError: '',
      isWaitingSubmit: false,
    };
  },
  computed: {
    template() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'Список точек учёта:',
            key: 'pointValue',
            type: 'filterAndCheckbox',
            customCheck: true,
            sort: {},
            filter: {},
            thStyle: {
              width: '50%',
              borderTop: borderStyle,
              borderRight: borderStyle,
              borderLeft: borderStyle,
              padding: '12px 16px',
            },
            tdStyle: {
              borderRight: borderStyle,
              borderLeft: borderStyle,
            },
          },
        ],
      };
    },
    transformedUsagePoints() {
      return this.usagePoints.map((it) => ({
        pointValue: it?.pointInfo?.mainSubstation,
        point: {
          id: it.id,
          mainSubstation: it?.pointInfo?.mainSubstation,
          pointStatus: it.pointStatus,
          tariffCategory: it.tariffCategory,
          selected: it.selected,
        },
      }));
    },
    isValid() {
      return this.usagePoints.filter((item) => item.selected).length > 0;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit('isWaitingSubmit', !val);
      },
    },
    customAction() {
      if (this.isValid) {
        this.generateAdditions();
      }
    },
  },
  methods: {
    async loadData() {
      if (this.getContract.usagePoints?.length > 0) {
        this.usagePoints = this.getContract.usagePoints;
        return;
      }

      this.usagePoints = await this.allNewUsagePoints({
        inn: this.getAccount.profile.inn,
      });
    },
    cellClickHandler(value) {
      const pointIndex = this.usagePoints.findIndex((it) => it.id === value.row.point.id);
      this.$set(this.usagePoints[pointIndex], 'selected', !this.usagePoints[pointIndex].selected);
      this.setUsagePoints({ usagePoints: this.usagePoints });
    },
    allBordersWithSubjectSelected(val) {
      this.usagePoints = this.usagePoints.map((item) => ({ ...item, selected: val }));
      this.setUsagePoints({ usagePoints: this.usagePoints });
    },
    ...mapActions('point', ['allNewUsagePoints']),
    ...mapActions('electricityTransmissionContract', ['setUsagePoints']),
  },
};

</script>

<style scoped>

</style>
