<template>
  <div class="table-container-scrollable">
    <div class="table-container-scrollable-child">
      <div :class="$style.table">
        <v-row>
          <v-col
            v-for="templateItem in template"
            :key="templateItem.label"
            :class="$style['table__label-cell']"
            :grid="templateItem.grid"
            :spacing="0"
          >
            {{ templateItem.label }}
          </v-col>
        </v-row>
        <v-row
          v-for="(internalDataItem, internalDataItemIndex) in internalData"
          :key="internalDataItem[id]"
          :class="{
            [$style['table__data-row']]: true,
            [$style['table__data-row--selectable']]: link,
          }"
          @click="link && $router.push(link(internalDataItem))"
        >
          <v-col
            v-for="templateItem in template"
            :key="templateItem.label"
            :class="$style['table__data-cell']"
            :grid="templateItem.grid"
            :spacing="0"
          >
            <span>{{ templateItem.value(internalDataItem, internalDataItemIndex) }}</span>
          </v-col>
        </v-row>
        <div :class="$style.pagination">
          <div :class="$style['pagination__sizes']">
            <v-pagination-button
              v-for="paginationSize in paginationSizes"
              :key="paginationSize"
              v-model="internalPagination.size"
              :selected="paginationSize === internalPagination.size"
              :size="paginationSize"
            />
          </div>
          <div :class="$style['pagination__pages']">
            <div :class="$style['pagination__pages-text']">
              Страница {{ internalPagination.page }} из
              {{ paginationPages.length }} (Всего элементов: {{ internalPagination.totalElements }})
            </div>
            <v-pagination-button
              v-for="paginationPage in paginationPages"
              :key="paginationPage"
              v-model="internalPagination.page"
              :selected="paginationPage === internalPagination.page"
              :size="paginationPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VPaginationButton } from '@/components';
import dayjs from 'dayjs';

export default {
  name: 'VTable',
  components: {
    VRow: () => import('@/components/VGrid/VRow'),
    VCol: () => import('@/components/VGrid/VCol'),
    VPaginationButton,
  },
  props: {
    link: {
      type: Function, default: () => {
      },
    },
    data: { type: Array, required: true },
    template: { type: Array, required: true },
    id: { type: String, required: true },
    onPaginationChange: {
      type: Function, default: () => {
      },
    },
    pagination: { type: Object, default: null },
  },
  data() {
    return {
      internalPagination: {
        totalPages: 0,
        totalElements: 0,
        size: 10,
        page: 1,
      },
      internalData: this.data.slice(0, 10),
      filters: {},
    };
  },
  computed: {
    paginationSizes() {
      return [10, 20, 30];
    },
    paginationPages() {
      if (this.pagination) {
        return this.internalPagination.totalPages;
      }
      const pagesCount = Math.ceil(
        this.data.length / this.internalPagination.size,
      );
      return [...Array(pagesCount).keys()].map((it) => it + 1);
    },
  },
  watch: {
    data() {
      this.internalData = this.data;
    },
    pagination() {
      if (this.pagination) {
        this.internalPagination = {
          ...this.pagination,
          page: this.pagination.page + 1,
        };
      }
    },
    'internalPagination.size'(newSize, oldSize) {
      if (!this.pagination) {
        this.internalData = this.data.slice(
          (this.internalPagination.page - 1) * 10,
          this.internalPagination.size * this.internalPagination.page,
        );
      }
      if (this.pagination && newSize !== oldSize) {
        this.onPaginationChange({
          page: 0,
          size: this.internalPagination.size,
        });
      }
    },
    'internalPagination.page'(newPage, oldPage) {
      if (!this.pagination) {
        this.internalData = this.data.slice(
          (this.internalPagination.page - 1) * 10,
          this.internalPagination.size * this.internalPagination.page,
        );
      }
      if (this.pagination && newPage !== oldPage) {
        this.onPaginationChange({
          page: this.internalPagination.page - 1,
          size: this.internalPagination.size,
        });
      }
    },
  },
  methods: {
    onFilterData(field, value, type, format) {
      this.filters[field] = value;
      this.internalData = this.data.filter((it) => {
        if (it[field] && value && value.length !== 0) {
          if (type === 'date') {
            return dayjs(it[field]).format(format) === value;
          }
          return it[field]
            .toString()
            .toLowerCase()
            .includes(value?.toLowerCase());
        }
        return typeof value === 'string' && value.length === 0;
      });
    },
  },
};
</script>

<style lang="scss" module>

.table {
  display: flex;
  flex-direction: column;
  margin: 0 8px;

  &__label-cell {
    padding: 16px 8px;
    color: #677379;
    border-bottom: 1px solid #cccccc;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  &__data-row {
    &--selectable {
      &:hover {
        background-color: #dee2e6;
        cursor: pointer;
      }
    }
  }

  &__data-cell {
    padding: 16px 8px;
    border-bottom: 1px solid #cccccc;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__header-controls {
    border-bottom: 1px solid #cccccc;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  &__sizes {
    display: flex;

    & > div {
      margin-right: 10px;
    }
  }

  &__pages {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 10px;
    }

    &-text {
      color: #677379;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
