<template>
  <div :class="$style.container">
    <div>
      <div :class="$style.offerGroup">
        <div v-if="offerDocument.accompanyingText" :class="$style.accompanyingTitle">Сотрудник {{ gridCompanyName }}
          рассмотрел ваши предложения и внёс изменения в документы:
        </div>
        <p v-if="offerDocument.accompanyingText" :class="$style.accompanyingText" v-html="offerComments"></p>
        <div :class="$style.title">
          Стоимость услуги подключения: {{ offerDocument.price }}<span
          v-if="offerDocument.comments && !offerDocument.isPreferential">*</span>
        </div>
        <div v-if="isIndividualConsumer" :class="$style.submitNote"><h4>Оплачивая счёт, Вы подтверждаете свое согласие с офертой договора на осуществление технологического присоединения</h4></div>
        <div v-if="offerDocument.comments && !offerDocument.isPreferential" :class="$style.commentsTitle"
             v-html="comments"></div>
      </div>
      <div v-if="offerDocument?.id" :class="$style.statusGroup">
        <v-file-attachment :document-id="offerDocument.id"
        />
      </div>
      <div v-if="!isDisabled" :class="$style.statusGroup">
        <p :class="$style.submitNote" style="margin-bottom: 32px">
          <c-checkbox
            v-model="isChecked"
            label='Я согласен с условиями'
            role="approve"/>
        </p>
      </div>

      <div v-if="!isChecked" :class="$style.blockContent">
        <div :class="$style.title">
          Предложения по изменениям *
        </div>
        <c-textarea
          v-model="correctionComments"
          role="correctionComments"
        />
      </div>
    </div>

    <div :class="$style.actionContent">
      <p v-if="!isDisabled && isChecked && isIndividualConsumer" :class="$style.submitNote">
        Нажимая кнопку «Подписать», вы принимаете условия договора, технические условия и
        подписываете их простой электронной подписью.
      </p>
      <p v-if="!isDisabled && !isChecked" :class="$style.submitNote">
        Сотрудник сетевой компании ознакомится с предложениями, по возможности учтёт их и вышлет новый договор и
        технические условия. Стоимость услуги и срок выполнения заявки при этом может измениться.
      </p>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <submit-task-details v-if="isDisabled" :disabled="isWaitingSubmit || !isValid" title="ПРОЙТИ БЕЗ ОПЛАТЫ (ТЕСТ)"
                           @submit="onSubmitWithoutPayment"/>
      <submit-task-details :disabled="isWaitingSubmit || !isValid || !offerDocument.id" :title="submitTitle"
                           @submit="onSubmit"/>
    </div>
    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="offerDocument.document"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import formatting from 'lib/utils/formatting';
import { SimpleSignatureModal, VFileAttachment } from 'components';

export default {
  name: 'ApproveOffer',
  components: {
    SimpleSignatureModal,
    VFileAttachment,
    SubmitTaskDetails,
    HorizontalProgressBar,
    CCheckbox: () => import('atoms/common/CCheckbox'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    offerDocument: Object,
    gridCompanyName: String,
    invoiceId: String,
    workflowId: String,
  },
  data() {
    return {
      isChecked: true,
      correctionComments: '',
      submitError: '',
      isWaitingSubmit: false,
      isDisabled: this.actionProps.isDisabled,
      isVisibleSimpleSignatureModal: false,
    };
  },
  computed: {
    submitTitle() {
      if (this.isDisabled) {
        return 'ПЕРЕЙТИ К ОПЛАТЕ';
      }
      if (this.isChecked) {
        return 'ПОДПИСАТЬ';
      }
      return 'ОТПРАВИТЬ ПРЕДЛОЖЕНИЯ';
    },
    isValid() {
      return this.isDisabled || (this.isChecked || this.correctionComments !== '');
    },
    isIndividualConsumer() {
      return this.$store.state.user.account.kind === 'INDIVIDUAL_CONSUMER';
    },
    reviewType() {
      if (this.isChecked) {
        return this.actionProps.approvedReviewType;
      }
      return this.actionProps.rejectedReviewType;
    },
    reviewComments() {
      if (this.isChecked) {
        return '';
      }
      return this.correctionComments;
    },
    comments() {
      return formatting.formattedString(this.offerDocument.comments, '* ', '');
    },
    offerComments() {
      return formatting.formattedString(this.offerDocument.accompanyingText, '* ', '');
    },
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('cabinet', ['getTaskByWorkflowId']),
  },
  methods: {
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });
        this.isDisabled = true;

        /* let countRequest = 0;
        while (!this.invoiceId && countRequest < 30) {
          countRequest++;
          await this.setWorkflows({ userType: this.getUserType });
          const currentTask = this.getTaskByWorkflowId(this.workflowId, this.getUserType);
          this.invoiceId = currentTask.props.invoiceId;
        }

        if (this.invoiceId) {
          await this.openPaymentUrl();
        } else {
          this.vueShowNotification(
            'ОШИБКА СТРАНИЦЫ ОПЛАТЫ, ПОПРОБУЙТЕ ПОЗЖЕ',
            '<p></p>',
          );
          this.isWaitingSubmit = false;
          this.onClose();
        }*/

        this.isWaitingSubmit = false;
        this.onClose();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmitWithoutPayment() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      try {
        if (this.isDisabled) {
          await this.createFixturePayment({ invoiceId: this.invoiceId });
          this.onClose();
        } else {
          const documentReviewXml = connectionApplicationData.documentReviewXml(
            this.documentId,
            this.reviewType,
            this.reviewComments,
            [],
            {},
          );

          await this.documentUpload({ xml: documentReviewXml });

          if (this.isChecked) {

            this.isDisabled = true;

            let countRequest = 0;
            while (!this.invoiceId && countRequest < 30) {
              countRequest++;
              await this.setWorkflows({ userType: this.getUserType });
              const currentTask = this.getTaskByWorkflowId(this.workflowId, this.getUserType);
              this.invoiceId = currentTask.props.invoiceId;
            }

            if (this.invoiceId) {
              await this.createFixturePayment({ invoiceId: this.invoiceId });
              this.onClose();
            } else {
              this.vueShowNotification(
                'ОШИБКА СТРАНИЦЫ ОПЛАТЫ, ПОПРОБУЙТЕ ПОЗЖЕ',
                '<p></p>',
              );
              this.isWaitingSubmit = false;
              this.onClose();
            }
          } else {
            setTimeout(() => {
              this.setWorkflows({ userType: this.getUserType });
              this.isWaitingSubmit = false;
              // TODO возможно, нужно ждать изменения состояния
              this.$nextTick(() => {
                this.vueShowNotification(
                  'ПРЕДЛОЖЕНИЯ ПО ИЗМЕНЕНИЯМ ОТПРАВЛЕНЫ',
                  '',
                );
              });
              this.onClose();
            }, 2000);
          }
        }
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      try {
        if (this.isDisabled) {
          this.submitError = '';
          this.isWaitingSubmit = true;
          await this.openPaymentUrl();
        } else {

          if (this.isChecked) {
            this.openSignatureModal();
          } else {
            this.submitError = '';
            this.isWaitingSubmit = true;
            const documentReviewXml = connectionApplicationData.documentReviewXml(
              this.offerDocument.id,
              'document-rejected',
              this.reviewComments,
              [],
              {},
            );

            await this.documentUpload({ xml: documentReviewXml });

            setTimeout(() => {
              this.setWorkflows({ userType: this.getUserType });
              this.isWaitingSubmit = false;
              // TODO возможно, нужно ждать изменения состояния
              this.$nextTick(() => {
                this.vueShowNotification(
                  'ПРЕДЛОЖЕНИЯ ПО ИЗМЕНЕНИЯМ ОТПРАВЛЕНЫ',
                  '',
                );
              });
              this.onClose();
            }, 2000);
          }
        }
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async openPaymentUrl() {
      const paymentUrl = await this.createPaymentUrl({ params: { invoiceId: this.invoiceId } });
      window.open(paymentUrl, '_self');
    },
    onClose() {
      this.statusGroup = undefined;
      this.tariff = undefined;
      this.$emit('close');
    },
    statusGroupClick(val) {
      this.tariff = undefined;
      this.statusGroup = val;
    },
    tariffGroupClick(val) {
      this.tariff = val;
    },
    handleRemoveCorrectionDocs(file) {
      this.correctionDocs = this.correctionDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveAgreementTechnologicalConnectionDocs(file) {
      this.agreementTechnologicalConnectionDocs = this.agreementTechnologicalConnectionDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveTechnicalConditionsDocs(file) {
      this.technicalConditionsDocs = this.technicalConditionsDocs
        .filter((scan) => scan.source !== file.source);
    },
    ...mapActions('document', ['documentUpload', 'createFixturePayment']),
    ...mapActions('cabinet', ['setWorkflows', 'createPaymentUrl']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.title
  padding-bottom: 16px
  +base-title

.commentsTitle, .commentsTitle p
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 18px
  margin-bottom: 8px

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.blockContent
  margin-bottom: 40px

.statusGroup
  margin-top: 40px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.accompanyingTitle
  margin-bottom: 8px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.accompanyingText p
  margin-bottom: 8px

.accompanyingText
  margin-bottom: 40px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.actionContent
  width: 560px
  margin: 0 auto
</style>
