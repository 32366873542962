// TODO вынести общие типы
const electricityUsagePointAdditionExcelPattern = {
  rowNumberStart: 6,
  ikts: { colIndex: 19, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. ID ПУ', col: 18 },
  supplyPointName: {
    mainSubstation: { colIndex: 2, rules: 'required|maxLength1024', title: 'Наименование центра питания *', col: 2 },
    mainSubstationNumber: { colIndex: 6, rules: 'required|maxLength1024', title: '№ ПЦ *', col: 6 },
    switchgear: { colIndex: 3, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 3 },
    feeder: { colIndex: 7, rules: 'maxLength1024', title: '№ фидера', col: 7 },
    distributionSubstation: { colIndex: 8, rules: 'maxLength1024', title: '№ РП', col: 8 },
    line: { colIndex: 9, rules: 'maxLength1024', title: '№ Линии', col: 9 },
    transformationSubstation: { colIndex: 10, rules: 'maxLength1024', title: '№ ТП', col: 10 },
    other: { colIndex: 11, rules: 'maxLength1024', title: 'иное', col: 11 },
    borderName: { colIndex: 5, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 5 },
    feederCell: { colIndex: 4, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 4 },
  },
  delimitationAct: {
    number: { colIndex: 12, rules: 'maxLength255', title: '№ и дата акта разграничения. Номер', col: 12 },
    date: { colIndex: 13, rules: 'date|lessCurrent', title: '№ и дата акта разграничения. Дата', col: 12 },
  },
  meterPlace: { colIndex: 16, rules: 'maxLength255', title: 'Место установки прибора учёта *', col: 15 },
  voltageLevel: {
    colIndex: 17,
    rules: 'number',
    title: 'Уровень (класс) напряжения. Фактический, кВ *',
    col: 16,
  },
  tariffVoltageLevel: {
    colIndex: 18,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен) *',
    col: 17,
  },
  meter: {
    type: {
      colIndex: 20,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Тип *',
      col: 19,
    },
    serial: {
      colIndex: 24,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта *',
      col: 21,
    },
    accuracyClass: {
      colIndex: 23,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности *',
      col: 20,
    },
    ratedCurrent: {
      colIndex: 22,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. I ном. *',
      col: 19,
    },
    nominalVoltage: {
      colIndex: 21,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Uном. *',
      col: 19,
    },
    calibrationDate: {
      colIndex: 25,
      rules: 'date|lessCurrent',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 22,
    },
    calibrationInterval: {
      colIndex: 26,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет *',
      col: 23,
    },
    nextCalibrationDate: {
      colIndex: 27,
      rules: 'date',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 24,
    },
  },
  currentTransformer: {
    type: { colIndex: 28, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы тока. Тип', col: 25 },
    transformationRatio: { colIndex: 30, rules: 'requiredGroup|ktNumber', title: 'Трансформаторы тока. Ктт', col: 26 },
    accuracyClass: {
      colIndex: 29,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы тока. Класс точности',
      col: 25,
    },
    calibrationDate: { colIndex: 31, rules: 'date|lessCurrent', title: 'Трансформаторы тока. Дата поверки', col: 27 },
    calibrationInterval: {
      colIndex: 32,
      rules: 'number',
      title: 'Трансформаторы тока. Межповерочный интервал, лет',
      col: 28,
    },
  },
  potentialTransformer: {
    type: { colIndex: 33, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы напряжения. Тип', col: 29 },
    transformationRatio: {
      colIndex: 35,
      rules: 'requiredGroup|ktNumber',
      title: 'Трансформаторы напряжения. Ктн',
      col: 30,
    },
    accuracyClass: {
      colIndex: 34,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы напряжения. Класс точности',
      col: 29,
    },
    calibrationDate: {
      colIndex: 36,
      rules: 'date|lessCurrent',
      title: 'Трансформаторы напряжения. Дата поверки',
      col: 31,
    },
    calibrationInterval: {
      colIndex: 37,
      rules: 'number',
      title: 'Трансформаторы напряжения. Межповерочный интервал, лет',
      col: 32,
    },
  },
  accountingRatio: { colIndex: 38, rules: 'required|number|accountingRatio', title: 'Расчётный коэффициент *', col: 33 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 40,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 35,
    },
    variable: {
      colIndex: 39,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 34,
    },
  },
  kind: {
    colIndex: 41,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный) *',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 36,
  },
  metersOwner: {
    colIndex: 42,
    rules: 'required|allowedValues',
    title: 'Собственник средств учёта (И1/И2 - Исполнитель, ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, П - потребитель) *',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['И1', 'И2', 'И-1', 'И-2', 'ССО', 'ПЭ', 'П'],
    col: 37,
  },
  measurementsReadingKind: {
    colIndex: 44,
    rules: 'allowedValues',
    title: 'Расчётный способ (А - по показаниям: автоматический ввод через ИСУ, Р - по показаниям: ручной ввод, По мощности, По переменному расходу, По постоянному расходу, По факту прошлого периода) *',
    listName: 'required|measurementsReadingKindList',
    allowedValues: ['А', 'Р', 'По мощности', 'По постоянному расходу', 'По переменному расходу', 'По факту прошлого периода'],
    col: 39,
  },
  maxPower: {
    colIndex: 43,
    rules: 'number',
    title: 'Максимальная мощность, МВт',
    col: 38,
  },
  sourceContractInfo: {
    number: {
      colIndex: 46,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 40,
    },
    date: {
      colIndex: 47,
      rules: 'date|lessCurrent',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 41,
    },
  },

};

// Приложение 1 МЭС-ОЭК-ТСО
export const electricityUsagePointAdditionInputExcelPattern = {
  ...electricityUsagePointAdditionExcelPattern, ...{
    supplier: {
      name: {
        colIndex: 14,
        rules: 'required|maxLength255',
        title: 'Наименование смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица) *',
        col: 13,
      },
      inn: {
        colIndex: 15,
        rules: 'required|inn',
        title: 'ИНН смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица) *',
        col: 14,
      },
    },
  },
};

// Приложение 2 МЭС-ОЭК-ТСО
export const electricityUsagePointAdditionOutputExcelPattern = {
  ...electricityUsagePointAdditionExcelPattern, ...{
    consumer: {
      name: {
        colIndex: 14,
        rules: 'required|maxLength255',
        title: 'Наименование смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица)',
        col: 13,
      },
      inn: {
        colIndex: 15,
        rules: 'required|inn',
        title: 'ИНН смежного субъекта розничного рынка (ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, иные лица)',
        col: 14,
      },
    },
  },
};

export const electricityConsumersAdditionExcelPattern = {
  rowNumberStart: 5,
  consumer: {
    name: { colIndex: 1, rules: 'required|maxLength255', title: 'Наименование потребителя *', col: 2 },
    inn: { colIndex: 2, rules: 'inn', title: 'ИНН потребителя', col: 3 },
  },
  serviceLocation: {
    colIndex: 3,
    rules: 'required',
    title: 'Адрес местоположения энергопринимающих устройств *',
    col: 4,
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 4,
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора. Номер',
      col: 5,
    },
    date: {
      colIndex: 5,
      rules: 'date|lessCurrent',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора. Дата',
      col: 5,
    },
    objectNumber: { colIndex: 6, rules: 'required', title: 'Сведения о договоре энергоснабжения. № объекта *', col: 6 },
    startsDate: {
      colIndex: 7,
      rules: 'date',
      title: 'Сведения о договоре энергоснабжения. Дата начала снабжения электрической энергией',
      col: 7,
    },
  },
  connectionOptions: {
    delimitationAct: {
      number: { colIndex: 8, rules: 'maxLength255', title: 'Номер и дата Акта разграничения. Номер', col: 8 },
      date: { colIndex: 9, rules: 'date|lessCurrent', title: 'Номер и дата Акта разграничения. Дата', col: 8 },
    },
    connectionAct: {
      number: {
        colIndex: 10,
        rules: 'maxLength255',
        title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Номер',
        col: 9,
      },
      date: {
        colIndex: 11,
        rules: 'date|lessCurrent',
        title: 'Номер и дата Акта о технологическом присоединении (разрешение на присоединение мощности). Дата',
        col: 9,
      },
    },
    reliabilityClass: {
      colIndex: 12,
      rules: 'required|allowedValues',
      listName: 'reliabilityClassListWithShortenedNames',
      allowedValues: ['I', 'II', 'III'],
      title: 'Категория надёжности электроснабжения *',
      col: 10,
    },
    maxPower: {
      colIndex: 13,
      rules: 'number',
      title: 'Величина максимальной мощности, МВт',
      col: 11,
    },
    emergencyArmorValue: { colIndex: 14 },
    technicalArmorValue: { colIndex: 15 },
    remark: { colIndex: 16 },
  },
  sourceContractInfo: {
    number: {
      colIndex: 17,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 15,
    },
    date: {
      colIndex: 18,
      rules: 'date|lessCurrent',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 15,
    },
  },
};

// Приложение 3 МЭС-ОЭК-ТСО
export const electricitySupplyPointAdditionAdditionExcelPattern = {
  rowNumberStart: 6,
  consumer: {
    name: {
      colIndex: 1, rules: 'required|maxLength255', title: 'Наименование потребителя *', col: 2,
    },
    inn: {
      colIndex: 2, rules: 'inn', title: 'ИНН потребителя', col: 3,
    },
  },
  serviceLocation: {
    colIndex: 3,
    rules: 'required',
    title: 'Адрес местоположения энергопринимающего устройства *',
    col: 4,
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 4,
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Номер',
      col: 5,
    },
    date: {
      colIndex: 5,
      rules: 'date|lessCurrent',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Дата',
      col: 5,
    },
    objectNumber: { colIndex: 6, rules: 'required', title: 'Сведения о договоре энергоснабжения. № объекта *', col: 6 },
    startsDate: {
      colIndex: 7,
      rules: 'date',
      title: 'Сведения о договоре энергоснабжения. Дата начала снабжения электрической энергией',
      col: 7,
    },
  },
  supplyPointName: {
    mainSubstation: {
      colIndex: 8,
      rules: 'required|maxLength1024',
      title: 'Наименование точки поставки электрической энергии. Наименование питающего центра (ПС, РП, ТП) *',
      col: 8,
    },
    borderName: { colIndex: 9 },
    connectionName: { colIndex: 10 },
    mainSubstationNumber: {
      colIndex: 11,
      rules: 'required|maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № ПЦ *',
      col: 11,
    },
    feeder: {
      colIndex: 12,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № фидера',
      col: 12,
    },
    distributionSubstation: {
      colIndex: 13,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № РП',
      col: 13,
    },
    line: {
      colIndex: 14,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № Линии',
      col: 14,
    },
    transformationSubstation: {
      colIndex: 15,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № ТП',
      col: 15,
    },
    other: {
      colIndex: 16,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. иное',
      col: 16,
    },
  },
  ikts: { colIndex: 17 },
  meterPlace: { colIndex: 18, rules: 'maxLength255', title: 'Место установки прибора учёта', col: 18 },
  voltageLevel: {
    colIndex: 19,
    rules: 'number',
    title: 'Уровень (класс) напряжения. Фактический, кВ',
    col: 19,
  },
  tariffVoltageLevel: {
    colIndex: 20,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен) *',
    col: 20,
  },
  meter: {
    type: {
      colIndex: 22,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Тип *',
      col: 22,
    },
    serial: {
      colIndex: 26,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта *',
      col: 24,
    },
    accuracyClass: {
      colIndex: 25,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности *',
      col: 23,
    },
    ratedCurrent: {
      colIndex: 24,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. I ном. *',
      col: 22,
    },
    nominalVoltage: {
      colIndex: 23,
      ruled: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Uном. *',
      col: 22,
    },
    calibrationDate: {
      colIndex: 27,
      rules: 'date',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 25,
    },
    calibrationInterval: {
      colIndex: 28,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет',
      col: 26,
    },
    nextCalibrationDate: {
      colIndex: 29,
      rules: 'date',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 27,
    },
  },
  currentTransformer: {
    type: { colIndex: 30, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы тока. Тип', col: 28 },
    transformationRatio: { colIndex: 32, rules: 'requiredGroup|ktNumber', title: 'Трансформаторы тока. Ктт', col: 29 },
    accuracyClass: {
      colIndex: 31,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы тока. Класс точности',
      col: 28,
    },
    calibrationDate: {
      colIndex: 33,
      rules: 'date',
      title: 'Трансформаторы тока. Дата поверки',
      col: 30,
      notRequiredInGroup: true,
    },
    calibrationInterval: {
      colIndex: 34,
      rules: 'number',
      title: 'Трансформаторы тока. Межповерочный интервал, лет',
      col: 31,
    },
  },
  potentialTransformer: {
    type: { colIndex: 35, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы напряжения. Тип', col: 32 },
    transformationRatio: {
      colIndex: 37,
      rules: 'requiredGroup|ktNumber',
      title: 'Трансформаторы напряжения. Ктн',
      col: 33,
    },
    accuracyClass: {
      colIndex: 36,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы напряжения. Класс точности',
      col: 32,
    },
    calibrationDate: {
      colIndex: 38,
      rules: 'date',
      title: 'Трансформаторы напряжения. Дата поверки',
      col: 34,
      notRequiredInGroup: true,
    },
    calibrationInterval: {
      colIndex: 39,
      rules: 'number',
      title: 'Трансформаторы напряжения. Межповерочный интервал, лет',
      col: 35,
    },
  },
  accountingRatio: { colIndex: 40, rules: 'required|number|accountingRatio', title: 'Расчётный коэффициент *', col: 36 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 42,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 38,
    },
    variable: {
      colIndex: 41,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 37,
    },
  },
  kind: {
    colIndex: 43,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный) *',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 39,
  },
  metersOwner: {
    colIndex: 44,
    rules: 'allowedValues',
    title: 'Собственник средств учёта (И1/И2 - Исполнитель, ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, П - потребитель) *',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['И1', 'И2', 'И-1', 'И-2', 'ССО', 'ПЭ', 'П'],
    col: 40,
  },
  measurementsReadingKind: {
    colIndex: 45,
    rules: 'required|allowedValues',
    title: 'Расчётный способ (А - по показаниям: автоматический ввод через ИСУ, Р - по показаниям: ручной ввод, По мощности, По переменному расходу, По постоянному расходу, По факту прошлого периода) *',
    listName: 'measurementsReadingKindList',
    allowedValues: ['А', 'Р', 'По мощности', 'По постоянному расходу', 'По переменному расходу', 'По факту прошлого периода'],
    col: 41,
  },
  sourceContractInfo: {
    number: {
      colIndex: 48,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 44,
    },
    date: {
      colIndex: 49,
      rules: 'date|lessCurrent',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 44,
    },
  },
};

// Приложение 3.1 МЭС-ОЭК-ТСО
export const electricityUsagePointForRelatedAdditionExcelPattern = {
  rowNumberStart: 4,
  consumer: {
    name: {
      colIndex: 1, rules: 'required|maxLength255', title: 'Наименование смежного субъекта розничного рынка *', col: 2,
    },
  },
  serviceLocation: {
    colIndex: 2,
    rules: 'required',
    title: 'Адрес местоположения энергопринимающих устройств *',
    col: 3,
  },
  relatedOrganizationKind: {
    colIndex: 3,
    rules: 'allowedValues',
    listName: 'relatedOrganizationList',
    allowedValues: ['Потребитель РРЭ', 'Потребитель ОРЭ', 'ГП', 'ЭСО', 'ПЭЭ'],
    title: 'Категория смежного субъекта оптового или розничного рынка',
    col: 4,
  },
  supplyPointName: {
    mainSubstation: {
      colIndex: 4,
      rules: 'required|maxLength1024',
      title: 'Наименование точки поставки электрической энергии. Наименование центра питания',
      col: 5,
    },
    mainSubstationNumber: {
      colIndex: 4,
      rules: 'required|maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № ПЦ *',
      col: 5,
    },
    feeder: {
      colIndex: 5,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № фидера',
      col: 6,
    },
    distributionSubstation: {
      colIndex: 6,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № РП',
      col: 7,
    },
    line: {
      colIndex: 7,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № Линии',
      col: 8,
    },
    transformationSubstation: {
      colIndex: 8,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. № ТП',
      col: 9,
    },
    other: {
      colIndex: 9,
      rules: 'maxLength1024',
      title: 'Наименование точки поставки электрической энергии. иное',
      col: 10,
    },
  },
  delimitationAct: {
    number: { colIndex: 10, rules: 'maxLength255', title: 'Номер и дата Акта разграничения. Номер', col: 11 },
    date: { colIndex: 11, rules: 'date|lessCurrent', title: 'Номер и дата Акта разграничения. Дата', col: 11 },
  },
  meterPlace: { colIndex: 12, rules: 'required', title: 'Место установки прибора учёта', col: 12 },
  voltageLevel: {
    colIndex: 13,
    rules: 'number',
    title: 'Уровень (класс) напряжения. Фактический, кВ',
    col: 13,
  },
  tariffVoltageLevel: {
    colIndex: 14,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен) *',
    col: 14,
  },
  meter: {
    id: {
      colIndex: 15,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. ID ПУ *',
      col: 15,
    },
    type: {
      colIndex: 16,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Тип *',
      col: 18,
    },
    serial: {
      colIndex: 20,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта *',
      col: 18,
    },
    accuracyClass: {
      colIndex: 19,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности *',
      col: 17,
    },
    ratedCurrent: {
      colIndex: 18,
      rules: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. I ном. *',
      col: 16,
    },
    nominalVoltage: {
      colIndex: 17,
      ruled: 'requiredGroup|maxLength255',
      title: 'Прибор учёта электрической энергии. Uном. *',
      col: 16,
    },
    calibrationDate: {
      colIndex: 21,
      rules: 'date|lessCurrent',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 19,
    },
    calibrationInterval: {
      colIndex: 22,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет *',
      col: 20,
    },
    nextCalibrationDate: {
      colIndex: 23,
      rules: 'date',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 21,
    },
  },
  currentTransformer: {
    type: { colIndex: 24, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы тока. Тип', col: 22 },
    transformationRatio: { colIndex: 26, rules: 'requiredGroup|ktNumber', title: 'Трансформаторы тока. Ктт', col: 23 },
    accuracyClass: {
      colIndex: 25,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы тока. Класс точности',
      col: 22,
    },
    calibrationDate: { colIndex: 27, rules: 'date|lessCurrent', title: 'Трансформаторы тока. Дата поверки', col: 24 },
    calibrationInterval: {
      colIndex: 28,
      rules: 'number',
      title: 'Трансформаторы тока. Межповерочный интервал, лет',
      col: 25,
    },
  },
  potentialTransformer: {
    type: { colIndex: 29, rules: 'requiredGroup|maxLength255', title: 'Трансформаторы напряжения. Тип', col: 26 },
    transformationRatio: {
      colIndex: 31,
      rules: 'requiredGroup|ktNumber',
      title: 'Трансформаторы напряжения. Ктн',
      col: 27,
    },
    accuracyClass: {
      colIndex: 30,
      rules: 'requiredGroup|maxLength255',
      title: 'Трансформаторы напряжения. Класс точности',
      col: 26,
    },
    calibrationDate: {
      colIndex: 32,
      rules: 'date|lessCurrent',
      title: 'Трансформаторы напряжения. Дата поверки',
      col: 28,
    },
    calibrationInterval: {
      colIndex: 33,
      rules: 'number',
      title: 'Трансформаторы напряжения. Межповерочный интервал, лет',
      col: 29,
    },
  },
  accountingRatio: { colIndex: 34, rules: 'required|number|accountingRatio', title: 'Расчётный коэффициент *', col: 30 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 36,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 32,
    },
    variable: {
      colIndex: 35,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 31,
    },
  },
  kind: {
    colIndex: 37,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный) *',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 33,
  },
  metersOwner: {
    colIndex: 38,
    rules: 'allowedValues',
    title: 'Собственник средств учёта (И1/И2 - Исполнитель, ССО - смежная сетевая организация, ПЭ - производитель электрической энергии, П - потребитель)',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['И1', 'И2', 'И-1', 'И-2', 'ССО', 'ПЭ', 'П'],
    col: 34,
  },
  maxPower: {
    colIndex: 39,
    rules: 'number',
    title: 'Величина максимальной мощности, МВт',
    col: 35,
  },
  measurementsReadingKind: {
    colIndex: 40,
    rules: 'required|allowedValues',
    title: 'Расчётный способ (А - по показаниям: автоматический ввод через ИСУ, Р - по показаниям: ручной ввод, По мощности, По переменному расходу, По постоянному расходу, По факту прошлого периода) *',
    listName: 'measurementsReadingKindList',
    allowedValues: ['А', 'Р', 'По мощности', 'По постоянному расходу', 'По переменному расходу', 'По факту прошлого периода'],
    col: 36,
  },
  sourceContractInfo: {
    number: {
      colIndex: 42,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 38,
    },
    date: {
      colIndex: 43,
      rules: 'date|lessCurrent',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 38,
    },
  },
};
