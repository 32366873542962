<template>
  <modal v-if="isModalVisible"
         @close="close"
  >
    <template #body>
      <div class="modal-progress-bar__body">
        <div class="modal-progress-bar-wrp">
          <progress-bar
            :is-complete="isComplete"
            :space-counter="8"
            width="550px"
            @update-fake-time-left="updateFakeTimeLeftHandler"
            @is-hundred-percent="checkComplete"
          />
        </div>

        <span class="modal-progress-bar__body__text">
          {{ timeText }}
        </span>

        <span class="modal-progress-bar__body__sub-text">
          {{ subText }}
        </span>
      </div>

    </template>
    <template #footer>
      <v-button
        class="modal-progress-bar__button"
        variant="filled--no-radius"
        @click="close"
      >
        ОК
      </v-button>
    </template>
  </modal>
</template>

<script>
import { VButton } from '@/components';
import Modal from '@/components/Modal/Modal';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

export default {
  name: 'ModalProgressBar',
  components: {
    Modal,
    ProgressBar,
    VButton,
  },
  props: {
    subText: {
      type: String,
      default: 'Если закрыть окно, скачивание продолжится в фоновом режиме',
    },
    calculateText: {
      type: String,
      default: 'Просчитываем время скачивания',
    },
    leftText: {
      type: Object,
      default: () => ({
        text: 'Осталось меньше минут',
        charIndexToPasteMinutes: 15,
      }),
    },
    completeText: {
      type: String,
      default: 'Скачивание завершено',
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.progressState = this.progressStates[0];
  },
  data() {
    return {
      progressStates: [
        'calculate',
        'left',
        'complete',
      ],
      progressState: null,
      minutesLeft: null,
    };
  },
  computed: {
    timeText() {
      const timeText = {
        calculate: this.calculateText,
        left: `${this.leftText.text.slice(0, this.leftText.charIndexToPasteMinutes)
        } ${this.minutesLeft}${
          this.leftText.text.slice(this.leftText.charIndexToPasteMinutes)}`,
        complete: this.completeText,
      };

      return timeText[this.progressState];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    updateFakeTimeLeftHandler(minutes) {
      this.progressState = this.progressStates[1];
      this.minutesLeft = minutes;
    },
    checkComplete(percent) {
      this.progressState = this.progressStates[2];
      this.$emit('is-hundred-percent', percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-progress-bar {
  &-wrp {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 100%;
    border-radius: 0;
    max-width: unset;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__text {
      margin-top: 8px;
      text-align: center;
      font-weight: 400;
      font-size: 19px;
      line-height: 23px;
    }

    &__sub-text {
      margin-top: 20px;
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
</style>
