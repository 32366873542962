<template>
  <page title="График платежей">
    <content-block :payment-schedule="paymentSchedule" :loading="loading" />
  </page>
</template>

<script>
import { Page } from '@/components';
import ContentBlock from './components/ContentBlock.vue';
import { mapActions } from 'vuex';

export default {
  name: 'PaymentScheduleIndex',
  components: { ContentBlock, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      paymentSchedule: [],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.paymentSchedule = await this.getContractPayments();
      } catch (error) {
        console.log('paymentSchedule', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('cabinet', ['getContractPayments']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped lang="scss">

</style>
