import { apolloClient, apolloClientFiles } from '@/api/graphql';
import {
  CREATE_BORDER_DEVICE_ACT,
  CREATE_BORDER_FLOW_ACT,
  CREATE_BOTH_BORDER_ACTS,
  CREATE_FIXTURE,
  CREATE_FIXTURE_PAYMENT,
  CREATE_METER_REPORT,
  DELETE_DRAFT,
  DOCUMENT_CANCEL,
  DOCUMENT_SUBMIT,
  GENERATE_BALANCE,
  GENERATE_LOSS_ACT,
  GENERATE_SERVICE_ACT,
  GENERATE_USEFUL_VACATION,
  GENERATE_USEFUL_VACATION_FOR_DEPARTMENT,
  GET_PERSON_IN_CASE,
  GET_WORD_IN_CASE,
  MANY_DOCUMENT_SUBMIT,
  REQUEST_SIGNATURE_CODE,
  SAVE_DRAFT,
  SIGN_DOCUMENT_WITH_SIMPLE_SIGNATURE,
} from '@/api/graphql/mutations';
import {
  ACTUAL_TARIFFS_BY_REGION,
  ADMISSION_CERTIFICATES,
  CONNECTION_APPLICATION_CONTRACTS,
  CONNECTION_CERTIFICATES,
  DRAFT_BY_ID,
  DRAFTS_BY_FILTER,
  ELECTRICITY_SUPPLY_CONTRACTS,
  GET_BASIC_ELECTRICITY_TRANSMISSION_CONTRACT_BY_ID,
  GET_BASIC_TRANSMISSION_CONTRACT_BY_ID,
  GET_BASIC_TRANSMISSION_CONTRACTS,
  GET_DOCUMENT,
  GET_DOCUMENT_VIEW,
  GET_DOCUMENT_VIEW_BY_DOCUMENT_ID,
  GET_DOCUMENT_VIEWS,
  GET_DOCUMENT_VIEWS_FILTER,
  GET_DOMAIN_DOCUMENT_BY_DOCUMENT_ID,
  GET_DOMAIN_DOCUMENT_BY_ID,
  GET_EIGHTEENTH_FORMS,
  GET_ELECTRICITY_TRANSMISSION_CONTRACTS_BY_TYPE,
  GET_INTEGRAL_ACT_FILE,
  GET_INTEGRAL_ACTS,
  GET_SOURCE_CONTRACT_PARTIES,
  GET_TRANSMISSION_CONTRACT_BY_ID,
  GET_TRANSMISSION_CONTRACTS,
  GET_USEFUL_VACATIONS,
  IS_NEED_VERIFICATION_ACT,
  IS_TRANSMISSION_CONTRACT_SIGNED,
  MEASUREMENTS_STATEMENTS,
  REPORTS,
  SAVE_URL,
  SHOW_URL,
  TARIFFS_BY_ID,
  UTILITY_INDIVIDUAL_TARIFF,
} from '@/api/graphql/queries';
// TODO remove local package crypto-pro
import { createDetachedSignature, createHash, getUserCertificates, isValidSystemSetup } from 'crypto-pro';
// import {SignedXml} from 'xml-crypto';
// TODO remove EVENT_BUS from project
import { EVENT_BUS } from '@/eventBus';
import { submitDocument, submitSignedDocument } from '@/api/rest/utm';
import axios from 'axios';
import baseApi from '@/api/axios/baseApi';

const CRYPTOPRO_ERROR = new Error('На вашем компьютере отсутствует установленное ПО КриптоПро или неверно указаны сертификаты. ' +
  'Установите необходимое ПО и проверьте валидность сертификатов');
const CRYPTOPRO_CERTIFICATES_ERROR = new Error('На вашем компьютере отсутствуют валидные сертификаты. Проверьте валидность сертификатов');
CRYPTOPRO_CERTIFICATES_ERROR.name = 'CRYPTOPRO_CERTIFICATES_ERROR';
CRYPTOPRO_ERROR.name = 'CRYPTOPRO_ERROR';

async function selectCertificate(store, payload) {

  console.info('Selecting certificates...');
  let isValid = false;

  try {
    isValid = await isValidSystemSetup();
  } catch (e) {
    console.log('isValidSystemSetup::catch', e);
    throw CRYPTOPRO_ERROR;
  }

  if (!isValid) {
    throw CRYPTOPRO_ERROR;
  }

  let certificates = [];
  try {
    certificates = await getUserCertificates();
  } catch (e) {
    console.log('getUserCertificates::catch', e);
    throw CRYPTOPRO_ERROR;
  }

  console.info(`Certificates: ${certificates.length}`);

  if (certificates.length === 0) {
    throw CRYPTOPRO_CERTIFICATES_ERROR;
  }

  if (certificates.length === 1) {
    return certificates[0];
  }

  return new Promise((resolve, reject) => {
    EVENT_BUS.$emit('SELECT_CERTIFICATE', {
      payload: payload,
      certificates: certificates,
      resolve: resolve,
      reject: reject,
    });
  });
}

async function capSign(xml, certificate, existingSignature) {
  try {
    return await createDetachedSignature(certificate.thumbprint, await createHash(xml), existingSignature);
  } catch (e) {
    console.log('createXMLSignature::catch', e);
    throw CRYPTOPRO_ERROR;
  }
}

async function documentUploadByUtm(store, payload) {
  if (payload.isCap || payload.isTestCap)
    await submitSignedDocument(payload.xml);
  else await submitDocument(payload.xml);
}

async function signDocument(store, payload) {
  console.log('pl', payload);
  if (payload.certificate) return await capSign(payload.xml, payload.certificate, payload.existingSignature);
  console.info('Certificate wasn\'t provided, will request from user');
  const certificate = await selectCertificate(store, payload);

  console.info(`Was selected ${certificate.name}`);
  if (certificate === undefined) {
    console.info('Certificate wasn\'t provided by user, return');
    return false;
  }
  console.info(`Singing... ${payload.xml}`);
  return await capSign(payload.xml, certificate, payload.existingSignature);
}


async function documentUpload(store, payload) {
  console.info('Start document uploading...');
  try {
    if (process.env.VUE_APP_IS_UTM) {
      await documentUploadByUtm(store, payload);
      return;
    }
    let signature;
    if (payload.isCap) {
      console.info('isCap = true, needs to sign');
      signature = await signDocument(store, payload);
    } else {
      signature = payload.existingSignature;
    }

    console.log('documentUpload xml', payload.xml);
    console.log('documentUpload signature', signature);
    const { data } = await apolloClientFiles.mutate({
      mutation: DOCUMENT_SUBMIT,
      variables: {
        xml: new Blob([payload.xml], { type: 'text/plain' }),
        sourceDocumentId: payload.sourceDocumentId,
        signature: signature?.length ? new Blob([signature], { type: 'text/plain' }) : undefined,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    if (data.documentSubmitWithMeta.status === 'ERROR') {
      return data.documentSubmitWithMeta;
    }
    return true;
  } catch (error) {
    console.log('document upload::catch', error);
    throw error;
  }
}

async function loadDocument(state, documentId) {
  try {
    console.log('loadDocument documentId', documentId);
    const {
      data: { document },
    } = await apolloClient.query({
      query: GET_DOCUMENT,
      variables: { id: documentId },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loaded document', document);
    return document;
  } catch (error) {
    console.log('load document::catch', error);
    throw error;
  }
}

async function loadDocumentView(state, id) {
  try {
    const {
      data: { documentView },
    } = await apolloClient.query({
      query: GET_DOCUMENT_VIEW,
      variables: { id: id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('loadDocumentView', documentView);
    return documentView;
  } catch (error) {
    console.log('loadDocumentView::catch', error);
    throw error;
  }
}

async function getDomainDocument(state, id) {
  try {
    const {
      data: { domainDocument },
    } = await apolloClient.query({
      query: GET_DOMAIN_DOCUMENT_BY_ID,
      variables: { id: id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('getDomainDocument', domainDocument);
    return domainDocument;
  } catch (error) {
    console.log('getDomainDocument::catch', error);
    throw error;
  }
}

async function getDomainDocumentByDocumentId(state, documentId) {
  try {
    const {
      data: { domainDocumentByDocumentId },
    } = await apolloClient.query({
      query: GET_DOMAIN_DOCUMENT_BY_DOCUMENT_ID,
      variables: { documentId: documentId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('getDomainDocumentByDocumentId', domainDocumentByDocumentId);
    return domainDocumentByDocumentId;
  } catch (error) {
    console.log('getDomainDocumentByDocumentId::catch', error);
    throw error;
  }
}

async function getTransmissionContract(state, id) {
  try {
    const {
      data: { transmissionContract },
    } = await apolloClient.query({
      query: GET_TRANSMISSION_CONTRACT_BY_ID,
      variables: { id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('getTransmissionContract', transmissionContract);
    return transmissionContract;
  } catch (error) {
    console.log('getTransmissionContract::catch', error);
    throw error;
  }
}

async function loadDocumentViewByDocumentId(state, documentId) {
  try {
    const {
      data: { documentViewByDocumentId },
    } = await apolloClient.query({
      query: GET_DOCUMENT_VIEW_BY_DOCUMENT_ID,
      variables: { documentId: documentId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('loadDocumentViewByDocumentId', documentViewByDocumentId);
    return documentViewByDocumentId;
  } catch (error) {
    console.log('loadDocumentViewByDocumentId::catch', error);
    throw error;
  }
}

async function loadDocumentViews(state, payload) {
  try {
    const {
      data: { documentViews },
    } = await apolloClient.query({
      query: GET_DOCUMENT_VIEWS,
      variables: {
        sort: {
          field: payload.field,
          direction: payload.direction,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('loadDocumentViews', documentViews);
    return documentViews;
  } catch (error) {
    console.log('loadDocumentViews::catch', error);
    throw error;
  }
}

async function filterDocumentViews(state, payload) {
  try {
    const {
      data: { documentViews },
    } = await apolloClient.query({
      query: GET_DOCUMENT_VIEWS_FILTER,
      variables: {
        filter: {
          documentKind: payload.kind,
          status: payload.status,
          searchPeriod: {
            from: payload.from,
            to: payload.to,
          },
        },
        /* sort: {
          field: 'date',
          direction: 'ASC',
        },*/
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadDocumentViews', documentViews);
    return documentViews;
  } catch (error) {
    console.log('loadDocumentViews::catch', error);
    throw error;
  }
}

async function loadTransmissionContracts(state, payload) {
  try {
    const {
      data: { transmissionContracts },
    } = await apolloClient.query({
      query: GET_TRANSMISSION_CONTRACTS,
      variables: {
        filter: {
          kindBySides: payload.kindBySides,
          kindByExisting: payload.kindByExisting,
          status: payload.status,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadTransmissionContracts', transmissionContracts);
    return transmissionContracts;
  } catch (error) {
    console.log('loadTransmissionContracts::catch', error);
    throw error;
  }
}

async function loadElectricityTransmissionContractsByType(state, payload) {
  try {
    const {
      data: { electricityTransmissionContractsByType },
    } = await apolloClient.query({
      query: GET_ELECTRICITY_TRANSMISSION_CONTRACTS_BY_TYPE,
      variables: {
        contractType: payload.contractType,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadElectricityTransmissionContractsByType', electricityTransmissionContractsByType);
    return electricityTransmissionContractsByType;
  } catch (error) {
    console.log('loadElectricityTransmissionContractsByType::catch', error);
    throw error;
  }
}

async function loadAdmissionCertificates(state) {
  try {
    const {
      data: { admissionCertificates },
    } = await apolloClient.query({
      query: ADMISSION_CERTIFICATES,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    return admissionCertificates;
  } catch (error) {
    console.log('admissionCertificates::catch', error);
    throw error;
  }
}

async function loadConnectionCertificates(state) {
  try {
    const {
      data: { connectionCertificates },
    } = await apolloClient.query({
      query: CONNECTION_CERTIFICATES,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    return connectionCertificates;
  } catch (error) {
    console.log('connectionCertificates::catch', error);
    throw error;
  }
}

async function loadConnectionApplicationContracts(state) {
  try {
    const {
      data: { connectionApplicationContracts },
    } = await apolloClient.query({
      query: CONNECTION_APPLICATION_CONTRACTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('connectionApplicationContracts', connectionApplicationContracts);
    return connectionApplicationContracts;
  } catch (error) {
    console.log('connectionApplicationContracts::catch', error);
    throw error;
  }
}

async function loadElectricitySupplyContracts(state) {
  try {
    const {
      data: { electricitySupplyContracts },
    } = await apolloClient.query({
      query: ELECTRICITY_SUPPLY_CONTRACTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('electricitySupplyContracts', electricitySupplyContracts);
    return electricitySupplyContracts;
  } catch (error) {
    console.log('electricitySupplyContracts::catch', error);
    throw error;
  }
}

async function loadUsefulVacations(state) {
  try {
    const {
      data: { usefulVacations },
    } = await apolloClient.query({
      query: GET_USEFUL_VACATIONS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadUsefulVacations', usefulVacations);
    return usefulVacations;
  } catch (error) {
    console.log('loadUsefulVacations::catch', error);
    throw error;
  }
}

async function loadEighteenthForms(state) {
  try {
    const {
      data: { eighteenthForms },
    } = await apolloClient.query({
      query: GET_EIGHTEENTH_FORMS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadEighteenthForms', eighteenthForms);
    return eighteenthForms;
  } catch (error) {
    console.log('loadEighteenthForms::catch', error);
    throw error;
  }
}

async function loadBasicTransmissionContracts(state, payload) {
  try {
    const {
      data: { transmissionContracts },
    } = await apolloClient.query({
      query: GET_BASIC_TRANSMISSION_CONTRACTS,
      variables: {
        filter: {
          ...payload,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadBasicTransmissionContracts', transmissionContracts);
    return transmissionContracts;
  } catch (error) {
    console.log('loadBasicTransmissionContracts::catch', error);
    throw error;
  }
}

async function loadSourceContractPartiesDetails(state, contractId) {
  try {
    const {
      data: { contractParties },
    } = await apolloClient.query({
      query: GET_SOURCE_CONTRACT_PARTIES,
      variables: {
        contractId: contractId,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadSourceContractPartiesDetails', contractParties);
    return contractParties;
  } catch (error) {
    console.log('loadSourceContractPartiesDetails::catch', error);
    throw error;
  }
}

async function getIntegralActs(state, payload) {
  try {
    const {
      data: { integralActs },
    } = await apolloClient.query({
      query: GET_INTEGRAL_ACTS,
      variables: {},
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadIntegralActs', integralActs, payload);
    return integralActs;
  } catch (error) {
    console.log('loadIntegralActs::catch', error);
    throw error;
  }
}

async function getIntegralActFile(state, payload) {
  try {
    const {
      data: { saveUrl },
    } = await apolloClient.query({
      query: GET_INTEGRAL_ACT_FILE,
      variables: {
        documentId: payload.documentId,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('getIntegralActFile', saveUrl);
    return saveUrl;
  } catch (error) {
    console.log('getIntegralActFile::catch', error);
    throw error;
  }
}

async function createFixturePayment(store, payload) {
  try {
    console.log('create fix payload', payload);
    const { data } = await apolloClient.mutate({
      mutation: CREATE_FIXTURE_PAYMENT,
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    console.log('create fixture::catch', error);
    throw error;
  }
}

async function createFixture(store, payload) {
  try {
    console.log('create fix payload', payload);
    const { data } = await apolloClient.mutate({
      mutation: CREATE_FIXTURE,
      variables: { ...payload },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    console.log('create fixture::catch', error);
    throw error;
  }
}

async function createMeterReport({ rootState }) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_METER_REPORT,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    console.log('create meter report::catch', error);
    throw error;
  }
}

async function createBorderDeviceAct(state, payload) {
  console.log(payload);
  try {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_BORDER_DEVICE_ACT,
      variables: { generateInput: payload.generateInput },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log('create integral acts report::catch', error);
    throw error;
  }
}

async function createBorderFlowAct(state, payload) {
  console.log(payload);
  try {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_BORDER_FLOW_ACT,
      variables: { generateInput: payload.generateInput },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log('create integral acts report::catch', error);
    throw error;
  }
}

async function createBothBorderActs(state, payload) {
  console.log(payload);
  try {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_BOTH_BORDER_ACTS,
      variables: { generateInput: payload.generateInput },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log('create integral acts report::catch', error);
    throw error;
  }
}

async function tariffsById(store, id) {
  try {
    const {
      data: { tariffsById },
    } = await apolloClient.query({
      query: TARIFFS_BY_ID,
      variables: { id: id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    console.log('tariffs', tariffsById);
    return tariffsById;
  } catch (error) {
    console.log('tariffs::catch', error);
    throw error;
  }
}

async function actualTariffsByRegion(store, region) {
  try {
    const {
      data: { actualTariffsByRegion },
    } = await apolloClient.query({
      query: ACTUAL_TARIFFS_BY_REGION,
      variables: { region: region },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    console.log('actualTariffsByRegion', actualTariffsByRegion);
    return actualTariffsByRegion;
  } catch (error) {
    console.log('actualTariffsByRegion::catch', error);
    throw error;
  }
}

async function getDocumentByUrl(store, url) {
  const token = store.rootState.user.token;

  const doc = await axios.request({
    url: url,
    method: 'get',
    responseType: 'blob',
    headers: {
      ['X-Authorization']: `Bearer ${token}`,
    },
  });

  return doc.data;
}

async function getDocumentStatusByUrl(store, url) {
  const token = store.rootState.user.token;

  const doc = await axios.request({
    url: url,
    method: 'POST',
    responseType: 'json',
    headers: {
      ['X-Authorization']: `Bearer ${token}`,
    },
  });

  return doc.data;
}

async function getSaveUrl(store, documentId) {
  try {
    const {
      data: { saveUrl },
    } = await apolloClient.query({
      query: SAVE_URL,
      variables: { documentId: documentId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return saveUrl;
  } catch (error) {
    console.log('saveUrl::catch', error);
    throw error;
  }
}

async function getShowUrl(store, documentId) {
  try {
    const {
      data: { showUrl },
    } = await apolloClient.query({
      query: SHOW_URL,
      variables: { documentId: documentId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    return showUrl;
  } catch (error) {
    console.log('getShowUrl::catch', error);
    throw error;
  }
}

async function generateBalance(state, payload) {
  try {
    console.log(payload.year);
    console.log(payload.month);

    const variables = {
      transmissionContractId: payload.transmissionContractId,
      month: payload.month,
      year: payload.year,
      totalLoss: payload.totalLoss,
      supplierRepresentative: payload.supplierRepresentative,
      performerFirstRepresentative: payload.performerFirstRepresentative,
      performerSecondRepresentative: payload.performerSecondRepresentative,
    };

    const { data } = await apolloClient.mutate({
      mutation: GENERATE_BALANCE,
      variables: variables,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log('generate balance', data);
  } catch (error) {
    console.log('error generate balance::catch', error);
    throw error;
  }
}

async function generateLossAct(state, payload) {
  try {
    console.log(payload.year);
    console.log(payload.month);

    const variables = {
      month: payload.month,
      year: payload.year,
      totalLoss: payload.totalLoss,
      supplierRepresentative: payload.supplierRepresentative,
      performerSecondRepresentative: payload.performerSecondRepresentative,
      balanceId: payload.balanceId,
      volumesByPointStatus: payload.volumesByPointStatus,
    };

    const { data } = await apolloClient.mutate({
      mutation: GENERATE_LOSS_ACT,
      variables: variables,
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log('generateLossAct', data);
  } catch (error) {
    console.log('error generateLossAct::catch', error);
    throw error;
  }
}

async function generateServiceAct(state, payload) {
  try {
    console.log(payload.year);
    console.log(payload.month);

    const variables = {
      month: payload.month,
      year: payload.year,
      totalLoss: payload.totalLoss,
      performerFirstRepresentative: payload.performerFirstRepresentative,
      performerSecondRepresentative: payload.performerSecondRepresentative,
      balanceId: payload.balanceId,
      volumesByPointStatus: payload.volumesByPointStatus,
      actKind: payload.actKind,
    };

    const { data } = await apolloClient.mutate({
      mutation: GENERATE_SERVICE_ACT,
      variables: variables,
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log('generateServiceAct', data);
  } catch (error) {
    console.log('error generateServiceAct::catch', error);
    throw error;
  }
}

async function documentCancel(store, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: DOCUMENT_CANCEL,
      variables: { documentId: payload.documentId },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });
    console.log(data);
    return true;
  } catch (error) {
    console.log('documentCancel::catch', error);
    throw error;
  }
}

async function submitManyDocuments(store, payload) {
  try {
    if (process.env.VUE_APP_IS_UTM) {
      payload.xmls.forEach((document) => {
        submitDocument(document);
      });
      return true;
    }
    console.log('submitManyDocuments xmls', payload.xmls);
    const blobs = payload.xmls.map((it) => new Blob([it], { type: 'application/xml' }));
    const { data } = await apolloClientFiles.mutate({
      mutation: MANY_DOCUMENT_SUBMIT,
      variables: { xmls: blobs },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.submitManyDocuments;
  } catch (error) {
    console.log('submitManyDocuments upload::catch', error);
    throw error;
  }
}

async function loadBasicTransmissionContractById(state, id) {
  try {
    const {
      data: { transmissionContract },
    } = await apolloClient.query({
      query: GET_BASIC_TRANSMISSION_CONTRACT_BY_ID,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadTransmissionContract', transmissionContract);
    return transmissionContract;
  } catch (error) {
    console.log('loadTransmissionContract::catch', error);
    throw error;
  }
}

async function loadBasicElectricityTransmissionContractById(state, id) {
  try {
    const {
      data: { electricityTransmissionContract },
    } = await apolloClient.query({
      query: GET_BASIC_ELECTRICITY_TRANSMISSION_CONTRACT_BY_ID,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('loadBasicElectricityTransmissionContractById', electricityTransmissionContract);
    return electricityTransmissionContract;
  } catch (error) {
    console.log('loadBasicElectricityTransmissionContractById::catch', error);
    throw error;
  }
}

async function loadMeasurementsStatements(store, kind) {
  try {
    const {
      data: { measurementsStatements },
    } = await apolloClient.query({
      query: MEASUREMENTS_STATEMENTS,
      variables: { kind: kind },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${store.rootState.user.token}`,
        },
      },
    });

    console.log('loadMeasurementsStatements', measurementsStatements);
    return measurementsStatements;
  } catch (error) {
    console.log('loadMeasurementsStatements::catch', error);
    throw error;
  }
}

async function isNeedVerificationAct(state, payload) {
  try {
    const { data: { isNeedVerificationAct } } = await apolloClient.query({
      query: IS_NEED_VERIFICATION_ACT,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    return isNeedVerificationAct;
  } catch (error) {
    console.log('isNeedVerificationAct::catch', error);
    throw error;
  }
}

async function isTransmissionContractSigned(state, payload) {
  try {
    const { data: { isTransmissionContractSigned } } = await apolloClient.query({
      query: IS_TRANSMISSION_CONTRACT_SIGNED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('isTransmissionContractSigned', isTransmissionContractSigned);
    return isTransmissionContractSigned;
  } catch (error) {
    console.log('isTransmissionContractSigned::catch', error);
    throw error;
  }
}

async function getPersonInCaseAction(state, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: GET_PERSON_IN_CASE,
      variables: {
        fio: payload.fio,
        inCase: payload.inCase,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.getPersonInCase;
  } catch (error) {
    console.log('get person in case::catch', error);
    throw error;
  }
}

async function getWordInCaseAction(state, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: GET_WORD_IN_CASE,
      variables: {
        word: payload.word,
        inCase: payload.inCase,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.getWordInCase;
  } catch (error) {
    console.log('get word in case::catch', error);
    throw error;
  }
}

// GENERATE USEFUL_VACATION
async function generateUsefulVacation(state, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: GENERATE_USEFUL_VACATION,
      variables: {
        consumerId: payload.consumerId,
        month: payload.month,
        year: payload.year,
      },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    return data;
  } catch (error) {
    console.log('generate useful vacation ERROR: ', error);
    throw error;
  }
}

async function loadUtilityIndividualTariff(state) {
  try {
    const {
      data: { utilityIndividualTariffAll },
    } = await apolloClient.query({
      query: UTILITY_INDIVIDUAL_TARIFF,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('utilityIndividualTariffAll', utilityIndividualTariffAll);
    return utilityIndividualTariffAll;
  } catch (error) {
    console.log('utilityIndividualTariff::catch', error);
    throw error;
  }
}

async function requestSignatureCode(state) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: REQUEST_SIGNATURE_CODE,
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.requestSignatureCode;
  } catch (error) {
    console.log('requestSignatureCode::catch', error);
    throw error;
  }
}

async function signDocumentWithSimpleSignature(state, payload) {
  try {
    const { data } = await apolloClientFiles.mutate({
      mutation: SIGN_DOCUMENT_WITH_SIMPLE_SIGNATURE,
      variables: {
        xml: new Blob([payload.xml], { type: 'application/xml' }),
        code: payload.code,
      },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
    return data.signDocumentWithSimpleSignature;
  } catch (error) {
    console.log('signDocumentWithSimpleSignature::catch', error);
    throw error;
  }
}

async function saveDraft(state, payload) {
  try {
    const variables = {
      xml: new Blob([payload.xml], { type: 'text/plain' }),
      title: payload.title,
    };

    if (payload.id) {
      variables.id = payload.id;
    }

    if (payload.documentType) {
      variables.documentType = payload.documentType;
    }

    const { data } = await apolloClientFiles.mutate({
      mutation: SAVE_DRAFT,
      variables: {
        draftInput: variables,
      },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('save draft errors', error.graphQLErrors);
      },
    });

    console.log('save draft', data, payload);

    return data.saveDraft.id;
  } catch (error) {
    console.log('saveDraft::catch', error);
    throw error;
  }
}

async function loadDrafts(state, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: DRAFTS_BY_FILTER,
      variables: {
        draftFilter: { ...payload },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('loadDrafts', data.draftsByFilter, payload);

    return data.draftsByFilter;
  } catch (error) {
    console.log('loadDrafts::catch', error);
    throw error;
  }
}

async function loadDraftById(state, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: DRAFT_BY_ID,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('loadDraftById', data.draft);

    return data.draft;
  } catch (error) {
    console.log('loadDraftById::catch', error);
    throw error;
  }
}

async function deleteDraft(state, payload) {
  try {
    await apolloClient.mutate({
      mutation: DELETE_DRAFT,
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('deleteDraft errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('deleteDraft::catch', error);
    throw error;
  }
}

async function createConnectionApplicationFixture(state, payload) {
  try {
    await apolloClient.mutate({
      mutation: CREATE_FIXTURE,
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('createConnectionApplicationFixture errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    console.log('createConnectionApplicationFixture::catch', error);
    throw error;
  }
}

async function generateUsefulVacationForDepartment(state, payload) {
  try {
    const { data } = await apolloClient.mutate({
      mutation: GENERATE_USEFUL_VACATION_FOR_DEPARTMENT,
      variables: {
        ...payload,
      },
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    return data;
  } catch (error) {
    console.log('generateUsefulVacationForDepartment ERROR: ', error);
    throw error;
  }
}

async function saveReportToServer(state, { file, fileName }) {
  try {
    const formData = new FormData();
    formData.append('file', file, fileName);
    await baseApi.post('/documents/report', formData);
  } catch (error) {
    console.log('saveReport::catch', error);
    throw error;
  }
}

async function getReportsFromServer(state) {
  try {
    const { data } = await apolloClient.query({
      query: REPORTS,
      context: {
        headers: {
          Authorization: `Bearer ${state.rootState.user.token}`,
        },
      },
      fetchPolicy: 'no-cache',
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    return data.reports;
  } catch (error) {
    console.log('getReportsFromServer ERROR: ', error);
    throw error;
  }
}

async function getReportFromServer(state, { id }) {
  try {
    return (await baseApi.get(`/documents/report/${id}`, {
      responseType: 'blob',
    })).data;
  } catch (error) {
    console.log('getReport::catch', error);
    throw error;
  }
}

export default {
  documentUpload,
  loadDocument,
  loadDocumentViews,
  loadDocumentView,
  loadDocumentViewByDocumentId,
  createFixture,
  tariffsById,
  actualTariffsByRegion,
  filterDocumentViews,
  getDocumentByUrl,
  createMeterReport,
  createBorderDeviceAct,
  createBothBorderActs,
  createBorderFlowAct,
  generateBalance,
  getSaveUrl,
  getShowUrl,
  getIntegralActs,
  getIntegralActFile,
  documentCancel,
  loadTransmissionContracts,
  loadBasicTransmissionContracts,
  loadSourceContractPartiesDetails,
  submitManyDocuments,
  getDocumentStatusByUrl,
  loadBasicTransmissionContractById,
  getDomainDocument,
  getTransmissionContract,
  loadMeasurementsStatements,
  loadElectricityTransmissionContractsByType,
  loadUsefulVacations,
  loadBasicElectricityTransmissionContractById,
  loadEighteenthForms,
  isNeedVerificationAct,
  isTransmissionContractSigned,
  loadUtilityIndividualTariff,
  getPersonInCaseAction,
  getWordInCaseAction,
  requestSignatureCode,
  signDocumentWithSimpleSignature,
  generateServiceAct,
  generateLossAct,
  createFixturePayment,
  generateUsefulVacation,
  saveDraft,
  loadDrafts,
  loadDraftById,
  deleteDraft,
  getDomainDocumentByDocumentId,
  loadElectricitySupplyContracts,
  loadConnectionApplicationContracts,
  createConnectionApplicationFixture,
  signDocument,
  selectCertificate,
  loadAdmissionCertificates,
  loadConnectionCertificates,
  generateUsefulVacationForDepartment,
  saveReportToServer,
  getReportsFromServer,
  getReportFromServer,
};
