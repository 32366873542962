<template>
  <page title="Карточка ОДК">
    <data-tab-selector
      :data-types-list="tabs"
      tab-style="table"
      @selectTab="selectTabHandler"
    />

    <exploitation-data v-if="selectedTab === 'exploitation'" />
    <exploitation-defects v-else-if="selectedTab === 'defects'" />
    <exploitation-photos v-else-if="selectedTab === 'photos'" />
    <exploitation-history v-else-if="selectedTab === 'history'" />
    <exploitation-objects v-else-if="selectedTab === 'objects'" />
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import ExploitationData from 'views/private/Exploitation/Card/ExploitationData.vue';
import ExploitationDefects from 'views/private/Exploitation/Card/ExploitationDefects.vue';
import ExploitationPhotos from 'views/private/Exploitation/Card/ExploitationPhotos.vue';
import ExploitationHistory from 'views/private/Exploitation/Card/ExploitationHistory.vue';
import ExploitationObjects from 'views/private/Exploitation/Card/ExploitationObjects.vue';

export default {
  name: 'ExploitationCard',
  components: {
    ExploitationPhotos,
    Page,
    DataTabSelector,
    ExploitationData,
    ExploitationDefects,
    ExploitationHistory,
    ExploitationObjects,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'Эксплуатация',
          value: 'exploitation',
          isActive: true,
        },
        {
          id: 2,
          title: 'Список дефектов',
          value: 'defects',
          isActive: false,
        },
        {
          id: 3,
          title: 'Фото',
          value: 'photos',
          isActive: false,
        },
        {
          id: 4,
          title: 'История',
          value: 'history',
          isActive: false,
        },
        {
          id: 5,
          title: 'Объекты',
          value: 'objects',
          isActive: false,
        },
      ],
      selectedTab: 'exploitation',
    };
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>
