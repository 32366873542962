<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Формирование проекта накладной</div>
          </v-card-title>
          <v-card-text>
            <simple-autocomplete-by-function
              ref="operationType"
              v-model="operationType"
              :fetch-data-function="getWarehouseOperationTypeByName"
              :fetch-default-data-function="getWarehouseOperationTypeByName"
              :search.sync="operationTypeName"
              is-required
              title="Тип накладной"
            ></simple-autocomplete-by-function>
            <process2 v-if="operationType === consignmentTypesConstant.EXTRADITE_FROM_WAREHOUSE" ref="extradite"
                      v-model="data"/>
            <process6
              v-else-if="operationType === consignmentTypesConstant.ACCEPTANCE_TO_WAREHOUSE || operationType === consignmentTypesConstant.BUY_CONSTRUCTION"
              ref="acceptance" v-model="data"
              disable-edit-by-contract disable-without-recalcultion/>
            <process7 v-else-if="operationType === consignmentTypesConstant.RECALCULATION" ref="recalculation"
                      v-model="data"/>
            <process8 v-else-if="operationType === consignmentTypesConstant.DEFECTOSCOPE" ref="defectoscope"
                      v-model="data" with-table/>
            <process9 v-else-if="operationType === consignmentTypesConstant.DECOMISSION" ref="decomission"
                      v-model="data" with-table/>
            <process101 v-else-if="operationType === consignmentTypesConstant.TO_REPAIR" ref="toRepair"
                        v-model="data" with-table/>
            <process102 v-else-if="operationType === consignmentTypesConstant.FROM_REPAIR" ref="fromRepair"
                        v-model="data" with-table/>
            <warehouse-date-picker
              ref="date"
              v-model="date"
              allow-future
              is-required
              label="Дата накладной"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { getSurnameAndInitials } from 'utils/user';
import WarehouseDatePicker from 'components/Processes/components/WarehouseDatePicker.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import { consignmentTypesConstant, consignmentTypesValueList } from 'components/Processes/list';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import Process2 from 'components/Processes/WarehouseForms/Process6.vue';
import Process6 from 'components/Processes/WarehouseForms/Process15-1.vue';
import Process8 from 'components/Processes/WarehouseForms/Process16-1.vue';
import Process7 from 'components/Processes/WarehouseForms/Process15-2.vue';
import Process9 from 'components/Processes/WarehouseForms/Process16-2.vue';
import Process101 from 'components/Processes/WarehouseForms/Process16-3.vue';
import Process102 from 'components/Processes/WarehouseForms/Process16-4.vue';

export default {
  components: {
    Process102,
    Process101,
    Process9,
    Process7,
    Process8,
    Process6,
    Process2,
    SimpleAutocompleteByFunction,
    WarehouseDatePicker,
    Consignment,
  },
  data: () => ({
    data: {
      axpQuantity: '',
      countAxp: '',
      quantityPlanAxpForEvent: null,
      countAxpInStrock: null,
      countAxpIssued: null,

      formElements: [],
      debt: [],

      eventId: null,
      warehouseId: null,
      contractorId: null,
      adreskaId: null,
      axpId: null,

      warehouseName: '',
      contractorName: '',
      axpName: '',
      adreskaAddress: '',
      eventName: '',

      isDisabled: true,
    },


    date: null,
    operationType: null,
    operationTypeName: '',

    progressValue: 0,
    loaderDialog: false,

    dialogForViewReport: false,
    dataReportCustom: {},
  }),

  computed: {
    consignmentTypesConstant() {
      return consignmentTypesConstant;
    },
    consignmentTypesValueListFiltered() {
      return consignmentTypesValueList.filter((it) => it.id !== consignmentTypesConstant.ACCOUNTING_BALANCES);
    },
    isDisabledSaveDialog() {
      const isDisabledDate = this.date == null;
      return (
        this.data.isDisabled ||
        isDisabledDate
      );
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },

  watch: {
    operationType: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (oldVal === newVal) return;
          this.data = {
            axpQuantity: '',
            countAxp: '',
            quantityPlanAxpForEvent: null,
            countAxpInStrock: null,
            countAxpIssued: null,

            formElements: [],
            debt: [],

            eventId: null,
            warehouseId: this.$route.params.warehouseId ? String(this.$route.params.warehouseId) : this.$route.params.warehouseId,
            contractorId: null,
            adreskaId: null,
            axpId: this.$route.params.axpId ? String(this.$route.params.axpId) : this.$route.params.axpId,
            contractId: null,

            warehouseName: this.$route.params.warehouseName ?? '',
            contractorName: '',
            axpName: this.$route.params.axpName,
            adreskaAddress: '',
            eventName: '',

            warehouses: this.$route.params.warehouseId ? [{
              id: String(this.$route.params.warehouseId),
              name: this.$route.params.warehouseName,
            }] : [],

            axpItems: this.$route.params.axpId ? [{
              id: String(this.$route.params.axpId),
              name: this.$route.params.axpName,
            }] : [],

            isDisabled: true,
            isByContract: newVal === consignmentTypesConstant.BUY_CONSTRUCTION,
          };
          console.log({ ...this.data });
      },
    },
  },
  methods: {
    getWarehouseOperationTypeByName(name) {
      if (!name?.length) return this.consignmentTypesValueListFiltered;
      return this.consignmentTypesValueListFiltered.filter((it) => it.name.toLowerCase().includes(name.toLowerCase()));
    },
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        countAxp: this.data.countAxp,
        warehouseId: this.data.warehouseId,
        contractorId: this.data.contractorId,
        contractId: this.data.contractId,
        adreskaId: this.data.adreskaId,
        eventId: this.data.eventId,
        axpId: this.data.axpId,
        type: this.operationType,
        isWithRecalculation: this.data.isWithRecalculation,
        elements: [],
        createDate: this.date,
      };

      this.data.formElements.forEach((f) => {
        if (Number(f.realCount) > 0) {
          request.elements.push({
            id: f.elementId,
            count: f.realCount ?? 0,
          });
        }
      });

      axios
        .post(`${BASE_URL}/plan-consignment`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.date = null;
      this.operationType = null;

      this.progressValue = 0;
      this.loaderDialog = false;

      this.dataReportCustom = {};
      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));
    },

    onTapViewReport() {
      const els = this.data.formElements.filter((element) => Number(element.realCount) > 0).map((element) => ({
        axpName: this.data.axpName,
        date: formatDateFromOdkTimestamp(this.date),
        type: this.operationTypeName,
        contractorName: this.data.contractorName,
        warehouseName: this.data.warehouseName,

        contract: this.data.contractName,
        photos: [],
        elementName: element.elementName,
        unitName: element.unitName,
        count: element.realCount ?? 0,
        employee: getSurnameAndInitials(this.getUser.fullName),
        eventName: this.data.eventName,
        adreskaName: this.data.adreskaAddress,
        plan: true,
      }));

      this.dataReportCustom = els[0] ?? {
        axpName: this.data.axpName,
        type: this.operationTypeName,
        contractorName: this.data.contractorName,
        warehouseName: this.data.warehouseName,
        date: formatDateFromOdkTimestamp(this.date),
        contract: this.data.contractName,
        plan: true,
        employee: getSurnameAndInitials(this.getUser.fullName),
        eventName: this.data.eventName,
        adreskaName: this.data.adreskaAddress,
      };
      this.dataReportCustom.elements = els;


      this.dialogForViewReport = true;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

