import { EVENT_BUS } from '@/eventBus';

export function onShownNotification(title, message, button, width) {
  EVENT_BUS.$emit('NOTIFICATION_LISTENER', {
    data: {
      title,
      message,
      button,
    },
    width: width || 700,
  });
}

export function checkSuccessfullyPdfOpen(url) {
  const handle = window.open(url);
  if (!handle) {
    onShownNotification(
      'Не удалось открыть документ',
      '<p>В настройках браузера дайте разрешение на открытие ссылок в новой вкладке</p>',
    );
  }
}
