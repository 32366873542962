import { render, staticRenderFns } from "./UsagePointConnection.vue?vue&type=template&id=884ea1ae&scoped=true"
import script from "./UsagePointConnection.vue?vue&type=script&lang=js"
export * from "./UsagePointConnection.vue?vue&type=script&lang=js"
import style0 from "./UsagePointConnection.vue?vue&type=style&index=0&id=884ea1ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "884ea1ae",
  null
  
)

export default component.exports