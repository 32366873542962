<template>
  <div :class="$style.navigationBar">
    <button
        v-if="isDisplayBack"
        :class="$style.btnBack"
        @click="back"
    >
      Назад
    </button>
    <div v-for="(step, idx) in stepsNavigation" :key="'step_' + idx"
         :class="{
        [$style.firstStep]: idx === 0 && stepsNavigation.length !== 1,
        [$style.lastStep]: idx === stepsNavigation.length - 1 && stepsNavigation.length !== 1,
        [$style.oneStep]: stepsNavigation.length === 1,
        [$style.currentStep]: step.stepNumber.value === currentStep + 1,
        [$style.step]: step.stepNumber.value < currentStep + 1,
        [$style.nextStep]: step.stepNumber.value > currentStep + 1
      }"
         @click="openStep(step.stepNumber.value)"
    >
      <span :class="$style.text">{{ step.stepNumber.title(contractExisting) }}</span>
      <div :class="$style.arrow"></div>
      <div :class="$style.arrow2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  props: {
    steps: Array,
    contractExisting: String,
    currentStep: Number,
    home: {
      type: String,
      default: '',
    },
    isDisplayBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stepsNavigation() {
      if (this.home.length > 0) {
        return [
          {
            stepNumber: {
              value: -1,
              title: () => this.home,
            },
          },
          ...this.steps,
        ];
      }
      return this.steps;
    },
  },
  methods: {
    openStep(index) {
      this.$emit('open', index);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="sass" module>

.navigationBar
  display: flex
  flex-flow: row wrap
  position: relative
  width: 100%
  background-color: #FFFFFF
  z-index: 999
  +title-small-uppercase
  margin: -8px 16px 0 0
  padding-right: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px

  .step, .nextStep, .currentStep
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 12px
    line-height: 14px
    text-transform: none
    height: 24px
    display: flex
    align-items: center
    justify-items: center
    padding: 0 16px 0 16px
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.15)
    white-space: nowrap
    margin-bottom: 8px
    position: relative

    &:before
      content: " "
      position: absolute
      left: 0
      border: 12px solid transparent
      transform: rotate(270deg)
      z-index: 1003

    &:after
      content: " "
      width: 17px
      height: 18px
      border: 1px solid rgba(0, 0, 0, 0.15)
      border-width: 1px 1px 0 0
      transform: translateX(-8px) rotate(45deg)
      position: absolute
      left: 0
      z-index: 1003

    .text
      transform: translateX(5px)

    .arrow
      width: 0
      height: 0
      border-top: 12px solid transparent
      border-bottom: 12px solid transparent
      position: absolute
      right: -12px
      z-index: 1000

    .arrow2
      width: 17px
      height: 18px
      border: 1px solid rgba(0, 0, 0, 0.15)
      border-width: 1px 1px 0 0
      transform: translateX(-7px) rotate(45deg)
      position: absolute
      right: -16px
      z-index: 1001

  .step
    cursor: pointer
    color: #71757A
    background-color: #F5F6F6

    .arrow
      border-left: 12px solid #F5F6F6

  .nextStep
    cursor: default
    color: #4C4E51
    background-color: #FFFFFF

    .arrow
      border-left: 12px solid #FFFFFF

  .currentStep
    cursor: default
    background-color: #2F82DF
    color: #FFFFFF

    .arrow
      border-left: 12px solid #2F82DF

  .firstStep
    border-radius: 12px 0 0 12px

    &:before, &:after
      display: none

  .lastStep
    border-radius: 0 12px 12px 0

  .oneStep
    border-radius: 12px 12px 12px 12px

  .lastStep, .oneStep
    .arrow, .arrow2
      display: none

  .btnBack
    width: 86px
    height: 24px
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 12px
    color: #677379
    padding-left: 12px
    margin-right: 24px
    background-image: url('~icons/navigation/arrow_left.svg')
    background-repeat: no-repeat
    background-position: center left 4px
    background-color: #FFFFFF
    border: 1px solid #C1C4C7
    border-radius: 24px

    &:hover
      background-color: #F5F6F6
</style>
