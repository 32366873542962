import CabinetTest from 'views/private/CabinetTest.vue';

import { VUE_APP_IS_SHOW_TEST_PAGES } from '@/constants/env';

export const checkField = (field) => {
  if (!field) {
    return '';
  }
  return field;
};

export const getAllVueRoutes = (routes) => {
  const allRoutes = [];
  routes.forEach((route) => {
    allRoutes.push(route);
    if (route.children && route.children.length > 0) {
      const childRoutes = getAllVueRoutes(route.children);
      allRoutes.push(...childRoutes);
    }
  });
  return allRoutes;
};

export const generateTestCabinetRoute = (accessMode) => {
  const isEnvWithTestPage = VUE_APP_IS_SHOW_TEST_PAGES;
  if (!isEnvWithTestPage) return;

  if (accessMode === 'private') {
    return {
      path: 'test',
      name: 'cabinet.test',
      component: CabinetTest,
      meta: { title: 'Тест' },
    };
  }

  if (accessMode === 'public') {
    return {
      path: '/test',
      name: 'cabinet.test',
      component: CabinetTest,
      meta: { title: 'Тест' },
    };
  }

};
