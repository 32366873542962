export const esoOekRmr1_14Patterns = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  serviceLocation: {
    colIndex: 11,
    rules: 'required',
    title: 'Точки поставки (место расположения границы балансовой принадлежности)',
    col: 11,
  },
  consumer: {
    name: {
      colIndex: 12,
      title: 'Наименование потребителя',
      rules: 'required',
      col: 12,
    },
    inn: {
      colIndex: 13,
      title: 'ИНН потребителя',
      rules: 'required|inn',
      col: 13,
    },
  },
  meterPlace: { colIndex: 14, rules: 'required', title: 'Место установки прибора учёта', col: 14 },
  voltageLevel: { colIndex: 15, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 15 },
  tariffVoltageLevel: {
    colIndex: 16,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 16,
  },
  meter: {
    rules: 'required',
    id: { colIndex: 17, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. ID ПУ', col: 17 },
    type: { colIndex: 18, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. Тип', col: 18 },
    serial: {
      colIndex: 22,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта',
      col: 22,
    },
    accuracyClass: {
      colIndex: 21,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности',
      col: 21,
    },
    ratedCurrent: { colIndex: 20, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. I ном.', col: 20 },
    nominalVoltage: { colIndex: 19, ruled: 'maxLength255', title: 'Прибор учёта электрической энергии. Uном.', col: 19 },
  },
  accountingRatio: { colIndex: 23, rules: 'required|number', title: 'Расчётный коэффициент', col: 23 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 25,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 25,
    },
    variable: {
      colIndex: 24,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 24,
    },
  },
  kind: {
    colIndex: 26,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный)',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 26,
  },
  remarks: { colIndex: 27 },
  sourceContractInfo: {
    number: {
      colIndex: 28,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 28,
    },
    date: {
      colIndex: 29,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 29,
    },
  },
};

export const esoOekRmr2_24Patterns = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  serviceLocation: {
    colIndex: 11,
    rules: 'required',
    title: 'Точки поставки (место расположения границы балансовой принадлежности)',
    col: 11,
  },
  consumer: {
    name: {
      colIndex: 12,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 12,
    },
    inn: {
      colIndex: 13,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 13,
    },
  },
  delimitationAct: {
    number: { colIndex: 14, rules: 'required|maxLength255', title: 'Номер и дата Акта разграничения. Номер', col: 14 },
    date: { colIndex: 15, rules: 'required|date', title: 'Номер и дата Акта разграничения. Дата', col: 15 },
  },
  electricitySupplyContractInfo: {
    number: {
      colIndex: 16,
      rules: 'required',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Номер',
      col: 16,
    },
    date: {
      colIndex: 17,
      rules: 'required|date',
      title: 'Сведения о договоре энергоснабжения. Номер и дата договора (для бытовых потребителей - № лицевого счета). Дата',
      col: 17,
    },
  },
  electricityPurchaseSaleContractInfo: {
    number: {
      colIndex: 18,
      rules: 'required',
      title: 'Сведения о договоре купли-продажи электроэнергии. Номер договора',
      col: 18,
    },
    date: {
      colIndex: 19,
      rules: 'required|date',
      title: 'Сведения о договоре купли-продажи электроэнергии. Дата договора',
      col: 19,
    },
  },
  voltageLevel: { colIndex: 20, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 20 },
  tariffVoltageLevel: {
    colIndex: 21,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 21,
  },
  reliabilityClass: {
    colIndex: 22,
    rules: 'required|allowedValues',
    listName: 'reliabilityClassListWithShortenedNames',
    allowedValues: ['I', 'II', 'III'],
    title: 'Категория надёжности электроснабжения',
    col: 22,
  },
  maxPower: { colIndex: 23, rules: 'required|number', title: 'Величина максимальной мощности, МВт', col: 23 },
  maxPowerForPoints: {
    colIndex: 24,
    rules: 'number',
    title: 'Величина максимальной мощности по каждой точке присоединения/поставки, МВт',
    col: 24,
  },
  remarks: { colIndex: 25 },
  sourceContractInfo: {
    number: {
      colIndex: 26,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 26,
    },
    date: {
      colIndex: 27,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 27,
    },
  },
};

export const esoOekRmr4_44Patterns = {
  rowNumberStart: 4,
  supplyPointName: {
    switchgear: { colIndex: 2, rules: 'maxLength1024', title: 'Наименование РУ питающего центра (ПС, РП, ТП)', col: 2 },
    feederCell: { colIndex: 3, rules: 'maxLength1024', title: '№ ячейки (фидера)', col: 3 },
    borderName: { colIndex: 4, rules: 'maxLength1024', title: 'Наименование границы субъекта в Точке приема', col: 4 },
    mainSubstationNumber: { colIndex: 5, rules: 'required|maxLength1024', title: '№ ПЦ', col: 5 },
    feeder: { colIndex: 6, rules: 'maxLength1024', title: '№ фидера', col: 6 },
    distributionSubstation: { colIndex: 7, rules: 'maxLength1024', title: '№ РП', col: 7 },
    line: { colIndex: 8, rules: 'maxLength1024', title: '№ Линии', col: 8 },
    transformationSubstation: { colIndex: 9, rules: 'maxLength1024', title: '№ ТП', col: 9 },
    other: { colIndex: 10, rules: 'maxLength1024', title: 'иное', col: 10 },
  },
  consumer: {
    name: {
      colIndex: 11,
      rules: 'required|maxLength255',
      title: 'Наименование потребителя',
      col: 11,
    },
    inn: {
      colIndex: 12,
      rules: 'required|inn',
      title: 'ИНН потребителя',
      col: 12,
    },
  },
  meterPlace: { colIndex: 13, rules: 'required', title: 'Место установки прибора учёта', col: 13 },
  voltageLevel: { colIndex: 14, rules: 'required|number', title: 'Уровень (класс) напряжения. Фактический, кВ', col: 14 },
  tariffVoltageLevel: {
    colIndex: 15,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень (класс) напряжения. Используемый для применения тарифов (цен)',
    col: 15,
  },
  meter: {
    rules: 'required',
    id: { colIndex: 16, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. ID ПУ', col: 16 },
    type: { colIndex: 17, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. Тип', col: 17 },
    serial: {
      colIndex: 21,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Номер прибора учёта',
      col: 21,
    },
    accuracyClass: {
      colIndex: 20,
      rules: 'maxLength255',
      title: 'Прибор учёта электрической энергии. Класс точности',
      col: 20,
    },
    ratedCurrent: { colIndex: 19, rules: 'maxLength255', title: 'Прибор учёта электрической энергии. I ном.', col: 19 },
    nominalVoltage: { colIndex: 18, ruled: 'maxLength255', title: 'Прибор учёта электрической энергии. Uном.', col: 18 },
    calibrationDate: {
      colIndex: 22,
      rules: 'date|lessCurrent',
      title: 'Прибор учёта электрической энергии. Дата поверки',
      col: 22,
    },
    calibrationInterval: {
      colIndex: 23,
      rules: 'number',
      title: 'Прибор учёта электрической энергии. Межповерочный интервал, лет',
      col: 23,
    },
    nextCalibrationDate: {
      colIndex: 24,
      rules: 'date|nextCalibrationDate',
      title: 'Прибор учёта электрической энергии. Дата очередной поверки',
      col: 24,
    },
  },
  currentTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 25, rules: 'maxLength255', title: 'Трансформаторы тока. Тип', col: 25 },
    transformationRatio: { colIndex: 27, rules: 'ktNumber', title: 'Трансформаторы тока. Ктт', col: 27 },
    accuracyClass: { colIndex: 26, rules: 'maxLength255', title: 'Трансформаторы тока. Класс точности', col: 26 },
  },
  potentialTransformer: {
    rules: 'requiredGroup',
    type: { colIndex: 28, rules: 'maxLength255', title: 'Трансформаторы напряжения. Тип', col: 28 },
    transformationRatio: { colIndex: 30, rules: 'ktNumber', title: 'Трансформаторы напряжения. Ктн', col: 30 },
    accuracyClass: { colIndex: 29, rules: 'maxLength255', title: 'Трансформаторы напряжения. Класс точности', col: 29 },
  },
  accountingRatio: { colIndex: 31, rules: 'required|number', title: 'Расчётный коэффициент', col: 31 },
  lossFactor: {
    rules: 'number',
    const: {
      colIndex: 33,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Постоянные, кВтч',
      col: 33,
    },
    variable: {
      colIndex: 32,
      title: 'Потери электроэнергии, если прибор учёта установлен не на границе. Переменные %',
      col: 32,
    },
  },
  kind: {
    colIndex: 34,
    rules: 'required|allowedValues',
    title: 'Тип учёта (Р-расчетный, К-контрольный)',
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
    col: 34,
  },
  sourceContractInfo: {
    number: {
      colIndex: 35,
      rules: 'maxLength255',
      title: 'Номер договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 35,
    },
    date: {
      colIndex: 36,
      rules: 'date',
      title: 'Дата договора/дополнительного соглашения, которым была включена точка в данный договор',
      col: 36,
    },
  },
};
