<template>
  <div :class="$style.container">
    <div v-if="reviewDocument.comments" :class="$style.reviewDocument">
      <div :class="$style.title">
        Устраните замечания по вашей заявке
      </div>
      <p v-html="comments"></p>
    </div>
    <div v-if="reviewDocument.files" :class="$style.reviewDocumentFiles">
      <p v-for="(file, index) in reviewDocument.files" :key="index" style="display: flex; justify-content: space-between">
        <a :href="fileUrl(file)" :role="'reviewFile[' + index + '].name'" :download="file.name" :class="$style.reviewFileName">{{ file.name }}</a>
        <a :href="fileUrl(file)" :role="'reviewFile[' + index + '].download'" :download="file.name" :class="$style.reviewFileDownload">
          <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
          скачать</a>

      </p>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';

export default {
  name: 'CorrectionApplication',
  props: {
    documentId: String,
    applicationData: Object,
    reviewDocument: Object,
    isDisplay: Boolean,
  },
  data() {
    return {
      dataDownloaded: false,
    };
  },
  computed: {
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '«', '»');
    },
  },
  watch: {
    /* applicationData: {
      immediate: true,
      async handler(val) {
        await connectionApplicationData.save(val);
        this.dataDownloaded = true;
      },
    },*/
    async isDisplay(val) {
      if (val) {
        this.onClose();
        await this.$router.push({
          name: 'cabinet.connection-application',
          params: {
            connectionApplication: this.applicationData,
            sourceDocumentId: this.documentId,
          },
        });
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
  },
};
</script>

<style lang="sass" module>
  .title
    font-family: 'Roboto Condensed'
    font-size: 24px
    line-height: 32px
    letter-spacing: 0.005em
    color: #000

  .reviewDocument
    padding-bottom: 24px

  .reviewDocumentFiles p a
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 16px
    color: #000
    text-decoration: underline

  .reviewDocument, .reviewDocumentFiles
    width: 100%
    max-width: 560px
    margin: 0 auto

  .reviewDocument p
    margin-top: 16px
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px

  .reviewFileName
    width: 400px
    margin-bottom: 16px

  .reviewFileDownload
    text-decoration: none
    margin-right: 16px

  .actionContent
    width: 560px
    margin: 0 auto
</style>
