<template>
  <div class="table">
    <v-tooltip
      v-if="tooltipHeader && ((tooltipHeader.tooltip && isMatchMinScreenWidthTooltip) ||
        (isUnconditionalWidthForTooltip && tooltipHeader.label.indexOf(' ') !== -1))"
      :content="tooltipHeader.label"
      :custom-style="tooltipHeader.tooltip?.customStyle"
      position="bottom"
      :position-x="tooltipX"
      :position-y="tooltipY"
      :window-inner-width="windowInnerWidth"
    />
    <v-table-filter
      v-if="isFiltered||isActions"
      :action-block-items="actionBlockItems"
      :filter-fields="filterFields"
      :filter-fields-key="filterFieldsKey"
      :filter-values="filterValues"
      :filter-values-end="filterValuesEnd"
      :is-filtered="isFiltered"
      :show-filters-block="showFiltersBlock"
      @change="changeFilter"
      @clear="clear"
      @search="search"
    />
    <div class="table__bar">
      <!-- dont delete this div -->
      <div></div>
      <div class="table__bar--tabs">
        <v-table-tabs
          v-if="template.subGroups"
          v-model="subGroupIndex"
          :sub-groups="template.subGroups"
          variant="filled"
          @input="onTabClickHandler"
        />
      </div>
    </div>

    <v-spoiler-left :details-style="spoilerDetailsStyle" :hide="hideSpoiler" :summary="title ?? ''">
      <template #button-right>
        <v-button v-if="isDisplayMapOption" class="table__column-hide-map table__spoiler-button" type="button"
                  variant="outlined"
                  @click="swapMode()">
          <span class="table__spoiler-button-label">Карта</span>
          <img alt="Карта" src="~@/assets/icons/map/map-point.svg" width="16" height="16" />
        </v-button>
        <v-button v-if="isDisplayColumnHideSettings" class="table__column-hide-settings table__spoiler-button" type="button"
                  variant="outlined"
                  @click="openColumnHideSettings()">
          <span class="table__spoiler-button-label">Настройка отображения столбцов</span>
          <img alt="Настройка отображения столбцов" src="~@/assets/icons/basic/settings.svg"/>
        </v-button>
        <v-button v-if="!hideExport" class="table__download-excel table__spoiler-button" type="button" variant="outlined"
                  @click="saveReport">
          <span class="table__spoiler-button-label">Скачать как .XLSX</span>
          <img alt="Скачать как .XLS" src="~@/assets/icons/file/download_action.svg"/>
        </v-button>
      </template>
      <div v-if="mode===tableModes.TABLE"
           :class="{'table__table-wo-scroll-wrp': hideOverflow, 'table__table-scroll-wrp': !hideOverflow}"
           :style="styleDivHeight"
      >
        <table class="table__table" :style="tableStyle">
          <!-- headers block begin -->
          <thead class="table__table-head-sticky">
          <v-table-th
            v-for="header in allHeadHeaders"
            :key="header.label"
            :header="header"
            :select-all-is-true="selectAllIsTrue"
            :sort-state="sortState"
            :window-inner-width="windowInnerWidth"
            :width-for-truncated="widthForTruncated"
            @filter="(val) => changeFilter(val.field, val.value)"
            @on-sort-column="onSortColumnHandler"
            @on-th-click="onThClickHandler"
            @select-all="(val) => $emit('select-all', val)"
            @labelMouseover="labelMouseover"
            @labelMouseout="labelMouseout"
          />
          </thead>
          <!-- headers block end -->
          <!-- body table begin -->
          <tbody>
          <!-- tr begin -->
          <v-table-tr v-for="(internalValueItem, index) in internalValue"
                      v-show="isShowTableRow(internalValueItem.isShow)"
                      :key="`${internalValueItem.id}_${index}`"
                      :divider-show="dividerShow"
                      :headers="allHeaders"
                      :is-nested="internalValueItem.isNested"
                      :is-nested-show="internalValueItem.isSpoilerButtonShow"
                      :next-row="internalValue[index + 1]"
                      :row="internalValueItem"
                      @delete="onCellDeleteHandler"
                      @edit="onCellEditHandler"
                      @click.native="$emit('on-row-click', internalValueItem)"
                      @on-cell-click="onCellClickHandler"
                      @on-cell-input="onCellInputHandler"
                      @on-spoiler-button-click="onSpoilerButtonClickHandler"
          />
          <!-- tr end -->
          </tbody>
          <!-- body table end -->
        </table>
      </div>

      <div v-else-if="mode===tableModes.MAP"
           :class="{'table__table-wo-scroll-wrp': hideOverflow, 'table__table-scroll-wrp': !hideOverflow}">
        <yandex-map
          ref="customMap"
          :collection-axp="indexedValue"
          enable-routing
          @tapPlacemark="tapPlacemark"
        />
      </div>

      <div v-if="loading" class="table__loader">
        <v-circle-loader/>
      </div>
      <v-table-pagination
        v-if="paginationShow && mode!==tableModes.MAP"
        :table-name="tableName"
        :total-elements="totalElements"
        custom-style="padding: 0 13px;"
        @change="paginationChange"
      />
    </v-spoiler-left>
    <modal v-if="isShowColumnHideSettings"
           :body-style="{
            padding: '0',
           }"
           :is-btn-close-visible="false"
           :modal-style="{
            maxWidth: '500px',
            padding: '0',
            borderRadius: '15px',
           }"
           :modal-wrapper-style="{
              'z-index': '10083'
           }"
           @close="isShowColumnHideSettings = false"
    >
      <template #header>
        <div class="table__settings-modal__title">
          <div>Настройка отображения столбцов</div>
          <img alt="Настройка отображения столбцов" class="table__settings-modal__title__img"
               src="~@/assets/icons/basic/settings.svg"/>
        </div>
      </template>
      <template #body>
        <div>
          <input
            v-model="searchColumnName"
            class="table__settings-modal__search"
            placeholder="Поиск"
          />
        </div>
        <div class="table__settings-modal__header">
          <edit-mode-button
            :is-true="selectedAllCurrent"
            select-all
            title="Выбрать всё"
            @click="selectAllCurrentHandler"
          />
        </div>
        <div class="table__settings-modal__list">
          <div v-for="item in headersForHiddenSettingsFiltered" :key="item.key"
               class="table__settings-modal__list__item" @click="hideColumn(item.key, item.colspan)">
            <img
              v-show="!updatedHideColumns.includes(item.key)"
              alt="Отобразить"
              class="table__settings-modal__list__img"
              src="@/assets/icons/checkmark-grey.svg"/>
            <span :style="updatedHideColumns.includes(item.key) ? 'margin-left: 20px' : ''">{{
                getColumnName(item)
              }}</span>
          </div>
        </div>
        <div class="table__settings-modal__action">
          <v-button variant="outlined" @click="isShowColumnHideSettings = false">Отмена</v-button>
          <v-button variant="filled" @click="updateHideColumns">Применить</v-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { VTableFilter, VTablePagination, VTableTabs, VTableTh, VTableTr } from './common';
import { VButton } from '@/components';

import { nanoid } from 'nanoid';
import VCircleLoader from 'components/VCircleLoader/VCircleLoader';
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VSpoilerLeft from '@/components/VSpoilerLeft/VSpoilerLeft';
import { exportToExcel } from 'utils/excel';
import Modal from '@/components/Modal/Modal';
import EditModeButton from '@/components/EditModeButton/EditModeButton';
import { VTableMode } from 'components/VTableNew/constants';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import VTooltip from '@/components/VTooltip/VTooltip.vue';

export default {
  name: 'VTableNew',
  components: {
    YandexMap,
    VTableFilter,
    VCircleLoader,
    VTableTabs,
    VTablePagination,
    VTableTh,
    VTableTr,
    VButton,
    VSpoilerLeft,
    Modal,
    EditModeButton,
    VTooltip,
  },
  props: {
    serverSideSorting: { type: Boolean, default: false },
    paginationShow: { type: Boolean, default: true },
    dividerShow: { type: Boolean, default: true },
    template: { type: Object, required: true },
    value: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    onPaginationChange: { type: Function, required: false },
    onFilterChange: { type: Function, required: false },
    tableName: { type: String, required: false },
    pageOut: { type: Object, required: false },
    isNestedMode: { type: Boolean, default: false },
    isNestedHideOnInit: { type: Boolean, default: false },
    hideOverflow: { type: Boolean, default: false },
    showFiltersBlock: { type: Boolean, default: true },
    title: { type: String, required: false },
    excelReportFileName: { type: String, required: false },
    onLoadAllValues: { type: Function, required: false },
    hideSpoiler: { type: Boolean, default: false },
    hideExport: { type: Boolean, default: false },
    isDisplayColumnHideSettings: { type: Boolean, default: true },
    spoilerDetailsStyle: { type: String, default: '' },
    selectAllIsTrue: {
      type: Boolean,
      required: false,
    },
    actionBlockItems: { type: Array, default: () => [] },
    isDisplayMapOption: { type: Boolean, default: false },
    styleTable: { type: Object, default: () => ({}) },
    widthForTruncated: {
      type: Number,
      default: 860,
    },
  },
  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.initFilters();
    this.initSettings();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  data() {
    return {
      subGroupIndex: (() => {
        if (!this.template.subGroups) {
          return 0;
        }
        const index = this.template.subGroups.findIndex((it) => it.default);
        return index === -1 ? 0 : index;
      })(),
      pagination: undefined,
      showFilters: true,
      internalValue: [],
      sortState: {
        key: '',
        direction: 1,
        type: '',
      },
      filter: {},
      activeFilter: {},
      filterFieldsKey: 1,
      filterValues: {},
      filterValuesEnd: {},
      windowInnerWidth: 0,
      windowInnerHeight: 0,
      isActiveFilters: false,
      hideColumns: [],
      updatedHideColumns: [],
      isShowColumnHideSettings: false,
      searchColumnName: '',
      selectedAllCurrent: false,
      mode: VTableMode.TABLE,
      tableModes: VTableMode,
      tooltipHeader: undefined,
      tooltipX: undefined,
      tooltipY: undefined,
      isMatchMinScreenWidthTooltip: false,
    };
  },
  computed: {
    // TODO: внимательно посмтореть на цепочку оброботки пропсов, может можно сделать проще и отрефакторить
    allHeadersIncludingHidden() {
      if (this.template.subGroups) {
        return this.template.headers.concat(this.template.subGroups[this.subGroupIndex].headers);
      }

      return this.template.headers;
    },
    allHeaders() {
      if (this.hideColumns && this.hideColumns.length > 0) {
        return this.allHeadersIncludingHidden.filter((item) => !this.hideColumns.includes(item.key));
      }

      return this.allHeadersIncludingHidden;
    },
    allHeadHeaders() {
      return this.allHeaders.filter((item) => item.colspan !== '0' && item.type !== 'externalFilter');
    },
    headersForHiddenSettings() {
      const headers = [...this.allHeadersIncludingHidden];

      return headers
        .filter((item) => (item.label || item.type === 'documentDownload' || item.type === 'xmlDownload' ||
          item.type === 'pdfDownload' || item.type === 'fileDownload'));
    },
    headersForHiddenSettingsFiltered() {
      return this.headersForHiddenSettings.filter((item) => item.colspan !== '0' && (this.searchColumnName.length === 0 ||
        item.label.toLowerCase().includes(this.searchColumnName.toLowerCase())));
    },
    indexedValue() {
      let result = this.value.map((it, index) => ({
        ...it,
        rowId: nanoid(),
        index: index + 1,
      }));

      if (this.isNestedMode) {
        const flatValue = [...this.makeFlatFromNested(this.value, true)];
        result = flatValue.map((it) => ({
          ...it,
          rowId: nanoid(),
          index: it.nestedIndex,
        }));
      }

      if (!this.onFilterChange && this.isFiltered && Object.keys(this.activeFilter).length > 0) {
        Object.keys(this.activeFilter).forEach((filterKey) => {

          if (this.activeFilter[filterKey].type === 'period') {
            result = result.filter((item) => {
              const value = this.stringValueByKey(filterKey, item);

              if (value === '-') {
                return false;
              }

              const diffFrom = dayjs(value, 'DD.MM.YYYY', true).diff(dayjs(this.filterValues[filterKey], 'DD.MM.YYYY', true));
              const diffTo = dayjs(value, 'DD.MM.YYYY', true).diff(dayjs(this.filterValuesEnd[filterKey], 'DD.MM.YYYY', true));

              return diffFrom >= 0 && diffTo <= 0;
            });
          } else if (this.activeFilter[filterKey].type === 'select') {
            result = result.filter((item) => {
              const value = this.stringValueByKey(filterKey, item);

              return value !== '-' && value.toUpperCase().trim() === this.activeFilter[filterKey].value.trim().toUpperCase();
            });
          } else if (this.activeFilter[filterKey].type === 'selectNotStrict') {
            result = result.filter((item) => {
              const value = this.stringValueByKey(filterKey, item);

              return value !== '-' && value.toUpperCase().trim().indexOf(this.activeFilter[filterKey].value.trim().toUpperCase()) === 0;
            });
          } else {
            result = result.filter((item) => {
              let value = this.stringValueByKey(filterKey, item)
                .trim()
                .replace(/\s\s+/g, ' ')
                .replace(/\s|&nbsp;/, ' ')
                .toUpperCase();

              let filterValue = this.activeFilter[filterKey].value
                .trim()
                .toUpperCase();

              if (this.activeFilter[filterKey].type === 'number') {
                value = value.replace(/\s+/g, '');
                filterValue = filterValue.replace(/\s+/g, '');
              }

              return value !== '-' &&
                value.indexOf(filterValue) !== -1;
            });
          }
        });

      }

      // TODO: зарефакторить, вынести и унифицировать методы сортировки
      const sortResultByDate = (arr) => {
        const sortedArr = arr.sort((firstElement, secondElement) => this.sortState.direction * (dayjs(firstElement[this.sortState.key], 'DD.MM.YYYY', true).diff(dayjs(secondElement[this.sortState.key], 'DD.MM.YYYY', true)) < 0 ? -1 : 1));
        return sortedArr;
      };

      const sortResultByNumber = (arr) => {
        const sortedArr = arr.sort((firstElement, secondElement) => this.sortState.direction * (Number(firstElement[this.sortState.key]) - Number(secondElement[this.sortState.key]) < 0 ? -1 : 1));
        return sortedArr;
      };

      const sortResultByString = (arr) => {
        const sortedArr = arr.sort((firstElement, secondElement) => this.sortState.direction * (`${firstElement[this.sortState.key]}`).localeCompare(secondElement[this.sortState.key]));
        return sortedArr;
      };

      const sortNestedResultByNumber = (nestedResult, sortKey) => {
        const nestedSortedResultArr = nestedResult.sort((firstElement, secondElement) => this.sortState.direction * (Number(firstElement.main[sortKey]) - Number(secondElement.main[sortKey]) < 0 ? -1 : 1));
        nestedSortedResultArr.forEach((nestedSortedResultArrItem) => {
          nestedSortedResultArrItem.nested = sortResultByNumber(nestedSortedResultArrItem.nested);
        });
        return nestedSortedResultArr;
      };

      // TODO: этот метод должен принимать метод сортировки в качестве аргумента
      const sortNestedResultByDate = (nestedResult, sortKey) => {
        const nestedSortedResultArr = nestedResult.sort((firstElement, secondElement) => this.sortState.direction * (dayjs(firstElement.main[sortKey], 'DD.MM.YYYY', true).diff(dayjs(secondElement.main[sortKey], 'DD.MM.YYYY', true)) < 0 ? -1 : 1));
        nestedSortedResultArr.forEach((nestedSortedResultArrItem) => {
          nestedSortedResultArrItem.nested = sortResultByDate(nestedSortedResultArrItem.nested);
        });
        return nestedSortedResultArr;
      };

      // TODO: этот метод должен принимать метод сортировки в качестве аргумента
      const sortNestedResultByString = (nestedResult, sortKey) => {
        const nestedSortedResultArr = nestedResult.sort((firstElement, secondElement) => this.sortState.direction * (`${firstElement.main[sortKey]}`).localeCompare(secondElement.main[sortKey]));
        nestedSortedResultArr.forEach((nestedSortedResultArrItem) => {
          nestedSortedResultArrItem.nested = sortResultByString(nestedSortedResultArrItem.nested);
        });
        return nestedSortedResultArr;
      };

      if (!this.serverSideSorting) {
        if (this.sortState.key.length === 0) {
          return result;
        }

        if (this.sortState.type === 'number') {
          if (this.isNestedMode) {
            const nestedResult = this.makeNestedFromFlat(result);
            const sortedNestedResult = sortNestedResultByNumber(nestedResult, this.sortState.key);
            return this.makeFlatFromNested(sortedNestedResult, false);
          }

          return sortResultByNumber(result);
        }

        if (this.sortState.type === 'date') {
          if (this.isNestedMode) {
            const nestedResult = this.makeNestedFromFlat(result);
            const sortedNestedResult = sortNestedResultByDate(nestedResult, this.sortState.key);
            return this.makeFlatFromNested(sortedNestedResult, false);
          }

          return sortResultByDate(result);
        }

        if (this.isNestedMode) {
          const nestedResult = this.makeNestedFromFlat(result);
          const sortedNestedResult = sortNestedResultByString(nestedResult, this.sortState.key);
          return this.makeFlatFromNested(sortedNestedResult, false);
        }

        return sortResultByString(result);
      }
      return result;

    },
    totalElements() {
      if (this.pageOut) {
        return this.pageOut.totalElements;
      }

      if (!this.indexedValue) {
        return 0;
      }

      return this.indexedValue.length;
    },
    isFiltered() {
      return this.template.headers.some((item) => item.filter) ||
        (this.template.subGroups && this.template.subGroups.some((group) => group.headers.some((item) => item.filter)));
    },
    isActions() {
      return this.actionBlockItems?.length;
    },
    filterFields() {
      const subGroups = [];

      if (this.template.subGroups) {
        this.template.subGroups.forEach((group) => {
          const filters = group.headers.filter((item) => item.filter);
          subGroups.push(...filters);
        });
      }

      return [...this.template.headers.filter((item) => item.filter), ...subGroups];
    },
    pageTableName() {
      if (this.tableName) {
        return this.tableName;
      }

      return this.$route.name;
    },
    styleDivHeight() {
      if (this.windowInnerHeight === 0 || this.hideOverflow) {
        return '';
      }

      return `maxHeight: ${this.windowInnerHeight - 216}px`;
    },
    tableStyle() {
      if (Object.keys(this.styleTable).length > 0) {
        return this.styleTable;
      }

      return 'minHeight: 150px';
    },
    isUnconditionalWidthForTooltip() {
      return this.windowInnerWidth < this.widthForTruncated;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateInternalValue();
      },
    },
    internalValue: {
      handler(val) {
        this.$emit('filteredValue', val);
      },
    },
    mode: {
      immediate: true,
      handler(val) {
        this.$emit('change-mode', val);
      },
    },
  },
  methods: {
    onResize() {
      this.windowInnerWidth = window.innerWidth;
      this.windowInnerHeight = window.innerHeight;
    },
    isShowTableRow(rowShowField) {
      if (!this.isNestedMode) return true;
      if (rowShowField) return true;
      return false;
    },
    onSpoilerButtonClickHandler(groupId) {
      this.internalValue.forEach((internalValueItem) => {
        if (internalValueItem.isNested && internalValueItem.groupId === groupId) {
          internalValueItem.isShow = !internalValueItem.isShow;
        }

        if (internalValueItem.groupId === groupId) {
          internalValueItem.isSpoilerButtonShow = !internalValueItem.isSpoilerButtonShow;
        }
      });
    },
    makeNestedFromFlat(flat) {
      const resultNested = [];
      const nestedNested = [];

      flat.forEach((flatItem) => {
        if (!flatItem.isNested) {
          resultNested.push({
            main: flatItem,
            nested: [],
          });
        }
      });

      flat.forEach((flatItem) => {
        if (flatItem.isNested) {
          nestedNested.push(flatItem);
        }
      });

      resultNested.forEach((resultNestedItem) => {
        nestedNested.forEach((nestedNestedItem) => {
          if (nestedNestedItem.groupId === resultNestedItem.main.groupId) {
            resultNestedItem.nested.push(nestedNestedItem);
          }
        });
      });

      return resultNested;
    },
    makeFlatFromNested(nested, isNeddAddGroupId) {
      const flat = [];
      nested.forEach((internalValueItem, mainIndex) => {
        const groupId = nanoid();

        const mainObjForPush = internalValueItem.main;
        mainObjForPush.isNested = false;
        mainObjForPush.isShow = true;
        mainObjForPush.isSpoilerButtonShow = !this.isNestedHideOnInit;
        mainObjForPush.nestedIndex = `${mainIndex + 1}`;
        if (isNeddAddGroupId) mainObjForPush.groupId = groupId;
        flat.push(mainObjForPush);

        if (internalValueItem.nested && internalValueItem.nested.length !== 0) {
          internalValueItem.nested.forEach((nested, nestedIndex) => {
            nested.isNested = true;
            nested.isShow = !this.isNestedHideOnInit;
            nested.isSpoilerButtonShow = !this.isNestedHideOnInit;
            nested.nestedIndex = `${mainIndex + 1}.${nestedIndex + 1}`;
            if (isNeddAddGroupId) nested.groupId = groupId;
            flat.push(nested);
          });
        }
      });
      return flat;
    },
    // TODO: refactor this, get one object from tabs
    onTabClickHandler(index, subGroup) {
      this.$emit('on-tab-click', subGroup);
    },
    onThClickHandler($event) {
      this.$emit('on-th-click', $event);
    },
    onCellDeleteHandler($event) {
      this.$emit('delete', $event);
    },
    onCellEditHandler($event) {
      this.$emit('edit', $event);
    },
    onCellClickHandler($event) {
      this.$emit('on-cell-click', $event);
    },
    onCellInputHandler($event) {
      this.$emit('on-cell-input', $event);
    },
    onSortColumnHandler(header) {
      if (this.sortState.key === header.key) {
        this.sortState.direction = ((this.sortState.direction + 2) % 3) - 1;
      } else {
        this.sortState.direction = 1;
      }
      this.sortState.key = header.key;
      this.sortState.type = header.sort.type;

      if (this.serverSideSorting) {
        this.$emit('on-sort', header);
      } else {
        this.updateInternalValue();
      }
    },
    changePage() {
      if (this.pagination) {
        this.internalValue = this.indexedValue.slice(
          (this.pagination.page - 1) * this.pagination.size,
          this.pagination.page * this.pagination.size,
        );
      }
    },
    paginationChange(pagination) {
      if (this.onPaginationChange) {
        this.onPaginationChange({
          page: pagination.page,
          size: pagination.size,
        });

        return;
      }
      this.pagination = pagination;
      this.changePage();
    },
    updateInternalValue() {
      Object.keys(this.filterValues).forEach((filterKey) => {
        if (typeof this.filterValues[filterKey] === 'string' || this.filterValues[filterKey] instanceof String) {
          this.filterValues[filterKey] = this.filterValues[filterKey].trim().replace(/\s\s+/g, ' ');
        }
      });

      Object.keys(this.filterValuesEnd).forEach((filterKey) => {
        if (typeof this.filterValuesEnd[filterKey] === 'string' || this.filterValuesEnd[filterKey] instanceof String) {
          this.filterValuesEnd[filterKey] = this.filterValuesEnd[filterKey].trim().replace(/\s\s+/g, ' ');
        }
      });

      this.internalValue = this.indexedValue;

      if (!this.onPaginationChange) {
        this.changePage();
      }
    },
    changeFilter(header, val) {
      if (!val || (String(val).length === 0)) {
        this.$delete(this.filterValues, header.key);
        this.$delete(this.filter, header.key);
        if (header.type === 'filterAndCheckbox') this.clear();
        return;
      }

      this.$set(this.filter, header.key, { value: val, type: header.filter.type, emptyValue: header.filter.emptyValue });
      if (header.type === 'filterAndCheckbox') this.search();
    },
    clear() {
      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key].emptyValue) {
          return;
        }
        this.$delete(this.filter, key);
      });

      this.filterValues = {};
      this.filterValuesEnd = {};

      this.filterFields.forEach((field) => {
        if (field.filter.emptyValue && !this.filterValues[field.key]) {
          this.$set(this.filterValues, field.key, field.filter.emptyValue);
        }
      });

      this.saveFilterTable();

      if (this.onFilterChange) {
        this.onFilterChange(this.filterValues, this.filterValuesEnd);
        return;
      }

      this.activeFilter = { ...this.filter };

      this.updateInternalValue();
      this.filterFieldsKey++;
    },
    valueByKey(key, data) {
      const keys = key.split('.');
      if (keys.length > 1) {
        return keys.reduce((acc, cur) => {
          if (acc[cur]) {
            return acc[cur];
          }
          return '-';
        }, data);
      }
      if (data[key] && data[key].length !== 0) {
        return data[key];
      }
      return '-';
    },
    stringValueByKey(key, data) {
      let value = this.valueByKey(key, data);
      if (Array.isArray(value)) {
        value = value.map((it) => it.value).join('');
      }
      return value;
    },
    saveFilterTable() {
      this.SAVE_TABLE_FILTERS({
        name: this.pageTableName,
        filters: {
          values: this.filterValues,
          valuesEnd: this.filterValuesEnd,
        },
      });
    },
    search() {
      this.saveFilterTable();

      this.filterFields.forEach((field) => {
        if (field.filter.emptyValue && !this.filterValues[field.key]) {
          this.$set(this.filterValues, field.key, field.filter.emptyValue);
        }
      });

      if (this.onFilterChange) {
        this.onFilterChange(this.filterValues, this.filterValuesEnd);
        return;
      }

      this.activeFilter = { ...this.filter };

      this.updateInternalValue();
    },
    initFilters() {
      const filters = this.getTableFilterByName()(this.pageTableName, {
        values: {},
        valuesEnd: {},
      });

      this.filterValues = filters.values ?? {};
      this.filterValuesEnd = filters.valuesEnd ?? {};

      if (filters.values && Object.keys(filters.values).length > 0) {
        this.filterFields.forEach((field) => {
          if (!this.filterValues[field.key] && field.filter.emptyValue) {
            this.$set(this.filterValues, field.key, field.filter.emptyValue);
          }
          this.changeFilter(field, this.filterValues[field.key]);
        });

        this.activeFilter = { ...this.filter };
      } else {
        const defaultFields = this.filterFields.filter((field) => field.filter.default);

        if (defaultFields.length > 0) {
          defaultFields.forEach((field) => {
            this.$set(this.filterValues, field.key, field.filter.default);
            this.changeFilter(field, this.filterValues[field.key]);
          });
        }
      }

      if (this.onFilterChange) {
        this.onFilterChange(this.filterValues, this.filterValuesEnd);
        return;
      }

      this.updateInternalValue();
    },
    initSettings() {
      const tableHiddenColumns = this.getTableHiddenColumns()(this.pageTableName);

      this.hideColumns = tableHiddenColumns ?? [];
    },
    async saveReport() {
      let values = [];

      if (this.onLoadAllValues) {
        values = await this.onLoadAllValues();
      } else {
        values = this.indexedValue;
      }

      const fileName = (this.excelReportFileName ??
        this.title ??
        `Отчёт_${dayjs().format('DD.MM.YYYY')}`).replace(/[/\\?%*:|"<>\n]/g, '');

      const headers = [...this.template.headers];

      this.template.subGroups?.forEach((group) => {
        headers.push(...group.headers.map((item) => ({ ...item, label: `${group.label}. ${item.label}` })));
      });

      const value = exportToExcel(
        fileName,
        this.allHeadHeaders.filter((item) => this.checkingColumnVisibilityForExcel(item)),
        'label',
        values,
        'key',
        {
          alignment: 'center',
          maxWidth: 40,
        },
      );

      const blob = new Blob([value]);

      try {
        await this.saveReportToServer({ file: blob, fileName: `${fileName}.xlsx` });
      } catch (e) {
        console.error('Не удалось сохранить отчёт');
        console.error(e);
      }
    },
    hideColumn(key, colspan) {
      const index = this.updatedHideColumns.indexOf(key);

      if (index === -1) {
        this.selectedAllCurrent = false;

        this.updatedHideColumns.push(key);

        if (colspan && colspan !== '0' && Number(colspan)) {
          const colspanNumber = Number(colspan);

          const indexInAll = this.headersForHiddenSettings.map((header) => header.key).indexOf(key);
          for (let i = 1; i < colspanNumber; i++) {
            const nextItem = this.headersForHiddenSettings[indexInAll + i];

            if (nextItem && nextItem.colspan === '0') {
              this.updatedHideColumns.push(nextItem.key);
            }
          }
        }
        return;
      }

      this.updatedHideColumns.splice(index, 1);

      if (colspan && colspan !== '0' && Number(colspan)) {
        const colspanNumber = Number(colspan);

        const indexInAll = this.allHeadersIncludingHidden.map((header) => header.key).indexOf(key);
        for (let i = 1; i < colspanNumber; i++) {
          const nextItem = this.allHeadersIncludingHidden[indexInAll + i];

          const nextItemIndex = this.updatedHideColumns.indexOf(nextItem.key);
          if (nextItemIndex !== -1) {
            this.updatedHideColumns.splice(nextItemIndex, 1);
          }
        }
      }
    },
    selectAllCurrentHandler() {
      this.selectedAllCurrent = !this.selectedAllCurrent;

      if (this.selectedAllCurrent) {
        this.updatedHideColumns = [];
        return;
      }

      this.updatedHideColumns = [...this.headersForHiddenSettingsFiltered.map((item) => item.key)];
    },
    updateHideColumns() {
      this.hideColumns = [...this.updatedHideColumns];
      this.isShowColumnHideSettings = false;

      this.SAVE_TABLE_HIDDEN_COLUMNS({
        name: this.pageTableName,
        hideColumns: this.hideColumns,
      });
    },
    openColumnHideSettings() {
      this.updatedHideColumns = [...this.hideColumns];
      this.isShowColumnHideSettings = true;
      this.searchColumnName = '';
      this.selectedAllCurrent = false;
    },
    getColumnName(item) {
      if (item.label) {
        return item.label;
      }

      if (item.type === 'documentDownload' || item.type === 'xmlDownload' || item.type === 'pdfDownload' ||
        item.type === 'fileDownload') {
        return 'Файл документа';
      }

      return item.type;
    },
    swapMode() {
      if (this.mode === VTableMode.TABLE) {
        this.mode = VTableMode.MAP;
      } else this.mode = VTableMode.TABLE;
    },
    tapPlacemark(id) {
      this.$emit('tap-placemark', id);
    },
    labelMouseover(header, eventMouseover, isMatchMinScreenWidthTooltip) {
      this.tooltipHeader = header;
      this.isMatchMinScreenWidthTooltip = isMatchMinScreenWidthTooltip;
      this.tooltipX = eventMouseover.clientX;
      this.tooltipY = eventMouseover.clientY;
    },
    labelMouseout() {
      this.tooltipHeader = undefined;
      this.tooltipX = undefined;
      this.tooltipY = undefined;
    },
    checkingColumnVisibilityForExcel(item) {
      return item.colspan !== '0' &&
          item.type !== 'followLink' &&
          item.type !== 'index' &&
          item.type !== 'externalFilter' &&
          item.type !== 'editDeleteButtons' &&
          item.type !== 'xmlDownload' &&
          item.type !== 'pdfDownload' &&
          item.type !== 'documentDownload' &&
          item.type !== 'spoilerButton' &&
          item.type !== 'delete' &&
          item.type !== 'crossDelete' &&
          item.type !== 'view' &&
          item.type !== 'edit' &&
          (item.type !== 'button' || item.isDisplayInExcel) &&
          item.type !== 'selectButton' &&
          item.type !== 'checkbox' &&
          item.type !== 'actionButton' &&
          item.type !== 'editDeleteButtons' &&
          item.type !== 'preview';
    },
    ...mapMutations('user', ['SAVE_TABLE_FILTERS', 'SAVE_TABLE_HIDDEN_COLUMNS']),
    ...mapGetters('user', ['getTableFilterByName', 'getTableHiddenColumns']),
    ...mapActions('document', ['saveReportToServer']),
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__table {
    width: 100%;
    position: relative;

    &-scroll-wrp {
      overflow: auto;

      &::-webkit-scrollbar-button {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0;
        border-radius: 10px;
        background-color: #d1d1d1;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a19f9f;
      }

      &::-webkit-resizer {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 10px;
      }
    }

    &-head-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: -6px;
      background-color: #FFFFFF;
      z-index: 2
    }
  }

  &__spoiler-button-label {
    @media (max-width: 860px) {
      display: none;
    }
  }

  &__bar {
    &--tabs {
      width: 50%;
      margin-bottom: 4px;
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__loader {
    position: relative;
    height: 50px;

    div:first-child {
      top: 50px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #71757A;
    background: #F5F6F6;
    border-radius: 20px 20px 0 0;
    padding: 8px 48px 8px 24px;
    text-transform: capitalize;
  }

  &__download-excel, &__column-hide-settings, &__column-hide-map {
    height: 26px;
    font-size: 12px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__download-excel {
    min-width: 168px;

    @media (max-width: 860px) {
      min-width: 60px;
    }
  }

  &__column-hide-settings {
    margin-right: 16px;
    min-width: 250px;

    @media (max-width: 860px) {
      min-width: 60px;
    }
  }

  &__column-hide-map {
    margin-right: 16px;
    min-width: 80px;

    @media (max-width: 860px) {
      min-width: 60px;
    }
  }

  &__settings-modal {
    &__title, &__header {
      padding: 0 12px 0 24px;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 32px;
      color: #4C4E51;

      &__img {
        height: 20px;
      }
    }

    &__header {
      height: 40px;
      display: flex;
      gap: 16px;
      align-items: center;
      background-color: #F5F6F6;
      color: #263238;

      &__sort-icon {
        cursor: pointer;
      }
    }

    &__list {
      color: #263238;
      overflow-y: scroll;

      &::-webkit-scrollbar-button {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0;
        border-radius: 10px;
        background-color: #d1d1d1;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a19f9f;
      }

      &::-webkit-resizer {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      min-height: 100px;
      max-height: 200px;
      border-bottom: 1px solid #C1C4C7;

      &__img {
        margin-right: 8px;
      }

      &__item {
        display: flex;
        padding: 12px 12px 12px 24px;
        cursor: pointer;

        &:hover {
          background-color: #cbcbcb;
        }
      }
    }

    &__search {
      width: 100%;
      padding-left: 48px;
      background-image: url('~icons/search/loupe-gray.svg');
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position-x: 24px;
      background-position-y: 10px;
      border-top: 1px solid #C1C4C7;
      border-bottom: 1px solid #C1C4C7;
      border-left: 0;
      border-right: 0;
      height: 40px;
      color: #263238;

      &:focus {
        outline: none;
        border-top: 1px solid #2f82df;
        border-bottom: 1px solid #2f82df;
      }
    }

    &__action {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px 8px 16px;
    }
  }
}
</style>
