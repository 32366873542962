<template>
  <div class="signatories-step">
    <span class="signatories-step__title">Выберите подписывающее лицо</span>
    <emploee-select-or-input :error="selectError" :init-employee="initEmployee" :inn="signatoryInn" mode="select" permission="SVET_A12-S" role="signatory"
                             @emploeeSelectOrInput="onSelected"/>
  </div>
</template>

<script>
import { EmploeeSelectOrInput } from '@/components';
import { mapGetters } from 'vuex';

export default {
  components: {
    EmploeeSelectOrInput,
  },
  props: {
    usefulVacationDocument: Object,
  },
  computed: {
    signatoryInn() {
      return this.getAccount.profile.inn;
    },
    selectError() {
      return { select: this.usefulVacationDocument.signatory_error.error };
    },
    initEmployee() {
      return {
        userId: this.usefulVacationDocument.signatory.userId,
        reason: {
          id: this.usefulVacationDocument.signatory.powerOfAttorneyId,
        },
      };
    },
    ...mapGetters('user', ['getAccount']),
  },
  methods: {
    onSelected(selected) {
      this.usefulVacationDocument.signatory.userId = selected.userId;
      this.usefulVacationDocument.signatory.role.id = selected.role?.id;
      this.usefulVacationDocument.signatory.role.name = selected.role?.name;
      this.usefulVacationDocument.signatory.powerOfAttorneyId = selected.reason?.id;
      this.usefulVacationDocument.signatory.reason.name = selected.reason?.reasonDocument;
      this.usefulVacationDocument.signatory.reason.number = selected.reason?.number;
      this.usefulVacationDocument.signatory.reason.date = selected.reason?.date;
      this.usefulVacationDocument.signatory.fullName.name = selected.fullName?.name;
      this.usefulVacationDocument.signatory.fullName.surname = selected.fullName?.surname;
      this.usefulVacationDocument.signatory.fullName.patronymic = selected.fullName?.patronymic;
      this.usefulVacationDocument.signatory.fullNameGenitive.name = selected.fullNameGenitive?.name;
      this.usefulVacationDocument.signatory.fullNameGenitive.surname = selected.fullNameGenitive?.surname;
      this.usefulVacationDocument.signatory.fullNameGenitive.patronymic = selected.fullNameGenitive?.patronymic;
      this.usefulVacationDocument.signatory.accountId = this.getAccount.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.signatories-step {
  width: 848px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px auto 0px;

  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #4C4E51;
  }
}
</style>
