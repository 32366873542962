<template>
  <div>
    <odk-tag-modal v-model="tagsModalState" @updated="fetchAxpInExploitation"/>
    <v-app class="vuetify-styles" data-app>
      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
          <span class="text-h5 align-center"
          >Назначение объектов на осмотр</span
          >
            </v-card-title>
            <v-card-text>

              <events-autocomplete
                ref="event"
                v-model="eventId"
                :items.sync="events"
                :search.sync="eventName"
                title="Мероприятие"
              />

              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"/>
              <div>
                <yandex-map
                  ref="customMap"
                  :collection-axp="axpItemsFiltered"
                  @tapPlacemark="onTapPlacemark"
                />
              </div>
              <v-data-table
                :headers="headersTable"
                :items="axpItemsFiltered"
                :loading="isLoadingAxp"
                :search="search"
                class="elevation-1 mb-4"
                item-key="id"
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.isSelected="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.isSelected"
                      class="no-margin-top mb-1"
                      color="success"
                      hide-details
                      @click="onTapCheckbox"
                    ></v-checkbox>
                  </div>
                </template>

                <template #item.dateInstallation="{ item }">
                  {{ formatDateFromOdkTimestamp(item.dateInstallation) }}
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.adreskaId)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>
                <template #item.isDefects="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.isDefects"
                      class="no-margin-top mb-1"
                      color="success"
                      disabled
                      hide-details
                    ></v-checkbox>
                  </div>
                </template>
                <template #item.isControlDefects="{ item }">
                  <div class="d-flex justify-center">
                    <v-checkbox v-model="item.isControlDefects"
                                class="align-center mb-1"
                                color="success"
                                disabled
                                hide-details
                                style="text-align: center"
                    ></v-checkbox>
                  </div>
                </template>
                <template #item.defectId="{ item }">
                  <div class="d-flex align-center mt-6" style="min-width: 100px">
                    <defect-autocomplete
                      :key="`Defect${item.id}`"
                      v-model="item.defectId"
                    ></defect-autocomplete>
                  </div>
                </template>
                <template #item.descriptionDefect="{ item }">
                  <div class="d-flex align-center mt-6" style="min-width: 100px">
                    <v-textarea
                      v-model="item.descriptionDefect"
                      dense
                      name="input-7-4"
                      outlined
                      rows="1"
                    ></v-textarea>
                  </div>
                </template>
                <template #expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    More info about {{ item.odkName }}
                  </td>
                </template>
              </v-data-table>

              <file-input
                ref="photos"
                v-model="photos"
                class="mt-2"
                title="Фото"
              ></file-input>

              <simple-autocomplete
                ref="criticality"
                v-model="criticalityId"
                :items.sync="criticalityItems"
                :search.sync="criticalityName"
                is-required
                title="Критичность осмотра"
                url="/criticality/find?name="
                url-default-data="/criticality"
              ></simple-autocomplete>

              <v-row>
                <v-dialog
                  ref="modalDate"
                  v-model="modalDate"
                  class="v-dialog-top"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-col>
                      <div>
                        <v-label
                          :class="{
                        'error-label': !isDateValid,
                      }"
                        >Даты начала и конца осмотра*
                        </v-label
                        >
                      </div>
                      <v-text-field
                        ref="textFieldDate"
                        v-model="dateRangeText"
                        dense
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-card class="v-application">
                    <v-date-picker v-model="dates" range></v-date-picker>
                    <v-card-text class="mt-2">
                      <v-row>Время начала осмотра</v-row>
                      <v-row>
                        <v-col>Часы</v-col>
                        <v-col>
                          <div class="pl-1">Минуты</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <!-- <v-row> -->
                          <text-field
                            ref="contract"
                            v-model="startHour"
                            :max="24"
                            class="pr-1"
                            is-time
                            simple
                          ></text-field>
                        </v-col>
                        <v-col cols="6">
                          <text-field
                            ref="contract"
                            v-model="startMinutes"
                            :max="59"
                            class="pl-1"
                            is-time
                            simple
                          ></text-field>
                          <!-- </v-row> -->
                        </v-col>
                      </v-row>
                      <v-row>Время окончания осмотра</v-row>
                      <v-row>
                        <v-col>Часы</v-col>
                        <v-col>
                          <div class="pl-1">Минуты</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <!-- <v-row> -->
                          <text-field
                            ref="contract"
                            v-model="endHour"
                            :max="24"
                            :min="startHour"
                            class="pr-1"
                            is-time
                            simple
                          ></text-field>
                        </v-col>
                        <v-col cols="6">
                          <text-field
                            ref="contract"
                            v-model="endMinutes"
                            :max="59"
                            :min="startMinutes"
                            class="pl-1"
                            is-time
                            simple
                          ></text-field>
                          <!-- </v-row> -->
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" text @click="modalDate = false">
                        Отмена
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="isDisabledSaveDate"
                        color="primary"
                        text
                        @click="save(dates)"
                      >
                        Принять
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>

              <simple-autocomplete-by-function
                ref="department"
                v-model="departmentId"
                :fetch-data-function="subdivisionsByName"
                :fetch-default-data-function="subdivisionsByName"
                :items.sync="departments"
                :search.sync="departmentName"
                is-required
                title="Департамент"
              ></simple-autocomplete-by-function>
              <v-banner v-if="departmentId && isDisableNoUsers" class="red--text mb-8">В данном департаменте отсутствуют
                сотрудники. Выберите другой департамент
              </v-banner>
              <simple-autocomplete-by-function
                ref="employee"
                v-model="employeeId"
                :dependency="userAutocompleteDependency"
                :fetch-data-function="rolesForPermissionByName"
                :fetch-default-data-function="rolesForPermissionByName"
                :items.sync="employees"
                :search.sync="employeeFullName"
                title="Сотрудник"
              ></simple-autocomplete-by-function>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabledSaveDialog"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSend"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog v-model="loaderDialog"
                  class="v-dialog-top" persistent width="250">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + "%" }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';
import YandexMap from '../components/Map/YandexMap.vue';
import TextField from 'components/Processes/components/TextField.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
// import { D11_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import { getSurnameAndInitials } from 'utils/user';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { filterForAccess } from 'lib/utils/permissions';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import DefectAutocomplete from 'components/Processes/components/DefectAutocomplete.vue';

export default {
  components: {
    OdkTagModal,
    SimpleAutocompleteByFunction,
    SimpleAutocomplete,
    YandexMap,
    TextField,
    FileInput,
    EventsAutocomplete,
    DefectAutocomplete,
  },

  name: 'Form1',

  async created() {
    // if (!checkAccess(D11_A)) {
    //   this.onTapBack();
    // }
    await this.fetchAxpInExploitation();
    this.defects = await (await fetch(`${BASE_URL}/defects`)).json();
  },

  data: () => ({
    tagsFilter: null,
    adreskaId: null,
    adreskaFactId: null,

    photos: [],
    criticalityId: null,
    criticalityName: '',
    criticalityItems: [],
    modalDate: false,
    isDateValid: true,
    dates: [],
    datesForText: [],
    startHour: '00',
    startMinutes: '00',
    endHour: '00',
    endMinutes: '00',

    expanded: [],
    search: null,
    axpItems: [],
    isLoadingAxp: false,
    updateMap: 0,

    departmentId: null,
    departmentName: '',
    departments: [],
    employeeId: null,
    employeeFullName: '',
    employees: [],

    eventId: null,
    events: [],
    eventName: '',

    progressValue: 0,
    loaderDialog: false,

    fetchedRoles: [],
    userAutocompleteDependency: null,
    defects: [],
    tagsModalState: null,
  }),
  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        { text: 'На осмотр', value: 'isSelected', align: 'center' },
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          fixed: {},
        },
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        { text: 'Дата монтажа', value: 'dateInstallation', align: 'center' },
        { text: 'Подрядчик', value: 'contractorName', align: 'center' },
        {
          text: 'Замечание',
          value: 'defectId',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Комментарий к замечанию',
          value: 'descriptionDefect',
          align: 'center',
          sortable: false,
        }, { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    axpItemsFiltered() {
      let filtered = [...this.axpItems];
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      if (this.eventId) filtered = filtered.filter((it) => String(it.eventId) === String(this.eventId));
      return filtered;
    },
    isDisableNoUsers() {
      return !this.fetchedRoles.length;
    },
    isDisabledSaveDialog() {
      const isDisableCriticality = this.criticalityId == null;
      const isDisableDateRangeText = this.dateRangeText == null;
      const isDisableDepartmentId = this.departmentId == null;
      const isDisableSelectedAdreskas = !this.axpItemsFiltered.filter((it) => it.isSelected).length;
      return (
        isDisableCriticality ||
        isDisableDateRangeText ||
        isDisableDepartmentId ||
        isDisableSelectedAdreskas ||
        this.isDisableNoUsers
      );
    },

    isDisabledSaveDate() {
      return (
        this.startHour == null ||
        this.startMinutes == null ||
        this.endHour == null ||
        this.endMinutes == null ||
        this.dates.length === 0
      );
    },

    dateRangeText() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.isDateValid = true;
      if (this.datesForText.length > 0) {
        return `${this.datesForText.join(' ~ ')} ${this.startHour}:${
          this.startMinutes
        } - ${this.endHour}:${this.endMinutes}`;
      }
      return null;

    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getToken', 'getAccount']),
  },

  watch: {
    startHour() {
      this.endHour = '00';
    },

    startMinutes() {
      this.endMinutes = '00';
    },

    async departmentId() {
      this.employeeId = null;
      const result = await this.getSubdivisionWithFilteredRolesById({
        id: this.departmentId,
      });
      this.fetchedRoles = this.departmentId ? result.roles.flatMap((role) => role.authorizations.map((auth) => ({
        authorization: auth,
        roleName: role.name,
      }))).map((elem) => ({
        id: elem.authorization.user.id,
        name: `${elem.roleName} ${getSurnameAndInitials(elem.authorization.user.fullName)}`,
      })) : [];
      this.employeeFullName = '';
      this.userAutocompleteDependency = this.departmentId;
    },
  },

  methods: {
    openTags(id) {
      this.tagsModalState = id;
    },
    isRequiredDescription(item) {
      return this.defects.find((it) => it.id === item.defectId)?.mnemonic === 'CUSTOM';
    },
    formatDateFromOdkTimestamp,
    async subdivisionsByName(name) {
      return await this.getSubdivisions({
        filter: {
          name: name,
        },
      });
    },
    // TODO серверная фильтрация
    async rolesForPermissionByName(name) {
      if (!name?.length) return this.fetchedRoles;
      const nameLower = name.toLowerCase();
      return this.fetchedRoles.filter((role) => role.name.toLowerCase().includes(nameLower));
    },
    save(dates) {
      this.modalDate = false;
      this.datesForText = dates.sort();
    },

    onTapPlacemark(id) {
      const index = this.axpItems.map((e) => e.id).indexOf(id);
      this.axpItems[index].isSelected =
        !this.axpItems[index].isSelected;
      this.axpItems[index].balloonContent.buttonLabel = this.axpItems[index].isSelected ? 'Отменить' : 'Выбрать';
      this.$refs.customMap.updateCollection();
    },

    onTapCheckbox() {
      this.$refs.customMap.updateCollection();
    },

    async fetchAxpInExploitation() {
      this.axpItems = [];
      this.isLoadingAxp = true;
      const response = await fetch(`${BASE_URL}/axp/exploitation`);
      const data = await response.json();
      data.forEach((it) => {
        let color = '';
        let status = '';
        if (it.statusInspection == 0) {
          color = 'blue';
          status = 'Не назначено на осмотр';
        } else if (it.statusInspection == 1) {
          color = 'green';
          status = 'Ожидает осмотр';
        } else if (it.statusInspection == 2) {
          if (it.statusLocalRepair == 1) {
            color = 'red';
            status = 'Ожидает ремонта';
          } else {
            color = 'yellow';
            status = 'Ремонт завершён';
          }
        } else if (it.statusInspection == 3) {
          color = 'green';
          status = 'Осмотр завершён без замечаний ';
        }
        const axp = {
          id: it.id,
          adreskaId: it.adreska.id,
          odkName: it.axp.odk.name,
          dateInstallation: it.createDate,
          contractorName: it.adreska.contractorName,
          eventId: it.adreska.event.id,
          isDefects: false,
          isControlDefects: false,
          locationLatitude: it.locationLatitude,
          locationLongitude: it.locationLongitude,
          statusInspection: it.statusInspection,
          statusLocalRepair: it.statusLocalRepair,
          isSelected: false,
          descriptionDefect: null,
          defectId: null,
          color: color,
          tags: it.adreska.tags.map((it) => it.name).join(', '),
          balloonContent: {
            title: it.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'Подрядчик',
                value: it.adreska.contractorName,
              },
              {
                label: 'Адресная программа',
                value: it.adreska.address,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(it.createDate),
              },
              {
                label: 'Статус',
                value: status,
              },
            ],
          },
        };
        this.axpItems.push(axp);
      });
      this.isLoadingAxp = false;
    },
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      let newDate = new Date(this.datesForText[0]);
      const startUnixTimestamp = newDate.getTime() / 1000;
      let endUnixTimestamp = 0;
      if (this.datesForText.length > 1) {
        newDate = new Date(this.datesForText[1]);
        endUnixTimestamp = newDate.getTime() / 1000;
      } else {
        endUnixTimestamp = startUnixTimestamp;
      }

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const installationItems = [];

      this.axpItems.forEach((it) => {
        if (it.isSelected) {
          installationItems.push({
            adreskaFactId: it.id,
            defectId: it.defectId,
            defectDescription: it.descriptionDefect,
          });
        }
      });

      let convertTime = (Number(this.startHour) * 60) + Number(this.startMinutes);
      const startInspectionTime = convertTime == null ? 0 : convertTime;

      convertTime = (Number(this.endHour) * 60) + Number(this.endMinutes);
      const endInspectionTime = convertTime == null ? 0 : convertTime;

      const request = {
        installationItems: installationItems,
        departmentId: this.departmentId,
        departmentEmployeeUserId: this.employeeId,
        departmentEmployeeAccountId: this.getAccount.id,
        criticalityId: this.criticalityId,
        photos: mainPhotos,
        startInspectionDate: startUnixTimestamp,
        endInspectionDate: endUnixTimestamp,
        startInspectionTime: startInspectionTime,
        endInspectionTime: endInspectionTime,
      };

      axios
        .post(`${BASE_URL}/assignment-for-inspection`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },


    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.adreskaId = null;
      this.adreskaFactId = null;
      this.startHour = '00';
      this.startMinutes = '00';
      this.endHour = '00';
      this.endMinutes = '00';
      this.isDateValid = true;
      this.dates = [];
      this.datesForText = [];
      this.photos = [];
      this.criticalityId = null;
      this.criticalityName = '';
      this.criticalityItems = [];
      this.modalDate = false;
      this.search = null;
      this.isLoadingAxp = false;
      this.progressValue = 0;
      this.loaderDialog = false;
      this.departmentId = null;
      this.departmentName = '';
      this.departments = [];
      this.employeeId = null;
      this.employeeFullName = '';
      this.employees = [];
      this.fetchedRoles = [];
      this.eventId = null;
      this.events = [];
      this.eventName = '';
    },
    ...mapActions('user', ['getSubdivisions', 'getSubdivisionWithFilteredRolesById', 'setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>
