<template>
  <connection-certificate-details
      v-if="data.actionComponent === 'SendConnectionCertificate' || data.actionComponent === 'SendAdmissionCertificate'"
      v-model="isShownTaskDetails"
      :account-id="taskParams.accountId"
      :additional-document="additionalDocument"
      :application-data="applicationData"
      :application-id="data.applicationId"
      :data="data"
      :grid-company-name="gridCompanyName"
      :hide-offer-in-summary="data.hideOfferInSummary === true"
      :is-display-action="data.isDisplayAction"
      :is-loading="isLoading"
      :offer-document="offerDocument"
      :offer-document-title="data.offerDocumentTitle"
      :retail-company-name="retailCompanyName"
      :retail-review-document="retailReviewDocument"
      :retailer-offer-document="retailerOfferDocument"
      :review-document="reviewDocument"
      :summary-props="data.summaryProps"
      @close="onClose"
  />
  <div v-else :class="$style.container">
    <div :style="{
        position: 'absolute',
        top: '0',
        backgroundColor: '#ffffff',
        height: '40px',
        padding: '10px',
        zIndex: '100',
        width: navBarWidth,
        marginLeft: '-16px',
       }">
      <p :class="$style.navigationBar">
        <span :class="$style.navigationRoot" @click="onClose">{{ fromTitle }}</span>
        <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
        <span>{{ data.title }}</span><span v-if="data.subtitle"> ({{ data.subtitle }})</span>
      </p>
    </div>

    <div ref="techPrisContent" :class="$style.content">
      <Details v-model="isShownTaskDetails"
               :account-id="taskParams.accountId"
               :additional-document="additionalDocument"
               :admission-certificate-id="taskParams.admissionCertificateId"
               :application-data="applicationData"
               :application-id="data.applicationId"
               :connection-certificate-id="taskParams.connectionCertificateId"
               :utility-offer-document-id="taskParams.utilityOfferDocumentId"
               :data="data"
               :grid-company-name="gridCompanyName"
               :hide-offer-in-summary="data.hideOfferInSummary === true"
               :is-display-action="data.isDisplayAction"
               :is-loading="isLoading"
               :offer-document="offerDocument"
               :offer-document-title="data.offerDocumentTitle"
               :retail-company-name="retailCompanyName"
               :retail-review-document="retailReviewDocument"
               :retailer-offer-document="retailerOfferDocument"
               :review-document="reviewDocument"
               :summary-props="data.summaryProps"
               :technical-conditions-act-document-id="taskParams.technicalConditionsActDocumentId"
               @close="onClose"
      />
    </div>

    <div
      :class="$style.contentFooter"
      :style="{
        position: 'absolute',
        bottom: '0',
        width: footerWidth,
        backgroundColor: '#ffffff',
      }">
      <div :class="$style.back" @click="onClose">
        <arrow-left/>
        <span>НАЗАД</span>
      </div>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import Details from 'views/private/ConnectionApplication/Task/Details';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { mapActions, mapGetters } from 'vuex';
import { initConnectionApplicationTaskData } from 'views/private/ConnectionApplication/Task/initData';
import ArrowLeft from 'atoms/icons/arrows/ArrowLeft';
import { getDateFromEpoch } from 'lib/utils/date';
import ConnectionCertificateDetails
  from 'views/private/ConnectionApplication/Task/Details/ConnectionCertificateDetails';

export default {
  name: 'Card',
  components: {
    Details,
    ArrowLeft,
    ConnectionCertificateDetails,
  },
  props: {
    task: Object,
    isShownTaskDetails: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    const accountId = this.getAccount.id;
    const initData = initConnectionApplicationTaskData(this.taskParams, accountId);
    this.title = initData.title;
    this.data = initData.data;
    this.currentStatus = initData.status;
    window.addEventListener('resize', this.setElementsWidth);
  },
  mounted() {
    this.setElementsWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      navBarWidth: '0',
      footerWidth: '0',
      data: {},
      taskParams: {},
      title: '',
      currentStatus: {},
      // isShownTaskDetails: false,
      applicationData: {},
      reviewDocument: {},
      offerDocument: {},
      retailReviewDocument: {},
      additionalDocument: {},
      retailerOfferDocument: {},
      isDisplayAction: true,
      gridCompanyName: '',
      retailCompanyName: '',
      isLoading: true,
    };
  },
  computed: {
    fromTitle() {
      return this.getUserType === 'Customer' ? 'Заявки' : 'Задачи';
    },
    localStorageKey() {
      return `TP_${this.task.userType}_${this.task.workflowId}_${this.task.stage}`;
    },
    ...mapGetters('dataLists', [
      'energyConsumerCountList',
      'objectTypeList',
      'phaseVoltageList',
      'reliabilityClassList',
      'workloadList',
      'supplierOfLastResortList',
      'gridCompanyList',
      'confirmationDocumentTitleList',
      'tariffCategoryTypeShortList',
      'tariffZoneTypeList',
      'typeAccountingLabelList',
      'connectionApplicationTypeList',
      'reasonToConnectionList',
      'connectionApplicationWithContractTypeList',
      'documentTypeList',
      'phaseVoltageList',
      'reliabilityClassList',
    ]),
    ...mapGetters('user', ['getUserType', 'getAccount']),
  },
  watch: {
    isShownTaskDetails: {
      immediate: true,
      async handler(newVal) {
        this.isLoading = true;
        if (newVal) {
          if (this.task) {
            localStorage.setItem('connection_application_task', JSON.stringify(this.task));
            this.taskParams = this.task;
          } else if (localStorage.getItem('connection_application_task')) {
            this.taskParams = JSON.parse(localStorage.getItem('connection_application_task'));
          } else {
            this.vueShowNotification(
                'Задача не найдена',
                '',
            );
            this.$router.push('/cabinet/tasks');
            return;
          }

          await this.loadData();
          this.isLoading = false;
        } else {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    setElementsWidth() {
      if (this.$refs.techPrisContent) {
        this.navBarWidth = `${this.$refs.techPrisContent.offsetWidth + 16}px`;
        this.footerWidth = `${this.$refs.techPrisContent.offsetWidth}px`;
      }
    },
    onShownTaskDetails() {
      this.isShownTaskDetails = true;
    },
    async loadData() {
      const pureData = await this.loadDocument(this.taskParams.documentId);
      const preparedData = JSON.parse(pureData.json);
      let latitude = '';
      let longitude = '';
      if (!preparedData.ObjectLocation?.Contur?.Point[0]?.Latitude ||
        preparedData.ObjectLocation.Contur.Point[0].Latitude === '') {
        // TODO временно, пока не разберемся с геокодинком в IE (на любой адрес показывает неизвестную территорию)
        latitude = '55.753857';
        longitude = '37.619153';
      } else {
        latitude = preparedData.ObjectLocation.Contur.Point[0].Latitude;
        longitude = preparedData.ObjectLocation.Contur.Point[0].Longitude;
      }
      await this.setSuppliers({ latitude: latitude, longitude: longitude });
      this.applicationData = connectionApplicationData.dataMapper(preparedData, this);
      this.applicationData.isLoading = true;
      this.gridCompanyName = this.applicationData.gridCompany.gridCompanyName.label;
      this.retailCompanyName = this.applicationData.connectionCharacteristics.supplierOfLastResort.label;

      // TODO Объеденить загрузку файлов в один запрос
      if (this.taskParams.reviewDocumentId) {
        const pureDataReviewDoc = await this.loadDocument(this.taskParams.reviewDocumentId);
        const preparedDataReviewDoc = JSON.parse(pureDataReviewDoc.json);

        this.reviewDocument = {
          companyName: this.applicationData.gridCompany.gridCompanyName.label,
          comments: preparedDataReviewDoc.Comments,
          files: preparedDataReviewDoc.Files?.File,
        };
      }

      if (this.taskParams.offerDocumentId) {
        const pureDataOfferDoc = await this.loadDocument(this.taskParams.offerDocumentId);
        const preparedDataOfferDoc = JSON.parse(pureDataOfferDoc.json);

        this.offerDocument = {
          id: this.taskParams.offerDocumentId,
          document: pureDataOfferDoc.xml,
          isPreferential: preparedDataOfferDoc.Preferential,
          price: Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          }).format(preparedDataOfferDoc.Amount),
          unformattedPrice: preparedDataOfferDoc.Amount,
          accompanyingText: preparedDataOfferDoc.AccompanyingText,
          comments: preparedDataOfferDoc.NotPreferentialReason,
          contractDate: getDateFromEpoch(preparedDataOfferDoc.ContractDate),
          contractNumber: preparedDataOfferDoc.ContractNumber,
          technicalConditions: preparedDataOfferDoc.TechnicalСonditions ? {
            pointName: preparedDataOfferDoc.TechnicalСonditions.Points.Point[0]?.Name ?? preparedDataOfferDoc.TechnicalСonditions.Points.Point.Name,
            maxPower: preparedDataOfferDoc.TechnicalСonditions.Points.Point[0]?.MaxPower?.toString() ?? preparedDataOfferDoc.TechnicalСonditions.Points.Point.MaxPower?.toString(),
            mainSubstationObject: preparedDataOfferDoc.TechnicalСonditions.MainSubstation ? {
              mainSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.MainSubstationNumber,
              },
              feeder: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.Feeder,
              distributionSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.DistributionSubstation,
              },
              line: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.Line,
              transformationSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.TransformationSubstation,
              },
              consumerLine: preparedDataOfferDoc.TechnicalСonditions.MainSubstation.ConsumerLine,
            } : {},
            reserveSubstation: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation ? {
              mainSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.MainSubstationNumber,
              },
              feeder: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.Feeder,
              distributionSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.DistributionSubstation,
              },
              line: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.Line,
              transformationSubstation: {
                number: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.TransformationSubstation,
              },
              consumerLine: preparedDataOfferDoc.TechnicalСonditions.ReserveSubstation.ConsumerLine,
            } : undefined,
            yearOfCommissioning: String(preparedDataOfferDoc.TechnicalСonditions.YearOfCommissioning),
            utilityResponsibilities: preparedDataOfferDoc.TechnicalСonditions.UtilityResponsibilities,
            consumerResponsibilities: preparedDataOfferDoc.TechnicalСonditions.ConsumerResponsibilities,
            generalRequirements: preparedDataOfferDoc.TechnicalСonditions.GeneralRequirements,
          } : null,
        };
      }

      if (this.taskParams.retailReviewDocumentId) {
        const pureDataOfferDoc = await this.loadDocument(this.taskParams.retailReviewDocumentId);
        const preparedDataOfferDoc = JSON.parse(pureDataOfferDoc.json);

        this.retailReviewDocument = {
          comments: preparedDataOfferDoc.Comments,
          files: preparedDataOfferDoc.Files?.File,
        };
      }

      if (this.taskParams.additionalDocumentId) {
        const pureDataOfferDoc = await this.loadDocument(this.taskParams.additionalDocumentId);
        const preparedDataOfferDoc = JSON.parse(pureDataOfferDoc.json);

        this.additionalDocument = {
          comments: preparedDataOfferDoc.Comments,
          files: preparedDataOfferDoc.Files?.File,
        };
      }

      if (this.taskParams.retailOfferDocumentId) {
        const pureDataOfferDoc = await this.loadDocument(this.taskParams.retailOfferDocumentId);
        const preparedDataOfferDoc = JSON.parse(pureDataOfferDoc.json);

        this.retailerOfferDocument = {
          id: this.taskParams.retailOfferDocumentId,
          document: pureDataOfferDoc.xml,
          comments: preparedDataOfferDoc.AccompanyingText,
          number: preparedDataOfferDoc.ContractInfo?.Number ?? preparedDataOfferDoc.ContractRequisites?.ContractNumber,
          date: getDateFromEpoch(preparedDataOfferDoc.ContractInfo?.Date ?? preparedDataOfferDoc.ContractRequisites?.ContractDate),
          tariffCategory: preparedDataOfferDoc.ConsumerTariffCategory ?? preparedDataOfferDoc.ContractImportantConditions.ElectricityPurchaseContractImportantConditions.ConsumerTariffCategory,
          tariffZone: preparedDataOfferDoc.ConsumerTariffZone ?? preparedDataOfferDoc.ContractImportantConditions?.ElectricityPurchaseContractImportantConditions?.ConsumerTariffZone,
        };
      }
    },
    onClose() {
      localStorage.removeItem('connection_application_task');
      this.$router.push('/cabinet/tasks');
    },
    ...mapActions('document', ['loadDocument']),
    ...mapActions('dataLists', ['setSuppliers']),
  },
};
</script>

<style lang="sass" module>
.container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: #fff

.content
  flex-grow: 2
  margin-top: 32px
  margin-bottom: 16px
  padding-bottom: 60px

  button
    max-width: 560px
    margin: 0 auto

.navigationBar
  width: 100%
  background-color: #FFFFFF
  z-index: 1006
  // more than modal z-index
  +title-small-uppercase
  margin: 8px 16px 0 0
  padding-left: 16px

  .navigationRoot, .navigationSeparator
    color: #979CA2
    cursor: pointer

  .navigationSeparator
    font-size: 24px
    line-height: 24px

.contentFooter
  height: 48px
  display: flex

.back, .next
  +button-text
  color: #FFFFFF
  margin-bottom: 16px
  display: flex
  align-items: center
  cursor: pointer
  background-color: #2F82DF
  padding: 18px

  svg
    fill: #FFFFFF

.center
  flex-grow: 2

.back
  margin-left: 16px

  span
    margin-left: 13px
</style>
