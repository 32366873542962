<template>
  <page title="Типы элементов АХП">
    <v-table-new
      v-model="transformedAxpElementTypes"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. Типы элементов АХП"
      @delete="onDelete"
      @edit="onEdit"
    />

    <axp-element-type-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData"
    />
  </page>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchElementTypes } from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapGetters, mapMutations } from 'vuex';
import Page from 'components/Page/Page.vue';
import AxpElementTypeEdit from 'views/private/OdkAccounting/AxpElementTypes/AxpElementTypeEdit.vue';

export default {
  name: 'AxpElementTypes',
  components: {
    Page,
    VTableNew,
    AxpElementTypeEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      axpElementType: [],
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ] : [];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              width: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'axpElementTypesOdk' },
          },
          {
            label: '% монтажа',
            key: 'installationCompletionPercentage',
            type: 'number',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          ...editHeaders,
        ],
      };
    },
    transformedAxpElementTypes() {
      if (!this.axpElementType?.length) {
        return [];
      }

      return this.axpElementType;
    },
    isAccessUpdate() {
      return filterForAccess('process/1/2', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    async loadData() {
      try {
        this.loading = true;
        this.axpElementType = (await fetchElementTypes()).map((it) => ({
          ...it,
        }));
      } catch (error) {
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
