<template>
  <inner-page :history="history"
              :steps="[title]"
              history-title="Детали"
              home="Задачи"
              @back="$router.back()"
              @goHome="goHome"
  >
    <div :class="$style.title">
      Технологическое присоединение
    </div>

    <div :class="$style.date">
      Дата создания: <span>{{ data.date }}</span>
    </div>

    <v-divider />

    <div v-if="data.isDisplayApplicationDocument" :class="$style.documentContainer">
      <v-file-attachment :document-id="data.documentId"
                         href="/"
                         label="Заявка на технологическое присоединение"/>
    </div>

    <div>
      <summary-application
          v-if="applicationData.consumer"
          :connection-application="applicationData"
          :expansion="summaryProps.expansion"
          :document-id="data.documentId"
          :init-spoiler-offer="summaryProps.initSpoilerOffer"
          :init-spoiler="false"
          :offer-document="offerDocumentSummary"
          :offer-document-title="offerDocumentTitle"
          :only-microgenerator="data.onlyMicrogenerator"
          :connection-certificate-id="data.connectionCertificateId"
          :admission-certificate-id="data.admissionCertificateId"
          :utility-offer-document-id="data.utilityOfferDocumentId"
          :technical-conditions-act-document-id="data.technicalConditionsActDocumentId"
          :retail-offer-document-id="retailerOfferDocument.id"
          :show-map="summaryProps.showMap"
          spoiler-type="v-spoiler"
          title="Детали заявки"
      />

      <div v-if="isLoading" :class="$style.loader">
        <v-circle-loader/>
      </div>

      <component
        :is="data.actionComponent"
        v-if="data.actionComponent"
        :account-id="accountId"
        :action-props="data.actionProps"
        :additional-document="additionalDocument"
        :application-data="applicationData"
        :application-id="applicationId"
        :document-id="data.documentId"
        :grid-company-name="gridCompanyName"
        :invoice-id="data.invoiceId"
        :is-display="isDisplayActionData"
        :offer-document="offerDocument"
        :only-microgenerator="data.onlyMicrogenerator"
        :retail-company-name="retailCompanyName"
        :retail-review-document="retailReviewDocument"
        :retailer-offer-document="retailerOfferDocument"
        :review-document="reviewDocument"
        :subtitle="data.subtitle"
        :workflow-id="data.workflowId"
        :is-loading="isLoading"
        style="flex-grow: 3"
        @close="onClose"
      />

      <div v-else style="flex-grow: 3">&nbsp;</div>
    </div>

    <submit-task-details v-if="!data.actionComponent" :class="$style.buttonNoAction" role="close" @submit="onClose"/>

    <submit-task-details v-else-if="!isDisplayActionData" :class="$style.buttonNoAction"
                         :title="data.displayActionTitle" role="displayAction" @submit="isDisplayActionData = true"/>
  </inner-page>
</template>

<script>
import DialogTemplate from 'templates/DialogTemplate';

import ScrollingHeader from 'molecules/ScrollingHeader';
import TaskStatus from 'molecules/TaskStatus';
import SummaryApplication from 'views/private/ConnectionApplication/steps/Summary';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import ApprovedApplication from 'views/private/ConnectionApplication/Task/actions/ApprovedApplication';
import CorrectionApplication from 'views/private/ConnectionApplication/Task/actions/CorrectionApplication';
import CorrectionApplicationMicrogenerator
  from 'views/private/ConnectionApplication/Task/actions/CorrectionApplicationMicrogenerator';
import SelectTariff from 'views/private/ConnectionApplication/Task/actions/SelectTariff';
import ApprovedOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedOffer';
import AdditionalInfo from 'views/private/ConnectionApplication/Task/actions/AdditionalInfo';
import SendRetailerOffer from 'views/private/ConnectionApplication/Task/actions/SendRetailerOffer';
import RetailerOfferSubmitted from 'views/private/ConnectionApplication/Task/actions/RetailerOfferSubmitted';
import ApprovedRetailerOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedRetailerOffer';
import SendConnectionCertificate from 'views/private/ConnectionApplication/Task/actions/SendConnectionCertificate';
import ConnectionCertificateDetails from 'views/private/ConnectionApplication/Task/Details/ConnectionCertificateDetails';
import SendAdmissionCertificate from 'views/private/ConnectionApplication/Task/actions/SendAdmissionCertificate';
import Cancelled from 'views/private/ConnectionApplication/Task/actions/Cancelled';
import VDialogHeader from 'components/VDialog/VDialogHeader';
import VFileAttachment from 'components/VFileAttachment/VFileAttachment';
import VCircleLoader from 'components/VCircleLoader/VCircleLoader';
import { InnerPage, VSpoiler, VDivider } from '@/components';

export default {
  name: 'Details',
  components: {
    VFileAttachment,
    VDialogHeader,
    DialogTemplate,
    ScrollingHeader,
    SubmitTaskDetails,
    SummaryApplication,
    TaskStatus,
    ApprovedApplication,
    CorrectionApplication,
    CorrectionApplicationMicrogenerator,
    SelectTariff,
    ApprovedOffer,
    AdditionalInfo,
    SendRetailerOffer,
    RetailerOfferSubmitted,
    ApprovedRetailerOffer,
    SendConnectionCertificate,
    SendAdmissionCertificate,
    Cancelled,
    VCircleLoader,
    ConnectionCertificateDetails,
    InnerPage,
    VSpoiler,
    VDivider,
  },
  props: {
    value: Boolean,
    data: Object,
    summaryProps: {
      type: Object,
      default: () => ({
          expansion: true,
          showMap: true,
          initSpoiler: false,
        }),
    },
    applicationId: String,
    accountId: String,
    applicationData: Object,
    retailCompanyName: String,
    gridCompanyName: String,
    reviewDocument: Object,
    offerDocument: Object,
    retailReviewDocument: Object,
    additionalDocument: Object,
    retailerOfferDocument: Object,
    offerDocumentTitle: String,
    isDisplayAction: {
      type: Boolean,
      default: true,
    },
    isDisplayApplicationDocument: {
      type: Boolean,
      default: false,
    },
    hideOfferInSummary: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isDisplayActionData = this.isDisplayAction;
  },
  data() {
    return {
      visible: false,
      spoiler: {
        summary: true,
        documents: false,
        status: false,
        tariff: false,
        upload: false,
      },
      preparedData: {},
      isDisplayActionData: true,
    };
  },
  computed: {
    offerDocumentSummary() {
      if (this.hideOfferInSummary) {
        return undefined;
      }
      return this.offerDocument;
    },
    scrollStyle() {
      const height = document.documentElement.clientHeight;

      return {
        height: `${height - 100}px`,
      };
    },
    history() {
      return this.data.statusList.map((item, index) => ({
        message: `${item.title} ${item.subTitle}`,
        current: this.data.statusIndex === index,
      }));
    },
    title() {
      if (this.data.actionComponent === 'SendConnectionCertificate') {
        return 'Формирование АТП';
      }
      if (this.data.actionComponent === 'SendAdmissionCertificate') {
        return 'Формирование АДПУ';
      }
      return this.data.actionComponent ?? 'Задача';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
        if (!val) {
          this.isDisplayActionData = this.isDisplayAction;
        }
      },
    },
  },
  methods: {
    onClose() {
      this.visible = false;
      this.$emit('close');
    },
    goHome() {
      this.$router.push('/cabinet/tasks/');
    },
  },
};
</script>

<style lang="scss" module>
.documentContainer {
  margin-left: 70px;
  margin-bottom: 40px;
}

.container {
  padding-top: 20px;
  overflow-y: auto;
}

.containerInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.status {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 0 40px;
}

.errorText + .small-text {
  margin: 4px;
  color: red;
}

.buttonNoAction {
  padding-top: 24px;
  margin-top: auto;
}

.loader {
  position: relative;
  height: 50px;
  margin-bottom: 32px;
}

.title {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}

.date {
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;

  span {
    font-weight: 600;
  }
}
</style>
