export const user = {
  groups: [
    {
      title: 'Объекты',
      route: 'objects',
      iconComponent: 'HomeWork',
    },
    {
      title: 'Заявки',
      route: 'tasks',
      iconComponent: 'Tasks',
    },
    {
      title: 'Технологическое присоединение',
      iconComponent: 'TpGroup',
      items: [
        {
          title: 'Заявки на технологическое присоединение',
          route: 'tpo',
          iconComponent: 'Tp',
        },
        {
          title: 'Договоры об осуществлении технологического присоединения',
          route: 'connection-application-contracts',
          iconComponent: 'AgreementsImplementationTechnologicalConnection',
        },
      ],
    },
    {
      title: 'Энергоснабжение',
      iconComponent: 'FlashAuto',
      items: [
        {
          title: 'Договоры энергоснабжения',
          route: 'electricity-supply-contracts',
          iconComponent: 'LibraryBooks',
        },
      ],
    },
    {
      title: 'Оплата',
      route: 'pay',
      iconComponent: 'Pay',
    },
    // TODO раздел в разработке
    // {
    //   title: 'УСЛУГИ',
    //   route: 'services',
    //   iconComponent: 'Service',
    // },
    {
      title: 'Документы',
      route: 'documents',
      iconComponent: 'Documents',
    },
    {
      title: 'Профиль',
      route: 'user-details',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Доступные профили',
      route: 'available-profiles',
      iconComponent: 'UserDetails',
    },
    {
      title: 'Чаты',
      route: 'user-requests',
      iconComponent: 'Chat',
    },
    // TODO раздел в разработке
    // {
    //   title: 'НАСТРОЙКИ',
    //   route: 'settings',
    //   iconComponent: 'MiscellaneousServices',
    // },
  ],
};
