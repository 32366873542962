<template>
  <div :class="$style['container']" :style="`--color-background:${color}`">
    <div :class="$style['container__indicator']">
      <slot name="indicator"></slot>
    </div>
    <div :class="$style['container__text']">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VIndicator',
  props: {
    color: {
      type: String,
      default: '#FF7400',
    },
  },
};
</script>

<style lang="scss" module>
.container {
  padding: 16px;
  background: #F5F6F6;
  display: flex;
  max-width: 194px;
  position: relative;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.07));
  margin-left: 34px;
  margin-bottom: 4px;


  div {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #262729;
    min-height: 36px;
  }

  &__indicator {
    margin: auto 0;
    border-radius: 100%;
    background: #FFF;
    width: 32px;
    height: 32px;
    padding: 16px;
    position: absolute;
    left: -18px;
    top: 16px;
    bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0 auto 8px;
  }

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: -34px;
    background: var(--color-background);
    width: 30px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    display: inline-block;
    height: 100%;
  }
}
</style>
