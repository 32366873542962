<template>
  <button
    :class="{
      [$style.button]: true,
      [$style[`button--${variant}`]]: true,
    }"
    :type="type"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    variant: { type: String, default: 'filled' },
    type: { type: String, default: 'button' },
  },
};
</script>

<style lang="scss" module>
.button {
  border-radius: 24px !important;
  padding: 10px 25px !important;

  font-family: 'Roboto Condensed', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  &:disabled {
    background-color: #7e8b91 !important;
  }

  &--filled {
    background-color: #2f82df !important;
    color: #ffffff !important;
  }

  &--filled--no-radius {
    background-color: #2f82df !important;
    color: #ffffff !important;
    border-radius: 0 !important;
  }

  &--outlined {
    color: #4C4E51 !important;
    background-color: #ffffff !important;
    border: 1px solid #dee2e6 !important;
  }

  &--outlined-colored {
    color: #4C4E51;
    background-color: #FFFFFF;
    border: 1px solid #2f82df;

    &:disabled {
      background-color: #ffffff;
      border: 1px solid #2f82df;
      opacity: 0.2;
    }
  }

  &--outlined-gray {
    background-color: #E9EBED;
    border: 1px solid #DEE2E6;
  }

  &--outlined-gray-2 {
    background-color: #C1C4C7;
    border: 1px solid #DEE2E6;
  }

  &--outlined-gray-3 {
    background-color: #FFFFFF;
    border: 1px solid #979CA2;
  }

  &--outlined-red {
    color: #4C4E51 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #EB5757 !important;

    &:disabled {
      background-color: #ffffff !important;
      border: 1px solid #EB5757 !important;
      opacity: 0.2 !important;
    }
  }

  &--outlined-green {
    color: #34b712;
    background-color: #FFFFFF;
    border: 1px solid #2bad08;

    &:disabled {
      background-color: #ffffff;
      border: 1px solid #2bad08;
      opacity: 0.2;
    }
  }
}
</style>
