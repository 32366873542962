<template>
  <div>
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />

    <v-table-new
      v-model="transformActs"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :pagination="pagination"
      :template="templateNew"
      excel-report-file-name="Акты снятия показаний приборов учёта и акты по отпуску и приему электроэнергии по границе балансовой принадлежности"
      @on-cell-click="cellClickHandler"
    />
  </div>
</template>

<script>
import { ModalProgressBar, VTableNew } from '@/components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { convertDbDateTimeFormat, convertDbFormat, getAccountingPeriodMonth } from 'lib/utils/date';
import { completedByStatus, formatContractStatus, getContractStatusList } from 'lib/utils/formatXmlType';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'ContentBlock',
  components: {
    VTableNew,
    ModalProgressBar,
  },
  props: {
    acts: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    statusOptions: { type: Array, required: true },
    suppliersOptions: { type: Array, required: true },
    pagination: { type: Object, required: true },
    onPaginationChange: { type: Function, required: false },
  },
  data() {
    return {
      isModalVisible: false,
      isProcessComplete: false,
    };
  },
  computed: {
    templateNew() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Дата формирования',
            key: 'date',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Наименование',
            key: 'title',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            type: 'clip',
            keyClip: 'correlationId',
            sort: {},
            filter: {},
          },
          {
            label: 'Месяц',
            key: 'accountingPeriodMonth',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'Год',
            key: 'accountingPeriodYear',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
          {
            label: 'ТСО 1',
            key: 'tsoOne',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'ТСО 2',
            key: 'tsoTwo',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Статус со стороны ТСО 1',
            key: 'statusOne',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны ТСО 2',
            key: 'statusTwo',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '90px',
            },
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Сформировано автоматически',
            key: 'autoGenerated',
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformActs() {
      if (this.acts.length === 0) {
        return [];
      }

      return this.acts.map((it) => ({
        date: convertDbFormat(it.createdAt),
        title: it.title,
        accountingPeriodMonth: getAccountingPeriodMonth(it.accountingPeriod),
        accountingPeriodYear: it.accountingPeriod.year,
        tsoOne: it.sideSecond.name,
        tsoTwo: it.sideThird.name,
        statusOne: formatContractStatus(it.sideSecond.status),
        statusTwo: formatContractStatus(it.sideThird.status),
        documentId: it.documentId,
        dbId: it.id,
        correlationId: it.borderDeviceActId,
        rejectedReason: it.rejectedReason,
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        status: it.status,
        actVerificationDocumentId: it.actVerification?.documentId,
        document: { documentId: it.documentId, name: it.title },
        act: this.checkEmptyAct(it.actVerification),
        initiatorAccountId: it.initiatorAccountId,
        initiatorUserId: it.initiatorUserId,
        autoGenerated: it.autoGenerated ? 'Сформировано автоматически' : '',
      }));
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать акты',
          method: goToPageByRoute('integral-acts/generate-integral-act'),
        },
      ];
    },
    ...mapGetters('user', ['getAccount', 'getUser']),
  },
  methods: {
    checkEmptyAct(act) {
      if (act) {
        return { documentId: act.documentId, name: 'Акт верификации' };
      }
      return null;

    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    async cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          if (cell.row.status === 'WAITING_VERIFICATION_ACT') {
            const domainDocument = await this.getDomainDocument(cell.row.dbId);
            return await this.$router.push({
              name: 'cabinet.document-scan-upload',
              params: {
                ...domainDocument,
                documentXmlType: domainDocument.xmlDocumentType,
                documentCreatedAt: convertDbDateTimeFormat(domainDocument.createdAt),
                from: 'integral-acts',
                fromTitle: 'ИНТЕГРАЛЬНЫЕ АКТЫ',
              },
            });
          }
          let res;

          this.acts.forEach((act) => {
            if (act.id === cell.row.dbId) {
              res = act;
            }
          });

          return this.link(res);

        }
      }
    },
    link(task) {
      const currentSideStatus = [task.sideSecond, task.sideThird].find((it) => it.accountId === this.getAccount.id).status;
      let workflowType;
      let title;
      switch (currentSideStatus) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        case 'REJECTED': {
          if (this.getUser.id !== task.initiatorUserId) {
            workflowType = 'documentSigning';
            break;
          }
          workflowType = 'documentReload';
          title = `Исправьте ${task.title}`;
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: task.id,
          from: 'integral-acts',
          fromTitle: 'ИНТЕГРАЛЬНЫЕ АКТЫ',
          to: workflowType,
          title: title,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    ...mapMutations('integralAct', ['setIntegralAct']),
    ...mapActions('document', ['getDomainDocument', 'loadDocument']),
  },
};
</script>

<style lang="scss" module></style>
