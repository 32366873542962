// import { read, utils } from 'xlsx';

export const createDisposableWorker = (response) => {
  const URL = window.URL || window.webkitURL;
  const blob = new Blob([response], { type: 'application/javascript' });
  const objectURL = URL.createObjectURL(blob);
  const worker = new Worker(new URL(objectURL));
  worker.post = (message) => new Promise((resolve, reject) => {
    worker.onmessage = (event) => {
      URL.revokeObjectURL(objectURL);
      resolve(event.data);
    };
    worker.onerror = (e) => {
      console.error(`Error: Line ${e.lineno} in ${e.filename}: ${e.message}`);
      reject(e);
    };
    worker.postMessage({ message });
  });
  return worker;
};

const makeResponse = (work) => `
  importScripts('https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js')
  importScripts('https://unpkg.com/xlsx@0.18.5/dist/xlsx.full.min.js')
  self.onmessage = function(event) {
    const args = event.data.message.args
    if (args) {
      self.postMessage((${work}).apply(null, args))
      return close()
    }
    self.postMessage((${work})())
    return close()
  }
`;

export default class VWorker {
  static run(work, args) {
    const worker = createDisposableWorker(makeResponse(work));
    return worker.post({ args });
  }
}
