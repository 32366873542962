<template>
  <v-app class="vuetify-styles" data-app>

    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Подключение/Смена точки подключения</div>
          </v-card-title>
          <v-card-text>
            <v-row class="mb-8">
              <v-col>
                <div id="maps">
                  <yandex-map
                    ref="customMap"
                    :collection-axp="adreskaFactCollection"
                  />
                </div>
              </v-col>
            </v-row>

            <contractor-autocomplete
              v-if="isBoiler"
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-disabled
              is-required
              title="Подрядчик"
              url="/contractors/find?name="
              url-default-data="/contractors/top20"
            ></contractor-autocomplete>

            <autocomplete-find-id
              v-if="contractorId && eventId"
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              is-disabled
              :items.sync="adreskaItems"
              :search.sync="adreskaAddress"
              :url-default-data="`/adreska/contractor/{0}/event/{1}/top20?statuses=MOUNTING_IN_PROCESS, MOUNTED, IN_EXPLOITATION`"
              :url-search="`/adreska/contractor/{0}/event/{1}/find?name={2}&statuses=MOUNTING_IN_PROCESS, MOUNTED, IN_EXPLOITATION`"
              is-required
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
            ></autocomplete-find-id>

            <div v-if="adreska">

              <adreska-history :adreska-id="adreska.id" subselect="INSTALLATION_CONTROL" title="Информация о монтаже"/>

              <text-field
                v-model="adreska.event.name"
                is-disabled
                title="Мероприятие"
              ></text-field>

              <text-field
                v-model="adreska.axp.odk.name"
                is-disabled
                title="ОДК"
              ></text-field>

              <text-field
                v-model="adreska.axp.name"
                is-disabled
                title="АХП"
              ></text-field>

              <text-field
                ref="administrativeDistrict"
                v-model="adreska.administrativeDistrict.name"
                is-disabled
                title="Административный округ г. Москвы установки ОДК"
              ></text-field>

              <text-field
                ref="responsibleRES"
                v-model="adreska.responsibleDistrictDepartmentName"
                is-disabled
                title="Ответственный РЭС"
              ></text-field>

              <text-field
                ref="quantityPlan"
                v-model="adreska.quantityPlan"
                is-disabled
                title="Количество по плану"
              ></text-field>

              <text-field
                ref="workModePlan"
                v-model="adreska.workModePlan"
                is-disabled
                title="Режим работы ОДК по плану"
              ></text-field>
            </div>

            <text-field
              ref="countOdk"
              v-model="countOdk"
              is-disabled
              is-required
              title="Количество ОДК"
            ></text-field>

            <text-field
                v-if="comment"
                v-model="comment"
                is-disabled
                title="Комментарий"
            ></text-field>

            <text-field
              ref="connectionPoint"
              v-model="connectionPoint"
              is-required
              title="Точка подключения"
            ></text-field>

            <address-autocomplete ref="addressConnectionPoint" v-model="addressConnectionPoint"
                                  :items.sync="addresses" :search.sync="addressConnectionPointName"
                                  is-required
                                  title="Адрес установки автоматического выключателя, коммутирующего ОДК"></address-autocomplete>

            <text-field
              ref="switchBoard"
              v-model="switchBoard"
              is-required
              title="ТМ ВРШ"
            ></text-field>

            <v-row class="no-margin-top mt-1 mb-3">
              <v-checkbox
                v-model="isConnectedGenerator"
                class="no-margin-top"
                color="success"
                hide-details
                label="Возможность подключения ДГУ или бензогенератора"
              ></v-checkbox>
            </v-row>

            <v-row class="no-margin-top mt-1 mb-3">
              <v-checkbox
                v-model="isConnectedElectricity"
                class="no-margin-top"
                color="success"
                hide-details
                label="Подключено к электропитанию?"
              ></v-checkbox>
            </v-row>

            <v-row class="no-margin-top mt-1 mb-3">
              <v-checkbox
                v-model="readyForInput"
                class="no-margin-top"
                color="success"
                hide-details
                label="Готово к вводу/включению?"
              ></v-checkbox>
            </v-row>

            <simple-autocomplete-by-function ref="workModePlan" v-model="workModeFact"
                                             :fetch-data-function="(name)=>workModeItems.filter((it)=>it.name.toLowerCase().includes(name?.toLowerCase() ?? ''))"
                                             :fetch-default-data-function="()=>workModeItems"
                                             :items.sync="workModeItemsSync"
                                             :search.sync="workModeFactName"
                                             is-required
                                             title="Режим работы ОДК по факту"/>

          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import AutocompleteFindId from '@/components/Processes/components/AutocompleteFindId.vue';
import TextField from '@/components/Processes/components/TextField.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import moment from 'moment';
import AddressAutocomplete from 'components/Processes/components/AddressAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import { workModeList } from 'components/Processes/list';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';

export default {
  components: {
    SimpleAutocompleteByFunction,
    AdreskaHistory,
    AddressAutocomplete,
    ContractorAutocomplete,
    YandexMap,
    AutocompleteFindId,
    TextField,
  },

  async created() {
    if (!this.isBoiler) this.contractorId = this.getAccount.supplier.id;
    await this.initAdreskaId();
    await this.initAssignmentForConnection();
  },

  data: () => ({
    progressValue: 0,
    updateMap: 0,
    loaderDialog: false,


    adreska: null,
    contractorId: null,
    adreskaId: null,
    eventId: null,
    addressConnectionPointName: '',

    contractorName: '',
    adreskaAddress: '',
    eventName: '',
    eventItems: [],
    contractors: [],
    adreskaItems: [],
    addresses: [],
    events: [],

    adreskaFactCollection: [],

    connectionPoint: null,
    addressConnectionPoint: null,
    isConnectedGenerator: false,
    isConnectedElectricity: false,
    administrativeDistrict: null,
    responsibleRES: null,
    readyForInput: false,
    switchBoard: null,
    workModeFact: null,
    plannedInstallationDateText: null,
    plannedInstallationDate: null,
    onlyAssigned: false,

    workModeItems: workModeList.map((it) => ({
      id: it,
      name: it,
    })),
    workModeItemsSync: [],
    workModeFactName: '',

    comment: null,
  }),

  computed: {
    countOdk() {
      return this.adreskaId ? this.adreskaFactCollection.length : 0;
    },
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisabledAdreska = !this.adreskaId;
      const isDisabledConnectionPoint = !this.connectionPoint?.length;
      const isDisabledAddressConnectionPoint = !this.addressConnectionPoint?.length;
      const isDisableSwitchBoard = !this.switchBoard?.length;

      return (
        isDisabledContractor ||
        isDisabledAdreska ||
        isDisabledConnectionPoint ||
        isDisabledAddressConnectionPoint ||
        isDisableSwitchBoard
      );
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'getAccount', 'getAuthorization', 'isBoiler']),
  },

  watch: {
    onlyAssigned() {
      this.fetchAdreskaFactCollection();
    },
    async adreskaId(newVal) {
      if (newVal) {
        await this.fetchAdreska();
        await this.fetchAdreskaFactCollection();
        this.onTapPlacemark(newVal);
      }
    },

  },

  methods: {
    async initAdreskaId() {
      if (!this.$route.params.initAdreskaId) {
        await this.setNotification({ message: 'Не найден ID объекта' });
        this.onTapBack();
        return;
      }

      this.adreskaId = this.$route.params.initAdreskaId;
    },
    async initAssignmentForConnection() {
      if (!this.$route.params.assignmentForConnectionId) {
        return;
      }

      const response = await fetch(`${BASE_URL}/assignment-for-connection`, {
            headers: {
              'X-Authorization': `Bearer ${this.getToken}`,
            },
          });

      const responseData = await response.json();
      const assignmentForConnection = responseData.find((item) => item.id === this.$route.params.assignmentForConnectionId);

      this.comment = assignmentForConnection?.comment;
    },
    formatDateFromOdkTimestamp,
    async onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        adreskaId: this.adreskaId,
        connectionPoint: this.connectionPoint,
        addressConnectionPoint: this.addressConnectionPoint,
        isConnectedGenerator: this.isConnectedGenerator,
        isConnectedElectricity: this.isConnectedElectricity,
        readyForInput: this.readyForInput,
        switchBoard: this.switchBoard,
        workModeFact: this.workModeFact,
      };

      axios
        .post(`${BASE_URL}/adreska-connection`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapPlacemark(id) {
      const fact = this.adreskaFactCollection.find((it) => it.adreskaId === id);

      if (!fact) {
        return;
      }
      this.contractors = [{
        id: fact.contractorId,
        name: fact.contractorName,
      }];
      this.contractorId = fact.contractorId;
      this.contractorName = fact.contractorName;

      this.eventItems = [{
        id: String(fact.eventId),
        name: fact.eventName,
      }];
      this.eventId = String(fact.eventId);
      this.eventName = fact.eventName;

      this.adreskaItems = [{
        id: fact.adreskaId,
        name: fact.adreskaName,
      }];

      this.adreskaAddress = fact.adreskaName;
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.progressValue = 0;
      this.updateMap = 0;
      this.contractorId = null;
      this.contractorName = '';
      this.contractors = [];
      this.adreskaId = null;
      this.adreska = null;
      this.adreskaAddress = '';
      this.adreskaItems = [];
      this.eventItems = [];
      this.adreskaFactCollection = [];
      this.connectionPoint = null;
      this.addressConnectionPoint = null;
      this.isConnectedGenerator = false;
      this.isConnectedElectricity = false;
      this.administrativeDistrict = null;
      this.responsibleRES = null;
      this.workModeFact = null;
      this.plannedInstallationDate = null;
      this.readyForInput = false;
      this.switchBoard = null;
      this.loaderDialog = false;
      this.addresses = [];
      this.events = [];
      this.eventName = '';
      this.eventId = null;
      this.addressConnectionPointName = '';
      this.workModeItemsSync = [];
      this.workModeFactName = '';
      this.onlyAssigned = false;
      this.workModeItemsSync = [];
    },
    async fetchAdreska() {
      const response = await fetch(
        `${BASE_URL}/adreska/${this.adreskaId}`,
      );
      const adreskaSummaryResponse = await fetch(
        `${BASE_URL}/adreska-summary-entity?adreskaId=${this.adreskaId}`,
      );
      if (response.status === 200 && adreskaSummaryResponse.status === 200) {
        this.adreska = await response.json();
        const adreskaSummary = await adreskaSummaryResponse.json();
        this.connectionPoint = adreskaSummary.connectionPoint;
        this.addressConnectionPoint = adreskaSummary.addressConnectionPoint;
        this.addressConnectionPointName = adreskaSummary.addressConnectionPoint;
        this.addresses = [{
          id: adreskaSummary.addressConnectionPoint,
          name: adreskaSummary.addressConnectionPoint,
        }];
        this.administrativeDistrict = adreskaSummary.administrativeDistrict;
        this.isConnectedGenerator = adreskaSummary.isConnectedGenerator;
        this.isConnectedElectricity = adreskaSummary.isConnectedElectricity;
        this.readyForInput = adreskaSummary.readyForInput;
        this.switchBoard = adreskaSummary.switchBoard;
        this.workModeFact = adreskaSummary.workModeFact;
        this.workModeFactName = adreskaSummary.workModeFact;
        // Может быть 0
        if (adreskaSummary.plannedInstallationDate !== null && adreskaSummary.plannedInstallationDate !== undefined) {
          this.plannedInstallationDate = moment(formatDateFromOdkTimestamp(adreskaSummary.plannedInstallationDate), 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.plannedInstallationDateText = formatDateFromOdkTimestamp(adreskaSummary.plannedInstallationDate);
        }
      } else {
        this.adreska = null;
        this.adreskaSummary = null;
      }
    },
    async fetchAdreskaFactCollection() {
      const response = await fetch(this.onlyAssigned ? `${BASE_URL}/axp/connection/regular-inspection-assigned` : `${BASE_URL}/adreska/adreskafact?statuses=MOUNTING_IN_PROCESS, MOUNTED, IN_EXPLOITATION`, {
        headers: {
          'X-Authorization': `Bearer ${this.getToken}`,
        },
      });

      const data = await response.json();

      this.adreskaFactCollection = data.filter((it) => it.adreska.id === this.adreskaId).map((el) => {
        let color;
        let status;
        if (el.statusAssemblyAcceptance == 1) {
          color = 'green';
          status = 'В эксплуатации';
        } else if (el.statusConnection == 1) {
          color = 'yellow';
          status = 'Подключено';
        } else {
          color = 'blue';
          status = 'Подключение не проводилось';
        }

        return {
          id: el.id,
          locationLatitude: el.locationLatitude,
          locationLongitude: el.locationLongitude,
          statusAssemblyAcceptance: el.statusAssemblyAcceptance,
          adreskaId: el.adreska.id,
          eventId: el.adreska.event.id,
          eventName: el.adreska.event.name,
          adreskaName: el.adreska.name,
          contractorId: el.adreska.contractorId,
          contractorName: el.adreska.contractorName,
          isSelected: el.adreska.id === this.adreskaId,
          countOdk: el.countOdk,
          color: color,
          balloonContent: {
            title: el.axp.odk.name,
            props: [
              {
                label: 'ID',
                value: el.id,
              },
              {
                label: 'АХП',
                value: el.axp.name,
              },
              {
                label: 'Тип декора',
                value: el.decorType?.name,
              },
              {
                label: 'Подрядчик',
                value: el.adreska.contractorName,
              },
              {
                label: 'Статус',
                value: status,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(el.createDate),
              },
            ],
          },
        };
      });
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

