<template>
  <c-dialog
      v-model="visible"
      :center="center"
      :height100="height100"
      :collapse="collapse"
      :style-type="styleType"
      :hide-close-button="hideCloseButton"
      :top="top"
      @close="$emit('close')"
  >
    <div
      :class="{
          [$style.container]: true,
          [$style.height100]: height100,
          [$style.roundedStyle]: styleType === 'roundedStyle',
        }"
    >
      <slot/>
    </div>
  </c-dialog>
</template>

<script>
import CDialog from 'atoms/common/CDialog.vue';

export default {
  name: 'DialogTemplate',
  components: {
    CDialog,
  },
  props: {
    value: Boolean,
    height100: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      required: false,
    },
    top: {
      type: Number,
      default: 0,
    },

  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  max-height: 100%;
  max-width: 700px;
  position: relative;
  align-self: center;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  background: #fff;
}

.height100 {
  height: 100%;
}

.roundedStyle {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  max-width: 960px;
}
</style>
