<template>
  <v-spoiler summary="Информационный измерительный комплекс">
    <div class="meter-info">
      <v-input
        v-model="internalValue.meterPlace"
        :disabled="!isEditing"
        class="meter-info__item meter-info__item--100"
        clearable
        title="Место установки прибора учёта *"
      />
    </div>

    <div class="meter-info">
      <v-select
        v-model="internalValue.meter.type"
        :disabled="!isEditing"
        :options="meterOptions"
        class="meter-info__item meter-info__item--select"
        title="Марка прибора учёта *"
      />

      <v-input
        v-model="internalValue.meter.serialNumber"
        :disabled="!isEditing"
        class="meter-info__item"
        clearable
        title="Номер прибора учёта *"
      />

      <img alt="Подробнее" class="follow-arrow" src="~@/assets/icons/navigation/follow-arrow.svg" @click="goToMeter"/>
    </div>

    <div class="meter-info">
      <v-select
        v-model="internalValue.currentTransformer.type"
        :disabled="!isEditing"
        :options="currentTransformerOptions"
        class="meter-info__item meter-info__item--select"
        title="Марка трансформатора тока *"
      />

      <v-input
        v-model="displayCurrentTransformerSerialNumber"
        :disabled="!isEditing"
        class="meter-info__item"
        clearable
        title="Номер трансформатора тока *"
      />
    </div>

    <div class="meter-info">
      <v-select
        v-if="false"
        v-model="internalValue.currentTransformer.type"
        :disabled="!isEditing"
        :options="meterOptions"
        class="meter-info__item meter-info__item--select"
        title="Марка трансформатора напряжения *"
      />

      <v-input
        v-if="false"
        v-model="internalValue.potentialTransformer.serialNumber"
        :disabled="!isEditing"
        class="meter-info__item"
        clearable
        title="Номер трансформатора напряжения *"
      />
    </div>
  </v-spoiler>
</template>

<script>
import { VInput, VSelect, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'MeterInfo',
  components: {
    VInput,
    VSelect,
    VSpoiler,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        meter: {
          location: '',
          type: '',
          serialNumber: '',
        },

        currentTransformer: {
          type: '',
          serialNumber: '',
        },

        potentialTransformer: {
          type: '',
          serialNumber: '',
        },
      },
      internalValue: {},
      options: [],
      meterOptions: [],
      currentTransformerOptions: [],
    };
  },
  computed: {
    displayCurrentTransformerSerialNumber() {
      return this.internalValue.currentTransformer.serialNumber || 'Неизвестно';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);

        if (this.options.length === 0) {
          this.currentTransformerOptions = [
            {
              value: this.internalValue.currentTransformer?.type,
              label: this.internalValue.currentTransformer?.type,
            },
          ];
          this.meterOptions = [
            {
              value: this.internalValue.meter?.type,
              label: this.internalValue.meter?.type,
            },
          ];
          this.options = [
            {
              value: this.internalValue.boundariesBalanceSheet,
              label: this.internalValue.boundariesBalanceSheet,
            },
          ];
        }
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    goToMeter() {
      this.$router.push(`/cabinet/meter/${this.internalValue.meter.id}`);
    },
  },
};

</script>

<style lang="scss" scoped>
.meter-info {
  display: flex;
  gap: 16px;

  &__item {
    width: 330px;
    margin-top: 24px;

    &--select {
      margin-top: 48px;
    }

    &--100 {
      width: 100%;
      margin-top: 0;
    }
  }

  img {
    margin-top: 24px;
    cursor: pointer;
  }

  img.follow-arrow {
    margin-top: 48px;
  }
}
</style>
