<template>
  <modal
    :name="name"
    :draggable="drag"
    adaptive
    reset
    scrollable
    :min-height="300"
    :height="height100 ? '90%': height"
    v-on="$listeners"
  >
    <div  class="dialog">
      <i v-if="close" class="iconClose" @click="$modal.hide(name)"></i>
      <i v-if="rollUp" class="collapse_icon" @click="onMinimize()"></i>
      <slot> </slot>
      <slot name="footer"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'VDialog',
  props: {
    name: { type: String, required: true },
    height100: { type: Boolean, default: false },
    height: { type: String, default: 'auto' },
    drag: {
      type: Boolean,
      default: false,
    },
    rollUp: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentHeight: Infinity,
      prevHeight: Infinity,
    };
  },
  methods: {
    onMinimize() {
      const modal = document.getElementsByClassName('v--modal')[0];
      const height = `${modal.clientHeight}px`;
      if (modal.style.height === '60px') {
        this.currentHeight = this.prevHeight;
        this.prevHeight = height;
        modal.style.height = this.currentHeight;
      } else {
        this.currentHeight = '60px';
        this.prevHeight = height;
        modal.style.height = this.currentHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.iconClose {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  z-index: 1007;
  top: 14px;
  right: 14px;
  background-image: url('~icons/modal/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  cursor: pointer;
}

.collapse_icon {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  z-index: 1007;
  top: 14px;
  right: 40px;
  background-image: url('~icons/modal/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 175%;
  cursor: pointer;
}
</style>
