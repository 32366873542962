<template>
  <div>
    <div>Доверенность:</div>
    <div :class="$style['container']">
      <div :class="$style['select']" @click="visible = true">
        №&nbsp;{{ attorneyName }}
        <div v-if="visible" v-custom-click-outside="close" :class="$style['select__options']">
          <p v-for="(attorney, attorneyIndex) in value" :key="'attorney' + attorneyIndex" :class="$style['select__option']" @click="$router.push('/cabinet/attorney')">
            <span :class="$style.download">№&nbsp;{{ attorney.number }}</span>
            <span v-if="isExpired(attorney)" :class="$style.archive"> (в&nbsp;архиве)</span>
          </p>
        </div>
      </div>
      <img src="~@/assets/icons/basic/go-from-cell.svg" alt="Перейти" @click="$router.push('/cabinet/attorney')" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'VTableCellSelectPowerOfAttorney',
  props: {
    value: { type: Array, required: true },
  },
  data() {
    return {
      visible: false,
      visibleCheck: false,
    };
  },
  computed: {
    attorneyName() {
      return this.value[0].number.length > 13
        ? `${this.value[0].number.slice(0, 12)}...`
        : `${this.value[0].number}`;
    },
  },
  methods: {
    close() {
      if (this.visible && !this.visibleCheck) {
        this.visibleCheck = true;
        return;
      }
      this.visible = false;
      this.visibleCheck = false;
    },
    isExpired(attorney) {
      return dayjs().startOf('day').diff(dayjs(attorney.expiresAt, 'YYYY-MM-DDTHH:mm:ss[Z]', true)) > 0;
    },
    ...mapActions('document', ['getDocumentByUrl']),
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
}
.select {
  position: relative;
  display: inline-block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #2F82DF;
  border-radius: 24px 2px 2px 24px;
  padding: 4px 10px;
  cursor: pointer;
  max-width: 120px;
  min-width: 120px;

  &__options {
    position: absolute;
    left: -50%;
    right: 50%;
    width: 240px;
    margin-top: 7px;
    padding: 8px 16px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  &__option {
    &:hover {
      background-color: #c4c4c4;
    }
  }

  &:after {
    content: url('~icons/basic/arrow_down_gray.svg');
    position: absolute;
    right: 5px;
    top: 5px;
    pointer-events: none;
    box-sizing: border-box;
  }
}

.download {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.archive {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
</style>
