<template>
  <div style="margin-top: 24px">
    <v-table-new
      v-model="transformedAxpElements"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. Элементы АХП"
      @delete="onDelete"
      @edit="onEdit"
    />
    <elements-axp-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData"
    />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import {
  fetchAxpElements,
} from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import { BASE_URL } from '@/constants/api';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { convertToLabelValueList } from 'utils/list';
import ElementsAxpEdit from 'views/private/OdkAccounting/Axp/ElementsAxpEdit.vue';

export default {
  name: 'ElementsAxp',
  components: {
    VTableNew,
    ElementsAxpEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      axpElements: [],
      AddDirectoryFieldModalActions: AddDirectoryFieldModalActions,
    };

  },
  computed: {
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'АХП',
            key: 'axp.name',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'elementsAxpOdk' },
          },
          {
            label: 'Единицы измерения',
            key: 'unit.name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'vSearchSelectValueUrl', urlDefault: '/units' },
          },
          {
            label: 'Количество',
            key: 'quantity',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Тип',
            key: 'type.name',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'axpElementTypesOdk' },
          },
          {
            label: 'Тип декора',
            key: 'decorType.name',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'decorTypeOdk' },
          },
          {
            label: 'Обязательный',
            key: 'requiredText',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'select', values: convertToLabelValueList(['Да', 'Нет']) },
          },
          {
            label: 'В архиве',
            key: 'archivedText',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'select', values: convertToLabelValueList(['Да', 'Нет']) },
          },
          ...editHeaders,
        ],
      };
    },
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить элемент АХП',
          method: this.onCreate,
        },
      ] : [];
    },
    transformedAxpElements() {
      if (!this.axpElements?.length) {
        return [];
      }

      return this.axpElements;
    },
    isAccessUpdate() {
      return filterForAccess('process/2/1', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.axpElements = (await fetchAxpElements()).map((it) => ({
          ...it,
          axpId: String(it.axp.id),
          unitId: String(it.unit.id),
          typeId: String(it.type.id),
          decorTypeId: String(it.decorType?.id ?? ''),
          required: String(it.required),
          archived: String(it.archived),
          quantity: String(it.quantity),
          requiredText: convertBooleanToText(it.required),
          archivedText: convertBooleanToText(it.archived),
          photos: this.transformFilesFromRequest(it.photos),
        }));
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    transformFilesFromRequest(photos) {
      return photos.map((doc) => ({
        fileId: doc.id,
        file: null,
        name: doc.name,
        previewUrl:
          `${BASE_URL}/api/get/${doc.folder}/${doc.name}`,
        new: false,
      }));
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
