import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

export const initState = () => ({
  currentDate: null,
  balanceIndicators: null,
  balanceGroupName: null,
  networkSections: [], // Все участки сети - balanceBoundaries
  selectedNetworkSectionsIds: [], // Выбранные участки сети - balanceBoundaryIds
  usagePoints: [], // Все точки учета
  selectedUsagePointsIds: [], // Выбранные точки учета
  boundariesWithSubject: [], // Границы со смежными субъектами
  selectedBoundariesWithSubjectsIds: [], // Границы со смежными субъектами
  sidesRepresentatives: null,
});

const state = initState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
