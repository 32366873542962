<template>
  <v-spoiler :pre-summary="'Строка ' + internalError.row"
             :summary="'| Столбец ' + internalError.col + ' | ' + internalError.title"
             class="error-item__spoiler">
    <div class="error-item__error">
      <v-input
        v-model="internalError.incorrectValue"
        :disabled="true"
        :err="true"
        class="error-item__error-item"
        title="Некорректное значение"
      />

      <v-date-picker
        v-if="inputType === 'date'"
        v-model="internalError.value"
        format="DD/MM/YYYY"
        title="Правильное значение *"
        @input="inputChange($event)"
      />
      <v-select
        v-else-if="inputType === 'select'"
        v-model="internalError.value"
        :options="options()"
        title="Правильное значение *"
        @input="inputChange($event)"
      />
      <v-search-select
          v-else-if="inputType === 'mainSubstations' && substations && substations.length > 0"
          v-model="internalError.value"
          :options="substations"
          title="Правильное значение *"
          class="error-item__error-item"
          @input="inputChange($event)"
      />
      <v-search-select
          v-else-if="inputType === 'allSubstations' && allSubstations && allSubstations.length > 0"
          v-model="internalError.value"
          :options="allSubstations"
          title="Правильное значение *"
          class="error-item__error-item"
          @input="inputChange($event)"
      />
      <v-input
        v-else
        v-model="internalError.value"
        class="error-item__error-item"
        clearable
        placeholder="Введенное правильное значение"
        title="Правильное значение *"
        @input="inputChange($event)"
      />

      <img alt="Подробнее" class="error-item__follow-arrow"
           src="../../../../../assets/icons/navigation/follow-arrow.svg" @click="check"/>
    </div>
    <div class="error-item__info">
      <div class="error-item__info-item">{{ internalError.text }}</div>
    </div>
  </v-spoiler>
</template>

<script>
import { VDatePicker, VInput, VSelect, VSpoiler } from 'components';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import VSearchSelect from '@/components/VSearchSelect/VSearchSelect';

export default {
  name: 'ErrorItem',
  components: {
    VSpoiler,
    VInput,
    VDatePicker,
    VSelect,
    VSearchSelect,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
    substations: {
      type: Array,
      required: false,
    },
    allSubstations: {
      type: Array,
      required: false,
    },
  },
  created() {
    if (this.inputType === 'date') {
      this.datePreFix();
    } else if (!this.rules.includes('required')) {
      this.notRequiredPreFix();
    }
  },
  data() {
    return {
      internalError: JSON.parse(JSON.stringify(this.error)),
    };
  },
  computed: {
    rules() {
      return this.internalError.template.rules?.split('|') ?? [];
    },
    inputType() {
      if (this.internalError.keys[1] === 'mainSubstationNumber') {
        return 'mainSubstations';
      }

      if (this.internalError.keys[1] === 'distributionSubstation' ||
          this.internalError.keys[1] === 'transformationSubstation') {
        return 'allSubstations';
      }

      if (!this.internalError.template.rules) {
        return 'other';
      }

      if (this.rules.includes('date')) {
        return 'date';
      }

      if (this.internalError.template.allowedValues) {
        return 'select';
      }

      return 'other';
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'reliabilityClassList',
      'reliabilityClassListWithShortenedNames',
      'voltageClassList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'relatedOrganizationList',
    ]),
  },
  watch: {
    application() {
      this.internalError = JSON.parse(JSON.stringify(this.error));
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.internalError);
    },
    datePreFix() {
      if (this.internalError.incorrectValue.match(/^\d+$/)) {
        this.internalError.value = `01/01/${this.internalError.incorrectValue}`;
        this.onChange();
      }
    },
    notRequiredPreFix() {
      this.internalError.value = null;
      this.onChange();
    },
    options() {
      if (!this.internalError?.template?.listName) {
        return [];
      }

      return this[this.internalError.template.listName].map((item) => ({ value: item.label, label: item.label }));
    },
    inputChange: debounce(function() {
      this.onChange();
    }, 500),
    check() {
      this.onChange();
      this.$emit('check');
    },
  },
};
</script>

<style lang="scss" scoped>
.error-item {
  &__spoiler {
    margin-top: 24px;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__error-item {
    width: 330px;
  }

  &__follow-arrow {
    width: 36px;
    height: 36px;
    margin-top: 24px;
    cursor: pointer;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 14px;
    position: absolute;
    top: 0;
    right: -422px;

    @media (max-width: 1400px) {
      flex-direction: row;
      position: relative;
      top: 0;
      right: 0;
      margin-left: -16px;
      width: 848px;
      margin-top: 16px;
      gap: 8px;
    }
  }

  &__info-item {
    width: 380px;
    padding: 12px 12px;
    background: #F5F6F6;
    border-radius: 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0E0F0F;
  }
}
</style>
