<template>
  <div class="contract-templates">
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
    <v-spoiler
      v-for="(pattern, idx) in patterns"
      :key="idx"
      :is-open="false"
      :summary='pattern.name'
      class="contract-templates__spoiler"
    >
      <div class="contract-templates__template">
        <div class="contract-templates__template-title">
          <img alt="" src="~@/assets/icons/file/template_xml.svg"/>
          <span>Типовая форма договора</span>
        </div>
        <div class="contract-templates__template-data">
          <div class="contract-templates__template-download">
            <document-downloader
              :is-open-eye-show="false"
              :types="['XML']"
              @download="downloadContractTemplate(pattern)"
              @open="downloadContractTemplate(pattern)"
            />
            <img alt="Редактировать" src="~@/assets/icons/file/edit.svg"/>
            <img alt="Удалить" height="26" src="~@/assets/icons/file/delete.svg" width="26"/>
          </div>
          <div class="contract-templates__template-date">
            <span>Создан: {{ convertDbDateTimeFormat(pattern.createdAt) }}</span></div>
          <div class="contract-templates__template-date">
            <span>Изменён: {{ convertDbDateTimeFormat(pattern.updatedAt) }}</span></div>
        </div>
      </div>
    </v-spoiler>
  </div>
</template>

<script>
import { VSpoiler } from '@/components';
import DocumentDownloader from '@/components/DocumentDownloader/DocumentDownloader';
import { mapActions } from 'vuex';
import { downloadXml } from 'lib/utils/pdf';
import ModalProgressBar from '@/components/ModalProgressBar/ModalProgressBar';
import { delay } from '@/utils/delay';
import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  name: 'ContractTemplates',
  components: { VSpoiler, DocumentDownloader, ModalProgressBar },
  created() {
    this.loadData();
  },
  data() {
    return {
      patterns: [],
      isModalVisible: false,
      isProcessComplete: false,
    };
  },
  methods: {
    convertDbDateTimeFormat,
    async loadData() {
      try {
        this.patterns = await this.getAllPatterns();
      } catch (e) {
        alert('Ошибка загрузки шаблонов');
      }
    },
    async downloadContractTemplate(pattern) {
      try {
        this.isModalVisible = true;
        this.isProcessComplete = true;
        await delay(1500);
        downloadXml(pattern.xmlContent, `${pattern.name}.xml`);
      } catch (e) {
        alert('Ошибка загрузки шаблона');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    ...mapActions('cabinet', ['getAllPatterns']),
  },
};

</script>

<style lang="scss" scoped>
.contract-templates {

  &__spoiler {
    margin-bottom: 24px;
  }

  &__template {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__template-title {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 48%;
    padding-top: 6px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #4C4E51;
    text-decoration: underline;
  }

  &__template-data {
    width: 52%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__template-download, &__template-date {
    width: 33%;
    min-width: 178px;
    padding-top: 8px;
  }

  &__template-download {
    display: flex;
    gap: 5px;
  }

  &__template-date {
    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #4C4E51;
    }
  }

  &__template-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;
    padding-bottom: 8px;
    display: block;
  }
}
</style>
