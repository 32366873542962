const getPowerOfAttorneys = (state) => state.powerOfAttorneys;
const getFormAttorney = (state) => state.formAttorney;

const getFilteredPowerOfAttorneys = (state) => {
  const checkMatchFields = (firstField, secondField) => firstField?.toUpperCase().trim().match(secondField.toUpperCase());

  return state.powerOfAttorneys
    .filter((item) => (state.filter.title.length > 0
        ? checkMatchFields(item.title, state.filter.title)
        : true))
    .filter((item) => (state.filter.from.length > 0
        ? (
          checkMatchFields(item.initiatorUser.fullName?.name, state.filter.from) ||
          checkMatchFields(item.initiatorUser.fullName?.surname, state.filter.from.toUpperCase()) ||
          checkMatchFields(item.initiatorUser.fullName?.patronymic, state.filter.from)
        )
        : true))
    .filter((item) => (state.filter.to.length > 0
        ? (
          checkMatchFields(item.grantedToUser.fullName?.name, state.filter.to) ||
          checkMatchFields(item.grantedToUser.fullName?.surname, state.filter.to) ||
          checkMatchFields(item.grantedToUser.fullName?.patronymic, state.filter.to)
        )
        : true))
    .filter((item) => (state.filter.permissions.length > 0
        ? item.permissions.map((item) => item.description).some((item) => checkMatchFields(item, state.filter.permissions))
        : true));
};

export default {
  getPowerOfAttorneys,
  getFormAttorney,
  getFilteredPowerOfAttorneys,
};
