<template>
  <div class="contract-sides-selector">
    <contract-side-select
      v-for="(side, idx) in sides" :key="idx"
      :side="side"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContractSideSelect from 'views/private/UploadDocument/steps/ContractRequisites/components/ContractSideSelect';

export default {
  name: 'ContractSidesSelector',
  components: { ContractSideSelect },
  data() {
    return {
    };
  },
  computed: {
    sides() {
      return this.getContract.selectedTemplate.sides;
    },
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
};
</script>

<style lang="scss" scoped>
.contract-sides-selector {
  margin-top: 60px;
  display: flex;
}
</style>
