export const initStepsbyType = (type) => {
  const uploadXmlSteps = [
    {
      label: 'Загрузить XML МЧД',
      url: '/cabinet/upload-attorney-xml',
    },
    {
      label: 'Сформировать МЧД',
      url: '/cabinet/form-attorney',
    },
    {
      label: 'Загрузить',
      url: '/cabinet/form-attorney/summary',
    },
  ];

  const generateDigitalOrUploadScanSteps = [
    {
      label: 'Сформировать МЧД',
      url: '/cabinet/form-attorney',
    },
    {
      label: 'Загрузить',
      url: '/cabinet/form-attorney/summary',
    },
  ];

  if (type === 'xml') { // upload xml
    return uploadXmlSteps;
  } else if (type === 'form') { // generate
    return generateDigitalOrUploadScanSteps;
  } else if (type === 'upload') { // upload paper scan
    return generateDigitalOrUploadScanSteps;
  }
};
