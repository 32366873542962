<template>
  <v-spoiler class="electricity-losses" summary="Потери и максимальная мощность">
    <div class="electricity-losses__item">
      <v-input
        v-model="internalValue.variableLoss"
        :disabled="!isEditing"
        clearable
        title="Потери переменные, %"
      />
    </div>
    <div class="electricity-losses__item">
      <v-input
        v-model="internalValue.constLoss"
        :disabled="!isEditing"
        clearable
        title="Потери постоянные, кВт*ч"
      />
    </div>
    <div class="electricity-losses__item">
      <v-input
        v-model="internalValue.maxPower"
        :disabled="!isEditing"
        clearable
        title="Максимальная мощность, кВт"
      />
    </div>
  </v-spoiler>
</template>

<script>
import { VInput, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'ElectricityLosses',
  components: {
    VSpoiler,
    VInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        loss: '',
        lossFactor: '',
        maxPower: '',
      },
      internalValue: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.electricity-losses {

  &__item {
    width: 100%;
    margin-top: 48px;
  }
}
</style>
