<template>
  <div class="document-downloader">
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />

    <img v-if="isOpenEyeShow && !document?.xml" alt="Открыть" class="document-downloader__control" src="~@/assets/icons/basic/eye.svg"
         @click="open"/>

    <select v-model="selectedType" class="document-downloader__select">
      <option v-for="(type, index) in conditionTypes" :key="index" :value="type">{{ type }}</option>
    </select>

    <img alt="Скачать" class="document-downloader__control" src="~@/assets/icons/basic/document-download.svg"
         @click="download"/>
  </div>
</template>

<script>
import ModalProgressBar from '@/components/ModalProgressBar/ModalProgressBar';
import { getDocument } from 'lib/utils/downloadDocuments';
import { delay } from '@/utils/delay';
import { downloadPdf, downloadSiganture, downloadXml, showPdf } from 'lib/utils/pdf';
import { mapActions } from 'vuex';

export default {
  name: 'DocumentDownloader',
  components: {
    ModalProgressBar,
  },
  props: {
    document: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    types: {
      type: Array,
      default: () => ['PDF', 'XML', 'SIG'],
    },
    isOpenEyeShow: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.selectedType = this.conditionTypes[0];
  },
  data() {
    return {
      selectedType: '',
      isModalVisible: false,
      isProcessComplete: false,
      signature: null,
      xml: null,
    };
  },
  computed: {
    conditionTypes() {
      if (this.document?.xml) {
        return ['XML'];
      }
      return this.types;
    },
  },
  methods: {
    async open() {
      if (!this.document) {
        this.$emit('open');
        return;
      }

      try {
        this.isModalVisible = true;
        const document = await getDocument(this.document.documentId);
        this.isProcessComplete = true;
        await delay(3250);
        showPdf(document);
      } catch (e) {
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    async pdfDownload(documentId, name) {
      try {
        this.isModalVisible = true;
        const document = await getDocument(documentId);
        this.isProcessComplete = true;
        await delay(3250);
        downloadPdf(document, name);
      } catch (e) {
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    async xmlDownload(documentId, name) {
      try {
        this.isModalVisible = true;
        if (this.document.xml) {
          downloadXml(this.document.xml, name);
        } else {
          const response = await this.loadDocument(documentId);
          this.isProcessComplete = true;
          await delay(3250);
          downloadXml(response.xml, name ?? response.title);
        }
      } catch (e) {
        console.log(e.message);
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    async sigDownload(documentId, name) {
      try {
        this.isModalVisible = true;
        const response = await this.loadDocument(documentId);
        this.isProcessComplete = true;
        await delay(3250);
        downloadSiganture(response.signature, name ?? response.title);
      } catch (e) {
        console.log(e.message);
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    async download() {
      if (!this.document) {
        this.$emit('download', this.selectedType);
        return;
      }

      if (this.selectedType === 'PDF') {
        await this.pdfDownload(this.document.documentId, this.document.name ?? this.document.title);
        return;
      }

      if (this.selectedType === 'XML') {
        await this.xmlDownload(this.document.documentId, this.document.name ?? this.document.title);
      }


      if (this.selectedType === 'SIG') {
        await this.sigDownload(this.document.documentId, this.document.name ?? this.document.title);
      }
    },
    ...mapActions('document', ['loadDocument']),
  },
};
</script>

<style lang="scss" scoped>
.document-downloader {
  display: flex;
  align-items: center;

  &__control {
    height: 24px;
    cursor: pointer;
  }

  &__select {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #000000;
    padding-left: 8px;

    margin-left: 8px;
    margin-right: 2px;
    width: 58px;
    height: 24px;
    border: 1px solid #2F82DF;
    border-radius: 24px 2px 2px 24px;

    &:disabled {
      color: #000000;
    }
  }

}
</style>
