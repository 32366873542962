var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-mode-button"},[_c('button',{class:{
      ['edit-mode-button__button']: true,
      ['edit-mode-button__button--select-all']: _vm.selectAll,
      ['edit-mode-button__button--select-all-border-gray']: _vm.selectAllBorderGray,
      ['edit-mode-button__button--select']: _vm.select,
      ['edit-mode-button__active']: _vm.isTrue || _vm.isChecked,
      ['edit-mode-button__active--select']: (_vm.isTrue || _vm.isChecked) && _vm.select,
      ['edit-mode-button__non-active']: !_vm.isChecked && !_vm.isTrue,
      ['edit-mode-button__non-active--select-all']: (!_vm.isChecked && !_vm.isTrue) && _vm.selectAll,
    },style:(_vm.customStyle),attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.click.apply(null, arguments)}}},[_c('span',{staticClass:"edit-mode-button__button-icon"},[((_vm.isChecked || _vm.isTrue) && _vm.select)?_c('img',{staticClass:"edit-mode-button__button-icon-img",attrs:{"alt":"","src":require("@/assets/icons/edit-button/on-select.svg")}}):_vm._e(),((_vm.isChecked || _vm.isTrue) && !_vm.select && !_vm.whiteIcon)?_c('img',{staticClass:"edit-mode-button__button-icon-img",attrs:{"alt":"","src":require("@/assets/icons/edit-button/on.svg")}}):_vm._e(),((_vm.isChecked || _vm.isTrue) && !_vm.select && _vm.whiteIcon)?_c('img',{staticClass:"edit-mode-button__button-icon-img",attrs:{"alt":"","src":require("@/assets/icons/edit-button/on-select-white.svg")}}):_vm._e(),(!(_vm.isChecked || _vm.isTrue))?_c('img',{staticClass:"edit-mode-button__button-icon-img",attrs:{"alt":"","src":require("@/assets/icons/edit-button/off.svg")}}):_vm._e(),_c('span',{staticClass:"edit-mode-button__button-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }