<template>
  <div>
    <cabinet-company-details />
  </div>
</template>

<script>
import CabinetCompanyDetails from 'views/private/CabinetCompanyDetails/CabinetCompanyDetails';
export default {
  name: 'CompanyCard',
  components: {
    CabinetCompanyDetails,
  },
};
</script>

<style scoped>

</style>
