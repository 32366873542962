export function generateDeleteSubstationXml(number) {
  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationList xmlns="http://energochain.ru/XML/documents/update-substation-application/1.0">\n' +
    '    <RevokeSubstation>\n' +
    `        <Number>${number}</Number>\n` +
    '    </RevokeSubstation>\n' +
    '</UpdateSubstationList>';
}

export function generateUpdateSubstationXml(substation) {
  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationList xmlns="http://energochain.ru/XML/documents/update-substation-application/1.0">\n' +
    '    <UpdateSubstation>\n' +
    `        <Number>${substation.numberOld}</Number>\n` +
    '        <Substation>\n' +
    `            <Name>${substation.fullName}</Name>\n` +
    `            <Number>${substation.number}</Number>\n` +
    `            <ShortName>${substation.shortName}</ShortName>\n` +
    `            <WorkingVoltageUpper>${substation.workingVoltageUpper}</WorkingVoltageUpper>\n` +
    `            <Address>${substation.address}</Address>\n` +
    `            <SubstationKind>${substation.substationKind}</SubstationKind>\n` +
    '        </Substation>\n' +
    '    </UpdateSubstation>\n' +
    '</UpdateSubstationList>';
}

export function generateAddSubstationXml(substation) {
  return '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="xsd2html.xsl" ?>\n' +
    '<UpdateSubstationList xmlns="http://energochain.ru/XML/documents/update-substation-application/1.0">\n' +
    '    <AddSubstation>\n' +
    `        <Name>${substation.fullName}</Name>\n` +
    `        <Number>${substation.number}</Number>\n` +
    `        <ShortName>${substation.shortName}</ShortName>\n` +
    `        <WorkingVoltageUpper>${substation.workingVoltageUpper}</WorkingVoltageUpper>\n` +
    `        <Address>${substation.address}</Address>\n` +
    `        <SubstationKind>${substation.substationKind}</SubstationKind>\n` +
    '    </AddSubstation>\n' +
    '</UpdateSubstationList>';
}
