<template>
  <div>
    <employee-content-block v-if="mode==='employees'" :is-loading="isLoading" @load="onLoad"/>
    <invitations-content-block v-if="mode==='acceptedInvitations'" mode="accepted" :is-loading="isLoading" @load="onLoad"/>
    <invitations-content-block v-if="mode==='notAcceptedInvitations'" mode="notAccepted" :is-loading="isLoading" @load="onLoad"/>
  </div>
</template>

<script>
import EmployeeContentBlock from './EmployeeContentBlock.vue';
import InvitationsContentBlock from './InvitationsContentBlock.vue';

export default {
  name: 'ContentBlock',
  components: {
    InvitationsContentBlock,
    EmployeeContentBlock,
  },
  props: {
    mode: { type: String, default: 'employees' },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onLoad() {
      this.$emit('load');
    },
  },
};
</script>

<style lang="scss" module>
</style>
