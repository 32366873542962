<template>
  <div>
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
    <v-table-new
      v-model="transformedDelivery"
      :action-block-items="actionBlockItems"
      :loading="loading" :template="template"
      excel-report-file-name="Ведомости снятия показаний точек учёта"
      @on-cell-click="cellClickHandler"
    />
  </div>
</template>

<script>
import { ModalProgressBar, VTableNew } from '@/components';
import { convertDbDateTimeFormat } from 'lib/utils/date';
import { formatContractStatus, getContractStatusList } from 'lib/utils/formatXmlType';
import { mapActions } from 'vuex';
import { checkField } from '@/utils/common';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  components: { ModalProgressBar, VTableNew },
  props: {
    measurementsStatements: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    pointType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      isProcessComplete: false,
      isSelectModalShow: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование документа',
            key: 'name',
            thStyle: {
              minWidth: '101px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Дата загрузки',
            key: 'createdAt',
            thStyle: {
              minWidth: '96px',
            },
            sort: { type: 'date' },
            filter: { type: 'period' },
          },
          {
            label: 'Работник, загрузивший документ',
            key: 'userName',
            thStyle: {
              minWidth: '144px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Тип снятия показаний',
            key: 'inputType',
            thStyle: {
              minWidth: '82px',
            },
            tooltip: {
              maxLength: 3,
              minSreenWidthTooltip: 1024,
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Статус',
            key: 'supplierStatus',
            thStyle: {
              width: '126px',
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '151px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              minWidth: '128px',
            },
            tooltip: {
              maxLength: 9,
              minSreenWidthTooltip: 1024,
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedDelivery() {
      if (this.measurementsStatements.length === 0) {
        return [];
      }

      return this.measurementsStatements.map((it) => ({
        name: it.title,
        userName: `${it.initiatorUser.fullName.surname} ${it.initiatorUser.fullName.name} ${it.initiatorUser.fullName.patronymic ?? ''}`,
        inputType: 'Ручной ввод',
        createdAt: convertDbDateTimeFormat(it.createdAt),
        documentId: it.documentId,
        rejectedReason: it.rejectedReason,
        supplierStatus: this.formatContractStatus(it.sides[0].status),
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        documentHistory: it.documentHistory,
        document: { documentId: it.documentId, name: 'Ведомость снятия показаний точек учёта' },
      }));
    },
    actionBlockItems() {
      return [
        /*        {
                  id: 1,
                  title: 'Сформировать акт визуальной проверки прибора учёта',
                  method: goToPageByRoute(`reading-sheets/indications-single-upload/${this.selectedTab}`),
                },*/
        {
          id: 1,
          title: 'Загрузить ведомость снятия показаний',
          method: goToPageByRoute('reading-sheets/indications-multiple-upload'),
        },
        {
          id: 2,
          title: 'Сформировать акт снятия показаний потребителя',
          method: goToPageByRoute('reading-sheets/finally-multiple-upload'),
        },
        {
          id: 3,
          title: 'Загрузить профиль мощности',
          method: goToPageByRoute('reading-sheets/power-profile-upload'),
        },
      ];
    },
  },
  methods: {
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          return this.$router.push({
            name: 'cabinet.reading-sheets-card',
            params: {
              pointType: this.pointType,
              fullName: {
                name: `${cell.row.userName.split(' ')[1]}`,
                surname: `${cell.row.userName.split(' ')[0]}`,
                patronymic: `${checkField(cell.row.userName.split(' ')[2])}`,
              },
              documentId: cell.row.documentId,
              name: cell.row.name,
              documentHistory: cell.row.documentHistory,
            },
          });
        }
      }
    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    ...mapActions('document', ['loadDocument']),
  },
};
</script>
