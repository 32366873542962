<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.logotype">
        <logo-cabinet />
      </div>

      <div :class="$style.menu">
        <menu-cabinet :items="getMenuItems" />
        <menu-chat-bot />
      </div>

      <div :class="$style.search">
        <search-filter />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const MENU_CONSUMER = [
  {
    label: 'объекты',
    router: 'cabinet.objects',
  },
  {
    label: 'дела',
    router: 'cabinet.workspaces',
  },
  {
    label: 'оплата',
    router: 'cabinet.pay',
  },
  {
    label: 'услуги',
    router: 'cabinet.services',
  },
  {
    label: 'документы',
    router: 'cabinet.documents',
  },
];

const MENU_SUPPLIER = [
  {
    label: 'задачи',
    router: 'cabinet.tasks',
  },
  {
    label: 'объекты',
    router: 'cabinet.objects',
  },
  {
    label: 'оплата',
    router: 'cabinet.pay',
  },
  {
    label: 'документы',
    router: 'cabinet.documents',
  },
];

const USER_MENU = {
  Customer: MENU_CONSUMER,
  Supplier: MENU_SUPPLIER,
  Retailer: MENU_SUPPLIER,
};

export default {
  name: 'HeaderCabinet',
  components: {
    LogoCabinet: () => import('atoms/logos/LogoCabinet'),
    MenuCabinet: () => import('molecules/menu/MenuCabinet'),
    MenuChatBot: () => import('molecules/menu/MenuChatBot'),
    SearchFilter: () => import('molecules/search/SearchFilter'),
  },
  computed: {
    getMenuItems() {
      return USER_MENU[this.getUserType];
    },
    ...mapGetters('user', ['getUserType']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 100%
    position: relative
    background: #F5F6F6

  .grid
    width: 100%
    max-width: 1168px
    margin: 0 auto

  .content
    height: 64px
    display: flex
    flex-direction: row

  .logotype
    display: flex
    align-items: center
    margin-left: 24px

  .menu
    display: flex
    margin: 0 0 0 22px

  .search
    display: flex
    margin: 0 0 0 auto

</style>
