<template>
  <div :style="styleSpacing" class="history">
    <div class="history__header">
      <h1>{{ title }}</h1>
      <img v-if="canBeCollapsed" alt="" src="~@/assets/icons/basic/eye.svg" @click="collapsed = !collapsed"/>
    </div>
    <div v-if="collapsed" class="history__items">
      <div :class="{
              ['history__progress']: true,
              ['history__progress--92']: history.length < 7,
              ['history__progress--96']: history.length >= 7,
           }"
      />

      <div v-for="(item, index) in internalHistory" :key="index" class="history__item-wrp">
        <div
          :class="{
            ['history__item']: true,
            ['history__item--current']: isCurrent(item),
         }"
        >
          <img v-if="isCurrent(item)" alt="" src="~@/assets/icons/basic/step_current_ellipse.svg"/>
          <img v-else alt="" src="~@/assets/icons/basic/step_ellipse.svg"/>
          <span v-if="item.action">{{
              `${convertDbDateTimeFormat(item.datetime)}${item.documentName ?? 'Документ'} ${convertAction(item.action)}`
            }} </span>
          <span v-else-if="item.message">
            {{ `${convertDbDateTimeFormat(item.datetime)}${item.message}` }}
          </span>
          <button v-else class="history__change-page" @click="changePage(item)">...</button>
          <span v-if="item.role">
        {{
              item.role.name
            }}
        </span>
          <span v-if="item.account?.profile?.name">{{ ` ${item.account.profile.name}` }}</span>
          <span v-if="item.user">
        {{
              item.user?.fullName
                ? `${item.user.fullName.surname} ${item.user.fullName.name} ${item.user.fullName.patronymic ? item.user.fullName.patronymic : ''}.`
                : ''
            }}
      </span>
          <span v-if="item.documentHash?.length">
        Хэш документа: {{ item.documentHash }}.
      </span>
        </div>
        <button v-if="item.onClick" class="history__item__button" @click="item.onClick"/>
      </div>
    </div>
  </div>
</template>

<script>
import { convertAction } from 'lib/utils/formatXmlType';
import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  name: 'History',
  props: {
    title: {
      type: String,
      default: 'История документа',
    },
    history: {
      type: Array,
      default: () => [],
    },
    spacing: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    canBeCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.setStyleSpacing();
    this.setLastPage();
    window.addEventListener('resize', this.setStyleSpacing);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setStyleSpacing);
  },
  data() {
    return {
      styleSpacing: {},
      page: 0,
      collapsed: !this.canBeCollapsed,
    };
  },
  computed: {
    internalHistory() {
      if (!this.history[0]) {
        return [];
      }

      const history = [this.history[0]];
      if (this.page > 0) history.push('prev');
      history.push(...this.history.slice((this.page * (this.pageSize - 1)) + 1, ((this.page + 1) * (this.pageSize - 1)) + 1));
      if (this.history.length > ((this.page + 1) * (this.pageSize - 1)) + 1) history.push('next');
      return history;
    },
  },
  watch: {
    history: {
      handler() {
        this.setLastPage();
      },
    },
  },
  methods: {
    isCurrent(item) {
      if (this.history.some((it) => it.current)) return item.current;
      return item === this.history[this.history.length - 1];
    },
    changePage(direction) {
      if (direction === 'prev' && this.page > 0) this.page--;
      if (direction === 'next' && this.page < this.history.length / this.pageSize) this.page++;
    },
    setStyleSpacing() {
      if (document.documentElement.clientWidth < 1400) {
        this.styleSpacing = { margin: '24px 0 0 0' };
      } else {
        this.styleSpacing = { margin: `${this.spacing}px 0 0 48px` };
      }
    },
    setLastPage() {
      this.page = Math.floor((this.history.length - 1) / this.pageSize);
    },
    convertAction(action) {
      return convertAction(action);
    },
    convertDbDateTimeFormat(date) {
      if (!date) {
        return '';
      }
      return `${convertDbDateTimeFormat(date)}. `;
    },
  },
};

</script>

<style lang="scss" scoped>
.history {
  &__header {
    display: flex;
    margin-bottom: 38px;

    h1 {
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      color: #4C4E51;
      margin-right: 12px;
    }
  }

  &__items {
    position: relative;
  }

  &__change-page {
    background: inherit;

    &:hover {
      color: #2F82DF;
    }
  }

  &__item {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #4C4E51;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 16px;

    &__button {
      min-width: 24px;
      min-height: 24px;
      color: #2F82DF;

      background-image: url('~icons/navigation/arrow_left_blue.svg');
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #FFFFFF;
      border: 1px solid #C1C4C7;
      border-radius: 100px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    &-wrp {
      display: flex;
      flex-direction: row;
      align-items: self-start;
    }

    img {
      width: 8px;
      height: 8px;
      position: absolute;
      left: 1px;
      margin: 4px 0 0;
    }

    &--current {
      font-weight: 600;
      line-height: 24px;
      color: #2F82DF;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        left: -2px;
      }
    }

  }

  &__progress {
    width: 2px;
    position: absolute;
    top: 8px;
    left: 4px;
    background: #DEE2E6;

    &--92 {
      height: 96%;
    }

    &--96 {
      height: 96%;
    }
  }
}
</style>
