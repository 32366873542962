<template>
  <div>
    <v-table-new
      v-model="drafts"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Договоры оказания услуг по передаче электрической энергии и дополнительные соглашения. Черновики"
      @on-cell-click="cellClickHandler"
    />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { completedByStatus, formatContractStatus } from 'lib/utils/formatXmlType';
import { convertDbDateTimeFormat } from 'lib/utils/date';
import { mapActions } from 'vuex';
import { setDraft } from '@/services/draft';

export default {
  name: 'ContractDrafts',
  components: {
    VTableNew,
  },
  props: {
    contractType: { type: String, required: true },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      drafts: [],
      loading: false,
      actionBlockItems: [
        {
          id: 1,
          title: 'Создать электронный договор',
          method: this.goToPageByRoute(
            { action: 'create', type: 'contract' },
          ),
        },
        {
          id: 2,
          title: 'Загрузить действующий договор',
          method: this.goToPageByRoute(
            { action: 'upload', type: 'contract' },
          ),
        },
        {
          id: 3,
          title: 'Создать электронное дополнительное соглашение',
          method: this.goToPageByRoute(
            { action: 'create', type: 'additionalAgreement' },
          ),
        },
        {
          id: 4,
          title: 'Загрузить действующее дополнительное соглашение',
          method: this.goToPageByRoute(
            { action: 'upload', type: 'additionalAgreement' },
          ),
        },
      ],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Тип',
            key: 'title',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата создания',
            key: 'formattedCreatedAt',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Дата последнего обновления',
            key: 'formattedUpdatedAt',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedDocuments() {
      if (this.drafts.length === 0) {
        return [];
      }

      return this.drafts.map((it) => ({
        ...it,
        formattedCreatedAt: convertDbDateTimeFormat(it.createdAt),
        formattedUpdatedAt: convertDbDateTimeFormat(it.updatedAt),
      }));
    },
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    async cellClickHandler(cell) {
      if (cell.key === 'followLink') {
        try {
          this.loading = true;

          const draft = await this.loadDraftById({ id: cell.row.id });

          if (!draft) {
            this.setNotification({ message: 'Не удалось загрузить черновик' });
            return;
          }

          const contractState = JSON.parse(draft.xml);

          contractState.draftId = cell.row.id;

          await setDraft(contractState.accountId, contractState, `${contractState.accountId}contractNew`);

          await this.initFromDraft({ draft: contractState });

          this.$router.push({
            name: `${this.$route.name}.upload`,
          });

        } catch (error) {
          console.log('error', error);
          this.setNotification({ message: 'Нет связи с сервером' });
        } finally {
          this.loading = false;
        }
      }
    },
    link(task) {
      this.$router.push({
        name: 'cabinet.disagreement',
        params: {
          id: task.documentId,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    async loadData() {
      try {
        this.loading = true;

        const drafts = await this.loadDrafts({
          documentTypes: [
            `contractcreate${this.contractType}`,
            `contractupload${this.contractType}`,
            `additionalAgreementcreate${this.contractType}`,
            `additionalAgreementupload${this.contractType}`,
          ],
        });

        if (drafts.length === 0) {
          this.drafts = [];
          return;
        }

        this.drafts = drafts.map((it) => ({
          ...it,
          formattedCreatedAt: convertDbDateTimeFormat(it.createdAt),
          formattedUpdatedAt: convertDbDateTimeFormat(it.updatedAt),
        }));
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    goToPageByRoute({ action, type }) {
      return async () => {
        await this.resetDraft();

        const contractType = this.contractType;
        this.setContractMode({ action, type, contractType });

        return this.$router.push({
          name: `${this.$route.name}.upload`,
        });
      };
    },
    ...mapActions('document', ['loadDrafts', 'loadDraftById']),
    ...mapActions('electricityTransmissionContract', ['initFromDraft', 'setContractMode', 'resetDraft']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
