export function initUploadDocumentTaskData(task) {
  if (task.completed) {
    if (task.rejected) {
      return { isActive: false, isError: true, status: 'ОТКЛОНЕНО', title: 'Загрузка договора с котловым доп. соглашением в актульной редакции', componentDetails: 'NotDetails' };
    }
    return { isActive: false, status: 'ЗАВЕРШЕНО', title: 'Загрузка договора с котловым доп. соглашением в актульной редакции', componentDetails: 'NotDetails' };
  }
  if (task.isComments) {
    return { isActive: true, status: 'НАЧАТЬ ВЫПОЛНЕНИЕ', title: 'Исправление договора с котловым доп. соглашением в актульной редакции', componentDetails: 'UploadDocumentDetails' };
  }
  return { isActive: true, status: 'НАЧАТЬ ВЫПОЛНЕНИЕ', title: 'Загрузка договора с котловым доп. соглашением в актульной редакции', componentDetails: 'UploadDocumentDetails' };
}
