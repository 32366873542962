<template>
  <div>
    <navigation-bar
      v-if="isDisplayNav"
      :current-step="currentStep"
      :steps="steps"
      @open="openStep"/>
    <div style="display: flex; flex-direction: column; align-items: center">
      <template v-if="!filledDocuments.length">
        <div style="display: flex; width: 100%; justify-content: space-between; align-items: center; margin-bottom: 16px">
          <a download="Шаблон профиля мощности" href="/xlsx/power_profile.xlsx" style="display: flex; align-items: center">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>Шаблон профиля мощности</span>
          </a>
          <document-downloader
              :is-open-eye-show="false"
              :types="['XLSX']"
              @download="xlsDownload('/xlsx/power_profile.xlsx', 'Шаблон профиля мощности')"
          />
        </div>

        <h3 style="margin: 20px 0">Загрузите профиль мощности (почасовой)</h3>

        <c-uploading
          v-model="filledDocuments"
          accept=".xlsx, .xls"
          accept-title="XLSX, XLS или перетащите его в эту область."
          role="filledDocuments"
        />
      </template>
      <div
        v-else
        style="padding-top: 28px;">
        <file-list-preview
          :list="filledDocuments"
          :visible-img="isDisplayPreview"
          action
          @delete="handleRemoveDocumentsScan" />
      </div>
    </div>
    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="xmlPowerProfile"
      @document-signed="onDocumentSigned"
      @close="isVisibleSimpleSignatureModal=false"
    />
    <dialog-template v-model="isErrorsModalShow" center>
      <div style="text-align: center; margin-top: 64px; margin-bottom: 64px;">
        <p>{{submitError}}</p>
      </div>
    </dialog-template>
    <dialog-template v-model="isPowerProfileSubmitted" center>
      <div style="text-align: center; margin-top: 64px; margin-bottom: 64px;">
        <p>Профиль мощности успешно загружен!</p>
      </div>
    </dialog-template>
    <dialog-template v-model="isDisplayValidationError" center>
      <div style="text-align: center; margin-top: 64px; margin-bottom: 64px;">
        <p>{{validationError}}</p>
      </div>
    </dialog-template>
    <div v-if="isDisplayPreview && filledDocuments.length">
      <div class="table-container">
        <h3>Номер счетчика: {{ meterNumber }}</h3>
        <table>
          <thead>
          <tr>
            <th v-for="header in tableHeaders" :key="header.key" style="text-align: left;">{{ header.text }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
            <td v-for="header in tableHeaders" :key="header.key" style="width: 60px" >{{ row[header.key] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isDisplayUploadButton" style="padding: 40px 0; display: flex; justify-content: center">
      <v-button
        :disabled="!filledDocuments.length"
        @click="generateXml"
      >Загрузить</v-button>
    </div>

  </div>

</template>

<script>
import NavigationBar from 'components/NavigationBar/NavigationBar';
import CUploading from 'atoms/common/CUploading';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import VButton from 'components/VButton/VButton';
import generateXml from 'lib/storage/connection-application/data';
import SimpleSignatureModal from 'components/SimpleSignatureModal/SimpleSignatureModal';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';
import DialogTemplate from 'templates/DialogTemplate';
import { parsePowerProfile } from 'utils/excel/excelParser';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';

export default {
  name: 'PowerProfileUpload',
  components: {
    DialogTemplate,
    SimpleSignatureModal,
    VButton,
    FileListPreview,
    CUploading,
    NavigationBar,
    DocumentDownloader,
  },
  props: {
    isDisplayNav: {
      type: Boolean,
      default: true,
    },
    startGenerateXml: {
      type: Boolean,
      default: false,
    },
    propMeterNumber: {
      type: String,
      default: undefined,
    },
    isDisplayPreview: {
      type: Boolean,
      default: true,
    },
    isDisplayUploadButton: {
      type: Boolean,
      default: true,
    },
    isClearForError: {
      type: Boolean,
      default: false,
    },
    isGoReadingSheetsAfterSubmitted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisibleSimpleSignatureModal: false,
      meterNumber: null,
      tableHeaders: null,
      filledDocuments: [],
      xmlPowerProfile: '',
      signedDocument: '',
      submitError: '',
      validationError: '',
      isValidationError: false,
      isDisplayValidationError: false,
      isPowerProfileSubmitted: false,
      isErrorsModalShow: false,
      rows: null,
      profileInfo: null,
      currentStep: 1,
      steps: [
        {
          stepNumber: {
            value: 1,
            from: 2,
            title: () => 'Ведомости снятия показания точек учета',
          },
        },
        {
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Загрузить профиль мощности',
          },
        },
      ],
    };
  },
  watch: {
    async filledDocuments(val) {
      this.$emit('fill', val.length);

      if (val.length === 0) {
        return;
      }
      this.submitError = '';
      const source = val[0].source;

      const blob = await this.urlToBlob(source);

      if (blob === null) {
        console.error('Не удалось преобразовать URL-адрес Blob в объект Blob');
        return;
      }

      this.isValidationError = false;
      parsePowerProfile(this, blob, this.propMeterNumber, (tableHeaders, rows, profileInfo) => {
        this.tableHeaders = tableHeaders;
        this.rows = rows;
        this.profileInfo = profileInfo;
      });
    },
    startGenerateXml() {
      if (this.filledDocuments.length === 0) {
        return;
      }

      this.generateXml();
    },
    isValidationError(val) {
      if (val) {
        this.isDisplayValidationError = true;

        if (this.isClearForError) {
          this.filledDocuments = [];
        }
      }
    },
    isVisibleSimpleSignatureModal(val) {
      if (!val && this.signedDocument.length === 0) {
        this.$emit('submitFinally', this.isPowerProfileSubmitted);
      }
    },
  },
  methods: {
    onDocumentSigned(signedDocument) {
      this.signedDocument = signedDocument;
      this.isVisibleSimpleSignatureModal = false;
      this.submitSignedDocument();
    },
    async submitSignedDocument() {
      try {
        const result = await this.documentUpload({ xml: this.signedDocument });
        if (!result) {
          return;
        }

        if (result === true) {
          this.isPowerProfileSubmitted = true;
          this.filledDocuments = [];

          if (this.isGoReadingSheetsAfterSubmitted) {
            await this.$router.push('/cabinet/reading-sheets');
          }
        }
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.isErrorsModalShow = true;
        this.submitError = errorData.message;
      } finally {
        this.$emit('submitFinally', this.isPowerProfileSubmitted);
      }
    },
    generateXml() {
      if (this.isValidationError) return;
      this.isVisibleSimpleSignatureModal = true;
      this.xmlPowerProfile = generateXml.generatePowerProfileXML({ meterId: this.meterNumber, excelData: this.rows, profileInfo: this.profileInfo });
    },
    async urlToBlob(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      } catch (error) {
        console.error('Ошибка при преобразовании URL в Blob:', error);
        return null;
      }
    },
    handleRemoveDocumentsScan(file) {
      this.filledDocuments = this.filledDocuments
        .filter((scan) => scan.source !== file.source);
    },
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
      this.$router.go(-1);
    },
    back() {
      this.$router.go(-1);
    },
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
  h3 {
    padding: 18px 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 8px;
      border: 1px solid #ddd;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #ddd;
    }
  }
}
</style>
