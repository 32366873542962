<template>
  <div
    :class="{
      ['row']: true,
      [`row--spacing-${spacing}`]: true,
    }"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VRow',
  props: {
    spacing: { type: Number, default: 0 },
  },
};
</script>

<style lang="scss" scoped>
.row {
  &--spacing-1 {
    * {
      margin-top: 8px;
    }
  }

  &--spacing-2 {
    * {
      margin-top: 16px;
    }
  }

  &--spacing-3 {
    * {
      margin-top: 24px;
    }
  }
}
</style>
