<template>
  <div class="attorney-page-attorney">
    <action-block class="attorney-page-attorney__item" @load="loadData" />
    <v-divider class="attorney-page-attorney__item" />
    <content-block />
  </div>
</template>

<script>
import ActionBlock from './ActionBlock';
import ContentBlock from './ContentBlock';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import { VDivider } from '@/components';

function compare(a, b) {
  if (dayjs(a.expiresAt).diff(dayjs(b.expiresAt)) < 0) {
    return 1;
  }

  return -1;
}

export default {
  name: 'Attorney',
  components: {
    ContentBlock,
    ActionBlock,
    VDivider,
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters('employees', ['getEmployees']),
  },
  methods: {
    async loadData() {
      try {
        await this.loadPowerOfAttorneys();
        let result;
        if (this.getEmployees.length > 0) {
          result = this.getEmployees;
        } else {
          const users = await this.getUser();
          result = users.authorizations.map((user) => ({
              ...user,
              transformPowerOfAttorney: this.transformPowerOfAttorney(user.powerOfAttorney),
            }));
        }
        this.SET_EMPLOYEES(result);
      } catch (e) {
        console.log('loadPowerOfAttorneys', e);
      }
    },
    transformPowerOfAttorney(list) {
      if (!list || list.length === 0) {
        return [];
      }

      return list.sort(compare).map((item) => ({
          ...item,
          isArchive: !item.expiresAt ? false : dayjs().startOf('day').diff(dayjs(item.expiresAt), 'day') > 0,
        }));
    },
    ...mapMutations('employees', ['SET_EMPLOYEES']),
    ...mapActions('attorney', ['loadPowerOfAttorneys']),
    ...mapActions('user', ['getUser']),
  },
};
</script>

<style lang="scss" scoped>
.attorney-page-attorney {
  &__item {
    margin-bottom: 24px;
  }
}
</style>
