<template>
  <page title="Склады">
    <v-table-new
      v-model="transformedWarehouses"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. Склады"
      @delete="onDelete"
      @edit="onEdit"
    />
    <warehouses-edit
      :modal-action.sync="modalAction"
      :modal-value.sync="modalValue"
      @loadData="loadData"
    />
  </page>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchWarehouse } from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { convertToLabelValueList } from 'utils/list';
import Page from 'components/Page/Page.vue';
import WarehousesEdit from 'views/private/OdkAccounting/Warehouses/WarehousesEdit.vue';

export default {
  name: 'Warehouses',
  components: {
    Page,
    VTableNew,
    WarehousesEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      warehouse: [],
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ] : [];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'warehousesOdk' },
          },
          {
            label: 'Адрес',
            key: 'address',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Скрыт',
            key: 'hideText',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: { type: 'select', values: convertToLabelValueList(['Да', 'Нет']) },
          },
          ...editHeaders,
        ],
      };
    },
    transformedWarehouses() {
      if (!this.warehouse?.length) {
        return [];
      }

      return this.warehouse;
    },
    isAccessUpdate() {
      return filterForAccess('process/1/1', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.warehouse = (await fetchWarehouse()).map((it) => ({
          ...it,
          hide: String(it.hide),
          hideText: convertBooleanToText(it.hide),
        }));
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
