<template>
  <div>
    <v-table-new
      v-model="transformedRegularInspectionSummary"
      :excel-report-file-name="excelReportFileName"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="regularInspectionSummary.pageOut"
      :table-name="tableName"
      :template="template"
      is-display-map-option
      server-side-sorting
      @change-mode="changeTableMode"
      @on-cell-click="handleCellClick"
      @tap-placemark="onTapPlacemark"
      @on-sort="handleSort"
    />
    <odk-tag-modal v-model="tagModalState" @updated="loadData"/>
  </div>
</template>

<script>
import { OdkTagModal, VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { mapGetters } from 'vuex';
import { BASE_URL } from '@/constants/api';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';
import { mapFilters } from 'lib/utils/table-pagination';
import { distinctBy } from 'utils/arrays/arrays';
import { filterForAccess, isAccessRegularInspectionOdk } from 'lib/utils/permissions';
import { inConstants } from 'lib/component/validators';
import { regularInspectionTaskType } from 'components/Processes/list';
import { VTableMode } from 'components/VTableNew/constants';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};
export default {
  name: 'RegularInspectionsListTable',
  components: { VTableNew, OdkTagModal },
  props: {
    excelReportFileName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator: inConstants(regularInspectionTaskType),
      required: true,
    },
    tableName: {
      type: String,
      default: 'regularInspectionTasksListSummary',
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      tableMode: null,
      regularInspectionSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
      tagModalState: null,
      objects: [],
    };
  },
  computed: {
    canInspect() {
      return isAccessRegularInspectionOdk(this.getAuthorization.blockAccess) && this.type === regularInspectionTaskType.INSPECTION;
    },
    transformedRegularInspectionSummary() {
      if (!this.regularInspectionSummary ||
        !this.regularInspectionSummary.data ||
        !Array.isArray(this.regularInspectionSummary.data)) {
        return [];
      }

      const transformed = this.regularInspectionSummary.data.map(this.transformed);
      if (this.tableMode === VTableMode.MAP) return distinctBy(transformed, (it) => it.adreskaFactId);
      return transformed;
    },
    template() {
      const commentColumn = this.type !== regularInspectionTaskType.ADRESKA_CONNECTION ? [
        {
          label: 'Комментарий',
          key: 'comment',
          size: 'sm',
          sort: {},
          thStyle: {
            minWidth: '105px',
          },
        },
      ] : [];
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'ID',
            key: 'adreskaFactId',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Иллюстрация ОДК',
            key: 'preview',
            type: 'preview',
            size: 'md',
            thStyle: {
              minWidth: '95px',
            },
            sort: {},
          },
          {
            label: 'Наименование ОДК',
            key: 'odkName',
            size: 'md',
            thStyle: {
              minWidth: '105px',
            },
            sort: {},
            filter: { type: 'odkOdk' },
          },
          {
            label: 'Наименование АХП',
            key: 'axpName',
            size: 'md',
            thStyle: {
              minWidth: '105px',
            },
            sort: {},
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Программа оформления',
            key: 'adreskaName',
            size: 'md',
            thStyle: {
              minWidth: '90px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Адрес',
            key: 'address',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Гарантийный',
            key: 'warranty',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Подразделение',
            key: 'departmentName',
            size: 'md',
            thStyle: {
              minWidth: '105px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Смена',
            key: 'shift',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: {
              type: 'vSearchSelectValue',
              values: this.shiftList,
            },
          },
          {
            label: 'Ответственный инспектор',
            key: 'fullName',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '105px',
            },
            filter: {},
          },
          {
            label: 'Даты начала и конца осмотров',
            key: 'startInspectionDate',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '115px',
            },
          },
          {
            label: 'Дата осмотра',
            key: 'date',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
            filter: { type: 'date' },
          },
          {
            label: 'Тэги',
            key: 'tags',
            size: 'sm',
            sort: {},
            type: 'list',
            spoilerCount: 3,
            thStyle: {
              minWidth: '83px',
            },
            filter: { type: 'tagOdk' },
          },
          ...commentColumn,
          {
            key: 'tagButton',
            type: 'button',
            customCheck: () => !this.hasAccessForTagEditing,
          },
          {
            key: 'inspectionButton',
            type: 'button',
            customCheck: () => !this.canInspect,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    defaultContractorName() {
      return this.getAccount?.kind === 'COUNTERPARTY' ? this.getAccount.profile?.name : undefined;
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAccount', 'getAuthorization']),
    ...mapGetters('dataLists', ['odkDefectStatus', 'shiftList']),
    ...mapGetters('dictionary', ['odkDefectStatusDictionary', 'shiftDictionary']),
  },
  methods: {
    changeTableMode(mode) {
      this.tableMode = mode;
      this.loadData();
    },
    async onTapPlacemark(id) {
      const item = this.transformedRegularInspectionSummary.find((it) => it.id === id);
      await this.goToInspection(item.adreskaFactId);
    },
    async handleCellClick(cell) {
      switch (cell.key) {
        case 'tagButton': {
          this.tagModalState = cell.row.adreskaId;
          break;
        }
        case 'inspectionButton': {
          await this.goToInspection(cell.row.adreskaFactId);
          return;
        }
        case 'followLink': {
          await this.$router.push({
            name: this.type === regularInspectionTaskType.INSPECTION ? 'cabinet.handling-defects.card' : 'cabinet.exploitation.card',
            query: { id: cell.row.adreskaId },
          });
        }
      }
    },
    async goToInspection(id) {
      await this.$router.push({
        name: 'cabinet.process.11.6',
        params: {
          initAdreskaFactId: id,
        },
      });
    },
    async transformFilter() {
      const filterMapping = {
        date: {
          name: 'date',
          mapping: formatDateToOdkTimestamp,
        },
        tags: {
          name: 'tag',
        },
      };
      return await mapFilters(
        filterMapping,
        this.filter,
        {},
        this.filterEnd,
      );
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = {
          ...await this.transformFilter(),
          type: this.type,
        };
        const { field: sortField, type: sort } = this.sort;
        let page, size;
        if (this.tableMode === VTableMode.TABLE) {
          page = this.pagination.page;
          size = this.pagination.size;
        } else {
          page = 1;
          size = this.this.regularInspectionSummary.pageOut.totalElements;
        }
        const responseData = await fetchData('regular-inspection-tasks-list-summary', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.regularInspectionSummary = responseData;
        this.regularInspectionSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
      await this.loadObjects();
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = {
          ...await this.transformFilter(),
          type: this.type,
        };
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('regular-inspection-tasks-list-summary', 1, this.regularInspectionSummary.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed) ?? [];
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async loadObjects() {
      try {
        this.loading = true;
        const filters = {
          ...await this.transformFilter(),
          type: this.type,
        };
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('regular-inspection-tasks-list-summary', 1, this.regularInspectionSummary.pageOut.totalElements, sortField, sort, filters);

        this.objects = distinctBy(responseData.data?.map(this.transformedObject) ?? [], (it) => it.id);
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformedObject(item) {
      const inspectionProps = this.type === regularInspectionTaskType.INSPECTION ? [
        {
          label: 'Наличие дефектов',
          value: convertBooleanToText(item.isActualDefect),
        },
      ] : [];
      return {
        id: item.adreskaFactId,
        locationLongitude: item.locationLongitude,
        locationLatitude: item.locationLatitude,
        balloonContent: {
          title: item.odkName,
          buttonLabel: this.canInspect ? 'Осмотреть' : null,
          props: [
            {
              label: 'ID',
              value: item.adreskaFactId,
            },
            {
              label: 'Мероприятие',
              value: item.eventName,
            },
            {
              label: 'АХП',
              value: item.axpName,
            },
            {
              label: 'Программа оформления',
              value: item.adreskaName,
            },
            {
              label: 'Адрес эксплуатации',
              value: item.address,
            },
            {
              label: 'Подрядчик',
              value: item.contractorName,
            },
            ...inspectionProps,
          ],
        },
      };
    },
    transformed(item, index) {
      const inspectionProps = this.type === regularInspectionTaskType.INSPECTION ? [
        {
          label: 'Наличие дефектов',
          value: convertBooleanToText(item.isActualDefect),
        },
      ] : [];
      return {
        ...item,
        index: index,
        id: index,
        preview: {
          src: `${BASE_URL}/axp/${item.axpId}/preview`,
          alt: 'Изображение ОДК',
          previewExists: item.axpPhotosCount,
          notExistsMessage: 'Отсутствует',
        },
        shift: this.shiftDictionary[item.shift],
        startInspectionDate: [...new Set([item.startInspectionDate, item.endInspectionDate])].filter((it) => it).map((it) => formatDateFromOdkTimestamp(it)).join(' ~ '),
        date: formatDateFromOdkTimestamp(item.date),
        tagButton: 'Тэги',
        inspectionButton: 'Осмотреть',
        tags: item.tags.map((tag) => ({ key: `${index} ${tag}`, value: tag })),
        comment: item.comment,
        balloonContent: {
          title: item.odkName,
          buttonLabel: this.canInspect ? 'Осмотреть' : null,
          props: [
            {
              label: 'ID',
              value: item.adreskaFactId,
            },
            {
              label: 'Мероприятие',
              value: item.eventName,
            },
            {
              label: 'АХП',
              value: item.axpName,
            },
            {
              label: 'Программа оформления',
              value: item.adreskaName,
            },
            {
              label: 'Адрес эксплуатации',
              value: item.address,
            },
            {
              label: 'Подрядчик',
              value: item.contractorName,
            },
            ...inspectionProps,
          ],
        },
      };
    },
  }
  ,
}
;
</script>

<style scoped>

</style>
