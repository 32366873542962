<template>
  <page title="Ведомости снятия показаний точек учёта">
    <data-tab-selector
      :data-types-list="tabs"
      class="cabinet-consumers-card__select"
      tab-style="table"
      @selectTab="selectTabHandler"
    />

    <content-table
      v-if="selectedTab === 'receiving'"
      point-type="Приём"
      :loading="loading"
      :measurements-statements="measurementsStatements"
    />
    <content-table
      v-else-if="selectedTab === 'delivery'"
      point-type="Поставка"
      :loading="loading"
      :measurements-statements="measurementsStatements"
    />
    <content-table
      v-else-if="selectedTab === 'transmission'"
      point-type="Передача"
      :loading="loading"
      :measurements-statements="measurementsStatements"
    />
    <content-table
      v-else-if="selectedTab === 'technical'"
      point-type="Техническая"
      :loading="loading"
      :measurements-statements="measurementsStatements"
    />
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import { mapActions } from 'vuex';
import { ContentTable } from './components/ContentTable';

export default {
  name: 'ReadingSheets',
  components: {
    DataTabSelector,
    ContentTable,
    Page,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      loading: false,
      measurementsStatements: [],
      selectedTab: 'receiving',
      kind: 'RECEIVING_POINT',
      tabs: [
        {
          id: 1,
          title: 'Приём',
          value: 'receiving',
          isActive: true,
        },
        {
          id: 2,
          title: 'Поставка',
          value: 'delivery',
          isActive: false,
        },
        {
          id: 3,
          title: 'Передача',
          value: 'transmission',
          isActive: false,
        },
        {
          id: 4,
          title: 'Техническая',
          value: 'technical',
          isActive: false,
        },
      ],
    };
  },
  computed: {
    getSelectedTab() {
      return this.selectedTab;
    },
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
      switch (this.selectedTab) {
        case 'receiving': {
          this.kind = 'RECEIVING_POINT';
          return this.loadData();
        }
        case 'delivery': {
          this.kind = 'DELIVERY_POINT';
          return this.loadData();
        }
        case 'transmission': {
          this.kind = 'TRANSFER_POINT';
          return this.loadData();
        }
        case 'technical': {
          this.kind = 'TECHNICAL_POINT';
          return this.loadData();
        }
      }
    },
    async loadData() {
      this.loading = true;
      try {
        this.measurementsStatements = await this.loadMeasurementsStatements(this.kind);
      } catch (error) {
        console.log('loadMeasurementsStatements', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['loadMeasurementsStatements']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
