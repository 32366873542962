<template>
  <page title="Заявки на технологическое присоединение">
    <div class="form">
      <div>
        <v-table-new
          v-model="transformedApplications"
          :action-block-items="actionBlockItems"
          :loading="loading"
          :template="template"
          excel-report-file-name="Заявки на технологическое присоединение"
          @on-cell-click="cellClickHandler"
        />
        <Details v-model="isShownTaskDetails"
                 :data-url="dataUrl"
                 :is-loading="isLoading"
                 :subtitle="subtitle"
                 :title="title"
        />
      </div>
    </div>
    <router-view></router-view>
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import Details from 'organisms/documents/Details';
import axios from 'axios';
import { convertDbFormat } from 'lib/utils/date';
import { convertToLabelValueList } from '@/utils/list';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

const status = {
  DRAFT: 'На согласовании',
  COMPLETED: 'Выполнена',
  CANCELED: 'Аннулирована',
};

export default {
  name: 'CabinetTp',
  components: {
    Details,
    Page,
    VTableNew,
  },
  props: {
    isTpo: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      applications: [],
      typename: null,
      type: null,
      isShownTaskDetails: false,
      // "loading" for data from server
      loading: false,
      isLoading: true,
      isWaitingCard: false,
      dataUrl: undefined,
      title: '',
      subtitle: '',
      pdfUrl: null,
      visible: false,
      displayTariffsChanging: false,
      from: '1900-01-01T00:00:00Z',
      to: '2900-01-01T00:00:00Z',
    };
  },
  computed: {
    ...mapGetters('user', ['getToken', 'getAccount']),
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Тип задачи',
            key: 'title',
            thStyle: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Объект технологического присоединения',
            key: 'info',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            filter: {},
          },
          {
            label: 'Номер документа',
            key: 'documentNumber',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 5,
              minSreenWidthTooltip: 1000,
            },
            filter: {},
          },
          {
            label: 'Контрагенты',
            key: 'counterparties',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 5,
              minSreenWidthTooltip: 800,
            },
            filter: {},
          },
          {
            label: 'Дата',
            key: 'formattedDate',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Статус',
            key: 'status',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: convertToLabelValueList(['На согласовании', 'Выполнена', 'Аннулирована']) },
          },
          {
            key: 'followLink',
            type: 'followLink',
            customCheck: () => !!this.isTpo,
          },
        ],
      };
    },
    filteredApplications() {
      if (this.isTpo) {
        const currentAccountId = this.getAccount.id;
        return this.applications.filter((application) => {
          const initiator = application.initiatorAccountId;
          return initiator === currentAccountId;
        });
      }
      const currentAccountId = this.getAccount.id;
      return this.applications.filter((application) => {
        const initiator = application.initiatorAccountId;
        return initiator !== currentAccountId;
      });

    },
    transformedApplications() {
      if (this.filteredApplications.length === 0) {
        return [];
      }

      return this.filteredApplications.map((it) => ({
        title: it.title,
        info: it.info,
        documentNumber: it.documentNumber,
        counterparties: it.counterparties?.reduce((acc, cur) => `${acc}${cur.name} `, ''),
        formattedDate: convertDbFormat(it.formattedDate),
        status: status[it.status],
        associatedId: it.associatedId,
      }));
    },
    actionBlockItems() {
      if (this.isTpo) {
        return [
          {
            id: 1,
            title: 'Создать заявку',
            method: goToPageByRoute(this.isTpo ? 'tpo/connection-application' : 'tp/connection-application'),
          },
        ];
      }
      return [];

    },
    ...mapGetters('cabinet', ['getWorkflowsByCorrelationId']),
    ...mapGetters('user', ['getUserType']),
  },
  watch: {
    async isShownTaskDetails(newVal) {
      this.isLoading = true;
      if (newVal) {
        await this.loadView();
        this.isLoading = false;
      }
    },
  },
  methods: {
    async cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          if (this.isWaitingCard) return;
          try {
            this.isWaitingCard = true;
            await this.setWorkflowsByCorrelationId({ correlationId: cell.row.associatedId, userType: this.getUserType });
            const workflows = this.getWorkflowsByCorrelationId();
            console.log(workflows[0]);
            await this.displayDetails({
              ...workflows[0],
            });
          } finally {
            this.isWaitingCard = false;
          }
        }
      }
    },
    formatCounterparties(counterparties) {
      if (!counterparties) {
        return null;
      }

      return counterparties.join('; ');
    },
    async loadData() {
      try {
        this.loading = true;
        this.applications = await this.filterDocumentViews({ kind: 'APPLICATION', from: this.from, to: this.to });
      } catch (error) {
        console.log('filterDocumentViews', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadView() {
      try {
        const token = this.getToken;
        const docPdf = await axios
          .request({
            url: this.pdfUrl,
            method: 'get',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        this.dataUrl = URL.createObjectURL(docPdf.data);
      } catch (error) {
        console.log('error documents docPdf', error);
      }
    },
    onShownTaskDetails(document) {
      if (document.__typename === 'ElectricityTariffsDocumentView') {
        this.type = document.electricityTariffsOrderId;
        this.visible = true;
      } else {
        if (!document.downloadPdfUrl) {
          return;
        }

        this.typename = document.__typename;
        this.title = document.title;
        this.subtitle = document.subtitle;
        this.pdfUrl = document.downloadPdfUrl;

        this.isShownTaskDetails = true;
      }
    },
    ...mapActions('document', ['filterDocumentViews']),
    ...mapActions('cabinet', ['setWorkflowsByCorrelationId']),
    ...mapActions('tasks', ['displayDetails']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
