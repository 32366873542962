<template>
  <button
    class="spoiler-button"
    @click.prevent="$emit('click-by-spoiler-button')"
  >
    <img
      v-if="isNestedShow"
      class="spoiler-button__img"
      src="@/assets/icons/spoiler/open.svg"
    >
    <img
      v-else
      class="spoiler-button__img spoiler-button__img_flip "
      src="@/assets/icons/spoiler/open.svg"
    >
  </button>
</template>

<script>
export default {
  props: {
    isNestedShow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.spoiler-button {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid #DEE2E6;
  background: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 16px;
    height: 16px;

    &_flip {
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
    }

  }
}
</style>
