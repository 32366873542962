<template>
  <inner-page v-if="usagePoint"
              :history="usagePoint.history"
              :history-spacing="298"
              :steps="[`Редактирование точки учёта`]"
              history-title="История"
              home="Точки учёта"
              @back="$router.back()"
              @goHome="goHome"
  >
    <usage-point-header :label-required="true" :status="status" :title="usagePoint.title" :uuid="usagePoint.id" />
    <connection-name v-model="usagePoint"/>
    <suppliers-info v-model="usagePoint"/>
    <!--    <consumer-info v-model="usagePoint"/>-->
    <object-info v-model="usagePoint"/>
    <type-accounting v-model="usagePoint"/>
    <voltage-level v-model="usagePoint"/>
    <meter-info v-if="usagePoint.meter" v-model="usagePoint"/>
    <remote-terminal-unit v-if="false" v-model="usagePoint"/>
    <electricity-losses v-model="usagePoint"/>

    <template v-if="usagePoint.documents">
      <v-divider/>

      <DocumentsList :documents="usagePoint.documents"/>

      <v-divider/>
    </template>

    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="goHome">
          Отменить изменения
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button class="btn-print">
          Напечатать
        </button>

        <button class="btn-save">
          Сохранить
        </button>
      </div>
    </div>
  </inner-page>
</template>

<script>
import { DocumentsList, InnerPage, VDivider } from '@/components';
import UsagePointHeader from './components/UsagePointHeader';
import ConnectionName from './components/ConnectionName';
import SuppliersInfo from './components/SuppliersInfo';
import ObjectInfo from './components/ObjectInfo';
import TypeAccounting from './components/TypeAccounting';
import VoltageLevel from './components/VoltageLevel';
import MeterInfo from './components/MeterInfo';
import RemoteTerminalUnit from './components/RemoteTerminalUnit';
import ElectricityLosses from './components/ElectricityLosses';
import { mapActions } from 'vuex';

export default {
  name: 'UsagePoint',
  components: {
    InnerPage,
    UsagePointHeader,
    ConnectionName,
    SuppliersInfo,
    // ConsumerInfo,
    ObjectInfo,
    TypeAccounting,
    VoltageLevel,
    MeterInfo,
    RemoteTerminalUnit,
    ElectricityLosses,
    DocumentsList,
    VDivider,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      usagePoint: undefined,
      revision: undefined,
      usagePointTest: {
        title: 'ТЭЦ-23, РТП-1, ТП-1',
        boundariesBalanceSheet: 'ТЭЦ-23 (ул. Набережная, 85)',
        borderName: 'ТЭЦ-23, КРУ-10кВ, РТП-1, ТП-1',
        history: [
          'Заявка отправлена от ООО «Гиацинт» в ТСО ООО «СК Энергия» на подключение объекта располагаемого по адресу: город Москва, улица Мневники, дом 4',
          'ООО «СК Энергия» в лице сотрудника Ёжикова А.С. одобрил заявку на технологическое присоединение',
          'ООО «СК Энергия» в лице сотрудника Ёжикова А.С. выслал договор на технологическое присоединение и технические условия в ООО «Гиацинт»',
          'ООО «СК Энергия» направило в ООО «Гиацинт» акт о технологическом присоединении и акт допуска прибора учёта электрической энергии в эксплуатацию',
        ],
        pointInfo: {},
        supplier: {
          name: 'ООО «СК Энергия»',
        },
        retailer: {
          name: 'ПАО «Мосэнергосбыт»',
        },
        consumer: {
          contactPerson: {},
        },
        objectType: '',
        meter: {},
        pointStatus: 'SETTLED',
        currentTransformer: {},
        potentialTransformer: {},
        remoteTerminalUnit: {},
        documents: [
          {
            name: 'Заявка на технологическое присоединение.XML',
            date: '22.02.2023',
            documentId: '',
          },
          {
            name: 'Договор на технологическое присоединение.XML',
            date: '22.02.2023',
            documentId: '',
          },
          {
            name: 'Приложение 1 к договору технологического присоединения: Техниче... .XML',
            date: '22.02.2023',
            documentId: '',
          },
        ],
      },
    };
  },
  computed: {
    status() {
      console.log(this.usagePoint);
      return [
        {
          type: 'isOk',
          value: this.usagePoint.pointStatus,
        },
      ];
    },
  },
  methods: {
    goHome() {
      this.$router.push('/cabinet/accounting-points');
    },
    async goRevision(revision) {
      this.revision = revision;
      await this.loadData();
    },
    getStringWithSpaceOrEmpty(val) {
      return val ? `${val} ` : '';
    },
    getPointTitle(pointConnection) {
      return `${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.mainSubstation)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.feeder)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.distributionSubstation)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.line)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.transformationSubstation)}${this.meterTitle(pointConnection)}`;
    },
    meterTitle(pointConnection) {
      if (pointConnection.meter?.serialNumber) {
        return ` Прибор учёта №${pointConnection.meter?.serialNumber}`;
      }
      return '';
    },
    async loadData() {
      try {
        const point = await this.loadUsagePoint({ id: this.$route.params.id, revision: this.revision });
        this.usagePoint = {
          ...point,
          title: this.getPointTitle(point),
          history: point.history?.map((history) => ({
              message: `Точка учёта изменена документом "${history.updatedByDocument.title}". Хеш транзакции: ${history.updatedByDocument.hash}`,
              datetime: history.updatedAt,
              onClick: async () => await this.goRevision(history.revisionId),
              current: history.revisionId === this.revision,
            })) ?? [],
        };
      } catch (e) {
        console.log('Usage point data loading exception', e);
      }
    },
    ...mapActions('point', ['loadUsagePoint']),
  },
};
</script>
<style lang="scss" scoped>
.action-buttons {
  display: flex;

  button {
    height: 40px;
    font-family: Roboto, 'sans-serif';
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }
  }

  .btn-print {
    background-image: url('~icons/basic/print.svg');
    background-repeat: no-repeat;
    background-position: center left 14px;
    padding-left: 48px;
  }

  &__action {
    width: 100%;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 16px;
      }
    }
  }
}
</style>
