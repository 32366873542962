<template>
  <v-form @submit="next">
    <div :class="$style.container">
      <div
        ref="navigationBar"
        :style="{
            position: 'sticky',
            top: '-16px',
            backgroundColor: '#ffffff',
            paddingLeft: '16px',
            zIndex: '100',
            width: navBarWidth,
            marginLeft: '-16px',
            paddingTop: '16px',
            marginTop: '-16px',
            marginBottom: '-8px',
            paddingBottom: '8px',
            display: 'flex',
            gap: '24px',
          }"
      >
        <button
          :class="$style.btnBack"
          type="button"
          :disabled="isWaitingSubmit"
          @click="back"
        >
          Назад
        </button>
        <navigation-bar
          :current-step="currentStep"
          home="Баланс общий"
          :steps="steps"
          @open="openStep"
        />
      </div>

      <div ref="balanceContent" :class="$style.content">
        <component
          :is="steps[currentStep].component"
          :step-number="steps[currentStep].stepNumber"
          :height-navigation-bar="heightNavigationBar"
          :creation-by-transmission-contract="requestData.creationByTransmissionContract"
          @close="close"
          @complete="complete"
          @next="next"
          @updateData="updateData"
        />
      </div>

      <v-circle-loader v-if="isWaitingSubmit"/>

      <div
        :class="$style.contentFooter"
        :style="{ position: 'absolute', bottom: '0', width: footerWidth, }"
      >
        <v-button :class="$style.delete" type="button" variant="outlined-red" :disabled="isWaitingSubmit" @click="displayDeleteDialog = true">
          <delete />
          <span>Удалить черновик</span>
        </v-button>
        <div :class="$style.center">&nbsp;</div>
        <v-button v-show="displayNext" :disabled="disabledNext" :class="$style.next" type="submit">
          <span>Далее</span>
          <arrow-right />
        </v-button>
        <v-button v-show="!displayNext" :class="$style.next" type="button" :disabled="isWaitingSubmit" @click="generatePlanningBalance">
          <span>Отправить баланс на согласование</span>
        </v-button>
      </div>
      <dialog-template v-model="displayDeleteDialog" center>
        <p :class="$style.deleteText">
          Удалить черновик?
        </p>
        <div :class="$style.submitBlock">
          <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraftAndClose" />
          <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
        </div>
      </dialog-template>
    </div>
  </v-form>
</template>

<script>
import Delete from 'atoms/icons/action/Delete.vue';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import ArrowRight from 'atoms/icons/arrows/ArrowRight.vue';
import GeneratePlanningBalance from './steps/GeneratePlanningBalance/GeneratePlanningBalance.vue';
import SignatoriesStep from './steps/SignatoriesStep/SignatoriesStep.vue';
import { NavigationBar, VButton, VForm, VCircleLoader } from 'components';
import { mapActions, mapGetters } from 'vuex';
import { removeDraft } from 'services/draft';

export default {
  name: 'GeneratePlanningBalanceIndex',
  components: {
    GeneratePlanningBalance,
    DialogTemplate,
    SubmitTaskDetails,
    SignatoriesStep,
    NavigationBar,
    VButton,
    VForm,
    Delete,
    ChevronLeft,
    ArrowRight,
    VCircleLoader,
  },
  async created() {
    if (!this.steps[this.currentStep]) {
      this.currentStep = 0;
    }
    window.addEventListener('resize', this.setElementsWidth);
    this.isLoading = false;
  },
  mounted() {
    this.setElementsWidth();
  },
  updated() {
    this.heightNavigationBar = this.$refs.navigationBar.clientHeight;
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      currentStep: 0,
      displayDeleteDialog: false,
      steps: [
        {
          component: 'GeneratePlanningBalance',
          isComplete: false,
          stepNumber: {
            value: 1,
            from: 2,
            title: () => 'Формирование баланса',
          },
        },
        {
          component: 'SignatoriesStep',
          isComplete: false,
          stepNumber: {
            value: 2,
            from: 2,
            title: () => 'Подписание',
          },
        },
      ],
      footerWidth: '0',
      navBarWidth: '0',
      heightNavigationBar: 24,
      isWaitingSubmit: false,
      requestData: {
        transmissionContractId: '',
        usagePointsIds: [],
        currentDate: {},
        totalLoss: '',
        sidesRepresentatives: {},
        balanceGroupName: '',
        isMeasurementsExists: false,
        isTransmissionContractSigned: false,
        creationByTransmissionContract: {},
      },
    };
  },
  computed: {
    disabledNext() {
      return !this.requestData?.isTransmissionContractSigned || !this.requestData?.isMeasurementsExists ||
        !this.requestData.usagePointsIds.length > 0;
    },
    displayNext() {
      return this.currentStep < this.steps.length - 1;
    },
    ...mapGetters('user', ['getAccount', 'getUser']),
  },
  methods: {
    async generatePlanningBalance() {
      try {
        this.isWaitingSubmit = true;

        await this.generateBalanceByUsagePoints({
          transmissionContractId: this.requestData.transmissionContractId,
          usagePointIds: this.requestData.usagePointsIds,
          accountingPeriod: this.requestData.currentDate,
          totalLoss: '',
          supplierRepresentative: this.requestData.sidesRepresentatives.supplierRepresentative,
          performerFirstRepresentative: this.requestData.sidesRepresentatives.performerFirstRepresentative,
          performerSecondRepresentative: this.requestData.sidesRepresentatives.performerSecondRepresentative,
          boundaryName: this.requestData.balanceGroupName,
        });

        this.deleteDraft();
        this.close();
      } catch (error) {
        console.log('error on send planningBalance', error);
        this.setNotification({ message: 'Ошибка формирования баланса' });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async deleteDraftAndClose() {
      await this.deleteDraft();
      await this.$router.push('/cabinet/balances');
    },
    async deleteDraft() {
      try {
        await removeDraft(`${this.getAccount.id}${this.getUser.id}planningBalance`);
      } catch (error) {
        console.error('Error deleting draft data:', error);
      }
    },
    setElementsWidth() {
      this.navBarWidth = `${this.$refs.balanceContent.offsetWidth + 16}px`;
      this.footerWidth = `${this.$refs.balanceContent.offsetWidth}px`;
    },
    close() {
      this.$router.go(-1);
    },
    openStep(value) {
      if (value === -1) {
        this.close();
        return;
      }
      if (value >= this.currentStep + 1) {
        return;
      }

      this.currentStep = value - 1;
    },
    back() {
      if (this.currentStep === 0) {
        return this.$router.go(-1);
      }
      this.currentStep -= 1;
    },
    next() {
      this.currentStep += 1;
    },
    complete(isComplete) {
      this.steps[this.currentStep].isComplete = isComplete;
    },
    updateData(data) {
      this.requestData = {
        ...this.requestData,
        ...data,
      };
    },
    ...mapActions('planningBalance', ['generateBalanceByUsagePoints']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
}

.loader {
  position: relative;
  height: 50px;

  div:first-child {
    top: 50px;
  }
}

.content {
  padding-top: 8px;
  padding-bottom: 60px;
  flex-grow: 2;
}

.contentFooter {
  height: 48px;
  display: flex;
  background-color: #ffffff;
  z-index: 1000;
}

.back, .next, .delete, .save {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;

  svg {
    fill: #FFFFFF;
  }
}

.delete {
  margin-left: 16px;

  svg {
    path {
      fill: #EB5757
    }
  }

  span {
    margin-left: 7px;
  }
}

.center {
  flex-grow: 2;
}

.back {
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.next {
  margin-right: 36px;

  span {
    margin-right: 13px;
  }
}

.save {
  margin-right: 16px;

  span {
    margin-left: 7px;
  }
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.uploadingDraft {
  z-index: 100;
}

.btnBack {
  width: 86px;
  height: 24px;
  margin: -8px 16px 0 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #677379;
  padding-left: 12px;

  background-image: url('~icons/navigation/arrow_left.svg');
  background-repeat: no-repeat;
  background-position: center left 4px;
  background-color: #FFFFFF;
  border: 1px solid #C1C4C7;
  border-radius: 24px;

  &:hover {
    background-color: #F5F6F6;
  }
}
</style>
