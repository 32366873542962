<template>
  <div>
    <div :id="`yandex-map-${id}`" style="width: 100%; height: 400px"></div>
    <v-dialog v-model="multipleAddingDialog"
              class="v-dialog-top" persistent width="250">
      <v-card class="v-application">
        <v-card-title class="text-h5 text-center green lighten-2">
          Добавление точек
        </v-card-title>
        <text-field v-model="addingCount" class="ma-4" is-only-integer-numbers is-required min="1"
                    title="Введите количество"/>
        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            rounded
            @click="onCancelMultipleAddingButtonClick"
          >
            Отменить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="isDisabledMultipleAddingSaveButton" color="primary" elevation="0"
                 rounded @click="onSaveMultipleAddingButtonClick">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import TextField from 'components/Processes/components/TextField.vue';
import { mapActions } from 'vuex';

const MINIMAL_POINT_DISTANCE = 0.3;
const DEFAULT_COLOR = 'blue';
const routingType = [{
  value: 'masstransit',
  label: 'На общественном транспорте',
}, {
  value: 'auto',
  label: 'На автомобиле',
}, {
  value: 'pedestrian',
  label: 'Пешком',
}];

export default {
  components: { TextField },
  props: {
    id: {
      type: String,
      default: '0',
    },
    collectionAxp: {
      type: Array,
      default: () => [],
    },
    enableRouting: {
      type: Boolean,
      default: false,
    },
    enableMultipleAdding: {
      type: Boolean,
      default: false,
    },
    onTapPlacemark: {
      type: Function,
      default: () => ({}),
    },
  },

  mounted() {
    const script = document.createElement('script');
    script.src =
      `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=a8060cad-3c7e-448d-bac3-b7724ce215d7&ns=ymaps-${this.id}`;
    script.onload = () => {
      this.initMap();
    };
    document.head.appendChild(script);
  },

  data: () => ({
    ymaps: null,
    myMap: null,
    routingType: null,
    polygone: null,
    isMultipleAddingEnabled: false,

    // Controls
    routingTypeSelector: null,
    multipleAddingButton: null,
    multipleSaveButton: null,
    multipleCancelButton: null,

    // Init Many Points Dialog
    multipleAddingDialog: false,
    addingCount: '0',
    isDrawing: false,
  }),
  computed: {
    isDisabledMultipleAddingSaveButton() {
      return !this.addingCount.match('^\\d*$') || Number(this.addingCount) <= 0;
    },
  },

  watch: {
    collectionAxp: {
      deep: true,
      handler() {
        this.updateCollection();
      },
    },
    isMultipleAddingEnabled() {
      if (this.isMultipleAddingEnabled) {
        this.polygone = new this.ymaps.Polyline([], {}, {
          editorDrawingCursor: 'crosshair',
          fillColor: '#00FF00',
          fillOpacity: 0.4,
          strokeColor: '#0000FF',
          strokeOpacity: 0.7,
          strokeWidth: 5,
        });
      } else {
        this.polygone.editor.stopDrawing();
        this.polygone = null;
      }
      this.updateCollection();
      this.updateButtons();
    },
    isDrawing() {
      this.updateButtons();
    },
    coords() {
      this.updateCollection();
    },
    routingType() {
      this.updateCollection();
    },
    enableRouting() {
      this.updateButtons();
    },
    enableMultipleAdding() {
      this.updateButtons();
    },
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      window[`ymaps-${this.id}`].ready(() => {
        // eslint-disable-next-line no-undef
        this.ymaps = window[`ymaps-${this.id}`];
        const children = document.getElementById(`yandex-map-${this.id}`).children;
        for (let i = 0; i < children.length; i++) {
          children.item(i).remove();
        }

        this.myMap = new this.ymaps.Map(
          `yandex-map-${this.id}`,
          {
            center: [55.751574, 37.573856],
            zoom: 9,
            controls: ['zoomControl'],
          },
          { suppressMapOpenBlock: true },
          {
            searchControlProvider: 'yandex#search',
          },
        );

        this.myMap.events.add('click', this.onClickMap);
        this.updateCollection();
        this.updateButtons();
      });
    },
    addControlIfNotExists(control, params) {
      if (this.myMap.controls.indexOf(control) === -1)
        this.myMap.controls.add(control, params);
    },
    removeControl(control) {
      this.myMap.controls.remove(control);
    },
    addBaseControls() {
      this.addControlIfNotExists('zoomControl');
    },
    createRoutingListBoxItem(type) {
      return new this.ymaps.control.ListBoxItem({
        data: {
          content: type.label,
          routingType: type.value,
        },
        state: {
          selected: this.routingType === type.value,
        },
      });
    },
    initRoutingTypeSelector() {
      const listBoxItems = routingType.map(this.createRoutingListBoxItem);
      this.routingTypeSelector = new this.ymaps.control.ListBox({
        items: listBoxItems,
        data: {
          content: 'Построить маршрут',
        },
      });
      this.routingTypeSelector.events.add('click', (e) => {
        this.onListBoxClick(this.routingTypeSelector, listBoxItems, e.get('target'));
      });
    },
    addRoutingControls() {
      if (!this.routingTypeSelector) this.initRoutingTypeSelector();
      this.addControlIfNotExists(this.routingTypeSelector, { float: 'left' });
    },
    removeRoutingControls() {
      this.removeControl(this.routingTypeSelector);
    },
    removeMultipleAddingControls() {
      this.removeControl(this.multipleCancelButton);
      this.removeControl(this.multipleSaveButton);
      this.removeControl(this.multipleAddingButton);
    },
    initMultipleAddingButton() {
      this.multipleAddingButton = new this.ymaps.control.Button({
        data: {
          content: 'Добавление нескольких объектов',
        },
        options: {
          maxWidth: [400],
        },
      });

      this.multipleAddingButton.events.add('click', this.onEnableMultipleAddingButtonClick);
    },
    initMultipleCancelButton() {
      this.multipleCancelButton = new this.ymaps.control.Button({
        data: {
          content: 'Отменить',
        },
        state: {
          selected: false,
        },
        options: {
          maxWidth: [400],
        },
      });

      this.multipleCancelButton.events.add('click', this.onCancelMultipleAddingButtonClick);
    },
    initMultipleSaveButton() {
      this.multipleSaveButton = new this.ymaps.control.Button({
        data: {
          content: 'Добавить точки',
        },
        state: {
          selected: false,
        },
        options: {
          maxWidth: [400],
        },
      });

      this.multipleSaveButton.events.add('click', this.onAddMultipleAddingButtonClick);
    },
    addMultipleAddingControls() {
      if (!this.multipleAddingButton) {
        this.initMultipleAddingButton();
      } else {
        this.multipleAddingButton.deselect();
      }
      if (!this.multipleSaveButton) {
        this.initMultipleSaveButton();
      } else {
        this.multipleSaveButton.deselect();
      }
      if (!this.multipleCancelButton) {
        this.initMultipleCancelButton();
      } else {
        this.multipleCancelButton.deselect();
      }
      if (!this.isMultipleAddingEnabled) {
        this.removeControl(this.multipleSaveButton);
        this.removeControl(this.multipleCancelButton);
        this.addControlIfNotExists(this.multipleAddingButton, { float: 'left' });
      } else {
        this.removeControl(this.multipleAddingButton);
        if (this.isDrawing) {
          this.removeControl(this.multipleSaveButton);
          this.removeControl(this.multipleCancelButton);
        } else {
          this.addControlIfNotExists(this.multipleSaveButton, { float: 'left' });
          this.addControlIfNotExists(this.multipleCancelButton, { float: 'left' });
        }
      }

    },
    updateButtons() {
      if (!this.ymaps) return;
      this.addBaseControls();
      if (this.enableRouting) {
        this.addRoutingControls();
      } else {
        this.removeRoutingControls();
      }

      if (this.enableMultipleAdding) {
        this.addMultipleAddingControls();
      } else {
        this.removeMultipleAddingControls();
      }
    },
    // Map Update Items
    clearMapItems() {
      this.myMap.geoObjects.removeAll();
    },
    addRouting() {
      const multiRoute = new this.ymaps.multiRouter.MultiRoute({
        referencePoints: this.collectionAxp.map((it) => [it.locationLatitude, it.locationLongitude]),
        params: {
          routingMode: this.routingType,
          reverseGeocoding: true,
        },
      }, {
        boundsAutoApply: true,
        wayPointVisible: false,
      });
      this.myMap.geoObjects.add(multiRoute);
    },
    createClusterer(color) {
      return new this.ymaps.Clusterer({
        preset: `islands#${color}ClusterIcons`,
      });
    },
    addObjects() {
      const clusterersByColor = new Map();
      clusterersByColor.set(DEFAULT_COLOR, this.createClusterer(DEFAULT_COLOR, this.collectionAxp[0]));
      this.collectionAxp.forEach((axp) => {
        let iconWhenSelected = '';
        let iconWhenNotSelected = '';
        if (axp.color?.length) {
          iconWhenSelected = `islands#${axp.color}DotIcon`;
          iconWhenNotSelected = `islands#${axp.color}Icon`;
        } else {
          iconWhenSelected = `islands#${DEFAULT_COLOR}DotIcon`;
          iconWhenNotSelected = `islands#${DEFAULT_COLOR}Icon`;
        }
        const balloonContentTemplate = this.createBalloonContentHtml(axp);
        const balloonContentLayout = this.createBalloonContentLayout(axp);

        const placemark = this.createPlacemark(
          axp,
          iconWhenSelected,
          iconWhenNotSelected,
          axp.balloonContent?.title,
          axp.description ?? '',
          balloonContentLayout,
          axp.balloonContent?.clusterTitle,
          balloonContentTemplate,
        );
        if (axp.balloonContent === undefined) {
          placemark.events.add('click', (e) => {
            this.onObjectClick(e.get('target'));
          });
        }
        if (clusterersByColor.has(axp.color)) {
          clusterersByColor.get(axp.color).add(placemark);
        } else {
          const clusterer = this.createClusterer(axp.color, axp);
          clusterersByColor.set(axp.color, clusterer);
          clusterer.add(placemark);
        }
      });
      [...clusterersByColor.values()].forEach((it) => this.myMap.geoObjects.add(it));
    },
    addPolygone() {
      this.myMap.geoObjects.add(this.polygone);
      const stateMonitor = new this.ymaps.Monitor(this.polygone.editor.state);
      stateMonitor.add('drawing', (newValue) => {
        this.isDrawing = newValue;
        this.polygone.options.set('strokeColor', newValue ? '#FF0000' : '#0000FF');
      });
      this.polygone.editor.startDrawing();
    },
    updateCollection() {
      if (!this.ymaps) return;
      this.clearMapItems();
      if (this.routingType) {
        this.addRouting();
      }
      this.addObjects();
      if (this.polygone) {
        this.addPolygone();
      }
    },

    // Draw Placemark
    createPlacemark(axp, iconWhenSelected, iconWhenNotSelected, header, description, balloonContentLayout, clusterBalloonTitle, clusterBalloonBody) {
      const presetIcon = axp.isSelected
        ? iconWhenSelected
        : iconWhenNotSelected;

      const placemark = new this.ymaps.Placemark(
        [axp.locationLatitude, axp.locationLongitude],
        {
          id: axp.id,
          description: description,
          isBalloonContent: axp.balloonContent !== undefined,
          balloonContentBody: clusterBalloonBody ?? description,
          clusterCaption: clusterBalloonTitle ?? header,
        },
        {
          preset: presetIcon,
          description: description,
          balloonContentLayout: balloonContentLayout,
        },
      );
      return placemark;
    },
    createBalloonContentHtml(axp, buttonAllowed) {
      if (!axp.balloonContent) {
        return undefined;
      }

      let properties = '';

      axp.balloonContent.props?.forEach((item) => {
        properties += item.value !== undefined && item.value !== null ? `<p><b>${item.label}: </b>${item.value}</p>` : '';
      });

      if (!axp.balloonContent.isNotDisplayCoordinates) {
        properties += `<p><b>Координаты: </b>${axp.locationLatitude}, ${axp.locationLongitude}</p>`;
      }

      let template = '<div>' +
          `<h2 style="margin-bottom: 16px">${axp.balloonContent.title}</h2>` +
          `${properties}`;
      if (axp.balloonContent.buttonLabel && buttonAllowed) {
        template += `<div style="text-align: center"><button id="map-balloon-button" style="border-radius: 24px;padding: 10px 25px;background-color: #2f82df;color: #ffffff;">${axp.balloonContent.buttonLabel}</button></div>`;
      }

      template += '</div>';

      return template;
    },
    createBalloonContentLayout(axp) {
      if (!axp.balloonContent) {
        return undefined;
      }

      const template = this.createBalloonContentHtml(axp, true);

      const balloonContentLayout = this.ymaps.templateLayoutFactory.createClass(template, {
        build: function() {
          balloonContentLayout.superclass.build.call(this);
          document?.getElementById('map-balloon-button')?.addEventListener('click', this.onCounterClick);
        },
        clear: function() {
          document?.getElementById('map-balloon-button')?.removeEventListener('click', this.onCounterClick);
          balloonContentLayout.superclass.clear.call(this);
        },
        onCounterClick: () => {
          this.$emit('tapPlacemark', axp.id);
        },
      });

      return balloonContentLayout;
    },

    // Map Events
    onClickMap(e) {
      if (this.isMultipleAddingEnabled) return;
      const coords = e.get('coords');
      if (!coords?.length) return;
      return !this.$emit('clickMap', coords);
    },
    onEnableMultipleAddingButtonClick() {
      this.isMultipleAddingEnabled = true;
    },
    onCancelMultipleAddingButtonClick() {
      this.isDrawing = false;
      this.isMultipleAddingEnabled = false;
      this.multipleAddingDialog = false;
    },
    onAddMultipleAddingButtonClick() {
      if (this.polygone.geometry.getLength() <= 1) {
        this.setNotification({ message: 'Вы должны выбрать как минимум 2 точки' });
        this.updateButtons();
        return;
      }
      this.multipleAddingDialog = true;
    },
    onSaveMultipleAddingButtonClick() {
      const geometry = this.polygone.geometry;
      // Находим общее расстояние и через сколько нам надо ставить точки
      const totalDistance = geometry.getDistance();
      const pointCount = Number(this.addingCount);
      const pointEveryDistance = totalDistance / pointCount;
      // Если расстояние слишком маленькое - ошибку
      if (pointEveryDistance < MINIMAL_POINT_DISTANCE) {
        this.setNotification({ message: 'Расстояние между точками слишком мало' });
        return;
      }

      // Расстояние от последней точки предыдущего отрезка до начала текущего отрезка
      let prevPointDistance = 0;
      // Идем по всем отрезкам
      for (let section = 0; section < geometry.getLength() - 1; section++) {
        const sectionDistance = geometry.getDistance(section, section + 1);
        // Количество точек которые нужны на отрезку
        const needPointsAtSection = Math.floor((prevPointDistance + sectionDistance) / pointEveryDistance);
        // Вдруг секция очень короткая
        if (needPointsAtSection > 0) {

          // Координаты точек
          const startPointCoords = geometry.get(section);
          const endPointCoords = geometry.get(section + 1);

          // Находим отступ для первой точки по пропорции
          const firstPointStartDistance = (pointEveryDistance - prevPointDistance) % pointEveryDistance;
          const firstPointStartRatio = firstPointStartDistance / sectionDistance;

          // Находим длину отрезка в координатах
          // TODO поскольку предполагаемые размеры области небольшие - можно принебречь изменением ценности одного градуса
          const longitudeDelta = (endPointCoords[0] - startPointCoords[0]);
          const latitudeDelta = (endPointCoords[1] - startPointCoords[1]);

          // Коррдината первой точки
          const firstPointLongitude = startPointCoords[0] + (longitudeDelta * firstPointStartRatio);
          const firstPointLatitude = startPointCoords[1] + (latitudeDelta * firstPointStartRatio);

          // Находим длину отрезка между токами
          const longitudePerPoint = (longitudeDelta * (1 - firstPointStartRatio)) / needPointsAtSection;
          const latitudePerPoint = (latitudeDelta * (1 - firstPointStartRatio)) / needPointsAtSection;
          // Отправляем 1 точку
          this.$emit('clickMap', [firstPointLongitude, firstPointLatitude]);
          // Отправляем остальные точки
          for (let point = 1; point < needPointsAtSection; point++) {
            this.$emit('clickMap', [firstPointLongitude + (longitudePerPoint * point), firstPointLatitude + (latitudePerPoint * point)]);
          }
        }
        // Сохраняем остаток
        prevPointDistance = (prevPointDistance + sectionDistance) % pointEveryDistance;
      }
      this.addingCount = '0';
      this.multipleAddingDialog = false;
      this.isMultipleAddingEnabled = false;
    },
    onListBoxClick(listBox, listBoxItems, target) {
      if (target != listBox) {
        // TODO мб есть вариант из коробки но я не нашел
        listBoxItems.forEach((it) => {
          if (it !== target) it.deselect();
        });
        this.routingType =
          target.data.get('routingType');
      }
    },
    onObjectClick(target) {
      const data = target.properties._data;
      this.$emit('tapPlacemark', data.id);
    },
    ...mapActions('user', ['setNotification']),
  },
}
;
</script>


