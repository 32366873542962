<template>
  <page title="Складские операции">
    <action-block :type="selectedTab" @report="saveReport" @saveWarehouseReport="saveWarehouseReport"/>
    <data-tab-selector
      v-if="tabs.length"
      :data-types-list="tabs"
      class="cabinet-consumers-card__select"
      tab-style="table"
      @selectTab="selectTabHandler"
    />
    <warehouse-elements-table v-if="selectedTab==='elements'"/>
    <warehouse-elements-leftover-table v-else-if="selectedTab === 'elements-warehouse'"/>
    <warehouse-axp-table v-else-if="selectedTab==='axp'"/>
    <warehouse-axp-letfover-table v-else-if="selectedTab === 'axp-warehouse'"/>
    <warehouse-to-be-extradited-table v-else-if="selectedTab==='to-extradite'"/>
    <warehouse-for-acceptance-table v-else-if="selectedTab==='to-acceptance'"/>
    <contractor-debt-table v-else-if="selectedTab==='contractor-debt'"/>
    <consignment-table v-else-if="selectedTab==='consignments'"/>
    <plan-consignment-table v-else-if="selectedTab==='plan-consignments'"/>
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import ActionBlock from './ActionBlock';
import WarehouseElementsTable from './WarehouseElementsTable.vue';
import ConsignmentTable from './ConsignmentTable.vue';
import WarehouseAxpTable from './WarehouseAxpTable.vue';
import WarehouseToBeExtraditedTable from './WarehouseToBeExtraditedTable.vue';
import WarehouseForAcceptanceTable from './WarehouseForAcceptanceTable.vue';
import ContractorDebtTable from './ContractorDebtTable.vue';
import PlanConsignmentTable from './PlanConsignmentTable.vue';
import { mapGetters } from 'vuex';
import { filterForAccess } from 'lib/utils/permissions';
import WarehouseElementsLeftoverTable from 'views/private/WarehouseOperations/WarehouseElementsLeftoverTable';
import WarehouseAxpLetfoverTable from 'views/private/WarehouseOperations/WarehouseAxpLetfoverTable';

export default {
  name: 'WarehouseOperations',
  components: {
    WarehouseAxpLetfoverTable,
    WarehouseElementsLeftoverTable,
    PlanConsignmentTable,
    ContractorDebtTable,
    WarehouseForAcceptanceTable,
    WarehouseToBeExtraditedTable,
    ConsignmentTable,
    WarehouseElementsTable,
    WarehouseAxpTable,
    DataTabSelector,
    ActionBlock,
    Page,
  },
  created() {
    const tabsByAccountKind = this.isBoiler ? this.boilerTabs : this.counterpartyTabs;
    const blockAccess = this.getAuthorization?.blockAccess;
    this.tabs = tabsByAccountKind.filter((tab) => filterForAccess(`warehouse-operations/${tab.value}`, blockAccess));
    if (this.tabs.length) {
      this.tabs[0].isActive = true;
      this.selectTabHandler(this.tabs[0]);
    }
  },
  data() {
    return {
      boilerTabs: [
        {
          id: 1,
          title: 'Складские остатки. Элементы АХП',
          value: 'elements',
          isActive: false,
        },
        {
          id: 2,
          title: 'Складские остатки. Элементы АХП по складам',
          value: 'elements-warehouse',
          isActive: false,
        },
        {
          id: 3,
          title: 'Складские остатки. АХП',
          value: 'axp',
          isActive: false,
        },
        {
          id: 4,
          title: 'Складские остатки. АХП по складам',
          value: 'axp-warehouse',
          isActive: false,
        },
        {
          id: 5,
          title: 'К выдаче',
          value: 'to-extradite',
          isActive: false,
        },
        {
          id: 6,
          title: 'К приёмке',
          value: 'to-acceptance',
          isActive: false,
        },
        {
          id: 7,
          title: 'Долги подрядчиков',
          value: 'contractor-debt',
          isActive: false,
        },
        {
          id: 8,
          title: 'Накладные',
          value: 'consignments',
          isActive: false,
        },
        {
          id: 9,
          title: 'Проекты накладных',
          value: 'plan-consignments',
          isActive: false,
        },
      ],
      counterpartyTabs: [
        {
          id: 5,
          title: 'Долги',
          value: 'contractor-debt',
          isActive: false,
        },
        {
          id: 3,
          title: 'К выдаче',
          value: 'to-extradite',
          isActive: false,
        },
        {
          id: 4,
          title: 'К приёмке',
          value: 'to-acceptance',
          isActive: false,
        },
        {
          id: 6,
          title: 'Накладные',
          value: 'consignments',
          isActive: false,
        },
        {
          id: 7,
          title: 'Проекты накладных',
          value: 'plan-consignments',
          isActive: false,
        },
      ],
      tabs: [],
      selectedTab: null,
    };
  },
  computed: {
    ...mapGetters('user', ['isBoiler', 'getAuthorization']),
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    saveReport() {
      this.saveReportFlag = !this.saveReportFlag;
    },
    saveWarehouseReport() {
      this.saveWarehouseReportFlag = !this.saveWarehouseReportFlag;
    },
  },

};
</script>

<style scoped>

</style>
