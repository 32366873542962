<template>
  <page title="Ведение договоров">
    <action-block/>
    <data-tab-selector
      v-if="tabs.length"
      :data-types-list="tabs"
      class="cabinet-initial-control-inspection__block"
      tab-style="table"
      @selectTab="selectTabHandler"
    />
    <contracts-table v-if="selectedTab === 'contracts'"/>
    <specification-table v-else-if="selectedTab === 'specification'"/>
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import ActionBlock from 'views/private/CabinetInitialControlInspection/ActionBlock';
import ContractsTable from 'views/private/CabinetInitialControlInspection/ContractsTable.vue';
import SpecificationTable from 'views/private/CabinetInitialControlInspection/SpecificationTable.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'CabinetInitialControlInspection',
  components: { SpecificationTable, ContractsTable, DataTabSelector, ActionBlock, Page },
  created() {
    const blockAccess = this.getAuthorization?.blockAccess;
    this.tabs = this.tabs.filter((tab) => filterForAccess(`initial-control-inspection/${tab.value}`, blockAccess));
    if (this.tabs.length) {
      this.tabs[0].isActive = true;
      this.selectTabHandler(this.tabs[0]);
    }
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'Договоры',
          value: 'contracts',
          isActive: false,
        },
        {
          id: 2,
          title: 'Спецификация',
          value: 'specification',
          isActive: false,
        },
      ],
      selectedTab: null,
    };
  },
  computed: {
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style scoped>
.cabinet-initial-control-inspection__block {
  padding-left: 8px;
}
</style>
