<template>
  <div :class="$style.content">
    <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

    <v-divider :class="$style.divider" />

    <p :class="$style.stepSubTitle">
      Отделение гарантирующего поставщика предоставившего 18 формы
    </p>
    <label :class="$style.label">
      <p :class="{[$style.label]: true, [$style.error]: usefulVacationDocument.Retailer_error.error}">Выбрать отделение</p>
      <select v-model="usefulVacationDocument.Retailer" :class="{[$style.select]: true, [$style.errorSelect]: usefulVacationDocument.Retailer_error.error}" role="Retailer">
        <option v-for="(retailer, index) in departments" :key="index" :value="retailer">
          {{ retailer.name }}
        </option>
      </select>
      <p v-if="usefulVacationDocument.Retailer_error.error" class="errorText">
        Поле обязательно для заполнения
      </p>
    </label>

    <v-divider :class="$style.divider" />

    <template v-if="usefulVacationDocument.isRetailer">
      <p :class="$style.stepSubTitle">
        Сетевая компания, для которой загружаются 18 формы
      </p>
      <label :class="$style.label">
        <p :class="{[$style.label]: true, [$style.error]: usefulVacationDocument.Utility_error.error}">Выбрать сетевую компанию</p>
        <select v-model="usefulVacationDocument.UtilityInfo" :class="{[$style.select]: true, [$style.errorSelect]: usefulVacationDocument.Utility_error.error}" role="Utility">
          <option v-for="(utility, index) in utilities" :key="index" :value="utility">
            {{ utility.name }}
          </option>
        </select>
        <p v-if="usefulVacationDocument.Utility_error.error" class="errorText">
          Поле обязательно для заполнения
        </p>
      </label>

      <v-divider :class="$style.divider" />
    </template>

    <div style="display: flex; align-items: start; gap: 16px">
      <p :class="$style.stepSubTitle" style="margin: 0">Внесение данных из 18 форм</p>
      <div style="width: 100%">
        <v-date-picker
          v-model="accountingPeriod"
          :err="usefulVacationDocument.AccountingPeriod_error.error"
          format="YYYY-MM"
          label="Расчётный период"
          role="AccountingPeriod"
          type="month"
          is-disabled-future-dates
        />
        <p v-if="usefulVacationDocument.AccountingPeriod_error.error" class="errorText">
          Поле обязательно для заполнения
        </p>
      </div>
    </div>

    <div v-if="usefulVacationDocument.isDisplayDocumentScan || !usefulVacationDocument.isRetailer">
      <p :class="{[$style.error]: usefulVacationDocument.documentsScan_error.error}">Загрузите подтверждающий документ</p>
      <c-uploading
          v-model="usefulVacationDocument.documentsScan"
          :error="usefulVacationDocument.documentsScan_error"
          role="DocumentsScan"
      />
      <div v-if="usefulVacationDocument.documentsScan.length">
        <file-list-preview
          :list="usefulVacationDocument.documentsScan"
          action
          @delete="handleRemoveDocumentsScan"
        />
      </div>
    </div>

    <v-divider :class="$style.divider" />

    <p v-if="usefulVacationDocument.AccountingPeriod.length" :class="$style.stepSubTitle">Потребители:</p>
    <label v-if="usefulVacationDocument.AccountingPeriod.length" :class="$style.label">
      <p :class="{[$style.label]: true, [$style.error]: usefulVacationDocument.ConsumerType_error.error}">Тип потребителя</p>
      <select v-model="usefulVacationDocument.ConsumerType" :class="{[$style.select]: true, [$style.errorSelect]: usefulVacationDocument.ConsumerType_error.error}" role="ConsumerType">
        <option value="Company">
          Юридические лица
        </option>
        <option value="Individual">
          Физические лица
        </option>
      </select>
      <p v-if="usefulVacationDocument.ConsumerType_error.error" class="errorText">
        Поле обязательно для заполнения
      </p>
    </label>

    <template
        v-if="usefulVacationDocument.ConsumerType !== undefined && usefulVacationDocument.ConsumerType.length > 0">
      <v-divider :class="$style.divider" />

      <div style="position: relative">
        <p :class="$style.stepSubTitle">
          Шаблоны приложений
        </p>
        <p :class="$style.stepNote">
          Для загрузки приложений скачайте шаблоны документов в формате .XLSX (MS Exsel)
        </p>
      </div>

      <div :class="$style.fileAttachmentWrp">
        <span :class="$style.attachmentTitle">Шаблон 18 формы</span>
        <div :class="$style.attachment">
          <a :class="$style.fileAttachment" :download="attachmentName" :href="attachmentPath">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40" />
            <span>Шаблон {{ attachmentName }}</span>
          </a>
          <document-downloader
              :is-open-eye-show="false"
              :types="['XLSX']"
              @download="xlsDownload(attachmentPath, attachmentName)"
          />
        </div>
      </div>

      <v-divider :class="$style.divider" />

      <div :class="$style.titleUpload">
        <p :class="$style.stepSubTitle" :style="{'color': usefulVacationDocument.attachments.length === 0 ? 'red': ''}">
          Загрузка заполненных документов
        </p>
        <div :class="$style.status">
          <div :class="$style.statusCircle" :style="{backgroundColor: usefulVacationDocument.attachments.length === 0 ? '#EB5757' : ''}"></div>
          <div>Файлы <span v-if="usefulVacationDocument.attachments.length === 0">не </span>загружены</div>
        </div>
        <p :class="$style.stepNote">
          <b>Важно!</b> Скачайте и заполните шаблоны в точном соответствии с названием полей шаблона. Не оставляйте поля пустыми
        </p>
      </div>

      <div>
        <span :class="$style.labelUpload">
          Загрузите заполненные 18 формы
        </span>
        <c-uploading
            v-model="filledDocuments"
            :class="$style.filledDocuments"
            :error="usefulVacationDocument.attachments_error"
            accept=".xlsx, .xls"
            accept-title="XLSX, XLS или перетащите его в эту область."
            role="filledAttachment"
        />
      </div>

      <template v-if="usefulVacationDocument.attachments.length > 0">
        <v-divider :class="$style.divider" />

        <span :class="$style.attachmentTitle">18 формы</span>

        <div v-for="(attachment, index) in usefulVacationDocument.attachments" :key="index" :class="$style.attachment">
          <a :class="$style.fileAttachment" :download="attachment.attachmentFile.name"
             :href="fileUrl(attachment.attachmentFile)">
            <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
            <span>{{ attachment.attachmentFile.name }}</span>
          </a>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
             @click="deleteDoc1(attachment.id)"
          >
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24" />
          </a>
        </div>
      </template>
    </template>

    <dialog-template v-model="isWaitingSubmit" center>
      <p :class="$style.searchNote">Проводится анализ файлов. Подождите немного.</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
    </dialog-template>
  </div>
</template>

<script>
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files';
import { excelParseWithWorker, excelValidateWithWorker } from '@/utils/excel/index.js';
import { mapActions, mapState } from 'vuex';
import { VDatePicker, VDivider } from '@/components';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { normalizeDiacritics } from '@/utils/string/normalize';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';

import {
  eighteenFormExcelCompanyPatternV1,
  eighteenFormExcelCompanyPatternV2,
} from '../patterns/EighteenFormPatterns.js';

const attachmentNameCompanyWithoutExtension = '18 формы (юридические лица)';
const attachmentNameIndividualWithoutExtension = '18 формы (физические лица)';

export default {
  name: 'Additions',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    DialogTemplate: () => import('templates/DialogTemplate'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
    VDatePicker,
    FileListPreview: () => import('molecules/upload/form/FileListPreview.vue'),
    VDivider,
    DocumentDownloader,
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
    componentKey: Number,
  },
  async created() {
    const departments = await this.mesDepartments();
    const suppliers = await this.setSuppliers();
    this.departments = departments;
    this.utilities = suppliers.UTILITY.filter((it) => it.onPlatform && it);

    if (!this.usefulVacationDocument.UtilityInfo.inn.length > 0)
      this.usefulVacationDocument.UtilityInfo = this.utilities[0];
  },
  data() {
    return {
      filledDocuments: [],
      errorReport: undefined,
      isWaitingSubmit: false,
      isCompleted: false,
      utilities: [],
      attachmentNameCompanyWithoutExtension: attachmentNameCompanyWithoutExtension,
      attachmentNameCompany: `${attachmentNameCompanyWithoutExtension}.xlsx`,
      attachmentNameIndividualWithoutExtension: attachmentNameIndividualWithoutExtension,
      attachmentNameIndividual: `${attachmentNameIndividualWithoutExtension}.xlsx`,
      departments: [],
      validateExcelConfigIndividual: {
        rowNumberStart: 1,
        columns: [
          {
            columnNumber: 21,
            rule: {
              type: 'Date?',
            },
          },
          {
            columnNumber: 35,
            rule: {
              type: 'Date?',
            },
          },
          {
            columnNumber: 36,
            rule: {
              type: 'Date?',
            },
          },
          // ---
          {
            columnNumber: 39,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 40,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 41,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 42,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 43,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 44,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 45,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 46,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 47,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 48,
            rule: {
              type: 'Number',
            },
          },
          {
            columnNumber: 49,
            rule: {
              type: 'Number',
            },
          },
        ],
      },
    };
  },
  computed: {
    accountingPeriod: {
      get() {
        return this.usefulVacationDocument.AccountingPeriod;
      },
      async set(val) {
        if (dayjs(this.usefulVacationDocument?.AccountingPeriod, 'YYYY-MM').year() !== dayjs(val, 'YYYY-MM').year()) {
          this.usefulVacationDocument.attachments = [];
        }
        this.usefulVacationDocument.AccountingPeriod = val;
        if (val?.length === 0 || !val) {
          this.usefulVacationDocument.isDisplayDocumentScan = false;
          return;
        }
        this.usefulVacationDocument.isDisplayDocumentScan = await this.isNeedVerificationAct(
          {
            isExisting: false,
            sidesInns: ['7736520080'],
            accountingPeriod: {
              year: dayjs(val, 'YYYY-MM').year(),
              month: dayjs(val, 'YYYY-MM').month() + 1,
            },
            isEighteenthFormsOrUsefulVacation: true,
          },
        );
      },
    },
    attachmentPath() {
      if (this.usefulVacationDocument.ConsumerType === 'Company' && this.accountingPeriodYear < 2023) {
        return '/xlsx/useful-vacation-document/attachment_1_company.xlsx';
      }
      if (this.usefulVacationDocument.ConsumerType === 'Company' && this.accountingPeriodYear > 2022) {
        return '/xlsx/useful-vacation-document/attachment_1_company_new.xlsx';
      }
      return '/xlsx/useful-vacation-document/attachment_1_individual.xlsx';
    },
    attachmentName() {
      if (this.usefulVacationDocument.ConsumerType === 'Company') {
        return this.attachmentNameCompany;
      }
      return this.attachmentNameIndividual;
    },
    accountingPeriodYear() {
      return dayjs(this.usefulVacationDocument?.AccountingPeriod, 'YYYY-MM').year();
    },
    ...mapState('user', ['data']),
  },
  watch: {
    filledDocuments: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }

        this.isWaitingSubmit = true;
        const incorrectFiles = [];
        const resetFilledDocuments = () => {
          this.filledDocuments = [];
          this.isWaitingSubmit = false;
        };

        const checkErrorsAndShow = () => {
          if (incorrectFiles.length > 0) {
            let attachmentName = '';
            if (this.usefulVacationDocument.ConsumerType === 'Individual') {
              attachmentName = this.attachmentNameIndividual;
            } else {
              attachmentName = this.attachmentNameCompany;
            }
            this.vueShowNotification(
              '',
              `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${attachmentName}</p>`,
            );
          }
        };

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          let tableArray;

          const pattern = this.accountingPeriodYear > 2022 ? eighteenFormExcelCompanyPatternV2 : eighteenFormExcelCompanyPatternV1;

          if (this.usefulVacationDocument.ConsumerType === 'Company' && normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attachmentNameCompanyWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...pattern },
              taskId,
            );
            this.usefulVacationDocument.attachments.push({
              id: nanoid(),
              value: res.data,
              errors: res.errors,
              attachmentFile: file,
              attachmentError: [],
            });
            console.log('attachment', this.usefulVacationDocument.attachments);
            checkErrorsAndShow();
            resetFilledDocuments();
          } else if (this.usefulVacationDocument.ConsumerType === 'Individual' && normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attachmentNameIndividualWithoutExtension)) !== -1) {

            try {
              const res = await excelValidateWithWorker(
                file.code,
                this.validateExcelConfigIndividual,
                taskId,
              );

              if (res.taskId === taskId) {
                tableArray = res.data;
                console.log('res', res);
              } else {
                incorrectFiles.push(file.name);
                checkErrorsAndShow();
                resetFilledDocuments();
                continue;
              }
            } catch (error) {
              console.log('error XLSX.read', error);
              incorrectFiles.push(file.name);
              continue;
            }

            const title1 = tableArray.slice(0, 1)[0];

            const titlesHead = [];
            title1.forEach((it, index) => {
              const title = title1[index];

              titlesHead.push({
                col: index,
                title: title,
              });
            });

            this.usefulVacationDocument.attachments.push({
              id: nanoid(),
              attachment: tableArray.slice(1, tableArray.length),
              attachmentFile: file,
              attachmentError: [],
              titlesHead: titlesHead,
            });
            checkErrorsAndShow();
            resetFilledDocuments();
          } else {
            incorrectFiles.push(file.name);
          }
        }
      },
    },
  },
  methods: {
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deleteDoc1(id) {
      this.usefulVacationDocument.attachments =
        this.usefulVacationDocument.attachments.filter((it) => it.id !== id);
    },
    handleRemoveDocumentsScan(file) {
      this.usefulVacationDocument.documentsScan = this.usefulVacationDocument.documentsScan
        .filter((scan) => scan.source !== file.source);
    },
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    ...mapActions('cabinet', ['setContractType']),
    ...mapActions('dataLists', ['mesDepartments', 'setSuppliers']),
    ...mapActions('document', ['isNeedVerificationAct']),
  },
};
</script>

<style lang="sass" module>
.content
  width: 848px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 24px auto 0

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51

  .stepSubTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 18px
    white-space: nowrap
    color: #4C4E51

  .label
    font-size: 16px
    color: #71757A

  .titleUpload
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    flex-wrap: wrap

  .labelUpload
    font-size: 16px
    color: #4C4E51

  .stepNote
    font-family: 'Roboto Condensed', sans-serif
    font-size: 12px
    font-weight: 500
    line-height: 18px
    width: 320px
    border-radius: 16px
    background-color: #F5F6F6
    position: absolute
    right: -363px
    padding: 8px 24px
    top: 0

    @media (max-width: 1650px)
      position: relative
      top: 0
      right: 0
      width: 100%
      margin-top: 16px

  .status
    display: flex
    align-items: center
    border-radius: 16px
    border: 1px solid #C1C4C7
    padding: 8px 14px

    .statusCircle
      min-width: 8px
      min-height: 8px
      border-radius: 24px
      margin-right: 8px
      background-color: #4CCB40

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    border-color: #C1C4C7

  .divider
    width: 864px
    margin-left: -8px

.content, .viewWidth
  table
    tr
      th
        width: 1%
        white-space: nowrap

      th.header
        text-align: center

      td, th
        border: 1px solid #0E0F0F

        input
          height: 100%
          width: 100%
          border: 0
          font-size: 16px
          margin: 0

      td
        p
          width: 100%
          text-align: center

      td.error
        input
          border: 1px solid red

        text-align: left

        p
          margin: 0

      th, td.text
        padding: 18px

    thead
      td
        font-weight: bold
        text-align: center

.viewWidth
  width: 100%
  margin-top: 64px

  table
    width: 98%

  p
    text-align: center

  table, p
    margin: 0 auto 32px

.fileAttachmentWrp
  display: flex
  flex-direction: column
  gap: 16px

.attachmentTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51

.attachment, .fileArchive, .attachmentReport
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  text-align: left
  color: #4C4E51


.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.errorSelect
  border-color: red !important

.error
  color: red !important
</style>
