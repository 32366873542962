import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  isDisplayDetails: false,
  componentDetails: '',
  taskDetails: {},
  popUpDetails: '',
  popUpDetailsProps: {},
  isDisplayPopUpDetails: false,
  loading: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
