<template>
  <modal
      :body-style="{
        padding: '24px 0',
      }"
      :header-style="{
        display: 'flex',
        justifyContent: 'center',
      }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
        borderRadius: '16px',
        padding: '50px 40px',
      }"
      class="hierarchy-permissions"
  >
    <template #header>
      <span class="hierarchy-permissions__title">Полномочия</span>
    </template>
    <template #body>
      <div class="hierarchy-permissions__body">
        <v-input
            v-model="innerRole.name"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            rules="required"
            required
            :disabled="isEditableRoleName"
            clearable
            title="Должность"
        />
        <v-select
            v-model="selectedBlock"
            :options="blocks"
            label="Функциональный блок"
        />
        <div v-if="selectedBlock && getAllowedRoutesByCode(selectedBlock).length !== 0"
             class="hierarchy-permissions__allowedRoutesWrp"
        >
          <div class="hierarchy-permissions__allowedRoutesWrp-allowedRoutes">
            Пункты меню, доступные при выдаче прав из функционального блока
          </div>
          <div v-for="(route, index) in getAllowedRoutesByCode(selectedBlock)" :key="index">
            {{ route }}
          </div>
        </div>
        <table>
          <thead>
          <tr>
            <td style="min-width: 40px">№</td>
            <td>Код</td>
            <td>Описание</td>
            <td style="min-width: 66px">Разрешено</td>
          </tr>
          </thead>
          <tbody v-if="innerRole.newPermissions" class="hierarchy-permissions__table">
          <tr
            v-for="(permission, permissionIndex) in innerRole.newPermissions.filter((newPermission) => newPermission.code.includes(`${selectedBlock}-`))"
            :key="permission.code"
          >
            <td>{{ permissionIndex + 1 }}</td>
            <td>{{ permission.code }}</td>
            <td>{{ permission.description }}</td>
            <td class="hierarchy-permissions__table--center">
              <input
                  v-model="permission.value"
                  :disabled="isDisabled"
                  type="checkbox"
                  @change="changePermissions()"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template #footer>
      <div class="hierarchy-permissions__controls">
        <v-button type="button" variant="outlined-red" @click="onClose">
          <span>Отмена</span>
        </v-button>
        <v-button type="button" :disabled="innerRole.name !== role.name || isUpdatedPermissions || !innerRole.name" @click="onSave">
          <span v-if="innerRole.id || innerRole.index !== undefined">Сохранить</span>
          <span v-else>Добавить</span>
        </v-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  Modal,
  VButton,
  VSelect,
  VInput,
} from '@/components';
import { checkField, getAllVueRoutes } from 'utils/common';
import { isAccess } from 'lib/utils/permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'Permissions',
  components: {
    Modal,
    VButton,
    VSelect,
    VInput,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
    blocks: {
      type: Array,
      required: true,
    },
    isEditableRoleName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerRole: {},
      isUpdatedPermissions: false,
      selectedBlock: this.blocks[0]?.value,
    };
  },
  computed: {
    isDisabled() {
      return this.innerRole.isCompanyOwner;
    },
    ...mapGetters('user', ['getUser', 'getAccount']),
  },
  watch: {
    role: {
      immediate: true,
      deep: true,
      handler(val) {
        this.innerRole = val;
      },
    },
  },
  methods: {
    getAllowedRoutesByCode(code) {
      const allRoutes = getAllVueRoutes(this.$router.options.routes);
      const routesByPermission = isAccess([{ code }], this.getAccount?.kind);
      const allowedRoutes = [];

      for (const prop in routesByPermission) {
        // eslint-disable-next-line no-prototype-builtins
        if (routesByPermission.hasOwnProperty(prop) && routesByPermission[prop]) {
          const allowedRoute = allRoutes.find((route) => route.path === prop);
          allowedRoutes.push(checkField(allowedRoute?.meta?.title));
        }
      }

      return allowedRoutes;
    },
    comparePermission(permission, permissionIndex) {
      return permission.value !==
          this.innerRole.newPermissions[permissionIndex]
              .value;
    },
    changePermissions() {
      this.innerRole.newPermissions.every((permission, permissionIndex) => {
        if (
            this.comparePermission(permission, permissionIndex)
        ) {
          this.isUpdatedPermissions = true;
          return false;
        }
          this.isUpdatedPermissions = false;
          return true;

      });
    },
    onClose() {
      this.$emit('close');
    },
    onSave() {
      this.$emit('save', this.innerRole);
    },
  },
};
</script>

<style lang="scss" scoped>
.hierarchy-permissions {
  font-family: 'Roboto Condensed';

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__allowedRoutesWrp {
    &-allowedRoutes {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  &__table {
    tr {
      border-top: 1px solid gray;
    }
    td {
      padding: 4px 16px 4px 0;
      color: gray;
      min-width: 40px;
    }
    &--center {
      text-align: center
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

}

</style>
