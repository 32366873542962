/* eslint-disable */
import formatting from 'lib/utils/formatting';
import { formatDateToXmlDateFormat } from 'lib/utils/date';

export function getValueByLabel(label, list) {
  const result = list.find((item) => item.label === label);

  if (result === undefined) {
    return '';
  }

  return result.value;
}

export function getCompletedTag(tagName, value, indent, namespace) {
  if (!value || value.trim().length === 0) {
    return '';
  }

  const innerIndent = indent ?? '';

  return `${innerIndent}<${tagName}${namespace ? ` xmlns="${namespace}"` : ''}>${value}</${tagName}>\n`;
}

export function getCompletedDateTag(tagName, value, indent, namespace) {
  if (!value || value.trim().length === 0) {
    return '';
  }
  let formatted;
  try {
    formatted = formatDateToXmlDateFormat(value);
  } catch (e) {
    return '';
  }
  return getCompletedTag(tagName, formatted, indent, namespace);
}

export function getCompletedTagByLabel(tagName, value, labels, indent, namespace) {
  if (!value || value.trim().length === 0) {
    return '';
  }
  const formatted = getValueByLabel(value, labels);
  return getCompletedTag(tagName, formatted, indent, namespace);
}

export function encodeXmlCharacters(unsafe) {
  return unsafe.replace(/[<>&'"]/g, function (c) {
    switch (c) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '&': return '&amp;';
      case '\'': return '&apos;';
      case '"': return '&quot;';
    }
  });
}

export function getContractType(data) {
  if (data.contractExisting === 'ADDITIONAL') {
    return 'Дополнительное соглашение к договору';
  }
    return 'Договор';

}

export function getContractLifeCycleStatus(data) {
  if (data.contractExisting === 'NEW') {
    return 'Новый';
  }
    return 'Действующий';

}

export function getCompanyInfo(company) {
  return (
    `${`        <Name>${company.Name}</Name>\n` +
    `        <INN>${company.INN}</INN>\n` +
    `        <KPP>${company.KPP}</KPP>\n` +
    `        <OGRN>${company.OGRN}</OGRN>\n` +
    `        <BIK>${company.bankRequisites[0]?.BIK}</BIK>\n` +
    `        <SettlementAccount>${company.bankRequisites[0]?.SettlementAccount}</SettlementAccount>\n` +
    `        <BankName>${company.bankRequisites[0]?.BankName}</BankName>\n` +
    `        <CorrespondentAccount>${company.bankRequisites[0]?.CorrespondentAccount}</CorrespondentAccount>\n` +
    `        <OKTMO>${company.OKTMO}</OKTMO>\n` +
    `        <OKPO>${company.OKPO}</OKPO>\n` +
    `        <OKVED>${company.OKVED}</OKVED>\n` +
    `        <PhoneNumber>${formatting.unformatPhonePrefix(
      company.PhoneNumber,
    )}</PhoneNumber>\n` +
    `     <LegalAddress>${company.LegalAddress}</LegalAddress>\n` +
    `     <PostAddress>${company.PostAddress}</PostAddress>\n` +
    `     ${getCompletedTag('AccountId', company.Representative.AccountId)}` +
    '      <Representative>\n' +
    '          <Name case="Nominative">\n' +
    `              <FamilyName>${company.Representative.Name.FamilyName}</FamilyName>\n` +
    `              <FirstName>${company.Representative.Name.FirstName}</FirstName>\n` +
    `              <Patronymic>${company.Representative.Name.Patronymic}</Patronymic>\n` +
    '          </Name>\n' +
    `          <Position case="Nominative">${company.Representative.Position}</Position>\n` +
    `          ${getCompletedTag('RoleId', company.Representative.RoleId)}` +
    `          ${getCompletedTag('UserId', company.Representative.UserId)}` +
    '          <Reason>\n' +
    `             ${getCompletedTag('ID', company.Representative.Reason.Id)}`}${
    company.Representative.Reason.ReasonDocument.nominative
      ? `<ReasonDocument case="Nominative">${company.Representative.Reason.ReasonDocument.nominative}</ReasonDocument>\n` +
      `                <ReasonDocument case="Genitive">${company.Representative.Reason.ReasonDocument.genitive}</ReasonDocument>\n`
      : `<ReasonDocument case="Nominative">${company.Representative.Reason.ReasonDocument}</ReasonDocument>\n`
    }                ${getCompletedTag('Number', company.Representative.Reason.Number)}` +
    `                ${getCompletedTag('Date', formatDateToXmlDateFormat(company.Representative.Reason.Date))}` +
    '            </Reason>\n' +
    '        </Representative>\n'
  );
}

export function executorsByName(executorName) {
  const executors = {
    SUPPLIER: 'Supplier',
    PERFORMER: 'Performer',
    PERFORMER_FIRST: 'PerformerFirst',
    PERFORMER_SECOND: 'PerformerSecond',
  };
  return executors[executorName];
}

export function getCompanyInfoContract(company, signatory, executorName, indent) {
  const newIndent = `${indent ?? ''}    `;
  return (
    `${indent ?? ''}<DocumentSide xmlns="http://energochain.ru/XML/documents/company-common/1.0">\n` +
    `${getCompletedTag('OrganisationName', company.name, newIndent)}` +
    `${getCompletedTag('ExecutorName', executorsByName(executorName), newIndent)}` +
    `${getCompletedTag('INN', company.inn, newIndent)}` +
    `${getCompletedTag('KPP', company.kpp, newIndent)}` +
    `${getCompletedTag('OGRN', company.ogrn, newIndent)}` +
    `${getCompletedTag('BIK', company.selectedBankRequisites?.bik, newIndent)}` +
    `${getCompletedTag('SettlementAccount', company.selectedBankRequisites?.settlementAccount, newIndent)}` +
    `${getCompletedTag('BankName', company.selectedBankRequisites?.bankName, newIndent)}` +
    `${getCompletedTag('CorrespondentAccount', company.selectedBankRequisites?.correspondentAccount, newIndent)}` +
    `${getCompletedTag('OKTMO', company.oktmo, newIndent)}` +
    `${getCompletedTag('OKPO', company.okpo, newIndent)}` +
    `${getCompletedTag('OKVED', company.okved, newIndent)}` +
    `${getCompletedTag('LegalAddress', company.legalAddress, newIndent)}` +
    `${getCompletedTag('PostAddress', company.postAddress, newIndent)}` +
    `${getCompletedTag('AccountId', signatory.accountId, newIndent)}` +
    `${getRepresentative(signatory, newIndent)}` +
    `${indent ?? ''}</DocumentSide>\n`
  );
}

function getRepresentative(signatory, indent) {
  const newIndent = `${indent ?? ''}    `;
  const newIndent2 = `${indent ?? ''}        `;

  const name = () => {
    return (
      `${newIndent}<Name case="Nominative">\n` +
      `${getCompletedTag('FamilyName', signatory.fullName.surname, newIndent2)}` +
      `${getCompletedTag('FirstName', signatory.fullName.name, newIndent2)}` +
      `${getCompletedTag('Patronymic', signatory.fullName.patronymic, newIndent2)}` +
      `${newIndent}</Name>\n`
    );
  }

  const reason = () => {
    return (
      `${newIndent}<Reason>\n` +
      `${getCompletedTag('ID', signatory.reason.id, newIndent2)}` +
      `${newIndent2}<ReasonDocument case="Nominative">${signatory.reason.reasonDocument}</ReasonDocument>\n` +
      `${getCompletedTag('Number', signatory.reason.number, newIndent2)}` +
      `${getCompletedTag('Date', formatDateToXmlDateFormat(signatory.reason.date), newIndent2)}` +
      `${newIndent}</Reason>\n`
    );
  }

  return (
    `${indent ?? ''}<Representative>\n` +
    `${name()}` +
    `${newIndent}<Position case="Nominative">${signatory.role.name}</Position>\n` +
    `${getCompletedTag('RoleId', signatory.role.id, newIndent)}` +
    `${getCompletedTag('UserId', signatory.userId, newIndent)}` +
    `${reason()}` +
    `${indent ?? ''}</Representative>\n`
  );
}

export function representativeVerificationFromContract(contract) {
  const sides = ['Supplier', 'PerformerFirst', 'PerformerSecond'];

  let sidesXml = '';

  sides.forEach((side) => {
    sidesXml += `
        <${side}>
            <Name>
                <FamilyName>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Name.FamilyName}</FamilyName>
                <FirstName>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Name.FirstName}</FirstName>
                <Patronymic>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Name.FirstName}</Patronymic>
            </Name>
            <Position>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Position}</Position>
            <Reason>
                <ReasonDocument>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Reason.ReasonDocument}</ReasonDocument>
                <Number>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Reason.Number}</Number>
                <Date>${contract.electricityTransmissionContract[side].RepresentativeVerificationAct.Reason.Date}</Date>
            </Reason>
        </${side}> \n`;
  });

  const actVerificationSignatoriesXml = `
  <ActVerificationSignatories>
    ${sidesXml}
  </ActVerificationSignatories>
  `;

  return actVerificationSignatoriesXml;
}

export function getIdentityDocType(identityDoc, indent) {
  let type = '';
  let otherTypeName = '';

  if (!identityDoc || !identityDoc.series || identityDoc.series.length === 0) {
    return '';
  }

  const innerIndent = indent ?? '';

  switch (identityDoc.documentType) {
    case 'PASSPORT_RF': {
      type = 'Passport';
      break;
    }
    case 'RESIDENCE_PERMIT_RF': {
      type = 'ResidencePermit';
      break;
    }
    case 'BIRTH_CERTIFICATE': {
      type = 'BirthCertificate';
      break;
    }
    case 'OTHER': {
      type = 'Other';
      otherTypeName = `${innerIndent}    <Name>${identityDoc.otherName}</Name>\n`;
      break;
    }
  }

  return `${`${innerIndent}<IdentityDoc>\n` +
      `${innerIndent}  <${type}>\n`}${
      otherTypeName
      }${innerIndent}    <Series>${identityDoc.series}</Series>\n` +
      `${innerIndent}    <Number>${identityDoc.number}</Number>\n` +
      `${innerIndent}    <Issuer>${identityDoc.issuerName}</Issuer>\n` +
      `${innerIndent}    <IssueDate>${formatDateToXmlDateFormat(identityDoc.issueDate)}</IssueDate>\n` +
      `${innerIndent}    ${getCompletedTag('SubdivisionCode', identityDoc.issuerCode)}\n` +
      `${innerIndent}  </${type}>\n` +
      `${innerIndent}</IdentityDoc>\n`;
}
