<template>
    <div>
        <div class="container">
            <HelpNav />
            <div class="content-container">
                <div class="content">
                    <img src="../../../../assets/images/help/bg-content-3.png" alt="rectangle" width="234" height="82">
                    <div class="content-text">
                        <p class="title">О компании</p>
                        <div class="content-text">
                            <p class="standart-text">
                                «Объединенная энергетическая компания» – одна из крупнейших электросетевых Компаний Москвы, занимающаяся развитием, эксплуатацией и реконструкцией принадлежащих городу электрических сетей. АО «ОЭК» обеспечивает передачу и распределение электроэнергии, осуществляет технологическое присоединение потребителей, ведет строительство новых сетей. Имущественный комплекс АО «ОЭК» включает 28 питающих центров напряжением 110-220 кВ (установленная суммарная мощность 8630 МВА), 3 567 трансформаторных подстанций 6-20/0,4 кВ, распределительных и соединительных пунктов 6-20 кВ, более 48 000 км линий электропередачи классом напряжения 0,4-220 кВ.С 1 января 2015 года АО «ОЭК» осуществляет эксплуатацию объектов наружного освещения, архитектурно-художественной подсветки, праздничной иллюминации и часового хозяйства, расположенных на территории города Москвы.
                                <br>
                                <br>
                                Своей основной миссией мы считаем качественное и надёжное снабжение потребителей электроэнергией, а также удовлетворение возрастающего спроса на электрическую энергию в Москве. Строительство энергообъектов и развитие сетей, происходящие сегодня, позволяют Компании использовать современные технологии и эффективные наработки, тем самым формируя новые стандарты качества в электроснабжении потребителей столицы.
                                <br>
                                <br>
                                В своей повседневной деятельности мы руководствуемся следующими принципами:
                            </p>
                            <div class="question">
                                <p class="second-title">Ответственность</p>
                            </div>
                            <div class="question">
                                <p class="standart-text">Мы полностью осознаём, что во многом на нас лежит ответственность за нормальное функционирование городского хозяйства и жизнедеятельность миллионов людей. Эта ответственность является ключевым стимулом эффективной работы «ОЭК».</p>
                            </div>
                            <div class="question">
                                <p class="second-title">Эффективность</p>
                            </div>
                            <div class="question">
                                <p class="standart-text">Мы считаем своим долгом добиваться эффективного отпуска электрической энергии и рационально использовать инвестиции. Важной задачей для нас является поддержание высокого уровня операционной эффективности. Это составляет основу нашей деятельности.</p>
                            </div>
                            <div class="question">
                                <p class="second-title">Качество</p>
                            </div>
                            <div class="question">
                                <p class="standart-text">Мы убеждены, что потребители должны получать качественные услуги. Это относится не только к качеству электроэнергии, которую мы поставляем, но и к выстраиванию взаимодействия с клиентами. Именно поэтому мы инвестируем значительные средства в развитие инфраструктуры и новые технологии, привлекаем самые квалифицированные кадры, обучаем и мотивируем персонал.</p>
                            </div>
                        </div>
                    </div>
                    <!-- /.content-text -->
                </div>
                <!-- /.content -->
        </div>
        <!-- /.content-container -->
    </div>
    <!-- /.container -->
    </div>
</template>

<script>
import HelpNav from 'views/public/help/components/HelpNav.vue';

export default {
    components: {
        HelpNav,
    },
};
</script>

<style scoped>
    .container{
        font-family: 'Roboto Condensed', sans-serif;
        max-width: 1200px;
        margin:0 auto;
        display: flex;
        overflow-x: auto;
    }

        /* Content */

    .content-container{
        display: flex;
        margin-left: 530px;
        margin-bottom: 24px;
    }

    .bg-content{
        width: 491px;
        height: 325px;
    }

    .content{
        margin-left: 80px;
        margin-top: 40px;
    }

    .content-text{
        margin-top: 40px;
    }

    .title{
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.005em;
        text-transform: uppercase;
    }

    .question{
        margin-top: 24px;
    }

    .second-title{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #0E0F0F;
    }

    .standart-text{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #0E0F0F;
        max-width: 465px;
    }

    .link{
        color: #0E0F0F;
    }
</style>
