<template>
  <div class="suppliers-info">
    <h2>Сведения о сторонах:</h2>

    <div class="suppliers-info__supplier">
      <v-input
        v-model="supplierName"
        :disabled="!isEditing"
        :title="supplierTitle"
        class="suppliers-info__name"
        clearable
        rules="required"
      />

      <img alt="Подробнее" src="~@/assets/icons/navigation/follow-arrow.svg" @click="openUtility"/>
    </div>

    <div v-if="!isTechnicalPoint" class="suppliers-info__supplier">
      <v-input
        v-model="consumerName"
        :disabled="!isEditing"
        :title="consumerTitle"
        class="suppliers-info__name"
        clearable
        rules="required"
      />

      <img alt="Подробнее" src="~@/assets/icons/navigation/follow-arrow.svg" @click="openUtility"/>
    </div>

    <div v-if="internalValue.retail && isDeliveryPoint" class="suppliers-info__supplier">
      <v-input
        v-model="internalValue.retail.name"
        :disabled="!isEditing"
        class="suppliers-info__name"
        clearable
        rules="required"
        title="Наименование сбытовой организации *"
      />

      <img alt="Подробнее" src="~@/assets/icons/navigation/follow-arrow.svg" @click="openRetailer"/>
    </div>

    <div v-if="internalValue?.retailDepartment?.name" class="suppliers-info__supplier">
      <v-input
        v-model="internalValue.retailDepartment.name"
        :disabled="!isEditing"
        class="suppliers-info__name"
        clearable
        title="Отделение"
      />
    </div>

    <v-divider/>
  </div>
</template>

<script>

import { VDivider, VInput } from '@/components';
import { merge } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'SuppliersInfo',
  components: {
    VInput,
    VDivider,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        supplier: {
          name: '',
        },
        retailer: {
          name: '',
        },
      },
      internalValue: {},
    };
  },
  computed: {
    isTechnicalPoint() {
      return this.internalValue.additionKind === 'TECHNICAL_POINT';
    },
    isTransferPoint() {
      return this.internalValue.additionKind === 'TRANSFER_POINT';
    },
    isReceivingPoint() {
      return this.internalValue.additionKind === 'RECEIVING_POINT';
    },
    isDeliveryPoint() {
      return this.internalValue.additionKind === 'DELIVERY_POINT';
    },
    supplierTitle() {
      return this.isDeliveryPoint ? 'Наименование сетевой организации *' : 'Из сетей';
    },
    consumerTitle() {
      return this.isDeliveryPoint ? 'Наименование потребителя *' : 'В сети';
    },
    supplierName() {
      if (!this.isReceivingPoint) return this.getAccount.profile.name;
      return this.internalValue.supplier.name;
    },
    consumerName() {
      if (this.isReceivingPoint || this.isTechnicalPoint) return this.getAccount.profile.name;
      if (this.isTransferPoint) return this.internalValue.supplier.name;
      return this.internalValue.consumer?.consumerName;
    },
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue(previous, current) {
      if (previous !== current)
        this.$emit('input', this.internalValue);
    },
  },
  methods: {
    openUtility() {
      this.$router.push('/cabinet/utilities');
    },
    openRetailer() {
      this.$router.push('/cabinet/retailers');
    },
  },
};

</script>

<style lang="scss" scoped>
.suppliers-info {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
    margin-bottom: 0;
  }

  &__supplier {
    display: flex;

    img {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__name {
    width: 100%;
    padding-right: 24px;
  }
}

</style>
