<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">{{ getPatternName }}</p>
    <p :class="$style.smallTitle" style="padding: 0 9px">Внесение данных из приложений к {{ contractType }}</p>

    <v-divider/>

    <div style="display: flex; justify-content: space-between; padding: 0 9px">
      <p :class="$style.subTitle" style="margin: auto 0">Шаблоны приложений</p>
      <button :class="{[$style.button]: true, [$style.grey]: true}"
              @click.prevent="isDisplayCloseAll = !isDisplayCloseAll">
          <span :class="$style.inner">
            <img v-show="isDisplayCloseAll" alt="свернуть" src="@/assets/icons/arrows/arrow-up.svg">
            <img v-show="!isDisplayCloseAll" alt="развернуть" src="@/assets/icons/arrows/arrow-down.svg">
            <span :class="$style.text">
              <span v-show="isDisplayCloseAll">Свернуть</span><span v-show="!isDisplayCloseAll">Развернуть</span>&nbsp;всё
            </span>
          </span>
      </button>
    </div>

    <div v-show="isDisplayCloseAll" :class="$style.attachmentsList">
      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 1</span>
          <a :class="$style.fileAttachment" :download="attachment1Name" :href="`${attachmentPath}attachment_1.xlsm`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment1Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSM']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_1.xlsm`, attachment1Name)"
        />
      </div>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 1.1</span>
          <a :class="$style.fileAttachment" :download="attachment11Name" :href="`${attachmentPath}attachment_1_1.xlsm`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment11Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSM']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_1_1.xlsm`, attachment11Name)"
        />
      </div>

      <v-divider/>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 2</span>
          <a :class="$style.fileAttachment" :download="attachment2Name" :href="`${attachmentPath}attachment_2.xlsm`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment2Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSM']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_2.xlsm`, attachment2Name)"
        />
      </div>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 3</span>
          <a :class="$style.fileAttachment" :download="attachment4Name" :href="`${attachmentPath}attachment_4.xlsm`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment4Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSM']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_4.xlsm`, attachment4Name)"
        />
      </div>

      <v-divider/>

      <div :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span :class="$style.attachmentTitle">Шаблон Приложения 3.1</span>
          <a :class="$style.fileAttachment" :download="attachment31Name" :href="`${attachmentPath}attachment_3_1.xlsm`">
            <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
            <span>{{ attachment31Name }}</span>
          </a>
        </div>
        <document-downloader
          :is-open-eye-show="false"
          :types="['XLSM']" style="margin-top: 24px;"
          @download="xlsDownload(`${attachmentPath}attachment_3_1.xlsm`, attachment31Name)"
        />
      </div>
    </div>

    <v-divider/>

    <p :class="$style.subTitle" style="padding: 0 9px">Точки поставки, передачи и потребителей ЭСО</p>

    <div style="padding: 0 9px">
      <p
        :class="$style.uploadingTitle"
        :style="{'color': getErrors.filledAttachment1.isError ? 'red': ''}"
      >
        Загрузка Приложений: Приложения 1, 1.1, 3.1
      </p>
      <c-uploading
        v-model="filledAttachment1"
        :class="$style.filledDocuments"
        :error="{ error: getErrors.filledAttachment1.isError}"
        accept=".xlsx, .xls, .xlsm"
        accept-title="XLSX, XLS, XLSM или перетащите его в эту область."
        role="filledAttachment1"
      />
    </div>

    <v-divider/>

    <div v-if="getContract.attachments.length > 0" :class="$style.attachmentsList">
      <div v-for="(attachment, index) in nonDepartmentApplications" :key="index" :class="$style.attachment">
        <div :class="$style.fileAttachmentWrp">
          <span>{{ formattedFileName(attachment.file.name) }}</span>
          <a :class="$style.fileAttachment" :download="attachment.file.name"
             :href="fileUrl(attachment.file)">
            <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
            <span>{{ attachment.file.name }}</span>
          </a>
        </div>
        <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
           @click="deleteDoc(attachment)"
        >
          <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
        </a>
      </div>
    </div>

    <v-divider v-if="getContract.attachments.length > 0" style="border-color: #2F82DF"/>

    <div v-for="(department, indexDepartment) in departmentsId" :key="indexDepartment">
      <p :class="$style.subTitle" style="padding: 0 9px">
        <span v-if="departmentsId.length > 1">{{ indexDepartment + 1 }}. </span>
        Точки поставки по отделениям {{ getContract.editableSides.SUPPLIER.company.name }}
      </p>
      <div :class="$style.attachment" style="padding: 0 9px">
        <p :class="$style.smallTitle" style="margin: 16px 0">Отделение: {{ departments[department.departmentId].name }}</p>
        <div style="display: flex; gap: 16px; padding: 0 9px">
          <p :class="$style.deleteText">Удалить отделение</p>
          <a :class="$style.deleteIcon" href="#" style="margin: auto 0" title="удалить"
             @click="deleteDepartment(department.departmentId)">
            <img alt="удалить" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>
      </div>

      <div style="padding: 0 9px; margin-bottom: 24px">
        <p
          :class="$style.uploadingTitle"
          :style="{'color': getErrors.filledDocuments.isError && getErrors.filledDocuments.role?.indexOf(department) !== -1 ? 'red': ''}"
        >
          Загрузка Приложений: Приложения 2, 3
        </p>
        <c-uploading
          v-model="filledDocuments[department.departmentId]"
          :class="$style.filledDocuments"
          :error="{ error: getErrors.filledDocuments.isError && getErrors.filledDocuments.role?.indexOf(department.departmentId) !== -1 }"
          :role="'filledDocuments_' + department.departmentId"
          accept=".xlsx, .xls, .xlsm"
          accept-title="XLSX, XLS, XLSM или перетащите его в эту область."
        />
      </div>

      <div :class="$style.attachmentsList" style="margin-bottom: 24px">
        <div v-if="departments[department.departmentId]['ElectricityConsumerAddition']?.file" :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <span>{{ formattedFileName(departments[department.departmentId]['ElectricityConsumerAddition']?.file.name) }}</span>
            <a :class="$style.fileAttachment"
               :download="departments[department.departmentId]['ElectricityConsumerAddition']?.file.name"
               :href="fileUrl(departments[department.departmentId]['ElectricityConsumerAddition']?.file)">
              <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
              <span>{{ departments[department.departmentId]['ElectricityConsumerAddition']?.file.name }}</span>
            </a>
          </div>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
             @click="deleteDoc2(department.departmentId, true)">
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>

        <div v-if="departments[department.departmentId]['ElectricitySupplyPointAddition']?.file" :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <span>{{ formattedFileName(departments[department.departmentId]['ElectricitySupplyPointAddition']?.file.name) }}</span>
            <a :class="$style.fileAttachment"
               :download="departments[department.departmentId]['ElectricitySupplyPointAddition']?.file.name"
               :href="fileUrl(departments[department.departmentId]['ElectricitySupplyPointAddition']?.file)">
              <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
              <span>{{ departments[department.departmentId]['ElectricitySupplyPointAddition']?.file.name }}</span>
            </a>
          </div>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
             @click="deleteDoc4(department.departmentId, true)">
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>
      </div>

      <v-divider/>
    </div>

    <div v-if="isAddDepartment" :class="$style.attachmentsList">
      <div :class="$style.attachment">
        <p :class="$style.subTitle" style="margin: auto 0">
          Точки поставки по отделениям {{ getContract.editableSides.SUPPLIER.company.name }}
        </p>
        <a :class="$style.deleteIcon" href="#" title="удалить"
           @click="isAddDepartment = false">
          <img alt="удалить" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
        </a>
      </div>

      <select v-model="addedDepartment" :class="$style.select">
        <option disabled>Выберите отделение</option>
        <template v-for="(depList, indexDepList) in departmentList">
          <option :key="indexDepList" :hidden="isHidden(depList.id)" :value="depList">
            {{ depList.name }}
          </option>
        </template>
      </select>
    </div>

    <v-divider v-if="isAddDepartment"/>

    <select-button :display-icon="false" :items="[{title: 'Добавить отделение', method: addDepartment, }]" type="add"/>
    <dialog-template v-model="visible" center>
      <p :class="$style.searchNote">Загрузка файлов. Подождите немного.</p>
      <div :class="$style.modalContainer">
        <progress-bar
          :fake-time-ms="25"
          :is-complete="true"
          text="Загрузка"
          @is-hundred-percent="downloadFinished"
        />
      </div>
    </dialog-template>

  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { getFileUrl } from 'lib/utils/files';
import { EVENT_BUS } from '@/eventBus';
import { excelParseWithWorker } from 'utils/excel';
import { nanoid } from 'nanoid';
import { mapActions, mapGetters } from 'vuex';
import { normalizeDiacritics } from 'utils/string/normalize';
import {
  electricityConsumersAdditionExcelPattern,
  electricitySupplyPointAdditionAdditionExcelPattern,
  electricityUsagePointAdditionInputExcelPattern,
  electricityUsagePointAdditionOutputExcelPattern,
  electricityUsagePointForRelatedAdditionExcelPattern,
} from 'views/private/UploadDocument/patterns/ThreeSidePatterns';
import VDivider from 'components/VDivider/VDivider.vue';
import DocumentDownloader from 'components/DocumentDownloader/DocumentDownloader.vue';
import SelectButton from 'components/SelectButton/SelectButton.vue';

const attachment1NameWithoutExtension = 'Приложение_1-Точки приёма электрической энергии в сеть Исполнителя-2';
const attachment11NameWithoutExtension = 'Приложение_1.1-Точки передачи электрической энергии из сети Исполнителя-2';
const attachment31NameWithoutExtension = 'Приложение_3.1-Точки поставки электрической энергии смежным субъектам оптового или розничного рынков';
const attachment2NameWithoutExtension = 'Приложение_2-Реестр договоров энергоснабжения с Потребителями';
const attachment4NameWithoutExtension = 'Приложение_3-Точки поставки электрической энергии Потребителей';

export default {
  name: 'ThreeSide',
  components: {
    ProgressBar,
    SelectButton,
    DocumentDownloader,
    VDivider,
    CUploading: () => import('atoms/common/CUploading.vue'),
    DialogTemplate: () => import('templates/DialogTemplate.vue'),
  },
  async created() {
    await this.completeDepartments();
  },
  data() {
    return {
      filledDocuments: {},
      visible: false,
      filledAttachment1: [],
      isWaitingSubmit: false,
      departmentId: -1,
      departmentList: [],
      attachment1NameWithoutExtension: attachment1NameWithoutExtension,
      attachment1Name: `${attachment1NameWithoutExtension}.xlsm`,
      attachment11NameWithoutExtension: attachment11NameWithoutExtension,
      attachment11Name: `${attachment11NameWithoutExtension}.xlsm`,
      attachment31NameWithoutExtension: attachment31NameWithoutExtension,
      attachment31Name: `${attachment31NameWithoutExtension}.xlsm`,
      attachment2NameWithoutExtension: attachment2NameWithoutExtension,
      attachment2Name: `${attachment2NameWithoutExtension}.xlsm`,
      attachment4NameWithoutExtension: attachment4NameWithoutExtension,
      attachment4Name: `${attachment4NameWithoutExtension}.xlsm`,
      addedDepartment: {},
      departmentSortIndex: undefined,
      isAddDepartment: false,
      isDisplayCloseAll: true,
    };
  },
  computed: {
    availableOptions() {
      return this.departmentList;
    },
    isHidden() {
      return (id) => this.departments[id];
    },
    isDisabled() {
      return !this.availableOptions.length;
    },
    attachmentPath() {
      if (this.getContract.action === 'upload') {
        return '/xlsx/contract-patterns/existing/mes-oek-tso/';
      }

      return '/xlsx/contract-patterns/new/mes-oek-tso/';
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договору';
      }
      return 'доп.соглашению';

    },
    type() {
      if (this.getContract.action === 'create') return 'Электронный';
      else if (this.getContract.action === 'upload') return 'Действующий';
      return '-';
    },
    nonDepartmentApplications() {
      return this.getContract.attachments.filter((attach) => !attach.departmentData);
    },
    departments() {
      const result = {};
      this.getContract.attachments
          .filter((attach) => attach.departmentData)
          .forEach((attach) => {

        if (!result[attach.departmentData.id]) {
          result[attach.departmentData.id] = {
            id: attach.departmentData.id,
            name: attach.departmentData.name,
            sortIndex: attach.departmentData.sortIndex,
          };
        }
        result[attach.departmentData.id][attach.name] = attach;
      });

      return result;
    },
    departmentsId() {
      return Object.keys(this.departments)
          .map((departmentId) => ({ departmentId: departmentId, sortIndex: this.departments[departmentId].sortIndex }))
          .sort((first, second) => (second.sortIndex < first.sortIndex ? 1 : -1));
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getErrors', 'getPatternName']),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }
        this.isWaitingSubmit = true;
        this.visible = this.isWaitingSubmit;

        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment1NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...electricityUsagePointAdditionInputExcelPattern },
              taskId,
            );

            const mainSubstationNumberErrors = await this.checkExistingSubstations(
                res.data,
                electricityUsagePointAdditionInputExcelPattern.rowNumberStart,
                electricityUsagePointAdditionInputExcelPattern.supplyPointName,
            );

            await this.setAttachments({
              attachment: {
                name: 'ElectricityUsagePointsAddition',
                kind: 'receiving',
                value: res.data,
                errors: [...res.errors, ...mainSubstationNumberErrors],
                file,
                number: 1,
              },
            });
          } else if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment11NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...electricityUsagePointAdditionOutputExcelPattern },
              taskId,
            );

            const mainSubstationNumberErrors = await this.checkExistingSubstations(
                res.data,
                electricityUsagePointAdditionOutputExcelPattern.rowNumberStart,
                electricityUsagePointAdditionOutputExcelPattern.supplyPointName,
            );

            await this.setAttachments({
              attachment: {
                name: 'ElectricityUsagePointsAddition',
                kind: 'transfer',
                value: res.data,
                errors: [...res.errors, ...mainSubstationNumberErrors],
                file,
                number: 2,
              },
            });
          } else if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment31NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...electricityUsagePointForRelatedAdditionExcelPattern },
              taskId,
            );

            const mainSubstationNumberErrors = await this.checkExistingSubstations(
                res.data,
                electricityUsagePointForRelatedAdditionExcelPattern.rowNumberStart,
                electricityUsagePointForRelatedAdditionExcelPattern.supplyPointName.mainSubstationNumber,
            );

            await this.setAttachments({
              attachment: {
                name: 'ElectricitySupplyPointsForRelatedOrganizationsAddition',
                value: res.data,
                errors: [...res.errors, ...mainSubstationNumberErrors],
                file,
                number: 3,
              },
            });
          } else {
            incorrectFiles.push(file.name);
          }
        }

        if (incorrectFiles.length > 0) {
          this.visible = false;
          this.onShownNotification(incorrectFiles, `${this.attachment1Name}<br/>${this.attachment11Name}<br/>${this.attachment31Name}`);
        }
        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
    filledDocuments: {
      immediate: true,
      async handler(val) {
        const departmentId = Object.keys(val)[0];

        if (!departmentId) {
          return;
        }

        if (val[departmentId].length === 0) {
          this.filledDocuments = {};
          return;
        }

        this.isWaitingSubmit = true;
        this.visible = this.isWaitingSubmit;
        const incorrectFiles = [];

        for (let i = 0; i < val[departmentId].length; i++) {
          const file = val[departmentId][i];
          const taskId = nanoid();

          const departmentData = {
            id: this.departments[departmentId].id,
            name: this.departments[departmentId].name,
            sortIndex: this.departments[departmentId].sortIndex,
          };

          if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment2NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...electricityConsumersAdditionExcelPattern },
              taskId,
            );

            this.deleteDoc2(departmentData.id);

            await this.setAttachments({
              attachment: {
                name: 'ElectricityConsumerAddition',
                departmentData,
                value: res.data,
                errors: res.errors,
                file,
                number: 4,
                sortIndex: departmentData.sortIndex,
              },
            });
            // configForExcelValidateWithWorker = this.validateExcelConfigAttachment2;
          } else if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment4NameWithoutExtension)) !== -1) {
            const res = await excelParseWithWorker(
              file.code,
              { ...electricitySupplyPointAdditionAdditionExcelPattern },
              taskId,
            );

            const mainSubstationNumberErrors = await this.checkExistingSubstations(
                res.data,
                electricitySupplyPointAdditionAdditionExcelPattern.rowNumberStart,
                electricitySupplyPointAdditionAdditionExcelPattern.supplyPointName,
            );

            this.deleteDoc4(departmentId);

            await this.setAttachments({
              attachment: {
                name: 'ElectricitySupplyPointAddition',
                departmentData: departmentData,
                value: res.data,
                errors: [...res.errors, ...mainSubstationNumberErrors],
                file,
                number: 5,
                sortIndex: departmentData.sortIndex,
              },
            });
          } else {
            incorrectFiles.push(file.name);
          }
        }

        if (incorrectFiles.length > 0) {
          this.visible = false;
          this.onShownNotification(incorrectFiles, `${this.attachment2Name}<br/>${this.attachment4Name}`);
        }

        this.filledDocuments = {};
        this.isWaitingSubmit = false;
      },
    },
    async addedDepartment() {
      if (!this.addedDepartment?.id) {
        return;
      }

      if (!this.departmentSortIndex) {
        this.departmentSortIndex = !this.departmentsId || this.departmentsId.length === 0
            ? 1 : this.departmentsId[this.departmentsId.length - 1].sortIndex + 2;
      }

      await this.setAttachments({
        attachment: {
          name: 'ElectricityConsumerAddition',
          departmentData: { ...this.addedDepartment, sortIndex: this.departmentSortIndex++ },
          number: this.addedDepartment.name + 1,
        },
      });

      await this.setAttachments({
        attachment: {
          name: 'ElectricitySupplyPointAddition',
          departmentData: { ...this.addedDepartment, sortIndex: this.departmentSortIndex++ },
          number: this.addedDepartment.name + 2,
        },
      });

      this.addedDepartment = {};
      this.isAddDepartment = false;

    },
  },
  methods: {
    downloadFinished() {
      setTimeout(() => {
        this.visible = false;
      }, 150);
    },
    addDepartment() {
      this.isAddDepartment = true;
    },
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
    formattedFileName(name) {
      const names = ['Приложение_1.1', 'Приложение_1', 'Приложение_3.1'];
      for (let i = 0; i < names.length; i++) {
        if (name.includes(names[i])) return names[i].replace('_', ' ');
      }
      return '';
    },
    async completeDepartments() {
      this.departmentList = await this.mesDepartments();
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    onShownNotification(files, attachments) {
      EVENT_BUS.$emit('NOTIFICATION_LISTENER', {
        data: {
          title: '',
          message: `<p><b>Имя файла:</b></p><p>${files}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${attachments}</p>`,
        },
        type: 'ALERT',
        width: 700,
      });
    },
    deleteDoc(attachment) {
      this.deleteAttachment({ attachment });
    },
    deleteDoc2(departmentId, checkEmptyDepartment) {
      if (this.departments[departmentId].ElectricityConsumerAddition) {
        const departmentData = this.departments[departmentId].ElectricityConsumerAddition.departmentData;
        this.deleteDoc(this.departments[departmentId].ElectricityConsumerAddition);

        if (checkEmptyDepartment && !this.departments[departmentId]) {
          this.addEmptyAdditionForDepartment(departmentData);
        }
      }
    },
    deleteDoc4(departmentId, checkEmptyDepartment) {
      if (this.departments[departmentId].ElectricitySupplyPointAddition) {
        const departmentData = this.departments[departmentId].ElectricitySupplyPointAddition.departmentData;
        this.deleteDoc(this.departments[departmentId].ElectricitySupplyPointAddition);

        if (checkEmptyDepartment && !this.departments[departmentId]) {
          this.addEmptyAdditionForDepartment(departmentData);
        }
      }
    },
    async addEmptyAdditionForDepartment(departmentData) {
      await this.setAttachments({
        attachment: {
          name: 'ElectricityConsumerAddition',
          departmentData: departmentData,
          number: this.addedDepartment.name + 1,
        },
      });

      await this.setAttachments({
        attachment: {
          name: 'ElectricitySupplyPointAddition',
          departmentData: departmentData,
          number: this.addedDepartment.name + 2,
        },
      });
    },
    deleteDepartment(departmentId) {
      this.deleteDoc2(departmentId);
      this.deleteDoc4(departmentId);
    },
    async checkExistingSubstations(
        data,
        rowNumberStart,
        patternInfo,
    ) {
      const existingSubstations = await this.getMainSubstationsListFromNumbers({
        numbers: [
            ...data.filter((item) => item.supplyPointName.mainSubstationNumber).map((item) => item.supplyPointName.mainSubstationNumber),
            ...data.filter((item) => item.supplyPointName.distributionSubstation).map((item) => item.supplyPointName.distributionSubstation),
            ...data.filter((item) => item.supplyPointName.transformationSubstation).map((item) => item.supplyPointName.transformationSubstation),
        ],
      });

      const numbersExistingSubstations = existingSubstations.map((item) => item.number);

      const errors = [];
      data.forEach((item, index) => {
        if (item.supplyPointName.mainSubstationNumber && !numbersExistingSubstations.includes(item.supplyPointName.mainSubstationNumber)) {
          errors.push({
            col: patternInfo.mainSubstationNumber.col,
            title: patternInfo.mainSubstationNumber.title,
            row: rowNumberStart + index + 1,
            rowIndex: index,
            text: 'Ошибка при заполнении столбца №ПЦ. Питающий центр или генератор отсутствует в справочнике питающих центров',
            template: patternInfo.mainSubstationNumber,
            keys: ['supplyPointName', 'mainSubstationNumber'],
            incorrectValue: item.supplyPointName.mainSubstationNumber,
            parsed: item,
          });
        }

        if (item.supplyPointName.distributionSubstation && !numbersExistingSubstations.includes(item.supplyPointName.distributionSubstation)) {
          errors.push({
            col: patternInfo.distributionSubstation.col,
            title: patternInfo.distributionSubstation.title,
            row: rowNumberStart + index + 1,
            rowIndex: index,
            text: 'Ошибка при заполнении столбца № РП. Питающий центр или генератор отсутствует в справочнике питающих центров',
            template: { ...patternInfo.distributionSubstation, rules: `${patternInfo.distributionSubstation.rules}|required` },
            keys: ['supplyPointName', 'distributionSubstation'],
            incorrectValue: item.supplyPointName.distributionSubstation,
            parsed: item,
          });
        }

        if (item.supplyPointName.transformationSubstation && !numbersExistingSubstations.includes(item.supplyPointName.transformationSubstation)) {
          errors.push({
            col: patternInfo.transformationSubstation.col,
            title: patternInfo.transformationSubstation.title,
            row: rowNumberStart + index + 1,
            rowIndex: index,
            text: 'Ошибка при заполнении столбца № ТП. Питающий центр или генератор отсутствует в справочнике питающих центров',
            template: { ...patternInfo.transformationSubstation, rules: `${patternInfo.transformationSubstation.rules}|required` },
            keys: ['supplyPointName', 'transformationSubstation'],
            incorrectValue: item.supplyPointName.transformationSubstation,
            parsed: item,
          });
        }
      });
      return errors;
    },
    ...mapActions('electricityTransmissionContract', ['setAttachments', 'deleteAttachment']),
    ...mapActions('dataLists', ['mesDepartments', 'getMainSubstationsListFromNumbers']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 800px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 22px
    font-weight: 500
    line-height: 26px
    letter-spacing: 0
    color: #4C4E51

    span
      margin-right: 12px
      color: #979CA2

  .smallTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .subTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 18px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .stepHeader
    +base-title
    margin-top: 58px
    padding: 8px
    background-color: #9e9fa2
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    border-color: #C1C4C7

.uploadingTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 16px
  font-weight: 500
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51

.attachmentsList
  display: flex
  flex-direction: column
  gap: 32px
  padding: 0 9px

.attachment, .fileArchive, .attachmentReport
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  text-align: left
  color: #4C4E51


.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachmentWrp
  display: flex
  flex-direction: column
  gap: 16px

.attachmentTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51


.fileAttachment
  display: flex
  align-items: center
  text-decoration: underline
  color: #4C4E51

  img
    width: 40px
    height: 40px
    margin-right: 16px

  span
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 400
    line-height: 22px
    letter-spacing: 0

  &:hover
    text-decoration: none

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

  img
    margin-right: 8px

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.modalContainer
  padding: 0 40px 40px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757 !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none

.inputField
  +input-text
  font-family: 'Roboto Condensed', sans-serif
  padding: 4px 16px
  color: gray

.button
  border-radius: 24px
  border: 1px solid #DEE2E6
  background: inherit
  padding: 0 20px
  height: 40px

  .inner
    display: flex
    gap: 10px

  .text
    display: flex
    align-self: center

.deleteText
  font-family: 'Roboto Condensed', sans-serif
  font-size: 16px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #C1C4C7
  margin: auto 0
</style>
