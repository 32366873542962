<template>
  <div>
    <warehouses-autocomplete
        ref="warehouseId" v-model="internalObject.warehouseId" :is-disabled="!!planConsignment"
        :items.sync="internalObject.warehouses" :search.sync="internalObject.warehouseName" is-required
        title="Склад"
    />

    <axp-autocomplete
        ref="simpleAutocompleteAxp"
        v-model="internalObject.axpId"
        :find-ids="axpFindIds"
        :is-disabled="!!planConsignment"
        :items.sync="internalObject.axpItems"
        :search.sync="internalObject.axpName"
        is-required
        title="АХП"
        url-default-data="/axp/getInRepairAxpByWarehouse?warehouseId={0}"
        url-search="/axp/getInRepairAxpByWarehouse?warehouseId={0}&name={1}"
    />

    <axp-photos-carousel v-if="internalObject.axpId" :axp-id="internalObject.axpId"/>

    <contractor-autocomplete ref="contractor" v-model="internalObject.contractorId" :is-disabled="!!planConsignment"
                             :items.sync="internalObject.contractors" :search.sync="internalObject.contractorName" is-required
                             title="Подрядчик"></contractor-autocomplete>

    <div v-if="withTable">
      <text-field
        ref="axpQuantity"
        v-model="internalObject.axpQuantity"
        is-only-numbers
        is-required
        title="К возврату из ремонта комплектов АХП"
      ></text-field>


      <v-row v-if="internalObject.axpId != null" class="pr-4 mb-2">
        <v-col>
          <p>
            Имеющееся на складе количество комплектов АХП:
            {{ internalObject.countAxpInStrock }}
          </p>
        </v-col>
        <v-col/>
        <v-col>
          <v-row>
            <v-spacer/>
            <v-btn @click="accountTotal">Рассчитать</v-btn>
            <v-spacer/>
            <v-btn @click="clearTotal">Очистить форму</v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="defaultHeaders"
        :items="internalObject.formElements"
        :loading="isLoadingElements"
        class="elevation-1 mb-4 "
      >
        <template #no-data>
          <span>Отсутствуют данные</span>
        </template>
        <template #item.realCount="{ item }">
          <div class="d-flex align-center mt-6">
            <text-field
              ref="contract"
              v-model="item.realCount"
              :max="item.quantityMax"
              is-only-numbers
              is-required
              simple
            ></text-field>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import { mapGetters } from 'vuex';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';

export default {
  name: 'Process102',
  components: {
    AxpPhotosCarousel,
    TextField,
    ContractorAutocomplete,
    AxpAutocomplete,
    WarehousesAutocomplete,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    withTable: {
      type: Boolean,
      default: false,
    },
    planConsignment: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    isLoadingElements: false,
    internalObject: {},
    defaultHeaders: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      {
        text: 'Количество на 1 АХП',
        align: 'start',
        value: 'elementQuantity',
      },
      {
        text: 'Нужно',
        value: 'needQuantity',
        align: 'center',
      },
      { text: 'Количество на складе', value: 'quantityInWarehouse', align: 'center' },
      {
        text: 'К возврату из ремонта',
        value: 'realCount',
        align: 'center',
      },
    ],
  }),
  computed: {
    axpFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.warehouseId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.internalObject = newVal;
        }
      },
    },
    internalObject: {
      immediate: true,
      deep: true,
      handler() {
        this.internalObject.isDisabled = this.accountDisabled();
        this.$emit('input', this.internalObject);
      },
    },
    async planConsignment(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        this.internalObject.warehouses = [{
          id: String(this.planConsignment.warehouse.id),
          name: this.planConsignment.warehouse.name,
        }];
        this.internalObject.warehouseId = String(this.planConsignment.warehouse.id);
        this.internalObject.warehouseName = this.planConsignment.warehouse.name;
        this.internalObject.contractors = [{
          id: this.planConsignment.contractorId,
          name: this.planConsignment.contractorName,
        }];
        this.internalObject.contractorId = this.planConsignment.contractorId;
        this.internalObject.contractorName = this.planConsignment.contractorName;
        this.internalObject.axpItems = [{
          id: String(this.planConsignment.axp.id),
          name: this.planConsignment.axp.name,
        }];
        this.internalObject.axpId = String(this.planConsignment.axp.id);
        this.internalObject.axpName = this.planConsignment.axp.name;
      }
    },
    'internalObject.axpId': {
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.internalObject.formElements = [];
        this.internalObject.countAxpInStrock = null;
        if (newVal != null) {
          await this.fetchQuantities();
          const item = (await (await fetch(`${BASE_URL}/axp/getInRepairAxpByWarehouse?warehouseId=${this.internalObject.warehouseId}&name=${this.internalObject.axpName}`)).json()).filter((it) => it.id == newVal);

          this.internalObject.countAxpInStrock = item[0].count;
          await this.fetchElements(newVal);
        }
      },
    },
    'internalObject.axpQuantity': {
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.setQuantitiesForElements();
      },
    },
  },
  methods: {
    async fetchElements() {
      this.isLoadingElements = true;
      const link = this.internalObject.adreskaId ? `${BASE_URL
      }/warehouse/${this.internalObject.warehouseId}/axp/${this.internalObject.axpId}/elements?adreska=${this.internalObject.adreskaId}` : `${BASE_URL
      }/warehouse/${this.internalObject.warehouseId}/axp/${this.internalObject.axpId}/elements`;
      const response = await fetch(
        link,
      );
      const data = await response.json();
      this.internalObject.formElements = data.map((el) => ({
        elementId: el.id,
        elementName: el.name,
        unitName: el.unitName,
        quantityInWarehouse: el.stockQuantity,
        quantityMax: el.stockQuantity,
        elementQuantity: el.elementQuantity,
        realCount: '',
      }));
      this.isLoadingElements = false;
      this.setQuantitiesForElements();
    },
    accountTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount:
          String(Math.min(Number(el.needQuantity), el.quantityInWarehouse)),
      }));
    },
    clearTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount: '',
      }));
    },
    setQuantitiesForElements() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        needQuantity: el.elementQuantity * (this.internalObject.axpQuantity ?? 0),
      }));
    },
    async fetchQuantities() {
      if (!this.internalObject.axpId) return;
      this.setQuantitiesForElements();
    },
    accountDisabled() {
      const isDisabledWarehouse = this.internalObject.warehouseId == null;
      const isDisableAxp = this.internalObject.axpId == null;
      const isDisableContractor = this.internalObject.contractorId == null;
      const isDisabledAxpQuantity = this.withTable && (!this.internalObject.axpQuantity?.length || isNaN(Number(this.internalObject.axpQuantity)));
      const isDisableCountElements = this.withTable && (this.internalObject.formElements.some((it) => !it.realCount?.length || isNaN(Number(it.realCount)) || Number(it.realCount) < 0 || Number(it.realCount) > Number(it.quantityMax)) ||
        this.internalObject.formElements.every((it) => Number(it.realCount) === 0));
      return isDisabledWarehouse ||
        isDisableAxp ||
        isDisabledAxpQuantity ||
        isDisableContractor ||
        isDisableCountElements;
    },
    resetValidation() {
      this.isLoadingElements = false;
      this.internalObject = {};
      this.axpQuantity = '';
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
