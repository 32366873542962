<template>
  <page title="Реестр точек учёта">
    <data-tab-selector
      :data-types-list="tabs"
      class="cabinet-consumers-card__select"
      tab-style="table"
      @selectTab="selectTabHandler"
    />

    <receiving-points v-if="selectedTab === 'RECEIVING_POINT'" :route="$router.currentRoute.fullPath" :addition-kind="selectedTab" :export-to-excel-action="exportToExcelAction" />
    <delivery-points v-else-if="selectedTab === 'DELIVERY_POINT'" :route="$router.currentRoute.fullPath" :addition-kind="selectedTab" :export-to-excel-action="exportToExcelAction" />
    <transfer-points v-else-if="selectedTab === 'TRANSFER_POINT'" :route="$router.currentRoute.fullPath" :addition-kind="selectedTab" :export-to-excel-action="exportToExcelAction" />
    <technical-points v-else-if="selectedTab === 'TECHNICAL_POINT'" :route="$router.currentRoute.fullPath" :addition-kind="selectedTab" :export-to-excel-action="exportToExcelAction" />
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import ReceivingPoints from './ReceivingPoints';
import DeliveryPoints from './DeliveryPoints';
import TransferPoints from './TransferPoints';
import TechnicalPoints from './TechnicalPoints';

export default {
  name: 'CabinetUsagePoints',
  components: {
    ReceivingPoints,
    DataTabSelector,
    DeliveryPoints,
    TransferPoints,
    TechnicalPoints,
    Page,
  },
  data() {
    return {
      selectedTab: 'RECEIVING_POINT',
      kind: 'RECEIVING_POINT',
      tabs: [
        {
          id: 1,
          title: 'Приём',
          value: 'RECEIVING_POINT',
          isActive: true,
        },
        {
          id: 2,
          title: 'Поставка',
          value: 'DELIVERY_POINT',
          isActive: false,
        },
        {
          id: 3,
          title: 'Передача',
          value: 'TRANSFER_POINT',
          isActive: false,
        },
        {
          id: 4,
          title: 'Техническая',
          value: 'TECHNICAL_POINT',
          isActive: false,
        },
      ],
      exportToExcelAction: false,
    };
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 26px;

  &__date-picker {
    width: 200px;
  }

}
.meter-action-block {
  margin-bottom: 24px;
}
</style>
