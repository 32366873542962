<template>
  <Process :mode="mode"/>
</template>

<script>
import Process from 'components/Processes/Process11/process5-7/Process';
import { inspectionScreenModes } from 'components/Processes/Process11/process5-7/constants';

export default {
  name: 'NonRegularInspection',
  components: { Process },
  data() {
    return {
      mode: inspectionScreenModes.NON_REGULAR,
    };
  },
};
</script>

<style scoped>

</style>
