<template>
  <div class="button-group">
    <button v-if="value.updatable" type="button">
      <img alt="редактировать" src="@/assets/icons/user/edit-member.svg" @click="$emit('edit')">
    </button>
    <button v-if="value.deletable" type="button">
      <img alt="удалить" src="@/assets/icons/user/delete-member.svg" @click="$emit('delete')">
    </button>
  </div>
</template>

<script>
export default {
  name: 'VTableCellEditDeleteButtons',
  props: {
    value: {
      type: Object,
      default: () => ({
        deletable: true,
        updatable: true,
      }),
    },
  },

};

</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  gap: 8px;

  button {
    width: 24px;
    height: 24px;
    background: inherit;
  }
}
</style>
