<template>
  <Process2/>
</template>

<script>
import Process2 from 'components/Processes/Process4/Process2.vue';

export default {
  components: { Process2 },
};
</script>

<style scoped>

</style>
