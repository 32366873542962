<template>
<span :class="$style.stepsCounter">
  <span :class="$style.currentStep">{{ stepNumber }}</span>
  <span :class="$style.totalSteps">/{{ totalSteps }}</span>
</span>
</template>

<script>
export default {
  name: 'StepCounter',
  props: {
    stepNumber: Number,
    totalSteps: Number,
  },
};
</script>

<style lang="sass" module>
.stepsCounter
  flex: none
  margin: 0 12px 0 0

.currentStep
  color: #000

.totalSteps
  color: #C4C4C4
</style>
