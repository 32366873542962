<template>
  <v-table-new
    v-model="transformedPaymentSchedule"
    :action-block-items="actionBlockItems"
    :template="template"
    excel-report-file-name="График платежей"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { formatPaymentType, formatTariffType } from 'lib/utils/formatXmlType.js';

export default {
  name: 'PaymentScheduleContentBlock',
  components: { VTableNew },
  props: {
    loading: { type: Boolean, required: true },
    paymentSchedule: { type: Array, required: true },
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
            },
          },
          {
            label: 'Тариф',
            key: 'tariff',
            sort: { type: '' },
            filter: { type: '' },
          },
          {
            label: 'От',
            key: 'from',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'К',
            key: 'to',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Вид платежа',
            key: 'paymentType',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Платеж до числа месяц | Включительно',
            key: 'withoutDate',
            sort: {},
            filter: {},
          },
          {
            label: 'Платеж до числа месяца',
            key: 'day',
            sort: {},
            filter: {},
          },
          {
            label: 'Процент %',
            type: 'number',
            key: 'percent',
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
        ],
      };
    },
    transformedPaymentSchedule() {
      if (this.paymentSchedule.length === 0) return [];

      return this.paymentSchedule.map((it) => ({
        tariff: formatTariffType(it.tariffType),
        from: it.from.name,
        to: it.to.name,
        paymentType: formatPaymentType(it.paymentType),
        day: it.day,
        withoutDate: it.withoutDate ? 'Да' : 'Нет',
        percent: it.percent,
      }));
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Добавить платёж',
          method: () => {
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
