<template>
  <validation-provider
    ref="validationProvider"
    v-slot="{ errors }"
    :custom-messages="rulesMessages"
    :name="name"
    :rules="rules"
    :vid="vid"
    mode="lazy"
  >
    <input v-model="selectedOption" style="display: none" type="text">
    <div
      v-custom-click-outside="setShowMenu"
      :class="$style['select-container']"
      :style="customStyle"
      @click="displayMenu"
    >
      <v-label
        :is-error="(errors.length > 0 || err) && !disabled"
        :is-need-mouseover-tooltip="isNeedMouseoverTooltip"
        :is-value="isValue"
        :label="labelByRequired"
        :title="titleByRequired"
        select
      >
        <div
          :class="{
            [$style['mt-8']]: isMarginTopNeeded,
            [$style['position-relative']]: true,
          }"
        >
          <v-divider
            v-if="newStyle"
            :class="$style['select-container__divider']"
            direction="vertical"
          />
          <div
            :class="{
              [$style['position-relative']]: true,
            }"
          >
            <img
              v-if="!clearable && !newStyle"
              :class="$style['select-container__arrow']"
              src="~@/assets/icons/arrows/arrow-down.svg"
            />
          </div>
          <img
              v-if="newStyle && (!clearable || selectedOption?.length === 0)"
              :class="[
                $style['select-container__arrow'],
                $style['select-container__arrow--new-style']
              ]"
              src="~@/assets/icons/arrows/arrow-down-horizontal.svg"
          />
          <div
            :class="{
            [$style['select-container__select']]: true,
            [$style['select-container__select--title']]: title.length > 0,
            [$style['select-container__select--disabled']]: disabled,
            [$style['select-container__select--new-style']]: newStyle,
            [$style['input-container__input--clearable']]: clearable,
            [$style['select-container__select--placeholder']]: selectedOption?.length === 0 && placeholder,
            [$style['error']]: (errors.length > 0 || err) && !disabled,
            [$style['select-container__select--no-border']]: noBorder,
            [$style['select-container__select--blue-border']]: blueBorder,}"
            tabindex="0"
          >
            <slot />
            <img
              v-if="isShowInputImg"
              alt=""
              src="@/assets/icons/read_more.svg"
              style="padding-right: 6px"
            />
            {{ getLabelBySelectedOption }}
            <div :class="{
              [$style['v-clearable-input-wrp']]: true,
            }">
              <v-clearable-input :is-show="isValue && clearable" new-style @click="setSelectedOption('')" />
            </div>
          </div>
        </div>
      </v-label>
      <v-menu v-if="showMenu" :options="options" @click="setSelectedOption" />
    </div>
    <span
      v-if="errors.length > 0 && !disabled"
      class="errorText">{{ errors[0] }}
      </span>
    <span
      v-if="errText !== '' && err"
      class="errorText"
    >
        {{ errText }}
      </span>
  </validation-provider>
</template>

<script>
import { VClearableInput, VMenu } from '@/components';
import VDivider from '@/components/VDivider/VDivider';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'VSelect',
  components: {
    VClearableInput,
    VLabel: () => import('../Common/VLabel/VLabel'),
    VMenu,
    VDivider,
    ValidationProvider,
  },
  props: {
    isShowInputImg: { type: Boolean, default: false },
    options: { type: Array, required: true },
    required: { type: Boolean, default: false },
    label: { type: String, default: '' },
    clearable: { type: Boolean, default: false },
    value: { type: String, default: '' },
    title: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    isNeedMouseoverTooltip: { type: Boolean, default: false },
    err: { type: Boolean, default: false },
    blueBorder: { type: Boolean, default: false },
    newStyle: { type: Boolean, default: false },
    height: { type: String, required: false },
    errText: { type: String, default: '' },
    vid: { type: String, default: '' },
    rules: { type: String, default: '' },
    rulesMessages: { type: Object, default: () => {} },
    noBorder: { type: Boolean, default: false },
  },
  data() {
    return {
      showMenu: false,
      selectedOption: this.value,
    };
  },
  computed: {
    isMarginTopNeeded() {
      if (this.title.length !== 0 || this.label.length !== 0) {
        return true;
      }
      return false;
    },
    name() {
      if (!this.label) return this.title;
      return this.label;
    },
    labelByRequired() {
      if (this.required && this.label && !this.disabled) {
        return `${this.label} *`;
      }
      return this.label;
    },
    titleByRequired() {
      if (this.required && this.title && !this.disabled) {
        return `${this.title} *`;
      }
      return this.title;
    },
    customStyle() {
      const result = {};

      if (this.height) {
        result.height = `${this.height}px`;
      }

      return result;
    },
    isValue() {
      return this.selectedOption?.length > 0;
    },
    optionsMap() {
      return this.options.reduce((acc, cur) => {
        acc[cur.value] = cur.label;
        return acc;
      }, {});
    },
    getLabelBySelectedOption() {
      if (this.selectedOption?.length === 0 && this.placeholder) {
        return this.placeholder;
      }

      return this.optionsMap[this.selectedOption];
    },
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    },
  },
  methods: {
    setSelectedOption(value) {
      if (this.disabled)
        return;
      this.selectedOption = value;
      this.showMenu = false;
      this.$emit('input', value);
    },
    setShowMenu() {
      this.showMenu = false;
    },
    displayMenu() {
      if (this.disabled)
        return;

      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss" module>
.select-container {
  position: relative;

  &__select {
    cursor: pointer;
    width: 100%;
    min-height: 40px;
    border: 1px solid #dee2e6;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #000000;

    &--disabled {
      color: #71757A;
    }

    &--clearable {
      padding-right: 36px;
      width: 24px;
      height: 24px;
    }

    &:focus {
      border: 1px solid #2f82df;
    }

    &:hover {
      border: 1px solid #2f82df;
    }

    &--title {
      border: 1px solid #DEE2E6;
      border-radius: 2px;
    }

    &--placeholder {
      color: #C1C4C7;
    }

    &--disabled {
      background-color: #F5F6F6;

      &:focus {
        border: 1px solid #dee2e6;
      }
    }

    &--blue-border {
      border: 1px solid #2f82df;

      &:focus {
        border: 1px solid #2f82df;
      }
    }

    &--new-style {
      height: 100%;
      padding: 0 48px 0 16px;
      border: 1px solid #E9EBED;
      border-radius: 4px;
    }

    &--no-border {
      border: 0 !important;
    }
  }

  &__arrow {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    top: 9px;
    cursor: pointer;

    &--new-style {
      width: 20px;
      height: 12px;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__divider {
    height: 44%;
    width: 1px;
    position: absolute;
    right: 38px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.error {
  border-color: red;
}

.mt-8 {
  margin-top: 8px;
}

.position-relative {
  position: relative;
}

.v-clearable-input-wrp {
  position: absolute;
  top: 13px;
  right: 10px;
}
</style>
