<template>
  <div v-if="filteredActionBlockItems.length">
    <select-button
      :items="filteredActionBlockItems"
      min-width="280"
    />
    <v-divider style="margin: 24px 0; height: 0;"/>
  </div>
</template>

<script>
import SelectButton from 'components/SelectButton/SelectButton.vue';
import { filterForAccess } from 'lib/utils/permissions';
import { mapGetters } from 'vuex';
import { VDivider } from 'components';

export default {
  name: 'ActionBlock',
  components: { VDivider, SelectButton },
  computed: {
    actionBlockItems() {
      return [
        {
          id: 'process/11/1',
          title: 'Назначение объектов на осмотр',
          method: this.goToPageByRoute('process/11/1'),
        },
        {
          id: 'process/11/5',
          title: 'Осмотр',
          method: this.goToPageByRoute('process/11/5'),
        },
        {
          id: 'process/12/1',
          title: 'Ремонт на объекте',
          method: this.goToPageByRoute('process/12/1'),
        },
        {
          id: 'process/11/3-4',
          title: 'Назначение объектов на регулярный осмотр',
          method: this.goToPageByRoute('process/11/3-4'),
        },
        {
          id: 'process/11/6',
          title: 'Регулярный осмотр',
          method: this.goToPageByRoute('process/11/6'),
        },
      ];
    },
    filteredActionBlockItems() {
      return this.actionBlockItems.filter((item) => filterForAccess(item.id, this.getAuthorization?.blockAccess));
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    goToPageByRoute(path) {
      return () => this.$router.push(path);
    },
    saveReport() {
      this.$emit('saveReport');
    },
  },
};
</script>

<style scoped>

</style>
