<template>
  <div :class="{['checkbox-wrp']: true, ['checkbox-wrp__selected']: data.point.selected}" @click="selected(data.point.id)">
    <div style="display: flex; gap: 8px; align-items: center">
      <input
             v-model="data.point.selected"
             class="checkbox-wrp__checkbox"
             type="checkbox"
             :disabled="isDisabled"
      />
      <label class="checkbox-wrp__label">{{ value }}</label>
    </div>
    <div style="display: flex; gap: 8px; align-items: center">
      <div :style="`background-color: ${colorByStatus}`" class="circle" />
      <point-location />
      <with-meter />
      <div style="height: 16px" @click="redirectToPoint(data.point.id)">
        <arrow-right-circle
          height="16"
          width="16"
          fill="none"
          stroke="#2F82DF"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowRightCircle from 'atoms/icons/arrows/ArrowRightCircle.vue';
import PointLocation from 'atoms/icons/point/PointLocation.vue';
import WithMeter from 'atoms/icons/point/WithMeter.vue';

const statusMap = {
  NEW: { label: 'Новая', color: '#DFE1E5' },
  SETTLED: { label: 'Урегулирована', color: '#4CCB40' },
  UNSETTLED: { label: 'Неурегулирована', color: '#EB5757' },
  ARCHIVED: { label: 'В архиве', color: '#EB5757' },
  IN_APPLICATION: { label: 'В заявке на ТП', color: '#3CB6EA' },
  CONNECTED: { label: 'Прошла ТП', color: '#4CCB40' },
  EXCLUDED: { label: 'Удалена', color: '#EB5757' },
  ACTIVE: { label: 'Активная', color: '#4CCB40' },
  WAITING_SUPPLY_CONTRACT: { label: 'Ожидает заключение ДЭС', color: '#3CB6EA' },
  WAITING_ADDITION_APPROVE: { label: 'Ожидает согласования ДС', color: '#3CB6EA' },
};

export default {
  name: 'VTableCellUsagePointsCheckBox',
  components: { PointLocation, ArrowRightCircle, WithMeter },
  props: {
    value: { type: String, default: '' },
    data: { type: Object, required: true },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorByStatus() {
      return statusMap[this.data.point.pointStatus]?.color;
    },
  },
  methods: {
    async redirectToPoint(id) {
      await this.$router.push(`/cabinet/usage-point/${id}`);
    },
    selected() {
      this.$emit('select');
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-wrp {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px;
  border-radius: 4px;



  &__checkbox {
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }

  &__label {
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #262729;
    text-align: left;

  }

  &__selected {
    background: rgba(47, 130, 223, 0.15);
  }
}

.circle {
  min-width: 12px;
  min-height: 12px;
  border-radius: 24px;
}
</style>
