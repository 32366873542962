<template>
  <div class="form-wrp" style="margin: 24px 0 20px 100px;">
    <div class="form">
      <div class="form-header">
        <h1 class="form-header__title">Формирование баланса</h1>
<!--        <p class="form-header__subtitle">За расчётный период</p>-->
      </div>
      <v-divider />
      <div class="generate-balance">
        <div>
          <v-row class="generate-balance__signatories">
            <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
              <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
                {{ creationByTransmissionContract?.supplier?.name }}*</p>
              <emploee-select-or-input :inn="creationByTransmissionContract?.supplier?.inn"
                                       permission="SVET_A13-S" @emploeeSelectOrInput="updateSupplier"/>
            </v-col>
          </v-row>
          <v-row class="generate-balance__signatories">
            <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
              <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
                {{ creationByTransmissionContract?.performerFirst?.name }}*</p>
              <emploee-select-or-input :inn="creationByTransmissionContract?.performerFirst?.inn"
                                       permission="SVET_A13-S"
                                       @emploeeSelectOrInput="updatePerformerFirstSignatory"/>
            </v-col>
          </v-row>
          <v-row class="generate-balance__signatories">
            <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
              <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
                {{ creationByTransmissionContract?.performerSecond?.name }}*</p>
              <emploee-select-or-input :inn="creationByTransmissionContract?.performerSecond?.inn"
                                       permission="SVET_A13-S"
                                       @emploeeSelectOrInput="updatePerformerSecondSignatory"/>
            </v-col>
          </v-row>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VDivider from 'components/VDivider/VDivider.vue';
import { EmploeeSelectOrInput, VRow, VCol } from '@/components';
import { mapGetters } from 'vuex';
import { getDateAsForceISOString } from 'lib/utils/date';

export default {
  name: 'SignatoriesStep',
  components: { VDivider, EmploeeSelectOrInput, VRow, VCol },
  props: {
    creationByTransmissionContract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      supplierSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
      performerFirstSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleNameGenitive: {},
      },
      performerSecondSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
    };
  },
  computed: {
    ...mapGetters('planningBalance', ['getBalance']),
  },
  watch: {
    // Отслеживаем изменения supplierSignatory
    supplierSignatory: {
      handler(newVal) {
        if (newVal) {
          this.updateRepresentatives();
        }
      },
      deep: true, // используется, если объект вложенный и мы хотим отслеживать его свойства
    },

    // Аналогично для других signatories
    performerFirstSignatory: {
      handler(newVal) {
        if (newVal) {
          this.updateRepresentatives();
        }
      },
      deep: true,
    },

    performerSecondSignatory: {
      handler(newVal) {
        if (newVal) {
          this.updateRepresentatives();
        }
      },
      deep: true,
    },
  },
  methods: {
    updateRepresentatives() {
      // Убедитесь, что у всех signatory поле заполнено перед первым вызовом createRepresentativeInput
      if (this.supplierSignatory && this.performerFirstSignatory && this.performerSecondSignatory) {
        this.$emit('updateData', {
          sidesRepresentatives: {
            supplierRepresentative: this.createRepresentativeInput(this.supplierSignatory),
            performerFirstRepresentative: this.createRepresentativeInput(this.performerFirstSignatory),
            performerSecondRepresentative: this.createRepresentativeInput(this.performerSecondSignatory),
          },
        });
      }
    },
    updateSignatory(signatory, selected) {
      signatory.userId = selected.userId;
      signatory.role = {
        id: selected.role?.id,
        name: selected.role?.name,
      };
      signatory.reason = {
        name: selected.reason?.reasonDocument,
        number: selected.reason?.number,
        date: selected.reason?.date,
      };
      signatory.fullName = {
        name: selected.fullName?.name,
        surname: selected.fullName?.surname,
        patronymic: selected.fullName?.patronymic,
      };
      signatory.fullNameGenitive = {
        name: selected.fullNameGenitive?.name,
        surname: selected.fullNameGenitive?.surname,
        patronymic: selected.fullNameGenitive?.patronymic,
      };
      signatory.powerOfAttorneyId = selected.reason?.id;
      signatory.accountId = selected.accountId;
    },
    updateSupplier(selected) {
      this.updateSignatory(this.supplierSignatory, selected);
    },
    updatePerformerFirstSignatory(selected) {
      this.updateSignatory(this.performerFirstSignatory, selected);
    },
    updatePerformerSecondSignatory(selected) {
      this.updateSignatory(this.performerSecondSignatory, selected);
    },
    createRepresentativeInput(signatory) {
      console.log(signatory, 'signatory');
      return signatory.userId ? {
        digital: {
          userId: signatory.userId,
          roleId: signatory.role.id,
          powerOfAttorneyId: signatory.powerOfAttorneyId,
        },
      } : {
        simple: {
          person: {
            name: signatory.fullName.name,
            surname: signatory.fullName.surname,
            patronymic: signatory.fullName.patronymic,
          },
          positionName: signatory.role.name,
          reasonDocument: {
            reasonDocumentName: signatory.reason.name,
            date: signatory.reason.date ? getDateAsForceISOString(signatory.reason.date) : null,
            number: signatory.reason.number,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  width: 1024px;
  gap: 24px;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  &-wrp {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 850px;
    }

    &__subtitle {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4C4E51;
    }
  }
  .generate-balance {
    display: flex;
    justify-content: center;

    &__signatories {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
