<template>
  <inner-page :current-step="currentStep"
              :home="formAttorney.homeTitle"
              :steps="steps"
              class="form-attorney"
              @back="back"
              @goHome="goHome"
              @openStep="openStep"
  >
    <h1 v-if="!isScanAttorney" class="form-attorney__title">
      Сформировать машиночитаемую доверенность
    </h1>
    <template v-else>
      <div>
        <span>Загрузка документа*</span>
        <c-uploading
          v-model="formAttorney.powerOfAttorneyScan"
          :error="{ error: false }"
          class="form-attorney__scan"
        />

        <div v-if="formAttorney.powerOfAttorneyScan && formAttorney.powerOfAttorneyScan[0]"
             class="form-attorney__attachment">
          <a :download="formAttorney.powerOfAttorneyScan[0].name" :href="fileUrl(formAttorney.powerOfAttorneyScan[0])"
             class="form-attorney__attachment-item">
            <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40"/>
            <span>{{ formAttorney.powerOfAttorneyScan[0].name }}</span>
          </a>
          <a class="form-attorney__attachment-delete" href="#" title="удалить" @click="deletePowerOfAttorneyScan()">
            <icon-delete/>
          </a>
        </div>
      </div>

      <div class="form-attorney__date">
        <v-date-picker
          v-model="formAttorney.issueDate"
          title="Дата выдачи*"
        />
        <span v-if="formAttorney.issueDate && !issueAtBeforeNow"
              class="errorText">Не может быть позже текущей даты</span>
      </div>

      <v-divider/>

      <h1 class="form-attorney__title">Заполнение карточки доверенности</h1>
    </template>

    <v-divider/>

    <v-select
      v-model="formAttorney.snils"
      :disabled="!userSelectorEnabled || isExternalAttorney"
      :options="validUsersForSelector"
      :placeholder="userPlaceholder"
      title="Выбор сотрудника*"
    />

    <v-input
      v-model="formAttorney.numberAttorney"
      :disabled="isExternalAttorney"
      clearable
      title="Номер доверенности*"
    />

    <div class="form-attorney__block-select-rights">
      <v-search-select
        v-model="rights"
        :disabled="!rightsSelectorEnabled || isExternalAttorney"
        :options="rightsListUnselect"
        :placeholder="rightsPlaceholder"
        title="Полномочия*"
      />

      <div v-if="validatedSelectedRights?.length" class="form-attorney__selected-rights-list">
        <div v-for="(item, index) in validatedSelectedRights" :key="index"
             :class="{['form-attorney__selected-rights']: true,
             ['form-attorney__selected-rights--not-exist']: item.isNotExist}"
        >
          {{ item.value }}
          <img src="~@/assets/icons/basic/cross.svg" @click="deleteRights(index)"/>
        </div>
      </div>

      <div v-show="validatedSelectedRights.length > 0" class="form-attorney__label-select-rights">
        <div v-for="(item, index) in validatedSelectedRights" :key="index"
             :class="{['form-attorney__label-select-rights--not-exist']: item.isNotExist}"><b>{{ item.value }}:</b>
          {{ item.description }}
        </div>
      </div>
    </div>
    <span v-if="isScanAttorney">В случае отсутствия необходимых полномочий в списке оставить заявку на добавление полномочий Вы можете в разделе <a
      class="form-attorney__permission-registry-link"
      @click="goToPermissionRegistry">"Справочник полномочий"</a>.</span>
    <div class="form-attorney__dates">
      <div class="form-attorney__start-date">
        <v-date-picker
          v-model="formAttorney.startDate"
          :disabled="isExternalAttorney"
          title="Начало действия*"
        />
        <span v-if="formAttorney.startDate && !startAtAfterIssue"
              class="errorText">Не может быть раньше даты выдачи ({{ formAttorney.issueDate }})</span>
        <span v-if="!startAtBeforeNow" class="errorText">Не может быть раньше текущей даты</span>
      </div>

      <div class="form-attorney__start-date">
        <v-date-picker
          v-model="formAttorney.endDate"
          :disabled="isExternalAttorney"
          title="Окончание действия*"
        />
        <span v-if="!endAtAfterStart" class="errorText">Не может быть раньше даты начала действия</span>
      </div>
    </div>

    <v-divider/>

    <span v-if="notExistRights.length > 0"
          class="errorText">Полномочия: {{ notExistRights.map(item => item.value).join(', ') }} отсутствуют на Платформе. Оставить заявку на добавление полномочий вы можете в разделе <a
      class="form-attorney__permission-registry-link"
      @click="goToPermissionRegistry">"Справочник полномочий"</a>.</span>
    <span v-if="isNotExistUser" class="errorText">Пользователь: {{ formAttorney.personFIO }}<span
      v-if="formAttorney.snils"> (СНИЛС: {{ formAttorney.snils }})</span> отсутствует на Платформе.</span>
    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="back">
          Назад
        </button>
        <button class="btn-delete-draft" @click="displayDeleteDialog = true">
          УДАЛИТЬ ЧЕРНОВИК
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button :disabled="!isValid" class="btn-save" @click="next">
          Далее
        </button>
      </div>
    </div>
    <input-tooltip v-if="!getAuthorization.authorization.identityDocumentInfo"
                   text="Для формирования машиночитаемой доверенности необходимо заполнить соответствующий блок в профиле пользователя"/>
    <dialog-template v-model="displayDeleteDialog" center>
      <div class="delete-dialog">
        <p class="delete-dialog__title">
          Удалить черновик?
        </p>
        <div class="delete-dialog__action">
          <button class="btn-delete-draft" @click="deleteDraft">
            УДАЛИТЬ
          </button>
          <button class="btn-cancel" @click="displayDeleteDialog = false">
            ЗАКРЫТЬ
          </button>
        </div>
      </div>
    </dialog-template>

  </inner-page>
</template>

<script>
import { InnerPage, VDatePicker, VDivider, VInput, VSearchSelect, VSelect } from '@/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import DialogTemplate from 'templates/DialogTemplate';
import { convertDbFormat, getDateAsISOString } from 'lib/utils/date';
import InputTooltip from 'components/InputTooltip/InputTooltip.vue';
import IconDelete from 'atoms/icons/action/Delete';
import CUploading from 'atoms/common/CUploading';
import { getFileUrl } from 'lib/utils/files';
import { initStepsbyType } from './steps';

export default {
  name: 'UsagePoint',
  components: {
    InputTooltip,
    InnerPage,
    VDivider,
    VSelect,
    VSearchSelect,
    VInput,
    VDatePicker,
    DialogTemplate,
    IconDelete,
    CUploading,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      users: [],
      formAttorney: {
        snils: '',
        numberAttorney: '',
        selectedRights: [],
        startDate: '',
        endDate: '',
        retrust: false,
        currentStep: 0,
        powerOfAttorneyScan: [],
        issueDate: '',
      },
      companyId: '',
      rights: '',
      displayDeleteDialog: false,
      possiblePermissions: [],
    };
  },
  computed: {
    initSteps() {
      return initStepsbyType(this.getFormAttorney.typeFormAttorney);
    },
    homeTitle() {
      return this.$route.params.homeTitle ?? 'Сотрудники компании';
    },
    userPlaceholder() {
      return this.validUsersForSelector?.length > 0 ? 'Сотрудник' : 'Отсутствуют сотрудники, принявшие приглашение';
    },
    isExternalAttorney() {
      return this.getFormAttorney.typeFormAttorney === 'xml';
    },
    isDigitalAttorney() {
      return this.getFormAttorney.typeFormAttorney === 'form';
    },
    isScanAttorney() {
      return this.getFormAttorney.typeFormAttorney === 'upload';
    },
    userSelectorEnabled() {
      return this.validUsersForSelector?.length > 0;
    },
    validUsers() {
      return this.users.authorizations
        ?.filter((it) => (it.account?.profile?.__typename === 'CompanyProfile' || it.account?.profile?.__typename === 'IndividualEnterpreneurProfile') && (
          it.type === 'EMPLOYEE_WITH_SIGNING_RIGHTS' || it.type === 'COUNTERPARTY_EMPLOYEE_WITH_SIGNING_RIGHTS')) ?? [];
    },
    validUsersForSelector() {
    return this.validUsers.map((item) => ({
        label: this.getUserTitle(item),
        value: item.user.snils,
      }));
    },
    rightsList() {
      if (!this.formAttorney.snils) {
        return [];
      }
      return this.users.authorizations
        ?.filter((item) => item.user.snils === this.formAttorney.snils)[0]
        ?.role?.permissions
        ?.filter((item) => item.usingOnAttorney)
        ?.map((item) => ({
          label: `${item.code}: ${item.description}`,
          value: item.code,
          description: item.description,
        })) ?? [];
    },
    rightsPlaceholder() {
      return this.rightsListUnselect?.length > 0 ? 'Полномочия сотрудника' : 'Права на подписание не выданы';
    },
    rightsSelectorEnabled() {
      return this.rightsListUnselect?.length > 0;
    },
    rightsListUnselect() {
      return this.rightsList.filter((item) => !this.formAttorney.selectedRights.map((it) => it.value).includes(item.value));
    },
    currentStep() {
      return this.getFormAttorney.currentStep ?? 0;
    },
    steps() {
      return initStepsbyType(this.getFormAttorney.typeFormAttorney);
    },
    startAtBeforeNow() {
      if (this.formAttorney.issueDate || this.isExternalAttorney) {
        return true;
      }
      return !this.formAttorney.startDate || dayjs().startOf('day').diff(dayjs(this.formAttorney.startDate, 'DD.MM.YYYY', true)) <= 0;
    },
    endAtAfterStart() {
      return !this.formAttorney.startDate || !this.formAttorney.endDate || dayjs(this.formAttorney.startDate, 'DD.MM.YYYY', true).diff(dayjs(this.formAttorney.endDate, 'DD.MM.YYYY', true)) <= 0;
    },
    startAtAfterIssue() {
      return !this.formAttorney.issueDate || dayjs(this.formAttorney.issueDate, 'DD.MM.YYYY', true).diff(dayjs(this.formAttorney.startDate, 'DD.MM.YYYY', true)) <= 0;
    },
    issueAtBeforeNow() {
      if (!this.formAttorney.issueDate) {
        return false;
      }

      return dayjs(this.formAttorney.issueDate, 'DD.MM.YYYY', true).diff(dayjs().startOf('day')) <= 0;
    },
    isValid() {
      return this.formAttorney.snils &&
        this.formAttorney.numberAttorney &&
        this.formAttorney.selectedRights.length > 0 &&
        this.formAttorney.startDate &&
        this.formAttorney.endDate &&
        this.startAtBeforeNow &&
        this.endAtAfterStart &&
        this.startAtAfterIssue && this.getAuthorization.authorization.identityDocumentInfo &&
        this.notExistRights.length === 0 &&
        !this.isNotExistUser &&
        ((this.formAttorney.powerOfAttorneyScan.length > 0 &&
          this.formAttorney.issueDate &&
          this.issueAtBeforeNow) || !this.isScanAttorney);
    },
    isNotExistUser() {
      if (!this.formAttorney.snils || this.formAttorney.snils.length === 0) {
        return false;
      }
      return this.validUsersForSelector.filter((user) => user.value === this.formAttorney.snils).length === 0;
    },
    validatedSelectedRights() {
      return this.formAttorney.selectedRights?.map((item) => ({
        ...item,
        isNotExist: this.isNotExist(item),
      })) ?? [];
    },
    notExistRights() {
      return this.validatedSelectedRights.filter((item) => item.isNotExist);
    },
    ...mapState('user', ['data']),
    ...mapGetters('attorney', ['getFormAttorney']),
    ...mapGetters('user', ['getAuthorization']),
  },
  watch: {
    rights() {
      if (this.rights.length > 0) {
        this.formAttorney.selectedRights.push(
          this.rightsList.filter((item) => item.value === this.rights)[0],
        );
        this.rights = '';
      }
    },
    formAttorney: {
      deep: true,
      handler(val) {
        this.updateFormAttorney(
          {
            ...this.getFormAttorney,
            ...val,
            steps: this.initSteps,
          },
        );
      },
    },
  },
  methods: {
    goHome() {
      this.$router.push(this.formAttorney.homeUrl);
    },
    deleteDraft() {
      this.updateFormAttorney({
        homeTitle: this.getFormAttorney.homeTitle,
        homeUrl: this.getFormAttorney.homeUrl,
      });
      this.goHome();
    },
    openStep(index) {
      if (this.formAttorney.currentStep > index - 1 && this.initSteps[index - 1]?.url) {
        this.updateFormAttorney(
          {
            ...this.getFormAttorney,
            currentStep: index - 1,
          },
        );
        this.$router.push(this.initSteps[index - 1].url);
      }
    },
    back() {
      if (!this.initSteps[this.formAttorney.currentStep - 1]?.url) {
        this.goHome();
        return;
      }
      this.openStep(this.formAttorney.currentStep);
    },
    goToPermissionRegistry() {
      this.$router.push({ name: 'cabinet.permissions-registry' });
    },
    async loadData() {
      this.users = await this.getAccountEmployees();

      this.companyId = this.getAuthorization.authorization.account.profile.id;

      this.formAttorney = {
        ...this.formAttorney,
        ...this.getFormAttorney,
      };

      this.possiblePermissions = await this.getPossiblePermissions();
      if (this.$route.query.id && this.formAttorney.sourceDocumentId !== this.$route.query.id) {
        this.formAttorney.sourceDocumentId = this.$route.query.id;
        const document = JSON.parse((await this.loadDocument(this.formAttorney.sourceDocumentId)).json);
        this.setAttorneyFromJson(document);
      }
    },
    setAttorneyFromJson(json) {
      this.formAttorney.typeFormAttorney = json.Документ?.Довер?.СвДоверит?.[0].Доверит?.РосОргДовер?.СвРосОрг ? 'form' : 'upload';
      if (this.formAttorney.typeFormAttorney === 'form') this.setDigitalAttorneyFromJson(json);
      if (this.formAttorney.typeFormAttorney === 'upload') this.setScanAttorneyFromJson(json);
    },
    setDigitalAttorneyFromJson(json) {
      this.formAttorney.snils = json.Документ?.Довер?.СвУпПред?.[0].Пред?.СведФизЛ?.СНИЛС.replace(/[^0-9]/g, '');
      this.formAttorney.numberAttorney = json.Документ?.Довер?.СвДов?.ВнНомДовер;
      this.formAttorney.selectedRights = json.Документ?.Довер?.СвПолн?.МашПолн?.map((it) => {
        const right = this.rightsList.find((rl) => rl.value === it.КодПолн);
        return {
          value: it.КодПолн,
          label: right?.label,
          description: right?.description,
        };
      }).filter((it) => it.label);
      this.formAttorney.startDate = json.Документ?.Довер?.СвДов?.ДатаВыдДовер ? convertDbFormat(getDateAsISOString(json.Документ?.Довер?.СвДов?.ДатаВыдДовер)) : '';
      this.formAttorney.endDate = json.Документ?.Довер?.СвДов?.СрокДейст ? convertDbFormat(getDateAsISOString(json.Документ?.Довер?.СвДов?.СрокДейст)) : '';
    },
    setScanAttorneyFromJson(json) {
      this.formAttorney.userId = json.UserId;
      this.formAttorney.snils = this.validUsers.find((it) => it.user.id === json.UserId).user.snils;
      this.formAttorney.numberAttorney = json.Number;
      this.formAttorney.selectedRights = json.Permissions?.Permission?.map((it) => {
        const right = this.rightsList.find((rl) => rl.value === it);
        return {
          value: it,
          label: right?.label,
          description: right?.description,
        };
      }).filter((it) => it.label);
      this.formAttorney.startDate = json.StartsFrom ? convertDbFormat(getDateAsISOString(json.StartsFrom)) : '';
      this.formAttorney.endDate = json.ExpiresAt ? convertDbFormat(getDateAsISOString(json.ExpiresAt)) : '';
      this.formAttorney.issueDate = json.GrantedAt ? convertDbFormat(getDateAsISOString(json.GrantedAt)) : '';
      this.formAttorney.powerOfAttorneyScan = [json.File];
    },
    getUserTitle(employee) {
      if (!employee) {
        return '';
      }

      if (employee.user.fullName) {
        return `${employee.user.fullName.surname} ${employee.user.fullName.name} ${employee.user.fullName.patronymic ? employee.user.fullName.patronymic : ''}, ${employee.user.email}`;
      }

      return employee.user.email;
    },
    deleteRights(index) {
      if (this.isExternalAttorney) return;
      this.formAttorney.selectedRights.splice(index, 1);
      this.rights = '';
    },
    next() {
      const userName = this.getUserTitle(
        this.users.authorizations.filter((it) => it.user.snils === this.formAttorney.snils)[0],
      );

      this.updateFormAttorney(
        {
          ...this.getFormAttorney,
          steps: this.initSteps,
          currentStep: this.formAttorney.currentStep + 1,
          userName: userName,
        },
      );

      this.$router.push('/cabinet/form-attorney/summary');
    },
    isNotExistForUser(checkItem) {
      return this.rightsList.filter((item) => item.value === checkItem.value).length === 0;
    },
    isNotExist(checkItem) {
      return this.possiblePermissions.filter((item) => (item.permissions.filter((permission) => (permission.code === checkItem.value)).length > 0)).length === 0;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deletePowerOfAttorneyScan() {
      this.formAttorney.powerOfAttorneyScan = [];
    },
    ...mapActions('document', ['loadDocument']),
    ...mapActions('user', ['getAccountEmployees', 'getPossiblePermissions']),
    ...mapActions('attorney', ['updateFormAttorney']),
  },
};
</script>

<style lang="scss" scoped>
.form-attorney {

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #4C4E51;
  }

  &__permission-registry-link {
    text-decoration: underline;
  }

  &__selected-rights-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    max-width: 848px;
  }

  &__selected-rights {
    padding: 5px 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4C4E51;

    background: #E9EBED;
    border: 1px solid #E9EBED;
    border-radius: 16px;

    img {
      height: 10px;
      cursor: pointer;
      margin-left: 8px;
    }

    &--not-exist {
      background-color: #e04646;
    }
  }

  &__block-select-rights {
    position: relative;
  }

  &__label-select-rights {
    position: absolute;
    right: -368px;
    top: 0;
    max-width: 300px;
    padding: 24px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0E0F0F;

    background: #F5F6F6;
    border-radius: 16px;

    @media (max-width: 1400px) {
      position: relative;
      top: 0;
      right: 0;
      width: 848px;
      margin-top: 16px;
    }

    &--not-exist {
      color: #e04646;
    }
  }

  &__dates {
    display: flex;
    gap: 16px;
  }

  &__start-date {
    width: 100%;
  }

  &__retrust {
    display: flex;
    align-items: center;
    width: 397px;
    background: #F5F6F6;
    border-radius: 16px;
    padding: 14px 16px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #4C4E51;
  }

  .action-buttons {
    display: flex;

    button {
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      border-radius: 24px;
      color: #0E0F0F;
      background: #FFFFFF;
      padding: 6px 25px 6px 25px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    .btn-save {
      color: #FFFFFF;
      background: #2F82DF;

      &:hover {
        background: #1f5b9b;
      }

      &:disabled {
        background: #717d8f;
      }
    }

    .btn-delete-draft {
      background-color: red;
      margin-left: 16px;
      color: #FFFFFF;

      &:hover {
        background-color: #831c1c;
      }
    }

    &__action {
      width: 100%;

      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;

        button {
          margin-right: 16px;
        }
      }
    }
  }

  &__date {
    width: 272px;
    margin-top: 24px;
  }

  &__attachment {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #0E0F0F;
    display: flex;
    justify-content: space-between;
  }

  &__attachment-item {
    display: flex;
    align-items: center;
    margin-top: 12px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    color: #0E0F0F
  }

  &__attachment-delete {
    color: #4C4E51;
    display: flex;
    align-items: center;
  }
}

.delete-dialog {
  &__action {
    display: flex;
    margin-top: 64px;

    button {
      width: 50%;
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      color: #FFFFFF;
      background: #71757A;
      padding: 6px 25px 6px 25px;

      &:hover {
        background: #525459;
      }
    }

    .btn-delete-draft {
      background-color: red;

      &:hover {
        background-color: #831c1c;
      }
    }
  }

  &__title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    margin-top: 64px;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

}
</style>
