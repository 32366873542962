<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-text v-if="defect.defect">
            <v-carousel v-if="defect.defect.photos.length" :show-arrows="false" class="mb-2">
              <v-carousel-item v-for="(item, index) in defect.defect.photos" :key="index" :src="item"
                               class="carousel-image"/>
            </v-carousel>
            <text-field
              v-model="defect.defect.adreskaFact.axp.odk.name"
              is-disabled
              title="Наименование ОДК"
            ></text-field>
            <text-field
              v-model="defect.defect.adreskaFact.axp.name"
              is-disabled
              title="Наименование АХП"
            ></text-field>
            <text-field
              v-model="defect.defect.adreskaFact.adreska.name"
              is-disabled
              title="Программа оформления"
            ></text-field>
            <text-field
              v-model="defect.defect.adreskaFact.adreska.address"
              is-disabled
              title="Адрес установки"
            ></text-field>
            <text-field
              v-model="defect.defect.adreskaFact.adreska.backupAddress"
              is-disabled
              title="Резервный адрес установки"
            ></text-field>
            <text-field
              v-model="defect.defect.adreskaFact.id"
              is-disabled
              title="ID ОДК"
            ></text-field>
            <text-field
              v-model="defect.defect.defect.name"
              is-disabled
              title="Замечание"
            ></text-field>
            <text-field
              v-model="defect.defect.defectDescription"
              is-disabled
              title="Описание дефекта"
            ></text-field>
            <text-field
              v-model="defect.defect.status"
              is-disabled
              title="Статус"
            ></text-field>
            <text-field
              v-model="defect.defect.reason"
              is-disabled
              title="Причины неисправности"
            ></text-field>
            <text-field
              v-model="defect.defect.works"
              is-disabled
              title="Выполненные работы"
            ></text-field>
            <div class="mb-2">
              <v-label>Расположение на местности</v-label>
            </div>
            <yandex-map
              ref="customMap"
              :collection-axp="axpItems"
              @tapPlacemark="onTapPlacemark"
            />
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { fetchData } from '@/api/rest/odk/api';
import TextField from '@/components/Processes/components/TextField.vue';
import { workModeList } from '@/components/Processes/list';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import { BASE_URL } from '@/constants/api';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import { mapGetters } from 'vuex';
import { isAccessInspectionOdk } from 'lib/utils/permissions';

export default {
  name: 'DefectData',
  components: {
    YandexMap,
    TextField,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      defect: {},
      axpPhotos: [],
      axpItems: [],
      isDisplayImage: false,
      contractorName: '',
      contractors: [],
      workModeItems: workModeList,
    };
  },
  computed: {
    canInspect() {
      return isAccessInspectionOdk(this.getAuthorization.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization']),
    ...mapGetters('dictionary', ['odkDefectStatusDictionary']),
  },
  methods: {
    async onTapPlacemark(id) {
      return await this.$router.push({
        name: 'cabinet.process.11.5-7',
        params: {
          initAdreskaFactId: id,
        },
      });
    },
    async loadData() {
      try {
        this.loading = true;

        if (!this.$route.query.id) {
          this.$router.back();
          return;
        }

        const responseData = await fetchData('local-repair-summary', 1, 10, undefined, undefined, { id: this.$route.query.id });

        if (!responseData?.data || responseData?.data.length === 0) {
          this.$router.back();
          return;
        }

        this.defect = responseData?.data[0];
        this.defect.defect.status = this.odkDefectStatusDictionary[this.defect.defect.status];
        this.defect.defect.updatedAt = formatDateFromOdkTimestamp(this.defect.defect.updatedAt);
        this.defect.defect.photos = this.defect.defect.photos.map((it) => `${BASE_URL}/api/get/${it.folder}/${it.name}`);
        this.axpItems = [{
          id: this.defect.defect.adreskaFact.id,
          locationLongitude: this.defect.defect.adreskaFact.locationLongitude,
          locationLatitude: this.defect.defect.adreskaFact.locationLatitude,
          balloonContent: {
            title: this.defect.defect.adreskaFact.axp.odk.name,
            buttonLabel: this.canInspect ? 'Осмотреть' : null,
            props: [
              {
                label: 'ID',
                value: this.defect.defect.adreskaFact.id,
              },
              {
                label: 'АХП',
                value: this.defect.defect.adreskaFact.axp.name,
              },
              {
                label: 'Подрядчик',
                value: this.defect.defect.adreskaFact.adreska.contractorName,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(this.defect.defect.adreskaFact.createDate),
              },
            ],
          },
        },
        ];
      } catch (error) {
        console.log('adreskaSummary', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";
@import "@/assets/scss/commonCardStyles";

</style>
