<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">
      <span>Сформированные XML файлы</span>
    </p>

    <p :class="$style.smallTitle">Реестр показаний приборов учёта</p>

    <div :class="$style.attachment">
      <a :class="$style.fileAttachment" :href="getXmlBlobUploadReadings()" download="ElectricityVerificationAct.xml">
        <img alt="" height="80" src="~@/assets/icons/file/attachment.svg" width="80" />
        <span>ElectricityVerificationAct.xml</span>
      </a>
    </div>

    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="xmlSingleReadings"
      @document-signed="onDocumentSigned"
      @close="isVisibleSimpleSignatureModal=false"/>

    <div :class="$style.submit">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit" title="Подписать ПЭП и загрузить" @submit="onSubmit" />
    </div>

  </div>
</template>

<script>
import generateXml from '@/lib/storage/connection-application/data.js';
import HTTPError from 'lib/utils/errors';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import { getCurrentDate } from 'lib/utils/date';
import { SimpleSignatureModal } from 'components/SimpleSignatureModal/index';


export default {
  components: {
    SimpleSignatureModal,
    SubmitTaskDetails,
    HorizontalProgressBar,
  },
  props: {
    value: {
      type: Object,
    },
    xmlType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.xmlSingleReadings = this.generateXml(this.value);
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
      xmlSingleReadings: '',
      isVisibleSimpleSignatureModal: false,
      signedDocument: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getUserType']),
    currentDate() {
       return getCurrentDate('YYYY-MM-DD');
    },
    currentTime() {
      return getCurrentDate('HH:mm:ss');
    },
  },
  methods: {
    generateXml(readings) {
      return generateXml.uploadSingleReadings(readings, this.xmlType, this.currentTime);
    },
    async onSubmit() {
      if (this.signedDocument.length === 0) {
        this.openSignatureModal();
      } else {
        await this.submitSignedDocument();
      }
    },
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    onDocumentSigned(signedDocument) {
      this.signedDocument = signedDocument;
      this.isVisibleSimpleSignatureModal = false;
      this.submitSignedDocument();
    },
    async submitSignedDocument() {
      this.isWaitingSubmit = true;
      try {
        const result = await this.documentUpload({ xml: this.signedDocument });
        if (!result) {
          return;
        }
        setTimeout(() => {
          // TODO: возможно, нужно ждать изменения состояния
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$emit('complete', true);
          this.$emit('close');
        }, 2000);

        this.$emit('on-submit-successful');
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    getXmlBlobUploadReadings() {
      const blob = new Blob([this.xmlSingleReadings], { type: 'text/xml' });
      return window.URL.createObjectURL(blob);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

.submit
  margin-top: 77px

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.errorText
  +small-text
  margin: 4px
  color: red

</style>
