var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":_vm.next}},[_c('div',{class:_vm.$style.container},[_c('div',{ref:"navigationBar",style:({
          position: 'sticky',
          top: '-16px',
          backgroundColor: '#ffffff',
          paddingLeft: '16px',
          zIndex: '100',
          width: _vm.navBarWidth,
          marginLeft: '-16px',
          paddingTop: '16px',
          marginTop: '-16px',
          marginBottom: '-8px',
          paddingBottom: '8px',
          display: 'flex',
          gap: '24px',
        })},[_c('navigation-bar',{attrs:{"current-step":_vm.currentStep,"home":"Платёжные поручения","steps":_vm.steps},on:{"open":_vm.openStep}})],1),_c('div',{ref:"content",class:_vm.$style.content},[_c(_vm.steps[_vm.currentStep].component,{tag:"component",attrs:{"step-number":_vm.steps[_vm.currentStep].stepNumber,"height-navigation-bar":_vm.heightNavigationBar},on:{"close":_vm.close,"complete":_vm.complete,"next":_vm.next}})],1),_c('div',{class:_vm.$style.contentFooter,style:({ position: 'absolute', bottom: '0', width: _vm.footerWidth, })},[_c('v-button',{class:_vm.$style.back,attrs:{"type":"button","variant":"outlined-colored"},on:{"click":_vm.back}},[_c('chevron-left'),_c('span',[_vm._v("Назад")])],1),_c('div',{class:_vm.$style.center},[_vm._v(" ")]),_c('v-button',{class:_vm.$style.next,attrs:{"type":"submit","disabled":_vm.loading}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayNext),expression:"displayNext"}]},[_vm._v("Далее")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.displayNext),expression:"!displayNext"}]},[_vm._v("Создать")]),_c('arrow-right')],1)],1),(_vm.loading)?_c('v-circle-loader'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }