<template>
  <div :style="spacingStyle" class="usage-point-connection">
    <v-search-select v-model="selected" :disabled="disabled" :options="isus" :vid="vid" :required="required" title="ИСУ"/>
  </div>
</template>

<script>
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import { mapActions } from 'vuex';

export default {
  name: 'DataAcquisitionEquipmentIsu',
  components: {
    VSearchSelect,
  },
  props: {
    value: { type: Object, required: true },
    spacing: { type: String, default: '12px' },
    disabled: { type: Boolean, default: false },
    vid: { type: String, default: '' },
    required: { type: Boolean, default: false },
  },
  async created() {
    this.isus = await this.getAllIsu();
    this.isus = this.isus.map((it) => ({
      ...it,
      label: `${it.name} - ${it?.isuProtocol?.name}`,
      value: it.id,
    }));
    this.internalValue = { ...this.value };
  },
  data() {
    return {
      internalValue: {},
      isus: [],
      selected: '',
    };
  },
  computed: {
    spacingStyle() {
      return {
        gap: this.spacing,
      };
    },
  },
  watch: {
    selected(newValue) {
      this.internalValue = this.getModelById(newValue);
    },
    value() {
      this.internalValue = this.value;
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    getModelById(id) {
      return this.isus.find((it) => it.id === id);
    },
    ...mapActions('dataLists', ['getAllIsu']),
  },
};
</script>

<style lang="scss" scoped>
.usage-point-connection {
  display: flex;
  flex-direction: column;
}
</style>
