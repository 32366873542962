<template>
  <div :class="getClassName">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VCol',
  props: {
    grid: { type: Object, default: () => {} },
  },
  data() {
    return {
      className: '',
    };
  },
  computed: {
    getClassName() {
      const gridString = Object.keys(this.grid ?? {}).reduce(
        (acc, cur) => `${acc}col-${cur}-${this.grid[cur]} `,
        '',
      );
      if (gridString.trim().length === 0) {
        return 'col-xs';
      }
      return gridString;
    },
  },
};
</script>
