<template>
  <page title="Плановые часы пиковой нагрузки">
    <v-table-new
      v-model="transformedTariffRate"
      :loading="loading"
      :on-pagination-change="onPaginationChange"
      :on-filter-change="onFilterChange"
      :page-out="tariffRatePage.pageOut"
      :template="template"
      :on-load-all-values="loadAllFiltered"
      excel-report-file-name="Плановые часы пиковой нагрузки"
    />
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
  </page>
</template>

<script>
import { ModalProgressBar, Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import { formatPeriod } from 'lib/utils/date';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'ScheduledPeakHours',
  components: {
    VTableNew,
    Page,
    ModalProgressBar,
  },
  data() {
    return {
      tariffRatePage: {},
      loading: false,
      pagination: initialPagination,
      isModalVisible: false,
      isProcessComplete: false,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Расчётный период',
            key: 'accountingPeriod',
            thStyle: {
              minWidth: '80px',
            },
            filter: { type: 'date', format: 'MM.YYYY', selectType: 'month' },
            sort: {},
          },
          {
            label: 'Начало интервала',
            key: 'intervalStart',
            thStyle: {
              minWidth: '80px',
            },
            filter: { type: 'date', format: 'HH:mm', selectType: 'time' },
            sort: {},
          },
          {
            label: 'Конец интервала',
            key: 'intervalEnd',
            filter: { type: 'date', format: 'HH:mm', selectType: 'time' },
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffRate() {
      return this.tariffRatePage.list ?? [];
    },
    transformedTariffRate() {
      return this.tariffRate.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0 && filterKey === 'accountingPeriod') {
          result[filterKey] = {
            year: filterValue.substr(3, 4),
            month: filterValue.substr(0, 2),
          };
        }

        if (filterValue.length !== 0 && (filterKey === 'intervalStart' || filterKey === 'intervalEnd')) {
          result.timeInterval = {};
          if (this.filter?.intervalStart) result.timeInterval.start = this.filter.intervalStart;
          if (this.filter?.intervalEnd) result.timeInterval.end = this.filter.intervalEnd;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffRatePage = await this.tariffPeakLoadHoursPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffPeakLoadHoursPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffRate = await this.tariffPeakLoadHoursPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffRatePage.pageOut.totalElements,
          },
        });

        if (!tariffRate?.list) {
          return [];
        }

        return tariffRate.list.map(this.transformed);
      } catch (e) {
        console.log('tariffPeakLoadHoursPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        id: it.id,
        accountingPeriod: formatPeriod(it.accountingPeriod.month, it.accountingPeriod.year),
        intervalStart: it.interval?.start,
        intervalEnd: it.interval?.end,
        document: { documentId: it.tariffsOrder?.documentId, name: 'ScheduledPeakHours' },
      };
    },
    ...mapActions('dataLists', ['tariffPeakLoadHoursPaged']),
    ...mapActions('document', ['loadDocument']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
