<template>
  <page title="Отчёты">
    <v-table-new
      v-model="transformedReports"
      :loading="loading"
      :template="template"
      excel-report-file-name="Отчёты"
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  name: 'CabinetReports',
  components: {
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      reports: [],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
          },
          {
            label: 'Наименование',
            key: 'title',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Создан',
            key: 'createdAt',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            key: 'followLink',
            type: 'followLink',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
    transformedReports() {
      return this.reports.map((it) => ({
        ...it,
        createdAt: convertDbDateTimeFormat(it.createdAt),
      }));
    },
  },
  methods: {
    async cellClickHandler(cell) {
      switch (cell.type) {
        case 'followLink':
          await this.downloadReport(cell.row.id, cell.row.title);
      }
    },
    async downloadReport(id, title) {
      this.loading = true;
      try {
        const report = await this.getReportFromServer({ id });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(report);
        downloadLink.download = title;
        downloadLink.click();
      } catch (e) {
        console.log(e);
        await this.setNotification({ message: 'Ошибка при скачивании отчёта' });
      } finally {
        this.loading = false;
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.reports = await this.getReportsFromServer();
      } catch (error) {
        await this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['getReportsFromServer', 'getReportFromServer']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
