<template>
  <page title="Реестр приказов на тарифы">
    <v-table-new
      v-model="transformedTariffOrder"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="tariffOrderPage.pageOut"
      :template="template"
      excel-report-file-name='Реестр приказов на тарифы'
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '../../../components';
import { mapActions, mapGetters } from 'vuex';
import { convertTariffKind } from '../../../lib/utils/formatXmlType.js';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'TariffOrder',
  components: { Page, VTableNew },
  data() {
    return {
      tariffOrderPage: {},
      loading: false,
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Название',
            key: 'tariffOrderName',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата документа',
            key: 'tariffDate',
            thStyle: {
              minWidth: '120px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Тип приказа',
            key: 'kind',
            thStyle: {
              minWidth: '120px',
            },
            sort: { },
            filter: { type: 'select', values: this.tariffOrderList },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffOrders() {
      return this.tariffOrderPage.list ?? [];
    },
    transformedTariffOrder() {
      return this.tariffOrders.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
    ...mapGetters('dataLists', ['tariffOrderList']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffOrderPage = await this.tariffOrdersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffOrdersByFilterPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffOrders = await this.tariffOrdersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffOrderPage.pageOut.totalElements,
          },
        });

        if (!tariffOrders?.list) {
          return [];
        }

        return tariffOrders.list.map(this.transformed);
      } catch (e) {
        console.log('tariffOrdersByFilterPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        tariffOrderName: it.orderName,
        tariffDate: it.docDate,
        kind: convertTariffKind(it.kind),
        document: { documentId: it.documentId, name: 'TariffOrders' },
      };
    },
    ...mapActions('dataLists', ['tariffOrdersByFilterPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped lang="scss">

</style>
