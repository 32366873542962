<template>
  <div
    v-if="!bankApi"
    v-custom-click-outside="clickOutside"
    class="search-select-container"
    @click="showMenuHandler"
  >
    <v-input
      :cinput="cinput"
      :clearable="clearable"
      :disabled="disabled"
      :err="err"
      :err-text="errText"
      :required="required"
      :title="title"
      :value="internalValue"
      :vid="vid"
      v-bind="$props"
      @input="onChange"
    />
    <v-menu
      v-if="showMenu"
      :options="internalOptions"
      :value="internalValue"
      @click="setSelectedOption"
    />
  </div>
  <div v-else class="row">
    <div
      v-custom-click-outside="clickOutside"
      class="col search-select-container"
      :class="isHideBik ? 'col--1of1' : 'col--1of2'"
      @click="showMenuHandler"
    >
      <v-input
        :cinput="cinput"
        :clearable="clearable"
        :disabled="disabled"
        :err="err"
        :err-text="errText"
        :required="required"
        :title="title"
        :value="internalValue"
        :vid="vid"
        v-bind="$props"
        @input="onChange"
      />
      <v-menu
        v-if="showMenu"
        :options="internalOptions"
        :value="internalValue"
        @click="setSelectedOption"
      />
    </div>
    <div v-if="!isHideBik" class="col col--1of2">
      <v-input
        :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
        :required="required"
        :value="bikValue"
        :vid="`БИК_${vid}`"
        disabled
        rules="bik"
        title="БИК"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { VInput, VMenu } from '@/components';
import { findAddress, findBank } from '@/api/rest/dadata/api';

export default {
  name: 'VAddressSearchSelect',
  components: {
    VInput,
    VMenu,
  },
  props: {
    cinput: { type: Boolean, default: false },
    label: { type: String, default: '' },
    clearable: { type: Boolean, default: false },
    value: { type: String, default: '' },
    rules: { type: String, default: '' },
    rulesMessages: {
      type: Object, default: () => {
      },
    },
    mode: { type: String, default: 'passive' },
    vid: { type: String, default: '' },
    validationMount: { type: Boolean, default: false },
    bankApi: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    err: { type: Boolean, default: false },
    errText: { type: String, default: '' },
    title: { type: String, default: '' },
    bik: { type: String, default: '' },
    isShowPostCode: { type: Boolean, default: true },
    isHideBik: { type: Boolean, default: false },
  },
  data() {
    return {
      showMenu: false,
      internalOptions: [],
      selectedOption: this.value,
      internalValue: this.value,
      bikValue: this.bik,
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
      this.selectedOption = this.value;
    },
    bik() {
      this.bikValue = this.bik;
    },
  },
  methods: {
    onChange: debounce(async function(value) {
      this.internalValue = value;
      if (!this.internalValue) {
        this.selectedOption = '';
        this.internalOptions = [];
        if (this.bankApi) this.bikValue = '';
        this.$emit('input', value);
      }
      if (this.internalValue && this.internalValue.trim().length !== 0 && this.internalValue !== this.selectedOption && !this.bankApi) {
        try {
          const data = await findAddress(this.internalValue);

          this.internalOptions = data.suggestions.map((it) => {
            const value = it.data?.postal_code ? `${it.data?.postal_code}, ${it.value}` : it.value;

            return {
              value: value,
              label: value,
              postalCode: it.data?.postal_code,
              address: it.value,
            };
          });
        } catch (e) {
          console.log(e);
        }
      }
      if (this.internalValue && this.internalValue.trim().length !== 0 && this.internalValue !== this.selectedOption && this.bankApi) {
        try {
          const data = await findBank(this.internalValue);
          this.internalOptions = data.suggestions.map((it) => ({
            value: it.value,
            label: it.value,
            bikValue: it.data.bic,
          }));
        } catch (e) {
          console.log(e);
        }
      }
      if (this.internalValue && this.internalValue.length === 0) {
        this.selectedOption = '';
        if (this.bankApi) this.bikValue = '';
        this.$emit('input', '');
      }
    }, 1000),
    showMenuHandler() {
      if (this.disabled) return;
      this.showMenu = true;
    },
    setSelectedOption(value) {
      this.selectedOption = value;
      if (this.bankApi) {
        this.internalValue = value;
        this.bikValue = this.internalOptions.find((it) => it.value === value).bikValue;
        this.$emit('input', value);
        this.$emit('keyup', { value, bik: this.bikValue });
      } else {
        const postCodeValue = this.isShowPostCode ? value : this.internalOptions.find((it) => it.value === value).address;

        this.internalValue = postCodeValue;
        this.$emit('input', postCodeValue);
        this.$emit('keyup', postCodeValue);

        this.$emit('postalCode', this.internalOptions.find((it) => it.value === value).postalCode);
      }
      this.showMenu = false;
    },
    clickOutside() {
      this.showMenu = false;
      if (this.selectedOption && this.selectedOption.length > 0) {
        this.internalValue = this.selectedOption;
      } else {
        this.internalValue = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";

.search-select-container {
  position: relative;
}
</style>
