<template>
  <v-table-new
    :action-block-items="actionBlockItems"
    :template="template"
    :value="transformedData"
    excel-report-file-name="Справочник полномочий. Функциональные блоки"
    table-name="permissionsRegistryFunctionalBlocks"
    @on-sort-column="onSortColumnHandler"
    @on-cell-click="cellClickHandler"
  />
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import VTableNew from 'components/VTableNew/VTable.vue';
import { convertAccountKind } from 'lib/utils/formatXmlType';
import { generateDeleteFunctionalBlockOrder } from 'views/private/CabinetPermissionsRegistry/PermissionsRegistry/xml';

export default {
  components: {
    VTableNew,
  },
  props: {
    isSupport: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKey: '',
      sortDirection: 1,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            type: 'index',
          },
          {
            label: 'Код',
            key: 'code',
            thStyle: {
              minWidth: '60px',
            },
            sort: {
              active: this.sortKey === 'code',
              direction: this.sortDirection,
            },
            filter: {},
          },
          {
            label: 'Описание',
            key: 'description',
            sort: {
              active: this.sortKey === 'description',
              direction: this.sortDirection,
            },
            filter: {},
          },
          {
            label: 'Тип аккаунта',
            key: 'accountKindText',
            sort: {
              active: this.sortKey === 'accountKind',
              direction: this.sortDirection,
            },
          },
          {
            key: 'crossDelete',
            type: 'crossDelete',
            customCheck: (data) => data.permissions?.length > 0 || !this.isSupport,
          },
          {
            key: 'edit',
            type: 'edit',
            customCheck: (data) => data.permissions?.some((permission) => permission.usingOnPlatform) || !this.isSupport,
          },
        ],
      };
    },
    transformedData() {
      const transformedData = this.getFunctionalBlocks.map((block) => ({
        ...block,
        accountKindText: block.accountKind.map((kind) => convertAccountKind(kind)).join(', '),
      }));
      if (this.sortKey.length === 0) {
        return transformedData;
      }

      return transformedData.sort((firstElement, secondElement) => this.compare(firstElement, secondElement));
    },
    functionalBlockTitle() {
      return this.isSupport ? 'Добавить функциональный блок' : 'Отправить заявку на добавление функционального блока';
    },
    permissionTilte() {
      return this.isSupport ? 'Добавить полномочие' : 'Отправить заявку на добавление полномочия';
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: this.functionalBlockTitle,
          method: () => {
            this.$emit('functionalBlockModal');
          },
        },
        {
          id: 2,
          title: this.permissionTilte,
          method: () => {
            this.$emit('permissionModal');
          },
        },
      ];
    },
    ...mapGetters('permissionsRegistry', ['getFunctionalBlocks']),
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    compare(firstElement, secondElement) {
      if (this.sortKey !== 'code') return this.sortDirection * (`${firstElement[this.sortKey]}`).localeCompare(secondElement[this.sortKey]);
      const comparedLetters = this.sortDirection * (`${firstElement[this.sortKey].slice(0, 1)}`).localeCompare(secondElement[this.sortKey].slice(0, 1));
      if (comparedLetters !== 0) return comparedLetters;
      return this.sortDirection * (Number(firstElement[this.sortKey].slice(1)) - Number(secondElement[this.sortKey].slice(1)));
    },
    async submitFunctionalBlock(code) {
      try {
        await this.documentUpload({ xml: generateDeleteFunctionalBlockOrder({ code: code }) });
        await this.loadPermissionsRegistry();
      } catch (e) {
        console.log(e);
      }
    },
    onSortColumnHandler(key) {
      if (this.sortKey === key) {
        this.sortDirection *= -1;
      } else {
        this.sortDirection = 1;
      }
      this.sortKey = key;
    },
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'crossDelete': {
          this.submitFunctionalBlock(cell.row.code);
          break;
        }
        case 'edit': {
          this.$emit('edit', cell.row);
          break;
        }
      }
    },
    ...mapActions('user', ['getAllPermissions']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('permissionsRegistry', ['loadPermissionsRegistry']),
  },
};
</script>

<style lang="scss" scoped>
</style>
