<template>
  <v-app class="vuetify-styles v-application" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Внесение данных о договорах купли-продажи движимого имущества</div>
          </v-card-title>
          <v-card-text>
            <contractor-autocomplete
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-required
              title="Подрядчик"
            />

            <events-autocomplete
              ref="event"
              v-model="eventId"
              :items.sync="events"
              :search.sync="eventName"
              is-required
              title="Мероприятие"
            />

            <autocomplete-find-id
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              :items.sync="adreskas"
              :search.sync="adreskaName"
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
              url-default-data="/adreska/contractor/{0}/event/{1}/top20"
              url-search="/adreska/contractor/{0}/event/{1}/find?name={2}"
            ></autocomplete-find-id>
            <text-field
              ref="contractNumber"
              v-model="contractNumber"
              is-required
              title="Номер договора"
            ></text-field>
            <custom-date-picker
              ref="contractDate"
              v-model="contractDate"
              is-required
              label="Дата договора"
            ></custom-date-picker>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
// import Access from '@/constants/access';
import TextField from '@/components/Processes/components/TextField.vue';
import { checkAccess, D1_A } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import CustomDatePicker from 'components/Processes/components/CustomDatePicker.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {

  components: {
    CustomDatePicker,
    AutocompleteFindId,
    ContractorAutocomplete,
    TextField,
    EventsAutocomplete,
  },

  created() {
    if (!checkAccess(D1_A)) {
      this.onTapBack();
    }
  },
  data: () => ({
    progressValue: 0,

    contractorId: null,
    eventId: null,
    axpId: null,
    adreskaId: null,

    contractNumber: null,
    contractDate: null,

    contractorName: '',
    eventName: '',
    adreskaName: '',
    axpName: '',

    contractors: [],
    adreskas: [],
    events: [],
    axpItems: [],


    loaderDialog: false,
  }),

  computed: {
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisableEvent = this.eventId == null;
      const isDisabledContractDate = this.contractDate == null;
      const isDisabledContractNumber = !this.contractNumber?.length;

      return (
        isDisabledContractor ||
        isDisableEvent ||
        isDisabledContractDate ||
        isDisabledContractNumber
      );
    },
    ...mapGetters('user', ['getToken']),
  },

  methods: {
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        contractorId: this.contractorId,
        eventId: this.eventId,
        adreskaId: this.adreskaId,
        contractNumber: this.contractNumber,
        contractDate: this.contractDate,
      };

      axios
        .post(`${BASE_URL}/bought-construction`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.contractorId = null;
      this.eventId = null;
      this.axpId = null;
      this.adreskaId = null;

      this.contractNumber = null;
      this.contractDate = null;

      this.contractorName = '';
      this.eventName = '';
      this.deliveryContract = '';
      this.axpName = '';
      this.adreskaName = '';

      this.contractors = [];
      this.events = [];
      this.axpItems = [];
      this.adreskas = [];

      this.loaderDialog = false;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

