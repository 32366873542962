import { apolloClient } from '@/api/graphql';
import { ALL_PERMISSIONS } from '@/api/graphql/queries';

async function loadPermissionsRegistry({ rootState, commit }) {
  try {
    const result = await apolloClient.query({
      query: ALL_PERMISSIONS, fetchPolicy: 'no-cache', context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });
    const functionalBlocks = result.data.permissions;
    const permissions = functionalBlocks.filter((block) => block.permissions).map((block) => block.permissions.map((permission) => ({
        ...permission, block: block.code, blockDescription: block.description,
      }))).flat();

    commit('SET_FUNCTIONAL_BLOCKS', functionalBlocks);
    commit('SET_PERMISSIONS', permissions);
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPowerOfAttorneys::catch', error);
    throw error;
  }
}

export default {
  loadPermissionsRegistry,
};
