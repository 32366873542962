<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">
      <span>{{ stepNumber.title() }}</span>
    </p>

    <p :class="$style.smallTitle">Реестр показаний приборов учёта</p>

    <div :class="$style.attachment">
      <a :class="$style.fileAttachment" :href="xmlBlobUploadReadings" download="ElectricityPurchaseReport.xml">
        <img alt="" height="80" src="~@/assets/icons/file/attachment.svg" width="80" />
        <span>ElectricityPurchaseReport.xml</span>
      </a>
    </div>

    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="xmlUploadReadings"
      @close="isVisibleSimpleSignatureModal=false"
      @document-signed="onDocumentSigned" />

    <div :class="$style.submit">
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit" title="Подписать ПЭП и загрузить" @submit="onSubmit" />
    </div>

  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import { mapActions, mapGetters } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import { SimpleSignatureModal } from '@/components';
import dayjs from 'dayjs';

export default {
  name: 'MeasurementsIndicationsUploadMultipleDownloadReport',
  components: {
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
    SimpleSignatureModal,
  },
  props: {
    stepNumber: Object,
    uploadReadings: Object,
  },
  async created() {
    this.xmlBlobUploadReadings = await this.getXmlBlobUploadReadings();
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
      xmlEighteenthForms: '',
      xmlUploadReadings: '',
      xmlBlobUploadReadings: null,
      isError: false,
      blobChanged: false,
      isVisibleSimpleSignatureModal: false,
      signedDocument: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dataLists', [
      'voltageClassList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'accountingMethodList',
    ]),
  },
  methods: {
    async onSubmit() {
      this.blobChanged = false;
      if (this.isError) {
        this.xmlBlobUploadReadings = await this.getXmlBlobUploadReadings();
        this.blobChanged = true;
      }
      if (this.isError) return;
      if (this.blobChanged || this.signedDocument.length === 0) {
        await this.openSignatureModal();
      } else {
        await this.submitSignedDocument();
      }
    },
    async openSignatureModal() {
      const isContractSigned = await this.isTransmissionContractSigned(
        {
          accountingPeriod: {
            year: dayjs(this.uploadReadings.Date, 'YYYY-MM').year(),
            month: dayjs(this.uploadReadings.Date, 'YYYY-MM').month() + 1,
          },
          isByInitiator: true,
        },
      );
      if (isContractSigned) {
        this.isVisibleSimpleSignatureModal = true;
      } else {
        this.setNotification({ message: 'Не найден подписанный и вступивший в силу договор.' });
      }
    },
    onDocumentSigned(signedDocument) {
      this.signedDocument = signedDocument;
      this.isVisibleSimpleSignatureModal = false;
      this.submitSignedDocument();
    },
    async submitSignedDocument() {
      this.isWaitingSubmit = true;
      console.log('xml uploadReadings', this.signedDocument);
      try {
        const result = await this.documentUpload({ xml: this.signedDocument });
        if (result.status === 'ERROR') {
          this.isWaitingSubmit = false;
          this.uploadReadings.documentSubmissionErrors = result.errorInfo;
          this.uploadReadings.currentStep -= 1;
          this.$emit('complete', false);
          return;
        }
        if (!result) {
          this.isWaitingSubmit = false;
          return;
        }
        setTimeout(() => {
          // TODO возможно, нужно ждать изменения состояния
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$emit('complete', true);
          this.$emit('close');
        }, 2000);

      } catch (error) {
        const errorMessage = error.message.split(': ')[2];
        this.setNotification({ message: errorMessage });
        this.isWaitingSubmit = false;
      }
      console.log('xml uploadReadings', this.xmlUploadReadings);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    async setUtilityNameInCase() {
      if (this.uploadReadings.Utility.inCase.fio.name.length > 0 && this.uploadReadings.Utility.inCase.fio.name === this.uploadReadings.Utility.Representative.Name.FirstName &&
        this.uploadReadings.Utility.inCase.fio.surname.length > 0 && this.uploadReadings.Utility.inCase.fio.surname === this.uploadReadings.Utility.Representative.Name.FamilyName
      ) {
        return this.uploadReadings.Utility.inCase.fio;
      }
      const fio = {
        name: this.uploadReadings.Utility.Representative.Name.FirstName,
        surname: this.uploadReadings.Utility.Representative.Name.FamilyName,
        patronymic: this.uploadReadings.Utility.Representative.Name.Patronymic,
      };
      return await this.getPersonInCaseAction({
        fio: fio,
        inCase: 'Nominative',
      });
    },
    async setUtilityPositionInCase() {
      if (this.uploadReadings.Utility.inCase.position.length > 0 && this.uploadReadings.Utility.inCase.position === this.uploadReadings.Utility.Representative.Position) {
        return this.uploadReadings.Utility.inCase.position;
      }
      return await this.getWordInCaseAction({
        word: this.uploadReadings.Utility.Representative.Position.toLowerCase(),
        inCase: 'Nominative',
      });
    },
    async setRetailerNameInCase() {
      if (this.uploadReadings.Retailer.inCase.fio.name.length > 0 && this.uploadReadings.Retailer.inCase.fio.name === this.uploadReadings.Retailer.Representative.Name.FirstName &&
        this.uploadReadings.Retailer.inCase.fio.surname.length > 0 && this.uploadReadings.Retailer.inCase.fio.surname === this.uploadReadings.Retailer.Representative.Name.FamilyName
      ) {
        return this.uploadReadings.Retailer.inCase.fio;
      }
      const fio = {
        name: this.uploadReadings.Retailer.Representative.Name.FirstName,
        surname: this.uploadReadings.Retailer.Representative.Name.FamilyName,
        patronymic: this.uploadReadings.Retailer.Representative.Name.Patronymic,
      };
      return await this.getPersonInCaseAction({
        fio: fio,
        inCase: 'Genitive',
      });
    },
    async setRetailerPositionInCase() {
      if (this.uploadReadings.Retailer.inCase.position.length > 0 && this.uploadReadings.Retailer.inCase.position === this.uploadReadings.Retailer.Representative.Position) {
        return this.uploadReadings.Retailer.inCase.position;
      }
      return await this.getWordInCaseAction({
        word: this.uploadReadings.Retailer.Representative.Position.toLowerCase(),
        inCase: 'Genitive',
      });
    },
    async getXmlBlobUploadReadings() {
      this.isError = false;
      if (this.xmlUploadReadings.length > 0) {
        const blob = new Blob([this.xmlUploadReadings], { type: 'text/xml' });
        return window.URL.createObjectURL(blob);
      }
      try {
        this.isWaitingSubmit = true;
        this.uploadReadings.Utility.inCase.fio = await this.setUtilityNameInCase();
        this.uploadReadings.Utility.inCase.position = await this.setUtilityPositionInCase();
        if (this.uploadReadings.Retailer?.Representative.Name.FirstName.length > 0 && this.uploadReadings.Retailer?.Representative.Name.FamilyName.length > 0) {
          this.uploadReadings.Retailer.inCase.fio = await this.setRetailerNameInCase();
          this.uploadReadings.Retailer.inCase.position = await this.setRetailerPositionInCase();
        }
        this.xmlUploadReadings = generateXml.ElectricityPurchaseReportIndications(
          this.uploadReadings,
          this.uploadReadings.Date,
          this.voltageClassList,
          this.accountingMethodList,
        );

        const blob = new Blob([this.xmlUploadReadings], { type: 'text/xml' });
        return window.URL.createObjectURL(blob);
      } catch (error) {
        this.isError = true;
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('cabinet', ['addTestTask', 'clearTestTask', 'setWorkflows']),
    ...mapActions('document', ['documentUpload', 'getPersonInCaseAction', 'getWordInCaseAction', 'isTransmissionContractSigned']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

.submit
  margin-top: 77px

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.errorText
  +small-text
  margin: 4px
  color: red

</style>
