const SET_DOCUMENT = (state, document) => {
  state.document = document;
};

const SET_KIND = (state, kind) => {
  state.kind = kind;
};

const SET_TITLE = (state, statusTitle) => {
  state.statusTitle = statusTitle;
};

const SET_STATUS = (state, status) => {
  state.status = status;
};

const SET_DOC = (state, idxDoc) => {
  state.idxDoc = idxDoc;
};

const SET_STAT = (state, idxStat) => {
  state.idxStat = idxStat;
};

export default {
  SET_DOCUMENT,
  SET_KIND,
  SET_TITLE,
  SET_STATUS,
  SET_DOC,
  SET_STAT,
};
