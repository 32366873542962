var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-table-new',{attrs:{"loading":_vm.loading,"on-filter-change":_vm.onFilterChange,"on-load-all-values":_vm.loadAllFiltered,"on-pagination-change":_vm.onPaginationChange,"page-out":_vm.data.pageOut,"template":_vm.template,"excel-report-file-name":"Складские операции. Проекты накладных","server-side-sorting":"","table-name":"consignment"},on:{"on-sort":_vm.handleSort,"on-cell-click":_vm.onCellClick},model:{value:(_vm.transformedData),callback:function ($$v) {_vm.transformedData=$$v},expression:"transformedData"}}),_c('v-app',{staticClass:"vuetify-styles",staticStyle:{"width":"100%"},attrs:{"data-app":""}},[_c('consignment',{attrs:{"consignment":_vm.consignment},on:{"updated":_vm.loadData},model:{value:(_vm.consignmentOpened),callback:function ($$v) {_vm.consignmentOpened=$$v},expression:"consignmentOpened"}})],1),(_vm.archiveModal)?_c('modal',{staticClass:"delete-modal",attrs:{"body-style":{
    padding: '24px 0',
  },"header-style":{
    display: 'flex',
    justifyContent: 'center'
  },"is-backdrop":true,"is-btn-close-visible":false,"modal-style":{
    borderRadius: '16px',
    padding: '50px 100px',
  }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"delete-modal__title"},[_vm._v("Подтвердите изменение статуса накладной")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("Накладная будет "+_vm._s(_vm.archiveModalDirection ? 'переведена в архив' : 'убрана из архива'))])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-button',{attrs:{"variant":"outlined-red"},on:{"click":_vm.setArchive}},[_vm._v(" Подтвердить ")]),_c('v-button',{attrs:{"variant":"outlined"},on:{"click":_vm.closeModal}},[_vm._v(" Отменить ")])],1)]},proxy:true}],null,false,120196563)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }