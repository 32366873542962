<template>
  <div class="textarea">
    <textarea
      ref="autoSizeTextArea"
      v-model="currentValue"
      :disabled="disabled"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'AutoTextarea',
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.currentValue = this.value;
  },
  mounted() {
    this.resize();
  },
  data() {
    return {
      currentValue: '',
    };
  },
  watch: {
    currentValue() {
      this.resize();
      this.$emit('input', this.currentValue);
    },
    value() {
      this.currentValue = this.value;
      this.resize();
    },
  },
  methods: {
    resize() {
      const { autoSizeTextArea } = this.$refs;
      autoSizeTextArea.style.height = '18px';
      autoSizeTextArea.style.height = `${autoSizeTextArea.scrollHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  textarea {
    width: 100%;
    border: 0;
    resize: none;
    overflow: hidden;
    padding: 10px;
    background: inherit;
  }
}
</style>
