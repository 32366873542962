import { getSurnameAndInitials } from '@/utils/user';

const isLogged = (state) => !!state.token && state.tokenStatus === 'FULL';
const getError = (state) => state.error;

const getUser = (state) => state.data;
const getAccount = (state) => state.account;
const getAuthorization = (state) => state.authorization;
const getAuthorizationType = (state) => state.authorization?.authorization?.type ?? '';

const getPermissions = (state) => state.authorization.authorization.role.permissions;

const getUserType = (state) => {
  if (!state.account) {
    return;
  }

  // TODO replace kind naming in project
  if (state.account.kind === 'INDIVIDUAL_CONSUMER') {
    return 'Customer';
  }

  if (state.account.kind === 'LEGAL_CONSUMER') {
    return 'Customer';
  }

  if (state.account.kind === 'BOILER') {
    return 'Boiler';
  }

  if (state.account.kind === 'RETAILER') {
    return 'Retailer';
  }

  if (state.account.kind === 'UTILITY') {
    return 'Supplier';
  }

  if (state.account.kind === 'PLATFORM') {
    return 'Platform';
  }

  return state.account.kind;
};

const isCompany = (state) => state.account?.profile?.__typename === 'CompanyProfile';

const isIndividualEnterpreneur = (state) => state.account?.profile?.__typename === 'IndividualEnterpreneurProfile';

const wasShowMicrogenerationHelp = (state) => state.wasShowMicrogenerationHelp;

const getToken = (state) => state.token;

const getTokenStatus = (state) => state.tokenStatus;

const getTokenUsername = (state) => state.tokenUsername;

const haveUserPermissionToDeleteUserFromCompany = (state) => state.authorization.authorization.role.permissions.find((it) => it.userFunctionalBlock === 'USERS' && it.userFunction === 'ADD_USERS_TO_ORGANISATION')?.availableForDeleting;

const getTables = (state) => state.settings.tables;

const getTableFilterByName = (state) => (name, initData) => {
  if (state.settings.tableFilters) {
    return state.settings.tableFilters[name] ?? { ...initData };
  }

  return { ...initData };
};

const getTableHiddenColumns = (state) => (name) => {
  if (state.settings.tableHiddenColumns) {
    return state.settings.tableHiddenColumns[name];
  }
  return undefined;
};

const getUserPowerOfAttorneys = (state) => {
  if (!state?.authorization?.authorization?.powerOfAttorney) {
    return [];
  }

  return state.authorization.authorization.powerOfAttorney;
};

const surnameAndInitials = (state) => {
  const fullName = state.authorization.authorization.user.fullName;
  return getSurnameAndInitials(fullName);
};

const isBoiler = (state) => state.account.kind === 'BOILER';

const isODK = (state) => state.account.kind === 'ODK';

const isPlatformWorker = (state) => state.account?.kind === 'PLATFORM';

export default {
  isLogged,
  getError,
  getUser,
  getUserType,
  getAccount,
  wasShowMicrogenerationHelp,
  isCompany,
  isIndividualEnterpreneur,
  getToken,
  getTokenStatus,
  getTokenUsername,
  getAuthorization,
  getPermissions,
  haveUserPermissionToDeleteUserFromCompany,
  getTables,
  getTableFilterByName,
  getTableHiddenColumns,
  getUserPowerOfAttorneys,
  surnameAndInitials,
  getAuthorizationType,
  isBoiler,
  isODK,
  isPlatformWorker,
};
