<template>
  <div>
    <follow-link />
  </div>
</template>

<script>
import FollowLink from 'atoms/icons/action/FollowLink';

export default {
  name: 'VTableCellFollowLink',
  components: { FollowLink },
};
</script>

<style scoped>

</style>
