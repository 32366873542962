<template>
  <div :class="$style.content">
    <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

    <div v-for="(attachment, indexAttachment) in usefulVacationDocument.attachments" :key="indexAttachment">
      <v-divider :class="$style.divider" />

      <div :key="key" :class="$style.attachment" style="margin-bottom: 32px">
        <a v-if="attachment.attachmentFile" :class="$style.fileAttachment" :download="attachment.attachmentFile.name"
           :href="fileUrl(attachment.attachmentFile)">
          <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
          <span>Файл: {{ attachment.attachmentFile.name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
           @click="deleteFile(indexAttachment)"
        >
          <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24" />
        </a>
      </div>

      <template v-if="attachment.attachmentError.length > 0">
        <div v-if="attachment.attachmentFile">
          <div>
            <p style="color: red; margin-bottom: 32px">Найдено ошибок: {{ attachment.attachmentError.length }}</p>
            <table :class="$style.details">
              <thead>
              <tr>
                <td>Строка</td>
                <td>Столбец</td>
                <td>Описание</td>
              </tr>
              </thead>
              <tbody :class="$style.tableTd">
              <tr v-for="(error, index) in attachment.attachmentError" :key="index">
                <td>{{ rowHandler(error.row) }}</td>
                <td>{{ error.col }}<br />{{ error.title }}</td>
                <td>{{ error.text }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div :key="key" style="margin-top: 24px">
            <div>
              <span
                :class="$style.labelUpload"
                :style="{'color': usefulVacationDocument.attachments_error.error ? 'red': ''}"
              >
                Загрузите исправленные 18-е формы
              </span>
              <c-uploading
                  v-model="filledDocuments"
                  :class="$style.filledDocuments"
                  :error="usefulVacationDocument.attachments_error"
                  accept=".xlsx, .xls"
                  accept-title="XLSX, XLS или перетащите его в эту область."
                  role="filledAttachment"
                  @click="indexUpload(indexAttachment)"
                  @handleDrop="indexUpload(indexAttachment)"
              />
            </div>

            <div :class="$style.attachment" style="margin-bottom: 32px">
              <a :class="$style.fileAttachment" :download="attachment.attachmentFile.name"
                 :href="fileUrl(attachment.attachmentFile)">
                <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
                <span>{{ attachment.attachmentFile.name }}</span>
              </a>
              <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
                 @click="deleteFile(indexAttachment)"
              >
                <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24" />
              </a>
            </div>
          </div>
        </div>

      </template>
      <template v-else>
        <div>
          <p :class="$style.stepTitle" style="color: green">Ошибок не найдено</p>
        </div>
      </template>
    </div>


  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files';
import { isErrorConvert } from 'lib/component/validators';
import { read, utils } from 'xlsx';
import { convertXlsFormat } from 'lib/utils/date';
import { numberConvert } from 'lib/utils/string';
import { nanoid } from 'nanoid';
import { normalizeDiacritics } from '@/utils/string/normalize';
import { VDivider } from '@/components';
import { excelParseWithWorker } from 'utils/excel/index.js';
import {
  eighteenFormExcelCompanyPatternV1,
  eighteenFormExcelCompanyPatternV2,
} from 'views/private/EighteenForms/EighteenFormUpload/patterns/EighteenFormPatterns.js';
import dayjs from 'dayjs';

const attachmentNameCompanyWithoutExtension = '18 формы (юридические лица)';
const attachmentNameIndividualWithoutExtension = '18 формы (физические лица)';

export default {
  name: 'EighteenthForms',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    VDivider,
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorText: 'Поле обязательно для заполнения',
      filledDocuments: [],
      attachmentNameCompanyWithoutExtension: attachmentNameCompanyWithoutExtension,
      attachmentNameCompany: `${attachmentNameCompanyWithoutExtension}.xlsx`,
      attachmentNameIndividualWithoutExtension: attachmentNameIndividualWithoutExtension,
      attachmentNameIndividual: `${attachmentNameIndividualWithoutExtension}.xlsx`,
      indexAttachment: -1,
      key: 1,
    };
  },
  computed: {
    internalUsefulVacationDocument() {
      return this.usefulVacationDocument.attachments[0];
    },
    accountingPeriodYear() {
      return dayjs(this.usefulVacationDocument?.AccountingPeriod, 'YYYY-MM').year();
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'voltageClassList',
      'typeAccountingList',
      'typeAccountingListLong',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'tariffCategoryTypeList',
    ]),
  },
  watch: {
    filledDocuments: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }
        this.isWaitingSubmit = true;
        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          if (this.usefulVacationDocument.ConsumerType === 'Company' &&
            normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attachmentNameCompanyWithoutExtension)) !== -1) {
            const pattern = this.accountingPeriodYear > 2022 ? eighteenFormExcelCompanyPatternV2 : eighteenFormExcelCompanyPatternV1;

            const res = await excelParseWithWorker(
              file.code,
              { ...pattern },
              taskId,
            );
            this.usefulVacationDocument.attachments[this.indexAttachment] = {
              id: nanoid(),
              value: res.data,
              errors: res.errors,
              attachmentFile: file,
              attachmentError: [],
            };
            this.$emit('preValidations');
          } else if (this.usefulVacationDocument.ConsumerType === 'Individual' &&
            normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attachmentNameIndividualWithoutExtension)) !== -1) {
            try {
              const excel = read(file.code, {
                type: 'base64',
                cellText: false,
                cellDates: true,
              });

              const tableArray = utils.sheet_to_json(excel.Sheets[excel.SheetNames[0]], {
                header: 1,
                defval: '',
                raw: false,
                blankrows: false,
                dateNF: 'DD/MM/YYYY',
              });

              const title1 = tableArray.slice(0, 1)[0];

              const titlesHead = [];
              title1.forEach((it, index) => {
                const title = title1[index];

                titlesHead.push({
                  col: index,
                  title: title,
                });
              });

              this.usefulVacationDocument.attachments[this.indexAttachment] = {
                id: nanoid(),
                attachment: tableArray.slice(1, tableArray.length),
                attachmentFile: file,
                attachmentError: [],
                titlesHead: titlesHead,
              };

              this.usefulVacationDocument.attachments[this.indexAttachment].attachment.forEach((item) => {
                item[21] = convertXlsFormat(item[21]);
                item[35] = convertXlsFormat(item[35]);
                item[36] = convertXlsFormat(item[36]);

                item[39] = numberConvert(item[39]);
                item[40] = numberConvert(item[40]);
                item[41] = numberConvert(item[41]);
                item[42] = numberConvert(item[42]);
                item[43] = numberConvert(item[43]);
                item[44] = numberConvert(item[44]);
                item[45] = numberConvert(item[45]);
                item[46] = numberConvert(item[46]);
                item[47] = numberConvert(item[47]);
                item[48] = numberConvert(item[48]);
                item[49] = numberConvert(item[49]);
              });

              this.$emit('preValidations');
            } catch (e) {
              console.log('error XLSX.read', e);
            }
          } else {
            incorrectFiles.push(file.name);
          }
        }

        if (incorrectFiles.length > 0) {
          let attachmentName = '';
          if (this.usefulVacationDocument.ConsumerType === 'Individual') {
            attachmentName = this.attachmentNameIndividual;
          } else {
            attachmentName = this.attachmentNameCompany;
          }
          this.vueShowNotification(
            '',
            `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Не соответсвуют шаблону:</b></p><p>${attachmentName}</p>`,
          );
        }

        this.filledDocuments = [];
        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    rowHandler(row) {
      if (this.accountingPeriodYear > 2022) return row + 2;
      return row;
    },
    addPoint() {
      this.contractData.attachment1.push(
        [],
      );

      this.scrollToEnd();
    },
    scrollToEnd() {
      setTimeout(() => {
        document.querySelector('[role=detailsContent]').scrollTo(0, document.body.scrollHeight);
      }, 200);
    },
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    isErrorConvert(errorArray, indexPoint, indexItem) {
      return isErrorConvert(errorArray, indexPoint, indexItem);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    indexUpload(index) {
      this.indexAttachment = index;
    },
    deleteDoc2(indexAttachment) {
      this.usefulVacationDocument.attachments[indexAttachment].attachmentFile = null;
      this.key++;
    },
    deleteFile(indexAttachment) {
      this.usefulVacationDocument.attachments.splice(indexAttachment, 1);
      this.$emit('preValidations');
    },
  },
};
</script>

<style lang="sass" module>
.fileName
  +base-title
  background-color: darkgray
  padding: 4px
  width: 100%

.smallTitle
  +card-title-small
  margin-top: 32px
  margin-bottom: 4px

.content
  width: 848px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 24px auto 0px

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .labelUpload
    font-size: 16px
    color: #4C4E51

  .divider
    width: 864px
    margin-left: -8px

.form
  position: absolute
  width: 100%
  margin-bottom: 64px
  padding: 0 32px 0 0

  div
    overflow-x: auto

    &::-webkit-scrollbar-button
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 0
      border-radius: 10px
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb:hover
      background-color: #a19f9f

    &::-webkit-resizer
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar
      width: 10px
      height: 10px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment, .fileArchive
  +base-subtitle

.attachment
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.downloadIcon
  display: flex
  align-items: center

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

  color: #4C4E51

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 48px
  margin-top: 32px
  margin-bottom: 48px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF

.space
  opacity: 0

.errorText
  +small-text
  color: red

.textInputTd
  +input-text
  font-family: 'Roboto Condensed'
  color: gray

.textTableTd
  padding: 4px 16px

.tableTd
  td
    min-width: 200px

  td.width220
    min-width: 220px

.endGroup
  min-width: 200px

  textarea
    padding: 8px
    border: none

    &::-webkit-scrollbar
      width: 0
      height: 0

table.details
  width: 100%

  thead
    text-align: center

    td
      +table-text-small
      color: #0F1010
      padding-left: 8px
      padding-right: 24px
      padding-top: 12px
      padding-bottom: 12px
      vertical-align: middle
      border: 1px solid #71757A

    td.firstTitle
      text-align: left
      vertical-align: bottom

    td.noborder
      border: none

  tbody
    td
      border: 1px solid #71757A
      vertical-align: middle

      p
        margin: 4px

      label
        display: none

      input
        border: 0

        &:disabled
          color: #71757A

      select
        width: 100%
        height: 46px
        font-size: 18px
        padding-left: 12px
        border: 0
        outline: none

        &:disabled
          color: #71757A
          opacity: 1

      img
        width: 24px
        height: 24px
        margin-top: 12px
        cursor: pointer

      img.close
        width: 18px
        height: 18px
        margin-left: 8px
        margin-right: 24px

    td.dateField
      input
        width: 140px

      input:disabled
        background-color: #ffffff

    td.field100
      input
        width: 100px

      input:disabled
        background-color: #ffffff

    td.copy
      padding-left: 10px
      border-right: none

    td.delete, td.copy
      border: none

    .footer
      td
        border: none
        height: 22px

  thead
    tr.header
      td
        font-size: 16px
        background-color: #e5e5e5

  tbody > tr > th:first-child,
  tbody > tr > td:first-child
    width: 48px
    height: 48px
    background-color: white
    min-width: 60px

  td
    padding: 8px
    text-align: center
    margin: 0
</style>
