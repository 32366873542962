<template>
  <div
    :class="{
      'modal__wrp_backdrop': isBackdrop,
    }"
    :style="modalWrapperStyle"
    class="modal__wrp"
  >
    <div
      :class="{
        'modal_box-shadow': isModalBoxShadow,
      }"
      :style="innerModalStyle"
      class="modal"
    >
      <header
        :style="headerStyle"
        class="modal-header"
      >
        <slot name="header">
        </slot>
        <button
          v-if="isBtnCloseVisible"
          aria-label="Close modal"
          class="btn-close"
          type="button"
          @click="close"
        >
        </button>
      </header>
      <section
        :style="bodyStyle"
        :class="{
          ['modal-body']: true,
          ['modal-body--scrollY']: isScrollY,
        }"
      >
        <slot name="body">
        </slot>
      </section>
      <footer
        :style="footerStyle"
        class="modal-footer"
      >
        <slot name="footer">
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    width: {
      type: String,
      default: '700px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    modalWrapperStyle: {
      type: Object,
      default: () => {
      },
    },
    modalStyle: {
      type: Object,
      default: () => {
      },
    },
    headerStyle: {
      type: Object,
      default: () => {
      },
    },
    bodyStyle: {
      type: Object,
      default: () => {
      },
    },
    footerStyle: {
      type: Object,
      default: () => {
      },
    },
    isBtnCloseVisible: {
      type: Boolean,
      default: true,
    },
    isBackdrop: {
      type: Boolean,
      default: true,
    },
    isModalBoxShadow: {
      type: Boolean,
      default: false,
    },
    isScrollY: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerModalStyle() {
      const size = {
        width: this.width,
        height: this.height,
      };
      return {
        ...this.modalStyle, ...size,
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &_box-shadow {
    box-shadow: 2px 2px 20px 1px;
  }

  background: #FFFFFF;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  z-index: 10020;
  max-height: 100vh;

  &__wrp {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10030;

    &_backdrop {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &-header {
    min-height: 32px;
    position: relative;
  }

  &-body {
    padding: 20px;

    &--scrollY {
      overflow-y: auto;

      &::-webkit-scrollbar-button {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0;
        border-radius: 10px;
        background-color: #d1d1d1;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a19f9f;
      }

      &::-webkit-resizer {
        background-repeat: no-repeat;
        width: 7px;
        height: 0;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }
    }
  }

  &-footer {
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 18px;
    height: 18px;
    background-image: url('~icons/modal/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
    background-color: #FFFFFF;
  }

}
</style>
