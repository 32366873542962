<template>
  <div
    :class="{ [$style.tab]: true, [$style['tab--active']]: isActive }"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VTab',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.tab {
  padding: 16px;
  cursor: pointer;

  &--active {
    border-bottom: 2px #2f82df solid;
  }

  &:hover {
    background-color: #dee2e6;
  }
}
</style>
