var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{ref:"validationProvider",attrs:{"custom-messages":_vm.rulesMessages,"name":_vm.name,"rules":_vm.rules,"vid":_vm.vid,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticStyle:{"display":"none"},attrs:{"type":"text"},domProps:{"value":(_vm.selectedOption)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedOption=$event.target.value}}}),_c('div',{directives:[{name:"custom-click-outside",rawName:"v-custom-click-outside",value:(_vm.setShowMenu),expression:"setShowMenu"}],class:_vm.$style['select-container'],style:(_vm.customStyle),on:{"click":_vm.displayMenu}},[_c('v-label',{attrs:{"is-error":(errors.length > 0 || _vm.err) && !_vm.disabled,"is-need-mouseover-tooltip":_vm.isNeedMouseoverTooltip,"is-value":_vm.isValue,"label":_vm.labelByRequired,"title":_vm.titleByRequired,"select":""}},[_c('div',{class:{
          [_vm.$style['mt-8']]: _vm.isMarginTopNeeded,
          [_vm.$style['position-relative']]: true,
        }},[(_vm.newStyle)?_c('v-divider',{class:_vm.$style['select-container__divider'],attrs:{"direction":"vertical"}}):_vm._e(),_c('div',{class:{
            [_vm.$style['position-relative']]: true,
          }},[(!_vm.clearable && !_vm.newStyle)?_c('img',{class:_vm.$style['select-container__arrow'],attrs:{"src":require("@/assets/icons/arrows/arrow-down.svg")}}):_vm._e()]),(_vm.newStyle && (!_vm.clearable || _vm.selectedOption?.length === 0))?_c('img',{class:[
              _vm.$style['select-container__arrow'],
              _vm.$style['select-container__arrow--new-style']
            ],attrs:{"src":require("@/assets/icons/arrows/arrow-down-horizontal.svg")}}):_vm._e(),_c('div',{class:{
          [_vm.$style['select-container__select']]: true,
          [_vm.$style['select-container__select--title']]: _vm.title.length > 0,
          [_vm.$style['select-container__select--disabled']]: _vm.disabled,
          [_vm.$style['select-container__select--new-style']]: _vm.newStyle,
          [_vm.$style['input-container__input--clearable']]: _vm.clearable,
          [_vm.$style['select-container__select--placeholder']]: _vm.selectedOption?.length === 0 && _vm.placeholder,
          [_vm.$style['error']]: (errors.length > 0 || _vm.err) && !_vm.disabled,
          [_vm.$style['select-container__select--no-border']]: _vm.noBorder,
          [_vm.$style['select-container__select--blue-border']]: _vm.blueBorder,},attrs:{"tabindex":"0"}},[_vm._t("default"),(_vm.isShowInputImg)?_c('img',{staticStyle:{"padding-right":"6px"},attrs:{"alt":"","src":require("@/assets/icons/read_more.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getLabelBySelectedOption)+" "),_c('div',{class:{
            [_vm.$style['v-clearable-input-wrp']]: true,
          }},[_c('v-clearable-input',{attrs:{"is-show":_vm.isValue && _vm.clearable,"new-style":""},on:{"click":function($event){return _vm.setSelectedOption('')}}})],1)],2)],1)]),(_vm.showMenu)?_c('v-menu',{attrs:{"options":_vm.options},on:{"click":_vm.setSelectedOption}}):_vm._e()],1),(errors.length > 0 && !_vm.disabled)?_c('span',{staticClass:"errorText"},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e(),(_vm.errText !== '' && _vm.err)?_c('span',{staticClass:"errorText"},[_vm._v(" "+_vm._s(_vm.errText)+" ")]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }