<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">Детали задачи</p>

    <p :class="$style.stepDate"><span>Дата создания:</span>{{ task.createDate }}</p>
    <p :class="$style.stepDate"><span>Отправлено на проверку и подписание:</span>{{ task.sendDate }}</p>
    <p :class="$style.stepDate"><span>Загруженный документ:</span><span :class="$style.documentTitle">{{ documentTitle }}</span></p>
    <p v-if="task.closeDate" :class="$style.stepDate"><span :class="$style.title">{{ closeTitle }}</span><span>{{ task.closeDate }}</span></p>

    <p :class="$style.stepStatus"><span :class="$style.title">Статус задачи:</span>
      <span :class="{
          [$style.active]: task.status === 'active',
          [$style.reject]: task.status === 'reject',
          [$style.close]: task.status === 'close',
        }">{{ statusText }}
      </span>
    </p>

    <template v-if="task.rejectComments">
      <p :class="$style.stepTitle">Замечания</p>
      <p :class="$style.rejectComments" v-html="rejectComments"></p>

      <div v-if="task.rejectAttachment" :class="$style.attachment">
        <a href='#' :download='task.rejectAttachment'  :class="$style.fileAttachment">
          <img src="~@/assets/icons/file/pdfIcon.svg" alt="" height="80" width="80"/>
          <span :class="$style.rejectAttachment">{{ task.rejectAttachment }}</span>
        </a>
      </div>
    </template>

    <p :class="$style.stepTitle">Документы</p>

    <p :class="$style.smallTitle">Отчёт о загрузке действующего двухстороннего договора оказания услуг по передаче электроэнергии</p>

    <div :class="$style.attachment">
      <a href='#' download='SP_2S_DPE.pdf'  :class="$style.fileAttachment">
        <img src="~@/assets/icons/file/pdfIcon.svg" alt="" height="80" width="80"/>
        <span>SP_2S_DPE.pdf</span>
      </a>
    </div>

    <p :class="$style.smallTitle">Данные из документов, загружаемых на Платформу</p>

    <div :class="$style.attachment">
      <a href='#' download='DATE_SP_2S_DPE.pdf'  :class="$style.fileAttachment">
        <img src="~@/assets/icons/file/pdfIcon.svg" alt="" height="80" width="80"/>
        <span>DATE_SP_2S_DPE.pdf</span>
      </a>
    </div>

    <p :class="$style.smallTitle">Отсканированные образы документов</p>

    <div :class="$style.attachment">
      <a href='#' download='SCAN_SP_2S_DPE.pdf'  :class="$style.fileAttachment">
        <img src="~@/assets/icons/file/pdfIcon.svg" alt="" height="80" width="80"/>
        <span>SCAN_SP_2S_DPE.pdf</span>
      </a>
    </div>

    <submit-task-details v-if="task.status === 'reject'" :class="$style.submit" title='начать новую задачу по исправлению' @submit="repeatTask" />
    <submit-task-details v-else :class="$style.submit" title='Закрыть' @submit="close" />
  </div>
</template>

<script>

import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import { getCurrentDate } from 'lib/utils/date';
import { mapActions } from 'vuex';

export default {
  name: 'ViewDownloadReport',
  components: {
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
  },
  props: {
    task: Object,
  },
  data() {
    return {
      documentTitles: {
        1: 'Действующий двусторонний договор на оказания услуг по передаче электроэнергии',
        2: 'Трёхсторонний двусторонний договор на оказания услуг по передаче электроэнергии',
        3: 'Дополнительное соглашение к двухстороннему договору на оказания услуг по передаче электроэнергии',
        4: 'Дополнительное соглашение к трёхстороннему договору на оказания услуг по передаче электроэнергии',
      },
    };
  },
  computed: {
    documentTitle() {
      return this.documentTitles[this.task.documentType];
    },
    closeTitle() {
      if (this.task.status === 'reject') {
        return 'Направлены замечания:';
      }
      return 'Закрыта:';
    },
    statusText() {
      if (this.task.status === 'reject') {
        return 'Отклонена';
      }
      if (this.task.status === 'active') {
        return 'На проверке';
      }
      if (this.task.status === 'close') {
        return 'Закрыта';
      }
      return '';
    },
    rejectComments() {
      return formatting.formattedString(this.task.rejectComments, '«', '»');
    },
  },
  methods: {
    fileUrl(file) {
      return getFileUrl(file);
    },
    close() {
      this.$emit('close');
    },
    repeatTask() {
      this.addTestTask(
        {
          props: {
            createDate: getCurrentDate('DD.MM.YYYY'),
          },
          component: 'UploadDocumentCard',
          componentDetails: 'UploadDocumentDetails',
        },
      );
      this.$emit('close');
    },
    ...mapActions('cabinet', ['addTestTask']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 560px
    margin: 0 auto 0
    z-index: 999

    .stepTitle
      +base-title
      margin-top: 32px
      margin-bottom: 32px

      span
        margin-right: 12px
        color: #979CA2

    .smallTitle, .rejectComments
      +card-title-small
      margin-top: 32px
      margin-bottom: 4px

    .rejectComments
      p
        color: #EB5757

    .rejectAttachment
      color: #EB5757

    .stepDate
      +table-data-small
      margin-bottom: 14px

      &:last-child
        margin-bottom: 34px

      span
        +table-text-small
        display: inline-block
        width: 220px
        min-width: 220px

      span.documentTitle
        display: inline-flex
        +table-data-small
        width: 340px
        min-width: 340px

    .stepStatus
      margin-bottom: 55px
      margin-top: 32px

      span.title
        +table-text-small
        display: inline-block
        width: 220px
        min-width: 220px

      span.active, span.reject, span.close
        +title-base-uppercase
        box-sizing: border-box
        border-radius: 4px
        padding: 0 16px

      span.active
          border: 2px solid #2F82DF
          color: #2F82DF

      span.reject
          border: 2px solid #EB5757
          color: #EB5757

      span.close
          border: 2px solid #979CA2
          color: #979CA2

  .submit
    margin-top: 77px

  .attachment
    +base-subtitle
    display: flex
    justify-content: space-between

    span
      text-decoration: underline

  .fileAttachment
    display: flex
    align-items: center
    margin-top: 12px

    img
      width: 40px
      height: 40px
      margin-right: 16px

    color: #0E0F0F

</style>
