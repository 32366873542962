<template>
  <div class="spoiler">
    <details :open="isOpen">
      <summary><b>{{ summary.first }}</b> | {{ summary.second }}</summary>
      <div class="spoiler__details">
        <slot/>
      </div>
    </details>
  </div>
</template>

<script>
export default {
  name: 'VSpoilerDouble',
  props: {
    summary: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
};

</script>

<style lang="scss" scoped>
.spoiler {
  position: relative;

  summary {
    padding: 8px 0 8px 20px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #71757A;

    background: #F5F6F6;
    border-radius: 20px 20px 20px 20px;
  }

  summary:first-letter {
    text-transform: uppercase;
  }

  details > summary {
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none
  }

  summary::after {
    content: url('~icons/spoiler/close.svg');
    position: absolute;
    right: 20px;
  }

  details {
    border: 0;
  }

  details[open] {
    border: 1px solid #F5F6F6;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 20px;

    summary {
      border-radius: 20px 20px 0 0;
    }

    summary:after {
      content: url('~icons/spoiler/open.svg');;
      position: absolute;
      right: 20px;
    }
  }

  &__details {
    margin: 20px 56px 24px 56px;
  }
}
</style>
