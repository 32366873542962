import { apolloClientMessenger } from '@/api/graphql';
import { CREATE_ROOM, CREATE_ROOM_WITH_SUPPORT, SEND_MESSAGE } from '@/api/graphql/mutations';
import { GET_ALL_ROOMS_FOR_USER, GET_MESSAGES, GET_ROOM } from '@/api/graphql/queries';
import { SUBSCRIBE_MESSAGES, SUBSCRIBE_ROOMS } from '@/api/graphql/subscriptions';
import { publicClient } from 'lib/utils/axios';

const BASE_URL_MESSENGER = process.env.VUE_APP_GRAPHQL_HTTP_MESSENGER
  ? process.env.VUE_APP_GRAPHQL_HTTP_MESSENGER.replace('/graphql', '')
  : `https://${window.location.hostname}/messenger`;

// eslint-disable-next-line no-unused-vars
async function createRoom({ rootState, commit }, payload) {
  try {
    const { data: { createRoom } } = await apolloClientMessenger.mutate({
      mutation: CREATE_ROOM,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    });
    console.log('room', createRoom);

    return createRoom;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function createRoomWithSupport({ rootState, commit }, payload) {
  try {
    const { data: { createRoomWithSupport } } = await apolloClientMessenger.mutate({
      mutation: CREATE_ROOM_WITH_SUPPORT,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    });
    console.log('room', createRoomWithSupport);

    return createRoomWithSupport;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function sendMessage({ rootState, commit }, payload) {
  try {
    const { data: { send } } = await apolloClientMessenger.mutate({
      mutation: SEND_MESSAGE,
      variables: {
        data: {
          ...payload,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return send;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

async function showAllRoomsForUser() {
  try {
    const { data: { roomsForUserId } } = await apolloClientMessenger.query({
      query: GET_ALL_ROOMS_FOR_USER,
      fetchPolicy: 'no-cache',
    });
    console.log('roomsForUserId', roomsForUserId);

    return roomsForUserId;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function showRoom({ rootState, commit }, payload) {
  try {
    const { data: { room } } = await apolloClientMessenger.query({
      query: GET_ROOM,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    });
    console.log('room', room);

    return room;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
async function getMessages({ rootState, commit }, payload) {
  try {
    const { data: { messages } } = await apolloClientMessenger.query({
      query: GET_MESSAGES,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    });
    console.log('messages', messages);

    return messages;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

async function receiveRooms({ rootGetters, commit }) {
  try {
    return await apolloClientMessenger.subscribe({
      query: SUBSCRIBE_ROOMS,
      variables: {
        userId: rootGetters['user/getUser'].id,
      },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

async function receiveMessages({ rootGetters, commit }, payload) {
  try {
    return await apolloClientMessenger.subscribe({
      query: SUBSCRIBE_MESSAGES,
      variables: {
        userId: rootGetters['user/getUser'].id,
        roomId: payload.roomId,
      },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

async function sendAttachment({ rootState, commit }, payload) {
  try {
    const { files, roomId, messageId } = payload;

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file.blob, file.name);
    });

    await publicClient.post(`${BASE_URL_MESSENGER}/file/upload/${roomId}/${messageId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Authorization': `Bearer ${rootState.user.token}`,
      },
    });
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

async function downloadFile({ rootState, commit }, payload) {
  try {
    const { roomId, fileId } = payload;

    return await publicClient.get(`${BASE_URL_MESSENGER}/file/${roomId}/${fileId}`, {
      responseType: 'blob',
      headers: {
        'X-Authorization': `Bearer ${rootState.user.token}`,
      },
    });
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error);
    throw error;
  }
}

export default {
  showAllRoomsForUser,
  showRoom,
  createRoom,
  createRoomWithSupport,
  receiveRooms,
  receiveMessages,
  sendMessage,
  getMessages,
  sendAttachment,
  downloadFile,
};
