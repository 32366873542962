export async function mapFilters(filterMapping, filterValues, filterEndMapping, filterEndValues) {
  return {
    ...await map(filterMapping, filterValues),
    ...await map(filterEndMapping, filterEndValues),
  };
}

async function map(mapping, values) {
  const result = {};
  if (!values || !mapping) return {};
  for (const filterKey of Object.keys(values)) {
    const filterValue = values[filterKey];
    const filterMapping = mapping[filterKey] ?? { name: filterKey };
    if (filterValue && filterValue.length !== 0) {
      try {
        result[filterMapping.name] = filterMapping.mapping ? await Promise.resolve(filterMapping.mapping(filterValue)) : filterValue;
      } catch (e) {
        console.log(e);
      }
    }
  }
  return result;
}
