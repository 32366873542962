<template>
  <div class="cabinet-consumers-card">

    <div class="cabinet-consumers-card__divider">
      <v-divider/>
    </div>

    <data-tab-selector
        :data-types-list="transformedDataTypesListWithActiveSelected"
        class="cabinet-consumers-card__select"
        @selectTab="selectTabHandler"
    />

    <div :class="dataClass">
      <div v-if="selectedTab === 'personal'">
        <cabinet-personal-data-form :user-id="$route.params.id" :account-id="$route.params.accountId"/>
      </div>
      <div v-if="selectedTab === 'attorneys'">
        <cabinet-user-attorneys :user-id="$route.params.id" :account-id="$route.params.accountId"/>
      </div>
    </div>

  </div>
</template>

<script>
import VDivider from 'components/VDivider/VDivider';

import { DataTabSelector } from '@/components';

import CabinetPersonalDataForm from './CabinetUserCard/CabinetPersonalDataForm.vue';
import { mapGetters } from 'vuex';
import CabinetUserAttorneys from 'views/private/CabinetUserDetails/CabinetUserCard/CabinetUserAttorneys.vue';


export default {
  components: {
    CabinetUserAttorneys,
    DataTabSelector,
    VDivider,
    CabinetPersonalDataForm,
  },
  data() {
    return {
      selectedTab: 'personal',
    };
  },
  computed: {
    transformedDataTypesList() {
      if ((this.getAuthorizationType.length === 0 ||
          (this.getAuthorizationType !== 'EMPLOYEE_WITH_SIGNING_RIGHTS' &&
              this.getAuthorizationType !== 'REPRESENTATIVE_OF_INDIVIDUAL' &&
              this.getAuthorizationType !== 'REPRESENTATIVE_OF_LEGAL_WITH_SIMPLE_SIGNATURE')) && !this.$route.params.id
      ) {
        return this.dataTypesList.filter((it) => it.value !== 'attorneys');
      }
      return this.dataTypesList;

    },
    transformedDataTypesListWithActiveSelected() {
      return this.transformedDataTypesList.map((it) => ({
        ...it,
        isActive: it.value === this.selectedTab,
      }));
    },
    dataClass() {
      if (this.selectedTab === 'attorneys') return 'cabinet-consumers-card__data-table';
      return 'cabinet-consumers-card__data';
    },
    personalTabTitle() {
      return this.getAuthorizationType.indexOf('EMPLOYEE') !== -1 || this.getAuthorizationType.indexOf('GENERAL_DIRECTOR') !== -1
          ? 'Данные сотрудника' : 'Личные данные';
    },
    dataTypesList() {
      return [
        {
          id: 1,
          title: this.personalTabTitle,
          value: 'personal',
          isActive: true,
        },
        {
          id: 2,
          title: this.$route.params.id ? 'Доверенности' : 'Мои доверенности',
          value: 'attorneys',
          isActive: false,
        },
      ];
    },
    ...mapGetters('user', ['getUserType', 'getAuthorizationType']),
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    goToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-consumers-card {
  font-family: 'Roboto';

  &__navigation {
    display: flex;

    &-btn-back {
      width: 86px;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #677379;

      background-image: url('~icons/navigation/arrow_left.svg');
      background-repeat: no-repeat;
      background-position: center left 4px;
      background-color: #FFFFFF;
      border: 1px solid #C1C4C7;
      border-radius: 24px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    &-bar {
      margin-left: 19px;
      width: 300px;
    }
  }

  &__divider {
    margin-top: 8px;
  }

  &__select {
    margin-top: 27px;
    color: #000000;

    &:disabled {
      color: #000000;
    }
  }


  &__data {
    margin: 56px 0 0 50px;
    max-width: 1700px;


    &-table {
      margin: 24px 20px 0 8px;
      max-width: 1700px;
    }

    @media (max-width: 1000px) {
      margin: 56px 0 0 0;
      width: 100%;
    }
  }
}
</style>

