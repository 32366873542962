<template>
  <div :class="$style['dialog-footer']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VDialogFooter',
};
</script>

<style lang="scss" module>
.dialog-footer{
}
</style>
