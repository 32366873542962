const SET_POWER_OF_ATTORNEYS = (state, powerOfAttorneys) => {
  state.powerOfAttorneys = powerOfAttorneys;
};

const SET_FORM_ATTORNEY = (state, formAttorney) => {
  state.formAttorney = formAttorney;
  localStorage.setItem('formAttorney', JSON.stringify(state.formAttorney));
};

const SET_FILTER = (state, filter) => {
  state.filter = filter;
};

export default {
  SET_POWER_OF_ATTORNEYS,
  SET_FORM_ATTORNEY,
  SET_FILTER,
};
