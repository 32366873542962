<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
        <span>Максимальная мощность энергопринимающих устройств и напряжение</span>
      </h2>
    </div>
    <div :class="{ [$style.disclaimerGroup]: true}">
      <div
        :class="{
              [$style.disclaimer]: true,
            }"
      >
        <div :class="$style.gridFluid">
          <p :class="$style.smallText">15 кВт мощности при трёхфазной нагрузке хватает для удовлетворения нужд дома,
            даже если отопление дома, горячее водоснабжение и приготовление пищи осуществляется с помощью
            электричества, а также для подключения дополнительных электроприборов и инструментов.</p>
          <v-divider style="margin: 20px 0; height: 1px"/>
          <p :class="$style.smallText">9 кВт мощности при однофазной нагрузке хватает для удовлетворения основных
            бытовых нужд в частном газифицированном доме. Если отопление дома, горячее водоснабжение и приготовление
            пищи осуществляется с помощью электричества, то данной мощности «впритык».</p>
          <p :class="$style.smallText">При выборе параметров подключения учитывайте физические возможности электросетей.
            Нестандартные варианты подключения могут увеличить стоимость и время выполнения заявки.</p>
        </div>
        <div :class="$style.grid2Column">
          <div :class="$style.smallText">
            <p>Холодильник – 0,5 кВт</p>
            <p>Телевизор — 0,2 кВт</p>
            <p>Электроплита — 2,5 кВт</p>
            <p>Духовка — 1,0-2,0 кВт</p>
            <p>Стиральная машина — 0,7 кВт</p>
            <p>Электрочайник — 1,2 кВт</p>
            <p>Бойлер для тёплой воды — 1,0-2,0 кВт</p>
          </div>
          <div :class="$style.smallText">
            <p>Насос в колодец — 0,5-1,0 кВт</p>
            <p>Освещение — 0,5 кВт</p>
            <p>Компьютер — 0,4 кВт</p>
            <p>Пылесос — 1,5-2,0 кВт</p>
            <p>Микроволновка — 1,5-2,0 кВт</p>
            <p>Другие электроприборы</p>
            <p>и электроинструменты — от 0,5 кВт</p>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in connectionCharacteristics.maximumPowerVoltage" :key="index">
        <hr v-if="index > 0">
        <p :class="$style.textHelper">
          <span>Категория надежности</span>
        </p>

        <v-input
          :value="item.reliabilityClass.value"
          disabled
        />
        <p></p>
        <div :class="$style.grid2Column">
          <c-input
            v-model="item.maxPowerConnection"
            class="numberInput"
            type="number"
            :error="errors.maxPowerConnection[index]"
            error-text="Поле обязательно для заполнения и должно быть больше 0"
            label="Максимальная мощность присоединяемых устройств, кВт *"
            :role="`maxPowerConnection${index}`"
            @input="() => updateItem(item)"
          />
          <div>
            <p :class="$style.textHelper">
              <span>Фазное напряжение *</span>
              <tooltip-helper offset="6px" placement="top">
                <div :class="$style.tooltip" style="width: 320px;">
                  <p>220 В (однофазное подключение) и 380 В (трёхфазное подключение)</p>
                </div>
              </tooltip-helper>
            </p>
            <c-select
              v-model="item.currentVoltage"
              :error="errors.currentVoltage[index]"
              :options="availablePhaseVoltageList"
              :role="`currentVoltage${index}`"
              @input="() => updateItem(item)"
            />
            <div :class="$style.noteContainer">
              <br>
              <p v-if="item.currentVoltage.value === 220">В розетке 220 В</p>
              <p v-if="item.currentVoltage.value === 380">Потребует установки трехфазного счетчика и
                вводного автомата</p>
              <p v-if="item.currentVoltage.value === 6000">Потребует установки трехфазного счетчика и
                вводного автомата и дополнительных затрат на установку и обслуживание трансформатора</p>
              <p v-if="item.currentVoltage.value === 10000">Потребует установки трехфазного счетчика и
                вводного автомата и дополнительных затрат на установку и обслуживание трансформатора</p>
              <p v-if="item.currentVoltage.value === 20000">Потребует установки трехфазного счетчика и
                вводного автомата и дополнительных затрат на установку и обслуживание трансформатора</p>
            </div>
          </div>
        </div>
        <div :class="$style.grid2Column">
          <c-input
            v-model="item.previousMaxPower"
            class="numberInput"
            :native-disabled="true"
            type="number"
            label="Максимальная мощность ранее присоединяемых устройств, кВт *"
            role="previousMaxPower"
            @input="() => updateItem(item)"
          />
          <c-input
            v-model="item.previousPhaseVoltage"
            class="numberInput"
            :native-disabled="true"
            type="number"
            label="Напряжение ранее присоединяемых устройств *"
            role="previousPhaseVoltage"
            @input="() => updateItem(item)"
          />
        </div>
        <div :class="$style.grid2Column">
          <c-input
            v-model="item.summaryMaxPower"
            label="Максимальная мощность присоединенных и ранее присоединяемых устройств, кВт"
            role="summaryMaxPowerConnection"
            class="numberInput"
            type="number"
            :native-disabled="true"
            :error="errors.summaryMaxPowerConnection"
            :error-text="summaryErrorText"
          />
          <div :class="$style.summaryPhaseTitle">
            Напряжение присоединенных и ранее присоединяемых устройств
            <div :class="$style.summaryPhaseValue">
              {{ item.summaryPhaseValue?.label ?? '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StepCounter from '../components/StepCounter';
import { VInput } from 'components';
import TooltipHelper from 'molecules/tooltip/TooltipHelper.vue';

export default {
  name: 'MaximumPowerVoltage',
  components: {
    TooltipHelper,
    VInput,
    CSelect: () => import('atoms/common/CSelect'),
    CInput: () => import('atoms/common/inputs/CInput'),
    StepCounter,
  },
  props: {
    connectionApplicationType: {
      type: String,
      required: true,
    },
    connectionCharacteristics: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    reasonToConnection: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.updateLists();
  },
  data() {
    return {
      summaryPhaseVoltage: null,
      phaseVoltageListItem: {},
      summaryPhaseVoltageListItem: {},
    };
  },

  computed: {
    actualReliabilityClassList() {
      switch (this.connectionCharacteristics.reliabilityClass.value) {
        case 'I':
          return this.reliabilityClassList;
        case 'II':
          return this.reliabilityClassMiddleList;
        default:
          return this.reliabilityClassShortList;
      }
    },
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    summaryErrorText() {
      switch (this.connectionApplicationType) {
        case 'INDIVIDUALS_UP_TO_15':
          return 'Сумма по всем категориям надежности не должна превышать 15 кВт';
        case  'LEGAL_ENTITIES_UP_TO_150':
          return 'Сумма по всем категориям надежности не должна превышать 150 кВт';
        case  'LEGAL_ENTITIES_OVER_150':
          return 'Сумма по всем категориям надежности должна быть выше 150 кВт';
        default:
          return '';
      }
    },
    availablePhaseVoltageList() {
      if (this.connectionApplicationType === 'POWER_REDUCTION') {
        return this.phaseVoltageList.filter((item) => item.value <= this.maximumPowerVoltage.previousPhaseVoltage);
      }

      if (this.reasonToConnection.value === 'POWER_INCREASE') {
        return this.phaseVoltageList.filter((item) => item.value >= this.maximumPowerVoltage.previousPhaseVoltage);
      }

      return this.phaseVoltageList;
    },
    ...mapGetters('dataLists', ['confirmationDocumentTitleList', 'phaseVoltageList', 'reliabilityClassShortList', 'reliabilityClassMiddleList', 'reliabilityClassList']),
  },
  watch: {
    reasonToConnection: {
      immediate: true,
      handler(val) {
        if (val.value === 'POWER_INCREASE' || this.connectionApplicationType === 'POWER_REDUCTION') {
          // TODO: hardcode previousPhaseVoltage
          this.connectionCharacteristics.maximumPowerVoltage.forEach((item) => {
            item.previousPhaseVoltage = '0';
            item.previousMaxPower = '0';
          });
          return;
        }
        this.connectionCharacteristics.maximumPowerVoltage.forEach((item) => {
          item.previousPhaseVoltage = '0';
          item.previousMaxPower = '0';
        });
      },
    },
    'connectionCharacteristics.reliabilityClass': {
      immediate: true,
      handler() {
        this.updateLists();
      },
    },
  },
  methods: {
    updateLists() {
      this.connectionCharacteristics.maximumPowerVoltage = this.connectionCharacteristics.maximumPowerVoltage.filter((it) => this.actualReliabilityClassList.some((reliability) => reliability.value === it.reliabilityClass.value));
      this.actualReliabilityClassList.forEach((reliability) => {
        if (!this.connectionCharacteristics.maximumPowerVoltage.some((it) => it.reliabilityClass.value === reliability.value)) {
          this.connectionCharacteristics.maximumPowerVoltage.push({
            reliabilityClass: reliability,
            previousPhaseVoltage: '0',
            previousMaxPower: '0',
            maxPowerConnection: '',
            currentVoltage: { value: '', label: '' },
          });
        }
      });
    },
    updateItem(item) {
      let parsedCurrentPower = Number(item.maxPowerConnection) ?? 0;
      if (isNaN(parsedCurrentPower)) parsedCurrentPower = 0;
      let previousMaxPower = Number(item.previousMaxPower) ?? 0;
      if (isNaN(previousMaxPower)) previousMaxPower = 0;
      item.summaryMaxPower = parsedCurrentPower + previousMaxPower;
      // TODO обновлять потом
      item.summaryPhaseValue = item.currentVoltage;
    },
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.titleContainer
  +base-spacing

.title
  display: flex

.noteContainer
  +base-spacing

  p
    +small-spacing
    +small-text

  a
    +base-link

.grid2Column
  +grid-2-column

.numberInput input[type='number']
  appearance: none
  -moz-appearance: textfield

  &:hover, &:focus
    appearance: none
    -moz-appearance: textfield

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
  -webkit-appearance: none

.textHelper
  +base-text
  color: #141412
  display: flex

  span
    margin-right: 10px

.gridFluid
  +grid-fluid

.textDescription
  p
    +base-text

  a
    +base-link

.dateLabel
  margin-bottom: 8px
  font-size: 16px
  line-height: 24px

.summaryPhaseTitle
  font-weight: 400
  font-size: 16px
  line-height: 24px
  margin-bottom: 8px
  color: #141412

.summaryPhaseValue
  font-weight: 400
  font-size: 21px
  line-height: 30px
  text-align: left
  font-family: 'Roboto Condensed'
  color: #4F4F4F
  padding: 4px 16px
  margin-top: 8px
  border: 1px solid #141412;
  height: 70px

.disclaimerGroup
  position: relative

  .disclaimer
    position: absolute
    top: 0
    width: 260px
    right: -304px

@media (max-width: 1480px)
  .disclaimerGroup
    .disclaimer
      position: relative
      width: 100%
      right: 0

.smallText
  +small-text

  p
    +small-text
</style>
