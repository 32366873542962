<template>
  <div>
    <content-block-integral-acts
      :acts="acts"
      :loading="loading"
      :on-pagination-change="onPaginationChange"
      :pagination="pagination"
      :signed-indicator="signedIndicator"
      :status-options="statusOptions"
      :suppliers-options="suppliersOptions"
    />
  </div>
</template>

<script>
import ContentBlockIntegralActs from './ContentBlockIntegralActs';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

const initialFilters = {
  name: '',
  billingPeriod: '',
  tco: '',
  actStatus: '',
  tcoActStatus: '',
};

export default {
  name: 'IntegralActs',
  components: {
    ContentBlockIntegralActs,
  },
  props: {
    isCreateIntegralActsClick: { type: Boolean, required: true },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      signedIndicator: false,
      acts: [],
      actsExternal: [],
      suppliers: [],
      filters: this.initialFilters(),
      pagination: {
        totalPages: 0,
        totalElements: 0,
        page: 0,
        size: 10,
      },
    };
  },
  computed: {
    suppliersOptions() {
      return this.suppliers.map((it) => ({ value: it.name, label: it.name }));
    },
    statusOptions() {
      return [
        { value: 'DRAFT', label: 'На согласовании' },
        { value: 'APPROVED', label: 'Согласован' },
        { value: 'SIGNED', label: 'Подписан' },
        { value: 'DELETED', label: 'Отзыв' },
        { value: 'NULLIFY', label: 'Аннулирован' },
        { value: 'REJECTED', label: 'Отклонён' },
        { value: 'COMPANY_NOT_ON_PLATFORM', label: 'Компания не найдена' },
        { value: 'NOT_SEND', label: 'Не отправлен' },
        { value: 'WAITING_VERIFICATION_ACT', label: 'Ожидает формирования акта верификации' },
        { value: 'NOT_SIGNING', label: 'Не подписывает' },
      ];
    },
    isFiltersActive() {
      return this.filters.name?.length > 0 ||
        this.filters.billingPeriod?.length > 0 ||
        this.filters.tco?.length > 0 ||
        this.filters.actStatus?.length > 0 ||
        this.filters.tcoActStatus?.length > 0;
    },
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    async isCreateIntegralActsClick() {
      if (this.isCreateIntegralActsClick) {
        const actsCount = this.acts.length;

        await this.loadData();

        if (actsCount === this.acts.length) {
          setTimeout(async () => {
            await this.loadData();
          }, 1000);
        }
      }
    },
  },
  methods: {
    onPaginationChange(newPagination) {
      this.pagination = { ...this.pagination, ...newPagination };
      this.onSearch();
    },
    onSearchClient() {
      this.acts = this.actsExternal
        .filter((act) => (this.filters.name.length > 0
            ? act.title.toUpperCase().trim().match(this.filters.name.toUpperCase())
            : true))
        .filter((act) => (this.filters.billingPeriod.length > 0
            ? JSON.stringify({
            year: act.accountingPeriod.year,
            month: act.accountingPeriod.month,
          }) === JSON.stringify({
            year: dayjs(this.filters.billingPeriod, 'MMMM | YYYY').year(),
            month: dayjs(this.filters.billingPeriod, 'MMMM | YYYY').month() + 1,
          })
            : true))
        .filter((act) => (this.filters.actStatus.length > 0
            ? act.sideSecond.status.toUpperCase().trim().match(this.filters.actStatus.toUpperCase())
            : true))
        .filter((act) => (this.filters.tcoActStatus.length > 0
            ? act.sideThird.status.toUpperCase().trim().match(this.filters.tcoActStatus.toUpperCase())
            : true))
        .filter((act) => (this.filters.tco.length > 0
            ? act.sideSecond.name.toUpperCase() === this.filters.tco.toUpperCase() || act.sideThird.name.toUpperCase() === this.filters.tco.toUpperCase()
            : true));
    },
    async onSearchServer() {
      try {
        const filter = {
          title: this.filters.name.length > 0 ? this.filters.name : null,
          accountingPeriod:
            this.filters.billingPeriod.length > 0
              ? {
                year: dayjs(this.filters.billingPeriod, 'MMMM | YYYY').year(),
                month:
                  dayjs(this.filters.billingPeriod, 'MMMM | YYYY').month() +
                  1,
              }
              : null,
          statusByCurrentCompany:
            this.filters.actStatus.length > 0 ? this.filters.actStatus : null,
          statusByAdjacentUtility:
            this.filters.tcoActStatus.length > 0
              ? this.filters.tcoActStatus
              : null,
          counterparties: this.filters.tco.length > 0 ? this.filters.tco : null,
        };
        const data = await this.getIntegralActs({
          ...this.pagination,
          filter: filter,
        });
        this.setFromData(data);
      } catch (e) {
        console.log('onClear::getIntegralActs', e);
      }
    },
    async onClear() {
      this.filters = { ...initialFilters };
      try {
        const data = await this.getIntegralActs({
          page: 0,
          size: 10,
          filter: {},
        });
        this.setFromData(data);
      } catch (e) {
        console.log('onSearch::getIntegralActs', e);
      }
    },
    async loadData() {
      try {
        this.loading = true;

        const data = await this.getIntegralActs({});
        this.setFromData(data);
        this.suppliers = await this.getSuppliers();
        this.$emit('uploaded');

        if (this.isFiltersActive) {
          this.onSearchClient();
        }
      } catch (error) {
        console.log('filterDocumentViews', error);
        this.setNotification({ message: 'Нет связи с сервером' });
        this.$emit('uploaded');
      } finally {
        this.loading = false;
      }
    },
    setFromData(data) {
      this.actsExternal = data;
      this.acts = this.actsExternal;
      if (data && data.length > 0 && !this.signedIndicator && data.some((it) => {
        const currentSide = [it.sideSecond, it.sideThird].find((side) => side.accountId = this.getAccount.id);
        return currentSide && ['WAITING_VERIFICATION_ACT', 'DRAFT', 'APPROVED'].some((status) => status === currentSide.status);
      })) {
        this.signedIndicator = data.documentsSignedIndicator;
        this.vueShowNotification(
          'Не подписаны интегральные акты',
          'АСППУ и АПОПЭЭ подписываются парами по каждому участку сети. Для более удобного подписания АСППУ и АПОПЭЭ сгруппированы попарно.',
        );
      }

      this.signedIndicator = data.documentsSignedIndicator;
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: parseInt(String(data.totalElements)),
        page: data.page,
        size: data.size,
      };
    },
    initialFilters() {
      return this.getTableFilterByName()(this.$route.name, initialFilters);
    },
    ...mapActions('document', ['getIntegralActs']),
    ...mapActions('dataLists', ['getSuppliers']),
    ...mapActions('user', ['setNotification']),
    ...mapGetters('user', ['getTableFilterByName']),
  },
};
</script>

<style lang="scss" module>
.divider {
  margin: 24px 0;
}

.button {
  margin: 24px 0;
}
</style>
