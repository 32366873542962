<template>
  <div ref="treeChart" class="tree-chart">
    <div v-if="zoom === undefined" class="tree-chart__zoom">
      <div class="tree-chart__zoom-plus" @click="zoomIn">
        <img src="@/assets/icons/basic/plus_circle.svg">
      </div>
      <div class="tree-chart__zoom-minus" @click="zoomOut">
        <img src="@/assets/icons/basic/minus_circle.svg">
      </div>
    </div>
    <div v-if="!nodes || nodes.length === 0" class="tree-chart__loader">
      <v-circle-loader />
    </div>
    <div ref="treeChartData" class="tree-chart__data" :style="treeChartDataStyle">
      <div v-for="(row, indexRow) in nodes" :key="indexRow"
           :class="{['tree-chart-row']: true, ['tree-chart-row--first']: indexRow === 0, ['tree-chart-row--group-in']: indexRow === lastRowIndex}"
           :style="rowStyle(indexRow)"
      >
        <div v-if="(isCanGrouping || groupingDisable) && indexRow === 1" class="tree-chart-row__group-out-line" :style="{width: `${widthRow}px`}">
          <div class="tree-chart-row__group-out-line__label">
            <span v-if="!groupingDisable">{{ grouping.out }}</span>
            <img src="@/assets/icons/basic/eye_flat.svg" @click="groupingDisable = !groupingDisable">
          </div>
        </div>
        <div v-if="isCanGrouping && indexRow === lastRowIndex + 1" class="tree-chart-row__group-out-line">
          <div class="tree-chart-row__group-out-line__label">
            <span>{{ grouping.in }}</span>
            <img src="@/assets/icons/basic/eye_flat.svg" @click="groupingDisable = !groupingDisable">
          </div>
        </div>
        <component
            :is="nodeView"
            v-for="(node, indexNode) in row.filter((item) => !item.isDuplicate)"
            ref="nodes"
            :key="indexNode"
            class="tree-chart-row__node"
            :style-node="nodeStyle(node)"
            :node="node"
            :selected="selected[node.id] ?? ''"
            :grouping="grouping"
            @clickNode="clickNode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LeaderLine from 'leader-line-new';
import VCircleLoader from 'components/VCircleLoader/VCircleLoader';
import SubdivisionNode from './components/SubdivisionNode';
import SubstationNode from './components/SubstationNode';

const lineOptions = (props) => ({
  size: 2,
  color: '#ACB0B5',
  startPlug: 'disc',
  startPlugColor: '#FFFFFF',
  startPlugOutline: true,
  startPlugOutlineColor: '#ACB0B5',
  startPlugSize: 2,
  endPlug: 'arrow2',
  startSocket: 'bottom',
  endSocket: 'top',
  path: 'grid',
  endSocketGravity: props?.gravity ?? 1,
  startSocketGravity: props?.startGravity ?? 1,
  middleLabel: props.lineLabel ? LeaderLine.captionLabel(props.lineLabel, { color: '#000000', fontSize: '12px' }) : undefined,
  // middleLabel: LeaderLine.captionLabel('10 rDn', { color: '#000000', fontSize: '12px' }),
});

export default {
  name: 'TreeChart',
  components: {
    VCircleLoader,
    SubdivisionNode,
    SubstationNode,
  },
  props: {
    topNode: {
      type: Object,
      required: true,
    },
    isGrouping: {
      type: Boolean,
      default: true,
    },
    grouping: {
      type: Object,
      default: () => ({ in: 'Имеется', out: 'Отсутствует', propName: 'propName' }),
    },
    getChildren: {
      type: Function,
      default: (node) => node.children,
    },
    indent: {
      type: String,
      default: '128',
    },
    ignoreNode: {
      type: Function,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    unselect: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      required: false,
    },
    widthNode: {
      type: Number,
      default: 272,
    },
    minWidthRow: {
      type: Number,
      required: false,
    },
    indentNode: {
      type: Number,
      default: 50,
    },
    startLine: {
      type: Boolean,
      default: false,
    },
    nodeView: {
      type: String,
      required: true,
      validator: (view) => ['SubdivisionNode', 'SubstationNode'].includes(view),
    },
    checkDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  beforeRouteLeave() {
    this.clear();
  },
  mounted() {
    this.$parent.$parent.$refs.rightcolumn.addEventListener('scroll', this.reposition);
    this.$refs.treeChart.addEventListener('mousedown', this.mouseDownHandler);
    this.$refs.treeChart.addEventListener('scroll', this.reposition);
  },
  beforeUnmount() {
    this.clear();
  },
  destroyed() {
    this.clear();
  },
  data() {
    return {
      arrows: [],
      selected: {},
      position: { top: 0, left: 0, x: 0, y: 0 },
      scale: 1,
      lastRowIndex: undefined,
      groupingDisable: false,
      widthRow: 0,
    };
  },
  computed: {
    isCanGrouping() {
      return this.isGrouping && !this.groupingDisable &&
          !this.list.some((parentRole) => parentRole[this.grouping.propName] && this.list.find((role) => role.parentRole?.id === parentRole.id && !role[this.grouping.propName]));
    },
    nodes() {
      if (!this.topNode?.id) {
        return [];
      }

      const list = this.list;
      const result = [];
      const countInRow = [];

      function calcMaxCountInRow(node, row, ignoreNode, getChildren) {
        const children = getChildren(node);

        if (children) {
          children.forEach((child) => {
            const childData = list.filter((item) => item.id === child.id)[0] ?? child;

            if (!ignoreNode || !ignoreNode(childData)) {
              if (!countInRow[row - 1]) {
                countInRow[row - 1] = 0;
              }

              countInRow[row - 1] = countInRow[row - 1] + 1;

              calcMaxCountInRow(childData, row + 1, ignoreNode, getChildren);
            }
          });
        }
      }

      calcMaxCountInRow(this.topNode, 1, this.ignoreNode, this.getChildren);

      const maxCountInRow = Math.max(...countInRow);

      const widthRow = this.getWidthRow(maxCountInRow);
      this.updateWidthRow(widthRow);

      function setPosition(node, row, ignoreNode, getNodePosition, parentIndex, getChildren, checkDuplicate) {
        if (!result[row]) {
          result[row] = [];
        }

        const children = getChildren(node);
        if (children) {
          children.forEach((child, childIndex) => {
            let isDuplicate = undefined;

            if (checkDuplicate) {
              isDuplicate = result[row].findIndex((item) => item.id === child.id) !== -1;
            }

            const childData = list.filter((item) => item.id === child.id)[0] ?? child;

            if (!ignoreNode || !ignoreNode(childData)) {
              const childDataPos = {
                ...childData,
                left: getNodePosition(node.left, children.length, childIndex),
                parentIndex: parentIndex,
                parentId: node.id,
                lineLabel: child.lineLabel,
                isDuplicate: isDuplicate,
              };

              result[row].push(childDataPos);

              setPosition(childDataPos, row + 1, ignoreNode, getNodePosition, result[row].length - 1, getChildren, checkDuplicate);
            }
          });
        }
      }

      const topNode = { ...this.topNode, left: (this.widthRow / 2) - (this.widthNode / 2) };
      result[0] = [topNode];

      // Разбиваем дерево на строки, дочерние элементы позиционируются симметрично относительно родительского
      setPosition(topNode, 1, this.ignoreNode, this.getNodePosition, 0, this.getChildren, this.checkDuplicate);

      function shiftChildren(rowIndex, parentId, shift) {
        if (!result[rowIndex]) {
          return;
        }

        result[rowIndex].filter((item) => item.parentId === parentId).forEach((child) => {
          child.left += shift;

          shiftChildren(rowIndex + 1, child.id, shift);
        });
      }

      result.forEach((row, rowIndex) => {
        row.forEach((node, nodeIndex) => {
          if (nodeIndex === 0) {
            return;
          }

          // Двигаем пересекающиеся элементы. Если разные родительские, то родителя выравниваем с первым дочерним
          if (node.left < row[nodeIndex - 1].left + this.widthNode + this.indentNode) {
            const oldLeft = node.left;

            if (node.parentIndex === row[nodeIndex - 1].parentIndex) {
              node.left = row[nodeIndex - 1].left + this.widthNode + this.indentNode;
            } else if (result[rowIndex - 1][node.parentIndex].left < row[nodeIndex - 1].left + this.widthNode + this.indentNode) {
              node.left = row[nodeIndex - 1].left + this.widthNode + this.indentNode;

              result[rowIndex - 1][node.parentIndex].left = node.left;

              for (let i = node.parentIndex + 1; i <= result[rowIndex - 1].length - 1; i++) {
                if (result[rowIndex - 1][i].left >= result[rowIndex - 1][i - 1].left + this.widthNode + this.indentNode) {
                  break;
                }

                result[rowIndex - 1][i].left = result[rowIndex - 1][i - 1].left + this.widthNode + this.indentNode;
              }
            } else {
              node.left = result[rowIndex - 1][node.parentIndex].left;
            }

            shiftChildren(rowIndex + 1, node.id, node.left - oldLeft);

            for (let i = nodeIndex + 1; i <= row.length - 1; i++) {
              if (row[i].parentId !== row[i - 1].parentId) {
                break;
              }
              if (row[i] >= row[i - 1].left + this.widthNode + this.indentNode) {
                break;
              }

              const oldLeft = row[i].left;
              row[i].left = row[i - 1].left + this.widthNode + this.indentNode;

              shiftChildren(rowIndex + 1, row[i].id, row[i].left - oldLeft);
            }
          }
        });
      });

      // В начале отображаем элементы с this.grouping.propName, ниже без
      if (this.isCanGrouping) {
        result.every((row, rowIndex) => {
          if (row.find((role) => !role[this.grouping.propName])) {
            this.updateLastRowIndex(rowIndex);
            return true;
          }
          return false;
        });

        result.forEach((row, rowIndex) => {
          if (rowIndex === 0) {
            return;
          }
          row.forEach((role, roleIndex) => {
            if (role[this.grouping.propName] && rowIndex <= this.lastRowIndex) {
              if (!result[rowIndex + 1]) {
                result[rowIndex + 1] = [];
              }

              if (result[rowIndex + 1].length - 1 >= roleIndex) {
                result[rowIndex + 1].splice(roleIndex, 0, JSON.parse(JSON.stringify(role)));
              } else {
                for (let i = result[rowIndex + 1].length; i < roleIndex; i++) {
                  result[rowIndex + 1].push({ isEmpty: true });
                }
                result[rowIndex + 1].splice(roleIndex, 0, JSON.parse(JSON.stringify(role)));
              }

              result[rowIndex][roleIndex] = { isEmpty: true };
            }
          });
        });
      }

      return result.filter((row) => row.find((node) => !node.isEmpty));
    },
    treeChartDataStyle() {
      return { width: `${this.widthRow}px`, transform: `scale(${this.scale})` };
    },
  },
  watch: {
    nodes: {
      immediate: true,
      handler() {
        this.setDragCursor();
        this.reDrawArrows();
      },
    },
    unselect(val) {
      if (val) {
        this.selected = {};
      }
    },
    treeChartDataStyle: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.reposition();
        });
      },
    },
    zoom: {
      immediate: true,
      handler(val) {
        this.scale = val;
        this.reDrawArrows();
        // this.setZoom();
      },
    },
  },
  methods: {
    checkScrolling() {
      return (this.$refs.treeChart && this.$refs.treeChart.scrollWidth > this.$refs.treeChart.clientWidth) ||
          (this.$parent.$parent.$refs.rightcolumn && this.$parent.$parent.$refs.rightcolumn.scrollHeight > this.$parent.$parent.$refs.rightcolumn.clientHeight);
    },
    drawArrows() {
      this.nodes.forEach((row) => {
        for (let i = 0; i < row.length; i++) {

          const parentId = row[i].parentId;
          const parentNodeDiv = document.getElementById(`node-bottom-${parentId}`);

          if (parentId) {
            const children = row.filter((nodeRow) => nodeRow.parentId === parentId);

            const center = (children.length / 2) - 0.5;

            children.forEach((node, nodeIndex) => {
              let x = this.widthNode / 2;

              // Если родитель смещен, то стрелки со сдвигом от центра вправо, иначе - от центра в обе стороны симметрично
              const parentShift = document.getElementById(`node-bottom-${children[0].id}`).getBoundingClientRect().left >= parentNodeDiv.getBoundingClientRect().left;
              const abs = parentShift ? (nodeIndex) : Math.abs(center - nodeIndex);

              // Отступ начальной точки стрелки от центра
              if (parentShift || nodeIndex > center) {
                x = x + (14 * abs);
              } else if (nodeIndex < center) {
                x = x - (14 * abs);
              }

              if (x < 28) {
                x = 28;
              } else if (x > this.widthNode - 28) {
                x = this.widthNode - 28;
              }

              // Горизонтальный отступ линии стрелки
              let gravity = 8 * abs;
              if (gravity < 1) {
                gravity = 1;
              } else if (gravity > 64) {
                gravity = 64;
              }

              if (node.isDuplicate) {
                gravity = 8 * gravity;
              }

              this.arrows.push(
                  new LeaderLine(
                      LeaderLine.pointAnchor(
                          parentNodeDiv,
                          { x: x * this.scale, y: '100%' },
                      ),
                      document.getElementById(`node-top-${node.id}`),
                      lineOptions({ gravity: gravity * this.scale, lineLabel: node.lineLabel }),
                  ),
              );
            });

            i += (children.length - 1);

          }
        }
      });

      // Рисуем стрелку входящую в первый элемент
      if (this.startLine && this.topNode?.id) {
        // Корректируем положение стрелки относительно первого элемента
        this.$emit('onDrawArrows', this.topNode);

        this.$nextTick(() => {
          this.arrows.push(
              new LeaderLine(
                  document.getElementById('node-bottom-start'),
                  document.getElementById(`node-top-${this.topNode.id}`),
                  lineOptions({ startGravity: 100 }),
              ),
          );
        });
      }
    },
    reposition() {
      this.arrows.forEach((arrow) => {
        arrow.position();
      });
    },
    reDrawArrows() {
      this.$nextTick(() => {
        this.arrows.forEach((arrow) => {
          arrow.remove();
        });
        this.arrows = [];
        this.drawArrows();
        this.reposition();
      });
    },
    clear() {
      this.$parent.$parent.$refs.rightcolumn.removeEventListener('scroll', this.reposition);

      this.arrows.forEach((arrow) => {
        arrow.remove();
      });
    },
    clickNode(node, target) {
      if (target === 'bottom' && !node[this.grouping.propName]) {
        return;
      }

      if (this.selected[node.id] === target) {
        this.selected = {};
        this.$emit('click', node.id, target);
        return;
      }

      this.selected = {};
      this.selected[node.id] = target;

      this.$emit('click', node.id, target);
    },
    rowStyle(indexRow) {
      const style = { marginBottom: `${this.indent}px` };

      style.width = `${this.widthRow}px`;

      if (indexRow > 0) {
        style.marginTop = `${2 * this.indent}px`;
      }

      return style;
    },
    nodeStyle(node) {
      if (!node) {
        return {};
      }
      return {
        left: `${node.left}px`,
        width: `${this.widthNode}px`,
      };
    },
    mouseDownHandler(e) {
      if (!this.checkScrolling()) {
        return;
      }

      this.position = {
        left: this.$refs.treeChart.scrollLeft,
        top: this.$parent.$parent.$refs.rightcolumn.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };

      this.$refs.treeChart.style.cursor = 'grabbing';
      this.$refs.treeChart.style.userSelect = 'none';

      this.$refs.treeChart.addEventListener('mousemove', this.mouseMoveHandler);
      this.$refs.treeChart.addEventListener('mouseup', this.mouseUpHandler);
      this.$refs.treeChart.addEventListener('mouseout', this.mouseUpHandler);
    },
    mouseUpHandler() {
      // TODO: fix checkScrolling (doesn't work if display scroll after adding node, works after reload)
      /* if (!this.checkScrolling) {
        return;
      }*/

      this.$refs.treeChart.removeEventListener('mousemove', this.mouseMoveHandler);
      this.$refs.treeChart.removeEventListener('mouseup', this.mouseUpHandler);

      this.$refs.treeChart.style.cursor = 'grab';
      this.$refs.treeChart.style.removeProperty('user-select');
    },
    mouseMoveHandler(e) {
      const dx = e.clientX - this.position.x;
      const dy = e.clientY - this.position.y;
      this.$parent.$parent.$refs.rightcolumn.scrollTop = this.position.top - dy;
      this.$refs.treeChart.scrollLeft = this.position.left - dx;

      this.reposition();
    },
    setDragCursor() {
      this.$nextTick(() => {
        document.getElementById(`node-top-${this.topNode.id}`)?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      });

      if (this.checkScrolling() && this.$refs.treeChart) {
        this.$refs.treeChart.style.cursor = 'grab';
      }
    },
    zoomIn() {
      this.scale += 0.1;
      // this.setZoom();
    },
    zoomOut() {
      this.scale -= 0.1;
      // this.setZoom();
    },
    /* setZoom() {
      //TODO: not working in firefox (now working scale in treeChartDataStyle)
      this.$refs.treeChartData.style.zoom = `${this.scale*100}%`;
      Array.from(document.getElementsByClassName('leader-line')).forEach(item => {
        item.style.zoom = `${this.scale*100}%`;
      });
      this.reposition();
    },*/
    updateLastRowIndex(val) {
      this.lastRowIndex = val;
    },
    updateWidthRow(val) {
      const minWidth = this.minWidthRow ?? ((2 * this.widthNode) + this.indentNode);
      if (val < minWidth) {
        this.widthRow = minWidth;
        return;
      }
      this.widthRow = val;
    },
    getWidthRow(maxCountInRow) {
      return ((maxCountInRow) * this.widthNode) + ((maxCountInRow - 1) * this.indentNode);
    },
    getNodePosition(parentLeft, childrenCount, childrenIndex) {
      const center = (childrenCount / 2) - 0.5;

      if (childrenIndex === center) {
        return parentLeft;
      }

      const widthRow = this.getWidthRow(childrenCount);

      const result = parentLeft - (widthRow / 2) + (childrenIndex * (this.widthNode + this.indentNode)) + (this.widthNode / 2);

      return result < 0 ? 0 : result;
    },
  },
};

</script>

<style lang="scss" scoped>
  .tree-chart {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 64px 128px 64px;
    transform: scale(1);

    &__data {
      position: relative;
      transform-origin: 0 0;
    }

    &__loader {
      position: relative;
      height: 50px;
    }

    &__zoom {
      position: fixed;
      top: 17px;
      right: 0;
      display: flex;
      gap: 1px;
      z-index: 5;

      &-plus, &-minus {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 1px;
        background-color: #F5F6F6;
        cursor: pointer;
      }

      &-plus {
        border-radius: 24px 0 0 24px;

        &--inactive {
          cursor: default;
          background-color: #fff7f7;
        }
      }
      &-minus {
        border-radius: 0 24px 24px 0;
      }
    }

    &::-webkit-scrollbar-button {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 0;
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a19f9f;
    }

    &::-webkit-resizer {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &-row {
      width: 100%;
      display: flex;
      gap: 24px;
      position: relative;

      &--first {
        justify-content: center;
      }

      &--start {
        height: 80px;
        position: absolute;
      }

      &--group-in {
        padding-bottom: 24px;
      }

      &__node {
        position: absolute;
      }

      &__group-out-line {
        position: absolute;
        border-top: 2px dashed #C1C4C7;
        width: 98%;
        top:-24px;
        display: flex;
        justify-content: end;
        margin-right: 10px;

        &__label {
          position: sticky;
          right: 12px;
          display: flex;
          align-items: center;
          gap: 8px;
          height: 40px;
          margin-top: -40px;
          margin-right: -15px;
          border: 1px solid #C1C4C7;
          border-radius: 24px 24px 24px 0;
          padding: 4px 16px 4px 24px;
          background-color: #FFFFFF;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
