import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  workflows: [],
  workflows_by_correlation_id: [],
  tasks: {},
  testTasks: [],
  tasksCounter: Number(localStorage.getItem('tasksCounter')) || 0,
  menuKeyUpdate: 1,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
