var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.navigationBar},[(_vm.isDisplayBack)?_c('button',{class:_vm.$style.btnBack,on:{"click":_vm.back}},[_vm._v(" Назад ")]):_vm._e(),_vm._l((_vm.stepsNavigation),function(step,idx){return _c('div',{key:'step_' + idx,class:{
      [_vm.$style.firstStep]: idx === 0 && _vm.stepsNavigation.length !== 1,
      [_vm.$style.lastStep]: idx === _vm.stepsNavigation.length - 1 && _vm.stepsNavigation.length !== 1,
      [_vm.$style.oneStep]: _vm.stepsNavigation.length === 1,
      [_vm.$style.currentStep]: step.stepNumber.value === _vm.currentStep + 1,
      [_vm.$style.step]: step.stepNumber.value < _vm.currentStep + 1,
      [_vm.$style.nextStep]: step.stepNumber.value > _vm.currentStep + 1
    },on:{"click":function($event){return _vm.openStep(step.stepNumber.value)}}},[_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(step.stepNumber.title(_vm.contractExisting)))]),_c('div',{class:_vm.$style.arrow}),_c('div',{class:_vm.$style.arrow2})])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }