<template>
  <v-button class="button" type="button" variant="outlined-colored" @click="$emit('click')">
    <add-circle />
    Добавить
  </v-button>
</template>

<script>
import { VButton } from 'components';
import AddCircle from 'atoms/icons/action/AddCircle.vue';

export default {
  name: 'VTableThAddedButton',
  components: { AddCircle, VButton },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 16px
}
</style>
