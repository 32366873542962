<template>
  <div v-show="isDisplay" :class="$style.container">
    <div>
      <div v-if="reviewDocument.comments" :class="$style.blockContent">
        <spoiler-preview v-model="spoiler.review">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title="Результаты предыдущей проверки">
              <template slot="icon">
                <chevron-up v-if="spoiler.review" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <p :class="$style.reviewComments" v-html="comments"></p>
          </template>
        </spoiler-preview>
      </div>

      <div :class="$style.blockContent">
        <spoiler-preview v-model="spoiler.regDoc">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title="Регулирующий документ">
              <template slot="icon">
                <chevron-up v-if="spoiler.regDoc" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <p :class="$style.documentTitle">Приказ Департамента экономической политики и развития города Москвы «Об
              установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп потребителей
              города Москвы»</p>

            <div v-if="tariffDocument.length > 0" :class="$style.reviewDocumentFiles">
              <p v-for="(file, index) in tariffDocument" :key="index"
                 style="display: flex; justify-content: space-between">
                <a :download="file.name" :href="fileUrl(file)"
                   :role="'tariffDocument[' + index + '].name'"><span>{{ file.name }}</span></a>
                <span :class="$style.replace">
                    <a :download="file.name" :href="fileUrl(file)"><img alt=""
                                                                        src="~@/assets/icons/file/file_download.svg" /></a>
                    <a :download="file.name" :href="fileUrl(file)"><span
                      :role="'tariffDocument[' + index + '].replace'">&nbsp;&nbsp;&nbsp;скачать</span></a>
                </span>

              </p>
            </div>
          </template>
        </spoiler-preview>

      </div>

      <div v-show="regulatoryDocs && regulatoryDocs.length > 0" :class="$style.blockContent">
        <spoiler-preview v-model="spoiler.tariff">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title="Тарифы">
              <template slot="icon">
                <chevron-up v-if="spoiler.tariff" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <tariff-form
              v-model="tariff"
              :description="description"
              :dictionary-category="tariffCategory"
              :dictionary-rate="tariffRate"
              :region="region"
              :tariffs-data="tariffsData"
              :task-document-id="documentId"
              :year="year"
              mode="review"
              @review-value="reviewValue"
            />
          </template>
        </spoiler-preview>
      </div>
    </div>

    <div v-show="spoiler.tariff" :class="$style.statusGroup">
      <p :class="$style.title">
        Отметьте результаты проверки
      </p>
      <div :class="$style.radioGroup">
        <label :class="$style.labelRadio">
          <input
            :checked="statusGroup === 1"
            :class="$style.control"
            name="taskStatus"
            role="radio.approve"
            type="radio"
            @click="statusGroupClick(1)"

          />
          <div :class="$style.contentRadioGroup">
            <div :class="$style.titleContainer">
              <div :class="$style.buttonRadioGroupCheck"></div>
              <h3 :class="$style.titleRadioGroup">Ошибок нет</h3>
            </div>
          </div>
        </label>
        <label :class="$style.labelRadio">
          <input
            :checked="statusGroup === 2"
            :class="$style.control"
            name="taskStatus"
            role="radio.reject"
            type="radio"
            @click="statusGroupClick(2)"
          />
          <div :class="$style.contentRadioGroup" style="margin-top: -16px">
            <div :class="$style.titleContainer">
              <div :class="$style.buttonRadioGroupError"></div>
              <h3 :class="$style.titleRadioGroup">Найдены ошибки</h3>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div v-show="spoiler.tariff && statusGroup === 2" :class="$style.correctionGroup">
      <div :class="$style.blockContent">
        <p>Описание ошибки</p>
        <c-textarea
          v-model="correctionComments"
          role="correctionComments"
        />
      </div>
    </div>

    <div v-show="statusGroup === 1 || statusGroup === 2" :class="$style.blockContent">
      <p :class="$style.correctionTitle">
        {{ notificationMessage.message }}
      </p>
    </div>

    <div>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details v-if="!spoiler.tariff" role="displayAction" title="Начать выполнение"
                           @submit="spoiler.tariff = true" />
      <div v-else :class="$style.buttons">
        <submit-task-details :class="$style.close" :disabled="isWaitingSubmit" title="продолжить позже"
                             @submit="onClose" />
        <submit-task-details :class="$style.submit" :disabled="isWaitingSubmit || !isValid" :title="submitTitle"
                             @submit="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import TariffForm from 'organisms/tasks/tariffs-update/TariffForm';
import generateXml from 'lib/storage/connection-application/data';

// TODO move to lib
function convertDocumentScan(targetArray, file) {
  targetArray.push({
    code: file.value,
    name: file.name,
    type: file.contentType,
  });
}

export default {
  name: 'ReviewTariff',
  components: {
    SubmitTaskDetails,
    HorizontalProgressBar,
    SpoilerPreview,
    SpoilerHeader,
    ChevronDown,
    ChevronUp,
    TariffForm,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    reviewDocument: Object,
    retailCompanyName: String,
    region: String,
    year: String,
    description: String,
    isDisplay: {
      type: Boolean,
      default: true,
    },
    tariffDocument: [],
    tariffsData: {},
    initSpoiler: {
      type: Object,
      default: () => ({
          tariff: true,
        }),
    },
  },
  data() {
    return {
      isChecked: true,
      correctionComments: '',
      submitError: '',
      isWaitingSubmit: false,
      regulatoryDocs: [],
      spoiler: {
        tariff: this.initSpoiler.tariff,
        review: true,
        regDoc: false,
      },
      tariff: [],
      isNewDocs: true,
      statusGroup: undefined,
      invalidTariff: {},
    };
  },
  computed: {
    isValid() {
      return this.statusGroup === 1 || (this.statusGroup === 2 && this.correctionComments.trim().length > 2);
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '', '');
    },
    submitTitle() {
      if (this.statusGroup === 2) {
        return 'Сообщить об ошибке';
      }
      return 'Опубликовать тарифы';
    },
    reviewType() {
      if (this.statusGroup === 1) {
        return 'tariff-update-task-approved';
      }
      return 'tariff-update-task-rejected';
    },
    notificationMessage() {
      if (this.statusGroup === 1) {
        return {
          title: 'Задача выполнена',
          message: 'Сотруднику Платформы, выполнившему задачу по актуализации тарифов, придёт сообщение с результатом проверки. Тарифы будут опубликованы на Платформе. Уведомление о смене тарифов будет направлено организациям и потребителям.',
        };
      }
      return {
        title: '',
        message: 'Сообщение об ошибках будет направлено сотруднику Платформы, выполняющему задачу по смене тарифов.',
      };
    },
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
  },
  watch: {
    tariffDocument: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.isNewDocs = false;
          this.regulatoryDocs = [];
          convertDocumentScan(this.regulatoryDocs, val[0]);
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      if (!this.spoiler.tariff) {
        this.spoiler.tariff = true;
        return;
      }

      this.submitError = '';
      this.isWaitingSubmit = true;

      const documentReviewXml = generateXml.documentReviewXml(
        this.documentId,
        this.reviewType,
        this.correctionComments,
        undefined,
        this.invalidTariff,
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              this.notificationMessage.title,
              `<p>${this.notificationMessage.message}</p>`,
            );
          });
          this.onClose();
        }, 2000);

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    handleRemoveRegulatoryDocs(file) {
      this.regulatoryDocs = this.regulatoryDocs
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    statusGroupClick(val) {
      this.statusGroup = val;
    },
    reviewValue(review) {
      const target = `\n${review.target}`;
      const targetTitle = 'Неверно указаны тарифы:';

      if (review.isNotValid) {
        if (this.correctionComments.indexOf(targetTitle) === -1) {
          if (this.correctionComments.trim().length !== 0) {
            this.correctionComments += '\n';
          }
          this.correctionComments += targetTitle;
        }
        this.correctionComments += target;
        this.statusGroupClick(2);

        this.invalidTariff[review.formId] = true;
      } else {
        this.correctionComments = this.correctionComments.replace(target, '');

        if (this.correctionComments.indexOf('раздел') === -1) {
          this.correctionComments = this.correctionComments.replace(targetTitle, '');

          if (this.correctionComments.trim().length === 0) {
            this.statusGroupClick(1);
          }
        }

        if (this.invalidTariff[review.formId]) {
          delete this.invalidTariff[review.formId];
        }
      }
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.container
  a
    display: inline !important

    span
      text-decoration: none

.title
  padding-bottom: 24px
  +base-title

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.blockContent
  margin-bottom: 40px

.statusGroup
  display: flex
  margin-bottom: 24px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.accompanyingText
  margin-bottom: 24px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.reviewDocument, .reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument p
  margin-top: 16px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.documentTitle
  +card-title-small
  margin-bottom: 24px

.buttons
  display: flex
  position: relative

  .close
    button
      background: #71757A

  div
    width: 50%

    &.disabled
      background: #DFE1E6

.spoilerHeader
  +questionnaries-container

.reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.reviewDocumentFiles p span.replace
  display: flex
  font-family: 'Roboto Condensed'
  color: #4C4E51
  white-space: nowrap
  cursor: pointer
  font-size: 18px
  line-height: 18px

  a
    text-decoration: none

.reviewComments
  p
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 24px
    color: #EB5757

.radioGroup
  margin-left: -66px
  margin-top: 28px

.labelRadio
  cursor: pointer
  height: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

    & .buttonRadioGroupError
      background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.correctionTitle
  padding-bottom: 12px
  +table-data-base

.correctionGroup
  width: 100%
  max-width: 560px
  margin: 0 auto

  textarea
    +card-title-small

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px
</style>
