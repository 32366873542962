<template>
  <div>

    <div :class="$style.title" @click="show = !show">
      <p>Cистемные требования</p>
      <ChevronUp v-if="show" style="margin-left: 16px" />
      <ChevronDown v-else style="margin-left: 16px" />
    </div>

    <div v-if="show" :class="$style.content">

      <ul :class="$style.text">
        <li>Система: Windows 7 SP1 64 bit или старше</li>
        <li>с установленным JDK (Java Development Toolkit)</li>
        <li>Процессор: CPU Intel i3-1500 или лучше</li>
        <li>Память: 8 Гб RAM</li>
        <li>Место на диске: 10 Мб доступного пространства</li>
        <li>Другое: свободный USB-порт</li>
      </ul>

    </div>

  </div>
</template>

<script>
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';

export default {
  name: 'System',
  components: {
    ChevronDown,
    ChevronUp,
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="sass" module>
.title
  display: flex
  cursor: pointer
  p
    +base-title

.content
  padding: 24px 0 40px 0

ul.text
  li
    +base-text
</style>
