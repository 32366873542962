<template>
  <v-table-new
    v-model="transformedLocalRepairSummary"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="localRepairSummary.pageOut"
    :template="template"
    excel-report-file-name="Работа с дефектами. Список дефектов"
    server-side-sorting
    table-name="localRepairSummary"
    @on-cell-click="handleCellClick"
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { mapGetters } from 'vuex';
import { formatDateTimeFromOdkTimestamp } from 'lib/utils/date';
import { BASE_URL } from '@/constants/api';
import { convertBooleanToText } from 'lib/utils/formatXmlType';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};
export default {
  name: 'LocalRepairSummaryTable',
  components: { VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      localRepairSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    transformedLocalRepairSummary() {
      if (
        !this.localRepairSummary ||
        !this.localRepairSummary.data ||
        !Array.isArray(this.localRepairSummary.data)
      ) {
        return [];
      }

      console.log('trans', this.localRepairSummary);
      return this.localRepairSummary.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Фото дефекта',
            key: 'preview',
            type: 'preview',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'ОДК',
            key: 'defect.adreskaFact.odk.name',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'АХП',
            key: 'defect.adreskaFact.axp.name',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Подрядчик',
            key: 'defect.adreskaFact.adreska.contractorName',
            thStyle: {
              minWidth: '83px',
            },
            size: 'lg',
            sort: {},
            filter: this.contractorFilter,
          },
          {
            label: 'Программа оформления',
            key: 'defect.adreskaFact.adreska.name',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Адрес эксплуатации',
            key: 'defect.adreskaFact.adreska.address',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '95px',
            },
            filter: {},
          },
          {
            label: 'Резервный адрес эксплуатации',
            key: 'defect.adreskaFact.adreska.backupAddress',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '120px',
            },
            filter: {},
          },
          {
            label: 'ID ОДК',
            key: 'defect.adreskaFact.id',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Выявленные замечания',
            key: 'defect.defect.name',
            thStyle: {
              minWidth: '95px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Комментарий к замечанию',
            key: 'defect.defectDescription',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Гарантийный',
            key: 'defect.warranty',
            size: 'md',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Статус устранения замечания',
            key: 'defect.status',
            thStyle: {
              minWidth: '120px',
            },
            size: 'md',
            sort: {},
            filter: {
              type: 'vSearchSelectValue',
              values: this.odkDefectStatus,
            },
          },
          {
            label: 'Причины неисправности',
            key: 'defect.reason',
            thStyle: {
              minWidth: '105px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Выполненные работы',
            key: 'defect.works',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'ФИО выявившего дефект',
            key: 'defect.foundBy',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата обнаружения ',
            key: 'defect.createDate',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата устранения ',
            key: 'defect.repairedAt',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        'defect.adreskaFact.adreska.contractorName': 'contractorName',
        'defect.status': 'status',
        'defect.adreskaFact.adreska.address': 'address',
        'defect.adreskaFact.adreska.backupAddress': 'backupAddress',
        'defect.adreskaFact.adreska.name': 'adreskaName',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });
      if (!this.isBoiler) {
        result.contractorId = this.getAccount.supplier.id;
      }
      return result;
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),
    ...mapGetters('dataLists', ['odkDefectStatus']),
    ...mapGetters('dictionary', ['odkDefectStatusDictionary']),
  },
  methods: {
    async handleCellClick(cell) {
      switch (cell.key) {
        case 'followLink': {
          await this.$router.push({
            name: 'cabinet.handling-defects.defect-card',
            query: { id: cell.row.defect.id },
          });
        }
      }
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData(
          'local-repair-summary',
          page,
          size,
          sortField,
          sort,
          filters,
        );
        console.log('resData', responseData);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.localRepairSummary = responseData;
        this.localRepairSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData(
          'local-repair-summary',
          1,
          this.localRepairSummary.pageOut.totalElements,
          sortField,
          sort,
          filters,
        );

        return responseData.data?.map(this.transformed) ?? [];
      } catch (error) {
        console.log('Error loading data:', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (
        pagination.page !== this.pagination.page ||
        pagination.size !== this.pagination.size
      ) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(item, index) {
      console.log('item', item);
      return {
        index: index + 1,
        preview: {
          src: `${BASE_URL}/api/get/${item.defect.photos[0]?.folder}/${item.defect.photos[0]?.name}`,
          alt: 'Фото дефекта',
          previewExists: item.defect.photos[0],
          notExistsMessage: 'Отсутствует',
        },
        defect: {
          id: item.defect.id,
          defect: {
            name: item.defect.defect.name,
          },
          reason: item.defect.reason,
          works: item.defect.works,
          status: this.odkDefectStatusDictionary[item.defect.status],
          defectDescription: item.defect.defectDescription,
          updatedAt: formatDateTimeFromOdkTimestamp(item.defect.updatedAt),
          adreskaFact: {
            id: item.defect.adreskaFact.id,
            countOdk: item.defect.adreskaFact.countOdk,
            axp: {
              odk: {
                name: item.defect.adreskaFact.axp.odk.name,
              },
              name: item.defect.adreskaFact.axp.name,
            },
            adreska: {
              contractorName: item.defect.adreskaFact.adreska.contractorName,
              address: item.defect.adreskaFact.adreska.address,
              name: item.defect.adreskaFact.adreska.name,
            },
          },

          foundBy: item.defect.foundBy,
          createDate: formatDateTimeFromOdkTimestamp(item.defect.createDate),
          repairedAt: formatDateTimeFromOdkTimestamp(item.defect.repairedAt),
          warranty: convertBooleanToText(item.defect.warranty),
        },
      };
    },
  },
};
</script>

<style scoped></style>
