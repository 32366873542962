/* eslint-disable */
import { getCompletedTag } from './tools.js';

const indent4 = '    ';
const indent8 = `${indent4}    `;
const indent12 = `${indent8}    `;
const indent16 = `${indent12}    `;
const indent20 = `${indent16}    `;

function getGeneralDirector(fullName, type) {
  if (!fullName.firstName && !fullName.familyName) return '';
  return (
    `                <sc:GeneralDirector ${type === 'Genitive' ? 'case="Genitive"' : ''}>\n` +
    `                    <cc:FamilyName>${fullName.familyName}</cc:FamilyName>\n` +
    `                    <cc:FirstName>${fullName.firstName}</cc:FirstName>\n` +
    `${getCompletedTag('cc:Patronymic', fullName.patronymic, indent20)}` +
    '                </sc:GeneralDirector>\n'
  );
}

function addSupplier(supplierType, data) {
  return (
    '    <AddSupplierChangeList>\n' +
    '        <AddSupplier>\n' +
    `            <sc:${supplierType}>\n` +
    `${getCompletedTag('sc:ExternalId', data.externalId, indent16)}` +
    `${getCompletedTag('sc:Name', data.fullName, indent16)}` +
    `${getCompletedTag('sc:ShortName', data.shortName, indent16)}` +
    `${getCompletedTag('sc:INN', data.inn, indent16)}` +
    `${getCompletedTag('sc:KPP', data.kpp, indent16)}` +
    `${getCompletedTag('sc:OGRN', data.ogrn, indent16)}` +
    `${getCompletedTag('sc:OKTMO', data.oktmo, indent16)}` +
    `${getCompletedTag('sc:OKPO', data.okpo, indent16)}` +
    `${getCompletedTag('sc:OKVED', data.okved, indent16)}` +
    `${getCompletedTag('sc:AdditionalOKVED', data.additionalOkved, indent16)}` +
    `${getGeneralDirector({familyName: data.directorFamilyName, firstName: data.directorFirstName, patronymic: data.directorPatronymic})}` +
    `${getGeneralDirector({familyName: data.directorFamilyNameGenitive, firstName: data.directorFirstNameGenitive, patronymic: data.directorPatronymicGenitive}, 'Genitive')}` +
    `${getCompletedTag('sc:DepartmentName', data.departmentName, indent16)}` +
    `${getCompletedTag('sc:PhoneNumber', data.phoneNumber, indent16)}` +
    `${getCompletedTag('sc:Email', data.email, indent16)}` +
    `${getCompletedTag('sc:Website', data.website, indent16)}` +
    `${getCompletedTag('sc:Region', data.region, indent16)}` +
    `${getCompletedTag('sc:District', data.district, indent16)}` +
    `${getCompletedTag('sc:Area', data.area, indent16)}` +
    `${getCompletedTag('sc:IsBoiler', data.isBoiler, indent16)}` +
    `${getCompletedTag('sc:IsSupplierOfLastResort', data.isSupplierOfLastResort, indent16)}` +
    `            </sc:${supplierType}>\n` +
    '        </AddSupplier>\n' +
    '    </AddSupplierChangeList>\n'
  );
}

function updateSupplier(supplierType, data) {
  return (
    '    <UpdateSupplierChangeList>\n' +
    '        <UpdateSupplier>\n' +
    `            <sc:${supplierType}>\n` +
    `${getCompletedTag('sc:Id', data.id, indent16)}` +
    `${getCompletedTag('sc:ExternalId', data.externalId, indent16)}` +
    `${getCompletedTag('sc:Name', data.fullName, indent16)}` +
    `${getCompletedTag('sc:ShortName', data.shortName, indent16)}` +
    `${getCompletedTag('sc:INN', data.inn, indent16)}` +
    `${getCompletedTag('sc:KPP', data.kpp, indent16)}` +
    `${getCompletedTag('sc:OGRN', data.ogrn, indent16)}` +
    `${getCompletedTag('sc:OKTMO', data.oktmo, indent16)}` +
    `${getCompletedTag('sc:OKPO', data.okpo, indent16)}` +
    `${getCompletedTag('sc:OKVED', data.okved, indent16)}` +
    `${getCompletedTag('sc:AdditionalOKVED', data.additionalOkved, indent16)}` +
    `${getGeneralDirector({familyName: data.directorFamilyName, firstName: data.directorFirstName, patronymic: data.directorPatronymic})}` +
    `${getGeneralDirector({familyName: data.directorFamilyNameGenitive, firstName: data.directorFirstNameGenitive, patronymic: data.directorPatronymicGenitive}, 'Genitive')}` +
    `${getCompletedTag('sc:DepartmentName', data.departmentName, indent16)}` +
    `${getCompletedTag('sc:PhoneNumber', data.phoneNumber, indent16)}` +
    `${getCompletedTag('sc:Email', data.email, indent16)}` +
    `${getCompletedTag('sc:Website', data.website, indent16)}` +
    `${getCompletedTag('sc:Region', data.region, indent16)}` +
    `${getCompletedTag('sc:District', data.district, indent16)}` +
    `${getCompletedTag('sc:Area', data.area, indent16)}` +
    `${getCompletedTag('sc:IsBoiler', data.isBoiler, indent16)}` +
    `${getCompletedTag('sc:IsSupplierOfLastResort', data.isSupplierOfLastResort, indent16)}` +
    `${getCompletedTag('sc:BoilerSupplierId', data.id, indent16)}` +
    `            </sc:${supplierType}>\n` +
    '        </UpdateSupplier>\n' +
    '    </UpdateSupplierChangeList>\n'
  );
}

function deleteSupplier(data) {
  return (
    '    <DeleteSupplierChangeList>\n' +
    '        <DeleteSupplier>\n' +
    `            <SupplierId>${data.id}</SupplierId>\n` +
    '        </DeleteSupplier>\n' +
    '    </DeleteSupplierChangeList>\n'
  );
}

function formSupplierChangingOrder(actionType, supplierType, data) {
  switch (actionType) {
    case 'CREATE': {
      return addSupplier(supplierType, data);
    }
    case 'UPDATE': {
      return updateSupplier(supplierType, data);
    }
    case 'DELETE': {
      return deleteSupplier(data);
    }
  }
}

export function supplierChangingOrder(actionType, supplierType, data) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<SupplierChangingOrder\n' +
    '        xmlns="http://energochain.ru/XML/documents/supplier-changing-order/1.0"\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns:cc="http://energochain.ru/XML/documents/company-common/1.0"\n' +
    '        xmlns:sc="http://energochain.ru/XML/documents/supplier-common/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/supplier-changing-order/1.0 SupplierChangingOrder.xsd">\n' +
    formSupplierChangingOrder(actionType, supplierType, data) +
    '</SupplierChangingOrder>'
  );
}
