<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

    </div>
    <template v-if="attachment.errors.length > 0">
      <div :class="$style.content">
        <div>
          <p style="color: red; margin-bottom: 32px">Найдено ошибок: {{ attachment.errors.length }}</p>
          <table :class="$style.details">
            <thead>
            <tr>
              <td>Строка</td>
              <td>Столбец</td>
              <td>Описание</td>
            </tr>
            </thead>
            <tbody :class="$style.tableTd">
            <tr v-for="(error, index) in attachment.errors" :key="index">
              <td>{{ error.row }}</td>
              <td>{{ error.col }}<br />{{ error.colTitle }}</td>
              <td>{{ error.text }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div :key="key">
          <p :class="$style.stepTitle">Загрузите исправленный документ XLSX на Платформу </p>

          <c-uploading
            v-model="filledDocuments"
            :class="$style.filledDocuments"
            :error="attachment.error"
            accept=".xlsx, .xls"
            accept-title="XLSX, XLS или перетащите его в эту область."
            role="CompanyTariff"
          />

          <div v-if="attachment?.file?.name" :class="$style.attachment">
            <a :class="$style.fileAttachment" :download="attachment.file.name"
               :href="fileUrl(attachment.file)">
              <img alt="" height="40" width="40" src="~@/assets/icons/file/attachment.svg" />
              <span>{{ attachment.file.name }}</span>
            </a>
            <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteAttachment()">
              <icon-delete />
            </a>
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <div :class="$style.content">
        <p :class="$style.stepTitle" style="color: green">Ошибок не найдено</p>
      </div>
    </template>


  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files';
import { isErrorConvert } from 'lib/component/validators';

const attachmentNameCompanyWithoutExtension = '18 формы (юридические лица)';
const attachmentNameIndividualWithoutExtension = '18 формы (физические лица)';

export default {
  name: 'EighteenthForms',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    IconDelete: () => import('atoms/icons/action/Delete'),
  },
  props: {
    stepNumber: Object,
    attachment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorText: 'Поле обязательно для заполнения',
      filledDocuments: [],
      attachmentNameCompanyWithoutExtension: attachmentNameCompanyWithoutExtension,
      attachmentNameCompany: `${attachmentNameCompanyWithoutExtension}.xlsx`,
      attachmentNameIndividualWithoutExtension: attachmentNameIndividualWithoutExtension,
      attachmentNameIndividual: `${attachmentNameIndividualWithoutExtension}.xlsx`,
      indexAttachment: -1,
      key: 1,
    };
  },
  computed: {
    internalUsefulVacationDocument() {
      return this.usefulVacationDocument.attachments[0];
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'voltageClassList',
      'typeAccountingList',
      'typeAccountingListLong',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'tariffCategoryTypeList',
    ]),
  },
  watch: {
    filledDocuments: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          return;
        }

        this.$emit('parseAttachment', val[0]);
        this.filledDocuments = [];
      },
    },
  },
  methods: {
    addPoint() {
      this.contractData.attachment1.push(
        [],
      );

      this.scrollToEnd();
    },
    scrollToEnd() {
      setTimeout(() => {
        document.querySelector('[role=detailsContent]').scrollTo(0, document.body.scrollHeight);
      }, 200);
    },
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    isErrorConvert(errorArray, indexPoint, indexItem) {
      return isErrorConvert(errorArray, indexPoint, indexItem);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    indexUpload(index) {
      this.indexAttachment = index;
    },
    deleteDoc2(indexAttachment) {
      this.usefulVacationDocument.attachments[indexAttachment].attachmentFile = null;
      this.key++;
    },
    deleteFile(indexAttachment) {
      this.usefulVacationDocument.attachments.splice(indexAttachment, 1);
      this.$emit('preValidations');
    },
    deleteAttachment() {
      this.$emit('clearAttachment');
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.stepTitle
  +base-title
  margin-top: 32px
  margin-bottom: 32px

.fileName
  +base-title
  background-color: darkgray
  padding: 4px
  width: 100%

.smallTitle
  +card-title-small
  margin-top: 32px
  margin-bottom: 4px

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)


.form
  position: absolute
  width: 100%
  margin-bottom: 64px
  padding: 0 32px 0 0

  div
    overflow-x: auto

    &::-webkit-scrollbar-button
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 0
      border-radius: 10px
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb:hover
      background-color: #a19f9f

    &::-webkit-resizer
      background-repeat: no-repeat
      width: 10px
      height: 0

    &::-webkit-scrollbar
      width: 10px
      height: 10px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment, .fileArchive
  +base-subtitle

.attachment
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.downloadIcon
  display: flex
  align-items: center

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

  color: #4C4E51

.button
  +base-button-text
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer
  margin-left: 48px
  margin-top: 32px
  margin-bottom: 48px

  &:hover
    text-decoration: none

.change
  color: #979CA2

.save
  color: #2F82DF

.space
  opacity: 0

.errorText
  +small-text
  color: red

.textInputTd
  +input-text
  font-family: 'Roboto Condensed'
  color: gray

.textTableTd
  padding: 4px 16px

.tableTd
  td
    min-width: 200px

  td.width220
    min-width: 220px

.endGroup
  min-width: 200px

  textarea
    padding: 8px
    border: none

    &::-webkit-scrollbar
      width: 0
      height: 0

table.details
  width: 100%

  thead
    text-align: center

    td
      +table-text-small
      color: #0F1010
      padding-left: 8px
      padding-right: 24px
      padding-top: 12px
      padding-bottom: 12px
      vertical-align: middle
      border: 1px solid #71757A

    td.firstTitle
      text-align: left
      vertical-align: bottom

    td.noborder
      border: none

  tbody
    td
      border: 1px solid #71757A
      vertical-align: middle

      p
        margin: 4px

      label
        display: none

      input
        border: 0

        &:disabled
          color: #71757A

      select
        width: 100%
        height: 46px
        font-size: 18px
        padding-left: 12px
        border: 0
        outline: none

        &:disabled
          color: #71757A
          opacity: 1

      img
        width: 24px
        height: 24px
        margin-top: 12px
        cursor: pointer

      img.close
        width: 18px
        height: 18px
        margin-left: 8px
        margin-right: 24px

    td.dateField
      input
        width: 140px

      input:disabled
        background-color: #ffffff

    td.field100
      input
        width: 100px

      input:disabled
        background-color: #ffffff

    td.copy
      padding-left: 10px
      border-right: none

    td.delete, td.copy
      border: none

    .footer
      td
        border: none
        height: 22px

  thead
    tr.header
      td
        font-size: 16px
        background-color: #e5e5e5

  tbody > tr > th:first-child,
  tbody > tr > td:first-child
    width: 48px
    height: 48px
    background-color: white
    min-width: 60px

  td
    padding: 8px
    text-align: center
    margin: 0
</style>
