<template>
  <div>
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
    <v-table-new
      v-model="transformedData"
      :action-block-items="actionBlockItems"
      :template="template"
      excel-report-file-name="Баланс электрической энергии"
      @on-cell-click="cellClickHandler"
    />
  </div>
</template>

<script>
import { ModalProgressBar, VTableNew } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import { convertDbDateTimeFormat, convertDbFormat, getMonthString } from 'lib/utils/date';
import { completedByStatus, formatContractStatus, getContractStatusList } from 'lib/utils/formatXmlType';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'ContentBlock',
  components: {
    VTableNew,
    ModalProgressBar,
  },
  props: {
    balances: { type: Array, required: true },
  },
  data() {
    return {
      isModalVisible: false,
      isProcessComplete: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Название компании',
            key: 'companyName',
            sort: {},
            thStyle: {
              minWidth: '80px',
            },
            filter: {},
          },
          {
            label: 'Период',
            key: 'monthYear',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: { type: 'date', format: 'MMMM YYYY', selectType: 'month' },
          },
          {
            label: 'Заказчик',
            key: 'supplierName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Исполнитель 1',
            key: 'performerFirstName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Исполнитель 2',
            key: 'performerSecondName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Номер договора',
            key: 'contractNumber',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата договора',
            key: 'contractDate',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Статус',
            key: 'transformedStatus',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Услуги, кВт * ч',
            key: 'paymentVolumeFormatted',
            type: 'number',
            thStyle: {
              minWidth: '80px',
            },
            sort: {
              type: 'number',
            },
            filter: { type: 'number' },
          },
          {
            label: 'Потери, кВт * ч',
            key: 'totalLossFormatted',
            type: 'number',
            thStyle: {
              minWidth: '80px',
            },
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Статус со стороны Заказчик',
            key: 'supplierStatus',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны Исполнитель-1',
            key: 'performerFirstStatus',
            sort: {},
            thStyle: {
              minWidth: '101px',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны Исполнитель-2',
            key: 'performerSecondStatus',
            sort: {},
            thStyle: {
              minWidth: '101px',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              minWidth: '100px',
            },
            type: 'spoilerWithoutButton',
          },
          {
            label: 'Сформировано автоматически',
            key: 'autoGenerated',
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedData() {
      if (!this.balances[0]) return [];
      return this.balances.map((it) => ({
        ...it,
        contractNumber: it.transmissionContract?.contractInfo?.number,
        contractDate: convertDbFormat(it.transmissionContract?.contractInfo?.date),
        companyName: it.company?.name,
        supplierName: it.supplier.name,
        performerFirstName: it.performerFirst.name,
        performerSecondName: it.performerSecond.name,
        transformedStatus: formatContractStatus(it.status),
        monthYear: `${getMonthString(it.accountingPeriod.month)} ${it.accountingPeriod.year}`,
        rejectedReason: it.rejectedReason,
        supplierStatus: this.formatContractStatus(it.supplier.status),
        performerFirstStatus: this.formatContractStatus(it.performerFirst.status),
        performerSecondStatus: this.formatContractStatus(it.performerSecond.status),
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        paymentVolumeFormatted: Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(it.paymentVolume),
        totalLossFormatted: Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(it.totalLoss),
        balanceId: it.id,
        autoGenerated: it.autoGenerated ? 'Сформировано автоматически' : '',
        actVerificationDocumentId: it.actVerification?.documentId,
        document: { documentId: it.documentId, name: it.title },
        act: this.checkEmptyAct(it.actVerification),
      }));
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать баланс',
          method: goToPageByRoute('generate-balance'),
        },
        {
          id: 2,
          title: 'Сформировать баланс по участкам сети',
          method: goToPageByRoute('generate-planning-balance'),
        },
      ];
    },
    ...mapGetters('dictionary', ['balanceStatus']),
    ...mapGetters('user', ['getAccount', 'getUser']),
  },
  methods: {
    checkEmptyAct(act) {
      if (act) {
        return { documentId: act.documentId, name: 'Акт верификации' };
      }
      return null;

    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    async cellClickHandler(cell) {
      switch (cell.key) {
        case 'followLink': {
          if (cell.row.status === 'WAITING_VERIFICATION_ACT') {
            const domainDocument = await this.getDomainDocument(cell.row.balanceId);
            return await this.$router.push({
              name: 'cabinet.document-scan-upload',
              params: {
                ...domainDocument,
                documentXmlType: domainDocument.xmlDocumentType,
                documentCreatedAt: convertDbDateTimeFormat(domainDocument.createdAt),
                from: 'balances',
                fromTitle: 'БАЛАНС ЭЛЕКТРОЭНЕРГИИ',
              },
            });
          }
          let res;

          this.balances.forEach((balance) => {
            if (balance.id === cell.row.balanceId) {
              res = balance;
            }
          });

          return this.link(res);

        }
      }
    },
    link(task) {
      const currentSideStatus = [task.supplier, task.performerFirst, task.performerSecond].find((it) => it.accountId === this.getAccount.id).status;
      let workflowType;
      let title;
      switch (currentSideStatus) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        case 'REJECTED': {
          if (this.getUser.id !== task.initiatorUserId) {
            workflowType = 'documentSigning';
            break;
          }
          workflowType = 'documentReload';
          title = `Исправьте ${task.title}`;
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: task.id,
          from: 'balances',
          fromTitle: 'БАЛАНС ЭЛЕКТРОЭНЕРГИИ',
          to: workflowType,
          title: title,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    ...mapActions('document', ['loadDocument', 'getDomainDocument']),
  },
};
</script>
