var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',{attrs:{"title":"Отозванные сертификаты"}},[_c('div',{staticClass:"revoked-certificates"},[_c('v-table-new',{attrs:{"action-block-items":_vm.actionBlockItems,"loading":_vm.loading,"template":_vm.template,"excel-report-file-name":"Отозванные сертификаты"},on:{"on-cell-click":_vm.cellClickHandler},model:{value:(_vm.transformedRevokedCertificates),callback:function ($$v) {_vm.transformedRevokedCertificates=$$v},expression:"transformedRevokedCertificates"}}),(_vm.isAddModalShow)?_c('modal',{attrs:{"body-style":{
      padding: '24px 0',
    },"header-style":{
      display: 'flex',
      justifyContent: 'center',
    },"is-backdrop":true,"is-btn-close-visible":false,"modal-style":{
      borderRadius: '16px',
      padding: '50px 100px',
    }},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"revoked-certificates__modal__title"},[_vm._v("Добавление списка")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{on:{"submit":_vm.submitHandler}},[_c('div',{staticClass:"revoked-certificates__list-name"},[_c('v-input',{attrs:{"title":"Название списка"},model:{value:(_vm.listNameForAdded),callback:function ($$v) {_vm.listNameForAdded=$$v},expression:"listNameForAdded"}})],1),_c('v-input',{attrs:{"rules-messages":{revokedCertificatesUrl: 'Поле должно содержать ссылку на .crl файл'},"rules":"revokedCertificatesUrl","title":"Ссылка на список"},model:{value:(_vm.listLinkForAdded),callback:function ($$v) {_vm.listLinkForAdded=$$v},expression:"listLinkForAdded"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around","margin-top":"20px"}},[_c('v-button',{attrs:{"type":"submit"}},[_vm._v(" Добавить список ")]),_c('v-button',{attrs:{"type":"button","variant":"outlined"},on:{"click":_vm.closeModal}},[_vm._v(" Отменить ")])],1)],1)]},proxy:true}],null,false,4168069744)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }