<template>
  <modal
    v-if="visible"
    :body-style="{
        padding: '32px 0',
      }"
    :header-style="{
        fontWeight: '500',
        fontSize: '22px',
        color: '#4C4E51'
      }"
    :is-btn-close-visible="false"
    :modal-style="{
        padding: '32px 48px 40px 48px',
        borderRadius: '24px'
      }"
    width="960px"
  >
    <template #header>
      <div>Согласие на обработку персональных данных</div>
    </template>
    <template #body>
      <personal-data-agreement-step
        :agreement-text="agreementText"
        @scrolled-to-end="isAgreementTextScrolledToEnd"
      />
    </template>
    <template #footer>
      <div class="privacy-policy__footer">
        <div v-if="isSendCodeRequest" class="privacy-policy-code">
          <label class="privacy-policy-code__label">ВВЕДИТЕ ОДНОРАЗОВЫЙ ПАРОЛЬ ИЗ {{ signMethod }}</label>
          <masked-input
            id="user-code"
            v-model="code"
            :mask="[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]"
            :show-mask="true"
            role="code"
            style="font-size: 21px; text-align: center"
          />
          <div
            v-if="isShownErrorMessage"
            class="privacy-policy-code__error"
          >
            {{ confirmStatus.errorMessage }}
          </div>
          <div
            v-if="isSendCodeRequest"
            class="privacy-policy-code__timer"
          >
            <p v-if="timer > 0">
              выслать повторно через {{ timer }} СЕКУНД
            </p>
            <p
              v-else-if="timer === 0"
              role="sendCode"
              style="cursor: pointer;"
              @click="sendCode"

            >
              выслать повторно
            </p>
          </div>
        </div>
        <div class="privacy-policy-footer">
          <div v-if="isShowSignButton">
            <personal-data-agreement-footer
              v-if="!isSendCodeRequest"
              :is-select-disabled="!isSignButtonEnabled"
              @signMethod="changeSignMethod"
            />
            <v-button
              v-else
              :style="signButtonVariantStyle === 'disabled' ? { pointerEvents: 'none' } : {}"
              :variant="signButtonVariantStyle"
              @click="signAgreement"
            >
              Подписать ПЭП
            </v-button>
          </div>
          <div v-else>
            <v-button
              :disabled='!isSignButtonEnabled'
              variant="filled"
              @click="closeModal"
            >
              Согласен
            </v-button>
          </div>
        </div>
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from 'components/Modal/Modal';
import PersonalDataAgreementStep from 'components/PersonalDataAgreement/PersonalDataAgreementStep';
import PersonalDataAgreementFooter from 'components/PersonalDataAgreement/PersonalDataAgreementFooter';
import MaskedInput from 'vue-text-mask';
import VButton from 'components/VButton/VButton';
import PersonalDataAgreementMixin from 'components/PersonalDataAgreement/mixins/personalDataAgreementMixin';

export default {
  name: 'PersonalDataAgreementDialog',
  mixins: [PersonalDataAgreementMixin],
  components: {
    VButton,
    PersonalDataAgreementFooter,
    PersonalDataAgreementStep,
    MaskedInput,
    Modal,
  },
};
</script>

<style lang="scss" scoped>
.privacy-policy-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer {
  .privacy-policy__footer {
    .privacy-policy-code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__label {
        font-weight: 400;
        font-size: 22px;
        color: #4F4F4F;
        margin-bottom: 16px;
      }

      &__timer {
        p {
          font-weight: 500;
          font-size: 16px;
          color: #C1C4C7;
          text-transform: uppercase;
          margin-top: 16px;
        }
      }

      #user-code {
        width: 160px;
        height: 40px;
        border: 1px solid #ACB0B5;
      }
    }
  }
}

</style>
