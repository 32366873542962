import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
import {
  greaterZero,
  validateBik,
  validateDateAfter,
  validateInn,
  validateKpp,
  validateKs,
  validateOgrn,
  validateOkpo,
  validateOktmo,
  validateOkved,
  validatePassword,
  validateRs,
  validateSnils,
  validateTelegramAccount,
} from './documentsValidation.js';

const error = { message: '', code: null };

extend('snils', {
  validate(value) {
    return validateSnils(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('inn', {
  validate(value) {
    return validateInn(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('ogrn', {
  validate(value) {
    return validateOgrn(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('okved', {
  validate(value) {
    return validateOkved(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('oktmo', {
  validate(value) {
    return validateOktmo(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('okpo', {
  validate(value) {
    return validateOkpo(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('dateAfter', {
  validate(value, args) {
    return validateDateAfter(value, args.date, args.format, error);
  },
  message() {
    return `${error.message}`;
  },
  params: ['format', 'date'],
});

extend('bik', {
  validate(value) {
    return validateBik(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('ks', {
  validate(value) {
    return validateKs(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('rs', {
  validate(value) {
    return validateRs(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('kpp', {
  validate(value) {
    return validateKpp(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('password', {
  validate(value) {
    return validatePassword(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('telegramAccount', {
  validate(value) {
    return validateTelegramAccount(value, error);
  },
  message() {
    return `${error.message}`;
  },
});

extend('revokedCertificatesUrl', {
  validate(value) {
    const regex = new RegExp('^(https?:\\/\\/)?' + // протокол (например, http://)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // доменное имя...
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ...или IP-адрес
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // порт и путь
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // параметры запроса
      '(\\#[-a-z\\d_]*)?$', 'i'); // фрагмент идентификатора

    const getFileFormat = (str) => {
      const lastIndex = str.lastIndexOf('.');
      if (lastIndex !== -1) {
        const substr = str.substr(lastIndex + 1);
        return substr.trim();
      }
      return '';
    };

    return regex.test(value) && getFileFormat(value) === 'crl';
  },
  message() {
    return `${error.message}`;
  },
});

extend('oneTimeCode', {
  validate(value) {
    return value && value.replace(RegExp('[ _]?', 'g'), '').length === 4;
  },
  message() {
    return 'Код состоит из четырех цифр';
  },
});

extend('greaterZero', {
  validate(value) {
    return greaterZero(value, error);
  },
  message() {
    return `${error.message}`;
  },
});


Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('ru', ru);
