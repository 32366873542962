<template>
  <page title="Выданные ОДК со склада">
    <select-button
      :items="actionBlockItems"
    />
    <v-table-new
      v-model="transformedData"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="data.pageOut"
      :template="template"
      excel-report-file-name="Свод выдачи"
      server-side-sorting
      @on-sort="handleSort"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import * as XLSX from 'xlsx-js-style';
import SelectButton from 'components/SelectButton/SelectButton.vue';
import { mapFilters } from 'lib/utils/table-pagination';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'ExtraditeConstructionsReport',
  components: {
    SelectButton,
    Page,
    VTableNew,
  },
  data() {
    return {
      data: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      }, actionBlockItems: [
        {
          id: 1,
          title: 'Экспортировать все страницы в .XLSX',
          method: this.saveReport,
        },
      ],
    };
  },
  computed: {
    transformedData() {
      if (!this.data || !this.data.data || !Array.isArray(this.data.data)) {
        return [];
      }

      return this.data.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Подрядчик',
            key: 'contractorName',
            sort: {},
            filter: {},

            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Наименование ОДК',
            key: 'axpName',
            sort: {},
            filter: {},

            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Наименование элемента',
            key: 'elementName',
            sort: {},
            filter: {},

            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Общий долг',
            type: 'number',
            key: 'countElement',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '83px',
            },
          },
        ],
      };
    },
  },
  methods: {
    async transformFilter() {

      const filterMapping = {
        axpName: {
          name: 'axpName',
        },
        contractorName: {
          name: 'contractorName',
        },
        elemName: {
          name: 'elementName',
        },
      };

      return await mapFilters(filterMapping, this.filter);
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('extradite-constructions/report-filters', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.data = responseData;
        this.data.pageOut = this.pagination;
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('extradite-constructions/report-filters', 1, this.data.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed);
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async fetchReport() {
      const filters = await this.transformFilter();
      const { field: sortField, type: sort } = this.sort;
      return await fetchData('extradite-constructions/report-filters', 1, this.data.total, sortField, sort, filters);
    },
    async saveReport() {
      const reportData = await this.fetchReport();
      const workbook = XLSX.utils.book_new();

      const dataExcel = [
        [this.titleReport],
        [
          'Подрядчик',
          'Наименование ОДК',
          'Наименование элемента',
          'Праздник',
          'Количество',
        ],
      ];
      let sumCount = 0;

      reportData.data.forEach((el) => {
        const eventName =
          el.eventName != null ? el.eventName : 'Без праздника';
        sumCount = sumCount + el.countElement;
        dataExcel.push([
          el.contractorName,
          el.axpName,
          el.elementName,
          eventName,
          el.countElement,
        ]);
      });
      dataExcel.push(['Итого', '', '', '', sumCount]);

      console.log(dataExcel);

      const worksheet = XLSX.utils.aoa_to_sheet(dataExcel);

      worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
      worksheet['!merges'].push({
        s: { r: dataExcel.length - 1, c: 0 },
        e: { r: dataExcel.length - 1, c: 3 },
      });

      const styles = {
        mainHeader: {
          font: {
            bold: true,
            name: 'Times New Roman', // Font name
            sz: 16, // Font size
          },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
            bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
            left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
            right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
          },
          alignment: {
            // Center align the text horizontally and vertically
            horizontal: 'center',
            vertical: 'center',
          },
        },
        header: {
          font: {
            bold: true,
            name: 'Times New Roman', // Font name
            sz: 14, // Font size
          },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
            bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
            left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
            right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
          },
        },
        allCell: {
          font: {
            name: 'Times New Roman', // Font name
            sz: 14, // Font size
          },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
            bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
            left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
            right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
          },
          wrapText: true,
        },
        footer: {
          font: {
            name: 'Times New Roman', // Font name
            sz: 14, // Font size
          },
          alignment: {
            // Center align the text horizontally and vertically
            horizontal: 'right',
            vertical: 'center',
          },
        },
      };

      const cellAddressHeader = XLSX.utils.encode_cell({
        r: 0,
        c: 0,
      });
      getOrCreateCell(cellAddressHeader, styles.mainHeader);
      const cellAddressFooter1 = XLSX.utils.encode_cell({
        r: dataExcel.length - 1,
        c: 0,
      });
      getOrCreateCell(cellAddressFooter1, styles.footer);
      const cellAddressFooter2 = XLSX.utils.encode_cell({
        r: dataExcel.length - 1,
        c: 4,
      });
      getOrCreateCell(cellAddressFooter2, styles.footer);

      console.log('go style');
      const headerRange = XLSX.utils.decode_range('A2:E2');
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        // Object.assign(worksheet[cellAddress].s, styles.header)
        getOrCreateCell(cellAddress, styles.header);
        console.log('ok header');
      }

      // Apply styles to the numeric cells
      const numberCellRange = XLSX.utils.decode_range(
        `A3:E${dataExcel.length - 1}`,
      );
      for (let row = numberCellRange.s.r; row <= numberCellRange.e.r; row++) {
        for (let col = numberCellRange.s.c; col <= numberCellRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          // bject.assign(worksheet[cellAddress].s, styles.allCell)
          getOrCreateCell(cellAddress, styles.allCell);
        }
      }

      const colWidths = [];
      for (let col = 0; col < dataExcel[1].length; col++) {
        let maxWidth = -1;
        for (let row = 1; row < dataExcel.length; row++) {
          const cellValue = dataExcel[row][col]
            ? dataExcel[row][col].toString()
            : '';
          // const cellWidth = XLSX.utils.str_utf8_length(cellValue);
          const cellWidth = cellValue.length + (cellValue.length / 2);
          maxWidth = Math.max(maxWidth, cellWidth);
        }
        colWidths.push({ wch: maxWidth });
      }
      console.log(colWidths);
      worksheet['!cols'] = colWidths;

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, 'report.xlsx');

      function getOrCreateCell(cellAddress, style) {
        if (!worksheet[cellAddress]) {
          worksheet[cellAddress] = { t: 's' };
        }
        if (!worksheet[cellAddress].s) {
          worksheet[cellAddress].s = {};
        }
        Object.assign(worksheet[cellAddress].s, style);
      }
    },
    transformed(item, index) {
      return {
        index: index + 1,
        contractorName: item.contractorName,
        axpName: item.axpName,
        elementName: item.elementName,
        countElement: item.countElement,
      };
    },
  },
};
</script>

<style scoped>

</style>
