<template>
  <page title="Акты допуска прибора учёта в эксплуатацию">
    <content-block :action-block-items="actionBlockItems" :acts="acts" :loading="loading" />
  </page>
</template>

<script>
import { Page } from '@/components';
import ContentBlock from 'views/private/ActsAuthorizationMeteringDevice/components/ContentBlock.vue';
import { mapActions } from 'vuex';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'ActsAuthorizationMeteringDevice',
  components: { ContentBlock, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      acts: [],
      loading: false,
    };
  },
  computed: {
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать акт допуска',
          method: goToPageByRoute('acts-authorization-metering-device/certificate-of-admission-upload'),
        },
        {
          id: 2,
          title: 'Сформировать акт проверки прибора учёта электроэнергии инструментальной/визуальной',
          method: goToPageByRoute('reading-sheets/indications-single-upload'),
        },
        {
          id: 3,
          title: 'Сформировать акт замены прибора учёта',
          method: goToPageByRoute('transport-meter-input-transit/generate-replacing-meter-act'),
        },
      ];
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.acts = await this.loadAdmissionCertificates();
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['loadAdmissionCertificates']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped lang="scss">

</style>
