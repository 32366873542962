import * as XLSX from 'xlsx-js-style';
import { BASE_URL } from '@/constants/api';
import { exportToExcel } from 'utils/excel';
import { formatDateFromOdkTimestamp, getCurrentDate } from 'lib/utils/date';

export async function saveReport11_5() {
  const data = await fetchReport11_5();
  const workbook = XLSX.utils.book_new();

  const dataExcel = [
    ['Выявленные замечания и их устранение'],
    [
      'ОДК',
      'подрядчик',
      'адрес эксплуатации',
      'выявленные замечания',
      'статус устранения замечания',
      'дата устранения',
      'выполненные работы',
      'затраченные материалы',
    ],
  ];
  data.localRepair.forEach((it) => {
    const inspection = it.inspection;
    const installationAssignmentForInspection =
      inspection.installationAssignmentForInspection;
    const adreska =
      installationAssignmentForInspection.installationConstruction.adreska;

    const complete = it.isComplete ? 'Исправлены' : 'Не исправлены';

    if (
      !inspection.isOldDefectNotValid &&
      installationAssignmentForInspection.defect.name != ''
    ) {
      dataExcel.push([
        adreska.axp.name,
        adreska.contractorName,
        adreska.name,
        installationAssignmentForInspection.defect.name,
        complete,
        formattedDate(it.createDate),
        it.defectComment,
        '',
      ]);
    }
    if (inspection.defects.length > 0) {
      inspection.defects.forEach((defect) => {
        dataExcel.push([
          adreska.axp.name,
          adreska.contractorName,
          adreska.name,
          defect.defect.name,
          complete,
          formattedDate(it.createDate),
          it.defectComment,
          '',
        ]);
      });
    }
  });

  console.log(dataExcel);

  const worksheet = XLSX.utils.aoa_to_sheet(dataExcel);

  worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];

  const styles = {
    mainHeader: {
      font: {
        bold: true,
        name: 'Times New Roman', // Font name
        sz: 16, // Font size
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
      alignment: {
        // Center align the text horizontally and vertically
        horizontal: 'center',
        vertical: 'center',
      },
    },
    header: {
      font: {
        bold: true,
        name: 'Times New Roman', // Font name
        sz: 14, // Font size
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
    },
    allCell: {
      font: {
        name: 'Times New Roman', // Font name
        sz: 14, // Font size
      },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Thin black top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Thin black bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Thin black left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Thin black right border
      },
      wrapText: true,
    },
  };

  const cellAddressHeader = XLSX.utils.encode_cell({
    r: 0,
    c: 0,
  });
  getOrCreateCell(cellAddressHeader, styles.mainHeader);
  const cellAddressFooter1 = XLSX.utils.encode_cell({
    r: dataExcel.length - 1,
    c: 0,
  });
  getOrCreateCell(cellAddressFooter1, styles.footer);
  const cellAddressFooter2 = XLSX.utils.encode_cell({
    r: dataExcel.length - 1,
    c: 4,
  });
  getOrCreateCell(cellAddressFooter2, styles.footer);

  console.log('go style');
  const headerRange = XLSX.utils.decode_range('A2:H2');
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const cellAddress = XLSX.utils.encode_cell({
      r: headerRange.s.r,
      c: col,
    });
    // Object.assign(worksheet[cellAddress].s, styles.header)
    getOrCreateCell(cellAddress, styles.header);
    console.log('ok header');
  }

  // Apply styles to the numeric cells
  const numberCellRange = XLSX.utils.decode_range(
    `A3:H${dataExcel.length}`,
  );
  for (let row = numberCellRange.s.r; row <= numberCellRange.e.r; row++) {
    for (let col = numberCellRange.s.c; col <= numberCellRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      // bject.assign(worksheet[cellAddress].s, styles.allCell)
      getOrCreateCell(cellAddress, styles.allCell);
    }
  }

  const colWidths = [];
  for (let col = 0; col < dataExcel[1].length; col++) {
    let maxWidth = -1;
    for (let row = 1; row < dataExcel.length; row++) {
      const cellValue = dataExcel[row][col]
        ? dataExcel[row][col].toString()
        : '';
      // const cellWidth = XLSX.utils.str_utf8_length(cellValue);
      const cellWidth = cellValue.length + (cellValue.length / 2);
      maxWidth = Math.max(maxWidth, cellWidth);
    }
    colWidths.push({ wch: maxWidth });
  }
  console.log(colWidths);
  worksheet['!cols'] = colWidths;

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  XLSX.writeFile(workbook, 'report.xlsx');

  function getOrCreateCell(cellAddress, style) {
    if (!worksheet[cellAddress]) {
      worksheet[cellAddress] = { t: 's' };
    }
    if (!worksheet[cellAddress].s) {
      worksheet[cellAddress].s = {};
    }
    Object.assign(worksheet[cellAddress].s, style);
  }
}

async function fetchReport11_5() {
  const response = await fetch(`${BASE_URL}/local-repair/all`);
  return await response.json();
}


function formattedDate(unixTimestamp) {
  const newDate = new Date(unixTimestamp * 1000);
  let date = newDate.getDate();
  date = date < 10 ? `0${date}` : date;
  let month = newDate.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const newDateText = `${newDate.getFullYear()}-${month}-${date}`;
  if (newDateText == '1970-01-01') {
    return unixTimestamp;
  }
  return newDateText;

}

async function fetchSummaryIssuanceReport(eventId) {
  const response = await fetch(`${BASE_URL}/warehouse-extradition-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    summary: it.totalCount,
    defect: it.defecatedCount,
    issued: it.extraditeCount,
  }));
}

async function fetchSummaryReceptionReport(eventId) {
  const response = await fetch(`${BASE_URL}/warehouse-acceptance-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    summary: it.totalCount,
    recalculated: it.recalculatedCount,
    accepted: it.acceptedCount,
  }));
}

async function fetchDismantlingReport(eventId) {
  const response = await fetch(`${BASE_URL}/dismantling-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    plan: it.planCount,
    fact: it.factCount,
    startDate: it.planDismantlingStartDate,
    endDate: it.planDismantlingEndDate,
  }));
}

async function fetchInstallationReportByAdministrativeDistricts(eventId) {
  const response = await fetch(`${BASE_URL}/installation-by-administrative-district-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.administrativeDistrictName,
    plan: it.plan,
    no: it.no,
    hours24: it.hours24,
    axp: it.axp,
    notLight: it.notLight,
    mounted: it.mounted,
    connected: it.connected,
    enabled: it.enabled,
  }));
}

async function fetchInstallationReport(eventId) {
  const response = await fetch(`${BASE_URL}/installation-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    plan: it.planCount,
    fact: it.factCount,
    connected: it.connectedCount,
    enabled: it.enabledCount,
  }));
}

async function fetchInspectionTotalReport(eventId) {
  const response = await fetch(`${BASE_URL}/inspection-total-report?eventId=${eventId}`);
  const data = await response.json();
  return {
    countPlan: data.countPlan,
    countFact: data.countFact,
    countInspected: data.countInspected,
    countNonRoutine: data.countNonRoutine,
    incidents: data.defects?.map((defect) => ({
      id: String(defect.defectId),
      label: defect.defectName,
      defectCount: defect.defectCount,
    })) ?? [],
  };
}

async function fetchExploitationReport(eventId) {
  const response = await fetch(`${BASE_URL}/local-repair-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    quantity: it.totalCount,
    comments: it.totalDefectCount,
    incidents: it.defects?.map((defect) => ({
      id: String(defect.defectId),
      label: defect.defectName,
      quantity: defect.defectCount,
    })) ?? [],
  }));
}

async function fetchLocalRepairByAdministrativeDistrictsExploitationReport(eventId) {
  const response = await fetch(`${BASE_URL}/local-repair-by-administrative-district-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.administrativeDistrictName,
    quantity: it.totalCount,
    comments: it.totalDefectCount,
    incidents: it.defects?.map((defect) => ({
      id: String(defect.defectId),
      label: defect.defectName,
      quantity: defect.defectCount,
      repairedCount: defect.repairedCount,
    })) ?? [],
  }));
}

async function fetchLocalRepairByAdreskaExploitationReport(eventId) {
  const response = await fetch(`${BASE_URL}/local-repair-by-adreska-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    adreskaName: it.adreskaName,
    countPlan: it.countPlan,
    countFact: it.countFact,
    workMode: it.workMode,
    odkName: it.odkName,
    section: it.section,
    lastInspectionName: it.lastInspectionName,
    lastInspectionDate: it.lastInspectionDate,
    lastInspectionComment: it.lastInspectionComment,
    totalDefectsCount: it.totalDefectsCount,
    incidents: it.defects?.map((defect) => ({
      id: String(defect.defectId),
      label: defect.defectName,
      count: defect.defectCount,
      repairedCount: defect.repairedCount,
    })) ?? [],
  }));
}

async function fetchLocalRepairByAdministrativeDistrictsAndContractorsExploitationReport(eventId) {
  const response = await fetch(`${BASE_URL}/local-repair-by-administrative-district-and-contractors-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.contractorName,
    quantity: it.totalCount,
    defectsQuantity: it.totalDefectCount,
    incidents: it.defects?.map((defect) => ({
      id: String(defect.administrativeDistrictId),
      label: defect.administrativeDistrictName,
      quantity: defect.defectCount,
    })) ?? [],
  }));
}

async function fetchLightPercentReport(eventId) {
  const response = await fetch(`${BASE_URL}/lightning-percent-report?eventId=${eventId}`);
  const data = await response.json();
  return data.map((it) => ({
    name: it.administrativeDistrictName,
    quantity: it.countOdk,
    lightCount: it.countLights,
  }));
}


export async function saveSummaryIssuanceReport(eventId) {
  const data = await fetchSummaryIssuanceReport(eventId);

  const headers = [
    {
      label: '',
      key: 'name',
    },
    {
      label: 'ВСЕГО АХП ',
      key: 'summary',
    },
    {
      label: 'Отдефектовано АХП',
      key: 'defect',
    },
    {
      label: 'Выдано АХП',
      key: 'issued',
    },
    {
      label: 'Ещё НЕ выдано',
      key: 'notIssued',
    },
    {
      label: 'Процент отдефектованных от ВСЕХ',
      key: 'summaryPercent',
    },
    {
      label: 'Процент выданных АХП',
      key: 'issuedPercent',
    },
  ];

  const total = {
    summary: 0,
    defect: 0,
    issued: 0,
  };
  data.forEach((item) => {
    total.summary += item.summary ?? 0;
    total.defect += item.defect ?? 0;
    total.issued += item.issued ?? 0;
  });

  const totalRow = [
    'ВСЕ ПОДРЯДЧИКИ',
    total.summary,
    total.defect,
    total.issued,
    total.summary - total.issued,
    total.summary ? `${((total.defect / total.summary) * 100).toFixed(2)}%` : '',
    total.summary ? `${((total.issued / total.summary) * 100).toFixed(2)}%` : '',
  ];

  return exportToExcel(
    'ВЫДАЧА АХП по подрядчикам',
    headers,
    'label',
    data.map((item) => ({
      ...item,
      notIssued: item.summary - item.issued,
      summaryPercent: item.summary ? `${((item.defect / item.summary) * 100).toFixed(2)}%` : '',
      issuedPercent: item.summary ? `${((item.issued / item.summary) * 100).toFixed(2)}%` : '',
    })),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
        title: 'СВОД ВЫДАЧИ АХП ВСЕХ',
        isDisplayHeader: true,
      },
    },
  );
}

export async function saveSummaryReceptionReport(eventId) {
  const data = await fetchSummaryReceptionReport(eventId);

  const headers = [
    {
      label: '',
      key: 'name',
    },
    {
      label: 'ВСЕГО АХП ',
      key: 'summary',
    },
    {
      label: 'ПРИНЯТО АХП ',
      key: 'accepted',
    },
    {
      label: 'НЕ ПРИНЯТО АХП ',
      key: 'notAccepted',
    },
    {
      label: 'ПЕРЕСЧИТАНО АХП ',
      key: 'recalculated',
    },
    {
      label: 'Процент сданных АХП',
      key: 'passedPercent',
    },
    {
      label: 'Процент пересчитанных АХП от ВСЕХ',
      key: 'recalculatedPercentFromAll',
    },
    {
      label: 'Процент пересчитанных АХП от СДАННЫХ',
      key: 'recalculatedPercent',
    },
  ];

  const total = {
    summary: 0,
    accepted: 0,
    recalculated: 0,
  };
  data.forEach((item) => {
    total.summary += item.summary ?? 0;
    total.accepted += item.accepted ?? 0;
    total.recalculated += item.recalculated ?? 0;
  });

  const totalRow = [
    'ВСЕ ПОДРЯДЧИКИ',
    total.summary,
    total.accepted,
    total.summary - total.accepted,
    total.recalculated,
    total.summary ? `${((total.accepted / total.summary) * 100).toFixed(2)}%` : '',
    total.summary ? `${((total.recalculated / total.summary) * 100).toFixed(2)}%` : '',
    total.accepted ? `${((total.recalculated / total.accepted) * 100).toFixed(2)}%` : '',
  ];

  return exportToExcel(
    'Свод СДАЧИ АХП по ПОДРЯДЧИКАМ',
    headers,
    'label',
    data.map((item) => ({
      ...item,
      notAccepted: item.summary - item.accepted,
      passedPercent: item.summary ? `${((item.accepted / item.summary) * 100).toFixed(2)}%` : '',
      recalculatedPercentFromAll: item.summary ? `${((item.recalculated / item.summary) * 100).toFixed(2)}%` : '',
      recalculatedPercent: item.accepted ? `${((item.recalculated / item.accepted) * 100).toFixed(2)}%` : '',
    })),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
        title: 'СВОД СДАЧИ АХП ВСЕХ',
        isDisplayHeader: true,
      },
    },
  );
}

export async function saveDismantlingReport(eventId) {
  const data = await fetchDismantlingReport(eventId);
  const topHeaders = [
    {
      label: 'ОДК',
    },
    {
      label: 'ДЕМОНТАЖ',
      merge: 2,
    },
  ];
  const infoData = [];
  if (data[0]) {
    infoData.push([`Демонтажные работы - с ${formatDateFromOdkTimestamp(data[0].startDate)} по ${formatDateFromOdkTimestamp(data[0].endDate)}`]);
  }
  const headers = [
    {
      label: `По состоянию на ${getCurrentDate('hh:mm DD.MM.YYYY')}`,
      key: 'name',
    },
    {
      label: 'ПЛАН',
      key: 'plan',
    },
    {
      label: 'ФАКТ',
      key: 'fact',
    },
    {
      label: '%',
      key: 'percent',
    },
  ];

  const total = {
    plan: 0,
    fact: 0,
  };
  data.forEach((item) => {
    total.plan += item.plan ?? 0;
    total.fact += item.fact ?? 0;
  });

  const totalRow = [
    'ИТОГО КОЛИЧЕСТВО',
    total.plan,
    total.fact,
    total.plan ? `${((total.fact / total.plan) * 100).toFixed(2)}%` : '',
  ];

  return exportToExcel(
    'Информация по демонтажу по подрядчикам',
    headers,
    'label',
    data.map((item) => ({
      ...item,
      percent: item.plan ? `${((item.fact / item.plan) * 100).toFixed(2)}%` : '',
    })),
    'key',
    {
      alignment: 'center',
      topHeaders: topHeaders,
      infoData: infoData,
      total: {
        row: totalRow,
      },
      widthRate: 2,
    },
  );
}

export async function saveInstallationReportByAdministrativeDistricts(eventId) {
  const data = await fetchInstallationReportByAdministrativeDistricts(eventId);

  const topHeaders = [
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: 'Режим работы',
      merge: 3,
    },
    {
      label: 'Информация по монтажу',
      merge: 3,
    },
  ];
  const headers = [
    {
      label: '',
      key: 'name',
    },
    {
      label: 'Всего',
      key: 'plan',
    },
    {
      label: 'НО',
      key: 'no',
    },
    {
      label: '24',
      key: 'hours24',
    },
    {
      label: 'АХП',
      key: 'axp',
    },
    {
      label: 'Не световые',
      key: 'notLight',
    },
    {
      label: 'Смонтировано',
      key: 'notLight',
    },
    {
      label: 'Подключено',
      key: 'notLight',
    },
    {
      label: 'Включено',
      key: 'notLight',
    },
    {
      label: '%',
      key: 'percent',
    },
  ];

  const total = {
    plan: 0,
    no: 0,
    hours24: 0,
    axp: 0,
    notLight: 0,
    mounted: 0,
    connected: 0,
    enabled: 0,
  };

  data.forEach((item) => {
    total.plan += item.plan ?? 0;
    total.no += item.no ?? 0;
    total.hours24 += item.hours24 ?? 0;
    total.axp += item.axp ?? 0;
    total.notLight += item.notLight ?? 0;
    total.mounted += item.mounted ?? 0;
    total.connected += item.connected ?? 0;
    total.enabled += item.enabled ?? 0;
  });

  const totalRow = [
    'ИТОГО КОЛИЧЕСТВО',
    total.plan,
    total.no,
    total.hours24,
    total.axp,
    total.notLight,
    total.mounted,
    total.connected,
    total.enabled,
    total.plan ? `${((total.enabled / total.plan) * 100).toFixed(0)}%` : '',
  ];
  return exportToExcel(
    'Свод монтажа по административным округам',
    headers,
    'label',
    data.map((item) => ({
      ...item,
      percent: item.plan ? `${((item.enabled / item.plan) * 100).toFixed(0)}%` : '',
    })),
    'key',
    {
      topHeaders: topHeaders,
      alignment: 'center',
      total: {
        row: totalRow,
      },
      widthRate: 2,
    },
  );
}

export async function saveInstallationReport(eventId) {
  const data = await fetchInstallationReport(eventId);
  const topHeaders = [
    {
      label: 'ОДК',
    },
    {
      label: 'УСТАНОВКА',
      merge: 2,
    },
    {
      label: 'ПОДКЛЮЧЕНИЕ',
      merge: 2,
    },
    {
      label: 'ВКЛЮЧЕНИЕ',
      merge: 2,
    },
  ];
  const headers = [
    {
      label: '',
      key: 'name',
    },
    {
      label: 'ПЛАН',
      key: 'plan',
    },
    {
      label: 'ФАКТ',
      key: 'fact',
    },
    {
      label: '%',
      key: 'mountedPercent',
    },
    {
      label: 'ПЛАН',
      key: 'plan',
    },
    {
      label: 'ФАКТ',
      key: 'connected',
    },
    {
      label: '%',
      key: 'connectedPercent',
    },
    {
      label: 'ПЛАН',
      key: 'plan',
    },
    {
      label: 'ФАКТ',
      key: 'enabled',
    },
    {
      label: '%',
      key: 'enabledPercent',
    },
  ];

  const total = {
    plan: 0,
    fact: 0,
    enabled: 0,
    connected: 0,
  };
  data.forEach((item) => {
    total.plan += item.plan ?? 0;
    total.fact += item.fact ?? 0;
    total.enabled += item.enabled ?? 0;
    total.connected += item.connected ?? 0;
  });

  const totalRow = [
    'ИТОГО КОЛИЧЕСТВО',
    total.plan,
    total.fact,
    total.plan ? `${((total.fact / total.plan) * 100).toFixed(2)}%` : '',
    total.plan,
    total.connected,
    total.plan ? `${((total.connected / total.plan) * 100).toFixed(2)}%` : '',
    total.plan,
    total.enabled,
    total.plan ? `${((total.enabled / total.plan) * 100).toFixed(2)}%` : '',
  ];

  return exportToExcel(
    'Свод монтажа по подрядчикам',
    headers,
    'label',
    data.map((item) => ({
      ...item,
      mountedPercent: item.plan ? `${((item.fact / item.plan) * 100).toFixed(2)}%` : '',
      connectedPercent: item.plan ? `${((item.connected / item.plan) * 100).toFixed(2)}%` : '',
      enabledPercent: item.plan ? `${((item.enabled / item.plan) * 100).toFixed(2)}%` : '',
    })),
    'key',
    {
      alignment: 'center',
      topHeaders: topHeaders,
      total: {
        row: totalRow,
      },
      widthRate: 2,
    },
  );
}

export async function saveLocalRepairByAdreskaExploitationReport(eventId) {
  const data = await fetchLocalRepairByAdreskaExploitationReport(eventId);

  const topHeaders = [
    {
      label: '№\nп/п',
      key: 'index',
    },
    {
      label: 'Участок осмотра',
      key: 'section',
    },
    {
      label: 'Наименование ОДК',
      key: 'odkName',
    },
    {
      label: 'Адресная программа',
      key: 'adreskaName',
    },
    {
      label: 'Количество план, шт.',
      key: 'countPlan',
    },
    {
      label: 'Количество факт, шт.',
      key: 'countFact',
    },
    {
      label: 'Режим работы',
      key: 'workMode',
    },
    {
      label: 'Инспектор',
      key: 'lastInspectionName',
    },
    {
      label: 'Дата обследования',
      key: 'lastInspectionDate',
    },
    {
      label: 'Примечание инспектора',
      key: 'lastInspectionComment',
    },
    {
      label: 'Общее количество дефектов',
      key: 'totalDefectsCount',
    },
  ];

  const total = {
    countPlan: 0,
    countFact: 0,
    totalDefectsCount: 0,
  };

  const incidentIds = [];
  data.forEach((item) => {
    total.countPlan += item.countPlan;
    total.countFact += item.countFact;
    total.totalDefectsCount += item.totalDefectsCount,
      item.incidents.forEach((incident) => {
        if (!topHeaders.find((header) => header.label === incident.label)) {
          topHeaders.push({ label: incident.label, key: `${incident.id}_quantity` });
          incidentIds.push(incident.id);
        }
        total[`${incident.id}_quantity`] = (total[`${incident.id}_quantity`] ?? 0) + incident.count ?? 0;
      });
  });

  const totalRow = [
    '',
    '',
    '',
    'Итого',
    total.countPlan,
    total.countFact,
    '',
    '',
    '',
    '',
    total.totalDefectsCount,
  ];

  incidentIds.forEach((id) => {
    totalRow.push(total[`${id}_quantity`]);
  });

  return exportToExcel(
    'Замечания в работе ОДК по списку адресов',
    topHeaders,
    'label',
    data.map((item, index) => {
      const result = {
        index: index + 1,
        odkName: item.odkName,
        adreskaName: item.adreskaName,
        section: item.section,
        countPlan: item.countPlan,
        countFact: item.countFact,
        workMode: item.workMode,
        lastInspectionName: item.lastInspectionName ?? '-',
        lastInspectionDate: item.lastInspectionDate ? formatDateFromOdkTimestamp(item.lastInspectionDate) : '-',
        lastInspectionComment: item.lastInspectionComment ?? '-',
        totalDefectsCount: item.totalDefectsCount,
      };

      item.incidents.forEach((incident) => {
        result[`${incident.id}_quantity`] = incident.count;
      });

      return result;
    }),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
      },
      emptyValue: '0',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  );
}


export async function saveLocalRepairByAdministrativeDistrictsExploitationReport(eventId) {
  const data = await fetchLocalRepairByAdministrativeDistrictsExploitationReport(eventId);

  const topHeaders = [
    {
      label: '№\nп/п',
    },
    {
      label: 'Административный округ',
    },
    {
      label: 'Количество ОДК, шт.',
    },
  ];

  const headers = [
    {
      label: '',
      key: 'index',
    },
    {
      label: '',
      key: 'name',
    },
    {
      label: '',
      key: 'quantity',
    },
  ];

  const total = {
    quantity: 0,
  };

  data.forEach((item) => {
    total.quantity += item.quantity;
    item.incidents.forEach((incident) => {
      if (!topHeaders.find((header) => header.label === incident.label)) {
        topHeaders.push({ label: incident.label, merge: 1 });
        headers.push({ label: 'Выявлено', key: `${incident.id}_quantity` });
        headers.push({ label: 'Устранено', key: `${incident.id}_repairedCount` });
      }
      total[`${incident.id}_quantity`] = (total[`${incident.id}_quantity`] ?? 0) + incident.quantity ?? 0;
      total[`${incident.id}_repairedCount`] = (total[`${incident.id}_repairedCount`] ?? 0) + incident.repairedCount ?? 0;
    });
  });

  const totalRow = [
    '',
    'Итого',
  ];

  total.comments_quantity_percent = '-';
  headers.forEach((header) => {
    if (total[header.key] || total[header.key] === 0) {
      totalRow.push(total[header.key]);
    }
  });

  return exportToExcel(
    'Замечания в работе ОДК по административным округам',
    headers,
    'label',
    data.map((item, index) => {
      const result = {
        index: index + 1,
        name: item.name,
        quantity: item.quantity,
      };

      item.incidents.forEach((incident) => {
        result[`${incident.id}_quantity`] = incident.quantity;
        result[`${incident.id}_repairedCount`] = incident.repairedCount;
      });

      return result;
    }),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
      },
      // widthRate: 2,
      topHeaders: topHeaders,
      emptyValue: '0',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  );
}

export async function saveLocalRepairByAdministrativeDistrictsAndContractorsExploitationReport(eventId) {
  const data = await fetchLocalRepairByAdministrativeDistrictsAndContractorsExploitationReport(eventId);

  const topHeaders = [
    {
      label: '№\nп/п',
    },
    {
      label: '№\nп/п',
    },
    {
      label: 'Подрядчик',
    },
    {
      label: 'Количество ОДК, шт.',
    },
    {
      label: 'Количество замечаний, шт.',
    },
    {
      label: 'АО',
      merge: -2,
    },
  ];

  const headers = [
    {
      label: '',
      key: 'index',
    },
    {
      label: '',
      key: 'name',
    },
    {
      label: '',
      key: 'quantity',
    },
    {
      label: '',
      key: 'defectsQuantity',
    },
  ];

  const total = {
    quantity: 0,
    defectsQuantity: 0,
  };

  console.log('data', data);
  data.forEach((item) => {
    total.quantity += item.quantity;
    item.incidents.forEach((incident) => {
      if (!headers.find((header) => header.label === incident.label)) {
        headers.push({ label: incident.label, key: `${incident.id}_quantity` });
        topHeaders[topHeaders.length - 1].merge += 1;
      }
      total[`${incident.id}_quantity`] = (total[`${incident.id}_quantity`] ?? 0) + incident.quantity ?? 0;
    });
  });

  const totalRow = [
    '',
    'Итого',
  ];

  headers.forEach((header) => {
    if (total[header.key] || total[header.key] === 0) {
      totalRow.push(total[header.key]);
    }
  });

  return exportToExcel(
    'Замечания в работе ОДК по подрядчикам и административным округам',
    headers,
    'label',
    data.map((item, index) => {
      const result = {
        index: index + 1,
        name: item.name,
        quantity: item.quantity,
        defectsQuantity: item.defectsQuantity,
      };

      item.incidents.forEach((incident) => {
        result[`${incident.id}_quantity`] = incident.quantity;
      });

      return result;
    }),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
      },
      // widthRate: 2,
      topHeaders: topHeaders,
      emptyValue: '0',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  );
}

export async function saveLightPercentReport(eventId) {
  const data = await fetchLightPercentReport(eventId);
  const topHeaders = [
    {
      label: '№\nп/п',
    },
    {
      label: 'АО',
    },
    {
      label: 'Количество ОДК, шт.',
    },
    {
      label: 'Факт горения ОДК',
      merge: 1,
    },
  ];

  const headers = [
    {
      label: '',
      key: 'index',
    },
    {
      label: '',
      key: 'name',
    },
    {
      label: '',
      key: 'quantity',
    },
    {
      label: 'шт.',
      key: 'lightCount',
    },
    {
      label: '%',
      key: 'lightPercent',
    },
  ];

  const total = {
    quantity: 0,
    lightCount: 0,
    lightPercent: 0,
  };

  data.forEach((item) => {
    total.quantity += item.quantity;
    total.lightCount += item.lightCount;
  });
  let percent = Number(total.lightCount / total.quantity * 100);
  if (isNaN(percent)) percent = 0;
  total.lightPercent = percent.toFixed(2);
  const totalRow = [
    '',
    'Итого',
    total.quantity,
    total.lightCount,
    total.lightPercent,
  ];

  return exportToExcel(
    'Горение ОДК по административным округам',
    headers,
    'label',
    data.map((item, index) => {
      let percent = Number(item.lightCount / item.quantity * 100);
      if (isNaN(percent)) percent = 0;
      return {
        index: index + 1,
        name: item.name,
        quantity: item.quantity,
        lightCount: item.lightCount,
        lightPercent: percent.toFixed(2),
      };
    }),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
      },
      // widthRate: 2,
      topHeaders: topHeaders,
      emptyValue: '0',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  );
}

export async function saveExploitationReport(eventId) {
  const data = await fetchExploitationReport(eventId);

  const topHeaders = [
    {
      label: '№\nп/п',
    },
    {
      label: 'Подрядная\nорганизация',
    },
    {
      label: 'Количество ОДК',
      merge: 1,
    },
    {
      label: 'Количество замечаний, в том числе:',
      merge: 1,
    },
    {
      label: 'Удельное количество замечаний на ОДК, единиц/100 штук, в том числе:',
    },
  ];

  const headers = [
    {
      label: '',
      key: 'index',
    },
    {
      label: '',
      key: 'name',
    },
    {
      label: 'Штук',
      key: 'quantity_unit',
    },
    {
      label: 'Доля от общего количества, %',
      key: 'quantity_percent',
    },
    {
      label: 'Единиц',
      key: 'comments_unit',
    },
    {
      label: 'Доля от общего количества, %',
      key: 'comments_percent',
    },
    {
      label: '',
      key: 'comments_quantity_percent',
    },
  ];

  const total = {
    quantity_unit: 0,
    quantity_percent: '100%',
    comments_unit: 0,
    comments_percent: '100%',
  };

  data.forEach((item) => {
    total.quantity_unit += item.quantity ?? 0;
    total.comments_unit += item.comments ?? 0;
    item.incidents.forEach((incident) => {
      if (!topHeaders.find((header) => header.label === incident.label)) {
        topHeaders.push({ label: incident.label, merge: 1 });
        headers.push({ label: 'Единиц', key: `${incident.id}_unit` });
        headers.push({ label: 'Доля от общего количества, %', key: `${incident.id}_percent` });
      }
      total[`${incident.id}_unit`] = (total[`${incident.id}_unit`] ?? 0) + incident.quantity;
      total[`${incident.id}_percent`] = '100%';
    });
  });

  const totalRow = [
    '',
    'Итого',
  ];

  total.comments_quantity_percent = '-';
  headers.forEach((header) => {
    if (total[header.key] || total[header.key] === 0) {
      totalRow.push(total[header.key]);
    }
  });

  return exportToExcel(
    'Замечания в работе ОДК по подрядчикам',
    headers,
    'label',
    data.map((item, index) => {
      const result = {
        index: index + 1,
        name: item.name,
        quantity_unit: item.quantity,
        quantity_percent: ((item.quantity / total.quantity_unit) * 100).toFixed(2),
        comments_unit: item.comments,
        comments_percent: ((item.comments / total.comments_unit) * 100).toFixed(2),
        comments_quantity_percent: !item.quantity || item.quantity ? ((item.comments / item.quantity) * 100).toFixed(2) : '',
      };

      item.incidents.forEach((incident) => {
        result[`${incident.id}_unit`] = incident.quantity;
        result[`${incident.id}_percent`] = ((incident.quantity / total[`${incident.id}_unit`]) * 100).toFixed(2);
      });

      return result;
    }),
    'key',
    {
      alignment: 'center',
      total: {
        row: totalRow,
      },
      // widthRate: 2,
      topHeaders: topHeaders,
      emptyValue: '0',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  );
}


export async function saveInspectionsTotalReport(eventId) {
  const data = await fetchInspectionTotalReport(eventId);

  const headers = [
    {
      label: '',
      key: 'index',
    },
    {
      label: 'Количество план, шт.',
      key: 'countPlan',
    },
    {
      label: 'Количество факт, шт.',
      key: 'countFact',
    },
  ];

  data.incidents.forEach((incident) => {
    if (!headers.find((header) => header.label === incident.label)) {
      headers.push({ label: incident.label, key: incident.id });
    }
  });

  const firstRow = {
    index: 'Итого',
    countPlan: data.countPlan,
    countFact: data.countFact,
  };
  const secondRow = {
    index: 'Всего осмотрено объектов',
    countPlan: data.countInspected,
  };
  const thirdRow = {
    index: 'Не регламентных объектов',
    countPlan: data.countNonRoutine,
  };
  console.log('data', data.incidents);
  data.incidents.forEach((incident) => {
    firstRow[incident.id] = incident.defectCount;
    // TODO по хорошему ячейки надо мерджить
  });
  console.log(firstRow);
  return exportToExcel(
    `Контроль регламентного состояния ОДК на ${getCurrentDate('DD.MM.YYYY')}`,
    headers,
    'label',
    [firstRow, secondRow, thirdRow],
    'key',
    {
      alignment: 'center',
      emptyValue: '',
      maxWidth: 30,
      rowHeights: [{}, { hpt: 70 }, { hpt: 30 }],
    },
  )
    ;
}
