<template>
  <div v-if="certificates.length > 0" :class="$style.select">
    <select v-model="selected" :class="$style.capSelect">
      <option disabled value="null">Выберите сертификат</option>
      <option v-for="(cert, idx) in certificates" :key="idx" :value="idx">{{ cert.name }}
        Выдан:{{ formatDate(cert.validFrom) }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'CertificateSelector',
  components: {}, props: {
    certificates: { type: Array, required: true },
    value: { type: Object, default: null },
  },
  created() {
    this.renewCertificates();
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    selected() {
      this.emitSelected();
    },
    value(oldVal, newVal) {
      if (oldVal !== newVal) this.renewCertificates();
    },
    certificates(oldVal, newVal) {
      if (oldVal !== newVal) this.renewCertificates();
    },
  },
  methods: {
    renewCertificates() {
      this.certificates.forEach((value, idx) => {
        if (value === this.value) this.selected = idx;
      });
      if (this.certificates.length === 1 && this.selected === null) {
        this.selected = 0;
      }
    },
    emitSelected() {
      if (this.selected === null) this.$emit('input', null);
      this.$emit('input', this.certificates[this.selected]);
    },
    formatDate(date) {
      date = date.split('T');
      date = date[0].replace(/-/gi, '.').split('.').reverse()
        .join('.');

      return date;
    },
  },
};

</script>

<style lang="sass" module>
.capCheck
  width: fit-content
  margin-top: 55px
  text-align: center
  margin-left: auto
  margin-right: auto

  div.check
    display: flex
    margin-bottom: 16px

  p
    margin-left: 8px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  padding-left: 24px
  position: relative
  background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.capSelect
  width: 100%
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 21px
  line-height: 30px
  padding: 5px 16px
  margin-bottom: 52px
  appearance: none

.select
  position: relative
  display: inline-block
  width: 100%

  &:after
    content: url('~icons/basic/arrow_down_black.svg')
    position: absolute
    right: 16px
    top: 16px
    pointer-events: none
    box-sizing: border-box


</style>
