<template>
  <page title="Платёжные поручения">
    <content-block :payment-orders="paymentOrders" :loading="loading" />
  </page>
</template>

<script>
import ContentBlock from './components/ContentBlock.vue';
import { mapActions } from 'vuex';
import { Page } from '@/components';

export default {
  name: 'PaymentOrders',
  components: { ContentBlock, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      paymentOrders: [],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.paymentOrders = await this.getPaymentOrders();
      } catch (error) {
        console.log('paymentOrders', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('paymentOrders', ['getPaymentOrders']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
</style>
