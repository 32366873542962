import { formatDateFromOdkTimestamp } from 'lib/utils/date';

const isLoading = (state) => state.loading;
const getAdreska = (state) => state.adreska;
const getAdreskaEvent = (state) => (state.adreska ? state.adreska.event : null);
const getObjects = (state) => state.objects;

const objectsTableHeaders = () => ({
  headers: [
    {
      label: 'ID',
      key: 'id',
      size: 'sm',
      thStyle: {
        minWidth: '50px',
      },
    },
    {
      label: 'АХП тип',
      key: 'axpType',
      size: 'md',
      thStyle: {
        minWidth: '100px',
      },
    },
    {
      label: 'Дата монтажа',
      key: 'createDate',
      size: 'md',
      thStyle: {
        minWidth: '80px',
      },
    },
    {
      label: 'Широта',
      key: 'locationLatitude',
      size: 'md',
      thStyle: {
        minWidth: '100px',
      },
    },
    {
      label: 'Долгота',
      type: 'number',
      key: 'locationLongitude',
      size: 'md',
      thStyle: {
        minWidth: '100px',
      },
    },
  ],
});

const getObjectsForTable = (state) => {
  if (!Array.isArray(state.objects) || !state.objects.length) return [];

  return state.objects.map((item, index) => ({
    ...item,
    axpType: item.axp ? item.axp.name : '',
    createDate: formatDateFromOdkTimestamp(item.createDate),
    index: index + 1,
  }));
};

const getObjectsMapForTable = (state) => {
  if (!Array.isArray(state.objects) || !state.objects.length) return [];

  return state.objects.map((item) => ({
    ...item,
    balloonContent: {
      title: item.axp.odk.name ?? '',
      buttonLabel: 'Внести дефект',
      props: [
        {
          label: 'ID',
          value: item.id,
        },
        {
          label: 'АХП тип',
          value: item.axp.name ?? '',
        },
        {
          label: 'Дата монтажа',
          value: formatDateFromOdkTimestamp(item.createDate),
        },
        {
          label: 'Широта',
          value: item.locationLatitude,
        },
        {
          label: 'Долгота',
          value: item.locationLongitude,
        },
      ],
    },
  }));
};

export default {
  isLoading,
  getAdreska,
  getAdreskaEvent,
  getObjects,
  objectsTableHeaders,
  getObjectsForTable,
  getObjectsMapForTable,
};
