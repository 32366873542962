<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">Отчёт о снятии показаний ПУ в автоматическом режиме</p>

      <v-file-attachment
        :document-id="task.documentId"
        label='Отчёт о снятии показаний ПУ в автоматическом режиме'
      />
    </div>

    <div :class="$style.content">
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <submit-task-details :class="$style.submit" :disabled="isWaitingSubmit || xml === undefined"
                           title="Подписать" @submit="submit"/>
      <submit-task-details :class="$style.submit" :disabled="isWaitingSubmit || xml === undefined"
                           title="Закрыть задачу и перейти к ручной передаче информации по ПУ" @submit="submitClose"/>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { VFileAttachment } from 'components';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'MeterReport',
  components: {
    VFileAttachment,
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    task: Object,
  },
  created() {
    this.downloadXml();
  },
  data() {
    return {
      xml: undefined,
      viewUrl: undefined,
      waitingXml: false,
      isWaitingSubmit: false,
      submitError: '',
    };
  },
  computed: {
    xmlBlob() {
      if (!this.xml) {
        return '';
      }
      const blob = new Blob([this.xml], { type: 'text/xml' });
      return window.URL.createObjectURL(blob);
    },
  },
  methods: {
    async downloadXml() {
      if (this.xml) {
        return;
      }
      try {
        this.waitingXml = true;

        const pureData = await this.loadDocument(this.task.documentId);

        this.xml = pureData.xml;

        this.waitingXml = false;
      } catch (error) {
        console.log('error actVerificationDocument xml', error);
        this.waitingXml = false;
      }
    },
    async submit() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      try {
        const result = await this.documentUpload({ xml: this.xml, isCap: true });
        if (result) {
          await this.setWorkflows({ userType: this.getUserType });
          this.$emit('complete', true);
          this.$emit('close');
        }
      } catch (error) {
        if (error.name === 'CRYPTOPRO_CERTIFICATES_ERROR' || error.name === 'CRYPTOPRO_ERROR') {
          this.submitError = error.message;
        } else {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
        }
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    submitClose() {
      this.$router.push('/cabinet/reading-sheets');
    },
    ...mapActions('document', ['loadDocument', 'documentUpload', 'getDocumentByUrl']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  height: 90%

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

.disabled
  label
    cursor: default
    color: grey

.waiting
  a, span
    cursor: default !important

.submit
  margin-top: 32px

.errorText
  +small-text
  margin: 32px 4px -28px 4px
  color: red

</style>
