<template>
  <page title="Работа с дефектами">
    <action-block/>
    <data-tab-selector
      :data-types-list="tabs"
      tab-style="table"
      @selectTab="selectTabHandler"
    />

    <local-repair-summary-table v-if="selectedTab==='defects'"/>
    <regular-inspections-list-table v-else-if="selectedTab==='tasks'"
                                    :type="regularInspectionTaskType.INSPECTION"
                                    excel-report-file-name="Работа с дефектами. Осмотр"/>
    <regular-inspections-table v-else-if="selectedTab==='assignment'"/>
  </page>
</template>

<script>
import { DataTabSelector, Page } from '@/components';
import ActionBlock from './ActionBlock';
import LocalRepairSummaryTable from 'views/private/HandlingDefects/LocalRepairSummaryTable.vue';
import RegularInspectionsTable from 'views/private/HandlingDefects/RegularInspectionsTable.vue';
import RegularInspectionsListTable from 'views/private/HandlingDefects/RegularInspectionsListTable.vue';
import { mapGetters } from 'vuex';
import { regularInspectionTaskType } from 'components/Processes/list';
import { filterForAccess } from 'lib/utils/permissions';

export default {
  name: 'HandlingDefects',
  components: {
    RegularInspectionsListTable,
    RegularInspectionsTable,
    LocalRepairSummaryTable,
    DataTabSelector,
    Page,
    ActionBlock,
  },
  created() {
    const tabsByAccountKind = this.isBoiler ? this.boilerTabs : this.counterpartyTabs;
    const blockAccess = this.getAuthorization?.blockAccess;
    this.tabs = tabsByAccountKind.filter((tab) => filterForAccess(`handling-defects/${tab.value}`, blockAccess));
    if (this.tabs.length) {
      this.tabs[0].isActive = true;
      this.selectTabHandler(this.tabs[0]);
    }
  },
  data() {
    return {
      boilerTabs: [
        {
          id: 1,
          title: 'Назначение на осмотр',
          value: 'assignment',
          isActive: false,
        },
        {
          id: 2,
          title: 'Осмотр',
          value: 'tasks',
          isActive: false,
        },
        {
          id: 3,
          title: 'Список дефектов',
          value: 'defects',
          isActive: false,
        },
      ],
      counterpartyTabs: [
        {
          id: 3,
          title: 'Список дефектов',
          value: 'defects',
          isActive: false,
        },
      ],
      tabs: [],
      selectedTab: null,
      regularInspectionTaskType: regularInspectionTaskType,
    };
  },
  computed: {
    ...mapGetters('user', ['isBoiler', 'getAuthorization']),
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
  },
};
</script>

<style scoped>
</style>
