const SET_EMPLOYEES = (state, employees) => {
  state.employees = employees;
  state.internalEmployees = state.employees;
};

const SET_ROLES = (state, roles) => {
  state.roles = roles;
};

const SET_FILTER = (state, payload) => {
  state.employees = payload;
};

const SET_INVITATIONS = (state, payload) => {
  state.serverInvitations = payload;
  state.acceptedInvitations = payload.filter((it) => it.status === 'ACCEPTED');
  state.notAcceptedInvitations = payload.filter((it) => it.status === 'WAITING');
};

export default {
  SET_EMPLOYEES,
  SET_ROLES,
  SET_FILTER,
  SET_INVITATIONS,
};
