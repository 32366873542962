var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('div',{style:({
        position: 'absolute',
        top: '0',
        backgroundColor: '#ffffff',
        height: '40px',
        padding: '10px',
        zIndex: '100',
        width: _vm.navBarWidth,
        marginLeft: '-16px',
      })},[_c('navigation-bar',{style:({
        margin: '0',
      }),attrs:{"current-step":_vm.connectionApplication.currentStep,"steps":_vm.steps,"home":"Заявка на подключение объекта"},on:{"open":_vm.openStep}})],1),_c('div',{ref:"connectionApplicationContent",class:_vm.$style.content,attrs:{"role":"detailsContent"}},[_c('div',{class:_vm.$style.title},[_vm._v(" Заявка на подключение объекта "),(_vm.connectionApplication.currentStep !== 0)?_c('p',{class:_vm.$style.subTitle},[_vm._v(" "+_vm._s(_vm.type)+" ")]):_vm._e()]),_c(_vm.steps[_vm.connectionApplication.currentStep].component,{key:_vm.componentKey,ref:"details",tag:"component",attrs:{"component-key":_vm.componentKey,"connection-application":_vm.connectionApplication,"errors":_vm.errors,"is-additional-owner":_vm.isAdditionalOwner,"is-legal":_vm.isLegal,"min-steps":8,"only-microgenerator":_vm.onlyMicrogenerator,"source-document-id":_vm.sourceDocumentId,"step-number":_vm.steps[_vm.connectionApplication.currentStep].stepNumber},on:{"changeType":_vm.changeType,"close":_vm.close,"next":_vm.next}})],1),_c('div',{class:_vm.$style.contentFooter,style:({
    position: 'absolute',
    bottom: '0',
    width: _vm.footerWidth,
  })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayBack),expression:"displayBack"}],class:_vm.$style.back,on:{"click":_vm.back}},[_c('arrow-left'),_c('span',[_vm._v("НАЗАД")])],1),_c('div',{class:_vm.$style.delete,on:{"click":function($event){_vm.displayDeleteDialog = true}}},[_c('span',[_vm._v("УДАЛИТЬ ЧЕРНОВИК")])]),_c('div',{class:_vm.$style.center}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayNext),expression:"displayNext"}],class:_vm.$style.next,on:{"click":_vm.next}},[_c('span',[_vm._v("ДАЛЕЕ")]),_c('arrow-right')],1)]),_c('dialog-template',{attrs:{"center":""},model:{value:(_vm.displayDeleteDialog),callback:function ($$v) {_vm.displayDeleteDialog=$$v},expression:"displayDeleteDialog"}},[_c('p',{class:_vm.$style.deleteText},[_vm._v(" Удалить черновик? ")]),_c('div',{class:_vm.$style.submitBlock},[_c('submit-task-details',{attrs:{"red":true,"title":"УДАЛИТЬ"},on:{"submit":_vm.deleteDraft}}),_c('submit-task-details',{attrs:{"gray":true,"title":"ЗАКРЫТЬ"},on:{"submit":function($event){_vm.displayDeleteDialog = false}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }