const worker = new Worker(new URL('./electricityTransmissionContractWorker.js', import.meta.url));
const eighteenFormsWorker = new Worker(new URL('./eighteenFormsWithWorker.js', import.meta.url));

export const generateElectricityTransmissionContractWithWorker = (...args) => new Promise((resolve, reject) => {
  worker.onmessage = (event) => {
    resolve(event.data);
  };

  worker.onerror = (e) => {
    reject(e);
  };

  worker.postMessage(args);
});

export const generateEighteenFormsWithWorker = (...args) => new Promise((resolve, reject) => {
  eighteenFormsWorker.onmessage = (event) => {
    resolve(event.data);
  };

  eighteenFormsWorker.onerror = (e) => {
    reject(e);
  };

  eighteenFormsWorker.postMessage(args);
});
