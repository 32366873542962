import Vue from 'vue';
import Vuetify from 'vuetify';
import ru from 'vuetify/lib/locale/ru';
import YmapPlugin from 'vue-yandex-maps';

const settings = {
  apiKey: 'a8060cad-3c7e-448d-bac3-b7724ce215d7',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
};

Vue.use(YmapPlugin, settings);
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru',
  },
});
