<template>
  <modal
    v-if="value"
    :body-style="{
          padding: '24px 0',
          overflowY:'initial'
        }"
    :header-style="{
          display: 'flex',
          justifyContent: 'center',
        }"
    :is-backdrop="true"
    :is-btn-close-visible="false"
    :modal-style="{
          borderRadius: '16px',
          padding: '24px',
        }"
    class="tags-modal">
    <template #header>
      <span>Тэги ОДК</span>
    </template>
    <template #body>
      <div>
        <div v-for="(tag, idx) in tags"
             :key="idx">
          <div class="tags-modal__field-wrp">
            <div class="tags-modal__field">
              <tag-autocomplete
                v-model="tags[idx].id"
                :fetch-data-function="fetchTags"
                :fetch-default-data-function="fetchTags"
                :items.sync="tags[idx].names"
                :search.sync="tags[idx].name"
                is-required
              />
            </div>
            <button class="tags-modal__btn" @click="remove(idx)">
              <cross-delete/>
            </button>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-around;">
        <v-button
          :disabled="!canBeAdded" style="width: 100%" @click="add"
        >
          <span>Добавить</span>
        </v-button>
      </div>
    </template>
    <template #footer>
      <horizontal-progress-bar v-if="loading" class="tags-modal__progress-bar"/>
      <div v-if="error?.length > 0" class="errorText">
        {{ error }}
      </div>
      <div style="display: flex; justify-content: space-around;">
        <v-button
          variant="outlined"
          @click="close"
        >
          Отменить
        </v-button>
        <v-button
          :disabled="loading" @click="save"
        >
          <span>Сохранить</span>
        </v-button>
      </div>
    </template>
  </modal>
</template>
<script>

import VButton from 'components/VButton/VButton.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { Modal } from 'components';
import { fetchAdreskaTags, findOdkTags, saveAdreskaTags } from '@/api/rest/odk/api';
import { mapActions } from 'vuex';
import TagAutocomplete from 'components/Processes/components/TagAutocomplete.vue';
import CrossDelete from 'atoms/icons/action/CrossDelete.vue';


export default {
  components: { CrossDelete, HorizontalProgressBar, VButton, Modal, TagAutocomplete },
  name: 'OdkTagModal',
  props: {
    value: {
      type: String, default: '',
    },
  },
  data() {
    return {
      submitError: '',
      internalFields: [],
      loading: false,
      error: '',
      tags: [],
    };
  },
  computed: {
    canBeAdded() {
      return !this.tags?.length || !this.tags.some((it) => !it.id);
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        await this.loadData();
      },
    },
  },
  methods: {
    close() {
      this.$emit('input', null);
    },
    remove(idx) {
      this.tags.splice(idx, 1);
    },
    async loadData() {
      this.loading = true;
      try {
        this.tags = [];

        if (this.value) {
          this.tags = (await fetchAdreskaTags(this.value)).map((it) => ({
            id: it.id.toString(),
          }));
        } else {
          this.tags = [];
        }
      } catch (e) {
        await this.setNotification({ message: e.response.data });
      } finally {
        this.loading = false;
        if (!this.tags?.length) {
          this.tags = [{ id: '' }];
        }
      }

    },
    async save() {
      this.loading = true;
      try {
        if (this.value) {
          await saveAdreskaTags(this.value, this.tags.filter((it) => it.id).map((it) => {
            if (String(it.id).startsWith('c')) {
              return {
                name: it.name,
              };
            }
            return {
              id: String(it.id),
              name: it.name,
            };
          }));
          this.$emit('updated');
          this.close();
        }
      } catch (e) {
        console.log(e);
        await this.setNotification({ message: e.response?.data });
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.tags.push({ id: '' });
    },
    async fetchTags(name) {
      if (!name?.length) return await findOdkTags(name);
      const tags = await findOdkTags(name);
      if (!tags.some((it) => it.name === name)) {
        tags.unshift({
          id: `c${name}`,
          name: name,
        });
      }
      return tags;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.tags-modal {
  margin-right: 10px;

  &__field-wrp {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: start;
  }

  &__field {
    width: 100%;
  }


  //TODO костыль из-за кривой работа autocomplete надо поправиь
  &__btn {
    background: inherit;
    padding-top: 6px;
  }

  &__progress-bar {
    margin-bottom: 12px;
  }
}

</style>
