import { generateRandomString, escape, unescape } from 'lib/utils/string';

export default {
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: () => 'text',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    nativeDisabled: {
      type: Boolean,
      default: () => false,
    },
    error: {
      // TODO Найти другое решение. Если использовать Boolean, то watch не сработает в при повторной внешней валидации. Пример, при сабмите получили, что email не валидин, юзер вводит новый невалидный email и watch не сработает т.к. error опять true
      type: Object,
      default: () => ({ error: false }),
    },
    localError: {
      type: Boolean,
      default: () => false,
    },
    validation: {
      type: Function,
      default: undefined,
    },
    mask: {
      type: [Array, Boolean],
      default: () => false,
    },
    role: {
      type: String,
      default: () => '',
    },
    errorText: {
      type: String,
      default: () => 'Поле обязательно для заполнения',
    },
    labelStyle: {
      type: String,
      default: () => '',
    },
    note: { type: String, required: false },
  },
  data() {
    return {
      internalValue: '',
      internalError: false,
      isEditing: false,
    };
  },
  computed: {
    inputId() {
      return generateRandomString();
    },
    isInvalid() {
      return this.internalError;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.isEditing = val?.length > 0;
        this.internalValue = unescape(val);
      },
    },
    internalValue: {
      immediate: true,
      handler(val) {
        const trimedValue = val.trim();

        this.handleValidate();
        this.$emit('input', escape(trimedValue), trimedValue);
      },
    },
    error: {
      immediate: true,
      handler(val) {
        this.internalError = val.error;
      },
    },
  },
  methods: {
    handleValidate() {
      if (this.$validate.isUndefined(this.validation)) {
        return;
      }
      const stringValue = typeof this.internalValue === 'number' ? String(this.internalValue) : this.internalValue;
      const trimedValue = stringValue.trim();
      const isValid = this.validation(trimedValue);

      this.internalError = !isValid;
    },
  },
};
