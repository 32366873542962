<template>
  <div>
    <cross-delete />
  </div>
</template>

<script>
import CrossDelete from 'atoms/icons/action/CrossDelete';

export default {
  name: 'VTableCellCrossDelete',
  components: { CrossDelete },
};
</script>

<style scoped>

</style>
