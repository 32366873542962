const tariffCategory = (state) => state.tariffCategory;
const tariffRate = (state) => state.tariffRate;
const userFunctionalBlock = (state) => state.userFunctionalBlock;
const userFunction = (state) => state.userFunction;
const typeAccounting = (state) => state.typeAccounting;
const ownerAccountingFacilities = (state) => state.ownerAccountingFacilities;
const measurementsReadingKind = (state) => state.measurementsReadingKind;
const balanceStatus = (state) => state.balanceStatus;
const contractExisting = (state) => state.contractExisting;
const pointKind = (state) => state.pointKind;
const documentSideNames = (state) => state.documentSideNames;
const odkDefectStatusDictionary = (state) => state.odkDefectStatusDictionary;
const shiftDictionary = (state) => state.shiftDictionary;
const odkProcessDictionary = (state) => state.odkProcessDictionary;
export default {
  tariffCategory,
  tariffRate,
  userFunctionalBlock,
  userFunction,
  typeAccounting,
  ownerAccountingFacilities,
  measurementsReadingKind,
  balanceStatus,
  contractExisting,
  pointKind,
  documentSideNames,
  odkDefectStatusDictionary,
  shiftDictionary,
  odkProcessDictionary,
};
