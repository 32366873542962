var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    ['table__tab']: true,
    [`table__tab-${_vm.variant}`]: true,
    [`table__tab-${_vm.variant}--active`]: _vm.isActive,
  }},[_c('div',{class:{
      ['table__tab-text']: true,
      [`table__tab-${_vm.variant}-text`]: true,
    }},[_vm._t("default")],2),(_vm.variant === 'filled')?_c('div',[_c('v-table-tab-arrow')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }