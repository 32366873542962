<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="1" :total-steps="totalSteps"/>
        <span>Ваши данные</span>
      </h2>
    </div>

    <p :class="$style.titleContainer">* обязательно заполните эти поля</p>

    <div v-if="isLegal" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.name"
        disabled
        label="Наименование компании"
        role="companyName"
      />
    </div>

    <div v-if="isLegal" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.address"
        disabled
        label="Фактический адрес"
        role="companyAddress"
      />
    </div>

    <div v-if="isLegal" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.inn"
        disabled
        label="ИНН"
        role="companyInn"
      />
    </div>

    <div v-if="isLegal && !isIndividualEnterpreneur" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.ogrn"
        disabled
        label="ОГРН"
        role="ogrn"
      />
    </div>

    <div v-if="isLegal && isIndividualEnterpreneur" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.ogrnip"
        disabled
        label="ОГРНИП"
        role="ogrnip"
      />
    </div>

    <div v-if="isLegal" :class="$style.gridFluid">
      <c-input
        v-model="consumer.company.role"
        disabled
        label="Должность"
        role="role"
      />
    </div>

    <div :class="$style.grid2Column">
      <c-input
        v-model="consumer.familyName"
        :disabled="isLegal"
        :error="errors.familyName"
        :validation="$validate.isNotEmptyString"
        label="Фамилия *"
        role="familyName"
      />
      <c-input
        v-model="consumer.firstName"
        :disabled="isLegal"
        :error="errors.firstName"
        :validation="$validate.isNotEmptyString"
        label="Имя *"
        role="firstName"
      />
    </div>

    <div :class="$style.gridFluid">
      <c-input
        v-model="consumer.patronymic"
        :disabled="isLegal"
        label="Отчество"
        role="patronymic"
      />
    </div>

    <div :class="$style.grid2Column">
      <div>
        <c-select
          v-model="consumer.documentType"
          :error="errors.documentType"
          :options="documentTypeList"
          label="Тип удостоверения личности: *"
          role="documentType"
        />
        <c-input
          v-if="consumer.documentType.value === 'OTHER'"
          v-model="consumer.otherDocumentType"
          :class="$style.subInput"
          :error="errors.otherDocumentType"
          :validation="$validate.isNotEmptyString"
          label="Иное: *"
          role="otherDocumentType"
        />
      </div>
      <c-input
        v-if="consumer.documentType.value === 'PASSPORT_RF'"
        v-model="consumer.documentSeries"
        :error="errors.documentSeries"
        :mask="[/\d/, /\d/, /\d/, /\d/]"
        :validation="$validate.isNotEmptyString"
        label="Серия: *"
        placeholder="ХХХХ"
        role="documentSeries"
      />
      <c-input
        v-else
        v-model="consumer.documentSeries"
        :error="errors.documentSeries"
        :validation="$validate.isNotEmptyString"
        label="Серия: *"
        role="documentSeries"
      />


    </div>
    <div :class="$style.grid2Column">
      <c-input
        v-if="consumer.documentType.value === 'PASSPORT_RF'"
        v-model="consumer.passportNumber"
        :error="errors.passportNumber"
        :mask="consumer.documentType.value === 'PASSPORT_RF' ? [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/] : undefined"
        :validation="$validate.isNotEmptyString"
        label="Номер: *"
        placeholder="ХХХXХХ"
        role="passportNumber"
      />
      <c-input
        v-else
        v-model="consumer.passportNumber"
        :error="errors.passportNumber"
        :validation="$validate.isNotEmptyString"
        label="Номер: *"
        role="passportNumber"
      />
      <div>
        <p :class="$style.dateLabel">
          <span>Дата выдачи: *</span>
        </p>
        <v-date-picker
            v-model="consumer.issueDate"
            :err="!consumer?.issueDate && errors.issueDate?.error"
            required
            :start-date="startDate"
            :validation="$validate.isNotEmptyString"
            role="issueDate"
            err-text="Поле обязательно для заполнения"
        />
      </div>
    </div>
    <div :class="$style.grid2Column">
      <c-input
        v-model="consumer.issuer"
        :error="errors.issuer"
        :validation="$validate.isNotEmptyString"
        label="Кем выдан: *"
        role="issuer"
      />
      <c-input
        v-if="consumer.documentType?.value !== 'BIRTH_CERTIFICATE'"
        v-model="consumer.subdivisionCode"
        :error="errors.subdivisionCode"
        :label="'Код подразделения: ' +
          (consumer.documentType?.value !== 'OTHER' && consumer.documentType?.value !== 'BIRTH_CERTIFICATE' ? '*' : '')"
        :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]"
        :validation="() => {
          if (consumer.documentType.value === 'OTHER' || consumer.documentType.value === 'BIRTH_CERTIFICATE') {
            return true;
          }
          return $validate.isNotEmptyString(consumer.subdivisionCode);
        }"
        placeholder="ХХХ-XХХ"
        role="subdivisionCode"
      />
    </div>

    <div :class="$style.grid2Column">
      <c-input
        v-model="consumer.insuranceNumber"
        disabled
        :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]"
        label="СНИЛС"
        placeholder="ХХХ-ХХХ-ХХХ ХХ"
        role="insuranceNumber"
      />
    </div>

    <div :class="$style.grid2Column">
      <div>
        <c-phone
            v-model="consumer.phone"
            :error="errors.phone"
            :validation="$validate.isNotEmptyString"
            label="Телефон *"
            role="phone"
        />
        <one-time-password-input
            :is-waiting="isSendCode.phoneCode"
            :is-disabled="isDisableSendPhoneCode"
            input-style="height: 40px;fontSize: 18px"
            is-display-send-button
            :required="consumer.phone && consumer.phone.length > 0"
            style="margin-top: 4px"
            vid="phoneCode"
            role="phoneCode"
            :is-error="errors.phoneCode?.error"
            @code-input="(code) => codeEntered(code, 'phoneCode')"
            @code-request="codeRequest('phone')"
        />
      </div>
      <div>
        <c-phone
            v-model="consumer.additionalPhone"
            label="Дополнительный телефон"
            role="additionalPhone"
        />
        <one-time-password-input
            :is-waiting="isSendCode.additionalPhoneCode"
            :is-disabled="isDisableSendAdditionalPhoneCode"
            input-style="height: 40px;fontSize: 18px"
            is-display-send-button
            :required="consumer.additionalPhone && consumer.additionalPhone.length > 0"
            style="margin-top: 4px"
            vid="additionalPhoneCode"
            role="additionalPhoneCode"
            :is-error="errors.additionalPhoneCode?.error"
            @code-input="(code) => codeEntered(code, 'additionalPhoneCode')"
            @code-request="codeRequest('additionalPhone')"
        />
      </div>
    </div>

    <div :class="$style.gridFluid">
      <c-input
        v-model="consumer.email"
        :error="errors.email"
        :validation="$validate.isNotEmptyString"
        label="Электронная почта *"
        role="email"
        type="email"
        note="В соответствии с ФЗ 406 от 31.07.2023 просим использовать для авторизации электронные почтовые ящики в зоне .ru или .рф"
      />
      <one-time-password-input
          :is-waiting="isSendCode.emailCode"
          :is-disabled="isDisableSendEmailCode"
          input-style="height: 40px;fontSize: 18px"
          is-display-send-button
          :required="consumer.email && consumer.email.length > 0"
          style="margin-top: 4px"
          vid="emailCode"
          role="emailCode"
          :is-error="errors.emailCode?.error"
          @code-input="(code) => codeEntered(code, 'emailCode')"
          @code-request="codeRequest('email')"
      />
    </div>

    <div v-if="!isLegal" :class="$style.grid2Column">
      <c-input
        v-model="consumer.postcodeRegistrationAddress"
        :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
        label="Почтовый индекс регистрации"
        placeholder="ХХХXХХ"
        role="postcodeRegistrationAddress"
      />
    </div>

    <div v-if="!isLegal" :class="$style.gridFluid">
      <p :class="$style.titleAddress" role="registrationAddress">Адрес регистрации *</p>
      <v-address-search-select
        v-model="consumer.registrationAddress"
        :rules-messages="{required: 'Обязательно для заполнения.'}"
        :validation-mount="errors.registrationAddress?.error"
        :is-show-post-code="false"
        cinput
        rules="required"
        @postalCode="postalCodeRegistrationAddress"
      />
    </div>

    <div v-if="!isLegal" :class="$style.gridFluid">
      <c-checkbox
        v-model="addressEqRegistrationAddress"
        label="Адрес регистрации совпадает с адресом фактического места жительства"
      />
    </div>

    <div v-if="!addressEqRegistrationAddress && !isLegal" :class="$style.grid2Column">
      <c-input
        v-model="consumer.postcodeAddress"
        :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
        label="Почтовый индекс проживания"
        placeholder="ХХХXХХ"
        role="postcodeAddress"
      />
    </div>

    <div v-show="!addressEqRegistrationAddress && !isLegal" :class="$style.gridFluid">
      <p :class="$style.titleAddress" role="address">Адрес фактического места жительства *</p>
      <v-address-search-select
        v-model="consumer.address"
        :rules-messages="{required: 'Обязательно для заполнения.'}"
        :validation-mount="errors.address?.error"
        :is-show-post-code="false"
        cinput
        rules="required"
        @postalCode="postalCodeAddress"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { VAddressSearchSelect, VDatePicker } from 'components';
import StepCounter from '../components/StepCounter';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput.vue';
import formatting from 'views/public/login/mixins/formatting';
import formattingData from 'lib/utils/formatting';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'Consumer',
  mixins: [formatting],
  components: {
    CInput: () => import('atoms/common/inputs/CInput'),
    CPhone: () => import('atoms/common/inputs/CPhone'),
    CCheckbox: () => import('atoms/common/CCheckbox'),
    VDatePicker,
    CSelect: () => import('atoms/common/CSelect'),
    VAddressSearchSelect,
    StepCounter,
    OneTimePasswordInput,
  },
  props: {
    consumer: {
      type: Object,
      required: true,
    },
    isLegal: {
      type: Boolean,
      default: false,
    },
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addressEqRegistrationAddress: false,
      isSendCode: {},
    };
  },
  computed: {
    isShownAddressMailIndex() {
      return this.$validate.isNotEmptyString(this.consumer.address);
    },
    isShownRegistrationAddressMailIndex() {
      return this.$validate.isNotEmptyString(this.consumer.registrationAddress);
    },
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    isDisableSendPhoneCode() {
      return this.getUser.phoneNumber === formattingData.unformatPhonePrefix(this.consumer.phone) || !this.isPhone(this.consumer.phone);
    },
    isDisableSendAdditionalPhoneCode() {
      return this.getUser.additionalPhone === formattingData.unformatPhonePrefix(this.consumer.additionalPhone) || !this.isPhone(this.consumer.additionalPhone);
    },
    isDisableSendEmailCode() {
      return this.getUser.email === this.consumer.email || !this.isEmail(this.consumer.email);
    },
    ...mapGetters('user', ['getUser', 'getAccount', 'getAuthorization', 'isIndividualEnterpreneur']),
    ...mapGetters('dataLists', ['documentTypeList']),
  },
  watch: {
    consumer: {
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.autoCompleteData();
        this.fillUndefinedRecursive(newVal);
      },
      immediate: true,
    },
    addressEqRegistrationAddress: {
      handler(val) {
        if (val) {
          this.consumer.address = this.consumer.registrationAddress;
          this.consumer.postcodeAddress = this.consumer.postcodeRegistrationAddress;
        } else {
          this.consumer.address = this.getAccount.profile.address;
          this.consumer.postcodeAddress = '';
        }
      },
    },
    async 'consumer.documentType'(val) {
      if (val.value === this.getAccount?.profile.identityDocumentInfo?.documentType) {
        this.autoCompleteIdentityDocument();
        return;
      }
      this.consumer.documentSeries = '';
      this.consumer.passportNumber = '';
      this.consumer.issueDate = '';
      this.consumer.issuer = '';
      this.consumer.subdivisionCode = '';
    },
  },
  methods: {
    fillUndefinedRecursive(obj) {
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          this.fillUndefinedRecursive(obj[key]);

        } else if (!obj[key]) {
          this.$set(obj, key, '');
        }
      }
    },
    autoCompleteCompany() {
      const account = this.getAccount.profile;
      const authorization = this.getAuthorization.authorization;
      this.consumer.company.name = account.name;
      this.consumer.company.ogrn = account.ogrn;
      this.consumer.company.ogrnip = account.ogrnip;
      this.consumer.company.address = account.postAddress;
      this.consumer.company.inn = account.inn;
      this.consumer.company.role = authorization.role.name;
    },
    autoCompleteIdentityDocument() {
      const account = this.getAccount.profile;
      const identityDocument = account.identityDocumentInfo;

      if (!this.consumer.documentSeries && this.validateField(identityDocument.series)) {
        this.consumer.documentSeries = identityDocument.series;
      }
      if (!this.consumer.passportNumber && this.validateField(identityDocument.number)) {
        this.consumer.passportNumber = identityDocument.number;
      }
      if (!this.consumer.issueDate && this.validateField(identityDocument.issueDate)) {
        this.consumer.issueDate = identityDocument.issueDate;
      }
      if (!this.consumer.issuer && this.validateField(identityDocument.issuerName)) {
        this.consumer.issuer = identityDocument.issuerName;
      }
      if (!this.consumer.subdivisionCode && this.validateField(identityDocument.issuerCode)) {
        this.consumer.subdivisionCode = identityDocument.issuerCode;
      }
      if (!this.consumer.otherDocumentType && this.validateField(identityDocument.otherName)) {
        this.consumer.otherDocumentType = identityDocument.otherName;
      }
    },
    autoCompleteFromUser() {
      const user = this.getUser;
      if (!this.consumer.phone && this.validateField(user.phoneNumber)) {
        this.consumer.phone = user.phoneNumber;
      }
      if (!this.consumer.familyName && this.validateField(user.fullName?.surname)) {
        this.consumer.familyName = user.fullName.surname;
      }
      if (!this.consumer.firstName && this.validateField(user.fullName?.name)) {
        this.consumer.firstName = user.fullName.name;
      }
      if (!this.consumer.patronymic && this.validateField(user.fullName?.patronymic)) {
        this.consumer.patronymic = user.fullName.patronymic;
      }
      if (!this.consumer.email && this.validateField(user.email)) {
        this.consumer.email = user.email;
      }
      if (!this.consumer.insuranceNumber && this.validateField(user.snils)) {
        this.consumer.insuranceNumber = user.snils;
      }
    },
    autoCompleteFromAccount() {
      const account = this.getAccount.profile;
      const identityDocument = account.identityDocumentInfo;
      if (!this.consumer.documentType?.value && this.validateField(identityDocument.documentType)) {
        const existingType = this.documentTypeList.find((it) => it.value === identityDocument.documentType);
        if (!existingType) return;
        this.consumer.documentType = existingType;
        this.autoCompleteIdentityDocument();
      }
      if (!this.consumer.phone && this.validateField(account.phoneNumber)) {
        this.consumer.phone = account.phoneNumber;
      }
      if (!this.consumer.familyName && this.validateField(account.fullName?.surname)) {
        this.consumer.familyName = account.fullName.surname;
      }
      if (!this.consumer.firstName && this.validateField(account.fullName?.name)) {
        this.consumer.firstName = account.fullName.name;
      }
      if (!this.consumer.patronymic && this.validateField(account.fullName?.patronymic)) {
        this.consumer.patronymic = account.fullName.patronymic;
      }
      if (!this.consumer.email && this.validateField(account.email)) {
        this.consumer.email = account.email;
      }

      if (!this.consumer.registrationAddress && this.validateField(account.registrationAddress)) {
        this.consumer.registrationAddress = account.registrationAddress;
      }
      if (!this.consumer.address && this.validateField(account.liveAddress)) {
        this.consumer.address = account.liveAddress;
      }
      if (!this.consumer.insuranceNumber && this.validateField(account.snils)) {
        this.consumer.insuranceNumber = account.snils;
      }
      if (!this.consumer.additionalPhone && this.validateField(account.additionalPhoneNumber)) {
        this.consumer.additionalPhone = account.additionalPhoneNumber;
      }
    },
    autoCompleteData() {
      if (this.isLegal) {
        this.autoCompleteCompany();
        this.autoCompleteFromUser();
        return;
      }
      if (this.getAccount) {
        this.autoCompleteFromAccount();
        return;
      }
      this.autoCompleteFromUser();
    },
    validateField(value) {
      return this.$validate.isNotEmptyItem(value);
    },
    postalCodeRegistrationAddress(postalCode) {
      this.consumer.postcodeRegistrationAddress = postalCode ?? '';
    },
    postalCodeAddress(postalCode) {
      this.consumer.postcodeAddress = postalCode ?? '';
    },
    codeEntered(code, field) {
      this.consumer[field] = code;
    },
    async codeRequest(contact) {
      const isPhone = this.isPhone(this.consumer[contact]);

      if (!isPhone && !this.isEmail(this.consumer[contact])) {
        return;
      }
      try {
        this.$set(this.isSendCode, contact, true);
        await this.sendUsernameVerificationCode({ username: isPhone ? formattingData.unformatPhonePrefix(this.consumer[contact]) : this.consumer[contact] });
      } catch (e) {
        console.log(e);
        const errorData = HTTPError.network(e);

        this.vueShowNotification(
            'Ошибка отправки кода',
            `<p>${errorData.message}</p>`,
        );
      } finally {
        this.$set(this.isSendCode, contact, false);
      }
    },
    ...mapActions('user', ['sendUsernameVerificationCode']),
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .titleAddress
    margin-bottom: 8px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

    .subInput
      margin-top: 8px

  .dateLabel
    margin-bottom: 8px
    font-size: 16px
    line-height: 24px
</style>
