<template>
  <page title="Сотрудники компании">
    <template v-if="isPlatformWorkerForCompany" #titleButton>
      <v-search-select
          v-model="selectedAccountId"
          :options="companies"
          style="min-width: 400px"
          placeholder="Выберите компанию"
      />
    </template>
    <template #default>
      <employees :company-account-id="selectedAccountId" :company-name="companyName" />
    </template>
  </page>
</template>

<script>
import { Page } from '@/components';
import Employees from './Employees/index.vue';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import { mapActions, mapGetters } from 'vuex';
import { accountProfileType } from 'views/private/UserManagement/AccountProfileType';

export default {
  name: 'EmployeesPage',
  components: {
    Employees,
    Page,
    VSearchSelect,
  },
  created() {
    if (this.isPlatformWorker) {
      this.loadCompanies();
    }
  },
  data() {
    return {
      companies: [],
      selectedAccountId: undefined,
    };
  },
  computed: {
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.company-employees';
    },
    companyName() {
      if (!this.selectedAccountId) {
        return undefined;
      }

      return this.companies.find((item) => item.value === this.selectedAccountId)?.label;
    },
    ...mapGetters('user', ['isPlatformWorker']),
  },
  methods: {
    async loadCompanies() {
      const accounts = await this.accountsByFilter({ filter: { profileType: accountProfileType.COMPANY } });

      this.companies = accounts.map((account) => ({
        value: account.id,
        label: account.profile.name,
      }));
    },
    ...mapActions('user', ['accountsByFilter']),
  },
};
</script>

<style scoped>

</style>
