<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <span class="text-h5 align-center">Дефектовка</span>
          </v-card-title>
          <v-card-text>
            <simple-autocomplete
              ref="planConsignment"
              v-model="planConsignmentId"
              :items.sync="planConsignments"
              :search.sync="planConsignmentName"
              search-by="uniqueNumber"
              title="Плановая накладная"
              url="/plan-consignment/find?type=DEFECTOSCOPE&hideArchive=true&uniqueNumber="
              url-default-data="/plan-consignment/find?type=DEFECTOSCOPE&hideArchive=true"
            ></simple-autocomplete>
            <process8 v-model="data" :plan-consignment="planConsignment"/>
            <div class="v-application">
              <v-data-table
                :headers="headersTable"
                :items="formElements"
                class="elevation-1 mt-4"
                style="width: 100%"
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #top>
                  <v-toolbar flat>
                    <edit-dialog-process
                      ref="editDialog"
                      v-model="editDialog"
                      :axp-id="data.axpId"
                      :cancel="cancelForAxpElements"
                      :dialog-data="editedItem"
                      :disabled="disabledEditDialog"
                      :edit="editedIndex"
                      :handle-success="addElement"
                    ></edit-dialog-process>
                    <v-dialog v-model="dialogDelete"
                              class="v-dialog-top" max-width="500px">
                      <v-card class="v-application">
                        <v-card-title class="text-h5 text-center"
                        >Вы точно хотите удалить этот элемент?
                        </v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogDelete = false"
                          >Отмена
                          </v-btn
                          >
                          <v-btn
                            class="ma-2"
                            color="error"
                            outlined
                            rounded
                            @click="deleteItemConfirm"
                          >Удалить
                          </v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template #item.actions="{ item }">
                  <v-icon class="mr-2" small @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon class="mr-2" small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
            <div style="width: 200px">
              <div style="height: 50px"></div>
            </div>

            <text-field
              ref="contractorEmployee"
              v-model="contractorEmployee"
              :is-required="false"
              title="Сотрудник подрядчика"
            >
            </text-field>

            <employee-autocomplete
              ref="performer"
              v-model="performerId"
              :items.sync="performerItems"
              :search.sync="performerName"
              class="mt-2"
              is-required
              set-current-by-default
              title="Сотрудник, совершивший операцию"
            ></employee-autocomplete>

            <v-checkbox v-model="isrepair" label="Передать в ремонт"></v-checkbox>
            <warehouse-date-picker
              ref="date"
              v-model="date"
              is-required
              label="Дата накладной"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSendButton"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>


<script>
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import EditDialogProcess from './EditDialog.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import WarehouseDatePicker from 'components/Processes/components/WarehouseDatePicker.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import Process8 from 'components/Processes/WarehouseForms/Process16-1.vue';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';
import EmployeeAutocomplete from 'components/Processes/components/EmployeeAutocomplete.vue';

export default {
  components: {
    EmployeeAutocomplete,
    SimpleAutocomplete,
    Process8,
    WarehouseDatePicker,
    Consignment,
    TextField,
    EditDialogProcess,
  },
  data() {
    return {
      performerId: null,
      performerName: '',
      performerItems: [],

      progressValue: 0,
      search: '',
      editDialog: false,
      dialogDelete: false,
      headersTable: [
        {
          text: 'Элемент АХП',
          align: 'start',
          value: 'elementName',
        },
        { text: 'Кол-во элементов', value: 'realCount' },
        { text: 'Дефект', value: 'defectNames' },
        { text: 'Фото', value: 'photos.length' },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {},

      rules: {
        requiredForNull: (value) => !!value || 'Поле обязательное',
        requiredForArr: (value) => value.length > 0 || 'Поле обязательное',
        onlyNumbers: (value) => {
          const pattern = /^\d+$/;
          return pattern.test(value) || 'Можно вводить только цифры';
        },
      },
      planConsignmentId: null,
      planConsignmentName: '',
      planConsignments: [],
      data: {
        warehouseId: null,
        warehouseName: '',
        eventId: null,
        eventName: '',
        axpId: null,
        axpName: '',
        contractorId: null,
        contractorName: '',
        adreskaName: '',
        adreskaId: null,
      },

      headerForTableReport: [],
      dataReportCustom: {},

      contractorEmployee: '',

      formElements: [],

      isDocumentInputValid: true,
      documents: [],
      countDocuments: null,
      addedDocuments: [],
      allNameAddedDocuments: new Set(),

      isPhotoInputValid: true,
      photos: [],
      countPhotos: null,
      addedPhotos: [],
      allNameAddedPhotos: new Set(),

      isTableElementsValid: false,

      loaderDialog: false,

      dialogForViewReport: false,

      disabledEditDialog: true,

      isrepair: false,

      date: null,
    };
  },
  computed: {
    planConsignment() {
      if (!this.planConsignmentId) return null;
      return this.planConsignments.find((it) => it.id === this.planConsignmentId);
    },
    isDisabledSendButton() {
      const isDisabledPerformer = this.performerId == null;
      const isDisabledDate = this.date == null;
      const isDisabledElements = this.formElements == 0;

      return this.data.isDisabled || isDisabledElements || isDisabledDate || isDisabledPerformer;
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    dialog(val) {
      val || this.closeElementDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    contractorId() {
      this.eventId = null;
    },
    eventId() {
      this.adreskaId = null;
    },
    'data.axpId'(newVal) {
      this.formElements = [];
      this.editedItem = {};
      this.editedIndex = -1;
      this.disabledEditDialog = newVal == null;
    },
  },
  methods: {
    addElement(data) {
      const newData = {};
      Object.assign(newData, data);
      if (this.editedIndex > -1) {
        Object.assign(this.formElements[this.editedIndex], newData);
      } else {
        this.formElements.push(newData);
      }
      this.$refs.editDialog.afterClose();
      this.editedIndex = -1;
      this.editDialog = false;
      this.countDisable = this.countDisable + 1;
    },

    cancelForAxpElements() {
      this.editedIndex = -1;
    },

    editItem(item) {
      this.editedIndex = this.formElements.indexOf(item);
      Object.assign(this.editedItem, this.formElements[this.editedIndex]);
      this.editDialog = true;
    },

    onTapAgain() {
      this.progressValue = 0;
      this.contractorEmployee = '';
      this.formElements = [];
      this.isDocumentInputValid = true;
      this.documents = [];
      this.countDocuments = null;
      this.addedDocuments = [];
      this.allNameAddedDocuments = new Set();
      this.isPhotoInputValid = true;
      this.photos = [];
      this.countPhotos = null;
      this.addedPhotos = [];
      this.allNameAddedPhotos = new Set();
      this.isTableElementsValid = false;
      this.loaderDialog = false;
      this.disabledEditDialog = true;
      this.isrepair = false;
      this.dialogForViewReport = false;
      this.headerForTableReport = [];
      this.dataReportCustom = {};
      this.date = null;
      this.performerId = null;
      this.performerName = '';
      this.performerItems = [];
      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));
    },

    onTapViewReport() {
      const els = this.formElements.filter((element) => element.realCount > 0).map((element) => ({
        axpName: this.data.axpName,
        date: formatDateFromOdkTimestamp(this.date),
        contractorName: this.data.contractorName,
        warehouseName: this.data.warehouseName,
        photos: element.photos,
        elementName: element.elementName,
        unitName: element.unitName,
        type: 'Дефектовка',
        count: element.realCount ?? 0,
        performer: this.performerName,
        employee: this.getUser.fullName,
        eventName: this.data.eventName,
        adreskaName: this.data.adreskaName,
        contractorEmployeeName: this.contractorEmployee,
      }));

      this.dataReportCustom = els[0];
      this.dataReportCustom.elements = els;


      this.dialogForViewReport = true;
    },

    deleteItem(item) {
      this.editedIndex = this.formElements.indexOf(item);
      this.dialogDelete = true;
    },

    onTapCloseDeleteTableDialog() {
      this.dialogDelete = false;
    },

    deleteItemConfirm() {
      this.formElements.splice(this.editedIndex, 1);
      this.onTapCloseDeleteTableDialog();
      this.editedIndex = -1;
      this.dialogDelete = false;
    },

    closeElementDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapSend() {

      this.loaderDialog = true;
      this.progressValue = 0;

      const request = {
        performerUserId: this.performerId,
        planConsignmentId: this.planConsignmentId,
        warehouseId: this.data.warehouseId,
        eventId: this.data.eventId,
        adreskaId: this.data.adreskaId,
        axpId: this.data.axpId,
        createDate: this.date,
        contractorId: this.data.contractorId,
        contractorEmployee: this.contractorEmployee,
        isRepair: this.isrepair,
        defectoscopeElements: [],
      };

      this.formElements.forEach((f) => {
        const formElementPhotos = f.photos == null ? [] : f.photos;
        const photos = [];
        formElementPhotos.forEach((doc) => {
          photos.push({
            stringBase64: doc.previewUrl,
            fileName: doc.name,
          });
        });
        request.defectoscopeElements.push({
          id: f.elementId,
          count: f.realCount ?? 0,
          defectIds: [f.defectId],
          additionalInfo: f.additionalInfo,
          photos: photos,
        });
      });

      axios
        .post(`${BASE_URL}/defectoscope-construction`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          this.progressValue = 100;
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
          console.error(error);
        });
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>


