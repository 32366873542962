<template>
  <div>
    <v-button>{{ value }}</v-button>
  </div>
</template>

<script>
import VButton from 'components/VButton/VButton.vue';

export default {
  name: 'VTableCellButton',
  components: { VButton },
  props: {
    value: { type: String, default: '' },
  },
};
</script>

<style scoped>

</style>
