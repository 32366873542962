<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="back">{{ params.fromTitle }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ toTitle }}</span>
    </p>

    <chat-messenger
        :loading="loading"
        :messages="messages"
        :room-id="$route.params.id"
        :class="$style.content"
        @submitted="load(true)"
    />

    <div :class="$style.contentFooter">
      <div :class="$style.back" @click="back">
        <arrow-left/>
        <span>НАЗАД</span>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeft from 'atoms/icons/arrows/ArrowLeft';
import { mapActions } from 'vuex';
import { Set } from 'core-js/internals/set-helpers';
import ChatMessenger from 'components/ChatBot/ChatMessenger';

export default {
  name: 'IndexMessenger',
  components: {
    ChatMessenger,
    ArrowLeft,
  },
  async created() {
    await this.load();
  },
  data() {
    return {
      params: {},
      message: '',
      messages: [],
      userIds: new Set(),
      users: new Map(),
      loading: false,
      observer: null,
    };
  },
  computed: {
    toTitle() {
      return this.params.title;
    },
  },
  methods: {
    async addUserNames(userIds) {
      const getFullNameByUser = (user) => (user.fullName ? `${user.fullName.surname} ${user.fullName.name} ${user.fullName.patronymic ?? ''}` : `Пользователь ${user.id}`);

      for (const userId of userIds) {
        const nameByUser = await this.getUserById({ id: userId });

        if (!nameByUser) {
          continue;
        }

        this.users.set(userId, getFullNameByUser(nameByUser));
      }
    },
    async load(isNotLoading) {
      if (!localStorage.getItem('messenger')) {
        localStorage.setItem('messenger', JSON.stringify(this.$route.params));
        this.params = JSON.parse(localStorage.getItem('messenger'));
      } else {
        this.params = JSON.parse(localStorage.getItem('messenger'));
      }
      this.loading = !isNotLoading;

      try {
        const messagesList = await this.getMessages({ roomId: this.$route.params.id });

        this.userIds = new Set(messagesList.map((it) => it.senderId));
        this.users = new Map();
        await this.addUserNames(this.userIds);

        this.messages = messagesList.map((message) => ({
          ...message,
          senderName: this.users.get(message.senderId),
        }));
        await this.subscribeMessages();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async send() {
      if (!this.message.trim()) {
        return;
      }
      const msg = this.message;
      this.message = '';
      await this.sendMessage({ roomId: this.$route.params.id, content: msg });
    },
    async subscribeMessages() {
      this.observer = await this.receiveMessages({ roomId: this.$route.params.id });
      this.observer.subscribe({
        messages: this.messages,
        getNameByUserId: (val) => this.users.get(val),
        isIdExists: (val) => this.userIds.has(val),
        addUserId: (val) => this.userIds.add(val),
        addUserNames: (val) => this.addUserNames(val),
        async next(response) {
          console.log('subscribeMessages response, this.messages', response, this.messages);
          const message = response.data.receiveMessages;

          if (await this.isIdExists(message.senderId)) {
            await this.addUserId(message.senderId);
            await this.addUserNames([message.senderId]);
          }

          const newMessage = {
            ...message,
            senderName: await this.getNameByUserId(message.senderId),
          };

          const messageIndex = this.messages.findIndex((msg) => msg.id === message.id);

          if (messageIndex === -1) {
            this.messages.push(newMessage);
          } else {
            this.messages.splice(messageIndex, 1, newMessage);
          }
        },
        error(error) {
          console.error('error', error);
        },
      });
    },
    back() {
      localStorage.removeItem('messenger');
      this.$router.go(-1);
    },
    ...mapActions('chatbot', ['receiveMessages', 'sendMessage', 'getMessages']),
    ...mapActions('user', ['getUserById']),
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.content {
  flex-grow: 2;
  height: 100%;
  overflow-y: auto;
  width: 520px;
}

.navigationBar {
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1006;
  // more than modal z-index
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0E0F0F;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 8px 16px 0 0;
  padding-left: 16px;

  .navigationRoot, .navigationSeparator {
    color: #979CA2;
    cursor: pointer;
  }

  .navigationSeparator {
    font-size: 24px;
    line-height: 24px;
  }
}

.contentFooter {
  display: flex;
}

.back, .next {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #2F82DF;
  padding: 18px;

  svg {
    fill: #FFFFFF;
  }
}

.center {
  flex-grow: 2;
}

.back {
  height: 48px;
  margin-left: 16px;

  span {
    margin-left: 13px
  }
}

.inputMessage {
  border-top: 2px solid #F2F2F2;
  display: flex;
  align-items: stretch;
  background-color: white;
  width: 560px;
  margin: 0 auto;
  transform: translateX(-73px);
}

.inputValue {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: none;
  margin-right: 2px;
  outline: none;
  resize: none;

  &::placeholder {
    color: #838383;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.send {
  align-items: center;
  margin-bottom: 0;
  width: 28px;
  height: 24px;
  cursor: pointer;
  transform: translateY(6px);
}
</style>
