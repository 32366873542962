<template>
  <validation-provider
    ref="validationProvider"
    v-slot="{ errors }"
    :custom-messages="rulesMessages"
    :name="name"
    :rules="rules"
    :vid="vid"
    :mode="validationMode"
  >
    <div
      v-if="!cinput"
      :class="{
        [$style['input-container']]: true,
      }"
    >
      <v-label
        :is-error="(errors.length > 0 || err) && !disabled"
        :is-need-mouseover-tooltip="isNeedMouseoverTooltip"
        :is-value="isText"
        :label="labelByRequired"
        :title="titleByRequired"
      >
        <span v-if="note">{{ note }}</span>
        <div
          :class="{
            [$style['mt-8']]: isMarginTopNeeded,
            [$style['masked-input-v-clearable-input-wrp']]: true,
          }"
        >
          <v-divider v-if="searchStyle && isShowClear" :class="$style['input-container__divider']" direction="vertical" />
          <masked-input
            v-model="internalValue"
            :class="{
              [$style['input-container__input']]: !cinput,
              [$style['input-container__input-search']]: !cinput && searchStyle,
              [$style['input-container__input--title']]: !cinput && title.length > 0,
              [$style['input-container__c-input']]: cinput,
              [$style['input-container__input--clearable']]: isShowClear,
              [$style['input-container__input--error']]: (errors.length > 0 || err) && !disabled,
              [$style['input-container--disabled']]: disabled,
              [$style['error']]: (errors.length > 0 || err) && !disabled,
            }"
            :disabled="disabled"
            :mask="decimal ? decimalMask : mask"
            :placeholder="placeholder"
            :type="innerType"
            @keyup="$emit('keyup', $event.target.value)"
          />
          <template v-if="type === 'password'">
            <img v-show="showPassword"
                 :class="$style['input-container__displayPassword']"
                 :style="showPasswordStyle"
                 title="Скрыть"
                 src="@/assets/icons/basic/eye_closed_flat.svg"
                 @click="clickShowPassword"
            />
            <img v-show="!showPassword"
                 :class="$style['input-container__displayPassword']"
                 :style="showPasswordStyle"
                 title="Показать"
                 src="@/assets/icons/basic/eye_flat.svg"
                 @click="clickShowPassword"
            />
          </template>
          <div
            :class="{
              [$style['v-clearable-input-wrp']]: true,
            }"
          >
            <v-clearable-input :is-show="isShowClear" @click="onValueChange('')" />
          </div>
        </div>
      </v-label>
      <span
        v-if="errors.length > 0 && !disabled"
        class="errorText">{{ errors[0] }}
      </span>
      <span
        v-if="errText !== '' && err && !disabled"
        class="errorText"
      >
        {{ errText }}
      </span>
    </div>

    <div v-else :class="$style['c-input-container']">
      <div
        :class="{
          [$style['masked-input-v-clearable-input-wrp']]: true,
        }"
      >
        <label
          v-if="cInputLabel.length > 0"
          :class="$style['c-input-container__label']"
        >
          {{ cInputLabel }}
        </label>
        <div
          :class="{
            [$style['v-clearable-input-wrp']]: true,
          }"
        >
          <v-clearable-input :is-show="isShowClear" cinput @click="onValueChange('')" />
        </div>
        <masked-input
          v-model="internalValue"
          :class="{
            [$style['c-input-container__c-input']]: cinput,
            [$style['clearable']]: clearable,
            [$style['error']]: (errors.length > 0 || err) && !disabled,
          }"
          :mask="mask"
          :placeholder="placeholder"
          :type="type"
          @keyup="$emit('keyup', $event.target.value)"
        />
      </div>
      <span
        v-if="(errors.length > 0) && !disabled"
        class="errorText">{{ errors[0] }}
      </span>
      <span
        v-if="errText !== '' && err && !disabled"
        class="errorText"
      >
        {{ errText }}
      </span>
    </div>
  </validation-provider>
</template>

<script>
import { VClearableInput } from '@/components';
import { ValidationProvider } from 'vee-validate';
import MaskedInput from 'vue-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import VDivider from 'components/VDivider/VDivider.vue';

export default {
  name: 'VInput',
  components: {
    VDivider,
    VLabel: () => import('../Common/VLabel/VLabel'),
    VClearableInput,
    ValidationProvider,
    MaskedInput,
  },
  props: {
    cinput: { type: Boolean, default: false },
    searchStyle: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: false },
    value: { type: [String, Number], default: '' },
    required: { type: Boolean, default: false },
    rules: { type: String, default: '' },
    rulesMessages: {
      type: Object, default: () => {
      },
    },
    validationMode: { type: String, default: 'lazy' },
    vid: { type: String, default: '' },
    mask: {
      type: [Array, Boolean, Function],
      default: () => false,
    },
    err: { type: Boolean, default: false },
    validationMount: { type: Boolean, default: false },
    title: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    decimal: { type: Boolean, default: false },
    isNeedMouseoverTooltip: { type: Boolean, default: false },
    errText: { type: String, default: '' },
    note: { type: String, required: false },
  },
  created() {
    this.innerType = this.type;
  },
  mounted() {
    if (this.validationMount) {
      this.startValidate();
    }
  },
  data() {
    return {
      internalValue: typeof this.value === 'number' ? this.value.toString() : this.value,
      showPassword: false,
      innerType: 'text',
    };
  },
  computed: {
    isMarginTopNeeded() {
      if (this.title.length !== 0 || this.label.length !== 0) {
        return true;
      }
      return false;
    },
    name() {
      if (!this.label) return this.title;
      return this.label;
    },
    labelByRequired() {
      if (this.cinput) {
        return '';
      }
      if (this.required && this.label && !this.disabled) {
        return `${this.label} *`;
      }
      return this.label;
    },
    titleByRequired() {
      if (this.cinput) {
        return '';
      }
      if (this.required && this.title && !this.disabled) {
        return `${this.title} *`;
      }
      return this.title;
    },
    cInputLabel() {
      return this.label;
    },
    isText() {
      if (!this.internalValue) return false;
      return this.internalValue && this.internalValue.length > 0;
    },
    isShowClear() {
      if (!this.internalValue) return false;
      return this.clearable && this.internalValue && this.internalValue.length > 0;
    },
    decimalMask() {
      const mask = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ' ',
        allowDecimal: true,
        decimalLimit: null,
        integerLimit: null,
      });
      return function replaceDecimalSymbol(val) {
        return mask(val);
      };
    },
    showPasswordStyle() {
      if (!this.isShowClear) {
        return '';
      }

      return 'right: 32px';
    },
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
    internalValue() {
      if (this.decimal) this.internalValue = this.internalValue.replaceAll(',', '.');
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    onValueChange(value) {
      if (this.disabled)
        return;

      this.internalValue = value;
      this.$emit('input', value);
    },
    startValidate() {
      this.$refs.validationProvider.validate();
    },
    clickShowPassword() {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.innerType = 'text';
      } else {
        this.innerType = 'password';
      }
    },
  },
};
</script>

<style lang="scss" module>
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.c-input-container {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141412;
    margin-bottom: 8px;
    width: 100%;
  }

  &__c-input {
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 4px 16px;
    color: #4F4F4F;
    background: #FFF;
    border: 1px solid #141412;

    &::placeholder {
      color: #E8E8E8;
    }

    &:focus {
      outline: none
    }
  }
}

.input-container {
  &--disabled {
    background-color: #fafafa;
  }

  &__input {
    width: 100% !important;
    height: 40px !important;
    border: 1px solid #dee2e6 !important;
    padding: 0 15px !important;

    &-search {
      border-radius: 4px !important;
      border: 1px solid #E9EBED !important;
      padding: 0 45px 0 30px !important;
      background: url("~@/assets/icons/search/loupe-gray.svg") no-repeat center left 8px !important;
    }

    &:focus {
      outline: none !important;
      border: 1px solid #2f82df !important;
    }

    &:hover {
      border: 1px solid #2f82df !important;
    }

    &--title {
      border: 1px solid #DEE2E6 !important;
      border-radius: 2px !important;
    }

    &--clearable {
      padding: 0 30px 0 15px !important;
    }
  }

  &__divider {
    height: 44%;
    width: 1px;
    position: absolute;
    right: 38px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__displayPassword {
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
  }
}

.clearable {
  padding-right: 80px;
}

.error {
  border-color: red;
}

.mt-8 {
  margin-top: 8px;
}

.masked-input-v-clearable-input-wrp {
  position: relative;
}
.v-clearable-input-wrp {
  position: absolute;
  top: 14px;
  right: 6px;
}
</style>
