<template>
  <page
    title="Ставка тарифа на услуги по передаче электрической энергии, используемая для целей определения расходов на оплату нормативных потерь электрической энергии">
    <v-table-new
      v-model="transformedTariffRate"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="tariffRatePage.pageOut"
      :template="template"
      :width-for-truncated="950"
      excel-report-file-name="Ставка тарифа на услуги по передаче электрической энергии, используемая для целей определения расходов на оплату нормативных потерь электрической энергии"
    />
    <modal-progress-bar
      :is-complete="isProcessComplete"
      :is-modal-visible="isModalVisible"
      @close="isModalVisible = false"
    />
  </page>
</template>

<script>
import { ModalProgressBar, Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import formatting from 'lib/utils/formatting';
import { formatPeriod } from 'lib/utils/date';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'ExpensesPaymentStandardLosses',
  components: {
    VTableNew,
    Page,
    ModalProgressBar,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      tariffRatePage: {},
      loading: false,
      pagination: initialPagination,
      isModalVisible: false,
      isProcessComplete: false,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Расчётный период',
            key: 'accountingPeriod',
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'date', format: 'MM.YYYY', selectType: 'month' },
            sort: {},
          },
          {
            label: 'Стоимость электрической энергии и мощности для целей определения ставки тарифа на услуги по передаче электрической энергии, используемой для целей определения расходов на оплату нормативных потерь электрической энергии при ее передаче по электрическим сетям единой национальной (общероссийской) электрической сети, руб',
            type: 'number',
            key: 'electricityCostForTransmissionTariffCalculation',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Объем потерь электрической энергии в электрических сетях единой национальной (общероссийской) электрической сети, МВт*ч',
            type: 'number',
            key: 'electricityLossVolumeInNationalGrid',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Ставка тарифа на услуги по передаче электрической энергии, используемая для целей определения расходов на оплату нормативных потерь электрической энергии при ее передаче по электрическим сетям единой национальной (общероссийской) электрической сети, руб/(МВт*ч)',
            type: 'number',
            key: 'tariffRateForNationalGridLosses',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'АТСэнерго',
            key: 'atsEnergo',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffRate() {
      return this.tariffRatePage.list ?? [];
    },
    transformedTariffRate() {
      return this.tariffRate.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0 && filterKey === 'accountingPeriod') {
          result[filterKey] = {
            year: filterValue.substr(3, 4),
            month: filterValue.substr(0, 2),
          };
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffRatePage = await this.tariffRateForElectricityTransmissionExpensesByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffRateForElectricityTransmissionExpensesByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffRate = await this.tariffRateForElectricityTransmissionExpensesByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffRatePage.pageOut.totalElements,
          },
        });

        if (!tariffRate?.list) {
          return [];
        }

        return tariffRate.list.map(this.transformed);
      } catch (e) {
        console.log('tariffRateForElectricityTransmissionExpensesByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        id: it.id,
        accountingPeriod: formatPeriod(it.accountingPeriod.month, it.accountingPeriod.year),
        electricityCostForTransmissionTariffCalculation: formatting.numberFormatted(it.electricityCostForTransmissionTariffCalculation),
        electricityLossVolumeInNationalGrid: formatting.numberFormatted(it.electricityLossVolumeInNationalGrid),
        tariffRateForNationalGridLosses: formatting.numberFormatted(it.tariffRateForNationalGridLosses),
        atsEnergo: it.atsEnergo,
        tariffsOrderId: it.tariffsOrder?.documentId,
        document: { documentId: it.tariffsOrder?.documentId, name: 'TariffRateForElectricityTransmissionExpenses' },
      };
    },
    ...mapActions('dataLists', ['tariffRateForElectricityTransmissionExpensesByPaged']),
    ...mapActions('document', ['loadDocument']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
