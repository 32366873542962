<template>
  <div class="vuetify-styles" data-app>
    <v-row>
      <v-col>
        <div>
          <v-label :class="{
          'error-label': !isValid,
        }">{{ internalTitle }}
          </v-label>
        </div>
        <v-autocomplete ref="autocomplete" v-model="selectedItem"
                        :append-icon="isDisabled ? '' : (selectedItem ? 'mdi-close non-rotating-icon' : 'mdi-chevron-down')"
                        :disabled="isDisabled" :error="!isValid"
                        :items="internalItems" :loading="isLoading" :menu-props="{ maxHeight: 200 }"
                        :rules="[rules.requiredForNull]"
                        :search-input.sync="searchItem" :search-threshold="3" dense item-text="name" item-value="id"
                        outlined
                        required
                        @focusout="checkValidity"
                        @input="onChange"
                        @click:append="onClear"></v-autocomplete>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import { debounce } from 'lodash';

export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    selected: {
      default: null,
    },
    fetchDataFunction: {
      type: Function,
      required: true,
    },
    fetchDefaultDataFunction: {
      type: Function,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      required: false,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:search', 'change', 'update:items'],
  created() {
    this.reFetchData();
  },
  data() {
    return {
      reFetchData: debounce(function() {
        this.fetchData();
      }, 500),
      isLoading: false,
      internalItems: [],
      isValid: true,
      rules: {
        requiredForNull: (value) => (this.isRequired ? !!value || 'Поле обязательное' : true),
      },
    };
  },

  computed: {
    internalTitle() {
      return this.isRequired && this.title ? `${this.title}*` : this.title;
    },

    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    isSearch() {
      return this.searchItem?.length;
    },
  },

  watch: {
    internalItems() {
      // Костыль из-за того что V-Autocomplete не эмитит null в случае изменения данных
      if (!this.internalItems?.some((it) => it.id === this.selectedItem)) this.onClear();
      this.$emit('update:items', this.internalItems);
    },
    items: {
      immediate: true,
      handler() {
        this.internalItems = this.items;
      },
    },
    isDisabled(newVal) {
      if (!newVal) {
        this.checkValidity();
      }
    },
    async selectedItem() {
      this.checkValidity();
    },
    async dependency() {
      this.reFetchData();
    },
    async searchItem() {
      this.reFetchData();
    },
  },

  methods: {
    async fetchByFunction() {
      if (this.isSearch) return await this.fetchDataFunction(this.searchItem);
      return await this.fetchDefaultDataFunction();
    },

    async fetchData() {
      this.isLoading = true;
      const data = await this.fetchByFunction();
      this.internalItems = [...data];
      this.isLoading = false;
    },

    onChange(item) {
      this.selectedItem = item;
    },

    onClear() {
      this.selectedItem = null;
    },

    checkValidity() {
      this.isValid = !this.isRequired || this.isDisabled || !!this.selectedItem;
    },

    resetValidation() {
      this.$refs.autocomplete.resetValidation();
      this.isValid = true;
    },
  },
};
</script>
