<template>
  <div :class="$style.container">
    <label :for="inputId" :class="$style.label" >{{ $props.label }}</label>
    <date-picker
      :id="inputId"
      v-model="internalValue"
      value-type="format"
      prefix-class="xmx"
      format="DD.MM.YYYY"
      :role="role"
      :input-class="{
        ['xmx-input']: true,
        [$style.invalid]: isInvalid
      }"
      placeholder='ДД.ММ.ГГГГ'
      :default-value="startDate"
      :disabled="$props.disabled"
      autocomplete="off"
      lang="ru"
      @blur="handleValidate"
      @clear="handleClear"
    />
    <p v-if="isInvalid" :class="$style.errorText">
      {{ errorText }}
    </p>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

import inputMixin from 'atoms/common/inputs/inputMixin';

export default {
  name: 'CDatePicker',
  mixins: [
    inputMixin,
  ],
  components: {
    DatePicker,
  },
  props: {
    startDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  methods: {
    handleClear() {
      this.internalValue = '';
    },
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    flex-direction: column
    align-items: flex-start

  .label
    +label-text
    margin-bottom: 8px
    color: #141412

  .field
    +input-text
    width: 100%
    font-family: 'Roboto Condensed'
    padding: 4px 16px
    color: #4F4F4F
    background: #FFF
    border: 1px solid #141412

    &::placeholder
      color: #E8E8E8

    &:focus
      outline: none

    &.date
      line-height: 28px

  .invalid
    color: red
    border-color: red

  .errorText
    +small-text
    color: red
</style>
