<template>
  <div>
    <table :key="rerenderKey" class="contract-xml-table">
      <thead>
      <tr>
        <th class="td" style="width: 5%;"/>
        <th class="td" style="width: 5%;"/>
        <th class="td" style="white-space: pre-line; width: 80%;">
          <div>
            <autosize-textarea
              v-model="xmlForView.header"
              :disabled="!isHeaderEditable"
            />
          </div>
        </th>
        <th style="width: 10%;"></th>
      </tr>
      </thead>
      <tbody v-if="xmlForView.isChaptersRoot">
      <xml-chapter-view v-for="(row, index) in xmlForView.body" :key="`${index}-${row.changes}`"
                        :chapter="row"
                        :is-create-new-row-in-progress="isCreateNewRowInProgress" :is-edit-mode-on="isEditModeOn"
                        @changeVisibility="changeVisibleChapter(row.number)" @chapterChanged="onChapterChanged"
                        @newRowFinished="onCreateNewRowFinished"
                        @newRowInProgress="onCreateNewRowInProgress" @pointCreated="onNewPointCreated"/>
      </tbody>
      <tbody v-else>
      <xml-point-view v-for="(row, index) in xmlForView.body" :key="`${index}-${row.changes}`"
                      :is-create-new-row-in-progress="isCreateNewRowInProgress"
                      :is-edit-mode-on="isEditModeOn" :is-show="true"
                      :point="row" previous-number=""
                      @changeVisibility="changeVisibleChapter(row.number)"
                      @newRowFinished="onCreateNewRowFinished"
                      @newRowInProgress="onCreateNewRowInProgress" @pointChanged="onChapterChanged"
                      @pointCreated="onNewPointCreated"
                      @pointDeleted="onPointDeleted"/>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutosizeTextarea from './AutosizeTextarea';
import { generateContractBody, parseContractXml } from 'services/contractXml';
import XmlPointView from 'components/XmlContractView/XmlPointView.vue';
import XmlChapterView from 'components/XmlContractView/XmlChapterView.vue';

export default {
  components: {
    XmlPointView,
    XmlChapterView,
    AutosizeTextarea,
  },
  props: {
    xml: {
      type: String,
      default: '',
    },
    closeAll: {
      type: Boolean,
      default: true,
    },
    openAll: {
      type: Boolean,
      default: true,
    },
    isEditModeOn: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.parse();
  },
  data() {
    return {
      isHeaderEditable: false,
      xmlForView: {
        header: null,
        body: null,
      },
      rerenderKey: 0,
      isCreateNewRowInProgress: false,
      newPointNumberOptions: [],
    };
  },
  watch: {
    closeAll() {
      this.changeVisibleChapter(-1, true);
    },
    openAll() {
      this.changeVisibleChapter(-1, false);
    },
  },
  methods: {
    onCreateNewRowInProgress() {
      this.isCreateNewRowInProgress = true;
    },
    onCreateNewRowFinished() {
      this.isCreateNewRowInProgress = false;
    },
    onPointDeleted(deletedPoint) {
      this.xmlForView.body = this.xmlForView.body.filter((point) => point.number !== deletedPoint.number).map((point, idx) => ({
        ...point,
        number: `${idx + 1}`,
      }));
      this.createXmlForServer();
    },
    onChapterChanged(newChapter) {
      this.xmlForView.body = this.xmlForView.body.map((chapter) => {
        if (chapter.number === newChapter.number) return {
          ...newChapter,
          changes: chapter.points.length !== newChapter.points.length ? chapter.changes + 1 : chapter.changes,
        };
        return chapter;
      });
      this.createXmlForServer();
    },
    onNewPointCreatedOnRootPoint(number) {
      this.xmlForView.body.splice(parseInt(number) - 1, 0, {
        number: number,
        points: [],
        chapters: [],
        text: '',
        isEditableNow: false,
        chapter: 0,
      });
      this.xmlForView.body = this.xmlForView.body.map((point, idx) => ({
        ...point,
        number: `${idx + 1}`,
      }));
      this.isCreateNewRowInProgress = false;
      this.createXmlForServer();
    },
    onNewPointCreated(number) {
      const numbers = number.split('.').map((it) => parseInt(it));
      if (numbers.length === 1) {
        this.onNewPointCreatedOnRootPoint(number);
        return;
      }
      const lastNumber = numbers[numbers.length - 1];
      let point = this.xmlForView.body[numbers[0] - 1];
      numbers.forEach((number, idx) => {
        if (idx === 0 || idx >= numbers.length - 1) return;
        point = point.points[number - 1];
      });
      point.points.splice(lastNumber - 1, 0, {
        number: `${lastNumber}`,
        points: [],
        chapters: [],
        text: '',
        isEditableNow: false,
      });
      point.points = point.points.map((point, idx) => ({
        ...point,
        number: `${idx + 1}`,
      }));
      this.xmlForView.body[numbers[0] - 1].changes++;
      this.isCreateNewRowInProgress = false;
      this.createXmlForServer();
    },
    createXmlForServer() {
      this.setEditedSelectedTemplateXml({
        xml: {
          contractHeader: `
          <Text>
            <Paragraph>
              ${this.xmlForView.header}
            </Paragraph>
          </Text>
        `,
          contractBody: generateContractBody(this.xmlForView),
        },
      });
    },
    changeVisibleChapter(number, closeAll) {
      if (!Array.isArray(this.xmlForView.body)) {
        return;
      }

      let allClose = true;

      this.xmlForView.body.forEach((row) => {
        if (row.number === number || closeAll !== undefined) {
          if (closeAll === undefined) {
            row.isShow = !row.isShow;
          } else {
            row.isShow = !closeAll;
          }
        }

        if (row.isShow) {
          allClose = false;
        }
      });

      this.$emit('allClosed', allClose);
    },

    parse() {
      this.xmlForView = parseContractXml(this.xml);
      this.createXmlForServer();
    },
    ...mapActions('electricityTransmissionContract', [
      'setEditedSelectedTemplateXml',
      'setContractLastChange',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.contract-xml-table {
  width: 100%;

  .td, .th {
    text-align: left;
    border: 1px solid #DEE2E6;
    padding: 20px;
  }

  &__button {
    height: 40px;
    width: 33px;

    border-radius: 0px 16px 16px 0px;
  }

  &__add-button {
    position: absolute;
    bottom: 0;
  }
}
</style>
