<template>
  <fragment style="width: 100%">
    <tr>
      <td style="border: 1px solid #DEE2E6; height: 1px;">

        <div style="position: relative;height: 100%;">
          <button
            v-show="!chapter.isNewPoint && !isCreateNewRowInProgress && isEditModeOn && !chapter.requisitesType"
            :disabled="!isEditModeOn || isCreateNewRowInProgress"
            style="background: inherit; width: 100%; position: absolute; bottom: -13px;"
            @click.prevent="addPointAfter"
          >
            <img src="../../assets/icons/contract/plus.svg">
          </button>
        </div>
      </td>
      <td class="td">{{ chapter.number }}</td>
      <td class="td">
        <div>{{ chapter.title }}</div>
        <div v-if="chapter.text.length">{{ chapter.text }}</div>
      </td>
      <td style="vertical-align: top;">
        <button
          v-show="!isInnerChapter"
          class="collapse_button"
          @click.prevent="changeVisibleChapter()"
        >
          <img v-if="chapter.isShow" src="../../assets/icons/arrows/arrow-down.svg">
          <img v-else src="../../assets/icons/arrows/arrow-up.svg">
        </button>
      </td>
    </tr>
    <xml-point-view v-for="(row) in chapter.points" :key="`${chapter.number}.${row.number}`"
                    :is-create-new-row-in-progress="isCreateNewRowInProgress" :is-edit-mode-on="isEditModeOn"
                    :is-show="chapter.isShow"
                    :point="row" :previous-number="chapter.number" @newRowFinished="emitNewRowFinished"
                    @newRowInProgress="emitNewRowInProgress"
                    @pointChanged="onPointChanged" @pointCreated="onNewPointCreated"
                    @pointDeleted="onPointDeleted"/>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: 'XmlChapterView',
  components: { Fragment },
  props: {
    chapter: { type: Object, required: true },
    isEditModeOn: { type: Boolean, default: false },
    isCreateNewRowInProgress: { type: Boolean, default: false },
    isInnerChapter: { type: Boolean, default: false },
  },
  beforeCreate: function() {
    this.$options.components.XmlPointView = require('./XmlPointView.vue').default;
  },
  methods: {
    emitNewRowInProgress() {
      this.$emit('newRowInProgress');
    },
    emitNewRowFinished() {
      this.$emit('newRowFinished');
    },
    changeVisibleChapter() {
      this.$emit('changeVisibility');
    },
    onNewPointCreated(number) {
      this.$emit('pointCreated', number);
    },
    onPointChanged(newPoint) {
      this.$emit('chapterChanged', {
        ...this.chapter,
        points: this.chapter.points.map((point) => {
          if (point.number === newPoint.number) return newPoint;
          return point;
        }),
      });
    },
    onPointDeleted(deletedPoint) {
      this.$emit('chapterChanged', {
        ...this.chapter,
        points: this.chapter.points.filter((point) => point.number !== deletedPoint.number).map((point, idx) => ({
          ...point,
          number: `${idx + 1}`,
        })),
      });
    },
    addPointAfter() {
      const newPoints = [...this.chapter.points];
      newPoints.splice(0, 0, {
        number: '',
        points: [],
        chapters: [],
        text: '',
        isEditableNow: false,
        isNewPoint: true,
      });
      console.log('new point');
      this.$emit('chapterChanged', {
        ...this.chapter,
        points: newPoints.map((point, idx) => ({
          ...point,
          number: `${idx + 1}`,
        })),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.td {
  text-align: left;
  border: 1px solid #DEE2E6;
  padding: 20px;
}

.collapse_button {
  height: 40px;
  width: 33px;

  border-radius: 0px 16px 16px 0px;
}
</style>
