<template>
  <v-spoiler v-if="type === 'v-spoiler'" :summary="summary" :is-open="isOpen" :disabled="disabled">
    <slot/>
  </v-spoiler>
  <spoiler-preview v-else v-model="isOpen" :disabled="disabled">
    <div slot="header" :class="$style.spoilerHeader">
      <spoiler-header :title="summary">
        <template v-if="!disabled" slot="icon">
          <chevron-up v-if="isOpen"/>
          <chevron-down v-else/>
        </template>
      </spoiler-header>
    </div>
    <slot/>
  </spoiler-preview>
</template>

<script>
import VSpoiler from '@/components/VSpoiler/VSpoiler';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';

export default {
  // TODO: заменить на v-spoiler (добавлен для компонентов, которые используются в старом и новом дизайне)
  name: 'VSpoilerProxy',
  components: {
    VSpoiler,
    ChevronDown,
    ChevronUp,
    SpoilerPreview,
    SpoilerHeader,
  },
  props: {
    type: {
      type: String,
      default: 'spoiler-preview',
      validator: (type) => ['v-spoiler', 'spoiler-preview'].includes(type),
    },
    summary: {
      type: String,
      required: true,
    },
    preSummary: {
      type: String,
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
<style lang="sass" module>
.spoilerHeader
  +questionnaries-container
</style>
