<template>
  <div v-if="technicalConditionsActDocumentId" class="container">
    <v-file-attachment :document-id="technicalConditionsActDocumentId"
    />
    <p v-if="submitError.length !== 0">{{ submitError }}</p>
    <horizontal-progress-bar v-if="isWaitingSubmit"/>
    <submit-task-details :disabled="isWaitingSubmit" :title="getTitle"
                         @submit="onSubmit"/>
    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="notSignedDoc"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
  </div>
</template>

<script>
import VFileAttachment from 'components/VFileAttachment/VFileAttachment';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import SimpleSignatureModal from 'components/SimpleSignatureModal/SimpleSignatureModal';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HTTPError from 'lib/utils/errors';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ApprovedTechnicalConditionsAct',
  components: { SubmitTaskDetails, SimpleSignatureModal, HorizontalProgressBar, VFileAttachment },
  props: {
    documentId: String,
    actionProps: Object,
    technicalConditionsActDocumentId: String,
    gridCompanyName: String,
    invoiceId: String,
    workflowId: String,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isWaitingSubmit: false,
      submitError: '',
      isVisibleSimpleSignatureModal: false,
      notSignedDoc: '',
    };
  },
  computed: {
    getTitle() {
      return 'ПОДПИСАТЬ И ОТПРАВИТЬ';
    },
    ...mapGetters('user', ['getUserType', 'getAccount']),
  },
  methods: {
    async loadData() {
      const doc = await this.loadDocument(this.technicalConditionsActDocumentId);
      this.notSignedDoc = doc.xml;
    },
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });
        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$nextTick(() => {
            this.vueShowNotification(
              'АКТ ВЫПОЛНЕНИЯ ТЕХНИЧЕСКИХ УСЛОВИЙ ОТПРАВЛЕН',
              '',
            );
          });
          this.$emit('close');
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      try {
        this.openSignatureModal();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('document', ['documentUpload', 'loadDocument']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  .blockContent {
    margin-bottom: 40px
  }
  .checkbox {
    margin: 40px 0
  }
}

</style>
