<template>
  <div v-if="filteredActionBlockItems.length">
    <select-button
      :items="filteredActionBlockItems"
      min-width="280"
    />
    <v-divider style="margin: 24px 0; height: 0;"/>
    <accounting-balances-from-excel
      v-model="visibleAddFromExcelDialog"
      :handle-error="handleError"
      :handle-success="handleSuccess"
    />
  </div>
</template>

<script>
import SelectButton from 'components/SelectButton/SelectButton.vue';
import { BASE_URL } from '@/constants/api';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import AccountingBalancesFromExcel from 'components/Processes/Process0/AccountingBalancesFromExcel.vue';

export default {
  name: 'ActionBlock',
  components: { AccountingBalancesFromExcel, SelectButton },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['elements', 'axp', 'consignments', 'plan-consignments', 'to-extradite', 'to-acceptance', 'contractor-debt', 'axp-warehouse', 'elements-warehouse'].includes(value);
      },
    },
  },
  data() {
    return {
      visibleAddFromExcelDialog: false,
    };
  },
  computed: {
    actionBlockItems() {
      return [
        {
          id: 'process/5/1',
          title: 'Формирование проекта накладной',
          method: this.goToPageByRoute('process/5/1'),
        },
        {
          id: 'process/6/1',
          title: 'Выдача АХП со склада',
          method: this.goToPageByRoute('process/6/1'),
        },
        {
          id: 'process/15/1',
          title: 'Приёмка на склад',
          method: this.goToPageByRoute('process/15/1'),
        },
        {
          id: 'process/16/1',
          title: 'Дефектовка',
          method: this.goToPageByRoute('process/16/1'),
        },
        {
          id: 'process/16/2',
          title: 'Списание',
          method: this.goToPageByRoute('process/16/2'),
        },
        {
          id: 'process/16/3',
          title: 'Выдача в ремонт',
          method: this.goToPageByRoute('process/16/3'),
        },
        {
          id: 'process/16/4',
          title: 'Возврат из ремонта',
          method: this.goToPageByRoute('process/16/4'),
        },
        {
          id: 'process/0/1',
          title: 'Учёт складских остатков',
          method: this.loadFromExcel,
        },
        {
          id: 7,
          title: 'Очистка данных (ТЕСТ)',
          method: () => fetch(`${BASE_URL}/delete-all-from-warehouse-stock`),
        },
      ];

    },
    filteredActionBlockItems() {
      return this.actionBlockItems.filter((item) => filterForAccess(item.id, this.getAuthorization?.blockAccess));
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    loadFromExcel() {
      this.visibleAddFromExcelDialog = true;
    },
    handleSuccess() {
      window.location.reload();
    },
    handleError(error) {
      console.error(error);
      this.setNotification({ message: error });
    },
    goToPageByRoute(path) {
      return () => this.$router.push(path);
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
