<template>
  <div class="signing-basis-selector">
    <span class="signing-basis-selector__title">
      Основание, по которому осуществляет деятельность
    </span>


    <div v-if="inputModeType === 'charter'">
      <select
        v-model="charterShow"
        :disabled="true"
        class="signing-basis-selector__select"
      >
        <option
          v-for="(charter, index) in ['Устав']"
          :key="index"
          :value="charter"
        >
          {{ charter }}
        </option>
      </select>
    </div>

    <div v-if="inputModeType === 'powerOfAttorney'">
      <select
        v-model="reason.id"
        :disabled="!editable"
        class="signing-basis-selector__select"
        @change="changeHandler(reason.id)"
      >
        <option
          v-for="(poa, index) in powerOfAttorneyList"
          :key="index"
          :value="poa.id"
        >
          {{ poa.title }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  components: {},
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    inputModeType: {
      type: String,
      required: true,
    },
    powerOfAttorneyList: {
      type: Array,
      default: () => [],
    },
    initReasonId: {
      type: String,
      required: false,
      default: null,
    },
  },
  created() {
    this.update();
  },
  data() {
    return {
      reason: {
        id: null,
        reasonDocument: null,
        number: null,
        date: null,
      },
      charterShow: 'Устав',
    };
  },
  watch: {
    powerOfAttorneyList: {
      handler() {
        this.update();
      },
    },
  },
  methods: {
    update() {
      if (this.inputModeType === 'charter') {
        this.changeHandler(null);
        return;
      }
      if (this.initReasonId) {
        this.changeHandler(this.initReasonId);
        return;
      }
      this.changeHandler(this.powerOfAttorneyList[0].id);
    },
    emptyReason() {
      return {
        id: null,
        reasonDocument: 'Устав',
        number: null,
        date: null,
      };
    },
    changeHandler(selectedPowerOfAttorneyId) {
      const powerOfAttorney = this.powerOfAttorneyList.find(
        (powerOfAttorney) => powerOfAttorney.id === selectedPowerOfAttorneyId,
      );
      this.reason = powerOfAttorney ? {
        id: powerOfAttorney.id,
        date: convertDbDateTimeFormat(powerOfAttorney.grantedAt),
        number: powerOfAttorney.number,
        reasonDocument: 'Доверенность',
      } : this.emptyReason();
      this.$emit('select', { ...this.reason });
    },
  },
};
</script>

<style lang="scss" scoped>
.signing-basis-selector {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
  }

  &__inputs {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 16px 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
  }

  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;
    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
    }

    &_error {
      border-color: #EB5757;
    }
  }
}
</style>
