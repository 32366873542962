<template>
  <div :class="$style.container">
    <div
      :class="{
        [$style.header]: true,
        [$style.disabled]: disabled,
      }"
      :style="{ background }"
      @click="handleClick"
    >
      <slot name="header" :internal-value="internalValue">
        <slot name="title">{{ title }}</slot>
      </slot>
    </div>

    <div
      v-show="internalValue"
      :class="$style.content"
      :style="{ background }"
    >
      <slot v-if="$slots.hasOwnProperty('default')" />

      <div v-else :class="$style.contentDefault">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spoiler',
  props: {
    value: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    content: {
      type: String,
      default() {
        return '';
      },
    },
    background: {
      type: String,
      default: () => 'transparent',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      internalValue: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internalValue = val;
      },
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }

      const newValue = !this.internalValue;

      this.internalValue = newValue;
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="sass" module>
  .header
    margin-bottom: 12px
    cursor: pointer

    &.disabled
      cursor: default

  .content
    height: 100%
    display: flex
    flex-direction: column
    flex: 1

  .contentDefault
    padding: 8px
</style>
