<template>
  <div>
    <warehouses-autocomplete
        ref="warehouse"
        v-model="internalObject.warehouseId"
        :is-disabled="disabledMainInfo"
        :items.sync="internalObject.warehouses"
        :search.sync="internalObject.warehouseName"
        is-required
        title="Склад"
    />

    <contractor-autocomplete
      ref="contractor"
      v-model="internalObject.contractorId"
      :is-disabled="disabledMainInfo"
      :items.sync="internalObject.contractors"
      :search.sync="internalObject.contractorName"
      is-required
      title="Подрядчик"
    ></contractor-autocomplete>

    <v-checkbox v-model="internalObject.isByContract" :disabled="disabledMainInfo" label="Приёмка по договору"/>
    <div v-if="internalObject.isByContract">
      <autocomplete-find-id ref="contract" v-model="internalObject.contractId" :find-id="internalObject.contractorId"
                            :is-disabled="internalObject.contractorId == null||disabledMainInfo"
                            :items.sync="internalObject.contracts"
                            :search.sync="internalObject.contractName"
                            is-required
                            is-required-find-id
                            title="Договор"
                            url-default-data="/contract-debt/contractor/{0}/top20"
                            url-search="/contract-debt/contractor/{0}/find?name={1}"></autocomplete-find-id>

      <v-banner v-if="!internalObject.contractId" class="mb-2">Если договора нет, перейдите в <a
        @click="$router.push('/cabinet/process/4/1')">раздел создания нового договора</a> или <a
        @click="$router.push('/cabinet/process/4/2')">внесения спецификации</a></v-banner>
    </div>
    <div v-else>
      <events-autocomplete
          ref="event"
          v-model="internalObject.eventId"
          :find-ids="eventsFindIds"
          :is-disabled="internalObject.contractorId == null || disabledMainInfo"
          :items.sync="internalObject.eventItems"
          :search.sync="internalObject.eventName"
          title="Мероприятие"
          url-default-data="/events/contractor/{0}/top20"
          url-search="/events/contractor/{0}/find?name={1}"
      />
    </div>

    <axp-autocomplete
      ref="axp"
      v-model="internalObject.axpId"
      :is-disabled="disabledMainInfo"
      :items.sync="internalObject.axpItems"
      :search.sync="internalObject.axpName"
      is-required
      title="АХП"
    />

    <axp-photos-carousel v-if="internalObject.axpId" :axp-id="internalObject.axpId"/>

    <v-row class="pr-4 mb-2">
      <v-col/>
      <v-col/>
      <v-col>
        <v-row>
          <v-spacer/>
          <v-btn @click="accountTotal">Рассчитать</v-btn>
          <v-spacer/>
          <v-btn @click="clearTotal">Очистить форму</v-btn>
        </v-row>
      </v-col>
    </v-row>


    <div>
      <v-data-table
        :headers="headersTable"
        :items="internalObject.formElements"
        :loading="isLoadingElements"
        class="elevation-1 mb-4"
      >
        <template #no-data>
          <span>Отсутствуют данные</span>
        </template>
        <template #item.realCount="{ item }">
          <div class="d-flex align-center mt-6">
            <text-field
              ref="realCount"
              v-model="item.realCount"
              is-only-numbers
              simple
            ></text-field>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import { mapGetters } from 'vuex';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  name: 'Process7',
  components: {
    AxpPhotosCarousel,
    AxpAutocomplete,
    TextField,
    AutocompleteFindId,
    ContractorAutocomplete,
    WarehousesAutocomplete,
    EventsAutocomplete,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    disabledMainInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoadingElements: false,
    internalObject: {},
    warehouses: [],
    contractors: [],
    axpItems: [],
    eventItems: [],
    contracts: [],
    headersTable: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      {
        text: 'Количество в одном АХП',
        value: 'elementQuantity',
        align: 'center',
      },
      {
        text: 'Принято без пересчёта',
        value: 'countWithoutRecalculation',
        align: 'center',
      },
      {
        text: 'Пересчитанное количество',
        value: 'realCount',
        align: 'center',
      },
    ],
  }),
  computed: {
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.contractorId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.internalObject = newVal;
        }
      },
    },
    internalObject: {
      immediate: true,
      deep: true,
      handler() {
        this.internalObject.isDisabled = this.accountDisabled();
        this.$emit('input', this.internalObject);
      },
    },
    'internalObject.isByContract': {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        if (!newVal) this.internalObject.contractId = null;
        else if (!this.disabledMainInfo) {
          this.internalObject.eventId = null;
        }
      },
    },
    'internalObject.axpId': {
      deep: true,
      immediate: true,
      async handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.internalObject.formElements = [];
        if (newVal != null) {
          await this.fetchDataProcesses(newVal);
        }
      },
    },
  },
  methods: {
    async fetchDataProcesses() {
      const response = await fetch(
        `${BASE_URL}/axp/${this.internalObject.axpId}/elements/actual`,
      );
      const elements = await response.json();
      const params = {
        axpId: this.internalObject.searchParams.axpId,
        warehouseId: this.internalObject.searchParams.warehouseId,
        contractorId: this.internalObject.searchParams.contractorId,
      };
      this.internalObject.searchParams.eventId != -1 && this.internalObject.searchParams.eventId != null ? params.eventId = this.internalObject.searchParams.eventId : false;
      this.internalObject.searchParams.contract != -1 && this.internalObject.searchParams.contract != null ? params.contract = this.internalObject.searchParams.contract : false;
      const responseForCount = await fetch(
        `${BASE_URL}/acceptance-to-warehouse/for-recalculation/count?${new URLSearchParams(params).toString()}`,
      );
      const elementsWithCount = await responseForCount.json();
      const mappedElements = {};
      elementsWithCount.forEach((it) => mappedElements[it.elementId] = it);
      this.internalObject.formElements = elements.map((el) => ({
        elementId: el.id,
        elementName: el.name,
        elementQuantity: el.quantity,
        countWithoutRecalculation: mappedElements[el.id]?.forRecalculationCount ?? 0,
        realCount: '',
        unitName: el.unitName,
      }));
    },
    accountTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount:
          String(el.countWithoutRecalculation),
      }));
    },
    clearTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount: '',
      }));
    },
    accountDisabled() {
      const isDisabledWarehouse = this.internalObject.warehouseId == null;
      const isDisabledContractor = this.internalObject.contractorId == null;
      const isDisabledContract = this.internalObject.isByContract && this.internalObject.contractId == null;
      const isDisableAxp = this.internalObject.axpId == null;
      const isDisableCountElements = this.internalObject.formElements.some((it) => Number(it.realCount) < 0) ||
        this.internalObject.formElements.every((it) => !it.realCount?.length || isNaN(Number(it.realCount) || Number(it.realCount) === 0));

      return isDisabledWarehouse ||
        isDisabledContractor ||
        isDisableAxp ||
        isDisabledContract ||
        isDisableCountElements;
    },
    resetValidation() {
      this.isLoadingElements = false;
      this.internalObject = {};
      this.axpQuantity = '';
      this.warehouseName = '';
      this.warehouses = [];
      this.contractorName = '';
      this.contractors = [];
      this.axpName = '';
      this.axpItems = [];
      this.eventName = '';
      this.eventItems = [];
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
