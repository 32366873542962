<template>
  <td
    v-if="header.type!=='hide'"
    ref="td"
    :class="{
      ['table__td']: true,
      ['table__td--row-color']: data.rowColor,
      ['table__td__padding']: !dividerShow
    }"
    :style="data.tdStyle && data.tdStyle[header.key] ? data.tdStyle[header.key] : header.tdStyle"
  >
    <v-table-cell
      :cell-value="cellValueByType(header)"
      :custom-check="customCheck()"
      :data-color="data.dataColor"
      :options="cellOptionsByType(header)"
      :row-color="data.rowColor"
      :spoiler-count="header.spoilerCount"
      :data="data"
      :type="header.type"
      :is-disabled="header.isDisabled"
      :validation-messages="cellValidationMessagesByType(header)"
      :validation-rules="cellValidationRulesByType(header)"
      :style="nestedCellStyle"
      :is-nested="isNested"
      :is-nested-show="isNestedShow"
      @delete="onCellDeleteHandler($event, header)"
      @edit="onCellEditHandler($event, header)"
      @on-cell-click="onCellClickHandler($event, header)"
      @on-cell-input="onCellInputHandler($event, header)"
      @on-spoiler-button-click="onSpoilerButtonClickHandler"
    />
  </td>
</template>

<script>
import { VTableCell } from '../VTableCell';
import { mapGetters } from 'vuex';

export default {
  name: 'VTableTd',
  components: {
    VTableCell,
  },
  props: {
    data: { type: Object, required: true },
    nextRowData: { type: Object, default: undefined },
    header: { type: Object, required: true },
    index: { type: Number, default: -1 },
    spoilerCount: {
      type: Number,
      default: 0,
    },
    isNested: { type: Boolean, default: false },
    isNestedShow: { type: Boolean, default: true },
    dividerShow: { type: Boolean, default: true },
  },
  mounted() {
    this.setFirefoxStyle();
  },
  computed: {
    nestedCellStyle() {
      if (this.isNested && this.index === 0) return { marginLeft: '20px' };
      return {};
    },
    ...mapGetters('user', ['getAuthorization', 'haveUserPermissionToDeleteUserFromCompany']),
    ...mapGetters('environment', ['browserProperties']),
  },
  methods: {
    setFirefoxStyle() {
      if (this.browserProperties.isFirefox) {
        const { td } = this.$refs;
        td.style.height = '100%';
      }
    },
    cellValueByType(header) {
      let emptyValue = '-';
      if (header.type === 'checkbox') emptyValue = false;
      if (header.type === 'input' || header.type === 'select') emptyValue = '';
      const keys = header.key.split('.');
      if (keys.length > 1) {
        return keys.reduce((acc, cur) => {
          if (acc[cur]) {
            return acc[cur];
          }
          return emptyValue;
        }, this.data);
      }
      if (this.data[header.key] && this.data[header.key].length !== 0) {
        return this.data[header.key];
      }
      if (typeof this.data[header.key] === 'number') {
        return this.data[header.key];
      }
      return emptyValue;
    },
    cellOptionsByType(header) {
      if (!this.data.options) return;
      const keys = header.key.split('.');
      if (keys.length > 1) {
        return keys.reduce((acc, cur) => {
          if (acc[cur]) {
            return acc[cur];
          }
          return [];
        }, this.data);
      }
      if (this.data.options[header.key] && this.data.options[header.key].length !== 0) {
        return this.data.options[header.key];
      }
      return [];
    },
    cellValidationRulesByType(header) {
      if (!this.data.validationRules) return;
      const keys = header.key.split('.');
      if (keys.length > 1) {
        return keys.reduce((acc, cur) => {
          if (acc[cur]) {
            return acc[cur];
          }
          return '';
        }, this.data);
      }
      if (this.data.validationRules[header.key] && this.data.validationRules[header.key].length !== 0) {
        return this.data.validationRules[header.key];
      }
      return '';
    },
    cellValidationMessagesByType(header) {
      if (!this.data.validationMessages) return;
      const keys = header.key.split('.');
      if (keys.length > 1) {
        return keys.reduce((acc, cur) => {
          if (acc[cur]) {
            return acc[cur];
          }
          return {};
        }, this.data);
      }
      if (this.data.validationMessages[header.key] && this.data.validationMessages[header.key].length !== 0) {
        return this.data.validationMessages[header.key];
      }
      return {};
    },
    onCellDeleteHandler($event, header) {
      this.$emit('delete', {
        value: $event,
        key: header.key,
        label: header.label,
        type: header.type,
        // TODO: its not best idea cause data(props) may be stolen
        row: this.data,
      });
    },
    onCellEditHandler($event, header) {
      this.$emit('edit', {
        value: $event,
        key: header.key,
        label: header.label,
        type: header.type,
        // TODO: its not best idea cause data(props) may be stolen
        row: this.data,
      });
    },
    onCellClickHandler($event, header) {
      this.$emit('on-cell-click', {
        value: $event,
        key: header.key,
        label: header.label,
        type: header.type,
        // TODO: its not best idea cause data(props) may be stolen
        row: this.data,
      });
    },
    onCellInputHandler($event, header) {
      this.$emit('on-cell-input', {
        value: $event,
        key: header.key,
        label: header.label,
        type: header.type,
        // TODO: its not best idea cause data(props) may be stolen
        row: this.data,
      });
    },
    customCheck() {
      if (this.header.type === 'clip' && this.header.keyClip) {
        const keyClips = this.header.keyClip.split(',');
        return this.nextRowData && keyClips.every((keyClip) => this.data[keyClip] === this.nextRowData[keyClip]);
      }
      if (this.header.checkEmpty) {
        return !this.data[this.header.key];
      }
      if (this.header.checkCrossDelete) {
        let check = false;
        // dont show for user with "OWNER" role
        if (this.data.role.isCompanyOwner) {
          check = true;
        }

        // dont show for user which dont have delete permission
        // TODO: implement for new permissions
        /* if (!this.haveUserPermissionToDeleteUserFromCompany) {
          check = false;
        }*/

        // dont show for himself
        if (this.getAuthorization.authorization.id === this.data.id) {
          check = true;
        }

        return check;
      }
      if (this.header.checkOnlyOwnerIsNotOwner) {
        if (this.data.role.isCompanyOwner || this.data.status === 'INVITED') {
          return true;
        }

        if (!this.getAuthorization.authorization.role.isCompanyOwner) {
          return true;
        }

        return false;
      }
      if (this.header.customCheck && this.header.type === 'filterAndCheckbox') {
        return true;
      }
      if (this.header.customCheck) {
        return this.header.customCheck(this.data);
      }
      return false;
    },
    onSpoilerButtonClickHandler() {
      this.$emit('on-spoiler-button-click');
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__td {
    position: relative;
    color: #263238;
    padding: 10px 5px;

    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &__slider {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--row-color {
      padding: 3px 0;
      height: 1px;
    }

    &__padding {
      padding: 3px 10px;
    }
  }
}
</style>
