<template>
  <div class="button-group">
    <button v-if="isInitiator" disabled type="button">
      <img alt="редактировать" src="@/assets/icons/user/edit-initiator.svg" @click="$emit('edit-initiator')">
    </button>
    <button v-if="!isInitiator" type="button">
      <img alt="редактировать" src="@/assets/icons/user/edit-member.svg" @click="test">
    </button>
    <button v-if="!isInitiator" type="button">
      <img alt="удалить" src="@/assets/icons/user/delete-member.svg" @click="$emit('delete')">
    </button>
  </div>
</template>

<script>
export default {
  name: 'VTableCellEditAndDelete',
  props: {
    isInitiator: { type: Boolean, default: false },
  },
  methods: {
    test() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  gap: 8px;

  button {
    width: 24px;
    height: 24px;
    background: inherit;
  }
}
</style>
