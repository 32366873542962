<template>
  <div>
    <index-electricity-transmission-contract
      style="margin: 24px 0 20px 100px;"
    />
  </div>
</template>

<script>
import IndexElectricityTransmissionContract from 'views/private/UploadDocument/steps/ContractRequisites/index.vue';

export default {
  name: 'ElectricityTransmissionContract',
  components: { IndexElectricityTransmissionContract },
};
</script>

<style scoped>

</style>
