<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="title">
              <span class="text-h5">Монтаж</span>
              <span v-if="isExpired" class="errorText">
                {{subtitleError}}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <contractor-autocomplete
              v-if="isBoiler"
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-required
              title="Подрядчик"
            ></contractor-autocomplete>

            <events-autocomplete
              ref="event"
              v-model="eventId"
              :find-ids="eventsFindIds"
              :is-disabled="!contractorId"
              :items.sync="eventItems"
              :search.sync="eventName"
              is-required
              title="Мероприятие"
              url-default-data="/events/contractor/{0}/top20"
              url-search="/events/contractor/{0}/find?name={1}"
            />

            <autocomplete-find-id
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              :is-disabled="!contractorId || !eventId"
              :items.sync="adreskaItems"
              :search.sync="adreskaAddress"
              is-required
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
              url-default-data="/adreska/contractor/{0}/event/{1}/top20?statuses=MOUNTED,MOUNTING_IN_PROCESS,null"
              url-search="/adreska/contractor/{0}/event/{1}/find?name={2}&statuses=MOUNTED,MOUNTING_IN_PROCESS,null"
            ></autocomplete-find-id>

            <div v-if="adreska">
              <v-row>
                <v-col>
                  <p>Выберите точки где установлена ОДК*</p>
                  <yandex-map
                    :collection-axp="adreskaValues"
                    enable-multiple-adding
                    @clickMap="onClickMap"
                    @tapPlacemark="onTapPlacemark"
                  />
                </v-col>
              </v-row>
              <text-field
                v-model="adreska.axp.odk.name"
                is-disabled
                title="ОДК"
              ></text-field>

              <axp-autocomplete
                ref="axp"
                v-model="axpId"
                :find-ids="axpFindIds"
                :items.sync="axpItems"
                :search.sync="axpName"
                is-required
                title="АХП"
                url-default-data="/axp/find?odkId={0}"
                url-search="/axp/find?odkId={0}&name={1}"
              />

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Список элементов
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="elementsHeaders"
                      :items="axpElements"
                      :loading="isLoadingElements"
                      class="elevation-1 mb-4"
                      style="width: 100%"
                    >
                      <template #no-data>
                        <span>Отсутствуют данные</span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="decor-wrapper">
                <decor-type-autocomplete
                  ref="decorType"
                  v-model="decorTypeId"
                  :find-ids="decorTypeFindIds"
                  :is-disabled="!axpId"
                  :items.sync="decorTypeItems"
                  :search.sync="decorTypeName"
                  title="Тип декора"
                  url-default-data="/axp/{0}/decor-types/find"
                  url-search="/axp/{0}/decor-types/find?name={1}"
                ></decor-type-autocomplete>
              </div>

              <adreska-history
                :adreska-id="adreskaId"
                style="margin-top: 12px"
                subselect="INSTALLATION_CONTROL"
                title="Информация о монтаже"
              />

              <v-data-table
                v-if="!axp.specialProject"
                v-model="selectedAxpElementTypes"
                :headers="axpElementTypesHeaders"
                :items="axpElementTypes"
                :loading="isLoadingAxpElementTypes"
                class="elevation-1 mb-4"
                style="width: 100%"
                item-key="id"
                show-select
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
              </v-data-table>

              <text-field
                v-else
                v-model="percentageConstruction"
                title="% монтажа"
                :is-required="axp.specialProject"
              ></text-field>
            </div>

            <text-field
              v-if="adreska"
              ref="contract"
              v-model="adreska.quantityPlan"
              is-disabled
              title="Количество ОДК по плану"
            ></text-field>

            <text-field
              ref="contract"
              v-model="countOdk"
              is-disabled
              title="Количество устанавливаемых комплектов ОДК"
            ></text-field>

            <v-row class="mt-12">
              <v-col>
                <div>
                  <v-label>Комментарий к монтажу</v-label>
                </div>
                <v-textarea
                  v-model="commentInstallation"
                  dense
                  name="input-7-4"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="mt-12">
              <v-col>
                <div>
                  <v-label>Комментарий к месту установки</v-label>
                </div>
                <v-textarea
                  v-model="commentPlace"
                  dense
                  name="input-7-4"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <file-input
              ref="photos"
              v-model="photos"
              :loading="isLoadingPhotos"
              class="mt-2"
              is-required
              title="Выберите фото"
            ></file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog
        v-model="loaderDialog"
        class="v-dialog-top"
        persistent
        width="250"
      >
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + '%' }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import AutocompleteFindId from '@/components/Processes/components/AutocompleteFindId.vue';
import TextField from '@/components/Processes/components/TextField.vue';
import FileInput from '@/components/Processes/components/FileInput.vue';
// import { D3_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import { formatDateFromOdkTimestamp, formatOdkTimestampToDateObject, getDateAtStartOfDay } from 'lib/utils/date';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import DecorTypeAutocomplete from 'components/Processes/components/DecorTypeAutocomplete.vue';
import {
  fetchAdreskaById,
  getElementTypesByAxpId,
  getElementTypesByAxpIdWithDecors,
} from '@/api/rest/odk/api';

export default {
  components: {
    AdreskaHistory,
    YandexMap,
    ContractorAutocomplete,
    AutocompleteFindId,
    TextField,
    FileInput,
    AxpAutocomplete,
    EventsAutocomplete,
    DecorTypeAutocomplete,
  },
  created() {
    if (!this.isBoiler) this.contractorId = this.getAccount.supplier.id;
    this.initAdreska();
  },
  data() {
    return {
      contractorId: null,
      adreskaId: null,
      axp: null,
      axpId: null,
      eventId: null,
      decorTypeId: null,
      contractorName: '',
      adreskaAddress: '',
      axpName: '',
      eventName: '',
      decorTypeName: '',
      contractors: [],
      adreskaItems: [],
      axpItems: [],
      eventItems: [],
      decorTypeItems: [],

      axpElements: [],

      selectedCoords: [],
      adreska: null,
      adreskaFactCollection: new Map(),

      commentInstallation: null,
      commentPlace: null,
      photos: [],

      isLoadingElements: false,
      isLoadingPhotos: false,

      progressValue: 0,
      loaderDialog: false,

      elementsHeaders: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Элемент',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Eдиница измерения',
          align: 'start',
          sortable: true,
          value: 'unitName',
        },
        {
          text: 'Количество на 1 ОДК',
          align: 'start',
          sortable: true,
          value: 'count',
        },
        {
          text: 'Тип декора',
          align: 'start',
          sortable: true,
          value: 'decorTypeName',
        },
      ],

      axpElementTypesHeaders: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Внешний ID',
          align: 'start',
          sortable: true,
          value: 'externalId',
        },
        {
          text: 'Наименование',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: '% монтажа',
          align: 'start',
          sortable: true,
          value: 'installationCompletionPercentage',
        },
      ],
      axpElementTypes: [],
      isLoadingAxpElementTypes: false,
      selectedAxpElementTypes: [],
      percentageConstruction: '',
    };
  },
  computed: {
    ...mapGetters('user', ['getToken', 'isBoiler', 'isODK', 'getAccount']),
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisabledCoord = !this.adreskaValues?.length;
      const isDisabledDdreska = !this.adreska;
      const isDisabledPhoto = this.photos.length == 0;
      const isDisabledSpecialProject =
        this.axp &&
        this.axp.specialProject &&
        this.percentageConstruction.length === 0;
      return (
        isDisabledContractor ||
        isDisabledDdreska ||
        isDisabledCoord ||
        isDisabledPhoto ||
        isDisabledSpecialProject
      );
    },
    isExpired() {
      if (!this.adreska) return false;
      // После приёмки процесс не доступен => если зашли в процесс <=> Приёмка не осуществлялась
      return getDateAtStartOfDay(Date.now()) > formatOdkTimestampToDateObject(this.adreska.event.planInstallationEndDate);
    },
    subtitleError() {
      return 'Просрочена плановая дата окончания монтажа';
    },
    adreskaValues() {
      return [...this.adreskaFactCollection.values()];
    },
    countOdk() {
      return this.adreskaValues.length;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorId,
        },
      ];
    },
    axpFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.adreska.axp.odk.id,
        },
      ];
    },
    decorTypeFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.axpId,
        },
      ];
    },
  },
  watch: {
    adreskaId() {
      if (!this.adreskaId) {
        this.axpId = null;
        this.adreska = null;
        this.axp = null;
        return;
      }
      this.adreskaFactCollection = new Map();
      this.fetchAdreska();
      this.fetchAdreskaFactCollection();
    },
    decorTypeId() {
      if (this.selectedAxpElementTypes.length > 0) {
        this.fetchAxpElements();
      }
    },
    axpId(newValue) {
      if (!newValue) {
        this.axpElementTypes = [];
      }
      this.getAxpElementsTypes();
    },
    selectedAxpElementTypes: {
      handler() {
        this.fetchAxpElements();
      },
      deep: true,
    },
  },
  methods: {
    async initAdreska() {
      if (!this.$route.params.initAdreskaId) return;
      const adreska = await fetchAdreskaById(this.$route.params.initAdreskaId);
      if (!adreska) {
        await this.setNotification({
          message: `Не найдена программа оформления с ID ${this.$route.params.initAdreskaId}`,
        });
        this.onTapBack();
      }
      if (
        !this.isBoiler &&
        adreska.contractorId !== this.getAccount.supplier.id
      ) {
        await this.setNotification({
          message: 'Вы не можете монтировать по данному адресу',
        });
        this.onTapBack();
      }

      this.contractors = [
        {
          id: adreska.contractorId,
          name: adreska.contractorName,
        },
      ];
      this.contractorName = adreska.contractorName;
      this.contractorId = adreska.contractorId;

      this.eventItems = [
        {
          id: String(adreska.event.id),
          name: adreska.event.name,
        },
      ];
      this.eventName = adreska.event.name;
      this.eventId = String(adreska.event.id);

      this.adreskaItems = [
        {
          id: adreska.id,
          name: adreska.name,
        },
      ];
      this.adreskaAddress = adreska.name;
      this.adreskaId = adreska.id;
      this.axp = adreska.axp;
    },
    async fetchAxpElements() {
      if (!this.axpId) return;
      this.isLoadingElements = true;
      this.axpElements = [];
      try {
        const params = {};

        if (this.decorTypeId) {
          params.decorTypeId = this.decorTypeId;
        }

        const elementIds = this.selectedAxpElementTypes
          .map((el) => el.id)
          .join(',');

        const { data } = await getElementTypesByAxpIdWithDecors(
          this.axpId,
          elementIds,
          params,
        );

        this.axpElements = data.map((it) => ({
          id: it.id,
          name: it.name,
          unitName: it.unit.name,
          decorTypeName: it.decorType?.name,
          count: it.quantity,
        }));
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingElements = false;
      }
    },
    onTapPlacemark(id) {
      this.adreskaFactCollection.delete(id);
      this.adreskaFactCollection = new Map(this.adreskaFactCollection);
    },
    async fetchAdreskaFactCollection() {
      const response = await fetch(
        `${BASE_URL}/adreskafact?adreskaId=${this.adreskaId}`,
      );
      const data = await response.json();
      if (data.length) {
        this.adreskaFactCollection = new Map(
          data.map((el) => {
            let color;
            let status;
            if (el.statusInstallationResultControl == 1) {
              color = 'red';
              status = 'С замечаниями';
            } else if (el.statusInstallationResultControl == 2) {
              color = 'green';
              status = 'Без замечаний';
            } else {
              color = 'blue';
              status = 'Контроль не проводился';
            }
            return [
              el.id,
              {
                id: el.id,
                adreskaFactId: el.id,
                locationLatitude: el.locationLatitude,
                locationLongitude: el.locationLongitude,
                statusAssemblyAcceptance: el.statusAssemblyAcceptance,
                isSelected: false,
                color: color,
                balloonContent: {
                  title: el.axp.odk.name,
                  buttonLabel:
                    el.statusAssemblyAcceptance > 0 ? null : 'Удалить',
                  props: [
                    {
                      label: 'ID',
                      value: el.id,
                    },
                    {
                      label: 'АХП',
                      value: el.axp.name,
                    },
                    {
                      label: 'Тип декора',
                      value: el.decorType?.name,
                    },
                    {
                      label: 'Статус',
                      value: status,
                    },
                    {
                      label: 'Дата монтажа',
                      value: formatDateFromOdkTimestamp(el.createDate),
                    },
                  ],
                },
              },
            ];
          }),
        );
      }
    },
    async fetchAdreska() {
      const response = await fetch(`${BASE_URL}/adreska/${this.adreskaId}`);
      if (response.status === 200) {
        this.adreska = await response.json();
        this.axpItems = [
          {
            id: String(this.adreska.axp.id),
            name: this.adreska.axp.name,
          },
        ];
        this.axpName = this.adreska.axp.name;
        this.axp = this.adreska.axp;
        this.axpId = String(this.adreska.axp.id);

        if (!this.adreskaValues.length) {
          this.onClickMap([
            this.adreska.locationLatitude,
            this.adreska.locationLongitude,
          ]);
        }
      } else {
        this.adreska = null;
        this.axpId = null;
      }
    },
    async getAxpElementsTypes() {
      if (!this.axpId) return;
      this.isLoadingAxpElementTypes = true;
      try {
        const { data } = await getElementTypesByAxpId(this.axpId);
        // console.log('getAxpElementsTypes::result', data);

        const elementTypes = [];
        data.map((it) => {
          elementTypes.push(it);
        });

        this.axpElementTypes = elementTypes;
      } catch (error) {
        console.log('getAxpElementsTypes::catch', error);
      } finally {
        this.isLoadingAxpElementTypes = false;
      }
    },
    onClickMap(coords) {
      const id = crypto.randomUUID();
      this.adreskaFactCollection.set(id, {
        id: id,
        locationLatitude: coords[0],
        locationLongitude: coords[1],
        balloonContent: {
          title: 'Новая точка',
          buttonLabel: 'Удалить',
        },
      });
      this.adreskaFactCollection = new Map(this.adreskaFactCollection);
    },

    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        contractorId: this.contractorId,
        axpId: this.axpId,
        adreskaId: this.adreskaId,
        commentInstallation:
          this.commentInstallation == null ? '' : this.commentInstallation,
        constructions: this.adreskaValues.map((it) => ({
          locationLatitude: it.locationLatitude,
          locationLongitude: it.locationLongitude,
          id: it.adreskaFactId,
        })),
        decorTypeId: this.decorTypeId,
        commentPlace: this.commentPlace == null ? '' : this.commentPlace,
        photos: mainPhotos,
      };

      if (this.axp && this.axp.specialProject) {
        request.specialProjectInstallationCompletionPercentage = parseInt(
          this.percentageConstruction,
        );
      } else {
        request.axpElementTypeIds = this.selectedAxpElementTypes.map((it) => it.id);
      }

      axios
        .post(`${BASE_URL}/installation-construction`, request, {
          headers: { 'X-Authorization': `Bearer ${this.getToken}` },
        })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapBack() {
      this.$router.back();
    },
    onTapAgain() {
      this.contractorId = null;
      this.adreskaId = null;
      this.axpId = null;
      this.eventId = null;
      this.decorTypeId = null;

      this.contractorName = '';
      this.adreskaAddress = '';
      this.axpName = '';
      this.eventName = '';
      this.decorTypeName = '';

      this.contractors = [];
      this.adreskaItems = [];
      this.axpItems = [];
      this.eventItems = [];
      this.decorTypeItems = [];

      this.selectedCoords = [];
      this.photos = [];

      this.commentInstallation = null;
      this.commentPlace = null;

      this.adreskaFactCollection = new Map();

      this.isLoadingPhotos = false;

      this.axpElements = [];

      this.progressValue = 0;
      this.loaderDialog = false;
      this.axpElementTypes = [];
      this.selectedAxpElementTypes = [];
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
#maps {
  width: 100%;
  height: 50vh;
  padding-top: 16px;
  font-size: 16px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.ymap-container {
  height: 100%;
}
.decor-wrapper {
  margin-top: 24px;
}
</style>
