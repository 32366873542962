<template>
  <page title="Справочник контрагентов ОДК">
    <v-table-new
      v-model="transformedSuppliers"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник контрагентов ОДК"
      @delete="onDelete"
      @edit="onEdit"
      @on-cell-click="cellClickHandler"
    />
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="action"
      @delete="action"
      @update="action"
    >
      <template #inn="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="required|digits:10|inn"
          @input="slotProps.input"
        />
      </template>
      <template #kpp="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="digits:9|kpp"
          @input="slotProps.input"
        />
      </template>
      <template #ogrn="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="required|digits:13|ogrn"
          @input="slotProps.input"
        />
      </template>
      <template #oktmo="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="oktmo"
          @input="slotProps.input"
        />
      </template>
      <template #okpo="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="okpo"
          @input="slotProps.input"
        />
      </template>
      <template #phoneNumber="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
          :required="slotProps.field.required"
          :rules-messages="{regex: 'Неправильно введён номер телефона'}"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="required"
          type="phoneNumber"
          @input="slotProps.input"
        />
      </template>
      <template #email="slotProps">
        <v-input
          :disabled="slotProps.disabled"
          :required="slotProps.field.required"
          :title="slotProps.field.name"
          :value="slotProps.value"
          rules="email"
          type="email"
          @input="slotProps.input"
        />
      </template>
    </add-directory-field-modal>
    <modal
      v-if="deletingSupplier"
      :body-style="{
      padding: '24px 0',
    }"
      :header-style="{
      display: 'flex',
      justifyContent: 'center',
    }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
      borderRadius: '16px',
      padding: '50px 100px',
    }"
      class="delete-modal">
      <template #header>
        <span class="delete-modal__title">Подтвердите удаление компании с платформы</span>
      </template>
      <template #body>
        <div style="text-align: center">
          <span>Компания {{ deletingSupplier.name }} будет удалена с платформы</span>
        </div>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-around;">
          <v-button
            variant="outlined-red"
            @click="onDeleteAccount"
          >
            Удалить
          </v-button>
          <v-button
            variant="outlined"
            @click="closeDeleteModal"
          >
            Отменить
          </v-button>
        </div>
      </template>
    </modal>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Modal, Page, VButton, VInput, VTableNew } from '@/components';
import { convertToLabelValueList } from '@/utils/list';
import generateXml from 'lib/storage/connection-application/data';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from '../../components/AddDirectoryFieldModal/consts.js';
import { supplierChangingOrder } from '../../utils/xml/supplierChangingOrder.js';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'CabinetContractors',
  components: {
    Modal,
    VButton,
    VTableNew,
    VInput,
    Page,
    AddDirectoryFieldModal,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      suppliers: [],
      deletingSupplier: null,
      xml: null,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Полное название организации',
            key: 'fullName',
            thStyle: {
              minWidth: '100px',
            },
            tdStyle: {
              width: '100px',
            },
          },
          {
            label: 'Краткое наименование организации',
            key: 'shortName',
            thStyle: {
              maxWidth: '200px',
              minWidth: '200px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'ИНН',
            key: 'inn',
            thStyle: {
              maxWidth: '85px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '85px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'КПП',
            key: 'kpp',
            thStyle: {
              maxWidth: '85px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '85px',
            },
            sort: {},
          },
          {
            label: 'Участник Платформы, (Да/Нет)',
            key: 'onPlatformText',
            thStyle: {
              minWidth: '90px',
            },
            tdStyle: {
              maxWidth: '80px',
            },
            sort: {},
            filter: { type: 'select', values: convertToLabelValueList(['Да', 'Нет']) },
          },
          {
            label: 'Юридический адрес',
            key: 'legalAddress',
            thStyle: {
              minWidth: '100px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
          },
          {
            label: 'Фактический адрес',
            key: 'actualAddress',
            thStyle: {
              minWidth: '100px',
            },
            tdStyle: {
              width: '100px',
            },
          },
          {
            label: 'Телефон',
            key: 'phoneNumber',
            thStyle: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '200px',
            },
          },
          {
            label: 'E-Mail',
            key: 'email',
            thStyle: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '200px',
            },
          },
          {
            label: 'Сайт',
            key: 'website',
            thStyle: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '200px',
            },
          },
          {
            label: 'ФИО генерального директора',
            thStyle: {
              minWidth: '100px',
            },
            key: 'directorFullName',
          },
          {
            label: 'БИК',
            key: 'bik',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Расчётный счёт',
            key: 'paymentAccount',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Наименование банка',
            key: 'bankName',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Корреспондентский счёт',
            key: 'correspondentAccount',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'ОГРН',
            key: 'ogrn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              width: '105px',
            },
          },
          {
            label: 'ОКТМО',
            key: 'oktmo',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'ОКПО',
            key: 'okpo',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'ОКВЭД',
            key: 'okved',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            key: 'crossDelete',
            type: 'button',
            customCheck: (data) => !data.onPlatform || this.getUserType !== 'Platform',
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
            customCheck: () => this.getUserType !== 'Platform',
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedSuppliers() {
      if (this.suppliers.length === 0) {
        return [];
      }
      console.log('it', this.suppliers);
      return this.suppliers.ODK.map((it) => ({
        id: it.id,
        // -- Common fields begin
        inn: it.inn,
        kpp: it.kpp,
        onPlatform: it.onPlatform,
        onPlatformText: this.formatPlatformMembership(it.onPlatform),
        // -- Common fields end
        // -- Details of the organization begin
        fullName: it.name,
        shortName: it.shortName,
        legalAddress: it.address,
        actualAddress: it.postAddress,
        phoneNumber: it.phoneNumber,
        departmentName: it.departmentName,
        area: it.area,
        district: it.district,
        email: it.email,
        website: it.website,
        region: it.regions[0],
        directorFullName: it.generalDirectorFullName,
        directorFamilyName: it.generalDirectorFullName.split(' ')[0],
        directorFirstName: it.generalDirectorFullName.split(' ')[1],
        directorPatronymic: it.generalDirectorFullName.split(' ')[2] ?? '',
        directorFamilyNameGenitive: it.generalDirectorFullNameGenitive.split(' ')[0],
        directorFirstNameGenitive: it.generalDirectorFullNameGenitive.split(' ')[1],
        directorPatronymicGenitive: it.generalDirectorFullNameGenitive.split(' ')[2] ?? '',
        // -- Details of the organization end
        // -- Bank requisites begin
        bik: it.bankRequisites[0]?.bik,
        paymentAccount: it.bankRequisites[0]?.settlementAccount,
        bankName: it.bankRequisites[0]?.bankName,
        correspondentAccount: it.bankRequisites[0]?.correspondentAccount,
        // -- Bank requisites end
        // -- Codes begin
        ogrn: it.ogrn,
        oktmo: it.oktmo,
        okpo: it.okpo,
        okved: it.okved,
        // -- Codes end
        accountId: it.account?.id,
        crossDelete: 'Удалить аккаунт',
      }));

    },
    actionBlockItems() {
      if (this.getUserType !== 'Platform') return [];
      return [
        {
          id: 1,
          title: 'Добавить компанию',
          method: this.onCreate,
        },
      ];
    },
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'fullName',
          name: 'Название компании',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'shortName',
          name: 'Название компании (сокращенное)',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'inn',
          name: 'ИНН компании',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'kpp',
          name: 'КПП компании',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'ogrn',
          name: 'ОГРН компании',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'oktmo',
          name: 'ОКТМО компании',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'okpo',
          name: 'ОКПО компании',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'okved',
          name: 'ОКВЭД компании',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorFamilyName',
          name: 'Фамилия генерального директора',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorFirstName',
          name: 'Имя генерального директора',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorPatronymic',
          name: 'Отчество генерального директора',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorFamilyNameGenitive',
          name: 'Фамилия генерального директора (в родительном падеже)',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorFirstNameGenitive',
          name: 'Имя генерального директора (в родительном падеже)',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'directorPatronymicGenitive',
          name: 'Отчество генерального директора (в родительном падеже)',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'departmentName',
          name: 'Название отделения',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'phoneNumber',
          name: 'Номер телефона поставщика',
          required: true,
          customValidator: (value) => {
            const pattern = /^(\+7) \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
            return pattern.test(value);
          },
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'email',
          name: 'Адрес электронной почты поставщика',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'website',
          name: 'Адрес сайта поставщика',
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'region',
          options: this.regionList,
          name: 'Регион',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'district',
          name: 'Район',
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'area',
          name: 'Область',
        },
      ];
    },
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dataLists', ['regionList']),
  },
  methods: {
    cellClickHandler(cell) {
      console.log(cell);
      switch (cell.key) {
        case 'crossDelete': {
          this.deletingSupplier = cell.row;
        }
          break;
        case 'followLink': {
          this.$router.push({
            name: 'cabinet.contractors.company-card',
            params: {
              inn: cell.row.inn,
            },
          });
        }
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    action() {
      this.generateXml();
      this.sendXml();
    },
    generateXml() {
      const data = this.modalAction === AddDirectoryFieldModalActions.DELETE ? { id: this.modalValue.id } : this.modalValue;
      this.xml = supplierChangingOrder(this.modalAction, 'OdkSupplier', data);
    },
    async sendXml() {
      this.loading = true;
      try {
        await this.documentUpload({ xml: this.xml });
        this.closeModal();
        await this.loadData();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.setNotification({ message: errorData.message });
      } finally {
        this.loading = false;
      }
    },
    onDeleteAccount() {
      this.deleteFromPlatform(this.deletingSupplier.accountId);
    },
    closeDeleteModal() {
      this.deletingSupplier = null;
    },
    formatPlatformMembership(value) {
      if (value) {
        return 'Да';
      }

      return 'Нет';
    },
    async loadData() {
      try {
        this.loading = true;
        this.suppliers = await this.setSuppliers();
      } catch (error) {
        console.log('setSuppliers', error);
      } finally {
        this.loading = false;
      }
    },
    async deleteFromPlatform(accountId) {
      try {
        await this.documentUpload({ xml: generateXml.DeleteCompany(accountId) });
        this.closeDeleteModal();
        await this.loadData();
      } catch (e) {
        console.log('error when delete company', e);
      }
    },
    ...mapActions('dataLists', ['setSuppliers']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.delete-modal {
  &__sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 24px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  &__position-fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #71757A;
  }

  &__position {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  &__fullname {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
