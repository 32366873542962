<template>
  <div :class="$style.container">
    <table>
      <thead>
      <tr>
        <td>Документы</td>
        <td>Номер документа</td>
        <td>Дата</td>
        <td>Статус</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(document, index) in documents" :key="index" @click="onShownTaskDetails(document, index)">
        <td>
          <p>{{ document.title }}</p>
          <p v-if="document.subtitle">{{ formatSubtitle(document.subtitle) }}</p>
          <horizontal-progress-bar v-if="isWaitingDownload.index === index && isWaitingDownload.isShow" />
        </td>
        <td>{{ document.documentNumber }}</td>
        <td>{{ formatDate(document.formattedDate) }}</td>
        <td :class="[document.status === 'CANCELLED' ? $style.red : $style.green]">
          {{ getStatus(document.status) }}
        </td>
        <td>
          <more-vert-icon />
        </td>
      </tr>
      </tbody>
    </table>

    <template v-if="typename === 'ElectricityTariffsDocumentView'">
      <TariffsCard v-model="visible" :title="title" :tariff-id="type" @tariff-changing="tariffChanging" />
      <tariffs-changing v-model="displayTariffsChanging" :service-location="tariffsChangingServiceLocation" />
    </template>
  </div>
</template>

<script>
import MoreVertIcon from 'atoms/icons/basic/MoreVert.vue';
import TariffsCard from 'organisms/tariffs/TariffsCard';
import { mapGetters } from 'vuex';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { getDocument } from 'lib/utils/downloadDocuments';
import { showPdf } from 'lib/utils/pdf';

export default {
  name: 'VerticalCard',
  components: {
    MoreVertIcon,
    TariffsCard,
    TariffsChanging: () => import('organisms/tariffs/TariffsChanging.vue'),
    HorizontalProgressBar,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      typename: null,
      type: null,
      isLoading: true,
      dataUrl: undefined,
      title: '',
      subtitle: '',
      pdfUrl: null,
      visible: false,
      displayTariffsChanging: false,
      tariffsChangingServiceLocation: {},
      isWaitingDownload: {
        index: null,
        isShow: false,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['getToken']),
  },
  methods: {
    async onShownTaskDetails(document, index) {
      if (document.__typename === 'ElectricityTariffsDocumentView') {
        this.type = document.electricityTariffsOrderId;
        this.visible = true;
      } else {
        if (!document.documentId) {
          return;
        }
        this.isWaitingDownload.index = index;
        this.isWaitingDownload.isShow = true;
        try {
          const doc = await getDocument(document.documentId, this.showWarning);
          showPdf(doc);
        } catch (e) {
          alert('Ошибка загрузки файла');
        } finally {
          this.isWaitingDownload.index = null;
          this.isWaitingDownload.isShow = false;
        }
      }
    },
    formatSubtitle(text) {
      text = text.split('от')[0];

      return text;
    },
    formatDate(date) {
      if (date === '') {
        return '';
      }
        date = date.replace(/-/gi, '.').split('.').reverse()
.join('.');


      return date;
    },
    getStatus(statusId) {
      switch (statusId) {
        case 'ACTING': {
          return 'ДЕЙСТВУЮЩИЙ';
        }
        case 'COMPLETED': {
          return 'ЗАКЛЮЧЕНА';
        }
        case 'FORMED': {
          return 'СФОРМИРОВАН';
        }
        case 'CANCELLED': {
          return 'АННУЛИРОВАН';
        }
        case 'DRAFT': {
          return 'СОЗДАНА';
        }
      }
      return statusId;
    },
    async tariffChanging(serviceLocation) {
      this.tariffsChangingServiceLocation = serviceLocation;
      this.displayTariffsChanging = true;
    },
    showWarning() {
      this.vueShowNotification(
          'Предупреждение',
          '<p>Формирование документа выполняется в фоновом режиме и может занимать до 15 минут. Просим подождать.</p>',
      );
    },
  },
};
</script>

<style lang="sass" module>
.container
  max-width: 85%
  display: flex

  .green
    color: #54B687

  .red
    color: #EB5757

  table
    flex-grow: 1

    thead
      +main-menu-text

      tr
        width: 100%
        border-bottom: 1px solid #E9EBED
        td
          padding: 8px 16px
          color: #979CA2


    tbody
      tr
        cursor: pointer
        border-bottom: 1px solid #E9EBED
        td
          padding: 8px 16px
          +main-menu-text
          p
            +main-menu-text

</style>
