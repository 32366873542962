var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('label',{class:_vm.$style.label,style:(_vm.labelStyle),attrs:{"for":_vm.inputId}},[_vm._v(_vm._s(_vm.$props.label))]),(_vm.note)?_c('span',[_vm._v(_vm._s(_vm.note))]):_vm._e(),_c('masked-input',{class:{
      [_vm.$style.field]: true,
      [_vm.$style[_vm.type]]: true,
      [_vm.$style.invalid]: _vm.isInvalid,
      [_vm.$style.disabled]: _vm.$props.disabled,
    },attrs:{"id":_vm.inputId,"type":_vm.$props.type,"mask":_vm.mask,"placeholder":_vm.$props.placeholder,"role":_vm.role,"disabled":_vm.$props.nativeDisabled,"autocomplete":"off"},on:{"blur":_vm.handleValidate,"keyup":function($event){return _vm.$emit('keyup', $event.target.value)}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}),(_vm.isInvalid)?_c('p',{class:{
      [_vm.$style.errorText]: !_vm.$props.localError,
      [_vm.$style.localErrorText]: _vm.$props.localError,
    }},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }