<template>
  <page title="Акты оказания услуг по передаче электрической энергии">
    <modal-progress-bar
        :is-complete="isProcessComplete"
        :is-modal-visible="isModalVisible"
        @close="isModalVisible = false"
    />
    <v-table-new
        v-model="transformedServiceActs"
        :action-block-items="actionBlockItems"
        :loading="loading"
        :template="template"
        excel-report-file-name="Акты оказания услуг по передаче электрической энергии"
        @on-cell-click="handleOnCellClick"
    />
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ModalProgressBar, Page, VTableNew } from '@/components';
import {
  completedByStatus,
  formatContractStatus,
  formatServiceKind,
  getContractStatusList,
} from 'lib/utils/formatXmlType';
import { convertDbDateTimeFormat, convertDbFormat, getMonthString } from 'lib/utils/date';
import { downloadXml, downloadXmlWin1251 } from 'lib/utils/pdf';
import { delay } from '@/utils/delay';
import formatting from 'lib/utils/formatting';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'CabinetServiceActs',
  components: {
    VTableNew,
    ModalProgressBar,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      serviceActs: [],
      loading: false,
      dataUrl: undefined,
      title: '',
      pdfUrl: null,
      isModalVisible: false,
      isProcessComplete: false,
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'getUser']),
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '40px',
            },
          },
          {
            label: 'Название компании',
            key: 'companyName',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Сумма к оплате, руб',
            type: 'number',
            thStyle: {
              minWidth: '100px',
            },
            key: 'totalCost',
            sort: { type: 'number' },
            filter: { type: 'number' },
          },
          {
            label: 'Период',
            key: 'monthYear',
            thStyle: {
              whiteSpace: 'nowrap',
              maxWidth: '160px',
            },
            tdStyle: {
              maxWidth: '160px',
            },
            sort: {},
            filter: { type: 'date', format: 'MMMM YYYY', selectType: 'month' },
          },
          {
            label: 'Номер договора',
            key: 'contractNumber',
            sort: {},
            filter: {},
          },
          {
            label: 'Дата договора',
            key: 'contractDate',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Исполнитель 1',
            key: 'performerFirstName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Исполнитель 2',
            key: 'performerSecondName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Подписывающие лица',
            key: 'signedBy',
            sort: {},
            thStyle: {
              minWidth: '113px',
            },
            filter: {},
          },
          {
            label: 'Вид',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            key: 'kind',
          },
          {
            label: 'Статус со стороны Исполнитель-1',
            key: 'performerFirstStatus',
            sort: {},
            thStyle: {
              minWidth: '101px',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Статус со стороны Исполнитель-2',
            key: 'performerSecondStatus',
            sort: {},
            thStyle: {
              minWidth: '101px',
            },
            filter: { type: 'select', values: getContractStatusList() },
          },
          {
            label: 'Дата и время изменения статуса документа',
            key: 'statusDateChangedAt',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
          },
          {
            label: 'Замечания к документу',
            key: 'rejectedReason',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Сформировано автоматически',
            key: 'autoGenerated',
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Счёт-фактура',
            key: 'invoice',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    transformedServiceActs() {
      if (this.serviceActs.length === 0) {
        return [];
      }

      return this.serviceActs.map((it) => ({
        ...it,
        companyName: it.performerSecond.name,
        performerFirstName: it.performerFirst.name,
        performerSecondName: it.performerSecond.name,
        monthYear: `${getMonthString(it.month)} ${it.year}`,
        sidesNames: this.formatSidesNames([it.performerFirst.name, it.performerSecond.name]),
        signedBy: this.formatSidesNames([it.performerFirst.name, it.performerSecond.name].filter((i) => i.status === 'SIGNED').map((i) => i.name)),
        kind: formatServiceKind(it.kind),
        open: '',
        invoiceDocumentId: it.invoiceDocumentId,
        documentId: it.documentId,
        rejectedReason: it.rejectedReason,
        performerFirstStatus: this.formatContractStatus(it.performerFirst.status),
        performerSecondStatus: this.formatContractStatus(it.performerSecond.status),
        statusDateChangedAt: convertDbDateTimeFormat(it.updatedAt),
        title: it.title,
        actVerificationDocumentId: it.actVerification?.documentId,
        serviceActId: it.id,
        totalCost: formatting.numberFormatted(it.totalCost),
        contractDate: convertDbFormat(it.contractDate),
        invoice: { documentId: it.invoiceDocumentId, name: 'Счёт-фактура' },
        document: { documentId: it.documentId, name: it.title },
        act: this.checkEmptyAct(it.actVerification),
        autoGenerated: it.autoGenerated ? 'Сформировано автоматически' : '',
      }));

    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать акты',
          method: goToPageByRoute('service/generate'),
        },
      ];
    },
  },
  methods: {
    checkEmptyAct(act) {
      if (act) {
        return { documentId: act.documentId, name: 'Акт верификации' };
      }
      return null;

    },
    formatContractStatus(data) {
      return formatContractStatus(data);
    },
    formatSidesNames(sidesNames) {
      if (!sidesNames) {
        return null;
      }

      return sidesNames.join(', ');
    },
    async handleOnCellClick(cell) {
      switch (cell.key) {
        case 'invoice': {
          return this.showPdf(cell.row.invoiceDocumentId);
        }
        case 'invoiceXmlDownload': {
          return this.xmlDownload(cell.row.invoiceDocumentId, `Счёт-фактура по документу ${cell.row.title}`, true);
        }
        case 'invoicePdfDownload': {
          return this.pdfDownload(cell.row.invoiceDocumentId, `Счёт-фактура по документу ${cell.row.title}`);
        }
        case 'followLink': {
          const currentSideStatus = [cell.row.performerFirst, cell.row.performerSecond].find((it) => it.accountId === this.getAccount.id).status;
          if (currentSideStatus === 'WAITING_VERIFICATION_ACT') {
            const domainDocument = await this.getDomainDocument(cell.row.serviceActId);
            return await this.$router.push({
              name: 'cabinet.document-scan-upload',
              params: {
                ...domainDocument,
                documentXmlType: domainDocument.xmlDocumentType,
                documentCreatedAt: convertDbDateTimeFormat(domainDocument.createdAt),
                from: 'service',
                fromTitle: 'Акты оказания услуг по передаче электрической энергии',
              },
            });
          }
          return this.link(cell.row);

        }
      }
    },
    link(task) {
      const currentSideStatus = [task.performerFirst, task.performerSecond].find((it) => it.accountId === this.getAccount.id).status;
      let workflowType;
      let title;
      switch (currentSideStatus) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        case 'REJECTED': {
          if (this.getUser.id !== task.initiatorUserId) {
            workflowType = 'documentSigning';
            break;
          }
          workflowType = 'documentReload';
          title = `Исправьте ${task.title}`;
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: task.serviceActId,
          from: 'service',
          fromTitle: 'Акты оказания услуг по передаче электрической энергии',
          to: workflowType,
          title: title,
        },
      });
    },
    completedByStatus(data) {
      return completedByStatus(data);
    },
    async loadData() {
      try {
        this.loading = true;
        this.serviceActs = await this.loadServiceActs();
      } catch (error) {
        console.log('serviceActs', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async xmlDownload(documentId, name, isWin1251) {
      try {
        this.isModalVisible = true;
        const response = await this.loadDocument(documentId);
        this.isProcessComplete = true;
        await delay(3250);
        if (isWin1251) downloadXmlWin1251(response.xml, name);
        else downloadXml(response.xml, name);
      } catch (e) {
        console.log(e.message);
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    ...mapActions('document', ['getDomainDocument', 'loadDocument']),
    ...mapActions('cabinet', ['loadServiceActs']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

