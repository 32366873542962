<template>
  <div class="charter-or-power-of-attorney">
    <span :style="labelStyle" class="charter-or-power-of-attorney__title">
      Основание, по которому осуществляет деятельность
    </span>

    <div v-if="mode === 'select'">

      <div v-if="selectModeType === 'charter'">
        <select
          v-model="charterShow"
          :disabled="true"
          :style="selectStyle"
          class="charter-or-power-of-attorney__select"
        >
          <option
            v-for="(charter, index) in ['Устав']"
            :key="index"
            :value="charter"
          >
            {{ charter }}
          </option>
        </select>
      </div>
      <div v-if="selectModeType === 'powerOfAttorney'">
        <select
          v-model="powerOfAttorney.id"
          :disabled="!editable"
          :style="selectStyle"
          class="charter-or-power-of-attorney__select"
          @change="changeHandler(powerOfAttorney.id)"
        >
          <option
            v-for="(powerOfAttorney, index) in powerOfAttorneyList"
            :key="index"
            :value="powerOfAttorney.id"
          >
            {{ powerOfAttorney.title }}
          </option>
        </select>
      </div>

    </div>

    <div v-if="mode === 'input'">

      <div>
        <select
          v-model="inputModeReaseon.reasonDocument"
          :class="{'charter-or-power-of-attorney__select_error': error.reasonDocument }"
          :disabled="!editable"
          class="charter-or-power-of-attorney__select"
        >
          <option
            v-for="(reason, index) in reasons"
            :key="index"
            :value="reason"
          >
            {{ reason }}
          </option>
        </select>
      </div>

      <div
        v-if="inputModeReaseon.reasonDocument === 'Доверенность'"
        class="charter-or-power-of-attorney__inputs"
      >
        <v-input
          v-model="inputModeReaseon.number"
          :disabled="!editable"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          rules="required"
          title="Номер документа *"
        />
        <v-date-picker
          v-model="inputModeReaseon.date"
          :disabled="!editable"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          rules="required"
          title="Дата документа *"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { VDatePicker, VInput } from '@/components';

export default {
  components: {
    VInput,
    VDatePicker,
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        reasonDocument: false,
        number: false,
        date: false,
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      // Два режима работы:
      // 1. Отображение существующего документа на основании которого может происходить подписание
      // 2. Выбор между уставом и вводом данных об организации
      validator: (mode) => ['select', 'input'].includes(mode),
      required: true,
    },
    selectModeType: {
      type: String,
      validator: (selectModeType) => ['charter', 'powerOfAttorney'].includes(selectModeType),
      required: false,
    },
    selectStyle: {
      type: Object,
      default: () => ({}),
    },
    labelStyle: {
      type: Object,
      default: () => ({}),
    },
    powerOfAttorneyList: {
      type: Array,
      default: () => [],
    },
    reason: {
      type: Object,
      default: () => ({
        reason: {
          reasonDocument: false,
          number: false,
          date: false,
        },
      }),
    },
    selectInitReasonId: {
      type: String,
      required: false,
    },
    inputInitReason: {
      type: Object,
      required: false,
    },
    notEmitInputReason: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    if (this.inputInitReason?.reasonDocument?.length > 0) {
      this.inputModeReaseon = { ...this.inputInitReason };
    }
  },
  data() {
    return {
      inputModeReaseon: {
        reasonDocument: '',
        number: null,
        date: null,
      },
      reasons: ['Устав', 'Доверенность'],
      powerOfAttorney: {
        id: null,
      },
      // Для отодбражения устава в селекторе
      charterShow: 'Устав',
    };
  },
  watch: {
    selectModeType: {
      immediate: true,
      handler(updatedMode) {
        if (updatedMode === 'charter') {
          console.log('charter');
          this.inputModeReaseon = {
            reasonDocument: 'Устав',
            number: null,
            date: null,
          };
          this.powerOfAttorney = {
            id: null,
          };
        } else {
          if (this.selectInitReasonId) {
            this.powerOfAttorney = { ...this.powerOfAttorneyList.find((poa) => poa.id === this.selectInitReasonId) };
            this.$emit('selectPowerOfAttorney', { ...this.powerOfAttorney });
          }
          if (!this.powerOfAttorney?.id) {
            this.powerOfAttorney = { ...this.powerOfAttorneyList[0] };
            this.$emit('selectPowerOfAttorney', { ...this.powerOfAttorney });
          }
        }
      },
    },
    inputModeReaseon: {
      deep: true,
      handler(updatedInputModeReaseon) {
        console.log('updated reason', updatedInputModeReaseon);
        this.$emit('selectReason', { ...updatedInputModeReaseon });
      },
    },
  },
  methods: {
    changeHandler(selectedPowerOfAttorneyId) {
      this.powerOfAttorney = {
        ...this.powerOfAttorneyList.find(
          (powerOfAttorney) => powerOfAttorney.id === selectedPowerOfAttorneyId,
        ),
      };
      this.$emit('selectPowerOfAttorney', { ...this.powerOfAttorney });
    },
  },
};
</script>

<style lang="scss" scoped>
.charter-or-power-of-attorney {
  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71757a;
  }

  &__inputs {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 16px 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
  }

  &__select {
    height: 40px;
    width: 100%;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 16px;
    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &_error {
      border-color: #EB5757;
    }

    &:disabled {
      color: #000000;
      opacity: 1;
    }
  }
}
</style>
