import { apolloClient } from '@/api/graphql';
import { CREATE_PAYMENT } from '@/api/graphql/mutations';
import { GET_PAYMENT_INFO } from '@/api/graphql/queries';

async function createPayment({ rootState, commit }) {
  try {
    const data = await apolloClient.mutate({
      mutation: CREATE_PAYMENT,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log(data);
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('createPayment::catch', error);
    throw error;
  }
}

async function paymentInfo({ rootState, commit }) {
  try {
    const { data: { invoices } } = await apolloClient.query({
      query: GET_PAYMENT_INFO,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('paymentInfo', invoices);
    return invoices;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error.message);
    throw error;
  }
}

export default {
  createPayment,
  paymentInfo,
};
