<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">{{ getPatternName }}</p>
    <p :class="$style.stepTitle">Внесение данных из приложений к {{ contractType }}</p>
    <p :class="$style.smallTitle">Для загрузки приложений к {{ contractType }} скачайте шаблоны документов в формате MS
      Excel</p>

    <div v-for="(attachment, index) in attachments" :key="index" :class="$style.attachment">
      <a :class="$style.fileAttachment" :download="attachment.attachmentName"
         :href="`${attachmentPath}${attachment.templateName}.xlsx`">
        <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
        <span>Шаблон {{ attachment.attachmentName }}</span>
      </a>
      <a :class="$style.downloadIcon" :download="attachment.attachmentName"
         :href="`${attachmentPath}${attachment.templateName}.xlsx`"
         title="скачать">
        <img alt="скачать" height="24" src="~@/assets/icons/file/download.svg" width="24"/>
      </a>
    </div>

    <p :class="$style.stepTitle">Загрузите заполненный документ XLSX на Платформу </p>

    <c-uploading
      v-model="filledAttachment1"
      :class="$style.filledDocuments"
      :error="{ error: getErrors.filledAttachment1.isError}"
      accept=".xlsx, .xls"
      accept-title="XLSX, XLS или перетащите его в эту область."
      role="filledAttachment1"
    />

    <div v-if="getContract.attachments.length > 0">
      <div v-for="(attachment, index) in nonDepartmentApplications" :key="index" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="attachment.file.name"
           :href="fileUrl(attachment.file)">
          <img alt="" height="40" src="../../../../../assets/icons/file/filled_template.svg" width="40"/>
          <span>{{ attachment.file.name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc(attachment)">
          <img alt="" height="24" src="../../../../../assets/icons/file/delete.svg" width="24"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import { EVENT_BUS } from '@/eventBus';
import { excelParseWithWorker } from 'utils/excel';
import { nanoid } from 'nanoid';
import { mapActions, mapGetters } from 'vuex';
import { normalizeDiacritics } from 'utils/string/normalize';
import {
  esoOek1AdditionPattern,
  esoOek2AdditionPattern,
} from 'views/private/UploadDocument/patterns/EsoOek_ConsumerOekPatterns';

const attachmentNameWithoutExtension = {
  'eso-oek': {
    attachment1NameWithoutExtension: 'Приложение_1-Перечень точек поставки электрической энергии и мощности',
    attachment2NameWithoutExtension: 'Приложение_2-Существенные условия договора по каждому Потребителю',
  },
  'oek-consumer': {
    attachment1NameWithoutExtension: 'Приложение_1-Перечень точек поставки электрической энергии и мощности Потребителю',
    attachment2NameWithoutExtension: 'Приложение_2-Существенные условия договора по точкам поставки Потребителя',
  },
};

export default {
  name: 'EsoOekConsumerOek',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  props: {
    templatePath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filledAttachment1: [],
      isWaitingSubmit: false,
      departmentId: -1,
      departmentList: [],

      attachments: [
        {
          attachmentNameWithoutExtension: attachmentNameWithoutExtension[this.templatePath].attachment1NameWithoutExtension,
          attachmentName: `${attachmentNameWithoutExtension[this.templatePath].attachment1NameWithoutExtension}.xlsx`,
          templateName: 'attachment_1',
          pattern: esoOek1AdditionPattern,
          name: 'ElectricitySupplyPointsEsoAndConsumerAddition',
          number: 1,
        },
        {
          attachmentNameWithoutExtension: attachmentNameWithoutExtension[this.templatePath].attachment2NameWithoutExtension,
          attachmentName: `${attachmentNameWithoutExtension[this.templatePath].attachment2NameWithoutExtension}.xlsx`,
          templateName: 'attachment_2',
          pattern: esoOek2AdditionPattern,
          name: 'ContractsEsoAndConsumerAddition',
          number: 2,
        },
      ],
    };
  },
  computed: {
    attachmentPath() {
      if (this.getContract.action === 'upload') {
        return `/xlsx/contract-patterns/existing/${this.templatePath}/`;
      }

      return `/xlsx/contract-patterns/new/${this.templatePath}/`;
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договору';
      }
        return 'доп.соглашению';

    },
    nonDepartmentApplications() {
      return this.getContract.attachments.filter((attach) => !attach.departmentData);
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getErrors', 'getPatternName']),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }

        this.isWaitingSubmit = true;

        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          const attachment = this.attachments.filter((attach) => normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attach.attachmentNameWithoutExtension)) !== -1);

          if (attachment.length === 0) {
            incorrectFiles.push(file.name);
          } else {
            const res = await excelParseWithWorker(
              file.code,
              { ...attachment[0].pattern },
              taskId,
            );
            await this.setAttachments({
              attachment: {
                name: attachment[0].name,
                value: res.data,
                errors: res.errors,
                file,
                number: attachment[0].number,
              },
            });
          }
        }

        if (incorrectFiles.length > 0) {
          let attachment1Name = '';

          this.attachments.forEach((item) => {
            attachment1Name += `${item.attachmentNameWithoutExtension}<br/>`;
          });

          this.onShownNotification(incorrectFiles, attachment1Name);
        }
        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    fileUrl(file) {
      return getFileUrl(file);
    },
    onShownNotification(files, attachments) {
      EVENT_BUS.$emit('NOTIFICATION_LISTENER', {
        data: {
          title: '',
          message: `<p><b>Имя файла:</b></p><p>${files}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${attachments}</p>`,
        },
        type: 'ALERT',
        width: 700,
      });
    },
    deleteDoc(attachment) {
      this.deleteAttachment({ attachment });
    },
    ...mapActions('electricityTransmissionContract', ['setAttachments', 'deleteAttachment']),
    ...mapActions('dataLists', ['mesDepartments']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px
    color: #4C4E51

    span
      margin-right: 12px
      color: #979CA2

  .stepHeader
    +base-title
    margin-top: 58px
    padding: 8px
    background-color: #9e9fa2
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    margin-bottom: 16px
    border-color: #C1C4C7

.attachment, .fileArchive, .attachmentReport
  +base-subtitle

.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

  img
    margin-right: 8px

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757 !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none

.inputField
  +input-text
  font-family: 'Roboto Condensed', sans-serif
  padding: 4px 16px
  color: gray
</style>
