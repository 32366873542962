import { convertDbDateTimeFormat, formatDateToCustomDateFormat } from 'lib/utils/date';
import Vue from 'vue';

function applicationProps(workflowId, parsedData, workflowType, userType) {
  return {
    address: parsedData.address,
    documentId: parsedData.documentId,
    date: formatDateToCustomDateFormat(parsedData.date, 'DD.MM.YYYY'),
    reviewDocumentId: parsedData.reviewDocumentId,
    offerDocumentId: parsedData.offerDocumentId,
    accountId: parsedData.accountId,
    retailReviewDocumentId: parsedData.retailReviewDocumentId,
    retailOfferDocumentId: parsedData.retailOfferDocumentId,
    utilityOfferDocumentId: parsedData.utilityOfferDocumentId,
    additionalDocumentId: parsedData.additionalDocumentId,
    connectionCertificateId: parsedData.connectionCertificateId,
    technicalConditionsActDocumentId: parsedData.technicalConditionsActDocumentId,
    admissionCertificateId: parsedData.admissionCertificateId,
    invoiceId: parsedData.invoiceId,
    applicationId: parsedData.applicationId,
    stage: parsedData.stage,
    completed: isCompletedByStage(workflowType, userType, parsedData.stage, parsedData),
    contractType: parsedData.contractType,
    userId: parsedData.userId,
    roleId: parsedData.roleId,
    ...commonProps(parsedData),
  };
}

function isCompletedByStage(workflowType, userType, stage, parsedData) {
  if (userType === 'Customer' && (workflowType === 'individualCustomerConnection' || workflowType === 'individualCustomerConnectionRetail')) {
    switch (stage) {
      case 'NOT_ON_PLATFORM': {
        return true;
      }
      case 'ADMISSION_CERTIFICATE_REQUIRED': {
        return true;
      }
      case 'ADMISSION_CERTIFICATE_SUBMITTED': {
        return false;
      }
      case 'CONNECTION_CERTIFICATE_REQUIRED': {
        return true;
      }
      case 'USAGE_POINT_LOSSES_DOCUMENT_REQUIRED': {
        return true;
      }
      case 'SUBMITTED': {
        return true;
      }
      case 'APPROVED': {
        return true;
      }
      case 'WAITING_FOR_APPROVING': {
        return true;
      }
      case 'CONNECTION_INVOICE_PAID': {
        return true;
      }
      case 'CONNECTION_INVOICE_CREATED': {
        return false;
      }
      case 'CONNECTED': {
        return true;
      }
      case 'TECHNICAL_CONDITIONS_ACT_APPROVED': {
        return true;
      }
      /* case 'CONNECTION_DOCUMENTS_SUBMITTED': {
        return false;
      }*/
      case 'CONNECTION_DOCUMENTS_SUBMITTED_WITH_INVOICE': {
        return false;
      }
      case 'CONNECTION_DOCUMENTS_SUBMITTED_AND_PAID': {
        return false;
      }
      case 'TECHNICAL_CONDITIONS_ACT_REJECTED': {
        return true;
      }
      case 'TECHNICAL_CONDITIONS_ACT_SUBMITTED': {
        return false;
      }
      case 'CORRECTION': {
        return false;
      }
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_REJECTED': {
        return false;
      }
      case 'RETAIL_OFFER_DOCUMENTS_SUBMITTED': {
        return false;
      }
      case 'RETAIL_OFFER_DOCUMENTS_APPROVED': {
        return true;
      }
      case 'CONNECTION_DOCUMENTS_EXECUTION_COMPLETED': {
        return true;
      }
    }
  }
  if (workflowType === 'utmInstallation') {
    switch (stage) {
      case 'SUBMITTED': {
        return false;
      }
      case 'APPROVED': {
        return true;
      }
    }
  }
  if ((userType === 'Supplier' || userType === 'Boiler') && parsedData.isInitiator && workflowType === 'individualCustomerConnection') {
    switch (stage) {
      case 'NOT_ON_PLATFORM':
      case 'SUBMITTED':
      case 'APPROVED':
      case 'CONNECTION_INVOICE_PAID':
      case 'ADMISSION_CERTIFICATE_REQUIRED':
      case 'CONNECTION_CERTIFICATE_REQUIRED':
      case 'CONNECTED':
      case 'USAGE_POINT_LOSSES_DOCUMENT_REQUIRED':
      case 'RETAIL_OFFER_DOCUMENTS_APPROVED':
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_APPROVED':
      case 'TECHNICAL_CONDITIONS_ACT_REJECTED':
      case 'TECHNICAL_CONDITIONS_ACT_APPROVED':
      case 'ADMISSION_CERTIFICATE_SUBMITTED':
      case 'CONNECTION_DOCUMENTS_EXECUTION_COMPLETED':
        return true;
      case 'CONNECTION_DOCUMENTS_SUBMITTED_WITH_INVOICE':
      case 'CONNECTION_DOCUMENTS_SUBMITTED_AND_PAID':
      // case 'CONNECTION_DOCUMENTS_SUBMITTED':
        return false;
      default:
        return false;
    }
  }
  if ((!parsedData.isInitiator && workflowType === 'individualCustomerConnection') &&
    (userType === 'Supplier' || userType === 'Boiler')) {
    switch (stage) {
      case 'SUBMITTED':
      case 'APPROVED':
      case 'CONNECTION_DOCUMENTS_REJECTED':
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_REJECTED':
      case 'CONNECTION_INVOICE_PAID':
      case 'ADMISSION_CERTIFICATE_REQUIRED':
      case 'CONNECTION_CERTIFICATE_REQUIRED':
      case 'USAGE_POINT_LOSSES_DOCUMENT_REQUIRED':
      case 'TECHNICAL_CONDITIONS_ACT_REJECTED':
      case 'CONNECTION_DOCUMENTS_EXECUTION_COMPLETED':
      case 'CONNECTION_DOCUMENTS_APPROVED':
      case 'RETAIL_OFFER_DOCUMENTS_REJECTED': {
        return false;
      }
      case 'RETAIL_OFFER_DOCUMENTS_APPROVED':
      case 'CONNECTION_INVOICE_CREATED':
      case 'CONNECTION_DOCUMENTS_SUBMITTED_WITH_INVOICE':// case 'CONNECTION_DOCUMENTS_SUBMITTED':
      case 'CONNECTION_DOCUMENTS_SUBMITTED_AND_PAID':
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_SUBMITTED':
      case 'RETAIL_OFFER_DOCUMENTS_SUBMITTED':
      case 'CONNECTION_CERTIFICATE_SUBMITTED':
      case 'REJECTED':
      case 'AT_WORK':
      case 'REVIEW':
      case 'COMPLETED':
      case 'CORRECTION':
      case 'CANCELLED':
      case 'CONNECTED':
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_APPROVED':
      case 'TECHNICAL_CONDITIONS_ACT_SUBMITTED':
      case 'WAITING_FOR_APPROVING':
      case 'TECHNICAL_CONDITIONS_ACT_APPROVED':
      case 'ADMISSION_CERTIFICATE_SUBMITTED':
      case 'NOT_ON_PLATFORM': {
        return true;
      }
    }
  }
  if (userType === 'SUPPLIER_OF_LAST_RESORT' && workflowType === 'individualCustomerConnectionRetail') {
    switch (stage) {
      case 'SUBMITTED': {
        return false;
      }
      case 'APPROVED': {
        return false;
      }
      case 'WAITING_FOR_APPROVING': {
        return false;
      }
      case 'CONNECTION_INVOICE_PAID': {
        return false;
      }
      case 'CONNECTION_DOCUMENTS_REJECTED': {
        return false;
      }
      case 'RETAIL_OFFER_DOCUMENTS_REJECTED': {
        return false;
      }
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_SUBMITTED': {
        return false;
      }
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_APPROVED': {
        return false;
      }
      case 'ADDITIONAL_DOCUMENTS_FOR_RETAIL_REJECTED': {
        return true;
      }
      case 'CONNECTION_INVOICE_CREATED': {
        return true;
      }
      /* case 'CONNECTION_DOCUMENTS_SUBMITTED': {
        return true;
      }*/
      case 'CONNECTION_DOCUMENTS_SUBMITTED_WITH_INVOICE': {
        return true;
      }
      case 'CONNECTION_DOCUMENTS_SUBMITTED_AND_PAID': {
        return true;
      }
      case 'RETAIL_OFFER_DOCUMENTS_SUBMITTED': {
        return true;
      }
      case 'CONNECTION_CERTIFICATE_SUBMITTED': {
        return true;
      }
      case 'REJECTED': {
        return true;
      }
      case 'AT_WORK': {
        return true;
      }
      case 'REVIEW': {
        return true;
      }
      case 'COMPLETED': {
        return true;
      }
      case 'CORRECTION': {
        return true;
      }
      case 'CONNECTED': {
        return false;
      }
      case 'CANCELLED': {
        return true;
      }
      case 'RETAIL_OFFER_DOCUMENTS_APPROVED': {
        return true;
      }
      case 'CONNECTION_DOCUMENTS_APPROVED': {
        return true;
      }
      case 'NOT_ON_PLATFORM': {
        return true;
      }
    }
  }
}

function commonProps(parsedData) {
  return {
    dataDocumentType: parsedData.documentType,
    documentCreatedAt: parsedData.documentCreatedAt,
    createdAt: parsedData.createdAt,
    updatedAt: parsedData.updatedAt,
  };
}

function taskProps(parsedData) {
  return {
    title: parsedData.title,
    ...commonProps(parsedData),
  };
}

function connectionProps(workflowId, parsedData, workflowType, userType) {
  return {
    ...applicationProps(workflowId, parsedData, workflowType, userType),
    /*    stage: parsedData.stage,*/
  };
}

function microgenerationProps(workflowId, parsedData, workflowType, userType) {
  return {
    ...applicationProps(workflowId, parsedData, workflowType, userType),
    /*    stage: parsedData.stage,*/
  };
}

function utmInstallationProps(workflowId, parsedData, workflowType, userType) {
  return {
    ...applicationProps(workflowId, parsedData, workflowType, userType),
    utmDownloadUrl: parsedData.utmDownloadUrl,
  };
}

function tariffsUpdateProps(workflowId, parsedData, workflowType, userType) {
  return {
    ...applicationProps(workflowId, parsedData, workflowType, userType),
    tariffOrderId: parsedData.tariffOrderId,
    expiresAt: formatDateToCustomDateFormat(parsedData.expiresAt, 'DD.MM.YYYY'),
    year: parsedData.year,
    region: parsedData.region,
    approverUserId: parsedData.approverUserId,
  };
}

function tariffsChangingProps(workflowId, parsedData, workflowType, userType) {
  return {
    ...applicationProps(workflowId, parsedData, workflowType, userType),
    serviceLocationName: parsedData.serviceLocationName,
    expiresAt: formatDateToCustomDateFormat(parsedData.expiresAt, 'DD.MM.YYYY'),
    businessAccount: parsedData.businessAccount,
    serviceLocationId: parsedData.serviceLocationId,
  };
}

function transmissionContractProps(workflowId, parsedData) {
  return {
    createDate: parsedData.Date,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    contractTypeBoiler: { label: 'Гарантирующий поставщик – ТСО – Котлодержатель', value: 'TSO_MES_OEK' },
    ...commonProps(parsedData),
  };
}

function transmissionContractThreeBoilerProps(workflowId, parsedData) {
  return {
    createDate: parsedData.Date,
    stage: parsedData.stage,
    completed: parsedData.completed,
    contractTypeBoiler: { label: 'МЭС - РМР - ОЭК', value: 'OEK_MES_RMR' },
    ...commonProps(parsedData),
  };
}

function transmissionContractSigningProps(workflowId, parsedData) {
  return {
    actDate: convertDbDateTimeFormat(parsedData.documentCreatedAt),
    documentType: parsedData.documentName,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Подпишите загруженные документы',
    actionTitle: 'ПОДПИСАТЬ',
    actionButton: 'ПОДПИСАТЬ АКТ ВЕРИФИКАЦИИ',
    completedTitle: 'ПОДПИСАН',
    detailsTitle: 'ПОДПИСАНИЕ ЗАГРУЖЕННЫХ ДОКУМЕНТОВ',
    rejectEnabled: false,
    domainDocumentId: parsedData.domainDocumentId,
    documentId: parsedData.documentId,
    actVerificationDocumentId: parsedData.actVerificationDocumentId,
    contractShowUrl: parsedData.contractShowUrl,
    contractSaveUrl: parsedData.contractSaveUrl,
    actVerificationShowUrl: parsedData.actVerificationShowUrl,
    actVerificationSaveUrl: parsedData.actVerificationSaveUrl,
    ...taskProps(parsedData),
  };
}

function transmissionContractAgreementProps(workflowId, parsedData) {
  return {
    actDate: convertDbDateTimeFormat(parsedData.documentCreatedAt),
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Согласуйте загруженный договор',
    actionTitle: 'СОГЛАСОВАТЬ',
    actionButton: 'СОГЛАСОВАТЬ',
    completedTitle: 'СОГЛАСОВАН',
    detailsTitle: 'СОГЛАСОВАНИЕ ЗАГРУЖЕННОГО ДОГОВОРА',
    rejectEnabled: true,
    documentInitiatorAccountId: parsedData.documentInitiatorAccountId,
    documentType: parsedData.documentName,
    domainDocumentId: parsedData.domainDocumentId,
    documentId: parsedData.documentId,
    actVerificationDocumentId: parsedData.actVerificationDocumentId,
    enumDocumentType: parsedData.documentType,
    ...taskProps(parsedData),
  };
}

function transmissionContractUpdateInfoProps(workflowId, parsedData) {
  return {
    actDate: convertDbDateTimeFormat(parsedData.documentCreatedAt),
    documentType: parsedData.documentName,
    stage: parsedData.stage,
    completed: parsedData.completed,
    cardTitle: 'Введите информацию о загруженном документе',
    actionTitle: 'ОБНОВИТЬ',
    actionButton: 'ОБНОВИТЬ',
    completedTitle: 'ОБНОВЛЕНО',
    detailsTitle: 'ОБНОВЛЕНИЕ ИНФОРМАЦИИ О ДОКУМЕНТЕ',
    rejectEnabled: false,
    domainDocumentId: parsedData.domainDocumentId,
    documentId: parsedData.documentId,
    actVerificationDocumentId: parsedData.actVerificationDocumentId,
    contractShowUrl: parsedData.contractShowUrl,
    contractSaveUrl: parsedData.contractSaveUrl,
    actVerificationShowUrl: parsedData.actVerificationShowUrl,
    actVerificationSaveUrl: parsedData.actVerificationSaveUrl,
    ...taskProps(parsedData),
  };
}

function transmissionContractReloadProps(workflowId, parsedData) {
  return {
    actNumber: parsedData.documentNumber,
    actDate: parsedData.documentDate,
    reason: parsedData.reason,
    stage: parsedData.stage,
    title: parsedData.title,
    documentType: parsedData.documentType,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Исправьте отклоненные документы',
    actionTitle: 'ИСПРАВИТЬ',
    actionButton: 'ИСПРАВИТЬ',
    completedTitle: 'ИСПРАВЛЕН',
    detailsTitle: 'ИСПРАВЛЕНИЕ ОТКЛОНЁННЫХ ДОКУМЕНТОВ',
    rejectEnabled: false,
    contractId: parsedData.domainDocumentId,
    documentId: parsedData.documentId,
    domainDocumentId: parsedData.domainDocumentId,
    ...commonProps(parsedData),
  };
}


function balanceSigningProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Подпишите баланс',
    actionTitle: 'ПОДПИСАТЬ',
    actionButton: 'ПОДПИСАТЬ АКТ ВЕРИФИКАЦИИ',
    completedTitle: 'ПОДПИСАН',
    detailsTitle: 'ПОДПИСАНИЕ БАЛАНСА',
    rejectEnabled: false,
    documentId: parsedData.balanceDocumentId,
    link: '/cabinet/balances/',
    ...taskProps(parsedData),
  };
}

function balanceAgreementProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Согласуйте баланс',
    actionTitle: 'СОГЛАСОВАТЬ',
    actionButton: 'СОГЛАСОВАТЬ',
    completedTitle: 'СОГЛАСОВАН',
    detailsTitle: 'СОГЛАСОВАНИЕ БАЛАНСА',
    rejectEnabled: true,
    documentId: parsedData.balanceDocumentId,
    link: '/cabinet/balances/',
    ...taskProps(parsedData),
  };
}

function borderDeviceSigningProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Подпишите АСППУ',
    actionTitle: 'ПОДПИСАТЬ',
    actionButton: 'ПОДПИСАТЬ АКТ ВЕРИФИКАЦИИ',
    completedTitle: 'ПОДПИСАН',
    detailsTitle: 'ПОДПИСАНИЕ АСППУ',
    rejectEnabled: false,
    documentId: parsedData.borderDeviceActDocumentId,
    integralActId: parsedData.integralActId,
    link: '/cabinet/integral-acts/',
    ...taskProps(parsedData),
  };
}

function borderDeviceAgreementProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Согласуйте АСППУ',
    actionTitle: 'СОГЛАСОВАТЬ',
    actionButton: 'СОГЛАСОВАТЬ',
    completedTitle: 'СОГЛАСОВАН',
    detailsTitle: 'СОГЛАСОВАНИЕ АСППУ',
    rejectEnabled: true,
    documentId: parsedData.borderDeviceActDocumentId,
    integralActId: parsedData.integralActId,
    link: '/cabinet/integral-acts/',
    ...taskProps(parsedData),
  };
}

function borderFlowSigningProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Подпишите АПОПЭЭ',
    actionTitle: 'ПОДПИСАТЬ',
    actionButton: 'ПОДПИСАТЬ АКТ ВЕРИФИКАЦИИ',
    completedTitle: 'ПОДПИСАН',
    detailsTitle: 'ПОДПИСАНИЕ АПОПЭЭ',
    rejectEnabled: false,
    documentId: parsedData.borderFlowActDocumentId,
    integralActId: parsedData.integralActId,
    link: '/cabinet/integral-acts/',
    ...taskProps(parsedData),
  };
}

function borderFlowAgreementProps(workflowId, parsedData) {
  return {
    actDate: parsedData.actDate,
    stage: parsedData.stage,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    cardTitle: 'Согласуйте АПОПЭЭ',
    actionTitle: 'СОГЛАСОВАТЬ',
    actionButton: 'СОГЛАСОВАТЬ',
    completedTitle: 'СОГЛАСОВАН',
    detailsTitle: 'СОГЛАСОВАНИЕ АПОПЭЭ',
    rejectEnabled: true,
    documentId: parsedData.borderFlowActDocumentId,
    integralActId: parsedData.integralActId,
    link: '/cabinet/integral-acts/',
    ...taskProps(parsedData),
  };
}

function lossActSigningProps(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    documentId: parsedData.lossActDocumentId,
    documentShowUrl: parsedData.lossActShowUrl,
    documentSaveUrl: parsedData.lossActSaveUrl,
    ...taskProps(parsedData),
  };
}

function serviceActSigningProps(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    documentId: parsedData.serviceActDocumentId,
    documentShowUrl: parsedData.serviceActShowUrl,
    documentSaveUrl: parsedData.serviceActSaveUrl,
    ...taskProps(parsedData),
  };
}

function meterReportProps(workflowId, parsedData) {
  return {
    stage: parsedData.stage,
    completed: parsedData.completed,
    documentId: parsedData.documentId,
    documentShowUrl: parsedData.documentShowUrl,
    documentSaveUrl: parsedData.documentSaveUrl,
    ...taskProps(parsedData),
  };
}

function documentScanUploadProps(workflowId, parsedData) {
  return {
    domainDocumentId: parsedData.domainDocumentId,
    documentId: parsedData.documentId,
    documentName: parsedData.documentName,
    documentType: parsedData.documentType,
    completed: parsedData.completed,
    documentCreatedAt: convertDbDateTimeFormat(parsedData.documentCreatedAt),
    documentHash: parsedData.documentHash,
    documentXmlType: parsedData.documentXmlType,
    completedTitle: 'ЗАГРУЖЕНО',
    actionTitle: 'ЗАГРУЗИТЬ',
    ...taskProps(parsedData),
  };
}

function companyInviteProps(workflowId, parsedData) {
  return {
    cardTitle: 'Заполните профиль сотрудника',
    accountId: parsedData.accountId,
    actionTitle: 'Ожидание',
    completedTitle: 'Выполнено',
    stage: parsedData.stage,
    completed: ['REVIEW', 'REVOKED', 'APPROVED'].includes(parsedData.stage),
    roleName: parsedData.roleName,
    companyName: parsedData.companyName,
    link: '/cabinet/task/workerConnection',
    ...taskProps(parsedData),
  };
}

function contractAdditionUpload(workflowId, parsedData) {
  return {
    documentId: parsedData.documentId,
    documentName: parsedData.documentName,
    completed: parsedData.completed,
    documentCreatedAt: convertDbDateTimeFormat(parsedData.documentCreatedAt),
    documentHash: parsedData.documentHash,
    completedTitle: 'ВЫПОЛНЕНО',
    actionTitle: 'ВЫПОЛНИТЬ',
    ...taskProps(parsedData),
  };
}

function userProfileDeleteProps(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    documentCreatedAt: parsedData.documentCreatedAt,
    documentId: parsedData.documentId,
    initiatorFullName: parsedData.initiatorFullName,
    initiatorSnils: parsedData.initiatorSnils,
    initiatorUserId: parsedData.initiatorUserId,
    isGeneralDirector: parsedData.isGeneralDirector,
    rejected: parsedData.rejected,
    rejectedDecisionDocumentId: parsedData.rejectedDecisionDocumentId,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    ...taskProps(parsedData),
  };
}

function companyDeleteProps(workflowId, parsedData) {
  return {
    documentId: parsedData.documentId,
    documentReviewId: parsedData.documentReviewId,
    title: parsedData.title,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    documentCreatedAt: parsedData.documentCreatedAt,
    initiatorCompanyName: parsedData.initiatorCompanyName,
    initiatorCompanyInn: parsedData.initiatorCompanyInn,
    deleteCompanyAccountId: parsedData.deleteCompanyAccountId,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    ...taskProps(parsedData),
  };
}

function connectionUserBySupplyContractApplicationProps(workflowId, parsedData) {
  return {
    documentId: parsedData.documentId,
    documentReviewId: parsedData.documentReviewId,
    title: parsedData.title,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    documentCreatedAt: parsedData.documentCreatedAt,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    initiatorName: parsedData.initiatorName,
    initiatorSnilsOrInn: parsedData.initiatorSnilsOrInn,
    supplyContractDocumentId: parsedData.supplyContractDocumentId,
    supplyContractNumber: parsedData.supplyContractNumber,
    supplyContractDate: parsedData.supplyContractDate,
    isAnotherUserConnected: parsedData.isAnotherUserConnected,
    ...taskProps(parsedData),
  };
}

function updateUserDataApplicationProps(workflowId, parsedData) {
  return {
    documentId: parsedData.documentId,
    documentReviewId: parsedData.documentReviewId,
    title: parsedData.title,
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    documentCreatedAt: parsedData.documentCreatedAt,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    ...taskProps(parsedData),
  };
}

function permissionRegistryChangesProps(workflowId, parsedData) {
  return {
    ...taskProps(parsedData),
    documentId: parsedData.documentId,
    userId: parsedData.userId,
    userFullName: parsedData.userFullName,
    accountId: parsedData.accountId,
    accountFullName: parsedData.accountFullName,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    completed: parsedData.completed,
    sender: `${stringToFio(parsedData.userFullName)}, ${parsedData.accountFullName}`,
    documentCreatedAt: parsedData.createdAt,
    documentReviewId: parsedData.documentReviewId,
  };
}

function connectionToPlatform(workflowId, parsedData) {
  return {
    ...taskProps(parsedData),
    documentId: parsedData.documentId,
    userId: parsedData.userId,
    accountId: parsedData.accountId,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    completed: parsedData.completed,
    rejected: parsedData.rejected,
    documentCreatedAt: parsedData.createdAt,
    documentReviewId: parsedData.documentReviewId,
  };
}

function odkProps(workflowId, parsedData) {
  return {
    ...taskProps(parsedData),
    odkId: parsedData.odkId,
    additionalData: parsedData.data,
    completedTitle: 'Выполнено',
    actionTitle: 'Выполнить',
    completed: parsedData.completed,
    documentCreatedAt: parsedData.createdAt,
    process: parsedData.process,
  };
}

function connectionCertificateAfterContractValidationProps(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    completedTitle: 'ЗАГРУЖЕНО',
    actionTitle: 'ЗАГРУЗИТЬ',
    consumerName: parsedData.consumerName,
    address: parsedData.address,
    meterSerial: parsedData.meterSerial,
    ...taskProps(parsedData),
  };
}

function inspectionReportAfterContractValidationProps2(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    completedTitle: 'ЗАГРУЖЕНО',
    actionTitle: 'ЗАГРУЗИТЬ',
    meterSerial: parsedData.meterSerial,
    ...taskProps(parsedData),
  };
}

function consumerAdditionAfterContractValidationProps(workflowId, parsedData) {
  return {
    completed: parsedData.completed,
    completedTitle: 'ЗАГРУЖЕНО',
    actionTitle: 'ЗАГРУЗИТЬ',
    consumers: parsedData.consumers,
    ...taskProps(parsedData),
  };
}

function stringToFio(fio) {
  const splitted = fio.split(' ');
  const surname = splitted.shift();
  return surname + splitted.map((it) => ` ${it[0]}.`).join('');
}

const STRATEGIES_MANAGEMENT = {
  individualCustomerConnection: {
    component: 'ConnectionApplicationCard',
    props: connectionProps,
  },
  individualCustomerConnectionRetail: {
    component: 'ConnectionApplicationCard',
    props: connectionProps,
  },
  microgenerationConnectionUtility: {
    component: 'ConnectionApplicationCard',
    props: microgenerationProps,
  },
  microgenerationConnectionRetail: {
    component: 'ConnectionApplicationCard',
    props: microgenerationProps,
  },
  tariffsUpdateTask: {
    component: 'TariffsUpdateCard',
    props: tariffsUpdateProps,
  },
  tariffChangingApplication: {
    component: 'TariffsChangingCard',
    props: tariffsChangingProps,
  },
  utmInstallation: {
    component: 'UtmInstallation',
    props: utmInstallationProps,
  },
  userProfileDelete: {
    component: 'UserProfileDelete',
    props: userProfileDeleteProps,
  },
  companyDelete: {
    component: 'CompanyDelete',
    props: companyDeleteProps,
  },
  transmissionContract: {
    component: 'UploadDocumentCard',
    componentDetails: 'UploadDocumentDetails',
    props: transmissionContractProps,
  },
  transmissionContractThreeBoiler: {
    component: 'UploadDocumentCard',
    componentDetails: 'UploadDocumentDetails',
    props: transmissionContractThreeBoilerProps,
  },
  transmissionContractSigning: {
    component: 'TransmissionContractSigningCard',
    componentDetails: 'TransmissionContractSigningDetails',
    props: transmissionContractSigningProps,
  },
  transmissionContractAgreement: {
    component: 'TransmissionContractSigningCard',
    componentDetails: 'TransmissionContractSigningDetails',
    props: transmissionContractAgreementProps,
  },
  transmissionContractReload: {
    component: 'TransmissionContractReloadCard',
    props: transmissionContractReloadProps,
  },
  documentSigning: {
    component: 'TransmissionContractSigningCard',
    componentDetails: 'TransmissionContractSigningDetails',
    props: transmissionContractSigningProps,
  },
  documentAgreement: {
    component: 'TransmissionContractSigningCard',
    componentDetails: 'TransmissionContractSigningDetails',
    props: transmissionContractAgreementProps,
  },
  documentReload: {
    component: 'TransmissionContractReloadCard',
    props: transmissionContractReloadProps,
  },
  documentUpdateInfo: {
    component: 'TransmissionContractSigningCard',
    componentDetails: 'TransmissionContractSigningDetails',
    props: transmissionContractUpdateInfoProps,
  },
  balanceSigning: {
    component: 'BalanceAgreementCard',
    props: balanceSigningProps,
  },
  balanceAgreement: {
    component: 'BalanceAgreementCard',
    props: balanceAgreementProps,
  },
  borderDeviceSigning: {
    component: 'IntegralActApproveCard',
    props: borderDeviceSigningProps,
  },
  borderDeviceAgreement: {
    component: 'IntegralActApproveCard',
    props: borderDeviceAgreementProps,
  },
  borderFlowSigning: {
    component: 'IntegralActApproveCard',
    props: borderFlowSigningProps,
  },
  borderFlowAgreement: {
    component: 'IntegralActApproveCard',
    props: borderFlowAgreementProps,
  },
  lossActSigning: {
    props: lossActSigningProps,
  },
  serviceActSigning: {
    props: serviceActSigningProps,
  },
  contractAdditionUpload: {
    props: contractAdditionUpload,
  },
  meterReport: {
    component: 'MeterReportCard',
    componentDetails: 'MeterReportDetails',
    props: meterReportProps,
  },
  documentScanUpload: {
    props: documentScanUploadProps,
  },
  companyInvite: {
    props: companyInviteProps,
  },
  connectionUserBySupplyContractApplication: {
    props: connectionUserBySupplyContractApplicationProps,
  },
  updateUserDataApplication: {
    props: updateUserDataApplicationProps,
  },
  permissionRegistryChanges: {
    props: permissionRegistryChangesProps,
  },
  connectionToPlatform: {
    props: connectionToPlatform,
  },
  odk: {
    props: odkProps,
  },
  connectionCertificateAfterContractValidation: {
    props: connectionCertificateAfterContractValidationProps,
  },
  supplyContractInformationAfterContractValidation: {
    props: connectionCertificateAfterContractValidationProps,
  },
  consumerAdditionAfterContractValidation: {
    props: consumerAdditionAfterContractValidationProps,
  },
  inspectionReportAfterContractValidation: {
    props: inspectionReportAfterContractValidationProps2,
  },
};

const SET_WORKFLOWS = async (state, data) => {
  state.workflows = data.workflows;
  if (data.workflows?.length > 0) {
    const taskList = data.workflows.map(async (workflow) => {
      const parsedData = JSON.parse(workflow.data);
      parsedData.isInitiator = parsedData.accountId === data.currentAccount?.id;
      const strategy = STRATEGIES_MANAGEMENT[workflow.type];
      if (!strategy) {
        return {};
      }

      return {
        props: {
          ...strategy.props(workflow.id, parsedData, workflow.type, data.userType),
          userType: data.userType,
          isInitiator: parsedData.isInitiator,
          workflowType: workflow.type,
          workflowId: workflow.id,
          createdAt: workflow.createdAt,
          updatedAt: workflow.updatedAt,
          // stage: parsedData.stage,
        },
        component: strategy.component,
        componentDetails: strategy.componentDetails,
      };
    });

    const tasks = await Promise.all(taskList);

    // TODO убрать когда появится подписания актов на услуги и потери
    const tasksWithoutLossActsAndService = tasks.filter((task) => task.props.workflowType !== 'lossActSigning' && task.props.workflowType !== 'serviceActSigning');

    const counter = tasks.filter((task) => task.props.completed === false && task.props.completed !== undefined);
    localStorage.setItem('tasksCounter', `${counter.length}`);
    console.log(tasks, 'tasks');
    state.tasksCounter = counter.length;

    SET_TASKS(state, tasksWithoutLossActsAndService, data.userType);
  } else {
    SET_TASKS(state, [], data.userType);
    localStorage.setItem('tasksCounter', '0');
    state.tasksCounter = 0;
  }
};

const SET_WORKFLOWS_BY_CORRELATION_ID = async (state, data) => {
  console.log(data);
  if (data.workflowsByCorrelationId?.length > 0) {
    const taskList = data.workflowsByCorrelationId.map(async (workflow) => {
      const parsedData = JSON.parse(workflow.data);
      const strategy = STRATEGIES_MANAGEMENT[workflow.type];

      if (!strategy) {
        return {};
      }

      return {
        props: {
          ...strategy.props(workflow.id, parsedData, workflow.type, data.userType),
          workflowType: workflow.type,
          workflowId: workflow.id,
          userType: data.userType,
          // stage: parsedData.stage,
        },
        component: strategy.component,
        componentDetails: strategy.componentDetails,
      };
    });

    state.workflowsByCorrelationId = await Promise.all(taskList);
  } else {
    state.workflowsByCorrelationId = [];
  }
};

const SET_TASKS = (state, tasks, userType) => {
  Vue.set(state.tasks, userType, tasks);
};

const ADD_TASK = (state, task) => {
  state.testTasks.push(task);
};

const CLEAR_TEST_TASK = (state) => {
  state.testTasks = [];
};

const CLEAR_WORKFLOWS = (state) => {
  state.workflows = [];
  state.tasks = {};
};

const CLEAR_TASKS = (state) => {
  state.tasks = {};
};

export default {
  SET_WORKFLOWS,
  SET_WORKFLOWS_BY_CORRELATION_ID,
  SET_TASKS,
  CLEAR_WORKFLOWS,
  ADD_TASK,
  CLEAR_TEST_TASK,
  CLEAR_TASKS,
};
