import LoginScreen from 'views/public/login/Login.vue';
import HelpScreen from 'views/public/help/Help.vue';
import HelpEntity from 'views/public/help/components/HelpEntity.vue';
import HelpNetworkCompany from 'views/public/help/components/HelpNetworkCompany.vue';
import HelpSalesCompany from 'views/public/help/components/HelpSalesCompany.vue';
import HelpAboutProject from 'views/public/help/components/HelpAboutProject.vue';
import HelpAboutCompany from 'views/public/help/components/HelpAboutCompany.vue';
import HelpTechnicalSupport from 'views/public/help/components/HelpTechnicalSupport.vue';
import CheckAuth from 'views/public/CheckAuth';
import RestorePasswordScreen from 'views/public/login/RestorePasswordScreen.vue';

import { generateTestCabinetRoute } from '@/utils/common';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginScreen,
    meta: { title: 'Вход на «Мой свет»' },
  },
  {
    path: '/check-auth',
    name: 'check-auth',
    component: CheckAuth,
    meta: { title: 'Вход на «Мой свет»' },
  },
  {
    path: '/help-individual',
    name: 'help-individual',
    component: HelpScreen,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-entity',
    name: 'help-entity',
    component: HelpEntity,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-network-company',
    name: 'help-network-company',
    component: HelpNetworkCompany,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-sales-company',
    name: 'help-sales-company',
    component: HelpSalesCompany,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-about-project',
    name: 'help-about-project',
    component: HelpAboutProject,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-about-company',
    name: 'help-about-company',
    component: HelpAboutCompany,
    meta: { title: 'Помощь' },
  },
  {
    path: '/help-technical-support',
    name: 'help-technical-support',
    component: HelpTechnicalSupport,
    meta: { title: 'Помощь' },
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: RestorePasswordScreen,
    meta: { title: 'Восстановление пароля' },
  },
];

const testCabinetRoute = generateTestCabinetRoute('public');

if (testCabinetRoute) {
  routes.push(testCabinetRoute);
}

export default routes.map((route) => {
  const meta = {
    ...route.meta,
    public: true,
    onlyLoggedOut: true,
  };

  return { ...route, meta };
});
