<template>
  <div :class="$style.container">
    <p :class="$style.description">{{ description }}</p>
    <c-input
      v-if="region"
      v-model="region"
      label="Территориальный субъект"
      :class="{[$style.disabled]: true, [$style.description]: true}"
      :disabled="true"
    />
    <c-input
      v-if="year"
      v-model="year"
      label="Период действия тарифов"
      :class="{[$style.disabled]: true, [$style.description]: true}"
      :disabled="true"
      style="width: 36%"
    />
    <table>
      <thead>
        <tr :class="$style.halfTitle">
          <th>&nbsp;</th>
          <td>&nbsp;</td>
          <td>I полугодие</td>
          <td>II полугодие</td>
        </tr>
      </thead>
      <tbody v-for="(tariff, index) in tariffHalf" :key="taskDocumentId + index">
        <tr>
          <th style="display: inline-block;vertical-align: top;">{{ tariff.number }}<span v-if="tariff.displayTotal">/6</span></th>
          <th colspan="3">
            <p :class="$style.textHelper">
              {{ tariff.title }}&nbsp;
              <tooltip-helper v-if="tariff.help && tariff.help.length > 0" placement="left-end" offset="6px">
                <div :class="$style.tooltip">
                  <p v-for="(help, indexHelp) in tariff.help" :key="indexHelp">{{ help }}</p>
                </div>
              </tooltip-helper>
            </p>
          </th>
        </tr>
        <template v-if="tariff.rates">
          <tr>
            <td>{{ tariff.number }}.1</td>
            <td>{{ tariff.rates.rate1.title }}</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate1.first.t1"
                :number="tariff.number"
                rate-number="1"
                half="I"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate1.second.t1"
                :number="tariff.number"
                rate-number="1"
                half="II"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
          <tr>
            <td>{{ tariff.number }}.2</td>
            <td colspan="3">{{ tariff.rates.rate2.title }}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td :class="$style.zoneTitle">Дневная зона<br/>(пиковая и полупиковая)</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate2.first.t1"
                :number="tariff.number"
                rate-number="2"
                half="I"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate2.second.t1"
                :number="tariff.number"
                rate-number="2"
                half="II"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td :class="$style.zoneTitle">Ночная зона</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate2.first.t2"
                :number="tariff.number"
                rate-number="2"
                half="I"
                tariff="t2"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate2.second.t2"
                :number="tariff.number"
                rate-number="2"
                half="II"
                tariff="t2"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
          <tr>
            <td>{{ tariff.number }}.3</td>
            <td colspan="3">{{ tariff.rates.rate3.title }}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td :class="$style.zoneTitle">Пиковая зона</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.first.t1"
                :number="tariff.number"
                rate-number="3"
                half="I"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.second.t1"
                :number="tariff.number"
                rate-number="3"
                half="II"
                tariff="t1"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td :class="$style.zoneTitle">Полупиковая зона</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.first.t3"
                :number="tariff.number"
                rate-number="3"
                half="I"
                tariff="t3"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.second.t3"
                :number="tariff.number"
                rate-number="3"
                half="II"
                tariff="t3"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td :class="$style.zoneTitle">Ночная зона</td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.first.t2"
                :number="tariff.number"
                rate-number="3"
                half="I"
                tariff="t2"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
            <td :class="$style.tariffValue">
              <view-value
                v-model="tariff.rates.rate3.second.t2"
                :number="tariff.number"
                rate-number="3"
                half="II"
                tariff="t2"
                :mode="mode"
                :is-correction="isCorrection"
                @review-value="changeReviewValue"
              />
            </td>
          </tr>
        </template>
      </tbody>

    </table>
  </div>
</template>

<script>
function NumberFormat(number) {
  return Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number.replace(new RegExp('[,]', 'g'), '.'));
}

export default {
  name: 'TariffForm',
  components: {
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    CInput: () => import('atoms/common/inputs/CInput'),
    ViewValue: () => import('organisms/tasks/tariffs-update/actions/ViewValue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    // TODO remove prudent prop
    taskDocumentId: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      default: undefined,
    },
    year: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: '',
    },
    tariffsData: {},
    mode: {
      type: String,
      default: 'view',
    },
    invalidValues: {
      type: Object,
      default: undefined,
    },
    dictionaryCategory: {
      type: Object,
      required: true,
    },
    dictionaryRate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tariffHalf: this.initTariff(),
      isCorrection: false,
    };
  },
  watch: {
/*    value: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.tariffHalf = val;
        }
      },
    },*/
    tariffHalf: {
      immediate: true,
      handler(val) {
        if (val.length > 0 && val[0].rates) {
          this.$emit('input', val);
        }
      },
    },
    tariffsData: {
      immediate: true,
      handler(val) {
        if (val && val.firstHalfYearTariffs) {
          let index = 0;

          if (this.invalidValues && Object.keys(this.invalidValues).length > 0) {
            this.isCorrection = true;
          }

          this.tariffHalf.forEach((item) => {
            if (item.rates) {
              item.rates.rate1.first.t1.value = NumberFormat(val.firstHalfYearTariffs[index].singleRate.T1.toString());
              item.rates.rate1.second.t1.value = NumberFormat(val.secondHalfYearTariffs[index].singleRate.T1.toString());

              item.rates.rate2.first.t1.value = NumberFormat(val.firstHalfYearTariffs[index].doubleRate.T1.toString());
              item.rates.rate2.first.t2.value = NumberFormat(val.firstHalfYearTariffs[index].doubleRate.T2.toString());
              item.rates.rate2.second.t1.value = NumberFormat(val.secondHalfYearTariffs[index].doubleRate.T1.toString());
              item.rates.rate2.second.t2.value = NumberFormat(val.secondHalfYearTariffs[index].doubleRate.T2.toString());

              item.rates.rate3.first.t1.value = NumberFormat(val.firstHalfYearTariffs[index].multiRate.T1.toString());
              item.rates.rate3.first.t2.value = NumberFormat(val.firstHalfYearTariffs[index].multiRate.T2.toString());
              item.rates.rate3.first.t3.value = NumberFormat(val.firstHalfYearTariffs[index].multiRate.T3.toString());
              item.rates.rate3.second.t1.value = NumberFormat(val.secondHalfYearTariffs[index].multiRate.T1.toString());
              item.rates.rate3.second.t2.value = NumberFormat(val.secondHalfYearTariffs[index].multiRate.T2.toString());
              item.rates.rate3.second.t3.value = NumberFormat(val.secondHalfYearTariffs[index].multiRate.T3.toString());

              if (this.isCorrection) {
                item.rates.rate1.first.t1.error = this.invalidValues[item.rates.rate1.first.t1.id] !== undefined;
                item.rates.rate1.second.t1.error = this.invalidValues[item.rates.rate1.second.t1.id] !== undefined;

                item.rates.rate2.first.t1.error = this.invalidValues[item.rates.rate2.first.t1.id] !== undefined;
                item.rates.rate2.first.t2.error = this.invalidValues[item.rates.rate2.first.t2.id] !== undefined;
                item.rates.rate2.second.t1.error = this.invalidValues[item.rates.rate2.second.t1.id] !== undefined;
                item.rates.rate2.second.t2.error = this.invalidValues[item.rates.rate2.second.t2.id] !== undefined;

                item.rates.rate3.first.t1.error = this.invalidValues[item.rates.rate3.first.t1.id] !== undefined;
                item.rates.rate3.first.t2.error = this.invalidValues[item.rates.rate3.first.t2.id] !== undefined;
                item.rates.rate3.first.t3.error = this.invalidValues[item.rates.rate3.first.t3.id] !== undefined;
                item.rates.rate3.second.t1.error = this.invalidValues[item.rates.rate3.second.t1.id] !== undefined;
                item.rates.rate3.second.t2.error = this.invalidValues[item.rates.rate3.second.t2.id] !== undefined;
                item.rates.rate3.second.t3.error = this.invalidValues[item.rates.rate3.second.t3.id] !== undefined;
              }

              index += 1;
            }
          });
        }
      },
    },
  },
  methods: {
    changeReviewValue(target) {
      this.$emit(
        'review-value',
        target,
      );
    },
    initTariff() {
      class TariffCategory {
        constructor(category, dictionaryRate) {
          this.rate1 = {
            title: dictionaryRate.SINGLE_RATE.title,
            first: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate1_first_t1` },
            },
            second: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate1_second_t1` },
            },
          };
          this.rate2 = {
            title: dictionaryRate.DOUBLE_RATE.title,
            first: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate2_first_t1` },
              t2: { value: '2,00', error: false, id: `tariff_${category}_rate2_first_t2` },
            },
            second: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate2_second_t1` },
              t2: { value: '2,00', error: false, id: `tariff_${category}_rate2_second_t2` },
            },
          };
          this.rate3 = {
            title: dictionaryRate.MULTI_RATE.title,
            first: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate3_first_t1` },
              t2: { value: '2,00', error: false, id: `tariff_${category}_rate3_first_t2` },
              t3: { value: '3,00', error: false, id: `tariff_${category}_rate3_first_t3` },
            },
            second: {
              t1: { value: '1,00', error: false, id: `tariff_${category}_rate3_second_t1` },
              t2: { value: '2,00', error: false, id: `tariff_${category}_rate3_second_t2` },
              t3: { value: '3,00', error: false, id: `tariff_${category}_rate3_second_t3` },
            },
          };
        }
      }

      return [
        {
          ...this.dictionaryCategory.CATEGORY_1,
          displayTotal: true,
          rates: new TariffCategory('CATEGORY_1', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_2,
          displayTotal: true,
          rates: new TariffCategory('CATEGORY_2', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_3,
          displayTotal: true,
          rates: new TariffCategory('CATEGORY_3', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_4,
          displayTotal: true,
          rates: new TariffCategory('CATEGORY_4', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_5,
          displayTotal: true,
          rates: new TariffCategory('CATEGORY_5', this.dictionaryRate),
        },
        {
          category: 'CATEGORY_6-title',
          title: 'Потребители, приравненные к населению',
          number: '6',
          displayTotal: true,
          help: [''],
        },
        {
          ...this.dictionaryCategory.CATEGORY_6,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_6', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_7,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_7', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_8,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_8', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_9,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_9', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_10,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_10', this.dictionaryRate),
        },
        {
          ...this.dictionaryCategory.CATEGORY_11,
          displayTotal: false,
          rates: new TariffCategory('CATEGORY_11', this.dictionaryRate),
        },

      ];
    },
  },
};
</script>

<style lang="sass" module>
  .tooltip
    width: 520px
    text-align: left

    p
      +card-caption

  .textHelper
    +base-title
    display: flex

  .container
    table
      tr
        td
          +card-title-small

        td, th
          padding-top: 16px
          padding-bottom: 16px

        th
          +base-title
          text-align: left

          span
            font-family: 'Roboto Condensed'
            font-style: normal
            font-weight: normal
            font-size: 17px
            line-height: 32px
            letter-spacing: 0.005em
            color: #C1C4C7

          &:first-child
            width: 40px
            text-align: left

    table
      input
        width: 128px !important

  .zoneTitle
    +base-text
    font-family: Roboto, sans-serif

  .disabled
    input
      border: 1px solid #ACB0B5

  .description
    margin-top: 12px
    margin-bottom: 24px

  .halfTitle
    td
      text-align: center

  .tariffValue
    text-align: right
</style>

