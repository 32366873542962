<template>
  <page
    title='Нормативы потерь электрической энергии при ее передаче по единой национальной (общероссийской) электрической сети, осуществляемой публичным акционерным обществом "Федеральная сетевая компания - Россети"'>
    <v-table-new
      v-model="transformedTariffLossNormatives"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="tariffLossNormativesPage.pageOut"
      :template="template"
      excel-report-file-name='Нормативы потерь электрической энергии при ее передаче по единой национальной (общероссийской) электрической сети, осуществляемой публичным акционерным обществом "Федеральная сетевая компания - Россети"'
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import formatting from 'lib/utils/formatting';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'TariffLossNormatives',
  components: {
    VTableNew,
    Page,
  },
  data() {
    return {
      tariffLossNormativesPage: {},
      loading: false,
      pagination: initialPagination,
      isModalVisible: false,
      isProcessComplete: false,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Год',
            key: 'year',
            thStyle: {
              minWidth: '70px',
            },
            sort: {},
            filter: { type: 'date', format: 'YYYY', selectType: 'year' },
          },
          {
            label: 'Суммарный отпуск ЭЭ из сети "220кВ и ниже", тыс. кВт*ч',
            type: 'number',
            key: 'volume220',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Норматив потерь ЭЭ при ее передаче по ЕНЭС по уровню напряжения "220кВ и ниже", % от суммарного отпуска ЭЭ из сети "220кВ и ниже"',
            type: 'number',
            key: 'lossVolume220',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Суммарный отпуск ЭЭ из сети "330кВ и выше", тыс. кВт*ч',
            type: 'number',
            key: 'volume330',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Норматив потерь ЭЭ при ее передаче по ЕНЭС по уровню напряжения "330кВ и выше", % от суммарного отпуска ЭЭ из сети "330кВ и выше"',
            type: 'number',
            key: 'lossVolume330',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    tariffLossNormatives() {
      return this.tariffLossNormativesPage.list ?? [];
    },
    transformedTariffLossNormatives() {
      return this.tariffLossNormatives.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.tariffLossNormativesPage = await this.tariffLossNormativesByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('tariffLossNormativesByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const tariffLossNormatives = await this.tariffLossNormativesByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.tariffLossNormativesPage.pageOut.totalElements,
          },
        });

        if (!tariffLossNormatives?.list) {
          return [];
        }

        return tariffLossNormatives.list.map(this.transformed);
      } catch (e) {
        console.log('tariffLossNormativesByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    transformed(it) {
      return {
        year: it.tariffsOrder?.year,
        lossVolume220: formatting.numberFormatted(it.lossVolume220),
        volume220: formatting.numberFormatted(it.volume220),
        lossVolume330: formatting.numberFormatted(it.lossVolume330),
        volume330: formatting.numberFormatted(it.volume330),
        document: { documentId: it.tariffsOrder?.documentId, name: 'TariffLossNormatives' },
      };
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    ...mapActions('dataLists', ['tariffLossNormativesByPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
