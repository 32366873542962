<template>
  <div style="margin-top: 24px">
    <v-table-new
      v-model="transformedAxps"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. АХП типы"
      @delete="onDelete"
      @edit="onEdit"
    />
    <axp-edit
      :modal-action.sync="modalAction"
      :modal-value.sync="modalValue"
      :elements-modal-action="elementsModalAction"
      :elements-modal-value="elementsModalValue"
      @loadData="loadData"
    />
  </div>
</template>

<script>
import VTableNew from '@/components/VTableNew/VTable';
import { fetchAxp, fetchAxpElementsByAxpId } from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import {
  convertBooleanToText,
  unconvertBooleanToText,
} from 'lib/utils/formatXmlType';
import { BASE_URL } from '@/constants/api';
import { convertToLabelValueList } from 'utils/list';
import AxpEdit from 'views/private/OdkAccounting/Axp/AxpEdit.vue';

export default {
  name: 'OdkAccountingAxp',
  components: {
    VTableNew,
    AxpEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      elementsModalAction: AddDirectoryFieldModalActions.EMPTY,
      elementsModalValue: {},
      loading: false,
      axp: [],
      odkName: '',
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate
        ? [
            {
              id: 1,
              title: 'Добавить АХП тип',
              method: this.onCreate,
            },
          ]
        : [];
    },
    isAccessUpdate() {
      return filterForAccess('process/2/1', this.getAuthorization?.blockAccess);
    },
    transformedAxps() {
      if (!this.axp?.length) {
        return [];
      }

      return this.axp.map((ax) => ({
        ...ax,
        specialProject: convertBooleanToText(ax.specialProject),
      }));
    },
    template() {
      const editHeaders = this.isAccessUpdate
        ? [
            {
              key: 'actionButtons',
              type: 'editDeleteButtons',
              thStyle: {
                width: '80px',
              },
            },
          ]
        : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Наименование ОДК',
            key: 'odk.name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'odkOdk' },
          },
          {
            label: 'Мощность',
            key: 'power',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Ель',
            key: 'firText',
            sort: {},
            thStyle: {
              minWidth: '60px',
            },
            filter: {
              type: 'select',
              values: convertToLabelValueList(['Да', 'Нет']),
            },
          },
          {
            label: 'Спецпроект',
            key: 'specialProject',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {
              type: 'select',
              values: convertToLabelValueList(['Да', 'Нет']),
            },
          },
          ...editHeaders,
        ],
      };
    },
    elementsTemplate() {
      return {
        headers: [
          {
            label: '№П/П',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Наименование',
            key: 'name',
          },
          {
            label: 'Количество',
            key: 'quantity',
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.axp = (await fetchAxp()).map((it) => ({
          ...it,
          odk: {
            id: String(it.odk.id),
            name: it.odk.name,
          },
          firText: convertBooleanToText(it.fir),
          power: String(it.power),
          fir: String(it.fir),
          photos: this.transformFilesFromRequest(it.photos),
          documents: this.transformFilesFromRequest(it.documents),
          specialProject: it.specialProject,
        }));
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    async onEdit(cell) {
      this.loading = true;
      try {
        const elements = await fetchAxpElementsByAxpId(cell.row.id);
        this.modalAction = AddDirectoryFieldModalActions.UPDATE;
        this.modalValue = {
          ...{
            ...cell.row,
            specialProject: String(unconvertBooleanToText(cell.row.specialProject)),
          },
          elements: elements.map((it) => ({
            ...it,
            axpId: String(it.axp.id),
            unitId: String(it.unit.id),
            typeId: String(it.type.id),
            decorTypeId: String(it.decorType?.id ?? ''),
            required: String(it.required),
            archived: String(it.archived),
            quantity: String(it.quantity),
            requiredText: convertBooleanToText(it.required),
            archivedText: convertBooleanToText(it.archived),
            photos: this.transformFilesFromRequest(it.photos),
            specialProject: it.axp.specialProject,
          })),
        };
      } catch (e) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {
        elements: [],
      };
    },
    transformFilesFromRequest(photos) {
      return photos.map((doc) => ({
        fileId: doc.id,
        file: null,
        name: doc.name,
        previewUrl: `${BASE_URL}/api/get/${doc.folder}/${doc.name}`,
        new: false,
      }));
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}

.elements {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-bottom: 12px;
}
</style>
