<template>
  <div :class="$style.container">
    <div :class="$style['container__action1']">
      <button v-for="(element, idx) in configuration" :key="idx" :class="$style['container__action1__element']"
              :disabled="!element.enabled" @click="changeMode(element.mode)">
        <span :class="{[$style['active-number']]: mode === element.mode}">{{ element.count }}</span>
        <div :class="$style.divider">&nbsp;</div>
        <span :class="{[$style['active-text']]: mode === element.mode}">{{ element.name }}</span>
        <div v-if="mode === element.mode" :class="$style['active']"/>
      </button>
    </div>
    <div :class="$style['container__btn-group']">
      <div :class="$style['container__group-divider']">&nbsp;</div>
      <v-divider :class="$style['container__horizontal-divider']"/>
      <v-button style="padding: 10px 12px" :disabled="isPlatformWorkerForCompany && !companyAccountId" @click="clickAddEmployee">Добавить сотрудника</v-button>
      <attorney-action-block v-if="!isPlatformWorker" home-title="Сотрудники компании" home-url="/cabinet/employees"/>
    </div>

    <!-- add user modal begin -->
    <v-modal :visible="isModalVisible" content-width="1024px">
      <div :class="$style.modalContent">
        <h1>Добавление сотрудников</h1>
        <p :class="$style['modalContent__step-title']">Введите e-mail сотрудника:</p>
        <v-form @submit="addEmployee">
          <div style="max-width: 100%; margin-bottom: 24px">
            <v-input
              v-model="email"
              :err="requiredEmailError"
              clearable
              label="Email *"
            />
            <p v-if="requiredEmailError" class="errorText">Поле Email обязательно для заполнения</p>
            <p v-if="errorContains" class="errorText">Пользователь уже добавлен</p>
          </div>
          <div style="max-width: 100%; margin-bottom: 24px;position: relative;z-index: 10;">
            <v-search-select
              v-model="selectedSubdivision"
              :err="errorSubdivision"
              :options="subdivisions"
              title="Подразделение"
            />
          </div>
          <div style="max-width: 100%; margin-bottom: 24px;position: relative">
            <v-search-select
              v-model="selectedRole"
              :err="errorRole"
              :options="roles"
              title="Должность"
            />
          </div>
          <v-button type="submit" :disabled="isWaitingUsersSubmit">Добавить в список сотрудников</v-button>
        </v-form>
        <v-divider/>
        <p :class="$style['modalContent__step-title']"><b>Список сотрудников кому будет отправлено приглашение:</b></p>
        <div ref="users" :class="$style['modalContent__users']">
          <div v-for="(user, idx) in users" :key="idx" :class="$style['modalContent__users__user']">
            <div :class="$style['modalContent__users__user__left']" @mouseleave="closeTooltip"
                 @mouseover="openTooltip(user.email, idx)">
              {{ transformEmail(user.email) }}
              <div v-if="tooltip && tooltipIdx === idx" :class="$style['modalContent__users__user__left__tooltip']">
                {{ user.email }}
              </div>
            </div>
            <v-search-select
                v-model="user.subdivision"
                :error="errorSubdivision"
                :options="subdivisions"
                :disabled="isWaitingUsersSubmit"
                required
                max-height="140"
            />
            <v-search-select
                v-model="user.role"
                :error="errorRole"
                :options="subdivisionRoles(user.subdivision)"
                :disabled="isWaitingUsersSubmit"
                required
                max-height="140"
            />
            <div :class="$style['modalContent__users__user__right']" @mousedown="removeEmployee(idx)">
              <img alt="close" src="@/assets/icons/basic/close4.svg">
            </div>
          </div>
        </div>
        <div :class="$style['modalContent__btn-group']">
          <p v-if="isSubmittingError" class="errorText">{{ submittingError }}</p>
          <v-button :disabled="isWaitingUsersSubmit || !isValidUsers" style="margin-right: 16px" @click="saveUsers">
            Отправить
          </v-button>
          <v-button variant="outlined" @click="cancel">Отменить</v-button>
          <horizontal-progress-bar v-if="isWaitingUsersSubmit"/>
        </div>
      </div>
    </v-modal>
    <!-- add user modal end -->

  </div>
</template>

<script>
import { VButton, VDivider, VForm, VInput, VModal, VSearchSelect } from '@/components';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import generateXml from 'lib/storage/connection-application/data';
import { mapActions, mapGetters } from 'vuex';
import AttorneyActionBlock from 'views/private/AttorneyPage/Attorney/ActionBlock';

export default {
  name: 'ActionBlock',
  components: {
    VSearchSelect,
    VDivider,
    VModal,
    VButton,
    HorizontalProgressBar,
    VForm,
    VInput,
    AttorneyActionBlock,
  },
  props: {
    mode: { type: String, default: 'employees' },
    companyAccountId: {
      type: String,
      default: undefined,
    },
    companyName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      users: [],
      acceptedInvitations: [],
      notAcceptedInvitations: [],
      xmlAddUsersToOrganisationApplication: '',
      submittingError: '',
      isSubmittingError: false,
      isWaitingUsersSubmit: false,
      isModalVisible: false,
      isModalCheck: false,
      email: '',
      selectedRole: '',
      selectedSubdivision: undefined,
      errorSubdivision: false,
      errorRole: false,
      errorContains: false,
      requiredEmailError: false,
      tooltip: false,
      tooltipIdx: null,
      typeFormAttorney: 'form',

    };
  },
  computed: {
    configuration() {
      return [{
        name: 'Всего сотрудников',
        count: this.allEmployees + this.notAcceptedInvitationsCount,
        mode: 'employees',
        enabled: true,
      },
        {
          name: 'Принято приглашений',
          count: this.acceptedInvitationsCount,
          mode: 'acceptedInvitations',
          enabled: true,
        },
        {
          name: 'В ожидании',
          count: this.notAcceptedInvitationsCount,
          mode: 'notAcceptedInvitations',
          enabled: true,
        },
        {
          name: 'Нет возможности выслать приглашение',
          count: 0,
          mode: 'errors',
          enabled: false,
        },
        {
          name: 'Недавно добавленные',
          count: 0,
          mode: 'new',
          enabled: false,
        },
      ];
    },
    allEmployees() {
      return this.getEmployees.length;
    },
    acceptedInvitationsCount() {
      return this.getAcceptedInvitations.length;
    },
    notAcceptedInvitationsCount() {
      return this.getNotAcceptedInvitations.length;
    },
    roles() {
      return this.getRoles
          .filter((it) => !it.isCompanyOwner && ((!it.subdivision && this.selectedSubdivision === 'Без подразделения') || (it.subdivision?.id === this.selectedSubdivision)))
          .map((item) => ({ ...item, label: item.name, value: item.id }));
    },
    subdivisions() {
      const subdivisions = [];
      this.getRoles.filter((it) => !it.isCompanyOwner && it.subdivision).forEach((role) => {
        if (!subdivisions.find((subdivision) => subdivision.id === role.subdivision.id)) {
          subdivisions.push({ name: role.subdivision.name, id: role.subdivision.id, label: role.subdivision.name, value: role.subdivision.id });
        }
      });
      return [{ name: 'Без подразделения', label: 'Без подразделения', value: 'Без подразделения' }, ...subdivisions];
    },
    isValidUsers() {
      return this.users.length > 0 && this.users.every((it) => it.role);
    },
    isPlatformWorkerForCompany() {
      return this.isPlatformWorker && this.$route.name === 'cabinet.company-employees';
    },
    ...mapGetters('employees', ['getEmployees', 'getRoles', 'getAcceptedInvitations', 'getNotAcceptedInvitations']),
    ...mapGetters('attorney', ['getFormAttorney']),
    ...mapGetters('user', ['isPlatformWorker']),
  },
  watch: {
    selectedSubdivision() {
      this.clearSubmittingError();
      this.selectedRole = '';
    },
    selectedRole() {
      this.clearSubmittingError();
    },
  },
  methods: {
    subdivisionRoles(subdivisionId) {
      return this.getRoles
          .filter((it) => !it.isCompanyOwner && ((!it.subdivision && subdivisionId === 'Без подразделения') || (it.subdivision?.id === subdivisionId)))
          .map((item) => ({ ...item, label: item.name, value: item.id }));
    },
    openTooltip(email, idx) {
      if (email.length > 38) {
        this.tooltipIdx = idx;
        this.tooltip = true;
      }
    },
    closeTooltip() {
      this.tooltipIdx = null;
      this.tooltip = false;
    },
    changeRole(data) {
      this.clearSubmittingError();
      this.users[data.id].role = data.option;
    },
    changeSubdivision(data) {
      this.clearSubmittingError();
      this.users[data.id].subdivision = data.option;
      this.users[data.id].role = {};
    },
    transformEmail(email) {
      return email.length > 38
        ? `${email.slice(0, 36)}...`
        : `${email}`;
    },
    async saveUsers() {
      this.clearSubmittingError();
      this.isWaitingUsersSubmit = true;

      const date = new Date();

      this.xmlAddUsersToOrganisationApplication =
        generateXml.AddUsersToOrganisationApplication(this.users, date);

      try {
        await this.documentUpload({
          xml: this.xmlAddUsersToOrganisationApplication,
        });
        this.isModalCheck = true;
        this.modalClose();
        this.users = [];
        this.$emit('load');
      } catch (error) {
        this.processSubmittingError(error);
      } finally {
        this.isWaitingUsersSubmit = false;
      }
    },
    modalClose() {
      this.clearSubmittingError();
      if (this.isModalVisible && !this.isModalCheck) {
        this.isModalCheck = true;
        return;
      }
      this.isModalVisible = false;
      this.isModalCheck = false;
    },
    cancel() {
      this.clearSubmittingError();
      this.isModalVisible = false;
      this.isModalCheck = false;
      this.email = '';
      this.selectedRole = '';
      this.users = [];
    },
    addEmployee() {
      this.clearSubmittingError();

      if (!this.email.length > 0) {
        this.requiredEmailError = true;
        return;
      }
        this.requiredEmailError = false;

      if (!this.selectedSubdivision) {
        this.errorSubdivision = true;
        return;
      }
      this.errorSubdivision = false;

      if (this.selectedRole === '') {
        this.errorRole = true;
        return;
      }
        this.errorRole = false;

      if (this.users.find((it) => it.email === this.email)) {
        this.errorContains = true;
        return;
      }
        this.errorContains = false;

      this.users.push({ email: this.email, role: this.selectedRole, subdivision: this.selectedSubdivision });

      this.$nextTick(() => {
        this.$refs.users.scrollTop = this.$refs.users.scrollHeight;
      });

      this.email = '';
      this.selectedRole = '';
      this.selectedSubdivision = undefined;
    },
    removeEmployee(index) {
      if (this.isWaitingUsersSubmit) {
        return;
      }

      this.clearSubmittingError();
      this.users.splice(index, 1);
    },
    processSubmittingError(error) {
      const errorText = error.message.split(': ')[2];
      if (errorText.includes('permissions')) {
        this.isSubmittingError = true;
        this.submittingError = 'У Вас недостаточно прав для совершения данного действия';
        return;
      }
      this.isSubmittingError = true;
      this.submittingError = errorText;
    },
    clearSubmittingError() {
      this.isSubmittingError = false;
      this.submittingError = '';
    },
    formAttorney() {
      const formAttorney = this.getFormAttorney;

      if (Object.keys(formAttorney).length !== 0) {
        delete formAttorney.steps;
        delete formAttorney.currentStep;

        this.updateFormAttorney(formAttorney);
      }

      this.updateFormAttorney(
        {
          ...formAttorney,
          homeTitle: 'Сотрудники компании',
          homeUrl: '/cabinet/employees',
        },
      );

      this.$router.push('/cabinet/form-attorney');
    },
    changeMode(mode) {
      this.$emit('changeMode', mode);
    },
    async clickAddEmployee() {
      if (!this.isPlatformWorkerForCompany) {
        this.isModalVisible = true;
        return;
      }

      await this.$router.push({
        name: 'cabinet.company-employees.add',
        params: {
          accountId: this.companyAccountId,
          companyName: this.companyName,
        },
      });
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('attorney', ['updateFormAttorney']),
  },
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 12px;
  margin-bottom: 24px;

  &__horizontal-divider {
    display: none;
  }

  &__group-divider {
    width: 1px;
    border-left: 1px solid #c1c4c7;
  }

  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__group-divider {
      display: none;
    }

    &__horizontal-divider {
      display: block;
    }
  }

  &__action1 {
    margin: auto 0;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #C1C4C7;

    @media (max-width: 860px) {
      flex-direction: column;
      gap: 4px;

      .active {
        display: none;
      }
    }

    &__element {
      background: inherit;
      position: relative;
      display: flex;
      gap: 6px;
    }
  }

  &__btn-group {
    display: flex;
    gap: 16px;

    @media (max-width: 860px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  &__add-attorney {
    display: flex;
    gap: 4px;
  }

  &__block-select-attorney {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border: 1px solid #2F82DF;
    border-radius: 24px 2px 2px 24px;

    img {
      height: 16px;
    }
  }

  &__select-attorney {
    width: 160px;
    border: 0;
  }

  &__form-attorney {
    cursor: pointer;
  }
}

.active {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  height: 2px;
  background: #2F82DF;

  &:before {
    position: absolute;
    left: -10px;
    content: " ";
    width: 10px;
    height: 2px;
    background: #2F82DF;
  }

  &:after {
    position: absolute;
    right: -10px;
    content: " ";
    width: 10px;
    height: 2px;
    background: #2F82DF;
  }
}

.active-number {
  color: #2F82DF;
}

.active-text {
  color: #4C4E51;
}

.divider {
  width: 1px;
  border-left: 1px solid #c1c4c7;
}

.modalContent {
  width: 560px;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #4C4E51;
  }

  &__step-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4C4E51;

    span {
      font-weight: bold;
    }
  }

  &__input-group {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    &__input {
      width: 100%;

      &__select {
        position: relative;
        display: inline-block;
        width: 100%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 28px 10px 8px;
        border: 1px solid #DEE2E6;
        cursor: pointer;
        color: #000000;

        &:disabled {
          color: #000000;
        }

        &__option {
          position: absolute;
          right: 0;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 200px;
          width: 790px;
          margin-top: 16px;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
          z-index: 10;

          p {
            color: #4C4E51;
            padding: 4px 16px 0 16px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;

            &:last-child {
              padding-bottom: 4px;
            }

            &:hover {
              background-color: #2F82DF;
              color: #FFFFFF;

              &:first-child {
                border-radius: 9px 0 0 0;
              }

              &:last-child {
                border-radius: 0 0 0 9px;
              }
            }
          }
        }

        &:after {
          content: url('~icons/basic/arrow_down_gray16x10.svg');
          position: absolute;
          right: 10px;
          top: 14px;
          pointer-events: none;
          box-sizing: border-box;
        }
      }
    }
  }

  &__users {
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 185px;
    max-height: 185px;

    &__user {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #4C4E51;
      display: flex;
      gap: 4px;
      margin-bottom: 8px;

      &__left {
        position: relative;
        max-width: 275px;
        min-width: 275px;
        padding: 4px 8px;
        border: 1px solid #E9EBED;
        border-radius: 24px 2px 2px 24px;
        background: #E9EBED;

        &__tooltip {
          position: absolute;
          top: 25px;
          left: 0;
          background-color: #f5f1f1;
          box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
          font-size: 12px;
          padding: 0 6px;
          border-radius: 5px;
          z-index: 1000;
        }
      }

      &__center {
        position: relative;
        font-family: 'Roboto';
        font-style: normal;
        max-width: 120px;
        min-width: 120px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #4C4E51;
        padding: 4px 16px 4px 6px;
        border: 1px solid #2F82DF;
        border-radius: 2px;
        cursor: pointer;

        &__option {
          position: absolute;
          right: 0;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 200px;
          width: 367px;
          margin-top: 7px;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
          z-index: 10;

          p {
            color: #4C4E51;
            padding: 4px 16px 0 16px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;

            &:last-child {
              padding-bottom: 4px;
            }

            &:hover {
              background-color: #2F82DF;
              color: #FFFFFF;

              &:first-child {
                border-radius: 9px 9px 0 0;
              }

              &:last-child {
                border-radius: 0 0 9px 9px;
              }
            }
          }
        }

        &:after {
          content: url('~icons/basic/arrow_down_gray12x8.svg');
          position: absolute;
          right: 4px;
          top: 5px;
          pointer-events: none;
          box-sizing: border-box;
        }
      }

      &__right {
        padding: 4px 6px;
        border: 1px solid #E9EBED;
        border-radius: 2px 24px 24px 2px;
        background: #E9EBED;
        cursor: pointer;
      }

      &__left, &__right {
        height: 40px;
        display: flex;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::-webkit-scrollbar-button {
      background-repeat: no-repeat;
      width: 10px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 0;
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a19f9f;
    }

    &::-webkit-resizer {
      background-repeat: no-repeat;
      width: 7px;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
  }

  &__btn-group {
    text-align: center;
  }
}
</style>
