<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps" />
        <span>Характер нагрузки</span>
      </h2>
    </div>

    <div :class="$style.grid2Column">
      <div>
        <p :class="$style.textHelper">
          <span>Характер нагрузки *</span>
        </p>
        <c-select
            v-model="connectionApplication.connectionCharacteristics.workload"
            :error="errors.workload"
            :options="workloadList"
            role='workload'
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StepCounter from '../components/StepCounter';

export default {
  name: 'Workload',
  components: {
    CSelect: () => import('atoms/common/CSelect'),
    StepCounter,
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDate() {
      return moment().subtract(30, 'years').toDate();
    },
    ...mapGetters('dataLists', ['workloadList']),
  },
};
</script>

<style lang="sass" module>
  .stepContainer
    +questionnaries-container
    +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .textHelper
    +base-text
    color: #141412
    display: flex
    span
      margin-right: 10px

  .noteContainer
    +base-spacing

    p
      +small-spacing
      +small-text

    a
      +base-link

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .textDescription
    p
      +base-text

    a
      +base-link

  .dateLabel
    margin-bottom: 8px
    font-size: 16px
    line-height: 24px
</style>
