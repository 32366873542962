<template>
  <login-template>
    <div :class="$style.container">
      <template v-if="!isSendCodeRequest">
        <input-contact-action
            v-model="contact"
            :error-message="errorMessage"
            :error-code="errorCode"
            :is-enabled="!isSendCodeRequest || timer === 0"
            :is-show-note="true"
            :is-restore="true"
            :is-waiting="isWaitingSend"
            title="Введите адрес электронной почты/ номер телефона на который будет выслан одноразовый пароль"
            role="contact"
            @input="onInput"
            @back="onBack"
        />

        <button :disabled="!isValidContact || isWaitingSend" :class="$style.buttonRestore" @click="sendCode">
          Восстановить пароль
        </button>

        <p v-if="errorMessage.length !== 0" class="errorText" style="text-align: center; margin-top: 8px">{{ errorMessage }}</p>

      </template>
      <div v-if="isSendCodeRequest" :class="$style.code">
        <label>ВВЕДИТЕ ОДНОРАЗОВЫЙ ПАРОЛЬ ИЗ {{ showEnterCodeMessage }}</label>
        <masked-input
            id="user-code"
            v-model="code"
            :class="{
            [$style.codeInput]: true,
            [$style.errorInput]:
              confirmStatus.errorMessage &&
              confirmStatus.errorMessage.length > 0,
          }"
            :mask="[/\d/, /\d/, /\d/, /\d/]"
            :show-mask="true"
            role="code"
        />
        <horizontal-progress-bar v-if="isWaitingConfirm"/>
        <div
            v-if="isShownErrorMessage"
            :class="$style.errorText"
            role="errorText"
        >
          {{ confirmStatus.errorMessage }}
        </div>
        <template v-if="isSendCodeRequest">
          <p v-if="timer > 0" :class="$style.pendingResendText">
            выслать повторно через {{ timer }} СЕКУНД
          </p>
          <p
              v-else-if="timer === 0"
              :class="$style.resendText"
              role="sendCode"
              @click="sendCode"
          >
            выслать повторно
          </p>
        </template>

        <div style="width: 100%; display: flex; flex-direction: column; gap: 16px">
          <v-input
              v-model="password"
              :clearable="true"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              title="Новый пароль"
              type="password"
          />

          <ul :class="$style.passwordError">
            <li v-for="(error, index) in passwordErrors" :key="index">{{ error }}</li>
            <li v-if="passwordErrors.length > 0 && passwordSymbolsError">Пароль не может содержать русские буквы</li>
          </ul>

          <v-input
              v-model="confirmPassword"
              :clearable="true"
              :rules-messages="{required: 'Обязательно для заполнения'}"
              required
              rules="required"
              title="Подтверждение пароля"
              type="password"
          />

          <ul v-if="confirmPassword && password !== confirmPassword"
              :class="$style.passwordError"
          >
            <li>Пароли не совпадают</li>
          </ul>
        </div>

        <button :disabled="passwordErrors.length > 0 || !isValidCode || isWaitingSend" :class="$style.buttonRestore" style="width: 100%;margin-top: 16px;" @click="clickRestorePassword">
          Изменить пароль
        </button>
        <horizontal-progress-bar v-if="isWaitingSend"/>

        <p v-if="errorMessage.length !== 0" class="errorText" style="text-align: center; margin-top: 8px">{{ errorMessage }}</p>

        <button :class="$style.buttonBack" @click="onBack">
          Назад
        </button>
      </div>

    </div>
    <div :class="$style.footer">
      <p :class="$style.offerta">
        Регистрируясь на платформе, вы принимаете условия
        <a href="#">договора-оферты.</a>
      </p>
      <router-link :class="$style.helpLink" to="/help-individual">
        <img
            :class="$style.helpicon"
            alt="icon: help"
            height="24px"
            src="@/assets/icons/help/help.svg"
            width="24px"
        />&nbsp;ПОДДЕРЖКА
      </router-link>
    </div>
    <dialog-template v-model="isShowPolicy" collapse>
      <v-dialog-header subtitle="" title="Пользовательское соглашение и политика конфиденциальности"
                       @close="isShowPolicy = false"/>
      <div :class="$style['privacy-policy']">
        <p :class="$style['privacy-policy-item']">
          <span :class="$style.spanCheckbox"><input v-model="privacyPolicy1" type="checkbox"/></span>&nbsp;
          <span style="color: red">*</span> Я согласен(-на) с <a download="Пользовательское соглашение.docx"
                                                                 href="/policy/user-agreement.docx">пользовательским
          соглашением</a>.
        </p>

        <p :class="$style['privacy-policy-item']">
          <span :class="$style.spanCheckbox"><input v-model="privacyPolicy2" type="checkbox"/></span>&nbsp;
          <span style="color: red">*</span> Я согласен(-на) с <a
            download="Согласие на обработку персональных данных.docx"
            href="/policy/processing-of-personal-data-worker.docx"
            target="_blank">политикой
          конфиденциальности</a>, даю согласие на обработку своих персональных данных и передачу их третьим лицам.
        </p>
        <div v-if="privacyPolicyError" style="margin-top: 5px; color: red">{{ privacyPolicyError }}</div>
      </div>
      <template #footer>
        <horizontal-progress-bar v-if="isPolicySubmit"/>
        <button :class="$style.saveUsers" :disabled="isSubmitDisabled" type="submit" @click="onPolicyApply">
          Сохранить
        </button>
      </template>
    </dialog-template>
    <cookie-notification :visible="cookieVisible" @close="closeCookie"/>
  </login-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MaskedInput from 'vue-text-mask';

import HTTPError from 'lib/utils/errors';
import { isBetaTest } from 'lib/system/env';

import LoginTemplate from 'templates/LoginTemplate';
import { VDialogHeader } from '@/components';

import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import InputContactAction from './components/InputContactAction.vue';
import formatting from './mixins/formatting.js';
import generateXml from 'lib/storage/connection-application/data.js';
import DialogTemplate from 'templates/DialogTemplate.vue';
import CookieNotification from 'components/CookieNotification/CookieNotification';
import { VInput } from 'components';

export default {
  name: 'Login',
  mixins: [formatting],
  components: {
    CookieNotification,
    DialogTemplate,
    LoginTemplate,
    HorizontalProgressBar,
    InputContactAction,
    MaskedInput,
    VDialogHeader,
    VInput,
  },
  created() {
    this.contact = this.$route.params.contact ?? this.getUser?.email ?? this.getUser?.phone ?? '';
    this.isSendCodeRequest = this.getUser?.email || this.getUser?.phone;
  },
  data() {
    return {
      code: '',
      contact: '',
      contactValid: false,
      confirmStatus: {},
      isBetaTest: isBetaTest(),
      isSendCodeRequest: false,
      privacyPolicy1: false,
      privacyPolicy2: false,
      isSubmitDisabled: true,
      isShowPolicy: false,
      privacyPolicyError: null,
      isPolicySubmit: false,
      errorMessage: '',
      errorCode: '',
      timer: 0,
      timerId: 0,
      isWaitingSend: false,
      isWaitingConfirm: false,
      cookieVisible: !JSON.parse(localStorage.getItem('agreedCookie')),
      password: '',
      confirmPassword: '',
      passwordSymbolsError: false,
    };
  },
  computed: {
    formattedContact() {
      if (this.isPhone(this.contact.trim())) {
        return (
            `+7${
                this.contact
                    .trim()
                    .replace(/\D/g, '')
                    .replace(/^8/, '')
                    .replace(/^7/, '')}`
        );
      }
      return this.contact.trim().toLowerCase();
    },
    isShownErrorMessage() {
      const { status } = this.confirmStatus;

      return status && status !== 0;
    },
    showEnterCodeMessage() {
      if (this.isEmail(this.contact)) {
        return 'ПИСЬМА';
      }
      return 'СМС';

    },
    isValidContact() {
      return this.isEmail(this.contact) || this.isPhone(this.contact);
    },
    passwordErrors() {
      const result = [];

      if (this.password.length < 12) {
        result.push('Пароль не менее 12 символов');
      }
      if (!this.password.match(/[A-Z]/)) {
        result.push('Пароль должен включать как минимум одну заглавную букву');
      }
      if (!this.password.match(/[a-z]/)) {
        result.push('Пароль должен включать как минимум одну строчную букву');
      }
      if (!this.password.match(/[0-9]/)) {
        result.push('Пароль должен включать как минимум одну цифру');
      }
      if (!this.password.match(/[!@#$%^&]/)) {
        result.push('Пароль должен включать как минимум один специальный символ (например: !, @, $)');
      }

      return result;
    },
    isValidCode() {
      return this.code.replaceAll('_', '').length === 4;
    },
    ...mapGetters('user', ['getError', 'getAccount', 'getUser', 'isLogged', 'getTokenStatus']),
  },
  watch: {
    privacyPolicy1() {
      this.isSubmitDisabled = !(this.privacyPolicy1 && this.privacyPolicy2);
    },
    privacyPolicy2() {
      this.isSubmitDisabled = !(this.privacyPolicy1 && this.privacyPolicy2);
    },
  },
  methods: {
    closeCookie() {
      this.cookieVisible = false;
    },
    successfulSendCode() {
      this.isSendCodeRequest = true;

      setTimeout(() => {
        const userCode = document.getElementById('user-code');
        if (userCode) {
          userCode.focus();
          userCode.setSelectionRange(0, 0);
        }
      }, 200);

      this.timer = 60;

      if (this.timerId !== 0) {
        clearInterval(this.timerId);
      }

      this.timerId = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(this.timerId);
        }
      }, 1000);

      this.errorMessage = '';
      this.code = '';
    },
    async sendCode() {
      this.errorMessage = '';
      this.isWaitingSend = true;

      try {
        await this.sendRestorePasswordCode({ username: this.formattedContact });

        this.successfulSendCode();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.errorMessage = errorData.message;
      }
      this.isWaitingSend = false;
    },
    async clickRestorePassword() {
      this.errorMessage = '';
      this.isWaitingSend = true;

      try {
        await this.restorePassword({ username: this.formattedContact, code: this.code.replace(RegExp('[ ]?', 'g'), ''), newPassword: this.password });

        this.vueShowNotification('', 'Пароль успешно изменен.');
        this.$router.push({
          name: 'login',
          params: { contact: this.contact },
        });
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.errorMessage = errorData.message;
      }
      this.isWaitingSend = false;
    },
    onInput() {
      this.isSendCodeRequest = false;
      clearInterval(this.timerId);
      this.timer = 0;
    },
    async onPolicyApply() {
      const policy = this.privacyPolicy1 && this.privacyPolicy2;
      if (policy) {
        try {
          const xml = generateXml.UserAgreements({
            agreedWithUserAgreement: this.privacyPolicy1,
            agreedWithPersonalDataAgreement: this.privacyPolicy2,
          });
          await this.documentUpload({ xml: xml });
          this.redirect();
        } catch (e) {
          const errorData = HTTPError.network(e);
          this.privacyPolicyError = errorData.message;
          console.log(e);
        }
      }
    },
    redirect() {
      if (this.isLogged) {
        if (this.$validate.isNull(this.getAccount?.profile)) {
          this.$router.push('/consumer-type');
          return;
        }
        this.$router.push('/cabinet');
      }
    },
    onBack() {
      this.$router.push('/login');
    },
    ...mapActions('user', ['sendRestorePasswordCode', 'restorePassword']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="sass" module>
@import "@/assets/scss/grid.scss";

.saveRoles
  margin-top: 16px
  position: relative
  width: 100%
  padding: 16px
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 24px
  background-color: #2F82DF
  color: #FFF
  text-transform: uppercase

.privacy-policy
  margin-left: 64px
  margin-right: 64px

.spanCheckbox
  height: 16px
  display: inline-flex
  vertical-align: middle
  align-items: center

.privacy-policy-item
  margin-top: 16px

.helpicon
  display: block
  cursor: pointer

.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.footer
  display: flex
  justify-content: space-between
  align-items: flex-end
  -ms-grid-row: 5
  -ms-grid-column: 1

.mosru
  display: flex
  align-items: center
  margin-top: 140px
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: #4F4F4F

  a
    display: flex
    margin-left: 16px

.offerta
  font-family: Roboto
  font-weight: 400
  font-size: 8px
  line-height: 18px
  color: #838383

  a
    text-decoration: underline
    color: #838383

    &:hover
      text-decoration: none

.code
  display: flex
  flex-direction: column
  margin-top: 28px
  align-items: center

  label
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: #4F4F4F

.codeInput
  width: 160px
  height: 40px
  margin-top: 10px
  padding: 8px 0 8px 18px
  text-align: center
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  color: #4F4F4F
  border: 1px solid #C4C4C4
  letter-spacing: 16px
  text-align: center

  &:focus
    border: 1px solid black
    outline: 0

.errorInput
  color: #EB5757
  border-color: #EB5757

  &:focus
    border-color: #EB5757
    outline: 0

.errorText
  margin-top: 12px
  text-align: center
  font-family: Roboto
  font-size: 13px
  line-height: 18px
  color: #EB5757

.pendingResendText
  margin-top: 8px
  text-align: center
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 12px
  line-height: 18px
  color: #D7D7D7
  text-transform: uppercase

.resendText
  margin-top: 8px
  text-align: center
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 12px
  line-height: 18px
  color: #4F4F4F
  text-decoration: underline
  text-transform: uppercase
  cursor: pointer

  &:hover
    text-decoration: none

.helpLink
  display: flex
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 19px
  text-transform: uppercase
  color: #C1C4C7
  align-items: center

.helpLink:hover
  color: #4F4F4F

.back
  width: max-content
  height: 40px
  display: flex
  align-items: center

  svg
    fill: #4C4E51

.buttonRestore
  background-color: #2f82df
  color: #ffffff
  border-radius: 0
  height: 56px
  text-transform: uppercase
  font-family: Roboto Condensed
  font-size: 21px
  font-weight: 500
  line-height: 30px

.buttonRestore:disabled
  background-color: #7e8b91

.passwordError
  color: #EB5757
  list-style-type: disc
  margin-left: 68px

  li
    margin-bottom: 8px

.buttonBack
  position: absolute
  bottom: 0
  width: 100%
  height: 56px
  text-transform: uppercase
  font-size: 21px
  font-family: 'Roboto Condensed', sans-serif
  font-weight: 500
  line-height: 30px
  margin-top: 16px
  background-color: #FFFFFF
  border: 1px solid #C1C4C7
  color: #262729

</style>
