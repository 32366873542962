<template>
  <div class="form-wrp" style="margin: 24px 0 20px 100px;">
    <div class="form">
      <div class="form-header">
        <h1 class="form-header__title">Формирование баланса</h1>
        <p class="form-header__subtitle">За расчётный период</p>
      </div>
      <v-divider />
      <div style="display: flex; gap: 16px">
        <p class="step-title">Выбрать период:</p>
        <v-date-picker
          v-model=currentDate
          format="MMMM | YYYY"
          style="width: 160px;"
          type="month"
        />
      </div>
      <v-divider />
      <div style="display: flex; gap: 24px; flex-wrap: wrap">
        <v-simple-indicator :color="indicators.transmissionContract.color" label="Договор загружен" />
        <v-simple-indicator :color="indicators.measurements.color" label="Показания загружены" />
      </div>
      <v-divider />
      <v-input
        v-model="balanceGroupName"
        title="Наименование балансовой группы"
      />
      <v-divider />
      <selection-points-by-network-sections
          v-if="balanceIndicators?.isTransmissionContractSigned && !isLoading"
          :date="currentDate"
          :selected="selectedIds"
          @updateSelected="updateSelected"
      />
      <v-circle-loader v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { VDatePicker, VDivider, VInput } from 'components';
import dayjs from 'dayjs';
import VSimpleIndicator from 'components/VSimpleIndicator/VSimpleIndicator.vue';
import { mapActions, mapGetters } from 'vuex';
import SelectionPointsByNetworkSections from '@/components/SelectionPointsByNetworkSections/SelectionPointsByNetworkSections';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import { getDraft, setDraft } from 'services/draft';
import { debounce } from 'lodash';

export default {
  name: 'GeneratePlanningBalance',
  components: {
    VInput,
    VSimpleIndicator,
    VDatePicker,
    VDivider,
    SelectionPointsByNetworkSections,
    VCircleLoader,
  },
  async created() {
    await this.loadDraft();
  },
  data() {
    return {
      currentDate: undefined,
      balanceIndicators: undefined,
      balanceGroupName: '',
      isLoading: false,
      selectedIds: {
        networkSectionsIds: [],
        boundariesIds: [],
        usagePointsIds: [],
      },
      updatedSelected: {},
    };
  },
  computed: {
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };

      if (!this.balanceIndicators) {
        return {
          transmissionContract: unknown,
          measurements: unknown,
        };
      }

      const result = {};

      result.transmissionContract = this.balanceIndicators.isTransmissionContractSigned ? check : error;
      result.measurements = this.balanceIndicators.isMeasurementsExists ? check : error;

      return result;
    },
    ...mapGetters('user', ['getAccount', 'getUser']),
  },
  watch: {
    balanceGroupName(val) {
      this.$emit('updateData', { balanceGroupName: val });
      this.saveDraft();
    },
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.isLoading = true;
          this.balanceIndicators = undefined;

          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          const period = { year: date.year(), month: date.month() + 1 };

          try {
            this.balanceIndicators = await this.loadMeasurementsIndicators(period);

            this.$emit('updateData', {
              currentDate: period,
              creationByTransmissionContract: this.balanceIndicators?.creationByTransmissionContract,
              transmissionContractId: this.balanceIndicators?.creationByTransmissionContract.id,
              isMeasurementsExists: this.balanceIndicators?.isMeasurementsExists,
              isTransmissionContractSigned: this.balanceIndicators?.isTransmissionContractSigned,
            });
            this.saveDraft();
          } catch (e) {
            console.log('error loadMeasurementsIndicators', e);
            this.setNotification({ message: 'Нет связи с сервером' });
          } finally {
            this.isLoading = false;
          }
        }
      },
    },
  },
  methods: {
    saveDraft: debounce(function() {
      setDraft(
          this.getAccount.id,
          {
            currentDate: this.currentDate,
            balanceGroupName: this.balanceGroupName,
            selectedIds: {
              networkSectionsIds: this.updatedSelected.networkSectionsIds,
              boundariesIds: this.updatedSelected.boundariesIds,
              usagePointsIds: this.updatedSelected.usagePointsIds,
            },
          },
          `${this.getUser.id}planningBalance`,
      );
    }, 500),
    updateSelected(val) {
      this.updatedSelected = val;
      this.$emit('updateData', this.updatedSelected);
      this.saveDraft();
    },
    async loadDraft() {
      try {
        const draft = await getDraft(`${this.getAccount.id}${this.getUser.id}planningBalance`);

        this.currentDate = draft?.currentDate ? draft.currentDate : dayjs().add(-1, 'month').format('MMMM | YYYY');

        if (!draft) {
          return;
        }

        if (draft.selectedIds) {
          this.selectedIds = draft.selectedIds;
        }

        if (draft.balanceGroupName) {
          this.balanceGroupName = draft.balanceGroupName;
        }
      } catch (error) {
        console.error('Ошибка при загрузке черновика:', error);
      }
    },
    ...mapActions('cabinet', ['loadBalanceIndicators', 'loadMeasurementsIndicators']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  width: 1024px;
  gap: 24px;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  &-wrp {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 850px;
    }

    &__subtitle {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4C4E51;
    }
  }
}

.step-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4C4E51;
}
</style>
