<template>
  <page title="Реестр мероприятий">
    <v-table-new
      v-model="transformedEvents"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="НСИ. Реестр мероприятий"
      @delete="onDelete"
      @edit="onEdit"
      @on-cell-click="cellClickHandler"
    />
    <events-edit
        :modal-action.sync="modalAction"
        :modal-value.sync="modalValue"
        @loadData="loadData"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { fetchEvents } from '@/api/rest/odk/api';
import { AddDirectoryFieldModalActions } from 'components/AddDirectoryFieldModal/consts';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import {
  addYearsToDateFormatted,
  formatDateFromOdkTimestamp,
  formatDateToCustomDateFormat,
} from 'lib/utils/date';
import EventsEdit from 'views/private/OdkAccounting/Events/EventsEdit.vue';

export default {
  name: 'Events',
  components: {
    VTableNew,
    Page,
    EventsEdit,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      events: [],
      AddDirectoryFieldModalActions: AddDirectoryFieldModalActions,
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ] : [];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '50px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'eventsOdk' },
          },
          {
            label: 'Плановая дата начала мероприятия',
            key: 'startDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания мероприятия',
            key: 'endDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата начала выдачи',
            key: 'planExtraditionStartDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания выдачи',
            key: 'planExtraditionEndDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата начала монтажа',
            key: 'planInstallationStartDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания монтажа',
            key: 'planInstallationEndDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата начала включения',
            key: 'planEnableStartDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания включения',
            key: 'planEnableEndDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата начала демонтажа',
            key: 'planDismantlingStartDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания демонтажа',
            key: 'planDismantlingEndDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата начала приёмки на склад',
            key: 'planAcceptanceStartDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Плановая дата окончания приёмки на склад',
            key: 'planAcceptanceEndDate',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            key: 'cloneButton',
            type: 'button',
            thStyle: {
              minWidth: '100px',
            },
          },
          ...editHeaders,
        ],
      };
    },
    transformedEvents() {
      if (!this.events?.length) {
        return [];
      }

      return this.events;
    },
    isAccessUpdate() {
      return filterForAccess('process/3/2', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    cellClickHandler(cell) {
      switch (cell.key) {
        case 'cloneButton': {
          this.onClone(cell.row);
        }
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.events = (await fetchEvents()).map((it) => ({
          ...it,
          holidayId: String(it.holiday.id),
          eventYear: String(it.eventYear),
          startDateRange: [formatDateFromOdkTimestamp(it.startDate), formatDateFromOdkTimestamp(it.endDate)],
          startDate: formatDateFromOdkTimestamp(it.startDate),
          endDate: formatDateFromOdkTimestamp(it.endDate),
          planExtraditionDateRange: [formatDateFromOdkTimestamp(it.planExtraditionStartDate), formatDateFromOdkTimestamp(it.planExtraditionEndDate)],
          planExtraditionStartDate: formatDateFromOdkTimestamp(it.planExtraditionStartDate),
          planExtraditionEndDate: formatDateFromOdkTimestamp(it.planExtraditionEndDate),
          planInstallationDateRange: [formatDateFromOdkTimestamp(it.planInstallationStartDate), formatDateFromOdkTimestamp(it.planInstallationEndDate)],
          planInstallationStartDate: formatDateFromOdkTimestamp(it.planInstallationStartDate),
          planInstallationEndDate: formatDateFromOdkTimestamp(it.planInstallationEndDate),
          planDismantlingDateRange: [formatDateFromOdkTimestamp(it.planDismantlingStartDate), formatDateFromOdkTimestamp(it.planDismantlingEndDate)],
          planDismantlingStartDate: formatDateFromOdkTimestamp(it.planDismantlingStartDate),
          planDismantlingEndDate: formatDateFromOdkTimestamp(it.planDismantlingEndDate),
          planAcceptanceDateRange: [formatDateFromOdkTimestamp(it.planAcceptanceStartDate), formatDateFromOdkTimestamp(it.planAcceptanceEndDate)],
          planAcceptanceStartDate: formatDateFromOdkTimestamp(it.planAcceptanceStartDate),
          planAcceptanceEndDate: formatDateFromOdkTimestamp(it.planAcceptanceEndDate),
          planEnableDateRange: [formatDateFromOdkTimestamp(it.planEnableStartDate), formatDateFromOdkTimestamp(it.planEnableEndDate)],
          planEnableStartDate: formatDateFromOdkTimestamp(it.planEnableStartDate),
          planEnableEndDate: formatDateFromOdkTimestamp(it.planEnableEndDate),
          cloneButton: 'Перенести программу оформления на следующий год',
        }));
      } catch (error) {
        this.setNotification({
          message: 'Нет связи с сервером',
        });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    onClone(row) {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {
        ...row,
        eventYear: String(Number(row.eventYear) + 1),
        startDateRange: row.startDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
        planExtraditionDateRange: row.planExtraditionDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
        planInstallationDateRange: row.planInstallationDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
        planDismantlingDateRange: row.planDismantlingDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
        planAcceptanceDateRange: row.planAcceptanceDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
        planEnableDateRange: row.planEnableDateRange.map((it) => formatDateToCustomDateFormat(addYearsToDateFormatted(it, 1, 'DD.MM.YYYY'), 'DD.MM.YYYY')),
      };
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
