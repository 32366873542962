<template>
  <page title="Справочник трансформаторов тока">
    <v-table-new
      v-model="transformedCurrents"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник трансформаторов тока"
    />
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { Page, VTableNew } from '@/components';

export default {
  name: 'CabinetCurrent',
  components: { VTableNew, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      currents: [],
      loading: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Государственный реестр средств измерений',
            key: 'grsiNumber',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '114px',
            },
          },
          {
            label: 'Наименование',
            key: 'title',
            sort: {},
            filter: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Номинальное напряжение, кВ',
            key: 'ratedVoltage',
            type: 'number',
            sort: { type: 'number' },
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Номинальная частота сети, Гц',
            key: 'ratedNetworkFrequency',
            type: 'number',
            sort: { type: 'number' },
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Номинальный первичный ток, А',
            key: 'ratedCurrentPrimary',
            type: 'number',
            sort: { type: 'number' },
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Номинальный вторичный ток, А',
            key: 'ratedCurrentSecondary',
            type: 'number',
            sort: { type: 'number' },
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Коэффициент трансформации ТТ',
            key: 'transformationRatio',
            sort: {},
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Класс точности',
            key: 'accuracyClass',
            sort: {},
            thStyle: {
              minWidth: '102px',
            },
          },
          {
            label: 'Межповерочный интервал',
            key: 'calibrationInterval',
            type: 'number',
            sort: { type: 'number' },
            thStyle: {
              minWidth: '111px',
            },
          },
        ],
      };
    },
    transformedCurrents() {
      if (this.currents.length === 0) {
        return [];
      }

      return this.currents.map((it) => ({
        id: it.id,
        grsiNumber: it.grsiNumber,
        title: it.title,
        ratedVoltage: it.ratedVoltage,
        ratedNetworkFrequency: it.ratedNetworkFrequency,
        ratedCurrentPrimary: it.ratedCurrentPrimary,
        ratedCurrentSecondary: it.ratedCurrentSecondary,
        transformationRatio: it.transformationRatio,
        accuracyClass: it.accuracyClass,
        calibrationInterval: it.calibrationInterval,
      }));
    },
  },
  methods: {
    async loadData() {
      try {
        this.currents = await this.getCurrentTransformerModels();
      } catch (error) {
        console.log(error);
      }
    },
    ...mapActions('dataLists', ['getCurrentTransformerModels']),
  },
};
</script>
