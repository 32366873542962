import { render, staticRenderFns } from "./DepartmentSelector.vue?vue&type=template&id=241d3531&scoped=true"
import script from "./DepartmentSelector.vue?vue&type=script&lang=js"
export * from "./DepartmentSelector.vue?vue&type=script&lang=js"
import style0 from "./DepartmentSelector.vue?vue&type=style&index=0&id=241d3531&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241d3531",
  null
  
)

export default component.exports