import { getCompletedTag } from 'utils/xml/tools';

export function generateSaveDirectory(directory) {

  let fields = '';

  directory.fields.forEach((item) => {
    fields += '        <Field>\n' +
      `            ${getCompletedTag('dc:FieldId', item.id)}\n` +
      `            <dc:Name>${item.name}</dc:Name>\n` +
      `            <dc:Type>${item.type}</dc:Type>\n` +
      `            <dc:Order>${item.order}</dc:Order>` +
      `            ${getCompletedTag('dc:DefaultValue', item.default)}\n` +
      '        </Field>\n';
  });

  return (
    `${'<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<RequestDirectory\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/directory/1.0"\n' +
    '        xmlns:dc="http://energochain.ru/XML/documents/directory-common/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/directory/1.0 ../xsd/RequestDirectory.xsd">\n' +
    '    <Directory DirectoryKind="LocalClassifier">\n' +
    `        ${getCompletedTag('dc:DirectoryId', directory.id)}\n` +
    `        <dc:Name>${directory.name}</dc:Name>\n` +
    `        <dc:Description>${directory.desc}</dc:Description>\n` +
    '    </Directory>\n' +
    '    <Fields>\n'}${
      fields
    }    </Fields>\n` +
    '<Action>Save</Action>' +
    '</RequestDirectory>\n'
  );
}

export function generateDeleteDirectory(directoryId) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<RequestDirectory\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/directory/1.0"\n' +
    '        xmlns:dc="http://energochain.ru/XML/documents/directory-common/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/directory/1.0 ../xsd/RequestDirectory.xsd">\n' +
    '    <Directory>' +
    `        <dc:DirectoryId>${directoryId}</dc:DirectoryId>\n` +
    '    </Directory>' +
    '<Action>Delete</Action>' +
    '</RequestDirectory>'
  );
}

export function generateSaveOrDeleteDirectoryRecord(record, action) {
  console.log('action', action);
  console.log('rec', record);
  const fieldsList = record?.fields ?? [];
  let fields = '';

  // нужно передать все поля справочника
  fieldsList.forEach((item) => {
    fields += '            <dc:Field>\n' +
      `                <dc:FieldId>${item.id}</dc:FieldId>\n` +
      `                <dc:Value>${record.value[item.id] ?? ''}</dc:Value>\n` +
      '            </dc:Field>\n';
  });

  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<RequestDirectoryRecord\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/directory-record/1.0"\n' +
    '        xmlns:dc="http://energochain.ru/XML/documents/directory-common/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/directory-record/1.0 ../xsd/RequestDirectoryRecord.xsd">\n' +
    '    <DirectoryRecord>\n' +
    `        ${getCompletedTag('dc:RecordId', record.id)}` +
    `        <dc:DirectoryId>${record.directoryId}</dc:DirectoryId>\n` +
    `        ${getCompletedTag('dc:RecordFields', fields)}` +
    '    </DirectoryRecord>\n' +
    `    <Action>${action}</Action>\n` +
    '</RequestDirectoryRecord>\n'
  );
}
