import { XMLParser } from 'fast-xml-parser';
import { booleanFromText } from 'lib/utils/formatXmlType';
import { fullNameToString } from 'utils/user';

function removeLineBreaks(text) {
  return text.replace(/(\r\n|\n|\r)/gm, '');
}

function getTextFromPoint(point) {
  let pointText = '';

  if (Array.isArray(point?.Text?.Paragraph)) {
    pointText += point?.Text?.Paragraph.map((paragraphItem) => {
      const number = paragraphItem.attributes?.number;
      const text = removeLineBreaks(String(paragraphItem['#text']));
      if (number) return `${number}) ${text}`;
      return text;
    }).join('\n\n');
  }


  if (point?.Text?.Paragraph['#text']) {
    pointText += removeLineBreaks(String(point.Text.Paragraph['#text']));
  }

  return pointText.replace(/ +/g, ' ').trim();
}

function toArrayIfNotArray(object) {
  if (!object) return [];
  if (Array.isArray(object)) return object;
  return [object];
}

function getPoints(object) {
  return toArrayIfNotArray(object).map((point) => ({
    number: point.attributes.number,
    points: point.Point ? getPoints(point.Point) : [],
    chapters: point.Chapter ? getChapters(point.Chapter) : [],
    text: getTextFromPoint(point),
    isEditableNow: false,
    changes: 0,
    changingChapterNumber: point.attributes.changingChapterNumber,
    reason: point.attributes.reason,
  }));
}

function getChapters(object) {
  return toArrayIfNotArray(object).map((point) => ({
    number: point.attributes.number,
    points: point.Point ? getPoints(point.Point) : [],
    chapters: point.Chapter ? getChapters(point.Chapter) : [],
    text: getTextFromPoint(point),
    title: point.attributes.title,
    isShow: true,
    isEditableNow: false,
    changes: 0,
    requisitesType: booleanFromText(point.attributes.requisitesType) ?? false,
  }));
}


function generateParagraphsXml(pointInner) {
  let paragraphsStr = '';

  const pointInnerByLineBreakArr = pointInner.split('\n\n');

  pointInnerByLineBreakArr.forEach((paragraph) => {
    paragraphsStr += `
                    <Paragraph>
                      ${paragraph}
                    </Paragraph>`;
  });

  return paragraphsStr;
}

function generatePoints(points) {
  let resStr = '';
  points.filter((it) => !it.isNewPoint).forEach((point) => {

    if (point.chapters?.length) {
      resStr += `<ChangeChapterRedactionPoint number="${point.number}" reason="${point.reason}" changingChapterNumber="${point.changingChapterNumber}">
            <Text>
              ${generateParagraphsXml(point.text)}
            </Text>
            ${generateChapters(point.chapters)}
          </ChangeChapterRedactionPoint>`;
    } else {
      resStr += `<Point number="${point.number}">
            <Text>
              ${generateParagraphsXml(point.text)}
            </Text>
            ${generatePoints(point.points)}
          </Point>`;
    }
  });

  return resStr;
}

function generateChapters(chapters) {
  let resStr = '';
  chapters.forEach((chapter) => {
    resStr += `<Chapter number="${chapter.number}" title="${chapter.title}" requisitesType="${chapter.requisitesType}">
                <Text>
                ${generateParagraphsXml(chapter.text)}
                </Text>
                ${generatePoints(chapter.points)}
            </Chapter>`;
  });
  return resStr;
}

export function generateContractBody(xml) {
  if (xml.isChaptersRoot) return generateChapters(xml.body);
  return generatePoints(xml.body);
}

export function parseContractXml(xml) {
  const parsed = {
    header: null,
    body: null,
  };
  const alwaysArray = [
    'Contract.ContractBody.Chapter.Point',
    'Contract.ContractBody.Chapter.Chapter',
    'Contract.ContractBody.Chapter.ChangeChapterRedactionPoint',
  ];

  const options = {
    ignoreAttributes: false,
    attributeNamePrefix: '',
    attributesGroupName: 'attributes',
    alwaysCreateTextNode: true,
    parseTagValue: true, // default
    alwaysArray: alwaysArray,
    // eslint-disable-next-line no-unused-vars
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (alwaysArray.indexOf(jpath) !== -1) return true;
    },
  };

  const parser = new XMLParser(options);
  const jsonContract = parser.parse(xml);
  parsed.header = getTextFromPoint(jsonContract.Contract.ContractHeader);

  console.log(jsonContract);
  const chapters = toArrayIfNotArray(jsonContract?.Contract?.ContractBody?.Chapter);
  const points = [
    ...toArrayIfNotArray(jsonContract?.Contract?.ContractBody?.ChangeChapterRedactionPoint),
    ...toArrayIfNotArray(jsonContract?.Contract?.ContractBody?.Point)].sort((first, second) => parseInt(first) - parseInt(second));
  if (!chapters ^ !points) throw new Error('Тело договора должно содержать только пункты или только разделы');

  parsed.body = chapters.length ? getChapters(chapters) : getPoints(points);
  parsed.isChaptersRoot = chapters.length > 0;
  console.log(parsed);
  return parsed;
}

export function contractSidesToList(sides) {
  const sidesList = [];
  for (const sideKey in sides) {
    const side = sides[sideKey];
    if (side.company.inn || side.consumer?.snils) {
      sidesList.push({ ...side, contractSide: sideKey });
    }
  }
  return sidesList;
}

export function xmlFromHeaderAndBody(headerAndBody) {
  return `<Contract>
    <ContractHeader>${headerAndBody.contractHeader}</ContractHeader>
    <ContractBody>${headerAndBody.contractBody}</ContractBody>
  </Contract>`;
}

function getSideText(side, number, sidesCount) {
  const delimiter = number === sidesCount - 2 ? ', и ' : ', ';
  return `${getSideCompanyText(side)} ${getRepresentativeText(side.signatory)} ${getSideNumberText(number)}${delimiter}`;
}


function getSideCompanyText(side) {
  return `${side.company.name}, именуемое в дальнейшем ${getSideName(side.contractSide)}, `;
}

function getRepresentativeText(representative) {
  if (!representative) {
    return '';
  }
  return `в лице ${representative.role.nameGenitive ?? representative.role.name} ${fullNameToString(representative.fullNameGenitive ?? representative.fullName)}, ${getReasonDocumentText(representative.reason)}`;
}

function getReasonDocumentText(reason) {
  if (!reason) {
    return '';
  }

  let text = `действующего на основании ${reason.reasonDocumentGenitive ?? reason.reasonDocument}`;
  if (reason.number?.length) {
    text += ` №${reason.number}`;
  }
  if (reason.date?.length) {
    text += ` от ${reason.date}`;
  }
  return `${text}, `;
}

function getSideNumberText(sideNumber) {
  switch (sideNumber) {
    case 0:
      return 'с одной стороны';
    case 2:
      return 'с третьей стороны';
    default:
      return 'с другой стороны';
  }
}

function getSideName(contractSide) {
  switch (contractSide) {
    case 'SUPPLIER':
      return 'Заказчик';
    case 'PERFORMER':
      return 'Исполнитель';
    case 'PERFORMER_FIRST':
      return 'Исполнитель-1';
    case 'PERFORMER_SECOND':
      return 'Исполнитель-2';
    default:
      return 'Сторона';
  }
}

function headerContractOrAddition(sourceContractInfo) {
  if (sourceContractInfo) {
    return `настоящее дополнительное соглашение к договору №${sourceContractInfo.number} от ${sourceContractInfo.date}`;
  }
  return 'настоящий договор';
}

export function formHeaderBySides(sides, sourceContractInfo) {
  const sidesList = contractSidesToList(sides);
  const text = `${sidesList.map((it, idx) => getSideText(it, idx, sidesList.length)).join('')} заключили ${headerContractOrAddition(sourceContractInfo)} о нижеследующем:`;

  return `
          <Text>
            <Paragraph>
                ${text}
            </Paragraph>
          </Text>
        `;
}
