<template>
  <div>
    <inner-page :current-step="0"
              :steps="steps"
              style="position: relative"
              home="Справки о полезном отпуске"
              @back="goBack"
              @goHome="goBack"
  >
    <div class="generate-useful-vacation-certificate">
      <template v-if="isRetailer">
        <div class="generate-useful-vacation-certificate__title">
          <span>Сетевая компания, для которой загружаются справки о полезном отпуске</span>
        </div>
        <div style="position: relative; margin-top: 24px">
          <v-label title="Выбрать сетевую компанию *" :is-value="false">
          <select v-model="utilityInfo" role="Utility" class="generate-useful-vacation-certificate__selector">
            <option v-for="(utility, index) in utilities" :key="index" :value="utility">
              {{ utility.name }}
            </option>
          </select>
          </v-label>
        </div>

        <v-divider />
      </template>
      <div class="generate-useful-vacation-certificate__title">
        <span>Внесение данных справки о полезном отпуске</span>
      </div>
      <div class="generate-useful-vacation-certificate__parameters">
        <v-date-picker
            v-model="currentDate"
            class="generate-useful-vacation-certificate__parameters__item"
            format="MMMM | YYYY"
            type="month"
            is-disabled-future-dates
            title="Расчётный период"
        />
      </div>

      <v-divider />

<!--      <template v-if="isDisplayDocumentScan">
        <div class="generate-useful-vacation-certificate__title">
          <span>Загрузите подтверждающий документ</span>
        </div>
        <div>
          <c-uploading
              v-model="documentsScan"
              :error="documentsScanError"
              role="documentsScan"
          />

          <div v-if="documentsScan.length">
            <file-list-preview :list="documentsScan" action
                               @delete="handleRemoveDocumentsScan"/>
          </div>
        </div>

        <v-divider />
      </template>-->

      <selection-points-by-network-sections-from-department
          v-if="currentDate"
          :date="currentDate"
          is-display-addition-kind-filter
          :addition-kinds="[{ value: 'DELIVERY_POINT', label: 'Точки поставки' }]"
          :company-inn="utilityInfo?.inn"
          grouping-by-departments
          @updateSelected="updateSelected"
      />


    </div>

  </inner-page>
    <div class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button
            :disabled="isWaitingSubmit"
            @click="goBack">
          Отменить
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button
            :disabled="!valid || isWaitingSubmit"
            class="btn-save"
            @click="generateUsefulVacationCertificates"
        >
          Сформировать справку
        </button>

      </div>

    </div>
    <v-circle-loader v-if="isWaitingSubmit" style="position: fixed;" />
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { InnerPage, VDatePicker } from '@/components';
import dayjs from 'dayjs';
import VDivider from 'components/VDivider/VDivider.vue';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import SelectionPointsByNetworkSectionsFromDepartment from '@/components/SelectionPointsByNetworkSections/SelectionPointsByNetworkSectionsFromDepartment';

export default {
  components: {
    VDivider,
    InnerPage,
    VDatePicker,
    VCircleLoader,
    SelectionPointsByNetworkSectionsFromDepartment,
    VLabel: () => import('@/components/Common/VLabel/VLabel'),
  },
  async created() {
    if (this.isRetailer) {
      const suppliers = await this.setSuppliers();
      this.utilities = suppliers.UTILITY.filter((it) => it.onPlatform);
    }
  },
  data() {
    return {
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      steps: [
        { label: 'Сформировать справку по полезному отпуску по участкам сети', url: '/cabinet/useful-vacation-certificate/generate' },
      ],
      isWaitingSubmit: false,
      updatedSelected: {},
      utilityInfo: {},
      utilities: [],
    };
  },
  computed: {
    valid() {
      return !this.isWaitingSubmit &&
          this.updatedSelected?.departmentsIds?.length > 0 &&
          this.updatedSelected?.usagePointsIds?.length > 0 &&
          (!this.isRetailer || this.utilityInfo?.id);
    },
    isRetailer() {
      return this.getAccount.kind === 'RETAILER' || this.getAccount.kind === 'SUPPLIER_OF_LAST_RESORT';
    },
    supplierId() {
      return this.isRetailer ? this.utilityInfo?.id : this.getAccount.profile?.id;
    },
    ...mapGetters('user', ['getAccount']),
  },
  methods: {
    goBack() {
      this.$router.push('/cabinet/useful-vacation-certificate');
    },
    updateSelected(val) {
      this.updatedSelected = val;
    },
    async generateUsefulVacationCertificates() {
      this.isWaitingSubmit = true;

      try {
        const params = {
          usagePointIds: this.updatedSelected.usagePointsIds,
          utilitySupplierId: this.supplierId,
        };

        const date = dayjs(this.currentDate, 'MMMM | YYYY');
        params.accountingPeriod = { year: date.year(), month: date.month() + 1 };

        if (this.updatedSelected.departmentsIds?.length === 1) {
          params.departmentId = this.updatedSelected.departmentsIds[0];
        }

        await this.generateUsefulVacationForDepartment({
          ...params,
        });

        this.vueShowNotification(
            'Справка о полезном отпуске успешно сформирована.',
        );
        this.goBack();
      } catch (error) {
        console.log('generateUsefulVacationForDepartment', error);
        this.setNotification({ message: 'Ошибка генерации Справки о полезном отпуске' });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('user', ['setNotification']),
    ...mapActions('dataLists', ['setSuppliers']),
    ...mapActions('document', ['generateUsefulVacationForDepartment']),
  },
};
</script>

<style lang="scss" scoped>
.generate-useful-vacation-certificate {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 58px;

  &__title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
  }

  &__error-container {

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #000;
      margin: 48px 64px 16px;
    }

    &__text {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: red;
      margin-left: 64px;
      margin-right: 64px;
    }
  }

  &__indicators {
    display: flex;
    justify-content: center;

    margin: 24px 0;

    div {
      margin-right: 8px;
    }
  }

  &__parameters {
    display: flex;
    gap: 16px;

    &__item {
      width: 276px;
      margin-top: 24px;
    }

    &__checkbox {
      font-family: Roboto;
      font-size: 14px !important;
      line-height: 16px;
      color: #4C4E51;
      width: 264px;
      border-radius: 16px;
      background: #F5F6F6;
      padding: 0 12px 0 12px;
    }
  }

  &__checkbox-wrp {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: flex-start;

    &__checkbox {
      min-width: 24px;
      min-height: 24px;
      cursor: pointer
    }

    &__label {
      margin-left: 12px;
      cursor: pointer
    }
  }

  &__acts-kind {
    display: flex;
    justify-content: flex-start;
    position: relative;
    gap: 16px;

    &__selector {
      width: 272px;
      height: 40px;
      padding-left: 12px;

      background: inherit;
      border: 1px solid #DEE2E6;
      border-radius: 2px;
      color: #000000;

      &:disabled {
        color: #000000;
      }
    }

    &__selector-tooltip {
      &__text {
        width: 320px;
      }
    }
  }

  &__selector {
    width: 272px;
    height: 40px;
    padding-left: 12px;

    background: inherit;
    border: 1px solid #DEE2E6;
    border-radius: 2px;
    color: #000000;

    &:disabled {
      color: #000000;
    }
  }
}

.action-buttons {
  width: 100%;
  position: absolute;
  bottom: 12px;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  button {
    height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }

    &:disabled {
      background: #717d8f;
    }
  }

  &__action {
    &--left {
      text-align: left;
      margin-left: 300px;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 46px;
      }
    }
  }
}
</style>
