import { apolloClient } from '@/api/graphql/index.js';
import { GET_USAGE_POINTS_BY_BALANCE_BOUNDARY_IDS } from '@/api/graphql/queries.js';
import { GENERATE_BALANCE_BY_USAGE_POINTS } from '@/api/graphql/mutations';

const setCurrentDate = ({ commit }, { date }) => {
  commit('SET_CURRENT_DATE', { date });
};

const setBalanceIndicators = ({ commit }, { balanceIndicators }) => {
  commit('SET_BALANCE_INDICATORS', { balanceIndicators });
};


const setBalanceGroupName = ({ commit }, payload) => {
  commit('SET_BALANCE_GROUP_NAME', payload);
};

const setSidesRepresentatives = ({ commit }, { sidesRepresentatives }) => {
  commit('SET_SIDES_REPRESENTATIVES', { sidesRepresentatives });
};

const setNetworkSections = ({ commit }, { networkSections }) => {
  commit('SET_NETWORK_SECTIONS', { networkSections });
};

const setSelectedNetworkSectionsIds = ({ commit }, { selectedNetworkSectionsIds }) => {
  commit('SET_SELECTED_NETWORK_SECTIONS_IDS', { selectedNetworkSectionsIds });
};

const setUsagePoints = ({ commit }, { usagePoints }) => {
  commit('SET_USAGE_POINTS', { usagePoints });
};


const setSelectedUsagePointIds = ({ commit }, { selectedUsagePointsIds }) => {
  commit('SET_SELECTED_USAGE_POINT_IDS', { selectedUsagePointsIds });
};

const setBoundariesWithSubject = ({ commit }, { boundariesWithSubject }) => {
  commit('SET_BOUNDARIES_WITH_SUBJECT', { boundariesWithSubject });
};

const setSelectedBoundariesWithSubjectsIds = ({ commit }, { selectedBoundariesWithSubjectsIds }) => {
  commit('SET_SELECTED_BOUNDARIES_WITH_SUBJECTS_IDS', { selectedBoundariesWithSubjectsIds });
};
// const setBalanceBoundaryObjectsIds = ({ commit }, { bordersBoundaryIds }) => {
//   commit('SET_BALANCE_BOUNDARY_OBJECTS_IDS', { bordersBoundaryIds });
// };
const removeBoundaryBySubjectId = ({ commit }, { id }) => {
  commit('REMOVE_BOUNDARY_BY_SUBJECT_ID', { id });
};
const addUsagePointId = ({ commit }, { usagePointId }) => {
  commit('ADD_USAGE_POINT_ID', { usagePointId });
};

const addBalanceBoundaryId = ({ commit }, { bordersBoundaryId }) => {
  commit('ADD_BALANCE_BOUNDARY_ID', { bordersBoundaryId });
};

const deleteUsagePointId = ({ commit }, { usagePointId }) => {
  commit('DELETE_USAGE_POINT_ID', { usagePointId });
};

const deleteBalanceBoundaryId = ({ commit }, { bordersBoundaryId }) => {
  commit('DELETE_BALANCE_BOUNDARY_ID', { bordersBoundaryId });
};

async function loadUsagePointsByBalanceBoundaryIds({ rootState, commit }, payload) {
  try {
    console.log('start load BalanceBoundaries', rootState.user.token);
    const { data: { usagePointsByBalanceBoundaryIdsAndDepartmentIds } } = await apolloClient.query({
      query: GET_USAGE_POINTS_BY_BALANCE_BOUNDARY_IDS, fetchPolicy: 'no-cache', variables: {
        ...payload,
      }, context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loaded UsagePointsByBalanceBoundaryIds', usagePointsByBalanceBoundaryIdsAndDepartmentIds);
    return usagePointsByBalanceBoundaryIdsAndDepartmentIds;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loading UsagePointsByBalanceBoundaryIds::catch', error);
    throw error;
  }
}
async function generateBalanceByUsagePoints({ rootState, commit }, payload) {
  try {
    const variables = {
      usagePointIds: payload.usagePointIds,
      accountingPeriod: payload.accountingPeriod,
      totalLoss: payload.totalLoss,
      supplierRepresentative: payload.supplierRepresentative,
      performerFirstRepresentative: payload.performerFirstRepresentative,
      performerSecondRepresentative: payload.performerSecondRepresentative,
      boundaryName: payload.boundaryName,
      transmissionContractId: payload.transmissionContractId,
    };
    const { data } = await apolloClient.mutate({
      mutation: GENERATE_BALANCE_BY_USAGE_POINTS,
      variables: variables,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('errors', error.graphQLErrors);
      },
    });
    console.log('generate balance by usagePoints', data);
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('error generate balance by usagePoints::catch', error);
    throw error;
  }
}

export default {
  setCurrentDate,
  setBalanceIndicators,
  setSelectedNetworkSectionsIds,
  generateBalanceByUsagePoints,
  setUsagePoints,
  setNetworkSections,
  removeBoundaryBySubjectId,
  setBalanceGroupName,
  setSidesRepresentatives,
  // setBalanceBoundaryObjectsIds,
  setBoundariesWithSubject,
  setSelectedBoundariesWithSubjectsIds,
  setSelectedUsagePointIds,
  addUsagePointId,
  addBalanceBoundaryId,
  deleteUsagePointId,
  deleteBalanceBoundaryId,
  loadUsagePointsByBalanceBoundaryIds,
};
