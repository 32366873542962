<template>
  <v-table-new
    v-model="transformedLeftOverWarehouseAxp"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="leftOverWarehouseAxp.pageOut"
    :template="template"
    excel-report-file-name="Складские остатки. АХП по складам"
    server-side-sorting
    table-name="leftOverWarehouseAxpElements"
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from 'components';
import { fetchData, fetchWarehouse } from '@/api/rest/odk/api';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'WarehouseAxpLetfoverTable',
  components: { VTableNew },
  data() {
    return {
      leftOverWarehouseAxp: [],
      loading: false,
      pagination: initialPagination,
      warehouses: [],
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    template() {
      const headers = [];
      headers.push(
        {
          label: '№ П/П',
          key: 'index',
          type: 'index',
          size: 'sm',
          thStyle: {
            minWidth: '60px',
          },
        },
        {
          label: 'АХП',
          key: 'axpName',
          size: 'md',
          thStyle: {
            minWidth: '83px',
          },
          sort: {},
          filter: { type: 'axpOdk' },
        },
        {
          label: 'Всего АХП',
          key: 'axpTotal',
          thStyle: {
            whiteSpace: 'nowrap',
          },
          sort: {},
        },
      );

      const warehouseSet = new Set();
      this.transformedLeftOverWarehouseAxp.forEach((item) => {
        (item.warehousesWithQuantity || []).forEach((it) => {
          warehouseSet.add(it.warehouseName);
        });
      });
      warehouseSet.forEach((warehouseName) => {
        headers.push({
          label: `${warehouseName}`,
          key: `warehouse_${warehouseName}`,
          thStyle: {
            whiteSpace: 'nowrap',
          },
        });
      });
      headers.push({
        label: 'Сформировать плановую накладную',
        key: 'actionButton',
        size: 'md',
        type: 'selectButton',
        thStyle: {
          minWidth: '80px',
        },
        customCheck: (item) => !item.actionButton.length,
      });
      return { headers };
    },
    transformedLeftOverWarehouseAxp() {
      if (!this.leftOverWarehouseAxp || !this.leftOverWarehouseAxp.data) {
        return [];
      }
      return this.leftOverWarehouseAxp.data.map(this.transformed);
    },
  },
  methods: {
    transformed(item, index) {
      const warehousesWithQuantity = item.warehousesWithQuantity?.reduce((acc, it) => {
        const key = `warehouse_${it.warehouseName}`;
        acc[key] = it.quantity;
        return acc;
      }, {});

      return {
        ...item,
        index: index + 1,
        axpName: item.axpName,
        axpTotal: item.axpTotal,
        ...warehousesWithQuantity,
        actionButton: this.warehouses?.map((it) => ({ id: it.name,
          title: it.name,
          method: this.formPlanConsignment({
            axpId: item.axpId,
            axpName: item.axpName,
            warehouseId: it.id,
            warehouseName: it.name,
          }) }
        )) ?? [],
      };
    },
    formPlanConsignment(params) {
      return () => this.$router.push({
        name: 'cabinet.process.5.1',
        params,
      });
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const response = await fetchData('axp-by-warehouses', 1, this.leftOverWarehouseAxp.pageOut.totalElements, sortField, sort, filters);

        return response.data?.map(this.transformed);
      } catch (error) {
        console.log('leftOverWarehouseAxp', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = this.filter;
        const { field: sortField, type: sort  } = this.sort;
        const { page, size } = this.pagination;
        const response = await fetchData('axp-by-warehouses', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: response.page,
          totalElements: response.total,
          totalPages: response.last_page,
        };
        this.leftOverWarehouseAxp = response;
        this.leftOverWarehouseAxp.pageOut = this.pagination;
        if (!this.warehouses?.length) this.warehouses = await fetchWarehouse();
      } catch (error) {
        console.log('leftOverWarehouseAxp', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
