<template>
  <page title="ОДК отчёты">
    <v-table-new v-model="reportsWithAccess" :loading="loading" :template="template" excel-report-file-name="Отчёты ОДК"
                 @on-cell-click="onCellClickHandler"/>
    <modal
      v-if="isDisplaySelectDialog"
      :header-style="{padding: '16px 16px 16px 24px'}"
      :modal-style="{
          borderRadius: '16px',
          padding: '6px 0 36px 0',
          position: 'relative',
        }"
      width="592px"
      @close="closeDialog">
      <template #body>
        <v-circle-loader v-if="loadingReport"/>
        <events-autocomplete
          ref="event"
          v-model="eventId"
          :is-disabled="loadingReport"
          is-required
          title="Выберите мероприятие"
        />
        <p v-if="loadingReportError.length > 0" class="errorText">{{ loadingReportError }}</p>
        <div style="width: 100%; display: flex; justify-content: end">
          <v-button :disabled="eventId === null || eventId.length === 0 || loadingReport"
                    @click="saveSummaryReport">
            Сформировать
          </v-button>
        </div>
      </template>
    </modal>
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import {
  saveDismantlingReport,
  saveExploitationReport,
  saveInspectionsTotalReport,
  saveInstallationReport,
  saveInstallationReportByAdministrativeDistricts,
  saveLightPercentReport,
  saveLocalRepairByAdministrativeDistrictsAndContractorsExploitationReport,
  saveLocalRepairByAdministrativeDistrictsExploitationReport,
  saveLocalRepairByAdreskaExploitationReport,
  saveSummaryIssuanceReport,
  saveSummaryReceptionReport,
} from 'views/private/OdkReports/reports/reports';
import Modal from '@/components/Modal/Modal.vue';
import VButton from '@/components/VButton/VButton';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import { mapActions, mapGetters } from 'vuex';
import { filterForAccess } from 'lib/utils/permissions';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  name: 'OdkReports',
  components: {
    Page,
    VTableNew,
    Modal,
    VButton,
    VCircleLoader,
    EventsAutocomplete,
  },
  data() {
    return {
      reports: [
        {
          name: 'Свод выдачи',
          click: this.summaryIssuanceReport,
          id: 'extradite',
        },
        {
          name: 'Свод приёма',
          click: this.summaryReceptionReport,
          id: 'acceptance',
        },
        {
          name: 'Свод монтажа по административным округам',
          click: this.installationReportByAdministrativeDistricts,
          id: 'installation-districts',
        },
        {
          name: 'Свод монтажа по подрядчикам',
          click: this.installationReport,
          id: 'installation-contractors',
        },
        {
          name: 'Информация по демонтажу по подрядчикам',
          click: this.dismantlingReport,
          id: 'dismantling',
        },
        {
          name: 'Замечания в работе ОДК по подрядчикам',
          click: this.exploitationReport,
          id: 'defects-contractors',
        },
        {
          name: 'Замечания в работе ОДК по административным округам',
          click: this.localRepairByAdministrativeDistricts,
          id: 'defects-districts',
        },
        {
          name: 'Замечания в работе ОДК по подрядчикам и административным округам',
          click: this.localRepairByAdministrativeDistrictsAndContractors,
          id: 'defects-contractors-districts',
        },
        {
          name: 'Горение ОДК по административным округам',
          click: this.lightPercentReport,
          id: 'lighting',
        },
        {
          name: 'Замечания в работе ОДК по списку адресов',
          click: this.localRepairByAdreskaReport,
          id: 'defects-adreska',
        },
        {
          name: 'Контроль регламентного состояния ОДК',
          click: this.inspectionsTotalReport,
          id: 'control',
        },
      ],
      loading: false,
      loadingReport: false,
      loadingReportError: '',
      isDisplaySelectDialog: false,
      reportType: '',
      reportName: '',
      eventId: '',
      eventIdSearch: '',
      events: [],
    };
  },
  computed: {
    reportsWithAccess() {
      return this.reports.filter((it) => filterForAccess(`odk-reports/${it.id}`, this.getAuthorization?.blockAccess));
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            size: 'lg',
          },
          {
            key: 'followLink',
            type: 'followLink',
            size: 'sm',
          },
        ],
      };
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    async onCellClickHandler(cellClick) {
      if (cellClick.key === 'followLink') {
        this.loading = true;
        try {
          await Promise.resolve(cellClick.row.click());
        } finally {
          this.loading = false;
        }
      }
    },
    navigateToReport(link) {
      this.$router.push(`/cabinet/odk-reports/${link}`);
    },
    closeDialog() {
      if (this.loadingReport) {
        return;
      }
      this.isDisplaySelectDialog = false;
    },
    summaryIssuanceReport() {
      this.reportType = 'SUMMARY_ISSUANCE_REPORT';
      this.reportName = 'Свод выдачи';
      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    summaryReceptionReport() {
      this.reportType = 'SUMMARY_RECEPTION_REPORT';
      this.reportName = 'Свод приёма';
      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    installationReportByAdministrativeDistricts() {
      this.reportType = 'INSTALLATION_REPORT_BY_ADMINISTRATIVE_DISTRICTS';
      this.reportName = 'Свод монтажа по административным округам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    installationReport() {
      this.reportType = 'INSTALLATION_REPORT';
      this.reportName = 'Свод монтажа по подрядчикам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    localRepairByAdministrativeDistricts() {
      this.reportType = 'LOCAL_REPAIR_BY_ADMINISTRATIVE_DISTRICTS';
      this.reportName = 'Замечания в работе ОДК по административным округам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    localRepairByAdministrativeDistrictsAndContractors() {
      this.reportType = 'LOCAL_REPAIR_BY_ADMINISTRATIVE_DISTRICTS_AND_CONTRACTORS';
      this.reportName = 'Замечания в работе ОДК по подрядчикам и административным округам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    lightPercentReport() {
      this.reportType = 'LIGHT_PERCENT_REPORT';
      this.reportName = 'Горение ОДК по административным округам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    localRepairByAdreskaReport() {
      this.reportType = 'LOCAL_REPAIR_BY_ADRESKA';
      this.reportName = 'Замечания в работе ОДК по списку адресов';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    dismantlingReport() {
      this.reportType = 'DISMANTLING_REPORT';
      this.reportName = 'Информация по демонтажу по подрядчикам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    exploitationReport() {
      this.reportType = 'EXPLOITATION_REPORT';
      this.reportName = 'Замечания в работе ОДК по подрядчикам';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    inspectionsTotalReport() {
      this.reportType = 'INSPECTIONS_TOTAL';
      this.reportName = 'Контроль регламентного состояния ОДК';

      this.eventId = '';
      this.isDisplaySelectDialog = true;
    },
    async saveReport(buffer) {
      const blob = new Blob([buffer]);
      try {
        await this.saveReportToServer({ file: blob, fileName: `${this.reportName}.xlsx` });
      } catch (e) {
        console.error('Не удалось сохранить отчёт');
        console.error(e);
      }
    },
    async saveSummaryReport() {
      try {
        if (this.reportType === 'SUMMARY_ISSUANCE_REPORT') {
          this.loadingReport = true;
          const report = await saveSummaryIssuanceReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
          return;
        }

        if (this.reportType === 'SUMMARY_RECEPTION_REPORT') {
          this.loadingReport = true;
          const report = await saveSummaryReceptionReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
          return;
        }

        if (this.reportType === 'DISMANTLING_REPORT') {
          this.loadingReport = true;
          const report = await saveDismantlingReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
          return;
        }

        if (this.reportType === 'EXPLOITATION_REPORT') {
          this.loadingReport = true;
          const report = saveExploitationReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }


        if (this.reportType === 'INSTALLATION_REPORT_BY_ADMINISTRATIVE_DISTRICTS') {
          this.loadingReport = true;
          const report = await saveInstallationReportByAdministrativeDistricts(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }

        if (this.reportType === 'INSTALLATION_REPORT') {
          this.loadingReport = true;
          const report = await saveInstallationReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }


        if (this.reportType === 'LOCAL_REPAIR_BY_ADMINISTRATIVE_DISTRICTS') {
          this.loadingReport = true;
          const report = await saveLocalRepairByAdministrativeDistrictsExploitationReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }


        if (this.reportType === 'LOCAL_REPAIR_BY_ADMINISTRATIVE_DISTRICTS_AND_CONTRACTORS') {
          this.loadingReport = true;
          const report = await saveLocalRepairByAdministrativeDistrictsAndContractorsExploitationReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }

        if (this.reportType === 'LIGHT_PERCENT_REPORT') {
          this.loadingReport = true;
          const report = await saveLightPercentReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }

        if (this.reportType === 'LOCAL_REPAIR_BY_ADRESKA') {
          this.loadingReport = true;
          const report = await saveLocalRepairByAdreskaExploitationReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }
        if (this.reportType === 'INSPECTIONS_TOTAL') {
          this.loadingReport = true;
          const report = await saveInspectionsTotalReport(this.eventId);
          await this.saveReport(report);
          this.loadingReport = false;
          this.isDisplaySelectDialog = false;
        }


      } catch (e) {
        console.log(e);
        await this.setNotification({ message: e.message });
      } finally {
        this.loadingReport = false;
        this.isDisplaySelectDialog = false;
      }
    },
    ...mapActions('user', ['setNotification']),
    ...mapActions('document', ['saveReportToServer']),
  },
};
</script>
