<template>
  <Process2/>
</template>


<script>
import Process2 from 'components/Processes/Process5/Process1.vue';

export default {
  name: 'CreatePlanConsignment',
  components: { Process2 },
};
</script>


<style lang="scss" scoped>

</style>
