<template>
  <login-template>
    <div :class="$style.container">
      <div :class="$style.content">
        <div :class="$style.infoGroup">
          <p>Авторизация по КЭП не была пройдена.</p>
          <p>Пожалуйста зайдите в настройки УТМ-клиента в профиль, вставьте токен в ЭВМ и авторизуйтесь по КЭП.</p>
        </div>
        <submit-task-details style="margin-top: 32px" title="проверить авторизацию" @submit="confirmAuth"/>
        <div style="margin-top: 100px">
          <router-link to="/help-individual" :class="$style.helpLink">
            <img src="@/assets/icons/help/help.svg" alt="icon: help" width="24px" height="24px"
                 :class="$style.helpicon">
          </router-link>
        </div>
      </div>
    </div>
  </login-template>
</template>

<script>
import LoginTemplate from 'templates/LoginTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: 'CheckAuth',
  components: {
    SubmitTaskDetails,
    LoginTemplate,
  },
  created() {
    this.confirmAuth();
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'isLogged']),
  },
  methods: {
    async confirmAuth() {
      try {
        await this.confirmUserWithUtm({});
        if (this.isLogged) {
          await this.$router.push('/cabinet');
        }
      } catch (error) {
        console.error(error);
      }
    },
    ...mapActions('user', ['confirmUserWithUtm']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.content
  display: flex
  flex-direction: column
  justify-content: space-between

.helpLink
  float: right
  max-width: 24px
  max-height: 24px

.helpicon
  max-width: 24px
  max-height: 24px
  display: block

.infoGroup
  p
    +base-title
</style>
