<template>
  <custom-date-picker
    v-model="internalValue" :allowed-dates="allowedDates"
    :is-required="isRequired"
    :label="label">
    <template #buttons style="display: contents">
      <v-btn @click="setToday">Сегодня</v-btn>
      <v-btn @click="setYesterday">Вчера</v-btn>
    </template>
  </custom-date-picker>
</template>
<script>


import CustomDatePicker from 'components/Processes/components/CustomDatePicker.vue';
import moment from 'moment/moment';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';

export default {
  components: { CustomDatePicker },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    allowFuture: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: null,
    };
  },
  computed: {
    allowedDates() {
      if (this.allowFuture) return;
      return (val) => val <= new Date().toISOString().substring(0, 10);
    },
  },
  watch: {
    internalValue: {
      immediate: true,
      handler() {
        this.$emit('input', this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        if (this.value == null) this.setToday();
        else this.internalValue = this.value;
      },
    },
  },
  methods: {
    setToday() {
      this.internalValue = formatDateToOdkTimestamp(formatDateFromOdkTimestamp(moment().unix()));
    },
    setYesterday() {
      this.internalValue = formatDateToOdkTimestamp(formatDateFromOdkTimestamp(moment().subtract(1, 'days').unix()));
    },
  },
};
</script>
