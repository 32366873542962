import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const addErrorsToState = (state) => {
  const keyify = (obj, prefix = '') => Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], `${prefix + el}.`)];
    }
    return [...res, prefix + el];
  }, []);

  const keys = keyify(state);

  const errors = {};

  keys.forEach((key) => {
    errors[key] = {
      isError: false,
      errorText: '',
      role: key,
    };
  });

  // флаги ошибок для массивов
  Object.keys(state).forEach((key) => {
    if (Array.isArray(state[key])) {
      errors[key] = {
        isError: false,
        errorText: '',
        role: key,
      };
    }
  });

  errors.filledAttachment1 = {
    isError: false,
    errorText: '',
    role: 'filledAttachment1',
  };

  errors.filledDocuments = {
    isError: false,
    errorText: '',
    role: 'filledDocuments',
  };

  return errors;
};

const initEditableSides = () => {
  const sidesName = ['SUPPLIER', 'PERFORMER', 'PERFORMER_FIRST', 'PERFORMER_SECOND'];
  const editableSides = {};
  sidesName.forEach((name) => {
    editableSides[name] = {
      company: {
        bankRequisites: [{
          bankName: null,
          bik: null,
          correspondentAccount: null,
          settlementAccount: null,
        }],
        selectedBankRequisites: {
          bankName: '',
          bik: '',
          correspondentAccount: '',
          settlementAccount: '',
          label: '',
          value: '',
        },
        inn: null,
        kpp: null,
        legalAddress: null,
        name: null,
        ogrn: null,
        okpo: null,
        oktmo: null,
        okved: null,
        postAddress: null,
        sideName: null,
      },
      signatory: {
        fullName: {
          surname: '',
          firstName: '',
          familyName: '',
        },
        reason: {
          id: null,
          reasonDocument: null,
          number: null,
          date: null,
        },
        role: {
          id: null,
          name: null,
        },
        userId: null,
        accountId: null,
      },
      signatoryVerificationAct: {
        fullName: {
          surname: '',
          firstName: '',
          familyName: '',
        },
        reason: {
          id: null,
          reasonDocument: null,
          number: null,
          date: null,
        },
        role: {
          id: null,
          name: null,
        },
        userId: null,
        accountId: null,
      },
    };
  });

  return editableSides;
};

function initAccountId() {
  const accountData = localStorage.getItem('account');

  if (accountData === null) {
    return '';
  }

  return JSON.parse(accountData).id;
}

export const initState = () => {
  const state = {
    action: null,
    type: null,
    contractType: null,
    contractNumber: null,
    sourceContractId: null,
    sourceContractNumber: null,
    additionalAgreementType: null,
    sourceContractDate: null,
    createdDate: null,
    conclusionDate: null,
    effectiveDate: null,
    expiresDate: null,
    prolongation: false,
    region: null,
    igk: null,
    advancePaymentDate: null,
    mainPaymentDate: null,
    initiator: null,
    contractSides: null,
    // редактируемые данные участников договора
    editableSides: null,
    selectedTemplate: null,
    editedSelectedTemplateXml: null,
    finallyContractXml: null,
    userEmployeesContractMembers: [],
    userEmployees: null,
    scanContract: [],
    paidLosses: false,
    paidIndividualRates: false,
    paidBoilerTariff: false,
    contractLastChange: [],
    rejectedContracts: [],
    attachments: [],
    isInit: false,
    accountId: initAccountId(),
    currentStep: 0,
    draftId: undefined,
    updated: false,
    editMode: false,
    paymentDates: [],
    usagePoints: [],
  };

  state.editableSides = initEditableSides();
  state.errors = addErrorsToState({ ...state });
  return state;
};

const state = initState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
