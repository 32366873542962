<template>
  <div>
    <v-table-new
      v-model="transformedData"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="data.pageOut"
      :template="template"
      excel-report-file-name="Складские операции. Проекты накладных"
      server-side-sorting
      table-name="consignment"
      @on-sort="handleSort"
      @on-cell-click="onCellClick"
    />
    <v-app class="vuetify-styles" data-app style="width: 100%">
      <consignment v-model="consignmentOpened" :consignment="consignment" @updated="loadData"/>
    </v-app>
    <modal
      v-if="archiveModal"
      :body-style="{
      padding: '24px 0',
    }"
      :header-style="{
      display: 'flex',
      justifyContent: 'center'
    }"
      :is-backdrop="true"
      :is-btn-close-visible="false"
      :modal-style="{
      borderRadius: '16px',
      padding: '50px 100px',
    }"
      class="delete-modal">
      <template #header>
        <span class="delete-modal__title">Подтвердите изменение статуса накладной</span>
      </template>
      <template #body>
        <div style="text-align: center">
          <span>Накладная будет {{ archiveModalDirection ? 'переведена в архив' : 'убрана из архива' }}</span>
        </div>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-around;">
          <v-button
            variant="outlined-red"
            @click="setArchive"
          >
            Подтвердить
          </v-button>
          <v-button
            variant="outlined"
            @click="closeModal"
          >
            Отменить
          </v-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal, VButton, VTableNew } from '@/components';
import { fetchData, setPlanConsignmentArchive } from '@/api/rest/odk/api';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';
import { mapFilters } from 'lib/utils/table-pagination';
import { BASE_URL } from '@/constants/api';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import { consignmentTypesMap, consignmentTypesValueList } from 'components/Processes/list';
import { mapGetters } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'OdkConsignmentTable',
  components: {
    Modal, VButton,
    Consignment,
    VTableNew,
  },
  data() {
    return {
      data: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      filterEnd: {},
      archiveModal: false,
      archiveModalDirection: false,
      archiveModalId: null,
      sort: {
        field: null,
        type: null,
      },
      consignmentOpened: false,
      consignment: {},
    };
  },
  computed: {
    transformedData() {
      if (!this.data || !this.data.data || !Array.isArray(this.data.data)) {
        return [];
      }
      return this.data.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Номер',
            key: 'uniqueNumber',
            sort: {},
            filter: { },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Тип',
            key: 'type',
            sort: {},
            filter: { type: 'vSearchSelectValue', values: consignmentTypesValueList },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Дата',
            key: 'date',
            sort: {},
            filter: { type: 'period' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Сотрудник',
            key: 'fullName',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Склад',
            key: 'warehouseName',
            sort: {},
            filter: { type: 'warehousesOdk' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'АХП',
            key: 'axpName',
            sort: {},
            filter: { type: 'axpOdk' },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Мероприятие',
            key: 'eventName',
            sort: {},
            filter: { type: 'eventsOdk' },
            thStyle: {
              minWidth: '100px',
            },
          }, {
            label: 'Программа оформления',
            key: 'adreskaName',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          }, {
            label: 'Подрядчик',
            key: 'contractorName',
            sort: {},
            filter: this.contractorFilter,
            thStyle: {
              minWidth: '83px',
            },
          }, {
            label: 'Договор',
            key: 'contract',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '83px',
            },
          }, {
            key: 'archiveButton',
            type: 'button',
            isDisplayInExcel: true,
            filter: {
              type: 'checkbox',
              label: 'Отображать архив',
            },
            thStyle: {
              minWidth: '83px',
            },
          }, {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),
  },
  methods: {
    async transformFilter() {

      const filterMapping = {
        contractorName: {
          name: 'contractorName',
        },
        fullName: {
          name: 'fullName',
        },
        warehouseName: {
          name: 'warehouseName',
        },
        eventName: {
          name: 'eventName',
        },
        adreskaName: {
          name: 'adreskaName',
        },
        type: {
          name: 'type',
        },
        axpName: {
          name: 'axpName',
        },
        uniqueNumber: {
          name: 'uniqueNumber',
        },
        archiveButton: {
          name: 'withArchive',
        },
        date: {
          name: 'fromDate',
          mapping: formatDateToOdkTimestamp,
        },
      };

      const filterEndMapping = {
        date: {
          name: 'toDate',
          mapping: formatDateToOdkTimestamp,
        },
        contractorName: {
          name: 'contractorId',
        },
        warehouseName: {
          name: 'warehouseId',
        },
        eventName: {
          name: 'eventId',
        },
        axpName: {
          name: 'axpId',
        },
        type: {
          name: 'typeLabel',
        },
      };

      const mappedFilters = await mapFilters(
        filterMapping,
        this.filter,
        filterEndMapping,
        this.filterEnd,
      );
      if (!this.isBoiler) {
        mappedFilters.contractorId = this.getAccount.supplier.id;
      }

      delete mappedFilters.typeLabel;
      return mappedFilters;
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('plan-consignments', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.data = responseData;
        this.data.pageOut = this.pagination;
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('plan-consignments', 1, this.data.pageOut.totalElements, sortField, sort, filters);

        return responseData.data
            ?.map(this.transformed)
            ?.map((item) => ({
              ...item,
              archiveButton: item.archive ? 'архивная' : 'не архивная',
            }));
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter, filterEnd) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      this.filterEnd = { ...filterEnd };
      await this.loadData();
    },
    async onCellClick(click) {
      if (click.key === 'archiveButton') {
        await this.openArchiveModal(click.row.idField, !click.row.archive);
      }
      if (click.key === 'followLink') {
        await this.openConsignment(click.row.idField, click.row.typeId);
      }
    },
    async openConsignment(consignmentId, consignmentType) {
      await this.fetchConsignment(consignmentId, consignmentType);
      this.consignmentOpened = true;
    },
    async openArchiveModal(consigmentId, archive) {
      this.archiveModal = true;
      this.archiveModalDirection = archive;
      this.archiveModalId = consigmentId;
    },
    closeModal() {
      this.archiveModal = false;
    },
    async setArchive() {
      this.loading = true;
      try {
        await setPlanConsignmentArchive(this.archiveModalId, this.archiveModalDirection);
        await this.loadData();
        this.closeModal();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchConsignment(consignmentId) {
      this.loading = true;
      try {
        const response = await fetch(
          `${BASE_URL}/plan-consignment/${consignmentId}`,
        );
        const result = await response.json();
        this.consignment = {
          uniqueNumber: result.info.uniqueNumber,
          warehouseName: result.info.warehouseName,
          eventName: result.info.eventName,
          adreskaName: result.info.adreskaName,
          contract: result.info.contract,
          contractorId: result.info.contractorId,
          contractorName: result.info.contractorName,
          date: formatDateFromOdkTimestamp(result.info.date),
          employee: result.info.fullName,
          axpName: result.info.axpName,
          type: consignmentTypesMap[result.info.type],
          originalType: result.info.type,
          plan: true,
          elements: result.elements.map((el) => ({
            elementName: el.elementName,
            unitName: el.unitName,
            count: el.countElement,
            factCount: el.factCountElement,
            photos: [],
          })),
        };
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchReport() {
      const filters = await this.transformFilter();
      const { field: sortField, type: sort } = this.sort;
      return await fetchData('plan-consignments', 1, this.data.total, sortField, sort, filters);
    },

    transformed(it) {
      return {
        idField: it.id,
        typeId: it.type,
        type: consignmentTypesMap[it.type],
        date: formatDateFromOdkTimestamp(it.date),
        fullName: it.fullName,
        warehouseName: it.warehouseName,
        eventName: it.eventName,
        uniqueNumber: it.uniqueNumber,
        adreskaName: it.adreskaName,
        contractorName: it.contractorName,
        axpName: it.axpName,
        contract: it.contract,
        archive: it.archive,
        archiveButton: it.archive ? 'Убрать из архива' : 'В архив',
      };
    },
  },
};
</script>

<style scoped>

</style>
