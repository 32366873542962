<template>
  <page title="Список запрещенных паролей">
    <div class="container">
      <v-table-new
        v-model="transformedPasswords"
        :action-block-items="actionBlockItems"
        :loading="loading"
        :on-filter-change="onFilterChange"
        :on-load-all-values="loadAllFiltered"
        :on-pagination-change="onPaginationChange"
        :page-out="prohibitedPasswords.pageOut"
        :template="template"
        excel-report-file-name="Список запрещенных паролей"
        @delete="onDelete"
        @edit="onEdit"
      />
      <modal
        v-if="isDisplayAddDialog"
        :body-style="{
          padding: '24px 0',
        }"
        :header-style="{
          display: 'flex',
          justifyContent: 'center',
        }"
        :is-backdrop="true"
        :is-btn-close-visible="false"
        :modal-style="{
          borderRadius: '16px',
          padding: '24px',
        }"
        class="delete-modal">
        <template #header>
          <span v-if="passwordId.length === 0 && deletePasswordId.length === 0">Добавление записи</span>
          <span v-else-if="passwordId.length !== 0">Редактирование записи</span>
          <span v-else-if="deletePasswordId.length !== 0">Удаление записи</span>
        </template>
        <template #body>
          <div class="directory__fields">
            <v-input
              v-model="field"
              :disabled="deletePasswordId.length !== 0"
            />
          </div>
        </template>
        <template #footer>
          <horizontal-progress-bar v-if="isWaitingSubmit" class="directory__progress-bar"/>
          <div v-if="submitError.length > 0" class="errorText">
            {{ submitError }}
          </div>
          <div style="display: flex; justify-content: space-around;">
            <v-button
              variant="outlined"
              @click="onCancelAction"
            >
              Отменить
            </v-button>
            <v-button
              :variant="deletePasswordId.length !== 0 ? 'outlined-red' : undefined"
              @click="onActionRecord"
            >
              <span v-if="passwordId.length === 0 && deletePasswordId.length === 0">Добавить</span>
              <span v-else-if="passwordId.length !== 0">Сохранить</span>
              <span v-else-if="deletePasswordId.length !== 0">Удалить</span>
            </v-button>
          </div>
        </template>
      </modal>
    </div>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { Modal, VButton, VInput, VTableNew } from 'components';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import HTTPError from 'lib/utils/errors';
import {
  addProhibitedPassword,
  deleteProhibitedPassword,
  updateProhibitedPassword,
} from 'views/private/ProhibitedPassword/xml';
import Page from 'components/Page/Page.vue';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  components: {
    Page,
    VTableNew,
    Modal,
    VButton,
    VInput,
    HorizontalProgressBar,
  },
  name: 'ForbiddenPasswords',
  data() {
    return {
      loading: false,
      prohibitedPasswords: {},
      field: '',
      isWaitingSubmit: false,
      submitError: '',
      isDisplayAddDialog: false,
      actionBlockItems: [
        {
          id: 1,
          title: 'Добавить пароль',
          method: this.addRecord,
        },
      ],
      pagination: initialPagination,
      filter: {},
      passwordId: '',
      editRecordId: '',
      deletePasswordId: '',
    };
  },
  computed: {
    passwords() {
      return this.prohibitedPasswords.list ?? [];
    },
    transformedPasswords() {
      if (this.passwords.length === 0) return [];
      return this.passwords.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '80px',
            },
          },
          {
            label: 'Пароль',
            key: 'value',
            sort: {},
            filter: {},
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
  },
  methods: {
    transformed(it) {
      return {
        ...it,
      };
    },
    async loadData() {
      try {
        this.loading = true;
        const prohibitedPasswords = await this.getProhibitedPasswordPage({
          filter: this.filter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
          sortedBy: { direction: 'ASC', field: 'VALUE' },
        });

        this.prohibitedPasswords = prohibitedPasswords;
        return prohibitedPasswords;
      } catch (error) {
        console.log('getProhibitedPasswordPage', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const prohibitedPasswords = await this.getProhibitedPasswordPage({
          filter: this.filter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.prohibitedPasswords.pageOut.totalElements,
          },
          sortedBy: { direction: 'ASC', field: 'VALUE' },
        });
        if (!prohibitedPasswords?.passwords?.list) {
          return [];
        }

        return prohibitedPasswords.passwords.list.map(this.transformed);
      } catch (error) {
        console.log('getProhibitedPasswordPage', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    onEdit(cell) {
      this.submitError = '';
      this.passwordId = cell.row.id;
      this.editRecordId = this.passwordId;
      this.deletePasswordId = '';
      this.field = cell.row.value;
      this.isDisplayAddDialog = true;
    },
    onDelete(cell) {
      this.submitError = '';
      this.deletePasswordId = cell.row.id;
      this.passwordId = '';
      this.field = cell.row.value;
      this.isDisplayAddDialog = true;
    },
    onCancelAction() {
      this.isDisplayAddDialog = false;
      this.field = '';
      this.submitError = '';
    },
    dropVariables() {
      this.field = '';
      this.passwordId = '';
      this.editRecordId = '';
      this.deletePasswordId = '';
    },
    async onEditPassword() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      const xml = updateProhibitedPassword(this.passwordId, this.field);
      try {
        await this.documentUpload({ xml: xml });

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      } finally {
        this.dropVariables();
        this.isWaitingSubmit = false;
        this.isDisplayAddDialog = false;
        this.loadData();
      }
    },
    async onDeleteRecord() {
      this.isWaitingSubmit = true;
      this.submitError = '';

      const xml = deleteProhibitedPassword(this.deletePasswordId);

      try {
        await this.documentUpload({ xml: xml });

      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      } finally {
        this.dropVariables();
        this.isWaitingSubmit = false;
        this.isDisplayAddDialog = false;
        this.loadData();
      }
    },
    onActionRecord() {
      if (this.deletePasswordId.length !== 0) {
        this.onDeleteRecord();
      } else if (this.editRecordId.length !== 0) {
        this.onEditPassword();
      } else {
        this.onAddRecord();
      }
    },
    async onAddRecord() {
      this.isWaitingSubmit = true;
      this.submitError = '';

      const xml = addProhibitedPassword(this.field);
      try {
        await this.documentUpload({ xml: xml });

      } catch (error) {
        const errorMessage = error.message.split(' : ')[1];
        await this.setNotification({ message: errorMessage });
        this.submitError = errorMessage;
        this.isWaitingSubmit = false;
      } finally {
        this.dropVariables();
        this.isWaitingSubmit = false;
        this.isDisplayAddDialog = false;
        this.loadData();
        this.field = '';
      }
    },
    addRecord() {
      this.submitError = '';
      this.passwordId = '';
      this.deletePasswordId = '';

      this.isDisplayAddDialog = true;
    },
    ...mapActions('cabinet', ['getProhibitedPasswordPage']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
