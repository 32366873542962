<template>
  <div>
    <inner-page :current-step="0"
                :steps="steps"
                home="Акты снятия показаний приборов учёта и акты по отпуску и приему электроэнергии по границе балансовой принадлежности"
                style="position: relative"
                @back="goBack"
                @goHome="goBack"
    >
      <div class="generate-integral-act">
        <div style="display: flex; gap: 24px; flex-wrap: wrap">
          <v-simple-indicator :color="indicators.transmissionContract.color" label="Договор загружен"/>
          <v-simple-indicator :color="indicators.measurements.color" label="Показания загружены"/>
        </div>

        <v-divider/>

        <div class="generate-integral-act__title">
          <span>Параметры формирования актов</span>
        </div>
        <div class="generate-integral-act__parameters">
          <select v-model="actsKind"
                  class="generate-integral-act__parameters__item generate-integral-act__acts-kind__selector">
            <option v-for="(act, index) in actsKindList" :key="index" :value="act.value">
              {{ act.label }}
            </option>
          </select>
          <v-date-picker
            v-model="currentDate"
            class="generate-integral-act__parameters__item"
            format="MMMM | YYYY"
            type="month"
          />
          <c-checkbox
            v-model="oneSignature"
            class="generate-integral-act__parameters__checkbox"
            input-style="width: 18px"
            label="Подписывается одной стороной"
            label-style="font-size: 14px; line-height: 16px; margin-left: 5px"/>
        </div>

        <div v-if="actsKind==='BORDER_DEVICE_ACT' || actsKind === 'BORDER_BOTH_ACTS'"
             class="generate-integral-act__acts-kind">
          <select v-model="balanceRule"
                  class="generate-integral-act__acts-kind__selector">
            <option v-for="(rule, index) in balanceRuleList" :key="index" :value="rule.value">
              {{ rule.label }}
            </option>
          </select>
          <tooltip-helper class="generate-integral-act__acts-kind__selector-tooltip" offset="6px" placement="left">
            <div class="generate-integral-act__acts-kind__selector-tooltip__text">
              <p>{{ balanceRuleTooltip }}</p>
            </div>
          </tooltip-helper>

          <c-checkbox
              v-model="ownConsumptionEnabled"
              class="generate-integral-act__parameters__checkbox"
              input-style="width: 18px"
              label="Собственное потребление"
              label-style="font-size: 14px; line-height: 16px; margin-left: 5px"/>
        </div>
        <div v-if="(actsKind==='BORDER_DEVICE_ACT' || actsKind === 'BORDER_BOTH_ACTS') && ownConsumptionEnabled"
             class="generate-integral-act__acts-kind">
          <v-input v-model="ownConsumption.vn" label="Собственное потребление ВН" type="number"/>
          <v-input v-model="ownConsumption.sn1" label="Собственное потребление СН-1" type="number"/>
          <v-input v-model="ownConsumption.sn2" label="Собственное потребление СН-2" type="number"/>
          <v-input v-model="ownConsumption.nn" label="Собственное потребление НН" type="number"/>

        </div>
        <v-divider/>

        <document-side-select-or-input
          :companies="firstSelectorCompanies"
          :contract-side="{id:'SIDE_SECOND', name: 'Сторона-2'}"
          :editable-company="!currentCompanyFirst"
          :enable-signatory="currentCompanyFirst||!oneSignature"
          :init-company="firstSelectorInitCompany"
          :init-department="firstDepartment"
          :init-employee="firstCompanySignatory"
          only-main-requisites
          @company="updateFirstCompany"
          @department="updateFirstDepartment"
          @employee="updateFirstSignatory"
        />

        <document-side-select-or-input
          v-if="secondSelectorCompanies.length > 0"
          :companies="secondSelectorCompanies"
          :contract-side="{id:'SIDE_THIRD', name: 'Сторона-3'}"
          :editable="currentCompanyFirst"
          :enable-signatory="!currentCompanyFirst||!oneSignature"
          :init-company="secondSelectorInitCompany"
          :init-department="secondDepartment"
          :init-employee="secondCompanySignatory"
          only-main-requisites
          @company="updateSecondCompany"
          @department="updateSecondDepartment"
          @employee="updateSecondSignatory"
        />
        <v-spoiler v-else summary="Сторона 3">
          <div style="margin-top: 40px">
            <v-select :options="[]" disabled placeholder="Акты сформированы по всем участкам сети" title="Компания"/>
          </div>
        </v-spoiler>

        <selection-points-by-network-sections
          v-if="actIndicators?.isTransmissionContractSigned && (actsKind==='BORDER_DEVICE_ACT' || actsKind ==='BORDER_BOTH_ACTS')"
          :addition-kinds="additionKindsByBalanceRule"
          :date="currentDate"
          :second-company-id="secondCompany?.id"
          is-display-addition-kind-filter
          @bordersLoaded="bordersLoaded"
          @updateSelected="updateSelected"
        />

        <v-spoiler v-if="availableActs.length>0 && actsKind==='BORDER_FLOW_ACT'"
                   summary="Интегральные акты">
          <div v-for="act in availableActs" :key="act.id">
            <div class="generate-integral-act__checkbox-wrp">
              <input :checked="borderDeviceActId===act.id"
                     class="generate-integral-act__checkbox-wrp__checkbox"
                     type="checkbox"
                     @change="actSelected(act.id)"
              />
              <div style="display:flex; flex-direction:column;gap: 6px">
                <div class="generate-integral-act__checkbox-wrp__label"
                     @click="actSelected(act.id)">{{ act.title }}
                </div>
                <div v-for="border in act.balanceBoundaries" :key="border.id"
                     class="generate-integral-act__checkbox-wrp__label"
                     @click="actSelected(act.id)">{{ formatting.formatBoundaryName(border) }}
                </div>
              </div>
            </div>
            <v-divider/>
          </div>

        </v-spoiler>

        <horizontal-progress-bar v-if="isWaitingSubmit"/>

        <dialog-template v-model="isShowError" center class="generate-integral-act__error-container">
          <template>
            <p class="generate-integral-act__error-container__title">При формировании актов возникла ошибка</p>
            <p class="generate-integral-act__error-container__text" style="margin-bottom: 32px">{{ submitError }}</p>
            <submit-task-details class="errorsSubmit" title="Закрыть" @submit="isShowError = false"/>
          </template>
          <horizontal-progress-bar v-if="isWaitingSubmit && submitError.length === 0"/>
        </dialog-template>

      </div>

    </inner-page>
    <div class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button
          :disabled="isWaitingSubmit"
          @click="goBack">
          Отменить
        </button>
        <button :disabled="isWaitingSubmit"
                style="margin-left: 8px"
                @click="changeCompanies">
          Поменять стороны местами
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button
          :disabled="!valid || isWaitingSubmit"
          class="btn-save"
          @click="createIntegralActsClick"
        >
          Сформировать акты
        </button>

      </div>

    </div>
    <v-circle-loader v-if="isWaitingSubmit" style="position: fixed; z-index: 2"/>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { InnerPage, VDatePicker, VInput, VSelect, VSpoiler } from '@/components';
import DialogTemplate from 'templates/DialogTemplate.vue';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import HTTPError from 'lib/utils/errors';
import dayjs from 'dayjs';
import { getDateAsForceISOString } from 'lib/utils/date';
import DocumentSideSelectOrInput from 'components/DocumentSideSelectOrInput/DocumentSideSelectOrInput.vue';
import CCheckbox from 'atoms/common/CCheckbox.vue';
import VDivider from 'components/VDivider/VDivider.vue';
import formatting from 'lib/utils/formatting';
import VSimpleIndicator from 'components/VSimpleIndicator/VSimpleIndicator.vue';
import SelectionPointsByNetworkSections
  from '@/components/SelectionPointsByNetworkSections/SelectionPointsByNetworkSections';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';

export default {
  components: {
    VDivider,
    InnerPage,
    CCheckbox,
    DocumentSideSelectOrInput,
    VSpoiler,
    VDatePicker,
    VInput,
    HorizontalProgressBar,
    DialogTemplate,
    SubmitTaskDetails,
    VSelect,
    VSimpleIndicator,
    SelectionPointsByNetworkSections,
    VCircleLoader,
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
  },
  created() {
    this.loadBoundariesAndActs();
  },
  data() {
    return {
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      steps: [
        { label: 'Сформировать интегральные и сводные акты', url: '/cabinet/generate-integral-acts' },
      ],
      borderId: '',
      borders: [],
      actsKind: 'BORDER_DEVICE_ACT',
      balanceRule: 'MODULE',
      actsKindList: [
        {
          label: 'АСППУ',
          value: 'BORDER_DEVICE_ACT',
        },
        {
          label: 'АПОПЭЭ',
          value: 'BORDER_FLOW_ACT',
        },
        {
          label: 'АСППУ и АПОПЭЭ',
          value: 'BORDER_BOTH_ACTS',
        },
      ],
      balanceRuleList: [
        {
          label: 'В зависимости от сальдо',
          value: 'MODULE',
        },
        {
          label: 'Приём - Отдача',
          value: 'INPUT',
        },
        {
          label: 'Отдача - Приём',
          value: 'OUTPUT',
        },
        {
          label: 'Без сальдирования. Приём',
          value: 'INPUT_WITHOUT_SALDO',
        },
        {
          label: 'Без сальдирования. Передача',
          value: 'OUTPUT_WITHOUT_SALDO',
        },
        {
          label: 'Без сальдирования. Приём, передача',
          value: 'INPUT_&_OUTPUT_WITHOUT_SALDO',
        },
      ],
      boundaries: [],
      companies: [],
      borderDeviceActId: null,
      integralActs: [],
      isShowError: false,
      submitError: '',
      isWaitingSubmit: false,
      actIndicators: undefined,
      currentCompanyFirst: true,
      firstCompany: null,
      secondCompany: null,
      firstCompanySignatory: null,
      secondCompanySignatory: null,
      firstDepartment: null,
      secondDepartment: null,
      oneSignature: false,
      ownConsumptionEnabled: false,
      ownConsumption: {
        vn: null,
        sn1: null,
        sn2: null,
        nn: null,
      },
      updatedSelected: {},
    };
  },
  computed: {
    formatting() {
      return formatting;
    },
    valid() {
      const validFirst = this.checkRepresentativeInput(this.firstCompanySignatory) || (!this.currentCompanyFirst && this.oneSignature);
      const validSecond = this.checkRepresentativeInput(this.secondCompanySignatory) || (this.currentCompanyFirst && this.oneSignature);
      const ownConsumptionValidity = !this.ownConsumptionEnabled || this.ownConsumption.nn?.length  || this.ownConsumption.vn?.length || this.ownConsumption.sn1?.length || this.ownConsumption.sn2?.length;

      const borderDeviceValidity = (this.actsKind !== 'BORDER_DEVICE_ACT' && this.actsKind !== 'BORDER_BOTH_ACTS') || (this.updatedSelected.usagePointsIds?.length > 0 && ownConsumptionValidity);
      const borderFlowValidity = this.actsKind !== 'BORDER_FLOW_ACT' || this.availableActs.some((it) => it.id === this.borderDeviceActId);
      const other = !this.isWaitingSubmit && this.actIndicators !== undefined && this.validIndicators;

      return validFirst && validSecond && borderDeviceValidity && borderFlowValidity && other ;
    },
    availableActs() {
      return this.integralActs.filter((it) => (
        it.sideSecond.inn === this.firstCompany?.inn && it.sideThird.inn === this.secondCompany?.inn &&
        it.sideSecond.departmentId === this.firstDepartment?.id && it.sideThird.departmentId === this.secondDepartment?.id
      )).map((it) => ({
        ...it,
      }));
    },
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.actIndicators) {
        return {
          transmissionContract: unknown,
          measurements: unknown,
        };
      }

      const result = {};

      result.transmissionContract = this.actIndicators.isTransmissionContractSigned ? check : error;
      result.measurements = this.actIndicators.isMeasurementsInputTransitExists ? check : error;

      return result;
    },
    validIndicators() {
      return !this.indicators.transmissionContract.isError && !this.indicators.measurements.isError;
    },
    isTechnicalActsAvailable() {
      if (this.actsKind === 'BORDER_DEVICE_ACT' || this.actsKind === 'BORDER_BOTH_ACTS') return this.borders.some((it) => it.firstCompany.inn === it.secondCompany.inn);
      return this.integralActs.some((it) => it.sideSecond.inn === it.sideThird.inn);
    },
    companiesWithCurrent() {
      const companies = [this.getAccount.profile];
      if (this.actsKind === 'BORDER_DEVICE_ACT' || this.actsKind === 'BORDER_BOTH_ACTS') {
        this.borders.forEach((it) => {
          if (!companies.some((company) => company.inn === it.firstCompany.inn)) companies.push(it.firstCompany);
          if (!companies.some((company) => company.inn === it.secondCompany.inn)) companies.push(it.secondCompany);
        });
      } else {
        this.integralActs.forEach((it) => {
          if (!companies.some((company) => company.inn === it.sideSecond?.companyInfo?.inn) && it.sideSecond.companyInfo) companies.push(it.sideSecond.companyInfo);
          if (!companies.some((company) => company.inn === it.sideThird?.companyInfo?.inn) && it.sideThird.companyInfo) companies.push(it.sideThird.companyInfo);
        });
      }
      return companies;
    },
    companiesWithoutCurrent() {
      return this.companiesWithCurrent.filter((it) => it.inn !== this.getAccount.profile.inn);
    },
    firstSelectorCompanies() {
      if (this.currentCompanyFirst || this.isTechnicalActsAvailable) return this.companiesWithCurrent;
      return this.companiesWithoutCurrent;
    },
    secondSelectorCompanies() {
      if (!this.currentCompanyFirst || this.isTechnicalActsAvailable) return this.companiesWithCurrent;
      return this.companiesWithoutCurrent;
    },
    firstSelectorInitCompany() {
      if (this.firstCompany) {
        return this.firstCompany;
      }
      if (this.currentCompanyFirst) {
        return this.getAccount.profile;
      }
      return null;
    },
    secondSelectorInitCompany() {
      if (this.secondCompany) return this.secondCompany;
      if (!this.currentCompanyFirst) return this.getAccount.profile;
      return null;
    },
    selectedDate() {
      const date = dayjs(this.currentDate, 'MMMM | YYYY');
      return { year: date.year(), month: date.month() + 1 };
    },
    balanceRuleTooltip() {
      if (this.balanceRule === 'MODULE') {
        return 'При расчёте баланса применяется формула "приём - отдача" либо "отдача-приём" (по одним и тем же приборам учёта) в зависимости от того, по какому сальдо будет "+" и записывается в "Отпуск в сеть" либо в "Транзит" соответственно.';
      }

      if (this.balanceRule === 'INPUT') {
        return 'При расчёте баланса всегда применяется формула "приём - отдача" (по одним и тем же приборам учёта) и записывается в "Отпуск в сеть".';
      }

      if (this.balanceRule === 'OUTPUT') {
        return 'При расчёте баланса всегда применяется формула "отдача - приём" (по одним и тем же приборам учёта) и записывается в "Транзит".';
      }

      if (this.balanceRule === 'INPUT_WITHOUT_SALDO') {
        return 'При расчёте баланса записывается в "отпуск в сеть"';
      }

      if (this.balanceRule === 'OUTPUT_WITHOUT_SALDO') {
        return 'При расчёте баланса записывается в "транзит"';
      }

      if (this.balanceRule === 'INPUT_&_OUTPUT_WITHOUT_SALDO') {
        return 'При расчёте баланса приём записывается в "отпуск в сеть", отдача в "транзит"';
      }

      return '';
    },
    balanceRuleByValue() {
      if (this.balanceRule === 'MODULE' || this.balanceRule === 'INPUT' || this.balanceRule === 'OUTPUT') {
        return this.balanceRule;
      }

      return 'WITHOUT_SALDO';
    },
    additionKindsByBalanceRule() {
      if (this.balanceRule === 'MODULE' || this.balanceRule === 'INPUT' || this.balanceRule === 'OUTPUT' ||
          this.balanceRule === 'INPUT_&_OUTPUT_WITHOUT_SALDO') {
        return [
          {
            label: 'Точки приёма',
            value: 'RECEIVING_POINT',
          },
          {
            label: 'Точки передачи',
            value: 'TRANSFER_POINT',
          },
        ];
      }

      if (this.balanceRule === 'INPUT_WITHOUT_SALDO') {
        return [
          {
            label: 'Точки приёма',
            value: 'RECEIVING_POINT',
          },
        ];
      }

      if (this.balanceRule === 'OUTPUT_WITHOUT_SALDO') {
        return [
          {
            label: 'Точки передачи',
            value: 'TRANSFER_POINT',
          },
        ];
      }

      return [];
    },
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    ownConsumptionEnabled(newValue) {
      if (!newValue) {
        this.ownConsumption =  {
          vn: null,
          sn1: null,
          sn2: null,
          nn: null,
        };
      }
    },
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.balanceIndicators = undefined;

          this.actIndicators = await this.loadActIndicators(this.selectedDate);
          await this.loadBoundariesAndActs();
        }
      },
    },
  },
  methods: {
    checkRepresentativeInput(signatory) {
      if (signatory?.userId) {
        return signatory.reason.reasonDocument === 'Устав' || signatory.reason.id;
      }
      return signatory?.fullName?.name?.length > 0 &&
        signatory.fullName?.surname?.length > 0 &&
        signatory.role.name?.length > 0 &&
        signatory.reason.reasonDocument?.length > 0 &&
        (signatory.reason.reasonDocument === 'Устав' || (signatory.reason.date && signatory.reason.number));
    },
    updateFirstSignatory(selected) {
      this.firstCompanySignatory = { ...selected };
    },
    updateSecondSignatory(selected) {
      this.secondCompanySignatory = { ...selected };
    },
    updateFirstCompany(selected) {
      this.firstCompany = { ...selected };
    },
    updateSecondCompany(selected) {
      this.secondCompany = { ...selected };
    },
    updateFirstDepartment(selected) {
      this.firstDepartment = { ...selected };
    },
    updateSecondDepartment(selected) {
      this.secondDepartment = { ...selected };
    },
    createGenerateBorderDeviceInput() {
      return {
        balanceBoundariesIds: this.updatedSelected.networkSectionsIds,
        sideSecond: this.createCompanyInput(this.firstCompany, this.firstDepartment, this.firstCompanySignatory, this.currentCompanyFirst || !this.oneSignature),
        sideThird: this.createCompanyInput(this.secondCompany, this.secondDepartment, this.secondCompanySignatory, !this.currentCompanyFirst || !this.oneSignature),
        accountingPeriod: this.selectedDate,
        balanceRule: this.balanceRuleByValue,
        usagePointIds: this.updatedSelected.usagePointsIds,
        selfNeeds: this.ownConsumptionEnabled ? this.ownConsumption : null,
      };
    },
    createGenerateBorderFlowInput() {
      return {
        borderDeviceActId: this.borderDeviceActId,
        sideSecondRepresentative: this.createRepresentativeInput(this.firstCompanySignatory, this.currentCompanyFirst || !this.oneSignature),
        sideThirdRepresentative: this.createRepresentativeInput(this.secondCompanySignatory, !this.currentCompanyFirst || !this.oneSignature),
      };
    },
    createCompanyInput(company, department, signatory, isSigning) {
      return {
        companyInn: company.inn,
        departmentId: department.id,
        representative: this.createRepresentativeInput(signatory, isSigning),
      };
    },
    createRepresentativeInput(signatory, isSigning) {
      if (!isSigning) {
        return null;
      }
      return signatory.userId ? {
        digital: {
          userId: signatory.userId,
          roleId: signatory.role.id,
          powerOfAttorneyId: signatory.reason.id,
        },
      } : {
        simple: {
          person: {
            name: signatory.fullName.name,
            surname: signatory.fullName.surname,
            patronymic: signatory.fullName.patronymic,
          },
          positionName: signatory.role.name,
          reasonDocument: {
            reasonDocumentName: signatory.reason.reasonDocument,
            date: signatory.reason.date ? getDateAsForceISOString(signatory.reason.date) : null,
            number: signatory.reason.number,
          },
        },
      };
    },
    async createIntegralActsClick() {
      if (this.indicators.transmissionContract.isError) {
        let errorMessage = '';
        if (this.indicators.transmissionContract.isError) {
          errorMessage += 'Договор не загружен. Прошу загрузить договор.<br>';
        }
        this.setNotification({ message: `Ошибка формирования актов: ${errorMessage}`, isHtml: true });
        return;
      }
      this.isWaitingSubmit = true;
      try {
        let resultList;
        if (this.actsKind === 'BORDER_DEVICE_ACT') {
          const result = await this.createBorderDeviceAct({
            generateInput: this.createGenerateBorderDeviceInput(),
          });
          resultList = result.generateBorderDeviceAct;
        } else if (this.actsKind === 'BORDER_FLOW_ACT') {
          const result = await this.createBorderFlowAct({
            generateInput: this.createGenerateBorderFlowInput(),
          });
          resultList = result.generateBorderFlowAct;
        } else {
          const result = await this.createBothBorderActs({
            generateInput: this.createGenerateBorderDeviceInput(),
          });
          resultList = result.generateBothBorderActs;
        }

        if (resultList.length > 0) {
          let notFormedActsMessage = '';
          resultList.forEach((value, index) => {
            let firstDepartmentName = '';
            let secondDepartmentName = '';
            if (value.firstCompanyDepartmentName !== null) {
              firstDepartmentName = `(отделение ${value.firstCompanyDepartmentName})`;
            }
            if (value.secondCompanyDepartmentName !== null) {
              secondDepartmentName = `(отделение ${value.secondCompanyDepartmentName})`;
            }
            notFormedActsMessage += `<p><b>${index + 1}.</b> ${value.firstCompanyName} ${firstDepartmentName} ${value.secondCompanyName} ${secondDepartmentName} за ${value.accountingPeriod.month}.${value.accountingPeriod.year} </p>\n`;
          });
          this.setNotification({ message: `По причине отсутствия показаний, не удалось сформировать следующие интегральные акты: ${notFormedActsMessage}`, isHtml: true });
        } else {
          this.vueShowNotification(
            'Интегральные акты были успешно сформированы!',
          );
          this.goBack();
        }
        this.reloadActs = true;
      } catch (e) {
        this.errorHandler(e);
        console.log(e);
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    goBack() {
      this.$router.push('/cabinet/integral-acts');
    },
    async loadBoundariesAndActs() {
      try {
        this.borders = await this.loadBalanceBoundaries({
          filter: {
            availableForAccounting: this.selectedDate,
          },
        });

        this.integralActs = await this.loadBorderDeviceActsForAccounting({
          accountingPeriod: this.selectedDate,
        });
      } catch (error) {
        console.log('loadIntegralActPatterns', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      }
    },
    errorHandler(error) {
      if (error.message.split(': ')[2] === 'THREE_SIDE_CONTRACT_NOT_FOUND') {
        this.isShowError = true;
        this.submitError = 'Вы не загрузили трёхсторонний договор';
        return;
      }
      if (error.message.split(': ')[2] === 'CONTRACT_NOT_SIGNED') {
        this.isShowError = true;
        this.submitError = 'Вы не подписали загруженный договор';
        return;
      }
      if (error.message.split(': ')[2] === 'THREE_SIDE_CONTRACT_WRONG_DATES') {
        this.isShowError = true;
        this.submitError = 'Не найден договор, период действия которого включает выбранный расчётный период';
        return;
      }
      if (error.message.split(': ')[2] === 'SUPPLIER_NOT_FOUND') {
        this.isShowError = true;
        this.submitError = 'Ваша компания не найдена в реестре сетевых и сбытовых компаний';
        return;
      }
      const errorData = HTTPError.network(error);
      this.submitError = errorData.message;
      this.isShowError = true;
    },
    changeCompanies() {
      [this.firstCompany,
        this.secondCompany,
        this.firstDepartment,
        this.secondDepartment,
        this.firstCompanySignatory,
        this.secondCompanySignatory] =
        [this.secondCompany,
          this.firstCompany,
          this.secondDepartment,
          this.firstDepartment,
          this.secondCompanySignatory,
          this.firstCompanySignatory];
      this.currentCompanyFirst = !this.currentCompanyFirst;
    },
    actSelected(id) {
      this.borderDeviceActId = id;
    },
    updateSelected(val) {
      this.updatedSelected = val;
    },
    bordersLoaded(val) {
      this.borders = val;
    },
    ...mapActions('document', ['createBorderDeviceAct', 'createBorderFlowAct', 'createBothBorderActs']),
    ...mapActions('cabinet', ['loadActIndicators', 'loadBorderDeviceActsForAccounting']),
    ...mapActions('border', ['loadBalanceBoundaries']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.generate-integral-act {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 58px;

  &__title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
  }

  &__error-container {

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #000;
      margin: 48px 64px 16px;
    }

    &__text {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: red;
      margin-left: 64px;
      margin-right: 64px;
    }
  }

  &__indicators {
    display: flex;
    justify-content: center;

    margin: 24px 0;

    div {
      margin-right: 8px;
    }
  }

  &__parameters {
    display: flex;
    gap: 16px;


    &__item {
      width: 276px;
    }

    &__checkbox {
      font-family: Roboto;
      font-size: 14px !important;
      line-height: 16px;
      color: #4C4E51;
      width: 264px;
      border-radius: 16px;
      background: #F5F6F6;
      padding: 0 12px 0 12px;
    }
  }

  &__checkbox-wrp {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: flex-start;

    &__checkbox {
      min-width: 24px;
      min-height: 24px;
      cursor: pointer
    }

    &__label {
      margin-left: 12px;
      cursor: pointer
    }
  }

  &__acts-kind {
    display: flex;
    justify-content: flex-start;
    position: relative;
    gap: 16px;

    &__selector {
      width: 272px;
      height: 40px;
      padding-left: 12px;

      background: inherit;
      border: 1px solid #DEE2E6;
      border-radius: 2px;
      color: #000000;

      &:disabled {
        color: #000000;
      }
    }

    &__selector-tooltip {
      &__text {
        width: 320px;
      }
    }
  }
}

.action-buttons {
  width: 100%;
  position: absolute;
  bottom: 12px;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  button {
    height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }

    &:disabled {
      background: #717d8f;
    }
  }

  &__action {
    &--left {
      text-align: left;
      margin-left: 300px;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 46px;
      }
    }
  }
}
</style>
