<template>
  <v-table-new
    v-model="transformedPlanningBalances"
    :action-block-items="actionBlockItems"
    :template="template"
    excel-report-file-name="Плановый баланс электрической энергии"
  />
</template>

<script>
import VTableNew from 'components/VTableNew/VTable.vue';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'PlanningBalancesContentBlock',
  components: { VTableNew },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
            },
          },
          {
            label: 'Расчётный период',
            key: 'accountingPeriod',
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Заказчик',
            key: 'supplierName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Исполнитель 1',
            key: 'performerFirstName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Исполнитель 2',
            key: 'performerSecondName',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Услуга на передачу, кВт*ч',
            type: 'number',
            key: 'service',
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Потери, %',
            type: 'number',
            key: 'lossPercent',
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Потери, кВт*ч',
            type: 'number',
            key: 'lossKWth',
            sort: {
              type: 'number',
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Акт верификации',
            key: 'act',
            type: 'documentDownload',
            checkEmpty: true,
          },
        ],
      };
    },
    transformedPlanningBalances() {
      return [];
    },
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать баланс',
          method: goToPageByRoute('generate-balance'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
