import { setDraft } from 'services/draft/index.js';

const setPlanningBalanceDraft = async (state) => {
  const accountId = state.balanceIndicators?.creationByTransmissionContract.initiatorAccountId;
  if (accountId) {
    await setDraft(accountId, { ...state }, 'planningBalance');
  }
};

const SET_SIDES_REPRESENTATIVES = async (state, { sidesRepresentatives }) => {
  state.sidesRepresentatives = {
    ...state.sidesRepresentatives,
    ...sidesRepresentatives,
  };
  await setPlanningBalanceDraft(state);
};

const SET_BALANCE_GROUP_NAME = async (state, payload) => {
  state.balanceGroupName = payload;
  await setPlanningBalanceDraft(state);
};

const SET_CURRENT_DATE = async (state, { date }) => {
  state.currentDate = date;
  await setPlanningBalanceDraft(state);
};

const SET_BALANCE_INDICATORS = async (state, { balanceIndicators }) => {
  state.balanceIndicators = balanceIndicators;
  await setPlanningBalanceDraft(state);
};

const SET_NETWORK_SECTIONS = async (state, { networkSections }) => {
  state.networkSections = networkSections;
  await setPlanningBalanceDraft(state);
};

const SET_SELECTED_NETWORK_SECTIONS_IDS = async (state, { selectedNetworkSectionsIds }) => {
  state.selectedNetworkSectionsIds = selectedNetworkSectionsIds;
  await setPlanningBalanceDraft(state);
};

const REMOVE_BOUNDARY_BY_SUBJECT_ID = async (state, { id }) => {
    state.boundariesWithSubject = state.boundariesWithSubject.filter((boundary) => boundary.id !== id);
};

const SET_USAGE_POINTS = async (state, { usagePoints }) => {
  state.usagePoints = usagePoints;
  await setPlanningBalanceDraft(state);
};

const SET_BOUNDARIES_WITH_SUBJECT = async (state, { boundariesWithSubject }) => {
  state.boundariesWithSubject.push(boundariesWithSubject);
  await setPlanningBalanceDraft(state);
};

const SET_SELECTED_USAGE_POINT_IDS = async (state, { selectedUsagePointsIds }) => {
  state.selectedUsagePointsIds = selectedUsagePointsIds;
  await setPlanningBalanceDraft(state);
};

const SET_SELECTED_BOUNDARIES_WITH_SUBJECTS_IDS = async (state, { selectedBoundariesWithSubjectsIds }) => {
  state.selectedBoundariesWithSubjectsIds = selectedBoundariesWithSubjectsIds;
  await setPlanningBalanceDraft(state);
};

const ADD_USAGE_POINT_ID = async (state, { usagePointId }) => {
  state.selectedUsagePointsIds.push(usagePointId);
  await setPlanningBalanceDraft(state);
};

const DELETE_USAGE_POINT_ID = async (state, { usagePointId }) => {
  const idx = state.selectedUsagePointsIds.indexOf(usagePointId);
  if (idx > -1) state.selectedUsagePointsIds.splice(idx, 1);
  await setPlanningBalanceDraft(state);
};

const ADD_BALANCE_BOUNDARY_ID = async (state, { bordersBoundaryId }) => {
  state.selectedBoundariesWithSubjectsIds.push(bordersBoundaryId);
  await setPlanningBalanceDraft(state);
};

const DELETE_BALANCE_BOUNDARY_ID = async (state, { bordersBoundaryId }) => {
  const idx = state.selectedBoundariesWithSubjectsIds.indexOf(bordersBoundaryId);
  if (idx > -1) state.selectedBoundariesWithSubjectsIds.splice(idx, 1);
  await setPlanningBalanceDraft(state);
};


export default {
  SET_SIDES_REPRESENTATIVES,
  SET_CURRENT_DATE,
  SET_BALANCE_INDICATORS,
  SET_NETWORK_SECTIONS,
  SET_USAGE_POINTS,
  SET_SELECTED_NETWORK_SECTIONS_IDS,
  SET_BALANCE_GROUP_NAME,
  REMOVE_BOUNDARY_BY_SUBJECT_ID,
  SET_SELECTED_USAGE_POINT_IDS,
  SET_BOUNDARIES_WITH_SUBJECT,
  SET_SELECTED_BOUNDARIES_WITH_SUBJECTS_IDS,
  ADD_USAGE_POINT_ID,
  ADD_BALANCE_BOUNDARY_ID,
  DELETE_USAGE_POINT_ID,
  DELETE_BALANCE_BOUNDARY_ID,
};
