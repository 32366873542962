<template>
  <Process :type="regularInspectionTaskType.INSTALLATION_CONTROL"/>
</template>

<script>
import Process from 'components/Processes/Assignment/Assignment.vue';
import { regularInspectionTaskType } from 'components/Processes/list';

export default {
  name: 'ObjectsForInstallationControl',
  components: { Process },
  data: () => ({
    regularInspectionTaskType: regularInspectionTaskType,
  }),
};
</script>

<style scoped>

</style>
