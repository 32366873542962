<template>
  <div :class="$style.container">
    <div :class="{
          [$style.progress]: true,
          [$style.progress92]: list.length < 7,
          [$style.progress96]: list.length >= 7,
        }"></div>
    <div :class="$style.list">
      <p v-for="(item, idx) in list"
         :key="idx"
         :class="{
          [$style.status]: true,
          [$style.current]: idx === currentIndex,
          [$style.disabled]: idx < currentIndex,
        }"
      >

        <img v-if="idx === currentIndex" src="~@/assets/icons/basic/step_current_ellipse.svg" :class="{ [$style.point]: true, [$style.current]: true }" alt="" />

        <img v-else src="~@/assets/icons/basic/step_ellipse.svg" :class="$style.point" alt="" />

        <span v-if="title && idx === currentIndex" :class="$style.text">{{ title.title }} {{ title.subTitle }}</span>
        <span v-else :class="$style.text">{{ item.title }} {{ item.subTitle }}</span>

        <span
          v-if="idx === currentIndex && subTitle"
          :class="$style.date"
        >
          {{ subTitle }}
        </span>

      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskStatus',
  props: {
    list: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    title: Object,
    subTitle: String,
  },
};
</script>

<style lang="sass" module>
  .container
    position: relative
    display: flex

  .list
    padding: 0 0 0 25px

  .progress
    width: 2px
    position: absolute
    top: 8px
    left: 4px
    background: #C1C4C7

  .progress92
    height: 92%

  .progress96
    height: 96%

  .status
    +step-future-and-past-text
    color: #0F1010

    &:not(:last-child)
      padding-bottom: 18px

  .current
    color: #3894FB
    +step-current-text

    ~ p
      color: #0F1010

  .disabled
    color: #C1C4C7

  .date
    +card-caption
    display: flex
    margin: 8px 0 0
    font-family: Roboto
    color: #0F1010

  .point
    width: 8px
    height: 8px
    position: absolute
    left: 1px
    margin: 5px 0 0

    &.current
      width: 14px
      height: 14px
      position: absolute
      left: -2px
</style>
