<template>
  <div>
    <warehouses-autocomplete
        ref="warehouse"
        v-model="internalObject.warehouseId"
        :is-disabled="!!planConsignment"
        :items.sync="internalObject.warehouses"
        :search.sync="internalObject.warehouseName"
        is-required
        title="Склад"
    />

    <contractor-autocomplete
      ref="contractor"
      v-model="internalObject.contractorId"
      :is-disabled="!!planConsignment"
      :items.sync="internalObject.contractors"
      :search.sync="internalObject.contractorName"
      is-required
      is-vsearch
      title="Подрядчик"
    ></contractor-autocomplete>

    <v-checkbox v-model="internalObject.isByContract" :disabled="disableEditByContract ||!!planConsignment"
                label="Приёмка по договору"/>
    <div v-if="internalObject.isByContract">
      <autocomplete-find-id ref="contract" v-model="internalObject.contractId" :find-id="internalObject.contractorId"
                            :is-disabled="internalObject.contractorId == null || !!planConsignment"
                            :items.sync="internalObject.contracts"
                            :search.sync="internalObject.contractName"
                            is-required
                            is-required-find-id
                            title="Договор"
                            url-default-data="/contract-debt/contractor/{0}/top20"
                            url-search="/contract-debt/contractor/{0}/find?name={1}"></autocomplete-find-id>

      <v-banner v-if="!internalObject.contractId" class="mb-2">Если договора нет, перейдите в <a
        @click="$router.push('/cabinet/process/4/1')">раздел создания нового договора</a> или <a
        @click="$router.push('/cabinet/process/4/2')">внесения спецификации</a></v-banner>
    </div>
    <div v-else>
      <events-autocomplete
        ref="event"
        v-model="internalObject.eventId"
        :find-ids="eventsFindIds"
        :is-disabled="internalObject.contractorId == null ||!!planConsignment"
        :items.sync="internalObject.eventItems"
        :search.sync="internalObject.eventName"
        title="Мероприятие"
        url-default-data="/events/contractor/{0}/top20"
        url-search="/events/contractor/{0}/find?name={1}"
      />


      <autocomplete-find-id
        ref="adreska"
        v-model="internalObject.adreskaId"
        :find-id="internalObject.contractorId"
        :find-id1="internalObject.eventId"
        :is-disabled="internalObject.contractorId == null || internalObject.eventId == null||!!planConsignment"
        :items.sync="internalObject.adreskaItems"
        :search.sync="internalObject.adreskaAddress"
        is-required-find-id
        is-required-find-id1
        title="Программа оформления"
        url-default-data="/adreska/contractor/{0}/event/{1}/top20"
        url-search="/adreska/contractor/{0}/event/{1}/find?name={2}"
      ></autocomplete-find-id>
    </div>

    <axp-autocomplete
        v-if="internalObject.adreskaId"
        ref="axp"
        v-model="internalObject.axpId"
        :find-ids="axpFindIds"
        :is-disabled="!!planConsignment"
        :items.sync="internalObject.axpItems"
        :search.sync="internalObject.axpName"
        is-required
        title="АХП"
        url-default-data="/axp/adreska/{0}/top20"
        url-search="/axp/adreska/{0}/find?name={1}"
    />

    <axp-autocomplete
        v-else
        ref="axp"
        v-model="internalObject.axpId"
        :is-disabled="!!planConsignment"
        :items.sync="internalObject.axpItems"
        :search.sync="internalObject.axpName"
        is-required
        title="АХП"
    />

    <axp-photos-carousel v-if="internalObject.axpId" :axp-id="internalObject.axpId"/>

    <text-field
      ref="axpQuantity"
      v-model="internalObject.countAxp"
      is-only-integer-numbers
      is-required
      title="Количество принимаемых комплектов АХП"
    ></text-field>


    <v-row v-if="internalObject.axpId != null" class="pr-4 mb-2">
      <v-col/>
      <v-col/>
      <v-col>
        <v-row>
          <v-spacer/>
          <v-btn @click="accountTotal">Рассчитать</v-btn>
          <v-spacer/>
          <v-btn @click="clearTotal">Очистить форму</v-btn>
          <v-spacer v-if="!disableWithoutRecalcultion"/>
          <v-checkbox
            v-if="!disableWithoutRecalcultion"
            v-model="internalObject.withoutRecalculation"
            class=""
            color="success"
            hide-details
            label="Без пересчёта"
            @click="onWithoutRecalculationClick"
          ></v-checkbox>
        </v-row>
      </v-col>
    </v-row>

    <div>
      <v-data-table
        :headers="headersTable"
        :items="internalObject.formElements"
        :loading="isLoadingElements"
        class="elevation-1 mb-4"
        style="width: 100%"
      >
        <template #no-data>
          <span>Отсутствуют данные</span>
        </template>
        <template #item.realCount="{ item }">
          <div class="d-flex align-center mt-6">
            <text-field
              ref="realCount"
              v-model="item.realCount"
              :max="internalObject.contractId?item.countByContract:null"
              is-only-numbers
              simple
            ></text-field>
          </div>
        </template>
        <template #item.withoutRecalculation="{ item }">
          <div class="d-flex align-center flex-fill justify-center">
            <v-checkbox
              v-model="item.withoutRecalculation"
              class="mb-6"
              color="success"
              hide-details
              @click="onWithoutRecalculationInTableChanged"
            ></v-checkbox>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import { mapGetters } from 'vuex';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  name: 'Process6',
  components: {
    AxpPhotosCarousel,
    TextField,
    AutocompleteFindId,
    ContractorAutocomplete,
    AxpAutocomplete,
    WarehousesAutocomplete,
    EventsAutocomplete,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    disableEditByContract: {
      type: Boolean,
      default: false,
    },
    disableWithoutRecalcultion: {
      type: Boolean,
      default: false,
    },
    planConsignment: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    isLoadingElements: false,
    internalObject: {},
    defaultHeaders: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      { text: 'Количество на складе', value: 'quantityInWarehouse', align: 'center' },
      {
        text: 'Нужно',
        value: 'needQuantity',
        align: 'center',
      },
      {
        text: 'Долги подрядчика',
        value: 'debt',
        align: 'center',
      },
      {
        text: 'Выдаваемое количество',
        value: 'realCount',
        align: 'center',
      },
    ],
  }),
  computed: {
    headersTable() {
      const headers = [
        {
          text: 'Элемент',
          align: 'start',
          value: 'elementName',
        },
        {
          text: 'Количество в одном АХП',
          value: 'elementQuantity',
          align: 'center',
        },
        {
          text: 'Нужно',
          value: 'needQuantity',
          align: 'center',
        },
        {
          text: 'Принимаемое количество',
          value: 'realCount',
          align: 'center',
        },
      ];
      if (this.internalObject.contractId != null) {
        headers.splice(3, 0, {
          text: 'Количество по договору',
          value: 'countByContract',
          align: 'center',
        });
      }
      if (this.planConsignment != null) {
        headers.splice(3, 0, {
          text: 'Количество по плановой накладной',
          value: 'countByPlanConsignment',
          align: 'center',
        }, {
          text: 'Осталось по плановой накладной',
          value: 'leftCountByPlanConsignment',
          align: 'center',
        });
      }
      if (this.internalObject.adreskaId != null) {
        headers.splice(3, 0, {
          text: 'Количество по программе оформления',
          value: 'countByAdreska',
          align: 'center',
        });
      }
      if (!this.disableWithoutRecalcultion) {
        headers.push({
          text: 'Без пересчёта',
          value: 'withoutRecalculation',
          align: 'center',
        });
      }
      return headers;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.contractorId,
        },
      ];
    },
    axpFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.adreskaId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.internalObject = newVal;
        }
      },
    },
    internalObject: {
      immediate: true,
      deep: true,
      handler() {
        this.internalObject.isDisabled = this.accountDisabled();
        this.$emit('input', this.internalObject);
      },
    },
    async planConsignment(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        this.internalObject.warehouses = [{
          id: String(this.planConsignment.warehouse.id),
          name: this.planConsignment.warehouse.name,
        }];
        this.internalObject.warehouseName = this.planConsignment.warehouse.name;
        this.internalObject.warehouseId = String(this.planConsignment.warehouse.id);

        this.internalObject.contractors = [{
          id: this.planConsignment.contractorId,
          name: this.planConsignment.contractorName,
        }];
        this.internalObject.contractorName = this.planConsignment.contractorName;
        this.internalObject.contractorId = this.planConsignment.contractorId;

        this.internalObject.isByContract = !!this.planConsignment.contract;

        if (this.internalObject.isByContract) {
          this.internalObject.contracts = [{
            id: this.planConsignment.contract.id,
            name: this.planConsignment.contract.contract,
          }];
          this.internalObject.contractName = this.planConsignment.contract.contract;
          this.internalObject.contractId = this.planConsignment.contract.id;
        } else {
          if (this.planConsignment.event) {
            this.internalObject.eventItems = [
              {
                id: String(this.planConsignment.event.id),
                name: this.planConsignment.event.name,
              },
            ];
            this.internalObject.eventName = this.planConsignment.event.name;
            this.internalObject.eventId = String(this.planConsignment.event.id);
          }
          if (this.planConsignment.adreska) {
            this.internalObject.adreskaItems = [
              {
                id: this.planConsignment.adreska.id,
                name: this.planConsignment.adreska.name,
              },
            ];
            this.internalObject.adreskaAddress = this.planConsignment.adreska.name;
            this.internalObject.adreskaId = this.planConsignment.adreska.id;
          }
        }

        this.internalObject.axpItems = [
          {
            id: String(this.planConsignment.axp.id),
            name: this.planConsignment.axp.name,
          },
        ];
        this.internalObject.axpName = this.planConsignment.axp.name;
        this.internalObject.axpId = String(this.planConsignment.axp.id);

        this.internalObject.countAxp = String(this.planConsignment.countAxp);


      }
      await this.fetchElementsByPlanConsignemt();
    },
    'internalObject.isByContract': {
      deep: true,
      handler(newVal, oldVal) {
        console.log('ibc');
        if (oldVal === newVal) return;
        if (!newVal) this.internalObject.contractId = null;
        else {
          this.internalObject.adreskaId = null;
          this.internalObject.eventId = null;
        }
      },
    },
    'internalObject.contractId': {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.fetchElementsDept();
      },
    },
    'internalObject.axpId': {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.internalObject.formElements = [];
        this.internalObject.countAxp = null;
        if (newVal != null) {
          this.fetchElements(newVal);
        }
      },
    },
    'internalObject.countAxp': {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
          ...el,
          needQuantity: newVal * el.elementQuantity,
        }));
      },
    },
  },
  methods: {
    async fetchElements() {
      this.isLoadingElements = true;
      this.internalObject.formElements = [];
      if (this.internalObject.adreskaId) {
        const response = await fetch(
          `${BASE_URL}/elements/actual/adreska/${this.internalObject.adreskaId}/axp/${this.internalObject.axpId}/all`,
        );
        const data = await response.json();
        this.internalObject.formElements = data.map((el) => ({
          elementId: el.id,
          elementName: el.name,
          unitName: el.unitName,
          elementQuantity: el.elementQuantity,
          realCount: '',
          countByContract: 0,
          countByAdreska: el.countByAdreska,
          needQuantity: 0,
          withoutRecalculation: this.withoutRecalculation,
        }));
      } else {
        const response = await fetch(
          `${BASE_URL}/axp/${this.internalObject.axpId}/elements/actual`,
        );
        const data = await response.json();
        this.internalObject.formElements = data.map((el) => ({
          elementId: el.id,
          elementName: el.name,
          unitName: el.unitName,
          elementQuantity: el.quantity,
          realCount: '',
          withoutRecalculation: this.withoutRecalculation,
          needQuantity: 0,
        }));
      }
      this.isLoadingElements = false;
      await this.fetchElementsDept();
      await this.fetchElementsByPlanConsignemt();
    },
    async fetchElementsDept() {
      if (!this.internalObject.contractId) {
        this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
          ...it,
          countByContract: undefined,
        }));
        return;
      }
      this.isLoadingElements = true;
      const response = await fetch(
        `${BASE_URL}/contract-debt?contractId=${this.internalObject.contractId}`,
      );
      const data = await response.json();
      const debt = {};
      data.forEach((el) => {
        debt[el.elements.id] = debt[el.elements.id] ? debt[el.elements.id] + el.countElement : el.countElement;
      });
      this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
        ...it,
        countByContract: debt[it.elementId] ?? 0,
      }));
      this.isLoadingElements = false;
    },
    async fetchElementsByPlanConsignemt() {
      if (!this.planConsignment) {
        this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
          ...it,
          countByPlanConsignment: undefined,
        }));
        return;
      }
      this.isLoadingElements = true;
      const response = await fetch(
        `${BASE_URL}/plan-consignment/${this.planConsignment.id}`,
      );
      const data = await response.json();
      const debt = {};
      const fact = {};
      data.elements.forEach((el) => {
        debt[el.elementId] = debt[el.elementId] ? debt[el.elementId] + el.countElement : el.countElement;
        fact[el.elementId] = fact[el.elementId] ? fact[el.elementId] + el.factCountElement : el.factCountElement;
      });
      this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
        ...it,
        countByPlanConsignment: debt[it.elementId] ?? 0,
        leftCountByPlanConsignment: (debt[it.elementId] ?? 0) - (fact[it.elementId] ?? 0),
      }));
      this.isLoadingElements = false;
    },
    onWithoutRecalculationInTableChanged() {
      this.internalObject.withoutRecalculation = !this.internalObject.formElements.some((it) => !it.withoutRecalculation);
    },
    onWithoutRecalculationClick() {
      this.internalObject.formElements.forEach((it) => {
        it.withoutRecalculation = this.internalObject.withoutRecalculation;
      });
    },
    accountTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
          ...el,
          realCount:
            String(
              Math.min(
                  Number(el.needQuantity),
                  el.countByContract ?? Number.MAX_VALUE,
                  el.countByPlanConsignment ?? Number.MAX_VALUE,
                  el.countByAdreska ?? Number.MAX_VALUE,
              ),
            ),
        }));
    },
    clearTotal() {
      this.withoutRecalculation = false;
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount: '',
      }));
    },
    accountDisabled() {
      const isDisabledWarehouse = this.internalObject.warehouseId == null;
      const isDisabledContractor = this.internalObject.contractorId == null;
      const isDisabledContract = this.internalObject.isByContract && this.internalObject.contractId == null;
      const isDisableAxp = this.internalObject.axpId == null;
      const isDisableCountAxp = !this.internalObject.countAxp?.length || !this.internalObject.countAxp.match('^\\d+$');
      const isDisableCountElements = this.internalObject.formElements.some((it) => Number(it.realCount) < 0 || (this.internalObject.contractId && Number(it.realCount) > Number(it.countByContract))) ||
        this.internalObject.formElements.every((it) => !it.realCount?.length || isNaN(Number(it.realCount) || Number(it.realCount) === 0));

      return isDisabledWarehouse ||
        isDisabledContractor ||
        isDisableAxp ||
        isDisableCountAxp ||
        isDisabledContract ||
        isDisableCountElements;
    },
    resetValidation() {
      this.isLoadingElements = false;
      this.internalObject = {};
      this.axpQuantity = '';
      this.warehouseName = '';
      this.contractorName = '';
      this.axpName = '';
      this.adreskaAddress = '';
      this.eventName = '';
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
