import Vue from 'vue';
import Vuex from 'vuex';

import tgis from './modules/tgis';
import user from './modules/user';
import document from './modules/document';
import payment from './modules/payment';
import cabinet from './modules/cabinet';
import exploitation from './modules/cabinet/exploitation';
import dataLists from './modules/data-lists';
import serviceLocations from './modules/service-locations';
import dictionary from './modules/dictionary';
import chatbot from './modules/chatbot';
import point from './modules/point';
import integralAct from './modules/integralAct';
import border from './modules/border';
import uploadDocument from './modules/upload-document';
import history from './modules/history';
import consumer from './modules/consumer';
import tasks from './modules/tasks';
import employees from 'store/modules/employees';
import attorney from 'store/modules/attorney';
import electricityTransmissionContract from './modules/electricity-transmission-contract';
import environment from './modules/environment';
import permissionsRegistry from './modules/permissions-registry';
import paymentOrders from './modules/payment-orders';
import planningBalance from './modules/planning-balance';

Vue.use(Vuex);

const INITIAL_USER = {
  isLogged: false,
  userType: undefined,
  consumerType: '',
  connectionType: '',
};

function initUser() {
  const userData = localStorage.getItem('userOld');

  if (userData === null) {
    return INITIAL_USER;
  }

  return JSON.parse(userData);
}

function saveUser(user) {
  localStorage.setItem('userOld', JSON.stringify(user));
}

const INITIAL_CONSUMER_STATUS = {
  current: 'На рассмотрении',
  date: '20.11.2021',
  list: [
    'Подана',
    'На рассмотрении',
    'Согласуйте документы',
    'Оплатите счёт',
    'Работы по подключению',
    'Выполнение заявки',
  ],
};

function initConsumerStatus() {
  const consumerStatus = localStorage.getItem('consumerStatus');

  if (consumerStatus === null) {
    return INITIAL_CONSUMER_STATUS;
  }

  return JSON.parse(consumerStatus);
}

function saveConsumerStatus(consumerStatus) {
  localStorage.setItem('consumerStatus', JSON.stringify(consumerStatus));
}

const INITIAL_GRID_STATUS = {
  current: 'Рассмотрите заявку',
  date: '20.11.2021',
  list: [
    'Рассмотрите заявку',
    'Подготовьте договор и технические условия',
    'Работы по присоединению',
    'Закрытие заявки',
  ],
};

function initGridStatus() {
  const gridStatus = localStorage.getItem('gridStatus');

  if (gridStatus === null) {
    return INITIAL_GRID_STATUS;
  }

  return JSON.parse(gridStatus);
}

function saveGridStatus(gridStatus) {
  localStorage.setItem('gridStatus', JSON.stringify(gridStatus));
}

export default new Vuex.Store({
  state: {
    userOld: initUser(),
    consumerStatus: initConsumerStatus(),
    gridStatus: initGridStatus(),
    currentItemId: undefined,
    // TODO тестовая переменная убрать при релизе
    info: [],
  },
  mutations: {
    login(state) {
      state.userOld.isLogged = true;
      saveUser(state.userOld);
    },
    logout(state) {
      state.userOld = INITIAL_USER;
      saveUser(state.userOld);
    },
    individualConsumerType(state) {
      state.userOld.consumerType = 'individual';
      saveUser(state.userOld);
    },
    clearConsumerType(state) {
      state.userOld.consumerType = '';
      saveUser(state.userOld);
    },
    individualConnectionType(state) {
      state.userOld.connectionType = 'individual-connection';
      saveUser(state.userOld);
    },
    clearConnectionType(state) {
      state.userOld.connectionType = '';
      saveUser(state.userOld);
    },
    consumerUserType(state) {
      state.userOld.userType = 'consumer';
      saveUser(state.userOld);
    },
    gridCompanyUserType(state) {
      state.userOld.userType = 'grid-company';
      saveUser(state.userOld);
    },
    updateConsumerStatus(state, val) {
      state.consumerStatus.current = val;
      saveConsumerStatus(state.consumerStatus);
    },
    updateGridStatus(state, val) {
      state.gridStatus.current = val;
      saveGridStatus(state.gridStatus);
    },
    clearGridStatus(state, val) {
      state.gridStatus.current = val;
      state.gridStatus.date = '';
      state.gridStatus.list = [val];
      saveGridStatus(state.gridStatus);
    },
    updateCurrentItemId(state, val) {
      state.currentItemId = val;
    },
  },
  actions: {},
  getters: {
    isLogged: (state) => state.userOld.isLogged,
    isIndividual: (state) => state.userOld.consumerType === 'individual',
    isConsumerUser: (state) => state.userOld.userType === 'consumer',
    consumerStatus: (state) => state.consumerStatus,
    gridStatus: (state) => state.gridStatus,
    currentItemId: (state) => state.currentItemId,
    isAuthorization: (state) => state.userOld.userType !== undefined,
  },
  modules: {
    electricityTransmissionContract,
    environment,
    tgis,
    user,
    document,
    payment,
    cabinet,
    exploitation,
    history,
    dataLists,
    serviceLocations,
    dictionary,
    chatbot,
    point,
    integralAct,
    uploadDocument,
    border,
    consumer,
    tasks,
    employees,
    attorney,
    permissionsRegistry,
    paymentOrders,
    planningBalance,
  },
});
