<template>
  <div class="consumer-info">
    <h2>Сведения о потребителе:</h2>

    <div class="consumer-info__consumer">
      <!--      <v-input-->
      <!--        v-model="internalValue.usagePoint.consumer.objectType"-->
      <!--        :disabled="!isEditing"-->
      <!--        class="consumer-info__item"-->
      <!--        clearable-->
      <!--        rules="required"-->
      <!--        title="Тип потребителя *"-->
      <!--      />-->

      <v-input
        v-model="internalValue.usagePoint.consumer.consumerName"
        :disabled="!isEditing"
        class="consumer-info__consumer__name"
        clearable
        rules="required"
        title="Наименования потребителя *"
      />

      <img alt="Подробнее" src="~@/assets/icons/navigation/follow-arrow.svg" @click="openConsumer"/>
    </div>

    <v-divider/>

    <template v-if="isDisplayPerson">
      <h3>Контактное лицо</h3>

      <div class="consumer-info__consumer">
        <v-input
          v-model="internalValue.consumer.contactPerson.familyName"
          :disabled="!isEditing"
          class="consumer-info__item"
          clearable
          rules="required"
          title="Фамилия *"
        />

        <v-input
          v-model="internalValue.consumer.contactPerson.FirstName"
          :disabled="!isEditing"
          class="consumer-info__item"
          clearable
          rules="required"
          title="Имя *"
        />

        <v-input
          v-model="internalValue.consumer.contactPerson.Patronymic"
          :disabled="!isEditing"
          class="consumer-info__item"
          clearable
          title="Отчество"
        />
      </div>

      <div class="consumer-info__consumer">
        <v-input
          v-model="internalValue.consumer.contactPerson.email"
          :disabled="!isEditing"
          class="consumer-info__item"
          clearable
          rules="required"
          title="Электронная почта *"
        />
        <v-input
          v-model="internalValue.consumer.contactPerson.phone"
          :disabled="!isEditing"
          class="consumer-info__item"
          clearable
          rules="required"
          title="Телефон *"
        />
      </div>

      <v-divider/>
    </template>

  </div>
</template>

<script>
import { VDivider, VInput } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'ConsumerInfo',
  components: {
    VInput,
    VDivider,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isDisplayPerson: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initData: {
        consumer: {
          kind: '',
          consumerName: '',

          contactPerson: {
            familyNamekind: '',
            FirstNamekind: '',
            Patronymickind: '',
            emailkind: '',
            phonekind: '',
          },
        },
      },
      internalValue: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    openConsumer() {
      this.$router.push('/cabinet/consumers');
    },
  },
};

</script>

<style lang="scss" scoped>
.consumer-info {
  display: flex;
  flex-direction: column;
  gap: 24px;


  h2, h3 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
  }

  h2 {
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
  }

  &__consumer {
    display: flex;
    gap: 24px;

    &__name {
      width: 100%;
    }

    img {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__item {
    width: 266px;

    &--500 {
      width: 500px;
    }
  }
}
</style>
