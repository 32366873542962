<template>
  <div>
    <add-directory-field-modal
        v-model="internalModalValue"
        :action="internalModalAction"
        :loading="loading"
        :template="fields"
        @close="closeModal"
        @create="createAxp"
        @delete="deleteAxp"
        @update="updateAxp"
        @isNotValid="reValidate">
      <template
          #odk="slotProps">
        <odk-autocomplete
            :selected="slotProps.value?.id"
            :is-disabled="slotProps.disabled"
            :is-required="slotProps.field.required"
            :title="slotProps.field.name"
            @change="(value, label)=>updateOdkOnAxp(value, label, slotProps.input)"
        />
      </template>
      <template
          #photos="slotProps">
        <div class="vuetify-styles no-min-height" data-app style="margin: 24px 0 0 0">
          <v-app class="no-min-height" style="display: flex; width: auto; min-height: 0">
            <file-input
                :is-required="slotProps.field.required"
                :only-view="slotProps.disabled"
                :title="slotProps.field.name"
                :value="slotProps.value"
                disable-vertical-padding
                :re-validate="isReValidate"
                @input="slotProps.input"
            />
          </v-app>
        </div>
      </template>
      <template
          #documents="slotProps">
        <div class="vuetify-styles" data-app style="margin: 24px 0 0 0">
          <v-app class="no-min-height" style="display: flex; width: auto;">
            <file-input
                :is-required="slotProps.field.required"
                :only-view="slotProps.disabled"
                :title="slotProps.field.name"
                :value="slotProps.value"
                disable-vertical-padding
                @input="slotProps.input"
            />
          </v-app>
        </div>
      </template>
      <template
          #elements="slotProps">
        <div class="elements">
          <v-table-new
              v-model="slotProps.value"
              :template="elementsTemplate"
              hide-spoiler
              @delete="onDeleteElement"
              @edit="onEditElement"
          />
          <v-button v-if="modalAction !== AddDirectoryFieldModalActions.DELETE" variant="filled"
            @click="onCreateElement">Добавить элемент</v-button>
        </div>
      </template>
    </add-directory-field-modal>

    <add-directory-field-modal
        v-model="internalElementsModalValue"
        :action="internalElementsModalAction"
        :loading="loading"
        :template="fieldsElements"
        @close="closeElementsModal"
        @create="createAxpElement"
        @delete="deleteAxpElement"
        @update="updateAxpElement"
        @isNotValid="reValidateElement">
      <template
          #photos="slotProps">
        <div class="vuetify-styles" data-app style="margin: 24px 0 0 0">
          <v-app class="no-min-height" style="display: flex; width: auto; min-height: 0">
            <file-input
                :is-required="slotProps.field.required"
                :only-view="slotProps.disabled"
                :title="slotProps.field.name"
                :value="slotProps.value"
                disable-vertical-padding
                :re-validate="isReValidateElement"
                @input="slotProps.input"
            />
          </v-app>
        </div>
      </template>
      <template #typeId="slotProps">
        <axp-element-types-autocomplete
            :selected="slotProps.value"
            :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
            :is-required="slotProps.field.required"
            :title="slotProps.field.name"
            @change="slotProps.input"
        />
      </template>
      <template #decorTypeId="slotProps">
        <decor-type-autocomplete
            :selected="slotProps.value"
            :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
            :is-required="slotProps.field.required"
            :title="slotProps.field.name"
            @change="slotProps.input"
        />
      </template>
    </add-directory-field-modal>
  </div>
</template>

<script>
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import VButton from 'components/VButton/VButton.vue';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from 'components/AddDirectoryFieldModal/consts';
import { createAxp, deleteAxp, updateAxp } from '@/api/rest/odk/api';
import FileInput from 'components/Processes/components/FileInput.vue';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';
import AxpElementTypesAutocomplete from 'components/Processes/components/AxpElementTypesAutocomplete.vue';
import DecorTypeAutocomplete from 'components/Processes/components/DecorTypeAutocomplete.vue';
import OdkAutocomplete from 'components/Processes/components/OdkAutocomplete';

export default {
  name: 'OdkAccountingAxpEdit',
  components: {
    AddDirectoryFieldModal,
    VButton,
    FileInput,
    AxpElementTypesAutocomplete,
    DecorTypeAutocomplete,
    OdkAutocomplete,
    VTableNew: () => import('components/VTableNew/VTable'),
  },
  props: {
    modalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    elementsModalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    modalValue: {
      type: Object,
      default: () => {},
    },
    elementsModalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalModalValue: {},
      internalElementsModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalElementsModalValue: {},
      loading: false,
      AddDirectoryFieldModalActions: AddDirectoryFieldModalActions,
      isReValidate: false,
      isReValidateElement: false,
    };
  },
  computed: {
    fields() {
        return [{
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'odk',
          name: 'ОДК',
          url: '/odk',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.NUMBER,
          options: this.name,
          id: 'power',
          name: 'Мощность',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'fir',
          name: 'Ель',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'specialProject',
          name: 'Спецпроект',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'photos',
          name: 'Фото',
          required: true,
          defaultValue: [],
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'documents',
          name: 'Документы',
          defaultValue: [],
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'elements',
          name: 'Элементы',
          defaultValue: [],
          required: true,
        },
      ];
    },
    fieldsElements() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'unitId',
          name: 'Единицы измерения',
          url: '/units',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.NUMBER,
          id: 'quantity',
          name: 'Количество',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'typeId',
          name: 'Тип',
          url: '/axp-element-types',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'decorTypeId',
          name: 'Тип декора',
          url: '/decor-types',
          required: false,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'required',
          name: 'Обязательный для сборки',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.BOOLEAN,
          id: 'archived',
          name: 'В архиве',
          required: true,
          defaultValue: 'false',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'photos',
          name: 'Фото',
          required: true,
          defaultValue: [],
        },
      ];
    },
    elementsTemplate() {
      return {
        headers: [
          {
            label: '№П/П',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Наименование',
            key: 'name',
          },
          {
            label: 'Количество',
            key: 'quantity',
          },
          {
            key: 'actionButtons',
            type: 'editDeleteButtons',
            thStyle: {
              width: '80px',
            },
          },
        ],
      };
    },
  },
  watch: {
    modalAction(val) {
      this.internalModalAction = val;
    },
    elementsModalAction(val) {
      this.internalElementsModalAction = val;
    },
    modalValue(val) {
      this.internalModalValue = val;
    },
    elementsModalValue(val) {
      this.internalElementsModalValue = val;
    },
  },
  methods: {
    updateOdkOnAxp(value, label, input) {
      input({
        id: value,
        name: label,
      });
    },
    closeModal() {
      this.internalModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:modalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalModalValue = {};
      this.$emit('update:modalValue', {});
    },
    deleteAxp() {
      this.doAction(() => deleteAxp(this.internalModalValue.id));
    },
    createAxp() {
      this.doAction(() => createAxp(this.formatBody()));
    },
    updateAxp() {
      this.doAction(() => updateAxp(this.internalModalValue.id, this.formatBody()));
    },
    reValidate() {
      this.isReValidate = !this.isReValidate;
    },
    reValidateElement() {
      this.isReValidateElement = !this.isReValidateElement;
    },
    onEditElement(cell) {
      this.internalElementsModalAction = AddDirectoryFieldModalActions.UPDATE;
      this.internalElementsModalValue = { ...cell.row };
    },
    onDeleteElement(cell) {
      this.internalElementsModalAction = AddDirectoryFieldModalActions.DELETE;
      this.internalElementsModalValue = { ...cell.row };
    },
    onCreateElement() {
      this.internalElementsModalAction = AddDirectoryFieldModalActions.CREATE;
      this.internalElementsModalValue = {};
    },
    closeElementsModal() {
      this.internalElementsModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:elementsModalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalElementsModalValue = {};
      this.$emit('update:elementsModalValue', {});
    },
    deleteAxpElement() {
      this.internalModalValue.elements.splice(this.internalElementsModalValue.index - 1, 1);
      this.closeElementsModal();
    },
    createAxpElement() {
      this.internalModalValue.elements.push(this.internalElementsModalValue);
      this.closeElementsModal();
    },
    updateAxpElement() {
      this.internalModalValue.elements.splice(this.internalElementsModalValue.index - 1, 1, this.internalElementsModalValue);
      this.closeElementsModal();
    },
    async doAction(action) {
      this.loading = true;
      try {
        const result = await action();
        this.$emit('onAction', result?.data);
        this.closeModal();
        this.$emit('loadData', this.internalElementsModalValue);
      } catch (error) {
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    transformFilesToRequest(photos) {
      return photos.map((doc) => ({
        id: doc.fileId,
        stringBase64: doc.previewUrl,
        fileName: doc.name,
      }));
    },
    formatBody() {
      return {
        ...this.internalModalValue,
        odkId: this.internalModalValue.odk.id !== '-1' ? this.internalModalValue.odk.id : null,
        odkName: this.internalModalValue.odk.name,
        photos: this.transformFilesToRequest(this.internalModalValue.photos),
        documents: this.transformFilesToRequest(this.internalModalValue.documents),
        elements: this.internalModalValue.elements.map((el) => ({
          ...el,
          axpId: this.internalModalValue.id,
          photos: this.transformFilesToRequest(el.photos),
        })),
      };
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>
