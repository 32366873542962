<template>
  <div :class="{['checkbox-wrp']: true, ['checkbox-wrp__selected']: data.selected}" @click="selected">
    <div style="display: flex; gap: 8px; align-items: center">
      <input
        v-model="data.selected"
        class="checkbox-wrp__checkbox"
        type="checkbox"
        :disabled="isDisabled"
      />
      <label class="checkbox-wrp__label">{{ data.label}}</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VTableCellBordersCheckbox',
  props: {
    data: { type: Object, required: true },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selected() {
      this.$emit('select');
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-wrp {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px 10px;
  border-radius: 4px;



  &__checkbox {
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
  }

  &__label {
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #262729;
    text-align: left;

  }

  &__selected {
    background: rgba(151, 156, 162, 0.3);
  }
}
</style>
