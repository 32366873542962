<template>
  <page title="Реестр физических лиц">
    <select-button
        :items="actionBlockItems"
    />

    <v-table-new
      v-model="transformedAccounts"
      :loading="loading"
      :template="template"
      excel-report-file-name="Реестр физических лиц"
    />

    <dialog-template
        v-model="displayConnectionDialog"
        :top="8"
        height100
        hide-close-button
        style-type="roundedStyle"
        @close="closeDialog"
    >
      <individual-connection-app
          @close="closeDialog"
          @route="loaded"
      />
    </dialog-template>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { Page, VTableNew } from '@/components';
import SelectButton from 'components/SelectButton/SelectButton.vue';
import IndividualConnectionApp from 'organisms/registration/individual-connection';
import DialogTemplate from 'templates/DialogTemplate';
import { ACCOUNT_PROFILE_TYPE } from 'views/private/UserManagement/AccountProfileType';

export default {
  name: 'RegisterIndividuals',
  components: {
    VTableNew,
    Page,
    SelectButton,
    IndividualConnectionApp,
    DialogTemplate,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      accounts: [],
      loading: false,
      displayConnectionDialog: false,
      actionBlockItems: [
        {
          id: '1',
          title: 'Зарегистрировать физическое лицо',
          method: () => {
            this.displayConnectionDialog = true;
          },
        },
      ],
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'ID',
            key: 'id',
            sort: {},
            filter: {},
          },
          {
            label: 'Фамилия',
            key: 'surname',
            sort: {},
            filter: {},
          },
          {
            label: 'Имя',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'Отчество',
            key: 'patronymic',
            sort: {},
            filter: {},
          },
          {
            label: 'СНИЛС',
            key: 'snils',
            sort: {},
            filter: {},
          },
        ],
      };
    },
    transformedAccounts() {
      if (this.accounts.length === 0) {
        return [];
      }

      return this.accounts.map((it) => {
        let fullName;
        let snils;

        if (it.authorizations && it.authorizations[0]?.user) {
          fullName = it.authorizations[0]?.user?.fullName;
          snils = it.authorizations[0]?.user?.snils;
        } else {
          fullName = it.profile.fullName;
          snils = it.profile.snils;
        }

        return {
          id: it.id,
          name: fullName?.name,
          surname: fullName?.surname,
          patronymic: fullName?.patronymic,
          snils: snils,
        };
      });
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        this.accounts = await this.accountsByFilter({ filter: { profileType: ACCOUNT_PROFILE_TYPE.INDIVIDUAL } });
      } catch (error) {
        console.log(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.displayConnectionDialog = false;
    },
    loaded() {
      this.closeDialog();
      this.loadData();
    },
    ...mapActions('user', ['accountsByFilter', 'setNotification']),
  },
};
</script>
