<template>
  <div class="form">
    <div class="form-header">
      <h1 class="form-header__title">Создание платёжного поручения</h1>
    </div>
    <v-divider />
    <v-select
      v-model="act"
      :options="serviceAndLossActsOptions"
      title="На основании"
      required
      rules="required"
      :rules-messages="{required: 'Обязательно для заполнения'}"
    />
    <v-divider />
    <div v-if="actDocumentDomainId.length > 0" style="display: flex; flex-direction: column; gap: 24px">
      <v-input v-model="formatTotalCost" disabled title="Сумма, руб" />
      <v-select
          v-model="paymentKind"
          :options="paymentKindList"
          title="Тип платежа"
          required
          rules="required"
          :rules-messages="{required: 'Обязательно для заполнения'}"
          style="margin-top: 24px"
      />
      <p class="step-title">Выбор реквизитов:</p>
      <v-spoiler summary="Сторона 1">
        <company-info
          with-company-name
          non-editable
          is-new-style
          vid="performerFirst"
          :company-info="actInfo.performerFirst?.companyInfo ?? actInfo.supplier?.companyInfo"
          @input="inputPerformerFirstBankRequisites"
          @select-bank="selectPerformerFirstBankRequisites"
        />
      </v-spoiler>
      <v-spoiler summary="Сторона 2">
        <company-info
          with-company-name
          non-editable
          is-new-style
          vid="performerSecond"
          :company-info="actInfo.performerSecond.companyInfo"
          @input="inputPerformerSecondBankRequisites"
          @select-bank="selectPerformerSecondBankRequisites"
        />
      </v-spoiler>
    </div>
  </div>
</template>

<script>
import { CompanyInfo, VDivider, VInput, VSelect, VSpoiler } from '../../../../../components';
import { mapActions, mapGetters } from 'vuex';
import formatting from 'lib/utils/formatting.js';

export default {
  name: 'GeneratePaymentOrder',
  components: {
    VSpoiler,
    VInput,
    VSelect,
    VDivider,
    CompanyInfo,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      acts: [],
      actInfo: null,
      actDocumentDomainId: '',
      loading: false,
      paymentKind: '',
    };
  },
  computed: {
    act: {
      get() {
        if (!this.actDocumentDomainId) return '';
        return this.serviceAndLossActsOptions.find((it) => it.value === this.actDocumentDomainId).value;
      },
      set(value) {
        this.actInfo = this.acts.find((it) => it.id === value);
        this.actDocumentDomainId = this.actInfo.id;
        this.setActDocumentDomainId({ actDocumentDomainId: this.actInfo.id });
        this.setTotalCost({ totalCost: this.actInfo.totalCost });
      },
    },
    paymentOrder() {
      return this.getPaymentOrders;
    },
    formatTotalCost() {
      return formatting.numberFormatted(this.paymentOrder.totalCost);
    },
    serviceAndLossActsOptions() {
      return this.acts.filter((it) => (it.status === 'SIGNED_BY_INITIATOR' || it.status === 'SIGNED') && it.performerSecond.accountId !== this.getAccount.id)
        .map((it) => ({
          label: it.title,
          value: it.id,
          status: it.status,
        }));
    },
    ...mapGetters('paymentOrders', ['getPaymentOrders']),
    ...mapGetters('user', ['getAccount']),
    ...mapGetters('dataLists', ['paymentKindList']),
  },
  watch: {
    paymentKind(val) {
      this.setPaymentKind({ paymentKind: val });
    },
  },
  methods: {
    inputPerformerFirstBankRequisites(value) {
      const bank = {
        bankName: value.bankName,
        bik: value.bik,
        correspondentAccount: value.correspondentAccount,
        settlementAccount: value.settlementAccount,
      };
      this.setPerformerFirstBankRequisites({ performerFirstBankRequisites: bank });
    },
    inputPerformerSecondBankRequisites(value) {
      const bank = {
        bankName: value.bankName,
        bik: value.bik,
        correspondentAccount: value.correspondentAccount,
        settlementAccount: value.settlementAccount,
      };
      this.setPerformerSecondBankRequisites({ performerSecondBankRequisites: bank });
    },
    selectPerformerFirstBankRequisites(bank) {
      delete bank.__typename;
      this.setPerformerFirstBankRequisites({ performerFirstBankRequisites: bank });
    },
    selectPerformerSecondBankRequisites(bank) {
      delete bank.__typename;
      this.setPerformerSecondBankRequisites({ performerSecondBankRequisites: bank });
    },
    async loadData() {
      try {
        this.loading = true;
        this.acts = await this.loadServiceActs();
        const lossActs = await this.loadLossActs();
        this.acts.push(...lossActs);
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('cabinet', ['loadServiceActs', 'loadLossActs']),
    ...mapActions('user', ['setNotification']),
    ...mapActions('paymentOrders', [
      'setActDocumentDomainId',
      'setTotalCost',
      'setPerformerFirstBankRequisites',
      'setPerformerSecondBankRequisites',
      'setPaymentKind',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 864px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 850px;
    }

    &__subtitle {
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4C4E51;
    }
  }
}

.step-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #4C4E51;
}
</style>
