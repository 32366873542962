<template>
  <inner-page :current-step="getFormAttorney.currentStep"
              :home="getFormAttorney.homeTitle"
              :steps="getFormAttorney.steps"
              class="form-attorney-summary"
              @back="back"
              @goHome="goHome"
              @openStep="openStep"
  >
    <h1 v-if="getFormAttorney.typeFormAttorney === 'form'" class="form-attorney-summary__title">
      Сформировать машиночитаемую доверенность
    </h1>
    <h1 v-else class="form-attorney-summary__title">Карточка доверенности</h1>
    <v-divider/>

    <div>
      Сотрудник: <span class="form-attorney-summary__strong-text">{{ getFormAttorney.userName }}</span>
    </div>

    <div class="form-attorney-summary__selected-rights-list">
      <span class="form-attorney-summary__strong-text">Выданные права:</span><br/>
      <div v-for="(item, index) in getFormAttorney.selectedRights" :key="index"
           class="form-attorney-summary__selected-rights">
        <span class="form-attorney-summary__strong-text">{{ item.value }} - </span>{{ item.description }};
      </div>
    </div>

    <div class="form-attorney-summary__dates">
      <div class="form-attorney-summary__start-date">Начало действия: <span class="form-attorney-summary__strong-text">{{
          getFormAttorney.startDate
        }}</span>
      </div>
      <div class="form-attorney-summary__end-date">Окончание действия: <span class="form-attorney-summary__strong-text">{{
          getFormAttorney.endDate
        }}</span>
      </div>
    </div>

    <v-divider/>
    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="back">
          Назад
        </button>
        <button class="btn-delete-draft" @click="displayDeleteDialog = true">
          УДАЛИТЬ ЧЕРНОВИК
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button :disabled="isWaitingSubmit" class="btn-save" @click="submit">
          Загрузить
        </button>
      </div>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <div class="delete-dialog">
        <p class="delete-dialog__title">
          Удалить черновик?
        </p>
        <div class="delete-dialog__action">
          <button class="btn-delete-draft" @click="deleteDraft">
            УДАЛИТЬ
          </button>
          <button class="btn-cancel" @click="displayDeleteDialog = false">
            ЗАКРЫТЬ
          </button>
        </div>
      </div>
    </dialog-template>
  </inner-page>
</template>

<script>
import { InnerPage, VDivider } from '@/components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import HTTPError from 'lib/utils/errors';
import { generatePowerOfAttorney } from './xml.js';
import DialogTemplate from 'templates/DialogTemplate';

export default {
  name: 'UploadAttorneySummary',
  components: {
    InnerPage,
    VDivider,
    DialogTemplate,
  },
  async created() {
    if (!this.getFormAttorney.snils) {
      this.updateFormAttorney(
        {
          ...this.getFormAttorney,
          currentStep: this.getFormAttorney.currentStep ? this.getFormAttorney.currentStep - 1 : 0,
        },
      );
      this.$router.push('/cabinet/form-attorney');
    }
  },
  data() {
    return {
      users: [],
      isWaitingSubmit: false,
      submitError: '',
      displayDeleteDialog: false,
    };
  },
  computed: {
    isDigAttorney() {
      return this.getFormAttorney.typeFormAttorney === 'form';
    },
    isExternalAttorney() {
      return this.getFormAttorney.typeFormAttorney === 'xml';
    },
    ...mapGetters('attorney', ['getFormAttorney']),
    ...mapGetters('user', ['getAuthorization', 'getAccount']),
  },
  methods: {
    ...mapMutations('user', ['SET_NOTIFICATION']),
    goHome() {
      this.$router.push(this.getFormAttorney.homeUrl);
    },
    deleteDraft() {
      this.updateFormAttorney({
        homeTitle: this.getFormAttorney.homeTitle,
        homeUrl: this.getFormAttorney.homeUrl,
      });
      this.goHome();
    },
    openStep(index) {
      const isBeforeAtCurrent = this.getFormAttorney.currentStep > index - 1;
      const isUrlExist = this.getFormAttorney.steps[index - 1]?.url;

      if (isBeforeAtCurrent && isUrlExist) {
        this.updateFormAttorney(
          {
            ...this.getFormAttorney,
            currentStep: index - 1,
          },
        );
        this.$router.push(this.getFormAttorney.steps[index - 1].url);
      }
    },
    back() {
      if (!this.getFormAttorney.steps[this.getFormAttorney.currentStep - 1]?.url) {
        this.goHome();
        return;
      }

      this.openStep(this.getFormAttorney.currentStep);
    },
    async submit() {
      this.isWaitingSubmit = true;
      this.submitError = '';

      const employees = await this.getAccountEmployees();
      const ownerAuth = employees.authorizations.find((it) => it.type === 'GENERAL_DIRECTOR');
      const workerAuth = employees.authorizations.find((item) => item.user.snils === this.getFormAttorney.snils);
      let xml = null;
      let existingSignature = null;
      try {
      if (this.isExternalAttorney) {
        xml = this.getFormAttorney.externalXml;
        existingSignature = this.getFormAttorney.externalSignature;
      } else {
        xml = generatePowerOfAttorney(
          this.isDigAttorney,
          this.getFormAttorney,
          this.getAuthorization.authorization.account,
          this.getAccount,
          ownerAuth.user,
          workerAuth.user,
          ownerAuth,
          workerAuth,
        );
      }


        const result = await this.documentUpload({
          xml: xml,
          existingSignature: existingSignature,
          isCap: false,
          sourceDocumentId: this.getFormAttorney.sourceDocumentId,
        });
        if (result) {
          this.updateFormAttorney({
            homeTitle: this.getFormAttorney.homeTitle,
            homeUrl: this.getFormAttorney.homeUrl,
          });
          this.goHome();
        }
      } catch (error) {
        const regex = /.*:\s(.*)/;
        const match = error.message.match(regex);
        const errorText = match ? match[1] : error.message;
        this.SET_NOTIFICATION({
          notificationType: 'error',
          notificationText: `${errorText}`,
        });
        if (error.name === 'CRYPTOPRO_CERTIFICATES_ERROR' || error.name === 'CRYPTOPRO_ERROR') {
          this.submitError = error.message;
        } else {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
        }
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('attorney', ['updateFormAttorney']),
    ...mapActions('user', ['getAccountEmployees', 'getUserById']),
    ...mapActions('document', ['documentUpload']),
  },
};

</script>

<style lang="scss" scoped>
.form-attorney-summary {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4C4E51;

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
  }

  &__selected-rights {
    margin-top: 4px;
  }

  &__retrust {
    font-weight: 600;
  }

  &__dates {
    display: flex;
    gap: 16px;
  }

  &__start-date, &__end-date {
    width: 100%;
  }

  &__error-text {
    width: 100%;
    text-align: center;
  }

  .action-buttons {
    display: flex;

    button {
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      border-radius: 24px;
      color: #0E0F0F;
      background: #FFFFFF;
      padding: 6px 25px 6px 25px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    .btn-save {
      color: #FFFFFF;
      background: #2F82DF;

      &:hover {
        background: #1f5b9b;
      }

      &:disabled {
        background: #717d8f;
      }
    }

    .btn-delete-draft {
      background-color: red;
      margin-left: 16px;
      color: #FFFFFF;

      &:hover {
        background-color: #831c1c;
      }
    }

    &__action {
      width: 100%;

      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;

        button {
          margin-right: 16px;
        }
      }
    }
  }

  &__strong-text {
    font-weight: 600;
  }
}

.delete-dialog {
  &__action {
    display: flex;
    margin-top: 64px;

    button {
      width: 50%;
      height: 40px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;

      border: 1px solid #C1C4C7;
      color: #FFFFFF;
      background: #71757A;
      padding: 6px 25px 6px 25px;

      &:hover {
        background: #525459;
      }
    }

    .btn-delete-draft {
      background-color: red;

      &:hover {
        background-color: #831c1c;
      }
    }
  }

  &__title {
    font-family: Roboto;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000;
    margin-top: 64px;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }

}
</style>
