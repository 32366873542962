import validate from 'lib/utils/validationRules';
import dayjs from 'dayjs';
import { getXlsFormat } from 'lib/utils/date';

function isNotEmptyItem(item) {
  return validate.isNotEmptyItem(item);
}

function isEmptyItem(item) {
  return validate.isEmptyItem(item);
}

const errorTexts = {
  required: 'Поле обязательно для заполнения. ',
  number: 'Некорректный формат. Корректный формат: число. ',
  positiveInteger: 'Некорректный формат. Корректный формат: целое положительное число. ',
  greaterZero: 'Значение должно быть больше нуля. ',
  ktNumber: 'Некорректный формат. Корректный формат: два числа через слэш. Например: 100/50. ',
  incorrectFormat: 'Некорректный формат. ',
  maxLength255: 'Максимальная длина строки 255 символов. ',
  maxLength1024: 'Максимальная длина строки 1024 символов. ',
  date: 'Некорректный формат. Корректный формат: дата. ',
  greaterCurrent: (date) => `Дата (${date}) не может быть меньше, чем текущая дата. `,
  lessCurrent: (date) => `Дата (${date}) не может быть больше, чем текущая дата. `,
  accountingRatio: 'В случае отсутствия прибора учёта, необходимо указать значение 1. ',
  valueInLabelList: (value) => `Некорректное значение: ${value}. `,
  nextCalibrationDate: (value, calibrationDate, calibrationInterval) => `Некорректное значение: ${value}. Дата очередной поверки должна быть равна Дате поверки (${calibrationDate.format('DD/MM/YYYY')}) + Межповерочный интервал, лет (${calibrationInterval})`,
};

export function check(value, template, parsed, line, objectValues) {
  if (!template.rules && !parsed.rules) {
    return { isNotValid: false };
  }

  const rules = parsed.rules ? [...parsed.rules.split('|')] : [];

  if (template.rules) {
    rules.push(...template.rules.split('|'));
  }
  let validResult = true;
  let errorText = '';
  rules.forEach((rule) => {
    switch (rule.trim()) {
      case 'required': {
        if (isEmptyItem(value)) {
          validResult = false;
          errorText += errorTexts.required;
        }
        break;
      }
      case 'accountingRatio': {
        if (isEmptyItem(value) || isNaN(Number(value))) {
          validResult = false;
          errorText += errorTexts.accountingRatio;
        }
        break;
      }
      case 'requiredByAccountingMethodValueConstOrVariable': {
        const accountingMethodValue = objectValues && objectValues.measurementsReadingKind ? objectValues.measurementsReadingKind : line[parsed.measurementsReadingKind.colIndex];
        if ((accountingMethodValue === 'const' || accountingMethodValue === 'variable') && isEmptyItem(value)) {
          validResult = false;
          errorText += errorTexts.required;
        }
        break;
      }
      case 'requiredByAccountingMethodValuePower': {
        const accountingMethodValue = objectValues && objectValues.measurementsReadingKind ? objectValues.measurementsReadingKind : line[parsed.measurementsReadingKind.colIndex];
        if (accountingMethodValue === 'power' && isEmptyItem(value)) {
          validResult = false;
          errorText += errorTexts.required;
        }
        break;
      }
      case 'requiredGroup': {
        if (isEmptyItem(value) && !template.notRequiredInGroup) {
          const resultSome = Object.keys(parsed).some((key) => {
            if (parsed[key].colIndex) {
              return isNotEmptyItem(line[parsed[key].colIndex]);
            }
          });

          if (resultSome) {
            validResult = false;
            errorText += errorTexts.required;
          }
        }
        break;
      }
      case 'allowedValues': {
        if (isNotEmptyItem(value) && !template.allowedValues.includes(value)) {
          validResult = false;
          errorText += `${errorTexts.valueInLabelList(value)} Допустимые значения: ${template.allowedValues?.join(', ')}`;
        }
        break;
      }
      case 'number': {
        if (isNotEmptyItem(value) && isNaN(Number(value))) {
          validResult = false;
          errorText += errorTexts.number;
        }
        break;
      }
      case 'positiveInteger': {
        if (isNotEmptyItem(value) && (isNaN(Number(value)) || value.indexOf('.') !== -1 || value.indexOf(',') !== -1 || value.indexOf('-') !== -1)) {
          validResult = false;
          errorText += errorTexts.positiveInteger;
        }
        break;
      }
      case 'ktNumber': {
        if (isNotEmptyItem(value) && value.match(/^\d+\/\d+$/g) === null) {
          validResult = false;
          errorText += errorTexts.ktNumber;
        }
        break;
      }
      case 'inn': {
        const errorInn = { message: '' };

        if (isNotEmptyItem(value) && !validate.validateInn(value, errorInn)) {
          validResult = false;
          errorText += errorTexts.incorrectFormat + errorInn.message;
        }
        break;
      }
      case 'maxLength255': {
        if (isNotEmptyItem(value) && value.length > 255) {
          validResult = false;
          errorText += errorTexts.maxLength255;
        }
        break;
      }
      case 'maxLength1024': {
        if (isNotEmptyItem(value) && value.length > 1024) {
          validResult = false;
          errorText += errorTexts.maxLength1024;
        }
        break;
      }
      case 'date': {
        if (isEmptyItem(value)) {
          break;
        }

        const date = dayjs(value, 'DD/MM/YYYY', true);

        if (!date.isValid()) {
          validResult = false;
          errorText += errorTexts.date;
        }
        break;
      }
      case 'greaterCurrent': {
        if (isEmptyItem(value)) {
          break;
        }

        const date = dayjs(value, 'DD/MM/YYYY', true);

        if (dayjs().startOf('day').diff(date, 'day') > 0) {
          validResult = false;
          errorText += errorTexts.greaterCurrent(value);
        }

        break;
      }
      case 'lessCurrent': {
        if (isEmptyItem(value)) {
          break;
        }

        const date = dayjs(value, 'DD/MM/YYYY', true);

        if (dayjs().startOf('day').diff(date, 'day') < 0) {
          validResult = false;
          errorText += errorTexts.lessCurrent(value);
        }

        break;
      }
      case 'nextCalibrationDate': {
        if (isEmptyItem(value)) {
          break;
        }

        const calibrationDateStr = objectValues && objectValues.meter ? objectValues.meter.calibrationDate : line[parsed.calibrationDate.colIndex];

        if (isEmptyItem(calibrationDateStr)) {
          break;
        }

        const calibrationInterval = objectValues && objectValues.meter ? objectValues.meter.calibrationInterval : line[parsed.calibrationInterval.colIndex];

        if (isEmptyItem(calibrationInterval)) {
          break;
        }

        const calibrationDate = dayjs(getXlsFormat(calibrationDateStr), 'DD/MM/YYYY', true);

        const date = dayjs(value, 'DD/MM/YYYY', true);

        if (calibrationDate.add(calibrationInterval, 'year').diff(date, 'month') !== 0) {
          validResult = false;
          errorText += errorTexts.nextCalibrationDate(value, calibrationDate, calibrationInterval);
        }

        break;
      }
      case 'greaterZero': {
        if (isNaN(parseFloat(value))) {
          errorText += errorTexts.number;
          validResult = false;
          break;
        }

        if (parseFloat(value) <= 0) {
          errorText += errorTexts.greaterZero;
          validResult = false;
        }
        break;
      }
    }
  });

  return {
    isNotValid: !validResult,
    errorText: errorText,
  };
}
