<template>
  <v-table-new
    v-model="transformedWarehouseStockSummary"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="warehouseStockSummary.pageOut"
    :template="template"
    excel-report-file-name="Складские операции. К приёмке"
    server-side-sorting
    table-name="warehouseForAcceptance"
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { mapGetters } from 'vuex';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import { mapFilters } from 'lib/utils/table-pagination';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'WarehouseForAcceptanceTable',
  components: { VTableNew },
  data() {
    return {
      warehouseStockSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Мероприятие',
            key: 'eventName',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: { type: 'eventsOdk' },
          },
          {
            label: 'Подрядчик',
            key: 'contractorName',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: this.contractorFilter,
          },
          {
            label: 'АХП',
            key: 'axpName',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Название элемента',
            key: 'elementName',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Тип декора',
            key: 'decorName',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Единицы измерения',
            key: 'unitName',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
          },
          {
            label: 'Количество по плану',
            type: 'number',
            key: 'planCount',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество принято',
            type: 'number',
            key: 'acceptedCount',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество к приёмке',
            type: 'number',
            key: 'forAcceptanceCount',
            thStyle: {
              minWidth: '100px',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Приёмка не произошла',
            key: 'notAccepted',
            thStyle: {
              minWidth: '60px',
            },
            sort: {},
            filter: {
              type: 'checkbox',
              label: 'Только приёмка не произошла',
            },
          },
        ],
      };
    },
    transformedWarehouseStockSummary() {
      if (!this.warehouseStockSummary || !this.warehouseStockSummary.data || !Array.isArray(this.warehouseStockSummary.data)) {
        return [];
      }
      return this.warehouseStockSummary.data.map(this.transformed);
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),

  },
  methods: {
    async transformFilter() {
      const filterMapping = {
        contractorName: {
          name: 'contractorName',
        },
        axpName: {
          name: 'axpName',
        },
        eventName: {
          name: 'eventName',
        },
        notAccepted: {
          name: 'onlyNotAccepted',
        },
      };

      const result = await mapFilters(
        filterMapping,
        this.filter,
      );

      if (!this.isBoiler) {
        result.contractorId = this.getAccount.supplier.id;
      }
      return result;
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('warehouse-stock-summary-for-acceptance', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.warehouseStockSummary = responseData;
        this.warehouseStockSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('warehouseStockSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('warehouse-stock-summary-for-acceptance', 1, this.warehouseStockSummary.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed);
      } catch (error) {
        console.log('warehouseStockSummaryAxp', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async fetchReport() {
      this.loading = true;
      const filters = await this.transformFilter();
      const { field: sortField, type: sort } = this.sort;
      const result = await fetchData('warehouse-stock-summary-for-acceptance', 1, this.pagination.totalElements, sortField, sort, filters);
      this.loading = false;
      return result;
    },
    transformed(item, index) {
      return {
        ...item,
        index: index + 1,
        eventId: item.eventId,
        eventName: item.eventName,
        axpName: item.axpName,
        contractorName: item.contractorName,
        elementName: item.elementName,
        decorName: item.decorName,
        unitName: item.unitName,
        planCount: item.planCount,
        acceptedCount: item.acceptedCount,
        forAcceptanceCount: item.forAcceptanceCount,
        notAccepted: convertBooleanToText(item.notAccepted),
      };
    },
  },

};
</script>

<style scoped>

</style>
