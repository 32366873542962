<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">Детали задачи</p>
      <p :class="$style.stepDate">Дата создания документа:<span>{{ convertDbDateTimeFormat(document.createdAt) }}</span>
      </p>
      <p :class="$style.stepDate">Дата обновления документа:<span>{{ convertDbDateTimeFormat(document.updatedAt)
        }}</span></p>

      <p :class="$style.description">Наименование Объекта проверки</p>
      <p :class="$style.stepDate">{{ document.title }}</p>

    </div>

    <div v-if="actVerification?.documentId" :class="$style.content">
      <v-file-attachment
        :document-id="actVerification.documentId"
        label="Акт верификации"
      />
    </div>

    <div v-if="document?.documentId" :class="$style.content">
      <v-file-attachment
        :document-id="document.documentId"
        :label="getTitleOfAttachment()"
      />
    </div>

    <div v-if="isRejected" :class="$style.content" style="margin-top: 24px">
      <p :class="$style.description">Отклонён</p>
      <p v-for="(rejected, index) in document.sides" :key="'rejected' + index"
         :class="$style.stepDate">
        {{ rejected.status === 'REJECTED' ? rejected.name : '' }}
      </p>
      <p :class="$style.stepDate">
        {{ `Комментарий: ${spoilerText}` }}
        <span v-if="document.rejectedReason.length > 32" :class="$style.spoiler" @click="spoiler = !spoiler">
            {{ spoiler ? '(показать всё)' : '(скрыть)' }}
        </span>
      </p>
    </div>

    <div v-if="powerOfAttorneyList.length > 0">
      <div :class="$style.content">
        <p :class="$style.stepTitle">Доверенности подписывающего лица</p>
      </div>
      <div v-for="(doc, idx) in powerOfAttorneyList" :key="idx">
        <div :class="$style.content">
          <div :class="{[$style.attachment]: true, [$style.waiting]: waitingDocXml}">
            <p :class="$style.fileAttachment"
               @click="powerAttorney(doc.contentType, doc.filename, doc.fileDownloadLink, doc.id)">
              <img alt="" height="80" src="~@/assets/icons/file/attachment.svg" width="80" />
              <span>{{ doc.filename }}</span>
            </p>
          </div>

          <div :class="{[$style.description]: true, [$style.waiting]: waitingDocXml}">
          <span :class="$style.action" @click="openPowerAttorneyView(doc.fileDownloadLink, doc.id)">
            <b v-if="isDisplayPowerAttorney && doc.id === powerAttorneyIdx"
               :class="$style.closeAction">Закрыть просмотр</b>
            <span v-else>Открыть просмотр</span>
          </span>
            <span :class="$style.action"
                  @click="powerAttorney(doc.contentType, doc.filename, doc.fileDownloadLink, doc.id)">Скачать</span>
            <horizontal-progress-bar v-if="waitingPowerAttorney && doc.id === powerAttorneyIdx" />
          </div>
        </div>

        <template v-if="isDisplayPowerAttorney && doc.id === powerAttorneyIdx">
          <div v-if="powerAttorneyPdfUrlView" :class="$style.viewPdf">
            <iframe :src="powerAttorneyPdfUrlView"></iframe>
          </div>
          <div v-else :class="$style.waitingPdf">
            <span>Загрузка...</span>
            <horizontal-progress-bar />
          </div>
        </template>
      </div>
    </div>

    <div :class="$style.content">
      <template v-if="!isRejected && !task.completed">
        <div :class="$style.statusGroup">

          <div :class="$style.radioGroup">
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 1"
                :class="$style.control"
                name="taskStatus"
                role="radio.approve"
                type="radio"
                @click="statusGroupClick(1)"

              />
              <div :class="$style.contentRadioGroup">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupCheck"></div>
                  <h3 :class="$style.titleRadioGroup">Нет замечаний</h3>
                </div>
              </div>
            </label>
            <label :class="$style.labelRadio">
              <input
                :checked="statusGroup === 2"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(2)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">Ошибка</h3>
                </div>
              </div>
            </label>
            <label v-if="isDisagreementsEnabled" :class="$style.labelRadio">
              <input
                :checked="statusGroup === 3"
                :class="$style.control"
                name="taskStatus"
                role="radio.reject"
                type="radio"
                @click="statusGroupClick(3)"
              />
              <div :class="$style.contentRadioGroup" style="margin-top: -16px">
                <div :class="$style.titleContainer">
                  <div :class="$style.buttonRadioGroupError"></div>
                  <h3 :class="$style.titleRadioGroup">Разногласия</h3>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div v-if="statusGroup === 2" :class="$style.correctionGroup">
          <p :class="$style.correctionTitle">
            Комментарий
          </p>
          <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

          <div :class="$style.blockContent">
            <c-textarea
              v-model="comments"
              role="correctionComments"
            />
          </div>
        </div>

        <div v-if="statusGroup === 3 && task.enumDocumentType==='ELECTRICITY_BALANCE'" :class="$style.correctionGroup">
          <p :class="$style.correctionTitle">
            Величина услуги при разногласиях
          </p>
          <div :class="$style.blockContent">
            Услуга по одноставке, кВт.ч
            <c-input
              v-model="paymentVolumeOnePartElectricity"
              role="correctionPowerService"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Услуга по двухставке, кВт.ч
            <c-input
              v-model="paymentVolumeTwoPartElectricity"
              role="correctionPowerService"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Услуга по мощности, МВт
            <c-input
              v-model="paymentVolumeTwoPartPower"
              role="paymentVolumepaymentVolumeTwoPartPower"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            <c-checkbox
              v-model="accountingTransit"
              label="Учитывать транзит в услуге"
              role="additionalOwner"
              @input="handleCheckBox"
            />
          </div>
          <div v-if="accountingTransit" :class="$style.blockContent">
            Услуга транзит, кВт.ч
            <c-input
              v-model="totalTransit"
              role="paymentVolumeTransit"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Общая величина услуги, кВт.ч
            <c-input
              v-model="paymentVolume"
              :disabled="true"
              role="paymentVolumeTotal"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Скорректированный полезный отпуск, кВт.ч
            <c-input
              v-model="totalUsefulVacation"
              :disabled="true"
              role="totalUsefulVacation"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Величина потерь, кВт.ч
            <c-input
              v-model="totalLoss"
              :disabled="true"
              role="totalLoss"
              type="number"
            />
          </div>
          <div :class="$style.blockContent">
            Величина потерь, %
            <c-input
              v-model="totalLossPercent"
              :disabled="true"
              role="totalLoss"
              type="number"
            />
          </div>
        </div>
      </template>

      <submit-task-details :class="{[$style.submit]: true, [$style.rejected]: isRejected}"
                           :disabled="task.completed || !isValid || isWaitingSubmit || isRejected || !statusGroup"
                           :title="actionButton" @submit="submit" />
      <horizontal-progress-bar v-if="isWaitingSubmit" />
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import axios from 'axios';
import VFileAttachment from 'components/VFileAttachment/VFileAttachment.vue';
import CInput from 'atoms/common/inputs/CInput';
import CCheckbox from 'atoms/common/CCheckbox';
import { convertDbDateTimeFormat } from 'lib/utils/date';

export default {
  name: 'SelectType',
  components: {
    VFileAttachment,
    CInput,
    CCheckbox,
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    task: Object,
  },
  created() {
    this.loadData();
    if (this.task.enumDocumentType === 'ELECTRICITY_BALANCE') this.preProcessData();
  },
  data() {
    return {
      statusGroup: undefined,
      comments: '',
      actXml: undefined,
      docXml: undefined,
      waitingDocXml: false,
      isWaitingSubmit: false,
      submitError: '',
      waitingPowerAttorney: false,
      isDisplayPowerAttorney: false,
      powerAttorneyPdfUrlView: undefined,
      powerOfAttorneyListBase: [],
      powerAttorneyIdx: null,
      accountingTransit: false,
      paymentVolumeOnePartElectricity: 0,
      paymentVolumeTwoPartElectricity: 0,
      paymentVolumeTwoPartPower: 0,
      totalTransit: 0,
      paymentVolume: 0,
      totalUsefulVacation: 0,
      totalLoss: 0,
      totalLossPercent: 0,
      spoiler: true,
      document: {},
      actVerification: undefined,
    };
  },
  computed: {
    spoilerText() {
      if (!this.document.rejectedReason)
        return;
      return this.spoiler && this.document.rejectedReason.length > 32
        ? `${this.document.rejectedReason.split(' ').slice(0, 32).join(' ')}...`
        : this.document.rejectedReason;
    },
    isValid() {
      return !this.task.rejectEnabled || (
        this.statusGroup === 1 || (this.statusGroup === 2 && this.comments.length > 3)
      ) || (this.statusGroup === 3 && this.isValidDisagreement);
    },
    isRejected() {
      return this.document.status === 'REJECTED';
    },
    isValidDisagreement() {
      return this.task.enumDocumentType === 'ELECTRICITY_BALANCE' && parseFloat(this.paymentVolumeOnePartElectricity) > 0 &&
        parseFloat(this.paymentVolumeTwoPartElectricity) >= 0 &&
        parseFloat(this.paymentVolumeTwoPartPower) >= 0 &&
        parseFloat(this.totalTransit) >= 0 &&
        parseFloat(this.paymentVolume) >= 0 &&
        parseFloat(this.totalUsefulVacation) >= 0;
    },
    isDisagreementsEnabled() {
      return this.document.initiatorAccountId !== this.getAccount.id;
    },
    actionButton() {
      if (this.statusGroup === 3) {
        return 'СОГЛАСОВАТЬ С РАЗНОГЛАСИЯМИ';
      }
      if (this.statusGroup === 2) {
        return 'ОТКЛОНИТЬ';
      }
      if (this.task.status === 'DRAFT') {
        return `СОГЛАСОВАТЬ ${this.actVerification ? 'АКТ ВЕРИФИКАЦИИ' : 'ДОКУМЕНТ'}`;
      }
      if (this.isRejected) {
        return 'ОТКЛОНЕНО';
      }
      if (this.task.completed) {
        return this.task.completedTitle;
      }
      return `${this.task.actionTitle} ${this.actVerification ? 'АКТ ВЕРИФИКАЦИИ' : 'ДОКУМЕНТ'}`;
    },
    powerOfAttorneyList() {
      return this.powerOfAttorneyListBase;
    },
    ...mapGetters('user', ['getToken', 'getAccount']),
  },
  watch: {
    paymentVolumeOnePartElectricity() {
      this.accountService();
    },
    paymentVolumeTwoPartElectricity() {
      this.accountService();
    },
    totalTransit() {
      this.accountService();
      this.accountService();
    },
    accountingTransit() {
      this.accountService();
      this.accountUseful();
    },
    paymentVolume() {
      this.accountUseful();
    },
    totalUsefulVacation() {
      this.accountLoss();
    },
    totalLoss() {
      this.accountLossPercent();
    },
  },
  methods: {
    convertDbDateTimeFormat(date) {
      return convertDbDateTimeFormat(date);
    },
    accountService() {
      let result = parseFloat(this.paymentVolumeOnePartElectricity) + parseFloat(this.paymentVolumeTwoPartElectricity);
      if (this.accountingTransit) result += parseFloat(this.totalTransit);
      this.paymentVolume = result.toString();
    },
    accountUseful() {

      let result = parseFloat(this.paymentVolume) + parseFloat(this.balance.otherRetails) + parseFloat(this.balance.dkp);
      if (!this.accountingTransit) result += parseFloat(this.totalTransit);
      this.totalUsefulVacation = result.toString();
    },
    accountLoss() {
      this.totalLoss = (parseFloat(this.balance.totalInput) - parseFloat(this.totalUsefulVacation)).toString();
    },
    accountLossPercent() {
      // TODO divide input
      this.totalLossPercent = ((parseFloat(this.totalLoss) / parseFloat(this.balance.totalInput) * 100.0).toFixed(2) ?? 0).toString();
      if (this.totalLossPercent === '-Infinity' || this.totalLossPercent === 'Infinity') this.totalLossPercent = '0';
    },
    handleCheckBox() {
      console.log(this.task.accountingTransit);
    },
    async preProcessData() {
      console.log(this.task, 'CCC');
      try {
        this.balance = await this.loadElectricityBalance({ id: this.task.domainDocumentId });
      } catch (error) {
        console.log('loadElectricityBalance', error);
      }
      this.accountingTransit = this.balance.accountingTransit === 'true';
      this.paymentVolumeOnePartElectricity = this.balance.paymentVolumeOnePartElectricity;
      this.paymentVolumeTwoPartElectricity = this.balance.paymentVolumeTwoPartElectricity;
      this.paymentVolumeTwoPartPower = this.balance.paymentVolumeTwoPartPower;
      this.totalTransit = this.balance.totalTransit;
      this.paymentVolume = this.balance.paymentVolume;
      this.totalUsefulVacation = this.balance.totalUsefulVacation;
      this.totalLoss = this.balance.totalLoss;
      this.totalLossPercent = this.balance.totalLossPercent;
    },
    async powerAttorney(type, name, url, id) {
      this.powerAttorneyIdx = id;
      this.waitingPowerAttorney = true;
      const token = this.getToken;
      axios.get(url, { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log('download error', error.message);
          alert('Ошибка загрузки');
        })
        .finally(() => {
          this.waitingPowerAttorney = false;
          this.powerAttorneyIdx = null;
        });
    },
    async loadData() {
      try {
        this.document = await this.getDomainDocument(this.task.domainDocumentId);
        console.log('Document', this.document);
        if (this.document.actVerification?.id) {
          this.actVerification = await this.getDomainDocument(this.document.actVerification.id);
          this.powerOfAttorneyListBase = this.actVerification.powerOfAttorney;
        } else {
          this.powerOfAttorneyListBase = this.document.powerOfAttorney;
        }
        await this.downloadActXml();
      } catch (error) {
        console.log('loadData', error);
      }
    },
    statusGroupClick(val) {
      this.statusGroup = val;
      if (val === 1) {
        this.submitTitle = 'СОГЛАСОВАТЬ';
      } else {
        this.submitTitle = 'ОТПРАВИТЬ ЗАМЕЧАНИЯ';
      }
    },
    async downloadActXml() {
      if (!this.actVerification) {
        try {
          this.waitingXml = true;
          const pureData = await this.loadDocument(this.document.documentId);
          this.actXml = pureData.xml;
          this.waitingXml = false;
        } catch (error) {
          this.waitingXml = false;
        }
      } else {
        try {
          this.waitingXml = true;

          const pureData = await this.loadDocument(this.actVerification.documentId);

          this.actXml = pureData.xml;
          this.waitingXml = false;
        } catch (error) {
          this.waitingXml = false;
        }
      }
    },
    async openPowerAttorneyView(url, id) {
      const index = id;
      this.isDisplayPowerAttorney = !this.isDisplayPowerAttorney;
      if (this.powerAttorneyPdfUrlView) {
        if (this.powerAttorneyIdx !== index) {
          this.powerAttorneyPdfUrlView = undefined;
          this.isDisplayPowerAttorney = true;
        } else {
          this.powerAttorneyPdfUrlView = undefined;
          this.isDisplayPowerAttorney = false;
          return;
        }
      }
      this.powerAttorneyIdx = index;

      try {
        const pdfDoc = await this.getDocumentByUrl(url);

        this.powerAttorneyPdfUrlView = URL.createObjectURL(pdfDoc);
      } catch (error) {
        console.log('error documents docPdf', error);
        this.isDisplayPowerAttorney = false;
        this.powerAttorneyIdx = null;
        alert('Ошибка загрузки');
      }
    },
    async submit() {
      console.info(`Submitting documents: ${this.task.workflowType}`);

      if (this.task.workflowType === 'documentSigning' && this.statusGroup !== 2) {
        this.isWaitingSubmit = true;
        this.submitError = '';
        try {
          const result = await this.documentUpload({ xml: this.actXml, isCap: true });
          if (result) {
            await this.setWorkflows({ userType: this.getUserType });
            this.$emit('complete', true);
            this.$emit('close');
          }
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.isWaitingSubmit = false;
        }
        return;
      }

      if (this.task.workflowType === 'documentAgreement' || this.statusGroup === 2) {
        let reviewType = '';
        let comments = '';
        const params = {};

        if (this.statusGroup === 1) {
          reviewType = 'document-approved';
        } else if (this.statusGroup === 2) {
          reviewType = 'document-rejected';
          comments = this.comments;
        } else if (this.statusGroup === 3) {
          reviewType = 'act-verification-disagreement';
          comments = this.comments;
        }

        try {
          this.isWaitingSubmit = true;

          let documentReviewXml;
          if (this.statusGroup === 3 && this.task.enumDocumentType === 'ELECTRICITY_BALANCE') {
            console.log('D', this.totalLossPercent);
            documentReviewXml = generateXml.documentReviewXml(
              this.task.documentId,
              'electricity-balance-disagreement',
              this.comments,
              [],
              {
                PaymentVolume: this.paymentVolume,
                TotalLoss: this.totalLoss,
                TotalLossPercent: this.totalLossPercent,
                PaymentVolumeOnePartElectricity: this.paymentVolumeOnePartElectricity,
                PaymentVolumeTwoPartElectricity: this.paymentVolumeTwoPartElectricity,
                PaymentVolumeTwoPartPower: this.paymentVolumeTwoPartPower,
                TotalTransit: this.totalTransit,
                AccountingTransit: this.accountingTransit,
                TotalUsefulVacation: this.totalUsefulVacation,
              },
            );
          } else if (!this.actVerification) {
            documentReviewXml = generateXml.documentReviewXml(
              this.document.documentId,
              reviewType,
              comments,
              [],
              params,
            );
          } else {
            documentReviewXml = generateXml.documentReviewXml(
              this.actVerification.documentId,
              reviewType,
              comments,
              [],
              params,
            );
          }

          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            // TODO возможно, нужно ждать изменения состояния
            this.isWaitingSubmit = false;
            this.$emit('complete', true);
            this.$emit('close');
          }, 2000);
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.isWaitingSubmit = false;
        }
        return;
      }
    },
    getTitleOfAttachment() {
      return 'Документ';
    },
    errorHandler(error) {
      let splitError;
      if (error.name === 'CRYPTOPRO_CERTIFICATES_ERROR' || error.name === 'CRYPTOPRO_ERROR') {
        splitError = error.message;
      } else {
        splitError = error.message.split(': ')[2];
      }
      if (splitError === 'ADDITIONAL_BEFORE_SOURCE_SIGNED') {
        this.setNotification({ message: 'Доп.соглашение не может проходить этапы согласования до тех пор, пока исходный договор не будет подписан всеми сторонами' });
      } else {
        this.setNotification({ message: splitError });
      }
      return;
    },
    ...mapActions('document', ['loadDocument', 'getDomainDocument', 'documentUpload', 'getDocumentByUrl']),
    ...mapActions('cabinet', ['loadElectricityBalance', 'setWorkflows']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="sass" module>
.container
  position: relative
  height: 90%

.viewPdf
  width: 100%
  height: 100%
  margin-top: 16px

  iframe
    min-height: 450px
    height: 100%
    width: 100%

    html
      overflow-y: hidden

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 20px

    span
      margin-right: 12px
      color: #979CA2

  .stepDate
    +table-text-small
    margin-bottom: 34px

    span
      +table-data-small
      margin-left: 116px

  .description, .descriptionTitle
    +card-title-small
    margin-bottom: 32px

    span
      +table-text-small

    span.action, a.action
      +table-text-small
      text-decoration: underline
      margin-right: 8px
      cursor: pointer
      color: #0E0F0F !important

  .descriptionTitle
    margin-top: 32px

.contractType
  margin-top: 32px
  margin-bottom: 39px

.radioItem
  position: relative
  padding: 0 2px
  margin: 18px 0 0

  &:last-child
    margin-bottom: 38px

  input
    display: none

  label
    +base-text
    cursor: pointer

.disabled
  label
    cursor: default
    color: grey

.radioItem label:before
  content: " "
  display: inline-block
  position: relative
  top: 5px
  margin: 0 10px 0 0
  width: 20px
  height: 20px
  border-radius: 11px
  border: 2px solid #0E0F0F
  background-color: transparent

.radioItem input[type=radio]:checked + label:after
  border-radius: 11px
  width: 12px
  height: 12px
  position: absolute
  top: 9px
  left: 6px
  content: " "
  display: block
  background: #0E0F0F

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px
  cursor: pointer

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.titleRadioGroup
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 21px
  line-height: 29px
  // color: #C1C4C7
  margin-left: 10px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center


.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup
    color: #141412

    p
      color: #141412

    & .buttonRadioGroupCheck
      background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

    & .buttonRadioGroupError
      background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.statusGroup
  width: 100%
  max-width: 560px
  margin: 0 auto
  display: flex

.statusGroup
  margin-top: 40px

.correctionTitle
  padding-bottom: 12px
  +base-title

.mediumSpacing
  +medium-spacing

.blockContent
  &:last-child
    margin-bottom: 40px

  &:not(:last-child)
    +base-spacing

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.correctionGroup, .radioGroup
  margin: 0 auto 40px

.closeAction
  color: red

div.description
  display: inline-block
  margin-bottom: 0 !important

.waiting
  a, span
    cursor: default !important

.waitingPdf
  width: 70px
  margin: 32px auto 0

.submit
  margin-top: 32px

.errorText
  +small-text
  margin: 32px 4px -28px 4px
  color: red

.rejected
  background-color: red !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none
</style>
