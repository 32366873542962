<template>
  <Details v-model="isShownTaskDetails"
           :data="data"
           :summary-props="data.summaryProps"
           :is-display-action="data.isDisplayAction"
           :application-data="applicationData"
           :review-document="reviewDocument"
           :tariffs-data="tariffsData"
           :tariff-document="tariffDocument"
           :hide-offer-in-summary="data.hideOfferInSummary === true"
           :offer-document="offerDocument"
           :offer-document-title="data.offerDocumentTitle"
           :retail-review-document="retailReviewDocument"
           :additional-document="additionalDocument"
           :retailer-offer-document="retailerOfferDocument"
           :grid-company-name="gridCompanyName"
           :retail-company-name="retailCompanyName"
           :application-id="data.applicationId"
           :is-loading="isLoading"
           :create-date="createDate"
           :init-spoiler="data.initSpoiler"
  />
</template>

<script>
import Details from 'organisms/tasks/tariffs-update/Details';
import { mapActions, mapGetters } from 'vuex';
import { formatDateToCustomDateFormat } from 'lib/utils/date';
import { initTariffsUpdateTaskData } from 'organisms/tasks/tariffs-update/initData';
import router from '@/router';

export default {
  name: 'Card',
  components: {
    Details,
  },
  created() {
    this.task = this.getTaskDetails;

    if (!this.task?.workflowType) {
      router.push('/cabinet/tasks/');

      console.error('task loading error');
      return;
    }

    const initData = initTariffsUpdateTaskData(this.task, this.getUser.id);
    this.title = initData.title;
    this.currentStatus = initData.status;
    this.data = initData.data;
  },
  data() {
    return {
      data: {},
      title: '',
      currentStatus: {},
      applicationData: {},
      reviewDocument: {},
      tariffDocument: [],
      tariffsData: {},
      offerDocument: {},
      retailReviewDocument: {},
      additionalDocument: {},
      retailerOfferDocument: {},
      isDisplayAction: true,
      gridCompanyName: '',
      retailCompanyName: '',
      isLoading: true,
      createDate: '',
      task: {},
      isShownTaskDetails: true,
    };
  },
  computed: {
    ...mapGetters('user', ['getUser']),
    ...mapGetters('tasks', ['getTaskDetails']),
  },
  watch: {
    isShownTaskDetails: {
      immediate: true,
      async handler(newVal) {
        this.isLoading = true;
        if (newVal) {
          await this.loadData();
          this.isLoading = false;
        } else {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    async loadData() {
      if (this.task.documentId) {
        const pureDataPlatformTask = await this.loadDocument(this.task.documentId);
        const preparedPlatformTask = JSON.parse(pureDataPlatformTask.json);

        this.createDate = `Создана ${formatDateToCustomDateFormat(preparedPlatformTask.Date, 'DD.MM.YYYY')}`;
      }

      if (this.task.reviewDocumentId) {
        const pureDataReviewDoc = await this.loadDocument(this.task.reviewDocumentId);
        const preparedDataReviewDoc = JSON.parse(pureDataReviewDoc.json);

        let params = undefined;
        let firstInvalid = undefined;

        if (preparedDataReviewDoc.Params && preparedDataReviewDoc.Params.Param && preparedDataReviewDoc.Params.Param.length > 0) {
          params = {};
          preparedDataReviewDoc.Params.Param.forEach((item) => {
            params[item.name] = item.value;
          });
          firstInvalid = preparedDataReviewDoc.Params.Param[0].name;
        }

        this.reviewDocument = {
          comments: preparedDataReviewDoc.Comments,
          params: params,
          firstInvalid: firstInvalid,
        };
      }

      if (this.task.tariffOrderId) {
        this.tariffsData = await this.tariffsById(this.task.tariffOrderId);
        const pureDataTariffOrder = await this.loadDocument(this.tariffsData.documentId);
        const preparedTariffOrder = JSON.parse(pureDataTariffOrder.json);
        if (preparedTariffOrder.Documents) {
          this.tariffDocument = preparedTariffOrder.Documents.Document;
        }
      }
    },
    ...mapActions('document', ['loadDocument', 'tariffsById']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 452px
    position: relative
    background-color: #fff
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    margin-bottom: 16px
    margin-right: 16px

    &:hover
      cursor: pointer

  .content
    display: flex
    flex-direction: column
    align-items: space-between
    padding: 16px 32px 16px 16px

  .moreIcon
    position: absolute
    top: 8px
    right: 8px

  .title
    +card-task-title
    margin: 0 0 8px

  .subtitle
    margin: 0 0 8px
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 24px
    color: #0E0F0F
    letter-spacing: 0.01em

  .caption
    margin: 0 0 8px
    p
      +card-task-caption


  .process,
  .expairedDate,
  .process
    align-self: flex-end
    p
      +card-task-status
      text-align: right

  .process p
    color: #2F82DF

  .expairedDate p
    color: #ACB0B5

  .footer
    display: flex
    justify-content: space-between
    margin-top: 36px
</style>
