<!-- TODO: вынести в молекулу TitleExpansion -->
<template>
  <div :class="$style.header">
    <div :class="$style.headerContent">
      <div :class="{[$style.title]: true, [$style.small]: small}">
        {{ title }}
      </div>
      <div :class="$style.icons">
        <slot name="icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpoilerHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" module>
  .header
    display: flex
    align-items: stretch

  .headerContent
    flex: 1
    display: flex
    align-items: center

  .title
    font-family: 'Roboto Condensed'
    font-size: 24px
    line-height: 32px
    letter-spacing: 0.005em
    color: #000

  .small
    font-size: 16px !important
    line-height: 24px !important

  .icons
    margin-left: 8px
    display: flex
</style>
