var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subdivision-node",style:(_vm.styleNode)},[(!_vm.node.isEmpty)?[_c('div',{class:{
                ['subdivision-node-top']: true,
                ['subdivision-node-top--selected']: _vm.selected === 'top'
              },attrs:{"id":'node-top-' + _vm.node.id},on:{"click":function($event){return _vm.clickNode(_vm.node, 'top')}}},[_c('span',[_vm._v(_vm._s(_vm.node.name))]),_c('info-gray',{staticClass:"subdivision-node-top-icon"})],1),_c('div',{class:{
                ['subdivision-node-bottom']: true,
                ['subdivision-node-bottom--selected']: _vm.selected === 'bottom',
                ['subdivision-node-bottom--active']: _vm.node[_vm.grouping.propName]
              },attrs:{"id":'node-bottom-' + _vm.node.id},on:{"click":function($event){return _vm.clickNode(_vm.node, 'bottom')}}},[(_vm.node[_vm.grouping.propName])?_c('span',[_vm._v(_vm._s(_vm.node[_vm.grouping.propName].name))]):_c('span',[_vm._v(_vm._s(_vm.grouping.out))]),_c('settings',{staticClass:"subdivision-node-bottom-icon"})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }