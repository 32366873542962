<template>
  <inner-page v-if="testData"
              :history="testData.history"
              :steps="[`Сопоставьте точки учёта`]"
              history-title="Сопоставление"
              home="Точки учёта"
              @back="goHome"
              @goHome="goHome"
  >
    <div class="point-matching">
      <div class="point-matching-header">
        <span class="point-matching-header__title">Сопоставьте точки учёта</span>
        <div class="point-matching-header__status">
          <div class="point-matching-header__circle" />
          <div>{{ pointCountText }}</div>
        </div>
      </div>

      <div class="point-matching-header">
        <div class="point-matching-header__note">
          Дата создания: <span class="point-matching-header__note--bold">{{ testData.createDate }}</span>
        </div>
        <div class="point-matching-header__note">
          Дата последнего изменения: <span class="point-matching-header__note--bold">{{ testData.updateDate }}</span>
        </div>
      </div>

      <v-divider class="point-matching__margin-bottom"/>

      <div class="point-matching-header point-matching-header--column">
        <div class="point-matching-header__note--title">
          Описание:
        </div>
        <div class="point-matching-header__note--note">
          {{ testData.description }}
        </div>
        <div class="point-matching-header__note--note">
          Исполнитель: <span class="point-matching-header__note--bold">{{ testData.executor }}</span>
        </div>
      </div>

      <v-divider class="point-matching__margin-bottom"/>

      <div class="point-matching-selectors">
        <div class="point-matching-selectors__title">
          Выбрать для объединения:
        </div>
        <v-select
          v-model="currentApplication"
          class="point-matching-selectors__selector"
          :options="testData.currentApplicationList"
          new-style
        />
        <v-select
          v-model="downloadedApplication"
          class="point-matching-selectors__selector"
          :options="testData.downloadedApplicationList"
          new-style
        />

        <div class="point-matching-selectors__divider" />
        <div class="point-matching-selectors__divider" />

        <div class="point-matching-note">
          <edit-mode-button
              title="Показывать только не сопоставленные точки"
              class="point-matching-note-select"
              select
          />
          <div class="point-matching-note-text">
            Сопоставляя точки учёта, вы даете согласие, что точки загруженные в Платформу идентичны точкам из загруженного договора. Не сопоставленные точки, будут добавлены как не урегулированные
          </div>

          <div class="point-matching-note-text">
            Данные по точке из приоритетного приложения (левой колонки) к договору будут использоваться как основные в случае расхождения данных по точке
          </div>
        </div>
      </div>

      <div class="point-matching-search">
        <div class="point-matching-search__item">
          <div class="point-matching-search__item-sort" @click="currentSortDirection = currentSortDirection * -1">
            <img src="~@/assets/icons/basic/sort.svg"
                 :class="{['point-matching-search__item-sort-icon']: currentSortDirection !== 1}"
            />
            <span>Список текущих точек</span>
          </div>
          <edit-mode-button
            :is-true="selectedAllCurrent"
            title="Выбрать всё"
            select-all
            @click="selectAllCurrentHandler"
          />
          <v-input
            v-model="searchCurrent"
            class="point-matching-search__item-input"
            clearable
          />
        </div>
        <div class="point-matching-search__item">
          <div class="point-matching-search__item-sort" @click="downloadedSortDirection = downloadedSortDirection * -1">
            <img src="~@/assets/icons/basic/sort.svg"
                 :class="{['point-matching-search__item-sort-icon']: downloadedSortDirection !== 1}"
            />
            <span>Список точек из загруженного договора</span>
          </div>
          <edit-mode-button
            title="Выбрать всё"
            select-all
            @click="selectAllDownloadedHandler"
          />
          <v-input
            v-model="searchDownloaded"
            class="point-matching-search__item-input"
            clearable
          />
        </div>
      </div>

      <div class="point-matching-list">
        <div v-for="(points, index) in filteredPointsList" :key="index + currentSortDirection" @click="setActive(index)">
          <matching-points
            :ref="'matchingPoint' + points.current.id"
            :points="points"
            :active="index === activeMatchingPoints"
            :selected="selectedPoints.includes(points.current.id)"
            :filter-downloaded="searchDownloaded"
            :sort-direction="downloadedSortDirection"
            @checked="selectPoints"
            @updatePoint="(action, points, downloadedPoint) => updatePoint(index === activeMatchingPoints, action, points, downloadedPoint)"
          />
        </div>
      </div>

      <div v-if="selectedPoints.length > 0" class="point-matching-group-actions">
        <div class="point-matching-group-actions-item point-matching-group-actions-item--cancel" @click="selectedAllCurrent = false; selectAllCurrentHandler(false); ">
          Отменить выбор
        </div>
        <div class="point-matching-group-actions-title">
          Действия для выбранных:
        </div>
        <div class="point-matching-group-actions-item point-matching-group-actions-item--merge" @click="groupUpdate('merge')">
          <img src="@/assets/icons/point/merge.svg" />
          Объединить
        </div>
        <div class="point-matching-group-actions-item point-matching-group-actions-item--replace" @click="groupUpdate('replace')">
          <img src="@/assets/icons/point/replace.svg" />
          Заменить
        </div>
        <div class="point-matching-group-actions-item point-matching-group-actions-item--delete" @click="groupUpdate('delete')">
          <img src="@/assets/icons/point/delete.svg" />
          Удалить
        </div>

        <div class="point-matching-note point-matching-note--top-0">
          <div class="point-matching-note-text">
            Действия применяются для всех выбранных точек
          </div>
        </div>
      </div>

      <div style="position: relative">
        <v-divider class="point-matching__margin-bottom"/>
        <div v-if="mergingPoints.length > 0 || replacementPoints.length > 0 || deletedPoints.length > 0" class="point-matching-note">
          <div class="point-matching-note-text">
            <b>Статусы урегулированности точки:</b><br/>
            Синий — точка урегулирована<br/>
            Красный — точка не урегулирована<br/>
          </div>
        </div>
      </div>


      <div v-if="mergingPoints.length > 0" class="point-matching-updated-points">
        <div class="point-matching-updated-points-title">
          Объединённые точки учёта:
        </div>

        <div v-for="(item, index) in mergingPoints" :key="index" @click="setActiveUpdated(index, 'merge')">
          <matching-points
            :points="item.points"
            :active="index === activeMergePoints"
            action="merge"
            :downloaded-point="item.downloadedPoint"
            @updatePoint="(action, points, downloadedPoint) => updatePoint(index === activeMergePoints, action, points, downloadedPoint)"
          />
        </div>
      </div>

      <div v-if="replacementPoints.length > 0" class="point-matching-updated-points">
        <div class="point-matching-updated-points-title">
          Заменённые точки учёта:
        </div>

        <div v-for="(item, index) in replacementPoints" :key="index" @click="setActiveUpdated(index, 'replace')">
          <matching-points
            :points="item.points"
            :active="index === activeReplacePoints"
            action="replace"
            :downloaded-point="item.downloadedPoint"
            @updatePoint="(action, points, downloadedPoint) => updatePoint(index === activeReplacePoints, action, points, downloadedPoint)"
          />
        </div>
      </div>

      <div v-if="deletedPoints.length > 0" class="point-matching-updated-points">
        <div class="point-matching-updated-points-title">
          Удалённые точки учёта:
        </div>

        <div v-for="(item, index) in deletedPoints" :key="index" @click="setActiveUpdated(index, 'delete')">
          <matching-points
            :points="item.points"
            :active="index === activeDeletePoints"
            action="delete"
            :downloaded-point="item.downloadedPoint"
            @updatePoint="(action, points, downloadedPoint) => updatePoint(index === activeDeletePoints, action, points, downloadedPoint)"
          />
        </div>
      </div>

    </div>

    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="goHome">
          Назад
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button class="btn-save">
          Согласовать
        </button>
      </div>
    </div>
  </inner-page>
</template>

<script>
import { InnerPage, VDivider, VSelect, EditModeButton } from '@/components';
import VInput from 'components/VInput/VInput.vue';
import MatchingPoints from './components/MatchingPoints';

function compareList(first, second, sortDirection) {
  if (first.current.name < second.current.name) {
    return sortDirection;
  }
  if (first.current.name > second.current.name) {
    return sortDirection * -1;
  }
  return 0;
}

export default {
  name: 'UsagePoint',
  components: {
    InnerPage,
    VDivider,
    VSelect,
    EditModeButton,
    VInput,
    MatchingPoints,
  },
  created() {
    this.currentApplication = this.testData.currentApplicationList[0].value;
    this.downloadedApplication = this.testData.downloadedApplicationList[0].value;
  },
  data() {
    return {
      testData: {
        createDate: '09.11.2023',
        updateDate: '10.11.2023',
        description: 'Был загружен Договор на передачу ЭЭ, требуется сопоставление точек',
        executor: 'АО «ОЭК» Александр Широков (куратор)',
        currentApplicationList: [{
          label: 'Приложение 3 к договору на передачу ЭЭ ТСО — АО «ОЭК» — АО «Мосэнернгосбыт»',
          value: 'Приложение 3 к договору на передачу ЭЭ ТСО — АО «ОЭК» — АО «Мосэнернгосбыт»',
        }],
        downloadedApplicationList: [{
          label: 'Приложение 3 к договору на передачу ЭЭ ТСО — АО «ОЭК»',
          value: 'Приложение 3 к договору на передачу ЭЭ ТСО — АО «ОЭК»',
        }],
        currentPointsList: [
          {
            current: {
              id: '1',
              name: 'ТЭЦ «ЗИЛ» 127456311',
            },
            downloadedPointList: [
              { label: 'ТЭЦ «ЗИЛ» 127456311', value: '11' },
              { label: 'ООО Квартал «ЗИЛ» 987456315', value: '22' },
              { label: 'ООО Квартал «ЗИЛ» 232324343', value: '33' },
              { label: 'ООО Квартал «ЗИЛ» 646564566', value: '44' },
            ],
          },
          {
            current: {
              id: '2',
              name: 'ТЭЦ «ЗИЛ» 127456313',
            },
            downloadedPointList: [
              { label: 'ТЭЦ «ЗИЛ» 127456311', value: '11' },
              { label: 'ООО Квартал «ЗИЛ» 987456315', value: '22' },
              { label: 'ООО Квартал «ЗИЛ» 232324343', value: '33' },
              { label: 'ООО Квартал «ЗИЛ» 646564566', value: '44' },
            ],
            unsettled: true,
          },
          {
            current: {
              id: '3',
              name: 'ТЭЦ «ЗИЛ» 127456314',
            },
            downloadedPointList: [
              { label: 'ТЭЦ «ЗИЛ» 127456311', value: '11' },
              { label: 'ООО Квартал «ЗИЛ» 987456315', value: '22' },
              { label: 'ООО Квартал «ЗИЛ» 232324343', value: '33' },
              { label: 'ООО Квартал «ЗИЛ» 646564566', value: '44' },
            ],
          },
          {
            current: {
              id: '4',
              name: 'ТЭЦ «ЗИЛ» 127456315',
            },
            downloadedPointList: [
              { label: 'ТЭЦ «ЗИЛ» 127456311', value: '11' },
              { label: 'ООО Квартал «ЗИЛ» 987456315', value: '22' },
              { label: 'ООО Квартал «ЗИЛ» 232324343', value: '33' },
              { label: 'ООО Квартал «ЗИЛ» 646564566', value: '44' },
            ],
          },

        ],
        history: [
          {
            message: 'Сопоставлены точки учёта из Приложения 1 и Приложения 1.1',
          },
          {
            message: 'Сопоставление точек учёта между Приложением 3 и Приложением 3',
            current: true,
          },
          {
            message: 'Сопоставлены точки учёта из Приложения 1 и Приложения 1.1',
          },
        ],
      },
      currentApplication: '',
      downloadedApplication: '',
      searchCurrent: '',
      searchDownloaded: '',
      activeMatchingPoints: undefined,
      activeMergePoints: undefined,
      activeReplacePoints: undefined,
      activeDeletePoints: undefined,
      selectedPoints: [],
      downloadedSortDirection: 1,
      currentSortDirection: 1,
      selectedAllCurrent: false,
      mergingPoints: [],
      replacementPoints: [],
      deletedPoints: [],
    };
  },
  computed: {
    pointCountText() {
      if (this.testData.currentPointsList.length === 1) {
        return '1 точка не совпала';
      }
      if (this.testData.currentPointsList.length <= 4) {
        return `${this.testData.currentPointsList.length} точки не совпали`;
      }
      return `${this.testData.currentPointsList.length} точек не совпало`;
    },
    filteredPointsList() {
      const points = [...this.testData.currentPointsList].filter(
        (item) => !this.mergingPoints.map((item) => item.points.current.id).includes(item.current.id) &&
          !this.replacementPoints.map((item) => item.points.current.id).includes(item.current.id) &&
          !this.deletedPoints.map((item) => item.points.current.id).includes(item.current.id),
      );

      if (this.searchCurrent.length === 0) {
        return points.sort((first, second) => compareList(first, second, this.currentSortDirection));
      }

      return points.filter(
        (item) => item.current.name.toUpperCase().indexOf(this.searchCurrent.toUpperCase()) !== -1,
      ).sort((first, second) => compareList(first, second, this.currentSortDirection));
    },
  },
  watch: {
    selectedPoints(val) {
      if (val.length === 0) {
        this.selectedAllCurrent = false;
        return;
      }
      if (val.length === this.filteredPointsList.length) {
        this.selectedAllCurrent = true;
      }
    },
  },
  methods: {
    goHome() {
      this.$router.back();
    },
    selectAllCurrentHandler(isCheckboxPressed) {
      this.selectedAllCurrent = isCheckboxPressed;

      if (isCheckboxPressed) {
        this.selectedPoints = this.filteredPointsList.map((item) => item.current.id);
        return;
      }

      this.selectedPoints = [];
    },
    selectAllDownloadedHandler(isCheckboxPressed) {
      // TODO: пока непонятна, что должно выбираться
      console.log('selectAllDownloadedHandler', isCheckboxPressed);
    },
    setActive(index) {
      if (this.activeMatchingPoints === index) {
        this.activeMatchingPoints = -1;
      }
      this.activeMatchingPoints = index;
    },
    setActiveUpdated(index, action) {
      let active = -1;

      if (active === index) {
        active = -1;
      }
      active = index;

      switch (action) {
        case 'merge': {
          this.activeMergePoints = active;
          break;
        }
        case 'replace': {
          this.activeReplacePoints = active;
          break;
        }
        case 'delete': {
          this.activeDeletePoints = active;
          break;
        }
      }
    },
    selectPoints(isCheck, id) {
      if (isCheck) {
        this.selectedPoints.push(id);
        return;
      }

      this.selectedPoints = this.selectedPoints.filter((item) => item !== id);
    },
    updatePoint(isActive, action, points, downloadedPoint) {
      if (!isActive) {
        return;
      }

      this.selectedPoints = this.selectedPoints.filter((item) => item !== points.current.id);

      switch (action) {
        case 'merge': {
          if (!this.mergingPoints.map((item) => item.points.current.id).includes(points.current.id)) {
            this.mergingPoints.push({ points: points, downloadedPoint: downloadedPoint });
            this.activeMatchingPoints = -1;
          }
          break;
        }
        case 'replace': {
          if (!this.replacementPoints.map((item) => item.points.current.id).includes(points.current.id)) {
            this.replacementPoints.push({ points: points, downloadedPoint: downloadedPoint });
            this.activeMatchingPoints = -1;
          }
          break;
        }
        case 'delete': {
          if (!this.deletedPoints.map((item) => item.points.current.id).includes(points.current.id)) {
            this.deletedPoints.push({ points: points, downloadedPoint: downloadedPoint });
            this.activeMatchingPoints = -1;
          }
          break;
        }
        case 'mergeCancel': {
          this.mergingPoints = this.mergingPoints.filter((item) => item.points.current.id !== points.current.id);
          this.activeMergePoints = -1;
          break;
        }
        case 'replaceCancel': {
          this.replacementPoints = this.replacementPoints.filter((item) => item.points.current.id !== points.current.id);
          this.activeReplacePoints = -1;
          break;
        }
        case 'deleteCancel': {
          this.deletedPoints = this.deletedPoints.filter((item) => item.points.current.id !== points.current.id);
          this.activeDeletePoints = -1;
          break;
        }
      }
    },
    groupUpdate(action) {
      this.selectedPoints.forEach((item) => {
        this.updatePoint(true, action, this.$refs[`matchingPoint${item}`][0].$props.points, this.$refs[`matchingPoint${item}`][0].$data.selectedPoint);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.point-matching {
  &__margin-bottom {
    margin-bottom: 24px;
  }

  .point-matching-selectors {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &__title {
      width: 100%;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-bottom: 24px;
    }

    &__selector {
      width: 46%;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
    }

    &__divider {
      background: #2F82DF;
      border-radius: 4px 4px 0 0;
      height: 2px;
      width: 48%;
      margin: 16px 0 16px 0;
    }
  }

  .point-matching-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &__title {
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      text-align: left;
      padding: 0;
    }

    &--column {
      flex-direction: column;
      justify-content: flex-start;
      gap: 24px;
    }

    &__status {
      display: flex;
      width: fit-content;
      border: 1px solid #C1C4C7;
      border-radius: 16px;
      padding: 3px 15px 3px 15px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 6px;
    }

    &__circle {
      width: 8px;
      height: 8px;
      margin-top: 6px;
      margin-right: 8px;
      border-radius: 24px;
      background-color: #EB5757;
    }

    &__note {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      width: 50%;

      &--bold, &--title {
        font-weight: 600;
      }

      &--title {
        font-size: 18px;
        margin-bottom: -12px;
      }
    }
  }

  .point-matching-search {
    display: flex;

    &__item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 50%;
      border: 1px solid #DEE2E6;
      border-radius: 2px;
      padding: 16px 16px 10px 16px;

      &-sort {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 12px;

        img {
          height: 8px;
        }

        &-icon {
          transform: scale(1, -1);
        }
      }

      &-input {
        width: 100%;
        margin-top: 28px;
      }
    }

    &__item:last-child {
      border-left: 0;
    }
  }

  &-note {
    width: 356px;
    max-width: 356px;
    position: absolute;
    top: 44px;
    right: -404px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &--top-0 {
      top: 0;
    }

    &-text {
      color: #71757A;
      padding: 13px 20px;
      border-radius: 16px;
      background-color: #F5F6F6;
      margin-bottom: 24px;
    }

    &-select {
      margin-bottom: 24px;
    }

    @media (max-width: 1400px) {
      position: relative;
      top: 0;
      right: 0;
      width: 848px;
      max-width: 848px;
      margin-top: 16px;
    }
  }
}

.point-matching-list {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 7px;
  margin-bottom: 24px;
}

.point-matching-group-actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  &-title {
    text-align: right;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &-item {
    display: flex;
    gap: 8px;
    border-radius: 24px;
    padding: 11px 20px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f7;
    }

    &--cancel {
      border: 1px solid #C1C4C7;
    }
    &--merge {
      border: 1px solid #2F82DF;
    }
    &--replace {
      border: 1px solid #269A12;
    }
    &--delete {
      border: 1px solid #EB5757;
    }

  }
}

.point-matching-updated-points {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 24px;

  &-title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }
}



.action-buttons {
  display: flex;

  button {
    height: 40px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }
  }

  .btn-print {
    background-image: url('~icons/basic/print.svg');
    background-repeat: no-repeat;
    background-position: center left 14px;
    padding-left: 48px;
  }

  &__action {
    width: 100%;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 16px;
      }
    }
  }
}
</style>
