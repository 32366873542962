<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps" />
        <span>Выберите сетевую компанию</span>
      </h2>
    </div>

    <div v-if="gridCompanyList.length > 0" :class="$style.blockContent">
      <p style="margin-bottom: 10px">Работы выполнит *</p>
      <v-search-select
        v-model="selectedGridCompanyName"
        :options="gridCompanyCustomList"
        :error="errors.gridCompanyName"
        role="gridCompanyName"
        :err="errors.gridCompanyName?.error"
      />
      <p :class="$style.inputNote">Список сетевых организаций и их зон обслуживания взят из открытых источников и может отличаться от фактического.</p>
    </div>

    <div v-if="gridCompanyList.length > 0 && gridCompany.gridCompanyName.onPlatform !== undefined" :class="$style.blockContent">
      <div :class="$style.textDescription">
        <p v-if="gridCompany.gridCompanyName && gridCompany.gridCompanyName.onPlatform">Участник проекта «Мой свет».</p>
        <p v-else>Не является участником проекта «Мой свет».</p>

        <p>Адрес: {{ gridCompany.gridCompanyName.address }}</p>
        <p v-if="gridCompany.gridCompanyName.phone">Тел: <a :href="'tel:' + gridCompany.gridCompanyName.phone">{{ gridCompany.gridCompanyName.phone }}</a></p>
        <p>E-mail: <a :href="'mailto:' + gridCompany.gridCompanyName.email">{{ gridCompany.gridCompanyName.email }}</a></p>
        <p v-if="gridCompany.gridCompanyName.host"><a :href="'https://' + gridCompany.gridCompanyName.host" target="_blank">{{ gridCompany.gridCompanyName.host }}</a></p>
        <div v-if="gridCompany.gridCompanyName.presenceZones.length" style="display: flex; gap: 8px">
          <p>Зоны обслуживания:</p>
          <div>
            <p v-for="(zone, id) in gridCompany.gridCompanyName.presenceZones" :key="id">{{ zone.presenceAddress }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="gridCompanyList.length === 0" >
      <p>Для выбора сетевой компании введите адрес объекта.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StepCounter from '../components/StepCounter';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect';

export default {
  name: 'GridCompany',
  components: {
    VSearchSelect,
    StepCounter,
  },
  props: {
    gridCompany: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (!this.gridCompany.gridCompanyName?.label && this.defaultCompany && !this.errors.gridCompanyName?.error) {
      this.gridCompany.gridCompanyName = { ...this.defaultCompany };
    }

    this.selectedGridCompanyName = this.gridCompany.gridCompanyName?.label ?? '';
  },
  data() {
    return {
      defaultGridCompany: {
        gridCompanyName: {
          value: 'Акционерное общество "Объединенная энергетическая компания',
          inn: '7720522853',
        },
      },
      selectedGridCompanyName: undefined,
      defaultCompany: {},
    };
  },
  computed: {
    gridCompanyCustomList() {
      if (this.gridCompanyList.length === 0) {
        return [];
      }
      const filteredOptions = this.gridCompanyList.filter((option) => option.inn !== this.getAccount?.profile.inn && (this.getAccount?.kind !== 'UTILITY' || this.getAccount?.kind !== 'BOILER'));

      return filteredOptions.concat({
        label: 'Иное',
      });
    },
    ...mapGetters('dataLists', ['gridCompanyList']),
    ...mapGetters('user', ['getAccount']),
  },
  watch: {
    gridCompanyList: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          const defaultCompany = newValue.find((company) => company.inn === this.defaultGridCompany.gridCompanyName.inn);
          if (defaultCompany) {
            this.defaultCompany = defaultCompany;

            if (this.selectedGridCompanyName !== undefined && !this.selectedGridCompanyName) {
              this.gridCompany.gridCompanyName = defaultCompany;
              this.selectedGridCompanyName = defaultCompany.label;
            }
          }
        }
      },
    },
    selectedGridCompanyName(val) {
      if (this.gridCompanyList.length === 0) {
        return;
      }
      this.gridCompany.gridCompanyName = this.gridCompanyList.filter((company) => company.label === val)[0] ?? {};
    },
  },
};
</script>

<style lang="sass" module>
  .stepContainer
    +questionnaries-container
    +step-spacing

  .titleContainer
    +base-spacing

  .title
    display: flex

  .grid2Column
    +grid-2-column

  .blockContent
    +base-spacing

  .textDescription
    p
      +base-text

    a
      +base-link


  .inputNote
    +input-note-text
    margin-top: 8px
</style>
