<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.title">Детали задачи</p>

      <div :class="$style.info">
        <p>ФИО пользователя:</p>
        <p>{{ params.userFullName }}</p>
      </div>

      <div :class="$style.info">
        <p>Действует от лица:</p>
        <p>{{ params.accountFullName }}</p>
      </div>

      <div :class="$style.info">
        <p>Документ:</p>
        <document-downloader
          :document="{
            documentId: params.documentId,
            name: `Заявка на добавление полномочий в справочник ${params.documentId}`,
          }"

        />
      </div>

      <div :class="$style.statusGroup">
        <div :class="$style.radioGroup">
          <label v-if="!params.completed || statusGroup === 1">
            <input
              :checked="statusGroup === 1"
              :class="$style.control"
              name="status"
              type="radio"
              @click="statusGroupClick(1)"
            />
            <div :class="$style.contentRadioGroup">
              <div :class="$style.titleContainer">
                <div
                  :class="{[$style['control__buttonRadioGroupCheck']]: true, [$style['control__checkedButtonRadioGroupCheck']]: statusGroup === 1}"></div>
                <h3 :class="$style.titleRadioGroup">
                  <span v-if="params.completed">Принято</span>
                  <span v-else>Принять</span>
                </h3>
              </div>
            </div>
          </label>
          <label v-if="!params.completed || statusGroup === 2">
            <input
              :checked="statusGroup === 2"
              :class="$style.control"
              :disabled="params.completed"
              name="status"
              type="radio"
              @click="statusGroupClick(2)"
            />
            <div :class="$style.contentRadioGroup">
              <div :class="$style.titleContainer">
                <div
                  :class="{[$style['control__buttonRadioGroupError']]: true, [$style['control__checkedButtonRadioGroupError']]: statusGroup === 2}"></div>
                <h3 :class="$style.titleRadioGroup">
                  <span v-if="params.completed">Отказано</span>
                  <span v-else>Отказать</span>
                </h3>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div v-if="params.completed && statusGroup === 2">
        <p :class="$style.correctionTitle">
          Комментарий:
        </p>
        <p :class="$style.info">
          {{ reason }}
        </p>
      </div>
      <div v-else-if="statusGroup === 2" :class="$style.correctionGroup">
        <p :class="$style.correctionTitle">
          Комментарий
        </p>
        <p :class="$style.mediumSpacing">* обязательно заполните это поле</p>

        <div :class="$style.blockContent">
          <c-textarea
            v-model="comments"
            role="correctionComments"
          />
        </div>
      </div>
      <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
      <submit-task-details
        v-if="!params.completed"
        :disabled="isNotValid || isWaitingSubmit || isDisabledByAnotherUserConnected"
        title="Отправить"
        @submit="onSubmit"
      />
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
    </div>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails.vue';
import CTextarea from 'atoms/common/inputs/CTextarea.vue';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { DocumentDownloader } from '@/components';

import { mapActions } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'PermissionRegistryChangesTask',
  components: { CTextarea, SubmitTaskDetails, HorizontalProgressBar, DocumentDownloader },
  props: {
    params: { type: Object, required: true },
    draftFullName: { type: String, required: true },
  },
  async created() {
    if (this.params.completed) {
      const document = await this.loadDocument(this.params.documentReviewId);
      if (document) {
        const parsed = JSON.parse(document.json);
        this.statusGroup = parsed.Type === this.changesApprovedType ? 1 : 2;
        if (this.statusGroup === 2) {
          this.reason = parsed.Comments;
        }
      }
    }
  },
  data() {
    return {
      statusGroup: null,
      comments: '',
      isWaitingSubmit: false,
      submitError: '',
      reason: '',
      changesApprovedType: 'permission-registry-changes-approved',
      changesRejectedType: 'permission-registry-changes-rejected',
    };
  },
  computed: {
    isNotValid() {
      return !this.statusGroup || (this.statusGroup === 2 && this.comments.length < 3);
    },
    isDisabledByAnotherUserConnected() {
      if (this.params.isAnotherUserConnected && this.statusGroup === 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    statusGroupClick(val) {
      this.statusGroup = val;
    },
    generateXml() {
      let xml = '';
      // statusGroup - null (не выбрано)
      // statusGroup - 1 ("Принять" (connection-user-by-supply-contract-application-approved))
      // statusGroup - 2 ("Отказать" (connection-user-by-supply-contract-application-rejected))
      if (this.statusGroup === 1) {
        xml = generateXml.documentReviewXml(
          this.params.documentId,
          this.changesApprovedType,
          this.comments,
          [],
          {},
        );
      }
      if (this.statusGroup === 2) {
        xml = generateXml.documentReviewXml(
          this.params.documentId,
          this.changesRejectedType,
          this.comments,
          [],
          {},
        );
      }
      return xml;
    },
    async onSubmit() {
      this.isWaitingSubmit = true;
      this.submitError = '';
      const xml = this.generateXml();

      try {
        await this.documentUpload({ xml: xml });
        this.isWaitingSubmit = false;

        localStorage.removeItem(this.draftFullName);
        await this.$router.push('/cabinet/tasks');
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    ...mapActions('document', ['documentUpload', 'loadDocument']),
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  height: 90%;
}

.content {
  width: 560px;
  margin: 0 auto 0;
  z-index: 999;

  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  span {
    margin-right: 12px;
    color: #979CA2;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 34px;

  p {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  &:last-child {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
}

.statusGroup {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
}

.correctionTitle {
  padding-bottom: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.mediumSpacing {
  margin-bottom: 16px
}

.blockContent {
  &:last-child {
    margin-bottom: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.correctionGroup, .radioGroup {
  margin: 20px auto 40px
}

.control {
  opacity: 0;
  pointer-events: none;

  &__buttonRadioGroupCheck {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center;
  }

  &__buttonRadioGroupError {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center;
  }

  &__checkedButtonRadioGroupCheck {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center
  }

  &__checkedButtonRadioGroupError {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/error.svg') no-repeat right center;
  }

  &:checked {
    p {
      color: #141412;
    }
  }
}

.contentRadioGroup {
  display: flex;
  flex-direction: column;
  color: #141412;

  p {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #C1C4C7;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.titleRadioGroup {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  margin-left: 10px;
}
</style>
