<template>
  <page title="Дефект">
    <defect-data/>
  </page>
</template>

<script>
import { Page } from '@/components';
import DefectData from 'views/private/HandlingDefects/Card/DefectData.vue';


export default {
  name: 'DefectCard',
  components: {
    DefectData,
    Page,

  },
};
</script>
