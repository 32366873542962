var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{['matching-points']: true, ['matching-points--active']: _vm.active}},[_c('div',{class:{
    ['matching-points__point']: true,
    ['matching-points__point--merge']: _vm.action === 'merge',
    ['matching-points__point--replace']: _vm.action === 'replace',
    ['matching-points__point--delete']: _vm.action === 'delete',
  }},[(_vm.action)?_c('div',{class:{['matching-points__point-unsettled']: _vm.points.unsettled}},[_vm._v(" "+_vm._s(_vm.points.current.name)+" ")]):_c('edit-mode-button',{staticClass:"matching-points__point-check-box",attrs:{"is-true":_vm.selected,"title":_vm.points.current.name,"select":""},on:{"click":_vm.selectCurrentHandler}}),_vm._m(0)],1),_c('div',{class:{
    ['matching-points__point']: true,
    ['matching-points__point--right']: true,
    ['matching-points__point--right-updated']: _vm.action,
    ['matching-points__point--merge']: _vm.action === 'merge',
    ['matching-points__point--replace']: _vm.action === 'replace',
    ['matching-points__point--delete']: _vm.action === 'delete',
  }},[(_vm.action === 'merge')?_c('img',{staticClass:"matching-points__point-action-icon",attrs:{"src":require("@/assets/icons/point/action-merge.svg")}}):(_vm.action === 'replace')?_c('img',{staticClass:"matching-points__point-action-icon",attrs:{"src":require("@/assets/icons/point/action-replace.svg")}}):(_vm.action === 'delete')?_c('img',{staticClass:"matching-points__point-action-icon",attrs:{"src":require("@/assets/icons/point/action-delete.svg")}}):_vm._e(),(_vm.action)?_c('div',[_vm._v(" "+_vm._s(_vm.downloadedPointName)+" ")]):_c('v-select',{class:{['matching-points__point-select']: true, ['matching-points__point-select--unsettled']: _vm.points.unsettled},attrs:{"options":_vm.filteredDownloaded,"new-style":""},model:{value:(_vm.selectedPoint),callback:function ($$v) {_vm.selectedPoint=$$v},expression:"selectedPoint"}}),_vm._m(1)],1),_c('div',{class:{['matching-points__action']: true, ['matching-points__action--title']: true, ['matching-points__action--active']: _vm.active }},[_c('div',{staticClass:"matching-points__action-title"},[_vm._v("Выбрать действие с точками учёта:")])]),_c('div',{class:{['matching-points__action']: true, ['matching-points__action--active']: _vm.active }},[(!_vm.action)?[_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--merge']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event, 'merge')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/merge.svg")}}),_vm._v(" Объединить ")]),_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--replace']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event,'replace')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/replace.svg")}}),_vm._v(" Заменить ")]),_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--delete']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event,'delete')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/delete.svg")}}),_vm._v(" Удалить ")])]:[(_vm.action === 'merge')?_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--cancel']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event,'mergeCancel')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/merge-cancel.svg")}}),_vm._v(" Отменить объединение ")]):(_vm.action === 'replace')?_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--cancel']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event,'replaceCancel')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/replace-cancel.svg")}}),_vm._v(" Отменить замену ")]):(_vm.action === 'delete')?_c('div',{class:{
        ['matching-points__action-item']: true,
        ['matching-points__action-item--cancel']: true,
        ['matching-points__action-item--active']: _vm.active
      },on:{"click":(event) => _vm.updatePoint(event,'deleteCancel')}},[_c('img',{attrs:{"src":require("@/assets/icons/point/delete-cancel.svg")}}),_vm._v(" Отменить удаление ")]):_vm._e()]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matching-points__point-navigate"},[_c('img',{staticStyle:{"height":"18px","margin-right":"10px"},attrs:{"src":require("@/assets/icons/point/point.svg")}}),_c('img',{attrs:{"src":require("@/assets/icons/point/follow.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matching-points__point-navigate"},[_c('img',{staticStyle:{"height":"18px","margin-right":"10px"},attrs:{"src":require("@/assets/icons/point/point.svg")}}),_c('img',{attrs:{"src":require("@/assets/icons/point/follow.svg")}})])
}]

export { render, staticRenderFns }