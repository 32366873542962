// Приложение 1 договор купли продажи ГП-ЭСО
export const retailRetailPurchase1AdditionPattern = {
  rowNumberStart: 7,
  electricitySupplyContractInfo: {
    objectNumber: { colIndex: 1, rules: 'required', title: '№ объекта', col: 2 },
  },
  consumer: {
    inn: { colIndex: 4, rules: 'required|inn', title: 'ИНН', col: 3 },
    name: { colIndex: 5, rules: 'required|maxLength255', title: 'Наименование потребителя', col: 4 },
  },
  serviceLocation: { colIndex: 16, rules: 'required', title: 'Адрес поставки', col: 5 },
};

// Приложение 1 договор купли продажи ГП-ЭСО
export const retailRetailPurchase2AdditionPattern = {
  rowNumberStart: 10,
  consumer: {
    name: {
      colIndex: 1,
      rules: 'required|maxLength255',
      title: 'Наименование объекта Абонента/ Транзитного потребителя',
      col: 2,
    },
  },
  /*    electricityPurchaseSaleContractInfo: {

      },*/
  electricitySupplyContractInfo: {
    objectNumber: { colIndex: 2, rules: 'required', title: 'Номер объекта', col: 3 },
  },
  connectionAct: {
    number: {
      colIndex: 3,
      rules: 'required',
      title: 'Наименование и реквизиты документа о тех. присоединении. Номер',
      col: 4,
    },
    date: {
      colIndex: 4,
      rules: 'required|date',
      title: 'Наименование и реквизиты документа о тех. присоединении. Дата',
      col: 4,
    },
  },
  maxPower: { colIndex: 5, rules: 'required|number', title: 'Максимальной мощности, кВт', col: 5 },
  supplyPointName: {
    mainSubstation: {
      colIndex: 6,
      rules: 'required|maxLength1024',
      title: 'Описание точки поставки. Наименование питающего центра',
      col: 6,
    },
    borderName: {
      colIndex: 13,
      rules: 'maxLength1024',
      title: 'Описание точки поставки. Наименование границы балансовой принадлежности',
      col: 6,
    },
    mainSubstationNumber: {
      colIndex: 7,
      rules: 'required|maxLength1024',
      title: 'Описание точки поставки. Номер питающего центра',
      col: 6,
    },
    feeder: { colIndex: 8, rules: 'maxLength1024', title: 'Описание точки поставки. № фидера', col: 6 },
    distributionSubstation: { colIndex: 9, rules: 'maxLength1024', title: 'Описание точки поставки. № РП', col: 6 },
    line: { colIndex: 11, rules: 'maxLength1024', title: 'Описание точки поставки. № Линии', col: 6 },
    transformationSubstation: { colIndex: 10, rules: 'maxLength1024', title: 'Описание точки поставки. № ТП', col: 6 },
    other: { colIndex: 12, rules: 'maxLength1024', title: 'Описание точки поставки. иное', col: 6 },
  },
  voltageLevel: {
    colIndex: 14,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень напряжения на границе балансовой принадлежности',
    col: 7,
  },
  tariffVoltageLevel: {
    colIndex: 15,
    rules: 'required|allowedValues',
    listName: 'voltageClassList',
    allowedValues: ['ВН', 'СН1', 'СН2', 'НН'],
    title: 'Уровень напряжения для применения цен',
    col: 8,
  },
  meter: {
    rules: 'required',
    id: { colIndex: 16, rules: 'maxLength255', title: 'Идентификационный номер точки учета', col: 9 },
    type: { colIndex: 19, rules: 'maxLength255', title: 'Тип прибора учета по паспорту (модель)', col: 12 },
    serial: {
      colIndex: 17,
      rules: 'maxLength255',
      title: '№ прибора учета',
      col: 10,
    },
    readingsValue: { colIndex: 18, title: 'Показания прибора учета', col: 11 },
    integerDigits: { colIndex: 20, title: 'Значность прибора учета', col: 13 },
    ratedCurrent: { colIndex: 21, rules: 'maxLength255', title: 'Номинал ТТ, А', col: 14 },
    nominalVoltage: { colIndex: 22, ruled: 'maxLength255', title: 'Прибор учёта электрической энергии. Uном.', col: 15 },
    calibrationDate: {
      colIndex: 30,
      rules: 'required|date',
      title: 'Государственная поверка прибора учёта. Дата',
      col: 23,
    },
    calibrationInterval: {
      colIndex: 31,
      rules: 'required',
      title: 'Государственная поверка прибора учёта. Срок МПИ',
      col: 24,
    },
  },
  accountingRatio: { colIndex: 23, rules: 'required', title: 'Расчетный коэффициент', col: 16 },
  lossFactor: {
    variable: { colIndex: 24, rules: 'required', title: 'Потери в линиях', col: 17 },
    const: { colIndex: 25, rules: 'required', title: 'Потери в силовых тарнсформаторах', col: 18 },
    summary: { colIndex: 26, title: 'Суммарные потери', col: 19 },
  },
  metersOwner: {
    colIndex: 27,
    rules: 'required|allowedValues',
    title: 'Балансовая принадлежность прибора учёта',
    listName: 'ownerAccountingFacilitiesList',
    allowedValues: ['З', 'И', 'ССО', 'П'],
    col: 20,
  },
  meterPlace: { colIndex: 28, rules: 'required', title: 'Место установки прибора учёта', col: 21 },
  kind: {
    colIndex: 29, rules: 'required|allowedValues', title: 'Тип учёта', col: 22,
    listName: 'typeAccountingList',
    allowedValues: ['Р', 'К'],
  },
  restrictionOrder: { colIndex: 32, rules: 'required', title: 'Порядок ограничения', col: 25 },
};
