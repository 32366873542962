<template>
    <div>
        <header class="header">
            <router-link to="/" class="logo-link">
                <img src="@/assets/images/logos/logo-brand.svg" alt="logo" class="logo">
            </router-link>
            <nav class="nav">
                <ul class="nav-menu">
                    <li class="nav-menu-item">
                        <router-link to="/help-individual" class="nav-menu-link" active-class="active-link">Потребителю физическому лицу</router-link>
                    </li>
                    <li class="nav-menu-item">
                        <router-link to="/help-entity" class="nav-menu-link" active-class="active-link">Потребителю юридическому лицу</router-link>
                    </li>
                    <li class="nav-menu-item">
                        <router-link to="/help-network-company" class="nav-menu-link" active-class="active-link">Сетевой компании</router-link>
                    </li>
                    <li class="nav-menu-item">
                        <router-link to="/help-sales-company" class="nav-menu-link" active-class="active-link">Сбытовой компании</router-link>
                    </li>
                </ul>
                <ul class="nav-menu">
                    <li class="nav-menu-item">
                        <router-link to="/help-about-project" class="nav-menu-link" active-class="active-link">О проекте</router-link>
                    </li>
                    <li class="nav-menu-item">
                        <router-link to="/help-about-company" class="nav-menu-link" active-class="active-link">О компании</router-link>
                    </li>
                    <li class="nav-menu-item">
                        <router-link to="/help-technical-support" class="nav-menu-link" active-class="active-link">Техническая поддержка</router-link>
                    </li>
                </ul>
            </nav>
            <div class="copyright">
              <p class="copyright-text">© АО «Объединенная энергетическая компания», 2011-{{ currentYear }}</p>
            </div>
        </header>
    </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
    .header{
        box-shadow: 5px 0 5px -5px rgba(0, 0, 0, .5);
        position: fixed;
        width: 432px;
        height: 100%;
        overflow-y: auto;
    }

    .header::-webkit-scrollbar{
        width: 0;
    }

    .copyright{
        max-width: 140px;
        margin-left: 87px;
        margin-top: 40px;
    }

    .copyright-text{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #74737B;
    }

    .logo-link {
        transform: translateX(-23px);
        margin-left: 87px;
        margin-top: 40px;
    }

    .logo{
        width: 264px;
        height: 95px;
    }

    .nav{
        margin-top: 37px;
        margin-left: 87px ;
    }

    .nav-menu {
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        font-family: 'Roboto Condensed', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }

    .nav-menu:last-child{
        margin-top: 73px;
    }

    .nav-menu-item{
        margin-bottom: 24px;
    }

    .active-link{
        border-bottom: 4px solid #4C4E51;
    }

    .nav-menu-link{
        text-decoration: none;
        text-transform: uppercase;
        color: #4F4F4F;
        cursor: pointer;
        margin-bottom: -20px;
    }

    .nav-menu-link:hover{
        border-bottom: 4px solid #4C4E51;
        margin-bottom: -20px;
        padding: 0;
    }
</style>
