<template>
  <div :class="$style.container">
    <div :class="$style.title" @click="show = !show">
      <p>Как установить приложение</p>
      <ChevronUp v-if="show" style="margin-left: 16px" />
      <ChevronDown v-else style="margin-left: 16px" />
    </div>
    <div v-if="show" :class="$style.content">

      <div :class="$style.utmInstallation">

        <p :class="$style.title">Руководство по установке УТМ</p>

        <a href="/instruction/Instruction_utm_install.pdf" download="Руководство по установке УТМ.pdf" style="width: 100%;" @submit.prevent>
          <div :class="$style.file">

            <div :class="$style.fileName">
              <i :class="$style.icon" />
              <p :class="$style.fileText"><b>Руководство по установке УТМ.pdf</b></p>
            </div>

            <div :class="$style.download">
              <i :class="$style.fileDownload" />
              <p :class="$style.text">cкачать</p>
            </div>

          </div>
        </a>

      </div>

      <p :class="$style.subtitle">После установки и запуска приложения вы попадёте в личный кабинет сотрудника компании. Вся дальнейшая работа с Платформой будет осуществляться через универсальный транспортный модуль. </p>

      <div :class="$style.utmInstallation">

        <p :class="$style.title">Универсанльный транспортный модуль МОЙ СВЕТ</p>
        <p :class="$style.text">версия 1.0.0, 2,3 Мб</p>

        <a :href="url" download style="width: 100%;" @submit.prevent>
          <div :class="$style.file">

            <div :class="$style.fileName">
              <i :class="$style.icon" />
              <p :class="$style.fileText"><b>utm-ui-1.0.0-all.zip</b></p>
            </div>

            <div :class="$style.download">
              <i :class="$style.fileDownload" />
              <p :class="$style.text">cкачать</p>
            </div>

          </div>
        </a>

      </div>

      <p :class="$style.text">Если у вас возникли трудности с установкой транспортного модуля, обратитесь в <a href="#">службу техподдержки</a>.</p>

    </div>

  </div>
</template>

<script>
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';

export default {
  name: 'System',
  components: {
    ChevronDown,
    ChevronUp,
  },
  props: {
    url: String,
  },
  data() {
    return {
      show: true,
      isLoading: false,
    };
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.title
  display: flex
  cursor: pointer
  p
    +base-title

.content
  padding: 24px 0 40px 0

ol.list
  padding-inline-start: 20px
  li
    +base-text
    margin-bottom: 20px

    a
      color: #2a56c6
      text-decoration: underline
      &:hover
        text-decoration: none
    &:last-child
      margin-bottom: 0

    code
      font-size: 14px

.subtitle
  +base-subtitle
  margin: 30px 0

.utmInstallation
  .title
    +base-subtitle
  .text
    +base-text

.file
  border: 2px dashed #2a56c6
  padding: 12px
  display: flex
  justify-content: space-between
  margin: 20px 0 40px 0
  cursor: pointer

.fileName
  display: flex

.fileText
  +base-text
  margin-left: 16px
  transform: translateY(20px)

.icon
  width: 64px
  height: 64px
  background-image: url('~icons/basic/rectangle_utm.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover

.fileDownload
  width: 13px
  height: 16px
  background-image: url('~icons/basic/download.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  margin-right: 13px
  transform: translateY(3px)

.text
  +base-text
  a
    color: #2a56c6
    text-decoration: underline
    &:hover
      text-decoration: none

.download
  display: flex
  transform: translateY(20px)

.loader
  position: absolute
  top: 380px
  left: 0
  z-index: 999
  width: 100%
  height: 100%
  display: flex
  justify-content: center

.loaderContent
  position: relative
  width: 300px
  text-align: center
  max-height: 200px
  max-width: 700px
  align-self: center
  overflow-x: hidden
  overflow-y: auto
  box-sizing: border-box
  background: #fff

  p
    margin: 8px

</style>
