<template>
  <div>
    <table-with-measurements-common-logic :kind-list="['RECEIVING_POINT', 'TRANSFER_POINT']"/>
  </div>
</template>

<script>
import TableWithMeasurementsCommonLogic from '../MeasurementsCommon/TableWithMeasurementsCommonLogic.vue';

export default {
  name: 'CabinetMeasurements',
  components: {
    TableWithMeasurementsCommonLogic,
  },
};
</script>
