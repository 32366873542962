<template>
  <div class="form-columns">
    <div class="form-columns">
      <div class="form-columns__row">
        <div
          v-if="column1.length !== 0"
          class="column"
        >
        <span v-for="(columnItem, i) in column1"
              :key="i"
              class="column__item"
        >
          <span class="column__item-label">{{ columnItem.label }}</span>&nbsp;
          <span class="column__item-value">{{ columnItem.value }}</span>
        </span>
        </div>
        <div
          v-if="column2.length !== 0"
          class="column"
        >
        <span v-for="(columnItem, i) in column2"
              :key="i"
              class="column__item"
        >
          <span class="column__item-label">{{ columnItem.label }}</span>&nbsp;
          <span class="column__item-value">{{ columnItem.value }}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="form-columns__row">
      <div
        v-if="column3.length !== 0"
        class="column"
      >
        <span v-for="(columnItem, i) in column3"
              :key="i"
              class="column__item"
        >
          <span class="column__item-label">{{ columnItem.label }}</span>&nbsp;
          <span class="column__item-value">{{ columnItem.value }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    column1: {
      type: Array,
      default: () => [],
    },
    column2: {
      type: Array,
      default: () => [],
    },
    column3: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.form-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #4C4E51;
  gap: 16px;

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .column {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      font-size: 16px;
      white-space: nowrap;

      &-value {
        font-weight: bold;
      }
    }
  }
}
</style>
