var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history",style:(_vm.styleSpacing)},[_c('div',{staticClass:"history__header"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),(_vm.canBeCollapsed)?_c('img',{attrs:{"alt":"","src":require("@/assets/icons/basic/eye.svg")},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}}):_vm._e()]),(_vm.collapsed)?_c('div',{staticClass:"history__items"},[_c('div',{class:{
            ['history__progress']: true,
            ['history__progress--92']: _vm.history.length < 7,
            ['history__progress--96']: _vm.history.length >= 7,
         }}),_vm._l((_vm.internalHistory),function(item,index){return _c('div',{key:index,staticClass:"history__item-wrp"},[_c('div',{class:{
          ['history__item']: true,
          ['history__item--current']: _vm.isCurrent(item),
       }},[(_vm.isCurrent(item))?_c('img',{attrs:{"alt":"","src":require("@/assets/icons/basic/step_current_ellipse.svg")}}):_c('img',{attrs:{"alt":"","src":require("@/assets/icons/basic/step_ellipse.svg")}}),(item.action)?_c('span',[_vm._v(_vm._s(`${_vm.convertDbDateTimeFormat(item.datetime)}${item.documentName ?? 'Документ'} ${_vm.convertAction(item.action)}`)+" ")]):(item.message)?_c('span',[_vm._v(" "+_vm._s(`${_vm.convertDbDateTimeFormat(item.datetime)}${item.message}`)+" ")]):_c('button',{staticClass:"history__change-page",on:{"click":function($event){return _vm.changePage(item)}}},[_vm._v("...")]),(item.role)?_c('span',[_vm._v(" "+_vm._s(item.role.name)+" ")]):_vm._e(),(item.account?.profile?.name)?_c('span',[_vm._v(_vm._s(` ${item.account.profile.name}`))]):_vm._e(),(item.user)?_c('span',[_vm._v(" "+_vm._s(item.user?.fullName ? `${item.user.fullName.surname} ${item.user.fullName.name} ${item.user.fullName.patronymic ? item.user.fullName.patronymic : ''}.` : '')+" ")]):_vm._e(),(item.documentHash?.length)?_c('span',[_vm._v(" Хэш документа: "+_vm._s(item.documentHash)+". ")]):_vm._e()]),(item.onClick)?_c('button',{staticClass:"history__item__button",on:{"click":item.onClick}}):_vm._e()])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }