<template>
  <page title="Программа оформления">
    <odk-tag-modal v-model="tagModalState" @updated="loadData"/>
    <v-table-new
      v-model="transformedAdreska"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Программа оформления"
      is-display-map-option
      @delete="onDelete"
      @edit="onEdit"
      @on-cell-click="handleCellClick"
    />
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createAdreska"
      @delete="deleteAdreska"
      @update="updateAdreska"
    >
      <template #location="slotProps">
        <div>
          <p>{{ slotProps.field.name }}</p>
          <yandex-map :key="slotProps['re-render-key']" :collection-axp="slotProps.value"
                      :zoom="15"
                      @clickMap="(val)=>slotProps.input([{locationLatitude: val[0], locationLongitude: val[1]}])"/>
        </div>
      </template>
      <template #eventId="slotProps">
        <events-autocomplete
            :selected="slotProps.value"
            :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
            :is-required="slotProps.field.required"
            :title="slotProps.field.name"
            @change="slotProps.input"
        />
      </template>
      <template #axpId="slotProps">
        <axp-autocomplete
            :selected="slotProps.value"
            :is-disabled="slotProps.disabled||slotProps.action!==AddDirectoryFieldModalActions.CREATE"
            :is-required="slotProps.field.required"
            :title="slotProps.field.name"
            @change="slotProps.input"
        />
      </template>
    </add-directory-field-modal>
    <add-adreska-from-excel
      v-model="visibleAddFromExcelDialog"
      :handle-error="handleError"
      :handle-success="handleSuccess"
    />
  </page>
</template>

<script>
import { OdkTagModal, Page, VTableNew } from 'components';
import {
  createAdreska,
  deleteAdreska,
  fetchAdreska,
  updateAdreska,
} from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';
import AddAdreskaFromExcel from 'components/Processes/Process3/AddAdreskaFromExcel.vue';
import { convertToLabelValueList } from 'utils/list';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import { workModeList } from 'components/Processes/list';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';

export default {
  name: 'CabinetAdreskaScreen',
  components: {
    YandexMap,
    AddAdreskaFromExcel,
    OdkTagModal,
    AddDirectoryFieldModal,
    VTableNew,
    Page,
    EventsAutocomplete,
    AxpAutocomplete,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      tagModalState: null,
      visibleAddFromExcelDialog: false,
      axps: [],
      adreska: [],
      events: [],
      administrativeDistrict: [],
      responsibleRes: [],
      workMode: convertToLabelValueList(workModeList),
      suppliersOptions: [],
      AddDirectoryFieldModalActions: AddDirectoryFieldModalActions,
    };
  },
  computed: {
    actionBlockItems() {
      return this.isAccessUpdate ? [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
        {
          id: 2,
          title: 'Добавить из Excel',
          method: this.loadFromExcel,
        },
      ] : [];
    },
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'eventId',
          url: '/events',
          name: 'Мероприятие',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'axpId',
          url: '/axp',
          name: 'АХП',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'contractorId',
          options: this.suppliersOptions,
          name: 'Подрядчик',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.ADDRESS,
          id: 'address',
          name: 'Адрес эксплуатации',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.ADDRESS,
          id: 'backupAddress',
          name: 'Резервный адрес эксплуатации',
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'administrativeDistrictId',
          url: '/administrative-district',
          name: 'Административный округ',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'responsibleDistrictDepartmentId',
          options: this.responsibleRes,
          name: 'Ответственный РЭС',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.REFERENCE,
          id: 'workModePlan',
          options: this.workMode,
          name: 'Режим работы по плану',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.NUMBER,
          id: 'quantityPlan',
          name: 'Количество по плану',
          required: true,
          customValidator: (value) => Number(value) > 0,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'comment',
          name: 'Примечание',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'location',
          name: 'Расположение',
          required: true,
          defaultValue: [],
        },
      ];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      const contractorName = this.isBoiler ? [
        {
          label: 'Подрядчик',
          key: 'contractorName',
          sort: {},
          filter: { type: 'contractorOdk' },
          thStyle: {
            minWidth: '100px',
          },
        },
      ] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              width: '60px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Мероприятие',
            key: 'event.name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: { type: 'eventsOdk' },
          },
          {
            label: 'Наименование АХП',
            key: 'axp.name',
            sort: {},
            filter: { type: 'axpOdk' },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Наименование ОДК',
            key: 'axp.odk.name',
            sort: {},
            filter: { type: 'odkOdk' },
            thStyle: {
              minWidth: '100px',
            },
          },
          ...contractorName,
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '110px',
            },
          },
          {
            label: 'Адрес эксплуатации',
            key: 'address',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Резервный адрес эксплуатации',
            key: 'backupAddress',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '120px',
            },
          },
          {
            label: 'Административный округ',
            key: 'administrativeDistrict.name',
            sort: {},
            filter: { type: 'administrativeDistrictOdk' },
            thStyle: {
              minWidth: '130px',
            },
          },
          {
            label: 'Ответственный РЭС',
            key: 'responsibleDistrictDepartmentName',
            sort: {},
            filter: { type: 'responsibleDistrictDepartmentOdk' },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Режим работы по плану',
            key: 'workModePlan',
            sort: {},
            filter: { type: 'vSearchSelect', values: convertToLabelValueList(workModeList) },
            thStyle: {
              minWidth: '100px',
            },
          }, {
            label: 'Количество по плану',
            key: 'quantityPlan',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Примечание',
            key: 'comment',
            sort: {},
            filter: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Тэги',
            key: 'tags',
            type: 'list',
            spoilerCount: 3,
            sort: {},
            filter: { type: 'tagOdk' },
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            key: 'tagButton',
            type: 'button',
            thStyle: {
              minWidth: '60px',
            },
          },
          ...editHeaders,
        ],
      };
    },
    transformedAdreska() {
      if (!this.adreska?.length) {
        return [];
      }

      return this.adreska;
    },
    isAccessUpdate() {
      return filterForAccess('process/3/3', this.getAuthorization?.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization', 'getToken', 'getAccount', 'isBoiler']),
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.adreska = (await fetchAdreska()).map((it, index) => ({
          ...it,
          eventId: String(it.event.id),
          axpId: String(it.axp.id),
          quantityPlan: String(it.quantityPlan),
          administrativeDistrictId: String(it.administrativeDistrict.id),
          tagButton: 'Добавить тэг',
          tags: it.tags.map((tag) => ({ key: `${index} ${tag.name}`, value: tag.name })),
          location: [{ locationLatitude: it.locationLatitude, locationLongitude: it.locationLongitude }],
          balloonContent: {
            clusterTitle: `${it.event.name}`,
            title: `${it.name}, ${it.axp.odk.name}`,
            props: [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'Мероприятие',
                value: it.event.name,
              },
              {
                label: 'Подрядчик',
                value: it.contractorName,
              },
              {
                label: 'Адрес эксплуатации',
                value: it.address,
              },
              {
                label: 'Количество по плану',
                value: it.quantityPlan,
              },
            ],
          },
        }));
        if (!this.isBoiler) this.adreska = this.adreska.filter((it) => it.contractorId === this.getAccount.supplier.id);
        this.suppliersOptions = this.defaultOdkEntityToSelectList(await this.getSupplierForSelectorByFilter({ kind: 'ODK' }));
        this.responsibleRes = this.defaultOdkEntityToSelectList(await this.supplierDepartments({ inn: '7720522853' }));
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    defaultOdkEntityToSelectList(entities) {
      return entities.map((it) => ({
        value: String(it.id), label: it.name,
      }));
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    formatBody(fields) {
      return {
        ...fields,
        locationLatitude: fields.location[0].locationLatitude,
        locationLongitude: fields.location[0].locationLongitude,
      };
    },
    deleteAdreska() {
      this.doAction(() => deleteAdreska(this.modalValue.id));
    },
    createAdreska() {
      this.doAction(() => createAdreska(
        this.formatBody(this.modalValue),
        this.getToken,
      ));
    },
    updateAdreska() {
      this.doAction(() => updateAdreska(
        this.modalValue.id,
        this.formatBody(this.modalValue),
        this.getToken,
      ));
    },
    async doAction(action) {
      this.loading = true;
      try {
        await action();
        this.closeModal();
        await this.loadData();
      } catch (error) {
        console.log('catch');
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    async handleCellClick(cell) {
      switch (cell.key) {
        case 'tagButton': {
          this.tagModalState = cell.row.id;
          return;
        }
      }
    },
    loadFromExcel() {
      this.visibleAddFromExcelDialog = true;
    },
    handleSuccess() {
      this.loadData();
    },

    handleError(error) {
      console.error(error);
      this.setNotification({ message: error });
    },
    ...mapActions('cabinet', ['getSupplierForSelectorByFilter']),
    ...mapActions('dataLists', ['supplierDepartments']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
