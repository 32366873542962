<template>
  <div>
    <v-table-new
      v-model="transformedData"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="data.pageOut"
      :template="template"
      excel-report-file-name="Эксплуатация. Свод монтажа"
      server-side-sorting
      @on-sort="handleSort"
    />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { formatDateFromOdkTimestamp, formatDateToOdkTimestamp } from 'lib/utils/date';
import { mapFilters } from 'lib/utils/table-pagination';
import { mapGetters } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'InstallationConstructionsReport',
  components: {
    VTableNew,
  },
  data() {
    return {
      data: [],
      reportData: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      filterEnd: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    transformedData() {
      if (!this.data || !this.data.data || !Array.isArray(this.data.data)) {
        return [];
      }
      console.log(this.data);
      return this.data.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Подрядчик',
            key: 'contractorName',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
            filter: this.contractorFilter,
          },
          {
            label: 'Наименование праздника',
            key: 'eventName',
            thStyle: {
              minWidth: '105px',
            },
            sort: {},
          },
          {
            label: 'Адрес',
            key: 'adreskaName',
            thStyle: {
              minWidth: '70px',
            },
            sort: {},
          },
          {
            label: 'Наименование ОДК',
            key: 'odkName',

            thStyle: {
              minWidth: '105px',
            },
            sort: {},
          },
          {
            label: 'Наименование АХП',
            key: 'axpName',
            sort: {},

            thStyle: {
              minWidth: '105px',
            },
          },
          {
            label: 'Количество ОДК',
            type: 'number',
            key: 'count',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '95px',
            },
          },
          {
            label: 'Комментарий место',
            key: 'commentPlace',
            sort: {},

            thStyle: {
              minWidth: '95px',
            },
          }, {
            label: 'Замечания',
            key: 'commentInstallation',
            sort: {},

            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Дата',
            key: 'date',
            filter: { type: 'period' },
            sort: {},

            thStyle: {
              minWidth: '83px',
            },
          },
        ],
      };
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),
  },
  methods: {
    async transformFilter() {
      const filterMapping = {
        contractorName: {
          name: 'contractorName',
        },
        date: {
          name: 'fromDate',
          mapping: formatDateToOdkTimestamp,
        },
      };

      const filterEndMapping = {
        date: {
          name: 'toDate',
          mapping: formatDateToOdkTimestamp,
        },
      };

      const filters = await mapFilters(
        filterMapping,
        this.filter,
        filterEndMapping,
        this.filterEnd,
      );
      if (!this.isBoiler) {
        filters.contractorId = this.getAccount.supplier.id;
      }
      return filters;
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('installation-construction/report-filters', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.data = responseData;
        this.data.pageOut = this.pagination;
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = await this.transformFilter();
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('installation-construction/report-filters', 1, this.data.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed);
      } catch (error) {
        console.log('data', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter, filterEnd) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      this.filterEnd = { ...filterEnd };
      await this.loadData();
    },
    async fetchReport() {
      const filters = await this.transformFilter();
      const { field: sortField, type: sort } = this.sort;
      return await fetchData('installation-construction/report-filters', 1, this.data.total, sortField, sort, filters);
    },
    transformed(item, index) {
      return {
        index: index + 1,
        contractorName: item.contractorName,
        odkName: item.odkName,
        axpName: item.axpName,
        eventName: item.eventName,
        adreskaName: item.adreskaName,
        count: item.count,
        commentPlace: item.commentPlace,
        commentInstallation: item.commentInstallation,
        date: formatDateFromOdkTimestamp(item.date),
      };
    },
  },
};
</script>

<style scoped>
</style>
