<template>
  <v-spoiler summary="ИВКЭ">
    <div class="remote-terminal-unit">
      <v-select
        v-model="internalValue.remoteTerminalUnit.type"
        :disabled="!isEditing"
        :options="options"
        class="remote-terminal-unit__item"
        title="Оборудование дистанционного сбора данных *"
      />

      <v-input
        v-model="internalValue.remoteTerminalUnit.serial"
        :disabled="!isEditing"
        class="remote-terminal-unit__item"
        clearable
        title="Номер ОДСД *"
      />

      <img alt="Подробнее" class="btn-more" src="~@/assets/icons/navigation/follow-arrow.svg"/>
    </div>
  </v-spoiler>
</template>

<script>
import { VInput, VSelect, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'TypeAccounting',
  components: {
    VInput,
    VSelect,
    VSpoiler,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        remoteTerminalUnit: {
          type: '',
          serial: '',
        },
      },
      internalValue: {},
      options: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);

        if (this.options.length === 0) {
          this.options = [
            {
              value: this.internalValue.boundariesBalanceSheet,
              label: this.internalValue.boundariesBalanceSheet,
            },
          ];
        }
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.remote-terminal-unit {
  display: flex;
  gap: 16px;

  &__item {
    width: 100%;
    margin-top: 0;
  }

  .btn-more {
    margin-top: 24px;
    cursor: pointer;
  }
}
</style>
