<template>
  <page title="Плановый баланс электрической энергии">
    <content-block />
  </page>
</template>

<script>
import ContentBlock from './components/ContentBlock.vue';
import { Page } from '@/components';

export default {
  name: 'PlanningBalancesPage',
  components: { ContentBlock, Page },
};
</script>

<style lang="scss" scoped>
</style>
