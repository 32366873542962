<template>
  <div :class="$style.container">
    <div>
      <div v-if="additionalDocument && additionalDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.additionalDocumentTitle">
          Дополнительная информация переданная потребителем:
        </div>
        <p v-html="comments"></p>
      </div>
      <div v-if="additionalDocument?.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in additionalDocument.files" :key="index"
           style="display: flex; justify-content: space-between">
          <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocumentFile[' + index + '].name'">{{ file.name }}</a>
          <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocumentFile[' + index + '].download'">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div v-if="retailReviewDocument && retailReviewDocument.comments" :class="$style.retailReviewDocument">
        <div :class="$style.commentsTitle">
          Потребитель предложил изменения в документах.
        </div>
        <p v-html="retailReviewComments"></p>
        <p :class="$style.title">Учтите предложения потребителя и загрузите договор для согласования:</p>

        <div v-if="!isNew && retailerOfferDocument.files" :class="$style.reviewDocumentFiles">
          <p v-for="(file, index) in retailerOfferDocument.files" :key="index"
             style="display: flex; justify-content: space-between">
            <a :download="file.name" :href="fileUrl(file)"
               :role="'retailerOfferDocumentFile[' + index + '].name'">{{
                index + 1
              }}.&nbsp;&nbsp;&nbsp;<span>{{ file.name }}</span></a>
            <span :class="$style.replace" :role="'retailerOfferDocumentFile[' + index + '].replace'" @click="replace">
              заменить</span>

          </p>
        </div>
      </div>

      <div :class="$style.blockContent">
        <c-input
          v-model="ContractConsumerMes.ContractNumber"
          :error="contractNumberError"
          :label="'Номер договора ' + contractName + '*'"
          :validation="$validate.isNotEmptyString"
          role="ContractConsumerMes.ContractNumber"
        />
      </div>

      <div :class="$style.blockContent">
        <c-input
          v-model="ContractConsumerMes.ExternalId"
          :error="externalIdError"
          :label="'ИКТС*'"
          :validation="$validate.isNotEmptyString"
          role="ContractConsumerMes.ExternalId"
        />
      </div>

      <div :class="$style.blockContent">
        <c-date-picker
          v-model="ContractConsumerMes.ContractDate"
          :error="contractDateError"
          :label="'Дата договора ' + contractName + '*'"
          role="ContractConsumerMes.ContractDate"
        />
      </div>

      <template v-if="isDkpContract">
        <div :class="$style.blockContent">
          <c-date-picker
              v-model="ContractConsumerMes.ContractStartDate"
              :error="contractStartDateError"
              label="Дата вступления в силу договора*"
              role="ContractConsumerMes.ContractStartDate"
          />
        </div>
        <div :class="$style.blockContent">
          <c-date-picker
              v-model="ContractConsumerMes.ContractEndDate"
              :error="contractEndDateError"
              label="Дата истечения договора*"
              role="ContractConsumerMes.ContractEndDate"
          />
        </div>
      </template>

      <div :class="$style.blockContent">
        <c-select
          v-model="ContractConsumerMes.TariffCategory"
          :options="tariffCategoryTypeList"
          label="Тариф*"
          role="TariffCategory"
        />
      </div>

      <div v-if="ContractConsumerMes.TariffCategory?.value === 'TARIFF_CATEGORY_2'"  :class="$style.blockContent">
          <c-select
            v-model="ContractConsumerMes.TariffZone"
            :options="tariffZoneTypeList"
            label="Тарифная зона *"
            role="tariffZone"
          />
      </div>

      <div :class="$style.blockContent">
        <department-selector
                             :inn="getAccount.profile.inn"
                             @select="onDepartmentSelected"/>
      </div>

      <div v-if="applicationData.consumer?.company?.inn && isDkpContract" :class="$style.blockContent">
        <employee-select-or-input
          :inn="applicationData.consumer.company.inn"
          is-company-mode
          mode="select"
          permission="SVET_A1-S"
          @employeeSelectOrInput="updateSignatory"
        />
      </div>
    </div>

    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="notSignedDoc"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
    <div :class="$style.actionContent">
      <p :class="$style.submitNote">
        Нажимая кнопку «Подписать и отправить», вы направляете договор потребителю и подписываете его простой
        электронной подписью.
      </p>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <submit-task-details :disabled="isWaitingSubmit || !isValid" title="ПОДПИСАТЬ И ОТПРАВИТЬ" @submit="onSubmit"/>
    </div>
  </div>
</template>

<script>

import { SimpleSignatureModal } from 'components';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import DepartmentSelector from 'components/DocumentSideSelectOrInput/DepartmentSelector';
import { formHeaderBySides } from 'services/contractXml';
import { formatDateToXmlDateFormat } from 'lib/utils/date';
import EmployeeSelectOrInput from 'components/DocumentSideSelectOrInput/EmployeeSelectOrInput.vue';

export default {
  name: 'SendRetailerOffer',
  components: {
    EmployeeSelectOrInput,
    SimpleSignatureModal,
    SubmitTaskDetails,
    HorizontalProgressBar,
    DepartmentSelector,
    CInput: () => import('atoms/common/inputs/CInput'),
    CDatePicker: () => import('atoms/common/inputs/CDatePicker'),
    CSelect: () => import('atoms/common/CSelect'),
  },
  props: {
    documentId: String,
    accountId: String,
    actionProps: Object,
    applicationData: Object,
    additionalDocument: Object,
    retailReviewDocument: Object,
    retailerOfferDocument: Object,
    userId: String,
    roleId: String,
  },
  data() {
    return {
      isChecked: true,
      accompanyingText: '',
      submitError: '',
      contractNumberError: { error: false },
      externalIdError: { error: false },
      contractDateError: { error: false },
      contractIgkError: { error: false },
      contractStartDateError: { error: false },
      contractEndDateError: { error: false },
      isWaitingSubmit: false,
      sendRetailerOfferDocs: [],
      isNew: true,
      departments: [],
      signatory: null,
      ContractConsumerMes: {
        SourceApplicationDocumentId: '',
        ContractNumber: '',
        ContractDate: '',
        ExternalId: '',
        ReliabilityClass: '',
        UsagePointAddress: '',
        VoltageLevel: '',
        AccountingRatio: '',
        ObjectNumber: '',
        MeterSerial: '',
        TariffCategory: { label: '1 ценовая категория', value: 'TARIFF_CATEGORY_1' },
        TariffZone: null,
        Department: '',
        ContractStartDate: '',
        ContractEndDate: '',
      },
      notSignedDoc: '',
      isVisibleSimpleSignatureModal: false,
    };
  },
  computed: {
    isValid() {
      return (this.ContractConsumerMes.ContractNumber?.length && this.ContractConsumerMes.ExternalId?.length && this.ContractConsumerMes.ContractDate?.length) && this.ContractConsumerMes.Department.id &&
          (!this.isDkpContract || (this.ContractConsumerMes.ContractStartDate?.length && this.ContractConsumerMes.ContractEndDate?.length)) && (this.ContractConsumerMes.TariffCategory.value !== 'TARIFF_CATEGORY_2' || this.ContractConsumerMes.TariffZone != null);
    },
    comments() {
      return formatting.formattedString(this.additionalDocument.comments, '«', '»');
    },
    retailReviewComments() {
      return formatting.formattedString(this.retailReviewDocument.comments, '«', '»');
    },
    isDkpContract() {
      return this.applicationData?.connectionApplicationWithContractType?.value === 'ELECTRICITY_PURCHASE_CONTRACT';
    },
    contractName() {
      return this.isDkpContract ? 'купли-продажи' : 'энергоснабжения';
    },
    ...mapGetters('dataLists', [
      'tariffCategoryTypeList',
      'tariffZoneTypeList',
    ]),
    ...mapGetters('user', ['getUserType', 'getAccount', 'getAuthorization']),
  },
  watch: {
    applicationData: {
      immediate: true,
      handler(val) {
        if (val?.objectLocation) this.setContractConsumerMesData();
      },
    },
    retailerOfferDocument: {
      immediate: true,
      handler(val) {
        if (val && val.number) {
          this.isNew = false;
          if (val.comments) {
            this.accompanyingText = val.comments;
          }
          this.ContractConsumerMes.ContractNumber = val.number;
          this.ContractConsumerMes.ContractDate = val.date;
          this.ContractConsumerMes.TariffCategory = this.tariffCategoryTypeList.find((it) => it.value === val.tariffCategory) ?? {
            label: '1 ценовая категория',
            value: 'TARIFF_CATEGORY_1',
          };
          this.ContractConsumerMes.TariffZone = val.tariffZone ? this.tariffZoneTypeList.find((it) => it.value === val.tariffZone) : null;
        }
      },
    },
    'ContractConsumerMes.TariffCategory': {
      deep: true,
      handler() {
        if (this.ContractConsumerMes.TariffCategory.value !== 'TARIFF_CATEGORY_2') {
          this.ContractConsumerMes.TariffZone = null;
        }
      },
    },
  },
  methods: {
    updateSignatory(newSignatory) {
      this.signatory = newSignatory;
    },
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              'ЗАЯВИТЕЛЮ ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ ПРИНЯТЬ УСЛОВИЯ ДОГОВОРА',
              '',
            );
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      const account = this.getAccount;
      const authorization = this.getAuthorization.authorization;
      this.submitError = '';
      this.isWaitingSubmit = true;

      if (this.ContractConsumerMes.ContractNumber.length === 0) {
        this.contractNumberError = { error: true };
        this.submitError = '';
        this.isWaitingSubmit = false;
        return;
      }

      if (this.ContractConsumerMes.ExternalId.length === 0) {
        this.externalIdError = { error: true };
        this.submitError = '';
        this.isWaitingSubmit = false;
        return;
      }

      if (this.ContractConsumerMes.ContractDate.length === 0) {
        this.contractDateError = { error: true };
        this.submitError = '';
        this.isWaitingSubmit = false;
        return;
      }

      const consumerName = `${this.applicationData.consumer.familyName} ${this.applicationData.consumer.firstName} ${this.applicationData.consumer.patronymic ?? ''}`;

      if (this.applicationData.connectionApplicationWithContractType.value === 'ELECTRICITY_PURCHASE_CONTRACT') {
        const sides = {
          PERFORMER: {
            company: {
              inn: account.profile.inn,
              name: account.profile.name,
            },
            signatory: {
              role: authorization.role,
              fullNameGenitive: authorization.user?.fullNameGenitive,
              fullName: authorization.user?.fullName,
            },
          },
          SUPPLIER: {
            company: {
              inn: this.applicationData.consumer.company?.inn,
              name: this.applicationData.consumer.company?.name ?? consumerName,
            },
            consumer: {
              snils: this.applicationData.consumer.snils,
            },
            signatory: this.applicationData.consumer.company ? {
              role: {
                name: '',
                fullName: consumerName,
              },
            } : undefined,
          },
        };

        const companyInfo = {
          inn: this.applicationData.consumer.company?.inn,
          companyName: this.applicationData.consumer.company?.name,
          name: consumerName,
          userId: this.userId,
          roleId: this.roleId,
          accountId: this.accountId,
          legalAddress: this.applicationData.consumer.company?.address,
          kpp: this.applicationData.consumer.company?.kpp,
          ogrn: this.applicationData.consumer.company?.ogrn,
          bankRequisites: this.applicationData.consumer.company?.bankRequisites?.first(),
          oktmo: this.applicationData.consumer.company?.oktmo,
          okved: this.applicationData.consumer.company?.okved,
          okpo: this.applicationData.consumer.company?.okpo,
          phoneNumber: this.applicationData.consumer.company?.phoneNumber,
        };

        const flConsumerInfo = {
          snils: this.applicationData.consumer.insuranceNumber,
          inn: this.applicationData.consumer.inn,
          companyName: this.applicationData.consumer.company?.name,
          name: consumerName,
          accountId: this.accountId,
          userId: this.userId,
          legalAddress: this.applicationData.consumer.registrationAddress,
          postAddress: this.applicationData.consumer.postAddress,
          phoneNumber: this.applicationData.consumer.phone,
          passport: {
            series: this.applicationData.consumer.documentSeries,
            number: this.applicationData.consumer.passportNumber,
            issueDate: formatDateToXmlDateFormat(this.applicationData.consumer.issueDate),
            issuer: this.applicationData.consumer.issuer,
            subdivisionCode: this.applicationData.consumer.subdivisionCode,
          },
        };
        const consumer = this.applicationData.consumer.company.inn !== undefined ? companyInfo  : flConsumerInfo;

        this.notSignedDoc = connectionApplicationData.contractConsumerElectricityPurchaseXml(
            this.ContractConsumerMes,
            {
              inn: account.profile.inn,
              name: account.profile.name,
              accountId: account.id,
              userId: authorization.user.id,
              roleId: authorization.role.id,
              legalAddress: account.profile.legalAddress,
              postAddress: account.profile.postAddress,
              kpp: account.profile.kpp,
              ogrn: account.profile.ogrn,
              bankRequisites: account.profile.bankRequisites[0],
              oktmo: account.profile.oktmo,
              okved: account.profile.okved,
              okpo: account.profile.okpo,
              phoneNumber: account.profile.phoneNumber,
            },
          consumer,
            this.signatory ?? consumer,
            formHeaderBySides(sides),
        );
      } else {
        this.notSignedDoc = connectionApplicationData.contractConsumerMesXml(
            this.ContractConsumerMes,
            {
              inn: account.profile.inn,
              name: account.profile.name,
              accountId: account.id,
            },
            {
              snils: this.applicationData.consumer.insuranceNumber,
              companyInn: this.applicationData.consumer.company?.inn,
              companyName: this.applicationData.consumer.company?.name,
              name: consumerName,
              accountId: this.accountId,
            },
            this.accompanyingText,
        );
      }

      console.log('this.notSignedDoc', this.notSignedDoc);

      this.openSignatureModal();
    },
    onClose() {
      this.$emit('close');
    },
    handleRemoveSendRetailerOfferDocs(file) {
      this.sendRetailerOfferDocs = this.sendRetailerOfferDocs
        .filter((scan) => scan.source !== file.source);
    },
    replace() {
      this.isNew = true;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    onDepartmentSelected(selectedDepartment) {
      this.ContractConsumerMes.Department = selectedDepartment;
    },
    setContractConsumerMesData() {
      this.ContractConsumerMes.SourceApplicationDocumentId = this.documentId;
      this.ContractConsumerMes.UsagePointAddress = this.applicationData.objectLocation.objectAddress;
      if (this.applicationData.connectionCharacteristics.reliabilityClass && this.applicationData.connectionCharacteristics.reliabilityClass.value) {
        this.ContractConsumerMes.ReliabilityClass = this.applicationData.connectionCharacteristics.reliabilityClass.value;
      }
      // TODO получить номер счетчика с admissionCertificate: this.ContractConsumerMes.MeterSerial = this.AdmissionCertificate.Meter.Serial;
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.title
  padding-bottom: 16px
  +base-title

.additionalDocumentTitle
  padding-bottom: 16px
  +base-title

.commentsTitle
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocument
  padding-bottom: 24px

.reviewDocument, .reviewDocumentFiles, .accompanyingText, .retailReviewDocument
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles
  margin-bottom: 40px

.reviewDocument p, .retailReviewDocument p
  margin-top: 8px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.reviewDocument p.title, .retailReviewDocument p.title
  margin-top: 40px
  padding-bottom: 0
  margin-bottom: 40px
  +base-title

.blockContent
  margin-bottom: 40px

.statusGroup
  margin-top: 40px

.submitNote
  margin-top: 40px
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.accompanyingText
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #000

.correctionTitle
  padding-bottom: 12px
  +base-title

p.subTitle
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  +medium-spacing

.reviewDocumentFiles p span.replace
  color: #4C4E51
  white-space: nowrap
  cursor: pointer

.actionContent
  width: 560px
  margin: 0 auto
</style>
