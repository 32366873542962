import { checkField } from '@/utils/common';

export const getSurnameAndInitials = (fullName) => {
  if (!fullName) {
    return '';
  }

  const getInitials = (name, patronymic) => {
    let initials = '';

    if (checkField(name)) {
      initials += `${name[0].toUpperCase()}.`;
    }

    if (checkField(patronymic)) {
      initials += `${patronymic[0].toUpperCase()}.`;
    }

    return initials;
  };

  return `${checkField(fullName.surname)} ${getInitials(fullName.name, fullName.patronymic)}`;
};


export const fullNameToString = (fullName) => {
  if (!fullName) {
    return '';
  }
  let fullNameString = checkField(fullName.surname);
  if (fullName.name) {
    fullNameString += ` ${fullName.name}`;
  }
  if (fullName.patronymic) {
    fullNameString += ` ${fullName.patronymic}`;
  }
  return `${fullNameString}`;
};
