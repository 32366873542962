<template>
  <img v-if="value.previewExists" :alt="value.alt" :src="value.src" class="v-table-cell-preview" @click="openImage"/>
  <div v-else>{{ value.notExistsMessage }}</div>
</template>

<script>

export default {
  name: 'VTableCellPreview',
  props: {
    value: {
      type: Object, default: () => ({
        previewExists: false,
        src: null,
        alt: 'Превью',
        notExistsMessage: 'Превью отсутствует',
        disableDefaultClick: true,
      }),
    },
  },
  methods: {
    openImage() {
      if (this.value.disableDefaultClick) {
        this.$emit('image-click');
        return;
      }

      window.open(this.value.src, '_blank');
    },
  },
};
</script>

<style lang="scss">
.v-table-cell-preview {
  width: 128px;
  height: 128px;
}
</style>
