<template>
  <div class="form-chapter">
    <div v-if="title !== ''" class="form-chapter__title-wrp">
      <h1
        class="form-chapter__title">
        {{ title }}
      </h1>

      <div
        v-if="type !== ''"
        class="form-chapter__title-type"
      >
        {{ type }}
      </div>
    </div>
    <slot/>
    <v-divider class="form-chapter__divider"/>
  </div>
</template>

<script>

import VDivider from 'components/VDivider/VDivider.vue';

export default {
  components: { VDivider },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    column1: {
      type: Array,
      default: () => [],
    },
    column2: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.form-chapter {
  display: flex;
  flex-direction: column;

  &__divider {
    margin-top: 25px;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;

    &-wrp {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    &-type {
      display: flex;
      align-self: center;
      padding: 8px 25px;
      border: 1px solid #C1C4C7;
      border-radius: 16px;
      color: #263238;
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;

    color: #4C4E51;

    .column {
      width: 50%;
      display: flex;
      flex-direction: column;

      &__item {
        margin-bottom: 20px;
        font-size: 16px;

        &-value {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
