<template>
  <page title="Участки сети">
    <select-button
      :items="actionBlockItems"
    />
    <v-circle-loader v-if="isLoadingSuppliers" :loading="isLoadingSuppliers"/>
    <v-table-new
      v-model="transformedBorders"
      :loading="loading"
      :template="template"
      excel-report-file-name="Участки сети"
      @delete="displayDeleteDialog"
      @edit="displayEditDialog"
      @on-cell-click="cellClickHandler"
    />
    <modal v-if="isShowDeleteDialog"
           :is-btn-close-visible="false"
           :modal-style="{
              borderRadius: '15px',
           }"
           :modal-wrapper-style="{
              'z-index': '10083'
           }"
           @close="isShowDeleteDialog = false"
    >
      <template #header>
        <div class="boundaries-balance-sheet__header">Удалить участок сети?</div>
      </template>
      <template #body>
        <div class="row mb-24 boundaries-balance-sheet__delete__info">
          <div class="col col--1of6 boundaries-balance-sheet__delete__info__title">Наименование:</div>
          <div class="col col--5of6">{{ itemForDelete.boundaryName }}</div>
        </div>
        <div class="row mb-24 boundaries-balance-sheet__delete__info">
          <div class="col col--1of6 boundaries-balance-sheet__delete__info__title">ТСО 1:</div>
          <div class="col col--5of6">{{ itemForDelete.firstCompany.name }}</div>
        </div>
        <div class="row mb-24 boundaries-balance-sheet__delete__info">
          <div class="col col--1of6 boundaries-balance-sheet__delete__info__title">ТСО 2:</div>
          <div class="col col--5of6">{{ itemForDelete.secondCompany.name }}</div>
        </div>

        <horizontal-progress-bar v-if="isWaitingSubmit" class="boundaries-balance-sheet__delete__progress"/>

        <div class="boundaries-balance-sheet__delete__actions">
          <v-button variant="outlined" @click="isShowDeleteDialog = false">Отмена</v-button>
          <v-button variant="outlined-red" @click="deleteBorder">Удалить</v-button>
        </div>
      </template>
    </modal>
    <modal v-if="isShowAddDialog"
           :body-style="{
             minHeight: '420px',
           }"
           :is-btn-close-visible="false"
           :modal-style="{
              borderRadius: '15px',
           }"
           :modal-wrapper-style="{
              'z-index': '10083'
           }"
           is-scroll-y
           @close="isShowAddDialog = false"
    >
      <template #header>
        <div class="boundaries-balance-sheet__header">
          <span v-show="boundaryId">Редактирование</span>
          <span v-show="!boundaryId">Добавление</span> участка сети
        </div>
      </template>
      <template #body>
        <div class="row mb-16 boundaries-balance-sheet__delete__info">
          <div class="col col--1of1">
            <v-search-select
              v-if="suppliersOptions.length > 0"
              v-model="tso"
              :options="suppliersOptions"
              title="Смежная сетевая компания*"
            />
          </div>
        </div>
        <div class="row mb-16 boundaries-balance-sheet__delete__info">
          <div class="col col--1of1">
            <usage-point-connection-select :key="usagePointConnectionReset" v-model="mainSubstationObject"
                                           :exclude-substations="excludeSubstations" only-tp rp-multi-select/>
          </div>
        </div>
        <div class="row mb-16 boundaries-balance-sheet__delete__info">
          <div class="col col--1of1" style="text-align: center">
            <v-button :disabled="tso.length === 0 || !mainSubstationObject?.mainSubstation" variant="outlined-colored"
                      @click="addBorder">Добавить в список
            </v-button>
          </div>
        </div>
        <div class="boundaries-balance-sheet__delete__info__title">
          <span v-if="addedBoundaries.length === 0">Добавьте в список</span>
        </div>
        <v-table-new
          v-model="addedBoundaries"
          :hide-export="true"
          :hide-spoiler="true"
          :is-display-column-hide-settings="false"
          :pagination-show="false"
          :template="templateBoundaries"
          style="margin-bottom: 100px"
          @delete="deleteBoundaries"
        />
        <horizontal-progress-bar v-if="isWaitingSubmit" class="boundaries-balance-sheet__delete__progress"/>
      </template>
      <template #footer>
        <div class="boundaries-balance-sheet__delete__actions">
          <v-button variant="outlined" @click="isShowAddDialog = false">Отмена</v-button>
          <v-button
            :disabled="tso.length === 0 || addedBoundaries.length === 0 || (boundaryId.length > 0 && tsoOld === tso && !isUpdated)"
            variant="filled"
            @click="submitBoundaries">
            <span v-show="boundaryId">Сохранить</span>
            <span v-show="!boundaryId">Отправить</span>
          </v-button>
        </div>
      </template>
    </modal>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { convertDbFormat } from 'lib/utils/date';
import { Page, SelectButton, VButton, VCircleLoader, VSearchSelect, VTableNew } from '@/components';
import formatting from 'lib/utils/formatting';
import Modal from '@/components/Modal/Modal';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { generateAddBalanceBoundaryXml, generateDeleteBoundariesXml, generateUpdateBalanceBoundaryXml } from './xml';
import UsagePointConnectionSelect from 'components/selectors/UsagePointConnectionSelect/UsagePointConnectionSelect.vue';
import { nanoid } from 'nanoid';

export default {
  name: 'CabinetBoundariesBalanceSheet',
  components: {
    VTableNew,
    Page,
    Modal,
    VButton,
    HorizontalProgressBar,
    UsagePointConnectionSelect,
    SelectButton,
    VSearchSelect,
    VCircleLoader,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      borders: [],
      loading: false,
      isLoadingSuppliers: false,
      actionBlockItems: [
        {
          id: 1,
          title: 'Добавить участок сети',
          method: () => {
            this.boundaryId = '';
            this.displayAddEditDialog();
          },
        },
      ],
      isShowDeleteDialog: false,
      isShowAddDialog: false,
      itemForDelete: undefined,
      isWaitingSubmit: false,
      mainSubstationObject: {},
      usagePointConnectionReset: 1,
      tso: '',
      tsoOld: '',
      isUpdated: false,
      addedBoundaries: [],
      suppliers: [],
      boundaryId: '',
    };
  },
  computed: {
    transformedBorders() {
      return this.borders.map((it, index) => ({
        ...it,
        index: index,
        boundaryName: formatting.formatBoundaryName(it),
      }));
    },
    template() {
      return {
        headers: [
          {
            key: 'id',
            type: 'hide',
          },
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Наименование',
            key: 'boundaryName',
            size: 'lg',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'ТСО 1',
            key: 'firstCompany.name',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
            filter: {},
          },
          {
            label: 'ТСО 2',
            key: 'secondCompany.name',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            size: 'sm',
            filter: {},
          },
          {
            key: 'editDeleteButtons',
            type: 'editDeleteButtons',
          },
          {
            key: 'followLink',
            type: 'followLink',
          },
        ],
      };
    },
    templateBoundaries() {
      return {
        headers: [
          {
            key: 'id',
            type: 'hide',
          },
          {
            label: 'ПЦ',
            key: 'mainSubstationName',
          },
          {
            label: 'РП',
            key: 'distributionSubstations',
            type: 'list',
            thStyle: {
              minWidth: '30px',
            },
          },
          {
            key: 'deleteButton',
            type: 'editDeleteButtons',
          },
        ],
      };
    },
    suppliersOptions() {
      return this.suppliers
        .map((it) => ({ value: it.inn, label: it.name }));
    },
    excludeSubstations() {
      return this.addedBoundaries.map((item) => (item.mainSubstationNumber));
    },
  },
  watch: {
    async isShowAddDialog(val) {
      if (!val || this.boundaryId.length === 0) {
        this.tso = '';
        this.addedBoundaries = [];
      }

      this.mainSubstationObject = {};
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.borders = await this.loadBalanceBoundaries();
      } catch (error) {
        this.setNotification({ message: error.message });
        console.log('error loadData: ', error);
      } finally {
        this.loading = false;
      }
    },
    convertDbFormat(value) {
      return convertDbFormat(value);
    },
    async cellClickHandler(cell) {
      if (cell.key === 'followLink' && cell.row.connections[0].mainSubstation.number) {
        return await this.$router.push({
          name: 'cabinet.boundaries-balance-sheet.chart',
          params: {
            number: cell.row.connections[0].mainSubstation.number,
          },
        });
      }
    },
    displayDeleteDialog(cell) {
      this.itemForDelete = cell.row;
      this.isShowDeleteDialog = true;
    },
    displayEditDialog(cell) {
      this.boundaryId = cell.row.id;
      this.tso = cell.row.secondCompany.inn;
      this.tsoOld = cell.row.secondCompany.inn;
      this.isUpdated = false;
      this.addedBoundaries = cell.row.connections.map((item) => {
        const result = {
          id: nanoid(),
          mainSubstationNumber: item.mainSubstation.number,
          mainSubstationName: item.mainSubstation.fullName,
          deleteButton: {
            deletable: true,
            updatable: false,
          },
        };

        if (item.distributionSubstation) {
          result.distributionSubstations = item.distributionSubstation?.map((item) => ({ value: item }));
        }

        return result;
      });

      this.displayAddEditDialog();
    },
    async deleteBorder() {
      this.isWaitingSubmit = true;

      try {
        const xml = generateDeleteBoundariesXml(this.itemForDelete.id);

        await this.documentUpload({ xml: xml });

        this.isShowDeleteDialog = false;
        await this.loadData();
      } catch (error) {
        console.log('error deleteBorder: ', error);
        this.setNotification({ message: error.message });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    async submitBoundaries() {
      this.isWaitingSubmit = true;

      try {
        const xml = this.boundaryId
          ? generateUpdateBalanceBoundaryXml(this.boundaryId, this.tso, this.addedBoundaries)
          : generateAddBalanceBoundaryXml(this.tso, this.addedBoundaries);

        await this.documentUpload({ xml: xml });

        this.isShowAddDialog = false;

        this.vueShowNotification(
          this.boundaryId ? 'Участок сети изменён' : 'Участок сети добавлен',
        );

        await this.loadData();
      } catch (error) {
        console.log('error submitBoundaries: ', error);
        this.setNotification({ message: error.message });
      } finally {
        this.isWaitingSubmit = false;
      }
    },
    addBorder() {
      this.isUpdated = true;
      this.addedBoundaries.push({
        id: nanoid(),
        mainSubstationNumber: this.mainSubstationObject.mainSubstation.number,
        mainSubstationName: this.mainSubstationObject.mainSubstation.fullName,
        distributionSubstations: this.mainSubstationObject.distributionSubstations?.map((item) => ({ value: item.fullName })),
        deleteButton: {
          deletable: true,
          updatable: false,
        },
      });

      this.usagePointConnectionReset++;
      this.mainSubstationObject = {};
    },
    deleteBoundaries(cell) {
      this.isUpdated = true;
      this.addedBoundaries = this.addedBoundaries.filter((item) => item.id !== cell.row.id);
    },
    async displayAddEditDialog() {
      if (this.suppliers.length > 0) {
        this.isShowAddDialog = true;
      }

      if (this.suppliers.length === 0) {
        try {
          this.isLoadingSuppliers = true;

          this.suppliers = await this.getSuppliers();

          this.isShowAddDialog = true;
        } catch (error) {
          console.log('error isShowAddDialog: ', error);
          this.setNotification({ message: error.message });
        } finally {
          this.isLoadingSuppliers = false;
        }
      }
    },
    ...mapActions('border', ['loadBalanceBoundaries']),
    ...mapActions('dataLists', ['getSuppliers']),
    ...mapActions('user', ['setNotification']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";

.boundaries-balance-sheet {
  &__header {
    font-size: 24px;
    width: 100%;
    text-align: center;
    padding-top: 12px;
  }

  &__delete {
    &__info {
      font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      &__title {
        font-weight: 600;
        font-size: 16px;
        color: gray;
      }
    }

    &__progress {
      width: 60%;
      margin: 0 auto;
    }

    &__actions {
      padding: 12px 24px 12px 24px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
