<template>
  <page title="Реестр договоров энергоснабжения с Потребителями">
    <v-table-new
      v-model="transformedConsumers"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="consumersPage.pageOut"
      :template="template"
      excel-report-file-name="Реестр договоров энергоснабжения с Потребителями"
      @on-cell-click="cellClickHandler"
    />
  </page>
</template>

<script>
import Page from 'components/Page/Page';
import VTableNew from 'components/VTableNew/VTable';
import { mapActions, mapGetters } from 'vuex';
import { convertDateStringToUTC, convertDbFormat } from 'lib/utils/date';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'CabinetConsumers',
  components: { VTableNew, Page },
  data() {
    return {
      consumersPage: {},
      loading: false,
      pagination: initialPagination,
      filter: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Наименование потребителя',
            key: 'consumerName',
            thStyle: {
              minWidth: '105px',
              width: '150px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'ИНН потребителя',
            key: 'inn',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 3,
            },
            filter: {},
          },
          {
            label: 'СНИЛС потребителя',
            key: 'snils',
            thStyle: {
              width: '150px',
              whiteSpace: 'nowrap',
            },
            sort: {},
            tooltip: {
              maxLength: 5,
            },
            filter: {},
          },
          {
            label: 'Адрес потребителя',
            key: 'serviceLocation',
            thStyle: {
              minWidth: '100px',
              width: '150px',
            },
            filter: {},
          },
          {
            label: 'Категория надежности',
            key: 'reliabilityClass',
            thStyle: {
              width: '150px',
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 9,
            },
            sort: {},
          },
          {
            label: 'Максимальная мощность, МВт',
            key: 'maxPower',
            type: 'number',
            thStyle: {
              width: '150px',
              whiteSpace: 'nowrap',
            },
            tooltip: {
              maxLength: 12,
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Состояние абонента',
            key: 'state',
            thStyle: {
              minWidth: '80px',
              width: '150px',
            },
            sort: {},
          },
          {
            label: 'Примечание',
            key: 'remark',
            thStyle: {
              minWidth: '90px',
            },
          },
          {
            label: 'Договор энергоснабжения. Номер договора',
            key: 'contractNumber',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Договор энергоснабжения. Наименование отделения',
            key: 'retailDepartmentName',
            thStyle: {
              whiteSpace: 'nowrap',
              minWidth: '100px',
            },
            sort: {},
          },
          {
            label: 'Договор энергоснабжения. Дата договора',
            key: 'contractDate',
            thStyle: {
              minWidth: '120px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Договор энергоснабжения. Номер объекта',
            key: 'objectNumber',
            thStyle: {
              whiteSpace: 'nowrap',
              minWidth: '60px',
            },
            sort: {},
          },
          {
            label: 'Договор энергоснабжения. Дата начала снабжения',
            key: 'startOfSupplyDate',
            thStyle: {
              minWidth: '120px',
            },
          },
          {
            label: 'Номер акта разграничения',
            key: 'delimitationActNumber',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Дата акта разграничения',
            key: 'delimitationActDate',
            sort: { type: 'date' },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Номер АТП',
            key: 'connectionActNumber',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Дата АТП',
            key: 'connectionActDate',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'Величина брони. Аварийной, МВт',
            type: 'number',
            key: 'emergencyArmorValue',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Величина брони. Технологической, МВт',
            type: 'number',
            key: 'technicalArmorValue',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Номер лицевого счёта',
            key: 'contractNumber',
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Лицевой счёт. Сумма',
            type: 'number',
            key: 'balance',
            thStyle: {
              minWidth: '60px',
            },
          },
          ...this.buttonsTemplate,
        ],
      };
    },
    buttonsTemplate() {
      if (this.getUserType === 'SUPPLIER_OF_LAST_RESORT' || this.getUserType === 'RETAILER' || this.getUserType === 'Retailer') return [
        {
          label: 'Выставить счёт',
          key: 'accountButton',
          type: 'button',
        },
        {
          label: 'Должник',
          key: 'debtorButton',
          type: 'button',
          customCheck: (item) => parseFloat(item.balance) >= 0 || item.state === 'Должник',
        },
      ];
      return [];
    },
    consumers() {
      return this.consumersPage.list ?? [];
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0 && filterKey === 'contractDate') {
          result[filterKey] = convertDateStringToUTC(filterValue);
        } else {
          result[filterKey] = filterValue;
        }
      });
      return result;
    },
    transformedConsumers() {
      if (this.consumers.length === 0) {
        return [];
      }

      return this.consumers.map(this.transformed);
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    async cellClickHandler(cell) {
      if (cell.key === 'followLink') {
        return this.$router.push(`consumers/card/${cell.row.inn ?? cell.row.snils}`);
      }
      if (cell.key === 'accountButton') {
        await this.decreaseAccountBalanceByThousand({ contractNumber: cell.row.contractNumber });
        await this.loadData();
        return;
      }
      if (cell.key === 'debtorButton') {
        const xml = `<?xml version="1.0" encoding="UTF-8" ?>
<RequestSetDebtorStatus
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns="http://energochain.ru/XML/documents/request-set-debtor-status/1.0"
        xsi:schemaLocation="http://energochain.ru/XML/documents/request-set-debtor-status/1.0 RequestSetDebtorStatus.xsd">
    <ConsumerId>${cell.row.id}</ConsumerId>
</RequestSetDebtorStatus>
`;
        await this.documentUpload({ xml: xml });
        await this.loadData();
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.consumersPage = await this.consumersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('loadConsumers', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const consumers = await this.consumersByFilterPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.consumersPage.pageOut.totalElements,
          },
        });

        console.log('consumers', consumers);
        if (!consumers?.list) {
          return [];
        }

        return consumers.list.map(this.transformed);
      } catch (e) {
        console.log('loadConsumers', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    formatState(data) {
      switch (data) {
        case 'ACTIVE':
          return 'Действующий';
        case 'N':
          return 'Новый';
        case 'DEBTOR':
          return 'Должник';
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        id: it.id,
        consumerName: it.consumerName,
        retailDepartmentName: it.retailDepartment?.name,
        serviceLocation: it.serviceLocation,
        contractNumber: it.contractNumber,
        contractDate: convertDbFormat(it.contractDate),
        objectNumber: it.objectNumber,
        startOfSupplyDate: convertDbFormat(it.startOfSupplyDate),
        delimitationActNumber: it.delimitationActNumber,
        delimitationActDate: convertDbFormat(it.delimitationActDate),
        connectionActNumber: it.connectionActNumber,
        connectionActDate: convertDbFormat(it.connectionActDate),
        reliabilityClass: it.reliabilityClass,
        maxPower: it.maxPower,
        emergencyArmorValue: it.emergencyArmorValue,
        technicalArmorValue: it.technicalArmorValue,
        state: this.formatState(it.state),
        remark: it.remark,
        inn: it.inn,
        snils: it.snils,
        balance: it.businessAccount?.balance,
        accountButton: 'Выставить счёт',
        debtorButton: 'Должник',
      };
    },
    ...mapActions('consumer', ['loadConsumers', 'consumersByFilterPaged', 'decreaseAccountBalanceByThousand']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>

</style>
