<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Переобнарядка</div>
          </v-card-title>
          <v-card-text>
            <contractor-autocomplete
              v-if="isBoiler"
              ref="contractor"
              v-model="contractorId"
              :items.sync="contractors"
              :search.sync="contractorName"
              is-required
              title="Подрядчик"
              url="/contractors/find?name="
              url-default-data="/contractors/top20"
            ></contractor-autocomplete>

            <events-autocomplete
              ref="event"
              v-model="eventId"
              :find-ids="eventsFindIds"
              :is-disabled="!contractorId"
              :items.sync="events"
              :search.sync="eventName"
              is-required
              title="Мероприятие"
              url-default-data="/events/contractor/{0}/top20"
              url-search="/events/contractor/{0}/find?name={1}"
            />

            <autocomplete-find-id
              ref="adreska"
              v-model="adreskaId"
              :find-id="contractorId"
              :find-id1="eventId"
              :is-disabled="!contractorId||!eventId"
              :items.sync="adreskaItems"
              :search.sync="adreskaAddress"
              is-required
              is-required-find-id
              is-required-find-id1
              title="Программа оформления"
              url-default-data="/adreska/contractor/{0}/event/{1}/top20?statuses=MOUNTING_IN_PROCESS, MOUNTED, IN_EXPLOITATION"
              url-search="/adreska/contractor/{0}/event/{1}/find?name={2}&statuses=MOUNTING_IN_PROCESS, MOUNTED, IN_EXPLOITATION"
            ></autocomplete-find-id>

            <div v-if="adreska">
              <adreska-history :adreska-id="adreska.id" subselect="INSTALLATION_CONTROL"
                               title="Информация о монтаже"/>
              <text-field
                v-model="adreska.axp.odk.name"
                is-disabled
                title="ОДК"
              ></text-field>

              <yandex-map
                ref="customMap"
                :collection-axp="adreskaFactCollectionFiltered"
                @tapPlacemark="onTapPlacemark"
              />

              <text-field
                :text="axpName"
                is-disabled
                style="margin-top: 12px"
                title="АХП"
              ></text-field>

              <decor-type-autocomplete
                ref="decorType"
                v-model="decorTypeId"
                :find-ids="decorTypeFindIds"
                :is-disabled="!axpId"
                :items.sync="decorTypeItems"
                :search.sync="decorTypeName"
                title="Тип декора"
                url-default-data="/axp/{0}/decor-types/find"
                url-search="/axp/{0}/decor-types/find?name={1}"
              ></decor-type-autocomplete>

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Список элементов
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="elementsHeaders"
                      :items="axpElements"
                      :loading="isLoadingElements"
                      class="elevation-1 mb-4"
                      style="width: 100%"
                    >
                      <template #no-data>
                        <span>Отсутствуют данные</span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <v-row style="margin-top: 12px">
              <v-col>
                <div>
                  <v-label>Комментарий</v-label>
                </div>
                <v-textarea
                  v-model="comment"
                  dense
                  name="input-7-4"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <file-input
              ref="photos"
              v-model="photos"
              :loading="isLoadingPhotos"
              class="mt-2"
              is-required
              title="Выберите фото"
            ></file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>

</template>

<script>
import { BASE_URL } from '@/constants/api';
import AutocompleteFindId from '@/components/Processes/components/AutocompleteFindId.vue';
import FileInput from '@/components/Processes/components/FileInput.vue';
// import { D5_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import DecorTypeAutocomplete from 'components/Processes/components/DecorTypeAutocomplete.vue';
import { fetchAdreskaById } from '@/api/rest/odk/api';

export default {
  name: 'ReEquipment',
  components: {
    AdreskaHistory,
    TextField, YandexMap,
    ContractorAutocomplete,
    AutocompleteFindId,
    FileInput,
    EventsAutocomplete,
    DecorTypeAutocomplete,
  },

  created() {
    if (!this.isBoiler) this.contractorId = this.getAccount.supplier.id;
    this.initAdreska();
  },

  data: () => ({
    progressValue: 0,

    adreska: null,
    contractorId: null,
    contractorName: '',
    contractors: [],
    axpId: null,
    axpName: '',
    adreskaId: null,
    adreskaAddress: '',
    adreskaItems: [],
    adreskaFactId: null,
    eventId: null,
    eventName: '',
    events: [],
    comment: null,
    photos: [],
    isLoadingPhotos: false,
    adreskaFactCollection: [],
    loaderDialog: false,
    decorTypeId: null,
    decorTypeName: '',
    decorTypeItems: [],
    elementsHeaders: [{
      text: 'ID',
      align: 'start',
      sortable: true,
      value: 'id',
    }, {
      text: 'Элемент',
      align: 'start',
      sortable: true,
      value: 'name',
    }, {
      text: 'Eдиница измерения',
      align: 'start',
      sortable: true,
      value: 'unitName',
    }, {
      text: 'Количество на 1 ОДК',
      align: 'start',
      sortable: true,
      value: 'count',
    },
      {
        text: 'Тип декора',
        align: 'start',
        sortable: true,
        value: 'decorTypeName',
      }],
    isLoadingElements: false,
    axpElements: [],
  }),

  computed: {
    isDisabledSaveDialog() {
      const isDisabledContractor = this.contractorId == null;
      const isDisabledDdreska = !this.adreska;
      const isDisabledPhoto = !this.photos.length;

      return (
        isDisabledContractor ||
        isDisabledDdreska ||
        isDisabledPhoto
      );
    },
    firstSelectedAdreskaFact() {
      return this.adreskaFactCollection.find((it) => it.isSelected);
    },
    adreskaFactCollectionFiltered() {
      let filtered = this.adreskaFactCollection;
      if (this.axp) filtered = filtered.filter((it) => it.axp.id === this.axp.id);
      return filtered;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.contractorId,
        },
      ];
    },
    decorTypeFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.axpId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'isBoiler', 'getAccount']),
  },

  watch: {
    adreskaId() {
      if (!this.adreskaId) {
        this.axpId = null;
        this.adreska = null;
        return;
      }
      this.fetchAdreska();
      this.fetchAdreskaFactCollection();

    },
    decorTypeId() {
      this.fetchAxpElements();
    },
    axpId() {
      this.fetchAxpElements();
    },
  },

  methods: {
    async initAdreska() {
      if (!this.$route.params.initAdreskaId) return;
      const adreska = await fetchAdreskaById(this.$route.params.initAdreskaId);
      if (!adreska) {
        await this.setNotification({ message: `Не найдена программа оформления с ID ${this.$route.params.initAdreskaId}` });
        this.onTapBack();
      }
      if (!this.isBoiler && adreska.contractorId !== this.getAccount.supplier.id)  {
        await this.setNotification({ message: 'Вы не можете монтировать по данному адресу' });
        this.onTapBack();
      }

      this.contractors = [{
        id: adreska.contractorId,
        name: adreska.contractorName,
      }];
      this.contractorName = adreska.contractorName;
      this.contractorId = adreska.contractorId;

      this.events = [{
        id: String(adreska.event.id),
        name: adreska.event.name,
      }];
      this.eventName = String(adreska.event.name);
      this.eventId = adreska.event.id;

      this.adreskaItems = [{
        id: adreska.id,
        name: adreska.name,
      }];
      this.adreskaAddress = adreska.name;
      this.adreskaId = adreska.id;
    },
    async fetchAxpElements() {
      if (!this.axpId) return;
      this.isLoadingElements = true;
      this.axpElements = [];
      try {
        let url = `${BASE_URL}/axp/${this.axpId}/elements/default-and-decor-not-archived`;
        if (this.decorTypeId) {
          url += `?decorTypeId=${this.decorTypeId}`;
        }
        const response = await fetch(
          url,
        );
        const data = await response.json();
        this.axpElements = data.map((it) => ({
          id: it.id,
          name: it.name,
          unitName: it.unit.name,
          decorTypeName: it.decorType?.name,
          count: it.quantity,
        }));
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingElements = false;
      }
    },
    async fetchAdreska() {
      const response = await fetch(
        `${BASE_URL}/adreska/${this.adreskaId}`,
      );
      if (response.status === 200) {
        this.adreska = await response.json();
        this.axpName = this.adreska.axp.name;
        this.axpId = this.adreska.axp.id;
      } else {
        this.adreska = null;
      }
    },
    async fetchAdreskaFactCollection() {
      const response = await fetch(
        `${BASE_URL}/adreskafact?adreskaId=${this.adreskaId}`,
      );
      const data = await response.json();
      this.adreskaFactCollection = data.map((el) => ({
          id: el.id,
          locationLatitude: el.locationLatitude,
          locationLongitude: el.locationLongitude,
          statusDismantling: el.statusDismantling,
          isSelected: false,
          countOdk: el.countOdk,
          color: 'blue',
          axpId: el.axp.id,
          axpName: el.axp.name,
          balloonContent:
            {
              title: el.axp.odk.name,
              buttonLabel: el.statusDismantling <= 0 ? 'Выбрать' : null,
              props:
                [
                  {
                    label: 'ID',
                    value: el.id,
                  },
                  {
                    label: 'АХП',
                    value: el.axp.name,
                  },
                  {
                    label: 'Тип декора',
                    value: el.decorType?.name,
                  },
                  {
                    label: 'Дата монтажа',
                    value: formatDateFromOdkTimestamp(el.createDate),
                  },
                ],
            },
        }));
    },
    onTapPlacemark(id) {
      const fact = this.adreskaFactCollection.find((it) => it.id === id && it.statusDismantling <= 0);
      fact.isSelected = !fact.isSelected;
      fact.balloonContent.buttonLabel = fact.isSelected ? 'Отменить' : 'Выбрать';
      this.adreskaFactCollection = this.adreskaFactCollection.map((it) => (it.id === id
        ? fact
        : it));
    },
    async onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        adreskaId: this.adreskaId,
        adreskaFactIds: this.adreskaFactCollection.filter((it) => it.isSelected).map((it) => it.id),
        comment: this.comment,
        photos: mainPhotos,
        newDecorTypeId: this.decorTypeId,
      };

      axios
        .post(`${BASE_URL}/re-equipment`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.progressValue = 0;

      this.contractorId = null;
      this.contractorName = '';
      this.contractors = [];

      this.adreskaId = null;
      this.adreskaAddress = '';
      this.adreskaItems = [];
      this.adreskaFactId = null;
      this.comment = null;

      this.eventId = null;
      this.eventName = '';
      this.events = [];

      this.photos = [];
      this.isLoadingPhotos = false;

      this.loaderDialog = false;

      this.decorTypeId = null;
      this.decorTypeName = '';
      this.decorTypeItems = [];
      this.isLoadingElements = false;
      this.axpElements = [];
    },
    ...
      mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

