<template>
  <Process2/>
</template>

<script>
import Process2 from '../../../components/Processes/Process6/Process1.vue';

export default {
  name: 'Issuance',
  components: {
    Process2,
  },
};
</script>

<style scoped>

</style>
