<template>
  <div class="progress-bar-wrp" :style="{
    border: `3px solid ${setBorderColor}`,
    height: `${height}`,
    width: `${width}`,
  }"
  >
    <div class="progress-bar">

      <div
        v-if="!isHundredPercent"
        class="progress-bar__text-wrp"
        :style="{
          background: primaryColor,
        }"
      >
        <span class="progress-bar__text">
          {{ text }}&nbsp;{{ internalPercent }}&nbsp;%
        </span>
      </div>

      <div
        v-if="isHundredPercent"
        class="progress-bar__text-wrp_complete"
      >
        <span class="progress-bar__text_complete">
          {{ text }}&nbsp;100&nbsp;%
        </span>
      </div>

      <div v-if="!isHundredPercent" class="progress-bar__inner">

        <div class="progress-bar__bar" :style="barStyle">
        </div>

        <div class="progress-bar__spaces">
          <div
            v-for="item in spaceCounter" :key="item"
            class="progress-bar__space"
            :style="spaceStyle"
          >
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    text: {
      type: String,
      default: 'Скачалось',
    },
    percent: {
      type: Number,
      default: 1,
    },
    spaceCounter: {
      type: Number,
      default: 9,
    },
    primaryColor: {
      type: String,
      default: '#2F82DF',
    },
    height: {
      type: String,
      default: '50px',
    },
    width: {
      type: String,
      default: '100%',
    },
    mode: {
      type: String,
      default: 'fake',
      validator: (value) => value === 'real' || value === 'fake',
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    fakeTimeMs: {
      type: Number,
      default: 1000,
    },
  },
  mounted() {
    this.internalPercent = this.percent;

    if (this.mode === 'fake') {
      this.runFake();
    }
  },
  data() {
    return {
      internalPercent: null,
      fakeTimeLeft: null, // minutes,
    };
  },
  computed: {
    spaceStyle() {
      return {
        marginRight: `${Math.round(100 / this.spaceCounter / 2)}%`,
        width: `${Math.round(100 / this.spaceCounter / 2)}%`,
      };
    },
    barStyle() {
      return {
        background: this.primaryColor,
        width: `${this.internalPercent}%`,
      };

    },
    isScew() {
      if (this.internalPercent <= 96) {
        return true;
      }
      return false;
    },
    setBorderColor() {
      if (this.isHundredPercent) {
        return '#37BA21';
      }

      return this.primaryColor;
    },
    isHundredPercent() {
      if (this.internalPercent <= 99) {
        return false;
      }

      return true;
    },
  },
  watch: {
    isComplete() {
      this.runFake();
    },
    fakeTimeLeft() {
      this.$emit('update-fake-time-left', this.fakeTimeLeft);
    },
  },
  methods: {
    runFake() {
      if (!this.isComplete) {
        this.repeat(this.fakeIterate, this.fakeTimeMs);
      } else {
        this.repeat(this.fakeIterate, 25);
      }
    },
    fakeIterate() {
      if (this.internalPercent >= 95 && this.isComplete) {
        this.internalPercent = 100;
        return this.$emit('is-hundred-percent', this.internalPercent);
      }

      if (this.internalPercent >= 95 && !this.isComplete) {
        return;
      }

      this.internalPercent++;
      this.updateFakeTime();
    },
    updateFakeTime() {
      if (this.internalPercent <= 10) {
        this.fakeTimeLeft = 5;
      } else if (this.internalPercent <= 30) {
        this.fakeTimeLeft = 4;
      } else if (this.internalPercent <= 50) {
        this.fakeTimeLeft = 3;
      } else if (this.internalPercent <= 70) {
        this.fakeTimeLeft = 2;
      } else if (this.internalPercent <= 90) {
        this.fakeTimeLeft = 1;
      } else if (this.internalPercent > 91) {
        this.fakeTimeLeft = 1;
      }
    },
    repeat(fn, ms) {
      const f = () => {
        fn();
        setTimeout(() => {
          if (this.internalPercent < 100) {
            f();
          }
        }, ms);
      };
      f();
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  border-radius: 20px;

  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  &-wrp {
    border-radius: 28px;
    display: flex;
    height: 55px;
  }

  &__inner {
    position: relative;
    width: 100%;
  }

  &__bar {
    position: absolute;
    height: 100%;
    max-width: 100%;
    transform: skew(-20deg, 0);
  }

  &__space {
    &s {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
    }

    transform: skew(-20deg, 0);
    height: 100%;
    background-color: white;
  }

  &__text {
    transform: skew(20deg, 0);

    &-wrp {
      // uncomment for make text over progress bar
      // position: absolute;

      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 160px;
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
      color: #FFFFFF;
      transform: skew(-20deg, 0);
      margin-left: -3px;

      &_complete {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #37BA21;
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        color: #FFFFFF;
        width: 100%;
      }
    }
  }

}
</style>
