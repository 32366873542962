<template>
  <power-of-attorney-table :power-of-attorneys="getUserPowerOfAttorneys"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { PowerOfAttorneyTable } from '@/components';

export default {
  components: {
    PowerOfAttorneyTable,
  },
  props: {
    userId: {
      type: String,
      required: false,
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      user: {},
    };
  },
  computed: {
    isScreenWidthLess1000px() {
      return window.matchMedia('(max-width: 1000px)').matches;
    },
    authorization() {
      if (this.userId) {
        if (this.isPlatformWorker) {
          if (this.accountId) {
            return this.user.authorizations?.filter((item) => item.account?.id === this.accountId)[0];
          }

          return this.user.authorizations ? this.user.authorizations[0] : undefined;
        }
        return this.user.authorizations?.filter((item) => item.account?.profile.id === this.getAuthorization.authorization.account.profile.id)[0];
      }
      return this.getAuthorization?.authorization;
    },
    getUserPowerOfAttorneys() {
      if (!this.authorization?.powerOfAttorney) {
        return [];
      }

      return this.authorization.powerOfAttorney;
    },
    ...mapGetters('user', ['getAuthorization', 'getUser', 'isPlatformWorker']),
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      if (this.userId) {
        this.user = await this.getUserById({ id: this.userId });
      } else {
        this.user = this.getUser;
      }
    },
    ...mapActions('user', ['getUserById']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";
</style>
