<template>
  <div>
    <v-table-new
      v-model="transformedActs"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Акты допуска прибора учёта в эксплуатацию"
    />
  </div>
</template>

<script>
import { VTableNew } from 'components';
import { getContractStatusLabelValueList } from 'lib/utils/formatXmlType.js';
import { convertDbFormat } from 'lib/utils/date.js';

export default {
  name: 'ContentBlockActsAuthorizationMeteringDevice',
  components: { VTableNew },
  props: {
    loading: { type: Boolean, required: true },
    acts: { type: Array, required: true },
    actionBlockItems: { type: Array, default: () => [] },
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование документа',
            key: 'title',
            thStyle: {
              maxWidth: '400px',
              whiteSpace: 'nowrap',
            },
            tdStyle: {
              maxWidth: '400px',
            },
            sort: {},
          },
          {
            label: 'Номер документа',
            key: 'documentNumber',
            thStyle: {
              minWidth: '111px',
            },
            sort: {},
            tooltip: {
              maxLength: 5,
              minSreenWidthTooltip: 1024,
            },
            filter: {},
          },
          {
            label: 'Дата заключения',
            key: 'formattedDate',
            thStyle: {
              minWidth: '110px',
            },
            sort: { type: 'date' },
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            filter: { type: 'date' },
          },
          {
            label: 'Сетевая организация',
            key: 'utility',
            sort: {},
            tooltip: {
              maxLength: 7,
              minSreenWidthTooltip: 1024,
            },
            filter: {},
          },
          {
            label: 'Заявитель',
            key: 'consumer',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: {},
          },
          {
            label: 'Статус',
            key: 'status',
            type: 'status',
            sort: {},
            thStyle: {
              whiteSpace: 'nowrap',
            },
            filter: { type: 'select', values: getContractStatusLabelValueList() },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            checkEmpty: true,
          },
        ],
      };
    },
    transformedActs() {
      return this.acts.map((contract) => ({
        title: contract.title,
        document: { documentId: contract.documentId, name: contract.title },
        documentNumber: contract.number,
        formattedDate: convertDbFormat(contract.date),
        utility: contract.sides.find((side) => side.sideName === 'SIDE_FIRST')?.name,
        consumer: contract.sides.find((side) => side.sideName === 'SIDE_SECOND')?.name,
        status: contract.status,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
