/* eslint-disable */
export const TEXT_ELECTRICITY_PURCHASE_CONTRACT_BODY =
  `<ContractBody>\n
            <Chapter number="1" title="ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ТЕКСТЕ НАСТОЯЩЕГО ДОГОВОРА:">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          Абонент – энергосбытовая организация, в том числе гарантирующий поставщик, приобретающая у МЭС
                          электрическую энергию (мощность) на розничном рынке в целях ее продажи Потребителям.
                      </Paragraph>
                      <Paragraph>
                          Аномальное потребление – потребление электрической энергии в следующих случаях:
                      </Paragraph>
                      <Paragraph>
                          а) предоставление потребителем ровного профиля потребления;
                      </Paragraph>
                      <Paragraph>
                          б) спад потребления в часы пик (снижение потребления в часы пик более чем на 10 % по сравнению с
                          остальными часами);
                      </Paragraph>
                      <Paragraph>
                          в) отрицательное потребление;
                      </Paragraph>
                      <Paragraph>
                          г) повторяющийся профиль потребления (каждый день одни и те же значения в сравнении с предыдущим
                          периодом);
                      </Paragraph>
                      <Paragraph>
                          д) предоставление потребителем показаний, идентичных предыдущему периоду (нулевое потребление)
                          (кроме случаев наличия заявления от потребителя или информации о приостановке деятельности
                          юридического лица);
                      </Paragraph>
                      <Paragraph>
                          е) резкое снижение (резкий рост) потребления электрической энергии по сравнению с прошлым
                          периодом
                          (аналогичным периодом прошлого года) (снижение/рост потребления более чем на 50 %), с учетом
                          сезонности работы сельскохозяйственных производителей и потребителей «Садовые товарищества» (в
                          период с ноября по апрель).
                      </Paragraph>
                      <Paragraph>
                          Безучетное потребление электрической энергии (мощности) – потребление электрической энергии
                          (мощности) с нарушением Абонентом (Потребителем) установленного настоящим Договором и
                          действующим
                          законодательством порядка учета, выражающееся во вмешательстве в работу прибора учета,
                          измерительного комплекса, измерительных трансформаторов тока и (или) напряжения, соединенных
                          между
                          собой по установленной схеме вторичными цепями, через которые приборы учета установлены
                          (подключены)
                          (далее – измерительные трансформаторы), системы учета, компонентов интеллектуальной системы
                          учета
                          электрической энергии (мощности) в случаях нарушения целостности (повреждения) прибора учета,
                          измерительного комплекса, измерительных трансформаторов, нарушения (повреждения) пломб и (или)
                          знаков визуального контроля, нанесенных на прибор учета, измерительный комплекс, измерительные
                          трансформаторы, систему учета, компоненты интеллектуальной системы учета электрической энергии
                          (мощности), на приспособления, препятствующие доступу к ним, расположенные до места установки
                          прибора учета электрической энергии (точки измерения прибором учета), когда в соответствии с
                          настоящим Договором и действующим законодательством прибор учета, измерительный комплекс,
                          измерительные трансформаторы, система учета, компоненты интеллектуальной системы учета
                          электрической
                          энергии (мощности) установлены в границах балансовой принадлежности Потребителя и (или) в
                          границах
                          земельного участка, принадлежащего такому Потребителю на праве собственности или ином законном
                          основании, на котором расположены энергопринимающие устройства Потребителя (далее – границы
                          земельного участка) или, если обязанность по обеспечению целостности и сохранности прибора
                          учета,
                          измерительного комплекса, измерительных трансформаторов (системы учета) возложена на Потребителя
                          (Абонента), а также с нарушением указанного порядка, обнаруженным в границах балансовой
                          принадлежности Потребителя подключения энергопринимающих устройств до точки измерения прибором
                          учета
                          или в границах земельного участка потребителя (покупателя) подключения до точки измерения
                          прибором
                          учета энергопринимающих устройств, расположенных в границах этого земельного участка.
                      </Paragraph>
                      <Paragraph>
                          Измерительный комплекс – совокупность приборов учета и измерительных трансформаторов,
                          предназначенных для измерения объемов электрической энергии (мощности) в одной точке поставки.
                      </Paragraph>
                      <Paragraph>
                          Максимальная мощность – наибольшая величина мощности, определенная к одномоментному
                          использованию
                          энергопринимающими устройствами (объектами электросетевого хозяйства) в соответствии с
                          документами о
                          технологическом присоединении и обусловленная составом энергопринимающего оборудования (объектов
                          электросетевого хозяйства) и технологическим процессом Потребителя, в пределах которой сетевая
                          организация принимает на себя обязательства обеспечить передачу электрической энергии,
                          исчисляемая в
                          киловаттах.
                      </Paragraph>
                      <Paragraph>
                          При наличии у Потребителя нескольких энергопринимающих устройств, имеющих между собой
                          электрические
                          связи через принадлежащие Потребителю объекты электросетевого хозяйства, величина максимальной
                          мощности определяется в отношении такой совокупности энергопринимающих устройств.
                      </Paragraph>
                      <Paragraph>
                          Объект – энергопринимающее устройство, либо совокупность энергопринимающих устройств
                          Потребителя,
                          находящихся по единому адресу поставки, относящихся к единой группе, на которые в соответствии с
                          действующим законодательством дифференцируются Потребители электрической энергии, и имеющих
                          единое
                          организационно-хозяйственное назначение.
                      </Paragraph>
                      <Paragraph>
                          Ограничение режима потребления – полное и (или) частичное ограничение режима потребления
                          электрической энергии (мощности) энергопринимающими устройствами Потребителя, в том числе уровня
                          потребления электрической энергии (мощности), осуществляемое в порядке и в случаях, которые
                          определяются Правилами полного и (или) частичного ограничения режима потребления электрической
                          энергии.
                      </Paragraph>
                      <Paragraph>
                          Основные положения функционирования розничных рынков - основные положения функционирования
                          розничных
                          рынков электрической энергии, утвержденные постановлением Правительства Российской Федерации от
                          04.05.2012 № 442.
                      </Paragraph>
                      <Paragraph>
                          Потребитель – лицо, владеющее на законных основаниях энергопринимающими устройствами и
                          приобретающее
                          электрическую энергию (мощность) у Абонента для собственных нужд, а также исполнитель
                          коммунальных
                          услуг, приобретающий электрическую энергию (мощность) у Абонента для ее использования при
                          предоставлении коммунальной услуги по электроснабжению.
                      </Paragraph>
                      <Paragraph>
                          Расчетный период (месяц) – календарный месяц, начало которого определяется с 00.00 первого дня
                          календарного месяца и заканчивается в 24.00 последнего дня этого месяца. Первым расчетным
                          периодом
                          по настоящему Договору является период, начало которого определяется с даты вступления в силу
                          настоящего Договора и заканчивается в 24.00 последнего дня этого месяца.
                      </Paragraph>
                      <Paragraph>
                          Сетевая организация – организация, оказывающая услуги по передаче электрической энергии
                          (мощности) с
                          использованием объектов электросетевого хозяйства, к которым непосредственно или опосредованно
                          присоединены энергопринимающие устройства Потребителя, и которая соответствует утвержденным
                          Правительством Российской Федерации критериям отнесения владельцев объектов электросетевого
                          хозяйства к сетевым организациям.
                      </Paragraph>
                      <Paragraph>
                          Система учета – совокупность приборов учета, измерительных комплексов, связующих и
                          вычислительных
                          компонентов, устройств сбора и передачи данных, программных средств, предназначенная для
                          измерения,
                          хранения, удаленного сбора и передачи показаний приборов учета по одной или нескольким точкам
                          поставки.
                      </Paragraph>
                      <Paragraph>
                          Точка поставки – место исполнения обязательств Сторон по настоящему Договору, а также услуг,
                          оказание которых является неотъемлемой частью процесса поставки электрической энергии,
                          используемое
                          для определения объема взаимных обязательств Сторон, расположенное на границе балансовой
                          принадлежности энергопринимающих устройств Потребителя, определенной в документах о
                          технологическом
                          присоединении, а до составления в установленном порядке документов о технологическом
                          присоединении –
                          в точке присоединения энергопринимающего устройства Потребителя к объектам электросетевого
                          хозяйства
                          смежного субъекта электроэнергетики.
                      </Paragraph>
                      <Paragraph>
                          Транзитные потребители – юридические и физические лица, владеющие на законных основаниях
                          энергопринимающими устройствами, имеющие договоры энергоснабжения (купли-продажи электрической
                          энергии) с МЭС и энергоснабжение которых осуществляется через объекты электросетевого хозяйства
                          Потребителя.
                      </Paragraph>
                      <Paragraph>
                          Транзитный прибор учета – это расчетный прибор учета, указанный в договоре с транзитным
                          потребителем
                          или определяющий объем передаваемой электрической энергии в электрические сети сетевых
                          организаций и
                          являющийся «минусовым» по отношению к расчетному прибору учета Абонента по настоящему Договору.
                      </Paragraph>
                      <Paragraph>
                          Уведомление - уведомления или его направление Абоненту (Потребителю) одним из следующих способов
                          (за
                          исключением случаев, когда для отдельных видов уведомлений прямо предусмотрены иные способы):
                      </Paragraph>
                      <Paragraph>
                          а) заказное почтовое отправление - если Абонентом является гражданин, то уведомление
                          отправляется по
                          адресу регистрации Абонента или месту жительства, или по адресу, который Абонент указал сам в
                          Договоре или, если Абонентом является индивидуальный предприниматель или юридическое лицо, то
                          уведомление отправляется по адресу, указанному в Едином государственном реестре индивидуальных
                          предпринимателей или Едином государственном реестре юридических лиц, или по адресу, указанному
                          Абонентом в Договоре. Уведомления, направленные по указанным адресам, считаются полученными,
                          даже
                          если Абонент фактически не проживает (не находится) по указанному адресу, по истечении
                          контрольных
                          сроков пересылки письменной корреспонденции;
                      </Paragraph>
                      <Paragraph>
                          б) включение текста уведомления в счет на оплату потребленной электрической энергии (мощности),
                          оказанных услуг по передаче электрической энергии и (или) услуг, оказание которых является
                          неотъемлемой частью процесса поставки электрической энергии;
                      </Paragraph>
                      <Paragraph>
                          в) электронная почта - по адресу электронной почты, который указан в Договоре. При этом
                          уведомление
                          считается полученным в момент его направления;
                      </Paragraph>
                      <Paragraph>
                          г) направление короткого текстового сообщения (далее – смс-сообщение) на номер мобильного
                          телефона,
                          указанный в Договоре. При этом уведомление считается полученным в момент его направления;
                      </Paragraph>
                      <Paragraph>
                          д) факсимильная или другие телекоммуникационные каналы связи - при условии наличия возможности
                          достоверно установить, от кого исходило сообщение и кому оно адресовано. При этом уведомление
                          считается полученным в момент его направления.
                      </Paragraph>
                      <Paragraph>
                          Утрата прибора учета - отсутствие результатов измерений и информации о состоянии такого прибора
                          учета по истечении 180 дней с даты последнего снятия показаний с прибора учета, в том числе
                          вследствие двукратного недопуска сетевой организации или МЭС к месту установки прибора учета в
                          целях
                          исполнения возложенных законодательством обязанностей.
                      </Paragraph>
                      <Paragraph>
                          Эксплуатация прибора учета (измерительного комплекса) – выполнение действий, обеспечивающих
                          функционирование прибора учета (измерительного комплекса) в соответствии с его назначением на
                          всей
                          стадии его жизненного цикла со дня допуска его в эксплуатацию до его выхода из строя, утраты или
                          демонтажа, включающих, в том числе осмотры прибора учета (измерительного комплекса), техническое
                          обслуживание (при необходимости), обеспечение своевременной поверки по истечении установленного
                          межповерочного интервала и перепрограммирование.
                      </Paragraph>
                      <Paragraph>
                          Энергопринимающие устройства – находящиеся у Потребителя аппараты, агрегаты, механизмы,
                          устройства и
                          иное оборудование (или их комплекс), предназначенные для преобразования электрической энергии в
                          другой вид энергии в целях использования (потребления) и имеющие между собой электрические
                          связи.
                      </Paragraph>
                      <Paragraph>
                          Иные понятия, используемые в настоящем Договоре, имеют значения, определенные действующим
                          законодательством.
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="2" title="ПРЕДМЕТ ДОГОВОРА">
              <Point number="1">
                  <Point number="1">
                      <Text>
                          <Paragraph>
                              В соответствии с настоящим Договором МЭС обязуется осуществлять продажу Абоненту
                              электрической энергии (мощности) и урегулировать отношения по оказанию услуг, являющихся
                              неотъемлемой частью процесса энергоснабжения Потребителей (за исключением услуг по передаче
                              электрической энергии, кроме случаев, отдельно оговоренных в настоящем Договоре), а Абонент
                              обязуется оплачивать поставленную электрическую энергию (мощность) и оказанные услуги в
                              порядке, установленном Договором.
                          </Paragraph>
                          <Paragraph>
                              Абонент приобретает электрическую энергию (мощность) по настоящему Договору в целях ее
                              продажи Потребителям. Перечень Потребителей и адресов поставки приведен в Приложении № 1 к
                              настоящему Договору.
                          </Paragraph>
                          <Paragraph>
                              В настоящем Договоре не регулируются отношения по передаче электрической энергии Абоненту.
                              Абонент самостоятельно урегулирует отношения по передаче электрической энергии, а также, в
                              случаях, установленных законодательством, и отношения по оперативно-диспетчерскому
                              управлению.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>
                              Объем взаимных обязательств по Договору определяется в точках поставки, которые находятся на
                              границе балансовой принадлежности энергопринимающих устройств (объектов электроэнергетики)
                              Потребителя, определенной в документах о технологическом присоединении, являющихся
                              неотъемлемой частью настоящего Договора.
                          </Paragraph>
                          <Paragraph>
                              При отсутствии на дату заключения настоящего Договора составленных в установленном порядке
                              документов о технологическом присоединении до их составления точки поставки определяются в
                              точках присоединения энергопринимающих устройств (объектов электроэнергетики) Потребителя к
                              объектам электросетевого хозяйства смежного субъекта электроэнергетики.
                          </Paragraph>
                          <Paragraph>
                              Энергопринимающие устройства Потребителя____________________ расположены по адресу (адрес
                              поставки):____________________________________________________________________________________
                              ____________________________________________________________________________________________.
                          </Paragraph>
                          <Paragraph>
                              При наличии нескольких адресов поставки Потребителя данные адреса указываются в Приложении №
                              1 к Договору. В этом случае по каждому адресу поставки каждого Потребителя Сторонами
                              составляются Приложения №2 к Договору.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              Качество электрической энергии, приобретаемой МЭС в целях исполнения обязательств по
                              настоящему Договору, должно соответствовать требованиям технических регламентов.
                          </Paragraph>
                          <Paragraph>
                              В случае отклонения показателей качества электрической энергии от норм, установленных
                              регламентирующими документами, указанными в предыдущем абзаце, МЭС совместно с Абонентом,
                              сетевой организацией и Потребителем в десятидневный срок с момента требования МЭС, сетевой
                              организации или Абонента организовывают проверку и анализ показателей качества электрической
                              энергии с оформлением результатов в соответствующем акте (протоколе измерений). Абонент
                              обеспечивает участие Потребителя в указанной проверке.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                          Стороны определили следующие существенные условия настоящего Договора в отношении каждого
                          потребителя, интересы которого представляет Заказчик:
                      </Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>
                              Акт разграничения балансовой принадлежности электросетей и эксплуатационной ответственности
                              сторон, который фиксирует точки присоединения энергопринимающих устройств Потребителя к
                              объектам электросетевого хозяйства Исполнителя и/или ТСО и границы ответственности между
                              Потребителем и Исполнителем и/или ТСО за состояние и обслуживание объектов электросетевого
                              хозяйства (в случае опосредованного присоединения энергопринимающих устройств Потребителя
                              приводится акт, фиксирующий точки поставки электроэнергии Потребителю) (Приложение № 7 к
                              настоящему Договору);
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
          </Chapter>
          <Chapter number="3" title="ОБЯЗАННОСТИ И ПРАВА МЭС">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          МЭС ОБЯЗУЕТСЯ:
                      </Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>
                              Осуществлять продажу Абоненту электрической энергии (мощности) в объеме и порядке,
                              установленными настоящим Договором, при условии заключения Абонентом (Потребителем) договора
                              оказания услуг по передаче электрической энергии до точек поставки, согласованных в
                              настоящем Договоре.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>
                              В случае если Абонент передает принятую от МЭС электрическую энергию потребителям,
                              приравненным в соответствии с нормативными правовыми актами в области государственного
                              регулирования цен (тарифов) к категории «Население» или гражданам-потребителям (далее –
                              население), урегулировать с сетевой организацией отношения, связанные с передачей
                              электрической энергии в объеме, передаваемом Абонентом населению.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              Производить по требованию Абонента (но не реже одного раза в год) сверку расчетов с
                              оформлением актов сверки по форме, установленной МЭС.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="4">
                      <Text>
                          <Paragraph>
                              Принимать на обслуживание Потребителей, в целях энергоснабжения которых заключен настоящий
                              Договор, в порядке и случаях, предусмотренных настоящим Договором и действующим
                              законодательством.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="5">
                      <Text>
                          <Paragraph>
                              Разместить на официальном сайте МЭС сети «Интернет» (www.mosenergosbyt.ru) информацию о
                              выделенных оператором подвижной радиотелефонной связи абонентских номерах и (или) об адресах
                              электронной почты, предназначенных для направления Абоненту уведомлений о введении
                              ограничения режима потребления электрической энергии (мощности).
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="6">
                      <Text>
                          <Paragraph>
                              Исполнять иные обязанности, предусмотренные настоящим Договором.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>МЭС ИМЕЕТ ПРАВО:</Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>Инициировать ограничение режима потребления электрической энергии (мощности),
                              поставляемой Абоненту по настоящему Договору, по основаниям и в порядке, предусмотренным
                              действующим законодательством об электроэнергетике, с учетом величин аварийной и (или)
                              технологической брони, а также определенной в процессе технологического присоединения
                              категории надежности энергоснабжения Потребителя.
                          </Paragraph>
                          <Paragraph>Порядок введения ограничения режима потребления электрической энергии (мощности), а
                              также особенности введения ограничения режима потребления в отношении Потребителя или его
                              отдельных объектов, для которых установлены величины аварийной (технологической) брони и
                              (или) ограничение режима потребления электрической энергии которых может привести к
                              экономическим, экологическим или социальным последствиям, устанавливается Правилами полного
                              и (или) частичного ограничения режима потребления электрической энергии.
                          </Paragraph>
                          <Paragraph>Сведения о применении в отношении отдельных энергопринимающих устройств Потребителя
                              специального порядка ограничения, связанного с установлением величины аварийной
                              (технологической) брони и (или) отнесением Потребителя к категории субъектов, ограничение
                              режима потребления электрической энергии (мощности) которых может привести к экономическим,
                              экологическим или социальным последствиям, указываются в Приложении №2 к настоящему
                              Договору.
                          </Paragraph>
                          <Paragraph>В установленных законодательством случаях ограничение режима потребления может
                              вводиться по инициативе иных субъектов электроэнергетики.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>В случаях и порядке, предусмотренных законодательством, в одностороннем порядке
                              отказаться от исполнения обязательств по настоящему Договору полностью при условии
                              уведомления об этом Абонента за 10 (десять) рабочих дней до планируемой даты прекращения
                              действия настоящего Договора.
                          </Paragraph>
                          <Paragraph>При этом для обеспечения бесперебойного энергоснабжения Потребителей, в интересах
                              которых заключен настоящий Договор, МЭС обеспечивает принятие указанных Потребителей на
                              обслуживание, организованное в установленном действующим законодательством порядке.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              Беспрепятственного доступа к электрическим установкам и приборам учета (измерительным
                              комплексам, системам учета), а также к необходимой технической, оперативной и иной
                              документации, связанной с энергоснабжением Потребителя, для:
                          </Paragraph>
                          <Paragraph>
                              а) участия в проведении мероприятий по допуску установленного прибора учета в эксплуатацию,
                              а также осмотру прибора учета и снятию его показаний при демонтаже – в рабочее время суток
                              Потребителя;
                          </Paragraph>
                          <Paragraph>
                              б) проведения работ, связанных с установкой, эксплуатацией, перепрограммированием и заменой
                              приборов учета (измерительных комплексов, систем учета), осуществляемых МЭС на возмездной
                              основе по заявке Абонента (Потребителя) – в рабочее время суток Потребителя;
                          </Paragraph>
                          <Paragraph>
                              в) обеспечения эксплуатации приборов учета, а также поверки измерительных трансформаторов
                              (если прибор учета входит в состав измерительного комплекса или системы учета),
                              принадлежащих МЭС и находящихся в границах объектов электросетевого хозяйства Потребителя –
                              в рабочее время суток Потребителя;
                          </Paragraph>
                          <Paragraph>
                              г) составления (пересмотра) «Реестра источников энергоснабжения, энергопринимающего
                              оборудования и средств учета электроэнергии и мощности» (Приложение № 2 к настоящему
                              Договору) – в рабочее время суток Потребителя;
                          </Paragraph>
                          <Paragraph>
                              д) контроля за соблюдением установленных режимов энергопотребления, снятия (контрольного
                              снятия) показаний приборов учета (в том числе путем опроса системы учета), проверки приборов
                              учета, измерительных комплексов, систем учета, в том числе условий их эксплуатации и
                              сохранности, с составлением соответствующего акта по установленной форме – в рабочее время
                              суток Потребителя;
                          </Paragraph>
                          <Paragraph>
                              е) проведения проверок (замеров), измерений с целью определения качества электрической
                              энергии. Указанные замеры организовывает МЭС с установкой приборов для измерений показателей
                              качества электрической энергии;
                          </Paragraph>
                          <Paragraph>
                              ж) осуществления мероприятий для проверок надежности энергоснабжения Потребителя и лиц,
                              владеющих на законных основаниях энергопринимающими устройствами и имеющих договоры с МЭС,
                              энергоснабжение которых осуществляется через объекты электросетевого хозяйства Потребителя,
                              в том числе для выявления наличия (отсутствия) препятствий перетоку электрической энергии
                              указанным лицам.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="4">
                      <Text>
                          <Paragraph>
                              Инициировать внеплановые проверки приборов учета (измерительных комплексов, систем учета),
                              используемых для определения объемов поставленной по Договору электрической энергии
                              (мощности), не включенных в интеллектуальную систему учета МЭС или сетевой организации, в
                              случае непредставления Абонентом показаний данных приборов учета более 2 (двух) расчетных
                              периодов подряд.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="5">
                      <Text>
                          <Paragraph>
                              При отсутствии у Абонента документов, подтверждающих сведения о величине максимальной
                              мощности энергопринимающих устройств Потребителя, использовать имеющиеся у МЭС сведения о
                              величине максимальной мощности энергопринимающих устройств Потребителя при условии, что
                              Потребитель ранее имел договорные отношения с МЭС.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="6">
                      <Text>
                          <Paragraph>
                              Начислять Абоненту в случае неисполнения или ненадлежащего исполнения Абонентом обязательств
                              по оплате электрической энергии (мощности) и услуг, оказание которых является неотъемлемой
                              частью процесса снабжения электрической энергией (мощностью), проценты за пользование чужими
                              денежными средствами, начиная со дня, следующего после дня истечения установленного
                              настоящим Договором срока исполнения обязательства, по день фактической уплаты долга, размер
                              которых определяется в соответствии с требованиями действующего законодательства Российской
                              Федерации (ст. 395 Гражданского кодекса Российской Федерации), или неустойку, размер которой
                              определяется в соответствии с абз. 1 п. 5.6 настоящего Договора.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="7">
                      <Text>
                          <Paragraph>
                              Изменить номер Договора при условии письменного уведомления Абонента способом, позволяющим
                              подтвердить получение Абонентом указанного уведомления.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
          </Chapter>
          <Chapter number="4" title="ОБЯЗАННОСТИ И ПРАВА АБОНЕНТА">
              <Point number="1">
                  <Text>
                      <Paragraph>АБОНЕНТ ОБЯЗУЕТСЯ:</Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>
                              Урегулировать отношения, связанные с передачей электрической энергии с даты вступления в
                              силу
                              настоящего Договора до точек поставки по настоящему Договору, в том числе оплачивать
                              соответствующие услуги сетевой организации.
                          </Paragraph>
                          <Paragraph>
                              Отношения, связанные с передачей электрической энергии, урегулируются Абонентом в части
                              объема
                              поставляемой по настоящему Договору электрической энергии (мощности) за вычетом объема
                              электрической энергии (мощности), передаваемой Абонентом населению.
                          </Paragraph>
                          <Paragraph>
                              В случае если Абонент передает принятую от МЭС электрическую энергию (мощность) населению,
                              отношения по оказанию услуг по передаче электрической энергии, передаваемой Абонентом
                              населению,
                              урегулирует МЭС в рамках соответствующего договора с сетевой организацией. При этом в
                              составе
                              стоимости электрической энергии (мощности) в объеме, переданном Абонентом населению, Абонент
                              оплачивает МЭС стоимость услуг по передаче электрической энергии.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>
                              Надлежащим образом производить оплату поставляемой электрической энергии (мощности) и услуг,
                              оказание которых является неотъемлемой частью процесса снабжения электрической энергией
                              (мощностью), с соблюдением сроков, размера и порядка оплаты, установленных настоящим
                              Договором и действующим законодательством.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              Уведомлять МЭС о выходе из строя (утрате или неисправности), истечении срока межповерочного
                              интервала, нарушении защитных и пломбирующих компонентов приборов учета (измерительных
                              комплексов, систем учета), принадлежащих Потребителю и (или) находящихся в границах
                              балансовой принадлежности объектов электросетевого хозяйства Потребителя, а также об иных
                              изменениях (нарушениях), происшедших в схеме энергоснабжения Потребителя, - не позднее
                              одного календарного дня, следующего за днем, когда Абонент узнал или должен был узнать о
                              наступлении указанных обстоятельств.
                          </Paragraph>
                          <Paragraph>
                              В случае выявления МЭС признаков аномального потребления электрической энергии Потребителем,
                              МЭС направляет Абоненту уведомление о данном факте на адрес электронной почты, указанный в
                              настоящем Договоре. После получения указанного уведомления Абонент обязан обеспечить
                              осуществление фотосъемки показаний прибора учета, в отношении которого выявлено аномальное
                              потребление электрической энергии, и направить данные фотоматериалы с использованием
                              электронной почты на следующий адрес: _______________@___________.___.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="4">
                      <Text>
                          <Paragraph>
                              Обеспечивать поддержание Потребителями на границах балансовой принадлежности электрических
                              сетей со смежными субъектами показателей качества электрической энергии в соответствии с
                              техническими регламентами.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="5">
                      <Text>
                          <Paragraph>
                              Присоединение энергопринимающих устройств Потребителей к сети, приводящее к увеличению
                              потребляемой мощности свыше максимальной, производить только в установленном
                              законодательством порядке.
                          </Paragraph>
                          <Paragraph>
                              Уведомлять МЭС о факте подачи Потребителем заявки на технологическое присоединение в сетевую
                              организацию в случаях:
                          </Paragraph>
                          <Paragraph>
                              а) реконструкции энергопринимающих устройств, влекущей изменение максимальной мощности;
                          </Paragraph>
                          <Paragraph>
                              б) изменения категории надежности, точек присоединения, вида производственной деятельности;
                          </Paragraph>
                          <Paragraph>
                              в) введения новых объектов.
                          </Paragraph>
                          <Paragraph>
                              В течение 3 (трех) дней с даты подписания с сетевой организацией предоставлять в МЭС
                              оформленные (переоформленные) документы о технологическом присоединении. После поступления
                              от Абонента указанных документов Стороны обязаны внести соответствующие изменения в
                              настоящий Договор.
                          </Paragraph>
                          <Paragraph>
                              Уведомлять МЭС о присоединении к электрическим сетям Потребителя энергопринимающих устройств
                              иных потребителей (Субабонентов) с предоставлением документов, подтверждающих такое
                              присоединение, в следующие сроки:
                          </Paragraph>
                          <Paragraph>
                              - в течение 10 рабочих дней с момента заключения Договора в отношении потребителей,
                              присоединение энергопринимающих устройств которых к электрическим сетям Потребителя было
                              осуществлено до момента заключения Договора;
                          </Paragraph>
                          <Paragraph>
                              - в течение 5 рабочих дней с момента присоединения энергопринимающих устройств потребителей
                              к электрическим сетям Потребителя, которое было осуществлено после заключения Договора.
                          </Paragraph>
                          <Paragraph>
                              В случае непредоставления документов в порядке и сроки, предусмотренные настоящим пунктом
                              Абонент несет связанные с таким непредоставлением негативные последствия, в том числе:
                          </Paragraph>
                          <Paragraph>
                              - неосуществление со стороны МЭС перерасчета стоимости электрической энергии до момента
                              предоставления указанных в настоящем пункте документов;
                          </Paragraph>
                          <Paragraph>
                              - убытки, связанные с невозможностью взыскания с сетевой организации излишне оплаченных
                              услуг по передаче электрической энергии;
                          </Paragraph>
                          <Paragraph>
                              - иные предусмотренные законодательством и настоящим Договором.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="6">
                      <Text>
                          <Paragraph>
                              В пятидневный срок уведомлять МЭС об изменениях места нахождения, адреса для переписки и
                              (или) электронного адреса (E-mail), банковских реквизитов, наименования, ведомственной
                              принадлежности и (или) формы собственности, реорганизации и других событиях и реквизитов,
                              влияющих на надлежащее исполнение Договора, с представлением соответствующих документов.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="7">
                      <Text>
                          <Paragraph>
                              Обеспечивать надлежащий учёт поставляемой электрической энергии (мощности) в соответствии с
                              разделом 4 настоящего Договора и действующим законодательством.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="8">
                      <Text>
                          <Paragraph>
                              Снять показания расчетных приборов учета по состоянию на 00 часов 00 минут даты начала
                              исполнения МЭС обязательств по настоящему Договору и сообщить их в МЭС не позднее дня,
                              следующего за датой заключения настоящего Договора, посредством телефонной связи,
                              электронной почты или иными способами, позволяющими подтвердить получение указанных
                              показаний МЭС, а также в письменной форме не позднее первого рабочего дня, следующего за
                              датой заключения настоящего Договора.
                          </Paragraph>
                          <Paragraph>
                              Обеспечивать допуск:
                          </Paragraph>
                          <Paragraph>
                              а) представителей МЭС – для осуществления мероприятий, указанных в п.2.2.3 настоящего
                              Договора, не чаще 1 (одного) раза в месяц;
                          </Paragraph>
                          <Paragraph>
                              б) владельца (его представителя) прибора учёта, установленного в границах балансовой
                              принадлежности объектов электросетевого хозяйства Потребителя – для осуществления
                              эксплуатации прибора учета.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="9">
                      <Text>
                          <Paragraph>
                              В случае если настоящий Договор заключен в отношении энергопринимающих устройств
                              Потребителя, ограничение режима потребления электрической энергии (мощности) которого может
                              привести к экономическим, экологическим, социальным последствиям, относящегося к категориям,
                              определенным в приложении к Правилам полного и (или) частичного ограничения режима
                              потребления электрической энергии, либо энергопринимающие устройства которого отнесены к
                              первой категории надежности, в отношении которого при осуществлении технологического
                              присоединения к объектам электросетевого хозяйства не был составлен и согласован с сетевой
                              организацией акт согласования технологической и (или) аварийной брони (далее – Акт брони)
                              либо он нуждается в изменении в связи с наступлением предусмотренных законодательством
                              оснований, Абонент обязан обеспечить составление (изменение) и направление Потребителем на
                              согласование в сетевую организацию Акта брони в течение 30 (тридцати) дней с даты заключения
                              настоящего Договора или в течение 20 (двадцати) дней со дня возникновения оснований для
                              изменения Акта брони соответственно, а также передать в МЭС копию Акта брони не позднее 5
                              (пяти) дней после дня согласования Потребителем с сетевой организацией.
                          </Paragraph>
                          <Paragraph>
                              В случае если Потребитель на момент заключения настоящего Договора имеет Акт брони и такой
                              Акт брони не нуждается в изменении в связи с ненаступлением предусмотренных
                              законодательством оснований для его изменения, Абонент обязан передать в МЭС копию Акта
                              брони не позднее 5 (пяти) дней после дня заключения настоящего Договора.
                          </Paragraph>
                          <Paragraph>
                              дня заключения настоящего Договора.
                              Абонент обеспечивает наличие сведений о Потребителе, относящемся к категории субъектов,
                              ограничение режима потребления которых может привести к экономическим, экологическим или
                              социальным последствиям из числа указанных в Приложении к Правилам полного и (или)
                              частичного ограничения режима потребления электрической энергии, в перечне Абонентов,
                              утверждаемом высшим должностным лицом субъекта Российской Федерации.
                          </Paragraph>
                          <Paragraph>
                              Абонент несет ответственность, в том числе перед третьими лицами, за последствия, вызванные
                              применением ограничения режима потребления к Потребителю, относящемуся к категории
                              субъектов, ограничение режима потребления которых может привести к экономическим,
                              экологическим или социальным последствиям, при отсутствии сведений о нем в перечне,
                              указанном в предыдущем абзаце настоящего пункта.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="10">
                      <Text>
                          <Paragraph>
                              Доводить до сведения Потребителей графики аварийного ограничения режима потребления
                              электрической энергии (мощности), а также обеспечивать выполнение Потребителями требований
                              МЭС и (или) сетевой организации и субъекта оперативно-диспетчерского управления в
                              электроэнергетике по реализации графиков аварийного ограничения.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="11">
                      <Text>
                          <Paragraph>
                              Производить по требованию МЭС (но не реже одного раза в год) сверку расчетов с оформлением
                              актов сверки расчетов по форме, установленной МЭС.
                          </Paragraph>
                          <Paragraph>
                              Акт сверки расчетов подписывается Абонентом в течение 14 (четырнадцати) дней с момента его
                              получения от МЭС. В случае если в течение 14 (четырнадцати) дней с момента получения акта
                              сверки Абонент не подпишет его и не представит МЭС мотивированные возражения по нему, акт
                              считается согласованным Абонентом.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="12">
                      <Text>
                          <Paragraph>
                              В порядке и случаях, предусмотренных действующим законодательством, компенсировать МЭС
                              затраты, связанные с ограничением и восстановлением энергоснабжения, на основании
                              выставленного Абоненту счета.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="13">
                      <Text>
                          <Paragraph>
                              Не препятствовать реализации права Потребителей по переходу на обслуживание к МЭС, а также
                              выполнять предусмотренные действующим законодательством требования при осуществлении
                              мероприятий по принятию Потребителей на обслуживание к МЭС.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="14">
                      <Text>
                          <Paragraph>
                              В порядке и случаях, предусмотренных действующим законодательством об электроэнергетике и
                              настоящим Договором, направлять в МЭС сведения о Потребителях при переходе их на
                              обслуживание к МЭС.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="15">
                      <Text>
                          <Paragraph>
                              Обеспечить осуществление Потребителем, к энергопринимающим устройствам которого присоединены
                              иные потребители, имеющие договоры с МЭС, мероприятий по введению ограничения режима
                              потребления таким потребителям после получения уведомления МЭС в порядке, предусмотренном
                              действующим законодательством.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="16">
                      <Text>
                          <Paragraph>
                              Информировать МЭС об изменении, расторжении, прекращении действия договора оказания услуг по
                              передаче электрической энергии в течение 1 (одного) рабочего дня.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="17">
                      <Text>
                          <Paragraph>
                              Обеспечивать допуск представителей МЭС к установленным на объектах сетевой организации
                              средствам измерения электрической энергии (мощности), используемым для определения объема
                              обязательств по настоящему Договору.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="18">
                      <Text>
                          <Paragraph>
                              Исполнять иные обязанности, предусмотренные настоящим Договором.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>АБОНЕНТ ИМЕЕТ ПРАВО:</Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>
                              Выбора ценовой категории, в том числе предполагающей почасовое планирование объема
                              потребления электрической энергии при соблюдении требований действующего законодательства.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>
                              Требовать поддержания показателей качества электрической энергии в соответствии с
                              техническими регламентами.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              Обращаться в МЭС по вопросам отклонения показателей качества электроэнергии от требований,
                              указанных в п. 3.2.2 Договора, а также по вопросам, связанным с прекращением поставки
                              электрической энергии (мощности) и иным вопросам, связанным с исполнением обязательств
                              Сторон по настоящему Договору.
                          </Paragraph>
                          <Paragraph>
                              Указанные обращения направляются Абонентом в адрес МЭС письменно, посредством телефонной
                              связи, электронной почты или сайта МЭС в сети «Интернет» (www.mosenergosbyt.ru).
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="4">
                      <Text>
                          <Paragraph>
                              В одностороннем порядке уменьшить объемы электрической энергии (мощности), приобретаемой по
                              настоящему Договору, путем приобретения части объемов по договору, заключаемому с
                              производителем электрической энергии (мощности) на розничном рынке, генерирующие объекты
                              которого расположены в установленной уполномоченным органом субъекта Российской Федерации
                              зоне деятельности МЭС в качестве гарантирующего поставщика, на территории которой
                              расположены энергопринимающие устройства Потребителя, при условии выполнения требований,
                              предусмотренных действующим законодательством, с последующим внесением соответствующих
                              изменений в настоящий Договор.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="5">
                      <Text>
                          <Paragraph>
                              В одностороннем порядке отказаться от исполнения настоящего Договора полностью при условии
                              письменного уведомления МЭС об этом не позднее чем за 20 (двадцать) рабочих дней до
                              заявленной даты расторжения, оплаты поставленной электрической энергии (мощности) не позднее
                              чем за 10 (десять) рабочих дней до заявленной даты.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="6">
                      <Text>
                          <Paragraph>
                              В случае утраты МЭС статуса гарантирующего поставщика перейти на обслуживание к организации,
                              которой присвоен статус гарантирующего поставщика, энергосбытовой (энергоснабжающей)
                              организации или производителю электрической энергии (мощности) на розничном рынке при
                              условии соблюдения предусмотренных действующим законодательством условий заключения
                              договоров с указанными субъектами.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="7">
                      <Text>
                          <Paragraph>
                              На возмещение причиненного реального ущерба в случаях перерывов энергоснабжения по вине МЭС,
                              за исключением случаев, когда перерывы в энергоснабжении были введены в соответствии с
                              условиями настоящего Договора и действующего законодательства в сфере электроэнергетики.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="8">
                      <Text>
                          <Paragraph>
                              Осуществлять иные права, предусмотренные настоящим Договором.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
          </Chapter>
          <Chapter number="5"
                   title="ПОРЯДОК УЧЕТА ЭЛЕКТРИЧЕСКОЙ ЭНЕРГИИ (МОЩНОСТИ)">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          Сведения о приборах учёта, установленных в отношении энергопринимающих устройств Потребителей
                          (в том числе места расположения приборов учёта, заводские номера, дата государственной поверки,
                          показания на дату и время начала исполнения МЭС настоящего Договора), указываются в Приложении №
                          2 к настоящему Договору.
                      </Paragraph>
                      <Paragraph>
                          Показания приборов учета (измерительных комплексов, систем учета), указанные в Приложении № 2 к
                          настоящему Договору, считаются снятыми по состоянию на 00 часов 00 минут даты начала исполнения
                          МЭС обязательств по настоящему Договору, если:
                      </Paragraph>
                      <Paragraph>
                          - иные показания приборов учета на дату начала исполнения МЭС обязательств по настоящему
                          Договору не будут представлены Абонентом в соответствии с п. 3.1.8 настоящего Договора, либо
                      </Paragraph>
                      <Paragraph>
                          - иные показания приборов учета на дату начала исполнения МЭС обязательств по настоящему
                          Договору не будут получены МЭС самостоятельно, либо
                      </Paragraph>
                      <Paragraph>
                          - иные показания приборов учета на дату начала исполнения МЭС обязательств по настоящему
                          Договору не будут предоставлены МЭС сетевой организацией.
                      </Paragraph>
                      <Paragraph>
                          Если показания приборов учета на дату начала исполнения МЭС обязательств по настоящему Договору
                          представлены Абонентом в соответствии с п. 3.1.8 настоящего Договора, либо сетевой организацией,
                          либо получены МЭС самостоятельно, в расчетах с Абонентом в качестве начальных применяются
                          показания приборов учета, время и дата снятия которых являются наиболее приближенными к 00 часов
                          00 минут даты начала исполнения обязательств по настоящему Договору. При этом если
                          представленные Абонентом в соответствии с п. 3.1.8 настоящего Договора и (или) сетевой
                          организацией и (или) полученные МЭС самостоятельно показания приборов учета сняты по состоянию
                          на одну и ту же дату, в расчетах с Абонентом в качестве начальных применяются те показания (из
                          указанных выше), которые имеют наибольшее значение.
                      </Paragraph>
                      <Paragraph>
                          В случае заключения настоящего Договора при смене собственника энергопринимающего устройства
                          Потребителя Абонент вправе предоставить показания приборов учета на дату перехода к Потребителю
                          права собственности на энергопринимающее устройство, подписанные предыдущим собственником.
                      </Paragraph>
                      <Paragraph>
                          При отсутствии таких показаний приборов учета в отношении первого расчетного периода со дня
                          указанного перехода права собственности объем потребления электрической энергии определяется
                          исходя из количества дней, истекших со дня последнего снятия показаний приборов учета до
                          указанной даты перехода права собственности, и усредненного объема потребления электрической
                          энергии в день в период между последним и предпоследним снятием показаний приборов учета.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>Если энергопринимающие устройства Потребителей на дату заключения настоящего Договора не
                          оборудованы приборами учёта, а также в случае выхода из строя, утрате, истечении срока
                          эксплуатации или истечении интервала между поверками приборов учета электрической энергии и
                          (или) иного оборудования, которые используются для коммерческого учета электрической энергии
                          (мощности), Абонент обязан обеспечить доступ к месту установки таких приборов представителям
                          сетевой организации, выполняющей мероприятия по вводу в эксплуатацию и демонтажу прибора учета,
                          проверке и снятию показаний, в том числе контрольному снятию показаний..
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="3">
                  <Text>
                      <Paragraph>При получении от сетевой организации запроса на установку (замену) прибора учёта, Абонент
                          (Потребитель) обязан в течение 10 (десяти) рабочих дней со дня получения такого запроса либо
                          подтвердить предложенные дату и время допуска к местам установки приборов учета для совершения
                          действий по установке (замене) и допуску в эксплуатацию приборов учета, либо согласовать иные
                          дату и (или) время.
                      </Paragraph>
                      <Paragraph>При ненаправлении Абонентом (Потребителем) сетевой организации в установленный срок
                          ответа на запрос на установку (замену) прибора учета, при получении ответа об отказе в установке
                          прибора учета или при двукратном недопуске к месту установки прибора учета, но не ранее 4
                          месяцев с момента первого недопуска, прибор учета подлежит установке в ином месте, максимально
                          приближенном к границе балансовой принадлежности, с уведомлением о смене места установки с
                          указанием адреса такого места.
                      </Paragraph>
                      <Paragraph>После двукратного недопуска объем потребления электрической энергии для расчета за
                          потребленную электрическую энергию (мощность) и оказанные услуги по передаче электрической
                          энергии определяется в порядке, установленным п. 182 Основных положений функционирования
                          розничных рынков.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="4">
                  <Text>
                      <Paragraph>Приборы учета, которыми оборудованы энергопринимающие устройства Потребителя, должны быть
                          допущены в эксплуатацию сетевой организацией в порядке, установленном разделом Х Основных
                          положений функционирования розничных рынков.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="5">
                  <Text>
                      <Paragraph>Мероприятия по установке (замене) расположенного в границах балансовой принадлежности
                          Потребителя прибора учета электрической энергии и допуску вновь установленного прибора учета в
                          эксплуатацию выполняются сетевой организацией в срок не позднее 6 (шести) месяцев с даты
                          получения обращения Абонента (Потребителя) об истечении интервала между поверками, срока
                          эксплуатации, а также об утрате, о выходе прибора учета из строя и (или) его неисправности либо
                          с даты выявления истечения срока поверки, срока эксплуатации, неисправности такого прибора учета
                          в ходе проведения его проверки или с даты признания прибора учета утраченным..
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="6">
                  <Text>
                      <Paragraph>В случае если настоящий Договор заключается в отношении энергопринимающих устройств
                          Потребителя до завершения процедуры их технологического присоединения допуск в эксплуатацию
                          прибора учета, установленного в процессе технологического присоединения соответствующих
                          энергопринимающих устройств Потребителя, организует сетевая организация в рамках выполнения
                          мероприятий по их технологическому присоединению.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="7">
                  <Text>
                      <Paragraph>Запрос на установку (замену) прибора учета и (или) приглашение для участия в процедуре
                          допуска прибора учета с указанием времени и места проведения соответствующих
                          организационно-технических мероприятий направляются в адрес Сторон сетевой организацией в
                          порядке, установленном разделом Х Основных положений функционирования розничных рынков.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="8">
                  <Text>
                      <Paragraph>Абонент обязан направить для участия в процедуре допуска прибора учета в эксплуатацию
                          своего уполномоченного представителя.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="9">
                  <Text>
                      <Paragraph>По итогам допуска прибора учета в эксплуатацию сетевая организация обеспечивает установку
                          контрольной одноразовой номерной пломбы и (или) знаков визуального контроля, а также составляет
                          Акт допуска прибора учета в эксплуатацию, подписываемый всеми участниками данной процедуры, с
                          указанием, в том числе, решения о допуске прибора учета в эксплуатацию или об отказе в таком
                          допуске, причин недопуска и конкретных мероприятий для обеспечения допуска.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="10">
                  <Text>
                      <Paragraph>Прибор учета, установленный и допущенный в эксплуатацию сетевой организацией в рамках
                          исполнения обязанностей, указанных согласно п. 136 Основных положений функционирования розничных
                          рынков, становится расчетным прибором учета и используется в расчетах с даты его допуска в
                          эксплуатацию.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="11">
                  <Text>
                      <Paragraph>Основанием для применения показаний установленного прибора учёта является наличие акта
                          допуска прибора учета в эксплуатацию и (или) паспорта-протокола измерительного комплекса.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="12">
                  <Text>
                      <Paragraph>При наличии в отношении точки поставки Абонента приборов учёта, расположенных по обе
                          стороны границы балансовой принадлежности, выбор расчётного прибора учёта осуществляется в
                          соответствии с п. 142 Основных положений функционирования розничных рыков.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="13">
                  <Text>
                      <Paragraph>Расчётные и контрольные приборы учёта указываются в Приложении № 2 к Договору.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="14">
                  <Text>
                      <Paragraph>Абонент обязан обеспечить надлежащую эксплуатацию Потребителем принадлежащих ему приборов
                          учёта, обязанность по эксплуатации прибора учёта, принадлежащего МЭС, сетевой организации или
                          иным лицам несёт соответствующий владелец прибора учёта.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="15">
                  <Text>
                      <Paragraph>Абонент обязан обеспечивать сохранность и целостность расположенных в границах балансовой
                          принадлежности энергопринимающих устройств Потребителя приборов учёта, измерительных комплексов,
                          систем учёта, контроля и управления электропотреблением (включая их технические, программные и
                          иные компоненты), пломб и (или) знаков визуального контроля, а также воздушных и кабельных
                          линий.
                      </Paragraph>
                      <Paragraph>Абонент обязан возместить сетевой организации убытки, причиненные неисполнением или
                          ненадлежащим исполнением Потребителем обязанностей по обеспечению сохранности и целостности
                          установленных сетевой организацией (гарантирующим поставщиком) приборов учета и (или) иного
                          оборудования, которые используются для обеспечения коммерческого учета электрической энергии
                          (мощности).
                      </Paragraph>
                      <Paragraph>Сохранность и целостность приборов учёта, расположенных в границах балансовой
                          принадлежности электросетевого хозяйства сетевой организации, а также целостность пломб и знаков
                          визуального контроля, обеспечивается сетевой организацией.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="16">
                  <Text>
                      <Paragraph>Абонент обязан обеспечить доступ к месту установки прибора учета представителей
                          организаций, уполномоченных на совершение действий по установке, вводу в эксплуатацию и
                          демонтажу прибора учета, проверке и снятию показаний, в том числе контрольному снятию показаний.
                      </Paragraph>
                      <Paragraph>Абонент также обязан обеспечить допуск представителям владельца приборов учёта,
                          установленных в границах энергопринимающих устройств Потребителя, для осуществления их
                          эксплуатации.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="17">
                  <Text>
                      <Paragraph> Абонент обязан обеспечивать снятие Потребителем показаний не присоединенных к
                          интеллектуальной системе учета расчётных приборов учёта, а, а также транзитных (расчетных)
                          приборов учета (используемых для целей определения объема электрической энергии (мощности),
                          передаваемой в сети смежных субъектов электроэнергетики (смежных сетевых организаций и
                          потребителей, имеющих отдельные договоры с МЭС)) и передавать их в МЭС в порядке и сроки,
                          установленные Приложением № 4 к Договору, а также обеспечивать снятие, фиксацию и хранение
                          Потребителем не включенных в интеллектуальную систему учета показаний контрольных приборов учёта
                          (при их наличии) в порядке и сроки, аналогичные снятию показаний расчётных приборов учёта.
                          Показания транзитных (расчетных) приборов учета должны быть согласованы со смежными субъектами
                          электроэнергетики и предоставляться в адрес МЭС с подтверждающими документами.
                      </Paragraph>
                      <Paragraph>Показания контрольных приборов учёта, не присоединенных к интеллектуальной системе учета,
                          если они в соответствии с п. 4.12 настоящего Договора временно не признаны расчётными,
                          предоставляются Абонентом в МЭС или сетевую организацию в течение 2 (двух) рабочих дней с даты
                          получения соответствующего запроса.
                      </Paragraph>
                      <Paragraph>В случае установки приборов учета, в том числе входящих в состав измерительного комплекса
                          или системы учета, в границах балансовой принадлежности электросетевого хозяйства сетевой
                          организации показания приборов учета предоставляются в МЭС сетевой организацией и используются
                          МЭС для осуществления расчетов за электрическую энергию (мощность), поставляемую по настоящему
                          Договору.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="18">
                  <Text>
                      <Paragraph>В случае необходимости демонтажа прибора учёта без его замены, для целей проведения работ
                          по капитальному ремонту или реконструкции объектов Потребителя в местах установки
                          соответствующих приборов учета, Абонент обязан обеспечить направление Потребителем в адрес МЭС и
                          сетевой организации уведомления с указанием даты, времени и причин выполнения работ по демонтажу
                          такого прибора учета, указанная в уведомлении дата не может быть ранее, чем через 7 (семь)
                          рабочих дней с даты направления уведомления.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="19">
                  <Text>
                      <Paragraph>При этом показания демонтируемого прибора учета, а также сведения о его техническом
                          состоянии, подлежат обязательной фиксации в составляемом сетевой организацией акте демонтажа
                          прибора учета, один экземпляр (копия) которого передается представителю Абонента (Потребителя).
                          Прибор учета должен быть установлен не позднее 6 (шести) месяцев с момента составления
                          указанного акта. Объем электрической энергии, потребленной энергопринимающими устройствами
                          Потребителя в соответствующей точке поставки в период с даты демонтажа и до установки прибора
                          учета определяется в соответствии с п. 179 Основных положений функционирования розничных рынков.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="20">
                  <Text>
                      <Paragraph>Абонент, а также Потребитель, являющийся собственником приборов учета и (или) иного
                          оборудования, используемых для обеспечения коммерческого учета электрической энергии (мощности)
                          на розничных рынках, а также являющийся собственником (владельцем) и (или) пользователем
                          объектов, на которых установлены такие приборы учета и (или) иное оборудование, не вправе по
                          своему усмотрению демонтировать приборы учета и (или) иное оборудование, ограничивать к ним
                          доступ, вмешиваться в процесс удаленного сбора, обработки и передачи показаний приборов учета
                          (измерительных трансформаторов), в любой иной форме препятствовать их использованию для
                          обеспечения и осуществления контроля коммерческого учета электрической энергии (мощности), в том
                          числе препятствовать проведению проверок целостности и корректности их работы, использованию для
                          этих целей данных, получаемых с принадлежащих им приборов учета электрической энергии.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="21">
                  <Text>
                      <Paragraph>Мероприятия по эксплуатации прибора учёта, которые производятся Потребителем, могут
                          производиться МЭС на возмездной основе по заявке Абонента или Потребителя, за исключением
                          мероприятий, которые должны осуществляться МЭС на безвозмездной основе в соответствии с
                          действующим законодательством.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="22">
                  <Text>
                      <Paragraph>Стороны договорились, что представителем Абонента по настоящему Договору при проведении
                          проверок и составлении актов является представитель Потребителя, обеспечивший доступ к приборам
                          учета (измерительным комплексам, системам учета) и иному электрооборудованию, находящемуся на
                          объектах Потребителя.
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="6"
                   title="ОБЩИЕ ПОЛОЖЕНИЯ ОБ ОПРЕДЕЛЕНИИ ОБЪЕМА, РАСЧЕТЕ СТОИМОСТИ И ПОРЯДКЕ ОПЛАТЫ ЭЛЕКТРИЧЕСКОЙ ЭНЕРГИИ (МОЩНОСТИ)">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          Фактический объем поставленной электрической энергии (мощности) определяется исходя из показаний
                          приборов учета (в том числе входящих в состав измерительных комплексов, систем учета) либо
                          расчетным способом в порядке, предусмотренном Приложением № 4 к Договору.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                          Сетевая организация и (или) МЭС (в случаях, предусмотренных законодательством) вправе не чаще 1
                          (одного) раза в месяц, выполнять мероприятия по контрольному снятию показаний расчетных приборов
                          учета, не включенных в интеллектуальную систему учета, с составлением по результатам выполнения
                          таких мероприятий акта контрольного снятия показаний по форме, установленной указанными
                          организациями, при условии надлежащего уведомления Абонента (Потребителя) о дате и времени
                          выполнения таких мероприятий не позднее чем за 5 (пять) рабочих дней до планируемой даты их
                          проведения (при необходимости допуска к энергопринимающим устройствам).
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="3">
                  <Text>
                      <Paragraph>
                           Стоимость поставленной электрической энергии (мощности) определяется в соответствии с
                          Приложением № 4 к Договору.
                      </Paragraph>
                      <Paragraph>
                          Стоимость электрической энергии (мощности) по настоящему Договору не включает стоимость услуг по
                          передаче электрической энергии, за исключением стоимости услуг по передаче электрической энергии
                          в объеме, передаваемом Абонентом населению.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="4">
                  <Text>
                      <Paragraph>
                          Получение Абонентом по окончании расчётного периода составленного по форме, установленной МЭС, и
                          подписанного со стороны МЭС Акта приёма-передачи электрической энергии (мощности) в двух
                          экземплярах, а также счета-фактуры (в случае его составления) и счета осуществляется:
                      </Paragraph>
                      <Paragraph>
                          - посредством использования Личного кабинета клиента (далее – ЛКК), являющегося программным
                          модулем, размещенным на сайте МЭС в сети «Интернет» (www.mosenergosbyt.ru), при условии
                          прохождения Абонентом необходимой регистрации и авторизации в ЛКК в соответствии с
                          установленными МЭС правилами, при условии наличия у Абонента необходимых программных и
                          технических средств для применения электронной подписи;
                      </Paragraph>
                      <Paragraph>
                          - в отделении МЭС в сроки получения расчетно-платежных документов, установленные Приложением № 5
                          к настоящему Договору. Факт получения Абонентом расчетно-платежных документов в отделении МЭС
                          удостоверяется подписью Абонента с проставлением даты получения. В случае неполучения Абонентом
                          расчетно-платежных документов датой получения считается дата их выписки МЭС.
                      </Paragraph>
                      <Paragraph>
                          МЭС обязано обеспечить возможность получения Абонентом указанных расчетно-платежных документов в
                          сроки и способами, указанными в предыдущих абзацах настоящего пункта Договора.
                      </Paragraph>
                      <Paragraph>
                          Абонент обязан в течение 5 (пяти) рабочих дней с даты получения Акта приёма-передачи
                          электрической энергии (мощности) возвратить в МЭС один его экземпляр, подписанный со своей
                          стороны.
                      </Paragraph>
                      <Paragraph>
                          В случае если Абонент не возвратит в течение 5 (пяти) рабочих дней с даты получения подписанный
                          со своей стороны вышеуказанный Акт или не представит мотивированные возражения к нему, расчет
                          стоимости поставленной Абоненту электрической энергии (мощности) осуществляется на основании
                          данных, указанных в Акте снятия показаний приборов учёта (Отчёте об отпуске и покупке
                          электрической энергии (мощности)), или иных документов, имеющихся у МЭС.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="5">
                  <Text>
                      <Paragraph>
                          Исполнение денежных обязательств по Договору, в том числе оплата стоимости поставляемой Абоненту
                          электрической энергии (мощности) и услуг, оказание которых является неотъемлемой частью процесса
                          поставки электрической энергии и входит в предмет настоящего Договора, считается произведенным
                          надлежащим образом при условии поступления денежных средств на расчетный счет МЭС, указанный в
                          Договоре, в порядке, в сроки и в размере, установленными Приложением № 5 к настоящему Договору.
                      </Paragraph>
                      <Paragraph>
                          Оплата поставленной электрической энергии (мощности) производится Абонентом с банковского счета
                          Абонента, если иное не предусмотрено действующим законодательством. Абонент имеет право
                          оплачивать стоимость поставленной электрической энергии (мощности) со счета банковской карты.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="6">
                  <Text>
                      <Paragraph>
                          В случае неисполнения или ненадлежащего исполнения Абонентом обязательств по оплате
                          электрической энергии (мощности) и услуг, оказание которых является неотъемлемой частью процесса
                          снабжения электрической энергией (мощностью), в сроки, установленные пп. 2, 3 Приложения № 5 к
                          настоящему Договору, МЭС вправе требовать уплаты неустойки, определенной в размере законной
                          неустойки.
                      </Paragraph>
                      <Paragraph>
                          Абонент обязан оплатить указанную неустойку на основании выставленного МЭС счета не позднее 10
                          (десяти) дней после его выставления.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="7">
                  <Text>
                      <Paragraph>
                          При осуществлении расчетов по настоящему Договору Стороны в платежных документах обязаны
                          указывать:
                      </Paragraph>
                      <Paragraph>
                          - стоимость электрической энергии (мощности);
                      </Paragraph>
                      <Paragraph>
                          - основание платежа;
                      </Paragraph>
                      <Paragraph>
                          - номер и дату Договора;
                      </Paragraph>
                      <Paragraph>
                          - период, за который производится платеж;
                      </Paragraph>
                      <Paragraph>
                          - номер и дату счета-фактуры (в случае его составления) либо счета;
                      </Paragraph>
                      <Paragraph>
                          - сумму НДС (если иное не предусмотрено налоговым законодательством РФ или настоящим Договором).
                      </Paragraph>
                      <Paragraph>
                          В случае если в платежных документах в совокупности не указаны основание платежа, номер и дата
                          заключения Договора, а также номер и дата счета-фактуры (в случае его составления) либо счета,
                          платеж считается произведенным по настоящему Договору только после письменного подтверждения
                          Абонентом указанных реквизитов.
                      </Paragraph>
                      <Paragraph>
                          В случае если в платежных документах указаны только основание платежа или номер и дата Договора,
                          платеж считается произведенным в счет погашения задолженности, возникшей в связи с неисполнением
                          или ненадлежащим исполнением обязательства Абонента по оплате, срок исполнения которого наступил
                          ранее.
                      </Paragraph>
                      <Paragraph>
                          В случае оплаты задолженности, подтвержденной решением суда, Стороны в платежном документе
                          обязаны указывать (в графе «назначении платежа») номер дела, дату вынесения решения суда (серию
                          и номер исполнительного листа).
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="7" title="ОТВЕТСТВЕННОСТЬ СТОРОН">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          МЭС несет ответственность за неисполнение или ненадлежащее исполнение обязательств по настоящему
                          Договору (за соответствие качества электрической энергии, приобретаемой МЭС в целях исполнения
                          обязательств по настоящему Договору, техническим регламентам и иным обязательным требованиям), в
                          том числе за неисполнение или ненадлежащее исполнение обязательств контрагентами МЭС по
                          договорам, заключенным им во исполнение настоящего Договора.
                      </Paragraph>
                      <Paragraph>
                          За неоказание или ненадлежащее оказание услуг по передаче электрической энергии ответственность
                          перед Абонентом несет оказывающая такие услуги сетевая организация.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                          Если в результате действий (бездействия) Абонента и (или) Потребителя, а также аварий в
                          энергопринимающих устройствах Потребителя имел место недоотпуск электрической энергии другим
                          потребителям МЭС, либо причинены убытки таким потребителям, МЭС в соответствии с действующим
                          законодательством имеет право обратного требования (регресса) к Абоненту.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="3">
                  <Text>
                      <Paragraph>
                          Стороны несут ответственность за нарушение (несоблюдение) предусмотренного законодательством и
                          настоящим Договором порядка введения ограничения режима потребления в порядке и размере,
                          установленными действующим законодательством Российской Федерации.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="4">
                  <Text>
                      <Paragraph>
                          МЭС не несет ответственности за нарушение энергоснабжения энергопринимающих устройств
                          Потребителей при включении автоматических устройств по восстановлению энергоснабжения, либо при
                          включении резервного питания, а также при аварийных снижениях (посадках) напряжения ниже
                          гарантируемого, связанных с работой противоаварийной автоматики и релейной защиты.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="5">
                  <Text>
                      <Paragraph>
                          МЭС не несет ответственности за ограничение режима потребления:
                      </Paragraph>
                      <Paragraph>
                          - В случае возникновения внерегламентных отключений, являющихся следствием повреждения линий
                          электропередачи и (или) оборудования, в том числе в результате стихийных явлений. В данных
                          случаях ограничение вводится по инициативе сетевых организаций или владельцев генерирующего
                          оборудования.
                      </Paragraph>
                      <Paragraph>
                          - В случае проведения плановых работ по ремонту объектов электросетевого хозяйства сетевой
                          организации.
                      </Paragraph>
                      <Paragraph>
                          - В случаях введения ограничения режима потребления электрической энергии (мощности) по
                          инициативе иных субъектов электроэнергетики.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="6">
                  <Text>
                      <Paragraph>
                           Абонент несет ответственность за убытки, причиненные МЭС, сетевой организации, а также иным
                          субъектам электроэнергетики и третьим лицам в случае неисполнения (ненадлежащего исполнения)
                          Потребителем:
                      </Paragraph>
                      <Paragraph>
                          - предусмотренного действующим законодательством порядка ограничения, в том числе в случае
                          применения ограничения к Потребителю, относящемуся к числу субъектов, указанных в п. 3.1.9
                          Договора, у которого отсутствует Акт брони или сведения о котором отсутствуют в перечне
                          Абонентов, утверждаемом высшим должностным лицом субъекта Российской Федерации;
                      </Paragraph>
                      <Paragraph>
                          - графиков аварийного ограничения режима потребления электрической энергии (мощности),
                          доведенных до сведения Абонента в установленном настоящим Договором и действующим
                          законодательством порядке;
                      </Paragraph>
                      <Paragraph>
                          - иных предусмотренных настоящим Договором обязательств, обеспечение исполнения которых
                          Потребителем закреплено за Абонентом.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="7">
                  <Text>
                      <Paragraph>
                          При несоблюдении Абонентом условий одностороннего уменьшения приобретаемых объемов электрической
                          энергии (мощности) или одностороннего отказа от исполнения Договора в соответствии с п.п. 3.2.5,
                          3.2.6 настоящего Договора, Абонент продолжает нести обязательства по настоящему Договору в
                          полном объеме.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="8">
                  <Text>
                      <Paragraph>
                          МЭС не несет ответственность за ненадлежащее предоставление и качество услуг по передаче
                          электрической энергии, в том числе за несоответствие показателей качества электрической энергии
                          требованиям технических регламентов и иным обязательным требованиям, и не участвует в
                          отношениях, связанных с оплатой указанных услуг (кроме случаев, отдельно оговоренных в настоящем
                          Договоре).
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="9">
                  <Text>
                      <Paragraph>
                          МЭС не несет ответственности перед Абонентом за недоотпуск электрической энергии (мощности),
                          вызванный:
                      </Paragraph>
                      <Paragraph>
                          - стихийными явлениями (пожарами, наводнениями, грозой, гололедом, бурей, снежными заносами и
                          т.п.), то есть такими явлениями, воздействие которых происходит извне и непредотвратимо;
                      </Paragraph>
                      <Paragraph>
                          - неправильными действиями персонала Абонента, сетевой организации или посторонних лиц, в том
                          числе связанными с условиями ограничения режима потребления Абонента.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="10">
                  <Text>
                      <Paragraph>
                           Наличие оснований и размер ответственности Сторон определяются в соответствии с настоящим
                          Договором, а также гражданским законодательством и законодательством об электроэнергетике.
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="8" title="ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          Настоящий Договор считается заключенным и вступает в силу с даты получения стороной, направившей
                          оферту, её акцепта.
                      </Paragraph>
                  </Text>
                  <Point number="1">
                      <Text>
                          <Paragraph>Исполнение обязательств по настоящему Договору начинается с 00 часов 00 минут даты
                              его заключения, но не ранее даты, с которой у Абонента возникает обязанность по продаже
                              электрической энергии Потребителю, а также не ранее заключения договора оказания услуг по
                              передаче электрической энергии, продажу которой обеспечивает настоящий Договор, если иное не
                              предусмотрено настоящим Договором или соглашением Сторон. Представитель МЭС, действующий в
                              силу полномочий, основанных на доверенности, вправе установить, что условия настоящего
                              Договора применяются к отношениям сторон, возникшим до его заключения, только если такое
                              полномочие (право) представителя специально оговорено в соответствующей доверенности.
                          </Paragraph>
                          <Paragraph>В случае если на момент заключения настоящего Договора Абонентом не урегулированы
                              отношения по передаче электрической энергии, Абонент обязан в течение трех рабочих дней с
                              даты заключения с сетевой организацией договора оказания услуг по передаче электрической
                              энергии, продажу которой обеспечивает настоящий Договор, в письменной форме уведомить МЭС о
                              дате заключения такого договора оказания услуг по передаче электрической энергии с
                              приложением заверенной копии указанного договора или выписки из него, содержащей сведения о
                              сроках начала и окончания оказания услуг по передаче электрической энергии по каждой точке
                              поставки, указанной в настоящем Договоре, а также содержащей сведения о величине
                              максимальной мощности энергопринимающих устройств Потребителя с распределением указанной
                              величины мощности по каждой точке поставки.  Исходя из представленной информации
                              определяется дата и время начала исполнения Сторонами обязательств, возникших из настоящего
                              Договора.
                          </Paragraph>
                          <Paragraph>В случае если настоящий Договор заключается при смене собственника энергопринимающего
                              устройства, исполнение Сторонами обязательств по настоящему Договору осуществляется с даты
                              возникновения у Потребителя (нового собственника) права собственности на энергопринимающее
                              устройство при условии соблюдения Абонентом тридцатидневного срока для направления в МЭС
                              заявления о заключении договора энергоснабжения в отношении соответствующего
                              энергопринимающего устройства, если иное не определено соглашением между предыдущим и новым
                              собственником энергопринимающего устройства.
                          </Paragraph>
                          <Paragraph>а) с даты фактической подачи сетевой организацией напряжения и мощности на объекты
                              Потребителя, соответствующей дате фактического присоединения, указанной в акте об
                              осуществлении технологического присоединения;
                          </Paragraph>
                          <Paragraph>б) со дня составления и размещения сетевой организацией на своем официальном сайте в
                              личном кабинете Абонента акта об осуществлении технологического присоединения, подписанного
                              со стороны сетевой организации, если технологическое присоединение энергопринимающих
                              устройств Потребителя осуществляется по второй или третьей категории надежности
                              энергопринимающих устройств, максимальная мощность которых составляет до 150 кВт
                              включительно (с учетом ранее присоединенных в данной точке присоединения энергопринимающих
                              устройств).
                          </Paragraph>
                          <Paragraph>В случае если настоящий Договор заключается в отношении энергопринимающих устройств
                              (объектов) Потребителя, указанных в Приложении № 2 к Договору, в отношении которых сетевой
                              организацией введено полное и (или) частичное ограничение режима потребления электрической
                              энергии (мощности), в том числе в связи с неисполнением или ненадлежащим исполнением
                              Абонентом или Потребителем (действовавшим в его интересах лицом) обязательств по оплате
                              электрической энергии (мощности), услуг по передаче электрической энергии и (или) услуг,
                              оказание которых является неотъемлемой частью процесса снабжения электрической энергией, по
                              предыдущему Договору с Абонентом или Потребителем, исполнение МЭС обязательств по настоящему
                              Договору начинается не ранее даты и времени отмены указанного ограничения режима потребления
                              в связи с устранением обстоятельств, явившихся основанием для введения указанного
                              ограничения режима потребления электрической энергии (мощности).
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="2">
                      <Text>
                          <Paragraph>
                              Настоящий Договор действует до 31 декабря текущего календарного года, в котором настоящий
                              Договор вступил в силу, если иная дата окончания срока действия настоящего Договора не
                              предусмотрена соглашением сторон. Настоящий Договор ежегодно пролонгируется на следующий
                              календарный год, если за 30 (тридцать) календарных дней до окончания срока его действия
                              Абонент письменно не заявит МЭС о его прекращении или изменении, либо о заключении Договора
                              на иных условиях.
                          </Paragraph>
                      </Text>
                  </Point>
                  <Point number="3">
                      <Text>
                          <Paragraph>
                              В случае если настоящий Договор заключается в отношении энергопринимающих устройств до
                              завершения процедуры их технологического присоединения Приложение № 2 «Реестр источников
                              энергоснабжения, энергопринимающего оборудования и средств учета электроэнергии и мощности»
                              оформляется МЭС и подписывается Сторонами в течение 30 (тридцати) дней с момента получения
                              МЭС документов, подтверждающих осуществление надлежащего технологического присоединения.
                          </Paragraph>
                          <Paragraph>
                              До подписания Сторонами Приложения № 2 к Договору необходимые для исполнения настоящего
                              Договора сведения определяются исходя из предоставляемых сетевой организацией и (или)
                              Абонентом документов о технологическом присоединении.
                          </Paragraph>
                          <Paragraph>
                              При этом копия Акта согласования технологической и (или) аварийной брони электроснабжения
                              Потребителя предоставляется в МЭС сетевой организацией в течение 2 (двух) рабочих дней с
                              даты подписания, после чего он становится неотъемлемой частью настоящего Договора. Указанный
                              документ может быть также представлен Абонентом.
                          </Paragraph>
                      </Text>
                  </Point>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                           Настоящий Договор прекращает свое действие в случаях и в порядке, предусмотренных настоящим
                          Договором и действующим законодательством Российской Федерации.
                      </Paragraph>
                      <Paragraph>
                          Уведомление о прекращении действия настоящего Договора направляется Стороне в сроки,
                          предусмотренные настоящим Договором, по адресу для переписки, указанному в настоящем Договоре.
                      </Paragraph>
                      <Paragraph>
                          Сроки уведомления о прекращении действия Договора при невозможности исполнения определяются
                          Стороной, исходя из существа обстановки.
                      </Paragraph>
                      <Paragraph>
                          Уведомление, направленное Стороне по адресу для переписки, указанному в настоящем Договоре, и не
                          врученное по причине ее отсутствия по указанному адресу или иной причине, считается полученным
                          последней.
                      </Paragraph>
                      <Paragraph>
                          Настоящий Договор прекращает свое действие в случае принятия МЭС на обслуживание Потребителей
                          Абонента. При этом прекращение поставки электрической энергии (мощности) не производится.
                      </Paragraph>
                      <Paragraph>
                          В случае заключения Потребителем Абонента договора энергоснабжения (купли-продажи электрической
                          энергии (мощности)) с МЭС настоящий Договор прекращает свое действие в отношении данного
                          Потребителя.
                      </Paragraph>
                      <Paragraph>
                          В случае заключения всеми Потребителями Абонента договоров энергоснабжения (купли-продажи
                          электрической энергии (мощности)) с МЭС настоящий Договор прекращает свое действие полностью.
                      </Paragraph>
                      <Paragraph>
                          В случае если Абонентом внесено предложение об изменении настоящего Договора или о заключении
                          между Сторонами в отношении тех же точек поставки нового Договора, отношения Сторон до изменения
                          Договора или до заключения нового Договора регулируются в соответствии с условиями настоящего
                          Договора.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="3">
                  <Text>
                      <Paragraph>
                          Изменение, расторжение или прекращение действия настоящего Договора не освобождает стороны от
                          взаимных расчетов за поставленную электрическую энергию (мощность).
                      </Paragraph>
                      <Paragraph>
                          Для осуществления окончательного расчета за поставленную по настоящему Договору электрическую
                          энергию (мощность) Абонент обязан предоставить в МЭС показания приборов учета, не включенных в
                          интеллектуальную систему учета, на дату расторжения (прекращения действия) или изменения
                          настоящего Договора.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="4">
                  <Text>
                      <Paragraph>
                          Все приложения, дополнения и изменения условий настоящего Договора имеют силу в том случае, если
                          они оформлены в письменной форме и подписаны уполномоченными лицами МЭС и Абонента, если иное не
                          установлено настоящим Договором.
                      </Paragraph>
                      <Paragraph>
                          В случае изменения у какой-либо из Сторон настоящего Договора адреса, наименования, банковских
                          реквизитов, телефонов, телефаксов она обязана в течение 5 (пяти) рабочих дней в письменной форме
                          известить об этом другую Сторону.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="5">
                  <Text>
                      <Paragraph>
                          Настоящий Договор составлен в трех экземплярах (два экземпляр(а) – МЭС, один экземпляр –
                          Абоненту), имеющих одинаковую юридическую силу.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="6">
                  <Text>
                      <Paragraph>
                          Споры и разногласия, возникающие из настоящего Договора, разрешаются Сторонами путем
                          переговоров. В случае невозможности разрешения споров путем переговоров:
                      </Paragraph>
                      <Paragraph>
                          а) споры, разногласия и требования, возникающие в связи с заключением, изменением, расторжением
                          или недействительностью Договора, могут быть переданы на рассмотрение суда (арбитражного суда);
                      </Paragraph>
                      <Paragraph>
                          б) споры, которые возникли или могут возникнуть между Абонентом и МЭС в связи с неисполнением
                          или ненадлежащим исполнением денежного обязательства, возникшего из настоящего Договора, в том
                          числе обязанности возместить убытки или уплатить неустойку в случае нарушения настоящего
                          Договора, могут быть переданы Сторонами на разрешение суда после принятия Сторонами мер по
                          досудебному урегулированию, под которым понимается направление претензии, по истечении 10
                          (десяти) календарных дней со дня наступления одного из следующих событий (в зависимости от того,
                          какое из указанных ниже событий наступит раньше):
                      </Paragraph>
                      <Paragraph>
                          - направление Абоненту (МЭС) заказным письмом с уведомлением о вручении соответствующей
                          претензии;
                      </Paragraph>
                      <Paragraph>
                          - получение Абонентом (МЭС) направленной (врученной) ему соответствующей претензии, если
                          претензия доставлена или вручена Абоненту (МЭС) непосредственно Абонентом (МЭС) или нарочным;
                      </Paragraph>
                      <Paragraph>
                          - направление Абоненту (МЭС) на адрес электронной почты последнего, указанный в настоящем
                          Договоре, соответствующей претензии. В данном случае направляемая Абоненту на указанный адрес
                          электронной почты претензия может исходить от любого работника МЭС, что подтверждается указанием
                          в адресе отправителя домена МЭС (mosenergosbyt.ru). При этом Абонент считается получившим
                          претензию в день ее отправления работником МЭС на адрес электронной почты Абонента, указанный в
                          настоящем Договоре.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="7">
                  <Text>
                      <Paragraph>
                          При исполнении настоящего Договора, в случае возникновения вопросов, не предусмотренных
                          Договором, Стороны руководствуются Гражданским кодексом Российской Федерации, Федеральным
                          законом «Об электроэнергетике», Основными положениями функционирования розничных рынков
                          электрической энергии, Правилами полного и (или) частичного ограничения режима потребления
                          электрической энергии и иными нормативными правовыми актами.
                      </Paragraph>
                      <Paragraph>
                          В случае принятия уполномоченными государственными органами нормативных и иных Актов,
                          устанавливающих порядок взаимоотношения Сторон, связанный с процессом энергоснабжения, отличный
                          от предусмотренного Договором, Стороны обязаны руководствоваться положениями указанных Актов с
                          момента их принятия, кроме случаев, когда в положениях принятого Акта прямо указано, что иное
                          может быть установлено Договором.
                      </Paragraph>
                      <Paragraph>
                          Каждая из заинтересованных Сторон при вышеуказанных обстоятельствах вправе обратиться в
                          арбитражный суд с иском о внесении изменений в настоящий Договор как на основании случая,
                          предусмотренного Договором.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="8">
                  <Text>
                      <Paragraph>
                          Если между Сторонами заключено соглашение о порядке использования электронной подписи, то
                          документы, предусмотренные настоящим Договором, подписанные в установленном порядке с
                          применением электронной подписи, считаются совершёнными в надлежащей форме.
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="9">
                  <Text>
                      <Paragraph>
                          Aбонент, получивший от МЭС (в том числе через сетевую организацию) подписанный проект настоящего
                          Договора на бумажном носителе или в электронной форме, при несогласии с условиями Договора
                          вправе направить МЭС предложение о заключении Договора на иных условиях. При этом Абонент не
                          вправе вносить изменения (правки) в текст проекта настоящего Договора, подписанного МЭС.
                          Внесение изменений (правок) в текст проекта настоящего Договора, подписанного МЭС, является
                          недобросовестным поведением со стороны Абонента.
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="9" title="ОСОБЫЕ УСЛОВИЯ">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          _____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________\n' +
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                          _____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________\n' +
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
          <Chapter number="10" title="МЕСТА НАХОЖДЕНИЯ, АДРЕСА И БАНКОВСКИЕ РЕКВИЗИТЫ СТОРОН" requisitesType="true"/>
          <Chapter number="11" title="ПРИЛОЖЕНИЯ К ДОГОВОРУ">
              <Point number="1">
                  <Text>
                      <Paragraph>
                          Приложение № 1 «Адреса поставки электрической энергии (мощности)».
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="2">
                  <Text>
                      <Paragraph>
                          Приложение № 2 «Реестр источников энергоснабжения, энергопринимающего оборудования и средств
                          учета электроэнергии и мощности».
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="3">
                  <Text>
                      <Paragraph>
                          Приложение № 3 «Акт согласования технологической и (или) аварийной брони электроснабжения
                          Абонента».
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="4">
                  <Text>
                      <Paragraph>
                          Приложение № 4 «Порядок определения объема и расчета стоимости поставленной электрической
                          энергии (мощности)».
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="5">
                  <Text>
                      <Paragraph>
                          Приложение № 5 «Порядок оплаты потребленной электрической энергии (мощности)».
                      </Paragraph>
                  </Text>
              </Point>
              <Point number="6">
                  <Text>
                      <Paragraph>
                          Приложения № 4, 5 являются неотъемлемой частью настоящего Договора.
                      </Paragraph>
                      <Paragraph>
                          Приложение № 2 является неотъемлемой частью настоящего Договора за исключением случаев
                          заключения Договора до завершения процедуры технологического присоединения энергопринимающих
                          устройств Потребителя к объектам электросетевого хозяйства.
                      </Paragraph>
                      <Paragraph>
                          В случае составления и подписания Сторонами Приложения, не поименованного в предыдущем абзаце
                          как неотъемлемая часть Договора, данное Приложение становится неотъемлемой частью Договора.
                      </Paragraph>
                      <Paragraph>
                          Документы о технологическом присоединении, Акт допуска прибора учета в эксплуатацию, Акт
                          согласования технологической и (или) аварийной брони электроснабжения Потребителя, оформленные и
                          подписанные с сетевой организацией в отношении объектов и точек поставки, указанных в Приложении
                          № 2 к настоящему Договору, становятся неотъемлемой частью Договора с момента их получения МЭС от
                          сетевой организации или Абонента.
                      </Paragraph>
                  </Text>
              </Point>
          </Chapter>
      </ContractBody>`;

export const MOCK_PHASES_DATA = [
  {
    name: 'Фаза A',
    totalActivePower: '',
    totalReactivePower: '',
    activePower: '1.13',
    reactivePower: '1.21',
    totalPower: '1.04',
    phaseVoltage: '306.08',
    lineVoltage: '200.71',
    phaseCurrent: '5.55',
  },
  {
    name: 'Фаза B',
    totalActivePower: '5.55',
    totalReactivePower: '2.12',
    activePower: '0.15',
    reactivePower: '0.57',
    totalPower: '0.33',
    phaseVoltage: '333.33',
    lineVoltage: '222.24',
    phaseCurrent: '1.03',
  },
  {
    name: 'Фаза C',
    totalActivePower: '',
    totalReactivePower: '',
    activePower: '1.63',
    reactivePower: '0.21',
    totalPower: '1.64',
    phaseVoltage: '386.58',
    lineVoltage: '218.70',
    phaseCurrent: '7.51',
  },
];
