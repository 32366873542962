const getWorkflows = (state) => state.workflows;

// TODO не самый изящный способ, иначе не обновляется после установки значения
const getWorkflowsByCorrelationId = (state) => () => state.workflowsByCorrelationId;

const getTasks = (state) => (userType) => {
  // TODO remove testTasks
  if (state.testTasks.length > 0) {
    return state.tasks[userType] === undefined ? state.testTasks
      : state.tasks[userType].filter((item) => item.props !== undefined).concat(state.testTasks);
  }
  // TODO remove, there should be no empty tasks
  return state.tasks[userType] === undefined ? [] : state.tasks[userType].filter((item) => item.props !== undefined);
};
const getTaskByWorkflowId = (state) => (workflowId, userType) => {
  const tasks = state.tasks[userType]?.filter((task) => task.props.workflowId === workflowId);
  if (!tasks || tasks.length === 0) {
    return {};
  }
  return tasks[0];
};
const getTasksCounter = (state) => state.tasksCounter;

export default {
  getWorkflows,
  getTasks,
  getTaskByWorkflowId,
  getTasksCounter,
  getWorkflowsByCorrelationId,
};
