<template>
  <v-table-new
    v-model="transformedContractsSummary"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="contractsSummary.pageOut"
    :template="template"
    excel-report-file-name="Ведение договоров. Спецификация"
    server-side-sorting
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';
import { mapGetters } from 'vuex';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'SpecificationTable',
  components: { VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      contractsSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    transformedContractsSummary() {
      if (!this.contractsSummary || !this.contractsSummary.data || !Array.isArray(this.contractsSummary.data)) {
        return [];
      }

      return this.contractsSummary.data.map(this.transformed);
    },
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Номер и дата договора',
            key: 'contract',
            size: 'sm',
            thStyle: {
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Подрядчик',
            key: 'contractorName',
            thStyle: {
              minWidth: '83px',
            },
            size: 'lg',
            filter: this.contractorFilter,
            sort: {},
          },
          {
            label: 'АХП',
            key: 'axpName',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Количество АХП',
            key: 'countAxp',
            type: 'number',
            size: 'sm',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Элемент',
            key: 'elementName',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Количество элементов',
            key: 'countElement',
            type: 'number',
            size: 'sm',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Единицы измерения',
            key: 'unitName',
            size: 'sm',
            sort: {},
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Цена за ед., руб.',
            key: 'price',
            type: 'number',
            size: 'sm',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '83px',
            },
          },
          {
            label: 'Мероприятие',
            key: 'eventName',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            filter: { type: 'eventsOdk' },
            sort: {},
          },
          {
            label: 'Дата начала мероприятия',
            key: 'eventStartDate',
            thStyle: {
              minWidth: '110px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата окончания мероприятия',
            key: 'eventEndDate',
            thStyle: {
              minWidth: '110px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Дата создания',
            key: 'createDate',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        contract: 'contract',
        axpName: 'axpName',
        contractorName: 'contractorName',
        eventName: 'eventName',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });
      if (!this.isBoiler) {
        result.contractorId = this.getAccount.supplier.id;
      }
      return result;
    },
    contractorFilter() {
      return this.isBoiler ? { type: 'contractorOdk' } : undefined;
    },
    ...mapGetters('user', ['getAccount', 'isBoiler']),
  },
  methods: {
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    async loadData() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('contract-specifications-summary', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };
        this.contractsSummary = responseData;
        this.contractsSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('contractsSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('contract-specifications-summary', 1, this.contractsSummary.pageOut.totalElements, sortField, sort, filters);

        this.contractsSummary = responseData;

        return responseData.data?.map(this.transformed) ?? [];
      } catch (error) {
        console.log('contractsSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(item, index) {
      return {
        ...item,
        index: index + 1,
        contractorName: item.contractorName,
        contract: item.contract,
        axpName: item.axpName,
        countAxp: item.countAxp,
        eventName: item.eventName,
        eventStartDate: formatDateFromOdkTimestamp(item.eventStartDate),
        eventEndDate: formatDateFromOdkTimestamp(item.eventEndDate),
        createDate: formatDateFromOdkTimestamp(item.createDate),
        elementName: item.elementName,
        unitName: item.unitName ?? 'Комплекты',
        price: item.price,
      };
    },
  },
};
</script>

<style scoped>
</style>
