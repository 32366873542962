<template>
  <div class="table__tabs__scroll">
    <div :style="displayLeft" class="table__tabs__scroll-left" @click="changeScrollLeft">
      <v-table-tab-scroll-arrow-left />
    </div>
    <div ref="tabs" class="table__tabs">
      <v-table-tab
        v-for="(subGroup, index) in subGroups"
        :key="index"
        :is-active="index === value"
        :variant="variant"
        @click.native="$emit('input', index, subGroup)"
      >
        {{ subGroup.label }}
      </v-table-tab>
    </div>
    <div :style="displayRight" class="table__tabs__scroll-right" @click="changeScrollRight">
      <v-table-tab-scroll-arrow-right />
    </div>
  </div>
</template>

<script>
import VTableTab from './VTableTab';
import VTableTabScrollArrowLeft from './VTableTabScrollArrowLeft';
import VTableTabScrollArrowRight from './VTableTabScrollArrowRight';

export default {
  name: 'VTableTabs',
  components: { VTableTab, VTableTabScrollArrowLeft, VTableTabScrollArrowRight },
  props: {
    subGroups: { type: Array, required: true },
    variant: { type: String, default: 'outlined' },
    value: { type: Number, required: true },
  },
  mounted() {
    this.isMounted = true;
  },
  data() {
    return {
      isMounted: false,
      scrollLeft: 0,
    };
  },
  computed: {
    displayLeft() {
      if (!this.isMounted)
        return;

      return this.scrollLeft > 0 ? 'display: flex' : 'display: none';
    },
    displayRight() {
      if (!this.isMounted)
        return;

      return this.$refs.tabs.scrollWidth - this.scrollLeft > this.$refs.tabs.clientWidth ? 'display: flex' : 'display: none';
    },
  },
  methods: {
    changeScrollLeft() {
      this.scrollLeft = this.$refs.tabs.scrollLeft - this.$refs.tabs.clientWidth - 30;
      this.$refs.tabs.scrollLeft = this.scrollLeft;
    },
    changeScrollRight() {
      this.scrollLeft = this.$refs.tabs.scrollLeft + this.$refs.tabs.clientWidth - 30;
      this.$refs.tabs.scrollLeft = this.scrollLeft;
    },
  },
};
</script>

<style lang="scss" scoped>
.table__tabs {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__scroll-left, &__scroll-right {
    cursor: pointer;
    background-color: #166CBF;
    padding: 10px 7px;
    text-align: center;

    svg {
      fill: #fff;
    }
  }

  &__scroll-left {
    align-items: center;
    margin-right: 3px;
    border-radius: 2px 0 0 0;
  }

  &__scroll-right {
    align-items: center;
    margin-left: 3px;
    border-radius: 0 2px 0 0;
  }

  &__scroll-left:hover, &__scroll-right:hover {
    background-color: #dee2e6;
  }
}

.table__tabs__scroll {
  position: relative;
  display: flex;
}
</style>
