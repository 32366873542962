<template>
  <page title="Акты об осуществлении технологического присоединения">
    <content-block :action-block-items="actionBlockItems" :acts="acts" :loading="loading" />
  </page>
</template>

<script>
import { Page } from '@/components';
import ContentBlock from 'views/private/ActsImplementationTechnologicalConnection/components/ContentBlock.vue';
import { mapActions } from 'vuex';
import { goToPageByRoute } from 'utils/goToRoute/index.js';

export default {
  name: 'ActsImplementationTechnologicalConnection',
  components: { ContentBlock, Page },
  created() {
    this.loadData();
  },
  data() {
    return {
      acts: [],
      loading: false,
    };
  },
  computed: {
    actionBlockItems() {
      return [
        {
          id: 1,
          title: 'Сформировать акт об осуществлении технологического присоединения',
          method: goToPageByRoute('acts-implementation-technological-connection/act-of-technological-connection'),
        },
      ];
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.acts = await this.loadConnectionCertificates();
      } catch (error) {
        console.log('error', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('document', ['loadConnectionCertificates']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped lang="scss">

</style>
