<template>
  <page title="Загрузите АТП для потребителя">
    <div class="atp-upload">
      <send-connection-certificate hide-status />
    </div>
  </page>
</template>

<script>

import { Page } from 'components';
import SendConnectionCertificate from 'views/private/ConnectionApplication/Task/actions/SendConnectionCertificate';

export default {
  name: 'AtpUpload',
  components: {
    SendConnectionCertificate,
    Page,
  },
};
</script>

<style lang="scss">
.atp-upload {
  display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    height: 100%;
}
</style>
