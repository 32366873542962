const INCOMIG = (state, props) => {
  state.messages.push({
    text: props.message,
    time: new Date().toLocaleTimeString().slice(0, -3),
    author: 'client',
    type: 'default',
    documents: props.documents,
  });
};

const OUTCOMING = (state, props) => {
  state.messages.push({
    text: props.message,
    time: new Date().toLocaleTimeString().slice(0, -3),
    author: 'bot',
    type: props.type,
    documents: [],
  });
};

const SET_USER_ROOMS = (state, userRooms) => {
  state.userRooms.push(userRooms);
};

export default {
  INCOMIG,
  OUTCOMING,
  SET_USER_ROOMS,
};
