<template>
  <div class="form-wrp">
    <form v-if="!$route.params.measurementKind || !value.measurementKind?.value" class="form">
      <v-select
        v-model="kind"
        label="Тип точки"
        required
        :options="measurmentKindOptions"
      />
    </form>

    <form class="form">
      <span class="form-date">
        Акт будет составлен на {{ value.currentDate.value }}.
      </span>
      <table class="test">
        <thead>
          <tr>
            <th colspan="7">1. Прибор учета (ПУ)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Тип</td>
            <td colspan="2">
              <input type="text" />
            </td>
            <td>Заводской номер</td>
            <td colspan="3">
              <input v-model.trim="value.factoryNumber.value" type="text">
            </td>
          </tr>
          <tr>
            <td rowspan="3">Показание</td>
            <td>Σ/Т1</td>
            <td>
              <input v-model.number="value.T1.value" type="number">
            </td>
            <td>Схема включения</td>
            <td colspan="3">
              <input type="text"/>
            </td>
          </tr>
          <tr>
            <td>Т2</td>
            <td>
              <input type="number" >
            </td>
            <td>Дата изготовления</td>
            <td>
              <input type="text" />
            </td>
            <td>№ пломбы</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>Т3</td>
            <td>
              <input type="number">
            </td>
            <td>Дата поверки</td>
            <td class="date-picker-fix">
              <v-date-picker
                v-model="value.verificationDate.value"
                format="YYYY-MM-DD"
                is-disabled-future-dates
              />
            </td>
            <td>Дата истечения МПИ</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>Класс точности</td>
            <td colspan="2">
              <input type="text" />
            </td>
            <td colspan="2">Место установки ПУ</td>
            <td colspan="2">
              <input type="text" />
            </td>
          </tr>
        </tbody>
      </table>

      <!-- TODO: not remove followings 3 tables -->
      <!--
      <table class="test">
        <thead>
          <tr>
            <th colspan="8">2. Установленные трансформаторы тока</th>
          </tr>
          <tr>
            <td>ТТ (тип, марка)</td>
            <td>Коэфф.тр-ции Кт.тт</td>
            <td>Класс точности</td>
            <td>№ ТТ</td>
            <td>№ пломбы </td>
            <td>Дата посл.поверки</td>
            <td>Дата истечения МПИ</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="test">
        <thead>
          <tr>
            <th colspan="8">3. Установленные трансформаторы напряжения</th>
          </tr>
          <tr>
            <td>ТН (тип, марка)</td>
            <td>Коэфф.тр-ции</td>
            <td>Класс точности</td>
            <td>№ ТН</td>
            <td>№ пломбы </td>
            <td>Дата поверки</td>
            <td>Дата истечения МПИ</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="test">
        <thead>
          <tr>
            <th colspan="4">4. Испытательная коробка</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Наличие испытательной коробки (да/нет)</td>
            <td>
              <input type="text" />
            </td>
            <td>№ пломбы</td>
            <td>
              <input type="text" />
            </td>
          </tr>
        </tbody>
      </table>
      -->

    </form>
  </div>
</template>

<script>
import { VDatePicker } from 'components';
import VSelect from 'components/VSelect/VSelect';

export default {
  components: {
    VSelect,
    VDatePicker,
  },
  props: {
    value: {
      type: Object,
    },
  },
  mounted() {
    this.sendEmptyKindToParent();
  },
  data() {
    return {
      kind: '',
      measurmentKindOptions: [
        {
          label: 'Приём',
          value: 'RECEIVING',
        },
        {
          label: 'Поставка',
          value: 'DELIVERY',
        },
        {
          label: 'Передача',
          value: 'TRANSFER',
        },
        {
          label: 'Техническая',
          value: 'TECHNICAL',
        },
      ],
    };
  },
  watch: {
    kind(newKind) {
      this.$emit('kind', newKind);
    },
  },
  methods: {
    sendEmptyKindToParent() {
      this.$emit('kind', this.kind);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px
  }

  display: flex;
  flex-direction: column;
  // TODO: hardcode width - not good
  width: 650px;

  &-date {
    font-size: larger;
    padding-top: 30px;
    margin-bottom: 5px;
  }

  table.test,
  table.test td,
  table.test th {
    padding: 4px;
    // width: 1000px;
    height: 35px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0.5px solid #999;
  }

  // hide spin box
  input[type='number'] {
    -moz-appearance:textfield;
  }

  // hide spin box
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input {
    border: 0;
    width: 100%;
    height: 100%;
    outline: 0;
  }

  .date-picker-fix {
    width: 120px;
  }
}
</style>
