<template>
  <div :class="$style.container">
    <div>
      <div v-if="additionalDocument && additionalDocument.comments" :class="$style.reviewDocument">
        <div :class="$style.additionalDocumentTitle">
          Дополнительная информация переданная по заявке:
        </div>
        <p v-html="comments"></p>
      </div>
      <div v-if="additionalDocument.files" :class="$style.reviewDocumentFiles">
        <p v-for="(file, index) in additionalDocument.files" :key="index"
           style="display: flex; justify-content: space-between">
          <a :class="$style.reviewFileName" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocumentFile[' + index + '].name'">{{ file.name }}</a>
          <a :class="$style.reviewFileDownload" :download="file.name" :href="fileUrl(file)"
             :role="'additionalDocumentFile[' + index + '].download'">
            <!--                <img src="~@/assets/icons/file/file_download.svg" style="margin-right: 4px" alt="" />-->
            скачать</a>

        </p>
      </div>

      <div v-if="retailerOfferDocument && retailerOfferDocument.id" :class="$style.statusGroup">
        <div v-if="retailerOfferDocument.comments" :class="$style.accompanyingTitle">Сотрудник {{ retailCompanyName }}
          рассмотрел ваши предложения и внёс изменения в документы:
        </div>
        <p v-if="retailerOfferDocument.comments" :class="$style.accompanyingText" v-html="retailerOfferComments"></p>
      </div>

      <div v-if="retailerOfferDocument?.id" :class="$style.statusGroup">
        <div :class="$style.retailerOfferDocumentTitle">
          Документы для подписания
        </div>
        <v-file-attachment :document-id="retailerOfferDocument.id"
        />
      </div>

      <div :class="$style.statusGroup">
        <p :class="$style.applicationNote" style="margin-bottom: 32px">
          <span>Потребление электричества без заключённого со сбытовой компанией договора влечёт за собой юридическую ответственность согласно <a
            href="https://base.garant.ru/70183216/#block_4148" target="_blank">п. 121 постановления Правительства РФ от 17 сентября 2018 г. N 1096</a></span>
          <br/>Если не принять условия договора, объект будет подключён к электросети, но вы не будете иметь права
          пользоваться электроэнергией пока не заключите договор со сбытовой компанией.
        </p>
      </div>

      <div :class="$style.statusGroup">
        <p :class="$style.submitNote" style="margin-bottom: 32px">
          <c-checkbox
            v-model="isChecked"
            label="Я согласен с условиями"
            role="approve"/>
        </p>
      </div>

      <div v-if="!isChecked" :class="$style.blockContent">
        <div :class="$style.title">
          Предложения по изменениям *
        </div>
        <c-textarea
          v-model="correctionComments"
          role="correctionComments"
        />
      </div>
    </div>

    <div :class="$style.actionContent">
      <p v-if="isChecked" :class="$style.submitNote">
        Нажимая кнопку «Принять и подписать», вы принимаете условия договора и подписываете его простой электронной
        подписью.
      </p>
      <p v-else :class="$style.submitNote">
        Сотрудник энергосбытовой компании ознакомится с предложениями, по возможности учтёт их и вышлет новый договор.
      </p>
      <p v-if="submitError.length !== 0" :class="$style.errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <submit-task-details :disabled="isWaitingSubmit || !isValid || !retailerOfferDocument.id"
                           :title="submitTitle"
                           @submit="onSubmit"/>
    </div>
    <simple-signature-modal
      :is-visible-simple-signature-modal="isVisibleSimpleSignatureModal"
      :xml-document="retailerOfferDocument.document"
      @close="closeSignatureModal"
      @document-signed="submitSignedDocument"/>
  </div>
</template>

<script>
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import { SimpleSignatureModal, VFileAttachment } from 'components';

export default {
  name: 'ApprovedRetailerOffer',
  components: {
    VFileAttachment,
    SimpleSignatureModal,
    SubmitTaskDetails,
    HorizontalProgressBar,
    CCheckbox: () => import('atoms/common/CCheckbox'),
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
  },
  props: {
    documentId: String,
    actionProps: Object,
    offerDocument: Object,
    additionalDocument: Object,
    retailerOfferDocument: Object,
    retailCompanyName: String,
  },
  data() {
    return {
      isChecked: true,
      correctionComments: '',
      submitError: '',
      isWaitingSubmit: false,
      isVisibleSimpleSignatureModal: false,
    };
  },
  computed: {
    submitTitle() {
      if (this.isChecked) {
        return 'ПРИНЯТЬ И ПОДПИСАТЬ';
      }
      return 'ОТПРАВИТЬ ПРЕДЛОЖЕНИЯ';
    },
    isValid() {
      return this.isChecked || this.correctionComments !== '';
    },
    reviewType() {
      if (this.isChecked) {
        return this.actionProps.approvedReviewType;
      }
      return this.actionProps.rejectedReviewType;
    },
    reviewComments() {
      if (this.isChecked) {
        return '';
      }
      return this.correctionComments;
    },
    comments() {
      return formatting.formattedString(this.additionalDocument.comments, '«', '»');
    },
    retailerOfferComments() {
      return formatting.formattedString(this.retailerOfferDocument.comments, '«', '»');
    },
    ...mapGetters('user', ['getUserType']),
  },
  methods: {
    openSignatureModal() {
      this.isVisibleSimpleSignatureModal = true;
    },
    closeSignatureModal() {
      this.isVisibleSimpleSignatureModal = false;
    },
    async submitSignedDocument(document) {
      this.closeSignatureModal();
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: document });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.showNotification();
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;

      if (this.isChecked) {
        this.openSignatureModal();
      } else {
        const documentReviewXml = connectionApplicationData.documentReviewXml(
          this.retailerOfferDocument.id,
          'document-rejected',
          this.reviewComments,
          [],
          {},
        );

        try {
          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            this.isWaitingSubmit = false;
            // TODO возможно, нужно ждать изменения состояния
            this.$nextTick(() => {
              this.showNotification();
            });
            this.onClose();
          }, 2000);

        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
      }
    },
    showNotification() {
      if (this.isChecked) {
        this.vueShowNotification(
          `${this.actionProps.agreementTitle} ПОДПИСАН`,
          '<p></p>',
        );
      } else {
        this.vueShowNotification(
          'ПРЕДЛОЖЕНИЯ ПО ИЗМЕНЕНИЯМ ОТПРАВЛЕНЫ',
          '<p></p>',
        );
      }
    },
    onClose() {
      this.statusGroup = undefined;
      this.tariff = undefined;
      this.$emit('close');
    },
    statusGroupClick(val) {
      this.tariff = undefined;
      this.statusGroup = val;
    },
    tariffGroupClick(val) {
      this.tariff = val;
    },
    handleRemoveCorrectionDocs(file) {
      this.correctionDocs = this.correctionDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveAgreementTechnologicalConnectionDocs(file) {
      this.agreementTechnologicalConnectionDocs = this.agreementTechnologicalConnectionDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveTechnicalConditionsDocs(file) {
      this.technicalConditionsDocs = this.technicalConditionsDocs
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
@import 'styles/components/task-details'

.title, .additionalDocumentTitle
  padding-bottom: 16px
  +base-title

.retailerOfferDocumentTitle
  +base-title

.commentsTitle
  margin-top: 40px
  margin-bottom: 24px
  +base-title

.statusGroup, .submitNote, .offerGroup, .blockContent
  width: 100%
  max-width: 560px
  margin: 0 auto

.blockContent
  margin-bottom: 40px

.statusGroup
  margin-top: 40px

.submitNote
  margin-bottom: 40px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px
  color: #0E0F0F

.accompanyingText p
  margin-bottom: 8px

.accompanyingText
  margin-bottom: 24px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.reviewDocument
  padding-bottom: 24px

.reviewDocument, .reviewDocumentFiles, .accompanyingText
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles
  margin-bottom: 40px

.reviewDocument p
  margin-top: 8px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #000

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.applicationNote
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.applicationNote span, .applicationNote span a
  color: #EB5757

.applicationNote span a
  text-decoration: underline

.accompanyingTitle
  margin-bottom: 8px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 22px

.accompanyingText
  margin-bottom: 40px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.actionContent
  width: 560px
  margin: 0 auto
</style>
