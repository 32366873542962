<template>
  <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createDefect"
      @delete="deleteDefect"
      @update="updateDefect">
    <template #processesId="slotProps">
      <div class="vuetify-styles no-min-height" data-app style="margin: 24px 0 0 0">
        <v-app class="no-min-height" style="display: flex; width: auto; min-height: 0">
          <simple-autocomplete
              ref="processes"
              :is-disabled="slotProps.disabled"
              :is-required="slotProps.field.required"
              :selected="slotProps.value"
              :title="slotProps.field.name"
              multiple
              platform-font
              url="/defects/processes?name="
              url-default-data="/defects/processes"
              @change="slotProps.input"
          ></simple-autocomplete>
        </v-app>
      </div>
    </template>
  </add-directory-field-modal>
</template>

<script>
import { createDefect, deleteDefect, updateDefect } from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';

export default {
  name: 'DefectEdit',
  components: {
    AddDirectoryFieldModal,
    SimpleAutocomplete,
  },
  props: {
    modalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalModalValue: {},
      loading: false,
    };
  },
  computed: {
    fields() {
        return [{
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
        {
          type: AddDirectoryFieldModalFieldType.CUSTOM,
          id: 'processesId',
          name: 'Процессы, в которых используется замечание',
          required: true,
          defaultValue: [],
        },
      ];
    },
  },
  watch: {
    modalAction(val) {
      this.internalModalAction = val;
    },
    modalValue(val) {
      this.internalModalValue = val;
    },
  },
  methods: {
    closeModal() {
      this.internalModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:modalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalModalValue = {};
      this.$emit('update:modalValue', {});
    },
    deleteDefect() {
      this.doAction(() => deleteDefect(this.modalValue.id));
    },
    createDefect() {
      this.doAction(() => createDefect(this.modalValue));
    },
    updateDefect() {
      this.doAction(() => updateDefect(this.modalValue.id, this.modalValue));
    },
    async doAction(action) {
      this.loading = true;
      try {
        const result = await action();
        this.$emit('onAction', result?.data);
        this.closeModal();
        this.$emit('loadData', this.modalValue);
      } catch (error) {
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>
