<template>
  <div>

    <odk-tag-modal v-model="tagsModalState" @updated="fetchAxp"/>

    <v-app class="vuetify-styles" data-app>
      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
              <div class="title">
                <span class="text-h5">Контроль монтажа</span>
                <span v-if="isExpired" class="errorText">
                  {{subtitleError}}
                </span>
              </div>
            </v-card-title>
            <v-card-text>
              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"/>
              <v-checkbox
                v-model="onlyAssigned"
                class="no-margin-top"
                color="success"
                hide-details
                label="Только назначенные на меня"
              ></v-checkbox>
              <div>
                <yandex-map
                  ref="customMap"
                  :collection-axp="axpItemsFiltered"
                  @tapPlacemark="onTapPlacemark"
                />
              </div>
              <v-data-table
                v-model="selectedAdreskaFact"
                :headers="headersTable"
                :items="axpItemsFiltered"
                :loading="isLoadingAxp"
                :search="search"
                class="elevation-1 mb-4"
                item-key="id"
                return-object
                show-select
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.adreskaId)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>

                <template #item.status="{ item }">
                  {{
                    item.status
                  }}
                </template>
              </v-data-table>

              <events-autocomplete
                ref="event"
                v-model="eventId"
                :dependency="axpItemsFiltered"
                :fetch-data-function="eventByName"
                :fetch-default-data-function="eventByName"
                :items.sync="events"
                :search.sync="eventName"
                is-required
                title="Мероприятие"
              />

              <simple-autocomplete-by-function
                ref="contractor"
                v-model="contractorId"
                :dependency="axpItemsFiltered"
                :fetch-data-function="contractorByName"
                :fetch-default-data-function="contractorByName"
                :items.sync="contractors"
                :search.sync="contractorName"
                is-required
                title="Подрядчик"
              ></simple-autocomplete-by-function>

              <simple-autocomplete-by-function
                ref="adreska"
                v-model="adreskaId"
                :dependency="axpItemsFiltered"
                :fetch-data-function="adreskaByName"
                :fetch-default-data-function="adreskaByName"
                :items.sync="adreskas"
                :search.sync="adreskaName"
                is-required
                title="Программа оформления"
              ></simple-autocomplete-by-function>

              <text-field
                ref="countPlan"
                :text="countPlan"
                class="mt-2"
                is-disabled
                title="Количество по плану"
              ></text-field>

              <text-field
                ref="count"
                :text="count"
                class="mt-2"
                is-disabled
                title="Количество к осмотру"
              ></text-field>

              <adreska-history :adreska-id="adreskaId" subselect="INSTALLATION_CONTROL" title="Информация о монтаже"/>


              <v-radio-group v-model="remarksRadioGroup" column>
                <v-radio label="Замечаний нет" value="NO_REMARKS"></v-radio>
                <v-radio label="Замечания есть" value="REMARKS"></v-radio>
              </v-radio-group>

              <div v-if="isRemark">
                <custom-date-picker
                  ref="modalDate"
                  v-model="remarkEliminationDate"
                  :allowed-dates="(val) => val >= new Date().toISOString().substring(0, 10)"
                  is-required
                  label="Дата устранения замечания"
                />
              </div>

              <div v-if="remarksRadioGroup?.length">
                <v-row>
                  <v-col>
                    <div>
                      <v-label :class="{
          'error-label': isRemark && commentRemarks != null && !commentRemarks?.length,
        }">{{ isRemark ? 'Комментарий*' : 'Комментарий' }}
                      </v-label>
                    </div>
                    <v-textarea
                      v-model="commentRemarks"
                      dense
                      name="input-7-4"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>

                <file-input
                  ref="photos"
                  v-model="photos"
                  is-required
                  title="Фото"
                ></file-input>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="Boolean(isDisabledSaveDialog)"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSendControlInstallationResults"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog v-model="loaderDialog"
                  class="v-dialog-top" persistent width="250">
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + "%" }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import FileInput from '@/components/Processes/components/FileInput.vue';
// import { D4_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import CustomDatePicker from 'components/Processes/components/CustomDatePicker.vue';
import TextField from 'components/Processes/components/TextField.vue';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { distinctBy } from 'utils/arrays/arrays';
import { formatDateFromOdkTimestamp, formatOdkTimestampToDateObject, getDateAtStartOfDay } from 'lib/utils/date';
import { filterForAccess } from 'lib/utils/permissions';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  components: {
    OdkTagModal,
    YandexMap, SimpleAutocompleteByFunction, TextField,
    CustomDatePicker,
    AdreskaHistory,
    FileInput,
    EventsAutocomplete,
  },
  props: {},

  async created() {
    await this.fetchAxp();
    if (this.$route.params.initAdreskaId) {
      const adreskaFact = this.axpItems.filter((it) => it.adreskaId === this.$route.params.initAdreskaId);
      if (!adreskaFact.length) {
        await this.setNotification({ message: 'Не найден объект с переданным ID' });
        this.onTapBack();
      }
      adreskaFact.forEach((it) => this.onTapPlacemark(it.id));
    }
  },
  data: () => ({

    progressValue: 0,
    remarksRadioGroup: null,
    isLoadingAxp: false,
    loaderDialog: false,
    tagsModalState: null,
    onlyAssigned: false,

    photos: [],
    remarkEliminationDate: null,
    commentRemarks: null,

    modalDate: false,

    adreskaId: null,
    contractorId: null,
    eventId: null,

    tagsFilter: null,
    contractorName: null,
    adreskaName: null,
    eventName: null,
    search: null,

    contractors: [],
    adreskas: [],
    events: [],

    alreadyMountCounter: 0,

    axpItems: [],
    selectedAdreskaFact: [],
  }),

  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing ? [
        { text: 'Tэги', value: 'addTagButton', align: 'center' }] : [];
      return [
        { text: 'ID', value: 'adreskaFactId', align: 'center' },
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        {
          text: 'Наименование АХП',
          align: 'start',
          value: 'axpName',
        },
        {
          text: 'Программа оформления',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Подрядчик',
          align: 'start',
          value: 'contractorName',
        },
        { text: 'Дата монтажа', value: 'dateText', align: 'center' },
        { text: 'Статус', value: 'status', align: 'center' },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    isRemark() {
      return this.remarksRadioGroup === 'REMARKS';
    },
    isDisabledSaveDialog() {
      const isDisabledPhoto = !this.photos?.length;
      const isDisabledCommentRemarks = this.isRemark && !this.commentRemarks?.length;
      const isDisabledAdreskaFact = !this.selectedAdreskaFact.length;
      const isDisabledDate = this.isRemark && !this.remarkEliminationDate;
      return isDisabledPhoto || isDisabledCommentRemarks || isDisabledDate || isDisabledAdreskaFact;
    },
    adreskaFactIds() {
      return this.selectedAdreskaFact.map((it) => it.id);
    },
    distinctContractors() {
      return distinctBy(this.axpItemsFiltered.map((it) => ({
        id: it.contractorId,
        name: it.contractorName,
      })), (it) => it.id);
    },
    distinctEvents() {
      return distinctBy(this.axpItemsFiltered.map((it) => ({
        id: it.eventId,
        name: it.eventName,
      })), (it) => it.id);
    },
    distinctAdreska() {
      return distinctBy(this.axpItemsFiltered.map((it) => ({
        id: it.adreskaId,
        name: it.name,
        contractorId: it.contractorId,
        contractorName: it.contractorName,
        eventId: it.eventId,
        eventName: it.eventName,
        countPlan: it.countPlan,
        event: it.event,
      })), (it) => it.id);
    },
    axpItemsFiltered() {
      let filtered = this.axpItems;
      if (this.adreskaId) filtered = filtered.filter((it) => it.adreskaId === this.adreskaId);
      if (this.contractorId) filtered = filtered.filter((it) => it.contractorId === this.contractorId);
      if (this.eventId) filtered = filtered.filter((it) => it.eventId?.toString() === this.eventId?.toString());
      if (this.tagsFilter) filtered = filtered.filter((it) => it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase()));
      return filtered;
    },
    axpItemsByAdreska() {
      let filtered = this.axpItems;
      if (this.adreskaId) filtered = filtered.filter((it) => it.adreskaId === this.adreskaId);
      return filtered;
    },
    selectedAdreska() {
      return this.distinctAdreska.find((it) => it.id === this.adreskaId);
    },
    countPlan() {
        return this.selectedAdreska?.countPlan;
    },
    count() {
      return this.selectedAdreskaFact.length;
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    isExpired() {
      if (!this.selectedAdreska) return false;
      // После приёмки процесс не доступен => если зашли в процесс <=> Приёмка не осуществлялась
      return getDateAtStartOfDay(Date.now()) > formatOdkTimestampToDateObject(this.selectedAdreska.event.planInstallationEndDate);
    },
    subtitleError() {
      return 'Просрочена плановая дата окончания монтажа';
    },
    ...mapGetters('user', ['getToken', 'getAuthorization']),
  },

  watch: {
    onlyAssigned() {
      this.fetchAxp();
    },
    remarksRadioGroup() {
      this.remarkEliminationDate = null;
    },
    distinctContractors() {
      if (this.contractorName == null) {
        this.contractorName = '';
      }
    },
    distinctAdreska() {
      if (this.adreskaName == null) {
        this.adreskaName = '';
      }
    },
    distinctEvents() {
      if (this.eventName == null) {
        this.eventName = '';
      }
    },
    async adreskaFactIds() {
      this.onItemSelected();
    },
    adreskaId(newVal, oldVal) {
      if (oldVal !== newVal && oldVal) {
        this.selectedAdreskaFact = [];
      }
      if (this.adreskaId != null) {
        this.events = [{
          id: String(this.selectedAdreska.eventId),
          name: this.selectedAdreska.eventName,
        }];
        this.eventName = this.selectedAdreska.eventName;
        this.eventId = String(this.selectedAdreska.eventId);

        this.contractors = [{
          id: this.selectedAdreska.contractorId,
          name: this.selectedAdreska.contractorName,
        }];
        this.contractorName = this.selectedAdreska.contractorName;
        this.contractorId = this.selectedAdreska.contractorId;

        this.$refs.contractor.resetValidation();
      }
    },
  },

  methods: {
    openTags(id) {
      this.tagsModalState = id;
    },
    findByName(list, name) {
      if (!list) return [];
      if (!name) return [...list];
      return list.filter((it) => it.name.toLowerCase().includes(name.toLowerCase()));
    },
    eventByName(name) {
      return this.findByName(this.distinctEvents, name);
    },
    contractorByName(name) {
      return this.findByName(this.distinctContractors, name);
    },
    adreskaByName(name) {
      return this.findByName(this.distinctAdreska, name);
    },
    onTapPlacemark(id) {
      const item = this.axpItemsFiltered.find((it) => it.id === id);
      if (this.selectedAdreskaFact.some((it) => it.id === id)) {
        this.selectedAdreskaFact = this.selectedAdreskaFact.filter((it) => it.id !== id);
        item.balloonContent.buttonLabel = 'Выбрать';
      } else {
        item.balloonContent.buttonLabel = 'Отменить';
        this.selectedAdreskaFact.push(item);
      }
      this.axpItems = [...this.axpItems];
    },
    async fetchAxp() {
      this.isLoadingAxp = true;
      this.axpItems = [];
      const url = this.onlyAssigned ? `${BASE_URL}/axp/installation/regular-inspection-assigned` : `${BASE_URL}/axp/installation`;
      const response = await fetch(url, {
        headers: {
          'X-Authorization': `Bearer ${this.getToken}`,
        },
      });
      const data = await response.json();
      this.alreadyMountCounter = data.length;

      this.axpItems = data.map((it) => {
        let color;
        let status;
        if (it.statusInstallationResultControl == 1) {
          color = 'red';
          status = 'С замечаниями';
        } else if (it.statusInstallationResultControl == 2) {
          color = 'green';
          status = 'Без замечаний';
        } else {
          color = 'blue';
          status = 'Контроль не проводился';
        }

        return {
          id: it.id,
          adreskaFactId: it.id,
          adreskaId: it.adreska.id,
          contractorId: it.adreska.contractorId,
          odkName: it.axp.odk.name,
          axpName: it.axp.name,
          name: it.adreska.name,
          address: it.adreska.address,
          contractorName: it.adreska.contractorName,
          event: it.adreska.event,
          status: status,
          color: color,
          locationLatitude: it.locationLatitude,
          locationLongitude: it.locationLongitude,
          statusInspection: it.statusInspection,
          statusLocalRepair: it.statusLocalRepair,
          isSelected: false,
          count: it.countOdk,
          countPlan: it.adreska.quantityPlan,
          dateText: formatDateFromOdkTimestamp(it.createDate),
          tags: it.adreska.tags.map((it) => it.name).join(', '),
          eventId: it.adreska.event.id,
          eventName: it.adreska.event.name,
          balloonContent: {
            title: it.axp.odk.name,
            buttonLabel: 'Выбрать',
            props: [
              {
                label: 'ID',
                value: it.id,
              },
              {
                label: 'АХП',
                value: it.axp.name,
              },
              {
                label: 'Тип декора',
                value: it.decorType?.name,
              },
              {
                label: 'Программа оформления',
                value: it.adreska.name,
              },
              {
                label: 'Подрядчик',
                value: it.adreska.contractorName,
              },
              {
                label: 'Статус',
                value: status,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(it.createDate),
              },
            ],
          },
        };
      });

      this.updateMap = this.updateMap + 1;
      this.isLoadingAxp = false;
    },
    onItemSelected() {
      if (!this.adreskaId) {
        const id = this.adreskaFactIds[0];
        this.adreskaId = this.axpItemsFiltered.find((it) => it.id === id)?.adreskaId;
      }
      const set = new Set(this.adreskaFactIds);
      this.axpItems = this.axpItems.map((it) => {
        const selected = set.has(it.id);
        return {
          ...it,
          isSelected: selected,
          balloonContent: {
            ...it.balloonContent,
            buttonLabel: selected ? 'Отменить' : 'Выбрать',
          },
        };
      });
    },
    onTapSendControlInstallationResults() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });

      const request = {
        taskId: this.taskId,
        adreskaId: this.selectedAdreska.id,
        adreskaFactId: this.adreskaFactIds,
        isRemark: this.isRemark,
        date: this.remarkEliminationDate,
        comment: this.commentRemarks,
        photos: mainPhotos,
      };

      axios
        .post(`${BASE_URL}/control-installation-results`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },

    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      window.location.reload();
    },

    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
</style>
