<template>
  <div class="table-cell-input">
    <v-input v-model="internalValue" :rules="validationRules" :rules-messages="validationMessages"
             class="table-cell-input__input"/>
  </div>
</template>

<script>
import VInput from 'components/VInput/VInput.vue';

export default {
  name: 'VTableCellInput',
  components: { VInput },
  props: {
    value: { type: String, default: '' },
    validationRules: { type: String, default: '' },
    validationMessages: {
      type: Object, default: () => {
      },
    },
    customCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    internalValue() {

      this.$emit('input', this.internalValue);
    },
  },
};
</script>

<style lang="scss">
.table-cell-input {
  display: flex;
}
</style>
