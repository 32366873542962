import { getAdreska, getAdreskaFact } from '@/api/rest/odk/api';

async function getAdreskaData({ commit }, id) {
  commit('SET_LOADING', true);
  try {
    const { data } = await getAdreska(id);
    // console.log('getAdreskaData::data', data);
    commit('SET_ADRESKA_DATA', data);
  } catch (error) {
    console.log('getAdreskaData::catch', error);
  } finally {
    commit('SET_LOADING', false);
  }
}

async function getObjectsData({ commit }, id) {
  commit('SET_LOADING', true);
  try {
    const { data } = await getAdreskaFact(id);
    // console.log('getObjectsData::data', data);
    commit('SET_OBJECTS_DATA', data);
  } catch (error) {
    console.log('getObjectsData::catch', error);
  } finally {
    commit('SET_LOADING', false);
  }
}

export default {
  getAdreskaData,
  getObjectsData,
};
