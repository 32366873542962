<template>
    <div>
      <div :class="$style.container">
        <div :class="$style.content">

          <div :class="$style.moreIcon">
            <tooltip-menu placement="left">
                <div slot="content" :class="$style.tooltip">
                  <p :class="$style.tooltipItem" @click="showPdf(item.invoiceDocumentId)"><img :class="$style.icon" src="~@/assets/icons/basic/check-eye.svg" alt="">Посмотреть квитанцию</p>
                  <p :class="$style.tooltipItem" @click.prevent="pdfDownload(item.invoiceDocumentId, item.name)"><img :class="$style.icon" src="~@/assets/icons/basic/pdf.svg" alt="">Скачать PDF</p>
                  <p :class="$style.tooltipItem"><img :class="$style.icon" src="~@/assets/icons/basic/chatbot-sign-mini-black.svg" alt="">Помощь</p>
                  <p v-if="item.__typename === 'MesElectricityInvoice' && !item.status" :class="$style.tooltipItem" @click="testpay({amount: item.amount, number: item.invoiceNumber})">Оплатить(тест)</p>
                </div>
            </tooltip-menu>
          </div>

          <p :class="$style.title">{{ item.name }}</p>

          <p :class="$style.secondTitle">Счёт <span v-if="item.__typename === 'MesElectricityInvoice'">{{ item.invoiceNumber }}</span> от {{ item.invoiceDate }}</p>

          <div style="margin-top: 10px">
            <p :class="$style.text">Получатель: {{ item.payeeName }}</p>
            <p :class="$style.text">ИНН {{ item.payeeInn }}</p>
            <p v-if="item.__typename === 'MesElectricityInvoice'" :class="$style.text">Лицевой счёт {{ item.personalAccount }}</p>
          </div>

          <div v-if="item.status" style="margin-top: 20px;">
            <div :class="$style.statusGroup">
              <span :class="$style.payed">Оплачен <br> {{ item.paymentDate }}</span>
              <span :class="$style.price">{{ item.amount }} ₽</span>
            </div>
          </div>

          <div v-else style="margin-top: 20px;">
            <div :class="$style.statusGroup">
              <span :class="$style.payedTime">Срок оплаты <br /> 5 рабочих дней</span>
              <span :class="$style.price">{{ item.amount }} ₽</span>
            </div>
          </div>

        </div>

        <div v-if="!item.status" :class="$style.btnGroup">
          <button :class="$style.btn" @click="showPdf(item.invoiceDocumentId)">Квитанция</button>
          <button :class="$style.btn" @click="openPaymentUrl">Оплатить</button>
        </div>

      </div>
      <modal-progress-bar
        :is-modal-visible="isModalVisible"
        :is-complete="isProcessComplete"
        @close="isModalVisible = false"
      />
    </div>
</template>

<script>
import generateXml from 'lib/storage/connection-application/data';
import { mapActions } from 'vuex';
import { getDocument } from 'lib/utils/downloadDocuments';
import { delay } from '@/utils/delay';
import { downloadPdf, showPdf } from 'lib/utils/pdf';
import ModalProgressBar from '@/components/ModalProgressBar/ModalProgressBar';

export default {
  name: 'CabinetPay',
  components: {
    TooltipMenu: () => import('molecules/tooltip/TooltipMenu'),
    ModalProgressBar,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isModalVisible: false,
      isProcessComplete: false,
    };
  },
  methods: {
    async openPaymentUrl() {
      await this.createFixturePayment({ invoiceId: this.item.id });
      location.reload();
      // TODO временно создается фикстура, оплата по ссылке будет доступна после фикса платежного шлюза;
      // const paymentUrl = await this.createPaymentUrl({ params: { invoiceId: this.item.id } });
      // window.open(paymentUrl, '_self');
    },
    // TODO убрать при релизе
    async testpay({ amount, number, date }) {
      date = new Date();
      date = date.toISOString().split('T')[0];
      const xml = generateXml.mesTransaction(amount, number, date);
      console.log(xml);
      try {
        await this.documentUpload({ xml: xml });
        this.$store.state.info = await this.paymentInfo();
      } catch (e) {
        console.log('error', e.message);
      }
    },
    async pdfDownload(documentId, name) {
      try {
        this.isModalVisible = true;
        const document = await getDocument(documentId);
        this.isProcessComplete = true;
        await delay(3250);
        downloadPdf(document, name);
      } catch (e) {
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    async showPdf(documentId) {
      try {
        this.isModalVisible = true;
        const document = await getDocument(documentId);
        this.isProcessComplete = true;
        await delay(3250);
        showPdf(document);
      } catch (e) {
        alert('Ошибка загрузки файла');
      } finally {
        this.isProcessComplete = false;
        this.isModalVisible = false;
      }
    },
    ...mapActions('cabinet', ['createPaymentUrl']),
    ...mapActions('document', ['documentUpload', 'createFixturePayment']),
    ...mapActions('payment', ['paymentInfo']),
    ...mapActions('document', ['loadDocument', 'getDomainDocument']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 452px
  position: relative
  background-color: #fff
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
  margin-bottom: 16px
  margin-right: 16px

.content
  display: flex
  flex-direction: column
  align-items: space-between
  padding: 16px 32px 16px 16px

.tooltip
  width: 269px
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2)
  padding: 18px 32px 18px 24px

.tooltipItem
  margin-bottom: 10px

  &:last-child
    margin-bottom: 0

.icon
  margin-right: 15px
  width: 18px
  height: 18px

.title
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 24px
  line-height: 32px
  text-transform: uppercase
  max-width: 270px

.secondTitle
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  margin-top: 8px

.text
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 18px

.moreIcon
  position: absolute
  top: 8px
  right: 8px
  cursor: pointer

.statusGroup
  display: flex
  justify-content: space-between

.btn
  width: 50%
  height: 40px
  margin-right: 2px
  text-align: center
  padding: 5px 16px
  background-color: #71757A
  color: white
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 21px
  line-height: 30px

  &:last-child
    margin-right: 0
    background-color: #3894FB

.btnGroup
  display: flex

.payed
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #54B687
  text-transform: uppercase
  transform: translateY(10px)
  max-width: 150px

.payedTime
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  color: #2F82DF
  text-transform: uppercase
  transform: translateY(10px)
  max-width: 150px

.price
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 36px
  line-height: 52px
</style>
