<template>
  <div id="app">
    <portal :order="1" to="modal">
      <router-view/>
      <notification-controller/>
      <notification-modal/>
      <select-certificate/>
    </portal>

    <portal-target multiple name="modal" style="width: 100%; height: 100%;">

    </portal-target>


  </div>
</template>

<script>
export default {
  name: 'Platforma',
  components: {
    NotificationModal: () => import('components/NotificationModal/NotificationModal'),
    NotificationController: () => import('organisms/notification/NotificationController.vue'),
    SelectCertificate: () => import('molecules/certificate/SelectCertificate'),
  },
};
</script>

<style lang="sass">
html, body
  height: 100vh
  width: 100vw
  margin: 0
  padding: 0

body::-webkit-scrollbar
  display: none

#app
  height: 100%
  width: 100vw
  position: relative

.tabs-component
  position: absolute
  width: 100%
  margin-bottom: 64px
  padding: 0 5px 0 0

.tabs-component-tabs
  border: solid 1px #e00000
  border-radius: 6px
  margin-bottom: 5px


@media (min-width: 700px)
  .tabs-component-tabs
    border: 0
    align-items: stretch
    display: flex
    justify-content: flex-start
    margin-bottom: -1px


.tabs-component-tab
  color: #999
  font-size: 14px
  font-weight: 600
  margin-right: 0
  list-style: none


.tabs-component-tab:not(:last-child)
  border-bottom: dotted 1px #ddd


.tabs-component-tab:hover
  color: #666


.tabs-component-tab.is-active
  color: #000


.tabs-component-tab.is-disabled *
  color: #cdcdcd
  cursor: not-allowed !important


@media (min-width: 700px)
  .tabs-component-tab
    background-color: #fff
    border: solid 1px #ddd
    border-radius: 3px 3px 0 0
    margin-right: .5em
    transform: translateY(2px)
    transition: transform .3s ease


.tabs-component-tab.is-active
  border-bottom: solid 1px #fff
  z-index: 2
  transform: translateY(0)

.v--modal-overlay
  background: none !important
  width: 0 !important

.v--modal-background-click
  width: 0 !important

.scrollable
  overflow-y: initial !important

.tabs-component-tab-a
  align-items: center
  color: inherit
  display: flex
  padding: .75em 1em
  text-decoration: none


.tabs-component-panels
  padding: 4em 2em 4em 0

@media (min-width: 700px)
  .tabs-component-panels
    border-top-left-radius: 0
    background-color: #fff
    border: solid 1px #ddd
    border-radius: 0 6px 6px 6px
    box-shadow: 0 0 10px rgba(0, 0, 0, .05)
    padding: 4em 2em 4em 0

.table-container-scrollable
  overflow: auto
  width: calc(100vw - 370px)

  &-child
    min-width: 730px
</style>
