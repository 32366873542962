import { apolloClient } from '@/api/graphql';
import {
  ALL_METER,
  ALL_USAGE_POINTS,
  ALL_USAGE_POINTS_BY_FILTER_PAGED,
  METER,
  POINTS_WITH_MEASUREMENTS,
  USAGE_POINT_BY_ID_AND_REVISION,
  ALL_METERS_BY_FILTER_PAGED,
  ALL_USAGE_POINTS_MEASUREMENTS_BY_FILTER_PAGED,
  ALL_NEW_USAGE_POINTS,
} from '@/api/graphql/queries';

// TODO: actions which use for ONLY request without commit to store - not good practice
async function loadPoints({ rootState, commit }) {
  try {
    const {
      data: { allUsagePoints },
    } = await apolloClient.query({
      query: ALL_USAGE_POINTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadPoints', allUsagePoints);
    return allUsagePoints;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPoints::catch', error);
    throw error;
  }
}

async function loadPointsByFilterPaged({ rootState, commit }, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: ALL_USAGE_POINTS_BY_FILTER_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadPointsByFilterPaged', { ...data.allUsagePointsByFilterPaged });
    return { ...data.allUsagePointsByFilterPaged };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPointsByFilterPaged::catch', error);
    throw error;
  }
}

async function loadUsagePoint({ rootState, commit }, payload) {
  try {
    const {
      data: { usagePoint },
    } = await apolloClient.query({
      query: USAGE_POINT_BY_ID_AND_REVISION,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('usagePoint', usagePoint);
    return usagePoint;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('usagePoint::catch', error);
    throw error;
  }
}

async function loadPointsWithMeasurements({ rootState, commit }, kindList) {
  try {
    const {
      data: { usagePointsByKind },
    } = await apolloClient.query({
      query: POINTS_WITH_MEASUREMENTS,
      fetchPolicy: 'no-cache',
      variables: {
        kind: kindList,
      },
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadPointsWithMeasurements', usagePointsByKind);
    return usagePointsByKind;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPointsWithMeasurements::catch', error);
    throw error;
  }
}

async function loadPointsWithMeasurementsByFilterPaged({ rootState, commit }, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: ALL_USAGE_POINTS_MEASUREMENTS_BY_FILTER_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadPointsWithMeasurementsByFilterPaged', { ...data.allUsagePointsByFilterPaged });
    return { ...data.allUsagePointsByFilterPaged };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPointsWithMeasurementsByFilterPaged::catch', error);
    throw error;
  }
}

async function meter({ rootState, commit }, payload) {
  try {
    const {
      data: { meter },
    } = await apolloClient.query({
      query: METER,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
      },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('meter', meter);
    return meter;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('meter::catch', error);
    throw error;
  }
}

async function allMeters({ rootState, commit }) {
  try {
    const {
      data: { allMeters },
    } = await apolloClient.query({
      query: ALL_METER,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return allMeters;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('allMeters::catch', error);
    throw error;
  }
}

async function allMetersByFilterPaged({ rootState, commit }, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: ALL_METERS_BY_FILTER_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('allMetersByFilterPaged', { ...data.allMetersByFilterPaged });
    return { ...data.allMetersByFilterPaged };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('allMetersByFilterPaged::catch', error);
    throw error;
  }
}

async function allNewUsagePoints(store, payload) {
  try {
    const {
      data: { allNewUsagePoints },
    } = await apolloClient.query({
      query: ALL_NEW_USAGE_POINTS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          Authorization: `Bearer ${store.rootState.user.token}`,
        },
      },
    });
    return allNewUsagePoints;
  } catch (error) {
    console.log('allNewUsagePoints::catch', error);
    throw error;
  }
}

export default {
  loadPoints,
  loadPointsByFilterPaged,
  loadPointsWithMeasurements,
  meter,
  allMeters,
  loadUsagePoint,
  allMetersByFilterPaged,
  loadPointsWithMeasurementsByFilterPaged,
  allNewUsagePoints,
};
