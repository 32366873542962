<template>
  <input-action
    v-model="date"
    :label="label"
    :is-enabled="isEnabled && (isValidPhone || isValidEmail)"
    :note="note"
    :is-show-note="isShowNote"
    :error-message="errorMessage"
    :error-code="errorCode"
    :is-waiting="isWaiting"
    :maxlength="256"
    :role="role"
    :is-restore="isRestore"
    @input="onInput"
    @keyup="onKeyup"
    @action="onAction"
    @password="changePassword"
    @back="onBack"
  />
</template>

<script>
import InputAction from './InputAction.vue';
import formatting from '../mixins/formatting.js';

export default {
  name: 'InputContactAction',
  mixins: [formatting],
  components: { 'input-action': InputAction },
  props: [
    'isEnabled',
    'note',
    'isShowNote',
    'errorMessage',
    'errorCode',
    'value',
    'isWaiting',
    'role',
    'title',
    'isRestore',
  ],
  data() {
    return {
      date: this.value,
      isValidPhone: false,
      isValidEmail: false,
    };
  },
  computed: {
    label() {
      if (!this.isShowNote && this.isValidEmail) {
        return 'Код придет на почту';
      }
      if (!this.isShowNote && this.isValidPhone) {
        return 'СМС придет на номер';
      }
      return this.title ?? 'Войти с помощью: Телефон / E-mail';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isValidEmail = this.isEmail(this.date);

        const phone = this.phoneFormat(this.date);
        this.isValidPhone = phone.isPhone;

        if (phone.isPhone) {
          this.date = phone.formattedPhone;
        } else {
          if (oldVal && this.isPhone(oldVal) && newVal.length > 1 && newVal[newVal.length - 1] === '@') {
            this.date = this.unformatPhone(newVal);
            return;
          }
          this.date = newVal;
        }
      },
    },
  },
  methods: {
    onInput() {

      this.$emit('input', this.date);
    },
    onKeyup(e) {
      this.$emit('keyup', e);
    },
    onAction() {
      this.$emit('action');
    },
    onBack() {
      this.$emit('back');
    },
    changePassword(password) {
      this.$emit('password', password);
    },
  },
};
</script>

<style scoped>
</style>
