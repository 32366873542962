<template>
  <div :class="$style.container">
    <p :class="$style.title">Лицевой счёт</p>
    <p :class="$style.balance">0 P</p>
    <p :class="$style.cardTitle">Привязана карта</p>
    <p :class="$style.cardNumber">VISA XXXX XXXX XXXX XXXX</p>
    <p :class="$style.autoPay">Автоплатёж </p>
  </div>
</template>

<script>
export default {
  name: 'PersonalAccount',
};
</script>

<style lang="sass" module>
  .container
    width: 330px
    padding: 16px 16px 0 24px

  .title
    +base-subtitle

  .balance
    margin-bottom: 22px
    +card-num-huge

  .cardTitle
    margin-bottom: 18px
    +base-card-text

  .cardNumber
    margin-bottom: 18px
    +card-title-small

  .autoPay
    +base-card-text

</style>
