<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-text>
            <axp-photos-carousel :axp-id="exploitation.axpId" :mode="axpPhotosCarouselMode"/>
            <text-field
              ref="adreskaName"
              v-model="exploitation.adreskaName"
              is-disabled
              title="Программа оформления"
            ></text-field>
            <text-field
              ref="address"
              v-model="exploitation.address"
              is-disabled
              title="Адрес установки"
            ></text-field>
            <text-field
              ref="address"
              v-model="exploitation.backupAddress"
              is-disabled
              title="Резервный адрес установки"
            ></text-field>

            <div class="mb-2">
              <v-label>Расположение на местности</v-label>
            </div>
            <yandex-map
              ref="customMap"
              :collection-axp="axpItems"
              @tapPlacemark="onTapPlacemark"
            />


            <text-field
              ref="quantityPlan"
              v-model="exploitation.quantityPlan"
              is-disabled
              title="Количество план"
            ></text-field>

            <text-field
              ref="quantityFact"
              v-model="exploitation.quantityFact"
              is-disabled
              title="Количество факт"
            ></text-field>

            <text-field
              ref="adreskaName"
              v-model="exploitation.odkName"
              is-disabled
              title="Наименование ОДК"
            ></text-field>

            <text-field
              ref="axpName"
              v-model="exploitation.axpName"
              is-disabled
              title="Наименование АХП"
            ></text-field>

            <text-field
              ref="connectionPoint"
              v-model="exploitation.connectionPoint"
              is-disabled
              title="Точка подключения"
            ></text-field>

            <text-field
              ref="addressConnectionPoint"
              v-model="exploitation.addressConnectionPoint"
              is-disabled
              title="Место установки автовыключателя, коммутирующего ОДК"
            ></text-field>

            <div style="margin-bottom: 24px; font-size: 16px; color: rgba(0, 0, 0, 0.6)">
              <input v-model="exploitation.isConnectedGenerator" disabled type="checkbox">&nbsp;Возможность подключения
              ДГК / Бензогенератора
            </div>

            <div style="margin-bottom: 24px; font-size: 16px; color: rgba(0, 0, 0, 0.6)">
              <input v-model="exploitation.isConnectedElectricity" disabled type="checkbox">&nbsp;Подключено к
              электропитанию
            </div>

            <div style="margin-bottom: 24px; font-size: 16px; color: rgba(0, 0, 0, 0.6)">
              <input v-model="exploitation.readyForInput" disabled type="checkbox">&nbsp;Готово к вводу/включению
            </div>

            <text-field
              ref="switchBoard"
              v-model="exploitation.switchBoard"
              is-disabled
              title="ТМ ВРШ"
            ></text-field>

            <text-field
              ref="administrativeDistrict"
              v-model="exploitation.administrativeDistrictName"
              is-disabled
              title="Административный округ"
            ></text-field>

            <text-field
              ref="responsibleDistrictDepartmentName"
              v-model="exploitation.responsibleDistrictDepartmentName"
              is-disabled
              title="Ответственный РЭС"
            ></text-field>

            <text-field
              ref="illuminationManagerPhone"
              v-model="exploitation.illuminationManagerPhone"
              is-disabled
              title="Телефон диспетчера по иллюминации"
            ></text-field>

            <text-field
              ref="contractor"
              v-model="exploitation.contractorName"
              is-disabled
              title="Подрядчик"
            ></text-field>

            <text-field
              v-for="(responsible, idx) in exploitation.responsibles"
              :key="idx"
              ref="responsibleContractorEmployee"
              :text="`${responsible.name}: ${formatting.phoneFormat(responsible.phone).formattedPhone}`"
              :title="`Ответственный подрядчик ${idx+1}`"
              is-disabled
            ></text-field>

            <text-field
              ref="power"
              v-model="exploitation.axpPower"
              is-disabled
              title="Мощность"
            ></text-field>

            <text-field
              ref="workModePlan"
              v-model="exploitation.workModePlan"
              is-disabled
              title="Режим работы ОДК по плану"
            ></text-field>

            <text-field
              ref="workModePlan"
              v-model="exploitation.workModeFact"
              is-disabled
              title="Режим работы ОДК по факту"
            ></text-field>

            <text-field
              ref="installationCompletionPercentage"
              v-model="exploitation.mountedByContractorPercent"
              is-disabled
              title="Монтаж, %"
            ></text-field>

            <text-field
              ref="dismantling"
              v-model="exploitation.dismantledPercent"
              is-disabled
              title="Демонтаж, %"
            ></text-field>

            <text-field
              ref="combustion"
              v-model="exploitation.lightPercent"
              is-disabled
              title="Горение, %"
            ></text-field>

            <text-field
              ref="planInstallationStartDate"
              v-model="exploitation.planInstallationStartDate"
              is-disabled
              title="Планируемая дата монтажа"
            ></text-field>

            <text-field
              ref="planDismantlingStartDate"
              v-model="exploitation.planDismantlingStartDate"
              is-disabled
              title="Планируемая дата демонтажа"
            ></text-field>

            <text-field
              ref="firstInspectionDate"
              v-model="exploitation.firstInspectionDate"
              is-disabled
              title="Фактическая дата осмотра"
            ></text-field>

            <text-field
              ref="lastInspectionDate"
              v-model="exploitation.lastInspectionDate"
              is-disabled
              title="Последняя дата осмотра"
            ></text-field>

            <text-field
              ref="remark"
              v-model="exploitation.remark"
              is-disabled
              title="Примечание"
            ></text-field>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { fetchData } from '@/api/rest/odk/api';
import TextField from '@/components/Processes/components/TextField.vue';
import { workModeList } from '@/components/Processes/list';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import { BASE_URL } from '@/constants/api';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import { axpPhotosCarouselMode } from 'components/Processes/components/AxpPhotosCarousel/constants';
import { mapGetters } from 'vuex';
import { isAccessDefectAssignment, isAccessInspectionOdk } from 'lib/utils/permissions';
import formatting from 'lib/utils/formatting';

export default {
  name: 'ExploitationData',
  components: {
    AxpPhotosCarousel,
    YandexMap,
    TextField,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      axpPhotosCarouselMode: axpPhotosCarouselMode.IMAGE,
      loading: false,
      exploitation: {},
      axpItems: [],
      isDisplayImage: false,
      contractorName: '',
      contractors: [],
      workModeItems: workModeList,
    };
  },
  computed: {
    formatting() {
      return formatting;
    },
    canAssignWithoutInspection() {
      return isAccessDefectAssignment(this.getAuthorization.blockAccess);
    },
    canInspect() {
      return isAccessInspectionOdk(this.getAuthorization.blockAccess);
    },
    ...mapGetters('user', ['getAuthorization']),
  },
  methods: {
    async onTapPlacemark(id) {
      return await this.$router.push({
        name: 'cabinet.process.11.5-7',
        params: {
          initAdreskaFactId: id,
        },
      });
    },
    async loadData() {
      try {
        this.loading = true;

        if (!this.$route.query.id) {
          this.$router.back();
          return;
        }

        const responseData = await fetchData('adreska-summary', 1, 10, undefined, undefined, { adreskaId: this.$route.query.id });

        if (!responseData?.data || responseData?.data.length === 0) {
          this.$router.back();
          return;
        }

        this.exploitation = responseData?.data[0];
        this.exploitation.planDismantlingStartDate = formatDateFromOdkTimestamp(this.exploitation.planDismantlingStartDate);
        this.exploitation.planInstallationStartDate = formatDateFromOdkTimestamp(this.exploitation.planInstallationStartDate);
        if (this.exploitation.firstInspectionDate) this.exploitation.firstInspectionDate = formatDateFromOdkTimestamp(this.exploitation.firstInspectionDate);
        if (this.exploitation.lastInspectionDate) this.exploitation.lastInspectionDate = formatDateFromOdkTimestamp(this.exploitation.lastInspectionDate);
        let label = null;
        if (this.canInspect) label = 'Осмотреть';
        if (this.canAssignWithoutInspection) label = 'Внести дефект';
        fetch(`${BASE_URL}/adreskafact?adreskaId=${this.exploitation.adreskaId}`).then((response) => response.json()).then((data) => {
          if (data.length) {
            this.axpItems = data.map((it) => ({
              ...it,
              balloonContent: {
                title: it.axp.odk.name,
                buttonLabel: label,
                props: [
                  {
                    label: 'ID',
                    value: it.id,
                  },
                  {
                    label: 'АХП',
                    value: it.axp.name,
                  },
                  {
                    label: 'Тип декора',
                    value: it.decorType?.name,
                  },
                  {
                    label: 'Подрядчик',
                    value: it.adreska.contractorName,
                  },
                  {
                    label: 'Дата монтажа',
                    value: formatDateFromOdkTimestamp(it.createDate),
                  },
                ],
              },
            }));
          } else {
            this.axpItems = [{
              id: 0,
              locationLatitude: this.exploitation.adreskaLocationLatitude,
              locationLongitude: this.exploitation.adreskaLocationLongitude,
              description: '',
              statusInspection: 0,
            }];
          }
        })
        ;
      } catch (error) {
        console.log('adreskaSummary', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid";
@import "@/assets/scss/commonCardStyles";

.files-carousel {
  &__image {
    min-height: 10vh;
    max-height: 75vh;
    max-width: 80vw;
  }
}
</style>
