<template>
  <div class="cabinet-service-acts-generate">
    <navigation-bar :current-step="0"
                    :steps="steps"
                    home="Акты оказания услуг по передаче электрической энергии"
                    is-display-back
                    @open="goBack" />
    <v-divider />

    <div class="cabinet-service-acts-generate__content">
      <h1 class="cabinet-service-acts-generate__content__header">Формирование акта</h1>
      <h3 class="cabinet-service-acts-generate__content__subtitle">За расчётный период</h3>

      <v-divider class="cabinet-service-acts-generate__content__divider" />

      <div class="cabinet-service-acts-generate__content_period">
        <div class="cabinet-service-acts-generate__content__title">
          Выбрать период:
        </div>
        <v-date-picker
            v-model="currentDate"
            format="MMMM | YYYY"
            type="month"
        />
      </div>

      <v-divider class="cabinet-service-acts-generate__content__divider" />

      <v-simple-indicator class="cabinet-service-acts-generate__content__indicator" :color="indicators.balance.color" label="Баланс подписан" />

      <v-divider class="cabinet-service-acts-generate__content__divider" />

      <v-circle-loader v-show="isLoading" />

      <template v-if="actIndicators?.creationByBalance">
        <h2 class="cabinet-service-acts-generate__content__title">Параметры акта</h2>

        <div class="cabinet-service-acts-generate__content__act__kind">
          <v-select
              v-model="actKind"
              :options="actKindList"
              title="Тип акта"
          />
        </div>

        <table class="cabinet-service-acts-generate__content__table">
          <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Показатель</th>
            <th>Мощность, Мвт</th>
            <th>Объемы, кВт*ч</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input v-model="settledVolume"
                     class="cabinet-service-acts-generate__content__checkbox"
                     type="checkbox"
              />
            </td>
            <td>
              Урегулированные объемы
            </td>
            <td>
              {{ balanceVolumes.power.settledVolume }}
            </td>
            <td>
              {{ balanceVolumes.volumes.settledVolume }}
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="unsettledVolume"
                     class="cabinet-service-acts-generate__content__checkbox"
                     type="checkbox"
              />
            </td>
            <td>
              Не урегулированные объемы
            </td>
            <td>
              {{ balanceVolumes.power.unsettledVolume }}
            </td>
            <td>
              {{ balanceVolumes.volumes.unsettledVolume }}
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="inProcessVolume"
                     class="cabinet-service-acts-generate__content__checkbox"
                     type="checkbox"
              />
            </td>
            <td>
              В процессе урегулирования
            </td>
            <td>
              {{ balanceVolumes.power.inProcessVolume }}
            </td>
            <td>
              {{ balanceVolumes.volumes.inProcessVolume }}
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="newVolume"
                     class="cabinet-service-acts-generate__content__checkbox"
                     type="checkbox"
              />
            </td>
            <td>
              Новые
            </td>
            <td>
              {{ balanceVolumes.power.newVolume }}
            </td>
            <td>
              {{ balanceVolumes.volumes.newVolume }}
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="disagreementVolume"
                     class="cabinet-service-acts-generate__content__checkbox"
                     type="checkbox"
              />
            </td>
            <td>
              Имеют разногласия
            </td>
            <td>
              {{ balanceVolumes.power.disagreementVolume }}
            </td>
            <td>
              {{ balanceVolumes.volumes.disagreementVolume }}
            </td>
          </tr>

          </tbody>
        </table>

        <v-divider class="cabinet-service-acts-generate__content__divider" />

        <h2 class="cabinet-service-acts-generate__content__title">Стороны</h2>

        <div class="cabinet-service-acts-generate__content__sides">
          <v-select
              v-model="performerFirstInn"
              :options="performerFirstList"
              title="Исполнитель 1"
              is-show-input-img
              class="cabinet-service-acts-generate__content__sides__select"
          />
          <v-select
              v-model="performerSecondInn"
              :options="performerSecondList"
              title="Исполнитель 2"
              is-show-input-img
              class="cabinet-service-acts-generate__content__sides__select"
          />
        </div>

        <v-divider class="cabinet-service-acts-generate__content__divider" />

        <h2 class="cabinet-service-acts-generate__content__title">Подписывающие лица</h2>

        <v-row class="cabinet-service-acts-generate__signatories">
          <v-col :grid="{ xs: 2.5 }" class="cabinet-service-acts-generate__signatories__col-wrapper">
            <p class="cabinet-service-acts-generate__content__subtitle">Подписывающее лицо
              {{ performerFirstName }}*</p>
            <emploee-select-or-input :inn="performerFirstInn" permission="SVET_A5-S"
                                     @emploeeSelectOrInput="updatePerformerFirstSignatory" />
          </v-col>
        </v-row>
        <v-row class="cabinet-service-acts-generate__signatories">
          <v-col :grid="{ xs: 2.5 }" class="cabinet-service-acts-generate__signatories__col-wrapper">
            <p class="cabinet-service-acts-generate__content__subtitle">Подписывающее лицо
              {{ performerSecondName }}*</p>
            <emploee-select-or-input :inn="performerSecondInn" permission="SVET_A5-S"
                                     @emploeeSelectOrInput="updatePerformerSecondSignatory" />
          </v-col>
        </v-row>

        <v-divider class="cabinet-service-acts-generate__content__divider" />
        <horizontal-progress-bar v-if="isWaitingSubmit" style="margin-bottom: 12px"/>
      </template>

      <v-row class="generate-integral-act__buttons">
        <v-col>
          <v-button style="color: #0E0F0F; background: #FFFFFF; border: 1px solid #C1C4C7;"
                    @click="goBack">
            Отменить
          </v-button>

        </v-col>
        <v-col style="display:flex; justify-content: right">
          <div>
            <v-button
                :disabled="notValid"
                style="border: 1px solid;"
                @click="generateActClick"
            >
              Сформировать акт
            </v-button>
          </div>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import {
  EmploeeSelectOrInput,
  NavigationBar,
  VButton,
  VCircleLoader,
  VCol,
  VDatePicker,
  VRow,
  VSelect,
} from '@/components';
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import { getDateAsForceISOString } from 'lib/utils/date';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import formatting from 'lib/utils/formatting';
import VSimpleIndicator from 'components/VSimpleIndicator/VSimpleIndicator.vue';

export default {
  name: 'CabinetServiceActsGenerate',
  components: {
    EmploeeSelectOrInput,
    VDatePicker,
    VButton,
    VCol,
    VRow,
    HorizontalProgressBar,
    VSelect,
    NavigationBar,
    VSimpleIndicator,
    VCircleLoader,
  },
  data() {
    return {
      isWaitingSubmit: false,
      actIndicators: undefined,
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      performerFirstSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleNameGenitive: {},
      },
      performerSecondSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
      steps: [
        {
          stepNumber: {
            value: 1,
            title: () => 'Формирование акта',
          },
        },
      ],
      isLoading: false,
      newVolume: false,
      settledVolume: false,
      unsettledVolume: false,
      inProcessVolume: false,
      disagreementVolume: false,
      performerSecondInn: '',
      performerFirstInn: '',
      actKind: '',
      actKindList: [
        {
          label: 'Одноставочный',
          value: 'ONE_PART',
        },
        {
          label: 'Двухставочный',
          value: 'TWO_PART',
        },
      ],
    };
  },
  computed: {
    notValid() {
      const notValidPerformerFirst = !this.checkRepresentativeInput(this.performerFirstSignatory);
      const notValidPerformerSecond = !this.checkRepresentativeInput(this.performerSecondSignatory);
      const other = this.isWaitingSubmit || this.actIndicators === undefined || !this.indicators.balance;
      return notValidPerformerFirst || notValidPerformerSecond || other || this.actKind.length === 0;
    },
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.actIndicators) {
        return {
          balance: unknown,
        };
      }

      const result = {};

      result.balance = this.actIndicators.isBalanceSigned ? check : error;

      return result;
    },
    onePartBalanceVolumes() {
      return {
        volumes: {
          newVolume: this.numberFormatted(this.actIndicators?.onePartVolumes?.newVolume),
          settledVolume: this.numberFormatted(this.actIndicators?.onePartVolumes?.settledVolume),
          unsettledVolume: this.numberFormatted(this.actIndicators?.onePartVolumes?.unsettledVolume),
          inProcessVolume: this.numberFormatted(this.actIndicators?.onePartVolumes?.inProcessVolume),
          disagreementVolume: this.numberFormatted(this.actIndicators?.onePartVolumes?.disagreementVolume),
        },
        power: {
          newVolume: '',
          settledVolume: '',
          unsettledVolume: '',
          inProcessVolume: '',
          disagreementVolume: '',
        },
      };
    },
    twoPartBalanceVolumes() {
      return {
        volumes: {
          newVolume: this.numberFormatted(this.actIndicators?.twoPartVolumes?.newVolume),
          settledVolume: this.numberFormatted(this.actIndicators?.twoPartVolumes?.settledVolume),
          unsettledVolume: this.numberFormatted(this.actIndicators?.twoPartVolumes?.unsettledVolume),
          inProcessVolume: this.numberFormatted(this.actIndicators?.twoPartVolumes?.inProcessVolume),
          disagreementVolume: this.numberFormatted(this.actIndicators?.twoPartVolumes?.disagreementVolume),
        },
        power: {
          newVolume: this.numberFormatted(this.actIndicators?.twoPartPower?.newVolume),
          settledVolume: this.numberFormatted(this.actIndicators?.twoPartPower?.settledVolume),
          unsettledVolume: this.numberFormatted(this.actIndicators?.twoPartPower?.unsettledVolume),
          inProcessVolume: this.numberFormatted(this.actIndicators?.twoPartPower?.inProcessVolume),
          disagreementVolume: this.numberFormatted(this.actIndicators?.twoPartPower?.disagreementVolume),
        },
      };
    },
    balanceVolumes() {
      if (this.actKind === 'ONE_PART') {
        return this.onePartBalanceVolumes;
      }

      if (this.actKind === 'TWO_PART') {
        return this.twoPartBalanceVolumes;
      }

      return {
        volumes: {
          newVolume: '',
          settledVolume: '',
          unsettledVolume: '',
          inProcessVolume: '',
          disagreementVolume: '',
        },
        power: {
          newVolume: '',
          settledVolume: '',
          unsettledVolume: '',
          inProcessVolume: '',
          disagreementVolume: '',
        },
      };
    },
    performerFirstList() {
      if (!this.actIndicators?.creationByBalance?.performerFirst) {
        return [];
      }

      return [{
        label: this.actIndicators.creationByBalance.performerFirst.name,
        value: this.actIndicators.creationByBalance.performerFirst.inn,
      }];
    },
    performerSecondList() {
      if (!this.actIndicators?.creationByBalance?.performerSecond) {
        return [];
      }

      return [{
        label: this.actIndicators.creationByBalance.performerSecond.name,
        value: this.actIndicators.creationByBalance.performerSecond.inn,
      }];
    },
    performerFirstName() {
      return this.performerFirstList.filter((item) => item.value === this.performerFirstInn)[0]?.label ?? '';
    },
    performerSecondName() {
      return this.performerSecondList.filter((item) => item.value === this.performerSecondInn)[0]?.label ?? '';
    },
  },
  watch: {
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.isLoading = true;

          this.actIndicators = undefined;
          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          this.actIndicators = await this.loadServiceAndLossActIndicators({
            year: date.year(),
            month: date.month() + 1,
          });

          this.isLoading = false;
          console.log('this.actIndicators', this.actIndicators);
        }
      },
    },
    performerFirstList(val) {
      if (val && val.length > 0) {
        this.performerFirstInn = val[0].value;
      }
    },
    performerSecondList(val) {
      if (val && val.length > 0) {
        this.performerSecondInn = val[0].value;
      }
    },
  },
  methods: {
    ...mapActions('cabinet', ['loadServiceAndLossActIndicators']),
    checkRepresentativeInput(signatory) {
      if (signatory.userId) {
        return signatory.reason.name === 'Устав' || signatory.powerOfAttorneyId;
      }
      return signatory.fullName?.name?.length > 0 &&
        signatory.fullName?.surname?.length > 0 &&
        signatory.role.name?.length > 0 &&
        signatory.reason.name?.length > 0 &&
        (signatory.reason.name === 'Устав' || (signatory.reason.date && signatory.reason.number));
    },
    updateSignatory(signatory, selected) {
      signatory.userId = selected.userId;
      signatory.role = {
        id: selected.role?.id,
        name: selected.role?.name,
      };
      signatory.reason = {
        name: selected.reason?.reasonDocument,
        number: selected.reason?.number,
        date: selected.reason?.date,
      };
      signatory.fullName = {
        name: selected.fullName?.name,
        surname: selected.fullName?.surname,
        patronymic: selected.fullName?.patronymic,
      };
      signatory.fullNameGenitive = {
        name: selected.fullNameGenitive?.name,
        surname: selected.fullNameGenitive?.surname,
        patronymic: selected.fullNameGenitive?.patronymic,
      };
      signatory.powerOfAttorneyId = selected.reason?.id;
      signatory.accountId = selected.accountId;
    },
    updatePerformerFirstSignatory(selected) {
      this.updateSignatory(this.performerFirstSignatory, selected);
    },
    updatePerformerSecondSignatory(selected) {
      this.updateSignatory(this.performerSecondSignatory, selected);
    },
    createRepresentativeInput(signatory) {
      return signatory.userId ? {
        digital: {
          userId: signatory.userId,
          roleId: signatory.role.id,
          powerOfAttorneyId: signatory.powerOfAttorneyId,
        },
      } : {
        simple: {
          person: {
            name: signatory.fullName.name,
            surname: signatory.fullName.surname,
            patronymic: signatory.fullName.patronymic,
          },
          positionName: signatory.role.name,
          reasonDocument: {
            reasonDocumentName: signatory.reason.name,
            date: signatory.reason.date ? getDateAsForceISOString(signatory.reason.date) : null,
            number: signatory.reason.number,
          },
        },
      };
    },
    goBack() {
      this.$router.push('/cabinet/service');
    },
    async generateActClick() {

      if (!this.actIndicators) {
        return;
      }

      if (!this.actIndicators.isBalanceSigned) {

        const errorMessage = 'Баланс не подписаны. Прошу подписать баланс.';

        this.setNotification({ message: errorMessage });
        return;
      }

      this.isWaitingSubmit = true;
      const date = dayjs(this.currentDate, 'MMMM | YYYY');

      try {
        await this.generateServiceAct({
          year: date.year(),
          month: date.month() + 1,
          performerFirstRepresentative: this.createRepresentativeInput(this.performerFirstSignatory),
          performerSecondRepresentative: this.createRepresentativeInput(this.performerSecondSignatory),
          balanceId: this.actIndicators.creationByBalance.id,
          volumesByPointStatus: this.getVolumesByPointStatus(),
          actKind: this.actKind,
        });
        this.goBack();
      } catch (e) {
        this.setNotification({ message: 'При формировании акта оказания услуг по передаче электрической энергии' });
        this.isWaitingSubmit = false;
        return;
      }

      this.isWaitingSubmit = false;
    },
    numberFormatted(val) {
      if (!val) {
        return '';
      }
      return formatting.numberFormatted(val);
    },
    getVolumesByPointStatus() {
      const result = [];

      if (this.newVolume) {
        result.push('NEW');
      }
      if (this.settledVolume) {
        result.push('SETTLED');
      }
      if (this.unsettledVolume) {
        result.push('UNSETTLED');
      }
      if (this.inProcessVolume) {
        result.push('IN_PROCESS');
      }
      if (this.disagreementVolume) {
        result.push('DISAGREEMENT');
      }

      return result;
    },
    ...mapActions('document', ['generateServiceAct']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.cabinet-service-acts-generate {
  &__content {
    padding: 24px 24px 24px 140px;
    width: 1032px;

    &__divider {
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &__header {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 16px;
    }

    &_period {
      display: flex;
      gap: 16px;
    }

    &__checkbox {
      margin-left: 16px;
      margin-right: 90px;
    }

    &__table {
      width: 100%;
      border: 1px solid #D9D9D9;

      th {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #677379;
      }

      tr {
        height: 48px;
        border-bottom: 1px solid #D9D9D9;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #263238;
      }
    }

    &__act__kind {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 33%;
    }

    &__sides {
      margin-top: 40px;
      display: flex;
      gap: 16px;

      &__select {
        width: 50%;
      }
    }
  }

  &__signatories {
    display: flex;
    padding: 8px;

    &__col-wrapper {
      width: 100%;
    }

  }

  &__buttons {
    margin-top: 10px;
    display: flex;
    width: 600px;
  }
}
</style>
