import { getDraft } from 'services/draft';
import dayjs from 'dayjs';
import { apolloClient } from '@/api/graphql';
import {
  GENERATE_ADDITIONS_FOR_NEW_USAGE_POINT_IDS_AS_XML_TEXT,
  GENERATE_ADDITIONS_FOR_NEW_USAGE_POINTS_AND_CONSUMERS,
  GENERATE_DISAGREEMENTS_FOR_CONTRACT,
} from '@/api/graphql/mutations';

const initFromDraft = async ({ commit, dispatch, getters, state, rootGetters }, { draft }) => {
  commit('RESET_STATE');

  let draftValue;

  if (draft) {
    draftValue = draft;
  } else {
    draftValue = await getDraft(`${state.accountId}contractNew`);
  }

  if (draftValue && draftValue !== 'null') {
    delete draftValue.errors;

    Object.assign(state, draftValue);
  }

  await initState({ commit, dispatch, getters, rootGetters, state });
};

const resetDraft = async ({ commit, dispatch, getters, state, rootGetters }) => {
  commit('RESET_STATE');
  await initState({ commit, dispatch, getters, rootGetters, state });
};

const setContractMode = ({ commit }, { action, type, contractType }) => {
  const isActionValid = ['create', 'upload'].includes(action);
  const isTypeValid = ['contract', 'additionalAgreement'].includes(type);
  const isContractTypeValid = ['ELECTRICITY_TRANSMISSION_CONTRACT', 'ELECTRICITY_PURCHASE_CONTRACT'].includes(contractType);

  if (!isActionValid || !isTypeValid || !isContractTypeValid) {
    throw new Error(`Не валидное действие и/или тип договора: action = ${action}, type = ${type}, contractType = ${contractType}`);
  }
  commit('SET_CONTRACT_MODE', { action, type, contractType });
};

const setCreatedDate = ({ commit }, { date }) => {
  commit('SET_CREATED_DATE', { date });
};

const getContractSides = async ({ commit, dispatch, getters }) => {
  if (getters.getContract.action !== null) {
    const sides = await dispatch('cabinet/getContractPatterns', {
      type: getters.getContract.type,
      action: getters.getContract.action,
      contractType: getters.getContract.contractType,
    }, { root: true });
    setContractSides({ commit }, { sides });
  }
};

const setContractSides = ({ commit }, { sides }) => {
  commit('SET_CONTRACT_SIDES', { sides });
};

const setSelectedTemplateByValue = ({ commit, getters }, { value }) => {
  const selectedTemplate = getters.getContract.contractSides.find(
    (side) => side.mnemonicType === value || (side.mnemonicType === 'ADDITIONAL_ATTACHMENTS' && value  === 'ADDITIONAL_NEW_CONSUMERS_AND_POINTS'),
  );
  commit('SET_SELECTED_TEMPLATE', { selectedTemplate });
};

const setSourceContractId = ({ commit }, { sourceContractId }) => {
  commit('SET_SOURCE_CONTRACT_ID', { sourceContractId });
};

const setSourceContractNumber = ({ commit }, { sourceContractNumber }) => {
  commit('SET_SOURCE_CONTRACT_NUMBER', { sourceContractNumber });
};

const setAdditionalAgreementType = ({ commit }, { additionalAgreementType }) => {
  commit('SET_ADDITIONAL_AGREEMENT_TYPE', { additionalAgreementType });
};

const setSourceContractDate = ({ commit }, { sourceContractDate }) => {
  commit('SET_SOURCE_CONTRACT_DATE', { sourceContractDate });
};


const getUserEmployees = async ({ commit, dispatch }) => {
  const { authorizations } = await dispatch('user/getUser', null, { root: true });
  console.log(authorizations);
  const filteredEmployees = authorizations.filter((it) => it.user.fullName && it.role && it.role.permissions.some((permission) => permission.code === 'SVET_A1-C' || permission.code === 'SVET_A2-C'));
  commit('SET_USER_EMPLOYEES', { userEmployees: filteredEmployees });
};

const addUserEmployees = async ({ commit }, { userEmployees }) => {
  commit('ADD_USER_EMPLOYEES', { userEmployees });
};

const removeUserEmployees = async ({ commit }, { userEmployees }) => {
  commit('REMOVE_USER_EMPLOYEES', { userEmployees });
};

const pushToUserEmployeesContractMembers = ({ commit }, { userEmployeesContractMember }) => {
  commit('ADD_TO_USER_EMPLOYEES_CONTRACT_MEMBERS', { userEmployeesContractMember });
};

const removeEmployeesContractMembers = ({ commit }, { userEmployeesContractMember }) => {
  commit('REMOVE_EMPLOYEES_CONTRACT_MEMBERS', { userEmployeesContractMember });
};

const setUserRemoveEmployeesContractMembers = ({ commit }, { userEmployeesContractMembers }) => {
  commit('SET_USER_EMPLOYEES_CONTRACT_MEMBERS', { userEmployeesContractMembers });
};

const setScanContract = ({ commit }, { scanContract }) => {
  commit('SET_SCAN_CONTRACT', { scanContract });
};

const setErrors = ({ commit }, { errors }) => {
  commit('SET_ERRORS', { errors });
};

const resetState = async ({ commit, dispatch, getters, rootGetters, state }) => {
  commit('RESET_STATE');

  await initState({ commit, dispatch, getters, rootGetters, state });
};

const setSignatoriesSide = ({ commit }, { signatoryEmployee, sideName }) => {
  commit('SET_SIGNATORIES_SIDE', { signatoryEmployee, sideName });
};

const setActVerificationSignatoriesSide = ({ commit }, { signatoryEmployee, sideName }) => {
  commit('SET_ACT_VERIFICATION_SIGNATORIES_SIDE', { signatoryEmployee, sideName });
};

const setCompanySide = ({ commit }, { selectedCompany, sideName }) => {
  commit('SET_COMPANY_SIDE', { selectedCompany, sideName });
};

const setBankRequisitesSide = ({ commit }, { selectedBank, sideName }) => {
  commit('SET_BANK_REQUISITES_SIDE', { selectedBank, sideName });
};

const setBankName = ({ commit }, { bankName, sideName }) => {
  commit('SET_BANK_NAME', { bankName, sideName });
};

const setSettlementAccount = ({ commit }, { settlementAccount, sideName }) => {
  commit('SET_SETTLEMENT_ACCOUNT', { settlementAccount, sideName });
};

const setCorrespondentAccount = ({ commit }, { correspondentAccount, sideName }) => {
  commit('SET_CORRESPONDENT_ACCOUNT', { correspondentAccount, sideName });
};

const setBik = ({ commit }, { bik, sideName }) => {
  commit('SET_BIK', { bik, sideName });
};

const setCompanyName = ({ commit }, { name, sideName }) => {
  commit('SET_COMPANY_NAME', { name, sideName });
};

const setCompanyShortName = ({ commit }, { name, sideName }) => {
  commit('SET_COMPANY_SHORT_NAME', { name, sideName });
};

const setCompanyKpp = ({ commit }, { kpp, sideName }) => {
  commit('SET_COMPANY_KPP', { kpp, sideName });
};

const setCompanyOgrn = ({ commit }, { ogrn, sideName }) => {
  commit('SET_COMPANY_OGRN', { ogrn, sideName });
};

const setCompanyOkpo = ({ commit }, { okpo, sideName }) => {
  commit('SET_COMPANY_OKPO', { okpo, sideName });
};

const setCompanyOktmo = ({ commit }, { oktmo, sideName }) => {
  commit('SET_COMPANY_OKTMO', { oktmo, sideName });
};

const setCompanyLegalAddress = ({ commit }, { legalAddress, sideName }) => {
  commit('SET_COMPANY_LEGAL_ADDRESS', { legalAddress, sideName });
};

const setCompanyPostAddress = ({ commit }, { postAddress, sideName }) => {
  commit('SET_COMPANY_POST_ADDRESS', { postAddress, sideName });
};


const setContractNumber = ({ commit }, { number }) => {
  commit('SET_CONTRACT_NUMBER', { number });
};

const setConclusionDate = ({ commit }, { date }) => {
  commit('SET_CONCLUSION_DATE', { date });
};

const setEffectiveDate = ({ commit }, { date }) => {
  commit('SET_EFFECTIVE_DATE', { date });
};

const setExpiresDate = ({ commit }, { date }) => {
  commit('SET_EXPIRES_DATE', { date });
};

const setProlongation = ({ commit }, { prolongation }) => {
  commit('SET_PROLONGATION', { prolongation });
};

const setRegion = ({ commit }, { region }) => {
  commit('SET_REGION', { region });
};

const setIgk = ({ commit }, { igk }) => {
  commit('SET_IGK', { igk });
};

const setPaymentDates = ({ commit }, { dates }) => {
  commit('SET_PAYMENT_DATES', { dates });
};

const setAttachments = ({ commit }, { attachment }) => {
  commit('SET_ATTACHMENTS', { attachment });
};

const deleteAttachment = ({ commit }, { attachment }) => {
  commit('DELETE_ATTACHMENT', { attachment });
};

const setAttachmentsList = ({ commit }, { attachments }) => {
  commit('SET_ATTACHMENTS_LIST', { attachments });
};

const setContract = ({ commit }, { contract }) => {
  commit('SET_CONTRACT', { contract });
};

const setDraftId = ({ commit }, { draftId }) => {
  commit('SET_DRAFT_ID', { draftId });
};

const setUpdated = ({ commit }, { updated }) => {
  commit('SET_UPDATED', { updated });
};

const setEditMode = ({ commit }, { editMode }) => {
  commit('SET_EDIT_MODE', { editMode });
};


const initState = async ({ commit, dispatch, getters, rootGetters, state }) => {
  await getContractSides({ commit, dispatch, getters });
  await setCreatedDate({ commit }, { date: dayjs().format('DD.MM.YYYY') });
  await getUserEmployees({ commit, dispatch });

  const authorization = rootGetters['user/getAuthorization'];
  if (authorization) {
    const initiatorEmployee = { ...authorization.authorization };
    initiatorEmployee.isInitiator = true;

    pushToUserEmployeesContractMembers({ commit }, {
      userEmployeesContractMember: initiatorEmployee,
    });
  }

  state.isInit = true;
};

const setCurrentStep = ({ commit }, { currentStep }) => {
  commit('SET_CURRENT_STEP', { currentStep });
};

const setEditedSelectedTemplateXml = ({ commit }, { xml }) => {
  commit('SET_EDITED_SELECTED_TEMPLATE_XML', { xml });
};

const setFinallyContractXml = ({ commit }, { xml }) => {
  commit('SET_FINALLY_CONTRACT_XML', { xml });
};


const setContractLastChange = ({ commit }, { contractLastChange }) => {
  commit('SET_CONTRACT_LAST_CHANGE', { contractLastChange });
};

async function generateAdditionsForNewUsagePointsAndConsumers({ rootState, commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: GENERATE_ADDITIONS_FOR_NEW_USAGE_POINTS_AND_CONSUMERS,
      variables: { contractDomainDocumentId: payload.sourceContractId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('generateAdditionsForNewUsagePointsAndConsumers::catch', error);
    throw error;
  }
}

async function generateDisagreementsForContract({ rootState }, payload) {
  try {
    await apolloClient.mutate({
      mutation: GENERATE_DISAGREEMENTS_FOR_CONTRACT,
      variables: { contractDocumentId: payload.contractDocumentId, usagePointIds: payload.usagePointIds },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

  } catch (error) {
    console.log('generateDisagreementsForContract::catch', error);
    throw error;
  }
}



async function generateAdditionsForNewUsagePointIdsAsXmlText({ rootState }, payload) {
  try {
    const { data: { generateAdditionsForNewUsagePointIdsAsXmlText } } = await apolloClient.mutate({
      mutation: GENERATE_ADDITIONS_FOR_NEW_USAGE_POINT_IDS_AS_XML_TEXT,
      variables: { usagePointIds: payload.usagePointIds },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return generateAdditionsForNewUsagePointIdsAsXmlText;
  } catch (error) {
    console.log('generateAdditionsForNewUsagePointIdsAsXmlText::catch', error);
    throw error;
  }
}

const setUsagePoints = ({ commit }, { usagePoints }) => {
  commit('SET_USAGE_POINTS', { usagePoints });
};

export default {
  setContractMode,
  setCreatedDate,
  getContractSides,
  getUserEmployees,
  pushToUserEmployeesContractMembers,
  removeEmployeesContractMembers,
  setSelectedTemplateByValue,
  setScanContract,
  setUserRemoveEmployeesContractMembers,
  setErrors,
  resetState,
  setSignatoriesSide,
  setContractNumber,
  setConclusionDate,
  setEffectiveDate,
  setExpiresDate,
  setProlongation,
  setRegion,
  setIgk,
  setCompanySide,
  setActVerificationSignatoriesSide,
  setPaymentDates,
  setAttachments,
  deleteAttachment,
  setSourceContractId,
  setSourceContractNumber,
  setAdditionalAgreementType,
  setSourceContractDate,
  setAttachmentsList,
  setContract,
  initFromDraft,
  initState,
  setCurrentStep,
  removeUserEmployees,
  addUserEmployees,
  setBankRequisitesSide,
  setBankName,
  setSettlementAccount,
  setCorrespondentAccount,
  setBik,
  setCompanyName,
  setCompanyShortName,
  setCompanyKpp,
  setCompanyOgrn,
  setCompanyOkpo,
  setCompanyOktmo,
  setCompanyLegalAddress,
  setCompanyPostAddress,
  setDraftId,
  resetDraft,
  setContractSides,
  setUpdated,
  setEditMode,
  setEditedSelectedTemplateXml,
  setContractLastChange,
  setFinallyContractXml,
  generateAdditionsForNewUsagePointsAndConsumers,
  generateAdditionsForNewUsagePointIdsAsXmlText,
  setUsagePoints,
  generateDisagreementsForContract,
};
