<template>
  <page title="Справочник категорий потребителей">
    <v-table-new
      v-model="transformedCategories"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="categoriesPage.pageOut"
      :template="template"
      excel-report-file-name="Справочник категорий потребителей"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'ElectricityBoilerTariffsCategories',
  components: {
    VTableNew,
    Page,
  },
  data() {
    return {
      categoriesPage: {},
      pagination: initialPagination,
      filter: {},
      loading: false,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Категория потребителей',
            key: 'description',
            sort: {},
            filter: {},
          },
        ],
      };
    },
    categories() {
      return this.categoriesPage.list ?? [];
    },
    transformedCategories() {
      return this.categories.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.categoriesPage = await this.boilerConsumerTypePaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('boilerConsumerTypePaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const consumerCategories = await this.boilerConsumerTypePaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.boilerConsumerTypePaged.pageOut.totalElements,
          },
        });

        if (!consumerCategories?.list) {
          return [];
        }

        return consumerCategories.list.map(this.transformed);
      } catch (e) {
        console.log('electricityBoilerTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    transformed(it) {
      return {
        description: it.description,
      };
    },
    ...mapActions('dataLists', ['boilerConsumerTypePaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>

</style>
