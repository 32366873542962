<template>
  <div :class="$style.container">
    <div :class="$style.innerContainer">
      <div v-if="isWaitingSubmitElectricityPayment" :class="$style.progressOverlay">
        <div :class="$style.progress">
          <p>Подождите...</p>
          <p>Тестовый счёт формируется</p>
          <horizontal-progress-bar/>
        </div>
      </div>
      <div v-for="(item, index) in items"
           :key="item.id" :class="{
            [$style.content]: item.pointStatus === 'IN_APPLICATION',
            [$style.contentConnected]: usagePointInConnectedState(item)
          }">
        <div :class="$style.locationInfo">
          <p :class="$style.title">{{ item?.objectType }}</p>
          <p :class="$style.address">{{ item?.objectAddress }}</p>
        </div>
        <modal
          v-if="isShowGenerateInvoiceModal"
          :body-style="{
            padding: '24px 0',
          }"
          :header-style="{
            fontWeight: '500',
            fontSize: '18px',
          }"
          :is-backdrop="true"
          :modal-style="{
            borderRadius: '16px',
            padding: '16px 20px',
          }"
          @close="isShowGenerateInvoiceModal = false"
        >
          <template #header>
            <div>Пополнить лицевой счёт</div>
          </template>
          <template #body>
            <v-input
              v-model="invoice"
              title="Введите сумму пополнения(не более 150 000 руб.)"
              type="number"
            />
            <div v-if="Number(invoice) > 150000" style="font-size: 12px; color: red; padding-top: 8px">Единоразовое пополнение лицевого счета не должно превышать 150 000 рублей</div>
          </template>
          <template #footer>
            <v-button
              :disabled="invoice.length <= 0 || invoice === 0 || invoice === '0' || Number(invoice) > 150000"
              type="submit"
              @click="onGenerateInvoice"
            >
              Пополнить
            </v-button>
          </template>
        </modal>
        <div v-if="item.pointStatus === 'IN_APPLICATION' || !item.meter" :class="$style.objectInfo">
          <p :class="$style.meterNotData">Данные по объекту пока не поступают на платформу. Они появятся когда сетевая
            компания выполнит подключение, вы заключите со сбытовой компанией договор на поставку электроэнергии и
            выберете тариф.</p>
        </div>
        <div v-else
             :class="{
              [$style.objectConnectedInfo]: true,
              [$style.minHeight260]: true,
              [$style.shadowBottom]: qualityDetails[index] !== undefined && qualityDetails[index] ||
              readingsDetails[index] !== undefined && readingsDetails[index] ||
              securityDetails[index] !== undefined && securityDetails[index] ||
              qualityDetailsNull[index] !== undefined && qualityDetailsNull[index]
           }"
        >
          <div v-show="!displaySendReadingsForm[item.meter.id] || displayMicrogeneratorMenu(item)" :class="$style.menu">
            <tooltip-menu :key="menuKeyForClose" offset="6px" placement="left">
              <div slot="content">
                <div v-if="isDisplayCreateFixture && item.meter.serialNumber"
                     :class="{[$style.popupMenu]:true, [$style.sendReadingsMenu]: true}"
                     @click="createElectricityPayment({meterNumber: item.meter.serialNumber, tariff: item.consumer?.tariff, serviceLocation: item, owner: item.consumer.consumerName, address: item.objectAddress, number: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000, secondnumber: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000});menuClose();">
                  <img :class="$style.menuIcon" alt="" src="~@/assets/icons/basic/light.svg"/>
                  <span>(Тест) Выставить счёт</span>
                </div>
                <div :class="{[$style.popupMenu]:true, [$style.changeTariffMenu]: true}" @click="generateInvoice(item);menuClose();">
                  <pay :class="$style.menuIcon"/>
                  <span>Пополнить лицевой счёт</span>
                </div>
                <div :class="{[$style.popupMenu]:true, [$style.changeTariffMenu]: true}" @click="tariffsChanging(item);menuClose();">
                  <tariff :class="$style.menuIcon"/>
                  <span>Сменить тариф</span>
                </div>
                <div v-if="item?.consumer" :class="{[$style.popupMenu]:true, [$style.sendReadingsMenu]: true}"
                     @click="showSendReadingsForm(true, item.meter.id);menuClose();">
                  <img :class="$style.menuIcon" alt="" src="~@/assets/icons/basic/swap_horiz.svg"/>
                  <span>Передать показания</span>
                </div>
                <div v-if="displayMicrogeneratorMenu(item)"
                     :class="{[$style.popupMenu]:true, [$style.microgeneratorMenu]: true}"
                     @click="addMicrogenerator(item.id, item.documentId, item.admissionCertificateId);menuClose();">
                  <img :class="$style.menuIcon" alt="" src="~@/assets/icons/basic/microgenerator.svg"/>
                  <span>Стать микрогенератором</span>
                </div>
              </div>
            </tooltip-menu>
          </div>
          <img v-if="item.microgeneratorStatus" :class="$style.microgeneratorIcon"
               alt="" src="~@/assets/icons/basic/meter_microgenerator.svg"/>
          <img v-else :class="$style.bigIcon" alt="" src="~@/assets/icons/basic/meter.svg"/>
          <div :class="$style.meterInfo">
            <p :class="$style.title">Счётчик №{{ item.meter.serialNumber }}</p>
            <p :class="$style.info">{{ item.meter.type }}</p>
            <p v-if="item?.consumer?.contractNumber" :class="$style.info">Лицевой счёт
              №{{ item?.consumer?.contractNumber }}</p>
            <p v-if="item?.consumer?.contractNumber" :class="$style.info">
              {{ item?.consumer?.businessAccount?.balance ?? '0,00' }} ₽</p>
            <p :class="{[$style.info]: true, [$style.textHelper]: true}">
              <span v-if="tariffRateForCategory(item) === 'SINGLE_RATE' || isPowerProfile(item)">Однотарифный учёт&nbsp;</span>
              <span v-else>Многотарифный учёт&nbsp;</span>
              <tooltip-helper v-if="item.firstHalfYearTariff" :small="true" offset="6px" placement="left-end">
                <div :class="$style.tariffInfo">
                  <p>{{ tariffCategory[item.consumer.category].title }}</p><br/>
                  <p>{{ tariffRate[tariffRateForCategory(item)].title }}</p><br/>
                  <p>Тариф в ₽ за кВт ⋅ ч</p>
                  <p v-for="(itemTariffValues, index) in tariffValues(item)" :key="index">
                    {{ itemTariffValues.zoneTitle }}
                    <span :class="$style.period">{{ itemTariffValues.zonePeriod }}</span>
                    <span :class="$style.tariffValue">{{ itemTariffValues.value }}</span>
                  </p>
                </div>
              </tooltip-helper>
            </p>
            <p :class="$style.status">
            <span :class="{[$style.errorStatus]: item.meter.qualityIndicators !== 'OK'}">
              <tooltip-icon :small="true" offset="6px" placement="left-end">
                <light-icon slot="icon"/>
          <div slot="content">{{ getDescStatus('quality', item.meter.qualityIndicators === 'OK') }}</div>
          </tooltip-icon>
          </span>
              <span :class="{[$style.errorStatus]: item.meter.readingsTransmitted !== 'OK'}">
              <tooltip-icon :small="true" offset="6px" placement="left-end">
                <connecting-icon slot="icon"/>
                <div slot="content">{{ getDescStatus('connecting', item.meter.readingsTransmitted === 'OK') }}</div>
              </tooltip-icon>
            </span>
              <span :class="{[$style.errorStatus]: item.meter.sealStatus !== 'OK'}">
              <tooltip-icon :small="true" offset="6px" placement="left-end">
                <lock-icon slot="icon"/>
                <div slot="content">{{ getDescStatus('security', item.meter.sealStatus === 'OK') }}</div>
              </tooltip-icon>
            </span>
            </p>
            <div :class="{
                  [$style.readingDetails]: true,
                  [$style.showDetails]: qualityDetails[index] !== undefined && qualityDetails[index] ||
                  readingsDetails[index] !== undefined && readingsDetails[index] ||
                  securityDetails[index] !== undefined && securityDetails[index] ||
                  qualityDetailsNull[index] !== undefined && qualityDetailsNull[index]
               }"
            >
              <div :class="{[$style.arrowShowDetails]:
                    (qualityDetails[index] !== undefined && qualityDetails[index]) ||
                    (qualityDetailsNull[index] !== undefined && qualityDetailsNull[index])}"
              >
                <img :class="$style.bigIcon" alt='' src="~@/assets/icons/basic/quality.svg" @click="showDetails(index, 0, item)"/>
                <img :class="$style.arrow" alt='' src="~@/assets/icons/basic/arrow_blue.svg"/>
              </div>
              <div :class="{
                    [$style.arrowShowDetails]: readingsDetails[index] !== undefined && readingsDetails[index]
                   }">
                <img :class="$style.bigIcon" alt='' src="~@/assets/icons/basic/bar_chart.svg" @click="openChart(index, 1);"/>
                <img :class="$style.arrow" alt='' src="~@/assets/icons/basic/arrow_blue.svg"/>
              </div>
              <div :class="{
                    [$style.arrowShowDetails]: securityDetails[index] !== undefined && securityDetails[index]
                   }">
                <img :class="$style.bigIcon" alt='' src="~@/assets/icons/basic/info_outline.svg" @click="showDetails(index, 2)"/>
                <img :class="$style.arrow" alt='' src="~@/assets/icons/basic/arrow_blue.svg"/>
              </div>
            </div>
          </div>
          <div :class="$style.readingInfo">
            <template v-if="displaySendReadingsForm[item.meter.id] !== undefined && displaySendReadingsForm[item.meter.id]">
              <p :class="$style.choice">
                <span>НОВЫЕ ПОКАЗАНИЯ</span>
                <span :class="$style.kvtch">кВт • ч</span>
              </p>
              <table :class="{[$style.summaryTable]: true, [$style.sendReadingValues]: true}">
                <template v-if="isPowerProfile(item)">
                  <tr>
                    <td :class="$style.powerProfile" colspan="2">
                      <power-profile-upload
                          :is-display-nav="false"
                          :is-display-preview="false"
                          :is-display-upload-button="false"
                          :is-go-reading-sheets-after-submitted="false"
                          :prop-meter-number="item.meter.serialNumber"
                          :start-generate-xml="startGenerateXml[item.meter.id] ?? false"
                          is-clear-for-error
                          @fill="(count) => fillProfile(count, item.meter.id)"
                          @submitFinally = "(isSubmitted) => submitFinally(item.meter.id, isSubmitted)"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else-if="tariffRateForCategory(item) === 'SINGLE_RATE'">
                  <tr :class="$style.tariffName">
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>Общий (T1)</span>
                      <p :class="{[$style.tariffPeriod]: true, [$style.rightAlignPeriod]: true}">07:00 – 07:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T1'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t1'"
                        @blur="currencyBlur(item.meter.id, 'T1', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T1')"
                        @input="currencyInput(item.meter.id, 'T1', tariffRateForCategory(item))"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else-if="tariffRateForCategory(item) === 'DOUBLE_RATE'">
                  <tr :class="$style.tariffName">
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>День (T1)</span>
                      <p :class="{[$style.tariffPeriod]: true, [$style.rightAlignPeriod]: true}">07:00 – 23:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_2.svg"/>
                      <span>Ночь (T2)</span>
                      <p :class="{[$style.tariffPeriod]: true, [$style.rightAlignPeriod]: true}">23:00 - 07:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T1'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t1'"
                        @blur="currencyBlur(item.meter.id, 'T1', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T1')"
                        @input="currencyInput(item.meter.id, 'T1', tariffRateForCategory(item))"
                      />
                    </td>
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T2'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t2'"
                        @blur="currencyBlur(item.meter.id, 'T2', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T2')"
                        @input="currencyInput(item.meter.id, 'T2', tariffRateForCategory(item))"
                      />
                    </td>
                  </tr>
                </template>
                <template v-else-if="tariffRateForCategory(item) === 'MULTI_RATE'">
                  <tr :class="$style.tariffName">
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>Пик (T1)</span>
                      <p :class="$style.tariffPeriod">07:00 – 10:00; 17:00 – 21:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_2.svg"/>
                      <span>Ночь (T2)</span>
                      <p :class="{[$style.tariffPeriod]: true, [$style.rightAlignPeriod]: true}">23:00 - 07:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_6.svg"/>
                      <span>Полупик (T3)</span>
                      <p :class="$style.tariffPeriod">10:00 – 17:00; 21:00 – 23:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T1'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t1'"
                        @blur="currencyBlur(item.meter.id, 'T1', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T1')"
                        @input="currencyInput(item.meter.id, 'T1', tariffRateForCategory(item))"
                      />
                    </td>
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T2'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t2'"
                        @blur="currencyBlur(item.meter.id, 'T2', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T2')"
                        @input="currencyInput(item.meter.id, 'T2', tariffRateForCategory(item))"
                      />
                    </td>
                    <td>
                      <input
                        :id="'reading_value_' + item.meter.id + 'T3'"
                        :class="$style.currencyField"
                        :role="'object_' + index + '_send_value_t3'"
                        @blur="currencyBlur(item.meter.id, 'T3', tariffRateForCategory(item))"
                        @focus="currencyFocus(item.meter.id, 'T3')"
                        @input="currencyInput(item.meter.id, 'T3', tariffRateForCategory(item))"
                      />
                    </td>
                  </tr>
                </template>
              </table>
            </template>
            <template v-else>
              <p :class="$style.choice">
              <span :class="{[$style.active]: choseFirst[index] === undefined || choseFirst[index]}"
                    @click="setActiveChoice(index, true)">ПОКАЗАНИЯ</span>
                <span :class="{[$style.active]: choseFirst[index] !== undefined && !choseFirst[index]}"
                      @click="setActiveChoice(index, false)">ПОТРЕБЛЕНИЕ</span>
                <span :class="$style.kvtch">кВт • ч</span>
              </p>
              <p :class="$style.periodPreset">
                <input
                  v-if="choseFirst[index] === undefined || choseFirst[index]"
                  :value="indicationsDate(meterReadingsIndicationsData[item.meter.id], item)"
                  disabled="disabled"
                  type="text"
                />
                <c-select v-else
                          v-model="periodPreset[item.meter.id]"
                          :options="periodPresetList"
                          role="periodPreset"
                          @input="selectPeriod(item.meter.id)"
                />
              </p>
              <div v-if="choseFirst[index] !== undefined && !choseFirst[index] && periodPreset[item.meter.id].value === 3"
                   style="display: flex;margin-top: -40px;margin-bottom: 32px">
                <c-date-picker
                  v-model="periodReadingFrom"
                  @input="selectDate(item.meter.id)"
                />
                <c-date-picker
                  v-model="periodReadingTo"
                  @input="selectDate(item.meter.id)"
                />
              </div>
              <table v-if="(choseFirst[index] !== undefined && !choseFirst[index]) ||
                        meterReadingsIndicationsData[item.meter.id]?.length > 0"
                     :class="$style.summaryTable"
              >
                <template v-if="isPowerProfile(item) && powerProfileData[item.meter.id]">
                  <tr>
                    <td colspan="2">
                      <div :class="$style.powerProfileDownload">
                        <span :class="$style.downloadTitle">Скачать профиль мощности</span><document-downloader :document="powerProfileData[item.meter.id].document"/>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="tariffRateForCategory(item) === 'SINGLE_RATE' || isPowerProfile(item)">
                  <tr :class="$style.tariffName">
                    <td v-if="item.meter.microgeneratorStatus" :class="$style.tariffSummaryTitle">&nbsp;</td>
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>Общий (T1)</span>
                      <p :class="$style.tariffPeriod">07:00 – 07:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td v-if="item.meter.microgeneratorStatus" :class="$style.tariffSummaryTitle">получено</td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT1', 't1Consumption')
                      }}</span></td>
                  </tr>
<!--                  <tr v-if="item.meter.microgeneratorStatus && (choseFirst[index] !== undefined && !choseFirst[index])"
                      :class="$style.tariffSummary" style="color: #02BC9B">
                    <td :class="$style.tariffSummaryTitle" style="color: #02BC9B">передано<br/>в сеть</td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 'readingT1')
                      }}</span></td>
                  </tr>-->
                </template>
                <template v-else-if="tariffRateForCategory(item) === 'DOUBLE_RATE'">
                  <tr :class="$style.tariffName">
                    <td v-if="item.meter.microgeneratorStatus" :class="$style.tariffSummaryTitle">&nbsp;</td>
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>День (T1)</span>
                      <p :class="$style.tariffPeriod">07:00 – 23:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_2.svg"/>
                      <span>Ночь (T2)</span>
                      <p :class="$style.tariffPeriod">23:00 - 07:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td v-if="item.meter.microgeneratorStatus" :class="$style.tariffSummaryTitle">получено</td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT1', 't1Consumption')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT2', 't2Consumption')
                      }}</span></td>
                  </tr>
<!--                  <tr v-if="item.meter.microgeneratorStatus && (choseFirst[index] !== undefined && !choseFirst[index])"
                      :class="$style.tariffSummary" style="color: #02BC9B">
                    <td :class="$style.tariffSummaryTitle" style="color: #02BC9B">передано<br/>в сеть</td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 'readingT1')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 't2Consumption')
                      }}</span></td>
                  </tr>-->
                </template>
                <template v-else-if="tariffRateForCategory(item) === 'MULTI_RATE'">
                  <tr :class="$style.tariffName">
                    <td v-if="item.microgeneratorStatus" :class="$style.tariffSummaryTitle">&nbsp;</td>
                    <td><img src="~@/assets/icons/basic/brightness_5.svg"/>
                      <span>Пик (T1)</span>
                      <p :class="$style.tariffPeriod">07:00 – 10:00; 17:00 – 21:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_2.svg"/>
                      <span>Ночь (T2)</span>
                      <p :class="$style.tariffPeriod">23:00 - 07:00</p></td>
                    <td><img src="~@/assets/icons/basic/brightness_6.svg"/>
                      <span>Полупик (T3)</span>
                      <p :class="$style.tariffPeriod">10:00 – 17:00; 21:00 – 23:00</p></td>
                  </tr>
                  <tr :class="$style.tariffSummary">
                    <td v-if="item.meter.microgeneratorStatus" :class="$style.tariffSummaryTitle">получено</td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT1', 't1Consumption')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT2', 't2Consumption')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.id], 'readingT3', 't3Consumption')
                      }}</span></td>
                  </tr>
<!--                  <tr v-if="item.meter.microgeneratorStatus && (choseFirst[index] !== undefined && !choseFirst[index])"
                      :class="$style.tariffSummary" style="color: #02BC9B">
                    <td :class="$style.tariffSummaryTitle" style="color: #02BC9B">передано<br/>в сеть</td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 'readingT1')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 't2Consumption')
                      }}</span></td>
                    <td><span>{{
                        summaryTariffGeneratedValue(index, item.meter.id, meterReadingsIndicationsData[item.meter.serialNumber], 't3Consumption')
                      }}</span></td>
                  </tr>-->
                </template>
              </table>
            </template>
            <table v-if="item?.consumer" :class="$style.sendReadings">
              <tr>
                <td colspan="2"><span>Показания необходимо ввести с 15 по 25 число текущего месяца.<horizontal-progress-bar
                  v-if="isWaitingSubmit[item.meter.id]"/></span></td>
              </tr>
              <tr v-if="displaySendReadingsForm[item.meter.id] === undefined || !displaySendReadingsForm[item.meter.id]">
                <td colspan="2">
                  <button role='open_send_readings' @click="showSendReadingsForm(true, item.meter.id)">ПЕРЕДАТЬ ПОКАЗАНИЯ
                  </button>
                </td>
              </tr>
              <tr v-else>
                <td style="width: 50%">
                  <button :class="{[$style.cancel]: true, [$style.invalid]: isWaitingSubmit[item.meter.id]}"
                          role='close_send_readings'
                          @click="showSendReadingsForm(false, item.meter.id)">НАЗАД
                  </button>
                </td>
                <td style="width: 50%">
                  <button v-if="isPowerProfile(item)"
                          :class="{[$style.invalid]: !isFillProfile[item.meter.id] || isWaitingSubmit[item.meter.id]}"
                          role='send_readings'
                          @click="sendReadings(item.meter.id, item.meter, 'POWER_PROFILE')">ЗАГРУЗИТЬ
                    ПОКАЗАНИЯ
                  </button>
                  <button v-else :class="{[$style.invalid]: !isValidReadingsValues[item.meter.id] || isWaitingSubmit[item.meter.id]}"
                          role='send_readings'
                          @click="sendReadings(item.meter.id, item.meter)">ВВЕДИТЕ
                    ПОКАЗАНИЯ
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <quality-details
          v-if="qualityDetails[index] !== undefined && qualityDetails[index] && item.meter?.id && meterReadingsIndicationsData[item.meter.id]"
          :date="currentDate"
          :phases="mockPhaseData"
        />
        <quality-null
          v-if="(qualityDetailsNull[index] !== undefined && qualityDetailsNull[index]) && (!item.meter?.id)"
        />
        <div v-if="readingsDetails[index] !== undefined && readingsDetails[index] && meterReadingData[item.meter.id] !== undefined" :class="$style.readingsDetails">
          <table :class="$style.readingsMonths">
            <thead>
            <tr>
              <th colspan="5">Потребление, кВт • ч</th>
            </tr>
            <tr>
              <th :class="$style.chartsIcons" colspan="5">
              <span :class="{[$style.active]: dataModeView[item.meter.id] === 'LINE_CHART'}"
                    @click="setDataViewMode('LINE_CHART', item.meter.id)"><show-chart-icon/></span>
                <span :class="{[$style.active]: dataModeView[item.meter.id] === 'BAR_CHART'}"
                      @click="setDataViewMode('BAR_CHART', item.meter.id)"><bar-chart-icon/></span>
                <span
                  :class="{[$style.active]: dataModeView[item.meter.id] === 'VIEW_LIST' || dataModeView[item.meter.id] === undefined}"
                  @click="setDataViewMode('VIEW_LIST', item.meter.id)"><view-list-icon/></span>
              </th>
            </tr>
            <template v-if="dataModeView[item.meter.id] === 'VIEW_LIST' || dataModeView[item.meter.id] === undefined">
              <tr>
                <th>&nbsp;</th>
                <td :class="$style.readingValue">
                  <p :class="$style.tariffNameDetails">Пиковая зона Т1</p>
                  <p :class="$style.tariffPeriodsDetails">(07:00 – 10:00;<br/>17:00 – 21:00)</p>
                </td>
                <td :class="$style.readingValue">
                  <p :class="$style.tariffNameDetails">Полупиковая зона Т3</p>
                  <p :class="$style.tariffPeriodsDetails">(10:00 – 17:00;<br/>21:00 – 23:00)</p>
                </td>
                <td :class="$style.readingValue">
                  <p :class="$style.tariffNameDetails">Ночная зона Т2</p>
                  <p :class="$style.tariffPeriodsDetails">(23:00 - 07:00)</p>
                </td>
                <td :class="$style.showMore">&nbsp;</td>
              </tr>
              <!--            <tr>
                            <th :class="$style.linkText" colspan="5">
                              <p @click="hideAll(item.meter.id)">свернуть всё</p>
                              <p @click="showAll(item.meter.id)">развернуть всё</p>
                            </th>
                          </tr>-->
            </template>
            <tr :class="$style.emptyRow">
              <th colspan="5"></th>
            </tr>
            </thead>
            <template v-if="dataModeView[item.meter.id] === 'VIEW_LIST' || dataModeView[item.meter.id] === undefined">
              <tbody v-for="(reading, monthIndex) in meterReadingData[item.meter.id]" :key="monthIndex">
                <tr :class="{[$style.openDetails]: !isHideMonth(item.meter.id, monthIndex), [$style.borderBottom2]: true}">
                  <th>{{ reading.accountingPeriod.month }}.{{ reading.accountingPeriod.year }}</th>
                  <td :class="$style.readingValue">{{ reading.t1Consumption }}</td>
                  <td :class="$style.readingValue">{{ reading.t3Consumption }}</td>
                  <td :class="$style.readingValue">{{ reading.t2Consumption }}</td>
                </tr>
              </tbody>
            </template>
            <template v-if="dataModeView[item.meter.id] === 'LINE_CHART'">
              <tbody>
              <tr>
                <td :class="$style.periodType">
                <span v-if="dataStartView[item.meter.id]"
                      :class="$style.periodTypeLineChart">{{ dataStartView[item.meter.id].periodTitle }}</span>
                </td>
                <td colspan="4" @wheel.prevent="chartScroll($event, item.meter.id)">
                  <line-chart :chart-data="lineChartData[item.meter.id]" :options="lineChartData[item.meter.id].options"
                              :update="updateChartTooltips"/>
                </td>
              </tr>
              <!--              TODO: move to local component (for line and bar charts)-->
              <tr v-if="dataStartView[item.meter.id] && dataStartView[item.meter.id].navigationVisible">
                <td>&nbsp;</td>
                <td
                  :class="{[$style.prevValueLink]: true, [$style.activeNavigation]: dataStartView[item.meter.id].prevActive}"
                  @click="prevChartDataView(item.meter.id)">
                  <div style="display: flex;align-items: center">
                    <chevron-left/>
                    <span>
                      раньше
                    </span>
                  </div>
                </td>
                <td :class="$style.range" colspan="2">
                  <!--                <input type="range" min="0" max="100" value="50" />-->
                </td>
                <td
                  :class="{[$style.nextValueLink]: true, [$style.activeNavigation]: dataStartView[item.meter.id].nextActive}"
                  @click="nextChartDataView(item.meter.id)">
                  <div style="display: flex;align-items: center; justify-content: flex-end">
                    <span>
                      позже
                    </span>
                    <chevron-right/>
                  </div>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td :class="$style.legend" colspan="4">
                  <div v-for="(tariff, indexTariff) in currentTariffs[item.meter.id]" :key="indexTariff">
                    <hr :class="{
                      [$style.t1Color]: tariff === 'T1' || tariff === 'Т1',
                      [$style.t2Color]: tariff === 'T2' || tariff === 'Т2',
                      [$style.t3Color]: tariff === 'T3' || tariff === 'Т3',
                    }">
                    <span>{{ tariffTitle[tariff] }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
            <template v-if="dataModeView[item.meter.id] === 'BAR_CHART'">
              <tbody>
              <tr>
                <td :class="$style.periodType">
                  <span v-if="dataStartView[item.meter.id]">{{ dataStartView[item.meter.id].periodTitle }}</span>
                </td>
                <td colspan="4" @wheel.prevent="chartScroll($event, item.meter.id)">
                  <bar-chart :chart-data="lineChartData[item.meter.id]" :options="lineChartData[item.meter.id].options"/>
                </td>
              </tr>
              <tr v-if="dataStartView[item.meter.id] && dataStartView[item.meter.id].navigationVisible">
                <td>&nbsp;</td>
                <td
                  :class="{[$style.prevValueLink]: true, [$style.activeNavigation]: dataStartView[item.meter.id].prevActive}"
                  colspan="2"
                  @click="prevChartDataView(item.meter.id)">
                  <div style="display: flex;align-items: center">
                    <chevron-left/>
                    <span>
                      раньше
                    </span>
                  </div>
                </td>
                <td
                  :class="{[$style.nextValueLink]: true, [$style.activeNavigation]: dataStartView[item.meter.id].nextActive}"
                  colspan="2"
                  @click="nextChartDataView(item.meter.id)">
                  <div style="display: flex;align-items: center; justify-content: flex-end">
                    <span>
                      позже
                    </span>
                    <chevron-right/>
                  </div>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td :class="$style.legend" colspan="4">
                  <div v-for="(tariff, indexTariff) in currentTariffs[item.meter.id]" :key="indexTariff">
                    <hr :class="{
                      [$style.t1Color]: tariff === 'T1' || tariff === 'Т1',
                      [$style.t2Color]: tariff === 'T2' || tariff === 'Т2',
                      [$style.t3Color]: tariff === 'T3' || tariff === 'Т3',
                    }">
                    <span>{{ tariffTitle[tariff] }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </table>
        </div>
        <div v-if="securityDetails[index] !== undefined && securityDetails[index]" :class="$style.securityDetails">
          <table>
            <tbody>
            <tr>
              <th>
                <p :class="$style.textBase">
                  Прибор учёта электроэнергии
                </p>
                <p :class="$style.titleBig">
                  {{ item.meter.type }}
                </p>
                <p>&nbsp;</p>
              </th>
              <td>
                <p :class="$style.titleSmall">
                  <connecting-icon :class="$style.connectingIcon"/>
                  <span>Онлайн</span>
                </p>
                <p :class="$style.textSmall" style="margin-top: 10px">
                  Последняя передача показаний
                </p>
                <p :class="$style.titleBase">
                  {{ currentDate }}
                </p>
              </td>
            </tr>
            <tr>
              <th>
                <img src="~@/assets/icons/basic/meter_img.svg"/>
              </th>
              <td :class="$style.lockIcons">
                <p>
                  <lock-icon/>
                  <span>Пломба постоянного магнитного поля</span>
                </p>
                <p>
                  <lock-icon/>
                  <span>Пломба переменного магнитного поля</span>
                </p>
                <p>
                  <lock-icon/>
                  <span>Пломба корпуса</span>
                </p>
                <p>
                  <lock-icon/>
                  <span>Пломба клеммной крышки</span>
                </p>
                <p>
                  <lock-icon/>
                  <span>Пломба модуля связи</span>
                </p>

              </td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th><span>Уникальный идентификатор точки поставки</span></th>
              <td><span>{{ item.id }}</span></td>
            </tr>
            <tr>
              <th><span>Заводской номер</span></th>
              <td><span>{{ item.meter.serialNumber }}</span></td>
            </tr>
            <tr>
              <th><span>Производитель</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Год производства</span></th>
              <td><span>{{ item.meter.productionYear }}</span></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <th><span>Тип связи:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Версия прошивки модуля связи:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Системное время прибора учёта:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Системная дата прибора учёта:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Коэффициент трансформации:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Управление нагрузкой:</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <th><span>Адрес установки</span></th>
              <td><span>{{ item.meter.address }}</span></td>
            </tr>
            <tr>
              <th><span>Место установки</span></th>
              <td><span>{{ item.meter.location }}</span></td>
            </tr>
            <tr>
              <th><span>Дата установки</span></th>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr>
              <th><span>Поверен</span></th>
              <td><span>{{ item.meter.calibrationDate }}</span></td>
            </tr>
            <tr>
              <th><span>Документ</span></th>
              <td><span>Акт допуска прибора учёта в эксплуатацию</span></td>
            </tr>
            <tr>
              <th><span>Очередная поверка</span></th>
              <td><span>{{ item.meter.nextCalibrationDate }}</span></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <th><span>Гарантирующий поставщик</span></th>
              <td><span>AO «МОСЭНЕРГОСБЫТ»</span></td>
            </tr>
            <tr>
              <th><span>Лицевой счёт №</span></th>
              <td><span>{{ item?.consumer?.contractNumber }}</span></td>
            </tr>
            <tr>
              <th><span>Договор №</span></th>
              <td><span>{{ item?.consumer?.contractNumber }}</span></td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>

            <tr>
              <th><span>Тарификация	</span></th>
              <td>
                <p>Многотарифный учёт с применением тарифа, дифференцированного по зонам суток.</p>
                <p>
                  Ночная зона Т2 (23:00 - 07:00)<br/>
                  Полупиковая зона Т3 (10:00 – 17:00; 21:00 – 23:00)<br/>
                  Пиковая зона Т1 (07:00 – 10:00; 17:00 – 21:00)
                </p>
              </td>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <td>&nbsp;</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="items.length > 0" :class="$style.addContainer">
        <div v-if="getUserType === 'Customer'" :class="$style.add" style="text-align: center" @click="addObject">
          <p style="display: flex;justify-content: center"><img alt=""
                                                                src="~@/assets/icons/action/add.svg"
                                                                style="height: 18px; width: 18px; margin-right: 4px; margin-top: 4px"/>
            подключить новый объект к электросети</p>
        </div>
      </div>
      <div v-else :class="$style.noObjects">
        <p>Оформите
          <span @click="addObject">заявку на технологическое присоединение</span>
          <br/>
          или
          <span @click="showContractModal"> добавьте договор энергоснабжения </span>
          <connection-user-by-contract-modal
            v-if="isContractModalShow"
            @close="hideContractModal"
          />
        </p>
      </div>
      <tariffs-changing v-model="displayTariffsChanging" :service-location="tariffsChangingServiceLocation"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EVENT_BUS } from 'root/eventBus';
import {
  formatDateToXmlDateFormat,
  getCurrentDate,
  getMomentCurrentDate,
  getMomentDate,
  getTimestamp,
  setLocale,
} from 'lib/utils/date';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import formatting from 'lib/utils/formatting';
import { isDisplayTestUi } from 'lib/utils/tests';
import route from '@/router';
import { ConnectionUserByContractModal, DocumentDownloader, Modal, VButton, VInput } from '@/components';
import PowerProfileUpload from 'views/private/PowerProfileUpload';
import dayjs from 'dayjs';
import { MOCK_PHASES_DATA } from '/src/mock';

const MAX_COUNT_VALUES = {
  LINE_CHART: {
    DAYS: 31,
    MONTHS: 8,
  },
  BAR_CHART: {
    DAYS: 10,
    MONTHS: 7,
  },
};

export default {
  name: 'ObjectCards',
  components: {
    Modal,
    VButton,
    VInput,
    TooltipMenu: () => import('molecules/tooltip/TooltipMenu'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    TooltipIcon: () => import('molecules/tooltip/TooltipIcon'),
    ConnectingIcon: () => import('atoms/icons/basic/Connecting.vue'),
    LockIcon: () => import('atoms/icons/basic/Lock.vue'),
    LightIcon: () => import('atoms/icons/basic/Light.vue'),
    BarChartIcon: () => import('atoms/icons/basic/BarChart.vue'),
    ShowChartIcon: () => import('atoms/icons/basic/ShowChart.vue'),
    ViewListIcon: () => import('atoms/icons/basic/ViewList.vue'),
    CSelect: () => import('atoms/common/CSelect'),
    ChevronRight: () => import('atoms/icons/navigation/ChevronRight'),
    ChevronLeft: () => import('atoms/icons/navigation/ChevronLeft'),
    CDatePicker: () => import('atoms/common/inputs/CDatePicker'),
    LineChart: () => import('molecules/chart/LineChart'),
    BarChart: () => import('molecules/chart/BarChart'),
    QualityDetails: () => import('organisms/object/QualityDetails'),
    QualityNull: () => import('organisms/object/QualityNull'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    Pay: () => import('atoms/icons/menu/Pay.vue'),
    Tariff: () => import('atoms/icons/menu/Tariff.vue'),
    TariffsChanging: () => import('organisms/tariffs/TariffsChanging.vue'),
    ConnectionUserByContractModal,
    PowerProfileUpload,
    DocumentDownloader,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      isContractModalShow: false,
      items: [],
      displayObjectMenu: {},
      isShowGenerateInvoiceModal: false,
      invoice: '',
      selectedItemIdToInvoice: null,
      choseFirst: {},
      periodPreset: {},
      periodPresetList: [],
      qualityDetailsNull: {},
      qualityDetails: {},
      readingsDetails: {},
      securityDetails: {},
      currentDate: getCurrentDate('DD.MM.YYYY, HH:MM'),
      meterReadingData: {},
      meterReadingsIndicationsData: {},
      powerProfileData: {},
      currentMeterNumber: '',
      hideMonthsData: {},
      readingFrom: getCurrentDate('YYYY-MM-01'),
      readingTo: getCurrentDate('YYYY-MM-DD'),
      periodReadingFrom: {},
      periodReadingTo: {},
      dataModeView: {},
      dataStartView: {},
      lineChartData: {},
      currentTariffs: {},
      showChartTooltips: false,
      lastScrollTime: 0,
      updateChartTooltips: 0,
      displaySendReadingsForm: {},
      sendValue1: {},
      sendValue2: '',
      sendValue3: '',
      isWaitingSubmit: {},
      isWaitingSubmitElectricityPayment: false,
      isValidReadingsValues: {},
      tariffTitle: {
        T1: 'Пиковая зона Т1 (07:00 – 10:00; 17:00 – 21:00)',
        Т1: 'Пиковая зона Т1 (07:00 – 10:00; 17:00 – 21:00)',
        T2: 'Ночная зона Т2 (23:00 - 07:00)',
        Т2: 'Ночная зона Т2 (23:00 - 07:00)',
        T3: 'Полупиковая зона Т3 (10:00 – 17:00; 21:00 – 23:00)',
        Т3: 'Полупиковая зона Т3 (10:00 – 17:00; 21:00 – 23:00)',
      },
      isDisplayCreateFixture: isDisplayTestUi(),
      displayTariffsChanging: false,
      tariffsChangingServiceLocation: {},
      startGenerateXml: {},
      isFillProfile: {},
      menuKeyForClose: 1,
    };
  },
  computed: {
    isHideMonth() {
      return (meterNumber, month) => {
        if (this.hideMonthsData[meterNumber] === undefined) {
          return true;
        }
        if (this.hideMonthsData[meterNumber][month] === undefined) {
          return true;
        }
        return this.hideMonthsData[meterNumber][month];
      };
    },
    indicationsDate() {
      return (meterReadingsIndications, item) => {
        if (meterReadingsIndications === undefined || meterReadingsIndications === null || meterReadingsIndications.length === 0) {
          return 'Нет данных';
        }

        const lastData = meterReadingsIndications[0];

        if (this.isPowerProfile(item) && !lastData.accountingPeriod) {
          return `переданные ${dayjs(lastData).format('DD.MM.YYYY')}`;
        }

        if (!lastData.accountingPeriod) {
          return '';
        }

        const period = `${lastData.accountingPeriod.month}.${lastData.accountingPeriod.year}`;

        return `переданные ${dayjs(period, 'M.YYYY').format('MM.YYYY')}`;
      };
    },
    mockPhaseData() {
      return MOCK_PHASES_DATA;
    },
    ...mapGetters('user', ['getUserType', 'getAccount', 'getTokenStatus']),
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
  },
  watch: {
    meterReadingData: {
      immediate: true,
      handler() {
        if (this.dataModeView[this.currentMeterNumber] === 'LINE_CHART' || this.dataModeView[this.currentMeterNumber] === 'BAR_CHART') {
          this.dataStartView[this.currentMeterNumber] = undefined;
          this.setDataViewMode(this.dataModeView[this.currentMeterNumber], this.currentMeterNumber);
        }
      },
    },
  },
  methods: {
   async onGenerateInvoice() {
     const selectedItem = this.items.find((item) => item.meter.id === this.selectedItemIdToInvoice);
     try {
        await this.generateAccountRefillInvoice({ params: {
           businessAccountId: selectedItem.consumer.businessAccount.id,
           amount: Number(this.invoice),
         } });
       this.vueShowNotification(
         'Ошибка пополнения лицевого счёта',
       );
       this.isShowGenerateInvoiceModal = false;
       this.invoice = '';
       // TODO счет создается, можно тестово оплатить как в техприсе через фикстуру в разделе оплата, реальная оплата будет доступна после фикса платежного шлюза;
       // const paymentUrl = await this.createPaymentUrl({ params: {
       //     invoiceId: accountBalanceId,
       //   } });
       // window.open(paymentUrl, '_self');
     } catch (e) {
       console.log('error', e);
       const errorData = HTTPError.network(e);
       this.vueShowNotification(
         'Ошибка пополнения лицевого счёта',
         `<p>${errorData.message}</p>`,
       );
     }
    },
    showContractModal() {
      this.isContractModalShow = true;
    },
    hideContractModal() {
      this.isContractModalShow = false;
    },
    async loadData() {
      if (!this.getAccount || this.getTokenStatus !== 'FULL') {
        return;
      }

      this.items = await this.loadUsagePointsIndividualWithMeasurements({
        accountingPeriod: {
          year: dayjs().year(),
          month: dayjs().month() + 1,
        },
      });

      setLocale('ru');
      const curDate = getMomentCurrentDate();
      const curDateString = curDate.format('DD.MM.YYYY');
      const month = getMomentCurrentDate()
        .add(-1, 'month')
        .format('MMMM YYYY')
        .toUpperCase();

      this.periodPresetList = [
        { label: `с начала месяца 01–${curDateString}`, value: 0 },
        { label: `предыдущий месяц ${month}`, value: 1 },
        { label: 'текущий год', value: 4 },
        { label: 'предыдущий год', value: 2 },
        { label: 'укажите период', value: 3 },
      ];
      for (const item of this.items) {
        if (item.meter !== null && item.meter.serialNumber) {
          this.periodPreset[item.meter.id] = { label: `с начала месяца 01–${curDateString}`, value: 0 };
        }
      }
      for (const item of this.items) {
        if (item.meter !== null) {
          const data = { ...this.meterReadingsIndicationsData };
          const dataPowerProfile = { ...this.powerProfileData };

          const tariffRate = this.tariffRateForCategory(item);

          if (this.isPowerProfile(item)) {
            const powerProfiles = await this.powerProfilesByMeterId(item.meter.id);

            if (powerProfiles && powerProfiles.length > 0) {
              dataPowerProfile[item.meter.id] = { ...powerProfiles[powerProfiles.length - 1], tariffRate: tariffRate };
            }
          }

          if (item.measurements) {
            data[item.meter.id] = item.measurements;
          }
          this.meterReadingsIndicationsData = { ...data };
        }
      }
    },
    addObject() {
      route.push('/cabinet/connection-application');
    },
    addMicrogenerator(serviceLocationId, connectionApplicationId, admissionCertificateId) {
      EVENT_BUS.$emit('MICROGENERATOR_CONNECTION', serviceLocationId, connectionApplicationId, admissionCertificateId);
    },
    showObjectMenu(index) {
      this.$set(this.displayObjectMenu, index, true);
    },
    hideObjectMenu() {
      this.displayObjectMenu = {};
    },
    displayMicrogeneratorMenu(item) {
      return item.admissionCertificateId && !item.meter.microgeneratorStatus;
    },
    getDescStatus(name, value) {
      const DESC_STATUS = {
        quality: {
          true: 'Качественные показатели электроэнергии в норме',
          false: 'Качественные показатели электроэнергии не стабильны',
        },
        connecting: {
          true: 'Модуль связи в порядке, показания передаются',
          false: 'Нет соединения с модулем связи',
        },
        security: {
          true: 'Все пломбы счётчика целы',
          false: 'Нарушена целостность пломб счётчика',
        },
      };

      return DESC_STATUS[name][value];
    },
    async setActiveChoice(index, value) {
      const qwe = { ...this.choseFirst };
      qwe[index] = value;
      this.choseFirst = qwe;
    },
    showDetails(index, number, item) {
      let isShow = false;

      const qualityDetailsNull = { ...this.qualityDetailsNull };
      const qualityDetails = { ...this.qualityDetails };
      const readingsDetails = { ...this.readingsDetails };
      const securityDetails = { ...this.securityDetails };

      if (number === 0) {
        if (this.meterReadingsIndicationsData[item.meter.id]) {
          isShow = !qualityDetails[index];
          qualityDetails[index] = isShow;
          readingsDetails[index] = false;
          securityDetails[index] = false;
        } else {
          isShow = !qualityDetailsNull[index];
          qualityDetailsNull[index] = isShow;
          readingsDetails[index] = false;
          securityDetails[index] = false;
        }
      }
      if (number === 1) {
        qualityDetails[index] = false;
        qualityDetailsNull[index] = false;
        isShow = !readingsDetails[index];
        readingsDetails[index] = isShow;
        securityDetails[index] = false;
      }
      if (number === 2) {
        qualityDetails[index] = false;
        qualityDetailsNull[index] = false;
        readingsDetails[index] = false;
        isShow = !securityDetails[index];
        securityDetails[index] = isShow;
      }

      this.qualityDetailsNull = qualityDetailsNull;
      this.qualityDetails = qualityDetails;
      this.readingsDetails = readingsDetails;
      this.securityDetails = securityDetails;

      return isShow;
    },
    openChart(index, detailsNumber) {
      const isShow = this.showDetails(index, detailsNumber);

      if (isShow && (this.choseFirst[index] === undefined || this.choseFirst[index])) {
        this.setActiveChoice(index, false);
      }
    },
    getSummaryValues(summary) {
      const tariffValues = {
        T1: '-',
        T3: '-',
        T2: '-',
      };

      summary.forEach((item) => {
        tariffValues[item.meter.id] = this.formattingNumber(item.value);
      });

      return Object.values(tariffValues);
    },
    showMonth(meterNumber, month) {
      const hideMonths = { ...this.hideMonthsData };

      if (hideMonths[meterNumber] === undefined) {
        hideMonths[meterNumber] = {};
        hideMonths[meterNumber][month] = false;
      } else if (hideMonths[meterNumber][month] === undefined) {
        hideMonths[meterNumber][month] = false;
      } else {
        hideMonths[meterNumber][month] = !hideMonths[meterNumber][month];
      }

      this.hideMonthsData = { ...hideMonths };
    },
    formattingNumber(value) {
      /* return Intl.NumberFormat('ru-RU', {
        style: 'decimal',
      }).format(value);*/
      return value;
    },
    hideAll(meterNumber) {
      this.hideMonthsData[meterNumber] = {};
    },
    showAll(meterNumber) {

      if (this.meterReadingData[meterNumber] === undefined) {
        return;
      }

      const hideMonths = { ...this.hideMonthsData };

      hideMonths[meterNumber] = {};

      this.meterReadingData[meterNumber].forEach((month, indexMonth) => {
        hideMonths[meterNumber][indexMonth] = false;
      });

      this.hideMonthsData = { ...hideMonths };
    },
    displayNumberFormat(value) {
      if (!value) {
       return '';
      }

      const formatted = Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        maximumFractionDigits: 5,
      }).format(value.replace(new RegExp('[,]', 'g'), '.'));
      if (formatted === 'не число') {
        return '';
      }
      return formatted;
    },
    // TODO remove tariffId2
    summaryTariffValue(index, meterNumber, meterReadingsIndications, tariffId, consumptionId) {
      if (this.choseFirst[index] === undefined || this.choseFirst[index]) {

        if (meterReadingsIndications === undefined || meterReadingsIndications === null ||
            meterReadingsIndications.length === 0 || !meterReadingsIndications[0].currentTariffsMeasurements) {
          return '';
        }

        const tariff = meterReadingsIndications[0].currentTariffsMeasurements[tariffId];

        return this.displayNumberFormat(tariff);
      }
      const meterReadingData = this.meterReadingData[meterNumber];

      if (meterReadingData === undefined) {
        return '';
      }

      let summary = 0;
      meterReadingData.forEach((reading) => {
        const value = Number(reading[consumptionId]);
        if (value) {
          summary += value;
        }
      });

      return this.displayNumberFormat(summary.toString());
    },
    // TODO merge with summaryTariffValue
    /* summaryTariffGeneratedValue(index, meterNumber, meterReadingsIndications, tariffId, tariffId2) {
      if (this.choseFirst[index] === undefined || this.choseFirst[index]) {

        if (meterReadingsIndications === undefined || meterReadingsIndications === null) {
          return '';
        }

        const tariff = meterReadingsIndications.tariffs.filter((item) => item.meter.id === tariffId || item.meter.id === tariffId2);

        if (tariff.length === 0) {
          return '';
        }

        return tariff[0].generatedValue;
      }
      const meterReadingData = this.meterReadingData[meterNumber];

      if (meterReadingData === undefined) {
        return '';
      }

      const summary = meterReadingData.summary?.filter((item) => item.meter.id === tariffId || item.meter.id === tariffId2);

      if (summary === undefined || summary.length === 0) {
        return '';
      }

      return summary[0].generatedValue;

    },*/
    async selectPeriod(meterId) {
      this.currentMeterNumber = meterId;
      const reading = {
        from: this.readingFrom,
        to: this.readingTo,
      };
      switch (this.periodPreset[meterId].value) {
        case 0:
          this.readingFrom = getCurrentDate('YYYY-MM-01');
          this.readingTo = getCurrentDate('YYYY-MM-DD');
          break;
        case 1:
          this.readingFrom = getMomentCurrentDate().add(-1, 'month').format('YYYY-MM-01');
          this.readingTo = getMomentCurrentDate().add(-1, 'month').endOf('month')
            .format('YYYY-MM-DD');
          break;
        case 2:
          this.readingFrom = getMomentCurrentDate().add(-1, 'year').format('YYYY-01-01');
          this.readingTo = getMomentCurrentDate().add(-1, 'year').format('YYYY-12-31');
          break;
        case 4:
          this.readingFrom = getMomentCurrentDate().format('YYYY-01-01');
          this.readingTo = getMomentCurrentDate().format('YYYY-12-31');
          break;
        case 3:
          this.periodReadingFrom = getMomentDate(this.readingFrom).format('DD.MM.YYYY');
          this.periodReadingTo = getMomentDate(this.readingTo).format('DD.MM.YYYY');
          // TODO fix not show date pickers
          this.meterReadingData = { ...this.meterReadingData };
          return;
      }

      this.hideAll(meterId);

      if (this.meterReadingData[meterId] !== undefined && reading.from === this.readingFrom && reading.to === this.readingTo) {
        return;
      }

      const data = { ...this.meterReadingData };
      data[meterId] = await this.meterConsumptionsByFilter({
        meterId: meterId,
        from: this.getAccountPeriod(this.readingFrom),
        to: this.getAccountPeriod(this.readingTo),
        energyType: 'ACTIVE',
        energyDirection: 'INPUT',
      });
      this.meterReadingData = { ...data };
    },
    async selectDate(meterId) {
      this.currentMeterNumber = meterId;

      const from = formatDateToXmlDateFormat(this.periodReadingFrom);
      const to = formatDateToXmlDateFormat(this.periodReadingTo);

      if (from === this.readingFrom && to === this.readingTo) {
        return;
      }

      this.readingFrom = from;
      this.readingTo = to;

      const data = { ...this.meterReadingData };
      data[meterId] = await this.meterConsumptionsByFilter({
        meterId: meterId,
        from: this.getAccountPeriod(this.readingFrom),
        to: this.getAccountPeriod(this.readingTo),
        energyType: 'ACTIVE',
        energyDirection: 'INPUT',
      });
      this.meterReadingData = { ...data };
    },
    setDataViewMode(mode, meterId) {

      function unformatStr(str) {
        return str.replace(/\s/g, '');
      }

      const dataModeView = { ...this.dataModeView };
      dataModeView[meterId] = mode;
      this.dataModeView = { ...dataModeView };
      if (mode !== 'LINE_CHART' && mode !== 'BAR_CHART') {
        return;
      }

      this.lineChartData[meterId] = {
        labels: [],
        datasets: [],
      };

      const chartData = {};

      // let xLabel = '';
      let maxCount = 0;

      let startView = 0;

      if (this.dataStartView[meterId] !== undefined) {
        startView = this.dataStartView[meterId].value;
      } else {
        this.dataStartView[meterId] = {
          value: 0,
        };
      }

      function setTariffData(tariffId, value) {
        if (chartData[tariffId] === undefined) {
          chartData[tariffId] = { data: [] };
        }
        chartData[tariffId].data.push(unformatStr(value ?? '0'));
      }

      if (this.meterReadingData[meterId].length >= 1) {
        maxCount = MAX_COUNT_VALUES[mode].MONTHS;
        // xLabel = 'месяц';
        let reverseI = this.meterReadingData[meterId].length - 1;
        for (
          let i = startView;
          i < startView + maxCount && i < this.meterReadingData[meterId].length;
          i++
        ) {
          const reading = this.meterReadingData[meterId][reverseI];

          this.lineChartData[meterId].labels.push(`${reading.accountingPeriod.month}.${reading.accountingPeriod.year}`);

          setTariffData('Т1', reading.t1Consumption);
          setTariffData('Т2', reading.t2Consumption);
          setTariffData('Т3', reading.t3Consumption);

          reverseI--;
        }

        const countMonths = this.meterReadingData[meterId].length;
        this.dataStartView[meterId] = {
          value: startView,
          navigationVisible: countMonths > maxCount,
          prevActive: startView !== 0,
          nextActive: countMonths - (startView + maxCount) > 0,
          periodTitle: '',
        };
      } else {
        maxCount = MAX_COUNT_VALUES[mode].DAYS;
        // xLabel = 'день';

        /* if (this.meterReadingData[meterNumber].months.length !== 0) {
          const month = this.meterReadingData[meterNumber].months[0];

          for (let i = startView; i < startView + maxCount && i < month?.days?.length; i++) {

            const day = month.days[i];

            this.lineChartData[meterNumber].labels.push(day.dayNumber);

            day.summary.forEach((value) => {
              if(chartData[value.id] === undefined) {
                chartData[value.id] = {data: []};
              }
              chartData[value.id].data.push(unformatStr(value.value));
            });
          }

          const countDays = month.days.length;

          this.dataStartView[meterNumber] = {
            value: startView,
            navigationVisible: countDays > maxCount,
            prevActive: startView !== 0,
            nextActive: countDays - (startView + maxCount) > 0,
            periodTitle: 'день',
          };
        }*/
      }

      this.currentTariffs[meterId] = Object.keys(chartData);

      const lineLabels = {
        T1: 'Пиковая зона Т1 (07:00 – 10:00; 17:00 – 21:00)                                                 ',
        T2: 'Ночная зона Т2 (23:00 - 07:00)                                                                           ',
        T3: 'Полупиковая зона Т3 (10:00 – 17:00; 21:00 – 23:00)                                         ',
      };
      const lineColors = [
        '#FF7121',
        '#265CAC',
        '#A0A7A9',
      ];
      /* const tariffColor = {
        '#FF7121': 'T1',
        '#265CAC': 'T2',
        '#A0A7A9': 'T3',
      };*/

      if (mode === 'LINE_CHART') {
        this.lineChartData[meterId].options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            /* position: 'bottom',
            align: 'start'*/
          },
          tooltips: {
            // displayColors: true,
            mode: 'x',
            callbacks: {
              afterBody: () => {
                this.showChartTooltips = true;
              },
            },
            /* custom: (tooltipModel) => {
              this.currentTooltipModel = tooltipModel;
              /!*if (curr - this.hideChartTooltips < 1000) {
                tooltipModel.opacity = 0;
              }*!/
            },*/
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'кВт • ч',
              },
            }],
          },
        };

        Object.keys(chartData).forEach((key, keyIndex) => {
          this.lineChartData[meterId].datasets.push({
            label: lineLabels[key],
            borderColor: lineColors[keyIndex],
            backgroundColor: lineColors[keyIndex],
            fill: false,
            tension: 0,
            data: [],
          });

          chartData[key].data.forEach((value) => {
            this.lineChartData[meterId].datasets[keyIndex].data.push(value);
          });

        });

        return;
      }
      if (mode === 'BAR_CHART') {
        this.lineChartData[meterId].options = {
          tooltips: {
            enabled: false,
          },
          /* tooltips: {
            displayColors: true,
            mode: 'x',
            custom: (tooltipModel) => {
              if (!tooltipModel.body || tooltipModel.body.length === 0 ||
                !tooltipModel.labelColors  ||
                !tooltipModel.title || tooltipModel.title.length === 0) {
                return;
              }

              const newBody = [];

              const currentIndex = this.lineChartData[this.currentMeterNumber].labels.indexOf(tooltipModel.title[0]);

              this.lineChartData[this.currentMeterNumber].datasets.forEach(value => {

                const tariffFromColor = lineLabels[tariffColor[value.backgroundColor[currentIndex]]];

                if (!tariffFromColor) {
                  return;
                }

                newBody.push(
                  {
                    lines: [
                      tariffFromColor + ': ' + value.data[currentIndex]
                    ]
                  }
                );

              });

              tooltipModel.body = newBody;
            }
          },*/
          scales: {
            xAxes: [{
              stacked: true,
              /*             scaleLabel: {
                             display: true,
                             labelString: xLabel
                           }*/
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'кВт • ч',
              },
            }],
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            /* position: 'bottom',
            align: 'start',
            labels: {
              generateLabels: function () {
                const result = [];
                Object.values(lineLabels).forEach((value, index) => {
                  result.push(
                    {
                      text: value,
                      fillStyle: lineColors[index],
                      lineWidth: 0,
                    }
                  );
                });
                return result;
              },
            },*/
          },
          plugins: {
            datalabels: {
              color: '#ffffff',
              /* formatter: function (value) {
                return Math.round(value) + '%';
              },
              font: {
                weight: 'bold',
                size: 16,
              },*/
            },
          },
        };

        Object.keys(chartData).forEach((key/* , index*/) => {
          this.lineChartData[meterId].datasets.push({
            label: lineLabels[key],
            labelColor: '#f30654',
            // labelColor: lineColors[index],
            borderColor: [],
            backgroundColor: [],
            barPercentage: 0.5,
            categoryPercentage: 1,
            tension: 0,
            data: [],
          });
        });

        // TODO WTF?!
        chartData[Object.keys(chartData)[0]]?.data.forEach((value, keyIndex) => {
          this.lineChartData[meterId].datasets[0].data.push(chartData[Object.keys(chartData)[0]].data[keyIndex]);
          this.lineChartData[meterId].datasets[0].backgroundColor.push(lineColors[0]);

          this.lineChartData[meterId].datasets[1].data.push(chartData[Object.keys(chartData)[1]].data[keyIndex]);
          this.lineChartData[meterId].datasets[1].backgroundColor.push(lineColors[1]);

          this.lineChartData[meterId].datasets[2].data.push(chartData[Object.keys(chartData)[2]].data[keyIndex]);
          this.lineChartData[meterId].datasets[2].backgroundColor.push(lineColors[2]);
        });
      }
    },
    nextChartDataView(meterId) {
      if (!this.dataStartView[meterId].nextActive) {
        return;
      }

      if (this.dataStartView[meterId] === undefined) {
        this.dataStartView[meterId] = { value: 1 };
      } else {
        this.dataStartView[meterId].value = this.dataStartView[meterId].value + 1;
      }
      this.setDataViewMode(this.dataModeView[meterId], meterId);
    },
    prevChartDataView(meterId) {
      if (!this.dataStartView[meterId].prevActive) {
        return;
      }

      if (this.dataStartView[meterId] === undefined) {
        this.dataStartView[meterId] = { value: 0 };
      } else {
        this.dataStartView[meterId].value = this.dataStartView[meterId].value - 1;
      }
      this.setDataViewMode(this.dataModeView[meterId], meterId);
    },
    chartScroll(event, meterId) {
      if (!this.dataStartView[meterId] || !this.dataStartView[meterId].navigationVisible) {
        return;
      }

      const currentTimestamp = getTimestamp();
      if (this.showChartTooltips && currentTimestamp - this.lastScrollTime < 500) {
        this.updateChartTooltips = currentTimestamp;
        // this.currentTooltipModel.opacity = 0;
        this.showChartTooltips = false;
      }

      this.lastScrollTime = getTimestamp();

      if (event.deltaY > 0) {
        this.nextChartDataView(meterId);
        return;
      }

      if (event.deltaY < 0) {
        this.prevChartDataView(meterId);
      }
    },
    /* chartScroll(event, meterNumber) {
      if (!this.dataStartView[meterNumber] || !this.dataStartView[meterNumber].navigationVisible) {
        return;
      }

      if (this.hideChartTooltips) {
        this.hideChartTooltips = false;
        return;
      }

      if (event.deltaY > 0) {
        this.nextChartDataView(meterNumber);
        return;
      }

      if (event.deltaY < 0) {
        this.prevChartDataView(meterNumber);
      }


    },*/
    // TODO move currency input in component
    reverseFormatNumber(val) {
      if (!val) {
        return '';
      }
      return val.replace(new RegExp('[\\s₽]', 'g'), '').replace(new RegExp('[,]', 'g'), '.');
    },
    currencyFocus(id, tariff) {
      const input = document.getElementById(`reading_value_${id}${tariff}`);

      if (input.value.length === 0) {
        return;
      }

      input.value = this.reverseFormatNumber(input.value);
    },
    currencyBlur(id, tariff, rate) {
      const input = document.getElementById(`reading_value_${id}${tariff}`);

      if (input.value.length === 0) {
        this.validationReadingsValues(id, rate);
        return;
      }

      input.value = Intl.NumberFormat('ru-RU').format(input.value.replace(new RegExp('[,]', 'g'), '.'));

      this.validationReadingsValues(id, rate);
    },
    currencyInput(id, tariff, rate) {
      const input = document.getElementById(`reading_value_${id}${tariff}`);

      if (input.value.length === 0) {
        this.validationReadingsValues(id, rate);
        return;
      }

      input.value = input.value.replace(new RegExp('[^\\d]', 'gi'), '');

      this.validationReadingsValues(id, rate);
    },
    async sendReadings(id, meter, isProfile) {
      if (isProfile) {
        if (!this.isFillProfile[id]) {
          return;
        }

        this.$set(this.isWaitingSubmit, id, true);
        this.$set(this.startGenerateXml, id, !this.startGenerateXml[id]);
        return;
      }

      if (!this.isValidReadingsValues[id] || this.isWaitingSubmit[id]) {
        return;
      }

      let value2 = '';
      let value3 = '';

      if (document.getElementById(`reading_value_${id}T2`)?.value?.length > 0) {
        value2 = this.reverseFormatNumber(document.getElementById(`reading_value_${id}T2`).value);
      }

      if (document.getElementById(`reading_value_${id}T3`)?.value?.length > 0) {
        value3 = this.reverseFormatNumber(document.getElementById(`reading_value_${id}T3`).value);
      }

      const xml = generateXml.measurementsXml({
        id: meter.id,
        serialNumber: meter.serialNumber,
        type: meter.type,
        dateTime: getCurrentDate('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        value1: this.reverseFormatNumber(document.getElementById(`reading_value_${id}T1`).value),
        value2: value2,
        value3: value3,
      });

      try {
        const oldWaitingSubmit = { ...this.isWaitingSubmit };
        oldWaitingSubmit[id] = true;
        this.isWaitingSubmit = { ...oldWaitingSubmit };

        await this.documentUpload({ xml: xml });
        await this.loadData();

        oldWaitingSubmit[id] = false;
        this.isWaitingSubmit = { ...oldWaitingSubmit };

        this.showSendReadingsForm(false, id);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        const oldWaitingSubmit = { ...this.isWaitingSubmit };
        oldWaitingSubmit[id] = false;
        this.isWaitingSubmit = { ...oldWaitingSubmit };
      }

      this.qualityDetailsNull = {};
    },
    showSendReadingsForm(isShow, id) {
      if (this.isWaitingSubmit[id]) {
        return;
      }

      this.isValidReadingsValues = !this.isValidReadingsValues;

      const oldValue = { ...this.displaySendReadingsForm };
      oldValue[id] = isShow;
      this.displaySendReadingsForm = { ...oldValue };
    },
    // TODO refact to v-model
    validationReadingsValues(id, rate) {
      const input1 = document.getElementById(`reading_value_${id}T1`);
      const input2 = document.getElementById(`reading_value_${id}T2`);
      const input3 = document.getElementById(`reading_value_${id}T3`);

      const oldValue = { ...this.isValidReadingsValues };
      oldValue[id] = (rate === 'SINGLE_RATE' && input1 && input1.value.length > 0) ||
        (rate === 'DOUBLE_RATE' && input1 && input1.value.length > 0 && input2 && input2.value.length > 0) ||
        (rate === 'MULTI_RATE' && input1 && input1.value.length > 0 && input2 && input2.value.length > 0 && input3 && input3.value.length);

      this.isValidReadingsValues = { ...oldValue };
    },
    async createElectricityPayment({ meterNumber, tariff, serviceLocation, owner, address, number, secondnumber }) {
      const xml = generateXml.electricityPaymentXml({
        meterNumber,
        tariff,
        serviceLocation,
        owner,
        address,
        number,
        secondnumber,
      });
      console.log('electricityPaymentXml', xml);

      try {
        this.isWaitingSubmitElectricityPayment = true;

        await this.documentUpload({ xml: xml });
        this.isWaitingSubmitElectricityPayment = false;
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmitElectricityPayment = false;
      }
    },
    tariffValues(object) {
      const tariffRate = this.tariffRateForCategory(object);

      switch (tariffRate) {
        case 'SINGLE_RATE': {
          return [
            {
              zoneTitle: 'Одноставочный тариф',
              zonePeriod: '',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T1),
            },
          ];
        }
        case 'DOUBLE_RATE': {
          return [
            {
              zoneTitle: 'Дневная зона T1 (пиковая и полупиковая)',
              zonePeriod: '(07:00 - 23:00)',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T1),
            },
            {
              zoneTitle: 'Ночная зона Т2',
              zonePeriod: '(23:00 - 07:00)',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T2),
            },
          ];
        }
        case 'MULTI_RATE': {
          return [
            {
              zoneTitle: 'Пиковая зона T1',
              zonePeriod: '(07:00 – 10:00; 17:00 – 21:00)',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T1),
            },
            {
              zoneTitle: 'Ночная зона Т2',
              zonePeriod: '(23:00 - 07:00)',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T2),
            },
            {
              zoneTitle: 'Полупиковая зона Т3',
              zonePeriod: '(10:00 – 17:00; 21:00 – 23:00)',
              value: formatting.currencyFormatted(object.consumer.firstHalfYearTariff.T3),
            },
          ];
        }
      }
    },
    generateInvoice(item) {
      this.selectedItemIdToInvoice = item.meter.id;
      this.isShowGenerateInvoiceModal = true;
    },
    tariffsChanging(serviceLocation) {
      this.tariffsChangingServiceLocation = serviceLocation;
      this.displayTariffsChanging = true;
    },
    menuClose() {
      this.menuKeyForClose++;
    },
    usagePointInConnectedState(usagePoint) {
      const acceptedXmlTypes = ['NEW', 'ACTIVE', 'WAITING_SUPPLY_CONTRACT', 'WAITING_ADDITION_APPROVE'];
      return acceptedXmlTypes.includes(usagePoint.pointStatus);
    },
    tariffRateForCategory(usagePoint) {
      return usagePoint.consumer?.tariff ?? '';
    },
    isPowerProfile(usagePoint) {
      return usagePoint.tariffCategory !== 'TARIFF_CATEGORY_1' && usagePoint.tariffCategory !== 'TARIFF_CATEGORY_2';
    },
    fillProfile(count, id) {
      this.$set(this.isFillProfile, id, count > 0);
    },
    async submitFinally(id, isSubmitted) {
      await this.loadData();
      this.$set(this.isWaitingSubmit, id, false);
      this.$set(this.displaySendReadingsForm, id, !isSubmitted);
    },
    getAccountPeriod(date) {
      const momentDate = getMomentDate(date);

      return {
        year: momentDate.year(),
        month: momentDate.month() + 1,
      };
    },
    ...mapActions('cabinet', ['createPaymentUrl', 'generateAccountRefillInvoice']),
    ...mapActions('serviceLocations', ['loadUsagePointsIndividualWithMeasurements', 'meterConsumptionsByFilter', 'powerProfilesByMeterId']),
    ...mapActions('document', ['documentUpload']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 100%
  min-height: 100%
  overflow-y: auto
  padding: 16px
  position: relative
  display: flex
  flex-direction: row
  @media screen and (max-width: 1000px)
    flex-direction: column


.innerContainer
  width: 100%
  min-height: 100%
  overflow-y: auto
  padding: 16px
  position: relative
  display: flex
  flex-direction: column

.personalAccount
  padding-right: 32px
  padding-left: 16px
  display: inline-flex
  flex-direction: column

  h1
    +base-card-title
    color: #4C4E51

  p
    +base-card-title
    font-size: 36px
    min-width: 200px

.noObjects
  margin: 20% auto

  p
    +base-title
    color: #4C4E51
    text-align: center

    span
      text-decoration: underline
      cursor: pointer

// Карточка, в которой только заголовок и текст
.content
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
  position: relative
  margin-bottom: 16px
  margin-right: 16px
  max-width: 848px

// Карточка, в которой очень много всего
.contentConnected
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
  position: relative
  margin-bottom: 16px
  margin-right: 16px
  max-width: 848px

.addContainer
  width: 100%

.add
  max-width: 848px
  min-height: 58px
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
  position: relative
  margin-bottom: 16px
  cursor: pointer
  margin-right: 16px

.add p
  padding-top: 16px
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px

.locationInfo
  padding: 16px 16px 0 24px

.objectInfo
  background: #F5F6F6

.shadowBottom
  box-shadow: 0 5px 5px -3px #C1C4C7

.minHeight260
  min-height: 260px

.objectConnectedInfo
  position: relative
  display: flex
  width: 100%
  //min-height: 230px
  //background: #F5F6F6
  //background: var(--color-background-gray-50)
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  border-top: 2px solid #F5F6F6
  margin-top: 16px

  img.bigIcon
    height: 32px
    width: 32px
    margin-top: 24px
    margin-left: 16px
    margin-right: 16px

  img.microgeneratorIcon
    height: 40px
    width: 40px

  img.menuIcon, svg.menuIcon
    position: absolute
    left: 8px
    top: 8px
    height: 32px
    width: 24px
    margin: 0

.meterInfo

  width: 200px
  //background-color: green

  .title
    margin-top: 24px
    margin-bottom: 8px

  .info
    +base-card-text
    font-family: Roboto

    &:not(:last-child)
      margin-bottom: 4px

.textHelper
  +small-text
  display: flex

.meterNotData
  text-align: center
  padding: 8px
  +base-text

.title
  +base-card-title

.address
  +base-card-text

  &:not(:last-child)
    margin-bottom: 6px

.supplierOfLastResort
  margin-bottom: 4px
  +card-title-small

.contract
  +card-caption

.menu
  cursor: pointer
  position: absolute
  top: 8px
  right: 8px

.popupMenu
  position: relative
  height: 48px
  width: 231px
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)

.popupMenu span
  position: absolute
  color: #FFFFFF
  left: 44px
  top: 16px
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 18px

.sendReadingsMenu, .changeTariffMenu
  background: #FFFFFF

.changeTariffMenu
  svg
    height: 16px !important
    fill: #0E0F0F
    top: 16px !important

.microgeneratorMenu
  background: #3894FB

.sendReadingsMenu, .changeTariffMenu
  span
    color: #0E0F0F

.status
  display: flex
  z-index: 999
  position: relative

  svg
    height: 16px
    width: 16px
    margin: 0
    margin-right: 4px
    fill: #54B687

.errorStatus
  svg
    fill: #e9441a

.readingDetails
  display: flex
  position: absolute
  bottom: 12px

  img
    display: block
    cursor: pointer
    margin-left: 0

  img.arrow
    display: none
    height: 12px
    width: 16px
    margin: 0 0 0 24px

.arrowShowDetails
  img.arrow
    display: block

.showDetails
  bottom: 0

.readingInfo
  position: relative
  width: 464px
  margin-left: 40px
  margin-top: 24px
  margin-right: 72px

  .choice
    span
      +base-card-title
      border-bottom: 4px solid #D5D7DA
      padding-left: 16px
      padding-right: 16px
      cursor: pointer

    span.active
      border-bottom: 4px solid #2F82DF

    span.kvtch
      +mg-base-card-text
      border-bottom: none
      padding-right: 0
      margin-top: 8px
      cursor: default
      position: absolute
      right: 0

.periodPreset
  margin-top: 16px
  margin-bottom: 32px

  span
    color: #0F1010

  input
    +base-button-text
    color: #0F1010
    width: 100%
    padding: 4px 0 4px 16px
    border: 1px solid #E9EBED
    background: #FFFFFF

.tariffName
  +base-card-text
  color: #4C4E51
  display: flex
  gap: 16px
  margin-top: 0
  //margin-left: 32px
  img
    width: 16px
    height: 16px
    margin: 0 4px 0 0

.tariffSummary
  +mg-card-title
  display: flex
  gap: 16px
  //margin-left: 32px
  margin-bottom: 12px

.tariffSummary
  vertical-align: center

  .tariffSummaryTitle
    +mg-base-card-text
    font-weight: normal
    vertical-align: center

.securityDetails
  padding: 48px 72px 56px 72px

  svg
    fill: #54B687
    margin-right: 8px

  table
    width: 100%

    tbody
      tr
        height: 24px
        width: 100%

      th
        +small-text
        width: 40%
        vertical-align: center
        text-align: right
        padding-right: 24px

      td
        +base-text
        width: 60%
        text-align: left
        padding-left: 24px
        vertical-align: center

        p
          margin-bottom: 6px
          margin-top: 6px

  .lockIcons
    +base-text

    svg
      width: 16px
      height: 16px

  svg.connectingIcon
    width: 24px
    height: 24px

  .textBase
    +base-text

  .titleSmall
    +base-subtitle

  .titleBig
    +big-title
    line-height: 50px

  .textSmall
    +small-text

  .titleBase
    +base-card-title

.readingsDetails
  padding: 48px 72px 56px 72px

  svg
    fill: #54B687

  table
    width: 100%

    tr
      width: 100%

      th
        width: 40%
        //border: 1px solid #F5F6F6
        text-align: left

      td
        text-align: right

      td.readingValue
        width: 19%
        text-align: center
      //border: 1px solid #F5F6F6

      td.showMore
        width: 3%

        svg
          cursor: pointer

        svg.chevronDown
          width: 24px
          height: 24px
          display: block


        svg.chevronUp
          width: 24px
          height: 24px
          display: none

    tr.openDetails
      td.readingValue
        color: #DFE1E5

      td.showMore
        svg.chevronDown
          display: none

        svg.chevronUp
          display: block

  tr
    height: 40px
    +table-data-small

    th
      font-weight: normal

  tr.emptyRow
    height: 10px

  thead
    tr
      vertical-align: top

      .tariffNameDetails
        +table-data-small

      .tariffPeriodsDetails
        +small-text

    tr
      .linkText p
        +small-text
        text-decoration: underline
        cursor: pointer

  tr.borderBottom1, tr.borderBottom2, thead tr th
    +table-data-base

  tr.borderBottom1
    border-bottom: 1px solid #D5D7DA

  tr.openDetails
    border-bottom: none

  tr.openDetails + tr
    border-bottom: 1px solid #D5D7DA

  tr.borderBottom2
    border-bottom: 2px solid #D5D7DA

.displayNone
  display: none

.disabled
  pointer-events: none
  opacity: 0.4

.summaryTable
  width: 100%

  td
    width: 129px
    text-align: left

  td.tariffSummaryTitle
    width: 200px
    text-align: left

.chartsIcons
  svg
    width: 24px
    height: 24px
    margin-right: 8px
    fill: #0E0F0F
    cursor: pointer

  .active
    svg
      fill: #979CA2
      cursor: default

.nextValueLink
  text-align: right !important
  width: 100px

  span
    font-size: 16px
    line-height: 24px
    text-transform: uppercase
    color: #E9EBED

  svg
    fill: #E9EBED

.prevValueLink
  text-align: left !important
  width: 100px

  span
    font-size: 16px
    line-height: 24px
    text-transform: uppercase
    color: #E9EBED

  svg
    fill: #E9EBED

.activeNavigation
  cursor: pointer

  svg
    fill: #0F1010

  span
    color: #0F1010

.periodType
  position: relative

  span
    position: absolute
    right: -70px
    bottom: 5px
    font-family: Roboto
    font-size: 12px
    line-height: 18px
    color: #71757A

  span.periodTypeLineChart
    right: -50px

.legend
  text-align: left !important
  width: 300px

  hr
    width: 30px
    height: 10px
    border: 0
    display: inline-block
    margin: 0 auto

  span
    font-family: 'Roboto', sans-serif
    font-size: 14px
    margin-left: 8px

.t1Color
  background-color: #FF7121

.t2Color
  background-color: #265CAC

.t3Color
  background-color: #A0A7A9

.range
  input
    width: 100%
    margin-top: 6px

.sendReadings
  width: 100%
  text-align: center
  margin-bottom: 12px

  span
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 26px
    color: #979CA2

  button
    width: 100%
    height: 40px
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 21px
    line-height: 24px
    color: #FFF
    background-color: #2F82DF
    cursor: pointer

  button.cancel
    background-color: #71757A

  button.invalid
    background-color: #DFE1E6
    cursor: default

.sendReadingValues
  margin-top: 32px

  .tariffName
    margin-bottom: 6px

    td
      text-align: center

  .tariffSummary
    input
      text-align: right

.currencyField
  font-weight: 400
  font-size: 21px
  line-height: 30px
  width: 129px
  font-family: 'Roboto Condensed'
  padding: 4px 16px
  color: #4F4F4F
  background: #FFF
  border: 1px solid #141412

  &::placeholder
    color: #E8E8E8

  &:focus
    outline: none

  &.date
    line-height: 28px

.tariffInfo
  +base-text

  .tariffValue
    position: absolute
    right: 32px

  .period
    +small-text

  p
    max-width: 400px

.progressOverlay
  position: absolute
  top: -200px
  z-index: 999
  width: 100%
  height: 100%
  display: flex
  justify-content: center

.progress
  position: relative
  width: 300px
  text-align: center
  max-height: 200px
  max-width: 700px
  align-self: center
  overflow-x: hidden
  overflow-y: auto
  box-sizing: border-box
  background: #eadfdf

.tariffPeriod
  font-family: Roboto
  font-style: normal
  font-weight: normal
  font-size: 8px
  line-height: 9px
  margin-bottom: 4px
  margin-top: 4px

.rightAlignPeriod
  min-width: 160px

.powerProfile
  padding-bottom: 32px

  h3
    width: 100%
    font-family: Roboto
    font-size: 16px
    font-weight: 400
    line-height: 20px
    letter-spacing: 0em
    text-align: left
    margin: 0 !important

.powerProfileDownload
  display: flex
  width: fit-content
  padding: 4px 8px 4px 16px
  border-radius: 24px
  border: 1px solid #C1C4C7
  margin-bottom: 32px

  .downloadTitle
    margin-right: 8px
    font-family: Roboto
    font-size: 14px
    font-weight: 500
    color: #262729

</style>
