<template>
  <div
    class="action-block"
    :class="{'disabled': isDisabled}"
  >
    <div class="action-block__add-path">
      <div class="action-block__block-select-path" :style="{minHeight: height + 'px'}">
        <img v-if="displayIcon" src="~@/assets/icons/basic/document-select.svg"  alt=""/>
        <select
            v-if="isSelect"
            v-model="selectedItemId"
            class="action-block__select-path"
        >
          <option v-for="(path, index) in items" :key="index"
                  :value="path.id"
          >
            {{ path.title }}
          </option>
        </select>
        <v-select
            v-else
            v-model="selectedItemId"
            new-style
            no-border
            :options="itemsForCustomSelect"
            :style="minWidth ? {minWidth: minWidth + 'px'} : {}"
        />
      </div>
      <div
          :class="{
            ['action-block__form-path']: true,
            ['action-block__form-path--disabled']: isDisabled,
            ['action-block__form-path--select']: type === 'select',
            ['action-block__form-path--add']: type === 'add',
          }"
          @click="callSelectedItemMethod"
      >
        <img
            v-if="isDisabled"
            src="~@/assets/icons/basic/select-choice-disabled.svg"
            alt="Перейти"
            :style="{height: height + 'px'}"
        />
        <img
            v-if="!isDisabled && type === 'select'"
            src="~@/assets/icons/basic/select-choice.svg"
            alt="Перейти"
            :style="{height: height + 'px'}"
        />
        <img
            v-if="!isDisabled && type === 'add'"
            src="~@/assets/icons/basic/add_button.svg"
            alt="Перейти"
            :style="{height: height + 'px'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VSelect } from 'components';

export default {
  components: {
    VSelect,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    displayIcon: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '42',
    },
    type: {
      type: String,
      default: 'select',
    },
    minWidth: {
      type: String,
      required: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItemId: null,
    };
  },
  computed: {
    isDisabled() {
      if (this.disabled !== undefined) {
        return this.disabled;
      }
      return false;
    },
    itemsForCustomSelect() {
      return this.items.map((item) => ({
        ...item,
        label: item.title,
        value: String(item.id) ?? '1',
        id: String(item.id) ?? '1',
      }));
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.selectedItemId = this.itemsForCustomSelect[0].id;
      },
    },
  },
  methods: {
    callSelectedItemMethod() {
      if (this.isDisabled) {
        return;
      }

      const selectedItem = this.itemsForCustomSelect.find((path) => path.id === this.selectedItemId);

      if (selectedItem.method) {
        selectedItem.method();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-block {
  &__add-path {
    display: flex;
    gap: 4px;
    height: 100%;
  }

  &__block-select-path {
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: 1px solid #2F82DF;
    border-radius: 24px 2px 2px 24px;
    background-color: inherit;

    img {
      height: 16px;
    }
  }

  &__select-path {
    background: inherit;
    border: 0;
  }

  &__form-path {
    cursor: pointer;
    width: 34px;
    min-width: 34px;
    height: 100%;
    background-color: #2F82DF;
    border-radius:  4px 24px 24px 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
      background-color: #C1C4C7;
      cursor: default;
    }

    &--select {
      width: 34px;
      min-width: 34px;
    }

    &--add {
      width: 38px;
      min-width: 38px;
    }
  }
}
.action-block.disabled {
  pointer-events: none;
}

</style>
