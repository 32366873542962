<template>
  <div>
    <component
      :is="step.component"
      v-for="(step, index) in filteredSteps"
      :key="`step-${index}`"
      v-bind="step.props"
      :step-number="index + 1"
      :total-steps="filteredSteps.length"
      :errors="errors[step.errorKey]"
    />
  </div>
</template>

<script>
import ReasonToConnection from './groups/ReasonToConnection';
import ObjectLocation from './groups/ObjectLocation';
import ConfirmationDocument from './groups/ConfirmationDocument';
import MaximumPowerVoltage from './groups/MaximumPowerVoltage';
import ReliabilityClass from './groups/ReliabilityClass';
import Workload from './groups/Workload';
import ReadyConnectionDate from './groups/ReadyConnectionDate';
import ConnectionCharacteristics from './groups/ConnectionCharacteristics';
import SupplierOfLastResort from './groups/SupplierOfLastResort';
import Microgenerator from './groups/Microgenerator';
import DocumentScans from './groups/DocumentScans';
import GridCompany from './groups/GridCompany';

export default {
  name: 'ObjectData',
  components: {
    ObjectLocation,
    GridCompany,
    ConfirmationDocument,
    MaximumPowerVoltage,
    ReliabilityClass,
    Workload,
    ReadyConnectionDate,
    ConnectionCharacteristics,
    SupplierOfLastResort,
    Microgenerator,
    DocumentScans,
    ReasonToConnection,
  },
  props: {
    connectionApplication: {
      type: Object,
      required: true,
    },
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    isAdditionalOwner: {
      type: Boolean,
      default: false,
    },
    minSteps: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    isLegal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      baseStep: 1,
    };
  },
  computed: {
    steps() {
      return [
        {
          component: ReasonToConnection,
          condition: this.isDisplayReasonToConnection,
          props: { connectionApplication: this.connectionApplication },
          errorKey: 'reasonToConnection',
        },
        {
          component: ObjectLocation,
          condition: true,
          props: { objectLocation: this.connectionApplication.objectLocation },
          errorKey: 'objectLocation',
        },
        {
          component: ConfirmationDocument,
          condition: true,
          props: { confirmationDocument: this.connectionApplication.confirmationDocument },
          errorKey: 'confirmationDocument',
        },
        {
          component: ReliabilityClass,
          condition: true,
          props: { connectionApplication: this.connectionApplication },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: Workload,
          condition: this.isLegal,
          props: { connectionApplication: this.connectionApplication },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: MaximumPowerVoltage,
          condition: true,
          props: {
            connectionApplicationType: this.connectionApplication.type,
            connectionCharacteristics: this.connectionApplication.connectionCharacteristics,
            reasonToConnection: this.connectionApplication.reasonToConnection,
          },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: ReadyConnectionDate,
          condition: true,
          props: { connectionApplicationType: this.connectionApplication.type, connectionCharacteristics: this.connectionApplication.connectionCharacteristics },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: SupplierOfLastResort,
          condition: true,
          props: { connectionCharacteristics: this.connectionApplication.connectionCharacteristics, connectionApplication: this.connectionApplication },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: ConnectionCharacteristics,
          condition: true,
          props: { connectionApplicationType: this.connectionApplication.type, connectionCharacteristics: this.connectionApplication.connectionCharacteristics },
          errorKey: 'connectionCharacteristics',
        },
        {
          component: Microgenerator,
          condition: this.onlyMicrogenerator,
          props: { microgenerator: this.connectionApplication.microgenerator },
          errorKey: 'microgenerator',
        },
        {
          component: GridCompany,
          condition: true,
          props: { gridCompany: this.connectionApplication.gridCompany },
          errorKey: 'gridCompany',
        },
        {
          component: DocumentScans,
          condition: true,
          props: { consumer: this.connectionApplication.consumer, documentScans: this.connectionApplication.documentScans, isAdditionalOwner: this.isAdditionalOwner },
          errorKey: 'documentScans',
        }];
    },
    filteredSteps() {
      return this.steps.filter((step) => step.condition);
    },
    isDisplayReasonToConnection() {
      const availableOptions = ['INDIVIDUALS_UP_TO_15', 'INDIVIDUALS_OVER_15', 'LEGAL_ENTITIES_UP_TO_150', 'LEGAL_ENTITIES_OVER_150', 'MICROGENERATION'];
      return availableOptions.includes(this.connectionApplication.type);
    },
  },
};
</script>

<style scoped>

</style>
