import { render, staticRenderFns } from "./HelpAboutCompany.vue?vue&type=template&id=245027a1&scoped=true"
import script from "./HelpAboutCompany.vue?vue&type=script&lang=js"
export * from "./HelpAboutCompany.vue?vue&type=script&lang=js"
import style0 from "./HelpAboutCompany.vue?vue&type=style&index=0&id=245027a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245027a1",
  null
  
)

export default component.exports