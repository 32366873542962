<template>
  <v-form class="page-form" @error="onError" @submit="onSubmit">
    <div>
      <div v-if="!hideStatus">
        <p class="page-form__title">
          Выбрать статус заявки
        </p>
        <div class="page-form__controlDiv">
          <label :class="{['page-form__controlDiv-label']: true, ['page-form__controlDiv-label--disabled']: isLoading }">
            <div class="control-contentRadioGroup">
              <div class="control-titleContainer">
                <div :class="getCheckboxClass(applicationStatus === 1)"></div>
                <h3 class="control-titleRadioGroup">Выполнена</h3>
              </div>
            </div>
            <input
                :checked="applicationStatus === 1"
                :disabled="isLoading"
                class="control"
                name="applicationStatus"
                type="radio"
                @click="applicationStatusClick(1)"
            />
          </label>
          <label :class="{['page-form__controlDiv-label']: true, ['page-form__controlDiv-label--disabled']: isLoading }">
            <div class="control-contentRadioGroup">
              <div class="control-titleContainer">
                <div :class="getCheckboxClass(applicationStatus === 2)"></div>
                <h3 class="control-titleRadioGroup">Аннулирована</h3>
              </div>
            </div>
            <input
                :checked="applicationStatus === 2"
                :disabled="isLoading"
                class="control"
                name="applicationStatus"
                type="radio"
                @click="applicationStatusClick(2)"
            />
          </label>
        </div>

        <v-divider class="mt-24 mb-24" />

      </div>

      <div v-if="applicationStatus === 2">
        <p>* обязательно заполните это поле</p>

        <div class="row mb-16">
          <p class="col col--1of1 page-form__sub-title">Указать причину аннулирования заявки *</p>
        </div>
        <div class="row mb-16">
          <c-textarea v-model="cancelledComments"
                      class="col col--1of1"
          />
        </div>

        <div>
          <div class="row mb-16">
            <p class="col col--1of1 page-form__sub-title">
              Приложить необходимые документы
            </p>
          </div>

          <div v-if="cancelledDocs.length" class="row mb-16">
            <file-list-preview
              :list="cancelledDocs"
              action
              class="col col--1of1"
              @delete="handleRemoveCancelledDocs"
            />
          </div>

          <div class="row mb-16">
            <c-uploading
              v-model="cancelledDocs"
              class="col col--1of1"
            />
          </div>
        </div>

      </div>

      <div v-if="applicationStatus === 1" class="page-form__form">
        <div>
          <div class="row mb-24">
            <h2 class="col col--1of1 page-form__sub-title">
              <span v-if="isMeterSwap">Акт замены электрического счётчика</span>
              <span v-else>Акт допуска в эксплуатацию прибора учета электрической энергии</span>
            </h2>
          </div>

          <div v-if="AdmissionCertificate.AdmissionCertificateNumber" class="row mb-24">
            <v-input v-model="AdmissionCertificate.AdmissionCertificateNumber" class="col col--1of1" disabled
                     title="Номер документа (DID) *"/>
          </div>
          <div class="row mb-24">
            <v-input v-model="AdmissionCertificate.Utility.Name" class="col col--1of1" disabled
                     title="Наименование сетевой организации *"/>
          </div>
          <div v-if="AdmissionCertificate.Utility.RepresentativeFullName" class="row mb-24">
            <v-input v-model="AdmissionCertificate.Utility.RepresentativeFullName" class="col col--1of1" disabled
                     title="Представитель сетевой организации *"/>
          </div>
          <div v-if="AdmissionCertificate.Utility.RepresentativeFullName" class="row mb-24">
            <signing-basis-selector :input-mode-type="basisSelectorMode" :power-of-attorney-list="attorneys"
                                    class="col col--1of1"
                                    style="margin-bottom: -16px"
                                    @select="(val) => AdmissionCertificate.Utility.CompanyDocument = val"/>
          </div>

          <template v-if="AdmissionCertificate.Retailer.Name">
            <div class="row mb-24">
              <v-input v-model="AdmissionCertificate.Retailer.Name" class="col col--1of1" disabled title="Наименование субъекта розничного рынка, с которым у заявителя заключен договор
                энергоснабжения *"></v-input>
            </div>

            <div class="row mb-24">
              <div class="col col--1of1">
                <emploee-select-or-input
                    :inn="applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn"
                    :not-emit-input-reason="true"
                    mode="select"
                    permission="SVET_A3-S"
                    @emploeeSelectOrInput="selectEmploee"
                />
              </div>
            </div>
          </template>

          <div v-if="!isMeterSwap" class="row mb-24">
            <div class="col col--1of1">
              <v-input v-model="AdmissionCertificate.Consumer.Name" class="col col--1of1" disabled
                       title="Фамилия, имя, отчество или наименование потребителя или его представителя *"/>
            </div>
            <div v-if="isCompanyApplication" class="col col--1of1">
              <emploee-select-or-input
                :inn="AdmissionCertificate.Consumer.Inn"
                :not-emit-input-reason="true"
                is-company-mode
                mode="select"
                permission="SVET_A7-S"
                @emploeeSelectOrInput="selectConsumerEmploee"
              />
            </div>

          </div>
          <!--TODO вернуть -->
          <!--          <div>-->
          <!--            <c-input-->
          <!--              v-model="ConnectionCertificate.CustomerRepresentative"-->
          <!--              label="Ф.И.О. лица – представителя заявителя"-->
          <!--              role="CustomerRepresentative"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <c-input-->
          <!--              v-model="ConnectionCertificate.CustomerDocument"-->
          <!--              label="Устав, доверенность, иной документ заявителя"-->
          <!--              role="CustomerDocument"-->
          <!--            />-->
          <!--          </div>-->

          <div v-if="!isMeterSwap" class="row mb-24">
            <v-date-picker v-model="AdmissionCertificate.VerificationDate" class="col col--1of1"
                           format="DD.MM.YYYY HH:mm"
                           rules="required"
                           title="Дата и время проведения проверки *"
                           type="datetime"
            />
          </div>
          <template v-else>
            <div class="row mb-24">
              <v-input v-model="AdmissionCertificate.Consumer.Name"
                       class="col col--1of1"
                       rules="required"
                       title="Наименование организации-заказчика *" />
            </div>
            <div class="row mb-24">
              <v-input
                  v-model="AdmissionCertificate.Consumer.Inn"
                  :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                  :rules-messages="{digits: 'Данные ИНН введены неправильно'}"
                  class="col col--1of1"
                  clearable
                  required
                  rules="required|digits:10|inn"
                  title="ИНН организации-заказчика"
              />
            </div>
          </template>

          <div class="row mb-24">
            <v-divider/>
          </div>

          <template v-if="applicationData?.gridCompany">
            <v-spoiler :is-open="false" class="row mb-24"
                       summary="1 | Сведения о точке поставки" w100>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.UsagePoint.Address"
                         class="col col--1of2"
                         disabled
                         title="Адрес *"
                />
                <v-input v-model="AdmissionCertificate.UsagePoint.Name"
                         class="col col--1of2"
                         disabled
                         title="Наименование *"
                />
              </div>

              <usage-point-connection-select v-if="AdmissionCertificate.UsagePoint.PowerSupplyCenter"
                                             v-model="AdmissionCertificate.UsagePoint.PowerSupplyCenter"
                                             disabled two-column-style
              />
            </v-spoiler>
          </template>

          <v-spoiler :is-open="isOpenSpoiler.spoiler2" :summary="titleSpoiler2"
                     class="row mb-24" w100 @toggle="toggleSpoiler('spoiler2')">
            <template v-if="isMeterSwap">
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.City" class="col col--1of1"
                         rules="required"
                         title="Город *"
                         vid="AdmissionCertificate.City"/>
              </div>
              <div class="row mb-24">
                <v-address-search-select
                    v-model="AdmissionCertificate.Address"
                    :rules-messages="{required: 'Обязательно для заполнения.'}"
                    class="col col--1of1"
                    rules="required"
                    title="Адрес *"
                    vid="AdmissionCertificate.Address"
                />
              </div>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Place" class="col col--1of1"
                         rules="required"
                         title="Место замены *"
                         vid="AdmissionCertificate.Place"/>
              </div>

              <div class="row mb-24">
                <h2 class="col col--1of1 page-form__sub-title">
                  Заменяемый прибор учёта
                </h2>
              </div>

              <div class="row mb-24">
                <v-search-select v-model="meterSelectSerial" :options="metersValueLabelList" class="page-form__search-select" emit-filter
                                 required title="Заводской номер" vid="meterSelectSerial" @changeFilter="changeFilterMeters" @scrolledToEnd="metersScrolledToEnd"/>
              </div>
            </template>

            <div v-else class="row mb-24">
              <meter-model-select v-model="meterModel" class="col col--1of1" required vid="meterModel"/>
            </div>

            <div v-show="AdmissionCertificate.Meter.Type?.length">
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Meter.AccuracyClass" class="col col--1of1" disabled
                         title="Класс точности *"/>
              </div>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Meter.RatedCurrent" class="col col--1of2" disabled
                         title="Номинальный ток, А *"/>
                <v-input v-model="AdmissionCertificate.Meter.NominalVoltage" class="col col--1of2" disabled
                         title="Номинальное напряжение, В *"/>
              </div>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Meter.IntegerDigits" class="col col--1of2" disabled
                         title="Разрядность (до запятой) *"/>
                <v-input v-model="AdmissionCertificate.Meter.FractionDigits" class="col col--1of2" disabled
                         title="Разрядность (после запятой) *"/>
              </div>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Meter.Location"
                         :disabled="isMeterSwap"
                         :rules="isMeterSwap ? '' : 'required'"
                         class="col col--1of2"
                         title="Место установки *"
                         vid="AdmissionCertificate.Meter.Location"
                />
                <v-select v-if="sealOrganisations.length > 0" v-model="AdmissionCertificate.Meter.Owner"
                          :disabled="isMeterSwap"
                          :options="sealOrganisations"
                          :rules="isMeterSwap ? '' : 'required'"
                          class="col col--1of2"
                          title="Балансовая принадлежность *"
                        vid="AdmissionCertificate.Meter.Owner"
              />
              <v-input v-else v-model="AdmissionCertificate.Meter.Owner"
                       :disabled="isMeterSwap"
                       :rules="isMeterSwap ? '' : 'required'"
                       class="col col--1of2"
                       title="Балансовая принадлежность *"
                       vid="AdmissionCertificate.Meter.Owner"
              />
            </div>
            <div class="row mb-24">
              <v-input v-model="AdmissionCertificate.Meter.Serial"
                       :disabled="isMeterSwap"
                       :error="meterNumberError"
                       :rules="isMeterSwap ? '' : 'required'"
                       :validation="$validate.isNotEmptyString"
                       class="col col--1of2"
                       title="Заводской номер *"
                       vid="AdmissionCertificate.Meter.Serial"
              />
              <v-input v-if="isMeterSwap" v-model="AdmissionCertificate.Meter.ProductionYear"
                       class="col col--1of2"
                       disabled
                       title="Год выпуска *"
                       vid="AdmissionCertificate.Meter.ProductionYear"
              />
              <v-date-picker v-else v-model="AdmissionCertificate.Meter.ProductionYear"
                             :rules="isMeterSwap ? '' : 'required'"
                             class="col col--1of2"
                             format="YYYY"
                             is-disabled-future-dates
                             title="Год выпуска *"
                               type="year"
                               vid="AdmissionCertificate.Meter.ProductionYear"
                />
              </div>
              <div class="row mb-24">
                <v-date-picker v-model="AdmissionCertificate.Meter.CalibrationDate"
                               :disabled="isMeterSwap"
                               :rules="isMeterSwap ? '' : 'required'"
                               class="col col--1of3"
                               title="Дата поверки *"
                               vid="AdmissionCertificate.Meter.CalibrationDate"
                />
                <v-date-picker v-model="AdmissionCertificate.Meter.NextCalibrationDate"
                               class="col col--1of3"
                               disabled
                               title="Дата следующей поверки *"
                />
                <v-input v-model="AdmissionCertificate.Meter.CalibrationInterval"
                         class="col col--1of3"
                         disabled
                         title="Межповерочный интервал *"
                />
              </div>
            </div>

            <div v-show="AdmissionCertificate.Meter.Type?.length">
              <div class="row mb-24">
                <h2 class="col col--1of1 page-form__sub-title">
                  Показания электрической энергии
                </h2>
              </div>

              <div v-for="(reading, index) in AdmissionCertificate.Readings.Value" :key="'Readings_' + index">
                <div style="position: relative">
                  <div class="row mb-24">
                    <v-date-picker v-model="reading.time"
                                   :vid="'reading.time_' + index"
                                   class="col col--1of2"
                                   format="DD.MM.YYYY HH:mm"
                                   rules="required"
                                   title="Дата и время *"
                                   type="datetime"
                    />
<!--                    <v-select v-model="reading.tariff"
                              :options="availableMeterTariffs(reading.tariff)"
                              class="col col&#45;&#45;1of2"
                              rules="required"
                              :vid="'reading.tariff_' + index"
                              style="margin-top: 24px"
                              title="Номер тарифа *"
                              type="number"
                    />-->
                  </div>
                  <button v-if="AdmissionCertificate.Readings.Value.length > 1" class="row mb-24" style="background: inherit; align-items: center; position: absolute; right: -32px; top: 30px"
                          @click.prevent="deleteReading(index)">
                    <cross-delete/>
                  </button>
                </div>

                <div class="row mb-24">
                  <v-input v-model="reading.ReadingT1"
                           class="col col--1of3"
                           title="T1"
                  />
                  <v-input v-model="reading.ReadingT2"
                           class="col col--1of3"
                           title="T2"
                  />
                  <v-input v-model="reading.ReadingT3"
                           class="col col--1of3"
                           title="T3"
                  />
                </div>
              </div>

              <div class="row mb-24">
                <button @click.prevent="addReading(AdmissionCertificate.Readings)">+ ещё
                  показания
                </button>
              </div>

            </div>

            <template v-if="isMeterSwap">
              <div class="row mb-24">
                <v-divider/>
              </div>

              <div class="row mb-24">
                <h2 class="col col--1of1 page-form__sub-title">
                  Устанавливаемый прибор учёта
                </h2>
              </div>

              <div class="row mb-24">
                <meter-model-select v-model="installableMeterModel" class="col col--1of1" required vid="installableMeterModel"/>
              </div>

              <div v-show="AdmissionCertificate.InstallableMeter.Type?.length">
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.InstallableMeter.AccuracyClass" class="col col--1of1" disabled
                           title="Класс точности *"/>
                </div>
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.InstallableMeter.RatedCurrent" class="col col--1of2" disabled
                           title="Номинальный ток, А *"/>
                  <v-input v-model="AdmissionCertificate.InstallableMeter.NominalVoltage" class="col col--1of2" disabled
                           title="Номинальное напряжение, В *"/>
                </div>
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.InstallableMeter.IntegerDigits" class="col col--1of2" disabled
                           title="Разрядность (до запятой) *"/>
                  <v-input v-model="AdmissionCertificate.InstallableMeter.FractionDigits" class="col col--1of2" disabled
                           title="Разрядность (после запятой) *"/>
                </div>
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.InstallableMeter.Location"
                           class="col col--1of2"
                           rules="required"
                           title="Место установки *"
                           vid="AdmissionCertificate.InstallableMeter.Location"
                  />
                  <v-select v-if="sealOrganisations.length > 0" v-model="AdmissionCertificate.InstallableMeter.Owner"
                            :options="sealOrganisations"
                            class="col col--1of2"
                            rules="required"
                            title="Балансовая принадлежность *"
                            vid="AdmissionCertificate.InstallableMeter.Owner"
                />
                <v-input v-else v-model="AdmissionCertificate.InstallableMeter.Owner"
                         class="col col--1of2"
                         rules="required"
                         title="Балансовая принадлежность *"
                         vid="AdmissionCertificate.InstallableMeter.Owner"
                />
              </div>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.InstallableMeter.Serial"
                         :error="meterNumberError"
                         :validation="$validate.isNotEmptyString"
                         class="col col--1of2"
                         rules="required"
                         title="Заводской номер *"
                         vid="AdmissionCertificate.InstallableMeter.Serial"
                />
                <v-date-picker v-model="AdmissionCertificate.InstallableMeter.ProductionYear"
                               class="col col--1of2"
                               format="YYYY"
                               is-disabled-future-dates
                               rules="required"
                               title="Год выпуска *"
                                 type="year"
                                 vid="AdmissionCertificate.InstallableMeter.ProductionYear"
                  />
                </div>
                <div class="row mb-24">
                  <v-date-picker v-model="AdmissionCertificate.InstallableMeter.CalibrationDate"
                                 class="col col--1of3"
                               rules="required"
                               title="Дата поверки *"
                                 vid="AdmissionCertificate.InstallableMeter.CalibrationDate"
                  />
                  <v-date-picker v-model="AdmissionCertificate.InstallableMeter.NextCalibrationDate"
                                 class="col col--1of3"
                               disabled
                                 title="Дата следующей поверки *"
                  />
                  <v-input v-model="AdmissionCertificate.InstallableMeter.CalibrationInterval"
                           class="col col--1of3"
                           disabled
                           title="Межповерочный интервал *"
                  />
                </div>
              </div>

              <div v-if="AdmissionCertificate.InstallableMeter.Type?.length">
                <div class="row mb-24">
                  <h2 class="col col--1of1 page-form__sub-title">
                    Показания электрической энергии
                  </h2>
                </div>

                <div v-for="(reading, index) in AdmissionCertificate.InstallableReadings.Value" :key="'Readings_' + index"
                >
                  <div class="row mb-24">
                    <v-date-picker v-model="reading.time"
                                   :vid="'installableReading.time_' + index"
                                   class="col col--1of2"
                                   format="DD.MM.YYYY HH:mm"
                                   rules="required"
                                   title="Дата и время *"
                                   type="datetime"
                    />
<!--                    <v-select v-model="reading.tariff"
                              :options="availableInstallableMeterTariffs(reading.tariff)"
                              class="col col&#45;&#45;1of2"
                              rules="required"
                              :vid="'installableReading.tariff_' + index"
                              title="Номер тарифа *"
                              type="number"
                    />-->
                  </div>

                  <div class="row mb-24">
                    <v-input v-model="reading.ReadingT1"
                             class="col col--1of3"
                             title="T1"
                    />
                    <v-input v-model="reading.ReadingT2"
                             class="col col--1of3"
                             title="T2"
                    />
                    <v-input v-model="reading.ReadingT3"
                             class="col col--1of3"
                             title="T3"
                    />
                  </div>
                </div>

                <div class="row mb-24">
                  <button @click.prevent="addReading(AdmissionCertificate.InstallableReadings)">+ ещё
                    показания
                  </button>
                </div>

              </div>
            </template>
          </v-spoiler>

          <template v-if="!isMeterSwap">
            <v-spoiler :is-open="isOpenSpoiler.spoiler3" :summary="(number + 1) + ' | Характеристики измерительных трансформаторов тока'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler3')">
              <div class="row mb-24">

                <v-input v-if="meterModel?.directConnection" class="col col--1of1" disabled
                         value="Прибор учёта прямого включения"/>
                <current-transformer-model-select v-else v-model="currentTransformerModel"
                                                  class="col col--1of1"/>
              </div>
              <div v-show="AdmissionCertificate.CurrentTransformer?.Type?.length">
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.CurrentTransformer.Placement"
                           :rules="AdmissionCertificate.CurrentTransformer?.Type?.length ? 'required' : ''"
                           class="col col--1of1"
                           title="Место установки *"
                           vid="AdmissionCertificate.CurrentTransformer.Placement"
                  />
                </div>

                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.CurrentTransformer.TransformationRatio"
                           class="col col--1of2"
                           disabled
                           title="Коэффициент трансформации *"
                  />
                  <v-input v-model="AdmissionCertificate.CurrentTransformer.AccuracyClass"
                           class="col col--1of2"
                           disabled
                           title="Класс точности *"
                  />
                </div>

                <div class="row mb-24" style="margin-top: 48px">
                  <v-date-picker v-model="AdmissionCertificate.CurrentTransformer.CalibrationDate"
                                 :rules="AdmissionCertificate.CurrentTransformer?.Type?.length ? 'required' : ''"
                                 class="col col--1of3"
                                 title="Дата поверки *"
                                 vid="AdmissionCertificate.CurrentTransformer.CalibrationDate"
                  />
                  <v-date-picker v-model="AdmissionCertificate.CurrentTransformer.NextCalibrationDate"
                                 class="col col--1of3"
                                 disabled
                                 title="Дата следующей поверки *"
                  />
                  <v-input v-model="AdmissionCertificate.CurrentTransformer.CalibrationInterval"
                           class="col col--1of3"
                           disabled
                           title="Межповерочный интервал *"
                  />
                </div>

                <div class="row mb-24">
                  <v-input v-for="(Phase, index) in AdmissionCertificate.CurrentTransformer.Phase"
                           :key="'CurrentTransformer.Phase_' + index"
                           v-model="Phase.Serial"
                           :rules="AdmissionCertificate.CurrentTransformer?.Type?.length ? 'required' : ''"
                           :title="`Заводской номер (Фаза ${Phase.name}) *`"
                           :vid="'AdmissionCertificate.CurrentTransformer.Phase_' + index"
                           class="col col--1of3"
                  />
                </div>
              </div>
            </v-spoiler>

            <v-spoiler :is-open="isOpenSpoiler.spoiler4" :summary="(number + 2) + ' | Характеристики измерительных трансформаторов напряжения'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler4')">
              <div class="row mb-24">
                <v-input v-if="meterModel?.directConnection" class="col col--1of1" disabled
                         value="Прибор учёта прямого включения"/>
                <voltage-transformer-model-select v-else
                                                  v-model="voltageTransformerModel" class="col col--1of1"/>
              </div>

              <div v-show="AdmissionCertificate.PotentialTransformer?.Type?.length">
                <div class="row mb-24">
                  <v-input
                      v-model="AdmissionCertificate.PotentialTransformer.Placement"
                      :rules="AdmissionCertificate.PotentialTransformer?.Type?.length ? 'required' : ''"
                      class="col col--1of1"
                      title="Место установки *"
                      vid="AdmissionCertificate.PotentialTransformer.Placement"
                  />
                </div>

                <div class="row mb-24">
                  <v-input
                      v-model="AdmissionCertificate.PotentialTransformer.TransformationRatio"
                      class="col col--1of2"
                      disabled
                      title="Коэффициент трансформации *"
                  />
                  <v-input
                      v-model="AdmissionCertificate.PotentialTransformer.AccuracyClass"
                      class="col col--1of2"
                      disabled
                      title="Класс точности *"
                  />
                </div>

                <div class="row mb-24" style="margin-top: 48px">
                  <v-date-picker
                      v-model="AdmissionCertificate.PotentialTransformer.CalibrationDate"
                      :rules="AdmissionCertificate.PotentialTransformer?.Type?.length ? 'required' : ''"
                      class="col col--1of3"
                      title="Дата поверки *"
                  />
                  <v-date-picker
                      v-model="AdmissionCertificate.PotentialTransformer.NextCalibrationDate"
                      class="col col--1of3"
                      disabled
                      title="Дата следующей поверки *"
                  />
                  <v-input v-model="AdmissionCertificate.PotentialTransformer.CalibrationInterval"
                           class="col col--1of3"
                           disabled
                           title="Межповерочный интервал *"
                  />
                </div>

                <div class="row mb-24">
                  <c-checkbox v-model="AdmissionCertificate.PotentialTransformer.HighVoltage" class="col col--1of1"
                              label="Высоковольтный учёт"/>

                </div>
                <div>
                  <div v-show="AdmissionCertificate.PotentialTransformer.HighVoltage" class="row mb-24">
                    <v-input
                        v-for="(Phase, index) in AdmissionCertificate.PotentialTransformer.Phase"
                        :key="'PotentialTransformer.Phase_' + index"
                        v-model="Phase.Serial"
                        :rules="AdmissionCertificate.PotentialTransformer?.Type?.length && AdmissionCertificate.PotentialTransformer.HighVoltage ? 'required' : ''"
                        :title="`Заводской номер (Фаза ${Phase.name}) *`"
                        :vid="'AdmissionCertificate.PotentialTransformer.Phase_' + index"
                        class="col col--1of3"
                    />
                  </div>
                  <div v-show="!AdmissionCertificate.PotentialTransformer.HighVoltage" class="row mb-24">
                    <v-input
                        :key="'PotentialTransformer.Phase_0'"
                        v-model="AdmissionCertificate.PotentialTransformer.Phase[0].Serial"
                        :rules="AdmissionCertificate.PotentialTransformer?.Type?.length ? 'required' : ''"
                        class="col col--1of1"
                        title="Заводской номер *"
                        vid="AdmissionCertificate.PotentialTransformer.Phase.Serial"
                    />
                  </div>
                </div>
              </div>
            </v-spoiler>

            <v-spoiler :is-open="isOpenSpoiler.spoiler5" :summary="(number + 3) + ' | Информация о знаках визуального контроля (пломбах)'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler5')">
              <div v-for="(Seal, index) in AdmissionCertificate.Seals.Seal" :key="'Seal_'+index">
                <div style="display: flex; flex-direction: row; justify-content: space-between">
                  <div class="row mb-24" style="width: 96%">
                    <v-select v-model="Seal.Placement"
                              :disabled="Seal.Placement === 'TERMINAL_COVER' && index === 0"
                              :options="sealPlaceListWithSerialNumber"
                              :rules-messages="{required: 'Поле Место установки пломбы обязательно для заполнения'}"
                              :vid="'Seal.Placement_' + index"
                              class="col col--1of3"
                              rules="required"
                              title="Место установки пломбы *"
                    />
                    <v-input v-model="Seal.Number"
                             :rules-messages="{required: 'Поле Номер пломбы обязательно для заполнения'}"
                             :vid="'Seal.Number_' + index"
                             class="col col--1of3"
                             rules="required"
                             title="Номер пломбы *"
                    />
                    <v-select v-if="sealOrganisations.length > 0" v-model="Seal.Organization"
                              :is-need-mouseover-tooltip="true"
                              :options="sealOrganisations"
                              :rules-messages="{required: 'Поле Наименование организации, установившей пломбу обязательно для заполнения'}"
                              :vid="'Seal.Organization_' + index"
                              class="col col--1of3"
                              rules="required"
                              title="Наименование организации, установившей пломбу *"
                    />
                    <v-input v-else v-model="Seal.Organization"
                             :is-need-mouseover-tooltip="true"
                             :rules-messages="{required: 'Поле Наименование организации, установившей пломбу обязательно для заполнения'}"
                             :vid="'Seal.Organization_' + index"
                             class="col col--1of3"
                             rules="required"
                             title="Наименование организации, установившей пломбу *"
                    />
                  </div>
                  <button v-if="index !== 0" class="row mb-24" style="background: inherit; align-items: center"
                          @click.prevent="deleteSeal(index)">
                    <cross-delete/>
                  </button>
                </div>
              </div>

              <div class="row mb-24">
                <button @click.prevent="addSeal">+ ещё пломба
                </button>
              </div>
            </v-spoiler>

            <v-spoiler :is-open="isOpenSpoiler.spoiler6" :summary="(number + 4) + ' | Сведения об оборудовании дистанционного сбора данных'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler6')">
              <div class="row mb-24">
                <h2 class="col col--1of1 page-form__sub-title">
                  Устройство сбора и передачи данных
                </h2>
              </div>
              <div class="row mb-24">
                <data-acquisition-equipment-model-select v-model="dataAcquisitionEquipmentModel" class="col col--1of1"/>
              </div>
              <div v-show="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length">
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Serial"
                           :rules="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length ? 'required' : ''"
                           class="col col--1of1"
                           title="Заводской номер *"
                           vid="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Serial"
                  />
                </div>
                <div class="row mb-24">
                  <v-input v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Location"
                           :rules="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length ? 'required' : ''"
                           class="col col--1of2"
                           title="Место установки *"
                           vid="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Location"
                  />
                  <v-select v-if="sealOrganisations.length > 0" v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
                            :options="sealOrganisations"
                            :rules="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length ? 'required' : ''"
                            class="col col--1of2"
                            title="Балансовая принадлежность *"
                            vid="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
                  />
                  <v-input v-else v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
                           :rules="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length ? 'required' : ''"
                           class="col col--1of2"
                           title="Балансовая принадлежность *"
                           vid="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner"
                  />
                </div>
                <div class="row mb-24">
                  <v-date-picker v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate"
                                 :rules="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit?.Type?.length ? 'required' : ''"
                                 class="col col--1of2"
                                 title="Дата поверки *"
                                 vid="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate"
                  />
                  <v-date-picker
                      v-model="AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.NextCalibrationDate"
                      class="col col--1of2"
                      disabled
                      title="Дата следующей поверки *"
                  />
                </div>
              </div>

              <div v-if="dataAcquisitionEquipmentModel && Object.keys(dataAcquisitionEquipmentModel).length !== 0">
                <div class="row mb-24">
                  <h2 class="col col--1of1 page-form__sub-title">
                    Коммуникационное оборудование
                  </h2>
                </div>

                <div class="row mb-24">
                  <data-acquisition-equipment-switch-model-select
                    v-model="dataAcquisitionEquipmentSwitchModel"
                    :required="true"
                    class="col col--1of1"
                  />
                </div>
                <div v-show="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length">
                  <div class="row mb-24">
                    <v-input
                        v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Serial"
                        :rules="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length ? 'required' : ''"
                        class="col col--1of1"
                        title="Заводской номер *"
                        vid="AdmissionCertificate.DataAcquisitionEquipment.Switch.Serial"
                    />
                  </div>
                  <div class="row mb-24">
                    <v-input
                        v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Location"
                        :rules="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length ? 'required' : ''"
                        class="col col--1of2"
                        title="Место установки *"
                        vid="AdmissionCertificate.DataAcquisitionEquipment.Switch.Location"
                    />
                    <v-select
                        v-if="sealOrganisations.length > 0"
                        v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
                        :options="sealOrganisations"
                        :rules="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length ? 'required' : ''"
                        class="col col--1of2"
                        title="Балансовая принадлежность *"
                      vid="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
                  />
                  <v-input v-else v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
                           :rules="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length ? 'required' : ''"
                           class="col col--1of2"
                           title="Балансовая принадлежность *"
                           vid="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"
                  />
                </div>

                  <div class="row mb-24">
                    <v-date-picker
                        v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate"
                        :rules="AdmissionCertificate.DataAcquisitionEquipment.Switch?.Type?.length ? 'required' : ''"
                        class="col col--1of2"
                        title="Дата поверки *"
                      vid="AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate"
                  />
                  <v-date-picker
                      v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.NextCalibrationDate"
                      class="col col--1of2"
                      disabled
                        title="Дата следующей поверки *"
                    />
                  </div>
                </div>
              </div>

              <div v-if="dataAcquisitionEquipmentModel && Object.keys(dataAcquisitionEquipmentModel).length !== 0">
                <div class="row mb-24">
                  <h2 class="col col--1of1 page-form__sub-title">
                    ИСУ
                  </h2>
                </div>

                <div class="row mb-24">
                  <div class="col col--1of1">
                    <data-acquisition-equipment-isu
                      v-model="dataAcquisitionEquipmentIsu"
                      :required="true"
                    />
                  </div>
                </div>
              </div>
            </v-spoiler>

            <v-spoiler :is-open="isOpenSpoiler.spoiler7" :summary="(number + 5) + ' | Результаты измерений'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler7')">
              <div v-for="(Phase, index) in AdmissionCertificate.PhaseMeasurements.Phase"
                   :key="'PhaseMeasurements.Phase_' + index">
                <p><span class="page-form__sub-title">Фаза {{ Phase.name }}</span></p>
                <div class="row mb-24">
                  <v-input
                      :key="'PhaseMeasurements.Phase.PrimaryCircuitCurrent_' + index"
                      v-model="Phase.PrimaryCircuitCurrent"
                      :vid="'PhaseMeasurements.Phase.PrimaryCircuitCurrent_' + index"
                      class="col col--1of2"
                      rules="required"
                      title="Сила тока в первичной цепи, А *"
                  />
                  <v-input
                      :key="'PhaseMeasurements.Phase.MeasuringCircuitCurrent_' + index"
                      v-model="Phase.MeasuringCircuitCurrent"
                      :vid="'PhaseMeasurements.Phase.MeasuringCircuitCurrent_' + index"
                      class="col col--1of2"
                      rules="required"
                      title="Сила тока в измерительных цепях, А *"
                  />
                </div>
                <div class="row mb-24">
                  <v-input
                      :key="'PhaseMeasurements.Phase.PhaseVoltage_' + index"
                      v-model="Phase.PhaseVoltage"
                      :vid="'PhaseMeasurements.Phase.PhaseVoltage_' + index"
                      class="col col--1of2"
                      rules="required"
                      title="Фазное напряжение, В *"
                  />
                  <v-input
                      :key="'PhaseMeasurements.Phase.PhaseShift_' + index"
                      v-model="Phase.PhaseShift"
                      :vid="'PhaseMeasurements.Phase.PhaseShift_' + index"
                      class="col col--1of2"
                      rules="required"
                      title="Угол фазового сдвига, рад *"
                  />
                </div>
              </div>
            </v-spoiler>

            <v-spoiler :is-open="false" :summary="(number + 6) + ' | Характеристики использованного оборудования'"
                       class="row mb-24" w100>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.UsedEquipment.Name"
                         class="col col--1of1"
                         clearable
                />
              </div>
            </v-spoiler>

            <v-spoiler :is-open="false" :summary="(number + 7) + ' | Прочее'"
                       class="row mb-24" w100>
              <div class="row mb-24">
                <v-input v-model="AdmissionCertificate.Comments"
                         class="col col--1of1"
                         clearable
                />
              </div>
            </v-spoiler>

            <v-spoiler :is-open="isOpenSpoiler.spoiler10" :summary="(number + 8) + ' | Заключение. Решение о допуске (недопуске) прибора учёта в эксплуатацию (в случае недопуска указать причины)'"
                       class="row mb-24" w100 @toggle="toggleSpoiler('spoiler10')">
              <div class="row mb-24">
                <c-checkbox v-model="AdmissionCertificate.Resolution" class="col col--1of1"
                            label="Прибор допущен в эксплуатацию"/>
              </div>

              <div v-if="!AdmissionCertificate.Resolution" class="row mb-24">
                <v-input v-model="AdmissionCertificate.ResolutionComments"
                         class="col col--1of1"
                         rules="required"
                         title="Причина недопуска *"
                         vid="AdmissionCertificate.ResolutionComments"
                />
              </div>
            </v-spoiler>
          </template>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; margin-top: 24px">
      <p v-if="isValid && applicationStatus !== 2">
        Нажимая кнопку «Подписать и отправить», вы направляете документы потребителю и подписываете их квалифицированной
        электронной подписью.
      </p>
      <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <v-button :disabled="isWaitingSubmit || !isValid" type="submit">{{ submitButtonTitle }}</v-button>
    </div>
  </v-form>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import FileListPreview from 'molecules/upload/form/FileListPreview';
import { mapActions, mapGetters } from 'vuex';
import HTTPError from 'lib/utils/errors';
import connectionApplicationData from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import {
  addMonthsToDateFormatted,
  addYearsToDateFormatted,
  convertDbFormat,
  formatDateToCustomDateFormat,
  getCurrentDate,
} from 'lib/utils/date';
import {
  CurrentTransformerModelSelect,
  EmploeeSelectOrInput,
  MeterModelSelect,
  UsagePointConnectionSelect,
  VAddressSearchSelect,
  VDatePicker,
  VInput,
} from '@/components';
import SigningBasisSelector from 'components/DocumentSideSelectOrInput/SigningBasisSelector.vue';
import VSelect from 'components/VSelect/VSelect.vue';
import VoltageTransformerModelSelect
  from 'components/selectors/VoltageTransformerModelSelect/VoltageTransformerModelSelect.vue';
import CrossDelete from 'atoms/icons/action/CrossDelete.vue';
import VDivider from 'components/VDivider/VDivider.vue';
import DataAcquisitionEquipmentModelSelect
  from 'components/selectors/DataAcquisitionEquipmentModelSelect/DataAcquisitionEquipmentModelSelect.vue';
import DataAcquisitionEquipmentSwitchModelSelect
  from 'components/selectors/DataAcquisitionEquipmentSwitchModelSelect/DataAcquisitionEquipmentSwitchModelSelect.vue';
import DataAcquisitionEquipmentIsu
  from 'components/selectors/DataAcquisitionEquipmentIsu/DataAcquisitionEquipmentIsu.vue';
import CCheckbox from 'atoms/common/CCheckbox.vue';
import VForm from 'components/VForm/VForm.vue';
import VButton from 'components/VButton/VButton.vue';
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';
import VSpoiler from '@/components/VSpoiler/VSpoiler';

export default {
  name: 'SendAdmissionCertificate',
  components: {
    VButton,
    VForm,
    CCheckbox,
    DataAcquisitionEquipmentSwitchModelSelect,
    DataAcquisitionEquipmentIsu,
    DataAcquisitionEquipmentModelSelect,
    VDivider,
    CrossDelete,
    VoltageTransformerModelSelect,
    CurrentTransformerModelSelect,
    VSelect,
    MeterModelSelect,
    UsagePointConnectionSelect,
    VInput, SigningBasisSelector,
    VDatePicker,
    HorizontalProgressBar,
    FileListPreview,
    VAddressSearchSelect,
    VSearchSelect,
    EmploeeSelectOrInput,
    CTextarea: () => import('atoms/common/inputs/CTextarea'),
    CUploading: () => import('atoms/common/CUploading.vue'),
    VSpoiler,
  },
  props: {
    documentId: String,
    accountId: String,
    hideStatus: {
      type: Boolean,
      default: false,
    },
    actionProps: Object,
    applicationData: Object,
    offerDocument: Object,
    applicationId: String,
    onlyMicrogenerator: {
      type: Boolean,
      default: false,
    },
    isMeterSwap: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.hideStatus) {
      this.applicationStatus = 1;
    }
    if (this.isMeterSwap) {
      this.updateMetersList();
    }
  },
  data() {
    return {
      submitError: '',
      meterNumberError: { error: false },
      mainSubstationError: { error: false },
      usagePointName: { error: false },
      contractNumberError: { error: false },
      isWaitingSubmit: false,
      applicationStatus: undefined,
      cancelledComments: '',
      cancelledDocs: [],
      sealOrganisations: [],
      AdmissionCertificate: {
        Date: '',
        AdmissionCertificateNumber: '',
        Consumer: {
          Name: '',
          Inn: '',
          Snils: '',
          AccountId: '',
          representativeUserId: '',
        },
        Utility: {
          Name: '',
          Inn: '',
          AccountId: '',
          RepresentativeFullName: '',
          representativeName: '',
          representativeUserId: '',
          representativeSurname: '',
          representativePatronymic: '',
          representativePosition: '',
          CompanyDocument: {},
        },
        Retailer: {
          Name: '',
          Inn: '',
          AccountId: '',
          representativeName: '',
          representativeUserId: '',
          representativeSurname: '',
          representativePatronymic: '',
          representativePosition: '',
          CompanyDocument: {},
        },
        GridCompanyName: '',
        GridCompanyInn: '',
        GridCompanyEmployee: '',
        Customer: '',
        CustomerCompany: '',
        CustomerInn: '',
        RetailCompanyName: '',
        RetailCompanyEmployee: '',
        RetailAgreementNumber: '',
        TariffCategory: '',
        VerificationDate: '',
        UsagePoint: {
          Name: '',
          PowerSupplyCenter: {},
          Address: '',
        },
        Meter: {
          Location: '',
          Owner: '',
          Type: '',
          Serial: '',
          AccuracyClass: '',
          RatedCurrent: '',
          NominalVoltage: '',
          IntegerDigits: '',
          FractionDigits: '',
          ProductionYear: '',
          CalibrationDate: '',
          NextCalibrationDate: '',
          CalibrationInterval: '',
        },
        Readings: {
          Value: [
            {
              time: '',
              tariff: '',
              ActiveInput: '',
              ActiveOutput: '',
              ReactiveInput: '',
              ReactiveOutput: '',
            },
          ],
        },
        CurrentTransformer: {
          Placement: '',
          Type: '',
          TransformationRatio: '',
          CalibrationDate: '',
          NextCalibrationDate: '',
          AccuracyClass: '',
          Phase: [
            {
              name: 'A',
              Serial: '',
            },
            {
              name: 'B',
              Serial: '',
            },
            {
              name: 'C',
              Serial: '',
            },
          ],
        },
        PotentialTransformer: {
          HighVoltage: false,
          Placement: '',
          Type: '',
          TransformationRatio: '',
          AccuracyClass: '',
          CalibrationDate: '',
          NextCalibrationDate: '',
          Phase: [
            {
              name: 'A',
              Serial: '',
            },
            {
              name: 'B',
              Serial: '',
            },
            {
              name: 'C',
              Serial: '',
            },
          ],
        },
        Seals: {
          Seal: [
            {
              Placement: 'TERMINAL_COVER',
              Number: '',
              Organization: '',
            },
          ],
        },
        DataAcquisitionEquipment: {
          RemoteTerminalUnit: {
            Location: '',
            Owner: '',
            Type: '',
            Serial: '',
            CalibrationDate: '',
            NextCalibrationDate: '',
          },
          Switch: {
            Location: '',
            Owner: '',
            Type: '',
            Serial: '',
            CalibrationDate: '',
            NextCalibrationDate: '',
          },
          Isu: {
            Name: '',
            IsuProtocolName: '',
          },
        },
        PhaseMeasurements: {
          Phase: [
            {
              name: 'A',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
            {
              name: 'B',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
            {
              name: 'C',
              PrimaryCircuitCurrent: '',
              MeasuringCircuitCurrent: '',
              PhaseVoltage: '',
              PhaseShift: '',
            },
          ],
        },
        UsedEquipment: {
          Name: '',
        },
        Comments: '',
        Resolution: false,
        ResolutionComments: '',
        Signers: {
          Signer: [
            {
              Name: '',
              FamilyName: '',
              FirstName: '',
              Patronymic: '',
            },
          ],
        },
        City: '',
        Address: '',
        Place: '',
        InstallableMeter: {
          Location: '',
          Owner: '',
          Type: '',
          Serial: '',
          AccuracyClass: '',
          RatedCurrent: '',
          NominalVoltage: '',
          IntegerDigits: '',
          FractionDigits: '',
          ProductionYear: '',
          CalibrationDate: '',
          NextCalibrationDate: '',
        },
        InstallableReadings: {
          Value: [
            {
              time: '',
              tariff: '',
              ActiveInput: '',
              ActiveOutput: '',
              ReactiveInput: '',
              ReactiveOutput: '',
            },
          ],
        },
      },
      meterModel: {},
      installableMeterModel: {},
      currentTransformerModel: {},
      voltageTransformerModel: {},
      dataAcquisitionEquipmentModel: {},
      dataAcquisitionEquipmentSwitchModel: {},
      dataAcquisitionEquipmentIsu: {},
      notSignedDoc: '',
      meterSelectSerial: '',
      metersList: [],
      filterMeters: '',
      filterMetersPageSize: 20,
      isOpenSpoiler: {
        spoiler2: false,
        spoiler3: false,
        spoiler4: false,
        spoiler5: false,
        spoiler6: false,
        spoiler7: false,
        spoiler10: false,
      },
      fieldsRulesSpoiler: {
        'AdmissionCertificate.City': 'spoiler2',
        'AdmissionCertificate.Address': 'spoiler2',
        'AdmissionCertificate.Place': 'spoiler2',
        meterSelectSerial: 'spoiler2',
        meterModel: 'spoiler2',
        'AdmissionCertificate.Meter.Location': 'spoiler2',
        'AdmissionCertificate.Meter.Owner': 'spoiler2',
        'AdmissionCertificate.Meter.Serial': 'spoiler2',
        'AdmissionCertificate.Meter.ProductionYear': 'spoiler2',
        'AdmissionCertificate.Meter.CalibrationDate': 'spoiler2',
        'reading.time': 'spoiler2',
        'reading.tariff': 'spoiler2',
        installableMeterModel: 'spoiler2',
        'AdmissionCertificate.InstallableMeter.Location': 'spoiler2',
        'AdmissionCertificate.InstallableMeter.Owner': 'spoiler2',
        'AdmissionCertificate.InstallableMeter.Serial': 'spoiler2',
        'AdmissionCertificate.InstallableMeter.ProductionYear': 'spoiler2',
        'AdmissionCertificate.InstallableMeter.CalibrationDate': 'spoiler2',
        'installableReading.time': 'spoiler2',
        'installableReading.tariff': 'spoiler2',
        'AdmissionCertificate.CurrentTransformer.Placement': 'spoiler3',
        'AdmissionCertificate.CurrentTransformer.CalibrationDate': 'spoiler3',
        'AdmissionCertificate.CurrentTransformer.Phase': 'spoiler3',
        'AdmissionCertificate.PotentialTransformer.Placement': 'spoiler4',
        'AdmissionCertificate.PotentialTransformer.Phase': 'spoiler4',
        'AdmissionCertificate.PotentialTransformer.Phase.Serial': 'spoiler4',
        'Seal.Placement': 'spoiler5',
        'Seal.Number': 'spoiler5',
        'Seal.Organization': 'spoiler5',
        'AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Serial': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Location': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Owner': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.Switch.Serial': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.Switch.Location': 'spoiler6',
        'v-model="AdmissionCertificate.DataAcquisitionEquipment.Switch.Owner"': 'spoiler6',
        'AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate': 'spoiler6',
        'PhaseMeasurements.Phase.PrimaryCircuitCurrent': 'spoiler7',
        'PhaseMeasurements.Phase.MeasuringCircuitCurrent': 'spoiler7',
        'PhaseMeasurements.Phase.PhaseVoltage': 'spoiler7',
        'PhaseMeasurements.Phase.PhaseShift': 'spoiler7',
        'AdmissionCertificate.ResolutionComments': 'spoiler10',
      },
    };
  },
  computed: {
    isCompanyApplication() {
      console.log(this.applicationData?.consumer.company);
      return this.applicationData?.consumer?.company?.inn;
    },
    isValid() {
      return (this.applicationStatus === 2 && this.cancelledComments.length > 0) ||
        (this.applicationStatus === 1);
    },
    reviewAttachedFiles() {
      return this.cancelledDocs;
    },
    reviewComments() {
      return this.cancelledComments;
    },
    attorneys() {
      return this.getAuthorization.authorization.powerOfAttorney ?? [];
    },
    currentDateForPicker() {
      return getCurrentDate('DD.MM.YYYY HH:mm');
    },
    basisSelectorMode() {
      return this.getAuthorization.authorization.role.isCompanyOwner ? 'charter' : 'powerOfAttorney';
    },
    number() {
      return this.applicationData?.gridCompany ? 2 : 1;
    },
    metersValueLabelList() {
      return this.metersList.filter((item) => item.replacementStatus !== 'WITHDRAWN').map((item) => ({
        value: item.serialNumber,
        label: item.serialNumber,
      }));
    },
    submitButtonTitle() {
      if (this.applicationStatus === 2) {
        return 'Аннулировать';
      }
      return 'Подписать и отправить';
    },
    titleSpoiler2() {
      return `${this.number} | Характеристики и показания ${this.isMeterSwap ? 'приборов' : 'прибора'} учёта`;
    },
    sealPlaceListWithSerialNumber() {
      return this.sealPlaceList
          .filter((item) => this.AdmissionCertificate.PotentialTransformer.HighVoltage || (item.label !== 'ТН Фаза B' && item.label !== 'ТН Фаза C'))
          .map((item) => ({
            ...item,
            label: item.label
                .replace('ТН Фаза A', `ТН Фаза A ${this.AdmissionCertificate.PotentialTransformer.Phase.find((phase) => phase.name === 'A')?.Serial ?? ''}`)
                .replace('ТН Фаза B', `ТН Фаза B ${this.AdmissionCertificate.PotentialTransformer.Phase.find((phase) => phase.name === 'B')?.Serial ?? ''}`)
                .replace('ТН Фаза C', `ТН Фаза C ${this.AdmissionCertificate.PotentialTransformer.Phase.find((phase) => phase.name === 'C')?.Serial ?? ''}`)
                .replace('ТТ Фаза A', `ТТ Фаза A ${this.AdmissionCertificate.CurrentTransformer.Phase.find((phase) => phase.name === 'A')?.Serial ?? ''}`)
                .replace('ТТ Фаза B', `ТТ Фаза B ${this.AdmissionCertificate.CurrentTransformer.Phase.find((phase) => phase.name === 'B')?.Serial ?? ''}`)
                .replace('ТТ Фаза C', `ТТ Фаза C ${this.AdmissionCertificate.CurrentTransformer.Phase.find((phase) => phase.name === 'C')?.Serial ?? ''}`),
          }));
    },
    ...mapGetters('dataLists', [
      'tariffCategoryTypeList',
      'sealPlaceList',
      'meterTariffTypeList',
    ]),
    ...mapGetters('user', ['getUserType', 'getAuthorization', 'getUser', 'getAccount']),
  },
  watch: {
    dataAcquisitionEquipmentIsu: {
      handler(newVal) {
        this.AdmissionCertificate.DataAcquisitionEquipment.Isu.Name = '';
        this.AdmissionCertificate.DataAcquisitionEquipment.Isu.IsuProtocolName = '';

        if (!newVal) return;

        const { label } = newVal;

        if (!label) return;

        this.AdmissionCertificate.DataAcquisitionEquipment.Isu.Name = label.split('-')[0].trim();
        this.AdmissionCertificate.DataAcquisitionEquipment.Isu.IsuProtocolName = label.split('-')[1].trim();
      },
      deep: true,
    },
    'meterModel.directConnection': {
      handler(val) {
        if (val) {
          this.currentTransformerModel = null;
          this.voltageTransformerModel = null;
        }
      },
    },
    'AdmissionCertificate.Meter.CalibrationDate': {
      handler() {
        this.updateMeterNextCalibrationDate();
      },
    },
    'AdmissionCertificate.InstallableMeter.CalibrationDate': {
      handler() {
        this.updateInstallableMeterNextCalibrationDate();
      },
    },
    meterModel() {
      if (!this.meterModel) {
        this.AdmissionCertificate.Meter = {};
        this.AdmissionCertificate.Readings.Value = [{}];
        return;
      }
      this.AdmissionCertificate.Meter.Type = this.meterModel?.attributes?.typeDesignation;
      this.AdmissionCertificate.Meter.IntegerDigits = this.meterModel?.digitsCapacity?.beforeComma;
      this.AdmissionCertificate.Meter.FractionDigits = this.meterModel?.digitsCapacity?.afterComma;
      this.AdmissionCertificate.Meter.AccuracyClass = this.meterModel?.accuracyClass;
      this.AdmissionCertificate.Meter.RatedCurrent = this.meterModel?.nominalCurrent;
      this.AdmissionCertificate.Meter.NominalVoltage = this.meterModel?.nominalVoltage;
      if (this.meterModel?.calibrationInterval?.years) {
        this.AdmissionCertificate.Meter.CalibrationInterval = this.meterModel?.calibrationInterval?.years;
      }
      if (!this.AdmissionCertificate.Meter.CalibrationDate) {
        this.AdmissionCertificate.Meter.CalibrationDate = this.currentDateForPicker;
      }
      this.updateMeterNextCalibrationDate();
    },
    installableMeterModel() {
      if (!this.installableMeterModel) {
        this.AdmissionCertificate.InstallableMeter = {};
        this.AdmissionCertificate.InstallableReadings.Value = [{}];
        return;
      }
      this.AdmissionCertificate.InstallableMeter.Type = this.installableMeterModel?.attributes?.typeDesignation;
      this.AdmissionCertificate.InstallableMeter.IntegerDigits = this.installableMeterModel?.digitsCapacity?.beforeComma;
      this.AdmissionCertificate.InstallableMeter.FractionDigits = this.installableMeterModel?.digitsCapacity?.afterComma;
      this.AdmissionCertificate.InstallableMeter.AccuracyClass = this.installableMeterModel?.accuracyClass;
      this.AdmissionCertificate.InstallableMeter.RatedCurrent = this.installableMeterModel?.nominalCurrent;
      this.AdmissionCertificate.InstallableMeter.NominalVoltage = this.installableMeterModel?.nominalVoltage;
      if (this.installableMeterModel?.calibrationInterval?.years) {
        this.AdmissionCertificate.InstallableMeter.CalibrationInterval = this.installableMeterModel?.calibrationInterval?.years;
      }
      if (!this.AdmissionCertificate.InstallableMeter.CalibrationDate) {
        this.AdmissionCertificate.InstallableMeter.CalibrationDate = this.currentDateForPicker;
      }
      this.updateInstallableMeterNextCalibrationDate();
    },
    'AdmissionCertificate.CurrentTransformer.CalibrationDate': {
      handler() {
        this.updateCurrentTransformerNextCalibrationDate();
      },
    },
    currentTransformerModel() {
      if (!this.currentTransformerModel) {
        this.AdmissionCertificate.CurrentTransformer = {
          Phase: [
            {
              name: 'A',
              Serial: '',
            },
            {
              name: 'B',
              Serial: '',
            },
            {
              name: 'C',
              Serial: '',
            },
          ],
        };
        return;
      }
      this.AdmissionCertificate.CurrentTransformer.Type = this.currentTransformerModel?.title;
      this.AdmissionCertificate.CurrentTransformer.TransformationRatio = this.currentTransformerModel.transformationRatio;
      this.AdmissionCertificate.CurrentTransformer.AccuracyClass = this.currentTransformerModel.accuracyClass;
      if (this.currentTransformerModel?.calibrationInterval) {
        this.AdmissionCertificate.CurrentTransformer.CalibrationInterval = this.currentTransformerModel?.calibrationInterval;
      }
      if (!this.AdmissionCertificate.CurrentTransformer.CalibrationDate) {
        this.AdmissionCertificate.CurrentTransformer.CalibrationDate = this.currentDateForPicker;
      }
      this.updateCurrentTransformerNextCalibrationDate();
    },
    'AdmissionCertificate.PotentialTransformer.CalibrationDate': {
      handler() {
        this.updateVoltageTransformerNextCalibrationDate();
      },
    },
    'AdmissionCertificate.PotentialTransformer.HighVoltage': {
      handler() {
        if (this.AdmissionCertificate.PotentialTransformer.HighVoltage) {
          this.AdmissionCertificate.PotentialTransformer.Phase = [
            {
              name: 'A',
              Serial: '',
            },
            {
              name: 'B',
              Serial: '',
            },
            {
              name: 'C',
              Serial: '',
            },
          ];
          return;
        }
        this.AdmissionCertificate.PotentialTransformer.Phase = [
          {
            name: 'A',
            Serial: '',
          },
        ];
      },
    },
    voltageTransformerModel() {
      if (!this.voltageTransformerModel) {
        this.AdmissionCertificate.PotentialTransformer = {
          Phase: [
            {
              name: 'A',
              Serial: '',
            },
            {
              name: 'B',
              Serial: '',
            },
            {
              name: 'C',
              Serial: '',
            },
          ],
        };
        return;
      }
      this.AdmissionCertificate.PotentialTransformer.Type = this.voltageTransformerModel?.title;
      this.AdmissionCertificate.PotentialTransformer.TransformationRatio = this.voltageTransformerModel.transformationRatio;
      this.AdmissionCertificate.PotentialTransformer.AccuracyClass = this.voltageTransformerModel.accuracyClass;
      if (this.voltageTransformerModel?.calibrationInterval) {
        this.AdmissionCertificate.PotentialTransformer.CalibrationInterval = this.voltageTransformerModel?.calibrationInterval;
      }
      if (!this.AdmissionCertificate.PotentialTransformer.CalibrationDate) {
        this.AdmissionCertificate.PotentialTransformer.CalibrationDate = this.currentDateForPicker;
      }
      this.updateVoltageTransformerNextCalibrationDate();
    },
    'AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit': {
      handler() {
        this.updateDataAcquisitionEquipmentModelCalibrationDate();
      },
    },
    dataAcquisitionEquipmentModel() {
      if (!this.dataAcquisitionEquipmentModel) {
        this.AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit = {};
        return;
      }
      this.AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.Type = this.dataAcquisitionEquipmentModel?.title;
      if (!this.AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate) {
        this.AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit.CalibrationDate = this.currentDateForPicker;
      }
      this.updateDataAcquisitionEquipmentModelCalibrationDate();
    },
    'AdmissionCertificate.DataAcquisitionEquipment.Switch': {
      handler() {
        this.updateDataAcquisitionEquipmentSwitchModelModelCalibrationDate();
      },
    },
    dataAcquisitionEquipmentSwitchModel() {
      if (!this.dataAcquisitionEquipmentSwitchModel) {
        this.AdmissionCertificate.DataAcquisitionEquipment.Switch = {};
        return;
      }
      this.AdmissionCertificate.DataAcquisitionEquipment.Switch.Type = this.dataAcquisitionEquipmentSwitchModel?.title;
      if (!this.AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate) {
        this.AdmissionCertificate.DataAcquisitionEquipment.Switch.CalibrationDate = this.currentDateForPicker;
      }
      this.updateDataAcquisitionEquipmentSwitchModelModelCalibrationDate();
    },
    offerDocument: {
      immediate: true,
      handler() {
        this.initSides();
        this.AdmissionCertificate.Date = getCurrentDate('YYYY-MM-DD');
        if (this.applicationData?.gridCompany) {
          this.AdmissionCertificate.VerificationDate = this.currentDateForPicker;
          this.AdmissionCertificate.AdmissionCertificateNumber = this.documentId;
          this.AdmissionCertificate.TariffCategory = this.applicationData.connectionCharacteristics.tariffCategoryType;
          this.sealOrganisations = [
            {
              label: this.applicationData.gridCompany.gridCompanyName.label,
              value: this.applicationData.gridCompany.gridCompanyName.inn,
            },
            {
              label: this.applicationData.connectionCharacteristics.supplierOfLastResort.label,
              value: this.applicationData.connectionCharacteristics.supplierOfLastResort.inn,
            },
          ];

          this.AdmissionCertificate.UsagePoint = {
            Address: this.applicationData.objectLocation.objectAddress,
            PowerSupplyCenter: this.offerDocument.technicalConditions.mainSubstationObject,
            Name: this.offerDocument.technicalConditions.pointName,
            ObjectType: this.applicationData.objectLocation.objectType?.value,
          };
        }
      },
    },
    meterSelectSerial(val) {
      const meterModel = this.metersList.filter((item) => item.serialNumber === val)[0];

      if (!meterModel || val.length === 0) {
        this.AdmissionCertificate.Meter = {};
        this.AdmissionCertificate.Readings.Value = [{}];
        return;
      }

      this.AdmissionCertificate.Meter.Id = meterModel.id;
      this.AdmissionCertificate.Meter.Type = meterModel.type;
      this.AdmissionCertificate.Meter.AccuracyClass = meterModel.accuracyClass;
      this.AdmissionCertificate.Meter.RatedCurrent = meterModel.ratedCurrent;
      this.AdmissionCertificate.Meter.NominalVoltage = meterModel.nominalVoltage;
      this.AdmissionCertificate.Meter.CalibrationDate = convertDbFormat(meterModel.calibrationDate);
      this.AdmissionCertificate.Meter.NextCalibrationDate = convertDbFormat(meterModel.nextCalibrationDate);
      this.AdmissionCertificate.Meter.CalibrationInterval = meterModel.calibrationInterval?.years ?? '';
      this.AdmissionCertificate.Meter.Location = meterModel.location ?? '';
      this.AdmissionCertificate.Meter.Owner = meterModel.ownerCompany ?? '';
      this.AdmissionCertificate.Meter.Serial = meterModel.serialNumber;
    },
    'AdmissionCertificate.Meter.Serial': {
      handler(val) {
        this.AdmissionCertificate.Meter.Serial = val.trim();
      },
    },
    'AdmissionCertificate.InstallableMeter.Serial': {
      handler(val) {
        this.AdmissionCertificate.InstallableMeter.Serial = val.trim();
      },
    },
  },
  methods: {
    async initSides() {
      const account = this.getAccount;
      const authorization = this.getAuthorization.authorization;
      if (!this.isMeterSwap) {
        let retailerData = [];
        if (this.applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn) {
          retailerData = await this.getSuppliersByFilter({ inn: this.applicationData?.connectionCharacteristics?.supplierOfLastResort?.inn });
        }

        this.AdmissionCertificate.Consumer.Name = this.applicationData?.consumer?.company?.name ?? `${this.applicationData?.consumer?.familyName ?? ''} ${this.applicationData?.consumer?.firstName ?? ''} ${this.applicationData?.consumer?.patronymic ?? ''}`;
        this.AdmissionCertificate.Consumer.Snils = this.applicationData?.consumer?.insuranceNumber;
        this.AdmissionCertificate.RetailAgreementNumber = this.applicationData?.connectionCharacteristics?.retailAgreementNumber;
        this.AdmissionCertificate.Consumer.Inn = this.applicationData?.consumer?.company?.inn;
        this.AdmissionCertificate.Consumer.AccountId = this.accountId;

        this.AdmissionCertificate.Retailer.Name = retailerData[0]?.name;
        this.AdmissionCertificate.Retailer.Inn = retailerData[0]?.inn;
        this.AdmissionCertificate.Retailer.AccountId = retailerData[0]?.account.id;
      }
      this.AdmissionCertificate.Utility.Name = account.profile.name;
      this.AdmissionCertificate.Utility.Inn = account.profile.inn;
      this.AdmissionCertificate.Utility.AccountId = account.id;
      this.AdmissionCertificate.Utility.RepresentativeFullName = `${authorization.user.fullName.surname} ${authorization.user.fullName.name} ${authorization.user.fullName.patronymic ?? ''}`;
      this.AdmissionCertificate.Utility.representativeName = authorization.user.fullName.name;
      this.AdmissionCertificate.Utility.representativeSurname = authorization.user.fullName.surname;
      this.AdmissionCertificate.Utility.representativePatronymic = authorization.user.fullName.patronymic;
      this.AdmissionCertificate.Utility.representativeUserId = authorization.user.id;
      this.AdmissionCertificate.Utility.representativePosition = authorization.role?.name ?? '';
      this.AdmissionCertificate.Utility.representativePositionId = authorization.role?.id ?? '';

    },
    deleteSeal(index) {
      this.AdmissionCertificate.Seals.Seal.splice(index, 1);
    },
    deleteReading(index) {
      this.AdmissionCertificate.Readings.Value.splice(index, 1);
    },
    getCheckboxClass(checked) {
      return checked ? 'control-buttonRadioGroupCheck-checked' : 'control-buttonRadioGroupCheck-unchecked';
    },
    updateCalibrationDate(object, interval) {
      if (object.CalibrationDate?.length) {
        object.NextCalibrationDate = formatDateToCustomDateFormat(addMonthsToDateFormatted(object.CalibrationDate, interval.months, 'DD.MM.YYYY'), 'DD.MM.YYYY');
        object.NextCalibrationDate = formatDateToCustomDateFormat(addYearsToDateFormatted(object.CalibrationDate, interval.years, 'DD.MM.YYYY'), 'DD.MM.YYYY');
      }
    },
    updateMeterNextCalibrationDate() {
      if (this.meterModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.Meter, this.meterModel.calibrationInterval);
      }
    },
    updateInstallableMeterNextCalibrationDate() {
      if (this.installableMeterModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.InstallableMeter, this.installableMeterModel.calibrationInterval);
      }
    },
    updateCurrentTransformerNextCalibrationDate() {
      if (this.currentTransformerModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.CurrentTransformer, {
          years: this.currentTransformerModel.calibrationInterval,
        });
      }
    },
    updateVoltageTransformerNextCalibrationDate() {
      if (this.voltageTransformerModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.PotentialTransformer, {
          years: this.voltageTransformerModel.calibrationInterval,
        });
      }
    },
    updateDataAcquisitionEquipmentModelCalibrationDate() {
      if (this.dataAcquisitionEquipmentModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.DataAcquisitionEquipment.RemoteTerminalUnit, {
          years: this.dataAcquisitionEquipmentModel.calibrationInterval,
        });
      }
    },
    updateDataAcquisitionEquipmentSwitchModelModelCalibrationDate() {
      if (this.dataAcquisitionEquipmentSwitchModel?.calibrationInterval) {
        this.updateCalibrationDate(this.AdmissionCertificate.DataAcquisitionEquipment.Switch, {
          years: this.dataAcquisitionEquipmentSwitchModel.calibrationInterval,
        });
      }
    },
    availableMeterTariffs(tariff) {
      return this.meterTariffTypeList.filter((tariffType) => !this.AdmissionCertificate.Readings.Value.some((reading) => reading.tariff === tariffType.value) || tariffType.value === tariff);
    },
    availableInstallableMeterTariffs(tariff) {
      return this.meterTariffTypeList.filter((tariffType) => !this.AdmissionCertificate.InstallableReadings.Value.some((reading) => reading.tariff === tariffType.value) || tariffType.value === tariff);
    },
    async submitSignedDocument() {
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        await this.documentUpload({ xml: connectionApplicationData.generateAdmissionCertificateXml(this.AdmissionCertificate, this.isCompanyApplication), isCap: true });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              this.isMeterSwap ? 'Акт замены электрического счётчика отправлен' : 'АДПУ ОТПРАВЛЕН',
              '',
            );
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        console.log(error);
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    async onSubmit() {
      if (this.applicationStatus === 2) {
        this.submitError = '';
        this.isWaitingSubmit = true;
        const documentReviewXml = connectionApplicationData.documentReviewXml(
          this.documentId,
          this.actionProps.reviewType,
          this.reviewComments,
          this.reviewAttachedFiles,
          [],
        );

        try {
          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.setWorkflows({ userType: this.getUserType });
            this.isWaitingSubmit = false;
            // TODO возможно, нужно ждать изменения состояния
            this.$nextTick(() => {
              this.vueShowNotification(
                'ЗАЯВКА АННУЛИРОВАНА',
                '',
              );
            });
            this.onClose();
          }, 2000);

        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
      } else {

        await this.submitSignedDocument();
      }
    },
    onClose() {
      this.applicationStatus = undefined;
      this.$emit('close');
    },
    applicationStatusClick(val) {
      this.applicationStatus = val;
    },
    handleRemoveCancelledDocs(file) {
      this.cancelledDocs = this.cancelledDocs
        .filter((scan) => scan.source !== file.source);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    addAdmissionSigner() {
      this.AdmissionCertificate.Signers.Signer.push(
        {
          Name: '',
          FamilyName: '',
          FirstName: '',
          Patronymic: '',
        },
      );
    },
    addReading(Readings) {
      Readings.Value.push(
        {
          time: '',
          tariff: '',
          ActiveInput: '',
          ActiveOutput: '',
          ReactiveInput: '',
          ReactiveOutput: '',
        },
      );
    },
    addSeal() {
      this.AdmissionCertificate.Seals.Seal.push(
        {
          Placement: '',
          Number: '',
          Organization: '',
        },
      );
    },
    async updateMetersList(pageSize) {
      const meters =  await this.allMetersByFilterPaged({
        filter: { serialNumber: this.filterMeters },
        pageIn: { currentPage: 1, pageSize: pageSize ?? 20 },
      });

      this.metersList = meters?.list ?? [];

      if (!pageSize) {
        this.filterMetersPageSize = 20;
      }
    },
    changeFilterMeters(val) {
      this.filterMeters = val;
      this.updateMetersList();
    },
    metersScrolledToEnd() {
      this.filterMetersPageSize += 20;
      this.updateMetersList(this.filterMetersPageSize);
    },
    onError(vid, element) {
      const vidWithoutIndex = vid.indexOf('_') === -1 ? vid : vid.substr(0, vid.indexOf('_'));

      if (!this.isOpenSpoiler[this.fieldsRulesSpoiler[vidWithoutIndex]]) {
        this.isError = true;
        this.isOpenSpoiler[this.fieldsRulesSpoiler[vidWithoutIndex]] = true;
      }

      this.$nextTick(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
    toggleSpoiler(val) {
      if (this.isError) {
        this.isError = false;
        return;
      }

      this.isOpenSpoiler[val] = !this.isOpenSpoiler[val];
    },
    selectEmploee(val) {
      this.AdmissionCertificate.Retailer.representativeName = val.fullName.name;
      this.AdmissionCertificate.Retailer.representativeSurname = val.fullName.surname;
      this.AdmissionCertificate.Retailer.representativePatronymic = val.fullName.patronymic;
      this.AdmissionCertificate.Retailer.representativePosition = val.role.name;
      this.AdmissionCertificate.Retailer.representativeUserId = val.userId;
      this.AdmissionCertificate.Retailer.CompanyDocument = val.reason;
    },
    selectConsumerEmploee(val) {
      this.AdmissionCertificate.Consumer.representativeName = val.fullName.name;
      this.AdmissionCertificate.Consumer.representativeSurname = val.fullName.surname;
      this.AdmissionCertificate.Consumer.representativePatronymic = val.fullName.patronymic;
      this.AdmissionCertificate.Consumer.representativePosition = val.role.name;
      this.AdmissionCertificate.Consumer.representativePositionId = val.role.id;
      this.AdmissionCertificate.Consumer.representativeUserId = val.userId;
      this.AdmissionCertificate.Consumer.CompanyDocument = val.reason;
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows', 'getSuppliersByFilter']),
    ...mapActions('point', ['allMetersByFilterPaged']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.control {
  opacity: 0;
  pointer-events: none;

  &-titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }


  &-titleRadioGroup {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 21px;
    line-height: 29px;
    margin-left: 10px;
  }

  .contentRadioGroup {
    display: flex;
    flex-direction: column;
    color: #141412;

    p {
      font-family: 'Roboto Condensed';
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #C1C4C7;
    }
  }

  &-buttonRadioGroupCheck-unchecked {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center;
  }

  &-buttonRadioGroupCheck-checked {
    width: 24px;
    height: 24px;
    position: relative;
    background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center;
  }
}


.page-form {
  display: flex;
  flex-direction: column;
  align-self: center;

  &__title {
    font-family: Roboto;
    color: #4C4E51;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &-sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  &__status {
    align-items: center;
  }

  &__controlDiv {
    border-left: 2px solid #DEE2E6;
    margin-left: 6px;
    padding-left: 14px;

    &-label {
      display: flex;
      cursor: pointer;
    }

    &-label--disabled {
      display: flex;
      cursor: default;
      color: gray;
    }

    &-label:first-child {
      margin-bottom: 10px;
    }
  }

  &__spoiler {
    details {
      width: 100% !important;
    }
  }

  &__action {
    position: relative;

    &-note {
      width: 320px;
      position: absolute;
      right: -364px;
      border-radius: 16px;
      background: #F5F6F6;
      color: #4C4E51;
      padding: 12px 12px 6px 24px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &-button {
      position: absolute;
      right: 0;
    }
  }
}
</style>
