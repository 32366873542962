<template>
  <div>

    <div :class="$style.title" @click="show = !show">
      <p>Детали задачи</p>
      <ChevronUp v-if="show" style="margin-left: 16px" />
      <ChevronDown v-else style="margin-left: 16px" />
    </div>

    <div v-if="show" :class="$style.content">

      <p :class="$style.date">Дата создания:<span>{{ date }}</span></p>

      <div :class="$style.subtitleGroup">

        <p :class="$style.subtitle">Для работы на Платформе в качестве сетевой компании необходимо установить приложение «Универсальный транспортный модуль МОЙ СВЕТ» на ваш компьютер.</p>

        <p :class="$style.text">При помощи приложения вы сможете осуществлять на Платформе электронный документооборот, формировать баланс и отчётные документы, оформлять технологическое присоединение.</p>

        <p :class="$style.text">Для работы в приложении необходима КЭП.</p>

      </div>

    </div>

  </div>
</template>

<script>
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import ChevronDown from 'atoms/icons/arrows/ChevronDown';

export default {
  name: 'TaskDetails',
  components: {
    ChevronDown,
    ChevronUp,
  },
  props: {
    date: String,
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="sass" module>
.title
  display: flex
  cursor: pointer
  p
    +base-title

.content
  padding: 24px 0 40px 0

.date
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 18px
  span
    +label-text
    margin-left: 32px

.subtitleGroup
  margin-top: 40px

  .subtitle
    +base-subtitle
    margin-bottom: 20px

  .text
    +base-text
    margin-bottom: 20px
    &:last-child
      margin-bottom: 0
</style>
