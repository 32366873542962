<template>
  <div v-if="!isWaitingVerifyCapSubmit" :class="$style.capCheck">
    <div v-if="error">
      <div :class="$style.check">
        <div :class="$style.buttonRadioGroupError"></div>
        <p>{{ verifyCapError }}</p>
      </div>
      <p>
        <a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html"
           target="_blank">
          Проверить работу плагина
        </a>
      </p>
    </div>
    <div v-else>
      <div :class="$style.check">
        <div :class="$style.buttonRadioGroupCheck"></div>
        <p>Расширение Крипто ПРО загружено</p>
      </div>
      <div :class="$style.check">
        <div :class="$style.buttonRadioGroupCheck"></div>
        <p>Плагин загружен</p>
      </div>
      <div :class="$style.check">
        <div :class="$style.buttonRadioGroupCheck"></div>
        <p>Криптопровайдер загружен</p>
      </div>
      <div v-if="certificates.length > 0" :class="$style.check">
        <div :class="$style.buttonRadioGroupCheck"></div>
        <p>Сертификаты установлены</p>
      </div>
      <div v-else>
        <div :class="$style.check">
          <div :class="$style.buttonRadioGroupError"></div>
          <p>Сертификаты отсутствуют</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { getUserCertificates, isValidSystemSetup } from 'crypto-pro';

export default {
  name: 'CertificateLoader',
  components: {},
  async created() {
    await this.loadCertificates();
  },
  data() {
    return {
      error: false,
      verifyCapError: '',
      certificates: [],
      isWaitingVerifyCapSubmit: true,
      selected: null,
    };
  },
  methods: {
    formatDate(date) {
      date = date.split('T');
      date = date[0].replace(/-/gi, '.').split('.').reverse()
        .join('.');

      return date;
    },
    async loadCertificates() {
      this.isWaitingVerifyCapSubmit = true;
      this.error = false;
      this.verifyCapError = '';

      let isValid = false;

      try {
        isValid = await isValidSystemSetup();
        if (!isValid) {
          this.verifyCapError = 'Ошибка Крипто ПРО. Некорректно настроена ЭП в системе';
          this.error = true;
        }
      } catch (error) {
        console.log('error catch', error);
        this.error = true;
        this.verifyCapError = `Ошибка Крипто ПРО. ${error}`;
      }

      if (!this.error) {
        try {
          this.certificates = await getUserCertificates();
        } catch (error) {
          console.log('getUserCertificates', error);
          this.error = true;
          this.verifyCapError = `Ошибка Крипто ПРО. ${error}`;
        }
      }

      if (this.error) {
        this.$emit('error', this.verifyCapError);
      } else {
        this.$emit('loaded', this.certificates);
      }

      this.isWaitingVerifyCapSubmit = false;
    },
  },
};

</script>

<style lang="sass" module>
.capCheck
  width: fit-content
  margin-top: 55px
  text-align: center
  margin-right: auto

  div.check
    display: flex
    margin-bottom: 16px

  p
    margin-left: 8px

.buttonRadioGroupCheck
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/check_circle.svg') no-repeat right center

.buttonRadioGroupError
  width: 24px
  height: 24px
  padding-left: 24px
  position: relative
  background: url('~@/assets/icons/radio/error.svg') no-repeat right center

.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.capSelect
  width: 100%
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 21px
  line-height: 30px
  padding: 5px 16px
  margin-bottom: 52px
  appearance: none

.select
  position: relative
  display: inline-block
  width: 100%

  &:after
    content: url('~icons/basic/arrow_down_black.svg')
    position: absolute
    right: 16px
    top: 16px
    pointer-events: none
    box-sizing: border-box


</style>
