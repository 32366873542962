<template>
  <div>
    <hover-tooltip
      v-if="tooltipShow && isCompact"
      :text="title"
      :top="distanceToTop"
    />
    <div
      ref="itemMenuRef"
      :class="{
        [$style.active]: route ? active : false,
        [$style.container]: true,
        [$style.open]: isOpen && items,
        [$style.isGroup]: items,
        [$style.under]: isUnder && !active,
        [$style.underActive]: isUnder && active,
        [$style.hide]: isHide,
        [$style.isCompact]: isCompact,
      }"
      @mouseleave="hideTooltip(title)"
      @mouseover="showTooltip(title)"
    >
      <router-link
        :to="route ? { name: `cabinet.${route}`, params: params } : '#'"
        :class="$style.menuLink"
      >
        <div :class="{[$style.compact]: isCompact }" :style="customStyle" @click="openGroup">
          <component :is="iconComponent"/>
          <span>{{ title }}</span>
          <p
            v-if="counter > 0"
            :class="{
                  [$style.counter]: !isCompact,
                  [$style.compactCounter]: isCompact,
                }">
            {{ transformCounter }}
          </p>
        </div>
      </router-link>
      <img v-if="items" :class="{[$style.iconChevron]: true, [$style.iconChevronUp]: isOpen}"
           alt=""
           src="~@/assets/icons/arrows/arrow-down-black.svg"
           @click="openGroup"
      />
    </div>
    <template v-if="items && isOpen">
      <item-menu
        v-for="(item, indexItem) in items"
        :key="indexItem"
        :block-access="blockAccess"
        :counter="counter"
        :icon-component="item.iconComponent"
        :index-group="indexItem"
        :is-compact="isCompact"
        :is-under="true"
        :items="item.items"
        :route="item.route"
        :title="item.title"
        :params="item.params"
        @openGroup="openSubGroup"
      />
    </template>
  </div>
</template>

<script>
import ItemMenu from './ItemMenu';
import HoverTooltip from './HoverTooltip';

export default {
  name: 'ItemMenu',
  components: {
    // TODO: удалить импорты иконок из общего компонента
    // раскидать их по соответсвующим типам меню в папке "userTypes"
    UsefulVacationCertificate: () => import('atoms/icons/menu/UsefulVacationCertificate'),
    TransitReadings: () => import('atoms/icons/menu/TransitReadings'),
    FinalReadings: () => import('atoms/icons/menu/FinalReadings'),
    DirectoryTN: () => import('atoms/icons/menu/DirectoryTN'),
    DirectoryTT: () => import('atoms/icons/menu/DirectoryTT'),
    Disagreements: () => import('atoms/icons/menu/Disagreements'),
    Tasks: () => import('atoms/icons/menu/Tasks'),
    Balance: () => import('atoms/icons/menu/Balance'),
    BalancePlanning: () => import('atoms/icons/menu/BalancePlanning'),
    Topology: () => import('atoms/icons/menu/Topology'),
    Tp: () => import('atoms/icons/menu/Tp'),
    TpGroup: () => import('atoms/icons/menu/TpGroup'),
    Partner: () => import('atoms/icons/menu/Partner'),
    Forms18: () => import('atoms/icons/menu/18Forms'),
    ContactPage1: () => import('atoms/icons/menu/ContactPage1'),
    ContactPage2: () => import('atoms/icons/menu/ContactPage2'),
    Contacts: () => import('atoms/icons/menu/Contacts'),
    FlashAuto: () => import('atoms/icons/menu/FlashAuto'),
    FlashOff: () => import('atoms/icons/menu/FlashOff'),
    HomeWork: () => import('atoms/icons/menu/HomeWork'),
    LibraryBooks: () => import('atoms/icons/menu/LibraryBooks'),
    LightningDevice: () => import('atoms/icons/menu/LightningDevice'),
    Subtract: () => import('atoms/icons/menu/Subtract'),
    ControlInspection: () => import('atoms/icons/menu/ControlInspection'),
    Exploitation: () => import('atoms/icons/menu/Exploitation'),
    Defects: () => import('atoms/icons/menu/Defects'),
    OdkAccounting: () => import('atoms/icons/menu/OdkAccounting'),
    Margin: () => import('atoms/icons/menu/Margin'),
    MeasuringPoint: () => import('atoms/icons/menu/MeasuringPoint'),
    MiscellaneousServices: () => import('atoms/icons/menu/MiscellaneousServices'),
    Quickreply: () => import('atoms/icons/menu/Quickreply'),
    History: () => import('atoms/icons/menu/History'),
    UserDetails: () => import('atoms/icons/menu/UserDetails'),
    Pay: () => import('atoms/icons/menu/Pay'),
    PaymentSchedule: () => import('atoms/icons/menu/PaymentSchedule'),
    Service: () => import('atoms/icons/menu/Service'),
    Documents: () => import('atoms/icons/menu/Documents'),
    AgreementsImplementationTechnologicalConnection: () => import('atoms/icons/menu/AgreementsImplementationTechnologicalConnection.vue'),
    ActsImplementationTechnologicalConnection: () => import('atoms/icons/menu/ActsImplementationTechnologicalConnection.vue'),
    ActsInstallationMeteringDevices: () => import('atoms/icons/menu/ActsInstallationMeteringDevices.vue'),
    RegisterMeteringDevicesEndUsers: () => import('atoms/icons/menu/RegisterMeteringDevicesEndUsers.vue'),
    CabinetConsumersScreen: () => import('atoms/icons/menu/CabinetConsumersScreen.vue'),
    CabinetPointsScreen: () => import('atoms/icons/menu/CabinetPointsScreen.vue'),
    CabinetMeterInputTransit: () => import('atoms/icons/menu/CabinetMeterInputTransit.vue'),
    CabinetMeasurementsStatementsInputTransit: () => import('atoms/icons/menu/CabinetMeasurementsStatementsInputTransit.vue'),
    CabinetMeasurementsStatementsCustomers: () => import('atoms/icons/menu/CabinetMeasurementsStatementsCustomers.vue'),
    UsefulVacationDepartmentsAndConsumers: () => import('atoms/icons/menu/UsefulVacationDepartmentsAndConsumers.vue'),
    Attorney: () => import('atoms/icons/menu/Attorney.vue'),
    Structure: () => import('atoms/icons/menu/Structure.vue'),
    IndividualTariffs: () => import('atoms/icons/menu/IndividualTariffs.vue'),
    PermissionsRegistry: () => import('atoms/icons/menu/PermissionsRegistry.vue'),
    ElectricEnergyTransport: () => import('atoms/icons/menu/ElectricEnergyTransport.vue'),
    WarehouseAccountingSystem: () => import('atoms/icons/menu/WarehouseAccountingSystem.vue'),
    AdditionalServices: () => import('atoms/icons/menu/AdditionalServices.vue'),
    ContractsGroup: () => import('atoms/icons/menu/ContractsGroup.vue'),
    Reports: () => import('atoms/icons/menu/Reports.vue'),
    UtmInstallation: () => import('atoms/icons/menu/UtmInstallation.vue'),
    Chat: () => import('atoms/icons/menu/Chat'),
    ItemMenu,
    HoverTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
    iconComponent: {
      type: String,
      required: true,
    },
    blockAccess: {
      type: Object,
      required: true,
    },
    counter: {
      type: Number,
      default: 0,
    },
    isCompact: {
      type: Boolean,
      required: true,
    },
    customStyle: {
      type: Object,
      default: () => {
      },
    },
    params: {
      type: Object,
      default: () => {
      },
    },
    items: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isUnder: {
      type: Boolean,
      default: false,
    },
    indexGroup: {
      type: Number,
      default: -1,
    },
  },
  created() {
    if (this.items) {
      const isActiveSubItem = this.items.some((item) => this.isActive(`/${item.route}`));

      if (isActiveSubItem) {
        this.openGroup();
      }
    }
  },
  data() {
    return {
      openIndex: '',
      tooltipShow: false,
      distanceToTop: 0,
    };
  },
  computed: {
    isHide() {
      if (this.route) {
        return this.blockAccess[this.route] !== undefined && !this.blockAccess[this.route];
      }

      const isHide = this.items.every((item) => this.blockAccess[item.route] !== undefined && !this.blockAccess[item.route]);

      return isHide;
    },
    active() {
      return this.isActive(`/${this.route}`);
    },
    transformCounter() {
      return this.counter > 99
        ? '99+'
        : this.counter;
    },
  },
  methods: {
    showTooltip() {
      const { itemMenuRef } = this.$refs;
      this.distanceToTop = itemMenuRef.getBoundingClientRect().top;
      this.tooltipShow = true;
    },
    hideTooltip() {
      this.tooltipShow = false;
    },
    isActive(route) {
      // TODO: rewrite this, work wrong by
      // "agreements-implementation-technological-connection" and "agreements value
      return this.$route.path.includes(route) && (!this.params || (this.params.id && this.params.id === this.$route.params.id));
    },
    openGroup() {
      this.$emit('openGroup', this.indexGroup);
    },
    openSubGroup(index) {
      this.openIndex = index;
    },
  },
};
</script>

<style lang="sass" module>
.container
  min-width: 270px
  width: 270px
  font-family: 'Roboto Condensed', sans-serif
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 20px
  color: #4C4E51

  &:hover
    background-color: #DFE1E5

  a
    display: block

    div
      display: flex
      padding: 16px 20px 16px 28px
      cursor: pointer

      span
        max-width: 220px
        color: #4C4E51

  svg
    min-width: 24px
    fill: #4C4E51
    margin-right: 16px

  svg
    min-width: 24px
    min-height: 24px

  div
    position: relative

    .counter
      font-family: 'Roboto Condensed', sans-serif
      font-style: normal
      font-weight: 400
      font-size: 10px
      line-height: 10px
      position: absolute
      width: 16px
      height: 16px
      right: 15px
      top: calc(50% - 18px / 2)
      display: flex
      justify-content: center
      align-items: center
      color: #fff !important
      background: #EE6300
      border-radius: 26px

    .compactCounter
      font-family: 'Roboto Condensed', sans-serif
      font-style: normal
      font-weight: 400
      font-size: 10px
      line-height: 10px
      position: absolute
      width: 16px
      height: 16px
      right: 5px
      top: calc(50% - 18px / 2)
      display: flex
      justify-content: center
      align-items: center
      color: #fff !important
      background: #EE6300
      border-radius: 26px

  .menuLink
    width: 100%

.compact
  width: 64px

  span
    display: none

  svg
    margin-right: 0

.active
  background-color: #DFE1E5
  border-left: 6px solid #EE6300
  font-family: 'Roboto Condensed', sans-serif

  div
    margin-left: -6px

  span
    color: #0F1010 !important

    svg
      fill: #0F1010

.under
  background-color: #E9EBED
  padding-left: 10px

.under.isCompact
  padding-left: 0

  a
    div
      padding-left: 22px

.container.isCompact
  width: 64px
  min-width: 64px

.underActive
  padding-left: 10px

.isCompact
  padding-left: 0

  a
    div
      padding-left: 12px

.open
  background: rgba(233, 235, 237, 0.7)
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15)
  margin-bottom: 8px

.isGroup
  display: flex
  justify-content: space-between

  .iconChevron
    margin-right: 22px
    cursor: pointer

  .iconChevronUp
    transform: scale(1, -1)

.isGroup.isCompact
  .iconChevron
    margin-left: -6px

  a
    width: 40px

.hide
  display: none
</style>
