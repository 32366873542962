import { apolloClient } from '@/api/graphql';
import {
  BALANCE_BOUNDARIES,
  DEPARTMENTS_BY_BALANCE_BOUNDARY_IDS,
  INTEGRAL_ACT_PATTERNS,
  SUBSTATIONS,
} from '@/api/graphql/queries';

async function loadIntegralActPatterns({ rootState, commit }) {
  try {
    console.log('start loading integral patterns', rootState.user.token);
    const { data: { integralActPatterns } } = await apolloClient.query({
      query: INTEGRAL_ACT_PATTERNS, fetchPolicy: 'no-cache', context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loaded integral patterns', integralActPatterns);
    return integralActPatterns;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loading integral patterns::catch', error);
    throw error;
  }
}
// Возвращает участки сети, а не границы;
async function loadBalanceBoundaries({ rootState, commit }, payload) {
  try {
    const { data: { balanceBoundaries } } = await apolloClient.query({
      query: BALANCE_BOUNDARIES, fetchPolicy: 'no-cache', variables: {
        filter: payload?.filter,
      }, context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loaded BalanceBoundaries', balanceBoundaries);
    return balanceBoundaries;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loading BalanceBoundaries::catch', error);
    throw error;
  }
}

async function departmentsByBalanceBoundaryIds({ rootState }, payload) {
  try {
    const { data: { departmentsByBalanceBoundaryIds } } = await apolloClient.query({
      query: DEPARTMENTS_BY_BALANCE_BOUNDARY_IDS, fetchPolicy: 'no-cache', variables: {
        balanceBoundaryIds: payload?.balanceBoundaryIds,
      }, context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loaded departmentsByBalanceBoundaryIds', departmentsByBalanceBoundaryIds);
    return departmentsByBalanceBoundaryIds;
  } catch (error) {
    console.log('loading departmentsByBalanceBoundaryIds::catch', error);
    throw error;
  }
}

async function substations({ rootState, commit }) {
  try {
    const { data: { substations } } = await apolloClient.query({
      query: SUBSTATIONS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return substations;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loading substations::catch', error);
    throw error;
  }
}

export default {
  loadIntegralActPatterns,
  loadBalanceBoundaries,
  departmentsByBalanceBoundaryIds,
  substations,
};
