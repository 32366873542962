<template>
  <div class="form-wrp" role="form-wrp-top">
    <div class="form-wrp__applications">
      <span v-if="getContract.selectedTemplate.mnemonicType === 'ADDITIONAL_REQUISITES'">
        {{ getPatternName }} не содержит приложений
      </span>
      <three-side
        v-else-if="(getContract.selectedTemplate.mnemonicType === 'MES_BOILER_TSO' || getContract.selectedTemplate.contractType === 'MES_BOILER_TSO') && getContract.contractType !== 'ELECTRICITY_PURCHASE_CONTRACT'"/>
      <eso-tso
        v-else-if="getContract.selectedTemplate.mnemonicType === 'BOILER_UTILITY' || getContract.selectedTemplate.mnemonicType === 'FSK_TSO' ||
        getContract.selectedTemplate.contractType === 'BOILER_UTILITY' || getContract.selectedTemplate.contractType === 'FSK_TSO'"/>
      <mes-rmr-oek
        v-else-if="getContract.selectedTemplate.mnemonicType === 'MES_RMR_OEK' ||
        getContract.selectedTemplate.contractType === 'MES_RMR_OEK'"/>
      <eso-rmr-oek
        v-else-if="getContract.selectedTemplate.mnemonicType === 'ESO_RMR_OEK' ||
      getContract.selectedTemplate.contractType === 'ESO_RMR_OEK'"/>
      <eso-oek-consumer-oek
        v-else-if="getContract.selectedTemplate.mnemonicType === 'CONSUMER_BOILER' || getContract.selectedTemplate.mnemonicType === 'BOILER_RETAILER' ||
        getContract.selectedTemplate.contractType === 'CONSUMER_BOILER' || getContract.selectedTemplate.contractType === 'BOILER_RETAILER'"
        :template-path="getContract.selectedTemplate.mnemonicType === 'CONSUMER_BOILER' || getContract.selectedTemplate.contractType === 'CONSUMER_BOILER' ? 'oek-consumer' : 'eso-oek'"
      />
      <retail-retail-purchase
        v-else-if="getContract.selectedTemplate.mnemonicType === 'RETAIL_RETAIL_PURCHASE' || getContract.contractType === 'ELECTRICITY_PURCHASE_CONTRACT'"
      />
    </div>

    <div style="display: flex; flex-direction: column; gap: 24px">
      <div class="form__tooltips">
        <input-tooltip
          :text="tooltipText3"
          style="width: 350px; margin-top: 160px"
        />
      </div>

      <div v-if="getContract.selectedTemplate.mnemonicType !== 'ADDITIONAL_REQUISITES'" class="form__tooltips">
        <input-tooltip
          :text="tooltipText2"
          style="width: 350px;"
        />
      </div>

      <div v-if="getContract.selectedTemplate.mnemonicType !== 'ADDITIONAL_REQUISITES'" class="form__tooltips">
        <input-tooltip
          :text="tooltipText4"
          style="width: 350px; margin-top: 75px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThreeSide from 'views/private/UploadDocument/steps/UploadAdditions/ThreeSide.vue';
import EsoTso from 'views/private/UploadDocument/steps/UploadAdditions/EsoTso.vue';
import MesRmrOek from 'views/private/UploadDocument/steps/UploadAdditions/MesRmrOek.vue';
import EsoRmrOek from 'views/private/UploadDocument/steps/UploadAdditions/EsoRmrOek.vue';
import EsoOekConsumerOek from 'views/private/UploadDocument/steps/UploadAdditions/EsoOekConsumerOek.vue';
import RetailRetailPurchase from 'views/private/UploadDocument/steps/UploadAdditions/RetailRetailPurchase.vue';
import { InputTooltip } from 'components';
import { mapGetters } from 'vuex';

export default {
  name: 'UploadAdditions',
  components: {
    EsoTso,
    InputTooltip,
    ThreeSide,
    MesRmrOek,
    EsoRmrOek,
    EsoOekConsumerOek,
    RetailRetailPurchase,
  },
  computed: {
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договора';
      }
      return 'доп.соглашения';

    },
    contractType2() {
      if (this.getContract.type === 'contract') {
        return 'договору';
      }
      return 'доп.соглашению';

    },
    tooltipText1() {
      const text = `Важно: у ${this.contractType} должно быть хотя бы одно отделение с приложениями.`;
      return this.boldString(text, 'Важно');
    },
    tooltipText2() {
      let text = 'Важно: Скачайте и заполните шаблоны всех приложений в точном соответствии с названием полей шаблона. Не оставляйте поля пустыми. Количество строк во всех приложениях должно совпадать. Введите точные данные, указанные в оригинальном документе, даже если эти данные содержат устаревшую или ошибочную информацию. Не используйте собственные формы файлов.';

      if (this.getContract.action === 'upload') {
        text += ' Введите точные данные, указанные в оригинальном документе, даже если эти данные содержат устаревшую или ошибочную информацию.';
      }

      return this.boldString(text, 'Важно');
    },
    tooltipText3() {
      return `Для загрузки приложений к ${this.contractType2} скачайте шаблоны документов в формате .XLSX (MS Exsel)`;
    },
    tooltipText4() {
      const text = 'Важно: Для каждого Отделения создайте отдельные файлы Приложения 2 и Приложения 3 на основе шаблонов.';
      return this.boldString(text, 'Важно');
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getPatternName']),
  },
  methods: {
    boldString(str, substr) {
      const strRegExp = new RegExp(substr, 'g');
      return str.replace(strRegExp, `<b style="color: #EB5757;">${substr}</b>`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-wrp {
    display: flex;
    flex-direction: row;
    gap: 20px;

    &__applications {
      margin: 24px 0 20px 100px;
      position: relative;
    }

    &__tooltips {
      width: 350px;
      position: absolute;
      right: -370px;
      margin-top: -60px;
    }
  }
}
</style>
