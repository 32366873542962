<template>
  <div>
    <label>{{ label }}</label><br/>
    <input
        v-model="date"
        :class="{'enabled': isEnabled && !isWaiting, 'disabled': !isEnabled || isWaiting}" :disabled="isWaiting"
        :maxlength="maxlength"
        :role="role"
        class="enabled field mb-24"
        type="text"
        @input="onInput"
        @keyup="onKeyup"
    />

    <template v-if="isShowNote && !isRestore">
      <label>Введите пароль</label><br/>
      <div class="password-container">
<!-- TODO: заменить на VInput (либо дизайн авторизации под VInput, либо стилизовать под текущий дизайн VInput -->
        <input
            ref="passwordInput"
            v-model="password"
            :class="{'enabled': isEnabled && !isWaiting, 'disabled': !isEnabled || isWaiting}" :disabled="isWaiting"
            class="enabled field"
            placeholder="Введите пароль"
            :type="passwordInputType"
            @input="changePassword($event)"
            @keyup="onKeyup"
        />
        <img v-show="showPassword"
             class="password-container__displayPassword"
             :style="showPasswordStyle"
             title="Скрыть"
             src="@/assets/icons/basic/eye_closed_flat.svg"
             @click="clickShowPassword"
        />
        <img v-show="!showPassword"
             class="password-container__displayPassword"
             :style="showPasswordStyle"
             title="Показать"
             src="@/assets/icons/basic/eye_flat.svg"
             @click="clickShowPassword"
        />
        <v-clearable-input class="password-container__displayPassword" style="right: 13px" :is-show="isShowClear" @click="password = ''" />
      </div>

      <p v-if="errorMessage.length !== 0" class="error-text" style="text-align: center">{{ errorMessage }}</p>

      <button :disabled="!isEnabled || isWaiting || password.length < 4" class="button-enter" @click="onAction">
        Войти
      </button>
    </template>

    <horizontal-progress-bar v-if="isWaiting"/>
    <p v-if="isLoginInvalid" class="restore-password" @click="restorePassword">Восстановить пароль</p>
    <p v-if="isShowNote" class="note-text">{{ note }}</p>

    <template v-if="isShowNote && !isLoginInvalid && !isRestore">
      <p class="other-enter__note">войти другим способом:</p>
      <div class="other-enter__options">
        <div class="other-enter__item" @click="capAuthorization">Эл. подпись</div>
        <a class="other-enter__item" href="https://esia.gosuslugi.ru/login/"><img alt=""
                                                                                  src="@/assets/images/logos/logo-gosuslugi.svg"></a>
        <!--        <div class="other-enter__item mos"><img alt="" src="@/assets/images/logos/mosru-logo.svg"></div>-->
      </div>

      <button class="button-enter" @click="registration">
        ЗАРЕГИСТРИРОВАТЬСЯ
      </button>
    </template>
    <button v-else-if="isRestore" class="button-back" @click="onBack">
      Назад
    </button>
  </div>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import { mapActions } from 'vuex';
import { VClearableInput } from '@/components';

export default {
  name: 'InputAction',
  components: {
    'horizontal-progress-bar': HorizontalProgressBar,
    VClearableInput,
  },
  props: [
    'label',
    'isEnabled',
    'note',
    'isShowNote',
    'errorMessage',
    'errorCode',
    'value',
    'maxlength',
    'isWaiting',
    'role',
    'isRestore',
  ],
  data() {
    return {
      date: this.value,
      password: '',
      showPassword: false,
      passwordInputType: 'password',
    };
  },
  computed: {
    isLoginInvalid() {
      return this.errorCode === 'INVALID';
    },
    isShowClear() {
      return this.password.length > 0;
    },
    showPasswordStyle() {
      if (!this.isShowClear) {
        return '';
      }

      return 'right: 32px';
    },
  },
  watch: {
    value(newVal) {
      this.date = newVal;
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.date);
    },
    onKeyup(e) {
      if (e.key === 'Enter' && this.isEnabled) {
        if (this.password.length >= 4) {
          this.onAction();
          return;
        }
        this.$refs.passwordInput.focus();
      }
    },
    onAction() {
      this.$emit('action');
    },
    onBack() {
      this.$emit('back');
    },
    registration() {
      this.$router.push('/consumer-type');
    },
    changePassword() {
      this.$emit('password', this.password);
    },
    capAuthorization() {
      this.clearUserData();
      this.$router.push('/cap-authorization');
    },
    restorePassword() {
      this.$router.push({
        name: 'restore-password',
        params: { contact: this.date },
      });
    },
    clickShowPassword() {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    },
    ...mapActions('user', ['clearUserData']),
  },
};
</script>

<style scoped>
label {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #4F4F4F;
  text-transform: uppercase;
}

input::-ms-clear {
  display: none;
}

input.field, .field input {
  font-family: 'Roboto Condensed', sans-serif !important;;
  width: 100%;
  height: 50px;
  font-size: 22px !important;
  font-style: normal !important;
  line-height: 64px !important;
  color: #4F4F4F;
  border: 1px solid #C4C4C4 !important;
  padding: 4px 90px 4px 16px;
  margin-top: 10px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  border: 1px solid #C4C4C4 !important;
  box-shadow: none !important;
  border-radius: unset !important;
  margin-bottom: 0 !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot:focus-visible {
  border: 1px solid black !important;
  outline: 0 !important;
}

.v-input--is-focused.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  border: 1px solid black !important;
  outline: 0 !important;
}

input.field:focus-visible {
  border: 1px solid black;
  outline: 0 !important;
}

input.field:focus {
  border: 1px solid black !important;
  outline: 0 !important;
}

.error-class {
  color: red;
  border-color: #ee9797;
  border-style: solid;
}

.error-class:focus-visible {
  color: red;
  border-color: red;
  border-style: solid;
  outline: none !important;
}

.error-class:focus {
  color: red;
  border-color: red !important;
  border-style: solid;
  outline: none !important;
}

.error-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-top: 12px;
  color: red;
}

.note-text {
  margin-top: 12px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #4F4F4F;
}

#root-img-enter-enabled {
  height: 64px;
  margin-left: 100%;
  margin-top: -64px;
}

#img-enter-enabled {
  height: 64px;
  width: 74px;
  cursor: pointer;
  display: inline-block;
  margin-left: -74px;
}

.button-enter {
  width: 100%;
  height: 56px;
  text-transform: uppercase;
  background-color: #2F82DF;
  color: #FFF;
  font-size: 21px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  line-height: 30px;
  margin-top: 16px;
}

.button-back {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 56px;
  text-transform: uppercase;
  font-size: 21px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  line-height: 30px;
  margin-top: 16px;
  background-color: #FFFFFF;
  border: 1px solid #C1C4C7;
  color: #262729;
}

.button-back:hover {
  background-color: #ececec;
}

.button-enter:disabled {
  background-color: #ACB0B5;
}

.mb-24 {
  margin-bottom: 24px;
}

.other-enter__note {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  color: #4F4F4F;
  margin-top: 20px;
  margin-bottom: 20px;
}

.other-enter__options {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 16px;
}

.other-enter__item {
  border-radius: 2px;
  border: 1px solid #C1C4C7;
  padding: 8px 13px;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.other-enter__item.mos {
  padding: 8px 18px;
}

.restore-password {
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 17px;
  cursor: pointer;
}

.password-container {
  position: relative;
}

.password-container__displayPassword {
  position: absolute;
  right: 8px;
  top: 26px;
  cursor: pointer;

  height: 18px;
}
</style>
