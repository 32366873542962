<template>
  <v-row>
    <v-col :grid="{ xs: 3 }">
      <v-date-picker
        v-model="dateFrom"
        label="Дата от"
        format="DD.MM.YYYY"
      />
    </v-col>
    <v-col :grid="{ xs: 3 }">
      <v-date-picker
        v-model="dateTo"
        label="Дата до"
        format="DD.MM.YYYY"
      />
    </v-col>
    <v-col :grid="{ xs: 1 }">
      <div style="display: flex;">
        <v-button style="margin-right: 10px;" variant="filled" @click="onSearchAll()"> Найти</v-button>
        <v-button variant="outlined" @click="onClearAll()"> Очистить</v-button>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { VButton, VCol, VDatePicker, VRow } from '@/components';

export default {
  name: 'VHistoryFilterBlock',
  components: {
    VButton,
    VRow,
    VCol,
    VDatePicker,
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
    };
  },
  watch: {
    value() {
      this.internalValue = this.value;
    },
  },
  methods: {
    onSearchAll() {
    },
    onClearAll() {
      this.dateTo = '';
      this.dateFrom = '';
    },
  },
};
</script>
