<template>
  <div class="page-form">

    <div class="page-form__form">
      <div class="row">
        <h1 class="col col--1of1 mb-24 page-form__title">
          {{ isIndividual ? 'Карточка потребителя ФЛ (ДЭС)' : 'Карточка потребителя ЮЛ (ДЭС)' }}
        </h1>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <h2 class="col col--1of1 page-form__sub-title">
          Ваши данные
        </h2>
      </div>

      <!-- Ваши данные -->
      <div class="row mb-24">
        <div class="col col--1of1">
          <v-input
            clearable
            disabled
            title="Наименование потребителя"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <div class="col col--2of3">
          <v-input
            clearable
            disabled
            title="Адрес местоположения энергопринимающих устройств"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            clearable
            disabled
            title="Почтовый индекс"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <h2 class="col col--1of1 mb-24 page-form__sub-title">
          Сведения о договоре энергоснабжения
        </h2>
      </div>

      <div
        v-if="isScreenWidthLess1000px"
        class="row"
        style="margin-bottom: 20px;"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Для сбытовых потребителей — № лицевого счёта"
          />
        </div>
      </div>

      <!-- Сведения о договоре энергоснабжения -->
      <div class="row mb-24">
        <div class="col col--1of2">
          <v-input
            clearable
            disabled
            title="Номер договора"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            disabled
            format="DD.MM.YYYY"
            title="Дата договора"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of2">
          <v-input
            clearable
            disabled
            title="Номер объекта"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            :is-need-mouseover-tooltip="true"
            disabled
            format="DD.MM.YYYY"
            title="Дата начала снабжения электрической энергией"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <h2 class="col col--1of1 page-form__sub-title">
          Сведения о документах, подтверждающих технологическое присоединение
        </h2>
      </div>

      <div
        v-if="isScreenWidthLess1000px"
        class="row"
        style="margin-bottom: 20px;"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Разрешение на присоединение мощности"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of2">
          <v-input
            clearable
            disabled
            title="Номер Акта разграничения"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            disabled
            format="DD.MM.YYYY"
            title="Дата Акта разграничения"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of2">
          <v-input
            clearable
            disabled
            title="Номер Акта о технологическом присоединении"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            :is-need-mouseover-tooltip="true"
            disabled
            format="DD.MM.YYYY"
            title="Дата Акта о технологическом присоединении"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div
        v-if="isScreenWidthLess1000px"
        class="row"
        style="margin-bottom: 20px;"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Определяется в соответствии с документом о технологическом присоединении"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-select
            :options="[]"
            disabled
            title="Категория надёжности электроснабжения"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-select
            :options="[]"
            disabled
            title="Величина максимальной мощности, МВт"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <h2 class="col col--1of1 page-form__sub-title">
          Величина брони, МВт
        </h2>
      </div>

      <div class="row mb-24">
        <div class="col col--1of2">
          <v-input
            clearable
            disabled
            title="Аварийной"
          />
        </div>
        <div class="col col--1of2">
          <v-date-picker
            disabled
            format="DD.MM.YYYY"
            title="Технологической"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-input
            clearable
            disabled
            title="Примечание"
          />
        </div>
      </div>

      <v-divider class="mb-24 page-form__divider"/>

      <!-- Документы -->
      <div class="row mb-24">
        <div class="col col--1of1">
          <documents-list :documents="documents"/>
        </div>
      </div>
    </div>

    <div
      v-if="!isScreenWidthLess1000px"
      :style="{
        marginLeft: '20px',
        maxWidth: '300px',
      }"
      class="page-form__aside"
    >
      <div
        :style="{
          marginTop: '422px'
        }"
        class="row"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Для сбытовых потребителей — № лицевого счёта"
          />
        </div>
      </div>

      <div
        :style="{
          marginTop: '200px'
        }"
        class="row"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Разрешение на присоединение мощности"
          />
        </div>
      </div>

      <div
        :style="{
          marginTop: '132px'
        }"
        class="row"
      >
        <div class="col col--1of1">
          <input-tooltip
            text="Определяется в соответствии с документом о технологическом присоединении"
          />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { DocumentsList, InputTooltip, VDatePicker, VDivider, VInput, VSelect } from '@/components';

export default {
  components: {
    VInput,
    VSelect,
    VDatePicker,
    VDivider,
    DocumentsList,
    InputTooltip,
  },
  props: {
    isIndividual: { type: Boolean, default: false },
  },
  data() {
    return {
      documents: [
        {
          name: 'Договор энергоснабжения.PDF',
          date: '',
          documentId: '',
        },
      ],
    };
  },
  computed: {
    isScreenWidthLess1000px() {
      return window.matchMedia('(max-width: 1000px)').matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";
</style>
