<template>
  <div>
    <v-table-new
      v-model="transformedContracts"
      :loading="loading"
      :template="template"
      excel-report-file-name="Договоры энергоснабжения"
    />
  </div>
</template>

<script>
import { VTableNew } from '@/components';
import { getContractStatusLabelValueList } from 'lib/utils/formatXmlType';
import { convertDbFormat } from 'lib/utils/date';

export default {
  name: 'ContentBlock',
  components: {
    VTableNew,
  },
  props: {
    loading: { type: Boolean, required: true },
    contracts: { type: Array, required: true },
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Договор',
            key: 'title',
            thStyle: {
              maxWidth: '400px',
            },
            tdStyle: {
              maxWidth: '400px',
            },
            sort: {},
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
            checkEmpty: true,
          },
          {
            label: 'Номер документа',
            key: 'documentNumber',
            thStyle: {
              minWidth: '111px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Дата заключения',
            key: 'formattedDate',
            thStyle: {
              minWidth: '110px',
            },
            sort: { type: 'date' },
            filter: { type: 'date' },
          },
          {
            label: 'Сбытовая организация',
            key: 'retailer',
            sort: {},
            filter: {},
          },
          {
            label: 'Абонент',
            key: 'consumer',
            sort: {},
            filter: {},
          },
          {
            label: 'Статус',
            key: 'status',
            type: 'status',
            sort: {},
            filter: { type: 'select', values: getContractStatusLabelValueList() },
          },
        ],
      };
    },
    transformedContracts() {
      return this.contracts.map((contract) => ({
        title: contract.title,
        document: { documentId: contract.documentId, name: contract.title },
        documentNumber: contract.number,
        formattedDate: convertDbFormat(contract.date),
        retailer: contract.sides.find((side) => side.sideName === 'SIDE_FIRST').name,
        consumer: contract.sides.find((side) => side.sideName === 'SIDE_SECOND').name,
        status: contract.status,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
