function createElement(name, value, namespace, prefix) {
  const element = document.createElementNS(namespace, `${prefix}:${name}`);
  element.appendChild(document.createTextNode(value));
  return element;
}

function addChildNode(parent, name, value, afterName) {
  if (value && String(value).length > 0) {
    const insertAfterNode = parent.getElementsByTagNameNS('*', afterName)[0];
    const newNode = createElement(name, value, parent.namespaceURI, parent.prefix);
    if (!insertAfterNode) {
      parent.appendChild(newNode);
      return;
    }
    insertAfterNode.parentNode.insertBefore(newNode, insertAfterNode.nextSibling);
  }
}

function removeNode(parent, name) {
  const element = parent.getElementsByTagNameNS('*', name);
  for (let i = 0; i < element.length; i++) {
    element[i].parentNode.removeChild(element[i]);
  }
}

function replaceNodesValue(parent, name, value, afterName) {
  removeNode(parent, name);
  addChildNode(parent, name, value, afterName);
}

export function addNumberAndDateToContract(xml, number, date) {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, 'application/xml');
    const docRequisitesNode = doc.getElementsByTagNameNS('*', 'ContractRequisites')[0];
    replaceNodesValue(docRequisitesNode, 'ContractNumber', number, 'ContractName');
    replaceNodesValue(docRequisitesNode, 'ContractDate', date, 'ContractPlace');
    return new XMLSerializer().serializeToString(doc).replaceAll(' xmlns=""', '');
  } catch (e) {
    console.log(e);
  }
}
