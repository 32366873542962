<template>
  <div>
    <p v-if="checkOwner" style="margin-bottom: 16px; color: #71757A">
      * актуальные данные по доверенностям являются ответственностью компании
    </p>
    <power-of-attorney-table
      :power-of-attorneys="getFilteredPowerOfAttorneys"
    />
  </div>
</template>

<script>
import { PowerOfAttorneyTable } from '@/components';
import { mapGetters } from 'vuex';

export default {
  name: 'ContentBlock',
  components: {
    PowerOfAttorneyTable,
  },
  data() {
    return {
      sortKey: '',
      sortDirection: 1,
    };
  },
  computed: {
    checkOwner() {
      return this.getAuthorization.authorization.role.isCompanyOwner;
    },
    ...mapGetters('attorney', ['getFilteredPowerOfAttorneys']),
    ...mapGetters('user', ['getAuthorization']),
  },
};
</script>

<style scoped>

</style>
