<template>
  <page title="Платёжные документы">
    <div class="form">
      <div>
        <v-table-new
          v-model="transformedInvoices"
          :loading="loading"
          :template="template"
          excel-report-file-name="Платёжные документы"
        />
      </div>
    </div>
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { mapActions } from 'vuex';
import { convertToLabelValueList } from 'utils/list';

export default {
  name: 'CabinetPd',
  components: {
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      invoices: [],
    };
  },
  computed: {
    transformedInvoices() {
      if (this.invoices.length === 0) return [];

      return this.invoices.map((invoice) => ({
        name: invoice.name,
        invoiceDate: invoice.invoiceDate,
        amount: invoice.amount,
        status: invoice.status === true ? 'Оплачен' : 'Неоплачен',
        payeeName: invoice.payeeName,
        invoiceDocumentId: { documentId: invoice.invoiceDocumentId, name: invoice.name },
      }));
    },
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: 'Наименование документа',
            key: 'name',
            thStyle: {
              maxWidth: '200px',
            },
            tdStyle: {
              maxWidth: '200px',
            },
            sort: {},
            tooltip: {
              maxLength: 12,
              minSreenWidthTooltip: 1024,
            },
            filter: {},
          },
          {
            label: 'Дата документа',
            key: 'invoiceDate',
            sort: { type: 'date' },
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            filter: { type: 'date' },
          },
          {
            label: 'Сумма платежа',
            key: 'amount',
            type: 'number',
            sort: {
              type: 'number',
            },
            tooltip: {
              maxLength: 5,
              minSreenWidthTooltip: 1024,
            },
            filter: {
              type: 'number',
            },
          },
          {
            label: 'Статус',
            key: 'status',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {},
            filter: { type: 'select', values: convertToLabelValueList(['Оплачен', 'Неоплачен']) },
          },
          {
            label: 'Кому выставлен',
            key: 'payeeName',
            sort: {},
            tooltip: {
              maxLength: 4,
              minSreenWidthTooltip: 1024,
            },
            filter: {},
          },
          {
            label: 'Ссылки на скачивание документа',
            key: 'invoiceDocumentId',
            tooltip: {
              maxLength: 6,
              minSreenWidthTooltip: 1024,
            },
            type: 'documentDownload',
            checkEmpty: true,
          },
        ],
      };
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.invoices = await this.paymentInfo();
      } catch (error) {
        console.log('paymentInfo', error);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('payment', ['paymentInfo']),
  },
};
</script>

<style lang="scss" scoped>
</style>
