import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';


// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Files URL root
// export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));
// Vue.prototype.$filesRoot = filesRoot;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ['Authorization']: `Bearer ${token}`,
      ['X-Authorization']: `Bearer ${token}`,
      'Apollo-Require-Preflight': 'true',
    },
  };
});

const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS_MESSENGER
  ? process.env.VUE_APP_GRAPHQL_WS_MESSENGER
  : `wss://${window.location.hostname}/messenger/subscriptions`;

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || `https://${window.location.hostname}/graphql`,
});

const messendgerHttpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP_MESSENGER || `https://${window.location.hostname}/messenger/graphql`,
});

const httpMultipartLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP || `https://${window.location.hostname}/graphql`,
});


// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
  },
});


const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' &&
      operation === 'subscription';
  },
  wsLink,
  messendgerHttpLink,
);

// Call this in the Vue app file
export function createProvider() {
  const apolloClientDefault = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const apolloClientMessenger = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  });

  const apolloClientFiles = new ApolloClient({
    link: authLink.concat(httpMultipartLink),
    cache: new InMemoryCache(),
  });


  // Create vue apollo provider
  return new VueApollo({
    clients: {
      apolloClientDefault,
      apolloClientMessenger,
      apolloClientFiles,
    },
    defaultClient: apolloClientDefault,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
    },
  });
}
