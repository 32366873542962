import { read, utils } from 'xlsx';
import { check } from 'lib/utils/validation/additionValidation';
import { compareRowCol } from 'utils/compare';
import { getXlsFormat } from 'lib/utils/date';

const xlsxReadConfig = {
  type: 'base64',
  cellText: false,
  cellDates: true,
};

// https://www.npmjs.com/package/xlsx#json
const sheetToJsonConfig = {
  header: 1,
  defval: '',
  raw: false,
  blankrows: false,
  dateNF: 'DD/MM/YYYY',
};

const readFromXlsx = (fileBase64, config) => read(fileBase64, config);
const sheetToJson = (sheet, config) => utils.sheet_to_json(sheet, config);

function process(value, template) {
  if (!template.rules || !value || value.length === 0) {
    return value;
  }

  const rules = template.rules.split('|');

  if (rules.includes('number')) {
    return String(value).replace(',', '.');
  }

  if (rules.includes('date')) {
    return getXlsFormat(value);
  }

  if (rules.includes('maxLength255') || rules.includes('maxLength1024')) {
    return String(value).replace(/\s\s+/g, ' ');
  }

  return value;
}

const parse = (parsed, line, rowNumberStart, lineIndex, parentKey) => {
  const result = {};
  const errors = [];

  for (const parsedKey in parsed) {
    const parsedValue = parsed[parsedKey];
    const keys = parentKey ? [...parentKey] : [];
    keys.push(parsedKey);
    if (parsedValue != null && typeof parsedValue === 'object' && !Array.isArray(parsedValue) && !parsedValue.colIndex) {
      const parseResult = parse(parsedValue, line, rowNumberStart, lineIndex, keys);

      result[parsedKey] = parseResult.result;
      errors.push(...parseResult.errors);

    } else if (parsedValue && parsedValue.colIndex) {
      const value = line[parsedValue.colIndex];
      result[parsedKey] = process(value, parsedValue);
      const resultValidation = check(result[parsedKey], parsedValue, parsed, line);

      if (resultValidation.isNotValid) {
        errors.push({
          text: resultValidation.errorText,
          value: result[parsedKey],
          incorrectValue: result[parsedKey],
          rowIndex: lineIndex - rowNumberStart,
          row: lineIndex + 1,
          colIndex: parsedValue.colIndex,
          col: parsedValue.col ?? parsedValue.colIndex + 1,
          title: parsedValue.title,
          keys: keys,
          template: parsedValue,
          parsed: parsed,
          line: line,
        });
      }
    }
  }
  return { result: result, errors: errors };
};

export const parseSheet = (argsArr) => {
  const [fileBase64, parsingRules, taskId] = argsArr;

  const excel = readFromXlsx(fileBase64, xlsxReadConfig);
  const sheet = sheetToJson(excel.Sheets[excel.SheetNames[0]], sheetToJsonConfig);

  const result = [];
  const errors = [];

  // rows
  for (let i = parsingRules.rowNumberStart; i < sheet.length; i++) {
    if (parsingRules.emptyBreakColumn && sheet[i][parsingRules.emptyBreakColumn].length === 0) {
      break;
    }

    const parseResult = parse(parsingRules, sheet[i], parsingRules.rowNumberStart, i);
    result.push(
      parseResult.result,
    );

    errors.push(...parseResult.errors);
  }

  return {
    data: result,
    errors: errors.length === 0 ? [] : errors.sort(compareRowCol),
    taskId,
  };
};

function toFixedNumber(value) {
  return Number(Number(value).toFixed(4));
}

export const parsePowerProfile = (context, blob, meterNumber) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const meterIdCellValue = 'C6';
    const totalValueCell = 'N6';
    const ktrValueCell = 'F6';
    const previousActiveInputValueCell = 'H6';
    const currentActiveInputValueCell = 'J6';
    const differenceValueCell = 'L6';

    const data = new Uint8Array(event.target.result);
    const workbook = readFromXlsx(data, { ...xlsxReadConfig, type: 'array' });

    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const meterNumberObj = worksheet[meterIdCellValue].v;
    context.meterNumber = meterNumberObj;
    const tableArray = [];
    const totalN6 = worksheet[totalValueCell] ? toFixedNumber(worksheet[totalValueCell].v) : 0;

    context.profileInfo = {
      ktr: worksheet[ktrValueCell] ? toFixedNumber(worksheet[ktrValueCell].v) : 0,
      previousActiveInput: worksheet[previousActiveInputValueCell] ? toFixedNumber(worksheet[previousActiveInputValueCell].v) : 0,
      currentActiveInput: worksheet[currentActiveInputValueCell] ? toFixedNumber(worksheet[currentActiveInputValueCell].v) : 0,
      difference: worksheet[differenceValueCell] ? toFixedNumber(worksheet[differenceValueCell].v) : 0,
      totalEnergy: totalN6,
    };

    if (meterNumber && meterNumber !== meterNumberObj.trim()) {
      context.isValidationError = true;
      context.validationError = `Номер счётчика (${meterNumber}) не совпадает с номером счётчика (${meterNumberObj}) в профиле мощности`;
    }

    const validateTotalValue = (totalValue, sumValue) => {
      if (totalValue !== sumValue) {
        context.isValidationError = true;
        context.validationError = `Сумма почасовых показаний (${sumValue}) должна быть равна показаниям в Итого квт*ч в вашем файле (${totalValue})`;
      }
    };

    for (let i = 0; i < 33; i++) {
      const row = i + 11;
      const rowData = {};

      const dateCell = worksheet[`A${row}`];
      if (dateCell) {
        rowData.date = dateCell.v;
        for (let j = 1; j <= 24; j++) {
          const columnName = String.fromCharCode(65 + j);
          const cell = worksheet[`${columnName}${row}`];

          if (cell) {
            rowData[`hour${j}`] = toFixedNumber(cell.v);
          }
        }
      }

      tableArray.push(rowData);
    }

    const headers = [
      { key: 'date', text: 'Дата' },
      ...Array.from({ length: 24 }, (_v, k) => {
        const hour = k + 1;
        return { key: `hour${hour}`, text: `${hour}:00` };
      }),
    ];

    context.tableHeaders = headers;
    const newArr = tableArray.slice(1);
    newArr.pop();
    context.rows = newArr;

    const totalRowsSum = context.rows.reduce((accumulator, row) => accumulator + Object.keys(row)
      .filter((key) => key.startsWith('hour'))
      .reduce((sum, key) => sum + parseFloat(row[key]), 0), 0);

    validateTotalValue(totalN6, totalRowsSum);

    console.log('Parsed meter number:', meterNumberObj);
    console.log('Parsed table data:', context.rows);
  };

  reader.onerror = (error) => {
    console.error('Ошибка при чтении файла:', error);
  };

  reader.readAsArrayBuffer(blob);
};


