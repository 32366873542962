<template>
  <div class="generate-balance">
    <div>
      <v-row class="generate-balance__indicators">
        <v-col :grid="{ xs: 2.5 }">
          <v-indicator :color="indicators.transmissionContract.color">
            <template v-if="indicators.transmissionContract.isError !== undefined">
              <img v-if="indicators.transmissionContract.isError" slot="indicator"
                   alt="" src="~@/assets/icons/indicator/error.svg"/>
              <img v-else slot="indicator" alt="" src="~@/assets/icons/indicator/check.svg"/>
            </template>
            <span slot="text">Договор загружен</span>
          </v-indicator>
        </v-col>
        <v-col :grid="{ xs: 2.5 }">
          <v-indicator :color="indicators.integralActs.color">
            <template v-if="indicators.integralActs.isError !== undefined">
              <img v-if="indicators.integralActs.isError" slot="indicator" alt=""
                   src="~@/assets/icons/indicator/error.svg"/>
              <img v-else slot="indicator" alt="" src="~@/assets/icons/indicator/check.svg"/>
            </template>
            <span slot="text">{{ indicators.integralActs.text }}</span>
          </v-indicator>
        </v-col>
        <v-col :grid="{ xs: 2.5 }">
          <v-indicator :color="indicators.usefulVacation.color">
            <template v-if="indicators.usefulVacation.isError !== undefined">
              <img v-if="indicators.usefulVacation.isError" slot="indicator" alt=""
                   src="~@/assets/icons/indicator/error.svg"/>
              <img v-else slot="indicator" alt="" src="~@/assets/icons/indicator/check.svg"/>
            </template>
            <span slot="text">Полезный отпуск пришёл</span>
          </v-indicator>
        </v-col>
      </v-row>

      <v-row class="generate-balance__date-losses">
        <v-col :grid="{ xs: 2.5 }">
          <div class="generate-balance__date">
            <v-date-picker
              v-model=currentDate
              format="MMMM | YYYY"
              style="width: 160px;"
              type="month"
            />
          </div>
        </v-col>
        <v-col v-if="!indicators.isPreviousBalanceExists" :grid="{ xs: 8}">
          <div class="generate-balance__losses">
            <input
              ref="totalLoss"
              :value="totalLoss | totalLossFilter"
              class="generate-balance__balance-input"
              placeholder="Отрицательный объем потерь за предыдущий период"
              type="text"
              @input="updateTotalLoss"
            />
          </div>
        </v-col>
      </v-row>
      <div v-if="balanceIndicators">
        <v-row class="generate-balance__signatories">
          <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
            <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
              {{ balanceIndicators.creationByTransmissionContract?.supplier?.name }}*</p>
            <emploee-select-or-input :inn="balanceIndicators.creationByTransmissionContract?.supplier?.inn"
                                     permission="SVET_A13-S" @emploeeSelectOrInput="updateSupplier"/>
          </v-col>
        </v-row>
        <v-row class="generate-balance__signatories">
          <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
            <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
              {{ balanceIndicators.creationByTransmissionContract?.performerFirst?.name }}*</p>
            <emploee-select-or-input :inn="balanceIndicators.creationByTransmissionContract?.performerFirst?.inn"
                                     permission="SVET_A13-S"
                                     @emploeeSelectOrInput="updatePerformerFirstSignatory"/>
          </v-col>
        </v-row>
        <v-row class="generate-balance__signatories">
          <v-col :grid="{ xs: 2.5 }" class="generate-balance__signatories__col-wrapper">
            <p class="generate-balance__signatories__col-wrapper__title">Подписывающее лицо
              {{ balanceIndicators.creationByTransmissionContract?.performerSecond?.name }}*</p>
            <emploee-select-or-input :inn="balanceIndicators.creationByTransmissionContract?.performerSecond?.inn"
                                     permission="SVET_A13-S"
                                     @emploeeSelectOrInput="updatePerformerSecondSignatory"/>
          </v-col>
        </v-row>
      </div>
      <div class="generate-balance__buttons-wrp">
        <v-row class="generate-balance__buttons-wrp__buttons">
          <v-col>
            <v-button style="color: #0E0F0F; background: #FFFFFF; border: 1px solid #C1C4C7;"
                      @click="goBack">
              Отменить
            </v-button>

          </v-col>
          <v-col style="display:flex; justify-content: right">
            <div>
              <v-button
                :disabled="notValid"
                style="border: 1px solid;"
                @click="generateBalanceClick"
              >
                Сформировать баланс
              </v-button>
              <horizontal-progress-bar v-if="isWaitingSubmit" style="width: 210px;"/>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { EmploeeSelectOrInput, VButton, VCol, VDatePicker, VIndicator, VRow } from '@/components';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';

import dayjs from 'dayjs';
import { getDateAsForceISOString } from 'lib/utils/date';

const formatByThousands = (str) => {
  let res = '';
  const srcStr = str;

  if (srcStr[0] === '-') {
    str = str.slice(1);
  }

  // separate by thousands begin
  const separate = (str) => {
    const arr = str.split('');
    let index = -3;
    while (arr.length + index > 0) {
      arr.splice(index, 0, ' ');
      index -= 4;
    }
    return arr.join('');
  };
  // separate by thousands end

  res = separate(str);

  if (srcStr[0] === '-') {
    return `-${res}`;
  }

  return res;
};

export default {
  components: {
    VButton,
    VCol,
    VDatePicker,
    VIndicator,
    VRow,
    EmploeeSelectOrInput,
    HorizontalProgressBar,
  },
  data() {
    return {
      currentDate: dayjs().add(-1, 'month').format('MMMM | YYYY'),
      isWaitingSubmit: false,
      totalLoss: '',
      balanceIndicators: undefined,
      supplierSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
      performerFirstSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleNameGenitive: {},
      },
      performerSecondSignatory: {
        userId: '',
        role: {
          id: '',
          name: '',
          nameGenitive: '',
        },
        accountId: '',
        reason: {
          name: '',
          nameGenitive: '',
          number: null,
          date: null,
        },
        powerOfAttorneyId: '',
        fullName: {},
        fullNameGenitive: {},
        roleName: {},
        roleNameGenitive: {},
      },
    };
  },
  computed: {
    indicators() {
      const check = {
        color: '#13CE5ECC',
        isError: false,
      };
      const error = {
        color: '#D00B0B',
        isError: true,
      };
      const unknown = {
        color: '#867777',
        isError: undefined,
      };
      if (!this.balanceIndicators) {
        return {
          transmissionContract: unknown,
          usefulVacation: unknown,
          integralActs: {
            ...unknown,
            text: 'Интегральные акты сформированы',
          },
        };
      }

      const result = {};

      result.transmissionContract = this.balanceIndicators.isTransmissionContractSigned ? check : error;
      result.usefulVacation = this.balanceIndicators.isUsefulVacationExists ? check : error;
      result.isPreviousBalanceExists = this.balanceIndicators.isPreviousBalanceExists;

      const isIntegralActsText = this.balanceIndicators.isIntegralActsExists ? 'Интегральные акты подписаны' : 'Интегральные акты сформированы';

      if (this.balanceIndicators.isIntegralActsExists && this.balanceIndicators.isIntegralActsSigned) {
        result.integralActs = {
          ...check,
          text: isIntegralActsText,
        };
      } else {
        result.integralActs = {
          ...error,
          text: isIntegralActsText,
        };
      }

      return result;
    },
    isValidIndicators() {
      return this.balanceIndicators.isIntegralActsExists && this.balanceIndicators.isIntegralActsSigned && this.balanceIndicators.isUsefulVacationExists && this.balanceIndicators.isTransmissionContractSigned;
    },
    notValid() {
      const notValidSupplier = !this.checkRepresentativeInput(this.supplierSignatory);
      const notValidPerformerFirst = !this.checkRepresentativeInput(this.performerFirstSignatory);
      const notValidPerformerSecond = !this.checkRepresentativeInput(this.performerSecondSignatory);
      const other = this.isWaitingSubmit || this.balanceIndicators === undefined || !this.isValidIndicators;
      return notValidSupplier || notValidPerformerFirst || notValidPerformerSecond || other;
    },
  },
  watch: {
    currentDate: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.balanceIndicators = undefined;
          const date = dayjs(this.currentDate, 'MMMM | YYYY');
          this.balanceIndicators = await this.loadBalanceIndicators({ year: date.year(), month: date.month() + 1 });
          console.log(this.balanceIndicators);
        }
      },
    },
  },
  filters: {
    totalLossFilter(value) {
      value = value.split(' ').join('').replace(/^0+/, '');
      return formatByThousands(value);
    },
  },
  methods: {
    checkRepresentativeInput(signatory) {
      if (signatory.userId) {
        return signatory.reason.name === 'Устав' || signatory.powerOfAttorneyId;
      }
      return signatory.fullName?.name?.length > 0 &&
        signatory.fullName?.surname?.length > 0 &&
        signatory.role.name?.length > 0 &&
        signatory.reason.name?.length > 0 &&
        (signatory.reason.name === 'Устав' || (signatory.reason.date && signatory.reason.number));
    },
    validateByAllowedChars(str) {
      const allowedChars = ['-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const totalLossArr = str.split('');

      for (let i = 0; i < totalLossArr.length; i++) {
        const char = totalLossArr[i];

        if (!allowedChars.includes(char)) {
          return false;
        }

        if (char === '-' && i !== 0) {
          return false;
        }
      }

      return true;
    },
    updateTotalLoss(e) {
      const value = e.target.value.split(' ').join('').replace(/^0+/, '');

      if (value.includes(' ')) {
        return this.$refs.totalLoss.value = formatByThousands(this.totalLoss);
      }

      if (value === '' || value === '-') {
        return;
      }

      if (this.validateByAllowedChars(value)) {
        return this.totalLoss = value;
      }

      return this.$refs.totalLoss.value = formatByThousands(this.totalLoss);
    },
    updateSignatory(signatory, selected) {
      signatory.userId = selected.userId;
      signatory.role = {
        id: selected.role?.id,
        name: selected.role?.name,
      };
      signatory.reason = {
        name: selected.reason?.reasonDocument,
        number: selected.reason?.number,
        date: selected.reason?.date,
      };
      signatory.fullName = {
        name: selected.fullName?.name,
        surname: selected.fullName?.surname,
        patronymic: selected.fullName?.patronymic,
      };
      signatory.fullNameGenitive = {
        name: selected.fullNameGenitive?.name,
        surname: selected.fullNameGenitive?.surname,
        patronymic: selected.fullNameGenitive?.patronymic,
      };
      signatory.powerOfAttorneyId = selected.reason?.id;
      signatory.accountId = selected.accountId;
    },
    updateSupplier(selected) {
      this.updateSignatory(this.supplierSignatory, selected);
    },
    updatePerformerFirstSignatory(selected) {
      this.updateSignatory(this.performerFirstSignatory, selected);
    },
    updatePerformerSecondSignatory(selected) {
      this.updateSignatory(this.performerSecondSignatory, selected);
    },
    createRepresentativeInput(signatory) {
      return signatory.userId ? {
        digital: {
          userId: signatory.userId,
          roleId: signatory.role.id,
          powerOfAttorneyId: signatory.powerOfAttorneyId,
        },
      } : {
        simple: {
          person: {
            name: signatory.fullName.name,
            surname: signatory.fullName.surname,
            patronymic: signatory.fullName.patronymic,
          },
          positionName: signatory.role.name,
          reasonDocument: {
            reasonDocumentName: signatory.reason.name,
            date: signatory.reason.date ? getDateAsForceISOString(signatory.reason.date) : null,
            number: signatory.reason.number,
          },
        },
      };
    },
    goBack() {
      this.$router.push('/cabinet/balances');
    },
    async generateBalanceClick() {
      this.displayBalanceDialog = false;

      if (!this.balanceIndicators) {
        return;
      }

      if (!this.balanceIndicators.isUsefulVacationExists ||
        !this.balanceIndicators.isIntegralActsExists ||
        !this.balanceIndicators.isIntegralActsSigned) {

        let errorMessage = '';

        if (!this.balanceIndicators.isUsefulVacationExists) {
          errorMessage += 'Справки о полезном отпуске не загружены. Прошу загрузить полезный отпуск.<br>';
        }
        if (!this.balanceIndicators.isIntegralActsExists) {
          errorMessage += 'АПОПЭЭ и АСППУ акты не сформированы. Прошу сформировать акты.<br>';
        } else if (!this.balanceIndicators.isIntegralActsSigned) {
          errorMessage += 'АПОПЭЭ и АСППУ акты не подписаны. Прошу подписать акты.';
        }

        this.setNotification({ message: errorMessage, isHtml: true });
        return;
      }

      this.isWaitingSubmit = true;
      const date = dayjs(this.currentDate, 'MMMM | YYYY');

      let totalLossForSend = null;

      if (!this.indicators.isPreviousBalanceExists) {
        totalLossForSend = Number(this.totalLoss);
      }
      try {
        await this.generateBalance({
          transmissionContractId: this.balanceIndicators.creationByTransmissionContract.id,
          year: date.year(),
          month: date.month() + 1,
          totalLoss: totalLossForSend,
          supplierRepresentative: this.createRepresentativeInput(this.supplierSignatory),
          performerFirstRepresentative: this.createRepresentativeInput(this.performerFirstSignatory),
          performerSecondRepresentative: this.createRepresentativeInput(this.performerSecondSignatory),
        });
        this.goBack();
      } catch (e) {
        this.setNotification({ message: 'При формировании баланса' });
        this.isWaitingSubmit = false;
        return;
      }

      this.isWaitingSubmit = false;
    },
    ...mapActions('cabinet', ['loadBalances', 'loadBalanceIndicators']),
    ...mapActions('document', ['generateBalance']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.generate-balance {
  display: flex;
  justify-content: center;

  &__indicators {
    display: flex;
    justify-content: center;

    margin: 24px 0;

    div {
      margin-right: 8px;
    }
  }

  &__date-losses {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__signatories {
    display: flex;
    justify-content: center;

    &__col-wrapper {
      width: 600px;

      &__title {
        font-family: 'Roboto Condensed';
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        margin-top: 32px;
        color: #4C4E51;
      }
    }

  }

  &__date {
    display: flex;

    input {
      border: 1px solid #C1C4C7;
      border-radius: 24px 0 0 24px;
    }

    button {
      border-radius: 0 24px 24px 0;
    }
  }

  &__losses {
    text-align: center;
  }

  &__balance-input {
    width: 100%;
    height: 40px;
    border: 1px solid #dee2e6;
    padding: 0 15px;

    &:focus {
      outline: none;
      border: 1px solid #2f82df;
    }

    &:hover {
      border: 1px solid #2f82df;
    }
  }

  &__buttons-wrp {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
