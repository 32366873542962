<template>
  <div :class="$style.container">
    <label :for="inputId" :class="$style.label">{{ $props.label }}</label>
    <textarea
      :id="inputId"
      v-model="internalValue"
      :class="{
        [$style.field]: true,
        [$style[type]]: true,
        [$style.invalid]: isInvalid,
        [$style.disabled]: $props.disabled,
      }"
      :disabled="disabled"
      :placeholder="$props.placeholder"
      :role="role"
      cols="30"
      rows="3"
      autocomplete="off"
      @blur="handleValidate"
    ></textarea>
    <p v-if="isInvalid" :class="$style.errorText">
      {{ errorText }}
    </p>
  </div>
</template>

<script>
import inputMixin from 'atoms/common/inputs/inputMixin';

export default {
  name: 'CTextarea',
  mixins: [
    inputMixin,
  ],
};
</script>

<style lang="sass" module>
  .container
    display: flex
    flex-direction: column
    align-items: flex-start

    textarea
      resize: none

  .label
    +label-text
    margin-bottom: 8px
    color: #141412

  .field
    +input-text
    width: 100%
    font-family: 'Roboto Condensed'
    padding: 4px 16px
    color: #4F4F4F
    background: #FFF
    border: 1px solid #141412

    &::placeholder
      color: #E8E8E8

    &:focus
      outline: none

    &.date
      line-height: 28px

  .invalid
    color: red
    border-color: red

  .disabled
    color: gray
    border: 0
    pointer-events: none

  .errorText
    +small-text
    color: red
</style>
