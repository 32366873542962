<template>
  <add-directory-field-modal
      v-model="internalModalValue"
      :action="internalModalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createTag"
      @delete="deleteTag"
      @update="updateTag"
  />
</template>

<script>
import { createOdkTags, deleteOdkTags, updateOdkTags } from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import { AddDirectoryFieldModalActions, AddDirectoryFieldModalFieldType } from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { mapActions } from 'vuex';

export default {
  name: 'TagEdit',
  components: {
    AddDirectoryFieldModal,
  },
  props: {
    modalAction: {
      type: String,
      default: AddDirectoryFieldModalActions.EMPTY,
    },
    modalValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalModalAction: AddDirectoryFieldModalActions.EMPTY,
      internalModalValue: {},
      loading: false,
    };
  },
  computed: {
    fields() {
      return [{
        type: AddDirectoryFieldModalFieldType.TEXT,
        id: 'name',
        name: 'Тэг',
        required: true,
      }];
    },
  },
  watch: {
    modalAction(val) {
      this.internalModalAction = val;
    },
    modalValue(val) {
      this.internalModalValue = val;
    },
  },
  methods: {
    closeModal() {
      this.internalModalAction = AddDirectoryFieldModalActions.EMPTY;
      this.$emit('update:modalAction', AddDirectoryFieldModalActions.EMPTY);
      this.internalModalValue = {};
      this.$emit('update:modalValue', {});
    },
    deleteTag() {
      this.doAction(() => deleteOdkTags(this.modalValue.id));
    },
    createTag() {
      this.doAction(() => createOdkTags(this.modalValue.name));
    },
    updateTag() {
      this.doAction(() => updateOdkTags(this.modalValue.id, this.modalValue.name));
    },
    async doAction(action) {
      this.loading = true;
      try {
        const result = await action();
        this.$emit('onAction', result?.data);
        this.closeModal();
        this.$emit('loadData', this.modalValue);
      } catch (error) {
        console.log('catch');
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>
