const state = () => ({
  integralAct: {},
});

const getters = {
  integralActStatus: (state, getters, rootState) => {
    if (state.integralAct.sideSecond.accountId === rootState.user.account.id) {
      return state.integralAct.sideSecond.status;
    }
    if (state.integralAct.sideThird.accountId === rootState.user.account.id) {
      return state.integralAct.sideThird.status;
    }
  },
  integralActTitle: (state) => state.integralAct.title,
  integralActType: (state) => {
    console.log(state);
    return state.integralAct.namespace;
  },
};

const actions = {};

const mutations = {
  setIntegralAct: (state, integralAct) => {
    state.integralAct = integralAct;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
