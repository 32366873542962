<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

      <p :class="[$style.smallTitle, $style.mbPointInfo]">Сведения о точке:</p>

      <div :class="$style.tooltipWrp">
        <div :class="[$style.tooltip, $style.mb40]">
          <span :class="$style.requiredNote">
            * поля обязательные для заполнения
          </span>
        </div>
      </div>

      <v-select
        v-model="uploadReadings.UsagePointKind"
        :err="uploadReadings.UsagePointKind_error.error"
        :options="pointsKindList"
        title="Тип точки *"
        role="UsagePointKind_error"
        @input="chosePointKind"
      />
      <p v-if="uploadReadings.UsagePointKind_error.error" class="errorText">Поле обязательно для заполнения</p>

      <div :class="$style.mt30">
        <v-date-picker
          v-model="uploadReadings.Date"
          :err="uploadReadings.Date_error.error"
          title="Расчётный период *"
          format="YYYY-MM"
          role="Date_error"
          type="month"
          is-disabled-future-dates
        />
        <p v-if="uploadReadings.Date_error.error" class="errorText">Поле обязательно для заполнения</p>
      </div>

      <v-divider :class="$style.my24"/>

      <p :class="[$style.smallTitle, $style.my24]">Представитель организации:</p>

      <div :class="[$style.df, $style.w100]">
        <div :class="[$style.mr16, $style.w100]">
          <v-input
            v-model="uploadReadings.Utility.Representative.Name.FamilyName"
            :err="uploadReadings.Utility.Representative.Name.FamilyName_error.error"
            :disabled="true"
            title="Фамилия *"
            role="Utility_FamilyName"
          />
          <p v-if="uploadReadings.Utility.Representative.Name.FamilyName_error.error" class="errorText">Поле обязательно
            для заполнения</p>
        </div>

        <div :class="[$style.mr16, $style.w100]">
          <v-input
            v-model="uploadReadings.Utility.Representative.Name.FirstName"
            :err="uploadReadings.Utility.Representative.Name.FirstName_error.error"
            :disabled="true"
            title="Имя *"
            role="Utility_FirstName"
          />
          <p v-if="uploadReadings.Utility.Representative.Name.FirstName_error.error" class="errorText">Поле обязательно
            для заполнения</p>
        </div>

        <div :class="$style.w100">
          <v-input
            v-model="uploadReadings.Utility.Representative.Name.Patronymic"
            :disabled="true"
            title="Отчество"
          />
        </div>

      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Utility.Representative.Position"
          :err="uploadReadings.Utility.Representative.Position_error.error"
          title="Должность представителя *"
          :disabled="true"
          role="Utility_Position"
        />
        <p v-if="uploadReadings.Utility.Representative.Position_error.error" class="errorText">Поле обязательно для
          заполнения</p>
      </div>
    </div>

    <div :class="$style.content">
      <v-divider :class="$style.my24"/>

      <p :class="[$style.smallTitle, $style.mb24]">Внесение данных об отпуске и покупке электрической энергии (мощности)</p>

      <!-- templates block begin -->
      <div :class="$style.tooltipWrp">
        <div :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <span :class="$style.attachmentTitle">
              Шаблон Реестр показаний приборов учёта
            </span>
            <a
              :class="$style.fileAttachment"
              :download="attachment1NameIndications"
              href="/xlsx/upload-readings/attachment_1_indications.xlsx"
            >
              <img height="40" width="40" src="@/assets/icons/file/template.svg" />
              <span>
                Шаблон {{ attachment1NameIndications }}
              </span>
            </a>
          </div>
          <document-downloader
            :is-open-eye-show="false"
            :types="['XLSX']"
            :class="$style.documentDownloader"
            style="margin-top: 24px;"
            @download="xlsDownload('/xlsx/upload-readings/attachment_1_indications.xlsx', attachment1NameIndications)"
          />
        </div>
        <div :class="$style.tooltip">
          <input-tooltip
            :text="
              'Для загрузки приложений скачайте шаблоны документов в формате .XLSX (MS Exsel)'
            "
          />
        </div>
      </div>
      <v-divider :class="$style.my24" />
      <!-- templates block end -->

      <div :class="$style.mb24" style="display: flex; justify-content: space-between;">
        <p
          :class="$style.smallTitle"
          style="display:inline-block; margin: 0;"
          :style="{'color': uploadReadings.attachment1.length === 0 ? 'red': ''}"
        >
          Загрузка заполненных документов
        </p>

        <div v-if="uploadReadings.attachment1.length !== 0" :class="$style.fileLoadedLabel">
          <div :class="$style.circleWrp">
            <div :class="[$style.circle, $style.green]">
            </div>
          </div>
          Файлы загружены
        </div>
        <div v-else :class="$style.fileLoadedLabel">
          <div :class="$style.circleWrp">
            <div :class="[$style.circle, $style.red]">
            </div>
          </div>
          Файлы не загружены
        </div>

      </div>

      <p>Загрузите заполненные документы</p>

      <div :class="$style.tooltipWrp">
        <c-uploading
          v-model="filledAttachment1"
          :class="$style.filledDocuments"
          :error="uploadReadings.attachments_error"
          accept=".xlsx, .xls"
          accept-title="XLSX, XLS или перетащите его в эту область."
          role="filledAttachments"
        />
        <div :class="$style.tooltip">
          <input-tooltip
            :text="'<b>Важно!</b> Скачайте и заполните шаблоны в точном соответствии с названием полей шаблона. Не оставляйте поля пустыми'"
          />
        </div>
      </div>

      <!-- added attachment block begin -->
      <div v-if="uploadReadings.attachment1File" :class="$style.attachmentsList">
        <div :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <a :class="$style.fileAttachment" :download="uploadReadings.attachment1File.name"
              :href="fileUrl(uploadReadings.attachment1File)">
              <img height="40" src="@/assets/icons/file/filled_template.svg" width="40" />
              <span>{{ uploadReadings.attachment1File.name }}</span>
            </a>
          </div>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
            @click="deleteDoc1"
          >
            <img height="24" src="@/assets/icons/file/delete.svg" width="24" />
          </a>
        </div>

        <v-divider style="margin-top: 24px;" />
      </div>
      <!-- added attachment block end -->

      <dialog-template v-model="isWaitingSubmit" center>
        <p :class="$style.searchNote">Проводится анализ файлов. Подождите немного.</p>
        <horizontal-progress-bar v-if="isWaitingSubmit" />
      </dialog-template>

    </div>
  </div>
</template>

<script>
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files';
import { read, utils } from 'xlsx';
import { VDatePicker, VInput, VSelect, VDivider, DocumentDownloader, InputTooltip } from 'components';
import { normalizeDiacritics } from '@/utils/string/normalize';
import { mapGetters } from 'vuex';
import { checkField } from '@/utils/common';

const attachment1NameWithoutExtensionIndications = 'Реестр показаний приборов учёта';

export default {
  name: 'UploadReadings',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    DialogTemplate: () => import('templates/DialogTemplate'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
    InputTooltip,
    VDatePicker,
    VInput,
    VSelect,
    VDivider,
    DocumentDownloader,
  },
  props: {
    stepNumber: Object,
    uploadReadings: Object,
    disable: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.getFullnameAndPosition();
  },
  data() {
    return {
      isWaitingSubmit: false,
      filledAttachment1: [],
      attachment1NameWithoutExtensionIndications: attachment1NameWithoutExtensionIndications,
      attachment1NameIndications: `${attachment1NameWithoutExtensionIndications}.xlsx`,
    };
  },
  computed: {
    ...mapGetters('dataLists', ['pointsKindList']),
    ...mapGetters('user', ['getUser', 'getAuthorization']),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.uploadReadings.documentSubmissionErrors = [];
        this.isWaitingSubmit = true;
        const incorrectFiles = [];

        function getAttachmentHead(tableArray) {
          const title1 = tableArray.slice(2, 3)[0].slice(0, 17);
          const title2 = tableArray.slice(3, 4)[0].slice(0, 17);
          const title3 = tableArray.slice(4, 5)[0].slice(0, 17);

          const titlesHead = [];
          title1.forEach((it, index) => {
            let title = title1[index];

            if (title1[index].length === 0 && index > 0) {
              let index2 = index - 1;

              do {
                title = title1[index2];
                index2--;
              }
              while (index2 >= 0 && title.length === 0);
            }

            if (title2[index].length > 0) {
              title = `${title}. ${title2[index]}`;
            }

            if (title3[index].length > 0) {
              title = `${title}. ${title3[index]}`;
            }

            titlesHead.push(title);
          });

          return titlesHead;
        }

        try {
          val.forEach((file) => {
            const excel = read(file.code, {
              type: 'base64',
              cellText: false,
              cellDates: true,
            });

            const tableArray = utils.sheet_to_json(excel.Sheets[excel.SheetNames[0]], {
              header: 1,
              defval: '',
              raw: false,
              blankrows: false,
              dateNF: 'DD/MM/YYYY',
            });

            if (tableArray.length === 0) {
              return [];
            }

            if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment1NameWithoutExtensionIndications)) !== -1) {
              this.uploadReadings.attachment1 = tableArray.slice(6, tableArray.length);
              this.uploadReadings.attachment1File = file;
              this.uploadReadings.titlesHead = getAttachmentHead(tableArray);
            } else {
              incorrectFiles.push(file.name);
            }

          });

        } catch (e) {
          console.log('error XLSX.read', e);
        }

        if (incorrectFiles.length > 0) {
          this.vueShowNotification(
            '',
            `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Не соответсвуют шаблону:</b></p><p>${this.attachment1NameIndications}</p>`,
          );
        }
        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    getFullnameAndPosition() {
      this.uploadReadings.Utility.Representative.Name.FamilyName = this.getUser.fullName.surname;
      this.uploadReadings.Utility.Representative.Name.FirstName = this.getUser.fullName.name;
      this.uploadReadings.Utility.Representative.Name.Patronymic = checkField(this.getUser.fullName.patronymic);

      this.uploadReadings.Utility.Representative.Position = checkField(this.getAuthorization?.authorization?.role?.name);
    },
    chosePointKind(kind) {
      this.uploadReadings.UsagePointKind = kind;
    },
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deleteDoc1() {
      this.uploadReadings.attachment1 = [];
      this.uploadReadings.attachment1File = null;
    },
    xlsDownload(url, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.click();
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51
    margin: 25px 0

  .smallTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 18px
    white-space: nowrap
    color: #4C4E51

  .grid2Column
    +grid-2-column
    margin-bottom: 24px

  .gridFluid
    +grid-fluid
    margin-bottom: 24px
    margin-top: 30px

  select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    margin-bottom: 16px
    border-color: #C1C4C7

.attachment, .attachmentReport
  display: flex
  justify-content: space-between
  width: 100%

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757

.documentDownloader
  select
    margin-top: 0 !important
    margin-bottom: 0 !important

.fileLoadedLabel
  padding: 3px 11px
  display: flex
  border: 1px solid #C1C4C7
  border-radius: 16px
  .circleWrp
    display: flex
    align-self: center
    margin-right: 8px
  .circle
    width: 8px
    height: 8px
    border-radius: 24px
  .red
    background-color: #EB5757
  .green
    background-color: #13CE5E

.requiredNote
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #71757A;

.tooltipWrp
  position: relative
  display: flex

  @media (max-width: 1300px)
    flex-direction: column-reverse

.tooltip
  position: absolute
  top: 0
  right: -275px
  width: 250px

  @media (max-width: 1300px)
    position: static
    display: flex
    width: 100%
    margin-bottom: 10px
    margin-top: 10px

.mbPointInfo
  margin-bottom: 40px

  @media (max-width: 1300px)
    margin-bottom: 10px

.mt10
  margin-top: 10px

.mt30
  margin-top: 30px

.mb40
  margin-bottom: 40px

.mb16
  margin-bottom: 16px;

.mb24
  margin-bottom: 24px

.my24
  margin: 24px 0

.mr16
  margin-right: 16px

.w100
  width: 100%

.df
  display: flex
</style>
