<template>
  <div>
    <warehouses-autocomplete
      ref="warehouse"
      v-model="internalObject.warehouseId"
      :is-disabled="!!planConsignment"
      :items.sync="internalObject.warehouses"
      :search.sync="internalObject.warehouseName"
      is-required
      title="Склад"
    />

    <contractor-autocomplete
      ref="contractor"
      v-model="internalObject.contractorId"
      :is-disabled="!!planConsignment"
      :items.sync="internalObject.contractors"
      :search.sync="internalObject.contractorName"
      is-required
      title="Подрядчик"
    ></contractor-autocomplete>

    <events-autocomplete
        ref="event"
        v-model="internalObject.eventId"
        :find-ids="eventsFindIds"
        :is-disabled="internalObject.warehouseId == null && internalObject.contractorId == null || !!planConsignment"
        :items.sync="internalObject.eventItems"
        :search.sync="internalObject.eventName"
        title="Мероприятие"
        url-default-data="/event/contractor/{0}/warehouse/{1}/top20"
        url-search="/event/contractor/{0}/warehouse/{1}/find?name={2}"
    />

    <autocomplete-find-id
      ref="adreska"
      v-model="internalObject.adreskaId"
      :find-id="internalObject.eventId"
      :find-id1="internalObject.contractorId"
      :find-id2="internalObject.warehouseId"
      :is-disabled="internalObject.warehouseId == null || internalObject.contractorId == null || !!planConsignment"
      :items.sync="internalObject.adreskaItems"
      :search.sync="internalObject.adreskaAddress"
      is-required-find-id
      is-required-find-id1
      is-required-find-id2
      title="Программа оформления"
      url-default-data="/adreska/event/{0}/contractor/{1}/warehouse/{2}/top20"
      url-search="/adreska/event/{0}/contractor/{1}/warehouse/{2}/find?name={3}"
    ></autocomplete-find-id>

    <axp-autocomplete
        ref="axp"
        v-model="internalObject.axpId"
        :find-ids="axpFindIds"
        :is-disabled="internalObject.warehouseId == null|| !!planConsignment"
        :items.sync="internalObject.axpItems"
        :search.sync="internalObject.axpName"
        is-required
        title="АХП"
        url-default-data="/axp/warehouse/{0}/top20"
        url-search="/axp/warehouse/{0}/find?name={1}"
    />

    <axp-photos-carousel v-if="internalObject.axpId" :axp-id="internalObject.axpId"/>


    <text-field
      v-if="internalObject.eventId"
      ref="axpQuantity"
      v-model="internalObject.quantityPlanAxpForEvent"
      is-disabled
      title="Плановое общее количество комплектов АХП для мероприятия"
    ></text-field>

    <text-field
      ref="axpQuantity"
      v-model="internalObject.axpQuantity"
      is-only-numbers
      is-required
      title="К выдаче комплектов АХП"
    ></text-field>

    <v-row v-if="internalObject.axpId != null" class="pr-4 mb-2">
      <v-col>
        <p>
          Имеющееся на складе количество комплектов АХП:
          {{ internalObject.countAxpInStrock }}
        </p>
      </v-col>
      <v-col/>
      <v-col>
        <v-row>
          <v-spacer/>
          <v-btn @click="accountTotal">Рассчитать</v-btn>
          <v-spacer/>
          <v-btn @click="clearTotal">Очистить форму</v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="internalObject.formElements"
      :loading="isLoadingElements"
      class="elevation-1 mb-4 "
    >
      <template #no-data>
        <span>Отсутствуют данные</span>
      </template>
      <template #item.realCount="{ item }">
        <div class="d-flex align-center mt-6">
          <text-field
            ref="contract"
            v-model="item.realCount"
            :max="item.quantityMax"
            is-only-numbers
            is-required
            simple
          ></text-field>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import axios from 'axios';
import { mapGetters } from 'vuex';
import AxpPhotosCarousel from 'components/Processes/components/AxpPhotosCarousel/AxpPhotosCarousel.vue';
import AxpAutocomplete from 'components/Processes/components/AxpAutocomplete.vue';
import WarehousesAutocomplete from 'components/Processes/components/WarehousesAutocomplete.vue';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';

export default {
  name: 'Process2',
  components: {
    AxpPhotosCarousel,
    TextField,
    AutocompleteFindId,
    ContractorAutocomplete,
    AxpAutocomplete,
    WarehousesAutocomplete,
    EventsAutocomplete,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    planConsignment: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    isLoadingElements: false,
    internalObject: {},

    defaultHeaders: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      { text: 'Количество на складе', value: 'quantityInWarehouse', align: 'center' },
      {
        text: 'Нужно',
        value: 'needQuantity',
        align: 'center',
      },
      {
        text: 'Долги подрядчика',
        value: 'debt',
        align: 'center',
      },
      {
        text: 'Выдаваемое количество',
        value: 'realCount',
        align: 'center',
      },
    ],
  }),
  computed: {
    headers() {
      const headers = this.defaultHeaders;
      if (this.planConsignment != null) {
        headers.splice(3, 0, {
          text: 'Количество по плановой накладной',
          value: 'countByPlanConsignment',
          align: 'center',
        }, {
          text: 'Осталось по плановой накладной',
          value: 'leftCountByPlanConsignment',
          align: 'center',
        });
      }
      return headers;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.contractorId,
        },
        {
          queryIndex: 2,
          value: this.internalObject.warehouseId,
        },
      ];
    },
    axpFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.internalObject.warehouseId,
        },
      ];
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.internalObject = newVal;
        }
      },
    },
    async planConsignment(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        this.internalObject.warehouses = [{
          id: String(this.planConsignment.warehouse.id),
          name: this.planConsignment.warehouse.name,
        }];
        this.internalObject.warehouseName = this.planConsignment.warehouse.name;
        this.internalObject.warehouseId = String(this.planConsignment.warehouse.id);

        this.internalObject.contractors = [{
          id: this.planConsignment.contractorId,
          name: this.planConsignment.contractorName,
        }];
        this.internalObject.contractorName = this.planConsignment.contractorName;
        this.internalObject.contractorId = this.planConsignment.contractorId;

        if (this.planConsignment.event) {
          this.internalObject.eventItems = [{
            id: String(this.planConsignment.event.id),
            name: this.planConsignment.event.name,
          }];
          this.internalObject.eventName = this.planConsignment.event.name;
          this.internalObject.eventId = String(this.planConsignment.event.id);

          if (this.planConsignment.adreska) {
            this.internalObject.adreskaItems = [{
              id: this.planConsignment.adreska.id,
              name: this.planConsignment.adreska.name,
            }];
            this.internalObject.adreskaAddress = this.planConsignment.adreska.name;
            this.internalObject.adreskaId = this.planConsignment.adreska.id;
          }
        }

        this.internalObject.axpItems = [{
          id: String(this.planConsignment.axp.id),
          name: this.planConsignment.axp.name,
        }];
        this.internalObject.axpName = this.planConsignment.axp.name;
        this.internalObject.axpId = String(this.planConsignment.axp.id);

        this.internalObject.countAxp = this.planConsignment.countAxp;
      }
      await this.fetchElementsByPlanConsignment();
    },
    internalObject: {
      immediate: true,
      deep: true,
      handler() {
        this.internalObject.isDisabled = this.accountDisabled();
        this.$emit('input', this.internalObject);
      },
    },
    'internalObject.adreskaId': {
      deep: true,
      handler() {
        this.fetchQuantities();
      },
    },
    'internalObject.eventId': {
      deep: true,
      handler() {
        this.fetchQuantities();
      },
    },
    'internalObject.axpId': {
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal === newVal) return;
        this.internalObject.quantityPlanAxpForEvent = null;
        this.internalObject.debt = [];
        this.internalObject.formElements = [];
        this.internalObject.countAxpInStrock = null;
        if (newVal != null) {
          await this.fetchQuantities();
          const items = (await (await fetch(`${BASE_URL}/axp/warehouse/${this.internalObject.warehouseId}/find?name=${this.internalObject.axpName}`)).json());
          const item = items.filter((it) => it.id == newVal);
          console.log('item', newVal, items, item);
          this.internalObject.countAxpInStrock = item[0]?.count;
          await this.fetchElements(newVal);
        }
      },
    },
    'internalObject.axpQuantity': {
      deep: true,
      handler() {
        this.setQuantitiesForElements();
      },
    },
  },
  methods: {
    async fetchElementsByPlanConsignment() {
      if (!this.planConsignment) {
        this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
          ...it,
          countByPlanConsignment: undefined,
        }));
        return;
      }
      this.isLoadingElements = true;
      const response = await fetch(
        `${BASE_URL}/plan-consignment/${this.planConsignment.id}`,
      );
      const data = await response.json();
      const debt = {};
      const fact = {};
      data.elements.forEach((el) => {
        debt[el.elementId] = debt[el.elementId] ? debt[el.elementId] + el.countElement : el.countElement;
        fact[el.elementId] = fact[el.elementId] ? fact[el.elementId] + el.factCountElement : el.factCountElement;
      });
      this.internalObject.formElements = this.internalObject.formElements.map((it) => ({
        ...it,
        countByPlanConsignment: debt[it.elementId] ?? 0,
        leftCountByPlanConsignment: (debt[it.elementId] ?? 0) - (fact[it.elementId] ?? 0),
      }));
      this.isLoadingElements = false;
    },
    async fetchElements() {
      this.isLoadingElements = true;
      const link = this.internalObject.adreskaId ? `${BASE_URL
      }/warehouse/${this.internalObject.warehouseId}/axp/${this.internalObject.axpId}/elements?adreska=${this.internalObject.adreskaId}` : `${BASE_URL
      }/warehouse/${this.internalObject.warehouseId}/axp/${this.internalObject.axpId}/elements`;
      const response = await fetch(
        link,
      );
      const data = await response.json();
      this.internalObject.formElements = data.map((el) => ({
        elementId: el.id,
        elementName: el.name,
        unitName: el.unitName,
        quantityInWarehouse: el.stockQuantity,
        quantityMax: el.stockQuantity,
        elementQuantity: el.elementQuantity,
        realCount: '',
      }));
      this.isLoadingElements = false;
      this.setQuantitiesForElements();
      await this.fetchElementsByPlanConsignment();
    },
    accountTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount:
          String(
            Math.min(
              Math.max(((Number(el.needQuantity)) - Math.max((el.debt), 0)), 0),
              el.quantityInWarehouse,
              el.countByPlanConsignment ?? Number.MAX_VALUE,
            ),
          ),
      }));
    },
    clearTotal() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        realCount: '',
      }));
    },
    setQuantitiesForElements() {
      this.internalObject.formElements = this.internalObject.formElements.map((el) => ({
        ...el,
        needQuantity: el.elementQuantity * (this.internalObject.axpQuantity ?? 0),
        quantityForEvents: el.elementQuantity * (this.internalObject.quantityPlanAxpForEvent ?? 0),
        debt: this.internalObject.debt.find((debt) => debt.elementId === el.elementId)?.quantity ?? 0,
      }));
    },
    async fetchQuantities() {
      if (!this.internalObject.axpId) return;
      // fetch axp plan
      if (this.internalObject.adreskaId) {
        await axios
          .get(`${BASE_URL}/adreska/${this.internalObject.adreskaId}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
          .then((response) => {
            this.internalObject.quantityPlanAxpForEvent = response.data.quantityPlan;
          })
          .catch((error) => {
            console.error(error);
            this.internalObject.quantityPlanAxpForEvent = 0;
          });
      } else if (this.internalObject.eventId) {
        await axios
          .get(`${BASE_URL}/events/${this.internalObject.eventId}/planCount?axpId=${this.internalObject.axpId}`, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
          .then((response) => {
            this.internalObject.quantityPlanAxpForEvent = response.data.planCount;
          })
          .catch((error) => {
            console.error(error);
            this.internalObject.quantityPlanAxpForEvent = 0;
          });
      }
      // fetch debt
      if (this.internalObject.contractorId) {
        this.internalObject.debt = [];
        const response = await fetch(
          `${BASE_URL}/contractor/${this.internalObject.contractorId}/contractor-debt/quantity?axpId=${this.internalObject.axpId}`,
        );
        this.internalObject.debt = await response.json();
      }
      this.setQuantitiesForElements();
    },
    accountDisabled() {
      const isDisabledWarehouse = this.internalObject.warehouseId == null;
      const isDisabledContractor = this.internalObject.contractorId == null;
      const isDisableAxp = this.internalObject.axpId == null;
      const isDisabledAxpQuantity = !this.internalObject.axpQuantity?.length || isNaN(Number(this.internalObject.axpQuantity));
      const isDisableCountElements = this.internalObject.formElements.some((it) => Number(it.realCount) < 0 || Number(it.realCount) > Number(it.quantityMax)) ||
        this.internalObject.formElements.every((it) => !it.realCount?.length || isNaN(Number(it.realCount) || Number(it.realCount) === 0));
      return isDisabledWarehouse ||
        isDisabledContractor ||
        isDisableAxp ||
        isDisabledAxpQuantity ||
        isDisableCountElements;
    },
    resetValidation() {
      this.isLoadingElements = false;
      this.internalObject = {};
      this.axpQuantity = '';
      this.warehouseName = '';
      this.contractorName = '';
      this.axpName = '';
      this.adreskaAddress = '';
      this.eventName = '';
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
