import { apolloClient } from '@/api/graphql';
import { CONSUMERS, CONSUMERS_BY_FILTER_PAGED } from '@/api/graphql/queries';
import { DECREASE_ACCOUNT_BALANCE } from '@/api/graphql/mutations';

async function loadConsumers({ rootState, commit }) {
  try {
    const {
      data: { consumers },
    } = await apolloClient.query({
      query: CONSUMERS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('loadConsumers', consumers);
    return consumers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadConsumers::catch', error);
    throw error;
  }
}

async function consumersByFilterPaged({ rootState, commit }, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: CONSUMERS_BY_FILTER_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    return { ...data.consumersByFilterPaged };

  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('consumersByFilterPaged::catch', error);
  }
}

async function decreaseAccountBalanceByThousand({ rootState, commit }, payload) {
  try {
    await apolloClient.mutate({
      mutation: DECREASE_ACCOUNT_BALANCE,
      variables: { ...payload },
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
      error(error) {
        console.log('decreaseAccountBalanceByThousand errors', error.graphQLErrors);
      },
    });
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('decreaseAccountBalanceByThousand::catch', error);
    throw error;
  }
}

export default {
  loadConsumers,
  consumersByFilterPaged,
  decreaseAccountBalanceByThousand,
};
