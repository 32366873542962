<template>
  <inner-page :steps="[`Загрузите информацию о договоре энергоснабжения`]"
              home="Задачи"
              @back="$router.back()"
              @goHome="$router.back()"
  >
    <send-retailer-offer />
  </inner-page>
</template>

<script>

import SendRetailerOffer from 'views/private/ConnectionApplication/Task/actions/SendRetailerOffer';
import { InnerPage } from '@/components';

export default {
  name: 'SupplyContractInformationUpload',
  components: {
    SendRetailerOffer,
    InnerPage,
  },
};
</script>
