<template>
  <page title="Справочник полномочий">
    <data-tab-selector
      :data-types-list="dataTypesList"
      tab-style="table"
      @selectTab="selectTabHandler"
    />
    <cabinet-functional-blocks-table
      v-if="selectedTab === 'functionalBlocks'"
      :is-support="isSupport"
      @edit="onFunctionalBlockEdit"
      @functionalBlockModal="openFunctionalBlockModal"
      @permissionModal="openPermissionModal"
    />
    <cabinet-permissions-table
      v-if="selectedTab === 'permissions'"
      :is-support="isSupport"
      @edit="onPermissionEdit"
      @functionalBlockModal="openFunctionalBlockModal"
      @permissionModal="openPermissionModal"
    />
    <modals
      :functional-block-visible="createFunctionalBlockModalVisible"
      :init-functional-block="selectedFunctionalBlock" :init-permission="selectedPermission"
      :permissions-visible="createPermissionModalVisible"
      @modalClose="closeModals"
    />
  </page>
</template>

<script>

import { DataTabSelector, Page } from '@/components';

import CabinetFunctionalBlocksTable from './PermissionsRegistry/Tables/CabinetFunctionalBlocksTable.vue';
import CabinetPermissionsTable from './PermissionsRegistry/Tables/CabinetPermissionsTable.vue';
import { mapActions, mapGetters } from 'vuex';
import Modals from './PermissionsRegistry/Modals.vue';

export default {
  name: 'PermissionRegistry',
  components: {
    Modals,
    CabinetPermissionsTable,
    CabinetFunctionalBlocksTable,
    DataTabSelector,
    Page,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      updating: false,
      createFunctionalBlockModalVisible: false,
      createPermissionModalVisible: false,
      selectedFunctionalBlock: null,
      selectedPermission: null,
      dataTypesList: [
        {
          id: 1,
          title: 'Функциональные блоки',
          value: 'functionalBlocks',
          isActive: true,
        },
        {
          id: 2,
          title: 'Полномочия',
          value: 'permissions',
          isActive: false,
        },
      ],
      selectedTab: 'functionalBlocks',
      functionalBlocks: [],
      permissions: [],
    };
  },
  computed: {
    isSupport() {
      return this.getAccount.kind === 'PLATFORM';
    },
    ...mapGetters('user', ['getAccount']),
  },
  methods: {
    openFunctionalBlockModal() {
      this.createFunctionalBlockModalVisible = true;
      this.selectedPermission = null;
    },
    openPermissionModal() {
      this.createPermissionModalVisible = true;
      this.selectedFunctionalBlock = null;
    },
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    goToPreviousPage() {
      this.$router.go(-1);
    },
    async loadData() {
      await this.loadPermissionsRegistry();
    },
    closeModals() {
      this.createFunctionalBlockModalVisible = false;
      this.createPermissionModalVisible = false;
      this.selectedPermission = null;
      this.selectedFunctionalBlock = null;
    },
    onFunctionalBlockEdit(functionalBlock) {
      this.selectedFunctionalBlock = functionalBlock;
      this.openFunctionalBlockModal();
    },
    onPermissionEdit(permission) {
      this.selectedPermission = permission;
      this.openPermissionModal();
    },
    ...mapActions('permissionsRegistry', ['loadPermissionsRegistry']),
  },
};
</script>

<style lang="scss" scoped>
</style>

