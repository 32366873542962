<template>
  <div class="selection-points-network-sections">
    <v-spoiler
        summary="Отделения"
        :custom-style="{display: 'flex', justifyContent: 'space-between'}"
    >
      <template #button>
        <edit-mode-button title="Выбрать всё" select-all-border-gray :is-true="bordersSelectAll" :is-disabled="isLoading" @click="allDepartmentsSelected" />
      </template>
      <div v-for="department in departments" :key="department.id">
        <div class="selection-points-network-sections__checkbox-wrp">
          <input :checked="department.selected"
                 class="selection-points-network-sections__checkbox-wrp__checkbox"
                 type="checkbox"
                 :disabled="isLoading"
                 @change="departmentSelected(department.id)"
          />
          <label class="selection-points-network-sections__checkbox-wrp__label"
                 @click="departmentSelected(department.id)">{{ department.name }}</label>
        </div>
      </div>
    </v-spoiler>

    <v-divider />

    <v-spoiler
        v-if="networkSections?.length > 0"
        summary="Участки сети"
        :custom-style="{display: 'flex', justifyContent: 'space-between'}"
    >
      <template #button>
        <edit-mode-button title="Выбрать всё" select-all-border-gray :is-disabled="isLoading" :is-true="networkSectionsSelectAll" @click="allNetworkSectionSelected" />
      </template>
      <div v-for="border in networkSections" :key="border.id">
        <div class="selection-points-network-sections__checkbox-wrp">
          <input :checked="border.selected"
                 class="selection-points-network-sections__checkbox-wrp__checkbox"
                 type="checkbox"
                 :disabled="isLoading"
                 @change="networkSectionSelected(border.id)"
          />
          <label class="selection-points-network-sections__checkbox-wrp__label"
                 @click="networkSectionSelected(border.id)">{{ formatBoundaryName(border) }}</label>
          <div style="cursor: pointer" @click="redirectToBorder(border.connections[0].mainSubstation.number)">
            <arrow-right-circle
                fill="none"
                stroke="#2F82DF"
            />
          </div>
        </div>
      </div>
    </v-spoiler>

    <template v-if="networkSectionsIds?.length > 0">
      <div v-if="isDisplayAdditionKindFilter && networkSectionsIds.length > 0" class="selection-points-network-sections__points-kind">
        <div v-for="(kind) in additionKinds" :key="kind.value" class="selection-points-network-sections__points-kind__item"
             :class="pointAdditionKind === kind.value ? 'selection-points-network-sections__points-kind__item--active' : ''"
             @click="pointAdditionKind = kind.value">
          <span>{{ kind.label }}</span>
        </div>
      </div>
      <div class="selection-points-network-sections__borders-points">
        <v-table-new
            v-if="transformedUsagePoints.length > 0"
            v-model="transformedUsagePoints"
            :template="templatePoint"
            :pagination-show="false"
            :divider-show="false"
            :show-filters-block="false"
            :select-all-is-true="selectAllIsTrueUsagePoints[usagePointsSelectAllKey] ?? false"
            :is-display-column-hide-settings="false"
            spoiler-details-style="padding: 0 6px 6px 6px"
            @on-cell-click="usagePointsSelected"
            @select-all="allUsagePointsSelected"
        />
        <div v-if="usagePoints.length > 0" class="selection-points-network-sections__tooltips">
          <div class="selection-points-network-sections__tooltips__item">
            <p><b>Статусы урегулированности точек:</b></p>
            <div v-for="(status, key) in pointsStatus" :key="key" class="selection-points-network-sections__tooltips__status">
              <div :style="`background-color: ${status.color}`" class="selection-points-network-sections__tooltips__circle"/>
              <div>-</div>
              <div>{{ status.text }}</div>
            </div>
          </div>
          <div class="selection-points-network-sections__tooltips__item">
            <p><b>Ценовая категория:</b></p>
            <div v-for="(category, key) in priceCategories" :key="key" class="selection-points-network-sections__tooltips__status">
              <component :is="category.icon"/>
              <div>-</div>
              <div>{{ category.text }}</div>
            </div>
          </div>
          <div class="selection-points-network-sections__tooltips__item">
            <p><b>Параметры точки:</b></p>
            <div v-for="(option, key) in pointOptions" :key="key" class="selection-points-network-sections__tooltips__status">
              <component :is="option.icon"/>
              <div>-</div>
              <div>{{ option.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <v-circle-loader v-if="isLoading" style="position: fixed;" />
  </div>
</template>

<script>
import VTableNew from '@/components/VTableNew/VTable';
import EditModeButton from '@/components/EditModeButton/EditModeButton';
import VDivider from '@/components/VDivider/VDivider';
import VSpoiler from '@/components/VSpoiler/VSpoiler';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import formatting from 'lib/utils/formatting';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'SelectionPointsByNetworkSectionsFromDepartment',
  components: {
    VTableNew,
    EditModeButton,
    VDivider,
    VSpoiler,
    VCircleLoader,
    FirstCategory: () => import('atoms/icons/price-category/FirstCategory.vue'),
    SecondCategory: () => import('atoms/icons/price-category/SecondCategory.vue'),
    ThirdCategory: () => import('atoms/icons/price-category/ThirdCategory.vue'),
    FourthCategory: () => import('atoms/icons/price-category/FourthCategory.vue'),
    FifthCategory: () => import('atoms/icons/price-category/FifthCategory.vue'),
    SixthCategory: () => import('atoms/icons/price-category/SixthCategory.vue'),
    WithMeter: () => import('atoms/icons/point/WithMeter.vue'),
    WithoutMeter: () => import('atoms/icons/point/WithoutMeter.vue'),
    ArrowRightCircle: () => import('atoms/icons/arrows/ArrowRightCircle.vue'),
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: () => ({
          networkSectionsIds: [],
          boundariesIds: [],
          usagePointsIds: [],
      }),
    },
    isDisplayAdditionKindFilter: {
      type: Boolean,
      default: false,
    },
    groupingByDepartments: {
      type: Boolean,
      default: false,
    },
    secondCompanyId: {
      type: String,
      required: false,
    },
    companyInn: {
      type: String,
      required: false,
    },
    additionKinds: {
      type: Array,
      default: () => [
        {
          label: 'Точки приёма',
          value: 'RECEIVING_POINT',
        },
        {
          label: 'Точки передачи',
          value: 'TRANSFER_POINT',
        },
        {
          label: 'Точки поставки',
          value: 'DELIVERY_POINT',
        },
      ],
    },
  },
  created() {
    if (this.selected.networkSectionsIds) {
      this.networkSectionsIds = this.selected.networkSectionsIds;
    }
    if (this.selected.boundariesIds) {
      this.boundariesIds = this.selected.boundariesIds;
    }
    if (this.selected.usagePointsIds) {
      this.usagePointsIds = this.selected.usagePointsIds;
    }
    if (this.selected.departmentsIds) {
      this.departmentsIds = this.selected.departmentsIds;
    }

    this.pointAdditionKind = this.additionKinds[0].value;

    this.loadDepartments();

    const date = dayjs(this.date, 'MMMM | YYYY');
    this.period = { year: date.year(), month: date.month() + 1 };
  },
  data() {
    return {
      isLoading: false,
      bordersSelectAll: false,
      networkSectionsSelectAll: false,
      pointsStatus: [
        {
          text: 'Точки урегулированы',
          color: '#4CCB40',
        },
        {
          text: 'Точки в процессе урегулирования',
          color: '#3CB6EA',
        },
        {
          text: 'Точки не урегулированы',
          color: '#EB5757',
        },
        {
          text: 'Точки новые',
          color: '#DFE1E5',
        },
        {
          text: 'Точки имеют разногласия',
          color: '#9163BE',
        },
      ],
      priceCategories: [
        {
          icon: 'FirstCategory',
          text: 'Первая ценовая категория',
        },
        {
          icon: 'SecondCategory',
          text: 'Вторая ценовая категория',
        },
        {
          icon: 'ThirdCategory',
          text: 'Третья ценовая категория',
        },
        {
          icon: 'FourthCategory',
          text: 'Четвёртая ценовая категория',
        },
        {
          icon: 'FifthCategory',
          text: 'Пятая ценовая категория',
        },
        {
          icon: 'SixthCategory',
          text: 'Шестая ценовая категория',
        },
      ],
      pointOptions: [
        {
          icon: 'WithMeter',
          text: 'Напряжение по прибору учёта',
        },
        {
          icon: 'WithoutMeter',
          text: 'Напряжение без прибора учёта',
        },
      ],
      networkSections: [],
      boundaries: [],
      usagePoints: [],
      departments: [],
      networkSectionsIds: [],
      boundariesIds: [],
      usagePointsIds: [],
      departmentsIds: [],
      period: {},
      pointAdditionKind: 'RECEIVING_POINT',
      selectAllIsTrueUsagePoints: {},
    };
  },
  computed: {
    templatePoint() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'Список точек учёта:',
            key: 'pointValue',
            type: 'filterAndCheckbox',
            customCheck: true,
            isDisabled: this.isLoading,
            sort: {},
            filter: {},
            thStyle: {
              width: '50%',
              borderTop: borderStyle,
              borderRight: borderStyle,
              borderLeft: borderStyle,
              padding: '12px 16px',
            },
            tdStyle: {
              borderRight: borderStyle,
              borderLeft: borderStyle,
              padding: '2px 5px',
            },
          },
        ],
      };
    },
    templateBorder() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'Границы со смежными субъектами:',
            key: 'borderName',
            type: 'filterAndCheckbox',
            isDisabled: this.isLoading,
            sort: {},
            filter: {},
            thStyle: {
              width: '50%',
              borderTop: borderStyle,
              borderRight: borderStyle,
              borderLeft: borderStyle,
              padding: '12px 16px',
            },
            tdStyle: {
              borderLeft: borderStyle,
              borderRight: borderStyle,
              padding: '2px 10px',
            },
          },
        ],
      };
    },
    transformedUsagePoints() {
      return this.usagePoints
          ?.filter((point) => point.additionKind === this.pointAdditionKind)
          .map(this.transformed);
    },
    /* transformedUsagePointsGroupKind() {
      const usagePoints = this.usagePoints?.map(this.transformed);

      const result = {};

      this.additionKinds.forEach((kind) => {
        result[kind.value] = usagePoints.filter((point) => point.additionKind === kind.value);
      });

      return result;
    },*/
    transformedBorders() {
      return this.boundaries.map((it) => ({
        label: it?.borderName,
        ...it,
      }));
    },
    usagePointsSelectAllKey() {
      return `${this.pointAdditionKind}${this.secondCompanyId}`;
    },
  },
  watch: {
    date: {
      async handler(val) {
        const date = dayjs(val, 'MMMM | YYYY');
        this.period = { year: date.year(), month: date.month() + 1 };
        await this.loadBoundaries();
      },
    },
    secondCompanyId: {
      async handler() {
        this.networkSectionsIds = [];
        this.selectAllIsTrueUsagePoints[this.usagePointsSelectAllKey] = false;
        await this.loadBoundaries();
      },
    },
    async pointAdditionKind() {
      this.usagePoints = [];
      this.boundariesIds = [];
      this.usagePointsIds = [];
      await this.updateUsagePointsList();
    },
    async networkSectionsIds() {
      this.updateSelected();
      await this.updateUsagePointsList();

      this.selectAllIsTrueUsagePoints[this.usagePointsSelectAllKey] = false;
    },
    boundariesIds() {
      this.updateSelected();
    },
    usagePointsIds() {
      this.updateSelected();
    },
    async departmentsIds() {
      this.updateSelected();
      await this.loadBoundaries();
    },
  },
  methods: {
    async loadBoundaries() {
      if (this.departmentsIds.length === 0) {
        this.networkSections = [];
        this.networkSectionsIds = [];
        return;
      }

      this.isLoading = true;

      const filter = {
        availableForAccounting: this.period,
        departmentIds: this.departmentsIds,
      };

      if (this.companyInn) {
        filter.companyInn = this.companyInn;
      }

      try {
        const borders = await this.loadBalanceBoundaries({
          filter: {
            ...filter,
          },
        });

        this.networkSections = borders
            .filter((boundary) => !this.secondCompanyId || boundary.secondCompany.id === this.secondCompanyId)
            .map((boundary) => ({
              ...boundary,
              selected: this.networkSectionsIds ? this.networkSectionsIds.includes(boundary.id) : false,
            }));

        this.$emit('bordersLoaded', borders);

        if (this.networkSections.length > 0 || this.networkSectionsIds.length > 0) {
          this.networkSectionsIds = this.networkSectionsIds.filter((selectedId) => this.networkSections.some((point) => point.id === selectedId));
        }

        await this.updateUsagePointsList();

        this.bordersSelectAll = false;
      } catch (error) {
        const errorData = HTTPError.network(error);
        console.log('loadBalanceBoundaries', error);
        this.setNotification({ message: errorData.message });
      } finally {
        this.isLoading = false;
      }
    },
    async loadDepartments() {
      this.isLoading = true;

      try {
        const departments = await this.mesDepartments();

        this.departments = departments
            ?.map((department) => ({
              ...department,
              selected: this.departmentsIds ? this.departmentsIds.includes(department.id) : false,
            }));
      } catch (error) {
        console.log('mesDepartments', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.isLoading = false;
      }
    },
    networkSectionSelected(id) {
      if (this.isLoading) {
        return;
      }

      const networkSection = this.networkSections.find((item) => item.id === id);

      if (!networkSection) {
        return;
      }

      networkSection.selected = !networkSection.selected;


      if (networkSection.selected) {
        this.networkSectionsIds.push(id);
      } else {
        this.networkSectionsIds.splice(this.networkSectionsIds.indexOf(id), 1);
        this.networkSectionsSelectAll = false;
      }

      this.updateUsagePointsList();
    },
    allNetworkSectionSelected(selected) {
      this.bordersSelectAll = selected;

      this.networkSections = this.networkSections.map((item) => ({
        ...item,
        selected: selected,
      }));

      if (selected) {
        this.networkSectionsIds = this.networkSections.map((item) => item.id);
      } else {
        this.networkSectionsIds = [];
      }

      this.updateUsagePointsList();
    },
    departmentSelected(id) {
      if (this.isLoading) {
        return;
      }

      const department = this.departments.find((item) => item.id === id);

      if (!department) {
        return;
      }

      department.selected = !department.selected;

      if (department.selected) {
        this.departmentsIds = [...this.departmentsIds, id];
      } else {
        this.departmentsIds.splice(this.departmentsIds.indexOf(id), 1);
        this.bordersSelectAll = false;
      }
    },
    allDepartmentsSelected(selected) {
      this.departments = this.departments.map((item) => ({
        ...item,
        selected: selected,
      }));

      if (selected) {
        this.departmentsIds = this.departments.map((item) => item.id);
      } else {
        this.departmentsIds = [];
      }
    },
    bordersSelected(cell) {
      const border = this.boundaries.find((item) => item.id === cell.row.id);

      if (!border) {
        return;
      }

      border.selected = !border.selected;

      if (border.selected) {
        this.boundariesIds.push(cell.row.id);
      } else {
        this.boundariesIds.splice(this.boundariesIds.indexOf(cell.row.id), 1);
      }

      this.updateUsagePointsList();
    },
    allBordersSelected(selected) {
      this.boundaries = this.boundaries.map((item) => ({
        ...item,
        selected: selected,
      }));

      if (selected) {
        this.boundariesIds = this.boundaries.map((item) => item.id);
      } else {
        this.boundariesIds = [];
      }

      this.updateUsagePointsList();
    },
    usagePointsSelected(cell) {
      const point = this.usagePoints.find((item) => item.id === cell.row.point.id);

      if (!point) {
        return;
      }

      point.selected = !point.selected;

      if (point.selected) {
        this.usagePointsIds.push(cell.row.point.id);
      } else {
        this.usagePointsIds.splice(this.usagePoints.indexOf(cell.row.point.id), 1);
        this.selectAllIsTrueUsagePoints[this.usagePointsSelectAllKey] = false;
      }
    },
    allUsagePointsSelected(selected, kind) {
      this.selectAllIsTrueUsagePoints[this.usagePointsSelectAllKey] = selected;

      this.usagePoints
          .filter((item) => !kind || item.additionKind === this.pointAdditionKind)
          .forEach((item) => item.selected = selected);

      this.usagePointsIds = this.usagePoints.filter((item) => item.selected).map((item) => item.id);
    },
    redirectToBorder(mainSubstationNumber) {
      this.$router.push({
        name: 'cabinet.boundaries-balance-sheet.chart',
        params: {
          number: mainSubstationNumber,
        },
      });
    },
    async updateUsagePointsList() {
      this.isLoading = true;

      let selectedId = [];

      selectedId = this.networkSections
          .filter((section) => section.selected)
          .map((section) => section.id);

      // const departmentIds = this.groupingByDepartments ? { departmentIds: this.departmentsIds } : {};

      try {
        const usagePoints = await this.loadUsagePointsByBalanceBoundaryIds({ balanceBoundaryIds: selectedId, departmentIds: this.departmentsIds });
        this.usagePoints = usagePoints.map((it) => ({
          ...it,
          selected: this.usagePointsIds && this.usagePointsIds.includes(it.id),
        }));

        if (this.usagePoints.length > 0 || this.usagePointsIds.length > 0) {
          this.usagePointsIds = this.usagePointsIds.filter((selectedId) => this.usagePoints.some((point) => point.id === selectedId));
        }
      } catch (error) {
        console.log('error loadUsagePointsByBalanceBoundaryIds', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.isLoading = false;
      }
    },
    formatBoundaryName(border) {
      return formatting.formatBoundaryName(border);
    },
    updateSelected() {
      this.$emit('updateSelected', {
        networkSectionsIds: this.networkSectionsIds,
        boundariesIds: this.boundariesIds,
        usagePointsIds: this.usagePointsIds,
        departmentsIds: this.departmentsIds,
      });
    },
    transformed(it) {
      const TP = it?.pointInfo?.transformationSubstation ? `; ${it?.pointInfo?.transformationSubstation}` : '';
      const RP = it?.pointInfo?.distributionSubstation ? `; ${it?.pointInfo?.distributionSubstation}` : '';
      const serialNumber = it?.meter?.serialNumber ? `; ПУ № ${it?.meter?.serialNumber}` : '';
      const consumerName = it?.consumer?.consumerName ? ` (${it?.consumer?.consumerName})` : '';

      return {
        pointValue: `${it?.pointInfo?.mainSubstation}${TP}${RP}${serialNumber}${consumerName}`,
        point: {
          id: it.id,
          mainSubstation: it?.pointInfo?.mainSubstation,
          pointStatus: it.pointStatus,
          tariffCategory: it.tariffCategory,
          selected: it.selected,
        },
        additionKind: it.additionKind,
      };
    },
    ...mapActions('border', ['loadBalanceBoundaries', 'departmentsByBalanceBoundaryIds']),
    ...mapActions('user', ['setNotification']),
    ...mapActions('planningBalance', ['loadUsagePointsByBalanceBoundaryIds']),
    ...mapActions('dataLists', ['mesDepartments']),
  },
};

</script>

<style lang="scss" scoped>
.selection-points-network-sections {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #4C4E51;
  }

  &__checkbox-wrp {
    display: flex;
    gap: 16px;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    background: #F5F6F6;

    &__checkbox {
      min-width: 16px;
      min-height: 16px;
      cursor: pointer
    }

    &__label {
      padding: 4px 8px;
      cursor: pointer;
      border: 1px solid #E9EBED;
      background: #FFFFFF;
      width: 100%;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__borders-points {
    position: relative;
    display: flex;
    .table {
      flex: 1;
    }
  }

  &__tooltips {
    position: absolute;
    top: 0;
    right: -316px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 50px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: #F5F6F6;
      padding: 24px;
      border-radius: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__status {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &__circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 24px;
      margin-left: 8px;
    }
  }

  &__points-kind {
    display: flex;
    margin-bottom: -24px;

    &__item {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      padding: 0 24px 16px 24px;
      border-bottom: 2px solid #FFFFFF;
      cursor: pointer;
      color: #C1C4C7;

      &--active {
        border-bottom: 2px solid #2F82DF;
        cursor: default;
        color: #4C4E51;
      }
    }
  }
}
</style>
