<template>
  <v-form class="page-form" @submit="onSubmit">
    <div v-if="admissionCertificateId" style="display: flex; flex-direction: column; align-items: center; margin-top: 24px">
      <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
      <documents-list :disable-date="true" :disable-title="true" :documents="documents"/>
      <horizontal-progress-bar v-if="isWaitingSubmit"/>
      <v-button :disabled="isWaitingSubmit" type="submit">Подписать</v-button>
    </div>
  </v-form>
</template>

<script>
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions } from 'vuex';
import HTTPError from 'lib/utils/errors';
import VForm from 'components/VForm/VForm.vue';
import VButton from 'components/VButton/VButton.vue';
import DocumentsList from '@/components/DocumentsList/DocumentsList.vue';

export default {
  name: 'SendAdmissionCertificate',
  components: {
    DocumentsList,
    VButton,
    VForm,
    HorizontalProgressBar,
  },
  props: {
    admissionCertificateId: String,
  },
  created() {
    console.log(this.admissionCertificateId);
  },
  data() {
    return {
      submitError: '',
      isWaitingSubmit: false,
    };
  },
  computed: {
    documents() {
      return [{
        documentId: this.admissionCertificateId,
        name: 'Акт допуска прибора учёта в эксплуатацию',
      }];
    },
  },
  watch: {
  },
  methods: {
    async onSubmit() {
      this.submitError = '';
      this.isWaitingSubmit = true;
      try {
        const doc = await this.loadDocument(this.admissionCertificateId);

        await this.documentUpload({ xml: doc.xml, existingSignature: doc.signature, isCap: true });

        setTimeout(() => {
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          // TODO возможно, нужно ждать изменения состояния
          this.$nextTick(() => {
            this.vueShowNotification(
              this.isMeterSwap ? 'Акт замены электрического счётчика отправлен' : 'АДПУ ОТПРАВЛЕН',
              '',
            );
          });
          this.onClose();
        }, 2000);
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaitingSubmit = false;
      }
    },
    onClose() {
      this.applicationStatus = undefined;
      this.$emit('close');
    },
    ...mapActions('cabinet', ['setWorkflows']),
    ...mapActions('document', ['documentUpload', 'loadDocument']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";
@import "@/assets/scss/commonCardStyles.scss";

.page-form {
  display: flex;
  flex-direction: column;
  align-self: center;
}
</style>
