<template>
  <div class="cabinet-consumers-card">
    <div class="cabinet-consumers-card__navigation">
      <button
        class="cabinet-consumers-card__navigation-btn-back"
        @click="goToPreviousPage"
      >
        Назад
      </button>
      <div class="cabinet-consumers-card__navigation-bar">
        <navigation-bar
          :current-step="0"
          :steps="steps"
          home="Потребители"
          style="margin: 0;"
          @open="openStep"
        />
      </div>
    </div>

    <div class="cabinet-consumers-card__divider">
      <v-divider/>
    </div>

    <data-tab-selector
      :data-types-list="dataTypeList"
      class="cabinet-consumers-card__select"
      @selectTab="selectTabHandler"
    />

    <div class="cabinet-consumers-card__data">
      <div v-if="selectedTab === 'personal'">
        <personal-data-form/>
      </div>
      <div v-if="selectedTab==='company'">
        <company-details-form/>
      </div>
      <div v-if="selectedTab === 'contract'">
        <consumer-contract-data/>
      </div>
    </div>

  </div>
</template>

<script>
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';
import VDivider from 'components/VDivider/VDivider';

import { DataTabSelector } from '@/components';
import PersonalDataForm from './PersonalDataForm';
import ConsumerContractData from './ConsumerContractData';
import CompanyDetailsForm from './CompanyDetailsForm';

export default {
  components: {
    CompanyDetailsForm,
    NavigationBar,
    DataTabSelector,
    VDivider,
    PersonalDataForm,
    ConsumerContractData,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      dataTypeList: [],
      steps: [
        {
          stepNumber: {
            value: 1,
            from: 1,
            title: () => 'Карточка потребителя',
          },
        },
      ],
      selectedTab: null,
    };
  },
  computed: {
    isIndividual() {
      return this.$route.params.inn.length === 12 || this.$route.params.inn.length === 11;
    },
  },
  methods: {
    loadData() {
      this.dataTypeList = this.isIndividual ? [
        {
          id: 1,
          title: 'Личные данные',
          value: 'personal',
          isActive: true,
        },
        {
          id: 2,
          title: 'Данные по договору энергоснабжения',
          value: 'contract',
          isActive: false,
        },
      ] : [
        {
          id: 1,
          title: 'Данные компании',
          value: 'company',
          isActive: true,
        },
        {
          id: 2,
          title: 'Данные по договору энергоснабжения',
          value: 'contract',
          isActive: false,
        },
      ];
      this.selectedTab = this.isIndividual ? 'personal' : 'company';
    },
    selectTabHandler(selectedTab) {
      this.selectedTab = selectedTab.value;
    },
    goToPreviousPage() {
      this.$router.go(-1);
    },
    openStep(index) {
      if (index === -1) {
        this.goToPreviousPage();

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-consumers-card {
  font-family: 'Roboto';

  &__navigation {
    display: flex;

    &-btn-back {
      width: 86px;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #677379;

      background-image: url('~icons/navigation/arrow_left.svg');
      background-repeat: no-repeat;
      background-position: center left 4px;
      background-color: #FFFFFF;
      border: 1px solid #C1C4C7;
      border-radius: 24px;

      &:hover {
        background-color: #F5F6F6;
      }
    }

    &-bar {
      margin-left: 19px;
      width: 300px;
    }
  }

  &__divider {
    margin-top: 8px;
  }

  &__select {
    margin-top: 27px;
    color: #000000;

    &:disabled {
      color: #000000;
    }
  }


  &__data {
    margin: 56px 0 0 50px;
    max-width: 1700px;

    @media (max-width: 1000px) {
      margin: 56px 0 0 0;
      width: 100%;
    }
  }
}
</style>
