<template>
  <v-spoiler summary="Прибор учёта">
    <div class="meter-properties">
      <v-input
        v-model="internalValue.type"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Марка прибора учёта *"
      />

      <v-input
        v-model="internalValue.serialNumber"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Номер прибора учёта *"
      />
    </div>

    <div class="meter-properties">
      <v-input
        v-model="internalValue.accuracyClass"
        :disabled="!isEditing"
        clearable
        class="meter-properties__item"
        title="Класс точности *"
      />

      <v-input
        value="кВт*ч"
        :disabled="!isEditing"
        clearable
        class="meter-properties__item"
        title="Единицы измерения"
      />
    </div>

    <div class="meter-properties">
      <v-input
        v-model="internalValue.ratedCurrent"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Номинальный ток, A *"
      />

      <v-input
        v-model="internalValue.nominalVoltage"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Номинальное напряжение, B *"
      />
    </div>

    <div class="meter-properties">
      <v-input
        v-model="internalValue.digitCapacity"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Разрядность до запятой *"
      />

      <v-input
        v-model="internalValue.fractionDigits"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Разрядность после запятой *"
      />
    </div>

    <div class="meter-properties">
      <v-input
        v-model="internalValue.productionYear"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Год выпуска *"
      />

      <v-input
        v-model="internalValue.calibrationInterval"
        :disabled="!isEditing"
        class="meter-properties__item"
        clearable
        title="Межповерочный интервал *"
      />
    </div>

    <div class="meter-properties">
      <v-date-picker
        v-model="internalValue.calibrationDate"
        :disabled="!isEditing"
        class="meter-properties__item meter-properties__item--date"
        clearable
        title="Дата поверки *"
      />

      <v-date-picker
        v-model="internalValue.nextCalibrationDate"
        :disabled="!isEditing"
        class="meter-properties__item meter-properties__item--date"
        clearable
        title=" Дата следующей поверки"
      />
    </div>
  </v-spoiler>
</template>

<script>
import { VDatePicker, VInput, VSpoiler } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'MeterProperties',
  components: {
    VInput,
    VSpoiler,
    VDatePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        type: '',
        serialNumber: '',
        accuracyClass: '',
        unit: '',
        ratedCurrent: '',
        ratedVoltage: '',
        digitCapacity: '',
        fractionDigits: '',
        productionYear: '',
        calibrationInterval: '',
        calibrationDate: '',
        nextCalibrationDate: '',

        currentTransformer: {
          type: '',
          serialNumber: '',
        },

        potentialTransformer: {
          type: '',
          serialNumber: '',
        },
      },
      internalValue: {},
      options: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {

        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
};

</script>

<style lang="scss" scoped>
.meter-properties {
  display: flex;
  gap: 16px;

  &__item {
    width: 330px;
    margin-top: 24px;

    &--select {
      margin-top: 48px;
    }

    &--100 {
      width: 100%;
      margin-top: 0;
    }

    &--date {
      margin-top: 48px;
    }
  }

  img {
    margin-top: 24px;
    cursor: pointer;
  }

  img.follow-arrow {
    margin-top: 48px;
  }
}
</style>
