import axios from 'axios';
import { VUE_APP_DADATA_PATH, VUE_APP_DADATA_TOKEN } from '@/constants/env';

export async function findAddress(name) {
  const { data } = await axios.post(
    `${VUE_APP_DADATA_PATH}/rs/suggest/address`,
    {
      query: name,
      locations_boost: [
        {
          kladr_id: '77',
        },
      ],
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${VUE_APP_DADATA_TOKEN}`,
      },
    },
  );
  return data;
}

export async function findBank(name) {
  const { data } = await axios.post(
    `${VUE_APP_DADATA_PATH}/rs/suggest/bank`,
    { query: name },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${VUE_APP_DADATA_TOKEN}`,
      },
    },
  );
  return data;
}
