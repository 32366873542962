export function isFullyVisibleInViewport(elem) {
  if (!elem) {
    return null; // returns "null" when element is not provided
  }

  const elementTop = elem.offsetTop;
  const elementBottom = elementTop + elem.offsetHeight;
  const viewportTop = window.scrollY;
  const viewportBottom = viewportTop + window.innerHeight;

  return elementTop >= viewportTop && elementBottom <= viewportBottom;
}

export function isPartlyInViewport(elem) {
  if (!elem) {
    return null; // returns "null" when element is not provided
  }

  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = elem.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + elem.clientHeight,
  };

  return (
    bounds.bottom >= viewport.top &&
    bounds.bottom <= viewport.bottom
  ) || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
}

export function scrollIntoView(elem) {
  if (!elem) {
    return;
  }

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('Trident/');

  if (msie > -1) {
    elem.scrollIntoView(false);
  } else {
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
