<template>
  <div class="container">
    <div class="technological-connection-act-header">
      <navigation-bar
        class="navigator"
        :current-step="currentStep"
        :steps="steps"
        is-display-back
        home="Реестр приборов учёта"
        @open="openStep"
      />
    </div>
    <v-divider class="divider"/>
    <send-admission-certificate hide-status is-meter-swap @close="openStep(-1)"/>
  </div>
</template>

<script>
import NavigationBar from 'components/NavigationBar/NavigationBar';
import VDivider from 'components/VDivider/VDivider';
import SendAdmissionCertificate from 'views/private/ConnectionApplication/Task/actions/SendAdmissionCertificate';
export default {
  components: {
    NavigationBar,
    VDivider,
    SendAdmissionCertificate,
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          stepNumber: {
            value: 1,
            from: 1,
            title: () => 'Сформировать акт замены прибора учёта',
          },
        },
      ],
    };
  },
  methods: {
    openStep(value) {
      if (value === -1) {
        this.$router.push('/cabinet/meter-input-transit');
        return;
      }
      this.$router.go(-1);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 8px 32px 56px 0;
  padding-left: 0;
  .divider {
    margin-bottom: 18px;
  }
  .technological-connection-act-header {
    display: flex;
    margin: 0 0 8px 12px;
    .navigator {
      margin: unset;
    }
  }

}
</style>
