<template>
  <page title="Доверенности">
    <template #default>
      <attorney />
    </template>
  </page>
</template>

<script>
import { Page } from '@/components';
import Attorney from 'views/private/AttorneyPage/Attorney';

export default {
  name: 'AttorneyPage',
  components: { Attorney, Page },
};
</script>
