<template>
  <v-app class="vuetify-styles" data-app style="width: 98%; height: 100%">
    <v-card-title>
      <div class="text-h5">Редактирование накладной</div>
    </v-card-title>
    <v-row class="form1" justify="center">
      <v-circle-loader v-if="isLoading" class="loading"/>
      <v-col v-else lg="8" md="10" sm="12">

        <text-field
            v-model="consignment.uniqueNumber"
            :is-disabled="true"
            title="Номер"
        ></text-field>

        <text-field
            v-model="consignment.typeLabel"
            :is-disabled="true"
            title="Тип"
        ></text-field>

        <custom-date-picker
            v-model="consignment.date"
            disabled
            label="Дата накладной"
        />

        <text-field
            v-model="consignment.performerFullName"
            :is-disabled="true"
            title="Сотрудник совершивший операцию"
        ></text-field>

        <text-field
            v-model="consignment.fullName"
            :is-disabled="true"
            title="Сотрудник занесший информацию"
        ></text-field>

        <text-field
            v-model="consignment.warehouseName"
            :is-disabled="true"
            title="Склад"
        ></text-field>

        <text-field
            v-model="consignment.axpName"
            :is-disabled="true"
            title="АХП"
        ></text-field>

        <text-field
            v-model="consignment.contractorName"
            :is-disabled="true"
            title="Подрядчик"
        ></text-field>

        <text-field
            v-if="consignment.contractorEmployeeName"
            v-model="consignment.contractorEmployeeName"
            :is-disabled="true"
            title="Сотрудник подрядчика"
        ></text-field>

        <div ref="form" class="v-application" style="display: block">
          <div v-if="isDisplayContract">
            <autocomplete-find-id ref="contract" v-model="consignment.contractId" :find-id="consignment.contractorId"
                                  :items.sync="contracts"
                                  is-required-find-id
                                  title="Договор"
                                  url-default-data="/contract-debt/contractor/{0}/top20"
                                  url-search="/contract-debt/contractor/{0}/find?name={1}"></autocomplete-find-id>
          </div>
          <div>
            <events-autocomplete
                v-if="isDisplayEvent"
                ref="event"
                v-model="consignment.eventId"
                :is-disabled="consignment.contractorId == null"
                :items.sync="eventItems"
                :search.sync="eventName"
                title="Мероприятие"
                url-default-data="/events/contractor/{0}/top20"
                url-search="/events/contractor/{0}/find?name={1}"
                :find-ids="eventsFindIds"
            />
            <autocomplete-find-id
                v-if="isDisplayAdreska"
                ref="adreska"
                v-model="consignment.adreskaId"
                :find-id="consignment.contractorId"
                :find-id1="consignment.eventId"
                :find-id2="consignment.axpId"
                :is-disabled="consignment.contractorId == null || consignment.eventId == null"
                :items.sync="adreskaItems"
                :search.sync="adreskaAddress"
                is-required-find-id
                is-required-find-id1
                is-required-find-id2
                title="Программа оформления"
                url-default-data="/adreska/contractor/{0}/event/{1}/axp/{2}/top20"
                url-search="/adreska/contractor/{0}/event/{1}/axp/{2}/find?name={3}"
            ></autocomplete-find-id>

            <div>
              <v-data-table
                  :headers="headersTable"
                  :items="consignment.elements"
                  class="elevation-1 mb-4"
                  style="width: 100%"
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.factCountElement="{ item }">
                  <div class="d-flex align-center mt-6">
                    <text-field
                        ref="realCount"
                        v-model="item.factCountElement"
                        :max="consignment.contractId?item.countByContract:null"
                        is-only-numbers
                        simple
                    ></text-field>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
          <v-card-actions>
            <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onCancel"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                rounded
                @click="onSave"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Сохранение данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onCancel"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="consignmentOpened" :consignment="newConsignment"/>
    </v-row>
  </v-app>
</template>

<script>
import AutocompleteFindId from 'components/Processes/components/AutocompleteFindId.vue';
import VCircleLoader from '@/components/VCircleLoader/VCircleLoader';
import TextField from 'components/Processes/components/TextField.vue';
import { BASE_URL } from '@/constants/api';
import { mapActions, mapGetters } from 'vuex';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import CustomDatePicker from 'components/Processes/components/CustomDatePicker.vue';
import {
  consignmentTypesMap,
  consignmentUrlByType,
} from 'components/Processes/list';
import axios from 'axios';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import { fetchConsignment } from './consignment';

export default {
  name: 'ConsignmentEdit',
  components: {
    TextField,
    AutocompleteFindId,
    EventsAutocomplete,
    VCircleLoader,
    CustomDatePicker,
    Consignment,
  },
  created() {
    this.consignmentId = this.$route.params?.consignmentId;
    this.consignmentType = this.$route.params?.type;

    if (!this.consignmentId || !this.consignmentType) {
      const storageData = JSON.parse(localStorage.getItem('edit_consignment'));
      this.consignmentId = storageData?.consignmentId;
      this.consignmentType = storageData?.consignmentType;

      if (!this.consignmentId) {
        this.setNotification({ message: 'Отсутствует consignmentId' });
        this.onCancel();
        return;
      }
      if (!this.consignmentType) {
        this.setNotification({ message: 'Отсутствует consignment type' });
        this.onCancel();
        return;
      }
    } else {
      localStorage.setItem(
          'edit_consignment',
          JSON.stringify({ consignmentId: this.consignmentId, consignmentType: this.consignmentType }),
      );
    }

    this.loadConsignment();
  },
  data: () => ({
    consignmentType: undefined,
    consignmentId: undefined,
    consignment: undefined,
    isLoading: false,
    contracts: [],
    contractName: '',
    eventItems: [],
    eventId: '',
    eventName: '',
    adreskaItems: [],
    adreskaId: '',
    adreskaAddress: '',
    loaderDialog: false,
    progressValue: 0,
    consignmentOpened: false,
    newConsignment: {},
    newConsignmentId: '',
  }),
  computed: {
    headersTable() {
      const headers = [
        {
          text: 'Элемент',
          align: 'start',
          value: 'elementName',
        },
        {
          text: 'Количество',
          value: 'factCountElement',
          align: 'center',
        },
      ];

      return headers;
    },
    eventsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.consignment.contractorId,
        },
      ];
    },
    isDisplayContract() {
      return this.consignmentType === 'BUY_CONSTRUCTION' || this.consignmentType === 'ACCEPTANCE_TO_WAREHOUSE' ||
          this.consignmentType === 'RECALCULATION';
    },
    isDisplayEvent() {
      return this.consignmentType === 'BUY_CONSTRUCTION' || this.consignmentType === 'ACCEPTANCE_TO_WAREHOUSE' ||
          this.consignmentType === 'RECALCULATION' || this.consignmentType === 'DEFECTOSCOPE' || this.consignmentType === 'EXTRADITE_FROM_WAREHOUSE';
    },
    isDisplayAdreska() {
      return this.consignmentType === 'BUY_CONSTRUCTION' || this.consignmentType === 'ACCEPTANCE_TO_WAREHOUSE' ||
          this.consignmentType === 'DEFECTOSCOPE' || this.consignmentType === 'EXTRADITE_FROM_WAREHOUSE';
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  watch: {
    contracts() {
      const contract = this.contracts.filter((item) => item.name === this.consignment.contract);

      if (contract.length === 1) {
        this.consignment.contractId = contract[0].id;
      }
    },
    eventItems() {
      if (this.eventId !== undefined) {
        this.consignment.eventId = this.eventId;
        this.eventId = undefined;
      }
    },
    adreskaItems() {
      if (this.adreskaId !== undefined) {
        this.consignment.adreskaId = this.adreskaId;
        this.adreskaId = undefined;
      }
    },
  },
  methods: {
    async loadConsignment() {
      this.isLoading = true;

      const response = await fetch(
          `${BASE_URL}/consignment?id=${this.consignmentId}&type=${this.consignmentType}`,
      );
      const responseData = await response.json();

      this.eventId = responseData.info?.eventId;
      this.adreskaId = responseData.info?.adreskaId;

      this.consignment = {
        ...responseData.info,
        elements: responseData.elements,
        typeLabel: consignmentTypesMap[responseData.info.type],
      };

      this.isLoading = false;
    },
    onCancel() {
      this.$router.back();
    },
    async onTapViewReport() {
       this.isLoading = true;

      this.newConsignment = await fetchConsignment(this.newConsignmentId, this.consignmentType);

      this.isLoading = false;
      this.consignmentOpened = true;
    },
    onSave() {
      this.loaderDialog = true;
      this.progressValue = 0;

      Object.keys(this.consignment).forEach((key) => {
        if (this.consignment[key] === null) {
          delete this.consignment[key];
        }
      });

      const isWithRecalculation = this.consignmentType === 'BUY_CONSTRUCTION' || this.consignmentType === 'ACCEPTANCE_TO_WAREHOUSE';

      const elements = this.consignment.elements.map((item) => {
        const element = {
          id: item.elementId,
          count: item.factCountElement ? Number(item.factCountElement) : null,
        };

        if (isWithRecalculation) {
          element.isWithRecalculation = true;
        }

        return element;
      });

      const request = {
        contract: this.consignment.contractId ? Number(this.consignment.contractId) : undefined,
        eventId: this.consignment.eventId ? Number(this.consignment.eventId) : undefined,
        adreskaId: this.consignment.adreskaId ? Number(this.consignment.adreskaId) : undefined,
        elements: elements,
      };

      axios
          .post(`${BASE_URL}/${consignmentUrlByType[this.consignmentType]}/${this.consignment.id}`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
          .then((result) => {
            this.newConsignmentId = result.data?.newConsignmentId;
            this.progressValue = 100;
          })
          .catch((error) => {
            console.error(error);
            this.loaderDialog = false;
            this.setNotification({ message: error.response.data });
          });
    },
    ...mapActions('user', ['setNotification']),
  },
};

</script>

<style scoped>
.form1 {
  padding: 30px;
}

.loading {
  margin-left: 50%;
  margin-top: 200px;
}
</style>
