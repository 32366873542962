<template>
  <div class="usage-point-connection">
    <h2>Наименование присоединения точки учёта:</h2>

    <div class="usage-point-connection__connection">
      <v-input
        :disabled="!isEditing"
        :value="getPointTitle(internalValue.usagePoint)"
        class="usage-point-connection__name"
        clearable
        rules="required"
        title="Наименование присоединения *"
      />
    </div>

    <v-divider/>

    <div>
      <v-input
        v-model="internalValue.usagePoint.objectAddress"
        :disabled="!isEditing"
        class="usage-point-connection__item usage-point-connection__item"
        clearable
        rules="required"
        title="Адрес точки поставки *"
      />

      <!--      <v-input-->
      <!--        v-model="internalValue.postcode"-->
      <!--        :disabled="!isEditing"-->
      <!--        class="usage-point-connection__item"-->
      <!--        clearable-->
      <!--        title="Почтовый индекс"-->
      <!--      />-->
    </div>

    <v-divider/>
  </div>
</template>

<script>

import { VDivider, VInput } from '@/components';
import { merge } from 'lodash';

export default {
  name: 'UsagePointConnection',
  components: {
    VInput,
    VDivider,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        connectionName: '',
        location: '',
        postcode: '',
      },
      internalValue: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.internalValue = merge(this.initData, this.value);
      },
    },
    internalValue() {
      this.$emit('input', this.internalValue);
    },
  },
  methods: {
    getStringWithSpaceOrEmpty(val) {
      return val ? `${val} ` : '';
    },
    getPointTitle(pointConnection) {
      return `${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.mainSubstation)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.feeder)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.distributionSubstation)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.line)}${this.getStringWithSpaceOrEmpty(pointConnection.pointInfo.transformationSubstation)}`;
    },
  },
};

</script>

<style lang="scss" scoped>
.usage-point-connection {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #4C4E51;
    margin-bottom: 0;
  }

  &__connection {
    display: flex;

    img {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__name {
    width: 100%;
  }

  &__location {
    display: flex;
    gap: 24px;

    img {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__item {
    width: 272px;

    &--560 {
      width: 560px;
    }
  }
}

</style>
