<template>
  <login-template>
    <div v-if="showIndividual" :class="$style.overlay"/>
    <div v-if="!isDisplayCapInfo" :class="$style.container">
      <template v-if="isCap">
        <certificate-loader @error="() => error = true" @loaded="(data)=>certificates=data"/>
        <certificate-selector v-if="!error && certificates.length" v-model="selectedCertificate"
                              :certificates="certificates"/>
        <submit-task-details :disabled="!selectedCertificate" title="ПРОВЕРИТЬ КЭП"
                             @submit="verifyCap"/>
      </template>
      <template v-else>
        <h3 :class="$style.title">Выберите какого пользователя Вы представляете:</h3>
        <div :class="$style.content">
          <template v-if="isLegalEntity">
            <div
              :class="{ [$style.leftColumn]: true, [$style.active]: isCap }"
              @click="setLegalEntityType('cap')"
            >
              <div :class="$style.icon">
                <legal-entity-cap-icon/>
              </div>
              <div :class="$style.capBlock">
                <span :class="$style.capLabel">С КЭП*</span>
              </div>
              <p :class="$style.text" role="individualUser">представитель&nbsp;ИП или&nbsp;ЮРИДИЧЕСКОго&nbsp;ЛИЦа</p>
            </div>

            <div
              :class="{ [$style.rightColumn]: true, [$style.active]: isWithoutCap }"
              @click="setLegalEntityType('without_cap')"
            >
              <div :class="$style.icon">
                <legal-entity-without-cap-icon/>
              </div>
              <div :class="$style.capBlock">
                <span :class="$style.capLabel">БЕЗ КЭП*</span>
              </div>
              <p :class="$style.text" role="companyUser">Сотрудник&nbsp;ип или&nbsp;ЮРИДИЧЕСКОго&nbsp;ЛИЦа</p>
            </div>
          </template>
          <template v-else>
            <div
              :class="{ [$style.leftColumn]: true, [$style.active]: isPersonEntity }"
              @click="setUserType('person')"
            >
              <div :class="$style.icon">
                <user-person-entity-icon/>
              </div>
              <p :class="$style.text" role="individualUser">физическое лицо</p>
            </div>

            <div
              :class="{ [$style.rightColumn]: true, [$style.active]: isLegalEntity }"
              @click="setUserType('legal')"
            >
              <div :class="$style.icon">
                <user-legal-entity-icon/>
              </div>
              <p :class="$style.text" role="companyUser">ИП или ЮРИДИЧЕСКОе&nbsp;ЛИЦо</p>
            </div>
          </template>

          <div :class="$style.centerColumn">
            <chevron-left-icon/>
            <span :class="$style.questionMark">?</span>
            <chevron-right-icon/>
          </div>
        </div>
      </template>

      <dialog-template v-model="employeeError" center>
        <p :class="$style.searchNote">Данный пользователь не был добавлен как сотрудник организации</p>
      </dialog-template>

    </div>
    <div v-else :class="$style.container">
      <div v-if="isShowEgrulFlow">
        <div v-if="checkStepName === 'certificateValidator'">
          <certificate-validator
            :certificate="selectedCertificate"
            :custom-validations="capValidations"
            @error="() => error = true"
            @validated="validatedHandler"
          />
          <p :class="$style.backLink" @click="back">ИСПОЛЬЗОВАТЬ ДРУГУЮ КЭП</p>
          <p :class="$style.backLink" @click="setUserType('person')">ВОЙТИ КАК ФИЗИЧЕСКОЕ ЛИЦО</p>
        </div>
        <div v-else-if="checkStepName === 'egrulValidator'">
          <egrul-validator
            :inn="innForEgrulCheck"
            @error="() => egurlError = true"
          />
        </div>
      </div>
      <div v-else>
        <certificate-validator
          :certificate="selectedCertificate"
          :custom-validations="capValidations"
          @error="() => error = true"
          @validated="validatedHandler"
        />
        <p :class="$style.backLink" @click="back">ИСПОЛЬЗОВАТЬ ДРУГУЮ КЭП</p>
        <p :class="$style.backLink" @click="setUserType('person')">ВОЙТИ КАК ФИЗИЧЕСКОЕ ЛИЦО</p>
      </div>
    </div>
    <div :class="$style.navigate">
      <v-button :class="$style.back" type="button" variant="outlined-gray-3" @click="back">
        <chevron-left/>
        <span>Назад</span>
      </v-button>
      <div v-if="isShowEgrulFlow">
        <div v-if="checkStepName === 'certificateValidator'">
          <v-button v-if="isDisplayCapInfo" :class="$style.reg" :disabled="error" type="button" @click="goToEgrulStep">
            <span>Далее</span>
            <chevron-right/>
          </v-button>
        </div>
        <div v-else-if="checkStepName === 'egrulValidator'">
          <v-button v-if="isDisplayCapInfo" :class="$style.reg" :disabled="egurlError" type="button" @click="openLegalEntity">
            <span>Зарегистрироваться</span>
            <chevron-right/>
          </v-button>
        </div>
      </div>
      <div v-else>
        <v-button v-if="isDisplayCapInfo" :class="$style.reg" :disabled="egurlError" type="button" @click="openLegalEntity">
          <span>Зарегистрироваться</span>
          <chevron-right/>
        </v-button>
      </div>
    </div>
    <div :class="$style.help">
      <p v-if="isLegalEntity && !isCap">* Для юридического лица потребуется КЭП с правами владельца компании. На
        компьютере должен быть установлен модуль Крипто ПРО CSP 3.6 и корневой сертификат, а в USB-порт вставлен
        токен.</p>
      <router-link :class="$style.helpLink" to="/help-individual">
        <img :class="$style.helpicon" alt="icon: help" height="24px" src="@/assets/icons/help/help.svg" width="24px">&nbsp;ПОДДЕРЖКА
      </router-link>
    </div>
    <cookie-notification :visible="cookieVisible" @close="closeCookie"/>
    <dialog-template
      v-model="showIndividual"
      :top="8"
      height100
      hide-close-button
      style-type="roundedStyle"
      @close="closeIndividual"
    >
      <individual-connection-app
        :certificate="selectedCertificate"
        :company="company"
        :is-legal-entity="isLegalEntity"
        :is-cap="isCap"
        @close="closeIndividual"
      />
    </dialog-template>
  </login-template>
</template>

<script>
import LoginTemplate from 'templates/LoginTemplate';

import ChevronLeftIcon from 'atoms/icons/arrows/ChevronLeft';
import ChevronRightIcon from 'atoms/icons/arrows/ChevronRight';
import UserPersonEntityIcon from 'atoms/icons/user/UserPersonEntity';
import UserLegalEntityIcon from 'atoms/icons/user/UserLegalEntity';
import LegalEntityWithoutCapIcon from 'atoms/icons/user/LegalEntityWithoutCap';
import LegalEntityCapIcon from 'atoms/icons/user/LegalEntityCap';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import { mapActions } from 'vuex';

import IndividualConnectionApp from 'organisms/registration/individual-connection';
import DialogTemplate from 'templates/DialogTemplate';
import CookieNotification from 'components/CookieNotification/CookieNotification';
import { CertificateLoader, VButton, EgrulValidator } from 'components';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import ChevronRight from 'atoms/icons/navigation/ChevronRight';
import CertificateSelector from 'components/ecp/CertificateSelector/CertificateSelector.vue';
import CertificateValidator from 'components/ecp/CertificateValidator/CertificateValidator.vue';

import { VUE_APP_SHOW_EGRUL_FLOW } from '@/constants/env';

export default {
  name: 'ConsumerType',
  components: {
    CertificateValidator,
    CertificateSelector,
    ChevronRight,
    CookieNotification,
    DialogTemplate,
    IndividualConnectionApp,
    LoginTemplate,
    ChevronLeftIcon,
    ChevronRightIcon,
    UserPersonEntityIcon,
    UserLegalEntityIcon,
    SubmitTaskDetails,
    LegalEntityWithoutCapIcon,
    LegalEntityCapIcon,
    VButton,
    ChevronLeft,
    CertificateLoader,
    EgrulValidator,
  },
  created() {
    this.isShowEgrulFlow = this.initShowEgrulFlow();
  },
  data() {
    return {
      userType: undefined,
      isDisplayCapInfo: false,
      company: {},
      certificates: [],
      selectedCertificate: null,
      error: undefined,
      egurlError: undefined,
      legalEntityType: undefined,
      showIndividual: false,
      showEmployee: false,
      employeeError: false,
      cookieVisible: !JSON.parse(localStorage.getItem('agreedCookie')),
      checkStepName: 'certificateValidator',
      innForEgrulCheck: '',
      isShowEgrulFlow: false,
    };
  },
  computed: {
    isPersonEntity() {
      return this.userType === 'person';
    },
    isLegalEntity() {
      return this.userType === 'legal';
    },
    isCap() {
      return this.legalEntityType === 'cap';
    },
    isWithoutCap() {
      return this.legalEntityType === 'without_cap';
    },
    capValidations() {
      return [{
        validator: this.isLegalOrIpCap,
        errorText: 'Невалидный сертификат',
      }];
    },
  },
  watch: {
    async selectedCertificate() {
      if (this.selectedCertificate && this.certificates.length === 1) {
        this.verifyCap();
      }

      if (!this.selectedCertificate) return;
      const certificate = await this.selectedCertificate.getOwnerInfo();

      const legalInn = certificate.find((item) => item.title === 'ИНН ЮЛ' || item.title === 'INNLE' || item.title === 'OID.1.2.643.100.4');
      if (legalInn) {
        this.innForEgrulCheck = legalInn.description;
        return;
      }

      const personalInn = certificate.find((item) => item.title === 'ИНН' || item.title === 'INN' || item.title === '1.2.643.3.131.1.1');
      if (personalInn) {
        this.innForEgrulCheck = personalInn.description;
        return;
      }
    },
  },
  methods: {
    initShowEgrulFlow() {
      return VUE_APP_SHOW_EGRUL_FLOW;
    },
    validatedHandler(data) {
      this.company = data;
    },
    goToEgrulStep() {
      this.checkStepName = 'egrulValidator';
    },
    closeCookie() {
      this.cookieVisible = false;
    },
    setUserType(type) {
      if (this.showIndividual) {
        this.showIndividual = false;
      }
      this.userType = type;
      if (this.userType === 'person') {
        this.showIndividual = true;
      } else if (this.userType === 'legal') {
        this.setLegalEntityType('cap');
      }
    },
    closeIndividual() {
      this.userType = undefined;
      this.showIndividual = false;
    },
    openLegalEntity() {
      this.setUserType('legal');
      this.showIndividual = true;
    },
    setLegalEntityType(type) {
      if (this.showEmployee)
        this.showEmployee = false;
      this.legalEntityType = type;

      if (type === 'without_cap') {
        this.showEmployee = true;
      }
    },
    formatDate(date) {
      date = date.split('T');
      date = date[0].replace(/-/gi, '.').split('.').reverse()
        .join('.');

      return date;
    },
    isLegalOrIpCap(certificate) {
      const isLegalCap = certificate.find((item) => item.title === 'ИНН ЮЛ' || item.title === 'INNLE' || item.title === 'OID.1.2.643.100.4') &&
        certificate.find((item) => item.title === 'ОГРН');
      const isCapHaveOgrnip = certificate.find((item) => item.title === 'ОГРНИП' || item.title === '1.2.643.100.5' || item.title === 'OGRNIP');
      return isLegalCap || isCapHaveOgrnip;
    },
    verifyCap() {
      this.isDisplayCapInfo = true;
    },
    back() {
      if (this.showIndividual)
        this.showIndividual = false;
      if (this.showEmployee)
        this.showEmployee = false;
      if (this.userType === undefined) {
        this.logOut();
        return;
      }
      if (this.legalEntityType === undefined) {
        this.userType = undefined;
        return;
      }
      if (this.isWithoutCap && !this.isDisplayCapInfo) {
        this.legalEntityType = undefined;
        this.userType = undefined;
        return;
      }
      if (this.isCap && !this.isDisplayCapInfo) {
        this.userType = undefined;
        this.legalEntityType = undefined;
        return;
      }
      if (this.isDisplayCapInfo) {
        this.isDisplayCapInfo = false;
      }
    },
    ...mapActions('user', ['logOut']),
    ...mapActions('dataLists', ['setSuppliers']),
  },
};
</script>

<style lang="sass" module>
.help
  height: 24px
  display: flex
  justify-content: flex-end
  align-items: flex-end

  p
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: #4C4E51

.helpLink
  display: flex
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 19px
  text-transform: uppercase
  color: #C1C4C7
  align-items: center

.helpLink:hover
  color: #4F4F4F

.helpicon
  max-width: 24px
  max-height: 24px
  display: block

.container
  position: relative
  grid-row: 2
  -ms-grid-row: 3
  -ms-grid-column: 1
  display: flex
  flex-direction: column
  justify-content: center

.content
  display: flex
  margin-top: 55px
  justify-content: space-between

.leftColumn
  order: 1

.rightColumn
  order: 3

.leftColumn, .rightColumn
  text-align: center
  margin-bottom: 4px

  &:hover:not(.active)
    cursor: pointer

  &:hover, &.active
    p
      color: #4F4F4F

    path
      fill: #4F4F4F

    border-bottom: 4px solid #F27922
    margin-bottom: 0

    .capLabel
      border-color: #D45D00
      color: #D45D00

.leftColumn.active ~ .centerColumn svg:first-child path
  fill: #4F4F4F

.rightColumn.active ~ .centerColumn svg:last-child path
  fill: #4F4F4F

.centerColumn
  order: 2
  display: flex
  width: 100%
  flex-direction: row
  align-items: center
  justify-content: center
  text-align: center

.title
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 19px
  text-transform: uppercase
  color: #4C4E51

.icon
  margin-bottom: 10px

  path
    fill: #C1C4C7

.text
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 21px
  text-align: center
  text-transform: uppercase
  color: #C1C4C7

.questionMark
  margin: 0 15px
  font-family: Roboto
  font-weight: 800
  font-size: 48px
  line-height: 56px
  color: #0F1010

.backLink
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: #71757A
  text-align: center
  text-decoration: underline
  cursor: pointer
  margin-bottom: 16px

.back, .reg
  width: max-content
  height: 46px
  display: flex
  align-items: center
  cursor: pointer
  padding: 10px 30px

  svg
    fill: #4C4E51

.reg
  svg
    fill: #FFF

.capLabel
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 14px
  text-align: center
  color: #C1C4C7
  border: 1px solid #C1C4C7
  border-radius: 16px
  padding: 6px 12px

.capBlock
  margin-top: 14px
  margin-bottom: 18px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.overlay
  background-color: #666666
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  opacity: 0.5

.navigate
  width: 100%
  display: flex
  justify-content: space-between
</style>
