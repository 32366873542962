<template>
  <Process/>
</template>

<script>
import Process from 'components/Processes/Process11/Process2.vue';

export default {
  name: 'DistributionForInspection',
  components: { Process },
};
</script>

<style scoped>

</style>
