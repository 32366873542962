<template>
  <div>
    <odk-tag-modal
      v-model="tagsModalState"
      @updated="fetchAdreskaFactCollection"
    />
    <v-app class="vuetify-styles" data-app>
      <v-row class="form1" justify="center">
        <v-col lg="8" md="10" sm="12">
          <div ref="form" class="v-application" style="display: block">
            <v-card-title>
              <div class="title">
                <span class="text-h5">Приёмка монтажа</span>
                <span v-if="isExpired" class="errorText">
                  {{subtitleError}}
                </span>
              </div>
            </v-card-title>
            <v-card-text>
              <text-field
                ref="tagsFilter"
                v-model="tagsFilter"
                title="Фильтрация по тэгу"
              />

              <events-autocomplete
                ref="event"
                v-model="eventId"
                :items.sync="events"
                :search.sync="eventName"
                title="Мероприятие"
              />

              <contractor-autocomplete
                ref="contractor"
                v-model="contractorId"
                :items.sync="contractors"
                :search.sync="contractorName"
                title="Подрядчик"
              ></contractor-autocomplete>

              <v-row class="mb-8">
                <v-col>
                  <div id="maps">
                    <p>Выберите принятые ОДК*</p>
                    <yandex-map
                      ref="customMap"
                      :collection-axp="axpItemsFiltered"
                      @tapPlacemark="onTapPlacemark"
                    />
                  </div>
                </v-col>
              </v-row>

              <v-data-table
                v-model="selectedAdreska"
                :headers="headersTable"
                :items="adreskaItemsFiltered"
                :loading="isLoadingAxp"
                class="elevation-1 mb-4"
                item-key="id"
                return-object
                show-select
              >
                <template #no-data>
                  <span>Отсутствуют данные</span>
                </template>
                <template #item.addTagButton="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      rounded
                      @click="openTags(item.id)"
                    >
                      Тэги
                    </v-btn>
                  </div>
                </template>

                <template #item.status="{ item }">
                  {{ item.status }}
                </template>
              </v-data-table>

              <div v-if="adreska">
                <adreska-history
                  :adreska-id="adreska.id"
                  subselect="INSTALLATION_CONTROL"
                  title="Информация о монтаже"
                />

                <text-field
                  v-model="adreska.event.name"
                  is-disabled
                  title="Мероприятие"
                ></text-field>

                <text-field
                  v-model="adreska.axp.odk.name"
                  is-disabled
                  title="ОДК"
                ></text-field>

                <text-field
                  v-model="adreska.axp.name"
                  is-disabled
                  title="АХП"
                ></text-field>

                <text-field
                  ref="administrativeDistrict"
                  v-model="adreska.administrativeDistrict.name"
                  is-disabled
                  title="Административный округ г. Москвы установки ОДК"
                ></text-field>

                <text-field
                  ref="responsibleRES"
                  v-model="adreska.responsibleDistrictDepartmentName"
                  is-disabled
                  title="Ответственный РЭС "
                ></text-field>

                <text-field
                  ref="quantityPlan"
                  v-model="adreska.quantityPlan"
                  is-disabled
                  title="Количество по плану"
                ></text-field>

                <text-field
                  ref="workModePlan"
                  v-model="adreska.workModePlan"
                  is-disabled
                  title="Режим работы ОДК по плану"
                ></text-field>

                <text-field
                  ref="workModePlan"
                  :text="
                    formatDateFromOdkTimestamp(
                      adreska.event.planInstallationStartDate,
                    )
                  "
                  is-disabled
                  title="Планируемая дата начала монтажа"
                ></text-field>

                <text-field
                  ref="countOdk"
                  v-model="countOdk"
                  is-disabled
                  title="Количество ОДК"
                ></text-field>

                <text-field
                  v-if="adreska"
                  ref="installationCompletionPercentage"
                  :text="installationPercentage"
                  is-disabled
                  title="% выполнения монтажа"
                ></text-field>
                <text-field
                  ref="connectionPoint"
                  v-model="connectionPoint"
                  is-disabled
                  title="Точка подключения"
                ></text-field>

                <address-autocomplete
                  ref="addressConnectionPoint"
                  v-model="addressConnectionPoint"
                  :items.sync="addresses"
                  :search.sync="addressConnectionPointName"
                  is-disabled
                  title="Адрес установки автоматического выключателя, коммутирующего ОДК"
                ></address-autocomplete>

                <text-field
                  ref="switchBoard"
                  v-model="switchBoard"
                  is-disabled
                  title="ТМ ВРШ"
                ></text-field>

                <v-row class="no-margin-top mt-1 mb-3">
                  <v-checkbox
                    v-model="isConnectedGenerator"
                    class="no-margin-top"
                    color="success"
                    disabled
                    hide-details
                    label="Возможность подключения ДГУ или бензогенератора"
                  ></v-checkbox>
                </v-row>

                <v-row class="no-margin-top mt-1 mb-3">
                  <v-checkbox
                    v-model="isConnectedElectricity"
                    class="no-margin-top"
                    color="success"
                    disabled
                    hide-details
                    label="Подключено к электропитанию?"
                  ></v-checkbox>
                </v-row>

                <v-row class="no-margin-top mt-1 mb-3">
                  <v-checkbox
                    v-model="readyForInput"
                    class="no-margin-top"
                    color="success"
                    disabled
                    hide-details
                    label="Готово к вводу/включению?"
                  ></v-checkbox>
                </v-row>

                <text-field
                  v-for="(responsible, idx) in responsibles"
                  :key="idx"
                  ref="responsibleContractorEmployee"
                  :text="`${responsible.responsibleName}: ${
                    formatting.phoneFormat(responsible.responsiblePhone)
                      .formattedPhone
                  }`"
                  :title="`Ответственный подрядчик ${idx + 1}`"
                  is-disabled
                ></text-field>
              </div>

              <simple-autocomplete-by-function
                ref="workModePlan"
                v-model="workModeFact"
                :fetch-data-function="
                  (name) =>
                    workModeItems.filter((it) =>
                      it.name.toLowerCase().includes(name?.toLowerCase() ?? ''),
                    )
                "
                :fetch-default-data-function="() => workModeItems"
                :items.sync="workModeItemsSync"
                :search.sync="workModeFactName"
                is-required
                title="Режим работы ОДК по факту"
              />

              <file-input
                v-if="adreska"
                ref="photos"
                v-model="photos"
                class="mt-2"
                title="Фото"
              ></file-input>

              <v-row class="no-margin-top mt-1">
                <v-checkbox
                  v-model="isDefectNotFound"
                  class="no-margin-top"
                  color="success"
                  hide-details
                  label="Дефекты не выявлены"
                ></v-checkbox>
              </v-row>

              <div v-if="!isDefectNotFound">
                <v-row class="mt-7">
                  <v-col>
                    <div>
                      <v-label>Комментарий</v-label>
                    </div>
                    <v-textarea
                      v-model="commentDefect"
                      dense
                      name="input-7-4"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-data-table
                  v-if="adreskaFactIds.length"
                  :headers="headersNewDefects"
                  :items="newDefects"
                >
                  <template #item.actions="{ item }">
                    <v-icon
                      v-if="!item.id"
                      class="error-label"
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template #top>
                    <v-btn
                      color="primary"
                      elevation="0"
                      rounded
                      style="width: 100%"
                      @click="addDefect"
                    >
                      Добавить дефект
                    </v-btn>
                  </template>
                  <template #item.defectId="{ item }">
                    <defect-autocomplete
                      :key="`defectId${item.id}`"
                      v-model="item.defectId"
                      :is-disabled="Boolean(item.id)"
                      :items.sync="item.defects"
                      :search.sync="item.defectName"
                      is-required
                      style="min-width: 100px"
                    ></defect-autocomplete>
                  </template>
                  <template #item.defectDescription="{ item }">
                    <v-textarea
                      :key="`desc${item.id}`"
                      v-model="item.defectDescription"
                      :disabled="Boolean(item.id)"
                      class="mt-8"
                      dense
                      name="input-7-4"
                      outlined
                      style="min-width: 100px"
                    ></v-textarea>
                  </template>
                  <template #item.photos="{ item }">
                    <file-input
                      :key="`photos${item.id}`"
                      v-model="item.photos"
                      :only-view="!!item.id"
                      style="min-width: 100px"
                    ></file-input>
                  </template>
                  <template #item.criticalityId="{ item }">
                    <simple-autocomplete
                      :key="`criticality${item.id}`"
                      v-model="item.criticalityId"
                      :is-disabled="Boolean(item.id)"
                      :items.sync="item.criticalityItems"
                      :search.sync="item.criticalityName"
                      is-required
                      style="min-width: 100px"
                      url="/criticality/find?name="
                      url-default-data="/criticality"
                    ></simple-autocomplete>
                  </template>
                  <template #item.isWarranty="{ item }">
                    <div style="justify-content: center; display: flex">
                      <v-checkbox
                        v-model="item.isWarranty"
                        class="no-margin-top mb-1"
                        color="success"
                        hide-details
                        disabled
                      ></v-checkbox>
                    </div>
                  </template>
                  <template #item.foundBy="{ item }">
                    <text-field
                      :key="`foundBy${item.id}`"
                      v-model="item.foundBy"
                      :is-disabled="
                        Boolean(item.id) ||
                        modeInternal !== inspectionScreenModes.ALL
                      "
                      is-required
                      style="min-width: 100px"
                    ></text-field>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isDisabledSaveDialog"
                color="primary"
                elevation="0"
                rounded
                @click="onTapSend"
              >
                Отправить
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-dialog
          v-model="loaderDialog"
          class="v-dialog-top"
          persistent
          width="250"
        >
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center green lighten-2">
              Загрузка данных
            </v-card-title>
            <div class="text-center mt-8">
              <div v-if="progressValue < 100">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-icon :size="70" :width="7" color="green darken-2">
                  mdi-cloud-check-variant
                </v-icon>
              </div>

              <div class="mt-2 mb-8">
                {{ progressValue + '%' }}
              </div>
            </div>
            <v-card-actions class="flex-column">
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="onTapBack"
              >
                На главную
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
                Заполнить еще раз
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogTableDelete"
          class="v-dialog-top"
          max-width="500px"
        >
          <v-card class="v-application">
            <v-card-title class="text-h5 text-center"
              >Вы уверены что хотите удалить?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                color="primary"
                outlined
                rounded
                @click="dialogTableDelete = false"
                >Отмена
              </v-btn>
              <v-btn
                class="ma-2"
                color="error"
                outlined
                rounded
                @click="onTapDeleteDefect"
              >
                <v-icon class="label-error pr-1">mdi-delete</v-icon>
                Удалить
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import TextField from 'components/Processes/components/TextField.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ContractorAutocomplete from 'components/Processes/components/ContractorAutocomplete.vue';
import { workModeList } from 'components/Processes/list';
import { formatDateFromOdkTimestamp, formatOdkTimestampToDateObject, getDateAtStartOfDay } from 'lib/utils/date';
import moment from 'moment';
import AddressAutocomplete from 'components/Processes/components/AddressAutocomplete.vue';
import YandexMap from 'components/Processes/components/Map/YandexMap.vue';
import SimpleAutocompleteByFunction from 'components/Processes/components/SimpleAutocompleteByFunction.vue';
import AdreskaHistory from 'components/Processes/components/AdreskaHistory.vue';
import formatting from 'lib/utils/formatting';
import OdkTagModal from 'components/OdkTagModal/OdkTagModal.vue';
import { distinctBy } from 'utils/arrays/arrays';
import { inspectionScreenModes } from 'components/Processes/Process11/process5-7/constants';
import FileInput from 'components/Processes/components/FileInput.vue';
import { filterForAccess } from 'lib/utils/permissions';
import EventsAutocomplete from 'components/Processes/components/EventsAutocomplete.vue';
import DefectAutocomplete from '@/components/Processes/components/DefectAutocomplete.vue';
import SimpleAutocomplete from '@/components/Processes/components/SimpleAutocomplete.vue';

export default {
  components: {
    FileInput,
    OdkTagModal,
    AdreskaHistory,
    SimpleAutocompleteByFunction,
    AddressAutocomplete,
    ContractorAutocomplete,
    YandexMap,
    TextField,
    EventsAutocomplete,
    DefectAutocomplete,
    SimpleAutocomplete,
  },

  async created() {
    await this.fetchAdreskaFactCollection();
    void this.fetchDefects();
    if (this.$route.params.initAdreskaId) {
      const adreskaFact = this.adreskaFactCollection.filter(
        (it) => it.adreskaId === this.$route.params.initAdreskaId,
      );
      this.adreska = adreskaFact[0];
      if (!this.adreska) {
        await this.setNotification({
          message: 'Невозможно совершить действие - неверный статус объекта',
        });
        this.onTapBack();
      }
      this.onTapPlacemark(adreskaFact[0].id);
    }
  },

  data() {
    return {
      progressValue: 0,
      updateMap: 0,
      loaderDialog: false,
      isLoadingAxp: false,
      tagsModalState: null,
      adreska: null,
      eventId: null,
      contractorId: null,
      addressConnectionPointName: '',

      contractorName: '',
      eventName: '',
      adreskaAddress: '',
      workModeFactName: '',
      tagsFilter: '',
      contractors: [],
      events: [],
      adreskaItems: [],
      addresses: [],
      workModeItems: workModeList.map((it) => ({
        id: it,
        name: it,
      })),
      workModeItemsSync: [],
      adreskaFactCollection: [],
      selectedAdreska: [],
      connectionPoint: null,
      addressConnectionPoint: null,
      isConnectedGenerator: false,
      isConnectedElectricity: false,
      administrativeDistrict: null,
      responsibleRES: null,
      responsibles: [],
      readyForInput: false,
      switchBoard: null,
      workModeFact: null,
      plannedInstallationDateText: null,
      plannedInstallationDate: null,
      photos: [],
      isDefectNotFound: false,
      commentDefect: null,
      headersNewDefects: [
        {
          text: 'Дефект',
          value: 'defectId',
          align: 'center',
          justify: 'start',
          sortable: false,
        },
        {
          text: 'Комментарий к дефекту',
          align: 'center',
          value: 'defectDescription',
          sortable: false,
        },
        {
          text: 'Фото',
          value: 'photos',
          align: 'center',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Критичность дефекта',
          value: 'criticalityId',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ФИО выявившего дефект',
          value: 'foundBy',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Гарантийный',
          value: 'isWarranty',
          align: 'center',
          sortable: false,
        },
        { text: '', align: 'end', value: 'actions', sortable: false },
      ],
      newDefects: [],
      dialogTableDelete: false,
      editId: -1,
      inspectionScreenModes: inspectionScreenModes,
      defectsTypes: [],
    };
  },
  computed: {
    headersTable() {
      const tagsEditHeader = this.hasAccessForTagEditing
        ? [{ text: 'Tэги', value: 'addTagButton', align: 'center' }]
        : [];
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          fixed: {},
        },
        {
          text: 'Адресная программа',
          align: 'start',
          value: 'adreskaName',
        },
        {
          text: 'Наименование ОДК',
          align: 'start',
          value: 'odkName',
        },
        { text: 'Подрядчик', value: 'contractorName', align: 'center' },
        { text: 'Tэги', value: 'tags', align: 'center' },
        ...tagsEditHeader,
      ];
    },
    adreskaItemsFiltered() {
      return distinctBy(this.axpItemsFiltered, (it) => it.adreskaId);
    },
    axpItemsFiltered() {
      let filtered = this.adreskaFactCollection;

      if (this.eventId) {
        filtered = filtered.filter(
          (it) => String(it.eventId) === String(this.eventId),
        );
      }
      if (this.contractorId) {
        filtered = filtered.filter(
          (it) => it.contractorId === this.contractorId,
        );
      }
      if (this.tagsFilter) {
        // eslint-disable-next-line arrow-body-style
        filtered = filtered.filter((it) => {
          return it.tags.toLowerCase().includes(this.tagsFilter.toLowerCase());
        });
      }

      return filtered;
    },
    formatting() {
      return formatting;
    },
    countOdk() {
      return this.adreskaId ? this.adreskaFactCollection.length : 0;
    },
    totalCountOdk() {
      return Number(this.adreska.quantityPlan);
    },
    isDisabledSaveDialog() {
      if (!this.selectedAdreska.length) return true;
      if (!this.workModeFact || this.workModeFact.length === 0) return true;

      let isDisableDefects = false;
      if (!this.isDefectNotFound) {
        const filteredDefects = this.newDefects.filter((it) => !it.id);

        const findedDefect = this.newDefects.some((it) => {
          let isDefect = false;

          // В дефекте проверяем заполненность фото, тип дефекта и критичность
          if (!it.id) {
            if (!it.defectId || !it.photos.length || !it.criticalityId) {
              isDefect = true;
            }

            const defectType = this.defectsTypes.find(
              (defectType) => defectType.id == it.defectId,
            );

            // Если тип дефекта - "Своё замечание", то надо проверить не пустое ли поле комментария
            if (
              !it.defectDescription &&
              defectType &&
              defectType.mnemonic === 'CUSTOM'
            ) {
              isDefect = !it.defectDescription || !it.defectDescription.trim();
            }
          }
          return isDefect;
        });

        isDisableDefects = filteredDefects.length === 0 || findedDefect;
      }

      return isDisableDefects;
    },
    installationPercentage() {
      return (this.countOdk / this.totalCountOdk) * 100;
    },
    adreskaId() {
      if (this.selectedAdreska.length === 1)
        return this.selectedAdreska[0].adreskaId;
      return null;
    },
    hasAccessForTagEditing() {
      return filterForAccess('process/1/5', this.getAuthorization?.blockAccess);
    },
    isExpired() {
      const date = getDateAtStartOfDay(Date.now());
      // После приёмки процесс не доступен => если зашли в процесс <=> Приёмка не осуществлялась
      return this.selectedAdreska.some((it) => date > formatOdkTimestampToDateObject(it.event.planInstallationEndDate));
    },
    subtitleError() {
      return 'Просрочена плановая дата окончания монтажа';
    },
    adreskaFactIds() {
      return this.selectedAdreska.map((it) => it.id);
    },
    modeInternal() {
      if (this.mode !== this.inspectionScreenModes.NOT_SPECIFIED)
        return this.mode;
      if (this.canInspectAll) return this.inspectionScreenModes.ALL;
      if (this.canInspectRegular) return this.inspectionScreenModes.REGULAR;
      return this.inspectionScreenModes.NON_REGULAR;
    },
    userFio() {
      let userName = '';

      const user = this.getUser;

      if (user.fullName) {
        userName = `${user.fullName.surname} ${user.fullName.name}`;

        if (user.fullName.patronymic) {
          userName += ` ${user.fullName.patronymic}`;
        }
      } else {
        userName = `Пользователь ${user.id}`;
      }

      return userName;
    },
    ...mapGetters('user', ['getToken', 'getAccount', 'isBoiler', 'getUser']),
  },

  watch: {
    selectedAdreska() {
      this.onSelected();
    },
    async adreskaId() {
      if (!this.adreskaId) {
        this.adreska = null;
        return;
      }
      await this.fetchAdreska();
      await this.fetchAdreskaFactCollection();
    },
  },

  methods: {
    formatDateFromOdkTimestamp,
    openTags(id) {
      this.tagsModalState = id;
    },
    addDefect() {
      this.newDefects.push({
        isNew: true,
        isWarranty: true,
        foundBy: this.userFio,
        photos: [],
      });
    },
    deleteItem(item) {
      this.editId = this.newDefects.indexOf(item);
      this.dialogTableDelete = true;
    },
    onTapDeleteDefect() {
      this.newDefects.splice(this.editId, 1);
      this.editId = -1;
      this.dialogTableDelete = false;
    },
    async onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const defects = [];
      if (!this.isDefectNotFound && this.selectedAdreska.length === 1) {
        this.newDefects.forEach((it) => {
          const defectPhotos = [];
          it.photos.forEach((doc) => {
            defectPhotos.push({
              stringBase64: doc.previewUrl,
              fileName: doc.name,
            });
          });
          defects.push({
            defectId: it.defectId,
            description: it.defectDescription,
            photos: defectPhotos,
            criticalityId: it.criticalityId,
            adreskaId: this.selectedAdreska[0].adreskaId,
          });
        });
      }

      const request = {
        adreskaIds: this.selectedAdreska.map((it) => it.adreskaId),
        workModeFact: this.workModeFact,
        photos: this.adreska
          ? this.photos.map((doc) => ({
              stringBase64: doc.previewUrl,
              fileName: doc.name,
            }))
          : [],
        defects: defects,
      };

      axios
        .post(`${BASE_URL}/assembly-acceptance`, request, {
          headers: { 'X-Authorization': `Bearer ${this.getToken}` },
        })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapPlacemark(id) {
      const fact = this.adreskaFactCollection.find((it) => it.id === id);
      const forSelectedAdreska = this.adreskaItemsFiltered.find(
        (it) => it.adreskaId === fact.adreskaId,
      );
      const state = fact.isSelected;
      if (!state) this.selectedAdreska.push(forSelectedAdreska);
      else
        this.selectedAdreska = this.selectedAdreska.filter(
          (it) => it.adreskaId !== fact.adreskaId,
        );
      this.onSelected();
    },
    onSelected() {
      const selectedAdreskaId = new Set(
        this.selectedAdreska.map((it) => it.id),
      );
      this.adreskaFactCollection = this.adreskaFactCollection.map((it) => ({
        ...it,
        isSelected: selectedAdreskaId.has(it.adreskaId),
        balloonContent: {
          ...it.balloonContent,
          buttonLabel: selectedAdreskaId.has(it.adreskaId)
            ? 'Отменить'
            : 'Выбрать',
        },
      }));
    },
    onTapBack() {
      this.$router.back();
    },

    onTapAgain() {
      this.progressValue = 0;
      this.updateMap = 0;
      this.contractorId = null;
      this.contractorName = '';
      this.contractors = [];
      this.adreskaId = null;
      this.adreska = null;
      this.adreskaAddress = '';
      this.adreskaItems = [];
      this.adreskaFactCollection = [];
      this.connectionPoint = null;
      this.addressConnectionPoint = null;
      this.isConnectedGenerator = false;
      this.isConnectedElectricity = false;
      this.administrativeDistrict = null;
      this.responsibleRES = null;
      this.responsibles = [];
      this.workModeFact = null;
      this.plannedInstallationDate = null;
      this.readyForInput = false;
      this.switchBoard = null;
      this.loaderDialog = false;
      this.addresses = [];
      this.addressConnectionPointName = '';
      this.workModeItemsSync = [];
      this.workModeFactName = '';
      this.selectedAdreska = [];
      this.tagsFilter = '';
    },
    async fetchAdreska() {
      if (!this.adreskaId) {
        this.adreska = null;
        return;
      }
      const response = await fetch(`${BASE_URL}/adreska/${this.adreskaId}`);
      const adreskaSummaryResponse = await fetch(
        `${BASE_URL}/adreska-summary-entity?adreskaId=${this.adreskaId}`,
      );
      const contractorResposiblesResponse = await fetch(
        `${BASE_URL}/contractor/${this.contractorId}/responsible`,
      );
      if (
        response.status === 200 &&
        adreskaSummaryResponse.status === 200 &&
        contractorResposiblesResponse.status === 200
      ) {
        this.adreska = await response.json();
        const adreskaSummary = await adreskaSummaryResponse.json();
        this.connectionPoint = adreskaSummary.connectionPoint;
        this.addresses = [
          {
            id: adreskaSummary.addressConnectionPoint,
            name: adreskaSummary.addressConnectionPoint,
          },
        ];
        this.addressConnectionPoint = adreskaSummary.addressConnectionPoint;
        this.addressConnectionPointName = adreskaSummary.addressConnectionPoint;
        this.administrativeDistrict = adreskaSummary.administrativeDistrict;
        this.isConnectedGenerator = adreskaSummary.isConnectedGenerator;
        this.isConnectedElectricity = adreskaSummary.isConnectedElectricity;
        this.readyForInput = adreskaSummary.readyForInput;
        this.switchBoard = adreskaSummary.switchBoard;
        this.workModeFact = adreskaSummary.workModeFact;
        this.workModeFactName = adreskaSummary.workModeFact;
        this.responsibles = await contractorResposiblesResponse.json();
        // Может быть 0
        if (
          adreskaSummary.plannedInstallationDate !== null &&
          adreskaSummary.plannedInstallationDate !== undefined
        ) {
          this.plannedInstallationDate = moment(
            formatDateFromOdkTimestamp(adreskaSummary.plannedInstallationDate),
            'DD.MM.YYYY',
          ).format('YYYY-MM-DD');
          this.plannedInstallationDateText = formatDateFromOdkTimestamp(
            adreskaSummary.plannedInstallationDate,
          );
        }
      } else {
        this.adreska = null;
        this.adreskaSummary = null;
      }
    },
    async fetchAdreskaFactCollection() {
      this.isLoadingAxp = true;
      let response = null;
      if (this.adreskaId) {
        response = await fetch(
          `${BASE_URL}/adreskafact?adreskaId=${this.adreskaId}`,
        );
      } else {
        response = await fetch(
          `${BASE_URL}/adreska/adreskafact?statuses=MOUNTED`,
        );
      }
      const data = await response.json();
      this.alreadyMountCounter = data.length;
      this.adreskaFactCollection = data.map((el) => {
        let buttonLabel = null;
        if (el.statusAssemblyAcceptance <= 0) {
          buttonLabel =
            el.adreska.id === this.adreskaId ? 'Отменить' : 'Выбрать';
        }
        let color;
        let status;
        if (el.statusInstallationResultControl == 1) {
          color = 'red';
          status = 'С замечаниями';
        } else if (el.statusInstallationResultControl == 2) {
          color = 'green';
          status = 'Без замечаний';
        } else {
          color = 'blue';
          status = 'Контроль не проводился';
        }
        return {
          id: el.id,
          locationLatitude: el.locationLatitude,
          locationLongitude: el.locationLongitude,
          statusAssemblyAcceptance: el.statusAssemblyAcceptance,
          event: {
            id: el.adreska.event.id,
            name: el.adreska.event.name,
            planInstallationStartDate: el.adreska.event.planInstallationStartDate,
            planInstallationEndDate: el.adreska.event.planInstallationEndDate,
          },
          axp: {
            name: el.axp.name,
            odk: {
              name: el.axp.odk.name,
            },
          },
          administrativeDistrict: {
            fullName: el.adreska.administrativeDistrict.fullName,
            id: el.adreska.administrativeDistrict.id,
            name: el.adreska.administrativeDistrict.name,
          },
          workModePlan: el.adreska.workModePlan,
          responsibleDistrictDepartmentName:
            el.adreska.responsibleDistrictDepartmentName,
          adreskaId: el.adreska.id,
          adreskaName: el.adreska.name,
          tags: el.adreska.tags.map((it) => it.name).join(', '),
          odkName: el.axp.odk.name,
          contractorId: el.adreska.contractorId,
          contractorName: el.adreska.contractorName,
          isSelected: el.adreska.id === this.adreskaId,
          countOdk: el.countOdk,
          color: color,
          balloonContent: {
            title: el.axp.odk.name,
            buttonLabel: buttonLabel,
            props: [
              {
                label: 'ID',
                value: el.id,
              },
              {
                label: 'АХП',
                value: el.axp.name,
              },
              {
                label: 'Тип декора',
                value: el.decorType?.name,
              },
              {
                label: 'Подрядчик',
                value: el.adreska.contractorName,
              },
              {
                label: 'Статус',
                value: status,
              },
              {
                label: 'Дата монтажа',
                value: formatDateFromOdkTimestamp(el.createDate),
              },
            ],
          },
        };
      });
      this.isLoadingAxp = false;
    },
    ...mapActions('user', ['setNotification']),
    async fetchDefects() {
      try {
        const defectsResponse = await fetch(`${BASE_URL}/defects`);
        const defectsData = await defectsResponse.json();
        this.defectsTypes = defectsData;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
</style>

