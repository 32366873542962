<template>
  <div :class="{['matching-points']: true, ['matching-points--active']: active}">
    <div :class="{
      ['matching-points__point']: true,
      ['matching-points__point--merge']: action === 'merge',
      ['matching-points__point--replace']: action === 'replace',
      ['matching-points__point--delete']: action === 'delete',
    }">
      <div v-if="action" :class="{['matching-points__point-unsettled']: points.unsettled}">
        {{ points.current.name }}
      </div>
      <edit-mode-button
        v-else
        :is-true="selected"
        :title="points.current.name"
        class="matching-points__point-check-box"
        select
        @click="selectCurrentHandler"
      />
      <div class="matching-points__point-navigate">
        <img src="@/assets/icons/point/point.svg" style="height: 18px; margin-right: 10px"/>
        <img src="@/assets/icons/point/follow.svg" />
      </div>
    </div>
    <div :class="{
      ['matching-points__point']: true,
      ['matching-points__point--right']: true,
      ['matching-points__point--right-updated']: action,
      ['matching-points__point--merge']: action === 'merge',
      ['matching-points__point--replace']: action === 'replace',
      ['matching-points__point--delete']: action === 'delete',
    }">
      <img v-if="action === 'merge'" class="matching-points__point-action-icon" src="@/assets/icons/point/action-merge.svg" />
      <img v-else-if="action === 'replace'" class="matching-points__point-action-icon" src="@/assets/icons/point/action-replace.svg" />
      <img v-else-if="action === 'delete'" class="matching-points__point-action-icon" src="@/assets/icons/point/action-delete.svg" />
      <div v-if="action">
        {{ downloadedPointName }}
      </div>
      <v-select
        v-else
        v-model="selectedPoint"
        :options="filteredDownloaded"
        :class="{['matching-points__point-select']: true, ['matching-points__point-select--unsettled']: points.unsettled}"
        new-style
      />
      <div class="matching-points__point-navigate">
        <img src="@/assets/icons/point/point.svg" style="height: 18px; margin-right: 10px"/>
        <img src="@/assets/icons/point/follow.svg" />
      </div>
    </div>

    <div :class="{['matching-points__action']: true, ['matching-points__action--title']: true, ['matching-points__action--active']: active }">
      <div class="matching-points__action-title">Выбрать действие с точками учёта:</div>
    </div>

    <div :class="{['matching-points__action']: true, ['matching-points__action--active']: active }">
      <template v-if="!action">
        <div :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--merge']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event, 'merge')"
        >
          <img src="@/assets/icons/point/merge.svg" />
          Объединить
        </div>
        <div :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--replace']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event,'replace')"
        >
          <img src="@/assets/icons/point/replace.svg" />
          Заменить
        </div>
        <div :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--delete']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event,'delete')"
        >
          <img src="@/assets/icons/point/delete.svg" />
          Удалить
        </div>
      </template>
      <template v-else>
        <div v-if="action === 'merge'" :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--cancel']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event,'mergeCancel')"
        >
          <img src="@/assets/icons/point/merge-cancel.svg" />
          Отменить объединение
        </div>
        <div v-else-if="action === 'replace'" :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--cancel']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event,'replaceCancel')"
        >
          <img src="@/assets/icons/point/replace-cancel.svg" />
          Отменить замену
        </div>
        <div v-else-if="action === 'delete'" :class="{
          ['matching-points__action-item']: true,
          ['matching-points__action-item--cancel']: true,
          ['matching-points__action-item--active']: active
        }"
             @click="(event) => updatePoint(event,'deleteCancel')"
        >
          <img src="@/assets/icons/point/delete-cancel.svg" />
          Отменить удаление
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import { EditModeButton, VSelect } from '@/components';

function compareList(first, second, sortDirection) {
  if (first.label < second.label) {
    return sortDirection;
  }
  if (first.label > second.label) {
    return sortDirection * -1;
  }
  return 0;
}

export default {
  name: 'MatchingPoints',
  components: {
    EditModeButton,
    VSelect,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    points: {
      type: Object,
      required: true,
    },
    filterDownloaded: {
      type: String,
      default: '',
    },
    sortDirection: {
      type: Number,
      default: 1,
    },
    action: {
      type: String,
      required: false,
    },
    downloadedPoint: {
      type: String,
      required: false,
    },
  },
  created() {
    this.selectedPoint = this.points.downloadedPointList[0].value;
  },
  data() {
    return {
      selectedPoint: '',
    };
  },
  computed: {
    filteredDownloaded() {
      if (this.filterDownloaded.length === 0) {
        return [...this.points.downloadedPointList].sort((first, second) => compareList(first, second, this.sortDirection));
      }

      return [...this.points.downloadedPointList.filter(
        (item) => item.label.toUpperCase().indexOf(this.filterDownloaded.toUpperCase()) !== -1,
      )].sort((first, second) => compareList(first, second, this.sortDirection));
    },
    downloadedPointName() {
      return this.points.downloadedPointList.filter((item) => item.value === this.downloadedPoint)[0].label;
    },
  },
  methods: {
    selectCurrentHandler(isCheckboxPressed) {
      this.$emit('checked', isCheckboxPressed, this.points.current.id);
    },
    updatePoint(event, action) {
      event.stopPropagation();
      this.$emit('updatePoint', action, this.points, this.selectedPoint);
    },
  },
};
</script>

<style lang="scss" scoped>
.matching-points {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #F5F6F6;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 4px;

  padding: 12px 8px 0 8px;

  &--active {
    padding-bottom: 12px;
  }

  &__point {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    background-color: #f6f6f7;
    padding: 8px 16px 8px 16px;
    border-radius: 4px 0 0 4px;

    &--merge, &--replace, &--delete {
      padding-right: 27px;
    }

    &--right-updated {
      padding-right: 16px;
      padding-left: 27px;
    }

    &--merge {
      background-color: #e0ecfa;
    }

    &--replace {
      background-color: #e2f9de;
    }

    &--delete {
      background-color: #fce6e6;
    }

    &-action-icon {
      position: absolute;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--right {
      border-left: 1px solid #DEE2E6;
      border-radius: 0 4px 4px 0;
    }

    &--merge, &--replace, &--delete {
      border-left: 1px solid #FFFFFF;
    }

    &-navigate {
      display: flex;
      justify-content: flex-end;
    }

    &-select {
      width: 80%;
      background-color: #FFFFFF;
      border-radius: 4px;

      &--unsettled {
        border: 1px solid #EB5757;
      }
    }

    &-check-box {
      font-size: 14px;
    }

    &-unsettled {
      border: 1px solid #EB5757;
      border-radius: 4px;
      padding: 4px 8px;
      width: 80%;
    }
  }

  &__action {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 12px;
    padding: 0 16px 0 16px;
    height: 4px;
    overflow-y: hidden;

    &--active {
      height: 100%;
    }

    &--title {
      justify-content: flex-end;
    }

    &-title {
      background-color: #f6f6f7;
      border: 1px solid #F5F6F6;
      border-radius: 4px;
      padding: 6px 16px;
    }

    &-item {
      display: flex;
      gap: 8px;
      border-radius: 4px;
      padding: 6px 16px;
      cursor: default;

      &--merge {
        background-color: #e0ecfa;
        color: #2f82df;

        &:hover {
          background-color: #bedeff;
        }
      }
      &--replace {
        background-color: #e2f9de;
        color: #269a12;

        &:hover {
          background-color: #b8fcad;
        }
      }
      &--delete {
        background-color: #fce6e6;
        color: #eb5757;

        &:hover {
          background-color: #fcc2c2;
        }
      }

      &--cancel {
        border: 1px solid #DEE2E6;
        border-radius: 4px;

        &:hover {
          background-color: #f6f6f7;
        }
      }

      &--active {
        align-items: center;
        cursor: pointer;
      }

    }
  }
}
</style>
