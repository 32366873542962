<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">
      {{ stepNumber.title() }}
    </p>

    <p :class="$style.stepSubTitle">18 формы</p>

    <template v-for="(attachment, attachmentIndex) in usefulVacationDocument.attachments">
      <div :key="attachmentIndex + '_attachment'" :class="$style.attachment">
        <a :class="$style.fileAttachment"
           :download="`EighteenthForms-${attachmentIndex + 1}.xml`"
           :href="getXmlBlobEighteenthForms(attachment, attachmentIndex)">
          <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
          <span>EighteenthForms-{{ attachmentIndex + 1 }}.xml</span>
        </a>
      </div>
    </template>

    <template v-if="usefulVacationDocument.documentsScan.length > 0">
      <p :class="$style.stepSubTitle">Подтверждающий документ</p>

      <div v-for="(file, index) in usefulVacationDocument.documentsScan" :key="index + '_scan'" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="file.name" :href="fileUrl(file)">
          <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40" />
          <span>{{ file.name }}</span>
        </a>
      </div>
    </template>

    <div :class="$style.submit">
      <horizontal-progress-bar v-if="isWaitingSubmit" />
      <submit-task-details :disabled="isWaitingSubmit" title="Загрузить" @submit="onSubmit" />
    </div>

  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import generateXml from 'lib/storage/connection-application/data';
import { getFileUrl } from 'lib/utils/files';
import { XmlMd5 } from 'lib/utils/md5';
import { generateEighteenFormsWithWorker } from 'utils/xml';
import dayjs from 'dayjs';

export default {
  name: 'DownloadReport',
  components: {
    SubmitTaskDetails: () => import('organisms/tasks/SubmitTaskDetails'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
  },
  props: {
    stepNumber: Object,
    usefulVacationDocument: Object,
  },
  data() {
    return {
      isWaitingSubmit: false,
      xmlEighteenthForms: [],
      xml: [],
    };
  },
  computed: {
    documentCompanyType() {
      const accountingPeriod = dayjs(this.usefulVacationDocument.AccountingPeriod, 'YYYY-MM').year();
      if (accountingPeriod > 2022) return 'EighteenthFormsV2';
      return 'EighteenthForms';
    },
    ...mapGetters('user', ['getUserType']),
    ...mapGetters('dataLists', [
      'voltageClassList',
      'typeAccountingList',
      'typeAccountingListLong',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
      'tariffCategoryTypeList',
      'tariffCategoryTypeListNumber',
      'consumerCategoryList',
      'subscriberStateList',
      'meterStateList',
      'appliedTariffList',
    ]),
  },
  methods: {
    async onSubmit() {
      this.isWaitingSubmit = true;

      if (this.usefulVacationDocument.ConsumerType === 'Company') {
        for (let i = 0; i < this.xmlEighteenthForms.length; i++) {
          this.xmlEighteenthForms[i] = await Promise.resolve(this.xmlEighteenthForms[i]);
        }
      }

      try {
        const results = [];
        for (const [index, xmlEighteenthForm] of this.xmlEighteenthForms.entries()) {
          if (this.usefulVacationDocument.documentsScan.length === 0) {
            results[index] = await this.documentUpload({ xml: xmlEighteenthForm });
          } else {
            const xmlScan = generateXml.DocumentScan(
              this.usefulVacationDocument.documentsScan,
              XmlMd5(xmlEighteenthForm),
              this.usefulVacationDocument.ConsumerType === 'Company' ? this.documentCompanyType : 'EighteenthFormsIndividuals',
              [this.usefulVacationDocument.signatory],
            );

            results[index] = await this.submitManyDocuments({ xmls: [xmlEighteenthForm, xmlScan] });
          }
        }

        if (results.some((result) => !result)) {
          this.isWaitingSubmit = false;
          return;
        }

        setTimeout(() => {
          // TODO возможно, нужно ждать изменения состояния
          this.setWorkflows({ userType: this.getUserType });
          this.isWaitingSubmit = false;
          this.$emit('complete', true);
          this.$emit('close');
        }, 2000);

      } catch (error) {
        const errorMessage = error.message.split(': ')[2];
        this.vueShowNotification(
          'Ошибка при загрузке 18х форм',
          `<p style="color: red">${errorMessage}</p>`,
        );
        this.isWaitingSubmit = false;
      }
    },
    getXmlBlobEighteenthForms(attachment, attachmentIndex) {
      const documentSignature = !this.usefulVacationDocument.isDisplayDocumentScan
        ? this.usefulVacationDocument.signatory : null;
      if (this.usefulVacationDocument.ConsumerType === 'Company') {
        this.xmlEighteenthForms[attachmentIndex] = generateEighteenFormsWithWorker(
          attachment,
          this.voltageClassList,
          this.typeAccountingList,
          this.tariffCategoryTypeListNumber,
          this.subscriberStateList,
          this.meterStateList,
          this.appliedTariffList,
          this.usefulVacationDocument.AccountingPeriod,
          this.typeAccountingListLong,
          this.consumerCategoryList,
          this.usefulVacationDocument.UtilityInfo,
          this.usefulVacationDocument.Retailer,
          documentSignature,
        );
        return this.generateBlob(this.xmlEighteenthForms[attachmentIndex]);
      } else if (this.usefulVacationDocument.ConsumerType === 'Individual') {
        this.xmlEighteenthForms[attachmentIndex] = generateXml.EighteenthFormsIndividual(
          attachment.attachment,
          this.voltageClassList,
          this.typeAccountingList,
          this.tariffCategoryTypeList,
          this.usefulVacationDocument.AccountingPeriod,
          this.typeAccountingListLong,
          this.usefulVacationDocument.UtilityInfo,
          this.usefulVacationDocument.Retailer,
          documentSignature,
        );
        const blob = new Blob([this.xmlEighteenthForms[attachmentIndex]], { type: 'text/xml' });
        return window.URL.createObjectURL(blob);
      }
    },
    generateBlob(xml) {
      xml.then((data) => this.xml = data);
      const blob = new Blob([this.xml], { type: 'text/xml' });
      return window.URL.createObjectURL(blob);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    ...mapActions('cabinet', ['addTestTask', 'clearTestTask', 'setWorkflows']),
    ...mapActions('document', ['documentUpload', 'submitManyDocuments', 'getWordInCaseAction', 'getPersonInCaseAction']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 848px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 24px auto 0

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 22px
    color: #4C4E51

  .stepSubTitle
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 500
    font-size: 18px
    white-space: nowrap
    color: #4C4E51

.submit
  margin-top: 44px

.attachment
  +base-subtitle
  display: flex
  justify-content: space-between

  span
    text-decoration: underline

.fileAttachment
  display: flex
  align-items: center

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.errorText
  +small-text
  margin: 4px
  color: red

</style>
