<template>
  <dialog-template v-model="visible" collapse @closed="onClose">
    <v-dialog-header
      :subtitle="'Заявка от ' + createDate"
      :title="data.title"
    />
    <div :class="$style.content">
      <spoiler-preview v-model="spoiler.tariffObject" :class="$style.currentTariff">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header title="Действующий тариф">
            <template slot="icon">
              <chevron-up v-if="spoiler.tariffObject" />
              <chevron-down v-else />
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.object">
            <p :class="$style.titleAddress">{{ tariffApplication.ServiceLocationName }}</p>

            <div :class="$style.prop">
              <span>Лицевой счёт:</span>
              <p>№&nbsp;{{ tariffApplication.BusinessAccount }}</p>
            </div>

            <div :class="$style.prop">
              <span>Адрес:</span>
              <p>{{ tariffApplication.ObjectLocation }}</p>
            </div>

            <div :class="$style.prop">
              <span>Группа населения:</span>
              <p>{{ tariffCategory[tariffApplication.currentConsumerCategory].title }}</p>
            </div>

            <p :class="$style.title">{{ tariffRate[tariffApplication.currentTariff].title }}</p>

            <div :class="$style.tariffHeader">
              <p :class="$style.titleTariff">Цены указаны в ₽ за кВт ⋅ ч<br />(с учётом НДС)</p>
              <p>I полугодие</p>
              <p>II полугодие</p>
            </div>


            <template v-if="tariffApplication.currentTariff === 'MULTI_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Пиковая зона T1<br /><span>(07:00–10:00; 17:00–21:00)</span></p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Ночная зона Т2<br /><span>(23:00 - 07:00)</span></p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T2')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T2')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Полупиковая зона Т3<br /><span>(10:00 – 17:00; 21:00 – 23:00)</span></p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T3')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T3')
                  }}</p>
              </div>
            </template>

            <template v-if="tariffApplication.currentTariff === 'DOUBLE_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Дневная зона T1<br />(пиковая и
                  полупиковая)<br /><span>(07:00-23:00)</span></p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Ночная зона Т2<br /><span>(23:00 - 07:00)</span></p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T2')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T2')
                  }}</p>
              </div>
            </template>

            <template v-if="tariffApplication.currentTariff === 'SINGLE_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">&nbsp;</p>
                <p>
                  {{ firstHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
                <p>
                  {{ secondHalfTariffValue(tariffApplication.currentConsumerCategory, tariffApplication.currentTariff, 'T1')
                  }}</p>
              </div>
            </template>

          </div>
        </template>
      </spoiler-preview>

      <!--      <spoiler-preview v-model="spoiler.tariff" :class="$style.currentTariff">
              <div slot="header" :class="$style.spoilerHeader">
                <spoiler-header title='Полная версия тарифов на электрическую энергию для населения' :small="true">
                  <template slot="icon">
                    <chevron-up v-if="spoiler.tariff" />
                    <chevron-down v-else />
                  </template>
                </spoiler-header>
              </div>

              <template>
                <p :class="$style.documentTitle">Приказ Департамента экономической политики и развития города Москвы «Об установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп потребителей города Москвы»</p>

                <div v-if="tariffDocument.length > 0" :class="$style.reviewDocumentFiles">
                  <p v-for="(file, index) in tariffDocument" :key="index" style="display: flex; justify-content: space-between">
                    <a :href="fileUrl(file)" :role="'tariffDocument[' + index + '].name'" :download="file.name"><span>{{ file.name }}</span></a>
                    <span :class="$style.replace">
                          <a :href="fileUrl(file)" :download="file.name"><img src="~@/assets/icons/file/file_download.svg" alt="" /></a>
                          <a :href="fileUrl(file)" :download="file.name"><span :role="'tariffDocument[' + index + '].replace'">&nbsp;&nbsp;&nbsp;скачать</span></a>
                      </span>

                  </p>
                </div>

                <tariff-form
                  v-model="tariffs"
                  task-document-id="23213"
                  mode="view"
                  :tariffs-data="tariffsData"
                  :dictionary-category="tariffCategory"
                  :dictionary-rate="tariffRate"
                />
              </template>
            </spoiler-preview>-->

      <spoiler-preview v-model="spoiler.newTariffObject" :class="$style.currentTariff">
        <div slot="header" :class="$style.spoilerHeader">
          <spoiler-header title="Выбран новый тариф">
            <template slot="icon">
              <chevron-up v-if="spoiler.tariffObject" />
              <chevron-down v-else />
            </template>
          </spoiler-header>
        </div>

        <template>
          <div :class="$style.object">
            <div :class="$style.prop">
              <span>Группа населения:</span>
              <p>{{ tariffCategory[tariffApplication.newConsumerCategory].title }}</p>
            </div>

            <p :class="$style.title">{{ tariffRate[tariffApplication.newTariff].title }}</p>

            <div :class="$style.tariffHeader">
              <p :class="$style.titleTariff">Цены указаны в ₽ за кВт ⋅ ч<br />(с учётом НДС)</p>
              <p>I полугодие</p>
              <p>II полугодие</p>
            </div>

            <template v-if="tariffApplication.newTariff === 'MULTI_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Пиковая зона T1<br /><span>(07:00–10:00; 17:00–21:00)</span></p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Ночная зона Т2<br /><span>(23:00 - 07:00)</span></p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T2')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T2')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Полупиковая зона Т3<br /><span>(10:00 – 17:00; 21:00 – 23:00)</span></p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T3')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T3')
                  }}</p>
              </div>
            </template>

            <template v-if="tariffApplication.newTariff === 'DOUBLE_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Дневная зона T1<br />(пиковая и
                  полупиковая)<br /><span>(07:00-23:00)</span></p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
              </div>

              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">Ночная зона Т2<br /><span>(23:00 - 07:00)</span></p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T2')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T2')
                  }}</p>
              </div>
            </template>

            <template v-if="tariffApplication.newTariff === 'SINGLE_RATE'">
              <div :class="$style.tariffValues">
                <p :class="$style.titleTariff">&nbsp;</p>
                <p>{{ firstHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
                <p>{{ secondHalfTariffValue(tariffApplication.newConsumerCategory, tariffApplication.newTariff, 'T1')
                  }}</p>
              </div>
            </template>

          </div>
        </template>
      </spoiler-preview>

      <div v-if="tariffDocument.length > 0" :class="$style.currentTariff">
        <p :class="$style.documentTitle">Приложенные документы</p>

        <div :class="$style.reviewDocumentFiles">
          <p v-for="(file, index) in tariffDocument" :key="index" style="display: flex; justify-content: space-between">
            <a :download="file.name" :href="fileUrl(file)"
               :role="'tariffDocument[' + index + '].name'"><span>{{ file.name }}</span></a>
            <span :class="$style.replace">
                      <a :download="file.name" :href="fileUrl(file)"><img alt=""
                                                                          src="~@/assets/icons/file/file_download.svg" /></a>
                      <a :download="file.name" :href="fileUrl(file)"><span
                        :role="'tariffDocument[' + index + '].replace'">&nbsp;&nbsp;&nbsp;скачать</span></a>
                  </span>

          </p>
        </div>
      </div>

      <div :class="$style.reviewBlock">
        <p :class="$style.reviewTitle">Решение по заявлению</p>
        <span v-if="data.isApproved === undefined" :class="$style.reviewLabelProcess">На рассмотрении</span>
        <span v-else-if="data.isApproved" :class="$style.reviewLabelApprove">Принято</span>
        <span v-else :class="$style.reviewLabelReject">Отклонено</span>
      </div>

      <div v-if="reviewDocument.comments" :class="$style.reviewBlock">
        <p>Причина мотивированного отказа и возможные способы решения</p>
        <p :class="$style.reviewText" v-html="comments"></p>
      </div>

      <div v-if="data.isApproved !== undefined && data.isApproved === false" :class="$style.reviewBlock">
        <p :class="$style.retry" @click="retry">Подать новое заявление на смену тарифа</p>
      </div>

    </div>

    <button :class="$style.btn" @click="onClose">ЗАКРЫТЬ</button>
    <horizontal-progress-bar v-if="isWaiting" />
  </dialog-template>
</template>

<script>
import DialogTemplate from 'templates/DialogTemplate';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';
// import TariffForm from 'organisms/tasks/tariffs-update/TariffForm';
import { mapActions, mapGetters } from 'vuex';
import { getCurrentDate } from 'lib/utils/date';
import { getFileUrl } from 'lib/utils/files';
import formatting from 'lib/utils/formatting';
import generateXml from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';

export default {
  name: 'TariffsChanging',
  components: {
    DialogTemplate,
    SpoilerPreview,
    SpoilerHeader,
    // TariffForm,
    VDialogHeader: () => import('@/components/VDialog/VDialogHeader.vue'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    ChevronDown: () => import('atoms/icons/arrows/ChevronDown'),
    ChevronUp: () => import('atoms/icons/arrows/ChevronUp'),
  },
  props: {
    value: Boolean,
    serviceLocation: Object,
    data: Object,
    tariffApplication: Object,
    tariffsData: Object,
    reviewDocument: Object,
    createDate: String,
    tariffDocument: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {

  },
  data() {
    return {
      visible: false,
      isWaiting: false,
      submitError: '',
      displayContent: false,
      spoiler: {
        tariff: false,
        tariffObject: false,
        newTariffObject: true,
      },
      selectedCategory: '',
      selectedRate: '',
      uploadDocs: [],
      additionalUploadDocs: [],
      approveAction: false,
      tariffs: [],
    };
  },
  computed: {
    firstHalfYearTariffs() {
      return this.tariffsData.firstHalfYearTariffs.reduce((map, object) => {
        map[object.consumerCategory] = {
          SINGLE_RATE: object.singleRate,
          DOUBLE_RATE: object.doubleRate,
          MULTI_RATE: object.multiRate,
        };
        return map;
      }, {});
    },
    secondHalfYearTariffs() {
      return this.tariffsData.secondHalfYearTariffs.reduce((map, object) => {
        map[object.consumerCategory] = {
          SINGLE_RATE: object.singleRate,
          DOUBLE_RATE: object.doubleRate,
          MULTI_RATE: object.multiRate,
        };
        return map;
      }, {});
    },
    // TODO Why is it not cached?
    firstHalfTariff() {
      return (serviceLocation, rate) => this.firstHalfTariffValue(serviceLocation.category, serviceLocation.meter[0].tariff, rate);
    },
    firstHalfTariffValue() {
      return (category, tariff, rate) => {
        if (!category) {
          return '';
        }

        const values = this.firstHalfYearTariffs[category][tariff];

        return formatting.currencyFormatted(values[rate]);
      };
    },
    secondHalfTariff() {
      return (serviceLocation, rate) => this.secondHalfTariffValue(serviceLocation.category, serviceLocation.meter[0].tariff, rate);
    },
    secondHalfTariffValue() {
      return (category, tariff, rate) => {
        if (!category) {
          return '';
        }

        const values = this.secondHalfYearTariffs[category][tariff];

        return formatting.currencyFormatted(values[rate]);
      };
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '', '');
    },
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      async handler(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onClose() {
      this.visible = false;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    selectRate(rate) {
      this.selectedRate = rate;
    },
    handleRemoveUploadDocs(file) {
      this.uploadDocs = this.uploadDocs
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveAdditionalUploadDocs(file) {
      this.additionalUploadDocs = this.additionalUploadDocs
        .filter((scan) => scan.source !== file.source);
    },
    async onSubmit() {
      this.submitError = '';
      this.isWaiting = true;

      const documentReviewXml = generateXml.changingTariffApplication(
        getCurrentDate('YYYY-MM-DD'),
        this.serviceLocation,
        this.selectedCategory,
        this.selectedRate,
        Array.prototype.concat.apply([], [this.uploadDocs, this.additionalUploadDocs]),
      );

      try {
        await this.documentUpload({ xml: documentReviewXml });
        this.isWaiting = false;
        this.vueShowNotification(
          '',
          '<p>Заявление о смене тарифа направлено гарантирующему поставщику AO «МЭС». Уведомление о результатах рассмотрения будет выслано в течение 10 рабочих дней.</p>',
        );
        this.onClose();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
        this.isWaiting = false;
      }
    },
    retry() {
      this.$emit('retry');
      this.onClose();
    },
    ...mapActions('document', ['loadDocument', 'actualTariffsByRegion', 'documentUpload']),
  },
};
</script>

<style lang="sass" module>
.btn
  width: 100%
  padding: 8px 16px
  background: #71757A
  color: #fff
  text-transform: uppercase
  font-style: normal
  font-weight: normal
  font-size: 21px
  line-height: 30px

.changingNote
  +base-text
  background-color: rgba(242, 201, 76, 0.25)
  padding: 16px
  max-width: 592px
  margin: 120px auto 0

.content
  width: 100%
  margin-top: 116px

  hr
    background-color: #D5D7DA

  input[type='radio']
    position: absolute

.btn
  width: 100%
  padding: 8px 16px
  background: #71757A
  color: #fff
  text-transform: uppercase
  font-style: normal
  font-weight: normal
  font-size: 21px
  line-height: 30px

.spoilerHeader
  font-size: 16px !important
  line-height: 24px !important
  +questionnaries-container
  margin-bottom: 24px

.spoilerHeader2
  font-size: 16px !important
  line-height: 24px !important


.titleAddress
  +base-card-title
  margin-bottom: 10px

.prop
  display: flex
  margin-bottom: 8px

  span
    +table-text-small
    line-height: 24px
    flex: 0 0 216px

  p
    +table-data-small

.title
  +card-title-small
  margin-top: 24px
  margin-bottom: 16px

.tariffHeader
  margin-bottom: 16px

.tariffValues
  margin-bottom: 4px

.tariffHeader
  display: flex

  .titleTariff
    +small-text
    flex: 0 0 216px

  p
    +table-data-small
    flex: 0 0 80px

.tariffValues
  display: flex

  .titleTariff
    +table-data-small
    flex: 0 0 216px

    span
      +small-text

  p
    +input-text
    flex: 0 0 80px

.selectRate
  .prop
    span
      flex: 0 0 300px

  .tariffHeader
    .titleTariff
      flex: 0 0 300px

  .tariffValues
    .titleTariff
      flex: 0 0 300px

  .tariffHeader
    p
      flex: 0 0 90px

.currentTariff, .selectCategory, .selectRate, .uploadDocuments, .approveBlock, .reviewBlock
  max-width: 560px
  margin: 40px auto 0

.selectCategory
  img
    margin-right: 24px
    margin-left: 8px

.reviewDocumentFiles
  width: 100%
  max-width: 560px
  margin: 0 auto

.reviewDocumentFiles p a
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 16px
  color: #000
  text-decoration: underline

.reviewDocumentFiles p span.replace
  display: flex
  font-family: 'Roboto Condensed'
  color: #4C4E51
  white-space: nowrap
  cursor: pointer
  font-size: 18px
  line-height: 18px

  a
    text-decoration: none

.documentTitle
  +card-title-small
  margin-bottom: 24px

.contentRadioGroup
  display: flex
  flex-direction: column
  color: #141412
  padding: 16px
  cursor: pointer
  margin-bottom: 8px

  p
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 14px
    line-height: 21px
    color: #C1C4C7

  .selectTariffNote
    +small-text
    margin-top: 16px
    display: none

.contentRadioGroup:hover, .rateBlock:hover
  background-color: #F5F6F6

  .selectTariffNote
    display: block

.titleContainer
  display: flex
  align-items: center
  margin-bottom: 4px

.titleRadioGroup
  +table-data-small
  flex-grow: 1

.buttonRadioGroup
  width: 24px
  height: 24px
  position: relative
  background: url('~@/assets/icons/radio/unchecked.svg') no-repeat right center

.control
  opacity: 0
  pointer-events: none

.control:checked
  ~ .image
    filter: none

  ~ .contentRadioGroup, ~ .rateBlock
    cursor: default
    color: #141412
    border: 1px solid #EE6300

    .selectTariffNote
      display: block

    p
      color: #141412

    & .buttonRadioGroup
      background: url('~@/assets/icons/radio/check_circle_orange.svg') no-repeat right center

  ~ .contentRadioGroup:hover
    background-color: #FFFFFF

.titleBlock
  +base-title
  margin-bottom: 24px

.selectRate
  .rateBlock
    padding: 16px
    cursor: pointer

  .buttonRadioGroup
    margin-left: 24px

.rateBlock
  .buttonRadioGroup
    position: absolute
    right: 78px

.uploadNote
  +small-text

.titleAdditionalUploadDocs
  +base-text
  margin-top: 24px

.approveBlock
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 40px

  p
    +card-title-small

  input
    cursor: pointer

.reviewTitle
  +base-card-title
  margin-bottom: 24px

.reviewLabelProcess, .reviewLabelApprove, .reviewLabelReject
  +base-card-title
  line-height: 24px
  box-sizing: border-box
  border-radius: 4px
  padding-left: 8px
  padding-right: 8px

.reviewLabelProcess
  border: 2px solid #979CA2
  color: #979CA2

.reviewLabelApprove
  border: 2px solid #54B687
  color: #54B687

.reviewLabelReject
  border: 2px solid #EB5757
  color: #EB5757

.reviewBlock
  margin-bottom: 40px

.reviewText
  +table-data-base
  color: #EB5757

.retry
  +base-title
  text-transform: uppercase
  text-decoration: underline
  color: #0E0F0F
  line-height: 24px
  text-align: center

</style>
