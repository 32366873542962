import { formatDateToXmlDateFormat } from 'lib/utils/date';
import dayjs from 'dayjs';
import { escapeXml } from 'lib/utils/string';

export function generatePowerOfAttorney(isDigAttorney, data, companyAccount, ownerAccount, ownerUser, user, ownerAuth, auth) {

  if (isDigAttorney) {
    return generatePowerOfAttorneyDig(isDigAttorney, data, companyAccount, ownerAccount, ownerUser, user, ownerAuth, auth);
  }

  return generatePowerOfAttorneyScan(isDigAttorney, data, ownerAccount, user);
}

function generatePowerOfAttorneyDig(isDigAttorney, data, companyAccount, ownerAccount, ownerUser, user, ownerAuth, auth) {
  function formatSnils(snils) {
    return snils.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
  }
  let empowerments = '';
  data.selectedRights.forEach((item) => {
    empowerments += '                ' +
      `<МашПолн КодПолн="${item.value}" НаимПолн="${item.description}"/>\n`;
  });
  function documentCode(docType) {
    switch (docType) {
      case 'PASSPORT_RF':
        return '21';
      case 'RESIDENCE_PERMIT_RF':
        return '12';
      case 'BIRTH_CERTIFICATE':
        return '03';
      default:
        return '91';
    }
  }
  function generateIdentityDocument(identityDocumentInfo) {
    let xml = `                                    <УдЛичнФЛ КодВидДок="${documentCode(identityDocumentInfo?.documentType)}"\n`;
    if (identityDocumentInfo?.series || identityDocumentInfo?.number) {
      xml += `                                              СерНомДок="${[identityDocumentInfo?.series, identityDocumentInfo?.number].filter((item) => item != null).join(' ')}"\n`;
    }
    if (identityDocumentInfo?.issueDate) {
      xml += `                                              ДатаДок="${formatDateToXmlDateFormat(identityDocumentInfo?.issueDate)}"\n`;
    }
    if (identityDocumentInfo?.issuerName) {
      xml += `                                              ВыдДок="${identityDocumentInfo?.issuerName}"\n`;
    }
    if (identityDocumentInfo?.issuerCode) {
      xml += `                                              КодВыдДок="${identityDocumentInfo?.issuerCode}"\n`;
    }
    xml += '/>';
    return xml;
  }

  const docSubject = generateIdentityDocument(ownerAuth.identityDocumentInfo);
  const docObject = generateIdentityDocument(auth.identityDocumentInfo);

  const company = companyAccount.profile;
  return '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="../xsl/ON_EMCHD_1_928_00_01_01_01.xsl" ?>\n' +
    '<Доверенность xmlns="http://energochain.ru/XML/documents/ON_EMCHD_1_928_00_01_01_01/1.0"\n' +
    '              xsi:schemaLocation="http://energochain.ru/XML/documents/ON_EMCHD_1_928_00_01_01_01/1.0 ../xsd/ON_EMCHD_1_928_00_01_01_01.xsd"\n' +
    '              xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '              ВерсФорм="EMCHD_1"\n' +
    '              ПрЭлФорм="00000000"\n' +
    `              ИдФайл="ON_EMCHD_${dayjs().utc().format('YYYYMMDD')}_00000000-0000-0000-0000-000000000000">\n` +
    '    <Документ xmlns="">\n' +
    '        <Довер>\n' +
    '            <СвДов ВидДовер="1"\n' +
    `                   ПрПередов="${data.retrust ? '3' : '1'}"\n` +
    `                   ВнНомДовер="${data.numberAttorney}"\n` +
    '                   НомДовер="00000000-0000-0000-0000-000000000000"\n' +
    `                   ДатаВыдДовер="${formatDateToXmlDateFormat(data.startDate)}"\n` +
    `                   СрокДейст="${formatDateToXmlDateFormat(data.endDate)}">\n` +
    '                <СведСист>Платформа Цифровой Розничный Рынок</СведСист>\n' +
    '            </СвДов>\n' +
    '            <СвДоверит ТипДоверит="1">\n' +
    '                <Доверит>\n' +
    '                    <РосОргДовер ЕИОУК="0" ЕИОФЛ="1" ЕИОИП="0">\n' +
    `                        <СвРосОрг НаимОрг="${escapeXml(company.name)}"\n` +
    `                                  ИННЮЛ="${company.inn}"\n` +
    `                                  КПП="${company.kpp}"\n` +
    `                                  ОГРН="${company.ogrn}">\n` +
    '                        </СвРосОрг>\n' +
    '                        <ЛицоБезДов ПолнЮЛ="1">\n' +
    `                            <СвФЛ ${ownerAuth.inn == null ? '' : `ИННФЛ="${ownerAuth.inn}"`}\n` +
    `                                  СНИЛС="${formatSnils(ownerUser.snils)}">\n` +
    `                                <СведФЛ ДатаРожд="${formatDateToXmlDateFormat(ownerAuth.birthDate)}">\n` +
    `                                    <ФИО Фамилия="${ownerUser.fullName.surname}"\n` +
    `                                         Имя="${ownerUser.fullName.name}"\n` +
    `                                         ${ownerUser.fullName.patronymic == null ? '' : `Отчество="${ownerUser.fullName.patronymic}"`}/>\n` +
    `${docSubject}` +
    '                                </СведФЛ>\n' +
    '                            </СвФЛ>\n' +
    '                        </ЛицоБезДов>\n' +
    '                    </РосОргДовер>\n' +
    '                </Доверит>\n' +
    '            </СвДоверит>\n' +
    '            <СвУпПред ТипПред="3">\n' +
    '                <Пред>\n' +
    `                    <СведФизЛ ИННФЛ="${auth.inn}"\n` +
    `                              СНИЛС="${formatSnils(user.snils)}">\n` +
    `                        <СведФЛ ДатаРожд="${formatDateToXmlDateFormat(auth.birthDate)}">\n` +
    `                            <ФИО Фамилия="${user.fullName.surname}"\n` +
    `                                 Имя="${user.fullName.name}"\n` +
    `                                 ${user.fullName.patronymic == null ? '' : `Отчество="${user.fullName.patronymic}"`}/>\n` +
    `${docObject}` +
    '                        </СведФЛ>\n' +
    '                    </СведФизЛ>\n' +
    '                </Пред>\n' +
    '            </СвУпПред>\n' +
    '            <СвПолн ТипПолн="1" ПрСовмПолн="2">\n' +
                   `${empowerments}` +
    '            </СвПолн>\n' +
    '        </Довер>\n' +
    '    </Документ>\n' +
    '</Доверенность>';
}

function generatePowerOfAttorneyScan(isDigAttorney, data, ownerAccount, user) {
  let permissions = '';
  data.selectedRights.forEach((item) => {
    permissions += `    <Permission>${item.value}</Permission>\n`;
  });

  return (
    '<?xml version="1.0" encoding="utf-8" ?>\n' +
    '<?xml-stylesheet type="text/xsl" href="PowerOfAttorney.xsl" ?>\n' +
    '<PowerOfAttorney\n' +
    '  xmlns="http://energochain.ru/XML/documents/power-of-attorney/1.0"\n' +
    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '  xmlns:xmime="http://www.w3.org/2005/05/xmlmime"\n' +
    '  xsi:schemaLocation="http://energochain.ru/XML/documents/power-of-attorney/1.0 PowerOfAttorney.xsd">\n' +
    `  <Date>${dayjs().utc().format()}</Date>\n` +
    `  <Number>${data.numberAttorney}</Number>\n` +
    `  <GrantedAt>${formatDateToXmlDateFormat(data.issueDate)}</GrantedAt>\n` +
    `  <StartsFrom>${formatDateToXmlDateFormat(data.startDate)}</StartsFrom>\n` +
    `  <ExpiresAt>${formatDateToXmlDateFormat(data.endDate)}</ExpiresAt>\n` +
    `  <AccountId>${ownerAccount.id}</AccountId>\n` +
    `  <UserId>${user.id}</UserId>\n` +
    '  <Permissions>\n' +
    `    ${permissions}\n` +
    '  </Permissions>\n' +
    `  <File xmime:contentType="${data.powerOfAttorneyScan[0].type}" name="${data.powerOfAttorneyScan[0].name}">${data.powerOfAttorneyScan[0].code}</File>\n` +
    '</PowerOfAttorney>'
  );
}
