export function addPresenceZones(inn, address, desc) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PresenceZonesChangingOrder\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0 PresenceZones.xsd">\n' +
    '   <AddPresenceZonesChangeList>\n' +
    '<AddPresenceZone>\n' +
    `<CompanyInn>${inn}</CompanyInn>\n` +
    `<EquipmentDescription>${desc}</EquipmentDescription>\n` +
    `<PresenceAddress>${address}</PresenceAddress>\n` +
    '</AddPresenceZone>\n' +
    '</AddPresenceZonesChangeList>\n' +
    '</PresenceZonesChangingOrder>'
  );
}

export function deletePresenceZones(id) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PresenceZonesChangingOrder\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0 PresenceZones.xsd">\n' +
    '   <DeletePresenceZonesChangeList>\n' +
    '<DeletePresenceZone>\n' +
    `<PresenceZoneId>${id}</PresenceZoneId>\n` +
    '</DeletePresenceZone>\n' +
    '</DeletePresenceZonesChangeList>\n' +
    '</PresenceZonesChangingOrder>'
  );
}
export function editPresenceZones(id, address, desc, inn) {
  return (
    '<?xml version="1.0" encoding="UTF-8" ?>\n' +
    '<PresenceZonesChangingOrder\n' +
    '        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n' +
    '        xmlns="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0"\n' +
    '        xsi:schemaLocation="http://energochain.ru/XML/documents/presence-zones-changing-order/1.0 PresenceZones.xsd">\n' +
    '   <UpdatePresenceZonesChangeList>\n' +
    '<UpdatePresenceZone>\n' +
    `<PresenceZoneId>${id}</PresenceZoneId>\n` +
    `<EquipmentDescription>${desc}</EquipmentDescription>\n` +
    `<PresenceAddress>${address}</PresenceAddress>\n` +
    `<CompanyInn>${inn}</CompanyInn>\n` +
    '</UpdatePresenceZone>\n' +
    '</UpdatePresenceZonesChangeList>\n' +
    '</PresenceZonesChangingOrder>'
  );
}
