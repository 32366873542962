import moment from 'moment';

function isEmail(email) {
  const emailPattern = /^\S+@\S+\.\S{2,}$/;

  return emailPattern.test(email);
}

function isPhone(phone) {
  const phonePattern = /(^\+?[87]\d{10}$)|(^[9]\d{9}$)/;

  return phonePattern.test(phone.replace(RegExp('[-]?[(]?[)]?[ ]?', 'g'), ''));
}

function required(val) {
  return isNotNull(val) && isNotUndefined(val) && isNotEmpty(val);
}

function isNull(val) {
  return val === null;
}

function isNotNull(val) {
  return !isNull(val);
}

function isUndefined(val) {
  return val === undefined;
}

function isNotUndefined(val) {
  return !isUndefined(val);
}

function isEmpty(val) {
  return isEmptyString(val) || isNan(val) || isEmptyArray(val) || isEmptyObject(val);
}

function isNotEmpty(val) {
  return !isEmpty(val);
}

function isNan(val) {
  return isNumber(val) && isNaN(val);
}

function isNotNan(val) {
  return isNumber(val) && !isNaN(val);
}

function isString(val) {
  return typeof val === 'string';
}

function isNotString(val) {
  return !isString(val);
}

function isEmptyString(val) {
  return isString(val) && val === '';
}

function isNotEmptyString(val) {
  return isString(val) && val.length > 0;
}

function isEmptyArray(val) {
  return isArray(val) && val.length === 0;
}

function isNotEmptyArray(val) {
  return isArray(val) && val.length > 0;
}

function isFixedLengthArray(val, length) {
  return isNotEmptyArray(val) && val.length === length;
}

function isObject(val) {
  return isNotNull(val) && isNotUndefined(val) && isNotString(val) && isNotArray(val) && isNotNumber(val);
}

function isNotObject(val) {
  return !isObject(val);
}

function isEmptyObject(val) {
  return isObject(val) && Object.keys(val).length === 0;
}

function isNotEmptyObject(val) {
  return isObject(val) && Object.keys(val).length > 0;
}

function isArray(val) {
  return Array.isArray(val);
}

function isNotArray(val) {
  return !isArray(val);
}

function isObjectWithFields(object, fields) {
  return isObject(object) && fields.every((field) => Object.prototype.hasOwnProperty.call(object, field));
}

function isNumber(val) {
  return typeof val === 'number';
}

function isNotNumber(val) {
  return !isNumber(val);
}

function isPositiveInt(val) {
  return isNumber(val) && Number.isInteger(val) && val > 0;
}

function isPositiveFloat(val) {
  return isNumber(val) && val % 1 !== 0 && val > 0;
}

function isPositiveNumber(val) {
  return isPositiveFloat(val) || isPositiveInt(val);
}

export function isStringIncludingSubstring(val, substring) {
  return val.indexOf(substring) !== -1;
}

export function isStringNotIncludingSubstring(val, substring) {
  return val.indexOf(substring) === -1;
}

function isObjectValuesValidByCallback(object, callback) {
  if (isUndefined(callback)) {
    throw new Error('Pass the callback function as the second argument');
  }

  return isObject(object) && Object.values(object).every(callback);
}

function dateInPast(value) {
  if (isEmptyString(value)) {
    return false;
  }

  const date = moment(value, 'DD.MM.YYYY');

  if (!date) {
    return false;
  }

  return moment().isSameOrAfter(date);
}

function dateInFuture(value) {
  if (isEmptyString(value)) {
    return false;
  }

  const date = moment(value, 'DD.MM.YYYY');

  if (!date) {
    return false;
  }

  return moment().isSameOrBefore(date);
}

function validateInn(inn, error) {
  let result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'Поле обязательно для заполнения';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 10 или 12 цифр';
  } else {
    const checkDigit = function(inn, coefficients) {
      let n = 0;
      for (const i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10);
    };
    switch (inn.length) {
      case 10: {
        const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      }
      case 12: {
        const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true;
        }
        break;
      }
    }
    if (!result) {
      error.code = 4;
      error.message = 'Неправильное контрольное число';
    }
  }
  return result;
}

function validateOrgn(orgn, error) {
  if ((orgn.length === 13 || orgn.length === 15) && !isNaN(parseFloat(orgn)) && !isNaN(orgn - 0)) {
    return true;
  }
  error.message = 'ОГРН должен состоять из 13 или 15 цифр';
  return false;
}

function validateKpp(kpp, error) {
  if (kpp.length === 9 && !isNaN(parseFloat(kpp)) && !isNaN(kpp - 0)) {
    return true;
  }
  error.message = 'КПП должен состоять из 9 цифр';
  return false;
}

function validateBik(bik, error) {
  if (bik.length === 9 && !isNaN(parseFloat(bik)) && !isNaN(bik - 0)) {
    return true;
  }
  error.message = 'БИК должен состоять из 9 цифр';
  return false;
}

function validateCompanyAccount(value, error) {
  if (value.length === 20 && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'Счёт должен состоять из 20 цифр';
  return false;
}

function validateOktmo(value, error) {
  if ((value.length === 8 || value.length === 11) && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'ОКТМО должен состоять из 8 или 11 цифр';
  return false;
}

function validateOkpo(value, error) {
  if (!value) {
    error.message = 'ОКПО должен состоять из 8 или 10 цифр';
    return false;
  }

  if ((value.length === 8 || value.length === 10) && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'ОКПО должен состоять из 8 или 10 цифр';
  return false;
}

function validateTenDigitPhone(value, error) {
  if (value.length === 10 && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'Номер телефона без +7 или 8 должен состоять из 10 цифр';
  return false;
}

function validateOkved(value, error) {
  const regExpOkvedCode = /^(\d\d)(\.\d(\d(\.\d{1,2})?)?)?$/;
  const isOkved = typeof value === 'string' &&
    regExpOkvedCode.test(value) === true;
  if (isOkved) {
    return true;
  }
  error.message = 'Неверный формат';
  return false;
}

function isNotEmptyItem(item) {
  return item !== undefined && item !== null && item.length > 0;
}

function isEmptyItem(item) {
  return item === undefined || item === null || item.length === 0;
}

function isUndefinedOrNull(value) {
  return value === undefined || value === null;
}

function validateSnils(value, error) {
  if (value.length === 11 && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'СНИЛС должен состоять из 20 цифр';
  return false;
}

function landCadastralNumber(value, error) {
  const val = value.replace(/\D/g, '');
  if (val.length === 13 || val.length === 15) {
    return true;
  }
  if (error) {
    error.message = 'Кадастровый номер должен состоять из 13 цифр или 15 цифр';
  }
  return false;
}

function validatePassportSeries(value, error) {
  if (value.length === 4 && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'Серия паспорта должна состоять из 4 цифр';
  return false;
}

function validatePassportNumber(value, error) {
  if (value.length === 6 && !isNaN(parseFloat(value)) && !isNaN(value - 0)) {
    return true;
  }
  error.message = 'Номер паспорта должен состоять из 6 цифр';
  return false;
}

function validatePassportSubdivisionCode(value, error) {
  const regExpSubdivisionCode = /^\d{3}-\d{3}$/;
  const isSubdivisionCode = typeof value === 'string' &&
    regExpSubdivisionCode.test(value) === true;
  if (isSubdivisionCode) {
    return true;
  }
  error.message = 'Неверный формат';
  return false;
}

function validateDateAfter(value, date, dateFormat, error) {
  const formattedDate = moment(value, dateFormat);
  const formattedBeforeDate = moment(date, dateFormat);
  if (formattedDate.isBefore(formattedBeforeDate)) {
    console.log('true');
    return true;
  }
  error.message = `Дата должна быть не позднее ${date}`;
  return false;
}

const validationRules = {
  required,
  isNull,
  isNotNull,
  isUndefined,
  isNotUndefined,
  isEmpty,
  isNotEmpty,
  isEmptyString,
  isNotEmptyString,
  isEmptyArray,
  isNotEmptyArray,
  isFixedLengthArray,
  isEmptyObject,
  isNotEmptyObject,
  isArray,
  isNotArray,
  isEmail,
  isPhone,
  isString,
  isNotString,
  isObject,
  isNotObject,
  isObjectWithFields,
  isNumber,
  isNotNumber,
  isNan,
  isNotNan,
  isPositiveInt,
  isPositiveFloat,
  isPositiveNumber,
  isStringIncludingSubstring,
  isStringNotIncludingSubstring,
  isObjectValuesValidByCallback,
  dateInPast,
  dateInFuture,
  validateInn,
  validateOrgn,
  validateKpp,
  isNotEmptyItem,
  isEmptyItem,
  isUndefinedOrNull,
  validateBik,
  validateCompanyAccount,
  validateOktmo,
  validateOkpo,
  validateOkved,
  validateSnils,
  validateTenDigitPhone,
  validatePassportSeries,
  validatePassportNumber,
  validatePassportSubdivisionCode,
  validateDateAfter,
  landCadastralNumber,
};

export default validationRules;
