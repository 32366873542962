<template>
  <div
    :class="{
      [$style.divider]: true,
      [$style[`divider__${direction}`]]: true,
    }"
  ></div>
</template>

<script>
export default {
  name: 'VDivider',
  props: {
    direction: { type: String, default: 'horizontal' },
  },
};
</script>

<style lang="scss" module>
.divider {
  width: 100%;
  height: 100%;

  &__horizontal {
    border-bottom: 1px solid #c1c4c7;
  }

  &__vertical {
    border-left: 1px solid #c1c4c7;
  }
}
</style>
