import { render, staticRenderFns } from "./XmlContractLastChange.vue?vue&type=template&id=351a6658&scoped=true"
import script from "./XmlContractLastChange.vue?vue&type=script&lang=js"
export * from "./XmlContractLastChange.vue?vue&type=script&lang=js"
import style0 from "./XmlContractLastChange.vue?vue&type=style&index=0&id=351a6658&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351a6658",
  null
  
)

export default component.exports