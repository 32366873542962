<template>
  <Process/>
</template>

<script>
import Process from 'components/Processes/Process7/Process1';

export default {
  name: 'Installation',
  components: { Process },
};
</script>

<style scoped>

</style>
