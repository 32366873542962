var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',{attrs:{"title":"Список запрещенных паролей"}},[_c('div',{staticClass:"container"},[_c('v-table-new',{attrs:{"action-block-items":_vm.actionBlockItems,"loading":_vm.loading,"on-filter-change":_vm.onFilterChange,"on-load-all-values":_vm.loadAllFiltered,"on-pagination-change":_vm.onPaginationChange,"page-out":_vm.prohibitedPasswords.pageOut,"template":_vm.template,"excel-report-file-name":"Список запрещенных паролей"},on:{"delete":_vm.onDelete,"edit":_vm.onEdit},model:{value:(_vm.transformedPasswords),callback:function ($$v) {_vm.transformedPasswords=$$v},expression:"transformedPasswords"}}),(_vm.isDisplayAddDialog)?_c('modal',{staticClass:"delete-modal",attrs:{"body-style":{
        padding: '24px 0',
      },"header-style":{
        display: 'flex',
        justifyContent: 'center',
      },"is-backdrop":true,"is-btn-close-visible":false,"modal-style":{
        borderRadius: '16px',
        padding: '24px',
      }},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.passwordId.length === 0 && _vm.deletePasswordId.length === 0)?_c('span',[_vm._v("Добавление записи")]):(_vm.passwordId.length !== 0)?_c('span',[_vm._v("Редактирование записи")]):(_vm.deletePasswordId.length !== 0)?_c('span',[_vm._v("Удаление записи")]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"directory__fields"},[_c('v-input',{attrs:{"disabled":_vm.deletePasswordId.length !== 0},model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}})],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.isWaitingSubmit)?_c('horizontal-progress-bar',{staticClass:"directory__progress-bar"}):_vm._e(),(_vm.submitError.length > 0)?_c('div',{staticClass:"errorText"},[_vm._v(" "+_vm._s(_vm.submitError)+" ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-button',{attrs:{"variant":"outlined"},on:{"click":_vm.onCancelAction}},[_vm._v(" Отменить ")]),_c('v-button',{attrs:{"variant":_vm.deletePasswordId.length !== 0 ? 'outlined-red' : undefined},on:{"click":_vm.onActionRecord}},[(_vm.passwordId.length === 0 && _vm.deletePasswordId.length === 0)?_c('span',[_vm._v("Добавить")]):(_vm.passwordId.length !== 0)?_c('span',[_vm._v("Сохранить")]):(_vm.deletePasswordId.length !== 0)?_c('span',[_vm._v("Удалить")]):_vm._e()])],1)]},proxy:true}],null,false,3513008743)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }