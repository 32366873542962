<template>
  <div :class="$style.container">
    <div :class="$style.leftColumn">
      <div :class="$style.content">
        <div :class="$style.logo">
          <advanced-logo />
        </div>

        <slot />
      </div>
    </div>
    <div :class="$style.rightColumn">
      <img src="@/assets/images/login-image.svg" alt="" />
    </div>
  </div>
</template>

<script>
import AdvancedLogo from 'atoms/icons/logos/AdvancedLogo';

export default {
  name: 'LoginTemplate',
  components: {
    AdvancedLogo,
  },
};
</script>

<style lang="sass" module>
  .container
    width: 100%
    height: 100%
    display: flex

  .leftColumn
    overflow: auto
    padding: 16px
    flex-grow: 7

    &::-webkit-scrollbar
      width: 0

  .rightColumn
    display: flex
    align-items: center
    padding: 16px
    background: linear-gradient(180deg, #CDE9FE 0%, #599EDD 100%)
    flex-grow: 5

    img
      max-width: 373px

    @media (max-width: 750px)
      display: none

  .leftColumn, .rightColumn
    width: 100%

  .content
    width: 100%
    height: 100%
    max-width: 628px
    margin: 0 auto
    display: grid
    display: -ms-grid
    -ms-grid-columns: 1fr
    -ms-grid-rows: 1fr 16px 6fr 16px 1fr
    grid-template-rows: 1fr 6fr 1fr
    grid-gap: 16px
    padding-left: 84px
    padding-right: 72px

  .logo
    margin-top: 34px
    display: flex
    -ms-grid-row: 1
    -ms-grid-column: 1
</style>
