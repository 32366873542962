<template>
  <cabinet-points :route="route" :addition-kind="additionKind" :export-to-excel-action="exportToExcelAction" />
</template>

<script>
import CabinetPoints from './CabinetPoints';

export default {
  name: 'DeliveryPoints',
  components: {
    CabinetPoints,
  },
  props: {
    additionKind: {
      type: String,
      required: true,
    },
    exportToExcelAction: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      required: false,
    },
  },
};
</script>

