<template>
  <div :class="{['dialog-container']: true, [$style['dialog-container']]: true}">
    <div :class="{
      [$style['content']]: true,
      [$style['closeButtonMargin']]: closeButtonMargin,
    }">
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
    </div>
    <horizontal-progress-bar v-if="isLoading" />
  </div>
</template>

<script>
export default {
  name: 'VDialogHeader',
  components: {
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    closeButtonMargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.dialog-container {
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  z-index: 1006;
  padding: 8px;
}

.content {
  h1 {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: #000;
  }

  h2 {
    margin: 16px 0 0 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #71757a;
  }
}

.closeButtonMargin {
  margin-right: 58px;
}
</style>
