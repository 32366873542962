import { apolloClient } from '@/api/graphql';
import {
  ACT_INDICATORS,
  BALANCE_INDICATORS,
  CONTRACT_PAYMENTS, DOCUMENTS_BY_FILTER_PAGED,
  GET_BORDER_DEVICE_ACTS_FOR_ACCOUNTING,
  GET_CONTRACT_PATTERNS,
  GET_ELECTRICITY_BALANCE_BY_ID,
  GET_ELECTRICITY_BALANCES, GET_PROHIBITED_PASSWORD_PAGE,
  GET_RETAILER_FOR_SELECTOR, GET_SUPPLIER_FOR_SELECTOR_BY_FILTER,
  GET_SUPPLIERS_BY_FILTER,
  GET_UTILITY_FOR_SELECTOR,
  GET_WORKFLOWS,
  GET_WORKFLOWS_BY_CORRELATION_ID,
  LOSS_ACTS, MEASUREMENTS_INDICATORS,
  PREFERENTIAL_CONNECTION_PRICE,
  REVOKED_CERTIFICATES,
  SERVICE_ACTS,
  SERVICE_AND_LOSS_ACT_INDICATORS,
  TELEGRAM_BOT_INFO,
} from '@/api/graphql/queries';
import { CREATE_PAYMENT_URL, GENERATE_INVOICE } from '@/api/graphql/mutations';

async function setWorkflows({ commit, rootState, rootGetters }, payload) {
  try {
    console.log('start loaded workflows', rootState.user.token);
    const { data: { workflows } } = await apolloClient.query({
      query: GET_WORKFLOWS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('loaded workflows', workflows);
    const currentAccount = rootGetters['user/getAccount'];
    commit('SET_WORKFLOWS', { workflows: workflows, userType: payload.userType, currentAccount: currentAccount });

    return workflows;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('setWorkflows::catch', error);
    throw error;
  }
}

async function setWorkflowsByCorrelationId({ commit, rootState }, payload) {
  try {
    console.log('start loaded workflows', rootState.user.token);

    const { data: { workflowsByCorrelationId } } = await apolloClient.query({
      query: GET_WORKFLOWS_BY_CORRELATION_ID,
      variables: payload,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    commit('SET_WORKFLOWS_BY_CORRELATION_ID', {
      workflowsByCorrelationId: workflowsByCorrelationId,
      userType: payload.userType,
    });

  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('setWorkflows::catch', error);
    throw error;
  }
}

async function getContractPatterns(state, payload) {
  const actionMapper = ({ type, action, contractType }) => {
    if (type === 'additionalAgreement') {
      return {
        // TODO check type for contractType === ELECTRICITY_PURCHASE_CONTRACT
        type: 'ELECTRICITY_TRANSMISSION_ADDITIONAL',
      };
    }

    // TODO replace ELECTRICITY_TRANSMISSION to ELECTRICITY_TRANSMISSION_CONTRACT in ContractPatternTypeEnum
    return {
      type: contractType === 'ELECTRICITY_TRANSMISSION_CONTRACT' ? 'ELECTRICITY_TRANSMISSION' : contractType,
      onlyAllSidesOnPlatform: action === 'create',
    };
  };

  try {
    const { data: { contractPatterns } } = await apolloClient.query({
      query: GET_CONTRACT_PATTERNS,
      variables: { filter: actionMapper(payload) },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });

    console.log('contract patterns', contractPatterns);

    return contractPatterns;
  } catch (error) {
    console.log('setContractType::catch', error);
    throw error;
  }
}

async function getAllPatterns({ commit, rootState }) {
  try {
    const { data: { contractPatterns } } = await apolloClient.query({
      query: GET_CONTRACT_PATTERNS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('getAllPatterns', contractPatterns);

    return contractPatterns;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('setContractType::catch', error);
    throw error;
  }
}
async function getProhibitedPasswordPage(state, payload) {
  try {
    const { data: { prohibitedPasswordsByFilterPagedSorted } } = await apolloClient.query({
      query: GET_PROHIBITED_PASSWORD_PAGE,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${state.rootState.user.token}`,
        },
      },
    });
    console.log('getProhibitedPasswordPage', prohibitedPasswordsByFilterPagedSorted);
    return prohibitedPasswordsByFilterPagedSorted;
  } catch (error) {
    console.log('getProhibitedPasswordPage::catch', error);
    throw error;
  }
}

async function createPaymentUrl({ rootState, commit }, payload) {
  try {
    const { data: { createPaymentUrl } } = await apolloClient.mutate({
      mutation: CREATE_PAYMENT_URL,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('create Payment Url', createPaymentUrl.value);

    return createPaymentUrl.value;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('create payment url::catch', error);
    throw error;
  }
}

async function generateAccountRefillInvoice({ rootState }, payload) {
  try {
    const { data: { generateAccountRefillInvoice } } = await apolloClient.mutate({
      mutation: GENERATE_INVOICE,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('generateAccountRefillInvoice', generateAccountRefillInvoice);

    return generateAccountRefillInvoice;
  } catch (error) {
    console.log('generateAccountRefillInvoice::catch', error);
    throw error;
  }
}


async function getPreferentialConnectionPrice({ commit, rootState }, payload) {
  try {
    const { data: { connectionPreferentialPrice } } = await apolloClient.query({
      query: PREFERENTIAL_CONNECTION_PRICE,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('get preferential connection price', connectionPreferentialPrice);
    return connectionPreferentialPrice;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('get preferential connection price::catch', error);
    throw error;
  }
}

function addTestTask({ commit }, task) {
  commit('ADD_TASK', task);
}

function clearTestTask({ commit }) {
  commit('CLEAR_TEST_TASK');
}

async function loadBalances({ commit, rootState }, filter) {
  try {
    const { data: { electricityBalances } } = await apolloClient.query({
      query: GET_ELECTRICITY_BALANCES,
      variables: {
        ...filter,
        filter: {
          ...filter.filter,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('balances', electricityBalances);
    return electricityBalances;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('balances::catch', error);
    throw error;
  }
}

async function loadLossActs({ rootState, commit }) {
  try {
    const { data: { lossActs } } = await apolloClient.query({
      query: LOSS_ACTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('lossActs', lossActs);
    return lossActs;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('lossActs::catch', error);
    throw error;
  }
}

async function loadBorderDeviceActsForAccounting({ rootState, commit }, payload) {
  try {
    const { data: { borderDeviceActsForAccounting } } = await apolloClient.query({
      query: GET_BORDER_DEVICE_ACTS_FOR_ACCOUNTING,
      variables: payload,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('borderDeviceActsForAccounting', borderDeviceActsForAccounting);
    return borderDeviceActsForAccounting;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('borderDeviceActsForAccounting::catch', error);
    throw error;
  }
}

async function loadServiceActs({ rootState, commit }) {
  try {
    const { data: { serviceActs } } = await apolloClient.query({
      query: SERVICE_ACTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('serviceActs', serviceActs);
    return serviceActs;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('serviceActs::catch', error);
    throw error;
  }
}

function clearTasks({ commit }) {
  commit('CLEAR_TASKS');
}

async function loadBalanceIndicators({ rootState, commit }, payload) {
  try {
    const { data: { balanceIndicators } } = await apolloClient.query({
      query: BALANCE_INDICATORS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return balanceIndicators;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadBalanceIndicators::catch', error);
    throw error;
  }
}
async function loadMeasurementsIndicators({ rootState, commit }, payload) {
  try {
    const { data: { measurementsIndicators } } = await apolloClient.query({
      query: MEASUREMENTS_INDICATORS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return measurementsIndicators;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadMeasurementsIndicators::catch', error);
    throw error;
  }
}

async function loadElectricityBalance({ rootState, commit }, payload) {
  try {
    const { data: { electricityBalance } } = await apolloClient.query({
      query: GET_ELECTRICITY_BALANCE_BY_ID,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return electricityBalance;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadActIndicators::catch', error);
    throw error;
  }
}


async function loadActIndicators({ rootState, commit }, payload) {
  try {
    const { data: { integralActIndicators } } = await apolloClient.query({
      query: ACT_INDICATORS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return integralActIndicators;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadActIndicators::catch', error);
    throw error;
  }
}

async function loadServiceAndLossActIndicators({ rootState, commit }, payload) {
  try {
    const { data: { serviceAndLossActIndicators } } = await apolloClient.query({
      query: SERVICE_AND_LOSS_ACT_INDICATORS,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          Authorization: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return serviceAndLossActIndicators;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('serviceAndLossActIndicators::catch', error);
    throw error;
  }
}


async function getUtilitiesForSelector({ rootState, commit }) {
  try {
    const { data: { suppliers } } = await apolloClient.query({
      query: GET_UTILITY_FOR_SELECTOR,
      variables: {},
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return suppliers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getSuppliersByFilter::catch', error);
    throw error;
  }
}

async function getSupplierForSelectorByFilter({ rootState, commit }, payload) {
  try {
    const { data: { suppliers } } = await apolloClient.query({
      query: GET_SUPPLIER_FOR_SELECTOR_BY_FILTER,
      variables: { filter: { ...payload } },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return suppliers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getOdkForSelector::catch', error);
    throw error;
  }
}

async function getSupplierForSelectorByFilterNotErrorNotification({ rootState }, payload) {
  try {
    const { data: { suppliers } } = await apolloClient.query({
      query: GET_SUPPLIER_FOR_SELECTOR_BY_FILTER,
      variables: { filter: { ...payload } },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return suppliers;
  } catch (error) {
    console.log('getOdkForSelector::catch', error);
    throw error;
  }
}

async function getRetailersForSelector({ rootState, commit }) {
  try {
    const { data: { suppliers } } = await apolloClient.query({
      query: GET_RETAILER_FOR_SELECTOR,
      variables: {},
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return suppliers;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getSuppliersByFilter::catch', error);
    throw error;
  }
}


async function getSuppliersByFilter({ rootState, commit }, payload) {
  try {
    const { data: { UTILITY, RETAILER, ODK } } = await apolloClient.query({
      query: GET_SUPPLIERS_BY_FILTER,
      variables: {
        filterUtility: { kind: 'UTILITY', ...payload },
        filterRetailer: { kind: 'RETAILER', ...payload },
        filterOdk: { kind: 'ODK', ...payload },
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return [...UTILITY, ...RETAILER, ...ODK];
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getSuppliersByFilter::catch', error);
    throw error;
  }
}

async function getTelegramBotInfo({ rootState, commit }) {
  try {
    const { data: { telegramBotInfo } } = await apolloClient.query({
      query: TELEGRAM_BOT_INFO,
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return telegramBotInfo;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('telegramBotInfo::catch', error);
    throw error;
  }
}

async function getRevokedCertificates({ rootState, commit }) {
  try {
    const { data: { certificates } } = await apolloClient.query({
      query: REVOKED_CERTIFICATES,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return certificates;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('getRevokedCertificates::catch', error);
    throw error;
  }
}

async function getContractPayments({ rootState, commit }) {
  try {
    const { data: { contractPayments } } = await apolloClient.query({
      query: CONTRACT_PAYMENTS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });
    console.log('contractPayments', contractPayments);
    return contractPayments;
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log(error.message);
    throw error;
  }
}

async function documentsByFilterPaged({ rootState, commit }, payload) {
  try {
    const {
      data,
    } = await apolloClient.query({
      query: DOCUMENTS_BY_FILTER_PAGED,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    return { ...data.documentsByFilterPaged };
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('documentsByFilterPaged::catch', error);
    throw error;
  }
}


export default {
  setWorkflows,
  createPaymentUrl,
  addTestTask,
  clearTestTask,
  getContractPatterns,
  loadBalances,
  loadLossActs,
  getProhibitedPasswordPage,
  loadServiceActs,
  clearTasks,
  loadBalanceIndicators,
  loadMeasurementsIndicators,
  loadActIndicators,
  getPreferentialConnectionPrice,
  loadElectricityBalance,
  loadServiceAndLossActIndicators,
  loadBorderDeviceActsForAccounting,
  getSuppliersByFilter,
  setWorkflowsByCorrelationId,
  getAllPatterns,
  getTelegramBotInfo,
  getRevokedCertificates,
  getUtilitiesForSelector,
  getRetailersForSelector,
  getContractPayments,
  getSupplierForSelectorByFilter,
  getSupplierForSelectorByFilterNotErrorNotification,
  documentsByFilterPaged,
  generateAccountRefillInvoice,
};
