<template>
  <button
    class="scroll-button"
    @click="scrollToTop"
  >
    <img src="@/assets/icons/basic/scroll-button.svg">
  </button>
</template>

<script>
export default {
  props: {
    getScrollTargetRef: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    isObjEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    scrollToTop() {
      if (this.isObjEmpty(this.getScrollTargetRef)) {
        this.$nextTick(() => {
          this.getScrollTargetRef().scrollTo({ top: 0, behavior: 'smooth' });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-button {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 9999;
  border: none;
  cursor: pointer;
  background: inherit;
}
</style>
