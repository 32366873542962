<template>
  <div :class="$style.container" @click="openDetails">
    <div :class="$style.content">

      <div :class="$style.moreIcon">
        <more-vert-icon />
      </div>

      <div :class="$style.title" :role="'task[' + index + '].title'">
        {{ task.cardTitle}}
      </div>

      <div v-if="task.actDate" :class="$style.caption">
        <p :role="'task[' + index + '].caption'">от {{ task.actDate}} г.</p>
      </div>

      <div v-if="task.rejected" :class="$style.footer">
        <div :class="$style.error">
          <p>ОТКЛОНЁН</p>
        </div>
      </div>

      <div v-else :class="$style.footer">
        <div v-if="!task.completed" :class="$style.process">
          <p>{{ task.actionTitle}}</p>
        </div>
        <div v-else :class="$style.expairedDate">
          <p>{{ task.completedTitle}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MoreVertIcon from 'atoms/icons/basic/MoreVert';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Card',
  components: {
    MoreVertIcon,
  },
  props: {
    task: Object,
    index: Number,
  },
  computed: {
    status() {
      if (this.task.status === 'reject') {
        return { title: 'ОТКЛОНЕНА', isError: true };
      }
      return { title: 'НА ПРОВЕКРКЕ', isError: false };
    },
  },
  methods: {
    async openDetails() {
      // TODO get integralAct by using loadDocumentView(this.task.documentId)
      const integralActs = await this.filterDocumentViews({
        kind: 'INTEGRAL_ACT', from: '1900-01-01T00:00:00Z', to: '2900-01-01T00:00:00Z',
      });

      integralActs.some((item) => {
        if (item.documentId === this.task.documentId) {
          this.setIntegralAct(item);
          return true;
        }
        return false;
      });

      this.$router.push(`${this.task.link}${this.task.documentId}`);
    },
    ...mapMutations('integralAct', ['setIntegralAct']),
    ...mapActions('document', ['filterDocumentViews']),
  },
};
</script>

<style lang="sass" module>
  .container
    width: 385px
    position: relative
    background-color: #fff
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    margin-bottom: 16px
    margin-right: 16px

    &:hover
      cursor: pointer

  .content
    display: flex
    flex-direction: column
    align-items: space-between
    padding: 16px 32px 16px 16px

  .moreIcon
    position: absolute
    top: 8px
    right: 8px

  .title
    +card-task-title
    margin: 0 0 8px

  .subtitle
    margin: 0 0 8px
    font-family: 'Roboto Condensed'
    font-weight: 400
    font-size: 18px
    line-height: 24px
    color: #0E0F0F
    letter-spacing: 0.01em

  .caption
    margin: 0 0 8px
    p
      +card-task-caption


  .process,
  .expairedDate,
  .process,
  .error,
    p
      +card-task-status
      text-align: right

  .process p
    color: #2F82DF

  .expairedDate p
    color: #ACB0B5

  .error p
    color: #EB5757

  .footer
    margin-top: 36px
</style>
