<template>
  <div :class="$style.stepContainer">
    <div :class="$style.titleContainer">
      <h2 :class="$style.title">
        <step-counter :step-number="stepNumber" :total-steps="totalSteps"/>
        <span>Загрузите скан или фото документов</span>
      </h2>
    </div>

    <div :class="$style.noteContainer">
      <p>Весь текст должен быть читаемым.</p>
    </div>

    <div :class="$style.noteContainer">
      <p><a href="/docs/Перечень-энергопринимающих-устройств.docx" target="_blank">Перечень и мощность энергопринимающих
        устройств, которые могут быть присоединены к устройствам противоаварийной и режимной автоматики</a></p><br>
      <p><a href="/docs/Договор купли продажи.docx" target="_blank">Договор купли-продажи
        электрической энергии</a></p>
    </div>

    <div :class="$style.blockContent">
      <p :class="$style.subtitle">
        <span>1.</span>
        Документ, удостоверяющий личность
        <span v-if="consumer.documentType.value === 'OTHER'">: {{ consumer.otherDocumentType }}</span> *
      </p>
      <p v-if="consumer.documentType.value === 'PASSPORT_RF'" :class="$style.description">
        Страницы с фото и пропиской
      </p>
      <div>

        <div v-if="documentScans.passportScans && documentScans.passportScans.length">
          <file-list-preview
            :list="documentScans.passportScans"
            action
            role="passportScans"
            @delete="handleRemovePassportScan"
          />
        </div>

        <c-uploading
          v-model="documentScans.passportScans"
          :error="errors.passportScans"
          :validation="$validate.isNotEmptyArray"
          role="passportScans"
        />
      </div>
    </div>

    <div v-if="isAdditionalOwner" :class="$style.blockContent">
      <div style="display: flex">
        <div :class="$style.subtitle">{{ 1 + isLegalStep }}.</div>
        <div :class="$style.subtitleDiv">Доверенность, согласие на подключение собственника или владельцев долевой собственности и согласие на обработку персональных данных *
        </div>
      </div>

      <div>
        <div v-if="documentScans.powerOfAttorneyScans && documentScans.powerOfAttorneyScans.length">
          <file-list-preview
            :list="documentScans.powerOfAttorneyScans"
            action
            role="powerOfAttorneyScans"
            @delete="handleRemovePowerOfAttorneyScans"
          />
        </div>

        <c-uploading
          v-model="documentScans.powerOfAttorneyScans"
          :error="errors.powerOfAttorneyScans"
          :validation="$validate.isNotEmptyArray"
          role="powerOfAttorneyScans"
        />
      </div>
    </div>

    <div :class="$style.blockContent">
      <div style="display: flex">
        <div :class="$style.subtitle">{{ (1 + isLegalStep + ownerStep) }}.</div>
        <div :class="$style.subtitleDiv">Документы, подтверждающие право собственности, или иное предусмотренное законом
          основание *
        </div>
      </div>

      <div>
        <div v-if="documentScans.confirmationDocumentScan && documentScans.confirmationDocumentScan.length">
          <file-list-preview
            :list="documentScans.confirmationDocumentScan"
            action
            role="confirmationDocumentScan"
            @delete="handleRemoveConfirmationDocumentScan"
          />
        </div>

        <c-uploading
          v-model="documentScans.confirmationDocumentScan"
          :error="errors.confirmationDocumentScan"
          :validation="$validate.isNotEmptyArray"
          role="confirmationDocumentScan"
        />
      </div>
    </div>

    <div :class="$style.blockContent">
      <p :class="$style.subtitle">
        <span>{{ (2 + isLegalStep + ownerStep) }}.</span>
        План расположения энергопринимающих устройств *
      </p>

      <p :class="$style.textHelper">
        <span>Ситуационный план, кадастровый план и т.п., если есть.</span>
        <tooltip-helper offset="6px" placement="top">
          <div :class="$style.tooltip">
            <p>Схема расположения объектов потребления электричества на вашем участке.</p>
          </div>
        </tooltip-helper>
      </p>

      <div>
        <div v-if="documentScans.objectShemeScan && documentScans.objectShemeScan.length">
          <file-list-preview
            :list="documentScans.objectShemeScan"
            action
            role="objectShemeScan"
            @delete="handleRemoveObjectShemeScan"
          />
        </div>

        <c-uploading
          v-model="documentScans.objectShemeScan"
          :error="errors.objectShemeScan"
          :validation="$validate.isNotEmptyArray"
          role="objectShemeScan"
        />
      </div>
    </div>

    <div :class="$style.blockContent">
      <p :class="$style.subtitle">
        <span>{{ (3 + isLegalStep + ownerStep) }}.</span>
        Прочие документы
      </p>

      <div>
        <div v-if="documentScans.otherScans && documentScans.otherScans.length">
          <file-list-preview
              :list="documentScans.otherScans"
              action
              @delete="handleRemoveOtherScan"
          />
        </div>

        <c-uploading
            v-model="documentScans.otherScans"
            :error="{error: false}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileListPreview from 'molecules/upload/form/FileListPreview';
import StepCounter from '../components/StepCounter';

export default {
  name: 'DocumentScans',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    TooltipHelper: () => import('molecules/tooltip/TooltipHelper'),
    FileListPreview,
    StepCounter,
  },
  props: {
    documentScans: {
      type: Object,
      required: true,
    },
    consumer: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    isAdditionalOwner: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    isLegal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteAction: {
        title: '',
        name: 'delete',
      },
    };
  },
  computed: {
    ownerStep() {
      if (this.isAdditionalOwner) {
        return 1;
      }
      return 0;
    },
    isLegalStep() {
      if (this.isLegal) return 0;
      return 1;
    },
  },
  methods: {
    handleRemovePassportScan(file) {
      this.documentScans.passportScans = this.documentScans.passportScans
        .filter((scan) => scan.source !== file.source);
    },
    handleRemovePowerOfAttorneyScans(file) {
      this.documentScans.powerOfAttorneyScans = this.documentScans.powerOfAttorneyScans
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveConfirmationDocumentScan(file) {
      this.documentScans.confirmationDocumentScan = this.documentScans.confirmationDocumentScan
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveObjectShemeScan(file) {
      this.documentScans.objectShemeScan = this.documentScans.objectShemeScan
        .filter((scan) => scan.source !== file.source);
    },
    handleRemoveOtherScan(file) {
      this.documentScans.otherScans = this.documentScans.otherScans
        .filter((scan) => scan.source !== file.source);
    },
  },
};
</script>

<style lang="sass" module>
.stepContainer
  +questionnaries-container
  +step-spacing

.titleContainer
  +base-spacing

.title
  display: flex

.grid2Column
  +grid-2-column

.blockContent
  +base-spacing

.noteContainer
  +base-spacing

  p
    +small-text

  a
    +base-link

.subtitleDiv
  margin-left: 12px
  +base-subtitle

.subtitle
  +base-subtitle


  span
    padding-right: 8px

.description
  +small-text
  margin-top: 4px
  padding-left: 24px

  a
    +base-link

.textHelper
  +small-text
  margin-top: 4px
  padding-left: 24px
  display: flex

  span
    margin-right: 10px

.previewContainer
  display: flex
  align-items: center
  padding: 0 24px
  +medium-spacing

.previewLink
  display: flex
  align-items: center

.previewImage
  width: 80px
  height: 80px
  background-position: center
  background-repeat: no-repeat
  background-size: cover

.previewName
  font-family: 'Roboto Condensed'
  font-weight: 400
  font-size: 18px
  line-height: 24px
  color: #0E0F0F
  text-decoration: underline
  margin: 0 8px 0 16px

.previewReset
  width: 24px
  height: 24px
  background-image: url('~icons/basic/trash.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer

.tooltip
  width: 320px

  p
    font-family: Roboto
    font-weight: 400
    font-size: 12px
    line-height: 18px
    color: #000


</style>
