<template>
  <div>
    <p v-if='label' :class="$style['file-attachment__label']">{{ label }}</p>
    <div :class="$style['file-attachment__attachment']">
      <a
        :class="$style['file-attachment__file']"
        :download="`${name}.xml`"
        :href="xmlBlob"
        @mouseleave="tooltip = false"
        @mouseover="tooltip = true"
      >
        <img
          alt=""
          height="80"
          src="~@/assets/icons/file/attachment.svg"
          width="80"
        />
        <span>{{ shortName }}</span>
        <div v-show="tooltip" :class="$style['file-attachment__file__tooltip']">
          {{ name + '.xml' }}
        </div>
      </a>
    </div>
    <div :class="$style.action">
      <a @click="requestPdfView">Открыть просмотр</a>
      <a download @click="requestPdfDownload">Скачать PDF</a>
      <a :download="`${name}.xml`" :href="xmlBlob">Скачать xml</a>
      <a v-if="signature" :download="`${name}.sig`" :href="sigBlob">Скачать подпись</a>
    </div>
    <horizontal-progress-bar v-if="isFileLoading||isPdfLoading"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { checkSuccessfullyPdfOpen } from 'utils/notification/notification.js';
import actualEnvContentPatch from '@/utils/env';
import { b64toBlob } from 'lib/utils/pdf';

export default {
  name: 'VFileAttachment',
  components: {
    HorizontalProgressBar: () => import('@/atoms/common/HorizontalProgressBar'),
  },
  props: {
    documentId: { type: String, required: true },
    label: { type: String, default: '' },
  },
  created() {
    this.getUrlXml();
  },
  data() {
    return {
      isFileLoading: false,
      isShowWarning: false,
      isPdfLoading: false,
      isPdfDownloadRequested: false,
      isPdfViewRequested: false,
      urlXml: null,
      signature: null,
      pdfView: null,
      pdfDoc: null,
      name: '',
      tooltip: false,
      requestsCount: 0,
      pdfStatus: 'NOT_REQUESTED',
    };
  },
  computed: {
    xmlBlob() {
      if (!this.urlXml) {
        return '';
      }
      const blob = new Blob([this.urlXml], { type: 'text/xml' });
      return window.URL.createObjectURL(blob);
    },
    sigBlob() {
      if (!this.signature) {
        return '';
      }
      const blob = b64toBlob(this.signature, 'application/octet-stream');
      return window.URL.createObjectURL(blob);
    },
    shortName() {
      if (!this.name)
        return `${this.documentId}.xml`;
      return this.name.split(' ').length > 5
        ? `${this.name.split(' ').slice(0, 6).join(' ')}... .xml`
        : `${this.name}.xml`;
    },
    ...mapGetters('user', ['getToken']),
  },
  watch: {
    isPdfLoading(newVal) {
      if (newVal) {
        this.pollDocumentStatus();
      }
    },
    requestsCount() {
      setTimeout(async () => {
        await this.pollDocumentStatus();
      }, 1000);
    },
    pdfView(newVal) {
      if (!newVal) {
        return;
      }
      if (this.isPdfViewRequested) {
        this.viewPdf();
      }
      if (this.isPdfDownloadRequested) {
        this.downloadPdf();
      }
    },
    isShowWarning(newVal) {
      if (newVal) {
        this.showWarning();
      }
    },
  },
  methods: {
    showWarning() {
      this.vueShowNotification('Предупреждение', '<p>Формирование документа выполняется в фоновом режиме и может занимать до 15 минут. Просим подождать.</p>');
    },
    async getUrlXml() {
      this.isFileLoading = true;
      try {
        const response = await this.loadDocument(this.documentId);
        this.name = response.title ?? response.id;
        this.name = this.name.replace(/['"]/g, '');
        this.urlXml = response.xml;
        this.signature = response.signature;
      } catch (e) {
        console.log(e.message);
      } finally {
        this.isFileLoading = false;
      }
    },
    async getDocumentStatus() {
      this.pdfStatus = await this.getDocumentStatusByUrl(
        `${actualEnvContentPatch}/v1/document/${this.documentId}/submit/pdf`,
      );
    },
    async onPdfCompleted() {
      this.pdfDoc = await this.getDocumentByUrl(
        `${actualEnvContentPatch}/v1/document/${this.documentId}/download`,
      );
      this.pdfView = URL.createObjectURL(this.pdfDoc);
      this.isPdfLoading = false;
    },
    async requestPdfView() {
      this.isPdfViewRequested = true;
      if (!this.pdfView) this.isPdfLoading = true;
      else await this.viewPdf();
    },
    async requestPdfDownload() {
      this.isPdfDownloadRequested = true;
      if (!this.pdfDoc) this.isPdfLoading = true;
      else await this.downloadPdf();
    },
    onPdfLoadingError(message) {
      this.isPdfLoading = false;
      this.isShowWarning = false;
      this.isPdfViewRequested = false;
      this.isPdfDownloadRequested = false;
      console.log(message);
      alert('Ошибка загрузки файла');
    },
    async pollDocumentStatus() {
      try {
        await this.getDocumentStatus();
        switch (this.pdfStatus) {
          case 'WAITING':
            this.isShowWarning = true;
            this.requestsCount++;
            break;
          case 'COMPLETE':
            await this.onPdfCompleted();
            break;
          case 'ERROR':
            this.onPdfLoadingError('Backend returned status ERROR');
            break;
          default:
            this.onPdfLoadingError(`Backend returned unknown status ${this.pdfStatus}`);
            break;
        }
      } catch (e) {
        this.onPdfLoadingError(e.message);
      }
    },
    viewPdf() {
      try {
        checkSuccessfullyPdfOpen(this.pdfView);
        this.isPdfViewRequested = false;
      } catch (e) {
        this.onPdfLoadingError(e.message);
      }
    },
    downloadPdf() {
      const link = document.createElement('a');
      link.href = this.pdfView;
      link.setAttribute('download', `${this.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.isPdfDownloadRequested = false;
    },
    ...mapActions('document', ['loadDocument', 'getDocumentByUrl', 'getDocumentStatusByUrl']),
  },
};
</script>

<style lang="scss" module>
.file-attachment {
  &__label {
    margin-top: 32px;
    margin-bottom: 4px;

    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #0e0f0f;
    letter-spacing: 0.01em;
  }

  &__attachment {
    display: flex;
    justify-content: space-between;

    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000;

    span {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__file {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 12px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    color: #000;

    &__tooltip {
      position: absolute;
      bottom: -55px;
      text-decoration: none;
      background-color: #f5f1f1;
      box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
      font-size: 12px;
      padding: 0 6px;
      border-radius: 5px;
      white-space: nowrap;
      z-index: 1000;
    }
  }
}

.dialog-content {
  height: 100%;
  padding: 16px 24px;
  overflow: auto;
  overflow-x: hidden;
}

.action {
  margin-top: 8px;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    margin-right: 8px;
    cursor: pointer;
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
