<template>
  <inner-page v-if="contract"
              :can-history-be-collapsed="true"
              :data-width="'100%'"
              :history="history"
              :history-spacing="0"
              :steps="[`Карточка договора`]"
              history-title="История изменений документа"
              home="Договоры"
              @back="goBack"
              @goHome="goHome"
  >
    <div class="contract-form__header">
      <div class="contract-form__header__title-wrp">
        <h1 class="contract-form__header__title">
          {{ pageTitle }}
        </h1>
      </div>
      <div class="contract-form__header__indicator">
        <v-simple-indicator :color="contractStatus.color" :label="contractStatus.text"/>
      </div>
    </div>
    <v-divider/>
    <form-columns
      :column1="headerColumns.column1"
      :column2="headerColumns.column2"
      :title="contract.title"
      class="columns"
    />
    <v-divider/>
    <form-chapter title="Полное наименование:">
        <span class="selected-rights">
      {{ contract.title }} №{{ contract.contractInfo.number }} от {{ convertedContractDate }}
    </span>
    </form-chapter>
    <v-table-new v-model="contractInfoTableData" :pagination-show="false" :template="contractInfoTableTemplate"/>
    <v-table-new v-model="contractCardInfoTableData" :pagination-show="false"
                 :template="contractCardInfoTableTemplate"/>
    <form-chapter>
      <div class="spoiler-wrp">
        <v-spoiler-double v-for="(side, idx) in contract.sides" :key="idx" :is-open="false"
                          :summary="accountSpoilerSummaryForSide(side)" :value="side">
          <company-info :company-info="side" :non-editable="true"/>
        </v-spoiler-double>
      </div>
    </form-chapter>
    <form-chapter v-if="contract.payments?.length" title="Авансовые и основной платежи:">
      <div class="contract-form__formula-list">
        <div class="contract-form__formula">
          <div class="contract-form__formula__img">
            <img height="40px" src="@/assets/images/payments-formula-2.png"/>
          </div>
          <div class="contract-form__formula__text">
            <p class="contract-form__formula__title">Расчёт окончательного платежа, где:</p>
            <p>Sк — окончательный платёж.</p>
            <p>Sт — стоимость по акту.</p>
            <p>Sп — стоимость по акту прошлого периода.</p>
            <p>X — размер авансового платежа в процентах.</p>
          </div>
        </div>
      </div>
      <v-table-new v-model="payments" :pagination-show="false" :template="paymentDatesTableTemplate"/>
    </form-chapter>
    <form-chapter title="Договор с актуальными изменениями">
      <DocumentsList
        :disable-title="true"
        :documents="documents"
      />
    </form-chapter>
    <div slot="buttons" class="buttons">
      <button class="buttons__save" @click="goTask">
        {{ actionButtonLabel }}
      </button>
      <button class="buttons__print">
        <print/>
        <span>Печать</span>
      </button>
    </div>
  </inner-page>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { InnerPage, VDivider, VTableNew } from '@/components';
import { CompanyInfo } from 'components/CompanyInfo';
import { compareDbDatetimeFormatDesc, convertDbFormat } from 'lib/utils/date';
import FormChapter from 'views/private/Contract/FormChapter.vue';
import FormColumns from 'views/private/Contract/FormColumns.vue';
import DocumentsList from 'components/DocumentsList/DocumentsList.vue';
import Print from 'atoms/icons/action/Print.vue';
import VSpoilerDouble from 'components/VSpoilerDouble/VSpoilerDouble.vue';
import { convertBooleanToText } from 'lib/utils/formatXmlType';
import VSimpleIndicator from 'components/VSimpleIndicator/VSimpleIndicator.vue';

export default {
  name: 'Contract',
  components: {
    VSimpleIndicator,
    VSpoilerDouble,
    CompanyInfo,
    Print,
    DocumentsList,
    FormColumns,
    FormChapter,
    VTableNew,
    InnerPage,
    VDivider,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      contract: undefined,
      actVerification: undefined,
      indicatorColors: {
        unknown: '#867777',
        true: '#13CE5ECC',
        false: '#D00B0B',
      },
      history: [],
    };
  },
  computed: {
    ...mapGetters('user', ['getAccount', 'getUser']),
    ...mapGetters('dictionary', ['documentSideNames']),
    ...mapGetters('dataLists', ['paymentKindList', 'paymentTariffsList']),
    convertedContractDate() {
      return convertDbFormat(this.contract.contractInfo.date);
    },
    contractInfoTableData() {
      return [{
        number: this.contract.contractInfo.number,
        date: convertDbFormat(this.contract.contractInfo.date),
        effectiveDate: convertDbFormat(this.contract.contractInfo.effectiveDate),
        expirationDate: convertDbFormat(this.contract.contractInfo.expirationDate),
        prolongation: convertBooleanToText(this.contract.contractInfo.prolongation),
        region: this.contract.contractInfo.region,
      }];
    },
    contractCardInfoTableData() {
      return [{
        id: this.contract.documentId,
        createdAt: convertDbFormat(this.contract.createdAt),
        updatedAt: convertDbFormat(this.contract.updatedAt),
      }];
    },
    headerColumns() {
      return {
        column1: [
          {
            label: 'Стороны:',
            value: this.contract.sides.map((it) => it.name).join(' — '),
          },
          {
            label: 'Номер:',
            value: this.contract.contractInfo.number,
          }],
        column2: [
          {
            label: '                ',
            value: '                      ',
          },
          {
            label: 'Дата заключения:',
            value: this.convertedContractDate,
          },
        ],
      };
    },
    payments() {
      return this.contract.payments.map((payment) => ({
        ...payment,
        tariffName: this.paymentTariffsList.find((it) => it.value === payment.tariffType).label,
        kindName: this.paymentKindList.find((it) => it.value === payment.paymentType).label,
        isWithoutDateReadable: convertBooleanToText(payment.withoutDate),
      }));
    },
    contractInfoTableTemplate() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'Номер',
            key: 'number',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '250px',
              padding: '16px',
            },
            tdStyle: {
              maxWidth: '250px',
              padding: '16px',
            },
          },
          {
            label: 'Дата заключения',
            key: 'date',
            thStyle: {
              maxWidth: '200px',
              padding: '16px',
              borderTop: borderStyle,
            },
            tdStyle: {
              maxWidth: '200px',
              padding: '16px',
            },
          },
          {
            label: 'Дата вступления в силу',
            key: 'effectiveDate',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',
            },
          },
          {
            label: 'Дата истечения',
            key: 'expirationDate',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',

            },
            tdStyle: {
              maxWidth: '200px',
              padding: '16px',
            },
          },
          {
            label: 'Автоматическая пролонгация',
            key: 'prolongation',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '120px',
              padding: '16px',

            },
            tdStyle: {
              maxWidth: '120px',
              padding: '16px',
            },
          },
          {
            label: 'Место заключения',
            key: 'region',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',
            },
            tdStyle: {
              maxWidth: '200px',
              padding: '16px',
            },
          },
        ],
      };
    },
    contractCardInfoTableTemplate() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: 'ID документа',
            key: 'id',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '250px',
              padding: '16px',
            },
            tdStyle: {
              maxWidth: '250px',
              padding: '16px',
            },
          },
          {
            label: 'Дата создания карточки документа',
            key: 'createdAt',
            thStyle: {
              maxWidth: '200px',
              padding: '16px',
              borderTop: borderStyle,
            },
            tdStyle: {
              maxWidth: '200px',
              padding: '16px',
            },
          },
          {
            label: 'Дата последнего изменения карточки документа',
            key: 'updatedAt',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '16px',
            },
          },
        ],
      };
    },
    paymentDatesTableTemplate() {
      const borderStyle = '1px solid #cccccc';
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '50px',
              padding: '6px',
              borderTop: borderStyle,
              borderLeft: borderStyle,
            },
            tdStyle: {
              width: '50px',
              padding: '6px',
              borderLeft: borderStyle,
            },
          },
          {
            label: 'Тариф',
            key: 'tariffName',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '100px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '125px',
              padding: '6px',
            },
          },
          {
            label: 'От',
            key: 'from.name',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
          },
          {
            label: 'К',
            key: 'to.name',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '150px',
              padding: '6px',
            },
          },
          {
            label: 'Вид платежа',
            key: 'kindName',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '6px',
            },
            tdStyle: {
              borderTop: borderStyle,
              maxWidth: '200px',
              padding: '6px',
            },
          },
          {
            label: 'Число месяца платежа',
            key: 'day',
            type: 'number',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '55px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '55px',
              padding: '6px',
            },
          }, {
            label: 'Включительно',
            key: 'isWithoutDateReadable',
            thStyle: {
              borderTop: borderStyle,
              maxWidth: '50px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '50px',
              padding: '6px',
            },
          },
          {
            label: 'Процент',
            key: 'percent',
            type: 'number',
            thStyle: {
              borderTop: borderStyle,
              borderRight: borderStyle,
              maxWidth: '60px',
              padding: '6px',
            },
            tdStyle: {
              maxWidth: '60px',
              borderRight: borderStyle,
              padding: '6px',
            },
          },
        ],
      };
    },
    contractStatus() {
      switch (this.contract.status) {
        case 'DRAFT':
          return {
            color: this.indicatorColors.true,
            text: 'На согласовании',
          };
        case 'APPROVED_BY_INITIATOR':
          return {
            color: this.indicatorColors.true,
            text: 'На подписании',
          };
        case 'SIGNED_BY_INITIATOR':
          return {
            color: this.indicatorColors.true,
            text: 'На согласовании',
          };
        case 'REJECTED':
          return {
            color: this.indicatorColors.false,
            text: 'Отклонен',
          };
        case 'APPROVED':
          return {
            color: this.indicatorColors.true,
            text: 'На подписании',
          };
        case 'SIGNED':
          return {
            color: this.indicatorColors.true,
            text: 'Подписан',
          };
        case 'DELETED':
          return {
            color: this.indicatorColors.false,
            text: 'Удалён',
          };
        case 'WAITING_VERIFICATION_ACT':
          return {
            color: this.indicatorColors.true,
            text: 'Ожидает формирования акта верификации',
          };
        case 'WAITING_UPDATE_INFO':
          return {
            color: this.indicatorColors.true,
            text: 'Ожидает ввода информации',
          };
        case 'NULLIFIED':
          return {
            color: this.indicatorColors.false,
            text: 'Аннулирован',
          };
        case 'DISAGREEMENT':
          return {
            color: this.indicatorColors.false,
            text: 'Разногласия',
          };
        default:
          return {
            color: this.indicatorColors.unknown,
            text: 'Неизвестный статус',
          };
      }
    },
    documents() {
      const documents = [{
        name: this.contract.title,
        date: convertDbFormat(this.contract.updatedAt),
        title: this.contract.title,
        documentId: this.contract.documentId,
      }];
      if (this.actVerification) {
        documents.push({
          name: this.actVerification.title,
          date: convertDbFormat(this.actVerification.updatedAt),
          title: this.actVerification.title,
          documentId: this.actVerification.documentId,
        });
      }
      return documents;
    },
    actionButtonLabel() {
      return 'Перейти к задаче';
    },
    pageTitle() {
      if (this.contract?.contractType === 'ELECTRICITY_PURCHASE_CONTRACT') {
        if (!this.contract?.sourceContractId) return 'Договор купли-продажи электрической энергии (мощности)';
        return 'Дополнительное соглашение к договору купли-продажи электрической энергии (мощности)';
      }
      if (!this.contract?.sourceContractId) return 'Договор оказания услуг по передаче электрической энергии';
      return 'Дополнительное соглашение к договору оказания услуг по передаче электрической энергии';
    },
  },
  watch: {
    '$route.params': 'loadData',
  },
  methods: {
    accountSideName(value) {
      return this.documentSideNames[value] ?? 'Неизвестная сторона';
    },
    accountIndicatorColor(value) {
      if (value) return this.indicatorColors.true;
      if (!value && value !== undefined && value !== null) return this.indicatorColors.false;
      return this.indicatorColors.unknown;
    },
    accountSpoilerSummaryForSide(side) {
      return {
        first: side.name,
        second: this.accountSideName(side.sideName),
      };
    },
    accountDocumentName(number, date, isAdditional) {
      if (isAdditional) return `Дополнительное соглашение №${number} от ${date}`;
      return `Основной договор №${number} от ${date}`;
    },
    accountOnClick(id) {
      if (id !== this.contract.id) return () => {
        this.$router.push({
          name: 'cabinet.contract',
          params: {
            id,
          },
        });
      };
    },
    accountHistory(document) {
      return document.documentHistory?.map((it) => ({
        ...it,
        documentName: this.accountDocumentName(document.contractInfo.number, convertDbFormat(document.contractInfo.date), document.sourceContractId),
        onClick: this.accountOnClick(document.id),
      })) ?? [];
    },
    async loadData() {
      if (!this.$route.params.id) {
        alert('Отсутствует id договора');
        this.$router.back();
        return;
      }

      try {
        this.contract = await this.getTransmissionContract(this.$route.params.id);
        this.currentSideStatus = this.contract.sides.find((it) => it.accountId === this.getAccount.id)?.status;

        if (this.contract.actVerification?.id) {
          this.actVerification = await this.getDomainDocument(this.contract.actVerification.id);
          this.documentId = this.actVerification.documentId;
          this.label = 'Акт верификации';
          this.powerOfAttorneyListBase = this.actVerification.powerOfAttorney;
        } else {
          this.documentId = this.contract.documentId;
          this.label = 'Документ';
          this.powerOfAttorneyListBase = this.contract.powerOfAttorney;
        }
      } catch (error) {
        console.log('loadData', error);
      }
      this.history = this.accountHistory(this.contract);
      this.contract.additionalContracts?.forEach((additional) => {
        this.history.push(...this.accountHistory(additional));
      });
      this.history = this.history.sort((first, second) => compareDbDatetimeFormatDesc(first, second));
      this.contract.sides.forEach((it) => {
        it.companyInfo = {
          ...it.companyInfo,
          ...it.companyInfo.bankRequisites[0],
        };
      });
    },
    goHome() {
      if (this.contract.contractType === 'ELECTRICITY_PURCHASE_CONTRACT') {
        this.$router.push('/cabinet/electricity-purchase-contracts');
      } else {
        this.$router.push('/cabinet/contracts-work-agreements');
      }
    },
    goBack() {
      this.$router.back();
    },
    goTask() {
      let workflowType;
      let title;
      switch (this.currentSideStatus) {
        case 'DRAFT': {
          workflowType = 'documentAgreement';
          break;
        }
        case 'WAITING_UPDATE_INFO': {
          workflowType = 'documentUpdateInfo';
          break;
        }
        case 'REJECTED': {
          if (this.getUser.id !== this.contract.initiatorUserId) {
            workflowType = 'documentSigning';
            break;
          }
          workflowType = 'documentReload';
          title = `Исправьте ${this.contract.title}`;
          break;
        }
        default: {
          workflowType = 'documentSigning';
          break;
        }
      }
      this.$router.push({
        name: 'cabinet.approve-document',
        params: {
          id: this.contract.id,
          from: `contract/${this.contract.id}`,
          goBackOnComplete: true,
          fromTitle: 'Карточка договора',
          to: workflowType,
          title: title,
        },
      });
    },
    ...mapActions('document', ['loadDocument', 'getDomainDocument', 'documentUpload', 'getTransmissionContract']),
  },
};
</script>

<style lang="scss" scoped>
.form-columns.columns {
  gap: 0;

  & .form-columns__row .column {
    justify-content: end;
  }
}

.contract-form {
  &__header {
    display: flex;
    flex-direction: row;
    padding: 0 8px;

    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #4C4E51;
      max-width: 565px;
    }

    &__indicator {
      margin-left: auto;
      display: flex;
    }
  }

  &__formula-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #F5F6F6;
    border-radius: 16px;
    padding: 12px 0 12px 0;
  }

  &__formula {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    &__text, &__img {
      width: 50%;
      padding-left: 60px;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 8px;
      padding-top: 10px;
    }

    &__text {
      font-size: 12px;
    }
  }
}

.spoiler-wrp {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.indicators-wrp {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

button {
  height: 40px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;

  border: 1px solid #C1C4C7;
  border-radius: 24px;
  color: #0E0F0F;
  background: #FFFFFF;
  padding: 6px 25px 6px 25px;

  &:hover {
    background-color: #F5F6F6;
  }
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;

  &__save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }

    &:disabled {
      background: #717d8f;
    }
  }

  &__print {
    display: flex;
    align-items: center;
    gap: 10px;

    &__icon {
      display: flex;
      align-items: center;
    }
  }
}

.selected-rights {
  font-size: 16px;
  color: #4C4E51;
}
</style>
