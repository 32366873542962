<template>
  <div :class="$style.container">
    <p :class="$style.navigationBar">
      <span :class="$style.navigationRoot" @click="goTasks">{{ 'Задачи' }}</span>
      <span :class="$style.navigationSeparator">&nbsp;>&nbsp;</span>
      <span>{{ data.title }}, {{ title2 }}</span>
    </p>

    <div v-if="!isLoading" :class="$style.content">
      <div :class="$style.spoiler">
        <spoiler-preview v-model="spoiler.details">
          <div slot="header" :class="$style.spoilerHeader">
            <spoiler-header title='Детали задачи'>
              <template slot="icon">
                <chevron-up v-if="spoiler.details" />
                <chevron-down v-else />
              </template>
            </spoiler-header>
          </div>

          <template>
            <div :class="$style.details">
              <template v-if="data.userType === 'Performer'">
                <p :class="$style.step">
                  1. Скачайте регулирующий документ и прикрепите его к задаче
                </p>
                <p :class="$style.step">
                  2. Введите новые тарифы на Платформу
                </p>
              </template>
              <template v-else>
                <p :class="$style.step">
                  Проверьте перед публикацией на Платформе новые регулируемые тарифы {{ data.title2 }}.
                </p>
              </template>
              <div style="display: flex;margin-top: 28px">
                <div :class="$style.executorsTitle">
                  <p>Срок исполнения:</p>
                  <p>Ответственный:</p>
                  <p>Контролирующий сотрудник:</p>
                </div>
                <div :class="$style.executorsValue">
                  <p>{{ data.expiresAt }}</p>
                  <p>{{ data.executor }}</p>
                  <p>{{ data.approver }}</p>
                </div>
              </div>

              <p :class="$style.source">Источники информации по тарифам</p>
              <p :class="$style.sourceLink"><a href="https://www.mos.ru/depr/documents/tarifnaia-politika/pa-v-sfere-gosudarstvennogo-regulirovaniya-tarifov/" target="_blank">Департамент экономической политики и развития города Москвы</a></p>
              <p :class="$style.sourceLink"><a href="https://www.mosenergosbyt.ru/individuals/tariffs-n-payments/tariffs-msk/polnaya-versiya-tarifov.php" target="_blank">АО «Мосэнергосбыт»</a></p>
            </div>
          </template>
        </spoiler-preview>
      </div>

      <component
          :is="data.actionComponent"
          v-if="data.actionComponent"
          :is-display="isDisplayActionData"
          :workflow-id="data.workflowId"
          :action-props="data.actionProps"
          :document-id="data.documentId"
          :invoice-id="data.invoiceId"
          :subtitle="data.subtitle"
          :region = "data.region"
          :year = "data.year"
          :only-microgenerator="data.onlyMicrogenerator"
          :grid-company-name="gridCompanyName"
          :retail-company-name="retailCompanyName"
          :review-document="reviewDocument"
          :application-id="applicationId"
          :application-data="applicationData"
          :offer-document="offerDocument"
          :retail-review-document="retailReviewDocument"
          :additional-document="additionalDocument"
          :retailer-offer-document="retailerOfferDocument"
          :tariff-document="tariffDocument"
          :tariffs-data="tariffsData"
          :init-spoiler="initSpoiler"
          :description="data.description"
          style="flex-grow: 3"
          @close="goTasks"
      />
      <div v-else style="flex-grow: 3">&nbsp;
        <div v-if="reviewDocument.comments" :class="$style.blockContent">
          <spoiler-preview v-model="spoiler.review">
            <div slot="header" :class="$style.spoilerHeader">
              <spoiler-header title='Результаты проверки'>
                <template slot="icon">
                  <chevron-up v-if="spoiler.review" />
                  <chevron-down v-else />
                </template>
              </spoiler-header>
            </div>

            <template>
              <p :class="$style.reviewComments" v-html="comments"></p>
            </template>
          </spoiler-preview>
        </div>

        <div :class="$style.blockContent">
          <spoiler-preview v-model="spoiler.regDoc">
            <div slot="header" :class="$style.spoilerHeader">
              <spoiler-header title='Регулирующий документ'>
                <template slot="icon">
                  <chevron-up v-if="spoiler.regDoc" />
                  <chevron-down v-else />
                </template>
              </spoiler-header>
            </div>

            <template>
              <p :class="$style.documentTitle">Приказ Департамента экономической политики и развития города Москвы «Об установлении цен (тарифов) на электрическую энергию для населения и приравненных к нему групп потребителей города Москвы»</p>

              <div v-if="tariffDocument.length > 0" :class="$style.reviewDocumentFiles">
                <p v-for="(file, index) in tariffDocument" :key="index" style="display: flex; justify-content: space-between">
                  <a :href="fileUrl(file)" :role="'tariffDocument[' + index + '].name'" :download="file.name"><span>{{ file.name }}</span></a>
                  <span :class="$style.replace">
                    <a :href="fileUrl(file)" :download="file.name"><img src="~@/assets/icons/file/file_download.svg" alt="" /></a>
                    <a :href="fileUrl(file)" :download="file.name"><span :role="'tariffDocument[' + index + '].replace'">&nbsp;&nbsp;&nbsp;скачать</span></a>
                </span>

                </p>
              </div>
            </template>
          </spoiler-preview>
        </div>

        <div :class="$style.blockContent">
          <spoiler-preview v-model="spoiler.tariff">
            <div slot="header" :class="$style.spoilerHeader">
              <spoiler-header title='Тарифы'>
                <template slot="icon">
                  <chevron-up v-if="spoiler.tariff" />
                  <chevron-down v-else />
                </template>
              </spoiler-header>
            </div>

            <template>
              <tariff-form
                  v-model="tariff"
                  mode="view"
                  :task-document-id="data.documentId"
                  :tariffs-data="tariffsData"
                  :region="data.region"
                  :year="data.year"
                  :dictionary-category="tariffCategory"
                  :dictionary-rate="tariffRate"
              />
            </template>
          </spoiler-preview>
        </div>
      </div>

      <div :class="$style.blockContent">
        <p v-if="submitError.length !== 0" :class="$style.errorText" >{{ submitError }}</p>
        <horizontal-progress-bar v-if="isWaitingSubmit" />
        <submit-task-details v-if="!data.actionComponent" :class="$style.buttonNoAction" role="close" @submit="back" />
        <submit-task-details v-else-if="!isDisplayActionData" :disabled="isWaitingSubmit" :class="$style.buttonNoAction" :title="data.displayActionTitle" role="displayAction" @submit="onDisplayAction" />
      </div>
    </div>

    <div :class="$style.contentFooter">
      <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
        <chevron-left />
        <span>Назад</span>
      </v-button>
      <div :class="$style.center">&nbsp;</div>
    </div>
  </div>
</template>

<script>
// import { formatDateToCustomDateFormat } from 'lib/utils/date';

import DialogTemplate from 'templates/DialogTemplate';

import ScrollingHeader from 'molecules/ScrollingHeader';
import TaskStatus from 'molecules/TaskStatus';
import SummaryApplication from 'organisms/SummaryApplication';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';
import SpoilerPreview from 'molecules/spoiler/Spoiler';
import SpoilerHeader from 'molecules/spoiler/base/SpoilerHeader';

import ApprovedApplication from 'views/private/ConnectionApplication/Task/actions/ApprovedApplication';
import CorrectionApplication from 'views/private/ConnectionApplication/Task/actions/CorrectionApplication';
import CorrectionApplicationMicrogenerator from 'views/private/ConnectionApplication/Task/actions/CorrectionApplicationMicrogenerator';
import SelectTariff from 'views/private/ConnectionApplication/Task/actions/SelectTariff';
import ApprovedOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedOffer';
import AdditionalInfo from 'views/private/ConnectionApplication/Task/actions/AdditionalInfo';
import SendRetailerOffer from 'views/private/ConnectionApplication/Task/actions/SendRetailerOffer';
import RetailerOfferSubmitted from 'views/private/ConnectionApplication/Task/actions/RetailerOfferSubmitted';
import ApprovedRetailerOffer from 'views/private/ConnectionApplication/Task/actions/ApprovedRetailerOffer';
import CloseApplication from 'views/private/ConnectionApplication/Task/actions/CloseApplication';
import Cancelled from 'views/private/ConnectionApplication/Task/actions/Cancelled';
import UploadTariff from 'organisms/tasks/tariffs-update/actions/UploadTariff';
import ReviewTariff from 'organisms/tasks/tariffs-update/actions/ReviewTariff';
import TariffForm from 'organisms/tasks/tariffs-update/TariffForm';

import ChevronDown from 'atoms/icons/arrows/ChevronDown';
import ChevronUp from 'atoms/icons/arrows/ChevronUp';
import connectionApplicationData from 'lib/storage/connection-application/data';
import HTTPError from 'lib/utils/errors';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar';
import { mapActions, mapGetters } from 'vuex';
import formatting from 'lib/utils/formatting';
import { getFileUrl } from 'lib/utils/files';
import VDialogHeader from 'components/VDialog/VDialogHeader';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import { VButton } from 'components';
import router from '@/router';

export default {
  name: 'Details',
  components: {
    VDialogHeader,
    DialogTemplate,
    ScrollingHeader,
    SubmitTaskDetails,
    SummaryApplication,
    TaskStatus,
    ApprovedApplication,
    CorrectionApplication,
    CorrectionApplicationMicrogenerator,
    SelectTariff,
    ApprovedOffer,
    AdditionalInfo,
    SendRetailerOffer,
    RetailerOfferSubmitted,
    ApprovedRetailerOffer,
    CloseApplication,
    Cancelled,
    SpoilerPreview,
    SpoilerHeader,
    ChevronDown,
    ChevronUp,
    UploadTariff,
    ReviewTariff,
    HorizontalProgressBar,
    TariffForm,
    ChevronLeft,
    VButton,
  },
  props: {
    value: Boolean,
    data: Object,
    initSpoiler: {
      type: Object,
      default: () => ({
          details: true,
          tariff: true,
        }),
    },
    applicationId: String,
    applicationData: Object,
    retailCompanyName: String,
    gridCompanyName: String,
    reviewDocument: Object,
    offerDocument: Object,
    retailReviewDocument: Object,
    additionalDocument: Object,
    retailerOfferDocument: Object,
    offerDocumentTitle: String,
    isDisplayAction: {
      type: Boolean,
      default: true,
    },
    hideOfferInSummary: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    createDate: {
      type: String,
      default: '',
    },
    tariffDocument: [],
    tariffsData: {},
  },
  mounted() {
    this.isDisplayActionData = this.isDisplayAction;
  },
  data() {
    return {
      visible: false,
      spoiler: {
        details: this.initSpoiler.details,
      },
      preparedData: {},
      isDisplayActionData: true,
      submitError: '',
      isWaitingSubmit: false,
      tariff: [],
    };
  },
  computed: {
    offerDocumentSummary() {
      if (this.hideOfferInSummary) {
        return undefined;
      }
      return this.offerDocument;
    },
    title2() {
      if (this.data.userType === 'Performer') {
        return this.data.title2;
      }
      return '';
    },
    comments() {
      return formatting.formattedString(this.reviewDocument.comments, '', '');
    },
    ...mapGetters('dictionary', ['tariffCategory', 'tariffRate']),
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.visible = val;
      },
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$emit('input', val);
        /* if (!val) {
          this.isDisplayActionData = this.isDisplayAction;
        }*/
      },
    },
  },
  methods: {
   async onDisplayAction() {
      if (this.data.startReviewType) {
        this.submitError = '';
        this.isWaitingSubmit = true;

        const documentReviewXml = connectionApplicationData.documentReviewXml(
          this.data.documentId,
          this.data.startReviewType,
          '',
          undefined,
          {},
        );

        try {
          await this.documentUpload({ xml: documentReviewXml });

          setTimeout(() => {
            this.isWaitingSubmit = false;
            this.isDisplayActionData = true;
            this.spoiler.details = false;
          }, 2000);

        } catch (error) {
          const errorData = HTTPError.network(error);
          this.submitError = errorData.message;
          this.isWaitingSubmit = false;
        }
        return;
      }
      this.isDisplayActionData = true;
      this.spoiler.details = false;
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    goTasks() {
      router.push('/cabinet/tasks/');
    },
    back() {
      router.back();
    },
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows']),
  },
};
</script>

<style lang="sass" module>
  .container
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    background-color: #fff

  .content
    flex-grow: 2
    padding-bottom: 50px
    margin-top: 24px

  .navigationBar
    font-family: 'Roboto Condensed', sans-serif
    font-weight: 400
    font-size: 18px
    line-height: 24px
    color: #0E0F0F
    letter-spacing: 0.01em
    text-transform: uppercase
    width: 100%
    background-color: #FFFFFF
    z-index: 1006
    margin: 8px 16px 0 0
    padding-left: 16px

    .navigationRoot, .navigationSeparator
      color: #979CA2
      cursor: pointer


    .navigationSeparator
      font-size: 24px
      line-height: 24px

  .contentFooter
    position: absolute
    bottom: 0
    width: 100%
    background-color: #ffffff
    height: 48px
    display: flex
    z-index: 1000

  .back
    margin-bottom: 16px
    display: flex
    align-items: center
    cursor: pointer
    padding: 19px
    background: #ffffff

    svg
      path
        fill: #2F82DF

  .center
    flex-grow: 2


  .status
    width: 100%
    max-width: 300px
    margin: 0 auto
    padding: 0 0 40px

  .errorText
    +small-text
    margin: 4px
    color: red

  .spoiler
    &:not(:last-child)
      margin-bottom: 40px

  .spoilerHeader
    +questionnaries-container

  .details
    width: 100%
    max-width: 560px
    margin: 12px auto 0 auto

  .step
    +card-title-small
    margin-bottom: 16px

  .executorsTitle
    flex-grow: 2
    p
      +table-text-small
      line-height: 24px
      margin-bottom: 9px


  .executorsValue
    flex-grow: 3
    p
      +table-data-small
      margin-bottom: 9px

  .source
    +small-text
    margin-top: 34px
    margin-bottom: 8px

  .sourceLink
    margin-bottom: 8px

  .blockContent
    width: 100%
    max-width: 560px
    margin: 0 auto 40px

  .reviewDocumentFiles
    width: 100%
    max-width: 560px
    margin: 0 auto

  .reviewDocumentFiles p a
    font-family: 'Roboto Condensed'
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 16px
    color: #000
    text-decoration: underline

  .reviewDocumentFiles p span.replace
    display: flex
    font-family: 'Roboto Condensed'
    color: #4C4E51
    white-space: nowrap
    cursor: pointer
    font-size: 18px
    line-height: 18px

    a
      text-decoration: none

  .documentTitle
    +card-title-small
    margin-bottom: 24px

  .back
    margin-bottom: 16px
    display: flex
    align-items: center
    cursor: pointer
    padding: 19px
    background: #ffffff

    svg
      path
        fill: #2F82DF

</style>
