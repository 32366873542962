<template>
  <v-dialog v-model="visibleDialog"
            class="v-dialog-top" max-width="500px" persistent>
    <template #activator="{ on, attrs }">
      <v-btn :disabled="isMainDisable" class="mb-2 flex-grow-1" color="primary" elevation="0" rounded v-bind="attrs"
             v-on="on">
        Добавить элемент
      </v-btn>
    </template>
    <v-card class="v-application">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <elements-axp-autocomplete
            ref="element"
            v-model="elementId"
            :find-ids="elementsFindIds"
            :is-disabled="disabled"
            :items.sync="editedItem.elements"
            :search.sync="editedItem.elementName"
            is-required
            title="Элемент АХП"
        />
        <text-field ref="elementsCount" v-model="elementsCount" is-only-numbers is-required non-zero
                    title="Количество элементов">
        </text-field>
        <file-input ref="elementPhotos" v-model="photos" :edit="photosEdit"
                    class="mt-5" is-required title="Фото дефектного элемента">
        </file-input>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" outlined rounded @click="onTapCloseTableDialog">
          Отмена
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="isDisabledSaveDialog" color="primary" elevation="0" rounded @click="onTapSaveDialog">
          {{ formButtonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from 'components/Processes/components/TextField.vue';
import FileInput from 'components/Processes/components/FileInput.vue';
import ElementsAxpAutocomplete from 'components/Processes/components/ElementsAxpAutocomplete.vue';
import { fetchAxpElementsById } from '@/api/rest/odk/api';

export default {
  components: {
    ElementsAxpAutocomplete,
    TextField,
    FileInput,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      default: false,
    },
    edit: {
      default: null,
    },
    handleSuccess: {
      type: Function,
      required: true,
    },
    handleError: {
      type: Function,
      default: () => ({}),
    },
    cancel: {
      type: Function,
      default: () => ({}),
    },
    url: {
      default: null,
    },
    dialogData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      default: true,
    },
    axpId: {
      default: -1,
    },
    warehouseId: {
      default: -1,
    },
  },

  data() {
    return {
      editedItem: {
        elementId: null,
        elementName: '',
        elements: [],
        realCount: null,
        photos: [],
      },
      photos: [],
      elementId: null,
      elementsCount: null,
      photosEdit: -1,
      isElemDefault: false,
      maxCount: null,
    };
  },

  computed: {
    visibleDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('change', val);
      },
    },

    formTitle() {
      return this.edit > -1 ? 'Редактирование' : 'Добавление';
    },

    formButtonTitle() {
      return this.edit > -1 ? 'Сохранить' : 'Добавить';
    },

    isDisabledSaveDialog() {
      const isDisabledElementAxp = this.elementId == null;
      const isDisableElementsCount = this.elementsCount == null || isNaN(Number(this.elementsCount)) || Number(this.elementsCount) <= 0;
      const isDisablePhotos = !this.photos.length;
      return isDisabledElementAxp || isDisableElementsCount || isDisablePhotos;
    },

    isMainDisable() {
      return this.disabled || this.isElemDefault;
    },
    elementsFindIds() {
      return [
        {
          queryIndex: 1,
          value: this.axpId,
        },
      ];
    },
  },

  watch: {
    edit(newVal) {
      if (newVal > -1) {
        Object.assign(this.editedItem, this.dialogData);
        this.elementId = this.editedItem.elementId;
        this.elementsCount = this.editedItem.realCount;
        this.photos = this.editedItem.photos;
      } else {
        this.defaultValue();
      }
    },

    elementId(newVal) {
      const privateItems = Object.assign([], this.axp);
      const item = privateItems.filter((el) => el.id == newVal);
      const contId = item[0].contractorId;
      this.contractorId = contId;
    },
  },

  methods: {
    async onTapSaveDialog() {
      this.editedItem.elementId = this.elementId;
      this.editedItem.realCount = this.elementsCount;
      this.editedItem.unitName = (await fetchAxpElementsById(this.editedItem.elementId)).unit.name;
      this.editedItem.photos = this.photos;
      this.handleSuccess(this.editedItem);
      this.resetValidation();
    },

    onTapCloseTableDialog() {
      this.visibleDialog = false;

      this.afterClose();
    },
    resetValidation() {
      Object.values(this.$refs).forEach((it) => (it.resetValidation ? it.resetValidation() : false));
    },
    afterClose() {
      this.defaultValue();

      this.resetValidation();

      this.cancel();
    },

    defaultValue() {
      this.elementId = null;
      this.editedItem.elementId = null;
      this.editedItem.elementName = '';
      this.editedItem.elements = [];
      this.editedItem.elementsCount = null;
      this.editedItem.photos = [];
      this.elementsCount = null;
      this.photosEdit = -1;
      this.$refs.elementPhotos.defaultValue();
    },

  },
};
</script>
