<template>
  <div class="container">
    <div class="container__date-picker">
      <v-date-picker
        v-model=currentDate
        format="YYYY"
        type="year"
      />
    </div>
    <div class="form">
      <div>
        <v-table-new
          v-model="transformedMeasurements"
          :loading="loading"
          :template="template"
          excel-report-file-name="Показания"
          @on-tab-click="onTabClickHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { mapActions } from 'vuex';

import { convertDbDateTimeFormat } from 'lib/utils/date';
import { VDatePicker, VTableNew } from 'components';
import {
  formatAccountingMethod,
  formatAdditionKind,
  formatMeasurementKind,
  formatMeasurementsReadingKind,
  formatPointKind,
} from 'lib/utils/formatXmlType';

export default {
  components: {
    VTableNew,
    VDatePicker,
  },
  props: {
    kindList: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      currentDate: String(dayjs().year()),
      loading: false,
      points: [],
      activeTab: `январь ${String(dayjs().year())}`,
    };
  },
  computed: {
    template() {
      const headers = {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
            thStyle: {
              width: '20px',
            },
          },
          {
            label: '№ прибора учёта',
            key: 'serialNumber',
            sort: {},
            filter: {},
          },
          {
            label: 'Тип прибора учёта',
            key: 'pointKind',
            sort: {},
            filter: {},
          },
          {
            label: 'Тип точки',
            key: 'additionKind',
            thStyle: {
              width: '190px',
            },
            sort: {},
            filter: {},
          },
        ],
      };

      if (this.transformedMeasurements.length === 0 || this.getAllAccountingPeriods.length === 0) {
        return headers;
      }

      headers.subGroups = [];

      this.getAllAccountingPeriods.forEach((period) => {
        headers.subGroups.push({
          label: period.label,
          headers: [
            {
              label: 'Начальные показания',
              type: 'number',
              key: `previousValue${period.value}`,
              sort: {
                type: 'number',
              },
            },
            {
              label: 'Конечные показания',
              type: 'number',
              key: `currentValue${period.value}`,
              sort: {
                type: 'number',
              },
            },
            {
              label: 'Расход по месяцам, кВт * ч',
              type: 'number',
              key: `consumption${period.value}`,
              sort: {
                type: 'number',
              },
            },
            {
              label: 'Дополнительные объёмы, кВт*ч',
              type: 'number',
              key: `additionalVolume${period.value}`,
              sort: {
                type: 'number',
              },
            },
            {
              label: 'Вид снятия показаний',
              key: `measurementsReadingKind${period.value}`,
              sort: {},
            },
            {
              label: 'Алгоритм расхода',
              key: `accountingMethod${period.value}`,
              sort: {},
            },
          ],
        });
      });

      return headers;
    },
    transformedMeasurements() {
      if (this.points.length === 0) {
        return [];
      }
      this.points.forEach((item, index, object) => {
        if (item.meter === null) {
          object.splice(index, 1);
        }
      });

      return this.points.map((it) => ({
        id: it.id,
        serialNumber: it.meter?.serialNumber,
        pointKind: this.formatPointKind(it.pointKind),
        additionKind: formatAdditionKind(it.additionKind),
        [`currentValue${this.valueFromPeriod()}`]: this.measurementFieldByPeriod(it.measurements, this.activeTab, 'currentValue'),
        [`additionalVolume${this.valueFromPeriod()}`]: this.measurementFieldByPeriod(it.measurements, this.activeTab, 'additionalVolume'),
        [`previousValue${this.valueFromPeriod()}`]: this.measurementFieldByPeriod(it.measurements, this.activeTab, 'previousValue'),
        [`consumption${this.valueFromPeriod()}`]: this.measurementFieldByPeriod(it.measurements, this.activeTab, 'consumption'),
        [`accountingMethod${this.valueFromPeriod()}`]: this.accountingMethodByPeriod(it.measurements, this.activeTab, 'accountingMethod'),
        [`measurementsReadingKind${this.valueFromPeriod()}`]: this.measurementsReadingKindByPeriod(it.measurements, this.activeTab, it.measurementsReadingKind),
      }));
    },
    accountingPeriodsFromCurrentDate() {
      return [
        `январь ${this.currentDate}`,
        `февраль ${this.currentDate}`,
        `март ${this.currentDate}`,
        `апрель ${this.currentDate}`,
        `май ${this.currentDate}`,
        `июнь ${this.currentDate}`,
        `июль ${this.currentDate}`,
        `август ${this.currentDate}`,
        `сентябрь ${this.currentDate}`,
        `октябрь ${this.currentDate}`,
        `ноябрь ${this.currentDate}`,
        `декабрь ${this.currentDate}`,
      ];
    },
    getAllAccountingPeriods() {
      return this.accountingPeriodsFromCurrentDate.map((period, i) => ({
        label: period,
        value: `-${i}-${period.split(' ')[1]}`,
      }));
    },
  },
  watch: {
    currentDate: {
      handler(updatedCurrentDate) {
        const activeTabArr = this.activeTab.split(' ');

        this.activeTab = `${activeTabArr[0]} ${updatedCurrentDate}`;
      },
    },
  },
  methods: {
    valueFromPeriod() {
      const accountingPeriod = this.getAllAccountingPeriods.find((accountingPeriod) => accountingPeriod.label === this.activeTab);

      if (accountingPeriod) {
        return accountingPeriod.value;
      }

      return '';
    },
    measurementFieldByPeriod(measurements, period, field) {
      if (!measurements) {
        return null;
      }

      const measurement = measurements.find((measurement) => {
        if (measurement) {
          return measurement.accountingPeriod === period;
        }
      });

      if (measurement) {
        return measurement[field];
      }

      return null;
    },
    accountingMethodByPeriod(measurements, period, field) {
      const fieldByPeriod = this.measurementFieldByPeriod(measurements, period, field);

      if (fieldByPeriod) {
        return formatAccountingMethod(fieldByPeriod);
      }

      return null;
    },
    measurementsReadingKindByPeriod(measurements, period, field) {
      if (!measurements) {
        return null;
      }

      const measurement = measurements.find((measurement) => {
        if (measurement) {
          return measurement.accountingPeriod === period;
        }
      });

      if (measurement) {
        return formatMeasurementsReadingKind(field);
      }

      return null;
    },
    onTabClickHandler(tab) {
      this.activeTab = tab.label;
    },
    formatMeasurementsReadingKind(data) {
      return formatMeasurementsReadingKind(data);
    },
    formatMeasurementKind(data) {
      return formatMeasurementKind(data);
    },
    formatPointKind(data) {
      return formatPointKind(data);
    },
    async loadData() {
      try {
        this.loading = true;
        this.points = await this.loadPointsWithMeasurements(this.kindList);
      } catch (error) {
        console.log('loadPointsWithMeasurements', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    convertDbDateTimeFormat(value) {
      return convertDbDateTimeFormat(value);
    },
    ...mapActions('point', ['loadPointsWithMeasurements']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  &__date-picker {
    width: 200px;
  }

  .button {
    margin: 5px;
  }

  .form {
    padding: 0 16px 0 0;
  }
}
</style>
