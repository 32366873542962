<template>
  <page title="Справочник административных округов">
    <v-table-new
      v-model="transformedAdministrativeDistricts"
      :action-block-items="actionBlockItems"
      :loading="loading"
      :template="template"
      excel-report-file-name="Справочник административных округов"
      @delete="onDelete"
      @edit="onEdit"
    />
    <add-directory-field-modal
      v-model="modalValue"
      :action="modalAction"
      :loading="loading"
      :template="fields"
      @close="closeModal"
      @create="createDistrict"
      @delete="deleteDistrict"
      @update="updateDistrict"
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import {
  createAdministrativeDistrict,
  deleteAdministrativeDistrict,
  fetchAdministrativeDistricts,
  updateAdministrativeDistrict,
} from '@/api/rest/odk/api';
import AddDirectoryFieldModal from 'components/AddDirectoryFieldModal/AddDirectoryFieldModal.vue';
import {
  AddDirectoryFieldModalActions,
  AddDirectoryFieldModalFieldType,
} from 'components/AddDirectoryFieldModal/consts';
import HTTPError from 'lib/utils/errors';
import { filterForAccess } from 'lib/utils/permissions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CabinetAdministrativeDistrictsScreen',
  components: {
    AddDirectoryFieldModal,
    VTableNew,
    Page,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      modalAction: AddDirectoryFieldModalActions.EMPTY,
      modalValue: {},
      loading: false,
      administrativeDistricts: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'fullName',
          name: 'Полное Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'name',
          name: 'Наименование',
          required: true,
        },
        {
          type: AddDirectoryFieldModalFieldType.TEXT,
          id: 'externalId',
          name: 'Внешний ID',
        },
      ];
    },
    template() {
      const editHeaders = this.isAccessUpdate ? [{
        key: 'actionButtons',
        type: 'editDeleteButtons',
        thStyle: {
          width: '80px',
        },
      }] : [];
      return {
        headers: [
          {
            label: 'ID',
            key: 'id',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Внешний ID',
            key: 'externalId',
            thStyle: {
              maxWidth: '100px',
              minWidth: '100px',
            },
            sort: {},
            filter: {},
          },
          {
            label: 'Полное наименование',
            key: 'fullName',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          {
            label: 'Наименование',
            key: 'name',
            sort: {},
            thStyle: {
              minWidth: '100px',
            },
            filter: {},
          },
          ...editHeaders,
        ],
      };
    },
    transformedAdministrativeDistricts() {
      if (!this.administrativeDistricts?.length) {
        return [];
      }

      return this.administrativeDistricts;
    },
    isAccessUpdate() {
      return filterForAccess('process/1/7', this.getAuthorization?.blockAccess);
    },
    actionBlockItems() {
      if (!this.isAccessUpdate) return [];
      return [
        {
          id: 1,
          title: 'Добавить запись',
          method: this.onCreate,
        },
      ];
    },
    ...mapGetters('user', ['getAuthorization', 'getToken']),
  },
  methods: {
    ...mapActions('user', ['setNotification']),
    async loadData() {
      try {
        this.loading = true;
        this.administrativeDistricts = await fetchAdministrativeDistricts();
      } catch (error) {
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    onEdit(cell) {
      this.modalAction = AddDirectoryFieldModalActions.UPDATE;
      this.modalValue = { ...cell.row };
    },
    onDelete(cell) {
      this.modalAction = AddDirectoryFieldModalActions.DELETE;
      this.modalValue = { ...cell.row };
    },
    onCreate() {
      this.modalAction = AddDirectoryFieldModalActions.CREATE;
      this.modalValue = {};
    },
    closeModal() {
      this.modalAction = AddDirectoryFieldModalActions.EMPTY;
      this.modalValue = {};
    },
    deleteDistrict() {
      this.doAction(() => deleteAdministrativeDistrict(this.modalValue.id));
    },
    createDistrict() {
      this.doAction(() => createAdministrativeDistrict(this.modalValue.name, this.modalValue.fullName));
    },
    updateDistrict() {
      this.doAction(() => updateAdministrativeDistrict(this.modalValue.id, this.modalValue.name, this.modalValue.fullName));
    },
    async doAction(action) {
      this.loading = true;
      try {
        await action();
        this.closeModal();
        await this.loadData();
      } catch (error) {
        console.log('catch');
        this.setNotification({
          message: HTTPError.network(error).message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 20px;
}
</style>
