import gql from 'graphql-tag';

// подписка на новые сообщения в комнате, работает только если пользователь добавлен в эту комнату
export const SUBSCRIBE_MESSAGES = gql`
    subscription SUBSCRIBE_MESSAGES($userId: String!, $roomId: String!) {
        receiveMessages(userId: $userId, roomId: $roomId) {
            # id сообщения
            id
            # id отправителя
            senderId
            # id комнаты
            roomId
            # текст сообщения пользователя
            content
            # время отправки сообщения
            creationTime
            attachments {
              id
              fileName
              fileType
              fileSize
            }
        }
    }
`;

// подписка на новые комнаты для пользователя
export const SUBSCRIBE_ROOMS = gql`
    subscription SUBSCRIBE_ROOMS($userId: String!) {
        receiveRooms(userId: $userId) {
            id
            title
        }
    }
`;
