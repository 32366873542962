<template>
  <div class="substation-tree-chart">
    <navigation-bar :current-step="0"
                    :steps="innerSteps"
                    home="Участки сети"
                    @open="goBack" />

    <v-divider class="substation-tree-chart__divider-top" />

    <div class="substation-tree-chart__header">
      <span>Оптовый рынок</span>
    </div>

    <div class="substation-tree-chart__start-line">
      <div id="node-bottom-start" class="substation-tree-chart__start-line__node" :style="{left: startLineLeft}">
      </div>
    </div>

    <v-divider />

    <div class="substation-tree-chart__select">
      <v-select
          v-model="number"
          :options="substationsListOptions"
          new-style
      />
    </div>

    <div class="substation-tree-chart__chart">
      <div class="substation-tree-chart__chart-overflow"></div>
      <tree-chart
          v-if="substation"
          :top-node="substation"
          :list="transformSubstation"
          :get-children="getChildren"
          :is-grouping="false"
          :zoom="zoom"
          :min-width-row="1077"
          :check-duplicate="true"
          node-view="SubstationNode"
          start-line
          prop-name="subdivision"
          class="substation-tree-chart__chart-data"
          @onDrawArrows="onDrawArrows"
      />

      <div class="substation-tree-chart__chart__zoom">
        <div class="substation-tree-chart__chart__zoom-plus" @click="zoomIn">
          <img src="@/assets/icons/basic/plus_circle.svg">
        </div>
        <div class="substation-tree-chart__chart__zoom-minus" @click="zoomOut">
          <img src="@/assets/icons/basic/minus_circle.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationBar, TreeChart, VDivider, VSelect } from 'components';
import { mapActions } from 'vuex';
import { transliterate } from 'utils/string/translit';

export default {
  name: 'SubstationTreeChart',
  components: {
    VDivider,
    NavigationBar,
    TreeChart,
    VSelect,
  },
  created() {
    if (!this.$route.params.number) {
      this.$router.back();
      return;
    }
    this.loadData();
  },
  data() {
    return {
      number: this.$route.params.number,
      name: '',
      substationList: [],
      substation: {},
      zoom: 1,
      startLineLeft: '50%',
    };
  },
  computed: {
    innerSteps() {
      return [{
        stepNumber: {
          value: 1,
          title: () => this.substation?.fullName ?? '',
        },
      }];
    },
    transformSubstation() {
      return this.substationList.map((substation) => (
          {
            ...substation,
            id: transliterate(substation.number),
            name: substation.shortName,
          }
      ));
    },
    substationsListOptions() {
      return this.transformSubstation.map((substation) => (
          {
            label: substation.fullName,
            value: substation.number,
          }
      ));
    },
  },
  watch: {
    number(val) {
      if (this.$route.params?.number !== val) {
        this.$router.push({
          name: 'cabinet.boundaries-balance-sheet.chart',
          params: {
            number: val,
          },
        });
      }
    },
    '$route'(to, from) {
      if (to.name === 'cabinet.boundaries-balance-sheet.chart' && to.params?.number !== from.params?.number) {
        this.number = to.params?.number;
        this.loadData();
        this.onReloadChartData();
      }
    },
  },
  methods: {
    goBack(index) {
      if (index === -1) {
        this.$router.push({
          name: 'cabinet.boundaries-balance-sheet',
        });
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.substationList = await this.substations();

        this.substation = this.transformSubstation.filter((substation) => substation.number === this.number)[0];

        if (!this.substation) {
          this.setNotification({ message: `Не удалось загрузить: ${this.number}` });
          this.$router.back();
          return;
        }
      } catch (error) {
        console.log('load main substations', error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    getChildren(node) {
      const lines = node?.lines?.map((line) => ({
        ...line.secondSubstation,
        id: transliterate(line.secondSubstation.number, line),
        name: line.secondSubstation.fullName,
        lineLabel: line.name,
      })) ?? [];

      const usagePoints = node?.usagePoints?.filter((point) => point.powerSupplyObject)?.map((point) => ({
        id: point.powerSupplyObject.id,
        name: point.powerSupplyObject.consumer?.consumerName ?? 'Без договора энергоснабжения',
        lineLabel: point.pointInfo?.line ?? '',
        address: point.powerSupplyObject.objectAddress,
        type: 'usagePoint',
      })) ?? [];

      return [...lines, ...usagePoints];
    },
    zoomIn() {
      this.zoom += 0.1;
    },
    zoomOut() {
      this.zoom -= 0.1;
    },
    onDrawArrows(rootNode) {
      if (this.zoom !== 1) {
        return;
      }
      this.startLineLeft = `${document.getElementById(`node-bottom-${rootNode.id}`).getBoundingClientRect().left - 299}px`;
    },
    onReloadChartData() {
      this.zoom = 1;
    },
    ...mapActions('border', ['substations']),
    ...mapActions('user', ['setNotification']),
  },
};

</script>

<style lang="scss" scoped>
  .substation-tree-chart {
    position: relative;
    padding: 0 24px 24px 138px;

    &__divider-top {
      position: absolute;
      left: 24px;
      height: 1px;
      top: 34px;
      width: 97%;
    }

    &__header {
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      padding: 24px 0;
    }

    &__select {
      width: 368px;
      margin-top: 25px;
    }

    &__start-line {
      width: 100%;
      position: relative;

      &__node {
        position: absolute;
        top: -37px;
      }
    }

    &__chart {
      position: relative;
      margin-top: 35px;
      display: flex;

      &__zoom {
        position: absolute;
        top: -90px;
        right: 24px;
        display: flex;
        gap: 1px;
        z-index: 5;

        &-plus, &-minus {
          width: 40px;
          height: 40px;
          padding: 10px;
          border: 1px;
          background-color: #F5F6F6;
          cursor: pointer;
        }

        &-plus {
          border-radius: 24px 0 0 24px;

          &--inactive {
            cursor: default;
            background-color: #fff7f7;
          }
        }
        &-minus {
          border-radius: 0 24px 24px 0;
        }
      }

      &-overflow {
        height: auto;
        position: relative;
        z-index: 4;
        background-color: #FFFFFF;
        display: flex;
        width: 168px;
        margin-left: -168px;
      }
    }

  }
</style>
