<template>
  <page title="Реестр юридических лиц">
    <select-button
        :items="actionBlockItems"
    />

    <v-table-new
      v-model="transformedAccounts"
      :loading="loading"
      :template="template"
      excel-report-file-name="Реестр юридических лиц"
      @on-cell-click="cellClickHandler"
    />

    <dialog-template
        v-model="displayConnectionDialog"
        :top="8"
        height100
        hide-close-button
        style-type="roundedStyle"
        @close="closeDialog"
    >
      <individual-connection-app
          is-legal-entity
          :company="newCompany"
          @close="closeDialog"
          @route="loaded"
      />
    </dialog-template>
  </page>
</template>

<script>
import { mapActions } from 'vuex';
import { Page, VTableNew } from '@/components';
import SelectButton from 'components/SelectButton/SelectButton.vue';
import IndividualConnectionApp from 'organisms/registration/individual-connection';
import DialogTemplate from 'templates/DialogTemplate';
import { ACCOUNT_PROFILE_TYPE } from 'views/private/UserManagement/AccountProfileType';

export default {
  name: 'RegisterLegalEntities',
  components: {
    VTableNew,
    Page,
    SelectButton,
    IndividualConnectionApp,
    DialogTemplate,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      accounts: [],
      loading: false,
      displayConnectionDialog: false,
      actionBlockItems: [
        {
          id: '1',
          title: 'Зарегистрировать юридическое лицо',
          method: () => {
            this.displayConnectionDialog = true;
          },
        },
      ],
      newCompany: {},
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            thStyle: {
              whiteSpace: 'nowrap',
            },
          },
          {
            label: 'ID',
            key: 'id',
            sort: {},
            filter: {},
          },
          {
            label: 'Название компании',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'ИНН',
            key: 'inn',
            sort: {},
            filter: {},
          },
          {
            label: 'КПП',
            key: 'kpp',
            sort: {},
            filter: {},
          },
          {
            label: 'ОГРН',
            key: 'ogrn',
            sort: {},
            filter: {},
          },
          {
            label: 'ФИО генерального директора',
            key: 'fio',
            sort: {},
            filter: {},
          },
          {
            label: 'Email генерального директора',
            key: 'email',
            sort: {},
            filter: {},
          },
          {
            key: 'edit',
            type: 'edit',
          },
        ],
      };
    },
    transformedAccounts() {
      if (this.accounts.length === 0) {
        return [];
      }

      return this.accounts.map((it) => {
        let fio = '';
        let email = '';

        const owner = it.authorizations.find((authorization) => authorization.role?.isCompanyOwner);

        if (owner?.user) {
          fio = owner.user.fullName
              ? `${owner.user.fullName.surname} ${owner.user.fullName.name} ${owner.user.fullName.patronymic ?? ''}` : '';

          email = owner.user.email ?? '';
        }

        return {
          id: it.id,
          name: it.profile.name,
          inn: it.profile.inn,
          kpp: it.profile.kpp,
          ogrn: it.profile.ogrn,
          fio: fio,
          email: email,
        };
      });
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        this.accounts = await this.accountsByFilter({ filter: { profileType: ACCOUNT_PROFILE_TYPE.COMPANY } });
      } catch (error) {
        console.log(error);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.displayConnectionDialog = false;
    },
    loaded() {
      this.closeDialog();
      this.loadData();
    },
    async cellClickHandler(cell) {
      if (cell.type === 'edit') {
        return await this.$router.push({
          path: 'register-legal-entities/company-details/',
          query: {
            id: cell.row.id,
          },
        });
      }
    },
    ...mapActions('user', ['accountsByFilter', 'setNotification']),
  },
};
</script>
