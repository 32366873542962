<template>
  <div v-if="!hide" class="spoiler">
    <details :open="isOpen">
      <summary :style="height"><span v-if="preSummary" class="spoiler__pre-summary">{{ preSummary }} </span>{{
          summary
        }}
        <slot name="button"/>
        <div class="spoiler__button-right">
          <slot name="button-right"/>
        </div>
      </summary>
      <div class="spoiler__details" :style="detailsStyle">
        <slot/>
      </div>
    </details>
  </div>
  <div v-else>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'VSpoilerLeft',
  props: {
    summary: {
      type: String,
      default: '',
    },
    preSummary: {
      type: String,
      required: false,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    detailsStyle: {
      type: String,
      default: '',
    },
  },
  computed: {
    height() {
      if ((this.preSummary?.length ?? 0) + this.summary.length < 100) {
        return 'height: 40px';
      }

      return 'height: 60px';
    },
  },
};

</script>

<style lang="scss" scoped>
.spoiler {
  position: relative;

  summary {
    height: 40px;
    padding: 8px 48px 8px 56px;

    display: flex;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #71757A;

    background: #F5F6F6;
    border-radius: 20px 20px 20px 20px;

    cursor: pointer;
  }

  &__pre-summary {
    font-weight: 600;
  }

  summary:first-letter {
    text-transform: uppercase;
  }

  details > summary {
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none
  }

  summary::after {
    content: url('~icons/spoiler/close.svg');
    position: absolute;
    left: 20px;
  }

  details {
    border: 0;
  }

  details[open] {
    border: 1px solid #F5F6F6;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    padding-bottom: 6px;

    summary {
      border-radius: 20px 20px 0 0;
      margin-bottom: 16px;

    }

    summary:after {
      content: url('~icons/spoiler/open.svg');;
      position: absolute;
      left: 20px;
    }
  }

  &__button-right {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: end;
    align-items: center;
  }
}
</style>
