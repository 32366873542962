var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-wrp",style:({
  border: `3px solid ${_vm.setBorderColor}`,
  height: `${_vm.height}`,
  width: `${_vm.width}`,
})},[_c('div',{staticClass:"progress-bar"},[(!_vm.isHundredPercent)?_c('div',{staticClass:"progress-bar__text-wrp",style:({
        background: _vm.primaryColor,
      })},[_c('span',{staticClass:"progress-bar__text"},[_vm._v(" "+_vm._s(_vm.text)+" "+_vm._s(_vm.internalPercent)+" % ")])]):_vm._e(),(_vm.isHundredPercent)?_c('div',{staticClass:"progress-bar__text-wrp_complete"},[_c('span',{staticClass:"progress-bar__text_complete"},[_vm._v(" "+_vm._s(_vm.text)+" 100 % ")])]):_vm._e(),(!_vm.isHundredPercent)?_c('div',{staticClass:"progress-bar__inner"},[_c('div',{staticClass:"progress-bar__bar",style:(_vm.barStyle)}),_c('div',{staticClass:"progress-bar__spaces"},_vm._l((_vm.spaceCounter),function(item){return _c('div',{key:item,staticClass:"progress-bar__space",style:(_vm.spaceStyle)})}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }