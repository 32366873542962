import { apolloClient } from '@/api/graphql';
import { POWER_OF_ATTORNEYS } from '@/api/graphql/queries';

async function loadPowerOfAttorneys({ rootState, commit }) {
  try {
    const { data: { powerOfAttorneys } } = await apolloClient.query({
      query: POWER_OF_ATTORNEYS,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          ['X-Authorization']: `Bearer ${rootState.user.token}`,
        },
      },
    });

    console.log('powerOfAttorneys', powerOfAttorneys);

    commit('SET_POWER_OF_ATTORNEYS', powerOfAttorneys);
  } catch (error) {
    commit('user/SET_NOTIFICATION', {
      notificationType: 'error',
      notificationText: `${error.message}`,
    }, { root: true });
    console.log('loadPowerOfAttorneys::catch', error);
    throw error;
  }
}

function updateFormAttorney({ commit }, formAttorney) {
  commit('SET_FORM_ATTORNEY', formAttorney);
}

function updateFilter({ commit }, filter) {
  commit('SET_FILTER', filter);
}

export default {
  loadPowerOfAttorneys,
  updateFormAttorney,
  updateFilter,
};
