<template>
  <div @click="openSearch(false)">
    <div v-if="showMicrogenerationHelp" :class="$style.microgeneration">
      <span :class="$style.text" @click="onSelectMicrogeneration()">
        Стань <img :class="$style.iconUnion" alt="icon: union" height="23px"
                   src="@/assets/images/microgeneration/iconunion.png" width="16px">
        микрогенератором. Передавай электроэнергию в сеть и уменьшай сумму счёта.
      </span>
      <i :class="$style.iconClose" @click="close()"></i>
    </div>
    <div :class="$style.container">
      <dialog-template v-model="isShownMicrogenerationHelpData">
        <Microgeneration @close="close"/>
      </dialog-template>
      <component
        :is="headerComponent"
        :is-compact="isCompact"
        :is-open-search="isOpenSearch"
        @mode="setMode"
        @openSearch="openSearch(true)"
      />
      <div :class="{[$style.content]: !showMicrogenerationHelp, [$style.contentHelp]: showMicrogenerationHelp}">
        <div
          :class="{
            [$style.leftColumn]: true,
            [$style.compact]: isCompact,
            [$style.full]: !isCompact,
            [$style.over]: isOver,
          }"
          :style="compactStyle"
          @click="scrollTop"
        >
          <left-menu :is-compact="isCompact"/>
        </div>
        <div ref="rightcolumn" :class="$style.rightColumn" @scroll="handleScroll" @click="clickRightColumn">
          <Chat v-if="$store.state.chatbot.isActive"/>
          <router-view></router-view>
        </div>
      </div>
      <microgenerator-connection/>
    </div>
    <cookie-notification :visible="cookieVisible" @close="closeCookie"/>
    <scroll-to-button
      v-show="isScrollButtonShow"
      :get-scroll-target-ref="() => $refs['rightcolumn']"
    />
    <div v-if="isDeleteProfileDialog" :class="$style.modalBackdrop">
      <dialog-template v-model="isDeleteProfileDialog" center hide-close-button>
        <div :class="$style.deleteProfileDialog">
          <p>{{ deleteProfileDialogText }}</p>
          <p v-if="submitError.length !== 0" class="errorText">{{ submitError }}</p>
          <v-button variant="filled" @click="agreeDeleteProfile">Согласен</v-button>
          <horizontal-progress-bar v-if="isWaitingDocumentUpload"/>
        </div>
      </dialog-template>
    </div>
    <modal
        v-if="isDisplaySendCode"
        width="592px"
        :header-style="{padding: '16px 16px 16px 24px'}"
        :modal-style="{
          borderRadius: '16px',
          padding: '6px 0 36px 0',
        }"
           @close="logOut">
      <template #body>
        <div :class="$style.modalTitle">
          <img src="@/assets/icons/user/phone.svg">
          <span>Ввод кода при смене аккаунта</span>
        </div>
        <div :class="$style.modalBody">
          <span :class="$style.codeTitle">{{ codeTitles.titleContact }}</span>
          <input disabled style="height: 40px;padding-left: 16px;margin-bottom: 16px" :value="codeTitles.contact" />
          <span :class="$style.codeTitle">{{ codeTitles.titleCode }}</span>
          <one-time-password-input
              :is-error="isCodeError"
              :is-waiting="isWaitingCode"
              input-style="height: 40px;font-size: 16px;letter-spacing:8px"
              :retry-button-style="'marginTop: 8px;justifyContent: left'"
              @code-input="codeEntered"
              @code-request="requestCode"
          />
          <horizontal-progress-bar v-if="isWaitingCode"/>
          <div
              v-if="isCodeError"
              class="errorText"
              role="errorText"
          >
            {{ codeErrorMessage }}
          </div>
          <v-button :disabled="code.length < 4" style="width: 136px;margin: 24px auto 0 auto" @click="sendCode">Подтвердить</v-button>
        </div>
      </template>

    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import HTTPError from 'lib/utils/errors';
import { LeftMenu } from 'components/LeftMenu';
import { ScrollToButton } from '@/components';
import CookieNotification from 'components/CookieNotification/CookieNotification';
import { VButton } from 'components';
import dayjs from 'dayjs';
import generateXml from 'lib/storage/connection-application/data';
import HorizontalProgressBar from 'atoms/common/HorizontalProgressBar.vue';
import Modal from 'components/Modal/Modal.vue';
import OneTimePasswordInput from 'components/OneTimePasswordInput/OneTimePasswordInput.vue';
import tr from 'vue2-datepicker/locale/es/tr';
import formatting from 'views/public/login/mixins/formatting';

export default {
  name: 'Cabinet',
  mixins: [formatting],
  components: {
    OneTimePasswordInput, Modal,
    HeaderCabinetUser: () => import('organisms/headers/user/HeaderCabinet'),
    Chat: () => import('components/ChatBot/Chat'),
    MicrogeneratorConnection: () => import('organisms/connection-application/MicrogeneratorConnection.vue'),
    DialogTemplate: () => import('templates/DialogTemplate.vue'),
    Microgeneration: () => import('@/organisms/microgeneration/Microgeneration'),
    LeftMenu,
    CookieNotification,
    ScrollToButton,
    VButton,
    HorizontalProgressBar,
  },
  async created() {
    console.log('cabinet created');
    this.onResize();
    window.addEventListener('resize', this.onResize);
    await this.initMicrogenerationHelp();
    try {
      await this.setUser();
    } catch (error) {
      console.log('set user err', error);
      if (HTTPError.network(error).code === HTTPError.code.PERMISSION_DENIED) {
        console.log(error.graphQLErrors[0].extensions.errorType);
        this.vueShowNotification('Сессия устарела или аккаунт был удален', '<p>Ваша сессия устарела, пожалуйста, войдите повторно.</p>');
        await this.logOut();
      }
    }

    this.checkUserProfileDeleteApplications();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  data() {
    return {
      windowInnerWidth: 0,
      isCompact: false,
      isShownMicrogenerationHelpData: false,
      showMicrogenerationHelp: false,
      isOpenSearch: false,
      cookieVisible: !JSON.parse(localStorage.getItem('agreedCookie')),
      isScrollButtonShow: false,
      isDeleteProfileDialog: false,
      deleteProfileDialogText: '',
      documentReviewId: '',
      isWaitingDocumentUpload: false,
      submitError: '',
      isCodeError: false,
      codeErrorMessage: '',
      isWaitingCode: false,
      code: '',
    };
  },
  computed: {
    tr() {
      return tr;
    },
    isDisplaySendCode() {
      return this.getTokenStatus === 'SWAP';
    },
    compactStyle() {
      if (this.browserProperties.isFirefox && this.isCompact) {
        return { maxWidth: '77px' };
      }
      return {};
    },
    isShownPersonalAccount() {
      return this.getUserType === 'Customer';
    },
    headerComponent() {
      return 'HeaderCabinetUser';
    },
    leftMenuComponent() {
      switch (this.getUserType) {
        case 'Platform': {
          return 'LeftMenuPlatform';
        }
        case 'Supplier': {
          return 'LeftMenuSupplier';
        }
        case 'Retailer': {
          return 'LeftMenuRetailer';
        }
        case 'Customer': {
          return 'LeftMenuUser';
        }
        case 'LEGAL_CONSUMER': {
          return 'LeftMenuLegalUser';
        }
        case 'Boiler': {
          return 'LeftMenuBoiler';
        }
        case 'SUPPLIER_OF_LAST_RESORT': {
          return 'LeftMenuGrandSupplier';
        }
        default: {
          return 'LeftMenuUser';
        }
      }
    },
    codeTitles() {
      if (this.isEmail(this.getTokenUsername)) {
        return {
          contact: this.maskedContact(this.getTokenUsername, true),
          titleContact: 'Письмо придёт на почту *',
          titleCode: 'Введите одноразовый код из письма *',
        };
      }

      return {
        contact: this.maskedContact(this.getTokenUsername, false),
        titleContact: 'Смс придёт на номер *',
        titleCode: 'Введите одноразовый код из СМС *',
      };
    },
    isOver() {
      if (this.isCompact) {
        return false;
      }

      return this.windowInnerWidth <= 550;
    },
    ...mapGetters('user', ['getUserType', 'getUser', 'wasShowMicrogenerationHelp', 'getTokenStatus', 'getTokenUsername']),
    ...mapGetters('environment', ['browserProperties']),
  },
  watch: {
    '$route'() {
      this.checkUserProfileDeleteApplications();
    },
  },
  methods: {
    async codeEntered(code) {
      this.code = code;
    },
    async sendCode() {
      this.isWaitingCode = true;
      this.codeErrorMessage = '';
      this.isCodeError = false;
      try {
        const res = await this.confirmUser({ code: this.code });
        if (res.message?.length) {
          this.isCodeError = true;
          this.codeErrorMessage = res.message;
          return;
        }

        this.clearTasks();
        if (this.$route.path === '/cabinet/tasks') {
          await this.setWorkflows({ userType: this.getUserType });
        } else {
          this.$router.push('/cabinet/tasks');
        }
      } catch (e) {
        this.codeErrorMessage = HTTPError.network(e).message;
        this.isCodeError = true;
      } finally {
        this.isWaitingCode = false;
      }
    },
    requestCode() {
      this.userSendAuthenticationCode();
    },
    onResize() {
      this.windowInnerWidth = window.innerWidth;

      if (this.windowInnerWidth <= 1024) {
        this.isCompact = true;
      } else {
        this.isCompact = false;
      }
    },
    handleScroll() {
      this.isScrollButtonShow = this.$refs.rightcolumn.scrollTop > 200;

      if (this.isOver) {
        this.isCompact = true;
      }
    },
    clickRightColumn() {
      if (this.isOver) {
        this.isCompact = true;
      }
    },
    closeCookie() {
      this.cookieVisible = false;
    },
    scrollTop() {
      // TODO переделать
      if (localStorage.getItem('approve_document'))
        localStorage.removeItem('approve_document');

      this.$nextTick(() => {
        this.$refs.rightcolumn.scrollTo(0, 0);
      });
    },
    onSelectMicrogeneration() {
      this.isShownMicrogenerationHelpData = true;
    },
    setMode() {
      this.isCompact = !this.isCompact;
    },
    openSearch(isOpen) {
      this.isOpenSearch = isOpen;
    },
    close() {
      this.showMicrogenerationHelp = false;
      this.isShownMicrogenerationHelpData = false;
      this.SET_MIGROGENERATION_HELP();
    },
    // TODO move to CabinetObjects
    async initMicrogenerationHelp() {
      if (!this.isShownPersonalAccount) {
        return;
      }

      if (this.wasShowMicrogenerationHelp) {
        this.showMicrogenerationHelp = false;
        return;
      }

      const objects = await this.loadUsagePointsIndividual();

      const objectsMeters = objects.filter(
        (item) => this.$validate.isNotEmpty(item.admissionCertificateId) && !item.meter?.microgeneratorStatus,
      );

      this.showMicrogenerationHelp = objectsMeters.length !== 0;
    },
    async agreeDeleteProfile() {
      try {
        this.isWaitingDocumentUpload = true;
        const documentReviewXml = generateXml.documentReviewXml(
          this.documentReviewId,
          'user-profile-delete-application-agreed-with-review',
        );

        await this.documentUpload({ xml: documentReviewXml });

        localStorage.removeItem(`application_user_delete_profile${this.getUser.id}`);
        this.isDeleteProfileDialog = false;
        await this.logOut();
      } catch (error) {
        const errorData = HTTPError.network(error);
        this.submitError = errorData.message;
      }

      this.isWaitingDocumentUpload = false;
    },
    async checkUserProfileDeleteApplications() {
      try {
        const applications = await this.userProfileDeleteApplications();
        const accepted = applications.filter((item) => item.status === 'ACCEPTED_BY_SUPPORT');

        if (accepted.length > 0) {
          this.documentReviewId = accepted[0].documentId;
          this.deleteProfileDialogText = `Сообщаем, что в соответствии с Вашим обращением (запросом) от ${accepted[0].createdAt} г. №${accepted[0].number} в АО «ОЭК» ${dayjs().add(1, 'M').format('DD.MM.YYYY')} будут исключены из обработки ваши персональные данные с последующим удалением профиля на Платформе. Нажмите на кнопку "Согласен" для того, чтобы удалить профиль немедленно.`;
          this.isDeleteProfileDialog = true;
        }
      } catch (error) {
        console.log(error.graphQLErrors[0].extensions.errorType);
      }
    },
    ...mapMutations('user', ['SET_MIGROGENERATION_HELP']),
    ...mapActions('serviceLocations', ['loadUsagePointsIndividual']),
    ...mapActions('user', ['setUser', 'userProfileDeleteApplications', 'logOut', 'confirmUser', 'userSendAuthenticationCode']),
    ...mapActions('document', ['documentUpload']),
    ...mapActions('cabinet', ['setWorkflows', 'clearTasks']),
  },
};
</script>

<style lang="sass" module>
// TODO: убрать backdrop в компонент модалки
.modalBackdrop
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10030;
  background-color: rgba(0, 0, 0, 0.3);

.container
  width: 100vw

.content, .contentHelp
  width: 100vw
  display: flex
  position: absolute
  top: 66px
  bottom: 0

.contentHelp
  top: 96px

.text
  display: flex
  align-items: center
  font-family: 'Roboto Condensed'
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  letter-spacing: 1px
  text-transform: uppercase
  text-decoration: underline
  color: #fff
  text-align: center
  cursor: pointer

  img
    margin: 0 4px

.leftColumn
  overflow-y: auto
  overflow-x: hidden
  background: #FBFBFB
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07)
  margin-right: 1px
  margin-top: 4px
  position: relative
  z-index: 10002

  &::-webkit-scrollbar
    width: 5px

  &:hover
    &::-webkit-scrollbar-button
      background-repeat: no-repeat
      width: 5px
      height: 0
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb
      -webkit-border-radius: 0
      border-radius: 10px
      background-color: #d1d1d1

    &::-webkit-scrollbar-thumb:hover
      background-color: #a19f9f

    &::-webkit-resizer
      background-repeat: no-repeat
      width: 5px
      height: 0

    &::-webkit-scrollbar
      width: 5px

.full
  min-width: 280px

.compact
  max-width: 64px
  min-width: 64px

.over
  position: absolute

.rightColumn
  overflow: auto
  flex-grow: 2
  padding-left: 16px
  padding-top: 16px

.microgeneration
  width: 100%
  background: #2F82DF
  display: flex
  justify-content: center
  align-items: center
  height: 32px
  @media screen and (max-width: 1020px)
    width: 1018px

.iconUnion
  max-width: 16px
  max-height: 23px

.iconClose
  transform: translateX(-9px) translateY(5px)
  width: 14px
  height: 14px
  display: block
  position: absolute
  top: 4px
  right: 5px
  background-image: url('~icons/basic/close2.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  cursor: pointer

.deleteProfileDialog
  padding: 16px
  display: flex
  flex-direction: column
  gap: 16px
  align-items: center


.modalTitle
  width: 100%
  text-align: center
  font-weight: 500
  font-size: 22px
  line-height: 26px
  color: #4C4E51
  display: flex
  align-items: center
  justify-content: center
  gap: 24px
  margin-bottom: 24px

.modalBody
  width: 272px
  margin: 0 auto
  display: flex
  flex-direction: column
  gap: 8px

  .codeTitle
    font-size: 16px
    margin-bottom: 4px
</style>
