<template>
  <div :class="$style.container">
    <p :class="$style.stepTitle">{{ getPatternName }}</p>
    <p :class="$style.stepTitle">Внесение данных из приложений к {{ contractType }}</p>
    <p :class="$style.smallTitle">Для загрузки приложений к {{ contractType }} скачайте шаблоны документов в формате MS
      Excel</p>

    <div v-for="(attachment, index) in attachments" :key="index" :class="$style.attachment">
      <a :class="$style.fileAttachment" :download="attachment.attachmentName"
         :href="`${attachmentPath}${attachment.templateName}.xlsx`">
        <img alt="" height="40" src="~@/assets/icons/file/template.svg" width="40"/>
        <span>Шаблон {{ attachment.attachmentName }}</span>
      </a>
      <a :class="$style.downloadIcon" :download="attachment.attachmentName"
         :href="`${attachmentPath}${attachment.templateName}.xlsx`"
         title="скачать">
        <img alt="скачать" height="24" src="~@/assets/icons/file/download.svg" width="24"/>
      </a>
    </div>

    <v-divider/>

    <p :class="$style.subTitle" style="padding: 0 9px">Точки поставки, передачи и потребителей АО "Мосэнергосбыт"</p>

    <div style="padding: 0 9px">
      <p :class="$style.uploadingTitle">Загрузка Приложений: Приложения 1, 1.1, 17, 18</p>
      <c-uploading
        v-model="filledAttachment1"
        :class="$style.filledDocuments"
        :error="{ error: getErrors.filledAttachment1.isError}"
        accept=".xlsx, .xls"
        accept-title="XLSX, XLS или перетащите его в эту область."
        role="filledAttachment1"
      />
    </div>

    <v-divider/>

    <div v-if="getContract.attachments.length > 0">
      <div :class="$style.fileAttachmentWrp">
        <div v-for="(attachment, index) in nonDepartmentApplications" :key="index" :class="$style.attachment">
          <a :class="$style.fileAttachment" :download="attachment.file.name"
             :href="fileUrl(attachment.file)">
            <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
            <span>{{ attachment.file.name }}</span>
          </a>
          <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc(attachment)">
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>
      </div>
    </div>

    <v-divider v-if="getContract.attachments.length > 0" style="border-color: #2F82DF"/>

    <div v-for="(department, indexDepartment) in departmentsId" :key="indexDepartment">
      <p :class="$style.subTitle" style="padding: 0 9px">
        <span v-if="departmentsId.length > 1">{{ indexDepartment + 1 }}. </span>
        Точки поставки по отделениям {{ getContract.editableSides.SUPPLIER.company.name }}
      </p>

      <div :class="$style.attachment" style="padding: 0 9px">
        <p :class="$style.smallTitle" style="margin: 16px 0">Отделение: {{ departments[department].name }}</p>
        <div style="display: flex; gap: 16px; padding: 0 9px">
          <p :class="$style.deleteText">Удалить отделение</p>
          <a :class="$style.deleteIcon" href="#" style="margin: auto 0" title="удалить"
             @click="deleteDepartment(department)">
            <img alt="удалить" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>
      </div>

      <div style="padding: 0 9px; margin-bottom: 24px">
        <p :class="$style.uploadingTitle">Загрузка Приложений: Приложения 2, 3</p>
        <c-uploading
          v-model="filledDocuments"
          :class="$style.filledDocuments"
          :error="{ error: getErrors.filledDocuments.isError && getErrors.filledDocuments.role?.indexOf(department) !== -1 }"
          :role="'filledDocuments_' + department"
          accept=".xlsx, .xls"
          accept-title="XLSX, XLS или перетащите его в эту область."
          @mousemove="uploadingMouseMove(department)"
        />
      </div>

      <div :class="$style.attachmentsList" style="margin-bottom: 24px">
        <div v-if="departments[department]['ElectricityConsumerAddition']?.file" :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <a :class="$style.fileAttachment"
               :download="departments[department]['ElectricityConsumerAddition']?.file.name"
               :href="fileUrl(departments[department]['ElectricityConsumerAddition']?.file)">
              <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
              <span>{{ departments[department]['ElectricityConsumerAddition']?.file.name }}</span>
            </a>
          </div>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
             @click="deleteDoc2(department, true)">
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>

        <div v-if="departments[department]['ElectricitySupplyPointAddition']?.file" :class="$style.attachment">
          <div :class="$style.fileAttachmentWrp">
            <a :class="$style.fileAttachment"
               :download="departments[department]['ElectricitySupplyPointAddition']?.file.name"
               :href="fileUrl(departments[department]['ElectricitySupplyPointAddition']?.file)">
              <img alt="" height="40" src="~@/assets/icons/file/filled_template.svg" width="40"/>
              <span>{{ departments[department]['ElectricitySupplyPointAddition']?.file.name }}</span>
            </a>
          </div>
          <a :class="$style.downloadIcon" href="#" style="margin-top: 24px;" title="удалить"
             @click="deleteDoc3(department, true)">
            <img alt="" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
          </a>
        </div>
      </div>

      <v-divider/>
    </div>

    <div v-if="isAddDepartment" :class="$style.attachmentsList">
      <div :class="$style.attachment">
        <p :class="$style.subTitle" style="margin: auto 0">
          Точки поставки по отделениям {{ getContract.editableSides.SUPPLIER.company.name }}
        </p>
        <a :class="$style.deleteIcon" href="#" title="удалить"
           @click="isAddDepartment = false">
          <img alt="удалить" height="24" src="~@/assets/icons/file/delete.svg" width="24"/>
        </a>
      </div>

      <select v-model="addedDepartment" :class="$style.select">
        <option disabled>Выберите отделение</option>
        <template v-for="(depList, indexDepList) in departmentList">
          <option :key="indexDepList" :hidden="isHidden(depList.id)" :value="depList">
            {{ depList.name }}
          </option>
        </template>
      </select>
    </div>

    <v-divider v-if="isAddDepartment"/>

    <select-button :display-icon="false" :items="[{title: 'Добавить отделение', method: addDepartment, }]" type="add"/>
    <dialog-template v-model="visible" center>
      <p :class="$style.searchNote">Загрузка файлов. Подождите немного.</p>
      <div :class="$style.modalContainer">
        <progress-bar
          :fake-time-ms="25"
          :is-complete="true"
          text="Загрузка"
          @is-hundred-percent="downloadFinished"
        />
      </div>
    </dialog-template>
  </div>
</template>

<script>
import { getFileUrl } from 'lib/utils/files';
import { EVENT_BUS } from '@/eventBus';
import { excelParseWithWorker } from 'utils/excel';
import { nanoid } from 'nanoid';
import { mapActions, mapGetters } from 'vuex';
import { normalizeDiacritics } from 'utils/string/normalize';
import {
  esoOekRmr1_14Patterns,
  esoOekRmr4_44Patterns,
} from 'views/private/UploadDocument/patterns/EsoOekRmrPatterns';
import {
  electricitySupplyPointAdditionAdditionExcelPattern,
  electricityUsagePointAdditionInputExcelPattern,
  electricityUsagePointAdditionOutputExcelPattern,
  electricityConsumersAdditionExcelPattern,
} from 'views/private/UploadDocument/patterns/ThreeSidePatterns';
import VDivider from 'components/VDivider/VDivider.vue';
import ProgressBar from 'components/ProgressBar/ProgressBar.vue';
import SelectButton from 'components/SelectButton/SelectButton.vue';
import DialogTemplate from 'templates/DialogTemplate.vue';

const attachment1NameWithoutExtension = 'Приложение_1-Точки приема из сети Исполнителя-1 в сеть Исполнителя-2';
const attachment11NameWithoutExtension = 'Приложение_1.1-Точки передачи из сети Исполнителя-2';
const attachment2NameWithoutExtension = 'Приложение_2-Реестр договоров энергоснабжения с Потребителями, присоединенными к сети Исполнителя-2';
const attachment3NameWithoutExtension = 'Приложение_3-Точки поставки потребителей находящихся на обслуживании в отделении АО \'Мосэнергосбыт\'';
const attachment17NameWithoutExtension = 'Приложение_17-Технические характеристики точек присоединения Исполнителя-2 к сетям Исполнителя-1';
const attachment18NameWithoutExtension = 'Приложение_18-Перечень объектов межсетевой координации Исполнителя-1 и Исполнителя-2';


export default {
  name: 'MesRmrOek',
  components: {
    DialogTemplate,
    SelectButton, ProgressBar, VDivider,
    CUploading: () => import('atoms/common/CUploading.vue'),
  },
  async created() {
    await this.completeDepartments();
  },
  data() {
    return {
      filledAttachment1: [],
      isWaitingSubmit: false,
      departmentId: -1,
      departmentList: [],
      filledDocuments: [],
      addedDepartment: {},
      isAddDepartment: false,
      visible: false,
      attachments: [
        {
          attachmentNameWithoutExtension: attachment1NameWithoutExtension,
          attachmentName: `${attachment1NameWithoutExtension}.xlsx`,
          templateName: 'attachment_1',
          pattern: electricityUsagePointAdditionInputExcelPattern,
          name: 'ElectricityUsagePointsAddition',
          kind: 'receiving',
          number: 1,
        },
        {
          attachmentNameWithoutExtension: attachment11NameWithoutExtension,
          attachmentName: `${attachment11NameWithoutExtension}.xlsx`,
          templateName: 'attachment_1_1',
          pattern: electricityUsagePointAdditionOutputExcelPattern,
          name: 'ElectricityUsagePointsAddition',
          kind: 'transfer',
          number: 2,
        },
        {
          attachmentNameWithoutExtension: attachment2NameWithoutExtension,
          attachmentName: `${attachment2NameWithoutExtension}.xlsx`,
          templateName: 'attachment_2',
          pattern: electricityConsumersAdditionExcelPattern,
          name: 'ElectricityConsumerAddition',
          number: 3,
        },
        {
          attachmentNameWithoutExtension: attachment3NameWithoutExtension,
          attachmentName: `${attachment3NameWithoutExtension}.xlsx`,
          templateName: 'attachment_3',
          pattern: electricitySupplyPointAdditionAdditionExcelPattern,
          name: 'ElectricitySupplyPointAddition',
          kind: 'related',
          number: 4,
        },
        {
          attachmentNameWithoutExtension: attachment17NameWithoutExtension,
          attachmentName: `${attachment17NameWithoutExtension}.xlsx`,
          templateName: 'attachment_17',
          pattern: esoOekRmr4_44Patterns,
          name: 'MeasurementDevicesBoilerAddition',
          kind: 'part',
          number: 5,
        },
        {
          attachmentNameWithoutExtension: attachment18NameWithoutExtension,
          attachmentName: `${attachment18NameWithoutExtension}.xlsx`,
          templateName: 'attachment_18',
          pattern: esoOekRmr1_14Patterns,
          name: 'ElectricityDeliveryPointsBoilerAddition',
          kind: 'first',
          number: 6,
        },
      ],
    };
  },
  computed: {
    attachmentPath() {
      if (this.getContract.action === 'upload') {
        return '/xlsx/contract-patterns/existing/mes-rmr-oek/';
      }

      return '/xlsx/contract-patterns/new/mes-rmr-oek/';
    },
    isHidden() {
      return (id) => this.departments[id];
    },
    contractType() {
      if (this.getContract.type === 'contract') {
        return 'договору';
      }
      return 'доп.соглашению';

    },
    nonDepartmentApplications() {
      return this.getContract.attachments.filter((attach) => !attach.departmentData);
    },
    departments() {
      const result = {};
      this.getContract.attachments.filter((attach) => attach.departmentData).forEach((attach) => {

        if (!result[attach.departmentData.id]) {
          result[attach.departmentData.id] = {
            id: attach.departmentData.id,
            name: attach.departmentData.name,
          };
        }
        result[attach.departmentData.id][attach.name] = attach;
      });

      return result;
    },
    departmentsId() {
      return Object.keys(this.departments);
    },
    ...mapGetters('electricityTransmissionContract', ['getContract', 'getErrors', 'getPatternName']),
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }
        const incorrectFileNames = [attachment2NameWithoutExtension, attachment3NameWithoutExtension];
        this.isWaitingSubmit = true;
        this.visible = this.isWaitingSubmit;

        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();

          const attachment = this.getAttachmentByFileName(file.name);
          if (!attachment || incorrectFileNames.includes(attachment.attachmentNameWithoutExtension)) {
            incorrectFiles.push(file.name);
          } else {
            const res = await excelParseWithWorker(
              file.code,
              { ...attachment.pattern },
              taskId,
            );
            await this.setAttachments({
              attachment: {
                name: attachment.name,
                kind: attachment.kind,
                value: res.data,
                errors: res.errors,
                file,
                number: attachment.number,
              },
            });
          }
        }

        if (incorrectFiles.length > 0) {
          let attachment1Name = '';
          this.visible = false;
          this.attachments.forEach((item) => {
            attachment1Name += `${item.attachmentNameWithoutExtension}<br/>`;
          });

          this.onShownNotification(incorrectFiles, attachment1Name);
        }
        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
    filledDocuments: {
      immediate: true,
      async handler(val) {
        if (val.length === 0) {
          return;
        }

        this.isWaitingSubmit = true;
        this.visible = this.isWaitingSubmit;
        const incorrectFiles = [];

        for (let i = 0; i < val.length; i++) {
          const file = val[i];
          const taskId = nanoid();
          const attachment = this.getAttachmentByFileName(file.name);
          const departmentData = {
            id: this.departments[this.departmentId].id,
            name: this.departments[this.departmentId].name,
          };
          if (attachment && attachment.attachmentNameWithoutExtension === attachment2NameWithoutExtension) {
            const res = await excelParseWithWorker(
              file.code,
              { ...attachment.pattern },
              taskId,
            );

            this.deleteDoc2(departmentData.id);

            await this.setAttachments({
              attachment: {
                name: attachment.name,
                departmentData: departmentData,
                kind: attachment.kind,
                value: res.data,
                errors: res.errors,
                file,
                number: attachment.number,
              },
            });
          } else if (attachment && attachment.attachmentNameWithoutExtension === attachment3NameWithoutExtension) {
            const res = await excelParseWithWorker(
              file.code,
              { ...attachment.pattern },
              taskId,
            );

            this.deleteDoc3(this.departmentId);

            await this.setAttachments({
              attachment: {
                name: attachment.name,
                departmentData: departmentData,
                kind: attachment.kind,
                value: res.data,
                errors: res.errors,
                file,
                number: attachment.number,
              },
            });
          } else {
            incorrectFiles.push(file.name);
          }
        }

        if (incorrectFiles.length > 0) {
          this.visible = false;
          this.onShownNotification(incorrectFiles, `${attachment2NameWithoutExtension}<br/>${attachment3NameWithoutExtension}`);
        }

        this.filledDocuments = [];
        this.isWaitingSubmit = false;
      },
    },
    async addedDepartment() {
      if (!this.addedDepartment?.id) {
        return;
      }

      await this.setAttachments({
        attachment: {
          name: 'ElectricityConsumerAddition',
          departmentData: this.addedDepartment,
          number: this.addedDepartment.name + 1,
        },
      });

      await this.setAttachments({
        attachment: {
          name: 'ElectricitySupplyPointAddition',
          departmentData: this.addedDepartment,
          number: this.addedDepartment.name + 2,
        },
      });

      this.addedDepartment = {};
      this.isAddDepartment = false;

    },
  },
  methods: {
    downloadFinished() {
      setTimeout(() => {
        this.visible = false;
      }, 150);
    },
    getAttachmentByFileName(fileName) {
      return this.attachments.find((attachment) => normalizeDiacritics(fileName).indexOf(normalizeDiacritics(attachment.attachmentName)) !== -1);
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    onShownNotification(files, attachments) {
      EVENT_BUS.$emit('NOTIFICATION_LISTENER', {
        data: {
          title: '',
          message: `<p><b>Имя файла:</b></p><p>${files}</p><p><b>Файл некорректно заполнен или не соответсвуют шаблону:</b></p><p>${attachments}</p>`,
        },
        type: 'ALERT',
        width: 700,
      });
    },
    deleteDoc(attachment) {
      this.deleteAttachment({ attachment });
    },
    addDepartment() {
      this.isAddDepartment = true;
    },
    async completeDepartments() {
      this.departmentList = await this.mesDepartments();
    },
    deleteDoc2(departmentId, checkEmptyDepartment) {
      if (this.departments[departmentId].ElectricityConsumerAddition) {
        const departmentData = this.departments[departmentId].ElectricityConsumerAddition.departmentData;
        this.deleteDoc(this.departments[departmentId].ElectricityConsumerAddition);

        if (checkEmptyDepartment && !this.departments[departmentId]) {
          this.addEmptyAdditionForDepartment(departmentData);
        }
      }
    },
    deleteDoc3(departmentId, checkEmptyDepartment) {
      if (this.departments[departmentId].ElectricitySupplyPointAddition) {
        const departmentData = this.departments[departmentId].ElectricitySupplyPointAddition.departmentData;
        this.deleteDoc(this.departments[departmentId].ElectricitySupplyPointAddition);

        if (checkEmptyDepartment && !this.departments[departmentId]) {
          this.addEmptyAdditionForDepartment(departmentData);
        }
      }
    },
    deleteDepartment(departmentId) {
      this.deleteDoc2(departmentId);
      this.deleteDoc3(departmentId);
    },
    uploadingMouseMove(departmentId) {
      this.departmentId = departmentId;
    },
    ...mapActions('electricityTransmissionContract', ['setAttachments', 'deleteAttachment']),
    ...mapActions('dataLists', ['mesDepartments']),
  },
};
</script>

<style lang="sass" module>
.container
  width: 800px
  display: flex
  flex-direction: column
  gap: 24px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 22px
    font-weight: 500
    line-height: 26px
    letter-spacing: 0
    color: #4C4E51

    span
      margin-right: 12px
      color: #979CA2

  .smallTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .subTitle
    font-family: 'Roboto Condensed', sans-serif
    font-size: 18px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0
    color: #4C4E51

  .stepHeader
    +base-title
    margin-top: 58px
    padding: 8px
    background-color: #9e9fa2
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  .select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    border-color: #C1C4C7

.uploadingTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 16px
  font-weight: 500
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51

.attachmentsList
  display: flex
  flex-direction: column
  gap: 32px
  padding: 0 9px

.attachment, .fileArchive, .attachmentReport
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  text-align: left
  color: #4C4E51


.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachmentWrp
  display: flex
  flex-direction: column
  gap: 16px

.attachmentTitle
  font-family: 'Roboto Condensed', sans-serif
  font-size: 18px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #4C4E51


.fileAttachment
  display: flex
  align-items: center
  text-decoration: underline
  color: #4C4E51

  img
    width: 40px
    height: 40px
    margin-right: 16px

  span
    font-family: 'Roboto Condensed', sans-serif
    font-size: 16px
    font-weight: 400
    line-height: 22px
    letter-spacing: 0

  &:hover
    text-decoration: none

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

  img
    margin-right: 8px

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.modalContainer
  padding: 0 40px 40px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757 !important

.spoiler
  margin: 0 !important
  color: #2F82DF !important
  text-decoration: underline
  cursor: pointer

  &:hover
    text-decoration: none

.inputField
  +input-text
  font-family: 'Roboto Condensed', sans-serif
  padding: 4px 16px
  color: gray

.button
  border-radius: 24px
  border: 1px solid #DEE2E6
  background: inherit
  padding: 0 20px
  height: 40px

  .inner
    display: flex
    gap: 10px

  .text
    display: flex
    align-self: center

.deleteText
  font-family: 'Roboto Condensed', sans-serif
  font-size: 16px
  font-weight: 400
  line-height: 20px
  letter-spacing: 0
  color: #C1C4C7
  margin: auto 0
</style>
