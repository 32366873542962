<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <p :class="$style.stepTitle">{{ stepNumber.title() }}</p>

      <p :class="$style.smallTitle">Расчётный период*</p>
      <v-date-picker
        v-model="uploadReadings.Date"
        :err="uploadReadings.Date_error.error"
        format="YYYY-MM"
        role="Date_error"
        type="month"
      />
      <p v-if="uploadReadings.Date_error.error" class="errorText">Поле обязательно для заполнения</p>


      <p :class="$style.stepTitle">Представитель сетевой организации</p>

      <div :class="$style.grid2Column">
        <div>
          <v-input
            v-model="uploadReadings.Utility.Representative.Name.FamilyName"
            :err="uploadReadings.Utility.Representative.Name.FamilyName_error.error"
            label="Фамилия*"
            role="Utility_FamilyName"
          />
          <p v-if="uploadReadings.Utility.Representative.Name.FamilyName_error.error" class="errorText">Поле обязательно
            для заполнения</p>
        </div>

        <div>
          <v-input
            v-model="uploadReadings.Utility.Representative.Name.FirstName"
            :err="uploadReadings.Utility.Representative.Name.FirstName_error.error"
            label="Имя*"
            role="Utility_FirstName"
          />
          <p v-if="uploadReadings.Utility.Representative.Name.FirstName_error.error" class="errorText">Поле обязательно
            для заполнения</p>
        </div>
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Utility.Representative.Name.Patronymic"
          label="Отчество"
        />
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Utility.Representative.Position"
          :err="uploadReadings.Utility.Representative.Position_error.error"
          label="Должность представителя*"
          role="Utility_Position"
        />
        <p v-if="uploadReadings.Utility.Representative.Position_error.error" class="errorText">Поле обязательно для
          заполнения</p>
      </div>

      <p :class="$style.stepTitle">Потребитель</p>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Consumer.Name"
          :err="uploadReadings.Consumer.Name_error.error"
          label="Наименование юридического лица*"
          role="Consumer_Name"
        />
        <p v-if="uploadReadings.Consumer.Name_error.error" class="errorText">Поле обязательно
          для заполнения</p>
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Consumer.ObjectName"
          :err="uploadReadings.Consumer.ObjectName_error.error"
          label="Наименование объекта*"
          role="Consumer_ObjectName"
        />
        <p v-if="uploadReadings.Consumer.ObjectName_error.error" class="errorText">Поле обязательно
          для заполнения</p>
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Consumer.Address"
          :err="uploadReadings.Consumer.Address_error.error"
          label="Местонахождение (адрес)*"
          role="Consumer_Address"
        />
        <p v-if="uploadReadings.Consumer.Address_error.error" class="errorText">Поле обязательно
          для заполнения</p>
      </div>

      <div :class="$style.grid2Column">
        <div>
          <v-input
            v-model="uploadReadings.Consumer.Representative.Name.FamilyName"
            :err="uploadReadings.Consumer.Representative.Name.FamilyName_error.error"
            label="Фамилия представителя"
            role="Consumer_FamilyName"
          />
          <p v-if="uploadReadings.Consumer.Representative.Name.FamilyName_error.error" class="errorText">Поле
            обязательно для
            заполнения</p>
        </div>

        <div>
          <v-input
            v-model="uploadReadings.Consumer.Representative.Name.FirstName"
            :err="uploadReadings.Consumer.Representative.Name.FirstName_error.error"
            label="Имя представителя"
            role="Consumer_FirstName"
          />
          <p v-if="uploadReadings.Consumer.Representative.Name.FirstName_error.error" class="errorText">Поле
            обязательно для
            заполнения</p>
        </div>
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Consumer.Representative.Name.Patronymic"
          label="Отчество представителя"
        />
      </div>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.Consumer.Representative.Position"
          :err="uploadReadings.Consumer.Representative.Position_error.error"
          label="Должность представителя"
          role="Consumer_Position"
        />
        <p v-if="uploadReadings.Consumer.Representative.Position_error.error" class="errorText">Поле обязательно для
          заполнения</p>
      </div>

      <p :class="$style.stepTitle">Измерительный комплекс</p>

      <div :class="$style.gridFluid">
        <v-input
          v-model="uploadReadings.MeasuringComplex.PlaceInstallation"
          :err="uploadReadings.MeasuringComplex.PlaceInstallation_error.error"
          label="Место установки*"
          role="MeasuringComplex_PlaceInstallation"
        />
        <p v-if="uploadReadings.MeasuringComplex.PlaceInstallation_error.error" class="errorText">Поле обязательно
          для заполнения</p>
      </div>

    </div>
    <div :class="$style.content">
      <p :class="$style.stepTitle">Внесение данных об отпуске и покупке электрической энергии (мощности)</p>
      <p :class="$style.smallTitle">Для загрузки данных скачайте шаблоны документов в формате MS Excel</p>

      <p :class="$style.stepNote"><b>Важно:</b> скачайте и заполните шаблоны в точном соответствии с названием полей
        шаблона. Не оставляйте поля пустыми.</p>

      <div :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="attachment1NameFinally"
           href="/xlsx/upload-readings/attachment_1_finally.xlsx">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40"/>
          <span>Шаблон {{ attachment1NameFinally }}</span>
        </a>
        <a :class="$style.downloadIcon" :download="attachment1NameFinally"
           href="/xlsx/upload-readings/attachment_1_finally.xlsx"
           title="скачать">
          <file-download/>
        </a>
      </div>

      <p :class="$style.stepTitle">Загрузите заполненные документы XLSX на Платформу </p>

      <c-uploading
        v-model="filledAttachment1"
        :class="$style.filledDocuments"
        :error="uploadReadings.attachments_error"
        accept=".xlsx, .xls"
        accept-title="XLSX, XLS или перетащите его в эту область."
        role="filledAttachments"
      />

      <div v-if="uploadReadings.attachment1File" :class="$style.attachment">
        <a :class="$style.fileAttachment" :download="uploadReadings.attachment1File.name"
           :href="fileUrl(uploadReadings.attachment1File)">
          <img alt="" height="40" src="~@/assets/icons/file/attachment.svg" width="40"/>
          <span>{{ uploadReadings.attachment1File.name }}</span>
        </a>
        <a :class="$style.downloadIcon" href="#" title="удалить" @click="deleteDoc1">
          <icon-delete/>
        </a>
      </div>

      <dialog-template v-model="isWaitingSubmit" center>
        <p :class="$style.searchNote">Проводится анализ файлов. Подождите немного.</p>
        <horizontal-progress-bar v-if="isWaitingSubmit"/>
      </dialog-template>

    </div>
  </div>
</template>

<script>
import { getFileUrl, getTestEmptyFile } from 'lib/utils/files.js';
import { read, utils } from 'xlsx';
import { VDatePicker, VInput } from 'components';
import { normalizeDiacritics } from '@/utils/string/normalize';

const attachment1NameWithoutExtensionFinally = 'Реестр показаний приборов учёта (Конечные потребители)';

export default {
  name: 'UploadReadings',
  components: {
    CUploading: () => import('atoms/common/CUploading.vue'),
    // CDatePicker: () => import('atoms/common/inputs/CDatePicker'),
    FileDownload: () => import('atoms/icons/file/FileDownload.vue'),
    IconDelete: () => import('atoms/icons/action/Delete.vue'),
    DialogTemplate: () => import('templates/DialogTemplate.vue'),
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar.vue'),
    VDatePicker,
    VInput,
  },
  props: {
    stepNumber: Object,
    uploadReadings: Object,
    disable: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    if (this.$route.path.indexOf('reading-sheets/finally-multiple-upload') !== -1) {
      this.uploadReadings.ReportPointsKind = 'delivery';
    }
  },
  data() {
    return {
      isWaitingSubmit: false,
      filledAttachment1: [],
      attachment1NameWithoutExtensionFinally: attachment1NameWithoutExtensionFinally,
      attachment1NameFinally: `${attachment1NameWithoutExtensionFinally}.xlsx`,
    };
  },
  watch: {
    filledAttachment1: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          return;
        }

        this.uploadReadings.documentSubmissionErrors = [];
        this.isWaitingSubmit = true;

        const incorrectFiles = [];

        function getAttachmentHead(tableArray) {
          const title1 = tableArray.slice(2, 3)[0].slice(0, 16);
          const title2 = tableArray.slice(3, 4)[0].slice(0, 16);
          const title3 = tableArray.slice(4, 5)[0].slice(0, 16);

          const titlesHead = [];
          title1.forEach((it, index) => {
            let title = title1[index];

            if (title1[index].length === 0 && index > 0) {
              let index2 = index - 1;

              do {
                title = title1[index2];
                index2--;
              }
              while (index2 >= 0 && title.length === 0);
            }

            if (title2[index].length > 0) {
              title = `${title}. ${title2[index]}`;
            }

            if (title3[index].length > 0) {
              title = `${title}. ${title3[index]}`;
            }

            titlesHead.push(title);
          });

          return titlesHead;
        }

        try {
          val.forEach((file) => {
            const excel = read(file.code, {
              type: 'base64',
              cellText: false,
              cellDates: true,
            });

            const tableArray = utils.sheet_to_json(excel.Sheets[excel.SheetNames[0]], {
              header: 1,
              defval: '',
              raw: false,
              blankrows: false,
              dateNF: 'DD/MM/YYYY',
            });

            if (tableArray.length === 0) {
              return [];
            }

            if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(this.attachment1NameWithoutExtensionFinally)) !== -1) {
              this.uploadReadings.attachment1 = tableArray.slice(6, tableArray.length);
              this.uploadReadings.attachment1File = file;
              this.uploadReadings.titlesHead = getAttachmentHead(tableArray);
            } else {
              incorrectFiles.push(file.name);
            }

          });

        } catch (e) {
          console.log('error XLSX.read', e);
        }

        if (incorrectFiles.length > 0) {
          this.vueShowNotification(
            '',
            `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Не соответсвуют шаблону:</b></p><p>${this.attachment1NameFinally}</p>`,
          );
        }

        this.filledAttachment1 = [];

        this.isWaitingSubmit = false;
      },
    },
  },
  methods: {
    getTestEmptyFile() {
      return getTestEmptyFile();
    },
    fileUrl(file) {
      return getFileUrl(file);
    },
    deleteDoc1() {
      this.uploadReadings.attachment1 = [];
      this.uploadReadings.attachment1File = null;
    },
  },
};
</script>

<style lang="sass" module>
.container
  position: relative

.content
  width: 560px
  margin: 0 auto 0
  z-index: 999

  .stepTitle
    +base-title
    margin-top: 32px
    margin-bottom: 32px

    span
      margin-right: 12px
      color: #979CA2

  .stepNote
    +base-text
    width: 100%
    padding: 16px
    margin-top: 20px
    margin-bottom: 32px
    background-color: rgba(242, 201, 76, 0.25)

  .smallTitle
    +card-title-small
    margin-top: 32px
    margin-bottom: 4px

  .stepCaption
    +card-caption
    margin-bottom: 16px

  .fieldTitle
    +label-text
    margin-top: 32px

  .grid2Column
    +grid-2-column

  .gridFluid
    +grid-fluid

  select
    height: 40px
    width: 100%
    font-size: 18px
    padding-left: 12px
    margin-top: 8px
    margin-bottom: 16px
    border-color: #C1C4C7


.attachment, .fileArchive, .attachmentReport
  +base-subtitle

.attachment, .attachmentReport
  display: flex
  justify-content: space-between

.fileArchive
  +base-button-text
  width: 100%
  display: flex
  justify-content: center
  margin-top: 32px

.fileAttachment
  display: flex
  align-items: center
  margin-top: 12px

  img
    width: 40px
    height: 40px
    margin-right: 16px

  color: #0E0F0F

.deleteIcon
  vertical-align: middle

.downloadIcon
  display: flex
  align-items: center

.downloadIcon, .deleteIcon
  color: #4C4E51

  svg
    width: 24px
    height: 24px
    fill: #4C4E51
    margin-right: 13px

.searchNote
  +base-title
  text-align: center
  margin-top: 64px
  margin-bottom: 64px

.errorReport
  border: 1px solid #EB5757

.completed
  border: 1px solid #54B687

.errorReport, .completed
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-top: 32px
  margin-bottom: 32px

  img
    margin-left: 36px
    margin-right: 18px

  .title
    +title-small-uppercase
    margin-bottom: 8px

  .documentName
    +small-text
    margin-bottom: 4px

  .error
    color: #EB5757

  .note
    +card-title-small
    margin-top: 14px
    margin-bottom: 18px

    a
      text-decoration: underline
      color: #0E0F0F

.underline
  text-decoration: underline

.submit
  margin-top: 32px

.error
  color: #EB5757
</style>
