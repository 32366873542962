<template>
  <div class="vuetify-styles" data-app>
    <v-row>
      <v-col>
        <div>
          <v-label :class="{
          'error-label': !isValid,
        }" :style="labelStyles">{{ internalTitle }}
          </v-label>
        </div>
        <v-autocomplete ref="autocomplete" v-model="selectedItem"
                        :append-icon="selectedItem ? 'mdi-close non-rotating-icon' : 'mdi-chevron-down'"
                        :disabled="isDisabled" :error="!isValid"
                        :item-text="searchBy" :items="internalItems" :loading="isLoading"
                        :menu-props="{ maxHeight: 200 }"
                        :multiple="multiple" :required="isRequired&&!multiple" :rules="[rules.requiredForNull]" :search-input.sync="searchItem"
                        :search-threshold="3"
                        dense
                        item-value="id"
                        outlined
                        style="border: none"
                        @focusout="checkValidity"
                        @input="onChange"
                        @click:append="onClear">
          <template v-if="multiple" #selection="{ item, index }">
            <v-chip
              :clearable="!isDisabled"
              :close="!isDisabled"
              :disabled="isDisabled"
              class="truncate-text"
              color="primary"
              label
              small
              @click:close="unselect(index)"
            > {{ item[searchBy] }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { BASE_URL } from '@/constants/api';
import { distinctBy } from 'utils/arrays/arrays';
import { difference } from 'lodash/array';
import { debounce } from 'lodash';

export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    platformFont: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    selected: {
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    dependency: {
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    urlDefaultData: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    searchBy: {
      type: String,
      default: 'name',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:search', 'update:items', 'change', 'changeItem'],
  created() {
    if (this.searchItem != null) {
      this.fetchData();
    }
  },
  data() {
    return {
      reFetchData: debounce(function() {
        this.fetchData();
      }, 500),
      multipleItems: [],
      internalItems: [],
      isLoading: false,
      isValid: true,
      rules: {
        requiredForNull: (value) => (this.isRequired && !this.multiple ? !!value || 'Поле обязательное' : true),
      },
    };
  },
  computed: {
    labelStyles() {
      if (!this.platformFont) return;
      return {
        fontFamily: '\'Roboto Condensed\', sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
      };
    },

    internalTitle() {
      return this.isRequired && this.title ? `${this.title}*` : this.title;
    },

    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
        this.$emit('changeItem', this.items?.filter((it) => it.id === val)[0]);
      },
    },

    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },

    isSearch() {
      return this.searchItem?.length;
    },
  },

  watch: {
    internalItems() {
      // Костыль из-за того что V-Autocomplete не эмитит null в случае изменения данных
      if (!this.multiple && !this.internalItems?.some((it) => it.id === this.selectedItem)) this.onClear();
      this.$emit('update:items', this.internalItems);
    },
    items: {
      immediate: true,
      handler() {
        this.internalItems = this.items;
      },
    },
    isDisabled(newVal) {
      if (!newVal) {
        this.checkValidity();
      }
    },
    selectedItem() {
      this.checkValidity();
    },
    searchItem() {
      this.reFetchData();
    },
    async dependency() {
      this.reFetchData();
    },
  },

  methods: {
    async fetchData() {
      this.isLoading = true;
      const response = await fetch(this.getURL());
      const data = await response.json();
      this.internalItems = [...this.multipleItems, ...data];
      this.isLoading = false;
    },

    getURL() {
      return this.isSearch ? BASE_URL + this.url + this.searchItem : BASE_URL + this.urlDefaultData;
    },

    onChange(item) {
      if (this.multiple) {
        const deleted = difference(this.selectedItem, item);
        const added = difference(item, this.selectedItem);
        this.multipleItems = this.multipleItems.filter((it) => !deleted.some((del) => del === it.id));
        this.multipleItems = distinctBy([...added.map((it) => this.internalItems.find((item) => it === item.id)), ...this.multipleItems], (it) => it.id);
      }
      this.selectedItem = item;
    },

    onClear() {
      this.selectedItem = null;
      this.multipleItems = [];
    },
    checkValidity() {
      this.isValid = !this.isRequired || this.isDisabled || !!this.selectedItem;
    },
    resetValidation() {
      this.$refs.autocomplete.resetValidation();
      this.isValid = true;
    },
    unselect(index) {
      if (!this.items.splice) return;
      const item = this.selectedItem[index];
      if (!item) return;
      this.selectedItem.splice(index, 1);
      this.multipleItems = this.multipleItems.filter((it) => it.id !== item.id);
    },
  },
};
</script>

