<template>
  <div class="subdivision-node" :style="styleNode">
    <template v-if="!node.isEmpty">
      <div
          :id="'node-top-' + node.id"
          :class="{
                  ['subdivision-node-top']: true,
                  ['subdivision-node-top--selected']: selected === 'top'
                }"
          @click="clickNode(node, 'top')"
      >
        <span>{{ node.name }}</span>
        <info-gray class="subdivision-node-top-icon"/>
      </div>
      <div
          :id="'node-bottom-' + node.id"
          :class="{
                  ['subdivision-node-bottom']: true,
                  ['subdivision-node-bottom--selected']: selected === 'bottom',
                  ['subdivision-node-bottom--active']: node[grouping.propName]
                }"
          @click="clickNode(node, 'bottom')"
      >
        <span v-if="node[grouping.propName]">{{ node[grouping.propName].name }}</span>
        <span v-else>{{ grouping.out }}</span>
        <settings class="subdivision-node-bottom-icon"/>
      </div>
    </template>
  </div>
</template>

<script>
import InfoGray from 'atoms/icons/basic/InfoGray.vue';
import Settings from 'atoms/icons/basic/Settings.vue';

export default {
  name: 'SubdivisionNode',
  components: {
    InfoGray,
    Settings,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    styleNode: {
      type: Object,
      required: true,
    },
    grouping: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickNode(node, target) {
      this.$emit('clickNode', node, target);
    },
  },
};

</script>

<style lang="scss" scoped>

.subdivision-node {
  position: relative;

  &-top {
    border: 1px solid #2F82DF;
    padding: 12px 40px 12px 24px;
    border-radius: 24px;
    background-color: #FFFFFF;
    position: relative;
    z-index: 2;
    color: #4C4E51;
    cursor: pointer;

    &-icon {
      position: absolute;
      top: 9px;
      right: 18px;
    }

    svg {
      fill: #C1C4C7;
    }

    &--selected, &:hover {
      background-color: #2F82DF;
      color: #FFFFFF;
      cursor: default;

      svg {
        fill: #FFFFFF;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-bottom {
    min-height: 80px;
    padding: 12px 38px 12px 24px;
    border-radius: 24px;
    background-color: #F5F6F6;
    margin-top: -40px;
    position: relative;
    z-index: 1;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: #262729;

    svg {
      fill: #ACB0B5;
    }

    &--selected, &--active:hover {
      background-color: #2F82DF;
      color: #FFFFFF;
      cursor: default;

      svg {
        fill: #FFFFFF;
      }
    }

    &--active {
      &:hover {
        cursor: pointer;
      }
    }

    span {
      padding-top: 40px;
    }

    &-icon {
      position: absolute;
      bottom: 12px;
      right: 18px;
    }
  }
}

</style>
