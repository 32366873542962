<template>
  <div class="emploee-select-or-input">
    <!-- TODO: нулевой сотрудник (гендир) должен отображаться по умолчанию -->
    <div v-if="internalMode === 'select'">
      <employee-selector
        :editable="editable"
        :employees="employees.getEmployees()"
        :init-employee="selectedEmployee"
        :is-error="error?.select"
        :label-style="customStyle.reasonTitle"
        :select-style="customStyle.employeeSelect"
        @select="selectHandler"
      />
      <charter-or-power-of-attorney
        v-if="selectedEmployee.powerOfAttorney"
        :error="error?.reason"
        :label-style="customStyle.reasonTitle"
        :not-emit-input-reason="notEmitInputReason"
        :power-of-attorney-list="[...selectedEmployee.powerOfAttorney]"
        :select-init-reason-id="initPowerOfAttorneyId"
        :select-mode-type="basisForSigning"
        :select-style="customStyle.powerOfAttorneySelect"
        mode="select"
        @selectPowerOfAttorney="selectPowerOfAttorneyHandler"
        @selectReason="selectReasonHandler"
      />
    </div>

    <div
      v-show="internalMode === 'input'"
    >
      <div class="row mb-24">
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.surname"
            :disabled="!editable"
            :err="error?.fullName?.surname"
            :err-text="getErrorTextForError(error?.fullName?.surname)"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            :vid="`${idx}_Фамилия`"
            rules="required"
            title="Фамилия *"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.name"
            :disabled="!editable"
            :err="error?.fullName?.name"
            :err-text="getErrorTextForError(error?.fullName?.name)"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            :vid="`${idx}_Имя`"
            rules="required"
            title="Имя *"
          />
        </div>
        <div class="col col--1of3">
          <v-input
            v-model="selectedEmployee.fullName.patronymic"
            :disabled="!editable"
            :err="error?.fullName?.patronymic"
            :err-text="getErrorTextForError(error?.fullName?.patronymic)"
            :vid="`${idx}_Отчество`"
            title="Отчество"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <v-input
            v-model="selectedEmployee.role.name"
            :disabled="!editable"
            :err="error?.role"
            :err-text="getErrorTextForError(error?.role)"
            :rules-messages="{required: 'Обязательно для заполнения'}"
            :vid="`${idx}_Должность подписывающего лица`"
            rules="required"
            title="Должность подписывающего лица *"
          />
        </div>
      </div>

      <div class="row mb-24">
        <div class="col col--1of1">
          <charter-or-power-of-attorney
            :editable="editable"
            :error="error?.reason"
            :input-init-reason="initEmployee?.reason"
            mode="input"
            @selectReason="selectReasonHandler"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { CharterOrPowerOfAttorney, EmployeeSelector, VInput } from '@/components';

import { Employees } from '@/services/employees';
import { convertDbFormat } from 'lib/utils/date';

export default {
  components: {
    VInput,
    EmployeeSelector,
    CharterOrPowerOfAttorney,
  },
  props: {
    inn: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    idx: {
      type: Number,
      required: false,
    },
    permission: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      validator: (mode) => ['select', 'input', 'auto'].includes(mode),
      default: 'auto',
    },
    error: {
      type: Object,
      default: () => ({
        select: false,
        fullName: {
          name: false,
          surname: false,
          patronymic: false,
        },
        role: false,
        reason: {
          reasonDocument: false,
          number: false,
          date: false,
        },
      }),
    },
    initEmployee: {
      type: Object,
      required: false,
    },
    notEmitInputReason: {
      type: Boolean,
      default: () => false,
    },
    customStyle: {
      type: Object,
      default: () => ({
        employeeSelect: {},
        powerOfAttorneySelect: {},
        reasonTitle: {},
      }),
    },
    isCompanyMode: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.employees = new Employees(this.isCompanyMode);
    await this.updateValue();
  },
  data() {
    return {
      employees: null,
      // internalMode: select или input
      internalMode: null,
      selectedEmployee: {
        id: null,
        userId: null,
        accountId: null,
        fullName: {
          name: null,
          surname: null,
          patronymic: null,
        },
        position: null,
        role: {
          id: null,
          name: null,
          isCompanyOwner: null,
        },
        reason: {
          id: null,
          reasonDocument: null,
          number: null,
          date: null,
        },
        powerOfAttorney: null,
      },
    };
  },
  computed: {
    initPowerOfAttorneyId() {
      console.log('init poa id', this.inn, this.selectedEmployee?.reason?.id);
      return this.selectedEmployee?.reason?.id;
    },
    basisForSigning() {
      console.log('init basic for signing id', this.inn, this.selectedEmployee?.role?.isCompanyOwner);
      if (this.selectedEmployee?.role?.isCompanyOwner) {
        return 'charter';
      }
      return 'powerOfAttorney';
    },
  },
  watch: {
    selectedEmployee: {
      deep: true,
      handler(updatedSelectedEmployee) {
        if (this.inn) {
          updatedSelectedEmployee.inn = this.inn;
        }
        this.$emit('emploeeSelectOrInput', { ...updatedSelectedEmployee });
      },
    },
    inn: {
      async handler() {
        this.setEmptyEmployee();
        await this.updateValue();
      },
    },
  },
  methods: {
    // TODO hotfix по хорошему ошибка должна приходить извне
    getErrorTextForError(error) {
      return error ? 'Обязательно для заполнения' : undefined;
    },
    setEmptyEmployee() {
      this.selectedEmployee = {
        id: null,
        userId: null,
        accountId: null,
        fullName: {
          name: null,
          surname: null,
          patronymic: null,
        },
        position: null,
        role: {
          id: null,
          name: null,
          isCompanyOwner: null,
        },
        reason: {
          id: null,
          reasonDocument: 'Устав',
          number: null,
          date: null,
        },
        powerOfAttorney: null,
      };
    },
    async updateValue() {
      if (this.inn) {
        await this.fetchSupplierEmployeesByInnAndPermission(this.inn, this.permission);
      }
      this.setMode();
      this.emitSupplierOnPlatform();

      if (this.internalMode === 'select') {
        let employee;
        let powerOfAttorney;
        if (this.initEmployee?.userId) {
          employee = { ...this.employees.getEmployees().find((employee) => employee.userId === this.initEmployee.userId) };
        }
        if (!employee) {
          employee = { ...this.employees.getEmployees()[0] };
        }
        if (this.initEmployee?.reason?.id) {
          powerOfAttorney = { ...employee.powerOfAttorney.find((poa) => poa.id === this.initEmployee.reason.id) };
        }
        if (!powerOfAttorney && employee.powerOfAttorney?.length > 0) {
          powerOfAttorney = { ...employee.powerOfAttorney[0] };
        }
        if (powerOfAttorney) {
          employee.reason = {
            id: powerOfAttorney.id,
            reasonDocument: 'Доверенность',
            date: powerOfAttorney.grantedAt,
            number: powerOfAttorney.number,
          };
        } else {
          employee.reason = {
            reasonDocument: 'Устав',
            id: null,
            date: null,
            number: null,
          };
        }
        this.selectedEmployee = { ...employee };
      } else {
        if (this.initEmployee)
          this.selectedEmployee = { ...this.initEmployee };
      }
    },
    emitSupplierOnPlatform() {
      this.$emit('companyOnPlatform', this.employees.getSupplierOnPlatform());
    },
    async fetchSupplierEmployeesByInnAndPermission(inn, permission) {
      try {
        await this.employees.fetchSupplierEmployeesByInnAndPermission(inn, permission);
      } catch (error) {
        console.log(error);
        this.internalMode = 'input';
      }
    },
    setMode() {
      if (this.mode !== 'auto') {
        this.internalMode = this.mode;
        return;
      }

      if (this.employees.getSupplierOnPlatform() && this.employees.getEmployees().length !== 0) {
        this.internalMode = 'select';
        return;
      }
      this.internalMode = 'input';
    },
    selectHandler(selectedEmployee) {
      selectedEmployee.reason = this.selectedEmployee.reason;
      this.selectedEmployee = { ...selectedEmployee };
    },
    selectReasonHandler(reason) {
      if (this.notEmitInputReason) {
        if (this.selectedEmployee.powerOfAttorney?.length > 0 && !reason.id) {
          console.log('cond', this.inn, this.selectedEmployee, reason, this.selectedEmployee.powerOfAttorney?.length > 0, !reason.id);
          return;
        }
      }
      console.log('after cond', this.inn, this.selectedEmployee, reason, this.selectedEmployee.powerOfAttorney?.length > 0, !reason.id);
      if (!this.notEmitInputReason || this.selectedEmployee.fullName.name?.length > 0) {
        this.selectedEmployee.reason = { ...reason };
        this.$emit('emploeeSelectOrInput', { ...this.selectedEmployee });
      }
    },
    selectPowerOfAttorneyHandler({ grantedAt, id, number }) {
      this.selectedEmployee.reason = {
        id: id,
        reasonDocument: 'Доверенность',
        date: convertDbFormat(grantedAt),
        number: number,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/grid.scss";

</style>
