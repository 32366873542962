<template>
  <div style="padding: 0 16px 16px 0; gap: 12px">
    <v-spoiler summary="Фикстура тех. приса">
      <div style="display: flex; gap: 24px; flex-direction: column; padding: 24px">
        <v-select v-model="selectedUtility" :options="utilities" title="Сетевая компания"/>
        <v-select v-model="selectedRetailer" :options="retailers" title="Сбытовая компания"/>
        <v-select v-model="selectedStage" :options="fixtureStageList" title="Стадия"/>

        <p v-if="error.length">{{ error }}</p>
        <v-button :disabled="!selectedUtility||!selectedRetailer||!selectedStage||isLoading" @click="createFixture">
          Создать фикстуру
        </v-button>
      </div>
    </v-spoiler>

    <v-spoiler summary="Перечень компаний">
      <v-table-new v-model="registeredCompanies" :template="registeredCompaniesTemplate"/>
    </v-spoiler>

    <v-spoiler summary="Перечень пользователей">
      <v-table-new v-model="registeredUsers" :template="registeredUsersTemplate"/>
    </v-spoiler>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VButton, VSelect, VSpoiler, VTableNew } from 'components';

export default {
  name: 'CabinetTest',
  components: { VButton, VSelect, VSpoiler, VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      utilities: [],
      retailers: [],
      selectedUtility: undefined,
      selectedRetailer: undefined,
      selectedStage: 'connection_certificate',
      isLoading: false,
      error: '',
      registeredCompanies: [],
      registeredUsers: [],
    };
  },
  computed: {
    registeredCompaniesTemplate() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'ID Аккаунта',
            key: 'accountId',
            sort: {},
          },
          {
            label: 'ИНН компании',
            key: 'inn',
            sort: {},
            filter: {},
          },
          {
            label: 'Название компании',
            key: 'name',
            sort: {},
            filter: {},
          },
          {
            label: 'UserId владельца',
            key: 'ownerUserId',
            sort: {},
          },
          {
            label: 'СНИЛС владельца',
            key: 'ownerSnils',
            sort: {},
            filter: {},
          },
          {
            label: 'Почта владельца',
            key: 'ownerEmail',
            sort: {},
            filter: {},
          },
          {
            label: 'Номер телефона владельца',
            key: 'ownerPhone',
            sort: {},
            filter: {},
          },
        ],
      };
    },
    registeredUsersTemplate() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'ID Пользователя',
            key: 'userId',
            sort: {},
          },
          {
            label: 'СНИЛС',
            key: 'snils',
            sort: {},
            filter: {},
          },
          {
            label: 'Email',
            key: 'email',
            sort: {},
          },
          {
            label: 'Номер телефона',
            key: 'phoneNumber',
            sort: {},
            filter: {},
          },
          {
            label: 'Авторизации',
            key: 'companies',
            sort: {},
            filter: {},
          },
          {
            label: 'Доп. почты и телефоны',
            key: 'additionals',
            sort: {},
            filter: {},
          },
        ],
      };
    },
    ...mapGetters('dataLists', ['fixtureStageList']),
    ...mapGetters('user', ['getAccount']),
  },
  methods: {
    async loadData() {
      try {
        this.utilities = (await this.getUtilitiesForSelector()).filter((it) => it.account).map((it) => ({
          label: it.name,
          value: it.account.id,
        }));
        this.retailers = (await this.getRetailersForSelector()).filter((it) => it.account).map((it) => ({
          label: it.name,
          value: it.account.id,
        }));
        this.selectedUtility = this.utilities.find((it) => it.label.includes('Котлодержатель')).value;
        this.selectedRetailer = this.retailers.find((it) => it.label.includes('Мосэнергосбыт')).value;
      } catch (e) {
        console.log('Ошибка при загрузке данных для фикстуры');
      }
      await this.loadRegisteredCompanies();
      await this.loadRegisteredUsers();
      console.log('utilities', this.utilities);
      console.log('retailers', this.retailers);
      console.log('registeredcompanies', this.registeredCompanies);
      console.log('registeredUsers', this.registeredUsers);
    },
    async createFixture() {
      this.error = '';
      this.isLoading = true;
      try {
        await this.createConnectionApplicationFixture(
          {
            utilityAccountId: this.selectedUtility,
            retailerAccountId: this.selectedRetailer,
            stage: this.selectedStage,
          },
        );
        await this.setUser();
        this.error = 'Успешно';
      } catch (e) {
        console.log(e);
        this.error = e.message;
      } finally {
        this.isLoading = false;
      }

    },
    async loadRegisteredCompanies() {
      const companies = await this.getAllAccounts();
      this.registeredCompanies = companies.filter((it) => it.profile.id).map((it) => {
        const owner = it.authorizations.find((user) => user.role.isCompanyOwner);
        return {
          accountId: it.id,
          name: it.profile.name,
          inn: it.profile.inn,
          ownerUserId: owner?.user.id,
          ownerEmail: owner?.user.email,
          ownerPhone: owner?.user.phoneNumber,
          ownerSnils: owner?.user.snils,
        };
      });
    },
    async loadRegisteredUsers() {
      const users = await this.getAllUsers();
      this.registeredUsers = users.map((it) => ({
        ...it,
        authorizations: it.authorizations ?? [],
      })).map((it) => ({
        userId: it.id,
        email: it.email,
        phoneNumber: it.phoneNumber,
        snils: it.snils,
        companies: it.authorizations.map((auth) => {
          if (!auth.account) return 'Нет ЛК';
          if (auth.account.profile.name) return `Сотрудник ${auth.account.profile.name}`;
          if (auth.account.profile.snils) return `Представитель ФЛ ${auth.account.profile.snils}`;
          return 'Администратор';
        }).join(';'),
        additionals: [...it.authorizations.map((auth) => auth.email).filter((it) => it), ...it.authorizations.map((auth) => auth.phoneNumber).filter((it) => it)].join(';'),
      }));
    },
    ...mapActions('user', ['getAllAccounts', 'getAllUsers', 'setUser']),
    ...mapActions('cabinet', ['getUtilitiesForSelector', 'getRetailersForSelector']),
    ...mapActions('document', ['createConnectionApplicationFixture']),
  },
};
</script>
