<template>
  <div :style="customStyle">
    <v-search-select
        :value="selectedItem"
        :disabled="isDisabled"
        :required="isRequired"
        :title="title"
        :url-default-data="urlDefaultData"
        :find-ids="findIds"
        :placeholder="placeholder"
        :not-reset-search="notResetSearch"
        :search.sync="searchItem"
        @input="selectItem"
    />
  </div>
</template>

<script>
import VSearchSelect from 'components/VSearchSelect/VSearchSelect.vue';

export default {
  model: {
    prop: 'selected',
    event: 'change',
  },
  components: {
    VSearchSelect,
  },
  props: {
    title: {
      default: '',
    },
    selected: {
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    items: {
      default: () => [],
    },
    urlDefaultData: {
      type: String,
      default: '/administrative-district',
    },
    findIds: {
      type: Array,
      required: false,
      validator(value) {
        return value.every((item) => typeof item.queryIndex === 'number');
      },
    },
    marginBottom: {
      type: String,
      default: '24',
    },
    placeholder: {
      type: String,
      default: '',
    },
    notResetSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:search'],
  computed: {
    selectedItem: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    searchItem: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    customStyle() {
      return `marginBottom: ${this.marginBottom}px`;
    },
  },
  methods: {
    selectItem(value, label) {
      this.selectedItem = value;
      this.$emit('update:search', label);
    },
  },
};
</script>
