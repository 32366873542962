import gql from 'graphql-tag';
import { profileType, roleType } from './types';

export const LOGIN_USER = gql`
  mutation sendCode($username: String!, $password: String!) {
    userAuthenticateWithPassword(username: $username, password: $password) {
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
          agreedWithPersonalDataAgreement
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;
export const REQUEST_CAP_AUTH = gql`
  mutation userGenerateSigString($snils: String!) {
    userGenerateSigString(snils: $snils) {
      ...on UserEcpStringPayload {
        ecpString
      }
    }
  }
`;

export const REQUEST_CODE_WITH_CAP = gql`
  mutation userAuthenticateWithEcp($signedString: String!) {
    userAuthenticateWithEcp(signedString: $signedString) {
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
          agreedWithPersonalDataAgreement
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;

export const USER_SWAP_ACCOUNT = gql`
  mutation userSwapAccount($accountId: String) {
    userSwapAccount(accountId: $accountId) {
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
          agreedWithPersonalDataAgreement
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;

export const CONFIRM_USER = gql`
  mutation signIn($code: String!) {
    userAuthenticateWithCode(code: $code) {
      __typename
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
          agreedWithPersonalDataAgreement
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;

export const CONFIRM_ACCOUNT = gql`
  mutation accountIn($accountId: String!) {
    userAuthenticateWithAccount(accountId: $accountId) {
      __typename
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;

export const REGISTRATION_USER = gql`
  mutation registerUser($password: String!, $xml: Upload!, $signature: Upload) {
    registerUser(password: $password, xml: $xml, signature: $signature) {
      __typename
      ... on UserAuthenticationPayload {
        token
        tokenStatus
        tokenUsername
        user {
          id
          fullName {
            name
            surname
            patronymic
          }
          fullNameGenitiveCase {
            name
            surname
            patronymic
          }
          email
          phoneNumber
          snils
          agreedWithUserAgreement
          agreedWithPersonalDataAgreement
          authorizations {
            type
            ogrnip
            id
            bankRequisites {
              bankName
              correspondentAccount
              bik
              settlementAccount
            }
            birthDate
            phoneNumber
            type
            email
            inn
            citizenship
            identityDocumentInfo {
              number
              documentType
              issueDate
              issuerCode
              issuerName
              series
              otherName
            }
            registrationAddress
            liveAddress
            birthPlace
            role {
              ...Role_type
            }
            account {
              id
              kind
              profile {
                ...Profile_type
              }
              supplier {
                id
                kind
              }
            }
            user {
              id
              fullName {
                name
                surname
                patronymic
              }
              fullNameGenitiveCase {
                name
                surname
                patronymic
              }
              email
              phoneNumber
              snils
            }
          }
        }
        account {
          id
          kind
          profile {
            ...Profile_type
          }
          supplier {
            id
            kind
          }
        }
      }
      ... on UserAuthenticationError {
        code
        message
      }
    }
  }
  ${roleType}
  ${profileType}
`;

export const DOCUMENT_SUBMIT = gql`
  mutation documentSubmit($xml: Upload!, $sourceDocumentId: String, $signature: Upload) {
    documentSubmitWithMeta(document: {
      xml: $xml,
      sourceDocumentId: $sourceDocumentId,
      signature: $signature,
    }) {
      id
      status
      errorInfo
      json
      xml
    }
  }
`;

export const MANY_DOCUMENT_SUBMIT = gql`
  mutation submitManyDocuments($xmls: [Upload!]) {
    submitManyDocuments(xmls: $xmls) {
      id
      hash
      json
      xml
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation {
    createPayment {
      formUrl
    }
  }
`;

export const SEND_SUPPORT = gql`
  mutation sendEmailToSupport($from: String!, $message: String!) {
    sendEmailToSupport(from: $from, message: $message)
  }
`;

export const CREATE_PAYMENT_URL = gql`
  mutation createPaymentUrl($params: PaymentUrlInput) {
    createPaymentUrl(params: $params) {
      value
    }
  }
`;

export const GENERATE_INVOICE = gql`
  mutation generateAccountRefillInvoice($params: AccountRefillInvoice) {
    generateAccountRefillInvoice(params: $params)
  }
`;

export const CREATE_FIXTURE_PAYMENT = gql`
  mutation createPaymentFixture(
    $invoiceId: String
  ) {
    createPaymentFixture(
      invoiceId: $invoiceId
    )
  }
`;

export const CREATE_METER_REPORT = gql`
  mutation {
    createMeterReport
  }
`;

export const CREATE_BORDER_DEVICE_ACT = gql`
  mutation createBorderDeviceAct($generateInput: BorderDeviceActGenerateInput!) {
    generateBorderDeviceAct(generateInput: $generateInput){
      accountingPeriod {
        year
        month
      }
      firstCompanyName
      secondCompanyName
      firstCompanyDepartmentName
      secondCompanyDepartmentName
    }
  }
`;

export const CREATE_BORDER_FLOW_ACT = gql`
  mutation createBorderFlowAct($generateInput: BorderFlowActGenerateInput!) {
    generateBorderFlowAct(generateInput: $generateInput){
      accountingPeriod {
        year
        month
      }
      firstCompanyName
      secondCompanyName
      firstCompanyDepartmentName
      secondCompanyDepartmentName
    }
  }
`;

export const CREATE_BOTH_BORDER_ACTS = gql`
  mutation createBothBorderActs($generateInput: BorderDeviceActGenerateInput!) {
    generateBothBorderActs(generateInput: $generateInput){
      accountingPeriod {
        year
        month
      }
      firstCompanyName
      secondCompanyName
      firstCompanyDepartmentName
      secondCompanyDepartmentName
    }
  }
`;

export const GENERATE_BALANCE = gql`
  mutation generateBalance($transmissionContractId: String!, $month: Int, $year: Int, $totalLoss: Int,   $supplierRepresentative: RepresentativeInput!,  $performerFirstRepresentative: RepresentativeInput!,  $performerSecondRepresentative: RepresentativeInput!){
    generateBalance(transmissionContractId: $transmissionContractId, month: $month, year: $year, totalLoss: $totalLoss, supplierRepresentative: $supplierRepresentative, performerFirstRepresentative: $performerFirstRepresentative, performerSecondRepresentative: $performerSecondRepresentative)
  }
`;

export const GENERATE_BALANCE_BY_USAGE_POINTS = gql`
  mutation generateBalanceByUsagePoints($transmissionContractId: String!, $usagePointIds: [String!], $accountingPeriod: AccountingPeriodInput, $supplierRepresentative: RepresentativeInput!,  $performerFirstRepresentative: RepresentativeInput!,  $performerSecondRepresentative: RepresentativeInput!){
    generateBalanceByUsagePoints(transmissionContractId: $transmissionContractId, usagePointIds: $usagePointIds, accountingPeriod: $accountingPeriod, supplierRepresentative: $supplierRepresentative, performerFirstRepresentative: $performerFirstRepresentative, performerSecondRepresentative: $performerSecondRepresentative)
  }
`;

export const GENERATE_PAYMENT_ORDER_FOR_SERVICE_OR_LOSS_ACT = gql`
  mutation generatePaymentOrderForServiceOrLossAct($actDocumentDomainId: String!, $performerFirstBankRequisites: BankRequisitesInput!, $performerSecondBankRequisites: BankRequisitesInput!, $totalCost: String!, $paymentType: PaymentType!){
    generatePaymentOrderForServiceOrLossAct(actDocumentDomainId: $actDocumentDomainId, performerFirstBankRequisites: $performerFirstBankRequisites, performerSecondBankRequisites: $performerSecondBankRequisites, totalCost: $totalCost, paymentType: $paymentType)
  }
`;

export const GENERATE_SERVICE_ACT = gql`
  mutation generateServiceAct($month: Int, $year: Int, $balanceId: String!,  $performerFirstRepresentative: RepresentativeInput!,  $performerSecondRepresentative: RepresentativeInput!, $volumesByPointStatus: [VolumesByPointStatusEnum], $actKind: ServiceActKind){
    generateServiceAct(month: $month, year: $year, performerFirstRepresentative: $performerFirstRepresentative, performerSecondRepresentative: $performerSecondRepresentative, balanceId: $balanceId, volumesByPointStatus: $volumesByPointStatus, actKind: $actKind)
  }
`;

export const GENERATE_LOSS_ACT = gql`
  mutation generateLossAct($month: Int, $year: Int, $balanceId: String!, $supplierRepresentative: RepresentativeInput!, $performerSecondRepresentative: RepresentativeInput!, $volumesByPointStatus: [VolumesByPointStatusEnum]){
    generateLossAct(month: $month, year: $year, supplierRepresentative: $supplierRepresentative, performerSecondRepresentative: $performerSecondRepresentative, balanceId: $balanceId, volumesByPointStatus: $volumesByPointStatus)
  }
`;


export const UPDATE_PERMISSIONS = gql`
  mutation updatePermissions($permissions: UserPermissions) {
    updatePermissions(permissions: $permissions)
  }
`;

export const UPDATE_COMPANY_PROFILE_BY_FNS = gql`
  mutation updateCompanyProfileByFns($companyAccountId: String) {
    updateCompanyProfileByFns(companyAccountId: $companyAccountId) {
      id
    }
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation documentCancel($documentId: String) {
    documentCancel (
      documentId: $documentId
    )
  }
`;

export const DELETE_USER_FROM_COMPANY = gql`
  mutation deleteUserFromCompany($deletableUserId: String) {
    deleteUserFromCompany(deletableUserId: $deletableUserId)
  }
`;

export const GET_PERSON_IN_CASE = gql`
  mutation getPersonInCase($fio: PersonInfoInput, $inCase: InCase) {
    getPersonInCase(fio: $fio, inCase: $inCase) {
      name
      surname
      patronymic
    }
  }
`;

export const GET_WORD_IN_CASE = gql`
  mutation getWordInCase($word: String, $inCase: InCase) {
    getWordInCase(word: $word, inCase: $inCase)
  }
`;

// создание комнаты с 1 участником (берется из токена)
export const CREATE_ROOM = gql`
  mutation createRoom($title: String!) {
    createRoom(title: $title) {
      ... on RoomOperationSuccess {
        room {
          id
          title
        }
      }
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

// создание комнаты с 2 участниками: рандомный саппорт и тот кто делал запрос (берется из токена)
export const CREATE_ROOM_WITH_SUPPORT = gql`
  mutation createRoomWithSupport($title: String!) {
    createRoomWithSupport(title: $title) {
      ... on RoomOperationSuccess {
        room {
          id
          title
        }
      }
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

// добавление пользователя в комнату
export const CONNECT = gql`
  mutation connect($roomId: String!) {
    connect(roomId: $roomId) {
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

// удаление пользователя из комнаты
export const DISCONNECT = gql`
  mutation disconnect($roomId: String!) {
    disconnect(roomId: $roomId) {
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

// отправка сообщения в комнату
export const SEND_MESSAGE = gql`
  mutation send($data: CreateMessageInput) {
    send(data: $data) {
      ... on MessageOperationSuccess {
        message {
          # id сообщения
          id
          # id отправителя
          senderId
          # id комнаты
          roomId
          # текст сообщения пользователя
          content
          # время отправки сообщения
          creationTime
        }
      }
      ... on GqlError {
        code
        error
      }
      __typename
    }
  }
`;

export const REQUEST_SIGNATURE_CODE = gql`
  mutation requestSignatureCode {
    requestSignatureCode {
      accountId
      userId
    }
  }
`;

export const SIGN_DOCUMENT_WITH_SIMPLE_SIGNATURE = gql`
  mutation signDocumentWithSimpleSignature($xml: Upload!, $code: String!) {
    signDocumentWithSimpleSignature(code: $code, xml: $xml)
  }
`;

export const GENERATE_USEFUL_VACATION = gql`
  mutation usefulOne($consumerId: String, $month: Int, $year: Int){
    generateUsefulVacationForConsumer(consumerId: $consumerId, month: $month, year: $year)
  }
`;

export const SAVE_DRAFT = gql`
  mutation saveDocumentDraft($draftInput: DraftInput) {
    saveDraft(draftInput: $draftInput) {
      id
      createdAt
    }
  }
`;

export const DELETE_DRAFT = gql`
  mutation deleteDraft($id: String) {
    deleteDraft(id: $id)
  }
`;

export const CREATE_FIXTURE = gql`
  mutation CREATE_FIXTURE(
    $utilityAccountId: String,
    $retailerAccountId: String,
    $stage: ConnectionApplicationFixtureStage,
  ) {
    createFixture(
      utilityAccountId:  $utilityAccountId,
      retailerAccountId: $retailerAccountId,
      stage: $stage,
    )
  }`;

export const DECREASE_ACCOUNT_BALANCE = gql`
  mutation DECREASE_ACCOUNT_BALANCE(
    $contractNumber: String!
  ) {
    decreaseAccountBalanceByThousand(
      contractNumber:  $contractNumber,
    )
  }`;

export const GENERATE_ADDITIONS_FOR_NEW_USAGE_POINTS_AND_CONSUMERS = gql`
  mutation GENERATE_ADDITIONS_FOR_NEW_USAGE_POINTS_AND_CONSUMERS(
    $contractDomainDocumentId: String!,
  ) {
    generateAdditionsForNewUsagePointsAndConsumers(
      contractDomainDocumentId:  $contractDomainDocumentId,
    )
  }`;

export const GENERATE_ADDITIONS_FOR_NEW_USAGE_POINT_IDS_AS_XML_TEXT = gql`
  mutation generateAdditionsForNewUsagePointIdsAsXmlText(
    $usagePointIds: [String!]!,
  ) {
    generateAdditionsForNewUsagePointIdsAsXmlText(
      usagePointIds:  $usagePointIds,
    )
  }`;

export const GENERATE_DISAGREEMENTS_FOR_CONTRACT = gql`
  mutation GENERATE_DISAGREEMENTS_FOR_CONTRACT(
    $contractDocumentId: String!,
    $usagePointIds: [String!]!
  ) {
    generateDisagreementsForContract(
      contractDocumentId:  $contractDocumentId,
      usagePointIds: $usagePointIds,
    )
  }`;

export const USER_SEND_AUTHENTICATION_CODE = gql`
  mutation userSendAuthenticationCode {
    userSendAuthenticationCode {
      username
    }
  }
`;

export const SEND_RESTORE_PASSWORD_CODE = gql`
  mutation sendRestorePasswordCode($username: String!) {
    sendRestorePasswordCode(username: $username) {
      username
    }
  }
`;

export const RESTORE_PASSWORD = gql`
  mutation restorePassword($username: String!, $code: String!, $newPassword: String!) {
    restorePassword(username: $username, code: $code, newPassword: $newPassword )
  }
`;

export const SEND_USERNAME_VERIFICATION_CODE = gql`
  mutation sendUsernameVerificationCode($username: String) {
    sendUsernameVerificationCode(username: $username) {
      username
    }
  }
`;

export const UPDATE_BALANCE_DOCUMENTS_SETTINGS = gql`
  mutation updateBalanceDocumentsSettings($updateInput: BalanceDocumentsSettingsInput!) {
    updateBalanceDocumentsSettings(updateInput: $updateInput) {
      id
    }
  }
`;

export const GENERATE_USEFUL_VACATION_FOR_DEPARTMENT = gql`
  mutation generateUsefulVacationForDepartment(
    $departmentId: String,
    $utilitySupplierId: String,
    $accountingPeriod: AccountingPeriodInput,
    $usagePointIds: [String!],
  ) {
    generateUsefulVacationForDepartment(
      departmentId: $departmentId,
      utilitySupplierId: $utilitySupplierId,
      accountingPeriod: $accountingPeriod,
      usagePointIds: $usagePointIds,
    )
  }
`;
