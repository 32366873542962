<template>
  <v-table-new
    v-model="transformedWarehouseStockSummary"
    :loading="loading"
    :on-filter-change="onFilterChange"
    :on-load-all-values="loadAllFiltered"
    :on-pagination-change="onPaginationChange"
    :page-out="warehouseStockSummary.pageOut"
    :template="template"
    excel-report-file-name="Складские операции. Элементы АХП"
    server-side-sorting
    table-name="warehouseElements"
    @on-sort="handleSort"
  />
</template>

<script>
import { VTableNew } from '@/components';
import { fetchData } from '@/api/rest/odk/api';

const initialPagination = {
  page: 1,
  size: 10,
  last_page: 1,
};

export default {
  name: 'WarehouseElementsTable',
  components: { VTableNew },
  data() {
    return {
      warehouseStockSummary: [],
      loading: false,
      pagination: initialPagination,
      filter: {},
      sort: {
        field: null,
        type: null,
      },
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№ П/П',
            key: 'index',
            type: 'index',
            size: 'sm',
            thStyle: {
              minWidth: '60px',
            },
          },
          {
            label: 'Склад',
            key: 'warehouseName',
            size: 'sm',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: { type: 'warehousesOdk' },
          },
          {
            label: 'АХП',
            key: 'axpName',
            size: 'md',
            thStyle: {
              minWidth: '83px',
            },
            sort: {},
            filter: { type: 'axpOdk' },
          },
          {
            label: 'Название элемента',
            key: 'axpElement.name',
            thStyle: {
              minWidth: '83px',
            },
            size: 'lg',
            sort: {},
          },
          {
            label: 'Количество на складе',
            type: 'number',
            key: 'stockQuantity',
            size: 'sm',
            sort: {
              type: 'number',
            },
            thStyle: {
              minWidth: '100px',
            },
          },
          {
            label: 'Единица измерения',
            key: 'axpElement.unit.name',
            thStyle: {
              minWidth: '82px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Тип декора',
            key: 'axpElement.decorType.name',
            thStyle: {
              minWidth: '83px',
            },
            size: 'md',
            sort: {},
          },
          {
            label: 'Количество дефектных элементов',
            type: 'number',
            key: 'defective',
            thStyle: {
              minWidth: '100px',
            },
            size: 'md',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество элементов в ремонте',
            type: 'number',
            key: 'inRepair',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество элементов на пересчёте',
            type: 'number',
            key: 'recalculation',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Количество списанных элементов',
            type: 'number',
            key: 'decomissioned',
            size: 'md',
            thStyle: {
              whiteSpace: 'nowrap',
            },
            sort: {
              type: 'number',
            },
          },
        ],
      };
    },
    transformFilter() {
      const result = {};

      const filterMapping = {
        warehouseName: 'warehouseName',
        axpName: 'axpName',
      };

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterMapping[filterKey]] = filterValue;
        }
      });

      return result;
    },
    transformedWarehouseStockSummary() {
      if (!this.warehouseStockSummary || !this.warehouseStockSummary.data || !Array.isArray(this.warehouseStockSummary.data)) {
        return [];
      }
      return this.warehouseStockSummary.data.map(this.transformed);
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const { page, size } = this.pagination;
        const responseData = await fetchData('warehouse-stock-summary', page, size, sortField, sort, filters);
        this.pagination = {
          ...this.pagination,
          page: responseData.page,
          totalElements: responseData.total,
          totalPages: responseData.last_page,
        };

        this.warehouseStockSummary = responseData;
        this.warehouseStockSummary.pageOut = this.pagination;
      } catch (error) {
        console.log('warehouseStockSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const filters = this.transformFilter;
        const { field: sortField, type: sort } = this.sort;
        const responseData = await fetchData('warehouse-stock-summary', 1, this.warehouseStockSummary.pageOut.totalElements, sortField, sort, filters);

        return responseData.data?.map(this.transformed);
      } catch (error) {
        console.log('warehouseStockSummary', error);
      } finally {
        this.loading = false;
      }
    },
    async handleSort(column) {
      this.sort.field = column.key;
      this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      this.filter = { ...filter };
      await this.loadData();
    },
    async fetchReport() {
      this.loading = true;
      const filters = this.transformFilter;
      const { field: sortField, type: sort } = this.sort;
      const result = await fetchData('warehouse-stock-summary', 1, this.pagination.totalElements, sortField, sort, filters);
      this.loading = false;
      return result;
    },
    transformed(item, index) {
      return {
        ...item,
        index: index + 1,
        warehouseName: item.warehouseName,
        axpName: item.axpName,
        axpElement: {
          name: item.axpElement.name,
          unit: {
            name: item.axpElement.unit?.name,
          },
          decorType: {
            name: item.axpElement.decorType?.name,
          },
        },
        stockQuantity: item.stockQuantity,
        defective: item.defective,
        inRepair: item.inRepair,
        recalculation: item.recalculation,
        decomissioned: item.decomissioned,
      };
    },
  },

};
</script>

<style scoped>

</style>
