<template>
  <div
    class="tooltip-wrp"
    :style="{
      top: innerTopPx,
    }"
  >
    <div
      class="tooltip-inner"
    >
      <div
        class="tooltip-inner__text"
        :style="innerTextStyle"
      >
        {{ text.toLocaleUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.innerTextStyle = this.generateInnerTextStyle();
  },
  data() {
    return {
      innerTop: 0,
      innerTextStyle: {},
    };
  },
  computed: {
    innerTopPx() {
      return `${this.innerTop}px`;
    },
  },
  methods: {
    generateInnerTextStyle() {
      const textLength = this.text.length;
      const innerTextStyle = {
        width: 'auto',
        whiteSpace: 'nowrap',
        maxWidth: 350,
        padding: '6px 14px',
      };

      if (textLength <= 20) {
        this.innerTop = this.top + 17.5;
      } else if (textLength > 20 && textLength <= 40) {
        this.innerTop = this.top + 17.5;
      } else if (textLength > 40 && textLength <= 45) {
        innerTextStyle.height = 56;
        innerTextStyle.width =  `${300}px`;
        innerTextStyle.whiteSpace = 'normal';
        this.innerTop = this.top;
      } else if (textLength > 45 && textLength <= 55) {
        innerTextStyle.height = 56;
        innerTextStyle.width =  `${335}px`;
        innerTextStyle.whiteSpace = 'normal';
        this.innerTop = this.top;
      } else {
        innerTextStyle.width =  `${textLength * 8}px`;
        innerTextStyle.whiteSpace = 'normal';
        this.innerTop = this.top;
      }

      innerTextStyle.height = `${innerTextStyle.height}px`;
      innerTextStyle.maxWidth = `${innerTextStyle.maxWidth}px`;

      const borderlineCase = this.setBorderlineCases(this.text);

      if (borderlineCase) {
        this.innerTop = borderlineCase.innerTop;
        delete borderlineCase.innerTop;
        return borderlineCase;
      }

      return innerTextStyle;
    },
    setBorderlineCases(text) {
      // TODO: использовать только при появлении пограничных случаев
      // фактически данный метод - костыль. Необходимо переделать в случае
      // переработки меню
      const menuItems = [
        {
          text: 'Полезный отпуск по отделениям и потребителям',
          height: '42px',
          width: '2500px',
          whiteSpace: 'normal',
          maxWidth: '253px',
          padding: '6px 14px',
          innerTop: this.top + 12,
        },
        {
          text: 'Акты оказания услуг по передаче электрической энергии',
          height: '42px',
          width: '260px',
          whiteSpace: 'normal',
          maxWidth: '260px',
          padding: '6px 14px',
          innerTop: this.top + 12,
        },
        {
          text: 'Акты приёма-передачи электрической энергии',
          height: '42px',
          width: '195px',
          whiteSpace: 'normal',
          maxWidth: '195px',
          padding: '6px 14px',
          innerTop: this.top + 12,
        },
        {
          text: 'Установка универсального транспортного модуля',
          height: '42px',
          width: '215px',
          whiteSpace: 'normal',
          maxWidth: '215px',
          padding: '6px 14px',
          innerTop: this.top + 12,
        },
        {
          text: 'Нормативы потерь электрической энергии при ее передаче по единой национальной (общероссийской) электрической сети, осуществляемой публичным акционерным обществом "Федеральная сетевая компания - Россети"',
          width: '350px',
          maxWidth: '350px',
          whiteSpace: 'normal',
          padding: '6px 14px',
          innerTop: this.top - 20,
        },
      ];

      const borderlineCase = menuItems.find((item) => item.text.toLocaleLowerCase() === text.toLocaleLowerCase());

      if (borderlineCase) delete borderlineCase.text;

      return borderlineCase;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  &-wrp {
    position: fixed;
    left: 72px;
    z-index: 9999;
  }

  &-inner {
    &__text {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: normal;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #979CA2;
      color: #ffffff;
      border-radius: 6px;

      position: absolute;
      z-index: 1;
      top: -5px;
      left: 110%;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #979CA2 transparent transparent;
      }
    }
  }
}
</style>
