<template>
  <Process1/>
</template>

<script>
import Process1 from 'components/Processes/Process4/Process1.vue';

export default {
  components: { Process1 },
};
</script>

<style scoped>

</style>
