<template>
  <inner-page v-if="meterData"
              :history="meterData.history"
              :history-spacing="132"
              :steps="[`Редактирование измерительного комплекса`]"
              history-title="История"
              home="Приборы учёта"
              @back="$router.back()"
              @goHome="goHome"
  >
    <meter-header :label-required="true" :status="status" :title="meterData.type" :uuid="$route.params.id" />
    <usage-point-connection v-if="meterData.usagePoint" v-model="meterData"/>
    <consumer-info v-if="meterData.usagePoint" v-model="meterData" :is-display-person="false"/>
    <object-info v-if="meterData.usagePoint" v-model="meterData.usagePoint"/>
    <type-accounting v-if="meterData.usagePoint" v-model="meterData.usagePoint"/>
    <meter-properties v-model="meterData"/>
    <transformer-info v-if="meterData.usagePoint" v-model="meterData.usagePoint.currentTransformer" title="тока"/>
    <transformer-info v-if="meterData.usagePoint" v-model="meterData.usagePoint.potentialTransformer" title="напряжения"/>
    <!--    <seals-info v-model="meterData"/>-->

    <template v-if="meterData.documents">
      <v-divider/>

      <DocumentsList :documents="meterData.documents"/>

      <v-divider/>
    </template>

    <div slot="buttons" class="action-buttons">
      <div class="action-buttons__action action-buttons__action--left">
        <button class="btn-cancel" @click="goHome">
          Отменить изменения
        </button>
      </div>
      <div class="action-buttons__action action-buttons__action--right">
        <button class="btn-print">
          Напечатать
        </button>

        <button class="btn-save" :disabled="!meterData.usagePoint">
          Сохранить
        </button>
      </div>
    </div>
  </inner-page>
</template>

<script>
import { DocumentsList, InnerPage, VDivider } from '@/components';
import MeterHeader from './components/MeterHeader';
import UsagePointConnection from './components/UsagePointConnection';
import MeterProperties from './components/MeterProperties';
import ConsumerInfo from '@/views/private/UsagePoint/components/ConsumerInfo';
import ObjectInfo from '@/views/private/UsagePoint/components/ObjectInfo';
import TypeAccounting from '@/views/private/UsagePoint/components/TypeAccounting';
import TransformerInfo from './components/TransformerInfo';
import { mapActions } from 'vuex';
import { convertDbFormat } from 'lib/utils/date';

export default {
  name: 'Meter',
  components: {
    InnerPage,
    MeterHeader,
    UsagePointConnection,
    ConsumerInfo,
    ObjectInfo,
    TypeAccounting,
    TransformerInfo,
    MeterProperties,
    DocumentsList,
    VDivider,
  },
  async created() {
    await this.loadData();
  },
  data() {
    return {
      meterData: undefined,
      revision: undefined,
    };
  },
  computed: {
    status() {
      if (this.meterData.replacementStatus === 'WITHDRAWN') {
        return [
            {
              type: 'qualityIndicators',
              value: 'WITHDRAWN',
            },
        ];
      }

      return [
        {
          type: 'qualityIndicators',
          value: this.meterData?.qualityIndicators,
        },
        {
          type: 'readingsTransmitted',
          value: this.meterData?.readingsTransmitted,
        },
        {
          type: 'sealStatus',
          value: this.meterData?.sealStatus,
        },
      ];
    },
  },
  methods: {
    goHome() {
      this.$router.push('/cabinet/meter-input-transit');
    },
    async goRevision(revision) {
      this.revision = revision;
      await this.loadData();
    },
    async loadData() {
      if (!this.$route.params.meter || this.$route.params.meter.id !== this.$route.params.id) {

        if (!this.$route.params.id) {
          alert('Ошибка загрузки прибора учёта');
          this.goHome();
          return;
        }

        const meter = await this.meter({
          id: this.$route.params.id,
          revision: this.revision,
        });

        if (!meter || meter.length === 0) {
          alert('Ошибка загрузки прибора учёта');
          this.goHome();
          return;
        }

        const actionTitle = meter.replacementStatus === 'WITHDRAWN' ? 'выведен' : 'поставлен';

        this.meterData = {
          ...meter,
          digitCapacity: 'Неизвестна',
          fractionDigits: 'Неизвестна',
          productionYear: 'Неизвестен',
          calibrationDate: this.convertDbFormat(meter.calibrationDate),
          nextCalibrationDate: this.convertDbFormat(meter.nextCalibrationDate),
          history: meter.history?.map((history) => ({
              message: `Прибор учёта ${actionTitle} документом "${history.updatedByDocument.title}". Хеш транзакции: ${history.updatedByDocument.hash}`,
              datetime: history.updatedAt,
              onClick: async () => await this.goRevision(history.revisionId),
              current: history.revisionId === this.revision,
            })) ?? [],
        };

        if (this.meterData.usagePoint) {
          this.meterData.usagePoint.currentTransformer = {
            ...this.meterData.usagePoint.currentTransformer,
            calibrationDate: this.meterData.usagePoint.currentTransformer.calibrationDate,
            nextCalibrationDate: this.meterData.usagePoint.currentTransformer.nextCalibrationDate,
            serialNumber: 'Неизвестен',
            productionYear: 'Неизвестен',
          };
        }

        if (this.meterData.usagePoint?.potentialTransformer) {
          this.meterData.usagePoint.potentialTransformer = {
            ...this.meterData.usagePoint.potentialTransformer,
            calibrationDate: this.meterData.usagePoint.potentialTransformer.calibrationDate,
            nextCalibrationDate: this.meterData.usagePoint.potentialTransformer.nextCalibrationDate,
            serialNumber: 'Неизвестен',
            productionYear: 'Неизвестен',
          };
        }

        return;
      }

      this.meterData = {
        ...this.$route.params.meter,
      };
    },
    convertDbFormat(value) {
      return convertDbFormat(value);
    },
    ...mapActions('point', ['meter']),
  },
};
</script>
<style lang="scss" scoped>
.action-buttons {
  display: flex;

  button {
    height: 40px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    border: 1px solid #C1C4C7;
    border-radius: 24px;
    color: #0E0F0F;
    background: #FFFFFF;
    padding: 6px 25px 6px 25px;

    &:hover {
      background-color: #F5F6F6;
    }
  }

  .btn-save {
    color: #FFFFFF;
    background: #2F82DF;

    &:hover {
      background: #1f5b9b;
    }
  }

  .btn-print {
    background-image: url('~icons/basic/print.svg');
    background-repeat: no-repeat;
    background-position: center left 14px;
    padding-left: 48px;
  }

  &__action {
    width: 100%;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;

      button {
        margin-right: 16px;
      }
    }
  }
}
</style>
