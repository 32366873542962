<template>
  <div>
    <table :key="rerenderKey" class="contract-xml-table">
      <thead>
      <tr>
        <th class="td" style="width: 5%;">
        </th>
        <th class="td" style="width: 5%;"></th>
        <th class="td" style="white-space: pre-line; width: 80%;">
          <div>
            {{ xmlForView.header }}
          </div>
        </th>
        <th style="width: 10%;"></th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(row, index) in editedResult"
        v-show="row.isShow||row.isMainChapterRow"
        :key="index"
      >
        <td
          style="border: 1px solid #DEE2E6; height: 1px"
        >
          <div
            style="height: 100%; position: relative;"
          >

            <div :style="markLabelMark(row.type)">
              <!-- блок с вертикальной полоской -->
            </div>

            <div
              :style="markChanged(row.type)"
              style="height: 100%; display: flex; flex-direction: column; justify-content: center;"
            >
              <div v-show="!row.isMainChapterRow" style="display: flex; flex-direction: column;">
                <button
                  v-if="!row.isNewPoint"
                  disabled
                  style="background: inherit;"
                >
                  <img
                    v-if="(row.type === 'edited')"
                    src="@/assets/icons/contract/last-change-edit.svg"
                  >
                  <img
                    v-if="(row.type === 'deleted')"
                    src="@/assets/icons/contract/last-change-delete.svg"
                  >
                  <img
                    v-if="(row.type === 'added')"
                    src="@/assets/icons/contract/last-change-plus.svg"
                  >
                </button>
              </div>
            </div>

          </div>
        </td>
        <td
          :style="markChanged(row.type)"
          class="td"
        >
          <div>
            {{ row.pointNumber }}
          </div>
        </td>
        <td
          :style="markChanged(row.type)"
          class="td"
          style="white-space: pre-line;"
        >
          <div :style="markChanged(row.type)" style="padding: 20px;">
            <div v-if="row.title">
              {{ row.title }}
            </div>
            <div v-if="row.text">
              {{ row.text }}
            </div>
          </div>

        </td>
        <td style="vertical-align: top;">
          <button
            v-if="row.isMainChapterRow"
            class="contract-xml-table__button"
            @click.prevent="changeVisibleChapter(row.number)"
          >
            <img v-if="row.isOpen" src="@/assets/icons/arrows/arrow-down.svg">
            <img v-else src="@/assets/icons/arrows/arrow-up.svg">
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseContractXml, xmlFromHeaderAndBody } from 'services/contractXml';

export default {
  props: {
    template: {
      type: String,
      default: '',
    },
    xml: {
      type: Object,
      default: () => ({ header: '', body: '' }),
    },
    closeAll: {
      type: Boolean,
      default: true,
    },
    openAll: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.parse();
  },
  data() {
    return {
      isHeaderEditable: false,
      xmlForView: {
        header: null,
        body: null,
      },
      editedResult: null,
      oldXmlForView: {},
      rerenderKey: 0,
      isCreateNewRowInProgress: false,
    };
  },
  computed: {
    ...mapGetters('electricityTransmissionContract', ['getContract']),
  },
  watch: {
    closeAll() {
      this.changeVisibleChapter(-1, true);
    },
    openAll() {
      this.changeVisibleChapter(-1, false);
    },
    xml() {
      this.parse();
    },
  },
  methods: {
    markLabelMark(type) {
      let markColor;
      switch (type) {
        case 'edited':
          markColor = '#FFB800';
          break;

        case 'added':
          markColor = '#37BA21';
          break;

        case 'deleted':
          markColor = '#EB5757';
          break;
      }

      return {
        position: 'absolute',
        height: '100%',
        width: '4px',
        backgroundColor: markColor,
        borderTopLeftRadius: '80px',
        borderBottomLeftRadius: '80px',
        padding: '2px',
      };
    },
    markChanged(type) {
      let rowColor;
      switch (type) {
        case 'edited':
          rowColor = '#fff8eb';
          break;

        case 'added':
          rowColor = '#edf8ea';
          break;

        case 'deleted':
          rowColor = '#feeeee';
          break;
      }

      return {
        backgroundColor: rowColor,
      };
    },
    changeVisibleChapter(pointNumber, closeAll) {
      if (!Array.isArray(this.xmlForView.body)) {
        return;
      }


      let allHide = true;

      this.editedResult.forEach((row) => {
        if (!row.isMainChapterRow && (row.pointNumber.startsWith(`${pointNumber}.`) || closeAll !== undefined)) {
          if (closeAll === undefined) {
            row.isShow = !row.isShow;
          } else {
            row.isShow = !closeAll;
          }
        }
        if (row.isMainChapterRow) {
          console.log(pointNumber);
          console.log(row.pointNumber);
        }
        if ((row.isMainChapterRow && (row.pointNumber === pointNumber)) || closeAll !== undefined) {
          if (closeAll === undefined) {
            row.isOpen = !row.isOpen;
          } else {
            row.isOpen = !closeAll;
          }
        }
        if (!row.isMainChapterRow && row.isShow) {
          allHide = false;
        }
      });

      this.$emit('allClosed', allHide);
    },
    parse() {
      this.xmlForView = parseContractXml(xmlFromHeaderAndBody(this.xml));
      this.oldXmlForView = parseContractXml(this.template);
      console.log('new', this.xmlForView);
      console.log('old', this.oldXmlForView);
      this.editedResult = this.comparePoints(this.oldXmlForView.body, this.xmlForView.body, this.xmlForView.isChaptersRoot);
    },
    comparePoints(old, edited, isMainChapterRow, previousNumber) {
      let editedResult = [];
      const maxLength = Math.max(old.length, edited.length);
      for (let i = 0; i < maxLength; i++) {
        const newNumber = previousNumber ? `${previousNumber}.${old[i]?.number ?? edited[i]?.number}` : `${old[i]?.number ?? edited[i]?.number}`;
        if (old[i] && edited[i]) {
          editedResult.push({
            ...edited[i],
            type: old[i].text !== edited[i].text ? 'edited' : null,
            isMainChapterRow: isMainChapterRow,
            pointNumber: newNumber,
            isShow: true,
            isOpen: true,
          });
          if (old[i].text !== edited[i].text) {
            console.log(old[i].text, ':', edited[i].text);
          }
        } else {
          if (!old[i]) {
            editedResult.push({
              ...edited[i],
              type: 'added',
              isMainChapterRow: isMainChapterRow,
              pointNumber: newNumber,
              isShow: true,
              isOpen: true,
            });
          }
          if (!edited[i]) {
            editedResult.push({
              ...old[i],
              type: 'deleted',
              isMainChapterRow: isMainChapterRow,
              pointNumber: newNumber,
              isShow: true,
              isOpen: true,
            });
          }
        }
        editedResult = [...editedResult, ...this.comparePoints(old[i]?.chapters ?? [], edited[i]?.chapters ?? [], true)];
        editedResult = [...editedResult, ...this.comparePoints(old[i]?.points ?? [], edited[i]?.points ?? [], false, newNumber)];
      }
      return editedResult;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-xml-table {
  width: 100%;

  .td, .th {
    text-align: left;
    border: 1px solid #DEE2E6;
    padding: 20px;
  }

  &__button {
    height: 40px;
    width: 33px;

    border-radius: 0px 16px 16px 0px;
  }

  &__add-button {
    position: absolute;
    bottom: 0;
  }
}
</style>
