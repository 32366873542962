const SET_RETAILER = (state, RETAILER) => {
  state.supplierOfLastResortList = RETAILER.map((item) => ({
    label: item.name,
    value: item.name,
    inn: item.inn,
    available: item.available,
  }));
};

const SET_UTILITY = (state, UTILITY) => {
  state.gridCompanyList = UTILITY.map((item) => ({
    ...item,
    label: item.name,
    address: item.address,
    inn: item.inn,
    kpp: item.kpp,
    phone: item.phoneNumber,
    email: item.email,
    host: item.website,
    onPlatform: item.onPlatform,
    value: item.name,
  }));
};

const SET_CURRENT_UTILITY = (state, supplier) => {
  let suppliers = [];
  let kind = '';

  if (supplier.UTILITY && supplier.UTILITY.length > 0) {
    suppliers = supplier.UTILITY;
    kind = 'utility';
  }
  if (supplier.RETAILER && supplier.RETAILER.length > 0) {
    suppliers = supplier.RETAILER;
    kind = 'retailer';
  }

  if (supplier.ODK && supplier.ODK.length > 0) {
    suppliers = supplier.ODK;
    kind = 'odk';
  }

  state.currentUtilityCompany = suppliers.map((item) => ({
    label: item.name,
    address: item.address,
    inn: item.inn,
    kpp: item.kpp,
    phone: item.phoneNumber,
    email: item.email,
    host: item.website,
    onPlatform: item.onPlatform,
    value: item.name,
    boiler: item.boiler,
    boilerCompanyName: item.boilerCompany?.name,
    supplierOfLastResort: item.supplierOfLastResort,
    kind: kind,
  }));
};

const CLEAR_SUPPLIERS = (state) => {
  state.gridCompanyList = [];
  state.supplierOfLastResortList = [];
};

export default {
  SET_RETAILER,
  SET_UTILITY,
  CLEAR_SUPPLIERS,
  SET_CURRENT_UTILITY,
};
