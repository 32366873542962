var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.actionComponent === 'SendConnectionCertificate' || _vm.data.actionComponent === 'SendAdmissionCertificate')?_c('connection-certificate-details',{attrs:{"account-id":_vm.taskParams.accountId,"additional-document":_vm.additionalDocument,"application-data":_vm.applicationData,"application-id":_vm.data.applicationId,"data":_vm.data,"grid-company-name":_vm.gridCompanyName,"hide-offer-in-summary":_vm.data.hideOfferInSummary === true,"is-display-action":_vm.data.isDisplayAction,"is-loading":_vm.isLoading,"offer-document":_vm.offerDocument,"offer-document-title":_vm.data.offerDocumentTitle,"retail-company-name":_vm.retailCompanyName,"retail-review-document":_vm.retailReviewDocument,"retailer-offer-document":_vm.retailerOfferDocument,"review-document":_vm.reviewDocument,"summary-props":_vm.data.summaryProps},on:{"close":_vm.onClose},model:{value:(_vm.isShownTaskDetails),callback:function ($$v) {_vm.isShownTaskDetails=$$v},expression:"isShownTaskDetails"}}):_c('div',{class:_vm.$style.container},[_c('div',{style:({
      position: 'absolute',
      top: '0',
      backgroundColor: '#ffffff',
      height: '40px',
      padding: '10px',
      zIndex: '100',
      width: _vm.navBarWidth,
      marginLeft: '-16px',
     })},[_c('p',{class:_vm.$style.navigationBar},[_c('span',{class:_vm.$style.navigationRoot,on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.fromTitle))]),_c('span',{class:_vm.$style.navigationSeparator},[_vm._v(" > ")]),_c('span',[_vm._v(_vm._s(_vm.data.title))]),(_vm.data.subtitle)?_c('span',[_vm._v(" ("+_vm._s(_vm.data.subtitle)+")")]):_vm._e()])]),_c('div',{ref:"techPrisContent",class:_vm.$style.content},[_c('Details',{attrs:{"account-id":_vm.taskParams.accountId,"additional-document":_vm.additionalDocument,"admission-certificate-id":_vm.taskParams.admissionCertificateId,"application-data":_vm.applicationData,"application-id":_vm.data.applicationId,"connection-certificate-id":_vm.taskParams.connectionCertificateId,"utility-offer-document-id":_vm.taskParams.utilityOfferDocumentId,"data":_vm.data,"grid-company-name":_vm.gridCompanyName,"hide-offer-in-summary":_vm.data.hideOfferInSummary === true,"is-display-action":_vm.data.isDisplayAction,"is-loading":_vm.isLoading,"offer-document":_vm.offerDocument,"offer-document-title":_vm.data.offerDocumentTitle,"retail-company-name":_vm.retailCompanyName,"retail-review-document":_vm.retailReviewDocument,"retailer-offer-document":_vm.retailerOfferDocument,"review-document":_vm.reviewDocument,"summary-props":_vm.data.summaryProps,"technical-conditions-act-document-id":_vm.taskParams.technicalConditionsActDocumentId},on:{"close":_vm.onClose},model:{value:(_vm.isShownTaskDetails),callback:function ($$v) {_vm.isShownTaskDetails=$$v},expression:"isShownTaskDetails"}})],1),_c('div',{class:_vm.$style.contentFooter,style:({
      position: 'absolute',
      bottom: '0',
      width: _vm.footerWidth,
      backgroundColor: '#ffffff',
    })},[_c('div',{class:_vm.$style.back,on:{"click":_vm.onClose}},[_c('arrow-left'),_c('span',[_vm._v("НАЗАД")])],1),_c('div',{class:_vm.$style.center},[_vm._v(" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }