import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

export const initState = () => ({
  actDocumentDomainId: null,
  totalCost: null,
  performerFirstBankRequisites: null,
  performerSecondBankRequisites: null,
  paymentKind: null,
});

const state = initState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
