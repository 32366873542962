<template>
  <page title='Нерегулируемые цены на электрическую энергию, поставляемую АО "Мосэнергосбыт"'>
    <v-table-new
      v-model="transformedUtilityLossTariff"
      :loading="loading"
      :on-filter-change="onFilterChange"
      :on-load-all-values="loadAllFiltered"
      :on-pagination-change="onPaginationChange"
      :page-out="utilityLossTariffPage.pageOut"
      :template="template"
      :width-for-truncated="1300"
      excel-report-file-name='Нерегулируемые цены на электрическую энергию, поставляемую АО "Мосэнергосбыт"'
    />
  </page>
</template>

<script>
import { Page, VTableNew } from '@/components';
import { formatPeriod } from 'lib/utils/date.js';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';

const initialPagination = {
  page: 1,
  size: 10,
};

export default {
  name: 'UtilityLossTariff',
  components: {
    VTableNew,
    Page,
  },
  data() {
    return {
      utilityLossTariffPage: {},
      filter: {},
      loading: false,
      pagination: initialPagination,
    };
  },
  computed: {
    template() {
      return {
        headers: [
          {
            label: '№',
            key: 'index',
            type: 'index',
          },
          {
            label: 'Название приказа',
            key: 'tariffOrderName',
            sort: {},
            filter: {},
          },
          {
            label: 'Расчётный период',
            key: 'accountingPeriod',
            thStyle: {
              minWidth: '100px',
            },
            sort: { type: 'date' },
            filter: { type: 'date', format: 'MM.YYYY', selectType: 'month' },
          },
          {
            label: 'Средневзв. стоимость э/э (м) для величин превышения утвержденных объёмов потерь по балансу ФАС России',
            type: 'number',
            key: 'extraAverageElectricityPart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Сбытовая надбавка ГП для величин превышения утвержденных объёмов потерь по балансу ФАС России',
            type: 'number',
            key: 'extraRetailerPart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Инфраструктурные платежи для величин превышения утвержденных объемов потерь по балансу ФАС России',
            type: 'number',
            key: 'extraInfrastructurePart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Средневзв. стоимость э/э (м) для фактических объемов, не превышающих утвержденные объёмы потерь по балансу ФАС России',
            type: 'number',
            key: 'averageElectricityPart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Сбытовая надбавка ГПдля фактических объемов, не превышающих утвержденные объёмы потерь по балансу ФАС России',
            type: 'number',
            key: 'retailerPart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Инфраструктурные платежи для фактических объемов, не превышающих утвержденные объёмы потерь по балансу ФАС России',
            type: 'number',
            key: 'infrastructurePart',
            sort: {
              type: 'number',
            },
          },
          {
            label: 'Файл документа',
            key: 'document',
            type: 'documentDownload',
          },
        ],
      };
    },
    utilityLossTariff() {
      return this.utilityLossTariffPage.list ?? [];
    },
    transformedUtilityLossTariff() {
      return this.utilityLossTariff.map(this.transformed);
    },
    transformFilter() {
      const result = {};

      Object.keys(this.filter).forEach((filterKey) => {
        const filterValue = this.filter[filterKey].trim();

        if (filterValue.length !== 0) {
          result[filterKey] = filterValue;
        }
      });

      if (result?.accountingPeriod) delete result.accountingPeriod;

      return result;
    },
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.utilityLossTariffPage = await this.utilityLossTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: this.pagination.page,
            pageSize: this.pagination.size,
          },
        });
      } catch (e) {
        console.log('utilityLossTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async loadAllFiltered() {
      try {
        this.loading = true;
        const utilityLossTariff = await this.utilityLossTariffsByPaged({
          filter: this.transformFilter,
          pageIn: {
            currentPage: 1,
            pageSize: this.utilityLossTariffPage.pageOut.totalElements,
          },
        });

        if (!utilityLossTariff?.list) {
          return [];
        }

        return utilityLossTariff.list.map(this.transformed);
      } catch (e) {
        console.log('utilityLossTariffsByPaged', e);
        this.setNotification({ message: 'Нет связи с сервером' });
      } finally {
        this.loading = false;
      }
    },
    async onFilterChange(filter) {
      this.pagination.page = 1;
      if (filter?.accountingPeriod) {
        const date = dayjs(filter.accountingPeriod, 'MM.YYYY');
        filter.month = (date.month() + 1).toString();
        filter.year = date.year().toString();
      } else {
        filter.month = '';
        filter.year = '';
      }
      this.filter = { ...filter };
      await this.loadData();
    },
    async onPaginationChange(pagination) {
      if (pagination.page !== this.pagination.page || pagination.size !== this.pagination.size) {
        this.pagination = { ...pagination };
        await this.loadData();
      }
    },
    transformed(it) {
      return {
        id: it.id,
        tariffOrderName: it.tariffsOrder?.orderName,
        accountingPeriod: formatPeriod(it.month, it.year),
        extraAverageElectricityPart: it.extraAverageElectricityPart,
        extraRetailerPart: it.extraRetailerPart,
        extraInfrastructurePart: it.extraInfrastructurePart,
        averageElectricityPart: it.averageElectricityPart,
        retailerPart: it.retailerPart,
        infrastructurePart: it.infrastructurePart,
        document: { documentId: it.tariffsOrder?.documentId, name: 'Нерегулируемые цены на электрическую энергию' },
      };
    },
    ...mapActions('dataLists', ['utilityLossTariffsByPaged']),
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style lang="scss" scoped>

</style>
