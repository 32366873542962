import axios from 'axios';
import validate from 'lib/utils/validationRules';

async function geocodeByLocation(state, { latitude, longitude }) {
  try {
    const { data: { result } } = await axios.get(`https://catalog.api.2gis.com/3.0/items/geocode?lat=${latitude}&lon=${longitude}&fields=items.point&key=ruafps3582`);

    if (validate.isUndefined(result)) {
      return null;
    }

    if (validate.isEmptyArray(result.items)) {
      return null;
    }

    console.log(result);
    return { ...result.items[0] };
  } catch (error) {
    throw new Error(error);
  }
}

async function geocodeByAddress(state, address) {
  try {
    const { data: { result } } = await axios.get(`https://catalog.api.2gis.com/3.0/items/geocode?q=${address}&fields=items.point&key=ruafps3582`);

    if (validate.isUndefined(result)) {
      return null;
    }

    if (validate.isEmptyArray(result.items)) {
      return null;
    }

    const { point: { lat, lon } } = result.items[0];

    console.log(result);
    return { latitude: lat, longitude: lon };
  } catch (error) {
    throw new Error(error);
  }
}

export default {
  geocodeByLocation,
  geocodeByAddress,
};
