<template>
  <div class="exploitation-object-content">
    <p v-if="isLoading" class="text">Загрузка данных...</p>
    <div v-else-if="!isLoading && getObjects">
      <v-table-new
        :value="tableData"
        :template="objectsTableHeaders"
        excel-report-file-name="Эксплуатация. Объекты"
        :pagination-show="false"
        is-display-map-option
        @change-mode="changeTableMode"
        @tap-placemark="onTapPlacemark"
      />
    </div>
    <p v-else class="text">Нет данных</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VTableMode } from 'components/VTableNew/constants';
import { VTableNew } from '@/components';

export default {
  name: 'ExploitationObjects',
  components: { VTableNew },
  created() {
    this.loadData();
  },
  data() {
    return {
      tableMode: VTableMode.TABLE,
    };
  },
  computed: {
    ...mapGetters('exploitation', [
      'isLoading',
      'getObjects',
      'objectsTableHeaders',
      'getObjectsForTable',
      'getObjectsMapForTable',
    ]),
    tableData() {
      return this.tableMode === VTableMode.MAP
        ? this.getObjectsMapForTable
        : this.getObjectsForTable;
    },
  },
  methods: {
    ...mapActions('exploitation', ['getObjectsData']),
    loadData() {
      void this.getObjectsData(this.$route.query.id);
    },
    changeTableMode(mode) {
      this.tableMode = mode;
    },
    async onTapPlacemark(id) {
      return await this.$router.push({
        name: 'cabinet.process.11.5-7',
        params: {
          initAdreskaFactId: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/grid.scss';

.exploitation-object-content {
  padding: 16px 0;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(29, 41, 57, 1);
}

#app .vuetify-styles {
  width: 100%;
}

.vuetify-styles .v-application--wrap {
  min-height: auto;
}
</style>
