<template>
  <div :class="$style.companyInfo" :style="borderStyle">
    <img v-if="error" alt="Ошибка" height="20" src="~@/assets/icons/basic/error.svg" width="20"/>
    <img v-else alt="Успешно" height="20" src="~@/assets/icons/basic/checked.svg" width="20"/>
    <div>
      <p :class="$style.capTitle">{{ title }}</p>
      <div v-if="error">
        <p :class="$style.capInfo">{{ errorText }}</p>
      </div>
      <div v-else-if="owner">
        <p :class="$style.capInfo">Владелец подписи</p>
        <p :class="$style.capInfo">{{ owner.name }}</p>
        <p :class="$style.capInfo">ИНН {{ owner.inn }}</p>
        <p v-if="owner?.ogrnip" :class="$style.capInfo">ОГРНИП {{ owner.ogrnip }}</p>
        <p v-if="owner?.ogrn" :class="$style.capInfo">ОГРН {{ owner.ogrn }}</p>
        <p :class="$style.capInfo">СНИЛС {{ owner.person.snils }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CertificateValidator',
  props: {
    certificate: { type: Object, required: true },
    customValidations: { type: Array, default: () => [] },
  },
  data() {
    return {
      owner: null,
      error: false,
      errorText: '',
    };
  },
  computed: {
    title() {
      return this.error ? 'Ошибка при проверке КЭП' : 'Проверка КЭП пройдена!';
    },
    borderStyle() {
      return this.error ? { border: '1px solid red' } : { border: '1px solid #54B687' };
    },
  },
  watch: {
    certificate: {
      immediate: true,
      async handler() {
        await this.validateCertificate();
      },
    },
  },
  methods: {
    async validateCertificate() {
      try {
        if (!this.certificate.isValid()) {
          this.errorText = 'Невалидный сертификат';
          this.error = true;
          return;
        }
        const certificate = await Promise.resolve(this.certificate.getOwnerInfo());
        const customValidationsResult = await Promise.all(this.customValidations.map((it) => it.validator(certificate)));
        this.customValidations.forEach((validation, idx) => {
          if (!customValidationsResult[idx]) {
            this.errorText = validation.errorText;
            this.error = true;
          }
        });

        let ogrnip = null;
        let legalInn = null;

        const ognipFromCertificate = certificate.find((item) => item.title === 'ОГРНИП' || item.title === '1.2.643.100.5' || item.title === 'OGRNIP');
        const legalInnFromCertificate = certificate.find((item) => item.title === 'ИНН ЮЛ' || item.title === 'INNLE' || item.title === 'OID.1.2.643.100.4');

        if (ognipFromCertificate?.description) {
          ogrnip = ognipFromCertificate.description;
        }
        if (legalInnFromCertificate?.description) {
          legalInn = legalInnFromCertificate.description;
        }

        if (ogrnip !== null) {
          // блок ип
          this.owner = {
            name: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description,
            inn: certificate.find((item) => item.title === 'ИНН' || item.title === '1.2.643.3.131.1.1' || item.title === 'INN').description,
            ogrnip: certificate.find((item) => item.title === 'ОГРНИП' || item.title === '1.2.643.100.5' || item.title === 'OGRNIP').description,
            address: certificate.find((item) => item.title === 'Адрес' || item.title === '2.5.4.9' || item.title === 'streetAddress').description,
            person: {
              familyName: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description.split(' ')[1],
              firstName: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description.split(' ')[2],
              patronymic: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description.split(' ')[3],
              snils: certificate.find((item) => item.title === 'СНИЛС' || item.title === '1.2.643.100.3' || item.title === 'SNILS').description,
            },
            isLegal: false,
          };
        } else if (legalInn !== null) {
          // блок юр лицо
          this.owner = {
            name: certificate.find((item) => item.title === 'Компания' || item.title === '2.5.4.10' || item.title === 'organizationName').description,
            ownerName: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description,
            inn: legalInn,
            ogrn: certificate.find((item) => item.title === 'ОГРН' || item.title === '1.2.643.100.1' || item.title === 'OGRN').description,
            address: certificate.find((item) => item.title === 'Адрес' || item.title === '2.5.4.9' || item.title === 'streetAddress').description,
            person: {
              familyName: certificate.find((item) => item.title === 'Фамилия' || item.title === '2.5.4.4' || item.title === 'surname').description,
              firstName: certificate.find((item) => item.title === 'Имя Отчество' || item.title === '2.5.4.42' || item.title === 'givenName').description.split(' ')[0],
              patronymic: certificate.find((item) => item.title === 'Имя Отчество' || item.title === '2.5.4.42' || item.title === 'givenName').description.split(' ')[1],
              inn: certificate.find((item) => item.title === 'ИНН' || item.title === '1.2.643.3.131.1.1' || item.title === 'INN').description,
              snils: certificate.find((item) => item.title === 'СНИЛС' || item.title === '1.2.643.100.3' || item.title === 'SNILS').description,
            },
            isLegal: true,
          };
        } else {
          // блок физ лица
          this.owner = {
            ownerName: certificate.find((item) => item.title === 'Владелец' || item.title === '2.5.4.3' || item.title === 'commonName').description,
            person: {
              inn: certificate.find((item) => item.title === 'ИНН' || item.title === '1.2.643.3.131.1.1' || item.title === 'INN').description,
              snils: certificate.find((item) => item.title === 'СНИЛС' || item.title === '1.2.643.100.3' || item.title === 'SNILS').description,
            },
            isLegal: false,
          };
        }

      } catch (e) {
        console.log('error', e);
        this.errorText = 'Невалидный сертификат';
        this.error = true;
      }
      this.$emit('parsed', this.owner);
      if (this.error) {
        this.$emit('error', this.errorText);
      } else {
        this.$emit('validated', this.owner);
      }
    },
  },
};

</script>

<style lang="sass" module>
.companyInfo
  display: flex
  padding: 16px 0 8px
  box-sizing: border-box
  margin-bottom: 38px

  img
    width: 20px
    margin-left: 36px
    margin-right: 18px

.capTitle
  +title-small-uppercase
  margin-bottom: 8px

.capInfo
  +small-text
  margin-bottom: 4px
</style>
