<template>
  <v-app class="vuetify-styles" data-app>
    <v-row class="form1" justify="center">
      <v-col lg="8" md="10" sm="12">
        <div ref="form" class="v-application" style="display: block">
          <v-card-title>
            <div class="text-h5">Выдача АХП со склада</div>
          </v-card-title>
          <v-card-text>

            <simple-autocomplete
              ref="planConsignment"
              v-model="planConsignmentId"
              :items.sync="planConsignments"
              :search.sync="planConsignmentName"
              search-by="uniqueNumber"
              title="Плановая накладная"
              url="/plan-consignment/find?type=EXTRADITE_FROM_WAREHOUSE&hideArchive=true&uniqueNumber="
              url-default-data="/plan-consignment/find?type=EXTRADITE_FROM_WAREHOUSE&hideArchive=true"
            ></simple-autocomplete>

            <process2 ref="data" v-model="data" :plan-consignment="planConsignment"/>

            <text-field
              ref="contractorEmployeeName"
              v-model="contractorEmployeeName"
              title="Сотрудник подрядчика"
            ></text-field>
            <file-input
              ref="photos"
              v-model="photos"
              class="mt-2"
              is-required
              title="Выберите фото"
            ></file-input>
            <employee-autocomplete
              ref="performer"
              v-model="performerId"
              :items.sync="performerItems"
              :search.sync="performerName"
              class="mt-2"
              is-required
              set-current-by-default
              title="Сотрудник, совершивший операцию"
            ></employee-autocomplete>
            <warehouse-date-picker
              ref="date"
              v-model="date"
              is-required
              label="Дата накладной"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              Назад
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabledSaveDialog"
              color="primary"
              elevation="0"
              rounded
              @click="onTapSend"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-dialog v-model="loaderDialog"
                class="v-dialog-top" persistent width="250">
        <v-card class="v-application">
          <v-card-title class="text-h5 text-center green lighten-2">
            Загрузка данных
          </v-card-title>
          <div class="text-center mt-8">
            <div v-if="progressValue < 100">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon :size="70" :width="7" color="green darken-2">
                mdi-cloud-check-variant
              </v-icon>
            </div>

            <div class="mt-2 mb-8">
              {{ progressValue + "%" }}
            </div>
          </div>
          <v-card-actions class="flex-column">
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapBack"
            >
              На главную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="primary"
              outlined
              rounded
              @click="onTapViewReport"
            >
              Показать накладную
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="0" rounded @click="onTapAgain">
              Заполнить еще раз
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <consignment v-model="dialogForViewReport" :consignment="dataReportCustom"/>
    </v-row>
  </v-app>
</template>

<script>
import { BASE_URL } from '@/constants/api';
import FileInput from '@/components/Processes/components/FileInput.vue';
// import { D2_A, checkAccess } from '@/constants/access';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import WarehouseDatePicker from 'components/Processes/components/WarehouseDatePicker.vue';
import { formatDateFromOdkTimestamp } from 'lib/utils/date';
import Consignment from 'components/Processes/Consignment/Consignment.vue';
import Process2 from '../WarehouseForms/Process6.vue';
import TextField from 'components/Processes/components/TextField.vue';
import SimpleAutocomplete from 'components/Processes/components/SimpleAutocomplete.vue';
import EmployeeAutocomplete from 'components/Processes/components/EmployeeAutocomplete.vue';

export default {
  components: {
    EmployeeAutocomplete,
    SimpleAutocomplete,
    TextField,
    WarehouseDatePicker,
    Consignment,
    FileInput,
    Process2,
  },
  data: () => ({

    planConsignmentId: null,
    planConsignmentName: '',
    planConsignments: [],

    performerId: null,
    performerName: '',
    performerItems: [],

    data: {
      axpQuantity: '',
      quantityPlanAxpForEvent: null,
      countAxpInStrock: null,
      countAxpIssued: null,

      formElements: [],
      debt: [],

      eventId: null,
      warehouseId: null,
      contractorId: null,
      adreskaId: null,
      axpId: null,

      warehouseName: '',
      contractorName: '',
      axpName: '',
      adreskaAddress: '',
      eventName: '',

      isDisabled: true,
    },


    date: null,
    photos: [],

    progressValue: 0,
    loaderDialog: false,

    dialogForViewReport: false,
    dataReportCustom: {},

    defaultHeaders: [
      {
        text: 'Элемент',
        align: 'start',
        value: 'elementName',
      },
      { text: 'Количество на складе', value: 'quantityInWarehouse', align: 'center' },
      {
        text: 'Нужно',
        value: 'needQuantity',
        align: 'center',
      },
      {
        text: 'Долги подрядчика',
        value: 'debt',
        align: 'center',
      },
      {
        text: 'Выдаваемое количество',
        value: 'realCount',
        align: 'center',
      },
    ],

    contractorEmployeeName: null,
  }),
  computed: {
    isDisabledSaveDialog() {

      const isDisablePhoto = !this.photos?.length;
      const isDisabledDate = this.date == null;
      const isDisabledPerformer = this.performerId == null;

      return (
        this.data.isDisabled ||
        isDisablePhoto ||
        isDisabledDate ||
        isDisabledPerformer
      );
    },

    planConsignment() {
      if (!this.planConsignmentId) return null;
      return this.planConsignments.find((it) => it.id === this.planConsignmentId);
    },
    ...mapGetters('user', ['getToken', 'getUser']),
  },
  methods: {
    onTapSend() {
      this.loaderDialog = true;
      this.progressValue = 0;

      const mainPhotos = [];
      this.photos.forEach((doc) => {
        mainPhotos.push({
          stringBase64: doc.previewUrl,
          fileName: doc.name,
        });
      });
      const request = {
        performerUserId: this.performerId,
        planConsignmentId: this.planConsignmentId,
        warehouseId: this.data.warehouseId,
        contractorId: this.data.contractorId,
        adreskaId: this.data.adreskaId,
        eventId: this.data.eventId,
        axpId: this.data.axpId,
        elements: [],
        photos: mainPhotos,
        createDate: this.date,
        contractorEmployeeName: this.contractorEmployeeName,
      };

      this.data.formElements.forEach((f) => {
        if (Number(f.realCount) > 0) {
          request.elements.push({
            elementId: f.elementId,
            extraditeCount: f.realCount ?? 0,
            quantity: f.elementQuantity,
          });
        }
      });

      axios
        .post(`${BASE_URL}/extradite-construction`, request, { headers: { 'X-Authorization': `Bearer ${this.getToken}` } })
        .then(() => {
          this.progressValue = 100;
        })
        .catch((error) => {
          console.error(error);
          this.loaderDialog = false;
          this.setNotification({ message: error.response.data });
        });
    },
    onTapBack() {
      this.$router.back();
    },
    onTapAgain() {
      this.progressValue = 0;
      this.photos = [];
      this.loaderDialog = false;
      this.dialogForViewReport = false;
      this.dataReportCustom = {};
      this.date = null;
      this.performerId = null;
      this.performerName = '';
      this.performerItems = [];

      this.data = {
        axpQuantity: '',
        quantityPlanAxpForEvent: null,
        countAxpInStrock: null,
        countAxpIssued: null,


        formElements: [],
        debt: [],

        eventId: null,
        warehouseId: null,
        contractorId: null,
        adreskaId: null,
        axpId: null,

        warehouseName: '',
        contractorName: '',
        axpName: '',
        adreskaAddress: '',
        eventName: '',

        isDisabled: true,
      };
      this.$refs.data.resetValidation();
    },
    onTapViewReport() {
      const els = this.data.formElements.filter((element) => Number(element.realCount) > 0).map((element) => ({
        axpName: this.data.axpName,
        date: formatDateFromOdkTimestamp(this.date),
        type: 'Выдача',
        performer: this.performerName,
        contractorName: this.data.contractorName,
        warehouseName: this.data.warehouseName,
        photos: this.photos,
        elementName: element.elementName,
        unitName: element.unitName,
        count: element.realCount,
        employee: this.getUser.fullName,
        eventName: this.data.eventName,
        adreskaName: this.data.adreskaAddress,
        contractorEmployeeName: this.contractorEmployeeName,
      }));

      this.dataReportCustom = els[0];
      this.dataReportCustom.elements = els;


      this.dialogForViewReport = true;
    },
    onTapCloseDialogReport() {
      this.dataReportCustom = {};
      this.dialogForViewReport = false;
    },
    ...mapActions('user', ['setNotification']),
  },
};
</script>

<style scoped>
.form1 {
  padding: 30px;
}
</style>

