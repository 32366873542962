<template>
  <div :class="$style.container">
    <div ref="navigationBar" :style="{...stickyPosition, top: '-16px' }">
      <navigation-bar :class="$style.navigationBar"
                      :current-step="usefulVacationDocument.currentStep"
                      :home="task === undefined ? 'Справки о полезном отпуске' : 'Задачи'"
                      :steps="steps"
                      @open="openStep" />

      <v-divider :class="$style.dividerNavigation" />
    </div>

    <div ref="usefulVacationContent" :class="$style.content" role="detailsContent">
      <component
        :is="steps[usefulVacationDocument.currentStep].component"
        :key="componentKey"
        ref="details"
        :component-key="componentKey"
        :disable="disable"
        :is-error="isError"
        :step-number="steps[usefulVacationDocument.currentStep].stepNumber"
        :task="task"
        :useful-vacation-document="usefulVacationDocument"
        @close="close"
        @complete="complete"
        @next="next"
        @reload="reload"
      />

      <v-divider :class="$style.divider" />
    </div>

    <div :class="$style.contentFooter" :style="{...stickyPosition, bottom: '16px' }">
      <div style="display: flex">
        <v-button :class="$style.back" type="button" variant="outlined-colored" @click="back">
          <chevron-left />
          <span>Назад</span>
        </v-button>
        <v-button :class="$style.delete" type="button" variant="outlined-red" @click="displayDeleteDialog = true">
          <delete />
          <span>Удалить черновик</span>
        </v-button>
      </div>
      <v-button v-show="displayNext" :class="$style.next" type="button" @click="next">
        <span>Далее</span>
        <arrow-right />
      </v-button>
    </div>

    <dialog-template v-model="displayDeleteDialog" center>
      <p :class="$style.deleteText">
        Удалить черновик?
      </p>
      <div :class="$style.submitBlock">
        <submit-task-details :red="true" title="УДАЛИТЬ" @submit="deleteDraft" />
        <submit-task-details :gray="true" title="ЗАКРЫТЬ" @submit="displayDeleteDialog = false" />
      </div>
    </dialog-template>
  </div>
</template>

<script>
import ArrowRight from 'atoms/icons/arrows/ArrowRight';
import ChevronLeft from 'atoms/icons/navigation/ChevronLeft.vue';
import Delete from 'atoms/icons/action/Delete.vue';

import Additions from './steps/Additions';
import UsefulVacation from './steps/UsefulVacation';
import DownloadReport from './steps/DownloadReport';
import NavigationBar from 'components/NavigationBar/NavigationBar.vue';

import DialogTemplate from 'templates/DialogTemplate';
import SubmitTaskDetails from 'organisms/tasks/SubmitTaskDetails';

import localforage from 'localforage';
import throttle from 'lib/utils/throttle';
import { scrollIntoView } from 'lib/utils/DOM';
import { mapGetters, mapState } from 'vuex';
import VWorker from '@/utils/worker';

import SignatoriesStep from './steps/SignatoriesStep.vue';
import { VButton, VDivider } from 'components';

const attachment2NameWithoutExtension = 'Справка по ПО';

export default {
  components: {
    VButton,
    Delete,
    ChevronLeft,
    ArrowRight,
    Additions,
    UsefulVacation,
    DownloadReport,
    NavigationBar,
    DialogTemplate,
    SubmitTaskDetails,
    SignatoriesStep,
    VDivider,
    HorizontalProgressBar: () => import('atoms/common/HorizontalProgressBar'),
  },
  props: {
    task: Object,
    index: Number,
    isComments: {
      type: Boolean,
      default: () => false,
    },
  },
  async created() {
    try {
      const account = this.account.profile;

      this.usefulVacationDocument.isRetailer = this.account.kind === 'RETAILER' || this.account.kind === 'SUPPLIER_OF_LAST_RESORT';
      if (!this.usefulVacationDocument.isRetailer)
        this.usefulVacationDocument.UtilityInfo = {
          name: account.name,
          inn: account.inn,
        };

      const usefulVacationDocument = await localforage.getItem('usefulVacationCertificateDocument');

      if (usefulVacationDocument === null) {
        return;
      }

      const object = JSON.parse(usefulVacationDocument);

      if (object.version === undefined || object.version < this.usefulVacationDocument.version) {
        return;
      }

      this.usefulVacationDocument = object;

      this.usefulVacationDocument.isRetailer = this.account.kind === 'RETAILER' || this.account.kind === 'SUPPLIER_OF_LAST_RESORT';
      if (!this.usefulVacationDocument.isRetailer)
        this.usefulVacationDocument.UtilityInfo = {
          name: account.name,
          inn: account.inn,
        };

    } catch (error) {
      console.log('error getData usefulVacationDocument', error);
    }
    window.addEventListener('resize', this.setElementsWidth);
  },
  mounted() {
    this.setElementsWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.setElementsWidth);
  },
  data() {
    return {
      usefulVacationDocument: {
        version: 4,
        currentStep: 0,
        attachment2: {},
        attachment2File: null,
        attachment2Error: [],
        attachments_error: { error: false },
        unitType: '',
        unitType_error: { error: false },
        powerUnitType: '',
        powerUnitType_error: { error: false },
        Retailer: {
          id: '',
          name: '',
        },
        Retailer_error: { error: false },
        UtilityInfo: {
          name: '',
          inn: '',
        },
        Utility_error: { error: false },
        eighteenthForms: {},
        AccountingPeriod: '',
        AccountingPeriod_error: { error: false },
        isDisplayDocumentScan: false,
        documentsScan: [],
        documentsScan_error: { error: false },
        currentSignatory: {
          role: {
            id: '',
            name: '',
            nameGenitive: '',
          },
          reason: {
            name: '',
            nameGenitive: '',
            number: null,
            date: null,
          },
          fullName: {},
          fullNameGenitive: {},
          roleName: {},
          roleNameGenitive: {},
        },
        inputSignatory: {
          userId: '',
          role: {
            id: '',
            name: '',
            nameGenitive: '',
          },
          accountId: '',
          reason: {
            name: '',
            nameGenitive: '',
            number: null,
            date: null,
          },
          fullName: {
            name: '',
            surname: '',
            patronymic: '',
          },
          fullNameGenitive: {},
        },
        signatory_error: { error: false },
        signatory_fullName_name_error: { error: false },
        signatory_fullName_surname_error: { error: false },
        signatory_fullName_patronymic_error: { error: false },
        signatory_position_error: { error: false },
        signatory_reason_reasonDocument_error: { error: false },
        signatory_reason_number_error: { error: false },
        signatory_reason_date_error: { error: false },
        isRetailer: false,
      },
      steps: [
        {
          component: 'Additions',
          stepNumber: {
            value: 1,
            from: 4,
            title: () => 'Загрузка данных',
          },
          isComplete: true,
          isDisplayEdit: false,
          validations: this.validAdditions,
        },
        {
          component: 'UsefulVacation',
          stepNumber: {
            value: 2,
            from: 4,
            title: () => 'Проверка приложений',
          },
          isDisplayEdit: true,
          isComplete: true,
          preValidations: this.validUsefulVacation,
          validations: this.validUsefulVacation,
        },
        {
          component: 'SignatoriesStep',
          stepNumber: {
            value: 3,
            from: 4,
            title: () => 'Подписывающие лица',
          },
          isDisplayEdit: false,
          isComplete: true,
          validations: this.validSignatures,
        },
        {
          component: 'DownloadReport',
          stepNumber: {
            value: 4,
            from: 4,
            title: () => 'Сформированные XML файлы',
          },
          isDisplayEdit: false,
          isComplete: false,
        },
      ],
      componentKey: 1,
      disable: true,
      displayDeleteDialog: false,
      isWaitingSubmit: false,
      attachment2NameWithoutExtension: attachment2NameWithoutExtension,
      attachment2Name: `${attachment2NameWithoutExtension}.xlsx`,
      isError: false,
      footerWidth: '0',
    };
  },
  computed: {
    displayBack() {
      return this.usefulVacationDocument.currentStep > 0;
    },
    displayNext() {
      return this.usefulVacationDocument.currentStep >= 0 && this.steps[this.usefulVacationDocument.currentStep].isComplete;
    },
    stickyPosition() {
      return {
        position: 'sticky',
        backgroundColor: '#ffffff',
        paddingLeft: '16px',
        zIndex: '100',
        marginLeft: '-16px',
        paddingTop: '16px',
        marginTop: '-16px',
        marginBottom: '-8px',
        paddingBottom: '8px',
      };
    },
    ...mapGetters('dataLists', [
      'voltageLevelList',
      'reliabilityClassList',
      'voltageClassList',
      'typeAccountingList',
      'ownerAccountingFacilitiesList',
      'measurementsReadingKindList',
    ]),
    ...mapState('user', ['account']),
  },
  watch: {
    usefulVacationDocument: {
      deep: true,
      immediate: false,
      handler: 'saveDataIntermediate',
    },
  },
  methods: {
    validAdditions() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      isValid = this.usefulVacationDocument.attachment2File !== null;
      if (!isValid) {
        result = true;
        errorRole = 'filledAttachments';
        this.usefulVacationDocument.attachments_error = { error: true };
      } else {
        this.usefulVacationDocument.attachments_error = { error: false };
      }

      isValid = this.usefulVacationDocument.AccountingPeriod.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'AccountingPeriod';
        this.usefulVacationDocument.AccountingPeriod_error = { error: true };
      } else {
        this.usefulVacationDocument.AccountingPeriod_error = { error: false };
      }

      isValid = this.usefulVacationDocument.documentsScan.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'documentsScan';
        this.usefulVacationDocument.documentsScan_error = { error: true };
      } else {
        this.usefulVacationDocument.documentsScan_error = { error: false };
      }

      isValid = this.usefulVacationDocument.Retailer.name.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Retailer';
        this.usefulVacationDocument.Retailer_error = { error: true };
      } else {
        this.usefulVacationDocument.Retailer_error = { error: false };
      }

      isValid = this.usefulVacationDocument.UtilityInfo.name.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'Utility';
        this.usefulVacationDocument.Utility_error = { error: true };
      } else {
        this.usefulVacationDocument.Utility_error = { error: false };
      }

      isValid = this.usefulVacationDocument.powerUnitType.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'powerUnitType';
        this.usefulVacationDocument.powerUnitType_error = { error: true };
      } else {
        this.usefulVacationDocument.powerUnitType_error = { error: false };
      }

      isValid = this.usefulVacationDocument.unitType.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'unitType';
        this.usefulVacationDocument.unitType_error = { error: true };
      } else {
        this.usefulVacationDocument.unitType_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validUsefulVacation() {
      let errorRole = '';
      let isValid = true;
      let result = false;

      function validVoltageClassValues(item, index, role) {
        isValid = item.vn.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_vn${index}`;
          item.vn_error = { error: true };
        } else {
          item.vn_error = { error: false };
        }

        isValid = item.sn1.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_sn1${index}`;
          item.sn1_error = { error: true };
        } else {
          item.sn1_error = { error: false };
        }

        isValid = item.sn2.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_sn2${index}`;
          item.sn2_error = { error: true };
        } else {
          item.sn2_error = { error: false };
        }

        isValid = item.nn.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_nn${index}`;
          item.nn_error = { error: true };
        } else {
          item.nn_error = { error: false };
        }

        isValid = item.total.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_total${index}`;
          item.total_error = { error: true };
        } else {
          item.total_error = { error: false };
        }
      }

      function valueValid(item, index, valuesIndex, role, voltageClass) {
        isValid = item.value.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `${role}_${valuesIndex}_${voltageClass}${index}`;
          item.value_error = { error: true };
        } else {
          item.value_error = { error: false };
        }
      }

      this.usefulVacationDocument.attachment2.transit.forEach((item, index) => {
        validVoltageClassValues(item, `_${index}`, 'transit');
      });

      validVoltageClassValues(this.usefulVacationDocument.attachment2.ownConsumption, '', 'ownConsumption');

      this.usefulVacationDocument.attachment2.voltageClassValues.forEach((valuesItem, valuesIndex) => {
        valuesItem.values.vn.forEach((item, index) => {
          valueValid(item, `_${index}`, valuesIndex, 'voltageClass', 'vn');
        });

        valuesItem.values.sn1.forEach((item, index) => {
          valueValid(item, `_${index}`, valuesIndex, 'voltageClass', 'sn1');
        });

        valuesItem.values.sn2.forEach((item, index) => {
          valueValid(item, `_${index}`, valuesIndex, 'voltageClass', 'sn2');
        });

        valuesItem.values.nn.forEach((item, index) => {
          valueValid(item, `_${index}`, valuesIndex, 'voltageClass', 'nn');
        });

        isValid = valuesItem.values.total.length > 0;

        if (!isValid) {
          result = true;
          errorRole = `voltageClass_${valuesIndex}_total`;
          valuesItem.values.total_error = { error: true };
        } else {
          valuesItem.values.total_error = { error: false };
        }
      });

      this.usefulVacationDocument.attachment2.departments.forEach((item, index) => {
        validVoltageClassValues(item, `_${index}`, 'department');
      });

      validVoltageClassValues(this.usefulVacationDocument.attachment2.value_2_6, '', 'value_2_6');

      validVoltageClassValues(this.usefulVacationDocument.attachment2.value_1, '', 'value_1');

      isValid = this.usefulVacationDocument.attachment2.date.length > 0;
      if (!isValid) {
        result = true;
        errorRole = 'attachment2_date';
        this.usefulVacationDocument.attachment2.date_error = { error: true };
      } else {
        this.usefulVacationDocument.attachment2.date_error = { error: false };
      }

      return { isError: result, firstError: errorRole };
    },
    validSignatures() {

      let errorRole = '';
      let isValid = true;
      let result = false;


      isValid = this.usefulVacationDocument.currentSignatory.userId && this.usefulVacationDocument.currentSignatory.userId.length > 0;

      if (!isValid) {
        result = true;
        errorRole = 'signatory';
        this.usefulVacationDocument.signatory_error = { error: true };
      } else {
        this.usefulVacationDocument.signatory_error = { error: false };
      }

      if (this.usefulVacationDocument.isDisplayDocumentScan) {

        isValid = this.usefulVacationDocument.inputSignatory.reason.name && this.usefulVacationDocument.inputSignatory.reason.name.length > 0;

        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_reason_reasonDocument_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_reason_reasonDocument_error = { error: false };
        }

        isValid = this.usefulVacationDocument.inputSignatory.reason.name === 'Устав' || (this.usefulVacationDocument.inputSignatory.reason.number && this.usefulVacationDocument.inputSignatory.reason.name.length > 0);

        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_reason_number_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_reason_number_error = { error: false };
        }

        isValid = this.usefulVacationDocument.inputSignatory.reason.name === 'Устав' || (this.usefulVacationDocument.inputSignatory.reason.date && this.usefulVacationDocument.inputSignatory.reason.date.length > 0);


        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_reason_date_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_reason_date_error = { error: false };
        }

        isValid = this.usefulVacationDocument.inputSignatory.role.name && this.usefulVacationDocument.inputSignatory.role.name.length > 0;

        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_position_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_position_error = { error: false };
        }

        isValid = this.usefulVacationDocument.inputSignatory.fullName.name && this.usefulVacationDocument.inputSignatory.fullName.name.length > 0;

        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_fullName_name_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_fullName_name_error = { error: false };
        }

        isValid = this.usefulVacationDocument.inputSignatory.fullName.surname && this.usefulVacationDocument.inputSignatory.fullName.surname.length > 0;

        if (!isValid) {
          result = true;
          errorRole = 'signatory';
          this.usefulVacationDocument.signatory_fullName_surname_error = { error: true };
        } else {
          this.usefulVacationDocument.signatory_fullName_surname_error = { error: false };
        }

      }
      return { isError: result, firstError: errorRole };
    },
    eighteenthFormsVacation() {
      const errorRole = '';
      const result = false;

      return { isError: result, firstError: errorRole };
    },
    saveDataIntermediate: throttle(function() {
      this.saveData();
    }, 500),
    async saveData() {
      try {
        await localforage.setItem('usefulVacationCertificateDocument', JSON.stringify(this.usefulVacationDocument));
      } catch (error) {
        console.log('error usefulVacationDocument.save, data:', this.usefulVacationDocument);
      }
    },
    async clearData() {
      try {
        await localforage.removeItem('usefulVacationCertificateDocument');
      } catch (error) {
        console.log('error usefulVacationDocument.clear, data:', this.usefulVacationDocument);
      }
    },
    scrollToTop() {
      const scrollButton = document.querySelector('.scroll-button');
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      scrollButton.dispatchEvent(clickEvent);
    },
    close() {
      if (this.task === undefined) {
        this.$router.push('/cabinet/useful-vacation-certificate');
        return;
      }
      this.$emit('close');
    },
    back() {
      if (this.usefulVacationDocument.currentStep === 0) {
        this.close();
        return;
      }

      this.usefulVacationDocument.currentStep -= 1;
      this.scrollToTop();
    },
    next() {
      this.scrollToTop();
      if (this.steps[this.usefulVacationDocument.currentStep].validations) {
        const result = this.steps[this.usefulVacationDocument.currentStep].validations();
        this.isError = result.isError;

        if (result.isError) {
          this.$nextTick(() => {
            console.log('result.firstError', result.firstError);
            scrollIntoView(document.querySelector(`[role=${result.firstError}]`));
          });

          return;
        }
        this.componentKey += 1;
      }
      this.usefulVacationDocument.currentStep += 1;

      this.preValidations();

      document.querySelector('[role=detailsContent]').scrollTop = 0;
    },
    complete(isComplete) {
      this.steps[this.usefulVacationDocument.currentStep].isComplete = isComplete;

      if (isComplete && (
        (this.usefulVacationDocument.currentStep === 3)
      )) {
        this.clearData();
      }
    },
    changePoint() {
      this.disable = false;
    },
    savePoint() {
      this.disable = true;
    },
    openStep(value) {
      if (value === -1) {
        this.close();
      }
      if (value >= this.usefulVacationDocument.currentStep + 1) {
        return;
      }

      this.usefulVacationDocument.currentStep = value - 1;
    },
    deleteDraft() {
      this.clearData();
      this.close();
    },
    preValidations() {
      if (this.steps[this.usefulVacationDocument.currentStep].preValidations) {
        const result = this.steps[this.usefulVacationDocument.currentStep].preValidations();

        this.steps[this.usefulVacationDocument.currentStep].isComplete = !result.isError;
        this.isError = result.isError;

        // if (result.isError) {
        this.componentKey += 1;
        // }
      }
    },
    async reload(val) {
      if (val.length === 0) {
        return;
      }

      this.isWaitingSubmit = true;
      let incorrectFiles = [];

      try {
        const res = await VWorker.run(
          (
            val,
            usefulVacationDocument,
            attachment2NameWithoutExtension,
          ) => {
            const internaIncorrectFiles = [];

            const convertXlsFormat = (date) => {
              if (!date || typeof date !== 'string') {
                return '';
              }
              if (date.indexOf('/') !== -1) {
                // eslint-disable-next-line no-undef
                return moment(date, 'DD/MM/YYYY').format('DD.MM.YYYY');
              }
              if (date.indexOf('.') !== -1) {
                // eslint-disable-next-line no-undef
                return moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
              }
              return '';
            };

            const normalizeDiacritics = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            function getValues(vn, sn1, sn2, nn, total, name) {
              return {
                vn: vn,
                vn_error: { error: vn.length === 0 },
                sn1: sn1,
                sn1_error: { error: sn1.length === 0 },
                sn2: sn2,
                sn2_error: { error: sn2.length === 0 },
                nn: nn,
                nn_error: { error: nn.length === 0 },
                total: total,
                total_error: { error: total.length === 0 },
                name: name,
              };
            }

            function voltageClassValues(parse, index) {
              const vn = [], sn1 = [], sn2 = [], nn = [];
              let current = undefined, total = '';

              let i = index + 1;

              for (; i < parse.length; i++) {
                if (parse[i][0].trim() === 'ВН') {
                  current = vn;
                  continue;
                }
                if (parse[i][0].trim() === 'СН 1') {
                  current = sn1;
                  continue;
                }
                if (parse[i][0].trim() === 'СН 2') {
                  current = sn2;
                  continue;
                }
                if (parse[i][0].trim() === 'НН') {
                  current = nn;
                  continue;
                }
                if (parse[i][0].trim() === 'ВСЕГО') {
                  total = value(parse[i][1]);
                  break;
                }

                if (current === undefined) {
                  throw new Error(`Ранее ожидался блок ВН/СН 1/СН 2/НН/ВСЕГО, строка: ${i}`);
                }

                const valueItem = value(parse[i][1]);
                current.push(
                  {
                    name: parse[i][0],
                    value: valueItem,
                    value_error: { error: valueItem.length === 0 },
                  },
                );
              }

              return {
                index: i + 1,
                result: {
                  vn: vn,
                  sn1: sn1,
                  sn2: sn2,
                  nn: nn,
                  total: total,
                  total_error: { error: total.length === 0 },
                },
              };
            }

            function value(value) {
              if (value === undefined || value.length === 0) {
                return '0';
              }
              if (isNaN(parseFloat(value))) {
                return '';
              }
              return value;
            }

            val.forEach((file) => {

              // eslint-disable-next-line no-undef
              const excel = XLSX.read(
file.code,
                {
                  type: 'base64',
                  cellText: false,
                  cellDates: true,
                },
              );

              // eslint-disable-next-line no-undef
              const tableArray = XLSX.utils.sheet_to_json(
excel.Sheets[excel.SheetNames[0]],
                {
                  header: 1,
                  defval: '',
                  raw: false,
                  blankrows: false,
                  dateNF: 'DD/MM/YYYY',
                },
);

              if (normalizeDiacritics(file.name).indexOf(normalizeDiacritics(attachment2NameWithoutExtension)) !== -1) {
                const parse = tableArray.slice(5, tableArray.length);

                const usefulVacation = {};
                usefulVacation.date = convertXlsFormat(tableArray[3][0]);
                usefulVacation.date_error = { error: false };
                usefulVacation.companyName = usefulVacationDocument.UtilityInfo.name;

                usefulVacation.value_1 = getValues(
                  value(parse[2][1]),
                  value(parse[3][1]),
                  value(parse[4][1]),
                  value(parse[5][1]),
                  value(parse[6][1]),
                );
                usefulVacation.value_2_6 = getValues(
                  value(parse[8][1]),
                  value(parse[9][1]),
                  value(parse[10][1]),
                  value(parse[11][1]),
                  value(parse[12][1]),
                );

                usefulVacation.departments = [];

                for (let i = 13; i < parse.length; i += 6) {
                  console.log(parse[i]);
                  if (parse[i][0].indexOf('обслуживаемым в ЦОКК') !== -1) {
                    usefulVacation.departments.push(
                      getValues(
                        value(parse[i + 1][1]),
                        value(parse[i + 2][1]),
                        value(parse[i + 3][1]),
                        value(parse[i + 4][1]),
                        value(parse[i + 5][1]),
                        'ЦОКК',
                      ),
                    );
                  } else if (parse[i][0].indexOf('обслуживаемым в ') !== -1) {
                    usefulVacation.departments.push(
                      getValues(
                        value(parse[i + 1][1]),
                        value(parse[i + 2][1]),
                        value(parse[i + 3][1]),
                        value(parse[i + 4][1]),
                        value(parse[i + 5][1]),
                        parse[i][0].replace('В том числе потребителям, обслуживаемым в ', ''),
                      ),
                    );
                  } else if (parse[i][0].indexOf('Мощность') !== -1) {

                    usefulVacation.voltageClassValues = [];

                    let values = voltageClassValues(parse, i);
                    i = values.index;

                    usefulVacation.voltageClassValues.push(
                      {
                        title: 'Мощность',
                        values: values.result,
                      },
                    );

                    usefulVacation.powerSummary = {
                      values: values.result,
                    };
                    // TODO костыль. Плохо учитываются индексы, нужно рефакторить весь цикл
                    usefulVacation.powerFourSixVolumes = getValues(
                      value(parse[i + 1][1]),
                      value(parse[i + 2][1]),
                      value(parse[i + 3][1]),
                      value(parse[i + 4][1]),
                      value(parse[i + 5][1]),
                    );
                    if (parse[i][0].indexOf('В том числе по 4-ой') === -1) {
                      console.log('parse source', parse);
                      console.log('line', parse[i][0]);
                      throw new Error(`Ожидался блок "В том числе по 4-ой, 6-ой ценовым категориям", строка: ${i}`);
                    }

                    values = voltageClassValues(parse, i);
                    i = values.index;

                    usefulVacation.voltageClassValues.push(
                      {
                        title: `В том числе по 4-ой, 6-ой ценовым категориям ${usefulVacation.companyName}`,
                        values: values.result,
                      },
                    );
                    usefulVacation.powerFourSix = {
                      values: values.result,
                    };

                    if (parse[i][0].indexOf('заказчика, по договору купли') === -1) {
                      console.log('parse source', parse);
                      console.log('line', parse[i][0]);
                      throw new Error(`Ожидался блок "В том числе по 4-ой, 6-ой ценовым категориям", строка: ${i}`);
                    }

                    values = voltageClassValues(parse, i + 1); // пропустить строку "Полезный отпуск"
                    i = values.index;

                    usefulVacation.voltageClassValues.push(
                      {
                        title: 'Абонент (потребитель) заказчика, по договору купли продажи',
                        values: values.result,
                      },
                    );

                    usefulVacation.voltageClassValuesDKP = {
                      values: values.result,
                    };

                    if (parse[i][0].indexOf('собственное потребление') === -1) {
                      console.log('parse source', parse);
                      console.log('line', parse[i][0]);
                      throw new Error(`Ожидался блок "в т.ч. собственное потребление", строка: ${i}`);
                    }

                    i++;

                    usefulVacation.ownConsumption = getValues(
                      value(parse[i][1]),
                      value(parse[i + 1][1]),
                      value(parse[i + 2][1]),
                      value(parse[i + 3][1]),
                      value(parse[i + 4][1]),
                    );

                    usefulVacation.transit = [];
                    for (let j = i + 5; j < parse.length; j += 6) {
                      if (parse[j][0].indexOf('Транзит в') !== -1) {
                        usefulVacation.transit.push(
                          getValues(
                            value(parse[j + 1][1]),
                            value(parse[j + 2][1]),
                            value(parse[j + 3][1]),
                            value(parse[j + 4][1]),
                            value(parse[j + 5][1]),
                            parse[j][0].replace('Транзит в ', ''),
                          ),
                        );
                        continue;
                      }
                      break;
                    }
                    break;
                  } else {
                    console.log('parse source', parse);
                    throw new Error(`Неизвестный блок. Ожидался обслуживаемым в ТО/ГО/ЦОКК или Мощность, строка: ${i}`);
                  }
                }

                usefulVacationDocument.attachment2 = usefulVacation;
                usefulVacationDocument.attachment2File = file;
              } else {
                internaIncorrectFiles.push(file.name);
              }
            });

            return {
              internaIncorrectFiles,
              usefulVacationDocument,
            };
          },
          [
            val,
            this.usefulVacationDocument,
            this.attachment2NameWithoutExtension,
          ],
        );

        incorrectFiles = res.internaIncorrectFiles;
        // this.usefulVacationDocument.attachment2 = res.usefulVacationDocument.attachment2;
        // this.usefulVacationDocument.attachment2File = res.usefulVacationDocument.attachment2File;
        this.usefulVacationDocument = res.usefulVacationDocument;

      } catch (e) {
        console.log('error XLSX.read', e);
      }

      if (incorrectFiles.length > 0) {
        this.vueShowNotification(
          '',
          `<p><b>Имя файла:</b></p><p>${incorrectFiles}</p><p><b>Не соответсвуют шаблону:</b></p><p>${this.attachment2Name}</p>`,
);
      }

      this.preValidations();

      this.isWaitingSubmit = false;
    },
    setElementsWidth() {
      this.footerWidth = `${this.$refs.usefulVacationContent.offsetWidth}px`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #fff;

  .dividerNavigation {
    margin-right: 60px;
    width: auto;
    margin-top: 8px;
  }

  .navigationBar {
    position: sticky;
    left: 146px;
    width: 848px
  }
}

.loader {
  position: relative;
  height: 50px;

  div:first-child {
    top: 50px;
  }
}

.content {
  padding-bottom: 60px;
  flex-grow: 2;
  position: sticky;
  left: 146px;
  width: 848px;

  .divider {
    width: 864px;
    margin-left: -8px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.contentFooter {
  height: 48px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 100;
  width: 100%;
}

.back, .next, .delete, .save, .submit {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 19px;

  svg {
    fill: #FFFFFF;
  }
}

.delete {
  margin-left: 16px;

  svg {
    path {
      fill: #EB5757
    }
  }

  span {
    margin-left: 7px;
  }
}

.back {
  background: #ffffff;

  svg {
    path {
      fill: #2F82DF;
    }
  }
}

.next {
  span {
    margin-right: 13px;
  }
}

.save {
  margin-right: 16px;

  span {
    margin-left: 7px;
  }
}

.submitBlock {
  display: flex;
  margin-top: 64px;

  div {
    width: 50%;
  }
}

.deleteText {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-top: 64px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.uploadingDraft {
  z-index: 100;
}
</style>
